import * as _ from 'underscore'
import { BeepPackageMixin } from "../beeppackage/BeepPackageMixin";
import {DataManager} from '../../../com/vbee/data/DataManager'
import { BeepPackage } from '../beeppackage/BeepPackage';

var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
export class PeriodDatasetMixin{
	constructor(){
        var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
        path.PeriodDatasetMixin = PeriodDatasetMixin;
    }
    defaults(){
		var ret = {
			type: "transformation_PeriodDataset",
			version: DataManager.getDataManager().get('currentVDMVersion')
		}
		return jQuery.extend(BeepPackage.prototype.defaults.apply(this),ret);
	}
    static getMixinRelations(){
		return _.union([
			{
				type: Backbone.HasOne,
				containingClass: "transformation_PeriodDataset",
				key: "next",
				relatedModel: "transformation.PeriodDataset",
				includeInJSON: "id",
				reverseRelation: {
					key: "previous",
					type: Backbone.HasOne,
					includeInJSON: "id"
				}
			}
		]);
	};
	static getSubModelTypes(){
        var ret = {};
        return ret;
    }
	static getCumulativeMixinRelations(){
		if (!PeriodDatasetMixin.cummulativeRelations) {
            PeriodDatasetMixin.cummulativeRelations = _.union(PeriodDatasetMixin.getMixinRelations()
                , BeepPackageMixin.getCumulativeMixinRelations()
            );
        }
		return PeriodDatasetMixin.cummulativeRelations.slice();
	};
	static getSuperTypes(){
		return [
			"beeppackage_BeepPackage"
		];
	}
	static getProperties(){
		return [
			{name : "version",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "label",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "parentLabel",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "syncedChangeLog",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "synchedChangeSet",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },

			{name : "year",type : "int",defaultValue : "null",containingClass : "transformation_PeriodDataset" },
            {name : "period",type : "int",defaultValue : "null",containingClass : "transformation_PeriodDataset" }
		]
	}
	getParent(){
		return this;	
    }
	getCalculationChangeType(operationType){
		return 2;
	}

	getCalculationChangeArtifact(operationType){
		if(this.get("inputOwner")){
			return this.get("inputOwner").id;
		}else if (this.get("resultOwner")){
			return this.get("resultOwner").id;
		}
		
	}
}
path.PeriodDatasetMixin = PeriodDatasetMixin;