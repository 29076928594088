import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	export class StereotypeMixin {

	defaults(){
		var ret = {
			type: "smm_Stereotype"
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!StereotypeMixin.cummulativeRelations) {
            StereotypeMixin.cummulativeRelations = _.union(StereotypeMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return StereotypeMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
		return [
		]
	}
	getParent(){
		var container;
		return EObject.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return EObject.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/StereotypePropertiesTemplate.html",
			templateName : "StereotypePropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/StereotypeViewModel",
			tabId : "StereotypeView",
			tabName: "Stereotype"
		}
	}
	}
	path.StereotypeMixin = StereotypeMixin;
	
	//return StereotypeMixin;
//});