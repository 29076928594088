import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {BMElementMixin} from './BMElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/BMElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,BMElementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var BMElement = VdmlElement.extend(utils.customExtends({
		relations:BMElementMixin.getMixinRelations(),
		subModelTypes: {
			vdml_BusinessModel : 'vdml.BusinessModel',
			vdml_BusinessModelDimension : 'vdml.BusinessModelDimension',
			vdml_BusinessModelState : 'vdml.BusinessModelState',
			vdml_BusinessModelItem : 'vdml.BusinessModelItem',
			vdml_BusinessModelPortfolio : 'vdml.BusinessModelPortfolio'
		},
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new BMElementMixin()
	));
	BMElement.abstract = true;
	path.BMElement = BMElement;
	export {BMElement};
//});