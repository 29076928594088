import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as  bootbox  from '../../../../../../libs/bootbox/bootbox'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import moment from 'moment';
import { Scenario } from '../../../../../version1/bo/vdml/Scenario'
import { DashboardMixin } from '../../../../../version1/bo/dashboard/DashboardMixin'
import { Observation } from '../../../../../version1/bo/smm/Observation'
//import { Presentation } from '../../../../../version1/bo/dashboard/Presentation'
import { AlternativeMixin } from '../../../../../version1/bo/transformation/AlternativeMixin'
import { Plan } from '../../../../../version1/bo/transformation/Plan'
import { PlanMixin } from '../../../../../version1/bo/transformation/PlanMixin'
//import { CodeContainer } from '../../../../../version1/bo/beeppackage/CodeContainer'
import { Analytics } from '../../../../../version1/bo/dashboard/Analytics'


/*define(["require","jquery","async","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global","typeahead","appbo/transformation/AlternativeMixin", "appbo/dashboard/DashboardMixin","appbo/transformation/Plan","appbo/vdml/Scenario","appbo/smm/Observation","appbo/transformation/PlanMixin","moment"],
function(require,$,async,_, Backbone,ko, koMapping,kb,bootbox,DataManager,global,typeahead,AlternativeMixin,DashboardMixin,Plan,Scenario,Observation,PlanMixin,moment
){*/
var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart",global.version);

export class DashboardScenariosViewModel {
	

	htmlEscape(str) {
		return String(str)
		.replace(/@/g, '')
		.replace(/ /g, '')
		.replace(/#/g, '');
	}
	
	cleanUp(){
		var self = this;
		if(this.alternativeList){
			this.alternativeList.removeAll();
		}
		if(this.phaseAccordionArray){
			this.phaseAccordionArray.length = 0;
		}
		if(self.nextPhaseCalculate){
			self.nextPhaseCalculate.length = 0;
		}
	};
	dispose(){
		var self = this;
		window.cleanViewModel(self);		
	};

	
	
	fillAlternatives(){
		var self = this;
		self.alternativeList([]);
		self.phaseAccordionArray = [];
		self.allObservations = [];
		var currentPlan = DataManager.getDataManager().get('currentPlan');
		var asIs = currentPlan.get('asIs');
		var goal = currentPlan.get('goal');
		var phase = currentPlan.get('phase').findWhere({nextPhase:null});
		while(phase){
			this.phaseAlts = phase.get('phaseAlternative');
			//var masterAlt = phase.get('master');
			var altAccordionList = [];
			self.phaseAlts.each(function(phaseAlt){
//					var scenarioList = [];
				//if(phaseAlt !== masterAlt){
					var altName = phaseAlt.get('name');
					if(phaseAlt == phase.get('primary')){
						altName = altName.concat(' (Primary)');
					}
					var observationModels = phaseAlt.getOrCreatePhaseObjectiveSet().get('objectiveObservation');
					/*if(observationModels.length === 0){
						phaseAlt.getOrCreatePhaseObjectiveSet().createContextObservation();
					}*/
					var obsArray = [];
					var scenarioList = self.fetchTimeStampList(phaseAlt.id);
					
					//removing deleted scenarios(advanced page) from json 
					for(var i=0;i<scenarioList.length;i++){
						var found = _.find(observationModels.models,function(ob){return ob.get('whenObservedGuid') === scenarioList[i]});
						if(!found){
							self.model.removeScenarioJson(scenarioList[i],phaseAlt.id);
						}	
					}
					//
					
					observationModels.each(function(observation){
						if(_.contains(scenarioList,observation.get('whenObservedGuid'))){
							var name = observation.get('name');
							if(!observation.get('whenObserved')){
								name = name.concat(' (Applied)');
							}
							
							if (!isNaN(observation.get('whenObserved')) && observation.get('whenObserved') != null){
								name = name.concat(' - Actual'+' ');
								var date = new Date(observation.get('whenObserved'));
								name = name.concat('('+date.toLocaleString()+')');
							}
							obsArray.push({id:observation.get('id'),encodeId:self.htmlEscape(observation.get('id')),name:name});
						}
						self.allObservations.push(observation.get('name'));
					});
					altAccordionList.push({id:phaseAlt.get('id'),encodeId:self.htmlEscape(phaseAlt.get('id')),name:altName,obsArray:obsArray});
				//}
			});		
			var phaseName = phase.get('name');
			if(asIs == phase){
				phaseName = phaseName.concat(' (As-Is)');
			}
			if(goal && goal.get('id') === phase.get('id')){
				phaseName = phaseName.concat(' (Goal)');
			}
			self.phaseAccordionArray.unshift({id:phase.get('id'),name:phaseName,encodeId:self.htmlEscape(phase.get('id')),altAccordionList:altAccordionList});
			altAccordionList = [];
			phase = phase.get('previousPhase');
		}
		self.alternativeList(self.phaseAccordionArray);
		self.allObservations = _.uniq(self.allObservations);
	};
	
	
	addPhaseAlternativesToArray(){
		var self = this;
		for(var i=0;i<self.phaseAlternatives.length;i++){
			var PhaseAlternateModel = self.phaseModel.get('phaseAlternative').models[i];
			var obj = {id:self.alternativeArray.length,originalId:PhaseAlternateModel.get("id"),modalId:self.htmlEscape(PhaseAlternateModel.get("id"))+"alternative", name:PhaseAlternateModel.get("name"),description:PhaseAlternateModel.get("description"),purpose:PhaseAlternateModel.get("purpose"),del:false};
			self.alternativeArray.push(obj);			
		}
	}
	
	/*this.createPhaseAlternative = function(){
		if(!DataManager.getDataManager().get('enterpriseEdition') && window.utils.getObservableLength(self.phaseAlternative()) <= DataManager.getDataManager().getMaxUnlicenssedAlternatives()){
			bootbox.alert(DataManager.getDataManager().get('localeManager').get("limitReached"));
			return false;
		}
		var id = htmlEscape(window.guidGenerator());
		var settings = self.model.get('settings');
		var addOptions = {'createNewAlternative':true,'alternatives':self.phaseAlternative(),'parentModal':self.phaseModel,'settings':settings};
		window.getAndCreateModalDialog(true,id,DashboardMixin,null,"EditScenario",self.saveOrCreateAlternative,addOptions);
	};*/
	
	/*this.saveOrCreateAlternative = function(view,encodeId){
		window.utils.startSpinner('creatingAlternativeSpinner', "Creating Alternative...");
		setTimeout(function(){        	
			var newAlt = [{'name':view.name(),'description':view.description(),'purpose':view.purpose(),'dependency':[],'originalId':'','clonedFromAltId':view.clonedFromAltId}];
			var phase = view.selectedPhase();			
			if(!phase){
				var phaseName = $('#selectPhaseOption'+encodeId).val().trim();
				var goalPhase = DataManager.getDataManager().get('currentPlan').get('goal');
				var prevPhaseModel = DataManager.getDataManager().get('currentPlan').get('phase').findWhere({nextPhase:null});
				Plan.createNewPhase(phaseName,phaseName,'',newAlt,DataManager.getDataManager().get('currentPlan'),function(phase){
					phase.setPrevNextLinks(goalPhase,prevPhaseModel,function(){
						var createdAlt = DataManager.getDataManager().getAlternativeSync(newAlt[0].originalId);
						createdAlt.saveDirectValues(view.directValueChanges);
						self.fillAlternatives();
						window.cleanDialogModel(encodeId,view);
						window.utils.stopSpinner('creatingAlternativeSpinner');
					});
				});
			}else {
				phase.savePhaseAlternatives(newAlt,true,function(phase){
					var createdAlt = DataManager.getDataManager().getAlternativeSync(newAlt[0].originalId);
					createdAlt.saveDirectValues(view.directValueChanges);
					self.fillAlternatives();
					window.cleanDialogModel(encodeId,view);
					window.utils.stopSpinner('creatingAlternativeSpinner');
				});
			}
		},10);
	};*/
	
	/*function getTimeLessObservation(context) {
		var observations = context.get('contextObservation');
		if (!observations) {
			observations = context.get('objectiveObservation');
		}
		for (var i = 0; i < observations.length; i++) {
			var obs = observations.at(i);
			var whenObs = obs.get('whenObserved');
			if (!whenObs) {
				return obs;
			}
		}
	}*/
	deleteScenario(view,encodeId){
		var self = this;
		window.utils.startSpinner('deletingScenarioSpinner', "Deleting Scenario...");
			setTimeout(function(){
				var whenObserved = ''+view.model.get('whenObserved');
				DataManager.getDataManager().invokeValueUpdates(function(){
					var plan = DataManager.getDataManager().get('currentPlan');
					DataManager.getDataManager().acquireSaveLock(function () {
						plan.deleteScenario([whenObserved], function () {
							DataManager.getDataManager().calculateValuesCache = {};
							DataManager.getDataManager().releaseSaveLock();
							self.model.removeScenarioJson(whenObserved, view.altModel.id);
							_.each(self.parentView.charts(), function (presentationView) {
								if (presentationView.viewInstance && 
								(presentationView.viewInstance.selectedChartType && presentationView.viewInstance.selectedChartType() !== null)) {
									if($("#view"+presentationView.encodeId).is(':visible')){
										$("#bind" + presentationView.encodeId).remove();
        								presentationView.loadContext();
									}
								}
							});
							self.parentView.setResizeHeight();
							self.fillAlternatives();
							self.openAccordion(view.phase.get('id'), view.altModel.get('id'));
							window.cleanDialogModel(encodeId, view);
							window.utils.stopSpinner('deletingScenarioSpinner');
						});
					});
				});
					
		},10);
	};
	
	saveScenario(view,encodeId,del){
		var self = this;
		if(del){
			self.deleteScenario(view,encodeId);
		}else {
			window.utils.startSpinner('savingAlternativeSpinner', "Saving Scenario...");
			setTimeout(function(){
				var obsName = view.obsName().trim();
				//var altScenario, alternativeObs;
				_.map(_.groupBy(view.directValueChanges,function(val){
					return val.altId;
				}),function(grouped){
					var alt = DataManager.getDataManager().getAlternativeSync(grouped[0].altId);
					/*altScenario = alt.get('phaseObjectiveSet');
					alternativeObs = altScenario.get('objectiveObservation').findWhere({ 'whenObserved': view.whenObserved });
					if(alternativeObs){
						alt.saveDirectValues(grouped,alternativeObs.get('whenObserved'));
					}else{
						alt.saveDirectValues(grouped);
					}*/
					alt.saveDirectValues(grouped,view.whenObserved);
				});
				var alternativeScenario = view.altModel.get('phaseObjectiveSet');
				var alternativeObservation = alternativeScenario.get('objectiveObservation').findWhere({ id: view.id() });
				if(alternativeObservation.get('name') !== obsName){
					alternativeObservation.set('name', obsName);
					self.fillAlternatives();
					self.openAccordion(view.phase.get('id'),view.altModel.get('id'));
					self.parentView.setResizeHeight(true);
				}else if(view.directValueChanges.length > 0){
					self.parentView.setResizeHeight(true);
				}
				//$('#'+htmlEscape(view.encodeId)).html(obsName);
				window.cleanDialogModel(encodeId,view);
				//self.fillAlternatives();
				window.utils.stopSpinner('savingAlternativeSpinner');				
			},10);
		}
	};
	createScenario(view,event){
		var self = this;
		if(!DataManager.getDataManager().isDiscoverPurchased() && !DataManager.getDataManager().isAdoptPurchased()){
			window.utils.limitExceeded();
		}else{
			DataManager.getDataManager().get('currentPlan').checkScenarioLimit(function(limitexceeded){
				if(limitexceeded) {
					bootbox.alert(DataManager.getDataManager().get('localeManager').get("scenarioLimitMsg"));
				} else{
					var htmlContent = "<div class=\"row\">";
					htmlContent = htmlContent + "<div class=\"col-xs-1\"></div><div class=\"col-xs-3\"><span class=\"control-label\">"+DataManager.getDataManager().get('localeManager').get('name')+"</span></div>";
					htmlContent = htmlContent + "<div class=\"col-xs-7\">";
					htmlContent = htmlContent + "<input id=\"obsName\" altId='" + view.id + "' class=\"form-control focus-ele\" autofocus type=\"text\" ></input>";
					htmlContent = htmlContent + "</div>";
					htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
					htmlContent = htmlContent + "</div>";
					//for warning
					htmlContent = htmlContent + "<div class=\"row warning hide\">";
					htmlContent = htmlContent + "<div class=\"col-xs-4\"></div>";
					htmlContent = htmlContent + "<div class=\"col-xs-7\">";
					htmlContent = htmlContent + "<span style=\"font-size: 12px;color: orange;\">"+DataManager.getDataManager().get('localeManager').get('ScenarioWarningMsg')+"</span>";
					htmlContent = htmlContent + "</div>";
					htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
					htmlContent = htmlContent + "</div>";
					//
					/*if(!DataManager.getDataManager().get('offLineMode') && DataManager.getDataManager().get('debugMode')){
						htmlContent += "<div class=\"addFromServer row hide\">";
						htmlContent += "<div class=\"col-xs-4\">Select Scenario</div>";
						htmlContent += "<div class=\"col-xs-7\">";
						htmlContent += "<select id=\"templateList\" class=\"form-control\"></select>";
						htmlContent += "</div>";
						htmlContent += "<div class=\"col-xs-1\"></div>";
						htmlContent += "</div><br>";
						//alternate
						htmlContent += "<div class=\"addFromServer row hide\">";
						htmlContent += "<div class=\"col-xs-4\">Select Alternative</div>";
						htmlContent += "<div class=\"col-xs-7\">";
						htmlContent += "<select id=\"alternateList\" class=\"form-control\"></select>";
						htmlContent += "</div>";
						htmlContent += "<div class=\"col-xs-1\"></div>";
						htmlContent += "</div><br>";
						//timestamp option
				
						htmlContent += "<div class=\"addFromServer row hide\">";
						htmlContent += "<div class=\"col-xs-4\">Actual Scenario</div>";
						htmlContent += "<div class=\"col-xs-7\">";
						htmlContent += "<input type=\"checkbox\" id=\"includeTimestamp\">";
						htmlContent += "</div>";
						htmlContent += "<div class=\"col-xs-1\"></div>";
					}*/
					htmlContent += "</div><br>";

					var box = bootbox.dialog({
						message: htmlContent,
						title: "<div><img class='smallIcon' src='img/icons/icons_16.png'>&nbsp;&nbsp;&nbsp;"+DataManager.getDataManager().get('localeManager').get('CreateScenario')+"</div>",
						buttons: {
							cancel: {
								label: DataManager.getDataManager().get('localeManager').get('Close'),
								className: "btn btn-default",
								callback: function () {
							
								}
							},
							/*addTemplate: {
								label: "Get Scenarios",
								className: "getScenario btn btn-default",
								callback: function() {
									var selectBtn = $('#templateList')[0];
									DataManager.getDataManager().get('vmpSnapshotService').getAllPromise("/vdmbee/snapshot?tenantId="+ DataManager.getDataManager().get('currentLegalEntity').entityId).then(function(data) {
										$("#templateList").empty();
										if(data.content) {
											$('.addFromServer')[0].classList.remove("hide");    //select
											$('.addFromServer')[1].classList.remove("hide");    //altIds
											$('.addFromServer')[2].classList.remove("hide");    //checkbox
											var altButton = $('#alternateList')[0];
											for(var j = 0; j < self.alternativeList().length; j++) {
												var opt = document.createElement("option");
												opt.innerHTML = self.alternativeList()[j].name;
												opt.dataset.val = self.alternativeList()[j].altAccordionList[0].id;
												altButton.appendChild(opt);
											}
											for(var i = 0; i < data.content.length; i++) {
												var opt = document.createElement("option");
												opt.innerHTML = data.content[i].name + ' ' + data.content[i].period;
												opt.value = data.content[i].name;
												//var href = data.content[i]._links.self.href;
												//href = href.substring(href.lastIndexOf('/') + 1);
												opt.dataset.id = data.content[i]._id;//href;
												opt.dataset.info = JSON.stringify(data.content[i]);
												selectBtn.appendChild(opt);
											}
											$('.createScenario').removeAttr('disabled');
										}
									});
									return false;
								}
							},*/
							success: {
								label: DataManager.getDataManager().get('localeManager').get('Complete'),
								className: "createScenario btn btn-complete",
								callback: function () {
									if($('#obsName').val().trim()) {
										window.utils.startSpinner('creatingScenarioSpinner', "Creating Scenario...");
										$('.createScenario').attr("disabled", true);
										var obsName = $('#obsName').val().trim();
										var altId = $('#obsName').attr('altId');
										setTimeout(function(){
											var vdmPackage = self.model.getNestedParent();
											DataManager.getDataManager().get('currentPlan').createNewObservation(obsName, function (obsTimeStampId) {
												function saveTimestamp(model,altId){
													var new_list = model.get('config')?JSON.parse(model.get('config')):{};
													if(new_list[''+altId]){
														new_list[''+altId].push(obsTimeStampId);
													}else {
														new_list[''+altId] = [obsTimeStampId];
													}
													model.set('config',JSON.stringify(new_list));
												}
												self.timeStampArray.push(obsTimeStampId);
												saveTimestamp(self.model,altId);
												self.fillAlternatives();
												var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({id:altId});
												self.openAccordion(alt.getParent().get('id'),alt.get('id'));
												window.utils.showSpinnerText('Saving Scenario...');
												/*DataManager.getDataManager().saveData({
													success: function () {
														box.modal('hide');
														window.utils.stopSpinner('creatingScenarioSpinner');
													},
													error: function () {
														box.modal('hide');
														window.utils.stopSpinner('creatingScenarioSpinner');
													},
													persist: true
												});*/
												box.modal('hide');
												window.utils.stopSpinner('creatingScenarioSpinner');
											});
										}, 50);
										return false;
									} /*else if($("#templateList :selected").text()) {
										var selectedTimeStamp = $('#includeTimestamp').is(':checked') ? Date.parse(moment()._d) : '';
										var csvContent;
										var startPoint = $("#alternateList :selected")[0].dataset.val;

										window.utils.startSpinner('importTemplate', "Importing Template ... ");
										if($("#templateList :selected").text()) {
											self.getJsonFromFileContent($("#templateList :selected")[0].dataset.info, function(csvData) {
												csvContent = csvData;
												if (csvContent.trim().length === 0) {
													window.utils.stopSpinner('importTemplate');
													bootbox.alert('Imported csv is empty');
													return;
												}
												var wb = XLSX.read(csvContent, { type: "binary" });
												var sheet1 = wb.Sheets[wb.SheetNames[0]];
												var csvObj = XLSX.utils.sheet_to_row_object_array(sheet1);
												var cloumnHeaders = Object.getOwnPropertyNames(csvObj[0]);
												if (cloumnHeaders.indexOf('Phase>Alternative') < 0 || cloumnHeaders.indexOf('Unit') < 0 || cloumnHeaders.indexOf('Value Type') < 0 || cloumnHeaders.indexOf('ValueName') < 0) {
													window.utils.stopSpinner('importTemplate');
													bootbox.alert('Imported template has wrong layout');
													return;
												} else {
													var currentPlan = DataManager.getDataManager().get('currentPlan');
													currentPlan.importCsvData(csvObj, selectedTimeStamp, startPoint, function(updateObs) {
														self.saveDataAfterImport(updateObs,function(){
															window.utils.stopSpinner('importTemplate');
														});	       
													});
												}
											});
										}
									}*/
								}
							}
						},
					
					});
					box.show();
					$('.createScenario').attr('disabled', 'disabled');
					var autoCompAttr = 'new-password';
					if(window.browser == "chrome"){
						autoCompAttr = 'off';
					}
					$('input[type="text"]').attr('autocomplete',autoCompAttr);
					/*if(DataManager.getDataManager().get('offLineMode') || !DataManager.getDataManager().get('debugMode')){
						$(".getScenario").hide();
					}*/
			
					$('#obsName').keyup(function(event){
						var inputVal = event.currentTarget.value.trim();
						var exists = _.find(self.allObservations, function(e){ return e === inputVal;});
						if(exists){
							$('.createScenario').attr('disabled', 'disabled');
							$('.warning').removeClass('hide');
						}else if(inputVal != ""){
							$('.createScenario').removeAttr('disabled');
							$('.warning').addClass('hide');
						}else{
							$('.createScenario').attr('disabled', 'disabled');
						}
					});
				}
			});
		}
	};

	saveDataAfterImport(updateObs,callback){
		var self = this;
		/*if(updateObs.codeContainerList){
			var codeContainerlist = options.addOptions.parentModal.DashboardViewModel.codeContainersList;
			if(codeContainerlist){
				_.each(updateObs.codeContainerList,function(codecont){
					codeContainerlist.push(codecont);
				})
				codeContainerlist = _.uniq(codeContainerlist);
			}else{
				options.addOptions.parentModal.DashboardViewModel.codeContainersList = updateObs.codeContainerList;
			}
		}
		options.addOptions.parentModal.importData=true;*/


		function saveTimestamp(model,altId,obsTimeStampId){
			var new_list = model.get('config')?JSON.parse(model.get('config')):{};
			if(new_list[''+altId]){
				new_list[''+altId].push(obsTimeStampId);
			}else {
				new_list[''+altId] = [obsTimeStampId];
			}
			model.set('config',JSON.stringify(new_list));
		}
		if(updateObs.obs.length>0){
			for(var i=0;i<updateObs.obs.length;i++){
				saveTimestamp(self.model,updateObs.altId,updateObs.obs[i]);
			}
		}
		//self.timeStampArray.push(obsTimeStampId);
		//saveTimestamp(self.model,altId,obsTimeStampId);
		self.fillAlternatives();
		//var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({id:altId});
		//self.openAccordion(alt.getParent().get('id'),alt.get('id'));
		window.utils.showSpinnerText('Saving Scenario...');
		callback();
		/*DataManager.getDataManager().saveData({
			success: function () {
				//box.modal('hide');
				//window.utils.stopSpinner('creatingScenarioSpinner');
				callback();
			},
			error: function () {
				//box.modal('hide');
				//window.utils.stopSpinner('creatingScenarioSpinner');
				callback();
			},
			persist: true
		});*/
	}

	/*getJsonFromFileContent(data, callback) {
		var self = this;
		data = JSON.parse(data);
		require(["xlsx"], function (_XLSX) {
			var templateContent = data.fileContent;
			var valueRows = [];
			var valueObj = {};
			var values = Object.getOwnPropertyNames(templateContent);

			for (var i = 0; i < values.length; i++) {
				var unit = templateContent[values[i]]['Unit'];
				var valueType = templateContent[values[i]]['ValueType'];
				if (valueType === 'Input') {
					valueObj = {};
					valueObj['ValueName'] = templateContent[values[i]]['ValueName'];
					valueObj['Unit'] = unit;
					valueObj['Phase>Alternative'] = templateContent[values[i]]['Phase>Alternative'];
					valueObj["Scenario-" + new Date().getFullYear() ] = templateContent[values[i]]['Value'];
					valueObj['Value Type'] = valueType;
					valueRows.push(valueObj);
				}
			}
				
			var ws = XLSX.utils.json_to_sheet(valueRows);
			blobData = XLSX.utils.sheet_to_csv(ws);
			callback(blobData);
		});
	};*/

	
	createRevisionBasedOnContext(pack,callback){
		var self = this;
		if(window.checkContextForRevision()) {
			function fetchBmModel(newVdmPackage){
					if(newVdmPackage) {
						DataManager.getDataManager().releaseSaveLock();
						DataManager.getDataManager().set('isActive',false);
						callback(newVdmPackage);
						/*DataManager.getDataManager().saveData({
							success:function(){
								callback(newVdmPackage);
							}, 
							error:function(){
								callback(newVdmPackage);
							}, 
							persist:true});	*/
					}
				}
			window.setTimeout(function(){
				DataManager.getDataManager().acquireSaveLock(function () {
					pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
				});
			},100);	
		}
		else {
			callback(pack);
		}
	};
	
	openAccordion(phase,alt){
		var self = this;
		
		if(!alt) {
			$('.panel-collapse:not(".in")').collapse('show');
		}else {
			$('#collapse'+self.htmlEscape(phase)).addClass('collapse in');
			$('#collapse'+self.htmlEscape(alt)).addClass('collapse in');
		}
	};
	
	editScenario(view,event){
		var self = this;
		var id = view.encodeId.replace('scenarios', '');
		var obsModel = Backbone.Relational.store.getObjectByName('smm.Observation').find({id:view.id});
		var settings = self.model.get('settings');
		var alternative = obsModel.getContext().getParent();
		var addOptions = {'settings':settings, alternative:alternative, 'width':'900px'};
		window.getAndCreateModalDialog(self,id,DashboardMixin,obsModel,"EditScenario",self.saveScenario,addOptions);
	};
	init(model, options){
		var self = this;
		this.options = options;
		this.DashboardScenariosViewModel = this;
		this.createScenario = _.bind(this.createScenario,self);
		this.editScenario = _.bind(this.editScenario,self);
		this.saveScenario = _.bind(this.saveScenario,this);
		this.parentView = model;
		this.model = model.model;
		this.phaseModel = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: DataManager.getDataManager().get('viewAlternative') }).get('phaseAlternativeOwner');
		this.id = ko.observable(self.model ? self.model.get('id') : null);
		this.phaseAlternatives = self.phaseModel ? self.phaseModel.get('phaseAlternative') : null;
		this.alternativeArray = [];
		this.phaseAlternative = ko.observableArray(this.alternativeArray);
		this.timeStampArray = [];
		this.allObservations = [];

		this.fetchTimeStampList = function (altId) {
			var ScenarioConfig = self.model.get('config');
			if (ScenarioConfig !== undefined) {
				var obj = JSON.parse(ScenarioConfig);
				if (obj['' + altId]) {
					return obj['' + altId];
				} else {
					return [];
				}
			} else {
				return [];
			}
		};
		self.timeStampArray = self.fetchTimeStampList(DataManager.getDataManager().get('viewAlternative'));
		this.alternativeList = ko.observableArray([]);
		this.phaseAccordionArray = [];
		this.nextPhaseCalculate = [];
		this.addPhaseAlternativesToArray();
		self.fillAlternatives();
		self.parentView.importData = false;
		/*self.parentView.fillAlternatives = function(){
			self.fillAlternatives();
		};*/
	}
	
	static getInstance(model,options){
		var view = new DashboardScenariosViewModel(model, options);
		view.init(model, options);
		return view;
	
	};		
}
path.DashboardScenariosViewModel = DashboardScenariosViewModel;