import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmElement} from './SmmElement'
import {MeasurementMixin} from './MeasurementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//import { ObservedMeasure } from './ObservedMeasure'
import { RefinementMeasurementRelationship } from './RefinementMeasurementRelationship'
import { EquivalentMeasurementRelationship } from './EquivalentMeasurementRelationship'
import { MeasurementRelationship } from './MeasurementRelationship'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/MeasurementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,MeasurementMixin
, ObservedMeasure
, RefinementMeasurementRelationship
, EquivalentMeasurementRelationship
, MeasurementRelationship){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	//if(!ObservedMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.ObservedMeasure){
			ObservedMeasure = importPath.ObservedMeasure;
		}
        else {
            import('./ObservedMeasure').then(({ default: ObservedMeasure }) => {
                ObservedMeasure = ObservedMeasure;
            });
            /*require(["appbo/smm/ObservedMeasure"], function (loadedModule) {
                ObservedMeasure = loadedModule;
			});*/
		}
	//}
	if(!RefinementMeasurementRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RefinementMeasurementRelationship){
			RefinementMeasurementRelationship = importPath.RefinementMeasurementRelationship;
		}
        else {
            import('./RefinementMeasurementRelationship').then(({ default: RefinementMeasurementRelationship }) => {
                RefinementMeasurementRelationship = RefinementMeasurementRelationship;
            });
			/*require(["appbo/smm/RefinementMeasurementRelationship"],function(loadedModule){
				RefinementMeasurementRelationship = loadedModule;
			});*/
		}
	}
	if(!EquivalentMeasurementRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.EquivalentMeasurementRelationship){
			EquivalentMeasurementRelationship = importPath.EquivalentMeasurementRelationship;
		}
        else {
            import('./EquivalentMeasurementRelationship').then(({ default: EquivalentMeasurementRelationship }) => {
                EquivalentMeasurementRelationship = EquivalentMeasurementRelationship;
            });
			/*require(["appbo/smm/EquivalentMeasurementRelationship"],function(loadedModule){
				EquivalentMeasurementRelationship = loadedModule;
			});*/
		}
	}
	if(!MeasurementRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.MeasurementRelationship){
			MeasurementRelationship = importPath.MeasurementRelationship;
		}
        else {
            import('./MeasurementRelationship').then(({ default: MeasurementRelationship }) => {
                MeasurementRelationship = MeasurementRelationship;
            });
			/*require(["appbo/smm/MeasurementRelationship"],function(loadedModule){
				MeasurementRelationship = loadedModule;
			});*/
		}
	}
	var Measurement = SmmElement.extend(utils.customExtends({
		relations:MeasurementMixin.getMixinRelations(),
		subModelTypes: {
			smm_DimensionalMeasurement : 'smm.DimensionalMeasurement',
			smm_GradeMeasurement : 'smm.GradeMeasurement'
		},
		initialize: function(attributes, options) {
			SmmElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new MeasurementMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	Measurement.abstract = sp2Enabled;
	Measurement.supported = !sp2Enabled;
	path.Measurement = Measurement;
	export {Measurement};
//});