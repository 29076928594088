import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {Attribute} from './Attribute'
import {Annotation} from './Annotation'
import {ObjectReference} from './ObjectReference'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin
, Attribute
, Annotation
, ObjectReference){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Attribute){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Attribute){
			Attribute = importPath.Attribute;
		}
		else{
			import('./Attribute').then(loadedModule => {
				Attribute = loadedModule;
			});
			/*require(["appbo/vdml/Attribute"],function(loadedModule){
				Attribute = loadedModule;
			});*/
		}
	}
	if(!Annotation){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Annotation){
			Annotation = importPath.Annotation;
		}
		else{
			import('./Annotation').then(loadedModule => {
				Annotation = loadedModule;
			});
			/*require(["appbo/vdml/Annotation"],function(loadedModule){
				Annotation = loadedModule;
			});*/
		}
	}
	if(!ObjectReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ObjectReference){
			ObjectReference = importPath.ObjectReference;
		}
		else{
			import('./ObjectReference').then(loadedModule => {
				ObjectReference = loadedModule;
			});
			/*require(["appbo/vdml/ObjectReference"],function(loadedModule){
				ObjectReference = loadedModule;
			});*/
		}
	}
	export class VdmlElementMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.VdmlElementMixin = VdmlElementMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_VdmlElement"
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getSubModelTypes(){
		return {
			vdml_ValueDeliveryModel : 'vdml.ValueDeliveryModel',
			vdml_AnalysisContext : 'vdml.AnalysisContext',
			vdml_Assignment : 'vdml.Assignment',
			vdml_CalendarService : 'vdml.CalendarService',
			vdml_PortDelegation : 'vdml.PortDelegation',
			vdml_MeasurableElement : 'vdml.MeasurableElement',
			vdml_MeasuredCharacteristic : 'vdml.MeasuredCharacteristic',
			vdml_BusinessItemLibrary : 'vdml.BusinessItemLibrary',
			vdml_CapabilityDependency : 'vdml.CapabilityDependency',
			vdml_CapabilityLibrary : 'vdml.CapabilityLibrary',
			vdml_Capability: 'vdml.Capability',
			vdml_Value: 'vdml.Value',
			vdml_PracticeCategory : 'vdml.PracticeCategory',
			vdml_PracticeDefinition : 'vdml.PracticeDefinition',
			vdml_PracticeLibrary : 'vdml.PracticeLibrary',
			vdml_ValueLibrary : 'vdml.ValueLibrary',
			vdml_ReleaseControl : 'vdml.ReleaseControl',
			vdml_Expression : 'vdml.Expression',
			vdml_Operand : 'vdml.Operand',
			vdml_ApplicationInterface : 'vdml.ApplicationInterface',
			vdml_ProcessReference : 'vdml.ProcessReference',
			vdml_CaseReference : 'vdml.CaseReference',
			vdml_EntityReference : 'vdml.EntityReference',
			vdml_WebServiceReference : 'vdml.WebServiceReference',
			vdml_WorklistReference : 'vdml.WorklistReference',
			vdml_BindingOperationReference : 'vdml.BindingOperationReference',
			vdml_RuleReference : 'vdml.RuleReference',
			vdml_VDMLDiagram : 'vdml.VDMLDiagram',
			vdml_BMElement : 'vdml.BMElement',
			vdml_RoleDefinition : 'vdml.RoleDefinition',
			vdml_RoleLibrary : 'vdml.RoleLibrary',
			vdml_RoleCategory : 'vdml.RoleCategory',
			vdml_BusinessItemLibraryElement : 'vdml.BusinessItemLibraryElement',
			vdml_ValueStream : 'vdml.ValueStream',
			vdml_VDMLCanvasElement: 'vdml.VDMLCanvasElement',
			canvas_BMCanvasDiagram :'canvas.BMCanvasDiagram',
			canvas_BMCanvasBlock :'canvas.BMCanvasBlock',
			canvas_BMCanvasItem: 'canvas.BMCanvasItem',
			dashboard_Presentation: 'dashboard.Presentation',
			dashboard_Dashboard: 'dashboard.Dashboard'
		}
	}
	static getMixinRelations(){
		return _.union([
/*		{
			type :Backbone.HasMany,
			containingClass:"vdml_VdmlElement",
			key:"attribute",
			relatedModel:"vdml.Attribute",
			reverseRelation: {
				key:"attributeOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_VdmlElement",
			key:"annotation",
			relatedModel:"vdml.Annotation",
			reverseRelation: {
				key:"annotationOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_VdmlElement",
			key:"attachments",
			relatedModel:"vdml.ObjectReference",
			reverseRelation: {
				key:"attachmentsOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}*/
		])
	}
	static getCumulativeMixinRelations(){
		if (!VdmlElementMixin.cummulativeRelations) {
            VdmlElementMixin.cummulativeRelations = _.union(VdmlElementMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return VdmlElementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		return EObject.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return EObject.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/VdmlElementPropertiesTemplate.html",
			templateName : "VdmlElementPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/VdmlElementViewModel",
			tabId : "VdmlElementView",
			tabName: "VdmlElement"
		}
	}
	
	}
	path.VdmlElementMixin = VdmlElementMixin;
	//return VdmlElementMixin;
//});