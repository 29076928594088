import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {RoleDefinitionMixin} from './RoleDefinitionMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Characteristic } from '../smm/Characteristic'
import { RoleCategory } from './RoleCategory'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/RoleDefinitionMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,RoleDefinitionMixin
, Characteristic
, RoleCategory){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Characteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Characteristic){
			Characteristic = importPath.Characteristic;
		}
		else{
           /* import('../smm/Characteristic').then(loadedModule => {
                Characteristic = loadedModule;
			});
			require(["appbo/smm/Characteristic"],function(loadedModule){
				Characteristic = loadedModule;
			});*/
		}
	}
	if(!RoleCategory){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.RoleCategory){
			RoleCategory = importPath.RoleCategory;
		}
		else{
            import('./RoleCategory').then(loadedModule => {
                RoleCategory = loadedModule;
			});
			/*require(["appbo/vdml/RoleCategory"],function(loadedModule){
				RoleCategory = loadedModule;
			});*/
		}
	}
	var RoleDefinition = VdmlElement.extend(utils.customExtends({
		relations:RoleDefinitionMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new RoleDefinitionMixin()
	));
	
	RoleDefinition.abstract = false;
	RoleDefinition.supported = false;
	path.RoleDefinition = RoleDefinition;
	export {RoleDefinition};
//});