import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {ScenarioProxyMixin} from './ScenarioProxyMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/transformation/ScenarioProxyMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,ScenarioProxyMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
	var ScenarioProxy = EObject.extend(utils.customExtends({
		relations:ScenarioProxyMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			EObject.prototype.initialize.apply(this, arguments);
		}
		}
		, new ScenarioProxyMixin()
	));
	
	ScenarioProxy.abstract = false;
	path.ScenarioProxy = ScenarioProxy;
	//return ScenarioProxy;
	export {ScenarioProxy};
//});