import * as _ from 'underscore'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ValueDefinitionMixin } from "./ValueDefinitionMixin";
import { Value2Mixin } from "./Value2Mixin";

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

export class ValueDefinition2Mixin {
    static getMixinRelations(){
        return _.union(ValueDefinitionMixin.getMixinRelations(),[
			{
				type :Backbone.HasOne,
				containingClass:"vdml_ValueDefinition",
				key:"formula",
				relatedModel:"vdml.Expression",
				reverseRelation: {
					key:"expressionOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			},
			{
				type :Backbone.HasOne,
				containingClass:"vdml_Unit",
				key:"unit",
				relatedModel:"vdml.Unit",
				includeInJSON: Backbone.Model.prototype.idAttribute
			},
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueDefinition",
				key:"aggregatedFrom",
				relatedModel:"vdml.ValueDefinition",
				includeInJSON: Backbone.Model.prototype.idAttribute,
			},
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueDefinition",
				key:"aggregatedTo",
				relatedModel:"vdml.ValueDefinition",
				includeInJSON: Backbone.Model.prototype.idAttribute,
			}			
		]);
    }
    static getCumulativeMixinRelations(){
        if (!ValueDefinition2Mixin.cummulativeRelations) {
            ValueDefinition2Mixin.cummulativeRelations = _.union(ValueDefinition2Mixin.getMixinRelations()
                , Value2Mixin.getCumulativeMixinRelations()
            );
        }
		return ValueDefinition2Mixin.cummulativeRelations.slice();
    }

	static getProperties(){
		return _.union(ValueDefinitionMixin.getProperties(),[
            { name: "balanceKind", type: "BalanceKind", defaultValue: "null", containingClass: "vdml_ValueDefinition" },
			{name : "acculumated",type : "EBoolean",defaultValue : "null",containingClass : "vdml_ValueDefinition" },
			{name : "externalId",type : "EString",defaultValue : "null",containingClass : "vdml_ValueDefinition" },
			{name : "sortOrder",type : "EString",defaultValue : "null",containingClass : "vdml_ValueDefinition" },
			{name : "isRescalable",type : "EBoolean",defaultValue : "null",containingClass : "vdml_ValueDefinition" },
			{ name: "aggregationMethod", type: "AggregationMethod", defaultValue: "null", containingClass: "vdml_ValueDefinition" },
			{ name: "valueType", type: "ValueType", defaultValue: "null", containingClass: "vdml_ValueDefinition" },
			{name : "isCalculated",type : "EBoolean",defaultValue : "null",containingClass : "vdml_ValueDefinition" }
		]);
	}
}
utils.customExtendClass (ValueDefinition2Mixin,new ValueDefinitionMixin());

path.ValueDefinition2Mixin = ValueDefinition2Mixin;