import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {VdmlElement} from './VdmlElement'
import {PolynomialCoefficientMixin} from './PolynomialCoefficientMixin'


	
var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

var PolynomialCoefficient = VdmlElement.extend(utils.customExtends({
    relations:PolynomialCoefficientMixin.getMixinRelations(),
    initialize: function(attributes, options) {
        VdmlElement.prototype.initialize.apply(this, arguments);
    }
    }
    , new PolynomialCoefficientMixin()
));
PolynomialCoefficient.getInstance = function(sequence,coefficient,owner){
    var coefficientId = DataManager.getDataManager().guidGeneratorByOwner(owner);
	var coefficients = new PolynomialCoefficient({id:coefficientId,sequence:sequence,coefficient:coefficient,coefficientsOwner:owner});
	return coefficients;
}
var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
PolynomialCoefficient.abstract = !sp2Enabled;
PolynomialCoefficient.supported = sp2Enabled;
path.PolynomialCoefficient = PolynomialCoefficient;
export {PolynomialCoefficient};
