import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {StrategyMapValuesChart} from './StrategyMapValuesChart'
import {BMGuidanceViewModel} from '../../../help/views/properties/BMGuidanceViewModel'
import {StrategyMapDetailsViewModel} from './StrategyMapDetailsViewModel'
import {StrategyMapDiagramMixin} from '../../../../../version1/bo/vdml/StrategyMapDiagramMixin'
import {EcoMapMixin} from '../../../../../version1/bo/ecomap/EcoMapMixin'
import {BusinessModel} from '../../../../../version1/bo/vdml/BusinessModel'
import * as EcoNavigatedViewer from '../../../../../../libs/bower-vdml-js/dist/vdml-navigated-viewer.min'
import * as EcoModeler from '../../../../../../libs/bower-vdml-js/dist/vdml-modeler.development'
import * as Highcharts from 'highcharts';
//import theme from 'highcharts/themes/dark-unica';
//theme(Highcharts);
import { HighChartThemes } from '../../../../../com/vbee/utils/HighChartThemes'
import { StrategyMapObjectiveViewModel } from './StrategyMapObjectiveViewModel'
import { CodeContainer } from '../../../../bo/beeppackage/CodeContainer'

/*define(["require", "jquery", "underscore", "backbone", "knockout", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/BusinessModel", "appbo/vdml/Participant", "appbo/vdml/ValueElement", "appbo/vdml/StrategyMapDiagram", "appbo/vdml/StrategyMapDiagramMixin", "appbo/vdml/ValueDeliveryModelMixin", "knockback", "ecomodeler", "econavigatedviewer", "appviews/strategyMap/views/designer/StrategyMapDetailsViewModel", "appviews/strategyMap/views/designer/StrategyMapObjectiveViewModel","appviews/help/views/properties/BMGuidanceViewModel", "appbo/beeppackage/CodeContainer", "appviews/strategyMap/views/designer/StrategyMapValuesChart", "appcommon/com/vbee/utils/HighChartThemes", "appbo/ecomap/EcoMapMixin"],
    function (require, $, _, Backbone, ko, bootbox, DataManager, global, BusinessModel, Participant, ValueElement, StrategyMapDiagram, StrategyMapDiagramMixin, ValueDeliveryModelMixin, kb, EcoModeler, EcoNavigatedViewer, StrategyMapDetailsViewModel, StrategyMapObjectiveViewModel, BMGuidanceViewModel, CodeContainer, StrategyMapValuesChart, HighChartThemes, EcoMapMixin) {*/
        var path = DataManager.getDataManager().buildAppNsPath("strategyMap.views.designer", global.version);
		export class StrategyMapDesignerViewModel {
		constructor(model,options){
			var self = this;
			this.init(model, options);
		}
		init(model, options) {
            var self = this;
            this.StrategyMapDesignerViewModel = this;
            self.model = model;
            self.data = model.get('data');
			this.enableComplete = ko.observable(false);
            this.shapesList = ko.observableArray([]);
			this.completeButton = ko.observable(true);
            //this.zoomButton = ko.observable(true);
			this.showObjectives = ko.observable(false);
			//if(DataManager.getDataManager().get('debugMode')){
				this.showObjectives(true);
			//}
            this.showViewMap = ko.observable(true);
            if(DataManager.getDataManager().get('debugMode')){
                this.showViewMap(true);
            }
			this.unsortedShapesList = [];
            this.deletedCodedContainers = [];
			this.children = [];
			this.mappingObject = {};
            this.mappingObject['vdml:Perspective'] = { 'name': DataManager.getDataManager().get('localeManager').get('Perspective'), collectionName: '' };
            this.mappingObject['vdml:Value'] = { 'name': DataManager.getDataManager().get('localeManager').get('value'), collectionName: 'value' };
            this.mappingObject['vdml:Activity'] = { 'name': DataManager.getDataManager().get('localeManager').get('Activity'), collectionName: 'activity' };
            this.mappingObject['vdml:Competency'] = { 'name': DataManager.getDataManager().get('localeManager').get('Competency'), collectionName: 'competence' };

            this.showDashboardStrategyMap = false;
            function htmlEscape(str) {
                return String(str)
                    .replace(/@/g, '')
                    .replace(/ /g, '');
            }
            this.encodeId = htmlEscape(self.model.id);
            this.editMode = DataManager.getDataManager().get('readMode')?false:true;
            if(window.utils.checkIsLinked(null,true)){
                self.editMode = false;
            }
            this.tempCapbilityIdList = [];
            this.tempValueDefList = [];
            //this.capabilityLibs = null;
            			
		    if (options.parentView && options.parentView == "Dashboard") {
                this.showDashboardStrategyMap = true;
                this.editMode = options.editMode;
                if (!ko.components.isRegistered('StrategyValuesChart')) {
                    ko.components.register("StrategyValuesChart", {
                        viewModel: { instance: self.chartViewInstance },
                        template: '<div></div>'
                    });
                }
				if (!ko.components.isRegistered('StrategyMapDetails')) {
					ko.components.register("StrategyMapDetails", {
						viewModel: StrategyMapDetailsViewModel,
						template: '<div></div>'
					});
                }
                if (!ko.components.isRegistered('StrategyMapObjectiveTemplate')) {
                    ko.components.register("StrategyMapObjectiveTemplate", {
                        viewModel: self.strategyMapObjectiveViewModel,
                        template: '<div></div>'
                    });
                }
            } else {
				
				/*window.utils.loadTemplate(function(name){
					if(name == "StrategyValuesChart"){
						self.chartViewInstance.initialize(self.model, false, self, self.model.get('value').models.concat());
                    }
                    if(name == "StrategyMapObjectiveTemplate"){
                        //self.strategyMapObjectiveViewModel.loadValues(self);
                        //self.strategyMapObjectiveViewModel.loadTable();
                    }				
				});*/
                self.highchartsDefaultTheme = $.extend(true, {}, Highcharts.defaultOptions);
                self.chartViewInstance = StrategyMapValuesChart.getInstance(this.model);
                if (!ko.components.isRegistered('StrategyValuesChart')) {
                    ko.components.register("StrategyValuesChart", {
                        viewModel: { instance: self.chartViewInstance },
                        template: window.utils.getHtmlPage("StrategyMapValuesChartTemplate"),
                        synchronous: true
                    });
                }
				if (!ko.components.isRegistered('StrategyMapDetails')) {
					ko.components.register("StrategyMapDetails", {
						viewModel: StrategyMapDetailsViewModel,
                        template: window.utils.getHtmlPage("StrategyMapDetailsTemplate"),
						synchronous: true
					});
				}
                self.strategyMapObjectiveViewModel = StrategyMapObjectiveViewModel.getInstance(this.model);
                if (!ko.components.isRegistered("StrategyMapObjectiveTemplate")) {
					ko.components.register("StrategyMapObjectiveTemplate", {
					      viewModel : { instance: self.strategyMapObjectiveViewModel },
                          template: window.utils.getHtmlPage("StrategyMapObjectiveTemplate"),
						  synchronous : true
					})
				}
            }

			if (!ko.components.isRegistered('StrategyMapGuidance')) {
				self.guidanceViewInstance = BMGuidanceViewModel.getInstance(this.model);
				ko.components.register("StrategyMapGuidance", {
					viewModel: { instance: self.guidanceViewInstance },
					template: '<div id="guidanceTab' + self.encodeId + '"></div>',
					synchronous: true
				});
			}

            this.createRevisionBasedOnContext = function (pack, callback) {
                if (window.checkContextForRevision()) {
                    window.utils.startSpinner('revisionSpinner', "Creating a copy...");
                    function fetchBmModel(newVdmPackage) {
                        if (newVdmPackage) {
                            DataManager.getDataManager().releaseSaveLock();
                            DataManager.getDataManager().set('isActive', false);
                            callback(newVdmPackage);
                            /*DataManager.getDataManager().saveData({
                                success: function () {
                                    callback(newVdmPackage);
                                },
                                error: function () {
                                    callback(newVdmPackage);
                                },
                                persist: true
                            });*/
                        }
                    }
                    window.setTimeout(function () {
                        DataManager.getDataManager().acquireSaveLock(function () {
						    window.utils.startSpinner('strategyMapSpinner', "Navigating to Strategy Map...");
                            pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
                        });
                    }, 100);
                }
                else {
                    callback(pack);
                }
            };

            if (window.checkContextForRevision() && !self.showDashboardStrategyMap) {
                var pack = self.model.getNestedParent();
                pack.checkRevision(model, function (revision) {
                    if (revision) {
                        self.createRevisionBasedOnContext(pack, function (newPack) {
                            var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
                            var altId = DataManager.getDataManager().getRepositoryId(newPack.id);
                            var map = newPack.get('diagrams').findWhere({ 'id': altId + bmId });
                            var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
                            var diagrams = newPack.get('diagrams');
                            for (var i = 0; i < diagrams.length; i++) {
                                newPack.fixDiagramOnRevision(diagrams.at(i), altId, oldAltId);
                            }
                            window.utils.stopSpinner('revisionSpinner');
                            DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newPack.get('id') + "/" + newPack.get('type') + "/" + newPack.get('version') + "/" + map.id + "/" + map.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                        });
                    }
                });
            }

            if (!self.data) {
                self.data = window.utils.getXmlData(model.get('type').replace("_","."),htmlEscape(model.id))
                self.model.set('data',self.data);
            }

			this.loadCapLibs = function(){
				if(self.model.get('capability').length > 0){
					var currentPlan = DataManager.getDataManager().get('currentPlan');
					currentPlan.loadCapabilityLibraries(function (capabilityLibraries) {
						self.capabilityLibs = capabilityLibraries;
					});
				}
			}
			
            this.openDiagram = function (xml, reDraw) {
                var self = this;
                //var xml = xml.split(self.model.id).join('s'+htmlEscape(self.model.id));
				self.model.set('data',xml);
                async function myDisplay() {
                    let result = new Promise(function(myResolve, myReject) {
                      myResolve("Works");
                    });
                    try {
                        result = await self.modeler.importXML(xml);
                        var { warnings } = result;
                        /*for(var warshape in warnings){
                            var ele = warnings[warshape].element;
                            if(!ele){
                                ele = warnings[warshape].context.element;
                            }
                            if(ele){
                                if (ele.$type === "vdml:BusinessItem" || ele.$type === "vdml:CausalRelationship" || ele.$type === "vdml:DeliverableFlow") {
                                    self.modeler.get('modeling').removeConnection(ele);
                                }else {
                                    if(!ele.outgoing){
                                        ele.outgoing = [];
                                    }
                                    if(!ele.incoming){
                                        ele.incoming = [];
                                    }
                                    self.modeler.get('modeling').removeElements([ele]);
                                }
                            }
                        }*/
                        //console.log(warnings);
                        //self.modeler.importXML(xml, function (err) {
                        // if (err) {
                        //      console.error(err);
                        //  }
                        //self.modeler.get("canvas").zoom("fit-viewport", true);
                        //$('svg').css('margin-left', 70);
                        if (window.vdmModelView === self) {
                            var height = self.model.get('height');
                            if (!height) {
                                height = getViewportSize().h * 0.80 + $('.navbar').height();
                            }
                            height = height > 700 ? height : 700;//to avoid 2 column djs-palette
                            $("#js-canvas" + self.encodeId).height(height);
                            self.loadTable();
                            self.loadCapLibs();
                            //$(".icon-hand-tool").click();
                            window.utils.stopSpinner('strategyMapSpinner');
                        }else if(reDraw){
                            self.loadCapLibs();
                        }
                        self.initializePresentation();
                        //$('#js-properties').height(getViewportSize().h);
                    //});
                      } catch (err) {
                        /*if(err.message = "no process or collaboration to display"){
                            var $xml = jQuery(xml);
                            var nodes = $xml.find('[id="' + self.model.id + '"]');
                            if(nodes.length != 0){
                                var diagramType = self.model.get('type').split("_");
                                var diagramTypeSuffix = "s";
                                if(diagramType[1]){
                                    diagramTypeSuffix = diagramType[1].charAt(0).toLowerCase();
                                }
                                var newId = diagramTypeSuffix + window.utils.htmlEscape(self.model.id);
                                var newXML = self.model.getNestedParent().fixXMLOnRevision(xml,self.model.id,newId);
                                self.model.set('data',newXML);
                                self.openDiagram(newXML);
                            }
                        } else {*/
                            console.log(err.message, err.warnings);
                        //}
                      }
                } 
                //var id  = "VDMLDiagram_1";//htmlEscape(self.model.get('id'));
                myDisplay();
            }
            
            this.initializePresentation = function () {
                var self = this;
                self.modeler.get("canvas").resized();
                self.modeler.get("canvas").zoom("fit-viewport", true);
                //self.afterRenderView(true,self);
			};

			this.getCapabilityElementModel = function (id) {
				if (!id) {
					return null;
				}
				var capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityCategory').find({ id: id });
				if (!capModel) {
					capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityDefinition').find({ id: id });
				}
				return capModel;
			}

			/*this.mapCapabilities = function (element) {
				function startCapDialog(){
					var id = htmlEscape(window.guidGenerator());
					var options = { capabilityLibs: self.capabilityLibs, callback: mapCapability };
					window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "CapabilityExplorerJson", null, options);

					function mapCapability(capId, capName, capDesc, capParentId) {
						if (!capId) {
							return;
						}
						function setCapId(newEcoMap) {
							element.businessObject.set('mcid', capId);
							element.businessObject.set('mcpid', capParentId);
							self.model.get('capability').add(self.getCapabilityElementModel(capId));
							if (!element.businessObject.get('name') || element.businessObject.get('name') == "") {
								element.businessObject.set('name', capName);
								element.businessObject.set('description', capDesc);
							}
							if (!newEcoMap) {
								self.modeler.get('eventBus').fire('elements.changed', {
									elements: [element]
								});
							}
						}
						if (window.checkContextForRevision()) {
							var pack = self.model.getNestedParent();
							self.createRevisionBasedOnContext(pack, function (newPackage) {
								var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
								var altId = DataManager.getDataManager().getRepositoryId(newPackage.id);
								var strategyMap = newPackage.get('diagrams').findWhere({ 'id': altId + bmId });
								window.utils.stopSpinner('revisionSpinner');
								self.model = strategyMap;
								setCapId(strategyMap);
								self.saveDiagram(null, null, null, strategyMap, newPackage, false);
							});
						} else {
							setCapId();
							self.saveDiagram();
						}
					}
				}
				if(self.capabilityLibs){
					var currentPlan = DataManager.getDataManager().get('currentPlan');
					currentPlan.loadCapabilityLibraries(function (capabilityLibraries) {
						self.capabilityLibs = capabilityLibraries;
						startCapDialog();
					});
				} else {
					startCapDialog();
				}
				
			};*/
			
            this.showProperties = function (data, ev) {
                if(self.editMode){
                    var id = htmlEscape(window.guidGenerator());
                    var elementRegistry = self.modeler.get('elementRegistry');
                    var options = { businessObject: data.businessObject, element: elementRegistry.get(data.businessObject.id), width: '660px' };
                    window.getAndCreateModalDialog(self, id, StrategyMapDiagramMixin, self.model, "ShapeProperties", null, options);
                }
            }
            this.loadTable = function (event) {
                self.shapesList.removeAll();
                self.unsortedShapesList = [];
                var shapes, definitions, strategyMap, modeler = self.modeler;
                if (modeler) {
                    definitions = modeler._definitions;
                }
                if (definitions) {
                    strategyMap = definitions.rootElements;
                }
                if (strategyMap && strategyMap.length) {
                    shapes = strategyMap[0].flowElements;
                }
                if (shapes) {
                    for (var i = 0; i < shapes.length; i++) {
                        if (event && event.type === 'shape.remove' && event.element.id === shapes[i].id) {
                            continue;
                        }
                        var type = shapes[i].$type;
                        if (type === "vdml:BusinessItem" || type === "vdml:CausalRelationship" || type === "vdml:DeliverableFlow") {
                            continue;
                        }
                        addToShapesList(shapes[i]);
                        if (type === "vdml:Perspective") {
                            var childrenShapes = shapes[i].flowElements;
                            if (childrenShapes && childrenShapes.length > 0) {
                                childrenShapes.forEach(function (shape) {
                                    addToShapesList(shape);
                                });
                            }
                        }
                    }
                }
                function addToShapesList(shape) {
                    if (shape.$type !== "vdml:BusinessItem" && shape.$type !==  "vdml:CausalRelationship" && shape.$type !==  "vdml:DeliverableFlow") {
                        var typeToDisplay = self.mappingObject[shape.$type].name;
                        var name = shape.name;
                        var mappingEleId = shape.mid;
                        var packId = shape.mpid;
                        var mappingEleName = '';
                        if (mappingEleId) {
                            self.model.getMappedElement(mappingEleId, packId, shape.$type, shape, function (model) {
                                mappingEleName = model ? model.get('name') : '';
                            });
                        }
                        self.shapesList.push({ businessObject: shape, name: name, type: typeToDisplay, mappedEle: mappingEleName });
                        self.unsortedShapesList.push({ businessObject: shape, name: name, type: typeToDisplay, mappedEle: mappingEleName });
                    }
                }
                //self.setResizeHeight();
                self.shapesList.sort(sortShapesInTableByName);
            }
            function sortShapesInTableByName(left, right) {
                if (left.name && right.name) {
                    return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
                }
            }
            this.cleanUp = function () {
                if (self.modeler) {
                    self.modeler.destroy();
                }
                if (ko.components.isRegistered('StrategyMapDetails')) {
                    ko.components.unregister('StrategyMapDetails');
                }
                if (ko.components.isRegistered('StrategyMapGuidance')) {
                    ko.components.unregister('StrategyMapGuidance');
                }
                if (ko.components.isRegistered('StrategyValuesChart')) {
                    ko.components.unregister('StrategyValuesChart');
                }
				if (ko.components.isRegistered('StrategyMapObjectiveTemplate')) {
                    ko.components.unregister('StrategyMapObjectiveTemplate');
                }
				//window.utils.loadTemplate(null,true);
                if (self.spliter) {
                    self.spliter.enhsplitter('remove');
                }
                /*if (self.loadedCodedContainers) {
                    var codeContainersList = self.loadedCodedContainers;
                    _.each(codeContainersList, function (codeContainer) {
                        codeContainer.save(null, {
                            success: function () {
                                DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
                            },
                            error: function (error) {
                                console.log(error);
                                //DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
                            }
                        });
                    });
                    codeContainersList.length = 0;
                }*/
                if (self.highchartsDefaultTheme) {
                    Highcharts.setOptions(HighChartThemes.getDefaultThemeFont());
         	        Highcharts.setOptions(self.highchartsDefaultTheme);
                }
            }
			this.saveShapeDetailsData = function(view){
				var self = this;
				var found = false;
				for(var i=0; i < self.children.length; i++){
					if(self.children[i].modal == view.mappedElementModel){
						self.children[i].name = view.name();
						self.children[i].description = view.description();
						found = true;
						break;
					}
				}
				if(!found){
					self.children.push({'name':view.name(),'description':view.description(),'modal':view.mappedElementModel});
				}
			}
			this.saveTempCapList = function(xml){
				var self = this;
				var xmlDoc = jQuery.parseXML(xml);
			    var $xml = jQuery(xmlDoc);
				for(var i=0; i < self.tempCapbilityIdList.length; i++){
					var nodes = $xml.find('[mcid="' + self.tempCapbilityIdList[i] + '"]');
					var cap = self.getCapabilityElementModel(self.tempCapbilityIdList[i]);
					if(nodes.length > 0){
						self.model.get('capability').add(cap);
					}else {
						self.model.get('capability').remove(cap);
					}
                }
                for (var i = 0; i < self.tempValueDefList.length; i++) {
                    var nodes = $xml.find('[mcid="' + self.tempValueDefList[i] + '"]');
                    var cap = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.tempValueDefList[i] });
                    if (nodes.length > 0) {
                        self.model.get('valueDefinition').add(cap);
                    } else {
                        self.model.get('valueDefinition').remove(cap);
                    }
                }
			}
			this.saveSyncData = function(revision){
				for(var i=0; i < self.children.length; i++){
					var vdmlModal = self.children[i].modal;
					if(revision){
						var modelSuffix = vdmlModal.id.substr(vdmlModal.id.lastIndexOf('@') + 1);
						var type = vdmlModal.get('type').replace('_', '.')
                        vdmlModal = Backbone.Relational.store.getObjectByName(""+type).find({ id: DataManager.getDataManager().get('viewAlternative') + modelSuffix });
					}
					if(vdmlModal) {
						vdmlModal.set('name',self.children[i].name);
						vdmlModal.set('description',self.children[i].description);
					}
				}
				self.children = [];
                EcoMapMixin.removeImageArtifact(self.deletedCodedContainers,self.model.getNestedParent());
                self.deletedCodedContainers = [];
			}
            this.createRevision = function (newXML, callback, depPacks) {
                window.utils.startSpinner('revisionSpinner', "Creating a copy...");
                setTimeout(function () {
                    self.model.getNestedParent().createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), function (newPackage) {
                        var newRep = DataManager.getDataManager().getRepositoryId(newPackage.id);
                        var modelSuffix = self.model.id.substr(self.model.id.lastIndexOf('@') + 1);
                        var newDiagram = Backbone.Relational.store.getObjectByName("vdml.StrategyMapDiagram").find({ id: newRep + modelSuffix });
                        newDiagram.set('data', newXML, { silent: true });
                        var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
                        var diagrams = newPackage.get('diagrams');
                        for (var i = 0; i < diagrams.length; i++) {
                            newPackage.fixDiagramOnRevision(diagrams.at(i), newRep, oldAltId);
                        }
                        window.utils.stopSpinner('revisionSpinner');
                        callback(newPackage, newDiagram);
                    }, depPacks);
                }, 10);
            }

            this.viewMap= function(){
                var self = this;
                self.model.getNestedParent().viewMap(model);
            }
            /*this.zoomReset= function () {
                //self.modeler.get('zoomScroll').reset(1);
                self.modeler.get("canvas").zoom("fit-viewport", true);
            }
            this.zoomIn= function () {
                self.modeler.get('zoomScroll').stepZoom(1);
            }
            this.zoomOut= function () {
                self.modeler.get('zoomScroll').stepZoom(-1);
            }*/
            this.saveDiagram = function (view, event, callback, revisionStrategyMap, vdmlPackageRevision) {
                if (self.model) {
                    window.utils.startSpinner('saveSpinner', "Saving Strategy-Map...");
                    setTimeout(function () {
                        async function saveVdmioXML() {
                            let result = new Promise(function(myResolve, myReject) {
                              myResolve("Works");
                            });
                            try {
                                result = await self.modeler.saveXML({ format: true });
                                var { xml } = result;
                                if (xml) {
                                    //var oldData = self.model.get('data');
                                    if (window.checkContextForRevision()) {
                                        if (revisionStrategyMap) {
                                            if (vdmlPackageRevision) {
                                                //var newXml = window.utils.replaceAll(xml, DataManager.getDataManager().get('currentWorkspace').id, DataManager.getDataManager().get('viewAlternative'));
                                                self.model.set('data', xml, { silent: true });
                                                self.saveTempCapList(xml);
                                                self.saveSyncData(true);
                                            } else {
                                                self.model.set('data', xml, { silent: true });
                                                self.saveTempCapList(xml);
                                                self.saveSyncData();
                                            }
                                            var oldAltId = DataManager.getDataManager().get('currentWorkspace').id;
                                            var newRep = DataManager.getDataManager().get('viewAlternative');
                                            var packEco = self.model.getNestedParent();
                                            var diagrams = packEco.get('diagrams');
                                            for (var i = 0; i < diagrams.length; i++) {
                                                packEco.fixDiagramOnRevision(diagrams.at(i), newRep, oldAltId);
                                            }
                                            self.enableComplete(false);
                                            window.utils.stopSpinner('saveSpinner');
                                            if (callback) {
                                                callback();
                                            } else {
                                                DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + revisionStrategyMap.getNestedParent().get('id') + "/" + revisionStrategyMap.getNestedParent().get('type') + "/" + revisionStrategyMap.getNestedParent().get('version') + "/" + revisionStrategyMap.id + "/" + revisionStrategyMap.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                                            }
                                        } else {
                                            //self.model.set('data', newXml, { silent: true });
                                            if (!revisionStrategyMap && !vdmlPackageRevision && !self.enableComplete()) {
                                                window.utils.stopSpinner('saveSpinner');
                                                if (callback) {
                                                    callback();
                                                }
                                            } else {
                                                var strategyPackage = self.model.getNestedParent();
                                                //var depPacks = strategyPackage.get('dependentPackage') ? strategyPackage.get('dependentPackage').models.concat() : [];
                                                self.createRevision(xml, function (newPackage, newDiagram) {
                                                    self.enableComplete(false);
                                                    self.saveSyncData(true);
                                                    window.utils.stopSpinner('saveSpinner');
                                                    if (callback) {
                                                        callback();
                                                    } else {
                                                        DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newPackage.get('id') + "/" + newPackage.get('type') + "/" + newPackage.get('version') + "/" + newDiagram.id + "/" + newDiagram.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                                                    }
                                                }, []);
                                            }
                                        }
                                    } else {
                                        if(vdmlPackageRevision){
                                            var xmlDoc = jQuery.parseXML(xml);
                                            var $xml = jQuery(xmlDoc);
                                            var alt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('currentWorkspace').get('id'));
                                            var vdmlPackIds = alt.getOwnedVDMLPackages();
                                            _.each(vdmlPackIds,function(packId){
                                                var pac = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: packId });
                                                if(pac) {
                                                    var oldPackId = pac.get('copySource');
                                                    var nodes = $xml.find('[mpid="' + oldPackId + '"]');
                                                    nodes.each(function (callback, node) {
                                                        node.setAttribute('mpid', packId);
                                                        var oldmidSuffix = window.utils.getSuffix(node.getAttribute('mid'));
                                                        node.setAttribute('mid', alt.id + oldmidSuffix);
                                                    })
                                                }
                                            });
                                            xml = (new XMLSerializer()).serializeToString(xmlDoc);
                                        }
                                        self.model.set('data', xml);
                                        self.saveSyncData();
                                        self.saveTempCapList(xml);
                                        self.enableComplete(false);
                                        window.utils.stopSpinner('saveSpinner');
                                        if (callback) {
                                            callback();
                                        }
                                    }
                                } else {
                                    self.enableComplete(false);
                                    window.utils.stopSpinner('saveSpinner');
                                    if (callback) {
                                        callback();
                                    }
                                }
                              } catch (err) {
                                console.log(err.message, err.warnings);
                              }
                        }
                        saveVdmioXML();
                        /*self.modeler.saveXML({ format: true }, function (err, xml) {
                                                    
                        });*/
						//self.setResizeHeight();
                    }, 100);
                } else {
                    callback();
                }
            };

            this.hasUnsavedData = function () {
                if (this.enableComplete() /*&& !self.viewMode()*/) {
                    return true;
                } else {
                    return false;
                }
            };

            this.saveDataBeforeClosing = function (callback) {
                var self = this;
                self.saveDiagram(null, null, callback);
            };

            function getViewportSize(w) {

                // Use the specified window or the current window if no argument
                w = w || window;

                // This works for all browsers except IE8 and before
                if (w.innerWidth != null) return { w: w.innerWidth, h: w.innerHeight };

                // For IE (or any browser) in Standards mode
                var d = w.document;
                if (document.compatMode == "CSS1Compat")
                    return {
                        w: d.documentElement.clientWidth,
                        h: d.documentElement.clientHeight
                    };

                // For browsers in Quirks mode
                return { w: d.body.clientWidth, h: d.body.clientHeight };

            }
			this.shapeDeleteModel = function(businessObject,element){
				var self = this;   
				var selectedBmsModelsList = [];
				self.businessObject = businessObject;
				var pack = self.model.getNestedParent();
				var htmlContent = "<div class=\"row\">";
				htmlContent = htmlContent + "<span style ='margin-left:15px'>"+DataManager.getDataManager().get('localeManager').get('DeleteShapeMsg')+"</span>";
				
				if(businessObject.mid /*&& !window.checkContextForRevision()*/){
					if(businessObject.$type == "vdml:Value"  && window.utils.getPrefix(businessObject.mid) === window.utils.getPrefix(DataManager.getDataManager().get('currentPlan').id)){
						htmlContent = "<span><input id='deleteShapeElement' type=\"checkbox\" style=\"cursor: pointer;\" checked /><span style ='margin-left:5px'>Delete value from Plan</span></span>";
					}/*else if(businessObject.$type == "vdml:Value"  && window.utils.getPrefix(businessObject.mid) != window.utils.getPrefix(DataManager.getDataManager().get('currentPlan').id)){
					    htmlContent = htmlContent;
					}*/else {
						htmlContent = "<span style = 'font-weight:bold'>Do you want to delete this element from following Business Models? Deleting it may lead to loss of data, that cannot be recovered, do you want to continue ?</span><br></br>";
						htmlContent += "<table class='table table-bordered' style = 'width:450px;margin-left:50px'><thead><tr>";
						htmlContent +=  "<th>Business Model(s)</th></tr></thead>";
						htmlContent +=  "<tbody id= 'bmsModelsList'>";
						htmlContent +=  "</tbody></table>"
					}
				}
		        htmlContent = htmlContent + "</div>";
				
				var dialog = bootbox.dialog({
					title: "<div>&nbsp;&nbsp;&nbsp;Delete Shape</div>",
					message: htmlContent,
					show: true,
					buttons:{
						main: {
							label: "Cancel",
							className: "btn btn-default",
						},
						success:{
							label: '<i id="strategyShapeDeleteBtn" class="fa fa-check"></i> Delete',
							className: "btn btn-complete shapeDelete", 
							callback: function() {
								var oldPack = self.model.getNestedParent();
								self.createRevisionBasedOnContext(oldPack, function (newPack) {
									var rev = oldPack.id !== newPack.id;
									var altId = DataManager.getDataManager().getRepositoryId(newPack.id);
									if(rev){
										var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
										var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
										var map = newPack.get('diagrams').findWhere({ 'id': altId + bmId });
										var diagrams = newPack.get('diagrams');
										for (var i = 0; i < diagrams.length; i++) {
											newPack.fixDiagramOnRevision(diagrams.at(i), altId, oldAltId);
										}
										self.model = map;
                                        var xmlDoc = jQuery.parseXML(self.model.get("data"));
                                        var jqueryXml = jQuery(xmlDoc);
                                        jqueryXml.find('[id="' + element.id + '"]').remove();
                                        jqueryXml.find('[id="' + element.id + '_di"]').remove();
                                        var newXML = (new XMLSerializer()).serializeToString(xmlDoc);
                                        self.model.set('data',newXML);
                                        window.utils.stopSpinner('revisionSpinner');
									}
                                    EcoMapMixin.deleteSuggestion(self.model, businessObject.id);
                                    self.modeler.get('modeling').removeElements([ element ]);
                                    var selectedBm = [];
                                    var bms = selectedBmsModelsList;
                                    for(var i=0;i<bms.length;i++){
                                        if($('#'+htmlEscape(bms[i].id)).prop('checked')){
                                            var bmId = bms[i].id;
                                            if(rev){
                                                var bmId = altId + window.utils.getSuffix(bmId);
                                            }
                                            var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:bmId});
                                            selectedBm.push(bm);
                                        }
                                    }
                                    if($('#deleteShapeElement').prop('checked') || selectedBm.length > 0){
                                        if(rev){
                                            businessObject.mid = altId + window.utils.getSuffix(businessObject.mid);
                                            businessObject.mpid = altId + window.utils.getSuffix(businessObject.mpid);
                                            EcoMapMixin.deleteTargetElement(businessObject,selectedBm,function(){
                                                EcoMapMixin.deleteSuggestion(self.model, businessObject.id);
                                                self.enableComplete(false);
                                                var newPackage = self.model.getNestedParent();
                                                DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newPackage.get('id') + "/" + newPackage.get('type') + "/" + newPackage.get('version') + "/" + self.model.id + "/" + self.model.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                                            });
                                        } else {
                                            window.utils.startSpinner('deletionSpinner', "Deleting Selected Element...");
                                            window.setTimeout(function () {
                                                EcoMapMixin.deleteTargetElement(businessObject,selectedBm,function(){
                                                    EcoMapMixin.deleteSuggestion(self.model, businessObject.id);
                                                    window.setTimeout(function () {
                                                        DataManager.getDataManager().invokeValueUpdates(function(){
                                                            if(self.chartViewInstance && self.chartViewInstance.initialize){
                                                                self.chartViewInstance.initialize(self.model, true, self, self.model.get('value').models.concat())
                                                            }
                                                            window.utils.stopSpinner('deletionSpinner');
                                                        },false);
                                                    },10);
                                                });
                                            },100);
                                        }
                                            
                                    } else {
                                        if(rev){
                                            self.saveDiagram(null,null,null,null, null, null);
                                            var newPackage = self.model.getNestedParent();
                                            DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newPackage.get('id') + "/" + newPackage.get('type') + "/" + newPackage.get('version') + "/" + self.model.id + "/" + self.model.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                                        }
                                        else if(self.chartViewInstance && self.chartViewInstance.initialize){
                                            self.chartViewInstance.initialize(self.model, true, self, self.model.get('value').models.concat())
                                        }
                                    }
                                });
							}
						}
					}
				})
				dialog.attr("id", "bootbox_id");
				$('#bootbox_id').draggable();
				if(businessObject.mid /*&& !window.checkContextForRevision()*/){
					var htmlContent ='';
					pack.checkBmModels(self.businessObject, '',function(bmsList){
						if(bmsList && !$('#bmsLists').length){
						    bmsList.sort(sortByName);
							for(var i =0;i<bmsList.length;i++){
							  selectedBmsModelsList.push({id:bmsList[i].get('id')});
					 		  htmlContent = "<tr><td><span id = 'bmsLists' style = 'padding-left:5px'><input type=\"checkbox\" id='" + htmlEscape(bmsList[i].id) + "'  value='" + bmsList[i].get('name') + "' style=\"cursor: pointer;\" checked /><span>" + " " + bmsList[i].get('name') + "</span></span></tr></td>";
							  $('#bmsModelsList').append(htmlContent);
							}
						}
					});
				}
			}
            function sortByName(left, right) {
				return left.get('name').toLowerCase() === right.get('name').toLowerCase() ? 0 : (left.get('name').toLowerCase() < right.get('name').toLowerCase() ? -1 : 1);
			}
			this.setResizeHeight = function (view, event) {
                if (!self.showDashboardStrategyMap) {
				    var spliterLength = $('.splitter_panel').find('.propertiesArea').parent().css('width');
                    setTimeout(function () {
                        var propertiesNode = 0;
                        if ($('.propertiesArea').width() > 10) {
                            propertiesNode = $('.propertiesArea').outerHeight();
                        }
                        //var baseHeight = _.max([bmNode.outerHeight(),propertiesNode.outerHeight()])+20;
                        //var topAreaHeight = $('.navbar').outerHeight();
                        var modelHeight = $('#mainTab' +self.encodeId).outerHeight();
                        var lhsHeight = $("#js-canvas" + self.encodeId).outerHeight() + 50;
                        if($('#mainTab' +self.encodeId).find('.active a span').text() === DataManager.getDataManager().get('localeManager').get('shapeDetails')){
							lhsHeight = $('.table-responsive').outerHeight() + $('.table-responsive').outerHeight()/3 ;
						}else if($('#mainTab' +self.encodeId).find('.active a span').text() === "Objectives"){
							lhsHeight = $('#myTable'+self.encodeId).outerHeight() + $('#myTable'+self.encodeId).outerHeight()/6;
						} else if($('#mainTab' +self.encodeId).find('.active a span').text() === DataManager.getDataManager().get('localeManager').get('Values')){
							lhsHeight = $(".chart").outerHeight() + $(".chart").outerHeight()/6;
						} else if(self.model){//map view
                            modelHeight = self.model.get('height') ? modelHeight + self.model.get('height') + $('.navbar').outerHeight() : modelHeight;
                        }
						var windowHeight = window.innerHeight;
                        
                        var baseHeight = _.max([lhsHeight, propertiesNode, windowHeight, modelHeight]);
                        if (self.spliter) {
                            self.spliter.css('height', baseHeight + 'px');
                            if ($('.propertiesArea').width() < 5 && Math.abs($(window).width() - $('.modeler').width()) < 30) {
                                var windowWidth = $(window).width() - 15;
                                $('.splitter_bar').css('left', windowWidth);
                            }
                            $("#splitPanels" + self.encodeId).enhsplitter('refresh');
							/*if($('.splitter_panel').find('.propertiesArea').parent().css('width') != spliterLength){
							   $('.splitter_panel').find('.propertiesArea').parent().css('width',spliterLength);
							}*/
                        }
                        var rightTab = $('#rightTab' + self.encodeId);
                        if (rightTab.width() > 0) {
                            if ($('#rightTab' + self.encodeId + ' .active a')[0]) {
                                var propDivId = $('#rightTab' + self.encodeId + ' .active a')[0].hash;
                                if (propDivId !== null && $('#rightTab .dropdown .active a')[0]) {
                                    propDivId = $('#rightTab .dropdown .active a')[0].hash;
                                }
                                var propNode = $('' + propDivId);
                            }
                        }
                        if (rightTab.width() > 10 && rightTab.children().last()[0].classList.contains('dropdown') /*&& rightTab.height() < 50*/) {
                            var dropdownDiv = rightTab.children().last();
                            var appendList = dropdownDiv.find('li');
                            var removedList = [];
                            for (var i = 0; i < appendList.length; i++) {
                                dropdownDiv.before(appendList[i]);
                                removedList.push(appendList[i]);
                            }
                            if (appendList.length === removedList.length) {
                                dropdownDiv.remove();
                            }
                        }
                        if (rightTab.width() > 10 && rightTab.width() < 415 && rightTab.children().length > 2/* && rightTab.height() > 50*/) {
                            var rightChildren = rightTab.children();
                            var appendList = [];
                            for (var i = rightChildren.length; i > 0; i--) {
                                if (!rightChildren[i - 1].classList.contains('dropdown')) {
                                    appendList.push(rightChildren[i - 1]);
                                    if (rightTab.width() < 375 && rightChildren[i - 2]) {
                                        appendList.push(rightChildren[i - 2]);
                                        break;
                                    } else {
                                        break;
                                    }
                                }
                            }
                            if (!rightChildren.last()[0].classList.contains('dropdown')) {
                                rightTab.append('<li class="dropdown"><a class="dropdown-toggle" data-toggle="dropdown" href="#"><span class="caret"></span></a><ul style="right:0" class="dropdown-menu pull-right"></ul></li>');
                            }
                            for (var i = 0; i < appendList.length; i++) {
                                rightTab.children().last().find('ul').prepend(appendList[i]);
                            }
                        }
                        if (rightTab.children().last()[0] && rightTab.children().last()[0].classList.contains('dropdown') && propNode && propNode.parent()[0] && propNode.parent()[0].classList.contains('tab-content')) {
                            var dropdownDiv = rightTab.children().last();
                            var appendList = dropdownDiv.find('li');
                            for (var i = 0; i < appendList.length; i++) {
                                appendList.eq(i).removeClass('active');
                            }
                        }
                        if ($('#mainTab' + self.encodeId).find('.active a span').text() === DataManager.getDataManager().get('localeManager').get('StrategyMap')) {
                            //self.modeler.get("canvas").zoom("fit-viewport", true);
                            self.initializePresentation();
                        }
					}, 300);
                }
			};
			this.getNumberOfCapabilityMappings = function (mid, type, event) {
				var shapes = self.modeler._definitions.rootElements[0].flowElements;
				var count = 0;

				for (var i = 0; i < shapes.length; i++) {
					if (event && event.type === 'shape.remove' && event.element.id === shapes[i].id) {
						continue;
					}
					if (type !== 'vdml:BusinessItem' || type !== "vdml:DeliverableFlow") {
						if (shapes[i].get('mcid') === mid) {
							count++;
						}
						var flowElements = shapes[i].get('flowElements');
						if (flowElements) {
							for (var j = 0; j < flowElements.length; j++) {
								if (flowElements[j].get('mcid') === mid) {
									count++;
								}
							}
						}
					} else {
						var flows = shapes.get('flows');
						if (flows) {
							for (var j = 0; j < flows.length; j++) {
								if (flows[j].get('mcid') === mid) {
									count++;
								}
							}
						}
					}
				}
				return count;
			}
            this.getNumberOfMappings = function (mid, type, event) {
                var shapes = self.modeler._definitions.rootElements[0].flowElements;
                var count = 0;

                for (var i = 0; i < shapes.length; i++) {
                    if (event && event.type === 'shape.remove' && event.element.id === shapes[i].id) {
                        continue;
                    }
					if (type !== 'vdml:BusinessItem' || type !== "vdml:DeliverableFlow") {
                        if (shapes[i].get('mid') === mid) {
                            count++;
                        }
                        var flowElements = shapes[i].get('flowElements');
                        if (flowElements) {
                            for (var j = 0; j < flowElements.length; j++) {
                                if (flowElements[j].get('mid') === mid) {
                                    count++;
                                }
                            }
                        }
                    } else {
						var flows = shapes.get('flows');
						if (flows) {
							for (var j = 0; j < flows.length; j++) {
								if (flows[j].get('mid') === mid) {
									count++;
								}
							}
						}
                    }
                }
                return count;
            }
            
            this.getCommonCodeContainer = function (callback) {
                var plan = DataManager.getDataManager().get('currentPlan');
                plan.getCommonScenario(function (commonScenario) {
                    var actualObs = commonScenario.get('contextObservation') ? commonScenario.get('contextObservation').findWhere({ 'name': "Actual" }) : "";
                    if (actualObs) {
                        var codeContainerId = actualObs.get('codeContainerId');
                        self.commonCodeContainer = codeContainerId;
                        callback(codeContainerId);
                    } else {
                        callback(null)
                    }
                });
            }
            
            this.handlePublicMenus = function () {
                var self = this;
                DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-wrench", "Show Object Explorer", $.proxy(this.showobjectExplorerTree, this));
                DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-check", "Validation", $.proxy(this.autoValidation, this));
            };
            this.autoValidation = function () {
                var self = this;
                var addOptions = this;
                var EcoMapDiagramMixin = Backbone.Relational.store.getObjectByName("vdml.EcoMapDiagramMixin");
                window.getAndCreateModalDialog(true, self.encodeId, EcoMapDiagramMixin, self.model, "mapValidation", closeView, addOptions);
                function closeView() {
                    window.cleanDialogModel(self.encodeId, EcoMapDiagramMixin);
                }
            };
            this.showobjectExplorerTree = function () {
                var vdm = self.model.getNestedParent();
                var addOptions = this;
                var id = htmlEscape(window.guidGenerator());
                window.getAndCreateModalDialog(true, id, StrategyMapDiagramMixin, vdm, "explorerModelJson", closeView, addOptions);
                function closeView() {
                    window.cleanDialogModel(id, StrategyMapDiagramMixin);
                }
            };

            this.resizeEcoElement = function (shapeId, newBounds) {
                var shape = self.modeler.get('elementRegistry')._elements[shapeId].element;
                self.modeler.get('modeling').resizeShape(shape, newBounds);
            };

            this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
                'name'
                , 'description'
                , 'Delete'
                , 'Complete'
                , 'Copy'
                , 'StrategyMapDetails'
                , 'Guidance'
                , 'Collaboration'
				, 'shapeDetails'
                , 'Objectives'
                , 'Shape'
                , 'ElementType'
                , 'targetElement'
                , 'Values'
                , 'StrategyMap'
                , 'PresentationType'
            ]);
        }
		
            afterRenderView(reDraw, view) {
			    var self = view;
                /*if (window.checkContextForRevision()) {
                    self.viewMode(true);
                } else {
                    self.viewMode(false);
                    //self.enableComplete(false);
                }*/
                if(!self){
                    self = this;
                }
                var container = $("#js-canvas" + self.encodeId);
                container.empty();
                /*if(redraw != true && self.showDashboardStrategyMap){
                    container.height(options.jsCanvasHeight);
                }*/
                var fontSize = '12';
                if(self.model.get('fontsize')){
                    fontSize = self.model.get('fontsize');
                }
                if (!self.editMode) {
                    self.modeler = new EcoNavigatedViewer({
                        container: container,
                        type:'vdml_StrategyMapDiagram',
                        additionalModules: [
                            {
                              moveCanvas: [ 'value', '' ],
                              zoomScroll: [ 'value', '' ]
                            }
                        ],
                        textRenderer: {
                            defaultStyle: {
                                fontSize: fontSize,
                            },
                            externalStyle: {
                                fontSize: fontSize,
                            }
                        }

                    });
                    $('#canvas-modal-footer' + self.encodeId + ' .btn-complete').hide();
                } else {
                    self.modeler = new EcoModeler({
                        keyboard: { bindTo: document },
                        container: container,
                        type: self.model.get('type'),
                        textRenderer: {
                            defaultStyle: {
                                fontSize: fontSize,
                            },
                            externalStyle: {
                                fontSize: fontSize,
                            }
                        }
                    });
                    $('#canvas-modal-footer' + self.encodeId + ' .btn-complete').show();
                    self.modeler.get('eventBus')
                        .on(['commandStack.shape.create.postExecute', 'commandStack.shape.delete.postExecute', 'connection.remove', 'connection.changed', 'shape.changed', 'shape.remove'],
                        function (event, element) {
                            if(event.type !== 'shape.changed' && event.type !== "connection.changed"){
                                self.loadTable(event);
                            }
							if (event.element && event.type == "shape.remove") {
                                var bo = event.element.businessObject;
                                var backImgUrl = bo.backgroundUrl;
                                if(backImgUrl){
                                    self.deletedCodedContainers.push(backImgUrl);
                                }
                                var mid = bo.get('mid');
                                if (mid) {
                                    var type = bo.$type;
                                    if (self.getNumberOfMappings(mid, type, event) === 0) {
                                        var modelType = self.mappingObject[type];
                                        var modelColl = self.model.get(modelType.collectionName);
                                        if (modelColl) {
                                            modelColl.remove(mid);
                                            if (self.chartViewInstance) {
												//self.chartViewInstance.initialize(self.model, true, self, self.model.get('value').models.concat());
                                            }
                                        }
                                    }
								}
								var mcid = bo.get('mcid');
								if (mcid) {
									var type = bo.$type;
									if (self.getNumberOfCapabilityMappings(mcid, type, event) === 0) {
										var modelColl = self.model.get('capability');
										if (modelColl) {
											modelColl.remove(mcid);
										}
									}
								}
                            }
                            if(self.customTrigger){
                                self.customTrigger = false;
                            } else {
                                self.enableComplete(true);
                            }                            
                        });
                }
                self.openDiagram(self.model.get('data'),reDraw);
                if (!self.showDashboardStrategyMap) {
                    //self.chartViewInstance.initialize(self.model, false, self, self.model.get('value').models.concat());
                    $('#maintab' + self.encodeId).show();
					self.spliter = $("#splitPanels" + self.encodeId).enhsplitter({ minSize: 0, onDrag: window.utils.splitterBoundary, collapse: 'right', position: '64%', handle: 'block', splitterSize: '9px', height: $("#js-canvas" + self.encodeId).outerHeight() + $('.table-responsive').outerHeight(), onDragEnd: self.setResizeHeight });
                    self.spliter.enhsplitter('collapse');
                    $(window).resize(function (view, event) {
                        this.timer = clearTimeout();
                        this.timer = setTimeout(function () { self && self.setResizeHeight && self.setResizeHeight(); }, 200);
                    });
                    $('#mainTab' + self.encodeId + ' a').click(function (e) {
                        e.preventDefault();
						$(this).tab('show');
						self.completeButton(true);
						if($(this)[0].className === "ChartValues" || $(this)[0].className === "Objectives"){
                            self.completeButton(false);
                        }
                        if ($(this)[0].className === "Objectives") {
                            self.strategyMapObjectiveViewModel.getValueElement(function(){
                                //self.strategyMapObjectiveViewModel.loadValues(self);
                                self.strategyMapObjectiveViewModel.loadTable(self);
                                //self.setResizeHeight();
                            });
                        }
                        //self.zoomButton(false);
                        if ($(this)[0].className === "ChartValues") {
                            self.chartViewInstance.initialize(self.model, false, self, self.model.get('value').models.concat());
                        }
                        if ($(this)[0].className === "ShapeDetails") {
                            self.loadTable();
                            self.setResizeHeight();
                        }
                    });
                    $('#rightTab' + self.encodeId + ' a').click(function (e) {
                        e.preventDefault();
                        if ($(this).text() === "Collaboration") {
                            return false;
                        }
                        $(this).tab('show');
                        if ($(this).text() === "Guidance") {
                            if ($('#guidanceTab' + self.encodeId).is(':empty')) {
                                $.get('js/app/version1/views/help/views/properties/StrategyMapGuidanceTemplate.html',function(viewStr){
								    self.countGuidanceClicks++;
									$('#guidanceTab' + self.encodeId).empty();
									$('#guidanceTab' + self.encodeId).append(viewStr);
									if (self.configObj && self.configObj.guidance) {
										$('#guidanceTab' + self.encodeId).append(self.configObj.guidance);
									}
									window.utils.applyFontSize($('#guidanceTab' + self.encodeId));
									self.setResizeHeight();
								})
							} else {
                                self.setResizeHeight();
                            }
                        } else {
                            self.setResizeHeight();
                        }
                    });
					container.resizable({
						stop: _.bind(function (event, ui) {
							if(ui.size.height >= 370){
								var self = this;
								self.model.set('height', ui.size.height);
						    }
						}, self)
					});	
                    self.setResizeHeight();
                    const dialogConfig = {
                        title: "<div><img class='smallIcon' src='img/icons/icons_23.png'>&nbsp;&nbsp;&nbsp;"+DataManager.getDataManager().get('localeManager').get('ValueCreationArrowVideo')+"</div>",
                        videos: [
                            { id: 'MJgXuSJfi9Q', title: '', width: '25vw', height: '14vw',description:DataManager.getDataManager().get('localeManager').get('StrategyMapDescription')},
                        ],
                    };
                    DataManager.getDataManager().handleVideoClick(dialogConfig,'StrategyMapVideo');
                } else {
                    $('#splitPanels' + self.encodeId).contents().unwrap();
					$('.dashboardStrategyMap').hide();
                    $('.bjs-powered-by').hide();
                    $("#js-canvasresize"+self.encodeId).hide();
					$('#mainTab' + self.encodeId).hide();
					if(self.editMode){
						self.completeButton(true);
						$('#complete' + self.encodeId).css('margin-top','-20px')
					}else{
						self.completeButton(false);
                        //self.zoomButton(false);
					}
                    //if (redraw !== true && options.parentView && options.parentView.setResizeHeight) {// when strategymap is added
                    //    options.parentView.setResizeHeight();
                    //}
                }
            };

        
        static getInstance(model,options){
            var view = new StrategyMapDesignerViewModel(model, options);
            //view.init(model, options);
			return view;
		};
    }
path.StrategyMapDesignerViewModel = StrategyMapDesignerViewModel;