import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {CapabilityMethod} from './CapabilityMethod'
import {ApplicationCollaborationMixin} from './ApplicationCollaborationMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CapabilityMethod","appbo/vdml/ApplicationCollaborationMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CapabilityMethod,ApplicationCollaborationMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var ApplicationCollaboration = CapabilityMethod.extend(utils.customExtends({
		relations:ApplicationCollaborationMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			CapabilityMethod.prototype.initialize.apply(this, arguments);
		}
		}
		, new ApplicationCollaborationMixin()
	));
	
	ApplicationCollaboration.abstract = false;
	ApplicationCollaboration.supported = false;
	path.ApplicationCollaboration = ApplicationCollaboration;
	export {ApplicationCollaboration};
//});