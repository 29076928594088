import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {WebServiceReferenceMixin} from './WebServiceReferenceMixin'
import {ObjectReference} from './ObjectReference'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/WebServiceReferenceMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,WebServiceReferenceMixin
, ObjectReference
) {*/
    var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
	if(!ObjectReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ObjectReference){
			ObjectReference = importPath.ObjectReference;
		}
		else{
			import('./ObjectReference').then(loadedModule => {
				ObjectReference = loadedModule;
			});
			/*require(["appbo/vdml/ObjectReference"],function(loadedModule){
				ObjectReference = loadedModule;
			});*/
		}
	}
	var WebServiceReference = VdmlElement.extend(utils.customExtends({
		relations:WebServiceReferenceMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new WebServiceReferenceMixin()
	));
	
	WebServiceReference.abstract = false;
	WebServiceReference.supported = false;
	path.WebServiceReference = WebServiceReference;
	export {WebServiceReference};
//});