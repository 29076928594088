import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BeepPackage} from '../beeppackage/BeepPackage'
import {PeriodDatasetMixin} from './PeriodDatasetMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
var PeriodDataset = BeepPackage.extend(utils.customExtends({
        relations:PeriodDatasetMixin.getMixinRelations(),
        subModelTypes: PeriodDatasetMixin.getSubModelTypes(),
        initialize: function(attributes, options) {
            BeepPackage.prototype.initialize.apply(this, arguments);
        }
    }
    , new PeriodDatasetMixin()
));
//#startCustomMethods			
//#endCustomMethods
PeriodDataset.getName = function(){
    return "PeriodDataset";
};

var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
PeriodDataset.abstract = !sp2Enabled;
PeriodDataset.supported = sp2Enabled;
path.PeriodDataset = PeriodDataset;
export {PeriodDataset};
