import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
//define(["underscore", "backbone", "Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/ChangeObject","backbone-relational"],
//function(_, Backbone, Lawnchair,backboneLawnchair,ChangeObject) {

    var ChangeSet = Backbone.RelationalModel.extend({
    	relations:[
	    	/*{
				type: Backbone.HasMany, // Use the type, or the string 'HasOne' or 'HasMany'.
				key: 'changes',
				relatedModel: 'com.vbee.data.ChangeObject',
				reverseRelation: {
					key: 'changeSet',
					includeInJSON: 'id'
				}
	    	},*/
	    	{
				type: Backbone.HasOne, // Use the type, or the string 'HasOne' or 'HasMany'.
				key: 'parent',
				relatedModel: 'com.vbee.data.ChangeSet',
				includeInJSON: Backbone.Model.prototype.idAttribute,
				reverseRelation: {
					key: 'nextChangeSets',
					includeInJSON: 'id'
				}
	    	}	    	
    	],    
        defaults: function(){
            return{
	            type:"com_vbee_data_ChangeSet",
	            id:window.guidGenerator(),
	            name:"",
	            description:"",
	            timestamp: (new Date()).getTime(),
	            parent:null
	       };
        },
        initialize: function (attributes, options) {
            this.changes = [];
            Backbone.Model.prototype.initialize.apply(this, arguments);
            //var dataManager = ChangeSet.dataManager;
            if(options && options.lawnchair ){
            	this.lawnchair = options.lawnchair;
            }
        },        
        getNestedParent: function(){
        	return this;
        },
        removeAll: function(){
        	this.getAsync('nextChangeSets');
        	var nextChangeSets = this.get('nextChangeSets');
        	for(var i=0;i<nextChangeSets.length;i++){
        		nextChangeSets.at(i).removeAll();
        	}
        	this.destroy();
        },
        isPersisted:function(){
        	return true;
        }
    },
    {
/*    	setDataManager: function(dataManager){
    		ChangeSet.dataManager = dataManager;
    	}*/
    });
    export {ChangeSet};
    //return ChangeSet;
//});