import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurableElement} from '../vdml/MeasurableElement'
import {MeasurableElementInstanceMixin} from './MeasurableElementInstanceMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("instdef",global.version);

var MeasurableElementInstance = MeasurableElement.extend(utils.customExtends({
        relations:MeasurableElementInstanceMixin.getMixinRelations(),
        initialize: function(attributes, options) {
            MeasurableElement.prototype.initialize.apply(this, arguments);
            
        }
    }
    , new MeasurableElementInstanceMixin()
));
MeasurableElementInstance.abstract = true;
path.MeasurableElementInstance = MeasurableElementInstance;
export {MeasurableElementInstance};
