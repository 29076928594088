import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmElement} from './SmmElement'
import {ObservationScopeMixin} from './ObservationScopeMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Observation } from './Observation'
/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/ObservationScopeMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,ObservationScopeMixin
, Observation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Observation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Observation){
			Observation = importPath.Observation;
		}
        else {
            import('./Observation').then(({ default: Observation }) => {
                Observation = Observation;
            });
           /* require(["appbo/smm/Observation"], function (loadedModule) {
                Observation = loadedModule;
			});*/
		}
	}
	var ObservationScope = SmmElement.extend(utils.customExtends({
		relations:ObservationScopeMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			SmmElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new ObservationScopeMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	ObservationScope.abstract = false;
	ObservationScope.supported = !sp2Enabled;
	path.ObservationScope = ObservationScope;
	export {ObservationScope};
//});