import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {Capability} from './Capability'
import {CapabilityDependency} from './CapabilityDependency'
import {CapabilityLibraryDiagram} from './CapabilityLibraryDiagram'
import {DataManager} from '../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../libs/bootbox/bootbox'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, Capability
, CapabilityDependency
, CapabilityLibraryDiagram){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Capability){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Capability){
			Capability = importPath.Capability;
		}
		else{
			import('./Capability').then(loadedModule => {
			//	Capability = loadedModule;
			});
			/*require(["appbo/vdml/Capability"],function(loadedModule){
				Capability = loadedModule;
			});*/
		}
	}
	if(!CapabilityDependency){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityDependency){
			CapabilityDependency = importPath.CapabilityDependency;
		}
		else{
			import('./CapabilityDependency').then(loadedModule => {
		//		CapabilityDependency = loadedModule;
			});
			/*require(["appbo/vdml/CapabilityDependency"],function(loadedModule){
				CapabilityDependency = loadedModule;
			});*/
		}
	}
	if(!CapabilityLibraryDiagram){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityLibraryDiagram){
			CapabilityLibraryDiagram = importPath.CapabilityLibraryDiagram;
		}
		else{
			import('./CapabilityLibraryDiagram').then(loadedModule => {
			//	CapabilityLibraryDiagram = loadedModule;
			});
			/*require(["appbo/vdml/CapabilityLibraryDiagram"],function(loadedModule){
				CapabilityLibraryDiagram = loadedModule;
			});*/
		}
	}
	export class CapabilityLibraryMixin {

	defaults(){
		var ret = {
			type: "vdml_CapabilityLibrary"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CapabilityLibrary",
			key:"capability",
			relatedModel:"vdml.Capability",
			reverseRelation: {
				key:"capabilityOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CapabilityLibrary",
			key:"capabilityDependency",
			relatedModel:"vdml.CapabilityDependency",
			reverseRelation: {
				key:"capabilityDependencyOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	
	static getCumulativeMixinRelations(){
		if (!CapabilityLibraryMixin.cummulativeRelations) {
            CapabilityLibraryMixin.cummulativeRelations = _.union(CapabilityLibraryMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return CapabilityLibraryMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
            { name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("capabilityLibraryOwner") ? this.get("capabilityLibraryOwner") : this.previousAttributes().capabilityLibraryOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("capabilityLibraryOwner") ? this.get("capabilityLibraryOwner") : this.previousAttributes().capabilityLibraryOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties (type) {
		return CapabilityLibraryMixin.getDialogViewProperties(type);
	};
	static getDialogViewProperties(type){
		if(type==="explorerModelJson"){
			return{
				templatePath : "views/beeppackage/views/properties/explorerModelJsonTemplate.html",
				templateName : "explorerModelJsonTemplate",
				viewTypeStr : "appviews/beeppackage/views/properties/explorerModelJsonViewModel",
				tabId: "explorerModelJsonView",
				tabName: "explorerModelJson"
			}
		}
		else{
		return {
			templatePath: "views/capabilityLibrary/views/properties/CapabilityLibraryTemplate.html",
			templateName: "CapabilityLibraryTemplate",
			viewTypeStr: "appviews/capabilityLibrary/views/properties/CapabilityLibraryViewModel",
			tabId: "CapabilityLibraryView",
			tabName: "CapabilityLibraryView"
		}
		}
	}
	static showCapLibList(planModel, files, selectionCallback){
                var htmlContent = "<b>List of Model(s)<span style= 'padding-left:58%'></span></b><br/>";
                htmlContent +=  "<table class='table table-bordered' style = 'width:450px;margin-left:50px'>";
                htmlContent +=  "<thead><tr><th><input type = 'checkbox' checked id='checkAllCapLibs'></th><th>Model(s)</th><th>Type</th></tr></thead>";
                htmlContent +=  "<tbody>";
                var capLibPackId = null;
                var planId = planModel.id.substr(planModel.id.lastIndexOf('@') + 1)
                var commonAltId = "@" + planId + "-Common@";var existCapPackId;
                async.eachSeries(files, function(file, fileCallBack){
                    if(file.type === "vdml_ValueDeliveryModel" && (file.capabilityLibrary || file.diagrams || file.valueLibrary)){
                        capLibPackId = file.id.substr(file.id.lastIndexOf('@') + 1);
                        capLibPackId = commonAltId + capLibPackId;
						debugger  //set ownerId or sourceId or documentId
                        var altRDFModel = DataManager.getDataManager().getRDFModel(commonAltId);
                        if(altRDFModel) {
                            altRDFModel.getTypeOfSubject(capLibPackId,function(isExist){
                                    existCapPackId = isExist ? capLibPackId : "";
                                    altRDFModel.getDiagramsfromPackage(capLibPackId,"vdml_ValueDeliveryModel-capabilityLibrary",function(res){
                                            var existLibList =[];
                                            if(res && res.length >0){
                                                existLibList = res;
                                            }
                                            if(file.capabilityLibrary){
                                                for(var i=0;i<file.capabilityLibrary.length;i++){
                                                    var addDisable = "";
                                                    for(var j=0;j<existLibList.length;j++){
                                                        if(window.utils.getSuffix(file.capabilityLibrary[i].id) === window.utils.getSuffix(existLibList[j].id)){
                                                            addDisable = "disabled";
                                                            break;
                                                        }
                                                    }
                                                    htmlContent += "<tr><td><span style = 'padding-left:5px'><input type='checkbox' class = 'listOfCapLibs'  value='" + file.capabilityLibrary[i].id + "' mapType='" + file.capabilityLibrary[i].type + "' parentId = '"+file.id+"' style=\"cursor: pointer;\" checked/ "+addDisable+"><span></td><td>" + file.capabilityLibrary[i].name + "</td><td>" + window.utils.getMapName(file.capabilityLibrary[i].type) + "</td></tr>";
                                                }
                                            }
                                            altRDFModel.getDiagramsfromPackage(capLibPackId,"vdml_ValueDeliveryModel-diagrams",function(res){
                                                    var existMapList =[];
                                                    if(res && res.length >0){
                                                        existMapList = res;
                                                    }
                                                    if(file.diagrams){
                                                        for(var a=0;a<file.diagrams.length;a++){
                                                            var addDisable = "";
                                                            for(var b=0;b<existMapList.length;b++){
                                                                if(window.utils.getSuffix(file.diagrams[a].id) === window.utils.getSuffix(existMapList[b].id)){
                                                                    addDisable = "disabled";
                                                                    break;
                                                                }
                                                            }
                                                            htmlContent += "<tr><td><span style = 'padding-left:5px'><input type='checkbox' class = 'listOfCapLibs'  value='" + file.diagrams[a].id + "' mapType='" + file.diagrams[a].type + "' parentId = '"+file.id+"' style=\"cursor: pointer;\" checked / "+addDisable+"><span></td><td>" + file.diagrams[a].name + "</td><td>" + window.utils.getMapName(file.diagrams[a].type) + "</td></tr>";
                                                        }
                                                    }
                                                    altRDFModel.getDiagramsfromPackage(capLibPackId,"vdml_ValueDeliveryModel-valueLibrary",function(res){
                                                        var existValList =[];
                                                        if(res && res.length >0){
                                                            existValList = res;
                                                        }
                                                        if(file.valueLibrary){
                                                            for(var a=0;a<file.valueLibrary.length;a++){
                                                                var addDisable = "";
                                                                for(var b=0;b<existValList.length;b++){
                                                                    if(window.utils.getSuffix(file.valueLibrary[a].id) === window.utils.getSuffix(existValList[b].id)){
                                                                        addDisable = "disabled";
                                                                        break;
                                                                    }
                                                                }
                                                                htmlContent += "<tr><td><span style = 'padding-left:5px'><input type='checkbox' class = 'listOfCapLibs'  value='" + file.valueLibrary[a].id + "' mapType='" + file.valueLibrary[a].type + "' parentId = '"+file.id+"' style=\"cursor: pointer;\" checked / "+addDisable+"><span></td><td>" + file.valueLibrary[a].name + "</td><td>" + window.utils.getMapName(file.valueLibrary[a].type) + "</td></tr>";
                                                            }
                                                        }
                                                    });
                                                    fileCallBack();
                                            });
                                    });
                            })
                        }else{
                        	fileCallBack();
                        }
                    }else{
                        fileCallBack();
                    }
                },function(err){	
                    htmlContent +=  "</tbody></table>"
                
                    if(capLibPackId){
                        var dialog = bootbox.dialog({
                            title: "<div>&nbsp;&nbsp;Select Model(s) to import ? </div>",
                            message: htmlContent,
                            buttons: {
                                main: {
                                label: "Close",
                                className: "btn btn-default",
                                },
                                success: {
                                    label: '<i id="importExternalPackagesBtn"></i> Complete',
                                    className: "btn btn-complete complete-btn",
                                    callback: function () {
                                        window.utils.startSpinner('importCapabilityLibraryPackage', "Importing Library Package ... ");
                                        var selectedMapList =[];
                                        for(var list =0;list<$('.listOfCapLibs').length;list++){
                                            if($('.listOfCapLibs')[list].checked && !$('.listOfCapLibs')[list].disabled){
                                                selectedMapList.push({id:$('.listOfCapLibs')[list].value, type : $('.listOfCapLibs')[list].getAttribute('mapType'), parentId : $('.listOfCapLibs')[list].getAttribute('parentId')})
                                            }
                                        }
                                        selectionCallback(true,selectedMapList,existCapPackId);
                                    }
                                }
                            }
                        });
                        dialog.attr("id", "bootboxForVS_id");
                        $('#bootboxForVS_id').find('.bootbox-body').css('height','500px');
                        $('#bootboxForVS_id').find('.bootbox-body').css('overflow-y','scroll');
                    }else{
                        selectionCallback(false,"","","");
                    }
                });
                $('input[id="checkAllCapLibs"]').change(function(event,checked) {
                    var vsList = $('.listOfCapLibs');
                    for(var i=0;i<vsList.length;i++){
                        if(!vsList[i].disabled) {
                            vsList[i].checked = event.currentTarget.checked;
                        }
                    }
                })
        }

		static createCapLibFromFiles(beepPackages, model, finalCallback, selectedCapList, existCapPackId) {
			var newclPackId = model.id;
			var newclPackSuffix = newclPackId.substr(newclPackId.lastIndexOf('@') + 1);
			var commonAlt = '@' + newclPackSuffix + "-Common@";
			var replaceIds = {};
			function replacePackageIds(pack) {
				var keys = Object.getOwnPropertyNames(replaceIds);
				var packStr = JSON.stringify(pack);
				for (var k = 0; k < keys.length; k++) {
					var re = new RegExp(keys[k], 'g');
					packStr = packStr.replace(re, replaceIds[keys[k]]);
				}
				return JSON.parse(packStr);
			}
			for (var i = 0; i < beepPackages.length; i++) {
				var currentPackAlt = beepPackages[i].id.substr(0, beepPackages[i].id.lastIndexOf('@') + 1);
				if(beepPackages[i].type == "beeppackage_CodeContainer"){
					replaceIds[currentPackAlt] = commonAlt;
					beepPackages[i] = replacePackageIds(beepPackages[i]);
				}else if(beepPackages[i].type === "vdml_ValueDeliveryModel"){
				    replaceIds[currentPackAlt] = commonAlt;
					beepPackages[i] = replacePackageIds(beepPackages[i]);
					if(selectedCapList){
					  selectedCapList = replacePackageIds(selectedCapList);
					}else{
						selectedCapList = {};
					}
				}
			}
			
			var vocabularyAlts = [];
			vocabularyAlts.push(commonAlt);
			var workspaces = {};
			DataManager.getDataManager().set('suppressLogging', true);
			async.eachSeries(vocabularyAlts, function (altId, altHandleCallback) {
				var workspaceStore = DataManager.getDataManager().get("workspaceStore");
				utils.invokeGetDefaultDirectory(workspaceStore, function (workspace) {
					workspaces[altId] = workspace;
					workspace.save();
					Backbone.Relational.store.register(workspace);
					var initialiseWorkspaceDataCount = 0;
					function getAltWorkspaceData(altId) {
						DataManager.getDataManager().getWorkspaceDataWithId(altId, function (wsData) {
							function sleepAndCheckModel() {
								if (wsData.get('rdfModel')) {
									altHandleCallback();
								} else {
									setTimeout(sleepAndCheckModel, 100);
								}
							}
							if (!wsData) {
								initialiseWorkspaceDataCount++;
								if (initialiseWorkspaceDataCount > 10) {
									altHandleCallback('Failed to initialise Workspace. Import of Library Package failed');
									return;
								} else {
									setTimeout(function () { getAltWorkspaceData(altId); }, 300);
								}
							} else {
								sleepAndCheckModel();
							}
							//altHandleCallback();	
						});
					}
					getAltWorkspaceData(altId);
				}, altId);
			}, function (err) {
				vocabularyAlts.length = 0;
				DataManager.getDataManager().set('suppressLogging', false);
				if (err) {
					bootbox.alert('' + err);
					finalCallback();
				} else {
				    createPackages(beepPackages,existCapPackId,selectedCapList);
				}
			});
			function createCapElements(element,capLib,check){
				var model = capLib.get('capability').findWhere({ name: element.name });
				if(!model){ 
					if(check){
						var CapabilityCategory = Backbone.Relational.store.getObjectByName("vdml.CapabilityCategory");
						model = new CapabilityCategory({ id: element.id, name: element.name, description: element.description, capabilityOwner: capLib });
					}else{
						var CapabilityDefinition = Backbone.Relational.store.getObjectByName("vdml.CapabilityDefinition");
						model = new CapabilityDefinition({ id: element.id, name: element.name, description: element.description, capabilityOwner: capLib });
					}
				}
				return model;
			}
            function createValElements(element,capLib,check){
				var model = capLib.get('value').findWhere({ name: element.name });
				if(!model){ 
					if(check){
                        var ValueCategory = Backbone.Relational.store.getObjectByName("vdml.ValueCategory");
						model = new ValueCategory({ id: element.id, name: element.name, description: element.description, capabilityOwner: capLib });
					}else{
                        var ValueDefinition = Backbone.Relational.store.getObjectByName("vdml.ValueDefinition");
						model = new ValueDefinition({ id: element.id, name: element.name, description: element.description, capabilityOwner: capLib });
					}
				}
				return model;
			}
			function spliceUnwanted(pack,selectedCapList,type){
				if(type === "vdml_CapabilityLibrary" && pack.capabilityLibrary && selectedCapList && selectedCapList.length == 0){
					for(var p=pack.capabilityLibrary.length-1;p>=0;p--){
						var isExist = false;
						for(var s=0;s<selectedCapList.length;s++){
							if(selectedCapList[s].type === type && pack.capabilityLibrary[p].id === selectedCapList[s].id){
							   isExist = true;
							}
						}
						if(!isExist){
							pack.capabilityLibrary.splice(p,1);
						}
					}
				}else if(type === "vdml_CapabilityLibraryDiagram" && selectedCapList && selectedCapList.length == 0){
					for(var p=pack.diagrams.length-1;p>=0;p--){
						var isExist = false;
						for(var s=0;s<selectedCapList.length;s++){
							if(selectedCapList[s].type === type && pack.diagrams[p].id === selectedCapList[s].id){
							   isExist = true;
							}
						}
						if(!isExist){
							pack.diagrams.splice(p,1);
						}
					}
				}else if(type === "vdml_ValueLibrary" && pack.valueLibrary && selectedCapList && selectedCapList.length == 0){
					for(var p=pack.valueLibrary.length-1;p>=0;p--){
						var isExist = false;
						for(var s=0;s<selectedCapList.length;s++){
							if(selectedCapList[s].type === type && pack.valueLibrary[p].id === selectedCapList[s].id){
							   isExist = true;
							}
						}
						if(!isExist){
							pack.valueLibrary.splice(p,1);
						}
					}
				}
				return pack;
			}
			function createCapLib(packModel,pack,selectedCapList){
				var packs = spliceUnwanted(pack,selectedCapList,"vdml_CapabilityLibrary")
				if(packs.capabilityLibrary && packs.capabilityLibrary.length > 0){
					for(var list =0;list<packs.capabilityLibrary.length;list++){
						var CapabilityLibrary = Backbone.Relational.store.getObjectByName('vdml.CapabilityLibrary');
						var capLib = CapabilityLibrary.getInstance(packs.capabilityLibrary[list].name,packs.capabilityLibrary[list].description,packModel,packs.capabilityLibrary[list].id);
						for(var cap=0;cap<packs.capabilityLibrary[list].capability.length;cap++){
							if(packs.capabilityLibrary[list].capability[cap].type === "vdml_CapabilityCategory"){
								var capCatModel = createCapElements(packs.capabilityLibrary[list].capability[cap],capLib,true);
							}else{
								var capCatModel = createCapElements(packs.capabilityLibrary[list].capability[cap],capLib,false);
							}
							if(capCatModel.get('parentCapability').length === 0 && packs.capabilityLibrary[list].capability[cap].parentCapability){
								var parentModel = capLib.get('capability').find({id: packs.capabilityLibrary[list].capability[cap].parentCapability[0]});
								if(parentModel){
										parentModel.get('childCapability').add(capCatModel);
										capCatModel.get('parentCapability').add(parentModel);
								}
							}
						}
					}
				}
			}
            function createValLib(packModel,pack,selectedCapList){
				var packs = spliceUnwanted(pack,selectedCapList,"vdml_ValueLibrary")
				if(packs.valueLibrary && packs.valueLibrary.length > 0){
                    var ValueLibrary = Backbone.Relational.store.getObjectByName("vdml.ValueLibrary");
					for(var list =0;list<packs.valueLibrary.length;list++){
						var capLib = ValueLibrary.getInstance(packs.valueLibrary[list].name,packs.valueLibrary[list].description,packModel,packs.valueLibrary[list].id);
						for(var cap=0;cap<packs.valueLibrary[list].value.length;cap++){
							if(packs.valueLibrary[list].value[cap].type === "vdml_ValueCategory"){
								var capCatModel = createValElements(packs.valueLibrary[list].value[cap],capLib,true);
							}else{
								var capCatModel = createValElements(packs.valueLibrary[list].value[cap],capLib,false);
							}
							if(capCatModel.get('parentValue').length === 0 && packs.valueLibrary[list].value[cap].parentCapability){
								var parentModel = capLib.get('value').find({id: packs.valueLibrary[list].value[cap].parentCapability[0]});
								if(parentModel){
										parentModel.get('childValue').add(capCatModel);
										capCatModel.get('parentValue').add(parentModel);
								}
							}
						}
					}
				}
			}
			function createCapMaps(packModel,pack,selectedCapList){
				if(pack.diagrams){
					var packs = spliceUnwanted(pack,selectedCapList,"vdml_CapabilityLibraryDiagram");
					if(packs.diagrams && packs.diagrams.length > 0){
						for(var map=0;map<packs.diagrams.length;map++){
							var newCapMap = CapabilityLibraryDiagram.getInstance(packs.diagrams[map].name, packs.diagrams[map].description, packModel, packs.diagrams[map].id)
							newCapMap.set('data',packs.diagrams[map].data);
						}
					}
				}
			}
			function createPackages(beepPackages,existCapPackId,selectedCapList) {
				var plan = DataManager.getDataManager().get('currentPlan');
				DataManager.getDataManager().set('suppressLogging', false);
				var packageModels = [];
				var serverChangeSet = DataManager.getDataManager().get(DataManager.getDataManager().CURRENT_CHANGESET);
				async.eachSeries(beepPackages, function (pack, fileHandleCallback) {
					DataManager.getDataManager().clearSaveInterval();
					var packId = pack.id;
					var altId = packId.substr(0, packId.lastIndexOf('@') + 1);
					if (pack.type === "beeppackage_CodeContainer") {
						var planId = model.id.substr(model.id.lastIndexOf('@') + 1)
				        var commonAltId = "@" + planId + "-Common@";
						var altRDFModel = DataManager.getDataManager().getRDFModel(commonAltId);
						altRDFModel.getTypeOfSubject(pack.id,function(isExist){
							if (isExist) {
							    fileHandleCallback();
							} else {
								var CodeContainerObj = Backbone.Relational.store.getObjectByName("beeppackage.CodeContainer");
								var codeContainer = CodeContainerObj.getBlobInstance(pack.name, pack.name, model, pack.id);
								var imgList = [];
								var imgTagList = [];
								if(pack.tags){
									var tagNames = pack.tags.split(',');
									for(var i=0;i<tagNames.length;i++){
										imgTagList.push({id:'',name:tagNames[i]});
									}
								}
                                imgList.push({'dataReference':pack.id,'packageId':model.id,'code':pack.code,'name':pack.name,'inputTags':imgTagList,'fullyQualifiedName':plan.name + ':'+pack.name});
                                var url = encodeURI("/vdmbee/image/save?legalEntityId="+DataManager.getDataManager().get("currentLegalEntity").entityId);
								DataManager.getDataManager().get('vmpServerService').postAllPromise(url,JSON.stringify(imgList)).then(function(imgResourceList) {
									if(imgResourceList){
										for(var key in imgResourceList){
											for(var i=0;i<beepPackages.length;i++){
												if(pack.type == "beeppackage_CodeContainer" && pack.id == key){
													codeContainer.set('dataReference',imgResourceList[key]);
													break;
												}
											}
										}
									}
									codeContainer.set('code', pack.code, {silent: true});
									fileHandleCallback();
								});
								//codeContainer.set('code', pack.code);
								//fileHandleCallback();
							}
						});
					} else if (pack.type === 'vdml_ValueDeliveryModel') { 
						DataManager.getDataManager().getWorkspaceData(workspaces[altId], function (wsData) {
							if(!existCapPackId){
								DataManager.getDataManager().set('suppressLogging', true);
								DataManager.getDataManager().set('suppressChangeLogging', true);
								var packs = spliceUnwanted(pack,selectedCapList,'vdml_CapabilityLibrary');
								packs = spliceUnwanted(packs,selectedCapList,'vdml_CapabilityLibraryDiagram');
                                packs = spliceUnwanted(packs,selectedCapList,'vdml_ValueLibrary');
								var ValueDeliveryModel = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel");
								packs['planId'] = plan.id
								var copyPck = new ValueDeliveryModel(packs, { silent: true });
								packageModels.push(copyPck);
								DataManager.getDataManager().get('initializedPackages').add({ 'id': copyPck.get('id'), 'version': parseInt(copyPck.get('version')), 'model': copyPck });
								DataManager.getDataManager().set('suppressLogging', false);
								DataManager.getDataManager().set('suppressChangeLogging', false);
								serverChangeSet = DataManager.getDataManager().addChangeObjectForFile(serverChangeSet,packs,plan);
							}else{
							    var repId = DataManager.getDataManager().getRepositoryId(pack.id);
								var vdmStore = DataManager.getDataManager().getVDMStore(repId);
								DataManager.getDataManager().fetchDocumentFromPackage(pack.id, 'vdml_ValueDeliveryModel', pack.version, pack.id, 'vdml_ValueDeliveryModel', vdmStore, {
									success: function (model) {
										createCapLib(model,pack,selectedCapList);
                                        createValLib(model,pack,selectedCapList);
								        createCapMaps(model,pack,selectedCapList);
										packageModels.push(model);
									},
									error: function (error) {
										console.log('Unable to load selected Package');
									}
								}, true, null);
							}
							fileHandleCallback();
						});
					}else{
					  fileHandleCallback();
					}
				}, function () {
                    /*for(var i=0;i<beepPackages.length;i++){
                        serverChangeSet = DataManager.getDataManager().addChangeObjectForFile(serverChangeSet,beepPackages[i],plan);
                    }*/                    
					beepPackages.length = 0;
					async.eachSeries(packageModels, function (packModel, generatedRDF) {
						for(var j=packModel.get('diagrams').length-1;j>=0;j--){
							if(packModel.get('diagrams').at(j).get('data')){   // For removing mid's if CapLib is not exist
							    var xml = packModel.get('diagrams').at(j).get('data');
								if(xml){
									var xmlDoc = jQuery.parseXML(xml);
									var $xml = jQuery(xmlDoc);	
									var nodes = $xml.find('[mid]');
									nodes.each(function (callback, node) {
                                        var cap = window.utils.getElementModel(node.getAttribute('mid'),['vdml.CapabilityCategory','vdml.CapabilityDefinition']);
										if(cap){
											var parentID = cap.getNestedParent().get('id');
										}
										if(!cap || parentID != packModel.id){
											if(cap){
											   packModel.get('diagrams').at(j).get('capabilities').remove(cap.id);
											}
											node.setAttribute('mid',"");
											node.setAttribute('mpid',"");
										}
									});
									xml = (new XMLSerializer()).serializeToString(xmlDoc);
									packModel.get('diagrams').at(j).set('data',xml);
								}
							}
						}
						var packId = packModel.get('id');
						var altId = packId.substr(0, packId.lastIndexOf('@') + 1);
						DataManager.getDataManager().getWorkspaceData(workspaces[altId], function (wsData) {
							var packRDFModel = DataManager.getDataManager().getRDFModel(altId);
							function checkAndAddRDFForModel() {
								if (packRDFModel) {
									addRDFFormModel();
								} else {
									setTimeout(checkAndAddRDFForModel, 10);
								}
							}
							function addRDFFormModel() {
								packRDFModel.addRDFForModel(packModel, function () {
									function savePackageRDF() {
										//packRDFModel.save();
										DataManager.getDataManager().getWorkspaceDataWithId(altId, function (wsData) {
											var copyAltVDMStore = wsData.get('vdmStore');
											packModel.lawnchair = copyAltVDMStore;
											DataManager.getDataManager().checkForCorrection(packModel, [], function () {
												//packModel.save();
												generatedRDF();
											});
										});
									}
									savePackageRDF();
								}, true);
							}
							checkAndAddRDFForModel();
						});
					}, function () {
						packageModels.length = 0;
                        DataManager.getDataManager().saveChangeSetToBackend(serverChangeSet,function(){// needed?
                            finalCallback();
                        });
					});
				});
			}
		}
	
	}
	path.CapabilityLibraryMixin = CapabilityLibraryMixin;
	//return CapabilityLibraryMixin;
//});