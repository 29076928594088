import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {CollaborationDiagram} from './CollaborationDiagram'
import {CmmnDiagramMixin} from './CmmnDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/CmmnDiagramMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,CmmnDiagramMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var CmmnDiagram = CollaborationDiagram.extend(utils.customExtends({
		relations:CmmnDiagramMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			CollaborationDiagram.prototype.initialize.apply(this, arguments);
		}
		}
		, new CmmnDiagramMixin()
	));
    CmmnDiagram.getInstance = function (name, description, owner) {
        var eid = DataManager.getDataManager().guidGeneratorByOwner(owner);
        var processMap = new CmmnDiagram({ id: eid, name: owner.getUniqueProperty('name', name, 'diagrams'), description: owner.getUniqueProperty('description', description, 'diagrams'), diagramOwner: owner });
        return processMap;
    }
    var caseTitle = "CMMN Model";
    CmmnDiagram.prototype.getSystemTags = function () {
        return ['VDM', caseTitle, 'Prototype'];
    };
	CmmnDiagram.abstract = false;
	CmmnDiagram.supported = true;
	path.CmmnDiagram = CmmnDiagram;
	export {CmmnDiagram};
//});