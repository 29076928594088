import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Collaboration} from './Collaboration'
import {CapabilityMethodMixin} from './CapabilityMethodMixin'
/*import {Performer} from './Performer'
import {CapabilityOffer} from './CapabilityOffer'
import {OrgUnit} from './OrgUnit'
import {PracticeDefinition} from './PracticeDefinition'*/
/*import {BusinessItem} from './BusinessItem'
import {CaseReference} from './CaseReference'
import {ProcessReference} from './ProcessReference'
import {ApplicationInterface} from './ApplicationInterface'
import {WebServiceReference} from './WebServiceReference'
import {Capability} from './Capability'*/
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Collaboration","appbo/vdml/CapabilityMethodMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Collaboration,CapabilityMethodMixin
, Performer
, CapabilityOffer
, OrgUnit
, PracticeDefinition
, BusinessItem
, CaseReference
, ProcessReference
, ApplicationInterface
, WebServiceReference
, Capability){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
//	if(!Performer){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Performer){
			Performer = importPath.Performer;
		}
		else{
			import('./Performer').then(loadedModule => {
			//	Performer = loadedModule;
			});
			/*require(["appbo/vdml/Performer"],function(loadedModule){
				Performer = loadedModule;
			});*/
		}
//	}
//	if(!CapabilityOffer){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityOffer){
			//CapabilityOffer = importPath.CapabilityOffer;
		}
		else{
			/*import('./CapabilityOffer').then(loadedModule => {
				CapabilityOffer = loadedModule;
			});
			require(["appbo/vdml/CapabilityOffer"],function(loadedModule){
				CapabilityOffer = loadedModule;
			});*/
		}
//	}
//	if(!OrgUnit){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OrgUnit){
			OrgUnit = importPath.OrgUnit;
		}
		else{
			/*import('./OrgUnit').then(loadedModule => {
				OrgUnit = loadedModule;
			});
			require(["appbo/vdml/OrgUnit"],function(loadedModule){
				OrgUnit = loadedModule;
			});*/
		}
//	}
//	if(!PracticeDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PracticeDefinition){
			PracticeDefinition = importPath.PracticeDefinition;
		}
		else{
			/*import('./PracticeDefinition').then(loadedModule => {
				PracticeDefinition = loadedModule;
			});
			require(["appbo/vdml/PracticeDefinition"],function(loadedModule){
				PracticeDefinition = loadedModule;
			});*/
		}
//	}
//	if(!BusinessItem){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItem){
			BusinessItem = importPath.BusinessItem;
		}
		else{
			import('./BusinessItem').then(loadedModule => {
	//			BusinessItem = loadedModule;
			});
			/*require(["appbo/vdml/BusinessItem"],function(loadedModule){
				BusinessItem = loadedModule;
			});*/
		}
//	}
	//if(!CaseReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CaseReference){
			//CaseReference = importPath.CaseReference;
		}
		else{
			/*import('./CaseReference').then(loadedModule => {
				CaseReference = loadedModule;
			});
			require(["appbo/vdml/CaseReference"],function(loadedModule){
				CaseReference = loadedModule;
			});*/
		}
	//}
	//if(!ProcessReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ProcessReference){
			//ProcessReference = importPath.ProcessReference;
		}
		else{
			/*import('./ProcessReference').then(loadedModule => {
				ProcessReference = loadedModule;
			});
			require(["appbo/vdml/ProcessReference"],function(loadedModule){
				ProcessReference = loadedModule;
			});*/
		}
	//}
	//if(!ApplicationInterface){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ApplicationInterface){
			//ApplicationInterface = importPath.ApplicationInterface;
		}
		else{
			/*import('./ApplicationInterface').then(loadedModule => {
				ApplicationInterface = loadedModule;
			});
			require(["appbo/vdml/ApplicationInterface"],function(loadedModule){
				ApplicationInterface = loadedModule;
			});*/
		}
	//}
	//if(!WebServiceReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.WebServiceReference){
			//WebServiceReference = importPath.WebServiceReference;
		}
		else{
			/*import('./WebServiceReference').then(loadedModule => {
				WebServiceReference = loadedModule;
			});
			require(["appbo/vdml/WebServiceReference"],function(loadedModule){
				WebServiceReference = loadedModule;
			});*/
		}
	//}
	//if(!Capability){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Capability){
			//Capability = importPath.Capability;
		}
		else{
			import('./Capability').then(loadedModule => {
	//			Capability = loadedModule;
			});
			/*require(["appbo/vdml/Capability"],function(loadedModule){
				Capability = loadedModule;
			});*/
		}
	//}
	var CapabilityMethod = Collaboration.extend(utils.customExtends({
		relations:CapabilityMethodMixin.getMixinRelations(),
		subModelTypes: {
			vdml_ProcessCollaboration : 'vdml.ProcessCollaboration',
			vdml_CaseCollaboration : 'vdml.CaseCollaboration',
			vdml_ApplicationCollaboration : 'vdml.ApplicationCollaboration'
		},
		initialize: function(attributes, options) {
			Collaboration.prototype.initialize.apply(this, arguments);
		}
		}
		, new CapabilityMethodMixin()
	));
	
	CapabilityMethod.abstract = false;
	path.CapabilityMethod = CapabilityMethod;
	export {CapabilityMethod};
//});