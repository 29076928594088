import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {NamedPlanElement} from './NamedPlanElement'
import {NamedPlanElementMixin} from './NamedPlanElementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import {User} from '../tickets/User'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/transformation/NamedPlanElement","appbo/transformation/NamedPlanElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,NamedPlanElement,NamedPlanElementMixin
//, User
//){
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
	if(!User){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.User){
			User = importPath.User;
		}
        else {
            import('../tickets/User').then(loadedModule => {
                User = loadedModule;
            });
			/*require(["appbo/tickets/User"],function(loadedModule){
				User = loadedModule;
			});*/
		}
	}
export class BranchReferenceMixin{
	defaults(){
		var ret = {
			type: "transformation_BranchReference"
		}
		return jQuery.extend(NamedPlanElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"transformation_BranchReference",
			key:"user",
			relatedModel:"tickets.User",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!BranchReferenceMixin.cummulativeRelations) {
            BranchReferenceMixin.cummulativeRelations = _.union(BranchReferenceMixin.getMixinRelations()
                , NamedPlanElementMixin.getCumulativeMixinRelations()
            );
        }
		return BranchReferenceMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"transformation_NamedPlanElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("branchReferenceOwner") ? this.get("branchReferenceOwner") : this.previousAttributes().branchReferenceOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("phasePackageBranchOwner") ? this.get("phasePackageBranchOwner") : this.previousAttributes().phasePackageBranchOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("phasePackageBranchOwner") ? this.get("phasePackageBranchOwner") : this.previousAttributes().phasePackageBranchOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/transformation/views/properties/BranchReferencePropertiesTemplate.html",
			templateName : "BranchReferencePropertiesTemplate",
			viewTypeStr : "appviews/transformation/views/properties/BranchReferenceViewModel",
			tabId : "BranchReferenceView",
			tabName: "BranchReference"
		}
	}
	//
	//return BranchReferenceMixin;
}
path.BranchReferenceMixin = BranchReferenceMixin;