import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Collaboration} from './Collaboration'
import {CollaborationMixin} from './CollaborationMixin'
//import {Party} from './Party'
import {BusinessModelPortfolio} from './BusinessModelPortfolio'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Collaboration","appbo/vdml/CollaborationMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Collaboration,CollaborationMixin
, Party
, BusinessModelPortfolio){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	//if(!Party){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Party){
			Party = importPath.Party;
		}
		else{
			import('./Party').then(({ default: Party }) => {
                Party = Party;
            });
			/*require(["appbo/vdml/Party"],function(loadedModule){
				Party = loadedModule;
			});*/
		}
	//}
	if(!BusinessModelPortfolio){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModelPortfolio){
			BusinessModelPortfolio = importPath.BusinessModelPortfolio;
		}
		else{
			import('./BusinessModelPortfolio').then(({ default: BusinessModelPortfolio }) => {
                BusinessModelPortfolio = BusinessModelPortfolio;
            });
			/*require(["appbo/vdml/BusinessModelPortfolio"],function(loadedModule){
				BusinessModelPortfolio = loadedModule;
			});*/
		}
	}
	export class BusinessNetworkMixin {

	defaults(){
		var ret = {
			type: "vdml_BusinessNetwork"
		}
		return jQuery.extend(Collaboration.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessNetwork",
			key:"party",
			relatedModel:"vdml.Party",
			reverseRelation: {
				key:"partyOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessNetwork",
			key:"BusinessModelPortfolio",
			relatedModel:"vdml.BusinessModelPortfolio",
			reverseRelation: {
				key:"businessModelPortfolioOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!BusinessNetworkMixin.cummulativeRelations) {
            BusinessNetworkMixin.cummulativeRelations = _.union(BusinessNetworkMixin.getMixinRelations()
                , CollaborationMixin.getCumulativeMixinRelations()
            );
        }
		return BusinessNetworkMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_Collaboration"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "progress", type: "double", defaultValue: "0", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
    }
    calculateProgress () {

    }
	getParent(){
		var container;
		return Collaboration.prototype.getParent.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BusinessNetworkPropertiesTemplate.html",
			templateName : "BusinessNetworkPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BusinessNetworkViewModel",
			tabId : "BusinessNetworkView",
			tabName: "BusinessNetwork"
		}
	}
//#startCustomMethods	
	getNestedParent(){
		return Collaboration.prototype.getNestedParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return Collaboration.prototype.getPackagePath.apply(this, arguments);
	}
	createDefaultRole(name,existingRoleId){
		var uniqueName = this.getUniqueProperty('name',name ? name: "My Business Role",'collaborationRole');
		if(uniqueName != name){
			existingRoleId = null;
		}
		var Party = Backbone.Relational.store.getObjectByName('vdml.Party');
		var roleId = existingRoleId?existingRoleId:DataManager.getDataManager().guidGeneratorByOwner(this);
		var role = new Party({id:roleId,name:uniqueName,collaborationRoleOwner:this});
		return role;
	};	
	
	//BusinessNetworkMixin.prototype.setCollborationName(newName){
	//	var self = this;
 //       DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_ParticipantNetwork-collaboration', 'vdml_ParticipantNetwork', "vbc:" + "cmof_EObject-name", 
	//	function(pNetworks){	
	//		pNetworks.each(function(pNetwork){
	//			if(pNetwork.get('name') !== newName){
	//				pNetwork.set('name',newName);
	//			}
	//		});
	//		}, true);
	//}
//#endCustomMethods
	
	}
	path.BusinessNetworkMixin = BusinessNetworkMixin;
	//return BusinessNetworkMixin;
//});