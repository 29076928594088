import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

export class PolynomialCoefficientMixin {

    defaults(){
        var ret = {
            type: "vdml_PolynomialCoefficient"
        }
        return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
    }
    static getMixinRelations(){
        return _.union([
        ])
    }
    static getCumulativeMixinRelations(){
        if (!PolynomialCoefficientMixin.cummulativeRelations) {
            PolynomialCoefficientMixin.cummulativeRelations = _.union(PolynomialCoefficientMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
        return PolynomialCoefficientMixin.cummulativeRelations.slice();
    }
    static getSuperTypes(){
        return [
            "vdml_VdmlElement"
        ];
    }
    static getProperties(){
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            {name : "sequence",type : "Int",defaultValue : "null",containingClass : "vdml_PolynomialCoefficient" },
            {name : "coefficient",type : "double",defaultValue : "null",containingClass : "vdml_PolynomialCoefficient" }
        ]
    }
    getParent(){
        var container;
        if(!container){
            container = this.get("coefficientsOwner") ? this.get("coefficientsOwner") : this.previousAttributes().coefficientsOwner;
            if(container){
                return container;
            }
        }
        return this;
    }
    getPackagePath(path){
        if(!path){
            path = [];
        }
        var container;
        if(!container){
            container = this.get("coefficientsOwner") ? this.get("coefficientsOwner") : this.previousAttributes().coefficientsOwner;
            if(container){
                path.unshift({id:this.get('id'),name:this.get('name')})
                return container.getPackagePath(path);
            }
        }
        return path;
    }
    getViewProperties(type){
        return {
            templatePath : "views/vdml/views/properties/PolynomialCoefficientPropertiesTemplate.html",
            templateName : "PolynomialCoefficientPropertiesTemplate",
            viewTypeStr : "appviews/vdml/views/properties/PolynomialCoefficientViewModel",
            tabId : "PolynomialCoefficientView",
            tabName: "PolynomialCoefficient"
        }
    }

}
path.PolynomialCoefficientMixin = PolynomialCoefficientMixin;
