import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurementRelationship} from './MeasurementRelationship'
import {RankingMeasurementRelationshipMixin} from './RankingMeasurementRelationshipMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { RankingMeasurement } from './RankingMeasurement'
import { DimensionalMeasurement } from './DimensionalMeasurement'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasurementRelationship","appbo/smm/RankingMeasurementRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurementRelationship,RankingMeasurementRelationshipMixin
, RankingMeasurement
, DimensionalMeasurement){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!RankingMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RankingMeasurement){
			RankingMeasurement = importPath.RankingMeasurement;
		}
        else {
            import('./RankingMeasurement').then(({ default: RankingMeasurement }) => {
                RankingMeasurement = RankingMeasurement;
            });
            /*require(["appbo/smm/RankingMeasurement"], function (loadedModule) {
                RankingMeasurement = loadedModule;
			});*/
		}
	}
	if(!DimensionalMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasurement){
			DimensionalMeasurement = importPath.DimensionalMeasurement;
		}
        else {
            import('./DimensionalMeasurement').then(({ default: DimensionalMeasurement }) => {
                DimensionalMeasurement = DimensionalMeasurement;
            });
           /* require(["appbo/smm/DimensionalMeasurement"], function (loadedModule) {
                DimensionalMeasurement = loadedModule;
            });*/
		}
	}
	var RankingMeasurementRelationship = MeasurementRelationship.extend(utils.customExtends({
		relations:RankingMeasurementRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			MeasurementRelationship.prototype.initialize.apply(this, arguments);
			//this.on("change:to",this.onToRemoved);
		}
		}
		, new RankingMeasurementRelationshipMixin()
	));
    RankingMeasurementRelationship["change:to"] = function (model, newTo, options) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.onToRemoved(model, newTo, options);
		}
    }
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	RankingMeasurementRelationship.abstract = false;
	RankingMeasurementRelationship.supported = !sp2Enabled;
	path.RankingMeasurementRelationship = RankingMeasurementRelationship;
	export {RankingMeasurementRelationship};
//});