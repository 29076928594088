import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {NamedPlanElement} from './NamedPlanElement'
import {BranchReferenceMixin} from './BranchReferenceMixin'
import {User} from '../tickets/User'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/transformation/NamedPlanElement","appbo/transformation/BranchReferenceMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,NamedPlanElement,BranchReferenceMixin
//, User
//){
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
	if(!User){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.User){
			User = importPath.User;
		}
        else {
            import('../tickets/User').then(loadedModule => {
                User = loadedModule;
            });
			/*require(["appbo/tickets/User"],function(loadedModule){
				User = loadedModule;
			});*/
		}
	}

	var BranchReference = NamedPlanElement.extend(utils.customExtends({
		relations:BranchReferenceMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			NamedPlanElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new BranchReferenceMixin()
	));
	
	BranchReference.abstract = false;
	BranchReference.supported = false;
	path.BranchReference = BranchReference;
	//return BranchReference;
	export {BranchReference};
//});