(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        module.exports = factory(require('jquery'));
    } else {
        factory(window.jQuery);
    }
}(function ($) {
    var countFindNext = 0;
    $.extend(true, $.summernote.lang, {
        'en-US': { /* English */
            findnreplace: {
                dialogTitle: 'Find \'N Replace',
                tooltip: 'Find \'N Replace',
                title: 'Title',
                find: 'Find All',
                replace: 'Replace All',
                findBtn: 'Find All',
                replaceBtn: 'Replace All'
            }
        }
    });
    $.extend($.summernote.options, {
        findnreplace: {
            notTime: 4800, // Time to display Notifications.
            highlight: 'border-bottom:3px solid #a8f3a3;',
            icon: '<i class="note-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" id="libre-findnreplace"><path d="m 5.8,2.3764705 c 0.941176,0 1.811765,0.376471 2.423529,1.011765 l -1.741176,1.741176 4.117647,0 0,-4.117647 -1.411765,1.411765 C 8.317647,1.5529415 7.117647,1.0117645 5.8,1.0117645 c -2.423529,0 -4.423529,1.788236 -4.752941,4.117647 l 1.388235,0 C 2.741176,3.5529415 4.129412,2.3764705 5.8,2.3764705 Z m 3.8588235,6.282353 c 0.4470585,-0.611764 0.7764705,-1.341176 0.8705885,-2.164706 l -1.388236,0 c -0.305882,1.552942 -1.694117,2.752942 -3.364705,2.752942 -0.941177,0 -1.811765,-0.376471 -2.42353,-1.011765 L 5.094118,6.4941175 1,6.4941175 1,10.611765 2.411765,9.2000005 C 3.282353,10.070589 4.482353,10.611765 5.8,10.611765 c 1.058824,0 2.047059,-0.352942 2.847059,-0.9411765 L 11.988235,12.988236 13,11.97647 9.6588235,8.6588235 Z"/></svg></i>'
        }
    });
    $.extend($.summernote.plugins, {
        'findnreplace': function (context) {
            var self = this;
            var ui = $.summernote.ui;
            var $note = context.layoutInfo.note;
            var $editor = context.layoutInfo.editor;
            var $editable = context.layoutInfo.editable;
            var options = context.options;
            var lang = options.langInfo;
            if (context.options && context.options.viewModel) {
                self.findLabel = context.options.viewModel.labels['findnReplace']();
            }
            context.memo('button.findnreplace', function () {
                var button = ui.button({
                    contents: '<svg height="13" viewBox="10 6 33 35" width="15" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none"/><path d="M22 12c2.76 0 5.26 1.12 7.07 2.93L24 20h12V8l-4.1 4.1C29.37 9.57 25.87 8 22 8 14.95 8 9.13 13.22 8.16 20h4.04c.93-4.56 4.96-8 9.8-8zm11.28 18.27c1.33-1.81 2.23-3.95 2.56-6.27H31.8c-.93 4.56-4.96 8-9.8 8-2.76 0-5.26-1.12-7.07-2.93L20 24H8v12l4.1-4.1c2.53 2.53 6.03 4.1 9.9 4.1 3.1 0 5.96-1.02 8.28-2.73L40 42.98 42.98 40l-9.7-9.73z"/></svg> ',
                    tooltip: self.findLabel,
                    click: function (e) {
                      context.invoke('findnreplace.show');
                    }
                });
                return button.render();
            });
            this.initialize = function () {
                var $container = options.dialogsInBody ? $(document.body) : $editor;
                var body = '<div class="form-group">' +
                    '<label for="note-findnreplace-title" class="control-label col-xs-2">' + lang.findnreplace.find + '</label>' +
                    '<span class="input-group col-xs-10">' +
                    '<input type="text" id="note-findnreplace-find" class="form-control note-findnreplace-find">' +
                    '</span>' +
                    '</div>' +
                    '<div id = "replaceForm" class="form-group">' +
                    '<label for="note-findnreplace-replace" class="control-label col-xs-2">' + lang.findnreplace.replace + '</label>' +
                    '<span class="input-group col-xs-10">' +
                    '<input type="text" class="form-control note-findnreplace-replace">' +
                    '</span></div>' + '<br><div style="padding-left: 80px"><input type="checkbox" id="note-findnreplace-regular">Regular Expression Search</input>' +
                    '</div>' + '<br><div style="padding-left: 80px"><input type="checkbox" id="note-findnreplace-fullword">Full Word Search</input>' +
                    '</div>' + '<br><div style="padding-left: 80px"><input type="checkbox" id="note-findnreplace-case">Case Sensitive</input>' +
                    '</div>';
                this.$dialog = ui.dialog({
                    title: lang.findnreplace.dialogTitle,
                    body: body,
                    footer: '<button href="#" class="btn btn-primary note-findnreplace-btn">' + lang.findnreplace.findBtn + '</button>' + '<button href="#" class="btn btn-primary note-findnreplace-btn note-replaceAll-btn">' + lang.findnreplace.replaceBtn + '</button>' + '<button id="findNext" class="btn btn-primary">' + "FindNext" + '</button>' + '<button id="replaceNext" class="btn btn-primary note-replace-btn">' + "Replace" + '</button>' 
                }).render().appendTo($container).draggable();
                $(".close").click(function (i) {
                    $editor.find('.note-findnreplace').contents().unwrap('span');
                })
                $("#note-findnreplace-find").keyup(function () {
                    countFindNext = 0;
                    $("#note-findnreplace-fullword").prop("disabled", false);
                    $("#note-findnreplace-case").prop("disabled", false);
                    $("#note-findnreplace-regular").prop("disabled", false);
                });
                $("#findNext").click(function (i) {             
                    $editor.find('.note-findnreplace').contents().unwrap('span');
                    var replacedText;
                    var text = $note.summernote('code');
                    var txt = document.createElement("textarea");
                    txt.innerHTML = text;
                    text = txt.value;
                    var findText = ($('.note-findnreplace-find').val()).trim();
                    var replaceText = "";
                    findText = findText.replace(/[\[|\)|\(|\]|\*|\+]/g, function (i, d) {
                        return '\\' + i;
                    });
                    var regex = (new RegExp(findText, "gi"));
                    var regex1 = (new RegExp("\\b(?:" + findText + ")\\b", "g"));
                    var regex2 = (new RegExp("\\b(?:" + findText + ")\\b", "gi"));
                    if ($("#note-findnreplace-case")["0"].checked && $("#note-findnreplace-fullword")["0"].checked) {
                        regex = regex1;
                    }
                    if ($("#note-findnreplace-fullword")["0"].checked && !$("#note-findnreplace-case")["0"].checked) {
                        regex = regex2;
                    }
                    if (!$("#note-findnreplace-fullword")["0"].checked && $("#note-findnreplace-case")["0"].checked) {
                        regex = (new RegExp(findText, "g"));
                    }
                    
                    var index = [];
                    var countOfTags = 0;
                    var tagFound = false;
                    var linkTextTag = false; 
                    for (var i = 0; i < text.length; i++) {
                        if (text[i] === '<' && !tagFound) {
                            if (text[i + 1] == 'k') {
                                linkTextTag = true;
                            }
                            index[countOfTags] = { start: i, end: null };
                            tagFound = true;
                        }
                        if (tagFound && text[i] === '>') {
                            if (!linkTextTag) {
                                index[countOfTags]["end"] = i + 1;
                                countOfTags++;
                                tagFound = false;
                            }
                            else {
                                if (text[i - 1] == 'd' && text[i - 2] == 'b' && text[i - 3] == 'k') {
                                    index[countOfTags]["end"] = i + 1;
                                    countOfTags++;
                                    tagFound = false;
                                    linkTextTag = false;
                                }
                            }
                        }
                    }
                    if (findText != '') {
                        var finalReplacedText = "";
                        var changeCount = 0;
                        for (var i = 0; i <= index.length; i++) {
                            
                            var start, end;
                            if (i === 0) {
                                start = 0
                                end = index[i]['start'];
                            }
                            var changeText = text.slice(start, end);
                            if (changeText != "") {
                                if ($("#note-findnreplace-regular")["0"].checked) {
                                    if (replaceText == '') {
                                        var txt = document.createElement("textarea");
                                        txt.innerHTML = changeText;
                                        changeText = txt.value;
                                        replacedText = changeText.replace(regex, function (i, d) {
                                            if (changeCount == countFindNext) {
                                                changeCount++;
                                                return '<span class="note-findnreplace" style="' + options.findnreplace.highlight + '">' + i + '</span>'
                                            }
                                            else {
                                                changeCount++;
                                                return i;
                                            }
                                        });
                                    }
                                }
                                else {
                                    if (!$("#note-findnreplace-fullword")["0"].checked) {
                                        if (replaceText == '') {
                                            var FindTextWithEscapeChar = findText.replace("[^(a-z A-Z)]", function (i, d) {
                                                return '\\' + i;
                                             });
                                            if ($("#note-findnreplace-case")["0"].checked) {
                                                var regex = new RegExp(FindTextWithEscapeChar, 'g');
                                            }
                                            else {
                                                var regex = new RegExp(FindTextWithEscapeChar, 'gi');
                                            }
                                            replacedText = changeText.replace(regex, function (i, d) {
                                                if (changeCount == countFindNext) { 
                                                    changeCount++;
                                                    return '<span class="note-findnreplace" style="' + options.findnreplace.highlight + '">' + i + '</span>'
                                                }
                                                else {
                                                    changeCount++;
                                                    return i;
                                                }
                                            });
                                        }
                                    }
                                    else {
                                        arrayofchunke = changeText.split(' ');
                                        for (var j = 0; j < arrayofchunke.length; j++) {
                                            if ($("#note-findnreplace-case")["0"].checked) {
                                                if (arrayofchunke[j] == findText) {
                                                    if (replaceText == '') {
                                                        if (changeCount == countFindNext) {
                                                            arrayofchunke[j] = '<span class="note-findnreplace" style="' + options.findnreplace.highlight + '">' + findText + '</span>';
                                                            changeCount++;
                                                        }
                                                        else {
                                                            arrayofchunke[j] =  findText;
                                                            changeCount++;
                                                        }
                                                    }
                                                    
                                                }
                                            }
                                            else {
                                                if (arrayofchunke[j].toLowerCase() == findText.toLowerCase()) {
                                                    if (replaceText == '') {
                                                        if (changeCount == countFindNext) {
                                                            arrayofchunke[j] = '<span class="note-findnreplace" style="' + options.findnreplace.highlight + '">' + arrayofchunke[j] + '</span>';
                                                            changeCount++;
                                                        }
                                                        else {
                                                            arrayofchunke[j] = arrayofchunke[j];
                                                            changeCount++;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        replacedText = "";
                                        for (var j = 0; j < arrayofchunke.length; j++) {
                                            replacedText += ' ' + arrayofchunke[j];
                                        }
                                    }
                                }
                            }
                            else {
                                replacedText = "";
                            }
                            if (i < index.length) {
                                finalReplacedText = finalReplacedText + replacedText + text.slice(index[i]['start'], index[i]['end']);
                            }
                            else {
                                finalReplacedText = finalReplacedText + replacedText;
                            }
                            if (i < index.length) {
                                start = index[i]['end'];
                                if (i + 1 === index.length) {

                                    end = text.length;
                                }
                                else {
                                    end = index[i + 1]["start"];
                                }
                            }
                        }
                    }
                    $note.summernote('code', finalReplacedText);
                    $("#note-findnreplace-fullword").prop("disabled", true);
                    $("#note-findnreplace-case").prop("disabled", true);
                    $("#note-findnreplace-regular").prop("disabled", true);
                    countFindNext++;
                  });
                $("#replaceNext").click(function (i) {
                    var replaceText = $('.note-findnreplace-replace').val();
                    if (replaceText != null) {
                        $editor.find('.note-findnreplace').text(replaceText);
                        countFindNext--;
                    }
                });
            };
            this.destroy = function () {
                ui.hideDialog(this.$dialog);
                this.$dialog.remove();
            };
            this.bindEnterKey = function ($input, $btn) {
                $input.on('keypress', function (event) {
                    if (event.keyCode === 13) $btn.trigger('click');
                });
            };
            this.bindLabels = function () {
                self.$dialog.find('.form-control:first').focus().select();
                self.$dialog.find('label').on('click', function () {
                    $(this).parent().find('.form-control:first').focus();
                });
            };
            this.show = function () {
                this.showLinkDialog()
            };
            this.showLinkDialog = function () {debugger
                return $.Deferred(function (deferred) {
                    var $editBtn = self.$dialog.find('.note-findnreplace-btn');
                    ui.onDialogShown(self.$dialog, function () {
                        $(".modal-backdrop.in").hide();
                        context.triggerEvent('dialog.shown');
                        $editBtn.click(function (e) {
                            e.preventDefault();
                            var replacedText;
                            var text = $note.summernote('code');
                            var txt = document.createElement("textarea");
                            txt.innerHTML = text;
                            text = txt.value;
                            var findText = ($('.note-findnreplace-find').val()).trim();
                            var replaceText = $('.note-findnreplace-replace').val();
                            replaceText = "<p>" + replaceText + "</p>";
                            replaceText = $('' + replaceText)["0"].innerHTML;
                            findText = findText.replace(/[\[|\)|\(|\]|\*|\+]/g, function (i, d) {
                                return '\\' + i;
                            });
                            var regex = (new RegExp(findText, "gi"));
                            var regex1 = (new RegExp("\\b(?:" + findText + ")\\b", "g"));
                            var regex2 = (new RegExp("\\b(?:" + findText + ")\\b", "gi"));
                            if ($("#note-findnreplace-case")["0"].checked && $("#note-findnreplace-fullword")["0"].checked) {
                                regex = regex1;
                            }
                            if ($("#note-findnreplace-fullword")["0"].checked && !$("#note-findnreplace-case")["0"].checked) {
                                regex = regex2;
                            }
                            if (!$("#note-findnreplace-fullword")["0"].checked && $("#note-findnreplace-case")["0"].checked) {
                                regex = (new RegExp(findText, "g"));
                            }
                            if (this.innerHTML === lang.findnreplace.findBtn) {
                                replaceText = "";
                            }
                            var index = [];
                            var countOfTags = 0;
                            var tagFound = false;
                            var linkTextTag = false;
                            for (var i = 0; i < text.length; i++) {
                                if (text[i] === '<' && !tagFound) {
                                    if (text[i + 1] == 'k') {
                                        linkTextTag = true;
                                    }
                                    index[countOfTags] = { start: i, end: null };
                                    tagFound = true;
                                }
                                if (tagFound && text[i] === '>') {
                                    if (!linkTextTag) {
                                        index[countOfTags]["end"] = i + 1;
                                        countOfTags++;
                                        tagFound = false;
                                    }
                                    else {
                                        if (text[i - 1] == 'd' && text[i - 2] == 'b' && text[i - 3] == 'k') {
                                            index[countOfTags]["end"] = i + 1;
                                            countOfTags++;
                                            tagFound = false;
                                            linkTextTag = false;
                                        }
                                    }
                                }
                            }

                            if (findText != '') {
                                var finalReplacedText = "";
                                for (var i = 0; i <= index.length; i++) {
                                    var start, end;
                                    if (i === 0) {
                                        start = 0
                                        end = index[i]['start'];
                                    }
                                    var changeText = text.slice(start, end);
                                    if (changeText != "" ) {
                                        if ($("#note-findnreplace-regular")["0"].checked) {
                                            if (replaceText == '') {
                                                var txt = document.createElement("textarea");
                                                txt.innerHTML = changeText;
                                                changeText = txt.value;
                                                replacedText = changeText.replace(regex, function (i, d) {
                                                    return '<span class="note-findnreplace" style="' + options.findnreplace.highlight + '">' + i + '</span>'
                                                });
                                            }
                                            else {
                                                replacedText = changeText.replace(regex, '<span class="note-findnreplace" style="' + options.findnreplace.highlight + '">' + replaceText + '</span>');
                                            }
                                        }
                                        else {
                                            if (!$("#note-findnreplace-fullword")["0"].checked) {
                                                if (replaceText == '') {
                                                    var FindTextWithEscapeChar = findText.replace("[^(a-z A-Z)]", function (i, d) {
                                                        return '\\' + i;
                                                    });
                                                    if ($("#note-findnreplace-case")["0"].checked) {
                                                        var regex = new RegExp(FindTextWithEscapeChar, 'g');
                                                    }
                                                    else {
                                                        var regex = new RegExp(FindTextWithEscapeChar, 'gi');
                                                    }
                                                    replacedText = changeText.replace(regex, function (i, d) {
                                                       return '<span class="note-findnreplace" style="' + options.findnreplace.highlight + '">' + i + '</span>'
                                                     });
                                                }
                                                else {
                                                    var FindTextWithEscapeChar = findText.replace("[^(a-z A-Z)]", function (i, d) {
                                                        return '\\' + i;
                                                    });
                                                    if ($("#note-findnreplace-case")["0"].checked) {
                                                        var regex = new RegExp(FindTextWithEscapeChar, 'g');
                                                    }
                                                    else {
                                                        var regex = new RegExp(FindTextWithEscapeChar, 'gi');
                                                    }
                                                    replacedText = changeText.replace(regex, function (i, d) {
                                                        return '<span class="note-findnreplace" style="' + options.findnreplace.highlight + '">' + replaceText + '</span>'
                                                    });
                                                }
                                            }
                                            else {
                                                arrayofchunke = changeText.split(' ');
                                                for (var j = 0; j < arrayofchunke.length; j++) {
                                                    if ($("#note-findnreplace-case")["0"].checked) {
                                                        if (arrayofchunke[j] == findText) {
                                                            if (replaceText == '') {
                                                                 arrayofchunke[j] = '<span class="note-findnreplace" style="' + options.findnreplace.highlight + '">' + findText + '</span>';
                                                              }
                                                            else {
                                                                    arrayofchunke[j] = '<span class="note-findnreplace" style="' + options.findnreplace.highlight + '">' + replaceText + '</span>';
                                                             }
                                                         }
                                                    }
                                                    else {
                                                        if (arrayofchunke[j].toLowerCase() == findText.toLowerCase()) {
                                                            if (replaceText == '') {
                                                                arrayofchunke[j] = '<span class="note-findnreplace" style="' + options.findnreplace.highlight + '">' + arrayofchunke[j] + '</span>';
                                                            }
                                                            else {
                                                                arrayofchunke[j] = '<span class="note-findnreplace" style="' + options.findnreplace.highlight + '">' + replaceText + '</span>';
                                                            }
                                                        }
                                                    }
                                                }
                                                replacedText = "";
                                                for (var j = 0; j < arrayofchunke.length; j++) {
                                                    replacedText += ' ' + arrayofchunke[j];
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        replacedText = "";
                                    }
                                    if (i < index.length) {
                                        finalReplacedText = finalReplacedText + replacedText + text.slice(index[i]['start'], index[i]['end']);
                                    }
                                    else {
                                        finalReplacedText = finalReplacedText + replacedText;
                                    }
                                    if (i < index.length) {
                                        start = index[i]['end'];
                                        if (i + 1 === index.length) {

                                            end = text.length;
                                        }
                                        else {
                                            end = index[i + 1]["start"];
                                        }
                                    }
                                }
                            }
                            $note.summernote('code', finalReplacedText);
                            //////////////////////////////////////////////////////////////////////
                           
                            //////////////////////////////////////////////////////////////////////
                            setTimeout(function () {
                          $editor.find('.note-findnreplace').contents().unwrap('span');
                            }, options.findnreplace.notTime);
                        });
                        self.bindEnterKey($editBtn);
                        self.bindLabels();
                    });
                    ui.onDialogHidden(self.$dialog, function () {
                        countFindNext = 0;
                        $("#note-findnreplace-fullword").prop("disabled", false);
                        $("#note-findnreplace-case").prop("disabled", false);
                        $("#note-findnreplace-regular").prop("disabled", false);
                        $(".btn-primary").prop("disabled", false);

                        $editBtn.off('click');
                        if (deferred.state() === 'pending') deferred.reject();
                    });
                    if (jQuery(".note-editable").attr("contenteditable") == "false") {
                        $(".note-replaceAll-btn").prop("disabled", true);
                        $(".note-replace-btn").prop("disabled", true);
                        $('#replaceForm').hide();
                    } else {     
                        $(".note-replaceAll-btn").prop("disabled", false);
                        $(".note-replace-btn").prop("disabled", false);
                        $('#replaceForm').show();
                    }
                    ui.showDialog(self.$dialog);
                });
            };
        }
    });
}));