import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Collaboration} from './Collaboration'
import {CollaborationMixin} from './CollaborationMixin'
//import {Performer} from './Performer'
import {CapabilityOffer} from './CapabilityOffer'
import {OrgUnit} from './OrgUnit'
import {PracticeDefinition} from './PracticeDefinition'
import {BusinessItem} from './BusinessItem'
import {CaseReference} from './CaseReference'
import {ProcessReference} from './ProcessReference'
import {ApplicationInterface} from './ApplicationInterface'
import {WebServiceReference} from './WebServiceReference'
import {Capability} from './Capability'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Collaboration","appbo/vdml/CollaborationMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Collaboration,CollaborationMixin
, Performer
, CapabilityOffer
, OrgUnit
, PracticeDefinition
, BusinessItem
, CaseReference
, ProcessReference
, ApplicationInterface
, WebServiceReference
, Capability){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	//if(!Performer){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Performer){
			Performer = importPath.Performer;
		}
		else{
			import('./Performer').then(loadedModule => {
			//	Performer = loadedModule;
			});
			/*require(["appbo/vdml/Performer"],function(loadedModule){
				Performer = loadedModule;
			});*/
		}
	//}
	if(!CapabilityOffer){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityOffer){
			CapabilityOffer = importPath.CapabilityOffer;
		}
		else{
			import('./CapabilityOffer').then(loadedModule => {
				CapabilityOffer = loadedModule;
			});
			/*require(["appbo/vdml/CapabilityOffer"],function(loadedModule){
				CapabilityOffer = loadedModule;
			});*/
		}
	}
	if(!OrgUnit){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OrgUnit){
			OrgUnit = importPath.OrgUnit;
		}
		else{
			import('./OrgUnit').then(loadedModule => {
		//		OrgUnit = loadedModule;
			});
			/*require(["appbo/vdml/OrgUnit"],function(loadedModule){
				OrgUnit = loadedModule;
			});*/
		}
	}
	if(!PracticeDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PracticeDefinition){
			PracticeDefinition = importPath.PracticeDefinition;
		}
		else{
			import('./PracticeDefinition').then(loadedModule => {
		//		PracticeDefinition = loadedModule;
			});
			/*require(["appbo/vdml/PracticeDefinition"],function(loadedModule){
				PracticeDefinition = loadedModule;
			});*/
		}
	}
	if(!BusinessItem){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItem){
			BusinessItem = importPath.BusinessItem;
		}
		else{
			import('./BusinessItem').then(loadedModule => {
		//		BusinessItem = loadedModule;
			});
			/*require(["appbo/vdml/BusinessItem"],function(loadedModule){
				BusinessItem = loadedModule;
			});*/
		}
	}
	if(!CaseReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CaseReference){
			CaseReference = importPath.CaseReference;
		}
		else{
			import('./CaseReference').then(loadedModule => {
				CaseReference = loadedModule;
			});
			/*require(["appbo/vdml/CaseReference"],function(loadedModule){
				CaseReference = loadedModule;
			});*/
		}
	}
	if(!ProcessReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ProcessReference){
			ProcessReference = importPath.ProcessReference;
		}
		else{
			import('./ProcessReference').then(loadedModule => {
				ProcessReference = loadedModule;
			});
			/*require(["appbo/vdml/ProcessReference"],function(loadedModule){
				ProcessReference = loadedModule;
			});*/
		}
	}
	if(!ApplicationInterface){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ApplicationInterface){
			ApplicationInterface = importPath.ApplicationInterface;
		}
		else{
			import('./ApplicationInterface').then(loadedModule => {
				ApplicationInterface = loadedModule;
			});
			/*require(["appbo/vdml/ApplicationInterface"],function(loadedModule){
				ApplicationInterface = loadedModule;
			});*/
		}
	}
	if(!WebServiceReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.WebServiceReference){
			WebServiceReference = importPath.WebServiceReference;
		}
		else{
			import('./WebServiceReference').then(loadedModule => {
				WebServiceReference = loadedModule;
			});
			/*require(["appbo/vdml/WebServiceReference"],function(loadedModule){
				WebServiceReference = loadedModule;
			});*/
		}
	}
	if(!Capability){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Capability){
			Capability = importPath.Capability;
		}
		else{
			import('./Capability').then(loadedModule => {
		//		Capability = loadedModule;
			});
			/*require(["appbo/vdml/Capability"],function(loadedModule){
				Capability = loadedModule;
			});*/
		}
	}
	export class CapabilityMethodMixin {

	defaults(){
		var ret = {
			type: "vdml_CapabilityMethod"
		}
		return jQuery.extend(Collaboration.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CapabilityMethod",
			key:"performer",
			relatedModel:"vdml.Performer",
			reverseRelation: {
				key:"performerOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CapabilityMethod",
			key:"supportedCapability",
			relatedModel:"vdml.CapabilityOffer",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CapabilityMethod",
			key:"implementedPractice",
			relatedModel:"vdml.PracticeDefinition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CapabilityMethod",
			key:"methodResource",
			relatedModel:"vdml.BusinessItem",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_CapabilityMethod",
			key:"caseReference",
			relatedModel:"vdml.CaseReference",
			reverseRelation: {
				key:"caseReferenceOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_CapabilityMethod",
			key:"processReference",
			relatedModel:"vdml.ProcessReference",
			reverseRelation: {
				key:"processReferenceOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CapabilityMethod",
			key:"applicationInterface",
			relatedModel:"vdml.ApplicationInterface",
			reverseRelation: {
				key:"applicationInterfaceOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CapabilityMethod",
			key:"webserviceReference",
			relatedModel:"vdml.WebServiceReference",
			reverseRelation: {
				key:"webserviceReferenceOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_CapabilityMethod",
			key:"capability",
			relatedModel:"vdml.Capability",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!CapabilityMethodMixin.cummulativeRelations) {
            CapabilityMethodMixin.cummulativeRelations = _.union(CapabilityMethodMixin.getMixinRelations()
                , CollaborationMixin.getCumulativeMixinRelations()
            );
        }
		return CapabilityMethodMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_Collaboration"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("methodOwner") ? this.get("methodOwner") : this.previousAttributes().methodOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("methodOwner") ? this.get("methodOwner") : this.previousAttributes().methodOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/CapabilityMethodPropertiesTemplate.html",
			templateName : "CapabilityMethodPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/CapabilityMethodViewModel",
			tabId : "CapabilityMethodView",
			tabName: "CapabilityMethod"
		}
	}
//#startCustomMethods
	createDefaultRole(name){
		var roleId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var role = new Performer({id:roleId,name:this.getUniqueProperty('name',name ? name: "Business",'collaborationRole'),collaborationRoleOwner:this});
		return role;
	};
//#endCustomMethods
	
	
}
path.CapabilityMethodMixin = CapabilityMethodMixin;