import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {BeepPackage} from '../beeppackage/BeepPackage'
import {AnalyticsMixin} from './AnalyticsMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {PackageReference} from '../../../com/vbee/filesystem/PackageReference'
/*
define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/beeppackage/BeepPackage", "appbo/dashboard/AnalyticsMixin"],
function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, BeepPackage, AnalyticsMixin
){*/
	
    var path = DataManager.getDataManager().buildAppNsPath("dashboard", global.version);
	var Analytics = BeepPackage.extend(utils.customExtends({
		relations:AnalyticsMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BeepPackage.prototype.initialize.apply(this, arguments);
			//this.on("change:name", this.handlePackageNameChange);
		}
		}
		, new AnalyticsMixin()
	));
//#startCustomMethods			
    Analytics["change:name"] = function (model,newName) {
		var previousName = model._previousAttributes ? model._previousAttributes.name:"";
		if(previousName != newName) {
			this.handlePackageNameChange(model, newName);
		}
    }
	Analytics.prototype.getSystemTags = function(){
	    return ['Analytics Package', 'VDM', 'Admin'];
	};
//#endCustomMethods
	Analytics.getName = function(){
		return "Analytics";
	};
	Analytics.createPackageWithName = function(name,description,currentAlternative){
		name = window.utils.getUniquePackageName(name, name, currentAlternative, "dashboard_Analytics");
		var docId = window.guidGenerator(); 
		var analytics = new Analytics({
			'name':name,
			'description':description,
			'id':docId,
			'label':"Base"});
		var pacRef = new PackageReference({beepReference:analytics,name:analytics.get('name'),description:analytics.get('description'),version:analytics.get('version'),beepType:analytics.get('type'),label:'Base'});
		DataManager.getDataManager().get('currentWorkspace').get('packageReference').add(pacRef);
		DataManager.getDataManager().get('initializedPackages').add({'id':analytics.get('id'),'version':parseInt(analytics.get('version')),'model':analytics});
		var currentphase = currentAlternative.get('phaseAlternativeOwner');
		var pprId = DataManager.getDataManager().guidGeneratorByOwner(currentphase);
		var PlanPackageReference = Backbone.Relational.store.getObjectByName("transformation.PackageReference");
		var planPacRef = new PlanPackageReference({id:pprId,beepReference:analytics.get('id'),name:analytics.get('name'),description:analytics.get('description'),version:analytics.get('version'),beepType:analytics.get('type'),label:'Base',isMain:true,phaseDesignPartOwner:currentAlternative});
		currentAlternative.get('phaseDesignPart').add(planPacRef);
		if(currentphase.get('primary') === currentAlternative || currentphase.get('master') === currentAlternative) {
			analytics.referPackageToNextPhases(currentAlternative);
		}
		return analytics;
	}
	
	function printStackSize(method){
		console.log('method:' + method)
		var i=0;
		function testStack(){
			i++
			testStack();
		}		
		try{
			testStack();
		}catch(e){
			console.log('stak size:' + i)
		}
	}
	Analytics.abstract = false;
	Analytics.supported = true;
	path.Analytics = Analytics;
	export {Analytics};
	//return Analytics;
//});