import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BaseMeasureRelationship} from './BaseMeasureRelationship'
import {BaseNMeasureRelationshipMixin} from './BaseNMeasureRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { DimensionalMeasure } from './DimensionalMeasure'
//import { CollectiveMeasure } from './CollectiveMeasure'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/BaseMeasureRelationship","appbo/smm/BaseNMeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BaseMeasureRelationship,BaseNMeasureRelationshipMixin
, DimensionalMeasure
, CollectiveMeasure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!DimensionalMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasure){
			DimensionalMeasure = importPath.DimensionalMeasure;
		}
        else {
            import('./DimensionalMeasure').then(({ default: DimensionalMeasure }) => {
                DimensionalMeasure = DimensionalMeasure;
            });

			/*require(["appbo/smm/DimensionalMeasure"],function(loadedModule){
				DimensionalMeasure = loadedModule;
			});*/
		}
	}
	//if(!CollectiveMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.CollectiveMeasure){
			CollectiveMeasure = importPath.CollectiveMeasure;
		}
        else {
            import('./CollectiveMeasure').then(({ default: CollectiveMeasure }) => {
                CollectiveMeasure = CollectiveMeasure;
            });

			/*require(["appbo/smm/CollectiveMeasure"],function(loadedModule){
				CollectiveMeasure = loadedModule;
			});*/
		}
	//}
	var BaseNMeasureRelationship = BaseMeasureRelationship.extend(utils.customExtends({
		relations:BaseNMeasureRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BaseMeasureRelationship.prototype.initialize.apply(this, arguments);
			if(attributes.influence){
				this.set('influence',path.Influence[attributes.influence instanceof Object ? attributes.influence.name : attributes.influence]);
			}
			//this.on("change:to", this.handleTargetChange);
		}
		}
		, new BaseNMeasureRelationshipMixin()
	));

    BaseNMeasureRelationship["change:to"] = function (model, related, options) {
		if(Backbone.skipPersistance){
    		return;
    	}
        this.handleTargetChange(model, related, options);
    }
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	BaseNMeasureRelationship.abstract = false;
	BaseNMeasureRelationship.supported = !sp2Enabled;
	path.BaseNMeasureRelationship = BaseNMeasureRelationship;
	export {BaseNMeasureRelationship};
//});