import React, { useEffect, useState } from "react";
import "./DashboardReact.scss"
import {
  Grid,

} from "@mui/material";
import PresentationWrapper from "./PresentationWrapper";

const DashboardElement = ({treeData}) => {

  // const [presentationArr,setPresentationArr] = useState([])

  // useEffect(()=>{
  //   if(treeData!== undefined) {
  //     setPresentationArr(treeData.subRows);
  //   }
  // },[treeData])

  return (
    <>
      <div className="rootDashboard">
        <div id="Dashboard" className="DashboardContent">
          <Grid  container className="mainContainer" spacing={2}>
            <PresentationWrapper node={treeData} arr={treeData.subRows} key={treeData.id}/>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default DashboardElement;
