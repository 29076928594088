import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelItem} from './BusinessModelItem'
import {BusinessModelItemMixin} from './BusinessModelItemMixin'
import {ValueProposition} from './ValueProposition'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelItem","appbo/vdml/BusinessModelItemMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelItem,BusinessModelItemMixin
, ValueProposition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ValueProposition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueProposition){
			ValueProposition = importPath.ValueProposition;
		}
		else{
			import('./ValueProposition').then(({ default: ValueProposition }) => {
                ValueProposition = ValueProposition;
            });
			/*require(["appbo/vdml/ValueProposition"],function(loadedModule){
				ValueProposition = loadedModule;
			});*/
		}
	}
	export class BusinessModelValueFormulaMixin {

	defaults(){
		var ret = {
			type: "vdml_BusinessModelValueFormula"
		}
		return jQuery.extend(BusinessModelItem.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"vdml_BusinessModelValueFormula",
			key:"valueFormula",
			relatedModel:"vdml.ValueProposition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!BusinessModelValueFormulaMixin.cummulativeRelations) {
            BusinessModelValueFormulaMixin.cummulativeRelations = _.union(BusinessModelValueFormulaMixin.getMixinRelations()
                , BusinessModelItemMixin.getCumulativeMixinRelations()
            );
        }
		return BusinessModelValueFormulaMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_BusinessModelItem"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("bmValueFormulaOwner") ? this.get("bmValueFormulaOwner") : this.previousAttributes().bmValueFormulaOwner;
			if(!container){
				container = this.get("valueFormula") ? this.get("valueFormula").getNestedParent() : null;
			}
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("bmValueFormulaOwner") ? this.get("bmValueFormulaOwner") : this.previousAttributes().bmValueFormulaOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BusinessModelValueFormulaPropertiesTemplate.html",
			templateName : "BusinessModelValueFormulaPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BusinessModelValueFormulaViewModel",
			tabId : "BusinessModelValueFormulaView",
			tabName: "BusinessModelValueFormula"
		}
	}
//#startCustomMethods	
	updateBMValueFormula(valueFormula,vfName,businessRole){
		var self = this;
		var name;
		if(!businessRole){
			return null;
		}
		
		if(valueFormula){
			name = valueFormula.get('name');
			if(businessRole){
				if(businessRole !== valueFormula.get('provider')){
					updateVF(valueFormula, businessRole, updateVFValues);
				}
				valueFormula.set('provider',businessRole);
			}
			if(name != vfName){
				self.set('name',vfName);
				self.set('description',vfName);
				self.get('valueFormula').set('name',vfName);
				self.get('valueFormula').set('description',vfName);
			}
		}else{
			name = vfName;
			updateVFValues();
		}
		
		function updateVFValues(){
			var oldVf = self.get('valueFormula');
			if(oldVf !== valueFormula){
				self.set('name',name);
				self.set('description',name);
				self.get('valueFormula').set('name',name);
				self.get('valueFormula').set('description',name);
				self.get('valueFormula').set('provider',businessRole);
				
				//this.set('valueFormula',valueProposition);
			}
		}
		//this.get('valueFormula').deleteValue(values,mainScenario);
		/*if(valueProposition === null){
			valueProposition = new ValueProposition({'name':name,description:name,provider:businessRole});
		}		
		if(valueProposition && oldVP !== valueProposition){
			this.set('valueFormula',valueProposition);
		}*/
		function updateVF(valueFormula, provider, callback){
            DataManager.getDataManager().getReverseAssociationInstances(self.get('valueFormula'), 'vdml_BusinessModelValueFormula-valueFormula', 'vdml_BusinessModelValueFormula', "vbc:" + "cmof_EObject-name", 
			function(bmVFs){
				var modelsToDelete = [];
				bmVFs.each(function(bmVF){
					var bm = bmVF.get('bmValueFormulaOwner');
					var bmRoles = bm.get('bmRole');
					var pRole = bmRoles.findWhere({id:provider.get('id')});
					if(!pRole){
						modelsToDelete.push(bmVF);
					}
				});
				_.each(modelsToDelete,function(modelToDel){
        			modelToDel.destroy();
        		});
				callback();
			}, true);
		}
	};
	
	
	
//#endCustomMethods		
	
	}
	path.BusinessModelValueFormulaMixin = BusinessModelValueFormulaMixin;
	//return BusinessModelValueFormulaMixin;
//});