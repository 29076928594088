import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import {DashboardViewModel} from './DashboardViewModel'


/*define(["require", "jquery", "enhsplitter", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "app/global", "typeahead", "appbo/dashboard/DashboardMixin"],
function (require, $, enhsplitter, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, global, typeahead, DashboardMixin)
{*/
    var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart", global.version);
	export class Dashboard2ViewModel extends DashboardViewModel {
		
		loadScenarioTab() {
			var self = this;
			var DashboardValuesViewModel = window.utils.getView("DashboardValuesViewModel");
			self.dashboardScenariosViewModel = DashboardValuesViewModel.getInstance(this);
			if (!ko.components.isRegistered("Scenarios")) {
				ko.components.register("Scenarios", {
					viewModel: { instance: self.DashboardValuesViewModel },
					template: window.utils.getHtmlPage("DashboardValuesTemplate"),
					synchronous: true,
				});
			}
		}
		
		loadDetailsTab() {
			var self = this;
			var DashboardDetails2ViewModel = window.utils.getView("DashboardDetails2ViewModel");
			this.dashboardDetailsViewModel = DashboardDetails2ViewModel.getInstance(this);
			if (!ko.components.isRegistered("DBDetails")) {
				ko.components.register("DBDetails", {
				//viewModel: DashboardDetailsViewModel,
				viewModel: { instance: self.dashboardDetailsViewModel },
				template: window.utils.getHtmlPage("DashboardDetailsTemplate"),
				//synchronous: true
				});
			}
		}

		updateObsValues(callback){
			callback();
		}
			
	init(model, options){
		super.init(model,options);
	}
	
	static getInstance(model, options){
		var view = new Dashboard2ViewModel(model, options);
		view.init(model, options);
		return view;
	};

}
path.Dashboard2ViewModel = Dashboard2ViewModel;