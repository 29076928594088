import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Measurement} from './Measurement'
import {GradeMeasurementMixin} from './GradeMeasurementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Operation } from './Operation'
import { GradeMeasurementRelationship } from './GradeMeasurementRelationship'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/Measurement","appbo/smm/GradeMeasurementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Measurement,GradeMeasurementMixin
, GradeMeasurementRelationship
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!GradeMeasurementRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.GradeMeasurementRelationship){
			GradeMeasurementRelationship = importPath.GradeMeasurementRelationship;
		}
        else {
            import('./GradeMeasurementRelationship').then(({ default: GradeMeasurementRelationship }) => {
                GradeMeasurementRelationship = GradeMeasurementRelationship;
            });
			/*require(["appbo/smm/GradeMeasurementRelationship"],function(loadedModule){
				GradeMeasurementRelationship = loadedModule;
			});*/
		}
	}
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
        else {
            import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });
			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	var GradeMeasurement = Measurement.extend(utils.customExtends({
		relations:GradeMeasurementMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Measurement.prototype.initialize.apply(this, arguments);
		}
		}
		, new GradeMeasurementMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	GradeMeasurement.abstract = false;
	GradeMeasurement.supported = !sp2Enabled;
	path.GradeMeasurement = GradeMeasurement;
	export {GradeMeasurement};
//});