import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasurement} from './DimensionalMeasurement'
import {BinaryMeasurementMixin} from './BinaryMeasurementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
//import { Base1MeasurementRelationship } from './Base1MeasurementRelationship'
//import { Base2MeasurementRelationship } from './Base2MeasurementRelationship'
import { Operation } from './Operation'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasurement","appbo/smm/BinaryMeasurementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasurement,BinaryMeasurementMixin
, Base1MeasurementRelationship
, Base2MeasurementRelationship
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
        else {
            import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });

			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	var BinaryMeasurement = DimensionalMeasurement.extend(utils.customExtends({
		relations:BinaryMeasurementMixin.getMixinRelations(),
		subModelTypes: {
			smm_RatioMeasurement : 'smm.RatioMeasurement'
		},
		initialize: function(attributes, options) {
			DimensionalMeasurement.prototype.initialize.apply(this, arguments);
		}
		}
		, new BinaryMeasurementMixin()
	));
	
	BinaryMeasurement.abstract = false;
	BinaryMeasurement.supported = false;	//As we are not using it yet
	path.BinaryMeasurement = BinaryMeasurement;
	export {BinaryMeasurement};
//});