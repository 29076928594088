import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Role} from './Role'
import {MemberMixin} from './MemberMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Role","appbo/vdml/MemberMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Role,MemberMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var Member = Role.extend(utils.customExtends({
		relations:MemberMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Role.prototype.initialize.apply(this, arguments);
		}
		}
		, new MemberMixin()
	));
	
	Member.abstract = false;
	path.Member = Member;
	export {Member};
//});