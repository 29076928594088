import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {PackageReference} from '../../../../../com/vbee/filesystem/PackageReference'
import {DateFormat} from '../../../../../com/vbee/utils/DateFormat'
import {BeepPackageMixin} from '../../../../../version1/bo/beeppackage/BeepPackageMixin'
import {ScenarioProxy} from '../../../../../version1/bo/transformation/ScenarioProxy'
import {ValueDeliveryModel} from '../../../../../version1/bo/vdml/ValueDeliveryModel'
import {BMCanvasItem} from '../../../../../version1/bo/canvas/BMCanvasItem'
import {BMCanvasDiagram} from '../../../../../version1/bo/canvas/BMCanvasDiagram'
import {BMCanvasDiagramMixin} from '../../../../../version1/bo/canvas/BMCanvasDiagramMixin'

//define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","async","appcommon/com/vbee/data/DataManager","appbo/beeppackage/BeepPackageMixin","app/global", "appcommon/com/vbee/utils/DateFormat","appbo/transformation/ScenarioProxy","appbo/transformation/PackageReference", "appbo/vdml/ValueDeliveryModel","appcommon/com/vbee/filesystem/PackageReference","appbo/canvas/BMCanvasItem"],
//function(require,$,_, Backbone,ko, koMapping,kb,bootbox,async,DataManager,BeepPackageMixin,global,DateFormat,ScenarioProxy,PlanPackageReference,ValueDeliveryModel,PackageReference,BMCanvasItem)
//{
var path = DataManager.getDataManager().buildAppNsPath("canvas.views.properties",global.version);
export class CanvasDetailsViewModel{
	init(model,options){
		var self = this;
		this.CanvasDetailsViewModel = this;
		this.model = model.model;
		this.id = kb.observable(self.model, 'id');
		this.encodeId = window.utils.htmlEscape(this.id());
		this.colorCheck = ko.observable(self.model ? self.model.get('colors') : false);
		this.enableComplete = ko.observable(false);
		this.name = ko.observable(self.model ? self.model.get('name') : null);
		this.description =ko.observable(self.model ? self.model.get('description') : "");
		this.existingPackages = [];
		this.currentAlt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
		this.currentPlan = DataManager.getDataManager().get('currentPlan');
		
		if (!self.model.get('businessModel')) {
                $('#linkedBMInfoDiv').addClass('hide');
                $('#linkedBM').html('');
                $('#linkedBusinessInfoDiv').addClass('hide');
                $('#linkedBusiness').html('');
        } else {
            $('#linkedBMInfoDiv').removeClass('hide');
            $('#linkedBM').html(self.model.get('businessModel').get('name'));
            //$('#linkedBusinessInfoDiv').removeClass('hide');
                var business = self.model.get('businessModel').get('business');
                if (business) {
                    $('#linkedBusinessInfoDiv').removeClass('hide');
                    $('#linkedBusiness').html(business.get('name'));
                } 
        }
		this.name.subscribe(function(val){
			self.description(val);
		});
		
		this.colorCheck.subscribe(function(v){
			self.enableComplete(true);
		});
        /*if(self.model.get('canvasType') == "SWOTAnalysisCanvas"){
            self.colorCheck(false);
            self.saveCanvasDetails();
        }*/
		this.afterRenderView = _.bind(this.afterRenderView,this);
	}
	dispose(){
		var self = this;
		window.cleanViewModel(self);		
	};
	getName() {
        var self = this;
		var configObj = self.getConfigObj();
		if (configObj) {
			return this.model.get('name') + " (" + configObj.name + ")";
		} else {
			return this.model.get('name');
		}
	};
			
	saveCanvasDetails(){
		var self = this;
		if(self.model){
			if(self.name().trim() != self.model.get('name')){
				self.model.set('name',self.name().trim());
				window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'),DataManager.getDataManager().get('viewAlternative'),self.CanvasDetailsViewModel);
			}
			self.model.set('description',self.description().trim());
			if(self.model.get('colors') != $('#useColors').is(':checked')){
				self.model.set('colors',$('#useColors').is(':checked'));
				var configObj = self.getConfigObj();
				var canvasBlocks = $('#canvas-group'+self.encodeId).children();
				if(configObj) {
					var backgroundColors = configObj.backgroundColors;
					if (self.model.get('colors')) {
						for(var i=0;i<backgroundColors.length;i++){
							canvasBlocks.eq(i).css('background-color', backgroundColors[i].color);
						}
					}else{
						for(var i=0;i<backgroundColors.length;i++){
							canvasBlocks.eq(i).css('background-color', "#ffffff" );
						}
					}
				}
				//self.createPicture();
			}
			self.enableComplete(false);
		}
	};
		
	getConfigObj(){
		var self = this;
		var canvasType = self.model.get('canvasType');
		if(!canvasType){
			canvasType = "BusinessCanvas";
		}
		var config = window.utils.getCanvasJson(canvasType);
		if(config){
			return config;
		}else {
			return null;
		}
	};
		
		
	getDimensions(){
		var self = this;
		var configObj = self.getConfigObj();
		if (configObj) {
			var dimensions = configObj.dimensions;
			var localeManager = DataManager.getDataManager().get('localeManager');
			_.each(dimensions, function (dimension) {
				localeManager.currentTranslations[dimension.id] = localeManager.currentTranslations[dimension.label];
			});
			this.dimensions = configObj.dimensions;
		} else {	//just to handle null values
			this.dimensions = [
				{ "id": "dimension1", "name": "Key Partners", "mapping": ["partner"] },
				{ "id": "dimension2", "name": "Key Activities", "mapping": ["activity"] },
				{ "id": "dimension3", "name": "Key Resources", "mapping": ["competence"] },
				{ "id": "dimension4", "name": "Value Propositions", "mapping": ["valueProposition"] },
				{ "id": "dimension5", "name": "Customer Relationships", "mapping": ["value", "competence", "activity", "network"] },
				{ "id": "dimension6", "name": "Channels", "mapping": ["partner", "competence"] },
				{ "id": "dimension7", "name": "Customer Segments", "mapping": ["customer"] },
				{ "id": "dimension8", "name": "Cost Structure", "mapping": ["value"] },
				{ "id": "dimension9", "name": "Revenue Streams", "mapping": ["value"] }
			];
		}
	}
		
		
	deleteCanvas(){
		var self = this;
		var dataItems = [];
		self.getDimensions();
		for(var i=1;i<=self.dimensions.length;i++){
			var dimObj = self.dimensions[_.findLastIndex(self.dimensions,{id:'dimension'+i})];
			var dimModal = self.model.get('bmCanvasBlock').find({name:dimObj.name});
			var items = dimModal?dimModal.get('blockItem').models:null;				
			if(items && items.length){
				dataItems.push(items[i]);
				break;
			}
		}
		if(dataItems.length>0 || self.model.get('businessModel')){					
			bootbox.confirm(''+DataManager.getDataManager().get('localeManager').get('BusinessCanvasDelete'), function(result) {
				if(result) {
					self.doCanvasDelete(function(){
						DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});	
					});	        									    				    	
				}    
			});
		}else{					
			self.doCanvasDelete(function(){
				DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});	
			});	
		}
	};			
		
	doCanvasDelete(callback){
		var self = this;
		var vdmPackage = self.model.getNestedParent();
		window.vdmModelView.enableComplete(false);
		self.createRevisionBasedOnContext(vdmPackage,function(newVdmPackage){
			if(window.checkContextForRevision()) {
				var bmcId = self.model.id.substr(self.model.id.lastIndexOf('@')+1,self.model.id.length);
				var altId = DataManager.getDataManager().getRepositoryId(newVdmPackage.id);
				var bmcToBeDeleted = newVdmPackage.get('canvas').findWhere({'id':altId+bmcId});
				bmcToBeDeleted.destroy();
			}else{
				self.model.destroy();
			}
			callback();
		}); 
	}
		
	/*createPicture () {debugger
			var self = this;
            var node = document.getElementById('canvasDesigner');

            domtoimage.toPng(node)
			    .then(function (dataUrl) {
			        self.model.set('picture' + DataManager.getDataManager().get('viewAlternative'), dataUrl);
			        if (!self.skipCreatePicture) {
			            var oldImages = $("img[diagramid = \"" + self.model.id + "\"]");
			            if (oldImages.length > 0) {
			                _.each(oldImages, function (oldImage) {
			                    var oldImgQuery = $(oldImage);
			                    oldImgQuery.attr('src', dataUrl);
			                    oldImgQuery.attr('lastUpdateTime', new Date().getTime());
			                });
			            } else {
			                var viewAltId = DataManager.getDataManager().get('viewAlternative');
			                var viewAlt = DataManager.getDataManager().getAlternativeSync(viewAltId);
			                var textNode = $("<div><p>Name: " + self.model.get('name') + " (" + self.model.get('description') + " )</p>"
								+ "<p>Designed By: " + DataManager.getDataManager().get('gapiDisplayName') + "</p>"
								+ "<p>Creation Date: " + DateFormat.getDateFromTimestamp(self.model.get('creationDate')) + "</p>"
								+ "<p>Phase/Alternative: " + viewAlt.get('phaseAlternativeOwner').get('name') + "/" + viewAlt.get('name') + "</p>"
								+ "</div>")[0];
                            $('#summernote').summernote('insertNode', textNode);
                            textNode = null;
			                $('#summernote').summernote('insertNode', $("<div/>")[0]);
			                $('#summernote').summernote('insertImage', dataUrl, function ($image) {
			                    $image.css('width', $image.width() / 3);
			                    $image.attr('diagramId', self.model.id);
			                    $image.attr('alternativeId', viewAltId);
			                    $image.attr('lastUpdateTime', new Date().getTime());
			                    $image.attr('parentId', self.model.get('canvasOwner').id);
			                    $image.attr('data-filename', 'retriever');
			                });
			            }
			        }
			    })
			    .catch(function (error) {
			        console.error('oops, something went wrong!', error);
			    });
        }*/
        
	createRevisionBasedOnContext(pack,callback){
		var self = this;
		if(window.checkContextForRevision()) {
			window.utils.startSpinner('revisionSpinner', "Creating a copy...");
			function fetchBmModel(newVdmPackage){
					if(newVdmPackage) {
						DataManager.getDataManager().releaseSaveLock();
						DataManager.getDataManager().set('isActive',false);
						window.utils.stopSpinner('revisionSpinner');
						callback(newVdmPackage);
						/*DataManager.getDataManager().saveData({
							success:function(){
								window.utils.stopSpinner('revisionSpinner');
								callback(newVdmPackage);
							}, 
							error:function(){
								window.utils.stopSpinner('revisionSpinner');
								callback(newVdmPackage);
							}, 
							persist:true});	*/
					}
				}
			window.setTimeout(function(){
				DataManager.getDataManager().acquireSaveLock(function () {
					pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
				});
			},100);	
		}
		else {
			callback(pack);
		}
	};
		
	checkUniqueValue(view,event){
		var self = this;
		var name = event.currentTarget.value.trim();
		if(name !== ""){
			if(self.model.get('name') === name){
				$('#duplicateName').text("");
				self.enableComplete(true);
				return;
			}else{
				self.enableComplete(true);
			}
		}
		else{
			self.enableComplete(false);
		}
	};
	loadCanvasPackages(callback){
		var self = this;
		DataManager.getDataManager().getAllDocumentsCollectionOfType(self.currentAlt, 'vdml_ValueDeliveryModel', function(results){
			for (var i = 0; i < results.length; i++) {
					var type = results.at(i).get('type');
					var referenceExists = self.currentAlt.get('phaseDesignPart').findWhere({ beepReference: results.at(i).id });
					if(referenceExists && referenceExists.get('beepReference').substring(0,referenceExists.get('beepReference').lastIndexOf('@')+1) === self.currentAlt.get('id') ){
						self.existingPackages.push(results.at(i));
					}
			}
			callback();
		}, true, null, null, 'vbc:cmof_EObject-name');
	};
	copyCanvas(){
		var self = this;
		function savePackage(view, modalId, type, moduleTypes) {
			window.utils.startSpinner('copyCanvasSpinner', "Copying Business Canvas...");
			setTimeout(function () {
				self.onAddBMC(view.name(), view.description(), view.selectedPackage(), null, null, self.currentAlt, view.selectedType(), moduleTypes, function () {
					window.cleanDialogModel(modalId, view);
					window.utils.stopSpinner('copyCanvasSpinner');
				});			       
			},100);
		}
		self.loadCanvasPackages(function(){
			var plusIconType = "ecoSystemArrow";
			if(self.model.get('canvasType') == "SWOTAnalysisCanvas"){
				plusIconType = "contextArrow";
			}else if(self.model.get('canvasType') == "LeanChangeCanvas"){
				plusIconType = "callToActionArrow";
			}
			var configObj = self.getConfigObj();
			var mapTitle = configObj.name;
			var addOptions = { 'alternative': self.currentAlt, 'plusIconType':plusIconType,'onlyMap':mapTitle};
			window.getAndCreateModalDialog(self.model, window.utils.htmlEscape(self.id()), BeepPackageMixin, self.currentPlan, "Discover", savePackage, addOptions);
		});
	};
	onAddBMC (name, description, pack, phase, alternative, currentAlternative, type, moduleTypes, callback) {
		var self = this;
		if (pack !== null && pack.id) {
			DataManager.getDataManager().fetchDocumentFromPackage(pack.id, "appbo/vdml/ValueDeliveryModel", pack.version, pack.id, "appbo/vdml/ValueDeliveryModel", DataManager.getDataManager().get('vdmStore'), {
				success: function (model) {
					self.createCanvases(name, description, model, currentAlternative, type, moduleTypes, callback);
				},
				error: function (error) {
					console.log('Unable to load selected Package');
				}
			});
		} else {
			self.createCanvases(name, description, null, currentAlternative, type, moduleTypes, callback);
		}
	};
	createCanvases (name, description, vdmPackage, currentAlternative, type, moduleTypes, callback) {
		var self = this;
		var saveWorkspace = !vdmPackage;
		DataManager.getDataManager().clearSaveInterval();
		if (!vdmPackage) {
			if (name !== null) {
				var packageName = name + " Package";
				//packageName = window.utils.getUniquePackageName(packageName, packageName, currentAlternative, "vdml_ValueDeliveryModel");
				self.getDocumentWithNamePath(packageName, 'vdml_ValueDeliveryModel', currentAlternative, false, null, function (result) {
					var vdmId;
					if (result && result[0]) {
						vdmId = currentAlternative.id + window.utils.getSuffix(result[0].doc);
						var phaseDesignPart = currentAlternative.get('phaseDesignPart');
						if (phaseDesignPart.findWhere({ 'beepReference': vdmId })) {
							vdmId = null;
						}
					}
					var vdm = ValueDeliveryModel.createPackageWithName(name, description, currentAlternative, vdmId);
					
					if (saveWorkspace) {
						DataManager.getDataManager().get('currentWorkspace').save();
					}
					if (moduleTypes[type]) {
						self.createOrRedirectBusinessCanvas(type, vdm, name, description, null, moduleTypes,true);
						var currentPlan =  DataManager.getDataManager().get('currentPlan');
						currentPlan.getAllAlternateScenarios(function (altScenarios) {
							if (!DataManager.getDataManager().altScenarios) {
								DataManager.getDataManager().altScenarios = {};
							}
							DataManager.getDataManager().altScenarios[currentPlan.get('id')] = altScenarios;
							callback();
						});
					}

				});
			}
		} else {
			if (saveWorkspace) {
				DataManager.getDataManager().get('currentWorkspace').save();
			}
			if (moduleTypes[type]) {
				self.createOrRedirectBusinessCanvas(type, vdmPackage, name, description, null, moduleTypes, false);
				callback();
			}
		}
	};
	createOrRedirectBusinessCanvas (type, pack, name, description, valternative, moduleTypes,newPackage) {
		var self = this;
		if (moduleTypes && moduleTypes[type]) {
			//require(["appcommon/com/vbee/data/DataManager", "appbo/canvas/BMCanvasDiagram", "appbo/canvas/BMCanvasDiagramMixin"], function (DataManager, BMCanvasDiagram, BMCanvasDiagramMixin) {
				var altId = DataManager.getDataManager().get('viewAlternative');
				if (altId) {
					var alternative = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: altId });
					var diagramInstance = BMCanvasDiagram.getInstance(moduleTypes[type], name, pack, null, null, description);
					self.copyDataToCanvas(diagramInstance,newPackage);
				}
			//});
		}
	};
	copyDataToCanvas(diagramInstance,newPackage){
		var self = this;
		var i=0;
		if(this.model.get('colors')){
			diagramInstance.set('colors',true);
		}
		_.each(this.model.get('bmCanvasBlock').models,function(bmcBlock){
			_.each(bmcBlock.get('blockItem').models,function(blockItem){
				var id = window.guidGenerator();
				if(newPackage && blockItem.get('canvasItem') && blockItem.get('canvasItem').id.indexOf('@BEEPPlans@') === -1){
					var dimensionItem = new BMCanvasItem({ id: id, name: blockItem.get('name'), blockItemOwner: bmcBlock,color:blockItem.get('color'),height:blockItem.get('height'),left:blockItem.get('left'),top:blockItem.get('top'),width:blockItem.get('width')});
				}else{
					dimensionItem = new BMCanvasItem({ id: id, name: blockItem.get('name'), blockItemOwner: bmcBlock,canvasItem:blockItem.get('canvasItem'),color:blockItem.get('color'),height:blockItem.get('height'),left:blockItem.get('left'),top:blockItem.get('top'),width:blockItem.get('width')});
				}
				diagramInstance.get('bmCanvasBlock').models[i].get('blockItem').models.push(dimensionItem);
			});
			i++;
		});
		if(!newPackage && this.model.get('businessModel')){
			diagramInstance.set('businessModel',this.model.get('businessModel'));
		}
	};
	getDocumentWithNamePath (namePath, type, currentAlt, isValue, pack, callback) {
	var self = this;
		var alts = currentAlt.getMasterPrimaryAlternativesOfPackage(currentAlt, pack);
		var rdfModels = [];
		async.each(alts, function (alt, altWSLoaded) {
			DataManager.getDataManager().getModelIdInitializedWSData((typeof alt === 'string') ? alt : alt.id, function (wsData) {
				if (wsData) {
					rdfModels.push(wsData.get('rdfModel'));
				}
				altWSLoaded();
			});
		}, function () {
			alts.length = 0;
			if (rdfModels.length > 0) {
				var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
				if (altRDFModel) {
					altRDFModel.getDocumentWithNamePath(namePath, type, rdfModels, isValue, callback);
				} else {
					callback();
				}
			} else {
				callback();
			}

		});
	};
	afterRenderView(){
	}
	static getInstance(model,options,detailedView){
		var view = new CanvasDetailsViewModel(model, options);
        view.init(model, options);
        return view;
	};
}
path.CanvasDetailsViewModel = CanvasDetailsViewModel;
