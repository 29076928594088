import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BMElement} from './BMElement'
import {BMElementMixin} from './BMElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BMElement","appbo/vdml/BMElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BMElement,BMElementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class BusinessModelDimensionMixin {

	defaults(){
		var ret = {
			type: "vdml_BusinessModelDimension"
		}
		return jQuery.extend(BMElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!BusinessModelDimensionMixin.cummulativeRelations) {
            BusinessModelDimensionMixin.cummulativeRelations = _.union(BusinessModelDimensionMixin.getMixinRelations()
                , BMElementMixin.getCumulativeMixinRelations()
            );
        }
		return BusinessModelDimensionMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_BMElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		return BMElement.prototype.getParent.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BusinessModelDimensionPropertiesTemplate.html",
			templateName : "BusinessModelDimensionPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BusinessModelDimensionViewModel",
			tabId : "BusinessModelDimensionView",
			tabName: "BusinessModelDimension"
		}
	}
	}
	path.BusinessModelDimensionMixin = BusinessModelDimensionMixin;
	
	//return BusinessModelDimensionMixin;
//});