import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {CanvasDesignerViewModel} from './CanvasDesignerViewModel'
import {DataManager} from '../../../../../com/vbee/data/DataManager'

//define(["require", "jquery", "underscore", "muuri", "backbone", "knockout", "bootbox", "appcommon/com/vbee/data/DataManager", "appbo/vdml/Annotation", "app/global", "appbo/vdml/BusinessModel", "appbo/vdml/Participant", "appbo/vdml/ValueElement", "appbo/canvas/BMCanvasItem", "knockback", "summernote", "appviews/canvas/views/designer/MappingWizardViewModel", "appviews/canvas/views/designer/CanvasDetailsViewModel", "appviews/help/views/properties/BMGuidanceViewModel", "appcommon/com/vbee/utils/DateFormat", "appbo/canvas/BMCanvasDiagramMixin", "appbo/vdml/CapabilityLibraryMixin", "appbo/vdml/ValueDeliveryModelMixin", "appbo/ecomap/EcoMapMixin", "domtoimage", "colorpicker"],
//    function (require, $, _, Muuri, Backbone, ko, bootbox, DataManager, Annotation, global, BusinessModel, Participant, ValueElement, BMCanvasItem, kb, summernote, MappingWizardViewModel, CanvasDetailsViewModel, BMGuidanceViewModel, DateFormat, BMCanvasDiagramMixin, CapabilityLibraryMixin, ValueDeliveryModelMixin, EcoMapMixin) {
var path = DataManager.getDataManager().buildAppNsPath("canvas.views.properties", global.version);
export class CanvasDesigner2ViewModel extends CanvasDesignerViewModel {

    init(model, options){
        super.init(model,options);
    }

    loadCanvasDetailComponent(){
        var self = this;
        if(!ko.components.isRegistered('CanvasDetails')){
            ko.components.register("CanvasDetails", {
                viewModel: window.utils.getView("CanvasDetails2ViewModel"),
                template: window.utils.getHtmlPage("CanvasDetails2Template"),
                synchronous: true
            });
        }
    }

    static getInstance(model,options){
        var view = new CanvasDesigner2ViewModel(model, options);
        view.init(model, options);
        return view;
    };
}
path.CanvasDesigner2ViewModel = CanvasDesigner2ViewModel;
