import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasureRelationship} from './MeasureRelationship'
import {RankingMeasureRelationshipMixin} from './RankingMeasureRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { DimensionalMeasure } from './DimensionalMeasure'
//import { RankingMeasure } from './RankingMeasure'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasureRelationship","appbo/smm/RankingMeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasureRelationship,RankingMeasureRelationshipMixin
, DimensionalMeasure
, RankingMeasure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!DimensionalMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasure){
			DimensionalMeasure = importPath.DimensionalMeasure;
		}
        else {
            import('./DimensionalMeasure').then(({ default: DimensionalMeasure }) => {
                DimensionalMeasure = DimensionalMeasure;
            });
			/*require(["appbo/smm/DimensionalMeasure"],function(loadedModule){
				DimensionalMeasure = loadedModule;
			});*/
		}
	}
	//if(!RankingMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RankingMeasure){
			RankingMeasure = importPath.RankingMeasure;
		}
        else {
            import('./RankingMeasure').then(({ default: RankingMeasure }) => {
                RankingMeasure = RankingMeasure;
            });
			/*require(["appbo/smm/RankingMeasure"],function(loadedModule){
				RankingMeasure = loadedModule;
			});*/
		}
	//}
	var RankingMeasureRelationship = MeasureRelationship.extend(utils.customExtends({
		relations:RankingMeasureRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			MeasureRelationship.prototype.initialize.apply(this, arguments);
			if(attributes.influence){
				this.set('influence',path.Influence[attributes.influence instanceof Object ? attributes.influence.name : attributes.influence]);
			}
		}
		}
		, new RankingMeasureRelationshipMixin()
	));
	RankingMeasureRelationship["change:to"] = function (model, newName) {
		if(!newName){
			//console.log("removed to from : "+model.get('name'));
		}
    }
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	RankingMeasureRelationship.abstract = false;
	RankingMeasureRelationship.supported = !sp2Enabled;
	path.RankingMeasureRelationship = RankingMeasureRelationship;
	export {RankingMeasureRelationship};
//});