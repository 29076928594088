import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {PortDelegation} from './PortDelegation'
import {InputDelegationMixin} from './InputDelegationMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { InputPort } from './InputPort'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/PortDelegation","appbo/vdml/InputDelegationMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,PortDelegation,InputDelegationMixin
, InputPort
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!InputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.InputPort){
			InputPort = importPath.InputPort;
		}
        else {
            import('./InputPort').then(({ default: InputPort }) => {
                InputPort = InputPort;
            });
           /* require(["appbo/vdml/InputPort"], function (loadedModule) {
                InputPort = loadedModule;
			});*/
		}
	}
	if(!InputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.InputPort){
			InputPort = importPath.InputPort;
		}
        else {
            import('./InputPort').then(({ default: InputPort }) => {
                InputPort = InputPort;
            });
			/*require(["appbo/vdml/InputPort"],function(loadedModule){
				InputPort = loadedModule;
			});*/
		}
	}
	var InputDelegation = PortDelegation.extend(utils.customExtends({
		relations:InputDelegationMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			PortDelegation.prototype.initialize.apply(this, arguments);
		}
		}
		, new InputDelegationMixin()
	));
	
	InputDelegation.abstract = false;
	path.InputDelegation = InputDelegation;
	export {InputDelegation};
//});