import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasure} from './DimensionalMeasure'
import {CollectiveMeasureMixin} from './CollectiveMeasureMixin'
import {Accumulator} from './Accumulator'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { BaseNMeasureRelationship } from './BaseNMeasureRelationship'
import { Operation } from './Operation'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasure","appbo/smm/CollectiveMeasureMixin","appbo/smm/Accumulator"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasure,CollectiveMeasureMixin,Accumulator
, BaseNMeasureRelationship
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!BaseNMeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.BaseNMeasureRelationship){
			BaseNMeasureRelationship = importPath.BaseNMeasureRelationship;
		}
        else {
            import('./BaseNMeasureRelationship').then(({ default: BaseNMeasureRelationship }) => {
                BaseNMeasureRelationship = BaseNMeasureRelationship;
            });

			/*require(["appbo/smm/BaseNMeasureRelationship"],function(loadedModule){
				BaseNMeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
        else {
            import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });

			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	var CollectiveMeasure = DimensionalMeasure.extend(utils.customExtends({
		relations:CollectiveMeasureMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			DimensionalMeasure.prototype.initialize.apply(this, arguments);
			if(attributes.scale){
				this.set('scale',path.ScaleOfMeasurement[attributes.scale instanceof Object ? attributes.scale.name : attributes.scale]);
			}
			if(attributes.accumulator){
				var sil = options ? options.silent:false;
				this.set('accumulator',Accumulator[attributes.accumulator instanceof Object ? attributes.accumulator.name : attributes.accumulator],{silent:sil});
			}
			//this.on('change:accumulator',this.checkAccumulatorForNull);
		}
		}
		, new CollectiveMeasureMixin()
	));
    CollectiveMeasure['change:accumulator'] = function (measure) {
        this.checkAccumulatorForNull(measure);
    }
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	CollectiveMeasure.abstract = false;
	CollectiveMeasure.supported = !sp2Enabled;
	path.CollectiveMeasure = CollectiveMeasure;
	export {CollectiveMeasure};
//});