import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { BusinessModelItem } from './BusinessModelItem'
import { BusinessModelItemMixin } from './BusinessModelItemMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Collaboration } from './Collaboration'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global","async","appbo/vdml/BusinessModelItem","appbo/vdml/BusinessModelItemMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,async,BusinessModelItem,BusinessModelItemMixin
, Collaboration){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Collaboration){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Collaboration){
			Collaboration = importPath.Collaboration;
		}
        else {
            import('./Collaboration').then(({ default: Collaboration }) => {
                Collaboration = Collaboration;
            });
            /*
			require(["appbo/vdml/Collaboration"],function(loadedModule){
				Collaboration = loadedModule;
			});
            */
		}
	}
export class ParticipantNetworkMixin {

    defaults() {
        var ret = {
            type: "vdml_ParticipantNetwork"
        }
        return jQuery.extend(BusinessModelItem.prototype.defaults.apply(this), ret);
    }
    static getMixinRelations() {
        return _.union([
            {
                type: Backbone.HasOne,
                containingClass: "vdml_ParticipantNetwork",
                key: "collaboration",
                relatedModel: "vdml.Collaboration",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            }
        ])
    }
    static getCumulativeMixinRelations() {
        if (!ParticipantNetworkMixin.cummulativeRelations) {
            ParticipantNetworkMixin.cummulativeRelations = _.union(ParticipantNetworkMixin.getMixinRelations()
                , BusinessModelItemMixin.getCumulativeMixinRelations()
            );
        }
        return ParticipantNetworkMixin.cummulativeRelations.slice();
    }
    static getSuperTypes() {
        return [
            "vdml_BusinessModelItem"
        ];
    }
    static getProperties() {
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "represents", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" }
        ]
    }
    getParent() {
        var container;
        if (!container) {
            container = this.get("participantNetworkOwner") ? this.get("participantNetworkOwner") : this.previousAttributes().participantNetworkOwner;
            if (container) {
                return container;
            }
        }
        return this;
    }
    getPackagePath(path) {
        if (!path) {
            path = [];
        }
        var container;
        if (!container) {
            container = this.get("participantNetworkOwner") ? this.get("participantNetworkOwner") : this.previousAttributes().participantNetworkOwner;
            if (container) {
                path.unshift({ id: this.get('id'), name: this.get('name') })
                return container.getPackagePath(path);
            }
        }
        return path;
    }
    getViewProperties(type) {
        return {
            templatePath: "views/vdml/views/properties/ParticipantNetworkPropertiesTemplate.html",
            templateName: "ParticipantNetworkPropertiesTemplate",
            viewTypeStr: "appviews/vdml/views/properties/ParticipantNetworkViewModel",
            tabId: "ParticipantNetworkView",
            tabName: "ParticipantNetwork"
        }
    }
    //#startCustomMethods

    addCollaborationToScenarioContexts(pn) {
        var vdm = this.getNestedParent();
        var scenarios = vdm.get('scenario');
        var coll = this.get('collaboration');
        if (coll && scenarios) {
            scenarios.each(function (scenario) {
                var isCommon = scenario.get('isCommon');
                if (!isCommon || isCommon === false) {
                    var contColl = scenario.get('contextCollaboration');
                    contColl.add(coll);
                }
            });
        }
    };

    removeCollaborationToScenarioContexts(businessModel) {
        var self = this;
        var collborationModel = self.get('collaboration') ? self.get('collaboration') : self.previousAttributes().collaboration;
        var collPackage = collborationModel ? collborationModel.getNestedParent() : null;
        var vdm = businessModel.getNestedParent();
        var colUsed = false;
        if (vdm !== collPackage) {
            for (var i = 0; i < vdm.get('businessModel').length; i++) {
                if (vdm.get('businessModel').at(i) !== businessModel) {
                    colUsed = businessModel.get('participantNetwork').findWhere({ 'collaboration': collborationModel });
                    if (colUsed) {
                        break;
                    }
                }
            }
            if (!colUsed) {
                var scenarios = vdm.get('scenario');
                scenarios.each(function (scenario) {
                    var isCommon = scenario.get('isCommon');
                    if (!isCommon || isCommon === false) {
                        var contColl = scenario.get('contextCollaboration');
                        contColl.remove(collborationModel);
                    }
                });
            }
        }
    };

    destroyParticipantNetwork() {
        var self = this;
        var collaboration = self.get('collaboration') ? self.get('collaboration') : self.previousAttributes().collaboration;
        var currentbm = self.get('participantNetworkOwner') ? self.get('participantNetworkOwner') : self.previousAttributes().participantNetworkOwner;
        if (!collaboration) {
            var collKeyContents = self._relations.collaboration.keyContents;
            if (collKeyContents instanceof Backbone.Model) {
                collaboration = collKeyContents;
            } else {
                collaboration = self._relations.collaboration.relatedModel.find({ id: self._relations.collaboration.keyContents });
            }
        }
        if (!currentbm) {
            var currentbmKeyContents = self._relations.participantNetworkOwner.keyContents;
            if (currentbmKeyContents instanceof Backbone.Model) {
                currentbm = collKeyContents;
            } else {
                currentbm = self._relations.participantNetworkOwner.relatedModel.find({ id: self._relations.participantNetworkOwner.keyContents });
            }
        }
        if (collaboration && currentbm) {
            var bmCustomers = currentbm.get('bmCustomer');
            var bmNetworkPartner = currentbm.get('bmNetworkPartner');
            var bmRoles = currentbm.get('bmRole');
            var collRoles = collaboration.get('collaborationRole');
            var assignmentColl = [];
            for (var j = 0; j < collRoles.length; j++) {
                var role = bmRoles.findWhere({ id: collRoles.at(j).get('id') });
                if (role) {
                    var assignment = role.get('roleAssignment').at(0);
                    var participant = assignment.get('participant');
                    if (currentbm.get('business') === participant) {
                        assignmentColl.push(assignment);
                        bmRoles.remove(role);
                    }
                    else {
                        for (var m = 0; m < bmCustomers.length; m++) {
                            if (bmCustomers.at(m).get('customer') === participant && bmCustomers.at(m).get('customerRole').findWhere({ id: role.get('id') })) {
                                var reUsed = currentbm.checkBmCustomerReused(bmCustomers.at(m), collaboration);
                                if (!reUsed) {
                                    currentbm.businessCanvasDeletions([participant]);
                                    bmCustomers.at(m).destroy();
                                }
                                assignmentColl.push(assignment);
                                break;
                            }
                        }
                        for (var n = 0; n < bmNetworkPartner.length; n++) {
                            if (bmNetworkPartner.at(n).get('networkPartner') === participant && bmNetworkPartner.at(n).get('networkPartnerRole').findWhere({ id: role.get('id') })) {
                                var reUsed = currentbm.checkBmNetworkPartnerReused(bmNetworkPartner.at(n), collaboration);
                                if (!reUsed) {
                                    currentbm.businessCanvasDeletions([participant]);
                                    bmNetworkPartner.at(n).destroy();
                                }
                                assignmentColl.push(assignment);
                                break;
                            }
                        }
                        bmRoles.remove(role);
                    }
                }
            }

			/*function checkAssignmentUsed(assignment,callback){
				var bm = this;
				var assignmentRole = assignment.get('assignedRole');
				var assignmentParticipant = assignment.get('participant');
				if(assignmentParticipant){debugger
					assignmentParticipant.getBusinessModelsInvolved(function(businessModels){
						var assignmentUsed = false;
						for(var i=0;i<businessModels.length;i++){
							if(businessModels[i] !== bm){
								var bmRoles = businessModels[i].get('bmRole');
								if(bmRoles.findWhere({id:assignmentRole.get('id')})){
									assignmentUsed = true;
									break;
								}
							}
						}
						if(!assignmentUsed){
							assignment.destroy();
							assignmentRole.destroy();
						}
						callback();
					});					
				}else{
					callback();
				}
	
			}*/

            currentbm.getAllInstances(function (businessModels) {
                var collUsed = false;
                for (var i = 0; i < businessModels.length; i++) {
                    var pnList = businessModels[i].get('participantNetwork');
                    if (businessModels[i] !== currentbm && pnList.findWhere({ collaboration: collaboration })) {
                        collUsed = true;
                        break;
                    }
                }
                _.each(assignmentColl, function (assignment) {
                    var assignmentRole = assignment.get('assignedRole');
                    var assignmentParticipant = assignment.get('participant');
                    if (assignmentParticipant) {
                        var assignmentUsed = false;
                        for (var i = 0; i < businessModels.length; i++) {
                            //if(businessModels[i] !== bm){
                            var bmRoles = businessModels[i].get('bmRole');
                            if (bmRoles.findWhere({ id: assignmentRole.get('id') })) {
                                assignmentUsed = true;
                                break;
                            }
                            //}
                        }
                        if (!assignmentUsed) {
                            assignment.destroy();
                            assignmentRole.destroy();
                        }
                    }
                });


                //async.eachSeries(assignmentColl, checkAssignmentUsed.bind(currentbm), function(err){
                if (!collUsed) {
                    collaboration.destroy();
                }
                if (bmCustomers.length === 0 && bmNetworkPartner.length === 0 && currentbm.get('participantNetwork').length === 1) {
                    currentbm.set('business', null);
                }
                //});
            }, collaboration.getNestedParent());
        }
    };
    //#endCustomMethods	
    
}
path.ParticipantNetworkMixin = ParticipantNetworkMixin;
	//return ParticipantNetworkMixin;
//});