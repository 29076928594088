import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {CollaborationDiagram} from './CollaborationDiagram'
import {CollaborationDiagramMixin} from './CollaborationDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ProcessDiscoveryDiagram } from './ProcessDiscoveryDiagram'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/CollaborationDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,CollaborationDiagramMixin
, ProcessDiscoveryDiagram){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ProcessDiscoveryDiagram){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ProcessDiscoveryDiagram){
			ProcessDiscoveryDiagram = importPath.ProcessDiscoveryDiagram;
		}
		else{
            import('./ProcessDiscoveryDiagram').then(({ default: ProcessDiscoveryDiagram }) => {
                ProcessDiscoveryDiagram = ProcessDiscoveryDiagram;
			});
			/*require(["appbo/vdml/ProcessDiscoveryDiagram"],function(loadedModule){
				ProcessDiscoveryDiagram = loadedModule;
			});*/
		}
	}
	export class RoleCollaborationDiagramMixin {

	defaults(){
		var ret = {
			type: "vdml_RoleCollaborationDiagram"
		}
		return jQuery.extend(CollaborationDiagram.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		 if(!RoleCollaborationDiagramMixin.cummulativeRelations) {
            RoleCollaborationDiagramMixin.cummulativeRelations = _.union(RoleCollaborationDiagramMixin.getMixinRelations()
                , CollaborationDiagramMixin.getCumulativeMixinRelations()
            );
         }
		 return RoleCollaborationDiagramMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_CollaborationDiagram"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("processDiscoveryDiagram") ? this.get("processDiscoveryDiagram") : this.previousAttributes().processDiscoveryDiagram;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("processDiscoveryDiagram") ? this.get("processDiscoveryDiagram") : this.previousAttributes().processDiscoveryDiagram;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/RoleCollaborationDiagramPropertiesTemplate.html",
			templateName : "RoleCollaborationDiagramPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/RoleCollaborationDiagramViewModel",
			tabId : "RoleCollaborationDiagramView",
			tabName: "RoleCollaborationDiagram"
		}
	}
	
	}
	path.RoleCollaborationDiagramMixin = RoleCollaborationDiagramMixin;
	//return RoleCollaborationDiagramMixin;
//});