import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasureRelationship} from './MeasureRelationship'
import {RescaledMeasureRelationshipMixin} from './RescaledMeasureRelationshipMixin'
/*import {DimensionalMeasure} from './DimensionalMeasure'
import {RescaledMeasure} from './RescaledMeasure'*/

import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasureRelationship","appbo/smm/RescaledMeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasureRelationship,RescaledMeasureRelationshipMixin
, RescaledMeasure
, DimensionalMeasure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	//if(!RescaledMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RescaledMeasure){
			RescaledMeasure = importPath.RescaledMeasure;
		}
		else{
			import('./RescaledMeasure').then(({ default: RescaledMeasure }) => {
				RescaledMeasure = RescaledMeasure;
			});
			/*require(["appbo/smm/RescaledMeasure"],function(loadedModule){
				RescaledMeasure = loadedModule;
			});*/
		}
	//}
	//if(!DimensionalMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasure){
			DimensionalMeasure = importPath.DimensionalMeasure;
		}
		else{
			import('./DimensionalMeasure').then(({ default: DimensionalMeasure }) => {
				DimensionalMeasure = DimensionalMeasure;
			});
			/*require(["appbo/smm/DimensionalMeasure"],function(loadedModule){
				DimensionalMeasure = loadedModule;
			});*/
		}
	//}
	var RescaledMeasureRelationship = MeasureRelationship.extend(utils.customExtends({
		relations:RescaledMeasureRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			MeasureRelationship.prototype.initialize.apply(this, arguments);
			if(attributes.influence){
				this.set('influence',path.Influence[attributes.influence instanceof Object ? attributes.influence.name : attributes.influence]);
			}
		}
		}
		, new RescaledMeasureRelationshipMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	RescaledMeasureRelationship.abstract = false;
	RescaledMeasureRelationship.supported = !sp2Enabled;
	path.RescaledMeasureRelationship = RescaledMeasureRelationship;
	export {RescaledMeasureRelationship};
//});