/**
 * 
 * copyright 2018 Nicolas Fournier.
 * email: nicolas@rousseaufournier.com
 * license: buy me a beer or a house.
 * 
 */
/**
 * 
 * copyright [year] [your Business Name and/or Your Name].
 * email: your@email.com
 * license: Your chosen license, or link to a license file.
 * 
 */
(function (factory) {
    /* Global define */
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Browser globals
        factory(window.jQuery);
    }
}(function ($) {
    /**
     * @class plugin.examplePlugin
     *
     * example Plugin
    */
    // Extends plugins for adding hello.
    //  - plugin is external module for customizing.
    $.extend($.summernote.plugins, {
        /**
         * @param {Object} context - context object has status of editor.
         */
        'tableofcontent': function (context) {
            var self = this;

            // ui has renders to build ui elements.
            //  - you can create a button with `ui.button`
            var ui = $.summernote.ui;
            var $note = context.layoutInfo.note;
            //var $code =   $note.summernote('code');
            var $editor = context.layoutInfo.editor;
            var $editable = context.layoutInfo.editable;
            var $toolbar = context.layoutInfo.toolbar;
            var options = context.options;
            var lang = options.langInfo;
            var insertData = new Map();
            context.memo('button.tableofcontent', function () {
                var button = ui.button({
                    contents: 'ToC',
                    tooltip: 'Create a table of content',
                    click: function () {
                        var allTags = $editable.wrapAll();
                        var headerTags = [];
                        if ($('#Toc')) {
                            $('#Toc').remove();
                        }
                        insertData.clear();
                        for (var i = 0; i < allTags[0].childNodes.length; i++) {
                            var tagEle = allTags[0].childNodes[i].nodeName.startsWith('H');
                            if (tagEle) {
                                headerTags.push({ node: allTags[0].childNodes[i], tagEle: allTags[0].childNodes[i].nodeName, eleText: allTags[0].childNodes[i].innerText });
                                insertData.set(allTags[0].childNodes[i].innerText, allTags[0].childNodes[i].innerText);
                            }

                        }
                        var node = document.createElement('div');
                        node.setAttribute("id", "Toc");
                        var space = ["&emsp; ", "&emsp; &emsp; ", "&emsp; &emsp; &emsp; ", "&emsp; &emsp; &emsp; &emsp; &emsp; ", "&emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;", "&emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; "]
                        var nodeData = "TABLE OF CONTENTS <br><br>";
                        var insertCount = 0;
                        var insertMap = new Map();
                        var priorityArray = [0, 0, 0, 0, 0, 0];
                        for (i = 0; i < headerTags.length; i++) {
                            if (priorityArray[parseInt(headerTags[i].tagEle.split("H")[1]) - 1] == 0){
                                priorityArray[parseInt(headerTags[i].tagEle.split("H")[1]) - 1] =1;
                            }
                        }
                        for (i = 0; i < priorityArray.length; i++) {
                            if (priorityArray[i] == 1) {
                                insertMap.set(i, insertCount);
                                insertCount++;
                            }
                        }
                        for (i = 0; i < headerTags.length; i++) {
                            if (headerTags[i].eleText.trim() != "") {
                                nodeData = nodeData + space[insertMap.get(parseInt(headerTags[i].tagEle.split("H")[1]) - 1)] + "&bull;&nbsp;" + '<span class="Toc">' + headerTags[i].eleText + "</span>";
                                if (i != headerTags.length - 1) {
                                    nodeData += "<br><br>";
                                }
                            }
                        }
                        nodeData = nodeData ;
                        node.innerHTML = nodeData;
                        $note.summernote('insertNode', node);
                        $("div")[0].scrollIntoView();
                    }
                });
               var $tableofcontent = button.render();
                return $tableofcontent;
            });
        }
    });
}));