import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BusinessIndicatorLibraryElement} from './BusinessIndicatorLibraryElement'
import {BusinessIndicatorDefinitionMixin} from './BusinessIndicatorDefinitionMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { BusinessIndicatorCategory } from './BusinessIndicatorCategory'

/*define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/smm/BusinessIndicatorLibraryElement", "appbo/smm/BusinessIndicatorDefinitionMixin"],
    function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, BusinessIndicatorLibraryElement, BusinessIndicatorDefinitionMixin
        , BusinessIndicatorCategory) {*/

        var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);

        if (!BusinessIndicatorCategory) {
            var importPath = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            if (importPath.BusinessIndicatorCategory) {
                BusinessIndicatorCategory = importPath.BusinessIndicatorCategory;
            }
            else {
                import('./BusinessIndicatorCategory').then(BusinessIndicatorCategory => {
                    BusinessIndicatorCategory = BusinessIndicatorCategory;
                });

                /*require(["appbo/smm/BusinessIndicatorCategory"], function (loadedModule) {
                    BusinessIndicatorCategory = loadedModule;
                });*/
            }
        }
        var BusinessIndicatorDefinition = BusinessIndicatorLibraryElement.extend(utils.customExtends({
            relations: BusinessIndicatorDefinitionMixin.getMixinRelations(),
            initialize: function (attributes, options) {
                BusinessIndicatorLibraryElement.prototype.initialize.apply(this, arguments);
            }
        }
            , new BusinessIndicatorDefinitionMixin()
        ));
		BusinessIndicatorDefinition.getInstance = function(name,description,owner){
			var measureid = DataManager.getDataManager().guidGeneratorByOwner(owner);
            var measureDef = new BusinessIndicatorDefinition({ id: measureid, name: name, description: description, version: owner.get('version'), businessIndicatorLibraryElementOwner:owner});
			owner.get('element').add(measureDef);
			return measureDef;
		};
        var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
        BusinessIndicatorDefinition.abstract = false;
        BusinessIndicatorDefinition.supported = !sp2Enabled;
        path.BusinessIndicatorDefinition = BusinessIndicatorDefinition;
        export {BusinessIndicatorDefinition};
    //});