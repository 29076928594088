import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmElement} from './SmmElement'
import {SmmElementMixin} from './SmmElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/SmmElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,SmmElementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	export class IntervalMixin {

	defaults(){
		var ret = {
			type: "smm_Interval"
		}
		return jQuery.extend(SmmElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!IntervalMixin.cummulativeRelations) {
            IntervalMixin.cummulativeRelations = _.union(IntervalMixin.getMixinRelations()
                , SmmElementMixin.getCumulativeMixinRelations()
            );
        }
		return IntervalMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_SmmElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" }
			/*{name : "maximumOpen",type : "EBoolean",defaultValue : "null",containingClass : "smm_Interval" },
			{name : "minimumOpen",type : "EBoolean",defaultValue : "null",containingClass : "smm_Interval" },
			{name : "maximumEndPoint",type : "EDouble",defaultValue : "null",containingClass : "smm_Interval" },
			{name : "minimumEndPoint",type : "EDouble",defaultValue : "null",containingClass : "smm_Interval" }*/
		]
	}
	getParent(){
		var container;
		return SmmElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return SmmElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/IntervalPropertiesTemplate.html",
			templateName : "IntervalPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/IntervalViewModel",
			tabId : "IntervalView",
			tabName: "Interval"
		}
	}
	
	}
	path.IntervalMixin = IntervalMixin;
	//return IntervalMixin;
//});