import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {Attribute} from './Attribute'
import {Annotation} from './Annotation'
import {ObjectReference} from './ObjectReference'
import { VdmlElementMixin } from './VdmlElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
export class VdmlElement2Mixin{

	static getSubModelTypes(){
		var ret = VdmlElementMixin.getSubModelTypes();
        ret['vdml_ProjectionProfile'] = 'vdml.ProjectionProfile';
		ret['vdml_PolynomialCoefficient'] = 'vdml.PolynomialCoefficient';
		ret['vdml_SeasonalFactor'] = 'vdml.SeasonalFactor';
		ret['vdml_VdmlElementContext'] = 'vdml.VdmlElementContext';
		return ret;
	}
    static getCumulativeMixinRelations(){
        if (!VdmlElement2Mixin.cummulativeRelations) {
            VdmlElement2Mixin.cummulativeRelations = _.union(VdmlElement2Mixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return VdmlElement2Mixin.cummulativeRelations.slice();
    }
	
}
utils.customExtendClass (VdmlElement2Mixin,new VdmlElementMixin());

path.VdmlElement2Mixin = VdmlElement2Mixin;
