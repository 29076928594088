import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global"],
//function(require,$,_, Backbone,ko, koMapping,kb,bootbox,DataManager,global){
	var path = DataManager.getDataManager().buildAppNsPath("beeppackage.views.properties",global.version);
export class PrototypeViewModel {
    constructor(model,options){
		var self = this;
		this.PrototypeViewModel = this;
		this.model = model;
		this.name = ko.observable("");
		this.description = ko.observable("");
		var dataManager = DataManager.getDataManager();
		this.localeManager = dataManager.get('localeManager');
		if(options && options.parentView){
			this.parentView = options.parentView;
			this.encodeId = ""+options.modalId;
			this.alternativeModel = options.addOptions.alternative;
			this.plusIconType = options.addOptions.plusIconType;
		}
		this.enableComplete = ko.observable(false);
		this.enablePackage = ko.observable(true);
		var allowedTypes = [];
		var moduleTypes = {};  
		this.types = ko.observableArray();
		if (options.onlyMap){
			self.types.push(options.onlyMap);
		} else {
			self.types.push(self.localeManager.get('BusinessModel'));
			self.types.push(self.localeManager.get('CaseModel'));
			self.types.push(self.localeManager.get('ProcessModel'));
			var targetLetter = '';
			var selectedArrow = $('#PrototypeHeading').find('.arrow_active')[0];
			if(selectedArrow && selectedArrow.id){
				targetLetter = selectedArrow.id.charAt(0);
				if(selectedArrow.id == "businessModelArrow"){
					targetLetter = "B";
				}
				if(selectedArrow.id == "capabilityMethod"){
					targetLetter = "P";
				}
			}
			self.types().sort((a, b) => {
				const aStartsWithLetter = a.toLowerCase().startsWith(targetLetter.toLowerCase());
				const bStartsWithLetter = b.toLowerCase().startsWith(targetLetter.toLowerCase());
			  
				if (aStartsWithLetter && !bStartsWithLetter) {
					return -1;
				} else if (!aStartsWithLetter && bStartsWithLetter) {
					return 1;
				} else {
					// If both start with the target letter or neither does, sort them alphabetically.
					return a.localeCompare(b);
				}
			});
		}
    	if(dataManager.isPrototypePurchased()){
            allowedTypes.push(self.localeManager.get('BusinessModel'));
			allowedTypes.push(self.localeManager.get('ProcessModel'));
			allowedTypes.push((self.localeManager.get('CaseModel')));
    	}
        
		  

		this.selectedType = ko.observable();
		this.packages = ko.observableArray();
		this.planBMs = ko.observableArray();
		this.selectedPackage = ko.observable();
		this.selectedPlanBM = ko.observable();
		
		$('#modal'+self.encodeId).on('shown.bs.modal', function () {
	          window.utils.focusElement("#newModal .focus-ele");
	    });
	    
        this.selectedType.subscribe(function (val) {
            if (val === 'Package') {
                self.enablePackage(false);
            }
            else if (val === 'Business Canvas') {
                self.enablePackage(true);
			}
	    	checkUniqueName(self.name());
	    });

	    this.selectedPlanBM.subscribe(function (val) {
	        checkUniqueName(self.name());
	    });
	    this.name.subscribe(function(val){
        	self.description(val);
        });
        
        if (self.alternativeModel) {
            var setPackage = function (package1) {
                var referenceExists = self.alternativeModel.get('phaseDesignPart').findWhere({ beepReference: package1.get('id') });
                if (!referenceExists || package1.get('id').indexOf("Common") > -1) {
                    return;
                }
                else {
                    var name = package1.get('name');
                    var packageModel = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: package1.id });
                    self.packages.push({ id: package1.get('id'), name: name, version: package1.get('version') });
                }
            };

            function loadEcoMapPackage(ecoMapPackage) {
                var referenceExists = self.alternativeModel.get('phaseDesignPart').findWhere({ beepReference: ecoMapPackage.get('id') });
                if (!referenceExists || ecoMapPackage.get('id').indexOf("Common") > -1) {
                    return;
                } else {
                    if (options.onlyMap){
                		if(referenceExists.get('beepReference').substring(0,referenceExists.get('beepReference').lastIndexOf('@')+1) !== self.alternativeModel.get('id') ){
                			return;
                		}
                	}
                    self.ecoMapPackages.push({ id: ecoMapPackage.get('id'), name: ecoMapPackage.get('name'), version: ecoMapPackage.get('version') });
                }
            }
            function handleAddClick(commonAlt) {
                function loadDocuments(results) {
                    for (var i = 0; i < results.length; i++) {
                        var type = results.at(i).get('type');
                        if (type === "vdml_ValueDeliveryModel") {
                            setPackage(results.at(i));
                        } else if (type === "vdml_BusinessModel") {
                            self.planBMs.push({ id: results.at(i).get('id'), name: results.at(i).get('name'), version: results.at(i).get('version'), parent: results.at(i).get('parent') });
                        }
                    }
                }
                function loadProDocuments(results) {
                    self.packages([]);
                    loadDocuments(results);
                    self.packages.push({ name: "New" });
                    self.planBMs.push({ name: "Plan" });
				}
				var searchTypes = '';
				/*if (self.plusIconType == "businessModelArrow") {
					searchTypes = 'vdml_ValueDeliveryModel,vdml_BusinessModel';
				}
				else if (self.plusIconType == "caseModelArrow") {
					searchTypes = 'vdml_ValueDeliveryModel';
				} else if (self.plusIconType == "processModelArrow") {
					searchTypes = 'vdml_ValueDeliveryModel';
				} else {*/
					searchTypes = 'vdml_ValueDeliveryModel,vdml_BusinessModel';
				//}
                var nameNS = 'vbc:cmof_EObject-name';
				DataManager.getDataManager().getAllDocumentsCollectionOfType(self.alternativeModel, searchTypes, loadProDocuments, true, null, null, nameNS);
            }

            var planId = self.model.id.substr(self.model.id.lastIndexOf('@'));
            var commonAltId = planId + "-Common@";
            function waitAndLoadRDFModel() {
                var commonRdfModel = DataManager.getDataManager().getRDFModel(commonAltId);
                if (commonRdfModel) {
                    handleAddClick(commonAltId);
                } else {
                    setTimeout(waitAndLoadRDFModel, 5);
                }
            }
            waitAndLoadRDFModel();

        }		
		
		this.cleanModal = function(){
			window.cleanDialogModel(self.encodeId,self);	 
		}
		
		this.createPackage = function(){
			if(self.parentView){
				if(self.name() !== null && self.name() !== "") {
                    if (allowedTypes.indexOf(self.selectedType()) === -1) {                        
                        window.utils.limitExceeded();                   
						window.cleanDialogModel(self.encodeId,self);
						return false;
					}else {
						return options.callback(self,self.encodeId,self.selectedType(),moduleTypes);
					}		
				}
			}
		}
		function checkUniqueName(name){
			var items = [];
            /*if (self.selectedType() === 'Business Model') {
                items = self.planBMs();
            } else if (self.selectedType() === 'Package') {
                items = self.packages();
            } else {
                items = name;
            }*/
			items = self.planBMs();
			for(var i=0;i<items.length;i++){			
				if((self.selectedPackage() && self.selectedType() === self.localeManager.get('BusinessModel') && items[i].parent === self.selectedPackage().id && items[i].name === name.trim()) || 
					(self.selectedType() == 'Package' && items[i].value === name.trim())){
                    $('#duplicateName').text("" + self.localeManager.get('DuplicateAlert',self.selectedType()));
					self.enableComplete(false);
					return;
                } else if ((self.selectedPackage() && self.selectedType() === DataManager.getDataManager().get('localeManager').get('CaseModel') && items[i].parent === self.selectedPackage().id && items[i].name === name.trim()) ||
                        (self.selectedType() == 'Package' && items[i].value === name.trim())){
                    $('#duplicateName').text("" + self.localeManager.get('DuplicateAlert',self.selectedType()));
                    self.enableComplete(false);
                    return;
                } else if ((self.selectedPackage() && self.selectedType() === DataManager.getDataManager().get('localeManager').get('ProcessModel') && items[i].parent === self.selectedPackage().id && items[i].name === name.trim()) ||
                    (self.selectedType() == 'Package' && items[i].value === name.trim())) {
                     $('#duplicateName').text("" + self.localeManager.get('DuplicateAlert',self.selectedType()));
                     self.enableComplete(false);
                     return;
                }

			}
			$('#duplicateName').text("");
			if(name.trim() !== "" && self.selectedType()){
				self.enableComplete(true);				
			}else{
				self.enableComplete(false);
			}
		}
		this.afterRenderView = function(){
			$("#packageName"+self.encodeId).keyup(function (view) {
                var newValue = view.currentTarget.value;
                if(newValue.trim() !== '') {
                    checkUniqueName(newValue);
                }
                else {
                	self.enableComplete(false);
                }
            });
            $('#editDetailsModalContent' + self.encodeId).resize(function () {
                $('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
            })
            if(options.onlyMap){
            	$('#type').attr('disabled',true);
			}
            window.utils.applyFontSizeInDialog();
			//self.loadBMs();
		}
				
//#endCustomMethods
		
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),[
		,'name'
		,'description'
		,'Complete'
		,'Close'
		,'selectType'
		,'selectPackage'
		,'CreateModel'
		,'selectPlanBusinessModel']);
	}

	static getInstance(model,options){
		var view = new PrototypeViewModel(model,options);
		return view;
	}
}
path.PrototypeViewModel = PrototypeViewModel;