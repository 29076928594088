import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { MeasuredCharacteristic } from './MeasuredCharacteristic'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, MeasuredCharacteristic){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
		else{
            import('./MeasuredCharacteristic').then(({ default: MeasuredCharacteristic }) => {
                MeasuredCharacteristic = MeasuredCharacteristic;
			});
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	export class OperandMixin {

	defaults(){
		var ret = {
			type: "vdml_Operand"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"vdml_Operand",
			key:"measuredCharacteristic",
			relatedModel:"vdml.MeasuredCharacteristic",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	
	static getCumulativeMixinRelations(){
		if (!OperandMixin.cummulativeRelations) {
            OperandMixin.cummulativeRelations = _.union(OperandMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return OperandMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "alias",type : "EString",defaultValue : "null",containingClass : "vdml_Operand" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("operandOwner") ? this.get("operandOwner") : this.previousAttributes().operandOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("operandOwner") ? this.get("operandOwner") : this.previousAttributes().operandOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/OperandPropertiesTemplate.html",
			templateName : "OperandPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/OperandViewModel",
			tabId : "OperandView",
			tabName: "Operand"
		}
	}
	
	}
	path.OperandMixin = OperandMixin;
	//return OperandMixin;
//});