import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../../../libs/bootbox/bootbox'
import { CaseDetailsViewModel } from './CaseDetailsViewModel'
import { BusinessModel } from '../../../../../bo/vdml/BusinessModel'
import { Participant } from '../../../../../bo/vdml/Participant'
import { ValueElement } from '../../../../../bo/vdml/ValueElement'
import { CmmnDiagram } from '../../../../../bo/vdml/CmmnDiagram'
import { CmmnDiagramMixin } from '../../../../../bo/vdml/CmmnDiagramMixin'
import { BMGuidanceViewModel } from '../../../../help/views/properties/BMGuidanceViewModel'
import { CodeContainer } from '../../../../../bo/beeppackage/CodeContainer'
import { EcoMapDiagramMixin } from '../../../../../bo/vdml/EcoMapDiagramMixin'
import * as CmmnModeler from '../../../../../../../libs/bower-cmmn-js/dist/cmmn-modeler.development'
import * as CmmnNavigatedViewer from '../../../../../../../libs/bower-cmmn-js/dist/cmmn-navigated-viewer.development'


/*define(["require", "jquery", "underscore", "backbone", "knockout", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/BusinessModel", "appbo/vdml/Participant", "appbo/vdml/ValueElement", "appbo/vdml/CmmnDiagram", "appbo/vdml/CmmnDiagramMixin", "knockback", "cmmnmodeler", "cmmnnavigatedviewer", "appviews/help/views/properties/BMGuidanceViewModel", "appbo/beeppackage/CodeContainer", "appviews/vdml/views/designer/caseModel/CaseDetailsViewModel","appbo/vdml/EcoMapDiagramMixin"],
    function (require, $, _, Backbone, ko, bootbox, DataManager, global, BusinessModel, Participant, ValueElement, CmmnDiagram, CmmnDiagramMixin, kb, CmmnModeler, CmmnNavigatedViewer, BMGuidanceViewModel, CodeContainer, CaseDetailsViewModel, EcoMapDiagramMixin) {*/
var path = DataManager.getDataManager().buildAppNsPath("vdml.views.designer.caseModel", global.version);
export class CmmnDiagramViewModel {
	constructor(model, options) {
		var self = this;
		$("#diagramcss").attr("href", "js/libs/bower-cmmn-js/dist/assets/diagram-js.css");
		this.init(model, options);
	}
	getMappedObj(type) {
		var shapeType1 = type.split(':');
		var shapeType = shapeType1[1];
		var typeToDisplay = shapeType.split(/(?=[A-Z])/).join(' ');
		return typeToDisplay;

	}
	createRevisionBasedOnContext(pack,callback){
		if (window.checkContextForRevision()) {
			window.utils.stopSpinner('cmmnSpinner');
			window.utils.startSpinner('revisionSpinner', "Creating a copy...");
			function fetchBmModel(newVdmPackage){
					if(newVdmPackage) {
						DataManager.getDataManager().releaseSaveLock();
						DataManager.getDataManager().set('isActive',false);
						callback(newVdmPackage);
						/*DataManager.getDataManager().saveData({
							success:function(){
								callback(newVdmPackage);
							}, 
							error:function(){
								callback(newVdmPackage);
							}, 
							persist:true});	*/
					}
				}
			window.setTimeout(function(){
				DataManager.getDataManager().acquireSaveLock(function () {
					pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
				});
			},100);	
		}
		else {
			callback(pack);
		}
	};
	getViewportSize(w) {
	// Use the specified window or the current window if no argument
		w = w || window;

		// This works for all browsers except IE8 and before
		if (w.innerWidth != null) return { w: w.innerWidth, h: w.innerHeight };

		// For IE (or any browser) in Standards mode
		var d = w.document;
		if (document.compatMode == "CSS1Compat")
			return {
				w: d.documentElement.clientWidth,
				h: d.documentElement.clientHeight
			};

		// For browsers in Quirks mode
		return { w: d.body.clientWidth, h: d.body.clientHeight };
	}
	openDiagram(xml,callback) {
		var self = this;
		self.modeler.importXML(xml, function (err) {
			if (err) {
				console.error(err);
			}
			self.modeler.get("canvas").zoom("fit-viewport", true);
			//$('svg').css('margin-left', 70);
			if(!self.showDashboardCmmn){
					var height = self.model.get('height');
					if (!height) {
						height = self.getViewportSize().h * 0.80 + $('.navbar').height();
					}
					$("#js-canvas" + self.encodeId).height(height);
			}
			self.loadTable();
			//$('#js-properties').height(getViewportSize().h);
			window.utils.stopSpinner('caseSpinner');
			if (callback) {
				callback();
			}
		});
	}
	showProperties(data,event) {
		var self = window.vdmModelView;
		if(self.editMode){
			var id = window.utils.htmlEscape(window.guidGenerator());
			var elementRegistry = self.modeler.get('elementRegistry');
			var options = { businessObject: data.businessObject, element: elementRegistry.get(data.businessObject.id), width: '660px' };
			window.getAndCreateModalDialog(self, id,EcoMapDiagramMixin, self.model, "ShapeProperties", null, options);
		}
	}
	loadTable(event){ 
		var self = this;
		self.shapesList.removeAll();
		self.unsortedShapesList = [];
		var shapes ,definitions ,cmmn ,modeler = self.modeler;
		if(modeler) {
			definitions = modeler._definitions;
		}
		if(definitions) {
			cmmn = definitions.CMMNDI.diagrams;
		}			
		if(cmmn && cmmn.length) {
			shapes = cmmn[0].diagramElements;
		}
		if(shapes){ 
			for (var i = 0; i < shapes.length; i++) {
				if (event && event.type === 'shape.remove' && event.element.id === shapes[i].id) {
					continue;
				}
				var type, name;
				type = shapes[i].$type;
				if (!shapes[i].cmmnElementRef) {                        
					continue;
				}
				if (shapes[i].cmmnElementRef.$type == "cmmn:PlanItem" || shapes[i].cmmnElementRef.$type == "cmmn:CaseFileItem") {
					type = shapes[i].cmmnElementRef.definitionRef.$type;
					name = shapes[i].cmmnElementRef.definitionRef.name
				} else {
					type = shapes[i].cmmnElementRef.$type;
					name = shapes[i].cmmnElementRef.name
				}
				if (type === 'cmmn:PlanItemOnPart') { 
					shapes[i].isStandardEventVisible = false;
					shapes[i].cmmnElementRef.standardEvent = null;
					continue;
				}
				//var typeToDisplay = self.mappingObject[type].name;
				var typeToDisplay = self.getMappedObj(type);                 
				if(shapes[i].cmmnElementRef.name){
					name = shapes[i].cmmnElementRef.name;
				}
				var mappingEleId = shapes[i].mid;
				var packId = shapes[i].mpid;
				var mappingEleName = '';
				if (mappingEleId) {
					self.model.getMappedElement(mappingEleId, packId, type, shapes[i], function (model) {debugger
						mappingEleName = model? model.get('name') : '';
					});
				}
				if (typeToDisplay && type != "cmmn:TextAnnotation" && type != 'cmmn:Association' && type != 'cmmn:ExitCriterion' && type != 'cmmn:EntryCriterion') {
					self.shapesList.push({ businessObject: shapes[i], name: name, type: typeToDisplay, mappedEle: mappingEleName });
					self.unsortedShapesList.push({ businessObject: shapes[i], name: name, type: typeToDisplay, mappedEle: mappingEleName });
				}
			
			}
		}
		self.setResizeHeight();
		self.shapesList.sort(self.sortShapesInTableByName);
	}
	
	cleanUp() {
		var self = this;
		if (self.modeler) {
			self.modeler.destroy();
		}
		if (ko.components.isRegistered('CaseDetails')) {
			ko.components.unregister('CaseDetails');
		}
		if(ko.components.isRegistered('CmmnGuidance')){
			ko.components.unregister('CmmnGuidance');
		}
		$("#diagramcss").attr("href", "js/libs/bower-bpmn-js/dist/assets/diagram-js.css");
		window.utils.loadTemplate(null,true);
		if(self.spliter){
			self.spliter.enhsplitter('remove');
		}
		/*if(self.loadedCodedContainers){
			var codeContainersList = self.loadedCodedContainers;
			_.each(codeContainersList, function (codeContainer) {
				codeContainer.save(null,{
					success: function () {
						DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
					},
					error:function(error){
						console.log(error);
						//DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
					}
				});
			});
			codeContainersList.length = 0;
		}*/
	}
	createRevision(newXML,callback,depPacks) {
		var self = this;
		window.utils.startSpinner('revisionSpinner', "Creating a copy...");
		setTimeout(function(){
			self.model.getNestedParent().createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), function (newPackage) {
				var newRep = DataManager.getDataManager().getRepositoryId(newPackage.id);
				var modelSuffix = self.model.id.substr(self.model.id.lastIndexOf('@') + 1);
				var newDiagram = Backbone.Relational.store.getObjectByName("vdml.CmmnDiagram").find({ id: newRep + modelSuffix });
				var oldId = "Case_"+ window.utils.htmlEscape(self.model.id);
				var newId = "Case_"+ window.utils.htmlEscape(newDiagram.id);
				newXML = newPackage.fixXMLOnRevision(newXML,oldId,newId);
				newDiagram.set('data', newXML, { silent: true });
				window.utils.stopSpinner('revisionSpinner');
				callback(newPackage,newDiagram);
			},depPacks);
		},10);
	}
	saveDiagram(view,event,callback,revisionCmmn,vdmlPackageRevision,enableComplete){
		var self = this;
		if (self.model) {
			window.utils.startSpinner('saveSpinner', "Saving Process...");
			setTimeout(function () {
				self.modeler.saveXML({ format: true }, function (err, xml) {
					if (xml) {
						//var oldData = self.model.get('data');
						if (window.checkContextForRevision()) {
							if(revisionCmmn){
								if(vdmlPackageRevision){
									var newXml = window.utils.replaceAll(xml,DataManager.getDataManager().get('currentWorkspace').id,DataManager.getDataManager().get('viewAlternative'));
									self.model.set('data', newXml);
								}else{
									self.model.set('data', xml);
								}
								self.enableComplete(false);
								window.utils.stopSpinner('saveSpinner');
								if(callback){
									callback();
								}else {
									DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + revisionCmmn.getNestedParent().get('id') + "/ecomap_Cmmn/" + revisionCmmn.getNestedParent().get('version') + "/" + revisionCmmn.get('id') + "/vdml_CmmnDiagram/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
								}	
							}else {
								//self.model.set('data', newXml, { silent: true });
								if(!revisionCmmn && !vdmlPackageRevision && !self.enableComplete()) {
									window.utils.stopSpinner('saveSpinner');
									if(callback){
										callback();
									} 		
								}else {
									var ecoPackage = self.model.getNestedParent();
									var depPacks = ecoPackage.get('dependentPackage')?ecoPackage.get('dependentPackage').models.concat():[];
									self.createRevision(xml,function(newPackage,newDiagram){
										self.enableComplete(false);
										window.utils.stopSpinner('saveSpinner');
										if(callback){
											callback();
										}else{
											DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newPackage.get('id') + "/ecomap_Cmmn/" + newPackage.get('version') + "/" + newDiagram.get('id') + "/vdml_CmmnDiagram/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
										}
									},depPacks);
								}
							}
						} else {
							self.model.set('data', xml);
							self.enableComplete(false);
							window.utils.stopSpinner('saveSpinner');
							if(callback){
								callback();
							}
						}
					}else {
						self.enableComplete(false);
						window.utils.stopSpinner('saveSpinner');
						if(callback){
							callback();
						}
					}				        
				});
			},100);
		}else {
			callback();
		}
	};
	
	hasUnsavedData(){
		if(this.enableComplete() /*&& !self.viewMode()*/) {
			return true;	
		}else {
			return false;
		}	
	};
	
	saveDataBeforeClosing(callback) {
		var self = this;
		self.saveDiagram(null,null,callback);
	};
	
	setResizeHeight(view, event) {
		var self = this;
		if (!self.showDashboardCmmn){
			setTimeout(function () {
				var propertiesNode = 0;
				if ($('.propertiesArea').width() > 10) {
					propertiesNode = $('.propertiesArea').outerHeight();
				}
				//var baseHeight = _.max([bmNode.outerHeight(),propertiesNode.outerHeight()])+20;
				//var topAreaHeight = $('.navbar').outerHeight();
				var lhsHeight = $("#js-canvas"+self.encodeId).outerHeight() + 30;
				if($('#mainTab' +self.encodeId).find('.active a span').text() === DataManager.getDataManager().get('localeManager').get('shapeDetails')){
					lhsHeight = $('.table-responsive').outerHeight() + $('.table-responsive').outerHeight()/3 ;
				}
				var windowHeight = window.innerHeight;
				var baseHeight = _.max([lhsHeight, propertiesNode, windowHeight]);
				if (self.spliter) {
					self.spliter.css('height', baseHeight + 'px');
					if ($('.propertiesArea').width() < 5 && Math.abs($(window).width() - $('.modeler').width()) < 30) {
						var windowWidth = $(window).width() - 15;
						$('.splitter_bar').css('left', windowWidth);
					}
					$("#splitPanels"+self.encodeId).enhsplitter('refresh');
				}
				var rightTab = $('#rightTab'+self.encodeId);
				if(rightTab.width() > 0){
					if($('#rightTab'+self.encodeId+' .active a')[0]){
						var propDivId = $('#rightTab'+self.encodeId+' .active a')[0].hash;
						if(propDivId !== null && $('#rightTab .dropdown .active a')[0]){
							propDivId = $('#rightTab .dropdown .active a')[0].hash;
						}
						var propNode = $(''+propDivId);
					}
				}
				if(rightTab.width() > 10 && rightTab.children().last()[0].classList.contains('dropdown') /*&& rightTab.height() < 50*/){
					var dropdownDiv = rightTab.children().last();
					var appendList = dropdownDiv.find('li');
					var removedList = [];
					for(var i=0;i<appendList.length;i++){
							dropdownDiv.before(appendList[i]);
							removedList.push(appendList[i]);
					}
					if(appendList.length === removedList.length){
						dropdownDiv.remove();
					}
				}
				if(rightTab.width() > 10 && rightTab.width() < 415 && rightTab.children().length > 2/* && rightTab.height() > 50*/){
					var rightChildren = rightTab.children();
					var appendList = [];
					for(var i=rightChildren.length;i>0;i--){
						if(!rightChildren[i-1].classList.contains('dropdown')){
							appendList.push(rightChildren[i-1]);
							if(rightTab.width() < 375 && rightChildren[i-2]){
								appendList.push(rightChildren[i-2]);
								break;
							}else {
								break;
							}
						}
					}
					if(!rightChildren.last()[0].classList.contains('dropdown')){
						rightTab.append('<li class="dropdown"><a class="dropdown-toggle" data-toggle="dropdown" href="#"><span class="caret"></span></a><ul style="right:0" class="dropdown-menu pull-right"></ul></li>');
					}
					for(var i=0;i<appendList.length;i++){
						rightTab.children().last().find('ul').prepend(appendList[i]);
					}
				}
				if(rightTab.children().last()[0]&& rightTab.children().last()[0].classList.contains('dropdown') && propNode && propNode.parent()[0] && propNode.parent()[0].classList.contains('tab-content')){
					var dropdownDiv = rightTab.children().last();
					var appendList = dropdownDiv.find('li');
					for(var i=0;i<appendList.length;i++){
						appendList.eq(i).removeClass('active');
					}	
				}
				if($('#mainTab' +self.encodeId).find('.active a span').text() === "showMap"){
					self.modeler.get("canvas").zoom("fit-viewport", true);
				}
			}, 300);	
		}
	};
	/*this.getNumberOfMappings = function (mid,type,event) {
		var shapes = self.modeler._definitions.rootElements[0].flowElements;
		var count =0;
		
		for (var i = 0; i < shapes.length; i++) {
			if (event && event.type === 'shape.remove' && event.element.id === shapes[i].id) {
				continue;
			}
			if (type !== 'vdml:BusinessItem') {
				if(shapes[i].get('mid') === mid){
					count++;
				}
			}else{
				var flows = shapes.get('flows');
				for(var j=0;j<flows.length;j++){
					if(flows[j].get('mid') === mid){
						count++;
					}
				}
			}
		}
		return count;
	}*/

	getJSONObject(callback) {
		var self = this;
		if (self.jsonObject) {
			callback(self.jsonObject)
		} else {
			$.getJSON('js/libs/bower-cmmn-js/dist/vmpextension.json', function (extModel) {
				self.jsonObject = extModel;
				callback(extModel);
			})
		}
	}
	
	afterRenderView(redraw,view) {
		var self = this;
		var CustomContextPadProvider = function(injector, connect) {
			var self = this;
			function showPropertySheet(event, element, autoActivate) {
				var id = element.businessObject.id;
				if (id) {
					var id = window.utils.htmlEscape(window.guidGenerator());
					var user;
					var options = {};
					options.businessObject = element.businessObject;
					options.element = element;
					options.width = '660px';
					function getCaseMapId(element) {
						if (element.parent.type == "cmmndi:CMMNDiagram") {
							return element.parent.id;
						} else {
							return getCaseMapId(element.parent);
						}
					}
					var parentView = window.vdmModelView.DashboardViewModel ? window.vdmModelView.mapInstance[getCaseMapId(element)] : window.vdmModelView;
					window.getAndCreateModalDialog(parentView, id, EcoMapDiagramMixin, parentView.model, "ShapeProperties", null, options);
				}
			}
			//var baseFunction = injector._providers.contextPadProvider[1].prototype.getContextPadEntries.bind(this);
			injector.invoke(injector._providers.contextPadProvider[1], this);
			var cached = injector._providers.contextPadProvider[1].prototype.getContextPadEntries.bind(this);
	
			this.getContextPadEntries = function (element) {
				var actions = cached(element);
				var businessObject = element.businessObject;
				if (businessObject.$type != "cmmn:CaseFileItem" && businessObject.$type !== "cmmndi:CMMNEdge" && businessObject.$type !== "cmmn:TextAnnotation" && businessObject.definitionRef && businessObject.definitionRef.$type != 'cmmn:EventListener' && businessObject.definitionRef.$type != "cmmn:UserEventListener" && businessObject.definitionRef.$type != 'cmmn:TimerEventListener' || (businessObject.$type !== "cmmndi:CMMNEdge" && element.type == 'label') || businessObject.$type == "cmmn:Stage") {
					actions['property'] = {
						group: 'view',
						className: 'icon-edit-property',
						title: 'Property Sheet',
						action: {
							click: showPropertySheet,
						}
					}
				}
				return actions;
			}
			//injector._providers.contextPadProvider[1].prototype.getContextPadEntries = this.getContextPadEntries;
		}

		/*if (window.checkContextForRevision()) {
			self.viewMode(true);
		} else {
			self.viewMode(false);
			//self.enableComplete(false);
		}*/
		
		var container = $("#js-canvas"+self.encodeId);
		container.empty();
		/*if(redraw != true && self.showDashboardCmmn){
			container.height(options.jsCanvasHeight);
		}*/ 
		$('.dashboardCmmn').hide();
		self.getJSONObject(function(extModel){
			if (!self.editMode) {
				self.modeler = new CmmnNavigatedViewer({
					container: container,
					moddleExtensions: {
							vmp: extModel
					}
				});
			} else {
				self.modeler = new CmmnModeler({
					keyboard: { bindTo: document },
					container: container,
					moddleExtensions: {
							vmp: extModel
					},
					additionalModules: [{__init__: ['CustomContextPadProvider'],CustomContextPadProvider: ['type', CustomContextPadProvider]}]
					/*propertiesPanel: {
						parent: '#js-properties'
					}*/
				});
				self.modeler.get('eventBus')
				.on(['commandStack.shape.create.postExecute', 'commandStack.shape.delete.postExecute', 'shape.changed', 'shape.remove'],
				function (event, element) {
					self.loadTable(event);
					if (event.context) {
						var bo = arguments[0].context.shape.businessObject;
						var mid = bo.get('mid');
						if (mid) {
							var type = bo.$type;
							/*if(self.getNumberOfMappings(mid,type,event) === 0){
								if (type === 'vdml:MarketSegment' || type === 'vdml:Enterprise' || type === 'vdml:Role' || type === 'vdml:Individual') {
									self.model.get('participant').remove(mid);
								} else if (type === 'vdml:BusinessModel') {
									self.model.get('businessModel').remove(mid);
								} else if (type === 'vdml:ValueProposition') {
									self.model.get('valueProposition').remove(mid);
								}

							}*/
						}
					}
					self.enableComplete(true);
				});
			}
		
			var data = self.model.get('data') ? self.model.get('data') : self.data;
			self.openDiagram(data, function () {
				if (!self.showDashboardCmmn) {
					self.spliter = $("#splitPanels" + self.encodeId).enhsplitter({ minSize: 0, onDrag: window.utils.splitterBoundary, collapse: 'right', position: '64%', handle: 'block', splitterSize: '9px', height: $("#js-canvas" + self.encodeId).outerHeight() + $('.table-responsive').outerHeight(), onDragEnd: self.setResizeHeight });
					$('.dashboardCmmn').show();
					self.spliter.enhsplitter('collapse');
					$(window).resize(function (view, event) {
						this.timer = clearTimeout();
						this.timer = setTimeout(function () { self && self.setResizeHeight && self.setResizeHeight(); }, 200);
					});
					$('#mainTab' + self.encodeId + ' a').click(function (e) {
						e.preventDefault();
						$(this).tab('show');
						self.setResizeHeight();
					});
					$('#rightTab' + self.encodeId + ' a').click(function (e) {
						e.preventDefault();
						if ($(this).text() === "Collaboration") {
							return false;
						}
						$(this).tab('show');
						if ($(this).text() === "Guidance") {
							if ($('#guidanceTab' + self.encodeId).is(':empty')) {
								$.get('js/app/version1/views/help/views/properties/CmmnGuidanceTemplate.html',function(viewStr){
									self.countGuidanceClicks++;
									$('#guidanceTab' + self.encodeId).empty();
									$('#guidanceTab' + self.encodeId).append(viewStr);
									if (self.configObj && self.configObj.guidance) {
										$('#guidanceTab' + self.encodeId).append(self.configObj.guidance);
									}
									window.utils.applyFontSize($('#guidanceTab' + self.encodeId));
									self.setResizeHeight();
								})
							} else {
								self.setResizeHeight();
							}
						} else {
							self.setResizeHeight();
						}
					});
					container.resizable({
						stop: _.bind(function (event, ui) {
							if (ui.size.height > ui.originalSize.height) {
								var self = this;
								self.model.set('height', ui.size.height);
							}
						}, self)
					});
				} else {
					$('#splitPanels' + self.encodeId).contents().unwrap();
					$('#mainTab' + self.encodeId).hide();
					if(self.editMode){
						self.completeButton(true);
						$('#complete' + self.encodeId).css('margin-top','-20px')
					}else{
						self.completeButton(false);
					}
					/*if (redraw !== true && options.parentView && options.parentView.setResizeHeight) {// when ecomap is added
						options.parentView.setResizeHeight();
					}*/
				}
			});
		});
	}
	handlePublicMenus(){
		var self = this;
		DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-wrench","Show Object Explorer",$.proxy(this.showobjectExplorerTree,this));
	};
	showobjectExplorerTree(){
		var self = this;
		var vdm = self.model.getNestedParent();
		var addOptions= this;
		var id = window.utils.htmlEscape(window.guidGenerator());
		window.getAndCreateModalDialog(true,id,CmmnDiagramMixin,vdm,"explorerModelJson",closeView,addOptions);
		function closeView(){
			window.cleanDialogModel(id,CmmnDiagramMixin);
		}
	};
	initializePresentation(){
		var self = this;
		self.afterRenderView(true);
	};
	
	resizeEcoElement(shapeId,newBounds){
		var shape = self.modeler.get('elementRegistry')._elements[shapeId].element;
		self.modeler.get('modeling').resizeShape(shape, newBounds);
	};
	sortShapesInTableByName(left, right) {
		if (left.name && right.name) {
			return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
		}
	}
	init(model, options){
		var self = this;
		this.CmmnDiagramViewModel = this;
		this.afterRenderView = _.bind(this.afterRenderView,self);
		self.model = model;
		self.data = model.get('data');
		this.enableComplete = ko.observable(false);
		this.shapesList = ko.observableArray([]);
		this.unsortedShapesList = [];
		this.showDashboardCmmn = false;
		this.completeButton = ko.observable(true);
		this.encodeId = window.utils.htmlEscape(self.model.id);
		this.editMode = DataManager.getDataManager().get('readMode')?false:true;
		if(window.utils.checkIsLinked(null,true)){
            self.editMode = false;
        }
		if (options.parentView && options.parentView == "Dashboard") {
			this.showDashboardCmmn = true;
			this.editMode = options.editMode;
		}
		if (window.checkContextForRevision() && !self.showDashboardCmmn) {
			//var pack = self.model.getNestedParent();
			//pack.checkRevision(model, function (revision) {
			//	if(revision){
			//		self.createRevisionBasedOnContext(pack, function (newPack) {
			//			var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
			//			var altId = DataManager.getDataManager().getRepositoryId(newPack.id);
			//			var map = newPack.get('diagrams').findWhere({ 'id': altId + bmId });
			//			var diagrams = newPack.get('diagrams');
			//			for (var i = 0; i < diagrams.length; i++) {
			//				newPack.fixDiagramOnRevision(diagrams.at(i), altId);
			//			}
			//			window.utils.stopSpinner('revisionSpinner');
			//			DataManager.getDataManager().get('router').navigate("views/" + newPack.get('id') + "/" + newPack.get('type') + "/" + newPack.get('version') + "/" + map.id + "/" + map.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
			//		});
			//	}
			//});
		}
		//this.CustomContextPadProvider = CustomContextPadProvider;
		if (!self.data) {
			self.data = window.utils.getXmlData(model.get('type').replace("_", "."), window.utils.htmlEscape(model.id))
		}
		window.utils.loadTemplate(function (name) {
		})
		if (!ko.components.isRegistered('CaseDetails')) {
			ko.components.register("CaseDetails", {
				viewModel: CaseDetailsViewModel,
				template: {
					fromUrl: "js/app/version1/views/vdml/views/designer/caseModel/CaseDetailsTemplate.html"
				},
				synchronous: true
			});
		}

		if (!ko.components.isRegistered('CmmnGuidance')) {
			self.guidanceViewInstance = BMGuidanceViewModel.getInstance(this.model);
			ko.components.register("CmmnGuidance", {
				viewModel: { instance: self.guidanceViewInstance },
				template: '<div id="guidanceTab' + self.encodeId + '"></div>',
				synchronous: true
			});
		}

		
		
		
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
			'name'
			, 'description'
			, 'Delete'
			, 'Complete'
			, 'CaseDetails'
			, 'Guidance'
			, 'Collaboration'
			, 'shapeDetails'
		]);

	}

    static getInstance(model, options) {
        var view = new CmmnDiagramViewModel(model, options);
        //view.init(model, options);
        return view;
    };
}
path.CmmnDiagramViewModel = CmmnDiagramViewModel;
