import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as ko from 'knockout'
import * as Backbone from 'backbone'
import * as  XLSX from 'xlsx'
import * as bootbox from '../../../../libs/bootbox/bootbox'
import * as async from 'async'
import * as jszip from '../../../../libs/jszip/jszip.min'
import {BeepPackage} from '../beeppackage/BeepPackage'
import {ValueDeliveryModelMixin} from './ValueDeliveryModelMixin'
import {ValueDeliveryModel2Mixin} from './ValueDeliveryModel2Mixin'
import {BusinessIndicatorLibrary} from '../smm/BusinessIndicatorLibrary'
import {ScenarioProxy} from '../transformation/ScenarioProxy'
import {ValueLibrary} from './ValueLibrary'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import {SmmModel} from '../smm/SmmModel'
import {BusinessIndicatorCategory} from '../smm/BusinessIndicatorCategory'
import {BusinessIndicatorDefinition} from '../smm/BusinessIndicatorDefinition'
import {BusinessItemLibrary} from './BusinessItemLibrary'
//import {Actor} from './Actor'
import {PracticeLibrary} from './PracticeLibrary'
import {Scenario} from './Scenario'
import {Collaboration} from './Collaboration'
import {BusinessModel} from './BusinessModel'
import {CollaborationDiagram} from './CollaborationDiagram'
import {ObjectReference} from './ObjectReference'
import {RoleLibrary} from './RoleLibrary'
import {OrgUnit} from './OrgUnit'
import {MeasureLibrary} from '../smm/MeasureLibrary'
import {ValueStream} from './ValueStream'
import {PackageReference} from '../../../com/vbee/filesystem/PackageReference'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { CapabilityLibrary } from './CapabilityLibrary'
import { CapabilityDefinition } from './CapabilityDefinition'
import { BusinessItemCategory } from './BusinessItemCategory'
import { BusinessItemDefinition } from './BusinessItemDefinition'
import { CapabilityDependency } from './CapabilityDependency'

/*define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/beeppackage/BeepPackage", "appbo/vdml/ValueDeliveryModelMixin", "appbo/vdml/CapabilityLibrary", "appbo/smm/BusinessIndicatorLibrary", "appbo/transformation/ScenarioProxy", "appbo/vdml/ValueLibrary"],
	function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, BeepPackage, ValueDeliveryModelMixin, CapabilityLibrary, BusinessIndicatorLibrary, ScenarioProxy, ValueLibrary
		, VdmlElement
		, VdmlElementMixin
		, SmmModel
		, BusinessItemLibrary
		, Actor
		, PracticeLibrary
		, Scenario
		, Collaboration
		, BusinessModel
		, CollaborationDiagram
		, ObjectReference
		, RoleLibrary
		, OrgUnit
		
        , MeasureLibrary
		, ValueStream
		, PlanPackageReference
		, PackageReference
	) {*/

		var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
		if (!VdmlElement) {
			var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
			if (importPath.VdmlElement) {
				VdmlElement = importPath.VdmlElement;
			}
			else {
				import('./VdmlElement').then(loadedModule => {
				VdmlElement = loadedModule;
				});
				/*require(["appbo/vdml/VdmlElement"],function(loadedModule){
					VdmlElement = loadedModule;
				});*/
				}
		}
		if (!VdmlElementMixin) {
			var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
			if (importPath.VdmlElementMixin) {
				VdmlElementMixin = importPath.VdmlElementMixin;
			}
			else {
				import('./VdmlElementMixin').then(loadedModule => {
				VdmlElementMixin = loadedModule;
				});
				/*require(["appbo/vdml/VdmlElementMixin"], function (loadedModule) {
					VdmlElementMixin = loadedModule;
				});*/
			}
		}
		if (!SmmModel) {
			var importPath = DataManager.getDataManager().buildAppNsPath("smm", global.version);
			if (importPath.SmmModel) {
				SmmModel = importPath.SmmModel;
			}
			else {
				import('../smm/SmmModel').then(loadedModule => {
				SmmModel = loadedModule;
				});
				/*require(["appbo/smm/SmmModel"], function (loadedModule) {
					SmmModel = loadedModule;
				});*/
			}
		}
		if (!ValueLibrary) {
			var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
			if (importPath.ValueLibrary) {
				ValueLibrary = importPath.ValueLibrary;
			}
			else {
				import('./ValueLibrary').then(loadedModule => {
					ValueLibrary = loadedModule;
				});
				/*require(["appbo/vdml/ValueLibrary"], function (loadedModule) {
					ValueLibrary = loadedModule;
				});*/
			}
		}

		if (!BusinessItemLibrary) {
			var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
			if (importPath.BusinessItemLibrary) {
				BusinessItemLibrary = importPath.BusinessItemLibrary;
			}
			else {
				import('./BusinessItemLibrary').then(loadedModule => {
				BusinessItemLibrary = loadedModule;
				});
				/*require(["appbo/vdml/BusinessItemLibrary"], function (loadedModule) {
					BusinessItemLibrary = loadedModule;
				});*/
			}
		}
		//if (!Actor) {
			var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
			if (importPath.Actor) {
			//	Actor = importPath.Actor;
			}
			else {
				import('./Actor').then(loadedModule => {
			//		Actor = loadedModule;
				});
				/*require(["appbo/vdml/Actor"], function (loadedModule) {
					Actor = loadedModule;
				});*/
			}
		//}
		if (!PracticeLibrary) {
			var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
			if (importPath.PracticeLibrary) {
				PracticeLibrary = importPath.PracticeLibrary;
			}
			else {
				import('./PracticeLibrary').then(loadedModule => {
				PracticeLibrary = loadedModule;
				});
				/*require(["appbo/vdml/PracticeLibrary"], function (loadedModule) {
					PracticeLibrary = loadedModule;
				});*/
			}
		}
		if (!Scenario) {
			var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
			if (importPath.Scenario) {
				Scenario = importPath.Scenario;
			}
			else {
				import('./Scenario').then(loadedModule => {
				Scenario = loadedModule;
				});
				/*require(["appbo/vdml/Scenario"], function (loadedModule) {
					Scenario = loadedModule;
				});*/
			}
		}
		if (!Collaboration) {
			var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
			if (importPath.Collaboration) {
				Collaboration = importPath.Collaboration;
			}
			else {
				import('./Collaboration').then(loadedModule => {
				Collaboration = loadedModule;
				});
				/*require(["appbo/vdml/Collaboration"], function (loadedModule) {
					Collaboration = loadedModule;
				});*/
			}
		}
		if (!BusinessModel) {
			var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
			if (importPath.BusinessModel) {
				BusinessModel = importPath.BusinessModel;
			}
			else {
				import('./BusinessModel').then(loadedModule => {
				BusinessModel = loadedModule;
				});
				/*require(["appbo/vdml/BusinessModel"], function (loadedModule) {
					BusinessModel = loadedModule;
				});*/
			}
		}
		if (!CollaborationDiagram) {
			var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
			if (importPath.CollaborationDiagram) {
				CollaborationDiagram = importPath.CollaborationDiagram;
			}
			else {
				import('./CollaborationDiagram').then(loadedModule => {
				//	CollaborationDiagram = loadedModule;
				});
				/*require(["appbo/vdml/CollaborationDiagram"], function (loadedModule) {
					CollaborationDiagram = loadedModule;
				});*/
			}
		}
		if (!ObjectReference) {
			var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
			if (importPath.ObjectReference) {
				ObjectReference = importPath.ObjectReference;
			}
			else {
				import('./ObjectReference').then(loadedModule => {
				ObjectReference = loadedModule;
				});
				/*require(["appbo/vdml/ObjectReference"], function (loadedModule) {
					ObjectReference = loadedModule;
				});*/
			}
		}
		if (!RoleLibrary) {
			var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
			if (importPath.RoleLibrary) {
				RoleLibrary = importPath.RoleLibrary;
			}
			else {
				import('./RoleLibrary').then(loadedModule => {
				RoleLibrary = loadedModule;
				});
				/*require(["appbo/vdml/RoleLibrary"], function (loadedModule) {
					RoleLibrary = loadedModule;
				});*/
			}
		}
		if (!OrgUnit) {
			var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
			if (importPath.OrgUnit) {
				OrgUnit = importPath.OrgUnit;
			}
			else {
				import('./OrgUnit').then(loadedModule => {
				OrgUnit = loadedModule;
				});
				/*require(["appbo/vdml/OrgUnit"], function (loadedModule) {
					OrgUnit = loadedModule;
				});*/
			}
		}
		
		if (!MeasureLibrary) {
			var importPath = DataManager.getDataManager().buildAppNsPath("smm", global.version);
			if (importPath.MeasureLibrary) {
				MeasureLibrary = importPath.MeasureLibrary;
			}
			else {
				import('../smm/MeasureLibrary').then(loadedModule => {
				MeasureLibrary = loadedModule;
				});
				/*require(["appbo/smm/MeasureLibrary"], function (loadedModule) {
					MeasureLibrary = loadedModule;
				});*/
			}
		}
		if (!ValueStream) {
			var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
			if (importPath.ValueStream) {
				ValueStream = importPath.ValueStream;
			}
			else {
				import('./ValueStream').then(loadedModule => {
				ValueStream = loadedModule;
				});
				/*require(["appbo/vdml/ValueStream"], function (loadedModule) {
					ValueStream = loadedModule;
				});*/
			}
		}

		if (!PackageReference) {
			var importPath = DataManager.getDataManager().buildCommonNsPath("com/vbee/filesystem", global.version);
			if (importPath.PackageReference) {
				PackageReference = importPath.PackageReference;
			}
			else {
				import('../../../com/vbee/filesystem/PackageReference').then(loadedModule => {
				PackageReference = loadedModule;
				});
				/*require(["appcommon/com/vbee/filesystem/PackageReference"], function (loadedModule) {
					PackageReference = loadedModule;
				});*/
			}
		}
        /*	if(!ScenarioProxy){
                var importPath = DataManager.getDataManager().buildCommonNsPath("transformation",global.version);
                if(importPath.ScenarioProxy){
                    ScenarioProxy = importPath.ScenarioProxy;
                }
                else{
                    require(["appbo/transformation/ScenarioProxy"],function(loadedModule){
                        PackageReference = loadedModule;
                    });
                }
            }*/
		var getMixinClass = function (){
			var toggleRouter = DataManager.getDataManager().get("toggleRouter");
			var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
			return sp2Enabled == true ? ValueDeliveryModel2Mixin : ValueDeliveryModelMixin;
		}
		var vdmClass = getMixinClass();
		var ValueDeliveryModel = BeepPackage.extend(utils.customExtends({
				relations: vdmClass.getMixinRelations(),
				initialize: function (attributes, options) {
					BeepPackage.prototype.initialize.apply(this, arguments);
					//this.on("add:scenario", this.addExistingBMContextsToNewScenario);
					//this.on("change:name", this.handlePackageNameChange);
				}
			}
			, new VdmlElementMixin()
			, new vdmClass()
		));
		utils.customExtendStaticMethods(ValueDeliveryModel,vdmClass);
		
		ValueDeliveryModel.prototype.getSystemTags = function () {
			return ['VDM', 'Admin', 'Design Package'];
		};
		ValueDeliveryModel['change:name'] = function (model, newName) {
			var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
			if(!suppressChangeLogging) {
				var previousName = model._previousAttributes ? model._previousAttributes.name:"";
				if(previousName != newName) {
					this.handlePackageNameChange(model, newName);
				}
			}
		}
		ValueDeliveryModel['add:scenario'] = function (model, collection) {
			var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
			if(!suppressChangeLogging) {
				this.addExistingBMContextsToNewScenario(model, collection);
			}
		}
		ValueDeliveryModel.prototype.getBusinessModelInstance = function (name, description, bmId) {
			return BusinessModel.getInstance(name, description, this, bmId);
		};
		ValueDeliveryModel.prototype.getCapabilityLibraryInstance = function (name, description, clId) {
			return CapabilityLibrary.getInstance(name, description, this, clId);
		};
		ValueDeliveryModel.prototype.getValueLibraryInstance = function (name, description, vlId) {
			return ValueLibrary.getInstance(name, description, this, vlId);
		};
        ValueDeliveryModel.prototype.getBusinessIndicatorLibraryInstance = function (name, description, clId) {
            return BusinessIndicatorLibrary.getInstance(name, description, this, clId);
		};
		ValueDeliveryModel.prototype.generateBusinessModelId = function (name, description, vdm, alt, callback) {
			var namePath = vdm.get('name') + '>' + name;
			var bmId = DataManager.getDataManager().guidGeneratorByOwner(vdm);
			vdm.getDocumentWithNamePath(namePath, "vdml_BusinessModel", alt, false, vdm, function (result) {
				if (result && result[0] && window.utils.getSuffix(result[0].parent) === window.utils.getSuffix(vdm.id)) {
					bmId = alt.id + window.utils.getSuffix(result[0].doc);
				}
				callback(bmId);
			});
		};
		ValueDeliveryModel.prototype.generateCapabilityLibraryId = function (name, description, vdm, alt, callback) {
			var namePath = vdm.get('name') + '>' + name;
			var clId = DataManager.getDataManager().guidGeneratorByOwner(vdm);
			vdm.getDocumentWithNamePath(namePath, "vdml_CapabilityLibrary", alt, false, vdm, function (result) {
				if (result && result[0] && window.utils.getSuffix(result[0].parent) === window.utils.getSuffix(vdm.id)) {
					clId = alt.id + window.utils.getSuffix(result[0].doc);
				}
				callback(clId);
			});
			return clId;
		};
		//#endCustomMethods
        /*	ValueDeliveryModel.findModel = function(attributes){
                var id = attributes.id;
                if(id && attributes.name){	// only in case attributes has name attribute, search by attributes other than name
                    var repId = DataManager.getDataManager().getRepositoryId(id);
                    //delete attributes.id;
                }
                var coll = Backbone.Relational.store.getCollection( this );
        
                var model = coll.find( function( m ) {
                    var mRepId = DataManager.getDataManager().getRepositoryId(m.get('id'));
                    if(repId){
                        return ((repId === mRepId) && (m.get('name') === attributes.name));
                    }else{
                        return m.get('name') === attributes.name;	
                    }
                });		
                return model;	
            };*/

		ValueDeliveryModel.getName = function () {
			return "ValueDeliveryModel";
		};
		/*ValueDeliveryModel.createPackage = function (copyAlternative, copyPckStr, callback) {
			var copyPckjson;
			if (!copyPckStr || copyPckStr === "") {
				callback();
				return;
			}
			try {
				copyPckjson = JSON.parse(copyPckStr);
			} catch (e) {
				callback();
				return;
			}

			var id = copyPckjson.id;
			var repId = DataManager.getDataManager().getRepositoryId(id);
			var re = new RegExp(repId, 'g');

			copyPckStr = copyPckStr.replace(re, copyAlternative.get('id'));
			copyPckjson = JSON.parse(copyPckStr);

			var copyPck = new ValueDeliveryModel(copyPckjson);
			DataManager.getDataManager().set('suppressChangeLogging', true);
			JSON.stringify(copyPck);
			printStackSize("before firstCall");
			DataManager.getDataManager().getRDFModel(copyAlternative.get('id')).addRDFForModel(copyPck, function () {
				DataManager.getDataManager().getWorkspaceDataWithId(copyAlternative.get('id'), function (wsData) {
					var copyWorkspace = wsData.get('workspace');
					var copyAltVDMStore = wsData.get('vdmStore');
					copyPck.lawnchair = copyAltVDMStore;
					new PackageReference({ beepReference: copyPck, name: copyPck.get('name'), description: copyPck.get('description'), version: copyPck.get('version'), beepType: copyPck.get('type'), label: 'Base', packageReferenceOwner: copyWorkspace });
					var packageRef = copyAlternative.get('phaseDesignPart').findWhere({ beepReference: copyPck.get('id') });
					if (packageRef) {
						packageRef.set('beepReference', copyPck.get('id'));
					} else {
						var pprId = DataManager.getDataManager().guidGeneratorByOwner(copyAlternative.get('phaseAlternativeOwner'));
						var PlanPackageReference = Backbone.Relational.store.getObjectByName("transformation.PackageReference");
						new PlanPackageReference({ id: pprId, beepReference: copyPck.get('id'), name: copyPck.get('name'), description: copyPck.get('description'), version: copyPck.get('version'), beepType: copyPck.get('type'), label: 'Base', phaseDesignPartOwner: copyAlternative, isMain: true });
						var scenarioProxyId = DataManager.getDataManager().guidGeneratorByOwner(copyAlternative.get('phaseAlternativeOwner'));
						var scenarios = copyPck.get('scenario');

						if (scenarios.length > 0) {
							var scenario = scenarios.at(0);
							var scenarioId = scenario.get('id');
							new ScenarioProxy({ id: scenarioProxyId, name: scenario.get('name'), description: scenario.get('name'), designScenarioOwner: copyAlternative, scenario: scenarioId });
						}
					}
					DataManager.getDataManager().set('suppressChangeLogging', false);
					callback();
					//DataManager.getDataManager().saveData({ success: callback });
				});
			}, true);
		};*/
		ValueDeliveryModel.createPackageWithName = function (name, description, currentAlternative, docId) {
			var dataManager = DataManager.getDataManager();
			var packageName = name + ' Package';
			var docId = docId?docId:window.guidGenerator();
			packageName = window.utils.getUniquePackageName(packageName, packageName, currentAlternative, "vdml_ValueDeliveryModel");
			var vdm = new ValueDeliveryModel({
				'name': packageName,
				'description': description + ' Package',
				'id': docId,
				'label': "Base"
			});
			//var scenarioPath = vdm.getNamePath(currentAlternative);
			var snapshotId = window.utils.getScenarioSnapshotId(currentAlternative,null);
			var scenario = vdm.createScenario(snapshotId);
			var pacRef = new PackageReference({ beepReference: vdm, name: vdm.get('name'), description: vdm.get('description'), version: vdm.get('version'), beepType: vdm.get('type'), label: 'Base' });
			dataManager.get('currentWorkspace').get('packageReference').add(pacRef);
			dataManager.get('initializedPackages').add({'id':vdm.get('id'),'version':parseInt(vdm.get('version')),'model':vdm});
			var currentphase = currentAlternative.get('phaseAlternativeOwner');
			var pprId = dataManager.guidGeneratorByOwner(currentphase);
			var PlanPackageReference = Backbone.Relational.store.getObjectByName("transformation.PackageReference");
			var planPacRef = new PlanPackageReference({ id: pprId, beepReference: vdm.get('id'), name: vdm.get('name'), description: vdm.get('description'), version: vdm.get('version'), beepType: vdm.get('type'), label: 'Base', isMain: true,phaseDesignPartOwner: currentAlternative });
			currentAlternative.get('phaseDesignPart').add(planPacRef);
			var scenarioProxyId = dataManager.guidGeneratorByOwner(currentAlternative.get('phaseAlternativeOwner'));
			var scenarioProxy = new ScenarioProxy({ id: scenarioProxyId, name: "Scenario Proxy", designScenarioOwner: currentAlternative, scenario: scenario.get('id') });
			currentAlternative.get('designScenario').add(scenarioProxy);
			//if (currentphase.get('primary') === currentAlternative || currentphase.get('master') === currentAlternative) {
				vdm.referPackageToNextPhases(currentAlternative);
			//}
			return vdm;
		}

		function printStackSize(method) {
			console.log('method:' + method)
			var i = 0;
			function testStack() {
				i++
				testStack();
			}
			try {
				testStack();
			} catch (e) {
				console.log('stak size:' + i)
			}
		}

        /*ValueDeliveryModel.createRGSFromXlsxFile = function (wb, operation, fileName, finalCallback, planModel) {
            //var previousWorkspace = DataManager.getDataManager().getWorkspace();
            
            //if (window.require1) {
                //window.require1(["xlsx", "bootbox", "appbo/smm/BusinessIndicatorCategory", "appbo/smm/BusinessIndicatorDefinition", "jquery", "knockout"], function (_XLSX, bootbox, BusinessIndicatorCategory, BusinessIndicatorDefinition, $, ko) {
                    for (var i = 0; i < wb.SheetNames.length; i++) {		//trimming all sheet names
                        wb.SheetNames[i] = wb.SheetNames[i].trim();
                    }

                    var rgsSheet = wb.Sheets[wb.SheetNames[0]];

                    var libraryDetails = function (sheet) {
                        
                        function createValueDeliveryModel(xlsxName, callback) {
                            var currentPlan = DataManager.getDataManager().get('currentPlan');
                            if (!currentPlan) {
                                currentPlan = planModel;
                            }
                            var planId = currentPlan.id.substr(currentPlan.id.lastIndexOf('@'));
                            var commonAltId = planId + "-Common@";
                            var vdm = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: commonAltId + xlsxName });
                            ValueDeliveryModel.createBusinessIndicatorLibrary(xlsxName, xlsxName, vdm, currentPlan, callback);
                        }
                        createValueDeliveryModel(fileName, function (measurementLibrary) {debugger
                            var mestLibData = ValueDeliveryModel.extractSheetData(sheet);
                            var version = fileName.substr(3);

                            var referenceCodeColl = version === "3.1" ? 0 : 1;
                            var NAME_COLL = 5;
                            var DESC_COLL = 6;
                            var TRANSACTION_TYPE_COLL = 7;
                            var LEVEL_COLL = 8;

                            for (var i = 2; i < mestLibData.length; i++) {
                                var row = mestLibData[i];
                                var refCode = row[referenceCodeColl];
                                var level = parseInt(row[LEVEL_COLL]);
                                var measurementDefinition = measurementLibrary.get('businessIndicatorLibraryElement').find({ 'referenceCode': refCode });
                                if (!measurementDefinition) {
                                    var mlId = DataManager.getDataManager().guidGeneratorByOwner(measurementLibrary);
                                    measurementDefinition = new BusinessIndicatorDefinition({ id: mlId, name: row[NAME_COLL], description: row[DESC_COLL], businessIndicatorLibraryElementOwner: measurementLibrary });
                                    measurementDefinition.set('referenceCode', refCode);
                                    if (level == 1) {
                                        measurementLibrary.get('element').add(measurementDefinition);
                                        i = addChildren(measurementDefinition, i, level, measurementLibrary);
                                        i = i - 1;
                                    }
                                }
                            }
                            function addChildren(parent, currentRow, currentLevel, measurementLibrary) {
                                var children = [];
                                for (var i = currentRow + 1; i < mestLibData.length; i++) {
                                    var row = mestLibData[i];
                                    var level = parseInt(row[LEVEL_COLL]);
                                    if (level > currentLevel + 1 || level <= currentLevel) {
                                        break;
                                    }

                                    var refCode = row[referenceCodeColl];
                                    if (!refCode || refCode == "") {
                                        refCode = row[referenceCodeColl + 1]
                                    }
                                    if (!refCode || refCode == "") {
                                        refCode = row[referenceCodeColl + 2]
                                    }
                                    if (!refCode || refCode == "") {
                                        continue;
                                    }
                                    refCode = refCode.trim();
                                    var measurementDefinition = measurementLibrary.get('businessIndicatorLibraryElement').find({ 'referenceCode': refCode });
                                    if (!measurementDefinition) {
                                        var mlId = DataManager.getDataManager().guidGeneratorByOwner(measurementLibrary);
                                        measurementDefinition = new BusinessIndicatorDefinition({ id: mlId, name: row[NAME_COLL], description: row[DESC_COLL], businessIndicatorLibraryElementOwner: measurementLibrary });
                                        measurementDefinition.set('tranactionType', row[TRANSACTION_TYPE_COLL]);
                                        measurementDefinition.set('referenceCode', refCode);
                                    }
                                    children.push(measurementDefinition);
                                    if (parent) {
                                        parent.get('child').add(measurementDefinition);
                                    }
                                    measurementDefinition.get('parent').add(parent);
                                    i = addChildren(measurementDefinition, i, level, measurementLibrary) - 1;
                                }
                                if (children.length > 0) {
                                    var expression = "toFixed(measurement(0.0";
                                    for (var j = 0; j < children.length; j++) {
                                        var multiplier = children[j].get('tranactionType') === 'C' ? 1 : -1;
                                        expression = expression + ",measureRelation(" + multiplier + ",0,3.0," + children[j].id + ")";
                                    }
                                    expression = expression + "),4)";
                                    parent.set('expression', expression);
                                }
                                return i;
                            }
                            finalCallback(mestLibData);
                        });
                    }
                    if (operation == "update") {
                        window.utils.stopSpinner('importXlsx');
                
                    } else if (operation == "create") {
                        libraryDetails(rgsSheet);
                    }
                //});
            //}
        }*/

        ValueDeliveryModel.extractSheetData = function (sheet) {
            var result = [];
            var row;
            var rowNum;
            var colNum;
            if(sheet){
				var range = XLSX.utils.decode_range(sheet['!ref']);
				for (var rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
					row = [];
					for (var colNum = range.s.c; colNum <= range.e.c; colNum++) {
						var nextCell = sheet[
							XLSX.utils.encode_cell({ r: rowNum, c: colNum })
						];
						if (typeof nextCell === 'undefined') {
							row.push(void 0);
						} else row.push(nextCell.w);
					}
					result.push(row);
				}
			}
            return result;
        }
		ValueDeliveryModel.createFromXlsxFile = function(wb, operation, newFileObject, finalCallback, planModel) {
			var BA_Guild_Title = 'ABOUT THE BUSINESS ARCHITECTURE CONTENTS HEREIN';
			var title = "";
			var previousWorkspace = DataManager.getDataManager().getWorkspace();
			//if (window.require1) {
				//window.require1(["xlsx", "bootbox", "appbo/vdml/CapabilityCategory", "appbo/vdml/CapabilityDefinition", "appbo/vdml/BusinessItemCategory", "appbo/vdml/BusinessItemDefinition", "appbo/vdml/CapabilityDependency", "appbo/ecomap/EcoMap", "appbo/vdml/ValueStreamMapDiagram", "jquery", "knockout"], function (_XLSX, bootbox, CapabilityCategory, CapabilityDefinition, BusinessItemCategory, BusinessItemDefinition, CapabilityDependency, EcoMap, ValueStreamMapDiagram, $, ko) {
					for (var i = 0; i < wb.SheetNames.length; i++) {		//trimming all sheet names
						wb.SheetNames[i] = wb.SheetNames[i].trim();
					}

					for (var key in wb.Sheets) {
						if (key != key.trim()) {
							Object.defineProperty(wb.Sheets, key.trim(), Object.getOwnPropertyDescriptor(wb.Sheets, key));
							delete wb.Sheets[key];
						}
					}
					var capabilityLib = wb.Sheets['Capability Map'];
					if(!capabilityLib){
						capabilityLib = wb.Sheets['Functional Definition'];
					}
					var sheetName = wb.SheetNames[0];
					var coverSheet = wb.Sheets[sheetName];

					var libraryDetails = function(sheet) {
						var range = XLSX.utils.decode_range(sheet['!ref']);
						for(var rowNum = range.s.r; rowNum <= range.e.r; rowNum++){
							var nextCell = sheet[ XLSX.utils.encode_cell({r: rowNum, c: 0})];
							if (nextCell != undefined) {
								nextCell = nextCell.h;
							}
							if(nextCell === BA_Guild_Title || nextCell === "Panorama360") {
								title = sheet[XLSX.utils.encode_cell({r: rowNum-1, c: 0})];		
								if(title){
									break;	
								}
							}
							if(!title){
							  title = sheet[XLSX.utils.encode_cell({r: 1, c: 0})];		
							}
						}
                        var capabilityLibData = ValueDeliveryModel.extractSheetData(capabilityLib);
						if(!title){
							title = sheet[XLSX.utils.encode_cell({r: 1-1, c: 0})];
							if(!title || !title.h){
								finalCallback();
								return;
							}
						}
						var vrmName = title.v?title.v:title.h;
						createValueDeliveryModel(vrmName,  newFileObject , function (capabilityLibrary) {
						    if(vrmName == "Panorama360"){
								for(var i= 1;i<capabilityLibData.length;i++){
								   if(capabilityLibData[i][0] && capabilityLibData[i][0] != null){
									 createCapCategory(capabilityLibrary, capabilityLibData[i], capabilityLibData[0]);
								   }
								}
							}else{
								var tiers = ['Strategic', 'Core', 'Supporting'];
								var column = {'tier': 0, 'level': 1, 'name': 2, 'defn': 3};
								var parentDetails = {'prevTier': null, 'prevLevel': null, 'parentName': null, 'model': null};	
								var levelLadder = [];								
								var cl = capabilityLibrary;
								window.parentBackboneNode=null;

								for (var i = 0; i < tiers.length; i++) {
									var tierCapabilityGroup = [tiers[i], tiers[i], tiers[i], tiers[i]];	
									createCapabilityCategory(cl, tierCapabilityGroup);
								}
								for (var index = 2; index < capabilityLibData.length; index++) {	
								
									var element = capabilityLibData[index];
									if(element[column.name] == undefined || element[column.defn] == undefined){
										continue;
									}
									element[column.name] = element[column.name].trim();
									element[column.defn] = element[column.defn].trim();
									//change
									element[column.name] = element[column.name].split(' / ').join('/');
									element[column.name] = element[column.name].split('/ ').join('/');
									element[column.name] = element[column.name].split(' /').join('/');

									if(element[column.tier] % 1 != 0) {
										element[column.tier] = element[column.tier][0];
										if(element[column.tier] % 1 != 0) {
											element[column.tier] = parentDetails.prevTier;
										}
									}
									if (element[column.level] == 1) {
										levelLadder = [];
										levelLadder.push({'level': element[column.level], 'name': element[column.name]});
										parentDetails = importLevelOneCapabilityGroups(cl, element, parentDetails, tiers[element[column.tier]-1]);
									} else if (element[column.level] > parentDetails.prevLevel) {
										levelLadder.push({'level': element[column.level], 'name': element[column.name]});
										window.parentBackboneNode = cl.get('capability').findWhere({ name: levelLadder[levelLadder.length-2].name });
										parentDetails = importLowerLevelCapabilityGroups(cl, element, parentDetails, levelLadder);
										//this.parentBackboneNode = cl.get('capability').findWhere({ name: element[column.name] });
									} else if (levelLadder[levelLadder.length-1].level != element[column.level] && levelLadder[levelLadder.length-1].level > element[column.level]) {
										var levels = [];
										for (var k = 0; k < levelLadder.length; k++) {
											if (levelLadder[k].level < element[1])
												levels[k] = levelLadder[k];
										}
										levelLadder = levels;
										levelLadder.push({ 'level': element[column.level], 'name': element[column.name] });		
										window.parentBackboneNode = cl.get('capability').findWhere({ name: levelLadder[levelLadder.length-2].name });
										parentDetails = importLowerLevelCapabilityGroups(cl, element, parentDetails, levelLadder);
										//this.parentBackboneNode = cl.get('capability').findWhere({ name: levelLadder[levelLadder.length-1].name });													
									} else {	
										if(parentDetails.prevLevel > element[column.level]) {
											levelLadder.push({'level': element[column.level], 'name': element[column.name]});
										}							
										window.parentBackboneNode = cl.get('capability').findWhere({ name: levelLadder[levelLadder.length-2].name });
										parentDetails = importLowerLevelCapabilityGroups(cl, element, parentDetails, levelLadder); 
									
									}
									if(capabilityLibData[index + 1] == undefined) {
										continue;
									}
									if(capabilityLibData[index + 1][column.level] > element[column.level] && capabilityLibData[index-1][column.level] > element[column.level]) {							
										levelLadder[levelLadder.length - 1] = {'level': element[column.level], 'name': element[column.name]};
									}

									if (capabilityLibData[index + 1][column.level] > element[column.level] && capabilityLibData[index-1][column.level] == element[column.level]) {
										levelLadder[levelLadder.length - 1] = {'level': element[column.level], 'name': element[column.name]};
									}
								}
							}
							var valueStreamArray= [];
							var valueStreamInventory = wb.Sheets["Value Streams Inventory"] || wb.Sheets["Value Stream Inventory"];
							if(valueStreamInventory){
							   var valueStreamInventoryData = ValueDeliveryModel.extractSheetData(valueStreamInventory);
							   for (var i = 0; i < valueStreamInventoryData.length; i++) { //trimming definitions
									if (valueStreamInventoryData[i][1] != undefined && valueStreamInventoryData[i][0] != "Value Stream Name") {
										valueStreamInventoryData[i][0] = valueStreamInventoryData[i][0].trim();
										if(valueStreamInventoryData[i][0].includes("'") || valueStreamInventoryData[i][0].includes("!A")){
											var arr = valueStreamInventoryData[i][0].split("'");
											valueStreamArray.push(arr[1] ? arr[1].trim() : "");
										}else{
										     valueStreamArray.push(valueStreamInventoryData[i][0])
										}
									}
							   }
							}
							else if(!valueStreamInventory){
								valueStreamInventory = wb.Sheets["Value Streams"];
								if(valueStreamInventory){
									valueStreamInventoryData = ValueDeliveryModel.extractSheetData(valueStreamInventory);
									for(var vs=6;vs<valueStreamInventoryData[2].length;vs++){
										valueStreamArray.push(valueStreamInventoryData[2][vs]);
									}
								}
							}
							
							
							var phases = planModel.get('phase').length;

							if (phases >= 1 && valueStreamArray.length > 0) {
								var htmlContent = "<select class=\"form-control\" id=\"selectValueStreamPhase\">";
								htmlContent = htmlContent + "<option>Select Phase ...</option>";
								for(var i=0;i<phases;i++){
									htmlContent = htmlContent + "<option phaseId='" + planModel.get('phase').at(i).attributes.id + "'>" +  planModel.get('phase').at(i).attributes.name + "</option>";
								}
								htmlContent = htmlContent + "</select><p/>"; 
								
								htmlContent = htmlContent + "<select class=\"form-control\" id=\"selectAlternative\">";
								htmlContent = htmlContent + "<option>Select Alternative ...</option>";
								htmlContent = htmlContent + "</select><p/>";
								
								
								htmlContent += "<input type = 'checkbox' id = 'checkAllVS'><b> List of Value Stream(s)</b><br/>";
								valueStreamArray = valueStreamArray.sort();
								for(var value =0;value<valueStreamArray.length;value++){
								    htmlContent +=  "<input type='checkbox' class = 'listOfValuestreams' value = \"" + valueStreamArray[value] + "\">" + " " + valueStreamArray[value] +'</br>';
								}
								
								
								var dialog = bootbox.dialog({
									title: "<div>&nbsp;&nbsp;Do you want to import Value Streams in ? </div>",
									message: htmlContent,
									buttons: {
										main: {
										label: "Close",
										className: "btn btn-default",
										},
										success: {
											label: "Complete",
											className: "btn btn-complete complete-btn disabled",
											callback: function () {
												var alternativeId = $('#selectAlternative option:selected').attr('altId');
												if(alternativeId !== undefined) {
													$('#modal'+window.utils.htmlEscape(planModel.id)).hide();
													window.autoProcess = true;var valueStreamsList =[];
													for(var list =0;list<$('.listOfValuestreams').length;list++){
													  if($('.listOfValuestreams')[list].checked){
													    valueStreamsList.push($('.listOfValuestreams')[list].value)
													  }
													}
													var alternative = DataManager.getDataManager().getAlternativeSync(alternativeId);
													var plan = alternative.getNestedParent();
													DataManager.getDataManager().set("currentPlan",plan);
													function waitForPlanLoad(){
														setTimeout(function(){
															if(window.loadedPackages[plan.get('id')]){
																window.utils.startSpinner('importValueStream', "Creating Value Streams ... ");	
																var vrmName = title.v?title.v:title.h;
																ValueDeliveryModel.setAltForVstreamImport(alternativeId, valueStreamsList,valueStreamInventoryData, wb, vrmName, function() {
																	window.autoProcess = false;
																	//var searchUrl = "search/null/null/null/0/Tag: \"Plan\"";
																	//DataManager.getDataManager().get('router').navigate(searchUrl, {trigger: true});
																	window.utils.navigatePage(true);
																	bootbox.alert(DataManager.getDataManager().get('localeManager').get('ImportComplete', 'Value Streams'));
																	window.utils.stopSpinner('importValueStream');
																	$('#modal'+window.utils.htmlEscape(planModel.id)).show();
																});
															} else {
																waitForPlanLoad();
															}
														},100);
													}
													waitForPlanLoad();
												}
											}
										}
									}
								});
								dialog.attr("id", "bootboxForVS_id");
								$('#bootboxForVS_id').find('.bootbox-body').css('height','500px');
								$('#bootboxForVS_id').find('.bootbox-body').css('overflow-y','scroll');
							}

							
							function fillAlternatives(){
								$( "#selectValueStreamPhase option:selected").each(function(phase){
									var phases = planModel.get('phase');
									var phaseId = $( "#selectValueStreamPhase option:selected" ).attr('phaseId');
									for(var i=0;i<phases.length;i++){
										if(phases.at(i).id === phaseId){
											self.selectedPhase = phases.at(i);
											break;
										}
									}
									if(self.selectedPhase){
										$('#selectAlternative').empty();
										$('#selectAlternative').append("<option selected='true'>Select Alternative ...</option>");	           
										var alts = self.selectedPhase.get('phaseAlternative');
										alts.each(function(alt){
											$('#selectAlternative').append("<option altId='"+ alt.id+ "'>"+alt.get('name')+ "</option>");
										});
									}
								});		            	
							}
							$('input[id="checkAllVS"]').change(function(event,checked) {
								var vsList = $('.listOfValuestreams');
								for(var i=0;i<vsList.length;i++){
								vsList[i].checked = event.currentTarget.checked;
								}
							})
							$('#selectValueStreamPhase').change(function(){
								fillAlternatives();
								if( $(this).children('option:first-child').is(':selected'))
								{
									$('#selectAlternative').empty();
									$('#selectAlternative').append("<option selected='true'>Select Alternative ...</option>");
								}
								$(".complete-btn").prop('disabled', true);
							});
							$('#selectAlternative').change(function(){
								if( $( "#selectAlternative option:selected" ).attr('altid') != undefined) {
									$(".complete-btn").prop('disabled', false);
									$(".complete-btn").removeClass("disabled");
								} else {
									$(".complete-btn").prop('disabled', true);
									$(".complete-btn").addClass("disabled");
								}
							});
							
							fillAlternatives();

							DataManager.getDataManager().setWorkspace(previousWorkspace, function () {
								finalCallback();
							});
						});
					};



					
					function createValueDeliveryModel(vrmName, newFileObject ,callback) {
						if(vrmName === "Panorama360"){
							vrmName = vrmName + " Functional Definition";
						}
						var currentPlan = DataManager.getDataManager().get('currentPlan');
						if (!currentPlan) {
							currentPlan = planModel;
						}
						var planId = currentPlan.id.substr(currentPlan.id.lastIndexOf('@'));
						var commonAltId = planId + "-Common@";
						var vdm = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: commonAltId + vrmName });
						if(!vdm){
							ValueDeliveryModel.createCapabilityPackage(vrmName, currentPlan, function(vdm){
								var cl = vdm.get('capabilityLibrary').findWhere({ 'name': vrmName });
								if (!cl) {
									cl = vdm.getCapabilityLibraryInstance(vrmName, vrmName, null);
								}
								callback(cl);
							});
						} else {
							var cl = vdm.get('capabilityLibrary').findWhere({ 'name': vrmName });
							if (!cl) {
								cl = vdm.getCapabilityLibraryInstance(vrmName, vrmName, null);
							}
							callback(cl);
						}
					}
					function createCapCategory(cl, node, sheetData){
					    var capName = node[0].replace(/("|')/g, "");
						capName = capName.replace(/(\r\n|\n|\r|\\n)/gm, '');
						var capCat = cl.get('capability').findWhere({name : capName});
						if(!capCat){
							var capId = DataManager.getDataManager().guidGeneratorByOwner(cl);
                            var CapabilityCategory = Backbone.Relational.store.getObjectByName("vdml.CapabilityCategory");			
							capCat = new CapabilityCategory({id:capId , name : capName , description : capName , capabilityOwner : cl});
						}
						capCat.set('definition', "");
						if(node[3] && node[3] != ""){
						    createCapDefinition(cl, node[3],node[5], node, capName,sheetData);
						}
						if(node[8] && node[8] != ""){
							createCapDefinition(cl, node[8],node[10], node, node[3],sheetData);
						}
					}
					function createCapDefinition(cl, nodeName, nodeDes, node, parentName,sheetData){
						var capDefinitionName = nodeName.replace(/("|')/g, "");
						capDefinitionName = capDefinitionName.replace(/(\r\n|\n|\r|\\n)/gm, '');
						var parentName =  parentName;
						var capGroup = cl.get('capability').findWhere({ name: capDefinitionName });
						if (!capGroup) {
							var capId = DataManager.getDataManager().guidGeneratorByOwner(cl);
							capGroup = new CapabilityDefinition({ id: capId, name: capDefinitionName, description: capDefinitionName, capabilityOwner: cl });
						}
						if(nodeName === node[3]){
						  nodeDes += "<br>" + "<b>" + sheetData[6] + ": </b>" + node[6] + "<br>" + "<b>" + sheetData[7] + ": </b>" + node[7];//sheetData for adding definition heading
						}
						capGroup.set('definition', nodeDes);
						var parentExist = capGroup.get('parentCapability').length;
						if(parentExist == 0 && parentName && parentName !== "") {
							parent = cl.get('capability').findWhere({ name: parentName });
							parent.get('childCapability').add(capGroup);
							capGroup.get('parentCapability').add(parent);
						}
					}
					function createCapabilityCategory(cl, node) {
						var strategicDefn = "This includes Capabilities that often reflect executive focal points.";
						var coreDefn = "This goes into the heart of what an enterprise does to ensure viability and thrive in the marketplace.";
						var supportingDefn = "This is about the Capabilities that an organization must have to function as a business. Certain supporting Capabilities are common targets for outsourcing.";
						var column = {'tier': 0, 'level': 1, 'name': 2, 'defn': 3};
						var capabilityGroupName = node[column.name]; 	//check
						if(node[column.name] == 'Strategic') {
							var capabilityGroupDefinition = strategicDefn;
						} else if(node[column.name] == 'Core') {
							var capabilityGroupDefinition = coreDefn;
						} else if(node[column.name] == 'Supporting') {
							var capabilityGroupDefinition = supportingDefn;
						}
						var capabilityGroupDescription = node[column.name];
						var capGroup = cl.get('capability').findWhere({ name: capabilityGroupName });
						if (!capGroup) {
							var capId = DataManager.getDataManager().guidGeneratorByOwner(cl);
							var CapabilityCategory = Backbone.Relational.store.getObjectByName("vdml.CapabilityCategory");			
                            capGroup = new CapabilityCategory({ id: capId, name: capabilityGroupName, description: capabilityGroupDescription, capabilityOwner: cl });
						}
						capGroup.set('definition', capabilityGroupDefinition);
					}

					function importLevelOneCapabilityGroups(cl, node, parentDetails, tier) {
						var column = {'tier': 0, 'level': 1, 'name': 2, 'defn': 3};
						var parentName = tier;
						var parent;
						var capabilityGroupName = node[column.name]; 	//check
						var capabilityGroupDescription = node[column.name];
						var capGroup = cl.get('capability').findWhere({ name: capabilityGroupName });
						if (!capGroup) {
							var capId = DataManager.getDataManager().guidGeneratorByOwner(cl);
							capGroup = new CapabilityDefinition({ id: capId, name: capabilityGroupName, description: capabilityGroupDescription, capabilityOwner: cl });
						}
						var definition = node[column.defn].replace(/\b(https?:\/\/\S+)/gi, '<a href="$1" target="_blank">$1</a>');
    					capGroup.set('definition', definition);

                        if (parentName && parentName !== "") {
							parent = cl.get('capability').findWhere({ name: parentName });
							parent.get('childCapability').add(capGroup);
							capGroup.get('parentCapability').add(parent);
						}
						window.parentBackboneNode = capGroup;
						parentDetails.prevTier = node[column.tier];
						parentDetails.prevLevel = node[column.level];
						parentDetails.parentName = node[column.name];
						return parentDetails;
					}

					function importLowerLevelCapabilityGroups(cl, node, parentDetails, ladder) {
						var column = {'tier': 0, 'level': 1, 'name': 2, 'defn': 3};
						var parentName = window.parentBackboneNode.get('name');//ladder[ladder.length-2].name;
						var parent;
						var capGroup;

						var capabilityGroupName = node[column.name];
						var capbilityGroupDesc = node[column.name];
						capGroup = window.parentBackboneNode.get('childCapability').findWhere({ name: capabilityGroupName });
						if (!capGroup) {
							var capId = DataManager.getDataManager().guidGeneratorByOwner(cl);
							capGroup = new CapabilityDefinition({ id: capId, name: capabilityGroupName, description: capbilityGroupDesc, capabilityOwner: cl });
						}
						var definition = node[column.defn].replace(/\b(https?:\/\/\S+)/gi, '<a href="$1" target="_blank">$1</a>');
						capGroup.set('definition', definition);
						if (parentName !== "") {
							parent = window.parentBackboneNode;//cl.get('capability').findWhere({ name: parentName });
							parent.get('childCapability').add(capGroup);
							capGroup.get('parentCapability').add(parent);
						}

						parentDetails.prevTier = node[column.tier];
						parentDetails.prevLevel = node[column.level];
						parentDetails.parentName = node[column.name];
						return parentDetails;
					}

					function loadDocuments(results) {
						for (var i = 0; i < results.length; i++) {
							var type = results.at(i).get('type');
							if (type === "vdml_CapabilityLibrary") {
								self.capabilityLibraryNames.push({ id: results.at(i).get('id'), name: results.at(i).get('name'), parent: results.at(i).get('parent') });
							}
						}
					}

					if(operation == "update"){
						window.utils.stopSpinner('importXlsx');
						var id = window.utils.htmlEscape(window.guidGenerator());
						var options = {coverSheet: coverSheet, planModel: planModel, wb: wb, fileObject : newFileObject};
						window.getAndCreateModalDialog(false, id, ValueDeliveryModelMixin, null, "CapabilityTreeConstruction", null, options);
					} else if(operation == "create") {
						libraryDetails(coverSheet);
					}
				//});
			//}
		}
        ValueDeliveryModel.setAltForVstreamImport = function(alternativeId, valueStreamsList,valueStreamInventoryData, wb, tilteName, callback) {
						//if (window.require1) {
							//window.require1(["async", "appbo/vdml/ValueStreamMapDiagram", "appbo/ecomap/EcoMap"], function(async, ValueStreamMapDiagram, EcoMap) {
							    var valueStreamColumn = {'name': 0, 'defn': 1};
								var packages;
								var alternative = DataManager.getDataManager().getAlternativeSync(alternativeId);
								var plan = alternative.getNestedParent();
								var planId = plan.id;
								var commonAltId = planId.substr(planId.lastIndexOf('@')) + "-Common@";
								
								var previousWorkspace = DataManager.getDataManager().getWorkspace();
								DataManager.getDataManager().getWorkspaceDataWithId(alternative.id, function (wsData) {
									var altWorkspace = wsData.get('workspace');
									DataManager.getDataManager().setWorkspace(altWorkspace, function () {
                                        var EcoMapFile = Backbone.Relational.store.getObjectByName("ecomap.EcoMap");
										var ecomap = EcoMapFile.createPackageWithName(tilteName + " Value Streams", tilteName + " Value Streams", alternative);

											if(tilteName.includes("Panorama360")){
											    var valuestreams = valueStreamsList;
												var valuestreamCount;
												DataManager.getDataManager().getAllDocumentsCollectionOfType(commonAltId, 'vdml_ValueDeliveryModel', function (result) {
													if (result && result.length > 0) {
														for(var i=0;i<result.length;i++){
															if (result.models[i].get('id').indexOf("Common") > -1) {
																if(result.models[i].get('name') != "Common Package" && result.models[i].get('name').includes("Panorama360")){
																		var packages = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({id:result.models[i].get('id')});
																        packages = packages ? packages.get('capabilityLibrary').models[0] : "";
																}
															}
														};
													}
												
													async.eachSeries(valuestreams, function(valuestream, valCallback) {
													  var valuestreamItem = valuestream.trim();
													  valuestreamItem = valuestreamItem.replace(/(\r\n|\n|\r|\\n)/gm, '');
													  for(var i=6;i<valueStreamInventoryData[2].length;i++){
														         var valueStreamData = valueStreamInventoryData[2][i].trim();
																 valueStreamData = valueStreamData.replace(/(\r\n|\n|\r|\\n)/gm, '');
																 if(valueStreamData.trim()  === valuestreamItem.trim()){
																     valuestreamCount = i;
																	 break;
																 }
												      }
													  var contextName;
													  for(var i=6;i<valueStreamInventoryData[0].length;i++){
													  	  if(valueStreamInventoryData[0][i]){
														     contextName = valueStreamInventoryData[0][i];
														  }else if(contextName){
														     valueStreamInventoryData[0][i] = contextName;
														  }
													  }
													  //valuestreamCount++;
													  if(valuestream != undefined){
													       var EcoMapFile = Backbone.Relational.store.getObjectByName("ecomap.EcoMap");
                                                           var ValueStreamMapDiagram = Backbone.Relational.store.getObjectByName("vdml.ValueStreamMapDiagram");
														   EcoMapFile.createValueStreamMapDiagram(ecomap, valuestreamItem.trim(), valuestreamItem.trim(), alternative, ValueStreamMapDiagram, function(){
																function saveVsDiag(){
																	setTimeout(function(){
																		ValueDeliveryModel.createPanoramaValueStreamMapElements(valueStreamInventoryData,valuestreamItem.trim(),valuestreamCount,wb,packages);
																		window.vdmModelView.saveDiagram(null, null, function () {
																			valCallback();
																		});
																	},500);
																}
																function waitforVsPage(){
																	if(!window.vdmModelView.saveDiagram){
																		setTimeout(function(){
																			waitforVsPage();
																		},500);
																	} else {
																		saveVsDiag();
																	}
																}	
																waitforVsPage();	
														   })
													  }else{
												  		  valCallback();
													  }
													},function(err){
														DataManager.getDataManager().setWorkspace(previousWorkspace, function () {
															DataManager.getDataManager().set('currentPlan',null);
															callback();
														});
													});
												}, false, null, true);
											}else{
											  async.eachSeries(valueStreamInventoryData, function(valueStreamData, valCallback) {
												if(valueStreamData[valueStreamColumn.name] != "Value Stream Name" && valueStreamData[valueStreamColumn.defn] != undefined) {
											        var valueStreamName;
													if( valueStreamData[valueStreamColumn.name].indexOf('!') !== -1) {
														valueStreamData[valueStreamColumn.name] = valueStreamData[valueStreamColumn.name].substring(
															valueStreamData[valueStreamColumn.name].indexOf('\'') + 1, valueStreamData[valueStreamColumn.name].lastIndexOf('\''));
													}
													valueStreamData[valueStreamColumn.name] = valueStreamData[valueStreamColumn.name].trim();
													for(var vs =0;vs<valueStreamsList.length;vs++){
														if(valueStreamsList[vs] === valueStreamData[valueStreamColumn.name]){
															valueStreamName = valueStreamData[valueStreamColumn.name];
															break;
														}
													}
													if(valueStreamName){
                                                        var EcoMap = Backbone.Relational.store.getObjectByName("ecomap.EcoMap");
                                                        var ValueStreamMapDiagram = Backbone.Relational.store.getObjectByName("vdml.ValueStreamMapDiagram");
													    EcoMap.createValueStreamMapDiagram(ecomap, valueStreamData[valueStreamColumn.name],
														valueStreamData[valueStreamColumn.name], alternative, ValueStreamMapDiagram, function() {
															function saveVsDiag(){
																setTimeout(function(){
																	ValueDeliveryModel.createValueStreamMapElements(valueStreamData[valueStreamColumn.name],wb);
																	window.vdmModelView.saveDiagram(null, null, function () {	
																		valCallback();
																	});
																},500);
															}
															function waitforVsPage(){
																if(!window.vdmModelView.saveDiagram){
																	setTimeout(function(){
																		   waitforVsPage();
																	},500);
																} else {
																	saveVsDiag();
																}
															}	
															waitforVsPage();														
														});
													}else{
														valCallback();
													}
												} else {
													valCallback();
												}
											  },function(err){
												window.autoProcess = false;
												DataManager.getDataManager().setWorkspace(previousWorkspace, function () {
													callback();
												});
											  });
											}
									});
								});
							//})
						//}
		}
		function checkBpmnModeler(valueStream){
			self.bpmnModeler = window.vdmModelView.modeler;
			self.eventBus = self.bpmnModeler.get('eventBus');
		    self.elementFactory = self.bpmnModeler.get('elementFactory');
			self.modeling = self.bpmnModeler.get('modeling');
			self.elementRegistry = self.bpmnModeler.get('elementRegistry');
			parent = self.bpmnModeler.get('canvas').getRootElement();
			var range = XLSX.utils.decode_range(valueStream['!ref']);
			return range;
		}
		function createValueStream(numberOfActivities,valuestreamItem){
			var shape = self.elementFactory.createShape({ type: 'vdml:ValueStream' });
			var vsShapeSize = 200;
			if(numberOfActivities ===  1 || numberOfActivities === 2){
			   vsShapeSize = 350;
			}
			var currentValueStreamShape = self.modeling.createShape(shape, { 'x': 0, 'y': 0, 'width':numberOfActivities * vsShapeSize }, parent);
			currentValueStreamShape.businessObject.name = valuestreamItem;
			currentValueStreamShape.businessObject.description = valuestreamItem;
			return currentValueStreamShape;
		}
		function sortByOrder(left, right) {
				if (left.number && right.number) {
					return left.number == right.number ? 0 : (left.number < right.number ? -1 : 1);
				}
	    }
		
		ValueDeliveryModel.createValueStreamMapElements = function(valueStreamData,wb){
		        var xpos = 0;
				var ypos = 0;

				var valueStream = wb.Sheets[valueStreamData];
				if( valueStream == undefined) {
					for (var key in wb.Sheets) {
						var tempSheet = wb.Sheets[key];
						var tempCell = tempSheet[ XLSX.utils.encode_cell({r: 1, c: 0})];
						if(tempCell != undefined) {
							//if(tempCell.h == valueStreamData || tempCell.v) {
							if(tempCell.h == valueStreamData) {
								valueStream = wb.Sheets[key];
								break;
							}
						}									
					}
				}
				if(valueStream){

		
				var range = checkBpmnModeler(valueStream);
				var docInfoStartIndex = 4;
				var docInfoEndIndex = 8;
				var rowCount = 0;

				for(var rows = range.s.r; rows < range.e.r; rows++) {
					var tempcell = valueStream[ XLSX.utils.encode_cell({r: rows, c:1})];
					if(tempcell != undefined) {
						rowCount++;
					}
				}

				var valuePropositionIndex = 3;
				var valueStreamRowIndex = 1;
				var valueStreamStakeColIndex = 7;
				var currentValueStreamShape = createValueStream(rowCount,valueStreamData);
															
				var definitionText = '';
				if(valueStream[XLSX.utils.encode_cell({r: 0,c: valueStreamStakeColIndex})]){
					definitionText = valueStream[ XLSX.utils.encode_cell({r: valueStreamRowIndex, c: 2})].h;
					definitionText = definitionText.replace( /&#x000d;&#x000a;/gm, '');
				}

				var additionalInfo = '<br><br><b>';
				if(valueStream[XLSX.utils.encode_cell({r: 0,c: valuePropositionIndex})]){
					additionalInfo +=  valueStream[XLSX.utils.encode_cell({
						r: 0,
						c: valuePropositionIndex
					})].h;
				}
				if(valueStream[XLSX.utils.encode_cell({r: valueStreamRowIndex,c: valuePropositionIndex})]){
					additionalInfo +=  "</b>: <br>"+ valueStream[XLSX.utils.encode_cell({
						r: valueStreamRowIndex,
						c: valuePropositionIndex
					})].h;
				}
				if(valueStream[XLSX.utils.encode_cell({r: 0,c: valueStreamStakeColIndex})]){
					additionalInfo += "<br><b>"+ valueStream[XLSX.utils.encode_cell({r: 0,c: valueStreamStakeColIndex})].h;
				}
				if(valueStream[XLSX.utils.encode_cell({r: valueStreamRowIndex,c: valueStreamStakeColIndex})]){
					additionalInfo += "</b>: <br>" + valueStream[XLSX.utils.encode_cell({r: valueStreamRowIndex,c: valueStreamStakeColIndex})].h;
				}
				definitionText = definitionText + additionalInfo;
				if(definitionText){
					var attributes = {};
					attributes.text = definitionText;
					var newDefinition = self.modeling._elementFactory._vdmlFactory.create('vdml:Definition', attributes);
					currentValueStreamShape.businessObject.definition = newDefinition;	
				}
                self.eventBus.fire('elements.changed', {
					elements: [currentValueStreamShape]
				});		
				parent = currentValueStreamShape;					
				var prevActivity = null;
				ypos += 100;
				xpos += 50;
				for(var rowNum = range.s.r; rowNum <= range.e.r; rowNum++){
					var cell = valueStream[ XLSX.utils.encode_cell({r: rowNum, c: 1})];
					if (cell != undefined && cell.h != "Value Stream Stage") {
						cell = cell.h;
						var shape = self.elementFactory.createShape({ type: 'vdml:Activity' });
						var currentActivityShape = self.modeling.createShape(shape, { 'x': xpos, 'y': ypos, 'width': 115}, parent);
						currentActivityShape.businessObject.name = window.utils.escapeHtmlTags(cell);
						currentActivityShape.businessObject.description = window.utils.escapeHtmlTags(cell);
						var definitionText = '';
						if(valueStream[XLSX.utils.encode_cell({r: rowNum,c: 2})]){
							definitionText = valueStream[ XLSX.utils.encode_cell({r: rowNum, c: 2})].h;
						}
						
						var activityAdditionalInfo = '<br>';
						for (var docInfoColIndex = docInfoStartIndex; docInfoColIndex < docInfoEndIndex; docInfoColIndex++) {
							activityAdditionalInfo += '<br><b>' 
							if(valueStream[XLSX.utils.encode_cell({r: 0,c: docInfoColIndex})]){
								activityAdditionalInfo += valueStream[XLSX.utils.encode_cell({r: 0,c: docInfoColIndex})].h + '</b>';; 
							}
							if(valueStream[XLSX.utils.encode_cell({r: rowNum,c: docInfoColIndex})]){
								activityAdditionalInfo += ':<br>' + valueStream[XLSX.utils.encode_cell({r: rowNum,c: docInfoColIndex})].h;
							}
						}
						definitionText = definitionText.replace(/&#x000d;&#x000a;/gm, '');
						definitionText += activityAdditionalInfo;

						if(definitionText){
							var attributes = {};
							attributes.text = definitionText;
							var newDefinition = self.modeling._elementFactory._vdmlFactory.create('vdml:Definition', attributes);
							currentActivityShape.businessObject.definition = newDefinition;	
						}
                        self.eventBus.fire('elements.changed', {
							elements: [currentActivityShape]
						});

						if(prevActivity != null) {
							var dataFlow = self.modeling.connect(prevActivity, currentActivityShape, {
								type: 'vdml:DeliverableFlow'
							});
						}
						xpos += 135;
						prevActivity = currentActivityShape;
					}
				}
			}
		}

		ValueDeliveryModel.createPanoramaValueStreamMapElements = function(valueStreamInventoryData,valuestreamItem,valuestreamCount,wb,capLib){
			var numberOfActivities =0;
			var actXposition = 0;
			var actYposition = 0;var actSequence = [];
			for(var activityStratsFrom=4;activityStratsFrom<valueStreamInventoryData.length;activityStratsFrom++){
				if(valueStreamInventoryData[activityStratsFrom][valuestreamCount] != undefined && valueStreamInventoryData[activityStratsFrom][valuestreamCount] != " "){
					numberOfActivities++;
					actSequence.push({text : valueStreamInventoryData[activityStratsFrom][2], number : parseInt(valueStreamInventoryData[activityStratsFrom][valuestreamCount]), capability : valueStreamInventoryData[activityStratsFrom][1] + " " + valueStreamInventoryData[activityStratsFrom][2]});
				}
			}
			if(actSequence.length > 0){
				actSequence.sort(sortByOrder);
			}
			
			var competence = wb.Sheets['Processes']; // For competencies
			var compData = ValueDeliveryModel.extractSheetData(competence);
			var valueStream = wb.Sheets['Value Streams']; // For value streams
			var range = checkBpmnModeler(valueStream);
			var currentValueStreamShape = createValueStream(numberOfActivities,valuestreamItem);
			var valueStrContext = valueStreamInventoryData[0][valuestreamCount] ? valueStreamInventoryData[0][valuestreamCount] : valueStreamInventoryData[0][valuestreamCount-1];
			var valueStreamDef = "<b>Context:</b> " + valueStrContext + "</br>" + "<b>Value Proposition:</b> " + valueStreamInventoryData[3][valuestreamCount];
			var newDefinitionForValueStr = createDefinitionTextForShape(valueStreamDef);
			currentValueStreamShape.businessObject.definition = newDefinitionForValueStr;
			currentValueStreamShape.businessObject.di.fill = "rgba(171,200,24,0.24)";
			self.eventBus.fire('elements.changed', {
				elements: [currentValueStreamShape]
			});	
			
			var prevActivity = null;var actCount =0;
			for(var i = 4;i<valueStreamInventoryData.length;i++){
				if(valueStreamInventoryData[i][valuestreamCount] != undefined && valueStreamInventoryData[i][valuestreamCount] != " "){
					var definitionText = "";
					var actParent = currentValueStreamShape;					
					if(actXposition == 0 && actYposition == 0){
						actXposition = 50;
						actYposition = 70;
					}else{
						actXposition += 200;
					}

					if(capLib){
						var capabilities = capLib.get('capability').models;
						for(var a=0;a<capabilities.length;a++){
							if(capabilities[a].get('name') === actSequence[actCount].capability.trim()){
								var capabilityId = capabilities[a].get('id');
								definitionText = capabilities[a].get('definition');
								break;
							}
						}
					}
					//creating Activity Shape
					shape = self.elementFactory.createShape({ type: 'vdml:Activity' });
					currentActivityShape = self.modeling.createShape(shape, { 'x': actXposition, 'y': actYposition, 'width': 115}, actParent);
					var actName = actSequence[actCount].text ? actSequence[actCount].text.replace(/("|')/g, "") : '';
					actName = actName ? actName.replace(/\d+([,.]\d+)?\s*/g, '') : '';
					actName = actName ? actName.trim() : "";
					actCount++;
					currentActivityShape.businessObject.name = actName;
					currentActivityShape.businessObject.description = actName;
					currentActivityShape.businessObject.mcid = capabilityId;
					currentActivityShape.businessObject.mcpid = capLib.get('id');
					window.vdmModelView.tempCapbilityIdList.push(capabilityId);
					var newDefinitionForAct = createDefinitionTextForShape(definitionText);
					currentActivityShape.businessObject.definition = newDefinitionForAct;	
																	
					self.eventBus.fire('elements.changed', {
						elements: [currentActivityShape]
					});

					if(prevActivity != null) {
						var dataFlow = self.modeling.connect(prevActivity, currentActivityShape, {
							type: 'vdml:DeliverableFlow'
						});
					}
					var competenceList =0;
					var valueStreamList = compData[1];var valueStrCount;                    // For To know the Value Stream map number
					for(var count=11;count<valueStreamList.length;count++){
						var valueStreamName = valueStreamList[count] ? valueStreamList[count].replace(/("|')/g, "") : "";
						valueStreamName = valueStreamName ? valueStreamName.replace(/\n|\r/g, "") : ""
						if(valuestreamItem === (valueStreamName ? valueStreamName.trim() : "")){
							valueStrCount = count;
							break;
						}
					}
					for(var c = 4;c<compData.length;c++){ // For to know number of competences for activity
						var activityName = compData[c][3] ? compData[c][3].replace(/("|')/g, "") : "";
						activityName = activityName ? activityName.replace(/\d+([,.]\d+)?\s*/g, '') : '';
						if(activityName){
							activityName =  activityName.replace(".","");
							activityName = activityName.trim();
						}
						if(compData[c][valueStrCount] && activityName &&  activityName === currentActivityShape.businessObject.name){
						     competenceList++;
						}
					}
					// creating Capability container
					if(competenceList && competenceList > 0){
						var contShape = self.elementFactory.createShape({ type: 'vdml:CapabilityContainer' });
						if(actXposition == 50 && actYposition == 70){
							var containerXaxis = actXposition - 32.5;
							var containerYaxis = actYposition + 150;
						}else{
							containerXaxis += 200;
						}
						containerShapeSize = 85;
						if(competenceList === 1){
							containerShapeSize = 100;
						}
						var currentContainerShape = self.modeling.createShape(contShape, { 'x': containerXaxis, 'y': containerYaxis, 'width': 180, 'height': competenceList * containerShapeSize}, parent);
						currentContainerShape.businessObject.di.fill = "rgba(250,119,49,0.37)";
						currentActivityShape.businessObject.source = currentActivityShape;
						currentActivityShape.businessObject.target = currentContainerShape;
						dataFlow = self.modeling.connect(currentActivityShape, currentContainerShape, {
								type: 'vdml:CausalRelationship'
						});
						createCompetenceShapes(actCount,containerXaxis,containerYaxis,compData,currentActivityShape,capLib,valueStrCount,currentContainerShape); // For competence shapes creation
					}
					prevActivity = currentActivityShape;
				}
			}
		}
		function createCompetenceShapes(actCount,containerXaxis,containerYaxis,compData,currentActivityShape,capLib,valueStrCount,currentContainerShape){
			var competenceXaxis = "";var competenceYaxis ="";
			for(var comp = 4;comp<compData.length;comp++){// For Creating Competencies
				var activityName = compData[comp][3] ? compData[comp][3].replace(/("|')/g, "") : "";
				activityName = activityName ? activityName.replace(/\d+([,.]\d+)?\s*/g, '') : '';
				if(activityName){
					activityName =  activityName.replace(".","");
					activityName = activityName.trim();
				}
				if(compData[comp][valueStrCount] && activityName &&  activityName === currentActivityShape.businessObject.name){
						var compShape = self.elementFactory.createShape({type : 'vdml:Competency'});
						if(actCount != 1 && !competenceXaxis && !competenceYaxis){
							competenceXaxis = containerXaxis + 50;
							competenceYaxis = containerYaxis + 30;
						}else if(!competenceXaxis && !competenceYaxis){
							competenceXaxis = containerXaxis + 30;
							competenceYaxis = containerYaxis + 30;
						}
						else{
						    competenceYaxis += 75;
						}
						var currentCompShape = self.modeling.createShape(compShape,  { 'x':competenceXaxis, 'y': competenceYaxis,  'width': 100, 'height': 50}, currentContainerShape);
						var compName = compData[comp][8] ? compData[comp][8].replace(/("|')/g, "") : "";
						compName = compName ? compName.replace(/\d+([,.]\d+)?\s*/g, '') : '';
						if(compName){
							compName =  compName.replace(".","");
							compName.trim();
						}
						currentCompShape.businessObject.name = compName;
						currentCompShape.businessObject.description = compName;
						var newCompDefinition = createDefinitionTextForShape(compData[comp][valueStrCount]);
						if(capLib){
							var capabilities = capLib.get('capability').models;
							for(var capExist = 0;capExist<capabilities.length;capExist++){// For Competence Capability reference
								if(capabilities[capExist].get('name') ===  compData[comp][9] + compName || capabilities[capExist].get('name') ===  compData[comp][9] + " "+ compName){
									currentCompShape.businessObject.mcid = capabilities[capExist].get('id');
									currentCompShape.businessObject.mcpid = capLib.get('id');
									window.vdmModelView.tempCapbilityIdList.push(capabilities[capExist].get('id'));
									break;
								}
							}
						}
						currentCompShape.businessObject.definition = newCompDefinition;
						self.eventBus.fire('elements.changed', {
							elements: [currentContainerShape]
						});
						self.eventBus.fire('elements.changed', {
							elements: [currentCompShape]
						});
				}
			}
		}
		function createDefinitionTextForShape(definition){
			var attributes = {};
			attributes.text = definition ? definition.replace(/\r\n/g, "<br />") : "";
			var definitionText = self.modeling._elementFactory._vdmlFactory.create('vdml:Definition', attributes);
			return definitionText;
		}
		ValueDeliveryModel.unzipVRMFiles = function (fileObject, callback) {
			//if (window.require1) {
				//window.require1(["async", "jszip", "bootbox"], function (async, jszip, bootbox) {
					var reader = new FileReader();
					reader.onload = function (ev) {
						//document.getElementById('contents').value = reader.result;
						var libraryContent = reader.result;
						var newplanZip = new jszip();
						/*planZip.readFile(planContent);*/
						newplanZip.loadAsync(libraryContent).then(function (vocZip) {
							var obj = vocZip.files;
							delete obj['manifest.json'];
							var files = jQuery.map(obj, function (el) { return el });//converting json to array
							var libraryFiles = [];
							var parser = new DOMParser();
							async.each(files, function (libFile, handledFile) {
								if (libFile.name.endsWith('xml')) {
									libFile.async("string").then(function (fileText) {
										try {
											var xmlDoc = parser.parseFromString(fileText, "text/xml");
											libraryFiles.push(xmlDoc);
										} catch (e) {
											console.log(e);
										}
										handledFile();
									});
								} else {
									handledFile();
								}

							}, function (err) {
								if (!err) {
									callback(libraryFiles);
								} else {
									bootbox.alert(err);
									callback();
									return;
								}
							});
						});
					}
					reader.readAsBinaryString(fileObject);
				//});
			//};
		}
		ValueDeliveryModel.createVRMFromFiles = function (libraryFiles, finalCallback, planModel) {
				//window.require1(["async", "appbo/vdml/CapabilityCategory", "appbo/vdml/CapabilityDefinition", "appbo/vdml/BusinessItemCategory", "appbo/vdml/BusinessItemDefinition", "appbo/vdml/CapabilityDependency"], function (async, CapabilityCategory, CapabilityDefinition, BusinessItemCategory, BusinessItemDefinition, CapabilityDependency) {
					var cl;
					var vdm;
					var biLibrary;
					var libraryName;
					function handleFiles() {
						//DataManager.getDataManager().set('currentPlan', model);
						var xmlFiles = [];
						xmlFiles.push(getFileWithName("Process_Level_0", libraryFiles));
						xmlFiles.push(getFileWithName("Process_Level_1", libraryFiles));
						xmlFiles.push(getFileWithName("Process_Level_2", libraryFiles));
						xmlFiles.push(getFileWithName("Process_Level_3", libraryFiles));
						xmlFiles.push(getFileWithName("Input_Output", libraryFiles));
						xmlFiles.push(getFileWithName("Level_Three_to_Input_Output", libraryFiles));
						var previousWorkspace = DataManager.getDataManager().getWorkspace();

						async.eachSeries(xmlFiles, function (xmlFile, fileHandled) {
							var dataNode = xmlFile.firstChild;
							var firstChild = dataNode.firstElementChild;
							if (firstChild) {
								if (firstChild.nodeName === "Process_Level_0") {
									var capLibName = firstChild.getElementsByTagName('LevelZeroID')[0].innerHTML
									createValueDeliveryModel(capLibName, function (capabilityLibrary) {
										importLibrary(capabilityLibrary, firstChild);
										cl = capabilityLibrary;
										vdm = cl.get('capabilityLibraryOwner');
										fileHandled();
									});
								} else if (firstChild.nodeName === "Process_Level_1") {
									importLevelOneCapabilityGroups(cl, firstChild);
									fileHandled();
								} else if (firstChild.nodeName === "Process_Level_2") {
									importLevelTwoCapabilityGroups(cl, firstChild);
									fileHandled();
								} else if (firstChild.nodeName === "Process_Level_3") {
									importLevelThreeCapabilityGroups(cl, firstChild);
									fileHandled();
								} if (firstChild.nodeName === "Input_Output") {
									biLibrary = createBusinessItemLibrary();
									importIOs(biLibrary, firstChild);
									fileHandled();
								} if (firstChild.nodeName === "Level_Three_to_Input_Output") {
									try {
										importCapabilityFlows(firstChild);
									} catch (e) {
										console.log(e);
									}

									fileHandled();
								} else {
									//fileHandled();
								}
							}
						}, function (err) {
							DataManager.getDataManager().setWorkspace(previousWorkspace, function () {
								//DataManager.getDataManager().set('currentPlan', null);
								//window.utils.stopSpinner('importVocabulary');
								finalCallback();
							});
						});

					}
					function getFileWithName(name, xmlFiles) {
						for (var i = 0; i < xmlFiles.length; i++) {
							var xmlFile = xmlFiles[i];
							var dataNode = xmlFile.firstChild;
							if (dataNode) {
								var firstChild = dataNode.firstElementChild;
								if (firstChild && firstChild.nodeName == name) {
									return xmlFile;
								}
							}
						}
					}
					function importCapabilityFlows(flowNode) {
						while (flowNode) {
							var io = null;
							var source = null;
							var target = null;
							var flow;
							var ioName = flowNode.getElementsByTagName('IOName')[0].innerHTML;
							if (ioName !== "") {
								io = biLibrary.get('businessItemLibraryElement').findWhere({ name: ioName, type: 'vdml_BusinessItemDefinition' });
							}
							var sourceExternal = flowNode.getElementsByTagName('SourceExternal')[0].innerHTML;
							if (sourceExternal == "") {
								sourceExternal = "0";
							}
							if ("0" === sourceExternal) {
								var sourceNodes = flowNode.getElementsByTagName('Source_Level_Three');
								var sourceName = sourceNodes.length > 0 ? sourceNodes[0].innerHTML : "";
								if (sourceName && sourceName != "") {
									source = cl.get('capability').findWhere({ name: sourceName });
								}
							}
							var targetExternal = flowNode.getElementsByTagName('TargetExternal')[0].innerHTML;
							if (targetExternal == "") {
								targetExternal = "0";
							}
							if ("0" === targetExternal) {
								var targetNodes = flowNode.getElementsByTagName('Target_Level_Three');
								var targetName = targetNodes.length > 0 ? targetNodes[0].innerHTML : "";
								if (targetName && targetName != "") {
									target = cl.get('capability').findWhere({ name: targetName });
								}
							}
							if ((source || target)) {

								flow = getFlowIfAlreadyExisted(source, target, io);
								if (flow == null) {
									var flowId = DataManager.getDataManager().guidGeneratorByOwner(vdm);
									var flowName = window.guidGenerator();
									flow = new CapabilityDependency({ id: flowId, name: flowName, description: flowName, capabilityDependencyOwner: cl });

									if (io != null) {
										flow.set('deliverableDefinition', io);
									}
									if ("0" == sourceExternal) {
										if (source != null) {
											flow.set('source', source);
											//source.getProducerDependencies().add(flow);
											flow.set('isFromExternalSource', false);
										}
										else {
											flow.set('isFromExternalSource', true);
										}
									}
									else {
										flow.set('isFromExternalSource', true);
									}
									if ("0" == targetExternal) {
										if (target != null) {
											flow.set('target', target);
											//target.getConsumerDependencies().add(flow);
											flow.set('isForExternalTarget', false);
										}
										else {
											flow.set('isForExternalTarget', true);
										}
									}
									else {
										flow.set('isForExternalTarget', true);
									}
								}
							}
							flowNode = flowNode.nextElementSibling;
						}
					}
					function getFlowIfAlreadyExisted(source, target, io) {
						var producerDependencies = null;
						var consumerDependencies = null;
						if (io) {
							if (source) {
								producerDependencies = source.get('output');
								for (var i = 0; i < producerDependencies.length; i++) {
									var dep = producerDependencies.at(i);
									if (dep.get('deliverableDefinition') && dep.get('deliverableDefinition') == io && dep.get('target') == target) {
										return dep;
									}
								}
							} else if (target) {
								consumerDependencies = target.get('input');
								for (var i = 0; i < consumerDependencies.length; i++) {
									var dep = consumerDependencies.at(i);
									if (dep.get('deliverableDefinition') && dep.get('deliverableDefinition') == io && dep.get('source') == source) {
										return dep;
									}
								}
							}
						}
						return null;
					}
					function importIOs(biLibrary, ioNode) {
						while (ioNode) {
							var ioSubClass = null;
							var ioSubClassStr = ioNode.getElementsByTagName('IOSubclass')[0].innerHTML;
							ioSubClass = biLibrary.get('businessItemLibraryElement').findWhere({ name: ioSubClassStr, type: 'vdml_BusinessItemCategory' });
							if (!ioSubClass) {
								var biCatId = DataManager.getDataManager().guidGeneratorByOwner(vdm);
								ioSubClass = new BusinessItemCategory({ id: biCatId, name: ioSubClassStr, description: ioSubClassStr, businessItemLibraryElementOwner: biLibrary });
							}
							biLibrary.get('businessItemLibraryElement').add(ioSubClass);

							var ioClassStr = ioNode.getElementsByTagName('IOClass')[0].innerHTML;
							var ioClass = biLibrary.get('businessItemLibraryElement').findWhere({ name: ioClassStr, type: 'vdml_BusinessItemCategory' });
							if (!ioClass) {
								var biCatId = DataManager.getDataManager().guidGeneratorByOwner(vdm);
								ioClass = new BusinessItemCategory({ id: biCatId, name: ioClassStr, description: ioClassStr, businessItemLibraryElementOwner: biLibrary });
							}
							biLibrary.get('businessItemLibraryElement').add(ioClass);
							ioClass.get('childCategory').add(ioSubClass);
							ioSubClass.get('parentCategory').add(ioClass);

							var ioName = ioNode.getElementsByTagName('IOName')[0].innerHTML;
							var io = biLibrary.get('businessItemLibraryElement').findWhere({ name: ioName, type: 'vdml_BusinessItemDefinition' });
							if (!io) {
								var biId = DataManager.getDataManager().guidGeneratorByOwner(vdm);
								io = new BusinessItemDefinition({ id: biId, name: ioName, description: ioName, businessItemLibraryElementOwner: biLibrary });

							}
							biLibrary.get('businessItemLibraryElement').add(io);
							var ioTypes = ioNode.getElementsByTagName('IOType');
							var ioTypeStr = ioTypes.length > 0 ? ioTypes[0].innerHTML : "Information";
							io.set('biType', ioTypeStr);
							var defs = ioNode.getElementsByTagName('IODefinition');
							if (defs.length > 0) {
								io.set('definition', defs[0].innerHTML);
							}
							ioSubClass.get('categoryItem').add(io);
							ioNode = ioNode.nextElementSibling;
						}
					}
					function importLevelOneCapabilityGroups(cl, capabilityGroupNode) {
						while (capabilityGroupNode) {
							var capabilityGroupName = capabilityGroupNode.getElementsByTagName('LevelOneID')[0].innerHTML;
							var capabilityGroupDescription = capabilityGroupNode.getElementsByTagName('LevelOneName')[0].innerHTML;
							var capGroup = cl.get('capability').findWhere({ name: capabilityGroupName });
							if (!capGroup) {
								var capId = DataManager.getDataManager().guidGeneratorByOwner(cl);
								var CapabilityCategory = Backbone.Relational.store.getObjectByName("vdml.CapabilityCategory");
                                capGroup = new CapabilityCategory({ id: capId, name: capabilityGroupName, description: capabilityGroupDescription, capabilityOwner: cl });
							}
							capGroup.set('definition', capabilityGroupNode.getElementsByTagName('LevelOneDefinition')[0].innerHTML);
							capabilityGroupNode = capabilityGroupNode.nextElementSibling;
						}
					}
					function importLevelTwoCapabilityGroups(cl, capabilityGroupNode) {
						while (capabilityGroupNode) {
							var parentName = capabilityGroupNode.getElementsByTagName('LevelOneID')[0].innerHTML;
							var parent;
							var capGroup;

							var capabilityGroupName = capabilityGroupNode.getElementsByTagName('LevelTwoID')[0].innerHTML;
							var capbilityGroupDesc = capabilityGroupNode.getElementsByTagName('LevelTwoName')[0].innerHTML;
							capGroup = cl.get('capability').findWhere({ name: capabilityGroupName });
							if (!capGroup) {
								var capId = DataManager.getDataManager().guidGeneratorByOwner(cl);
								var CapabilityCategory = Backbone.Relational.store.getObjectByName("vdml.CapabilityCategory");
                                capGroup = new CapabilityCategory({ id: capId, name: capabilityGroupName, description: capbilityGroupDesc, capabilityOwner: cl });
							}
							capGroup.set('definition', capabilityGroupNode.getElementsByTagName('LevelTwoDefinition')[0].innerHTML);
							if (parentName !== "") {
								parent = cl.get('capability').findWhere({ name: parentName });
								parent.get('childCapability').add(capGroup);
								capGroup.get('parentCapability').add(parent);
							}

							capabilityGroupNode = capabilityGroupNode.nextElementSibling;
						}
					}
					function importLevelThreeCapabilityGroups(cl, capabilityNode) {
						while (capabilityNode) {
							var parentDesc = capabilityNode.getElementsByTagName('LevelTwoName')[0].innerHTML;
							var parent;
							var capability;
							var capbilityDefName = capabilityNode.getElementsByTagName('LevelThreeID')[0].innerHTML;
							var capbilityDefDesc = capabilityNode.getElementsByTagName('LevelThreeName')[0].innerHTML;
							capability = cl.get('capability').findWhere({ name: capbilityDefName });
							if (!capability) {
								var capId = DataManager.getDataManager().guidGeneratorByOwner(cl);
								capability = new CapabilityDefinition({ id: capId, name: capbilityDefName, description: capbilityDefDesc, capabilityOwner: cl });
							}
							capability.set('definition', capabilityNode.getElementsByTagName('LevelThreeDefinition')[0].innerHTML);

							if (parentDesc != "") {
								parent = cl.get('capability').findWhere({ description: parentDesc });
								if (parent) {
									parent.get('childCapability').add(capability);
									capability.get('parentCapability').add(parent);
								}
							}
							capabilityNode = capabilityNode.nextElementSibling;
						}
					}
					function importLibrary(cl, levelZeroNode) {
						while (levelZeroNode) {
							libraryName = levelZeroNode.getElementsByTagName('LevelZeroID')[0].innerHTML;
							var libraryDescription = levelZeroNode.getElementsByTagName('LevelZeroName')[0].innerHTML;
							var vrmIndex = libraryDescription.indexOf("VRM - ");
							if (vrmIndex >= 0) {
								libraryDescription = libraryDescription.substring(vrmIndex + 6, libraryDescription.length);
							}
							var libraryDefinition = levelZeroNode.getElementsByTagName('LevelZeroDefinition')[0].innerHTML;
							cl.set('name', libraryName);
							cl.set('description', libraryDescription);
							cl.set('definition', libraryDefinition);
							levelZeroNode = levelZeroNode.nextElementSibling;
						}
					}

					function createValueDeliveryModel(capLibName, callback) {
						//var vrmName = fileObject.name.substr(0, fileObject.name.lastIndexOf('.zip'));
						var vrmName = "VRM Version 3.0 XML representation"
						var currentPlan = DataManager.getDataManager().get('currentPlan');
						if (!currentPlan) {
							currentPlan = planModel;
						}
						var planId = currentPlan.id.substr(currentPlan.id.lastIndexOf('@'));
						var commonAltId = planId + "-Common@";
						var vdm = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: commonAltId + vrmName });
						if(!vdm){
							ValueDeliveryModel.createCapabilityPackage(vrmName, currentPlan, function(vdm){
								var cl = vdm.get('capabilityLibrary').findWhere({ 'name': capLibName });
								if (!cl) {
									cl = vdm.getCapabilityLibraryInstance(capLibName, capLibName, null);
								}
								callback(cl);
							});
						} else {
							var cl = vdm.get('capabilityLibrary').findWhere({ 'name': capLibName });
							if (!cl) {
								cl = vdm.getCapabilityLibraryInstance(capLibName, capLibName, null);
							}
							callback(cl);
						}
					}
					function createBusinessItemLibrary() {
						biLibrary = vdm.get('businessItemLibrary').findWhere({ 'name': libraryName + " Business Item Definitions" });
						if (!biLibrary) {
							var libId = DataManager.getDataManager().guidGeneratorByOwner(vdm);
							biLibrary = new BusinessItemLibrary({ id: libId, name: libraryName + " Business Item Definitions", description: libraryName + " Business Item Definitions", businessItemLibraryOwner: vdm });
							vdm.get('businessItemLibrary').add(biLibrary);
						}
						return biLibrary;
					}
					handleFiles();
				//});
                  
        };
        /*ValueDeliveryModel.createBusinessIndicatorLibrary = function (name, description, vdmPackage, currentPlan, libCallback) {
            var saveWorkspace = !vdmPackage;
            var vdm;
            var ml;
            DataManager.getDataManager().clearSaveInterval();
            if (!vdmPackage) {
                var packageName = name + " BusinessIndicators";
                var planId = currentPlan.id.substr(currentPlan.id.lastIndexOf('@'));
                var commonAltId = planId + "-Common@";
                DataManager.getDataManager().getWorkspaceDataWithId(commonAltId, function (wsData) {
                    var commonWorkspace = wsData.get('workspace');
                    //var previousWorkspace = DataManager.getDataManager().getWorkspace();
                    DataManager.getDataManager().setWorkspace(commonWorkspace, function () {
                        var docId = commonAltId + name.replace(/ /g, "");
                        var vdm = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: docId });
                        if (!vdm) {
                            vdm = new ValueDeliveryModel({
                                'name': packageName,
                                'description': description + " BusinessIndicators",
                                'id': docId,
                                'label': "Base",
                                'libraryPackage': true
                            });
                            var pacRef = new PackageReference({ beepReference: vdm, name: vdm.get('name'), description: vdm.get('description'), version: vdm.get('version'), beepType: vdm.get('type'), label: 'Base' });
                            DataManager.getDataManager().get('currentWorkspace').get('packageReference').add(pacRef);
                            if (saveWorkspace) {
                                DataManager.getDataManager().get('currentWorkspace').save();
                            }
                        }
                        var defaultMetricModel = vdm.createDefaultMetricModel();
                        ml = vdm.get('defaultMetricsModel').getOrCreateBusinessIndicatorLibrary(name);
                        libCallback(ml)
                    });
                });
            } else {
                ml = vdmPackage.get('defaultMetricsModel').getOrCreateBusinessIndicatorLibrary(name);
                libCallback(ml);
            }
        };*/
        ValueDeliveryModel.createCapabilityPackage = function(name, currentPlan, libCallback) {
            //DataManager.getDataManager().clearSaveInterval();
			var packageName = name + " Package";
			var planId = currentPlan.id.substr(currentPlan.id.lastIndexOf('@'));
			var commonAltId = planId + "-Common@";
			DataManager.getDataManager().getWorkspaceDataWithId(commonAltId, function (wsData) {
				var commonWorkspace = wsData.get('workspace');
				//var previousWorkspace = DataManager.getDataManager().getWorkspace();
				DataManager.getDataManager().setWorkspace(commonWorkspace, function () {
					//var docId = commonAltId + name.replace(/ /g, "");
					var vdm = new ValueDeliveryModel({
						'name': packageName,
						'description': packageName,
						'id': window.guidGenerator(),
						'label': "Base",
						'libraryPackage': true
					});
					var pacRef = new PackageReference({ beepReference: vdm, name: vdm.get('name'), description: vdm.get('description'), version: vdm.get('version'), beepType: vdm.get('type'), label: 'Base' });
					DataManager.getDataManager().get('currentWorkspace').get('packageReference').add(pacRef);
					DataManager.getDataManager().get('initializedPackages').add({'id':vdm.get('id'),'version':parseInt(vdm.get('version')),'model':vdm});
					var packList = DataManager.getDataManager().get('planPackages')[currentPlan.get("id")];
					if(packList){
						var obj = {ref: false, documentId: vdm.id, id: vdm.id, type: vdm.get("type"), documentVersion: vdm.get("documentVersion")};
						packList.push(obj);
						DataManager.getDataManager().get('planPackages')[currentPlan.get("id")] = packList;
					}

					DataManager.getDataManager().get('currentWorkspace').save();
					libCallback(vdm)
				});
			});
        };

        ValueDeliveryModel.abstract = false;
        path.ValueDeliveryModel = ValueDeliveryModel;
        export {ValueDeliveryModel};
    //});