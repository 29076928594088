import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
//import * as  backboneLawnchair from 'backbonelawnchair'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {BeepPackageMixin} from '../beeppackage/BeepPackageMixin'
import {BeepPackage} from '../beeppackage/BeepPackage'
import {InstantiationContext} from './InstantiationContext'
import {MeasurableElementInstance} from './MeasurableElementInstance'
import {LookupTableInstance} from './LookupTableInstance'
import { ConceptElement } from '../concept/ConceptElement'
import { MeasurableElement } from '../vdml/MeasurableElement'


export class PlanInstantiationPackageMixin {
    constructor(){
        var path = DataManager.getDataManager().buildAppNsPath("instdef",global.version);
        path.PlanInstantiationPackageMixin = PlanInstantiationPackageMixin;
    }

    defaults(){
        var ret = {
            type: "instdef_PlanInstantiationPackage"
        };
        return jQuery.extend(BeepPackage.prototype.defaults.apply(this),ret);
    }
    static getMixinRelations(){
        return _.union([
            {
                type: Backbone.HasMany,
                containingClass: "instdef_PlanInstantiationPackage",
                key: "initializationContext",
                relatedModel: "instdef.InstantiationContext",
                reverseRelation: {
                    key: "instantiationContextOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },
            {
                type: Backbone.HasMany,
                containingClass: "instdef_PlanInstantiationPackage",
                key: "lookupTableInstance",
                relatedModel: "instdef.LookupTableInstance",
                reverseRelation: {
                    key: "lookupTableInstanceOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            }
        ]);
    };
    static getCumulativeMixinRelations(){
        if (!PlanInstantiationPackageMixin.cummulativeRelations) {
            PlanInstantiationPackageMixin.cummulativeRelations = _.union(PlanInstantiationPackageMixin.getMixinRelations()
                , BeepPackageMixin.getCumulativeMixinRelations()
            );
        }
        return PlanInstantiationPackageMixin.cummulativeRelations.slice();
    };
    static getSuperTypes(){
        return [
            "beeppackage_BeepPackage"
        ];
    };
    static getProperties(){
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            {name : "version",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" }
        ]
    }
    getParent(){
        return this;
    }
    getPackagePath(path){
        if(!path){
            path = [];
        }
        return path;
    }
    getViewProperties(type){
        return {
            templatePath : "views/smm/views/properties/PlanInstantiationPackagePropertiesTemplate.html",
            templateName : "PlanInstantiationPackagePropertiesTemplate",
            viewTypeStr : "appviews/smm/views/properties/PlanInstantiationPackageViewModel",
            tabId : "PlanInstantiationPackageView",
            tabName: "PlanInstantiationPackage"
        }
    }
    //#startCustomMethods

    static getInstantiationPackageInstance(plan) {
        var self = this;
        var instPackId = self.getCommonRepositoryId() + '-InstantiationPackage';
        var instPackage = new PlanInstantiationPackageMixin({ id: instPackId, name: 'Instantiation Package', description: 'Instantiation Package', planId: plan.id });
        return instPackage;
    };

    addInstanceForMeasurableElement(measurableElement,instPackage,instanceContextName){
        var dataManager = DataManager.getDataManager();
        var context = instPackage.get("instantiationContext").findWhere({name:instanceContextName});
        var instId = dataManager.guidGeneratorByOwner(instPackage);
        var instance = new MeasurableElementInstance({ id: instId, name: measurableElement.get("name") + " " + context.get("name"), description: measurableElement.get("name") + " " + context.get("name"),measurableElementId:measurableElement.get("id"), instantiationContextOwner: context });
        context.get("instantiationContext").add(instance);
    }

    addValueInstanceForMeasurableElement(measurableElementInstance,value){
        var dataManager = DataManager.getDataManager();
        var instId = dataManager.guidGeneratorByOwner(measurableElementInstance);
        var context = measurableElementInstance.get("measurableElementInstanceOwner");
        var instance = new ValueInstance({ id: instId, name: value.get("name") + " " + context.get("name"), description: value.get("name") + " " + context.get("name"), valueElementId: value.get("id") ,valueInstanceOwner: measurableElementInstance });
        measurableElementInstance.get("valueInstance").add(instance);
    }
    removeValueInstanceForMeasurableElement(measurableElementInstance,valueId){
        var dataManager = DataManager.getDataManager();
        let valueInstance = measurableElementInstance.get("valueInstance").findWhere({valueElementId: valueId});
        valueInstance.destroy();
    }
    removeInstanceForMeasurableElement(measurableElement,instPackage,instanceContextName){
        let context = instPackage.get("instantiationContext").findWhere({name : instanceContextName});
        let mesElementInstance = context.get("measurableElement").findWhere({measurableElementId:measurableElement.get("id")});
        if(mesElementInstance){
            let valueInstances = mesElementInstance.get("valueInstance").models.slice();
            valueInstances.forEach(element =>{
                element.destroy();
            })
            mesElementInstance.destroy();
        }
    }

    getInstatiationContext(contextName){
        return this.get("initializationContext").findWhere({name:contextName});
    }
    getMeasurableElementInstance(contextName,instanceMeasurableElementId){
        var context =  this.get("initializationContext").findWhere({name:contextName});
        if(context){
            return context.get("measurableElementInstance").findWhere({measurableElementId:instanceMeasurableElementId});
        }
    }
    deleteInstantiationContext(contextName){
        var context = this.getInstatiationContext(contextName);
        if(context){
            var elementInsts = context.get("measurableElementInstance").models.slice();
            elementInsts.forEach(element => {
                var values = element.get("valueInstance").models.slice();
                values.forEach(value =>{
                    delete values[j];
                });
                element.get("valueInstance").reset();
            });
            context.get("measurableElementInstance").reset();
        }
    }
    
    //#endCustomMethods
}