import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AbstractMeasureElement} from './AbstractMeasureElement'
import {AbstractMeasureElementMixin} from './AbstractMeasureElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Measure } from './Measure'
import { CategoryRelationship } from './CategoryRelationship'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/AbstractMeasureElement","appbo/smm/AbstractMeasureElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AbstractMeasureElement,AbstractMeasureElementMixin
, Measure
, CategoryRelationship){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Measure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measure){
			Measure = importPath.Measure;
		}
        else {
            import('./Measure').then(({ default: Measure }) => {
                Measure = Measure;
            });
            /*require(["appbo/smm/Measure"], function (loadedModule) {
                Measure = loadedModule;
			});*/
		}
	}
	if(!CategoryRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.CategoryRelationship){
			CategoryRelationship = importPath.CategoryRelationship;
		}
        else {
            import('./CategoryRelationship').then(({ default: CategoryRelationship }) => {
                CategoryRelationship = CategoryRelationship;
            });
            /*require(["appbo/smm/CategoryRelationship"], function (loadedModule) {
                CategoryRelationship = loadedModule;
			});*/
		}
	}
	export class MeasureCategoryMixin {

	defaults(){
		var ret = {
			type: "smm_MeasureCategory"
		}
		return jQuery.extend(AbstractMeasureElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"smm_MeasureCategory",
			key:"categoryMeasure",
			relatedModel:"smm.Measure",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_MeasureCategory",
			key:"category",
			relatedModel:"smm.MeasureCategory",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_MeasureCategory",
			key:"categoryElement",
			relatedModel:"smm.MeasureCategory",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_MeasureCategory",
			key:"categoryRelationship",
			relatedModel:"smm.CategoryRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"from",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!MeasureCategoryMixin.cummulativeRelations) {
            MeasureCategoryMixin.cummulativeRelations = _.union(MeasureCategoryMixin.getMixinRelations()
                , AbstractMeasureElementMixin.getCumulativeMixinRelations()
            );
        }
		return MeasureCategoryMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_AbstractMeasureElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" }
		]
	}
	getParent(){
		var container;
		return AbstractMeasureElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return AbstractMeasureElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/MeasureCategoryPropertiesTemplate.html",
			templateName : "MeasureCategoryPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/MeasureCategoryViewModel",
			tabId : "MeasureCategoryView",
			tabName: "MeasureCategory"
		}
        }
    }
	path.MeasureCategoryMixin = MeasureCategoryMixin;
	
	//return MeasureCategoryMixin;
//});