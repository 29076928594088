import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BaseMeasureRelationship} from './BaseMeasureRelationship'
import {Base2MeasureRelationshipMixin} from './Base2MeasureRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
//import { BinaryMeasure } from './BinaryMeasure'
//import { DimensionalMeasure } from './DimensionalMeasure'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/BaseMeasureRelationship","appbo/smm/Base2MeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BaseMeasureRelationship,Base2MeasureRelationshipMixin
, BinaryMeasure
, DimensionalMeasure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	//if(!BinaryMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.BinaryMeasure){
			BinaryMeasure = importPath.BinaryMeasure;
		}
        else {
            import('./BinaryMeasure').then(({ default: BinaryMeasure }) => {
                BinaryMeasure = BinaryMeasure;
            });

			/*require(["appbo/smm/BinaryMeasure"],function(loadedModule){
				BinaryMeasure = loadedModule;
			});*/
		}
	//}
	//if(!DimensionalMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasure){
			DimensionalMeasure = importPath.DimensionalMeasure;
		}
        else {
            import('./DimensionalMeasure').then(({ default: DimensionalMeasure }) => {
                DimensionalMeasure = DimensionalMeasure;
            });

			/*require(["appbo/smm/DimensionalMeasure"],function(loadedModule){
				DimensionalMeasure = loadedModule;
			});*/
		}
	//}
	var Base2MeasureRelationship = BaseMeasureRelationship.extend(utils.customExtends({
		relations:Base2MeasureRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BaseMeasureRelationship.prototype.initialize.apply(this, arguments);
			if(attributes.influence){
				this.set('influence',path.Influence[attributes.influence instanceof Object ? attributes.influence.name : attributes.influence]);
			}
		}
		}
		, new Base2MeasureRelationshipMixin()
	));
	Base2MeasureRelationship.abstract = false;
	Base2MeasureRelationship.supported = false; 	//As we are not using it yet
	path.Base2MeasureRelationship = Base2MeasureRelationship;
	export {Base2MeasureRelationship};
//});