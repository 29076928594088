import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmElement} from './SmmElement'
import {SmmElementMixin} from './SmmElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/SmmElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,SmmElementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	export class SmmRelationshipMixin {

	defaults(){
		var ret = {
			type: "smm_SmmRelationship"
		}
		return jQuery.extend(SmmElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!SmmRelationshipMixin.cummulativeRelations) {
            SmmRelationshipMixin.cummulativeRelations = _.union(SmmRelationshipMixin.getMixinRelations()
                , SmmElementMixin.getCumulativeMixinRelations()
            );
        }
		return SmmRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_SmmElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" }
		]
	}
	getParent(){
		var container;
		return SmmElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return SmmElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/SmmRelationshipPropertiesTemplate.html",
			templateName : "SmmRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/SmmRelationshipViewModel",
			tabId : "SmmRelationshipView",
			tabName: "SmmRelationship"
		}
	}
	
	}
	path.SmmRelationshipMixin = SmmRelationshipMixin;
	//return SmmRelationshipMixin;
//});