import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { Role } from './Role'
import { RoleMixin } from './RoleMixin'
import { Pool } from './Pool'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Role","appbo/vdml/RoleMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Role,RoleMixin
, Pool){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Pool){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Pool){
			Pool = importPath.Pool;
		}
        else {
            import('./Pool').then(loadedModule => {
                Pool = loadedModule;
            });
			/*require(["appbo/vdml/Pool"],function(loadedModule){
				Pool = loadedModule;
			});*/
		}
	}
export class PositionMixin {

    defaults () {
        var ret = {
            type: "vdml_Position"
        }
        return jQuery.extend(Role.prototype.defaults.apply(this), ret);
    }
    static getMixinRelations () {
        return _.union([
            {
                type: Backbone.HasMany,
                containingClass: "vdml_Position",
                key: "actorPool",
                relatedModel: "vdml.Pool",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            }
        ])
    }
    static getCumulativeMixinRelations () {
        if (!PositionMixin.cummulativeRelations) {
            PositionMixin.cummulativeRelations = _.union(PositionMixin.getMixinRelations()
                , RoleMixin.getCumulativeMixinRelations()
            );
        }
        return PositionMixin.cummulativeRelations.slice();
    }
    static getSuperTypes () {
        return [
            "vdml_Role"
        ];
    }
    static getProperties () {
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "progress", type: "double", defaultValue: "0", containingClass: "cmof_EObject" },
            { name: "represents", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "isLead", type: "EBoolean", defaultValue: "null", containingClass: "vdml_Role" }
        ]
    }
    getParent () {
        return Role.prototype.getParent.apply(this, arguments);
    }
    getPackagePath (path) {
        if (!path) {
            path = [];
        }
        var container;
        if (!container) {
            return Role.prototype.getPackagePath.apply(this, arguments);
        }
        return path;
    }
    getViewProperties (type) {
        return {
            templatePath: "views/vdml/views/properties/PositionPropertiesTemplate.html",
            templateName: "PositionPropertiesTemplate",
            viewTypeStr: "appviews/vdml/views/properties/PositionViewModel",
            tabId: "PositionView",
            tabName: "Position"
        }
    }
    
}
path.PositionMixin = PositionMixin;
	//return PositionMixin;
//});