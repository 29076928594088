import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { PolynomialCoefficient } from './PolynomialCoefficient'
import { SeasonalFactor } from './SeasonalFactor'
import { TrendModel } from './TrendModel'

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

export class ProjectionProfileMixin {

    defaults(){
        var ret = {
            type: "vdml_ProjectionProfile"
        }
        return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
    }
    static getMixinRelations(){
        return _.union([
            {
                type :Backbone.HasMany,
                containingClass:"vdml_PolynomialCoefficient",
                key:"coefficients",
                relatedModel:"vdml.PolynomialCoefficient",
                reverseRelation: {
                    key: "coefficientsOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },
            {
                type :Backbone.HasMany,
                containingClass:"vdml_SeasonalFactor",
                key:"seasonalFactors",
                relatedModel:"vdml.SeasonalFactor",
                reverseRelation: {
                    key: "seasonalFactorsOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            }
        ])
    }
    
    static getCumulativeMixinRelations(){
        if (!ProjectionProfileMixin.cummulativeRelations) {
            ProjectionProfileMixin.cummulativeRelations = _.union(ProjectionProfileMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
        return ProjectionProfileMixin.cummulativeRelations.slice();
    }
    static getSuperTypes(){
        return [
            "vdml_VdmlElement"
        ];
    }
    static getProperties(){
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            {name : "trendModel",type : "TrendModel",defaultValue : "null",containingClass : "vdml_ProjectionProfile" },
            {name : "rateFactor",type : "double",defaultValue : "null",containingClass : "vdml_ProjectionProfile" },
            {name : "polynomialDegree",type : "int",defaultValue : "null",containingClass : "vdml_ProjectionProfile" }
        ]
    }
    getParent(){
        var container;
        if(!container){
            container = this.get("projectionProfileOwner") ? this.get("projectionProfileOwner") : this.previousAttributes().projectionProfileOwner;
            if(container){
                return container;
            }
        }
        return this;
    }
    getPackagePath(path){
        if(!path){
            path = [];
        }
        var container;
        if(!container){
            container = this.get("projectionProfileOwner") ? this.get("projectionProfileOwner") : this.previousAttributes().projectionProfileOwner;
            if(container){
                path.unshift({id:this.get('id'),name:this.get('name')})
                return container.getPackagePath(path);
            }
        }
        return path;
    }
    getViewProperties(type){
        return {
            templatePath : "views/vdml/views/properties/ProjectionProfilePropertiesTemplate.html",
            templateName : "ProjectionProfilePropertiesTemplate",
            viewTypeStr : "appviews/vdml/views/properties/ProjectionProfileViewModel",
            tabId : "ProjectionProfileView",
            tabName: "ProjectionProfile"
        }
    }

}
path.ProjectionProfileMixin = ProjectionProfileMixin;
