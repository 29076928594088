import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {LookupTable} from './LookupTable'
import {PatternLookupTableDefinitionMixin} from './PatternLookupTableDefinitionMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("lookup",global.version);

var PatternLookupTableDefinition = LookupTable.extend(utils.customExtends({
        relations:PatternLookupTableDefinitionMixin.getMixinRelations(),
        initialize: function(attributes, options) {
            LookupTable.prototype.initialize.apply(this, arguments);
        }
    }
    , new PatternLookupTableDefinitionMixin()
));
PatternLookupTableDefinition.abstract = true;
path.PatternLookupTableDefinition = PatternLookupTableDefinition;
export {PatternLookupTableDefinition};
