import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Scenario } from './Scenario' 

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, Scenario){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Scenario){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Scenario){
			Scenario = importPath.Scenario;
		}
		else{
            import('./Scenario').then(({ default: Scenario }) => {
                Scenario = Scenario;
			});
			/*require(["appbo/vdml/Scenario"],function(loadedModule){
				Scenario = loadedModule;
			});*/
		}
	}
	export class ReleaseControlMixin {

	defaults(){
		var ret = {
			type: "vdml_ReleaseControl"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_ReleaseControl",
			key:"scenario",
			relatedModel:"vdml.Scenario",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	
	static getCumulativeMixinRelations(){
		 if(!ReleaseControlMixin.cummulativeRelations) {
            ReleaseControlMixin.cummulativeRelations = _.union(ReleaseControlMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
         }
		 return ReleaseControlMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "strategy",type : "EString",defaultValue : "null",containingClass : "vdml_ReleaseControl" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("releaseControlOwner") ? this.get("releaseControlOwner") : this.previousAttributes().releaseControlOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("releaseControlOwner") ? this.get("releaseControlOwner") : this.previousAttributes().releaseControlOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ReleaseControlPropertiesTemplate.html",
			templateName : "ReleaseControlPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ReleaseControlViewModel",
			tabId : "ReleaseControlView",
			tabName: "ReleaseControl"
		}
	}
	
	}
	path.ReleaseControlMixin = ReleaseControlMixin;
	//return ReleaseControlMixin;
//});