import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BinaryMeasurement} from './BinaryMeasurement'
import {RatioMeasurementMixin} from './RatioMeasurementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/BinaryMeasurement","appbo/smm/RatioMeasurementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BinaryMeasurement,RatioMeasurementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var RatioMeasurement = BinaryMeasurement.extend(utils.customExtends({
		relations:RatioMeasurementMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BinaryMeasurement.prototype.initialize.apply(this, arguments);
		}
		}
		, new RatioMeasurementMixin()
	));
	
	RatioMeasurement.abstract = false;
	RatioMeasurement.supported = false;
	path.RatioMeasurement = RatioMeasurement;
	export {RatioMeasurement};
//});