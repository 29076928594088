import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AbstractMeasureElement} from './AbstractMeasureElement'
import {AbstractMeasureElementMixin} from './AbstractMeasureElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
/*import { ObservedMeasure } from './ObservedMeasure'
import { Characteristic } from './Characteristic'
import { Scope } from './Scope'
import { MeasureCategory } from './MeasureCategory'
import { RefinementMeasureRelationship } from './RefinementMeasureRelationship'
import { EquivalentMeasureRelationship } from './EquivalentMeasureRelationship'
import { MeasureRelationship } from './MeasureRelationship'
import { Operation } from './Operation'
import { RescaledMeasure } from './RescaledMeasure'*/

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/AbstractMeasureElement","appbo/smm/AbstractMeasureElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AbstractMeasureElement,AbstractMeasureElementMixin
, ObservedMeasure
, Characteristic
, Scope
, MeasureCategory
, RefinementMeasureRelationship

, EquivalentMeasureRelationship

, MeasureRelationship
, Operation
, RescaledMeasure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	//if(!ObservedMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.ObservedMeasure){
			ObservedMeasure = importPath.ObservedMeasure;
		}
        else {
            import('./ObservedMeasure').then(({ default: ObservedMeasure }) => {
                ObservedMeasure = ObservedMeasure;
            });
           /* require(["appbo/smm/ObservedMeasure"], function (loadedModule) {
                ObservedMeasure = loadedModule;
			});*/
		}
	//}
	//if(!Characteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Characteristic){
			Characteristic = importPath.Characteristic;
		}
        else {
            import('./Characteristic').then(({ default: Characteristic }) => {
                Characteristic = Characteristic;
            });
           /* require(["appbo/smm/Characteristic"], function (loadedModule) {
                Characteristic = loadedModule;
			});*/
		}
	//}
	//if(!Scope){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Scope){
			Scope = importPath.Scope;
		}
        else {
            import('./Scope').then(({ default: Scope }) => {
                Scope = Scope;
            });
           /* require(["appbo/smm/Scope"], function (loadedModule) {
                Scope = loadedModule;
			});*/
		}
	//}
	//if(!MeasureCategory){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.MeasureCategory){
			MeasureCategory = importPath.MeasureCategory;
		}
        else {
            import('./MeasureCategory').then(({ default: MeasureCategory }) => {
                MeasureCategory = MeasureCategory;
            });
            /*require(["appbo/smm/MeasureCategory"], function (loadedModule) {
                MeasureCategory = loadedModule;
			});*/
		}
	//}
	//if(!RefinementMeasureRelationship){
		/*var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RefinementMeasureRelationship){
			RefinementMeasureRelationship = importPath.RefinementMeasureRelationship;
		}
        else {
            import('./RefinementMeasureRelationship').then(({ default: RefinementMeasureRelationship }) => {
                RefinementMeasureRelationship = RefinementMeasureRelationship;
            });
            require(["appbo/smm/RefinementMeasureRelationship"], function (loadedModule) {
                RefinementMeasureRelationship = loadedModule;
			});
		}*/
	//}
	
	
	//if(!EquivalentMeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.EquivalentMeasureRelationship){
			EquivalentMeasureRelationship = importPath.EquivalentMeasureRelationship;
		}
        else {
            import('./EquivalentMeasureRelationship').then(({ default: EquivalentMeasureRelationship }) => {
                EquivalentMeasureRelationship = EquivalentMeasureRelationship;
            });
            /*require(["appbo/smm/EquivalentMeasureRelationship"], function (loadedModule) {
                EquivalentMeasureRelationship = loadedModule;
			});*/
		}
	//}
	//if(!MeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.MeasureRelationship){
			MeasureRelationship = importPath.MeasureRelationship;
		}
        else {
            import('./MeasureRelationship').then(({ default: MeasureRelationship }) => {
                MeasureRelationship = MeasureRelationship;
            });
            /*require(["appbo/smm/MeasureRelationship"], function (loadedModule) {
                MeasureRelationship = loadedModule;
			});*/
		}
	//}
	//if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
        else {
            import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });
           /* require(["appbo/smm/Operation"], function (loadedModule) {
                Operation = loadedModule;
			});*/
		}
	//}
	//if(!RescaledMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RescaledMeasure){
			RescaledMeasure = importPath.RescaledMeasure;
		}
        else {
            import('./RescaledMeasure').then(({ default: RescaledMeasure }) => {
                RescaledMeasure = RescaledMeasure;
            });
            /*require(["appbo/smm/RescaledMeasure"], function (loadedModule) {
                RescaledMeasure = loadedModule;
			});*/
		}
	//}
	export class MeasureMixin {

	defaults(){
		var ret = {
			type: "smm_Measure"
		}
		return jQuery.extend(AbstractMeasureElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"smm_Measure",
			key:"observedMeasure",
			relatedModel:"smm.ObservedMeasure",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"measure",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"smm_Measure",
			key:"scope",
			relatedModel:"smm.Scope",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_Measure",
			key:"category",
			relatedModel:"smm.MeasureCategory",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		/*{
			type :Backbone.HasMany,
			containingClass:"smm_Measure",
			key:"refinementFrom",
			relatedModel:"smm.RefinementMeasureRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"to",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_Measure",
			key:"refinementTo",
			relatedModel:"smm.RefinementMeasureRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"from",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},*/
		{
			type :Backbone.HasMany,
			containingClass:"smm_Measure",
			key:"equivalentTo",
			relatedModel:"smm.EquivalentMeasureRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"from",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_Measure",
			key:"equivalentFrom",
			relatedModel:"smm.EquivalentMeasureRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"to",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_Measure",
			key:"measureRelationships",
			relatedModel:"smm.MeasureRelationship",
			reverseRelation: {
				key:"measure",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		])
	}
	static getCumulativeMixinRelations(){
		if (!MeasureMixin.cummulativeRelations) {
            MeasureMixin.cummulativeRelations = _.union(MeasureMixin.getMixinRelations()
                , AbstractMeasureElementMixin.getCumulativeMixinRelations()
            );
        }
		return MeasureMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_AbstractMeasureElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			//{name : "definition",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "scale",type : "ScaleOfMeasurement",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "customScale",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "source",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "visible",type : "EBoolean",defaultValue : "null",containingClass : "smm_Measure" }
		]
	}
	getParent(){
		var container;
		return AbstractMeasureElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return AbstractMeasureElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/MeasurePropertiesTemplate.html",
			templateName : "MeasurePropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/MeasureViewModel",
			tabId : "MeasureView",
			tabName: "Measure"
		}
	}

//#startCustomMethods
	measureUsesPreviousAltRelation(){
		return false;
	};
	getIntervalTupleSet(){
		var measure = this;
		var intervalTupleSet = [];
		if(measure) {
			var intervals = measure.get('interval');
			var intervalCount = 0;
			intervals.each(function(intervalTuple){
				if(intervalTuple.get('type') === "smm_GradeMeasure"){ //Grade Measure
	                var obj = {};
	                var intCount = intervalCount++;
	                obj.new = {seqNo:intCount,id:intervalTuple.get('id'),symbol:intervalTuple.get('symbol'),minimumEndPoint:intervalTuple.get('minimumEndPoint'),maximumEndPoint:intervalTuple.get('maximumEndPoint'),maximumOpen:intervalTuple.get('maximumOpen'),minimumOpen:intervalTuple.get('minimumOpen')};
	                obj.old = {seqNo:intCount,id:intervalTuple.get('id'),symbol:intervalTuple.get('symbol'),minimumEndPoint:intervalTuple.get('minimumEndPoint'),maximumEndPoint:intervalTuple.get('maximumEndPoint'),maximumOpen:intervalTuple.get('maximumOpen'),minimumOpen:intervalTuple.get('minimumOpen')};
	                intervalTupleSet.push(obj);
	            }
	            else{ //Ranking Measure
	                var obj = {};
	                var intCount = intervalCount++;
	                obj.new = {seqNo:intCount,id:intervalTuple.get('id'),value:intervalTuple.get('value'),minimumEndPoint:intervalTuple.get('minimumEndPoint'),maximumEndPoint:intervalTuple.get('maximumEndPoint'),maximumOpen:intervalTuple.get('maximumOpen'),minimumOpen:intervalTuple.get('minimumOpen')};
	                obj.old = {seqNo:intCount,id:intervalTuple.get('id'),value:intervalTuple.get('value'),minimumEndPoint:intervalTuple.get('minimumEndPoint'),maximumEndPoint:intervalTuple.get('maximumEndPoint'),maximumOpen:intervalTuple.get('maximumOpen'),minimumOpen:intervalTuple.get('minimumOpen')};
	                intervalTupleSet.push(obj);
	            }
				
			});	
		}
		return intervalTupleSet;
	};
	measureMatches(charToMatch,unitToMatch,typeToMatch,accToMatch,baseRelSetToMatch,intervalTupleSet,isDimensional){
		var self = this;
		var isMeasureMatches = true;
		if(!self.get('trait')){
			return;
		}
		var charMeasure = self.get('trait').get('name');
		if(charMeasure !== charToMatch){
			return false;
		}
		var unitMeasure = self.get('unit');
		if((!unitMeasure && (unitToMatch && unitToMatch.unitName)) || (unitMeasure && (!unitToMatch || !unitToMatch.unitName))){
			return false;
		}
		if(unitMeasure){
			var strUnit = unitMeasure.get('name');
			if(strUnit !== unitToMatch.unitName){
				return false;
			}
		}
		if(this.get('type') !== typeToMatch){
			return false;
		}		
		
		if(typeToMatch === "smm_GradeMeasure" || typeToMatch === "smm_RankingMeasure"){
			if(this.get('measureRelationships').length>0 !== isDimensional){
				return false;
			}
			if(baseRelSetToMatch[0].measurement){
				var baseRels = self.get('measureRelationships');
				var measure = baseRelSetToMatch[0].measurement.get('observedMeasure').get('measure');
				if(baseRels && measure){
					var relationFound = false;
					for(var j=0;j<baseRels.length;j++){
						if(measure === baseRels.at(j).get('to')){
							relationFound = true;
							break;
						}
					}
					if(!relationFound){
						return false;
					}
				}
			}			
			var intervals = self.get('interval');
			if((intervalTupleSet && intervalTupleSet.length === intervals.length) || (!intervalTupleSet && intervals.length === 0)){
				if(intervalTupleSet){
					var matchIntervalCount = 0;
					for(var i=0; i<intervalTupleSet.length; i++){
						for(var j=0; j<intervals.length; j++){
							var matchFound = true;
							var interval = intervals.at(j);
							for(var prop in intervalTupleSet[i]){
								if(prop === 'id' || prop === 'seqNo'){
									continue;
								}
								if(intervalTupleSet[i][prop] !== interval.get(prop)){
									matchFound = false;
									break;
								}
							}
							if(matchFound){
								matchIntervalCount++;
								break;
							}
						}
					}
					if(matchIntervalCount !== intervalTupleSet.length){
						return false;
					}
				}
			}else{
				return false;
			}
		}
		if(typeToMatch === "smm_CollectiveMeasure"){
			var baseRels = self.get('baseMeasureTo');
			if((!baseRelSetToMatch && baseRels.length === 0) || (baseRelSetToMatch && baseRelSetToMatch.length === baseRels.length)){
				if(baseRelSetToMatch){
					for(var i=0; i<baseRelSetToMatch.length; i++){
						var relationFound = false;
						for(var j=0;j<baseRels.length;j++){
							var baseRel = baseRels.at(j);
							if(baseRelSetToMatch[i].measure === baseRel.get('to')){
								relationFound = true;
								if(baseRel.get('usePreviousAlternative') !== baseRelSetToMatch[i]['usePreviousAlternative']){
									isMeasureMatches= false;
								}else{
									for(var prop in baseRelSetToMatch[i]){
										if(prop === "offset" || prop === "multiplier" || prop === "operation"){
											var rescaledMeasure = baseRel.get('rescaledMeasure');
											if(rescaledMeasure){
												if((prop === 'offset' || prop === 'multiplier') && (baseRelSetToMatch[i][prop] !== rescaledMeasure.get(prop))) {
													isMeasureMatches= false;
													break;
												}
												if(prop === 'operation' && 
													((baseRelSetToMatch[i][prop] && !rescaledMeasure.get('operation')) 
														|| (baseRelSetToMatch[i][prop] && !(rescaledMeasure.get('operation').get('name') === baseRelSetToMatch[i][prop]))
														|| (!baseRelSetToMatch[i][prop] && rescaledMeasure.get('operation')) )){
													isMeasureMatches= false;
													break;
												}
											}else{
												if(prop === 'offset' && baseRelSetToMatch[i][prop] !== 0){
													isMeasureMatches= false;
													break;
												}
												if(prop === 'multiplier' && baseRelSetToMatch[i][prop] !== 1){
													isMeasureMatches= false;
													break;
												}
												if(prop === 'operation' && (baseRelSetToMatch[i][prop] != null && baseRelSetToMatch[i][prop] !== "")){
													isMeasureMatches= false;
													break;
												}									
											}
										}
									}
								}
							}
							if(relationFound){
								baseRelSetToMatch[i].baseMeasureRel = baseRel;
								break;
							}
						}
						if(!relationFound || !isMeasureMatches){
							return false;
						}
					}					
				}
			}else{
				return false;
			}
			var accToMatchMeasure = self.get('accumulator');
			if(baseRelSetToMatch.length > 1 && accToMatchMeasure != accToMatch ){
				return false;	
			}
		}
		return true;
	}
	//TODO check if this is fine with the datamanager and logging..
	createCopyWithOutMeasureRelationsships(){	
		var library = this.get('libraries');
		var copy = this.clone({libraries:library});
		copy.set('name',library.getUniqueProperty('name',copy.get('name'),'measuredElements'));
		copy.get('measureRelationships').reset();
		if(copy.get('type') === 'smm_GradeMeasure' || copy.get('type') === 'smm_RankingMeasure'){
			var intervals = this.get('intervals');
			var copyIntervals = copy.get('intervals');
			intervals.each(function(interval){
				copyIntervals.add(interval.clone());
			});
		}
	}
	rescaleToFixUnitDifference(rescaleUp,rescaleDown){
		var self=this;
    	var measureRelationships = this.get('measureRelationships');
	    if(rescaleDown){
	        measureRelationships.each(function(bmr){
		        if(bmr.get('type')==="smm_BaseNMeasureRelationship"){
	        		if(bmr.get('rescaledMeasure') && bmr.get('rescaledMeasure')!==null){
			      		var resc = bmr.get('rescaledMeasure');
			      		if(resc.get('unit')!==self.get('unit')){
			      			resc.set('unit',self.get('unit'));
			      		}
			      	}
			     	else{
				     	if(bmr.get('to').get('unit')!==self.get('unit')){
			      			var bmrm = bmr.get('to');
			      			var measureLib = self.get('libraries');
			      			var reMeasureId = DataManager.getDataManager().guidGeneratorByOwner(bmr);
                            var RescaledMeasure = Backbone.Relational.store.getObjectByName('smm.RescaledMeasure');
							var resc = new RescaledMeasure({id:reMeasureId,name:self.get('name')+ " RescaledMeasure",rescaledMeasureOwner:bmr});
							//resc.set('unit',self.get('unit'));
							resc.set('trait',bmrm.get('trait'));
							resc.set('scope',bmrm.get('scope'));
							resc.set('offset',0);
							resc.set('multiplier',1);
							bmr.set('rescaledMeasure',resc);
			      		}
			      	}
	        	}
		      	
	    	});
	    }
	    if(rescaleUp){
	    	measureRelationships.each(function(bmr){
	    		if(bmr.get('type')==="smm_BaseMeasureRelationship"){
	    			var measureParent = bmr.get('to');
		    		if(measureParent==self){
		    			if(!bmr.get('rescaledMeasure')){
		    				if(measureParent.get('unit')!==self.get('unit')){
		    					var measureLib = measureParent.get('libraries');
		    					var reMeasureId = DataManager.getDataManager().guidGeneratorByOwner(measureLib);
						        var resc = new RescaledMeasure({id:reMeasureId,name:bmr.getUniqueProperty('name',self.get('name')+" Rescaled",'measureElements'),'libraries':measureLib,rescaledMeasureOwner:bmr});
						        resc.set('unit',measureParent.get('unit'));
						        resc.set('trait',self.get('trait'));
								resc.set('scope',self.get('scope'));
								resc.set('offset',0);
								resc.set('multiplier',1);
								//bmr.set('rescaledMeasure',resc);
		    				}
		    			}
		    		}
	    		}
	    	});
	    }
	    //TODO Create Suggestion Ticket to have a look on  offset and multiplier
	}
	
	constructIntervalTupleSet(satisfactionType){
		var intervalTupleSet = [];
		var intervals = this.get('interval');
		intervals.each(function(intv) {
			var tuple = {};
			var obj = {maximumOpen:intv.get('maximumOpen'),minimumOpen:intv.get('minimumOpen'),maximumEndPoint:intv.get('maximumEndPoint'),minimumEndPoint:intv.get('minimumEndPoint')};
			if(satisfactionType === "Grade"){
				obj.symbol = intv.get('symbol');
			}else{
				obj.value = intv.get('value'); 
			}
			tuple.old = obj;
			intervalTupleSet.push(tuple);
		});
		return _.uniq(intervalTupleSet);
	}	
	 
 	reused(measuredCharacteristic){
 		var observedMeasures  = this.get('observedMeasure');
 		var ret = false;
 		observedMeasures.each(function(obsMes){
 			if(!ret){
	 			var measurements = obsMes.get('measurements');
	 			if(measurements.length> 0 && measurements.at(0).get('measuredCharacteristic') !== measuredCharacteristic){
	 				ret = true;
	 			}
 			}
 		});
 		return ret;
 	};
 	
//#endCustomMethods		
	
	}
	path.MeasureMixin = MeasureMixin;
	//return MeasureMixin;
//});
