import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {CollaborationDiagram} from './CollaborationDiagram'
import {ValueStreamMapDiagramMixin} from './ValueStreamMapDiagramMixin'
import {ValueStreamMapDiagram2Mixin} from './ValueStreamMapDiagram2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/ValueStreamMapDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,ValueStreamMapDiagramMixin
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? ValueStreamMapDiagram2Mixin : ValueStreamMapDiagramMixin;
	}
	var mixinClass = getMixinClass();
	var ValueStreamMapDiagram = CollaborationDiagram.extend(utils.customExtends({
		relations:mixinClass.getMixinRelations(),
		initialize: function(attributes, options) {
		    CollaborationDiagram.prototype.initialize.apply(this, arguments);
		    /*this.on('remove:participant', this.handleRemoveParticipant); 
		    this.on('remove:businessModel', this.handleRemoveBusinessModel);
		    this.on('remove:valueProposition', this.handleRemoveValueProposition);*/
		}
		}
		, new mixinClass()
	));
    ValueStreamMapDiagram['remove:valueStream'] = function (model, coll, options) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.handleRemoveParticipant(model, coll, options);
		}
    }
    ValueStreamMapDiagram['remove:activity'] = function(model, coll, options) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.handleRemoveBusinessModel(model, coll, options);
		}
    }
    ValueStreamMapDiagram['remove:competence'] = function (model, coll, options) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.handleRemoveValueProposition(model, coll, options);
		}
    };
	ValueStreamMapDiagram.getInstance = function(name,description,owner,id){
		var eid = id ? id : DataManager.getDataManager().guidGeneratorByOwner(owner);
		var valueStreamMap = new ValueStreamMapDiagram({id:eid,name:owner.getUniqueProperty('name',name,'diagrams'),description:owner.getUniqueProperty('description',description,'diagrams'), ecoMap:owner});
		return valueStreamMap;
	}
	ValueStreamMapDiagram.prototype.getSystemTags = function () {
	    return ['VDM', 'Value Stream Map' ,'Discover'];
	};
	
	ValueStreamMapDiagram.abstract = false;
	ValueStreamMapDiagram.supported = true;
	path.ValueStreamMapDiagram = ValueStreamMapDiagram;
	export {ValueStreamMapDiagram};
//});