(function (factory) {
  /* global define */
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node/CommonJS
    module.exports = factory(require('jquery'));
  } else {
    // Browser globals
    factory(window.jQuery);
  }
}(function ($) {

  $.extend(true,$.summernote.lang, {
		'en-US': {
		  backgroundColor: {
			tooltip: 'Background-Color'
		  },
		  tooltip: 'auto',
		  container: 'body',
		  color: {
              recent: 'Recent Color',
              more: 'More Color',
              background: 'Background Color',
              foreground: 'Foreground Color',
              transparent: 'Transparent',
              setTransparent: 'Set transparent',
              reset: 'Reset',
              resetToDefault: 'Reset to default',
              cpSelect: 'Select'
          },
		  colors: [
              ['#000000', '#424242', '#636363', '#9C9C94', '#CEC6CE', '#EFEFEF', '#F7F7F7', '#FFFFFF'],
              ['#FF0000', '#FF9C00', '#FFFF00', '#00FF00', '#00FFFF', '#0000FF', '#9C00FF', '#FF00FF'],
              ['#F7C6CE', '#FFE7CE', '#FFEFC6', '#D6EFD6', '#CEDEE7', '#CEE7F7', '#D6D6E7', '#E7D6DE'],
              ['#E79C9C', '#FFC69C', '#FFE79C', '#B5D6A5', '#A5C6CE', '#9CC6EF', '#B5A5D6', '#D6A5BD'],
              ['#E76363', '#F7AD6B', '#FFD663', '#94BD7B', '#73A5AD', '#6BADDE', '#8C7BC6', '#C67BA5'],
              ['#CE0000', '#E79439', '#EFC631', '#6BA54A', '#4A7B8C', '#3984C6', '#634AA5', '#A54A7B'],
              ['#9C0000', '#B56308', '#BD9400', '#397B21', '#104A5A', '#085294', '#311873', '#731842'],
              ['#630000', '#7B3900', '#846300', '#295218', '#083139', '#003163', '#21104A', '#4A1031']
          ],
          // http://chir.ag/projects/name-that-color/
          colorsName: [
              ['Black', 'Tundora', 'Dove Gray', 'Star Dust', 'Pale Slate', 'Gallery', 'Alabaster', 'White'],
              ['Red', 'Orange Peel', 'Yellow', 'Green', 'Cyan', 'Blue', 'Electric Violet', 'Magenta'],
              ['Azalea', 'Karry', 'Egg White', 'Zanah', 'Botticelli', 'Tropical Blue', 'Mischka', 'Twilight'],
              ['Tonys Pink', 'Peach Orange', 'Cream Brulee', 'Sprout', 'Casper', 'Perano', 'Cold Purple', 'Careys Pink'],
              ['Mandy', 'Rajah', 'Dandelion', 'Olivine', 'Gulf Stream', 'Viking', 'Blue Marguerite', 'Puce'],
              ['Guardsman Red', 'Fire Bush', 'Golden Dream', 'Chelsea Cucumber', 'Smalt Blue', 'Boston Blue', 'Butterfly Bush', 'Cadillac'],
              ['Sangria', 'Mai Tai', 'Buddha Gold', 'Forest Green', 'Eden', 'Venice Blue', 'Meteorite', 'Claret'],
              ['Rosewood', 'Cinnamon', 'Olive', 'Parsley', 'Tiber', 'Midnight Blue', 'Valentino', 'Loulou']
          ]
		}
  });
  var Renderer = /** @class */ (function () {
      function Renderer(markup, children, options, callback) {
          this.markup = markup;
          this.children = children;
          this.options = options;
          this.callback = callback;
      }
      Renderer.prototype.render = function ($parent) {
          var $node = $(this.markup);
          if (this.options && this.options.contents) {
              $node.html(this.options.contents);
          }
          if (this.options && this.options.className) {
              $node.addClass(this.options.className);
          }
          if (this.options && this.options.data) {
              $.each(this.options.data, function (k, v) {
                  $node.attr('data-' + k, v);
              });
          }
          if (this.options && this.options.click) {
              $node.on('click', this.options.click);
          }
          if (this.children) {
              var $container_1 = $node.find('.note-children-container');
              this.children.forEach(function (child) {
                  child.render($container_1.length ? $container_1 : $node);
              });
          }
          if (this.callback) {
              this.callback($node, this.options);
          }
          if (this.options && this.options.callback) {
              this.options.callback($node);
          }
          if ($parent) {
              $parent.append($node);
          }
          return $node;
      };
      return Renderer;
  }());
  var renderer = {
      create: function (markup, callback) {
          return function () {
              var options = typeof arguments[1] === 'object' ? arguments[1] : arguments[0];
              var children = $.isArray(arguments[0]) ? arguments[0] : [];
              if (options && options.children) {
                  children = options.children;
              }
              return new Renderer(markup, children, options, callback);
          };
      }
  };
  var palette = renderer.create('<div class="note-color-palette"></div>', function ($node, options) {
      var contents = [];
      for (var row = 0, rowSize = options.colors.length; row < rowSize; row++) {
          var eventName = options.eventName;
          var colors = options.colors[row];
          var colorsName = options.colorsName[row];
          var buttons = [];
          for (var col = 0, colSize = colors.length; col < colSize; col++) {
              var color = colors[col];
              var colorName = colorsName[col];
              buttons.push([
                  '<button type="button" class="note-color-btn"',
                  'style="background-color:', color, '" ',
                  'data-event="', eventName, '" ',
                  'data-value="', color, '" ',
                  'title="', colorName, '" ',
                  'aria-label="', colorName, '" ',
                  'data-toggle="button" tabindex="-1"></button>'
              ].join(''));
          }
          contents.push('<div class="note-color-row">' + buttons.join('') + '</div>');
      }
      $node.html(contents.join(''));
      if (options.tooltip) {
          $node.find('.note-color-btn').tooltip({
              container: options.container,
              trigger: 'hover',
              placement: 'bottom'
          });
      }
  });
  $.extend($.summernote.plugins, {
    'backgroundColor': function (context) {
      var options = context.options;
      var lang    = options.langInfo;
	  var ui      = $.summernote.ui;
	  context.memo('button.backgroundColor',function() {
	      var button = ui.buttonGroup([
			  ui.button({
				    className: 'dropdown-toggle',
					contents: '<b>B</b><span class="caret"></span>',
					tooltip: lang.backgroundColor.tooltip,
					data: {
						toggle: 'dropdown'
					}
			  }),
			  ui.dropdown({
			      className: 'drop-default summernote-list',
				  contents: ([
                          '<div class="note-palette" style = "width:175px;padding-left:5px">',
                          '  <div class="note-palette-title">' + lang.color.background + 
						  '    <input type="color" id="selectedBackColor" class="note-btn note-color-select-btn" value="#FFFFFF" data-event="backColorPalette"></div>',
                          ' </br> <div class="note-holder" data-event="backColor"></div>',
                          '  <div class="note-holder-custom" id="backColorPalette" data-event="backColor"></div>',
                          '</div>'
                  ].join('')),
				  callback: function ($dropdown) {
                          $dropdown.find('.note-holder').each(function (idx, item) {
                              var $holder = $(item);
                              $holder.append(palette({
                                  colors: lang.colors,
                                  colorsName: lang.colorsName,
                                  eventName: $holder.data('event'),
                                  container: lang.container,
                                  tooltip: lang.tooltip
                              }).render());
                          });
                          /* TODO: do we have to record recent custom colors within cookies? */
                          var customColors = [
                              ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']
                          ];
                          $dropdown.find('.note-holder-custom').each(function (idx, item) {
                              var $holder = $(item);
                              $holder.append(palette({
                                  colors: customColors,
                                  colorsName: customColors,
                                  eventName: $holder.data('event'),
                                  container: lang.container,
                                  tooltip: lang.tooltip
                              }).render());
                          });
                          $dropdown.find('input[type=color]').each(function (idx, item) {
                              $(item).change(function () {
                                  var $chip = $dropdown.find('#' + $(this).data('event')).find('.note-color-btn').first();
                                  var color = this.value.toUpperCase();
                                  $chip.css('background-color', color)
                                      .attr('aria-label', color)
                                      .attr('data-value', color)
                                      .attr('data-original-title', color);
                                  $chip.click();
                              });
                          });
                  },
				  click: function (event) {
                    if(jQuery('.note-table-popover').is(":visible")){
						var range = window.getSelection().getRangeAt(0);
						var ele = range?range.commonAncestorContainer:null;
						if(ele && !ele.closest && ele.parentElement && ele.parentElement.closest){
							ele = ele.parentElement;
							if(ele.tagName == "SPAN"){//for datatable
								ele = ele.parentElement;
							}
						}
						if(ele && ele.closest) {
							//if(ele.closest('table').className == "dataTable no-footer"){
								if(ele.className == 'subHeading'){//uncategorised
									ele.style.backgroundColor = event.target.dataset.value;
								}else {
									if(ele.closest('thead')){
										ele = ele.closest('thead')
									}else {
										ele = ele.closest('tbody');
									}
									if(ele) {
										ele.childNodes.forEach(function(trEle) {
											trEle.style.backgroundColor = event.target.dataset.value;
										});
									}
								}
							/*}else {
								ele.closest('tbody').style.backgroundColor = event.target.dataset.value;
							}*/
						}
					}else {
						jQuery('.note-editable').css('background-color',event.target.dataset.value);
						jQuery("#selectedBackColor").attr('value', event.target.dataset.value);
					}     
                  }
			  })
		  ])
		  return button.render();
	  });
    }
  });
}));