import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { CollaborationDiagram } from './CollaborationDiagram'
import { ProcessDiscoveryDiagramMixin } from './ProcessDiscoveryDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ActivityNetworkDiagram } from './ActivityNetworkDiagram'
import { RoleCollaborationDiagram } from './RoleCollaborationDiagram'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/ProcessDiscoveryDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,ProcessDiscoveryDiagramMixin
, ActivityNetworkDiagram
, RoleCollaborationDiagram){
*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ActivityNetworkDiagram){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ActivityNetworkDiagram){
			ActivityNetworkDiagram = importPath.ActivityNetworkDiagram;
		}
        else {
            import('./ActivityNetworkDiagram').then(({ default: ActivityNetworkDiagram }) => {
                ActivityNetworkDiagram = ActivityNetworkDiagram;
            });
			/*require(["appbo/vdml/ActivityNetworkDiagram"],function(loadedModule){
				ActivityNetworkDiagram = loadedModule;
			});*/
		}
	}
	if(!RoleCollaborationDiagram){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.RoleCollaborationDiagram){
			RoleCollaborationDiagram = importPath.RoleCollaborationDiagram;
		}
        else {
            import('./RoleCollaborationDiagram').then(({ default: RoleCollaborationDiagram }) => {
                RoleCollaborationDiagram = RoleCollaborationDiagram;
            });
			/*require(["appbo/vdml/RoleCollaborationDiagram"],function(loadedModule){
				RoleCollaborationDiagram = loadedModule;
			});*/
		}
	}
	var ProcessDiscoveryDiagram = CollaborationDiagram.extend(utils.customExtends({
		relations:ProcessDiscoveryDiagramMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			CollaborationDiagram.prototype.initialize.apply(this, arguments);
		}
		}
		, new ProcessDiscoveryDiagramMixin()
	));

    ProcessDiscoveryDiagram.getInstance = function (name, description, owner) {
        var eid = DataManager.getDataManager().guidGeneratorByOwner(owner);
        var processMap = new ProcessDiscoveryDiagram({ id: eid, name: owner.getUniqueProperty('name', name, 'diagrams'), description: owner.getUniqueProperty('description', description, 'diagrams'), diagramOwner: owner });
        return processMap;
    }
    var processTitle = "Process Model";
    ProcessDiscoveryDiagram.prototype.getSystemTags = function () {
        return ['VDM', processTitle, 'Prototype'];
    };
	ProcessDiscoveryDiagram.abstract = false;
	ProcessDiscoveryDiagram.supported = true;
	path.ProcessDiscoveryDiagram = ProcessDiscoveryDiagram;
    export { ProcessDiscoveryDiagram };
//});