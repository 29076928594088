import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import {Value} from './Value'
import {ValueMixin} from './ValueMixin'
import {ValueDefinition} from './ValueDefinition'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin","appbo/vdml/Value","appbo/vdml/ValueMixin"],
	function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, VdmlElement, VdmlElementMixin, Value, ValueMixin
, ValueDefinition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ValueDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueDefinition){
			ValueDefinition = importPath.ValueDefinition;
		}
		else{
			import('./ValueDefinition').then(loadedModule => {
				//ValueCategoryViewModel = loadedModule;
			});
			/*require(["appbo/vdml/ValueDefinition"],function(loadedModule){
				ValueDefinition = loadedModule;
			});*/
		}
	}
	export class ValueCategoryMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.ValueCategoryMixin = ValueCategoryMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_ValueCategory"
		}
		return jQuery.extend(Value.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		
		])
	}
	static getCumulativeMixinRelations(){
		if (!ValueCategoryMixin.cummulativeRelations) {
            ValueCategoryMixin.cummulativeRelations = _.union(ValueCategoryMixin.getMixinRelations()
				, ValueMixin.getCumulativeMixinRelations()
            );
        }
		return ValueCategoryMixin.cummulativeRelations.slice();
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{ name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
			{ name: "color", type: "EString", defaultValue: "null", containingClass: "vdml_Value" }
		]
	}
	static getSuperTypes() {
		return [
			"vdml_Value"
		];
	}
	getParent() {
		var container;
		return Value.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path) {
		if (!path) {
			path = [];
		}
		return Value.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ValueCategoryPropertiesTemplate.html",
			templateName : "ValueCategoryPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ValueCategoryViewModel",
			tabId : "ValueCategoryView",
			tabName: "ValueCategory"
		}
	}
	
	}
	path.ValueCategoryMixin = ValueCategoryMixin;
	//return ValueCategoryMixin;
//});