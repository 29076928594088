import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { VdmlElement } from './VdmlElement'
import { VdmlElementMixin } from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { BusinessItemDefinition } from './BusinessItemDefinition';
import { PracticeCategory } from './PracticeCategory'
import { CapabilityDefinition } from './CapabilityDefinition'
import { CapabilityMethod } from './CapabilityMethod'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, BusinessItemDefinition
, PracticeCategory
, CapabilityDefinition
, CapabilityMethod){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!BusinessItemDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemDefinition){
			BusinessItemDefinition = importPath.BusinessItemDefinition;
		}
        else {
            import('./BusinessItemDefinition').then(loadedModule => {
            //    BusinessItemDefinition = loadedModule;
            });
			/*require(["appbo/vdml/BusinessItemDefinition"],function(loadedModule){
				BusinessItemDefinition = loadedModule;
			});*/
		}
	}
	if(!PracticeCategory){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PracticeCategory){
			PracticeCategory = importPath.PracticeCategory;
		}
        else {
            import('./PracticeCategory').then(loadedModule => {
                PracticeCategory = loadedModule;
            });
			/*require(["appbo/vdml/PracticeCategory"],function(loadedModule){
				PracticeCategory = loadedModule;
			});*/
		}
	}
	if(!CapabilityDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityDefinition){
			CapabilityDefinition = importPath.CapabilityDefinition;
		}
        else {
            import('./CapabilityDefinition').then(loadedModule => {
                CapabilityDefinition = loadedModule;
            });
			/*require(["appbo/vdml/CapabilityDefinition"],function(loadedModule){
				CapabilityDefinition = loadedModule;
			});*/
		}
	}
	if(!CapabilityMethod){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityMethod){
			CapabilityMethod = importPath.CapabilityMethod;
		}
        else {
            import('./CapabilityMethod').then(loadedModule => {
                CapabilityMethod = loadedModule;
            });
			/*require(["appbo/vdml/CapabilityMethod"],function(loadedModule){
				CapabilityMethod = loadedModule;
			});*/
		}
	}
export class PracticeDefinitionMixin {

    defaults() {
        var ret = {
            type: "vdml_PracticeDefinition"
        }
        return jQuery.extend(VdmlElement.prototype.defaults.apply(this), ret);
    }
    static getMixinRelations() {
        return _.union([
            {
                type: Backbone.HasMany,
                containingClass: "vdml_PracticeDefinition",
                key: "resourceDefinition",
                relatedModel: "vdml.BusinessItemDefinition",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            },
            {
                type: Backbone.HasMany,
                containingClass: "vdml_PracticeDefinition",
                key: "category",
                relatedModel: "vdml.PracticeCategory",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            },
            {
                type: Backbone.HasMany,
                containingClass: "vdml_PracticeDefinition",
                key: "capability",
                relatedModel: "vdml.CapabilityDefinition",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            },
            {
                type: Backbone.HasMany,
                containingClass: "vdml_PracticeDefinition",
                key: "Method",
                relatedModel: "vdml.CapabilityMethod",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            }
        ])
    }
    
    static getCumulativeMixinRelations() {
        if (!PracticeDefinitionMixin.cummulativeRelations) {
            PracticeDefinitionMixin.cummulativeRelations = _.union(PracticeDefinitionMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
        return PracticeDefinitionMixin.cummulativeRelations.slice();
    }
    static getSuperTypes() {
        return [
            "vdml_VdmlElement"
        ];
    }
    static getProperties() {
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "represents", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            //{name : "definition",type : "EString",defaultValue : "null",containingClass : "vdml_PracticeDefinition" }
        ]
    }
    getParent() {
        var container;
        if (!container) {
            container = this.get("practiceDefinitionOwner") ? this.get("practiceDefinitionOwner") : this.previousAttributes().practiceDefinitionOwner;
            if (container) {
                return container;
            }
        }
        return this;
    }
    getPackagePath(path) {
        if (!path) {
            path = [];
        }
        var container;
        if (!container) {
            container = this.get("practiceDefinitionOwner") ? this.get("practiceDefinitionOwner") : this.previousAttributes().practiceDefinitionOwner;
            if (container) {
                path.unshift({ id: this.get('id'), name: this.get('name') })
                return container.getPackagePath(path);
            }
        }
        return path;
    }
    getViewProperties(type) {
        return {
            templatePath: "views/vdml/views/properties/PracticeDefinitionPropertiesTemplate.html",
            templateName: "PracticeDefinitionPropertiesTemplate",
            viewTypeStr: "appviews/vdml/views/properties/PracticeDefinitionViewModel",
            tabId: "PracticeDefinitionView",
            tabName: "PracticeDefinition"
        }
    }
    
}
path.PracticeDefinitionMixin = PracticeDefinitionMixin;
	//return PracticeDefinitionMixin;
//});