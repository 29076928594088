import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as XLSX from 'xlsx'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { ValueDeliveryModel } from '../../../../bo/vdml/ValueDeliveryModel'
import { ValueDeliveryModelMixin } from '../../../../bo/vdml/ValueDeliveryModelMixin'
import { CapabilityDefinition } from '../../../../bo/vdml/CapabilityDefinition'
import { EcoMap } from '../../../../bo/ecomap/EcoMap'
import {CapabilityCategory} from '../../../../bo/vdml/CapabilityCategory'
//define(["require", "jquery", "jstree", "jqueryui", "underscore", "backbone", "async", "knockout", "knockoutMapping", "knockback", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/ValueDeliveryModelMixin", "appbo/vdml/CapabilityCategory", "appbo/vdml/CapabilityDefinition", "appbo/vdml/ValueDeliveryModel", "spin"],
//    function (require, $, jstree, jqueryui, _, Backbone, async, ko, koMapping, kb, bootbox, DataManager, global, ValueDeliveryModelMixin, CapabilityCategory, CapabilityDefinition, ValueDeliveryModel, Spinner) {

	var path = DataManager.getDataManager().buildAppNsPath("tickets", global.version);
	//var importPath;

	export class CapabilityTreeConstructionViewModel {
		constructor(){
			var self=this;
		}
        init(model, options){
            var self = this;
            this.CapabilityTreeConstructionViewModel = this;
            this.BA_Guild_Title = 'ABOUT THE BUSINESS ARCHITECTURE CONTENTS HEREIN';
            this.coverSheet = options.coverSheet;
            this.planModel = options.planModel;
            var wb = options.wb;
            this.fileName = ko.observable();
			this.newPackageId = options.fileObject; 
            this.capabilityTable = ko.observable();
            this.packageName = ko.observable();
            this.encodeId = options.modalId;
            this.filtered = ko.observable(true);
            this.mappingObject = {};
            this.mappingObject['vdml_CapabilityCategory'] = "Capability Category";
			this.mappingObject['vdml_CapabilityDefinition'] = "Capability Definition";
			this.mappingObject['vdml:capabilityCategory'] = "capability Category";
			this.mappingObject['vdml:capabilityDefinition'] = "capability Definition";
			this.mappingObject['vdml_CapabilityLibraryDiagram'] = "Capability Library";
			this.mappingObject['vdml_StrategyMapDiagram'] = "Strategy Map";
			this.mappingObject['vdml_ValueStreamMapDiagram'] = "Value Stream Map";
			this.mappingObject['vdml_BusinessModelCompetence'] = "Business Model Competence";
		    this.mappingObject['vdml_BusinessModelActivity'] = "Business Model Activity";
			this.mappingObject['vdml_BusinessModel'] = "Business Map";
            this.mappingObject['canvas_BMCanvasDiagram'] = "BM Canvas";
            this.mappingObject['vdml_Activity'] = "Activity" ;
            this.mappingObject['vdml_CapabilityOffer'] = "Capability";

            this.extractSheetData = function (sheet) {
                var result = [];
                var row;
                var rowNum;
                var colNum;
                var range = XLSX.utils.decode_range(sheet['!ref']);
                for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
                    row = [];
                    for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
                        var nextCell = sheet[
                            XLSX.utils.encode_cell({
                                r: rowNum,
                                c: colNum
                            })
                        ];
                        if (typeof nextCell === 'undefined') {
                            row.push(void 0);
                        } else row.push(nextCell.w);
                    }
                    result.push(row);
                }
                return result;
            };

            this.flatten = function (object) {
                function iter(o, p) {
                    if (o !== null && typeof o === 'object') {
                        Object.keys(o).forEach(function (k) {
                            iter(o[k], p.concat(k));
                        });
                        return;
                    }
                    path[p.join(' > ')] = o;
                }

                var path = {};
                iter(object, []);
                return path;
            };

            this.filterNewCapabilities = function() {
                if(self.filtered() == false) {
                    self.filtered(true);
                    self.capabilityTable().fnFilter('null');
					$('.changeIcon').text('All Elements');
                } else {
                    self.filtered(false);
                    self.capabilityTable().fnFilter('');
					$('.changeIcon').text('Changes');
                }
            };

            this.createTreeStructure = function (treeObj, treeArray) {
                for (var i = 0; i < treeArray.length; i++) {
                    if (treeArray[i][2] != undefined) {
                        treeArray[i][2] = treeArray[i][2].trim();
                    }
                }
                var tiers = ['Strategic', 'Core', 'Supporting'];
                var column = {
                    'tier': 0,
                    'level': 1,
                    'name': 2,
                    'defn': 3
                };
                var levelLadder = [];

                for (var i = 0; i < tiers.length; i++) {
                    treeObj[tiers[i]] = {
                        "_name": tiers[i],
                        "_desc": tiers[i]
                    };
                }

                for (var index = 2; index < treeArray.length; index++) {
                    var element = treeArray[index];
                    if(element[0] == undefined || element[1] == undefined ||  element[2] == undefined) {
                        continue;
                    }
                    var individualDigits = element[0].split('');
                    for(var i = 0; i < individualDigits.length; i++) {
                        if(Number(individualDigits[i]) != NaN){
                            element[0] = individualDigits[i];
                            break;
                        }
                    }
                    if (element[2] != undefined) {
                        element[2] = element[2].trim();
                        element[2] = element[2].split(' / ').join('/');
                        element[2] = element[2].split('/ ').join('/');
                        element[2] = element[2].split(' /').join('/');

                        if (element[1] == '1') {
                            levelLadder = [];
                            levelLadder.push({
                                "tier": element[0],
                                "level": element[1],
                                "name": element[2],
                                "desc": element[3]
                            });
                            treeObj[tiers[element[0] - 1]][element[2]] = {
                                "_name": element[2],
                                "_desc": element[3]
                            };
                        } else if (element[1] > levelLadder[levelLadder.length - 1].level) {
                            levelLadder.push({
                                "tier": element[0],
                                "level": element[1],
                                "name": element[2],
                                "desc": element[3]
                            });
                            if (levelLadder.length == 2)
                                this.secondLevel(treeObj, levelLadder, element[column.tier] - 1);
                            else if (levelLadder.length == 3)
                                this.thirdLevel(treeObj, levelLadder, element[column.tier] - 1);
                            else if (levelLadder.length == 4)
                                this.fourthLevel(treeObj, levelLadder, element[column.tier] - 1);
                            else if (levelLadder.length == 5)
                                this.fifthLevel(treeObj, levelLadder, element[column.tier] - 1);
                        } else if (levelLadder[levelLadder.length - 1].level != element[column.level] && levelLadder[levelLadder.length - 1].level > element[column.level]) {
                            var levels = [];
                            for (var k = 0; k < levelLadder.length; k++) {
                                if (levelLadder[k].level < element[1])
                                    levels[k] = levelLadder[k];
                            }
                            levelLadder = levels;
                            levelLadder.push({
                                "tier": element[0],
                                "level": element[1],
                                "name": element[2],
                                "desc": element[3]
                            });
                            if (levelLadder.length == 2)
                                this.secondLevel(treeObj, levelLadder, element[column.tier] - 1);
                            else if (levelLadder.length == 3)
                                this.thirdLevel(treeObj, levelLadder, element[column.tier] - 1);
                            else if (levelLadder.length == 4)
                                this.fourthLevel(treeObj, levelLadder, element[column.tier] - 1);
                            else if (levelLadder.length == 5)
                                this.fifthLevel(treeObj, levelLadder, element[column.tier] - 1);
                        } else if (levelLadder[levelLadder.length - 1].level == element[column.level]) {
                            levelLadder[levelLadder.length - 1] = {
                                "tier": element[column.tier],
                                "level": element[column.level],
                                "name": element[column.name],
                                "desc": element[column.defn]
                            };
                            if (levelLadder.length == 2)
                                this.secondLevel(treeObj, levelLadder, element[column.tier] - 1);
                            else if (levelLadder.length == 3)
                                this.thirdLevel(treeObj, levelLadder, element[column.tier] - 1);
                            else if (levelLadder.length == 4)
                                this.fourthLevel(treeObj, levelLadder, element[column.tier] - 1);
                            else if (levelLadder.length == 5)
                                this.fifthLevel(treeObj, levelLadder, element[column.tier] - 1);
                        }
                        if (treeArray[index + 1]) {
                            if (treeArray[index + 1][column.level] > element[column.level] && treeArray[index - 1][column.level] > element[column.level]) {
                                levelLadder[levelLadder.length - 1] = {
                                    "tier": element[column.tier],
                                    "level": element[column.level],
                                    "name": element[column.name],
                                    "desc": element[column.defn]
                                };
                            }

                            if (treeArray[index + 1][column.level] > element[column.level] && treeArray[index - 1][column.level] == element[column.level]) {
                                levelLadder[levelLadder.length - 1] = {
                                    "tier": element[column.tier],
                                    "level": element[column.level],
                                    "name": element[column.name],
                                    "desc": element[column.defn]
                                };
                            }
                        }
                    }
                }
                var newCapPaths = self.flatten(treeObj);
                var capComponents = [];
                var finalCapabilities = [];
                for (var key in newCapPaths) {
                    var tempObj = {};
                    tempObj.path = key.substring(0, key.lastIndexOf(" > "));
                    tempObj.defn = newCapPaths[key];
                    capComponents.push(tempObj);
                }
                for (var i = 1; i < capComponents.length; i = i + 2) {
                    finalCapabilities.push(capComponents[i]);
                }
                return finalCapabilities;
            };

            this.secondLevel = function (treeObj, levelLadder, tier) {
                var tiers = ['Strategic', 'Core', 'Supporting'];
                var name = levelLadder[levelLadder.length - 1].name;
                var desc = levelLadder[levelLadder.length - 1].desc;
                treeObj[tiers[tier]][levelLadder[0].name][name] = {
                    "_name": name,
                    "_desc": desc
                };
            };

            this.thirdLevel = function (treeObj, levelLadder, tier) {
                var tiers = ['Strategic', 'Core', 'Supporting'];
                var name = levelLadder[levelLadder.length - 1].name;
                var desc = levelLadder[levelLadder.length - 1].desc;
                treeObj[tiers[tier]][levelLadder[0].name][levelLadder[1].name][name] = {
                    "_name": name,
                    "_desc": desc
                };
            };

            this.fourthLevel = function (treeObj, levelLadder, tier) {
                var tiers = ['Strategic', 'Core', 'Supporting'];
                var name = levelLadder[levelLadder.length - 1].name;
                var desc = levelLadder[levelLadder.length - 1].desc;
                treeObj[tiers[tier]][levelLadder[0].name][levelLadder[1].name][levelLadder[2].name][name] = {
                    "_name": name,
                    "_desc": desc
                };
            };

            this.fifthLevel = function (treeObj, levelLadder, tier) {
                var tiers = ['Strategic', 'Core', 'Supporting'];
                var name = levelLadder[levelLadder.length - 1].name;
                var desc = levelLadder[levelLadder.length - 1].desc;
                treeObj[tiers[tier]][levelLadder[0].name][levelLadder[1].name][levelLadder[2].name][levelLadder[3].name][name] = {
                    "_name": name,
                    "_desc": desc
                };
            };

            this.createTree = function (treeArray) {
                this.treeObj = {};
                return self.createTreeStructure(this.treeObj, treeArray);
            };
			function checkNodeExisting(existingNodes,newNodes){
			    var node;
				for(var i=0,j=0;i<existingNodes.length || j < newNodes.length;i++,j++){
					if(existingNodes[i] != newNodes[j]){
					    node = newNodes[j];
					}
				}
				return node;
			}
			function newCapPath(capList){
				var path =[];var capCatName = "";var capDefName1 = ""; var capDefName2 = "";
				var isCapCat = [];var iscapDef1 = []; var isCapDef2 = [];
				var capLib = "Panorama360 Functional Definition";
				for(var i=1;i<capList.length;i++){
						var isCapCatExist = true;var isCapDef1Exist = true;var isCapDef2Exist = true;
						if(capList[i][0]){
							capCatName = capList[i][0].replace(/("|')/g, "");
							capCatName = capCatName.replace(/\n|\r/g, "");
							var p =[];
							p.push(capCatName);
							for(var a=0;a<isCapCat.length;a++){
								if(isCapCat[a] == capCatName){
								isCapCatExist = false;
								}
							}
							if((isCapCatExist)){
								path.push({
									"path" : p.join(' > '),
									"defn" : "",
									"id" : null,
									"type" : "vdml_CapabilityCategory"
								})
								isCapCat.push(capCatName);
							}
						}
						if(capList[i][3]){
							capDefName1 = capList[i][3].replace(/("|')/g, "");
							capDefName1 = capDefName1.replace(/\n|\r/g, "");
							var cd = [];
							cd.push(capCatName);
							cd.push(capDefName1);
							for(var b=0;b<iscapDef1.length;b++){
								if(iscapDef1[b] == capDefName1){
								isCapDef1Exist = false;
								}
							}
							if((isCapDef1Exist)){ 
								path.push({
									"path" : cd.join(' > '),
									"defn" : capList[i][5] ? capList[i][5] : "" + "<br>" + "<b>"+capList[0][6]+": </b>" + capList[i][6] + "<br>" + "<b>"+capList[0][7]+": </b>" + capList[i][7],
									"id" : null,
									"type"  : "vdml_CapabilityDefinition"
								})
								iscapDef1.push(capDefName1);
							}
						}
						if(capList[i][8]){ 
							capDefName2 = capList[i][8].replace(/("|')/g, "");
							capDefName2 = capDefName2.replace(/\n|\r/g, "");
							var cd2 = [];
							cd2.push(capCatName);
							cd2.push(capDefName1);
							cd2.push(capDefName2);
							for(var j=0;j<isCapDef2.length;j++){
								if(isCapDef2[j] == capDefName2){
								isCapDef2Exist = false;
								}
							}
							if((isCapDef2Exist)){
								path.push({
									"path" : cd2.join(' > '),
									"defn" : capList[i][10] ? capList[i][10] : "",
									"id" : null,
									"type": "vdml_CapabilityDefinition"
								})
								isCapDef2.push(capDefName2);
							}
						}
				}
				return path;
			}
			function viewCapabilityTree(ExistingCapPaths,newCapPaths){
				var list =[];
				for(var j=0; j<newCapPaths.length;j++){
					var tempList = {
						"existingEntry" : null,
						"newEntry" : newCapPaths[j].path,
						"newDefn": newCapPaths[j].defn,
						"existDefn": null,
						"id":null,
						"newType" :newCapPaths[j].type
					}
					for(var a=0;a<ExistingCapPaths.length;a++){
						if(newCapPaths[j].path == ExistingCapPaths[a].path){
							tempList = {
								"existingEntry" :ExistingCapPaths[a].path,
								"newEntry" : ExistingCapPaths[a].path,
								"newDefn": ExistingCapPaths[a].defn,
								"existDefn": ExistingCapPaths[a].path,
								"id":ExistingCapPaths[a].id,
								"newType" : ExistingCapPaths[a].type,
								"existingType" : ExistingCapPaths[a].type
							}
							break;
						}
					}
					list.push(tempList);
				}
				for(var j=0; j<ExistingCapPaths.length;j++){
					var found = false;
					for(var a=0;a<newCapPaths.length;a++){
						if(newCapPaths[a].path == ExistingCapPaths[j].path){
							found = true;
							break;
						}
					}
					if(!found){
						var tempList = {
							"existingEntry" :ExistingCapPaths[j].path,
							"newEntry" : null,
							"newDefn": null,
							"existDefn": ExistingCapPaths[j].path,
							"id":ExistingCapPaths[j].id,
							"newType" : ExistingCapPaths[j].type,
							"existingType" : ExistingCapPaths[j].type
						}
						list.push(tempList);
					}
				}

				/*for(var i=0,j=0;i<ExistingCapPaths.length || j<newCapPaths.length;i++,j++){
					var existList = false;
				    if(ExistingCapPaths[i] != null && newCapPaths[j] != null && !ExistingCapPaths[i].check){
					    if(ExistingCapPaths[i].path == newCapPaths[j].path){
							var tempList = {
								"existingEntry" : ExistingCapPaths[i].path,
								"newEntry" : newCapPaths[j].path,
								"newDefn": newCapPaths[j].defn,
								"existDefn": ExistingCapPaths[i].defn,
								"id": ExistingCapPaths[i].id,
								"newType" : newCapPaths[j].type,
								"existingType" : ExistingCapPaths[i].type
							}
						}else{
						    var newNode = false;
						    for(var a=0;a<ExistingCapPaths.length;a++){
										if(newCapPaths[j].path == ExistingCapPaths[a].path){
											var newNode = true;
											ExistingCapPaths[a].check = true;
											if(a > j){
												//i--;
											}
											break;
										}
							}
							if(newNode){
							        var tempList = {
											"existingEntry" :ExistingCapPaths[a].path,
											"newEntry" : ExistingCapPaths[a].path,
											"newDefn": ExistingCapPaths[a].defn,
											"existDefn": ExistingCapPaths[a].path,
											"id":ExistingCapPaths[a].id,
											"newType" : ExistingCapPaths[a].type,
											"existingType" : ExistingCapPaths[a].type
									}
							}else{
								    var tempList = {
									"existingEntry" : null,
									"newEntry" : newCapPaths[j].path,
									"newDefn": newCapPaths[j].defn,
									"existDefn": null,
									"id":null,
									"newType" :newCapPaths[j].type
									}
									if(i <= j){
										//i--;
									}
							}
						}
					}else if(ExistingCapPaths[i] == null && newCapPaths[j] != null){
					    var newNode = false;
						for(var a=0;a<ExistingCapPaths.length;a++){
										if(newCapPaths[j].path == ExistingCapPaths[a].path){
											newNode = true;
											break;
										}
						}
						if(newNode){
						    var tempList = {
								"existingEntry" :ExistingCapPaths[a].path,
								"newEntry" : ExistingCapPaths[a].path,
								"newDefn": ExistingCapPaths[a].defn,
								"existDefn": ExistingCapPaths[a].path,
								"id":ExistingCapPaths[a].id,
								"newType" : ExistingCapPaths[a].type,
								"existingType" : ExistingCapPaths[a].type
						    }
						}else{
						    var tempList = {
									"existingEntry" : null,
									"newEntry" : newCapPaths[j].path,
									"newDefn": newCapPaths[j].defn,
									"existDefn": null,
									"id":null,
									"newType" :newCapPaths[j].type
						    }
							if(i > j){
								i--;
							}
						}
					}else if(ExistingCapPaths[i] != null && newCapPaths[j] == null && !ExistingCapPaths[i].check){
					    var tempList = {
									"existingEntry" :ExistingCapPaths[i].path,
									"newEntry" : null,
									"newDefn":null,
									"existDefn": ExistingCapPaths[i].defn,
									"id":ExistingCapPaths[i].id,
									"newType" : null,
									"existingType" : ExistingCapPaths[i].type
						}
					}
					else if(ExistingCapPaths[i].check != undefined){
							j--;
					}
					
					for(var a=0;a<list.length;a++){
					    if((tempList.id != null && list[a].id != null && tempList.id == list[a].id) || tempList == list[a]){
							existList = true; 
							break;
						}
					}
					if(!existList){
					   list.push(tempList);
					}
				}*/
				return list;
			}
			/*function capabilityStructureTree(ExistingCapPaths,newCapPaths){
				var objList = [];
				for (var k = 0, l = 0; k < ExistingCapPaths.length || l < newCapPaths.length;) {
							if (newCapPaths[l] && newCapPaths[l].visited == true) {
								l++;
							} else if (newCapPaths[l] && (newCapPaths[l].path == "Supporting" || newCapPaths[l].path == "Strategic" || newCapPaths[l].path == "Core")) {
								l++;
							} else {
								if (ExistingCapPaths[k] != null && newCapPaths[l] != null) {
									if (ExistingCapPaths[k].path == newCapPaths[l].path) {
										var tempObj = {
											"existingEntry": ExistingCapPaths[k].path,
											"newEntry": newCapPaths[l].path,
											"newDefn": newCapPaths[l].defn,
											"existDefn": ExistingCapPaths[k].defn,
											"id": ExistingCapPaths[k].id,
											"newType" : newCapPaths[l].type
										};
										newCapPaths[l].visited = true;
										k++;
										l++;
									} else if (ExistingCapPaths[k].path != newCapPaths[l].path) {
										if (ExistingCapPaths[k].path != null && newCapPaths[l].path != null) {
										    var newNode;
											var existingNodes = ExistingCapPaths[k].path.split(' > ');
											var newNodes = newCapPaths[l].path.split(' > ');
											if(!wb){
											   var node = checkNodeExisting(existingNodes,newNodes);
											   if(node){
											      newNode = node;
											   }
											}else{
											  newNode = self.getDefnFromJson(existingNodes, self.treeObj);
											}
											if (newNode) {
											    if(!wb){
											       var tempObj = {
													"existingEntry": ExistingCapPaths[k].path,
													"newEntry": newCapPaths[k].path,
													"newDefn": newNode._desc,
													"existDefn": ExistingCapPaths[k].defn,
													"id": ExistingCapPaths[k].id,
													"newType" : newCapPaths[l].type
												   };
											    }else{
												   var tempObj = {
													"existingEntry": ExistingCapPaths[k].path,
													"newEntry": ExistingCapPaths[k].path,
													"newDefn": newNode._desc,
													"existDefn": ExistingCapPaths[k].defn,
													"id": ExistingCapPaths[k].id,
													"newType" : newCapPaths[l].type
												   };
												}
											} else {
												var tempObj = {
													"existingEntry": ExistingCapPaths[k].path,
													"newEntry": null,
													"newDefn": null,
													"existDefn": ExistingCapPaths[k].defn,
													"id": ExistingCapPaths[k].id,
													"newType" : newCapPaths[l].type
												}
											}
											var arrayEle = newCapPaths.find(function (obj) {
												if (obj.path == ExistingCapPaths[k].path) {
													return obj.index;
												}
											});
											if (arrayEle != undefined) {
												newCapPaths[arrayEle.index].visited = true;
											}
											k++;l++;
										}
									} else if (ExistingCapPaths[k].path == undefined && newCapPaths[l].path != undefined) {
										var tempObj = {
											"existingEntry": null,
											"newEntry": newCapPaths[l].path,
											"newDefn": newCapPaths[l].defn,
											"existDefn": null,
											"id": null,
											"newType" : newCapPaths[l].type
										};
										newCapPaths[l].visited = true;
										l++;
										k++;
									} else if (newCapPaths[l].path == undefined) {
										var tempObj = {
											"existingEntry": ExistingCapPaths[k].path,
											"newEntry": null,
											"newDefn": null,
											"existDefn": ExistingCapPaths[k].defn,
											"id": ExistingCapPaths[k].id,
										};
										newCapPaths[l].visited = true;
										l++;
										k++;
									}

								} else if (ExistingCapPaths[k] == null && newCapPaths[l] != null) {
									var tempObj = {
										"existingEntry": null,
										"newEntry": newCapPaths[l].path,
										"newDefn": newCapPaths[l].defn,
										"existDefn": null,
										"id": null,
										"newType" : newCapPaths[l].type,
									};
									newCapPaths[l].visited = true;
									l++;
								} else if (ExistingCapPaths[k] != null && newCapPaths[l] == null) {
									var tempObj = {
										"existingEntry": ExistingCapPaths[k].path,
										"newEntry": null,
										"newDefn": null,
										"existDefn": ExistingCapPaths[k].defn,
										"id": ExistingCapPaths[k].id,
									};
									k++;
								}
								objList.push(tempObj);
							}
				}
				return objList;
			}*/
			this.createPanoramaTree = function(sheet){
				var titleName = [{_name: "Panorama360", _desc : "Panorama360"}];
			    titleName[0].children = [];
				for(var i=1;i<sheet.length;i++){
					 var isCapExist1 = false;
					 for(var j=0;j<titleName[0].children.length;j++){
					 	 if(titleName[0].children[j]._name === sheet[i][0]){
						     isCapExist1 = true;
						 }
					 }
					 if(!isCapExist1){
					 	 titleName[0].children.push({_name : sheet[i][0], _desc : ""});
					 }
					 var isCapExist2 = false;
					 for(var a=0;a<titleName[0].children.length;a++){
					 	 if(!titleName[0].children[a].children){
						 	 titleName[0].children[a].children = [];
						 }else{
							 for(var b=0;b<titleName[0].children[a].children.length;b++){
								 if(titleName[0].children[a].children[b]._name === sheet[i][3]){
							 		 isCapExist2 = true;
								 }
							 }
						 }
						 if(!isCapExist2 && titleName[0].children[a]._name === sheet[i][0]){
							titleName[0].children[a].children.push({_name: sheet[i][3], _desc : sheet[i][5]});
						 }
						 var child = titleName[0].children[a].children;
						 for(var k=0;k<child.length;k++){
						 		if(!child[k].children){
									child[k].children = [];
									child[k].children.push({_name : sheet[i][8], _desc : sheet[i][10]})
								}else{
									if(child[k]._name == sheet[i][3]){
										child[k].children.push({_name : sheet[i][8], _desc : sheet[i][10]})
									}
								}
						 }
					 }
				}
				return titleName[0];
			}
            this.loadLibraryPackages = function (sheet) {
                DataManager.getDataManager().set('currentPlan', self.planModel);
                //self.currentPlan(DataManager.getDataManager().get('currentPlan'));
                var planId = self.planModel.get('id');
                var commonAltId = planId + "-Common@";
                DataManager.getDataManager().getAllDocumentsCollectionOfType(commonAltId, 'vdml_ValueDeliveryModel', function (result) {
                    var libFound = false;var title ="";
                    if (result && result.length > 0) {
                        _.each(result.models, function (pack) {
                            if (pack.get('id').indexOf("Common") > -1) {
                                if (pack.get('id').indexOf('CommonPackage') === -1) {
								  if(wb){
									  var capabilityLib = wb.Sheets['Capability Map'] ? wb.Sheets['Capability Map']:wb.Sheets['Capability Template'];
									  if(!capabilityLib){
											capabilityLib = wb.Sheets['Functional Definition'];
									  }
								  }
								  if(wb && capabilityLib){
								    var range = XLSX.utils.decode_range(sheet['!ref']);
                                    for (var rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
                                        var nextCell = sheet[XLSX.utils.encode_cell({
                                            r: rowNum,
                                            c: 0
                                        })];
                                        if (nextCell != undefined) {
                                            nextCell = nextCell.h;
                                        }
                                        if (nextCell === self.BA_Guild_Title) {
                                            self.fileName(sheet[XLSX.utils.encode_cell({
                                                r: rowNum - 1,
                                                c: 0
                                            })].h);
                                            title = sheet[XLSX.utils.encode_cell({
                                                r: rowNum - 1,
                                                c: 0
                                            })].h + ' Package';
                                        }
										if(nextCell === "Business Domain"){
											title = "Panorama360";
										}
                                    }
									if(!title) {
										var sheetName = sheet[XLSX.utils.encode_cell({ r: 0, c: 0})]
										if(sheetName && sheetName.h){
											title = sheetName.h + ' Package';
										}
									}
                                    var repId = DataManager.getDataManager().getRepositoryId(pack.get('id'));
                                    var vdmStore = DataManager.getDataManager().getVDMStore(repId);
                                        
                                    DataManager.getDataManager().fetchDocumentFromPackage(pack.get('id'), 'vdml_ValueDeliveryModel', pack.get('version'), pack.get('id'), 'vdml_ValueDeliveryModel', vdmStore, {
                                        success: function (model) {
                                            var capLibs  = model.get('capabilityLibrary');
											var isExist = false;var selectedCapLib;
											for(var cap=0;cap<capLibs.length;cap++){
												if(capLibs.at(cap).get('id') === self.newPackageId){
													isExist = true;
													selectedCapLib = capLibs.at(cap);
													self.fileName(capLibs.at(cap).get('name'));
													if(self.fileName().includes('Panorama360')){
														self.fileName("Panorama360");
													}
													break;
												}
											}
											if(((pack.get('name') == title || pack.get('name') == title + " Functional Definition Package") && !self.newPackageId) || (self.newPackageId && isExist)){
													self.packageName(pack);
													libFound = true;
													var capLib = model.get('capabilityLibrary');
													var ExistingCapPaths = [];
													var htmlContent = "<table id=\'CapabilitiesUpdateTable\' border=\'2\' style=\'overflow: scroll;\' class = \'updateCapLib\'><thead><tr> <th>Existing</th> <th>New</th> <th>References</th> </tr></thead><tbody>";
													//for (var j = 0; j < capLib.length ; j++) {
														//var capDep = capLib.at(j);
														var capList = [];
                                                        if(selectedCapLib){
                                                            capList = selectedCapLib.get('capability');
                                                        }
														
															for (var i = 0; i < capList.length; i++) {
																var p = [];
																var tempModel = capList.models[i];
																p.push(tempModel.get('name'));
																var definition = tempModel.get('definition');
																var modelId = tempModel.get('id');
																while (tempModel.get('parentCapability').length != 0) {
																	tempModel = tempModel.get('parentCapability').models[0];
																	p.push(tempModel.get('name'));
																}
																//p.push(capDep.get('name'))
																p.reverse();
																ExistingCapPaths.push({
																	"path": p.join(' > '),
																	"defn": definition,
																	"id": modelId
																});
															}
															var capabilityLibData = self.extractSheetData(capabilityLib);
															var objList;
															if(wb.Sheets['Functional Definition']){
																var newCapPaths = newCapPath(capabilityLibData);
																var capJSON = self.createPanoramaTree(capabilityLibData)
															}
															else{
																var newCapPaths = self.createTree(capabilityLibData);
																var capJSON = self.treeObj;
															}
															objList = viewCapabilityTree(ExistingCapPaths,newCapPaths);
															for (var i = 0; i < objList.length; i++) {
																if(objList[i].newEntry != null && objList[i].newDefn != null) {
																	htmlContent += "<tr data-existing=\'" + objList[i].existingEntry + "\' data-new=\'" + objList[i].newEntry + "\' data-newDefn=\'" + objList[i].newDefn + "\' data-existDefn=\'" + objList[i].existDefn + "\' data-id=\'" + objList[i].id + "\' data-newType=\'" + objList[i].newType + "\'> <td>" + objList[i].existingEntry + "</td> <td style='cursor: pointer;' class=\'new\'><a>" + objList[i].newEntry + "</a></td> <td></td> </tr>";
																} else {
																	htmlContent += "<tr data-existing=\'" + objList[i].existingEntry + "\' data-new=\'" + objList[i].newEntry + "\' data-newDefn=\'" + objList[i].newDefn + "\' data-existDefn=\'" + objList[i].existDefn + "\' data-id=\'" + objList[i].id + "\' data-newType=\'" + objList[i].newType + "\'> <td>" + objList[i].existingEntry + "</td> <td style='cursor: pointer;' class=\'new\'><a>" + objList[i].newEntry + "</a></td> <td style='cursor: pointer;' class=\'ref\'><a> References </a></td> </tr>";
																}
															}
															htmlContent += "</tbody></table>";
															var tableElement = document.createElement('div');
													        tableElement.innerHTML = htmlContent;
													        var tableParent = $('#capabilityTable');
													        tableParent[0].appendChild(tableElement);
													        self.capabilityTable($('#CapabilitiesUpdateTable').dataTable());
													    
													//}
													
													$('#CapabilitiesUpdateTable tbody').on('click', 'td.new', function () {
														if ($(this).hasClass('selected')) {
															$(this).removeClass('selected');
														} else {
															self.capabilityTable().$('td.selected').removeClass('selected');
															$(this).addClass('selected');
															var id = window.utils.htmlEscape(window.guidGenerator());
															var options = {
																capabilityJSON: capJSON,
																path: this.parentElement.dataset.new,
																callback: self.updatePath
															};
															window.getAndCreateModalDialog(false, id, ValueDeliveryModelMixin, null, "UpdateCapabilityTree", null, options);
														}
													});
													$('#CapabilitiesUpdateTable tbody').on('click', 'td.ref', function () {
														var parent = this.parentElement;
														self.onClickReferenceLabel(parent);
													});
													self.capabilityTable().fnFilter('null');
											}	
										},
                                        error: function (error) {
                                            console.log('Unable to load selected Package');
                                            packHandled();
                                        }
                                    }, true, null);
                                  }else if(!wb){
								      var capPacks = sheet;
								  	  if (pack.get('name') == capPacks[0].name) {
									     var repId = DataManager.getDataManager().getRepositoryId(pack.get('id'));
                                         var vdmStore = DataManager.getDataManager().getVDMStore(repId);
										 self.packageName(pack);
									     DataManager.getDataManager().fetchDocumentFromPackage(pack.get('id'), 'vdml_ValueDeliveryModel', pack.get('version'), pack.get('id'), 'vdml_ValueDeliveryModel', vdmStore, {
										    success: function (model) {
											    libFound = true;
										        htmlContent = "<table id=\'CapabilityLibraryUpdate\' border=\'2\' style=\'overflow: scroll;\' class = \'CapLibraryUpdate\'><thead><tr> <th>Existing</th> <th>New</th> <th>References</th></tr></thead><tbody>";
										        var ExistingCapPaths = capTree(model);
										        var newCapPath = newCapTree(capPacks[0]);
												function capTree(model){
													var path =[]; 
													var capLib = model.get('capabilityLibrary');
													for(var i=0;i<capLib.models.length;i++){
														var capabilities = capLib.models[i].get('capability');
														for(var j=0;j<capabilities.length;j++){
																	var p = [];
																	var tempModel = capabilities.models[j];
																	var type = tempModel.get('type');
																	var definition = tempModel.get('definition');
																	p.push(tempModel.get('name'));
																	var modelId = tempModel.get('id');
																	while (tempModel.get('parentCapability').length != 0) {
																		tempModel = tempModel.get('parentCapability').models[0];
																		p.push(tempModel.get('name'));
																	}
																	p.push(capLib.models[i].get('name'));
																	p.reverse();
																	path.push({
																		"path": p.join(' > '),
																		"defn": definition,
																		"id": modelId,
																		"type" : type
																	});
														}
													}
													return path;
												}
												function newCapTree(model){
													var path =[];
													var capLib = model.capabilityLibrary;
													for(var i=0;i<capLib.length;i++){
														var capabilities = capLib[i].capability;
														for(var j=0;j<capabilities.length;j++){
																	var p = [];
																	var tempModel = capabilities[j];
																	p.push(tempModel.name);
																	var modelId = tempModel.id;
																	var type = tempModel.type;
															        var definition = tempModel.definition;    
																	while (tempModel.parentCapability) {
																		var parent = _.filter(capabilities,function(pack){if(pack.id === tempModel.parentCapability[0]){return pack.name; }});
																		tempModel = parent[0];
																		p.push(parent[0].name);
																	}
																	p.push(capLib[i].name);
																	p.reverse();
																	path.push({
																		"path": p.join(' > '),
																		"id": modelId,
																		"defn":definition,
																		"type" : type
																	});
														}
													}
													return path;
												}
												var objList = viewCapabilityTree(ExistingCapPaths,newCapPath);
												
												for (var i = 0; i < objList.length; i++) {
													if(objList[i].newEntry != null) {
														htmlContent += "<tr data-existing=\'" + objList[i].existingEntry + "\' data-new=\'" + objList[i].newEntry + "\' data-id=\'" + objList[i].id + "\' data-newDefn=\'" + objList[i].newDefn + "\' data-existDefn=\'" + objList[i].existDefn + "\' data-existType=\'" + objList[i].existType + "\' data-newType=\'" + objList[i].newType + "\'> <td>" + objList[i].existingEntry + "</td> <td style='cursor: pointer;' class=\'new\'><a>" + objList[i].newEntry + "</a></td><td></td></tr>";
													} else {
														htmlContent += "<tr data-existing=\'" + objList[i].existingEntry + "\' data-new=\'" + objList[i].newEntry + "\' data-id=\'" + objList[i].id + "\' data-newDefn=\'" + objList[i].newDefn + "\' data-existDefn=\'" + objList[i].existDefn + "\' data-existType=\'" + objList[i].existType + "\' data-newType=\'" + objList[i].newType + "\'> <td>" + objList[i].existingEntry + "</td> <td style='cursor: pointer;' class=\'new\'><a>" + objList[i].newEntry + "</a></td> <td style='cursor: pointer;' class=\'ref\'><a> References </a></td></tr>";
													}
												}
												htmlContent += "</tbody></table>";
												var tableElement = document.createElement('div');
                                                tableElement.innerHTML = htmlContent;
                                                var tableParent = $('#capabilityTable');
                                                tableParent[0].appendChild(tableElement);
                                                self.capabilityTable($('#CapabilityLibraryUpdate').dataTable());

												$('#CapabilityLibraryUpdate tbody').on('click', 'td.ref', function () {
												    var parent = this.parentElement;
                                                    self.onClickReferenceLabel(parent);
                                                });
												self.capabilityTable().fnFilter('null');
											}
										 });
									  }
								  }
								}
                            }
                        });
                    }
                    if (!libFound) {
                        //libraryDetails(coverSheet);
                        DataManager.getDataManager().set('currentPlan', null);
                    }
                }, false, null, true);
            };
			this.onClickReferenceLabel = function(parent){
				if($(this).hasClass('selected')) {
					$(this).removeClass('selected');
				} else {
					self.capabilityTable().$('td.selected').removeClass('selected');
					$(this).addClass('selected');
					var modelNode = self.getCapabilityElementModel(parent.dataset.id);
					DataManager.getDataManager().getReferencesToModel2(modelNode, function(data){
						var htmlContent = "<table class=\"table table-striped table-bordered\"> <tr> <th>Name</th> <th>Type</th> <th>Path</th> </tr>"
						for(var i = 0; i < data.length; i++) {
							if(data[i].predicate.indexOf("Capability-childCapability") != -1 || data[i].predicate.indexOf("CapabilityLibrary-capability") != -1 || data[i].predicate.indexOf("Capability-parentCapability") != -1 || data[i].predicate.indexOf("vdml_CapabilityMethod-capability") != -1) {
								continue;
							} else {
								var altId = DataManager.getDataManager().getRepositoryId(data[i].id);
								var alternative =  Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: altId });
								if(alternative) {
									var alternativeName = alternative.get('name');
									var phaseName = alternative.get('phaseAlternativeOwner').get('name');
									data[i].namePath = phaseName + "/" + alternativeName + "/" + data[i].namePath;
								}
								var type = data[i].type.substring(data[i].type.lastIndexOf('_') + 1);
								type = type.split(/(?=[A-Z])/).join(' ');
								htmlContent += "<tr><td>"+ data[i].name +"</td><td>"+ self.mappingObject[data[i].type] +"</td><td>" + data[i].namePath + "</td></tr>";
							}
						}
						htmlContent += "</table>";
						bootbox.dialog({
							title: "<div>&nbsp;&nbsp;References</div>",
							message: htmlContent,
							className: "showReferenceTable resizeBox"
						});
					}, true);
				}
			}
            this.getCapabilityElementModel = function (id) {
				var capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityCategory').find({ id: id });
				if (!capModel) {
					capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityDefinition').find({ id: id });
				}
				return capModel;
		    }

            this.getDefnFromJson = function (nodes, treeObj) {
                var jsonData;
                var tree = treeObj[nodes[0]];
                for (var i = 1; i < nodes.length; i++) {
                    if (tree && tree[nodes[i]]) {
                        tree = tree[nodes[i]];
                    } else {
                        return;
                    }
                }
                return tree;
            }

            this.updatePath = function (path, definition) {
                $('#CapabilitiesUpdateTable .selected')[0].innerText = path;
                $('#CapabilitiesUpdateTable .selected')[0].parentElement.dataset.new = path;
                $('#CapabilitiesUpdateTable .selected')[0].parentElement.dataset.newdefn = definition;
                $('#CapabilitiesUpdateTable .selected')[0].classList.remove('selected');
            }

            this.deleteChildren = function (model) {
                var children = model.get('childCapability');
                if (children) {
                    var numberOfChildren = children.length - 1;
                    while (numberOfChildren >= 0) {
                        var child = children.models[numberOfChildren];
                        self.deleteChildren(child);
                        children.models[numberOfChildren].destroy();
                        numberOfChildren--;
                    }
                }
            }

            this.DeleteOrReplaceChildren = function (existingEle, nodeToBeReplaced) {
                var existingChildren = existingEle.get('childCapability');
                var childrenToBeReplaced = nodeToBeReplaced.get('childCapability');
                var existingModels = existingChildren.length - 1;

                while (existingModels >= 0) {
                    var unique = true;
                    var replaceModels = childrenToBeReplaced.length - 1;
                    while (replaceModels >= 0) {
                        if (existingChildren.models[existingModels] == childrenToBeReplaced.models[replaceModels]) {
                            continue;
                        } else {
                            unique = false;
                        }
                        replaceModels--;
                    }
                    if (!unique) {
                        self.deleteChildren(existingChildren.models[existingModels]);
                        existingChildren.models[existingModels].destroy();
                    }
                    existingModels--;
                }
                for (var key in childrenToBeReplaced.models) {
                    existingEle.get('childCapability').add(childrenToBeReplaced.models[key]);
                    childrenToBeReplaced.models[key].get('parentCapability').add(existingEle);
                }
            }

            this.updateAllCapabilities = function () {
                var rowData = self.capabilityTable().fnGetNodes();
				window.utils.startSpinner('update', "Updating Capabilities ... ");
				setTimeout(function(){
                var repId = DataManager.getDataManager().getRepositoryId(self.packageName().get('id'));
                var vdmStore = DataManager.getDataManager().getVDMStore(repId);
                self.packageName(self.packageName());
                DataManager.getDataManager().fetchDocumentFromPackage(self.packageName().get('id'), 'vdml_ValueDeliveryModel', self.packageName().get('version'), self.packageName().get('id'), 'vdml_ValueDeliveryModel', vdmStore, {
                    success: function (model) {
                        var capLib = model.get('capabilityLibrary');
                        
                        for (var j = 0; j < capLib.length ; j++) {
                            if(capLib.at(j).get('id') === self.newPackageId){
							    var cl = capLib.at(j);
                                var capabilityModels = cl.get('capability').models;
								for (var i = 0; i < rowData.length; i++) {
									var existingPath = rowData[i].dataset.existing;
									var newPath = rowData[i].dataset.new;
									var existingDefn = rowData[i].dataset.existdefn; //defn or Defn?
									var newDefn = rowData[i].dataset.newdefn;
									var newType = rowData[i].dataset.newtype;
									if (newPath == "null") { //deletion
										var existingId = rowData[i].dataset.id;var isDestroy=false;
										var existingEle = cl.get('capability').findWhere({id: existingId});
										if (existingEle) {
											var parentModel = existingEle.get('parentCapability').models[0] ? existingEle.get('parentCapability').models[0] : "";
											var existingComponent = rowData[i].dataset.existing.split(' > ');
											if(parentModel){
													for(var a=1;a<existingComponent.length;a++){
														if(existingComponent[a] === existingEle.get('name')){
														  var checkElement = true;
														  break;
														}
													}
													if(checkElement){
														while(parentModel.get('parentCapability').length != 0){
																parentModel = parentModel.get('parentCapability').models[0];
														}
														for(var a=0;a<existingComponent.length;a++){
															if(existingComponent[a] === parentModel.get('name')){
																var check = true;
																break;
															}
														}
														if(check){
															isDestroy = true;
														}
													}
											}
											if(isDestroy || !parentModel){
											   existingEle.destroy();
											} 
										}
									}
								}

								for (var i = 0; i < rowData.length; i++) {
									var existingPath = rowData[i].dataset.existing;
									var newPath = rowData[i].dataset.new;
									var existingDefn = rowData[i].dataset.existdefn; //defn or Defn?
									var newDefn = rowData[i].dataset.newdefn;
									var newType = rowData[i].dataset.newtype;
									if (newPath.indexOf('>') != -1) {
										var existingComponents = existingPath.split(' > ');
										var newComponents = newPath.split(' > ');

										if (existingPath == "null") { //creation
											var parent = undefined;
											var node;
											var k = 1;
											if(wb){
												k = 0;
											}
											for (k; k < newComponents.length; k++) {
												if (k < newComponents.length) {
													var node = cl.get('capability').findWhere({
														name: newComponents[k]
													});
												}
												if (!node) {
													if(!rowData[i].dataset.newtype || rowData[i].dataset.newtype == 'vdml_CapabilityDefinition'){
														node = self.createNewDefinitionNode(cl, newComponents[k], newDefn, parent)
													}else{
													  node = self.createNewNode(cl, newComponents[k], newDefn, parent);
													}
													if(node && rowData[i].dataset.newtype && rowData[i].dataset.newtype != "undefined" && (rowData[i].dataset.newtype && node.get('type') != rowData[i].dataset.newtype)){
													   node.set('type', rowData[i].dataset.newtype);
													}
												} else {
												   self.assignParent(cl, node, parent);
												}
												parent = node;
											}
										} else { //updation
											var existingId = rowData[i].dataset.id;
											var existingEle = cl.get('capability').findWhere({
												id: existingId
											}); //{name: existingComponents[existingComponents.length-1]}
											var existingParent; // = existingComponents[0];    //supp,or core etc backbone model
											for (var j = 0; j < newComponents.length; j++) { //start from supp,core 
												if (j == newComponents.length - 1) {
													if (existingEle == null) {
														continue;
													}
													if (existingEle.get('name') != newComponents[newComponents.length - 1]) {
														if(!wb){
															var nodeToBeReplaced = _.filter(cl.get('capability').models,function(pack){if(pack.get('name') == existingEle.get('name'))return pack});
															nodeToBeReplaced[0].set('name',newComponents[newComponents.length - 1]);nodeToBeReplaced[0].set('description',newComponents[newComponents.length - 1]);
														}else{
														   var nodeToBeReplaced = cl.get('capability').findWhere({
																name: newComponents[0]
														   });
														   if(nodeToBeReplaced){
																   for (var newComp = 1; newComp < newComponents.length; newComp++) {
																	  nodeToBeReplaced = nodeToBeReplaced.get('childCapability').findWhere({
																		 name: newComponents[newComp]
																	  });
																   }
																   self.DeleteOrReplaceChildren(existingEle, nodeToBeReplaced);
																   nodeToBeReplaced.destroy();
														   }
 														}
														existingEle.set('name', newComponents[j]);
														existingEle.set('description', newComponents[j]);
														existingEle.set('definition', newDefn);
														break;
													}
													/*if(!wb){
													   if(existingEle && existingEle.get('type') != newType){
														 var nodeToBeReplaced = _.filter(cl.get('capability').models,function(pack){if(pack.get('name') == existingEle.get('name'))return pack});
														 nodeToBeReplaced[0].set('type', newType);
														}
													}*/
												}
											}
											if (existingEle && existingEle.get('definition') != newDefn) {
												existingEle.set('definition', newDefn);
											}
											//var newParent = existingComponents[existingComponents.length-2];       //already exists 
											// var newNode = newComponents[newComponents.length - 1];//cl.get('capability').findWhere({name: newComponents[newComponents.length - 1]})
											//self.assignParent(cl, newNode, newParent); 
											//var nodeToBeReplaced = cl.get('capability').findWhere({name: existingParent});

										}
									}
								}
							}
                        }
                        window.utils.stopSpinner('update');
						if(wb){
                           var showValStream = self.updateValueStreamsBootbox();
						   bootbox.alert("Capabilities have been updated.");
						   if(!showValStream){
							  DataManager.getDataManager().set('currentPlan', null);
							  self.cleanModalDialog();
						   }
                        }else{
						   bootbox.alert("Capabilities have been updated.");
						   DataManager.getDataManager().set('currentPlan', null);
                           self.cleanModalDialog();
						}
					},
                    error: function (error) {
                        console.log('Unable to load selected Package');
                        packHandled();
                        self.cleanModalDialog();
                    }
                }, true, null);
				},300);
            }

            this.createNewNode = function (cl, nodeName, nodeDefn, parent) {
                var capId = DataManager.getDataManager().guidGeneratorByOwner(cl);
                var capGroup = new CapabilityCategory({
                    id: capId,
                    name: nodeName,
                    description: nodeName,
                    definition: nodeDefn,
                    capabilityOwner: cl
                });
                self.assignParent(cl, capGroup, parent);
                return capGroup;
            }
			this.createNewDefinitionNode = function (cl, nodeName, nodeDefn, parent) {
                var capId = DataManager.getDataManager().guidGeneratorByOwner(cl);
                var capGroup = new CapabilityDefinition({
                    id: capId,
                    name: nodeName,
                    description: nodeName,
                    definition: nodeDefn,
                    capabilityOwner: cl
                });
                self.assignParent(cl, capGroup, parent);
                return capGroup;
            }
            this.assignParent = function (cl, capGroup, parent) {
                if (parent && capGroup) {
                    parent.get('childCapability').add(capGroup);
                    capGroup.get('parentCapability').add(parent);
                }

                //capGroup.get('parentCapability').add(parent);
            }

            this.updateValueStreamsBootbox = function () {
				var showValStreamBox = false;
                var valueStreamArray= [];
				var valueStreamInventory = wb.Sheets["Value Streams Inventory"] || wb.Sheets["Value Stream Inventory"];
				if(valueStreamInventory){
					var valueStreamInventoryData = ValueDeliveryModel.extractSheetData(valueStreamInventory);
					for (var i = 0; i < valueStreamInventoryData.length; i++) { //trimming definitions
						if (valueStreamInventoryData[i][1] != undefined && valueStreamInventoryData[i][0] != "Value Stream Name") {
							valueStreamInventoryData[i][0] = valueStreamInventoryData[i][0].trim();
							if(valueStreamInventoryData[i][0].includes("'") || valueStreamInventoryData[i][0].includes("!A")){
								var arr = valueStreamInventoryData[i][0].split("'");
								valueStreamArray.push(arr[1] ? arr[1].trim() : "");
							}
						}
					}
				}
				else if(!valueStreamInventory){
					valueStreamInventory = wb.Sheets["Value Streams"];
					valueStreamInventoryData = ValueDeliveryModel.extractSheetData(valueStreamInventory);
					for(var vs=6;vs<valueStreamInventoryData[2].length;vs++){
						valueStreamArray.push(valueStreamInventoryData[2][vs]);
					}
				}
                var valueStreamInventoryData = self.extractSheetData(valueStreamInventory);
                var phases = self.planModel.get('phase').length;
                if (phases >= 1 && valueStreamArray.length > 0) {
					showValStreamBox = true;
                    var htmlContent = "<select class=\"form-control\" id=\"selectValueStreamPhase\">";
                    htmlContent = htmlContent + "<option>Select Phase ...</option>";
                    for (var i = 0; i < phases; i++) {
                        htmlContent = htmlContent + "<option phaseId='" + self.planModel.get('phase').at(i).attributes.id + "'>" + self.planModel.get('phase').at(i).attributes.name + "</option>";
                    }
                    htmlContent = htmlContent + "</select><p/>";

                    htmlContent = htmlContent + "<select class=\"form-control\" id=\"selectAlternative\">";
                    htmlContent = htmlContent + "<option>Select Alternative ...</option>";
                    htmlContent = htmlContent + "</select><p/>";
					
					htmlContent += "<input type = 'checkbox' id = 'checkAllVS'><b> List of Value Stream(s)</b><br/>";
					valueStreamArray = valueStreamArray.sort();
					for(var value =0;value<valueStreamArray.length;value++){
						htmlContent +=  "<input type='checkbox' class = 'listOfValuestreams' value = \"" + valueStreamArray[value] + "\">" + " " + valueStreamArray[value] +'</br>';
					}
					

                    var dialog = bootbox.dialog({
                        title: "<div>&nbsp;&nbsp;Do you want to update Value Streams in ? </div>",
                        message: htmlContent,
                        buttons: {
                            main: {
                                label: "Close",
                                className: "btn btn-default",
								callback: function () {
								  DataManager.getDataManager().set('currentPlan', null);
							      self.cleanModalDialog();
								}
                            },
                            success: {
                                label: "Complete",
                                className: "btn btn-complete complete-btn disabled",
                                callback: function () {
                                    var alternativeId = $('#selectAlternative option:selected').attr('altId');
                                    if (alternativeId !== undefined) {
                                        window.utils.startSpinner('updateValueStream', "updating Value Streams ... ");
										$('#modal' + window.utils.htmlEscape(self.planModel.id)).hide();
										var valueStreamsList =[];
										for(var list =0;list<$('.listOfValuestreams').length;list++){
											if($('.listOfValuestreams')[list].checked){
											valueStreamsList.push($('.listOfValuestreams')[list].value)
											}
										}
                                        checkPackages(valueStreamsList,alternativeId, valueStreamInventoryData, function () {
                                            /*DataManager.getDataManager().get('router').navigate("Plan", {
                                                trigger: true
                                            });*/
                                            bootbox.alert(DataManager.getDataManager().get('localeManager').get('ImportComplete', 'Value Streams'));
                                            window.utils.stopSpinner('updateValueStream');
                                            $('#modal' + window.utils.htmlEscape(self.planModel.id)).show();
                                        });
                                    }
                                }
                            }
                        }
                    });
					dialog.attr("id", "bootboxForVS_id");
					$('#bootboxForVS_id').find('.bootbox-body').css('height','500px');
					$('#bootboxForVS_id').find('.bootbox-body').css('overflow-y','scroll');
                }

                function fillAlternatives() {
                    $("#selectValueStreamPhase option:selected").each(function (phase) {
                        var phases = self.planModel.get('phase');
                        var phaseId = $("#selectValueStreamPhase option:selected").attr('phaseId');
                        for (var i = 0; i < phases.length; i++) {
                            if (phases.at(i).id === phaseId) {
                                self.selectedPhase = phases.at(i);
                                break;
                            }
                        }
                        if (self.selectedPhase) {
                            $('#selectAlternative').empty();
                            $('#selectAlternative').append("<option selected='true'>Select Alternative ...</option>");
                            var alts = self.selectedPhase.get('phaseAlternative');
                            alts.each(function (alt) {
                                $('#selectAlternative').append("<option altId='" + alt.id + "'>" + alt.get('name') + "</option>");
                            });
                        }
                    });
                }
				if(showValStreamBox){
					$('input[id="checkAllVS"]').change(function(event,checked) {
						var vsList = $('.listOfValuestreams');
						for(var i=0;i<vsList.length;i++){
						  vsList[i].checked = event.currentTarget.checked;
						}
					})
					$('#selectValueStreamPhase').change(function () {
						fillAlternatives();
						if ($(this).children('option:first-child').is(':selected')) {
							$('#selectAlternative').empty();
							$('#selectAlternative').append("<option selected='true'>Select Alternative ...</option>");
						}
						$(".complete-btn").prop('disabled', true);
					});
					$('#selectAlternative').change(function () {
						if ($("#selectAlternative option:selected").attr('altid') != undefined) {
							$(".complete-btn").prop('disabled', false);
							$(".complete-btn").removeClass("disabled");
						} else {
							$(".complete-btn").prop('disabled', true);
							$(".complete-btn").addClass("disabled");
						}
					});
					fillAlternatives();
				}
				return showValStreamBox;
            }

            function checkPackages(valueStreamArray,alternativeId, valueStreamInventoryData) {
			    $('#CapabilityTreeConstructionView' + self.encodeId).hide();
                var alternative = DataManager.getDataManager().getAlternativeSync(alternativeId);
                var alterModels = alternative.get('phaseDesignPart').models;
                var modelLength = alternative.get('phaseDesignPart').models.length;
                var update = false;
                var modelIndex = 0;
                for (; modelIndex < modelLength; modelIndex++) {
                    if (alterModels[modelIndex].get('name') === self.fileName() + " Value Streams") {
                        update = true;
                        break;
                    }
                }
                if (update) {
                    self.createOrUpdateValueStreams(valueStreamArray,alternativeId, valueStreamInventoryData, update, alterModels[modelIndex], function () {
                        /*DataManager.getDataManager().get('router').navigate("Plan", {
                            trigger: true
                        });*/
                        bootbox.alert('Value Streams have been updated');
                        window.utils.stopSpinner('updateValueStream');
                        $('#modal' + window.utils.htmlEscape(self.planModel.id)).show();
                        self.cleanModalDialog();
                    });
                } else {
                    self.createOrUpdateValueStreams(valueStreamArray,alternativeId, valueStreamInventoryData, update, null, function () {
                        /*DataManager.getDataManager().get('router').navigate("Plan", {
                            trigger: true
                        });*/
                        bootbox.alert(DataManager.getDataManager().get('localeManager').get('ImportComplete', 'Value Streams'));
                        window.utils.stopSpinner('updateValueStream');
                        $('#modal' + window.utils.htmlEscape(self.planModel.id)).show();
                        self.cleanModalDialog();
                    });
                }
            }

            this.createOrUpdateValueStreams = function (valueStreamList,alternativeId, valueStreamInventoryData, update, model, callback) {
				var alternative = DataManager.getDataManager().getAlternativeSync(alternativeId);
				var previousWorkspace = DataManager.getDataManager().getWorkspace();
				if (update) {
					var missingValueStreams = [];
					var packAltId = alternativeId;
					var vdmStore = DataManager.getDataManager().getVDMStore(packAltId);
					DataManager.getDataManager().fetchDocumentFromPackage(model.get('beepReference'), model.get('beepType'), DataManager.getDataManager().get('currentVDMVersion'), model.get('beepReference'), model.get('beepType'), vdmStore, {
						success: function (ecoMapPackage) {
							var valueStreamDiagrams = ecoMapPackage.get('diagrams');
							var existingValueStreams = [];
							for (var i = 0; i < valueStreamDiagrams.models.length; i++) {
								existingValueStreams.push(valueStreamDiagrams.models[i].get('name'));
							}
							
							for (var i = 0; i < valueStreamList.length; i++) {
								var vsName = valueStreamList[i].trim();
								vsName = vsName.replace(/(\r\n|\n|\r|\\n)/gm, '');
								if ($.inArray(vsName.trim(), existingValueStreams) == -1) {
									missingValueStreams.push(vsName);
								}
							}
							
							self.valueStreamsDiagrams(valueStreamInventoryData,ecoMapPackage, alternative, previousWorkspace, missingValueStreams, function () {
								callback();
							});
						},
						error: function (error) {
							bootbox.alert(error);
						}
					});
				} else {
					DataManager.getDataManager().getWorkspaceDataWithId(alternative.id, function (wsData) {
						var altWorkspace = wsData.get('workspace');
						DataManager.getDataManager().setWorkspace(altWorkspace, function () {
							ValueDeliveryModel.setAltForVstreamImport(alternative.id, valueStreamList,valueStreamInventoryData, wb, self.fileName(),function(){
								callback();
							})
						});
					});
				}
            }

            this.valueStreamsDiagrams = function (valueStreamInventoryData,ecomap, alternative, previousWorkspace, existingList, callback) { 
				var ValueStreamMapDiagram = Backbone.Relational.store.getObjectByName("vdml.ValueStreamMapDiagram");
				if(self.fileName() == "Panorama360"){
					var packages;
					DataManager.getDataManager().set("currentPlan",alternative.get('phaseAlternativeOwner').get('phaseOwner'));
					var planId = alternative.get('phaseAlternativeOwner').get('phaseOwner').id;
					var commonAltId = planId.substr(planId.lastIndexOf('@')) + "-Common@";
					DataManager.getDataManager().getAllDocumentsCollectionOfType(commonAltId, 'vdml_ValueDeliveryModel', function (result) {
						if (result && result.length > 0) {
							for(var i=0;i<result.length;i++){
								if (result.models[i].get('id').indexOf("Common") > -1) {
									if(result.models[i].get('name') != "Common Package" && result.models[i].get('name') === self.fileName() + " Functional Definition Package"){
											packages = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({id:result.models[i].get('id')});
											packages = packages ? packages.get('capabilityLibrary').models[0] : "";
									}
								}
							};
						}
					
						async.eachSeries(existingList, function(valuestreamItem, valCallback) {
							EcoMap.createValueStreamMapDiagram(ecomap, valuestreamItem, valuestreamItem, alternative, ValueStreamMapDiagram, function(){
								var valuestreamCount;
								for(var i=6;i<valueStreamInventoryData[2].length;i++){
									if(valueStreamInventoryData[2][i]){
										var isExistItem = valueStreamInventoryData[2][i].trim();
										isExistItem = isExistItem.replace(/(\r\n|\n|\r|\\n)/gm, '');
										if(isExistItem.trim() === valuestreamItem){
											valuestreamCount = i;
											break;
										}
									}
								}
								ValueDeliveryModel.createPanoramaValueStreamMapElements(valueStreamInventoryData,valuestreamItem,valuestreamCount,wb,packages);
								window.vdmModelView.saveDiagram(null, null, function () {
									valCallback();
								});
							})
						},function(err){
							DataManager.getDataManager().setWorkspace(previousWorkspace, function () {
								DataManager.getDataManager().set('currentPlan',null);
								callback();
							});
						});
					}, false, null, true);
				}else{
					var existList =[];
					for(var i=0;i<existingList.length;i++){
						if(wb.Sheets[existingList[i].trim()]){
							existList.push(existingList[i]);
						}
					}
					var valueStreamColumn = {'name': 0, 'defn': 1};
					async.eachSeries(valueStreamInventoryData, function(valueStreamData, valCallback) {
						if(valueStreamData[valueStreamColumn.name] != "Value Stream Name" && valueStreamData[valueStreamColumn.defn] != undefined) {
							var valueStreamName;
							if( valueStreamData[valueStreamColumn.name].indexOf('!') !== -1) {
								valueStreamData[valueStreamColumn.name] = valueStreamData[valueStreamColumn.name].substring(
									valueStreamData[valueStreamColumn.name].indexOf('\'') + 1, valueStreamData[valueStreamColumn.name].lastIndexOf('\''));
							}
							valueStreamData[valueStreamColumn.name] = valueStreamData[valueStreamColumn.name].trim();
							for(var vs =0;vs<existList.length;vs++){
								if(existList[vs].trim() === valueStreamData[valueStreamColumn.name]){
									valueStreamName = valueStreamData[valueStreamColumn.name];
									break;
								}
							}
							if(valueStreamName){
								EcoMap.createValueStreamMapDiagram(ecomap, valueStreamData[valueStreamColumn.name],
								valueStreamData[valueStreamColumn.name], alternative, ValueStreamMapDiagram, function() {
									setTimeout(function(){
										ValueDeliveryModel.createValueStreamMapElements(valueStreamData[valueStreamColumn.name],wb);
										window.vdmModelView.saveDiagram(null, null, function () {	
											valCallback();
										});
									},500);
								});
							}else{
								valCallback();
							}
						} else {
							valCallback();
						}
					},function(err){
						window.autoProcess = false;
						DataManager.getDataManager().setWorkspace(previousWorkspace, function () {
							callback();
						});
					});
				}
            }
			
            this.afterRenderView = function () {
                self.loadLibraryPackages(self.coverSheet);
            };

            this.cleanModalDialog = function () {
                window.cleanDialogModel(self.encodeId, self);
            };

            this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), ['Plan', 'Close', 'Delete', 'Complete', 'Progress', 'Details', 'Collaboration', 'Guidance', 'Changes', 'UpdateCapabilities']);
        }

        //this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), ['Plan', 'Close', 'Complete', 'update']);

        isAdminView() {
            return true;
        }

        setResizeHeightHandler() {
            var self = this;
            setTimeout(function () {
                if (self.spliter) {
                    var windowHeight = window.innerHeight - $('.navbar').outerHeight();
                    var propertiesNode = $('.propertiesArea');
                    var purchaseNode = $('#purchase');
                    var detailsNode = $('#details');
                    var tabNode = $('.tab-content');
                    var adminAreaNode = $('.adminArea');
                    var baseHeight = _.max([windowHeight, purchaseNode.outerHeight(), adminAreaNode.outerHeight(), tabNode.outerHeight(), propertiesNode.outerHeight(), detailsNode.outerHeight(), propertiesNode.outerHeight()]);
                    self.spliter.css('height', (baseHeight) + 'px');
                    $("#splitPanels").enhsplitter('refresh');
                }
            }, 150)
        };

        static getInstance(model, options) {
            var view = new CapabilityTreeConstructionViewModel(model, options);
            view.init(model, options);
            return view;
        }
}
path.CapabilityTreeConstructionViewModel = CapabilityTreeConstructionViewModel;