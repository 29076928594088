import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {NamedPlanElementMixin} from './NamedPlanElementMixin'
import {EObject} from '../cmof/EObject'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/transformation/NamedPlanElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,NamedPlanElementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
	var NamedPlanElement = EObject.extend(utils.customExtends({
		relations:NamedPlanElementMixin.getMixinRelations(),
		subModelTypes: {
			transformation_Plan : 'transformation.Plan',
			transformation_Phase : 'transformation.Phase',
			transformation_Role : 'transformation.Role',
			transformation_PhaseObjectiveSet : 'transformation.PhaseObjectiveSet',
			transformation_BranchReference : 'transformation.BranchReference',
			transformation_PackageReference : 'transformation.PackageReference',
			transformation_Alternative : 'transformation.Alternative'
		},
		initialize: function(attributes, options) {
			EObject.prototype.initialize.apply(this, arguments);
			this.lawnchair = DataManager.getDataManager().getVDMStore(window.plansKey);
		}
		}
		, new NamedPlanElementMixin()
	));
	NamedPlanElement.abstract = true;
	path.NamedPlanElement = NamedPlanElement;
	//return NamedPlanElement;
	export {NamedPlanElement};
//});