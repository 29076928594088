import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import { StrategyMapDetailsViewModel } from './StrategyMapDetailsViewModel'
import { PeriodKind } from '../../../../bo/vdml/PeriodKind'
//import {PackageReference as PlanPackageReference} from '../../../../../version1/bo/transformation/PackageReference'

/*define(["require", "jquery", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "appbo/beeppackage/BeepPackageMixin", "appbo/transformation/PackageReference", "appcommon/com/vbee/filesystem/PackageReference","appbo/vdml/StrategyMapDiagram","appbo/ecomap/EcoMap","app/global"],
	function (require, $, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, BeepPackageMixin, PlanPackageReference, PackageReference, StrategyMapDiagram, EcoMap, global)
{*/
var path = DataManager.getDataManager().buildAppNsPath("strategyMap.views.designer", global.version);
export class StrategyMapDetails2ViewModel extends StrategyMapDetailsViewModel {
    getPeriodsByYear(year, plan) {
        var self = this;
        let selectedYear = year
        const periods = self.dataSets.filter(d => d.year === +selectedYear)
        const periodsByPeriodKind = periods.map(d => {
            return plan.getPeriodKinds(d.year, d.period, self.periodKind)
        });
        self.periodMonths(periodsByPeriodKind);
    }
    init(model, options) {
        super.init(model, options);
        var self = this;
        var dataManager = DataManager.getDataManager();
        this.periodYears = ko.observableArray();
        this.periodMonths = ko.observableArray();
        this.selectedYear = ko.observable();
        this.selectedPeriod = ko.observable();
        this.showPeriods = ko.observable(true)
        this.periodKind = dataManager.get('currentPlan').get("periodKind");
        var scenarioId = dataManager.get("currentPlan").get("defaultScenario");
        var currentAlternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({id: dataManager.get('viewAlternative')});
        var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({id: scenarioId});
        var currentPlan = dataManager.get('currentPlan');
        this.dataSets = currentAlternative.getPhaseAlternativeSteps(scenario);
        const filteredYears = this.dataSets.filter((item, index, self) =>
            index === self.findIndex((t) => t.year === item.year)
        );
        this.periodYears(filteredYears.map(d=>{return {text:d.year,value:d.year}}));
        self.selectedYear.subscribe(function(value) {
            if (value !== undefined) {
                dataManager.set("currentYear", value);
                self.getPeriodsByYear(value, currentPlan);
                self.defaultPeriod ? self.selectedPeriod(self.defaultPeriod) : self.selectedPeriod();
                if (self.parentView && self.parentView.showPost) {
                    window.vdmModelView.updateYearAndPeriod(value, self.selectedPeriod());
                }
                self.defaultPeriod = undefined
            }
        });

        self.selectedPeriod.subscribe(function(value) {
            if (value !== undefined) {
                dataManager.set('currentPeriod', value);
                if (self.parentView && self.parentView.showPost) {
                    window.vdmModelView.updateYearAndPeriod(self.selectedYear(), value);
                }
            }
        });
        if (PeriodKind.symbols()[0].name === self.periodKind) {
            this.showPeriods(false)
        }
        var defaultPeriod = scenario.fillDefaultPeriods(this.dataSets, self.periodKind);
        self.defaultPeriod = defaultPeriod.period
        dataManager.set('currentPeriod', defaultPeriod.period);
        self.selectedYear(defaultPeriod.year);
        dataManager.set("currentYear", defaultPeriod.year);
    }

    //self.openDiagram(self.model.get('data'),reDraw);

    static getInstance(model, options) {
        var view = new StrategyMapDetails2ViewModel();
        view.init(model, options);
        return view;
    };
}
path.StrategyMapDetails2ViewModel = StrategyMapDetails2ViewModel;