import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { RoleDefinition } from './RoleDefinition'
import { RoleCategory } from './RoleCategory'
import { ValueDeliveryModel } from './ValueDeliveryModel'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, RoleDefinition
, RoleCategory
, ValueDeliveryModel){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!RoleDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.RoleDefinition){
			RoleDefinition = importPath.RoleDefinition;
		}
		else{
            import('./RoleDefinition').then(loadedModule => {
                RoleDefinition = loadedModule;
			});
			/*require(["appbo/vdml/RoleDefinition"],function(loadedModule){
				RoleDefinition = loadedModule;
			});*/
		}
	}
	if(!RoleCategory){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.RoleCategory){
			RoleCategory = importPath.RoleCategory;
		}
		else{
            import('./RoleCategory').then(loadedModule => {
                RoleCategory = loadedModule;
			});
			/*require(["appbo/vdml/RoleCategory"],function(loadedModule){
				RoleCategory = loadedModule;
			});*/
		}
	}
	if(!ValueDeliveryModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueDeliveryModel){
			ValueDeliveryModel = importPath.ValueDeliveryModel;
		}
		else{
            import('./ValueDeliveryModel').then(loadedModule => {
         //       ValueDeliveryModel = loadedModule;
			});
			/*require(["appbo/vdml/ValueDeliveryModel"],function(loadedModule){
				ValueDeliveryModel = loadedModule;
			});*/
		}
	}
	export class RoleLibraryMixin {

	defaults(){
		var ret = {
			type: "vdml_RoleLibrary"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_RoleLibrary",
			key:"roleDefinition",
			relatedModel:"vdml.RoleDefinition",
			reverseRelation: {
				key:"library",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_RoleLibrary",
			key:"roleCategory",
			relatedModel:"vdml.RoleCategory",
			reverseRelation: {
				key:"library",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		])
	}
	
	static getCumulativeMixinRelations(){
		if (!RoleLibraryMixin.cummulativeRelations) {
            RoleLibraryMixin.cummulativeRelations = _.union(RoleLibraryMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return RoleLibraryMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("containingModel") ? this.get("containingModel") : this.previousAttributes().containingModel;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("containingModel") ? this.get("containingModel") : this.previousAttributes().containingModel;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/RoleLibraryPropertiesTemplate.html",
			templateName : "RoleLibraryPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/RoleLibraryViewModel",
			tabId : "RoleLibraryView",
			tabName: "RoleLibrary"
		}
	}
	
	}
	path.RoleLibraryMixin = RoleLibraryMixin;
	//return RoleLibraryMixin;
//});