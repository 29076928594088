import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import {SmmElement} from './SmmElement'
import {SmmElementMixin} from './SmmElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ObservedMeasure } from './ObservedMeasure';
import { SmmModel } from './SmmModel';
import { ObservationScope } from './ObservationScope';
import { AbstractMeasureElement } from './AbstractMeasureElement';

/*define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/SmmElementMixin"],
function(require,jQuery,_,async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,SmmElementMixin
, ObservedMeasure
, SmmModel
, ObservationScope
, AbstractMeasureElement){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!ObservedMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.ObservedMeasure){
			ObservedMeasure = importPath.ObservedMeasure;
		}
        else {
            import('./ObservedMeasure').then(({ default: ObservedMeasure }) => {
                ObservedMeasure = ObservedMeasure;
            });
            /*require(["appbo/smm/ObservedMeasure"], function (loadedModule) {
                ObservedMeasure = loadedModule;
			});*/
		}
	}
	if(!SmmModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.SmmModel){
			SmmModel = importPath.SmmModel;
		}
        else {
            import('./SmmModel').then(({ default: SmmModel }) => {
                SmmModel = SmmModel;
            });
          /*require(["appbo/smm/SmmModel"], function (loadedModule) {
                SmmModel = loadedModule;
			});  */
		}
	}
	if(!ObservationScope){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.ObservationScope){
			ObservationScope = importPath.ObservationScope;
		}
        else {
            import('./ObservationScope').then(({ default: ObservationScope }) => {
                ObservationScope = ObservationScope;
            });
            /*require(["appbo/smm/ObservationScope"], function (loadedModule) {
                ObservationScope = loadedModule;
			});*/
		}
	}
	if(!AbstractMeasureElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.AbstractMeasureElement){
			AbstractMeasureElement = importPath.AbstractMeasureElement;
		}
        else {
            import('./AbstractMeasureElement').then(({ default: AbstractMeasureElement }) => {
                AbstractMeasureElement = AbstractMeasureElement;
            });
           /* require(["appbo/smm/AbstractMeasureElement"], function (loadedModule) {
                AbstractMeasureElement = loadedModule;
			});*/
		}
	}
	export class ObservationMixin {

	defaults(){
		var ret = {
			type: "smm_Observation"
		}
		return jQuery.extend(SmmElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"smm_Observation",
			key:"observedMeasures",
			relatedModel:"smm.ObservedMeasure",
			reverseRelation: {
				key:"observation",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		/*{
			type :Backbone.HasMany,
			containingClass:"smm_Observation",
			key:"scopes",
			relatedModel:"smm.ObservationScope",
			reverseRelation: {
				key:"observation",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_Observation",
			key:"requestedMeasures",
			relatedModel:"smm.AbstractMeasureElement",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}*/
		])
	}
	static getCumulativeMixinRelations(){
		if (!ObservationMixin.cummulativeRelations) {
            ObservationMixin.cummulativeRelations = _.union(ObservationMixin.getMixinRelations()
                , SmmElementMixin.getCumulativeMixinRelations()
            );
        }
		return ObservationMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_SmmElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			//{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "whenObserved",type : "EDate",defaultValue : "null",containingClass : "smm_Observation" },
			//{name : "observer",type : "EString",defaultValue : "null",containingClass : "smm_Observation" },
            //{ name: "tool", type: "EString", defaultValue: "null", containingClass: "smm_Observation" },
            { name: "dataReference", type: "EString", defaultValue: "null", containingClass: "smm_Observation" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("smmModel") ? this.get("smmModel") : this.previousAttributes().smmModel;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("smmModel") ? this.get("smmModel") : this.previousAttributes().smmModel;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/ObservationPropertiesTemplate.html",
			templateName : "ObservationPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/ObservationViewModel",
			tabId : "ObservationView",
			tabName: "Observation"
		}
	}


    //#startCustomMethods

	clone(data, observation,callback) {
	    var metrics = this.get('smmModel');
	    var obsMeasures = this.get('observedMeasures');
	    async.each(obsMeasures.models,function (obsMes,obsMesHandled) {
	        obsMes.clone(data, observation, function () {
	            obsMesHandled();
	        });
	    }, function () {
	        if (callback) {
	            callback(observation);
	        }
	    });
	    return observation;
	}
    createObservedMeasure (obsMesName) {
		var obsMesId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var obsMes = new ObservedMeasure({id:obsMesId,name:obsMesName,observation:this});
		return obsMes;
	};
	getContext(){
		var observationContext = this.get('observationContext');
		if(!observationContext){
			observationContext = this.get('phaseObjectiveSet');
		}
		return observationContext;
    };
    
    onNameChanged(model, newName) {
        
    	if(!model.changed['name']){
    		return; // to trigger it only when the name is changed, not when the model is created
    	}
        var whenObserved = model.get('whenObserved');
        var plan = DataManager.getDataManager().get('currentPlan');
        if (plan) {
            plan.getAllDocumentsCollectionOfType(plan, "smm_Observation", function (results) {
                async.each(results.models, function (obs, obsUpdated) {
                    var isPlanDoc = obs.id.indexOf(window.plansKey) >= 0;
                    if (isPlanDoc) {
                        var obsModel = Backbone.Relational.store.getObjectByName('smm.Observation').find({ id: obs.id });
                        if (obsModel && obsModel.getNestedParent().id !== plan.id) {
                            obsUpdated();
                            return;
                        }
                        if (obsModel && obsModel.id !== model.id && obsModel.get('whenObserved') === whenObserved && obsModel.get('name') !== newName) {
                            obsModel.set('name', newName);
                        }
                        obsUpdated();
                    } else {
                        var altId = DataManager.getDataManager().getRepositoryId(obs.id);
                        var vdmStore = DataManager.getDataManager().getVDMStore(altId);
                        DataManager.getDataManager().fetchDocumentFromPackage(obs.get('parent'), "appbo/vdml/ValueDeliveryModel", obs.get('version'), obs.id, "appbo/smm/Observation", vdmStore, {
                            success: function (obsModel) {
                                if (obsModel.id !== model.id && obsModel.get('whenObserved') === whenObserved && obsModel.get('name') !== newName) {
                                    obsModel.set('name', newName);
                                }
                                obsUpdated();
                            },
                            error: function (error) {
                                console.log('Unable to load Package for updating observation name');
                                obsUpdated();
                            }
                        });
                    }
                }, function () {
                });
            }, null, null, null, "vbc:cmof_EObject-name", null, null, true, null, true);
        }
    }

	static applyDefaultObservation(whenObserved, delScenario, callback) {
		var plan = DataManager.getDataManager().get('currentPlan');
		plan.getAllDocumentsCollectionOfType(plan, "smm_Observation", function (results) {
			async.eachSeries(results.models, function (obs, obsUpdated) {
				var isPlanDoc = obs.id.indexOf(window.plansKey) >= 0;
				if (isPlanDoc) {
					var obsModel = Backbone.Relational.store.getObjectByName('smm.Observation').find({ id: obs.id });
					if (obsModel && obsModel.getNestedParent().id !== plan.id) {
						obsUpdated();
						return;
					}
					if (obsModel && obsModel.get('whenObserved') === whenObserved) {
						obsModel.unset('whenObserved');
					}
					else if (obsModel && !obsModel.get('whenObserved')) {
						obsModel.set('whenObserved', whenObserved);
						if (delScenario) {
							obsModel.destroy();
						}
					}
					obsUpdated();
				} else {
					var altId = DataManager.getDataManager().getRepositoryId(obs.id);
					var vdmStore = DataManager.getDataManager().getVDMStore(altId);
					DataManager.getDataManager().fetchDocumentFromPackage(obs.get('parent'), "appbo/vdml/ValueDeliveryModel", obs.get('version'), obs.id, "appbo/smm/Observation", vdmStore, {
						success: function (obsModel) {
							if (obsModel && obsModel.get('whenObserved') === whenObserved) {
								obsModel.unset('whenObserved');
							}
							else if (obsModel && !obsModel.get('whenObserved')) {
								obsModel.set('whenObserved', whenObserved);
								if (delScenario) {
									obsModel.destroy();
								}
							}
							obsUpdated();
						},
						error: function (error) {
							console.log('Unable to load Package for updating observation name');
							obsUpdated();
						}
					});
				}
			}, function () {
				callback();
			});
		}, null, null, null, "vbc:cmof_EObject-name", null, null, true, null, true);
	};

	static updateObservations(timeStampArray, finalCallback) {
		//var self = this;
		var triggerUpdate = false;
		var currentPlan = DataManager.getDataManager().get('currentPlan');
		for(var i=0; i< timeStampArray.length; i++){
			var obsList = Backbone.Relational.store._types["smm.Observation"]._collection.where({whenObserved:timeStampArray[i]});
			for(var j=0; j<obsList.length; j++){
				var ob = obsList[j];
				var obsParent = ob.getNestedParent();
				if(ob && ob.get('triggerUpdate') && (obsParent.id == currentPlan.id || obsParent.get('planId') == currentPlan.id)){
					triggerUpdate = true;
					ob.set('triggerUpdate',false);
				}
			}						
		}
		var phaseList = [];
		var newObservations = [];
		var phase = currentPlan.get('phase').findWhere({ previousPhase: null });
		var firstPhasePrimaryobsModels = phase.get('primary').getOrCreatePhaseObjectiveSet().get('objectiveObservation').models;
		if (phase.get('primary') !== phase.get('master')) {
			var firstPhaseMasterobsModels = phase.get('master').getOrCreatePhaseObjectiveSet().get('objectiveObservation').models;
			firstPhasePrimaryobsModels = firstPhaseMasterobsModels;
		}
		while (phase) {
			phaseList.push(phase);
			phase = phase.get('nextPhase');
		}
		async.eachSeries(phaseList, function (phase, phaseHandled) {
			var phaseAlts = phase.get('phaseAlternative');
			handleAllObservations(firstPhasePrimaryobsModels, phaseAlts, function () {
				phaseHandled();
			});
		}, function () {
			DataManager.getDataManager().unset('newObservations');
			finalCallback && finalCallback();
		});

		function handleAllObservations(primaryobsModels, phaseAlts, callback1) {
			async.each(phaseAlts.models, function (alt, altHandled) {
				var altObsModels = alt.getOrCreatePhaseObjectiveSet().get('objectiveObservation').models;
				for (var i = 0; i < primaryobsModels.length; i++) {
					var whenObserved = primaryobsModels[i].get('whenObserved');
					if (whenObserved && !isNaN(whenObserved)) {
						continue;
					}
					var exists = _.find(altObsModels, function (a) {
						if (a.get('whenObserved')) {
							return a.get('whenObserved') === primaryobsModels[i].get('whenObserved');
						}
					});
					if (!exists && (timeStampArray.indexOf(primaryobsModels[i].get('whenObserved')) > -1)) {
						var newObservation = primaryobsModels[i].get('smmModel').createObservation(primaryobsModels[i].get('name'), primaryobsModels[i].get('whenObserved'), null, alt.id);
						var altScenario = alt.getOrCreatePhaseObjectiveSet();
						altScenario.get('objectiveObservation').add(newObservation);
						newObservations.push(newObservation.id);
						DataManager.getDataManager().set('newObservations', newObservations);
						triggerUpdate = true;
					}
				}
				
				if(triggerUpdate){
					async.each([handlePlanObsModels, updateBmObservations], function (func, funchandled) {
						func(alt, altObsModels, funchandled);
					}, function () {
						altHandled();
					});
				} else {
					altHandled();
				}
				
			}, function () {
				callback1 && callback1();
			});
		}

		function handlePlanObsModels(alt, altObsModels, callback4) {
			//var newObsMeasures = [];
			var createdObsMeasure;
			var baseScenario = _.find(altObsModels, function (a) { if (!a.get('whenObserved')) { return a; } });
			var baseScenarioObsMeasures = baseScenario ? baseScenario.get('observedMeasures').models : "";
			async.each(altObsModels, function (ob, obHandled1) {
				if (ob !== baseScenario && isNaN(ob.get('whenObserved'))) {
					async.each(baseScenarioObsMeasures, function (obMeasureModel, obsMeasureHandled) {
						var exists = alt.getOrCreatePhaseObjectiveSet().getObservedMeasureWithMeasurements(obMeasureModel.get('measurements').models[0].get('measuredCharacteristic'), ob.get('whenObserved') ? true : false, ob.get('whenObserved'));
						if (!exists) {
							obMeasureModel.clone(null, ob, function () {
								createdObsMeasure = alt.getOrCreatePhaseObjectiveSet().getObservedMeasureWithMeasurements(obMeasureModel.get('measurements').models[0].get('measuredCharacteristic'), ob.get('whenObserved') ? true : false, ob.get('whenObserved'))
								//newObsMeasures.push(createdObsMeasure.id);
								if (newObservations.indexOf(createdObsMeasure.get('observation').id) > -1 && alt.get('phaseAlternativeOwner').get('previousPhase')) {
									var val = createdObsMeasure.get('measurements').models[0].get('measuredCharacteristic').getParent();
									if (val) {
										if (alt !== alt.get('phaseAlternativeOwner').get('master')) {
											var prevPhasePrimAltComp = alt.get('phaseAlternativeOwner').get('master').getModelLinkedSync(val);
											var prevPhasePrimAltScenario = prevPhasePrimAltComp ? alt.get('phaseAlternativeOwner').get('master').getMainScenario(prevPhasePrimAltComp.getNestedParent()) : null;
										} else {
											prevPhasePrimAltComp = alt.get('phaseAlternativeOwner').get('previousPhase').get('primary').getModelLinkedSync(val);
											prevPhasePrimAltScenario = prevPhasePrimAltComp ? alt.get('phaseAlternativeOwner').get('previousPhase').get('primary').getMainScenario(prevPhasePrimAltComp.getNestedParent()) : null;
										}
										var prevBaseObservedMeasure = prevPhasePrimAltScenario ? prevPhasePrimAltScenario.getObservedMeasureWithMeasurements(prevPhasePrimAltComp.get('valueMeasurement'), false, '') : null;
										var prevobservedMeasure = prevPhasePrimAltScenario ? prevPhasePrimAltScenario.getObservedMeasureWithMeasurements(prevPhasePrimAltComp.get('valueMeasurement'), true, ob.get('whenObserved')) : null;
										var prevObservedMest = prevobservedMeasure ? prevobservedMeasure.get('measurements').at(0) : null;
										var prevBaseObservedMest = prevBaseObservedMeasure ? prevBaseObservedMeasure.get('measurements').at(0) : null;
										if (prevBaseObservedMest && prevObservedMest && prevBaseObservedMest.get('value') !== prevObservedMest.get('value') && prevObservedMest.get('type') === 'smm_DirectMeasurement') {
											createdObsMeasure.get('measurements').at(0).set('value', prevObservedMest.get('value'));
											createdObsMeasure.get('measurements').at(0).calculate();
										}
										obsMeasureHandled();
									} else {
										obsMeasureHandled();
									}
								} else {
									var msmt = createdObsMeasure.get('measurements');
									if(msmt && msmt.at(0)){
										msmt.at(0).calculate();
									}
									obsMeasureHandled();
								}
							});
						} else {
							obsMeasureHandled();
						}
					}, function () {
						obHandled1();
						/*async.each(baseScenarioObsMeasures,function(obMeasureModel,obsMeasureModelHandled1){
							var exists = alt.getOrCreatePhaseObjectiveSet().getObservedMeasureWithMeasurements(obMeasureModel.get('measurements').models[0].get('measuredCharacteristic'),ob.get('whenObserved')?true:false, ob.get('whenObserved'));
							if(newObsMeasures.indexOf(exists.id)>-1){
								handlePlanObsMeasureParents(obMeasureModel,alt,newObsMeasures,ob);
								obsMeasureModelHandled1();
							}else{
								obsMeasureModelHandled1();
							}
						},function(){
							obHandled1();			
						});*/
					});
				} else {
					obHandled1();
				}
			}, function () {
				if (callback4) {
					callback4();
				}
			});
		}

		/*function handlePlanObsMeasureParents(obMeasureModel, alt, newObsMeasures, ob) {
			async.each(obMeasureModel.get('measurements').models[0].getParents(), function (parent, parentHandled) {
				var useScenario = alt.getOrCreatePhaseObjectiveSet();
				if (parent.getNestedParent() !== DataManager.getDataManager().get('currentPlan')) {
					useScenario = alt.getMainScenario(parent.getNestedParent());
				}
				var obParentMeasure = useScenario.getObservedMeasureWithMeasurements(parent.getParent().get('measurements').models[0].get('measuredCharacteristic'), ob.get('whenObserved') ? true : false, ob.get('whenObserved'));
				if (obParentMeasure && (newObsMeasures.indexOf(obParentMeasure.id) === -1)) {
					async.each(parent.get('measurementRelationships').models, function (rel, relHandled) {
						if (rel.get('to').getParent() === obMeasureModel) {
							rel.clone(null, obParentMeasure.get('measurements').models[0], null);
							relHandled();
						} else {
							relHandled();
						}
					}, function () {
						parentHandled();
					});
				} else {
					parentHandled();
				}
			}, function () {

			});
		}*/

		function updateBmObservations(alt, altObsModels, callback2) {
			async.each(alt.get('designScenario').models, function (scenario, scenarioHandled) {
				var scenarioObj = Backbone.Relational.store.getObjectByName('vdml.Scenario').find({ id: scenario.get('scenario') });
				if(!scenarioObj){
					scenarioHandled();
				} else {
					var altBmobsModels = scenarioObj.get('contextObservation').models;
					handleBmObsModels(alt, altObsModels, altBmobsModels, scenarioObj, function () {
						scenarioHandled();
					});
				}
			}, function () {
				callback2 && callback2();
			});
		}

		function handleBmObsModels(alt, altObsModels, altBmobsModels, scenarioObj, callback3) {
			for (var i = 0; i < altObsModels.length; i++) {
				var whenObserved = altObsModels[i].get('whenObserved');
				if (whenObserved && !isNaN(whenObserved)) {
					continue;
				}
				var exists = _.find(altBmobsModels, function (a) {
					if (a.get('whenObserved')) {
						return a.get('whenObserved') === altObsModels[i].get('whenObserved');
					}
				});
				if (!exists && (timeStampArray.indexOf(altObsModels[i].get('whenObserved')) > -1)) {
					var snapshotId = window.utils.getScenarioSnapshotId(alt,altObsModels[i].get('whenObserved'));
					var newobservation = scenarioObj.getParent().get('defaultMetricsModel').createObservation(altObsModels[i].get('name'), altObsModels[i].get('whenObserved'), snapshotId, alt.id);
					scenarioObj.get('contextObservation').add(newobservation);
					newObservations.push(newobservation.id);
					DataManager.getDataManager().set('newObservations', newObservations);
				}
			}
			handleBmObsMeasures(alt, altBmobsModels, scenarioObj, function () {
				callback3 && callback3();
			});
		}

		function handleBmObsMeasures(alt, altBmobsModels, scenarioObj, callback4) {
			//var newBMObsMeasures = [];
			var createdObsMeasure;
			var baseScenario = _.find(altBmobsModels, function (a) { if (!a.get('whenObserved')) { return a; } });
			var baseScenarioObsMeasures = null;
            if(baseScenario){
                baseScenarioObsMeasures = baseScenario.get('observedMeasures').models;
            }
			async.each(altBmobsModels, function (ob, obHandled3) {
				if (ob !== baseScenario && isNaN(ob.get('whenObserved'))) {
					async.each(baseScenarioObsMeasures, function (obMeasureModel, obsMeasureHandled1) {
						var exists = scenarioObj.getObservedMeasureWithMeasurements(obMeasureModel.get('measurements').models[0].get('measuredCharacteristic'), ob.get('whenObserved') ? true : false, ob.get('whenObserved'));
						if (!exists) {
							obMeasureModel.clone(null, ob, function () {
								createdObsMeasure = scenarioObj.getObservedMeasureWithMeasurements(obMeasureModel.get('measurements').models[0].get('measuredCharacteristic'), ob.get('whenObserved') ? true : false, ob.get('whenObserved'));
								if(createdObsMeasure) {
									//newBMObsMeasures.push(createdObsMeasure.id);
								}
								if (createdObsMeasure && newObservations.indexOf(createdObsMeasure.get('observation').id) > -1 && alt.get('phaseAlternativeOwner').get('previousPhase')) {
									var val = createdObsMeasure.get('measurements').models[0].get('measuredCharacteristic').getParent();
									if (val) {
										if (alt !== alt.get('phaseAlternativeOwner').get('master')) {
											var prevPhasePrimAltComp = alt.get('phaseAlternativeOwner').get('master').getModelLinkedSync(val);
										} else {
											prevPhasePrimAltComp = alt.get('phaseAlternativeOwner').get('previousPhase').get('primary').getModelLinkedSync(val);
										}
										var prevPhasePrimAltScenario = prevPhasePrimAltComp ? alt.get('phaseAlternativeOwner').get('previousPhase').get('primary').getMainScenario(prevPhasePrimAltComp.getNestedParent()) : null;
										var prevBaseObservedMeasure = prevPhasePrimAltScenario ? prevPhasePrimAltScenario.getObservedMeasureWithMeasurements(prevPhasePrimAltComp.get('valueMeasurement'), false, '') : null;
										var prevobservedMeasure = prevPhasePrimAltScenario ? prevPhasePrimAltScenario.getObservedMeasureWithMeasurements(prevPhasePrimAltComp.get('valueMeasurement'), true, ob.get('whenObserved')) : null;
										var prevObservedMest = prevobservedMeasure ? prevobservedMeasure.get('measurements').at(0) : null;
										var prevBaseObservedMest = prevBaseObservedMeasure ? prevBaseObservedMeasure.get('measurements').at(0) : null;
										if (prevBaseObservedMest && prevobservedMeasure && prevBaseObservedMest.get('value') !== prevObservedMest.get('value') && prevObservedMest.get('type') === 'smm_DirectMeasurement') {
											createdObsMeasure.get('measurements').at(0).set('value', prevObservedMest.get('value'));
											createdObsMeasure.get('measurements').at(0).calculate();
										}
										obsMeasureHandled1();
									} else {
										obsMeasureHandled1();
									}
								} else {
									var msmt = createdObsMeasure.get('measurements');
									if(msmt && msmt.at(0)){
										msmt.at(0).calculate();
									}
									obsMeasureHandled1();
								}
							});
						} else {
							obsMeasureHandled1();
						}
					}, function () {
						obHandled3();
						/*async.each(basescenarioobsmeasures,function(obmeasuremodel,obmeasuremodelhandled){
							var exists = scenarioobj.getobservedmeasurewithmeasurements(obmeasuremodel.get('measurements').models[0].get('measuredcharacteristic'),ob.get('whenobserved')?true:false, ob.get('whenobserved'));
							if(newbmobsmeasures.indexof(exists.id)>-1){
								handlebmobsmeasureparents(alt,obmeasuremodel,scenarioobj,newbmobsmeasures,ob);
								obmeasuremodelhandled();	
							}else{
								obmeasuremodelhandled();
							}
						},function(){
							obhandled3();			
						});*/
					});
				} else {
					obHandled3();
				}
			}, function () {
				callback4 && callback4();
			});
		}

		/*function handleBmObsMeasureParents(alt, obMeasureModel, scenarioObj, newBMObsMeasures, ob) {
			async.each(obMeasureModel.get('measurements').models[0].getParents(), function (parent, parentHandled1) {
				var useScenario = scenarioObj;
				if (parent.getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
					useScenario = alt.getOrCreatePhaseObjectiveSet();
				}
				var obParentMeasure = useScenario.getObservedMeasureWithMeasurements(parent.getParent().get('measurements').models[0].get('measuredCharacteristic'), ob.get('whenObserved') ? true : false, ob.get('whenObserved'));
				if (obParentMeasure && (newBMObsMeasures.indexOf(obParentMeasure.id) === -1)) {
					async.each(parent.get('measurementRelationships').models, function (rel, relHandled1) {
						if (rel.get('to').getParent() === obMeasureModel) {
							rel.clone(null, obParentMeasure.get('measurements').models[0], null);
						} else {
							relHandled1();
						}
					}, function () {
						parentHandled1();
					});
				} else {
					parentHandled1();
				}
			}, function () {
			});
		}*/
	};
//#endCustomMethods	
	
	}
	path.ObservationMixin = ObservationMixin;
	//return ObservationMixin;
//});