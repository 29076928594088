import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as  bootbox from '../../../../../../libs/bootbox/bootbox'
import moment from 'moment';
//import { DashboardMixin } from '../../../../../version1/bo/dashboard/DashboardMixin'

/*define(["jquery", "underscore", "backbone", "knockout", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "app/global","appcommon/com/vbee/utils/DateFormat","moment", "jquery.DataTable", "dataTable.PageResize", "dataTable.ColReorderWithResize" ],
function ($, _, Backbone, ko, kb, bootbox, async, DataManager, global, DateFormat, moment)
{*/
    var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart", global.version);
	export class ImportDataViewModel {
		
		populateDefaultValues() {
		    var today = new Date();
		    var month = today.getMonth() - 1,
		        year = today.getFullYear();
		    if (month < 0) {
		        month = 11;
		        year -= 1;
		    }
		    return $.datepicker.formatDate('mm/dd/yy', today);
		}
		
		fillAlternatives(){
			var self = this;
    		var planPhases = DataManager.getDataManager().get('currentPlan').get('phase');
    		var phaseId = self.selectedPhase().id;
    		for(var j=0;j<planPhases.length;j++){
    			if(planPhases.at(j).id === phaseId){
    				var selPhase = planPhases.at(j);
    				break;
    			}
    		}
    		if(selPhase){
			    var alts = selPhase.get('phaseAlternative');
		        alts.each(function(alt){
		        	self.altList.push({id:alt.get('id'),name:alt.get('name')});
		        });
    		}
		}
		
		fillPhases(){
			var self = this;
			var phases = DataManager.getDataManager().get('currentPlan').get('phase');
			for(var i=0;i<phases.length;i++){
				self.phaseList.push({id:phases.at(i).get('id'),name:phases.at(i).get('name')});
			}
		}
		
		
		
		saveImportSelection () {
			var self = this;
			if(!DataManager.getDataManager().isDiscoverPurchased() && !DataManager.getDataManager().isAdoptPurchased()){
			    window.utils.limitExceeded();
			}else{
                //For Actual Scenario no need to check for limitation
                if(self.isChecked()){
                   var timeStamp = self.isChecked() ? Date.parse(moment()._d):'';
                   self.parentView.startPoint = self.selectedAlt()?self.selectedAlt().id:null;
                   document.getElementById("importCSVFile").click();
                }else{
                    //For normal Scenario checking for limitation
				    DataManager.getDataManager().get('currentPlan').checkScenarioLimit(function(limitexceeded){
					    if(limitexceeded) {
						    bootbox.alert(DataManager.getDataManager().get('localeManager').get("scenarioLimitMsg"));
					    }else{
						    var timeStamp = self.isChecked() ? Date.parse(moment()._d):'';
						    self.parentView.startPoint = self.selectedAlt()?self.selectedAlt().id:null;
						    /*if (window.utils.checkChrome() && chrome.fileSystem) {
							    DataManager.getDataManager().get('currentPlan').importMetricsCSV(timeStamp,self.parentView.startPoint,function(updateObs){
	           					    self.saveDataAfterImport(updateObs);	           
							    });
						    }else{*/
							    document.getElementById("importCSVFile").click();
						    //}
					    }
				    });
                }
			}
		};
		saveDataAfterImport(updateObs){
			var self = this;
			if(updateObs.obs.length>0){
           		for(var i=0;i<updateObs.obs.length;i++){
					self.saveTimestamp(self.model,updateObs.altId,updateObs.obs[i]);
	           }
            }
            if(updateObs.codeContainerList){
            	var codeContainerlist = window.vdmModelView.codeContainersList;
            	if(codeContainerlist){
            		_.each(updateObs.codeContainerList,function(codecont){
            			codeContainerlist.push(codecont);
            		})
            		codeContainerlist = _.uniq(codeContainerlist);
            	}else{
            		window.vdmModelView.codeContainersList = updateObs.codeContainerList;
            	}
            }
           self.addOptions.parentModal.importData=true;
           window.utils.startSpinner('savingImportSpinner', "Saving Scenario...");
		   if(self.cleanModal){
				self.addOptions.parentModal.setResizeHeight(true);
				self.cleanModal();
				window.utils.stopSpinner('savingImportSpinner');
			}
            /*DataManager.getDataManager().saveData({
                success: function () {
					if(self.cleanModal){
						self.addOptions.parentModal.setResizeHeight(true);
						self.cleanModal();
						window.utils.stopSpinner('savingImportSpinner');
					}
                },
                error: function () {
					self.cleanModal();
					window.utils.stopSpinner('savingImportSpinner');
                },
                persist: false
            });*/
		}
		saveTimestamp(model,altId,obsTimeStampId){
    		var new_list = model.get('config')?JSON.parse(model.get('config')):{};
    		if(new_list[''+altId]){
    			new_list[''+altId].push(obsTimeStampId);
    		}else {
    			new_list[''+altId] = [obsTimeStampId];
    		}
    		model.set('config',JSON.stringify(new_list));
    	}
	    afterRenderView() {
			var self = this;
			//$("#timeStamp"+self.encodeId).datetimepicker();
			$('#editDetailsModalContent' + self.encodeId).resize(function () {
				$('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
			})
		};
    	
    	cleanUp(){
    	};
    	
		cleanModal() {
			var self = this;
			self.parentView = null;
			window.cleanDialogModel(self.encodeId,self);		
    	};
		openCSVFile(view,event){
			var self = this;
			var fileObject = event.target.files[0];
			var timeStamp = self.isChecked() ? Date.parse(moment()._d) : '';
			DataManager.getDataManager().get('currentPlan').importMetricsCSV(timeStamp,self.parentView.startPoint,function(updateObs){
           		self.saveDataAfterImport(updateObs);	           
			}, fileObject);
		};
      

    init(model, options){
        var self = this;
		this.ImportDataViewModel = this;
		this.afterRenderView = _.bind(this.afterRenderView,self);
		this.saveImportSelection = _.bind(this.saveImportSelection,this);
		this.openCSVFile = _.bind(this.openCSVFile,this);
		this.model = model;
		if(options){
			this.addOptions = options.addOptions;
		}
		this.parentView = model;
		this.id = ko.observable(options.modalId);
		self.altList = ko.observableArray([]);
		this.selectedPhase=ko.observable();
		this.selectedAlt=ko.observable();
		self.phaseList=ko.observableArray([]);
		//this.timeStamp = ko.observable();
		this.isChecked = ko.observable(false);
		this.enableActuals = ko.observable(true);
		this.encodeId = window.utils.htmlEscape(self.id());
		this.enableComplete = ko.observable(true);
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
			'name'
			, 'description'
			, 'Close'
			, 'Complete'
			, 'Cancel'
			, 'ExportCSV'
			, 'ImportCSV'
			, 'actual'
			, 'SelectStartingPoint'
			, 'selectphase'
			, 'selectalternative'
			, 'importOptions'
		]);
		this.selectedPhase.subscribe(function (val) {
			self.altList.removeAll();
			if (val) {
				self.fillAlternatives();
				self.enableComplete(false);
			} else {
				self.enableComplete(true);
			}
		});
    	this.selectedAlt.subscribe(function (val) {
        if (val) {
            self.enableComplete(true);
        } else {
            self.enableComplete(false);
        }
        });
		this.fillPhases();
    }
	
	static getInstance(model,options){
    	var view = new ImportDataViewModel(model, options);
    	view.init(model, options);
    	return view;
	};
}
path.ImportDataViewModel = ImportDataViewModel;