import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmElement} from './SmmElement'
import {SmmRelationshipMixin} from './SmmRelationshipMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/SmmRelationshipMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,SmmRelationshipMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var SmmRelationship = SmmElement.extend(utils.customExtends({
		relations:SmmRelationshipMixin.getMixinRelations(),
		subModelTypes: {
			smm_CategoryRelationship : 'smm.CategoryRelationship',
			smm_MeasureRelationship : 'smm.MeasureRelationship',
			smm_MeasurementRelationship : 'smm.MeasurementRelationship'
		},
		initialize: function(attributes, options) {
			SmmElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new SmmRelationshipMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	SmmRelationship.abstract =  sp2Enabled;
	SmmRelationship.supported =  !sp2Enabled;
	path.SmmRelationship = SmmRelationship;
	export {SmmRelationship};
//});