import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import { ScenarioMeasurement } from "../transformation/ScenarioMeasurement";
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ValueElementMixin } from './ValueElementMixin'
import { ValueElementContext } from "../vdml/ValueElementContext";
import { Expression } from "../vdml/Expression";
import {MeasurableElementMixin} from './MeasurableElementMixin'
import {VDMLCanvasElementMixin} from './VDMLCanvasElementMixin'
import { ProjectionProfile } from './ProjectionProfile'
import {ScenarioValueConstraints} from '../transformation/ScenarioValueConstraints'
import { Unit } from './Unit'

import { ValueType } from './ValueType'
import { SeasonalFactor } from './SeasonalFactor';
import { PolynomialCoefficient } from './PolynomialCoefficient';
	
var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
export class ValueElement2Mixin{

    static getMixinRelations(){
		let relations = ValueElementMixin.getMixinRelations();
		relations.splice(relations.findIndex(item => item.key === "valueMeasurement"), 1)
        return _.union(relations,[
            {
                type :Backbone.HasMany,
                containingClass:"vdml_ValueElement",
                key:"context",
                relatedModel:"vdml.ValueElementContext",
                reverseRelation: {
                    key:"contextOwner",
                    type :Backbone.HasOne,
                    includeInJSON:"id"
                }
            },
			{
                type :Backbone.HasOne,
                containingClass:"vdml_ValueElement",
                key:"unit",
                relatedModel:"vdml.Unit",
				includeInJSON: Backbone.Model.prototype.idAttribute
            },
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueElement",
				key:"aggregatedToContext",
				relatedModel:"vdml.ValueElementContext",
				includeInJSON: Backbone.Model.prototype.idAttribute,
			},
			{
                type :Backbone.HasOne,
                containingClass:"vdml_ValueElement",
                key:"projectionProfile",
                relatedModel:"vdml.ProjectionProfile",
                reverseRelation: {
                    key:"projectionProfileOwner",
                    type :Backbone.HasOne,
                    includeInJSON:"id"
                }
            }
        ])
    }
	getParent(){
		var container;
		if(!container){
            container = this.get("percentageWeightOwner") ? this.get("percentageWeightOwner") : this.previousAttributes().percentageWeightOwner;
            if(container){
                return container;
            }
        }
		if(!container){
            container = this.get("satisfactionLevelOwner") ? this.get("satisfactionLevelOwner") : this.previousAttributes().satisfactionLevelOwner;
            if(container ){
                return container;
            }
        }
        if(!container){
            container = this.get("recipientOpinionOwner") ? this.get("recipientOpinionOwner") : this.previousAttributes().recipientOpinionOwner;
            if(container){
                return container;
            }
        }
		return this;
	}
	static getProperties(){
		let properties = ValueElementMixin.getProperties();
		properties.push({ name: "valueType", type: "EString", defaultValue: "Atomic", containingClass: "vdml_VdmlElement" });
		properties.push({ name:"isNominal", type:"EBoolean", defaultValue:false, containingClass:"vdml_VdmlElement"});
		return properties;
	}
	static getSubModelTypes(){
		var ret = ValueElementMixin.getSubModelTypes();
        // ret['vdml_ProjectionProfile'] = 'vdml.ProjectionProfile';
		// ret['vdml_PolynomialCoefficient'] = 'vdml.PolynomialCoefficient';
		// ret['vdml_SeasonalFactor'] = 'vdml.SeasonalFactor';
		// ret['vdml_ValueElementContext'] = 'vdml.ValueElementContext';
		return ret;
	}
    static getCumulativeMixinRelations(){
        if (!ValueElement2Mixin.cummulativeRelations) {
            ValueElement2Mixin.cummulativeRelations = _.union(ValueElement2Mixin.getMixinRelations()
                ,MeasurableElementMixin.getCumulativeMixinRelations()
                ,VDMLCanvasElementMixin.getCumulativeMixinRelations()
            );
        }
        return ValueElement2Mixin.cummulativeRelations.slice();
    }

	onDestroyElement(model) {
		//TODO remove context
	}

	getValueContext(altId){
		var contexts = this.get("context").models;
        var context = null;
        for(var i=0;i<contexts.length;i++){
            if(contexts[i].get("alternative") && contexts[i].get("alternative").id == altId){
                context = contexts[i];
                break;
            }
        }
		return context;
	}

	getFromAggregations(altId){
		var self = this;
		var components = [];
		var packAltId = window.utils.getPrefix(self.getNestedParent().id);
		var context = self.getValueContext(altId);
		if(!context && self.get("valueType") == ValueType.Atomic && (packAltId == window.plansKey || altId == packAltId)){
			components = self.get('aggregatedFrom');
		}
		else if(context){
			components = context.get('aggregatedFromValue');
			if(/*(packAltId == window.plansKey || altId == packAltId) &&*/ components && components.length == 0){
				components = self.get('aggregatedFrom');
			}
		}else if(packAltId == window.plansKey || altId != packAltId){
			var alt = window.utils.getElementModel(altId,['transformation.Alternative']);
			var preAlt = null;
			if(alt.getParent().get("primary") == alt){
				preAlt = alt.getPreviousPhasePrimary();
			} else if(alt.getParent().get("master") !== alt){
				preAlt = alt.getParent().get("master");
			}
			if(preAlt){
				return self.getFromAggregations(preAlt.id);
			}
		}
		return components;
	}

	getToAggregations(altId){
		var self = this;
		var components = new Backbone.Collection();
		var alt = window.utils.getElementModel(altId,['transformation.Alternative']);
		var contextColl = self.get('aggregatedToContext').where({'alternative':alt});
		if(!contextColl || contextColl.length == 0){
			components = self.get('aggregatedTo');
		}
		else {
			for(var i=0; i< contextColl.length; i++){
				components.add(contextColl.at(i).getParent());
			}
			var packAltId = window.utils.getPrefix(self.getNestedParent().id);
			if(packAltId == window.plansKey || altId != packAltId){
				var aggto = self.get('aggregatedTo');
				for(var i=0; i< aggto.length; i++){
					if(!aggto.at(i).getValueContext(altId)){
						components.add(aggto.at(i));
					}
				}
			}
		}
		return components;
	}

	getValueFormula(altId){
		var self = this;
		var formula;
		if(self.get('valueType') == ValueType.Atomic){
			return formula;
		}
		var context = self.getValueContext(altId);
		var packAltId = window.utils.getPrefix(self.getNestedParent().id);
        if(context){
            formula = context.get("formula");
        } else if(packAltId == window.plansKey || altId != packAltId){
			var alt = window.utils.getElementModel(altId,['transformation.Alternative']);
			var preAlt = null;
			if(alt.getParent().get("primary") == alt){
				preAlt = alt.getPreviousPhasePrimary();
			} else if(alt.getParent().get("master") !== alt){
				preAlt = alt.getParent().get("master");
			}
			if(preAlt){
				return self.getValueFormula(preAlt.id);
			}
		}
		return formula;
	}
	addSatisfactionBaseTuple(component){
		var self = this;
		var obj = {};
		var baseTuples=[]
        obj.new = { seqNo: 0, id: self.get('id'), component: component, existing: false};
		baseTuples.push(obj);
		for(var i=0;i<baseTuples.length;i++) {
			if(!baseTuples[i].old && baseTuples[i].new) {
				var component = baseTuples[i].new.component;
				self.get('aggregatedFrom').add(component);
				if(component && !component.get('aggregatedTo').findWhere({id:self.get('id')})) {
					component.get('aggregatedTo').add(self);
				}
			}else if(baseTuples[i].old && !baseTuples[i].new){
				var component = baseTuples[i].old.component;
				var compCount = 0;
				for(var j=0;j<baseTuples.length;j++) {
					if(baseTuples[j].old && baseTuples[j].old.component === component && baseTuples[j].new){
						compCount++;
					}
				}
				if(compCount === 0) {
					this.get('aggregatedFrom').remove(component);
					if(component && component.get('aggregatedTo').findWhere({id:self.get('id')})) {
						component.get('aggregatedTo').remove(self);
					}
				}
			}else if(baseTuples[i].old && baseTuples[i].new){
				if(baseTuples[i].old.usePreviousAlternative != baseTuples[i].new.usePreviousAlternative){
					var comp = baseTuples[i].new.component;
					if(comp){
						comp.onAggregatedFromAdded(comp);
					}
				}
			}
		}
		
	};

	createExpression(context, valFormula,config){
		var self = this;
        var expId = DataManager.getDataManager().guidGeneratorByOwner(self);
        var exp = new Expression({id:expId,formulaOwner:context,expressionStr: valFormula,expressionConfig:config});
        context.set("formula",exp);
        return exp;
    }

    createValueElementContext(viewAlternative){
		var self = this;
        var contextId = DataManager.getDataManager().guidGeneratorByOwner(self);
        var context = new ValueElementContext({id:contextId,alternative:viewAlternative,contextOwner:self});
        self.get("context").add(context);
        return context;
    }
	createProjectionProfile(projectionProfile){
		var self = this;
		if(projectionProfile){
            var projectionProfileInstance = ProjectionProfile.getInstance(projectionProfile.trendModel,projectionProfile.rateFactor,projectionProfile.polynomialDegree,self);
            self.set("projectionProfile",projectionProfileInstance);
			// Add seasonal factors
			for(var i = 0; i<projectionProfile.seasonalFactorData.length;i++){
				var quantity = projectionProfile.seasonalFactorData[i].factor;
				let seasonalFactors = SeasonalFactor.getInstance(i+1,quantity,projectionProfileInstance);
				projectionProfileInstance.get('seasonalFactors').add(seasonalFactors);
			}
			//Add polynomial coefficients
			if(projectionProfile.polynomialDegree){
				for(var j = 0; j<projectionProfile.polynomialData.coefficients.length;j++){
					var coefficient = projectionProfile.polynomialData.coefficients[j];
					let polynomialCoefficient = PolynomialCoefficient.getInstance(j+1,coefficient,projectionProfileInstance);
					projectionProfileInstance.get('coefficients').add(polynomialCoefficient);
				}
			}
        }
	}

	createValueConstraints(valueConstraint,alt){
		var self = this
		var scenario = DataManager.getDataManager().get('defaultScenario');
		if(valueConstraint.maxBound!=='' || valueConstraint.minBound!=='' || valueConstraint.equalValue!==''){
			var step = scenario.get("step").findWhere({alternativeId: alt.id});
			var scenarioValueConstraint = ScenarioValueConstraints.getInstance(self.get('id'),valueConstraint.maxBound,valueConstraint.minBound,valueConstraint.equalValue,step);
			step.get('constraints').add(scenarioValueConstraint);
		}
	}
	handleNameChange(name, viewAlternative, changeInValue) {
        var self = this;
        var nameChanged = changeInValue ? changeInValue : false;
        if (self.get('name') !== name) {
            self.set('name', name);
            self.set('description', name);
            nameChanged = true;
        }
        return nameChanged;
    }

	updateValueElementChanges(currentScenario, period, year, name, value, valueUnit, valFormula, baseTuples, timestamp, viewAlternative, maxChange,valueConstraint, callback, skipOtherScenarios, updateOtherScenarios){
		var self = this;
		var plan = DataManager.getDataManager().get("currentPlan");
		var defaultExecutionScenaroId = plan.get("defaultExecutionScenario");
		var defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
		var periodDataset = defaultExecutionScenaro.get("input").findWhere({period: period,year:year,periodKind : plan.get("periodKind")});
		var replaceMeasure = false;
		var calculate = false;
		var changeInValue = false;
		var updateOtherScenarios = updateOtherScenarios?updateOtherScenarios:false;
		var obsMesScenario;
		var mestVM;
		var measureVM;
					
		var calculateSat;
		if(value === '####'){
			value = Number.MAX_VALUE;
		}
		
        changeInValue = handleBaseRelationChange();
        changeInValue = self.handleNameChange(name, viewAlternative, changeInValue);
		
        handleFormulaChange(valFormula, viewAlternative, baseTuples);
		handleConstraintChange(valueConstraint,defaultExecutionScenaro);
		if (!skipOtherScenarios && updateOtherScenarios) {
			callback(obsMesScenario,mestVM,measureVM,calculateSat,changeInValue);			
		}else{			
			callback(obsMesScenario,mestVM,measureVM,calculateSat,changeInValue);
		}


		function handleBaseRelationChange() {
			handleMaxChangeChange();
				//handleValueUnitChange();
				handleQuantityChange(periodDataset);
			if(replaceMeasure){
				calculate = true;
            }
			return calculate;
		}	
		
		/*function handleValueUnitChange(){
			if(measureVM && measureVM.get('unit')){
				var unit = beepPackage.findOrCreateUnit(valueUnit.unitName,valueUnit.unitObj);
				if(measureVM.get('unit').get('name') !== valueUnit.unitName){
					if(obsMesCurrentScenario){
						if(obsMesScenario && self.checkMeasureReuse(measureVM) || matchingMeasureExists()){
							replaceMeasure = true;
						}else{
							measureVM.set('unit',unit);
						}
					}
					rescale = true;
					changeInValue = true;
				}
				var roundingFactor = valueUnit.roundingDigits;
				var significantFactor = valueUnit.significantDecimals;
				if(roundingFactor < 0 || roundingFactor > 20){//can remove when validation in ui exists
					roundingFactor = 4;
				}
				if(significantFactor < 0 || significantFactor > 20){//can remove when validation in ui exists
					significantFactor = 2;
				}
				if(unit.get('roundingDigits') != roundingFactor){
					unit.set('roundingDigits',roundingFactor);
					calculate = true;
					calculateSat = true;
				}
				unit.set('significantDecimals',significantFactor);
			}
		}*/	

		function handleConstraintChange(constraintVal,scenario){
			var scenario = DataManager.getDataManager().get('defaultScenario');
			if(constraintVal.maxBound!==''||constraintVal.minBound!==''||constraintVal.equalValue!==''){
				var step = scenario.get("step").findWhere({alternativeId: viewAlternative.id});
				var existobj = step.get('constraints').findWhere({'valueId':self.id});
				if(existobj){
					existobj.set('maxBound',constraintVal.maxBound);
					existobj.set('minBound',constraintVal.minBound);
					existobj.set('equalValue',constraintVal.equalValue);
				}				
			}
		}
		

        function handleFormulaChange(valFormula, viewAlternative, baseTuples){
			var oldFormula = self.getValueFormula(viewAlternative.id);
			if(valFormula && valFormula.length > 0 && oldFormula != valFormula){
				self.set('valueType',ValueType.Aggregated);
				var contextCreation = false;
				var context = self.getValueContext(viewAlternative.id);
				if(!context){
					context = self.createValueElementContext(viewAlternative);
					contextCreation = true;
				}
				var packAltId = window.utils.getPrefix(self.getNestedParent().id);
				if((packAltId == window.plansKey && !contextCreation) || viewAlternative.id == packAltId){
					self.addBaseTuple(baseTuples);
				} else {
					context.addBaseTuple(baseTuples, contextCreation);
				}
				var exp = context.get("formula");
				if(exp){
					exp.set("expressionStr",valFormula);
				} else {
					exp = self.createExpression(context, valFormula);
				}
			}
		}
		function handleQuantityChange(periodDataset){
			if(periodDataset && self.get("valueType") == ValueType.Atomic){
				var measurement = new ScenarioMeasurement(self,periodDataset);
				measurement.setValue(value);
			}
			var defaultValLib = plan.get('defaultValueLibrary');
			var unit = defaultValLib.get('unit').findWhere({name:valueUnit.unitName});
			if(valueUnit && valueUnit.unitName != "" && !unit){
				unit = Unit.getInstance(valueUnit.unitName,valueUnit.unitName,valueUnit.roundingDigits,valueUnit.significantDecimals,defaultValLib);
				self.set("unit",unit);
			}else if(valueUnit && valueUnit.unitName != "" && unit){
				self.set("unit",unit);
			}
		}	
		function handleMaxChangeChange(){
			var isIterative = self.get('isIterative');
			if(isIterative && self.get('maxChange') !== maxChange){
				self.set('maxChange',maxChange);
				calculate = true;
				calculateSat = true;				
			}
		}		
	};

    primeValueMeasurement(valueMeasurement,measurand,year, period, value,unitToMatch,valFormula,accumulator,baseTuples,timestamp,viewAlternative,maxChange, isRecipient,expression,projectionProfile,valueConstraint){
		var self = this;
		var mcCharacteristic  = self.get('valueDefinition');
		/*if(mcCharacteristic){
			charToMatch = mcCharacteristic.get('name');
		}else{
			charToMatch = isRecipient ? measurand.get('name') + " recipient" : measurand.get('name');
		}*/
		if((baseTuples && baseTuples.length > 0) || (valFormula && valFormula!="")){
			self.set("valueType",ValueType.Aggregated);
		} else {
			self.set("valueType",ValueType.Atomic);
		}
		if(projectionProfile.seasonalFactorData.length > 0 || projectionProfile.trendModel){
			self.createProjectionProfile(projectionProfile);
		}
		if(valueConstraint){
			self.createValueConstraints(valueConstraint,viewAlternative)
		}
		//var currentPackage = self.getNestedParent();
		//var measureLibrary = currentPackage.createDefaultMeasureLibrary();
		var scenario = viewAlternative.getDefaultScenario();
        var measurement = scenario.createMeasurement(self,viewAlternative,year, period, valFormula,unitToMatch,baseTuples);     // 
		//var measurement = scenario.createMeasurement(currentPackage,self,valueMeasurement,null,charToMatch,unitToMatch,valFormula,accToMatch,baseTuples,null,null,timestamp,null,isOVS,viewAlternative);
        if (!self.get('isIterative')) {
			var roundingFactor = unitToMatch.roundingDigits;
			if(value && !isNaN(value)){
				value = parseFloat(value).toFixed(roundingFactor);
			}
			//measurement.set('value',value);	
			if(measurement && year!==undefined){
				measurement.setValue(value);	
			}
		}else{
			self.set('maxChange',maxChange);
			//measurement.get('measuredCharacteristic').getMeasurand().calculate(scenario,timestamp);
		}
		return measurement;
	};
}
utils.customExtendClass (ValueElement2Mixin,new ValueElementMixin());

path.ValueElement2Mixin = ValueElement2Mixin;
