import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
//import {EObject} from '../cmof/EObject'
import {BeepReportMixin} from './BeepReportMixin'
import {BeepPackage} from '../beeppackage/BeepPackage'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { PackageReference } from '../../../com/vbee/filesystem/PackageReference'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/beeppackage/BeepPackage","appbo/report/BeepReportMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BeepPackage,BeepReportMixin
//,PlanPackageReference
//,PackageReference
//){
	
	var path = DataManager.getDataManager().buildAppNsPath("report",global.version);

/*	if(!PackageReference){
		var importPath = DataManager.getDataManager().buildCommonNsPath("com/vbee/filesystem",global.version);
		if(importPath.PackageReference){
			PackageReference = importPath.PackageReference;
		}
		else{
			require(["appcommon/com/vbee/filesystem/PackageReference"],function(loadedModule){
				PackageReference = loadedModule;
			});
		}
	}
	if(!PlanPackageReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.PackageReference){
			PlanPackageReference = importPath.PackageReference;
		}
		else{
			require(["appbo/transformation/PackageReference"],function(loadedModule){
				PlanPackageReference = loadedModule;
			});
		}
	}	
	if(!ScenarioProxy){
		var importPath = DataManager.getDataManager().buildCommonNsPath("transformation",global.version);
		if(importPath.ScenarioProxy){
			ScenarioProxy = importPath.ScenarioProxy;
		}
		else{
			require(["appbo/transformation/ScenarioProxy"],function(loadedModule){
				PackageReference = loadedModule;
			});
		}
	}*/
	
	var BeepReport = BeepPackage.extend(utils.customExtends({
		relations:BeepReportMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BeepPackage.prototype.initialize.apply(this, arguments);
			//this.on("change:name", this.handlePackageNameChange);
		}
		}
		, new BeepReportMixin()
	));
//#startCustomMethods	
    BeepReport["change:name"] = function (model, newName) {
		var previousName = model._previousAttributes ? model._previousAttributes.name:"";
		if(previousName != newName) {
			this.handlePackageNameChange(model, newName);
			var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
			if(suppressChangeLogging) {
				window.setBreadCrumb(model.id, "", window.vdmModelView);
			}
		}
    }
	BeepReport['destroy'] = function (model) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(suppressChangeLogging) {
			utils.updateUIReport(model,true);
		} else {
			var deletedCodedContainers = [];
            _.each(model.get('dependentPackage').models,function(dep){
                if(dep.get('type') == "beeppackage_CodeContainer"){
                    deletedCodedContainers.push(dep.id);
                }
            });
			var EcoMapMixin = Backbone.Relational.store.getObjectByName("ecomap.EcoMapMixin");
			EcoMapMixin.removeImageArtifact(deletedCodedContainers,model,[model.id]);
		}
    }
	BeepReport['change:content'] = function (model) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(suppressChangeLogging) {
			utils.updateUIReport(model,false);
		}
    }
	BeepReport.prototype.getSystemTags = function (options) {
		if (options && options.type == "Discover") {
			return ['Report', 'VDM', 'Discover'];
		} else {
			return ['Report', 'VDM', 'Adopt'];
		}
	};
//#endCustomMethods
	BeepReport.getName = function(){
		return "BeepReport";
	};
	BeepReport.createPackageWithName = function (name, description, currentAlternative, callback, iconType){
        	var currentPlan = DataManager.getDataManager().get('currentPlan');
        	var planId = currentPlan.id.substr(currentPlan.id.lastIndexOf('@'));
        	var commonAltId = planId + "-Common@";
        	DataManager.getDataManager().getWorkspaceDataWithId(commonAltId,function(wsData){
        		var commonWorkspace = wsData.get('workspace');
        		DataManager.getDataManager().setWorkspace(commonWorkspace,function(){
        			createReport();
        		});
        	});
        	function createReport(){
				var docId = window.guidGenerator();
				var type;
				if (iconType === "contextArrow" || iconType === "addDiscovery" || iconType === "ecoGenerate") {
					type = "Discover";
				}
	            var report = new BeepReport({
	                'name':name,
	                'description':description,
	                'id':docId,
					'label': "Base"
				}, { type: type});
	            var pacRef = new PackageReference({beepReference:report,name:report.get('name'),description:report.get('description'),version:report.get('version'),beepType:report.get('type'),label:'Base'});
	            DataManager.getDataManager().get('currentWorkspace').get('packageReference').add(pacRef);
	            //var currentphase = currentAlternative.get('phaseAlternativeOwner');
				/*var pprId = window.guidGenerator();//DataManager.getDataManager().guidGeneratorByOwner(currentphase);
				var PlanPackageReference = Backbone.Relational.store.getObjectByName("transformation.PackageReference");
				var planPacRef = new PlanPackageReference({id:pprId,beepReference:report.get('id'),name:report.get('name'),description:report.get('description'),version:report.get('version'),beepType:report.get('type'),label:'Base',isMain:false,phaseDesignPartOwner:commonpack});
				commonpack.get('phaseDesignPart').add(planPacRef);*/
				DataManager.getDataManager().get('initializedPackages').add({'id':report.get('id'),'version':parseInt(report.get('version')),'model':report});
	            /*if(currentphase.get('primary') === currentAlternative || currentphase.get('master') === currentAlternative) {
	            	report.referPackageToNextPhases(currentAlternative);
	            }*/
				if(callback){
					callback(report);
				}                      	
        	}
	}
	
	function printStackSize(method){
		console.log('method:' + method)
		var i=0;
		function testStack(){
			i++
			testStack();
		}		
		try{
			testStack();
		}catch(e){
			console.log('stak size:' + i)
		}
	}
	BeepReport.abstract = false;
	path.BeepReport = BeepReport;
	//return BeepReport;
	export {BeepReport};
//}