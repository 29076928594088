import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import { ConceptElementMixin } from '../concept/ConceptElementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
/*define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin","appbo/concept/ConceptElement","appbo/concept/ConceptElementMixin"],
function(require,jQuery,_,async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin,ConceptElement,ConceptElementMixin
,BMConceptBlock
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("concept",global.version);
	export class VocabularyMixin {
	defaults(){
		var ret = {
			type: "concept_Vocabulary"
		};
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		]);
	};
	static getCumulativeMixinRelations(){
		if (!VocabularyMixin.cummulativeRelations) {
            VocabularyMixin.cummulativeRelations = _.union(VocabularyMixin.getMixinRelations()
                , ConceptElementMixin.getCumulativeMixinRelations()
            );
        }
		return VocabularyMixin.cummulativeRelations.slice();
	};
	static getSuperTypes(){
		return [
			"concept_ConceptElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "data",type : "EString",defaultValue : "null",containingClass : "concept_Vocabulary" },
			{name : "locale",type : "EString",defaultValue : "null",containingClass : "concept_Vocabulary" },
			{name : "businessContext",type : "EString",defaultValue : "null",containingClass : "concept_Vocabulary" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("vocabularyOwner") ? this.get("vocabularyOwner") : this.previousAttributes().vocabularyQwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("vocabularyOwner") ? this.get("vocabularyOwner") : this.previousAttributes().vocabularyOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')});
				return container.getPackagePath(path);
			}
		}
		return path;
	};
	getViewProperties(type){
		return {
			templatePath : "views/concept/ConceptTemplate.html",
			templateName : "ConceptTemplate",
			viewTypeStr : "appviews/concept/ConceptViewModel",
			tabId : "ConceptView",
			tabName: "Concept"
		}
	};
	getDialogViewProperties(type){
		/*if(type === 'OsterwalderDesigner'){
			return {
				templatePath : "views/concept/views/designer/OsterwalderDesignerTemplate.html",
				templateName : "OsterwalderDesignerTemplate",
				viewTypeStr : "appviews/concept/views/designer/OsterwalderDesignerViewModel",
				tabId : "OsterwalderDesignerView",
				tabName: "OsterwalderDesignerView"
			};
		}*/
	};
	//
	//return VocabularyMixin;
}
path.VocabularyMixin = VocabularyMixin;