import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import {bootbox} from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","appcommon/com/vbee/utils/views/wizard/wizardModel","app/global","appbo/vdml/BusinessModelCompetence","appbo/vdml/CapabilityOffer","appbo/vdml/BusinessItem"],
function(require,$,_, Backbone,ko, koMapping,kb,bootbox,DataManager,WizardModel,global,BusinessModelCompetence
, CapabilityOffer
, BusinessItem
){*/
	var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties",global.version);
	export class BusinessModelCompetenceViewModel{
		setCompetenceTypeOption(){
			var competence = self.model.get('competence');
			var currentCompetenceType = this.competenceType();
			if(!competence || currentCompetenceType){
				return;
			}
			if(competence instanceof BusinessItem){
				this.competenceType(competenceTypeArray[0]);
			}else if(competence instanceof CapabilityOffer){
				this.competenceType(competenceTypeArray[1]);
			}		
		};		
		showDetailedViewBmCompetence(){
			var bo = this.model;
			DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + bo.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + bo.get('version')+ "/" + bo.get('id') + "/vdml.BusinessModelCompetence/" + DataManager.getDataManager().get('currentWorkspace').get('id'), {trigger: true});
		};		
		removeFromCollection(itemsToBeRemoved,collection,removeOnlyRef){
			var items = [];
			for(var i=0;i<itemsToBeRemoved.length;i++)
			{
				for(var j=0;j<collection.length;j++){
					if(collection.at(j).id === itemsToBeRemoved[i]){
						items.push(collection.at(j));
					}
				}
			}
			for(var k=0;k<items.length;k++){
				items[k].lawnchair = DataManager.getDataManager().vdmStore;
				if(removeOnlyRef){
					collection.remove(items[k]);
				}else{
					items[k].destroy();
				}
			}
		};
		handlePublicMenus(){
			var self = this;
			function handleNew(){
				if(!self.wizard){
					self.wizard = WizardModel.getInstance([
					]);
				}
				self.wizard.startWizard();
			}
			DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-plus","New",$.proxy(handleNew,this));
			//DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-remove","Remove",$.proxy(this.removeSelectedItems,this));
		}
		removeSelectedItems(){
		}
		afterRenderView(){
		}
		init(model,options){
			var self = this;
			this.BusinessModelCompetenceViewModel = this;
			this.model = model;
			this.editable = ko.observable(false);
			this.name = kb.observable(model, 'name');
		
			this.id = kb.observable(model, 'id');
			var CompetenceType = function(name,type,typeStr){
				this.name = name;
				this.type = type;
				this.typeStr = typeStr;
			};
			var competenceTypeArray = [new CompetenceType("Resource",BusinessItem,"BusinessItem"),
				new CompetenceType("Capability",CapabilityOffer,"CapabilityOffer"),
				];
			this.competenceTypeOptions = ko.observableArray(competenceTypeArray);
			//this.customerModel = kb.observable(model, 'customer');
			this.competenceType = ko.observable();
			this.competenceTypeName = ko.computed({
				read:function(){
					var type = this.competenceType();
					if(type){
						return type.name;	
					}else{
						return "";
					}			
				},
				write:function(value){;
					if(value === "BusinessItem"){
						self.competenceType(this.competenceTypeOptions()[0]);
					}else if(value === "CapabilityOffer"){
						self.competenceType(this.competenceTypeOptions()[1]);
					}
				},
				owner:self
			});
			this.competenceTypeSubscription = this.competenceType.subscribe(function(newValue){
				if(newValue && !self.model.get('competence')){
					self.competenceTypeSubscription.dispose();
					self.model.createCompetence(newValue.type);
				}
			});
		
			
			this.description = kb.observable(model, 'description');
		
			/*this.competence = kb.observable(model,{
				key: 'competence',
				read: function(){
					self.setCompetenceTypeOption();
					return self.model.get('competence') ? self.model.get('competence').get('name') : "";
				},
				write:function(competence){
					self.setCompetenceTypeOption();
					self.model.set('competence',competence);
				}
			},'competence',this);*/
			this.competence = kb.observable(model.get('competence'),{
				key: null,
				read: function(){
					self.setCompetenceTypeOption();
					return self.model.get('competence') ? self.model.get('competence').get('name') : "";
				},
				write:function(competence){
					self.setCompetenceTypeOption();
					self.model.set('competence',competence);
				}
			},this);		
		
			this.competenceTypeEditable = ko.computed(function(){
				return !this.competence();
			},this);
		
			if(!includeDetails){
				model.once('change:competence',function(){
					if(self.competenceTypeName){
						var competence = self.model.get('competence');
						if(competence instanceof CapabilityOffer){
							self.competenceTypeName(competenceTypeArray[1].typeStr);
						}else if(competence instanceof BusinessItem){
							self.competenceTypeName(competenceTypeArray[0].typeStr);
						}					
					}
				},this);		
			}		
	//#endCustomMethods
		
			this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['general','BusinessModelCompetence'
			,'name'
			,'description'
			,'capability'
			,'Capability'
			,'competence'
			,'Competence'
			,'competenceType']);
	}
	
	static getInstance(model, options){
		var view = new BusinessModelCompetenceViewModel(model, options);
		view.init(model, options);
		return view;
	};
}
path.BusinessModelCompetenceViewModel = BusinessModelCompetenceViewModel;