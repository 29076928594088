import * as _ from 'underscore'

import * as  Backbone from 'backbone'
export class asyncsimple {
	
	itemHandledn(){
		var self = this;
		if(self.lock.isLocked()){
			setTimeout(self.itemHandled.bind(self),5);	
		}else{
			self.lock.acquire();
			self.count--;
			if(self.count === 0){
				self.lock.release();
				self.callback();
			}else{
				self.lock.release();	
			}
		}
	}		
	
	handleAsyncSimple(arr,handler,callback){
		var self = this;
		if(arr.length === 0){
			callback();
			return;
		}
		self.callback = callback;
		self.lock = _.extend({},Backbone.Semaphore);
		self.count = arr.length;
		self.lock.setAvailablePermits(1);
		for(var i=0;i<arr.length;i++){
			handler(arr[i],self.itemHandledn.bind(self));
		}
	};
	static each(arr,handler,callback){
		this.getInstance().handleAsyncSimple(arr,handler,callback);
	};
	static getInstance(){
		return new asyncsimple();
	};
	
}


