import * as _ from 'underscore'
import { ScenarioType } from "../vdml/ScenarioType";
import { ValueElement } from '../vdml/ValueElement';
import { PeriodDataset } from "./PeriodDataset";
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ScenarioMeasurement } from "./ScenarioMeasurement";
import { BeepPackage } from '../beeppackage/BeepPackage';
import { BeepPackageMixin } from '../beeppackage/BeepPackageMixin';
import { ScenarioPathStep } from './ScenarioPathStep';
import { ValueLibrary } from '../vdml/ValueLibrary'
import {Unit} from '../vdml/Unit'
import { PeriodKind } from '../vdml/PeriodKind';
import { ValueType } from '../vdml/ValueType'
import { Expression } from '../vdml/Expression';
import { InstantiationContext } from '../instdef/InstantiationContext';



export class PlanScenarioMixin{
    constructor(){
        var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
        path.PlanScenarioMixin = PlanScenarioMixin;
    }
    /*constructor(id,planId,startPeriod){
        var path = DataManager.getDataManager().buildAppNsPath("dashboard",global.version);
        path.PlanScenarioMixin = PlanScenarioMixin;
        this.id = id;
        this.planId = planId;
        this.startPeriod = startPeriod;
        this.scenarioPathSteps = {};
        this.firstStep = null;
    }*/
    defaults(){
		var ret = {
			type: "transformation_PlanScenario",
			version: DataManager.getDataManager().get('currentVDMVersion')
		}
		return jQuery.extend(BeepPackage.prototype.defaults.apply(this),ret);
	}
    static getMixinRelations(){
		return _.union([
            {
                type: Backbone.HasMany,
                containingClass: "transformation_PlanScenario",
                key: "step",
                relatedModel: "transformation.ScenarioPathStep",
                reverseRelation: {
                    key: "stepOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },
            {
                type: Backbone.HasOne,
                containingClass: "transformation_PlanScenario",
                key: "firstStep",
                relatedModel: "transformation.ScenarioPathStep",
                includeInJSON: "id"
            },
            {
                type: Backbone.HasOne,
                containingClass: "transformation_PlanScenario",
                key: "optimizationObjective",
                relatedModel: "vdml.Expression",
                reverseRelation: {
                    key: "optimizationObjectiveOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },
            {
                type: Backbone.HasMany,
                containingClass: "transformation_PlanScenario",
                key: "executionContext",
                relatedModel: "instdef.InstantiationContext"
            }
        ]);
	};
	static getCumulativeMixinRelations(){
		if (!PlanScenarioMixin.cummulativeRelations) {
            PlanScenarioMixin.cummulativeRelations = _.union(PlanScenarioMixin.getMixinRelations()
                , BeepPackageMixin.getCumulativeMixinRelations()
            );
        }
		return PlanScenarioMixin.cummulativeRelations.slice();
	};
	static getSuperTypes(){
		return [
			"beeppackage_BeepPackage"
		];
	}
	static getProperties(){
		return [
			{name : "version",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "label",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "parentLabel",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "syncedChangeLog",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "synchedChangeSet",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
            {name : "planId",type : "EString",defaultValue : "null",containingClass : "transformation_PlanScenario" },
            {name : "startTime",type : "long",defaultValue : "null",containingClass : "transformation_PlanScenario" },
            {name : "defaultExecutionScenario",type : "EString",defaultValue : "null",containingClass : "transformation_PlanScenario" }
		]
	}

    createExpression(valFormula,config){
		var self = this;
        var expId = DataManager.getDataManager().guidGeneratorByOwner(self);
        var exp = new Expression({id:expId,optimizationObjectiveOwner:self,expressionStr: valFormula,expressionConfig:config});
        self.set("optimizationObjective",exp);
        return exp;
    }

	getParent(){
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return BeepPackage.prototype.getPackagePath.apply(this, arguments);
	}
    createMeasurement(value,viewAlternative, year, period, valFormula,unitToMatch,baseTuples){
		var self = this;
        var defaultExecutionScenaroId = self.get("defaultExecutionScenario");
        var defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
        //TODO load if not loaded
        // var step = this.get("step").findWhere({alternativeId: viewAlternative.id});
        // var periodDataset = null;
        // if(step){
        //     if(period == undefined){
        //         period = step.get("startPeriod");
        //     }
        let plan = Backbone.Relational.store.getObjectByName("transformation.Plan").find({ id: self.get("planId") });
        var defaultValLib = plan.get("defaultValueLibrary");
        if(!defaultValLib) {
            var libName = plan.get('name') + " Library";
            var commomPackId = plan.getCommonRepositoryId() + "-CommonPackage";
            var commonPackage = window.utils.getElementModel(commomPackId,["vdml.ValueDeliveryModel"]);
            defaultValLib = ValueLibrary.getInstance(libName, libName, commonPackage, null);
            plan.set("defaultValueLibrary",defaultValLib);
        }
            
        //     let yearPeriod = utils.calculateYearPeriod(self.get("startTime") , period, plan.get("periodKind") );
        //     if(!periodDataset){
        //         var id = window.guidGenerator();
        //         periodDataset = new PeriodDataset({id:id,year:yearPeriod.year,periodKind:plan.get("periodKind"),period: yearPeriod.period});
        //         defaultExecutionScenaro.get("input").add(periodDataset);
        //     }
        // }
        if(unitToMatch && unitToMatch.unitName!= ""){
            var unit = defaultValLib.get('unit').findWhere({name:unitToMatch.unitName});
            if(!unit){
                unit = Unit.getInstance(unitToMatch.unitName,unitToMatch.unitName,unitToMatch.roundingDigits,unitToMatch.significantDecimals,defaultValLib);
                value.set("unit",unit);
            }else if(unit){
                value.set("unit",unit);
            }
        }
        
        if((valFormula && valFormula.length > 0) || baseTuples.length > 0){
            var contexts = value.get("context").models;
            var context = null;
            for(var i=0;i<contexts.length;i++){
                if(contexts[i].get("alternative").id == viewAlternative.id){
                    context = contexts[i];
                    break;
                }
            }
            if(!context){
                context = value.createValueElementContext(viewAlternative);
                
                var exp = value.createExpression(context, valFormula);
                
                //context.addBaseTuple(baseTuples,viewAlternative);
                //baseTuples
            }
        }
        // if(!periodDataset.get("data")){
        //     periodDataset.set("data" , {});
        // }
        //periodDataset.data.put(value.id,)
        var measurement;
        if(value.get("valueType") == ValueType.Atomic){
            var periodDataset = defaultExecutionScenaro.get("input").findWhere({period: period, periodKind : plan.get("periodKind"), year:year});
            measurement = new ScenarioMeasurement(value,periodDataset);
        }
        
		return measurement;
	};

    getTSLessMeasurementReferringToMeasuredCharteristic(measuredCharacteristic){
        if(!measuredCharacteristic){
			return null;
		}
        var objType = Backbone.Relational.store.getObjectByName("transformation.ScenarioProxy");
        var scenarioProxy = objType._collection.findWhere({scenario:this.id});
        var alt = scenarioProxy.get("designScenarioOwner");
        var currentPlan = DataManager.getDataManager().get("currentPlan");
        //currentPlan.getPlanScenarioFromBackend(timeStamp ? timeStamp : currentPlan.get("defaultScenario"));
        //assume the timestamp scenario is already cached
        var planScenarios = DataManager.getDataManager().get("planScenarios");
        var planScenario = planScenarios.get(timeStamp ? timeStamp : currentPlan.get("defaultScenario"));
        var step = planScenario.get("step").findWhere({alternativeId: alt.id});
        var startPeriod = step.startPeriod;
        var dataset = planScenario.getDefaultExecutionScenarioInput(alt,startPeriod);
        if(dataset){
            //return dataset.get("data")[measuredCharacteristic.id];
            return dataset.get(measuredCharacteristic.id);
        }else{
            return null;
        }	 
	}
    getObservedMeasureWithMeasurements(measuredCharacteristic,withTimeStamp,timeStamp){
		var observationToCheck;
		if(!measuredCharacteristic){
			return null;
		}
        var objType = Backbone.Relational.store.getObjectByName("transformation.ScenarioProxy");
        var scenarioProxy = objType._collection.findWhere({scenario:this.id});
        var alt = scenarioProxy.get("designScenarioOwner");
        var currentPlan = DataManager.getDataManager().get("currentPlan");
        //currentPlan.getPlanScenarioFromBackend(timeStamp ? timeStamp : currentPlan.get("defaultScenario"));
        //assume the timestamp scenario is already cached
        var planScenarios = DataManager.getDataManager().get("planScenarios");
        var planScenario = planScenarios.get(timeStamp ? timeStamp : currentPlan.get("defaultScenario"));
        var step = planScenario.get("step").findWhere({alternativeId: alt.id});
        var startPeriod = step.startPeriod;
        var dataset = planScenario.getDefaultExecutionScenarioInput(alt,startPeriod);
        if(dataset){
            //return dataset.get("data")[measuredCharacteristic.id];
            return dataset.get(measuredCharacteristic.id);
        }else{
            return null;
        }
	}

    getDefaultExecutionScenarioInputByPeriod(period){
        var self = this;
        var defaultExecutionScenaroId = self.get("defaultExecutionScenario");
        var defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
        //TODO load if not loaded
        var periodDataset = null;
        if(period == undefined){
            return null;
        }
        let plan = Backbone.Relational.store.getObjectByName("transformation.Plan").find({ id: self.get("planId") });
        let yearPeriod = utils.calculateYearPeriod(self.get("startTime") , period,plan.get("periodKind") );
        var periodDataset = defaultExecutionScenaro.get("input").findWhere({period: yearPeriod.period,periodKind : plan.get("periodKind"), year: yearPeriod.year});
        
        if(!periodDataset){
            var id = window.guidGenerator();
            periodDataset = new PeriodDataset({id:id,year:yearPeriod.year,periodKind:plan.get("periodKind"),period: yearPeriod.period});
            defaultExecutionScenaro.get("input").add(periodDataset);
        }
        return periodDataset;
    }
    getDefaultExecutionScenarioInput(alternative,period){
        var self = this;
        var defaultExecutionScenaroId = self.get("defaultExecutionScenario");
        var defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
        //TODO load if not loaded
        var step = self.get("step").findWhere({alternativeId: alternative.id});
        var periodDataset = null;
        if(step){
            if(period == undefined){
                period = step.get("startPeriod");
            }
            let plan = Backbone.Relational.store.getObjectByName("transformation.Plan").find({ id: self.get("planId") });
            let yearPeriod = utils.calculateYearPeriod(self.get("startTime") , period,plan.get("periodKind") );
            var periodDataset = defaultExecutionScenaro.get("input").findWhere({period: yearPeriod.period,periodKind : plan.get("periodKind"),year:yearPeriod.year});
            
            if(!periodDataset){
                var id = window.guidGenerator();
                periodDataset = new PeriodDataset({id:id,year:yearPeriod.year,periodKind:plan.get("periodKind"),period: yearPeriod.period});
                defaultExecutionScenaro.get("input").add(periodDataset);
            }
        }
        return periodDataset;
    }

    getPlanDatasets(plan){
		var scenario = this;
		var datasetsArr = []
		var periodKind = plan.get("periodKind");
		var phases = plan.get("phase");
		for (var j = 0; j < phases.length; j++) {
			var alternatives = phases.at(j).get('phaseAlternative');
			for (var i = 0; i < alternatives.length; i++) {
                var step = scenario.get("step").findWhere({alternativeId: alternatives.at(i).id});
                if(!step){
                    continue;
                }
                var periodsLen = step.get('noPeriods');
                var period = step.get("startPeriod");			
                for(var l = 0; l < periodsLen; l++){
                    if(l != 0){
                        period++;
                    }
                    let yearPeriod = utils.calculateYearPeriod(scenario.get("startTime") , period, periodKind );
                    datasetsArr.push({...yearPeriod});
                }
				
			}
		}
		return datasetsArr;
	}
    
    datasetsByPhase(phaseID,periodKind){
		var dataManager = DataManager.getDataManager();
		var phaseDataSets=[]
		var scenarioId = dataManager.get("currentPlan").get("defaultScenario");
		var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
		var step = scenario.get("step").findWhere({phaseId: phaseID});
		var periodsLen = step.get('noPeriods');
		var period = step.get("startPeriod");			
		for(var l = 0; l < periodsLen; l++){
			if(l != 0){
				period++;
			}
			let yearPeriod = utils.calculateYearPeriod(scenario.get("startTime") , period, periodKind );
			phaseDataSets.push(yearPeriod);
		}
		return phaseDataSets
	}

    getCurrentWeek() {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 4 - (currentDate.getDay() || 7));
        const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
        const dayOfYear = ((currentDate - startOfYear) / 86400000) + 1;
        const weekNumber = Math.ceil(dayOfYear / 7);
        return weekNumber;
    }

    getCurrentQuarter() {
        const currentDate = new Date();
    
        const month = currentDate.getMonth();
    
        const quarter = Math.floor(month / 3) + 1;
    
        return quarter;
    }

    getCurrentFourWeeklyPeriod() {
        const currentDate = new Date();
    
        currentDate.setDate(currentDate.getDate() + 4 - (currentDate.getDay() || 7));
    
        const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    
        const dayOfYear = Math.floor((currentDate - startOfYear) / 86400000) + 1;
    
        const weekNumber = Math.ceil(dayOfYear / 7);
    
        const fourWeeklyPeriod = Math.ceil(weekNumber / 4);
    
        return fourWeeklyPeriod;
    }

    fillDefaultPeriods(phaseDataSets,periodKind){
        var self =  this;
	
		var datasets = {}
		
        if(phaseDataSets.length>0){
            var currentDate = new Date()
            var currentYear = currentDate.getFullYear();
		var currentPeriodKind 
        if(PeriodKind.symbols()[2].name ===periodKind){
            currentPeriodKind = currentDate.getMonth()+1;
        }else if(PeriodKind.symbols()[4].name ===periodKind){
            currentPeriodKind = self.getCurrentWeek()
        }else if (PeriodKind.symbols()[1].name ===periodKind){
            currentPeriodKind = self.getCurrentQuarter()
        }else if(PeriodKind.symbols()[3].name ===periodKind){
            currentPeriodKind = self.getCurrentFourWeeklyPeriod()
        }
            const highestYear = isNaN(Math.max(...phaseDataSets.map(obj => obj.year))) ? phaseDataSets[0].year : Math.max(...phaseDataSets.map(obj => obj.year));
            const LowestYear = isNaN(Math.min(...phaseDataSets.map(obj => obj.year))) ? phaseDatasets[0].year : Math.min(...phaseDataSets.map(obj => obj.year));
            const pastYearDatasets = phaseDataSets.filter(d=>d.year<currentYear);
            const futureYearDatasets =  phaseDataSets.filter(d=>d.year>currentYear);
            const highestPeriod = Math.max(...pastYearDatasets.map(obj => obj.period));
            const lowestPeriod = Math.min(...futureYearDatasets.map(obj => obj.period));
            if(highestYear < currentYear){
                datasets.period = highestPeriod
                datasets.year = highestYear
            } else if(highestYear > currentYear && LowestYear > currentYear ){
                datasets.period = lowestPeriod
                datasets.year = highestYear
            }
            else {
                const currentYearDataSets = phaseDataSets.filter(d=>d.year===currentYear);
                datasets.year = currentYear
                var nearestMinMonth = currentYearDataSets.every(d=>d.period<currentPeriodKind);
                var nearestMaxMonth = currentYearDataSets.every(d=>d.period>currentPeriodKind);
                if(nearestMinMonth){
                    const currentYearHighestPeriod = Math.max(...currentYearDataSets.map(obj => obj.period));
                    datasets.period = currentYearHighestPeriod
                }else if(nearestMaxMonth){
                    const currentYearLowestPeriod =  Math.min(...currentYearDataSets.map(obj => obj.period));
                    datasets.period = currentYearLowestPeriod;
                }else {
                    datasets.period = currentPeriodKind
                }
            }
        }else {
            datasets.period = null;
            datasets.year = null
        }
	
		return datasets
	}


    getSortedDataSets(plan){
        //this function will return year and period of datasets in sorted order
		var self = this;
		var datasetsArr = []
		var periodKind = plan.get("periodKind");
        var totalStepLength = self.get("step").length;
        for(var i=0; i < totalStepLength; i++){
            var step = self.get("step").at(i);//.findWhere({startPeriod: i+1});
            if(!step){
                return datasetsArr;
            }
            var periodsLen = step.get('noPeriods');
            var period = step.get("startPeriod");			
            for(var l = 0; l < periodsLen; l++){
                if(l != 0){
                    period++;
                }
                let yearPeriod = utils.calculateYearPeriod(self.get("startTime") , period, periodKind );
                datasetsArr.push({...yearPeriod});
            }
        }
        
		return datasetsArr;
	}


    getValuesContexts(){
        let self = this;
        let step = self.get("firstStep");
        let alternativeContexts = {};
        let seq = 0;
        while(step!= null){
            let alternativeId = step.get("alternativeId");
            let altData = {};
            let alternative = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: alternativeId });
            altData.valueElementContext = alternative.get("valueElementContext");
            altData.id = alternativeId;
            altData.startPeriod = step.get("startPeriod");
            altData.noPeriods = step.get("noPeriods");
            alternativeContexts[seq] = altData;
            step = step.get("next");
            seq++;
        }
        return alternativeContexts;
    }
}