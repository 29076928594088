import * as $ from 'jquery'
import * as _ from 'underscore'
import * as Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import {DataManager} from '../../../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","async","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global","appbo/vdml/ValueDeliveryModel","appbo/vdml/BusinessModel","appbo/tickets/User","appbo/vdml/OrgUnit","typeahead"],
function(require,$,_, Backbone,async,ko, koMapping,kb,bootbox,DataManager,global,ValueDeliveryModel,BusinessModel,User,OrgUnit,typeahead
){*/

var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties",global.version);
var importPath;

export class BusinessDetailsViewModel {
    init(model, options) {
        var self = this;
        this.BusinessDetailsViewModel = this;
        this.model = model;

        function htmlEscape(str) {
            return String(str)
                .replace(/@/g, '')
                .replace(/ /g, '');
        }
        this.id = ko.observable(self.model ? self.model.get('id') : null);
        this.encodeId = htmlEscape(this.id());
        if (options && options.parentView) {
            this.parentView = options.parentView;
            this.encodeId = "" + options.modalId;
        }
        this.options = options;
        this.name = ko.observable(self.model ? self.model.get('name') : '');
        this.description = ko.observable(self.model ? self.model.get('description') : "");
        this.roleDescription = ko.observable("");
        this.rolesArray = [{
            id: '0',
            name: 'My Business Role',
            desc: 'My Business Role',
            originalId: '',
            del: false
        }];
        var roleId = "";
        this.rolesColl = ko.observableArray(this.rolesArray);
        this.businessDetailsRoleOriginalId = "";
        this.orgId = ko.observable();
        this.enableComplete = ko.observable(true);
        this.editRoleName = ko.observable(false);
        this.enableShowBusinesRoleDiv = ko.observable(false);
        this.disallowedNames = [];
        this.disallowedRoleNames = [];
        this.businessTypeahead = new Backbone.Collection();
        this.displaybusinessTypeahead = new Backbone.Collection();
        this.businessTypeaheadRoles = new Backbone.Collection();
        this.displayRoleTypeahead = new Backbone.Collection();
        this.rolesTypeahead = ko.observableArray([]);
        this.modalShown = 0;
        this.editRole = _.bind(self.editRole, self);
        this.deleteRole = _.bind(self.deleteRole, self);
        this.name.subscribe(function(val) {
            self.description(val);
        });
        this.filterBusinessArray = options.addOptions.filterTypeahead;

        this.setOptions = function(options) {
            this.filterBusinessArray = options.filterTypeahead;
        }

        this.fillRolesColl = function() {
            var self =this;
            debugger
            var options = self.options;
            if (options.addOptions && options.addOptions.collRoles) {
                var assignments = options.addOptions.assignments;
                var assignmentColl = assignments[self.model.get("id")];
                var bmRoles = self.parentView.get('bmRole');
                self.rolesColl([]);
                var roleColl = options.addOptions.collRoles;
                if (assignmentColl.models) {
                    for (var x = 0; x < assignmentColl.models.length; x++) {
                        var assignedRole = assignmentColl.models[x].get("assignedRole");
                        if (roleColl.indexOf(assignedRole) !== -1 && options.addOptions.notAttached && bmRoles.indexOf(assignedRole) !== -1) {
                            var id = window.utils.htmlEscape(window.guidGenerator());
                            self.rolesColl.push({
                                id: id,
                                name: assignedRole.get("name"),
                                desc: assignedRole.get("description"),
                                originalId: assignedRole.get("id")
                            });
                        }
                    }
                }
                var removeRoleArray = options.addOptions.removeRole;
                if (removeRoleArray && removeRoleArray.length > 0) {
                    for (var i = 0; i < self.rolesColl().length; i++) {
                        for (var j = 0; j < removeRoleArray.length; j++) {
                            if (removeRoleArray[j].participantId == self.id() && removeRoleArray[j].roleId === self.rolesColl()[i].originalId) {
                                self.rolesColl.destroy(self.rolesColl()[i]);
                                break;
                            }
                        }
                    }
                }
                if (self.rolesColl().length < 1) {
                    self.rolesColl(self.rolesArray);
                }
                self.rolesColl.sort(self.sortRolesByName);
            }
        }
        if (self.model) {
            self.fillRolesColl();
            var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({
                id: DataManager.getDataManager().get('viewAlternative')
            });
            self.disallowedNames = alt.getDisallowedNames(self.model, 'collaboration');
            var bmRole = self.parentView.get('bmRole').findWhere({
                'id': self.rolesColl()[0].originalId
            });
            if (bmRole) {
                self.disallowedRoleNames = alt.getDisallowedNames(bmRole, 'collaborationRole');
            }
        }

        function fillRoleTypeAheadDetails() {
            self.displayRoleTypeahead._reset();
            var roles = options.addOptions.collRoles;
            if (roles) {
                for (var i = 0; i < roles.length; i++) {
                    var rolcond = false;
                    for (var j = 0; j < self.rolesColl().length; j++) {
                        if (self.rolesColl()[j].originalId == roles.at(i).get('id')) {
                            rolcond = true;
                        }
                    }
                    if (!rolcond) {
                        self.businessTypeaheadRoles.push(roles.at(i));
                    }
                }
            }
            for (var i = 0; i < self.businessTypeaheadRoles.length; i++) {
                var displayRolename = self.checkRoleDuplicates(self.businessTypeaheadRoles, self.businessTypeaheadRoles.at(i));
                self.displayRoleTypeahead.push({
                    id: self.businessTypeaheadRoles.at(i).id,
                    name: displayRolename
                });
            }
        }
        fillRoleTypeAheadDetails();

        function checkBusinessTypeahead(orgUnits, colId, orgUnit) {
            var path = orgUnit.get('name');
            for (var i = 0; i < orgUnits.length; i++) {
                if (orgUnits.at(i).get("type") === "vdml_OrgUnit" && orgUnits.at(i).get("id") !== colId && (orgUnits.at(i).get('name') == orgUnit.get('name')) && (orgUnits.at(i).get('id') !== orgUnit.get('id'))) {
                    path = path.concat("  [  ");
                    path = path.concat(orgUnit.getNestedParent().get('name') + "  ]");
                }
            }
            return path;
        }

        function fillTypeAheadDetails() {
            self.displaybusinessTypeahead._reset();
            var orgUnits = options.addOptions.orgUnits;
            var roleColl = options.addOptions.collRoles;
            var colId = roleColl ? roleColl.collaborationRoleOwner.get("id") : null;
            if (orgUnits) {
                for (var i = 0; i < orgUnits.length; i++) {
                    if (orgUnits.at(i).get("type") === "vdml_OrgUnit" && orgUnits.at(i).get("id") !== colId) {
                        self.businessTypeahead.push(orgUnits.at(i));
                        var path = checkBusinessTypeahead(orgUnits, colId, orgUnits.at(i));
                        self.displaybusinessTypeahead.push({
                            id: orgUnits.at(i).id,
                            name: path
                        });
                    }
                }
            }
            for (var j = 0; j < self.filterBusinessArray.length; j++) {
                var orgUsed = self.businessTypeahead.findWhere({
                    id: self.filterBusinessArray[j].id
                });
                if (orgUsed) {
                    self.businessTypeahead.remove(orgUsed);
                }
                orgUsed = self.displaybusinessTypeahead.findWhere({
                    id: self.filterBusinessArray[j].id
                });
                if (orgUsed) {
                    self.displaybusinessTypeahead.remove(orgUsed);
                }
            }
        }
        this.options = options;
        $('#modal' + self.encodeId).on('shown.bs.modal', function() {
            self.businessTypeaheadRoles.reset();
            fillRoleTypeAheadDetails();
            self.businessTypeahead.reset();
            fillTypeAheadDetails();
            $('#name' + self.encodeId).focus();
            self.modalShown++;
            //for modal window close functionality
            $('#name' + self.encodeId).val(self.name());
        });
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), ['name', 'description', 'Role', 'Close', 'Complete', 'businessDetailsForNetwork', 'addAnother']);
    }
    checkRoleDuplicates(models, element) {
        var path = element.get('name');
        for (var i = 0; i < models.length; i++) {
            if ((element.get('name') === models.at(i).get('name') && (element.get('id') !== models.at(i).get('id')))) {
                path = path.concat("   [");
                for (var k = 0; k < element.getPackagePath().length - 1; k++) {
                    path = path.concat(element.getPackagePath()[k].name);
                    if (element.getPackagePath()[k + 2]) {
                        path = path.concat("/");
                    }
                }
                path = path.concat("]");

            }

        }
        return path;
    }

    deleteRole(view, event) {
        var self = this;
        var count = 0;
        var role = self.options.parentView.get('bmRole').findWhere({
            'id': view.originalId
        });

        function doDeleteRole() {
            self.rolesColl.destroy(view);
            for (var i = 0; i < self.rolesColl().length; i++) {
                if (!self.rolesColl()[i]._destroy) {
                    count = count + 1;
                }
            }
            if (count === 0) {
                self.enableComplete(false);
            }
        }
        if (view.originalId !== "" && role && self.options.addOptions.pNModel) {
            var pn = self.options.addOptions.pNModel;
            var vpMessage = '';
            var vfMessage = '';
            var rvpMessage = '';
            var bm = pn.get('participantNetworkOwner');
            var bmVPList = bm.get('bmValueProposition');
            var vpcount = [];
            var rvpcount = [];
            for (var j = 0; j < bmVPList.length; j++) {
                var bmvp = bmVPList.at(j).get('valueProposition');
                if (bmvp && bmvp.get('provider') == role) {
                    vpcount.push(bmVPList.at(j).get('name'));
                } else if (bmvp && bmvp.get('recipient') == role) {
                    rvpcount.push(bmVPList.at(j).get('name'));
                }
            }
            for (var j = 0; j < rvpcount.length; j++) {
                if (j > 0) {
                    rvpMessage = rvpMessage.concat(', ');
                } else {
                    if (rvpcount.length > 1) {
                        rvpMessage = rvpMessage.concat(', receives Value Propositions ');
                    } else {
                        rvpMessage = rvpMessage.concat(', receives Value Proposition ');
                    }
                }
                rvpMessage = rvpMessage.concat('' + rvpcount[j]);
            }
            for (var j = 0; j < vpcount.length; j++) {
                if (j > 0) {
                    vpMessage = vpMessage.concat(', ');
                } else {
                    if (vpcount.length > 1) {
                        vpMessage = vpMessage.concat(', provides Value Propositions ');
                    } else {
                        vpMessage = vpMessage.concat(', provides Value Proposition ');
                    }
                }
                vpMessage = vpMessage.concat('' + vpcount[j]);
            }

            var bmVFList = bm.get('bmValueFormula');
            var vfcount = [];
            for (var j = 0; j < bmVFList.length; j++) {
                if (bmVFList.at(j).get('valueFormula') && bmVFList.at(j).get('valueFormula').get('provider') == role) {
                    vfcount.push(bmVFList.at(j).get('name'));
                }
            }
            for (var j = 0; j < vfcount.length; j++) {
                if (j > 0) {
                    vfMessage = vfMessage.concat(', ');
                } else {
                    if (vfcount.length > 1) {
                        vfMessage = vfMessage.concat(', provides My Propositions ');
                    } else {
                        vfMessage = vfMessage.concat(', provides My Proposition ');
                    }
                }
                vfMessage = vfMessage.concat('' + vfcount[j]);
            }
            var actMessage = '';
            var actCount = [];
            for (var i = 0; i < role.get('performedWork').length; i++) {
                actCount.push(role.get('performedWork').at(i).get('name'));
            }
            for (var j = 0; j < actCount.length; j++) {
                if (j > 0) {
                    actMessage = actMessage.concat(', ');
                } else {
                    if (actCount.length > 1) {
                        actMessage = actMessage.concat(', and performs Activities ');
                    } else {
                        actMessage = actMessage.concat(', and performs Activity ');
                    }
                }
                actMessage = actMessage.concat('' + actCount[j]);
            }
            var message = '';
            if (actMessage.length > 0 || vpMessage.length > 0 || vfMessage.length > 0 || rvpMessage.length > 0) {
                message = message.concat('Role ' + role.get('name') + ' of Participant ' + self.name());
                message = message.concat('' + vpMessage);
                message = message.concat('' + rvpMessage);
                message = message.concat('' + vfMessage);
                message = message.concat('' + actMessage);
                message = message.concat('. ' + DataManager.getDataManager().get('localeManager').get('ConfirmMessage'));
            }
            if (message.length > 0) {
                bootbox.confirm('' + message, function(result) {
                    if (result) {
                        doDeleteRole();
                    }
                });
            } else {
                doDeleteRole();
            }
        } else {
            doDeleteRole();
        }
    }

    cleanModal() {
        var self = this;
        if (self.modalShown == 1) {
            return self.options.callback(this, self.encodeId, 'business', true);
        }
    }

    saveEditRole() {
        var self = this;
        var roleName = $('#editRoleName' + self.encodeId).val().trim();
        //var existing = businessDetailsRoleOriginalId?true:false;
        if ($('#roleMessage' + self.encodeId).text().length == 0 && roleName != '') {
            var roleDescription = $('#editRoleName' + self.encodeId).val();
            //var roleDescription = $('#roleDescription'+self.encodeId).val();
            $('#businessRolesName' + self.roleId).text(roleName);
            var match = _.filter(self.rolesColl(), function(obj) {
                return obj.id === self.roleId;
            });
            match[0].name = roleName;
            match[0].desc = roleDescription;
            //				self.rolesColl()[self.roleId].name=roleName;
            //				self.rolesColl()[self.roleId].desc=roleDescription;			
            $("#cancelBusinessRoles" + self.encodeId).hide();
            $("#saveBusinessEditedRoles" + self.encodeId).hide();
            $("#addBusinessRoles" + self.encodeId).show();
            $('#editRoleName' + self.encodeId).val("");
            $('#roleName' + self.encodeId).val("");
            $('#roleDescription' + self.encodeId).val("");
            self.editRoleName(false);
            self.roleId = "";
            self.enableShowBusinesRoleDiv(false);
            if (self.rolesColl().length > 0 && $('#message' + self.encodeId).html().length == 0 && $('#name' + self.encodeId).val() !== "") {
                self.enableComplete(true);
            }
            //fillRoleTypeAheadDetails();
        }
    }

    /*this.cancelRole = function(){
			$("#cancelBusinessRoles"+self.encodeId).hide();
	        $("#saveBusinessEditedRoles"+self.encodeId).hide();
	        $("#addBusinessRoles"+self.encodeId).show();
	        self.roleId="";
	        $('#roleName'+self.encodeId).val("");
	        $('#roleDescription'+self.encodeId).val("");
	        self.editRoleName(false);
		}*/

    editRole(view, event) {
        var self = this;
        self.enableShowBusinesRoleDiv(true);
        $('#roleMessage' + self.encodeId).text("");
        self.roleId = view.id;
        self.editRoleName(true);
        self.roleDescription("");
        $('#editRoleName' + self.encodeId).val(view.name);
        $('#roleName' + self.encodeId).val(view.name);
        if (view.desc && (view.desc) !== "") {
            $('#roleDescription' + self.encodeId).val(view.desc);
            self.roleDescription(view.desc);
        } else {
            $('#roleDescription' + self.encodeId).val(view.name);
        }
        $("#cancelBusinessRoles" + self.encodeId).show();
        $("#saveBusinessEditedRoles" + self.encodeId).show();
        $("#addBusinessRoles" + self.encodeId).hide();
        $('#editRoleName' + self.encodeId).focus();
    }
    checkRoleExist(roleName, existing) {
        var self = this;
        var count = 0;
        for (var i = 0; i < self.rolesColl().length; i++) {
            if (!self.rolesColl()[i]._destroy && self.rolesColl()[i].name === roleName && self.rolesColl()[i].id !== self.roleId) {
                count++;
            }
        }
        for (var i = 0; i < self.businessTypeaheadRoles.length; i++) {
            if (self.businessTypeaheadRoles.at(i).get('name') === roleName) {
                count++;
            }
        }
        if (self.model && existing && self.disallowedRoleNames.includes(roleName)) {
            return false;
        }
        if (count > 0) {
            return false;
        }
        return true;
    }
    addRole() {
        var self = this;
        var roleName = $('#roleName' + self.encodeId).val();
        var roleDescription = $('#roleName' + self.encodeId).val();
        //var roleDescription = $('#roleDescription'+self.encodeId).val();
        var existing = self.businessDetailsRoleOriginalId ? true : false;
        if ($('#roleMessage' + self.encodeId).text().length == 0) {
            var id = window.utils.htmlEscape(window.guidGenerator());
            self.rolesColl.push({
                id: id,
                name: roleName,
                desc: roleDescription,
                originalId: self.businessDetailsRoleOriginalId,
                existing: existing
            });
            self.businessTypeaheadRoles.remove(self.businessDetailsRoleOriginalId);
            self.displayRoleTypeahead.remove(self.businessDetailsRoleOriginalId);
            $('#roleName' + self.encodeId).val("");
            $('#roleDescription' + self.encodeId).val("");
            if (self.name() !== "") {
                self.enableComplete(true);
            }
            self.enableShowBusinesRoleDiv(false);
            self.roleDescription("");
            self.rolesColl.sort(self.sortRolesByName);
        }

    };

    sortRolesByName(left, right) {
        return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
    }
    saveBusinessDetails() {
        var self = this;
        if (self.parentView) {
            if (self.name() !== null && self.name() !== "") {
                return self.options.callback(this, self.encodeId, 'business');
            }
        }
    };

    saveData(bm, participantNetwork, assignments, participantCollection, removeRoleArray, revision, alt, callback) {
        var self = this;
        if (self.model && revision) {
            self.model = bm.get('business');
        }
        return self.createBusinessDetails(bm, participantNetwork, assignments, participantCollection, removeRoleArray, revision, alt, callback);
    };

    removeOldBusinessPNAssociations(refarray, oldOrgUnit, assignments, bm) {
        var self = this;
        var pnList = bm.get('participantNetwork');
        var bmRoles = bm.get('bmRole');
        for (var i = 0; i < pnList.length; i++) {
            var roleColl = pnList.at(i).get('collaboration').get('collaborationRole');
            if (oldOrgUnit) {
                var assignmentColl = assignments[oldOrgUnit.get("id")];
                if (assignmentColl.models) {
                    for (var x = 0; x < assignmentColl.models.length; x++) {
                        var assignedRole = assignmentColl.models[x].get("assignedRole");
                        var roleExists = bmRoles.findWhere({
                            id: assignedRole.get('id')
                        });
                        if (roleExists && roleColl.indexOf(assignedRole) !== -1) {
                            refarray.push(assignedRole.get("id"));
                            refarray.push(assignmentColl.at(x).get("id"));
                            bm.get('bmRole').remove(assignedRole);
                        }
                    }
                }
            }
        }
    };

    setNewBuisnessPNAssociations(assignments, bm, participantNetwork, participantCollection, alt) {
        var pnList = bm.get('participantNetwork');
        for (var j = 0; j < participantCollection().length; j++) {
            if (participantCollection()[j].attached && participantCollection()[j].originalId !== participantNetwork.get('collaboration').get('id')) {
                var pnModel = null;
                for (var k = 0; k < participantCollection()[j].businessColl[0].roles.length; k++) {
                    var roleName = participantCollection()[j].businessColl[0].roles[k].name;
                    for (var i = 0; i < pnList.length; i++) {
                        if (pnList.at(i).get('collaboration').get('id') === participantCollection()[j].originalId) {
                            pnModel = pnList.at(i);
                            break;
                        }
                    }
                    bm.addNewRoleToBusiness(self.model, roleName, roleName, pnModel, alt, function(newAssignment) {
                        assignments[self.model.get("id")].push(newAssignment);
                    });

                }
            }
        }

    };

    reassignBusiness(bm, orgUnitModal, participantNetwork, assignments, participantCollection, alt) {
        var self = this;
        var oldOrgUnit = bm.get("business");
        orgUnitModal = self.businessTypeahead.findWhere({
            id: self.orgId()
        });
        var refarray = [];
        self.removeOldBusinessPNAssociations(refarray, oldOrgUnit, assignments, bm);
        if (oldOrgUnit) {
            bm.removeExistingBusiness(oldOrgUnit, assignments, participantNetwork, refarray);
        }
        bm.set("business", orgUnitModal);
        self.model = orgUnitModal;
        if (!assignments[self.model.get("id")]) {
            assignments[self.model.get("id")] = new Backbone.Collection;
        }
        self.setNewBuisnessPNAssociations(assignments, bm, participantNetwork, participantCollection, alt);
        self.businessNameChange(participantCollection);
    };

    createBusinessDetails(bm, participantNetwork, assignments, participantCollection, removeRoleArray, revision, alt, callback) {
        var self = this;
        if (self.orgId()) {
            var orgUnitModal;
            self.reassignBusiness(bm, orgUnitModal, participantNetwork, assignments, participantCollection, alt);
        } else {
            if (!self.model) {
                var vdmPackage = bm.getNestedParent();
                var defaultBusiness = vdmPackage.createDefaultBusiness(bm, self.name().trim());
                /*var orgId = DataManager.getDataManager().guidGeneratorByOwner(vdmPackage);
					var defaultBusiness = new OrgUnit({id:orgId,name:self.name(),description:self.description(),collaborationOwner:vdmPackage});
                    bm.set('business',defaultBusiness);*/
                self.model = defaultBusiness;
                if (!assignments[self.model.get("id")]) {
                    assignments[self.model.get("id")] = new Backbone.Collection;
                }
            } else {
                if (this.name().trim() !== self.model.get('name')) {
                    self.businessNameChange(participantCollection);
                }
                self.model.set('name', this.name().trim());
                self.model.set('description', this.description().trim());
            }
        }
        self.saveBusinessRoles(bm, participantNetwork, assignments, removeRoleArray, revision, alt, function(businessJson) {
            callback(businessJson);
        });
    }

    saveBusinessRoles(bm, participantNetwork, assignments, removeRoleArray, revision, alt, callback) {
        var self = this;
        var busRoleColl = [];
        var createNewRoles = [];
        var roleDestroyArray = [];
        if (revision) {
            for (var i = 0; i < self.rolesColl().length; i++) {
                if (self.rolesColl()[i].originalId !== "") {
                    var rollSuffix = window.utils.getSuffix(self.rolesColl()[i].originalId);
                    self.rolesColl()[i].originalId = '' + DataManager.getDataManager().get('viewAlternative') + '' + rollSuffix;
                }
            }
        }
        for (var i = 0; i < self.rolesColl().length; i++) {
            if (self.rolesColl()[i].originalId !== "" && !self.rolesColl()[i]._destroy) {
                var role = participantNetwork.get('collaboration').get('collaborationRole').findWhere({
                    'id': self.rolesColl()[i].originalId
                });
                if (role) {
                    role.set('name', self.rolesColl()[i].name);
                    role.set('description', self.rolesColl()[i].desc);
                    if (self.rolesColl()[i].existing) {
                        var changedNewAssignment = bm.addExistingRoleToBusiness(self.model, role, participantNetwork);
                        if (assignments[self.model.get("id")]) {
                            assignments[self.model.get("id")].push(changedNewAssignment);
                        }
                    }
                    busRoleColl.push({
                        name: role.get("name"),
                        originalId: role.get("id")
                    });
                }
            } else {
                if (self.rolesColl()[i]._destroy && self.rolesColl()[i].originalId !== "") {
                    var assignmentColl = assignments[self.model.get("id")];
                    if (assignmentColl.models) {
                        for (var x = 0; x < assignmentColl.models.length; x++) {
                            var assignedRole = assignmentColl.models[x].get("assignedRole");
                            if (self.rolesColl()[i].originalId === assignedRole.get("id")) {
                                roleDestroyArray.push(assignedRole);
                                //bm.removeExistingRole(self.model,assignedRole,assignmentColl.models[x],assignments);
                            }
                        }
                    }
                } else {
                    if (!self.rolesColl()[i]._destroy) {
                        createNewRoles.push(self.rolesColl()[i]);
                        /*var newAssignment = bm.addNewRoleToBusiness(self.model,self.rolesColl()[i].name,self.rolesColl()[i].desc,participantNetwork);
                        var newRole = newAssignment.get("assignedRole");
                        busRoleColl.push({name:newRole.get("name"),originalId:newRole.get("id")});
                        if(assignments[self.model.get("id")]){
                        	assignments[self.model.get("id")].push(newAssignment);
                        }*/
                    }
                }
            }
        }
        async.eachSeries(createNewRoles, function(role, roleCallback) {
            bm.addNewRoleToBusiness(self.model, role.name, role.desc, participantNetwork, alt, function(newAssignment) {
                var newRole = newAssignment.get("assignedRole");
                busRoleColl.push({
                    name: newRole.get("name"),
                    originalId: newRole.get("id")
                });
                if (assignments[self.model.get("id")]) {
                    assignments[self.model.get("id")].push(newAssignment);
                }
                roleCallback();
            });
        }, function(err) {
            for (var j = 0; j < roleDestroyArray.length; j++) {
                var roleDelete = roleDestroyArray[j];
                var roleDeleteAssignment = roleDestroyArray[j].get('roleAssignment').at(0);
                var deleteRole = true;
                for (var k = 0; k < removeRoleArray.length; k++) {
                    if (removeRoleArray[k].roleId == roleDelete.get('id') && removeRoleArray[k].participantId == roleDeleteAssignment.get('participant').get('id')) {
                        deleteRole = false;
                        break;
                    }
                }
                if (deleteRole) {
                    bm.removeExistingRole(self.model, roleDelete, roleDeleteAssignment, assignments);
                }
            }
            var businessJson = {
                modalId: self.encodeId,
                name: self.name(),
                originalId: self.model.get("id"),
                roles: busRoleColl
            };
            callback(businessJson);
        });
    }

    businessNameChange(participantCollection) {
        var self = this;
        var newCollection = [];
        var len = participantCollection().length;
        while (len--) {
            if (participantCollection()[len].attached) {
                var obj = participantCollection()[len];
                if (obj.businessColl[0]) {
                    obj.businessColl[0].name = self.name();
                    obj.businessColl[0].originalId = self.model.get("id");
                    participantCollection.splice(len, 1);
                    newCollection.push(obj);
                }
            }
        }
        for (var k = 0; k < newCollection.length; k++) {
            participantCollection.push(newCollection[k]);
        }
    }

    getUniqueValue(val) {
        var self = this;
        if (self.options.addOptions) {
            var participantList = new Backbone.Collection();
            var nestedpack = self.parentView.getNestedParent();
            var colls = nestedpack.get('collaboration');
            for (var i = 0; i < colls.length; i++) {
                if (colls.at(i).get('type') === 'vdml_OrgUnit') {
                    participantList.add(colls.at(i));
                }
            }
            if (self.model && self.model.get('name') == val) {
                return false;
            }
            var unique = window.isUniqueName(val, self.filterBusinessArray, window.utils.htmlEscape(self.id()), participantList);
            if (unique && self.model && self.disallowedNames.includes(val.trim())) {
                $('#message' + self.encodeId).text("" + DataManager.getDataManager().get('localeManager').get('ParticipantNameDuplicate'));
                unique = false;
                bmNameExists = true;
            }
            if (unique) {
                $('#message' + self.encodeId).text("");
                if (val !== "") {
                    self.matchField[0].innerHTML = 'New';
                    self.matchField.show();
                } else {
                    self.matchField.hide();
                }
                return false;
            }
            var bmNameExists = false;
            var bmCustomers = self.parentView.get('bmCustomer');
            for (var i = 0; i < bmCustomers.length; i++) {
                if (bmCustomers.at(i).get('customer').get('name') === val && bmCustomers.at(i).get('customer').get('type') === 'vdml_OrgUnit') {
                    bmNameExists = true;
                    break;
                }
            }
            if (!bmNameExists) {
                var bmpartners = self.parentView.get('bmNetworkPartner');
                for (var i = 0; i < bmpartners.length; i++) {
                    if (bmpartners.at(i).get('networkPartner').get('name') === val && bmpartners.at(i).get('networkPartner').get('type') === 'vdml_OrgUnit') {
                        bmNameExists = true;
                        break;
                    }
                }
            }
            if (bmNameExists) {
                $('#message' + self.encodeId).text("" + DataManager.getDataManager().get('localeManager').get('ParticipantNameDuplicate'));
            } else {
                $('#message' + self.encodeId).text("" + DataManager.getDataManager().get('localeManager').get('ParticipantDuplicate'));
            }
            self.matchField[0].innerHTML = 'Match';
            self.matchField.show();
            return true;
        }
    }
    showBusinessRoleDiv() {
        var self = this;
        self.enableShowBusinesRoleDiv(true);
        $("#roleName" + self.encodeId).focus();
    }
    afterRenderView(node, view) {
        var self = view;
        $('.match').hide();
        if (!self.description()) {
            self.description(self.name());
        }
        $('#editDetailsModalContent' + self.encodeId).resize(function() {
            $('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
        })
        var ExtendedroleName = Backbone.Typeahead.extend({
            template: '<input type="text" style="width:170px" id="roleName' + self.encodeId + '" placeholder="Role Name" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        $("#cancelBusinessRoles" + self.encodeId).hide();
        $("#saveBusinessEditedRoles" + self.encodeId).hide();
        var roleNametypeahead = new ExtendedroleName({
            collection: self.displayRoleTypeahead
        });
        roleNametypeahead.setElement('#bmbusinessRoleNameDiv' + self.encodeId).render();
        roleNametypeahead.on('selected', function(fetchmodel) {
            $('#roleMessage' + self.encodeId).text("");
            var tempmodel = self.businessTypeaheadRoles.findWhere({
                id: fetchmodel.get('id')
            });
            var rolesAssignment = tempmodel.get('roleAssignment');
            var currentOrgId = self.orgId() ? self.orgId() : self.id();
            if (tempmodel.get('roleAssignment').length > 0) {
                var participant = rolesAssignment.at(0).get('participant');
                if (participant.get('id') === currentOrgId) {
                    self.businessDetailsRoleOriginalId = tempmodel.get("id");
                    self.typeaheadRoleName = tempmodel.get("name");
                    $('#roleName' + self.encodeId).val(tempmodel.get("name"));
                    if (tempmodel.get("desc") && tempmodel.get("desc") !== "") {
                        $('#roleDescription' + self.encodeId).val(tempmodel.get("desc"));
                        self.roleDescription(tempmodel.get("desc"));
                    } else {
                        $('#roleDescription' + self.encodeId).val(tempmodel.get("name"));
                    }
                    if ($('#name' + self.encodeId).val() !== "" && $('#message' + self.encodeId).text().length == 0) {
                        self.enableComplete(true);
                    }
                } else {
                    var message = '';
                    var blockRole = false;
                    participant.getBusinessModelsInvolved(function(bms) {
                        for (var i = 0; i < bms.length; i++) {
                            var bm = bms[i];
                            if (bm.get('bmRole').findWhere({
                                    id: tempmodel.get('id')
                                })) {
                                var bmMessage = '';
                                var enableVpMessage = false;
                                var enableVfMessage = false;
                                var enableActivityMessage = false;
                                var enableComptenencyMessage = false;
                                var participantMessage = '';
                                var vpMessage = '';
                                var vfMessage = '';
                                var activityMessage = '';
                                var comptenencyMessage = '';
                                participantMessage = participantMessage.concat('Role ' + tempmodel.get('name') + ', as assigned to ' + participant.get('name'));

                                var bmVPList = bm.get('bmValueProposition');
                                var vpcount = 0;
                                for (var j = 0; j < bmVPList.length; j++) {
                                    if (bmVPList.at(j).get('valueProposition') && bmVPList.at(j).get('valueProposition').get('provider') == tempmodel) {
                                        if (vpcount > 0) {
                                            vpMessage = vpMessage.concat(', ');
                                        } else {
                                            vpMessage = vpMessage.concat(', provides Value Proposition ');
                                        }
                                        vpMessage = vpMessage.concat('' + bmVPList.at(j).get('name'));
                                        enableVpMessage = true;
                                        vpcount++;
                                    }
                                }

                                var bmVFList = bm.get('bmValueFormula');
                                var vfcount = 0;
                                for (var j = 0; j < bmVFList.length; j++) {
                                    if (bmVFList.at(j).get('valueFormula') && bmVFList.at(j).get('valueFormula').get('provider') == tempmodel) {
                                        if (vfcount > 0) {
                                            blockRole = true;
                                            vfMessage = vfMessage.concat(', ');
                                        } else {
                                            vfMessage = vfMessage.concat(', provides My Proposition ');
                                        }
                                        vfMessage = vfMessage.concat('' + bmVFList.at(j).get('name'));
                                        enableVfMessage = true;
                                        vfcount++;
                                    }
                                }

                                var bmActList = bm.get('bmActivity');
                                var actcount = 0;
                                var compcount = 0;
                                for (var j = 0; j < bmActList.length; j++) {
                                    if (bmActList.at(j).get('activity') && bmActList.at(j).get('activity').get('performingRole') == tempmodel) {
                                        if (actcount > 0) {
                                            activityMessage = activityMessage.concat(', ');
                                        } else {
                                            activityMessage = activityMessage.concat(' performs Activity ');
                                        }
                                        activityMessage = activityMessage.concat('' + bmActList.at(j).get('name'));
                                        enableActivityMessage = true;
                                        var competences = bmActList.at(j).get('activity').getActivityCompetences();
                                        if (competences.length > 0) {
                                            blockRole = true;
                                            var bmCompList = bm.get('bmCompetence');
                                            if (bmCompList.length > 0 && (comptenencyMessage.indexOf('Competency') == -1 && comptenencyMessage.indexOf('Competencies') == -1)) {
                                                if (blockRole) {
                                                    comptenencyMessage = comptenencyMessage.concat(' and also');
                                                } else {
                                                    comptenencyMessage = comptenencyMessage.concat(' using');
                                                }
                                                if (bmCompList.length > 1) {
                                                    comptenencyMessage = comptenencyMessage.concat(' Competencies ');
                                                } else {
                                                    comptenencyMessage = comptenencyMessage.concat(' Competency ');
                                                }
                                            }
                                            for (var n = 0; n < competences.length; n++) {
                                                if (bmCompList.findWhere({
                                                        'competence': competences[n]
                                                    })) {
                                                    if (compcount > 0) {
                                                        comptenencyMessage = comptenencyMessage.concat(', ');
                                                    }
                                                    comptenencyMessage = comptenencyMessage.concat('' + competences[n].get('name'));
                                                    enableComptenencyMessage = true;
                                                    compcount++;
                                                }
                                            }
                                        }
                                        actcount++;
                                    }
                                }
                                bmMessage = bmMessage.concat('' + participantMessage);
                                if (enableVpMessage && !blockRole) {
                                    bmMessage = bmMessage.concat('' + vpMessage);
                                }
                                if (enableVfMessage) {
                                    bmMessage = bmMessage.concat('' + vfMessage);
                                }
                                if (enableActivityMessage && !blockRole) {
                                    bmMessage = bmMessage.concat('' + activityMessage);
                                }
                                if (enableComptenencyMessage) {
                                    bmMessage = bmMessage.concat('' + comptenencyMessage);
                                }
                                if (!enableVpMessage && !enableVfMessage && !enableActivityMessage && !enableComptenencyMessage) {
                                    bmMessage = 'Role ' + tempmodel.get('name') + ', is assigned to ' + participant.get('name');
                                }
                                bmMessage = bmMessage.concat(' in Business Model ' + bm.get('name'));
                                message = message.concat('' + bmMessage + '<br/>');
                            }

                        }

                        if (blockRole) {
                            bootbox.alert('' + message);
                            $('#roleName' + self.encodeId).val('');
                            $('#roleDescription' + self.encodeId).val('');
                        } else {
                            bootbox.confirm('' + message, function(result) {
                                if (result) {
                                    self.businessDetailsRoleOriginalId = tempmodel.get("id");
                                    self.typeaheadRoleName = tempmodel.get("name");
                                    $('#roleName' + self.encodeId).val(tempmodel.get("name"));
                                    if (tempmodel.get("desc") && tempmodel.get("desc") !== '') {
                                        $('#roleDescription' + self.encodeId).val(tempmodel.get("desc"));
                                    } else {
                                        $('#roleDescription' + self.encodeId).val(tempmodel.get("name"));
                                    }
                                    if ($('#name' + self.encodeId).val() !== "" && $('#message' + self.encodeId).text().length == 0) {
                                        self.enableComplete(true);
                                    }
                                } else {
                                    $('#roleName' + self.encodeId).val('');
                                    $('#roleDescription' + self.encodeId).val('');
                                    self.roleDescription('');
                                    self.matchField && self.matchField.hide();
                                    if (window.utils.getObservableLength(self.rolesColl()) == 0) {
                                        self.enableComplete(false);
                                    }
                                }
                            });
                        }

                    });
                }
            } else {
                self.businessDetailsRoleOriginalId = tempmodel.get("id");
                $('#roleName' + self.encodeId).val(tempmodel.get("name"));
                if (tempmodel.get("desc") && tempmodel.get("desc") !== "") {
                    $('#roleDescription' + self.encodeId).val(tempmodel.get("desc"));
                } else {
                    $('#roleDescription' + self.encodeId).val(tempmodel.get("name"));
                }
                if ($('#name' + self.encodeId).val() !== "" && $('#message' + self.encodeId).text().length == 0) {
                    self.enableComplete(true);
                }
            }
        });
        $("#roleName" + self.encodeId).change(function(view) {
            var newValue = view.currentTarget.value;
            $('#roleDescription' + self.encodeId).val(newValue);
            self.roleDescription(newValue);
            if (self.typeaheadRoleName !== newValue && newValue !== '') {
                self.businessDetailsRoleOriginalId = "";
            }
        });
        $("#editRoleName" + self.encodeId).change(function(view) {
            var newValue = view.currentTarget.value;
            $('#roleDescription' + self.encodeId).val(newValue);
        });
        $("#editRoleName" + self.encodeId).keyup(function(view) {
            var newValue = view.currentTarget.value;
            if (view.currentTarget.value.length > 0) {
                if (!self.checkRoleExist(view.currentTarget.value.trim(), true)) {
                    $('#roleMessage' + self.encodeId).text("" + DataManager.getDataManager().get('localeManager').get('RoleDuplicate'));
                    self.enableShowBusinesRoleDiv(true);
                    self.enableComplete(false);
                } else {
                    $('#roleMessage' + self.encodeId).text("");
                    if (view.currentTarget.value.length > 0 && $('#name' + self.encodeId).val() !== "" && $('#message' + self.encodeId).html().length == 0) {
                        self.enableComplete(true);
                    }
                }
            }
        });
        $("#roleName" + self.encodeId).keyup(function(view) {
            if (view.currentTarget.value.length > 0 && $('#name' + self.encodeId).val() !== "" && $('#message' + self.encodeId).html().length == 0) {
                self.enableComplete(true);
            }
            if (view.currentTarget.value.length > 0) {
                if (!self.checkRoleExist(view.currentTarget.value.trim(), false)) {
                    $('#roleMessage' + self.encodeId).text("" + DataManager.getDataManager().get('localeManager').get('RoleDuplicate'));
                    self.enableComplete(false);
                    self.enableShowBusinesRoleDiv(true);
                } else {
                    $('#roleMessage' + self.encodeId).text("");
                }
            } else {
                if (window.utils.getObservableLength(self.rolesColl()) == 0 || $('#name' + self.encodeId).val() == "") {
                    self.enableComplete(false);
                }
            }
        });
        $("#roleDescription" + self.encodeId).keyup(function(view) {
            var newValue = view.currentTarget.value;
            self.roleDescription(newValue);
        });
        attachMouseDown();

        function attachMouseDown() {
            var eleID = ('modal' + self.encodeId);
            $('#' + eleID).mouseup(function(event) {
                if ($('#businessRoleDiv' + self.encodeId).has($(event.target)).length > 0) {
                    self.enableShowBusinesRoleDiv(true);
                } else {
                    if ($("#roleName" + self.encodeId).val() && ($("#roleName" + self.encodeId).val().trim() !== "" || $("#editRoleName" + self.encodeId).val().trim() !== "")) {
                        if (self.editRoleName() && self.roleId !== "") {
                            if (self.roleDescription() == "") {
                                $("#roleDescription" + self.encodeId).val($("#roleName" + self.encodeId).val());
                            } else {
                                $("#roleDescription" + self.encodeId).val(self.roleDescription());
                            }
                            self.saveEditRole();
                        } else if ($("#roleName" + self.encodeId).val() !== "") {
                            if (self.roleDescription() == "") {
                                $("#roleDescription" + self.encodeId).val($("#editRoleName" + self.encodeId).val());
                            } else {
                                $("#roleDescription" + self.encodeId).val(self.roleDescription());
                            }
                            self.addRole();
                        }
                    } else {
                        self.enableShowBusinesRoleDiv(false);
                    }
                }
            });
        }
        var Extendedname = Backbone.Typeahead.extend({
            template: '<input type="text" id="name' + self.encodeId + '" placeholder="Name" value="' + self.name() + '" class="form-control" autofocus><ul class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        $('#name' + self.encodeId).focus();
        var bmDetailstypeahead = new Extendedname({
            collection: self.displaybusinessTypeahead
        });
        bmDetailstypeahead.setElement('#BmDetailsDiv' + self.encodeId).render();
        bmDetailstypeahead.on('selected', function(fetchDisplayModel) {

            $('#message' + self.encodeId).text('');
            var fetchmodel = self.businessTypeahead.findWhere({
                'id': fetchDisplayModel.get('id')
            });
            $('#roleName' + self.encodeId).val('');
            $('#roleDescription' + self.encodeId).val('');
            self.businessDetailsRoleOriginalId = '';
            var businessMessage = '';
            var cond = true;
            if (self.model) {
                var roleList = [];
                var bmCompList = self.parentView.get('bmCompetence');
                var compNames = '';
                for (var i = 0; i < bmCompList.length; i++) {
                    compNames = compNames.concat('' + bmCompList.at(i).get('name'));
                    if (bmCompList.length > 1 && bmCompList.length - 1 !== i) {
                        compNames = compNames.concat(', ');
                    }
                }
                if (bmCompList.length > 0) {
                    cond = false;
                    var comptenceWordMsg = 'Competency';
                    if (bmCompList.length > 1) {
                        comptenceWordMsg = 'Competencies';
                    }
                    businessMessage = DataManager.getDataManager().get('localeManager').get('BlockBusinessChange', comptenceWordMsg, compNames);
                } else {
                    if (self.parentView.get('bmValueFormula').length > 0 || self.parentView.get('bmValueProposition').length > 0) {
                        var businessChangeConfirm = DataManager.getDataManager().get('localeManager').get('BusinessChange');
                    }
                }
                var assignments = self.options.addOptions.assignments;
                var assignmentColl = assignments[self.model.get("id")];
                var bmRoles = self.parentView.get('bmRole');
                var roleColl = self.options.addOptions.collRoles;
                if (assignmentColl.models && roleColl) {
                    for (var x = 0; x < assignmentColl.models.length; x++) {
                        var assignedRole = assignmentColl.models[x].get("assignedRole");
                        if (roleColl.indexOf(assignedRole) !== -1 && self.options.addOptions.notAttached && bmRoles.indexOf(assignedRole) !== -1) {
                            roleList.push(assignedRole);
                        }
                    }
                }
                var bmList = fetchmodel.get('businessModel');
                if (bmList) {
                    for (var i = 0; i < bmList.length; i++) {
                        for (var j = 0; j < roleList.length; j++) {
                            var roleExists = bmList.at(i).get('bmRole').findWhere({
                                id: roleList[j].get('id')
                            });
                            if (roleExists) {
                                cond = false;
                                businessMessage = DataManager.getDataManager().get('localeManager').get('BlockBusinessInconsistency');
                                break;
                            }
                        }
                    }
                }
            }
            if (cond) {
                function setNewbusiness() {
                    self.orgId(fetchmodel.get("id"));
                    self.typeaheadName = fetchmodel.get("name");
                    self.name(fetchmodel.get("name"));
                    if (fetchmodel.get("description")) {
                        self.description(fetchmodel.get("description"));
                    } else {
                        self.description(fetchmodel.get("name"));
                    }
                    self.rolesColl.removeAll();
                    self.enableComplete(false);
                }
                if (businessChangeConfirm) {
                    bootbox.confirm(businessChangeConfirm, function(result) {
                        if (result) {
                            setNewbusiness();
                        } else {
                            $('#name' + self.encodeId).val(self.model.get("name"));
                            self.name(self.model.get("name"));
                        }
                    })
                } else {
                    setNewbusiness();
                }

            } else {
                bootbox.alert('' + businessMessage);
                $('#name' + self.encodeId).val(self.model.get("name"));
                self.name(self.model.get("name"));
                for (var j = 0; j < self.rolesColl().length; j++) {
                    if (!self.rolesColl()[j]._destroy) {
                        self.enableComplete(true);
                        break;
                    }
                }
            }
        });
        $("#name" + self.encodeId).change(function(view) {
            $('#roleName' + self.encodeId).val('');
            $('#roleDescription' + self.encodeId).val('');
            self.businessDetailsRoleOriginalId = '';
            var newValue = view.currentTarget.value;
            if (newValue !== '') {
                self.name(newValue);
                if (self.typeaheadName !== newValue) {
                    self.orgId("");
                }
                if (self.orgId() !== "" && self.model) {
                    self.fillRolesColl();
                }
            }
        });
        $("#name" + self.encodeId).keyup(function(view) {
            self.matchField = $(view.currentTarget).closest('.row').find('.match');
            var newValue = view.currentTarget.value;
            var unique = self.getUniqueValue(newValue);
            if (!unique && newValue !== '' && self.rolesColl().length > 0) {
                self.enableComplete(true);
            } else {
                self.enableComplete(false);
            }
        });
        if (self.rolesColl().length === 0) {
            $('#customerButtonComplete').hide();
        }
        window.utils.applyFontSizeInDialog();
    };


    static getInstance(model, options) {
        var view = new BusinessDetailsViewModel(model, options);
        view.init(model, options);
        return view;
    };
}
path.BusinessDetailsViewModel = BusinessDetailsViewModel;