import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {CapabilityMethod} from './CapabilityMethod'
import {CapabilityMethodMixin} from './CapabilityMethodMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CapabilityMethod","appbo/vdml/CapabilityMethodMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CapabilityMethod,CapabilityMethodMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class CaseCollaborationMixin {

	defaults(){
		var ret = {
			type: "vdml_CaseCollaboration"
		}
		return jQuery.extend(CapabilityMethod.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!CaseCollaborationMixin.cummulativeRelations) {
            CaseCollaborationMixin.cummulativeRelations = _.union(CaseCollaborationMixin.getMixinRelations()
                , CapabilityMethodMixin.getCumulativeMixinRelations()
            );
        }
		return CaseCollaborationMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_CapabilityMethod"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		return CapabilityMethod.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return CapabilityMethod.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/CaseCollaborationPropertiesTemplate.html",
			templateName : "CaseCollaborationPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/CaseCollaborationViewModel",
			tabId : "CaseCollaborationView",
			tabName: "CaseCollaboration"
		}
	}
	
	}
	path.CaseCollaborationMixin = CaseCollaborationMixin;
	//return CaseCollaborationMixin;
//});