import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { Store } from './Store'
import { StoreMixin } from './StoreMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//import { Position } from './Position';
import { CalendarService } from './CalendarService'
import { MeasuredCharacteristic } from './MeasuredCharacteristic'
import { WorklistReference } from './WorklistReference'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Store","appbo/vdml/StoreMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Store,StoreMixin
, Position
, CalendarService
, MeasuredCharacteristic
, WorklistReference
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	//if(!Position){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Position){
			Position = importPath.Position;
		}
        else {
            import('./Position').then(loadedModule => {
           //     Position = loadedModule;
            });
			/*require(["appbo/vdml/Position"],function(loadedModule){
				Position = loadedModule;
			});*/
		}
	//}
	if(!CalendarService){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CalendarService){
			CalendarService = importPath.CalendarService;
		}
        else {
            import('./CalendarService').then(loadedModule => {
                CalendarService = loadedModule;
            });
			/*require(["appbo/vdml/CalendarService"],function(loadedModule){
				CalendarService = loadedModule;
			});*/
		}
	}
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
        else {
            import('./MeasuredCharacteristic').then(loadedModule => {
                MeasuredCharacteristic = loadedModule;
            });
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	if(!WorklistReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.WorklistReference){
			WorklistReference = importPath.WorklistReference;
		}
        else {
            import('./WorklistReference').then(loadedModule => {
                WorklistReference = loadedModule;
            });
			/*require(["appbo/vdml/WorklistReference"],function(loadedModule){
				WorklistReference = loadedModule;
			});*/
		}
	}
	
export class PoolMixin {

    defaults() {
        var ret = {
            type: "vdml_Pool"
        }
        //var Store = Backbone.Relational.store.getObjectByName("vdml.Store");
        return jQuery.extend(Store.prototype.defaults.apply(this), ret);
    }
    static getMixinRelations() {
        return _.union([
            {
                type: Backbone.HasMany,
                containingClass: "vdml_Pool",
                key: "position",
                relatedModel: "vdml.Position",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            },
            {
                type: Backbone.HasOne,
                containingClass: "vdml_Pool",
                key: "poolCalendar",
                relatedModel: "vdml.CalendarService",
                reverseRelation: {
                    key: "poolCalendarOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },
            /*{
                type: Backbone.HasOne,
                containingClass: "vdml_Pool",
                key: "poolSize",
                relatedModel: "vdml.MeasuredCharacteristic",
                reverseRelation: {
                    key: "poolSizeOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },*/
            {
                type: Backbone.HasOne,
                containingClass: "vdml_Pool",
                key: "worklistReference",
                relatedModel: "vdml.WorklistReference",
                reverseRelation: {
                    key: "worklistReferenceOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },
            {
                type: Backbone.HasOne,
                containingClass: "vdml_Pool",
                key: "manager",
                relatedModel: "vdml.Position",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            }
        ])
    }
    static getCumulativeMixinRelations() {
        if (!PoolMixin.cummulativeRelations) {
            PoolMixin.cummulativeRelations = _.union(PoolMixin.getMixinRelations()
                , StoreMixin.getCumulativeMixinRelations()
            );
        }
        return PoolMixin.cummulativeRelations.slice();
    }
    static getSuperTypes() {
        return [
            "vdml_Store"
        ];
    }
    static getProperties() {
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "represents", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" }
        ]
    }
    getParent() {
        var container;
        return Store.prototype.getParent.apply(this, arguments);
    }
    getPackagePath(path) {
        if (!path) {
            path = [];
        }
        return Store.prototype.getPackagePath.apply(this, arguments);
    }
    getViewProperties(type) {
        return {
            templatePath: "views/vdml/views/properties/PoolPropertiesTemplate.html",
            templateName: "PoolPropertiesTemplate",
            viewTypeStr: "appviews/vdml/views/properties/PoolViewModel",
            tabId: "PoolView",
            tabName: "Pool"
        }
    }
    
}
path.PoolMixin = PoolMixin;
	//return PoolMixin;
//});