import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Measure} from './Measure'
import {MeasureMixin} from './MeasureMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { RankingMeasureRelationship } from './RankingMeasureRelationship'
import { Base1MeasureRelationship } from './Base1MeasureRelationship'
import { Base2MeasureRelationship } from './Base2MeasureRelationship'
import { BaseNMeasureRelationship } from './BaseNMeasureRelationship'
import { RescaledMeasureRelationship } from './RescaledMeasureRelationship'
import { UnitOfMeasure } from './UnitOfMeasure'

/*function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Measure,MeasureMixin
, RankingMeasureRelationship
, Base1MeasureRelationship
, Base2MeasureRelationship
, BaseNMeasureRelationship
, RescaledMeasureRelationship
, UnitOfMeasure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!RankingMeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RankingMeasureRelationship){
			RankingMeasureRelationship = importPath.RankingMeasureRelationship;
		}
		else{
            import('./RankingMeasureRelationship').then(({ default: RankingMeasureRelationship }) => {
                RankingMeasureRelationship = RankingMeasureRelationship;
            });

			/*require(["appbo/smm/RankingMeasureRelationship"],function(loadedModule){
				RankingMeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!Base1MeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Base1MeasureRelationship){
			Base1MeasureRelationship = importPath.Base1MeasureRelationship;
		}
		else{
            import('./Base1MeasureRelationship').then(({ default: Base1MeasureRelationship }) => {
                Base1MeasureRelationship = Base1MeasureRelationship;
            });

			/*require(["appbo/smm/Base1MeasureRelationship"],function(loadedModule){
				Base1MeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!Base2MeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Base2MeasureRelationship){
			Base2MeasureRelationship = importPath.Base2MeasureRelationship;
		}
		else{
            import('./Base2MeasureRelationship').then(({ default: Base2MeasureRelationship }) => {
                Base2MeasureRelationship = Base2MeasureRelationship;
            });

			/*require(["appbo/smm/Base2MeasureRelationship"],function(loadedModule){
				Base2MeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!BaseNMeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.BaseNMeasureRelationship){
			BaseNMeasureRelationship = importPath.BaseNMeasureRelationship;
		}
		else{
            import('./BaseNMeasureRelationship').then(({ default: BaseNMeasureRelationship }) => {
                BaseNMeasureRelationship = BaseNMeasureRelationship;
            });

			/*require(["appbo/smm/BaseNMeasureRelationship"],function(loadedModule){
				BaseNMeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!RescaledMeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RescaledMeasureRelationship){
			RescaledMeasureRelationship = importPath.RescaledMeasureRelationship;
		}
		else{
            import('./RescaledMeasureRelationship').then(({ default: RescaledMeasureRelationship }) => {
                RescaledMeasureRelationship = RescaledMeasureRelationship;
            });

			/*require(["appbo/smm/RescaledMeasureRelationship"],function(loadedModule){
				RescaledMeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!UnitOfMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.UnitOfMeasure){
			UnitOfMeasure = importPath.UnitOfMeasure;
		}
		else{
            import('./UnitOfMeasure').then(({ default: UnitOfMeasure }) => {
                UnitOfMeasure = UnitOfMeasure;
            });

			/*require(["appbo/smm/UnitOfMeasure"],function(loadedModule){
				UnitOfMeasure = loadedModule;
			});*/
		}
	}
	export class DimensionalMeasureMixin {
        /*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            path.DimensionalMeasureMixin = DimensionalMeasureMixin;
        }*/
	defaults(){
		var ret = {
			type: "smm_DimensionalMeasure"
		}
		return jQuery.extend(Measure.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"smm_DimensionalMeasure",
			key:"rankingFrom",
			relatedModel:"smm.RankingMeasureRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"to",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_DimensionalMeasure",
			key:"baseMeasure1From",
			relatedModel:"smm.Base1MeasureRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"to",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_DimensionalMeasure",
			key:"baseMeasure2From",
			relatedModel:"smm.Base2MeasureRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"to",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_DimensionalMeasure",
			key:"baseMeasureFrom",
			relatedModel:"smm.BaseNMeasureRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"to",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"smm_DimensionalMeasure",
			key:"rescaleTo",
			relatedModel:"smm.RescaledMeasureRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"from",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"smm_DimensionalMeasure",
			key:"unit",
			relatedModel:"smm.UnitOfMeasure",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!DimensionalMeasureMixin.cummulativeRelations) {
            DimensionalMeasureMixin.cummulativeRelations = _.union(DimensionalMeasureMixin.getMixinRelations()
                , MeasureMixin.getCumulativeMixinRelations()
            );
        }
		return DimensionalMeasureMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_Measure"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			//{name : "definition",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "scale",type : "ScaleOfMeasurement",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "customScale",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "source",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "visible",type : "EBoolean",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "formula",type : "EString",defaultValue : "null",containingClass : "smm_DimensionalMeasure" }
		]
	}
	getParent(){
		var container;
		return Measure.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return Measure.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/DimensionalMeasurePropertiesTemplate.html",
			templateName : "DimensionalMeasurePropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/DimensionalMeasureViewModel",
			tabId : "DimensionalMeasureView",
			tabName: "DimensionalMeasure"
		}
	};
	checkUnitReferencesAndDestroy(model){
		if(Backbone.skipPersistance){
			return;
		}		
		var self = this;
		var unit = model.previousAttributes().unit;
		if( !unit || !unit.get('libraries')){
			return;
		}
		if(unit){
			var unitUsed = false;
			var lib = model.get('libraries');
			if(!lib){
				lib = model.getNestedParent().get('defaultMeasureLibrary');
			}
			var meaureElements = lib?lib.get('measureElements'):[];
			var meaureElementsLen = meaureElements.length;
			for(var i=0;i<meaureElementsLen;i++){
				if(meaureElements.at(i).get('type') == "smm_CollectiveMeasure" ||  meaureElements.at(i).get('type') == "smm_DirectMeasure"){
					if(meaureElements.at(i).get('unit') == unit && meaureElements.at(i) != model){
						unitUsed = true;
						break;
					}
				}
			}
			if(!unitUsed) {
				if(DataManager.getDataManager().getRepositoryId(unit.get('id')) == window.plansKey){
					unit.destroy();
				}else {
					var spinText = $('#SpinnerText').text();
					if(spinText && (spinText.includes("Deleting Phase") || spinText.includes("Deleting Alternative"))){
						unit.destroy();
					}else {
						var libId = unit.get('libraries').get('id');
						DataManager.getDataManager().getReverseAssociationInstances(unit, 'smm_DimensionalMeasure-unit', 'smm_DimensionalMeasure','vbc:cmof_EObject-name',function(measures){
							if(measures.length === 0 || (measures.length === 1 && measures.at(0) === self)){
								unit.destroy();
							}	
						});	
					}
				}
			}
		}
		
		
	};
	
	}
	path.DimensionalMeasureMixin = DimensionalMeasureMixin;
	//return DimensionalMeasureMixin;
//});