import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import {Value} from './Value'
import {ValueMixin} from './ValueMixin'
import {ValueCategory} from './ValueCategory'
import {Characteristic} from '../smm/Characteristic'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin","appbo/vdml/Value"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin,Value
	, ValueCategory
	, PracticeDefinition
, Characteristic){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ValueCategory){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueCategory){
			ValueCategory = importPath.ValueCategory;
		}
		else{
			import('./ValueCategory').then(loadedModule => {
				ValueCategory = loadedModule;
			});
			/*require(["appbo/vdml/ValueCategory"],function(loadedModule){
				ValueCategory = loadedModule;
			});*/
		}
	}
	if(!Characteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Characteristic){
			Characteristic = importPath.Characteristic;
		}
		else{
			import('../smm/Characteristic').then(loadedModule => {
				Characteristic = loadedModule;
			});
			/*require(["appbo/smm/Characteristic"],function(loadedModule){
				Characteristic = loadedModule;
			});*/
		}
	}
	export class ValueDefinitionMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.ValueDefinitionMixin = ValueDefinitionMixin;
        }*/
	static getSuperTypes() {
		return [
			"vdml_Value"
		];
	}
	getParent() {
		var container;
		return Value.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path) {
		if (!path) {
			path = [];
		}
		return Value.prototype.getPackagePath.apply(this, arguments);
	}
	defaults(){
		var ret = {
			type: "vdml_ValueDefinition"
		}
		return jQuery.extend(Value.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
			
		])
	}
	static getCumulativeMixinRelations(){
		if (!ValueDefinitionMixin.cummulativeRelations) {
            ValueDefinitionMixin.cummulativeRelations = _.union(ValueDefinitionMixin.getMixinRelations()
                , ValueMixin.getCumulativeMixinRelations()
            );
        }
		return ValueDefinitionMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_Value"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{ name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
			{ name: "color", type: "EString", defaultValue: "null", containingClass: "vdml_Value" }
			//{name : "definition",type : "EString",defaultValue : "null",containingClass : "vdml_ValueDefinition" }
		]
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ValueDefinitionPropertiesTemplate.html",
			templateName : "ValueDefinitionPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ValueDefinitionViewModel",
			tabId : "ValueDefinitionView",
			tabName: "ValueDefinition"
		}
	}
	
	}
	path.ValueDefinitionMixin = ValueDefinitionMixin;
	//return ValueDefinitionMixin;
//});