import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {VdmlElementMixin} from '../vdml/VdmlElementMixin'
/*
define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
){*/
	
    var path = DataManager.getDataManager().buildAppNsPath("dashboard", global.version);
	export class PresentationMixin{
		constructor(){
			var path = DataManager.getDataManager().buildAppNsPath("dashboard",global.version);
			path.PresentationMixin = PresentationMixin;
		}
	
	defaults(){
		var ret = {
			type: "dashboard_Presentation"
		}
		var VdmlElement = Backbone.Relational.store.getObjectByName("vdml.VdmlElement");
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
	    return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!PresentationMixin.cummulativeRelations) {
            PresentationMixin.cummulativeRelations = _.union(PresentationMixin.getMixinRelations()
                , VdmlElementMixin.getCumulativeMixinRelations()
            );
        }
		return PresentationMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "presentationType", type: "EString", defaultValue: "null", containingClass: "dashboard_Presentation" },
            { name: "presentationReference", type: "EString", defaultValue: "null", containingClass: "dashboard_Presentation" }
		]
	}
	getParent(){
		var container;
		if(!container){
		    container = this.get("analytics") ? this.get("analytics") : this.previousAttributes().analytics;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
		    container = this.get("analytics") ? this.get("analytics") : this.previousAttributes().analytics;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type) {
		return {
			templatePath : "views/dashboard/views/chart/PresentationTemplate.html",
			templateName: "PresentationTemplate",
			viewTypeStr: "appviews/dashboard/views/chart/PresentationViewModel",
			tabId: "PresentationView",
			tabName: "PresentationView"
		}
	}
	getDialogViewProperties(type) {
	    
	}


	//path.PresentationMixin = PresentationMixin;
	//return PresentationMixin;
}