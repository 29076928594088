import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { CapabilityMethod } from './CapabilityMethod'
import { ProcessCollaborationMixin } from './ProcessCollaborationMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CapabilityMethod","appbo/vdml/ProcessCollaborationMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CapabilityMethod,ProcessCollaborationMixin){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var ProcessCollaboration = CapabilityMethod.extend(utils.customExtends({
		relations:ProcessCollaborationMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			CapabilityMethod.prototype.initialize.apply(this, arguments);
		}
		}
		, new ProcessCollaborationMixin()
	));
	
	ProcessCollaboration.abstract = false;
	ProcessCollaboration.supported = false;
	path.ProcessCollaboration = ProcessCollaboration;
    export { ProcessCollaboration };
//});