import * as _ from 'underscore'
import * as  Backbone from 'backbone'
//import * as Lawnchair from 'lawnchair'
//import * as Lawnchair from 'lawnchair'
import * as ko from 'knockout'
//import * as  backboneLawnchair from 'backbonelawnchair'
//define(["underscore", "backbone", "Lawnchair", "backbone-lawnchair","knockout"],

	//function(_, Backbone, Lawnchair, backboneLawnchair,ko) {
	export class LocaleManager {
		constructor(translations_by_locale,locale_identifier) {
		    this.translations_by_locale = translations_by_locale;
		    this.current_locale = ko.observable(locale_identifier);
	    	this.currentTranslations = _.extend({},this.translations_by_locale[this.current_locale()]);
		    this.re = /{(.*?)}/g;
			this.get = function(string_id) {
				var self = this;
			    if (!this.currentTranslations) { return '(no translation)'; }
			    if (!this.currentTranslations.hasOwnProperty(string_id)) { return '(no translation)'; }
			     
			    var locObj = this.currentTranslations[string_id];
				if(!locObj){
					return null;
				}
			    var string = locObj.value;
			    string = string.replace(this.re,function(match,offset,totalStr){
			    	var matchContent = match.substr(1,match.length -2);
			    	if(isNaN(matchContent)){
			    		return self.get(matchContent);
			    	}
			    	return match;
			    });
			    // add arguments
			    var arg = Array.prototype.slice.call(arguments, 1);
				var _i;
                for (var index = _i = 0; _i < arg.length; index = ++_i) { string = string.replace("{" + index + "}", arg[index]); }
			    return string;
		    };
		     
		    this.getLocale = function() {
		    	return this.current_locale(); 
		    };
		    this.setLocale = function(locale_identifier) {
			    this.current_locale(locale_identifier);
			    this.trigger('change', this);
			    var map = this.translations_by_locale[this.current_locale()];
			    for (var key in map) { this.trigger("change:" + key, map[key]); }
			    this.currentTranslations = _.extend({},this.translations_by_locale[this.current_locale()]);
		    };
		     
		    this.getLocales = function() {
			    var locales = [];
			    for (var string_id in this.translations_by_locale) {
			    //value = this.translations_by_locale[string_id];
			    locales.push(string_id);
			    }
			    return locales;
		    };
		    this.getTranslationsByLocale = function(locale){
		    	return this.translations_by_locale[locale];
		    };
		    this.reset = function(){
		    	delete this.currentTranslations;
		    	this.currentTranslations = _.extend({},this.translations_by_locale[this.current_locale()]);
		    };
		    this.applyConcepts = function(concepts){
		    	var self = this;
		    	self.reset();
		    	for(var i=concepts.length-1;i>=0;i--){
		    		if(concepts[i].get('data')){
						var conceptData = JSON.parse(concepts[i].get('data'));
						_.extend(self.currentTranslations,conceptData);		    		
		    		}
		    	}
		    };
		    this.cleanUp = function(){
		    	delete this.current_locale;
		    	this.currentTranslations = null;
		    	this.translations_by_locale = null;
		    }
		}
	}
     
    _.extend(LocaleManager.prototype, Backbone.Events);
    //return LocaleManager;
//});	