import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {CollaborationDiagram} from './CollaborationDiagram'
import {StrategyMapDiagramMixin} from './StrategyMapDiagramMixin'
import {StrategyMapDiagram2Mixin} from './StrategyMapDiagram2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/StrategyMapDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,StrategyMapDiagramMixin
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? StrategyMapDiagram2Mixin : StrategyMapDiagramMixin;
	}
	var mixinClass = getMixinClass();
	var StrategyMapDiagram = CollaborationDiagram.extend(utils.customExtends({
		relations:mixinClass.getMixinRelations(),
		initialize: function(attributes, options) {
		    CollaborationDiagram.prototype.initialize.apply(this, arguments);
		    /*this.on('remove:participant', this.handleRemoveParticipant); 
		    this.on('remove:businessModel', this.handleRemoveBusinessModel);
		    this.on('remove:valueProposition', this.handleRemoveValueProposition);*/
		}
		}
		, new mixinClass()
	));
	StrategyMapDiagram['remove:value'] = function (model, coll, options) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.handleRemoveParticipant(model, coll, options);
		}
    }
	StrategyMapDiagram['remove:activity'] = function(model, coll, options) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.handleRemoveBusinessModel(model, coll, options);
		}
    }
	StrategyMapDiagram['remove:competence'] = function (model, coll, options) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.handleRemoveValueProposition(model, coll, options);
		}
    };
	StrategyMapDiagram.getInstance = function(name,description,owner,id){
		var eid = id ? id : DataManager.getDataManager().guidGeneratorByOwner(owner);
		var strategyMap = new StrategyMapDiagram({id:eid,name:owner.getUniqueProperty('name',name,'diagrams'),description:owner.getUniqueProperty('description',description,'diagrams'), ecoMap:owner});
		return strategyMap;
	}
	StrategyMapDiagram.prototype.getSystemTags = function () {
	    return ['VDM', 'Strategy Map' ,'Discover'];
	};
	
	StrategyMapDiagram.abstract = false;
	StrategyMapDiagram.supported = true;
	path.StrategyMapDiagram = StrategyMapDiagram;
	export {StrategyMapDiagram};
//});