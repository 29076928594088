import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DataManager} from '../../../com/vbee/data/DataManager'
import { SmmModel } from './SmmModel';
import { AbstractMeasureElement } from './AbstractMeasureElement'
import { CategoryRelationship } from './CategoryRelationship'
import { CollectiveMeasure } from './CollectiveMeasure'
import { BaseNMeasureRelationship } from './BaseNMeasureRelationship'
import { RescaledMeasure } from './RescaledMeasure'
import { DirectMeasure } from './DirectMeasure'
import { GradeMeasure } from './GradeMeasure'
import { GradeMeasureRelationship } from './GradeMeasureRelationship'
import { GradeInterval } from './GradeInterval'
import { RankingMeasure } from './RankingMeasure'
import { RankingMeasureRelationship } from './RankingMeasureRelationship'
import { RankingInterval } from './RankingInterval'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/SmmElementMixin","appbo/smm/Characteristic","appbo/smm/UnitOfMeasure","appbo/smm/Scope","appbo/smm/Measure"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,SmmElementMixin,Characteristic,UnitOfMeasure,Scope,Measure
, SmmModel
, AbstractMeasureElement
, CategoryRelationship
, CollectiveMeasure
, BaseNMeasureRelationship
, RescaledMeasure
, DirectMeasure
, GradeMeasure
, GradeMeasureRelationship
, GradeInterval
, RankingMeasure
, RankingMeasureRelationship
, RankingInterval){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!SmmModel){		
		if(importPath.SmmModel){
			SmmModel = importPath.SmmModel;
		}
        else {
            import('./SmmModel').then(({ default: SmmModel }) => {
                SmmModel = SmmModel;
            });
            /*require(["appbo/smm/SmmModel"], function (loadedModule) {
                SmmModel = loadedModule;
			});*/
		}
	}
	if(!AbstractMeasureElement){
		if(importPath.AbstractMeasureElement){
			AbstractMeasureElement = importPath.AbstractMeasureElement;
		}
        else {
            import('./AbstractMeasureElement').then(({ default: AbstractMeasureElement }) => {
                AbstractMeasureElement = AbstractMeasureElement;
            });
            /*require(["appbo/smm/AbstractMeasureElement"], function (loadedModule) {
                AbstractMeasureElement = loadedModule;
			});*/
		}
	}
	if(!CategoryRelationship){
		if(importPath.CategoryRelationship){
			CategoryRelationship = importPath.CategoryRelationship;
		}
		else{
			import('./CategoryRelationship').then(({ default: CategoryRelationship }) => {
                CategoryRelationship = CategoryRelationship;
            });
            /*require(["appbo/smm/CategoryRelationship"], function (loadedModule) {
                CategoryRelationship = loadedModule;
			});*/
		}
	}
	if(!CollectiveMeasure){
		if(importPath.CollectiveMeasure){
			CollectiveMeasure = importPath.CollectiveMeasure;
		}
        else {
            import('./CollectiveMeasure').then(({ default: CollectiveMeasure }) => {
                CollectiveMeasure = CollectiveMeasure;
            });
            /*require(["appbo/smm/CollectiveMeasure"], function (loadedModule) {
                CollectiveMeasure = loadedModule;
			});*/
		}
	}
	if(!BaseNMeasureRelationship){
        if (importPath.BaseNMeasureRelationship) {
            BaseNMeasureRelationship = importPath.BaseNMeasureRelationship;
		}
		else{
			import('./BaseNMeasureRelationship').then(({ default: BaseNMeasureRelationship }) => {
                BaseNMeasureRelationship = BaseNMeasureRelationship;
            });
            /*require(["appbo/smm/BaseNMeasureRelationship"], function (loadedModule) {
                BaseNMeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!RescaledMeasure){
		if(importPath.RescaledMeasure){
			RescaledMeasure = importPath.RescaledMeasure;
		}
        else {
            import('./RescaledMeasure').then(({ default: RescaledMeasure }) => {
                RescaledMeasure = RescaledMeasure;
            });
            /*require(["appbo/smm/RescaledMeasure"], function (loadedModule) {
                RescaledMeasure = loadedModule;
			});*/
		}
	}
	if(!DirectMeasure){
		if(importPath.DirectMeasure){
			DirectMeasure = importPath.DirectMeasure;
		}
        else {
            import('./DirectMeasure').then(({ default: DirectMeasure }) => {
                DirectMeasure = DirectMeasure;
            });
            /*require(["appbo/smm/DirectMeasure"], function (loadedModule) {
                DirectMeasure = loadedModule;
			});*/
		}
	}
	if(!GradeMeasure){
		if(importPath.GradeMeasure){
			GradeMeasure = importPath.GradeMeasure;
		}
        else {
            import('./GradeMeasure').then(({ default: GradeMeasure }) => {
                GradeMeasure = GradeMeasure;
            });
            /*require(["appbo/smm/GradeMeasure"], function (loadedModule) {
                GradeMeasure = loadedModule;
			});*/
		}
	}
	if(!GradeMeasureRelationship){
		if(importPath.GradeMeasureRelationship){
			GradeMeasureRelationship = importPath.GradeMeasureRelationship;
		}
        else {
            import('./GradeMeasureRelationship').then(({ default: GradeMeasureRelationship }) => {
                GradeMeasureRelationship = GradeMeasureRelationship;
            });
            /*require(["appbo/smm/GradeMeasureRelationship"], function (loadedModule) {
                GradeMeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!GradeInterval){
		if(importPath.GradeInterval){
			GradeInterval = importPath.GradeInterval;
		}
        else {
            import('./GradeInterval').then(({ default: GradeInterval }) => {
                GradeInterval = GradeInterval;
            });
            /*require(["appbo/smm/GradeInterval"], function (loadedModule) {
                GradeInterval = loadedModule;
			});*/
		}
	}
	if(!RankingMeasure){
		if(importPath.RankingMeasure){
			RankingMeasure = importPath.RankingMeasure;
		}
        else {
            import('./RankingMeasure').then(({ default: RankingMeasure }) => {
                RankingMeasure = RankingMeasure;
            });
           /* require(["appbo/smm/RankingMeasure"], function (loadedModule) {
                RankingMeasure = loadedModule;
			});*/
		}
	}
	if(!RankingMeasureRelationship){
		if(importPath.RankingMeasureRelationship){
			RankingMeasureRelationship = importPath.RankingMeasureRelationship;
		}
        else {
            import('./RankingMeasureRelationship').then(({ default: RankingMeasureRelationship }) => {
                RankingMeasureRelationship = RankingMeasureRelationship;
            });
            /*require(["appbo/smm/RankingMeasureRelationship"], function (loadedModule) {
                RankingMeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!RankingInterval){
		if(importPath.RankingInterval){
			RankingInterval = importPath.RankingInterval;
		}
        else {
            import('./RankingInterval').then(({ default: RankingInterval }) => {
                RankingInterval = RankingInterval;
            });
			/*require(["appbo/smm/RankingInterval"],function(loadedModule){
				RankingInterval = loadedModule;
			});*/
		}
	}
	export class MeasureLibraryMixin {

	defaults(){
		var ret = {
			type: "smm_MeasureLibrary"
		}
		var SmmElement = Backbone.Relational.store.getObjectByName("smm.SmmElement");
		return jQuery.extend(SmmElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"smm_MeasureLibrary",
			key:"measureElements",
			relatedModel:"smm.AbstractMeasureElement",
			reverseRelation: {
				key:"libraries",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_MeasureLibrary",
			key:"categoryRelationship",
			relatedModel:"smm.CategoryRelationship",
			reverseRelation: {
				key:"libraries",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!MeasureLibraryMixin.cummulativeRelations) {
			var SmmElementMixin = Backbone.Relational.store.getObjectByName("smm.SmmElementMixin");
            MeasureLibraryMixin.cummulativeRelations = _.union(MeasureLibraryMixin.getMixinRelations()
                , SmmElementMixin.getCumulativeMixinRelations()
            );
        }
		return MeasureLibraryMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_SmmElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("smmModel") ? this.get("smmModel") : this.previousAttributes().smmModel;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("smmModel") ? this.get("smmModel") : this.previousAttributes().smmModel;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/MeasureLibraryPropertiesTemplate.html",
			templateName : "MeasureLibraryPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/MeasureLibraryViewModel",
			tabId : "MeasureLibraryView",
			tabName: "MeasureLibrary"
		}
	}
//#startCustomMethods	
	getOrCreateCharacteristic(charName){
		var libElements = this.get('measureElements');
		var Characteristic = Backbone.Relational.store.getObjectByName("smm.Characteristic");
		var selectedCharacteristic;
		libElements.each(function(element){
			if((element instanceof Characteristic) && (element.get('name') === charName)){
				selectedCharacteristic = element;	
			}
		});		
		if(!selectedCharacteristic){
			var charId = DataManager.getDataManager().guidGeneratorByOwner(this);
			selectedCharacteristic = new Characteristic({id:charId,name:charName,description:charName,'libraries':this});					
		}
		return selectedCharacteristic;
	}
	getOrCreateUnit(unitName,existingUnitObj){
        var libElements = this.get('measureElements');
        var unit;
		var UnitOfMeasure = Backbone.Relational.store.getObjectByName('smm.UnitOfMeasure');
        libElements.each(function(element){
            if((element instanceof UnitOfMeasure) && (element.get('name') === unitName)){
                unit = element; 
            }
        });     
        if(!unit){
        	var unitId
        	if(existingUnitObj && existingUnitObj.get('id')) {
        		var existingUnitId = existingUnitObj.get('id');
        		var suffixId = existingUnitId.substr(existingUnitId.lastIndexOf('@')+ 1);
        		unitId = DataManager.getDataManager().getRepositoryId(this.get('id')) + suffixId;
        	}else {
        		unitId = DataManager.getDataManager().guidGeneratorByOwner(this);
        	}
			
        	unit = UnitOfMeasure.find({id:unitId});
        	if(!unit) {
        		unit = new UnitOfMeasure({id:unitId,name:unitName,description:unitName,libraries:this});	
        	}
        }
        return unit;
    }
    LinkOrCreateAndLinkScope(measure){
        var libElements = this.get('measureElements');
		var Scope = Backbone.Relational.store.getObjectByName('smm.Scope');
        var scope;
        libElements.each(function(element){
            if((element instanceof Scope) && (element.get('name') === 'Business Model')){
                scope = element;    
            }
        });     
        if(!scope){
            var scopeId = DataManager.getDataManager().guidGeneratorByOwner(this);
            scope = new Scope({id:scopeId,name:'Business Model',description:'Business Model',libraries:this});
        }
        measure.set('scope',scope);
    }; 
    
	findMeasure(charToMatch,unitToMatch,accToMatch,baseRelSetToMatch,satisfactionTuple,intervalTupleSet,nonDimensional,typeToMatch){
		var self= this;
		var baseRelSetToMatchNew = [];
		var intervalTupleSetNew = [];
		_.each(baseRelSetToMatch, function(baseRel){
			if(baseRel.new){
				baseRelSetToMatchNew.push(baseRel.new);	
			}
		});
		_.each(intervalTupleSet, function(intervalTuple){
			if(intervalTuple.new){
				intervalTupleSetNew.push(intervalTuple.new);	
			}
		});		
		if(!typeToMatch){
			if(!satisfactionTuple){
				if(baseRelSetToMatchNew.length === 0){
					typeToMatch = "smm_DirectMeasure";
				}else{
					typeToMatch = "smm_CollectiveMeasure";
				}
			}else{
				typeToMatch = satisfactionTuple.type;
			}
		}
		var measureElements = self.get('measureElements');
		var Measure = Backbone.Relational.store.getObjectByName('smm.Measure');
		for(var i=0;i<measureElements.length;i++){
			var element = measureElements.at(i);
			if(Backbone.Relational.instanceofmodel(element, Measure) && element.measureMatches(charToMatch,unitToMatch,typeToMatch,accToMatch,baseRelSetToMatchNew,intervalTupleSetNew,!nonDimensional)){ 
				return element;
			}			
		}
	};
	findOrCreateMeasure(charToMatch,unitToMatch,valFormula,accToMatch,baseRelSetToMatch,satisfactionTuple,intervalTupleSet,nonDimensional,typeToMatch){
		var m_ref;
		var self= this;
		var baseRelSetToMatchNew = [];
		var intervalTupleSetNew = [];
		_.each(baseRelSetToMatch, function(baseRel){
			if(baseRel.new){
				baseRelSetToMatchNew.push(baseRel.new);	
			}
		});
		_.each(intervalTupleSet, function(intervalTuple){
			if(intervalTuple.new){
				intervalTupleSetNew.push(intervalTuple.new);	
			}
		});		
		if(!typeToMatch){
			if(!satisfactionTuple){
				if(baseRelSetToMatchNew.length === 0){
					typeToMatch = "smm_DirectMeasure";
				}else{
					typeToMatch = "smm_CollectiveMeasure";
				}
			}else{
				typeToMatch = satisfactionTuple.type;
				if(baseRelSetToMatchNew.length === 0 && typeToMatch == "smm_RankingMeasure" || typeToMatch == "smm_GradeMeasure"){
					baseRelSetToMatchNew = [{measurement:satisfactionTuple.measurement}];
				}
			}
		}
		var measure_found = false;
		var Measure = Backbone.Relational.store.getObjectByName("smm.Measure");
		self.get('measureElements').each(function(element){
			if(!measure_found && Backbone.Relational.instanceofmodel(element, Measure) && element.measureMatches(charToMatch,unitToMatch,typeToMatch,accToMatch,baseRelSetToMatchNew,intervalTupleSetNew,!nonDimensional)){ 
				measure_found = true;
				m_ref = element;
			}
		});
		if(!measure_found) {
			if(typeToMatch === 'smm_CollectiveMeasure') {
				var collMeasureId = DataManager.getDataManager().guidGeneratorByOwner(self);
				m_ref = new CollectiveMeasure({id:collMeasureId,'accumulator':accToMatch,name:self.getUniqueProperty('name',charToMatch + " Measure",'measureElements'),libraries:self});
				//m_ref.set('accumulator',accToMatch);
				for(var i=0;i<baseRelSetToMatchNew.length;i++){
					if(!baseRelSetToMatchNew[i].measure){ //this section gets the latest measure and updates the tuple set(not in doc).
						if(baseRelSetToMatchNew[i].component.get('valueMeasurement')){
							var valueMeasurement = baseRelSetToMatchNew[i].component.get('valueMeasurement');
							if(valueMeasurement.get('measurement').length > 0){
								var measure = valueMeasurement.get('measurement').at(0).get('observedMeasure').get('measure');
								if(measure){
									baseRelSetToMatchNew[i].measure=measure;
								}
							}
						}
					}
					if(baseRelSetToMatchNew[i].measure) {
						var bmrel = m_ref.createBaseMeasureRelationship(baseRelSetToMatchNew[i].measure);
						bmrel.set('usePreviousAlternative',baseRelSetToMatchNew[i].usePreviousAlternative);
						bmrel.createRescaledMeasure(baseRelSetToMatchNew[i].offset,baseRelSetToMatchNew[i].multiplier,baseRelSetToMatchNew[i].operation);
						/*if(baseRelSetToMatchNew[i].measure.get('unit').get('name') !== unitToMatch.unitName || baseRelSetToMatchNew[i].offset !== 0 || baseRelSetToMatchNew[i].multiplier !== 1 ||  baseRelSetToMatchNew[i].operation != null) {
							var reMeasureId = DataManager.getDataManager().guidGeneratorByOwner(bmrel);
							var rescaledName = baseRelSetToMatchNew[i].component.get('name')+ " RescaledMeasure";
							var inLinedRescaledMes = new RescaledMeasure({id:reMeasureId,name:rescaledName,rescaledMeasureOwner:bmrel});
							inLinedRescaledMes.set('offset',baseRelSetToMatchNew[i].offset);
							inLinedRescaledMes.set('multiplier',baseRelSetToMatchNew[i].multiplier);
							if(baseRelSetToMatchNew[i].operation){
								var operation = null;
								if(baseRelSetToMatchNew[i].operation instanceof Backbone.Model) {
									operation = baseRelSetToMatchNew[i].operation;
								}else {
									operation = self.get('measureElements').findWhere({name:baseRelSetToMatchNew[i].operation});
								}
								inLinedRescaledMes.set('operation',operation);
							}
							bmrel.set('rescaledMeasure',inLinedRescaledMes);
	
						}*/
						baseRelSetToMatchNew[i].baseMeasureRel = bmrel;
					}
                }
                //m_ref.set('expression',m_ref.getMeasureDetails());
			}
			if(typeToMatch === 'smm_DirectMeasure'){
				var directMeasureId = DataManager.getDataManager().guidGeneratorByOwner(self);
				m_ref = new DirectMeasure({id:directMeasureId,name:self.getUniqueProperty('name',charToMatch+ " Measure",'measureElements'),libraries:self});
			}
			if((typeToMatch === 'smm_GradeMeasure') || (typeToMatch === 'smm_RankingMeasure')){
				var m_s;
				if(satisfactionTuple.measure){
					m_s = satisfactionTuple.measure;				
				}
				if(satisfactionTuple.measurement){
					var mm_s = satisfactionTuple.measurement;
					m_s = mm_s?mm_s.get('observedMeasure').get('measure'):null;					
				}
				if(typeToMatch === 'smm_GradeMeasure') {
					var gradeId = DataManager.getDataManager().guidGeneratorByOwner(self);
					m_ref = new GradeMeasure({id:gradeId,name:self.getUniqueProperty('name',charToMatch + " Measure",'measureElements'),libraries:self});
					if(!nonDimensional){
						var gradeRelationId = DataManager.getDataManager().guidGeneratorByOwner(m_ref);
						var gmr = new GradeMeasureRelationship({id:gradeRelationId,name:m_ref.getUniqueProperty('name',m_ref.get('name')+ " GradeRelationship",'measureRelationships'),measure:m_ref});
						gmr.set('from',m_ref);
						gmr.set('to', m_s);
					}
					for(var i=0; i<intervalTupleSetNew.length; i++){
						var gradeIntId = DataManager.getDataManager().guidGeneratorByOwner(m_ref);
						var grInterval  =new GradeInterval({id:gradeIntId,name:m_ref.getUniqueProperty('name',m_ref.get('name')+ " GradeInterval",'interval'),maximumOpen:intervalTupleSetNew[i].maximumOpen,minimumOpen:intervalTupleSetNew[i].minimumOpen,maximumEndPoint:intervalTupleSetNew[i].maximumEndPoint,minimumEndPoint:intervalTupleSetNew[i].minimumEndPoint, symbol:intervalTupleSetNew[i].symbol,intervalOwner:m_ref,correctiveActions:intervalTupleSetNew[i].correctiveActions});
						if(intervalTupleSetNew[i].smiley != "0"){
							grInterval.set('smiley',intervalTupleSetNew[i].smiley);
							grInterval.set('smileyColor',intervalTupleSetNew[i].smileyColor);
						}
					}
				}
				else {
					var rankingId = DataManager.getDataManager().guidGeneratorByOwner(self);
					m_ref = new RankingMeasure({id:rankingId,name:self.getUniqueProperty('name',charToMatch + " Measure",'measureElements'),libraries:self});
					var rankRelationId = DataManager.getDataManager().guidGeneratorByOwner(m_ref);
					var rmr = new RankingMeasureRelationship({id:rankRelationId,name:m_ref.getUniqueProperty('name',m_ref.get('name')+ " RankingRelationship",'measureRelationships'),measure:m_ref});
					rmr.set('from',m_ref);
					rmr.set('to',m_s);
					for(var i=0; i<intervalTupleSetNew.length; i++){
						var rankId = DataManager.getDataManager().guidGeneratorByOwner(m_ref);
						var rankInterval = new RankingInterval({id:rankId,name:m_ref.getUniqueProperty('name',m_ref.get('name')+ " RankingInterval",'interval'), maximumOpen:intervalTupleSetNew[i].maximumOpen,minimumOpen:intervalTupleSetNew[i].minimumOpen,maximumEndPoint:intervalTupleSetNew[i].maximumEndPoint,minimumEndPoint:intervalTupleSetNew[i].minimumEndPoint, value:intervalTupleSetNew[i].value,intervalOwner:m_ref,correctiveActions:intervalTupleSetNew[i].correctiveActions});
						if(intervalTupleSetNew[i].smiley != "0"){
							rankInterval.set('smiley',intervalTupleSetNew[i].smiley);
							rankInterval.set('smileyColor',intervalTupleSetNew[i].smileyColor);
						}
					}
				}
			}
			m_ref.set('trait',self.getOrCreateCharacteristic(charToMatch));
			if(typeToMatch !== 'smm_GradeMeasure' && unitToMatch) {
				var unitName = unitToMatch.unitName;
				var unitToLink = self.getNestedParent().findOrCreateUnit(unitName,unitToMatch.unitObj); 
				if(unitToLink){
					unitToLink.set({'roundingDigits': unitToMatch.roundingDigits,'significantDecimals': unitToMatch.significantDecimals});
					//unitToLink.set('significantDecimals', unitToMatch.significantDecimals);
				}
				m_ref.set('unit',unitToLink);
				//m_ref.set('formula', valFormula);
			}
			self.LinkOrCreateAndLinkScope(m_ref); 
		}
		return m_ref;
	};
//#endCustomMethods	

	
	}
	path.MeasureLibraryMixin = MeasureLibraryMixin;
	//return MeasureLibraryMixin;
//});