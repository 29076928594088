import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Interval} from './Interval'
import {IntervalMixin} from './IntervalMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/Interval","appbo/smm/IntervalMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Interval,IntervalMixin){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	export class GradeIntervalMixin {
	
	defaults(){
		var ret = {
			type: "smm_GradeInterval"
		}
		return jQuery.extend(Interval.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!GradeIntervalMixin.cummulativeRelations) {
            GradeIntervalMixin.cummulativeRelations = _.union(GradeIntervalMixin.getMixinRelations()
                , IntervalMixin.getCumulativeMixinRelations()
            );
        }
		return GradeIntervalMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_Interval"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			/*{name : "maximumOpen",type : "EBoolean",defaultValue : "null",containingClass : "smm_Interval" },
			{name : "minimumOpen",type : "EBoolean",defaultValue : "null",containingClass : "smm_Interval" },
			{name : "maximumEndPoint",type : "EDouble",defaultValue : "null",containingClass : "smm_Interval" },
			{name : "minimumEndPoint",type : "EDouble",defaultValue : "null",containingClass : "smm_Interval" },*/
			{name : "symbol",type : "EString",defaultValue : "null",containingClass : "smm_GradeInterval" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("intervalOwner") ? this.get("intervalOwner") : this.previousAttributes().intervalOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("intervalOwner") ? this.get("intervalOwner") : this.previousAttributes().intervalOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/GradeIntervalPropertiesTemplate.html",
			templateName : "GradeIntervalPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/GradeIntervalViewModel",
			tabId : "GradeIntervalView",
			tabName: "GradeInterval"
		}
	}
	
	}
	path.GradeIntervalMixin = GradeIntervalMixin;
	//return GradeIntervalMixin;
//});