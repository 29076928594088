import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {CollaborationDiagram} from './CollaborationDiagram'
import {RoleCollaborationDiagramMixin} from './RoleCollaborationDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ProcessDiscoveryDiagram } from './ProcessDiscoveryDiagram'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/RoleCollaborationDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,RoleCollaborationDiagramMixin
, ProcessDiscoveryDiagram){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ProcessDiscoveryDiagram){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ProcessDiscoveryDiagram){
			ProcessDiscoveryDiagram = importPath.ProcessDiscoveryDiagram;
		}
		else{
            import('./ProcessDiscoveryDiagram').then(({ default: ProcessDiscoveryDiagram }) => {
                ProcessDiscoveryDiagram = ProcessDiscoveryDiagram;
			});
			/*require(["appbo/vdml/ProcessDiscoveryDiagram"],function(loadedModule){
				ProcessDiscoveryDiagram = loadedModule;
			});*/
		}
	}
	var RoleCollaborationDiagram = CollaborationDiagram.extend(utils.customExtends({
		relations:RoleCollaborationDiagramMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			CollaborationDiagram.prototype.initialize.apply(this, arguments);
		}
		}
		, new RoleCollaborationDiagramMixin()
	));
	
	RoleCollaborationDiagram.abstract = false;
	RoleCollaborationDiagram.supported = false;
	path.RoleCollaborationDiagram = RoleCollaborationDiagram;
	export {RoleCollaborationDiagram};
//});