import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { VdmlElement } from './VdmlElement'
import { VdmlElementMixin } from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { BindingOperationReference } from './BindingOperationReference'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, BindingOperationReference){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!BindingOperationReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BindingOperationReference){
			BindingOperationReference = importPath.BindingOperationReference;
		}
        else {
            import('./BindingOperationReference').then(({ default: BindingOperationReference }) => {
                BindingOperationReference = BindingOperationReference;
            });
			/*require(["appbo/vdml/BindingOperationReference"],function(loadedModule){
				BindingOperationReference = loadedModule;
			});*/
		}
	}
export class PortDelegationMixin {

    defaults() {
        var ret = {
            type: "vdml_PortDelegation"
        }
        return jQuery.extend(VdmlElement.prototype.defaults.apply(this), ret);
    }
    static getMixinRelations() {
        return _.union([
            {
                type: Backbone.HasOne,
                containingClass: "vdml_PortDelegation",
                key: "bindingOperationReference",
                relatedModel: "vdml.BindingOperationReference",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            }
        ])
    }
    
    static getCumulativeMixinRelations() {
        if (!PortDelegationMixin.cummulativeRelations) {
            PortDelegationMixin.cummulativeRelations = _.union(PortDelegationMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
        return PortDelegationMixin.cummulativeRelations.slice();
    }
    static getSuperTypes() {
        return [
            "vdml_VdmlElement"
        ];
    }
    static getProperties() {
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "represents", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" }
        ]
    }
    getParent() {
        var container;
        if (!container) {
            container = this.get("internalPortDelegationOwner") ? this.get("internalPortDelegationOwner") : this.previousAttributes().internalPortDelegationOwner;
            if (container) {
                return container;
            }
        }
        if (!container) {
            container = this.get("contextBasedPortDelegationOwner") ? this.get("contextBasedPortDelegationOwner") : this.previousAttributes().contextBasedPortDelegationOwner;
            if (container) {
                return container;
            }
        }
        return this;
    }
    getPackagePath(path) {
        if (!path) {
            path = [];
        }
        var container;
        if (!container) {
            container = this.get("internalPortDelegationOwner") ? this.get("internalPortDelegationOwner") : this.previousAttributes().internalPortDelegationOwner;
            if (container) {
                path.unshift({ id: this.get('id'), name: this.get('name') })
                return container.getPackagePath(path);
            }
        }
        if (!container) {
            container = this.get("contextBasedPortDelegationOwner") ? this.get("contextBasedPortDelegationOwner") : this.previousAttributes().contextBasedPortDelegationOwner;
            if (container) {
                path.unshift({ id: this.get('id'), name: this.get('name') })
                return container.getPackagePath(path);
            }
        }
        return path;
    }
    getViewProperties(type) {
        return {
            templatePath: "views/vdml/views/properties/PortDelegationPropertiesTemplate.html",
            templateName: "PortDelegationPropertiesTemplate",
            viewTypeStr: "appviews/vdml/views/properties/PortDelegationViewModel",
            tabId: "PortDelegationView",
            tabName: "PortDelegation"
        }
    }

    copy(sourcePort, targetPort, targetContainer) {
        var delId = DataManager.getDataManager().guidGeneratorByOwner(targetContainer);
        var copyDel;
        var nameCopy;
        if (targetContainer.get('type') === 'vdml_DelegationContext') {
            nameCopy = targetContainer.getUniqueProperty('name', this.get('name'), 'internalPortDelegation');
            copyDel = new this.constructor({ id: delId, name: nameCopy, description: this.get('name'), contextBasedPortDelegationOwner: targetContainer });
        } else {
            nameCopy = targetContainer.getUniqueProperty('name', this.get('name'), 'internalPortDelegation');
            copyDel = new this.constructor({ id: delId, name: nameCopy, description: this.get('name'), internalPortDelegationOwner: targetContainer });
        }
        copyDel.set('source', sourcePort);
        copyDel.set('target', targetPort);
        return copyDel;
    };

    
}
path.PortDelegationMixin = PortDelegationMixin;
	//return PortDelegationMixin;
//});