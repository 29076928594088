import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ValueAdd } from './ValueAdd'
import {ActivityMixin} from './ActivityMixin'
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

	export class Activity2Mixin {
	//#startCustomMethods
		
		createWhatValues(year,period,viewAlternative,whatValueName,value,unit,valFormula, baseTuples,maxChange,bmActivityModal,oldValModel,projectionProfile,valueConstraint){
			var self = this;
			var valueOutputPort = self.createDefaultOutputPort(bmActivityModal);
			var outputId;
			if(oldValModel){
				var newValueAddIdSuffix = window.utils.getSuffix(oldValModel.id);
				outputId = DataManager.getDataManager().getRepositoryId(valueOutputPort.id) + newValueAddIdSuffix;
			}else{
				outputId = DataManager.getDataManager().guidGeneratorByOwner(valueOutputPort);	
			}
			var valueAdd = new ValueAdd({id:outputId,name:valueOutputPort.getUniqueProperty('name', whatValueName,'valueAdd'),description:whatValueName,valueAddOwner:valueOutputPort});
			valueAdd.prime(year,period,value,unit,valFormula,baseTuples,viewAlternative,maxChange,projectionProfile,valueConstraint);
			return valueAdd;
		};
	//#endCustomMethods	
	
	}
	path.Activity2Mixin = Activity2Mixin;
	utils.customExtendClass (Activity2Mixin,new ActivityMixin());