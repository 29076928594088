import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as async from 'async'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import { ValueType } from '../../../../bo/vdml/ValueType'

	var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties", global.version);
	export class Values2ViewModel {
        constructor(model, options) {
            var self = this;
            this.init(model, options);
        }
		dispose(){
			var self = this;
			$("#xaxis"+self.encodeId).jstree('destroy');
			window.cleanViewModel(self);
		}
		/*getValueModalFromPreviousPhases(valId,altModel){
			var preAlt = altModel.getPreviousPhasePrimary();
			if(preAlt){
				valId = preAlt.id + window.utils.getSuffix(valId);
				var valueModal = window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
				if(!valueModal){
					valueModal = self.getValueModalFromPreviousPhases(valId,preAlt);
				} else {
					return valueModal;
				}
			} else {
				return valueModal;
			}
		}*/

		callToJulia(url,json,callback){
			window.utils.startSpinner('refreshValues', 'Refreshing ...');
			DataManager.getDataManager().get('vmpServerService').postAllPromise(url, JSON.stringify(json)).then(function(res) {
			//save res to period dataset
			callback(res);			
			}).catch(error => {
				bootbox.alert("failed "+ error);
				console.error('An error occurred:', error);
				window.utils.stopSpinner('refreshValues');
			});
		}

		extrapolateBaseline(valId,selectedHorizon,qty){
			var self = this;
			var url = "/vdmbee/intrapolation/baseline";
			var params = {};
			var valueModal = window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
			var projectionProfile = valueModal.get("projectionProfile");
			const dataManager = DataManager.getDataManager();
			const currentPlan = dataManager.get('currentPlan');
			const scenarioId = currentPlan.get("defaultScenario");
			const scenarioData = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
			var periodsUntillCurPhase = currentPlan.getNoOfPeriodDatasets();
			params = {baseline_quantity : parseInt(qty), periods:periodsUntillCurPhase, pattern:String(projectionProfile.get('trendModel')).toLocaleLowerCase()}

			if(projectionProfile.get('seasonalFactors').length > 0){
				var seasonalFactors = projectionProfile.get('seasonalFactors').models.map((d)=>{
					return d.get('quantity') ?  parseInt(d.get('quantity')) : 0
				})
				params =  {...params,seasonal_factors:seasonalFactors}
			}
			
			//TODO fetch value of valId
			if(projectionProfile.get('trendModel')==="Linear"){
				params = {...params,growth_rate: projectionProfile.get('rateFactor') ? projectionProfile.get('rateFactor') : 0}
			}else if(projectionProfile.get('trendModel') ==="Exponential"){
				params = {...params,growth_rate:projectionProfile.get('rateFactor')?projectionProfile.get('rateFactor'):0}

			}else if(projectionProfile.get('trendModel') ==="Logarithmic"){
				params = {...params,scaling_factor:projectionProfile.get('rateFactor')?projectionProfile.get('rateFactor'):0}

			}else if(projectionProfile.get('trendModel') ==="Power"){
				params = {...params,exponent:projectionProfile.get('rateFactor')?projectionProfile.get('rateFactor'):0}

			}else if(projectionProfile.get('trendModel') ==="Polynomial"){
				params = {...params,degree:projectionProfile.get('rateFactor')?projectionProfile.get('rateFactor'):0}

				if(projectionProfile.get('coefficients').length > 0){
					var coefficients = projectionProfile.get('coefficients').models.map((d)=>{
						return parseInt(d.get('coefficient'))
					})
					params = {...params,coefficients:coefficients}
				}
			}
			var periodDatasets = scenarioData.getPlanDatasets(currentPlan);
			self.callToJulia(url,params,function(res){
				if(res.forecast.length>0){
					for(let i = 0;i<periodDatasets.length;i++){
						var periodDataset = self.defaultExecutionScenaro.get("input").findWhere({period: periodDatasets[i].period,year: periodDatasets[i].year,periodKind : currentPlan.get("periodKind")});
						if(periodDataset){
							periodDataset.set(valId,res.forecast[i]);
						}						
					}
				}
				self.destroyOfflineTable();
				self.createOfflineTable("offlineTable");
				window.utils.stopSpinner('refreshValues');
			});
				
		}

		extrapolateTrend(valId,selectedHorizon){
			var self = this
			var url = "/vdmbee/intrapolation/sparsedata";
			var params = {};
			const hashMap = {1: 1.1, 2: 2.2, 3: 3.3};
			const jsonString = JSON.stringify(hashMap);
			params.known_quantities = jsonString;
			params.planning_horizon = 2;
			params.pattern = "abc";
			params.seasonal_factors = [1.25,2.25];
			self.callToJulia(url,params);
		}

		interpolate(valId,selectedHorizon){
			var self = this
			var url = "/vdmbee/intrapolation/timeSeries";
			var params = {};
			params.season_length = 2;
			params.periods = 2;
			params.growth_rate = 1.00;
			params.time_series = [1.25,2.25];
			self.callToJulia(url,params);
		}

		interpolateextrapolate(valId,selectedHorizon){
			var self = this
			var url = "/vdmbee/intrapolation/twopoint";
			var params = {};
			params.start_quantity = 1.00;
			params.end_quantity = 1.00;
			params.planning_horizon = 2;
			params.pattern = "abc";
			params.seasonal_factors = [1.25,2.25];
			self.callToJulia(url,params);
		}
		
		/*showAggregationView(view) {
			var self = this;
			var valueId = view.id;
			var id = "aggregation" + window.utils.htmlEscape(valueId);
			var altModel = window.utils.getElementModel(view.altId,['transformation.Alternative']);
			var valueModal = window.utils.getElementModel(valueId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
			if(!valueModal){
				var phaseMasterAlt = altModel.getParent().get('master');
				if(phaseMasterAlt != altModel){
					valueModal = window.utils.getElementModel(phaseMasterAlt.id + window.utils.getSuffix(valueId),['vdml.ValuePropositionComponent','vdml.ValueAdd']);
				}
				if(!valueModal){
					valueModal = self.getValueModalFromPreviousPhases(view.id,altModel);
				}
			}
			if (valueModal) {
				var isTimestamp = false;
				var mest;
				//valueModal = altModel.getModelLinkedSync(valueModal);
				if (valueModal) {
					var altScenario = altModel.getMainScenario(valueModal.getNestedParent());
					var observedMeasure = altScenario ? altScenario.getObservedMeasureWithMeasurements(valueModal.get('valueMeasurement'), isTimestamp, view.timeStamp) : null;
					mest = observedMeasure ? observedMeasure.get('measurements').at(0) : null;
					var addOptions = { 'currentAlternativeModel': altModel };
					if (!mest) {
						mest = valueModal;
					}
					var ValueElementMixin = Backbone.Relational.store.getObjectByName("vdml.ValueElementMixin");
					window.getAndCreateModalDialog(true, id, ValueElementMixin, mest, "AggregationView", function (changeInValue) {
						if (changeInValue) {
							window.utils.startSpinner('refreshValues', 'Refreshing ...');
							DataManager.getDataManager().invokeValueUpdates(function () {
								self.destroyOfflineTable();
								self.createOfflineTable("offlineTable");
								window.utils.stopSpinner('refreshValues');
							});
						}
					}, addOptions);
				}
			}
		};*/
			
			buildOrderObject(dt, key, column) {
				var self = this;
				//var rowData = dt.row("#" + key).data();
				var rowData = dt.row("#" + key).data();
				if (typeof rowData === 'undefined') {
					return {};
				} else {
					var object = self.buildOrderObject(dt, rowData['parent'], column);
					var a = object;
					while (typeof a.child !== 'undefined') {
						a = a.child;
					}
					a.child = {};
					a.child.key = rowData['key'];
					a.child.value = rowData[column];
					return object;
				}
			};

			getQuantity(comp,inputPeriodDataset,resultPeriodDataset){
				var self = this;
				var qty = undefined;
				if (!comp){
					return qty
				}
				if(self.enableDirectEditing){
					if(inputPeriodDataset && comp.get("valueType") == ValueType.Atomic){
						qty = inputPeriodDataset.get(comp.get('id'));
					} else if(resultPeriodDataset && comp.get("valueType") == ValueType.Aggregated) {
						qty = resultPeriodDataset.get(comp.get('id'));
					}
				} else {
					if(resultPeriodDataset){
						qty = resultPeriodDataset.get(comp.get('id'));
						if(!qty){
							qty = inputPeriodDataset.get(comp.get('id'));
						}
					} else {
						qty = inputPeriodDataset.get(comp.get('id'));
					}
				}				
				return qty
			}

			subComponentValues(subComp,keyIndex,valObj,altColumnList,inputPeriodDataset, resultPeriodDataset, dynamicRows,phaseAltName,name,comp){
				var self = this;
				var calc = false;
				var subCompVal
				var childValObj 

				var compSuffix = comp.get('id').substr(comp.get('id').lastIndexOf('@') + 1);

				for (var z = 0; z < dynamicRows.length; z++) {
					if (dynamicRows[z]["suffix"] == name+compSuffix) {
						childValObj = dynamicRows[z];
					}
					if (childValObj != null) {
						break;
					}
				}

				subCompVal = self.getQuantity(subComp,inputPeriodDataset,resultPeriodDataset)

				var subCompName

				if(name ==='Recipient Opinion'){
					if(subComp && subComp.get('unit')){
						subCompName =  name + " " + "("+subComp.get('unit').get('name')+")"
					}else {
						subCompName = name + " " + "("+comp.get('unit').get('name')+")"
					}
				}

				if(name ==='Weight'){
					subCompName = name + " " + "(%)"
					if(subComp && subComp.get('unit')){
						subCompName = name + " " + "("+subComp.get('unit').get('name')+")"
					}
				}

				if(name === 'Satisfaction'){
					calc = true;
					subCompName = name + ""
					if(subComp && subComp.get('isNominal')===false && subComp.get('unit')){
						subCompName = name + " " + "("+subComp.get('unit').get('name')+")"
					}
				}

				/*if (subComp && subComp.get('valueType') != ValueType.Atomic) {
					calc = true;
				}*/

				if(childValObj==undefined){
					childValObj = { "DT_RowId": keyIndex, "level": 4, "key": keyIndex, "parent": valObj.key, "name":subCompName, "type": 'Value', "suffix": name+compSuffix, "calc": calc };
					for (var j = 0; j < altColumnList.length; j++) {
						var altName = altColumnList[j];
						if(!childValObj[altName]){
							childValObj[altName] = "-";
						}
					}
					if(subCompVal !== undefined && subCompVal){
						childValObj[phaseAltName] = subCompVal
					}else {
						childValObj[phaseAltName] = "-"
					}	
					dynamicRows.push(childValObj);
				}else if (phaseAltName) {
					if(subCompVal!==undefined && subCompVal){
						childValObj[phaseAltName] = subCompVal
					}else {
						childValObj[phaseAltName] = "-"
					}
				}		
				keyIndex = keyIndex + 1;
				return keyIndex
			}

			checkValues(planComponents, valueLevel, dynamicRows, keyIndex, phaseAltName, inputPeriodDataset,resultPeriodDataset, altColumnList) {
				var self = this;
				planComponents.each(function (comp) {
					var valObj;
					var compSuffix = comp.id.substr(comp.id.lastIndexOf('@') + 1);
					for (var z = 0; z < dynamicRows.length; z++) {
						if (dynamicRows[z]["suffix"] == compSuffix) {
							valObj = dynamicRows[z];
						}
						if (valObj != null) {
							break;
						}
					}
					var mstValueUnit = "-";
					var calc = false;
					var smileyValue = "";
					var smileyColor = "";
					var isterative = false;
					var mstValueUnit;

					mstValueUnit = self.getQuantity(comp,inputPeriodDataset,resultPeriodDataset);
					
					// 
					/*var valueMsmt = null;//self.getComponentMeasurement(comp, 'valueMeasurement', scenario);
					if(valueList && valueMsmt){
						var compValues = valueList[valueMsmt.id];
						if(compValues){
							var significantDecimals = 2;//TODO
							mstValueUnit = window.utils.getRoundedValues(compValues[0]['Value'], significantDecimals) + ' ' + compValues[0]['Unit'];
							
						}
					} else {
						var selectedInterval = null;//comp.getSatisfactionInterval(scenario);
						if(selectedInterval){
							smileyValue = selectedInterval.get('smiley') ? selectedInterval.get('smiley') : '';
							smileyColor = selectedInterval.get('smileyColor') ? selectedInterval.get('smileyColor') : '#000000';
							//satisfactionSmiley = comp.fetchSmileySrc(smileyValue);
						}
						if(valueMsmt){
							var observedMeasure = valueMsmt.getParent();
							var measure = observedMeasure.get('measure');
							if (comp.get('aggregatedFrom').length > 0) {
								calc = true;
								if (comp.get('isIterative')) {
									var cyclePath = valueMsmt.getCyclicPath();
									if (cyclePath && cyclePath.length > 0){
										isterative = true;
									}
									
								}
							}
							var unit = measure.get('unit');
							var significantDecimals = unit ? unit.get('significantDecimals') : 2;
							var mstVal = window.utils.getRoundedValues(valueMsmt.get('value'), significantDecimals);
							if(mstVal && mstVal != '-'){
								mstVal = window.utils.thousandSeparator(mstVal);
							}
							mstValueUnit = mstVal + ' ' + unit.get('name');
						} else if(selectedInterval){//Gradeonly
							mstValueUnit = selectedInterval.get('symbol');
						}
						
					}*/
					if (comp.get('valueType') != ValueType.Atomic) {
						calc = true;
					}
					var level 

					if(comp.get('type')==="vdml_ValuePropositionComponent"){
						level = 3
					}else {
						level = 4
					}
					
					if (valObj == null) {
						var isplanValue = false;
						if (DataManager.getDataManager().getRepositoryId(comp.id) == window.plansKey) {
							isplanValue = true;
						}
						var compName = comp.get('name') + " " + "("+comp.get('unit').get('name')+")"
						valObj = { "DT_RowId": keyIndex, "level": level, "key": keyIndex, "parent": valueLevel, "name": compName, "type": self.titleMap[comp.get('type')], "suffix": compSuffix, "calc": calc, planValue:isplanValue };
						for (var j = 0; j < altColumnList.length; j++) {
							var altName = altColumnList[j];
							if(!valObj[altName]){
								valObj[altName] = "-";
							}
						}
						if(mstValueUnit){
							valObj[phaseAltName] = mstValueUnit
						}else{
							valObj[phaseAltName] = "-"
						};
						//valObj[phaseAltName] = {'value':mstValueUnit,"altId":altId,"customId":comp.id,"whenObserved": null};
						if(smileyValue){
							valObj[phaseAltName + '-satisfactionSmiley'] = smileyValue;
							valObj[phaseAltName + '-smileyColor'] = smileyColor;
						}
						// valObj[phaseAltName + '-isIterative'] = isterative;
						dynamicRows.push(valObj);
						keyIndex = keyIndex + 1;
						
					} else if(phaseAltName){
						if(smileyValue){
							valObj[phaseAltName + '-satisfactionSmiley'] = smileyValue;
							valObj[phaseAltName + '-smileyColor'] = smileyColor;
						}
						// valObj[phaseAltName + '-isIterative'] = isterative;
						if(mstValueUnit){
							valObj[phaseAltName] = mstValueUnit
						}else {
							valObj[phaseAltName] = "-"
						};

					
					}
					if(comp.get('type')==="vdml_ValuePropositionComponent" && !comp.get('isNominal')){
						keyIndex = self.subComponentValues(comp.get("satisfactionLevel"),keyIndex,valObj,altColumnList,inputPeriodDataset,resultPeriodDataset,self.dynamicRows,phaseAltName,"Satisfaction",comp)
						if(self.enableDirectEditing){
							keyIndex = self.subComponentValues(comp.get("percentageWeight"),keyIndex,valObj,altColumnList,inputPeriodDataset,resultPeriodDataset,self.dynamicRows,phaseAltName,"Weight",comp)
							keyIndex = self.subComponentValues(comp.get("recipientOpinion"),keyIndex,valObj,altColumnList,inputPeriodDataset,resultPeriodDataset,self.dynamicRows,phaseAltName,"Recipient Opinion",comp)
						} else {
							keyIndex = comp.get("percentageWeight") ? self.subComponentValues(comp.get("percentageWeight"),keyIndex,valObj,altColumnList,inputPeriodDataset,resultPeriodDataset,self.dynamicRows,phaseAltName,"Weight",comp) : keyIndex;
							keyIndex = comp.get("recipientOpinion") ? self.subComponentValues(comp.get("recipientOpinion"),keyIndex,valObj,altColumnList,inputPeriodDataset,resultPeriodDataset,self.dynamicRows,phaseAltName,"Recipient Opinion",comp) : keyIndex
						}
					}
					
				});
				return keyIndex;
			};

			

	generateMetricsCSV() {
		// currentPlan.scenarioExportDialog();
		DataManager.getDataManager().get('currentPlan').scenarioExportDialog([]);
	};
	
	
	createOfflineTable(tableName){
		var self = this;
		/*self.showOnlyValues = false;
		if(self.selectedChartType() == "Show Only Values"){
			self.showOnlyValues = true;
		}*/
		var dynamicColumns = [{ title: 'Select', targets: 0 },{ title: 'Path', targets: 1 },{ title: 'Key', targets: 2 },
					{ title: 'Parent', targets: 3 },{ title: 'Name', targets: 4 },{ title: 'Type', targets: 5}];
				var columnRendering = [{
					"class": "details-control",
					"orderable": false,
					"data": null,
					"defaultContent": "",
					"width" :100,
					"createdCell": function (td, cellData, rowData, row, col) {
						if (rowData.level > 0) {
							td.className = td.className + ' level-' + rowData.level;
							if (rowData.type == "Value"){
								td.parentElement.setAttribute("suffix",rowData.suffix);
								td.parentElement.setAttribute("planValue",rowData.planValue);
							} /*else if(self.showOnlyValues){
								td.parentElement.style.display = "none";
							}*/
						}/* else if(self.showOnlyValues){
							td.parentElement.style.display = "none";
						}*/
					}
				}, {
					"data": "level",
					"visible": false
				}, {
					"data": "key",
					"visible": false
				}, {
					"data": "parent",
					"visible": false
				}, {
					"data": "name",
					"type": "custom",
					"render": function (data, type, full, meta) {
							var order = self.buildOrderObject($('#'+tableName).DataTable(), full['key'], 'name').child;
							switch (type) {
								case "display":
									return data;
								case "filter":
									return data;
								case "sort":
									return order;
							}
							return data;
						}
					},
					{
						"data": "type",
                         "visible" : true
					}
				]
				var plan = DataManager.getDataManager().get('currentPlan');
				var targetIndex = 6;

				
					// var alternatives = phases.at(j).get('phaseAlternative');
					//var phaseAltName = phases.at(j).get('name') + "/" + alternatives.at(i).get('name');
					//self.altMap[phaseAltName] = alternatives.at(i).id;
					//phaseAltName = phaseAltName.replace(/\./g,'\\.');
					// var steps = self.scenario.get("step").findWhere({alternativeId: alternatives.at(i).id});
				var steps = self.scenario.get("step")
				for (var step of steps.models){
					var periodsLen = step.get('noPeriods');
					var period = step.get("startPeriod");
					for(var l = 0; l < periodsLen; l++){
						if(l != 0){
							period++;
						}
						let yearPeriod = utils.calculateYearPeriod(self.scenario.get("startTime") , period,plan.get("periodKind"));
						//var phaseAltName = phases.at(j).get('name') + "/" + alternatives.at(i).get('name');
						var periodKind =  self.model.getPeriodKinds(yearPeriod.year,yearPeriod.period,plan.get("periodKind"));
						var periodKindText = periodKind?.text || yearPeriod.period;
						var phaseAltName = yearPeriod.year + "-" + periodKindText;							
						self.altMap[phaseAltName] = step.get('alternativeId');
						self.yearPeriodMap[phaseAltName] = yearPeriod;
						dynamicColumns.push({
							title: phaseAltName,
							targets: targetIndex
						});
						columnRendering.push({
							"data": phaseAltName,
							"type": "custom",
							"className": "text-right",
							"render": function (data, type, full, meta) {
								var order = self.buildOrderObject($('#'+tableName).DataTable(), full['key'], phaseAltName).child
								switch (type) {
									case "display":
										return data;
									/*case "filter":
										var val = data != '-'?data.value:data;
										return val;
									case "sort":
										return order;*/
								}
								return data;
							}
						});
						targetIndex++;
					}

				}
			
				


				
						
				
				var dt = $('#'+tableName).DataTable({
					"data": self.dynamicRows,
					"bLengthChange": true,
					"pageLength": 50,
					"ordering": false,
					"pageResize": true,
					"columnDefs": dynamicColumns,
					"columns": columnRendering,
                    "bStateSave": true,
					/*"order": [
						[4, 'asc']
					]*/
					"fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
						if(aData["type"] === "Value"){
							if(aData["calc"] == true){
								var imageUrl = '/img/calculator.png';
								$('td:eq(1)', nRow).css('background', 'url(' + imageUrl + ') no-repeat right');
							}							
							//var svgSmiliesList = window.utils.getSmiliesList();
							//var theadChildrens = $("#offlineTable").children().find('tr').children();
							$('td', nRow).each(function () {
								if (this.className == " text-right") {
									if (!self.enableDirectEditing){
										var a = document.createElement('a');
										a.style.color = 'inherit';
										a.style.cursor= 'pointer';
										a.setAttribute('id',aData["suffix"]);
										var val = this.innerText;
										a.appendChild(document.createTextNode(val));
										this.innerText = '';
										this.append(a);
										$(a).click(function(event){
											event.stopPropagation();
											var qty = event.currentTarget.innerText;
											var phaseAltName = $('tr th').eq(event.currentTarget.parentElement.cellIndex)[0].innerHTML;
											var altId = self.altMap[phaseAltName];
											var suffix = event.currentTarget.parentElement.parentElement.getAttribute('suffix');
											var valId = altId+suffix;
											var planValue = event.currentTarget.parentElement.parentElement.getAttribute('planValue');
											if(planValue == 'true'){
												valId = window.plansKey+suffix;
											}
											self.showProjectionDropdown(event,valId,qty);	
										});
									}
									/*var colu = theadChildrens[this.cellIndex].innerText;
									if(aData[colu + "-satisfactionSmiley"]){
										var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
										svg.setAttribute("width", 21);
										svg.setAttribute("height", 20);
										svg.setAttribute("style",'vertical-align:bottom;');
										svg.setAttribute("viewBox", "-3 -2 26 26");
										svg.setAttribute("fill", aData[colu + "-smileyColor"]);
										//svg.setAttribute("correctiveActions",found[8]);
										//svg.style.cursor = 'pointer';
										var link = document.createElementNS("http://www.w3.org/2000/svg", "path");
										var smileySrc = '';
										for(var j=0;j<svgSmiliesList.length;j++){
											if(svgSmiliesList[j].value === aData[colu + "-satisfactionSmiley"]) {
												smileySrc = svgSmiliesList[j].src;
												break;
											}
										}
										link.setAttribute("d", smileySrc);
										svg.appendChild(link);
										//$('td:eq(3)', nRow).append(svg);
										this.append(svg);
									}
									if(aData[colu + "-isIterative"] == true){
										var span = document.createElement('span');
										span.style.color = 'orange';
										span.style.cursor= 'pointer';
										span.className = "glyphicon glyphicon-repeat";
										//span.setAttribute('id',aData["suffix"]);
										this.append(span);
									}*/
									if(aData["calc"] != true && self.enableDirectEditing){
										this.setAttribute('contenteditable', true);
										$(this).on("keydown", function (event) {
											$(this).prop('oldValue', $(this).val());
											window.utils.checkNumericValue(event, this);
										});
										$(this).on("keyup", function (event) {
											let currentValue = $(event.target).val();
											var decimalLimit = 4;
											var rgx = new RegExp(`^\\-?[0-9]*\\.?[0-9]{0,${decimalLimit}}$`);
											if (!rgx.test(currentValue)) {
												$(event.target).val($(this).prop('oldValue'));
											}
										});
										$(this).blur(function(event){
											//var tr = event.currentTarget.parentElement;
											var qty = event.currentTarget.innerText;
											var phaseAltName = $('tr th').eq(event.currentTarget.cellIndex)[0].innerHTML;
											var altId = self.altMap[phaseAltName];
											var suffix = event.currentTarget.parentElement.getAttribute('suffix');
											var valId = altId+suffix;
											var planValue = event.currentTarget.parentElement.getAttribute('planValue');
											if(planValue == 'true'){
												valId = window.plansKey+suffix;
											}
											self.valueChange(valId,qty,phaseAltName);
										});
									}
									/*$(a).click(function(event){
										var currentTarget = event.currentTarget;
										var index = currentTarget.parentElement.cellIndex;
										var phaseAltName = $('tr th').eq(index)[0].innerHTML;
										var altId = self.altMap[phaseAltName];
										var suffix = currentTarget.getAttribute('id');
										var valId = altId+suffix;
										var planValue = $(currentTarget.parentElement.parentElement).children()[0].getAttribute('planValue');
										if(planValue == 'true'){
											valId = window.plansKey+suffix;
										}
										var obj1 = {id:valId,altId:altId,timeStamp:null};
										self.showAggregationView(obj1);
									});*/
								}
							});
						}
					}
				});
				$('#'+tableName).on('init.dt', function () {
					dt.columns([3]).search('^(0)$', true, false).draw();
				});
				var displayed = new Set(_.pluck(this.dynamicRows, 'key'));
				$('#'+tableName+' tbody').on('click', 'tr td:first-child', function () {
					var tr = $(this).closest('tr');
					var row = dt.row(tr);
					var key = row.data().key;
					if (displayed.has(key)) {
						var childKeyList = [];
						function getChildKeys(childKeyList,key){
							for (var k = 0; k < self.dynamicRows.length; k++) {
								if (self.dynamicRows[k]["parent"] == key) {
									var childKey = self.dynamicRows[k].key;
									childKeyList.push(childKey);
									getChildKeys(childKeyList,childKey);
								}
							}
							return childKeyList
						}
						getChildKeys(childKeyList,key);
						var childList = childKeyList.length;
						while(childList--){
							displayed.delete(childKeyList[childList]);
						}							
						displayed.delete(key);
						tr.removeClass('details');
					} else {
						displayed.add(key);
						tr.addClass('details');
					}
					var regex = "^(0";
					displayed.forEach(function (value) {
						regex = regex + "|" + value;
					});
					regex = regex + ")$";
					dt.columns([3]).search(regex, true, false).draw();
				});		
			}

			showProjectionDropdown(event,valId,qty){
				var self = this;
				const arr = []
				//var altId = self.altMap[phaseAltName];
				var values =  window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
				if(values.get('valueType') == ValueType.Atomic){
					const currentPlan = DataManager.getDataManager().get('currentPlan');
					const scenarioId = currentPlan.get("defaultScenario");
					const scenarioData = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
					var periodDatasets = scenarioData.getPlanDatasets(currentPlan);
					for(let i = 0;i<periodDatasets.length;i++){
						var periodDataset = self.defaultExecutionScenaro.get("input").findWhere({period: periodDatasets[i].period,year: periodDatasets[i].year,periodKind : currentPlan.get("periodKind")});
						if(periodDataset.get(valId)!==undefined){
							if(arr.length>2){
								break;
							}
							arr.push(periodDataset.get(valId));
							
						}
					}
					var options = ["Extrapolate baseline", "Extrapolate trend", "Interpolate", "Interpolate-extrapolate"];
					if(arr.length>1 || periodDatasets.length===1){
						options = ["Extrapolate trend", "Interpolate", "Interpolate-extrapolate"]
					}

					function populateDropdown(menuElement, options, event) {
						menuElement.innerHTML = ""; // Clear existing buttons
						options.forEach(option => {
							const button = document.createElement("button");
							button.textContent = option;
							button.style.display = "block";
							button.style.width = "100%";
							button.style.border = "none";
							button.style.background = "none";
							button.style.padding = "10px";
							button.style.cursor = "pointer";
							button.style.textAlign = "left";
							button.onmouseover = () => button.style.backgroundColor = "#f0f0f0";
							button.onmouseout = () => button.style.backgroundColor = "white";
							button.onclick = () => {
								var currentTarget = event.currentTarget;
								var index = currentTarget.parentElement.cellIndex;
								var phaseAltName = $('tr th').eq(index)[0].innerHTML;
								var altId = self.altMap[phaseAltName];
								var suffix = currentTarget.getAttribute('id');
								var valId = altId+suffix;
								var planValue = $(currentTarget.parentElement.parentElement).children()[0].getAttribute('planValue');
								if(planValue == 'true'){
									valId = window.plansKey+suffix;
								}
								menuElement.style.display = "none";
								if(option == "Extrapolate baseline") {
									window.vdmModelView.extrapolateBaseline(valId,phaseAltName,event.currentTarget.innerHTML);
								} else if(option == "Extrapolate trend") {
									window.vdmModelView.extrapolateTrend(valId,phaseAltName);
								} else if(option == "Interpolate") {
									window.vdmModelView.interpolate(valId,phaseAltName);
								} else if(option == "Interpolate-extrapolate") {
									window.vdmModelView.interpolateextrapolate(valId,phaseAltName);
								} else {
									console.log(`You clicked: ${option}`);
								}
								
							};
							menuElement.appendChild(button);
						});
					}

					function showDropdownMenu(event) {
						const dropdownMenu = document.getElementById("dropdownMenu");
						const cellRect = event.target.getBoundingClientRect();
						populateDropdown(dropdownMenu, options, event);
						var left = cellRect.left - 50;
						var top = cellRect.bottom - 50;
						dropdownMenu.style.display = "block";
						dropdownMenu.style.left = `${left}px`;
						dropdownMenu.style.top = `${top}px`;

						function handleClickOutside(event) {
							var self = this;
							if (!dropdownMenu.contains(event.target) && !event.target.classList.contains('dropdown-cell')) {
								dropdownMenu.style.display = "none";
								document.removeEventListener('click', handleClickOutside);
							}
						}
						document.addEventListener('click', handleClickOutside);
					}
					showDropdownMenu(event);
				}
			}

			destroyOfflineTable(){
				window.utils.setDatatableEmpty('offlineTable');
				$("#offlineTable_wrapper").html('');
				$('<table id="offlineTable" class="display" width="100%" cellspacing="0">    </table>').insertAfter($('#offlineTable_wrapper'));
				$("#offlineTable_wrapper").remove();
			}

			valueChange(valId,qty,phaseAltName){
				var self = this;
				var yearPeriod = self.yearPeriodMap[phaseAltName];
				const currentPlan = DataManager.getDataManager().get('currentPlan');
				var	inputPeriodDataset = self.defaultExecutionScenaro.get("input").findWhere({period: yearPeriod.period,year: yearPeriod.year,periodKind : currentPlan.get("periodKind")});
				var valModal = window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
				if(inputPeriodDataset && valModal){
					inputPeriodDataset.set(valId,qty);
				}
			}
		
			getXAxisData() {
				var self = this;
				var xAxisData = [];
				var currentPlan = DataManager.getDataManager().get('currentPlan');
				var phase = currentPlan.get('phase').findWhere({ nextPhase: null });
				while (phase) {
					var phaseData = {};
					xAxisData.unshift(phaseData);
					phaseData.id = phase.get('id');
					phaseData.parent = '#';
					phaseData.text = phase.get('name');
					phaseData.selected = true;
					self.xAxisSelected.push(phaseData.id);
		
					var phasePrimary = phase.get('primary');
					var phaseAlts = phase.get('phaseAlternative');
					phaseAlts.each(function (phaseAlt) {
						var altData = {};
						altData.id = phaseAlt.get('id');
						altData.parent = phase.get('id');
						altData.text = phaseAlt.get('name');
						xAxisData.push(altData);
						if ((phasePrimary === phaseAlt)) {
							altData.state = { opened: true, selected: true };
						}
						self.xAxisSelected.push(phaseAlt.get('id'));
					});
					phase = phase.get('previousPhase');
				}
				return xAxisData;
			};

		afterRenderView(node, view) {
			var self = view;
			var xAxisData = self.getXAxisData();
			self.createOfflineTable("offlineTable");
			$(".switch-input").change(function() {
				self.enableDirectEditing = !self.enableDirectEditing;
				self.showPeriods(!self.showPeriods());
				self.destroyOfflineTable();
				self.renderDynamicRows();
				self.createOfflineTable("offlineTable");
			});
			var xaxisNode = $("#xaxis" + self.encodeId);
			xaxisNode.jstree({
				'core' : {'data' : xAxisData,'themes':{"icons":false,"dots":false}},
				"checkbox" : {"three_state" : true},		
				"plugins" : [ "wholerow", "checkbox" ]
			}).on("changed.jstree", function (e, data) {

			}).on("select_node.jstree", function (e, data) {
				var currentNode = data.node.id;
				var parentNode = data.node.parent;
				if(parentNode === '#'){
					var phases = self.model.get('phase');
					phases.each(function(phase){
						if(phase.get('id') == currentNode){
							self.xAxisSelected.push(currentNode);
							var phaseAlts = phase.get('phaseAlternative');
							phaseAlts.each(function(alt){
								self.xAxisSelected.push(alt.id);
							});
						}
					});
				} else {
					var parentPhase = self.model.get('phase').get(parentNode);
					if (parentPhase) {
						var children = data.instance.get_node(parentNode).children;
						_.each(children, function (childNode) {
							if (childNode == currentNode) {
								self.xAxisSelected.push(childNode);
							}
						});
					}
					self.destroyOfflineTable();
					self.createOfflineTable("offlineTable");
				}
			}).on("deselect_node.jstree", function (e, data) {
				var currentNode = data.node.id;
				const index = self.xAxisSelected.indexOf(currentNode);
				if (index > -1) {
					self.xAxisSelected.splice(index, 1);
				}
				self.destroyOfflineTable();
				self.createOfflineTable("offlineTable");
			}).on("open_node.jstree", function (e, data) {
				
			}).on("close_node.jstree", function (e, data) {
				
			});
			var dropdownMenu = $("#dropdownMenu"+self.encodeId);
			$('#dropdown'+self.encodeId).on('click', function(event) {
    			event.stopPropagation();
    			dropdownMenu.toggle();
			});
			$('#ValuesView').on('click', function(event) {
			    if (!dropdownMenu.is(event.target) && dropdownMenu.has(event.target).length === 0) {
			        dropdownMenu.hide();
			    }
			});
			window.utils.stopSpinner('valueView');
		}
		calculate(){
			var self = this;
			var dataManager = DataManager.getDataManager();
			if(self.defaultExecutionScenaro.get('lastCalculated') && self.skipBuiding){
				window.utils.startSpinner('calculate', "Calculating...");
				dataManager.calculateJuliaModel(function(){
					self.showLastCalulatedDate();
					self.destroyOfflineTable();
					self.renderDynamicRows();
					self.createOfflineTable("offlineTable");
					window.utils.stopSpinner('calculate');
				});
			} else {
				window.utils.startSpinner('calculate', "Building...");
				dataManager.buidlJuliaModel(function(err){
					if(err){
						window.utils.stopSpinner('calculate');
						return;
					}
					window.utils.showSpinnerText("Calculating...");
					window.setTimeout(function () {
						dataManager.calculateJuliaModel(function(){
							self.showLastCalulatedDate();
							self.destroyOfflineTable();
							self.renderDynamicRows();
							self.createOfflineTable("offlineTable");
							window.utils.stopSpinner('calculate');
						});
					}, 500);
				});
			}
		}
		showLastCalulatedDate(){
			var self = this;
			if(self.defaultExecutionScenaro.get('lastCalculated') != null){
				var lastCalc = new Date(self.defaultExecutionScenaro.get('lastCalculated'));
				self.lastCalculate('Last Calculated on '+ lastCalc.toLocaleDateString() + " "+ lastCalc.toLocaleTimeString());
			} else {
				self.lastCalculate('');
			}
		}

		renderDynamicRows(){
			var self = this;
			var plan = DataManager.getDataManager().get('currentPlan');
			self.dynamicRows = []; 
			function creatDynamicRow(name,id,key,level,parent,phaseAltName,type,altColumnList){
				var valObj;
				var compSuffix = id.substr(id.lastIndexOf('@') + 1);
				for (var k = 0; k <	self.dynamicRows.length; k++) {
					if (self.dynamicRows[k]["suffix"] == compSuffix) {
						valObj = self.dynamicRows[k];
					}
					if (valObj != null) {
						break;
					}
				}
				if (valObj == null) {
					valObj = {};
					valObj["DT_RowId"] = key;
					valObj["level"] = level;
					valObj["key"] = key;
					valObj["parent"] = parent;
					valObj["name"] = name;
					valObj["type"] = type;
					valObj["suffix"] = compSuffix;
					if(altColumnList) {
						for (var j = 0; j < altColumnList.length; j++) {
							var altName = altColumnList[j];
							if(!valObj[altName]){
								valObj[altName] = "-";
							}
						}
					}
				}
				if(phaseAltName){
					valObj[phaseAltName] = "-";
				}
				return valObj;
			}
			//---------------- plan section start---------------//
			var altColumnList = [];
			var keyIndex = 1;
			var planObj = creatDynamicRow(plan.get('name'), plan.get('id'), keyIndex, 0, 0, null ,self.titleMap[plan.get("type")]);
			//planObj["type"] = self.titleMap[plan.get("type")]
			self.dynamicRows.push(planObj);
			keyIndex++;
			var planCriterionSet = plan.getOrCreatePlanCriterionSet();
			
			var planComponents = planCriterionSet.get('component');
		
			var steps = self.scenario.get("step")
			for(var step of steps.models){
				var periodsLen = step.get('noPeriods');
				var period = step.get("startPeriod");
				for(var l = 0; l < periodsLen; l++){
					if(l != 0){
						period++;
					}
					let yearPeriod = utils.calculateYearPeriod(self.scenario.get("startTime") , period,plan.get("periodKind") );
					//var phaseAltName = phases.at(j).get('name') + "/" + alternatives.at(i).get('name');
					var periodKind =  self.model.getPeriodKinds(yearPeriod.year,yearPeriod.period,plan.get("periodKind"));
					var periodKindText = periodKind?.text || yearPeriod.period;
					var phaseAltName = yearPeriod.year + "-" + periodKindText;
					altColumnList.push(phaseAltName);
					planObj[phaseAltName] = "-";
					
					var	 inputPeriodDataset = self.defaultExecutionScenaro.get("input").findWhere({period: yearPeriod.period,year: yearPeriod.year,periodKind : plan.get("periodKind")});
					var resultPeriodDataset = self.defaultExecutionScenaro.get("result").findWhere({period: yearPeriod.period,year: yearPeriod.year,periodKind : plan.get("periodKind")});
					keyIndex = self.checkValues(planComponents, 1, self.dynamicRows, keyIndex, phaseAltName, inputPeriodDataset,resultPeriodDataset, altColumnList);
				}

			}
				
			
			//---------------- plan section end---------------//
			//---------------- package section start---------------//
			
				var steps = self.scenario.get("step");
				for(var step of steps.models){
					var periodsLen = step.get('noPeriods');
					var period = step.get("startPeriod");
					for(var x = 0; x < periodsLen; x++){
						if(x != 0){
							period++;
						}
						let yearPeriod = utils.calculateYearPeriod(self.scenario.get("startTime") , period,plan.get("periodKind") );
						//var phaseAltName = phases.at(j).get('name') + "/" + alternatives.at(i).get('name');
						var periodKind =  self.model.getPeriodKinds(yearPeriod.year,yearPeriod.period,plan.get("periodKind"));
						var periodKindText = periodKind?.text || yearPeriod.period;
						var phaseAltName = yearPeriod.year + "-" + periodKindText;
						var	 inputPeriodDataset = self.defaultExecutionScenaro.get("input").findWhere({period: yearPeriod.period,year: yearPeriod.year,periodKind : plan.get("periodKind")});
						var resultPeriodDataset = self.defaultExecutionScenaro.get("result").findWhere({period: yearPeriod.period,year: yearPeriod.year,periodKind : plan.get("periodKind")});
						var altModel = window.utils.getElementModel(step.get('alternativeId'),['transformation.Alternative']);
						var phaseDesignPart = altModel.get("phaseDesignPart");
						for (var k = 0; k < phaseDesignPart.length; k++) {
							var packId = phaseDesignPart.at(k).get("beepReference");
							if (packId.indexOf("Common") == -1 && phaseDesignPart.at(k).get("beepType") === "vdml_ValueDeliveryModel") {
								var packageModel = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: packId });
								if(!packageModel){
									continue;
								}
								for (var l = 0; l < packageModel.get("businessModel").models.length; l++) {
									var bm = packageModel.get("businessModel").models.at(l);
									var bm1Row = creatDynamicRow(bm.get("name"), bm.get("id"), keyIndex, 1, 0, phaseAltName, self.titleMap[bm.get('type')],altColumnList);
									var bmLevel = keyIndex;
									if(!self.dynamicRows.includes(bm1Row)){
										self.dynamicRows.push(bm1Row);
										keyIndex++;
									}else {
										bmLevel = bm1Row.key;
									}
									//var scenario =  alternatives.at(i).getMainScenario(bm.getNestedParent());
									keyIndex = fillValueProposition(bm, keyIndex, bmLevel, inputPeriodDataset,resultPeriodDataset, phaseAltName, altColumnList);
									keyIndex = fillActivity(bm, keyIndex, bmLevel, inputPeriodDataset,resultPeriodDataset, phaseAltName, altColumnList)
									keyIndex = fillMyValueProposition(bm, keyIndex, bmLevel, inputPeriodDataset,resultPeriodDataset, phaseAltName, altColumnList)
								}
							}
						}
					}
				}
				function fillValueProposition(bm, keyIndex, bmLevel, inputPeriodDataset, resultPeriodDataset, phaseAltName, altColumnList) {
					for (var m = 0; m < bm.get("bmValueProposition").length; m++) {
						var bmVp = bm.get("bmValueProposition").models.at(m);
						var component = bmVp.get("valueProposition").get("component");
						if(component.length == 0){
							continue;
						}
						var bmChild = creatDynamicRow(bmVp.get("name"), bmVp.get("id"), keyIndex, 2, bmLevel, phaseAltName, self.titleMap[bmVp.get('type')],altColumnList);
						var valueLevel = keyIndex;
						if(!self.dynamicRows.includes(bmChild)){
							self.dynamicRows.push(bmChild);
							keyIndex++;
						}else {
							valueLevel = bmChild.key;
						}
						if (inputPeriodDataset || resultPeriodDataset) {
							keyIndex = self.checkValues(component, valueLevel, self.dynamicRows, keyIndex, phaseAltName,inputPeriodDataset,resultPeriodDataset,altColumnList);
						}
					}
					return keyIndex			
				}
				function fillActivity(bm, keyIndex, bmLevel, inputPeriodDataset, resultPeriodDataset, phaseAltName, altColumnList) {
					for (var n = 0; n < bm.get("bmActivity").length; n++) {
						var bmAct = bm.get("bmActivity").models.at(n);
						var component = bmAct.get("activityValue");
						if(component.length == 0){
							continue;
						}
						var bmChild = creatDynamicRow(bmAct.get("name"), bmAct.get("id"), keyIndex, 2, bmLevel, phaseAltName,self.titleMap[bmAct.get('type')],altColumnList);
						var valueLevel = keyIndex;
						if(!self.dynamicRows.includes(bmChild)){
							self.dynamicRows.push(bmChild);
							keyIndex++;
						}else {
							valueLevel = bmChild.key;
						}
						if (inputPeriodDataset||resultPeriodDataset) {
							keyIndex = self.checkValues(component, valueLevel, self.dynamicRows, keyIndex, phaseAltName, inputPeriodDataset, resultPeriodDataset, altColumnList);
						}
					}
					return keyIndex;
				}
				function fillMyValueProposition(bm, keyIndex, bmLevel, inputPeriodDataset, resultPeriodDataset, phaseAltName, altColumnList) {
					for (var o = 0; o < bm.get("bmValueFormula").length; o++) {
						var bmMyVP = bm.get("bmValueFormula").models.at(o);
						var component = bmMyVP.get("valueFormula").get("component");
						if(component.length == 0){
							continue;
						}
						var bmChild = creatDynamicRow(bmMyVP.get("name"), bmMyVP.get("id"), keyIndex, 2, bmLevel, phaseAltName, self.titleMap[bmMyVP.get('type')],altColumnList);
						var valueLevel = keyIndex;
						if(!self.dynamicRows.includes(bmChild)){
							self.dynamicRows.push(bmChild);
							keyIndex++;
						}else {
							valueLevel = bmChild.key;
						}
						if (inputPeriodDataset || resultPeriodDataset) {
							keyIndex = self.checkValues(component, valueLevel, self.dynamicRows, keyIndex, phaseAltName, inputPeriodDataset, resultPeriodDataset, altColumnList);
						}
					}
					return keyIndex;
				}
		}

		getColumnPeriodDataSets(){
			var self = this
			var plan = DataManager.getDataManager().get('currentPlan');
			
			var steps = self.scenario.get("step")
			for (var step of steps.models){
				var periodsLen = step.get('noPeriods');
				var period = step.get("startPeriod");
				for(var l = 0; l < periodsLen; l++){
					if(l != 0){
						period++;
					}
					let yearPeriod = utils.calculateYearPeriod(self.scenario.get("startTime") , period,plan.get("periodKind"));
					//var phaseAltName = phases.at(j).get('name') + "/" + alternatives.at(i).get('name');
					var periodKind =  self.model.getPeriodKinds(yearPeriod.year,yearPeriod.period,plan.get("periodKind"));
					var periodKindText = periodKind?.text || yearPeriod.period;
					self.periodSets().push({year:yearPeriod.year,period:periodKindText,periodNumber:periodKind.value})
				}
			}	
			 
			

		}

		getPeriodsByFromYear(year){
			var self = this;
			let selectedYear = year
			const periods = self.periodSets().filter(d=>d.year===+selectedYear)
			self.fromPeriods(periods.map(d=>d.period));
		}

		getPeriodsByToYear(year){
			var self = this;
			let selectedYear = year
			const periods = self.periodSets().filter(d=>d.year===+selectedYear)
			self.toPeriods(periods.map(d=>d.period));
			self.selectedToPeriod(self.toPeriods()[self.toPeriods().length-1])
		}

		init(model,options){
			var self = this;
			this.Values2ViewModel = this;
			self.model = model;
			this.alternativeModel = options.addOptions;
			this.selectedChartType = ko.observable();
			this.chartTypes = ko.observableArray(['Show All','Show Only Values']);
			this.periodSets = ko.observableArray([])
			this.fromYears = ko.observableArray([]);
			this.toPeriods = ko.observableArray([]);
			this.toYears = ko.observableArray([]);
			this.fromPeriods = ko.observableArray([]);
			this.selectedFromYear = ko.observable("");
			this.selectedFromPeriod = ko.observable("");
			this.selectedToYear = ko.observable("");
			this.selectedToPeriod = ko.observable("");
			this.periodKind = DataManager.getDataManager().get('currentPlan').get("periodKind")
			this.encodeId = this.model ? window.utils.htmlEscape(this.model.id) : null;
			self.xAxisSelected = [];
			this.skipBuiding = true;
			this.enableDirectEditing = false;
			this.showInputValues = ko.observable(true);
			this.showPeriods = ko.observable(false);
			this.titleMap = {};
			this.altMap = {};
			this.yearPeriodMap = {};
			this.titleMap['vdml_Community'] = "Market Segment";
			this.titleMap['vdml_OrgUnit'] = "Enterprise";
			this.titleMap['vdml_Actor'] = "Individual";
			this.titleMap['transformation_Plan'] = "Plan";
			this.titleMap['vdml_Party'] = "Role";
			this.titleMap['vdml_BusinessModel'] = "Business Model";
			this.titleMap['vdml_BusinessModelActivity'] = "Activity";
			this.titleMap['vdml_BusinessNetwork'] = "Participant Network";
			this.titleMap['vdml_BusinessModelValueProposition'] = "Value Proposition";
			this.titleMap['vdml_BusinessModelValueFormula'] = "My Proposition";
			this.titleMap['vdml_Activity'] = "Activity";
			this.titleMap['vdml_ValuePropositionComponent'] = "Value";
			this.titleMap['vdml_ValueAdd'] = "Value";
			self.packages = [];
			var dataManager = DataManager.getDataManager();
			var scenarioId = dataManager.get("currentPlan").get("defaultScenario");
			this.scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
			var defaultExecutionScenaroId = this.scenario.get("defaultExecutionScenario");
			this.defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
			var setPackage = function (package1) {	
		    	var packageModel = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: package1.id });
				self.packages.push(packageModel);	
			}
			this.lastCalculate = ko.observable();
			self.showLastCalulatedDate();
			function loadDocuments(results) {
				for (var i = 0; i < results.length; i++) {
					var type = results.at(i).get('type');
					if (type === "vdml_ValueDeliveryModel") {
						setPackage(results.at(i));
					}
				}
			}

			var searchTypes = 'vdml_ValueDeliveryModel';
			var nameNS = 'vbc:cmof_EObject-name';
			var alt = self.model.get("phase").at(0).get("phaseAlternative").at(0)
			DataManager.getDataManager().getAllDocumentsCollectionOfType(alt, searchTypes, loadDocuments, true, null, null, nameNS);
			this.selectedChartType.subscribe(function (selectedType) {
				if(selectedType == "Show Only Values" && self.xAxisSelected.length > 0){
					self.destroyOfflineTable();
					self.renderDynamicRows();
					self.createOfflineTable("offlineTable");
				} else if(self.xAxisSelected.length > 0){
					self.destroyOfflineTable();
					self.renderDynamicRows();
					self.createOfflineTable("offlineTable");
				}
			});	

			self.getColumnPeriodDataSets()

			const filteredYears = this.periodSets().filter((item, index, self) =>
				index === self.findIndex((t) => t.year === item.year)
			);

			self.fromYears(filteredYears.map(d=>d.year));

			var defaultPeriods = this.scenario.fillDefaultPeriods(this.periodSets,self.periodKind);
             var defaultPeriod = defaultPeriods.period

			self.selectedFromYear.subscribe(function(value){
				if(value!==undefined){
					self.getPeriodsByFromYear(value)
					var toYears = filteredYears.filter(d=>d.year>=value).map(d=>d.year)
					self.toYears(toYears);
					defaultPeriod?self.selectedFromPeriod(defaultPeriod):self.selectedFromPeriod(value);
					self.selectedToYear(self.toYears()[self.toYears().length-1]);
					defaultPeriod=undefined
				}
			});
			self.selectedFromPeriod.subscribe(function(value){
				if(value!==undefined){
					self.selectedFromPeriod(value)
				}
			});

			
			self.selectedToYear.subscribe(function(value){
				if(value!==undefined){
					self.getPeriodsByToYear(value);
				}
			});
			self.selectedToPeriod.subscribe(function(value){
				if(value!==undefined){
					console.log(value);
				}
			})			
			
			self.renderDynamicRows();
			
			this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['ExportCSV', 'PresentationType', 'Overview', 'Scenarios']);
		}

		static getInstance(model,options){
			var view = new Values2ViewModel(model,options);
			return view;
		};
	}
	path.Values2ViewModel = Values2ViewModel;
