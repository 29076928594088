import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {Preference} from './Preference'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin
//, Preference){
	
	/*if(!Preference){
		var importPath = DataManager.getDataManager().buildAppNsPath("preference",global.version);
		if(importPath.Preference){
			Preference = importPath.Preference;
		}
		else{
			require(["appbo/preference/Preference"],function(loadedModule){
				Preference = loadedModule;
			});
		}
	}*/
	export class UserPreferencesMixin{
		constructor(){
			var path = DataManager.getDataManager().buildAppNsPath("preference",global.version);
			path.UserPreferencesMixin = UserPreferencesMixin;
		}
	defaults(){
		var ret = {
			type: "preference_UserPreferences"
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"preference_UserPreferences",
			key:"preference",
			relatedModel:"preference.Preference",
			reverseRelation: {
				key:"preferenceOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!UserPreferencesMixin.cummulativeRelations) {
            UserPreferencesMixin.cummulativeRelations = _.union(UserPreferencesMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return UserPreferencesMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "email",type : "EString",defaultValue : "null",containingClass : "preference_UserPreferences" },
			{name : "userName",type : "EString",defaultValue : "null",containingClass : "preference_UserPreferences" }
		]
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return EObject.prototype.getPackagePath.apply(this, arguments);
	}
	static getViewProperties(type){
		return {
			templatePath : "views/preference/views/properties/UserPreferencesPropertiesTemplate.html",
			templateName : "UserPreferencesPropertiesTemplate",
			viewTypeStr : "appviews/preference/views/properties/UserPreferencesViewModel",
			tabId : "UserPreferencesView",
			tabName: "UserPreferences"
		}
	}
	getParent(){
		var container;
		container = this.get("userPreferenceOwner") ? this.get("userPreferenceOwner") : this.previousAttributes().userPreferenceOwner;
		if(container){
			return container;
		}
		return this;
	} 	
	//path.UserPreferencesMixin = UserPreferencesMixin;
	//return UserPreferencesMixin;
}