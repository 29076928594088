import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import { BaseMeasureRelationship } from './BaseMeasureRelationship'
import { BaseMeasureRelationshipMixin } from './BaseMeasureRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
//import { DimensionalMeasure } from './DimensionalMeasure'
//import { BinaryMeasure } from './BinaryMeasure'


//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/BaseMeasureRelationship","appbo/smm/BaseMeasureRelationshipMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BaseMeasureRelationship,BaseMeasureRelationshipMixin
//, BinaryMeasure
// DimensionalMeasure){

var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
//if (!BinaryMeasure) {
    var importPath = DataManager.getDataManager().buildAppNsPath("smm", global.version);
    if (importPath.BinaryMeasure) {
        BinaryMeasure = importPath.BinaryMeasure;
    }
    else {
        import('./BinaryMeasure').then(({ default: BinaryMeasure }) => {
            //BinaryMeasure = BinaryMeasure;
        });

        /*require(["appbo/smm/BinaryMeasure"],function(loadedModule){
            BinaryMeasure = loadedModule;
        });*/
    }
//}
//if (!DimensionalMeasure) {
    var importPath = DataManager.getDataManager().buildAppNsPath("smm", global.version);
    if (importPath.DimensionalMeasure) {
        DimensionalMeasure = importPath.DimensionalMeasure;
    }
    else {
        import('./DimensionalMeasure').then(({ default: DimensionalMeasure }) => {
            DimensionalMeasure = DimensionalMeasure;
        });

        /*require(["appbo/smm/DimensionalMeasure"],function(loadedModule){
            DimensionalMeasure = loadedModule;
        });*/
    }
//}
export class Base1MeasureRelationshipMixin {
    /*constructor() {
        var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
        path.Base1MeasureRelationshipMixin = Base1MeasureRelationshipMixin;
    }*/


    defaults() {
        var ret = {
            type: "smm_Base1MeasureRelationship"
        }
        return jQuery.extend(BaseMeasureRelationship.prototype.defaults.apply(this), ret);
    }
    static getMixinRelations() {
        return _.union([
            {
                type: Backbone.HasOne,
                containingClass: "smm_Base1MeasureRelationship",
                key: "from",
                relatedModel: "smm.BinaryMeasure",
                includeInJSON: Backbone.Model.prototype.idAttribute,
                reverseRelation: {
                    key: "baseMeasure1To",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },
        ])
    }
    static getCumulativeMixinRelations() {
        if (!Base1MeasureRelationshipMixin.cummulativeRelations) {
            Base1MeasureRelationshipMixin.cummulativeRelations = _.union(Base1MeasureRelationshipMixin.getMixinRelations()
                , BaseMeasureRelationshipMixin.getCumulativeMixinRelations()
            );
        }
        return Base1MeasureRelationshipMixin.cummulativeRelations.slice();
    }
    static getSuperTypes() {
        return [
            "smm_BaseMeasureRelationship"
        ];
    }
    static getProperties() {
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "shortDescription", type: "EString", defaultValue: "null", containingClass: "smm_SmmElement" },
            { name: "influence", type: "Influence", defaultValue: "null", containingClass: "smm_MeasureRelationship" }
        ]
    }
    getParent() {
        var container;
        return BaseMeasureRelationship.prototype.getParent.apply(this, arguments);
    }
    getPackagePath(path) {
        if (!path) {
            path = [];
        }
        return BaseMeasureRelationship.prototype.getPackagePath.apply(this, arguments);
    }
    getViewProperties(type) {
        return {
            templatePath: "views/smm/views/properties/Base1MeasureRelationshipPropertiesTemplate.html",
            templateName: "Base1MeasureRelationshipPropertiesTemplate",
            viewTypeStr: "appviews/smm/views/properties/Base1MeasureRelationshipViewModel",
            tabId: "Base1MeasureRelationshipView",
            tabName: "Base1MeasureRelationship"
        }
    }

}
path.Base1MeasureRelationshipMixin = Base1MeasureRelationshipMixin;
	//return Base1MeasureRelationshipMixin;
//});