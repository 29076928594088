import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VDMLDiagram} from './VDMLDiagram'
import {BusinessModelDiagramMixin} from './BusinessModelDiagramMixin'
import {BusinessModel} from './BusinessModel'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VDMLDiagram","appbo/vdml/BusinessModelDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VDMLDiagram,BusinessModelDiagramMixin
, BusinessModel){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!BusinessModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModel){
			BusinessModel = importPath.BusinessModel;
		}
		else{
			import('./BusinessModel').then(({ default: BusinessModel }) => {
                BusinessModel = BusinessModel;
            });
			/*require(["appbo/vdml/BusinessModel"],function(loadedModule){
				BusinessModel = loadedModule;
			});*/
		}
	}
	var BusinessModelDiagram = VDMLDiagram.extend(utils.customExtends({
		relations:BusinessModelDiagramMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VDMLDiagram.prototype.initialize.apply(this, arguments);
		}
		}
		, new BusinessModelDiagramMixin()
	));
	
	BusinessModelDiagram.abstract = false;
	BusinessModelDiagram.supported = false;
	path.BusinessModelDiagram = BusinessModelDiagram;
	export {BusinessModelDiagram};
//});