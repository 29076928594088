import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'

/*
define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global","appbo/vdml/ValueDeliveryModel","appbo/vdml/BusinessModel","appbo/tickets/User",
	"typeahead", "appbo/vdml/CapabilityOffer", "appbo/vdml/BusinessItem", "appbo/vdml/ValueDeliveryModelMixin"],
	function (require, $, _, Backbone, ko, koMapping, kb, bootbox, DataManager, global, ValueDeliveryModel, BusinessModel, User, typeahead, CapabilityOffer, BusinessItem, ValueDeliveryModelMixin
){*/
    
    var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties",global.version);
    var importPath;
    
    export class BusinessModelCompetencyDetailsViewModel {
        
        htmlEscape(str) {
            return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '');
        }
        
        checkDuplicatesPath(models,element){
          var path=element.get('name');
          for(var i=0;i<models.length;i++){
            if((element.get('name')===models[i].model.get('name') && (element.get('id')!==models[i].model.get('id')) && !models[i].utilized)){
              for(var k=0;k<element.getPackagePath().length-1;k++){
                        path = path.concat(" ["+element.getPackagePath()[k].name);
                        if(element.getPackagePath()[k+2]){
                            path = path.concat("/");
                        }
                    }
                    path=path.concat("]");
            }
          }
          return path;
       }
        filterComptetencyTypeAhead(){
			var self = this;
        	self.displayCompetencyTypeahead._reset();
        	if(self.options.addOptions && self.options.addOptions.competencyTypeAhead) {
        		var comptencyTypeAhead = self.options.addOptions.competencyTypeAhead;
	            for(var j=0;j<comptencyTypeAhead.length;j++){
				        //var displayname = checkDuplicatesPath(comptencyTypeAhead,comptencyTypeAhead[j].model);
                    var name = comptencyTypeAhead.at(j).get("name");
                    var desc = comptencyTypeAhead.at(j).get("description");
                    //if (comptencyTypeAhead.at(j).get("capability") && _.find(options.addOptions.competencyCollection, function (col) { return col.id != comptencyTypeAhead.at(j).get("capability").id})) {
                    //    continue
                    //}
                    /*if (comptencyTypeAhead.at(j).get("type") === "vdml_CapabilityDefinition" || comptencyTypeAhead.at(j).get('type') === "vdml_CapabilityCategory") {
                        var type = "CapabilityCategory"
                        if(comptencyTypeAhead.at(j).get("type") === "vdml_CapabilityDefinition"){
                            type = "CapabilityDefinition"
                        }
                        name = name.concat(' (' + type +')');
                    }*/
                    self.displayCompetencyTypeahead.push({ id: comptencyTypeAhead.at(j).get("id"), name: name, description: desc, originalName: comptencyTypeAhead.at(j).get("name")});
	            }
	            var existingcomptencies = self.options.addOptions.competencyCollection;   
                for (var k = 0; k < existingcomptencies.length; k++){
	            	var compExists = self.displayCompetencyTypeahead.findWhere({id:existingcomptencies[k].originalId});
	            	if(compExists){
	            		self.displayCompetencyTypeahead.remove(compExists);
	            	}
	            }
	            var children = self.options.addOptions.children;
	            for(var i=0;i<children.length;i++){
	            	if(children[i].type == "competency" && children[i].view && children[i].view.selectedCompetence){
		            	self.displayCompetencyTypeahead.remove(children[i].view.selectedCompetence);
	            	}
	            }
            }
        }
        
cleanModal(){
    var self = this;
            if(!self.enableDelete()) {
                window.cleanDialogModel(self.encodeId,this);
            }
            if(self.count == 1 && self.model){
            	window.cleanDialogModel(self.encodeId,this);
            }
        }
        
        deleteModal(){  
			var self = this;
            return self.options.callback(this,self.encodeId,'competency',true);
        }
       
        
        fetchCompetenceType(typeStr){
			var self = this;
			var type;
			for(var i=0;i<self.competenceTypeArray.length;i++){
				if(self.competenceTypeArray[i].typeStr == typeStr){
					type = self.competenceTypeArray[i].type;
					break;
				}
			}
			return type;
		}

		mapCompCapability() {
			var self = this;
			var id = window.utils.htmlEscape(window.guidGenerator());
			var options = {
				capabilityLibs: self.capabilityTypeAhead, callback: function (capId, capName, capDesc, capParentId) {
					if (!capId) {
						return;
					}
					self.usedCapability = capId;
					self.usedCapabilityComName(capName);
					$('#mapCapability'+ self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
					$('#mapCapability'+ self.encodeId).css('margin-left','10px');
					$('#deleteComp' + self.encodeId).show();
					if (self.name() == null || self.name() == "") {
						self.name(capName);
						$('#name' + self.encodeId).val(capName);
						self.description(capName);
						self.getUniqueValue(capName);
					}
				}, showCapabilityId: self.usedCapability
			};
            var ValueDeliveryModelMixin = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModelMixin');
			window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "CapabilityExplorerJson", null, options);
		};
        deleteCompCapability(view,event) {
				var self = this;
				var cap = self.usedCapability;
				if(cap){
					self.usedCapability = null;
					self.usedCapabilityComName(null);
					$('#deleteComp'+self.encodeId).hide();
					$("#mapCapability" + self.encodeId).removeClass('glyphicon-pencil').addClass('glyphicon-plus-sign');
				}
		};
        saveCompetencyDetails(){
			var self = this;
            if(self.parentView){
                if(self.name() !== null && self.name() !== "") {
                    return self.options.callback(this,self.encodeId,'competency');
                }
            }
        }

        saveData(bm, activity, mainScenario, alt, callback) {
			var self = this;
            return self.createCompetency(bm, activity, mainScenario, alt, callback);
        }         
        createCompetency(bm,activity,mainScenario,alt,callback){
			var self = this;
            var competency;
            var capModel;
            if (self.usedCapability) {
				capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityCategory').find({ id: self.usedCapability });
				if (!capModel) {
					capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityDefinition').find({ id: self.usedCapability });
				}
            }
            if(self.id() && self.id() !== ""){
                competency = self.model.get('competence');
                if(self.model.get('name') !== self.name()) {
                	self.model.set('name',this.name());
                	competency.set('name',this.name());
                }
                if(self.model.get('description') !== self.description()) {
                	self.model.set('description', this.description());
                	competency.set('description', this.description());
                }
                competency.set('capability', capModel);
                callback(activity);
            }
            else {
                var orgUnit = bm.get('business');
                if (self.selectedCompetence && (self.selectedCompetence.get('type') === "vdml_CapabilityOffer" || self.selectedCompetence.get('type') === "vdml_BusinessItem")){
                    competency = self.selectedCompetence;
                    bm.addExistingCompetency(competency, self.fetchCompetenceType(this.competenceType()), activity, orgUnit, mainScenario, capModel);
            		if(self.id() && self.id() !== ""){
	            		callback(competency,false);	 	
	            	}else{
	            	 	callback(competency,true);	
                    }
            	}
                else {
                    bm.createNewCompetency(this.name(), this.description(), self.fetchCompetenceType(this.competenceType()), activity, orgUnit, mainScenario, alt, function (competency) {
                        if(self.id() && self.id() !== ""){
		            		callback(competency,false);	 	
		            	}else{
		            	 	callback(competency,true);	
		            	}
	                }, capModel); 
               	 }
            } 
        };
        
        
        getUniqueValue(val, typeChange){
			var self = this;
        	var competencies = [];
        	var competencyType = "Resource";
        	if(self.options.parentView){ //feteching all comptencies of selected type
        		var business = self.options.parentView.get('business');
        		if(self.competenceType() === "BusinessItem"){
        			for(var i=0;i<business.get('ownedStore').length;i++){
                        competencies.push(business.get('ownedStore').models[i].get('resource'));
        			}
        		}
        		else{
        			competencies = business.get('capabilityOffer').models;
                    competencyType = "Capability";
        		}
        	}
        	if(self.model && self.model.get('name') === val.trim()){
        		self.enableComplete(true);
        		$('#message'+self.encodeId).text("");
        	}
        	else{
        		for(var i=0;i<competencies.length;i++){
	        		if(competencies[i].get('name') === val.trim()){
	        			$('#message'+self.encodeId).text(DataManager.getDataManager().get('localeManager').get('CompetencyDuplicateName',competencyType));
	        			if(self.matchField[0]){
							self.matchField[0].innerHTML = 'Match';
						}
	        			self.matchField.show();
	        			self.enableComplete(false);
	        			$('#name'+self.encodeId).next().find('li :contains('+val+')').click();
	        			return;
	        		}
	        	}
	        	var comptencyChildren = self.options.addOptions.children;
	        	for(var j=0;j<comptencyChildren.length;j++){
	        		if(comptencyChildren[j].type === "competency" && comptencyChildren[j].oldData.competenceType === self.competenceType()){
	        			if(comptencyChildren[j].oldData.name === val.trim() && comptencyChildren[j].modalId !== self.encodeId){
	        				$('#message'+self.encodeId).text(DataManager.getDataManager().get('localeManager').get('CompetencyDuplicateName',competencyType));
	        				if(self.matchField[0]){
								self.matchField[0].innerHTML = 'Match';
							}
	        				self.matchField.show();
	        				self.enableComplete(false);
	        				return;
	        			}
	        		}
                }
                if (self.disallowedNames.includes(val.trim())) {
                    $('#message' + self.encodeId).text(DataManager.getDataManager().get('localeManager').get('CompetencyDuplicateName', competencyType));
                    self.enableComplete(false);
                    return;
                }
        	}
            if (val.trim() != ""){
                if (self.matchField && self.matchField[0]){
                    self.matchField[0].innerHTML = 'New';
                    self.matchField.show();
                }
                if (!typeChange) {
                    self.selectedCompetence = null;
                }
        	}
        	else{
        		self.matchField.hide();
        	}
        	$('#message'+self.encodeId).text("");
		    self.enableComplete(true);
        }
        afterRenderView(node,view){
			var self = view;
        	$('.match').hide();
			$('#deleteComp' + self.encodeId).hide();
            var Extended = Backbone.Typeahead.extend({
              template: '<input type="text" value ="'+self.name()+'" id="name'+self.encodeId+'" placeholder="Name" class="form-control focus-ele"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            $('#editDetailsModalContent' + self.encodeId).resize(function () {
                $('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
            })
            var bmCompetencytypeahead = new Extended({collection:self.displayCompetencyTypeahead});
            bmCompetencytypeahead.setElement('#bmCompetencyDiv'+self.encodeId).render();
            bmCompetencytypeahead.on('selected', function(fetchmodel) {
        		var comptencyTypeAhead = self.options.addOptions.competencyTypeAhead;
            	var tempmodel=comptencyTypeAhead.findWhere({id:fetchmodel.get('id')});
                self.originalId(tempmodel.get("id"));
                self.name(tempmodel.get("name"));
                $("#name" + self.options.modalId).val(tempmodel.get("name"));
                self.description(tempmodel.get("description"));
                self.enableSelectOptions(false);
                self.selectedCompetence=tempmodel;
                if(tempmodel.get('type') === "vdml_BusinessItem"){
                    self.competenceType(self.competenceTypeOptions()[0].typeStr);
                } else if (tempmodel.get('type') === "vdml_CapabilityOffer"/* || tempmodel.get('type') === "vdml_CapabilityDefinition" || tempmodel.get('type') === "vdml_CapabilityCategory"*/) {
                    self.competenceType(self.competenceTypeOptions()[1].typeStr);
                    if (tempmodel.get('capability')) {
                        self.usedCapability = tempmodel.get('capability').id;
                        self.usedCapabilityComName(tempmodel.get('capability').get('name'));
                    }
                }
                $('#message' + self.encodeId).text("");
        		self.enableComplete(true);
				if(self.matchField[0]){
					self.matchField[0].innerHTML = 'Match';
				}
            });
			if(self.model && self.usedCapability){
				$('#mapCapability'+ self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
				$('#mapCapability'+ self.encodeId).css('margin-left','10px');
				$("#deleteComp" + self.encodeId).show();
			}
			$("#name"+self.encodeId).change(function (view) {
            	self.getUniqueValue(view.currentTarget.value);
                var newValue = view.currentTarget.value;
                if(newValue.trim() !== '') {
                    self.name(newValue);
                    self.description(newValue);
                    self.originalId("");
                    if(self.model === null && self.matchField && self.matchField[0] && self.matchField[0].innerHTML == 'New'){
                    	self.enableSelectOptions(true); 
                    }
                }
                if(self.originalId()!=""){
            	    self.selectedCompetence=null;
        	    }
            });
            $('#name'+self.encodeId).keyup(function (view) {
            	self.matchField = $(view.currentTarget).closest('.row').find('.match');
                self.getUniqueValue(view.currentTarget.value);
                if(view.currentTarget.value.trim() == ""){
                    self.enableComplete(false);
                }
                if(self.model === null && self.matchField[0] && self.matchField[0].innerHTML == 'New'){
                	self.enableSelectOptions(true); 
                }
            });
            $('#name' + self.encodeId).dblclick(function (view) {
                $('#name' + self.encodeId).trigger('keyup');
                $('#name' + self.encodeId).focus();
            });
            if(self.model) {
                $("#titleLabel"+self.encodeId).text(DataManager.getDataManager().get('localeManager').get('EditCompetencyDetails'));
                if(self.competenceType() == 'CapabilityOffer'){
                	self.showCapabilityLibList(true);
            	}
			}else {
				$("#titleLabel"+self.encodeId).text(DataManager.getDataManager().get('localeManager').get('EnterCompetencyDetails'));
			}
			
			self.count = 0;
			$('#modal'+self.encodeId).on('shown.bs.modal', function () {
				window.utils.focusElement("#newModal .focus-ele");
				if(self.name() != null && self.name() !== '') {
					self.enableDelete(true);
				}
				//for modal window close functionality
				$('#name'+self.encodeId).val(self.name());
				self.count++;
			});
        };
        
        
init(model, options){
    var self = this;
	self.options = options;
    this.BusinessModelCompetencyDetailsViewModel = this;
    this.model = model;
    this.id = ko.observable(self.model ? self.model.get('id') : null);
    this.comptenceId = ko.observable(self.model ? self.model.get('competence').get('id') : null);
    this.encodeId = window.utils.htmlEscape(this.id());
    this.capabilityTypeAhead = options.capabilityTypeAhead.slice();;
    this.name = ko.observable(self.model ? self.model.get('name') : "");
    this.showCapabilityLibList = ko.observable(false);
    this.originalId = ko.observable();
    this.selectedCompetence = null;
    this.description = ko.observable(self.model ? self.model.get('description') : "");
    this.enableDelete = ko.observable(false);
    this.enableComplete = ko.observable(false);
    this.enableSelectOptions = ko.observable(true);
    this.displayCompetencyTypeahead = new Backbone.Collection();
    this.usedCapabilityComName = ko.observable();
    this.name.subscribe(function (name) {
        self.description(name);
    })
    this.competenceType = ko.observable();
    
    this.name.subscribe(function (val) {
        self.description(val);
    });
    this.competenceType.subscribe(function (type) {
        if (self.name().trim() !== "") {
            self.getUniqueValue(self.name(), true);
        }
        if (self.competenceType() == "CapabilityOffer" && self.capabilityTypeAhead.length > 0) {
            self.showCapabilityLibList(true);
        } else {
            self.showCapabilityLibList(false);
        }
    });
	if(options && options.parentView){
            this.parentView = options.parentView;
            this.encodeId = ""+options.modalId;
		}
		
		//this.capabilityTypeAhead = ko.observableArray();
        //_.each(options.capabilityTypeAhead, function (cap) {
        //    var dispName = cap.get('name');
        //    if (dispName != cap.get('description')) {
        //        dispName = cap.get('name') + "-" + cap.get('description');
        //    }
        //    self.capabilityTypeAhead.push({ id: cap.id, name: cap.get('name'), displayName: dispName });
        //});
        this.usedCapability = null;
        
        this.disallowedNames = [];
        var CompetenceType = function(name,type,typeStr){
            this.name = name;
            this.type = type;
            this.typeStr = typeStr;
        };
		var BusinessItem = Backbone.Relational.store.getObjectByName("vdml.BusinessItem");
		var CapabilityOffer = Backbone.Relational.store.getObjectByName("vdml.CapabilityOffer");
		
        self.competenceTypeArray = [new CompetenceType(""+DataManager.getDataManager().get('localeManager').get('Resource'),BusinessItem,"BusinessItem"),
                        new CompetenceType(""+DataManager.getDataManager().get('localeManager').get('Capability'),CapabilityOffer,"CapabilityOffer")];
						
		this.competenceTypeOptions = ko.observableArray(self.competenceTypeArray);
        
        if(self.name()!==""){
        	self.enableComplete(true);
        }
        if(self.model){
            var competence = self.model.get('competence');
            var currentCompetenceType = this.competenceType();
            if(!competence || currentCompetenceType){
                return;
            }
            if(competence instanceof BusinessItem){
                this.competenceType(self.competenceTypeArray[0].typeStr);
            }else if(competence instanceof CapabilityOffer){
                this.competenceType(self.competenceTypeArray[1].typeStr);
            }
            self.enableSelectOptions(false);
            if (self.model.get('competence') && self.model.get('competence').get('capability')) {
				self.usedCapability = self.model.get('competence').get('capability').id;
				self.usedCapabilityComName(self.model.get('competence').get('capability').get('name'));
            }
            var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: DataManager.getDataManager().get('viewAlternative') });
            var type = 'businessItem';
            if (self.competenceType() == "CapabilityOffer") {
                type = 'capabilityOffer';
            }
            self.disallowedNames = alt.getDisallowedNames(self.model.get('competence'), type);
        }
		if(!self.model) {
        	self.filterComptetencyTypeAhead();
        }
		
    this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
        'name'
        , 'description'
        , 'Role'
        , 'type'
        , 'Close'
        , 'Complete'
        , 'Delete'
        , 'EnterCompetencyDetails'
        , 'EditCompetencyDetails'
    ]);

}
static getInstance(model, options){
    var view = new BusinessModelCompetencyDetailsViewModel(model, options);
    view.init(model, options);
    return view;
};
}
path.BusinessModelCompetencyDetailsViewModel = BusinessModelCompetencyDetailsViewModel;