import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {CollaborationDiagram} from './CollaborationDiagram'
import {CollaborationDiagramMixin} from './CollaborationDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/CollaborationDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,CollaborationDiagramMixin
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class StrategyMapDiagramMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.StrategyMapDiagramMixin = StrategyMapDiagramMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_StrategyMapDiagram"
		}
		return jQuery.extend(CollaborationDiagram.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
	    return _.union([
            {
            	type: Backbone.HasMany,
            	containingClass: "vdml_StrategyMapDiagram",
            	key: "value",
            	relatedModel: "vdml.ValueElement",
            	includeInJSON: Backbone.Model.prototype.idAttribute,
			},
			{
				type: Backbone.HasMany,
				containingClass: "vdml_StrategyMapDiagram",
				key: "activity",
				relatedModel: "vdml.Activity",
				includeInJSON: Backbone.Model.prototype.idAttribute,
			},
			{
				type: Backbone.HasMany,
				containingClass: "vdml_StrategyMapDiagram",
				key: "competence",
				relatedModel: "vdml.Competence",
				includeInJSON: Backbone.Model.prototype.idAttribute,
            },
            {
                type: Backbone.HasMany,
                containingClass: "vdml_StrategyMapDiagram",
                key: "capability",
                relatedModel: "vdml.Capability",
                includeInJSON: Backbone.Model.prototype.idAttribute,
			},
			{
				type: Backbone.HasMany,
				containingClass: "vdml_StrategyMapDiagram",
				key: "valueDefinition",
				relatedModel: "vdml.ValueDefinition",
				includeInJSON: Backbone.Model.prototype.idAttribute,
			}
		])
	}
	static getCumulativeMixinRelations(){
		if (!StrategyMapDiagramMixin.cummulativeRelations) {
            StrategyMapDiagramMixin.cummulativeRelations = _.union(StrategyMapDiagramMixin.getMixinRelations()
                , CollaborationDiagramMixin.getCumulativeMixinRelations()
            );
        }
		return StrategyMapDiagramMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_CollaborationDiagram"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "progress", type: "double", defaultValue: "0", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
    }
    calculateProgress() {

    }
	getParent(){
		var container;
		if(!container){
		    container = this.get("ecoMap") ? this.get("ecoMap") : this.previousAttributes().ecoMap;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
		    container = this.get("ecoMap") ? this.get("ecoMap") : this.previousAttributes().ecoMap;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/strategyMap/views/designer/StrategyMapDesignerTemplate.html",
            templateName : "StrategyMapDesignerTemplate",
            viewTypeStr : "appviews/strategyMap/views/designer/StrategyMapDesignerViewModel",
            tabId : "StrategyMapDesignerView",
            tabName: "StrategyMapDesigner"
		}
	}
	static getDialogViewProperties(type) {
	    if (type && type === "ShapeProperties") {
	        return {
	            templatePath: "views/ecoMap/views/designer/ShapePropertiesTemplate.html",
	            templateName: "ShapePropertiesTemplate",
	            viewTypeStr: "appviews/ecoMap/views/designer/ShapePropertiesViewModel",
	            tabId: "ShapePropertiesView",
	            tabName: "ShapeProperties"
	        }
	    }else if(type === "TargetDetails"){
			return{
				templatePath: "views/canvas/views/designer/CanvasTargetDetailsTemplate.html",
		        templateName: "CanvasTargetDetailsTemplate",
		        viewTypeStr: "appviews/canvas/views/designer/CanvasTargetDetailsViewModel",
		        tabId: "CanvasTargetDetailsView",
		        tabName: "CanvasTargetDetails"
			}
		}else if(type==="explorerModelJson"){
			return{
				templatePath : "views/beeppackage/views/properties/explorerModelJsonTemplate.html",
			    templateName : "explorerModelJsonTemplate",
			    viewTypeStr : "appviews/beeppackage/views/properties/explorerModelJsonViewModel",
			     tabId: "explorerModelJsonView",
			    tabName: "explorerModelJson"
			}
		}else if(type==="ViewValues"){
			return{
				templatePath: "views/ecoMap/views/designer/ChartDetailsTemplate.html",
		        templateName: "ChartDetailsTemplate",
		        viewTypeStr: "appviews/ecoMap/views/designer/ChartDetailsViewModel",
		        tabId: "ChartDetailsView",
		        tabName: "ChartDetails"
			}
		}else if(type === "ImageSelector"){
			return{
				templatePath: "views/ecoMap/views/designer/ImageSelectorTemplate.html",
		        templateName: "ImageSelectorTemplate",
		        viewTypeStr: "appviews/ecoMap/views/designer/ImageSelectorViewModel",
		        tabId: "ImageSelectorView",
		        tabName: "ImageSelector"
			}
		}
	}
	/*StrategyMapDiagramMixin.prototype.handleRemoveParticipant(model, coll, options) {debugger
	    var self = this;
	    var modeler = new EcoModeler({});
	    var parentId = model.getNestedParent().id;
	    modeler.importXML(this.get('data'), function (err) {
	        if (err) {
	            console.error(err);
	        }
	        var flowElements = modeler.definitions.rootElements[0].flowElements;
	        var index;
	        while ((index = _.findIndex(flowElements, {mid:model.id})) >= 0) {
	        	if (options && options.creatingRevision && options.revisionCollectionNewId) {
			        var parentRep = DataManager.getDataManager().getRepositoryId(options.revisionCollectionNewId);
			        var parentSuffix = parentId.substr(parentId.indexOf('@') + 1);
		            flowElements[index].set('mid', options.revisionCollectionNewId);
	            	flowElements[index].set('mpid', parentRep + parentSuffix);				        
		        }else{
		            flowElements[index].set('mid', undefined);
	            	flowElements[index].set('mpid', undefined);		        	
		        }
	        }
	        modeler.saveXML({ format: true }, function (err, xml) {
	            if (xml) {
	                self.set('data', xml);
	            }
	            modeler.destroy();
	        });
	    });
	};*/
	handleRemoveParticipant(model, coll, options) {
	    var self = this;
	    var xml = this.get('data');
	    if (!xml || DataManager.getDataManager().get('suppressLogging'))
	        return;
	    var xmlDoc = jQuery.parseXML(xml);
	    var $xml = jQuery(xmlDoc);
	    var nodes = $xml.find('[mid="' + model.id + '"]')
	    //var re = new RegExp(model.id, 'g');
	    var parentId = model.getNestedParent().id;
	    //var pre = new RegExp(parentId, 'g');
	    if (options && options.creatingRevision && options.revisionCollectionNewId) {
	        var parentRep = DataManager.getDataManager().getRepositoryId(options.revisionCollectionNewId);
	        //var parentSuffix = parentId.substr(parentId.indexOf('@') + 1);
	        var parentSuffix = window.utils.getSuffix(parentId);
	        //xml = xml.replace(re, options.revisionCollectionNewId);
	        //xml = xml.replace(pre, parentRep + parentSuffix);
	        nodes.each(function (callback, node) {
	            node.setAttribute('mid', options.revisionCollectionNewId);
	            node.setAttribute('mpid', parentRep + parentSuffix);
	        })
	    } else {
	        nodes.each(function (callback, node) {
	            node.setAttribute('mid', '');
	            node.setAttribute('mpid', '');
	        });
	    }
	    xml = (new XMLSerializer()).serializeToString(xmlDoc);
	    self.set('data', xml);
	};

	handleRemoveBusinessModel(model, coll, options) {
	    this.handleRemoveParticipant(model, coll, options);
	};
	handleRemoveValueProposition(model, coll, options) {
	    this.handleRemoveParticipant(model, coll, options);
	};
	}
	path.StrategyMapDiagramMixin = StrategyMapDiagramMixin;
	
	//return StrategyMapDiagramMixin;
//});