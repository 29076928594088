import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasurement} from './DimensionalMeasurement'
import {DimensionalMeasurementMixin} from './DimensionalMeasurementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasurement","appbo/smm/DimensionalMeasurementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasurement,DimensionalMeasurementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	export class NamedMeasurementMixin {

	defaults(){
		var ret = {
			type: "smm_NamedMeasurement"
		}
		return jQuery.extend(DimensionalMeasurement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!NamedMeasurementMixin.cummulativeRelations) {
            NamedMeasurementMixin.cummulativeRelations = _.union(NamedMeasurementMixin.getMixinRelations()
                , DimensionalMeasurementMixin.getCumulativeMixinRelations()
            );
        }
		return NamedMeasurementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_DimensionalMeasurement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "error",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			{name : "breakValue",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			{name : "value",type : "EDouble",defaultValue : "null",containingClass : "smm_DimensionalMeasurement" }
		]
	}
	getParent(){
		var container;
		return DimensionalMeasurement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return DimensionalMeasurement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/NamedMeasurementPropertiesTemplate.html",
			templateName : "NamedMeasurementPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/NamedMeasurementViewModel",
			tabId : "NamedMeasurementView",
			tabName: "NamedMeasurement"
		}
        }
    }
	path.NamedMeasurementMixin = NamedMeasurementMixin;
	
	//return NamedMeasurementMixin;
//});