import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasurement} from './DimensionalMeasurement'
import {RescaledMeasurementMixin} from './RescaledMeasurementMixin'
import {RescaledMeasurementRelationship} from './RescaledMeasurementRelationship'
import {Operation} from './Operation'
import {DataManager} from '../../../com/vbee/data/DataManager'
/*
define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasurement","appbo/smm/RescaledMeasurementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasurement,RescaledMeasurementMixin
, RescaledMeasurementRelationship
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!RescaledMeasurementRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RescaledMeasurementRelationship){
			RescaledMeasurementRelationship = importPath.RescaledMeasurementRelationship;
		}
		else{
			import('./RescaledMeasurementRelationship').then(({ default: RescaledMeasurementRelationship }) => {
				RescaledMeasurementRelationship = RescaledMeasurementRelationship;
			});
			/*require(["appbo/smm/RescaledMeasurementRelationship"],function(loadedModule){
				RescaledMeasurementRelationship = loadedModule;
			});*/
		}
	}
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
		else{
			import('./Operation').then(({ default: Operation }) => {
				Operation = Operation;
			});
			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	var RescaledMeasurement = DimensionalMeasurement.extend(utils.customExtends({
		relations:RescaledMeasurementMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			DimensionalMeasurement.prototype.initialize.apply(this, arguments);
		}
		}
		, new RescaledMeasurementMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	RescaledMeasurement.abstract = false;
	RescaledMeasurement.supported = !sp2Enabled;
	path.RescaledMeasurement = RescaledMeasurement;
	export {RescaledMeasurement};
//});