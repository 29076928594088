import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { BusinessModelItem } from './BusinessModelItem'
import { ParticipantNetworkMixin } from './ParticipantNetworkMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Collaboration } from './Collaboration'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelItem","appbo/vdml/ParticipantNetworkMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelItem,ParticipantNetworkMixin
, Collaboration){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Collaboration){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Collaboration){
			Collaboration = importPath.Collaboration;
		}
		else{
            import('./Collaboration').then(({ default: Collaboration }) => {
                Collaboration = Collaboration;
            });
            /*require(["appbo/vdml/Collaboration"], function (loadedModule) {
				Collaboration = loadedModule;
			});*/
		}
	}
	var ParticipantNetwork = BusinessModelItem.extend(utils.customExtends({
		relations:ParticipantNetworkMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BusinessModelItem.prototype.initialize.apply(this, arguments);
			/*this.on("change:collaboration",this.addCollaborationToScenarioContexts);
			this.on("destroy", this.destroyParticipantNetwork);*/
		}
		}
		, new ParticipantNetworkMixin()
	));
    ParticipantNetwork["change:collaboration"] = function (pn) {
		if(Backbone.skipPersistance){
    		return;
    	}
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.addCollaborationToScenarioContexts(pn);
		}
    };
    ParticipantNetwork["destroy"] = function () {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.destroyParticipantNetwork();
		}
    };
	ParticipantNetwork.abstract = false;
	path.ParticipantNetwork = ParticipantNetwork;
export { ParticipantNetwork };
//});