import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class ObjectReferenceMixin {

	defaults(){
		var ret = {
			type: "vdml_ObjectReference"
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!ObjectReferenceMixin.cummulativeRelations) {
            ObjectReferenceMixin.cummulativeRelations = _.union(ObjectReferenceMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return ObjectReferenceMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_ObjectReference" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("referenceLibraryOwner") ? this.get("referenceLibraryOwner") : this.previousAttributes().referenceLibraryOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("attachmentsOwner") ? this.get("attachmentsOwner") : this.previousAttributes().attachmentsOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("userInterfaceOwner") ? this.get("userInterfaceOwner") : this.previousAttributes().userInterfaceOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("businessProcessOwner") ? this.get("businessProcessOwner") : this.previousAttributes().businessProcessOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("caseModelOwner") ? this.get("caseModelOwner") : this.previousAttributes().caseModelOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("entityOwner") ? this.get("entityOwner") : this.previousAttributes().entityOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("webServiceInterfaceOwner") ? this.get("webServiceInterfaceOwner") : this.previousAttributes().webServiceInterfaceOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("runtimeWebserviceInterfaceOwner") ? this.get("runtimeWebserviceInterfaceOwner") : this.previousAttributes().runtimeWebserviceInterfaceOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("worklistOwner") ? this.get("worklistOwner") : this.previousAttributes().worklistOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("bindingOperationOwner") ? this.get("bindingOperationOwner") : this.previousAttributes().bindingOperationOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("ruleOwner") ? this.get("ruleOwner") : this.previousAttributes().ruleOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("referenceLibraryOwner") ? this.get("referenceLibraryOwner") : this.previousAttributes().referenceLibraryOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("attachmentsOwner") ? this.get("attachmentsOwner") : this.previousAttributes().attachmentsOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("userInterfaceOwner") ? this.get("userInterfaceOwner") : this.previousAttributes().userInterfaceOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("businessProcessOwner") ? this.get("businessProcessOwner") : this.previousAttributes().businessProcessOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("caseModelOwner") ? this.get("caseModelOwner") : this.previousAttributes().caseModelOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("entityOwner") ? this.get("entityOwner") : this.previousAttributes().entityOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("webServiceInterfaceOwner") ? this.get("webServiceInterfaceOwner") : this.previousAttributes().webServiceInterfaceOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("runtimeWebserviceInterfaceOwner") ? this.get("runtimeWebserviceInterfaceOwner") : this.previousAttributes().runtimeWebserviceInterfaceOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("worklistOwner") ? this.get("worklistOwner") : this.previousAttributes().worklistOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("bindingOperationOwner") ? this.get("bindingOperationOwner") : this.previousAttributes().bindingOperationOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("ruleOwner") ? this.get("ruleOwner") : this.previousAttributes().ruleOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ObjectReferencePropertiesTemplate.html",
			templateName : "ObjectReferencePropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ObjectReferenceViewModel",
			tabId : "ObjectReferenceView",
			tabName: "ObjectReference"
		}
	}
	
	}
	path.ObjectReferenceMixin = ObjectReferenceMixin;
	//return ObjectReferenceMixin;
//});