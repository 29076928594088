import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AnalysisContext} from './AnalysisContext'
import {AnalysisContextMixin} from './AnalysisContextMixin'
import {Collaboration} from './Collaboration'
import {MeasuredCharacteristic} from './MeasuredCharacteristic'
import {ReleaseControl} from './ReleaseControl'
//import {Actor} from './Actor'
import {BusinessModel} from './BusinessModel'
import {Alternative} from '../transformation/Alternative'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/AnalysisContext","appbo/vdml/AnalysisContextMixin","appbo/transformation/Alternative"],
function(require,jQuery,_,async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AnalysisContext,AnalysisContextMixin,Alternative
, Collaboration
, MeasuredCharacteristic
, ReleaseControl

, Actor
, BusinessModel){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Collaboration){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Collaboration){
			Collaboration = importPath.Collaboration;
		}
		else{
			import('./Collaboration').then(loadedModule => {
				Collaboration = loadedModule;
			});
			/*require(["appbo/vdml/Collaboration"],function(loadedModule){
				Collaboration = loadedModule;
			});*/
		}
	}
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
		else{
			import('./MeasuredCharacteristic').then(loadedModule => {
				MeasuredCharacteristic = loadedModule;
			});
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	if(!ReleaseControl){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ReleaseControl){
			ReleaseControl = importPath.ReleaseControl;
		}
		else{
			import('./ReleaseControl').then(loadedModule => {
				ReleaseControl = loadedModule;
			});
			/*require(["appbo/vdml/ReleaseControl"],function(loadedModule){
				ReleaseControl = loadedModule;
			});*/
		}
	}
	

	if(!BusinessModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModel){
			BusinessModel = importPath.BusinessModel;
		}
		else{
			import('./BusinessModel').then(loadedModule => {
				BusinessModel = loadedModule;
			});
			/*require(["appbo/vdml/BusinessModel"],function(loadedModule){
				BusinessModel = loadedModule;
			});*/
		}
	}
	export class ScenarioMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.ScenarioMixin = ScenarioMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_Scenario"
		}
		return jQuery.extend(AnalysisContext.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Scenario",
			key:"contextCollaboration",
			relatedModel:"vdml.Collaboration",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_Scenario",
			key:"horizon",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"horizonOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Scenario",
			key:"releaseControl",
			relatedModel:"vdml.ReleaseControl",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_Scenario",
			key:"heatThreshold",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"heatThresholdOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Scenario",
			key:"contextActor",
			relatedModel:"vdml.Actor",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Scenario",
			key:"businessModel",
			relatedModel:"vdml.BusinessModel",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!ScenarioMixin.cummulativeRelations) {
            ScenarioMixin.cummulativeRelations = _.union(ScenarioMixin.getMixinRelations()
                , AnalysisContextMixin.getCumulativeMixinRelations()
            );
        }
		return ScenarioMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_AnalysisContext"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "type",type : "EString",defaultValue : "null",containingClass : "vdml_Scenario" },
			{name : "isCommon",type : "EBoolean",defaultValue : "null",containingClass : "vdml_Scenario" },
			{name : "localChange",type : "EBoolean",defaultValue : false,containingClass : "vdml_AnalysisContext" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("scenarioOwner") ? this.get("scenarioOwner") : this.previousAttributes().scenarioOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("scenarioOwner") ? this.get("scenarioOwner") : this.previousAttributes().scenarioOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ScenarioPropertiesTemplate.html",
			templateName : "ScenarioPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ScenarioViewModel",
			tabId : "ScenarioView",
			tabName: "Scenario"
		}
	}
//#startCustomMethods

	disablePackagesUpdates(value,update){
		var self = this;
		if(update) {
			var altId = DataManager.getDataManager().get('viewAlternative');
			var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({id:altId});
			if(!alt){
				return;
			}
			var designScenario = alt.get('designScenario');
			var bm = self.get('scenarioOwner');
			var depPackage = bm?bm.get('dependentPackage'):[];
			for(var i=0;i<depPackage.length;i++){
                if (DataManager.getDataManager().getRepositoryId(depPackage.at(i).get('id')) !== altId && depPackage.at(i).get('scenario')) {
					var depScenarios = depPackage.at(i).get('scenario');
					for(var j=0;j<depScenarios.length;j++){
						if(designScenario.findWhere({'scenario':depScenarios.at(j).get('id')})) {
							depScenarios.at(j).set('localChange',true);
							break;
						}
					}
				}
			}
		}
	};
	
	getAlternative(useOnlySuffix){
		var currentPlan = DataManager.getDataManager().get('currentPlan');
		if(currentPlan) {
			var suffix = this.id.substr(this.id.lastIndexOf('@') + 1);
			var phases = currentPlan.get('phase');
			for(var i=0;i<phases.length;i++){
				var phase = phases.at(i);
				var alts = phase.get('phaseAlternative');
				for(var j=0;j<alts.length;j++){
					var alt = alts.at(j);
					var scenarios = alt.get('designScenario');
					for(var k=0;k<scenarios.length;k++){
						var scenario = scenarios.at(k);
						if(!useOnlySuffix){
							if(scenario.get('scenario') === this.get('id')){
								return alt;
							}
						}else{
							var scenarioStr = scenario.get('scenario');
							var scenarioSuffix = scenarioStr.substr(scenarioStr.lastIndexOf('@')+1);
							if(scenarioSuffix === suffix){
								return alt;
							}							
						}
					}
				}
			}
		}
			
	};
	
	getAlternativeAsync(callback){
		var self = this;
		var altId = DataManager.getDataManager().getRepositoryId(self.get('id'));
		function loadPlanDocument(planId){
			DataManager.getDataManager().getPlansVDMLStore(function(lc){	
				DataManager.getDataManager().fetchDocumentFromPackage(planId,"appbo/transformation/Plan",DataManager.getDataManager().get('currentPlanVersion'),planId,"appbo/transformation/Plan",lc,{
					success:function(plan){
						var phases = plan.get('phase');
						for(var i=0;i<phases.length;i++){
							var phase = phases.at(i);
							var alts = phase.get('phaseAlternative');
							for(var j=0;j<alts.length;j++){
								var alt = alts.at(j);
								var scenarios = alt.get('designScenario');
								for(var k=0;k<scenarios.length;k++){
									var scenario = scenarios.at(k);
									if(scenario.get('scenario') === self.get('id')){
										callback(alt);
									}
								}
							}
						}
					},
					error:function(){
						if(callback){
							callback();
						}
					}
				});
			});
		}
		
		var alt = Alternative.find({id:altId});
		if(alt){
			var phase = alt.get('phaseAlternativeOwner');
			if(phase){
				loadPlanDocument(phase.get('phaseOwner').get('id'));
				return;
			}
		}
		var plansRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
		plansRDFModel.getPlanPhaseOfAlternative(altId,loadPlanDocument);
	};
	
	copy(scenarioCopy,measurementsCopied,incrementalUpdate){
		if(!measurementsCopied){
			var measurementsCopied = [];
		}
		if(!scenarioCopy){
			var scenarioOwner = this.get('scenarioOwner');
			var scenarioId = DataManager.getDataManager().guidGeneratorByOwner(this);
			scenarioCopy = new this.constructor({id:scenarioId,name:scenarioOwner.getUniqueProperty('name','Copy of ' + this.get('name'),'scenario'),scenarioOwner:scenarioOwner});
			var heatThreashold = this.get('heatThreshold');
			if(heatThreashold){
				heatThreashold.copy(scenarioCopy,measurementsCopied);
			}
			var horizon = this.get('horizon');
			if(horizon){
				horizon.copy(scenarioCopy,measurementsCopied);
			}
		}
		if(scenarioCopy.get('localChange') === true){
			/*if(callback){
				callback(scenarioCopy);
			}*/
			return measurementsCopied;
		}
		var contextStores = this.get('contextStore');
		var targetContextStores = scenarioCopy.get('contextStore');
		targetContextStores.add(contextStores.models);
		var delStores = _.difference(targetContextStores.models,contextStores.models);
		targetContextStores.remove(delStores);
		
		this.copyData(scenarioCopy,false,null,null,measurementsCopied,incrementalUpdate);
		
		return measurementsCopied;
	};
	
	createContextObservation(timestamp,snapshotId){
		var beepPackage = this.getNestedParent();
		if(!beepPackage){
			return;
		}
		var defaultMetricModel = beepPackage.get('defaultMetricsModel');
		if(!defaultMetricModel){
			defaultMetricModel = beepPackage.createDefaultMetricModel();
		}
		var observations = this.get('contextObservation');
		var ret;
		observations.each(function(obs){
			if(!ret){
				if(obs.get('whenObserved') == timestamp){	// to compare null or undefined
					ret = obs;
				}
			}
		})
		if(ret){
			return ret;
		}
		var name = this.get('name');
		if(!timestamp){
			name = "Base Scenario";
		}
		var contextAltId = DataManager.getDataManager().getRepositoryId(beepPackage.id);
		if(!snapshotId){
			var alt = window.utils.getElementModel(contextAltId,["transformation.Alternative"]);
			snapshotId = alt?window.utils.getScenarioSnapshotId(alt,timestamp):null;//for common it is null
		}
		var observation = defaultMetricModel.createObservation(name,timestamp,snapshotId);
		this.get('contextObservation').add(observation);
		return observation;
	};

	createMeasurement(packageCurrent,valueComponent,measuredCharecterstic,measureLibrary,charToMatch,unitToMatch,valFormula,accToMatch,baseRelSetToMatch,satisfactionTuple,intervalTupleSet,timeStamp,nonDimensional,isOVS,viewAlternative){
		var self = this;
		var measurement;
		var characteristic = measuredCharecterstic.get('characteristicDefinition');
		if(!characteristic){
			characteristic = packageCurrent.linkOrCreateAndLinkCharacteristic(charToMatch,measuredCharecterstic);
		}
		var measure = measureLibrary.findOrCreateMeasure(charToMatch,unitToMatch,valFormula,accToMatch,baseRelSetToMatch,satisfactionTuple,intervalTupleSet,nonDimensional);
		var currentObservation;
		function getCurrentObservation(scenario){
			var contextObservation;
			if(scenario.get('type') === "transformation_PhaseObjectiveSet"){
				contextObservation = scenario.get('objectiveObservation');
			}else{
				contextObservation = scenario.get('contextObservation');
			}
			contextObservation.each(function(observation){
				if(observation.get('whenObserved') == timeStamp){
					currentObservation = observation;
				}
			});
			if(!currentObservation){
				currentObservation = self.createContextObservation(timeStamp);
			}
			return currentObservation;
		}
		currentObservation = getCurrentObservation(this);
        var observedMeasure = currentObservation.createObservedMeasure(currentObservation.getUniqueProperty('name', "Observed " + measure.get('name'), 'observedMeasures'));
		var name = observedMeasure.getUniqueProperty('name',charToMatch + " Measurement",'measurements');
		observedMeasure.set({'measure':measure,'lastChange':new Date().getTime()});
		//observedMeasure.set('lastChange',new Date().getTime());
		if(!satisfactionTuple){
			if(baseRelSetToMatch && baseRelSetToMatch.length === 0){
				measurement = observedMeasure.createDirectMeasurement(name,name);
			}else{
				measurement = observedMeasure.createCollectiveMeasurement(name,name);
				if(measure.get('type') === "smm_CollectiveMeasure"){
					_.each(baseRelSetToMatch,function(rel){
						var altToUse = viewAlternative;
						if(!rel.new){
							return;
						}
						var component = rel.new.component;
						if(rel.new.usePreviousAlternative === true){
							altToUse = viewAlternative.getPreviousPhasePrimary();
						}
						if(altToUse){
							altToUse.getModelLinked(component,function(linkedComponent){
								var compScenario = altToUse.getMainScenario(linkedComponent.getNestedParent());
								var observation = getCurrentObservation(compScenario);
								var observedMeasures = observation.get('observedMeasures');
								var count = 1;
								
								for(var i=0;i<observedMeasures.length;i++){
									var obsMes = observedMeasures.at(i);
									var mes = obsMes.get('measure');
									var oldmeasurement = obsMes.get('measurements').at(0);
									var mc = isOVS ? linkedComponent.get('satisfactionLevel') : linkedComponent.get('valueMeasurement');
									//if(oldmeasurement && oldmeasurement.get('measuredCharacteristic') === mc && rel.new.measure === mes){
									if(oldmeasurement && oldmeasurement.get('measuredCharacteristic') === mc){
										var basneName = oldmeasurement.get('name') + "Base" + count++;
										measurement.createBaseMesurementRelationship(basneName,basneName,oldmeasurement,rel.new.baseMeasureRel);
										break;
									}
								}
							});
						}
					});
				}
			}
		}else{
			var relName;
			if(satisfactionTuple.type === "smm_GradeMeasure"){
				measurement = observedMeasure.createGradeMeasurement(name,name);
				relName = measurement.get('name') + "GradeRel" ;
				measurement.createGradeMesurementRelationship(relName,relName,satisfactionTuple.measurement);
			}else{
				measurement = observedMeasure.createRankingMeasurement(name,name);
				relName = measurement.get('name') + "RankingRel"; 
				measurement.createRankingMesurementRelationship(relName,relName,satisfactionTuple.measurement);				
			}
		}
		measuredCharecterstic.get('measurement').add(measurement);
		return measurement;
	};
	
//#endCustomMethods
	
	}
	path.ScenarioMixin = ScenarioMixin;
	//return ScenarioMixin;
//});