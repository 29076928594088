import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BaseMeasurementRelationship} from './BaseMeasurementRelationship'
import {Base2MeasurementRelationshipMixin} from './Base2MeasurementRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/BaseMeasurementRelationship","appbo/smm/Base2MeasurementRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BaseMeasurementRelationship,Base2MeasurementRelationshipMixin
, DimensionalMeasurement
, BinaryMeasurement){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	//if(!DimensionalMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasurement){
			//DimensionalMeasurement = importPath.DimensionalMeasurement;
		}
        else {
            import('./DimensionalMeasurement').then(loadedModule => {
               // DimensionalMeasurement = loadedModule;
            });

			/*require(["appbo/smm/DimensionalMeasurement"],function(loadedModule){
				DimensionalMeasurement = loadedModule;
			});*/
		}
	//}
	//if(!BinaryMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.BinaryMeasurement){
			//BinaryMeasurement = importPath.BinaryMeasurement;
		}
        else {
            import('./BinaryMeasurement').then(loadedModule => {
                //BinaryMeasurement = loadedModule;
            });

			/*require(["appbo/smm/BinaryMeasurement"],function(loadedModule){
				BinaryMeasurement = loadedModule;
            });*/
		}
	//}
	var Base2MeasurementRelationship = BaseMeasurementRelationship.extend(utils.customExtends({
		relations:Base2MeasurementRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BaseMeasurementRelationship.prototype.initialize.apply(this, arguments);
			//this.on("change:to",this.onToRemoved);
		}
		}
		, new Base2MeasurementRelationshipMixin()
    ));
    Base2MeasurementRelationship["change:to"] = function (model, newTo, options) {
        this.onToRemoved(model, newTo, options);
    }
	Base2MeasurementRelationship.abstract = false;
	Base2MeasurementRelationship.supported = false; 	//As we are not using it yet
	path.Base2MeasurementRelationship = Base2MeasurementRelationship;
	export {Base2MeasurementRelationship};
//});