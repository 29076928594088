import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Participant} from './Participant'
import {RoleMixin} from './RoleMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
/*import { Activity } from './Activity'
import { Assignment } from './Assignment'
import { ValueProposition } from './ValueProposition'
import { RoleDefinition } from './RoleDefinition'*/

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Participant","appbo/vdml/RoleMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Participant,RoleMixin
, Activity
, Assignment
, ValueProposition

, RoleDefinition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	
	var Role = Participant.extend(utils.customExtends({
		relations:RoleMixin.getMixinRelations(),
		subModelTypes: {
			vdml_Member : 'vdml.Member',
			vdml_Party : 'vdml.Party',
			vdml_Performer : 'vdml.Performer',
			vdml_Position : 'vdml.Position'
		},
		initialize: function(attributes, options) {
			Participant.prototype.initialize.apply(this, arguments);
		}
		}
		, new RoleMixin()
	));
	Role.abstract = true;
	path.Role = Role;
	export {Role};
//});