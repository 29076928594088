import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurableElement} from './MeasurableElement'
import {DeliverableFlowMixin} from './DeliverableFlowMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { InputPort } from './InputPort'
import { OutputPort } from './OutputPort'
import { BusinessItem } from './BusinessItem'
import { MeasuredCharacteristic } from './MeasuredCharacteristic'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/DeliverableFlowMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,DeliverableFlowMixin
, InputPort
, OutputPort
, BusinessItem
, MeasuredCharacteristic){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!InputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.InputPort){
			InputPort = importPath.InputPort;
		}
        else {
            import('./InputPort').then(({ default: InputPort }) => {
                InputPort = InputPort;
            });
            /*require(["appbo/vdml/InputPort"], function (loadedModule) {
                InputPort = loadedModule;
			});*/
		}
	}
	if(!OutputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OutputPort){
			OutputPort = importPath.OutputPort;
		}
        else {
            import('./OutputPort').then(({ default: OutputPort }) => {
                OutputPort = OutputPort;
            });
            /*require(["appbo/vdml/OutputPort"], function (loadedModule) {
                OutputPort = loadedModule;
			});*/
		}
	}
	if(!BusinessItem){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItem){
			BusinessItem = importPath.BusinessItem;
		}
        else {
            import('./BusinessItem').then(({ default: BusinessItem }) => {
                BusinessItem = BusinessItem;
            });
            /*require(["appbo/vdml/BusinessItem"], function (loadedModule) {
                BusinessItem = loadedModule;
			});*/
		}
	}
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
        else {
            import('./MeasuredCharacteristic').then(({ default: MeasuredCharacteristic }) => {
                MeasuredCharacteristic = MeasuredCharacteristic;
            });
            /*require(["appbo/vdml/MeasuredCharacteristic"], function (loadedModule) {
                MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	var DeliverableFlow = MeasurableElement.extend(utils.customExtends({
		relations:DeliverableFlowMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			MeasurableElement.prototype.initialize.apply(this, arguments);
			//this.on('destroy',checkAndDeleteFPorts);
		}
		}
		, new DeliverableFlowMixin()
	));
	
	//function checkAndDeleteFPorts(model){
/*		var inputPort = model.previousAttributes().recipient;
		if(inputPort){
			inputPort.destroy();
		}
		var outputPort = model.previousAttributes().provider;
		if(outputPort){
			outputPort.destroy();
		}*/		
	//}
	
	DeliverableFlow.abstract = false;
	path.DeliverableFlow = DeliverableFlow;
	export {DeliverableFlow};
//});