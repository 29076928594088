import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AbstractMeasureElement} from './AbstractMeasureElement'
import {OperationMixin} from './OperationMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
/*import { DirectMeasure } from './DirectMeasure';
import { Scope } from './Scope';
import { Measure } from './Measure';
import { EquivalentMeasureRelationship } from './EquivalentMeasureRelationship';
import { MeasureRelationship } from './MeasureRelationship';*/

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/AbstractMeasureElement","appbo/smm/OperationMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AbstractMeasureElement,OperationMixin
, DirectMeasure
, Scope

, Measure
, EquivalentMeasureRelationship
, MeasureRelationship){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	
	var Operation = AbstractMeasureElement.extend(utils.customExtends({
		relations:OperationMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			AbstractMeasureElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new OperationMixin()
	));
	Operation.invertOperation = function(val){
			if(val === 0 || val == Number.MAX_VALUE){
				return Number.MAX_VALUE;
			}
			else {
				return 1/val;
			}
	};
	Operation.squareOperation = function(val){
			if(val != Number.MAX_VALUE){
    			val = val * val;
    		}
			return val;
	};
	Operation.squareRootOperation = function(val){
			if(val != Number.MAX_VALUE){
    			val = Math.sqrt(val);
    		}
			return val;
	};	
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	Operation.abstract = false;
	Operation.supported = !sp2Enabled;
	path.Operation = Operation;
	export {Operation};
//});