import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurableElement} from './MeasurableElement'
import {ResourceUseMixin} from './ResourceUseMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { OutputPort } from './OutputPort'
import { InputPort } from './InputPort'
import { MeasuredCharacteristic } from './MeasuredCharacteristic'
import { Expression } from './Expression'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/ResourceUseMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,ResourceUseMixin
, OutputPort
, InputPort
, MeasuredCharacteristic

, Expression){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!OutputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OutputPort){
			OutputPort = importPath.OutputPort;
		}
		else{
            import('./OutputPort').then(loadedModule => {
                OutputPort = loadedModule;
			});
			/*require(["appbo/vdml/OutputPort"],function(loadedModule){
				OutputPort = loadedModule;
			});*/
		}
	}
	if(!InputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.InputPort){
			InputPort = importPath.InputPort;
		}
		else{
            import('./InputPort').then(loadedModule => {
               // InputPort = loadedModule;
			});
			/*require(["appbo/vdml/InputPort"],function(loadedModule){
				InputPort = loadedModule;
			});*/
		}
	}
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
		else{
            import('./MeasuredCharacteristic').then(loadedModule => {
                MeasuredCharacteristic = loadedModule;
			});
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	if(!Expression){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Expression){
			Expression = importPath.Expression;
		}
		else{
            import('./Expression').then(loadedModule => {
                Expression = loadedModule;
			});
			/*require(["appbo/vdml/Expression"],function(loadedModule){
				Expression = loadedModule;
			});*/
		}
	}
	var ResourceUse = MeasurableElement.extend(utils.customExtends({
		relations:ResourceUseMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			MeasurableElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new ResourceUseMixin()
	));
	
	ResourceUse.abstract = false;
	ResourceUse.supported = false;
	path.ResourceUse = ResourceUse;
	export {ResourceUse};
//});