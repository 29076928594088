import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {DataManager} from '../../../../com/vbee/data/DataManager'
    var path = DataManager.getDataManager().buildAppNsPath("ecomap.migration", global.version);
    var EcoMap1004 = function () {
    }

    EcoMap1004.migrate = function (ecoPackage,callback) {
        var self = this;
        var altId = DataManager.getDataManager().getRepositoryId(ecoPackage.id);
        var diagrams = ecoPackage.get('diagrams');
        for (var i = 0; i < diagrams.length; i++) {
			var diagram = diagrams.at(i);
            var diagramType = diagram.get('type');
			if(diagramType == "vdml_StrategyMapDiagram"){
				var newXML = diagram.get('data');
				var xmlDoc = jQuery.parseXML(newXML);
				//var $xml = jQuery(xmlDoc);
				var causalRelationshipNodes = xmlDoc.getElementsByTagName("vdml:causalRelationship");
				for(var itr=0;itr<causalRelationshipNodes.length;itr++){
					if(causalRelationshipNodes[itr].getAttribute("curveType") == null){
					   causalRelationshipNodes[itr].setAttribute("curveType", "c");
					}
				}
				newXML = (new XMLSerializer()).serializeToString(xmlDoc);
				diagram.set('data',newXML);
			}			
        }
		
		function migrateDependencyToImages(ecoPackage){
			var diagrams = ecoPackage.get('diagrams');
			var planCodeConList = DataManager.getDataManager().get("planPackages")[DataManager.getDataManager().get("currentPlan").id].filter(item=> item.type == 'beeppackage_CodeContainer')
			//var EcoMapMixin = Backbone.Relational.store.getObjectByName("ecomap.EcoMapMixin");
			for (var k = 0; k < diagrams.length; k++) {
				var newXML = diagrams.at(k).get('data');
				var xmlDoc = jQuery.parseXML(newXML);
				var $xml = jQuery(xmlDoc);													
				var nodes = $xml.find('[backgroundUrl]');
				nodes.each(function (callback, node) {
					var url = node.getAttribute('backgroundUrl');
					if(url && url.startsWith('data')){
						for (var i = 0; i < planCodeConList.length; i++) {
							var codeContainer = window.utils.getElementModel(planCodeConList[i].documentId,['beeppackage.CodeContainer']);
							if(codeContainer && codeContainer.get('code') === url && codeContainer.get('dataReference')){
								newXML = newXML.replace(url,codeContainer.get('id'));
								url = codeContainer.id;
								break;
							}
						}						
					}
					if(url && url.startsWith('@')){
						var codeContainer = window.utils.getElementModel(url,['beeppackage.CodeContainer']);
						if(codeContainer){
							ecoPackage.get('dependentPackage').add(codeContainer);	
						}
					}
				});
				diagram.set('data',newXML);
			}
		}
		migrateDependencyToImages(ecoPackage);
		callback(ecoPackage);
    }
    path.EcoMap1004 = EcoMap1004;
    export {EcoMap1004};
//});