import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {CollaborationDiagram} from './CollaborationDiagram'
import {CollaborationDiagramMixin} from './CollaborationDiagramMixin'
import {ProcessDiscoveryDiagram} from './ProcessDiscoveryDiagram'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/CollaborationDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,CollaborationDiagramMixin
, ProcessDiscoveryDiagram){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ProcessDiscoveryDiagram){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ProcessDiscoveryDiagram){
			ProcessDiscoveryDiagram = importPath.ProcessDiscoveryDiagram;
		}
		else{
			import('./ProcessDiscoveryDiagram').then(({ default: ProcessDiscoveryDiagram }) => {
                ProcessDiscoveryDiagram = ProcessDiscoveryDiagram;
            });
			/*require(["appbo/vdml/ProcessDiscoveryDiagram"],function(loadedModule){
				ProcessDiscoveryDiagram = loadedModule;
			});*/
		}
	}
	export class ActivityNetworkDiagramMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.ActivityNetworkDiagramMixin = ActivityNetworkDiagramMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_ActivityNetworkDiagram"
		}
		return jQuery.extend(CollaborationDiagram.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!ActivityNetworkDiagramMixin.cummulativeRelations) {
            ActivityNetworkDiagramMixin.cummulativeRelations = _.union(ActivityNetworkDiagramMixin.getMixinRelations()
                , CollaborationDiagramMixin.getCumulativeMixinRelations()
            );
        }
		return ActivityNetworkDiagramMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_CollaborationDiagram"
		];
	}
	static getProperties (){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("processDiscoveryDiagram") ? this.get("processDiscoveryDiagram") : this.previousAttributes().processDiscoveryDiagram;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("processDiscoveryDiagram") ? this.get("processDiscoveryDiagram") : this.previousAttributes().processDiscoveryDiagram;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ActivityNetworkDiagramPropertiesTemplate.html",
			templateName : "ActivityNetworkDiagramPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ActivityNetworkDiagramViewModel",
			tabId : "ActivityNetworkDiagramView",
			tabName: "ActivityNetworkDiagram"
		}
	}
	
	}
	path.ActivityNetworkDiagramMixin = ActivityNetworkDiagramMixin;
	//return ActivityNetworkDiagramMixin;
//});