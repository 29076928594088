import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {Status} from '../../../../../version1/bo/transformation/Status'
import {AlternativeViewModel} from './AlternativeViewModel'
import { Phase2Mixin } from '../../../../bo/transformation/Phase2Mixin'
//import {PhaseMixin} from '../../../../../version1/bo/transformation/PhaseMixin'

//define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global","appbo/transformation/Status","appviews/transformation/views/properties/AlternativeViewModel","appbo/transformation/PhaseMixin"],
//function(require,$,_, Backbone,ko, koMapping,kb,bootbox,DataManager,global,Status,AlternativeViewModel,PhaseMixin)
//{
    var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties", global.version);
    export class PhaseViewModel {
        constructor(model,options){
			var self = this;
			this.init(model, options);
		}
    htmlEscape(str) {
        return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
    }
    cleanUp() {
        var self = this;
        delete self.model.phaseOwner;
        self.model.destroy();
    };
    handlePublicMenus() {
        var self = this;
        /*
        DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-plus","New",$.proxy(handleNew,this));*/
        //DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-remove","Remove",$.proxy(this.removeSelectedItems,this));
    }
    onPhaseExplorerHandler(view) {
        var self = this;
        //$('#currentRepository').attr('href', "#search/" + window.vdmModelView.visualSearch.searchBox.value());
        //$('#brandIcon').attr('href', "#search/" + window.vdmModelView.visualSearch.searchBox.value());
        //DataManager.getDataManager().get('router').navigate("search/" + window.vdmModelView.visualSearch.searchBox.value(), { replace: true });
        var planId = view.model.get('phaseOwner');
        var dataManager = DataManager.getDataManager();
        //var wsId = dataManager.get('artifactsDocuments')[planId].workspaceId;
        var phaseId = view.model.id;
		if(!DataManager.getDataManager().checkPlanVersion(view.model.phaseOwner.get('version'))){ return }
        window.utils.startSpinner('spinnerPhaseExplorer', "Loading Plan Data...");
        window.utils.showSpinnerPercentage(10);
        setTimeout(function () {
            /*var alt = window.utils.getElementModel(altId,['transformation.Alternative']);
			if(alt && window.loadedPackages[planId]){//wrong switch is needed
                var plan = window.utils.getElementModel(planId,['transformation.Plan']);
                dataManager.get('router').navigate("/" + window.utils.getPlanNavigateUrl(plan.id,plan.get('documentVersion')) + '/'+ altId, { trigger: true });
            } else {*/
                window.utils.loadPlan(planId, function (plan) {
                    if(!plan){
                        window.utils.stopSpinner('spinnerPhaseExplorer');
						bootbox.confirm('Plan Does not Exists. Delete Plan?', function(result) { 
							if(result){
								var serverChangeSet = DataManager.getDataManager().get(DataManager.getDataManager().CURRENT_CHANGESET);
								var tempPlan = {'id':planId,'type':'transformation_Plan','version':0};
								DataManager.getDataManager().addpackageForDelete(serverChangeSet,tempPlan,tempPlan);
								window.vdmModelView.plansCollection.remove(window.vdmModelView.plansCollection.get(tempPlan.id))
							}
						});
						return;
					}
					var ph = plan.get('phase').findWhere({id:phaseId});
					var altId = ph.get('primary') ? ph.get('primary').id : ph.get('phaseAlternative').at(0).get('id');
                    window.utils.showSpinnerPercentage(30); 
                    dataManager.get('router').navigate("/" + window.utils.getPlanNavigateUrl(plan.id,plan.get('documentVersion')) + '/'+ altId, { trigger: true });
                });
            //}
        }, 100);
    };
    openPhaseDetailsViewHandler(view, event) {
        event.stopPropagation();
        var self = this;
        var bo = view.model;
		const dataManager = DataManager.getDataManager();
		var PhaseMixin = Backbone.Relational.store.getObjectByName("transformation.PhaseMixin");
		if(!dataManager.checkPlanVersion(view.model.phaseOwner.get('version'))){ return }
        /*if (!(bo instanceof Backbone.RelationalModel)) {
            bo = Backbone.Relational.store.getObjectByName('transformation.Phase').find({ id: bo.id });
        }
        if (!bo) {*/
		window.utils.startSpinner('loadPhase', "Loading Phase Data...");
		window.utils.loadPlan(view.model.get('phaseOwner'), function (plan) {
			bo = plan.get('phase').findWhere({ 'id': view.model.id });
			function waitForPlanLoad() {
				setTimeout(function () {
					if (window.loadedPackages[plan.id]) {
						const mixinClass = dataManager.getModelTypeByTypeStr(bo.get('type'), bo.getNestedParent().get('version'), true);
						var addOptions = { 'planModel': plan, 'parentModel': view.model, 'customMode': false };
						window.getAndCreateModalDialog("", self.encodeId, mixinClass, bo, "PhaseDetails", self.options, addOptions);
						window.utils.stopSpinner('loadPhase');
					} else {
						waitForPlanLoad();
					}
				}, 100);
			}
			if (dataManager.isSP2Enabled()) {
				dataManager.set('currentPlan', plan);
				waitForPlanLoad();
			} else {
				const mixinClass = dataManager.getModelTypeByTypeStr(bo.get('type'), bo.getNestedParent().get('version'), true);
				var addOptions = { 'planModel': plan, 'parentModel': view.model, 'customMode': false };
				window.getAndCreateModalDialog("", self.encodeId, mixinClass, bo, "PhaseDetails", self.options, addOptions);
				window.utils.stopSpinner('loadPhase');
			}
		});
        /*} else {
            var addOptions = { 'planModel': bo.get('phaseOwner'), 'parentModel': self.model, 'parentPlanView': self.model.phaseOwner };
            window.getAndCreateModalDialog("", self.encodeId, PhaseMixin, bo, "PhaseDetails", self.options, addOptions);
        }*/
        event.stopPropagation();
    };
	init(model,options){
		var self = this;
		this.PhaseViewModel = this;
		this.model = model;
		model = null;
		this.editable = ko.observable(false);
		this.id = kb.observable(self.model, 'id');
		this.options = options;
		options = null;
		this.encodeId = self.htmlEscape(this.id());
		this.version = kb.observable(self.model, 'version');
		this.name = kb.observable(self.model, 'name');
		this.description = kb.observable(self.model, 'description');
		this.startDate = kb.observable(self.model, 'startDate');
		this.endDate = kb.observable(self.model, 'endDate');
		this.planDate = kb.observable(self.model, 'planDate');
		this.name = kb.observable(self.model, 'name');
		this.statusOptions = Status.symbols();
		this.isGoalPhase = ko.observable(false);
		this.isAsIsPhase = ko.observable(false);
		this.customMode=ko.observable(false);
		if(self.model){
/*			if(!self.relModel){
				self.relModel = Backbone.Relational.store.getObjectByName('transformation.Phase').find({id:self.model.id});
			}*/
			var planModel = self.model.phaseOwner?self.model.phaseOwner:self.model.get('phaseOwner');
			var goalPhase = planModel.get('goal');
			if(goalPhase && (goalPhase === self.model.get('id') || goalPhase.id === self.model.get('id'))){
				this.isGoalPhase(true); 
			}
			var asIsPhase = planModel.get('asIs');
			if(asIsPhase && (asIsPhase === self.model.get('id') || asIsPhase.id === self.model.get('id'))){
				this.isAsIsPhase(true); 
			}
		}
		this.phaseStatus = kb.observable(self.model, 'status');
		//if status type is Object or symbol
		if(typeof this.phaseStatus() !== "string" && this.phaseStatus()){
			this.phaseStatus = this.phaseStatus().name;
		}
		this.phaseAlternative = kb.collectionObservable(self.model.get('phaseAlternative'),{view_model : AlternativeViewModel});
		this.hasMoreThanOneAlternative = ko.computed(function(){
			return self.phaseAlternative().length > 1;
		})
        this.onPhaseExplorer = _.bind(self.onPhaseExplorerHandler, self);
		
        this.openPhaseDetailsView = _.bind(self.openPhaseDetailsViewHandler, self);
		this.afterRenderView = function(){
		}


		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['Phase'
		,'name'
		,'description'
		,'startDate'
		,'endDate'
		,'name'
		,'status'
		,'assignedTicket'
		,'Ticket'
		,'Initiated'
		,'InProgress'
		,'Closed'
		,'goalPhase'
		,'asIsPhase'
		]);

		for(var i=0;i<this.statusOptions.length;i++){
			this.statusOptions[i].description = this.labels[this.statusOptions[i].name]();
		}
	}
	//path.PhaseViewModel = PhaseViewModel;
	static getInstance(model,options){
		var view = new PhaseViewModel(model,options);
		//view.init(model, options);
		return view;
	};
}
path.PhaseViewModel = PhaseViewModel;