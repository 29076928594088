import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin
//, User){
	
	var path = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
	/*if(!User){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.User){
			User = importPath.User;
		}
		else{
			require(["appbo/tickets/User"],function(loadedModule){
				User = loadedModule;
			});
		}
	}*/
	export class VoteMixin{
	defaults(){
		var ret = {
			type: "tickets_Vote"
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"tickets_Vote",
			key:"voter",
			relatedModel:"tickets.User",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!VoteMixin.cummulativeRelations) {
            VoteMixin.cummulativeRelations = _.union(VoteMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return VoteMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
		return [
			{name : "inFavour",type : "VoteStatus",defaultValue : "null",containingClass : "tickets_Vote" },
			{name : "comments",type : "EString",defaultValue : "null",containingClass : "tickets_Vote" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("voteOwner") ? this.get("voteOwner") : this.previousAttributes().voteOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("voteOwner") ? this.get("voteOwner") : this.previousAttributes().voteOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	static getViewProperties(type){
		return {
			templatePath : "views/tickets/views/properties/VotePropertiesTemplate.html",
			templateName : "VotePropertiesTemplate",
			viewTypeStr : "appviews/tickets/views/properties/VoteViewModel",
			tabId : "VoteView",
			tabName: "Vote"
		}
	}
	//
	//return VoteMixin;
}
path.VoteMixin = VoteMixin;