import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelItem} from './BusinessModelItem'
import {BusinessModelItemMixin} from './BusinessModelItemMixin'
import {Capability} from './Capability'
import {Competence} from './Competence'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelItem","appbo/vdml/BusinessModelItemMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelItem,BusinessModelItemMixin
, Capability
, Competence){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Capability){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Capability){
			Capability = importPath.Capability;
		}
		else{
			import('./Capability').then(({ default: Capability }) => {
                Capability = Capability;
            });
			/*require(["appbo/vdml/Capability"],function(loadedModule){
				Capability = loadedModule;
			});*/
		}
	}
	if(!Competence){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Competence){
			Competence = importPath.Competence;
		}
		else{
			import('./Competence').then(({ default: Competence }) => {
                Competence = Competence;
            });
			/*require(["appbo/vdml/Competence"],function(loadedModule){
				Competence = loadedModule;
			});*/
		}
	}
	export class BusinessModelCompetenceMixin {

	defaults(){
		var ret = {
			type: "vdml_BusinessModelCompetence"
		}
		return jQuery.extend(BusinessModelItem.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"vdml_BusinessModelCompetence",
			key:"capability",
			relatedModel:"vdml.Capability",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_BusinessModelCompetence",
			key:"competence",
			relatedModel:"vdml.Competence",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!BusinessModelCompetenceMixin.cummulativeRelations) {
            BusinessModelCompetenceMixin.cummulativeRelations = _.union(BusinessModelCompetenceMixin.getMixinRelations()
                , BusinessModelItemMixin.getCumulativeMixinRelations()
            );
        }
		return BusinessModelCompetenceMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_BusinessModelItem"
		];
	}
	static getProperties (){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("bmCompetenceOwner") ? this.get("bmCompetenceOwner") : this.previousAttributes().bmCompetenceOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("bmCompetenceOwner") ? this.get("bmCompetenceOwner") : this.previousAttributes().bmCompetenceOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BusinessModelCompetencePropertiesTemplate.html",
			templateName : "BusinessModelCompetencePropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BusinessModelCompetenceViewModel",
			tabId : "BusinessModelCompetenceView",
			tabName: "BusinessModelCompetence"
		}
	}
//#startCustomMethods	

	checkEmptyCompetence(model,newCompetence){
		if(Backbone.skipPersistance){
			return;
		}
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
			if(!newCompetence){
				this.destroy();
			}
		}
	}
	addBIStoreAsBMResourceStore(businessItem){
		var bm = this.get('bmCompetenceOwner');
		var stores = businessItem.get('store');
		if(stores.length === 1){
			bm.get('bmResourceStore').add(stores.at(0));
		}
	}
//#endCustomMethods
}
	path.BusinessModelCompetenceMixin = BusinessModelCompetenceMixin;
	
	//return BusinessModelCompetenceMixin;
//});