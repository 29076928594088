import * as $ from 'jquery'
import * as _ from 'underscore'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
var path = DataManager.getDataManager().buildAppNsPath("tickets.views.properties", global.version);
export class History{
    constructor () {
    }
    static getViewProperties = function (type) {
        return {
            templatePath: "views/tickets/views/properties/HistoryTemplate.html",
            templateName: "HistoryTemplate",
            viewTypeStr: "appviews/tickets/views/properties/HistoryViewModel",
            tabId: "HistoryView",
            tabName: "History"
        }
    }

}
path.History = History;
