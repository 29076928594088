import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BMElement} from './BMElement'
import {BMElementMixin} from './BMElementMixin'
import {BusinessModel} from './BusinessModel'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BMElement","appbo/vdml/BMElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BMElement,BMElementMixin
, BusinessModel){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!BusinessModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModel){
			BusinessModel = importPath.BusinessModel;
		}
		else{
			import('./BusinessModel').then(({ default: BusinessModel }) => {
                BusinessModel = BusinessModel;
            });
			/*require(["appbo/vdml/BusinessModel"],function(loadedModule){
				BusinessModel = loadedModule;
			});*/
		}
	}
	export class BusinessModelPortfolioMixin {

	defaults(){
		var ret = {
			type: "vdml_BusinessModelPortfolio"
		}
		return jQuery.extend(BMElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessModelPortfolio",
			key:"BusinessModel",
			relatedModel:"vdml.BusinessModel",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!BusinessModelPortfolioMixin.cummulativeRelations) {
            BusinessModelPortfolioMixin.cummulativeRelations = _.union(BusinessModelPortfolioMixin.getMixinRelations()
                , BMElementMixin.getCumulativeMixinRelations()
            );
        }
		return BusinessModelPortfolioMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_BMElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("businessModelPortfolioOwner") ? this.get("businessModelPortfolioOwner") : this.previousAttributes().businessModelPortfolioOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("businessModelPortfolioOwner") ? this.get("businessModelPortfolioOwner") : this.previousAttributes().businessModelPortfolioOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("businessModelPortfolioOwner") ? this.get("businessModelPortfolioOwner") : this.previousAttributes().businessModelPortfolioOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("businessModelPortfolioOwner") ? this.get("businessModelPortfolioOwner") : this.previousAttributes().businessModelPortfolioOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BusinessModelPortfolioPropertiesTemplate.html",
			templateName : "BusinessModelPortfolioPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BusinessModelPortfolioViewModel",
			tabId : "BusinessModelPortfolioView",
			tabName: "BusinessModelPortfolio"
		}
	}
	
	}
path.BusinessModelPortfolioMixin = BusinessModelPortfolioMixin;
	//return BusinessModelPortfolioMixin;
//});