import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {Comment} from './Comment'
import {Solution} from './Solution'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin
//, Comment
//, User

//, Solution){
	
	
	/*if(!Comment){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.Comment){
			Comment = importPath.Comment;
		}
		else{
			require(["appbo/tickets/Comment"],function(loadedModule){
				Comment = loadedModule;
			});
		}
	}
	if(!User){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.User){
			User = importPath.User;
		}
		else{
			require(["appbo/tickets/User"],function(loadedModule){
				User = loadedModule;
			});
		}
	}
	if(!EObject){
		var importPath = DataManager.getDataManager().buildAppNsPath("cmof",global.version);
		if(importPath.EObject){
			EObject = importPath.EObject;
		}
		else{
			require(["appbo/cmof/EObject"],function(loadedModule){
				EObject = loadedModule;
			});
		}
	}
	if(!Solution){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.Solution){
			Solution = importPath.Solution;
		}
		else{
			require(["appbo/tickets/Solution"],function(loadedModule){
				Solution = loadedModule;
			});
		}
	}*/
	export class TicketMixin{
		constructor(){
			var path = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
			path.TicketMixin = TicketMixin;
		}
	
	defaults(){
		var ret = {
			type: "tickets_Ticket"
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"tickets_Ticket",
			key:"comment",
			relatedModel:"tickets.Comment",
			reverseRelation: {
				key:"commentOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"tickets_Ticket",
			key:"closedBy",
			relatedModel:"tickets.User",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"tickets_Ticket",
			key:"referredElement",
			relatedModel:"cmof.EObject",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"tickets_Ticket",
			key:"possilbeSolution",
			relatedModel:"tickets.Solution",
			reverseRelation: {
				key:"possilbeSolutionOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"tickets_Ticket",
			key:"acceptedSolution",
			relatedModel:"tickets.Solution",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasOne,
			containingClass:"tickets_Ticket",
			key:"assignedTo",
			relatedModel:"tickets.User",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!TicketMixin.cummulativeRelations) {
            TicketMixin.cummulativeRelations = _.union(TicketMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return TicketMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
		return [
			{name : "creationDate",type : "EDate",defaultValue : "null",containingClass : "tickets_Ticket" },
			{name : "lastUpdate",type : "EDate",defaultValue : "null",containingClass : "tickets_Ticket" },
			{name : "status",type : "TicketStatus",defaultValue : "null",containingClass : "tickets_Ticket" },
			{name : "closingDate",type : "EDate",defaultValue : "null",containingClass : "tickets_Ticket" },
			{name : "ticketType",type : "TicketType",defaultValue : "null",containingClass : "tickets_Ticket" },
			{name : "systemGenerated",type : "EBoolean",defaultValue : "null",containingClass : "tickets_Ticket" },
			{name : "fixedInVersion",type : "EString",defaultValue : "null",containingClass : "tickets_Ticket" },
			{name : "fixingDate",type : "EDate",defaultValue : "null",containingClass : "tickets_Ticket" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "selectedSolutionCriteria",type : "EString",defaultValue : "null",containingClass : "tickets_Ticket" },
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("ticketOwner") ? this.get("ticketOwner") : this.previousAttributes().ticketOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("ticketOwner") ? this.get("ticketOwner") : this.previousAttributes().ticketOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	static getViewProperties(type){
		return {
			templatePath : "views/tickets/views/properties/TicketPropertiesTemplate.html",
			templateName : "TicketPropertiesTemplate",
			viewTypeStr : "appviews/tickets/views/properties/TicketViewModel",
			tabId : "TicketView",
			tabName: "Ticket"
		}
	}
	
	//path.TicketMixin = TicketMixin;
	//return TicketMixin;
}