import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EcoMapDiagramMixin} from './EcoMapDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/CollaborationDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,CollaborationDiagramMixin
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class EcoMapDiagram2Mixin {

		getMixinClass(){
			return EcoMapDiagram2Mixin;
		}
		
		getViewProperties(type){
			return {
				templatePath : "views/ecoMap/views/designer/EcoMapDesignerTemplate.html",
				templateName : "EcoMapDesignerTemplate",
				viewTypeStr : "appviews/ecoMap/views/designer/EcoMapDesigner2ViewModel",
				tabId : "EcoMapDesignerView",
				tabName: "EcoMapDesigner"
			}
		}
	}
	utils.customExtendClass (EcoMapDiagram2Mixin,new EcoMapDiagramMixin());

	path.EcoMapDiagram2Mixin = EcoMapDiagram2Mixin;