import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurableElementMixin} from './MeasurableElementMixin'
import {VDMLCanvasElementMixin} from './VDMLCanvasElementMixin'
import {MeasurableElement} from './MeasurableElement'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/MeasurableElementMixin","appbo/vdml/VDMLCanvasElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,MeasurableElementMixin,VDMLCanvasElementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class CompetenceMixin {

	defaults(){
		var ret = {
			type: "vdml_Competence"
		}
		return jQuery.extend(MeasurableElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!CompetenceMixin.cummulativeRelations) {
            CompetenceMixin.cummulativeRelations = _.union(CompetenceMixin.getMixinRelations()
                ,MeasurableElementMixin.getCumulativeMixinRelations()
		        ,VDMLCanvasElementMixin.getCumulativeMixinRelations()
            );
        }
		return CompetenceMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_MeasurableElement","vdml_VDMLCanvasElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		return MeasurableElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = "";
		}
		return MeasurableElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/CompetencePropertiesTemplate.html",
			templateName : "CompetencePropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/CompetenceViewModel",
			tabId : "CompetenceView",
			tabName: "Competence"
		}
	}
	getBusinessModelsInvolved (callback) {
		var self = this;
		var businessModels = [];
		DataManager.getDataManager().getAllDocumentsCollectionOfType(self, 'vdml_BusinessModel', function (refs) {
			var count = refs.length;
			function getBMInstance() {
				count--;
				if (count >= 0 && refs.at(count).get('parent')) {
					var ref = refs.at(count);
					var parentId = ref.get('parent');
					var repId = DataManager.getDataManager().getRepositoryId(parentId);
					var vdmStore = DataManager.getDataManager().getVDMStore(repId);
					DataManager.getDataManager().fetchDocumentFromPackage(parentId, "appbo/vdml/ValueDeliveryModel", ref.get('version'), ref.get('id'), "appbo/vdml/BusinessModel", vdmStore, {
						success: function (model) {
							var bmCompetencies = model.get('bmCompetence');
							var competenceExistsInBM = bmCompetencies.findWhere({ 'competence': self });
							if (competenceExistsInBM) {
								businessModels.push(model);
							}
							getBMInstance();
						},
						error: function (error) {
							console.log(error);
							getBMInstance();
						}
					});
				} else {
					if (count >= 0) {
						getBMInstance();
					} else {
						callback(businessModels);
					}
				}
			}
			getBMInstance();
		}, null, null, true, null, false);
	};
	
	}
	path.CompetenceMixin = CompetenceMixin;
	//return CompetenceMixin;
//});