import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BMElement} from './BMElement'
import {BMElementMixin} from './BMElementMixin'
import {VdmlElement} from './VdmlElement'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BMElement","appbo/vdml/BMElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BMElement,BMElementMixin
, VdmlElement){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!VdmlElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.VdmlElement){
			VdmlElement = importPath.VdmlElement;
		}
		else{
			import('./VdmlElement').then(({ default: VdmlElement }) => {
                VdmlElement = VdmlElement;
            });
			/*require(["appbo/vdml/VdmlElement"],function(loadedModule){
				VdmlElement = loadedModule;
			});*/
		}
	}
	export class BusinessModelItemMixin {

	defaults(){
		var ret = {
			type: "vdml_BusinessModelItem"
		}
		return jQuery.extend(BMElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
/*		{
			type :Backbone.HasOne,
			containingClass:"vdml_BusinessModelItem",
			key:"instance",
			relatedModel:"vdml.VdmlElement",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}*/
		])
	}
	static getCumulativeMixinRelations(){
		if (!BusinessModelItemMixin.cummulativeRelations) {
            BusinessModelItemMixin.cummulativeRelations = _.union(BusinessModelItemMixin.getMixinRelations()
                , BMElementMixin.getCumulativeMixinRelations()
            );
        }
		return BusinessModelItemMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_BMElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		return BMElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return BMElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BusinessModelItemPropertiesTemplate.html",
			templateName : "BusinessModelItemPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BusinessModelItemViewModel",
			tabId : "BusinessModelItemView",
			tabName: "BusinessModelItem"
		}
	}
	}
	path.BusinessModelItemMixin = BusinessModelItemMixin;
	
	//return BusinessModelItemMixin;
//});