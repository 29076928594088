import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../libs/bootbox/bootbox'
import { DataManager } from '../../../../com/vbee/data/DataManager'
import { ProcessTree } from '../../../views/valueStreamMap/views/designer/ProcessTree'
import { BeepPackageMixin } from '../../../bo/beeppackage/BeepPackageMixin'
import { ValueStreamMapDiagram } from '../../../bo/vdml/ValueStreamMapDiagram'
import { PackageReference as PlanPackageReference } from '../../../bo/transformation/PackageReference'
import { PackageReference } from '../../../../com/vbee/filesystem/PackageReference'

/*define(["require", "jquery", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "appbo/beeppackage/BeepPackageMixin", "appbo/transformation/PackageReference", "appcommon/com/vbee/filesystem/PackageReference", "appbo/vdml/ValueStreamMapDiagram", "app/global", "appviews/valueLibrary/properties/ValueLibraryViewModel", "appviews/valueStreamMap/views/designer/ProcessTree"],
	function (require, $, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, BeepPackageMixin, PlanPackageReference, PackageReference, ValueStreamMapDiagram, global, ValueLibraryViewModel, ProcessTree) {*/
		var path = DataManager.getDataManager().buildAppNsPath("valueLibrary.views.properties", global.version);
export class ValueLibraryDetailsViewModel {
    constructor(model, options) {
        this.init(model, options);
    }
    init(model, options) {
        var self = this;
        this.ValueLibraryDetailsViewModel = this;
        this.parentView = model;
        this.model = model.model;
        this.id = kb.observable(self.model, 'id');
        function htmlEscape(str) {
            return String(str)
                .replace(/@/g, '')
                .replace(/ /g, '');
        }
        this.encodeId = htmlEscape(this.id());
        this.enableDelete = ko.observable(true);
        this.enableComplete = ko.observable(true);
        this.enableProcess = ko.observable(this.model.get('process') ? true : false);
        this.name = ko.observable(self.model ? self.model.get('name') : null);
        this.description = ko.observable(self.model ? self.model.get('description') : "");
        this.importButton = ko.observable(true);
        if(self.model && self.model.get('value').length > 0){
            this.importButton(false);
        }
        this.existingPackages = [];
        this.currentAlt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
        this.name.subscribe(function (val) {
            self.description(val);
        });

    }


    dispose() {
        var self = this;
        window.cleanViewModel(self);
    };

    saveValLibDetails() {
        var self = this;
        if (self.name() !== "") {
            if (self.name().trim() != self.model.get('name')) {
                self.model.set('name', self.name().trim());
                window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'), DataManager.getDataManager().get('viewAlternative'), self.ValueLibraryDetailsViewModel);
            }
            self.model.set('description', self.description().trim());
        }

    };
    importValueLibrary() {
        var self = this;
        self.parentView.importValueLibrary();
    }
    deleteValLib() {
        var self = this;
        bootbox.confirm('' + DataManager.getDataManager().get('localeManager').get('MapDeleteAlert','Value Library'), function(result) {
	        if(result) {
	            self.doValueLibraryDelete();
		    }    
		});
    };

    doValueLibraryDelete() {
        var self=this;
        window.utils.startSpinner('deleteValLib', 'Deleting Value Library...');
        window.setTimeout(function () {	
            self.model.destroy();
            DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
            window.utils.stopSpinner('deleteValLib');
        }, 500);
    };

    checkUniqueValue(view, event) {
        var self = this;
        var name = event.currentTarget.value.trim();
        if (name !== "") {
            if (self.model.get('name') === name) {
                $('#duplicateName' + self.encodeId).text("");
                $("#complete").removeAttr('disabled');
                return;
            }
            var unique = window.isUniqueName(name, null, self.id(), self.model.getNestedParent().get('valueLibrary'));
            if (unique) {
                $('#duplicateName' + self.encodeId).text("");
                $("#complete").removeAttr('disabled');
            }
            else {
                var lcManager = DataManager.getDataManager().get('localeManager');
                $('#duplicateName' + self.encodeId).text(lcManager.get('DuplicateAlert', lcManager.get('ValueLibrary')));
                $("#complete").attr('disabled', 'disabled');
            }
        }
        else {
            $("#complete").attr('disabled', 'disabled');
        }
    };
    afterRenderView() {
    }

    static getInstance(model, options) {
        var view = new ValueLibraryDetailsViewModel(model, options);
        view.init(model, options);
        return view;
    }
}
path.ValueLibraryDetailsViewModel = ValueLibraryDetailsViewModel;
