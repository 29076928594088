import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {CollaborationDiagram} from './CollaborationDiagram'
import {CollaborationDiagramMixin} from './CollaborationDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/CollaborationDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,CollaborationDiagramMixin
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class EcoMapDiagramMixin {

	defaults(){
		var ret = {
			type: "vdml_EcoMapDiagram"
		}
		return jQuery.extend(CollaborationDiagram.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
	    return _.union([
            {
            	type: Backbone.HasMany,
            	containingClass: "vdml_EcoMapDiagram",
            	key: "participant",
            	relatedModel: "vdml.Participant",
            	includeInJSON: Backbone.Model.prototype.idAttribute,
            },
            {
                type: Backbone.HasMany,
                containingClass: "vdml_EcoMapDiagram",
                key: "businessModel",
                relatedModel: "vdml.BusinessModel",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            },
            {
                type: Backbone.HasMany,
                containingClass: "vdml_EcoMapDiagram",
                key: "valueProposition",
                relatedModel: "vdml.ValueProposition",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            }
		])
	}
	static getCumulativeMixinRelations(){
		if (!EcoMapDiagramMixin.cummulativeRelations) {
            EcoMapDiagramMixin.cummulativeRelations = _.union(EcoMapDiagramMixin.getMixinRelations()
                , CollaborationDiagramMixin.getCumulativeMixinRelations()
            );
        }
		return EcoMapDiagramMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_CollaborationDiagram"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
		    container = this.get("ecoMap") ? this.get("ecoMap") : this.previousAttributes().ecoMap;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
		    container = this.get("ecoMap") ? this.get("ecoMap") : this.previousAttributes().ecoMap;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/ecoMap/views/designer/EcoMapDesignerTemplate.html",
            templateName : "EcoMapDesignerTemplate",
            viewTypeStr : "appviews/ecoMap/views/designer/EcoMapDesignerViewModel",
            tabId : "EcoMapDesignerView",
            tabName: "EcoMapDesignerView"
		}
	}
	static getDialogViewProperties (type) {
	    if (type && type === "ShapeProperties") {
	        return {
	            templatePath: "views/ecoMap/views/designer/ShapePropertiesTemplate.html",
	            templateName: "ShapePropertiesTemplate",
				viewTypeStr: "appviews/ecoMap/views/designer/ShapePropertiesViewModel",
	            tabId: "ShapePropertiesView",
	            tabName: "ShapeProperties"
	        }
	    }else if(type === "TargetDetails"){
			return{
				templatePath: "views/canvas/views/designer/CanvasTargetDetailsTemplate.html",
		        templateName: "CanvasTargetDetailsTemplate",
		        viewTypeStr: "appviews/canvas/views/designer/CanvasTargetDetailsViewModel",
		        tabId: "CanvasTargetDetailsView",
		        tabName: "CanvasTargetDetails"
			}
		}else if(type==="explorerModelJson"){
			return{
				templatePath : "views/beeppackage/views/properties/explorerModelJsonTemplate.html",
			    templateName : "explorerModelJsonTemplate",
			    viewTypeStr : "appviews/beeppackage/views/properties/explorerModelJsonViewModel",
			     tabId: "explorerModelJsonView",
			    tabName: "explorerModelJson"
			}
		}else if(type==="ViewValues"){
			return{
				templatePath: "views/ecoMap/views/designer/ChartDetailsTemplate.html",
		        templateName: "ChartDetailsTemplate",
				viewTypeStr: "appviews/ecoMap/views/designer/ChartDetailsViewModel",
		        tabId: "ChartDetailsView",
		        tabName: "ChartDetails"
			}
		}else if(type === "ImageSelector"){
			return{
				templatePath: "views/ecoMap/views/designer/ImageSelectorTemplate.html",
		        templateName: "ImageSelectorTemplate",
		        viewTypeStr: "appviews/ecoMap/views/designer/ImageSelectorViewModel",
		        tabId: "ImageSelectorView",
		        tabName: "ImageSelector"
			}
		}else if(type && type === "mapValidation"){
            return {
                templatePath : "views/ecoMap/views/designer/MapValidationTemplate.html",
                templateName : "MapValidationTemplate",
                viewTypeStr : "appviews/ecoMap/views/designer/MapValidationViewModel",
                tabId : "MapValidationView",
                tabName: "MapValidation"
            }
		}
	}
	static showDialog(options, dialog) {
		var id = window.utils.htmlEscape(window.guidGenerator());
		if(dialog == "ImageSelector"){
			options.width =  window.innerWidth-window.innerWidth/5+'px'
		} else {
			options.width = '660px';
		}
		/*function getParentMapId(element){
			if(element.parent.type == "vdml:ValueStreamMap"){
				return element.parent.id;
			}else {
				return getParentMapId(element.parent);
			}
		}*/
		var parentView = window.vdmModelView;
		if(options.element && options.element.parent){
		   parentView = window.vdmModelView.DashboardViewModel ? window.vdmModelView.mapInstance[options.element.parent.id] : window.vdmModelView; 
		}
		if(parentView){
			window.getAndCreateModalDialog(parentView, id, EcoMapDiagramMixin, parentView.model, dialog, null, options);
		}
	}
	/*EcoMapDiagramMixin.prototype.handleRemoveParticipant (model, coll, options) {debugger
	    var self = this;
	    var modeler = new EcoModeler({});
	    var parentId = model.getNestedParent().id;
	    modeler.importXML(this.get('data'), function (err) {
	        if (err) {
	            console.error(err);
	        }
	        var flowElements = modeler.definitions.rootElements[0].flowElements;
	        var index;
	        while ((index = _.findIndex(flowElements, {mid:model.id})) >= 0) {
	        	if (options && options.creatingRevision && options.revisionCollectionNewId) {
			        var parentRep = DataManager.getDataManager().getRepositoryId(options.revisionCollectionNewId);
			        var parentSuffix = parentId.substr(parentId.indexOf('@') + 1);
		            flowElements[index].set('mid', options.revisionCollectionNewId);
	            	flowElements[index].set('mpid', parentRep + parentSuffix);				        
		        }else{
		            flowElements[index].set('mid', undefined);
	            	flowElements[index].set('mpid', undefined);		        	
		        }
	        }
	        modeler.saveXML({ format: true }, function (err, xml) {
	            if (xml) {
	                self.set('data', xml);
	            }
	            modeler.destroy();
	        });
	    });
	};*/
	handleRemoveParticipant (model, coll, options) {
	    var self = this;
	    var xml = this.get('data');
	    if (!xml || DataManager.getDataManager().get('suppressLogging'))
	        return;
	    var xmlDoc = jQuery.parseXML(xml);
	    var $xml = jQuery(xmlDoc);
	    var nodes = $xml.find('[mid="' + model.id + '"]')
	    //var re = new RegExp(model.id, 'g');
	    var parentId = model.getNestedParent().id;
	    //var pre = new RegExp(parentId, 'g');
	    if (options && options.creatingRevision && options.revisionCollectionNewId) {
	        var parentRep = DataManager.getDataManager().getRepositoryId(options.revisionCollectionNewId);
	        //var parentSuffix = parentId.substr(parentId.indexOf('@') + 1);
	        var parentSuffix = window.utils.getSuffix(parentId);
	        //xml = xml.replace(re, options.revisionCollectionNewId);
	        //xml = xml.replace(pre, parentRep + parentSuffix);
	        nodes.each(function (callback, node) {
	            node.setAttribute('mid', options.revisionCollectionNewId);
	            node.setAttribute('mpid', parentRep + parentSuffix);
	        })
	    } else {
	        nodes.each(function (callback, node) {
	            node.setAttribute('mid', '');
	            node.setAttribute('mpid', '');
	        });
	    }
	    xml = (new XMLSerializer()).serializeToString(xmlDoc);
	    self.set('data', xml);
	};

	handleRemoveBusinessModel (model, coll, options) {
	    this.handleRemoveParticipant(model, coll, options);
	};
	handleRemoveValueProposition (model, coll, options) {
	    this.handleRemoveParticipant(model, coll, options);
	};
	
	}
	path.EcoMapDiagramMixin = EcoMapDiagramMixin;
	//return EcoMapDiagramMixin;
//});