import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { BM2DetailsViewModel, BMDetails2ViewModel } from './BMDetails2ViewModel';
import {BusinessModelViewModel} from './BusinessModelViewModel'
import * as ko from 'knockout'
import * as _ from 'underscore'
import { ValueType } from '../../../../bo/vdml/ValueType'

var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties", global.version);

export class BusinessModel2ViewModel extends BusinessModelViewModel{

    editValuesHandler(view, event) {
        var self = this;
        var component = view.valueModel;
        var id = self.htmlEscape(component.get('id') + "_valuesTab");
        if (component.get('componentOwner')) {
            var type = component.get('componentOwner').get('isValueFormula') ? "valueFormula" : "valueProposition";
            if ($.find("#modal" + id).length > 0) {
                self.updateView(type, id);
                $("#modal" + id).modal('show');
            }
            else {
                var scenario = component.get('componentOwner') ? self.currentAlternativeModel.getMainScenario(component.get('componentOwner').getNestedParent()) : self.mainScenario();
                var aggregatedColl = self.getAggregatedColl();
                var addOptions = { 'period':self.defaultPeriod, 'year':self.defaultYear, 'type': type, 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'currentAlternativeModel': self.currentAlternativeModel, 'disableDelete': true };
                window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), component, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
            }
        }
        else {
            if ($.find("#modal" + id).length > 0) {
                self.updateView('createWhatValues', id);
                $("#modal" + id).modal('show');
            }
            else {
                var act = component.get('valueAddOwner').get('containedPortOwner');
                var role = act.get('performingRole');
                var scenario = self.currentAlternativeModel.getMainScenario(component.getNestedParent());
                var activities = [{ 'id': act.get('id'), 'name': act.get('name'), 'modalId': self.htmlEscape(act.get('id')) }];
                var aggregatedColl = self.getAggregatedColl();
                var addOptions = { 'period':self.defaultPeriod, 'year':self.defaultYear, 'type': 'createWhatValues', 'activityProviderRole': role, 'activities': activities, 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'currentAlternativeModel': self.currentAlternativeModel, 'disableDelete': true };
                window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), component, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
            }
        }
    }
    
    editValueFormulaValuesClickHandler(view, event) {
        var self = this;
        var id = view.modalId;
        var modalEle = self.selectedValueFormula() ? self.selectedValueFormula().get('component').findWhere({ 'id': view.id }) : null;
        var tempValuesAgg = self.addTempValuesForAggregation(self.vfName(), 'valueFormula', 'My Proposition');
        if ($.find("#modal" + id).length > 0) {
            self.updateView('valueFormula', id, tempValuesAgg);
            $("#modal" + id).modal('show');
        }
        else {
            var scenario = self.selectedValueFormula() ? self.currentAlternativeModel.getMainScenario(self.selectedValueFormula().getNestedParent()) : self.mainScenario();
            var collaboration = self.model.get('bmRole').get(self.selectedBusinessRole()).getParent();
            var aggregatedColl = self.getAggregatedColl();
            var addOptions = { 'period':self.defaultPeriod, 'year':self.defaultYear, 'type': 'valueFormula', 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'valuesColl': self.valueFormulaValuesColl(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), modalEle, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
        }
    };

    addValueFormulaClick() {
        var self = this;
        if (self.selectedBusinessRole()) {
            var id = self.htmlEscape(window.guidGenerator()) + "valueFormula";
            var tempValuesAgg = self.addTempValuesForAggregation(self.vfName(), 'valueFormula', 'My Proposition');
            var scenario = self.selectedValueFormula() ? self.currentAlternativeModel.getMainScenario(self.selectedValueFormula().getNestedParent()) : self.mainScenario();
            var collaboration = self.model.get('bmRole').get(self.selectedBusinessRole()).getParent();
            var aggregatedColl = self.getAggregatedColl();
            var addOptions = { 'period':self.defaultPeriod, 'year':self.defaultYear, 'type': 'valueFormula', 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'valuesColl': self.valueFormulaValuesColl(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
        }
    };

    editValuePropositionValuesClickHandler(view, event) {
        var self = this;
        var id = view.modalId;
        var modalEle = self.selectedValueProposition() ? self.selectedValueProposition().get('component').findWhere({ 'id': view.id }) : null;
        var tempValuesAgg = self.addTempValuesForAggregation(self.vpName(), 'valueProposition', 'Value Proposition');
        if ($.find("#modal" + id).length > 0) {
            self.updateView('valueProposition', id, tempValuesAgg);
            $("#modal" + id).modal('show');
        }
        else {
            var scenario = self.selectedValueProposition() ? self.currentAlternativeModel.getMainScenario(self.selectedValueProposition().getNestedParent()) : self.mainScenario();
            var collaboration = self.model.get('bmRole').get(self.selectedProviderRole()).getParent();
            var aggregatedColl = self.getAggregatedColl();
            var addOptions = { 'period':self.defaultPeriod, 'year':self.defaultYear, 'type': 'valueProposition', 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'valuesColl': self.valuePropositionValuesColl(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), modalEle, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
        }
    };

    addValueFormulaClick() {
        var self = this;
        if (self.selectedBusinessRole()) {
            var id = self.htmlEscape(window.guidGenerator()) + "valueFormula";
            var tempValuesAgg = self.addTempValuesForAggregation(self.vfName(), 'valueFormula', 'My Proposition');
            var scenario = self.selectedValueFormula() ? self.currentAlternativeModel.getMainScenario(self.selectedValueFormula().getNestedParent()) : self.mainScenario();
            var collaboration = self.model.get('bmRole').get(self.selectedBusinessRole()).getParent();
            var aggregatedColl = self.getAggregatedColl();
            var addOptions = {period:self.defaultPeriod, year:self.defaultYear, 'type': 'valueFormula', 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'valuesColl': self.valueFormulaValuesColl(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
        }
    };

    addValuePropositionClick() {
        var self = this;
        if (self.selectedProviderRole()) {
            var id = self.htmlEscape(window.guidGenerator()) + "valueProposition";
            var scenario = self.selectedValueProposition() ? self.currentAlternativeModel.getMainScenario(self.selectedValueProposition().getNestedParent()) : self.mainScenario();
            var tempValuesAgg = self.addTempValuesForAggregation(self.vpName(), 'valueProposition', 'Value Proposition');
            var collaboration = self.model.get('bmRole').get(self.selectedProviderRole()).getParent();
            var aggregatedColl = self.getAggregatedColl();
            var addOptions = {period:self.defaultPeriod, year:self.defaultYear, 'type': 'valueProposition', 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'valuesColl': self.valuePropositionValuesColl(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
        }
    };

    contributingWhatValuesActivitiesClickHandler() {
        var self = this;
        if (self.selectedPersued()) {
            var id = self.htmlEscape(window.guidGenerator()) + "contributingActivity";
            var vp = self.fetchValueProposition(self.persuedOptions(), self.selectedPersued());
            var vpValues = self.filterActivityAggregatedToValuesList(vp, self.activityContributeWhatValuesColl());
            var tempValuesAgg = self.addTempValuesForAggregation(null, 'createWhatValues', 'Activity', true);
            //DataManager.getDataManager().get('currentPlan').getValuesThatCanBeRefferedTo(function(values){
            //	var valuesColl = new Backbone.Collection();
            //	valuesColl.add(values);
            var aggregatedColl = self.getAggregatedColl();
            var addOptions = {period:self.defaultPeriod, year:self.defaultYear, 'type': 'contributeWhatValues', 'activityValueProposition': vp, 'activitiesValues': self.activityCreateWhatValuesColl(), 'vpValue': vpValues, 'aggregatedColl': aggregatedColl, 'mainScenario': self.mainScenario(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg };
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
            //});
        }
    };

    createWhatValuesActivitiesClickHandler() {
        var self = this;
        var openWhatValuesDialog = false;
        for (var i = 0; i < self.activityCollection().length; i++) {
            if (!self.activityCollection()[i]._destroy) {
                openWhatValuesDialog = true;
                break;
            }
        }
        if (openWhatValuesDialog) {
            var id = self.htmlEscape(window.guidGenerator()) + "activity";
            var role = self.model.get('bmRole').findWhere({ id: self.selectedActivityProviderRole() });
            var tempValuesAgg = self.addTempValuesForAggregation(null, 'createWhatValues', 'Activity');
            var aggregatedColl = self.getAggregatedColl();
            var addOptions = { period:self.defaultPeriod, year:self.defaultYear, 'type': 'createWhatValues', 'activityProviderRole': role, 'activities': self.activityCollection(), 'aggregatedColl': aggregatedColl, 'mainScenario': self.mainScenario(), 'valuesColl': self.activityCreateWhatValuesColl(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg };
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
        }
    };

    editActivityCreateValuesClickHandler(view, event) {
        var self = this;
        var id = view.modalId;
        var tempValuesAgg = self.addTempValuesForAggregation(null, 'createWhatValues', 'Activity');
        if ($.find("#modal" + id).length > 0) {
            self.updateView('createWhatValues', id, tempValuesAgg);
            $("#modal" + id).modal('show');
        }
        else {
            var modalEle = null;
            var activityModal = self.fetchActivityModal(view.activityId);
            var ports = activityModal.get('containedPort');
            for (var i = 0; i < ports.length; i++) {
                if (ports.at(i).get('type') === "vdml_OutputPort") {
                    modalEle = ports.at(i).get('valueAdd').findWhere({ id: view.originalId });
                    if (modalEle) {
                        break;
                    }
                }
            }
            var role = self.model.get('bmRole').findWhere({ id: self.selectedActivityProviderRole() });
            var scenario = self.currentAlternativeModel.getMainScenario(modalEle.getNestedParent());
            var collaboration = activityModal.getNestedParent();
            var aggregatedColl = self.getAggregatedColl();
            var addOptions = { 'period':self.defaultPeriod, 'year':self.defaultYear, 'type': 'createWhatValues', 'activityProviderRole': role, 'activities': self.activityCollection(), 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'valuesColl': self.activityCreateWhatValuesColl(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), modalEle, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
        }
    };

	loadBusinessDetailComponent(){
        var self = this
        self.bmDetails2ViewInstance = BMDetails2ViewModel.getInstance(this);
        if(!ko.components.isRegistered('BMDetailsWidget')){
            ko.components.register("BMDetailsWidget", {
                viewModel: {instance:self.bmDetails2ViewInstance},
                template: window.utils.getHtmlPage("BMDetails2Template"),
            });
		}
	}

    getMeasurementValueandUnit(component,period) {
        var self = this;
        var val = {};
        //var scenario = self.currentAlternativeModel.getDefaultScenario(value.getNestedParent());
        var valueElementContexts = component.get("context").models;
        var valueContext = null;
        for(var i = 0;i<valueElementContexts.length;i++){
            var valueContextObj = valueElementContexts[i];
            if(valueContextObj.get("alternative") == self.currentAlternativeModel ){
                valueContext = valueContextObj
                break;
            }
        }
        var definition = component.get("definition");
        var unit = component.get("unit");
        if (self.periodDataset != null) {
            if(definition != null && !unit){
                unit = definition.get("unit");
            }    
            val['unit'] = unit ? unit.get('name') : "";
            var significantDecimals = unit ? unit.get('significantDecimals') : 2;
            if (parseFloat(significantDecimals) < 0 || parseFloat(significantDecimals) > 20) {
                significantDecimals = 2;
            }
            var value;
            if(self.periodDataset){
                //value = periodDataset.get("data")[value.id];
                value = self.periodDataset.get(component.id);
            }
            if(component.get("valueType") != ValueType.Atomic){
                var isCollective = true;
                value = self.calcPeriodDataset?self.calcPeriodDataset.get(component.id):null;
            }

            /*if (!definition || definition.get("valueType") == ValueType.Literal) {
                val['value'] = value ? value: null;
            } else {*/
                if(value != null){
                    val['value'] = window.utils.getRoundedValues(value, significantDecimals);
                }
                //val['measurement'] = valueContext;
            //}
            if (val['value'] == "") {
                val['unit'] = "";
            }
            if(valueContext && valueContext.get('formula')){
                val['formula'] = valueContext.get('formula').get('expressionStr');
            }
            
            
            if (!val['formula']) {//back-end compatability
                val['formula'] = definition ? definition.get('formula'): null;
            }
        } else {
            val['unit'] = "";
            val['value'] = "";
            val['formula'] = "";
            if (component.get('aggregatedFrom').length > 0) {
                val['isCollective'] = true;
            } else {
                val['isCollective'] = false;
            }
        }
        return val;
    }

    showScenarioExplorer() {
		var self = this;
		var addOptions = this;
		var Plan2Mixin = Backbone.Relational.store.getObjectByName("transformation.Plan2Mixin");
		var defaultScenario = DataManager.getDataManager().get('defaultScenario');
		window.getAndCreateModalDialog(true, self.encodeId, Plan2Mixin, defaultScenario, "explorerModelJson", null, addOptions);
	};

	showDatasetExplorer() {
		var self = this;
		var addOptions = this;
		var Plan2Mixin = Backbone.Relational.store.getObjectByName("transformation.Plan2Mixin");
		var defaultScenario = DataManager.getDataManager().get('defaultScenario');
		var defaultExecutionScenaroId = defaultScenario.get("defaultExecutionScenario");
        var defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
		window.getAndCreateModalDialog(true, self.encodeId, Plan2Mixin, defaultExecutionScenaro, "explorerModelJson", null, addOptions);
	};

    handlePublicMenus() {
		var self = this;
        var dataManager = DataManager.getDataManager();
		dataManager.addContextBarMenu("glyphicon glyphicon-wrench", "Show Object Explorer", $.proxy(this.showobjectExplorerTree, this));
        dataManager.addContextBarMenu("glyphicon glyphicon-print", "Print", $.proxy(this.printReport, this));
		dataManager.addContextBarMenu("", "Default Scenario", $.proxy(this.showScenarioExplorer, this), "Default Scenario");
		dataManager.addContextBarMenu("", "Scenario Execution", $.proxy(this.showDatasetExplorer, this), "Scenario Execution");
		dataManager.addJuliaMenuItem();
        dataManager.addContextMenu("", "Calculate ", function(){
            window.utils.startSpinner('calculate', "Calculating...");
            dataManager.calculateJuliaModel(function(){
                window.utils.stopSpinner('calculate');
                self.updateYearAndPeriod(self.defaultYear,self.defaultPeriod);
            });
        }, $('#navbarlist'));	
	};

    updateYearAndPeriod(year,period){
        var self = this;
        self.defaultPeriod = period;
        self.defaultYear = year;
        self.loadDatasets(period,year);
        window.utils.startSpinner('loadingValues', "loading Values...");
        window.setTimeout(function () {
            self.resetTables(function () {
                window.utils.stopSpinner('loadingValues');
            });
        },100);
    }

    init(model, options){
        var self = this;
        var dataManager = DataManager.getDataManager();
        this.selectedYear = ko.observable();
        this.selectedPeriod = ko.observable();
        var currentPlan = dataManager.get('currentPlan');
        this.periodKind = currentPlan.get("periodKind");
        var currentAlternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: dataManager.get('viewAlternative')})
        var scenarioId = currentPlan.get("defaultScenario");
        var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
        this.dataSets = currentAlternative.getPhaseAlternativeSteps(scenario);
        var defaultPeriodList = scenario.fillDefaultPeriods(this.dataSets,self.periodKind);
        self.defaultPeriod = defaultPeriodList?.period
        self.defaultYear = defaultPeriodList?.year;

        var defaultExecutionScenaroId = scenario.get("defaultExecutionScenario");
        self.defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
        self.loadDatasets(self.defaultPeriod, self.defaultYear);
        super.init(model,options);
    }

    loadDatasets(period,year){
        var self = this;
        self.periodDataset = self.defaultExecutionScenario.get("input").findWhere({period: period, periodKind : self.periodKind, year:year});
        self.calcPeriodDataset = self.defaultExecutionScenario.get("result").findWhere({period: period, periodKind : self.periodKind, year:year});
    }

    static getInstance(model, options) {
        var view = new BusinessModel2ViewModel(model, options);
        return view;
    };
}



path.BusinessModel2ViewModel = BusinessModel2ViewModel;