import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {CapabilityDependencyMixin} from './CapabilityDependencyMixin'
import {CapabilityDefinition} from './CapabilityDefinition'
import {BusinessItemDefinition} from './BusinessItemDefinition'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/CapabilityDependencyMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,CapabilityDependencyMixin
, BusinessItemDefinition
, CapabilityDefinition
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!BusinessItemDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemDefinition){
			BusinessItemDefinition = importPath.BusinessItemDefinition;
		}
		else{
			import('./BusinessItemDefinition').then(loadedModule => {
			//	BusinessItemDefinition = loadedModule;
			});
			/*require(["appbo/vdml/BusinessItemDefinition"],function(loadedModule){
				BusinessItemDefinition = loadedModule;
			});*/
		}
	}
	if(!CapabilityDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityDefinition){
			CapabilityDefinition = importPath.CapabilityDefinition;
		}
		else{
			import('./CapabilityDefinition').then(loadedModule => {
			//	CapabilityDefinition = loadedModule;
			});
			/*require(["appbo/vdml/CapabilityDefinition"],function(loadedModule){
				CapabilityDefinition = loadedModule;
			});*/
		}
	}
	var CapabilityDependency = VdmlElement.extend(utils.customExtends({
		relations:CapabilityDependencyMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new CapabilityDependencyMixin()
	));
	
	CapabilityDependency.abstract = false;
	CapabilityDependency.supported = true;
	path.CapabilityDependency = CapabilityDependency;
	export {CapabilityDependency};
//});