import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {PortDelegation} from './PortDelegation'
import {PortDelegationMixin} from './PortDelegationMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { InputPort } from './InputPort'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/PortDelegation","appbo/vdml/PortDelegationMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,PortDelegation,PortDelegationMixin
, InputPort
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!InputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.InputPort){
			InputPort = importPath.InputPort;
		}
        else {
            import('./InputPort').then(({ default: InputPort }) => {
                InputPort = InputPort;
            });
            /*require(["appbo/vdml/InputPort"], function (loadedModule) {
                InputPort = loadedModule;
			});*/
		}
	}
	if(!InputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.InputPort){
			InputPort = importPath.InputPort;
		}
        else {
            import('./InputPort').then(({ default: InputPort }) => {
                InputPort = InputPort;
            });
            /*require(["appbo/vdml/InputPort"], function (loadedModule) {
                InputPort = loadedModule;
			});*/
		}
	}
	export class InputDelegationMixin {

	defaults(){
		var ret = {
			type: "vdml_InputDelegation"
		}
		return jQuery.extend(PortDelegation.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!InputDelegationMixin.cummulativeRelations) {
            InputDelegationMixin.cummulativeRelations = _.union(InputDelegationMixin.getMixinRelations()
                , PortDelegationMixin.getCumulativeMixinRelations()
            );
        }
		return InputDelegationMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_PortDelegation"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		return PortDelegation.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return PortDelegation.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/InputDelegationPropertiesTemplate.html",
			templateName : "InputDelegationPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/InputDelegationViewModel",
			tabId : "InputDelegationView",
			tabName: "InputDelegation"
		}
	};
//#startCustomMethods
	copyPortDelegation(copyContext,sourcePort,targetPort){
		var copy;
		copyContext.get('contextBasedPortDelegation').each(function(portDel){
			if(portDel.get('source') === sourcePort && portDel.get('target') === targetPort){
				copy = portDel;
			}
		});
		if(!copy){
			var copyId = DataManager.getDataManager().guidGeneratorByOwner(copyContext);
			copy = new this.constructor({id:copyId,contextBasedPortDelegationOwner:copyContext,source:sourcePort,target:targetPort,name:copyContext.getUniqueProperty('name',sourcePort.get('name') + "Delegation",'contextBasedPortDelegation'),description:sourcePort.get('description')});
		}
		return copy;
	};
//#endCustomMethods		
	
	}
	path.InputDelegationMixin = InputDelegationMixin;
	//return InputDelegationMixin;
//});