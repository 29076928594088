import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VDMLDiagramMixin} from './VDMLDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VDMLDiagramMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VDMLDiagramMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var VDMLDiagram = VdmlElement.extend(utils.customExtends({
		relations:VDMLDiagramMixin.getMixinRelations(),
		subModelTypes: {
			vdml_CollaborationDiagram : 'vdml.CollaborationDiagram',
			vdml_BusinessModelDiagram : 'vdml.BusinessModelDiagram'
		},
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new VDMLDiagramMixin()
	));
	
	VDMLDiagram.abstract = false;
	VDMLDiagram.supported = true;
	path.VDMLDiagram = VDMLDiagram;
	export {VDMLDiagram};
//});