import * as _ from 'underscore'

import {VdmlElement} from './VdmlElement'
import {UnitMixin} from './UnitMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
var Unit = VdmlElement.extend(utils.customExtends({
	relations:UnitMixin.getMixinRelations(),
	initialize: function(attributes, options) {
		VdmlElement.prototype.initialize.apply(this, arguments);
	}
	}
	, new UnitMixin()
));
Unit.getInstance = function(unitName,unitDesc,roundingDigit,significantDecimal,owner){
	var unitId = DataManager.getDataManager().guidGeneratorByOwner(owner);
	var unit = new Unit({id:unitId,name:unitName,description:unitDesc,roundingDigits:roundingDigit,significantDecimals:significantDecimal,unitOwner:owner});
	return unit
}
Unit.abstract = false;
path.Unit = Unit;
export {Unit};
