import { DataManager } from '../../../com/vbee/data/DataManager'
import {Enum} from '../../../../libs/enums/enums'

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
if(!path.PeriodKind){
    var PeriodKind = new Enum("Yearly", "Quarterly", "Monthly", "4-Weekly", "Weekly");
    path.PeriodKind = PeriodKind;
}
export {PeriodKind};

const maxPeriods = {
    maxPeriodsForYearly : 50,
    maxPeriodsForQuarterly : 200,
    maxPeriodsForMonthly : 600,
    maxPeriodsFor4Weekly : 650,
    maxPeriodsForWeekly : 2600,
}
export {maxPeriods};
