import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { PackageReference as PlanPackageReference } from './PackageReference'
import { PhaseMixin } from './PhaseMixin'
import { ScenarioPathStep } from './ScenarioPathStep'
import { PeriodKind } from '../vdml/PeriodKind'
import { daysPer4Weekly, daysPerWeekly } from '../../../../utils'
import { PeriodDataset } from './PeriodDataset'
import { addQuarters , addMonths, add, addDays, format, lastDayOfQuarter, lastDayOfMonth, lastDayOfYear} from 'date-fns'


var path = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
export class Phase2Mixin {
    getMixinClass() {
        return Phase2Mixin;
    };

    static getDialogViewProperties(type) {
        if (type == "PhaseDetails") {
            return {
                templatePath: "views/transformation/views/properties/PhaseDetailsTemplate.html",
                templateName: "PhaseDetailsTemplate",
                viewTypeStr: "appviews/transformation/views/properties/PhaseDetails2ViewModel",
                tabId: "PhaseDetails2View",
                tabName: "Phase"
            }
        } else {
            return PhaseMixin.getDialogViewProperties(type);
        }
    }

   static calcPhaseStartDate(periodKind, planStartDate, totalPeriods) {
        // totalPeriods is the sum of periods of all phase which are before the required phase
        let startDate = null;
        if (periodKind === PeriodKind.symbols()[0].name) {
            startDate = add(planStartDate, {
                years: totalPeriods,
            });
        } else if (periodKind === PeriodKind.symbols()[1].name) {
            startDate = addQuarters(planStartDate, totalPeriods);
        } else if (periodKind === PeriodKind.symbols()[2].name) {
            startDate = addMonths(planStartDate, totalPeriods);
        } else if (periodKind === PeriodKind.symbols()[3].name) {
            startDate = addDays(planStartDate, ((totalPeriods) * daysPer4Weekly));
        } else if (periodKind === PeriodKind.symbols()[4].name) {
            startDate = addDays(planStartDate, ((totalPeriods) * daysPerWeekly));
        }
        return format(startDate, 'MM/dd/yyyy');
    }
    
    static calcPhaseEndDate(periodKind, startDate, noOfPeriods) {
        let endDate = null;
        if (periodKind === PeriodKind.symbols()[0].name) {
            endDate = lastDayOfYear(add(startDate, {
                years: noOfPeriods - 1,
            }));
        } else if (periodKind === PeriodKind.symbols()[1].name) {
            endDate = lastDayOfQuarter(addQuarters(startDate, noOfPeriods - 1));
        } else if (periodKind === PeriodKind.symbols()[2].name) {
            endDate = lastDayOfMonth(addMonths(startDate, noOfPeriods - 1));
        } else if (periodKind === PeriodKind.symbols()[3].name) {
            endDate = addDays(startDate, ((noOfPeriods) * daysPer4Weekly) - 1);
        } else if (periodKind === PeriodKind.symbols()[4].name) {
            endDate = addDays(startDate, ((noOfPeriods) * daysPerWeekly - 1));
        }
        return format(endDate, 'MM/dd/yyyy');
    }

    getUsePreviousIds(phase){
		var usePreviousId = [];
        return usePreviousId;
    }

    updateStepAlt(altId){
        var self = this;
        const phaseId = self.get('id');
        const plan = self.get('phaseOwner');
        const scenarioId = plan.get("defaultScenario");
        const planScenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
        const stepData = planScenario.get('step').models.find((obj) => obj.get('phaseId') === phaseId);
        stepData.set('alternativeId', altId);
    }

    handlePrimaryChange(currentAltenative,newPrimary,callback){
		var self = this;
        const altId = newPrimary.id;
		self.updatePrimaryReferences(currentAltenative,newPrimary,function(altId){
            self.updateStepAlt(altId);
            callback();
        });
	};

    copyPlanScenario(currentAltModel,measurementsCopied,callback){
		callback();
	};

    deletePhaseAndStep(callback) {
        var self = this;
        const phaseId = self.get('id');
        const plan = self.get('phaseOwner');
        const scenarioId = plan.get("defaultScenario");
        const planScenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
        const sortedDataset = planScenario.getSortedDataSets(plan);
        const stepData = planScenario.get('step').models.find((obj) => obj.get('phaseId') === phaseId);
        const executionScenarioId = planScenario.get("defaultExecutionScenario");
        const executionScenario = Backbone.Relational.store.getObjectByName('transformation.ScenarioExecution').find({ id: executionScenarioId });
        const phaseNoOfPeriods = parseInt(stepData.get('noPeriods'));
        stepData.destroy();
        for (let index = 0; index < phaseNoOfPeriods; index++) {
            let inputDataset = executionScenario.get("input").findWhere(sortedDataset[sortedDataset.length - index - 1]);
            inputDataset.destroy();
        }
        callback();
    };

    createTicketForUsePrevious(callback) {
        callback();
    }

    setPrevNextIntermediateLinks(prevPhaseModel, createPreviousPhase, isolation, callback) {
        var self = this;
        if (createPreviousPhase) {
            var oldpreviousPhase = prevPhaseModel.get('previousPhase');
            self.set('previousPhase', oldpreviousPhase);
            self.set('nextPhase', prevPhaseModel);
            prevPhaseModel.set('previousPhase', self);
            var ownedPackages = [];
            var packRef = prevPhaseModel.get('primary').get('phaseDesignPart');
            for (var i = 0; i < packRef.length; i++) {
                var pacRef = packRef.at(i);
                var pprId = DataManager.getDataManager().guidGeneratorByOwner(self);
                var beepRef = pacRef.get('beepReference');
                if (beepRef.indexOf('Common') == -1) {
                    var suffixId = pacRef.get('beepReference').substr(pacRef.get('beepReference').lastIndexOf('@') + 1);
                    beepRef = self.get('primary').id + suffixId;
                    var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: DataManager.getDataManager().getRepositoryId(pacRef.get('beepReference')) });
                    ownedPackages.push({ 'copyAlt': alt, 'alt': self.get('primary'), 'pacRef': pacRef });
                }
                if (beepRef.indexOf('Common@-CommonPackage') == -1) {
                    var newPacRef = new PlanPackageReference({ id: pprId, beepReference: beepRef, name: pacRef.get('name'), description: pacRef.get('description'), version: pacRef.get('version'), label: pacRef.get('label'), isMain: pacRef.get('isMain'), phaseDesignPartOwner: self.get('primary') });
                    newPacRef.set('beepType', pacRef.get('beepType'));
                    if (pacRef.get('beepType') === "vdml_ValueDeliveryModel" && pacRef.get('beepReference').indexOf('Common') == -1) {
                        newPacRef.set('isMain', true);
                    } else {
                        newPacRef.set('isMain', false);
                    }
                    self.get('primary').get('phaseDesignPart').add(newPacRef);
                }
            }

            self.createOwnedPackages(ownedPackages, prevPhaseModel.get('primary'), self.get('master'), function (ownedPackageList, copiedPackages) {
                callback();
            }, true);
        } else {
            var oldNext = prevPhaseModel.get('nextPhase');
            if (oldNext) {
                prevPhaseModel.set('nextPhase', self);
                self.set('previousPhase', prevPhaseModel);
                self.set('nextPhase', oldNext);
                oldNext.set('previousPhase', self);
                self.createReferencesForIntermediatePhase(prevPhaseModel, function (copiedPackages) {
                    if (isolation) {
                        self.createIsolation(prevPhaseModel.get('primary'), callback);
                    } else {
                        callback();
                    }
                });
            } else {
                prevPhaseModel.set('nextPhase', self);
                self.set('previousPhase', prevPhaseModel);
                self.createReferencesForNewPhase(prevPhaseModel, function (measurementsCopied) {
                    if (isolation) {
                        self.createIsolation(prevPhaseModel.get('primary'), callback);
                    } else {
                        callback();
                    }
                });
            }
        }
    };

    copypValues(measurementsCopied, vdmlPackages, callback) {
        callback(null);
    }

    createIntermediatePhaseStep(selectedPhaseModel, creatingPreviousPhase) {
        var self = this;
        const newPhaseId = self.get('id');
        const alt = self.get("primary") ? self.get("primary") : self.get("phaseAlternative").models[0];
        const plan = self.get('phaseOwner');
        const scenarioId = plan.get("defaultScenario");
        const planScenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
        const executionScenarioId = planScenario.get("defaultExecutionScenario");
        const executionScenario = Backbone.Relational.store.getObjectByName('transformation.ScenarioExecution').find({ id: executionScenarioId });
        const stepsData = planScenario.get('step').models;
        const selectedPhaseModelId = selectedPhaseModel.get('id');
        const selectedPhaseModelStep = stepsData.find((obj) => obj.get('phaseId') === selectedPhaseModelId);
        if (creatingPreviousPhase) {
            const oldPreviosStep = selectedPhaseModelStep.get('previous');
            const newPhaseStep = new ScenarioPathStep({ id: window.guidGenerator(), stepOwner: planScenario, phaseId: newPhaseId, alternativeId: alt.id, startPeriod: 0, noPeriods: 0, previous: oldPreviosStep, next: selectedPhaseModelStep });
            planScenario.get("step").add(newPhaseStep);
            selectedPhaseModelStep.set("previous", newPhaseStep);
            if (oldPreviosStep) {
                oldPreviosStep.set("next", newPhaseStep);
            } else {
                planScenario.set("firstStep", newPhaseStep);
            }
        } else {
            let noOfPeriods = 0;
            let startPeriod = 0;
            const oldNextStep = selectedPhaseModelStep.get('next');
            if(oldNextStep == null){
                noOfPeriods = 1;
                const totalNoOfPeriods = stepsData.reduce((accumulator, currentObject) => {
                    return accumulator + currentObject.get('noPeriods');
                }, 0);
                startPeriod = 1 + totalNoOfPeriods;
                plan.createDefaultDataSets(planScenario.get('startTime'), startPeriod, noOfPeriods, plan.get('periodKind'), executionScenario);
            }
            const newPhaseStep = new ScenarioPathStep({ id: window.guidGenerator(), stepOwner: planScenario, phaseId: newPhaseId, alternativeId: alt.id, startPeriod: startPeriod, noPeriods: noOfPeriods, previous: selectedPhaseModelStep, next: oldNextStep });
            planScenario.get("step").add(newPhaseStep);
            selectedPhaseModelStep.set("next", newPhaseStep);
            if (oldNextStep) {
                oldNextStep.set("previous", newPhaseStep);
            }
        }
    }

}

path.Phase2Mixin = Phase2Mixin;
utils.customExtendClass(Phase2Mixin, new PhaseMixin());

