import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurementRelationship} from './MeasurementRelationship'
import {EquivalentMeasurementRelationshipMixin} from './EquivalentMeasurementRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { Measurement } from './Measurement'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasurementRelationship","appbo/smm/EquivalentMeasurementRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurementRelationship,EquivalentMeasurementRelationshipMixin
, Measurement
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Measurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measurement){
			Measurement = importPath.Measurement;
		}
        else {
            import('./Measurement').then(({ default: Measurement }) => {
                Measurement = Measurement;
            });
			/*require(["appbo/smm/Measurement"],function(loadedModule){
				Measurement = loadedModule;
			});*/
		}
	}
	
	var EquivalentMeasurementRelationship = MeasurementRelationship.extend(utils.customExtends({
		relations:EquivalentMeasurementRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			MeasurementRelationship.prototype.initialize.apply(this, arguments);
		}
		}
		, new EquivalentMeasurementRelationshipMixin()
	));
	
	EquivalentMeasurementRelationship.abstract = false;
	EquivalentMeasurementRelationship.supported = false;
	path.EquivalentMeasurementRelationship = EquivalentMeasurementRelationship;
	export {EquivalentMeasurementRelationship};
//});