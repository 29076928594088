import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {EObject} from '../cmof/EObject'
import {VdmlElementMixin} from './VdmlElementMixin'
import {Attribute} from './Attribute'
import {Annotation} from './Annotation'
import {ObjectReference} from './ObjectReference'
import {DataManager} from '../../../com/vbee/data/DataManager'

import { VdmlElement2Mixin } from './VdmlElement2Mixin'

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
if(!Attribute){
	var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(importPath.Attribute){
		Attribute = importPath.Attribute;
	}
	else{
		import('./Attribute').then(loadedModule => {
			Attribute = loadedModule;
		});
		/*require(["appbo/vdml/Attribute"],function(loadedModule){
			Attribute = loadedModule;
		});*/
	}
}
if(!Annotation){
	var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(importPath.Annotation){
		Annotation = importPath.Annotation;
	}
	else{
		import('./Annotation').then(loadedModule => {
			Annotation = loadedModule;
		});
		/*require(["appbo/vdml/Annotation"],function(loadedModule){
			Annotation = loadedModule;
		});*/
	}
}
if(!ObjectReference){
	var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(importPath.ObjectReference){
		ObjectReference = importPath.ObjectReference;
	}
	else{
		import('./ObjectReference').then(loadedModule => {
			ObjectReference = loadedModule;
		});
		/*require(["appbo/vdml/ObjectReference"],function(loadedModule){
			ObjectReference = loadedModule;
		});*/
	}
}
var getMixinClass = function (){
	var toggleRouter = DataManager.getDataManager().get("toggleRouter");
	var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
	return sp2Enabled == true ? VdmlElement2Mixin : VdmlElementMixin;
}
var vdmlMixinCls = getMixinClass();
var VdmlElement = EObject.extend(utils.customExtends({
	relations:vdmlMixinCls.getMixinRelations(),
	subModelTypes: vdmlMixinCls.getSubModelTypes(),
	initialize: function(attributes, options) {
		EObject.prototype.initialize.apply(this, arguments);
	}
	}
	, new vdmlMixinCls()
));

VdmlElement.abstract = true;
path.VdmlElement = VdmlElement;
utils.customExtendStaticMethods(VdmlElement,vdmlMixinCls);
export {VdmlElement};
