import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { VdmlElement } from './VdmlElement'
import { VdmlElementMixin } from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { PracticeDefinition } from './PracticeDefinition'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, PracticeDefinition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!PracticeDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PracticeDefinition){
			PracticeDefinition = importPath.PracticeDefinition;
		}
        else {
            import('./PracticeDefinition').then(loadedModule => {
                //PracticeDefinition = loadedModule;
            });
			/*require(["appbo/vdml/PracticeDefinition"],function(loadedModule){
				PracticeDefinition = loadedModule;
			});*/
		}
	}
export class PracticeCategoryMixin {
    defaults() {
        var ret = {
            type: "vdml_PracticeCategory"
        }
        return jQuery.extend(VdmlElement.prototype.defaults.apply(this), ret);
    }
    static getMixinRelations() {
        return _.union([
            {
                type: Backbone.HasMany,
                containingClass: "vdml_PracticeCategory",
                key: "categoryPractice",
                relatedModel: "vdml.PracticeDefinition",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            },
            {
                type: Backbone.HasMany,
                containingClass: "vdml_PracticeCategory",
                key: "parentCategory",
                relatedModel: "vdml.PracticeCategory",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            },
            {
                type: Backbone.HasMany,
                containingClass: "vdml_PracticeCategory",
                key: "childCategory",
                relatedModel: "vdml.PracticeCategory",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            }
        ])
    }
    
    static getCumulativeMixinRelations() {
        if (!PracticeCategoryMixin.cummulativeRelations) {
            PracticeCategoryMixin.cummulativeRelations = _.union(PracticeCategoryMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
        return PracticeCategoryMixin.cummulativeRelations.slice();
    }
    static getSuperTypes() {
        return [
            "vdml_VdmlElement"
        ];
    }
    static getProperties() {
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "represents", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" }
        ]
    }
    getParent() {
        var container;
        if (!container) {
            container = this.get("practiceCategoryOwner") ? this.get("practiceCategoryOwner") : this.previousAttributes().practiceCategoryOwner;
            if (container) {
                return container;
            }
        }
        return this;
    }
    getPackagePath(path) {
        if (!path) {
            path = [];
        }
        var container;
        if (!container) {
            container = this.get("practiceCategoryOwner") ? this.get("practiceCategoryOwner") : this.previousAttributes().practiceCategoryOwner;
            if (container) {
                path.unshift({ id: this.get('id'), name: this.get('name') })
                return container.getPackagePath(path);
            }
        }
        return path;
    }
    getViewProperties(type) {
        return {
            templatePath: "views/vdml/views/properties/PracticeCategoryPropertiesTemplate.html",
            templateName: "PracticeCategoryPropertiesTemplate",
            viewTypeStr: "appviews/vdml/views/properties/PracticeCategoryViewModel",
            tabId: "PracticeCategoryView",
            tabName: "PracticeCategory"
        }
    }
    
}
path.PracticeCategoryMixin = PracticeCategoryMixin;
	//return PracticeCategoryMixin;
//});