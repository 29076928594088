import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmElement} from './SmmElement'
import {SmmElementMixin} from './SmmElementMixin'
import { Measure } from './Measure'
//import {BaseNMeasurementRelationship} from './BaseNMeasurementRelationship'
import {GradeMeasurement} from './GradeMeasurement'
import {RankingMeasurement} from './RankingMeasurement'
import {CollectiveMeasurement} from './CollectiveMeasurement'
import {DirectMeasurement} from './DirectMeasurement'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Observation } from './Observation'
import { Measurement } from './Measurement'


/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/SmmElementMixin","appbo/smm/BaseNMeasurementRelationship","appbo/smm/GradeMeasurement","appbo/smm/RankingMeasurement","appbo/smm/CollectiveMeasurement","appbo/smm/DirectMeasurement"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,SmmElementMixin,BaseNMeasurementRelationship,GradeMeasurement,RankingMeasurement,CollectiveMeasurement,DirectMeasurement
, Observation
, Measurement
, Measure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Observation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Observation){
			Observation = importPath.Observation;
		}
        else {
            import('./Observation').then(({ default: Observation }) => {
                Observation = Observation;
            });
           /* require(["appbo/smm/Observation"], function (loadedModule) {
                Observation = loadedModule;
			});*/
		}
	}
	if(!Measurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measurement){
			Measurement = importPath.Measurement;
		}
        else {
            import('./Measurement').then(({ default: Measurement }) => {
                Measurement = Measurement;
            });
            /*require(["appbo/smm/Measurement"], function (loadedModule) {
                Measurement = loadedModule;
			});*/
		}
	}
	if(!Measure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measure){
			Measure = importPath.Measure;
		}
        else {
            import('./Measure').then(({ default: Measure }) => {
                Measure = Measure;
            });
            /*require(["appbo/smm/Measure"], function (loadedModule) {
                Measure = loadedModule;
			});*/
		}
	}
	export class ObservedMeasureMixin {

	defaults(){
		var ret = {
			type: "smm_ObservedMeasure"
		}
		return jQuery.extend(SmmElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"smm_ObservedMeasure",
			key:"measurements",
			relatedModel:"smm.Measurement",
			reverseRelation: {
				key:"observedMeasure",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		])
	}
	static getCumulativeMixinRelations(){
		if (!ObservedMeasureMixin.cummulativeRelations) {
            ObservedMeasureMixin.cummulativeRelations = _.union(ObservedMeasureMixin.getMixinRelations()
                , SmmElementMixin.getCumulativeMixinRelations()
            );
        }
		return ObservedMeasureMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_SmmElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "spreadsheetElement",type : "EString",defaultValue : "null",containingClass : "smm_ObservedMeasure" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("observation") ? this.get("observation") : this.previousAttributes().observation;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("observation") ? this.get("observation") : this.previousAttributes().observation;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/ObservedMeasurePropertiesTemplate.html",
			templateName : "ObservedMeasurePropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/ObservedMeasureViewModel",
			tabId : "ObservedMeasureView",
			tabName: "ObservedMeasure"
		}
	}
//#startCustomMethods
	copyObservedMeasureExclusive(observationTarget,measurementsCopied){ 
		var copyId = DataManager.getDataManager().guidGeneratorByOwner(observationTarget);
        var copyObsMeasure = new this.constructor({ id: copyId, name: this.get('name'), description: this.get('description'), observation: observationTarget });
        copyObsMeasure.set({'valueFormula': this.get('valueFormula'),'measure':this.get('measure'),'lastChange':new Date().getTime()});
		var selfMeasurement = this.get('measurements');
		if(selfMeasurement.length > 0){ //as we have only one instance
			copyId = DataManager.getDataManager().guidGeneratorByOwner(observationTarget);
			var measurement = selfMeasurement.at(0);
			var copyMeasurement = new measurement.constructor({id:copyId,value:measurement.get('value'),'measuredCharacteristic':measurement.get('measuredCharacteristic'),symbol:measurement.get('symbol'),name:measurement.get('name'),description:measurement.get('description'),observedMeasure:copyObsMeasure});	
			//copyMeasurement.set('observedMeasure',copyObsMeasure);
			/*copyMeasurement.set('value',measurement.get('value'));
			if(measurement.get('symbol')){
				copyMeasurement.set('symbol',measurement.get('symbol'));
			}*/
			if(measurementsCopied){
				var copyObj = {'measurement':measurement,'measurementCopy':copyMeasurement};
				measurementsCopied.push(copyObj);
			}
			//copyMeasurement.set('measuredCharacteristic',measurement.get('measuredCharacteristic'));
		}
		return copyObsMeasure;
	}
	
	copyObservedMeasureInclusive(observationTarget,measurementsCopied){
		var copyObsMeasure = this.copyObservedMeasureExclusive(observationTarget,measurementsCopied);
		var currentMeasurements = this.get('measurements');
		var currentMeasurement;
		var copyMeasurement;
		if(currentMeasurements.length > 0){
			currentMeasurement= currentMeasurements.at(0);
		}
		var copyMeasurements = copyObsMeasure.get('measurements');
		if(copyMeasurements.length > 0){
			copyMeasurement = copyMeasurements.at(0);
		}
		if(currentMeasurement && copyMeasurement){
			var measurementRelations = currentMeasurement.get('measurementRelationships');
			measurementRelations.each(function(mestRel){
				var copyId = DataManager.getDataManager().guidGeneratorByOwner(observationTarget);
				var copyRel = new mestRel.constructor({id:copyId,name:mestRel.get('name'),description:mestRel.get('description'),measurementRelationshipsOwner:copyMeasurement});
				var mestBase = mestRel.get('to');
				var mestChar = mestBase.get('measuredCharacteristic');
				copyRel.set('from',copyMeasurement);
				var targetContext = observationTarget.getContext();
				if(targetContext){
					var mestBaseTarget = targetContext.getTSLessMeasurementReferringToMeasuredCharteristic(mestChar);
					if(mestBaseTarget){
						copyRel.set('to',mestBaseTarget);
					}else{
						copyRel.set('to',mestBase);
					}
				}
			});
			var parents = [];
			if(this.getParents){
				parents = this.getParents();
			}
			_.each(parents,function(parent){
				if(parent instanceof Backbone.Model) {
					var obsMesParent = parent.get('observedMeasure');
					if(obsMesParent){
						obsMesParent.copyObservedMeasureInclusive(observationTarget,measurementsCopied);
					}
				}
			});
		}
	}	
	
	replaceMeasurement(newType,baseMeasurements,toMeasurement,baseTuples){
		var measurement = this.get('measurements').at(0);
		var timeStamp = measurement.get('observedMeasure').get('observation').get('whenObserved');
		//this.get('measurements').reset();
		var newMeasurement;
		switch(newType){
			case "smm_CollectiveMeasure":
				var mesRels = this.get('measure').get('baseMeasureTo').models.concat();
				newMeasurement = this.createCollectiveMeasurement(measurement.get('name'),measurement.get('description'));
				_.each(baseMeasurements,function(bm){
					//var relId = DataManager.getDataManager().guidGeneratorByOwner(newMeasurement);
					var bmTimeStamp = bm.get('observedMeasure').get('observation').get('whenObserved');
					if(bmTimeStamp !== timeStamp){
						return;
					}
					var name = newMeasurement.getUniqueProperty('name',bm.get('name') + "Base",'baseMeasurementTo');
					
					var mesRel = null;
					for(var i=0;i<mesRels.length;i++){
						if(mesRels[i].get('to') === bm.get('observedMeasure').get('measure')){
							mesRel = mesRels[i];
							break;
						}
					}
					var oldMesRel = null;
					if(!mesRel && baseTuples){
						for(var i=0;i<baseTuples.length;i++){
							if(baseTuples[i].new && baseTuples[i].new.component === bm.get('measuredCharacteristic').getParent()){//TODO new to check rescaled measures
								mesRel = baseTuples[i].new.baseMeasureRel;
								if(mesRel && mesRel.get('to') != bm.get('observedMeasure').get('measure')){//needed during cycle
									mesRel.set('to',bm.get('observedMeasure').get('measure'));
								}
								oldMesRel = bm.get('observedMeasure').get('measure');
								break;
							}
						}
					}
					var baseMeasurementRel = newMeasurement.createBaseMesurementRelationship(name,name,bm,mesRel);
					if(oldMesRel){
						mesRel = oldMesRel;
					}
					if(mesRel){
						var relIndex = _.indexOf(mesRels,mesRel);
						mesRels.splice(relIndex,1);
						//baseMeasurementRel.set('definition',mesRel);
					}
					//var baseMeasurementRel = new BaseNMeasurementRelationship({id:relId,name:bm.get('name')+ "Relation",description:bm.get('description')+ "Relation",measurementRelationshipsOwner:newMeasurement});
					//baseMeasurementRel.set('to',bm);
					//baseMeasurementRel.set('from',newMeasurement);
					newMeasurement.get('baseMeasurements').add(bm);
				});
			
				break;
			case 'smm_DirectMeasure':
				newMeasurement = this.createDirectMeasurement(measurement.get('name'),measurement.get('description'));
				break;
			case 'smm_RankingMeasure':
				newMeasurement = this.createRankingMeasurement(measurement.get('name'),measurement.get('description'));
				if(toMeasurement){
					var name = newMeasurement.get('name') + " Rel";
					newMeasurement.createRankingMesurementRelationship(name,name,toMeasurement);
				}
				break;
			case 'smm_GradeMeasure':
				newMeasurement = this.createGradeMeasurement(measurement.get('name'),measurement.get('description'));
				if(toMeasurement){
					var name = newMeasurement.get('name') + " Rel";
					newMeasurement.createGradeMesurementRelationship(name,name,toMeasurement);
				}				
				break;
		}
		measurement.get('measuredCharacteristic').get('measurement').add(newMeasurement);	// TODO is this ok
		var parents = measurement.getParents();
		var measurementsParents = [];
		var definitionList = [];
		_.each(parents,function(measurementParent){
			if(!measurementParent.get('measuredCharacteristic')){
				return;
			}
			/*var component = measurementParent.get('measuredCharacteristic').getMeasurand();
			var valProp = component.get('componentOwner');
			var isOVS = false;
			if(valProp){
				var ovs = valProp.get('overAllSatisfaction');
				if(ovs){
					if(component.get('id') === ovs.get('id')){
						isOVS = true;
					}
				}
			}
			!isOVS &&*/ measurementsParents.push(measurementParent);
		});
		function setNewMeasurement(relToBeChanged,measureRelationships){
			if(relToBeChanged.get('type') === "smm_RescaledMeasurementRelationship"){
				relToBeChanged.set('from',newMeasurement);
			}else{
				relToBeChanged.set('to',newMeasurement);
				if (relToBeChanged.get('type') === "smm_BaseNMeasurementRelationship") {
					relToBeChanged.getParent().get('baseMeasurements').add(newMeasurement);
				    for (var i = 0; i < measureRelationships.length; i++) {
				         if (measureRelationships.at(i).get('to') === relToBeChanged.get('to').get('observedMeasure').get('measure')) {
				            if(relToBeChanged.get('definition') && 
				            (measureRelationships.at(i).get('usePreviousAlternative') == relToBeChanged.get('definition').get('usePreviousAlternative')|| 
				            measureRelationships.at(i).get('usePreviousAlternative') == undefined && !relToBeChanged.get('definition').get('usePreviousAlternative'))) {
				            	if(measureRelationships.at(i).get('to').get('observedMeasure').length > 1){//measure resued
				            		var parentMsmtRel = relToBeChanged.get('from').get('measurementRelationships');
				            		var alreadyAssigned = false;
									for(var n = 0; n < parentMsmtRel.length; n++){
										if(parentMsmtRel.at(n) != relToBeChanged && parentMsmtRel.at(n).get('definition') === measureRelationships.at(i)){
											alreadyAssigned = true;
											break;
										}
									}
									if(alreadyAssigned){
										continue;
									}
				            	}
				            	var rescaledMeasure = measureRelationships.at(i).get('rescaledMeasure');
				            	var relRescaledMeasure = relToBeChanged.get('definition').get('rescaledMeasure');
				            	
				            	var offset = rescaledMeasure?rescaledMeasure.get('offset'):0;
				            	var multiplier = rescaledMeasure?rescaledMeasure.get('multiplier'):1;
				            	var operation = rescaledMeasure?rescaledMeasure.get('operation'):null;
				            	
				            	var reloffset = relRescaledMeasure?relRescaledMeasure.get('offset'):0;
				            	var relmultiplier = relRescaledMeasure?relRescaledMeasure.get('multiplier'):1;
				            	var reloperation = relRescaledMeasure?relRescaledMeasure.get('operation'):null;
				            	
				            	if(offset === reloffset && multiplier === relmultiplier && operation === reloperation){
					            	relToBeChanged.set('definition', measureRelationships.at(i));
						            break;
					            }
				            }
				            
				        }
				    }
				}
			}
        }
        _.each(measurementsParents, function (measurementParent) { 
            var measurementRelationships = measurementParent.get('measurementRelationships');
            var measureRelationships = measurementParent.get('observedMeasure').get('measure').get('measureRelationships');
            measurementRelationships.each(function (rel) {
                var relToBeChanged = null;
                if (rel.get('type') === 'smm_RescaledMeasurementRelationship') {
                    if (rel.get('from') === measurement) {
                        relToBeChanged = rel;
                    }
                } else {
                    if (rel.get('to') === measurement) {
                        relToBeChanged = rel;
                    }
                }
                if (relToBeChanged) {
                    setNewMeasurement(relToBeChanged, measureRelationships);
                }

            });
            if (DataManager.getDataManager().get('debugMode') && measurementParent.get('type') === 'smm_CollectiveMeasurement') {
                measurementParent.set("expression", measurementParent.getRelationDetails()['expression']);
            }
            
        });
        if (DataManager.getDataManager().get('debugMode') && newMeasurement.getRelationDetails) {
            newMeasurement.set("expression", newMeasurement.getRelationDetails()['expression']);
        }
		measurement.destroy();
		return newMeasurement;						
	}

	createDirectMeasurement(mesName,mesDescription){
		var mesId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var directMeasurement = new DirectMeasurement({id:mesId,name:mesName,description:mesDescription,observedMeasure:this,value:0});
		return directMeasurement;
	}	
	
	createRankingMeasurement(mesName,mesDescription){
		var mesId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var rankingMeasurement = new RankingMeasurement({id:mesId,name:mesName,description:mesDescription,observedMeasure:this,value:0});
		return rankingMeasurement;
	}
	createGradeMeasurement(mesName,mesDescription){
		var mesId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var gradeMeasurement = new GradeMeasurement({id:mesId,name:mesName,description:mesDescription,observedMeasure:this});
		return gradeMeasurement;
	}	
	createCollectiveMeasurement(mesName,mesDescription){
		var mesId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var collMeasurement = new CollectiveMeasurement({id:mesId,name:mesName,description:mesDescription,observedMeasure:this,value:0});
		return collMeasurement;
	}
	clone(data, observation,callback) {
	    var obsMesId = DataManager.getDataManager().guidGeneratorByOwner(this);
	    var obsMes = new this.constructor({ id: obsMesId, name: this.get('name'), description: this.get('description'), observation: observation,'measure': this.get('measure'),'valueFormula': this.get('valueFormula') });
	    var mest = this.get('measurements').at(0);
		if(mest.get('measuredCharacteristic')){
			mest.clone(data, obsMes, function () {
				if (callback) {
					callback(obsMes);
				}
			});
		}else {
			callback(obsMes);
		}
	    return obsMes;
	}

        //#endCustomMethods
    }
	path.ObservedMeasureMixin = ObservedMeasureMixin;
	
	//return ObservedMeasureMixin;
//});
