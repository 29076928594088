import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {NamedPlanElement} from './NamedPlanElement'
import {NamedPlanElementMixin} from './NamedPlanElementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import {BeepPackage} from '../beeppackage/BeepPackage'
import {BranchReference} from './BranchReference'
/*
define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/transformation/NamedPlanElement","appbo/transformation/NamedPlanElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,NamedPlanElement,NamedPlanElementMixin
, BeepPackage
, BranchReference
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
	if(!BeepPackage){
		var importPath = DataManager.getDataManager().buildAppNsPath("beeppackage",global.version);
		if(importPath.BeepPackage){
			BeepPackage = importPath.BeepPackage;
		}
        else {
            import('../beeppackage/BeepPackage').then(loadedModule => {
                BeepPackage = loadedModule;
            });

			/*require(["appbo/beeppackage/BeepPackage"],function(loadedModule){
				BeepPackage = loadedModule;
			});*/
		}
	}
	if(!BranchReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.BranchReference){
			BranchReference = importPath.BranchReference;
		}
		else{
			import('./BranchReference').then(loadedModule => {
                BranchReference = loadedModule;
            });
			/*require(["appbo/transformation/BranchReference"],function(loadedModule){
				BranchReference = loadedModule;
			});*/
		}
	}
export class PackageReferenceMixin {

	defaults(){
		var ret = {
			type: "transformation_PackageReference",
			beepType:"vdml_ValueDeliveryModel",
			isMain:false
		}
		return jQuery.extend(NamedPlanElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
/*		{
			type :Backbone.HasOne,
			containingClass:"transformation_PackageReference",
			key:"beepReference",
			relatedModel:"beeppackage.BeepPackage",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},*/
		{
			type :Backbone.HasMany,
			containingClass:"transformation_PackageReference",
			key:"phasePackageBranch",
			relatedModel:"transformation.BranchReference",
			reverseRelation: {
				key:"phasePackageBranchOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!PackageReferenceMixin.cummulativeRelations) {
            PackageReferenceMixin.cummulativeRelations = _.union(PackageReferenceMixin.getMixinRelations()
                , NamedPlanElementMixin.getCumulativeMixinRelations()
            );
        }
		return PackageReferenceMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"transformation_NamedPlanElement"
		];
	}
	static getProperties(){
		return [
			{name : "version",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "label",type : "EString",defaultValue : "null",containingClass : "transformation_PackageReference" },
			{name : "isMain",type : "Boolean",defaultValue : "false",containingClass : "transformation_PackageReference" },
			{name : "beepReference",type : "EString",defaultValue : "null",containingClass : "transformation_PackageReference" }						
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("phaseDesignPartOwner") ? this.get("phaseDesignPartOwner") : this.previousAttributes().phaseDesignPartOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("phaseDesignPartOwner") ? this.get("phaseDesignPartOwner") : this.previousAttributes().phaseDesignPartOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/transformation/views/properties/PackageReferencePropertiesTemplate.html",
			templateName : "PackageReferencePropertiesTemplate",
			viewTypeStr : "appviews/transformation/views/properties/PackageReferenceViewModel",
			tabId : "PackageReferenceView",
			tabName: "PackageReference"
		}
	}
	//
	//return PackageReferenceMixin;
}
path.PackageReferenceMixin = PackageReferenceMixin;