import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { Role } from './Role'
import { RoleMixin } from './RoleMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Role","appbo/vdml/RoleMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Role,RoleMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
export class PerformerMixin {

    defaults() {
        var ret = {
            type: "vdml_Performer"
        }
        return jQuery.extend(Role.prototype.defaults.apply(this), ret);
    }
    static getMixinRelations() {
        return _.union([
        ])
    }
    static getCumulativeMixinRelations() {
        if (!PerformerMixin.cummulativeRelations) {
            PerformerMixin.cummulativeRelations = _.union(PerformerMixin.getMixinRelations()
                , RoleMixin.getCumulativeMixinRelations()
            );
        }
        return PerformerMixin.cummulativeRelations.slice();
    }
    static getSuperTypes() {
        return [
            "vdml_Role"
        ];
    }
    static getProperties() {
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "represents", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "isLead", type: "EBoolean", defaultValue: "null", containingClass: "vdml_Role" }
        ]
    }
    getParent() {
        return Role.prototype.getParent.apply(this, arguments);
    }
    getPackagePath(path) {
        if (!path) {
            path = [];
        }
        var container;
        if (!container) {
            return Role.prototype.getPackagePath.apply(this, arguments);
        }
        return path;
    }
    getViewProperties(type) {
        return {
            templatePath: "views/vdml/views/properties/PerformerPropertiesTemplate.html",
            templateName: "PerformerPropertiesTemplate",
            viewTypeStr: "appviews/vdml/views/properties/PerformerViewModel",
            tabId: "PerformerView",
            tabName: "Performer"
        }
    }
    
}
path.PerformerMixin = PerformerMixin;
	//return PerformerMixin;
//});