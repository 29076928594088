import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { MeasurableElement } from './MeasurableElement'
import { MeasurableElementMixin } from './MeasurableElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { MeasuredCharacteristic } from './MeasuredCharacteristic'
//import { Expression } from './Expression'
import { Role } from './Role'
import { BindingOperationReference } from './BindingOperationReference'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/MeasurableElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,MeasurableElementMixin
, MeasuredCharacteristic

, Expression
, Role
, BindingOperationReference){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
        else {
            import('./MeasuredCharacteristic').then(loadedModule => {
                MeasuredCharacteristic = loadedModule;
            });
            
            /*require(["appbo/vdml/MeasuredCharacteristic"], function (loadedModule) {
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	/*if(!Expression){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Expression){
			Expression = importPath.Expression;
		}
        else {
            import('./Expression').then(loadedModule => {
                Expression = loadedModule;
            });
		}
	}*/
	if(!Role){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Role){
			Role = importPath.Role;
		}
        else {
            import('./Role').then(loadedModule => {
              //  Role = loadedModule;
            });
			/*require(["appbo/vdml/Role"],function(loadedModule){
				Role = loadedModule;
			});*/
		}
	}
	if(!BindingOperationReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BindingOperationReference){
			BindingOperationReference = importPath.BindingOperationReference;
		}
        else {
            import('./BindingOperationReference').then(loadedModule => {
                BindingOperationReference = loadedModule;
            });
			/*require(["appbo/vdml/BindingOperationReference"],function(loadedModule){
				BindingOperationReference = loadedModule;
			});*/
		}
	}
export class PortMixin {

    defaults() {
        var ret = {
            type: "vdml_Port"
        }
        return jQuery.extend(MeasurableElement.prototype.defaults.apply(this), ret);
    }
    static getMixinRelations() {
        return _.union([
            /*{
                type: Backbone.HasOne,
                containingClass: "vdml_Port",
                key: "planningPercentage",
                relatedModel: "vdml.MeasuredCharacteristic",
                reverseRelation: {
                    key: "planningPercentageOwnerPort",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },
            {
                type: Backbone.HasOne,
                containingClass: "vdml_Port",
                key: "batchSize",
                relatedModel: "vdml.MeasuredCharacteristic",
                reverseRelation: {
                    key: "batchSizeOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },*/
            {
                type: Backbone.HasOne,
                containingClass: "vdml_Port",
                key: "offset",
                relatedModel: "vdml.MeasuredCharacteristic",
                reverseRelation: {
                    key: "offsetOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },
            /*{
                type: Backbone.HasOne,
                containingClass: "vdml_Port",
                key: "condition",
                relatedModel: "vdml.Expression",
                reverseRelation: {
                    key: "conditionOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },*/
            {
                type: Backbone.HasOne,
                containingClass: "vdml_Port",
                key: "handler",
                relatedModel: "vdml.Role",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            },
            {
                type: Backbone.HasOne,
                containingClass: "vdml_Port",
                key: "bindingOperationReference",
                relatedModel: "vdml.BindingOperationReference",
                reverseRelation: {
                    key: "bindingOperationReferenceOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            }
        ])
    }
    static getCumulativeMixinRelations() {
        if (!PortMixin.cummulativeRelations) {
            PortMixin.cummulativeRelations = _.union(PortMixin.getMixinRelations()
                , MeasurableElementMixin.getCumulativeMixinRelations()
            );
        }
        return PortMixin.cummulativeRelations.slice();
    }
    static getSuperTypes() {
        return [
            "vdml_MeasurableElement"
        ];
    }
    static getProperties() {
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "represents", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "isIntermediate", type: "EBoolean", defaultValue: "null", containingClass: "vdml_Port" }
        ]
    }
    getParent() {
        var container;
        if (!container) {
            container = this.get("containedPortOwner") ? this.get("containedPortOwner") : this.previousAttributes().containedPortOwner;
            if (container) {
                return container;
            }
        }
        return this;
    }
    getPackagePath(path) {
        if (!path) {
            path = [];
        }
        var container;
        if (!container) {
            container = this.get("containedPortOwner") ? this.get("containedPortOwner") : this.previousAttributes().containedPortOwner;
            if (container) {
                path.unshift({ id: this.get('id'), name: this.get('name') })
                return container.getPackagePath(path);
            }
        }
        return path;
    }
    getViewProperties(type) {
        return {
            templatePath: "views/vdml/views/properties/PortPropertiesTemplate.html",
            templateName: "PortPropertiesTemplate",
            viewTypeStr: "appviews/vdml/views/properties/PortViewModel",
            tabId: "PortView",
            tabName: "Port"
        }
    }
    
}
path.PortMixin = PortMixin;
	//return PortMixin;
//});