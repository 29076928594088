import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//import {Attribute} from './Attribute'
//import {Annotation} from './Annotation'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin
, Attribute
, Annotation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	//if(!Attribute){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		/*if(importPath.Attribute){
			Attribute = importPath.Attribute;
		}
		else{
			/*import('./Attribute').then(({ default: Attribute }) => {
                Attribute = Attribute;
            });*/
			/*require(["appbo/smm/Attribute"],function(loadedModule){
				Attribute = loadedModule;
			});
		}*/
	//}
	//if(!Annotation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Annotation){
			Annotation = importPath.Annotation;
		}
		else{
			import('./Annotation').then(({ default: Annotation }) => {
                Annotation = Annotation;
            });
			/*require(["appbo/smm/Annotation"],function(loadedModule){
				Annotation = loadedModule;
			});*/
		}
	//}
	export class SmmElementMixin {

	defaults(){
		var ret = {
			type: "smm_SmmElement"
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		/*{
			type :Backbone.HasMany,
			containingClass:"smm_SmmElement",
			key:"attributes",
			relatedModel:"smm.Attribute",
			reverseRelation: {
				key:"owner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_SmmElement",
			key:"annotations",
			relatedModel:"smm.Annotation",
			reverseRelation: {
				key:"owner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}*/
		])
	}
	static getCumulativeMixinRelations(){
		if (!SmmElementMixin.cummulativeRelations) {
            SmmElementMixin.cummulativeRelations = _.union(SmmElementMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return SmmElementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" }
		]
	}
	getParent(){
		var container;
		return EObject.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return EObject.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/SmmElementPropertiesTemplate.html",
			templateName : "SmmElementPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/SmmElementViewModel",
			tabId : "SmmElementView",
			tabName: "SmmElement"
		}
	}
	
	}
	path.SmmElementMixin = SmmElementMixin;
	//return SmmElementMixin;
//});