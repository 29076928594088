import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as async from 'async'
import {DataManager} from '../../../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","async","appcommon/com/vbee/data/DataManager","app/global","appbo/vdml/ValueElementMixin", "jquery.DataTable", "dataTable.PageResize"],
//function(require,$,_, Backbone,ko, koMapping,kb,bootbox,async,DataManager,global,ValueElementMixin)
//{
	var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties",global.version);
	export class AlternativeInputValuesViewModel {
		constructor(model,options){
			var self = this;
			this.init(model, options);
		}
	htmlEscape(str){
        return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
    };
    cleanUp(){
    	var self = this;
		if(self.dataTable){
			self.dataTable.destroy(true);
		}
	};
	cleanModal(){
		var self = this;
        window.cleanDialogModel(self.encodeId,self);
	};
	saveAlternativeDetails(){
        var self = this;
        self.viewLoadedCount++;
		return self.options.callback(this,self.encodeId,false);

	};
	custResize(){
		var self = this;
		this.timer = clearTimeout();
        this.timer = setTimeout(function(){
        	var height = $('#editDetailsModalContent'+self.encodeId).height();
		    $('#AlternativeInputValuesView'+self.encodeId).css('height', height);
		    $('#valueTable'+self.encodeId).css('height', height - self.heightExcludingTable);
        }, 100);
	};
	fillDuplicatePath(bmValues,val,values){
		var displayPath = ' '; 
		var valFound = _.filter(bmValues,function(obj){if(obj.get('name').toLowerCase() === val.get('name').toLowerCase() && obj.id != val.get('id')){return obj};});
		if(valFound && valFound.length){
			var valuePackagePath = val.getPackagePath();
			var valueName = valuePackagePath[2]? valuePackagePath[2].name : "";
			displayPath = displayPath.concat(" ["+valuePackagePath[0].name+"  /  "+valuePackagePath[1].name+"  /  "+valueName+"]");
		}else {
			var valFound = _.filter(values,function(obj){return obj['name'].toLowerCase() === val.get('name').toLowerCase();});
			if(valFound && valFound.length){
				if(valFound[0].namePath.length < 2){
					var vp = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: valFound[0].id });
					if(vp){
						var nestedValuePackagePath = vp.getPackagePath();
						var nestedValueName = nestedValuePackagePath[2]? nestedValuePackagePath[2].name : "";
						valFound[0].namePath = " ["+nestedValuePackagePath[0].name+"  /  "+nestedValuePackagePath[1].name+"  /  "+nestedValueName+"]";
					}
				}
				var valuePackagePath = val.getPackagePath();
				var valueName = valuePackagePath[2]? valuePackagePath[2].name : "";
				displayPath = displayPath.concat(" ["+valuePackagePath[0].name+"  /  "+valuePackagePath[1].name+"  /  "+valueName+"]");
			}	
		}
		return displayPath;
	};

	fetchPlanDirectforAlt(plan,phase,inheritingAlt){
		var self = this;
		var planCriterionSet = plan?plan.get('planCriterionSet'):null;
		var planComponents = planCriterionSet?planCriterionSet.get('component'):null;
		if(planComponents){
			planComponents.each(function(value){
				var currentValue;
				var previousValue = '-';
				var currentUnit = ' ';
				var previousUnit = ' ';
				var showValue = false;
				var phaseAlts = [];
				if(!phase && inheritingAlt){
					phaseAlts.push(inheritingAlt);
				}else {
					phaseAlts = phase.get('phaseAlternative').models;
				}
				var previousDirectExists = false;
				var phaseAltsValues = [];
				if(inheritingAlt){
					var altComp = inheritingAlt.getModelLinkedSync(value);
					var preAltScenario = altComp?inheritingAlt.getMainScenario(altComp.getNestedParent()):null;
					var previousMest = preAltScenario?preAltScenario.getTSLessMeasurementReferringToMeasuredCharteristic(altComp.get('valueMeasurement')):null;
					if(preAltScenario && previousMest && previousMest.get('type') === 'smm_DirectMeasurement'){
						previousDirectExists = true;
						var unit = previousMest.get('observedMeasure').get('measure').get('unit');
						previousUnit = unit?unit.get('name'):' ';
						var sigDecimal = unit?unit.get('significantDecimals'):'2';
						previousValue = window.utils.getRoundedValues(previousMest.get('value'),sigDecimal);
						currentValue = previousValue;
						currentUnit = previousUnit;
					}/*else{
						previousMest = preAltScenario?preAltScenario.getTSLessMeasurementReferringToMeasuredCharteristic(altComp.get('satisfactionLevel')):null;
						if(previousMest){
							var compSatisfactionMeasure = previousMest?previousMest.get('observedMeasure').get('measure'):null;
							if(compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
								previousValue = '' + previousMest.get('symbol');
								previousUnit = 'Grade only';
								currentValue = previousValue;
								currentUnit = previousUnit;
							}
						}else{
							previousValue = '-';
						}
					}*/
				}
				if(self.model){
					var intervalArray = [];
					var altScenario = self.model.getMainScenario(value.getNestedParent());
					var currentMest = altScenario.getTSLessMeasurementReferringToMeasuredCharteristic(value.get('valueMeasurement'));
					if(currentMest && currentMest && currentMest.get('type') === 'smm_DirectMeasurement'){
						showValue = true;
						var unit = currentMest.get('observedMeasure').get('measure').get('unit');
						currentUnit = unit?unit.get('name'):' ';
						var sigDecimal = unit?unit.get('significantDecimals'):'2';
						currentValue = window.utils.getRoundedValues(currentMest.get('value'),sigDecimal);
					}/*else{
						currentMest = altScenario?altScenario.getTSLessMeasurementReferringToMeasuredCharteristic(value.get('satisfactionLevel')):null;
						if(currentMest){
							var compSatisfactionMeasure = currentMest?currentMest.get('observedMeasure').get('measure'):null;
							if(compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
								showValue = true;
								currentValue = '' + currentMest.get('symbol');
								currentUnit = 'Grade only';
								compSatisfactionMeasure.get('interval').each(function(interval){
                        			intervalArray.push(interval.get('symbol')?interval.get('symbol'): ' ');
								});
							}
						}else{
							showValue = false;
						}
					}*/
				}else if(previousDirectExists){
					showValue = true;
				}
				if(phase){
					for(var i=0;i<phaseAlts.length;i++){
						if(phaseAlts[i] !== self.model){
							var altComp = phaseAlts[i].getModelLinkedSync(value);
							var preAltScenario = altComp?phaseAlts[i].getMainScenario(altComp.getNestedParent()):null;
							var previousMest = preAltScenario?preAltScenario.getTSLessMeasurementReferringToMeasuredCharteristic(altComp.get('valueMeasurement')):null;
							if(preAltScenario && previousMest){
								var unit = previousMest.get('observedMeasure').get('measure').get('unit');
								previousUnit = unit?unit.get('name'):' ';
								/*if(currentUnit == previousUnit){
									previousUnit = ' ';
								}*/
								var sigDecimal = unit?unit.get('significantDecimals'):'2';
								previousValue = window.utils.getRoundedValues(previousMest.get('value'),sigDecimal);
								phaseAltsValues.push({id:phaseAlts[i].get('id'),previousValue:''+previousValue,previousUnit:previousUnit});
							}else {
                                phaseAltsValues.push({id:'-',previousValue:'-',previousUnit:' '});
								/*previousMest = preAltScenario?preAltScenario.getTSLessMeasurementReferringToMeasuredCharteristic(altComp.get('satisfactionLevel')):null;
								if(previousMest){
									var compSatisfactionMeasure = previousMest?previousMest.get('observedMeasure').get('measure'):null;
									if(compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
										previousValue = '' + previousMest.get('symbol');
										previousUnit = 'Grade only';
										phaseAltsValues.push({id:phaseAlts[i].get('id'),previousValue:''+previousValue,previousUnit:previousUnit});
									}
								}else{
									phaseAltsValues.push({id:'-',previousValue:'-',previousUnit:' '});	
								}*/
							}
						}
					}
				}
				if(showValue) {
					var namePath = self.fillDuplicatePath([], value, self.valueArray);
					var obj = {id:value.get('id'),escapeId:self.htmlEscape(value.get('id')),name:value.get('name'),namePath:namePath,previousValue:''+previousValue,currentValue:''+currentValue,previousUnit:previousUnit,currentUnit:currentUnit,phaseAltsValues:phaseAltsValues, intervalArray:intervalArray};
					if(intervalArray && intervalArray.length > 0){
						obj.gradeOnly = true;
					}else{
						obj.gradeOnly = false;
					}
					self.valueArray.push(obj);
				}
			});
		}
	};
	getTableHeadings(phase){
		var self = this;
		self.phaseAltsHeadings = [];
		var phaseAlts = phase?phase.get('phaseAlternative'):[];
		//self.phaseAltsHeadings.push('Values');
		self.phaseAltsHeadings.push(''+self.name());
		for(var i=0;i<phaseAlts.length;i++){
			if(phaseAlts.at(i) !== self.model){
				self.phaseAltsHeadings.push(phaseAlts.at(i).get('name'));
			}
		}
	};
	showDirectValues(callback){
		var self = this;
		self.valueArray = [];
		var packageList = [];
		var inheritingAlt;
		var plan;
		var phase;
		var clonedFromAltId = self.options.addOptions.clonedFromAltId;
		var clonedAlt;
		if(clonedFromAltId){           
			var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");                
			clonedAlt = docTypeObj.find({ id: clonedFromAltId });
		}
		function fetchVdmlPackages(alt){
			var phaseDesignPart = alt.get('phaseDesignPart');
			var vdmlList = [];
			for(var i=0;i<phaseDesignPart.length;i++){
				if(phaseDesignPart.at(i).get('beepReference').indexOf("Common") == -1 && phaseDesignPart.at(i).get('beepType') !== 'report_BeepReport' && phaseDesignPart.at(i).get('beepType') !== 'ecomap_EcoMap' && phaseDesignPart.at(i).get('beepType') !== 'dashboard_Analytics'){
					vdmlList.push(phaseDesignPart.at(i).get('beepReference'));
				}	
			}
			return vdmlList;
		}
		if(self.model){
			phase = self.model.getParent();
			self.getTableHeadings(phase);
			plan = self.model.getNestedParent();
			inheritingAlt = self.model.getPreviousPhasePrimary();
			packageList = fetchVdmlPackages(self.model);
		}else {
			phase = self.parentModal;
			if(phase){
				self.getTableHeadings(phase);
				plan = phase.getNestedParent();
				if(clonedAlt){
					inheritingAlt = clonedAlt;
				}else{
					inheritingAlt = phase.get('master');
				}
				packageList = fetchVdmlPackages(inheritingAlt);
			}else {
				plan = self.options.addOptions.planModel;
				if(plan) {
					var lastPhase = _.filter(plan.get('phase').models,function(obj){return  obj.get('nextPhase') === null;});
					self.getTableHeadings();
					if(lastPhase && lastPhase[0]){
						if(clonedAlt){
							inheritingAlt = clonedAlt;
						}else{
							inheritingAlt = lastPhase[0].get('primary');
						}
						packageList = fetchVdmlPackages(inheritingAlt);
					}
				}
			}
		}
		window.utils.startSpinner('loadPackageSpinner', "loading Package...");
		//var planWorkspaceId = DataManager.getDataManager().getWorkspaceIdByPlanId(plan.id);
		//var planWorkspaceName = DataManager.getDataManager().getWorkspaceName(planWorkspaceId);
		window.utils.startSpinner('loadPackage', 'Fetching Details...');
		window.setTimeout(function () {
				DataManager.getDataManager().set('currentPlan',plan,{silent:true});
		self.fetchPlanDirectforAlt(plan,phase,inheritingAlt);
			setTimeout(function(){
				async.eachSeries(packageList,function(beepReferenceId,handlePackage){
					var packageAlt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().getRepositoryId(beepReferenceId));
					packageAlt.getBeepPackageWithId(beepReferenceId,'vdml_ValueDeliveryModel',function(beepPackage){
						if(beepPackage){
							var businessModels = beepPackage.get('businessModel');
							_.each(businessModels.models,function(bm){
								var values = [];
								bm.getValues(values,true);
									_.each(values,function(value){
										var valFound = _.filter(self.valueArray,function(obj){return obj['id'] === value.get('id')});
										if(valFound && valFound[0]){
											return;
										}
										var currentValue;
										var previousValue = '-';
										var currentUnit = ' ';
										var previousUnit = ' ';
										var showValue = false;
										var phaseAlts = [];
										if(!phase && inheritingAlt){
											phaseAlts.push(inheritingAlt);
										}else {
											phaseAlts = phase.get('phaseAlternative').models;
										}
										var previousDirectExists = false;
										var phaseAltsValues = [];
										if(inheritingAlt){
											var altComp = inheritingAlt.getModelLinkedSync(value);
											var preAltScenario = altComp?inheritingAlt.getMainScenario(altComp.getNestedParent()):null;
											var previousMest = preAltScenario?preAltScenario.getTSLessMeasurementReferringToMeasuredCharteristic(altComp.get('valueMeasurement')):null;
											if(preAltScenario && previousMest && previousMest.get('type') === 'smm_DirectMeasurement'){
												previousDirectExists = true;
												var unit = previousMest.get('observedMeasure').get('measure').get('unit');
												previousUnit = unit?unit.get('name'):' ';
												var sigDecimal = unit?unit.get('significantDecimals'):'2';
												previousValue = window.utils.getRoundedValues(previousMest.get('value'),sigDecimal);
												currentValue = previousValue;
												currentUnit = previousUnit;
											}/*else{
												previousMest = preAltScenario?preAltScenario.getTSLessMeasurementReferringToMeasuredCharteristic(altComp.get('satisfactionLevel')):null;
												if(previousMest){
													var compSatisfactionMeasure = previousMest?previousMest.get('observedMeasure').get('measure'):null;
													if(compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
														previousValue = '' + previousMest.get('symbol');
														previousUnit = '';
														currentValue = previousValue;
														currentUnit = previousUnit;
													}
												}else{
													previousValue = '-';
												}
											}*/
										}
										if(self.model){
											var intervalArray = [];
											var altScenario = self.model.getMainScenario(value.getNestedParent());
											var currentMest = altScenario.getTSLessMeasurementReferringToMeasuredCharteristic(value.get('valueMeasurement'));
											if(currentMest && currentMest && currentMest.get('type') === 'smm_DirectMeasurement'){
												showValue = true;
												var unit = currentMest.get('observedMeasure').get('measure').get('unit');
												currentUnit = unit?unit.get('name'):' ';
												var sigDecimal = unit?unit.get('significantDecimals'):'2';
												currentValue = window.utils.getRoundedValues(currentMest.get('value'),sigDecimal);
											}/*else{
												currentMest = altScenario?altScenario.getTSLessMeasurementReferringToMeasuredCharteristic(value.get('satisfactionLevel')):null;
												if(currentMest){
													var compSatisfactionMeasure = currentMest?currentMest.get('observedMeasure').get('measure'):null;
													if(compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
														showValue = true;
														currentValue = '' + currentMest.get('symbol');
														currentUnit = 'Grade only';
														compSatisfactionMeasure.get('interval').each(function(interval){
						                        			intervalArray.push(interval.get('symbol')?interval.get('symbol'): ' ');
														});
													}
												}else{
													showValue = false;
												}
											}*/
										}else if(previousDirectExists){
											showValue = true;
										}
										if(phase) {
											for(var i=0;i<phaseAlts.length;i++){
												if(phaseAlts[i] !== self.model){
													var altComp = phaseAlts[i].getModelLinkedSync(value);
													var preAltScenario = altComp?phaseAlts[i].getMainScenario(altComp.getNestedParent()):null;
													var previousMest = preAltScenario?preAltScenario.getTSLessMeasurementReferringToMeasuredCharteristic(altComp.get('valueMeasurement')):null;
													if(preAltScenario && previousMest){
														var unit = previousMest.get('observedMeasure').get('measure').get('unit');
														previousUnit = unit?unit.get('name'):' ';
														/*if(currentUnit == previousUnit){
															previousUnit = ' ';
														}*/
														var sigDecimal = unit?unit.get('significantDecimals'):'2';
														previousValue = window.utils.getRoundedValues(previousMest.get('value'),sigDecimal);
														phaseAltsValues.push({id:phaseAlts[i].get('id'),previousValue:''+previousValue,previousUnit:previousUnit});
													}else {
                                                        phaseAltsValues.push({id:'-',previousValue:'-',previousUnit:' '});
														/*previousMest = preAltScenario?preAltScenario.getTSLessMeasurementReferringToMeasuredCharteristic(altComp.get('satisfactionLevel')):null;
														if(previousMest){
															var compSatisfactionMeasure = previousMest?previousMest.get('observedMeasure').get('measure'):null;
															if(compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
																previousValue = '' + previousMest.get('symbol');
																previousUnit = 'Grade only';
																phaseAltsValues.push({id:phaseAlts[i].get('id'),previousValue:''+previousValue,previousUnit:previousUnit});
															}
														}else{
															phaseAltsValues.push({id:'-',previousValue:'-',previousUnit:' '});	
														}*/
													}
												}
											}
										}
										
										if(showValue) {												
                        					var namePath = self.fillDuplicatePath(values, value, self.valueArray);
											var obj = {id:value.get('id'),escapeId:self.htmlEscape(value.get('id')),name:value.get('name'),namePath:namePath,currentValue:''+currentValue,currentUnit:currentUnit,phaseAltsValues:phaseAltsValues, intervalArray:intervalArray};
											if(intervalArray && intervalArray.length > 0){
												obj.gradeOnly = true;
											}else{
												obj.gradeOnly = false;
											}
											self.valueArray.push(obj);
										}
									});
							});
							handlePackage();
						}else {
							handlePackage();
						}
					});
				},function(err){
					self.valueArray.sort(function(left, right) {
		          		return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
		    	  	});
					//self.values(self.valueArray);
					window.utils.stopSpinner('loadPackageSpinner');
					callback && callback();
				});
			},100);
			
	}, 100);
	};
	displayTable(callback){
		var self = this;
		var myTableDiv = $('#valueTable' + self.encodeId);
		myTableDiv.empty();
		var table = document.createElement('TABLE');
	    table.id = 'table' + self.encodeId;
	    table.className="display";
	    //table.className="pageResize";
	    table.cellspacing="0";
	    table.width="100%";
	    //for header
	    var tableHeader = document.createElement('THEAD');
    	table.appendChild(tableHeader);
    	var headerTR1 = document.createElement('TR');
    	tableHeader.appendChild(headerTR1);
    	var th = document.createElement('TH');
        th.appendChild(document.createTextNode("Values"));
        headerTR1.appendChild(th);
	    _.each(self.phaseAltsHeadings, function(header){
	       var th = document.createElement('TH');
           th.appendChild(document.createTextNode(header));
           th.className = "no-sort";	           headerTR1.appendChild(th);
	    });
	    //for tbody
	    var tableBody = document.createElement('TBODY');
    	table.appendChild(tableBody);
    	for (var i=0; i<self.valueArray.length; i++){
    	   var value = self.valueArray[i];
	       var tr = document.createElement('TR');
	       tr.setAttribute("id", value.escapeId);
	       tableBody.appendChild(tr);
	       
           var td1 = document.createElement('TD');
			td1.align = "left";
			var unitText = value.currentUnit && value.currentUnit != '' ? ' (' + value.currentUnit + ')' : '';
			td1.appendChild(document.createTextNode(value.name + value.namePath + unitText));
			
	       tr.appendChild(td1);
           var tdCurrent = document.createElement('TD');
			tdCurrent.align = "right";
			tdCurrent.setAttribute('id', 'tdValue');
			tdCurrent.setAttribute('valId', value.id);
			tdCurrent.setAttribute('altId', self.model ? self.model.get('id') : "");
			tdCurrent.setAttribute('contenteditable', true);
	      if(value.gradeOnly){
			/*tdCurrent.setAttribute('contenteditable', false);
	       	   var selectList = document.createElement("select");
	       	   for (var k = 0; k < value.intervalArray.length; k++) {
				    var option = document.createElement("option");
				    option.value = value.intervalArray[k];
				    option.text = value.intervalArray[k];
				    selectList.appendChild(option);
			   }
    		   selectList.setAttribute("value", value.currentValue);
    		   selectList.setAttribute("class", "form-control");
			   selectList.style="display:inline; width:60%;";
    		   var selectedIndex = _.findIndex(selectList.options, {value:value.currentValue});
    		   selectList.selectedIndex = selectedIndex;
		       tdCurrent.appendChild(selectList);
		       tdCurrent.appendChild(document.createTextNode(value.currentValue? ' (Grade only)': ''));*/
	       }else{
              
		       var val = value.currentValue;
		       var unit = value.currentUnit;
		       var input = document.createElement("INPUT");
               input.setAttribute("value", val);
               input.setAttribute("data-bind", "numeric: number");
               input.setAttribute("type", "text")
    		   input.style="display:none; width:90%;";
		       //tdCurrent.appendChild(input);
		       function appendInputToTD(unit, tdCurrent, textField, input){
		       	   textField.appendChild(document.createTextNode(val));		
			       tdCurrent.appendChild(textField);
			       var editButton = document.createElement("i");
			       editButton.className = "glyphicon glyphicon-pencil";
			       editButton.style = "cursor:pointer";
			       $(editButton).click(function(){
			       		$(textField).hide();
			       		$(this).hide();
			       		$(input).css('display','inline-block');
			       		$(updateButton).show();
			       });
			       var updateButton = document.createElement("i");
			       updateButton.className = "glyphicon glyphicon-ok";
			       updateButton.style = "cursor:pointer;display:none";
			       $(updateButton).click(function(){
			       		var inputVal = $(input).val();
			       		$(textField).text(inputVal+" ("+unit+") ");
			       		$(textField).show();
			       		$(editButton).show();
			       		$(this).hide();
			       		$(input).hide();
			       });
			       //tdCurrent.appendChild(editButton);
			       //tdCurrent.appendChild(updateButton);
			       return tdCurrent;
		       }
		       var textField;
		       if(self.model){
			       textField = document.createElement("a");
				   textField.style.color = "inherit";
				   //textField.style.cursor = "pointer";
				   textField.setAttribute('valId',value.id);
				   textField.setAttribute('altId',self.model ? self.model.get('id'):"");
				   /*$(textField).click(function(event){
				   	var obj = {id:event.currentTarget.getAttribute('valId'), altId:event.currentTarget.getAttribute('altId')};
		       		self.showAggregationView(obj);
				   });*/
			   }
			   else{
			   	textField = document.createElement("SPAN");
			   }
		       if(!val || val.trim() === ""){
		       		tdCurrent.appendChild(document.createTextNode("-"));	
		       }else {
		       	   tdCurrent = appendInputToTD(unit, tdCurrent, textField, input);
		       }
	       }
			tr.appendChild(tdCurrent);
			
	       for (var j=0; j<value.phaseAltsValues.length; j++){
	       	   var column = value.phaseAltsValues[j];
               var td = document.createElement('TD');
			   td.align = "right";
			   
	           var text = column.previousValue;
	           var unitText = column.previousUnit && column.previousUnit.trim() != ''?' ('+column.previousUnit+')' : '';
	           if(text!='-'){
	           	   if(self.model){
			           var a1 = document.createElement('a');
			           a1.style.color = 'inherit';
			           //a1.style.cursor = 'pointer';
			           a1.setAttribute('valId',value.id);
			           a1.setAttribute('altId',column.id);
			           a1.appendChild(document.createTextNode(text));
					   td.appendChild(a1);
					   /*$(a1).click(function(event){
					   	var obj = {id:event.currentTarget.getAttribute('valId'), altId:event.currentTarget.getAttribute('altId')};
			       		self.showAggregationView(obj);
					   });*/
				   }
				   else{
				   	   td.appendChild(document.createTextNode(text));
				   }
			   }
			   else{
			   		td.appendChild(document.createTextNode('-'));
			   }
			   tr.appendChild(td);

	       }
	    }
		myTableDiv.append(table);
		$('#table' + self.encodeId + "  tr #tdValue").bind().on('change', function () {
			self.valueChange($(this));
		});
		$('#table' + self.encodeId + "  tr #tdValue").bind().on('keyup keypress', function () {
			if (/*event.keyCode == 46 ||*/ event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13 ||
				// Allow: Ctrl+A
				(event.keyCode == 65 && event.ctrlKey === true) ||
				// Allow: Ctrl+v
				(event.keyCode == 86 && event.ctrlKey === true) ||
				// Allow: Ctrl+c
				(event.keyCode == 67 && event.ctrlKey === true) ||
				// Allow: . 
				((event.keyCode == 190 || event.keyCode == 46 || event.keyCode == 110) && event.currentTarget.textContent.indexOf('.') == -1) ||
				// Allow: home, end, left, right
				(event.keyCode >= 35 && event.keyCode <= 39)) {
				// let it happen, don't do anything
				return;
			}
			else {
				if (event.shiftKey || ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) ||
					!(parseInt(event.key) >= 0 || parseInt(event.key) <= 9) || ((event.which != 46 || (event.which == 46 && $(this).val() == '')) ||
						$(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
					//if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (parseInt(event.key) >= 0 && parseInt(event.key) <= 9) && (event.keyCode < 96 || event.keyCode > 105)) {
					event.preventDefault();
				}
			}
			if ($(this).find('a').text() === "" && event.currentTarget.textContent) {
				var a = document.createElement('a');
				a.style.color = 'inherit';
				//a.style.cursor = 'pointer';
				a.setAttribute('altId', $(this).attr('altid'))
				a.setAttribute('valid', $(this).attr('valid'));
				a.textContent = event.currentTarget.textContent;
				/*$(a).click(function (event) {
					var obj = { id: event.currentTarget.getAttribute('valId'), altId: event.currentTarget.getAttribute('altId') };
					self.showAggregationView(obj);
				});*/
				$(this).text("");
				$(this).append(a);
				var el = $(this)
				var range = document.createRange()
				var sel = window.getSelection()

				range.setStart($(this).find('a')[0], 1)
				range.collapse(true)

				sel.removeAllRanges()
				sel.addRange(range)
			}
			//self.enableComplete(true);
			self.valueChange($(this));
		})
		//to prevent -,+,e
		$('#table' + self.encodeId + "  tr #tdValue").bind('paste', function (e) {
			var clipboardData, pastedData;
		    // Get pasted data via clipboard API
		    clipboardData = e.originalEvent.clipboardData || window.clipboardData;
		    pastedData = clipboardData.getData('Text');
		    var regex = /^[0-9]+\.?[0-9]*$/;
			if(!pastedData.match(regex) || !/^\d+$/.test(pastedData)){
		        e.stopPropagation();
		        e.preventDefault();
		    }
	   	});
    	self.dataTable = $('#table' + self.encodeId).DataTable({
    		"columnDefs": [
                { "orderable": false, "targets": "no-sort"}
            ],
            "autoWidth": false,
            "pageResize": true,
            "bLengthChange": false
    	});
	};
	valueChange(element){
		var self = this;
		var value = element.val() ? element.val() : (element[0].children[0] ? element[0].children[0].text : "");
		var trId = element.parents('tr')[0].id;
		var valueObj = _.find(self.valueArray, function(e){ return e.escapeId === trId;});
		if(value != valueObj.currentValue){
			var valueFound = false;
			for(var i=0;i<self.directValueChanges.length;i++){
				if(self.directValueChanges[i].id === valueObj.id){
					self.directValueChanges[i].value = value;
					valueFound = true;
					break;
				}
			}
			if(!valueFound){
				self.directValueChanges.push({'id':valueObj.id,'value':value});
			}
		}
	};
	afterRenderView (node,view){
        var self = view;
        self.viewLoadedCount = 1;
		self.showDirectValues( function(){
			//self.displayTable();
		});
        window.utils.applyFontSizeInDialog();
	}
	/*showAggregationView(view) {
		var self = this;
		if(!self.model){
			return;
		}
		var valueId = view.id;
        var id = "aggregation" + self.htmlEscape(valueId);
        var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
        var alt = docTypeObj.find({ id: view.altId });
		var valueModal = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: valueId });
		if(!valueModal){
			valueModal = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: valueId });
		}
		if(valueModal){
			valueModal = alt.getModelLinkedSync(valueModal);
			var mest, pack = valueModal.getNestedParent();
			DataManager.getDataManager().getMainScenario(pack,alt.get('id'),function(mainScenario){
				mest = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(valueModal.get('valueMeasurement'));
				if(!mest){
					mest = valueModal;
				}
				var addOptions = { 'currentAlternativeModel':alt};
				var ValueElementMixin = Backbone.Relational.store.getObjectByName('vdml.ValueElementMixin');
	        	window.getAndCreateModalDialog(true, id, ValueElementMixin, mest, "AggregationView",function(changeInValue){
		        	if(changeInValue) {
		        		window.utils.startSpinner('refreshValues','Refreshing ...');
						DataManager.getDataManager().invokeValueUpdates(function(){
							self.showDirectValues(function(){
								self.displayTable();
								window.utils.stopSpinner('refreshValues');	
							})
						});
					}	
	        	}, addOptions);
        	});
		}
    };*/
	init(model,options){
		var self = this;
		self.options = options;
		this.AlternativeInputValuesViewModel = this;
		this.model = model;
		this.id = kb.observable(model, 'id');
		//this.values = ko.observableArray();
		this.valueArray = [];
		this.name = ko.observable(self.model ? self.model.get('name') : ' ');
		this.directValueChanges = [];
		this.phaseAltsHeadings = [];
		this.enableComplete = ko.observable(true);
		this.encodeId = self.htmlEscape(this.id());
		if(options && options.parentView){
			this.parentView = options.parentView;
			this.encodeId = ""+options.modalId;
			this.parentModal = options.parentModal;
			if(!self.model && options.altName){
				self.name(options.altName);
			}
		}
        $('#modal' + self.encodeId).on('shown.bs.modal', function () {
            if (self.viewLoadedCount < 2) {
                self.displayTable();
            }
			self.heightExcludingTable = $('#AlternativeInputValuesView'+self.encodeId).height() - $('#valueTable'+self.encodeId).height();
		});
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),[
		'Close'
		,'Complete'
		,'InputValues']);
	};

	static getInstance(model,options){
		var view = new AlternativeInputValuesViewModel(model,options);
		//view.init(model, options);
		return view;
	};
}
path.AlternativeInputValuesViewModel = AlternativeInputValuesViewModel;