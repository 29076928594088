import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessItemLibraryElement} from './BusinessItemLibraryElement'
import {BusinessItemLibraryElementMixin} from './BusinessItemLibraryElementMixin'
import {PracticeDefinition} from './PracticeDefinition'
import {CapabilityDependency} from './CapabilityDependency'
import {BusinessItemCategory} from './BusinessItemCategory'
import {CapabilityDefinition} from './CapabilityDefinition'
import {EntityReference} from './EntityReference'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessItemLibraryElement","appbo/vdml/BusinessItemLibraryElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessItemLibraryElement,BusinessItemLibraryElementMixin
, PracticeDefinition
, CapabilityDependency
, BusinessItemCategory
, CapabilityDefinition
, EntityReference){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!PracticeDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PracticeDefinition){
			PracticeDefinition = importPath.PracticeDefinition;
		}
		else{
			import('./PracticeDefinition').then(({ default: PracticeDefinition }) => {
                PracticeDefinition = PracticeDefinition;
            });
			/*require(["appbo/vdml/PracticeDefinition"],function(loadedModule){
				PracticeDefinition = loadedModule;
			});*/
		}
	}
	if(!CapabilityDependency){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityDependency){
			CapabilityDependency = importPath.CapabilityDependency;
		}
		else{
			import('./CapabilityDependency').then(({ default: CapabilityDependency }) => {
                CapabilityDependency = CapabilityDependency;
            });
			/*require(["appbo/vdml/CapabilityDependency"],function(loadedModule){
				CapabilityDependency = loadedModule;
			});*/
		}
	}
	if(!BusinessItemCategory){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemCategory){
			BusinessItemCategory = importPath.BusinessItemCategory;
		}
		else{
			import('./BusinessItemCategory').then(({ default: BusinessItemCategory }) => {
                BusinessItemCategory = BusinessItemCategory;
            });
			/*require(["appbo/vdml/BusinessItemCategory"],function(loadedModule){
				BusinessItemCategory = loadedModule;
			});*/
		}
	}
	if(!CapabilityDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityDefinition){
			CapabilityDefinition = importPath.CapabilityDefinition;
		}
		else{
			import('./CapabilityDefinition').then(({ default: CapabilityDefinition }) => {
                CapabilityDefinition = CapabilityDefinition;
            });
			/*require(["appbo/vdml/CapabilityDefinition"],function(loadedModule){
				CapabilityDefinition = loadedModule;
			});*/
		}
	}
	if(!EntityReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.EntityReference){
			EntityReference = importPath.EntityReference;
		}
		else{
			import('./EntityReference').then(({ default: EntityReference }) => {
                EntityReference = EntityReference;
            });
			/*require(["appbo/vdml/EntityReference"],function(loadedModule){
				EntityReference = loadedModule;
			});*/
		}
	}
	export class BusinessItemDefinitionMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.BusinessItemDefinitionMixin = BusinessItemDefinitionMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_BusinessItemDefinition"
		}
		return jQuery.extend(BusinessItemLibraryElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessItemDefinition",
			key:"practiceDefinition",
			relatedModel:"vdml.PracticeDefinition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessItemDefinition",
			key:"capabilityDependency",
			relatedModel:"vdml.CapabilityDependency",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"deliverableDefinition",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessItemDefinition",
			key:"category",
			relatedModel:"vdml.BusinessItemCategory",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessItemDefinition",
			key:"supportedCapability",
			relatedModel:"vdml.CapabilityDefinition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessItemDefinition",
			key:"entityReference",
			relatedModel:"vdml.EntityReference",
			reverseRelation: {
				key:"entityReferenceOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!BusinessItemDefinitionMixin.cummulativeRelations) {
            BusinessItemDefinitionMixin.cummulativeRelations = _.union(BusinessItemDefinitionMixin.getMixinRelations()
                , BusinessItemLibraryElementMixin.getCumulativeMixinRelations()
            );
        }
		return BusinessItemDefinitionMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_BusinessItemLibraryElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "isFungible",type : "EBoolean",defaultValue : "null",containingClass : "vdml_BusinessItemDefinition" },
			{name : "isShareable",type : "EBoolean",defaultValue : "null",containingClass : "vdml_BusinessItemDefinition" },
			//{name : "definition",type : "EString",defaultValue : "null",containingClass : "vdml_BusinessItemDefinition" },
			{name : "biType",type : "EString",defaultValue : "null",containingClass : "vdml_BusinessItemDefinition" }
		]
	}
	getParent(){
		var container;
		return BusinessItemLibraryElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return BusinessItemLibraryElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BusinessItemDefinitionPropertiesTemplate.html",
			templateName : "BusinessItemDefinitionPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BusinessItemDefinitionViewModel",
			tabId : "BusinessItemDefinitionView",
			tabName: "BusinessItemDefinition"
		}
	}
	
	}
	path.BusinessItemDefinitionMixin = BusinessItemDefinitionMixin;
	//return BusinessItemDefinitionMixin;
//});