import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelDimension} from './BusinessModelDimension'
import {NetworkPartnersDimensionMixin} from './NetworkPartnersDimensionMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelDimension","appbo/vdml/NetworkPartnersDimensionMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelDimension,NetworkPartnersDimensionMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var NetworkPartnersDimension = BusinessModelDimension.extend(utils.customExtends({
		relations:NetworkPartnersDimensionMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BusinessModelDimension.prototype.initialize.apply(this, arguments);
		}
		}
		, new NetworkPartnersDimensionMixin()
	));
	
	NetworkPartnersDimension.abstract = false;
	NetworkPartnersDimension.supported = false;
	path.NetworkPartnersDimension = NetworkPartnersDimension;
	export {NetworkPartnersDimension};
//});