import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Capability} from './Capability'
import {CapabilityDefinitionMixin} from './CapabilityDefinitionMixin'
import {CapabilityDependency} from './CapabilityDependency'
import {PracticeDefinition} from './PracticeDefinition'
import {BusinessItemDefinition} from './BusinessItemDefinition'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Capability","appbo/vdml/CapabilityDefinitionMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Capability,CapabilityDefinitionMixin
, CapabilityDependency

, PracticeDefinition
, BusinessItemDefinition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!CapabilityDependency){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityDependency){
			CapabilityDependency = importPath.CapabilityDependency;
		}
		else{
			import('./CapabilityDependency').then(loadedModule => {
				CapabilityDependency = loadedModule;
			});
			/*require(["appbo/vdml/CapabilityDependency"],function(loadedModule){
				CapabilityDependency = loadedModule;
			});*/
		}
	}
	
	if(!PracticeDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PracticeDefinition){
			PracticeDefinition = importPath.PracticeDefinition;
		}
		else{
			import('./PracticeDefinition').then(loadedModule => {
		//		PracticeDefinition = loadedModule;
			});
			/*require(["appbo/vdml/PracticeDefinition"],function(loadedModule){
				PracticeDefinition = loadedModule;
			});*/
		}
	}
	if(!BusinessItemDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemDefinition){
			BusinessItemDefinition = importPath.BusinessItemDefinition;
		}
		else{
			import('./BusinessItemDefinition').then(loadedModule => {
		//		BusinessItemDefinition = loadedModule;
			});
			/*require(["appbo/vdml/BusinessItemDefinition"],function(loadedModule){
				BusinessItemDefinition = loadedModule;
			});*/
		}
	}
	var CapabilityDefinition = Capability.extend(utils.customExtends({
		relations:CapabilityDefinitionMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Capability.prototype.initialize.apply(this, arguments);
		}
		}
		, new CapabilityDefinitionMixin()
	));
	CapabilityDefinition.getInstance = function(name,description,owner){
		var cid = DataManager.getDataManager().guidGeneratorByOwner(owner);
		var CapabilityDef = new CapabilityDefinition({id:cid,name:owner.getUniqueProperty('name',name,'capability'),description:owner.getUniqueProperty('description',description,'capability'),version: owner.get('version'), capabilityOwner:owner});
		owner.get('capability').add(CapabilityDef);
		//CapabilityLiDiagram.set('capabilityLibrary',capabilityLibrary);
		return CapabilityDef;
	};
	CapabilityDefinition.abstract = false;
	CapabilityDefinition.supported = true;
	path.CapabilityDefinition = CapabilityDefinition;
	export {CapabilityDefinition};
//});