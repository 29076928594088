import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurableElement} from './MeasurableElement'
import {MeasurableElementMixin} from './MeasurableElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { InputPort } from './InputPort'
import { BusinessItem } from './BusinessItem'
import { MeasuredCharacteristic } from './MeasuredCharacteristic'
import { OutputPort } from './OutputPort'


/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/MeasurableElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,MeasurableElementMixin
, InputPort
, OutputPort
, BusinessItem
, MeasuredCharacteristic){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!InputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.InputPort){
			InputPort = importPath.InputPort;
		}
        else {
            import('./InputPort').then(({ default: InputPort }) => {
                InputPort = InputPort;
            });
			/*require(["appbo/vdml/InputPort"],function(loadedModule){
				InputPort = loadedModule;
			});*/
		}
	}
	if(!OutputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OutputPort){
			OutputPort = importPath.OutputPort;
		}
        else {
            import('./OutputPort').then(({ default: OutputPort }) => {
                OutputPort = OutputPort;
            });
           /* require(["appbo/vdml/OutputPort"], function (loadedModule) {
                OutputPort = loadedModule;
			});*/
		}
	}
	if(!BusinessItem){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItem){
			BusinessItem = importPath.BusinessItem;
		}
        else {
            import('./BusinessItem').then(({ default: BusinessItem }) => {
                BusinessItem = BusinessItem;
            });
            /*require(["appbo/vdml/BusinessItem"], function (loadedModule) {
                BusinessItem = loadedModule;
			});*/
		}
	}
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
        else {
            import('./MeasuredCharacteristic').then(({ default: MeasuredCharacteristic }) => {
                MeasuredCharacteristic = MeasuredCharacteristic;
            });
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	export class DeliverableFlowMixin {

	defaults(){
		var ret = {
			type: "vdml_DeliverableFlow"
		}
		return jQuery.extend(MeasurableElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"vdml_DeliverableFlow",
			key:"recipient",
			relatedModel:"vdml.InputPort",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"input",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_DeliverableFlow",
			key:"provider",
			relatedModel:"vdml.OutputPort",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"output",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_DeliverableFlow",
			key:"duration",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"durationOwnerFlow",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!DeliverableFlowMixin.cummulativeRelations) {
            DeliverableFlowMixin.cummulativeRelations = _.union(DeliverableFlowMixin.getMixinRelations()
                , MeasurableElementMixin.getCumulativeMixinRelations()
            );
        }
		return DeliverableFlowMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_MeasurableElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "isTangible",type : "EBoolean",defaultValue : "null",containingClass : "vdml_DeliverableFlow" },
			{name : "channel",type : "EString",defaultValue : "null",containingClass : "vdml_DeliverableFlow" },
			{name : "forAutomation",type : "EBoolean",defaultValue : "null",containingClass : "vdml_DeliverableFlow" },
			{name : "showInDiagrams",type : "EBoolean",defaultValue : "null",containingClass : "vdml_DeliverableFlow" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("flowOwner") ? this.get("flowOwner") : this.previousAttributes().flowOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("flowOwner") ? this.get("flowOwner") : this.previousAttributes().flowOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/DeliverableFlowPropertiesTemplate.html",
			templateName : "DeliverableFlowPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/DeliverableFlowViewModel",
			tabId : "DeliverableFlowView",
			tabName: "DeliverableFlow"
		}
	}
	
	copy(providerPort,receipentPort,sourceCollaboration, targetCollaboration,measurementsCopied){
		var flowId = DataManager.getDataManager().guidGeneratorByOwner(targetCollaboration);
		var nameStr;
		var deliverable = this.get('deliverible');
		if(sourceCollaboration === targetCollaboration){
			nameStr = targetCollaboration.getUniqueProperty('name',deliverable + " Flow",'flow');	
		}else{
			nameStr = targetCollaboration.getUniqueProperty('name',this.get('name'),'flow');	
		}
		var copyFlow = new this.constructor({id:flowId,name:nameStr,description:this.get('name'),flowOwner:targetCollaboration});
		var targetDeliverable = targetCollaboration.get('businessItem').findWhere({name:deliverable.get('name')});
		copyFlow.set('deliverable',targetDeliverable);
		copyFlow.set('recipient',receipentPort);
		copyFlow.set('provider',providerPort);
		var mesChars = this.get('measuredCharacteristic');
		mesChars.each(function(mesChar){
			mesChar.copy(copyFlow,measurementsCopied);			
		});		
		var duration = this.get('duration');
		if(duration){
			duration.copy(copyFlow,measurementsCopied);	
		}
		return copyFlow;
	};
	
	}
	path.DeliverableFlowMixin = DeliverableFlowMixin;
	//return DeliverableFlowMixin;
//});