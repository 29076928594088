import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Collaboration} from './Collaboration'
import {CollaborationMixin} from './CollaborationMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { CapabilityOffer } from './CapabilityOffer'
//import { Store } from './Store'
//import { Position } from './Position'
import { CapabilityMethod } from './CapabilityMethod'
import { BusinessModelPortfolio } from './BusinessModelPortfolio'
import { BusinessModel } from './BusinessModel'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Collaboration","appbo/vdml/CollaborationMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Collaboration,CollaborationMixin
, CapabilityOffer
, Store
, Position
, CapabilityMethod
, BusinessModelPortfolio
, BusinessModel){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!CapabilityOffer){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityOffer){
			CapabilityOffer = importPath.CapabilityOffer;
		}
		else{
            import('./CapabilityOffer').then(loadedModule => {
                CapabilityOffer = loadedModule;
			});
			/*require(["appbo/vdml/CapabilityOffer"],function(loadedModule){
				CapabilityOffer = loadedModule;
			});*/
		}
	}
	//if(!Store){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Store){
			//Store = importPath.Store;
		}
		else{
            import('./Store').then(loadedModule => {
                Store = loadedModule;
			});
			/*require(["appbo/vdml/Store"],function(loadedModule){
				Store = loadedModule;
			});*/
		}
	//}
	//if(!Position){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Position){
			//Position = importPath.Position;
		}
		else{
            import('./Position').then(loadedModule => {
              //  Position = loadedModule;
			});
			/*require(["appbo/vdml/Position"],function(loadedModule){
				Position = loadedModule;
			});*/
		}
	//}
	if(!CapabilityMethod){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityMethod){
			CapabilityMethod = importPath.CapabilityMethod;
		}
		else{
            import('./CapabilityMethod').then(loadedModule => {
                CapabilityMethod = loadedModule;
			});
			/*require(["appbo/vdml/CapabilityMethod"],function(loadedModule){
				CapabilityMethod = loadedModule;
			});*/
		}
	}
	if(!BusinessModelPortfolio){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModelPortfolio){
			BusinessModelPortfolio = importPath.BusinessModelPortfolio;
		}
		else{
            import('./BusinessModelPortfolio').then(loadedModule => {
                BusinessModelPortfolio = loadedModule;
			});
			/*require(["appbo/vdml/BusinessModelPortfolio"],function(loadedModule){
				BusinessModelPortfolio = loadedModule;
			});*/
		}
	}
	if(!BusinessModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModel){
			BusinessModel = importPath.BusinessModel;
		}
		else{
            import('./BusinessModel').then(loadedModule => {
        //        BusinessModel = loadedModule;
			});
			/*require(["appbo/vdml/BusinessModel"],function(loadedModule){
				BusinessModel = loadedModule;
			});*/
		}
	}
	export class OrgUnitMixin {
	
	defaults(){
		var ret = {
			type: "vdml_OrgUnit"
		}
		return jQuery.extend(Collaboration.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_OrgUnit",
			key:"capabilityOffer",
			relatedModel:"vdml.CapabilityOffer",
			reverseRelation: {
				key:"capabilityProvider",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_OrgUnit",
			key:"ownedStore",
			relatedModel:"vdml.Store",
			reverseRelation: {
				key:"storeOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_OrgUnit",
			key:"position",
			relatedModel:"vdml.Position",
			reverseRelation: {
				key:"positionOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_OrgUnit",
			key:"ownedMethod",
			relatedModel:"vdml.CapabilityMethod",
			reverseRelation: {
				key:"methodOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_OrgUnit",
			key:"BusinessModelPortfolio",
			relatedModel:"vdml.BusinessModelPortfolio",
			reverseRelation: {
				key:"businessModelPortfolioOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_OrgUnit",
			key:"businessModel",
			relatedModel:"vdml.BusinessModel",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"business",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!OrgUnitMixin.cummulativeRelations) {
            OrgUnitMixin.cummulativeRelations = _.union(OrgUnitMixin.getMixinRelations()
                , CollaborationMixin.getCumulativeMixinRelations()
            );
        }
		return OrgUnitMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_Collaboration"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "progress", type: "double", defaultValue: "0", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "location",type : "EString",defaultValue : "null",containingClass : "vdml_OrgUnit" },
			{name : "color",type : "EString",defaultValue : "null",containingClass : "vdml_OrgUnit" }
		]
	}
	/*OrgUnitMixin.prototype.getNestedParent(){
		var container;
		return Collaboration.prototype.getNestedParent.apply(this, arguments);
	}*/
	getPackagePath(path){
		if(!path){
			path = "";
		}
		return Collaboration.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/OrgUnitPropertiesTemplate.html",
			templateName : "OrgUnitPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/OrgUnitViewModel",
			tabId : "OrgUnitView",
			tabName: "OrgUnit"
		}
	}
//#startCustomMethods	
	getParent(){
		return Collaboration.prototype.getParent.apply(this, arguments);
	}
	createDefaultRole(name){
		var roleId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var role = new Position({id:roleId,name:this.getUniqueProperty('name',name ? name: "Business",'collaborationRole'),collaborationRoleOwner:this});
		return role;
	};	
	createStoreforResource(businessItem,storeType){
		var ownedStores = this.get('ownedStore');
		for(var i=0;i<ownedStores.models.length;i++){
			var ownedStore = ownedStores.at(i);
			if(ownedStore.get('resource') === businessItem){
				return ownedStore;
			}
		}
		var storeId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var store = new storeType({id:storeId,name:businessItem.get('name')+' Store',description:businessItem.get('description')+' Store',storeOwner:this});
		store.set('resource',businessItem);
		return store;
	};
	createCapabilityMethod(name){
		var methodId = DataManager.getDataManager().guidGeneratorByOwner(this);
		return new CapabilityMethod({
			'name':this.getUniqueProperty('name',name,'ownedMethod'),
			'description':name,
			methodOwner:this});		
		
	}
	
	getSubUnits(subUnits){
		if(!subUnits){
			subUnits = new Backbone.Collection();		
		}
		var collRoles = this.get('collaborationRole');
		collRoles.each(function(position){
			var assignments = position.get('roleAssignment');
			assignments.each(function(assignment){
				var participant = assignment.get('participant');
				if(participant && participant.get('type') === "vdml_OrgUnit"){
					if(!subUnits.get(participant.get('id'))){
						participant.getSubUnits(subUnits);
						subUnits.add(participant);	
					}
				}
			});
		});
		return subUnits;
	};
	
	getHierarchy(subUnits){
		if(!subUnits){
			subUnits = new Backbone.Collection();		
		}
		var collRoles = this.get('collaborationRole');
		collRoles.each(function(position){
			var assignments = position.get('roleAssignment');
			assignments.each(function(assignment){
				var participant = assignment.get('participant');
				if(participant){
					var pType = participant.get('type');
					if(pType === "vdml_OrgUnit"){
						if(!subUnits.get(participant.get('id'))){
							subUnits.add(participant);
							participant.getHierarchy(subUnits);
						}
					}
					else if(pType === "vdml_Community"){
						var communityColl = participant.getHierarchy();
						subUnits.add(communityColl.models);
					}
					else{
						subUnits.add(participant);
					}
				}
			});
		});
		return subUnits;
		
	};
	
	getCapabilityMethods(capabilityMethods){
		if(!capabilityMethods){
			capabilityMethods = new Backbone.Collection();
		}
		var subUnits = this.getSubUnits();
		var methods = this.get('ownedMethod');
		capabilityMethods.add(methods.models);
		subUnits.each(function(unit){
			capabilityMethods.add(unit.get('ownedMethod').models);
		});
		return capabilityMethods;
	};	
	
	setOrgUnitNameChange(newName){
		var self = this;
        DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_BusinessModelCustomer-customer', 'vdml_BusinessModelCustomer', "vbc:" + "cmof_EObject-name", 
		function(bmCustomers){	
			bmCustomers.each(function(bmCustomer){
				if(bmCustomer.get('name') !== newName){
					bmCustomer.set('name',newName);
				}
			});
			renameNetworkPartners();
		}, true);
		
		
		function renameNetworkPartners(){
            DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_BusinessModelNetworkPartner-networkPartner', 'vdml_BusinessModelNetworkPartner', "vbc:" + "cmof_EObject-name", 			
			function(bmNetworkPartners){
				bmNetworkPartners.each(function(bmNetworkPartner){
					if(bmNetworkPartner.get('name') !== newName){
						bmNetworkPartner.set('name',newName);
					}
			   });
			}, true);
		}
	}
	
//#endCustomMethods
	
	}
	path.OrgUnitMixin = OrgUnitMixin;
	//return OrgUnitMixin;
//});