import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Port} from './Port'
import {PortMixin} from './PortMixin'
import {MeasuredCharacteristic} from './MeasuredCharacteristic'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { DeliverableFlow } from './DeliverableFlow'
import { ResourceUse } from './ResourceUse'
import { BusinessItemDefinition } from './BusinessItemDefinition'
import { ValueAdd } from './ValueAdd'
import { OutputDelegation } from './OutputDelegation'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Port","appbo/vdml/PortMixin","appbo/vdml/MeasuredCharacteristic"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Port,PortMixin
, DeliverableFlow
, ResourceUse
, BusinessItemDefinition
, ValueAdd
, OutputDelegation

, MeasuredCharacteristic){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!DeliverableFlow){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.DeliverableFlow){
			DeliverableFlow = importPath.DeliverableFlow;
		}
		else{
            import('./DeliverableFlow').then(loadedModule => {
         //       DeliverableFlow = loadedModule;
			});
			/*require(["appbo/vdml/DeliverableFlow"],function(loadedModule){
				DeliverableFlow = loadedModule;
			});*/
		}
	}
	if(!ResourceUse){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ResourceUse){
			ResourceUse = importPath.ResourceUse;
		}
		else{
            import('./ResourceUse').then(loadedModule => {
     //           ResourceUse = loadedModule;
			});
			/*require(["appbo/vdml/ResourceUse"],function(loadedModule){
				ResourceUse = loadedModule;
			});*/
		}
	}
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
		else{
            import('./MeasuredCharacteristic').then(loadedModule => {
                MeasuredCharacteristic = loadedModule;
			});
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	if(!BusinessItemDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemDefinition){
			BusinessItemDefinition = importPath.BusinessItemDefinition;
		}
		else{
            import('./BusinessItemDefinition').then(loadedModule => {
      //          BusinessItemDefinition = loadedModule;
			});
			/*require(["appbo/vdml/BusinessItemDefinition"],function(loadedModule){
				BusinessItemDefinition = loadedModule;
			});*/
		}
	}
	if(!ValueAdd){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueAdd){
			ValueAdd = importPath.ValueAdd;
		}
		else{
            import('./ValueAdd').then(loadedModule => {
                ValueAdd = loadedModule;
			});
			/*require(["appbo/vdml/ValueAdd"],function(loadedModule){
				ValueAdd = loadedModule;
			});*/
		}
	}
	if(!OutputDelegation){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OutputDelegation){
			OutputDelegation = importPath.OutputDelegation;
		}
		else{
            import('./OutputDelegation').then(loadedModule => {
                OutputDelegation = loadedModule;
			});
			/*require(["appbo/vdml/OutputDelegation"],function(loadedModule){
				OutputDelegation = loadedModule;
			});*/
		}
	}
	
	export class OutputPortMixin {

	defaults(){
		var ret = {
			type: "vdml_OutputPort"
		}
		return jQuery.extend(Port.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_OutputPort",
			key:"resourceUse",
			relatedModel:"vdml.ResourceUse",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"deliverable",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_OutputPort",
			key:"outputDefinition",
			relatedModel:"vdml.BusinessItemDefinition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_OutputPort",
			key:"valueAdd",
			relatedModel:"vdml.ValueAdd",
			reverseRelation: {
				key:"valueAddOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_OutputPort",
			key:"delegatedOutput",
			relatedModel:"vdml.OutputDelegation",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"target",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_OutputPort",
			key:"outputDelegation",
			relatedModel:"vdml.OutputDelegation",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"source",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!OutputPortMixin.cummulativeRelations) {
            OutputPortMixin.cummulativeRelations = _.union(OutputPortMixin.getMixinRelations()
                , PortMixin.getCumulativeMixinRelations()
            );
        }
		return OutputPortMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_Port"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "isIntermediate",type : "EBoolean",defaultValue : "null",containingClass : "vdml_Port" }
		]
	}
	getParent(){
		var container;
		return Port.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return Port.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/OutputPortPropertiesTemplate.html",
			templateName : "OutputPortPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/OutputPortViewModel",
			tabId : "OutputPortView",
			tabName: "OutputPort"
		}
	}
//#startCustomMethods
	addValue(bm,viewAlternative,whatValueName,isPrime,value,valueUnit,valFormula,accumulator,baseTuples,maxChange,oldValModel,expression){
		var self = this;
		var outputId;
		if(oldValModel){
			var newValueAddIdSuffix = window.utils.getSuffix(oldValModel.id);
			outputId = DataManager.getDataManager().getRepositoryId(this.id) + newValueAddIdSuffix;
		}else{
			outputId = DataManager.getDataManager().guidGeneratorByOwner(this);	
		}
		var valueAdd = new ValueAdd({id:outputId,name:this.getUniqueProperty('name', whatValueName,'valueAdd'),description:whatValueName,valueAddOwner:this});
		var vdm = bm.getNestedParent();
		vdm.createValueDetails(valueAdd,oldValModel);
		if(baseTuples){
			valueAdd.addBaseTuple(baseTuples);	
		}
		if(isPrime){
			var measurementComponent = valueAdd.primeValueMeasurement(valueAdd.get('valueMeasurement'),valueAdd,value,valueUnit,valFormula,accumulator,baseTuples,null,viewAlternative,maxChange,false,expression);
			var measureLibrary = vdm.createDefaultMeasureLibrary();
			var scenario = viewAlternative.getMainScenario(self.getNestedParent());
			var objObs = scenario.get('objectiveObservation')?scenario.get('objectiveObservation'):scenario.get('contextObservation');
			if(objObs){
				for(var i=0; i < objObs.length; i++){
					var timeStamp = objObs.at(i).get('whenObserved');
					if(timeStamp && isNaN(timeStamp)){
						objObs.at(i).set('triggerUpdate',true);
					}
				}
			}
			valueAdd.addBaseRelationshipsToParents(measureLibrary,measurementComponent,viewAlternative,function(){
				if(baseTuples.length > 0) {
					valueAdd.calculate(scenario);
				}
			});
		}
		return valueAdd;
	};

	copy(targetContainer,sourceCollaboration,targetCollaboration,measurementsCopied,valuesCopied){
		var portId = DataManager.getDataManager().guidGeneratorByOwner(targetContainer);
		var nameStr = targetContainer.getUniqueProperty('name',this.get('name'),'containedPort');
		var copyPort = new this.constructor({id:portId,name:nameStr,description:this.get('name'),containedPortOwner:targetContainer});
		var mesChars = this.get('measuredCharacteristic');
		mesChars.each(function(mesChar){
			mesChar.copy(copyPort,measurementsCopied);			
		});	
		var planingPercentage = this.get('planningPercentage');
		if(planingPercentage){
			planingPercentage.copy(copyPort,measurementsCopied);	
		}
		var offset = this.get('offset');
		if(offset){
			offset.copy(copyPort,measurementsCopied);	
		}		
		var batchSize = this.get('batchSize');
		if(batchSize){
			batchSize.copy(copyPort,measurementsCopied);	
		}	
		var handler = this.get('handler');
		if(handler){
			var targetHandleRole = targetCollaboration.get('collaborationRole').findWhere({name:handler.get('name')});
			if(targetHandleRole){
				copyPort.set('handler',targetHandleRole);
			}
		}
		var condition = this.get('condition');
		if(condition){
			condition.copy(copyPort);
		}
		var exp = this.get('correlationExpression');
		if(exp){
			exp.copy(copyPort);
		}
		copyPort.set('outputDefinition',this.get('outputDefinition'));
		var container = this.get('containedPortOwner');
		if(container.get('type') === 'vdml_Activity' && (sourceCollaboration === targetCollaboration)){
			var delegatedOutputs = this.get('delegatedOutput');
			delegatedOutputs.each(function(delegation){
				delegation.copy(delegation.get('source'),copyPort,targetCollaboration);
			});
		}
		var valueAdds = this.get('valueAdd');
		valueAdds.each(function(valueAdd){
			valueAdd.copy(copyPort,measurementsCopied,valuesCopied);
		});

		return copyPort;
	};
	
	handleOutputPortNameChange(modelRef, newName){
		var self = this;
		var outputDelegations = self.get('outputDelegation');
		outputDelegations.each(function(delegation){
			delegation.set('name',newName + 'Delegation');
			delegation.set('description',newName + 'Delegation');	
			var delTarget = delegation.get('target');
			if(delTarget) {
				delTarget.set('name',newName);
				delTarget.set('description',newName);
			}
		});
	};

//#endCustomMethods	
	
	}
	path.OutputPortMixin = OutputPortMixin;
	//return OutputPortMixin;
//});
