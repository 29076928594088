import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelItem} from './BusinessModelItem'
import {BusinessModelCustomerMixin} from './BusinessModelCustomerMixin'
import {Participant} from './Participant'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelItem","appbo/vdml/BusinessModelCustomerMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelItem,BusinessModelCustomerMixin
, Participant){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Participant){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Participant){
			Participant = importPath.Participant;
		}
		else{
			import('./Participant').then(({ default: Participant }) => {
                Participant = Participant;
            });
			/*require(["appbo/vdml/Participant"],function(loadedModule){
				Participant = loadedModule;
			});*/
		}
	}
	var BusinessModelCustomer = BusinessModelItem.extend(utils.customExtends({
		relations:BusinessModelCustomerMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BusinessModelItem.prototype.initialize.apply(this, arguments);
		}
		}
		, new BusinessModelCustomerMixin()
	));
	
	BusinessModelCustomer.abstract = false;
	path.BusinessModelCustomer = BusinessModelCustomer;
	export {BusinessModelCustomer};
//});