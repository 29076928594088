import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
var path = DataManager.getDataManager().buildAppNsPath("tickets", global.version);

var path = DataManager.getDataManager().buildAppNsPath("tickets", global.version);

export class SupportViewModel{
    constructor(model, options, detailedView) {
        window.utils.startSpinner('SupportSpinner', 'Loading Support data...');
        var self = this;
        this.SupportViewModel = this;
        this.email = ko.observable();
        this.errorMessage = ko.observable();
        this.stackTraceInfo = ko.observable();
        this.lineNumber = ko.observable();
        this.columnNumber = ko.observable();
        this.createdDate1 = ko.observable();
        this.scriptURL = ko.observable();
        this.errorStatus = ko.observable("false");
        this.enableSupportUpdate = ko.observable(false);
        this.enableSupportCancel = ko.observable(false);
        this.clearSupportHistory = ko.observable(true);
        this.enableDelete = ko.observable(false);
        this.encodeId = "" + options.modalId;
        this.errorLogHistoryArr = [];
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'Close'
            , 'update'
            , 'Cancel'
            , 'Delete'
            , 'Progress'
            , 'Details'
            , 'Collaboration'
            , 'Guidance'
        ]);
        this.afterRenderView = _.bind(this.afterRenderView,this);
    }

    setToDefault() {
        var self = this;
        self.email("");
        self.createdDate1("");
        self.errorMessage("");
        self.stackTraceInfo("");
        self.scriptURL("");
        self.lineNumber("");
        self.columnNumber("");
        self.errorStatus("false");
        self.enableSupportUpdate(false);
        self.enableSupportCancel(false);
        self.clearSupportHistory(true);
        self.enableDelete(false);
        $('#stackTraceTable tr').removeClass('selected');
    } 

    setBreadCrumb(breadCrumbItems) {
        var self = this;
        $('#breadcrumb').addClass('breadcrumb');
        breadCrumbItems.push('<li><a style="font-size: x-large;" href="#adminviews/Support">' + 'Support' + '</a></li>');
    };

    /*convertToSimpleDate(dateFormat) {
        var self = this;
        if (dateFormat == null)
            return null;
        var newDate = new Date(dateFormat);
        var month = String(newDate.getMonth() + 1).length > 1 ? newDate.getMonth() + 1 : '0' + (newDate.getMonth() + 1);
        var date = String(newDate.getDate()).length > 1 ? newDate.getDate() : '0' + newDate.getDate();
        var hours = String(newDate.getHours()).length < 2 ? ('0' + newDate.getHours()) : newDate.getHours();
        var minutes = String(newDate.getMinutes()).length < 2 ? ('0' + newDate.getMinutes()) : newDate.getMinutes();
        var seconds = String(newDate.getSeconds()).length < 2 ? ('0' + newDate.getSeconds()) : newDate.getSeconds();
        var time = hours + ":" + minutes + ":" + seconds;
        return date + '-' + month + '-' + newDate.getFullYear() + ' ' + time;
    };*/

    isAdminView() {
        return true;
    };

        
    getErrorLogHistory() {
        var self = this;
        var request = new XMLHttpRequest();
        request.onload = function (data) {
            if (request.response) {
                self.errorLogHistoryArr = [];
                window.utils.stopSpinner('supportSpinner');
                var data = JSON.parse(request.response);
                for (var i = 0; i < data.length; i++) {
                    if (data[i].createdDate == null) {
                        data[i].createdDate= null;
                    } else {
                        var newDate = new Date(data[i].createdDate);
                        var month = (newDate.getMonth()) >= 9 ? newDate.getMonth() + 1 : '0' + (newDate.getMonth() + 1);
                        var date = String(newDate.getDate()).length > 1 ? newDate.getDate() : '0' + newDate.getDate();
                        var hours = String(newDate.getHours()).length < 2 ? ('0' + newDate.getHours()) : newDate.getHours();
                        var minutes = String(newDate.getMinutes()).length < 2 ? ('0' + newDate.getMinutes()) : newDate.getMinutes();
                        var seconds = String(newDate.getSeconds()).length < 2 ? ('0' + newDate.getSeconds()) : newDate.getSeconds();
                        var time = hours + ":" + minutes + ":" + seconds;
                        data[i].createdDate = date + '-' + month + '-' + newDate.getFullYear() + ' ' + time;
                    }
                    self.errorLogHistoryArr.push({
                        id: data[i].id,
                        Email: data[i].email,
                        ErrorMessage: data[i].errorMessage,
                        ScriptUrl: data[i].scriptUrl,
                        CreatedDate1: data[i].createdDate,
                        LineNumber: data[i].lineNumber,
                        ColumnNumber: data[i].columnNumber,
                        Status: data[i].status,
                        StackTraceInfo: data[i].stackTraceInfo
                    });
                }
                //self.createDataTableViaAjax();
                var err = document.getElementById('stackTraceTable');
                if (self.selectedId != "") {
                        self.createDataTableViaAjax();
                } else {
                    $('#stackTraceTable').DataTable().destroy();
                    self.createDataTableViaAjax();
                }
            }
        };
        request.onerror = function (data) {
            if (this.status == 0 || this.status == "404") {
                callback(true);
            }
        };
        request.open("GET", DataManager.getDataManager().get('vmpServerHost') + '/invitation/errorloghistory', true);
        request.setRequestHeader("Content-Type", "application/json", true);
        request.send();
    }

    createDataTableViaAjax() {
        var self = this;
        var table = $('#stackTraceTable').DataTable({
            data: self.errorLogHistoryArr,
            select: true,
            scrollX: true, // Replaces "scrollX": 200
            order: [[4, "asc"]],
            columns: [ // Replaces "aoColumns"
                { data: 'id', orderable: true, title: "ID" },
                { data: 'Email', orderable: true, title: "Email" },
                { data: 'ErrorMessage', orderable: true, title: "Error Message" },
                { data: 'ScriptUrl', orderable: true, title: "Script URL" },
                { data: 'CreatedDate1', orderable: true, title: "Created Date" },
                { data: 'LineNumber', orderable: true, title: "Line Number" },
                { data: 'ColumnNumber', orderable: true, title: "Column Number" },
                { 
                    data: 'Status', 
                    orderable: true, 
                    title: "Status", 
                    render: function (data) { // Replaces "mRender"
                        return data === "true" ? "Closed" : "Open";
                    }
                },
                { data: 'StackTraceInfo', orderable: true, title: "Stack Trace Info" }
            ],
            columnDefs: [ // Replaces "columnDefs"
                {
                    targets: [0],
                    visible: false,
                    searchable: false
                },
                {
                    targets: [8],
                    visible: false,
                    searchable: false
                }
            ],
            createdRow: function (row, data) { // Replaces "fnCreatedRow"
                $(row).attr('id', data.id);
                $(row).attr('data-email', data.Email);
                $(row).attr('data-errorMessage', data.ErrorMessage);
                $(row).attr('data-scriptUrl', data.ScriptUrl);
                $(row).attr('data-createdDate1', data.CreatedDate1);
                $(row).attr('data-lineNumber', data.LineNumber);
                $(row).attr('data-columnNumber', data.ColumnNumber);
                $(row).attr('data-status', data.Status);
                $(row).attr('data-stackTraceInfo', data.StackTraceInfo);
            },
            drawCallback: function (settings) { // Replaces "fnDrawCallback"
                self.setResizeHeightHandler();
            }
        });        
    };

    afterRenderView() {
        var self = this;
        $(window).bind("resize", function (event) {
            if (this == event.target) {
                $('.sp').removeAttr('style');
            }
            if (self.setResizeHeightHandler) {
                self.setResizeHeightHandler();
            }
        });
        $('#stackTraceTable').on('click', 'tr', function () {
            if (this.parentElement.tagName == 'TBODY' && this.textContent != "No data available in table") {
                if ($(this).hasClass('selected')) {
                    $(this).removeClass('selected');
                    self.selectedId = '';
                    self.setToDefault();
                }
                else {
                    $('tr.selected').removeClass('selected');
                    $(this).addClass('selected');
                    self.selectedId = this.id;
                    self.email(this.dataset.email);
                    self.errorMessage(this.dataset.errormessage);
                    self.stackTraceInfo(this.dataset.stacktraceinfo);
                    self.scriptURL(this.dataset.scripturl);
                    self.createdDate1(this.dataset.createddate1);
                    self.lineNumber(this.dataset.linenumber);
                    self.columnNumber(this.dataset.columnnumber);
                    self.errorStatus(this.dataset.status);
                    self.enableSupportUpdate(true);
                    self.enableSupportCancel(true);
                    self.enableDelete(true);
                    self.clearSupportHistory(false);
                }
            }
        });
        self.getErrorLogHistory();
        window.utils.stopSpinner('SupportSpinner');
        var windowHeight = window.innerHeight - $('.navbar').outerHeight();
        var propertiesNode = $('.propertiesArea');
        var supportNode = $('#maintainSupport');
        var tabNode = $('.tab-content');
        var adminAreaNode = $('.adminArea');
        var baseHeight = _.max([windowHeight, adminAreaNode.outerHeight(), tabNode.outerHeight(), propertiesNode.outerHeight(), supportNode.outerHeight()]);
        //self.spliter = $("#splitPanels").enhsplitter({ minSize: 2, onDrag: window.utils.splitterBoundary, collapse: 'right', position: '56%', handle: 'block', splitterSize: '9px', height: baseHeight, onDragEnd: self.setResizeHeight });
        //self.spliter.enhsplitter('collapse');
    };

    updateStatus(selfLink) {
        var self = this;
        var params = {};
        params.id = self.selectedId;
        params.email = self.email();
        params.errorMessage = self.errorMessage();
        params.scriptUrl = self.scriptURL();
        params.createdDate1 = new Date();
        params.lineNumber = self.lineNumber();
        params.columnNumber = self.columnNumber();
        params.status = self.errorStatus();
        params.stackTraceInfo = self.stackTraceInfo();
        var spinnerText = "Updating Status...";
        if (self.selectedAuditId) {
            spinnerText = "Updating Status..."
        }
        window.setTimeout(function () {
            window.utils.startSpinner('updateSpinner', spinnerText);
            var request = new XMLHttpRequest();
            request.onload = function (data) {
                if (request.response) {
                    self.getErrorLogHistory();
                    self.setToDefault();
                    window.utils.stopSpinner('updateSpinner');
                }
            };
            request.onerror = function (data) {
                if (this.status == 0 || this.status == "404") {
                    callback(true);
                }
            };
            request.open("POST", DataManager.getDataManager().get('vmpServerHost') + '/invitation/updateerrorloghistory', true);
            request.setRequestHeader("Content-Type", "application/json", true);
            request.send(JSON.stringify(params));
        }, 100)

    };   

    deleteSupport() {
        var self = this;
        bootbox.confirm(DataManager.getDataManager().get('localeManager').get('DeleteAlert'), function (deleteOk) {
            if (deleteOk && self.selectedId) {
                window.setTimeout(function () {
                    window.utils.startSpinner('deleteSpinner', 'Deleting support...');
                    var request = new XMLHttpRequest();
                    request.onload = function (data) {
                        if (request.response) {
                            self.getErrorLogHistory();
                            self.setToDefault();  
                            window.utils.stopSpinner('deleteSpinner');
                        }
                    };
                    request.onerror = function (data) {
                        if (this.status == 0 || this.status == "404") {
                            callback(true);
                        }
                    };
                    request.open("DELETE", DataManager.getDataManager().get('vmpServerHost') + '/invitation/deleteloghistory' + "?id=" + self.selectedId, true);
                    request.setRequestHeader("Content-Type", "application/json", true);
                    request.send();
                }, 100);
            }
        });
    };
    cleanModalDialog() {
        var self = this;
        self.spliter.enhsplitter('remove');
        $(window, ".resize").unbind('resize');
        window.cleanDialogModel(options.modalId, self);
    }
    setResizeHeightHandler() {
        var self = this;
        setTimeout(function () {
            if (self.spliter) {
                var windowHeight = window.innerHeight - $('.navbar').outerHeight();
                var propertiesNode = $('.propertiesArea');
                var supportNode = $('#maintainSupport');
                var tabNode = $('.tab-content');
                var adminAreaNode = $('.adminArea');
                var baseHeight = _.max([windowHeight, adminAreaNode.outerHeight(), tabNode.outerHeight(), supportNode.outerHeight(), propertiesNode.outerHeight()]);
                //self.spliter.css('height', (baseHeight) + 'px');
                //$("#splitPanels").enhsplitter('refresh');
            }
        }, 150)
    };

    
    static getInstance(model, options, detailedView) {
        return new SupportViewModel(model, options, detailedView);
    };
}
path.SupportViewModel = SupportViewModel;
