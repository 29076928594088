import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurableElement} from './MeasurableElement'
import {MeasurableElementMixin} from './MeasurableElementMixin'
import {VDMLCanvasElementMixin} from './VDMLCanvasElementMixin'
import {Role} from './Role'
import {ValuePropositionComponent} from './ValuePropositionComponent'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","async","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/MeasurableElementMixin","appbo/vdml/VDMLCanvasElementMixin"],
function(require,jQuery,async,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,MeasurableElementMixin,VDMLCanvasElementMixin
, ValuePropositionComponent
, Role
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ValuePropositionComponent){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValuePropositionComponent){
			ValuePropositionComponent = importPath.ValuePropositionComponent;
		}
		else{
			import('./ValuePropositionComponent').then(loadedModule => {
				ValuePropositionComponent = loadedModule;
			});
			/*require(["appbo/vdml/ValuePropositionComponent"],function(loadedModule){
				ValuePropositionComponent = loadedModule;
			});*/
		}
	}
	if(!Role){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Role){
			Role = importPath.Role;
		}
		else{
			import('./Role').then(loadedModule => {
				Role = loadedModule;
			});
			/*require(["appbo/vdml/Role"],function(loadedModule){
				Role = loadedModule;
			});*/
		}
	}
	
	export class ValuePropositionMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.ValuePropositionMixin = ValuePropositionMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_ValueProposition"
		}
		return jQuery.extend(MeasurableElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_ValueProposition",
			key:"component",
			relatedModel:"vdml.ValuePropositionComponent",
			reverseRelation: {
				key:"componentOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_ValueProposition",
			key:"overAllSatisfaction",
			relatedModel:"vdml.ValuePropositionComponent",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!ValuePropositionMixin.cummulativeRelations) {
            ValuePropositionMixin.cummulativeRelations = _.union(ValuePropositionMixin.getMixinRelations()
                ,MeasurableElementMixin.getCumulativeMixinRelations()
		        ,VDMLCanvasElementMixin.getCumulativeMixinRelations()
            );
        }
		return ValuePropositionMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_MeasurableElement",
			"vdml_VDMLCanvasElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "progress", type: "double", defaultValue: "0", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "valuePropositionSheet",type : "EString",defaultValue : "null",containingClass : "vdml_ValueProposition" },
			{name : "isValueFormula",type : "EBoolean",defaultValue : "null",containingClass : "vdml_ValueProposition" }
		]
    }
    calculateProgress() {

    }
	getParent(){
		var container;
		if(!container){
			container = this.get("provider") ? this.get("provider") : this.previousAttributes().provider;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("planCriterionSetOwner") ? this.get("planCriterionSetOwner") : this.previousAttributes().planCriterionSetOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("planCriterionSetOwner") ? this.get("planCriterionSetOwner") : this.previousAttributes().planCriterionSetOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("provider") ? this.get("provider") : this.previousAttributes().provider;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ValuePropositionPropertiesTemplate.html",
			templateName : "ValuePropositionPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ValuePropositionViewModel",
			tabId : "ValuePropositionView",
			tabName: "ValueProposition"
		}
	}
//#startCustomMethods	
	createValuePropositionComponent(nameValue,descriptionValue, isOVS,vpcSuffix){
		var vpcId;
		if(vpcSuffix){
			vpcId = DataManager.getDataManager().getRepositoryId(this.id) + vpcSuffix;
		}else{
			vpcId = DataManager.getDataManager().guidGeneratorByOwner(this);
		}
		var component = new ValuePropositionComponent({id:vpcId,name:this.getUniqueProperty('name',nameValue,'component'),description:descriptionValue,componentOwner:this});	
		if(isOVS){
			this.set('overAllSatisfaction',component);
		}
		return component;
	};

	deleteValue(values){
		var self = this;
		if(values.length > 0) {
			_.each(values,function(valueId){
					var components = self.get('component');
					var valueModal = components.findWhere({id:valueId});
					if(valueModal) {
	                	valueModal.destroy();
					}
			});
		}		
	};
	
	handleVPNameChange(modelRef, newName){
		if(Backbone.skipPersistance){
			return;
		}
		var self = this;
		if(self.getNestedParent().get('type') === "vdml_ValueDeliveryModel"){
            DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_BusinessModelValueProposition-valueProposition', 'vdml_BusinessModelValueProposition', "vbc:" + "cmof_EObject-name", 
			function(bmVPs){	
				bmVPs.each(function(bmVP){
					if(bmVP.get('name') !== newName){
						bmVP.set('name',newName);
					}
				});
			}, true);
		}
	}
	copy(targetRole,measurementsCopied,valuesCopied){
		var vpId = DataManager.getDataManager().guidGeneratorByOwner(targetRole);
		var sourceRole = this.get('provider');
		var nameStr;
		if(sourceRole === targetRole){
			nameStr = targetRole.getUniqueProperty('name','Copy of ' + this.get('name'),'providedProposition');
		}else{
			nameStr = targetRole.getUniqueProperty('name',this.get('name'),'providedProposition');
		}
		var copyVP = new this.constructor({id:vpId,name:nameStr,description:this.get('description'), provider:targetRole});		
		var mesChars = this.get('measuredCharacteristic');
		mesChars.each(function(mesChar){
			mesChar.copy(copyVP,measurementsCopied);			
		});	
		var rec = this.get('recipient');
		var targetColl = targetRole.get('collaborationRoleOwner');
		if(targetColl ===  sourceRole.get('collaborationRoleOwner')){
			var recRole = targetColl.get('collaborationRole').findWhere({name:rec.get('name')});
			copyVP.set('recipient',recRole);
		}else{
			copyVP.set('recipient',rec);
		}
		var valuePropComps = this.get('component');
		valuePropComps.each(function(comp){
			comp.copy(copyVP,measurementsCopied,valuesCopied);
		});
		return copyVP;
	}

	canConnectToTargetInEcoMap(sourceElement, targetElement, connection) {debugger
		var self = this;
		var recepient = self.get('recipient');
		if(targetElement.businessObject.$type === 'vdml:Enterprise' || targetElement.businessObject.$type === 'vdml:MarketSegment' || targetElement.businessObject.$type === 'vdml:Individual'){
			if(recepient && recepient.get('roleAssignment').at(0).get('participant').id === targetElement.businessObject.get('mid')) {
				return true;
			}
		}else if(targetElement.businessObject.$type === 'vdml:Role'){
			if(recepient && recepient.id === targetElement.businessObject.get('mid')) {
				return true;
			}
		}else if(targetElement.businessObject.$type === 'vdml:BusinessModel'){
			var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: targetElement.businessObject.get('mid') });
			if(bm && recepient && bm.get('bmRole').findWhere({'id':recepient.id}) && bm.get('bmRole').findWhere({'id':self.get('provider').id})) {
				return true;
			}
		}
	    return false;
	};

	onUpdateEcoMapConnection(e) {
	    debugger;
	    var self = this;
	    var context = e.context;
	    var targetTypePath;
	    if (e.command === "connection.create") {
	        if (e.context.target.businessObject.$type === "vdml:ValueProposition") {
	            targetTypePath = "appbo/vdml/ValueProposition";
	        } else if (e.context.target.businessObject.$type === "vdml:Role") {
	            targetTypePath = "appbo/vdml/Role";
	        } else if (e.context.target.businessObject.$type === "vdml:MarketSegment") {
	            targetTypePath = "appbo/vdml/Community";
	        } else if (e.context.target.businessObject.$type === "vdml:Enterprise") {
	            targetTypePath = "appbo/vdml/OrgUnit";
	        } else if (e.context.target.businessObject.$type === "vdml:Individual") {
	            targetTypePath = "appbo/vdml/Actor";
	        } else if (e.context.target.businessObject.$type === "vdml:BusinessModel") {
	            targetTypePath = "appbo/vdml/BusinessModel";
	        }
	        var dataManager = DataManager.getDataManager();
	        var docId = e.context.target.businessObject.get('mid');
	        var parentId = e.context.target.businessObject.get('mpid');
	        var parentAltId = dataManager.getRepositoryId(parentId);
	        var vdmStore = dataManager.getVDMStore(parentAltId);
	        dataManager.fetchDocumentFromPackage(parentId, "appbo/vdml/ValueDeliveryModel", dataManager.get('currentVDMVersion'), docId, targetTypePath, vdmStore, {
	            success: function (target) {debugger
					if(target.get('type') === "vdml_BusinessModel" && !target.get('bmValueProposition').findWhere({'valueProposition':self})){
						target.addBMValueProposition(self,self.get('name'),self.get('provider'),self.get('recipient'),self.get('description'),context,function(){
							
						});
					}
	            },
	            error: function () {
	            }
	        });
	    }
	}
	
//#endCustomMethods	
	
	}
	path.ValuePropositionMixin = ValuePropositionMixin;
	//return ValuePropositionMixin;
//});