import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {CapabilityLibraryMixin} from './CapabilityLibraryMixin'
import {Capability} from './Capability'
import {CapabilityDependency} from './CapabilityDependency'
import {CapabilityLibraryDiagram} from './CapabilityLibraryDiagram'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/CapabilityLibraryMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,CapabilityLibraryMixin
, Capability
, CapabilityDependency
, CapabilityLibraryDiagram){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Capability){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Capability){
			Capability = importPath.Capability;
		}
		else{
			import('./Capability').then(loadedModule => {
			//	Capability = loadedModule;
			});
			/*require(["appbo/vdml/Capability"],function(loadedModule){
				Capability = loadedModule;
			});*/
		}
	}
	if(!CapabilityDependency){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityDependency){
			CapabilityDependency = importPath.CapabilityDependency;
		}
		else{
			import('./CapabilityDependency').then(loadedModule => {
			//	CapabilityDependency = loadedModule;
			});
			/*require(["appbo/vdml/CapabilityDependency"],function(loadedModule){
				CapabilityDependency = loadedModule;
			});*/
		}
	}
	if(!CapabilityLibraryDiagram){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityLibraryDiagram){
			CapabilityLibraryDiagram = importPath.CapabilityLibraryDiagram;
		}
		else{
			import('./CapabilityLibraryDiagram').then(loadedModule => {
			//	CapabilityLibraryDiagram = loadedModule;
			});
			/*require(["appbo/vdml/CapabilityLibraryDiagram"],function(loadedModule){
				CapabilityLibraryDiagram = loadedModule;
			});*/
		}
	}
	var CapabilityLibrary = VdmlElement.extend(utils.customExtends({
		relations:CapabilityLibraryMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			if (this.isNew()) {
				this.set('version', DataManager.getDataManager().get('currentVDMVersion'));
			}	
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new CapabilityLibraryMixin()
	));
	CapabilityLibrary.getName = function () {
		return "CapabilityLibrary";
	};
	CapabilityLibrary.prototype.getSystemTags = function () {
		return ['VDM', 'Capability Library', 'Admin'];
	};
	CapabilityLibrary.getInstance = function(name,description,vdm,id){
		var clId = id?id:DataManager.getDataManager().guidGeneratorByOwner(vdm);
	    var cl = new CapabilityLibrary({'id':clId,
	    	'name':name,
	    	'description':description,
			'version': vdm.get('version'),
			'capabilityLibraryOwner':vdm
		});
	    vdm.get('capabilityLibrary').add(cl);
		return cl;
	};
	CapabilityLibrary.abstract = false;
	CapabilityLibrary.supported = true ;
	path.CapabilityLibrary = CapabilityLibrary;
	export {CapabilityLibrary};
//});