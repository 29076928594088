import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelItem} from './BusinessModelItem'
import {BusinessModelValuePropositionMixin} from './BusinessModelValuePropositionMixin'
import {ValueProposition} from './ValueProposition'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelItem","appbo/vdml/BusinessModelValuePropositionMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelItem,BusinessModelValuePropositionMixin
, ValueProposition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ValueProposition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueProposition){
			ValueProposition = importPath.ValueProposition;
		}
		else{
			import('./ValueProposition').then(({ default: ValueProposition }) => {
                ValueProposition = ValueProposition;
            });
			/*require(["appbo/vdml/ValueProposition"],function(loadedModule){
				ValueProposition = loadedModule;
			});*/
		}
	}
	var BusinessModelValueProposition = BusinessModelItem.extend(utils.customExtends({
		relations:BusinessModelValuePropositionMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BusinessModelItem.prototype.initialize.apply(this, arguments);
		}
		}
		, new BusinessModelValuePropositionMixin()
	));
	
	BusinessModelValueProposition.abstract = false;
	path.BusinessModelValueProposition = BusinessModelValueProposition;
	export {BusinessModelValueProposition};
//});