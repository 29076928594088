import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessIndicatorLibraryElement} from './BusinessIndicatorLibraryElement'
import {BusinessIndicatorCategoryMixin} from './BusinessIndicatorCategoryMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { BusinessIndicatorDefinition } from './BusinessIndicatorDefinition'

/*define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/smm/BusinessIndicatorLibraryElement", "appbo/smm/BusinessIndicatorCategoryMixin"],
    function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, BusinessIndicatorLibraryElement, BusinessIndicatorCategoryMixin
        , BusinessIndicatorDefinition) {*/

        var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
        if (!BusinessIndicatorDefinition) {
            var importPath = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            if (importPath.BusinessIndicatorDefinition) {
                BusinessIndicatorDefinition = importPath.BusinessIndicatorDefinition;
            }
            else {
                import('./BusinessIndicatorDefinition').then(({ default: BusinessIndicatorDefinition }) => {
                    BusinessIndicatorDefinition = BusinessIndicatorDefinition;
                });

                /*require(["appbo/smm/BusinessIndicatorDefinition"], function (loadedModule) {
                    BusinessIndicatorDefinition = loadedModule;
                });*/
            }
        }
        var BusinessIndicatorCategory = BusinessIndicatorLibraryElement.extend(utils.customExtends({
            relations: BusinessIndicatorCategoryMixin.getMixinRelations(),
            initialize: function (attributes, options) {
                BusinessIndicatorLibraryElement.prototype.initialize.apply(this, arguments);
            }
        }
            , new BusinessIndicatorCategoryMixin()
        ));
		BusinessIndicatorCategory.getInstance = function(name,description,owner){
            var BusinessIndicatorid = DataManager.getDataManager().guidGeneratorByOwner(owner);
            var BusinessIndicatorCat = new BusinessIndicatorCategory({ id: BusinessIndicatorid, name: name, description: description, version: owner.get('version'), businessIndicatorLibraryElementOwner:owner});
            owner.get('element').add(BusinessIndicatorCat);
            return BusinessIndicatorCat;
		};
        var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
        BusinessIndicatorCategory.abstract = false;
        BusinessIndicatorCategory.supported = !sp2Enabled;
        path.BusinessIndicatorCategory = BusinessIndicatorCategory;
        export {BusinessIndicatorCategory};
    //});