import * as _ from 'underscore'
import { OrgUnitMixin } from "./OrgUnitMixin";
import {DataManager} from '../../../com/vbee/data/DataManager'
import {CollaborationMixin} from './CollaborationMixin'
import {ValueProposition} from './ValueProposition'

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

export class OrgUnit2Mixin {
    static getMixinRelations(){
        var ret = _.union(OrgUnitMixin.getMixinRelations(),[{
            type :Backbone.HasOne,
            containingClass:"vdml_OrgUnit",
            key:"fininancialStatementContainer",
            relatedModel:"vdml.ValueProposition",
            includeInJSON: Backbone.Model.prototype.idAttribute,
            reverseRelation: {
				key:"finiancialStatementOrgUnit",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
        }]);
        return ret;
    }
    static getCumulativeMixinRelations(){
        if (!OrgUnit2Mixin.cummulativeRelations) {
            OrgUnit2Mixin.cummulativeRelations = _.union(OrgUnit2Mixin.getMixinRelations()
                ,CollaborationMixin.getCumulativeMixinRelations()
            );
        }
        return OrgUnit2Mixin.cummulativeRelations.slice();
    }
}
utils.customExtendClass (OrgUnit2Mixin,new OrgUnitMixin());

path.OrgUnit2Mixin = OrgUnit2Mixin;