import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {ValueStreamMixin} from './ValueStreamMixin'
import {Activity} from './Activity'
import {ValueAdd} from './ValueAdd'
import {ValueProposition} from './ValueProposition'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/ValueStreamMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,ValueStreamMixin, Activity
, ValueAdd
, ValueProposition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Activity){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Activity){
			Activity = importPath.Activity;
		}
		else{
			import('./Activity').then(loadedModule => {
				Activity = loadedModule;
			});
			/*require(["appbo/vdml/Activity"],function(loadedModule){
				Activity = loadedModule;
			});*/
		}
	}
	if(!ValueAdd){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueAdd){
			ValueAdd = importPath.ValueAdd;
		}
		else{
			import('./ValueAdd').then(loadedModule => {
				ValueAdd = loadedModule;
			});
			/*require(["appbo/vdml/ValueAdd"],function(loadedModule){
				ValueAdd = loadedModule;
			});*/
		}
	}
	if(!ValueProposition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueProposition){
			ValueProposition = importPath.ValueProposition;
		}
		else{
			import('./ValueProposition').then(loadedModule => {
		//		ValueProposition = loadedModule;
			});
			/*require(["appbo/vdml/ValueProposition"],function(loadedModule){
				ValueProposition = loadedModule;
			});*/
		}
	}
	var ValueStream = VdmlElement.extend(utils.customExtends({
		relations:ValueStreamMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
			/*this.on("remove:activityValue",this.removeActivityValue);
			this.on('remove:valueStreamActivity',this.removeActivity);*/
		}
		}
		, new ValueStreamMixin()
	));
    ValueStream["remove:activityValue"] = function (actValue) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.removeActivityValue(actValue);
		}
    }
    ValueStream['remove:valueStreamActivity'] = function (activity) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.removeActivity(activity);
		}
    }
	ValueStream.abstract = false;
	path.ValueStream = ValueStream;
	export {ValueStream};
//});