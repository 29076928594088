import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {PortDelegation} from './PortDelegation'
import {OutputDelegationMixin} from './OutputDelegationMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { OutputPort } from './OutputPort'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/PortDelegation","appbo/vdml/OutputDelegationMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,PortDelegation,OutputDelegationMixin
, OutputPort
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!OutputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OutputPort){
			OutputPort = importPath.OutputPort;
		}
		else{
            import('./OutputPort').then(({ default: OutputPort }) => {
                OutputPort = OutputPort;
            });
			/*require(["appbo/vdml/OutputPort"],function(loadedModule){
				OutputPort = loadedModule;
			});*/
		}
	}
	
	var OutputDelegation = PortDelegation.extend(utils.customExtends({
		relations:OutputDelegationMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			PortDelegation.prototype.initialize.apply(this, arguments);
		}
		}
		, new OutputDelegationMixin()
	));
	
	OutputDelegation.abstract = false;
	path.OutputDelegation = OutputDelegation;
	export {OutputDelegation};
//});