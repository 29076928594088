import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {StrategyMapValuesChart} from '../../../strategyMap/views/designer/StrategyMapValuesChart'
import {BMGuidanceViewModel} from '../../../help/views/properties/BMGuidanceViewModel'
import {EcoMapDetailsViewModel} from './EcoMapDetailsViewModel'
import {EcoMapDiagramMixin} from '../../../../../version1/bo/vdml/EcoMapDiagramMixin'
import {BusinessModelMixin} from '../../../../../version1/bo/vdml/BusinessModelMixin'
import {EcoMapMixin} from '../../../../../version1/bo/ecomap/EcoMapMixin'
import {Community} from '../../../../../version1/bo/vdml/Community'
import {OrgUnit} from '../../../../../version1/bo/vdml/OrgUnit'
import {Actor} from '../../../../../version1/bo/vdml/Actor'
import {ValueDeliveryModel} from '../../../../../version1/bo/vdml/ValueDeliveryModel'
import * as EcoNavigatedViewer from '../../../../../../libs/bower-vdml-js/dist/vdml-navigated-viewer.min'
import * as EcoModeler from '../../../../../../libs/bower-vdml-js/dist/vdml-modeler.development'
import * as Highcharts from 'highcharts';
import { Suggestion } from '../../../../bo/vdml/Suggestion'
//import theme from 'highcharts/themes/dark-unica';
//theme(Highcharts);
import {HighChartThemes} from '../../../../../com/vbee/utils/HighChartThemes'
/*define(["require", "jquery", "underscore", "backbone", "knockout","async", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/BusinessModel", "appbo/vdml/Participant", "appbo/vdml/ValueElement", "appbo/vdml/EcoMapDiagram", "appbo/vdml/EcoMapDiagramMixin", "knockback", "ecomodeler", "econavigatedviewer", "appviews/ecoMap/views/designer/EcoMapDetailsViewModel", "appviews/help/views/properties/BMGuidanceViewModel", "appbo/beeppackage/CodeContainer", "appviews/strategyMap/views/designer/StrategyMapValuesChart","appcommon/com/vbee/utils/HighChartThemes", "appbo/ecomap/EcoMapMixin"],
function (require, $, _, Backbone, ko,async, bootbox, DataManager, global, BusinessModel, Participant, ValueElement, EcoMapDiagram, EcoMapDiagramMixin, kb, EcoModeler, EcoNavigatedViewer, EcoMapDetailsViewModel, BMGuidanceViewModel, CodeContainer, StrategyMapValuesChart, HighChartThemes, EcomapMixin) {*/
    var path = DataManager.getDataManager().buildAppNsPath("ecoMap.views.designer", global.version);
	export class EcoMapDesignerViewModel {
		constructor(model,options){
			var self = this;
			this.init(model, options);
		}
    init(model, options) {
        var self = this;
		self.afterRenderView = _.bind(self.afterRenderView,self);
        this.EcoMapDesignerViewModel = this;
		self.model = model;
		self.data = model.get('data');	
		this.enableComplete = ko.observable(false);
		this.shapesList = ko.observableArray([]);
		this.completeButton = ko.observable(true);
		this.switchButton = ko.observable(false);
		//this.zoomButton = ko.observable(true);
		this.unsortedShapesList = [];
		this.children = [];
		this.deletedCodedContainers = [];
		this.progress = self.model.get('progress');
		this.mappingObject = {};
		this.mappingObject['vdml:MarketSegment'] = { name: DataManager.getDataManager().get('localeManager').get('marketSegment'), collectionName: 'participant', type: 'vdml.MarketSegment', complexType: "vdml.Community"};
		this.mappingObject['vdml:Enterprise'] = { name: DataManager.getDataManager().get('localeManager').get('enterprise'), collectionName: 'participant', type: 'vdml.Enterprise', complexType: "vdml.OrgUnit"};
		this.mappingObject['vdml:Individual'] = { name: DataManager.getDataManager().get('localeManager').get('individual'), collectionName: 'participant', type: 'vdml.Individual', complexType: "vdml.Actor"};
		this.mappingObject['vdml:BusinessModel'] = { name: DataManager.getDataManager().get('localeManager').get('BusinessModel'), collectionName: 'businessModel', type: 'vdml.BusinessModel', complexType: "vdml.BusinessModel"};
		this.mappingObject['vdml:Role'] = { name: DataManager.getDataManager().get('localeManager').get('Role'), collectionName: 'participant', type: 'vdml.Role', complexType: "vdml.Party"};
		this.mappingObject['vdml:ValueProposition'] = { name: DataManager.getDataManager().get('localeManager').get('ValueProposition'), collectionName: 'valueProposition', type: 'vdml.ValueProposition', complexType: "vdml.ValueProposition"};
        this.mappingObject['vdml_Community'] = { name: DataManager.getDataManager().get('localeManager').get('marketSegment'), collectionName: 'participant', type: 'vdml.MarketSegment'};
		this.mappingObject['vdml_OrgUnit'] = { name: DataManager.getDataManager().get('localeManager').get('enterprise'), collectionName: 'participant', type: 'vdml.Enterprise'};
		this.mappingObject['vdml_Actor'] = { name: DataManager.getDataManager().get('localeManager').get('individual'), collectionName: 'participant', type: 'vdml.Individual'};
		this.mappingObject['vdml_BusinessModel'] = { name: DataManager.getDataManager().get('localeManager').get('BusinessModel'), collectionName: 'businessModel', type: 'vdml.BusinessModel'};
		this.mappingObject['vdml_Party'] = { name: DataManager.getDataManager().get('localeManager').get('Role'), collectionName: 'participant', type: 'vdml.Role'};
		this.mappingObject['vdml_ValueProposition'] = { name: DataManager.getDataManager().get('localeManager').get('ValueProposition'), collectionName: 'valueProposition', type: 'vdml.ValueProposition'};
        this.showDashboardEcoMap = false;
        this.modeler = null;
        self.getCurrentAlt();
        self.getHeaderTitles();
        this.mappedDiagramBO = null;
        this.diagramBO = null;
        this.RelationshipColl = ["Partner", "Customer", "Business"];
        this.ParticipantTypeColl = ["Enterprise","Individual", "Market Segment"];
        this.BMsColl = new Backbone.Collection();
        this.BusinessColl = new Backbone.Collection();
        this.PNsColl = new Backbone.Collection();
        this.VPsColl = new Backbone.Collection();
        this.ParticipantsColl = new Backbone.Collection();
        this.rolesColl = new Backbone.Collection();
        this.ProvidersColl = ["Select a Participant..."];
        this.ProviderRolesColl = ["Select a Role..."];
        this.RecipientsColl = ["Select a Participant..."];
        this.RecipientRolesColl = ["Select a Role..."];
        this.CustomerType = function(name,type,typeStr){
		    this.name = name;
		    this.type = type;
		    this.typeStr = typeStr;
		};
        this.custTypeArray = [new this.CustomerType("Market Segment",Community,"Community"),
                                new this.CustomerType("Enterprise",OrgUnit,"OrgUnit"),
								new this.CustomerType("Individual",Actor,"Actor")];
		this.customerTypeOptions = ko.observableArray(this.custTypeArray);
		this.childTable = ko.observable();
		this.bmTypeahead = ko.observableArray([]);
        this.selectedReceiverRole = ko.observable();
        this.selectedProviderRole = ko.observable();
        this.selectedValueProposition = ko.observable();
        this.packagesListBM = ko.observableArray([]);
        this.enableAutoSuggestions = ko.observable(false);
        this.isNewMappingTable = ko.observable(false);
        this.enableSwitchNewTable = ko.observable(false);
        if(DataManager.getDataManager().get('debugMode')){
            self.enableSwitchNewTable(true);
        }
        function htmlEscape(str) {
                return String(str)
	            .replace(/@/g, '')
	            .replace(/ /g, '');
        }
        this.encodeId = htmlEscape(self.model.id);
        this.editMode = DataManager.getDataManager().get('readMode')?false:true;
		if(window.utils.checkIsLinked(null,true)){
            self.editMode = false;
        }
        this.parentView = options.parentView;
        this.showProperties = _.bind(self.showProperties, self);
		if (options.parentView && options.parentView == "Dashboard") {
            this.showDashboardEcoMap = true;
            this.editMode = options.editMode;
            if (!ko.components.isRegistered('StrategyValuesChart')) {
                ko.components.register("StrategyValuesChart", {
                    viewModel: { instance: self.chartViewInstance },
                    template: '<div></div>'
                });
            }

			if (!ko.components.isRegistered('EcoMapDetails')) {
				ko.components.register("EcoMapDetails", {
					viewModel: EcoMapDetailsViewModel,
					template: '<div></div>'
				});
			}
        } else {
			/*window.utils.loadTemplate(function(name){
				if(name == "StrategyValuesChart" && self.chartViewInstance){
					var valueList = [];
					if(self.model){
						_.each(self.model.get('valueProposition').models, function (vp) {
							valueList = valueList.concat(vp.get('component').models.concat());
						});
					}
					self.chartViewInstance.initialize(self.model, false, self, valueList);
				}
			})*/
            self.highchartsDefaultTheme = $.extend(true, {}, Highcharts.defaultOptions);
            self.chartViewInstance = StrategyMapValuesChart.getInstance(this.model);
			
            if (!ko.components.isRegistered('StrategyValuesChart')) {
                ko.components.register("StrategyValuesChart", {
                    viewModel: { instance: self.chartViewInstance },
					template: window.utils.getHtmlPage("StrategyMapValuesChartTemplate"),
                    synchronous: true
                });
            }

			if (!ko.components.isRegistered('EcoMapDetails')) {
				ko.components.register("EcoMapDetails", {
					viewModel: EcoMapDetailsViewModel,
					template: window.utils.getHtmlPage("EcoMapDetailsTemplate"),
					synchronous: true
				});
			}
        }

		if (!ko.components.isRegistered('EcoMapGuidance')) {
			self.guidanceViewInstance = BMGuidanceViewModel.getInstance(this.model);
			ko.components.register("EcoMapGuidance", {
				viewModel: { instance: self.guidanceViewInstance },
				template: '<div id="guidanceTab'+self.encodeId+'"></div>',
				synchronous: true
			});
		}

        if (window.checkContextForRevision() && !self.showDashboardEcoMap) {
			var pack = self.model.getNestedParent();
			pack.checkRevision(model, function (revision) {
				if(revision){
					self.createRevisionBasedOnContext(pack, function (newPack) {
						var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
						var altId = DataManager.getDataManager().getRepositoryId(newPack.id);
						var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
						var map = newPack.get('diagrams').findWhere({ 'id': altId + bmId });
						var diagrams = newPack.get('diagrams');
						for (var i = 0; i < diagrams.length; i++) {
							newPack.fixDiagramOnRevision(diagrams.at(i), altId, oldAltId);
						}
						DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newPack.get('id') + "/" + newPack.get('type') + "/" + newPack.get('version') + "/" + map.id + "/" + map.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
						window.utils.stopSpinner('revisionSpinner');
					});
				}
			});
    	}

        
		if (!self.data) {
		    self.data = window.utils.getXmlData(model.get('type').replace("_","."),htmlEscape(model.id))
			self.model.set('data',self.data);
		}
        
		this.openDiagram= function(xml) {
			//var xml = xml.split(self.model.id).join('e'+htmlEscape(self.model.id));
			//self.model.set('data',xml);
			/*self.modeler.importXML(xml, function (err) {
		        if (err) {
		            console.error(err);
		        }
		        self.modeler.get("canvas").zoom("fit-viewport", true);
		        //$('svg').css('margin-left', 70);
				if (window.vdmModelView === self) {
					var height = self.model.get('height');
					if (!height) {
						height = getViewportSize().h * 0.80 + $('.navbar').height();
					}
					$("#js-canvas" + self.encodeId).height(height);
					self.loadTable();
					window.utils.stopSpinner('ecoMapSpinner');
		        }
		        //$('#js-properties').height(getViewportSize().h);
		    });
			debugger;*/
			async function myDisplay() {
				let result = new Promise(function(myResolve, myReject) {
				  myResolve("Works");
				});
				try {
					result = await self.modeler.importXML(xml);
					var { warnings } = result;
					console.log(warnings);
					self.modeler.get("canvas").zoom("fit-viewport", true);
					if (window.vdmModelView === self) {
						/*var zoomlevel = self.model.get('zoom');
						if(zoomlevel){
							var viewbox = self.model.get('viewbox');
							if(viewbox){
								self.modeler.get("canvas").viewbox().x = viewbox.x;
								self.modeler.get("canvas").viewbox().y = viewbox.y;
							}
							self.modeler.get("canvas").zoom(zoomlevel);
						}*/
						if (!self.showDashboardEcoMap){
							var height = self.model.get('height');
							if (!height) {
								height = self.getViewportSize().h * 0.80 + $('.navbar').height();
							}
                            height = height > 700 ? height : 700;//to avoid 2 column djs-palette
							$("#js-canvas" + self.encodeId).height(height);
							self.loadTable();
							self.refreshNewMappingTable();
							window.utils.stopSpinner('ecoMapSpinner');
						}
						self.initializePresentation();
						//self.loadTable();
						//$(".icon-hand-tool").click();
					}
					//$('#js-properties').height(getViewportSize().h);
				//});
				  } catch (err) {
					/*if(err.message = "no process or collaboration to display"){
						var $xml = jQuery(xml);
						var nodes = $xml.find('[id="' + self.model.id + '"]');
						if(nodes.length != 0){
							var diagramType = self.model.get('type').split("_");
							var diagramTypeSuffix = "e";
							if(diagramType[1]){
								diagramTypeSuffix = diagramType[1].charAt(0).toLowerCase();
							}
							var newId = diagramTypeSuffix + window.utils.htmlEscape(self.model.id);
							var newXML = self.model.getNestedParent().fixXMLOnRevision(xml,self.model.id,newId);
							self.model.set('data',newXML);
							self.openDiagram(newXML);
						}
					} else {*/
						console.log(err.message, err.warnings);
					//}
				  }
			}
			myDisplay();
			
			
			
		}
		
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
		'name'
		,'description'
		,'Delete'
		,'Complete'
        ,'EcoMapDetails'
		,'Guidance'
		,'Collaboration'
		,'shapeDetails'
		,'Shape'
		,'ElementType'
		,'targetElement'
		,'BusinessEcosystemMap'
		,'PresentationType'
		,'Select'
		,'Actions'
		,'Switch'
        ]);
        
	}
	zoomReset() {
		var self = this;
        self.modeler.get("canvas").zoom("fit-viewport", true);
    }
    zoomIn() {
		var self = this;
        self.modeler.get('zoomScroll').stepZoom(1);
    }
    zoomOut() {
		var self = this;
        self.modeler.get('zoomScroll').stepZoom(-1);
    }
        
	createRevisionBasedOnContext(pack,callback){
		if (window.checkContextForRevision()) {
			window.utils.stopSpinner('ecoMapSpinner');
			window.utils.startSpinner('revisionSpinner', "Creating a copy...");
			function fetchBmModel(newVdmPackage){
				if(newVdmPackage) {
					DataManager.getDataManager().releaseSaveLock();
					DataManager.getDataManager().set('isActive',false);
					callback(newVdmPackage);
				}
			}
			window.setTimeout(function(){
				DataManager.getDataManager().acquireSaveLock(function () {
					window.utils.startSpinner('ecoMapSpinner', "Navigating to Business Ecosystem Map...");
					pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
				});
			},100);	
		}
		else {
			callback(pack);
		}
	};
        
        
		showProperties(data,ev) {
			var self = this;
			if(self.editMode){
				var id = window.utils.htmlEscape(window.guidGenerator());
				var elementRegistry = self.modeler.get('elementRegistry');
				var options = { businessObject: data.businessObject, element: elementRegistry.get(data.businessObject.id), width: '660px' };
				window.getAndCreateModalDialog(self, id,EcoMapDiagramMixin, self.model, "ShapeProperties", null, options);
			}
  		}
  		fillAllDiagrams(diagCallback) {
			var packageList = [];
			var designPartList = [];
			var vsList = [];
            var currentAltId = DataManager.getDataManager().get('viewAlternative');
            var currentAlternative = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({id: currentAltId});
			_.each(currentAlternative.get('phaseDesignPart').models,function(designPart){
				if(designPart.get('beepType') == "ecomap_EcoMap"){
					designPartList.push(designPart);
				}
			});
            
           // DataManager.getDataManager().getAllDocumentsCollectionOfType(currentAlternative, 'ecomap_EcoMap', function(result){
           //     if(result && result.length > 0){
                    async.eachSeries(designPartList,function(pack,callback){
						var packId = pack.get('beepReference');
                        var repId = DataManager.getDataManager().getRepositoryId(packId);
                        var vdmStore = DataManager.getDataManager().getVDMStore(repId);
                        DataManager.getDataManager().fetchDocumentFromPackage(packId, 'ecomap_EcoMap', pack.get('version'), packId, 'ecomap_EcoMap', vdmStore, {
                            success: function (model) {
                                packageList.push(model);
								callback();
                            },
                            error: function (error) {
                                console.log('Unable to load selected Package');
                            }
                        }, true, null);
                    },function(arr){
						_.each(packageList, function (mypackage) {
							_.each(mypackage.get('diagrams').models, function (diag) {
								if (diag.get('type') == 'vdml_ValueStreamMapDiagram') {
									vsList.push(diag);
								}
									
							})
						})
						diagCallback(vsList);
					});
           /*     }else {
					diagCallback(vsList);
				}
            }, true, null, null, 'vbc:cmof_EObject-name');*/
        }

        navigateToValueStream(businessObj) {
			var self = this;
            self.fillAllDiagrams(function(vsList){
                var showVsList = [];
                _.each(vsList, function (vs) {
                    var exists = _.filter(vs.get('valueStream').models, function (vp) {
                        if (vp.get('persuedProposition').id == businessObj.mid) {
                            return true
                        }
                    });
                    if (exists.length > 0 & self.model.id !== vs.id) {
                        showVsList.push(vs);
                    }
                });
                var htmlContent = "<select class=\"form-control\" id=\"selectValueStream\">";
                if(showVsList.length === 1){
					   var selectedVS = showVsList[0];
					   DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + selectedVS.getParent().id + "/" + selectedVS.getNestedParent().get('type') + "/" + selectedVS.getNestedParent().get('version') + "/" + selectedVS.id + "/" + selectedVS.get('type') + "/" + DataManager.getDataManager().getRepositoryId(selectedVS.id), {trigger: true});
				}else{
					htmlContent = htmlContent + "<option>Select ValueStream  ...</option>";
					for (var i = 0; i < showVsList.length; i++) {
						htmlContent = htmlContent + "<option valueStreamId ='" + showVsList[i].id + "'>" + showVsList[i].get('name') + "</option>";
					}
				
				    htmlContent = htmlContent + "</select><p/>";

					bootbox.dialog({
						title: "<div>&nbsp;&nbsp;Do you want to navigate to Value Streams? </div>",
						message: htmlContent,
						buttons: {
							main: {
								label: "Close",
								className: "btn btn-default",
							},
							success: {
								label: "Complete",
								className: "btn btn-complete complete-btn ",
								callback: function () {
									var valueStreamId = $('#selectValueStream option:selected').attr('valueStreamId');
									if (valueStreamId !== undefined) {
										for (var i = 0; i < showVsList.length; i++) {
											if (showVsList[i].id == valueStreamId) {
												var selectedVS = showVsList[i];
											}
										}
										window.utils.startSpinner('navigateValueStream', "Navigating to Value Stream ... ");
										DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + selectedVS.getParent().id + "/" + selectedVS.getNestedParent().get('type') + "/" + selectedVS.getNestedParent().get('version') + "/" + selectedVS.id + "/" + selectedVS.get('type') + "/" + DataManager.getDataManager().getRepositoryId(selectedVS.id), {trigger: true});
										window.utils.stopSpinner('navigateValueStream');
									}
								}
							}
						}
					});
				}
			});
        }
        fillAllCanvasDiagrams(diagCallback) {
            var currentAltId = DataManager.getDataManager().get('viewAlternative');
            var currentAlternative = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({id: currentAltId});
            var packageList = [];
            var canvasList = [];
			var designPartList = [];
			_.each(currentAlternative.get('phaseDesignPart').models,function(designPart){
				if(designPart.get('beepType') == "vdml_ValueDeliveryModel" && designPart.get('beepReference').indexOf('CommonPackage')== -1){
					designPartList.push(designPart);
				}
			});
            //DataManager.getDataManager().getAllDocumentsCollectionOfType(currentAlternative, 'vdml_ValueDeliveryModel', function(result){
            //    if(result && result.length > 0){
                    async.eachSeries(designPartList,function(pack,callback){
						var packId = pack.get('beepReference');
                        var repId = DataManager.getDataManager().getRepositoryId(packId);
                        var vdmStore = DataManager.getDataManager().getVDMStore(repId);
                        DataManager.getDataManager().fetchDocumentFromPackage(packId, 'vdml_ValueDeliveryModel', pack.get('version'), packId, 'vdml_ValueDeliveryModel', vdmStore, {
                            success: function (model) {
                                packageList.push(model);
                                callback();
                            },
                            error: function (error) {
                                callback();
                                console.log('Unable to load selected Package');
                            }
                        }, true, null);
                    },function(arr){
                        _.each(packageList, function (mypackage) {
                            _.each(mypackage.get('canvas').models, function (canvas) {
                                if (canvas.get('canvasType') !== 'LeanCanvas' || canvas.get('canvasType') !== 'SWOTAnalysisCanvas' || canvas.get('canvasType') !== 'LeanChangeCanvas') {
                                    canvasList.push(canvas);
                                }
                            })
                        })
                        diagCallback(canvasList);
                    });
              /*  }else {
                    diagCallback(canvasList);
                }
            }, true, null, null, 'vbc:cmof_EObject-name');*/
        }
        navigateToBusinseeCanvas(businessObj) {
			var self = this;
            self.fillAllCanvasDiagrams(function(canvasList){
                var showCanvasList = [];
                _.each(canvasList, function (canvas) {
                	if(canvas.get('businessModel')){
                        if (canvas.get('businessModel').get('business').id == businessObj.mid) {
                            showCanvasList.push(canvas);
                        } else if(canvas.get('businessModel').id == businessObj.mid){
							showCanvasList.push(canvas);
						}
					}
                });
                var htmlContent = "<select class=\"form-control\" id=\"selectCanvas\">";
                if(showCanvasList.length === 1){
					   var selectedCanvas = showCanvasList[0];
					   DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + selectedCanvas.getParent().id + "/" + selectedCanvas.getNestedParent().get('type') + "/" + selectedCanvas.getNestedParent().get('version') + "/" + selectedCanvas.id + "/" + selectedCanvas.get('type') + "/" + DataManager.getDataManager().getRepositoryId(selectedCanvas.id), {trigger: true});
				}else{
					htmlContent = htmlContent + "<option>Select Canvas...</option>";
					for (var i = 0; i < showCanvasList.length; i++) {
						htmlContent = htmlContent + "<option selectCanvasId ='" + showCanvasList[i].id + "'>" + showCanvasList[i].get('name') + "</option>";
					}
				
				    htmlContent = htmlContent + "</select><p/>";

					bootbox.dialog({
						title: "<div>&nbsp;&nbsp;Do you want to navigate to Canvas? </div>",
						message: htmlContent,
						buttons: {
							main: {
								label: "Close",
								className: "btn btn-default",
							},
							success: {
								label: "Complete",
								className: "btn btn-complete complete-btn ",
								callback: function () {
									var selectCanvasId = $('#selectCanvas option:selected').attr('selectCanvasId');
									if (selectCanvasId !== undefined) {
										for (var i = 0; i < showCanvasList.length; i++) {
											if (showCanvasList[i].id == selectCanvasId) {
												var selectedCanvas = showCanvasList[i];
											}
										}
										window.utils.startSpinner('navigateCanvas', "Navigating to Canvas... ");
										DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + selectedCanvas.getParent().id + "/" + selectedCanvas.getNestedParent().get('type') + "/" + selectedCanvas.getNestedParent().get('version') + "/" + selectedCanvas.id + "/" + selectedCanvas.get('type') + "/" + DataManager.getDataManager().getRepositoryId(selectedCanvas.id), {trigger: true});
										window.utils.stopSpinner('navigateCanvas');
									}
								}
							}
						}
					});
				}
			});
        }

		loadTable(event){
			var self = this;
			self.shapesList.removeAll();
			self.unsortedShapesList = [];
            var shapes ,definitions ,ecoMap ,modeler = self.modeler;
			if(modeler) {
				definitions = modeler._definitions;
			}
			if(definitions) {
				ecoMap = definitions.rootElements;
			}			
			if(ecoMap && ecoMap.length) {
				shapes = ecoMap[0].flowElements;
			}
			if(shapes){
			    for (var i = 0; i < shapes.length; i++) {
			        if (event && event.type === 'shape.remove' && event.element.id === shapes[i].id) {
			            continue;
			        }
			        var type = shapes[i].$type;
			        if (type === "vdml:BusinessItem") {
			            continue;
			        }
					var typeToDisplay = self.mappingObject[type].name;
					var name = shapes[i].name ? shapes[i].name : "";
					var mappingEleId = shapes[i].mid;
					var packId = shapes[i].mpid;
					var mappingEleName = '';
					if(mappingEleId){
						self.model.getMappedElement(mappingEleId, packId, type, shapes[i], function (model) {
							mappingEleName = model? model.get('name') : '';
						});
					}
                    //mid is empty: Then delete suggestions if there are any
                    var suggestionExists = self.fetchSuggestionForMappedShape(shapes[i].id, shapes[i].$type);
                    if(!mappingEleId && suggestionExists){
                        EcoMapMixin.deleteSuggestion(self.model, shapes[i].id, shapes[i].$type);
                    }
					self.shapesList.push({ businessObject: shapes[i], name: name, type: typeToDisplay, mappedEle: mappingEleName });
					self.unsortedShapesList.push({ businessObject: shapes[i], name: name, type: typeToDisplay, mappedEle: mappingEleName });
                    //self.progress = self.calculateShapeProgress(shapes[i]);
				}
			}
			//self.setResizeHeight();
            self.shapesList.sort(self.sortShapesInTableByName);
		}
        fillPackagesBM() {
             var self = this;
             self.packagesListBM().length = 0;
             var packages = _.filter(self.currentAlternative.get('phaseDesignPart').models, function(packagesRef) {
                 if (packagesRef.get('beepType') === 'vdml_ValueDeliveryModel' && packagesRef.get('beepReference').indexOf('Common') == -1) {
                     return packagesRef
                 }
             });
             var tempPackageArray = [];
             for (var i = 0; i < packages.length; i++) {
                 var obj = packages[i];
                 var tempObj = {
                     id: obj.get('beepReference'),
                     name: obj.get('name'),
                     version: obj.get('version'),
                     type: obj.get('beepType')
                 };
                 var include = true;
                 if (self.providerVPShape) {
                     include = self.filterPackagesForBMFlowsToVP(self.providerVPShape.mid, tempObj);
                 }
                 if (self.receiverVPShape) {
                     include = self.filterPackagesForBMFlowsToVP(self.receiverVPShape.mid, tempObj);
                 }
                 if (self.mappedDiagramBO) {
                     if (tempObj.id !== self.mappedDiagramBO.mpid) {
                         include = false;
                     }
                 }
                 if (include) {
                     tempPackageArray.push({id: obj.get('beepReference'), name: obj.get('name'), version: obj.get('version'), type: obj.get('beepType')});
                 }
             }
             if (self.mappedDiagramBO) {
                 self.packagesListBM(tempPackageArray);
                 //$('#packageFieldBM' + self.encodeId).attr('disabled', 'disabled');
             } else {
                 self.packagesListBM(tempPackageArray);
             }
        }
        createInfoButton(td, cellId){
            var self = this;
            var div = document.createElement('DIV');
            div.setAttribute("id", cellId + "Info" + self.rowId);
            div.setAttribute("class", "glyphicon glyphicon-info-sign glyphicon-button");
            div.setAttribute("style", "font-size: medium; color:orange; padding-top: 7px; display:none;");
            td.appendChild(div);
        }
        createTypeaheadTDCell(trTBody, cellId, optionsColl) {
            var self = this;
            var td1 = document.createElement("TD");

            var divRow = document.createElement('DIV');
            divRow.setAttribute("class", "row");
            var divEle1 = document.createElement('DIV');
            divEle1.setAttribute("class", "col-xs-10");
            divEle1.setAttribute("id", cellId + "Div" + self.rowId);
            var divEle2 = document.createElement('DIV');
            //divEle2.setAttribute("class", "col-xs-1");
            self.createInfoButton(divEle2, cellId);
            divRow.appendChild(divEle1);
            divRow.appendChild(divEle2);

            var inputExtended = Backbone.Typeahead.extend({
                template: '<input type="text" id="' + cellId + self.rowId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%;" class="dropdown-menu typeahead-scrollbar typeahead-input"></ul>',
            });
            td1.appendChild(divRow);
            trTBody.appendChild(td1);
            var typeaheadEle = new inputExtended({ collection: optionsColl });
            return typeaheadEle;
        }
        createDropdownTDCell(trTBody, optionsColl, cellId){
            var self= this;
            var td1 = document.createElement("TD");
            trTBody.appendChild(td1);
            var select1 = document.createElement('SELECT');
            select1.setAttribute("width", "50%");
            select1.setAttribute("class", "form-control");
            select1.setAttribute("id", cellId + self.rowId);
            select1.setAttribute("optionsText", "name");
            for(var i=0; i<optionsColl.length; i++){
               // Use the Option constructor: args text, value, defaultSelected, selected
               var option = new Option(optionsColl[i], optionsColl[i], false, false);
               select1.appendChild(option);
            }
            td1.appendChild(select1);
        }
        createTableHeaders(headerTitels, headerTR1){
            var self = this;
            for(var i=0;i<headerTitels.length;i++){
                var th1 = document.createElement('TH');
                th1.setAttribute("width", "15%");
                th1.appendChild(document.createTextNode(headerTitels[i]));
                headerTR1.appendChild(th1);
            }
        }
        createTDCell(trTBody, cellData){
            var self = this;
            var td1 = document.createElement('TD');
            td1.align = "left";
            if(cellData){
                td1.appendChild(document.createTextNode(cellData));
            }
            trTBody.appendChild(td1);
        }
        createMappingWizard(shapeType, trTBody){
            var self = this;
            if (shapeType == "vdml:Role") {
                // create BM input
                var bmTypeahead = self.createTypeaheadTDCell(trTBody, "selectedBM", self.BMsColl);
                self.bmTypeahead = bmTypeahead;
                // create Business input
                var businessTypeahead = self.createTypeaheadTDCell(trTBody, "selectedBusiness", self.BusinessColl);
                self.businessTypeahead = businessTypeahead;
                // create PN input
                var pnTypeahead = self.createTypeaheadTDCell(trTBody, "selectedPN", self.PNsColl);
                self.pnTypeahead = pnTypeahead;
                // create Relationship drop down
                self.createDropdownTDCell(trTBody, self.RelationshipColl, "selectedRelationship");
                // craete Participant input
                var participantTypeahead = self.createTypeaheadTDCell(trTBody, "selectedParticipant", self.ParticipantsColl);
                self.participantTypeahead = participantTypeahead;
                // create participantType drop down
                self.createDropdownTDCell(trTBody, self.ParticipantTypeColl, "selectedParticipantType");
                // create role input
                var roleTypeahead = self.createTypeaheadTDCell(trTBody, "selectedRole", self.rolesColl);
                self.roleTypeahead = roleTypeahead;
            }else if(shapeType == "vdml:BusinessModel") {
                // create BM input
                var bmTypeahead = self.createTypeaheadTDCell(trTBody, "selectedBM", self.BMsColl);
                self.bmTypeahead = bmTypeahead;
                // create Business input
                var businessTypeahead = self.createTypeaheadTDCell(trTBody, "selectedBusiness", self.BusinessColl);
                self.businessTypeahead = businessTypeahead;
            }else if(shapeType == "vdml:ValueProposition"){
                // create BM input
                var bmTypeahead = self.createTypeaheadTDCell(trTBody, "selectedBM", self.BMsColl);
                self.bmTypeahead = bmTypeahead;
                // create VP input
                var vpTypeahead = self.createTypeaheadTDCell(trTBody, "selectedVP", self.VPsColl);
                self.vpTypeahead = vpTypeahead;
                // create Provider Dropdown
                self.createDropdownTDCell(trTBody, self.ProvidersColl, "selectedProvider");
                //self.providerTypeahead = providerTypeahead;
                // create ProviderRole Dropdown
                self.createDropdownTDCell(trTBody, self.ProviderRolesColl, "selectedProviderRole");
                // create Recipient Dropdown
                self.createDropdownTDCell(trTBody, self.RecipientsColl, "selectedRecipient");
                //self.recipientTypeahead = recipientTypeahead;
                // create RecipientRole Dropdown
                self.createDropdownTDCell(trTBody, self.RecipientRolesColl, "selectedRecipientRole");
            }else if(shapeType == "vdml:Enterprise" || shapeType == "vdml:Individual" || shapeType == "vdml:MarketSegment"){
                // create BM input
                var bmTypeahead = self.createTypeaheadTDCell(trTBody, "selectedBM", self.BMsColl);
                self.bmTypeahead = bmTypeahead;
                // create PN input
                var pnTypeahead = self.createTypeaheadTDCell(trTBody, "selectedPN", self.PNsColl);
                self.pnTypeahead = pnTypeahead;
                // create Relationship drop down
                if(shapeType != "vdml:Enterprise"){
                    self.RelationshipColl.pop();
                }
                self.createDropdownTDCell(trTBody, self.RelationshipColl, "selectedRelationship");
                if(self.RelationshipColl.indexOf('Business') === -1){
                    self.RelationshipColl.push("Business");
                }
                // craete Participant input
                var participantTypeahead = self.createTypeaheadTDCell(trTBody, "selectedParticipant", self.ParticipantsColl);
                self.participantTypeahead = participantTypeahead;
                // create role input
                var roleTypeahead = self.createTypeaheadTDCell(trTBody, "selectedRole", self.rolesColl);
                self.roleTypeahead = roleTypeahead;
            }
            //Create Actions create button
            self.createActionButton(trTBody, "btn-create-mapping btn-complete", "Create");
        }
        createTableRowData(tableData, tableBody, shapeType){
            var self = this;
            /*if(self.autoSuggestionList[self.rowId] && self.autoSuggestionList[self.rowId].length){
                var trTBody = document.createElement('TR');
                //trTBody.setAttribute("class", "auto-suggest-row");
                tableBody.appendChild(trTBody);
                self.createMappingWizard(shapeType, trTBody);
            }*/
            if(tableData.length == 0){
                var trTBody = document.createElement('TR');
                tableBody.appendChild(trTBody);
                self.createMappingWizard(shapeType, trTBody);
             }
            for (var k = 0; k < tableData.length; k++) {
                var trTBody = document.createElement('TR');
                tableBody.appendChild(trTBody);
                self.createChildTDsByShape(shapeType, tableData[k], trTBody);
                //Create Actions delete button
                self.createActionButton(trTBody, "btn-delete-mapping btn-complete", "Delete");
            }
        }
        createChildTDsByShape(shapeType, tableData, trTBody){
            var self = this;
            if (shapeType == "vdml:Role") {
                self.createTDCell(trTBody, tableData.bm);
                self.createTDCell(trTBody, tableData.business);
                self.createTDCell(trTBody, tableData.pn);
                self.createTDCell(trTBody, tableData.relationship);
                self.createTDCell(trTBody, tableData.participant);
                var participantType = self.mappingObject[tableData.participantType].name;
                self.createTDCell(trTBody, participantType);
                self.createTDCell(trTBody, tableData.role);
            } else if (shapeType == "vdml:ValueProposition") {
                self.createTDCell(trTBody, tableData.bm);
                self.isMyProposition = tableData.myProposition;
                self.createTDCell(trTBody, tableData.pn);
                self.createTDCell(trTBody, tableData.provider);
                self.createTDCell(trTBody, tableData.providerRole);
                self.createTDCell(trTBody, tableData.recipient);
                self.createTDCell(trTBody, tableData.recipientRole);
            } else if (shapeType == "vdml:Enterprise" || shapeType == "vdml:Individual" || shapeType == "vdml:MarketSegment") {
                self.createTDCell(trTBody, tableData.bm);
                self.createTDCell(trTBody, tableData.pn);
                self.createTDCell(trTBody, tableData.relationship);
                self.createTDCell(trTBody, tableData.participant);
                self.createTDCell(trTBody, tableData.role);
            } else if (shapeType == "vdml:BusinessModel") {
                self.createTDCell(trTBody, tableData.bm);
                self.createTDCell(trTBody, tableData.business);
            }
        }
        createActionButton(trTBody, className, actionText){
            var self = this;
            var td1 = document.createElement('TD');
            td1.align = "left";
            var button = document.createElement("BUTTON");
            button.setAttribute("class", className);
            var text = document.createTextNode(actionText);
            button.appendChild(text);
            td1.appendChild(button);
            trTBody.appendChild(td1);
        }
        createThead(table) {
            var tableHeader = document.createElement('THEAD');
            table.appendChild(tableHeader);
            var headerTR1 = document.createElement('TR');
            tableHeader.appendChild(headerTR1);
            return headerTR1;
        }
        createMyPropositionCheckbox(childTablePlaceHolder, rowId){
            var self = this;
            var spanEle = document.createElement('SPAN');
            var textnode = document.createTextNode("My Proposition" + " ");
            var checkboxNode = document.createElement("INPUT");
            checkboxNode.setAttribute("type", "checkbox");
            checkboxNode.setAttribute("id", "isMyProposition" + self.rowId);
            spanEle.appendChild(textnode);
            spanEle.appendChild(checkboxNode);
            childTablePlaceHolder.appendChild(spanEle);
        }
        getHeaderTitles(){
            var self = this;
            self.headerTitlesJson = {
                "vdml:Role" : ["Business Model", "Business", "Participant Network","Relationship","Participant","Participant Type","Role", "Actions"],
                "vdml:MarketSegment" : ["Business Model", "Participant Network","Relationship", "Participant", "Role", "Actions"],
                "vdml:Enterprise" : ["Business Model", "Participant Network","Relationship", "Participant", "Role", "Actions"],
                "vdml:Individual" : ["Business Model", "Participant Network","Relationship", "Participant", "Role", "Actions"],
                "vdml:ValueProposition" : ["Business Model","Value Proposition","Provider", "Provider Role", "Recipient", "Recipient Role", "Actions"],
                "vdml:BusinessModel": ["Business Model", "Business", "Actions"]
            }
        }
        getShapeType(shapeId){
            var self = this;
            var elementRegistry = self.modeler.get('elementRegistry');
            shapeId = shapeId.substr(0,shapeId.lastIndexOf('_'));
            var currentShapeEle = elementRegistry._elements[shapeId].element;
            return currentShapeEle.type;
        }
        getMappedShapeDetails(rowId){
            var self = this;
            var elementRegistry = self.modeler.get('elementRegistry');
            rowId = rowId.substr(0,rowId.lastIndexOf('_'));
            var currentShapeEle = elementRegistry._elements[rowId].element;
            var shape = currentShapeEle.businessObject;
            var mappingElementType = shape.$type;
            var mappingElementId = shape.mid;
            var packageId = shape.mpid;
            var returnObj = null;
            self.diagramBO = shape;
            if (mappingElementId) {
                self.mappedDiagramBO = shape;
                self.model.getMappedElement(mappingElementId, packageId, mappingElementType, null, function(mappedElement) {
                    if (!mappedElement) {
                        bootbox.alert("No data found");
                        return returnObj;
                    }else{
                        self.mappedElementModel = mappedElement;
                        returnObj = mappedElement;
                        return returnObj;
                    }
                });
            }else{
                //shape is not mapped: provide Auto-suggestions
                self.createAutoSuggestions();
            }
            return returnObj;
        }
        getChildTableData(rowId){
            var self = this;
            var mappedElementsList = [];
            var mappedModal = self.getMappedShapeDetails(rowId);
            if(!mappedModal) {
                return mappedElementsList;
            }
            // for vdml:Role shape
            if (mappedModal.get('type') === "vdml_Party") {
                var pnName = mappedModal.get('collaborationRoleOwner').get('name');
                var assignment = mappedModal.get('roleAssignment').models[0];
                var participant = assignment.get('participant');
                var participantName = participant.get('name');
                participant.getBusinessModelsInvolved(function(bms) {
                    for (var i = 0; i < bms.length; i++) {
                        var roles = bms[i].get('bmRole').models;
                        for (var j = 0; j < roles.length; j++) {
                            if (roles[j].id === mappedModal.id) {
                                mappedElementsList.push({'bm': bms[i].get('name'), 'business': bms[i].get('business').get('name'),
                                    'relationship': bms[i].getRoleBMRelationship(bms[i], participant, mappedModal),
                                    'pn': pnName, 'participant': participantName, 'participantType': participant.get('type'), 'role': mappedModal.get('name')
                                });
                                break;
                            }
                        }
                    }
                });
                return mappedElementsList;
            } else if (mappedModal.get('type') === "vdml_ValueProposition") {
			    var vp = mappedModal;
			    if (vp.get('isValueFormula')) {
                    self.mappingObject['vdml:ValueProposition'].name = DataManager.getDataManager().get('localeManager').get('MyProposition');
			    }
			    var providerRole = vp.get('provider');
			    var providerAssignment = providerRole.get('roleAssignment').models[0];
			    var providerParticipant = providerAssignment.get('participant');
			    var recipientRole = vp.get('recipient');
                var recipientName = null; var recipientRoleName = null;
			    if (recipientRole) {
				    var recipientAssignment = recipientRole.get('roleAssignment').models[0];
				    var recipientParticipant = recipientAssignment.get('participant');
                    recipientName = recipientParticipant.get('name');
                    recipientRoleName = recipientRole.get('name');
			    }
			    var mypackage = vp.getNestedParent();
			    function fetchMappedBm(pack) {
				    var bms = pack.get('businessModel');
				    for (var i = 0; i < bms.length; i++) {
					    if (!vp.get('isValueFormula') && bms.at(i).get('bmValueProposition').findWhere({ 'valueProposition': vp })) {
                            mappedElementsList.push({'bm': bms.at(i).get('name'),'pn': vp.get('name'),'myProposition': false,
                                'provider': providerParticipant.get('name'), 'providerRole': providerRole.get('name'),
                                'recipient': recipientName, 'recipientRole': recipientRoleName
                            });
                        }else if (vp.get('isValueFormula') && bms.at(i).get('bmValueFormula').findWhere({ 'valueFormula': vp })) {
						    mappedElementsList.push({'bm': bms.at(i).get('name'),'pn': vp.get('name'),'myProposition': true,
                                'provider': providerParticipant.get('name'), 'providerRole': providerRole.get('name'),
                                'recipient': recipientName, 'recipientRole': recipientRoleName
                            });
					    }
				    }
			    }
			    fetchMappedBm(mypackage);
			    var depPack = mypackage.get('dependentPackage')?mypackage.get('dependentPackage'):[];
			    for (var i = 0; i < depPack.length; i++) {
				    if (depPack.at(i).get('type') === "vdml_ValueDeliveryModel") {
					    fetchMappedBm(depPack.at(i));
				    }
			    }
                return mappedElementsList;
		    }else if (mappedModal.get('type') === "vdml_OrgUnit" || mappedModal.get('type') === "vdml_Community" || mappedModal.get('type') === "vdml_Actor") {
                //For Enterprise, MarketSegment, Individual shapes
                var roles;
                var roleId = [];
                var PNRoleColl = [];
                mappedModal.getBusinessModelsInvolved(function(bms) {
                    for (var i = 0; i < bms.length; i++) {
                        roles = bms[i].getRolesofParticipant(mappedModal);
                        if (roles && roles.length > 0) {
                            _.each(roles, function(role) {
                                var pnName = role.get('collaborationRoleOwner').get('name');
                                var roleNames = role.get('name');
                                var PNAlreadyExists = PNRoleColl[_.findLastIndex(PNRoleColl, {
                                    'pnName': pnName
                                })];

                                if (PNAlreadyExists) {
                                    if (_.filter(PNAlreadyExists['roleId'], function(pnRoleId) {
                                            return pnRoleId === role.id
                                        }).length == 0) {
                                        var oldRolesText = PNAlreadyExists.roleNames;
                                        PNAlreadyExists['roleNames'] = oldRolesText + ", " + roleNames;
                                        PNAlreadyExists['roleId'].push(role.id);
                                        PNRoleColl.pop(PNAlreadyExists);
                                        PNRoleColl.push(PNAlreadyExists);
                                    }
                                } else {
                                    roleId.push(role.id);
                                    PNRoleColl.push({
                                        pnName: pnName,
                                        roleNames: roleNames,
                                        roleId: roleId
                                    });
                                }
                            });
                            _.each(PNRoleColl, function(PN){
                                 mappedElementsList.push({
                                'bm': bms[i].get('name'),
                                'relationship': bms[i].getPaticipantBMType(bms[i], mappedModal),
                                'pn': PN.pnName, 'role': PN.roleNames, 'participant': mappedModal.get('name')
                                
                                });
                            });
                        }
                    }
                });
                return mappedElementsList;
            } else if (mappedModal.get('type') === "vdml_BusinessModel") {
			    var packageName = mappedModal.getNestedParent().get('name');
			    var businessName = mappedModal.get('business') ? mappedModal.get('business').get('name') : '';
                mappedElementsList.push({'bm': mappedModal.get('name'), 'business' : businessName});
                return mappedElementsList;
			}
        }
        loadChildTable(childRow, rowId){
            var self = this;
            var shapeType = self.getShapeType(self.rowId);
            var childTablePlaceHolder = document.createElement('DIV');
            // Special row for VP shape identification
            if(shapeType == "vdml:ValueProposition"){
                self.createMyPropositionCheckbox(childTablePlaceHolder, self.rowId);
            }
            var table = document.createElement('TABLE');
            childTablePlaceHolder.appendChild(table);
            table.id = 'childTable' + self.rowId;
            table.setAttribute("class", "ic-detail-table");
            table.cellspacing="0";
	        table.width="100%";
            // Create THEAD and header row
	        var headerTR1 = self.createThead(table);

            //Get table headers
            var headerTitles = self.headerTitlesJson[shapeType];
            self.createTableHeaders(headerTitles, headerTR1);
            var mappedElementsList = self.getChildTableData(self.rowId);
            // creation of tbody
            var tableBody = document.createElement('TBODY');
	        table.appendChild(tableBody);
            self.createTableRowData(mappedElementsList, tableBody, shapeType);
            self.childTable = table;
            return childTablePlaceHolder;
        }
        refreshNewMappingTable(){
            var self = this;
            if(self.dataTable) {
                self.dataTable.destroy();
                var dt = $('#NewShapeDetailsTable' + self.encodeId).DataTable();
                //dt.clear();
                //dt.rows().remove();
                dt.destroy();
                $('#NewShapeDetailsTable' + self.encodeId).empty()
                self.loadNewMappingTable();
            }else{
                self.loadNewMappingTable();
            }
        }
        loadNewMappingTable(){
            var self = this;
            var headers = [{ title: 'Select', targets: 0 },{ title: 'Shape', targets: 1 },{ title: 'Element Type', targets: 2 },
					{ title: 'Target Element', targets: 3 },{ title: 'Actions', targets: 4 }];
            var jsonData = [];
            var shapes = self.shapesList();
            //Constructing json for shapes
            for(var i=0; i<shapes.length; i++){
                jsonData.push({ id: shapes[i].businessObject.id, name: shapes[i].name, type: shapes[i].type, mappedEle: shapes[i].mappedEle });
            }
            //creating DataTable
            self.dataTable =  $('#NewShapeDetailsTable' + self.encodeId).DataTable({
                data: jsonData,
                "columnDefs": headers,
                columns: [
                    {
                        className: 'details-control',
                        orderable: false,
                        data: null,
                        defaultContent: ''
                    },
                    { data: 'name' },
                    { data: 'type' },
                    { data: 'mappedEle' },
                    { data: null,
                      "render": function(data, type, row) {
                          if(data.mappedEle != ""){
                              return "<span class='glyphicon glyphicon-trash delete-shape-mapping'></span>"
                          }else{
                              return "";
                          }
                      }
                    }
                ],
                "rowId" : function(data) {return data.id  + "_" + window.utils.htmlEscape(window.guidGenerator());},
                order: [[2, 'asc']],
                "paging": false,
                "bInfo": false,
                "dom": '<"toolbar">frtip'
            });
            $("div.toolbar").html('<label class="form-check form-switch">Enable Suggestions <input class="form-check-input" id="autoSuggestionToggle" role="switch" type="checkbox"><label class="form-check-label" for="autoSuggestionToggle"></label></label>');
            $('#NewShapeDetailsTable' + self.encodeId+' tbody').on('click', 'td.details-control', function (e) {
                var tr = $(this).closest('tr');
                var row = self.dataTable.row( tr );
                if ( row.child.isShown() ) {
                    // This row is already open - close it
                    row.child.hide();
                    tr.removeClass('shown');
                    tr[0].firstElementChild.setAttribute("class", "details-control");
                    return;
                }else {
                    self.mappedDiagramBO = null;
                    self.diagramBO = null;
                    self.resetTypeaheadCollections();
                    // Open this row
                    self.rowId = row.id();
                    // Loading child table
                    row.child( self.loadChildTable(row.data(), row.id()) ).show();
                    tr.addClass('shown');
                    if(self.isMyProposition){
                        document.getElementById("isMyProposition" + self.rowId).checked = self.isMyProposition;
                    }
                    tr[0].firstElementChild.setAttribute("class", "details-control expand")
                    self.setResizeHeight();
                    $(".btn-create-mapping").on("click",function(){
                        self.createMapping();
                        row.child.hide();
                        tr.removeClass('shown');
                        tr[0].firstElementChild.setAttribute("class", "details-control");
                        self.loadTable();
                        self.createSuggestionForMappedShape();
                        self.refreshNewMappingTable();
                    });
                    $(".btn-delete-mapping").on("click", function(){
                        var businessObject = self.diagramBO;
                        var shapeElement = self.modeler.get('elementRegistry')._elements[businessObject.id].element;
                        self.shapeDeleteModel(businessObject, shapeElement);
                        self.saveDiagram(null,null,null,null, null, null);
                        self.loadTable();
                        self.refreshNewMappingTable();
                    });
                    $("#isMyProposition" + self.rowId).change(function(event){
                        if(event.currentTarget.checked){
                            $("#selectedRecipient" + self.rowId).val("Select a Participant...");
                            $("#selectedRecipientRole" + self.rowId).val("Select a Role...");
                            $("#selectedRecipient" + self.rowId).attr('disabled', 'disabled');
                            $("#selectedRecipientRole" + self.rowId).attr('disabled', 'disabled');
                            $("#selectedProvider" + self.rowId).removeAttr('disabled');
                            $("#selectedProviderRole" + self.rowId).removeAttr('disabled');
                            self.isMyProposition = true;
                            $("#selectedVP" + self.rowId).val("");
                            $("#selectedVPInfo" + self.rowId).hide();
                            self.selectedMappingVP = null;
                            self.loadVPs();
                        }else{
                            $("#selectedRecipient" + self.rowId).removeAttr('disabled');
                            $("#selectedRecipientRole" + self.rowId).removeAttr('disabled');
                            $("#selectedProvider" + self.rowId).removeAttr('disabled');
                            $("#selectedProviderRole" + self.rowId).removeAttr('disabled');
                            self.isMyProposition = false;
                            $("#selectedVP" + self.rowId).val("");
                            $("#selectedVPInfo" + self.rowId).hide();
                            self.selectedMappingVP = null;
                            //self.RecipientRolesColl = ["Select a Role..."];
                            self.loadVPs();
                            //self.selectedVDMPackage ? self.loadReceivers(self.selectedVDMPackage.id) : null;
                        }
                    });
                }
                // Prefill data on child row input fields
                if(self.mappedDiagramBO == null){
                    self.prefillChildRowInputs(self.rowId);
                }
            });
            $('.delete-shape-mapping').on('click', function(){
                var tr = $(this).closest('tr');
                var row = self.dataTable.row( tr );
                // get DiagramBO and mappedModal
                self.getMappedShapeDetails(row.id());
                self.removeMapping();
            });
            $('#autoSuggestionToggle').on('click', function(event){
                if(event.target.checked){
                    self.enableAutoSuggestions(true);
                }else{
                    self.enableAutoSuggestions(false);
                }
            });
        }
        createMapping(){
            var self = this;
            self.selectedBM = $("#selectedBM" + self.rowId).val();
            if(!self.selectedBM){
                bootbox.alert("BM not found");
                return;
            }
            self.selectedBusiness = $("#selectedBusiness" + self.rowId).val();
            self.selectedPN = $("#selectedPN" + self.rowId).val();
            self.selectedVP = $("#selectedVP" + self.rowId).val();
            self.selectedRelationship = $("#selectedRelationship" + self.rowId).val();
            self.selectedParticipant = $("#selectedParticipant" + self.rowId).val();
            self.selectedParticipantType = $("#selectedParticipantType" + self.rowId).val() ? $("#selectedParticipantType" + self.rowId).val() : self.mappingObject[self.getShapeType(self.rowId)].name;
            self.selectedRole = $("#selectedRole" + self.rowId).val();
            self.selectedProvider = $("#selectedProvider" + self.rowId).val();
            self.selectedProviderRole = $("#selectedProviderRole" + self.rowId).val();
            self.selectedRecipient = $("#selectedRecipient" + self.rowId).val();
            self.selectedRecipientRole = $("#selectedRecipientRole" + self.rowId).val();
            self.tempArrToStoreSuggestions = {
                                                "bm": self.selectedBM,
                                                "business": self.selectedBusiness,
                                                "provider": self.selectedProvider,
                                                "providerRole": self.selectedProviderRole,
                                                "recipient": self.selectedRecipient,
                                                "recipientRole": self.selectedRecipientRole,
                                                "recipientRelationship": self.recipientRelationship,
                                                "vp": self.selectedVP,
                                                "pn": self.selectedPN,
                                                "relationship": self.selectedRelationship,
                                                "participant": self.selectedParticipant,
                                                "participantType": self.selectedParticipantType,
                                                "role": self.selectedRole
                                            };

            DataManager.getDataManager().getWorkspaceDataWithId(DataManager.getDataManager().get('viewAlternative'), function(wsData) {
                var currentWorkspace = wsData.get('workspace');
                var previousWorkspace = DataManager.getDataManager().getWorkspace();
                self.selectedMappingBM = null, self.selectedVDMPackage = null;
                self.selectedMappingBusiness = null;
                var bmFound = _.filter(self.BMsColl.models, function(obj) {return obj.get('name').toLowerCase() === self.selectedBM.toLowerCase();});
                if (bmFound && bmFound.length) {
                    self.selectedMappingBM = bmFound[0];
                    self.selectedVDMPackage = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({id: bmFound[0].id});
                }
                if (self.selectedBusiness != null) {
                    var businessFound = _.filter(self.BusinessColl.models, function(obj) {return obj.get('name').toLowerCase() === self.selectedBusiness.toLowerCase();});
                    if (businessFound && businessFound.length) {
                        self.selectedMappingBusiness = businessFound[0];
                    }
                }
                DataManager.getDataManager().setWorkspace(currentWorkspace, function() {
                    self.mapBusinessModel(self.selectedMappingBM, self.selectedBM, self.selectedVDMPackage, self.diagramBO, self.selectedMappingBusiness, self.selectedBusiness, function(bm) {
                        if (self.selectedPN) {
                            self.createParticipantNetwork(self.selectedPN, bm);
                        }
                        if (self.selectedVP) {
                            self.saveValueProposition(self.selectedVP, bm)
                        }
                        DataManager.getDataManager().setWorkspace(previousWorkspace, function() {
                            //self.modeler.get('modeling').updateLabel(shape, shape.businessObject.name);
                            self.saveDiagram(null, null, null, null, null, null);
                        });
                    });
                });
            });
        }
        prefillInputByDiagramBo(cellId,rowId){
            var self =this;
            if(self.diagramBO && self.diagramBO.get('name')){
                $("#" + cellId + self.rowId).val(self.diagramBO.get('name'));
            }
        }
        prefillInputByAutoSuggestions(rowId){debugger
            var self = this;
            if(!self.enableAutoSuggestions()){
                return;
            }
            if(!self.autoSuggestionList){
                return;
            }
            if(self.autoSuggestionList && self.autoSuggestionList[rowId] && self.autoSuggestionList[rowId].length == 0){
                return;
            }
            var suggestion = self.autoSuggestionList[rowId];
            if(suggestion.bm){
                self.selectedMappingBM = suggestion.bm;
                self.selectedVDMPackage = suggestion.bm.getNestedParent();
                $("#selectedBM" + self.rowId).val(suggestion.bm.get('name'));
                $("#selectedBM" + self.rowId).attr('disabled', 'disabled');
                $("#selectedBMInfo" + self.rowId).show();
            }
            if(suggestion.business){
                self.selectedMappingBusiness = suggestion.business;
                $("#selectedBusiness" + self.rowId).val(suggestion.business.get('name'));
                $("#selectedBusiness" + self.rowId).attr('disabled', 'disabled');
                $("#selectedBusinessInfo" + self.rowId).show();
            }
            if(suggestion.provider && !suggestion.recipient){
                self.isMyProposition = true;
                $("#isMyProposition" + self.rowId)[0].checked = true;
                $("#selectedRecipient" + self.rowId).attr('disabled', 'disabled');
                $("#selectedRecipientRole" + self.rowId).attr('disabled', 'disabled');
                $("#selectedRecipientRoleInfo" + self.rowId).show();
            }
            if(suggestion.provider){
                self.selectedMappingProvider = suggestion.provider;
                $("#selectedProvider" + self.rowId).append(new Option(suggestion.provider.get('name'), suggestion.provider.id, true, true));
                $("#selectedProvider" + self.rowId).attr('disabled', 'disabled');
                $("#selectedProviderInfo" + self.rowId).show();
            }
            if(suggestion.providerRole){
                self.selectedMappingProviderRole = suggestion.providerRole;
                $("#selectedProviderRole" + self.rowId).append( new Option(suggestion.providerRole.get('name'), suggestion.providerRole.id, true, true));
                $("#selectedProviderRole" + self.rowId).attr('disabled', 'disabled');
                $("#selectedProviderRoleInfo" + self.rowId).show();
            }
            if(suggestion.recipient){
                self.selectedMappingRecipient = suggestion.recipient;
                $("#selectedRecipient" + self.rowId).append(new Option(suggestion.recipient.get('name'), suggestion.recipient.id, true, true));
                $("#selectedRecipient" + self.rowId).attr('disabled', 'disabled');
                $("#selectedRecipientInfo" + self.rowId).show();
            }
            if(suggestion.recipientRole){
                self.selectedMappingRecipientRole = suggestion.recipientRole;
                $("#selectedRecipientRole" + self.rowId).append( new Option(suggestion.recipientRole.get('name'), suggestion.recipientRole.id, true, true));
                $("#selectedRecipientRole" + self.rowId).attr('disabled', 'disabled');
                $("#selectedRecipientRoleInfo" + self.rowId).show();
            }
            if(suggestion.vp){
                self.VPsColl.push(suggestion.vp);
                self.selectedMappingVP = suggestion.vp;
                $("#selectedVP" + self.rowId).val(suggestion.vp.get('name'));
                $("#selectedVP" + self.rowId).attr('disabled', 'disabled');
                $("#selectedVPInfo" + self.rowId).show();
            }
            if(suggestion.pn){
                self.selectedMappingPN = suggestion.pn;
                $("#selectedPN" + self.rowId).val(suggestion.pn.get('name'));
                $("#selectedPN" + self.rowId).attr('disabled', 'disabled');
                $("#selectedPNInfo" + self.rowId).show();
            }
            if(suggestion.participant){
                self.selectedMappingParticipant = suggestion.participant;
                $("#selectedParticipant" + self.rowId).val(suggestion.participant.get('name'));
                $("#selectedParticipant" + self.rowId).attr('disabled', 'disabled');
                $("#selectedParticipantInfo" + self.rowId).show();
            }
            if(suggestion.relationship){
                var found = _.filter(self.RelationshipColl, function(obj) {return obj.toLowerCase() === suggestion.relationship.toLowerCase();});
                $("#selectedRelationship" + self.rowId).val(found[0]);
                $("#selectedRelationship" + self.rowId).attr('disabled', 'disabled');
                self.onChangeSelectedRelationship();
            }
            if(suggestion.recipientRelationship){
                var found = _.filter(self.RelationshipColl, function(obj) {return obj.toLowerCase() === suggestion.recipientRelationship.toLowerCase();});
                $("#selectedRelationship" + self.rowId).val(found[0]);
                $("#selectedRelationship" + self.rowId).attr('disabled', 'disabled');
                self.onChangeSelectedRelationship();
            }
            if(suggestion.participantType){
                var participantType = self.mappingObject[suggestion.participantType].name;
                $("#selectedParticipantType" + self.rowId).val(participantType);
                $("#selectedParticipantType" + self.rowId).attr('disabled', 'disabled');
            }
            if(suggestion.role){
                self.selectedMappingRole = suggestion.role;
                $("#selectedRole" + self.rowId).val(suggestion.role.get('name'));
                $("#selectedRole" + self.rowId).attr('disabled', 'disabled');
                $("#selectedRoleInfo" + self.rowId).show();
            }
        }
        prefillChildRowInputs(rowId){
            var self = this;
            // Pre-filling child rows inputs collections
            self.prefillChildRowInputCollections(rowId);
            var shapeType = self.getShapeType(rowId);
            if(shapeType == "vdml:BusinessModel"){
                self.bmTypeahead.setElement('#selectedBMDiv' + self.rowId).render();
                self.businessTypeahead.setElement('#selectedBusinessDiv' + self.rowId).render();
                self.prefillInputByDiagramBo("selectedBM",rowId);
                self.onChangeSelectedMappingBM();
            }else if(shapeType == "vdml:Role"){
                self.bmTypeahead.setElement('#selectedBMDiv' + self.rowId).render();
                self.businessTypeahead.setElement('#selectedBusinessDiv' + self.rowId).render();
                self.pnTypeahead.setElement('#selectedPNDiv' + self.rowId).render();
                self.participantTypeahead.setElement('#selectedParticipantDiv' + self.rowId).render();
                self.roleTypeahead.setElement('#selectedRoleDiv' + self.rowId).render();
                self.prefillInputByDiagramBo("selectedRole", rowId);//self.loadParticipants();
                self.prefillInputByAutoSuggestions(rowId);
            }else if(shapeType == "vdml:Enterprise" || shapeType == "vdml:Individual" || shapeType == "vdml:MarketSegment"){
                self.bmTypeahead.setElement('#selectedBMDiv' + self.rowId).render();
                self.pnTypeahead.setElement('#selectedPNDiv' + self.rowId).render();
                self.participantTypeahead.setElement('#selectedParticipantDiv' + self.rowId).render();
                self.roleTypeahead.setElement('#selectedRoleDiv' + self.rowId).render();
                self.prefillInputByDiagramBo("selectedParticipant", rowId);// self.loadParticipants()
                self.prefillInputByAutoSuggestions(rowId);
            }else if(shapeType == "vdml:ValueProposition"){
                self.bmTypeahead.setElement('#selectedBMDiv' + self.rowId).render();
                self.vpTypeahead.setElement('#selectedVPDiv' + self.rowId).render();
                //self.providerTypeahead.setElement('#selectedProviderDiv' + self.rowId).render();
                //self.recipientTypeahead.setElement('#selectedRecipientDiv' + self.rowId).render();
                //self.prefillInputByDiagramBo("selectedVP", rowId);
                self.loadVPs();
                self.prefillInputByAutoSuggestions(rowId);
            }
            self.bmTypeahead.on('selected', function (selectedBM) {
                if(self.matchField){
                    self.matchField.hide();
                }
                self.selectedMappingBM = selectedBM;
                self.onChangeSelectedMappingBM();
                $("#selectedBMInfo"+ self.rowId).show();
                //self.enableCompleteForBM();
            });
            if(self.businessTypeahead) {
                self.businessTypeahead.on('selected', function (selectedBusiness) {
                    if(self.matchField){
                        self.matchField.hide();
                    }
                    self.selectedBusiness = selectedBusiness;
                    $('#selectedBusinessInfo'+self.rowId).show();
                });
            }
            if(self.pnTypeahead){
                self.pnTypeahead.on('selected', function (selectedPN) {
                    if(self.matchField){
                        self.matchField.hide();
                    }
                    self.selectedMappingPN = selectedPN;
                    $("#selectedPNInfo"+ self.rowId).show();
                    self.loadParticipants(shapeType);
                });
            }
            if(self.vpTypeahead){
                self.vpTypeahead.on('selected', function (selectedVP) {
                    if(self.matchField){
                        self.matchField.hide();
                    }
                    self.selectedMappingVP = selectedVP;
                    self.refreshProviderAndRecipientBasedOnSelectedVP(self.selectedMappingVP)
                    //self.onChangeSelectedVP();
                    $("#selectedVPInfo"+ self.rowId).show();
                });
            }
            if(self.participantTypeahead){
                self.participantTypeahead.on('selected', function (selectedParticipant) {
                    if(self.matchField){
                        self.matchField.hide();
                    }
                    self.selectedMappingParticipant = selectedParticipant;
                    $("#selectedParticipantInfo"+ self.rowId).show();
                    self.refreshRoleTypeahead();
                });
            }
            if(self.roleTypeahead){
                self.roleTypeahead.on('selected', function (selectedRole) {
                    if(self.matchField){
                        self.matchField.hide();
                    }
                    self.selectedMappingRole = selectedRole;
                    $("#selectedRoleInfo"+ self.rowId).show();
                });
            }
            $('#selectedBM' + self.rowId).keyup(function(view) {
                var matchFound = _.filter(self.BMsColl.models, function(obj) {
                    return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase();
                });
                if (matchFound && matchFound.length) {
                    self.selectedMappingBM = matchFound[0];
                } else {
                    self.selectedMappingBM = null;
                    //self.matchField[0].innerHTML = "New";
                }
                self.onChangeSelectedMappingBM();
                //self.enableCompleteForBM();
            });
            $('#selectedBM' + self.rowId).dblclick(function (view) {
				$('#selectedBM'+ self.rowId).trigger('keyup');
				$('#selectedBM'+ self.rowId).focus();
			});
            $("#selectedPN" + self.rowId).keyup(function(view) {
                var matchFound = _.filter(self.PNsColl.models, function(obj) { return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase(); });
                if (matchFound && matchFound.length) {
                    self.selectedMappingPN = matchFound[0];
                } else {
                    self.selectedMappingPN = null;
                }
            });
            $('#selectedPN' + self.rowId).dblclick(function(view) {
                $('#selectedPN' + self.rowId).trigger('keyup');
                $('#selectedPN' + self.rowId).focus();
            });
            $("#selectedVP" + self.rowId).keyup(function(view) {
                var matchFound = _.filter(self.VPsColl.models, function(obj) { return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase(); });
                if (matchFound && matchFound.length) {
                    self.selectedMappingVP = matchFound[0];
                    self.refreshProviderAndRecipientBasedOnSelectedVP(self.selectedMappingVP);
                    $("#selectedVPInfo" + self.rowId).show();
                    //self.onChangeSelectedVP();
                } else {
                    self.selectedMappingVP = null;
                    $("#selectedProvider" + self.rowId).removeAttr('disabled');
                    $("#selectedProviderRole" + self.rowId).removeAttr('disabled');
                    if(!self.isMyProposition){
                        $("#selectedRecipient" + self.rowId).removeAttr('disabled');
                        $("#selectedRecipientRole" + self.rowId).removeAttr('disabled');
                    }
                    $("#selectedVPInfo" + self.rowId).hide();
                }
            });
            $('#selectedVP' + self.rowId).dblclick(function(view) {
                $('#selectedVP' + self.rowId).trigger('keyup');
                $('#selectedVP' + self.rowId).focus();
            });
            $('#selectedPN' + self.rowId).dblclick(function(view) {
                $('#selectedPN' + self.rowId).trigger('keyup');
                $('#selectedPN' + self.rowId).focus();
            });
            $("#selectedParticipant" + self.rowId).keyup(function(view) {
                var matchFound = _.filter(self.ParticipantsColl.models, function(obj) { return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase(); });
                if (matchFound && matchFound.length) {
                    self.selectedMappingParticipant = matchFound[0];
                    self.refreshRoleTypeahead();
                } else {
                    self.selectedMappingParticipant = null;
                }
            });
            $('#selectedParticipant' + self.rowId).dblclick(function(view) {
                $('#selectedParticipant' + self.rowId).trigger('keyup');
                $('#selectedParticipant' + self.rowId).focus();
            });
            $("#selectedRole" + self.rowId).keyup(function(view) {
                var matchFound = _.filter(self.rolesColl.models, function(obj) { return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase(); });
                if (matchFound && matchFound.length) {
                    self.selectedMappingRole = matchFound[0];
                } else {
                    self.selectedMappingRole = null;
                }
            });
            $('#selectedRole' + self.rowId).dblclick(function(view) {
                $('#selectedRole' + self.rowId).trigger('keyup');
                $('#selectedRole' + self.rowId).focus();
            });
            $('#selectedRelationship' + self.rowId).change(function (view) {
				self.selectedRelationship = $('#selectedRelationship'+ self.rowId).val();
				//self.loadParticipants(shapeType);
				self.onChangeSelectedRelationship(shapeType);
			});
            $('#selectedBMInfo'+self.rowId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({ id: self.selectedMappingBM.id });
                self.createDetailsTemplate(mappedElement);
            });
            $('#selectedBusinessInfo'+self.rowId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName('vdml.Participant').find({id:self.selectedMappingBusiness.id});
                self.createDetailsTemplate(mappedElement);
            });
            $('#selectedPNInfo'+self.rowId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName('vdml.BusinessNetwork').find({id:self.selectedMappingPN.id});
                self.createDetailsTemplate(mappedElement);
            });
            $('#selectedVPInfo'+self.rowId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({id:self.selectedMappingVP.id});
                self.createDetailsTemplate(mappedElement);
            });
            $('#selectedParticipantInfo'+self.rowId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName('vdml.Participant').find({id:self.selectedMappingParticipant.id});
                self.createDetailsTemplate(mappedElement);
            });
            $('#selectedRoleInfo'+self.rowId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName('vdml.Role').find({id:self.selectedMappingRole.id});
                self.createDetailsTemplate(mappedElement);
            });
            $('#selectedProviderInfo'+self.rowId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName('vdml.Provider').find({id:self.selectedMappingProvider.id});
                self.createDetailsTemplate(mappedElement);
            });
            $('#selectedProviderRoleInfo'+self.rowId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName('vdml.Role').find({id:self.selectedMappingProviderRole.id});
                self.createDetailsTemplate(mappedElement);
            });
            $('#selectedRecipientInfo'+self.rowId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName('vdml.Recipient').find({id:self.selectedMappingRecipient.id});
                self.createDetailsTemplate(mappedElement);
            });
            $('#selectedRecipientRoleInfo'+self.rowId).click(function(ev){
                var mappedElement = Backbone.Relational.store.getObjectByName('vdml.Role').find({id:self.selectedMappingRecipientRole.id});
                self.createDetailsTemplate(mappedElement);
            });
        }
        prefillChildRowInputCollections(rowId){
            var self = this;
            self.fillPackagesBM();
            if (self.packagesListBM().length > 0) {
                for (var i = 0; i < self.packagesListBM().length; i++) {
                    var pack = self.packagesListBM()[i];
                    var VDMPack = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: pack.id });
                    for (var i = 0; i < VDMPack.get('businessModel').models.length; i++) {
                        self.BMsColl.push({
                            id: VDMPack.get('businessModel').models[i].id,
                            name: VDMPack.get('businessModel').models[i].get('name')
                        });
                    }
                    self.BusinessColl.reset();
                    // get BusinessColl from package
                    VDMPack.getBusinessCollOfPackage(VDMPack, self.currentAlternative, self.BusinessColl);
                    DataManager.getDataManager().getAllDocumentsCollectionOfType(VDMPack.id, 'vdml.Collaboration', function(result) {
                        var repId = DataManager.getDataManager().get('viewAlternative');
                        for (var i = 0; i < result.length; i++) {
                            if (repId !== window.utils.getPrefix(result.at(i).get('id'))) {
                                var newId = repId + window.utils.getSuffix(result.at(i).get('id'));
                                if (window.utils.getElementModel(newId, ['vdml.BusinessNetwork'])) {
                                    continue;
                                }
                            }
                            var pn = Backbone.Relational.store.getObjectByName('vdml.BusinessNetwork').find({
                                id: result.at(i).get('id')
                            });

                            if (pn && self.currentAlternative.get('phaseDesignPart').findWhere({
                                    'beepReference': pn.getNestedParent().id
                                })) {
                                self.PNsColl.push({
                                    id: pn.id,
                                    name: pn.get('name')
                                });
                            }
                        }
                    }, false, null, false, null, null, true);
                }
            }
        }
        loadParticipants(shapeType) {
            var self = this;
            self.ParticipantsColl.reset();
            self.participantTypeahead.rerender([]);
            var bm = self.selectedMappingBM ? Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.selectedMappingBM.id }) : null;
            if (!bm) {
                return;
            }
            var packId = bm.getNestedParent();
            function filterParticipants(type1, type2) {
                var participants = [];
                DataManager.getDataManager().getAllDocumentsCollectionOfType(packId, type1, function(result) {
                    var repId = DataManager.getDataManager().get('viewAlternative');
                    for (var i = 0; i < result.length; i++) {
                        if (repId !== window.utils.getPrefix(result.at(i).get('id'))) {
                            var newId = repId + window.utils.getSuffix(result.at(i).get('id'));
                            if (window.utils.getElementModel(newId, [type2])) {
                                continue;
                            }
                        }
                        var relOrgUnit = Backbone.Relational.store.getObjectByName(type2).find({ id: result.at(i).get('id') });
                        if (relOrgUnit && self.currentAlternative.get('phaseDesignPart').findWhere({ 'beepReference': relOrgUnit.getNestedParent().id })) {
                            participants.push(relOrgUnit);
                        }
                    }
                    if (participants) {
                        for (var i = 0; i < participants.length; i++) {
                            self.selectedRelationship = $("#selectedRelationship" + self.rowId).val();
                            if (self.selectedRelationship === "Business") {
                                if (bm && participants[i] === bm.get('business')) {
                                    self.ParticipantsColl.push({ id: participants[i].id, name: participants[i].get('name') });
                                }
                            } else if (bm && participants[i] !== bm.get('business')) { // when existing bm is selected, filter for participans which is not business of the bm
                                self.ParticipantsColl.push({ id: participants[i].id, name: participants[i].get('name') });
                            } else if (!bm) {
                                self.ParticipantsColl.push({ id: participants[i].id, name: participants[i].get('name') });
                            }
                        }
                    }
                    participants.length = 0;
                }, false, null, false, null, null, true);
            }
            if (shapeType === "vdml:MarketSegment") {
                filterParticipants('vdml_Community', 'vdml.Community');
            } else if (shapeType === "vdml:Enterprise") {
                filterParticipants('vdml_OrgUnit', 'vdml.OrgUnit');
            } else if (shapeType === "vdml:Individual") {
                filterParticipants('vdml_Actor', 'vdml.Actor');
            } else if (shapeType === "vdml:Role") {
                //self.showPartType(true);
                filterParticipants('vdml_Community', 'vdml.Community');
                filterParticipants('vdml_OrgUnit', 'vdml.OrgUnit');
                filterParticipants('vdml_Actor', 'vdml.Actor');
            }
        }
        onChangeSelectedMappingBM() {
  	        var self = this;
            self.selectedBM = $("#selectedBM" + self.rowId).val();
            if(self.selectedBM){
                var bmFound = _.filter(self.BMsColl.models,function(obj){return obj.get('name').toLowerCase() === self.selectedBM.toLowerCase();});
                if(bmFound && bmFound.length){
                    self.selectedMappingBM = bmFound[0];
                    var businessModel = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({ id: bmFound[0].id });
                    self.selectedVDMPackage = businessModel.getNestedParent();
                }
            }
            if (self.selectedMappingBM) { //on selecting an existing bm, fill the business
                $('#selectedBMInfo' + self.rowId).show();
                var business = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id: self.selectedMappingBM.id}).get('business');
                $('#selectedBusiness' + self.rowId).val(business ? business.get('name') : 'My Business');
                if (business) {
                    self.selectedMappingBusiness = business;
                    $('#selectedBusiness' + self.rowId).attr('disabled', 'disabled');
                    $('#selectedBusinessInfo' + self.rowId).show();
                }
            } else {
                $('#selectedBMInfo' + self.rowId).hide();
                $('#selectedBusinessInfo' + self.rowId).hide();
            }
            var shapeType = self.getShapeType(self.rowId);
            if(shapeType == "vdml:ValueProposition"){
                self.onChangeSelectedVP();
            }else if(shapeType == "vdml:Role" || shapeType == "vdml:MarketSegment" || shapeType == "vdml:Enterprise" || shapeType == "vdml:Individual"){
                self.loadParticipants(shapeType);
            }
        }
        onChangeSelectedRelationship(shapeType) {
            var self = this;
            self.loadParticipants(shapeType);
        }
        onChangeSelectedVP() {
            var self = this;
            if(!self.selectedVDMPackage || !self.selectedMappingBM){
                return;
            }
            self.loadProviders(self.selectedVDMPackage.id);
            self.addListToDropdown("selectedProvider", self.ProvidersColl);
            if(self.ProvidersColl.length){
                //Loading Roles of first provider
                self.selectedMappingProvider = self.ProvidersColl[0];
                self.loadProviderRoles(self.selectedMappingProvider, null);
                self.addListToDropdown("selectedProviderRole", self.ProviderRolesColl, "Select a Role...");
            }
            self.loadReceivers(self.selectedVDMPackage.id);
            self.addListToDropdown("selectedRecipient", self.RecipientsColl, "Select a Participant...");
        }
        saveValueProposition(selectedVP, bm) {debugger
            var self = this;
            if (!selectedVP || selectedVP.length == 0) {
                return;
            }
            var providerRole = self.selectedMappingProviderRole ? bm.get('bmRole').get(self.selectedMappingProviderRole) : null;
            var receiverRole = self.selectedMappingRecipientRole ? bm.get('bmRole').get(self.selectedMappingRecipientRole) : null;
            var vpExists = self.isMyProposition ? bm.get('bmValueFormula').findWhere({'valueFormula':self.selectedMappingVP}) : bm.get('bmValueProposition').findWhere({'valueProposition':self.selectedMappingVP});
            if(vpExists){
                setDiagramId(self.selectedMappingVP);
            }else {
                saveValPropostion();
            }
            function saveValPropostion() {
                if (self.isMyProposition) {
                    bm.addBMValueFormula(self.selectedMappingVP, selectedVP, providerRole, selectedVP, self.currentAlternative, function(bmVF) {
                        var vp = bmVF.get('valueFormula');
                        setDiagramId(vp);
                    });
                } else {
                    bm.addBMValueProposition(self.selectedMappingVP, selectedVP, providerRole, receiverRole, selectedVP, self.currentAlternative, function(bmVP) {
                        //self.bmValueProposition(bmVP);
                        var vp = bmVP.get('valueProposition');
                        setDiagramId(vp);
                    });
                }
                //self.bmValueProposition().updateBMValueProposition(self.selectedValueProposition(), selectedVP, providerRole, receiverRole);
            };
            function setDiagramId(vp) {
                self.diagramBO.set('vdml:mid', vp.id);
                self.diagramBO.set('vdml:mpid', self.selectedVDMPackage.id);
                self.model.get('valueProposition').add(vp);
                /*if (self.parentView.chartViewInstance && self.parentView.chartViewInstance.initialize) {
                    var valueList = [];
                    _.each(self.parentView.model.get('valueProposition').models, function(vp) {
                        valueList = valueList.concat(vp.get('component').models.concat());
                    });
                    self.parentView.chartViewInstance.initialize(self.parentView.model, true, self.parentView, valueList);
                }*/
                if (!self.diagramBO.get('name') || self.diagramBO.get('name') === '') {
                    self.diagramBO.set('name', vp.get('name'));
                }
            }
        }
        createParticipantNetwork(selectedPN, bm){
            var self = this;
            var structuralChange = null;
            var participantNetwork;
            var newColl = false;
            var pnFound = _.filter(self.PNsColl.models, function(obj) {return obj.get('name').toLowerCase() === self.selectedRole.toLowerCase();});
            if (pnFound && pnFound.length) {
                self.selectedMappingPN = pnFound[0];
            }
            if (self.selectedMappingPN) {
                var pn = Backbone.Relational.store.getObjectByName("vdml.BusinessNetwork").find({id: self.selectedMappingPN.id});
                participantNetwork = bm.get('participantNetwork').findWhere({'collaboration':pn});
                self.createParticipant(bm, participantNetwork, newColl, structuralChange);
            } else {
                bm.createParticipantNetwork(self.selectedPN, null, self.currentAlternative, function(participantNetwork) {
                    //self.collaborations.add(participantNetwork.get("collaboration"));
                    if(self.selectedRelationship == 'Business'){
                        participantNetwork.get('collaboration').createAndAssignDefaultRole(bm);
                    }
                    self.createParticipant(bm, participantNetwork);
                });
            }
        }
        fetchCustomerType(typeStr) {
            var self = this;
			var type;
			for(var i=0;i<self.custTypeArray.length;i++){
				if(self.custTypeArray[i].name == typeStr){
					type = self.custTypeArray[i].type;
					break;
				}
			}
			return type;
		}
        createParticipant(bm, pn) {
            var self = this;
            var diagBO = self.diagramBO;
            var partFound = _.filter(self.ParticipantsColl.models, function(obj) {return obj.get('name').toLowerCase() === self.selectedParticipant.toLowerCase()});
            if (partFound && partFound.length) {
                self.selectedMappingParticipant = partFound[0];
            }
            if (self.selectedRelationship == "Customer") {
                self.createCustomer(bm, pn);
                /*bm.createNewCustomer(self.selectedParticipant.trim(), self.selectedParticipant.trim(), self.fetchCustomerType(self.selectedParticipantType), null, pn, self.currentAlternative, function(customer) {
                    bm.addNewCustomerRole(customer, self.selectedRole, self.selectedRole, pn, self.currentAlternative, function(newAssignment) {
                        console.log("Customer created==> " + self.selectedParticipant);
                        var role = newAssignment.get('assignedRole');
                        EcoMapMixin.setCustomerDiagramId(self.model, bm, diagBO, customer, role);
                    });
                });*/
            } else if (self.selectedRelationship == "Partner") {
                self.createPartner(bm, pn);
                /*bm.createNewPartner(self.selectedParticipant.trim(), self.selectedParticipant.trim(), self.fetchCustomerType(self.selectedParticipantType), null, pn, self.currentAlternative, function(networkPartner) {
                    bm.addNewPartnerRole(networkPartner, self.selectedRole, self.selectedRole, pn, self.currentAlternative, function(newAssignment) {
                        console.log("Partner created==> " + self.selectedParticipant);
                        var role = newAssignment.get('assignedRole');
                        EcoMapMixin.setPartnerDiagramId(self.model, bm, diagBO, networkPartner, role);
                    });
                });*/
            }else{
                self.createBusinessRole(bm, pn);
                /*bm.addNewRoleToBusiness(self.selectedMappingBusiness, self.selectedRole, self.selectedRole, pn, self.currentAlternative, function(assignment) {
                    var role = assignment.get('assignedRole');
                    EcoMapMixin.setBusinessDiagramId(self.model, bm, diagBO, participant, role, callback);
                });*/
            }
        }

        mapBusinessModel(bmElement, mappedElementName, pack, diagBO, business, businessName, callback) {
            var self = this;
            var bm;
            var vdm;

            function createBusiness(previousWorkspace) {
                function setDiagramId(diagBO, bm, bmCallback) {
                    if (diagBO && bm) {
                        diagBO.set('vdml:mid', bm.id);
                        diagBO.set('vdml:mpid', vdm.id);
                        self.model.get('businessModel').add(bm);
                        if (!diagBO.get('name') || diagBO.get('name') === '') {
                            diagBO.set('name', bm.get('name'));
                        }
                        if (!diagBO.get('description') || diagBO.get('description') === '') {
                            diagBO.set('description', bm.get('description'));
                        }
                    }
                    if (previousWorkspace) {
                        DataManager.getDataManager().setWorkspace(previousWorkspace, function() {
                            bmCallback(bm);
                        });
                    } else {
                        bmCallback(bm);
                    }

                }
                if (!bmElement) {
                    var bmDescription = mappedElementName;
                    if (diagBO && diagBO.$type === "vdml:BusinessModel") {
                        bmDescription = diagBO.description ? diagBO.description : mappedElementName;
                    } // bm creation instance getBusinessModelInstance
                    vdm.generateBusinessModelId(mappedElementName, bmDescription, vdm, self.currentAlternative, function(bmId) {
                        bm = vdm.getBusinessModelInstance(mappedElementName, bmDescription, bmId);
                        if (business) {
                            if (!(business instanceof Backbone.RelationalModel)) {
                                business = Backbone.Relational.store.getObjectByName('vdml.OrgUnit').find({
                                    id: business.id
                                });
                            }
                            bm.set('business', business);
                        } else {
                            var defaultBusiness = vdm.createDefaultBusiness(bm, businessName);
                        }
                        setDiagramId(diagBO, bm, function(bm) {
                            var currentPlan = DataManager.getDataManager().get('currentPlan');
                            currentPlan.getAllAlternateScenarios(function(altScenarios) {
                                if (!DataManager.getDataManager().altScenarios) {
                                    DataManager.getDataManager().altScenarios = {};
                                }
                                DataManager.getDataManager().altScenarios[currentPlan.get('id')] = altScenarios;
                                callback(bm);
                            });
                        });
                    });
                } else {
                    bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({
                        'id': bmElement.id
                    });
                    setDiagramId(diagBO, bm, callback);
                }
            }
            if (!pack) {
                function createVDMPackage(packCallback) {
                    var ValueDeliveryModel = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel');
                    var packageName = mappedElementName; //mappedElementName + " Package";
                    if (!self.currentAlternative) {
                        self.getCurrentAlt();
                    }
                    vdm = ValueDeliveryModel.createPackageWithName(packageName, packageName, self.currentAlternative);
                    packCallback();
                }

                var previousWorkspace = DataManager.getDataManager().getWorkspace();
                if (previousWorkspace.id != self.currentAlternative.id) {
                    DataManager.getDataManager().getWorkspaceDataWithId(self.currentAlternative.id, function(wsData) {
                        var currentWorkspace = wsData.get('workspace');
                        DataManager.getDataManager().setWorkspace(currentWorkspace, function() {
                            createVDMPackage(function() {
                                createBusiness(previousWorkspace);
                            });
                        });
                    });
                } else {
                    createVDMPackage(createBusiness);
                }

            } else {
                vdm = pack;
                createBusiness();
            }
        }
		calculateShapeProgress(shape) {
			var shapeProgress = 0;
			if (shape.name != '') {
				shapeProgress = shapeProgress + DataManager.getDataManager().get('progress').BusinessEcosystemMap.Label;
			}
			if (shape.mid != '') {
				shapeProgress = shapeProgress + DataManager.getDataManager().get('progress').BusinessEcosystemMap.Mapped;
			}
			return shapeProgress;
		}
		sortShapesInTableByName(left, right) {
			if(left.name && right.name){
            	return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
            }
        }
		cleanUp() {
			var self = this;
		    if (self.modeler) {
		        self.modeler.destroy();
		    }
		    if(ko.components.isRegistered('EcoMapDetails')){
    			ko.components.unregister('EcoMapDetails');
    		}
    		if(ko.components.isRegistered('EcoMapGuidance')){
    			ko.components.unregister('EcoMapGuidance');
    		}
    		if (ko.components.isRegistered('StrategyValuesChart')) {
                ko.components.unregister('StrategyValuesChart');
            }
			//window.utils.loadTemplate(null,true);
    		if(self.spliter){
    			self.spliter.enhsplitter('remove');
    		}
    		/*if(self.loadedCodedContainers){
		    	var codeContainersList = self.loadedCodedContainers;
		        _.each(codeContainersList, function (codeContainer) {
	            	codeContainer.save(null,{
		                success: function () {
        					DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
		                },
                        error:function(error){
                            console.log(error);
                            //DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
                        }
		            });
		        });
		        codeContainersList.length = 0;
		    }*/
		    if (self.highchartsDefaultTheme) {
                   Highcharts.setOptions(HighChartThemes.getDefaultThemeFont());
         	       Highcharts.setOptions(self.highchartsDefaultTheme);
            }
		}
		saveShapeDetailsData(view){
			var self = this;
			var found = false;
			for(var i=0; i < self.children.length; i++){
				if(self.children[i].modal == view.mappedElementModel){
					self.children[i].name = view.name();
					self.children[i].description = view.description();
					found = true;
					break;
				}
			}
			if(!found){
				self.children.push({'name':view.name(),'description':view.description(),'modal':view.mappedElementModel});
			}
		}
		saveSyncData(revision){
			var self = this;
			for(var i=0; i < self.children.length; i++){
				var vdmlModal = self.children[i].modal;
				if(revision){
					var modelSuffix = vdmlModal.id.substr(vdmlModal.id.lastIndexOf('@') + 1);
					var type = vdmlModal.get('type').replace('_', '.')
                    vdmlModal = Backbone.Relational.store.getObjectByName(""+type).find({ id: DataManager.getDataManager().get('viewAlternative') + modelSuffix });
				}
				if(vdmlModal) {
					vdmlModal.set('name',self.children[i].name);
					vdmlModal.set('description',self.children[i].description);
				}
			}
			self.children = [];
			EcoMapMixin.removeImageArtifact(self.deletedCodedContainers,self.model.getNestedParent());
			self.deletedCodedContainers = [];
		}
		createRevision(newXML,callback,depPacks) {
			var self = this;
			window.utils.startSpinner('revisionSpinner', "Creating a copy...");
			setTimeout(function(){
			    self.model.getNestedParent().createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), function (newPackage) {
			        var newRep = DataManager.getDataManager().getRepositoryId(newPackage.id);
			        var modelSuffix = self.model.id.substr(self.model.id.lastIndexOf('@') + 1);
			        var newDiagram = Backbone.Relational.store.getObjectByName("vdml.EcoMapDiagram").find({ id: newRep + modelSuffix });
			        newDiagram.set('data', newXML, { silent: true });
					var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
					var diagrams = newPackage.get('diagrams');
					for (var i = 0; i < diagrams.length; i++) {
						newPackage.fixDiagramOnRevision(diagrams.at(i), newRep, oldAltId);
					}
			        window.utils.stopSpinner('revisionSpinner');
			        callback(newPackage,newDiagram);
			    },depPacks);
			},10);
        }
		saveDiagram(view,event,callback,revisionEcoMap,vdmlPackageRevision,enableComplete){
			var self = this;
		    if (self.model) {
		    	window.utils.startSpinner('saveSpinner', "Saving Business Ecosystem Map...");
		    	setTimeout(function () {
					async function saveVdmioXML() {
						let result = new Promise(function(myResolve, myReject) {
						  myResolve("Works");
						});
						try {
							result = await self.modeler.saveXML({ format: true });
							var { xml } = result;
							if (xml) {
								//var oldData = self.model.get('data');
								if (window.checkContextForRevision()) {
									if(revisionEcoMap){
										if(vdmlPackageRevision){
											//var newXml = window.utils.replaceAll(xml,DataManager.getDataManager().get('currentWorkspace').id,DataManager.getDataManager().get('viewAlternative'));
											self.model.set('data', xml, { silent: true });
											self.saveSyncData(true);
										}else{
											self.model.set('data', xml, { silent: true });
											self.saveSyncData();
										}
										var oldAltId = DataManager.getDataManager().get('currentWorkspace').id;
										var newRep = DataManager.getDataManager().get('viewAlternative');
										var packEco = self.model.getNestedParent();
										var diagrams = packEco.get('diagrams');
										for (var i = 0; i < diagrams.length; i++) {
											packEco.fixDiagramOnRevision(diagrams.at(i), newRep, oldAltId);
										}
										self.enableComplete(false);
										//self.model.set('progress', self.progress);
										window.utils.stopSpinner('saveSpinner');
										if(callback){
											callback();
										}else {
                                            DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + revisionEcoMap.getNestedParent().get('id') + "/" + revisionEcoMap.getNestedParent().get('type') + "/" + revisionEcoMap.getNestedParent().get('version') + "/" + revisionEcoMap.id + "/" + revisionEcoMap.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
										}	
									}else {
										//self.model.set('data', newXml, { silent: true });
										if(!revisionEcoMap && !vdmlPackageRevision && !self.enableComplete()) {
											   window.utils.stopSpinner('saveSpinner');
											if(callback){
												callback();
											} 		
										}else {
											var ecoPackage = self.model.getNestedParent();
											//var depPacks = ecoPackage.get('dependentPackage')?ecoPackage.get('dependentPackage').models.concat():[];
											self.createRevision(xml,function(newPackage,newDiagram){
												self.enableComplete(false);
                                                self.saveSyncData(true);
												window.utils.stopSpinner('saveSpinner');
												if(callback){
													callback();
												}else{
													DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newPackage.get('id') + "/" + newPackage.get('type') + "/" + newPackage.get('version') + "/" + newDiagram.id + "/" + newDiagram.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
												}
											},[]);
										}
									}
								} else {
									if(vdmlPackageRevision){
										var xmlDoc = jQuery.parseXML(xml);
										var $xml = jQuery(xmlDoc);
										var alt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('currentWorkspace').get('id'));
										var vdmlPackIds = alt.getOwnedVDMLPackages();
										_.each(vdmlPackIds,function(packId){
											var pac = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: packId });
											if(pac) {
												var oldPackId = pac.get('copySource');
												var nodes = $xml.find('[mpid="' + oldPackId + '"]');
												nodes.each(function (callback, node) {
													node.setAttribute('mpid', packId);
													var oldmidSuffix = window.utils.getSuffix(node.getAttribute('mid'));
													node.setAttribute('mid', alt.id + oldmidSuffix);
												})
											}
										});
										xml = (new XMLSerializer()).serializeToString(xmlDoc);
									}
									self.model.set('data', xml);
									self.saveSyncData();
									/*debugger
									var zoomLevel = self.modeler.get("canvas").zoom();
									self.model.set('zoom', zoomLevel);
									var vbox = self.modeler.get("canvas").viewbox();
									if(vbox){
										self.model.set('viewbox',{'x':vbox.x,'y':vbox.y});
									}*/
									//self.model.set('progress', self.progress);
									self.enableComplete(false);
									window.utils.stopSpinner('saveSpinner');
									if(callback){
										callback();
									}
								}
							}else {
								self.enableComplete(false);
								window.utils.stopSpinner('saveSpinner');
								if(callback){
									callback();
								}
							}
						  } catch (err) {
							console.log(err.message, err.warnings);
						  }
					}
					saveVdmioXML();
			        /*self.modeler.saveXML({ format: true }, function (err, xml) {
			            				        
			        });*/
		        },100);
		    }else {
		    	callback();
		    }
		};
		
		hasUnsavedData(){
			var self = this;
        	if(self.enableComplete() /*&& !self.viewMode()*/) {
        		return true;	
        	}else {
        		return false;
        	}	
        };
		
		saveDataBeforeClosing(callback) {
			var self = this;
			self.saveDiagram(null,null,callback);
		};
		
		getViewportSize(w) {

		    // Use the specified window or the current window if no argument
		    w = w || window;

		    // This works for all browsers except IE8 and before
		    if (w.innerWidth != null) return { w: w.innerWidth, h: w.innerHeight };

		    // For IE (or any browser) in Standards mode
		    var d = w.document;
		    if (document.compatMode == "CSS1Compat")
		        return {
		            w: d.documentElement.clientWidth,
		            h: d.documentElement.clientHeight
		        };

		    // For browsers in Quirks mode
		    return { w: d.body.clientWidth, h: d.body.clientHeight };

		}
		sortByName(left, right) {
			return left.get('name').toLowerCase() === right.get('name').toLowerCase() ? 0 : (left.get('name').toLowerCase() < right.get('name').toLowerCase() ? -1 : 1);
		}
		shapeDeleteModel(businessObject,element){
			var self = this;
			var selectedBmsModelsList = [];
			self.businessObject = businessObject;
			var pack = self.model.getNestedParent();
			var htmlContent = "<div class=\"row\">";
			htmlContent = htmlContent + "<span style ='margin-left:15px'>"+DataManager.getDataManager().get('localeManager').get('DeleteShapeMsg')+"</span>";
			if(businessObject.mid /*&& !window.checkContextForRevision()*/){
				if(businessObject.$type == "vdml:BusinessModel"){
					htmlContent = "<span><input id='deleteShapeElement' type=\"checkbox\" style=\"cursor: pointer;\" checked /><span style ='margin-left:5px'>Delete Business Model from Plan</span></span>";
				}else {
					htmlContent  =  "<span style = 'font-weight:bold'>Do you want to delete this element from following Business Models? Deleting it may lead to loss of data, that cannot be recovered, do you want to continue ?</span><br></br>";
					htmlContent +=  "<table class='table table-bordered' style = 'width:450px;margin-left:50px'><thead><tr>";
					htmlContent +=  "<th>Business Model(s)</th></tr></thead>";
					htmlContent +=  "<tbody id= 'bmsModelsList'>";
					htmlContent +=  "</tbody></table>"
				}
			}
			htmlContent = htmlContent + "</div>";
			
			var dialog = bootbox.dialog({
				title: "<div>&nbsp;&nbsp;&nbsp;Delete Shape</div>",
				message: htmlContent,
				show: true,
				buttons:{
					main: {
                        label: "Cancel",
                        className: "btn btn-default",
                    },
					success:{
                        label:'<i id="ecomapShapeDeleteBtn" class="fa fa-check"></i> Delete',
                        className: "btn btn-complete shapeDelete", 
						callback: function() {
                            var oldPack = self.model.getNestedParent();
                            self.createRevisionBasedOnContext(oldPack, function (newPack) {
                                var rev = oldPack.id !== newPack.id;
                                var altId = DataManager.getDataManager().getRepositoryId(newPack.id);
                                if(rev){
                                    var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
                                    var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
                                    var map = newPack.get('diagrams').findWhere({ 'id': altId + bmId });
                                    var diagrams = newPack.get('diagrams');
                                    for (var i = 0; i < diagrams.length; i++) {
                                        newPack.fixDiagramOnRevision(diagrams.at(i), altId, oldAltId);
                                    }
                                    self.model = map;
                                    var xmlDoc = jQuery.parseXML(self.model.get("data"));
		                            var jqueryXml = jQuery(xmlDoc);
                                    jqueryXml.find('[id="' + element.id + '"]').remove();
                                    jqueryXml.find('[id="' + element.id + '_di"]').remove();
                                    var newXML = (new XMLSerializer()).serializeToString(xmlDoc);
                                    self.model.set('data',newXML);
                                    window.utils.stopSpinner('revisionSpinner');
                                }
                                EcoMapMixin.deleteSuggestion(self.model, businessObject.id, businessObject.$type);
                                self.modeler.get('modeling').removeElements([ element ]);
                                var selectedBm = [];
                                var bms = selectedBmsModelsList;
                                for (var i = 0; i < bms.length; i++) {
                                    if ($('#' + window.utils.htmlEscape(bms[i].id)).prop('checked')) {
                                        var bmId = bms[i].id;
                                        if(rev){
                                            var bmId = altId + window.utils.getSuffix(bmId);
                                        }
                                        var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id: bmId });
                                        selectedBm.push(bm);
                                    }
                                }
                                if($('#deleteShapeElement').prop('checked') || selectedBm.length > 0){
                                    if(rev){
                                        businessObject.mid = altId + window.utils.getSuffix(businessObject.mid);
                                        businessObject.mpid = altId + window.utils.getSuffix(businessObject.mpid);
                                        EcoMapMixin.deleteTargetElement(businessObject,selectedBm,function(){
                                            EcomapMixin.deleteSuggestion(self.model, businessObject.id, businessObject.$type);
                                            self.enableComplete(false);
                                            var newPackage = self.model.getNestedParent();
                                            DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newPackage.get('id') + "/" + newPackage.get('type') + "/" + newPackage.get('version') + "/" + self.model.id + "/" + self.model.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                                        });
                                    } else {
                                        window.utils.startSpinner('deletionSpinner', "Deleting Selected Element...");
                                        window.setTimeout(function () {
                                            EcoMapMixin.deleteTargetElement(businessObject,selectedBm,function(){
                                                EcoMapMixin.deleteSuggestion(self.model, businessObject.id, businessObject.$type);
                                                DataManager.getDataManager().invokeValueUpdates(function(){
                                                    if(self.chartViewInstance && self.chartViewInstance.initialize){
                                                        var valueList = [];
                                                        if(self.model){
                                                            _.each(self.model.get('valueProposition').models, function (vp) {
                                                                valueList = valueList.concat(vp.get('component').models.concat());
                                                            });
                                                        }
                                                        self.chartViewInstance.initialize(self.model, false, self, valueList);
                                                    }
                                                    window.utils.stopSpinner('deletionSpinner');
                                                },false);
                                            });
                                        },100);
                                    }
                                } else {
                                    if(rev){
                                        self.saveDiagram(null,null,null,null, null, null);
                                        var newPackage = self.model.getNestedParent();
                                        DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newPackage.get('id') + "/" + newPackage.get('type') + "/" + newPackage.get('version') + "/" + self.model.id + "/" + self.model.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                                    }
                                }
                            });						     
						}
					}
				}
			})
			dialog.attr("id", "bootbox_id");
			$('#bootbox_id').draggable();
			if(businessObject.mid /*&& !window.checkContextForRevision()*/){
				var htmlContent ='';
				pack.checkBmModels(self.businessObject, '', function(bmsList){
					if(bmsList && !$('#bmsLists').length){
					    bmsList.sort(self.sortByName);
						for(var i =0;i<bmsList.length;i++){
						  selectedBmsModelsList.push({id:bmsList[i].get('id')})
						  htmlContent = "<tr><td><span id = 'bmsLists' style = 'padding-left:5px'><input type=\"checkbox\" id='" + window.utils.htmlEscape(bmsList[i].id) + "'  value='" + bmsList[i].get('name') + "' style=\"cursor: pointer;\" checked /><span>" + " " + bmsList[i].get('name') + "</span></span></tr></td>";
						  $('#bmsModelsList').append(htmlContent);
						}
					}
				});
			}
		}
		
		setResizeHeight(view, event) {
			var self = window.vdmModelView;
			if (!self.showDashboardEcoMap){
				setTimeout(function () {
	                var propertiesNode = 0;
	                if ($('.propertiesArea').width() > 10) {
	                    propertiesNode = $('.propertiesArea').outerHeight();
	                }
	                //var baseHeight = _.max([bmNode.outerHeight(),propertiesNode.outerHeight()])+20;
	                //var topAreaHeight = $('.navbar').outerHeight();
					var modelHeight = $('#mainTab' +self.encodeId).outerHeight();
	                var lhsHeight = $("#js-canvas"+self.encodeId).outerHeight() + 30;
	                if($('#mainTab' +self.encodeId).find('.active a span').text() === DataManager.getDataManager().get('localeManager').get('shapeDetails')){
						lhsHeight = $('.table-responsive').outerHeight() + $('.table-responsive').outerHeight()/3 ;
					}else if($('#mainTab' +self.encodeId).find('.active a span').text() === DataManager.getDataManager().get('localeManager').get('Values')){
						lhsHeight = $(".chart").outerHeight() + $(".chart").outerHeight()/6;
					} else if(self.model){//map view
						modelHeight = self.model.get('height') ? modelHeight + self.model.get('height') + $('.navbar').outerHeight() : modelHeight;
					}
					var windowHeight = window.innerHeight;
	                var baseHeight = _.max([lhsHeight, propertiesNode, windowHeight, modelHeight]);
	                if (self.spliter) {
	                    self.spliter.css('height', baseHeight + 'px');
	                    if ($('.propertiesArea').width() < 5 && Math.abs($(window).width() - $('.modeler').width()) < 30) {
	                        var windowWidth = $(window).width() - 15;
	                        $('.splitter_bar').css('left', windowWidth);
	                    }
	                    $("#splitPanels"+self.encodeId).enhsplitter('refresh');
	                }
					var rightTab = $('#rightTab'+self.encodeId);
					if(rightTab.width() > 0){
						if($('#rightTab'+self.encodeId+' .active a')[0]){
							var propDivId = $('#rightTab'+self.encodeId+' .active a')[0].hash;
							if(propDivId !== null && $('#rightTab .dropdown .active a')[0]){
				            	propDivId = $('#rightTab .dropdown .active a')[0].hash;
				            }
							var propNode = $(''+propDivId);
						}
					}
					if(rightTab.width() > 10 && rightTab.children().last()[0].classList.contains('dropdown') /*&& rightTab.height() < 50*/){
						var dropdownDiv = rightTab.children().last();
						var appendList = dropdownDiv.find('li');
						var removedList = [];
						for(var i=0;i<appendList.length;i++){
								dropdownDiv.before(appendList[i]);
								removedList.push(appendList[i]);
						}
						if(appendList.length === removedList.length){
							dropdownDiv.remove();
						}
					}
					if(rightTab.width() > 10 && rightTab.width() < 415 && rightTab.children().length > 2/* && rightTab.height() > 50*/){
						var rightChildren = rightTab.children();
						var appendList = [];
						for(var i=rightChildren.length;i>0;i--){
							if(!rightChildren[i-1].classList.contains('dropdown')){
								appendList.push(rightChildren[i-1]);
								if(rightTab.width() < 375 && rightChildren[i-2]){
									appendList.push(rightChildren[i-2]);
									break;
								}else {
									break;
								}
							}
						}
						if(!rightChildren.last()[0].classList.contains('dropdown')){
							rightTab.append('<li class="dropdown"><a class="dropdown-toggle" data-toggle="dropdown" href="#"><span class="caret"></span></a><ul style="right:0" class="dropdown-menu pull-right"></ul></li>');
						}
						for(var i=0;i<appendList.length;i++){
							rightTab.children().last().find('ul').prepend(appendList[i]);
						}
					}
					if(rightTab.children().last()[0]&& rightTab.children().last()[0].classList.contains('dropdown') && propNode && propNode.parent()[0] && propNode.parent()[0].classList.contains('tab-content')){
						var dropdownDiv = rightTab.children().last();
						var appendList = dropdownDiv.find('li');
						for(var i=0;i<appendList.length;i++){
							appendList.eq(i).removeClass('active');
						}	
					}
					if($('#mainTab' +self.encodeId).find('.active a span').text() === DataManager.getDataManager().get('localeManager').get('BusinessEcosystemMap')){
					 //self.modeler.get("canvas").zoom("fit-viewport", true);
                     self.initializePresentation();
					}
	            }, 300);	
			}
        };
		getNumberOfMappings(mid,type,event) {
			var self = this;
		    var shapes = self.modeler._definitions.rootElements[0].flowElements;
		    var count =0;
		    
		    for (var i = 0; i < shapes.length; i++) {
		        if (event && event.type === 'shape.remove' && event.element.id === shapes[i].id) {
		            continue;
		        }
		        if (type !== 'vdml:BusinessItem') {
		            if(shapes[i].get('mid') === mid){
		                count++;
		            }
		        }else{
		            var flows = shapes.get('flows');
		            for(var j=0;j<flows.length;j++){
		                if(flows[j].get('mid') === mid){
		                    count++;
		                }
		            }
		        }
		    }
		    return count;
		}
		afterRenderView(redraw,view) {
			var self = this;
            //Suggestion.getInstance("shapeId","targetId","targetType","data",self.model);
            		    /*if (window.checkContextForRevision()) {
		        self.viewMode(true);
		    } else {
		        self.viewMode(false);
		        //self.enableComplete(false);
		    }*/
		    var container = $("#js-canvas"+self.encodeId);
		    container.empty();
		    /*if(redraw != true && self.showDashboardEcoMap){
		    	container.height(options.jsCanvasHeight);
		    }*/
			var fontSize = '12';
			if(self.model.get('fontsize')){
				fontSize = self.model.get('fontsize') ;//+ 'px';
			}
		    if (!self.editMode) {
		        self.modeler = new EcoNavigatedViewer({
		            container: container,
					additionalModules: [
						{
						  moveCanvas: [ 'value', '' ],
						  zoomScroll: [ 'value', '' ]
						}
					],
					textRenderer: {
						defaultStyle: {
							fontSize: fontSize,
						},
						externalStyle: {
							fontSize: fontSize,
						}
					}
		        });
		        $('#canvas-modal-footer'+self.encodeId+' .btn-complete').hide();
		    } else {
		        self.modeler = new EcoModeler({
		            keyboard: { bindTo: document },
		            container: container,
					textRenderer: {
						defaultStyle: {
							fontSize: fontSize,
						},
						externalStyle: {
							fontSize: fontSize,
						}
					}
		            /*propertiesPanel: {
                        parent: '#js-properties'
                    }*/
		        });
		        $('#canvas-modal-footer'+self.encodeId+' .btn-complete').show();
		        self.modeler.get('eventBus')
                .on(['commandStack.shape.create.postExecute', 'commandStack.shape.delete.postExecute', 'connection.remove', 'connection.changed', 'shape.changed', 'shape.remove'],
                function (event, element) {
                    if(event.type !== 'shape.changed' && event.type !== "connection.changed"){
						self.loadTable(event);
                        self.refreshNewMappingTable();
					}
                    if (event.element && event.type == "shape.remove") {
						var bo = event.element.businessObject;
						var backImgUrl = bo.backgroundUrl;
						if(backImgUrl){
							self.deletedCodedContainers.push(backImgUrl);
						}
                        var mid = bo.get('mid');
                        if (mid) {
                            var type = bo.$type;
                            if(self.getNumberOfMappings(mid,type,event) === 0){
                                if (type === 'vdml:MarketSegment' || type === 'vdml:Enterprise' || type === 'vdml:Role' || type === 'vdml:Individual') {
                                    self.model.get('participant').remove(mid);
                                } else if (type === 'vdml:BusinessModel') {
                                    self.model.get('businessModel').remove(mid);
                                } else if (type === 'vdml:ValueProposition') {
                                    self.model.get('valueProposition').remove(mid);
                                }
                             }
                        }
                    }
                    self.enableComplete(true);
                });
		    }
		    self.openDiagram(self.model.get('data'));
		    if(!self.showDashboardEcoMap){
				var valueList = [];
				if(self.model){
					_.each(self.model.get('valueProposition').models, function (vp) {
						valueList = valueList.concat(vp.get('component').models.concat());
					});
				}
				self.chartViewInstance.initialize(self.model, false, self, valueList);
		    	self.spliter = $("#splitPanels"+self.encodeId).enhsplitter({ minSize: 0, onDrag: window.utils.splitterBoundary, collapse: 'right', position: '64%', handle: 'block', splitterSize: '9px', height: $("#js-canvas"+self.encodeId).outerHeight() + $('.table-responsive').outerHeight(), onDragEnd: self.setResizeHeight });
	            self.spliter.enhsplitter('collapse');
	            $(window).resize(function (view, event) {
	                this.timer = clearTimeout();
					this.timer = setTimeout(function(){self && self.setResizeHeight && self.setResizeHeight();}, 200);
	            });
	            $('#mainTab' + self.encodeId + ' a').click(function (e) {
                    e.preventDefault();
                    $(this).tab('show');
					self.completeButton(true);
					self.switchButton(true);
					if($(this)[0].className === "ChartValues"){
					   self.completeButton(false);
					   self.switchButton(false);
					}
					/*self.zoomButton(false);
					if ($(this)[0].className === "showMap") {
						self.zoomButton(true);
					}*/
					if ($(this)[0].className === "ShapeDetails") {
						self.loadTable();
                        self.switchButton(true);
                        self.refreshNewMappingTable();
                        /*if(self.dataTable) {
                            self.dataTable.destroy(true);
                            self.loadNewMappingTable();
                        }else{
                            self.loadNewMappingTable();
                        }*/
					}
                    self.setResizeHeight();
                });
	            $('#rightTab'+self.encodeId+' a').click(function (e) {
		            e.preventDefault();
		            if ($(this).text() === "Collaboration") {
		                return false;
		            }
		            $(this).tab('show');
		            if ($(this).text() === "Guidance") {
		                if ($('#guidanceTab'+self.encodeId).is(':empty')) {
		                   $.get('js/app/version1/views/help/views/properties/EcoMapGuidanceTemplate.html',function(viewStr){
						        self.countGuidanceClicks++;
								$('#guidanceTab'+self.encodeId).empty();
								$('#guidanceTab'+self.encodeId).append(viewStr);
								if (self.configObj && self.configObj.guidance) {
									$('#guidanceTab'+self.encodeId).append(self.configObj.guidance);
								}
								window.utils.applyFontSize($('#guidanceTab'+self.encodeId));
								self.setResizeHeight();
						   })
						} else {
		                    self.setResizeHeight();
		                }
		            } else {
		                self.setResizeHeight();
		            }
		        });
				container.resizable({
					stop: _.bind(function (event, ui) {
						if(ui.size.height >= 370){
								var self = this;
								self.model.set('height', ui.size.height);
						}
					}, self)
				});	
				self.setResizeHeight();
                const dialogConfig = {
                    title: "<div><img class='smallIcon' src='img/icons/icons_21.png'>&nbsp;&nbsp;&nbsp;"+DataManager.getDataManager().get('localeManager').get('EcoMapSteps')+"</div>",
                    videos: [
                        { id: 'kncqJOdmG_c', title: '', width: '25vw', height: '14vw',description:DataManager.getDataManager().get('localeManager').get('EcomapDescription')},
                    ],
                };
                DataManager.getDataManager().handleVideoClick(dialogConfig,'EcomapVideo');
		    }else{
		    	$('#splitPanels'+self.encodeId).contents().unwrap();
		    	$('.dashboardEcoMap').hide();
				$('.bjs-powered-by').hide();
				$("#js-canvasresize"+self.encodeId).hide();
				$('#mainTab' + self.encodeId).hide();
				if(self.editMode){
					self.completeButton(true);
					$('#complete' + self.encodeId).css('margin-top','-20px')
				}else{
				    self.completeButton(false);
					//self.zoomButton(false);
				}
				if(redraw !== true && self.parentView && self.parentView){// when ecomap is added
					window.vdmModelView.setResizeHeight();
				}
			}
		};

		handlePublicMenus(){
			var self = this;
			DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-wrench","Show Object Explorer",$.proxy(this.showobjectExplorerTree,this));
			DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-check", "Validation", $.proxy(this.autoValidation, this));
		};
		autoValidation() {
            var self = this;
            var addOptions = this;
            window.getAndCreateModalDialog(true, self.encodeId, EcoMapDiagramMixin, self.model, "mapValidation", closeView, addOptions);
            function closeView() {
                window.cleanDialogModel(self.encodeId, EcoMapDiagramMixin);
            }
        };
		showobjectExplorerTree(){
			var self=this;
			var vdm = self.model.getNestedParent();
			var addOptions= this;
			var id = window.utils.htmlEscape(window.guidGenerator());
			window.getAndCreateModalDialog(true,id,EcoMapDiagramMixin,vdm,"explorerModelJson",closeView,addOptions);
			function closeView(){
				window.cleanDialogModel(id,EcoMapDiagramMixin);
			}
		};
		initializePresentation(){
			var self = this;
			self.modeler.get("canvas").resized();
            self.modeler.get("canvas").zoom("fit-viewport", true);
        	//self.afterRenderView(true);
        };
        
		resizeEcoElement(shapeId,newBounds){
			var shape = self.modeler.get('elementRegistry')._elements[shapeId].element;
			self.modeler.get('modeling').resizeShape(shape, newBounds);
        };

        getCurrentAlt(){
            var self = this;
            var phase = DataManager.getDataManager().get('currentPlan').get('phase');
            var alternative = DataManager.getDataManager().get('currentWorkspace').get("id");
            var tempAlt = DataManager.getDataManager().get('viewAlternative') ? DataManager.getDataManager().get('viewAlternative') : alternative;
            for (var j = 0; j < phase.length; j++) {
                var alts = phase.at(j).get('phaseAlternative');
                var viewAlternativeModel = alts.findWhere({
                    'id': tempAlt
                });
                if (viewAlternativeModel) {
                    self.currentAlternative = viewAlternativeModel;
                    break;
                }
            }
        }
        createDetailsTemplate (mappedElement) {
            var self = this;
            var currentAltId = DataManager.getDataManager().get('viewAlternative');
            var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
            var currentAlt = docTypeObj.find({
                id: currentAltId
            });
            var scenario = currentAlt.getMainScenario(mappedElement.getNestedParent());
            var id = window.utils.htmlEscape(window.guidGenerator());
            var options = {};
            options.context = scenario;
            options.mappedModal = mappedElement;
            options.width = '800px';
            var EcoMapDiagramMixin = Backbone.Relational.store.getObjectByName("vdml.EcoMapDiagramMixin");
            window.getAndCreateModalDialog(self.model, id, EcoMapDiagramMixin, self.model, "TargetDetails", null, options);
        }
        resetTypeaheadCollections(){
            var self = this;
            //self.rowId = null;
            self.BMsColl.reset();
            self.BusinessColl.reset();
            self.PNsColl.reset();
            self.ParticipantsColl.reset();
            self.isMyProposition = false;
            self.autoSuggestionList = null;
            self.incomingShape = null;
            self.outgoingShape = null;
            self.rolesColl.reset();
            self.ProvidersColl = ["Select a Participant..."];
            self.ProviderRolesColl = ["Select a Role..."];
            self.RecipientsColl = ["Select a Participant..."];
            self.RecipientRolesColl = ["Select a Role..."];
            self.tempArrToStoreSuggestions = {};
        }
        removeMapping() {
            var self = this;
            var oldPack = self.model.getNestedParent();
            self.createRevisionBasedOnContext(oldPack, function(pack) {
                var model, altId = DataManager.getDataManager().getRepositoryId(pack.id);
                var modeler = self.modeler;
                var shapes = modeler._definitions.rootElements[0].flowElements;
                var businessObject = self.diagramBO;
                var mid = businessObject.mid;
                var type = businessObject.$type;
                if (pack.id !== oldPack.id) {
                    var modelId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
                    var model = pack.get('diagrams').findWhere({
                        'id': altId + modelId
                    });
                    var diagrams = pack.get('diagrams');
                    for (var i = 0; i < diagrams.length; i++) {
                        pack.fixDiagramOnRevision(diagrams.at(i), altId);
                    }
                    var hasMoreMappings = false;
                    if (shapes) {
                        for (var i = 0; i < shapes.length; i++) {
                            if (mid === shapes[i].get('mid')) {
                                //EcoMapMixin.deleteSuggestion(self.model, shapes[i].id);
                                hasMoreMappings = true;
                                break;
                            }
                            if (shapes[i].$type === "vdml:Perspective") {
                                var childrenShapes = shapes[i].flowElements;
                                if (childrenShapes && childrenShapes.length > 0) {
                                    for (var j = 0; j < childrenShapes.length; j++) {
                                        if (mid === childrenShapes[j].get('mid')) {
                                            hasMoreMappings = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (!hasMoreMappings) {
                        var modelType = self.mappingObject[type];
                        if (!modelType) {
                            modelType = self.mappingObject[type.replace("vdml", "smm")];
                        }
                        var modelColl = modelType ? model.get(modelType.collectionName) : null;

                        if (mid.indexOf(window.plansKey) != -1) {
                            var newMid = mid;
                        } else {
                            newMid = altId + mid.substr(mid.lastIndexOf('@') + 1, mid.length);
                        }
                        if (modelColl) {
                            modelColl.remove(newMid);
                        }
                    }
                    self.enableComplete(false);
                    self.cleanModal();
                    window.utils.stopSpinner('revisionSpinner');
                    self.loadTable();
                    self.refreshNewMappingTable();
                } else {
                    model = self.model;
                    //var capRequirement = self.mappedElementModel.get('capabilityRequirement') ? self.mappedElementModel.get('capabilityRequirement') : self.mappedElementModel.get('capability');
                    self.diagramBO.mid = undefined;
                    self.diagramBO.mpid = undefined;
                    self.diagramBO.syncName = undefined;
                    var hasMoreMappings = null;
                    if (shapes) {
                        for (var i = 0; i < shapes.length; i++) {
                            if (mid === shapes[i].get('mid')) {
                                //EcoMapMixin.deleteSuggestion(self.model, shapes[i].id);
                                hasMoreMappings = true;
                                break;
                            }
                            if (shapes[i].$type === "vdml:Perspective") {
                                var childrenShapes = shapes[i].flowElements;
                                if (childrenShapes && childrenShapes.length > 0) {
                                    for (var j = 0; j < childrenShapes.length; j++) {
                                        if (mid === childrenShapes[j].get('mid')) {
                                            hasMoreMappings = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (type == "vdml:Value") {
                        var shapename = self.diagramBO.name;
                        var shapeElement = self.modeler.get('elementRegistry')._elements[self.diagramBO.id].element;
                        self.modeling.updateLabel(shapeElement, shapename);
                    }
                    if (!hasMoreMappings) {
                        var modelType = self.mappingObject[type];
                        if (!modelType) {
                            modelType = self.mappingObject[type.replace("vdml", "smm")];
                        }
                        var modelColl = modelType ? model.get(modelType.collectionName) : null;
                        EcoMapMixin.deleteSuggestion(self.model, businessObject.id, businessObject.$type);
                        modelColl.remove(mid);
                        if ((type == "vdml:Value" || type == "vdml:Activity" || type == "vdml:ValueProposition" || type == "vdml:ValueStream") && self.chartViewInstance && self.chartViewInstance.initialize) {
                            //self.chartViewInstance.initialize(self.model, true, self, self.getValueList(self.model));
                        }
                    }
                    self.loadTable();
                    self.refreshNewMappingTable();
                }
            })
        }
        getIncomingAndOutgoingShapes(){
            var self = this;
            var incomingShapes = self.diagramBO.incoming;
            if (incomingShapes && incomingShapes.length) {
                var providerShape = incomingShapes[0].sourceRef.businessObject;
                if (!providerShape) {
                    self.incomingShape = incomingShapes[0].sourceRef;
                    //self.loadProviders();
                }
            }
            var outgoingShapes = self.diagramBO.outgoing;
            if (outgoingShapes && outgoingShapes.length) {
                var providerShape = outgoingShapes[0].targetRef.businessObject;
                if (!providerShape) {
                    self.outgoingShape = outgoingShapes[0].targetRef;
                    //self.loadReceivers();
                }
            }
        }
        createAutoSuggestions(){
            var self = this;
            var currentShapeType = self.diagramBO.$type;
            if(currentShapeType == "vdml:BusinessModel"){
                return;
            }
            // check and fetch incoming and outgoing shapes
            self.getIncomingAndOutgoingShapes();
            if(!self.incomingShape && !self.outgoingShape){
                return;
            }
            self.incomingShapeMappingExists = false;
            self.outgoingShapeMappingExists = false;
            if((self.incomingShape && self.incomingShape.mid)){
                self.incomingShapeMappingExists = true;
            }
            if((self.outgoingShape && self.outgoingShape.mid)){
                self.outgoingShapeMappingExists = true;
            }
            if(!self.incomingShapeMappingExists && !self.outgoingShapeMappingExists){
                return;
            }
            // Fetching BM and package from Incoming or outgoing shapes
            var bmArr = self.getBMByIncomingOrOutgoingShapePackageId();
            self.autoSuggestionList = [];
            var providerList = [];
            var recipientList = [];
            if(currentShapeType == "vdml:ValueProposition"){
                if(self.incomingShapeMappingExists){
                    self.createSuggestionsBasedOnIncoimgShape(providerList, bmArr.bm, self.incomingShape);
                }
                if(self.outgoingShapeMappingExists){
                    self.createSuggestionsBasedOnOutgoingShape(recipientList, bmArr.bm, self.outgoingShape);
                }
                //self.autoSuggestionList[self.rowId] = {"bm": arr["bm"], "business": arr["business"], "provider": arr["provider"], "providerRole": arr["providerRole"], "recipient": arr["recipient"], "recipientRole": arr["recipientRole"]};
                //self.autoSuggestionList[self.rowId] = bmList;
                //self.autoSuggestionList[self.rowId].concat(providerList, recipientList);
            }else if(currentShapeType == "vdml:Role" || currentShapeType == "vdml:Enterprise" || currentShapeType == "vdml:MarketSegment" || currentShapeType == "vdml:Individual"){
                if(self.incomingShapeMappingExists){
                    self.createSuggestionsBasedOnIncoimgShape(providerList, bmArr.bm, self.incomingShape);
                }else if(self.outgoingShapeMappingExists){
                    self.createSuggestionsBasedOnIncoimgShape(providerList, bmArr.bm, self.outgoingShape);
                }
            }
            self.autoSuggestionList[self.rowId] = {"bm": bmArr.bm, "business": bmArr.business, "provider": providerList.provider, "providerRole": providerList.providerRole, "recipient": recipientList.recipient, "recipientRole": recipientList.recipientRole, "recipientRelationship": recipientList.recipientRelationship, "vp":providerList.vp, "pn": providerList.pn, "relationship": providerList.relationship, "participant": providerList.participant, "participantType": providerList.participantType, "role": providerList.role};
        }
        createSuggestionsBasedOnIncoimgShape(arr, bm, shape){
            var self = this;
            if(shape.$type == "vdml:Role"){
                var participantRole = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: shape.mid });
                var assignment = participantRole.get('roleAssignment').models[0];
                var participant = assignment.get('participant');
                arr["provider"] = participant;
                arr["providerRole"] = participantRole;
                var pn = participantRole.get('collaborationRoleOwner');
                arr["pn"] = pn;
                return arr;
            }else if(shape.$type == "vdml:MarketSegment" || shape.$type == "vdml:Enterprise" || shape.$type == "vdml:individual"){
                var shapeType = self.mappingObject[shape.$type].complexType;
                var participant = Backbone.Relational.store.getObjectByName(shapeType).find({ id: shape.mid });
                var bmRoles = bm.get('bmRole');
                bmRoles.each(function(role) {
                    var roleAssignments = role.get('roleAssignment');
                    for (var i = 0; i < roleAssignments.length; i++) {
                        var assignment = roleAssignments.at(i);
                        if (assignment.get('participant').id === participant.id) {
                            arr["provider"] = participant;
                            arr["providerRole"] = role;
                            var pn = role.get('collaborationRoleOwner');
                            arr["pn"] = pn;
                            return arr;
                        }
                    }
                });
            }else if(shape.$type == "vdml:ValueProposition"){
                var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: shape.mid });
                var providerRole = vp.get('provider');
                var pn = providerRole.get('collaborationRoleOwner');
                var providerAssignment = providerRole.get('roleAssignment').models[0];
                var providerParticipant = providerAssignment.get('participant');
                var relationship = bm.getRoleBMRelationship(bm, providerParticipant, providerRole);
                arr["participant"] = providerParticipant;
                arr["role"] = providerRole;
                arr["participantType"] = providerParticipant.get('type');
                arr["vp"] = vp;
                arr["pn"] = pn;
                arr["relationship"] = relationship;
                var recipientRole = vp.get('recipient');
                var recipientName = null;
                var recipientRoleName = null;
                if (recipientRole) {
                    var recipientAssignment = recipientRole.get('roleAssignment').models[0];
                    var recipientParticipant = recipientAssignment.get('participant');
                    var recipientRelationship = bm.getRoleBMRelationship(bm, recipientParticipant, recipientRole);
                    arr["recipient"] = recipientParticipant;
                    arr["recipientRole"] = recipientRole;
                    arr["recipientRelationship"] = recipientRelationship;
                }
            }
        }
        createSuggestionsBasedOnOutgoingShape(arr, bm){
            var self = this;
            if(self.outgoingShape.$type == "vdml:Role"){
                var participantRole = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: self.outgoingShape.mid });
                var assignment = participantRole.get('roleAssignment').models[0];
                var participant = assignment.get('participant');
                arr["recipient"] = participant;
                arr["recipientRole"] = participantRole;
                var pn = participantRole.get('collaborationRoleOwner');
                arr["pn"] = pn;
                return arr;
                //autoSuggestionList.push({"provider": participant, "providerRole": role});
            }else if(self.outgoingShape.$type == "vdml:MarketSegment" || self.outgoingShape.$type == "vdml:Enterprise" || self.outgoingShape.$type == "vdml:individual"){
                var shapeType = self.mappingObject[self.outgoingShape.$type].complexType;
                var participant = Backbone.Relational.store.getObjectByName(shapeType).find({ id: self.outgoingShape.mid });
                var bmRoles = bm.get('bmRole');
                bmRoles.each(function(role) {
                    var roleAssignments = role.get('roleAssignment');
                    for (var i = 0; i < roleAssignments.length; i++) {
                        var assignment = roleAssignments.at(i);
                        if (assignment.get('participant').id === participant.id) {
                            arr["recipient"] = participant;
                            arr["recipientRole"] = role;
                            var pn = role.get('collaborationRoleOwner');
                            arr["pn"] = pn;
                            return arr;
                        }
                    }
                });
            }
        }
        getBMByIncomingOrOutgoingShapePackageId() {
            var self = this;
            var packId;
            var mid;
            var shapeType;
            var bmArr = [];
            if(self.incomingShapeMappingExists){
                packId = self.incomingShape.mpid;
                mid = self.incomingShape.mid;
                shapeType = self.incomingShape.$type;
            }else {
                packId = self.outgoingShape.mpid;
                mid = self.outgoingShape.mid;
                shapeType = self.outgoingShape.$type;
            }
            if(!packId){
                return;
            }
            var VDMPack = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: packId });
            for (var i = 0; i < VDMPack.get('businessModel').models.length; i++) {
                var models; var found;
                if(shapeType == "vdml:Role"){
                    models = VDMPack.get('businessModel').models[i].get('bmRole').models;
                    found = _.filter(models, function(obj) {return obj.id == mid});
                }else if(shapeType == "vdml:ValueProposition"){
                    models = VDMPack.get('businessModel').models[i].get('bmValueProposition').models;
                    found = _.filter(models, function(obj) {return obj.get('valueProposition').id == mid});
                }else if(shapeType == "vdml:Enterprise"||shapeType == "vdml:MarketSegment"||shapeType == "vdml:Individual"){
                    var participantFound = [];
                    var customers = VDMPack.get('businessModel').models[i].get('bmCustomer').models;
                    var partners = VDMPack.get('businessModel').models[i].get('bmNetworkPartner').models;
                    var business = VDMPack.get('businessModel').models[i].get('business');
                    //participants.concat(customers, partners, business);
                    var customerFound = _.filter(customers, function(obj) {return obj.get('customer').id == mid});
                    var partnerFound = _.filter(partners, function(obj) {return obj.get('networkPartner').id == mid});
                    var businessFound = _.filter(business, function(obj) {return obj.id == mid});
                    found = participantFound.concat(customerFound, partnerFound, businessFound);
                }
                if (found && found[0]) {
                    var bm = VDMPack.get('businessModel').models[i];
                    var business = VDMPack.get('businessModel').models[i].get('business');
                    bmArr["bm"] = bm;
                    bmArr["business"] = business;
                    break;
                }
            }
            return bmArr;
        }
        createPartner(bm, pn) {
            var self = this;
            var bmPartner = self.selectedMappingParticipant ? bm.get('bmNetworkPartner').findWhere({'networkPartner': self.selectedMappingParticipant.id}) : null;
            if (!bmPartner) {
                bm.createNewPartner(self.selectedParticipant.trim(), self.selectedParticipant.trim(), self.fetchCustomerType(self.selectedParticipantType), null, pn, self.currentAlternative, function(bmPartner) {
                    self.createPartnerRole(bmPartner, pn, bm);
                });
            } else {
                bmPartner = bm.createBmNetworkPartner(self.selectedParticipant.trim(), self.selectedParticipant.trim(), self.selectedMappingParticipant);
                self.createPartnerRole(bmPartner, pn, bm);
            }
        }
        createCustomer(bm, pn) {
            var self = this;
            var bmCustomer = self.selectedMappingParticipant ?bm.get('bmCustomer').findWhere({'customer': self.selectedMappingParticipant.id}) : null;
            if (!bmCustomer) {
                bm.createNewCustomer(self.selectedParticipant.trim(), self.selectedParticipant.trim(), self.fetchCustomerType(self.selectedParticipantType), null, pn, self.currentAlternative, function(bmCustomer) {
                    self.createCustomerRole(bmCustomer, pn, bm);
                });
            } else {
                bmCustomer = bm.createBmCustomer(self.selectedParticipant.trim(), self.selectedParticipant.trim(), self.selectedMappingParticipant);
                self.createCustomerRole(bmCustomer, pn, bm);
            }
        }
        createCustomerRole(customer, pn, bm) {
            var self = this;
            var roleFound = _.filter(self.rolesColl.models, function(obj) {
                return obj.get('name').toLowerCase() === self.selectedRole.toLowerCase()
            });
            if (roleFound && roleFound.length) {
                self.selectedMappingRole = roleFound[0];
                //self.onChangeSelectedRole();
            }
            if (!self.selectedMappingRole) {
                bm.addNewCustomerRole(customer, self.selectedRole, self.selectedRole, pn, self.currentAlternative, function(newAssignment) {
                    console.log("Customer Role created==> " + self.selectedRole);
                    var role = newAssignment.get('assignedRole');
                    EcoMapMixin.setCustomerDiagramId(self.model, bm, self.diagramBO, customer, role);
                    if (self.updateVPBasedOnTheRecipientMapping) {
                        self.updateVPBasedOnTheRecipientMapping.set('recipient', role);
                    }
                });
            } else {
                var custRole = Backbone.Relational.store.getObjectByName('vdml.Role').find({id:self.selectedMappingRole.id});
                bm.get('bmRole').add(custRole);
                customer.get('customerRole').add(custRole);
                bm.checkRoleExistsForPartner(customer.get('customer'), custRole, true);
                EcoMapMixin.setCustomerDiagramId(self.model, bm, self.diagramBO, customer, custRole);
                if (self.updateVPBasedOnTheRecipientMapping) {
                    self.updateVPBasedOnTheRecipientMapping.set('recipient', custRole);
                }
            }
        }
        createPartnerRole(partner, pn, bm) {
            var self = this;
            var roleFound = _.filter(self.rolesColl.models, function(obj) {
                return obj.get('name').toLowerCase() === self.selectedRole.toLowerCase()
            });
            if (roleFound && roleFound.length) {
                self.selectedMappingRole = roleFound[0];
                //self.onChangeSelectedRole();
            }
            if (!self.selectedMappingRole) {
                bm.addNewPartnerRole(partner, self.selectedRole, self.selectedRole, pn, self.currentAlternative, function(newAssignment) {
                    console.log("partner Role created==> " + self.selectedRole);
                    var role = newAssignment.get('assignedRole');
                    EcoMapMixin.setPartnerDiagramId(self.model, bm, self.diagramBO, partner, role);
                    if (self.updateVPBasedOnTheRecipientMapping) {
                        self.updateVPBasedOnTheRecipientMapping.set('recipient', role);
                    }
                });
            } else {
                var partRole = Backbone.Relational.store.getObjectByName('vdml.Role').find({id:self.selectedMappingRole.id});
                bm.get('bmRole').add(partRole);
                partner.get('networkPartnerRole').add(partRole);
                bm.checkRoleExistsForCustomer(partner.get('networkPartner'), partRole, true);
                EcoMapMixin.setPartnerDiagramId(self.model, bm, self.diagramBO, partner, partRole);
                if (self.updateVPBasedOnTheRecipientMapping) {
                    self.updateVPBasedOnTheRecipientMapping.set('recipient', partRole);
                }
            }
        }
        createBusinessRole(bm, pn) {
            var self = this;
            // In case: of creating new BM with Business participant type
            if (!self.selectedMappingBusiness) {
                bm.get('business').set('name', self.selectedParticipant);
                //var businessDesc = self.diagramBO.description ? self.diagramBO.description : self.selectedParticipant;
                bm.get('business').set('description', self.selectedParticipant);
            }
            var participant = bm.get('business');
            if (!self.selectedMappingRole) {
                bm.addNewRoleToBusiness(participant, self.selectedRole, self.selectedRole, pn, self.currentAlternative, function(assignment) {
                    var role = assignment.get('assignedRole');
                    EcoMapMixin.setBusinessDiagramId(self.model, bm, self.diagramBO, participant, role, null);
                    if (self.updateVPBasedOnTheRecipientMapping) {
                        self.updateVPBasedOnTheRecipientMapping.set('recipient', role);
                    }
                });
            } else {
                bm.get('bmRole').add(self.selectedMappingRole);
                EcoMapMixin.setBusinessDiagramId(self.model, bm, self.diagramBO, participant, self.selectedMappingRole, null);
                if (self.updateVPBasedOnTheRecipientMapping) {
                    self.updateVPBasedOnTheRecipientMapping.set('recipient', self.selectedMappingRole);
                }
            }
        }
        enableCompleteForOtherShapes() {
            var self = this;
            if ($('#selectedBM' + self.rowId).val().trim() !== "" && $('#selectedParticipant' + self.self.rowId).val().trim() !== "" && $('#selectedPN' + self.self.rowId).val().trim() !== "" && $('#selectedRole' + self.self.rowId).val().trim() !== "") {
                $('.btn-create-mapping').removeAttr('disabled');
            } else {
                $('.btn-create-mapping').attr('disabled', 'disabled');
            }
        }
        enableCompleteForVP() {
            var self = this;
            if ($('#selectedBM' + self.rowId).val().trim() !== "" && $('#selectedVP' + self.rowId).val().trim() !== "" && $('#selectedProvider' + self.rowId).val().trim() !== "" && $('#selectedProviderRole' + self.rowId).val().trim() !== "") {
                $('.btn-create-mapping').removeAttr('disabled');
            } else {
                $('.btn-create-mapping').attr('disabled', 'disabled');
            }
        }
        enableCompleteForBM() {
            var self = this;
            if ($('#selectedBM' + self.rowId).val().trim() !== "" && $('#selectedBusiness' + self.rowId).val().trim() !== "") {
                $('.btn-create-mapping').removeAttr('disabled');
            } else {
                $('.btn-create-mapping').attr('disabled', 'disabled');
            }
        }
        filterParticipants(packId, type1, type2, callback) {
            var self = this;
            var participants = [];
            DataManager.getDataManager().getAllDocumentsCollectionOfType(packId, type1, function(result) {
                var repId = DataManager.getDataManager().get('viewAlternative');
                for (var i = 0; i < result.length; i++) {
                    if (repId !== window.utils.getPrefix(result.at(i).get('id'))) {
                        var newId = repId + window.utils.getSuffix(result.at(i).get('id'));
                        if (window.utils.getElementModel(newId, [type2])) {
                            continue;
                        }
                    }
                    var relOrgUnit = Backbone.Relational.store.getObjectByName(type2).find({
                        id: result.at(i).get('id')
                    });
                    if (relOrgUnit) {
                        participants.push(relOrgUnit);
                    }
                }
                //return participants;
            }, false, null, false, null, null, true);
            return participants;
        }
        loadProviders(packId, limitToType, limitToBusiness) {
            var self = this;
            if(!packId){
                return;
            }
            var selProvider = $("#selectedProvider" + self.rowId).val();
            self.ProvidersColl = [];
            if (!packId) {
                return;
            }

            function setParticipants(participants) {
                if (participants && participants.length > 0) {
                    for (var i = 0; i < participants.length; i++) {
                        self.ProvidersColl.push({
                            name: participants[i].get('name'),
                            id: participants[i].id,
                            provider: participants[i]
                        });
                    }
                }
                if (selProvider) {
                    var item = $.grep(self.ProvidersColl, function(e) {
                        return e.name === selProvider;
                    });
                    if(item && item[0]){
                        self.selectedMappingProvider = item[0].provider;
                    }
                }
            }
            if (limitToType === 'vdml.Actor') {
                setParticipants(self.filterParticipants(packId, 'vdml_Actor', 'vdml.Actor'));
            } else if (limitToType === 'vdml.Community') {
                setParticipants(self.filterParticipants(packId, 'vdml_Community', 'vdml.Community'));
            } else if (limitToType === 'vdml.OrgUnit') {
                self.filterParticipants(packId, 'vdml_OrgUnit', 'vdml.OrgUnit', function(participants) {
                    if (limitToBusiness && self.selectedVP) {
                        var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({
                            id: self.selectedVP.id
                        });
                        participants = $.grep(participants, function(part) {
                            return part.id === bm.get('business').id;
                        });
                    }
                    setParticipants(participants);
                });
                //participants = VDMPack.get('collaboration').where({type:'vdml_OrgUnit'});

            } else {
                setParticipants(self.filterParticipants(packId, 'vdml_Community', 'vdml.Community'));
                setParticipants(self.filterParticipants(packId, 'vdml_OrgUnit', 'vdml.OrgUnit'));
                setParticipants(self.filterParticipants(packId, 'vdml_Actor', 'vdml.Actor'));
            }
        };
        loadReceivers(packId, limitToType) {
            var self = this;
            if(!packId){
                return;
            }
            var selReceiver = $("#selectedRecipient" + self.rowId).val();
            self.RecipientsColl = [];

            function setParticipants(participants) {
                if (participants && participants.length > 0) {
                    for (var i = 0; i < participants.length; i++) {
                        self.RecipientsColl.push({
                            name: participants[i].get('name'),
                            id: participants[i].id,
                            receiver: participants[i]
                        });
                    }
                }
                if (selReceiver) {
                    var item = $.grep(self.RecipientsColl, function(e) {
                        return e.name === selReceiver;
                    });
                    if(item && item[0]){
                        self.selectedMappingRecipient = item[0].receiver;
                    }
                }
            }
            if (limitToType === 'vdml.Actor') {
                setParticipants(self.filterParticipants(packId, 'vdml_Actor', 'vdml.Actor'));
            } else if (limitToType === 'vdml.Community') {
                setParticipants(self.filterParticipants(packId, 'vdml_Community', 'vdml.Community'));
            } else if (limitToType === 'vdml.OrgUnit') {
                self.participants = filterParticipants(packId, 'vdml_OrgUnit', 'vdml.OrgUnit', setParticipants);
            } else {
                setParticipants(self.filterParticipants(packId, 'vdml_Community', 'vdml.Community'));
                setParticipants(self.filterParticipants(packId, 'vdml_OrgUnit', 'vdml.OrgUnit'));
                setParticipants(self.filterParticipants(packId, 'vdml_Actor', 'vdml.Actor'));
            }
        };
        loadProviderRoles(provider, receiverRole) {
            var self = this;
            if(!self.selectedMappingBM || !provider) {
                return;
            }
            var selProviderRole = $("#selectedProviderRole" + self.rowId).val();
            var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id: self.selectedMappingBM.id});
            self.ProviderRolesColl = [];
            var bmRolesArr = self.loadBMRoles(bm, provider, receiverRole);
            self.ProviderRolesColl = bmRolesArr;
            if (selProviderRole) {
                var item = $.grep(self.ProviderRolesColl, function(e) {return e.name === selProviderRole;});
                if(item && item[0]){
                    self.selectedMappingProviderRole = item[0].role;
                }
            }
        }
        loadReceiverRoles(receiver, providerRole) {
            var self = this;
            if(!self.selectedMappingBM || !receiver) {
                return;
            }
            var selReceiverRole = $("#selectedRecipientRole" + self.rowId).val();
            var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id: self.selectedMappingBM.id});
            self.RecipientRolesColl = [];
            var bmRolesArr = self.loadBMRoles(bm, receiver, providerRole);
            self.RecipientRolesColl = bmRolesArr;
            if (selReceiverRole) {
                var item = $.grep(self.RecipientRolesColl, function(e) {return e.name === selReceiverRole;});
                if(item && item[0]){
                    self.selectedMappingRecipientRole = item[0].role;
                }
            }
        }
        loadBMRoles(bm, participant, participantRole) {
            var self = this;
            var bmRoles = bm.get('bmRole');
            var bmRolesArr = [];
            bmRoles.each(function(role) {
                var roleAssignments = role.get('roleAssignment');
                for (var i = 0; i < roleAssignments.length; i++) {
                    var assignment = roleAssignments.at(i);
                    if (assignment.get('participant').id === participant.id) {
                        var allBMRoles = bm.get('bmRole').models;
                        var displayRolename = null;//self.checkDuplicatesPath(allBMRoles, role, role.get('name'));
                        if (participantRole && participantRole.id === role.id) {
                            break;
                        }
                        bmRolesArr.push({
                            id: role.id,
                            name: role.get('name'),
                            displayRolename: displayRolename,
                            role: role
                        });
                    }
                }
            });
            return bmRolesArr;
        }
        refreshRoleTypeahead() {
            var self = this;
            self.rolesColl.reset();
            if (self.roleTypeahead) {
                self.roleTypeahead.rerender([]);
            }
            $('#selectRole' + self.encodeId).val('');
            self.selectedRole = null;
            //self.onChangeSelectedRole();
            var pn, pnRoles;
            if (self.selectedMappingPN && self.selectedMappingParticipant /*&& self.selectedRelationship !== "Business"*/) {
                pn = Backbone.Relational.store.getObjectByName("vdml.BusinessNetwork").find({
                    id: self.selectedMappingPN.id
                });
                pnRoles = pn.get('collaborationRole').models;
                for (var i = 0; i < pnRoles.length; i++) {
                    var assignment = pnRoles[i].get('roleAssignment').at(0);
                    if (assignment && assignment.get('participant').id === self.selectedMappingParticipant.id) {
                        self.rolesColl.push({
                            id: pnRoles[i].id,
                            name: pnRoles[i].get('name')
                        });
                    }
                }
            }
            if (self.updateVPBasedOnTheRecipientMapping) {
                var rejected = _.filter(self.rolesColl.models, function(role) {
                    return role.id === self.updateVPBasedOnTheRecipientMapping.get('provider').id;
                });
                self.rolesColl.remove(rejected[0]);
            }
            if (self.diagramBO && self.diagramBO.$type == "vdml:Role" && self.diagramBO.name) {
                $('#selectedRole' + self.rowId).val(self.diagramBO.get('name'));
                var roleFound = _.filter(self.rolesColl.models, function(obj) {
                    return obj.get('name').toLowerCase() === self.diagramBO.get('name').toLowerCase()
                });
                if (roleFound && roleFound.length) {
                    self.selectedMappingRole = roleFound[0];
                    //self.onChangeSelectedRole();
                }
            }
        }
        loadVPs(callback) {
            var self = this;
            self.VPsColl.reset();
            var filteredVPsArray = [];
            self.vpTypeahead.rerender([]);
            //$('#selectVP' + self.encodeId).val('');
  	        //self.onChangeSelectedVP();
            var VPs = new Backbone.Collection();
            DataManager.getDataManager().getAllDocumentsCollectionOfType(self.currentAlternative.id, 'vdml_ValueProposition', function(result) {
                var repId = DataManager.getDataManager().get('viewAlternative');
                for (var i = 0; i < result.length; i++) {
                    if (repId !== window.utils.getPrefix(result.at(i).get('id'))) {
                        var newId = repId + window.utils.getSuffix(result.at(i).get('id'));
                        if (window.utils.getElementModel(newId, ['vdml.ValueProposition'])) {
                            continue;
                        }
                    }
                    var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({
                        id: result.at(i).get('id')
                    });
                    if (vp.get('isValueFormula')) {
                        if (self.isMyProposition && self.currentAlternative.get('phaseDesignPart').findWhere({
                                'beepReference': vp.getNestedParent().id
                            })) {
                            self.VPsColl.push(vp);
                        }
                    } else if (!self.isMyProposition && self.currentAlternative.get('phaseDesignPart').findWhere({
                            'beepReference': vp.getNestedParent().id
                        })) {
                        self.VPsColl.push(vp);
                    }
                }
                if (self.diagramBO.get('name')) {
                    var shapeName = self.diagramBO.get('name');
                    if (self.diagramBO.get('mid')) {
                        var vpFound = _.filter(self.VPsColl.models, function(obj) {
                            return obj.get('id') === self.diagramBO.get('mid')
                        });
                    } else {
                        var vpFound = _.filter(self.VPsColl.models, function(obj) {
                            return obj.get('name').toLowerCase() === self.diagramBO.get('name').toLowerCase()
                        });
                    }
                    if (vpFound && vpFound.length) {
                        self.selectedMappingVP = vpFound[0];
                        shapeName = vpFound[0].get('name');
                        //self.onChangeSelectedVP();
                    }
                    $('#selectedVP' + self.rowId).val(shapeName);
                    //self.enableCompleteForVP();
                    callback && callback();
                } else {
                    callback && callback();
                }
            }, false, null, false);

        };
        addListToDropdown(idOfTheCell, optionsList, optionsCaption){
            var self = this;
            var select1 = $("#" + idOfTheCell + self.rowId);
            select1.html("");
            if(optionsCaption){
               // Use the Option constructor: args text, value, defaultSelected, selected
               var option = new Option(optionsCaption, optionsCaption, true, true);
               select1.append(option);
            }
            for(var i=0; i<optionsList.length; i++){
               // Use the Option constructor: args text, value, defaultSelected, selected
               var option = new Option(optionsList[i].name, optionsList[i].name, false, false);
               select1.append(option);
            }
            $("#selectedProvider" + self.rowId).change(function(view) {
                var matchFound = _.filter(self.ProvidersColl, function(obj) {
                    return obj.name.toLowerCase() === view.currentTarget.value.toLowerCase();
                });
                if (matchFound && matchFound.length) {
                    self.selectedMappingProvider = matchFound[0].provider;
                    self.loadProviderRoles(self.selectedMappingProvider, null);
                    self.addListToDropdown("selectedProviderRole", self.ProviderRolesColl, "Select a Role...");
                } else {
                    self.selectedMappingProvider = null;
                }
            });
            $("#selectedRecipient" + self.rowId).change(function(view) {
                var matchFound = _.filter(self.RecipientsColl, function(obj) {
                    return obj.name.toLowerCase() === view.currentTarget.value.toLowerCase();
                });
                if (matchFound && matchFound.length) {
                    self.selectedMappingRecipient = matchFound[0].receiver;
                    self.loadReceiverRoles(self.selectedMappingRecipient, null);
                    self.addListToDropdown("selectedRecipientRole", self.RecipientRolesColl, "Select a Role...");
                } else {
                    self.selectedMappingRecipient = null;
                }
            });
            $("#selectedProviderRole" + self.rowId).change(function(view) {
                var matchFound = _.filter(self.ProviderRolesColl, function(obj) {
                    return obj.name.toLowerCase() === view.currentTarget.value.toLowerCase();
                });
                if (matchFound && matchFound.length) {
                    self.selectedMappingProviderRole = matchFound[0].role;
                } else {
                    self.selectedMappingProviderRole = null;
                }
            });
            $("#selectedRecipientRole" + self.rowId).change(function(view) {
                var matchFound = _.filter(self.RecipientRolesColl, function(obj) {
                    return obj.name.toLowerCase() === view.currentTarget.value.toLowerCase();
                });
                if (matchFound && matchFound.length) {
                    self.selectedMappingRecipientRole = matchFound[0].role;
                } else {
                    self.selectedMappingRecipientRole = null;
                }
            });
        }
        refreshProviderAndRecipientBasedOnSelectedVP(selectedVP) {
            var self = this;
            if(!self.selectedMappingBM || !self.selectedVDMPackage) {
                return;
            }
            self.selectedMappingProvider = null;
            self.selectedMappingProviderRole = null;
            self.selectedMappingRecipient = null;
            self.selectedMappingRecipientRole = null;
            var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({
                id: selectedVP.id
            });
            var providerRole = vp.get('provider');
            var assignment = providerRole.get('roleAssignment').at(0);
            var participant = assignment ? assignment.get('participant') : null;
            var provider = $.grep(self.ProvidersColl, function(participantProvider) {
                return participantProvider.id === participant.id;
            });
            if (provider && provider.length) {
                $("#selectedProvider" + self.rowId).val(provider[0].name).change();
                self.selectedMappingProvider = provider[0].provider;
            }
            if (providerRole) {
                $("#selectedProviderRole" + self.rowId).val(providerRole.get("name")).change();
                self.selectedMappingProviderRole = providerRole;
            }
            var recipientRole = vp.get('recipient');
            if (recipientRole) {
                assignment = recipientRole.get('roleAssignment').at(0);
                participant = assignment ? assignment.get('participant') : null;
                var recipient = $.grep(self.RecipientsColl, function(participantReceiver1) {
                    return participantReceiver1.id === participant.id;
                });
                if (recipient && recipient.length) {
                    $("#selectedRecipient" + self.rowId).val(recipient[0].name).change();
                    self.selectedMappingRecipient = recipient[0].receiver;
                }
                if (recipientRole) {
                    $("#selectedRecipientRole" + self.rowId).val(recipientRole.get("name")).change();
                    self.selectedMappingRecipient = recipientRole;
                }
            }
            $('#selectedProvider' + self.rowId).attr('disabled', 'disabled');
            $('#selectedProviderRole' + self.rowId).attr('disabled', 'disabled');
            $('#selectedRecipient' + self.rowId).attr('disabled', 'disabled');
            $('#selectedRecipientRole' + self.rowId).attr('disabled', 'disabled');
            self.enableCompleteForVP();
        }
        switchNewMappingTable(){
            var self = this;
            if(self.isNewMappingTable()){
                self.isNewMappingTable(false);
            }else{
                self.isNewMappingTable(true);
            }
        }
        createSuggestionForMappedShape(){debugger
            var self = this;
            var businessObject = self.diagramBO;
            var tempArrToStoreSuggestions = self.tempArrToStoreSuggestions;
            Suggestion.getInstance(businessObject.id, businessObject.mid, businessObject.$type,JSON.stringify(tempArrToStoreSuggestions), self.model);
        }
        fetchSuggestionForMappedShape(shapeId, shapeType){
            var self = this;
            var suggestions = self.model.get("suggestion").findWhere({shapeId: shapeId, targetType: shapeType});
            if(suggestions){
                return suggestions;
            }
            return null;
        }
    static getInstance(model,options){
		var view = new EcoMapDesignerViewModel(model, options);
		//view.init(model, options);
		return view;
	};
}
path.EcoMapDesignerViewModel = EcoMapDesignerViewModel;