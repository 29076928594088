// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rootDashboard {
  display: flex;
  flex-direction: column;
  padding-left: 0;
}

.DashboardContent {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  position: fixed;
  height: 93vh;
  margin-right: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.mainContainer {
  flex-grow: 1;
  margin: 0 !important;
}`, "",{"version":3,"sources":["webpack://./js/app/version1/views/dashboard/views/chart/DashboardReact.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,sBAAA;EACA,eAAA;AAAJ;;AAIA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AADJ;;AAIA;EACI,YAAA;EACA,oBAAA;AADJ","sourcesContent":["\n.rootDashboard {\n    display: flex;\n    flex-direction: column;\n    padding-left: 0;\n   \n}\n\n.DashboardContent {\n    display: flex;\n    flex-direction:column;\n    padding-right: 20px;\n    position: fixed;\n    height: 93vh;\n    margin-right: 20px;\n    overflow-y: auto;\n    overflow-x: hidden;\n}\n\n.mainContainer {\n    flex-grow: 1;\n    margin: 0 !important;\n    \n}\n\n// @media screen and (max-width: 1440px) {\n//     .DashboardContent {\n//         width: 83%;\n//     }\n// }\n\n// @media screen and (min-width:1440px) and (max-width: 3100px) {\n//     .DashboardContent {\n//         width: 88%;\n//     }\n// }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
