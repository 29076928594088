import * as $ from 'jquery'
import * as _ from 'underscore'
import * as Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {BeepPackageMixin} from '../../../../bo/beeppackage/BeepPackageMixin'
import {PackageReference as PlanPackageReference} from '../../../../bo/transformation/PackageReference'
import {PackageReference} from '../../../../../com/vbee/filesystem/PackageReference'
import {StrategyMapDiagram} from '../../../../bo/vdml/StrategyMapDiagram'
import {CapabilityLibraryDiagram} from '../../../../bo/vdml/CapabilityLibraryDiagram'
import {ValueDeliveryModel} from '../../../../bo/vdml/ValueDeliveryModel'
import {EcoMap} from '../../../../bo/ecomap/EcoMap'

/*define(["require", "jquery", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "appbo/beeppackage/BeepPackageMixin", "appbo/transformation/PackageReference", "appcommon/com/vbee/filesystem/PackageReference", "appbo/vdml/StrategyMapDiagram", "appbo/vdml/CapabilityLibraryDiagram", "appbo/vdml/ValueDeliveryModel","appbo/ecomap/EcoMap","app/global"],
	function (require, $, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, BeepPackageMixin, PlanPackageReference, PackageReference, StrategyMapDiagram, CapabilityLibraryDiagram, ValueDeliveryModel,global)
{*/
var path = DataManager.getDataManager().buildAppNsPath("capabilityLibrary.views.designer", global.version);
export class CapabilityLibraryDiagramDetailsViewModel {
    constructor(model, options) {
        this.init(model, options);
    }
    init(model, options) {
        var self = this;
        this.CapabilityLibraryDiagramDetailsViewModel = this;
        this.model = model.model;
        this.id = kb.observable(self.model, 'id');

        function htmlEscape(str) {
            return String(str)
                .replace(/@/g, '')
                .replace(/ /g, '');
        }
        this.encodeId = htmlEscape(this.id());
        this.enableDelete = ko.observable(true);
        this.enableComplete = ko.observable(true);
        this.name = ko.observable(self.model ? self.model.get('name') : null);
        this.description = ko.observable(self.model ? self.model.get('description') : "");
        var fsize = self.model ? self.model.get('fontsize') : 12;
		this.fontsize = ko.observable(fsize ? fsize : 12);
        this.existingPackages = [];
        this.existingLibraries = [];
        this.currentAlt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
        this.plan = DataManager.getDataManager().get('currentPlan');

        this.name.subscribe(function(val) {
            self.description(val);
        });
    }
    copyDataToEcomap(diagramInstance) {
        var self = this;
        var xml = self.model.get('data');
        if (xml) {
            var xmlDoc = jQuery.parseXML(xml);
            var $xml = jQuery(xmlDoc);
            var nodes = $xml.find('[id="' + self.model.id + '"]');
            var nodes1 = $xml.find('[vdmlElement="' + self.model.id + '"]')
            nodes.each(function(callback, node) {
                node.setAttribute('id', diagramInstance.get('id'));
            })
            nodes1.each(function(callback, node) {
                node.setAttribute('vdmlElement', diagramInstance.get('id'));
            })
            xml = (new XMLSerializer()).serializeToString(xmlDoc);
        }
        diagramInstance.set('data', xml);
    }
    loadLibraryPackages(callback) {
        var self = this;
        var planId = self.model.id.substr(self.model.id.lastIndexOf('@'));
        self.existingLibraries.length = 0;
        var commonAltId = planId + "-Common@";
        DataManager.getDataManager().getAllDocumentsCollectionOfType(commonAltId, 'vdml_ValueDeliveryModel', function(result) {
            if (result && result.length > 0) {
                _.each(result.models, function(pack) {
                    if (pack.get('id').indexOf("Common") > -1) {
                        if (pack.get('id').indexOf('CommonPackage') === -1) {
                            self.existingPackages.push({
                                id: pack.get('id'),
                                name: pack.get('name'),
                                version: pack.get('version')
                            });
                        }
                    }
                });
            }
        }, false, null, true);
        self.existingPackages.push({
            name: "New"
        });
        callback();
        //loadCapabilityLibrariesPackages(callback);
    }
    dispose() {
        var self = this;
        window.cleanViewModel(self);
    };

    /*if(window.checkContextForRevision()) {
        self.enableDelete(false);
    }*/

    saveCapabilityLibraryDiagramDetails() {
        var self = this;
        if (self.name() !== "") {
            if (self.name().trim() != self.model.get('name')) {
                self.model.set('name', self.name().trim());
                window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'), DataManager.getDataManager().get('viewAlternative'), self.CapabilityLibraryDiagramDetailsViewModel);
            }
            self.model.set('description', self.description().trim());
            var defaultFontSize = self.model.get('fontsize') ? self.model.get('fontsize') : 12;
            if(self.fontsize() && defaultFontSize != self.fontsize()){
                self.model.set('fontsize',self.fontsize());
                window.utils.startSpinner('fontsizespinner', "Applying Font Size ...");
                var modeler = window.vdmModelView.modeler;
                var fontsize = self.fontsize()
                modeler.get('textRenderer').getDefaultStyle().fontSize = fontsize+'px';
                modeler.get('textRenderer').getExternalStyle().fontSize = fontsize+'px';
                
                setTimeout(function () {
                    var obj = modeler.get('elementRegistry')._elements;
                    for (var key in obj) {
                        if (obj.hasOwnProperty(key)) {
                            var val = obj[key];
                            var shape = val.element;
                            var name = shape.businessObject?shape.businessObject.name : null;
                            if(name){
                                modeler.get('modeling').updateLabel(shape, name);
                            }
                        }
                    }
                    window.utils.stopSpinner('fontsizespinner');
                },100);
            }
        }

    };
    /* function loadCapabilityLibrariesPackages(callback){
        var repId = DataManager.getDataManager().getRepositoryId(vdmPackage.id);
        var vdmStore = DataManager.getDataManager().getVDMStore(repId);
        DataManager.getDataManager().fetchDocumentFromPackage(vdmPackage.id, 'vdml_ValueDeliveryModel', vdmPackage.version, vdmPackage.id, 'vdml_ValueDeliveryModel', vdmStore, {
            success: function (model) {
                var capabilityLibraries = model.get('capabilityLibrary').models;
                _.each(capabilityLibraries, function (cl) {
                    self.existingLibraries.push({ id: cl.get('id'), name: cl.get('name'), version: cl.get('version') });
                });
            },
            error: function (error) {
                console.log('Unable to load selected Package');
            }
        }, true, null);
        self.existingLibraries.push({ name: "New" });
        callback();
    }; */
    /* if(DataManager.getDataManager().get('viewAlternative') !== DataManager.getDataManager().get('currentWorkspace').get('id')){
        $('#copyCapabilityLibraryDiagram').hide();
    } */
    copyCapabilityLibraryDiagram() {
        var self = this;
        if (!DataManager.getDataManager().isAdminPortalPurchased()) {
			window.utils.limitExceeded();
			return false;
		}
        function savePackage(view, modalId, type, moduleTypes) {
            window.utils.startSpinner('copyCapabilityLibraryDiagram', "Copying Capability Library Diagram...");
            setTimeout(function() {
                self.onAddCapabilityLibraryDiagram(view.name(), view.description(), view.selectedCapabilityLibraryPackage(), self.model.get('capabilityLibrary'), null, self.currentAlt, type, moduleTypes);
                window.cleanDialogModel(modalId, view);
                window.utils.stopSpinner('copyCapabilityLibraryDiagram');
            }, 100)
        }
        self.loadLibraryPackages(function() {
            var plan = DataManager.getDataManager().get('currentPlan');
            var mapTitle = DataManager.getDataManager().get('localeManager').get("CapabilityMap");
            var addOptions = {
                'alternative': self.currentAlt,
                'packages': self.existingPackages,
                'libraries': self.existingLibraries,
                'plusIconType': 'addAdmin',
                onlyMap: mapTitle
            };
            window.getAndCreateModalDialog(self.model, window.utils.htmlEscape(self.id()), BeepPackageMixin, plan, "Admin", savePackage, addOptions);
        });
    };
    onAddCapabilityLibraryDiagram(name, description, pack, phase, alternative, type, moduleTypes) {
        var self = this;
		if(pack !== null && pack.id){
			var vdmPackage = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({id:pack.id})
			self.createCapabilityLibraryDiagram(vdmPackage, name, description);
		}else{
			var currentPlan =  DataManager.getDataManager().get('currentPlan');
			ValueDeliveryModel.createCapabilityPackage(name, currentPlan, function(vdm){
				self.createCapabilityLibraryDiagram(vdm, name, description);
			});
		}
    };
    createCapabilityLibraryDiagram(vdmPackage, name, description) {
        var self = this;
        var diagramInstance = CapabilityLibraryDiagram.getInstance(name, description, vdmPackage);
        self.copyDataToEcomap(diagramInstance);
    };

    deleteCapabilityLibraryDiagram() {
        var self = this;
        window.vdmModelView.enableComplete(false);
        if (self.model.get('data')) {
            var message = 'A deleted Capability Map and the data that it contains cannot be recovered. Are you sure you want to continue?';
            bootbox.confirm(message, function(result) {
                if (result) {
                    self.model.destroy();
                    DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {
                        trigger: true
                    });
                }
            });
        } else {
            self.model.destroy();
            DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {
                trigger: true
            });
        }
    };
    checkUniqueValue(view, event) {
        var self = this;
        var name = event.currentTarget.value.trim();
        if (name !== "") {
            if (self.model.get('name') === name) {
                $('#duplicateName' + self.encodeId).text("");
                $("#complete").removeAttr('disabled');
                return;
            }
            var unique = window.isUniqueName(name, null, self.id(), self.model.getNestedParent().get('diagrams'));
            if (unique) {
                $('#duplicateName' + self.encodeId).text("");
                $("#complete").removeAttr('disabled');
            } else {
                var lcManager = DataManager.getDataManager().get('localeManager');
                $('#duplicateName' + self.encodeId).text(lcManager.get('localeManager').get('DuplicateAlert', lcManager.get('CapabilityLibrary')));
                $("#complete").attr('disabled', 'disabled');
            }
        } else {
            $("#complete").attr('disabled', 'disabled');
        }
    };
    limiter(view,event) {
        var input = event.currentTarget;
        if (input.value < 10) input.value = 10;
        if (input.value > 50) input.value = 50;
    }
    afterRenderView() {}


    static getInstance(model, options) {
        var view = new CapabilityLibraryDiagramDetailsViewModel(model, options);
        view.init(model, options);
        return view;
    }
}
path.CapabilityLibraryDiagramDetailsViewModel = CapabilityLibraryDiagramDetailsViewModel;