import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import * as Highcharts from 'highcharts';
import {PlanValuesChart} from '../../../transformation/views/chart/PlanValuesChart'
import {BMValuesChart} from '../../../vdml/views/chart/BMValuesChart'

/*define(["require","jquery","enhsplitter","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","async","appcommon/com/vbee/data/DataManager",
	"app/global", "typeahead", "appviews/transformation/views/chart/PlanValuesChart", "appviews/vdml/views/chart/BMValuesChart", "appcommon/com/vbee/utils/HighChartThemes", "highcharts-common", "domtoimage"],
function(require,$,enhsplitter,_, Backbone,ko, koMapping,kb,bootbox,async,DataManager,
	global, typeahead, PlanValuesChart, BMValuesChart, HighChartThemes, Highcharts)
{*/
    var path = DataManager.getDataManager().buildAppNsPath("ecoMap.views.designer", global.version);
	export class ChartDetailsViewModel {
		constructor(model,options){
			var self = this;
			this.init(model, options);
		}
		init(model, options) {
			var self = this;
			this.ChartDetailsViewModel = this;
		   // this.name = kb.observable(model, 'name');
			//this.description = kb.observable(model, 'description');
			this.id = kb.observable(model, 'id');
			this.model = model;
			if(options && options.addOptions){
				this.chartBM = options.addOptions.chartBM;
			}    
			self.highchartsDefaultTheme = $.extend(true, {}, Highcharts.defaultOptions);
			this.initializeChart = function (redraw) {
				if (self.model.getNestedParent() !== DataManager.getDataManager().get('currentPlan')) {
					self.chartViewInstance.selectedChartType("Table");
					self.chartViewInstance.initialize(self.model, redraw, self, self.model);
				} else {
					//self.chartViewInstance.initialize(self.context, redraw, self, self.model, self.model.get('configuration'));
				}
				$('.chart-container').css('height','500px');
				if (!redraw) {
					$("#chartConfig" + self.chartViewInstance.encodeId).hide();
				}
			}

			function htmlEscape(str) {
				return String(str)
				.replace(/@/g, '')
				.replace(/ /g, '')
				.replace(/#/g, '');
			}
			self.encodeId = htmlEscape(model.id);
			if (self.model) {
				window.utils.loadTemplate(function(name){
					if(name == "chart" + self.encodeId){
						self.initializeChart(false);
						$('#chartTooltip').css('z-index', 1240);
						$('.tooltiptext').remove();
					}
				});
				if (self.model.getNestedParent() !== DataManager.getDataManager().get('currentPlan')) {
					self.chartViewInstance = BMValuesChart.getInstance(self.chartBM,null,true,self.encodeId);
					if (!ko.components.isRegistered('chart') + self.encodeId) {
						ko.components.register("chart" + self.encodeId, {
							viewModel: { instance: self.chartViewInstance },
							template: {fromUrl : "js/app/version1/views/vdml/views/chart/BMValuesChartTemplate.html"},
							synchronous: true
						});
					}
				} else {
					self.context = DataManager.getDataManager().get('currentPlan');
					self.chartViewInstance = PlanValuesChart.getInstance(self.context,self.encodeId);
					if (!ko.components.isRegistered('chart') + self.encodeId) {
						ko.components.register("chart" + self.encodeId, {
							viewModel: { instance: self.chartViewInstance },
							template: {
								 fromUrl : "js/app/version1/views/transformation/views/chart/PlanValuesChartTemplate.html"
							},
							synchronous: true
						});
					}
					//window.setTimeout(self.initializeChart, 1000);
				}
			}
			this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),[
			'Close',
			'SelectValues',
			'PresentationType'
			]);
		}
		
		custResize(){
            var self = this;
			this.timer = clearTimeout();
            this.timer = setTimeout(function(){self && self.setResizeHeight && self.setResizeHeight()}, 100);
		}


		cleanModal() {
            var self = this;
	        ko.components.unregister("chart" + self.encodeId);
			window.utils.loadTemplate(null,true);
			window.cleanDialogModel(self.encodeId,this);
    	};

		setResizeHeight() {
		    /*setTimeout(function () {
		        self.initializeChart(true);
		    }, 150);*/
		}

    	afterRenderView() {
		    
    	}

    	/*$(window).bind("resize", function (event) {
    	    self.setResizeHeight();
    	});*/



    	refreshWidgetData(refresh) {
            var self=this;
    	    if (self.model.get('bmContext')) {
    	        self.chartViewInstance.initialize(self.context, refresh, self,null, self.model, self.model.get('configuration'));
    	    } else {
    	        self.chartViewInstance.initialize(self.context, refresh, self, self.model, self.model.get('configuration'));
    	    }
		};

	static getInstance(model,options){
		var view = new ChartDetailsViewModel(model, options);
		//view.init(model, options);
		return view;
	};
}
path.ChartDetailsViewModel = ChartDetailsViewModel;