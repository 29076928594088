import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { Actor } from './Actor'
import { PersonMixin } from './PersonMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Actor","appbo/vdml/PersonMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Actor,PersonMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var Person = Actor.extend(utils.customExtends({
		relations:PersonMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Actor.prototype.initialize.apply(this, arguments);
		}
		}
		, new PersonMixin()
	));
	
	Person.abstract = false;
	path.Person = Person;
    export { Person };
//});