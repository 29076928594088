import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BeepPackage} from '../beeppackage/BeepPackage'
import {PlanInstantiationPackageMixin} from './PlanInstantiationPackageMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

	
var path = DataManager.getDataManager().buildAppNsPath("instdef",global.version);
var PlanInstantiationPackage = BeepPackage.extend(utils.customExtends({
        relations:PlanInstantiationPackageMixin.getMixinRelations(),
        initialize: function(attributes, options) {
            BeepPackage.prototype.initialize.apply(this, arguments);
        }
    }
    , new PlanInstantiationPackageMixin()
));

PlanInstantiationPackage.abstract = false;
path.PlanInstantiationPackage = PlanInstantiationPackage;
export {PlanInstantiationPackage};
