import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {TicketMixin} from './TicketMixin'
import {TicketType} from './TicketType'
import {DataManager} from '../../../com/vbee/data/DataManager'
//
/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/tickets/TicketMixin","appbo/tickets/TicketType","appbo/tickets/TicketStatus",],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,TicketMixin,TicketType,TicketStatus
, Comment
, User
, Solution){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
	/*if(!Comment){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.Comment){
			Comment = importPath.Comment;
		}
		else{
			require(["appbo/tickets/Comment"],function(loadedModule){
				Comment = loadedModule;
			});
		}
	}
	if(!User){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.User){
			User = importPath.User;
		}
		else{
			require(["appbo/tickets/User"],function(loadedModule){
				User = loadedModule;
			});
		}
	}
	if(!Solution){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.Solution){
			Solution = importPath.Solution;
		}
		else{
			require(["appbo/tickets/Solution"],function(loadedModule){
				Solution = loadedModule;
			});
		}
	}*/

	var Ticket = EObject.extend(utils.customExtends({
		relations:TicketMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			EObject.prototype.initialize.apply(this, arguments);
			if(attributes.status){
				this.set('status',path.TicketStatus[attributes.status instanceof Object ? attributes.status.name : attributes.status]);
			}
			if(attributes.ticketType){
				this.set('ticketType',TicketType[attributes.ticketType instanceof Object ? attributes.ticketType.name : attributes.ticketType]);
			}
		}
		}
		, new TicketMixin()
	));
	
	Ticket.abstract = false;
	Ticket.supported = true;
	path.Ticket = Ticket;
	//return Ticket;
	export {Ticket};
//});