import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurementRelationship} from './MeasurementRelationship'
import {MeasurementRelationshipMixin} from './MeasurementRelationshipMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { RankingMeasurement } from './RankingMeasurement'
import { DimensionalMeasurement } from './DimensionalMeasurement'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasurementRelationship","appbo/smm/MeasurementRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurementRelationship,MeasurementRelationshipMixin
, RankingMeasurement
, DimensionalMeasurement){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!RankingMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RankingMeasurement){
			RankingMeasurement = importPath.RankingMeasurement;
		}
        else {
            import('./RankingMeasurement').then(({ default: RankingMeasurement }) => {
                RankingMeasurement = RankingMeasurement;
            });
            /*require(["appbo/smm/RankingMeasurement"], function (loadedModule) {
                RankingMeasurement = loadedModule;
			});*/
		}
	}
	if(!DimensionalMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasurement){
			DimensionalMeasurement = importPath.DimensionalMeasurement;
		}
        else {
            import('./DimensionalMeasurement').then(({ default: DimensionalMeasurement }) => {
                DimensionalMeasurement = DimensionalMeasurement;
            });
            /*require(["appbo/smm/DimensionalMeasurement"], function (loadedModule) {
                DimensionalMeasurement = loadedModule;
			});*/
		}
	}
	export class RankingMeasurementRelationshipMixin {

	defaults(){
		var ret = {
			type: "smm_RankingMeasurementRelationship"
		}
		return jQuery.extend(MeasurementRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"smm_RankingMeasurementRelationship",
			key:"to",
			relatedModel:"smm.DimensionalMeasurement",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"rankingMeasurementRelationship",
				type :Backbone.HasMany,
				includeInJSON:"id"
			}				
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!RankingMeasurementRelationshipMixin.cummulativeRelations) {
            RankingMeasurementRelationshipMixin.cummulativeRelations = _.union(RankingMeasurementRelationshipMixin.getMixinRelations()
                , MeasurementRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return RankingMeasurementRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_MeasurementRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" }
		]
	}
	getParent(){
		var container;
		return MeasurementRelationship.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return MeasurementRelationship.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/RankingMeasurementRelationshipPropertiesTemplate.html",
			templateName : "RankingMeasurementRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/RankingMeasurementRelationshipViewModel",
			tabId : "RankingMeasurementRelationshipView",
			tabName: "RankingMeasurementRelationship"
		}
	}
//#startCustomMethods
	onToRemoved(model,newTo,options){
		if(Backbone.skipPersistance){
			return;
		}		
		if(!newTo && options.__related){
			var parent = model.get('from');
			model.destroy();	
			if(parent){
				parent.calculate();
			}
		}
	}
	clone(data, mest, callback) {
	    var relId = DataManager.getDataManager().guidGeneratorByOwner(this);
	    var mestRel = new this.constructor({ id: relId, name: this.get('name'), description: this.get('description'), measurementRelationshipsOwner: mest });
	    var dimMestOld = this.get('to');
	    var mesChar = dimMestOld.get('measuredCharacteristic');
	    var timestamp = mest.get('observedMeasure').get('observation').get('whenObserved');
	    var mestObsContext = dimMestOld.get('observedMeasure').get('observation').getContext();
	    function findAndSetDimMest() {
	        var mests = mesChar.get('measurement');
	        var found = false;
	        var targetMest;
	        for (var i = 0; i < mests.length; i++) {
	            targetMest = mests.at(i);
	            var obs = targetMest.get('observedMeasure').get('observation');
	            var targetMestContext = obs.getContext();
	            if (obs.get('whenObserved') === timestamp  && targetMestContext===mestObsContext) {
	                found = true;
	                break;
	            }
	        }
	        if (found) {
	            mestRel.set('to', targetMest);
				mest.set('rankingTo', mestRel);//to trigger changelog as reverse association not triggering changelog
	            mestRel.set('from', mest);
                mest.calculate();
	            if (callback) {
	                callback(mestRel);
	            }
	        } else {
	            setTimeout(findAndSetDimMest, 50);
	        }
	    }
	    findAndSetDimMest();
	}
//#endCustomMethods	
    }
	path.RankingMeasurementRelationshipMixin = RankingMeasurementRelationshipMixin;
	
	//return RankingMeasurementRelationshipMixin;
//});