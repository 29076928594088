import * as $ from 'jquery'
import * as _ from 'underscore'
import * as Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
//import * as d3 from '../../../../../../libs/d3/d3.min'
import * as FileSaver from '../../../../../../libs/FileSaver/FileSaver'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { CodeContainer } from '../../../../bo/beeppackage/CodeContainer'
import { ValueDeliveryModelMixin } from '../../../../bo/vdml/ValueDeliveryModelMixin'
import { EcoMapDiagramMixin } from '../../../../bo/vdml/EcoMapDiagramMixin'
import { MappingWizardViewModel } from '../../../../views/strategyMap/views/designer/MappingWizardViewModel'

/*define(["require", "jquery", "underscore", "backbone", "async", "knockout", "bootbox", "FileSaver", "jstree", "appcommon/com/vbee/data/DataManager", "app/global", "knockback", "d3", "appbo/beeppackage/CodeContainer", "appviews/strategyMap/views/designer/MappingWizardViewModel", "appbo/vdml/ValueDeliveryModelMixin", "appbo/vdml/EcoMapDiagramMixin", "jquery.DataTable", "dataTable.PageResize", "dataTable.ColReorderWithResize","tick-positioner"],
	function (require, $, _, Backbone, async, ko, bootbox, FileSaver, jstree, DataManager, global, kb, d3, CodeContainer, MappingWizardViewModel, ValueDeliveryModelMixin, EcoMapDiagramMixin) {*/
var path = DataManager.getDataManager().buildAppNsPath("strategyMap.views.designer", global.version);
export class StrategyMapObjectiveViewModel {
    constructor(model, options) {
        var self = this;
        this.init(model, options);
    }
    init(model, options) {
        var self = this;
        this.StrategyMapObjectiveViewModel = this;
        this.enableValues = ko.observable(false);
        this.showValDef = ko.observable(false);
        var planPackages = DataManager.getDataManager().get('currentPlan').getPlanPackageList('vdml_ValueDeliveryModel',true);
        var hasLib = planPackages.filter(b=>b.beepReference.indexOf('Common@') != -1 && b.beepType == 'vdml_ValueDeliveryModel' && b.beepReference.indexOf('CommonPackage') == -1 && b.beepReference.indexOf('CommonPackage') == -1);
        if (hasLib && hasLib.length > 0) {
            this.showValDef(true);
        }
        //this.headerNo = window.valLibraries?2:2;
        this.values = [];
        this.model = model
        this.selectedValueLibList = [];
        this.selectedPhases = [];
        this.fc = "fileContent";
        this.valuesColl = new Backbone.Collection();
        this.unitsColl = new Backbone.Collection();
        this.existingMapTargetList = [];
        this.showValueElementId = null;
        this.showTargetElemetId = null;
        this.valLibNames = {};
        this.valLibLoaded = false;
        //this.elementFactory = window.vdmModelView.modeler ? window.vdmModelView.modeler.get('elementFactory') : "";
        this.parentView = options ? options.parentView : "";

        function htmlEscape(str) {
            return String(str)
                .replace(/@/g, '')
                .replace(/ /g, '');
        }
        this.encodeId = htmlEscape(self.model.id);
        this.options = options;
        $('#dropdownMenuObj' + self.encodeId).unbind('click');
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'name',
            'description',
            'Details',
            'Unit',
            'value',
            'import',
            'export',
            'ValueDefinition',
            'Phase(s)/Value Definition(s)',
            'Objective',
            'AddObjective'
        ]);
    }

    /*loadValues(parentView) {
        var self = this;
        self.parentView = parentView;
        var shapes, definitions, strategyMap, modeler = parentView.modeler;
        if (modeler) {
            definitions = modeler._definitions;
        }
        if (definitions) {
            strategyMap = definitions.rootElements;
        }
        if (strategyMap && strategyMap.length) {
            shapes = strategyMap[0].flowElements;
        }
        self.values = [];
        if (shapes) {
            for (var i = 0; i < shapes.length; i++) {
                if (event && event.type === 'shape.remove' && event.element.id === shapes[i].id) {
                    continue;
                }
                var type = shapes[i].$type;
                if (type === "vdml:BusinessItem" || type === "vdml:CausalRelationship" || type === "vdml:DeliverableFlow") {
                    continue;
                }

                if (type === "vdml:Perspective") {
                    var childrenShapes = shapes[i].flowElements;
                    if (childrenShapes && childrenShapes.length > 0) {
                        childrenShapes.forEach(function(shape) {
                            if (shape.$type === "vdml:Value") {
                                self.values.push(shape)
                            }
                        });
                    }
                }
            }
        }
        var plan = DataManager.getDataManager().get('currentPlan');
        self.vdmlValues = [];
        self.fetchPlanValues(plan);
        var alt = DataManager.getDataManager().get('viewAlternative');
        self.fetchBMValues(alt);


        self.values.sort(function(left, right) {
            return (left.name === right.name ? 0 : left.name < right.name ? -1 : 1);
        });
    }*/


    /*fetchPlanValues(plan) {
        var self = this;
        var planCriterionSet = plan ? plan.get('planCriterionSet') : null;
        var planComponents = planCriterionSet ? planCriterionSet.get('component').models : null;
        if (planComponents) {
            for (var i = 0; i < planComponents.length; i++) {
                self.vdmlValues.push({
                    id: planComponents[i].id,
                    name: planComponents[i].get('name'),
                    type: planComponents[i].get('type')
                })
            }
        }
    }

    fetchBMValues(alt) {
        var self = this;
        var altModel = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({id: alt});
        var models = altModel.get('phaseDesignPart').models;
        var bmValues = [];
        for (var i = 0; i < models.length; i++) {
            if (models[i].get('beepType') === "vdml_ValueDeliveryModel" && models[i].get('name') != "commonScenario") {
                var beepReference = models[i].get('beepReference');
                var bmModel = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({
                    id: beepReference
                });
                for (var j = 0; j < bmModel.get('businessModel').models.length; j++) {
                    var bm = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({
                        id: bmModel.get('businessModel').models[j].id
                    });
                    bm.getValues(bmValues, true);
                    for (var v = 0; v < bmValues.length; v++) {
                        self.vdmlValues.push({
                            id: bmValues[v].id,
                            name: bmValues[v].get('name'),
                            type: bmValues[v].get('type')
                        })
                    }
                }
            }
        }
    }*/
    getTargetElementUnit(valModel){
        var unitName;
        /*var currentAltId = DataManager.getDataManager().get('viewAlternative');
        var currentAlt = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({id: currentAltId});
        var preScenario = currentAlt.getMainScenario(valModel.getNestedParent());
        var measurement = preScenario ? preScenario.getObservedMeasureWithMeasurements(valModel.get('valueMeasurement'), false, null) : null;
        if(measurement && measurement.get('measure') && measurement.get('measure').get('unit')){
            unitName = measurement.get('measure').get('unit').get('name');
        }*/
        var mCharacteristic = valModel.get('valueMeasurement');
        var charDef = mCharacteristic?mCharacteristic.get('characteristicDefinition'):null
        if(charDef && charDef.get('measure').length > 0){
            var unit = charDef.get('measure').at(0).get('unit');
            unitName = unit?unit.get('name'):"";
        }
        return unitName;
    }
    loadTable(parentView) {
        var self = this;
        self.parentView = parentView;
        var json = {};
        var codeContainerId = self.commonCodeContainer;
        CodeContainer.getBlobInstanceWithId(codeContainerId, DataManager.getDataManager().get('currentPlan'), function(codeContainer) {
            if (codeContainer) {
                if (codeContainer.get('code')) {
                    json = JSON.parse(codeContainer.get('code'));
                    var table = $('#myTable' + self.encodeId);
                    $('#objBody').remove();
                    //if (!$('#objBody') || ($('#objBody') && $('#objBody').length === 0)) {
                        var tableBody = document.createElement('TBODY');
                        tableBody.setAttribute('id', "objBody")
                        table.append(tableBody);
                    //}
                    //var headerLength = table.find('th').length;
                    var keyContent = self.fc;
                    for (var phaseId in json) {
                        if(table.find("th[id='"+phaseId+"']").length == 0){
                            self.addHeaderColumn(phaseId, json[phaseId].phaseMapName);
                            if (json[phaseId]) {
                                self.selectedPhases.push({
                                    id: phaseId,
                                    mapId: json[phaseId].phaseMapId,
                                    name: json[phaseId].phaseMapName
                                })
                            }
                        }
                        for (var key in json[phaseId][keyContent]) {
                            if ($('#row' + json[phaseId][keyContent][key].id) && $('#row' + json[phaseId][keyContent][key].id).length > 0) {
                                var tr = $('#row' + json[phaseId][keyContent][key].id);
                            } else {
                                var valTargetId = json[phaseId][keyContent][key].valueTargetId;
                                var unit = json[phaseId][keyContent][key].unit ? json[phaseId][keyContent][key].unit : "";
                                var valDefId = json[phaseId][keyContent][key].valueElementId;
                                var valueDef = self.getValueLibTypeModal(valDefId);
                                var valDefName = valueDef ? valueDef.get("name") : "";
                                var valTargetEle = self.getValueTarget(json[phaseId].phaseMapId, valTargetId);
                                if (valTargetEle) {
                                    self.existingMapTargetList.push(window.utils.getSuffix(valTargetId)); 
                                    unit = self.getTargetElementUnit(valTargetEle);
                                    var valueDef = valTargetEle.get('valueDefinition');
                                    if(valueDef){
                                        valDefName = valueDef.get("name");
                                        self.selectedValueLibList.push(valueDef.get("id"));
                                    }
                                    
                                }
                                var displayObjName = json[phaseId][keyContent][key].ValueName.concat(unit ? " (" + unit + ")" : "");
                                
                                var tr = self.createRow(json[phaseId][keyContent][key].id, displayObjName, valDefName, valTargetId);
                            }
                            self.objectiveColumnCreate(json[phaseId][keyContent][key].id + phaseId, tr, phaseId, json[phaseId][keyContent][key].Value);
                        }
                    }
                }
            }
            self.createDatatable();
            self.parentView.setResizeHeight();
        });
    }

    createDatatable(){
        var self = this;
        if(self.dataTable){
            //window.utils.setDatatableEmpty('myTable' + self.encodeId);
            self.dataTable.destroy(false);
        } else {
            $("#StrategyMapDesignerView").unbind('mouseup');
            $('#StrategyMapDesignerView').click(function (event) {
                $("#dropdownMenuObj" + self.encodeId).hide();
            });
        }
        self.dataTable = $('#myTable' + self.encodeId).DataTable({
            "columnDefs": [
                { "orderable": false, "targets": "sort"}
            ],
            "autoWidth": false,
            //"pageLength": 35,
            "paging": false,
            "pageResize": true,
            "bLengthChange": false,
            "language" : {
                "zeroRecords": " "             
            }
        });
        /*self.dataTable.on('length.dt', function (e, settings, len) {
			var self = this;
			self.parentView.setResizeHeight();
		}.bind(self));*/
    }

    dispose() {
        var self = this;
        if (!this.model) {
            return;
        }
        $(window).unbind('resize');
        window.cleanViewModel(self);
    };

    loadMapvalues() {
        var self = this;
        self.valuesColl.reset();
        self.unitsColl.reset();
        var data = self.model.get('data');
        /*var keyContent = self.fc;
        var codeContainer = Backbone.Relational.store.getObjectByName('beeppackage.CodeContainer').find({id:self.commonCodeContainer});
        var json = JSON.parse(codeContainer.get('code'));
        for (var phaseId in json) {
            for (var key in json[phaseId][keyContent]) {
                var targetId = json[phaseId][keyContent][key].valueTargetId
                if (targetId) {
                    self.existingMapTargetList.push(window.utils.getSuffix(targetId));
                }
            }
            break;
        }*/
        if (data) {
            var xml = $.parseXML(data);
            var valueTags = xml.getElementsByTagName('vdml:value');
            for (var i = 0; i < valueTags.length; i++) {
                var isExist = false;
                var valMid = valueTags[i].getAttribute('mid');
                if (valMid && self.existingMapTargetList.indexOf(window.utils.getSuffix(valMid)) != -1) {
                    isExist = true;
                }
                if (!isExist && valueTags[i].getAttribute('name')) {
                    self.valuesColl.push({
                        id: valMid ? valMid : valueTags[i].getAttribute('id'),
                        name: valueTags[i].getAttribute('name')
                    })
                }
            }
        }
    }
    showTargetDetailsDialog = function(){
        var self = this;
        var mappingElementId = self.showTargetElemetId;
        var packageId = self.model.getNestedParent().id;
        var valueModel = window.utils.getElementModel(mappingElementId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
        var mappingElementType;
        if (valueModel) {
            mappingElementType = valueModel ? valueModel.get('type') : "";
        }
        if (mappingElementId) {
            self.model.getMappedElement(mappingElementId, packageId, mappingElementType, null, function(mappedElement) {
                if (!mappingElementId) {
                    bootbox.alert("No data found");
                    return;
                }
                if (mappingElementType === 'vdml_ValueDefinition' || mappingElementType === 'vdml_ValueCategory') {
                    var id = window.utils.htmlEscape(window.guidGenerator());
                    var currentPlan = DataManager.getDataManager().get('currentPlan');
                    currentPlan.loadValueLibraries(function(valueLibraries) {
                        var options = {
                            valueLibs: valueLibraries,
                            targetDetailsMode: true,
                            callback: function(capId, capName) {
                                if (!capId) {
                                    return;
                                }
                            }
                        };
                        options.showValueElementId = self.showValueElementId;
                        window.getAndCreateModalDialog(self.model, id, ValueDeliveryModelMixin, self.model, "ValueExplorerJson", null, options);
                    });
                } else {
                    var currentAltId = DataManager.getDataManager().get('viewAlternative');
                    var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
                    var currentAlt = docTypeObj.find({id: currentAltId});
                    var scenario = currentAlt.getMainScenario(valueModel.getNestedParent());
                    var id = window.utils.htmlEscape(window.guidGenerator());
                    var options = {};
                    options.context = scenario;
                    options.mappedModal = valueModel;
                    options.width = '800px';
                    window.getAndCreateModalDialog(self.model, id, EcoMapDiagramMixin, self.model, "TargetDetails", function(refresh) {}, options);
                }
            });
        }
    }
    getValueTarget(phaseId, mapId) {
        if(!mapId){
            return null;
        }
        var phase = window.utils.getElementModel(phaseId,['transformation.Phase']);
        var altId = phase ? phase.get('primary').get('id'): null;
        var valueModal = window.utils.getElementModel(altId + mapId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
        if(!valueModal){
            valueModal = window.utils.getElementModel(window.plansKey + mapId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
            if(!valueModal){
                var plan = DataManager.getDataManager().get('currentPlan');
                valueModal = window.utils.getElementModel(plan.id + mapId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
                if(!valueModal){
                    //var valSuffixId = window.utils.getSuffix(mapId);
                    var phase = plan.get('phase');
                    for (var j = 0; j < phase.length; j++) {
                        var phaseAlts = phase.at(j).get('phaseAlternative');
                        for (var k = 0; k < phaseAlts.length; k++) {
                            var altId = phaseAlts.at(k).get('id');
                            valueModal = window.utils.getElementModel(altId+mapId,['vdml.ValuePropositionComponent','vdml.ValueAdd'])
                            if(valueModal){
                                break;
                            }
                        }   
                        if(valueModal){
                            break;
                        } 
                    }
                }
            }
        }
        return valueModal
    }

    createRow(valueId, text, defName, mapId) {
        var self = this;
        var tr = document.createElement('TR');
        tr.setAttribute("id", "row" + valueId)
        tr.setAttribute("originalId", valueId)
        $('#objBody').append(tr);

        var td1 = document.createElement('TD');
        td1.setAttribute('id', 'value' + valueId);
        td1.textContent = defName

        var td = document.createElement('TD');
        td.setAttribute('id', valueId);
        td.setAttribute('vText', text);
        if (mapId) {
            td.setAttribute('mapId', mapId);
        }
        var a = document.createElement('a');
        a.setAttribute('href', "javascript:void(0)");
        a.textContent = text;
        a.addEventListener("click", openRowDetails);
        tr.append(td1);
        tr.append(td);
        td.append(a);

        function deleteSelectedValue(value) {
            for (var i = self.selectedValueLibList.length - 1; i >= 0; i--) {
                if (self.selectedValueLibList[i] && self.selectedValueLibList[i] === value.valueElementId) {
                    self.selectedValueLibList.splice(i, 1);
                    break;
                }
            }
            if(value.valueTargetId){
                self.existingMapTargetList = self.existingMapTargetList.filter(item => item !== window.utils.getSuffix(value.valueTargetId));
            }
        }

        function openRowDetails() {
            //var self = this;
            var json = {}
            var codeContainer = Backbone.Relational.store.getObjectByName('beeppackage.CodeContainer').find({id:self.commonCodeContainer});
            //CodeContainer.getBlobInstanceWithId(self.commonCodeContainer, DataManager.getDataManager().get('currentPlan'), function(codeContainer) {
                if (codeContainer && codeContainer.get('code')) {
                    json = JSON.parse(codeContainer.get('code'));
                    var id = event.currentTarget.parentElement.id;
                    //var text = event.currentTarget.textContent;

                    function sortByName(left, right) {
                        return left.name.toLowerCase() === right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
                    }
                    var htmlContent = self.getObjectiveHtmlContent(true,id);

                    var dialog = bootbox.dialog({
                        title: "<div><img class=\"smallIcon\" src=\"img/icons/icons_31.png\"</img>&nbsp;&nbsp;" + self.labels['Objective']() /*+ self.labels['Details']() + */+"</div>",
                        message: htmlContent,
                        show: true,
                        buttons: {
                            delete: {
                                label: "Delete",
                                className: "btn btn-danger glyphicon glyphicon-trash pull-left deleteRow",
                                callback: function() {
                                    self.dataTable.row('#row' + id).remove().draw();
                                    //$('#row' + id).remove();
                                    var json = {}
                                    var codeContainer = Backbone.Relational.store.getObjectByName('beeppackage.CodeContainer').find({id:self.commonCodeContainer});
                                    //CodeContainer.getBlobInstanceWithId(self.commonCodeContainer, DataManager.getDataManager().get('currentPlan'), function(codeContainer) {
                                        if (codeContainer) {
                                            if (codeContainer.get('code')) {
                                                json = JSON.parse(codeContainer.get('code'));
                                                for (var key in json) {
                                                    if (json[key][self.fc][id]) {
                                                        deleteSelectedValue(json[key][self.fc][id]);
                                                        delete json[key][self.fc][id];
                                                        self.showValueElementId = null;
                                                    }
                                                }
                                                codeContainer.set('code', JSON.stringify(json));
                                            }
                                        }
                                    //})
                                }
                            },
                            crete: {
                                label: "Link Value",
                                className: "btn btn-default createMapping",
                                callback: function() {
                                    var mapName = $('#valueName'+id).val() ? $('#valueName'+id).attr('vtext') : $('#valueElementName').text();
                                    var options = { parentView: vdmModelView, shape: "", name: mapName,description: mapName,
                                    objectiveMapping: true,usedValue: self.showValueElementId,existingMapTargetList: self.existingMapTargetList.slice()};
                                    var elementFactory = vdmModelView.modeler ? vdmModelView.modeler.get('elementFactory') : "";
                                    var shape = elementFactory ? elementFactory.createShape({type: "vdml:Value"}) : "";
                                    shape.businessObject.name = mapName;
                                    shape.businessObject.description = mapName;
                                    shape.businessObject.set('mcid',self.showValueElementId);
                                    self.wizard = MappingWizardViewModel.getInstance(self.model, options, shape.businessObject, function(model,rev,diagramBO) {
                                        if (model) {
                                            $('.createMapping').addClass('hide');
                                            $('.deleteMapping').css("display", "block");   
                                            var valModel = window.utils.getElementModel(diagramBO.mid,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
                                            var valSuffix = window.utils.getSuffix(diagramBO.mid);
                                            self.existingMapTargetList.push(valSuffix);
                                            var valDef = valModel.get('valueDefinition');
                                            if (valDef) {
                                                self.showValueElementId = valDef.id;
                                                $('#valueElementName').text(valDef.get('name'));
                                                $("#showValueDefForObj").removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
                                            }else {
                                                $('#showValueElement').hide();
                                            }
                                            var unitName = self.getTargetElementUnit(valModel);
                                            $('#unit' + id).val(unitName);
                                            $('#unit' + id).prop('disabled', true);
                                            $('#showTargetElement').show();
                                            $('#selectMapId').hide();
                                            $('#showTargetInfo').css('visibility', 'visible');
                                            $('#showSelectForm').text(valModel.get('name'));                                            
                                            var codeContainer = Backbone.Relational.store.getObjectByName('beeppackage.CodeContainer').find({id:self.commonCodeContainer});
                                            //CodeContainer.getBlobInstanceWithId(self.commonCodeContainer, DataManager.getDataManager().get('currentPlan'), function(codeContainer) {
                                                if (codeContainer && codeContainer.get('code')) {
                                                    var json = JSON.parse(codeContainer.get('code'));
                                                    for (var phaseId in json) {
                                                        for (var key in json[phaseId][self.fc]) {
                                                            if (key === id) {
                                                                if (valModel) {
                                                                    json[phaseId][self.fc][key].valueTargetId = valSuffix;
                                                                    self.showTargetElemetId = valModel.id;
                                                                    if(unitName){
                                                                        json[phaseId][self.fc][key].unit = unitName;
                                                                    }
                                                                }
                                                                break;
                                                            }
                                                        }
                                                    }
                                                    codeContainer.set('code', JSON.stringify(json));
                                                }
                                            //});
                                        }
                                        $('.showComplete').removeClass('disabled')
                                    });
                                    self.wizard.startWizard();
                                    $(this).css('z-index', '1050');
                                    return false;
                                }
                            },
                            deleteMapping: {
                                label: "Unlink Value",
                                className: "btn btn-default deleteMapping deleteRow",
                                callback: function() {
                                    $('.deleteMapping').css("display", "none");                                           
                                    var codeContainer = Backbone.Relational.store.getObjectByName('beeppackage.CodeContainer').find({id:self.commonCodeContainer});
                                    if (codeContainer && codeContainer.get('code')) {
                                            var json = JSON.parse(codeContainer.get('code'));
                                            for (var phaseId in json) {
                                                for (var key in json[phaseId][self.fc]) {
                                                    if (key === id) {
                                                        self.existingMapTargetList = self.existingMapTargetList.filter(item => item !== window.utils.getSuffix(json[phaseId][self.fc][key].valueTargetId));
                                                        json[phaseId][self.fc][key].valueTargetId = "";
                                                        break;
                                                    }
                                                }
                                            }
                                            codeContainer.set('code', JSON.stringify(json))
                                        }
                                        $('.showComplete').removeClass('disabled');
                                        $('.createMapping').removeClass('hide');
                                        $('#unit' + id).prop('disabled', false);
                                        $('#showTargetElement').hide();
                                        return false;
                                    }
                            },
                            main: {
                                label: "close",
                                className: "btn btn-default",
                                callback: function() {
                                    self.showValueElementId = null;
                                }
                            },
                            success: {
                                label: '<i class="fa fa-check"></i> Complete',
                                className: "btn btn-complete showComplete disabled",
                                callback: function() {
                                    var codeContainer = Backbone.Relational.store.getObjectByName('beeppackage.CodeContainer').find({id:self.commonCodeContainer});
                                    //CodeContainer.getBlobInstanceWithId(self.commonCodeContainer, DataManager.getDataManager().get('currentPlan'), function(codeContainer) {
                                        if (codeContainer && codeContainer.get('code')) {
                                            var json = JSON.parse(codeContainer.get('code'));
                                            /*if ($('#' + id).attr('valueId') && self.showTargetElemetId) {
                                                var data = self.model.get('data');
                                                var xml = $.parseXML(data);
                                                var valueTags = xml.getElementsByTagName('vdml:value');
                                                for (var i = 0; i < valueTags.length; i++) {
                                                    if (valueTags[i].getAttribute('id') === $('#' + id).attr('valueId')) {
                                                        valueTags[i].setAttribute('mid', self.showTargetElemetId);
                                                        var model = self.getValueTarget(self.showTargetElemetId);
                                                        valueTags[i].setAttribute('mpid', model.getNestedParent().id)
                                                        break;
                                                    }
                                                }
                                                self.model.set('data', (new XMLSerializer()).serializeToString(xml));
                                                self.parentView.openDiagram(self.model.get('data'), false);
                                            }*/
                                            if ($('#valueElementName').text() === "") {
                                                for (var i = self.selectedValueLibList.length - 1; i >= 0; i--) {
                                                    if (self.selectedValueLibList[i] && self.selectedValueLibList[i].id === self.showValueElementId) {
                                                        self.selectedValueLibList.splice(i, 1);
                                                        break;
                                                    }
                                                }
                                                self.showValueElementId = null;
                                            }
                                            var valModel = self.showValueElementId?self.getValueLibTypeModal(self.showValueElementId):null;
                                            for (var phaseId in json) {
                                                for (var key in json[phaseId][self.fc]) {
                                                    if (key === id) {
                                                        if (!json[phaseId][self.fc][key]) {
                                                            json[phaseId][self.fc][key] = {};
                                                        }
                                                        //if (json[phaseId][self.fc][key].ValueName != $('#valueName'+id).val().trim()) {
                                                            json[phaseId][self.fc][key].ValueName = $('#valueName'+id).val().trim();
                                                            var unitText = $('#unit' + id).val() ? " (" + $('#unit' + id).val() + ")" : "";
                                                            var text = $('#valueName'+id).val().trim() + unitText;
                                                            $('#' + id).find('a').text(text);
                                                        //}
                                                        json[phaseId][self.fc][key].unit = $('#unit' + id).val();
                                                        if (valModel) {                                                            
                                                            $('#value' + id).text(valModel.get('name'));
                                                            //valModel.set('unit', $('#unit' + id).val().trim())
                                                            self.selectedValueLibList.push(self.showValueElementId)
                                                            json[phaseId][self.fc][key].valueElementId = self.showValueElementId;
                                                            $('#showValueElement').show();
                                                            $('#valueElementName').text(valModel ? valModel.get('name') : "");
                                                            $("#showValueDefForObj").removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
                                                        } else {
                                                            json[phaseId][self.fc][key].valueElementId = self.showValueElementId;
                                                            $('#value' + id).text("");
                                                        }
                                                        break;
                                                    }
                                                }
                                            }
                                            codeContainer.set('code', JSON.stringify(json))
                                        }
                                    //})
                            }   }
                        }
                    });
                    //$('#valueName' + self.encodeId).val(text);
                    //$('#valueName' + self.encodeId).attr('vtext', event.currentTarget.parentElement.getAttribute('vtext'))
                    $('.deleteRow').css('float', 'left');
                    //$('.deleteRow').css('background-color', 'rgb(217, 83, 79)');
                    $('#deleteValueDef').hide();

                    //if ($('#unit' + id).val()) {
                        $('.showComplete').removeClass('disabled')
                    //}
                    /*$('#unit' + id).keyup(function(eve) {
                        var unit = eve.currentTarget.value;
                        if (unit && unit.trim() && $('#valueName'+id).val() && $('#valueName'+id).val().trim()) {
                            $('.showComplete').removeClass('disabled');
                        } else {
                            $('.showComplete').addClass('disabled');
                        }
                    })*/

                    $('#showValueDefForObj').on('click', function() {
                        var randId = window.utils.htmlEscape(window.guidGenerator());
                        var currentPlan = DataManager.getDataManager().get('currentPlan');
                        currentPlan.loadValueLibraries(function(valueLibraries) {
                            var options = {
                                valueLibs: valueLibraries,
                                callback: function(values, phases) {
                                    if (values) {
                                        self.showValueElementId = values[0];
                                        var valueDef = self.getValueLibTypeModal(self.showValueElementId);
                                        if (valueDef) {
                                            $('#unit' + id).val(valueDef ? valueDef.get('unit') : "");
                                            $('#valueElementName').text(valueDef ? valueDef.get('name') : "");
                                            $("#showValueDefForObj").removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
                                            $('.showComplete').removeClass('disabled');
                                        }
                                    }
                                }
                            };
                            options.selectedList = self.selectedValueLibList;
                            options.showValueElementId = self.showValueElementId;
                            options.showPhases = true;
                            options.hideCheck = true;
                            if(!$('#showTargetElement').is(":hidden")) {
                                options.targetDetailsMode = true;
                            }
                            window.getAndCreateModalDialog(self.model, randId, ValueDeliveryModelMixin, self.model, "ValueExplorerJson", null, options);
                        });
                    })

                    $('#deleteValueDef').on('click', function() {
                        $('#valueElementName').text("");
                        $("#showValueDefForObj").removeClass('glyphicon-pencil').addClass('glyphicon-plus-sign');
                        $('.showComplete').removeClass('disabled');
                        $('#deleteValueDef').hide();
                    })

                    $('#valueName'+id).keyup(function(eve) {
                        var val = eve.currentTarget.value;
                        if (val && val.trim()) {
                            $('.showComplete').removeClass('disabled');
                        } else {
                            $('.showComplete').addClass('disabled');
                        }
                    })
                    $('#showValMappedInfo').click('on', function(event) {
                        event.stopPropagation();
                        var id = window.utils.htmlEscape(window.guidGenerator());
                        var currentPlan = DataManager.getDataManager().get('currentPlan');
                        currentPlan.loadValueLibraries(function(valueLibraries) {
                            var options = {
                                valueLibs: valueLibraries,
                                targetDetailsMode: true,
                                callback: function(capId, capName) {
                                    if (!capId) {
                                        return;
                                    }
                                }
                            };
                            options.showValueElementId = self.showValueElementId;
                            options.showPhases = true;
                            options.hideCheck = true;
                            window.getAndCreateModalDialog(self.model, id, ValueDeliveryModelMixin, self.model, "ValueExplorerJson", null, options);
                        });
                    })
                    
                    $('#showTargetInfo').click('on', function(event) {
                        self.showTargetDetailsDialog(event,self.showTargetElemetId);
                    })

                    for (var phaseId in json) {
                        for (var key in json[phaseId][self.fc]) {
                            if (key === id) {
                                var valueModal = self.getValueTarget(json[phaseId].phaseMapId, json[phaseId][self.fc][key].valueTargetId);
                                if (valueModal) {
                                    $('#showTargetElement').show()
                                    $('#selectMapId').hide();
                                    $('#showTargetInfo').css('visibility', 'visible');
                                    self.showTargetElemetId = valueModal.id;
                                    $('#showTargetElement').show()
                                    $('#showSelectForm').text(valueModal.get('name'));
                                    $('.createMapping').addClass('hide');
                                    $('.deleteMapping').css("display", "block");
                                    $('#unit' + id).prop('disabled', true);
                                    var unitName = self.getTargetElementUnit(valueModal);
                                    var valueDef = valueModal.get('valueDefinition');
                                    var valDefName = valueDef ? valueDef.get("name") : valDefName;
                                    //var valModel = self.getValueLibTypeModal(json[phaseId][self.fc][key].valueElementId);
                                    if (valueDef) {
                                        $('#showValueElement').show();
                                        self.showValueElementId = valueDef.id;
                                        $('#valueElementName').text(valueDef.get("name"));
                                        $('#deleteValueDef').show();
                                        $("#showValueDefForObj").removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
                                    }else {
                                        $('#showValueElement').hide();
                                    }
                                    if (unitName) {
                                        $('#unit' + id).val(unitName)
                                    }
                                    //break;
                                } else {
                                    $('#showTargetElement').hide();
                                    if (json[phaseId][self.fc][key].unit) {
                                        $('#unit' + id).val(json[phaseId][self.fc][key].unit)
                                    }
                                    $('.deleteMapping').css("display", "none");
                                    self.showTargetElemetId = null;
                                    json[phaseId][self.fc][key].valueTargetId = null;
                                    $('.createMapping').removeClass('disabled');
                                }
                                if (json[phaseId][self.fc][key] && !valueModal && json[phaseId][self.fc][key].valueElementId) {
                                    var valDef = self.getValueLibTypeModal(json[phaseId][self.fc][key].valueElementId);
                                    if(valDef){
                                        self.showValueElementId = json[phaseId][self.fc][key].valueElementId;
                                        $('#showValueElement').show();
                                        $('#deleteValueDef').show();
                                        $('#valueElementName').text(valDef.get("name"));
                                        $("#showValueDefForObj").removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
                                    }else {
                                        json[phaseId][self.fc][key].valueElementId = ""; 
                                    }
                                    if (json[phaseId][self.fc][key].unit) {
                                        $('#unit' + id).val(json[phaseId][self.fc][key].unit)
                                    }
                                }
                                $('#valueName' + id).val(json[phaseId][self.fc][key].ValueName);
                                $('#valueName' + id).attr('vtext', json[phaseId][self.fc][key].ValueName);
                                break;
                            }
                        }
                        break;
                    }
                    if(window.checkContextForRevision()){
                        $('.createMapping').addClass('hide');
                    }
                    codeContainer.set('code', JSON.stringify(json));
                }
            //});
        }
        return tr;
    }

    getObservation() {
        var altId = DataManager.getDataManager().get('viewAlternative');
        var altModel = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({id: altId})
        var phaseObjSet = altModel ? altModel.get('phaseObjectiveSet') : "";
        var sce = phaseObjSet ? phaseObjSet.get('objectiveObservation') : "";
        return sce;
    }

    importJson() {
        var self = this;
        document.getElementById("file1").click();
    }
    getValueDefPath(valModel,valDefPath){
        var self= this;
        if(valModel.get("type") != "vdml_ValueLibrary"){
            valDefPath = ">"+valModel.get("name").concat(valDefPath);
            if(valModel.get('parentValue').length > 0){
                valModel = valModel.get('parentValue').models[0];
            }else {
                valModel = valModel.getParent();
            }
            valDefPath = self.getValueDefPath(valModel,valDefPath);
        }else {
            valDefPath = valModel.get("name").concat(valDefPath);
        }
        return valDefPath;
    }
	generateCSVData(json){
        var self = this;
		var csvDataObj = [];
        var keyContent = self.fc;
        var csvRow = {};
        for(var phaseId in json){
            //csvRow['period'] = json[phaseId].period;
            var phaseMapName = json[phaseId].phaseMapName;
            for (var key in json[phaseId][keyContent]) {
                var valueName = json[phaseId][keyContent][key].ValueName;
                var existingCsvRow = csvRow[valueName];
                if(!existingCsvRow){
                    csvRow[valueName] = {}
                    csvRow[valueName]['ValueName'] = valueName;
                    var unit = json[phaseId][keyContent][key].unit;
                    if(unit){
                        csvRow[valueName]['Unit'] = unit;
                    }
                    var valueDefinitionId = json[phaseId][keyContent][key].valueElementId;
                    var valDefPath = "";
                    var valModel = self.getValueLibTypeModal(valueDefinitionId);
                    if(valModel){
                        csvRow[valueName]['ValueDefinitionName'] = self.getValueDefPath(valModel,valDefPath);;
                    }
                }
                var value = json[phaseId][keyContent][key].Value;
                //if(Value){
                    csvRow[valueName][phaseMapName] = value?value:"-";
                //}
            }
        }
        for(var val in csvRow){
            csvDataObj.push(csvRow[val]);
        }
		if (csvDataObj.length === 0) {
            csvDataObj.push({});
        }
        return csvDataObj;
	}
	exportJson(){
		var self = this;
		window.utils.startSpinner('exportCSV', "Exporting CSV ... ");
		window.setTimeout(function () {
			var currentPlan = DataManager.getDataManager().get('currentPlan');
			CodeContainer.getBlobInstanceWithId(self.commonCodeContainer, currentPlan, function(codeContainer) {
				var json = {};
				if (codeContainer && codeContainer.get('code')) {
					json = JSON.parse(codeContainer.get('code'));
				}
				require(["xlsx"], function (XLSX) {
					var csvObj = self.generateCSVData(json);
					var ws = XLSX.utils.json_to_sheet(csvObj);
					var blobData = XLSX.utils.sheet_to_csv(ws);
                    window.utils.stopSpinner('exportCSV');
                    var exportMsg = DataManager.getDataManager().get('localeManager').get('exportComplete','Objective');
                    window.utils.exportFileToSystem(self.model.get('name'),'CSV File',".csv",blobData,exportMsg);
				});
			})
		}, 100);
		
	}
    importFile(view, event) {
        var self = this;
        var reader = new FileReader();
        var fileObject = event.target.files[0];
        window.utils.startSpinner('importCSV', "Importing CSV ... ");
        reader.onload = function (ev) {
            import('xlsx')
            .then((XLSX) => {
                var csvContent = ev.currentTarget.result;
                function showErrorMsg(){
                    window.utils.stopSpinner('importCSV');
                    bootbox.alert('Imported csv is empty');
                    return;
                }
                if (csvContent.trim().length === 0) {
                    showErrorMsg();
                }
                var wb = XLSX.read(csvContent, { type: "binary"});
                var sheet1 = wb.Sheets[wb.SheetNames[0]];
                var csvObj = XLSX.utils.sheet_to_row_object_array(sheet1);
                if (csvObj.length === 0) {
                    showErrorMsg();
                }
                var cloumnHeaders = Object.getOwnPropertyNames(csvObj[0]);
                if (cloumnHeaders.indexOf('ValueName') < 0) {
                    window.utils.stopSpinner('importCSV');
                    bootbox.alert('Imported csv has wrong layout');
                    return;
                } else {
                    self.importCsvData(csvObj,function(){
                        self.loadTable(self.parentView);
                        window.utils.stopSpinner('importCSV');
                        bootbox.alert('Import of Objectives completed');
                    });
                }
            }).catch(error => {
                window.utils.stopSpinner('importCSV');
                bootbox.alert('Imported csv has wrong layout');
                console.error('An error occurred:', error);
            });
        };
        if(fileObject != null){
            reader.readAsText(fileObject);
        }
	}

    importCsvData(csvDataObj,callback){
        var self = this;
        var codeContainer = Backbone.Relational.store.getObjectByName('beeppackage.CodeContainer').find({id:self.commonCodeContainer});
        //CodeContainer.getBlobInstanceWithId(self.commonCodeContainer, DataManager.getDataManager().get('currentPlan'), function(codeContainer) {
            if (codeContainer) {
                    var existJson = {};
                    existJson = JSON.parse(codeContainer.get('code'));
                    var keyContent = self.fc;
                    var existingValueNames = {};
                    var existingPhaseNames = {};
                    for(var phaseId in existJson){
                        if(Object.keys(existingValueNames).length === 0){
                            for (var key in existJson[phaseId][keyContent]) {
                                existingValueNames[existJson[phaseId][keyContent][key].ValueName]=existJson[phaseId][keyContent][key].id;
                            }
                        }
                        existingPhaseNames[existJson[phaseId].phaseMapName]=phaseId;
                    }
                    var newPhaseList = {};
                    for(var csvRow in csvDataObj){
                        var objectiveName = csvDataObj[csvRow].ValueName;
                        if(!objectiveName){
                            continue;
                        }
                        var unitName = csvDataObj[csvRow].Unit;
                        var valueDefinitionNamePath = csvDataObj[csvRow].ValueDefinitionName;
                        var valueDefId = "";
                        if(valueDefinitionNamePath){
                            var names = valueDefinitionNamePath.split('>');
                            if(names.length > 0){
                                var matchedLibId;
                                for(var libId in self.valLibNames){
                                    if(names[0] == self.valLibNames[libId]){
                                        matchedLibId = libId;
                                        break;
                                    }
                                    
                                }
                                if(matchedLibId){
                                    var matchedLib = Backbone.Relational.store.getObjectByName('vdml.ValueLibrary').find({ id: matchedLibId});
                                    if(matchedLib){
                                        var valueDef = matchedLib.get('value').findWhere({'name':names[1]});
                                        if(valueDef){
                                            if(names.length > 1){
                                                for(var i=2;i<names.length;i++){
                                                    valueDef = valueDef.get('childValue').findWhere({'name':names[i]});
                                                    if(!valueDef){
                                                        valueDef = null;
                                                        break; 
                                                    }
                                                }
                                            }
                                        }
                                        valueDefId = valueDef.get('id');
                                    }
                                }
                            }
                        }
                        var newValueCreated = false;
                        var obj = csvDataObj[csvRow];
                        var matchedPhaseList = {};
                        for (var key in obj) {
                            if (obj.hasOwnProperty(key) && key != 'ValueName' && key != 'Unit' && key != 'ValueDefinitionName') {
                                var value = obj[key];
                                var phaseMapName = key;
                                var phaseId = existingPhaseNames[phaseMapName];
                                if(!phaseId){
                                    phaseId = newPhaseList[phaseMapName];
                                }
                                if(!phaseId){
                                    phaseId = window.utils.htmlEscape(window.guidGenerator());
                                    existJson[phaseId] = {};
                                    //existJson[phaseId].period = phaseMapName;
                                    existJson[phaseId].phaseMapId ="";
                                    existJson[phaseId].phaseMapName = phaseMapName;
                                    //existingPhaseNames[phaseMapName]=phaseId;
                                    newPhaseList[phaseMapName]=phaseId;
                                }else {
                                    matchedPhaseList[phaseMapName] = phaseId;
                                }
                                var valueId = existingValueNames[objectiveName]
                                if(!valueId){
                                    valueId = "average_" + window.utils.htmlEscape(window.guidGenerator());
                                    existingValueNames[objectiveName] = valueId;
                                    newValueCreated = true;
                                }
                                existJson = self.addObjectiveToCodeContainer(existJson, phaseId, keyContent, valueId, objectiveName, unitName, value, valueDefId, null);
                            }
                        }
                        if(newValueCreated){
                            for(let phaseName in existingPhaseNames){
                                if(!matchedPhaseList[phaseName]){
                                    existJson = self.addObjectiveToCodeContainer(existJson, existingPhaseNames[phaseName], keyContent, existingValueNames[objectiveName], objectiveName, unitName, null, valueDefId, null);
                                }
                            }
                        }
                        /*for(var ph in newPhaseList){
                            existingPhaseNames[ph]=newPhaseList[ph];
                        }*/
                    }
                codeContainer.set('code', JSON.stringify(existJson));
                callback();
            }
        //});
    }
    getValueLibTypeModal(id){
        if(!id){
            return null;
        }
        var valModel = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({id: id});
        if (!valModel) {
            valModel = Backbone.Relational.store.getObjectByName('vdml.ValueCategory').find({id: id});
        }
        return valModel;
    }

    addObjectiveToCodeContainer(json, phaseId, keyContent, randomId, objectiveName, unitName, value, valueElementId, valueTargetId){
        var self = this;
        if (json[phaseId]) {
            if (json[phaseId][keyContent]) {
                json[phaseId][keyContent][randomId] = {};
            } else {
                json[phaseId][keyContent] = {};
            }
            json[phaseId][keyContent][randomId] = {};
            json[phaseId][keyContent][randomId].ValueName = objectiveName;
            json[phaseId][keyContent][randomId].id = randomId;
            json[phaseId][keyContent][randomId].Value = isNaN(value)?"":value;
            json[phaseId][keyContent][randomId].valueElementId = valueElementId;
            json[phaseId][keyContent][randomId].valueTargetId = valueTargetId;
            //var valDefName = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.showValueElementId});
            var valDef = self.getValueLibTypeModal(valueElementId);
            //json[phaseId][keyContent][randomId].valueDefinitionName = valDef ? valDef.get('name') : ""
            var unitName = valDef ? valDef.get('unit') : unitName;
            json[phaseId][keyContent][randomId].unit = unitName;
        }
        return json;
    }

    /*importFile(view, event) {
        var self = this;
        var reader = new FileReader();
        var fileObject = event.target.files[0];
        reader.onload = function(ev) {
            var header = $('#myTable' + self.encodeId).find('th');
            var headerId;
            var phaseExist = false;
            var res = ev.currentTarget.result;
            var obj = {};
            obj = JSON.parse(res);
            var htmlContent = "<div>Choose phase to import<br/>";
            for (var i = 2; i < header.length; i++) {
                var hId = header[i].id;
                var text = $('#text' + hId).text();
                htmlContent += "<input type=radio id=" + hId + " value=" + text + " name=phases><label for=" + hId + ">" + text + "</label><br/>";
                phaseExist = true;
            }
            htmlContent += "</div><br/>"
            if (!phaseExist) {
                bootbox.alert("There are no phases matching for importing.")
            } else {
                htmlContent += "<div>Select value import as<br/><input type='checkbox' id='objSelect'><b> Objective</b><br><input type='checkbox' id='valueSelect'><b> Value</b></div>";
                bootbox.dialog({
                    title: "<div>Select Import Type....</div>",
                    message: htmlContent,
                    show: true,
                    buttons: {
                        cancel: {
                            label: "Cancel",
                            className: "btn btn-default",
                            callback: function() {}
                        },
                        complete: {
                            label: "Complete",
                            className: "btn btn-complete saveImport",
                            callback: function() {
                                headerId = $('input[type=radio][name=phases]:checked')[0]? $('input[type=radio][name=phases]:checked')[0].id : null;
                                if (headerId && $('#objSelect').is(':checked')) {
                                    if ($('#objBody') && $('#objBody').length > 0) {
                                        var child = $('#objBody').children();
                                        CodeContainer.getBlobInstanceWithId(self.commonCodeContainer, DataManager.getDataManager().get('currentPlan'), function(codeContainer) {
                                            if (codeContainer) {
                                                if (codeContainer.get('code')) {
                                                    var existJson = {};
                                                    existJson = JSON.parse(codeContainer.get('code'));
                                                    for (var i = 0; i < child.length; i++) {
                                                        var cId = child[i].getAttribute('originalid');
                                                        if (obj.fileContent) {
                                                            var valueSet = _.filter(obj.fileContent, function(mod) {
                                                                return mod
                                                            });
                                                            var matchFound = _.filter(valueSet, function(model) {
                                                                return model.ValueName === $('#' + cId).attr('vtext')
                                                            })
                                                            if (matchFound && matchFound.length > 0) {
                                                                var match = _.filter(matchFound, function(val) {
                                                                    return val.id.startsWith('average_')
                                                                })
                                                                if (match.length === 1) {
                                                                    $('#span' + cId + headerId).text(match[0].Value === "NaN" ? "-" : match[0].Value)
                                                                    if (existJson[headerId]) {
                                                                        existJson[headerId][self.fc][cId].Value = match[0].Value === "NaN" ? "-" : match[0].Value;
                                                                    }
                                                                }
                                                            }
                                                        } else if(obj[headerId]){
                                                            var matchFound = _.filter(obj[headerId][self.fc], function(val) {
                                                                return val.ValueName === $('#' + cId).attr('vtext')
                                                            });
                                                            if (matchFound.length > 0) {
                                                                $('#span' + cId + headerId).text(matchFound[0].Value === "NaN" ? "-" : matchFound[0].Value)
                                                                if (existJson[headerId]) {
                                                                    existJson[headerId][self.fc][cId].Value = matchFound[0].Value === "NaN" ? "-" : matchFound[0].Value;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    codeContainer.set('code', JSON.stringify(existJson));
                                                }
                                            }
                                        })
                                    }
                                }
                                if ($('#valueSelect').is(':checked')) {
                                    CodeContainer.getBlobInstanceWithId(self.commonCodeContainer, DataManager.getDataManager().get('currentPlan'), function(codeContainer) {
                                        if (codeContainer) {
                                            if (codeContainer.get('code')) {
                                                var existJson = {};
                                                existJson = JSON.parse(codeContainer.get('code'));
                                                for (var key in existJson[headerId][self.fc]) {
                                                    if (obj.fileContent) {
                                                        var valModel = self.getValueTarget(existJson[headerId][self.fc][key].valueTargetId);
                                                        var text = valModel ? valModel.get('name') : "";
                                                        var valueSet = _.filter(obj.fileContent, function(mod) {
                                                            return mod
                                                        });
                                                        var matchFound = _.filter(valueSet, function(model) {
                                                            return model.ValueName === text
                                                        });
                                                        if (matchFound.length > 0) {
                                                            for (var i = 0; i < matchFound.length; i++) {
                                                                if (matchFound[i].id.startsWith('average_')) {
                                                                    $('#span' + key + headerId).text(matchFound[i].Value === "NaN" ? "-" : matchFound[i].Value);
                                                                    if (existJson[headerId]) {
                                                                        existJson[headerId][self.fc][key].Value = matchFound[i].Value === "NaN" ? "-" : matchFound[i].Value;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    } else {
                                                        for (var key in obj[headerId][self.fc]) {
                                                            var valModel = self.getValueTarget(existJson[headerId][self.fc][key].valueTargetId);
                                                            var text = valModel ? valModel.id : "";
                                                            var matchFound = _.filter(obj[headerId][self.fc], function(val) {
                                                                return val.valueTargetId === text
                                                            });
                                                            if (matchFound.length > 0) {
                                                                for (var i = 0; i < matchFound.length; i++) {
                                                                    if (matchFound[i].id.startsWith('average_')) {
                                                                        $('#span' + key + headerId).text(matchFound[i].Value === "NaN" ? "-" : matchFound[i].Value);
                                                                        if (existJson[headerId]) {
                                                                            existJson[headerId][self.fc][key].Value = matchFound[i].Value === "NaN" ? "-" : matchFound[i].Value;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            codeContainer.set('code', JSON.stringify(existJson));
                                        }
                                    });
                                }
                            }
                        }
                    }
                })
            }

            self.parentView.setResizeHeight();
        }
        if(fileObject != null){
            reader.readAsText(fileObject);
        }
    }
    exportJson() {
        var self = this;
        CodeContainer.getBlobInstanceWithId(self.commonCodeContainer, DataManager.getDataManager().get('currentPlan'), function(codeContainer) {
            if (codeContainer) {
                if (codeContainer.get('code')) {
                    var json = {};
                    json = JSON.parse(codeContainer.get('code'));
                    var blob = new Blob([JSON.stringify(json)], {
                        type: "application/json"
                    });

                    var saveAs = window.saveAs;
                    saveAs(blob, self.model.get('name') + ".json");
                    bootbox.alert('Export is completed')
                }
            }
        })
    }*/
    showDropdown() {
        var self = this;
        event.stopPropagation();
        $("#dropdownMenuObj" + self.encodeId).toggle();
    }
    addPhaseOrValueDef() {
        var self = this;
        var id = window.utils.htmlEscape(window.guidGenerator());
        var currentPlan = DataManager.getDataManager().get('currentPlan');
        currentPlan.loadValueLibraries(function(valueLibraries) {
            var options = {
                valueLibs: valueLibraries,
                callback: function(values, phases) {
                    if (phases) {
                        var self = window.vdmModelView.strategyMapObjectiveViewModel;
                        var container = self.commonCodeContainer;
                        CodeContainer.getBlobInstanceWithId(container, DataManager.getDataManager().get('currentPlan'), function(codeContainer) {
                            if (codeContainer) {
                                var existingValues = {};
                                var keyContent = self.fc;
                                var json = {};
                                if (codeContainer.get('code')) {
                                    json = JSON.parse(codeContainer.get('code'));
                                    for(var phId in json){
                                        for (var key in json[phId][keyContent]) {
                                            existingValues[key]=json[phId][keyContent][key];
                                        }
                                        break;
                                    }
                                }
                                for (var p = 0; p < phases.length; p++) {
                                    var name = phases[p].name;
                                    var phaseId = phases[p].id;
                                    var mapId = phases[p].mapId;
                                    var table = $('#myTable' + self.encodeId);
                                    if(table.find("th[id='"+phaseId+"']").length == 0){
                                        self.selectedPhases.push({
                                            id: phaseId,
                                            name: name,
                                            mapId: mapId
                                        })
                                        self.addHeaderColumn(window.utils.htmlEscape(phaseId), name);
                                        if (codeContainer.get('code')) { 
                                            json[phaseId] = {};
                                            json[phaseId][keyContent] = {};
                                            //json[phaseId].period = name;
                                            json[phaseId].phaseMapId = mapId ? mapId : "";
                                            json[phaseId].phaseMapName = name;
                                            var header = $('#myTable' + self.encodeId).find('th');
                                            for (var i = 2; i < header.length; i++) {
                                                if (json[header[i].id]) {
                                                    if (json[header[i].id][keyContent]) {
                                                        for (var key in json[header[i].id][keyContent]) {
                                                            if ($('#row' + json[header[i].id][keyContent][key].id) && $('#row' + json[header[i].id][keyContent][key].id).length > 0) {
                                                                json[phaseId][keyContent][key] = {};
                                                                json[phaseId][keyContent][key].id = key;
                                                                var existVal = existingValues[key];
                                                                if(existVal){                                                                
                                                                    json = self.addObjectiveToCodeContainer(json, phaseId, keyContent, key, existVal.ValueName, existVal.unit, null, existVal.valueElementId, existVal.valueTargetId);
                                                                    self.objectiveColumnCreate(key + phaseId, $('#row' + json[header[i].id][keyContent][key].id), phaseId);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                break;
                                            }
                                        } else {
                                            json[phaseId] = {};
                                            //json[phaseId].period = name;
                                            json[phaseId].phaseMapId = mapId ? mapId : "";
                                            json[phaseId].phaseMapName = name;
                                        }
                                    }else {
                                        json[phaseId].phaseMapName = name;
                                        $('#text' + phaseId).text(name);
                                    }                                    
                                }
                                codeContainer.set('code', JSON.stringify(json));
                            }
                        })
                    }
                    if (values) {
                        for (var val = 0; val < values.length; val++) {
                            var valDefModal = self.getValueLibTypeModal(values[val]);
                            var text = valDefModal ? valDefModal.get('name') : null;
                            if(text){
                                if(valDefModal){
                                    var text = valDefModal.get('name');
                                    var unitName = valDefModal.get('unit');
                                    var displayObjName = text.concat(unitName ? " (" + unitName + ")" : "");
                                    var vId = values[val];
                                    self.createTable(displayObjName, text, vId)
                                }
                            }
                        }
                    }
                    self.loadTable(self.parentView);
                    self.parentView.setResizeHeight();
                }
            };
            options.selectedList = self.selectedValueLibList;
            options.selectedPhases = self.selectedPhases;
            window.getAndCreateModalDialog(self.model, id, ValueDeliveryModelMixin, self.model, "ValueExplorerJson", null, options);
        });

    }

    getObjectiveHtmlContent(editMode,id){
        var self = this;
        var htmlContent = "<div class = row style='font-size:14px'><div class=col-xs-1></div>";
        htmlContent += "<div class = col-xs-3><span class = control-label>";
        htmlContent += "<span>" + self.labels['name']() + "</span></span><label style='color:orange'>*</label></div>"
        htmlContent += "<div class=col-xs-7>"
        if(editMode){
            htmlContent += "<input id = valueName" + id + " class ='form-control focus-ele' style=height:30px;font-size:15px;>";
        }else {
            htmlContent += "<div id = valueName" + id + "></div><span style='font-size: 12px; color: orange;' id = objMessage" + id + "></span>";
        }
        htmlContent += "</div>";
        htmlContent += '<div class="col-xs-1" style = "padding-top: 4px;"><span class="match"></span></div></div><br>'
        htmlContent += "<div class = row style='font-size :14px'><div class=col-xs-1></div>";
        htmlContent += "<div class =col-xs-3><span class= control-label>";
        htmlContent += "<span>" + self.labels['Unit']() + "</span></span></div>";
        htmlContent += "<div class=col-xs-7>"
        if(editMode){
            htmlContent += "<input placeholder=" + self.labels['Unit']() + " class ='form-control focus-ele' id=unit" + id + " style=font-size:14px>";
        }else {
            htmlContent += "<div id = unitName" + id + "></div>";
        }
        htmlContent += "</div>";
        htmlContent += '<div class="col-xs-1" style = "padding-top: 4px;"><span class="match"></span></div></div><br>'
        htmlContent += "<div id =showValueElement class = row style='font-size :14px'><div class=col-xs-1></div>";
        htmlContent += "<div class =col-xs-3><span class= control-label>";
        htmlContent += "<span>" + self.labels['ValueDefinition']() + "</span></span></div>";
        htmlContent += "<div class=col-xs-7><a href=javascript:void(0) id=showValMappedInfo><span id=valueElementName></span></a><span class='glyphicon glyphicon-plus-sign glyphicon-button' style='padding-left: 10px;font-size: 1.2em;' id='showValueDefForObj'></span>";
        htmlContent += "</div></div>";
        //if(editMode){
            htmlContent += "<br><div class = row style='font-size :14px' id=showTargetElement><div class=col-xs-1></div>";
            htmlContent += "<div class =col-xs-3><span class= control-label>";
            htmlContent += "<span>" + self.labels['value']() + "</span></span></div>";
            htmlContent += "<div class=col-xs-5><span id=showSelectForm></span>";
            htmlContent += "<span id=showTargetInfo title=Target Details class='glyphicon glyphicon-info-sign glyphicon-button' style='font-size: small; color: orange;padding-left:10px;visibility:hidden'></span></div><br></div>";
        //}
        return htmlContent;
    }

    addObjective(view,event) {
        event.stopPropagation();
        var self = this;
        self.loadMapvalues();
        self.fillMeasurementUnits();
        self.showTargetElemetId = null;
        self.showValueElementId = null;
        var htmlContent = self.getObjectiveHtmlContent(false,self.encodeId);
        var json={};
        var codeContainer = Backbone.Relational.store.getObjectByName('beeppackage.CodeContainer').find({id:self.commonCodeContainer});
        if (codeContainer) {
            if (codeContainer.get('code')) {
                json = JSON.parse(codeContainer.get('code'));
            }
            if (Object.keys(json).length === 0 && json.constructor === Object) {
                var customDialog = bootbox.dialog({
                    message: DataManager.getDataManager().get('localeManager').get('AddPhaseFirst'),
                    show: true,
                    buttons:{
                        cancel: {
                            label: "Cancel",
                            className: "btn btn-default",
                            callback: function() {}
                        },
                        ok: {
                            label: "OK",
                            className: "btn btn-primary",
                            callback: function() {
                                self.addPhaseOrValueDef();
                            }
                        }
                    },
                })
            }else{
                var dialog = bootbox.dialog({
                    title: "<div><img class=\"smallIcon\" src=\"img/icons/icons_31.png\"</img>&nbsp;&nbsp;" + self.labels['Objective']() + "</div>",
                    message: htmlContent,
                    show: true,
                    buttons: {
                        main: {
                            label: "Close",
                            className: "btn btn-default",
                            callback: function() {
                                self.showValueElementId = null;
                            }
                        },
                        success: {
                            label: '<i class="fa fa-check"></i> Complete',
                            className: "btn btn-complete showComplete disabled",
                            callback: function() {
                                if ($('#selectedValue' + self.encodeId).val()) {
                                    var objectiveName = $('#selectedValue' + self.encodeId).val();
                                    var randomId = "average_" + window.utils.htmlEscape(window.guidGenerator())
                                    if(self.dataTable){
                                        self.dataTable.destroy(false);
                                        self.dataTable = null;
                                        //$('#objBody').empty();
                                        //$('#myTable' + self.encodeId).dataTable().fnDestroy();
                                    }
                                    var table = $('#myTable' + self.encodeId)
                                    if (!$('#objBody') || ($('#objBody') && $('#objBody').length === 0)) {
                                        var tableBody = document.createElement('TBODY');
                                        tableBody.setAttribute('id', "objBody")
                                        table.append(tableBody);
                                    }
                                    var header = table.find('th'),
                                    keyContent = self.fc;
                                    //CodeContainer.getBlobInstanceWithId(codeId, DataManager.getDataManager().get('currentPlan'), function(codeContainer) {
                                        if (codeContainer) {
                                            if (codeContainer.get('code')) {
                                                json = JSON.parse(codeContainer.get('code'));
                                            }
                                            //var model = self.getValueTarget(self.showTargetElemetId);
                                            
                                            var valDefModal = self.getValueLibTypeModal(self.showValueElementId);
                                            if (valDefModal) {
                                                self.selectedValueLibList.push(valDefModal ? valDefModal.id : null);
                                            }
                                            var unitName = $('#unit' + self.encodeId).val();
                                            var displayObjName = objectiveName.concat(unitName ? " (" + unitName + ")" : "");
                                            var tr = self.createRow(randomId, displayObjName, (valDefModal ? valDefModal.get('name') : ""));
                                            for (var i = 2; i < header.length; i++) {
                                                self.objectiveColumnCreate(randomId + header[i].id, tr, header[i].id);
                                                if (json[header[i].id]) {
                                                    if (json[header[i].id][keyContent]) {
                                                        json[header[i].id][keyContent][randomId] = {};
                                                    } else {
                                                        json[header[i].id][keyContent] = {};
                                                    }
                                                    json[header[i].id][keyContent][randomId] = {};
                                                    json[header[i].id][keyContent][randomId].ValueName = objectiveName;
                                                    json[header[i].id][keyContent][randomId].id = randomId;
                                                    json[header[i].id][keyContent][randomId].unit = unitName;
                                                    json[header[i].id][keyContent][randomId].valueElementId = self.showValueElementId;
                                                    json[header[i].id][keyContent][randomId].valueTargetId = window.utils.getSuffix(self.showTargetElemetId);
                                                }
                                            }
                                            $('#' + randomId).attr('valueId', $('#selectedValue' + self.encodeId).attr('valueId'));
                                            codeContainer.set('code', JSON.stringify(json));
        
                                        }
                                        self.createDatatable();
                                        window.vdmModelView.setResizeHeight();
                                    //})
                                }
                            }
                        }
                    }
                })
                dialog.attr("id", "bootbox_id");
            }   
        }
        $('#bootbox_id').draggable();
        $('#showTargetInfo').click('on', function(event) {
            event.stopPropagation();
            self.showTargetDetailsDialog();
        });
        $('#showValMappedInfo').click('on', function(event) {
            event.stopPropagation();
            var id = window.utils.htmlEscape(window.guidGenerator());
            var currentPlan = DataManager.getDataManager().get('currentPlan');
            currentPlan.loadValueLibraries(function(valueLibraries) {
                var options = {
                    valueLibs: valueLibraries,
                    targetDetailsMode: true,
                    callback: function(capId, capName) {
                        if (!capId) {
                            return;
                        }
                    }
                };
                options.showValueElementId = self.showValueElementId;
                options.showPhases = true;
                options.hideCheck = true;
                window.getAndCreateModalDialog(self.model, id, ValueDeliveryModelMixin, self.model, "ValueExplorerJson", null, options);
            });
        })
        $('#showValueDefForObj').on('click', function() {
            var randId = window.utils.htmlEscape(window.guidGenerator());
            var currentPlan = DataManager.getDataManager().get('currentPlan');
            currentPlan.loadValueLibraries(function(valueLibraries) {
                var options = {
                    valueLibs: valueLibraries,
                    callback: function(values, phases) {
                        if (values) {
                            var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({id: values[0]});
                            $('#showValueElement').show();
                            self.showValueElementId = values[0];
                            $('#valueElementName').text(valueDef ? valueDef.get('name') : "");
                            $('#showValueDefForObj').hide();
                            if (!$('#valueName'+ self.encodeId).val()) {
                                $('#valueName'+ self.encodeId).val(valueDef ? valueDef.get('name') : "");
                                $('.showComplete').removeClass('disabled');
                            }
                            $('#unit' + self.encodeId).val(valueDef ? valueDef.get('unit') : "")
                        }
                    }
                };
                options.selectedList = self.selectedValueLibList;
                options.showPhases = true;
                options.hideCheck = true;
                window.getAndCreateModalDialog(self.model, randId, ValueDeliveryModelMixin, self.model, "ValueExplorerJson", null, options);
            });
        })

        var objExtended = Backbone.Typeahead.extend({
            template: '<input type="text" id="selectedValue' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        self.valueTypeahead = new objExtended({
            collection: this.valuesColl
        });
        var unitExtended = Backbone.Typeahead.extend({
            template: '<input type="text" id="unit' + self.encodeId + '" placeholder="' + self.labels['Unit']() + '" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        self.unitTypeahead = new unitExtended({
            collection: this.unitsColl
        });
        $('#showTargetElement').hide();
        self.valueTypeahead.setElement('#valueName' + self.encodeId).render();
        self.unitTypeahead.setElement('#unitName' + self.encodeId).render();
        $('#selectedValue' + self.encodeId).attr('autocomplete','new-password');
        $('#unit' + self.encodeId).attr('autocomplete','new-password');
        self.unitTypeahead.on('selected', function(selectedValue) {
            if (self.matchField) {
                self.matchField.hide();
            }
            $('.showComplete').removeClass('disabled');
            $('#unit' + self.encodeId).val(selectedValue.get('name'));
        });
        $('#unit' + self.encodeId).keyup(function (view) {
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                if (view.target.value && !view.target.value.trim() == "") {
                    var unitMatch = self.unitsColl.findWhere({ name: view.target.value });
                    if (unitMatch) {
                        self.matchField[0].innerHTML = 'Match';
                        self.matchField.show();
                        $('#unit' + self.encodeId).next().find('li').filter(function () { return $(this).text() === view.target.value; }).click();
                    }
                    else if (!view.target.value == "") {
                        self.matchField[0].innerHTML = 'New';
                        self.matchField.show();
                    }
                }
                else {
                    self.matchField.hide();
                }
            });
            $('#unit' + self.encodeId).dblclick(function (view) {
                $('#unit' + self.encodeId).trigger('keyup');
                $('#unit' + self.encodeId).focus();
            });
        self.valueTypeahead.on('selected', function(selectedValue) {
            if (self.matchField) {
                self.matchField.hide();
            }
            self.selectedValue = selectedValue;
            $('.showComplete').removeClass('disabled');
            //var model = self.getValueTarget(selectedValue.id);
            var model = window.utils.getElementModel(selectedValue.id,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
            $('#selectedValue' + self.encodeId).attr('valueId', selectedValue.id)
            if (model) {
                self.showTargetElemetId = model.id;
                $('#showTargetElement').show();
                $('#showTargetInfo').css('visibility', 'visible');
                 $('#showSelectForm').text(model.get('name')); 
                if(model.get('valueDefinition')){
                    self.showValueElementId = model.get('valueDefinition').id;
                    $('#valueElementName').text(model.get('valueDefinition').get('name'));
                    $('#showValueDefForObj').hide();
                }
                $('#unit' + self.encodeId).val(self.getTargetElementUnit(model));
                $('#unit' + self.encodeId).prop('disabled', true);
            }
        });
        $("#selectedValue" + self.encodeId).keyup(function(view) {
            var currentValue = view.currentTarget.value.trim().toLowerCase();
            var objExist = false;
            self.matchField = $(view.currentTarget).closest('.row').find('.match');
            /*if ($('#objBody') && $('#objBody').length > 0) {
                var child = $('#objBody').children();
                for (var i = 0; i < child.length; i++) {
                    var cId = child[i].getAttribute('originalid');
                    if ($('#' + cId).attr('vtext').trim() === view.currentTarget.value.trim()) {
                        $('#objMessage' + self.encodeId).text("" + DataManager.getDataManager().get('localeManager').get('ObjectiveDuplicate'));
                        $('.showComplete').addClass('disabled');
                        objExist = true;
                        break;
                    } else {
                        $('#objMessage' + self.encodeId).text("");
                    }
                }
            }*/
            if (view.currentTarget.value.trim() !== "" && !objExist) {
                self.matchField.show();
                $('.showComplete').removeClass('disabled');
            } else if (!objExist) {
                self.matchField.hide();
                $('.showComplete').addClass('disabled');
            }
            var matchFound = _.filter(self.valuesColl.models, function(obj) {
                return obj.get('name').toLowerCase() === currentValue;
            });
            if (matchFound && matchFound.length && !objExist) {
                self.selectedValue = matchFound[0];
                self.matchField[0].innerHTML = "Match";
            } else if (!objExist) {
                self.selectedValue = null;
                self.matchField[0].innerHTML = "New";
                $('#showTargetElement').hide();
                $('#unit' + self.encodeId).prop('disabled', false);
                self.showTargetElemetId = null;
            }
        });
        $('#selectedValue' + self.encodeId).change(function(view) {
            if (self.matchField) {
                self.matchField.hide();
                $('#showTargetElement').hide();
            }
        });
        $('#selectedValue' + self.encodeId).dblclick(function(view) {
            $('#selectedValue' + self.encodeId).trigger('keyup');
            $('#selectedValue' + self.encodeId).focus();
        });

    }
    showDialogValue(id, valueId) {
        var self = this;
        var value = $('#obj' + id + valueId).text();
        var keyContent = self.fc;
        var codeContainer = Backbone.Relational.store.getObjectByName('beeppackage.CodeContainer').find({id:self.commonCodeContainer});
        //CodeContainer.getBlobInstanceWithId(self.commonCodeContainer, DataManager.getDataManager().get('currentPlan'), function(codeContainer) {
            if (codeContainer) {
                if (codeContainer.get('code')) {
                    var json = JSON.parse(codeContainer.get('code'));
                    if (json[valueId]) {
                        if (json[valueId][keyContent] && json[valueId][keyContent][id]) {
                            /*var valueModel = self.getValueTarget(json[valueId][keyContent][id].valueTargetId);
                            if (valueModel) {
                                json[valueId][keyContent][id].ValueName = valueModel.get('name');
                                if (valueModel.get('valueDefinition')) {
                                    json[valueId][keyContent][id].valueElementId = valueModel.get('valueDefinition').id;
                                    json[valueId][keyContent][id].unit = valueModel.get('valueDefinition').get('unit');
                                }
                            }*/
                            json[valueId][keyContent][id].Value = value;
                        }
                        /*if ($("#" + id).attr('mapId')) {
                            json[valueId][keyContent][id].valueTargetId = $("#" + id).attr('mapId');
                        }*/
                    }
                    codeContainer.set('code', JSON.stringify(json));
                }
            }
        //})
    }
    fillMeasurementUnits() {
        var self = this;
        // fetching the units of Objectives
        var json = {};
        var codeContainer = Backbone.Relational.store.getObjectByName('beeppackage.CodeContainer').find({id:self.commonCodeContainer});
        if (codeContainer) {
            if (codeContainer.get('code')) {
                json = JSON.parse(codeContainer.get('code'));
                for (var key in json) {
                    if (json[key][self.fc]) {
                        var unitsJson = json[key][self.fc];
                        for(var key1 in unitsJson){
                            var unitObj = unitsJson[key1];
                            self.unitsColl.push({ id: unitObj.id, name: unitObj.unit});
                        }
                    }
                }
            }
        }                                            
        var currentPlan = DataManager.getDataManager().get('currentPlan');
        if (!currentPlan) {
            currentPlan = self.currentAlternativeModel.get('phaseAlternativeOwner').get('phaseOwner');
        }
        if (!currentPlan) {
            return;
        }
        var altScenarios = DataManager.getDataManager().altScenarios[currentPlan.get('id')];
        for (var scenarios in altScenarios) {
            var packages = altScenarios[scenarios];
            for (var packageScenario in packages) {
                var pac = packages[packageScenario].get('scenarioOwner');
                if (pac) {
                    var libElements = pac.createDefaultMeasureLibrary().get('measureElements');
                    for (var i = 0; i < libElements.length; i++) {
                        if (libElements.at(i).get("type") == "smm_UnitOfMeasure" && !self.unitsColl.findWhere({ 'name': libElements.at(i).get('name') })) {
                            self.unitsColl.push({ id: '' + libElements.at(i).get('id'), name: '' + libElements.at(i).get('name')});
                        }
                    }
                }
            }
        }
        //loading plan units
        var planlibElements = currentPlan.createDefaultMeasureLibrary().get('measureElements');
        for (var i = 0; i < planlibElements.length; i++) {
            if (planlibElements.at(i).get("type") == "smm_UnitOfMeasure" && !self.unitsColl.findWhere({ 'name': planlibElements.at(i).get('name') })) {
                self.unitsColl.push({ id: '' + planlibElements.at(i).get('id'), name: '' + planlibElements.at(i).get('name') });
            }
        }
    }
    objectiveColumnCreate(data, tr, valueId, val) { //objectiveColumnCreate creating objective 
        var self = this;
        var td2 = document.createElement('TD');
        tr.append(td2);
        td2.setAttribute('id', "obj" + data);
        td2.setAttribute('valueId', valueId);
        td2.setAttribute('contenteditable', "");
        td2.style.textAlign = 'right';
        //td2.style.width = "300px";
        //td2.style.overflow = "hidden";
        if (val || val === 0) {
            if (val === "NaN") {
                td2.textContent = "-";
            } else {
                td2.textContent = val;
            }
        }
        /*$('#obj' + data).mouseout(function() {
            var parentId = $(this).parent()[0].getAttribute('originalId')
            self.showDialogValue(parentId, $(this)[0].getAttribute('valueId'));
        })*/
        $('#obj' + data).on("keydown", function (event) {
            var value = event.target.innerText;
            $('#obj' + data).prop('val', value);
            window.utils.checkNumericValue(event,$('#obj' + data));
            if(event.keyCode === 13){
                event.preventDefault();
            }
            if ((event.keyCode === 190 || event.keyCode === 110) && value.indexOf('.') !== -1) {
                event.preventDefault();
            }
        });
        $('#obj' + data).bind('paste', function (e) {
			var clipboardData, pastedData;
		    // Get pasted data via clipboard API
		    clipboardData = e.originalEvent.clipboardData || window.clipboardData;
		    pastedData = clipboardData.getData('Text');
		    var regex = /^[0-9]+\.?[0-9]*$/;
			if(!pastedData.match(regex) || !/^\d+$/.test(pastedData)){
		        e.stopPropagation();
		        e.preventDefault();
		    }
	   	});
        $('#obj' + data).on("keyup", function (event) {
            var val = event.target.innerText;
            var oldVal = event.target.val;
            if(oldVal == val){
                return;
            }
            if(val == "" || validate(val)){
                var parentId = $(this).parent()[0].getAttribute('originalId');
                var valueId = $(this)[0].getAttribute('valueId');
                var value = $('#obj' + parentId + valueId).text();
                if(value != "" && isNaN(value)){
                    event.preventDefault();
                    return;
                }
                self.showDialogValue(parentId, valueId);
            }
            function validate(s) {
               var rgx = /\-?[0-9]*\.?[0-9]*$/;
               if(s.match(rgx) == s){
                    return true;
               }
               return false;
            }
        });
    }

    createTable(displayName, text, vId) {
        var self = this;
        var table = $('#myTable' + self.encodeId)
        if (!$('#objBody') || ($('#objBody') && $('#objBody').length === 0)) {
            var tableBody = document.createElement('TBODY');
            tableBody.setAttribute('id', "objBody")
            table.append(tableBody);
        }
        self.selectedValueLibList.push(vId)
        if (self.commonCodeContainer) {
            var codeId = self.commonCodeContainer;
            var json = {};
            var header = table.find('th'),
                keyContent = self.fc;
            var randomId = "average_" + window.utils.htmlEscape(window.guidGenerator())
            CodeContainer.getBlobInstanceWithId(codeId, DataManager.getDataManager().get('currentPlan'), function(codeContainer) {
                if (codeContainer) {
                    if (codeContainer.get('code')) {
                        json = JSON.parse(codeContainer.get('code'));
                    }
                    var tr = self.createRow(randomId, displayName, text);
                    for (var i = 2; i < header.length; i++) {
                        self.objectiveColumnCreate(randomId + header[i].id, tr, header[i].id);
                        if (json[header[i].id]) {                            
                            json = self.addObjectiveToCodeContainer(json, header[i].id, keyContent, randomId, text, null, null, vId, null);
                        }
                    }
                    codeContainer.set('code', JSON.stringify(json));
                }
            })
        }
        self.createDatatable();
    }

    getValueElement(callback) {
        var self = this;
        self.createCommonObservation(function(containerId) {
            CodeContainer.getBlobInstanceWithId(self.commonCodeContainer, DataManager.getDataManager().get('currentPlan'), function(codeCon) {
                if ((!codeCon) || (codeCon && !codeCon.get('code')) || (codeCon && codeCon.get('code') === "{}")) {
                    //var currentPlan = DataManager.getDataManager().get('currentPlan');
                    var id = window.utils.htmlEscape(window.guidGenerator());
                    self.loadValueLibNames(function(valueLibraries) {
                    //currentPlan.loadValueLibraries(function(valueLibraries) {
                        if (valueLibraries) {
                            var options = {
                                valueLibs: valueLibraries,
                                callback: function(values, phases, capDesc, capParentId) {
                                    var self = window.vdmModelView.strategyMapObjectiveViewModel;
                                    if (phases) {
                                        for (var p = 0; p < phases.length; p++) {
                                            var name = phases[p].name;
                                            var phaseId = phases[p].id;
                                            var mapId = phases[p].mapId;
                                            if(self.selectedPhases){
                                                self.selectedPhases.push({
                                                    id: phaseId,
                                                    name: name,
                                                    mapId: mapId
                                                })
                                            }
                                            self.addHeaderColumn(window.utils.htmlEscape(phaseId), name);
                                            var json = {};
                                            CodeContainer.getBlobInstanceWithId(self.commonCodeContainer, DataManager.getDataManager().get('currentPlan'), function(codeContainer) {
                                                if (codeContainer) {
                                                    if (codeContainer.get('code')) {
                                                        json = JSON.parse(codeContainer.get('code'));
                                                        json[phaseId] = {};
                                                        //json[phaseId].period = name;
                                                        json[phaseId].phaseMapId = mapId ? mapId : "";
                                                        json[phaseId].phaseMapName = name;
                                                    } else {
                                                        json[phaseId] = {};
                                                        //json[phaseId].period = name;
                                                        json[phaseId].phaseMapId = mapId ? mapId : "";
                                                        json[phaseId].phaseMapName = name;
                                                    }
                                                    codeContainer.set('code', JSON.stringify(json));
                                                }
                                            })
                                        }
                                    }
                                    if (values) {
                                        for (var val = 0; val < values.length; val++) {
                                            var valDefModal = self.getValueLibTypeModal(values[val]);
                                            if(valDefModal){
                                                var text = valDefModal.get('name');
                                                var unitName = valDefModal.get('unit');
                                                var displayObjName = text.concat(unitName ? " (" + unitName + ")" : "");
                                                var vId = values[val];
                                                self.createTable(displayObjName, text, vId)
                                            }
                                        }
                                    }

                                }
                            };
                            window.getAndCreateModalDialog(self.model, id, ValueDeliveryModelMixin, self.model, "ValueExplorerJson", null, options);
                        }
                        callback();
                    });
                }else {
                    if(!self.valLibLoaded){
                        self.loadValueLibNames(function(){
                            callback();
                        });
                    }else {
                        callback();
                    }
                }
            })
        });
    }

    loadValueLibNames(callback){
        var self = this;
        var currentPlan = DataManager.getDataManager().get('currentPlan');
        currentPlan.loadValueLibraries(function(valueLibraries) {
            if (valueLibraries) {
                for(var i =0;i<valueLibraries.length;i++){
                    var valLibModels= valueLibraries[i].models;
                    for(var j =0;j<valLibModels.length;j++){
                        self.valLibNames[valLibModels[j].get('id')] = valLibModels[j].get('name');
                    }
                }
            }
            self.valLibLoaded = true;
            callback(valueLibraries);
        });
    }

    addHeaderColumn(phaseId, name) {
        var self = this;
        var th = document.createElement("th");
        th.setAttribute('id', phaseId);

        var span = document.createElement("span");
        span.setAttribute('id', 'text' + phaseId);
        span.setAttribute('valueId', phaseId)
        var edit = document.createElement("i");
        edit.className = "glyphicon glyphicon-pencil";
        edit.style = "cursor:pointer;float:right";
        edit.setAttribute('id', "edit" + phaseId);
        edit.setAttribute('valueId', phaseId);
        $(edit).click(function() {
            var id = $(this).attr('valueId');
            var currentPlan = DataManager.getDataManager().get('currentPlan');
            var phaseModels = currentPlan.get('phase').models;
            var json = {};
            var codeConId = self.commonCodeContainer ? self.commonCodeContainer : "";
            CodeContainer.getBlobInstanceWithId(codeConId, DataManager.getDataManager().get('currentPlan'), function(codeContainer) {
                if (codeContainer) {
                    if (codeContainer.get('code')) {
                        json = JSON.parse(codeContainer.get('code'))
                        var htmlContent = "<div class = row><div class=col-xs-1></div>";
                        htmlContent += "<div class =col-xs-3><span class= control-label>";
                        htmlContent += "Name</span></div>";
                        htmlContent += "<div class=col-xs-5><span><input type = 'text'  id= input" + id + " class ='form-control focus-ele' style=float:left></span>";
                        htmlContent += "</div></div><br>";
                        htmlContent += "<div class = row><div class=col-xs-1></div>";
                        htmlContent += "<div class =col-xs-3><span class= control-label>";
                        htmlContent += "Phase Mapping</span></div>";
                        htmlContent += "<div class=col-xs-5><select id = selectPhaseId style=border-radius:5px;font-size:14px;height:25px><option>Select Phase ...</option>";
                        var phaseList = [];
                        var currentPhase;
                        for (var phaseId in json) {
                            if (phaseId === id) {
                                currentPhase = json[phaseId].phaseMapId;
                                continue;
                            }
                            if (json[phaseId] && json[phaseId].phaseMapId) {
                                phaseList.push(json[phaseId].phaseMapId);
                            }
                        }
                        for (var i = 0; i < phaseModels.length; i++) {
                            if (phaseList.indexOf(phaseModels[i].id) != -1) {
                                continue;
                            }
                            if (phaseModels[i].id === currentPhase) {
                                htmlContent += "<option id=" + phaseModels[i].id + " valueId=" + id + " selected>" + phaseModels[i].get('name') + "</option>"
                            }
                            else {
                                htmlContent += "<option id=" + phaseModels[i].id + " valueId=" + id + ">" + phaseModels[i].get('name') + "</option>"
                            }
                        }
                        htmlContent += "</div></div><br>";
                        var dialog = bootbox.dialog({
                            title: "<div>&nbsp;&nbsp;&nbsp;Phase Details</div>",
                            message: htmlContent,
                            show: true,
                            buttons: {
                                main: {
                                    label: "Close",
                                    className: "btn btn-default",
                                },
                                success: {
                                    label: '<i class="fa fa-check"></i> Complete',
                                    className: "btn btn-complete showComplete",
                                    callback: function() {
                                        var phName = $('#input' + id).val().trim();
                                        /*if ($('#text' + id).text().trim() != phName) {
                                            if (json) {
                                                for (var phId in json) {
                                                    if (phId === id) {
                                                        json[phId].phaseMapName = phName;
                                                        break;
                                                    }
                                                }
                                                codeContainer.set('code', JSON.stringify(json))
                                            }
                                        }*/
                                        $('#text' + id).text(phName);
                                        
                                        //if ((phaseExists && !valueId) || (!phaseExists && valueId)) {
                                            //var valueId = $('select#selectPhaseId option:selected').attr('valueId');
                                            var mapId = $('select#selectPhaseId option:selected').attr('id');
                                            //var mapName = $('select#selectPhaseId option:selected').val();
                                            if (json) {
                                                for (var phaseId in json) {
                                                    if (phaseId === id) {
                                                        json[phaseId].phaseMapId = mapId;
                                                        json[phaseId].phaseMapName = phName;
                                                        var isExist = false;
                                                        for (var i = 0; i < self.selectedPhases.length; i++) {
                                                            if (self.selectedPhases[i].id === phaseId) {
                                                                self.selectedPhases[i].mapId = mapId;
                                                                isExist = true;
                                                                break;
                                                            }
                                                        }
                                                        if (!isExist) {
                                                            self.selectedPhases.push({
                                                                id: phaseId,
                                                                name: mapName,
                                                                mapId: mapId
                                                            })
                                                        }
                                                        break;
                                                    }
                                                }
                                                codeContainer.set('code', JSON.stringify(json))
                                            }
                                        //}
                                    }
                                },
                                delete:{
                                    label : "Delete",
                                    className:"btn btn-danger glyphicon glyphicon-trash pull-left",
                                    callback: function(){
                                        self.handlePhaseDeletion(phaseId);
                                    }
                                }
                            }
                        })
                        $('#input' + id).val($('#text' + id).text());
                        $('#input' + id).keyup(function(eve) {
                            var phName = eve.currentTarget.value;
                            if (phName && phName.trim() != "") {
                                $('.showComplete').removeClass('disabled');
                            } else {
                                $('.showComplete').addClass('disabled');
                            }
                        })
                        /*for (var key in json) {
                            var phaseId = key;
                            if (json[phaseId] && json[phaseId].phaseMapId) {
                                var phase = Backbone.Relational.store.getObjectByName('transformation.Phase').find({
                                    id: json[phaseId].phaseMapId
                                });
                                if (phase) {
                                    for (var i = 0; i < self.selectedPhases.length; i++) {
                                        if (self.selectedPhases[i].mapId === json[phaseId].phaseMapId && key === id) {
                                            $('#selectPhaseId').hide();
                                            $('#showPhaseSelect').text(phase.get('name'));
                                            $('#input' + id).val(json[phaseId].phaseMapName)
                                            break;
                                        }
                                    }
                                } else {
                                    json[phaseId].phaseMapId = null;
                                    json[phaseId].phaseMapName = null
                                }
                            }
                        }*/
                    }
                }
            })
        });
        var del = document.createElement("i");
        del.className = "glyphicon glyphicon-minus";
        del.style = "cursor:pointer;";
        del.setAttribute('id', "delete" + phaseId);
        del.setAttribute('valueId', phaseId)
        $(del).click(function() {
            var id = $(this).attr('valueId');
            self.handlePhaseDeletion(id);
        })
        th.appendChild(span);
        th.appendChild(edit);
        th.appendChild(del);
        $('#myTable' + self.encodeId).find('thead').find('tr').append(th);
        $('#text' + phaseId).text(name);
    }
    handlePhaseDeletion(phaseId) {
        var self = this;
        var message = DataManager.getDataManager().get('localeManager').get('ConfirmMessage');
        bootbox.confirm(message, function(result) {
            if (result) {
                $('#' + phaseId).remove();
                self.deleteFromJSON(phaseId);
            }
        });
    };
    deleteFromJSON(id) {
        var self = this;
        var codeConId = self.commonCodeContainer ? self.commonCodeContainer : "";
        CodeContainer.getBlobInstanceWithId(codeConId, DataManager.getDataManager().get('currentPlan'), function(codeContainer) {
            if (codeContainer) {
                if (codeContainer.get('code')) {
                    var json = JSON.parse(codeContainer.get('code'));
                    if (json[id]) {
                        for (var key in json[id][self.fc]) {
                            $('#obj' + key + id).remove();
                        }
                        for (var i = self.selectedPhases.length - 1; i >= 0; i--) {
                            if (self.selectedPhases[i].id === id) {
                                self.selectedPhases.splice(i, 1)
                                break;
                            }
                        }
                        delete json[id]
                    }
                    codeContainer.set('code', JSON.stringify(json));
                }
            }
        })
    }

    createCommonObservation(callback) {
        var self = this;
        if (!self.commonCodeContainer) {
            var plan = DataManager.getDataManager().get('currentPlan');
            var planId = plan.id.substr(plan.id.lastIndexOf('@'));
            var commonAltId = planId + "-Common@";
            var previousWorkspace = DataManager.getDataManager().getWorkspace();
            DataManager.getDataManager().getWorkspaceDataWithId(commonAltId, function(wsData) {
                var commonWorkspace = wsData.get('workspace');
                DataManager.getDataManager().setWorkspace(commonWorkspace, function() {
                    plan.getCommonPackage(function(commonPackage) {
                        var timeStamp = Date.parse(new Date());
                        var metricsModel = commonPackage.get('metricsModel')
                        var newObservation;
                        if (metricsModel) {
                            self.checkObservation(function(actualObs) {
                                if (!actualObs) {
                                    var snapshotId = window.uuidGenerator();
                                    newObservation = metricsModel.models[0].createObservation("Actual", timeStamp, snapshotId, null);
                                    newObservation.set("planId", plan.id);
                                    var commonScenario = commonPackage.get('scenario').at(0);
                                    commonScenario.get('contextObservation').add(newObservation);
                                    var codeContainer = CodeContainer.getBlobInstance("Actual", "Actual", DataManager.getDataManager().get('currentPlan'));
                                    newObservation.set('codeContainerId', codeContainer.id);
                                    self.commonCodeContainer = codeContainer.id;
                                    DataManager.getDataManager().setWorkspace(previousWorkspace, function() {
                                        callback(self.commonCodeContainer);
                                    });
                                }else {
                                    self.commonCodeContainer = actualObs.get('codeContainerId');
                                    CodeContainer.getBlobInstanceWithId(self.commonCodeContainer, DataManager.getDataManager().get('currentPlan'), function(codeCon) {
                                        if(!codeCon){
                                            codeCon = CodeContainer.getBlobInstance("Actual", "Actual", DataManager.getDataManager().get('currentPlan'));
                                            actualObs.set('codeContainerId', codeCon.id);
                                            self.commonCodeContainer = codeCon.id;
                                        }
                                        DataManager.getDataManager().setWorkspace(previousWorkspace, function() {
                                            callback(self.commonCodeContainer);
                                        });
                                    });
                                }
                            })
                        }
                    });
                });
            });
        } else {
            callback(self.commonCodeContainer)
        }
    }

    /*getCommonCodeContainer(callback) {
        var plan = DataManager.getDataManager().get('currentPlan');
        plan.getCommonScenario(function(commonScenario) {
            var actualObs = commonScenario.get('contextObservation') ? commonScenario.get('contextObservation').findWhere({
                'name': "Actual"
            }) : "";
            if (actualObs) {
                var codeContainerId = actualObs.get('codeContainerId');
                self.commonCodeContainer = codeContainerId;
                callback(codeContainerId);
            } else {
                callback(null)
            }
        });
    }*/

    checkObservation(callback) {
        var plan = DataManager.getDataManager().get('currentPlan');
        plan.getCommonScenario(function(commonScenario) {
            var actualObs = commonScenario.get('contextObservation').findWhere({'name': "Actual"});
            callback(actualObs);
        });
    }
    afterRenderView() {  }
    static getInstance(model, options) {
        var view = new StrategyMapObjectiveViewModel(model, options);
        return view;
    };
}
path.StrategyMapObjectiveViewModel = StrategyMapObjectiveViewModel;