import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BMElement} from './BMElement'
import {BusinessModelStateMixin} from './BusinessModelStateMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BMElement","appbo/vdml/BusinessModelStateMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BMElement,BusinessModelStateMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var BusinessModelState = BMElement.extend(utils.customExtends({
		relations:BusinessModelStateMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BMElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new BusinessModelStateMixin()
	));
	
	BusinessModelState.abstract = false;
	BusinessModelState.supported = false;
	path.BusinessModelState = BusinessModelState;
	export {BusinessModelState};
//});