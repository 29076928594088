
//import * as require from 'require'
import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { EObjectMixin } from './EObjectMixin';

var path = DataManager.getDataManager().buildAppNsPath("cmof",global.version);
export class EObject2Mixin{
    static getSubModelTypes(){
        var ret = EObjectMixin.getSubModelTypes();
        delete ret.smm_SmmElement;
		return _.extend(ret,{
			transformation_ScenarioPathStep: 'transformation.ScenarioPathStep',
			transformation_WhatIfValue: 'transformation.WhatIfValue',
			transformation_ScenarioValueConstraints: 'transformation.ScenarioValueConstraints'
		});
	}
}
utils.customExtendClass (EObject2Mixin,new EObjectMixin());
path.EObject2Mixin = EObject2Mixin;