import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Port} from './Port'
import {PortMixin} from './PortMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { DeliverableFlow } from './DeliverableFlow'
import { ResourceUse } from './ResourceUse'
import { InputDelegation } from './InputDelegation'
import { Assignment } from './Assignment'
import { BusinessItemDefinition } from './BusinessItemDefinition'
//import { Expression } from './Expression'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Port","appbo/vdml/PortMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Port,PortMixin
, DeliverableFlow
, ResourceUse
, InputDelegation

, Assignment
, BusinessItemDefinition
, Expression){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!DeliverableFlow){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.DeliverableFlow){
			DeliverableFlow = importPath.DeliverableFlow;
		}
        else {
            import('./DeliverableFlow').then(({ default: DeliverableFlow }) => {
                DeliverableFlow = DeliverableFlow;
            });
            /*require(["appbo/vdml/DeliverableFlow"], function (loadedModule) {
                DeliverableFlow = loadedModule;
			});*/
		}
	}
	if(!ResourceUse){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ResourceUse){
			ResourceUse = importPath.ResourceUse;
		}
        else {
            import('../vdml/ResourceUse').then(({ default: ResourceUse }) => {
                ResourceUse = ResourceUse;
            });
            /*require(["appbo/vdml/ResourceUse"], function (loadedModule) {
                ResourceUse = loadedModule;
			});*/
		}
	}
	if(!InputDelegation){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.InputDelegation){
			InputDelegation = importPath.InputDelegation;
		}
        else {
            import('../vdml/InputDelegation').then(({ default: InputDelegation }) => {
                InputDelegation = InputDelegation;
            });
            /*require(["appbo/vdml/InputDelegation"], function (loadedModule) {
                InputDelegation = loadedModule;
			});*/
		}
	}
	
	if(!Assignment){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Assignment){
			Assignment = importPath.Assignment;
		}
        else {
            import('./Assignment').then(({ default: MeasureLibrary }) => {
                MeasureLibrary = MeasureLibrary;
            });
            /*require(["appbo/vdml/Assignment"], function (loadedModule) {
                Assignment = loadedModule;
			});*/
		}
	}
	if(!BusinessItemDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemDefinition){
			BusinessItemDefinition = importPath.BusinessItemDefinition;
		}
        else {
            import('../vdml/BusinessItemDefinition').then(({ default: BusinessItemDefinition }) => {
                BusinessItemDefinition = BusinessItemDefinition;
            });
            /*require(["appbo/vdml/BusinessItemDefinition"], function (loadedModule) {
                BusinessItemDefinition = loadedModule;
			});*/
		}
	}
	/*if(!Expression){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Expression){
			Expression = importPath.Expression;
		}
        else {
            import('../vdml/Expression').then(({ default: Expression }) => {
                Expression = Expression;
            });
		}
	}*/
	export class InputPortMixin {

	defaults(){
		var ret = {
			type: "vdml_InputPort"
		}
		return jQuery.extend(Port.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_InputPort",
			key:"resourceUse",
			relatedModel:"vdml.ResourceUse",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_InputPort",
			key:"delegatedInput",
			relatedModel:"vdml.InputDelegation",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"target",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_InputPort",
			key:"inputDelegation",
			relatedModel:"vdml.InputDelegation",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"source",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_InputPort",
			key:"assignment",
			relatedModel:"vdml.Assignment",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_InputPort",
			key:"inputDefinition",
			relatedModel:"vdml.BusinessItemDefinition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}/*,
		{
			type :Backbone.HasOne,
			containingClass:"vdml_InputPort",
			key:"correlationExpression",
			relatedModel:"vdml.Expression",
			reverseRelation: {
				key:"correlationExpressionOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}*/
		])
	}
	static getCumulativeMixinRelations(){
		if (!InputPortMixin.cummulativeRelations) {
            InputPortMixin.cummulativeRelations = _.union(InputPortMixin.getMixinRelations()
                , PortMixin.getCumulativeMixinRelations()
            );
        }
		return InputPortMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_Port"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "isIntermediate",type : "EBoolean",defaultValue : "null",containingClass : "vdml_Port" }
		]
	}
	getParent(){
		var container;
		return Port.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return Port.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/InputPortPropertiesTemplate.html",
			templateName : "InputPortPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/InputPortViewModel",
			tabId : "InputPortView",
			tabName: "InputPort"
		}
	}
//#startCustomMethods	
	copy(targetContainer,sourceCollaboration,targetCollaboration,measurementsCopied,valuesCopied){
		var portId = DataManager.getDataManager().guidGeneratorByOwner(targetContainer);
		var nameStr = targetContainer.getUniqueProperty('name',this.get('name'),'containedPort');
		var copyPort = new this.constructor({id:portId,name:nameStr,description:this.get('name'),containedPortOwner:targetContainer});
		var mesChars = this.get('measuredCharacteristic');
		mesChars.each(function(mesChar){
			mesChar.copy(copyPort,measurementsCopied);			
		});	
		var planingPercentage = this.get('planningPercentage');
		if(planingPercentage){
			planingPercentage.copy(copyPort,measurementsCopied);	
		}
		var offset = this.get('offset');
		if(offset){
			offset.copy(copyPort,measurementsCopied);	
		}		
		var batchSize = this.get('batchSize');
		if(batchSize){
			batchSize.copy(copyPort,measurementsCopied);	
		}	
		var handler = this.get('handler');
		if(handler){
			var targetHandleRole = targetCollaboration.get('collaborationRole').findWhere({name:handler.get('name')});
			if(targetHandleRole){
				copyPort.set('handler',targetHandleRole);
			}
		}
		var condition = this.get('condition');
		if(condition){
			condition.copy(copyPort);
		}
		var exp = this.get('correlationExpression');
		if(exp){
			exp.copy(copyPort);
		}
		copyPort.set('inputDefinition',this.get('inputDefinition'));
		var container = this.get('containedPortOwner');
		if(container.get('type') === 'vdml_Activity' && (sourceCollaboration === targetCollaboration)){
			var delegatedInputs = this.get('delegatedInput');
			delegatedInputs.each(function(delegation){
				delegation.copy(delegation.get('source'),copyPort,targetCollaboration);
			});
		}
		if((container.get('type') === 'vdml_Activity' || container.get('type') === 'vdml_Store') && 
			(sourceCollaboration === targetCollaboration)){
			var inputFlow = this.get('input');
			if(inputFlow){
				var op = inputFlow.get('provider');
				var sourceContainer = op.get('containedPortOwner');
				var opCopy = op.copy(sourceContainer,sourceCollaboration,targetCollaboration,measurementsCopied,valuesCopied);
				if(sourceContainer.get('type') === 'vdml_Activity'){
					var activityDels = sourceContainer.get('delegationContext');
					activityDels.each(function(actDel){
						sourceContainer.createPortDelegationAndTargetCollaborationPort(opCopy,actDel.get('contextCollaboration'),actDel);	
					});
				}
				inputFlow.copy(opCopy,copyPort,sourceCollaboration, targetCollaboration,measurementsCopied);
			}
		}
		if(sourceCollaboration === targetCollaboration){
			var ownedAssignments = sourceCollaboration.get('ownedAssignment');
			ownedAssignments.each(function(assignment){
				var roleResource = assignment.get('roleResource');
				if(roleResource === this){
					copyPort.get('assignment').add(assignment);
					assignment.get('roleResource').add(copyPort);
				}
			});
		}
		var contAssignments = this.get('assignment');
		contAssignments.each(function(assignment){
			var contextOwner= assignment.get('contextBasedAssignmentOwner');
			if(contextOwner){
				copyPort.get('assignment').add(assignment);
				assignment.get('roleResource').add(copyPort);
			}
		});
		return copyPort;
	};
	
	handleInputPortNameChange(modelRef, newName){
		var self = this;
		var inputDelegations = self.get('inputDelegation');
		inputDelegations.each(function(delegation){
			delegation.set('name',newName + 'Delegation');
			delegation.set('description',newName + 'Delegation');	
			var delTarget = delegation.get('target');
			if(delTarget) {
				delTarget.set('name',newName);
				delTarget.set('description',newName);
			}
		});
	};
//#endCustomMethods	
	
	}
	path.InputPortMixin = InputPortMixin;
	//return InputPortMixin;
//});