import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {PortContainer} from './PortContainer'
import {StoreMixin} from './StoreMixin'

/*import {OrgUnit} from './OrgUnit'
import {CapabilityOffer} from './CapabilityOffer'
import {MeasuredCharacteristic} from './MeasuredCharacteristic'
import {AnalysisContext} from './AnalysisContext'
import {BusinessItem} from './BusinessItem'
import {ApplicationInterface} from './ApplicationInterface'
import {WebServiceReference} from './WebServiceReference'*/
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/PortContainer","appbo/vdml/StoreMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,PortContainer,StoreMixin
, OrgUnit
, CapabilityOffer
, MeasuredCharacteristic

, AnalysisContext
, BusinessItem
, ApplicationInterface
, WebServiceReference){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	
	var Store = PortContainer.extend(utils.customExtends({
		relations:StoreMixin.getMixinRelations(),
		subModelTypes: {
			vdml_Pool : 'vdml.Pool'
		},
		initialize: function(attributes, options) {
			PortContainer.prototype.initialize.apply(this, arguments);
		}
		}
		, new StoreMixin()
	));
	Store.abstract = false;
	path.Store = Store;
	export {Store};
//});