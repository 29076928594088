import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {PortContainer} from './PortContainer'
import {PortContainerMixin} from './PortContainerMixin'
import {ApplicationInterface} from './ApplicationInterface'
/*import {OrgUnit} from './OrgUnit'
import {CapabilityOffer} from './CapabilityOffer'
import {MeasuredCharacteristic} from './MeasuredCharacteristic'
import {AnalysisContext} from './AnalysisContext'
import {BusinessItem} from './BusinessItem'

import {WebServiceReference} from './WebServiceReference'*/
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/PortContainer","appbo/vdml/PortContainerMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,PortContainer,PortContainerMixin
, OrgUnit
, CapabilityOffer
, MeasuredCharacteristic

, AnalysisContext
, BusinessItem
, ApplicationInterface
, WebServiceReference){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	
	export class StoreMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.StoreMixin = StoreMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_Store"
		}
		return jQuery.extend(PortContainer.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Store",
			key:"supportedCapability",
			relatedModel:"vdml.CapabilityOffer",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_Store",
			key:"duration",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"durationOwnerStore",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		/*{
			type :Backbone.HasOne,
			containingClass:"vdml_Store",
			key:"inventoryLevel",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"inventoryLevelOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},*/
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Store",
			key:"storeContext",
			relatedModel:"vdml.AnalysisContext",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_Store",
			key:"applicationInterface",
			relatedModel:"vdml.ApplicationInterface",
			reverseRelation: {
				key:"applicationInterfaceOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_Store",
			key:"webserviceReference",
			relatedModel:"vdml.WebServiceReference",
			reverseRelation: {
				key:"webserviceReferenceOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!StoreMixin.cummulativeRelations) {
            StoreMixin.cummulativeRelations = _.union(StoreMixin.getMixinRelations()
                , PortContainerMixin.getCumulativeMixinRelations()
            );
        }
		return StoreMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_PortContainer"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("storeOwner") ? this.get("storeOwner") : this.previousAttributes().storeOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("storeOwner") ? this.get("storeOwner") : this.previousAttributes().storeOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	};
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/StorePropertiesTemplate.html",
			templateName : "StorePropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/StoreViewModel",
			tabId : "StoreView",
			tabName: "Store"
		};
	};
	
	copy(targetOU,measurementsCopied,valuesCopied){
		var sourceOU = this.get('storeOwner');
		var storeId = DataManager.getDataManager().guidGeneratorByOwner(targetOU);
		var storeCopy = new this.constructor({id:storeId,storeOwner:targetOU,name:this.get('name'),description:this.get('description')});
		var resource = this.get('resource');
		if(resource.get('businessItemOwner') === sourceOU){
			var targetResource = targetOU.get('businessItem').findWhere({name:resource.get('name')});
			storeCopy.set('resource',targetResource);
		}else{
			storeCopy.set('resource',resource);
		}
		var mesChars = this.get('measuredCharacteristic');
		mesChars.each(function(mesChar){
			mesChar.copy(storeCopy,measurementsCopied);			
		});	
		var duration = this.get('duration');
		if(duration){
			duration.copy(storeCopy,measurementsCopied);
		}
		var inventoryLevel = this.get('inventoryLevel');
		if(inventoryLevel){
			inventoryLevel.copy(storeCopy,measurementsCopied);
		}
		if(this.get('type') === 'vdml_Pool'){
			var poolSize = this.get('poolSize');
			if(poolSize){
				poolSize.copy(storeCopy);
			}
			//TODO copy calendar service
			var position = this.get('position');
			if(position.get('collaborationRoleOwner') === sourceOU){
				var targetManager = sourceOU.get('collaborationRole').findWhere({name:position.get('name')});
				storeCopy.set('position',targetManager);
			}else{
				storeCopy.set('position',position);
			}
		}
		this.get('containedPort').each(function(port){
			port.copy(storeCopy,sourceOU,targetOU,measurementsCopied,valuesCopied);
		});
		return storeCopy;
	}
	
	
	}
	path.StoreMixin = StoreMixin;
	//return StoreMixin;
//});