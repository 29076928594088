import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {CollaborationDiagram} from '../vdml/CollaborationDiagram'
import {BusinessIndicatorLibraryDiagramMixin} from './BusinessIndicatorLibraryDiagramMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/CollaborationDiagram","appbo/smm/BusinessIndicatorLibraryDiagramMixin"],
//   function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, CollaborationDiagram,BusinessIndicatorLibraryDiagramMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
    var BusinessIndicatorLibraryDiagram = CollaborationDiagram.extend(utils.customExtends({
        relations:BusinessIndicatorLibraryDiagramMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			CollaborationDiagram.prototype.initialize.apply(this, arguments);
		}
		}
            , new BusinessIndicatorLibraryDiagramMixin()
	));
	function getInstance(name,description,owner){
		var cid = DataManager.getDataManager().guidGeneratorByOwner(owner);
        var MeasurementLiDiagram = new BusinessIndicatorLibraryDiagram({id:cid,name:owner.getUniqueProperty('name',name,'diagrams'),description:owner.getUniqueProperty('description',description,'diagrams'),version: owner.get('version'), diagramOwner:owner});
		owner.get('diagrams').add(MeasurementLiDiagram);
		return MeasurementLiDiagram;
	};
	/*BusinessIndicatorLibraryDiagram['remove:businessIndicatorLibraryElements'] = function (model, coll, options) {
        this.handleRemoveBusinessIndicatorElementLibraries(model, coll, options);
	}*/
	function getSystemTags(){
        return ['VDM','BusinessIndicator Library Diagram','Admin'];
	};
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	BusinessIndicatorLibraryDiagram.abstract = false;
	BusinessIndicatorLibraryDiagram.supported = !sp2Enabled;
        path.BusinessIndicatorLibraryDiagram = BusinessIndicatorLibraryDiagram;
        export {BusinessIndicatorLibraryDiagram};
//});