import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {PackageReference} from '../../../../../com/vbee/filesystem/PackageReference'
import { EcoMapDiagram } from '../../../../bo/vdml/EcoMapDiagram'
//import {PackageReference as PlanPackageReference} from '../../../../../version1/bo/transformation/PackageReference'

/*define(["require", "jquery", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "appbo/beeppackage/BeepPackageMixin", "appbo/transformation/PackageReference", "appcommon/com/vbee/filesystem/PackageReference","appbo/vdml/EcoMapDiagram","appbo/ecomap/EcoMap","app/global"],
	function (require, $, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, BeepPackageMixin,PlanPackageReference,PackageReference,EcoMapDiagram,EcoMap,global)
{*/
	var path = DataManager.getDataManager().buildAppNsPath("ecoMap.views.designer",global.version);
	export class EcoMapDetailsViewModel {
		constructor(model,options){
			var self = this;
			this.init(model, options);
		}
		init(model, options) {
			var self = this;
			this.EcoMapDetailsViewModel = this;
			this.model = model.model;
			this.id = kb.observable(self.model, 'id');
			function htmlEscape(str) {
				return String(str)
				.replace(/@/g, '')
				.replace(/ /g, '');
			}
			this.encodeId = htmlEscape(this.id());
			this.enableDelete = ko.observable(true);
			this.enableComplete = ko.observable(true);
			this.name = ko.observable(self.model ? self.model.get('name') : null);
			this.description = ko.observable(self.model ? self.model.get('description') : "");
			var fsize = self.model ? self.model.get('fontsize') : 12;
			this.fontsize = ko.observable(fsize ? fsize : 12);
			this.currentAlt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
			/*this.importDiagramList = ko.observableArray();
			this.importDiagram = ko.observable();
			this.alternativeModel = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));*/
			this.name.subscribe(function(val){
				self.description(val);
			});
			if (DataManager.getDataManager().get('viewAlternative') !== DataManager.getDataManager().get('currentWorkspace').get('id')) {
				$('#copy' + self.encodeId).hide();
			}
			if(window.checkContextForRevision()) {
				self.enableDelete(false);
			}
		}

		dispose(){
			var self = this;
			window.cleanViewModel(self);		
		}; 
		
		
		
		/*if(window.checkContextForRevision()) {
			self.enableDelete(false);
		}*/
		
	    saveEcoMapDetails(){
			var self = this;
	    	if(self.name() !== ""){
	    		if(self.name().trim() != self.model.get('name')){
	    			self.model.set('name',self.name().trim());
	    			window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'),DataManager.getDataManager().get('viewAlternative'),self.EcoMapDetailsViewModel);
	    		}
				self.model.set('description',self.description().trim());	
				var defaultFontSize = self.model.get('fontsize') ? self.model.get('fontsize') : 12;
				if(self.fontsize() && defaultFontSize != self.fontsize()){
					self.model.set('fontsize',self.fontsize());
					window.utils.startSpinner('fontsizespinner', "Applying Font Size ...");
					self.model.getNestedParent().refreshLabels(window.vdmModelView.modeler,self.fontsize(),function(){
						window.utils.stopSpinner('fontsizespinner');
					});
				}
			}		

		};

        
		copyEcoMap(){
			var self = this;
			if (!DataManager.getDataManager().isDiscoverPurchased()) {
				window.utils.limitExceeded();
				return false;
			}
			function savePackage(view, modalId, type, moduleTypes) {
		        window.utils.startSpinner('copyEcoMapSpinner', "Copying Business Ecosystem Map...");
			    setTimeout(function () {
			       	self.onAddEcoMap(view.name(), view.description(), view.selectedEcoMapPackage(), null, null, self.currentAlt, type, moduleTypes);
			        window.cleanDialogModel(modalId, view);
			        window.utils.stopSpinner('copyEcoMapSpinner');
		        },100)
	        }
		    var plan = DataManager.getDataManager().get('currentPlan');
			var mapTitle = DataManager.getDataManager().get('localeManager').get("BusinessEcosystemMap");
			var addOptions = { 'alternative': self.currentAlt, 'plusIconType':'ecoSystemArrow',onlyMap:mapTitle};
			//window.getAndCreateModalDialog(self.model, htmlEscape(self.id()), BusinessModelMixin, plan, "CreatePackage", savePackage, addOptions);
			var BeepPackageMixin = Backbone.Relational.store.getObjectByName("beeppackage.BeepPackageMixin");
			window.getAndCreateModalDialog(self.model, window.utils.htmlEscape(self.id()), BeepPackageMixin, plan, "Discover", savePackage, addOptions);
		};
		onAddEcoMap(name, description, pack, phase, alternative, currentAlternative, type, moduleTypes) {
		    var self = this;
		    if (pack !== null && pack.id) {
		        var altId = DataManager.getDataManager().getRepositoryId(pack.id);
		        DataManager.getDataManager().fetchDocumentFromPackage(pack.id, "appbo/ecomap/EcoMap", pack.version, pack.id, "appbo/ecomap/EcoMap", DataManager.getDataManager().getVDMStore(altId), {
		            success: function (model) {
		                if (altId !== currentAlternative.get('id')) {
		                	var ecoPackage = self.model.getNestedParent();
							var depPacks = ecoPackage.get('dependentPackage')?ecoPackage.get('dependentPackage').models.concat():[];
		                    self.createRevisionBasedOnContext(model,depPacks,function (newPackage) {
		                        var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
		                        var altId = DataManager.getDataManager().getRepositoryId(newPackage.id);
								var ecoMap = newPackage.get('diagrams').findWhere({ 'id': altId + bmId });
								var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
		                        var diagrams = newPackage.get('diagrams');
		                        for (var i = 0; i < diagrams.length; i++) {
		                            newPackage.fixDiagramOnRevision(diagrams.at(i), altId, oldAltId);
		                        }
		                        //window.utils.startSpinner('revisionSpinner', "Creating a copy...");
		                        //setTimeout(function(){
		
		                        //model.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), function (newPackage) {
		                        window.utils.stopSpinner('revisionSpinner');
		                        self.createEcoMap(name, description, newPackage, type, moduleTypes);
		                        //});
		                        //},10);
		                    });
		                } else {
		                    self.createEcoMap(name, description, model, type, moduleTypes);
		                }
		            },
		            error: function (error) {
		                console.log('Unable to load selected Package');
		            }
		        });
		    } else {
		        self.createEcoMap(name, description, null, currentAlternative, type, moduleTypes);
		    }
		};
    	
		createEcoMap(name, description, ecoMapPackage, currentAlternative, type) {
	        var self = this;			
	        if (!ecoMapPackage) {
	                var preWorkspace = DataManager.getDataManager().get('currentWorkspace');
					if(window.checkContextForRevision()){
					    var workspaceAlt = DataManager.getDataManager().getWorkspaceDataWithIdSync(self.currentAlt.get('id')).get('workspace');
					    DataManager.getDataManager().set('currentWorkspace', workspaceAlt);
					    DataManager.getDataManager().set('currentWorkSpaceKey', workspaceAlt.get('id'));
					}
					var EcoMap = Backbone.Relational.store.getObjectByName("ecomap.EcoMap");
					ecoMapPackage = EcoMap.createPackageWithName(name + " Package", description, currentAlternative);
					DataManager.getDataManager().get('currentWorkspace').save();
	                self.createEcoMapDiagram(ecoMapPackage, name, description, null);
					bootbox.alert("Copy of Eco Map Completed");
					DataManager.getDataManager().set('currentWorkspace', preWorkspace);
					DataManager.getDataManager().set('currentWorkSpaceKey', preWorkspace.get('id'));
	        } else {
	            self.createEcoMapDiagram(ecoMapPackage, name, description, null);
				bootbox.alert("Copy of Eco Map Completed");
	        }
	    };

	    createEcoMapDiagram(pack, name, description, valternative) {
	        var self = this;
	        var altId = DataManager.getDataManager().get('viewAlternative');
	        if (altId) {
	            DataManager.getDataManager().getAlternative(altId, function (alternative) {
	                var diagramInstance = EcoMapDiagram.getInstance(name, description, pack);
	                self.copyDataToEcomap(diagramInstance);
				});
	        }
	    }
	    copyDataToEcomap(diagramInstance){
			var self = this;
			var xml = self.model.get('data');
			var fz = self.model.get('fontsize');
			var oldId = "e"+ window.utils.htmlEscape(self.model.id);
			var newId = "e"+ window.utils.htmlEscape(diagramInstance.id);
			xml = self.model.getNestedParent().fixXMLOnRevision(xml,oldId,newId);
			diagramInstance.set('data',xml);
			diagramInstance.set('fontsize',fz);
	    	if(self.model.get('businessModel').length>0){
	    		_.each(self.model.get('businessModel').models,function(bm){
	    			diagramInstance.get('businessModel').push(bm);
	    		});	
	    	}
	    	if(self.model.get('participant').length>0){
	    		_.each(self.model.get('participant').models,function(part){
	    			diagramInstance.get('participant').push(part);
	    		});	
	    	}
	    	if(self.model.get('valueProposition').length>0){
	    		_.each(self.model.get('valueProposition').models,function(vp){
	    			diagramInstance.get('valueProposition').push(vp);
	    		});	
	    	}
	    }
		/*this.importEcoMapDiagrams = function(){
			var self = this;
			function loadEcoMapPackage(ecoMapPackage) {
                var referenceExists = self.alternativeModel.get('phaseDesignPart').findWhere({ beepReference: ecoMapPackage.get('id') });
                if (!referenceExists || ecoMapPackage.get('id').indexOf("Common") > -1) {
                    return;
                } else {
                	var pack = Backbone.Relational.store.getObjectByName('ecomap.EcoMap').find({id:ecoMapPackage.get('id')});
                	if(pack){
                		var diag = pack.get('diagrams');
                		for(var j=0;j<diag.length;j++){
                			if(diag.at(j) !== self.model){
                				self.importDiagramList.push({ id: diag.at(j).get('id'), name: diag.at(j).get('name')});
                			}
                		}
                	}
                }
            }
            DataManager.getDataManager().getAllDocumentsCollectionOfType(self.alternativeModel, 'ecomap_EcoMap', function(results){
            	self.importDiagramList.push({ id: '', name: 'Select Diagram'});
            	for (var i = 0; i < results.length; i++) {
                        var type = results.at(i).get('type');
                        if (type === "ecomap_EcoMap") {
                            loadEcoMapPackage(results.at(i));
                        }
           		}
            	
            }, true, null, null, 'vbc:cmof_EObject-name');

		};
		
		self.importEcoMapDiagrams();*/
		
		createRevisionBasedOnContext(pack,skipDependentPackages,callback){
			if(window.checkContextForRevision()) {
				window.utils.startSpinner('revisionSpinner', "Creating a copy...");
				function fetchBmModel(newVdmPackage){
						if(newVdmPackage) {
                            DataManager.getDataManager().releaseSaveLock();
		        			DataManager.getDataManager().set('isActive',false);
							window.utils.stopSpinner('revisionSpinner');
		        			callback(newVdmPackage);
							/*DataManager.getDataManager().saveData({
								success:function(){
									window.utils.stopSpinner('revisionSpinner');
		        					callback(newVdmPackage);
								}, 
								error:function(){
									window.utils.stopSpinner('revisionSpinner');
		        					callback(newVdmPackage);
								}, 
								persist:true});	*/
						}
					}
				window.setTimeout(function(){
                    DataManager.getDataManager().acquireSaveLock(function () {
                        pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel, skipDependentPackages);
                    });
	        	},100);	
        	}
        	else {
        		callback(pack);
        	}
		};
		
		deleteEcoMap(){
			var self = this;
			window.vdmModelView.enableComplete(false);
			if(window.checkContextForRevision()) {
				var ecoPackage = self.model.getNestedParent();
				var depPacks = ecoPackage.get('dependentPackage')?ecoPackage.get('dependentPackage').models.concat():[];
				/*if(ecoPackage.get('diagrams').length === 1) {
					var alternativeModel = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
					var phaseDesignPartRef = alternativeModel.get('phaseDesignPart').findWhere({'beepReference':ecoPackage.id});
					phaseDesignPartRef && phaseDesignPartRef.destroy();
					DataManager.getDataManager().get('router').navigate(DataManager.getDataManager().get('viewAlternative'), {trigger: true});
				}else {*/
					self.createRevisionBasedOnContext(ecoPackage,depPacks,function(newEcoPackage){
						var len = depPacks.length;
						while(len--){
							newEcoPackage.get('dependentPackage').remove(depPacks[len]);
						}
						var bmId = self.model.id.substr(self.model.id.lastIndexOf('@')+1,self.model.id.length);
			       		var altId = DataManager.getDataManager().getRepositoryId(newEcoPackage.id);			                	
			   			var ecoToBeDeleted = newEcoPackage.get('diagrams').findWhere({'id':altId+bmId});
			   			ecoToBeDeleted.destroy();
			   			DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});	
					});

				/*}*/
			}else {	
				if(self.model.get('data')){					
                    bootbox.confirm('' + DataManager.getDataManager().get('localeManager').get('MapDeleteAlert','Business Ecosystem Map'), function(result) {
					if(result) {
						self.model.destroy();
						DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});			        									    				    	
					}    
					});
				}else{					
					self.model.destroy();
					DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});
				}
			}
		};			
		
		checkUniqueValue(view,event){
			var self = this;
			var name = event.currentTarget.value.trim();
			if(name !== ""){
				if(self.model.get('name') === name){
					$('#duplicateName'+self.encodeId).text("");
					$("#complete").removeAttr('disabled');
					return;
				}
	    		var unique = window.isUniqueName(name,null,self.id(),self.model.getNestedParent().get('diagrams'));
	    		if(unique){
	    			$('#duplicateName'+self.encodeId).text("");
	    			$("#complete").removeAttr('disabled');
	    		}
	    		else{
                    $('#duplicateName' + self.encodeId).text("" + DataManager.getDataManager().get('localeManager').get('DuplicateAlert','Business Ecosystem Map'));
	    			$("#complete").attr('disabled','disabled');
	    		}
			}
			else{
				$("#complete").attr('disabled','disabled');
			}
        };   
		limiter(view,event) {
			var input = event.currentTarget;
			if (input.value < 10) input.value = 10;
			if (input.value > 50) input.value = 50;
		}    
		afterRenderView(){ 
		}
	
	
	static getInstance(model,options){
		var view = new EcoMapDetailsViewModel(model, options);
		return view;
	};
}
path.EcoMapDetailsViewModel = EcoMapDetailsViewModel;