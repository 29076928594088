import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AbstractMeasureElement} from './AbstractMeasureElement'
import {AbstractMeasureElementMixin} from './AbstractMeasureElementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { Measure } from './Measure'
import { RoleDefinition } from '../vdml/RoleDefinition'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/AbstractMeasureElement","appbo/smm/AbstractMeasureElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AbstractMeasureElement,AbstractMeasureElementMixin
, Measure
, RoleDefinition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Measure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measure){
			Measure = importPath.Measure;
		}
        else {
            import('./Measure').then(({ default: Measure }) => {
                Measure = Measure;
            });

			/*require(["appbo/smm/Measure"],function(loadedModule){
				Measure = loadedModule;
			});*/
		}
	}
	if(!RoleDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.RoleDefinition){
			RoleDefinition = importPath.RoleDefinition;
		}
        else {
            import('../vdml/RoleDefinition').then(({ default: RoleDefinition }) => {
                RoleDefinition = RoleDefinition;
            });

			/*require(["appbo/vdml/RoleDefinition"],function(loadedModule){
				RoleDefinition = loadedModule;
			});*/
		}
	}
	export class CharacteristicMixin {
        /*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            path.CharacteristicMixin = CharacteristicMixin;
        }*/
	defaults(){
		var ret = {
			type: "smm_Characteristic"
		}
		return jQuery.extend(AbstractMeasureElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"smm_Characteristic",
			key:"measure",
			relatedModel:"smm.Measure",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"trait",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"smm_Characteristic",
			key:"parent",
			relatedModel:"smm.Characteristic",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_Characteristic",
			key:"roleDefinition",
			relatedModel:"vdml.RoleDefinition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!CharacteristicMixin.cummulativeRelations) {
            CharacteristicMixin.cummulativeRelations = _.union(CharacteristicMixin.getMixinRelations()
                , AbstractMeasureElementMixin.getCumulativeMixinRelations()
            );
        }
		return CharacteristicMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_AbstractMeasureElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" }
		]
	}
	getParent(){
		var container;
		return AbstractMeasureElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return AbstractMeasureElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/CharacteristicPropertiesTemplate.html",
			templateName : "CharacteristicPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/CharacteristicViewModel",
			tabId : "CharacteristicView",
			tabName: "Characteristic"
		}
	}
	
	}
	path.CharacteristicMixin = CharacteristicMixin;
	//return CharacteristicMixin;
//});