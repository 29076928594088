import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {CollaborationDiagram} from './CollaborationDiagram'
import {CapabilityLibraryDiagramMixin} from './CapabilityLibraryDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/CapabilityLibraryDiagramMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,CapabilityLibraryDiagramMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var CapabilityLibraryDiagram = CollaborationDiagram.extend(utils.customExtends({
		relations:CapabilityLibraryDiagramMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			CollaborationDiagram.prototype.initialize.apply(this, arguments);
		}
		}
		, new CapabilityLibraryDiagramMixin()
	));
	CapabilityLibraryDiagram.getInstance = function(name,description,owner, id){
		var cid = id ? id : DataManager.getDataManager().guidGeneratorByOwner(owner);
		var CapabilityLiDiagram = new CapabilityLibraryDiagram({id:cid,name:owner.getUniqueProperty('name',name,'diagrams'),description:owner.getUniqueProperty('description',description,'diagrams'),version: owner.get('version'), diagramOwner:owner});
		owner.get('diagrams').add(CapabilityLiDiagram);
		//CapabilityLiDiagram.set('capabilityLibrary',capabilityLibrary);
		return CapabilityLiDiagram;
	};
	CapabilityLibraryDiagram['remove:capabilities'] = function (model, coll, options) {
		this.handleRemoveCapabilities(model, coll, options);
	}
	CapabilityLibraryDiagram.prototype.getSystemTags = function(){
		return ['VDM','Capability Library Diagram','Admin'];
	};
	CapabilityLibraryDiagram.abstract = false;
	CapabilityLibraryDiagram.supported = true;
	path.CapabilityLibraryDiagram = CapabilityLibraryDiagram;
	export {CapabilityLibraryDiagram};
//});