import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BMCanvasBlockMixin} from './BMCanvasBlockMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {VdmlElement} from '../vdml/VdmlElement'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/canvas/BMCanvasBlockMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,BMCanvasBlockMixin
//){
	
	var path = DataManager.getDataManager().buildAppNsPath("canvas",global.version);
	var BMCanvasBlock = VdmlElement.extend(utils.customExtends({
		relations:BMCanvasBlockMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new BMCanvasBlockMixin()
	));
	BMCanvasBlock.abstract = false;
	path.BMCanvasBlock = BMCanvasBlock;
	export {BMCanvasBlock};
//});