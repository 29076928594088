import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import { ValueType } from './ValueType'
import {VdmlElement} from './VdmlElement'
import {VdmlElement2Mixin} from './VdmlElement2Mixin'
import {Expression} from './Expression'
import {Alternative} from '../transformation/Alternative'
import {DataManager} from '../../../com/vbee/data/DataManager'

	
var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
export class ValueElementContextMixin {

defaults(){
    var ret = {
        type: "vdml_ValueElementContext"
    }
    return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
}
static getMixinRelations(){
    return _.union([
        {
            type :Backbone.HasOne,
            containingClass:"vdml_ValueElementContext",
            key:"formula",
            relatedModel:"vdml.Expression",
            reverseRelation: {
                key:"formulaOwner",
                type :Backbone.HasOne,
                includeInJSON:"id"
            }
        },
        {
			type :Backbone.HasMany,
			containingClass:"vdml_ValueElementContext",
			key:"aggregatedFromValue",
			relatedModel:"vdml.ValueElement",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		/*{
			type :Backbone.HasMany,
			containingClass:"vdml_ValueElementContext",
			key:"aggregatedToContext",
			relatedModel:"vdml.ValueElement",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}*/
    ])
}

static getCumulativeMixinRelations(){
    if (!ValueElementContextMixin.cummulativeRelations) {
        ValueElementContextMixin.cummulativeRelations = _.union(ValueElementContextMixin.getMixinRelations()
            , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
        );
    }
    return ValueElementContextMixin.cummulativeRelations.slice();
}
static getSuperTypes(){
    return [
        "vdml_VdmlElement"
    ];
}
static getProperties(){
    return [
        { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
        { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
        {name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
        {name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
    ]
}
getExpression(){
    let self = this;
    let value = self.get("contextOwner");
    let satisfactionLevelOwner = value.get("satisfactionLevelOwner");
    let isSatisfaction = satisfactionLevelOwner ? true: false;
    let expresionStr;
    if(!isSatisfaction){
        expresionStr = self.get("formula") ? self.get("formula").get("expressionStr") : undefined ;
    }else{
        expresionStr = self.get("formula") ? self.get("formula").get("expressionConfig") : undefined ;
    }
    return expresionStr;
}
getParent(){
    var container;
    if(!container){
        container = this.get("contextOwner") ? this.get("contextOwner") : this.previousAttributes().contextOwner;
        if(container){
            return container;
        }
    }
}

getPackagePath(path){
    if(!path){
        path = [];
    }
    return VdmlElement.prototype.getPackagePath.apply(this, arguments);
}
getViewProperties(type){
    return {
        templatePath : "views/vdml/views/properties/ValueElementContextPropertiesTemplate.html",
        templateName : "ValueElementContextPropertiesTemplate",
        viewTypeStr : "appviews/vdml/views/properties/ValueElementContextViewModel",
        tabId : "ValueElementContextView",
        tabName: "ValueElementContext"
    }
}

    migrationExistingContext(baseTuples){
        var context = this;
        for(var i=0;i<baseTuples.length;i++) {
            if(baseTuples[i].old && baseTuples[i].new) {
                var component = baseTuples[i].new.component;
                context.get('aggregatedFromValue').add(component);
                component.get('aggregatedToContext').add(context);
            }
        }
    }

    addBaseTuple(baseTuples, contextCreation){
        var context = this;
        if(contextCreation){//move existing agg to context
            context.migrationExistingContext(baseTuples);
        }
        for(var i=0;i<baseTuples.length;i++) {
            if((!baseTuples[i].old && baseTuples[i].new)) {
                var component = baseTuples[i].new.component;
                context.get('aggregatedFromValue').add(component);
                component.get('aggregatedToContext').add(context);
            }else if(baseTuples[i].old && !baseTuples[i].new){
                var component = baseTuples[i].old.component;
                context.get('aggregatedFromValue').remove(component);
                component.get('aggregatedToContext').remove(context);
            }
        }
    };

}
path.ValueElementContextMixin = ValueElementContextMixin;
