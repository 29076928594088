import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { VdmlElement } from './VdmlElement'
import { VdmlElementMixin } from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { PracticeDefinition } from './PracticeDefinition'
import { PracticeCategory } from './PracticeCategory'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, PracticeDefinition
, PracticeCategory){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!PracticeDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PracticeDefinition){
			PracticeDefinition = importPath.PracticeDefinition;
		}
        else {
            import('./PracticeDefinition').then(({ default: PracticeDefinition }) => {
                PracticeDefinition = PracticeDefinition;
            });
			/*require(["appbo/vdml/PracticeDefinition"],function(loadedModule){
				PracticeDefinition = loadedModule;
			});*/
		}
	}
	if(!PracticeCategory){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if (importPath.PracticeCategory) {
            PracticeCategory = importPath.PracticeCategory;
		}
        else {
            import('./PracticeCategory').then(({ default: PracticeCategory }) => {
                PracticeCategory = PracticeCategory;
            });
			/*require(["appbo/vdml/PracticeCategory"],function(loadedModule){
				PracticeCategory = loadedModule;
			});*/
		}
	}
export class PracticeLibraryMixin {

    defaults() {
        var ret = {
            type: "vdml_PracticeLibrary"
        }
        return jQuery.extend(VdmlElement.prototype.defaults.apply(this), ret);
    }
    static getMixinRelations() {
        return _.union([
            {
                type: Backbone.HasMany,
                containingClass: "vdml_PracticeLibrary",
                key: "practiceDefinition",
                relatedModel: "vdml.PracticeDefinition",
                reverseRelation: {
                    key: "practiceDefinitionOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },
            {
                type: Backbone.HasMany,
                containingClass: "vdml_PracticeLibrary",
                key: "practiceCategory",
                relatedModel: "vdml.PracticeCategory",
                reverseRelation: {
                    key: "practiceCategoryOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            }
        ])
    }
    
    static getCumulativeMixinRelations() {
        if (!PracticeLibraryMixin.cummulativeRelations) {
            PracticeLibraryMixin.cummulativeRelations = _.union(PracticeLibraryMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
        return PracticeLibraryMixin.cummulativeRelations.slice();
    }
    static getSuperTypes() {
        return [
            "vdml_VdmlElement"
        ];
    }
    static getProperties() {
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "represents", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" }
        ]
    }
    getParent() {
        var container;
        if (!container) {
            container = this.get("practiceLibraryOwner") ? this.get("practiceLibraryOwner") : this.previousAttributes().practiceLibraryOwner;
            if (container) {
                return container;
            }
        }
        return this;
    }
    getPackagePath(path) {
        if (!path) {
            path = [];
        }
        var container;
        if (!container) {
            container = this.get("practiceLibraryOwner") ? this.get("practiceLibraryOwner") : this.previousAttributes().practiceLibraryOwner;
            if (container) {
                path.unshift({ id: this.get('id'), name: this.get('name') })
                return container.getPackagePath(path);
            }
        }
        return path;
    }
    getViewProperties(type) {
        return {
            templatePath: "views/vdml/views/properties/PracticeLibraryPropertiesTemplate.html",
            templateName: "PracticeLibraryPropertiesTemplate",
            viewTypeStr: "appviews/vdml/views/properties/PracticeLibraryViewModel",
            tabId: "PracticeLibraryView",
            tabName: "PracticeLibrary"
        }
    }
    
}
path.PracticeLibraryMixin = PracticeLibraryMixin;
	//return PracticeLibraryMixin;
//});