(function (factory) {
  /* global define */
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node/CommonJS
    module.exports = factory(require('jquery'));
  } else {
    // Browser globals
    factory(window.jQuery);
  }
}(function ($) {

  // Extends plugins for adding objectexplorer.
  //  - plugin is external module for customizing.
  $.extend($.summernote.plugins, {
    /**
     * @param {Object} context - context object has status of editor.
     */
    'objectexplorer': function (context) {
      var self = this;

      // ui has renders to build ui elements.
      //  - you can create a button with `ui.button`
      var ui = $.summernote.ui;

      // add objectexplorer button
      context.memo('button.objectexplorer', function () {
        // create button
        self.context = arguments[0];
        var button = ui.button({
          contents: '<i class="fa fa-child"/> Explore Report Model',
          tooltip: 'Explore Report Model',
          click: function () {
          //	window.require1(["appbo/report/BeepReportMixin"],function(BeepReportMixin){
            var BeepReportMixin = Backbone.Relational.store.getObjectByName("report.BeepReportMixin");
				function htmlEscape(str) {
		    		return String(str)
		            .replace(/@/g, '')
		            .replace(/ /g, '');
				}	          	
				var report = self.context.options.viewModel.model;
				var id = htmlEscape(window.guidGenerator()) + "report";
				window.getAndCreateModalDialog(true,id,BeepReportMixin,report,"explorerModelJson",null,self);
          	//});
            
          }
        });

        // create jQuery object from button instance.
        var $objectexplorer = button.render();
        return $objectexplorer;
      });

      // This events will be attached when editor is initialized.
      this.events = {
        // This will be called after modules are initialized.
        'summernote.init': function (we, e) {
          //console.log('summernote initialized', we, e);
        },
        // This will be called when user releases a key on editable.
        'summernote.keyup': function (we, e) {
          //console.log('summernote keyup', we, e);
        }
      };

      // This method will be called when editor is initialized by $('..').summernote();
      // You can create elements for plugin
      this.initialize = function () {
      };

      // This methods will be called when editor is destroyed by $('..').summernote('destroy');
      // You should remove elements on `initialize`.
      this.destroy = function () {
      };
    }
  });
}));
