import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelDimension} from './BusinessModelDimension'
import {ValuePropositionDimensionMixin} from './ValuePropositionDimensionMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelDimension","appbo/vdml/ValuePropositionDimensionMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelDimension,ValuePropositionDimensionMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var ValuePropositionDimension = BusinessModelDimension.extend(utils.customExtends({
		relations:ValuePropositionDimensionMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BusinessModelDimension.prototype.initialize.apply(this, arguments);
		}
		}
		, new ValuePropositionDimensionMixin()
	));
	
	ValuePropositionDimension.abstract = false;
	ValuePropositionDimension.supported = false;
	path.ValuePropositionDimension = ValuePropositionDimension;
	export {ValuePropositionDimension};
//});