import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Capability} from './Capability'
import {CapabilityMixin} from './CapabilityMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Capability","appbo/vdml/CapabilityMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Capability,CapabilityMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class CapabilityCategoryMixin {

	defaults(){
		var ret = {
			type: "vdml_CapabilityCategory"
		}
		return jQuery.extend(Capability.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!CapabilityCategoryMixin.cummulativeRelations) {
            CapabilityCategoryMixin.cummulativeRelations = _.union(CapabilityCategoryMixin.getMixinRelations()
                , CapabilityMixin.getCumulativeMixinRelations()
            );
        }
		return CapabilityCategoryMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_Capability"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			//{name : "definition",type : "EString",defaultValue : "null",containingClass : "vdml_Capability" },
			{name : "color",type : "EString",defaultValue : "null",containingClass : "vdml_Capability" }
		]
	}
	getParent(){
		var container;
		return Capability.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return Capability.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/CapabilityCategoryPropertiesTemplate.html",
			templateName : "CapabilityCategoryPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/CapabilityCategoryViewModel",
			tabId : "CapabilityCategoryView",
			tabName: "CapabilityCategory"
		}
	}
	
	}
	path.CapabilityCategoryMixin = CapabilityCategoryMixin;
	//return CapabilityCategoryMixin;
//});