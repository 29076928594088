import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","knockout","knockback","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ],
function(require,$,_, Backbone,Lawnchair, ko,kb,backboneLawnchair,DataManager,global){*/

	var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties",global.version);
	export class ValueUnitDetailsViewModel{
		
		cleanUp(){
			delete ko.bindingHandlers.unitNumeric; 
		}		
        cleanModal() {
            var self = this;
			window.cleanDialogModel(self.encodeId,this); 
        }
        saveUnitDetails() {
            var self = this;
            if(self.showUnit){
                self.options.callback(self.unit(),self.roundingValue(), self.significantDecimal()); 
            }else{
                self.options.callback(self.roundingValue(), self.significantDecimal());
            }
			window.cleanDialogModel(self.encodeId,this); 
		}
		
        afterRenderView(node, view) {
            var self = view;
			$("#sigDecimal"+self.encodeId).keyup(function (view) {
            	var sigValue = view.target.value;
            	if(sigValue < 0 || sigValue > 20 || sigValue > self.roundingValue()){
            		$("#sigDecimal"+self.encodeId).val(self.options.addOptions.significantDecimals);
					self.significantDecimal(self.options.addOptions.significantDecimals);
				}
            });
            $("#roundDecimal"+self.encodeId).keyup(function (view) {
            	var roundValue = view.target.value;
            	if(roundValue < 0 || roundValue > 20){
            		$("#roundDecimal"+self.encodeId).val(self.options.addOptions.roundingDigits);
					self.roundingValue(self.options.addOptions.roundingDigits);
				}
            });
		}
		
init(model,options){
    var self = this;
    this.ValueUnitDetailsViewModel = this;
    this.model = model;
    model = null;
    this.options = options;
    options = null;
    function htmlEscape(str) {
        return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
    }
    if (self.options && self.options.parentView) {
        this.parentView = self.options.parentView;
    }
    this.showUnit = ko.observable(false);
    this.unitDetails = ko.observable('');
    this.encodeId = "" + self.options.modalId;
    this.roundingValue = ko.observable(self.model ? self.model.get('roundingDigits') : 4);
    this.significantDecimal = ko.observable(self.model ? self.model.get('significantDecimals') : 2);
    this.unit = ko.observable(self.model ? self.model.get('unit') : "");
    if (self.options && self.options.addOptions && self.options.addOptions.roundingDigits) {
        self.roundingValue(self.options.addOptions.roundingDigits);
        self.significantDecimal(self.options.addOptions.significantDecimals);
        if(self.options.addOptions.showUnit){
            self.showUnit(true);
            self.unit(self.options.addOptions.unit);
        }
        if(self.options.addOptions.createUnit){
            self.unitDetails('Create unit Details')
        }else{
            self.unitDetails('Edit unit Details')
        }
       
    }
    ko.bindingHandlers.unitNumeric = {
        init: function (element) {
            $(element).on("keydown", function (event) {
                // Allow: backspace, delete, tab, escape, and enter
                if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13 ||
                    // Allow: Ctrl+A
                    (event.keyCode == 65 && event.ctrlKey === true) ||
                    /*// Allow: . ,
                    (event.keyCode == 188 || event.keyCode == 190 || event.keyCode == 110) ||*/
                    // Allow: home, end, left, right
                    (event.keyCode >= 35 && event.keyCode <= 39)) {
                    // let it happen, don't do anything
                    return;
                }
                else {
                    // Ensure that it is a number and stop the keypress
                    //if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
                    if (event.shiftKey || ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) ||
                        !(parseInt(event.key) >= 0 || parseInt(event.key) <= 9)) {
                        event.preventDefault();
                    }
                }
            });
        }
    };
    this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), ['general'
        , 'version'
        , 'name'
        , 'description'
        , 'type'
        , 'Close'
        , 'Complete'
        , 'addAnother'
        , 'unitDetails'
        , 'RoundingDecimals'
        , 'Unit'
        , 'SignificantDecimals'
        , 'Cancel'
        , 'createUnitDetails'
    ]);

}
	
    static getInstance(model,options){
    var view = new ValueUnitDetailsViewModel(model, options);
    view.init(model, options);
    return view;
    };
}
path.ValueUnitDetailsViewModel = ValueUnitDetailsViewModel;