import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {CanvasDetailsViewModel} from './CanvasDetailsViewModel'
import {DataManager} from '../../../../../com/vbee/data/DataManager'

//define(["require", "jquery", "underscore", "muuri", "backbone", "knockout", "bootbox", "appcommon/com/vbee/data/DataManager", "appbo/vdml/Annotation", "app/global", "appbo/vdml/BusinessModel", "appbo/vdml/Participant", "appbo/vdml/ValueElement", "appbo/canvas/BMCanvasItem", "knockback", "summernote", "appviews/canvas/views/designer/MappingWizardViewModel", "appviews/canvas/views/designer/CanvasDetailsViewModel", "appviews/help/views/properties/BMGuidanceViewModel", "appcommon/com/vbee/utils/DateFormat", "appbo/canvas/BMCanvasDiagramMixin", "appbo/vdml/CapabilityLibraryMixin", "appbo/vdml/ValueDeliveryModelMixin", "appbo/ecomap/EcoMapMixin", "domtoimage", "colorpicker"],
//    function (require, $, _, Muuri, Backbone, ko, bootbox, DataManager, Annotation, global, BusinessModel, Participant, ValueElement, BMCanvasItem, kb, summernote, MappingWizardViewModel, CanvasDetailsViewModel, BMGuidanceViewModel, DateFormat, BMCanvasDiagramMixin, CapabilityLibraryMixin, ValueDeliveryModelMixin, EcoMapMixin) {
var path = DataManager.getDataManager().buildAppNsPath("canvas.views.properties", global.version);
export class CanvasDetails2ViewModel extends CanvasDetailsViewModel {
    init(model, options) {
        super.init(model,options);
    }

    static getInstance(model, options) {
        var view = new CanvasDetails2ViewModel(model, options);
        view.init(model, options);
        return view;
    };
}
path.CanvasDetails2ViewModel = CanvasDetails2ViewModel;
