import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AbstractMeasureElement} from './AbstractMeasureElement'
import {CharacteristicMixin} from './CharacteristicMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { Measure } from './Measure'
import { RoleDefinition } from '../vdml/RoleDefinition'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/AbstractMeasureElement","appbo/smm/CharacteristicMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AbstractMeasureElement,CharacteristicMixin
, Measure
, RoleDefinition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Measure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measure){
			Measure = importPath.Measure;
		}
        else {
            import('./Measure').then(({ default: Measure }) => {
                Measure = Measure;
            });

			/*require(["appbo/smm/Measure"],function(loadedModule){
				Measure = loadedModule;
			});*/
		}
	}
	if(!RoleDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.RoleDefinition){
			RoleDefinition = importPath.RoleDefinition;
		}
        else {
            import('../vdml/RoleDefinition').then(({ default: RoleDefinition }) => {
                RoleDefinition = RoleDefinition;
            });

			/*require(["appbo/vdml/RoleDefinition"],function(loadedModule){
				RoleDefinition = loadedModule;
			});*/
		}
	}
	var Characteristic = AbstractMeasureElement.extend(utils.customExtends({
		relations:CharacteristicMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			AbstractMeasureElement.prototype.initialize.apply(this, arguments);
			/*this.on('remove:measure',function(model){
				if(Backbone.skipPersistance){
					return;
				}				
				if(this.get('measure').length === 0){
					this.destroy();
				}
			});
			this.on('remove:measuredCharacteristic',function(model){
				if(Backbone.skipPersistance){
					return;
				}				
				if(this.get('measuredCharacteristic').length === 0){
					this.destroy();
				}
			});*/
		}
		}
		, new CharacteristicMixin()
	));
    Characteristic['remove:measure'] = function (model) {
        if (Backbone.skipPersistance) {
            return;
        }
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
			if (this.get('measure').length === 0) {
				this.destroy();
			}
		}
    };
    Characteristic['remove:measuredCharacteristic']= function (model) {
        if (Backbone.skipPersistance) {
            return;
        }
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
			if (this.get('measuredCharacteristic').length === 0) {
				this.destroy();
			}
		}
    };
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	Characteristic.abstract = false;
	Characteristic.supported = !sp2Enabled;
	path.Characteristic = Characteristic;
	export {Characteristic};
//});