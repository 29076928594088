import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {VdmlElement} from '../vdml/VdmlElement'
import {VdmlElementMixin} from '../vdml/VdmlElementMixin'
import { VdmlElement2Mixin } from '../vdml/VdmlElement2Mixin'
import {VDMLCanvasElement} from '../vdml/VDMLCanvasElement'
//define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin","appbo/vdml/VDMLCanvasElement"],
//function(require,jQuery,_,async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin,VDMLCanvasElement
//){
	
	var path = DataManager.getDataManager().buildAppNsPath("canvas",global.version);
	/*if(!VdmlElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("canvas",global.version);
		if(importPath.VdmlElement){
			VdmlElement = importPath.VdmlElement;
		}
		else{
			require(["appbo/vdml/VdmlElement"],function(loadedModule){
				VdmlElement = loadedModule;
			});
		}
	}*/		
	export class BMCanvasItemMixin{
	defaults(){
		var ret = {
			type: "canvas_BMCanvasItem"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"canvas_BMCanvasItem",
			key:"canvasItem",
			relatedModel:"vdml.VDMLCanvasElement",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type: Backbone.HasOne,
			containingClass: "canvas_BMCanvasItem",
			key: "capabilityItem",
			relatedModel: "vdml.Capability",
			includeInJSON: Backbone.Model.prototype.idAttribute,
        },
        {
             type: Backbone.HasMany,
             containingClass: "canvas_BMCanvasItem",
             key: "annotation",
             relatedModel: "vdml.Annotation",
             reverseRelation: {
                key: "annotationOwner",
                type: Backbone.HasOne,
                includeInJSON: Backbone.Model.prototype.idAttribute
             }
        }
        ])
	}
	
	static getCumulativeMixinRelations(){
		if (!BMCanvasItemMixin.cummulativeRelations) {
            BMCanvasItemMixin.cummulativeRelations = _.union(BMCanvasItemMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return BMCanvasItemMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" }
			/*{name : "top",type : "EString",defaultValue : "null",containingClass : "canvas_BMCanvasItem" },
			{name : "left",type : "EString",defaultValue : "null",containingClass : "canvas_BMCanvasItem" },
			{name : "width",type : "EString",defaultValue : "null",containingClass : "canvas_BMCanvasItem" },
			{name : "height",type : "EString",defaultValue : "null",containingClass : "canvas_BMCanvasItem" }*/
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("blockItemOwner") ? this.get("blockItemOwner") : this.previousAttributes().blockItemOwner;
			if(container){
				return container;
			}
		}
		return this;
	};
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("blockItemOwner") ? this.get("blockItemOwner") : this.previousAttributes().blockItemOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')});
				return container.getPackagePath(path);
			}
		}
		return path;
	};
	//
	}
	path.BMCanvasItemMixin = BMCanvasItemMixin;