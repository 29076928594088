import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {VdmlElement} from '../vdml/VdmlElement'
import {VdmlElementMixin} from '../vdml/VdmlElementMixin'
import {BMCanvasBlock} from './BMCanvasBlock'
import {DataManager} from '../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
//function(require,jQuery,_,async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
//,BMCanvasBlock
//){
	
var path = DataManager.getDataManager().buildAppNsPath("canvas",global.version);
export class BMCanvasDiagramMixin{
	
	defaults(){
		var ret = {
			type: "canvas_BMCanvasDiagram"
		};
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"canvas_BMCanvasDiagram",
			key:"bmCanvasBlock",
			relatedModel:"canvas.BMCanvasBlock",
			reverseRelation: {
				key:"bmCanvasBlockOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
        {
            type :Backbone.HasOne,
            containingClass:"canvas_BMCanvasDiagram",
            key:"businessModel",
            relatedModel:"vdml.BusinessModel",
            includeInJSON: Backbone.Model.prototype.idAttribute,
        }		
		]);
	};
	static getCumulativeMixinRelations(){
		if (!BMCanvasDiagramMixin.cummulativeRelations) {
            BMCanvasDiagramMixin.cummulativeRelations = _.union(BMCanvasDiagramMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return BMCanvasDiagramMixin.cummulativeRelations.slice();
	};
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "progress", type: "double", defaultValue: "0", containingClass: "cmof_EObject" },
			{name : "canvasType",type : "EString",defaultValue : "null",containingClass : "canvas_BMCanvasDiagram" }
		]
    }
    calculateProgress () {

    }
	getParent(){
		var container;
		if(!container){
			container = this.get("diagramOwner") ? this.get("diagramOwner") : this.previousAttributes().diagramOwner;
			if(container){
				return container;
			}else{
				container = this.get("canvasOwner") ? this.get("canvasOwner") : this.previousAttributes().canvasOwner;
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("diagramOwner") ? this.get("diagramOwner") : this.previousAttributes().diagramOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')});
				return container.getPackagePath(path);
			}
		}
		return path;
	};
	/*static loadMappingModules(callback){
		if(!DataManager.getDataManager().canvasModules){
			DataManager.getDataManager().canvasModules = {};
		}	
		if(!DataManager.getDataManager().get('currentPlan') || DataManager.getDataManager().canvasModules[DataManager.getDataManager().get('currentPlan').id]){
			callback();
			return;
		}
  		var modules = [];
        DataManager.getDataManager().getAllDocumentsCollectionOfType(DataManager.getDataManager().get('currentPlan').getCommonRepositoryId(),'beeppackage_BeepModule',function(refs){
            var count = refs.length;
            function getModuleInstance(){
                count--;
                if(count >=0  && refs.at(count).get('parent')){
                    var ref = refs.at(count);
                    var parentId = ref.get('parent');
                    var repId = DataManager.getDataManager().getRepositoryId(parentId);
                    var vdmStore = DataManager.getDataManager().getVDMStore(repId);
                    DataManager.getDataManager().fetchDocumentFromPackage(parentId,"appbo/beeppackage/BeepModule",ref.get('version'),ref.get('id'),"appbo/beeppackage/BeepModule",vdmStore,{
                        success:function(model){
                            modules.push(model);
                            getModuleInstance();
                        },
                        error:function(error){
                            console.log(error);
                            getModuleInstance();
                        }
                    });                     
                }else{
                	if(count >=0){
                		getModuleInstance();
                	}else {
						var canvasModules = {};
						for(var i=0;i<modules.length;i++){
							var module = modules[i];
							var configContainer = module.get('artifact').at(0);
							if(configContainer){
								var config = configContainer.get('code');
								var configObj = JSON.parse(config);
								canvasModules[configObj.type] = config;
							}
						}
						
						DataManager.getDataManager().canvasModules[DataManager.getDataManager().get('currentPlan').id] = canvasModules;
						callback();
                	}
                }
            }
            getModuleInstance();
        },null,null,true,'vbc:cmof_EObject-name');	
	}*/
	getViewProperties(type){
		var canvasType = this.get('canvasType');
		var template = 'NineDimensionsDesignerTemplate';
		if(!canvasType){
			canvasType = "BusinessCanvas";
		}
		var config = window.utils.getCanvasJson(canvasType);
		if(config){
			//var configObj = JSON.parse(config);
			template = config.template;
		}
		
		return {
			templatePath : "views/canvas/views/designer/canvasTemplates/" + template + ".html",
			templateName : template,
			viewTypeStr : "appviews/canvas/views/designer/CanvasDesignerViewModel",
			tabId : "CanvasDesignerView",
			tabName: "CanvasDesignerView"
		}
	};
	static getDialogViewProperties(type,model){
		if(type === 'CanvasDesigner'){
			var template = 'NineDimensionsDesignerTemplate';
			var canvasType = model.get('canvasType');
			var config = window.utils.getCanvasJson(canvasType);
			if(config){
				//var configObj = JSON.parse(config);
				template = config.template;
			}		
		
			return {
				templatePath : "views/canvas/views/designer/canvasTemplates/" + template + ".html",
				templateName : template,
				viewTypeStr : "appviews/canvas/views/designer/CanvasDesignerViewModel",
				tabId : "CanvasDesignerView",
				tabName: "CanvasDesignerView"
			};
		}else if(type === "TargetDetails"){
			return{
				templatePath: "views/canvas/views/designer/CanvasTargetDetailsTemplate.html",
		        templateName: "CanvasTargetDetailsTemplate",
		        viewTypeStr: "appviews/canvas/views/designer/CanvasTargetDetailsViewModel",
		        tabId: "CanvasTargetDetailsView",
		        tabName: "CanvasTargetDetails"
			}
		}else if(type==="explorerModelJson"){
			return{
				templatePath : "views/beeppackage/views/properties/explorerModelJsonTemplate.html",
			    templateName : "explorerModelJsonTemplate",
			    viewTypeStr : "appviews/beeppackage/views/properties/explorerModelJsonViewModel",
                tabId: "explorerModelJsonView",
                tabName: "explorerModelJson"
			}
		}
	};
	
}
path.BMCanvasDiagramMixin = BMCanvasDiagramMixin;
