import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from './EObject'
import {EObjectMixin} from './EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin){
	
	
	export class ElementMixin{
		constructor(){
			var path = DataManager.getDataManager().buildAppNsPath("cmof",global.version);	
			path.ElementMixin = ElementMixin;
		}
	defaults(){
		var ret = {
			type: "cmof_Element"
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!ElementMixin.cummulativeRelations) {
            ElementMixin.cummulativeRelations = _.union(ElementMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return ElementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
		return []
	}
	getParent(){
		var container;
		return EObject.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return EObject.prototype.getPackagePath.apply(this, arguments);
	}
	static getViewProperties(type){
		return {
			templatePath : "views/cmof/views/properties/ElementPropertiesTemplate.html",
			templateName : "ElementPropertiesTemplate",
			viewTypeStr : "appviews/cmof/views/properties/ElementViewModel",
			tabId : "ElementView",
			tabName: "Element"
		}
	}
	//return ElementMixin;
}