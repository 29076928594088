import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global","appbo/vdml/ValueDeliveryModel","appbo/vdml/BusinessModel","appbo/vdml/ValueStream","typeahead","appbo/vdml/ValueDeliveryModelMixin", "summernote"],
function(require,$,_, Backbone,ko, koMapping,kb,bootbox,DataManager,global,ValueDeliveryModel,BusinessModel,ValueStream,typeahead,ValueDeliveryModelMixin
){*/
    
    var path = DataManager.getDataManager().buildAppNsPath("scenario.views.properties",global.version);
    
    export class CalculationSummaryViewModel{
        
		
    afterRenderView(node, view){
    
    }
          
    cleanModalHandler() {
        var self = this;
        window.cleanDialogModel(self.encodeId, this);
    }
        
    init(model, options){
		var self = this;
        this.CalculationSummaryViewModel = this;
        this.model = model;
        this.id = ko.observable(self.model.get('id'));
        this.encodeId = window.utils.htmlEscape(this.id());
        this.name = ko.observable(self.model.get('name'));
        var defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: self.model.get("defaultExecutionScenario") });
        this.scName = ko.observable(defaultExecutionScenario.get('name'));
        this.lastCalculated = ko.observable();
        this.calculationSummaryDetails = ko.observableArray([]);
		this.cleanModal = _.bind(self.cleanModalHandler, self);
		var dataManager = DataManager.getDataManager();
        dataManager.fetchCalcData(true,true,function(resp){
            var calcResults = resp;//dataManager.get('calcInfo');
            //var modelChange = calcResults ? calcResults.modelChange :{};
            var scenarioExecutionChange = calcResults ? calcResults.scenarioExecutionChange : {};
            //var defaultScenario = dataManager.get('defaultScenario');
            //var defaultExecutionScenaroId = defaultScenario ? defaultScenario.get("defaultExecutionScenario"):null;
            //var defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
            //var calculationData = defaultExecutionScenario ? defaultExecutionScenario.get('calculationSummary') :null;
            //var calculationData = dataManager.get('calculationSummary');
            //debugger
            var calculationData = calcResults.calculationSummary;
            if (calculationData) {
                calculationData = JSON.parse(calculationData);
                //var lastChange = modelChange.lastChange ? new Date(modelChange.lastChange).toLocaleDateString() + " "+ new Date(modelChange.lastChange).toLocaleTimeString() :'';
                var lastCalc = "";
                if(scenarioExecutionChange.lastCalculated){
                    lastCalc = scenarioExecutionChange.lastCalculated ? new Date(scenarioExecutionChange.lastCalculated).toLocaleDateString() + " "+ new Date(scenarioExecutionChange.lastCalculated).toLocaleTimeString() :'';
                }
                self.lastCalculated(lastCalc);
                var calculationSummaryArray = [];
                //var calculationSummary1 = '<ul>';
                for (const [key, value] of Object.entries(calculationData)) {
                    //calculationSummary1 += `<li><strong>${key}:</strong> ${value}</li>`;
                    calculationSummaryArray.push({'name':key,'value':value});
                }
                self.calculationSummaryDetails(calculationSummaryArray);
                /*calculationSummary1 += '</ul>';
                const calculationSummary = `
                    <ul>
                        <li>lastChange : `+lastChange+`</li>
                        <li>noOfChanges : `+modelChange.noOfChanges+`</li>
                        <li>lastCalculated : `+lastCalculated+`</li>
                        <li>Calculation : `+calculationSummary1+`</li>
                    </ul>
                `;
                var dialogContent = `<div> <div> ${calculationSummary} </div> </div>`;*/
            }
        });
        
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['Close'
        ,'CalculationSummary'
        ,'Complete'
        ]);
	}
    
    static getInstance(model,options){
        var view = new CalculationSummaryViewModel(model,options);
		view.init(model, options);
		return view;
    };
}
path.CalculationSummaryViewModel = CalculationSummaryViewModel;