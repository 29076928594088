import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {RoleCategoryMixin} from './RoleCategoryMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { RoleDefinition } from './RoleDefinition'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/RoleCategoryMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,RoleCategoryMixin
, RoleDefinition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!RoleDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.RoleDefinition){
			RoleDefinition = importPath.RoleDefinition;
		}
		else{
            import('./RoleDefinition').then(({ default: RoleDefinition }) => {
                RoleDefinition = RoleDefinition;
			});
			/*require(["appbo/vdml/RoleDefinition"],function(loadedModule){
				RoleDefinition = loadedModule;
			});*/
		}
	}
	var RoleCategory = VdmlElement.extend(utils.customExtends({
		relations:RoleCategoryMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new RoleCategoryMixin()
	));
	
	RoleCategory.abstract = false;
	RoleCategory.supported = false;
	path.RoleCategory = RoleCategory;
	export {RoleCategory};
//});