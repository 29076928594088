import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Interval} from './Interval'
import {RankingIntervalMixin} from './RankingIntervalMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/Interval","appbo/smm/RankingIntervalMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Interval,RankingIntervalMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var RankingInterval = Interval.extend(utils.customExtends({
		relations:RankingIntervalMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Interval.prototype.initialize.apply(this, arguments);
		}
		}
		, new RankingIntervalMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	RankingInterval.abstract = false;
	RankingInterval.supported = !sp2Enabled;
	path.RankingInterval = RankingInterval;
	export {RankingInterval};
//});