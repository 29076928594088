import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurableElement} from './MeasurableElement'
import {MeasurableElementMixin} from './MeasurableElementMixin'
//import {Pool} from './Pool'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { OutputPort } from './OutputPort'
import { InputPort } from './InputPort'
import { MeasuredCharacteristic } from './MeasuredCharacteristic'
//import { Expression } from './Expression'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/MeasurableElementMixin","appbo/vdml/Pool"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,MeasurableElementMixin,Pool
, OutputPort
, InputPort
, MeasuredCharacteristic

, Expression){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!OutputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OutputPort){
			OutputPort = importPath.OutputPort;
		}
		else{
            import('./OutputPort').then(loadedModule => {
                OutputPort = loadedModule;
			});
			/*require(["appbo/vdml/OutputPort"],function(loadedModule){
				OutputPort = loadedModule;
			});*/
		}
	}
	if(!InputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.InputPort){
			InputPort = importPath.InputPort;
		}
		else{
            import('./InputPort').then(loadedModule => {
          //      InputPort = loadedModule;
			});
			/*require(["appbo/vdml/InputPort"],function(loadedModule){
				InputPort = loadedModule;
			});*/
		}
	}
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
		else{
            import('./MeasuredCharacteristic').then(loadedModule => {
                MeasuredCharacteristic = loadedModule;
			});
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	/*if(!Expression){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Expression){
			Expression = importPath.Expression;
		}
		else{
            import('./Expression').then(loadedModule => {
                Expression = loadedModule;
			});
		}
	}*/
	export class ResourceUseMixin {

	defaults(){
		var ret = {
			type: "vdml_ResourceUse"
		}
		return jQuery.extend(MeasurableElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_ResourceUse",
			key:"resource",
			relatedModel:"vdml.InputPort",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		/*{
			type :Backbone.HasOne,
			containingClass:"vdml_ResourceUse",
			key:"quantity",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"quantityOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_ResourceUse",
			key:"duration",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"durationOwnerResourceUse",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_ResourceUse",
			key:"planningPercentage",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"planningPercentageOwnerResourceUse",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_ResourceUse",
			key:"condition",
			relatedModel:"vdml.Expression",
			reverseRelation: {
				key:"conditionOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}*/
		])
	}
	static getCumulativeMixinRelations(){
		 if(!ResourceUseMixin.cummulativeRelations) {
            ResourceUseMixin.cummulativeRelations = _.union(ResourceUseMixin.getMixinRelations()
                , MeasurableElementMixin.getCumulativeMixinRelations()
            );
         }
		 return ResourceUseMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_MeasurableElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "inputDriven",type : "EBoolean",defaultValue : "null",containingClass : "vdml_ResourceUse" },
			{name : "isExclusive",type : "EBoolean",defaultValue : "null",containingClass : "vdml_ResourceUse" },
			{name : "resourceIsConsumed",type : "EBoolean",defaultValue : "null",containingClass : "vdml_ResourceUse" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("resourceUseOwner") ? this.get("resourceUseOwner") : this.previousAttributes().resourceUseOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("resourceUseOwner") ? this.get("resourceUseOwner") : this.previousAttributes().resourceUseOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ResourceUsePropertiesTemplate.html",
			templateName : "ResourceUsePropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ResourceUseViewModel",
			tabId : "ResourceUseView",
			tabName: "ResourceUse"
		}
	}

	
	}
	path.ResourceUseMixin = ResourceUseMixin;
	//return ResourceUseMixin;
//});