import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelDimension} from './BusinessModelDimension'
import {ValueFormulaDimensionMixin} from './ValueFormulaDimensionMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelDimension","appbo/vdml/ValueFormulaDimensionMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelDimension,ValueFormulaDimensionMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var ValueFormulaDimension = BusinessModelDimension.extend(utils.customExtends({
		relations:ValueFormulaDimensionMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BusinessModelDimension.prototype.initialize.apply(this, arguments);
		}
		}
		, new ValueFormulaDimensionMixin()
	));
	
	ValueFormulaDimension.abstract = false;
	ValueFormulaDimension.supported = false;
	path.ValueFormulaDimension = ValueFormulaDimension;
	export {ValueFormulaDimension};
//});