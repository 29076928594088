import React from 'react'
import { DataManager } from "../../../../../com/vbee/data/DataManager";
import { MarketPlace as MarketPlaceContainer} from '@marketplace/marketplacecontainer';
import * as bootbox from '../../../../../../libs/bootbox/bootbox'

const MarketPlaceCloud = ({workspaceName, id, selectedOrg, model, canvas}) => {
  var accountId = DataManager.getDataManager().get('gapiId');
  var myMSALObj = DataManager.getDataManager().get('mSALObj');
  const account = {account:myMSALObj.getAccountByHomeId(accountId), instance:myMSALObj}
  let dataManager = DataManager.getDataManager();
  var protectedResources = dataManager.get('protectedResources');
  let planWorkspaceId = dataManager.get('currentWorkspaceId');
  let workspaceData = dataManager.get("workspaceNames");
  let updWorkspaceName = workspaceName;
  var planBackboneId = model ? model.id : null;
  const purchasedProducts = dataManager.get("products") && dataManager.get("products").map((d) => d.productId);
  const planList = [1].toString();
  const planEditList = [2, 5, 7].toString();
  const canvasList = [8].toString();
  const typeList = canvas ? canvasList : (id !== null ? planEditList : planList);

  const postCallHandler=(params,workspaceId, workspaceName, planId, packageDocumentId, orgId, phaseId, type, link)=>{
    window.utils.startSpinner("CopySpinner", "Installing...");
    dataManager.saveChangeSetToBackend(dataManager.get(dataManager.CURRENT_CHANGESET),function(response){
      let workspaceNameExists = false;
      for (var key in workspaceData) {
        if (workspaceData[key] == workspaceName) {
          workspaceId = key;
          workspaceNameExists = true;
          break;
        }
      }
      let applyWsId = workspaceId;
      if(!workspaceNameExists){
        workspaceId = window.uuidGenerator().toUpperCase();
        //dataManager.saveWorkspaceNamesData(planWorkspaceId, self.workspaceName);
        dataManager.get("workspaceLegal")[workspaceId] = selectedOrg;
        applyWsId = null;
      }
      dataManager.applyWorkspaceById(applyWsId,function(){
        let installUrl;
        if (type.toUpperCase() !== 'BUSINESS MAP' && planId !== null) {
          installUrl = `/vdmbee/workspace/copymarketplacepackagetoplan/${workspaceId}/${planId}/${packageDocumentId}/${orgId}`;
        } else if (type.toUpperCase() === 'BUSINESS MAP' && planId !== null) {
          installUrl = `/vdmbee/workspace/copymarketplacepackagetoplan/${workspaceId}/${planId}/${packageDocumentId}/${orgId}?altId=${phaseId}`;
        } else {
          installUrl = `/vdmbee/workspace/copymarketplacepackage/${workspaceId}/${packageDocumentId}/${orgId}?wsName=${workspaceName}`;
        }
        dataManager.get('vmpServerService').postAllPromise(installUrl, JSON.stringify(params)).then(function(data) {
          /*if(link){
            window.utils.startSpinner("CopySpinner", "Linking...");
          }else {
            window.utils.startSpinner("CopySpinner", "Installing...");
          }*/
          dataManager.getMarketPlaceProducts();
          if (type == "Business Canvas"){
            dataManager.fetchCanvasFromBackend();   
          }
          //dataManager.applyWorkspaceById(workspaceId,function(){
              var query = {tags:[],type: '',name : '',documentOwner : ''};
              if(planId !== null && planBackboneId != null){
                var plan = window.utils.getElementModel(planBackboneId,['transformation.Plan']);
                if(plan){
                  dataManager.set('currentPlan',plan);
                  function waitForPlanLoad(){
                    setTimeout(function(){
                      if(window.loadedPackages[planBackboneId]){
                        dataManager.acquireSaveLock(function(){
                          dataManager.pullServerChanges(function(){
                            dataManager.releaseSaveLock();
                            dataManager.getPlansFromBackend(query,function(artifacts){
                              bootbox.alert('Package has been installed.');
                              window.loadedPackages[planBackboneId] = null;
                              dataManager.set('currentPlan',null);
                              window.utils.stopSpinner('CopySpinner');
                            });
                          },false); 
                        });
                      } else {
                        waitForPlanLoad();
                      }
                    },100);
                  }
                  waitForPlanLoad();
                } else {
                  dataManager.getPlansFromBackend(query,function(artifacts){
                    bootbox.alert('Package has been installed.');
                    window.utils.stopSpinner('CopySpinner');
                  });
                }
              } else {
                window.vdmModelView.plansCollection.reset();
                if(!workspaceNameExists){
                  dataManager.saveWorkspaceNamesData(workspaceId, workspaceName);
                }
                dataManager.getPlansFromBackend(query,function(artifacts){
                  window.vdmModelView.importAllPlans(artifacts,function(){
                    /*if(link){
                      bootbox.alert('Package has been linked to Workspace');
                    } else {*/
                      bootbox.alert('Package has been installed.');
                    //}
                    window.utils.stopSpinner('CopySpinner');
                    jQuery('.cloudClose').click();
                    jQuery('.btn-editPlanClose').click();
                  });
                });
              }
            //});
        }).catch(error => {	
          console.log(error);
          bootbox.alert(error);
          window.utils.stopSpinner("CopySpinner");
        });
      });
    });
  }

  let phaseAltList = []

  if (model !== null) {
    var phases = model.get("phase");
    for (var j = 0; j < phases.length; j++) {
      var master = phases.at(j).get("master");
      phaseAltList.push({
        id: master.get("id"),
        name: phases.at(j).get("name"),
      });
    }
  }

const updatePurchasedProducts = function () {
  dataManager.getLicenses({
    success: function (response) {
      dataManager.onLicenseUpdate(response);
    },
    error: function (err) {
      console.log(err);
    },
  });
};


  
  return (
    <div>
        <MarketPlaceContainer protectedResourcesDetails={protectedResources} strategyPlanner={true} accountDetails={account} orgId={dataManager.get("currentLegalEntity").entityId} postCallHandler={postCallHandler} cloud={true} workspaceId={planWorkspaceId} workspaceName={updWorkspaceName} phaseList={phaseAltList} id={id} purchasedProducts={purchasedProducts} updatePurchasedProducts={updatePurchasedProducts} bootbox={bootbox} typeList={typeList} />
    </div>
  )
}

export default MarketPlaceCloud