import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import * as fnon from 'fnon';
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as asyncsimple from '../../../../../../libs/asyncsimple/asyncsimple'
/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","asyncsimple","Lawnchair","backbone-lawnchair","bootbox","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/ValueDeliveryModel","appbo/transformation/Plan","appbo/preference/UserPreferences"],
function(require,$,_,Backbone,ko, koMapping,kb,asyncsimple,Lawnchair, backboneLawnchair,bootbox,DataManager,global,ValueDeliveryModel,Plan,UserPreferences){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation.views.correction",global.version);
	export class PlanValidationViewModel {
		init(model, options,detailedView) {
			var self = this;
			this.PlanValidationViewModel = this;
			this.model = model;
			if(options && options.parentView){
				this.parentView = options.parentView;
				this.encodeId = ""+options.modalId;
			}

			this.planValueDetails = ko.observableArray();
			this.accumulatorMissingDetails = ko.observableArray();
			this.definitionMissingDetails = ko.observableArray();
			this.valueFormulaMissingDetails = ko.observableArray();
			this.junkMeasureRelationshipDetails = ko.observableArray();
			this.baseMeasurementsMissingDetails = ko.observableArray();
			this.satisfactionMissingDetails = ko.observableArray();
			this.satisfactionMeasureDetails = ko.observableArray();
			this.satisfactionMissingFix = [];
			this.usePreviousMissingDetails = ko.observableArray();
			this.measureRelationReused = ko.observableArray();
			this.unitMissing = ko.observableArray();
			this.compareValueFormulaDetails = ko.observableArray();
			this.autoSaveTimer = ko.observable("1");
			this.usePreviousBoolean = ko.observableArray();
			this.satisfactionCalMissing = ko.observableArray();
			this.junkObjects = [];
		
			//this.autoSave = ko.observable();

			/*this.autoSave.subscribe(function (selected) {
				if(selected){
					DataManager.getDataManager().set('autoSave',true);
				}else {
					DataManager.getDataManager().set('autoSave',false);
					clearInterval(DataManager.getDataManager().saveTimer);
				}
			});*/
			this.autoSaveTimer.subscribe(function (selected) {
				if(selected){
					//clearInterval(DataManager.getDataManager().saveTimer);
					if(selected == "0"){
						//DataManager.getDataManager().set('readMode',true);
						DataManager.getDataManager().set('autoSave',false);

						/*document.body.style.backgroundColor = "rgb(56 50 50)";
						document.body.style.color = "rgb(181 170 170)";
						jQuery('.navbar-inverse').css("background-color", "rgb(56 50 50)");
						jQuery('.VS-search-inner').css("background-color", "rgb(56 50 50)");*/
					}else {
						//DataManager.getDataManager().set('readMode',false);
						DataManager.getDataManager().set('autoSave',true);
						/*document.body.style.backgroundColor = "rgb(255, 255, 255)";
						document.body.style.color = "rgb(51, 51, 51)";
						jQuery('.navbar-inverse').css("background-color", "rgb(255, 255, 255)");
						jQuery('.VS-search-inner').css("background-color","white");*/
						
						//var newTimer = selected * 1000;
						//DataManager.getDataManager().set('autoSave',true);
						//DataManager.getDataManager().set('autoSaveTimer',newTimer);
						//DataManager.getDataManager().saveTimer = setInterval(DataManager.getDataManager().callSaveData,newTimer);	
					}
				}else {
					//clearInterval(DataManager.getDataManager().saveTimer);
				}
			});
			ko.bindingHandlers.numeric = {
			init: function (element, valueAccessor) {
				$(element).on("keydown", function (event) {
					$(element).prop('oldValue', $(element).val());
					window.utils.checkNumericValue(event,element);
				});
				$(element).on("keyup", function (event) {
					if(!validate($(event.target).val())){
						$(event.target).val($(element).prop('oldValue'));
					}
					function validate(s) {
					   var rgx = /\-?[0-9]*\.?[0-9]*$/;
					   if(s.match(rgx) == s){
							return true;
					   }
					   return false;
					}
				});
			}
			};
		}

		deleteDisposableMeasures(){
			var self = this;
			var mesLength = self.junkObjects.length;
			while(mesLength--){
				self.junkObjects[mesLength].destroy();
			}
			self.junkMeasureRelationshipDetails([]);
		}
		
		cleanModal(){
			var self = this;
			delete ko.bindingHandlers.numeric;
			window.cleanDialogModel(self.encodeId,self);
		}
		
		checkPhaseDetails(){
            var self = this;
            var phases = self.model.get('phase');
			for(var i=0;i<phases.length;i++){
				self.checkAlternativeDetails(phases.at(i));
			}
		};

		checkJunkObservedMeasure(observation) {
            var self = this;
			var observedMeasures = observation.get('observedMeasures');
			if(observedMeasures){
				for(var i=0;i<observedMeasures.length;i++){
					var obs = observedMeasures.at(i);
					var mst = obs.get("measurements") ? obs.get("measurements").at(0):null;
					if(mst && mst.get('measuredCharacteristic') == null){
						self.junkMeasureRelationshipDetails.push("mCharacteristic missing:"+obs.get('name'));
						self.junkObjects.push(obs);
					}
				}
			}
        };
		
		checkAlternativeDetails(phaseModel){
			var self = this;
			var alternatives = phaseModel.get('phaseAlternative');
			for(var i=0;i<alternatives.length;i++){
				var scenario = alternatives.at(i).getOrCreatePhaseObjectiveSet();
				var obs = scenario.get('objectiveObservation');
				if(obs){
					for(var x=0; x<obs.length; x++){
						self.checkJunkObservedMeasure(obs.at(x));
						self.checkPlanValues(alternatives.at(i), obs.at(x).get("whenObserved"));
					}
				}
			}
		};
		
		getComponentMeasurement(component, mc, useScenario, timestamp){
            var measurement = null;
            var mCharacteristic = component.get(mc);
			var isTimeStamp = false;
			if(timestamp){
				isTimeStamp = true;
			}
            var observedMeasure = useScenario.getObservedMeasureWithMeasurements(mCharacteristic, isTimeStamp, timestamp);
            if(observedMeasure){
                measurement = observedMeasure.get('measurements').at(0);
            }
            return measurement;
        };
        
        checkDefinitionAssociation(msmtRelationship){
			var self = this;
        	var definition = msmtRelationship.get('definition');
        	var fromMeasure = msmtRelationship.get('from').get('observedMeasure').get('measure');
        	var toMeasure = msmtRelationship.get('to')?msmtRelationship.get('to').get('observedMeasure').get('measure'):null;
        	if(definition && (definition.get('from') != fromMeasure || definition.get('to') != toMeasure)) {
        		self.planValueDetails.push(msmtRelationship.get('name'));
        	}
        };
        
        checkDefinitionMissing(msmtRelationship){
			var self = this;
        	var definition = msmtRelationship.get('definition');
        	if(!definition){
        		self.definitionMissingDetails.push(msmtRelationship.get('name'));
        	}
        }; 
        
        checkJunkMeasureRelationShips(){
			var self = this;
        	var libraries = self.model.get('planObservationContainer')?self.model.get('planObservationContainer').get('libraries'):[];
			if(libraries && libraries.length > 0){
				for(var i=0;i<libraries.length;i++){
					var measureElements = libraries.at(i).get('measureElements');
					for(var j=0;j<measureElements.length;j++){
						if(measureElements.at(j).get('type') === 'smm_CollectiveMeasure'){
							var measureRelationship = measureElements.at(j).get('measureRelationships');
							for(var k=0;k<measureRelationship.length;k++){
					        	if(!measureRelationship.at(k).get('to')){
					        		self.junkMeasureRelationshipDetails.push(measureRelationship.at(k).get('name'));
									self.junkObjects.push(measureRelationship.at(k));
					        	}
				        	}
			        	}
		        	}
	        	}
        	}
        };

		checkJunkMeasurementRelationShips(comp,valueMsmt,msmtRels){
			var self = this;
			var measure = valueMsmt.get('observedMeasure').get('measure');
			var measureRel = measure.get('measureRelationships');
			var mesaureReused = comp.checkMeasureReuse(measure);
			if(!mesaureReused && measureRel.length != msmtRels.length){
				for(var i=0;i<measureRel.length;i++){
					if(!msmtRels.findWhere({'definition':measureRel.at(i)})){
						self.junkMeasureRelationshipDetails.push(measureRel.at(i).get('name'));
						self.junkObjects.push(measureRel.at(i));
					}
				}
				
			}
        };

		checkCalculationMissing(valueMsmt,msmtRels){
			var self = this;
			var sum = '';
			var measure = valueMsmt.get('observedMeasure').get('measure');
			var acc = measure.get('accumulator');
			for(var i=0;i<msmtRels.length;i++){
				msmtRels.at(i).get('value');
				
			}
			if(valueMsmt.get('value') == sum){
				self.junkMeasureRelationshipDetails.push(valueMsmt.getParent().get('name'));
			}
		};
		
		checkPlanValues(alt,timestamp){
			var self = this;
			var previousPhasePrimary = alt.getPreviousPhasePrimary();
			var planCriterionSet = self.model.getOrCreatePlanCriterionSet();
			self.checkJunkMeasureRelationShips();
			var planComponents = planCriterionSet.get('component');
			var scenario = alt.getOrCreatePhaseObjectiveSet();
			planComponents.each(function(comp){
				var valueMsmt = self.getComponentMeasurement(comp,'valueMeasurement',scenario,timestamp);
				if(valueMsmt && !timestamp){
					var valMeasure = valueMsmt.get('observedMeasure').get('measure');
					if(valueMsmt.get('type') === "smm_DirectMeasurement"){
						//TODO
					}else if(valueMsmt.get('type') === "smm_CollectiveMeasurement"){
						var msmtRelationships = valueMsmt.get('measurementRelationships');
						if(msmtRelationships.length < 1){// can be agg to non-primed values
							//self.planValueDetails.push(comp.get('name'));
						}else {
							//self.checkCalculationMissing(valueMsmt,msmtRelationships);
							self.checkBaseMeasurementMissing(valueMsmt);
                            self.checkValueFormulaMissing(valueMsmt);
                            self.compareValueFormula(valueMsmt);
							self.checkAccumulatorMissing(valueMsmt);
							self.checkMeasureRelationReused(msmtRelationships);
							self.checkUnitMissing(valueMsmt);
							self.checkJunkMeasurementRelationShips(comp,valueMsmt,msmtRelationships);
							for(var i=0;i<msmtRelationships.length;i++){
								self.checkUsePreviousAssociation(msmtRelationships.at(i),previousPhasePrimary);
								self.checkDefinitionMissing(msmtRelationships.at(i));
				        		self.checkDefinitionAssociation(msmtRelationships.at(i));
				        	}
							var cyclePath = valueMsmt.getCyclicPath();
							if (cyclePath && cyclePath.length > 0 && !comp.get('isIterative')) {
								self.definitionMissingDetails.push(" Wrong Cycle Val: "+comp.get('name'));
							}
						}
					}
				}
				var satMsmt = self.getComponentMeasurement(comp,'satisfactionLevel',scenario,timestamp);
				if(satMsmt){
					//var satMeasure = satMsmt.get('observedMeasure').get('measure');
					var valSatisfaction = satMsmt.get('symbol') ? satMsmt.get('symbol') : satMsmt.get('value');
					if(!valSatisfaction) {
						self.satisfactionCalMissing.push(comp.get('name'));
					}
					var satMsmtRel = satMsmt.get('measurementRelationships');
					if((satMsmt.get('type') == "smm_RankingMeasurement" || satMsmt.get('type') == "smm_GradeMeasurement") && satMsmtRel.length > 0){
						var satMeasure = satMsmt.get('observedMeasure').get('measure');
						var satMsRel = satMeasure.get('measureRelationships');
						var valMeasure = valueMsmt?valueMsmt.get('observedMeasure').get('measure'):null;
						if(satMsmt.get('type') == "smm_RankingMeasurement"){
							if(!satMsmt.get('rankingTo')){
								var msgText = "RankingTo Missing: "+comp.get('name');
								if(timestamp){
									msgText = msgText + " Sc:" + obsName
								}
								self.satisfactionMissingDetails.push(msgText);
								self.satisfactionMissingFix.push(satMsmt.id);
							}
						} else {
							if(!satMsmt.get('gradeTo')){
								var msgText = "gradeTo Missing: "+comp.get('name');
								if(timestamp){
									msgText = msgText + " Sc:" + obsName
								}
								self.satisfactionMissingDetails.push(msgText);
								self.satisfactionMissingFix.push(satMsmt.id);
							}
						}
						for(var i=0;i<satMsRel.length;i++){
							if(!satMsRel.at(i).get('to')) {
								self.satisfactionMeasureDetails.push('Relation to missing:' + satMsRel.at(i).get('name'));
							}
							if(satMsRel.at(i).get('to') && satMsRel.at(i).get('to') != valMeasure) {
								self.satisfactionMeasureDetails.push('Satifaction Measure Wrong:' + satMsRel.at(i).get('to').get('name') + " instead of "+valMeasure.get("name"));
							}
						}
						if(valueMsmt) {//to void gr only
							for(var i=0;i<satMsmtRel.length;i++){
								if(!satMsmtRel.at(i).get('to')) {
									self.satisfactionMissingDetails.push('Relation to missing:' + satMsmtRel.at(i).get('name'));
									self.satisfactionMissingFix.push(satMsmt.id);
								}
							}
						}
					} else if(satMsmtRel && satMsmtRel.length == 0){
						self.satisfactionMissingDetails.push('Relation missing:' + comp.get('name'));
						self.satisfactionMissingFix.push(satMsmt.id);
					}
				}
				//var weightMsmt = self.getComponentMeasurement(comp,'percentageWeight',scenario);
				//var recipentMsmt = self.getComponentMeasurement(comp,'recipientOpinion',scenario);
			});
		};

		fixSatisfaction(){
			var self = this;
			for (var i = 0; i < self.satisfactionMissingFix.length; i++) {
				var satMstId = self.satisfactionMissingFix[i];
				var satMst = window.utils.getElementModel(satMstId,['smm.RankingMeasurement','smm.GradeMeasurement']);
				if(satMst){
					var satMsmtRel = satMst.get('measurementRelationships');
					var rel = satMsmtRel?satMsmtRel.at(0):null;
					if(rel){
						if(rel && satMst.get('type') == "smm_RankingMeasurement" && !satMst.get('rankingTo')){
							satMst.set('rankingTo',rel);
							satMst.calculate();
						}
						else if(rel && !satMst.get('gradeTo')){
							satMst.set('gradeTo',rel);
							satMst.calculate();
						} else if(satMst.get('gradeTo') && !rel.get('to')){
							var comp = satMst.get('measuredCharacteristic').getParent();
							var scenario = self.currentAlternativeModel.getMainScenario(comp.getNestedParent());
							var valueMsmt = self.getComponentMeasurement(comp, 'valueMeasurement', scenario);
							rel.set('to',valueMsmt);
						}
					} else {
						var comp = satMst.get('measuredCharacteristic').getParent();
						//var scenario = self.currentAlternativeModel.getMainScenario(comp.getNestedParent());
                		var valueMsmt = self.getComponentMeasurement(comp, 'valueMeasurement', satMst.getParent().getParent().get("phaseObjectiveSet"))
						if(valueMsmt && satMst.get('type') == "smm_RankingMeasurement"){
							var relName = satMst.get('name') + "RankingRel"; 
							satMst.createRankingMesurementRelationship(relName,relName,valueMsmt);	
						}else if(satMst.get('type') == "smm_GradeMeasurement"){
							var relName = satMst.get('name') + "GradeRel";
							satMst.createGradeMesurementRelationship(relName,relName,valueMsmt);
						}
					}
				}
			}
			self.satisfactionMissingDetails([]);
			fnon.Hint.Light("fixed",{displayDuration: 1500});
		}
		
		checkAccumulatorMissing(valueMsmt){
			var self = this;
			if(valueMsmt.get('measurementRelationships').length > 0) {
				var measure = valueMsmt.get('observedMeasure').get('measure');
				if(!measure.get('accumulator')){
					self.accumulatorMissingDetails.push(valueMsmt.get('measuredCharacteristic').getParent().get('name'));
				}
			}
		};
		
		checkBaseMeasurementMissing(valueMsmt){
			var self = this;
			var mstmRels = valueMsmt.get('measurementRelationships');
			if(mstmRels.length != valueMsmt.get('baseMeasurements').length) {
				var msmtToArray = [];
				for(var i=0;i<mstmRels.length;i++){
					var msmtTo = mstmRels.at(i).get('to');
					if(msmtTo){
						msmtToArray.push(msmtTo);
					}
				}
				msmtToArray = _.uniq(msmtToArray);
				if(msmtToArray.length != valueMsmt.get('baseMeasurements').length){
					self.baseMeasurementsMissingDetails.push(valueMsmt.get('measuredCharacteristic').getParent().get('name'));
				}
			}
		};
		
		checkUsePreviousAssociation(msmtRelationship,previousPhasePrimary){
			var self = this;
			var definition = msmtRelationship.get('definition');
			var usePre = definition? definition.get('usePreviousAlternative') : false;
			if(usePre == "false"){
				definition.set('usePreviousAlternative',false);
				self.usePreviousBoolean.push(msmtRelationship.get('name'));
			}
        	if(definition && definition.get('usePreviousAlternative')){
	        	var toMsmt = msmtRelationship.get('to');
	        	var toMeasure = definition.get('to');
	        	if(toMsmt && toMeasure){
	        		var scenario = toMsmt.get('observedMeasure').get('observation').getContext();
	        		var phaseobjectiveSetOwner = scenario.get('phaseObjectiveSetOwner');
	        		var scenarioExists = false;
	        		if(phaseobjectiveSetOwner && phaseobjectiveSetOwner === previousPhasePrimary){
						scenarioExists = true;	        			 
	        		}else {
	        			scenarioExists = previousPhasePrimary.get('designScenario').findWhere({'scenario':scenario.id});
	        		}
	        		if(!scenarioExists || toMsmt.get('observedMeasure').get('measure') !== toMeasure){
						self.usePreviousMissingDetails.push(msmtRelationship.get('name'));	        			
	        		}
	        	}
        	}
		};
		
		checkMeasureRelationReused(msmtRelationships){
			var self = this;		
			for(var i=0;i<msmtRelationships.length;i++){
				var def = msmtRelationships.at(i).get('definition');
				if(def){
					var dup = _.filter(msmtRelationships.models,function(rel){if(rel != msmtRelationships.at(i) && rel.get('definition') === def){return rel}});
					if(dup && dup.length > 0){
						self.measureRelationReused.push(msmtRelationships.at(i).get('name'));
					}
				}
        	}
		};
		checkUnitMissing(valueMsmt){
			var self = this;
			var measure = valueMsmt.get('observedMeasure') ? valueMsmt.get('observedMeasure').get('measure') : null;
			if(measure && !measure.get('unit')){
				self.unitMissing.push(valueMsmt.get('measuredCharacteristic').getParent().get('name'))
			}
		}
		checkValueFormulaMissing(valueMsmt){
			var self = this;
			//if(valueMsmt.get('measurementRelationships').length > 1) {
				var measure = valueMsmt.get('observedMeasure').get('measure');
                if (!measure.get('formula') && !valueMsmt.get('measuredCharacteristic').get('valueFormula') && !valueMsmt.getParent().get('valueFormula')){
					self.valueFormulaMissingDetails.push(valueMsmt.get('measuredCharacteristic').getParent().get('name'));
				}
			//}
        };

        compareValueFormula(valueMsmt) {
            var self = this;
			var mstRel = valueMsmt.get('measurementRelationships');
            if (mstRel.length > 0) {
                var givenFormula = '';
                var expectedFormula = '';
                var measure = valueMsmt.get('observedMeasure').get('measure');
                var acc = measure.get('accumulator');
                var accSymbol = '+';
                if (acc && acc.name == 'product') {
                    accSymbol = '*'
                } else if (acc && acc.name == 'average') {
                    accSymbol = 'Average'
                } else if (acc && acc.name == 'maximum') {
                    accSymbol = 'Maximum'
                } else if (acc && acc.name == 'minimum') {
                    accSymbol = 'Minimum'
                } else if (acc && acc.name == 'standardDeviation') {
                    accSymbol = 'Standard Deviation'
                } else if (acc && acc.name == 'WeightedAverage') {
                    accSymbol = 'Weighted Average'
                }
                if (accSymbol !== '*' && accSymbol !== '+') {
                    expectedFormula = expectedFormula.concat(accSymbol + ' {');
                }
				var removedAcc = false;
                _.each(mstRel.models, function (msmtRel, index, list) {
					var initialOperation = false;
					var brackets = false;
                    var reScale = msmtRel.get('definition')?msmtRel.get('definition').get('rescaledMeasure'):null;
                    if (reScale && reScale.get('operation')) {
                        var opSymbol = '';
                        var opName = reScale.get('operation').get('name');
                        if (opName == 'InvertOperation' || opName == 'invertOperation') {
                            opSymbol = '/';
                            var checkPreviousAccSymbol = expectedFormula[expectedFormula.length - 1];
                            if (checkPreviousAccSymbol && !removedAcc && checkPreviousAccSymbol == '*' || checkPreviousAccSymbol == '+') {
                                expectedFormula = expectedFormula.slice(0, -2);
								removedAcc = true;
                            }
                        } else if (opName == 'SquareOperation' || opName == 'squareOperation') {
                            opSymbol = '*2';
                        } else if (opName == 'SquareRootOperation' || opName == 'squareRootOperation') {
                            opSymbol = 'Sqrt';
                        }
                        if(expectedFormula.length != 0 || opSymbol == 'Sqrt'){
							var checkPreviousAccSymbol = opSymbol == '/' ? expectedFormula[expectedFormula.length - 1]:null;
							if(checkPreviousAccSymbol != "*"){
								expectedFormula = expectedFormula.concat(' ' + opSymbol);
							}
						}else if(mstRel.length == 1 && opSymbol == '/'){
							expectedFormula = (' 1 / (' ).concat(expectedFormula);
							brackets = true;
						} else {
							initialOperation = true;
						}
                    }
					if(msmtRel.get('to') && msmtRel.get('to').get('measuredCharacteristic')) {
						expectedFormula = reScale ? expectedFormula.concat(' ('):expectedFormula;
						expectedFormula = expectedFormula.concat(' ' + msmtRel.get('to').get('measuredCharacteristic').getParent().get('name'));
					}
					if(msmtRel.get('definition') && msmtRel.get('definition').get('usePreviousAlternative')){
						expectedFormula = expectedFormula.concat(' (previous Phase)');
					}
					if(opSymbol && initialOperation && !removedAcc){
						expectedFormula = expectedFormula.concat(' ' + opSymbol);
					}
                    if (reScale) {
						
                        if (reScale.get('multiplier') != 1) {
                            var mul = '' + reScale.get('multiplier');
                            /*if (mul.includes(".") && Number(mul) < 1) {
                                var newMul = '';
                                var multArr = mul.split(".");
                                newMul = newMul.concat(multArr[1][multArr[1].length - 1]);
                                for (var i = 0; i < multArr[1].length; i++) {
                                    newMul = newMul.concat('0');
                                }
								if(mul.charAt(0) == "-"){
									var pos = expectedFormula.lastIndexOf('+');
									if (pos != '-1') {
										expectedFormula = expectedFormula.substring(0, pos) + '-' + expectedFormula.substring(pos + 1);
									} else {
										var pos = expectedFormula.lastIndexOf(''+msmtRel.get('to').get('measuredCharacteristic').getParent().get('name'));
										if (pos != '-1') {
											brackets = true;
											expectedFormula = expectedFormula.substring(0, pos) + '(1- ' + expectedFormula.substring(pos);
										}
									}
								}
								expectedFormula = expectedFormula.concat(' / ' + newMul);
                                
                            } else*/ if (mul == '-1' && accSymbol == '+') {
                                var pos = expectedFormula.lastIndexOf('+');
                                if (pos != '-1') {
                                    expectedFormula = expectedFormula.substring(0, pos) + '-' + expectedFormula.substring(pos + 1);
                                } else {
                                    expectedFormula = expectedFormula.concat(' * ' + reScale.get('multiplier'));
                                }
                                
                            } else {
                                expectedFormula = expectedFormula.concat(' * ' + reScale.get('multiplier'));
                            }
                        }

                        if (reScale.get('offset') != 0) {
                            expectedFormula = expectedFormula.concat(' + ' + reScale.get('offset'));
                        }
						if(brackets){
							expectedFormula = expectedFormula.concat(' )');
						}
						expectedFormula = reScale ? expectedFormula.concat(' )'):expectedFormula;
                    }
                    if (index != list.length - 1) {
                        if (accSymbol == '*' || accSymbol == '+') {
                            expectedFormula = expectedFormula.concat(' ' + accSymbol);
                        } else {
                            expectedFormula = expectedFormula.concat(' ,');
                        }
                    } else {
                        if (accSymbol !== '*' && accSymbol !== '+') {
                            expectedFormula = expectedFormula.concat(' }');
                        }
                    }
                });
                if (measure) {
                    givenFormula = valueMsmt.getParent().get('valueFormula');
                    if (!givenFormula) {
                        givenFormula = valueMsmt.get('measuredCharacteristic').get('valueFormula');
                        if (!givenFormula) {
                            givenFormula = '';
                        }
                    }
                }
                if (givenFormula.trim() != expectedFormula.trim()) {
                    self.compareValueFormulaDetails.push({ 'valueName': valueMsmt.get('measuredCharacteristic').getParent().get('name'), 'givenFormula': givenFormula, 'expectedFormula': expectedFormula });
                }
            }
        }
		
		
		

		
		
		afterRenderView(node, view){
            var self = view; //bootbox.alert('hi');
			self.checkPhaseDetails();
			if(DataManager.getDataManager().get('readMode')){
				self.autoSaveTimer("0");
			}
			/*var milSec = DataManager.getDataManager().get('autoSaveTimer');
			if(!DataManager.getDataManager().get('autoSave')){
				self.autoSaveTimer("0");
			}
			else if(milSec){
				self.autoSaveTimer(milSec/1000);
			}	*/		
			 /*$("#timer").keyup(function (view) {
                var name = view.target.value;
                if (self.existingValueModal == null) {
                    self.getUniqueValue(name);
                }
             });*/
		};
	static getInstance(model,options){
		var view = new PlanValidationViewModel(model, options);
		view.init(model, options);
		return view;
	};
}
	path.PlanValidationViewModel = PlanValidationViewModel;