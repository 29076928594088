import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {Measurement} from './Measurement'
import {MeasurementMixin} from './MeasurementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/Measurement","appbo/smm/MeasurementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Measurement,MeasurementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	export class DimensionalMeasurementMixin {
        /*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            path.DimensionalMeasurementMixin = DimensionalMeasurementMixin;
        }*/
	defaults(){
		var ret = {
			type: "smm_DimensionalMeasurement"
		}
		return jQuery.extend(Measurement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!DimensionalMeasurementMixin.cummulativeRelations) {
            DimensionalMeasurementMixin.cummulativeRelations = _.union(DimensionalMeasurementMixin.getMixinRelations()
                , MeasurementMixin.getCumulativeMixinRelations()
            );
        }
		return DimensionalMeasurementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_Measurement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "error",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			{name : "breakValue",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" }
			/*{name : "value",type : "EDouble",defaultValue : "null",containingClass : "smm_DimensionalMeasurement" }*/
		]
	}
	getParent(){
		var Measurement = Backbone.Relational.store.getObjectByName("smm.Measurement");
		return Measurement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var Measurement = Backbone.Relational.store.getObjectByName("smm.Measurement");
		return Measurement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/DimensionalMeasurementPropertiesTemplate.html",
			templateName : "DimensionalMeasurementPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/DimensionalMeasurementViewModel",
			tabId : "DimensionalMeasurementView",
			tabName: "DimensionalMeasurement"
		}
	}
//#startCustomMethods	

//#endCustomMethods	
    }
	path.DimensionalMeasurementMixin = DimensionalMeasurementMixin;
	
	//return DimensionalMeasurementMixin;
//});