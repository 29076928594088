import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmRelationship} from './SmmRelationship'
import {SmmRelationshipMixin} from './SmmRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { MeasureLibrary } from './MeasureLibrary'
import { MeasureCategory } from './MeasureCategory'
import { AbstractMeasureElement } from './AbstractMeasureElement' 

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmRelationship","appbo/smm/SmmRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmRelationship,SmmRelationshipMixin
, MeasureLibrary
, MeasureCategory
, AbstractMeasureElement){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!MeasureLibrary){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.MeasureLibrary){
			MeasureLibrary = importPath.MeasureLibrary;
		}
        else {
            import('./MeasureLibrary').then(({ default: MeasureLibrary }) => {
                MeasureLibrary = MeasureLibrary;
            });

			/*require(["appbo/smm/MeasureLibrary"],function(loadedModule){
				MeasureLibrary = loadedModule;
			});*/
		}
	}
	if(!MeasureCategory){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.MeasureCategory){
			MeasureCategory = importPath.MeasureCategory;
		}
        else {
            import('./MeasureCategory').then(({ default: MeasureCategory }) => {
                MeasureCategory = MeasureCategory;
            });

			/*require(["appbo/smm/MeasureCategory"],function(loadedModule){
				MeasureCategory = loadedModule;
			});*/
		}
	}
	if(!AbstractMeasureElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.AbstractMeasureElement){
			AbstractMeasureElement = importPath.AbstractMeasureElement;
		}
        else {
            import('./AbstractMeasureElement').then(({ default: AbstractMeasureElement }) => {
                AbstractMeasureElement = AbstractMeasureElement;
            });

			/*require(["appbo/smm/AbstractMeasureElement"],function(loadedModule){
				AbstractMeasureElement = loadedModule;
			});*/
		}
	}
	export class CategoryRelationshipMixin {
        /*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            path.CategoryRelationshipMixin = CategoryRelationshipMixin;
        }*/
	defaults(){
		var ret = {
			type: "smm_CategoryRelationship"
		}
		return jQuery.extend(SmmRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"smm_CategoryRelationship",
			key:"to",
			relatedModel:"smm.AbstractMeasureElement",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!CategoryRelationshipMixin.cummulativeRelations) {
            CategoryRelationshipMixin.cummulativeRelations = _.union(CategoryRelationshipMixin.getMixinRelations()
                , SmmRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return CategoryRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_SmmRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("libraries") ? this.get("libraries") : this.previousAttributes().libraries;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("libraries") ? this.get("libraries") : this.previousAttributes().libraries;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/CategoryRelationshipPropertiesTemplate.html",
			templateName : "CategoryRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/CategoryRelationshipViewModel",
			tabId : "CategoryRelationshipView",
			tabName: "CategoryRelationship"
		}
}
}
	path.CategoryRelationshipMixin = CategoryRelationshipMixin;
	
	//return CategoryRelationshipMixin;
//});