import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BMElement} from './BMElement'
import {BMElementMixin} from './BMElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BMElement","appbo/vdml/BMElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BMElement,BMElementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class BusinessModelStateMixin {

	defaults(){
		var ret = {
			type: "vdml_BusinessModelState"
		}
		return jQuery.extend(BMElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!BusinessModelStateMixin.cummulativeRelations) {
            BusinessModelStateMixin.cummulativeRelations = _.union(BusinessModelStateMixin.getMixinRelations()
                , BMElementMixin.getCumulativeMixinRelations()
            );
        }
		return BusinessModelStateMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_BMElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		return BMElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return BMElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BusinessModelStatePropertiesTemplate.html",
			templateName : "BusinessModelStatePropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BusinessModelStateViewModel",
			tabId : "BusinessModelStateView",
			tabName: "BusinessModelState"
		}
	}
	
	}
	path.BusinessModelStateMixin = BusinessModelStateMixin;
	//return BusinessModelStateMixin;
//});