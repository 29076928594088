
import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
//import * as DataTable from '../../../../../../libs/DataTable/jquery.dataTables'
import * as async from 'async'
import * as jstree from '../../../../../../libs/jstree/jstree'
//import * as d3 from '../../../../../../libs/d3/d3.min'
//import {select, selectAll} from 'd3-selection';
//import {scaleOrdinal, scaleLinear} from 'd3-scale';
//import {RadarChart} from '../../../../../../libs/d3/RadarChart'
import * as multiselect from '../../../../../../libs/bootstrap-multiselect/js/bootstrap-multiselect'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import { PlanValuesChart } from "./PlanValuesChart";

var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties",global.version);

export class PlanValues2Chart extends PlanValuesChart{
    static getInstance(model,options){
		var view = new PlanValues2Chart(model, options);
		//view.init(model, options);
		return view;
	};
    getPlanValues(plan){
		var self = this;
		var vp = plan.get('planCriterionSet');
		if(vp){
			var components = vp.get('component');
			components.each(function(valueComponent){
				//var value = valueComponent.get('valueMeasurement');
                var value = valueComponent;
				self.values.push(value);
			});
		}
		return self.values;
	};

    getValueMeasurements(alts,callback){
		var self = this;
		var dataSet = {};
		var hasMoreThanOneValue = false;
		self.margin = 0.05;
		self.altIndex = {};
		var selectedChartType = self.selectedChartType();
		self.showBars = selectedChartType === "Bars" || selectedChartType === "Stacked bars" || selectedChartType === "Columns" || selectedChartType === "Stacked columns"? true : false;
		if(!self.alts){
			callback();
    		return;
    	}
		async.each(self.values,function(val,valHandleCallback){
			var measurandData = {};
			function addMeasurandData(unit){
				var valueData = {};
				//valueData.label = val.getMeasurand().get('name');
                valueData.label = val.get('name');
				//valueData.valueName = val.getMeasurand().get('name');
                valueData.valueName = val.get('name');
				valueData.data = [];
				measurandData[unit] = valueData;
				return valueData;
			}
			
			async.eachSeries(alts,function(alt,altHandleCallback){
				var unit = "";
				function pushEmpty(valueData){
					altHandleCallback();
				}
                var mainScenario = DataManager.getDataManager().get("defaultScenario");
                
				if(!mainScenario){
					pushEmpty();
					return;
				}
                //TODO get measurements
				// var mests = mainScenario.getMeasurementsReferringToMeasuredCharteristic(val);
				// if(mests.length == 0){
				// 	var satMc = val.getParent().get('satisfactionLevel');
				// 	mests = mainScenario.getMeasurementsReferringToMeasuredCharteristic(satMc);
				// }
				// _.each(mests, function (mest) {
				//     var valData = [];
				//     var planName = alt.get('phaseAlternativeOwner').get('name');
				//     var altName = alt.get('name');
				//     var altProp = planName + " / " + altName;
				//     var whenObs = mest.get('observedMeasure').get('observation').get('whenObserved');
				//     if (whenObs != null) {
				//         altProp = altProp + "(" + whenObs+ ")";
				//     }
				//     if (_.indexOf(self.xAxisSelected, alt.id + (whenObs != null ? whenObs : "")) < 0) {
				//         return;
                //     }
				//     if (_.has(self.altIndex, altProp)) {
				//         valData.push("Alt-" + self.altIndex[altProp]);
				//     } else {
				//         self.altIndex[altProp] = (_.keys(self.altIndex)).length;
				//         valData.push("Alt-" + self.altIndex[altProp]);
				//     }
				//     //valData.push(planName+ " / " +altName);
				//     if (mest && mest.get('observedMeasure').get('measure') && (mest.get('value') !== null || mest.get('symbol') !== null)) {
				//         unit = mest.get('observedMeasure').get('measure').get('unit');
				//         if (mest.get('value') === Number.MAX_VALUE || mest.get('value') === '') {
				//             valData.push(null);
				//         } else {
				//         	if(mest.get('value')) {
				//         		valData.push(parseFloat(mest.get('value')));
				//         	}else {
				//         		valData.push(mest.get('symbol'));
				//         	}
				            
				//         }
				//         var selectedInterval = val.getParent().getSatisfactionInterval(mainScenario);
				// 		var smileySrc = '';
				// 		var smileyColor = '';
				// 		var correctiveActions = '';
				// 		if(selectedInterval){
				// 			smileySrc = val.getParent().fetchSmileySrc(selectedInterval.get('smiley'));
		        //         	smileyColor = selectedInterval.get('smileyColor');
		        //         	correctiveActions = selectedInterval.get('correctiveActions');
		        //         }
		        //         valData.push(smileySrc);
		        //         valData.push(smileyColor);
		        //         valData.push(alt.id);
		        //         valData.push(val.getParent().id);
		        //         valData.push(correctiveActions);
				//     } else {
				//         valData.push(null);
				//     }
				//     var valueData;
				//     if (mest) {
				//     	var unitName;
				//         if (unit) {
				//            unitName = window.utils.replaceEscapeCharacters(unit.get('name'));
				//         }/*else {
				//         	unitName = 'SatisfactionOnly';
				//         }*/
				         
				//         valueData = measurandData[unitName];
				//         if (!valueData) {
				//             valueData = addMeasurandData(unitName);
				//             valueData.significantDecimals = unit?unit.get('significantDecimals'):'2';
				//             var label = valueData.label;
				//             if(unitName){
				//             	label = label.concat(" (" + unitName + ")");
				//             }
				//             valueData.label = label;
				//             valueData.unit = unitName?unitName:'Grade only';
				//         }
				//         valueData.data.push(valData);
				//     } else {
				//         valueData = addMeasurandData("");
				//         valueData.data.push(valData);
				//     }
				// });

				//valueData.data.push(valData);
				altHandleCallback();
			},function(){
				var emptyData = measurandData[""];
				if(emptyData){
					delete measurandData[""];
				}
				
				Object.getOwnPropertyNames(measurandData).forEach(function(key) {
					var valueCount =0;
					var valueData = measurandData[key];
					if(emptyData){
					  	valueData.data = valueData.data.concat(emptyData.data);
					}
					for(var i=0;i<valueData.data.length;i++){
						if(valueData.data[i][1] != null){
							valueCount++;	
						}
					}
					var unitString = window.utils.replaceEscapeCharacters(valueData.unit);
				  	dataSet[val.get('id') + unitString ] = valueData;
					if(valueCount > 1){
						hasMoreThanOneValue = true;
					}				  	
				});				
				valHandleCallback();
			});	
		},function(){
			/*if(!self.showBars && !hasMoreThanOneValue){
				self.showBars = true;
			}*/
			if(self.showBars && !hasMoreThanOneValue){
				self.margin = 0.5;
			}
			self.datasets = dataSet;
			callback();
		});
	};
}
path.PlanValues2Chart = PlanValues2Chart;