import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {Participant} from './Participant'
import {Role} from './Role'
import {InputPort} from './InputPort'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, Participant
, Role
, InputPort){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Participant){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Participant){
			Participant = importPath.Participant;
		}
		else{
			import('./Participant').then(({ default: Participant }) => {
                Participant = Participant;
            });
			/*require(["appbo/vdml/Participant"],function(loadedModule){
				Participant = loadedModule;
			});*/
		}
	}
	if(!Role){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Role){
			Role = importPath.Role;
		}
		else{
			import('./Role').then(({ default: Role }) => {
                Role = Role;
            });
			/*require(["appbo/vdml/Role"],function(loadedModule){
				Role = loadedModule;
			});*/
		}
	}
	if(!InputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.InputPort){
			InputPort = importPath.InputPort;
		}
		else{
			import('./InputPort').then(({ default: InputPort }) => {
                InputPort = InputPort;
            });
			/*require(["appbo/vdml/InputPort"],function(loadedModule){
				InputPort = loadedModule;
			});*/
		}
	}
	export class AssignmentMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.AssignmentMixin = AssignmentMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_Assignment"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Assignment",
			key:"roleResource",
			relatedModel:"vdml.InputPort",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_Assignment",
			key:"participant",
			relatedModel:"vdml.Participant",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}		
		])
	}
	
	static getCumulativeMixinRelations(){
		if (!AssignmentMixin.cummulativeRelations) {
            AssignmentMixin.cummulativeRelations = _.union(AssignmentMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return AssignmentMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("contextBasedAssignmentOwner") ? this.get("contextBasedAssignmentOwner") : this.previousAttributes().contextBasedAssignmentOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("ownedAssignmentOwner") ? this.get("ownedAssignmentOwner") : this.previousAttributes().ownedAssignmentOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("contextBasedAssignmentOwner") ? this.get("contextBasedAssignmentOwner") : this.previousAttributes().contextBasedAssignmentOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("ownedAssignmentOwner") ? this.get("ownedAssignmentOwner") : this.previousAttributes().ownedAssignmentOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/AssignmentPropertiesTemplate.html",
			templateName : "AssignmentPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/AssignmentViewModel",
			tabId : "AssignmentView",
			tabName: "Assignment"
		}
	};
	
	copy(sourceContainer,targetContainer,sourceAssignedRole,targetAssignedRole){
		var asgntId = DataManager.getDataManager().guidGeneratorByOwner(targetContainer);
		var targetType = targetContainer.get('type');
		var asgntCopy;
		if(targetType === 'vdml_Scenario' || targetType === 'vdml_DelegationContext'){
			asgntCopy = new this.constructor({id:asgntId,parentContext:targetContainer,name:targetContainer.getUniqueProperty('name',this.get('name'),'contextBasedAssignment')});
		}else{
			asgntCopy = new this.constructor({id:asgntId,ownedAssignmentOwner:targetContainer,name:targetContainer.getUniqueProperty('name',this.get('name'),'ownedAssignment')});
		}
		var participant = this.get('participant');
		if(participant){
			asgntCopy.set('participant',participant);
		}
		if(sourceAssignedRole !== targetAssignedRole){
			asgntCopy.set('assignedRole',targetAssignedRole);
		}else{
			asgntCopy.set('assignedRole',sourceAssignedRole);
		}
		var roleResources = this.get('roleResource');
		roleResources.each(function(roleResource){
			asgntCopy.get('roleResource').add(roleResource);
		});
		return asgntCopy;
	};
	
	}
	path.AssignmentMixin = AssignmentMixin;
	//return AssignmentMixin;
//});