import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from '../vdml/VdmlElement'
import {LookupTableMixin} from './LookupTableMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("lookup",global.version);

var LookupTable = VdmlElement.extend(utils.customExtends({
    relations:LookupTableMixin.getMixinRelations(),
    subModelTypes: {
        lookup_PatternLookupTableDefinition : 'lookup.PatternLookupTableDefinition',
        lookup_DecisionTable : 'lookup.DecisionTable'
    },
    initialize: function(attributes, options) {
        VdmlElement.prototype.initialize.apply(this, arguments);
        
    }
    }
    , new LookupTableMixin()
));
LookupTable.abstract = true;
path.LookupTable = LookupTable;
export {LookupTable};
