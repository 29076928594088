import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasure} from './DimensionalMeasure'
import {DimensionalMeasureMixin} from './DimensionalMeasureMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { Base1MeasureRelationship } from './Base1MeasureRelationship'
import { Operation } from './Operation'
import { Base2MeasureRelationship } from './Base2MeasureRelationship'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasure","appbo/smm/DimensionalMeasureMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasure,DimensionalMeasureMixin
, Base1MeasureRelationship
, Base2MeasureRelationship
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Base1MeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Base1MeasureRelationship){
			Base1MeasureRelationship = importPath.Base1MeasureRelationship;
		}
        else {
            import('./Base1MeasureRelationship').then(({ default: Base1MeasureRelationship }) => {
                Base1Base1MeasureRelationship = Base1MeasureRelationship;
            });

			/*require(["appbo/smm/Base1MeasureRelationship"],function(loadedModule){
				Base1MeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!Base2MeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Base2MeasureRelationship){
			Base2MeasureRelationship = importPath.Base2MeasureRelationship;
		}
        else {
            import('./Base2MeasureRelationship').then(({ default: Base2MeasureRelationship }) => {
                Base2Base1MeasureRelationship = Base2MeasureRelationship;
            });

			/*require(["appbo/smm/Base2MeasureRelationship"],function(loadedModule){
				Base2MeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
        else {
            import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });

			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
export class BinaryMeasureMixin {
    /*constructor() {
        var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
        path.BinaryMeasureMixin = BinaryMeasureMixin;
    }*/

	defaults(){
		var ret = {
			type: "smm_BinaryMeasure"
		}
		return jQuery.extend(DimensionalMeasure.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"smm_BinaryMeasure",
			key:"customFunctor",
			relatedModel:"smm.Operation",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!BinaryMeasureMixin.cummulativeRelations) {
            BinaryMeasureMixin.cummulativeRelations = _.union(BinaryMeasureMixin.getMixinRelations()
                , DimensionalMeasureMixin.getCumulativeMixinRelations()
            );
        }
		return BinaryMeasureMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_DimensionalMeasure"
		];
	}
	static getProperties(){
		return [
			{name : "version",type : "EString",defaultValue : "null",containingClass : "cmof_EObject" },
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
		    {name : "scale",type : "ScaleOfMeasurement",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "customScale",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "source",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "visible",type : "EBoolean",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "formula",type : "EString",defaultValue : "null",containingClass : "smm_DimensionalMeasure" },
			{name : "functor",type : "BinaryFunctor",defaultValue : "null",containingClass : "smm_BinaryMeasure" }
		]
	}
	getParent(){
		var container;
		return DimensionalMeasure.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return DimensionalMeasure.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/BinaryMeasurePropertiesTemplate.html",
			templateName : "BinaryMeasurePropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/BinaryMeasureViewModel",
			tabId : "BinaryMeasureView",
			tabName: "BinaryMeasure"
		}
	}
	
	}
	path.BinaryMeasureMixin = BinaryMeasureMixin;
	//return BinaryMeasureMixin;
//});