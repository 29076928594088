import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {CollaborationDiagram} from './CollaborationDiagram'
import {ValuePropositionDiagramMixin} from './ValuePropositionDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/ValuePropositionDiagramMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,ValuePropositionDiagramMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var ValuePropositionDiagram = CollaborationDiagram.extend(utils.customExtends({
		relations:ValuePropositionDiagramMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			CollaborationDiagram.prototype.initialize.apply(this, arguments);
		}
		}
		, new ValuePropositionDiagramMixin()
	));
	
	ValuePropositionDiagram.abstract = false;
	ValuePropositionDiagram.supported = false;
	path.ValuePropositionDiagram = ValuePropositionDiagram;
	export {ValuePropositionDiagram};
//});