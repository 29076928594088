import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {ObjectReference} from './ObjectReference'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, ObjectReference){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ObjectReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ObjectReference){
			ObjectReference = importPath.ObjectReference;
		}
		else{
			import('./ObjectReference').then(({ default: ObjectReference }) => {
                ObjectReference = ObjectReference;
            });
			/*require(["appbo/vdml/ObjectReference"],function(loadedModule){
				ObjectReference = loadedModule;
			});*/
		}
	}
	export class BindingOperationReferenceMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.BindingOperationReferenceMixin = BindingOperationReferenceMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_BindingOperationReference"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"vdml_BindingOperationReference",
			key:"bindingOperation",
			relatedModel:"vdml.ObjectReference",
			reverseRelation: {
				key:"bindingOperationOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!BindingOperationReferenceMixin.cummulativeRelations) {
            BindingOperationReferenceMixin.cummulativeRelations = _.union(BindingOperationReferenceMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return BindingOperationReferenceMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "Url",type : "EString",defaultValue : "null",containingClass : "vdml_BindingOperationReference" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("bindingOperationReferenceOwner") ? this.get("bindingOperationReferenceOwner") : this.previousAttributes().bindingOperationReferenceOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("bindingOperationReferenceOwner") ? this.get("bindingOperationReferenceOwner") : this.previousAttributes().bindingOperationReferenceOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("bindingOperationReferenceOwner") ? this.get("bindingOperationReferenceOwner") : this.previousAttributes().bindingOperationReferenceOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("bindingOperationReferenceOwner") ? this.get("bindingOperationReferenceOwner") : this.previousAttributes().bindingOperationReferenceOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BindingOperationReferencePropertiesTemplate.html",
			templateName : "BindingOperationReferencePropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BindingOperationReferenceViewModel",
			tabId : "BindingOperationReferenceView",
			tabName: "BindingOperationReference"
		}
	}
	
	}
	path.BindingOperationReferenceMixin = BindingOperationReferenceMixin;
	//return BindingOperationReferenceMixin;
//});