import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {DashboardMixin} from '../dashboard/DashboardMixin'
import {Dashboard2Mixin} from '../dashboard/Dashboard2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {VdmlElement} from '../vdml/VdmlElement'
/*define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/VdmlElement", "appbo/dashboard/DashboardMixin", "appbo/dashboard/Presentation"],
function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, VdmlElement, DashboardMixin, Presentation
) { */
    
    var path = DataManager.getDataManager().buildAppNsPath("dashboard", global.version);
	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? Dashboard2Mixin : DashboardMixin;
	}
	var mixinClass = getMixinClass();
	var Dashboard = VdmlElement.extend(utils.customExtends({
		relations:mixinClass.getMixinRelations(),
		initialize: function(attributes, options) {
		    VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new mixinClass()
	));
	
	Dashboard.getInstance = function (name, description, owner, iconType){
		var aid = DataManager.getDataManager().guidGeneratorByOwner(owner);
		var type;
		if (iconType === "callToActionArrow" || iconType === "addDiscovery" || iconType === "ecoGenerate") {
			type = "Discover";
		}
		var dashboard = new Dashboard({ id: aid, name: owner.getUniqueProperty('name', name, 'dashboard'), description: owner.getUniqueProperty('description', description, 'dashboard'), analytics: owner }, { type: type });
		
		return dashboard;
	}
	Dashboard.prototype.getSystemTags = function (options) {
		if (options && options.type == "Discover") {
			return ['VDM', 'Dashboard', 'Discover'];
		} else {
			return ['VDM', 'Dashboard', 'Adopt'];
		}
	};
	Dashboard['destroy'] = function (model) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(suppressChangeLogging) {
			utils.updateUIDashBoard(model,true);
		}
    }
	Dashboard['change:presentation'] = function (model) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(suppressChangeLogging) {
			utils.updateUIDashBoard(model.getParent(),false);
		}
    }
	Dashboard['add:presentation'] = function (model) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(suppressChangeLogging) {
			utils.updateUIDashBoard(model.getParent(),false);
		}
    }
	Dashboard['destroy:presentation'] = function (model) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(suppressChangeLogging) {
			utils.updateUIDashBoard(model.getParent(),false);
		}
    }
	
	Dashboard.abstract = false;
	Dashboard.supported = true;
	path.Dashboard = Dashboard;
	export {Dashboard};
	//return Dashboard;
//});