import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessItemLibraryElement} from './BusinessItemLibraryElement'
import {BusinessItemCategoryMixin} from './BusinessItemCategoryMixin'
import {BusinessItemDefinition} from './BusinessItemDefinition'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessItemLibraryElement","appbo/vdml/BusinessItemCategoryMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessItemLibraryElement,BusinessItemCategoryMixin
, BusinessItemDefinition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!BusinessItemDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemDefinition){
			BusinessItemDefinition = importPath.BusinessItemDefinition;
		}
		else{
			import('./BusinessItemDefinition').then(({ default: BusinessItemDefinition }) => {
                BusinessItemDefinition = BusinessItemDefinition;
            });
			/*require(["appbo/vdml/BusinessItemDefinition"],function(loadedModule){
				BusinessItemDefinition = loadedModule;
			});*/
		}
	}
	var BusinessItemCategory = BusinessItemLibraryElement.extend(utils.customExtends({
		relations:BusinessItemCategoryMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BusinessItemLibraryElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new BusinessItemCategoryMixin()
	));
	
	BusinessItemCategory.abstract = false;
	BusinessItemCategory.supported = true;
	path.BusinessItemCategory = BusinessItemCategory;
	export {BusinessItemCategory};
//});