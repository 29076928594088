import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","async","appcommon/com/vbee/data/DataManager","app/global"],
function(require,$,_, Backbone,ko, koMapping,kb,bootbox,async,DataManager,global)
{*/
	var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties",global.version);
	export class BMDetailsViewModel {
		
		dispose(){
			var self = this;
			//self.BMs.reset();
			window.cleanViewModel(self);		
		}; 	
		/*if(window.checkContextForRevision()) {
			self.enableDelete(false);
		}*/
	    
	    saveBMDetails(){
			var self = this;
	    	if(self.model){
	    		if(self.name().trim() != self.model.get('name')){
	    			self.model.set('name',self.name().trim());
	    			if(!self.parentView.BusinessModelViewModel){
	    				window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'),DataManager.getDataManager().get('viewAlternative'),self.BMDetailsViewModel);
	    			}
	    		}
	    		self.model.set('description',self.description());
	    	}
		};
		
		deleteBM(){
			var self = this;
			var bm = self.model;
            var bmRoles= bm.get('bmRole');
            if(bmRoles.length>0){               
                bootbox.confirm('' + DataManager.getDataManager().get('localeManager').get('MapDeleteAlert','Business Model'), function(result) {
	                if(result) {
	                	self.doBMdelete();
		            }    
				});		
			}else{
				self.doBMdelete();
			}
		}
		doBMdelete(){
			var self = this;
			var vdmPackage = self.model.getNestedParent();
			if(self.parentView.disableAllCompleteButtons){
				self.parentView.disableAllCompleteButtons();
			}
            /*var vdmStore = DataManager.getDataManager().getVDMStore(DataManager.getDataManager().get('viewAlternative'));
	         DataManager.getDataManager().fetchDocumentFromPackage(pack.id,"appbo/vdml/ValueDeliveryModel",pack.version,pack.id,"appbo/vdml/ValueDeliveryModel",vdmStore,{
	         success:function(vdmPackage){*/
			self.createRevisionBasedOnContext(vdmPackage,function(newVdmPackage){
				window.utils.startSpinner('bmDeleteSpinner','Deleting Business Model ...');
			  	var newPack = newVdmPackage;
			    if(window.checkContextForRevision()) {
					var bmId = self.model.id.substr(self.model.id.lastIndexOf('@')+1,self.model.id.length);
			        var altId = DataManager.getDataManager().getRepositoryId(newPack.id);
				}			                	
			    var bmToBeDeleted = window.checkContextForRevision() ? newPack.get('businessModel').findWhere({'id':altId+bmId}) : self.model;
			    window.setTimeout(function(){
					newPack.removeBusinessModel(bmToBeDeleted,function(){
						var dataManager = DataManager.getDataManager();
						dataManager.invokeValueUpdates(function(){
							window.utils.showSpinnerText('Saving Data ... ');
							dataManager.saveChangeSetToBackend(dataManager.get(dataManager.CURRENT_CHANGESET),function(response){
								dataManager.applyWorkspaceById(dataManager.get("currentWorkspaceId"),function(){
								//dataManager.pullServerChanges(function(){
									//dataManager.clearSaveInterval();
									window.utils.stopSpinner('bmDeleteSpinner');
									dataManager.get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + dataManager.get('viewAlternative'), {trigger: true});					                	
								});
							});
						});
					});
			    },100);
			 });                    				
	                   /* },
	                    error:function(error){
	                        console.log('Unable to load selected Package');
	                    }
	                });*/	
		}
		createRevisionBasedOnContext(pack,callback){
			if(window.checkContextForRevision()) {
				window.utils.startSpinner('revisionSpinner', "Creating a copy...");
				function fetchBmModel(newVdmPackage){
						if(newVdmPackage) {
							var altId = DataManager.getDataManager().getRepositoryId(newVdmPackage.id);
							var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
							var diagrams = newVdmPackage.get('diagrams');
							for (var i = 0; i < diagrams.length; i++) {
								newVdmPackage.fixDiagramOnRevision(diagrams.at(i), altId, oldAltId);
							}
                            DataManager.getDataManager().releaseSaveLock();
		        			DataManager.getDataManager().set('isActive',false);
							window.utils.stopSpinner('revisionSpinner');
		        			callback(newVdmPackage);
							/*DataManager.getDataManager().saveData({
								success:function(){
									window.utils.stopSpinner('revisionSpinner');
		        					callback(newVdmPackage);
								}, 
								error:function(){
									window.utils.stopSpinner('revisionSpinner');
		        					callback(newVdmPackage);
								}, 
								persist:true});	*/
						}
					}
				window.setTimeout(function(){
                    DataManager.getDataManager().acquireSaveLock(function () {
                        pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
                    });
	        	},100);	
        	}
        	else {
        		callback(pack);
        	}
		};
		checkUniqueValue(view,event){
			var self=this;
			var name = event.currentTarget.value.trim();
			if(name !== ""){
				if(self.model.get('name') === name){
					$('#duplicateName'+self.encodeId).text("");
					$("#complete").removeAttr('disabled');
					return;
				}
	    		var unique = window.isUniqueName(name,null,self.id(),self.BMs);
	    		if(unique){
	    			$('#duplicateName'+self.encodeId).text("");
	    			$("#complete").removeAttr('disabled');
	    		}
	    		else{
                    $('#duplicateName' + self.encodeId).text("" + DataManager.getDataManager().get('localeManager').get('DuplicateAlert', 'Business Model'));
	    			$("#complete").attr('disabled','disabled');
	    		}
			}
			else{
				$("#complete").attr('disabled','disabled');
			}
        }
		afterRenderView(){
		}
		/*this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['general','Alternative'
		,'version'
		,'name'
		,'description'
		,'Close'
		,'Complete'
		,'Delete'
		,'BusinessModelDuplicate'
		,'EditBusinessModel']);*/
	init(model, options){
		var self = this;
		this.BMDetailsViewModel = this;
		this.parentView = model;
		this.model = model.model;
		this.id = kb.observable(self.model, 'id');
		function htmlEscape(str) {
    		return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '');
		}
		this.encodeId = htmlEscape(this.id());
		/*this.enableRevision = ko.observable(false);*/
		//this.enableDelete = ko.observable(true);
		this.enableComplete = ko.observable(true);
		this.version = kb.observable(self.model, 'version');
		this.name = ko.observable(self.model ? self.model.get('name') : null);
		this.description =ko.observable(self.model ? self.model.get('description') : "");
		$('#modal'+self.encodeId).on('shown.bs.modal', function () {
			window.utils.focusElement("#newModal .focus-ele");
		});
		
		this.name.subscribe(function(val){
			self.description(val);
		});
		self.BMs = new Backbone.Collection;
		function fetchBMs(){
			var businessModels = self.model.getNestedParent().get('businessModel');
			for(var i=0;i<businessModels.length;i++){
				self.BMs.add(businessModels.models[i]);
			}
		}
		fetchBMs();
		
	}
	
	static getInstance(model,options){
		var view = new BMDetailsViewModel(model,options);
		view.init(model, options);
		return view;
	};
}
path.BMDetailsViewModel = BMDetailsViewModel;
