import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AnalysisContext} from './AnalysisContext'
import {AnalysisContextMixin} from './AnalysisContextMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Collaboration } from './Collaboration'
import { PortDelegation } from './PortDelegation'
import { Activity } from './Activity'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/AnalysisContext","appbo/vdml/AnalysisContextMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AnalysisContext,AnalysisContextMixin

, Collaboration
, PortDelegation
, Activity){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!AnalysisContext){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.AnalysisContext){
			AnalysisContext = importPath.AnalysisContext;
		}
        else {
            import('./AnalysisContext').then(({ default: AnalysisContext }) => {
                AnalysisContext = AnalysisContext;
            });
           /* require(["appbo/vdml/AnalysisContext"], function (loadedModule) {
                AnalysisContext = loadedModule;
			});*/
		}
	}
	if(!Collaboration){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Collaboration){
			Collaboration = importPath.Collaboration;
		}
        else {
            import('./Collaboration').then(({ default: Collaboration }) => {
                Collaboration = Collaboration;
            });
            /*require(["appbo/vdml/Collaboration"], function (loadedModule) {
                Collaboration = loadedModule;
			});*/
		}
	}
	if(!PortDelegation){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PortDelegation){
			PortDelegation = importPath.PortDelegation;
		}
        else {
            import('./PortDelegation').then(({ default: PortDelegation }) => {
                PortDelegation = PortDelegation;
            });
            /*require(["appbo/vdml/PortDelegation"], function (loadedModule) {
                PortDelegation = loadedModule;
			});*/
		}
	}
	if(!Activity){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Activity){
			Activity = importPath.Activity;
		}
        else {
            import('./Activity').then(({ default: Activity }) => {
                Activity = Activity;
            });
            /*require(["appbo/vdml/Activity"], function (loadedModule) {
                Activity = loadedModule;
			});*/
		}
	}
	export class DelegationContextMixin{

	defaults(){
		var ret = {
			type: "vdml_DelegationContext"
		}
		return jQuery.extend(AnalysisContext.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_DelegationContext",
			key:"contextBasedPortDelegation",
			relatedModel:"vdml.PortDelegation",
			reverseRelation: {
				key:"contextBasedPortDelegationOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		])
	}
	static getCumulativeMixinRelations(){
		if (!DelegationContextMixin.cummulativeRelations) {
            DelegationContextMixin.cummulativeRelations = _.union(DelegationContextMixin.getMixinRelations()
                , AnalysisContextMixin.getCumulativeMixinRelations()
            );
        }
		return DelegationContextMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_AnalysisContext"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("parentContext") ? this.get("parentContext") : this.previousAttributes().parentContext;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("parentContext") ? this.get("parentContext") : this.previousAttributes().parentContext;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/DelegationContextPropertiesTemplate.html",
			templateName : "DelegationContextPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/DelegationContextViewModel",
			tabId : "DelegationContextView",
			tabName: "DelegationContext"
		}
	};
//#startCustomMethods
	disconnectCollaboration(collaboration){
		var self = this;
		var activities = collaboration.get('activity');
		activities.each(function(activity){
			var activityDelContexts = activity.get('delegationContext').models.concat();
			_.each(activityDelContexts, function(actContext){
				actContext.disconnectCollaboration(actContext.get('contextCollaboration'));
				if(self.get('delegationContext').findWhere({id:actContext.get('id')})){
					actContext.destroy();
				}
			});
		});
	};
	
//#endCustomMethods	
	
	
	}
	path.DelegationContextMixin = DelegationContextMixin;
	//return DelegationContextMixin;
//});