import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as async from 'async'
import * as  Backbone from 'backbone'
import {RDFSchemaModel} from './RDFSchemaModel'
//import * as rdfstore from 'rdfstore'
import * as rdfstore from '../../../../libs/rdfstore-0.8.4/rdf_store'
//import * as Lawnchair from 'lawnchair'
//import * as  backboneLawnchair from 'backbonelawnchair'
//define(["jquery","underscore","async", "backbone","appcommon/com/vbee/rdf/RDFSchemaModel","backbone-relational","rdf_store","rdf_interface_api"],

//function(jQuery,_,async, Backbone, RDFSchemaModel) {
	//var rdfstore = require('rdfstore')
   var RDFModel = function(storeName){
    	this.name = storeName;
    	this.suppressUpdates = false;
    	//RDFModel.rdfModels[storeName] = this;
    };
    //RDFModel.vdmbeeSchema = "http://www.vdmbee.com/1/schema#";
    RDFModel.vdmbeeSchema = "//s/#";
    //RDFModel.vdmbeeInst = "http://www.vdmbee.com/1/data#";
    RDFModel.vdmbeeInst = "//d/#";
    RDFModel.rdfModels = {};
    RDFModel.prototype.initialize = function(callback){
    	var rdfModel = this;
    	/*new rdfstore.Store({persistent:false, name:this.name, overwrite:resetRDF ? resetRDF : false}, function(store){
    		rdfModel.initializeWithStore(store,callback,resetRDF);
    	});*/
    	var cacheRDFModel = function (args){
    		RDFModel.rdfModels[rdfModel.name] = rdfModel;
    		if(callback){
    			callback(args);
    		}
    	}
    	function initializeStoreWithData(store){
    		if(!RDFModel.store){
    			RDFModel.store = store;
    		}
			var rdfLawnchairStore = rdfModel.dataManager.get('rdfStore');
			rdfLawnchairStore.exists(rdfModel.name,function(exists){
				if(exists){
					rdfLawnchairStore.nuke(function(){	//cleaning to not use stored cache
						rdfLawnchairStore.get(rdfModel.name, function(data) {
							if(data && data.instanceData){
								var dataSize = data.instanceData.length;
								var pos = 0;
								var chunkSize = 200000;
								function loadNextChunk(){
									var chunkData = data.instanceData.substr(pos,((pos+chunkSize < dataSize) ? chunkSize : (dataSize - pos)));
									var tripleEnd = chunkData.lastIndexOf('\n');
									//var tripleEnd = chunkData.lastIndexOf(' . ');
									chunkData =  chunkData.substr(0,tripleEnd);
									store.load("text/n3",chunkData,"http://vdmbee.com/graph/" + rdfModel.name, function(success, results) {
										pos = pos + tripleEnd + 1;
										if(pos < dataSize){
											loadNextChunk();
										}else{
											if(rdfModel.name === window.plansKey){
												store.load("text/n3",data.schemaData,"http://vdmbee.com/graph/schema/" + rdfModel.name, function(success, results) {
													rdfModel.initializeWithStore(store,cacheRDFModel);
												});
											}else{
												rdfModel.initializeWithStore(store,cacheRDFModel);
											}
										}
										chunkData = null;
										tripleEnd = null;
									});
								}
								loadNextChunk();							
								//store.load("text/n3",data.instanceData,"http://vdmbee.com/graph/" + rdfModel.name, function(success, results) {
								//	store.load("text/n3",data.schemaData,"http://vdmbee.com/graph/schema/" + rdfModel.name, function(success, results) {
								//		rdfModel.initializeWithStore(store,cacheRDFModel);
								//	});
								//});
							}else{
								rdfModel.initializeWithStore(store,cacheRDFModel);
							}
						});
					});
				}else{
					rdfModel.initializeWithStore(store,cacheRDFModel);
				}
			});
    	}
    	if(!RDFModel.store){
    		new rdfstore.Store({name:this.name, overwrite:true}, initializeStoreWithData);    	
    	}else{
    		initializeStoreWithData(RDFModel.store);
    	}
    };
    
    RDFModel.prototype.save = function(){
    	var rdfModel = this;
    	function handleInstanceData(data){
    		var obj = {};
    		obj.key = rdfModel.name;
    		obj['instanceData'] = data;
            function handleSchemaData(schema) {
                var repositoryUpdater = rdfModel.dataManager.get('repositorySaver');
				obj['schemaData'] = schema;
                //rdfModel.dataManager.get("rdfStore").save(obj);	
                repositoryUpdater.postMessage({ action: "update", lawnchair: rdfModel.dataManager.get("rdfStore").name, value: obj });
			}
			if(rdfModel.name === window.plansKey){
				rdfModel.schemaModel.getNT(handleSchemaData);	
			}else{
				handleSchemaData('');
			}
    	}
    	this.getNT(handleInstanceData);
    };
    RDFModel.prototype.clean = function(skipSave,callback){
    	var rdfModel = this;
    	function handleInstanceData(data){
    		var obj = {};
    		obj.key = rdfModel.name;
    		obj['instanceData'] = data;
			function handleSchemaData(schema){
				obj['schemaData'] = schema;
				rdfModel.dataManager.get("rdfStore").save(obj,callback);	
			}
			handleSchemaData('');
    	}
    	rdfModel.store.clear(rdfModel.graphUri,function(){
    		if(!skipSave){
    			handleInstanceData('');		
    		}else{
    			if(callback){
    				callback();
    			}
    		}
    		
    	});
    };    
    RDFModel.prototype.initializeWithStore = function(store, callback){
    	var rdfModel = this;
		rdfModel.store = store;
		rdfModel.rdf = store.rdf;
		rdfModel.store.rdf.setPrefix("vbc",RDFModel.vdmbeeSchema);
		rdfModel.store.rdf.setPrefix("vbi",RDFModel.vdmbeeInst);
		rdfModel.graphUri = "http://vdmbee.com/graph/" + rdfModel.name;
		rdfModel.store.graph(rdfModel.graphUri, function(succes, graph){
			/*if(succes === false){
				rdfModel.store.rdf.setPrefix("vbc",RDFModel.vdmbeeSchema);
    			rdfModel.store.rdf.setPrefix("vbi",RDFModel.vdmbeeInst);
    		}*/
    		var schemaCallback = function(schemaModel){
	    		rdfModel.schemaModel = schemaModel;
	    		schemaModel.dataManager = rdfModel.dataManager;
	    		schemaModel.wsData = rdfModel.wsData;
	    		if(callback){
	    			callback(rdfModel);
	    		}
    		};
    		RDFSchemaModel.getInstance(window.plansKey,schemaCallback,rdfModel.store);
    		//graph.removeMatches();
		});
    };
    RDFModel.prototype.clear= function(callback){
    	var rdfModel = this;
    	new rdfstore.Store({persistent:true, name:this.name, overwrite:true}, function(store){
			store.clear(rdfModel.graphUri, function(success){
	    		rdfModel.schemaModel.clear(callback);
	    	}); //clear not working 
    	});
    };
    RDFModel.prototype.applyChangeSet = function(changeSet,callback){
    	var rdfModel = this;
    	if(this.suppressUpdates){
    		if(callback){
    			callback();	
    		}
    		return;
    	}
    	var createRDFForChangeSet = function(){
	    	var changes = changeSet.changes;
	    	var i=0;
	    	async.each(changes,function(change,handleChangeCallback){
	    	  rdfModel.redoChange(change,handleChangeCallback);
	    	},function(err){
          if(callback){
            callback();
          }	    	  
	    	});
	    	/*var count = 0;
	    	function handleNextChange(){
	    		if(count < changes.length){
	    			var change = changes[count];
	    			count++;
	    			rdfModel.redoChange(change,handleNextChange);
	    		}else{
	    			if(callback){
	    				//rdfModel.print();
	    				callback();
	    			}
	    		}
	    	}
	    	handleNextChange();*/
    	}
    	createRDFForChangeSet();
    	//setTimeout(createRDFForChangeSet,0);	// making creating rdf in sync as there is uncertinaity of when its created.
    };
    
    RDFModel.prototype.redoChange = function(changeObj,callback){
    	//if(this.suppressUpdates || utils.isScenarioType(changeObj.get("beepPackageType"))){
		if(this.suppressUpdates){
    		if(callback){
    			callback();	
    		}
    		return;
    	}    	
    	var operation = changeObj.get('operationType');
/*    	var redoChangeTimer = this.dataManager.getAsyncTimer("rdf " + operation + " " + changeObj.get('changeObjectId')).startTimer();
    	function tempCallback(){
    		redoChangeTimer.stopTimer().print();
    		if(callback){
    			callback();	
    		}    		
    	}  */  	
    	if(operation === 'add'){
    		this.redoAdd(changeObj,callback);
    	}else if(operation === 'update'){
    		this.redoUpdate(changeObj,callback);
    	} else {
    		this.redoDelete(changeObj,callback);
    	}
    };
    
    RDFModel.prototype.undoChange = function(changeObj,callback){
    	if(this.suppressUpdates){
    		if(callback){
    			callback();	
    		}
    		return;
    	}    	
    	var operation = changeObj.get('operationType');
    	if(operation === 'add'){
    		this.undoAdd(changeObj,callback);
    	}else if(operation === 'update'){
    		this.undoUpdate(changeObj,callback);
    	} else {
    		this.undoDelete(changeObj,callback);
    	}
    };
    
    RDFModel.prototype.undoDelete = function(changeObj,callback){
    	var prevValue = JSON.parse(changeObj.get('previous'));
    	var objType = this.dataManager.getChangeObjectModelType(changeObj,prevValue.type);// eval('this.get(\'appNS\').' + prevValue.type);
		if(objType){
			var instance = objType.find({'id': changeObj.get('changeObjectId')});
			if(instance){
				this.addRDFForModel(instance,callback);		
			}
		}    	
    };
    
    RDFModel.prototype.undoAdd = function(changeObj,callback){
    	var change = JSON.parse(changeObj.get('change')); 
    	var type = change.type;
    	var modelType = this.dataManager.getChangeObjectModelType(changeObj,type);// eval('this.get(\'appNS\').' + type);  
    	var changeObjId = change['id'];
    	this.removeRDFForModel(changeObjId,callback);
    };
    RDFModel.prototype.isEnumProperty = function(typeMixinCls,property){
    	if(!typeMixinCls){
    		return null;
    	}
		var properties = typeMixinCls.getProperties();
		for(var i=0;i<properties.length;i++){
			if(properties[i].name === property){
				properties.length = 0;
				return true;
			}
		}
		properties.length = 0;
    };
    RDFModel.prototype.removeRefernce = function(sourceModel,property,targetModel,callback){
    	var rdfModel = this;
    	var subjectNode = this.rdf.createNamedNode(this.rdf.resolve("vbi:" + sourceModel.get('id')));
    	var predicateNode = this.rdf.createNamedNode(this.rdf.resolve("vbc:" + sourceModel.get('type') + "-" + property));
    	rdfModel.removeReferenceValue(subjectNode,predicateNode,targetModel.get('id'),callback);
    }
    
    RDFModel.prototype.undoUpdate = function(changeObj,callback){
    	var rdfModel = this;
    	var prevValue = JSON.parse(changeObj.get('previous'));
    	var change = JSON.parse(changeObj.get('change'));
    	var type = changeObj.get('changeObjectType');
    	var version = changeObj.get('version');
    	if(!version){
    		version =1;
    	}
    	var typeMixinCls = this.dataManager.getModelTypeByTypeStr(type,version,true );

    	var changeObjId = changeObj.get('changeObjectId');
    	var subjectNode = this.rdf.createNamedNode(this.rdf.resolve("vbi:" + changeObjId));

		var changePairs = _.pairs(change);
		var count = 0;
		function handleNextChangePair(){
			if(count < changePairs.length){
				var changePair = changePairs[count];		
				count++;
				handleChangePair(changePair);
			}else{
				if(callback){
					callback();
				}
			}
		}
		
		function handleChangePair(changePair){
			var defaultPredicateNode = rdfModel.rdf.createNamedNode(rdfModel.rdf.resolve("vbc:" + type + "-" + changePair[0]));
			if(changePair[1].constructor === Array){
				/*var relation = modelType.getRelation(changePair[0]);
				var relatedModel = relation.relatedModel;
				for(var j=0;j<changePair[1].length;j++){
					//TODO
				}*/
				handleNextChangePair();
			}
			else{
				if(changePair[1] instanceof Object && !rdfModel.isEnumProperty(typeMixinCls,changePair[0])){
					var relPredicateNode = rdfModel.getPredicateNodeForRelation(typeMixinCls,changePair[0]);
					var isPartOfRelation = rdfModel.isPartOfRelation(typeMixinCls,changePair[0]);
					function addPOD0(){
						if(isPartOfRelation){
							rdfModel.addPartOfDirectlyTriple(subjectNode,changePair[0].id,handleNextChangePair);
						}else{
							handleNextChangePair();
						}
					}							
					function addPOD1(){
						if(isPartOfRelation){
							rdfModel.addPartOfDirectlyTriple(subjectNode,changePair[1].id,handleNextChangePair);
						}else{
							handleNextChangePair();
						}
					}
					function removePOD(){
						if(isPartOfRelation){
							rdfModel.removePartOfDirectlyTriple(subjectNode,changePair[1].id,handleNextChangePair);
						}else{
							handleNextChangePair();
						}
					}					
					if(!changePair[1].relationOperation){
						if(prevValue[changePair[0]]){
							rdfModel.changeReferenceValue(subjectNode,relPredicateNode ? relPredicateNode : defaultPredicateNode,prevValue[changePair[0]].id,addPOD0);
						}else{
							rdfModel.deleteMatches(subjectNode,relPredicateNode ? relPredicateNode : defaultPredicateNode,null,handleNextChangePair);
						}
					}else{
						if(changePair[1].relationOperation === "add"){
							rdfModel.removeReferenceValue(subjectNode,relPredicateNode ? relPredicateNode : defaultPredicateNode,changePair[1].id,removePOD);
						}
						else{
							rdfModel.addReferenceValue(subjectNode,relPredicateNode ? relPredicateNode : defaultPredicateNode,changePair[1].id,addPOD1);
						}
					}
				}
				else{
                    var predicateNode = rdfModel.getPredicateNodeForProperty(typeMixinCls, changePair[0]);
                    if (predicateNode) {
                        rdfModel.changeLiteralValue(subjectNode, predicateNode ? predicateNode : defaultPredicateNode, prevValue[changePair[0]], handleNextChangePair);			
                    }
				}
			}				
		}
		handleNextChangePair();
    };

	RDFModel.prototype.deleteMatches =  function(subject,predicate,object,callback){
		var rdfModel = this;
		this.store.node(subject.nominalValue,this.graphUri,function(success, graph){
			if(success){
				var removeGraph = graph.match(subject,predicate,object);
				rdfModel.deleteGraphFromStore(removeGraph,callback);
			}
			else{
				if(callback){
					callback()
				}
			}
			//graph.removeMatches();
		});
	};
        
    RDFModel.prototype.redoAdd =  function(changeObj,callback){
    	var objType = this.dataManager.getChangeObjectModelType(changeObj); //eval('this.get(\'appNS\').' + changeObj.get('changeObjectType'));
		if(objType){
			var instance = objType.find({'id': changeObj.get('changeObjectId')});
			if(instance){
				this.addRDFForModel(instance,callback);		
			}else{
				if(callback){
					callback();
				}
			}
		}else{
			if(callback){
				callback();
			}
		}    	
    };
    
    RDFModel.prototype.getPredicateNodeForProperty = function(typeMixinCls,property){
    	if(!typeMixinCls){
    		return;
    	}
    	var properties = typeMixinCls.getProperties();
    	for(var i=0;i<properties.length;i++){
    		if(properties[i].name === property){
    			var containingClass = properties[i].containingClass;
    			properties.length = 0;
    			return this.rdf.createNamedNode(this.rdf.resolve("vbc:" + containingClass + "-" + property));
    		}
    	}
    	properties.length = 0;
    };
    
    RDFModel.prototype.isPartOfRelation = function(typeMixinCls,property){
    	if(!typeMixinCls){
    		return false;
    	}
    	var relations = typeMixinCls.getCumulativeMixinRelations();
    	for(var i=0;i<relations.length;i++){
    		if(relations[i].key === property){
    			var includeInJSON = relations[i].includeInJSON;
    			if(!includeInJSON || includeInJSON === true){
    				relations.length = 0;
    				return true;
    			}else{
    				relations.length = 0;
    				return false;
    			}
    		}
    	}
    	relations.length = 0;
    	return false;
    };
    RDFModel.prototype.getPredicateNodeForRelation = function(typeMixinCls,property){
    	if(!typeMixinCls){
    		return;
    	}
    	var relations = typeMixinCls.getCumulativeMixinRelations();
    	for(var i=0;i<relations.length;i++){
    		if(relations[i].key === property){
    			var containingClass = relations[i].containingClass;
    			relations.length = 0;
    			return this.rdf.createNamedNode(this.rdf.resolve("vbc:" + containingClass + "-" + property));
    		}
    	}
    	relations.length = 0;
    };   
    RDFModel.prototype.redoUpdate = function(changeObj,callback){
    	var rdfModel = this;
    	var change = JSON.parse(changeObj.get('change'));
    	var prevValue = JSON.parse(changeObj.get('previous'));
    	var type = changeObj.get('changeObjectType');
    	var version = changeObj.get('version');
    	if(!version){
    		version =1;
    	}
    	var typeMixinCls = this.dataManager.getModelTypeByTypeStr(type,version,true );
    	var changeObjId = changeObj.get('changeObjectId');
		var subjectNode = this.rdf.createNamedNode(this.rdf.resolve("vbi:" + changeObjId));

		var changePairs = _.pairs(change);
			
		var count = 0;
		function handleNextChangePair(){
			if(count < changePairs.length){
				var changePair = changePairs[count];		
				count++;
				handleChangePair(changePair);
			}else{
				if(callback){
					callback();
				}
			}
		}
		function handleChangePair(changePair){
			var defaultPredicateNode = rdfModel.rdf.createNamedNode(rdfModel.rdf.resolve("vbc:" + type + "-" + changePair[0]));
			
			if(changePair[1] && changePair[1].constructor === Array){
				/*var relation = modelType.getRelation(changePair[0]);
				var relatedModel = relation.relatedModel;
				for(var j=0;j<changePair[1].length;j++){
					//TODO
				}*/
				handleNextChangePair();
			}
			else{
				if((changePair[1] instanceof Object || prevValue[changePair[0]] instanceof Object) && !rdfModel.isEnumProperty(typeMixinCls,changePair[0])){
					var relPredicateNode = rdfModel.getPredicateNodeForRelation(typeMixinCls,changePair[0]);
					var isPartOfRelation = rdfModel.isPartOfRelation(typeMixinCls,changePair[0]);
					function addPOD(){
						if(isPartOfRelation && changePair[1]){
							rdfModel.addPartOfDirectlyTriple(subjectNode,changePair[1].id,handleNextChangePair);
						}else{
							handleNextChangePair();
						}
					}
					function removePOD(){
						if(isPartOfRelation && changePair[1]){
							rdfModel.removePartOfDirectlyTriple(subjectNode,changePair[1].id,handleNextChangePair);
						}else{
							handleNextChangePair();
						}
					}
					if(!changePair[1]){
						rdfModel.removeReferenceValue(subjectNode,relPredicateNode ? relPredicateNode : defaultPredicateNode,null,removePOD);
					}else{
						if(!changePair[1].relationOperation){
							if(prevValue[changePair[0]]){
								rdfModel.changeReferenceValue(subjectNode,relPredicateNode ? relPredicateNode : defaultPredicateNode,changePair[1].id,addPOD);
							}else{
								rdfModel.addReferenceValue(subjectNode,relPredicateNode ? relPredicateNode : defaultPredicateNode,changePair[1].id,addPOD);
							}
						}else{
							if(changePair[1].relationOperation === "add"){
								rdfModel.addReferenceValue(subjectNode,relPredicateNode ? relPredicateNode : defaultPredicateNode,changePair[1].id,addPOD);
							}
							else{
								rdfModel.removeReferenceValue(subjectNode,relPredicateNode ? relPredicateNode : defaultPredicateNode,changePair[1].id,removePOD);
							}
						}
					}
				}
				else{
                    var predicateNode = rdfModel.getPredicateNodeForProperty(typeMixinCls, changePair[0]);
                    if (predicateNode) {
                        rdfModel.changeLiteralValue(subjectNode, predicateNode ? predicateNode : defaultPredicateNode, changePair[1], handleNextChangePair);			
                    }
				}
			}
		}
		handleNextChangePair();
    };
    
    RDFModel.prototype.redoDelete = function(changeObj,callback){
    	var change = JSON.parse(changeObj.get('previous')); 
    	var type = change.type;
    	var modelType = this.dataManager.getChangeObjectModelType(changeObj,type); //eval('this.get(\'appNS\').' + type);  
    	var changeObjId = change['id'];
    	this.removeRDFForModel(changeObjId,callback);
    }; 	  
    
 	RDFModel.prototype.changeLiteralValue = function(subjectNode, predicateNode, value,callback){
 		//this.print();
 	
 		var rdfModel = this;
 		var insertGraph = rdfModel.store.rdf.createGraph();
 		rdfModel.store.node(subjectNode.nominalValue,rdfModel.graphUri, function(succes, graph){
	 		var matchGraph = graph.match(subjectNode,predicateNode);
	 		if(matchGraph.triples.length > 0){
	 			rdfModel.store['delete'](matchGraph,rdfModel.graphUri, function(success, result) {
	 					//rdfModel.print();
					if(value && typeof(value) === 'object'){
						value = value.name;
					}	 				
	 				insertGraph.add(rdfModel.rdf.createTriple(subjectNode,
	 															predicateNode,
	 															rdfModel.rdf.createLiteral(String(value))));
					rdfModel.saveGraphToStore(insertGraph,callback);
	 			});
                } else {
                    if (value && typeof (value) === 'object') {
                        value = value.name;
                    }
                    insertGraph.add(rdfModel.rdf.createTriple(subjectNode,
                        predicateNode,
                        rdfModel.rdf.createLiteral(String(value))));
                    rdfModel.saveGraphToStore(insertGraph, callback);
	 		}
	 		graph.removeMatches();
 		});
 	};
      RDFModel.prototype.replaceOldNamePredicates = function (callback) {
        var rdfModel = this;
        var oldPredicates = [];
        oldPredicates.push("vbc:vdml_VdmlElement-name");
        oldPredicates.push("vbc:report_BeepReport-name");
        oldPredicates.push("vbc:concept_ConceptElement-name");
        oldPredicates.push("vbc:ecomap_EcoMap-name");
        oldPredicates.push("vbc:vdml_CapabilityLibrary-name");
        oldPredicates.push("vbc:transformation_NamedPlanElement-name");
        oldPredicates.push("vbc:smm_SmmElement-name");
        oldPredicates.push("vbc:cmof_Tag-name");
        var newPridicateNode = rdfModel.rdf.resolve("vbc:cmof_EObject-name");
            async.each(oldPredicates, function (predicate, predicateHandled) {
                rdfModel.replacePredicate(rdfModel.rdf.resolve("vbc:vdml_VdmlElement-name"), newPridicateNode, predicateHandled);
            }, function () {
                if (callback) {
                    callback();
                }
        });
    }
    RDFModel.prototype.replacePredicate = function (predicateNode, newPredicateNode, callback) {
        var rdfModel = this;
        var insertGraph = rdfModel.store.rdf.createGraph();
        rdfModel.store.graph(rdfModel.graphUri, function (err, graph) {
            var matchGraph = graph.match(null, predicateNode, null);
            for (var i = 0; i < matchGraph.triples.length; i++) {
                var triple = matchGraph.triples[i];
                try {
                    insertGraph.add(rdfModel.rdf.createTriple(triple.subject,
                        newPredicateNode,
                        triple.object));
                } catch (err) {
                    console.log(err);
                }
            }
            rdfModel.store['delete'](matchGraph, rdfModel.graphUri, function (success, result) {
                rdfModel.saveGraphToStore(insertGraph, callback);
                matchGraph.removeMatches();
            });
        });
    }
 	RDFModel.prototype.changeReferenceValue = function(subjectNode, predicateNode, id, callback){
		var rdfModel = this;
 		var insertGraph = rdfModel.store.rdf.createGraph();
 		rdfModel.store.node(subjectNode.nominalValue,rdfModel.graphUri, function(succes, graph){
	 		var matchGraph = graph.match(subjectNode,predicateNode);
	 		if(matchGraph.triples.length > 0){
	 			rdfModel.store['delete'](matchGraph,rdfModel.graphUri, function(success, result) {
	 				insertGraph.add(rdfModel.rdf.createTriple(subjectNode,
	 															predicateNode,
	 															rdfModel.rdf.createNamedNode(rdfModel.rdf.resolve("vbi:" + id))));
	 				rdfModel.saveGraphToStore(insertGraph,callback);
	 				matchGraph.removeMatches();
	 			});
	 		}else{//we are currently doing this(created for phase prev,next scenario but not needed)
 				insertGraph.add(rdfModel.rdf.createTriple(subjectNode,
 															predicateNode,
 															rdfModel.rdf.createNamedNode(rdfModel.rdf.resolve("vbi:" + id))));
 				rdfModel.saveGraphToStore(insertGraph,callback);
	 		}
	 		graph.removeMatches();
 		}); 		
 	}; 	
 	
 	RDFModel.prototype.addReferenceValue = function(subjectNode, predicateNode, id,callback){
 		var insertGraph = this.store.rdf.createGraph();
		insertGraph.add(this.rdf.createTriple( subjectNode,
                        predicateNode,
                        this.rdf.createNamedNode(this.rdf.resolve("vbi:" + id)) ));    	
		this.saveGraphToStore(insertGraph,callback);                        
 	}; 	

 	RDFModel.prototype.removeReferenceValue = function(subjectNode, predicateNode, id,callback){
 		var rdfModel = this;
 		rdfModel.store.node(subjectNode.nominalValue,rdfModel.graphUri, function(succes, graph){
	 		var matchGraph = graph.match(subjectNode,predicateNode,id ? rdfModel.rdf.createNamedNode(rdfModel.rdf.resolve("vbi:" + id)) : null);
	 		if(matchGraph.triples.length > 0){
	 			rdfModel.store['delete'](matchGraph,rdfModel.graphUri, function(success, result){
	 				if(callback){
	 					callback(success, result);
	 				}
	 				matchGraph.removeMatches();
	 			});
	 		}else{
	 			if(callback){
	 				callback();
	 			}
	 		}
	 		graph.removeMatches();
	 	});
 	}; 	
 	
    RDFModel.prototype.undoChangeSet = function(changeSet){
    	
    };
    
    RDFModel.prototype.removeRDFForModel = function(modelId,callback){
    	var rdfModel = this;
    	if(this.suppressUpdates){
    		if(callback){
    			callback();	
    		}
    		return;
    	}    	
		rdfModel.store.node(rdfModel.rdf.resolve("vbi:" + modelId),rdfModel.graphUri, function(success,graph){
			if(success === true){
				//rdfModel.print();
				rdfModel.store['delete'](graph,rdfModel.graphUri, function(success, result) {
					//rdfModel.print();
					if(callback){
						callback();
					}
					graph.removeMatches();
				});
			}else{
				if(callback){
					callback();
				}
			}
		});
    };
    
    RDFModel.prototype.removeRDFForModelNew = function(modelId,callback){
    	var rdfModel = this;
    	if(this.suppressUpdates){
    		if(callback){
    			callback();	
    		}
    		return;
    	}    	
    	var modelIdUri = rdfModel.rdf.resolve("vbi:" + modelId);
    	var query = "CONSTRUCT { ?s ?p ?o }" + " FROM <"+ this.graphUri + "> FROM <"+ this.schemaModel.graphUri + "> {\
    		{?s ?p ?o .\
    		FILTER (?o = <"+ modelIdUri +">)} UNION { ?s ?p ?o\
    		FILTER (?s = <"+ modelIdUri +">)} }";
		rdfModel.store.execute(query,function(success, graph){
			rdfModel.store['delete'](graph,rdfModel.graphUri, function(success, result) {
				//rdfModel.print();
				if(callback){
					callback();
				}
			});			
		});
		query = modelIdUri = null;
    };
    
	function printStackSize(method){
		console.log('method:' + method)
		var i=0;
		function testStack(){
			i++
			testStack();
		}		
		try{
			testStack();
		}catch(e){
			console.log('stak size:' + i)
		}
	}    
	RDFModel.prototype.addRDFForModel = function(model,callback,recursive){
    	//printStackSize("inside addRDFModel" + model.get('name'));
    	var rdfModel = this;
    	if(this.suppressUpdates){
    		if(callback){
    			callback();	
    		}
    		return;
    	}    	
		var handleModelSchema = function(clsSchema){
			if(rdfModel.isSystemClass(model)){
				rdfModel.createRDFForSystemModel(model,clsSchema,callback);	
			}else{
				var models = [model];
				rdfModel.createRDFForApplicationModels(models,callback,recursive);	
			}
		};
		rdfModel.schemaModel.getRDFSchemaClass(model,handleModelSchema);		
	}
    RDFModel.prototype.createRDFForApplicationModels = function(models,callback,recursive){
    	var rdfModel = this;
		var dm = rdfModel.dataManager;
		var q = async.queue(function(model,handleAppModelCllback){
            //printStackSize("inside addRDFModel" + model.get('name'));
            var rdfModel = dm.getRDFModel(dm.getRepositoryId(model.get('id')));
            if (rdfModel) {
                var handleModelSchema = function(clsSchema){
                    function createdRDFForApplicationModel(){
                        handleAppModelCllback();
                    }
                    rdfModel.createRDFForApplicationModel(model,clsSchema,createdRDFForApplicationModel,recursive,models,q);    
                };
                rdfModel.schemaModel.getRDFSchemaClass(model,handleModelSchema);
            } else {
                handleAppModelCllback();
            }
                        
        },1);
		//await q.drain();
		/*q.error(function(err, task) {
			console.error('task experienced an error');
		});*/
		
		q.push(models,function(){
    		if(callback){
    			callback();	
    		}
    	});
    	/*async.each(models,function(model,handleAppModelCllback){
    		//printStackSize("inside addRDFModel" + model.get('name'));
			var handleModelSchema = function(clsSchema){
				function createdRDFForApplicationModel(){
					handleAppModelCllback();
				}
				rdfModel.createRDFForApplicationModel(model,clsSchema,createdRDFForApplicationModel,recursive,models);	
			};
			rdfModel.schemaModel.getRDFSchemaClass(model,handleModelSchema);    		
    	},function(){
    		if(callback){
    			callback();	
    		}
    	});*/
    };
    
    RDFModel.prototype.isSystemClass = function(model){
    	var type = model.get('type');
    	if(type.indexOf("com_vbee_data") >=0 || type.indexOf("com_vbee_filesystem") >=0 || type.indexOf("com_vbee_utils") >=0 || type.indexOf("com_vbee_rdf") >=0){
    		return true;
    	}
    	return false;
    }
    
	RDFModel.prototype.createRDFForApplicationModel = function(model,clsSchema,callback,recursive,models,q){
    	var rdfModel = this;
    	var relatedGraphs = {};
    	var graph = rdfModel.store.rdf.createGraph();
    	relatedGraphs[rdfModel.name] = graph;
    	/*if(utils.isScenarioType(model.get("type"))){
			callback();
			return;
		}*/
    	var modelId = model.get('id');
    	var typeMixinCls = rdfModel.dataManager.getModelType(model,true);
    	
    	var instanceNode = this.rdf.createNamedNode(this.rdf.resolve("vbi:" + modelId));
    	
		graph.add(this.rdf.createTriple( instanceNode,
                                this.rdf.createNamedNode(this.rdf.resolve("rdf:type")),
                                clsSchema));    	
		this.addAttributesForAppCls(graph,instanceNode,model,clsSchema,typeMixinCls);                           
		
		this.addRelationsShipsForAppCls(graph,relatedGraphs,instanceNode,model,clsSchema,typeMixinCls,addGraphToStore,recursive,models,q);
		function addGraphToStore(){
			var graphModels = Object.getOwnPropertyNames(relatedGraphs);
			async.each(graphModels,function(modelKey,graphInserted){
				var modelGraph = relatedGraphs[modelKey];
				var relatedModel = rdfModel.dataManager.getRDFModel(modelKey);
				relatedModel.store.insert(modelGraph,relatedModel.graphUri, function(success, results){
					modelGraph.removeMatches();
					modelGraph.triples.length = 0;
					graphInserted();
				});				
			},function(){
				relatedGraphs = null;
				graph = null;
				if(callback){
					callback(instanceNode);
				}							
			})
		}
		return instanceNode;
    };    
    RDFModel.prototype.createRDFForSystemModel = function(model,clsSchema,callback){
    	var rdfModel = this;
    	var modelId = model.get('id');
    	var graph = rdfModel.store.rdf.createGraph();
    	var instanceNode = this.rdf.createNamedNode(this.rdf.resolve("vbi:" + modelId));
    	
		graph.add(this.rdf.createTriple( instanceNode,
                                this.rdf.createNamedNode(this.rdf.resolve("rdf:type")),
                                clsSchema));    	
		this.addAttributes(graph,instanceNode,model,clsSchema);                           
		this.addRelationsShips(graph,instanceNode,model,clsSchema);
		this.store.insert(graph,this.graphUri, function(success, results){
			graph.removeMatches();
			graph.triples.length = 0;
			graph = null;
			if(callback){
				callback(instanceNode);
			}							
		});
		return instanceNode;
    };
    RDFModel.prototype.saveGraphToStore = function(graph,callback){
    	var rdfModel = this;
		this.store.insert(graph,this.graphUri, function(success, results){
			graph.removeMatches();
			graph.triples.length = 0;
			graph = null;
			if(callback){
				callback(success, results);
			}							
		});
    };
    RDFModel.prototype.deleteGraphFromStore = function(graph,callback){
    	var rdfModel = this;
    	if(graph.triples.length > 0){
	    	rdfModel.store['delete'](graph,rdfModel.graphUri, function(success, result) {
	    		graph.removeMatches();
				graph.triples.length = 0;
				graph = null;	    		
				if(callback){
					callback(success, result);
				}							
			});
		}else{
			if(callback){
				callback(true);
			}
		}
    };
    
    
    
    RDFModel.prototype.addAttributesForAppCls = function (graph, instanceNode, model, clsSchema, typeMixinCls) {
    	var attributes = typeMixinCls.getProperties();
    	var type = model.get('type');
		for(var i=0;i<attributes.length;i++){
			var value = model.get(attributes[i].name);
			
			if(value){
				if(typeof(value) === 'object'){
					value = value.name;
				}
				graph.add(this.rdf.createTriple( instanceNode,
	                        this.rdf.createNamedNode(this.rdf.resolve("vbc:" + attributes[i].containingClass + "-" + attributes[i].name)),
	                        this.rdf.createLiteral(String(value)) )); 
            }
		}
		attributes.length = 0;
    };    
    RDFModel.prototype.addAttributes = function(graph,instanceNode,model,clsSchema){
    	var attributes = model.attributes;
    	var type = model.get('type');
		for(var x in attributes){
			if(typeof attributes[x] === "object"){
				continue;
			}
			var value = model.get(x);
			if(value){
				var triple = this.rdf.createTriple( instanceNode,
	                        this.rdf.createNamedNode(this.rdf.resolve("vbc:" + type + "-" + x)),
	                        this.rdf.createLiteral(String(value)) );
				graph.add(triple); 
            }
		}
    };
	RDFModel.prototype.removePartOfDirectlyTriple = function(ownerId,partId,callback){
		var rdfModel = this;
		var ownerNode; 
		var partNode;
		if(typeof ownerId === "string"){
			var ownerUri = this.rdf.resolve("vbi:" + ownerId);
			ownerNode = this.rdf.createNamedNode(ownerUri);
		}else{
			ownerNode = ownerId;
		};
		if(typeof partId === "string"){
			var partUri = this.rdf.resolve("vbi:" + partId);
			partNode = this.rdf.createNamedNode(partUri);
		}else{
			partNode = partId;
		}
		var podUri = this.rdf.resolve("vbc:" + "partOfDirectly");
		var podNode = this.rdf.createNamedNode(podUri);
		this.store.node(partNode.nominalValue,this.graphUri,function(success, graph){
			if(success){
				var removeGraph = graph.match(partNode,podNode);
				rdfModel.deleteGraphFromStore(removeGraph,callback);
				graph.removeMatches();
			}
		});		
	}
	RDFModel.prototype.addPartOfDirectlyTriple = function(ownerId,partId,callback){
		var rdfModel = this;
		var ownerNode; 
		var partNode;
		if(typeof ownerId === "string"){
			var ownerUri = this.rdf.resolve("vbi:" + ownerId);
			ownerNode = this.rdf.createNamedNode(ownerUri);
		}else{
			ownerNode = ownerId;
		};
		if(typeof partId === "string"){
			var partUri = this.rdf.resolve("vbi:" + partId);
			partNode = this.rdf.createNamedNode(partUri);
		}else{
			partNode = partId;
		}
		var podUri = this.rdf.resolve("vbc:" + "partOfDirectly");
		var podNode = this.rdf.createNamedNode(podUri);
		
		this.store.node(partNode.nominalValue,this.graphUri,function(success, graph){
			var addNewPod = function(){
				var createGraph = rdfModel.store.rdf.createGraph();
				createGraph.add(rdfModel.rdf.createTriple(partNode,podNode,ownerNode));	
				rdfModel.saveGraphToStore(createGraph,callback);
			};
			if(success){
				var removeGraph = graph.match(partNode,podNode);
				rdfModel.deleteGraphFromStore(removeGraph,addNewPod);
				graph.removeMatches();
				graph.triples.length = 0;
				graph = null;				
			}
		});
	};
    RDFModel.prototype.addRelationsShipsForAppCls = function(graph,relatedGraphs,instanceNode,model,clsSchema,typeMixinCls,callback,recursive,models,q){
    	var rdfModel = this;
    	var relations = typeMixinCls.getCumulativeMixinRelations();
    	
		var repId = this.dataManager.getRepositoryId(model.id);
		var repInstanceNode = rdfModel.rdf.createNamedNode(rdfModel.rdf.resolve("vbi:" + repId));
		graph.add(rdfModel.rdf.createTriple( instanceNode,
                    this.rdf.createNamedNode(this.rdf.resolve("vbc:" + 'cmof_EObject' + "-" + 'repId')),
                    repInstanceNode));    
                    
		async.each(relations,function(relation,handledRelation){
			addRelationShip(relation,handledRelation);
		},function(){
			relations.length = 0;
			callback();
		});
		
		function addReverseRelationShip(relation,relatedModel,relatedInstanceNode){
			var reverseRelation = relation.reverseRelation;
			if(!reverseRelation){
				return;
			}
			if(relatedInstanceNode.nominalValue.indexOf(rdfModel.name) < 0){
				var repositoryId = relatedInstanceNode.nominalValue.substr(relatedInstanceNode.nominalValue.indexOf('#') + 1); 
				repositoryId = repositoryId.substr(0,repositoryId.lastIndexOf('@')+ 1);
				var relatedRdfModel = rdfModel.dataManager.getRDFModel(repositoryId);
				function checkRDFModelAndSetRel(){
					var relatedRdfModel = rdfModel.dataManager.getRDFModel(repositoryId);
					var relatedGraph = relatedGraphs[repositoryId];
					if(!relatedGraph && relatedRdfModel){
						relatedGraph = relatedRdfModel.store.rdf.createGraph();	
						relatedGraphs[relatedRdfModel.name] = relatedGraph;	
						addReverseTriple(relatedRdfModel,relatedGraph);
					} else {
						console.log("Rdf not loaded for "+repositoryId);//TODO for new alt multiuser fails
					}
				}
				if(!relatedRdfModel){
					setTimeout(checkRDFModelAndSetRel,10);
				}else{
					var relatedGraph = relatedGraphs[repositoryId];
					if(!relatedGraph && relatedRdfModel){
						relatedGraph = relatedRdfModel.store.rdf.createGraph();	
						relatedGraphs[relatedRdfModel.name] = relatedGraph;	
					}
					addReverseTriple(relatedRdfModel,relatedGraph);
				}
				
			}else{
				addReverseTriple(rdfModel,graph);
			}
			function addReverseTriple(relatedRdfModel,relatedGraph){
				var relatedModelStr = relation.relatedModel.replace('.','_');
	    		var relationNode = relatedRdfModel.rdf.createNamedNode(relatedRdfModel.rdf.resolve("vbc:" + relatedModelStr + "-" + reverseRelation.key));
				if((reverseRelation.type === Backbone.HasOne) || (reverseRelation.type === "HasOne") ){
					var relationValue = relatedModel.get(reverseRelation.key);
					if(relationValue && relatedGraph){
						relatedGraph.add(relatedRdfModel.rdf.createTriple( relatedInstanceNode,
			                        relationNode,
			                         relatedRdfModel.rdf.createNamedNode(relatedRdfModel.rdf.resolve("vbi:" + relationValue.get('id')))));
		            }
				}				
			}
		}
		
		function addRelationShip(relation,handleNextRelation){
/*			if(relation.key=== 'tag'){
				
			}*/
    		var relationNode = rdfModel.rdf.createNamedNode(rdfModel.rdf.resolve("vbc:" + relation.containingClass + "-" + relation.key));
    		var isPartOfRelation = rdfModel.isPartOfRelation(typeMixinCls,relation.key);
			if((relation.type === Backbone.HasOne) || (relation.type === "HasOne") ){
				var relationValue = model.get(relation.key);
				function addTriple(){
					if(relationValue){
						var relatedInstanceNode = rdfModel.rdf.createNamedNode(rdfModel.rdf.resolve("vbi:" + relationValue.get('id')));
						graph.add(rdfModel.rdf.createTriple( instanceNode,
			                        relationNode,
			                         relatedInstanceNode));
			            if(!isPartOfRelation){
			            	addReverseRelationShip(relation,relationValue,relatedInstanceNode);			                         	
			            }
			            relatedInstanceNode = null;
		            }
		            relationNode = null;
		            handleNextRelation();
	            }				
				if(isPartOfRelation){	//add only if its partOfObject
						if(recursive && relationValue){
							models.push(relationValue);
							q.push(relationValue);
						}				
					if(relationValue){
						rdfModel.addPartOfDirectlyTriple(instanceNode,relationValue.get('id'),addTriple);	
					}else{
						relationNode = null;
						handleNextRelation();
					}
				}else{
					addTriple();
				}

			}else{
				var relationValues = model.get(relation.key);
				function addRelationshipValue(relationValue,addRelationCallback){
					if(relationValue){
						function addValueTriple(){
							var relatedInstanceNode = rdfModel.rdf.createNamedNode(rdfModel.rdf.resolve("vbi:" + relationValue.get('id')));
							graph.add(rdfModel.rdf.createTriple( instanceNode,
				                        relationNode,
				                        relatedInstanceNode));
							if(!isPartOfRelation){				                        
				            	addReverseRelationShip(relation,relationValue,relatedInstanceNode);	        
				            }
				            relatedInstanceNode = null;
							if(recursive && isPartOfRelation){
								//rdfModel.addRDFForModel(relationValue,addRelationCallback);
								models.push(relationValue);
								q.push(relationValue);
							}
							addRelationCallback();
			            }
						if(isPartOfRelation){	//add only if its partOfObject
							rdfModel.addPartOfDirectlyTriple(instanceNode,relationValue.get('id'),addValueTriple);
						}else{
							addValueTriple();
						}
		           	}else{
		           		addRelationCallback();
		           	}
	           	}				
				if (relationValues && relationValues.models){
					async.each(relationValues.models,function(relationValue,addRelationCallback){
						addRelationshipValue(relationValue,addRelationCallback);
					},function(){
						relationNode = null;
						handleNextRelation();
					});
				}else{
					relationNode = null;
					handleNextRelation();
				}
			}                         
		}	
    };    

    RDFModel.prototype.addRelationsShips = function(graph,instanceNode,model,clsSchema){
    	var relations = model.getRelations();
    	for(var i=0;i<relations.length;i++){
    		var relation = relations[i];
    		var relationNode = this.rdf.createNamedNode(this.rdf.resolve("vbc:" + model.get('type') + "-" + relation.key));
			if((relation.type === Backbone.HasOne) || (relation.type === "HasOne") ){
				var singleRelationValue = model.get(relation.key);
				if(singleRelationValue){
					graph.add(this.rdf.createTriple( instanceNode,
		                        relationNode,
		                         this.rdf.createNamedNode(this.rdf.resolve("vbi:" + singleRelationValue.get('id')))));
	            }
			}else{
				var relationValues = model.get(relation.key);
				for(var j=0;relationValues && j<relationValues.length;j++){
					var multiRelationValue = relationValues.at(j);
					if(multiRelationValue){
						graph.add(this.rdf.createTriple( instanceNode,
			                        relationNode,
			                         this.rdf.createNamedNode(this.rdf.resolve("vbi:" + multiRelationValue.get('id')))));
		           	}
				}
			}                         
    	}
    };    
    
    RDFModel.getInstance = function(storeName,dataManager,wsData,callback){
    	if(RDFModel.rdfModels[storeName]){
    		callback(RDFModel.rdfModels[storeName]);
    		return;
    	}
    	var rdfModel = new RDFModel(storeName);
    	rdfModel.dataManager = dataManager;
    	rdfModel.wsData = wsData;
    	rdfModel.initialize(callback);
    };
    RDFModel.prototype.reloadGraph = function(callback){
    	this.store.graph(this.graphUri,function(succes, graph){
    		if(callback){
    			callback();
    		}
    		//graph.removeMatches();
		});
	};
    RDFModel.prototype.getNT = function(callback){
    	var rdfModel = this;
    	this.store.graph(this.graphUri,function(succes, graph){
    		callback(graph.toNT());
    		//graph.removeMatches();
    	});
    };
    RDFModel.prototype.print = function(){
    	this.store.graph(this.graphUri,function(succes, graph){
    		console.log("graph length:" + graph.triples.length);
    		console.log("Instance Data Graph");
    		for(var i=0;i<graph.triples.length;i++){
    			var triple= graph.triples[i];
    			try{
    				console.log(triple.subject.nominalValue + " " + triple.predicate.nominalValue + " " + triple.object.nominalValue);	
    			}catch(err){
    				console.log(err);
    			}
    			
    		}
    		graph.removeMatches();
		});
	};
	RDFModel.prototype.getPackages = function(searchTerm,callback){
	    //var classUri = this.rdf.resolve("vbc:" + "vdml_ValueDeliveryModel");
	    var classUris = ["vbc:vdml_ValueDeliveryModel", "vbc:report_BeepReport", "vbc:ecomap_EcoMap", "vbc:vdml_CapabilityLibrary","vbc:concept_BeepVocabulary"];
		var nameUri = this.rdf.resolve("vbc:" + "cmof_EObject-name");
		var idUri = this.rdf.resolve("vbc:" + "vdml_VdmlElement-id");
		var rdfTypeUri = this.rdf.resolve("rdf:type");
        var nameUris = ['vbc:cmof_EObject-name'];

		var nameQuery = "{ { ?s <" + this.rdf.resolve(nameUris[0]) + ">  ?name . } ";
		for (var i = 1; i < nameUris.length; i++) {
		    nameQuery = nameQuery + "UNION { ?s <" + this.rdf.resolve(nameUris[i]) + ">  ?name . } ";
		}
		nameQuery = nameQuery + " } ";

		var classQuery = "{ { ?s <" + rdfTypeUri + ">  <" + this.rdf.resolve(classUris[0]) + "> . } ";
		for (var i = 1; i < classUris.length; i++) {
		    classQuery = classQuery + "UNION { ?s <" + rdfTypeUri + ">  <" + this.rdf.resolve(classUris[i]) + "> . } ";
		}
		classQuery = classQuery + " } ";

		var query = "SELECT ?s ?name FROM <" + this.graphUri + "> { " + classQuery + ". " + nameQuery + "}";
		this.store.execute(query,function(success, results){
			var ret = new Array();
			for(var i=0;i<results.length;i++){
				var result = {};
				result.id = results[i].s.value.substr(RDFModel.vdmbeeInst.length);
				result.value = results[i].name.value;
				result.label = results[i].name.value;
				if (result.value !== 'Common Package') {
				    ret.push(result);
				}
			}
			results.length = 0;
			callback(ret);
		});
		classUris.length = 0;
		nameUris.length = 0;
		query = idUri = rdfTypeUri = null;		
	};
	
	RDFModel.prototype.getDocumentTypeUri = function(docType){
		var docTypeUri;
		if(!docType){
			return;
		}
		if(docType === 'Plan'){
			docTypeUri = this.rdf.resolve("vbc:" + "transformation_Plan");
		}else if(docType === 'Design Package'){
			docTypeUri = this.rdf.resolve("vbc:" + "vdml_ValueDeliveryModel");
		}else if(docType === 'Business Model'){
			docTypeUri = this.rdf.resolve("vbc:" + "vdml_BusinessModel");
		}else if(docType === 'Business Canvas'){
			docTypeUri = this.rdf.resolve("vbc:" + "canvas_BMCanvasDiagram");
		}else if(docType === 'Business Model portfolio'){
			docTypeUri = this.rdf.resolve("vbc:" + "vdml_BusinessModelPortfolio");
		}else if(docType === 'Organization Unit'){
			docTypeUri = this.rdf.resolve("vbc:" + "vdml_OrgUnit");
		}else if(docType === 'Capability Library'){
			docTypeUri = this.rdf.resolve("vbc:" + "vdml_CapabilityLibrary");
		}else if(docType === 'Report'){
			docTypeUri = this.rdf.resolve("vbc:" + "report_BeepReport");
		}else if(docType === 'Vocabulary Package'){
			docTypeUri = this.rdf.resolve("vbc:" + "concept_BeepVocabulary");
		}else if(docType === 'Vocabulary Library'){
			docTypeUri = this.rdf.resolve("vbc:" + "concept_VocabularyLibrary");
		}else if (docType === 'Business Ecosystem Map') {
		    docTypeUri = this.rdf.resolve("vbc:" + "vdml_EcoMapDiagram");
		}else if (docType === 'Business Ecosystem Map Package') {
		    docTypeUri = this.rdf.resolve("vbc:" + "ecomap_EcoMap");
		}else if (docType === 'Capability Library Diagram') {
		    docTypeUri = this.rdf.resolve("vbc:" + "vdml_CapabilityLibraryDiagram");
		}else if (docType === 'Strategy Map') {
		    docTypeUri = this.rdf.resolve("vbc:" + "vdml_StrategyMapDiagram");
		}else if (docType === 'ValueStream Map') {
		    docTypeUri = this.rdf.resolve("vbc:" + "vdml_ValueStreamMapDiagram");
		}else if (docType === 'Dashboard') {
		    docTypeUri = this.rdf.resolve("vbc:" + "dashboard_Dashboard");
		}else if (docType === 'Analytics Package') {
		    docTypeUri = this.rdf.resolve("vbc:" + "dashboard_Analytics");
		}
		return docTypeUri;
	}

	RDFModel.prototype.getPlanDependentPackageNameVersion = function (packageId, callback) {
	    var rdfModel = this;

        var nameUri = this.rdf.resolve("vbc:" + "cmof_EObject-name");
	    var versionUri = this.rdf.resolve("vbc:" + "beeppackage_BeepPackage-version");
	    var beepReferenceUri = this.rdf.resolve("vbc:" + "transformation_PackageReference-beepReference");
	    //var subjectUri = this.rdf.resolve("vbi:" + packageId);

	    var query = "SELECT ?name ?version" + " FROM <" + this.graphUri + "> FROM <" + this.schemaModel.graphUri + "> {";
	    query = query + " ?packRef <" + beepReferenceUri + ">  \"" + packageId + "\" . ";
	    query = query + "?packRef <" + nameUri + ">  ?name . ";
	    query = query + "?packRef <" + versionUri + ">  ?version .";
	    query = query + "} ";

	    this.store.execute(query, function (success, results) {
	        var ret;
	        if (results.length > 0) {
	            ret = { dependentPackageName: results[0].name.value, dependentPackageVersion: results[0].version.value };
	        }
	        results.length = 0;
	        callback(ret);
	    });
	    query = nameUri = versionUri = null;
	}

	RDFModel.prototype.getDependentPackages = function(packageId,version,callback){
		var rdfModel = this;
		
        var nameUri = this.rdf.resolve("vbc:" + "cmof_EObject-name");
		var versionUri = this.rdf.resolve("vbc:" + "beeppackage_BeepPackage-version");
		var depPackUri = this.rdf.resolve("vbc:" + "beeppackage_BeepPackage-dependentPackage");
		var subjectUri = this.rdf.resolve("vbi:" + packageId);

		var query = "SELECT ?depPack ?depPackName ?depPackVersion" + " FROM <"+ this.graphUri + "> FROM <"+ this.schemaModel.graphUri + "> {";
		query = query +  "<" + subjectUri + "> <"+ depPackUri + ">  ?depPack ."; 
		query = query +  " OPTIONAL { ?depPack <"+ nameUri + ">  ?depPackName }. "; 
		query = query +  " OPTIONAL { ?depPack <"+ versionUri + ">  ?depPackVersion } ."; 
		query = query + " <" + subjectUri + "> <" + versionUri + "> \"" + version + "\". ";
		query= query + "} ";
	
		this.store.execute(query,function(success, results){
			var ret = new Array();
			for(var i=0;i<results.length;i++){
				var result = {};
				result.id = results[i].depPack.value.substr(RDFModel.vdmbeeInst.length);
				if(results[i].depPackVersion){
					result.version = results[i].depPackVersion.value;	
				}
				if(results[i].depPackName){
					result.name = results[i].depPackName.value;	
				}
				ret.push(result);

			}
			async.eachSeries(ret, function (item, gotItemVersionName) {
			    if (!item.depPackVersion) {
			        var itemRep = rdfModel.dataManager.getRepositoryId(item.id);
			        if (itemRep === window.plansKey) {
			            rdfModel.getPlanDependentPackageNameVersion(item.id, function (itemResult) {
							if(itemResult){
								item.version = itemResult.dependentPackageVersion;
								item.name = itemResult.dependentPackageName;
							}
			                gotItemVersionName();
			            })
			        } else {
			            gotItemVersionName();
			        }

			    } else {
			        gotItemVersionName();
			    }
			}, function () {
			});
			results.length = 0;
			callback(ret);
		});	
		query = nameUri = versionUri = depPackUri = subjectUri = null;
	}
	
	RDFModel.prototype.getAllDependentOnPackages = function(packageId,version,callback){
		var self = this;
		var ret= [];
		var count = 0;
		function handleResult(depPackages){
			_.each(depPackages,function(depPackage){
				_.each(ret,function(addedPackage){
					if(addedPackage.id === depPackage){
						return;
					}else{
						ret.push(depPackage);
					}
				})
			});
			count++;
			if(count < ret.length){
				var curPack = ret[count-1];
				var packId = curPack.id;
				var packAlt = packId.substr(0,packId.lastIndexOf('@')+ 1);
				var rdfModel = self.dataManager.getRDFModel(packAlt);
				if(rdfModel){
					rdfModel.getOneLevelDependentOnPackages(curPack.id,curPack.version,handleResult);
				}else{
					console.log("RDF model of alt not loaded: " + packAlt);
					handleResult([]);
				}
			}else{
				if(callback){
					callback(ret);
				}
			}
		}
		self.getOneLevelDependentOnPackages(packageId,version,handleResult);
	}
	RDFModel.prototype.getOneLevelDependentOnPackages = function(packageId,version,callback){
		var rdfModel = this;
        var nameUri = this.rdf.resolve("vbc:" + "cmof_EObject-name");
		var versionUri = this.rdf.resolve("vbc:" + "beeppackage_BeepPackage-version");
		var depPackPredUri = this.rdf.resolve("vbc:" + "beeppackage_BeepPackage-dependentPackage");
		var depOnPackUri = this.rdf.resolve("vbi:" + packageId);
		var rdfTypeUri = this.rdf.resolve("rdf:type");

		var query = "SELECT ?subject ?type ?subjectName ?subjectVersion" + " FROM <"+ this.graphUri + "> FROM <"+ this.schemaModel.graphUri + "> {";
		query = query +  " ?subject <"+ depPackPredUri + "> <" + depOnPackUri + "> ."; 
		query = query + " ?subject <" + rdfTypeUri + "> ?type . ";
		query = query +  " OPTIONAL { ?subject <"+ nameUri + ">  ?subjectName }. "; 
		query = query +  " OPTIONAL { ?subject <"+ versionUri + ">  ?subjectVersion } ."; 
		query = query + " <" + depOnPackUri + "> <" + versionUri + "> \"" + version + "\". ";
		query= query + "} ";
	
		rdfModel.store.execute(query,function(success, results){
			var ret = [];
			for(var i=0;i<results.length;i++){
				var result = {};
				result.id = results[i].subject.value.substr(RDFModel.vdmbeeInst.length);
				result.type = results[i].type.value.substr(RDFModel.vdmbeeSchema.length);
				if(results[i].subjectVersion){
					result.version = results[i].subjectVersion.value;	
				}
				if(results[i].subjectName){
					result.name = results[i].subjectName.value;	
				}
				ret.push(result);
			}
			results.length = 0;
			callback(ret);
		});	
		query = nameUri = versionUri = depPackPredUri = depOnPackUri = null;
    };
    RDFModel.prototype.getObservationWithName = function (obsName, callback) {
        var rdfModel = this;
        var obsNameUri = this.rdf.resolve("vbc:" + "cmof_EObject-name");

        var query = "SELECT ?doc ";
        query = query + " FROM <" + rdfModel.graphUri + "> ";
        query = query + " FROM <" + rdfModel.schemaModel.graphUri + "> {";
        query = query + " ?doc <" + obsNameUri + "> \"" + obsName + "\" . } "
        rdfModel.store.execute(query, function (success, results) {
            var doc, parent;
            if(results.length>0){
                var result = results[0];
                if (result.doc) {
                    doc = result.doc.value.substr(RDFModel.vdmbeeInst.length);
                }
                rdfModel.getNestedParent(doc, "smm_Observation", function (ret) {
                    callback(doc, ret.id);
                }, null, null, null, "vbc:cmof_EObject-name");
            } else {
                callback();
            }
        });
        query = query + " }"
    }
    RDFModel.prototype.getMestInObservation = function (obsName, valueName, whenObserved, callback) {
        var rdfModel = this;
        var mestUri = this.rdf.resolve("vbc:" + "vdml_MeasuredCharacteristic-measurement");
        var nameUri = this.rdf.resolve("vbc:" + "cmof_EObject-name");
        var obsNameUri = this.rdf.resolve("vbc:" + "cmof_EObject-name");

        var obsMesMestUri = this.rdf.resolve("vbc:" + "smm_ObservedMeasure-measurements");

        var mestObsMesUri = this.rdf.resolve("vbc:" + "smm_Observation-observedMeasures");
        var whenObservedUri = this.rdf.resolve("vbc:" + "smm_Observation-whenObserved");

        var query = "SELECT ?doc ?whenObserved";
        query = query + " FROM <" + rdfModel.graphUri + "> ";
        query = query + " FROM <" + rdfModel.schemaModel.graphUri + "> {";
        query = query + " ?mesChar <" + mestUri + "> ?doc . "
        query = query + " ?mesChar <" + nameUri + "> \"" + valueName + "\" . "
        query = query + " ?obsMes <" + obsMesMestUri + "> ?doc . "
        query = query + " ?obs <" + mestObsMesUri + "> ?obsMes . "
        query = query + " ?obs <" + obsNameUri + "> \"" + obsName + "\" . "
        query = query + " OPTIONAL { ?obs <" + whenObservedUri + "> ?whenObserved . }} "
        rdfModel.store.execute(query, function (success, results) {
            var doc, parent;
            if (results.length === 0) {
                callback();
                return;
            }
            var ret = [];
            async.eachSeries(results, function (result, gotParent) {
                var resultWhenObs = result.whenObserved;
                if ((resultWhenObs && !whenObserved) || (!resultWhenObs && whenObserved)) {
                    gotParent();
                    return;
                }
                if (result.doc) {
                    doc = result.doc.value.substr(RDFModel.vdmbeeInst.length);
                }
                rdfModel.getNestedParent(doc, "smm_Measurement", function (parent) {
                    ret.push({ id: doc, parent: parent.id });
                    gotParent();
                })
            }, function () {
                callback(ret);
            });
        });
        query = query + " }"
    }
    RDFModel.prototype.getDocumentWithNamePath = function (namePath,type, rdfModels, fetchAllResults, callback) {
        var rdfModel = this;
        var rdfTypeUri = this.rdf.resolve("rdf:type");
        var names = namePath.split(">");
        /*_.each(names, function (name, index) {
            names[index] = rdfModel.rdf.resolve("vbi:" + name);
        });*/
        var subTypes = [];
        subTypes.push(type);
        var subTypeStr = type.replace('_', '.');
        var typeObj = Backbone.Relational.store.getObjectByName(subTypeStr);
        this.getSubTypes(typeObj, subTypes);

        var typeUriArray = [];
        for (var i = 0; i < subTypes.length; i++) {
            typeUriArray.push(this.rdf.resolve("vbc:" + subTypes[i]));
        }
        subTypes.length = 0;

        var typeUri = this.rdf.resolve("vbc:" + type);
        var versionUri = this.rdf.resolve("vbc:" + "beeppackage_BeepPackage-version");
        var rdfTypeUri = this.rdf.resolve("rdf:type");


        var nameSpace = rdfModel.rdf.resolve('vbc:cmof_EObject-name');

        //var query = "SELECT ?doc ?docType ?parent ?parentType ";
        var query = "SELECT ?doc ?parent ";
        if (!rdfModels) {
            rdfModels = [];
            rdfModels.push(self);
        }
        for (var i = 0; i < rdfModels.length; i++) {
            if (rdfModels[i]) {
                query = query + " FROM <" + rdfModels[i].graphUri + "> ";
            }
        }
        var podUri = this.rdf.resolve("vbc:" + "partOfDirectly");
        query = query + " FROM <" + rdfModel.schemaModel.graphUri + "> {";

        //query = query + " ?parent <" + rdfTypeUri + "> ?parentType . ";
        //query = query + " ?doc <" + rdfTypeUri + "> ?docType . ";

        query = query + " ?parent <" + nameSpace+ "> \"" + names[0] + "\" . ";
        if(names.length < 2) {
        	query = query + " ?doc <" + nameSpace + "> \"" + names[names.length - 1] + "\" . ";
        }else {
        	query = query + " ?doc <" + nameSpace + "> \"" + names[names.length - 1] + "\" . ";
        	for (var i = 1; i < names.length - 1; i++) {
	            if (i === 1) {
	                query = query + " ?p1 <" + podUri + ">  ?parent . "; 
	                query = query + " ?p1 <" + nameSpace + "> \"" + names[i] + "\" . "; 
	            } else {
	                query = query + " ?p" + i + " <" + podUri + ">  ?p" + (i - 1) + " . "; 
	                query = query + " ?p" + i + " <" + nameSpace+ "> \"" + names[i] + "\" . ";
	            }
	        }
	        query = query + " ?doc <" + podUri + ">  ?p" + (names.length - 2) + " . ";
        }
        

        if (typeUriArray.length > 1) {
            query = query + "{";
            for (var j = 0; j < typeUriArray.length; j++) {
                if (j > 0) {
                    query = query + " UNION ";
                }
                query = query + "{ ?doc <" + rdfTypeUri + "> <" + typeUriArray[j] + ">. }";
            }
            query = query + "} .";
        } else {
            query = query + " ?doc <" + rdfTypeUri + "> <" + typeUri + ">. ";
        }

        query = query + " }";
        rdfModel.store.execute(query, function (success, results) {
            var doc, docType, parentId, parentType;
            var res = [];
            if (results && results.length > 0) {
            	for(var i=0;i<results.length;i++){
	                if (results[i].doc) {
	                    doc = results[i].doc.value.substr(RDFModel.vdmbeeInst.length);
	                }
	                if (results[i].docType) {
	                    docType = results[i].docType.value.substr(RDFModel.vdmbeeSchema.length);
	                }
	                if (results[i].parent) {
	                    parentId = results[i].parent.value.substr(RDFModel.vdmbeeInst.length);
	                }
	                if (results[i].parentType) {
	                    parentType = results[i].parentType.value.substr(RDFModel.vdmbeeSchema.length);
	                }
	                res.push({'doc':doc, 'parent':parentId, 'docType':docType, 'parentType':parentType});
	                if(!fetchAllResults){
            			break;
            		}
            	}
            }
            callback(res);
        });
    }

	RDFModel.prototype.getNestedParent2 = function(docId,callback,childIdPath,childNamePath,childTypePath,nameNS){
		var rdfModel = this;
		var nameUris = [];
		if(nameNS){
			var namesArray = nameNS.split(",");
			_.each(namesArray,function(nameNSPart){
				nameUris.push(rdfModel.rdf.resolve(nameNSPart));
			});
		}else{
            nameUris.push(rdfModel.rdf.resolve("vbc:" + "cmof_EObject-name"));	
		}
		function getNameQuery(element,optional){
			var nameQuery = "";
			
			if(nameUris.length>1){
				nameQuery = nameQuery +  "{ { " + element + " <"+ nameUris[0] + ">  "+ element + "Name . } "; 
				for(var i=1;i<nameUris.length;i++){
					nameQuery = nameQuery +  "UNION { " + element + " <"+ nameUris[i] + ">  " + element + "Name. } "; 
				}
				nameQuery = nameQuery +  " } ";
			}else{
				nameQuery = nameQuery + (optional ? " OPTIONAL { " : "") +  element + " <"+ nameUris[0] + ">  "+ element +"Name "+ (optional ? " } " : "") + ". "; 
			}
			return nameQuery;
		}
		
		var podUri = this.rdf.resolve("vbc:" + "partOfDirectly");
		var versionUri = this.rdf.resolve("vbc:" + "beeppackage_BeepPackage-version");
		var subjectUri = this.rdf.resolve("vbi:" + docId);
		var rdfTypeUri = this.rdf.resolve("rdf:type");
		
		var query = "SELECT ?parent ?parentName ?type ?subjectVersion ?p1 ?p1Name ?p1Type ?p2 ?p2Name ?p2Type ?p2 ?p2Name ?p2Type ?p3 ?p3Name ?p3Type ?p4 ?p4Name ?p4Type" + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
		//var query = "SELECT ?parent ?parentName ?type ?subjectVersion " + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
		query = query + "{ ?parent <" + rdfTypeUri + "> ?type . ";
		query = query + " ?parent <" + versionUri + "> ?subjectVersion . {";
		
		query = query +  "{ <" + subjectUri + "> <"+ podUri + ">  ?parent . ";
		query = query + " FILTER NOT EXISTS { ?parent <" + podUri + "> ?nonExistant . } ";	
		
		query = query +  "} UNION { <" + subjectUri + "> <"+ podUri + ">  ?p1 .  "; 
		query = query +  " ?p1 <"+ podUri + ">  ?parent  "; 
		query = query + " FILTER NOT EXISTS { ?parent <" + podUri + "> ?nonExistant . } ";		
		
		query = query + " ?p1 <" + rdfTypeUri + "> ?p1Type  . ";
		query = query + getNameQuery("?p1");		
		
		query = query +  "} UNION { <" + subjectUri + "> <"+ podUri + ">  ?p1 .  "; 
		query = query +  " ?p1 <"+ podUri + ">  ?p2 . "; 
		query = query +  " ?p2 <"+ podUri + ">  ?parent . "; 
		query = query + " FILTER NOT EXISTS { ?parent <" + podUri + "> ?nonExistant . } ";
		
		query = query + " ?p1 <" + rdfTypeUri + "> ?p1Type  . ";
		query = query + getNameQuery("?p1");
		
		query = query + " ?p2 <" + rdfTypeUri + "> ?p2Type  . ";
		query = query + getNameQuery("?p2");		
		query = query +  "} UNION { <" + subjectUri + "> <"+ podUri + ">  ?p1 .  "; 
		query = query +  " ?p1 <"+ podUri + ">  ?p2 . "; 
		query = query +  " ?p2 <"+ podUri + ">  ?p3 . "; 	
		query = query +  " ?p3 <"+ podUri + ">  ?parent . "; 
		query = query + " FILTER NOT EXISTS { ?parent <" + podUri + "> ?nonExistant . } ";
		
		query = query + " ?p1 <" + rdfTypeUri + "> ?p1Type  . ";
		query = query + getNameQuery("?p1");
		
		query = query + " ?p2 <" + rdfTypeUri + "> ?p2Type  . ";
		query = query + getNameQuery("?p2");
		
		query = query + " ?p3 <" + rdfTypeUri + "> ?p3Type  . ";
		query = query + getNameQuery("?p3");	
		
		query = query +  "} UNION { <" + subjectUri + "> <"+ podUri + ">  ?p1 .  "; 
		query = query + " ?p1 <"+ podUri + ">  ?p2 . "; 
		query = query + " ?p2 <"+ podUri + ">  ?p3 . "; 	
		query = query + " ?p3 <"+ podUri + ">  ?p4 . "; 	
		query = query +  " ?p4 <"+ podUri + ">  ?parent . ";		
		query = query + " FILTER NOT EXISTS { ?parent <" + podUri + "> ?nonExistant . } ";		
		
		query = query + " ?p1 <" + rdfTypeUri + "> ?p1Type  . ";
		query = query + getNameQuery("?p1");
		
		query = query + " ?p2 <" + rdfTypeUri + "> ?p2Type  . ";
		query = query + getNameQuery("?p2");
		
		query = query + " ?p3 <" + rdfTypeUri + "> ?p3Type  . ";
		query = query + getNameQuery("?p3");
		
		query = query + " ?p4 <" + rdfTypeUri + "> ?p4Type . ";
		query = query + getNameQuery("?p4");
		
		query = query + "}} " + getNameQuery("?parent");
		
		query = query + "} UNION { ";	
		query = query + " <" + subjectUri + "> <" + rdfTypeUri + "> ?type . ";
		query = query + " <" + subjectUri + "> <" + versionUri + "> ?subjectVersion . ";
		if(nameUris.length>1){
			query = query +  "{ { <" + subjectUri + "> <"+ nameUris[0] + ">  ?name . } "; 
			for(var i=1;i<nameUris.length;i++){
				query = query +  "UNION { <" + subjectUri + "> <"+ nameUris[i] + ">  ?parentName. } "; 
			}
			query = query +  " } ";
		}else{
			query = query +  " <" + subjectUri + "> <"+ nameUris[0] + ">  ?parentName ."; 
		}				
		query = query + " FILTER NOT EXISTS { <" + subjectUri + "> <" + podUri + "> ?nonExistant2 . } ";
		query = query + "} } LIMIT 1 ";	
		
		rdfModel.store.execute(query,function(success, results){
			
			if(results && results.length> 0){
				var parentId;
				var parentName;
				var parentType;
				
				if(results[0].parent){
					parentId = results[0].parent.value.substr(RDFModel.vdmbeeInst.length);
					childIdPath = parentId;
					parentName = results[0].parentName.value;
					childNamePath = parentName;
					parentType = results[0].type.value.substr(RDFModel.vdmbeeSchema.length);
					childTypePath = parentType;
				}
				if(results[0].p4){
					var p4Id = results[0].p4.value.substr(RDFModel.vdmbeeInst.length);
					childIdPath = parentId + "/" + p4Id;
					var p4Name = results[0].p4Name.value;
					childNamePath = parentName + "/" + p4Name;
					var p4Type = results[0].p4Type.value.substr(RDFModel.vdmbeeSchema.length);
					childTypePath = parentType + "/" + p4Type;
				}				
				if(results[0].p3){
					var p3Id = results[0].p3.value.substr(RDFModel.vdmbeeInst.length);
					childIdPath = parentId + "/" + p3Id;
					var p3Name = results[0].p3Name.value;
					childNamePath = parentName + "/" + p3Name;
					var p3Type = results[0].p3Type.value.substr(RDFModel.vdmbeeSchema.length);
					childTypePath = parentType + "/" + p3Type;
				}				
				if(results[0].p2){
					parentId = results[0].p2.value.substr(RDFModel.vdmbeeInst.length);
					childIdPath = parentId + "/" + parentId;
					parentName = results[0].p2Name.value;
					childNamePath = parentName + "/" + parentName;
					parentType = results[0].p2Type.value.substr(RDFModel.vdmbeeSchema.length);
					childTypePath = parentType + "/" + parentType;
				}				
				
				if(results[0].p1){
					var p1Id = results[0].p1.value.substr(RDFModel.vdmbeeInst.length);
					childIdPath = parentId + "/" + p1Id;
					var p1Name = results[0].p1Name.value;
					childNamePath = parentName + "/" + p1Name;
					var p1Type = results[0].p1Type.value.substr(RDFModel.vdmbeeSchema.length);
					childTypePath = parentType + "/" + p1Type;
				}
/*				if(results[0].parent){
					parentId = results[0].parent.value.substr(RDFModel.vdmbeeInst.length);
				}*/
				callback({id:parentId ? parentId :docId , name:results[0].parentName.value , type:results[0].type.value,idPath:childIdPath,namePath:childNamePath,typePath:childTypePath,version:results[0].subjectVersion.value});
			}
			else{
				callback(null);
			}
		});
		query = podUri =  versionUri = subjectUri = rdfTypeUri = null;
	};
	RDFModel.prototype.getParentsByType = function(docId,type,instance){
		if(!type){
			return;
        }
		var modelType = type.replace('_','.');	
		var modelInstance = Backbone.Relational.store.getObjectByName(modelType).find({id:docId});
		if(modelInstance){
			if(instance){
				return modelInstance;
			}else {
				return modelInstance.getParentsHierarchy();
			}
		}
    };


	RDFModel.prototype.getNestedParent = function(docId,type,callback,childIdPath,childNamePath,childTypePath,nameNS){
		var rdfModel = this;
		var parents = rdfModel.getParentsByType(docId,type);
        if (parents) {
            if (parents.length > 0) {
                var nestedParent = parents.at(parents.length - 1);
                var idPath;
                var namePath;
                var typePath;
                parents.each(function (parent) {
                    idPath = idPath ? idPath + "/" + parent.get('id') : parent.get('id');
                    namePath = namePath ? namePath + "/" + parent.get('name') : parent.get('name');
                    typePath = idPath ? idPath + "/" + parent.get('type') : parent.get('type');
                });
                callback({ id: nestedParent.get('id'), name: nestedParent.get('name'), type: RDFModel.vdmbeeSchema + nestedParent.get('type'), version: nestedParent.get('version'), idPath: idPath, namePath: namePath, typePath: typePath });
            } else {
                //callback(null);
				var ins = rdfModel.getParentsByType(docId,type,true);
				callback({ id: ins.get('id'), name: ins.get('name'), type: RDFModel.vdmbeeSchema + ins.get('type'), version: ins.get('version'), idPath: null, namePath: null, typePath: null });
            }
			return;
		}
		var nameUris = [];
		if(nameNS){
			var namesArray = nameNS.split(",");
			_.each(namesArray,function(nameNSPart){
				nameUris.push(rdfModel.rdf.resolve(nameNSPart));
			});
		}else{
            nameUris.push(rdfModel.rdf.resolve("vbc:" + "cmof_EObject-name"));	
		}
		
		var podUri = this.rdf.resolve("vbc:" + "partOfDirectly");
		var versionUri = this.rdf.resolve("vbc:" + "beeppackage_BeepPackage-version");
		var subjectUri = this.rdf.resolve("vbi:" + docId);
		var rdfTypeUri = this.rdf.resolve("rdf:type");

		var query = "SELECT ?parent ?parentName ?parentType" + " FROM <"+ this.graphUri + "> FROM <"+ this.schemaModel.graphUri + "> {";
		query = query +  "<" + subjectUri + "> <"+ podUri + ">  ?parent ."; 
		query = query +  " ?parent <"+ rdfTypeUri + ">  ?parentType ."; 
		if(nameUris.length>1){
			query = query +  "{ { ?parent <"+ nameUris[0] + ">  ?parentName . } "; 
			for(var i=1;i<nameUris.length;i++){
				query = query +  "UNION { ?parent <"+ nameUris[i] + ">  ?parentName . } "; 
			}
			query = query +  " } ";
		}else{
			query = query +  " ?parent <"+ nameUris[0] + ">  ?parentName ."; 
		}
		
		query= query + "} ";
	
		this.store.execute(query,function(success, results){
			
			if(results && results.length > 0 ){
				var parentId = results[0].parent.value.substr(RDFModel.vdmbeeInst.length);
				var parentName = results[0].parentName.value;
				var parentType = results[0].parentType.value.substr(RDFModel.vdmbeeSchema.length);
				
				var repId = rdfModel.dataManager.getRepositoryId(parentId);
				var parentRDFModel = rdfModel
				if(repId !== rdfModel.name){
					parentRDFModel = rdfModel.dataManager.getRDFModel(repId);
				}	
				function checkRDFModelAndGetParent(){
					if(!parentRDFModel){
						setTimeout(checkRDFModelAndGetParent,10);
					}else{
						return parentRDFModel.getNestedParent(parentId,parentType,callback,childIdPath ? parentId + "/" + childIdPath  : parentId,
															childNamePath ? parentName + "/" + childNamePath  : parentName,
															childTypePath ? parentType + "/" + childTypePath  : parentType,nameNS);
					}
				}
				checkRDFModelAndGetParent();
			}else{
				var typeQuery = "SELECT ?name ?type ?subjectVersion" + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
				typeQuery = typeQuery + " <" + subjectUri + "> <" + rdfTypeUri + "> ?type . ";
				typeQuery = typeQuery + " <" + subjectUri + "> <" + versionUri + "> ?subjectVersion . ";
				if(nameUris.length>1){
					typeQuery = typeQuery +  "{ { <" + subjectUri + "> <"+ nameUris[0] + ">  ?name . } "; 
					for(var i=1;i<nameUris.length;i++){
						typeQuery = typeQuery +  "UNION { <" + subjectUri + "> <"+ nameUris[i] + ">  ?name. } "; 
					}
					typeQuery = typeQuery +  " } ";
				}else{
					typeQuery = typeQuery +  " <" + subjectUri + "> <"+ nameUris[0] + ">  ?name ."; 
				}				
				typeQuery = typeQuery + "} ";
				nameUris.length = 0;
				rdfModel.store.execute(typeQuery,function(success, results){
					typeQuery = null;
					if(results && results.length> 0){
						callback({id:docId , name:results[0].name.value , type:results[0].type.value,idPath:childIdPath,namePath:childNamePath,typePath:childTypePath,version:results[0].subjectVersion.value});
					}
					else{
						callback(null);
					}
				});
			}
		});
		query = null;
	};
	RDFModel.prototype.getAndFilterByNestedParentForSingleResult = function(result,filterPackagesNames,callback,nameNS,idAttr,filterPackagesIds){
		var rdfModel = this;
		function handleParent(parent){
			if(parent){
				var skip;
				if(filterPackagesNames.length >0 || (filterPackagesIds && filterPackagesIds.length > 0)){
					skip = true;
				}else{
					skip=false;
				}
				for(var i=0;i<filterPackagesNames.length;i++){
					if(filterPackagesNames[i] === parent.name){
						skip = false;
						break;
					}
				}
				if(filterPackagesIds){
					for(var i=0;i<filterPackagesIds.length;i++){
						if(filterPackagesIds[i] === parent.id){
							skip = false;
							break;
						}
					}
				}
				if(!skip){
					if(idAttr){
						result[idAttr + 'Parent'] = parent.id;
						result[idAttr + 'ParentName'] = parent.name;
						result[idAttr + 'ParentVersion'] = parent.version;
						result[idAttr + 'ParentType'] = parent.type.substr(RDFModel.vdmbeeSchema.length);
						result[idAttr + 'IDPath'] = parent.idPath;
						result[idAttr + 'NamePath'] = parent.namePath;
					}else{
						result.parent = parent.id;
						result.parentName = parent.name;
						result.version = parent.version;
						result.parentType = parent.type.substr(RDFModel.vdmbeeSchema.length);
						result.idPath = parent.idPath;
						result.namePath = parent.namePath;
					}
				}else{
					if(callback){
						callback();
					}
					return;
				}
			}
			if(callback){
				callback(result);	
			}
		}
		
		var id;
		var type;
		if(idAttr){
			id = result[idAttr];
			type = result[idAttr + 'Type'];  
		}else{
			id = result.id;
			type = result.type;  
		}
		var repId = rdfModel.dataManager.getRepositoryId(id);
		
		if(repId !== rdfModel.name){
			var docRDFModel = rdfModel.dataManager.getRDFModel(repId);
			docRDFModel.getNestedParent(id,type,handleParent,null,null,null,nameNS);
		}else{
			rdfModel.getNestedParent(id,type,handleParent,null,null,null,nameNS);	
		}		
	};
	
	RDFModel.prototype.getAndFilterByNestedParent = function(results,filterPackagesNames,callback,nameNS,idAttr,filterPackagesIds){
		var rdfModel = this;
		var endResults = [];
		if(!results || results.length <= 0){
			callback(endResults);
			return;
		}
		async.each(results,function(result,handleResultCallback){
			rdfModel.getAndFilterByNestedParentForSingleResult(result, filterPackagesNames, function(ret){
				if(ret){
					endResults.push(ret);	
				}
				handleResultCallback();
			}, nameNS, idAttr, filterPackagesIds);
		},function(){
			if(callback){
				callback(endResults);	
			}			
		});
	};
/*	RDFModel.prototype.getAndFilterByNestedParent = function(results,filterPackagesNames,callback,nameNS,idAttr,filterPackagesIds){
		var rdfModel = this;
		var endResults = [];
		if(!results || results.length <= 0){
			callback(endResults);
			return;
		}
		var count = 0;
		function handleParent(result){
			if(result){
				var skip;
				if(filterPackagesNames.length >0 || (filterPackagesIds && filterPackagesIds.length > 0)){
					skip = true;
				}else{
					skip=false;
				}
				for(var i=0;i<filterPackagesNames.length;i++){
					if(filterPackagesNames[i] === result.name){
						skip = false;
						break;
					}
				}
				if(filterPackagesIds){
					for(var i=0;i<filterPackagesIds.length;i++){
						if(filterPackagesIds[i] === result.id){
							skip = false;
							break;
						}
					}
				}
				if(!skip){
					if(idAttr){
						results[count][idAttr + 'Parent'] = result.id;
						results[count][idAttr + 'ParentName'] = result.name;
						results[count][idAttr + 'ParentVersion'] = result.version;
						results[count][idAttr + 'ParentType'] = result.type.substr(RDFModel.vdmbeeSchema.length);
						results[count][idAttr + 'IDPath'] = result.idPath;
						results[count][idAttr + 'NamePath'] = result.namePath;
						endResults.push(results[count]);						
					}else{
						results[count].parent = result.id;
						results[count].parentName = result.name;
						results[count].parentType = result.type.substr(RDFModel.vdmbeeSchema.length);
						results[count].idPath = result.idPath;
						results[count].namePath = result.namePath;
						endResults.push(results[count]);	
					}
				}
			}
			count++;
			if(count < results.length){
				var id;
				if(idAttr){
					id = results[count][idAttr];
				}else{
					id = results[count].id;
				}
				var repId = rdfModel.dataManager.getRepositoryId(id);
				if(repId !== rdfModel.name){
					var docRDFModel = rdfModel.dataManager.getRDFModel(repId);
					docRDFModel.getNestedParent(id,handleParent,null,null,null,nameNS);
				}else{
					rdfModel.getNestedParent(id,handleParent,null,null,null,nameNS);	
				}
			}
			else{
				if(callback){
					callback(endResults);	
				}
			}
		}
		if(idAttr){
			rdfModel.getNestedParent(results[count][idAttr],handleParent,null,null,null,nameNS);
		}else{
			rdfModel.getNestedParent(results[count].id,handleParent,null,null,null,nameNS);	
		}
	};*/
	
	RDFModel.prototype.getDocumentNames = function(searchTerm,docType,packages,tags,name,callback,nameNS,propertyExt,includeTags,documentOwner){
		var rdfModel = this;
		//this.print();
		var docTypeUri;
		var beepPackageUri;
		var userEmailUri;
		var nameUris = [];
		if(nameNS){
			var namesArray = nameNS.split(",");
			_.each(namesArray,function(nameNSPart){
				nameUris.push(rdfModel.rdf.resolve(nameNSPart));
			});
		}else{
            nameUris.push(rdfModel.rdf.resolve("vbc:" + "cmof_EObject-name"));	
		}
		
		var tagNameUri = this.rdf.resolve("vbc:" + "cmof_EObject-name");
		var versionUri = this.rdf.resolve("vbc:" + "beeppackage_BeepPackage-version");
		var tagUri = this.rdf.resolve("vbc:" + "cmof_EObject-tag");
		if(docType){
			docTypeUri = this.getDocumentTypeUri(docType);
		}
        if(documentOwner){
			beepPackageUri = this.rdf.resolve("vbc:" + "beeppackage_BeepPackage-user");
			userEmailUri =  this.rdf.resolve("vbc:" + "tickets_User-email");
		}
		var rdfTypeUri = this.rdf.resolve("rdf:type");
		
		var query = "SELECT DISTINCT ?s ?docName ?parent ?type ?parentName ";
		if(propertyExt){
			for(var i=0;i<propertyExt.length;i++){
				query = query + "?" + propertyExt[i].property + " ";
			}
		}
		var query = query + " FROM <"+ this.graphUri + "> FROM <"+ this.schemaModel.graphUri + "> {";
		query = query + " ?s <" + tagUri + "> ?t . ";
		//query = query + " ?s <" + versionUri + "> ?version . ";
		query = query + " ?s <" + rdfTypeUri + "> ?type . ";
		if(propertyExt){
			for(var i=0;i<propertyExt.length;i++){
				var propertyUri = this.rdf.resolve("vbc:" + propertyExt[i].predicate);
				query = query + " ?s <" + propertyUri + "> " + "?" + propertyExt[i].property + " . ";
			}
		}
		
		query = query + " ?t <" + tagNameUri + "> ?tagName . ";
		if(docType){
			query = query + " ?s <" + rdfTypeUri + "> <" +  docTypeUri + ">.";
		}
        if(documentOwner != undefined){
			if(documentOwner === "My Plans"){
				query = query + " ?s <" + beepPackageUri + "> ?u.";
				query = query + " ?u <" + userEmailUri + "> \"" + rdfModel.dataManager.get('email') + "\".";
			}else{
				query = query + " ?s <" + beepPackageUri + "> ?u.";
				query = query + " ?u <" + userEmailUri + "> ?email.";
				query = query + " FILTER ( ?email != \""+ rdfModel.dataManager.get('email') +"\") "
			}
		}
		if(name){
			//query = query + " ?s <" + nameUri + "> \"" + name + "\". ";
			if(nameUris.length>1){
				query = query +  "{ { ?s <"+ nameUris[0] + ">  \"" + name + "\". } "; 
				for(var i=1;i<nameUris.length;i++){
					query = query +  "UNION { ?s <"+ nameUris[i] + ">  \"" + name + "\". } "; 
				}
				query = query +  " } ";
			}else{
				query = query +  " ?s <"+ nameUris[0] + ">  \"" + name + "\"."; 
			}					
		}		
		//query = query + " ?s <" + nameUri + "> ?docName . ";
		if(nameUris.length>1){
			query = query +  "{ { ?s <"+ nameUris[0] + ">  ?docName . } "; 
			for(var i=1;i<nameUris.length;i++){
				query = query +  "UNION { ?s <"+ nameUris[i] + ">  ?docName . } "; 
			}
			query = query +  " } ";
		}else{
			query = query +  " ?s <"+ nameUris[0] + ">  ?docName ."; 
		}		
		for(var i=0;i<tags.length;i++){
			query = query + " ?s <" + tagUri + "> ?vdmTag" + i +" . ";
			query = query + " ?vdmTag" + i + " <" + tagNameUri + "> \"" + tags[i] + "\". ";
		}
		/*if(packages.length > 0){
			query= query + "{ ";
			for(var i=0;i<packages.length;i++){
				query= query + "{ ";
				query = query + " ?s <"+ podUri + ">+  ?parent ."; 
				query = query + " ?parent  <"+ rdfTypeUri + "> <" + vdmUri + ">."; 
				query = query + " ?parent  <"+ nameUri + "> \"" + packages[i] + "\"."; 
				query= query + "} UNION {";
				query = query + " ?s  <"+ nameUri + "> \"" + packages[i] + "\"."; 
				query = query + " ?s  <"+ rdfTypeUri + "> <" + vdmUri + ">. }"; 
				if(i<packages.length -1){
					query = query + " UNION ";
				}
			}
			query= query + "} ";
		}else{
				query= query + " {";
				query = query + " ?s <"+ podUri + ">*  ?parentName "; 
				query= query + "}"; 
		}*/

		query = query  + "}";
		
		this.store.execute(query,function(success, results){
			var ret = new Array();
			for(var i=0;i<results.length;i++){
				var result = {};
				result.id = results[i].s.value.substr(RDFModel.vdmbeeInst.length);
				result.type = results[i].type.value.substr(RDFModel.vdmbeeSchema.length);
				result.parent = results[i].parent ? results[i].parent.value.substr(RDFModel.vdmbeeInst.length) : result.id;
				//result.version = results[i].version.value;
				result.value = results[i].docName.value;
				result.label = results[i].docName.value;
				if(propertyExt){
					for(var j=0;j<propertyExt.length;j++){
						result[propertyExt[j].property] = results[i][propertyExt[j].property].value;
					}
				}				
				ret.push(result);
			}
            results.length = 0;
            if (includeTags) {
                rdfModel.getAndFilterByNestedParent(ret, packages, function (filteredResults) {
                    async.each(filteredResults, function (rec, gotTags) {
                        rdfModel.getTagsOfDocument(rec.id, [rdfModel], function (tags) {
                            rec['tag'] = [];
                            for (var k = 0; k < tags.length; k++) {
                                rec['tag'].push(tags[k].value);
                            }
                            gotTags();
                        });
                    }, function (e) {
                        if (callback) {
                            callback(filteredResults);
                        }
                    });
                }, nameNS);

            } else {
                rdfModel.getAndFilterByNestedParent(ret, packages, callback, nameNS);
            }
		});
		query = null;
	};	

	RDFModel.prototype.getDiagramsfromPackage = function(id, type, callback){
		var pacId = this.rdf.resolve("vbi:" + id);
		var docTypeUri = this.rdf.resolve("vbc:" + type);
        var nameUri = this.rdf.resolve("vbc:" + "cmof_EObject-name");
		
		var query = "SELECT ?s ?docName " +" FROM <"+ this.graphUri + "> FROM <"+ this.schemaModel.graphUri + "> {";
		query = query +  "<" + pacId + "> <"+ docTypeUri + ">  ?s ."; 
		query = query + " ?s <" + nameUri + "> ?docName  ";
		query = query  + "}";
		this.store.execute(query,function(success, results){
			var ret = new Array();
			for(var i=0;i<results.length;i++){
				var result = {};
				result.id = results[i].s.value.substr(RDFModel.vdmbeeInst.length);
				result.name = results[i].docName.value;
				ret.push(result);
			}
			results.length = 0;
			callback(ret);
		});
		query = null;
	};
	
	RDFModel.prototype.getPackagesWithNames = function(callback){
		var rdfTypeUri = this.rdf.resolve("rdf:type");
		var docTypeUri = this.rdf.resolve("vbc:" + "vdml_ValueDeliveryModel");
        var nameUri = this.rdf.resolve("vbc:" + "cmof_EObject-name");
		var versionUri = this.rdf.resolve("vbc:" + "beeppackage_BeepPackage-version");
		
		var query = "SELECT ?s ?docName ?version " +" FROM <"+ this.graphUri + "> FROM <"+ this.schemaModel.graphUri + "> {";
		query = query + " ?s <" + rdfTypeUri + "> <" +  docTypeUri + ">.";
		query = query + " ?s <" + nameUri + "> ?docName . ";
		query = query + " ?s <" + versionUri + "> ?version  ";//todo get Type too
		query = query  + "}";
		this.store.execute(query,function(success, results){
			var ret = new Array();
			for(var i=0;i<results.length;i++){
				var result = {};
				result.id = results[i].s.value.substr(RDFModel.vdmbeeInst.length);
				result.version = results[i].version.value;
				result.name = results[i].docName.value;
				ret.push(result);
			}
			results.length = 0;
			callback(ret);
		});
		query = null;
	};
	RDFModel.prototype.getSubTypes = function(typeObj,subTypes){
		if(typeObj.prototype.subModelTypes){
			var subTypeProperties = Object.getOwnPropertyNames(typeObj.prototype.subModelTypes);
			for(var i=0;i<subTypeProperties.length;i++){
				var subTypeStr = subTypeProperties[i].replace('_','.');
				subTypes.push(subTypeProperties[i]);
				var subTypeObj = Backbone.Relational.store.getObjectByName(subTypeStr);
				if(subTypeObj){
					this.getSubTypes(subTypeObj,subTypes);
				}
			}
		}
	};
	/*RDFModel.prototype.getCountOfDocumentsOfType = function(type,callback,rdfModels){
      var self = this;
      var subTypes = [];
      var typeStr = type.replace('_','.');
      subTypes.push(type);
      var typeObj = Backbone.Relational.store.getObjectByName( typeStr );
      this.getSubTypes(typeObj,subTypes);
      var typeUriArray = [];
	  for(var i=0;i<subTypes.length;i++){
	      typeUriArray.push(this.rdf.resolve("vbc:" + subTypes[i]));
	  }
      subTypes.length = 0;
      
      var typeUri = this.rdf.resolve("vbc:" + type);
      var rdfTypeUri = this.rdf.resolve("rdf:type");
      
      var query = "SELECT (COUNT(?document) AS ?count)";
      if(!rdfModels){
      	rdfModels = [];
      	rdfModels.push(self);
      }
	  for(var i=0;i<rdfModels.length;i++){
	  		if(rdfModels[i]){
	  			query = query + " FROM <"+ rdfModels[i].graphUri + "> ";	
	  		}
	  }
	  query = query + " FROM <"+ self.schemaModel.graphUri + "> {";      
      if(typeUriArray.length >1){
        query = query + "{";
        for(var j=0;j<typeUriArray.length;j++){
          if(j > 0){
            query = query + " UNION ";
          }
          query = query + "{ ?document <" + rdfTypeUri + "> <" + typeUriArray[j]+ ">. }";
        }
        query = query + "}";
      }else{
        query = query + " ?document <" + rdfTypeUri + "> <" + typeUri+ ">. "; 
      }
      query = query  + " }";
      this.store.execute(query,function(success, results){
      	typeUriArray.length = 0;
        var result = {};
        for(var i=0;i<results.length;i++){
          if(!results[i].count.value){
          	continue;
          }
          result.count = parseInt(results[i].count.value);
        }
        results.length = 0;
        if(callback){
        	callback(result);
        }
      }); 
      query = null;
  	};*/	
    RDFModel.prototype.getAllDocumentsOfType = function (type, callback, queryExt, filterPackagesIds, nameNS, rdfModels, skipParent,getDesc) {
        var self = this;
        if (!rdfModels) {
            rdfModels = [];
            rdfModels.push(self);
        }

        var nameUris = [];
        var descUris = [];
        if (nameNS) {
            var namesArray = nameNS.split(",");
            _.each(namesArray, function (nameNSPart) {
                nameUris.push(self.rdf.resolve(nameNSPart));
                var descNSPart = nameNSPart.replace('name', 'description');
                descUris.push(self.rdf.resolve(descNSPart));
            });
        } else {
            nameUris.push(self.rdf.resolve("vbc:" + "cmof_EObject-name"));
            descUris.push(self.rdf.resolve("vbc:" + "cmof_EObject-description"));
        }

        function getNameDescQuery() {
            var nameQuery = "";

            if (nameUris.length > 1) {
                nameQuery = nameQuery + "{ { ?document <" + nameUris[0] + ">  ?documentName . ";
                if (getDesc) {
                    nameQuery = nameQuery + " OPTIONAL { ?document <" + descUris[0] + "> ?documentDescription }} ";
                } else {
                    nameQuery = nameQuery + "} "
                }
                
                for (var i = 1; i < nameUris.length; i++) {
                    nameQuery = nameQuery + "UNION { ?document <" + nameUris[i] + ">  ?documentName . ";
                    if (getDesc) {
                        nameQuery = nameQuery + " OPTIONAL { ?document <" + descUris[i] + "> ?documentDescription }} ";
                    } else {
                        nameQuery = nameQuery + "} "
                    }
                    
                }
                nameQuery = nameQuery + " } ";
            } else {
                nameQuery = nameQuery + "{ ?document <" + nameUris[0] + ">  ?documentName . ";
                if (getDesc) {
                    nameQuery = nameQuery + " OPTIONAL { ?document <" + descUris[0] + "> ?documentDescription }} . ";
                } else {
                    nameQuery = nameQuery + " } ";
                }
            }
            return nameQuery;
        }

        var subTypes = [];
        var typeArray = type.split(",");
        for (var i = 0; i < typeArray.length; i++) {
            var typeStr = typeArray[i].replace('_', '.');
            subTypes.push(typeArray[i]);
            var typeObj = Backbone.Relational.store.getObjectByName(typeStr);
            this.getSubTypes(typeObj, subTypes);
        }
        subTypes = _.uniq(subTypes);

        var typeUriArray = [];
        for (var i = 0; i < subTypes.length; i++) {
            typeUriArray.push(this.rdf.resolve("vbc:" + subTypes[i]));
        }
        subTypes.length = 0;

        var typeUri = this.rdf.resolve("vbc:" + type);
        var versionUri = this.rdf.resolve("vbc:" + "beeppackage_BeepPackage-version");
        var rdfTypeUri = this.rdf.resolve("rdf:type");

        var query = "SELECT ?document ?documentName ?documentVersion ?documentType ";
        if(getDesc){
        	query = query + "?documentDescription ";
        }
        for (var i = 0; i < rdfModels.length; i++) {
            if (rdfModels[i]) {
                query = query + " FROM <" + rdfModels[i].graphUri + "> ";
            }
        }
        query = query + " FROM <" + self.schemaModel.graphUri + "> {";
        query = query + " ?document <" + rdfTypeUri + "> ?documentType . "
        if (typeUriArray.length > 1) {
            query = query + "{";
            for (var j = 0; j < typeUriArray.length; j++) {
                if (j > 0) {
                    query = query + " UNION ";
                }
                query = query + "{ ?document <" + rdfTypeUri + "> <" + typeUriArray[j] + ">. }";
            }
            query = query + "} .";
        } else {
            query = query + " ?document <" + rdfTypeUri + "> <" + typeUri + ">. ";
        }
        query = query + getNameDescQuery();
        query = query + " OPTIONAL { ?document <" + versionUri + ">  ?documentVersion } . ";
        if (queryExt && queryExt !== "") {
            query = query + queryExt;
        }
        query = query + " }";
        this.store.execute(query, function (success, results) {
            typeUriArray.length = 0;
            var ret = [];
            for (var i = 0; i < results.length; i++) {
                var result = {};
                result.id = results[i].document.value.substr(RDFModel.vdmbeeInst.length);
                if (results[i].documentVersion) {
                    result.version = results[i].documentVersion.value;
                }
                result.name = results[i].documentName.value;
                if (results[i].documentDescription) {
                    result.description = results[i].documentDescription.value;
                }
                result.type = results[i].documentType.value.substr(RDFModel.vdmbeeSchema.length);
                ret.push(result);
            }
            var handleResultWithParent = function () {
                callback(ret);
            };
            if (!skipParent) {
                self.getAndFilterByNestedParent(ret, [], handleResultWithParent, nameNS, null, filterPackagesIds);
            } else {
                callback(ret);
            }
            results.length = 0;
        });
        query = null;
    };

    RDFModel.prototype.getAllDocumentsCollectionOfType = function (type, callback, queryExt, filterPackagesIds, nameNS, rdfModels, skipParents,getDesc) {
        var self = this;
        var createCollection = function (ret) {
            var retColl = new Backbone.Collection();
            for (var i = 0; i < ret.length; i++) {
                if (ret[i].id === ret[i].parent && ret[i].parentType) {
                    ret[i].type = ret[i].parentType;
                }
                var model = new Backbone.Model({ id: ret[i].id, name: ret[i].name, description: ret[i].description, version: ret[i].version, parent: ret[i].parent, parentName: ret[i].parentName, type: ret[i].type, namePath: ret[i].namePath, idPath: ret[i].idPath });
                retColl.push(model);
            }
            callback(retColl);
        }
        //TODO : doing this as UNION of sparql not working///
        var typeArray = type.split(",");

        /*var subTypes = new Array();
        for(var i=0;i<typeArray.length;i++){
          var typeStr = typeArray[i].replace('_','.');
          subTypes.push(typeStr);
          var typeObj = Backbone.Relational.store.getObjectByName( typeStr );
          this.getSubTypes(typeObj,subTypes);
        }*/

        var count = typeArray.length;
        var docsArray = new Array();
        function getDocuments(ret) {
            if (ret) {
                docsArray = docsArray.concat(ret);
            }
            count--;
            if (count >= 0) {
                self.getAllDocumentsOfType(typeArray[count], getDocuments, queryExt, filterPackagesIds, nameNS, rdfModels, skipParents, getDesc);
            } else {
                createCollection(docsArray);
            }
        }
        getDocuments();
    };
	
	
	RDFModel.prototype.getTags = function(searchTerm,docType,packages,tags,name,namePropertyNS,callback){
		var rdfModel = this;
		//this.print();
		var docTypeUri;
		var nameUri;
		if(namePropertyNS){
			nameUri = this.rdf.resolve(namePropertyNS);
		}else{
            nameUri = this.rdf.resolve("vbc:" + "cmof_EObject-name");	
		}		
		var tagNameUri = this.rdf.resolve("vbc:" + "cmof_EObject-name");
		var tagUri = this.rdf.resolve("vbc:" + "cmof_EObject-tag");
		if(docType){
			docTypeUri = this.getDocumentTypeUri(docType);
		}
		var rdfTypeUri = this.rdf.resolve("rdf:type");
		
		var query = "SELECT DISTINCT ?t ?tagName" + " FROM <"+ this.graphUri + "> FROM <"+ this.schemaModel.graphUri + "> {";
		query = query + " ?s <" + tagUri + "> ?t . ";
		query = query + " ?t <" + tagNameUri + "> ?tagName . ";
		if(docType){
			query = query + " ?s <" + rdfTypeUri + "> <" +  docTypeUri + ">.";
		}
		if(name){
			query = query + " ?s <" + nameUri + "> \"" + name + "\". ";
		}
		for(var i=0;i<tags.length;i++){
			query = query + " ?s <" + tagUri + "> ?vdmTag . ";
			query = query + " ?vdmTag <" + tagNameUri + "> \"" + tags[i] + "\". ";
		}
		/*if(packages.length > 0){
			query= query + "{ ";
			for(var i=0;i<packages.length;i++){
				query= query + "{ ";
				query = query + " ?s <"+ podUri + ">+  ?parent ."; 
				query = query + " ?parent  <"+ rdfTypeUri + "> <" + vdmUri + ">."; 
				query = query + " ?parent  <"+ nameUri + "> \"" + packages[i] + "\"."; 
				query= query + "} UNION {";
				query = query + " ?s  <"+ nameUri + "> \"" + packages[i] + "\"."; 
				query = query + " ?s  <"+ rdfTypeUri + "> <" + vdmUri + ">. }"; 
				if(i<packages.length -1){
					query = query + " UNION ";
				}
				
			}
			query= query + "} ";
		}*/
		if(tags.length > 0){
			query = query + " FILTER ( ";
			for(var i=0;i<tags.length;i++){
				query = query + " ?tagName != \"" +  tags[i] + "\" ";
				if(i<tags.length -1){
					query = query + " && "
				}
			}
			query = query + " ) ";
		}
		
		query = query  + "}";
		
		this.store.execute(query,function(success, results){
			var ret = [];
			for(var i=0;i<results.length;i++){
				var result = {};
				result.id = results[i].t.value.substr(RDFModel.vdmbeeInst.length);
				result.value = results[i].tagName.value;
				result.label = results[i].tagName.value;
				result.type = "cmof.Tag";
				ret.push(result);
			}
			results.length = 0;
			rdfModel.getAndFilterByNestedParent(ret,packages,callback,namePropertyNS);
		});
		query = null;
	};

/*	RDFModel.prototype.getTickets = function(id,ticketarray,callback){
			var rdfModel = this;
			var participant = rdfModel.rdf.resolve("vbi:" + id);
			var ticketPredicate = rdfModel.rdf.resolve("vbc:" + "tickets_Ticket-referredElement");
			var query = "SELECT ?ticket " + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
				query = query +  " ?ticket <" + ticketPredicate + ">  <" + participant + "> . ";
				query= query + "} ";
					
			rdfModel.store.execute(query,function(success, results){
				for(var i=0;i<results.length;i++){
					ticketarray.push(results[i].ticket.value.substr(RDFModel.vdmbeeInst.length));
				}
				if(callback){
					callback();
				}
			});
	}*/

	RDFModel.prototype.getChildren = function(id,callback){
			var rdfModel = this;
			var participant = rdfModel.rdf.resolve("vbi:" + id);
			var podUri = this.rdf.resolve("vbc:" + "partOfDirectly");
			var query = "SELECT ?children " + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
				query = query +  " ?children <" + podUri + "> <" + participant + "> . ";
				query= query + "}";
			var children=[];
			var count =0;
			rdfModel.store.execute(query,function(success, results){
				if(results.length>0){
					for(var i=0;i<results.length;i++){
						var id = results[i].children.value.substr(RDFModel.vdmbeeInst.length);
						if(_.indexOf(children,id) < 0){
							children.push(id);	
						}
					}
					function getChildsChildren(subChildren){
						if(subChildren && subChildren.length > 0){
							children = children.concat(subChildren);
						}
						if(count < children.length){
							rdfModel.getChildren(children[count++],getChildsChildren);
						}else{
							callback(children);		
						}
					}
					getChildsChildren();
						
				}else{
					callback(children);
				}
				
			});
	};	
	
	/*RDFModel.prototype.getAllTickets = function(id,callback){
		var rdfModel = this;
		var tickets = [];
		function getTicketsOfChildren(children){
			children.push(id);
			var count = children.length;
			function getTickets(){
				count--;
				if(count >= 0){
					rdfModel.getTickets(children[count], tickets, getTickets)
				}else{
					callback(tickets);
				}
			}
			getTickets();
		}
		
		rdfModel.getChildren(id, getTicketsOfChildren);
	}*/
	
	RDFModel.prototype.getPlanOfPhase = function(id,callback){
			var rdfModel = this;
			var phase = rdfModel.rdf.resolve("vbi:" + id);
			var phasePredicate = rdfModel.rdf.resolve("vbc:" + "transformation_Plan-phase");

			var query = "SELECT ?plan " + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
				query = query +  " ?plan <" + phasePredicate + ">  <" + phase + "> . ";
				query= query + "} ";
					
			rdfModel.store.execute(query,function(success, results){
				if(results.length >0){
					callback(results[0].plan.value.substr(RDFModel.vdmbeeInst.length));
				}else{
					callback();
				}
				results.length = 0;
			});
			query = null;			
	};	
	RDFModel.prototype.getPlanPhaseOfAlternative= function(id,callback){
			var rdfModel = this;
			var alternative = rdfModel.rdf.resolve("vbi:" + id);
			var phasePredicate = rdfModel.rdf.resolve("vbc:" + "transformation_Plan-phase");
			var phaseAlternativePredicate = rdfModel.rdf.resolve("vbc:" + "transformation_Phase-phaseAlternative");
			var planVersionPredicate = rdfModel.rdf.resolve("vbc:" + "beeppackage_BeepPackage-version");

			var query = "SELECT ?plan ?version ?phase" + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
			    query = query + " ?plan <" + phasePredicate + ">  ?phase . ";
			    query = query + " ?plan <" + planVersionPredicate + ">  ?version . ";
				query = query +  " ?phase <" + phaseAlternativePredicate + ">  <" + alternative + "> . ";
				query= query + "} ";
					
			rdfModel.store.execute(query,function(success, results){
				if(results.length >0){
				    callback(results[0].plan.value.substr(RDFModel.vdmbeeInst.length), results[0].phase.value.substr(RDFModel.vdmbeeInst.length), results[0].version.value);
				}else{
					callback();
				}
				results.length = 0;
			});
			query = null;
	};	
	
	RDFModel.prototype.getPlanTickets= function(callback){
		var rdfModel = this;
		var ticketPredicate = rdfModel.rdf.resolve("vbc:" + "tickets_Ticket-referredElement");
		var query = "SELECT ?ticket " + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
			query = query +  " ?ticket <" + ticketPredicate + ">  ?t . ";
			query= query + "} ";
				
		rdfModel.store.execute(query,function(success, results){
			if(results.length >0){
				callback(results.length);
			}else{
				callback(0);
			}
			results.length = 0;
		});
	};
	

	RDFModel.prototype.getAlternativeCollaborations= function(callback){
		var rdfModel = this;
		var collaborationPredicate = rdfModel.rdf.resolve("vbc:" + "vdml_ValueDeliveryModel-collaboration");
		var query = "SELECT ?collaboration " + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
			query = query +  " ?s <" + collaborationPredicate + ">  ?collaboration. ";
			query= query + "} ";
		var vdmStore = rdfModel.dataManager.getVDMStore(rdfModel.name);
		rdfModel.store.execute(query,function(success, results){
			
			var children = [];
			var childrenModels = [];
			if(results.length >0){
				for(var i=0;i<results.length;i++){
					var result = {};
					result.id = results[i].collaboration.value.substr(RDFModel.vdmbeeInst.length);
					result.type = "vdml.Collaboration";
					children.push(result);	
				}
				results.length = 0;
			}
			function getChildModels(result){
				var count = result.length;
				function fetchCollaboration(){
					count--;
					if(count >=0){
						rdfModel.dataManager.fetchDocumentFromPackage(result[count].parent,"appbo/vdml/ValueDeliveryModel",rdfModel.dataManager.get('currentVDMVersion'),result[count].id,"appbo/vdml/Collaboration",vdmStore,{
								success:function(model){
									childrenModels.push(model);
									fetchCollaboration();
								},
								error:function(error){
									console.log(error);
									fetchCollaboration();
								}
						});
					}else{
						callback(childrenModels);		
					}
				}
				fetchCollaboration();
			};
			
			rdfModel.getAndFilterByNestedParent(children,[],getChildModels);
		});
		query = null;
	};
	
	
// works with master version of rdf store..(custom filters but our other queries not working)	
	RDFModel.prototype.isAlternativeReference = function(engine,args) {
		var rdfTypeUri = this.rdf.resolve("rdf:type");
        //var o1 = engine.effectiveTypeValue(args[0]);	// customized rdfstore.js...to support uri objects
        var p1 = engine.effectiveTypeValue(args[1]);	
		if(p1 === rdfTypeUri || (args[0].token === 'literal')){
			return engine.ebvBoolean(false);
		}
        return engine.ebvBoolean(true);
    }	

    RDFModel.prototype.getTagsOfDocument = function (modelId,rdfModels, callback) {
        var rdfModel = this;
        var s = rdfModel.rdf.resolve("vbi:" + modelId);

        var tagNameUri = this.rdf.resolve("vbc:" + "cmof_EObject-name");
        var tagUri = this.rdf.resolve("vbc:" + "cmof_EObject-tag");
        var rdfTypeUri = this.rdf.resolve("rdf:type");

        var query = "SELECT DISTINCT ?t ?tagName ";
        for (var i = 0; i < rdfModels.length; i++) {
            query = query + " FROM <" + rdfModels[i].graphUri + "> ";
        }
        query = query + " FROM <" + this.schemaModel.graphUri + "> {";
        query = query + " <" + s + "> <" + tagUri + "> ?t . ";
        query = query + " ?t <" + tagNameUri + "> ?tagName . ";
        query = query + "}";

        this.store.execute(query, function (success, results) {
            var ret = [];
            for (var i = 0; i < results.length; i++) {
                var result = {};
                result.id = results[i].t.value.substr(RDFModel.vdmbeeInst.length);
                result.value = results[i].tagName.value;
                result.label = results[i].tagName.value;
                result.type = "cmof.Tag";
                ret.push(result);
            }
            results.length = 0;
            if (callback) {
                callback(ret);
            }
        });
        query = null;
    };

	RDFModel.prototype.getReferenceToAlternative = function(alternativeId,callback,filterPackageIds,rdfModels){
		
		var rdfModel = this;
		var rdfTypeUri = this.rdf.resolve("rdf:type");
		var repIdUri = this.rdf.resolve("vbc:" + 'cmof_EObject' + "-" + 'repId');
		var alternativeUri = rdfModel.rdf.resolve("vbi:" + alternativeId)
		
		var query1 = "SELECT ?s ?p ?o ?type ?objectType ";
			for(var i=0;i<rdfModels.length;i++){
				query1 = query1 + " FROM <"+ rdfModels[i].graphUri + "> ";
			}
			query1 = query1 + " FROM <"+ rdfModel.schemaModel.graphUri + "> {";
			query1 = query1 +  " ?s  ?p ?o . ";
			query1 = query1 + " ?s <" + rdfTypeUri + "> ?type . ";
			query1 = query1 +  " ?o <" + rdfTypeUri + "> ?objectType .  ";
			query1 = query1 + " ?s <" + repIdUri + "> <" + alternativeUri + "> . ";
			query1 = query1 + " FILTER NOT EXISTS { ?o <" + repIdUri + "> <" + alternativeUri + ">}. ";
			query1 = query1 + " filter(custom:isAlternativeReference(?o,?p)). ";
			query1 = query1 +  " FILTER ( (?type != <http://www.w3.org/2000/01/rdf-schema#Class>) && (?type != <http://www.w3.org/1999/02/22-rdf-syntax-ns#Property>)) " ;
			query1= query1 + "} ";
			
		var query2 = "SELECT ?s ?p ?o ?type ?objectType ";
			for(var i=0;i<rdfModels.length;i++){
				query2 = query2 + " FROM <"+ rdfModels[i].graphUri + "> ";
			}
			query2 = query2 + " FROM <"+ rdfModel.schemaModel.graphUri + "> {";
			query2 = query2 +  " ?s  ?p ?o . ";
			query2 = query2 + " ?s <" + rdfTypeUri + "> ?type . ";
			query2 = query2 +  " ?o <" + rdfTypeUri + "> ?objectType .  ";
			query2 = query2 + " ?o <" + repIdUri + "> <" + alternativeUri + "> . ";
			query2 = query2 + " FILTER NOT EXISTS { ?s <" + repIdUri + "> <" + alternativeUri + ">}. ";
			query2 = query2 + " filter(custom:isAlternativeReference(?o,?p)). ";
			query2 = query2 +  " FILTER ( (?type != <http://www.w3.org/2000/01/rdf-schema#Class>) && (?type != <http://www.w3.org/1999/02/22-rdf-syntax-ns#Property>)) " ;
			query2= query2 + "} ";			
		
	    rdfModel.store.registerCustomFunction('isAlternativeReference', _.bind(rdfModel.isAlternativeReference,rdfModel));			
		rdfModel.store.execute(query1,function(success, results1){
			handleReferences (results1,[],function(ret1){
				results1.length= 0;
				rdfModel.store.execute(query2,function(success, results2){
					handleReferences (results2,filterPackageIds,function(ret2){
						results2.length = 0;
						callback(ret1.concat(ret2));
					});
					
				});
				query2 = null;
			});
		});
		query1 = null;
		function handleReferences (results,filterPackageIds,handleReferenceCallback){
				var ret = [];
				for(var i=0;i<results.length;i++){
					var result = {};
					result.id = results[i].s.value.substr(RDFModel.vdmbeeInst.length);
					result.predicate = results[i].p.value.substr(RDFModel.vdmbeeSchema.length);
					result.object =results[i].o.value.substr(RDFModel.vdmbeeInst.length);
					if(results[i].type){
						result.type = results[i].type.value.substr(RDFModel.vdmbeeSchema.length);
					}
					if(results[i].objectType){
						result.objectType = results[i].objectType.value.substr(RDFModel.vdmbeeSchema.length);	
					}
					ret.push(result);
				}
				function getParentsofObjects(){
					var alternativeRDFModel = rdfModel.dataManager.getRDFModel(alternativeId);
					var refCount = ret.length;
					function getTypes(){
						refCount--;
						if(refCount>=0){
							function checkAndGetObjectType(){
								if(ret[refCount].objectType){
									chekcAndGetSubjectType()
								}else{
									var objAltId = ret[refCount].object.substr(0,ret[refCount].object.lastIndexOf('@')+ 1);
									var objRdfModel = rdfModel.dataManager.getRDFModel(objAltId);
									objRdfModel.getTypeOfSubject(ret[refCount].object,function(type){
										if(type){
											ret[refCount].objectType = type;
										}
										chekcAndGetSubjectType();	
									});									
								}
							}
							function chekcAndGetSubjectType(){
								if(ret[refCount].type){
									getTypes();	
								}else{
									var subAltId = ret[refCount].id.substr(0,ret[refCount].id.lastIndexOf('@')+ 1);
									var subRdfModel = rdfModel.dataManager.getRDFModel(subAltId);
									rdfModel.getTypeOfSubject(ret[refCount].object,function(type){
										if(type){
											ret[refCount].type = type;
										}
										getTypes();
									});
								}							
							}
							checkAndGetObjectType();
						}else{
                            alternativeRDFModel.getAndFilterByNestedParent(ret, [], handleReferenceCallback,'vbc:cmof_EObject-name',"object",filterPackageIds);		
						}
					}
					getTypes();
				}
                rdfModel.getAndFilterByNestedParent(ret, [], getParentsofObjects,'vbc:cmof_EObject-name');				
		}
	};

	RDFModel.prototype.filterModelsPartOfParents = function(models,parents,callback){
		var self = this;
		var ret = [];
		var count = models.length;
		if(count === 0){
			callback(ret);
			return;
		}
		function checkParent(){
			function handleResult(isParent){
				if(!isParent){
					ret.push(models[count])
				}
				checkParent();
			}
			count--;
			if(count>=0){
				self.isModelPartOfParents(models[count].id, parents, handleResult)
			}else{
				callback(ret);
			}
		}
		checkParent();
	}
	
	RDFModel.prototype.isModelPartOfParents = function(modelId,parents,callback){
		var self = this;
		var count = parents.length;
		if(count === 0){
			callback(false);
			return;
		}
		function checkParent(isParent){
			if(isParent === true){
				callback(true);
				return;
			}
			count--;
			if(count>=0){
				self.isModelPartOfParent(modelId, parents[count], checkParent)
			}else{
				callback(false);
			}
		}
		checkParent();
	}
	
	RDFModel.prototype.isModelPartOfParent = function(modelId,parentId,callback){
		var rdfModel = this;
		if(modelId === parentId){
			callback(true);
			return;
		}
		var s = rdfModel.rdf.resolve("vbi:" + modelId);
		var o = rdfModel.rdf.resolve("vbi:" + parentId);
		var rdfTypeUri = this.rdf.resolve("rdf:type");
		var podUri = this.rdf.resolve("vbc:" + "partOfDirectly");
		
		var query = "SELECT ?type FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
			query = query + " <" + s + "> <" + rdfTypeUri + "> ?type . ";
			query = query + "{ { <" + s + "> <" + podUri +"> <" + o + "> . } ";
			query = query + "UNION { <"+ s +"> <" + podUri +"> ?c11 . ";
			query = query + " ?c11 <" + podUri + "> <" + o + "> . } ";
			query = query + "UNION { <"+ s +"> <" + podUri +"> ?c12 . ";
			query = query + " ?c12 <" + podUri + "> ?c13 . ";			
			query = query + " ?c13 <" + podUri + "> <" + o + "> . } ";
			query = query + "UNION { <"+ s +"> <" + podUri +"> ?c14 . ";
			query = query + " ?c14 <" + podUri + "> ?c15 . ";			
			query = query + " ?c15 <" + podUri + "> ?c16 . ";			
			query = query + " ?c16 <" + podUri + "> <" + o + "> . } ";
			query= query + "} }";

		rdfModel.store.execute(query,function(success, results){
			if(results.length > 0){
				if(results[0].type){
					callback(true);
				}else{
					callback(false);		
				}
			}else{
				callback(false);		
			}
		});
		query = s = o = rdfTypeUri = podUri = null;
	}
	
	RDFModel.prototype.getReferencesToModelFiltedByParents = function(model,parents,callback,getParents,namePropertyNS,rdfModels){
		var self = this;
		var ret = [];
		function handleResult(refResult){
			if(refResult){
				self.filterModelsPartOfParents(refResult, parents, callback);
			}				
		}
		self.getReferencesToModel2(model,handleResult,getParents,namePropertyNS,null,null,rdfModels)
	}
	
	RDFModel.prototype.getReferencesToModels = function(models,callback,getParents,namePropertyNS){
		var self = this;
		var count = models.length;
		var ret = [];
		function fetchModelReferences(){
			function handleFilterResult(filResult){
				ret = ret.concat(filResult);
				fetchModelReferences();
			}
			function handleResult(refResult){
				if(refResult){
					self.filterModelsPartOfParents(refResult, models, handleFilterResult);
				}				
			}
			count--;
			if(count>=0){
				self.getReferencesToModel(models[count],handleResult,getParents,namePropertyNS)
			}else{
				callback(ret);
			}
		}
		fetchModelReferences()
	};
	
	RDFModel.prototype.executeReferencesToModelQuery = function(model,callback,rdfModels){
		var ret = [];
		var rdfModel = this;
		var isTypeOptional = true;
	    if(!rdfModels){
	    	isTypeOptional = false;
	      	rdfModels = [];
	      	rdfModels.push(rdfModel);
	    }		
		var containedModels = model.getContainedModels();
		containedModels.push(model);
		var containedModelsCollection = new Backbone.Collection(containedModels);
		
		var modelId = model.get('id');
		
		var query = "PREFIX vbi: <" + RDFModel.vdmbeeInst + "> ";
			query = query + " SELECT DISTINCT ?s ?p ?type ";
			  for(var i=0;i<rdfModels.length;i++){
			  		if(rdfModels[i]){
			  			query = query + " FROM <"+ rdfModels[i].graphUri + "> ";	
			  		}
			  }
			  query = query + " FROM <"+ rdfModel.schemaModel.graphUri + "> {";  		
				query = query + "{";
				query = query + " ?s ?p ?o . ";
				if(isTypeOptional){
					query = query + " OPTIONAL { ?s rdf:type ?type . } ";
				}else{
					query = query + " ?s rdf:type ?type . ";	
				}
				
				query = query + " FILTER ( custom:filterChild(?s,?o)) . }";
				query = query + " UNION {";
				query = query + " ?o ?p ?s . ";
				if(isTypeOptional){
					query = query + " OPTIONAL { ?s rdf:type ?type . } ";
				}else{
					query = query + " ?s rdf:type ?type . ";	
				}
				query = query + " FILTER ( custom:filterChild(?s,?o)) . }";
				query = query + "}";
		
	    rdfModel.store.registerCustomFunction('filterChild', function(engine,args) {
	        var sub = engine.effectiveTypeValue(args[0]);	// customized rdfstore.js...to support uri objects
	        var sI = sub.indexOf('@');
	        if(sI < 0){
	        	return engine.ebvBoolean(false);
	        }else{
	        	sub = sub.substr(sI);
	        }
	        var obj = engine.effectiveTypeValue(args[1]);	// customized rdfstore.js...to support uri objects
			var oI = obj.indexOf('@');
	        if(oI < 0){
	        	return engine.ebvBoolean(false);
	        }else{
	        	obj = obj.substr(oI);
	        }
	        if(containedModelsCollection.get(obj) && !containedModelsCollection.get(sub)){
	        	return engine.ebvBoolean(true);	
	        }else{
	        	return engine.ebvBoolean(false);	
	        }
       
	    });		
	    
		rdfModel.store.execute(query,function(success, results){
			for(var i=0;i<results.length;i++){
				var subject = results[i].s.value;
				if(results[i].s.token === "literal" || subject.indexOf(RDFModel.vdmbeeSchema) >=0 || 
					results[i].p.value === RDFModel.vdmbeeSchema + "cmof_EObject-repId"){
					continue;
				}
				var sId = subject.substr(RDFModel.vdmbeeInst.length);
				if(sId !== modelId){
					var predicate = results[i].p.value.substr(RDFModel.vdmbeeSchema.length);
					if((results[i].type && results[i].type.value === 'http://www.w3.org/2000/01/rdf-schema#Class') || predicate === 'partOfDirectly'){
						continue;
					}
					var result = {};
					result.id = sId;
					result.predicate = predicate;
					if(results[i].type){
						result.type = results[i].type.value.substr(RDFModel.vdmbeeSchema.length);	
					}
					ret.push(result);	
				}
			}
			results.length = 0;
			function getTypesofObjects(){
				async.each(ret,function(ref,refHandled){
					if(!ref.type){
						var alternativeId = rdfModel.dataManager.getRepositoryId(ref.id);
						var alternativeRDFModel = rdfModel.dataManager.getRDFModel(alternativeId);
						if(alternativeRDFModel){
							alternativeRDFModel.getTypeOfSubject(ref.id,function(type,name){
								if(type){
									ref.type = type;
									ref.name = name;
								}
								refHandled();
							});				
						}
					}
					else{
						refHandled();
					}					
				},function(err){
					containedModelsCollection.reset();
					containedModels.length = 0;
					callback(ret);
				});
			}			
			getTypesofObjects();
		});
		query = null;
	};	
	RDFModel.prototype.getReferencesToModel2 = function(model,callback,getParents,namePropertyNS,filterParentNames,filterParentIds,rdfModels){
		var rdfModel = this;
		rdfModel.executeReferencesToModelQuery(model,function(ret){
			if(getParents){
				rdfModel.getAndFilterByNestedParent(ret,filterParentNames? filterParentNames :[],callback,namePropertyNS ? namePropertyNS : 'vbc:cmof_EObject-name',null,filterParentIds?filterParentIds:[]);
			}else{
				callback(ret);
			}			
		},rdfModels);
	};
	
	RDFModel.prototype.getReferencesToModel = function(modelId,callback,getParents,namePropertyNS){
		var ret = [];
		var rdfModel = this;
		var oReplace = rdfModel.rdf.resolve("vbi:" + modelId);
		var podUriReplace = this.rdf.resolve("vbc:" + "partOfDirectly");
		var rdfTypeUriReplace = this.rdf.resolve("rdf:type");
		
		if(!rdfModel.refToModelQuery){
			var o = '%o%';
			var podUri = '%p%';
			var rdfTypeUri = '%r%';
			var filterQuery = " FILTER NOT EXISTS { ";
				filterQuery = filterQuery + " { ?s <" + podUri +"> <" + o + "> . } ";
				filterQuery = filterQuery + "UNION { ?s <" + podUri +"> ?c16 . ";
				filterQuery = filterQuery + " ?c16 <" + podUri + "> <" + o + "> . } ";
				filterQuery = filterQuery + "UNION { ?s <" + podUri +"> ?c17 . ";
				filterQuery = filterQuery + " ?c17 <" + podUri + "> ?c18 . ";			
				filterQuery = filterQuery + " ?c18 <" + podUri + "> <" + o + "> . } ";
				filterQuery = filterQuery + "UNION { ?s <" + podUri +"> ?c19 . ";
				filterQuery = filterQuery + " ?c19 <" + podUri + "> ?c20 . ";			
				filterQuery = filterQuery + " ?c20 <" + podUri + "> ?c21 . ";			
				filterQuery = filterQuery + " ?c21 <" + podUri + "> <" + o + "> . } ";
				filterQuery = filterQuery + "UNION { ?s <" + podUri +"> ?c22 . ";
				filterQuery = filterQuery + " ?c22 <" + podUri + "> ?c23 . ";			
				filterQuery = filterQuery + " ?c23 <" + podUri + "> ?c24 . ";			
				filterQuery = filterQuery + " ?c24 <" + podUri + "> ?c25 . ";			
				filterQuery = filterQuery + " ?c25 <" + podUri + "> <" + o + "> . } ";
				
				filterQuery = filterQuery + " } ";				
			
			var query = "SELECT DISTINCT ?s ?p ?type " + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
				query = query + " {";
	
				query = query + "{ { { ?s ?p <" + o + "> } " + filterQuery + " } ";
				query = query + "UNION {{  ?s ?p ?c1  . ";
				query = query + " ?c1 <" + podUri + "> <" + o + "> . }" + filterQuery + " } ";
				
				query = query + "UNION {{ ?s ?p ?c2 . ";
				query = query + " ?c2 <" + podUri + "> ?c3 . ";
				query = query + " ?c3 <" + podUri + "> <" + o + "> . } " + filterQuery + " } ";
	
				query = query + "UNION {{ ?s ?p ?c4 . ";
				query = query + " ?c4 <" + podUri + "> ?c5 . ";
				query = query + " ?c5 <" + podUri + "> ?c6 . ";			
				query = query + " ?c6 <" + podUri + "> <" + o + "> . } " + filterQuery + " } ";
				
				query = query + "UNION {{ ?s ?p ?c7 . ";
				query = query + " ?c7 <" + podUri + "> ?c8 . ";
				query = query + " ?c8 <" + podUri + "> ?c9 . ";
				query = query + " ?c9 <" + podUri + "> ?c10 . ";			
				query = query + " ?c10 <" + podUri + "> <" + o + "> . } " + filterQuery + " } ";

				query = query + "UNION {{ ?s ?p ?c11 . ";
				query = query + " ?c11 <" + podUri + "> ?c12 . ";
				query = query + " ?c12 <" + podUri + "> ?c13 . ";
				query = query + " ?c13 <" + podUri + "> ?c14 . ";			
				query = query + " ?c14 <" + podUri + "> ?c15 . ";			
				query = query + " ?c15 <" + podUri + "> <" + o + "> . } " + filterQuery + " } " + "} ." ;
				
				query = query + " OPTIONAL { ?s <" + rdfTypeUri + "> ?type } . ";
				
				query = query + " }";
				//reverse part
				query = query + " UNION {";
	
				query = query + "{ {{ <" + o + "> ?p ?s } "  + filterQuery + " } ";
				query = query + "UNION {{ ?c1 ?p ?s . ";
				query = query + " ?c1 <" + podUri + "> <" + o + "> . } " + filterQuery + " } ";
				
				query = query + "UNION {{ ?c2 ?p ?s . ";
				query = query + " ?c2 <" + podUri + "> ?c3 . ";
				query = query + " ?c3 <" + podUri + "> <" + o + "> . } " + filterQuery + " } ";
	
				query = query + "UNION {{ ?c4 ?p ?s . ";
				query = query + " ?c4 <" + podUri + "> ?c5 . ";
				query = query + " ?c5 <" + podUri + "> ?c6 . ";			
				query = query + " ?c6 <" + podUri + "> <" + o + "> . } " + filterQuery + " } ";
				
				query = query + "UNION {{ ?c7 ?p ?s . ";
				query = query + " ?c7 <" + podUri + "> ?c8 . ";
				query = query + " ?c8 <" + podUri + "> ?c9 . ";
				query = query + " ?c9 <" + podUri + "> ?c10 . ";			
				query = query + " ?c10 <" + podUri + "> <" + o + "> . } " + filterQuery + " } ";

				query = query + "UNION {{ ?s ?p ?c11 . ";
				query = query + " ?c11 <" + podUri + "> ?c12 . ";
				query = query + " ?c12 <" + podUri + "> ?c13 . ";
				query = query + " ?c13 <" + podUri + "> ?c14 . ";			
				query = query + " ?c14 <" + podUri + "> ?c15 . ";			
				query = query + " ?c15 <" + podUri + "> <" + o + "> . } " + filterQuery + " } " + "} ." ;
				
				query = query + " OPTIONAL { ?s <" + rdfTypeUri + "> ?type }  ";

				query = query + " }";				
				
				query= query + " } ";
			rdfModel.refToModelQuery = query;	
		}
		var refQuery = rdfModel.refToModelQuery;
		refQuery = refQuery.replace(/%o%/g, oReplace);
		refQuery = refQuery.replace(/%p%/g, podUriReplace);
		refQuery = refQuery.replace(/%r%/g, rdfTypeUriReplace);
		
		rdfModel.store.execute(refQuery,function(success, results){
			for(var i=0;i<results.length;i++){
				var subject = results[i].s.value;
				if(results[i].s.token === "literal" || subject.indexOf(RDFModel.vdmbeeSchema) >=0){
					continue;
				}
				var sId = subject.substr(RDFModel.vdmbeeInst.length);
				
				if(sId !== modelId){
					var predicate = results[i].p.value.substr(RDFModel.vdmbeeSchema.length);
					if((results[i].type && results[i].type.value === 'http://www.w3.org/2000/01/rdf-schema#Class') || predicate === 'partOfDirectly'){
						continue;
					}
					var result = {};
					result.id = sId;
					result.predicate = predicate;
					if(results[i].type){
						result.type = results[i].type.value.substr(RDFModel.vdmbeeSchema.length);	
					}
					ret.push(result);	
				}
			}
			results.length = 0;
			function getParentsofObjects(){
				var refCount = ret.length;
				function getTypeOfObject(){
					refCount--;
					if(refCount>=0){
						if(!ret[refCount].type){
							var alternativeId = rdfModel.dataManager.getRepositoryId(ret[refCount].id);
							var alternativeRDFModel = rdfModel.dataManager.getRDFModel(alternativeId);
							alternativeRDFModel.getTypeOfSubject(ret[refCount].id,function(type){
								if(type){
									ret[refCount].type = type;
								}
								getTypeOfObject();
							});				
						}
						else{
							getTypeOfObject();
						}
					}else{
						callback(ret);
					}
				}
				getTypeOfObject();
			}			
			if(getParents){
				rdfModel.getAndFilterByNestedParent(ret,[],getParentsofObjects,namePropertyNS ? namePropertyNS : 'vbc:cmof_EObject-name');
			}else{
				getParentsofObjects();
			}
		});
		refQuery = null;
	};
	
	
    RDFModel.prototype.getTypeOfSubject = function (subject, callback) {
        var cachedModel = Backbone.Relational.store.getObjectByName("cmof.EObject").find({ id: subject });
        
        if (cachedModel) {
            callback(cachedModel.get('type'), cachedModel.get('name'));
            return;
        }
		var rdfModel = this;
		var s = rdfModel.rdf.resolve("vbi:" + subject);
		var rdfTypeUri = this.rdf.resolve("rdf:type");
		var nameUri = this.rdf.resolve("vbc:" + "cmof_EObject-name");
		var query = "SELECT ?type ?name " + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
			query = query + " <" + s + "> <" + rdfTypeUri + "> ?type . ";
			query = query + " <" + s + "> <" + nameUri + "> ?name . ";
			query= query + "} ";

		rdfModel.store.execute(query,function(success, results){
			if(results.length > 0){
				callback(results[0].type.value.substr(RDFModel.vdmbeeSchema.length),results[0].name.value);
				results.length = 0;
			}else{
				callback();
			}
		});
		query = null;
	};
	RDFModel.prototype.findSubject = function(subject,callback){
		this.getTypeOfSubject(subject,function(type){
			var ret = {};
			if(type){
				ret.id = subject;
				ret.type = type;
				callback(ret);
			}else{
				callback();
			}
		});		
	};	
	RDFModel.prototype.getReferenceToAlternativeOld = function(alternativeId,callback,filterPackageIds){
		
		var rdfModel = this;
		var versionUri = this.rdf.resolve("vbc:" + "beeppackage_BeepPackage-version");
		var rdfTypeUri = this.rdf.resolve("rdf:type");
		
		//alternativeId = this.dataManager.get('currentWorkspace').get('id');
	
		var query = "SELECT ?s ?p ?type ?o ?objectType" + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
			query = query +  " ?s ?p ?o . ";
			query = query + " ?s <" + rdfTypeUri + "> ?type . ";
			query = query +  " OPTIONAL { ?o <" + rdfTypeUri + "> ?objectType }. ";
			query = query +  " FILTER ( (?type != <http://www.w3.org/2000/01/rdf-schema#Class>) && (?type != <http://www.w3.org/1999/02/22-rdf-syntax-ns#Property>)) " ;
			query= query + "} ";

		rdfModel.store.execute(query,function(success, results){
			var ret = new Array();
			for(var i=0;i<results.length;i++){
				if(((rdfModel.name !== alternativeId) &&  (results[i].o.value.indexOf(alternativeId) < 0 ))){
					continue;
				}
				if(results[i].p.value === rdfTypeUri || (results[i].o.token === 'literal')){
					continue;
				}
				if(((rdfModel.name === alternativeId) && (results[i].o.value.indexOf(alternativeId) >= 0 ))){
					continue;
				}
				var result = {};
				result.id = results[i].s.value.substr(RDFModel.vdmbeeInst.length);
				result.predicate = results[i].p.value.substr(RDFModel.vdmbeeSchema.length);
				result.object =results[i].o.value.substr(RDFModel.vdmbeeInst.length);
				if(results[i].type){
					result.type = results[i].type.value.substr(RDFModel.vdmbeeSchema.length);
				}
				if(results[i].objectType){
					result.objectType = results[i].objectType.value.substr(RDFModel.vdmbeeSchema.length);	
				}
/*				if(results[i].version){
					result.version = results[i].version.value;
				}else{
					result.version = 1;
				}*/
				ret.push(result);
			}
			function getParentsofObjects(){
				var alternativeRDFModel = rdfModel.dataManager.getRDFModel(alternativeId);
				var refCount = ret.length;
				function getTypes(){
					refCount--;
					if(refCount>=0){
						function checkAndGetObjectType(){
							if(ret[refCount].objectType){
								chekcAndGetSubjectType()
							}else{
								var objAltId = ret[refCount].object.substr(0,ret[refCount].object.lastIndexOf('@')+ 1);
								var objRdfModel = rdfModel.dataManager.getRDFModel(objAltId);
								objRdfModel.getTypeOfSubject(ret[refCount].object,function(type){
									if(type){
										ret[refCount].objectType = type;
									}
									chekcAndGetSubjectType();	
								});									
							}
						}
						function chekcAndGetSubjectType(){
							if(ret[refCount].type){
								getTypes();	
							}else{
								var subAltId = ret[refCount].id.substr(0,ret[refCount].id.lastIndexOf('@')+ 1);
								var subRdfModel = rdfModel.dataManager.getRDFModel(subAltId);
								rdfModel.getTypeOfSubject(ret[refCount].object,function(type){
									if(type){
										ret[refCount].type = type;
									}
									getTypes();
								});
							}							
						}
						checkAndGetObjectType();
					}else{
						alternativeRDFModel.getAndFilterByNestedParent(ret,[],callback,'vbc:cmof_EObject-name',"object",filterPackageIds);		
					}
				}
				getTypes();
			}
			rdfModel.getAndFilterByNestedParent(ret,[],getParentsofObjects,'vbc:cmof_EObject-name');
		});
		query = null;
	};	
	
	RDFModel.prototype.getAltScenariosReferingToPackageScenarios = function(packScenarios,callback){
		var rdfModel = this;
		var ret = [];
		if(packScenarios.length <=0 ){
			callback(ret);
		}
		var scenarioPredicate = rdfModel.rdf.resolve("vbc:" + "transformation_ScenarioProxy-scenario");

		var query = "SELECT ?scenario " + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
			query = query + " ?scenarioProxy <" + scenarioPredicate + "> " + " ?scenario . { ";
			for(var i=0;i<packScenarios.length;i++){
				query = query + (i === 0 ? " { " : " UNION { ") + " ?scenarioProxy <" + scenarioPredicate + "> \"" + packScenarios.at(i).get('id') + "\" } ";
			}
			query= query + "} }";
				
		rdfModel.store.execute(query,function(success, results){
			for(var j=0;j<results.length;j++){
				ret.push(packScenarios.get(results[j].scenario.value));
			}
			results.length = 0;
			callback(ret);
		});	
		query = null;
	};
	RDFModel.prototype.getParticipantAssignments = function(participantId,callback,rdfModels){
		var rdfModel = this;
		if(!rdfModels){
		  	rdfModels = [];
		  	rdfModels.push(rdfModel);
		}		
		var participant = rdfModel.rdf.resolve("vbi:" + participantId);
		var participantPredicate = this.rdf.resolve("vbc:" + "vdml_Assignment-participant");
		var nameUri = this.rdf.resolve("vbc:" + "cmof_EObject-name");
		var versionUri = this.rdf.resolve("vbc:" + "beeppackage_BeepPackage-version");
		
		var query = "SELECT ?assignment ?assignmentName ";
		for(var i=0;i<rdfModels.length;i++){
		  		if(rdfModels[i]){
		  			query = query + " FROM <"+ rdfModels[i].graphUri + "> ";	
		  		}
		}
		query = query + " FROM <"+ rdfModel.schemaModel.graphUri + "> {"; 
		query = query + " ?assignment <" + participantPredicate + "> <" +  participant + ">.";
		query = query + " ?assignment <" + nameUri + "> ?assignmentName . ";
		//query = query + " ?assignment <" + versionUri + "> ?version  ";
		query = query  + "}";
		this.store.execute(query,function(success, results){
			var ret = new Array();
			for(var i=0;i<results.length;i++){
				var result = {};
				result.id = results[i].assignment.value.substr(RDFModel.vdmbeeInst.length);
				//result.version = results[i].version.value;
				result.name = results[i].assignmentName.value;
				result.type = "vdml.Assignment";
				ret.push(result);
			}
			results.length = 0;
			callback(ret);
			//rdfModel.getAndFilterByNestedParent(ret,[],callback,'vbc:vdml_VdmlElement-name');
		});	
		query = null;
	};	
	
	RDFModel.prototype.getReverseAssociationInstances = function(modelId,associationPredicate,namePropertyNS,callback,filterPackagesIds,rdfModels){
		var rdfModel = this;
		if(!rdfModels){
		  	rdfModels = [];
		  	rdfModels.push(rdfModel);
		}	
		
		var model = rdfModel.rdf.resolve("vbi:" + modelId);
		var rdfTypeUri = this.rdf.resolve("rdf:type");
		var refPredicate = this.rdf.resolve("vbc:" + associationPredicate);
		if(!namePropertyNS){
			namePropertyNS = "cmof_EObject-name";
		}
		var nameUri = this.rdf.resolve(namePropertyNS);
		//var versionUri = this.rdf.resolve("vbc:" + "beeppackage_BeepPackage-version");
		
		var query = "SELECT ?ref ?type ?refName ";
		for(var i=0;i<rdfModels.length;i++){
		  		if(rdfModels[i]){
		  			query = query + " FROM <"+ rdfModels[i].graphUri + "> ";	
		  		}
		}
		query = query + " FROM <"+ rdfModel.schemaModel.graphUri + "> {"; 		
		query = query + " ?ref <" + refPredicate + "> <" +  model + ">.";
		query = query + " ?ref <" + rdfTypeUri + "> ?type . ";
		query = query + " ?ref <" + nameUri + "> ?refName . ";
		//query = query + " ?ref <" + versionUri + "> ?version  ";
		query = query  + "}";
		this.store.execute(query,function(success, results){
			var ret = [];
			for(var i=0;i<results.length;i++){
				var result = {};
				result.id = results[i].ref.value.substr(RDFModel.vdmbeeInst.length);
				//result.version = results[i].version.value;
				result.name = results[i].refName.value;
				result.type = results[i].type.value.substr(RDFModel.vdmbeeSchema.length);
				ret.push(result);
			}
			results.length = 0;
			rdfModel.getAndFilterByNestedParent(ret,[],callback,'vbc:cmof_EObject-name',null,filterPackagesIds);
		});	
		query = null;
	};
	RDFModel.prototype.getPlanBackboneModelFromRdf= function(planId,callback){
		var rdfModel = this;
        var namePredicate = rdfModel.rdf.resolve("vbc:" + "cmof_EObject-name");
        var descPredicate = rdfModel.rdf.resolve("vbc:" + "cmof_EObject-description");
		var statusPredicate = rdfModel.rdf.resolve("vbc:" + "transformation_Plan-status");
		var phaseStatusPredicate = rdfModel.rdf.resolve("vbc:" + "transformation_Phase-status");
		var goalPredicate = rdfModel.rdf.resolve("vbc:" + "transformation_Plan-goal");
		var asIsPredicate = rdfModel.rdf.resolve("vbc:" + "transformation_Plan-asIs");
		var phasePredicate = rdfModel.rdf.resolve("vbc:" + "transformation_Plan-phase");
		var primaryPredicate = rdfModel.rdf.resolve("vbc:" + "transformation_Phase-primary");
		var nextPredicate = rdfModel.rdf.resolve("vbc:" + "transformation_Phase-nextPhase");
		var prevPredicate = rdfModel.rdf.resolve("vbc:" + "transformation_Phase-previousPhase");
		var altPredicate = rdfModel.rdf.resolve("vbc:" + "transformation_Phase-phaseAlternative");
		
		var palnUri = this.rdf.resolve("vbi:" + planId);
		function cleanStrings(){
			palnUri = null;
			namePredicate = null;
			descPredicate = null;
			statusPredicate = null;
			phaseStatusPredicate = null;
			goalPredicate = null;
			asIsPredicate = null;
			phasePredicate = null;
			primaryPredicate = null;
			nextPredicate = null;
			prevPredicate = null;
			altPredicate = null;
		}
		function getPlanDetails(){
			var query = "SELECT ?name ?description ?status ?goal ?asIs  " + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
				query = query +  "<" + palnUri + "> <"+ namePredicate + ">  ?name ."; 
				query = query +  "<" + palnUri + "> <"+ descPredicate + ">  ?description ."; 
				query = query +  " OPTIONAL { <" + palnUri + "> <"+ statusPredicate + ">  ?status }."; 
				query = query +  " OPTIONAL { <" + palnUri + "> <"+ goalPredicate + ">  ?goal }. ";
				query = query +  " OPTIONAL { <" + palnUri + "> <"+ asIsPredicate + ">  ?asIs }. ";
				query= query + "} ";
			rdfModel.store.execute(query,function(success, results){
				if(results.length >0){
					var nam = results[0].name.value;
					var desc = results[0].description.value;
					var stat;
					if(results[0].status){
						stat = results[0].status.value;	
					}
					var goal,asIs;
					if(results[0].goal){
						goal = results[0].goal.value.substr(RDFModel.vdmbeeInst.length);	
					}
					if(results[0].asIs){
						asIs = results[0].asIs.value.substr(RDFModel.vdmbeeInst.length);	
					}				
					var planModel = new Backbone.Model({id:planId,name:nam,description:desc});
					if(stat){
						planModel.set('status',stat);
					}
					if(goal){
						planModel.set('goal',goal);
					}
					if(asIs){
						planModel.set('asIs',asIs);
					}
					getPhaseModels(planModel);
					cleanStrings();
					query = null;
					callback(planModel); 
				}else{
					cleanStrings();
					query = null;
					callback(null);
				}
			});			
		}

		function getPhaseModels(planModel){
			var phases = new Backbone.Collection();
			phases.comparator = function(ph1,ph2){
				var next = ph1.get('nextPhase');
				if(next){
					//var nextModel = Backbone.Relational.store.getObjectByName("transformation.Phase").find({id:next});
					var nextModel = phases.get(next);
					while(nextModel){
						if(nextModel.id === ph2.id){
							return -1;
						}else{
							next = nextModel.get('nextPhase');
							if(next){
								nextModel = phases.get(next);	
							}
							else{
								return 1;
							}
						}
					}
					return 1;
				}else{
					return 1;
				}
			};			
			var query = "SELECT ?phase ?name ?description ?status ?nextPhase ?prevPhase ?primary " + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
				query = query +  "<" + palnUri + "> <"+ phasePredicate + ">  ?phase ."; 
				query = query +  " ?phase <"+ namePredicate + ">  ?name ."; 
				query = query +  " ?phase <"+ descPredicate + ">  ?description ."; 
				query = query +  " ?phase <"+ phaseStatusPredicate + ">  ?status ."; 
				query = query +  " ?phase <"+ primaryPredicate + ">  ?primary ."; 
				query = query +  " OPTIONAL { ?phase <"+ nextPredicate + ">  ?nextPhase }. ";
				query = query +  " OPTIONAL { ?phase <"+ prevPredicate + ">  ?prevPhase }. ";
				query= query + "} ";
			rdfModel.store.execute(query,function(success, results){
				for(var i=0;i<results.length;i++){
					var phase = results[i].phase.value.substr(RDFModel.vdmbeeInst.length);
					var nam = results[i].name.value;
					var desc = results[i].description.value;
					var stat = results[i].status.value;
					var prim = results[i].primary.value.substr(RDFModel.vdmbeeInst.length);
					var nextPhase = null;
					var prevPhase = null;
					if(results[i].nextPhase){
						nextPhase = results[i].nextPhase.value.substr(RDFModel.vdmbeeInst.length);	
					}
					if(results[i].prevPhase){
						prevPhase = results[i].prevPhase.value.substr(RDFModel.vdmbeeInst.length);	
					}				
					var phaseModel = new Backbone.Model({id:phase,name:nam,status:stat,description:desc,phaseOwner:planId,primary:prim});
					phaseModel.phaseOwner = planModel;
					if(nextPhase){
						phaseModel.set('nextPhase',nextPhase);
					}
					if(prevPhase){
						phaseModel.set('previousPhase',prevPhase);
					}
					phases.add(phaseModel);
					getAlternativeDetails(phaseModel,results[i].phase.value,phase);
				}
				planModel.set('phase',phases);
			});
		}
		function getAlternativeDetails(phaseModel,phaseUri,phaseId){
			var alts = new Backbone.Collection();
			var query = "SELECT ?alt ?name ?description " + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
				query = query +  "<" + phaseUri + "> <"+ altPredicate + ">  ?alt ."; 
				query = query +  " ?alt <"+ namePredicate + ">  ?name ."; 
				query = query +  " ?alt <"+ descPredicate + ">  ?description ."; 
				query= query + "} ";
			rdfModel.store.execute(query,function(success, results){
				for(var j=0;j<results.length;j++){
					var alt = results[j].alt.value.substr(RDFModel.vdmbeeInst.length);
					var nam = results[j].name.value;
					var desc = results[j].description.value;
					var altModel = new Backbone.Model({id:alt,name:nam,description:desc,phaseAlternativeOwner:planId});
					altModel.phaseAlternativeOwner = phaseModel;
					alts.add(altModel);
				}
				phaseModel.set('phaseAlternative',alts);	
			});				
		}		
		getPlanDetails();
	};	
	
	RDFModel.prototype.getAlternativeLength = function(phaseId,callback){
		var phaseUri = RDFModel.vdmbeeInst + phaseId;
        var namePredicate = this.rdf.resolve("vbc:" + "cmof_EObject-name");
        var descPredicate = this.rdf.resolve("vbc:" + "cmof_EObject-description");
		var altPredicate = this.rdf.resolve("vbc:" + "transformation_Phase-phaseAlternative");
		var query = "SELECT ?alt ?name ?description " + " FROM <"+ this.graphUri + "> FROM <"+ this.schemaModel.graphUri + "> {";
			query = query +  "<" + phaseUri + "> <"+ altPredicate + ">  ?alt ."; 
			query = query +  " ?alt <"+ namePredicate + ">  ?name ."; 
			query = query +  " ?alt <"+ descPredicate + ">  ?description ."; 
			query= query + "} ";
		this.store.execute(query,function(success, results){
			/*for(var j=0;j<results.length;j++){
				var alt = results[j].alt.value.substr(RDFModel.vdmbeeInst.length);
				var nam = results[j].name.value;
				var desc = results[j].description.value;
			}*/
			callback(results.length);
		});
	}
	
	RDFModel.prototype.getMeasurementParents = function(measurementId,callback,rdfModels){
		var rdfModel = this;
		if(!rdfModels){
		  	rdfModels = [];
		  	rdfModels.push(rdfModel);
		}		
		var measurement = rdfModel.rdf.resolve("vbi:" + measurementId);
		var rdfTypeUri = this.rdf.resolve("rdf:type");
		var base1MestToPredicate = this.rdf.resolve("vbc:" + "smm_Base1MeasurementRelationship-to");
		var base1MestRelPredicate = this.rdf.resolve("vbc:" + "smm_BinaryMeasurement-baseMeasurement1To");
		
		var base2MestToPredicate = this.rdf.resolve("vbc:" + "smm_Base2MeasurementRelationship-to");
		var base2MestRelPredicate = this.rdf.resolve("vbc:" + "smm_BinaryMeasurement-baseMeasurement2To");
		
		var baseNMestToPredicate = this.rdf.resolve("vbc:" + "smm_BaseNMeasurementRelationship-to");
		var baseNMestRelPredicate = this.rdf.resolve("vbc:" + "smm_CollectiveMeasurement-baseMeasurementTo");
		
		var rescaleFromPredicate = this.rdf.resolve("vbc:" + "smm_RescaledMeasurementRelationship-from");
		var rescaledFromRelPredicate = this.rdf.resolve("vbc:" + "smm_RescaledMeasurement-rescaleFrom");
		
		var rankingToPredicate = this.rdf.resolve("vbc:" + "smm_RankingMeasurementRelationship-to");
		var rankingToRelPredicate = this.rdf.resolve("vbc:" + "smm_RankingMeasurement-rankingTo");
		
		var gradeToPredicate = this.rdf.resolve("vbc:" + "smm_GradeMeasurementRelationship-to");
		var gradeToRelPredicate = this.rdf.resolve("vbc:" + "smm_GradeMeasurement-gradeTo");
		
        var nameUri = this.rdf.resolve("vbc:" + "cmof_EObject-name");
		var versionUri = this.rdf.resolve("vbc:" + "beeppackage_BeepPackage-version");
		
		var query = "SELECT ?parentMeasurement ?parentMeasurementName ?parentMeasurementType ?relationship";
		for(var i=0;i<rdfModels.length;i++){
		  		if(rdfModels[i]){
		  			query = query + " FROM <"+ rdfModels[i].graphUri + "> ";	
		  		}
		}
		query = query + " FROM <"+ rdfModel.schemaModel.graphUri + "> {"; 		
		query = query + " ?parentMeasurement <" + nameUri + "> ?parentMeasurementName . ";
		//query = query + " ?parentMeasurement <" + versionUri + "> ?version . ";
		query = query + " ?parentMeasurement <" + rdfTypeUri + "> ?parentMeasurementType . ";
		query = query + " {";
			query = query + " { ?relationship <" + base1MestToPredicate + "> <" + measurement + "> . ";
			query = query + " ?parentMeasurement <" + base1MestRelPredicate + "> ?relationship . } ";

			query = query + "UNION  { ?relationship <" + base2MestToPredicate + "> <" + measurement + "> .";
			query = query + " ?parentMeasurement <" + base2MestRelPredicate + "> ?relationship . } ";

			query = query + "UNION  { ?relationship <" + baseNMestToPredicate + "> <" + measurement + "> . ";
			query = query + " ?parentMeasurement <" + baseNMestRelPredicate + "> ?relationship . } ";

			query = query + "UNION  { ?relationship <" + rescaleFromPredicate + "> <" + measurement + "> . ";
			query = query + " ?parentMeasurement <" + rescaledFromRelPredicate + "> ?relationship . } ";

			query = query + "UNION  { ?relationship <" + rankingToPredicate + "> <" + measurement + "> . ";
			query = query + " ?parentMeasurement <" + rankingToRelPredicate + "> ?relationship . } ";
			
			query = query + "UNION  { ?relationship <" + gradeToPredicate + "> <" + measurement + "> . ";
			query = query + " ?parentMeasurement <" + gradeToRelPredicate + "> ?relationship . } ";
			
		query = query + " }";
		query = query  + "}";
		this.store.execute(query,function(success, results){
			var ret = [];
			for(var i=0;i<results.length;i++){
				var result = {};
				result.id = results[i].parentMeasurement.value.substr(RDFModel.vdmbeeInst.length);
				result.type = results[i].parentMeasurementType.value.substr(RDFModel.vdmbeeSchema.length);
				result.relationship = results[i].relationship.value.substr(RDFModel.vdmbeeInst.length);
				//result.version = results[i].version.value;
				result.name = results[i].parentMeasurementName.value;
				ret.push(result);
			}
			results.length = 0;
			rdfModel.getAndFilterByNestedParent(ret,[],callback,'vbc:cmof_EObject-name');
		});	
		query = null;
	};
	
	RDFModel.prototype.getParticipantNetworks = function(id,callback){
			var rdfModel = this;
			var collaborationId = rdfModel.rdf.resolve("vbi:" + id);
			var collaborationPredicate = rdfModel.rdf.resolve("vbc:" + "vdml_ParticipantNetwork-collaboration");
			var query = "SELECT ?pn " + " FROM <"+ rdfModel.graphUri + "> FROM <"+ rdfModel.schemaModel.graphUri + "> {";
				query = query +  " ?pn <" + collaborationPredicate + ">  <" + collaborationId + "> . ";
				query= query + "} ";
			rdfModel.store.execute(query,function(success, results){
					var vdmStore = rdfModel.dataManager.getVDMStore(rdfModel.name);
					var children = [];
					var childrenModels = [];
					if(results.length >0){
						for(var i=0;i<results.length;i++){
							var result = {};
							result.id = results[i].pn.value.substr(RDFModel.vdmbeeInst.length);
							result.type = "vdml.ParticipantNetwork";
							children.push(result);	
						}
					}
					results.length = 0;
					function getChildModels(result){
						var count = result.length;
						function fetchCollaboration(){
							count--;
							if(count >=0){
								rdfModel.dataManager.fetchDocumentFromPackage(result[count].parent,"appbo/vdml/ValueDeliveryModel",rdfModel.dataManager.get('currentVDMVersion'),result[count].id,"appbo/vdml/ParticipantNetwork",vdmStore,{
										success:function(model){
											childrenModels.push(model);
											fetchCollaboration();
										},
										error:function(error){
											console.log(error);
											fetchCollaboration();
										}
								});
							}else{
								callback(childrenModels);		
							}
						}
						fetchCollaboration();
					};
					rdfModel.getAndFilterByNestedParent(children,[],getChildModels);
				});
				query = null;
			};
    //return RDFModel;
	export {RDFModel};
//});
