import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Competence} from './Competence'
import {CompetenceMixin} from './CompetenceMixin'
import {OrgUnit} from './OrgUnit'
import {MeasuredCharacteristic} from './MeasuredCharacteristic'
/*import {Store} from './Store'
import {CapabilityMethod} from './CapabilityMethod'
import {ReleaseControl} from './ReleaseControl'
import {Activity} from './Activity'
import {Capability} from './Capability'*/
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Competence","appbo/vdml/CompetenceMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Competence,CompetenceMixin
, OrgUnit
, Capability
, Store
, CapabilityMethod
, ReleaseControl
, Activity
, MeasuredCharacteristic){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!OrgUnit){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OrgUnit){
			OrgUnit = importPath.OrgUnit;
		}
		else{
			import('./OrgUnit').then(loadedModule => {
		//		OrgUnit = loadedModule;
			});
			/*require(["appbo/vdml/OrgUnit"],function(loadedModule){
				OrgUnit = loadedModule;
			});*/
		}
	}
	//if(!Capability){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Capability){
			Capability = importPath.Capability;
		}
		else{
			import('./Capability').then(loadedModule => {
		//		Capability = loadedModule;
			});
			/*require(["appbo/vdml/Capability"],function(loadedModule){
				Capability = loadedModule;
			});*/
		}
	//}
	//if(!Store){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Store){
			//Store = importPath.Store;
		}
		else{
			import('./Store').then(loadedModule => {
				Store = loadedModule;
			});
			/*require(["appbo/vdml/Store"],function(loadedModule){
				Store = loadedModule;
			});*/
		}
//	}
//	if(!CapabilityMethod){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityMethod){
			CapabilityMethod = importPath.CapabilityMethod;
		}
		else{
			import('./CapabilityMethod').then(loadedModule => {
		//		CapabilityMethod = loadedModule;
			});
			/*require(["appbo/vdml/CapabilityMethod"],function(loadedModule){
				CapabilityMethod = loadedModule;
			});*/
		}
//	}
//	if(!ReleaseControl){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ReleaseControl){
			//ReleaseControl = importPath.ReleaseControl;
		}
		else{
			import('./ReleaseControl').then(loadedModule => {
				ReleaseControl = loadedModule;
			});
			/*require(["appbo/vdml/ReleaseControl"],function(loadedModule){
				ReleaseControl = loadedModule;
			});*/
		}
//	}
//	if(!Activity){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Activity){
			Activity = importPath.Activity;
		}
		else{
			import('./Activity').then(loadedModule => {
		//		Activity = loadedModule;
			});
			/*require(["appbo/vdml/Activity"],function(loadedModule){
				Activity = loadedModule;
			});*/
		}
//	}
//	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			//MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
		else{
			import('./MeasuredCharacteristic').then(loadedModule => {
				MeasuredCharacteristic = loadedModule;
			});
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
//	}
	export class CapabilityOfferMixin {

	defaults(){
		var ret = {
			type: "vdml_CapabilityOffer"
		}
		return jQuery.extend(Competence.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"vdml_CapabilityOffer",
			key:"capability",
			relatedModel:"vdml.Capability",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CapabilityOffer",
			key:"capabilityResource",
			relatedModel:"vdml.Store",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CapabilityOffer",
			key:"method",
			relatedModel:"vdml.CapabilityMethod",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CapabilityOffer",
			key:"releaseControl",
			relatedModel:"vdml.ReleaseControl",
			reverseRelation: {
				key:"releaseControlOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CapabilityOffer",
			key:"applyingActivity",
			relatedModel:"vdml.Activity",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"appliedCapabilityOffer",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}/*,
		{
			type :Backbone.HasOne,
			containingClass:"vdml_CapabilityOffer",
			key:"heatIndex",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"heatIndexOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}*/
		])
	}
	static getCumulativeMixinRelations(){
		if (!CapabilityOfferMixin.cummulativeRelations) {
            CapabilityOfferMixin.cummulativeRelations = _.union(CapabilityOfferMixin.getMixinRelations()
                , CompetenceMixin.getCumulativeMixinRelations()
            );
        }
		return CapabilityOfferMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_Competence"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "location",type : "EString",defaultValue : "null",containingClass : "vdml_CapabilityOffer" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("capabilityProvider") ? this.get("capabilityProvider") : this.previousAttributes().capabilityProvider;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("capabilityProvider") ? this.get("capabilityProvider") : this.previousAttributes().capabilityProvider;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/CapabilityOfferPropertiesTemplate.html",
			templateName : "CapabilityOfferPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/CapabilityOfferViewModel",
			tabId : "CapabilityOfferView",
			tabName: "CapabilityOffer"
		}
	}

	copy(targetOU,measurementsCopied){
		var offerId = DataManager.getDataManager().guidGeneratorByOwner(targetOU);
		var sourceOU = this.get('capabilityProvider');
		var capOfferCopy;
		if(sourceOU === targetOU){
			capOfferCopy = new this.constructor({id:offerId,capabilityProvider:targetOU,name:targetOU.getUniqueProperty('name',"Copy of " + this.get('name'),'capabilityOffer')});
		}else{
			capOfferCopy = new this.constructor({id:offerId,capabilityProvider:targetOU,name:targetOU.getUniqueProperty('name',this.get('name'),'capabilityOffer')});
		}
		var mesChars = this.get('measuredCharacteristic');
		mesChars.each(function(mesChar){
			mesChar.copy(capOfferCopy,measurementsCopied);			
		});	
		var heatIndex = this.get('heatIndex',measurementsCopied);
		if(heatIndex){
			heatIndex.copy(capOfferCopy);
		}
		//TODO copy release control
		capOfferCopy.set('capability',this.get('capability'));
		this.get('capabilityResource').each(function(store){
			if(store.get('storeOwner') === sourceOU){
				var targetStore = targetOU.get('store').findWhere({name:store.get('name')});
				capOfferCopy.get('capabilityResource').add(targetStore);
				targetStore.get('supportedCapability').add(capOfferCopy);
			}else{
				capOfferCopy.get('capabilityResource').add(store);
				store.get('supportedCapability').add(capOfferCopy);
			}
		});
		this.get('ownedMethod').each(function(ownedMethod){
			if(ownedMethod.get('methodOwner') === sourceOU){
				var targetMethod = targetOU.get('ownedMethod').findWhere({name:ownedMethod.get('name')});
				capOfferCopy.get('method').add(targetMethod);
				targetMethod.get('supportedCapability').add(capOfferCopy);
			}else{
				capOfferCopy.get('method').add(ownedMethod);
				ownedMethod.get('supportedCapability').add(capOfferCopy);
			}			
		});
		return capOfferCopy;
	};
	
	handleCapabilityOfferNameChange(model,newName){
		if(Backbone.skipPersistance){
			return;
		}
		var self= this;
        DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_BusinessModelCompetence-competence', 'vdml_BusinessModelCompetence', "vbc:" + "cmof_EObject-name", 
		function(bmCompetencies){	
			bmCompetencies.each(function(bmCompetence){
				if(bmCompetence.get('name') !== newName){
					bmCompetence.set('name',newName);
				}
			});
		}, true);
		var applyingActivities = self.get('applyingActivity');
		for(var i=0;i<applyingActivities.length;i++){
			applyingActivities.at(i).set('name',newName);
			applyingActivities.at(i).set('description',newName);
		}
	};	
	
	
	}
	path.CapabilityOfferMixin = CapabilityOfferMixin;
	//return CapabilityOfferMixin;
//});