import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import {Activity} from './Activity'
import {ValueAdd} from './ValueAdd'
import {ValueProposition} from './ValueProposition'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_,async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, Activity
, ValueAdd
, ValueProposition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Activity){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Activity){
			Activity = importPath.Activity;
		}
		else{
			import('./Activity').then(loadedModule => {
				Activity = loadedModule;
			});
			/*require(["appbo/vdml/Activity"],function(loadedModule){
				Activity = loadedModule;
			});*/
		}
	}
	if(!ValueAdd){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueAdd){
			ValueAdd = importPath.ValueAdd;
		}
		else{
			import('./ValueAdd').then(loadedModule => {
				ValueAdd = loadedModule;
			});
			/*require(["appbo/vdml/ValueAdd"],function(loadedModule){
				ValueAdd = loadedModule;
			});*/
		}
	}
	if(!ValueProposition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueProposition){
			ValueProposition = importPath.ValueProposition;
		}
		else{
			import('./ValueProposition').then(loadedModule => {
		//		ValueProposition = loadedModule;
			});
			/*require(["appbo/vdml/ValueProposition"],function(loadedModule){
				ValueProposition = loadedModule;
			});*/
		}
	}
	export class ValueStreamMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.ValueStreamMixin = ValueStreamMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_ValueStream"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_ValueStream",
			key:"valueStreamActivity",
			relatedModel:"vdml.Activity",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_ValueStream",
			key:"activityValue",
			relatedModel:"vdml.ValueAdd",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_ValueStream",
			key:"persuedProposition",
			relatedModel:"vdml.ValueProposition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	
	static  getCumulativeMixinRelations(){
		if (!ValueStreamMixin.cummulativeRelations) {
            ValueStreamMixin.cummulativeRelations = _.union(ValueStreamMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return ValueStreamMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("valueStreamOwner") ? this.get("valueStreamOwner") : this.previousAttributes().valueStreamOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("valueStreamOwner") ? this.get("valueStreamOwner") : this.previousAttributes().valueStreamOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')});
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ValueStreamPropertiesTemplate.html",
			templateName : "ValueStreamPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ValueStreamViewModel",
			tabId : "ValueStreamView",
			tabName: "ValueStream"
		}
	}
	
	removeActivityValue(actValue){
		if(Backbone.skipPersistance){
			return;
		}
		var self = this;
        DataManager.getDataManager().getReverseAssociationInstances(actValue, 'vdml_ValueStream-activityValue', 'vdml_ValueStream', "vbc:" + "cmof_EObject-name",function(valueStreams){
			var destroyValue = true;
			for(var i=0;i<valueStreams.length;i++){
				if(valueStreams.at(i) !== self){
					destroyValue = false;
					break;
				}
			}
			if(destroyValue){
				actValue.destroy();
			}
		},true);
	};
	
	removeActivity(activity){
		if(Backbone.skipPersistance){
			return;
		}
		var self = this;
		var actUsed = false;
		var vs = activity.getNestedParent().get('valueStream');
		var vsLen = vs ? vs.length : 0;
		for(var i = 0; i < vsLen; i++){
			if(vs.at(i) != self && vs.at(i).get('valueStreamActivity').findWhere({'id':activity.id})){
				actUsed = true;
				break;
			}
		}
		if(!actUsed) {
			DataManager.getDataManager().getReverseAssociationInstances(activity, 'vdml_ValueStream-valueStreamActivity', 'vdml_ValueStream', "vbc:" + "cmof_EObject-name",function(valueStreams){
				var destroyActivity = true;
				for(var i=0;i<valueStreams.length;i++){
					if(valueStreams.at(i) !== self){
						destroyActivity = false;
						break;
					}
				}
				if(destroyActivity){
					activity.destroy();
				}
				if(self.get('valueStreamActivity').length === 0){
					self.destroy();
				}
			},true);
		} else {
			if(self.get('valueStreamActivity').length === 0){
				self.destroy();
			}
		}
	};
	
	}
	path.ValueStreamMixin = ValueStreamMixin;
	//return ValueStreamMixin;
//});