import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { VocabularyLibrary } from './VocabularyLibrary'
import { BeepPackage } from '../beeppackage/BeepPackage'
import { BeepPackageMixin } from '../beeppackage/BeepPackageMixin'
import { ConceptElementMixin } from '../concept/ConceptElementMixin'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/beeppackage/BeepPackage","appbo/beeppackage/BeepPackageMixin","appbo/concept/ConceptElementMixin","appbo/concept/VocabularyLibrary"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BeepPackage,BeepPackageMixin,ConceptElementMixin,VocabularyLibrary
,Concept
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("concept",global.version);
	export class BeepVocabularyMixin{
	
	defaults(){
		var ret = {
			type: "concept_BeepVocabulary",
			version: DataManager.getDataManager().get('currentVDMVersion')
		}
		return jQuery.extend(BeepPackage.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
            type :Backbone.HasMany,
            containingClass:"concept_BeepVocabulary",
            key:"vocabularyLibrary",
            relatedModel:"concept.VocabularyLibrary",
            reverseRelation: {
                key:"vocabularyLibraryOwner",
                type :Backbone.HasOne,
                includeInJSON:"id"
            }
        }
		]);
	};
	static getCumulativeMixinRelations(){
		if (!BeepVocabularyMixin.cummulativeRelations) {
            BeepVocabularyMixin.cummulativeRelations = _.union(BeepVocabularyMixin.getMixinRelations()
                , BeepPackageMixin.getCumulativeMixinRelations()
				,ConceptElementMixin.getCumulativeMixinRelations()
            );
        }
		return BeepVocabularyMixin.cummulativeRelations.slice();
	};
	static getSuperTypes(){
		return [
			"concept_ConceptElement"
		];
	}
	static getProperties(){
		return [
			{name : "version",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "label",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "parentLabel",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "syncedChangeLog",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "synchedChangeSet",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" }
		]
	}
	getParent(){
		return BeepPackage.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return BeepPackage.prototype.getPackagePath.apply(this, arguments);
	}
	static getDialogViewProperties(type){
		if(type && type==="concept_BeepVocabulary"){
			return{
				templatePath : "views/concept/views/properties/VocabularyPackageTemplate.html",
			    templateName : "VocabularyPackageTemplate",
			    viewTypeStr : "appviews/concept/views/properties/VocabularyPackageViewModel",
			    tabId : "VocabularyPackageView",
			    tabName: "VocabularyPackage"
			}
		}
		else if(type==="explorerModelJson"){
			return{
				templatePath : "views/beeppackage/views/properties/explorerModelJsonTemplate.html",
			    templateName : "explorerModelJsonTemplate",
			    viewTypeStr : "appviews/beeppackage/views/properties/explorerModelJsonViewModel",
                tabId: "explorerModelJsonView",
                tabName: "explorerModelJson"
			}
		}		
	}
	static getViewProperties(type){
		return {
			templatePath : "views/concept/views/properties/ConceptTemplate.html",
			templateName : "ConceptTemplate",
			viewTypeStr : "appviews/concept/views/properties/ConceptViewModel",
			tabId : "ConceptView",
			tabName: "Concept"
		}
	}
//#startCustomMethods

	
//#endCustomMethods
	//path.BeepVocabularyMixin = BeepVocabularyMixin;
}
path.BeepVocabularyMixin = BeepVocabularyMixin;
//	return BeepVocabularyMixin;
//});