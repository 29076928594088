import * as _ from 'underscore'
import { ValuePropositionMixin } from "./ValuePropositionMixin";
import {DataManager} from '../../../com/vbee/data/DataManager'
import {VdmlElementMixin} from './VdmlElementMixin'
import {BeepPackageMixin} from '../beeppackage/BeepPackageMixin'
import {Scenario} from './Scenario'
import { ValueElement } from './ValueElement';
import { ValuePropositionComponent } from './ValuePropositionComponent';
import { PlanInstantiationPackage } from '../instdef/PlanInstantiationPackage';

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

export class ValueProposition2Mixin {

    initializeInstances(){//?
        
        if(this.get("isIntantiatable") == true ) {
            var plan = DataManager.getDataManager().get("currentPlan");
            plan.getInstantiationPackage(function (instPackage){
                if(!instPackage){
                    instPackage = PlanInstantiationPackage.getInstantiationPackageInstance(plan);
                }
                var valueProps = this.get("component").models;
                valueProps.forEach(value => {
                    
                    
                });
            })
        }
    }
}
utils.customExtendClass (ValueProposition2Mixin,new ValuePropositionMixin());

path.ValueProposition2Mixin = ValueProposition2Mixin;