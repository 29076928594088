import * as jQuery from 'jquery'
import * as _ from 'underscore'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

	
var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
export class UnitMixin {

	defaults(){
		var ret = {
			type: "vdml_Unit",
			roundingDigits : 4,
			significantDecimals : 2
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	
	static getCumulativeMixinRelations(){
		if (!UnitMixin.cummulativeRelations) {
			UnitMixin.cummulativeRelations = _.union(UnitMixin.getMixinRelations()
				, DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
			);
		}
		return UnitMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "roundingDigits",type : "EString",defaultValue : 6 ,containingClass : "vdml_Unit" },
			{name : "significantDecimals",type : "EString",defaultValue : 0 ,containingClass : "vdml_Unit" }
		]
	}

	getParent(){
		var container;
		if (!container) {
			container = this.get("unitOwner") ? this.get("unitOwner") : this.previousAttributes().valueOwner;
			if (container) {
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return VdmlElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/UnitPropertiesTemplate.html",
			templateName : "UnitPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/UnitViewModel",
			tabId : "UnitView",
			tabName: DataManager.getDataManager().get('localeManager').get('Unit')
		}
	}

}
path.UnitMixin = UnitMixin;
