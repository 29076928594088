import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
//import * as d3 from '../../../../../../libs/d3/d3.min'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {UserPreferences} from '../../../../bo/preference/UserPreferences'
import * as Highcharts from 'highcharts';
import { HighChartThemes } from '../../../../../com/vbee/utils/HighChartThemes'
import { Preference } from '../../../../bo/preference/Preference'
import { StrategyMapValuesChart } from './StrategyMapValuesChart'
import more from 'highcharts/highcharts-more';
more(Highcharts);

/*define(["require", "jquery", "jstree", "underscore",  "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/preference/UserPreferences", "appbo/preference/Preference", "d3", "RadarChart", "appbo/vdml/ValueElementMixin", "appcommon/com/vbee/utils/HighChartThemes", "highcharts-common","bootstrap-multiselect", "jquery.DataTable", "dataTable.PageResize", "dataTable.ColReorderWithResize","tick-positioner"],
    function (require, $, jstree, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, global, UserPreferences, Preference, d3, RadarChart, ValueElementMixin, HighChartThemes,Highcharts
    ) {
*/
        var path = DataManager.getDataManager().buildAppNsPath("strategyMap.views.designer", global.version);
	export class StrategyMapValues2Chart extends StrategyMapValuesChart{

        getValueMeasurements(alts, callback) {
            callback();
        }
        
        static getInstance(model,options){
			var view = new StrategyMapValues2Chart(model, options);
			return view;
		};
    }
	path.StrategyMapValues2Chart = StrategyMapValues2Chart;


