import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {PreferenceMixin} from './PreferenceMixin'
import {EObject} from '../cmof/EObject'
import {DataManager} from '../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/preference/PreferenceMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,PreferenceMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("preference",global.version);
	var Preference = EObject.extend(utils.customExtends({
		relations:PreferenceMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			EObject.prototype.initialize.apply(this, arguments);
		}
		}
		, new PreferenceMixin()
	));
	Preference.abstract = false;
	path.Preference = Preference;
	//return Preference;
	export {Preference};
//});