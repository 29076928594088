import React, { useRef, useEffect } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Split } from "@geoffcox/react-splitter";
import "./NavigationTree.scss"

function TreeNode({ node }) {
  const navigationController = (id,type) => {
    var element 
    if (type === 0) {
       element = document.getElementById(window.utils.htmlEscape(id));
    } else {
      element = document.getElementById(`view${window.utils.htmlEscape(id)}`);
    }
    if (element) {
      element.scrollIntoView({
        behavior: "auto", 
        block:"start",
        inline:"nearest"
      });
    }
    document.getElementById("DashboardView").style.overflow = "hidden";
  };
  if (node.type === 0) {
    return (
      <>
        <Tooltip title={<p style={{ fontSize: "12px" }} >{node.name ? node.name : "Container"}</p>} >
          <p
            style={{
              margin: "2px",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "bold",
              textWrap:"nowrap" 
            }}
            onClick={(e) => {
              e.preventDefault();
              navigationController(node?.id, node?.type);
              e.stopPropagation();
            }}
          >
            {node.name
              && node.name.length > 20
                ? `${node.name.substring(0, 20)}...`
                : node.name
            }
          </p>
        </Tooltip>
        {node.subRows && node.subRows.length > 0 && (
          <>
            {node.subRows.map((subNode, index) => (
              <TreeNode key={index} node={subNode} />
            ))}
          </>
        )}
      </>
    );
  } else if (node.type === 1) {
    return (
      <Tooltip title={<span><p style={{"fontSize":"12px"}}>{node.name}</p></span>}>
      <p style={{ margin: "4px", cursor:"pointer",fontSize:"16px", marginLeft:"6%",textWrap:"nowrap" }} onClick={()=>navigationController(node?.id, node?.type)}>
        {node.name.length > 20 ? `${node.name.substring(0, 20)}...` : node.name}
      </p>
    </Tooltip>
    );
  } else if (node.subRows && node.subRows.length > 0) {
    return (
        <div className="treeNode">
          {node.subRows.map((subNode, index) => (
            <TreeNode key={index} node={subNode} />
          ))}
        </div>
    );
  } else {
    return null;
  }
}

const NavigationTree = ({treeData}) => { 

  useEffect(()=>{
    window.vdmModelView.toc(false);
  },[])

  function handleResizeStarted(size) {
    window.vdmModelView.resizeNavWidth(size.secondary);
  };

  const handleNavigator = () => {
    window.vdmModelView.toggleTOC();
  };

  return (
    <>
      <Box className="navigationContainer">
        <div className="navigationTree">
          <Split
            initialPrimarySize="100%"
            onMeasuredSizesChanged={handleResizeStarted}
          >
            <div
              className="navigationWrapper"
            >
              <IconButton
                className="iconButton"
                onClick={() => {
                  handleNavigator();
                }}
              >
                <span
                  className="glyphicon glyphicon-chevron-left"
                  data-toggle="tooltip"
                  title="Close Navigator"
                  aria-hidden="true"
                ></span>
              </IconButton>
              {!!treeData && treeData.subRows?.length>0 && treeData.subRows.map((node, index) => (
                <TreeNode key={index} node={node} />
              ))}
            </div>
            <div></div>
          </Split>
        </div>
      </Box>
    </>
  );
};

export default NavigationTree;
