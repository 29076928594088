import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import { ValueType } from '../../../../../version1/bo/vdml/ValueType'
import { ScenarioType } from '../../../../../version1/bo/vdml/ScenarioType'
import { ScenarioExecution } from '../../../../../version1/bo/transformation/ScenarioExecution'
/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","asyncsimple","Lawnchair","backbone-lawnchair","bootbox","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/ValueDeliveryModel","appbo/transformation/Plan","appbo/preference/UserPreferences"],
function(require,$,_,Backbone,ko, koMapping,kb,asyncsimple,Lawnchair, backboneLawnchair,bootbox,DataManager,global,ValueDeliveryModel,Plan,UserPreferences){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation.views.correction",global.version);
	export class PlanValidation2ViewModel {
		init(model, options,detailedView) {
			var self = this;
			this.PlanValidation2ViewModel = this;
			this.model = model;
			if(options && options.parentView){
				this.parentView = options.parentView;
				this.encodeId = ""+options.modalId;
			}
			this.formulaArray = ko.observableArray();
			this.alternativeArray = ko.observableArray();
			this.checkPhaseDetails();
			this.selectedAlternative = ko.observable();
			this.periodNo = ko.observable();
			utils.defineKoNumeric(ko);
			var scenarioId = DataManager.getDataManager().get("currentPlan").get("defaultScenario");
        	this.scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
			var subCompLevel = ["satisfactionLevel","percentageWeight","recipientOpinion"];
			let aggValues = self.model.getDefaultScenarioValues(this.scenario,[ValueType.Aggregated.name],subCompLevel);
			self.validateFormulas(aggValues);
		}

		checkPhaseDetails(){
            var self = this;
            var phases = self.model.get('phase');
			for(var i=0;i<phases.length;i++){
				self.checkAlternativeDetails(phases.at(i));
			}
		};

		checkAlternativeDetails(phaseModel){
			var self = this;
			var alternatives = phaseModel.get('phaseAlternative');
			for(var i=0;i<alternatives.length;i++){
				var altModel = alternatives.at(i);
				var obj = {id:self.alternativeArray.length,originalId:altModel.get("id"),modalId:window.utils.htmlEscape(altModel.get("id"))+"alternative", name:altModel.getParent().get("name") + "-" + altModel.get("name")};		
				//if(altModel.get("id") === altModel.getParent().get("primary").get('id')){
					self.alternativeArray.push(obj);
				//}
			}
		};

		createTargetScenario(){
			var self = this;
			//var dataManager = DataManager.getDataManager();
			//var scenarioStepData = [];
			var scenario = self.scenario;
			var startTime = self.scenario.get('startTime');
			var periodKind = self.model.get("periodKind");
			let executionScenario = new ScenarioExecution({ id: window.guidGenerator(), name: "Target Scenario",scenarioType:ScenarioType.Target});//,changeSetEnabled:true });
			let stepObj = scenario.get("firstStep");
			while (stepObj) {
				const noOfPeriods = stepObj.get('noPeriods');
				self.model.createDefaultDataSets(startTime, stepObj.get('startPeriod'), noOfPeriods, periodKind, executionScenario);
				stepObj = stepObj.get("next");
			}
		}

		createAltScenario(){
			var self = this;
			var dataManager = DataManager.getDataManager();
			var scenarioStepData = [];
			var altId;
			for(var i=0;i<self.alternativeArray().length;i++){
				var alt = self.alternativeArray().at(i);
				if(alt.modalId === self.selectedAlternative()){
					altId = alt.originalId;
					break;
				}
			}
			var currentAlternative = window.utils.getElementModel(altId,['transformation.Alternative']);
			var obj = {id: currentAlternative.id, noOfPeriods: self.periodNo()};
			scenarioStepData.push(obj);
			var scenario = self.scenario;
			dataManager.set(dataManager.SUPPRESS_CHANGE_LOGGING, true);
			var planScenario = self.model.createPlanScenarioInstance(scenario.get("startTime"), scenarioStepData);
			planScenario.set("name", "custom "+new Date());
			dataManager.set(dataManager.SUPPRESS_CHANGE_LOGGING, false);
			var url = "/vdmbee/package/planscenarios";
			self.saveScenarioToBackend(url,planScenario,function(){
				var defaultExecutionScenaroId = planScenario.get("defaultExecutionScenario");
				var executionScenario = window.utils.getElementModel(defaultExecutionScenaroId,["transformation.ScenarioExecution"]);
				var executionUrl = "/vdmbee/package/scenarioexecutions";
				self.saveScenarioToBackend(executionUrl,executionScenario,function(){
					async.eachSeries(executionScenario.get("input").models, function(periodDataset, phaseHandled){
						//var periodDataset = window.utils.getElementModel(periodDatasetId,["transformation.PeriodDataset"]);
						var periodUrl = "/vdmbee/package/perioddatasets";
						self.saveScenarioToBackend(periodUrl,periodDataset,function(){
							phaseHandled();
						});
					},function(){
						bootbox.alert("Scenario created");
					});
				});
			});
			
		}

		saveScenarioToBackend(url,planScenario,callback){
			window.utils.startSpinner('saveSc', "Saving Scenario.. ");
			//let plan = DataManager.getDataManager().get("currentPlan");
			var dataManager = DataManager.getDataManager();
			dataManager.get('vmpServerService').postAllPromise(url, JSON.stringify(planScenario)).then(function(response) {
				window.utils.stopSpinner('saveSc');
				callback();
			}).catch(error => {
				console.error('An error occurred:', error);
				window.utils.stopSpinner('saveSc');
				callback();
			});
		}

		validateFormulas(aggValues){
			var self = this;
			_.each(aggValues,function(comp){
				var contexts = comp.get("context").models;
				for(var i=0;i<contexts.length;i++){
					var context = contexts[i];
					var alt = contexts[i].get("alternative");
					var formula = context.get("formula");
					var exp = formula ? formula.get('expressionStr') : null;
					if(!exp){
						continue;
					}
					var components = comp.getFromAggregations(alt.id);
					var expBuilderOptions = {variables: [],expression: ""};
					components.each(function (component) {
						expBuilderOptions.variables.push({variableId: self.model.htmlEscape(component), name:component.get('name')})
					});
					var agg  = expBuilderOptions.variables.map((d)=>{return {...d,variableId:window.utils.htmlEscape(d.variableId)}});
        			const expStr = utils.replaceIdsWithNames(exp, agg);
					if(expStr.includes('_')){
						var obj = {'name':comp.get('name'),'altName':alt.get('name')+'('+alt.getParent().get('name')+')','formula':expStr};
						self.formulaArray.push(obj);
					}
				}
			});
		}
		
	afterRenderView(node, view){
		var self = view;
	};

	cleanModal(){
		var self = this;
		delete ko.bindingHandlers.numeric;
		window.cleanDialogModel(self.encodeId,self);
	}
	
	static getInstance(model,options){
		var view = new PlanValidation2ViewModel(model, options);
		view.init(model, options);
		return view;
	};
}
	path.PlanValidation2ViewModel = PlanValidation2ViewModel;