import * as _ from 'underscore'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {ValueMixin} from './ValueMixin'
import { VdmlElement } from './VdmlElement'

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

export class Value2Mixin {
    static getMixinRelations(){
        var ret = ValueMixin.getMixinRelations().filter(item =>{
            return item.key !== "characteristicDefinition";
        });
        return ret;
    }
    
    static getCumulativeMixinRelations(){
        if (!Value2Mixin.cummulativeRelations) {
            Value2Mixin.cummulativeRelations = _.union(Value2Mixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
        return Value2Mixin.cummulativeRelations.slice();
    }
}
utils.customExtendClass (Value2Mixin,new ValueMixin());

path.Value2Mixin = Value2Mixin;