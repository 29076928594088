import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { CollaborationDiagram } from './CollaborationDiagram'
import { CollaborationDiagramMixin } from './CollaborationDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ActivityNetworkDiagram } from './ActivityNetworkDiagram'
import { RoleCollaborationDiagram } from './RoleCollaborationDiagram'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/CollaborationDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,CollaborationDiagramMixin
, ActivityNetworkDiagram
, RoleCollaborationDiagram){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ActivityNetworkDiagram){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ActivityNetworkDiagram){
			ActivityNetworkDiagram = importPath.ActivityNetworkDiagram;
		}
        else {
            import('./ActivityNetworkDiagram').then(({ default: ActivityNetworkDiagram }) => {
                ActivityNetworkDiagram = ActivityNetworkDiagram;
            });
			/*require(["appbo/vdml/ActivityNetworkDiagram"],function(loadedModule){
				ActivityNetworkDiagram = loadedModule;
			});*/
		}
	}
	if(!RoleCollaborationDiagram){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.RoleCollaborationDiagram){
			RoleCollaborationDiagram = importPath.RoleCollaborationDiagram;
		}
        else {
            import('./RoleCollaborationDiagram').then(({ default: RoleCollaborationDiagram }) => {
                RoleCollaborationDiagram = RoleCollaborationDiagram;
            });
			/*require(["appbo/vdml/RoleCollaborationDiagram"],function(loadedModule){
				RoleCollaborationDiagram = loadedModule;
			});*/
		}
	}
export class ProcessDiscoveryDiagramMixin {

    defaults() {
        var ret = {
            type: "vdml_ProcessDiscoveryDiagram"
        }
        return jQuery.extend(CollaborationDiagram.prototype.defaults.apply(this), ret);
    }
    static getMixinRelations() {
        return _.union([
            {
                type: Backbone.HasOne,
                containingClass: "vdml_ProcessDiscoveryDiagram",
                key: "activityNetworkDiagram",
                relatedModel: "vdml.ActivityNetworkDiagram",
                reverseRelation: {
                    key: "processDiscoveryDiagram",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },
            {
                type: Backbone.HasMany,
                containingClass: "vdml_ProcessDiscoveryDiagram",
                key: "activity",
                relatedModel: "vdml.Activity",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            },
            {
                type: Backbone.HasMany,
                containingClass: "vdml_ProcessDiscoveryDiagram",
                key: "capability",
                relatedModel: "vdml.Capability",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            },
            {
                type: Backbone.HasMany,
                containingClass: "vdml_ProcessDiscoveryDiagram",
                key: "competence",
                relatedModel: "vdml.Competence",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            },
            {
                type: Backbone.HasOne,
                containingClass: "vdml_ProcessDiscoveryDiagram",
                key: "roleCollaborationDiagram",
                relatedModel: "vdml.RoleCollaborationDiagram",
                reverseRelation: {
                    key: "processDiscoveryDiagram",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            }
        ])
    }
    static getCumulativeMixinRelations() {
        if (!ProcessDiscoveryDiagramMixin.cummulativeRelations) {
            ProcessDiscoveryDiagramMixin.cummulativeRelations = _.union(ProcessDiscoveryDiagramMixin.getMixinRelations()
                , CollaborationDiagramMixin.getCumulativeMixinRelations()
            );
        }
        return ProcessDiscoveryDiagramMixin.cummulativeRelations.slice();
    }
    static getSuperTypes() {
        return [
            "vdml_CollaborationDiagram"
        ];
    }
    static getProperties() {
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "represents", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" }
        ]
    }
    getParent() {
        var container;
        if (!container) {
            container = this.get("diagramOwner") ? this.get("diagramOwner") : this.previousAttributes().diagramOwner;
            if (container) {
                return container;
            }
        }
        return this;
    }
    getPackagePath(path) {
        if (!path) {
            path = [];
        }
        return CollaborationDiagram.prototype.getPackagePath.apply(this, arguments);
    }
    getViewProperties(type) {
        return {
            templatePath: "views/vdml/views/designer/processModel/ProcessDiscoveryDiagramTemplate.html",
            templateName: "ProcessDiscoveryDiagramTemplate",
            viewTypeStr: "appviews/vdml/views/designer/processModel/ProcessDiscoveryDiagramViewModel",
            tabId: "ProcessDiscoveryDiagramView",
            tabName: "ProcessDiscoveryDiagram"
        }
    }
    static getDialogViewProperties(type) {
        if (type && type === "TargetDetails") {
            return {
                templatePath: "views/canvas/views/designer/CanvasTargetDetailsTemplate.html",
                templateName: "CanvasTargetDetailsTemplate",
                viewTypeStr: "appviews/canvas/views/designer/CanvasTargetDetailsViewModel",
                tabId: "CanvasTargetDetailsView",
                tabName: "CanvasTargetDetails"
            }
        } else if (type === "explorerModelJson") {
            return {
                templatePath: "views/beeppackage/views/properties/explorerModelJsonTemplate.html",
                templateName: "explorerModelJsonTemplate",
                viewTypeStr: "appviews/beeppackage/views/properties/explorerModelJsonViewModel",
                tabId : "explorerModelJsonView",
                tabName: "explorerModelJson"
            }
        } else if (type === "ViewValues") {
            return {
                templatePath: "views/ecoMap/views/designer/ChartDetailsTemplate.html",
                templateName: "ChartDetailsTemplate",
                viewTypeStr: "appviews/ecoMap/views/designer/ChartDetailsViewModel",
                tabId: "ChartDetailsView",
                tabName: "ChartDetails"
            }
        } else if (type === "ImageSelector") {
            return {
                templatePath: "views/ecoMap/views/designer/ImageSelectorTemplate.html",
                templateName: "ImageSelectorTemplate",
                viewTypeStr: "appviews/ecoMap/views/designer/ImageSelectorViewModel",
                tabId: "ImageSelectorView",
                tabName: "ImageSelector"
            }
        }
    }
}
    path.ProcessDiscoveryDiagramMixin = ProcessDiscoveryDiagramMixin;

	//return ProcessDiscoveryDiagramMixin;
//});