import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {NamedPlanElement} from './NamedPlanElement'
import {PackageReferenceMixin} from './PackageReferenceMixin'
import {BeepPackage} from '../beeppackage/BeepPackage'
import {BranchReference} from './BranchReference'
import { DataManager } from '../../../com/vbee/data/DataManager'
/*
define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/transformation/NamedPlanElement","appbo/transformation/PackageReferenceMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,NamedPlanElement,PackageReferenceMixin
, BeepPackage
, BranchReference
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
	if(!BeepPackage){
		var importPath = DataManager.getDataManager().buildAppNsPath("beeppackage",global.version);
		if(importPath.BeepPackage){
			BeepPackage = importPath.BeepPackage;
		}
        else {
            import('../beeppackage/BeepPackage').then(loadedModule => {
                BeepPackage = loadedModule;
            });
			/*require(["appbo/beeppackage/BeepPackage"],function(loadedModule){
				BeepPackage = loadedModule;
			});*/
		}
	}
	if(!BranchReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.BranchReference){
			BranchReference = importPath.BranchReference;
		}
        else {
            import('./BranchReference').then(loadedModule => {
                BranchReference = loadedModule;
            });
			/*require(["appbo/transformation/BranchReference"],function(loadedModule){
				BranchReference = loadedModule;
			});*/
		}
	}
	
	var PackageReference = NamedPlanElement.extend(utils.customExtends({
		relations:PackageReferenceMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			NamedPlanElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new PackageReferenceMixin()
	));
	
	PackageReference.abstract = false;
	path.PackageReference = PackageReference;
	//return PackageReference;
	export {PackageReference};
//});