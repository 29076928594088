import * as $ from 'jquery'
import * as _ from 'underscore'
import * as Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as XLSX from 'xlsx'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'

//define(["require", "jquery", "jstree", "jqueryui", "underscore", "backbone", "async", "knockout", "knockoutMapping", "knockback", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global"],
//    function (require, $, jstree, jqueryui, _, Backbone, async, ko, koMapping, kb, bootbox, DataManager, global) {

var path = DataManager.getDataManager().buildAppNsPath("tickets", global.version);

export class UpdateCapabilityTreeViewModel {
    init(model, options) {
        var self = this;
        this.UpdateCapabilityTreeViewModel = this;
        //this.model = model;
        //this.id = kb.observable(model, 'id');
        this.capabilityJSON = options.capabilityJSON;
        var path = options.path;
        this.nodeName = path.substring(path.lastIndexOf('>') + 1).trim();
        this.encodeId = options.modalId;
        this.selectedNode = ko.observable();
        this.selectedPath = ko.observable();
        this.selectedDefinition = ko.observable();
        self.callback = options.callback;
        this.options = options;
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), ['Plan', 'Close', 'Delete', 'Complete', 'NewCapabilitiesTree']);
    }
    afterRenderView(node, view) {
        var self = view;
        $('#CapJSTree').jstree({
            'core': {
                'data': self.getCapbilityData()
            },
            'plugins': ['search']
        });
        var to = false;
        $('#CapJSTree_search').keyup(function() {
            if (to) {
                clearTimeout(to);
            }
            to = setTimeout(function() {
                var v = $('#CapJSTree_search').val();
                $('#CapJSTree').jstree(true).search(v);
            }, 250);
        });
        $('#CapJSTree').on('activate_node.jstree', function(e, data) {
            self.selectedNode(data);
        });

    };

    getCapbilityData() {
        var self = this;
        var data = [];
        return self.getCapabilityDataInJSTreeFormat(self.options.capabilityJSON, "");
    }

    getCapabilityDataInJSTreeFormat(model) {
        var self = this;
        var jsonData = {};
        var ret = [];
        ret.push(jsonData);
        if (!model._name) {
            //jsonData.id = "baguild";
            jsonData.text = "baguild";
            jsonData.data = "baguild";
        } else {
            //jsonData.id = id + "_" + model._name.replace(/\s/g,'');
            jsonData.text = model._name;
            jsonData.data = model._desc;
        }
        jsonData.children = [];
        var jsonChildren = Object.getOwnPropertyNames(model);
        for (var i = 0; i < jsonChildren.length; i++) {
            if (jsonChildren[i] == "_name" || jsonChildren[i] == "_desc") {
                continue;
            }
            var child = model[jsonChildren[i]];
            if (child.length > 1) {
                for (var i = 0; i < child.length; i++) {
                    if (child[i]._name != undefined) {
                        var childArray = this.getCapabilityDataInJSTreeFormat(child[i]);
                        if (childArray[0].text == self.nodeName) {
                            childArray[0].state = {
                                'opened': true,
                                'selected': true
                            };
                        }
                        for (var j = 0; j < childArray.length; j++) {
                            jsonData.children.push(childArray[j]);
                        }
                    }
                }
            } else {
                var childArray = this.getCapabilityDataInJSTreeFormat(child /*,jsonData.id*/ );
                if (childArray[0].text == self.nodeName) {
                    childArray[0].state = {
                        'opened': true,
                        'selected': true
                    };
                }
                for (var j = 0; j < childArray.length; j++) {
                    jsonData.children.push(childArray[j]);
                }
            }
        }
        ret[0].icon = "img/object.png";
        return ret;
    };

    setSelectedCapability() {
        var self = this;
        if (!$('#CapJSTree .jstree-clicked')) {
            self.selectedNode(undefined);
        }
        if (self.selectedNode()) {
            var path = [];
            var definition;
            var parentList = self.selectedNode().node.parents;
            for (var i = parentList.length - 3; i >= 0; i--) {
                path.push(self.selectedNode().instance.get_node(parentList[i]).text);
            }
            path.push(self.selectedNode().node.text);
            path = path.join(' > ');
            definition = self.selectedNode().node.data;
            self.selectedDefinition(definition);
            self.selectedPath(path);
            self.callback(path, definition); //add defn to callback

        } else {

        }
        self.cleanModalDialog();
    };

    cleanModalDialog() {
        var self = this;
        window.cleanDialogModel(self.encodeId, self);
    };

    static getInstance(model, options, detailedView) {
        var view = new UpdateCapabilityTreeViewModel(model, options);
        view.init(model, options);
        return view;
    };
}
path.UpdateCapabilityTreeViewModel = UpdateCapabilityTreeViewModel;