import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
//import * as  backboneLawnchair from 'backbonelawnchair'
import {EObjectMixin} from './EObjectMixin'
import {EObject2Mixin} from './EObject2Mixin' 

//import {Tag} from './Tag'
import * as LocaleManager from '../../../com/vbee/data/LocaleManager'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObjectMixin"],
//function(jQuery,_, Backbone,Lawnchair, backboneLawnchair,EObjectMixin){ debugger
	
	
	var path = DataManager.getDataManager().buildAppNsPath("cmof",global.version);
	function changeHandler(key){
		return function(model,related,destroyedObj){
			if(!related){
				DataManager.getDataManager().addObjectToChangedObjects(model,null,destroyedObj,key);	
			}
		};
	}	
	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? EObject2Mixin : EObjectMixin;
	}
	var eobjectMixinClass = getMixinClass();
	var EObject = Backbone.RelationalModel.extend(utils.customExtends({
		relations:eobjectMixinClass.getMixinRelations(),
		subModelTypes: eobjectMixinClass.getSubModelTypes(),
		initialize: function(attributes, options) {
			var model = this;
			Backbone.Model.prototype.initialize.apply(this, arguments);
			var corruption = false;
			if(!model.isNew() && this.get('type') == "transformation_Plan" && this.get('ownedTag') && (this.get('tag').length == 0 || this.get('ownedTag').length == 0)){
				corruption = true;
			}
			var Tag = Backbone.Relational.store.getObjectByName("cmof.Tag");
			if((model.isNew() && this.getSystemTags && (!options || (options && !options.silent))) || corruption){
				var tags = this.getSystemTags(options);
				var rootObj = this.getNestedParent();
				if(rootObj && rootObj.get('ownedTag') && rootObj.get('ownedTag').findWhere){
					for(var i=0;i<tags.length;i++){
						var tagObj = rootObj.get('ownedTag').findWhere({name:tags[i],tagOwner:rootObj});
						if(!tagObj){
							tagObj = new Tag({name:tags[i],tagOwner:rootObj});
						}
						this.get('tag').add(tagObj);
					}
				}
			}
			
			if(model.isNew() && (!options || (options && !options.silent))){
				DataManager.getDataManager().addNewObjectToChangedObjects(this);
			}
/*			this.on("change",DataManager.getDataManager().addObjectToChangedObjects);
			this.on("destroy",DataManager.getDataManager().addDeletedObjectToChangedObjects);
			var relations = this.getRelations();
			for(var i=0;i<relations.length;i++){
				var rel = relations[i];
				if(rel.collectionType){
					this.on("add:" + rel.key, DataManager.getDataManager().addRelatedObjectToChangedObjects);
					this.on("remove:" + rel.key, DataManager.getDataManager().addRelatedDeletedObjectToChangedObjects);
				}
				if(rel.options.type === Backbone.HasOne){
					var reverseRelation = rel.reverseRelation;
					var reverseKey = reverseRelation.key;
					if(!reverseKey || (reverseKey && (reverseRelation.includeInJSON !== true))){
						this.on("change:" + rel.key,changeHandler(rel.key));
					}
				}
			}*/
			if(!this.lawnchair){
				var docId = this.get('id');
				if(docId){
					var repId = DataManager.getDataManager().getRepositoryId(docId);
					this.lawnchair = DataManager.getDataManager().getVDMStore(repId);
				}
				if(!this.lawnchair){
					this.lawnchair = DataManager.getDataManager().get("vdmStore");//TODO
				}
				
			}
/*			this.on("destroy",function(model){
				if(model.previousAttributes().file){
					model.previousAttributes().file.destroy();
				}
			});*/
		}
		}
		, new eobjectMixinClass()
	));
	utils.customExtendStaticMethods(EObject,eobjectMixinClass);
	EObject.abstract = true;
	
	path.EObject = EObject;
	export {EObject};

