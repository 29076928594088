import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as fnon from 'fnon';
import {DataManager} from '../../../../../com/vbee/data/DataManager'
/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","asyncsimple","Lawnchair","backbone-lawnchair","bootbox","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/ValueDeliveryModel","appbo/transformation/Plan","appbo/preference/UserPreferences"],
function(require,$,_,Backbone,ko, koMapping,kb,asyncsimple,Lawnchair, backboneLawnchair,bootbox,DataManager,global,ValueDeliveryModel,Plan,UserPreferences){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("ecomap.views.designer",global.version);
	export class MapValidationViewModel {
		init(model, options,detailedView) {
			var self = this;
			this.MapValidationViewModel = this;
			this.model = model;
			if(options && options.parentView){
				this.parentView = options.parentView;
				this.encodeId = ""+options.modalId;
			}
			this.idCheckDetails = ko.observableArray("");
			this.textDetails = ko.observableArray("");
			this.elementCheckDetails = ko.observableArray("");
			this.elementDetails = {};
		}

		fixMap(){
			var self = this;
			self.elementDetails
			if(Object.keys(self.elementDetails).length > 0){
				var obj = self.elementDetails;
				debugger
				for (var key in obj) {
					if (obj.hasOwnProperty(key)) {
						var valType = obj[key];
						valType = valType.replace('_','.')
						var aggComp = window.utils.getElementModel(key,[valType]);
						if(aggComp){
							self.model.get('value').remove(aggComp);
						}
					}
				}
				self.elementCheckDetails([]);
			}
			if(self.idCheckDetails().length > 0){
				var newRep = DataManager.getDataManager().getRepositoryId(self.model.id);
				var alternativeModel = DataManager.getDataManager().getAlternativeSync(newRep);
				var preAlt = alternativeModel.getPreviousPhasePrimary();
				if(!preAlt){
					preAlt = alternativeModel;
				}
				var oldAltId = preAlt ? preAlt.id:'';
				var newPackage = self.model.getNestedParent();
				var diagrams = newPackage.get('diagrams');
				for (var i = 0; i < diagrams.length; i++) {
					newPackage.fixDiagramOnRevision(diagrams.at(i), newRep, oldAltId);
				}
				self.idCheckDetails([]);
			}
			fnon.Hint.Light("fixed",{displayDuration: 1500});
		}

		copyToClip(){
			var self = this;
			//var copyText = self.textDetails();
			/*var copyText = document.getElementById("copyText");
			// Select the text field
			copyText.select();
			copyText.setSelectionRange(0, 99999); // For mobile devices
			// Copy the text inside the text field
			navigator.clipboard.writeText(copyText.value);
			navigator.clipboard.writeText(copyText.outerText);*/
			var text_to_copy = document.getElementById("copyText").innerText;
			var msg = "copied";
			if (!navigator.clipboard){
				// use old commandExec() way
			} else{
				navigator.clipboard.writeText(text_to_copy).then(
					function(){
						fnon.Hint.Light(msg,{displayDuration: 1500});
					})
				.catch(
					function() {
						fnon.Hint.Light("Failed to copy",{displayDuration: 1500});
				});
			} 
		}
		
		cleanModal(){
			var self = this;
			window.cleanDialogModel(self.encodeId,self);
		}

		createStringText(){
			var self = this;
			var modeler = window.vdmModelView.modeler;
			var obj = modeler.get('elementRegistry')._elements;
			for (var key in obj) {
				if (obj.hasOwnProperty(key)) {
					var val = obj[key];
					var shape = val.element;
					if(shape.type !== "label" && !shape.curveType) {
						var name = shape.businessObject?shape.businessObject.name : null;
						if(name){
							//var txt = name + " of type: "+shape.businessObject.$type;
							if(shape.businessObject.$type == "vdml:Value"){
								var aggComp = window.utils.getElementModel(shape.businessObject.mid,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
								name = aggComp ? self.getValueDetails(name,aggComp): name;
							} else {
								var shapeType = shape.type.split(":")[1];
								name = name.concat(" of type:"+shapeType);
							}
							self.textDetails.push(name);
						}
					}
				}
			}
		};
		getValueDetails(name,mappedCap){
			var viewAlt = DataManager.getDataManager().get('viewAlternative');
			var beepPack = mappedCap.getNestedParent();
			if(!viewAlt){
				viewAlt = window.utils.getPrefix(beepPack.get('id'));
			}
			var alt = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: viewAlt});
			var scenario = alt.getMainScenario(beepPack);
			var observedMeasure = scenario?scenario.getObservedMeasureWithMeasurements(mappedCap.get('valueMeasurement'), null):null;
			var valueMsmt = observedMeasure?observedMeasure.get('measurements').at(0):null;
			var valueFormula = observedMeasure?observedMeasure.get('valueFormula'):null;
			if (valueMsmt) {
				var measure = valueMsmt.getParent().get('measure');
				var unit = measure.get('unit');
				var significantDecimals = unit ? unit.get('significantDecimals') : 2;
				var value = window.utils.getRoundedValues(valueMsmt.get('value'), significantDecimals);
				var unitName = unit.get('name');   
				if (unitName) {
					name += " (" + value + " " + unitName + ")";
				}              
			}
			if(valueFormula && valueFormula.length > 0){
				name += " with formula:" + valueFormula;
			}
			return name;
		}
		checkCorruption(xml){
			var self = this;
			var diagramType = self.model.get('type').split("_");
			var diagramTypeSuffix = "e";
			if(diagramType[1]){
				diagramTypeSuffix = diagramType[1].charAt(0).toLowerCase();
			}
			var attrValue = diagramTypeSuffix + window.utils.htmlEscape(self.model.id);
			var valExists = window.utils.searchXmlData(xml, 'id', attrValue);
			if(!valExists){
				self.idCheckDetails.push('id failed');
			}
			var viewAltId = window.utils.getPrefix(self.model.id);
			var alternativeModel = DataManager.getDataManager().getAlternativeSync(viewAltId);
			function changeMappedId(models) {
				if(models){
					var modelsLen = models.length;
					while (modelsLen--) {
						var oldModal = models.at(modelsLen);
						var prefix = window.utils.getPrefix(oldModal.id);
						if(prefix !== alternativeModel.id && prefix != window.plansKey){
							self.idCheckDetails.push('id failed for '+oldModal.get('name') +' type:'+ oldModal.get('type'));
						}
						if(!window.utils.searchXmlData(xml,'mid', oldModal.id)){
							self.elementCheckDetails.push('remove element '+oldModal.get('name'));
							self.elementDetails[oldModal.get('id')] = oldModal.get('type');
						}
					}
				}
			}	
			changeMappedId(self.model.get('participant'));
			changeMappedId(self.model.get('businessModel'));
			changeMappedId(self.model.get('valueProposition'));
			changeMappedId(self.model.get('activity'));
			changeMappedId(self.model.get('value'));
			changeMappedId(self.model.get('valueStream'));
			changeMappedId(self.model.get('competence'));		
		};
		
		afterRenderView(node, view){
            var self = view;
			var xml = self.model.get('data');
			self.createStringText();
			self.checkCorruption(xml);
			
		};
	static getInstance(model,options){
		var view = new MapValidationViewModel(model, options);
		view.init(model, options);
		return view;
	};
}
	path.MapValidationViewModel = MapValidationViewModel;