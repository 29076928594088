import * as _ from 'underscore'
import { BusinessModelMixin } from "./BusinessModelMixin";
import {DataManager} from '../../../com/vbee/data/DataManager'
var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

export class BusinessModel2Mixin {
    getMixinClass(){
        return BusinessModel2Mixin;
    }

    getViewProperties(type){
        return BusinessModel2Mixin.getDialogViewProperties(type);
    }
    static getDialogViewProperties(type){
        if(type && type === "BusinessDetails"){
           return {
               templatePath : "views/vdml/views/properties/BusinessDetailsPropertiesTemplate.html",
               templateName : "BusinessDetailsPropertiesTemplate",
               viewTypeStr : "appviews/vdml/views/properties/BusinessDetailsViewModel",
               tabId : "BusinessDetailsView",
               tabName: "BusinessDetails"
           }
       }
       if(type==="explorerModelJson"){
           return{
               templatePath : "views/beeppackage/views/properties/explorerModelJsonTemplate.html",
               templateName : "explorerModelJsonTemplate",
               viewTypeStr : "appviews/beeppackage/views/properties/explorerModelJsonViewModel",
                tabId: "explorerModelJsonView",
               tabName: "explorerModelJson"
           }
       }
       if(type && type === "BusinessModelSummernoteReportDetails"){
           return {
               templatePath : "views/vdml/views/report/BusinessModelSummernoteReportTemplate.html",
               templateName : "BusinessModelSummernoteReportTemplate",
               viewTypeStr : "appviews/vdml/views/report/BusinessModelSummernoteReportViewModel",
               tabId : "BusinessModelSummernoteReportView",
               tabName: "BusinessModelSummernoteReportView"
           }
       }	
       if(type && type === "NetworkPartner"){
           return {
               templatePath : "views/vdml/views/properties/NetworkPartnerPropertiesTemplate.html",
               templateName : "NetworkPartnerPropertiesTemplate",
               viewTypeStr : "appviews/vdml/views/properties/NetworkPartnerViewModel",
               tabId : "NetworkPartnerView",
               tabName: "NetworkPartner"
           }
       }
       if(type && type === "CustomerDetails"){
           return {
               templatePath : "views/vdml/views/properties/CustomerDetailsPropertiesTemplate.html",
               templateName : "CustomerDetailsPropertiesTemplate",
               viewTypeStr : "appviews/vdml/views/properties/CustomerDetailsViewModel",
               tabId : "CustomerDetailsView",
               tabName: "CustomerDetails"
           }
       }
       if(type && type === "BusinessModelReportDetails"){
           return {
               templatePath : "views/vdml/views/properties/BusinessModelReportTemplate.html",
               templateName : "BusinessModelReportTemplate",
               viewTypeStr : "appviews/vdml/views/properties/BusinessModelReportViewModel",
               tabId : "BusinessModelReportView",
               tabName: "BusinessModelReportView"
           }
       }
       if(type && type === "ActivityDetails"){
           return {
               templatePath : "views/vdml/views/properties/BusinessModelActivityDetailsPropertiesTemplate.html",
               templateName : "BusinessModelActivityDetailsPropertiesTemplate",
               viewTypeStr : "appviews/vdml/views/properties/BusinessModelActivityDetailsViewModel",
               tabId : "BusinessModelActivityDetailsView",
               tabName: "BusinessModelActivityDetails"
           }
       }
       if(type && type === "CompetencyDetails"){
           return {
               templatePath : "views/vdml/views/properties/BusinessModelCompetencyDetailsPropertiesTemplate.html",
               templateName : "BusinessModelCompetencyDetailsPropertiesTemplate",
               viewTypeStr : "appviews/vdml/views/properties/BusinessModelCompetencyDetailsViewModel",
               tabId : "BusinessModelCompetencyDetailsView",
               tabName: "BusinessModelCompetencyDetails"
           }
       }
       if(type && type === "Cube"){
           return {
               templatePath : "views/vdml/views/bmcube/BMCubeTemplate.html",
               templateName : "BMCubeTemplate",
               viewTypeStr : "appviews/vdml/views/bmcube/BMCube2ViewModel",
               tabId : "BMCubeView",
               tabName: "BusinessModelCube"
           }
       }
       if(type && type === "ValueDetails"){
           return {
               templatePath : "views/vdml/views/properties/ValueDetailsProperties2Template.html",
               templateName : "ValueDetailsProperties2Template",
               viewTypeStr : "appviews/vdml/views/properties/ValueDetails2ViewModel",
               tabId : "ValueDetails2View",
               tabName: "ValueDetails"
           }
       }
       if(type==="vdml_ValueDeliveryModel"){
           return{
               templatePath : "views/vdml/views/properties/EditValuedeliveryModelTemplate.html",
               templateName : "EditValuedeliveryModelTemplate",
               viewTypeStr : "appviews/vdml/views/properties/EditValueDeliveryModelDetailsViewModel",
               tabId : "EditValueDeliveryModelDetailsView",
               tabName: "EditValueDeliveryModelDetails"
           }
       }
       if(type && type==="ValueUnitDetails"){
           return{
               templatePath : "views/vdml/views/properties/ValueUnitDetailsPropertiesTemplate.html",
               templateName : "ValueUnitDetailsPropertiesTemplate",
               viewTypeStr : "appviews/vdml/views/properties/ValueUnitDetailsViewModel",
               tabId : "ValueUnitDetailsView",
               tabName: "ValueUnitDetails"
           }
        }
        if (type && type === "ValueExpressionDetails") {
            return {
                templatePath: "views/vdml/views/properties/ValueExpressionDetailsPropertiesTemplate.html",
                templateName: "ValueExpressionDetailsPropertiesTemplate",
                viewTypeStr: "appviews/vdml/views/properties/ValueExpressionDetailsViewModel",
                tabId: "ValueExpressionDetailsView",
                tabName: "ValueExpressionDetails"
            }
        }
        if (type && type === "ecoMap") {
           return {
               templatePath : "views/ecoMap/views/designer/EcoMapDesignerTemplate.html",
               templateName : "EcoMapDesignerTemplate",
               viewTypeStr : "appviews/ecoMap/views/designer/EcoMapDesignerViewModel",
               tabId : "EcoMapDesignerView",
               tabName: "EcoMapDesignerView"
           }
       }if(type && type === "businessModelValidation"){
           return {
               templatePath : "views/vdml/views/correction/BusinessModelValidationTemplate.html",
               templateName : "BusinessModelValidationTemplate",
               viewTypeStr : "appviews/vdml/views/correction/BusinessModelValidationViewModel",
               tabId : "BusinessModelValidationView",
               tabName: "BusinessModelValidation"
           }
       }if(type && type==="moveValue"){
            return{
                templatePath : "views/vdml/views/properties/MoveValueTemplate.html",
                templateName : "MoveValueTemplate",
                viewTypeStr : "appviews/vdml/views/properties/MoveValueViewModel",
                tabId : "MoveValueView",
                tabName: "MoveValue"
            }
        }
       return {
           templatePath : "views/vdml/views/properties/BusinessModelPropertiesTemplate.html",
           templateName : "BusinessModelPropertiesTemplate",
           viewTypeStr : "appviews/vdml/views/properties/BusinessModel2ViewModel",
           tabId : "BusinessModelView",
           tabName: "BusinessModel"
       }
   };
}
utils.customExtendClass (BusinessModel2Mixin,new BusinessModelMixin());

path.BusinessModel2Mixin = BusinessModel2Mixin;