import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {ReleaseControlMixin} from './ReleaseControlMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Scenario } from './Scenario'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/ReleaseControlMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,ReleaseControlMixin
, Scenario){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Scenario){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Scenario){
			Scenario = importPath.Scenario;
		}
		else{
            import('./Scenario').then(({ default: Scenario }) => {
                Scenario = Scenario;
			});
			/*require(["appbo/vdml/Scenario"],function(loadedModule){
				Scenario = loadedModule;
			});*/
		}
	}
	var ReleaseControl = VdmlElement.extend(utils.customExtends({
		relations:ReleaseControlMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new ReleaseControlMixin()
	));
	
	ReleaseControl.abstract = false;
	ReleaseControl.supported = false;
	path.ReleaseControl = ReleaseControl;
	export {ReleaseControl};
//});