import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as xlsx from 'xlsx'
import { DataManager } from '../../../../com/vbee/data/DataManager'
import {AggregationMethod} from '../../../bo/vdml/AggregationMethod'
import {ValueType} from '../../../bo/vdml/ValueType'
import {ValueLibraryViewModel} from './ValueLibraryViewModel'
import {BalanceKind} from '../../../bo/vdml/BalanceKind'
import {Unit} from '../../../bo/vdml/Unit'
import * as bootbox from '../../../../../libs/bootbox/bootbox'
import { Expression } from '../../../bo/vdml/Expression'
import { ValueCategory } from '../../../bo/vdml/ValueCategory'
import { ValueDefinition } from '../../../bo/vdml/ValueDefinition'


/*define(["require", "jquery", "jstree", "jqueryui", "underscore", "backbone", "async", "knockout", "knockoutMapping", "knockback", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appviews/valueLibrary/properties/ValueLibraryDetailsViewModel", "appviews/help/views/properties/BMGuidanceViewModel", "appbo/vdml/EcoMapDiagramMixin", "appbo/vdml/ValueCategory", "appbo/vdml/ValueDefinition", "summernote"],
	function (require, $, jstree, jqueryui, _, Backbone, async, ko, koMapping, kb, bootbox, DataManager, global, ValueLibraryDetailsViewModel, BMGuidanceViewModel, EcoMapDiagramMixin, ValueCategory, ValueDefinition) {*/
		var path = DataManager.getDataManager().buildAppNsPath("valueLibrary.views.properties", global.version);
export class ValueLibrary2ViewModel extends ValueLibraryViewModel {
    // constructor(model, options) {
    //     super(model, options);
    // }
    
   

    init(model, options) {
        super.init(model,options);
        var self = this;
        this.model = model;
        this.ValueLibrary2ViewModel = this;
        this.balanceKindOptions = BalanceKind.symbols();
        this.selectedBalanceKind = ko.observable(BalanceKind.Debit);
        this.selectedIsAccumulated = ko.observable(false);
        this.selectedExternalId = ko.observable();
        this.selectedSortOrder = ko.observable();
        this.selectedRoundingDecimal = ko.observable(4);
        this.selectedSignificantDecimals = ko.observable(2);
        this.selectedUpdateDataSet = ko.observable();
        this.selectedCountEmptyAsZero = ko.observable();
        this.showBooleanBound =  ko.observable(false);
        this.selectedIsRescalable = ko.observable(true);
        this.selectedAggregationMethod = ko.observable(AggregationMethod.Sum);
        this.aggregationMethodOptions  = AggregationMethod.symbols();
        this.showValLibFields = ko.observable(false);
        this.showValCatFields = ko.observable(false);
        this.selectedValueType = ko.observable();
        this.valueTypeOptions =  ko.observableArray();
        this.measureUnits = new Backbone.Collection();
        this.selectedIsCalculated = ko.observable(true);
        this.showFormula = ko.observable(false);
        this.roundingFactor = ko.observable(false);
        this.enableCapComplete = ko.observable(false);
        self.labels["Publish"] = kb.observable(
            DataManager.getDataManager().get("localeManager"),
            "Publish"
        );
        this.changeInSigValue = false;
        const parentPackage = self.model.getNestedParent();
        const currentPlan = DataManager.getDataManager().get('currentPlan');
        const comPackId = currentPlan.getCommonRepositoryId() + '-CommonPackage';
        this.isDefaultValueLibrary = parentPackage.id === comPackId ? ko.observable(true) : ko.observable(false);
        this.selectedParent = ko.observable("#");

        
        Object.assign(this.labels,kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'balanceKind','isAccumulated','sortOrder','isRescalable','isCalculated','valueType','aggregationMethod','externalId','RoundingDecimals','SignificantDecimals','updateDataSet','countEmptyAsZero',"Unit", "valueLibUnitDetails", "Action"
            ]));
        this.saveUnitDetails = function (unit,roundingVal, sigDigits) {
            self.selectedRoundingDecimal(roundingVal);
            self.selectedSignificantDecimals(sigDigits);
        }
        this.saveTableUnitDetails = function(unitName,roundedValue, sigDigits){
            var unitId = DataManager.getDataManager().guidGeneratorByOwner(self.model.get("valueOwner"));
            var unit = new Unit({id:unitId,unitOwner:self.model,name:unitName,description:unitName,roundingDigits:roundedValue,significantDecimals:sigDigits});
            self.model.get("unit").add(unit);
            self.measureUnits.push({ id: '' +unitId, name: '' + unitName, roundingDigits: '' + roundedValue, significantDecimals: '' + sigDigits });
            self.displayTable()
        }
        this.editRoundingFactor = function (view) {
            var addOptions = { roundingDigits: self.selectedRoundingDecimal(), significantDecimals: self.selectedSignificantDecimals() };
            var valUnit;
            var BusinessModelMixin = Backbone.Relational.store.getObjectByName("vdml.BusinessModel2Mixin");
            window.getAndCreateModalDialog(self.model.get('unit'), self.encodeId, BusinessModelMixin, valUnit, "ValueUnitDetails", self.saveUnitDetails, addOptions);
        }

        for(var i=0;i<ValueType.symbols().length;i++){
            self.valueTypeOptions.push({text:ValueType.symbols()[i],value:ValueType.symbols()[i]});
        }
        self.fillMeasurementUnits();       

    }

    submitToMarket() {
        var self = this;
        var dataManager = DataManager.getDataManager();
        var packDoc = dataManager.get("artifactsDocuments")[self.model.get('valueLibraryOwner').id];
        window.utils.startSpinner('checking','Checking...');
        dataManager.saveChangeSetToBackend(dataManager.get(dataManager.CURRENT_CHANGESET),function(response){
            dataManager.applyWorkspaceById(dataManager.get("currentWorkspaceId"),function(){
                dataManager.checkOwnedPackage(self.model.get('valueLibraryOwner'),function(resp){
                    window.utils.stopSpinner('checking');
                    if(resp && self){
                        /*if(resp.productId){
                            self.productId = resp.productId;
                        }*/
                        if(resp.owner){
                            var planId = dataManager.get('currentPlan').id;
                            var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
                            if(resp.marketplaceId){
                                dataManager.navigateToCrmPage("workspace/"+currentWorkspaceId+"/submitted/"+resp.marketplaceId+"/"+packDoc.artifactId);
                            } else {
                                dataManager.navigateToCrmPage("workspace/"+currentWorkspaceId+"/"+packDoc.artifactId+"/marketplace");
                            }
                        }else {
                            bootbox.alert("Package Submission is not allowed");
                        }
                        /*if(resp.published){
                            self.publishedDate(new Date(resp.publishedDate).toDateString());
                        }*/                     
                    }
                });
            });
        });
        //var planDocVersion = planDoc ? planDoc.documentVersion : self.model.get('documentVersion');
    };

    fillMeasurementUnits() {
        var self = this;
        var units = self.model.get("unit").models;
        for(var j=0; j< units.length;j++){
            var unit = units[j];
            self.measureUnits.push({ id: '' +unit.get('id'), name: '' + unit.get('name'), roundingDigits: '' + unit.get('roundingDigits'), significantDecimals: '' + unit.get('significantDecimals') });
        }
    }   
    
    treeConstruction(sheet, range, wb) {
        var self = this;
        var jsonData = {};
        var ret = [];
        ret.push(jsonData);
        jsonData.text = "Node";
        ret[0].icon = "img/object.png";
        function getCellByName(cellName){
            var cellNo;
            var formatCellName = cellName.replace(" ", "").toUpperCase();
            for (var colNum = 0; colNum <= range.e.c; colNum++) {
                var col = sheet[xlsx.utils.encode_cell({ r: 0, c: colNum })];
                if(col){
                    var colName = col.h ? col.h : col.v
                    if (colName && colName.replace(" ", "").toUpperCase() == formatCellName) {
                        cellNo = colNum;
                        break;
                    }
                }
            }
            return cellNo;
        }
        var formulaCell = getCellByName("FORMULA");
        var unitCell = getCellByName("UNIT");
        var roundingDigitCell = getCellByName("ROUNDING");
        var significantDigitCell = getCellByName("SIGNIFICANT");
        var rubricTypeCell = getCellByName("VALUE TYPE");
        if(!rubricTypeCell){
            bootbox.alert("Column VALUE TYPE Missing");
            return;
        }
        var descriptionTypeCell = getCellByName("DESCRIPTION");
        if(!descriptionTypeCell){
            descriptionTypeCell = getCellByName("NAME");
        }
        // var valueElementAndValueTypeJson = self.getValueElementAndValueTypeJson(sheet,range,descriptionTypeCell,rubricTypeCell);
        for (var rowNum = 1; rowNum <= range.e.r; rowNum++) {
            var row = sheet[xlsx.utils.encode_cell({ r: rowNum, c: descriptionTypeCell })];
            if (row) {
                var parent = null;
                var valueType = null;
                if(row.v && row.v != null){
                    row.h = row.v.trim();
                }else{
                    row.h = row.h.trim();
                }
                if(row.h.split('.').length != 0 && row.h.split('.').length > 2){
                    var rowExisted = self.rowsDataList[row.h.substring(0, row.h.lastIndexOf('.'))];
                    if(rowExisted == null){
                         bootbox.alert(DataManager.getDataManager().get('localeManager').get('MissingParent', row.h));
                         return;
                    }
                }
                if(sheet[xlsx.utils.encode_cell({ r: rowNum, c: rubricTypeCell })] == null){
                    valueType = self.getValueType(sheet, row, rubricTypeCell, valueElementAndValueTypeJson);
                    sheet[xlsx.utils.encode_cell({ r: rowNum, c: rubricTypeCell })] = valueType;
                }else{
                    valueType = sheet[xlsx.utils.encode_cell({ r: rowNum, c: rubricTypeCell })].v;
                    parent = sheet[xlsx.utils.encode_cell({ r: rowNum, c: rubricTypeCell })].v;
                }
                if(valueType == null){
                    bootbox.alert(DataManager.getDataManager().get('localeManager').get('ValueTypeEmpty'));
                    return;
                }              
                if (!parent) {
                    parent = sheet[xlsx.utils.encode_cell({ r: rowNum, c: rubricTypeCell })]?.h;
                }
                var textArr = row?.h?.split('.');
                var parentCat = self.model.get('value').findWhere({ name: parent });
                if (!parentCat) {
                    parentCat = ValueCategory.getInstance(parent, parent, self.model);
                }
                var formula = formulaCell?sheet[xlsx.utils.encode_cell({ r: rowNum, c: formulaCell })]:null;
                var unit = unitCell?sheet[xlsx.utils.encode_cell({ r: rowNum, c: unitCell })]:null;


                for (var i = 1; i < textArr.length; i++) {
                    var child;
                    if (i == 1) {
                        child = self.addChildDefinition(parent, self.model, textArr[i]);
                    } else {
                        child = self.addChildDefinition(textArr[i - 1], self.model, textArr[i]);
                    }
                    if (i === textArr.length - 1) {
                        var def = self.addDefinitionInfo(sheet, range, rowNum, child);
                        child.set('definition', def);
                        if (formula) {
                            child.set('formula', formula.w);
                        }
                        if(unit && unit.w !=="" && unit.w !== " -"){
                            var roundingDigit 
                            var significantDecimal
                            if(roundingDigitCell){
                                roundingDigit = roundingDigitCell?sheet[xlsx.utils.encode_cell({ r: rowNum, c: roundingDigitCell })]:{w:"4"};
                            }
                            if(significantDigitCell){
                                significantDecimal = significantDigitCell?sheet[xlsx.utils.encode_cell({ r: rowNum, c: significantDigitCell })]:{w:"2"};
                            }                           
                           self.setUnit(child,unit.w,roundingDigit.w,significantDecimal.w);
                        }

                    }
                    
                }
            }
        }
        var data = self.getModelDataInJSTreeFormat([self.model.getParent().get('valueLibrary').slice()]);
        return data;
    }


    setPropertyValues(parentsLength,selectedNode){
        super.setPropertyValues(parentsLength,selectedNode);
        var self = this;
        var valModel = self.getValElementModal(selectedNode.id); 
        var unit = valModel.get('unit') ? valModel.get('unit') : null;
        var formula = valModel.get('formula') ? valModel.get('formula') : null;
        if(valModel.get("type") ===  "vdml_ValueDefinition"){
            if(unit){
                //self.showUnit(true);
                self.selectedUnit(unit.get('name'));
                $("#measurementUnit"+self.encodeId).val(unit.get('name'));
                self.selectedRoundingDecimal(unit.get('roundingDigits'));
                self.selectedSignificantDecimals(unit.get('significantDecimals'));
            }else {
                self.selectedUnit("");
                $("#measurementUnit"+self.encodeId).val("");
            }
            if(formula){
                self.selectedFormula(formula.get('expressionStr'));
            }else{
                self.selectedFormula("");
            }
        }

        if (parentsLength > 1 && (selectedNode.original.icon == "img/object.png" || selectedNode.original.icon == "img/leaf.png")) {
            valModel.get("balanceKind")?self.selectedBalanceKind(valModel.get("balanceKind")):self.selectedBalanceKind(BalanceKind.Debit);
            self.selectedIsAccumulated(valModel.get("accumulated"));
            self.selectedExternalId(valModel.get("externalId"));
            self.selectedSortOrder(valModel.get("sortOrder"));
            self.selectedIsRescalable(valModel.get("isRescalable"));
            valModel.get("aggregationMethod")?self.selectedAggregationMethod(valModel.get("aggregationMethod")):self.selectedAggregationMethod(AggregationMethod.Sum);
            valModel.get("valueType")?self.selectedValueType(valModel.get("valueType")):self.selectedValueType(ValueType.Atomic);
            self.selectedIsCalculated(valModel.get("isCalculated"));
            self.selectedCountEmptyAsZero(valModel.get("countEmpAsZero"));
            self.selectedUpdateDataSet(valModel.get("updateDataSet"))
        }
        self.selectedParent(selectedNode.parent);
    }
    setUnit(valModel,unitName,roundingDigit,significantDecimal){
        if(unitName===undefined||unitName===""){
            return
        }
        var units = valModel.get("valueOwner").get("unit");
        var unit = units.findWhere({"name":unitName});
        if(!unit){
            unit = Unit.getInstance(unitName,unitName,roundingDigit,significantDecimal,valModel.get("valueOwner"));
            valModel.get("valueOwner").get("unit").add(unit);
        }
        valModel.set('unit',unit);
    }

    setExpression(valModel,expressionStr){
        var expression = valModel.get('formula') ? valModel.get('formula'):null;
        if(!expression){
            var expressionId = DataManager.getDataManager().guidGeneratorByOwner(valModel.get("valueOwner"));
            expression = new Expression({id:expressionId,expressionOwner:valModel,expressionStr:expressionStr,name:valModel.get('name')+" expression"});
        } else {
            expression.set({'expressionStr':expressionStr,'name':valModel.get('name')+" expression"});
        }
        //valModel.set('formula',expression);
    }

    deleteUnit(data, event, callback){
		var self = this;
		event.stopPropagation();
        window.utils.startSpinner('deleteUnit','Deleting Unit...');
        window.setTimeout(function(){
            var deleteNode = self.model.get("unit").findWhere({id:event.target.id});
            if(deleteNode){
                deleteNode.destroy();
            }
            // self.model.get("unit").remove(deleteNode);
            self.measureUnits.remove(event.target.id);
            self.displayTable();
            window.utils.stopSpinner('deleteUnit');
        },10);
	};

    displayTable(){
		var self = this;
		var myTableDiv = $('#unitColl' + self.encodeId);
		myTableDiv.empty();
		var table = document.createElement('TABLE');
		table.id = 'table' + self.encodeId;
		table.className="display";
		//table.className="pageResize";
		table.cellspacing="0";
		table.width="100%";
		//for header
		var tableHeader = document.createElement('THEAD');
		table.appendChild(tableHeader);
		var headerTR1 = document.createElement('TR');
		tableHeader.appendChild(headerTR1);
				
		var th1 = document.createElement('TH');
		th1.appendChild(document.createTextNode(self.labels['Unit']()));
		headerTR1.appendChild(th1);

        var th2 = document.createElement('TH');
		th2.appendChild(document.createTextNode(self.labels['RoundingDecimals']()));
		headerTR1.appendChild(th2);

        var th3 = document.createElement('TH');
		th3.appendChild(document.createTextNode(self.labels['SignificantDecimals']()));
		headerTR1.appendChild(th3);
				
		var th4 = document.createElement('TH');
		th4.appendChild(document.createTextNode(self.labels['Action']()));
		headerTR1.appendChild(th4);
		
		//for tbody
		var tableBody = document.createElement('TBODY');
		table.appendChild(tableBody);
		for (var i=0; i<self.measureUnits.models.length; i++){
			var value = self.measureUnits.at(i);
			var tr = document.createElement('TR');
			tableBody.appendChild(tr);
			var td1 = document.createElement('TD');
			td1.appendChild(document.createTextNode(value.get('name')));
			td1.style.textAlign = "left";
			tr.appendChild(td1);

            var td2 = document.createElement('TD');
			td2.appendChild(document.createTextNode(value.get("roundingDigits")));
			td2.style.textAlign = "right";
			tr.appendChild(td2);

            var td3 = document.createElement('TD');
			td3.appendChild(document.createTextNode(value.get("significantDecimals")));
			td3.style.textAlign = "right";
			tr.appendChild(td3);
					
			var td4 = document.createElement('TD');
            var editIcon = document.createElement('icon');
            editIcon.className = 'glyphicon glyphicon-pencil glyphicon-button';
            editIcon.title = 'Edit Value'; 
            editIcon.id = value.id; 
            editIcon.style.fontSize = '1.2em';
            td4.style.textAlign = "center";
            td4.appendChild(editIcon);
            tr.appendChild(td4);

            var unitId


            function editUnitDetails (unit,roundingDigits,significantDecimals){
                self.selectedUnit(unit);
                self.selectedRoundingDecimal(roundingDigits);
                self.selectedSignificantDecimals(significantDecimals);
                var selectedUnit = self.measureUnits.findWhere({id:unitId})
                selectedUnit.set('name',unit);
                selectedUnit.set('description',unit)
                selectedUnit.set('roundingDigits',roundingDigits)
                selectedUnit.set('significantDecimals',significantDecimals)
                const model = self.model.get('unit').findWhere({id:unitId});
                model.set('name',unit);
                model.set('description',unit)
                model.set('roundingDigits',roundingDigits)
                model.set('significantDecimals',significantDecimals);
                self.displayTable();
            }

            $(editIcon).on('click', function(event) {
                unitId = event.target.id;
                var model = self.model.get('unit').findWhere({id:event.target.id});
                var addOptions = {unit:model.get('name'),roundingDigits:model.get('roundingDigits'),significantDecimals:model.get('significantDecimals'),showUnit:true};
                var valUnit;
                var BusinessModelMixin = Backbone.Relational.store.getObjectByName("vdml.BusinessModel2Mixin");
                window.getAndCreateModalDialog(self.model.get('unit'), self.encodeId, BusinessModelMixin, valUnit, "ValueUnitDetails", editUnitDetails, addOptions);
            });

			var icon = document.createElement('icon');
			icon.className = 'glyphicon glyphicon-trash glyphicon-button';
			icon.title = 'Delete Value';
			icon.id = value.id;
			icon.style.fontSize = '1.2em';
            icon.style.marginLeft = '25px';
			td4.appendChild(icon);
			tr.appendChild(td4);
			$(icon).on('click', function(event){
                var model = self.model.get('unit').findWhere({id:event.target.id});
                DataManager.getDataManager().getReferencesToModel2(model, function (data) {
                    const result = data.find(element => element.type !== "vdml_ValueLibrary");
                    const valDefArr = data.filter(element => element.type !== "vdml_ValueLibrary")
                    if (result !== undefined) {
                        bootbox.confirm({
                            message: "This unit is used in"+" "+ valDefArr.map(d=>d.name).join(', ')+". "+"Deleting this unit will permanently delete from all the places it is used, you want to continue?" ,
                            buttons: {
                                cancel: {
                                    label: "Cancel",
                                },
                                confirm: {
                                    label: "OK",
                                    className : "okBut"
                                }
                            },
                            callback: function(result) {
                                if (result) {
                                    self.deleteUnit(value, event, function(){
                                        self.displayTable ? self.displayTable() : '';
                                    });
                                }
                            }
                        });
                        if(self.isDefaultValueLibrary()) $('.okBut').hide();
                        // bootbox.alert("This unit is used in"+" "+ result.name)
                    } else {
                        bootbox.confirm("Are you sure, you want to delete?", function(result) {
                            if (result) {
                            self.deleteUnit(value, event, function(){
                            self.displayTable ? self.displayTable() : '';
                            });
                            }
                        });
                    }
                });
            });
           

            
		}
		myTableDiv.append(table);
		self.dataTable = $('#table' + self.encodeId).DataTable({
			"columnDefs": [
				{ "orderable": false, "targets": 3} 
			],
			"sDom": '<"pull-right"f><"table-container"t><"bottom d-flex"<"pull-left"i><"pull-right"p>>',
			"fnDrawCallback": function( oSettings ) {
				//self.setResizeHeight();
			}
		});
		$('#table'+self.encodeId+'_filter').css({float:'left'});
		$('#table'+self.encodeId+'_length').css('width','32%');

        if(!DataManager.getDataManager().get('readMode') || !window.utils.checkIsLinked(null,true)){
		    $("<div style=\"float:right; margin-left:5px;\"><span id: 'valueAddIconDiv'"+self.encodeId+"}\">"+
				    "<b>"+DataManager.getDataManager().get('localeManager').get('addAnother')+"</b>"+
				    "<button id = 'valueAddIconDiv'"+self.encodeId+"\" style=\"float:right; font-size: 1.2em;border: 0px;background: #f5f5f5;\" data-toggle=\"tooltip\" title=\"Add Value\" class=\"glyphicon glyphicon-plus-sign glyphicon-button \"></button>"+
		    "</span></div>").insertAfter('#table'+self.encodeId+'_filter');
        }
		$('#valueAddIconDiv').click(function(){
			var addOptions = {unit:"",roundingDigits:self.selectedRoundingDecimal(),significantDecimals:self.selectedSignificantDecimals(),showUnit:true,createUnit:true};
            var valUnit;
            var BusinessModelMixin = Backbone.Relational.store.getObjectByName("vdml.BusinessModel2Mixin");
			window.getAndCreateModalDialog(self.model.get('unit'), self.encodeId, BusinessModelMixin, valUnit, "ValueUnitDetails", self.saveTableUnitDetails, addOptions);
		});
	};

    clearAndReload() {
        super.clearAndReload();
        var self = this;
        self.selectedName('');
        self.selectedDescription('');
        self.selectedDefinition('');
        self.selectedBalanceKind('')
        self.selectedIsAccumulated(false);
        self.selectedIsCalculated(false);
        self.selectedExternalId("");
        self.selectedSortOrder("");
        self.selectedRoundingDecimal();
        self.selectedSignificantDecimals() ;
        self.selectedIsRescalable(false);
        self.selectedAggregationMethod('');
        self.selectedUnit("")
        self.selectedUpdateDataSet(false);
        self.selectedCountEmptyAsZero(false);
        self.fillMeasurementUnits()
    }

    saveCapElement(view, event) {
        var self = view;
        var refresh = false;
        var valModel = self.getValElementModal(self.selectedCapId);
        if (valModel) {
            refresh = true
            if (valModel.get('name') != self.selectedName()) {
                valModel.set('name', self.selectedName());
                valModel.set('description', self.selectedDescription());
                refresh = true;
            }
            var summernote = $('#summernoteDefinition' + self.encodeId);
            var contentData = summernote.summernote('code');
            if (contentData == "") {
                contentData = null;
            }
            if (contentData != self.selectedDefinition()) {
                valModel.set('definition', contentData);
                //refresh = true;
            }
            if (self.showBoolean()) {
                valModel.set('domain', $('#type').val());
                valModel.set('bounds', $('input[name="boolean"]:checked').val());
            } else {
                if ($('#minRange' + self.encodeId).val() && $('#maxRange' + self.encodeId).val()) {
                    valModel.set('minRange', $('#minRange' + self.encodeId).val());
                    valModel.set('maxRange', $('#maxRange' + self.encodeId).val());
                    valModel.set('domain', $('#type').val());
                }
            }
           
            if (valModel.get('balanceKind') != self.selectedBalanceKind()) {
                valModel.set('balanceKind',self.selectedBalanceKind());
            }
            if (valModel.get('updateDataSet') != self.selectedUpdateDataSet()) {
                valModel.set('updateDataSet',self.selectedUpdateDataSet());
            }
            if (valModel.get('countEmpAsZero') != self.selectedCountEmptyAsZero()) {
                valModel.set('countEmpAsZero',self.selectedCountEmptyAsZero());
            }
            if (valModel.get('externalId') != self.selectedExternalId()) {
                valModel.set('externalId',self.selectedExternalId());
            }
            if (valModel.get('sortOrder') != self.selectedSortOrder()) {
                valModel.set('sortOrder',self.selectedSortOrder());
            }
            if (valModel.get('isRescalable') != self.selectedIsRescalable()) {
                valModel.set('isRescalable',self.selectedIsRescalable());
            }
            if (valModel.get('aggregationMethod') != self.selectedAggregationMethod()) {
                valModel.set('aggregationMethod',self.selectedAggregationMethod());
            }
            if (valModel.get('valueType') != self.selectedValueType()) {
                valModel.set('valueType',self.selectedValueType());
            }
            if (valModel.get('isCalculated') != self.selectedIsCalculated()) {
                valModel.set('isCalculated',self.selectedIsCalculated());
            }
            if (valModel.get('isAccumulated') != self.selectedIsAccumulated()) {
                valModel.set('isAccumulated',self.selectedIsAccumulated());
            }
            if(self.selectedValueType().name === "Aggregated"){
                self.setExpression(valModel,self.selectedFormula());
            }else {
                if(valModel.get('formula')){
                    valModel.get('formula').destroy();
                }
            }
            self.setUnit(valModel,self.selectedUnit(),self.selectedRoundingDecimal(),self.selectedSignificantDecimals());
         
            self.enableCapComplete(false);
        }
        if (refresh) {
            var newCap = self.selectedCapId;
            setTimeout(function () {
                $('#jstree_div'+self.encodeId).jstree('select_node', (newCap));
                $('#jstree_div'+self.encodeId).jstree('open_node', (newCap));
                window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'), DataManager.getDataManager().get('viewAlternative'), self.ValueLibraryViewModel);
            }, 100);
            self.setResizeHeight();
        }
       
    }

    afterRenderView(node, view) {
        var self = view;
        self.jsTreeModelData = self.getModelDataInJSTreeFormat([self.model.getParent().get('valueLibrary').slice()]);
        var pluginList =["contextmenu","search"];
        if(!DataManager.getDataManager().get("debugMode")){
            $('.showDomain').hide();
        }

        function customMenu(node) {
            var items = {
                createItem: { 
                    label: "Add Child",
                    action: function (event) {
                        self.addChild(view,event)
                    },
                    _disabled: node.original.type === "vdml_ValueDefinition"
                },
                deleteItem: {
                    label: "Delete",
                    action: function (event) {
                        self.deleteCapElement(view, event)
                    }
                }
            };
            return items;
        }

        var tree = $('#jstree_div'+self.encodeId).on("select_node.jstree", function (e, data) {
            if (data.selected.length) {
                var selectedNode = data.instance.get_node(data.selected[0]);
                var parentsLength = selectedNode.parents.length;
                self.childrens([]);
                self.selectedFormula("");
                self.selectedCapId = selectedNode.id;
                self.setPropertyValues(parentsLength,selectedNode);
                
                var summernote = $('#summernoteDefinition' + self.encodeId);
                summernote.summernote('code', '');
                summernote.summernote('destroy');
                $("#capdef_div").hide();
                self.selectedName(selectedNode.text);
                self.selectedDescription(selectedNode.original.text);
                self.selectedType(self.mappingObject[selectedNode.original.type].name);
                //$('a .showValueDetails'+self.encodeId)[0].click();
                if(self.selectedType() === 'Value Library') {
                    self.showValLibFields(false);
                    self.showValCatFields(false);
                } else if(self.selectedType() === 'Value Category'){
                    self.showValLibFields(false);
                    self.showValCatFields(true);
                } else {
                    self.showValLibFields(true);
                    self.showValCatFields(false);
                }
            }
        }).jstree({
            'core': {
                'check_callback': true,
                'data': function (obj, callback) {
                    callback.call(this, []);

                }
            },
            "plugins": pluginList,
            "contextmenu": { items: customMenu },
            "search": {
                'case_sensitive': false
            },
        });
        $('#jstree_div'+self.encodeId).bind('loaded.jstree', function () {

            setTimeout(function(){
                if(self.model.id){
                    $('#jstree_div'+self.encodeId).jstree(true).select_node( self.jsTreeModelData);
                    $('#jstree_div'+self.encodeId).jstree(true).open_node( self.jsTreeModelData);
                }
            },20);
            
        });
        $('#jstree_div'+self.encodeId).jstree(true).create_node(null, self.jsTreeModelData[0]);
        $('#treeSearch').change(function () {
            window.utils.startSpinner('jsonSearch', 'Searching...');
            setTimeout(function () {
                var v = $('#treeSearch').val();
                $('#jstree_div'+self.encodeId).jstree(true).search(v);
                window.utils.applyFontSize($('#content'));
                window.utils.stopSpinner('jsonSearch');
                $('.jstree-anchor').css('text-overflow', 'ellipsis');
                $('.jstree-anchor').css('overflow', 'hidden');
                $('.jstree-anchor').css('max-width', '97%');
            }, 10);
        });
        $('#jstree_div'+self.encodeId).on('dblclick.jstree', function (e) {
            var text = e.target.text;
            if (text && text.length > 0) {
                var textSub = text.substr(text.indexOf(":") + 2);
                navigator.clipboard.writeText(textSub);
            }
        });
        $('#jstree_div'+self.encodeId).on('open_node.jstree', function () {
            self.setResizeHeight();
        });
        $('#jstree_div'+self.encodeId).on('close_node.jstree', function () {
            self.setResizeHeight();
        });
        $('#type').on('change', function (view) {
            if ($('#type').val() === "Boolean") {
                self.showDomain(false);
                self.showBoolean(true);
            } else {
                self.showDomain(true);
                self.showBoolean(false)
            }
        })
        $('#minRange' + self.encodeId).on('keyup', function (view) {
            var value = view.currentTarget.value.trim();
            self.minRange(value);
            if (value && $('#type').val() === "Integers") {
                if (parseInt(self.minRange()) >= -2147483648 && $("#selectedName" + self.encodeId).val().trim()) {
                    self.enableCapComplete(true);
                } else {
                    self.enableCapComplete(true);
                }
            } else if (value && ($('#type').val() === "NegativeIntegers" || $('#type').val() === "NonPositiveIntegers")) {
                if (parseInt(self.minRange()) >= -2147483648 && $("#selectedName" + self.encodeId).val().trim()) {
                    self.enableCapComplete(true);
                } else {
                    self.enableCapComplete(true);
                }
            } else if (value && ($('#type').val() === "PositiveIntegers" || $('#type').val() === "NonNegativeIntegers")) {
                if (parseInt(self.minRange()) >= 0 && $("#selectedName" + self.encodeId).val().trim()) {
                    self.enableCapComplete(true);
                } else {
                    self.enableCapComplete(true);
                }
            }else {
                self.enableCapComplete(true);
            }
        })
        $('#maxRange' + self.encodeId).on('keyup', function (view) {
            var value = view.currentTarget.value.trim();
            self.maxRange(value);
            if (value && $('#type').val() === "Integers") {
                if (parseInt(self.maxRange()) <= 2147483647 && $("#selectedName" + self.encodeId).val().trim() && self.minRange() < self.maxRange()) {
                    self.enableCapComplete(true);
                } else {
                    self.enableCapComplete(true);
                }
            } else if (value && ($('#type').val() === "NegativeIntegers" || $('#type').val() === "NonPositiveIntegers") && self.minRange() < self.maxRange()) {
                if (parseInt(self.maxRange()) <= -1 && $("#selectedName" + self.encodeId).val().trim()) {
                    self.enableCapComplete(true);
                } else {
                    self.enableCapComplete(true);
                }
            } else if (value && ($('#type').val() === "PositiveIntegers" || $('#type').val() === "NonNegativeIntegers") && self.minRange() < self.maxRange()) {
                if (parseInt(self.maxRange()) <= 2147483647 && $("#selectedName" + self.encodeId).val().trim()) {
                    self.enableCapComplete(true);
                } else {
                    self.enableCapComplete(true);
                }
            }else {
                self.enableCapComplete(true);
            }
        })

        $("#selectedName" + self.encodeId).on('keyup', function (view) {
            var name = view.currentTarget.value.trim();
            self.selectedDescription(name);
            var prevName = self.selectedCapName;
            if (name === "") {
                self.enableCapComplete(false);
            } else if (name === prevName) {
                self.saveCapElement(false);
            } else {
                self.enableCapComplete(true);
            }
        });
        self.spliter = $("#splitPanels" + self.encodeId).enhsplitter({ minSize: 0, onDrag: window.utils.splitterBoundary, collapse: 'right', position: '50%', handle: 'block', splitterSize: '9px', height: $("#js-canvas" + self.encodeId).outerHeight() + $('.table-responsive').outerHeight() + 300, onDragEnd: self.setResizeHeight });
        $(window).resize(function (view, event) {
            this.timer = clearTimeout();
            this.timer = setTimeout(function () { self && self.setResizeHeight && self.setResizeHeight(); }, 200);
        });

        var unit = self.selectedUnit() ? self.selectedUnit() : "";
        
        var Extended = Backbone.Typeahead.extend({
            template: '<input type="text" value ="' + unit  + '" id="measurementUnit' + self.encodeId + '" placeholder="'+DataManager.getDataManager().get('localeManager').get('Unit')+'" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        self.measureUnittypeahead = new Extended({ collection: self.measureUnits });
        self.measureUnittypeahead.setElement('#measurementUnitDiv' + self.encodeId).render();

        self.measureUnittypeahead.on('selected', function (fetchmodel) {
            self.selectedUnit(fetchmodel.get('name'));
            self.roundingFactor(true);
            $('#measurementUnitDiv' + self.encodeId).val(fetchmodel.get('name'));
            self.matchField[0].innerHTML = "Match";
            self.matchField.show();
        });
        $('#measurementUnit' + self.encodeId).change(function (view) {
            var newValue = view.target.value.trim();
            if (self.model) {
                setTimeout(function () {
                    var unitName;
                    var unitNodeVal = $('#measurementUnit' + self.encodeId).val();
                    if (self.model && !!self.model.get('unit')) {
                        unitName = self.model.get(self.currentAlternativeModel, self.model.get('unit'));
                    }
                    if (unitName && unitNodeVal !== "" && unitNodeVal !== unitName) {
                        bootbox.alert(DataManager.getDataManager().get('localeManager').get('PreviousUnit', unitName));
                    }
                    self.roundingFactor(true)
                }, 300);
            }
            self.selectedUnit(newValue);
            $('#recipientUnitDiv' + self.encodeId).text(newValue);
        });
        $('#measurementUnit' + self.encodeId).keyup(function (view) {
            self.matchField = $(view.currentTarget).closest('.row').find('.match');
            if (!view.target.value.trim() == "") {
                var unitMatch = self.measureUnits.findWhere({ name: view.target.value });
                if (unitMatch) {
                    self.matchField[0].innerHTML = 'Match';
                    self.matchField.show();
                    $('#measurementUnit' + self.encodeId).next().find('li').filter(function () { return $(this).text() === view.target.value; }).click();
                }
                else if (!view.target.value == "") {
                    self.matchField[0].innerHTML = 'New';
                    self.matchField.show();
                }
                self.selectedUnit(view.target.value);
            }
            else {
                self.matchField.hide();
            }
        });
        $('#measurementUnit' + self.encodeId).dblclick(function (view) {
            $('#measurementUnit' + self.encodeId).trigger('keyup');
            $('#measurementUnit' + self.encodeId).focus();
        });

        $('#rightTab' + self.encodeId + ' a').click(function (e) {
            e.preventDefault();
            if ($(this).text() === "Collaboration") {
                return false;
            }
            $(this).tab('show');
            $('.note-popover').hide()
            if ($(this).text() === "Guidance") {
                if ($('#guidanceTab').is(':empty')) {
                    $.get('js/app/version1/views/help/views/properties/ValueLibraryGuidanceTemplate.html', function(viewStr) {
                        self.countGuidanceClicks++;
                        $('#guidanceTab').empty();
                        $('#guidanceTab').append(viewStr);
                        if (self.configObj && self.configObj.guidance) {
                            $('#guidanceTab').append(self.configObj.guidance);
                        }
                        window.utils.applyFontSize($('#guidanceTab'));
                        self.setResizeHeight();
                    })
                } else {
                    self.setResizeHeight();
                }
            }  else if ($(this).text() === "Unit Details") {
                self.fillMeasurementUnits()
                 self.displayTable();
            } else {
                self.setResizeHeight();
            }     
        });

        $('#rightTab' + self.encodeId + ' a').click(function (e) {
            e.preventDefault();
            if ($(this).text() === "Collaboration") {
                return false;
            }
            $(this).tab('show');
            $('.note-popover').hide()
                 
        });

        $("#summernoteDefinition" + self.encodeId).on('keyup', function (view) {
            var name = view.currentTarget.value.trim();
            var prevName = self.selectedCapName;
            if (name === "") {
                self.enableCapComplete(false);
            } else if (name === prevName) {
                self.saveCapElement(false);
            } else {
                self.enableCapComplete(true);
            }
        });

        function checkboxChangeMethod(view,fieldName){
            var checked = view.currentTarget.checked;
            var valModel = self.getValElementModal(self.selectedCapId)
            if (checked === valModel.get(fieldName)) {
                self.enableCapComplete(false);
            } else {
                self.enableCapComplete(true);
            }
        }

        function inputFieldChangeMethod(view,fieldName){
            var value = view.currentTarget.value;
            var valModel = self.getValElementModal(self.selectedCapId)
            if (value === valModel.get(fieldName)) {
                self.enableCapComplete(false);
            } else {
                self.enableCapComplete(true);
            }
        }


        $("#updateDataSet").on('change', function (view) {
            checkboxChangeMethod(view,'updateDataSet')
        });

        $("#countEmptyAsZero").on('change', function (view) {
            checkboxChangeMethod(view,'countEmptyAsZero')
        });

        $("#type").on('change', function (view) {
            var value = view.currentTarget.value;
            var valModel = self.getValElementModal(self.selectedCapId)
            if(value === "Boolean"){
                self.showBooleanBound(true);
                self.enableCapComplete(false);
            }
            else if (value === valModel.get('domain')) {
                self.enableCapComplete(false);
                self.showBooleanBound(false)
            } 
            else {
                self.showBooleanBound(false)
                self.enableCapComplete(true);
            }
        });

        $("#measurementUnit"+self.encodeId).on('change', function (view) {
            var value = view.currentTarget.value;
            var valModel = self.getValElementModal(self.selectedCapId)
            if (!!valModel.get('unit') && value === valModel.get('unit').get('name')) {
                self.enableCapComplete(false);
            } else {
                self.enableCapComplete(true);
            }
        });

        $("#externalId"+self.encodeId).on('change', function (view) {
            inputFieldChangeMethod(view,'externalId')
        });

        $("#sortOrder"+self.encodeId).on('change', function (view) {
            inputFieldChangeMethod(view,'sortOrder')
        });

        $("#balanceKind").on('change', function (view) {
            inputFieldChangeMethod(view,'balanceKind')
        });

        $("#aggregationMethod").on('change', function (view) {
            inputFieldChangeMethod(view,'aggregationMethod')
        });

        self.selectedValueType.subscribe(function(value){
            var valModel = self.getValElementModal(self.selectedCapId)
            if(value.name === valModel.get('valueType')){
                self.enableCapComplete(false);
                if(!["Atomic","Composite"].includes(value.name)){
                    self.showFormula(true);
                }else{
                    self.showFormula(false);
                }
            }else if(value.name !== valModel.get('valueType')){
                self.enableCapComplete(true);
                if(!["Atomic","Composite"].includes(value.name)){
                    self.showFormula(true);
                }else {
                    self.showFormula(false);
                }
            }else {
                self.showFormula(false);
                self.enableCapComplete(true);
            }
        })

        $("#isCalculated").on('change', function (view) {
           checkboxChangeMethod(view,'isCalculated')
        });

        $("#accumulated").on('change', function (view) {
            checkboxChangeMethod(view,'accumulated')
        });

        $("#isRescalable").on('change', function (view) {
            checkboxChangeMethod(view,'isRescalable')
        });

        $('#jstree_div'+self.encodeId).bind('ready.jstree', function (e, data) {
            $('#jstree_div'+self.encodeId).jstree('open_node', (self.encodeId));
        });
        self.setResizeHeight();
        window.utils.stopSpinner('valueLibrarySpinner');
    };

    static getInstance(model, options) {
        var view = new ValueLibrary2ViewModel(model, options);
        return view; 
    }
}
path.ValueLibrary2ViewModel = ValueLibrary2ViewModel;
