import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {EntityReferenceMixin} from './EntityReferenceMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ObjectReference } from './ObjectReference'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/EntityReferenceMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,EntityReferenceMixin
, ObjectReference){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ObjectReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ObjectReference){
			ObjectReference = importPath.ObjectReference;
		}
        else {
            import('./ObjectReference').then(({ default: ObjectReference }) => {
                ObjectReference = ObjectReference;
            });
           /* require(["appbo/vdml/ObjectReference"], function (loadedModule) {
                ObjectReference = loadedModule;
			});*/
		}
	}
	var EntityReference = VdmlElement.extend(utils.customExtends({
		relations:EntityReferenceMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new EntityReferenceMixin()
	));
	
	EntityReference.abstract = false;
	EntityReference.supported = false;
	path.EntityReference = EntityReference;
	export {EntityReference};
//});