import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {ObjectReference} from './ObjectReference'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, ObjectReference){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ObjectReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ObjectReference){
			ObjectReference = importPath.ObjectReference;
		}
		else{
			import('./ObjectReference').then(({ default: ObjectReference }) => {
                ObjectReference = ObjectReference;
            });
			/*require(["appbo/vdml/ObjectReference"],function(loadedModule){
				ObjectReference = loadedModule;
			});*/
		}
	}
	export class ApplicationInterfaceMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.ApplicationInterfaceMixin = ApplicationInterfaceMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_ApplicationInterface"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"vdml_ApplicationInterface",
			key:"userInterface",
			relatedModel:"vdml.ObjectReference",
			reverseRelation: {
				key:"userInterfaceOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	
	static getCumulativeMixinRelations(){
		if (!ApplicationInterfaceMixin.cummulativeRelations) {
            ApplicationInterfaceMixin.cummulativeRelations = _.union(ApplicationInterfaceMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return ApplicationInterfaceMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "Url",type : "EString",defaultValue : "null",containingClass : "vdml_ApplicationInterface" },
			{name : "method",type : "EString",defaultValue : "null",containingClass : "vdml_ApplicationInterface" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("applicationInterfaceOwner") ? this.get("applicationInterfaceOwner") : this.previousAttributes().applicationInterfaceOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("applicationInterfaceOwner") ? this.get("applicationInterfaceOwner") : this.previousAttributes().applicationInterfaceOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("applicationInterfaceOwner") ? this.get("applicationInterfaceOwner") : this.previousAttributes().applicationInterfaceOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("applicationInterfaceOwner") ? this.get("applicationInterfaceOwner") : this.previousAttributes().applicationInterfaceOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ApplicationInterfacePropertiesTemplate.html",
			templateName : "ApplicationInterfacePropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ApplicationInterfaceViewModel",
			tabId : "ApplicationInterfaceView",
			tabName: "ApplicationInterface"
		}
	}
	
	}
	path.ApplicationInterfaceMixin = ApplicationInterfaceMixin;
	//return ApplicationInterfaceMixin;
//});