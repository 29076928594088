import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {BusinessItemLibraryElementMixin} from './BusinessItemLibraryElementMixin'
import {Characteristic} from '../smm/Characteristic'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/BusinessItemLibraryElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,BusinessItemLibraryElementMixin
, Characteristic){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Characteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Characteristic){
			Characteristic = importPath.Characteristic;
		}
		else{
			import('../smm/Characteristic').then(({ default: Characteristic }) => {
                Characteristic = Characteristic;
            });
			/*require(["appbo/smm/Characteristic"],function(loadedModule){
				Characteristic = loadedModule;
			});*/
		}
	}
	var BusinessItemLibraryElement = VdmlElement.extend(utils.customExtends({
		relations:BusinessItemLibraryElementMixin.getMixinRelations(),
		subModelTypes: {
			vdml_BusinessItemCategory : 'vdml.BusinessItemCategory',
			vdml_BusinessItemDefinition : 'vdml.BusinessItemDefinition'
		},
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new BusinessItemLibraryElementMixin()
	));
	BusinessItemLibraryElement.abstract = true;
	BusinessItemLibraryElement.supported = true;	
	path.BusinessItemLibraryElement = BusinessItemLibraryElement;
	export {BusinessItemLibraryElement};
//});