import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { VdmlElement } from './VdmlElement'
import { PortDelegationMixin } from './PortDelegationMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { BindingOperationReference } from './BindingOperationReference'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/PortDelegationMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,PortDelegationMixin
, BindingOperationReference){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!BindingOperationReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BindingOperationReference){
			BindingOperationReference = importPath.BindingOperationReference;
		}
        else {
            import('./BindingOperationReference').then(({ default: BindingOperationReference }) => {
                BindingOperationReference = BindingOperationReference;
            });
			/*require(["appbo/vdml/BindingOperationReference"],function(loadedModule){
				BindingOperationReference = loadedModule;
			});*/
		}
	}
	var PortDelegation = VdmlElement.extend(utils.customExtends({
		relations:PortDelegationMixin.getMixinRelations(),
		subModelTypes: {
			vdml_InputDelegation : 'vdml.InputDelegation',
			vdml_OutputDelegation : 'vdml.OutputDelegation'
		},
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new PortDelegationMixin()
	));
	
	PortDelegation.abstract = false;
	path.PortDelegation = PortDelegation;
export { PortDelegation };
//});