import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'


/*define(["require", "jquery", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "app/global", "appcommon/com/vbee/filesystem/VMPServerService"],
    function (require, $, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, global, VMPServerService) {*/

    var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties", global.version);
    export class PostmanViewModel{
        constructor(model,options){
            var self = this;
            this.PostmanViewModel = this;

            this.id = ko.observable("postman");
            function htmlEscape(str) {
                return String(str)
                    .replace(/@/g, '')
                    .replace(/ /g, '');
            }
            this.encodeId = htmlEscape(this.id());
            /*this.enableRevision = ko.observable(false);*/
            //this.enableDelete = ko.observable(true);
            this.services = ko.observableArray(['vmpServerService', 'vmpSnapshotService']);
            this.selectedService = ko.observable();

            this.url = ko.observable();
            this.methods = ko.observableArray(['Get', 'Post', 'Put', 'Delete']);
            this.selectedMethod = ko.observable();
            this.contentTypes = ko.observableArray(['application/json', 'text/uri-list']);
            this.selectedContentType = ko.observable();
            this.htmlBody = ko.observable();
            this.properties = {};

            $('#modal' + self.encodeId).on('shown.bs.modal', function () {
                window.utils.focusElement("#newModal .focus-ele");
            });    
        }

        
        dispose = function () {
            var self = this;
            window.cleanViewModel(self);
        }
        send = function () {
            var self = this;
            var requestData;
            try {
                switch (self.selectedMethod()) {
                    case 'Get':
                        DataManager.getDataManager().get(self.selectedService()).getAllPromise(self.url()).then(function (data) {
                            self.htmlBody(JSON.stringify(data, null, "\t"));
                        })
                        break;
                    case 'Post':
                        requestData = JSON.parse(self.htmlBody());
                        var currentLegalEntity = DataManager.getDataManager().get("currentLegalEntity");
                        if (currentLegalEntity && currentLegalEntity.entityId) {
                            requestData.currentEntityId = currentLegalEntity.entityId;
                        }
                        switch (self.selectedContentType()) {
                            case 'text/uri-list':
                                _.extend(requestData, self.properties);
                                DataManager.getDataManager().get(self.selectedService()).postAllPromiseForUriList(self.url(), JSON.stringify(requestData), 'POST').then(function (data) {
                                    self.htmlBody(JSON.stringify(data, null, "\t"));
                                });
                                break;
                            case 'application/json':
                                _.extend(requestData, self.properties);
                                DataManager.getDataManager().get(self.selectedService()).postAllPromise(self.url(), JSON.stringify(requestData), 'POST').then(function (data) {
                                    self.htmlBody(JSON.stringify(data, null, "\t"));
                                });
                                break;
                        }
                        break;
                    case 'Put':
                        requestData = JSON.parse(self.htmlBody());
                        var currentLegalEntity = DataManager.getDataManager().get("currentLegalEntity");
                        if (currentLegalEntity && currentLegalEntity.entityId) {
                            requestData.currentEntityId = currentLegalEntity.entityId;
                        }

                        switch (self.selectedContentType()) {
                            case 'text/uri-list':
                                DataManager.getDataManager().get(self.selectedService()).postAllPromiseForUriList(self.url(), JSON.stringify(requestData), 'PUT').then(function (data) {
                                    self.htmlBody(JSON.stringify(data, null, "\t"));
                                });
                                break;
                            case 'application/json':
                                DataManager.getDataManager().get(self.selectedService()).postAllPromise(self.url(), JSON.stringify(requestData), 'PUT').then(function (data) {
                                    self.htmlBody(JSON.stringify(data, null, "\t"));
                                });
                                break;
                        }
                        break;
                    case 'Delete':
                        requestData = JSON.parse(self.htmlBody());
                        var currentLegalEntity = DataManager.getDataManager().get("currentLegalEntity");
                        if (currentLegalEntity && currentLegalEntity.entityId) {
                            requestData.currentEntityId = currentLegalEntity.entityId;
                        }
                        switch (self.selectedContentType()) {
                            case 'application/json':
                                DataManager.getDataManager().get(self.selectedService()).deleteAllPromiseForUriList(self.url(), JSON.stringify(requestData),true).then(function (data) {
                                    self.htmlBody(JSON.stringify(data, null, "\t"));
                                });
                                break;
                            case 'text/uri-list':
                                DataManager.getDataManager().get(self.selectedService()).deleteAllPromiseForUriList(self.url(), JSON.stringify(requestData)).then(function (data) {
                                    self.htmlBody(JSON.stringify(data, null, "\t"));
                                });
                                break;
                        }
                        break;
                    case 'Patch':
                        break;
                }
            }catch (e) {
                bootbox.alert(e.message);
            }
        }
        afterRenderView = function () {

        }
        clearProperties = function () {
            this.properties = {};
        }
        setProperty = function () {
            var self = this;
            var htmlContent = "<div class=\"row\">";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "<div class=\"col-xs-3\">";
            htmlContent = htmlContent + "<span class=\"control-label\">Property</span>";
            htmlContent = htmlContent + "</div>";
            htmlContent = htmlContent + "<div class=\"col-xs-7\">";
            htmlContent = htmlContent + "<input id=\"prop\" class=\"form-control focus-ele\" autofocus type=\"text\"></input>";
            htmlContent = htmlContent + "</div>";
            htmlContent = htmlContent + "</div><br>";
            htmlContent = htmlContent + "</div>";
            htmlContent = htmlContent + "<div class=\"row\">";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "<div class=\"col-xs-3\">";
            htmlContent = htmlContent + "<span class=\"control-label\">Content</span>";
            htmlContent = htmlContent + "</div>";
            htmlContent = htmlContent + "<div class=\"col-xs-7\">";
            htmlContent = htmlContent + "<textarea rows=\"5\" id=\"propContent\" class=\"form-control focus-ele\" autofocus type=\"text\"></textarea>";
            htmlContent = htmlContent + "</div>";
            htmlContent = htmlContent + "</div><br>";
            htmlContent = htmlContent + "</div>";
            var box = bootbox.dialog({
                message: htmlContent,
                title: "<div>&nbsp;&nbsp;&nbsp;Observation Properties</div>",
                buttons: {
                    danger: {
                        label: "&nbsp;Set",
                        className: "btn glyphicon pull-left",
                        callback: function () {
                            self.properties[$('#prop')[0].value] = $('#propContent')[0].value;
                        }
                    }
                }
            });
            box.show();
        };

        cleanModalDialog = function () {
            var self = this;
            window.cleanDialogModel(self.encodeId, self);
        };
        static getInstance = function (model,options) {
            return new PostmanViewModel(model,options);
        };

    }
    path.PostmanViewModel = PostmanViewModel;
    //});
