import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from '../vdml/VdmlElement'
import {LookupTableInstanceMixin} from './LookupTableInstanceMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("instdef",global.version);

var LookupTableInstance = VdmlElement.extend(utils.customExtends({
        relations:LookupTableInstanceMixin.getMixinRelations(),
        initialize: function(attributes, options) {
            VdmlElement.prototype.initialize.apply(this, arguments);
            
        }
    }
    , new LookupTableInstanceMixin()
));
LookupTableInstance.abstract = true;
path.LookupTableInstance = LookupTableInstance;
export {LookupTableInstance};
