import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasurement} from './DimensionalMeasurement'
import {CollectiveMeasurementMixin} from './CollectiveMeasurementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { Operation } from './Operation'
import { BaseNMeasurementRelationship } from './BaseNMeasurementRelationship'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasurement","appbo/smm/CollectiveMeasurementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasurement,CollectiveMeasurementMixin

, Operation
, BaseNMeasurementRelationship){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!DimensionalMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasurement){
			DimensionalMeasurement = importPath.DimensionalMeasurement;
		}
        else {
            import('./DimensionalMeasurement').then(({ default: DimensionalMeasurement }) => {
                DimensionalMeasurement = DimensionalMeasurement;
            });

			/*require(["appbo/smm/DimensionalMeasurement"],function(loadedModule){
				DimensionalMeasurement = loadedModule;
			});*/
		}
	}
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
        else {
            import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });

			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	if(!BaseNMeasurementRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.BaseNMeasurementRelationship){
			BaseNMeasurementRelationship = importPath.BaseNMeasurementRelationship;
		}
        else {
            import('./BaseNMeasurementRelationship').then(({ default: BaseNMeasurementRelationship }) => {
                BaseNMeasurementRelationship = BaseNMeasurementRelationship;
            });

            /*require(["appbo/smm/BaseNMeasurementRelationship"], function (loadedModule) {
                BaseNMeasurementRelationship = loadedModule;
			});*/
		}
	}
	var CollectiveMeasurement = DimensionalMeasurement.extend(utils.customExtends({
		relations:CollectiveMeasurementMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			DimensionalMeasurement.prototype.initialize.apply(this, arguments);
		}
		}
		, new CollectiveMeasurementMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	CollectiveMeasurement.abstract = false;
	CollectiveMeasurement.supported = !sp2Enabled;
	path.CollectiveMeasurement = CollectiveMeasurement;
	export {CollectiveMeasurement};
//});