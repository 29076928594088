import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import * as jszip from '../../../../../../libs/jszip/jszip.min'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import {PhaseObjectiveSet} from '../../../../../version1/bo/transformation/PhaseObjectiveSet'
import {Alternative} from '../../../../../version1/bo/transformation/Alternative'
import {CodeContainer} from '../../../../../version1/bo/beeppackage/CodeContainer'
import {Observation} from '../../../../../version1/bo/smm/Observation'
import {Scenario} from '../../../../../version1/bo/vdml/Scenario'
import {MeasuredCharacteristic} from '../../../../../version1/bo/vdml/MeasuredCharacteristic'
import {BeepVocabulary} from '../../../../../version1/bo/concept/BeepVocabulary'
/*
define(["require", "jquery", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "jszip", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/concept/BeepVocabulary", "appbo/transformation/PhaseObjectiveSet", "appbo/vdml/Scenario", "appbo/vdml/MeasuredCharacteristic", "appbo/smm/Observation", "appbo/transformation/Phase", "appbo/transformation/Alternative", "appbo/beeppackage/CodeContainer"],
    function (require, $, _, Backbone, ko, koMapping, kb, bootbox, async, jszip, DataManager, global, BeepVocabulary, PhaseObjectiveSet, Scenario, MeasuredCharacteristic, Observation, Phase, Alternative, CodeContainer)
{*/
	var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties",global.version);
export class  AdvancedPlanDetailsViewModel {


    /*submitToIp() {
        var self = this;
        var dataManager = DataManager.getDataManager();
        var localmsg = dataManager.get('localeManager').get('submitToIp');
        if(self.publishedDate()){
            localmsg = dataManager.get('localeManager').get('reSubmitToIp');
        }
        bootbox.confirm(localmsg, function (val) {
            if (val) {
                window.utils.startSpinner('submitIP', "Submitting Plan... ");
                var update = false;
                if(self.publishedDate()){
                    update = true;
                }
                dataManager.submitToIp(self.model.id,self.model.get('type'),update,function(){
                    self.enableIPSubmit(false);
                    window.utils.stopSpinner('submitIP');
                });
            }
        });
    };*/

    submitToMarket() {
        var self = this;
        var dataManager = DataManager.getDataManager();
        var planId = self.model.id;
        DataManager.getDataManager().checkOwnedPackage(self.model,function(resp){
            if(resp && self){
                /*if(resp.productId){
                    self.productId = resp.productId;
                }*/
                if(resp.owner){
                    var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
                    var planDoc = dataManager.get("artifactsDocuments")[planId];
                    if(resp.marketplaceId){
                        dataManager.navigateToCrmPage("workspace/"+currentWorkspaceId+"/submitted/"+resp.marketplaceId+"/"+planDoc.artifactId);
                    } else {
                        dataManager.navigateToCrmPage("workspace/"+currentWorkspaceId+"/"+planDoc.artifactId+"/marketplace");
                    }
                    //self.showSwitch(true); 
                    //self.showBackUp(true);
                    //self.showMarketSubmit(true);
                }else {
                    bootbox.alert("Package Submission is not allowed");
                }
                /*if(resp.published){
                    self.publishedDate(new Date(resp.publishedDate).toDateString());
                }*/                     
            }
        });
        //var planDocVersion = planDoc ? planDoc.documentVersion : self.model.get('documentVersion');
    };

    createBackUp(){
        var self = this;
        var dataManager = DataManager.getDataManager();
        var planId = self.model.id;
        var now = new Date();
        var dateTime = now.getFullYear() + "-" +
               (now.getMonth() + 1) + "-" +
               now.getDate() + " " +
               now.getHours() + ":" +
               now.getMinutes();
        bootbox.prompt({
            title: "Version Label",
            //inputType: 'textarea',
            value: dateTime, /* this is the option you would use */
            callback: function (versionLabel) {
                        var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
                        var packArtDoc = dataManager.get("artifactsDocuments")[planId];
                        var legalEntityId = dataManager.get("workspaceLegal")[currentWorkspaceId];
                        var existingPackArtId = packArtDoc ? packArtDoc.artifactId :planId ;
                        var url = '/vdmbee/workspace/backup/'+currentWorkspaceId+'/'+existingPackArtId+'?entityId=' + legalEntityId+'&versionlabel='+versionLabel;
                        dataManager.get('vmpServerService').postAllPromise(url).then(function(data) {
                            bootbox.alert("BackUp SucessFully done");
                        });
                    }
        });
    }
    getPlanVersions(previousDocumentVersion, callback){
        var self = this;
        var dataManager = DataManager.getDataManager();
        var planId = self.model.id;
        var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
        var packArtDoc = dataManager.get("artifactsDocuments")[planId];
        var legalEntityId = dataManager.get("workspaceLegal")[currentWorkspaceId];
        var existingPackArtId = packArtDoc ? packArtDoc.artifactId :planId ;
        var url = '/vdmbee/package/versions/'+currentWorkspaceId+'/'+existingPackArtId+'?entityId=' + legalEntityId;			
        dataManager.get('vmpServerService').getAllPromise(url).then(function(data) {
            self.packageVersionsList([]);
            if(data) {
                for(var i=0; i<data.length; i++){
                    self.packageVersionsList.push({name: data[i].versionLabel, id: data[i].id, documentId:data[i].documentId, version:data[i].version});
                    if(data[i].version == previousDocumentVersion){
                        self.selectedVersion(data[i].versionLabel);
                    }
                }
            }
            callback(data);
        });
    }
    showPlanVersions(){
        debugger
        var self = this;
        var dataManager = DataManager.getDataManager();
        var planId = self.model.id;
        var previousDocumentVersion = self.model.get("previousDocumentVersion");
        var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
        //var planDoc = dataManager.get("artifactsDocuments")[planId];
        //var artId = planDoc ? planDoc.artifactId : planId;
        self.getPlanVersions(previousDocumentVersion, function(){
            var htmlContent = "<div class=\"row\">";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "<div class=\"col-xs-3\">Current Version</div>";
            htmlContent = htmlContent + "<div class=\"col-xs-7\">";
            htmlContent = htmlContent + "<span>" + self.selectedVersion() + "</span>";
            htmlContent = htmlContent + "</div></div><br>";
            htmlContent = htmlContent + "<div class=\"row\">";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "<div class=\"col-xs-3\">Select Version</div>";
            var htmlContent = htmlContent + "<div class=\"col-xs-7\"><select style = 'width:80px' id = 'planVersions'>";
            for(var i=0;i<self.packageVersionsList().length;i++){
                htmlContent += "<option name='version' value = \"" + self.packageVersionsList()[i].id + "\">"+ self.packageVersionsList()[i].name+ '</option>';
            }
            htmlContent = htmlContent + "</select></div>";
            htmlContent = htmlContent + "</div><br>";
            var box = bootbox.dialog({
                message: htmlContent,
                title: "<div><img class='smallIcon' src='img/icons/icons_16.png'>&nbsp;&nbsp;&nbsp;Select Plan version</div>",
                buttons: {
                    cancel: {
                        label: "Close",
                        className: "btn btn-default",
                        callback: function () {}
                    },
                    success: {
                        label: "Complete",
                        className: "switchPlan btn btn-complete",
                        callback: function () {
                            var docId;
                            var docVersion;
                            var selectedSwitchVersion = $('#planVersions').val();
                            for(var i=0; i<self.packageVersionsList().length; i++){
                                if(self.packageVersionsList()[i].id == selectedSwitchVersion){
                                    docId = self.packageVersionsList()[i].documentId;
                                    docVersion = self.packageVersionsList()[i].version;
                                    break;
                                }
                            }
                            if(docVersion == previousDocumentVersion){
                                bootbox.alert("You are already using that version");
                                //self.saveAdvancedDetails();
                                return;
                            }
                            window.utils.startSpinner('switchPlanVersionSpinner', "Switching Plan vesion...");
                            setTimeout(function () {
                                var url = '/vdmbee/artifact?docId='+docId+'&docVer='+docVersion+'&workspaceId='+currentWorkspaceId;
                                DataManager.getDataManager().get('vmpServerService').getAllPromise(url).then(function(res) {
                                    if(res && res.packageId){
                                        var packArtId = res.packageId;
                                        var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
                                        var legalEntityId = dataManager.get("workspaceLegal")[currentWorkspaceId];
                                        var packArtDoc = dataManager.get("artifactsDocuments")[planId];
                                        var existingPackArtId = packArtDoc ? packArtDoc.artifactId :planId ;
                                        var url = '/vdmbee/workspace/switchplanversion/'+currentWorkspaceId+'/'+packArtId+'/'+existingPackArtId+'?entityId=' + legalEntityId;
                                        dataManager.get('vmpServerService').postAllPromise(url).then(function(data) {
                                            dataManager.applyWorkspaceById(currentWorkspaceId,function(){
                                                window.utils.stopSpinner("switchPlanVersionSpinner");
                                                self.saveAdvancedDetails();
                                                jQuery('.btn-editPlanClose').click();
                                                dataManager.applyWorkspaceById(currentWorkspaceId,function(){
                                                    window.vdmModelView.plansCollection.reset();
                                                    var query = {tags:[],type: '',name : '',documentOwner : ''};
                                                    dataManager.getPlansFromBackend(query,function(artifacts){
                                                        window.vdmModelView.importAllPlans(artifacts,function(){
                                                            bootbox.alert('Package has been installed.');
                                                            window.utils.stopSpinner('switchPlanVersionSpinner');
                                                        });
                                                    });
                                                });
                                            });
                                        });
                                    } else {
                                        bootbox.alert("There are no such package");
                                    }	
                                });
                            }, 10);
                        }
                    }
                }
            });
            box.show();
        });
    }

    cleanModal() {
        var self = this;
        if (self.modalShown == 1 && self.model) {
            window.cleanDialogModel(self.encodeId, self);
        }
    };

    deleteScenario(view, event) {
        var self =this;
        if ($("#selectObs option:selected").length > 0) {
            var id = $("#selectObs option:selected").attr("value");
            if (id === self.appliedScenario()) {
                bootbox.alert('You cannot delete Applied Scenario');
            } else {
                bootbox.confirm('' + DataManager.getDataManager().get('localeManager').get('ScenarioDelete'), function (delScenario) {
                    if (delScenario) {
                        var id = $("#selectObs option:selected").attr("value");
                        for (var i = 0; i < self.timeStamps().length; i++) {
                            if (self.timeStamps()[i].id === id) {
                                self.timeStamps.destroy(self.timeStamps()[i]);
                                break;
                            }
                        }
                        if (window.utils.getObservableLength(self.timeStamps) == 1) {
                            self.scenarioSelected(false);
                        }
                    }
                });
            }
        }
    };


    moveUp() {
        var self = this;
        var id = self.vocabularySelected();
        var index = self.linkedConceptPackageList().map(function (el) { return el.id; }).indexOf(id);
        if (index) {
            var spliced = self.linkedConceptPackageList.splice(index, 1)[0];
            self.linkedConceptPackageList.splice(index - 1, 0, spliced);
        }
    }
    moveDown() {
        var self = this;
        var id = self.vocabularySelected();
        var index = self.linkedConceptPackageList().map(function (el) { return el.id; }).indexOf(id);
        if (self.linkedConceptPackageList()[index + 1]) {
            var spliced = self.linkedConceptPackageList.splice(index, 1)[0];
            self.linkedConceptPackageList.splice(index + 1, 0, spliced);
        }
    }



    cleanUp() {
        this.conceptsPackageModel.length = 0;
        this.linkedConceptPackageList.removeAll();
    }

    showVocabDetails() {
        var self = this;
        var id = this.vocabularySelected();
        var index = self.linkedConceptPackageList().map(function (el) { return el.id; }).indexOf(id);
        var vocab = window.utils.getElementModel(self.linkedConceptPackageList()[index].id,['concept.Vocabulary']);
        var vocabName = vocab.get('name');
        var vocablocale = vocab.get('locale');
        var businessContext = vocab.get('businessContext');
        var vocabLib = vocab.get('vocabularyOwner').get('name');
        var vocabPackage = vocab.get('vocabularyOwner').get('vocabularyLibraryOwner').get('name');

        var msg = "<div>\
				<div class=\"row\">\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">Name</span></span></div>\
					<div class=\"col-xs-6\"><span> "+ vocabName + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

        var localeDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">Locale</span></span></div>\
					<div class=\"col-xs-6\"><span> "+ self.LANGUAGE_BY_LOCALE[vocablocale] + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

        var bcDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">Business Context</span></span></div>\
					<div class=\"col-xs-6\"><span>"+ businessContext + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

        var vocabLibDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">Vocabulary Library</span><span></div>\
					<div class=\"col-xs-6\"><span>"+ vocabLib + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

        var VocabPackageDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">Vocabulary Library Package</span><span></div>\
					<div class=\"col-xs-6\"><span>"+ vocabPackage + "</div><br>\
					<div class=\"col-xs-1\"></div><br>\
				</div>";

        if (vocablocale !== "") {
            msg = msg.concat(localeDiv);
        }
        if (businessContext !== "") {
            msg = msg.concat(bcDiv);
        }
        msg = msg.concat(vocabLibDiv).concat(VocabPackageDiv).concat("</div>");
        bootbox.dialog({
            message: msg,
            title: "Vocabulary Details",
            buttons: {
                main: {
                    label: "Close",
                    className: "btn btn-default",
                }
            }
        });
    }

    exportImages(){
        var self = this;
        var url = encodeURI("/vdmbee/image/download?planId="+self.model.id);
        window.utils.startSpinner('exportImages', 'Exporting Images...');
        DataManager.getDataManager().get('vmpServerService').getAllPromise(url).then(function(content) {
            window.utils.stopSpinner('exportImages');
			if(content){
                debugger
                var reader = new FileReader();
                reader.onload = function (ev) {
                    var zipContent = reader.result;
                    var name = self.model.get('name') + " Images";
                    var exportMsg = DataManager.getDataManager().get('localeManager').get('exportComplete', 'Images');
                    window.utils.exportFileToSystem(name,'ZIP File',".zip",zipContent,exportMsg);
                };
                reader.readAsArrayBuffer(content);
            } else {
                bootbox.alert("Plan has no Images");
            }
        });
    }

    saveAdvancedView(planModel, callback) {
        var self = this;
        planModel.set('maxChange', self.maxchange());
        planModel.set('maxIterations', self.maxIterations());
        async.each(self.imageList(), function (img, imgCallback) {
            if (img._destroy) {
                var mapList = img.mapList;
                if (mapList) {
                    for (var i = 0; i < mapList.length; i++) {
                        var type = mapList[i].diagType.replace('_', '.');
                        type = type.replace(' ', '.');
                        var mapModel = Backbone.Relational.store.getObjectByName('' + type).find({ id: mapList[i].id });
                        if (mapModel) {
                            var xml = mapModel.get('data');
                            var newXml = xml.replace(new RegExp(img.id, 'g'), '')
                            mapModel.set('data', newXml);
                        }
                    }
                }
                CodeContainer.getBlobInstanceWithId(img.id, self.model, function (codeContainer) {
                    if (codeContainer) {
                        codeContainer.destroy();
                        DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
                        imgCallback();
                    }
                });
            } else {
                imgCallback();
            }
        }, function () {
            //saving/deleting observations
            var scenarioList = [];
            for (var i = 0; i < self.timeStamps().length; i++) {
                if (self.timeStamps()[i]._destroy) {
                    scenarioList.push(self.timeStamps()[i].whenObserved);
                }
            }
            _.each(self.timeStamps(), function (timeStamp) {
                if (timeStamp.update) {
                    var model = Backbone.Relational.store.getObjectByName('smm.Observation').find({ id: timeStamp.id });
                    var plan = self.model;
                    var newName = timeStamp.name;
                    var whenObserved = model.get('whenObserved');
                    model.set('name', newName);
                    plan.getAllDocumentsCollectionOfType(plan, "smm_Observation", function (results) {
                        async.each(results.models, function (obs, obsUpdated) {
                            var isPlanDoc = obs.id.indexOf(window.plansKey) >= 0;
                            if (isPlanDoc) {
                                var obsModel = Backbone.Relational.store.getObjectByName('smm.Observation').find({ id: obs.id });
                                if (obsModel && obsModel.getNestedParent().id !== plan.id) {
                                    obsUpdated();
                                    return;
                                }
                                if (obsModel && obsModel.id !== model.id && obsModel.get('whenObserved') === whenObserved && obsModel.get('name') !== newName) {
                                    obsModel.set('name', newName);
                                }
                                obsUpdated();
                            } else {
                                var altId = DataManager.getDataManager().getRepositoryId(obs.id);
                                var vdmStore = DataManager.getDataManager().getVDMStore(altId);
                                DataManager.getDataManager().fetchDocumentFromPackage(obs.get('parent'), "appbo/vdml/ValueDeliveryModel", obs.get('version'), obs.id, "appbo/smm/Observation", vdmStore, {
                                    success: function (obsModel) {
                                        if (obsModel.id !== model.id && obsModel.get('whenObserved') === whenObserved && obsModel.get('name') !== newName) {
                                            obsModel.set('name', newName);
                                        }
                                        obsUpdated();
                                    },
                                    error: function (error) {
                                        console.log('Unable to load Package for updating observation name');
                                        obsUpdated();
                                    }
                                });
                            }
                        }, function () {
                        });
                    }, null, null, null, "vbc:cmof_EObject-name", null, null, true, null, true);
                }
            });
            planModel.deleteScenario(scenarioList, function () {
                if (self.appliedScenario() != self.timelessObsId) {
                    function applyDefaultObservation(callback) {
                        var selectedScenarioWhenObserved = _.find(self.timeStamps(), function (obj) { return obj.id === self.appliedScenario() }).whenObserved;
                        var ObservationMixin = Backbone.Relational.store.getObjectByName('smm.ObservationMixin');
                        ObservationMixin.updateObservations([selectedScenarioWhenObserved], function () {
                            ObservationMixin.applyDefaultObservation(selectedScenarioWhenObserved, false, function () {
                                callback();
                            });
                        });
                    }
                    if (!DataManager.getDataManager().altScenarios) {
                        DataManager.getDataManager().loadAltScenarios(DataManager.getDataManager(), planModel, function () {
                            applyDefaultObservation(callback);
                        });
                    } else {
                        applyDefaultObservation(callback);
                    }
                } else {
                    callback();
                }
            });
        })

    };

    saveAdvancedDetails() {
        var self = this;
        return self.options.callback(this);
    };

    deleteImage(view, event) {
        var self = this;
        var plan = self.options.parentView.model;
        var codeId = self.selectedImage();//view.id;
        if (codeId) {
            function confirmMsg(imageFoundList) {
                var message = 'Are you sure you want to delete this Image?';
                if (imageFoundList.length > 0) {
                    debugger
                    for (var j = 0; j < self.imageList().length; j++) {
                        if (self.imageList()[j].id === codeId) {
                            self.imageList()[j].mapList = imageFoundList;
                            break;
                        }
                    }
                    message = message + "<br/>It is being used in the following Business Map(s)";
                    for (var i = 0; i < imageFoundList.length; i++) {
                        message = message + "<br/>" + imageFoundList[i].name;
                    }
                }

                bootbox.confirm(message, function (result) {
                    if (result) {
                        for (var i = 0; i < self.imageList().length; i++) {
                            if (self.imageList()[i].id === codeId) {
                                self.imageList.destroy(self.imageList()[i]);
                                break;
                            }
                        }
                        $('#showImage').hide();
                        $('#showImage').attr("src", "");
                    }
                });
            }
            if (self.mapList.length < 1) {
                self.model.getAllDocumentsCollectionOfType(self.model, 'ecomap_EcoMap,vdml_ValueDeliveryModel', function (results) {
                    results.each(function (pack) {
                        self.mapList.push(pack);
                    });
                    window.utils.searchImage(plan,codeId,self.mapList,confirmMsg);
                }, null, null, null, null, true);
            } else {
                window.utils.searchImage(plan,codeId,self.mapList,confirmMsg);
            }
        }
    };

    showImageInfo(view, event) {
        var self = this;
        var codeId = self.selectedImage();//view.id;
        if (codeId) {
            CodeContainer.getBlobInstanceWithId(codeId, self.model, function (codeContainer) {
                if (codeContainer) {
                    $('#showImage').show();
                    $('#showImage').attr("src", "" + codeContainer.get('code'));
                    $('#showImage').attr("height", "50px");
                    $('#showImage').attr("width", "50px");
                } else {
                    $('#showImage').hide();
                    $('#showImage').attr("src", "");
                }
            });
        } else {
            $('#showImage').hide();
            $('#showImage').attr("src", "");
        }
    };

    afterRenderView(node,view){
        var self = view;
        $("#maxchange").keyup(function (view) {
            var newValue = view.currentTarget.value;
            if (newValue == '') {
                self.enableComplete(false);
            } else if (newValue != '' && self.maxIterations() != '') {
                self.enableComplete(true);
            }
        });
        $("#maxIterations").keyup(function (view) {
            var newValue = view.currentTarget.value;
            if (newValue == '') {
                self.enableComplete(false);
            } else if (newValue != '' && self.maxchange() != '') {
                self.enableComplete(true);
            }
        });
        window.utils.applyFontSizeInDialog();
    };

    /* this.importMetricsCSV = function () {
         self.model.importMetricsCSV();
     };*/
    sortByName(left, right) {
        return left.name.toLowerCase() === right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
    }
    generateMetricsCSV() {
        var self = this;
        self.options.parentView.model.scenarioExportDialog([]);
    };
    updateView(view, encodeId) {
        $("#selectObs option:selected").text(view.obsName());
        window.cleanDialogModel(encodeId, view);
    };

    /*this.editScenarioDetails = function(){
          if($("#selectObs option:selected").length > 0  ){
               var id = $("#selectObs option:selected").attr('value');
               var obsModels = self.model.get('planObservationContainer').get('observations');
               var obsModel = obsModels.findWhere({'id':id});
               var alternative = obsModel.getContext().getParent();
               var addOptions = {'settings':false, alternative:alternative, 'width':'900px', parentView : self};
               window.getAndCreateModalDialog(true,htmlEscape(id),DashboardMixin,obsModel,"EditScenario",self.updateView,addOptions);
         }
     };*/
    editScenarioDetails() {
        var self = this;
        var id = $("#selectObs option:selected").attr('value');
        var observationName = $("#selectObs option:selected").text();
        var obsModels = self.model.get('planObservationContainer').get('observations');
        var obsModel = obsModels.findWhere({ 'id': id });
        var alternative = obsModel.getContext().getParent();
        var htmlContent = "<div class=\"row\">";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
        htmlContent = htmlContent + "<div class=\"col-xs-3\">";
        htmlContent = htmlContent + "<span class=\"control-label\">Name</span>";
        htmlContent = htmlContent + "</div>";
        htmlContent = htmlContent + "<div class=\"col-xs-7\">";
        htmlContent = htmlContent + "<input id=\"obsName\" altId='" + alternative.get('id') + "' class=\"form-control focus-ele\" autofocus type=\"text\" ></input>";
        htmlContent = htmlContent + "</div>";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
        htmlContent = htmlContent + "</div>";
        //for warning
        htmlContent = htmlContent + "<div class=\"row warning hide\">";
        htmlContent = htmlContent + "<div class=\"col-xs-4\"></div>";
        htmlContent = htmlContent + "<div class=\"col-xs-7\">";
        htmlContent = htmlContent + "<span style=\"font-size: 12px;color: orange;\">"+DataManager.getDataManager().get('localeManager').get('ScenarioWarningMsg')+"</span>";
        htmlContent = htmlContent + "</div>";
        htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
        htmlContent = htmlContent + "</div><br>";
        var box = bootbox.dialog({
            message: htmlContent,
            title: "<div><img class='smallIcon' src='img/icons/icons_16.png'>&nbsp;&nbsp;&nbsp;"+DataManager.getDataManager().get('localeManager').get('EditScenario')+"</div>",
            buttons: {
                cancel: {
                    label: "Close",
                    className: "btn btn-default",
                    callback: function () {

                    }
                },
                success: {
                    label: "Complete",
                    className: "editScenario btn btn-complete",
                    callback: function () {
                        //                            window.utils.startSpinner('savingAlternativeSpinner', "Saving Scenario...");
                        setTimeout(function () {
                            var obsName = $('#obsName').val().trim();
                            for (var i = 0; i < self.timeStamps().length; i++) {
                                if (self.timeStamps()[i].name == observationName) {
                                    self.timeStamps()[i].name = obsName;
                                    self.timeStamps()[i].update = true;
                                }
                            }
                            var timeStampsdummy = self.timeStamps();
                            self.timeStamps([]);
                            self.timeStamps(timeStampsdummy);
                        }, 10);
                    }
                }
            }
        });
        box.show();
        $('.editScenario').attr('disabled', 'disabled');
        $('#obsName').val(observationName);
        $('#obsName').keyup(function (event) {
            var inputVal = event.currentTarget.value.trim();
            var exists = _.find(self.timeStamps(), function (e) { return e.name === inputVal; });
            if (exists) {
                $('.editScenario').attr('disabled', 'disabled');
                $('.warning').removeClass('hide');
            } else if (inputVal != "") {
                $('.editScenario').removeAttr('disabled');
                $('.warning').addClass('hide');
            } else {
                $('.editScenario').attr('disabled', 'disabled');
            }
        });
    };
    init(model,options){
        var self = this;
        this.AdvancedPlanDetailsViewModel = this;
        this.model = model;
        this.id = kb.observable(model, 'id');
        function htmlEscape(str) {
            return String(str)
                .replace(/@/g, '')
                .replace(/ /g, '')
                .replace(/#/g, '');
        }
        //this.encodeId = htmlEscape(this.id());
        this.encodeId = 'advanced';
        this.options = options;
        if (options && options.parentView) {
            //this.parentView = options.parentView;
            this.encodeId = "" + options.modalId;
            //this.parentModal = options.parentModal;
        }
        this.vocabularySelected = ko.observable(false);
        this.appliedScenario = ko.observable('');
        this.selectedImage = ko.observable('');
        this.scenarioSelected = ko.observable(true);
        this.showSwitch = ko.observable(false);
        this.showBackUp = ko.observable(false);
        //this.enableDefaultScenario = ko.observable(false);
        this.maxchange = ko.observable(model ? model.get('maxChange') : 0.0001);
        this.maxIterations = ko.observable(model ? model.get('maxIterations') : 100);
        this.LANGUAGE_BY_LOCALE = utils.languageList();
        this.enableComplete = ko.observable(true);
        this.showMarketSubmit = ko.observable(false);        
        this.timeStamps = ko.observableArray();
        this.showTimeStamps = ko.observableArray();
        this.linkedConceptPackageList = ko.observableArray([]);
        this.linkedModulesList = ko.observableArray([]);
        this.conceptsPackageModel = [];
        this.modalShown = 0;
        this.observations = ko.observableArray([]);
        this.defaultObs = ko.observable('');
        this.imageList = ko.observableArray();
        this.publishedDate = ko.observable();
        this.mapList = [];
        this.packageVersionsList = ko.observableArray([]);
		this.selectedVersion = ko.observable();
        this.showImageInfo = _.bind(self.showImageInfo, self);
        this.deleteImage = _.bind(self.deleteImage, self);
        this.editScenarioDetails = _.bind(self.editScenarioDetails, self);
        this.deleteScenario = _.bind(self.deleteScenario, self);
        if (self.model) {
            this.canExport = ko.observable(true);
		    this.canExport(DataManager.getDataManager().canExport(self.model));
            if(!DataManager.getDataManager().get('readMode') && !window.utils.checkIsLinked(null,true) && this.canExport()){
                //also check submitted and purchased products
                var roles = DataManager.getDataManager().get('serverRoles');
                var show = false;
                if((roles && roles.indexOf('LEGAL_ENTITY_ADMIN') != -1) || window.utils.isVMPUser()){
                    show = true;
                }
                if(!DataManager.getDataManager().isEnterpriseEdition()){
                    show = false;
                }
                self.showMarketSubmit(show);
            }
            function loadConceptPackages(conceptsPackages) {
                async.eachSeries(conceptsPackages.models, function (vocabulary, packageCallback) {
                    self.model.getVocabularyBeepPackageWithId(vocabulary.id, function (vocabularyModel) {
                        self.conceptsPackageModel.push(vocabularyModel);
                        packageCallback();
                    });
                }, function (err) {
                    for (var j = 0; j < self.conceptsPackageModel.length; j++) {
                        var vocabularyLibraries = self.conceptsPackageModel[j].get('vocabularyLibrary');
                        for (var k = 0; k < vocabularyLibraries.length; k++) {
                            var vocabularies = vocabularyLibraries.at(k).get('vocabulary');
                            for (var i = 0; i < vocabularies.length; i++) {
                                var locale = vocabularies.at(i).get('locale');
                                var localeString = "";
                                if (locale) {
                                    localeString = ' (' + self.LANGUAGE_BY_LOCALE[locale] + ')';
                                }
                                var conceptName = vocabularies.at(i).get('name') + localeString + ' (' + vocabularies.at(i).getParent().getParent().get('name') + ')';
                                self.linkedConceptPackageList.push({ id: vocabularies.at(i).get('id'), name: conceptName });
                            }
                        }
                    }
                });

            }

            function loadImageList(codeContainers) {
                //self.imageList.push({ name: 'SelectImage...', id: ''});
                codeContainers.comparator = function (model) {
                    return model.get('name');
                }
                codeContainers.sort();
                codeContainers.each(function (container) {
                    var lastIndex = container.get('name').lastIndexOf('.');
                    var ext = container.get('name').substr(lastIndex + 1);
                    if (ext !== "html" && lastIndex != -1) {
                        var imageName = (container.get('name').length > 20) ? container.get('name').substr(0,20) + '...' : container.get('name');
                        var html = "<span id='tooltipText' data-toggle='tooltip' title='" + container.get('name').substr(20, container.get('name').length) + "'> ...</span>";
                        self.imageList.push({ name: imageName, id: container.get('id'), imageCompleteName : container.get('name'), html: html });
                    }
                });
                //$("#selectImg").prop("selectedIndex", 0).val();
                $("#selectImg option:first").prop("selected", "selected");
            }
            function waitAndLoadRDFModel() {
                var planId = self.model.id.substr(self.model.id.lastIndexOf('@'));
                var commonAltId = planId + "-Common@";
                var commonRdfModel = DataManager.getDataManager().getRDFModel(commonAltId);
                if (commonRdfModel) {
                    commonRdfModel.getAllDocumentsCollectionOfType('concept_BeepVocabulary', loadConceptPackages, null, null, 'vbc:cmof_EObject-name', null, true);
                    //commonRdfModel.getAllDocumentsCollectionOfType('beeppackage_CodeContainer', loadImageList, null, null, null, null, true);
                    commonRdfModel.getAllDocumentsCollectionOfType('beeppackage_BeepModule', function (refs) {
                        var modules = refs.models;
                        _.each(modules, function (module) {
                            self.linkedModulesList.push({ id: module.get('id'), name: module.get('name') });
                        });
                        self.linkedModulesList.sort(function (left, right) {
                            return left.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() == right.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ? 0 : (left.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() < right.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ? -1 : 1);
                        });
                        window.utils.stopSpinner('loadPackage');
                    }, false, null, 'vbc:cmof_EObject-name', null, true);
                } else {
                    
                   //setTimeout(waitAndLoadRDFModel, 5);
                }
            }
            //var planWorkspaceId = DataManager.getDataManager().getWorkspaceIdByPlanId(self.model.id);
            //var planWorkspaceName = DataManager.getDataManager().getWorkspaceName(planWorkspaceId);
            window.utils.startSpinner('loadPackage', 'Fetching Details...');
            window.setTimeout(function () {
                DataManager.getDataManager().set('currentPlan',self.model,{silent:true});
                var planId = self.model.id.substr(self.model.id.lastIndexOf('@'));
                var commonAltId = planId + "-Common@";
                DataManager.getDataManager().getModelIdInitializedWSData(commonAltId,function(){                    
                    model.fetchCustomPlanPackages(['concept_BeepVocabulary','beeppackage_BeepModule'/*,'beeppackage_CodeContainer'*/], function(packagesList){
                        waitAndLoadRDFModel();
                    });
                });
                
            }, 100);
            var obs = self.model.get('planObservationContainer') ? self.model.get('planObservationContainer').get('observations') : [];
            var timeStampObsArray = [];
            var allTimeStampObsArray = [];
            for (var i = 0; i < obs.length; i++) {
                var alreadyExists = _.filter(timeStampObsArray, function (obj) { return obj.whenObserved === '' + obs.at(i).get('whenObserved') });
                if (!alreadyExists[0]) {
                    //if (isNaN(obs.at(i).get('whenObserved'))) {
                        timeStampObsArray.push({ id: '' + obs.at(i).get('id'), whenObserved: '' + obs.at(i).get('whenObserved'), name: '' + obs.at(i).get('name') });
                    //}
                }
                var checkExists = _.filter(allTimeStampObsArray, function (obj) { return obj.whenObserved === '' + obs.at(i).get('whenObserved') });
                if (!checkExists[0]) {
                    allTimeStampObsArray.push({ id: '' + obs.at(i).get('id'), whenObserved: '' + obs.at(i).get('whenObserved'), name: '' + obs.at(i).get('name') });
                }
            }
            for (var i = 0; i < obs.length; i++) {
                if (!obs.at(i).get('whenObserved')) {
                    self.defaultObs(obs.at(i).get('id'));
                    self.appliedScenario(obs.at(i).get('id'));
                    self.timelessObsId = obs.at(i).get('id');
                    break;
                }
            }
            self.timeStamps(allTimeStampObsArray);
            self.showTimeStamps(timeStampObsArray)
        }

        this.appliedScenario.subscribe(function (val) {
            if (val && self.timelessObsId != val) {
                bootbox.confirm(DataManager.getDataManager().get('localeManager').get('ApplyScenario'), function (result) {
                    if (!result) {
                        self.appliedScenario(self.timelessObsId);
                    }
                });
            }
        });
        this.selectedImage.subscribe(function (val) {
            if (val) {
                self.showImageInfo();
            }
        });            


        $('#modal' + self.encodeId).on('shown.bs.modal', function () {
            $("#selectImg option:first").attr("selected", "selected");
            self.selectedImage($("#selectImg option:first").val());
            window.utils.focusElement("#newModal .focus-ele");
            self.modalShown++;
        });

        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), ['name'
            , 'description'
            , 'Close'
            , 'Maxchange'
            , 'Maxiterations'
            , 'Advanced'
            , 'Complete'
            , 'addAnother'
            , 'Delete'
            , 'ExportCSV'
            ,  'Publish'
        ]);
    }


   static getInstance(model, options){
        var view = new AdvancedPlanDetailsViewModel(model, options);
        view.init(model, options);
        return view;
   };
}
path.AdvancedPlanDetailsViewModel = AdvancedPlanDetailsViewModel;