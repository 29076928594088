import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Measure} from './Measure'
import {GradeMeasureMixin} from './GradeMeasureMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { GradeMeasureRelationship } from './GradeMeasureRelationship'
import { GradeInterval } from './GradeInterval'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/Measure","appbo/smm/GradeMeasureMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Measure,GradeMeasureMixin
, GradeMeasureRelationship
, GradeInterval){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!GradeMeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.GradeMeasureRelationship){
			GradeMeasureRelationship = importPath.GradeMeasureRelationship;
		}
        else {
            import('./GradeMeasureRelationship').then(({ default: GradeMeasureRelationship }) => {
                GradeMeasureRelationship = GradeMeasureRelationship;
            });
			/*require(["appbo/smm/GradeMeasureRelationship"],function(loadedModule){
				GradeMeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!GradeInterval){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.GradeInterval){
			GradeInterval = importPath.GradeInterval;
		}
        else {
            import('./GradeInterval').then(({ default: GradeInterval }) => {
                GradeInterval = GradeInterval;
            });
			/*require(["appbo/smm/GradeInterval"],function(loadedModule){
				GradeInterval = loadedModule;
			});*/
		}
	}
	var GradeMeasure = Measure.extend(utils.customExtends({
		relations:GradeMeasureMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Measure.prototype.initialize.apply(this, arguments);
			if(attributes.scale){
				this.set('scale',path.ScaleOfMeasurement[attributes.scale instanceof Object ? attributes.scale.name : attributes.scale]);
			}
		}
		}
		, new GradeMeasureMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	GradeMeasure.abstract = false;
	GradeMeasure.supported = !sp2Enabled;
	path.GradeMeasure = GradeMeasure;
	export {GradeMeasure};
//});