import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
//define(["jquery","underscore", "backbone","backbone-relational","rdf_store","rdf_interface_api"],

//function(jQuery,_, Backbone) {
    var RDFSchemaModel = function(storeName){
    	this.name = storeName;
    	RDFSchemaModel.schemaModels[storeName] = this;
    };
    RDFSchemaModel.schemaModels = new Array();
    RDFSchemaModel.prototype.initialize = function(callback,resetRDF){
    	var schemaModel = this;
    	new rdfstore.Store({persistent:false, name:this.name, overwrite:resetRDF ? resetRDF : false}, function(store){
    		schemaModel.initializeWithStore(store,callback);
    	});
    };
    RDFSchemaModel.prototype.initializeWithStore = function(store, callback){
    	var schemaModel = this;
		schemaModel.store = store;
		schemaModel.rdf = store.rdf;
		schemaModel.store.rdf.setPrefix("vbc","//s/#");
		schemaModel.store.rdf.setPrefix("vbi","//d/#");
		schemaModel.graphUri = "http://vdmbee.com/graph/schema/" + schemaModel.name;
		schemaModel.store.graph(schemaModel.graphUri,function(success, graph){
    		if(callback){
    			callback(schemaModel);
    		}
    		graph.removeMatches();
		});
    };
    RDFSchemaModel.prototype.clear= function(callback){
    	var schemaModel = this;
    	new rdfstore.Store({persistent:true, name:this.name, overwrite:true}, function(store){
	    	store.clear(schemaModel.graphUri,function(success){
	    		if(callback){
	    			callback();
	    		}
	    	});
    	});
    };
	function printStackSize(method){
		console.log('method:' + method)
		var i=0;
		function testStack(){
			i++
			testStack();
		}		
		try{
			testStack();
		}catch(e){
			console.log('stak size:' + i)
		}
	}     
    RDFSchemaModel.prototype.getRDFSchemaClass = function(model,callback){
    	var type = model.get('type');
    	var schemaModel = this;
    	if(type){
    		this.store.node(this.rdf.resolve("vbc:" + type),this.graphUri, function(success,graph){
				if(success === true){
					if(graph.length > 0){
						callback(graph.triples[0].subject);
					}else{
						if(schemaModel.isSystemClass(model)){
							schemaModel.createRDFSchemaForSystemClass(model,callback);
						}
						else{
							schemaModel.createRDFSchemaForApplicationClass(model,callback);
						}
					}
				}
				graph.removeMatches();
    		});
    	}
    };
    
    RDFSchemaModel.prototype.isSystemClass = function(model){
    	var type = model.get('type');
    	if(type.indexOf("com_vbee_data") >=0 || type.indexOf("com_vbee_filesystem") >=0 || type.indexOf("com_vbee_utils") >=0 || type.indexOf("com_vbee_rdf") >=0){
    		return true;
    	}
    	return false;
    }
    RDFSchemaModel.prototype.createRDFSchemaForApplicationClass = function(model,callback){
      //printStackSize('schema stack:' + model.get('type'));
    	var schemaModel = this;
    	var type = model.get('type');
    	var modelName = type.substr(type.lastIndexOf('_') + 1);
    	var modelPackage = type.substr(0,type.lastIndexOf('_'));
		var graph = this.store.rdf.createGraph();
    	var typeMixinCls = schemaModel.dataManager.getModelType(model,true);
    	
    	var clsNode = this.store.rdf.createNamedNode(this.rdf.resolve("vbc:" + type));
    	
		graph.add(this.rdf.createTriple( clsNode,
                                this.store.rdf.createNamedNode(this.rdf.resolve("rdf:type")),
                                this.store.rdf.createNamedNode(this.rdf.resolve("rdfs:Class"))));    	
    	
		graph.add(this.rdf.createTriple( clsNode,
                                this.rdf.createNamedNode(this.rdf.resolve("vbc:name")),
                                this.rdf.createLiteral(modelName) ));    	
		graph.add(this.rdf.createTriple( clsNode,
                                this.rdf.createNamedNode(this.rdf.resolve("vbc:packageName")),
                                this.rdf.createLiteral(modelPackage) )); 
		this.addAttributesSchemaForAppCls(graph,clsNode,model,typeMixinCls);                           
		this.addRelationsShipsSchemaForAppCls(graph,clsNode,model,typeMixinCls);
		this.addSuperClassesSchema(graph,clsNode,model,typeMixinCls);

		this.store.insert(graph,this.graphUri, function(success, results){
			graph.removeMatches();
			if(callback){
				callback(clsNode);
			}							
		});
		return clsNode;
    };
	RDFSchemaModel.prototype.addSuperClassesSchema = function(graph,clsNode,model,typeMixinCls){
		var superTypes = typeMixinCls.getSuperTypes();
		for(var i=0;i<superTypes.length;i++){
			graph.add(this.rdf.createTriple(clsNode,
                        this.rdf.createNamedNode(this.rdf.resolve("rdfs:subClassOf")),
                        this.rdf.createNamedNode(this.rdf.resolve("vbc:" + superTypes[i])))); 			
		}
	};
	RDFSchemaModel.prototype.addRelationsShipsSchemaForAppCls = function(graph,clsNode,model,typeMixinCls){
    	var relations = typeMixinCls.getCumulativeMixinRelations();
    	for(var i=0;i<relations.length;i++){
    		var relation = relations[i];

    		var relationNode = this.rdf.createNamedNode(this.rdf.resolve("vbc:" + relation.containingClass + "-" + relation.key));
			graph.add(this.rdf.createTriple( clsNode,
                        this.rdf.createNamedNode(this.rdf.resolve("rdf:Property")),
                         relationNode));
			graph.add(this.rdf.createTriple( relationNode,
                        this.store.rdf.createNamedNode(this.rdf.resolve("rdf:type")),
                        this.store.rdf.createNamedNode(this.rdf.resolve("rdf:Property") ))); 
			graph.add(this.rdf.createTriple( relationNode,
                        this.rdf.createNamedNode(this.rdf.resolve("rdfs:range")),
                        this.rdf.createNamedNode(this.rdf.resolve("vbc:" + relation.relatedModel.replace(".","_")) ))); 
    	}
    	relations.length = 0;
	};
	
    RDFSchemaModel.prototype.addAttributesSchemaForAppCls = function(graph,clsNode,model,typeMixinCls){
    	var attributes = typeMixinCls.getProperties();
		for(var i=0;i<attributes.length;i++){
			var attributeNode = this.rdf.createNamedNode(this.rdf.resolve("vbc:" + attributes[i].containingClass + "-" + attributes[i].name)) ;
			graph.add(this.rdf.createTriple( clsNode,
                        this.rdf.createNamedNode(this.rdf.resolve("rdf:Property")),
                        attributeNode)); 
			graph.add(this.rdf.createTriple( attributeNode,
	                        this.rdf.createNamedNode(this.rdf.resolve("rdfs:DataType")),
	                        this.rdf.createNamedNode(this.getRDFType(attributes[i].type)))); 
			                        
		}
		attributes.length=0;
    };

	RDFSchemaModel.prototype.getRDFType = function(type){ //TODO remainign datatypes
		if(type === "EString"){
			return this.rdf.resolve("xsd:string");
		}else
		if(type === "EBoolean"){
			return this.rdf.resolve("xsd:boolean");
		}
		return this.rdf.resolve("xsd:string");
	}
    RDFSchemaModel.prototype.createRDFSchemaForSystemClass = function(model,callback){
    	var schemaModel = this;
    	var graph = this.store.rdf.createGraph();
    	var type = model.get('type');
    	var modelName = type.substr(type.lastIndexOf('_') + 1);
    	var modelPackage = type.substr(0,type.lastIndexOf('_'));
    	var clsNode = this.store.rdf.createNamedNode(this.rdf.resolve("vbc:" + type));
    	
		graph.add(this.rdf.createTriple( clsNode,
                                this.store.rdf.createNamedNode(this.rdf.resolve("rdf:type")),
                                this.store.rdf.createNamedNode(this.rdf.resolve("rdfs:Class"))));    	
    	
		graph.add(this.rdf.createTriple( clsNode,
                                this.rdf.createNamedNode(this.rdf.resolve("vbc:name")),
                                this.rdf.createLiteral(modelName) ));    	
		graph.add(this.rdf.createTriple( clsNode,
                                this.rdf.createNamedNode(this.rdf.resolve("vbc:packageName")),
                                this.rdf.createLiteral(modelPackage) ));    	
		this.addAttributesSchema(graph,clsNode,model);                           
		this.addRelationsShipsSchema(graph,clsNode,model);
		this.addSubClassesSchema(graph,clsNode,model);
		this.store.insert(graph,this.graphUri, function(success, results){
			graph.removeMatches();
			if(callback){
				callback(clsNode);
			}							
		});
		return clsNode;
    };
    RDFSchemaModel.prototype.addAttributesSchema = function(graph,clsNode,model){
    	var attributes = model.attributes;
		graph.add(this.rdf.createTriple( clsNode,
                    this.rdf.createNamedNode("rdf:Property"),
                    this.rdf.createNamedNode(this.rdf.resolve("vbc:name") ))); 
		graph.add(this.rdf.createTriple( clsNode,
                    this.rdf.createNamedNode(this.rdf.resolve("rdf:Property")),
                    this.rdf.createNamedNode(this.rdf.resolve("vbc:description") ))); 
		for(var x in attributes){
			if(typeof attributes[x] === "object"){
				continue;
			}
			if(x === "name" || x === "description"){
				continue;
			}
			graph.add(this.rdf.createTriple( clsNode,
                        this.rdf.createNamedNode(this.rdf.resolve("rdf:Property")),
                        this.rdf.createNamedNode(this.rdf.resolve("vbc:" + model.get('type') + "-" + x)) )); 
		}
    };
    RDFSchemaModel.prototype.addRelationsShipsSchema = function(graph,clsNode,model){
    	var relations = model.getRelations();
    	for(var i=0;i<relations.length;i++){
    		var relation = relations[i];
    		var relationNode = this.rdf.createNamedNode(this.rdf.resolve("vbc:" + model.get('type') + "-" + relation.key));
			graph.add(this.rdf.createTriple( clsNode,
                        this.rdf.createNamedNode(this.rdf.resolve("rdf:Property")),
                         relationNode));
			graph.add(this.rdf.createTriple( relationNode,
                        this.store.rdf.createNamedNode(this.rdf.resolve("rdf:type")),
                        this.store.rdf.createNamedNode(this.rdf.resolve("rdf:Property") ))); 
			if(typeof relation.options.relatedModel === "function"){
				continue;
			}                        
			graph.add(this.rdf.createTriple( relationNode,
                        this.rdf.createNamedNode(this.rdf.resolve("rdfs:range")),
                        this.rdf.createNamedNode(this.rdf.resolve("vbc:" + relation.options.relatedModel.replace(".","_")) ))); 
    	}
    };    
    
    RDFSchemaModel.prototype.addSubClassesSchema = function(graph,clsNode,model){
    	var subClasses = model.subModelTypes;
    	if(subClasses){
	    	for(var x in subClasses){
				graph.add(this.rdf.createTriple( this.rdf.createNamedNode(this.rdf.resolve("vbc:" + subClasses[x])),
	                        this.rdf.createNamedNode(this.rdf.resolve("rdfs:subClassOf")),
	                        clsNode)); 
	    	}
    	}
    }    
    
    RDFSchemaModel.getInstance = function(storeName,callback,store){
    	if(RDFSchemaModel.schemaModels[storeName]){
    		callback(RDFSchemaModel.schemaModels[storeName]);
    		return;
    	}
    	var schemaModel = new RDFSchemaModel(storeName);
    	if(!store){
    		schemaModel.initialize(callback,true);
    	}else{
    		schemaModel.initializeWithStore(store,callback);
    	}
    };
    RDFSchemaModel.prototype.reloadGraph = function(callback){
    	this.store.graph(this.graphUri,function(succes, graph){
    		if(callback){
    			callback();
    		}
    		graph.removeMatches();
		});
	}
    RDFSchemaModel.prototype.getNT = function(callback){
    	this.store.graph(this.graphUri,function(succes, graph){
    		if(callback){
    			callback(graph.toNT());
    		}
    		graph.removeMatches();
    	});
    };	
    RDFSchemaModel.prototype.print = function(){
    	this.store.graph(this.graphUri,function(succes, graph){
    		console.log("graph length:" + graph.length);
    		console.log("schema Graph");
    		for(var i=0;i<graph.triples.length;i++){
    			var triple= graph.triples[i];
    			console.log(triple.subject.nominalValue + " " + triple.predicate.nominalValue + " " + triple.object.nominalValue);
    		}
		});
	}
    //return RDFSchemaModel;
	export {RDFSchemaModel};
//});