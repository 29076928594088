import * as $ from 'jquery'
import * as _ from 'underscore'
import * as Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
//import * as d3 from '../../../../../../libs/d3/d3.min'
import * as FileSaver from '../../../../../../libs/FileSaver/FileSaver'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { CodeContainer } from '../../../../bo/beeppackage/CodeContainer'
import { ValueDeliveryModelMixin } from '../../../../bo/vdml/ValueDeliveryModelMixin'
import { EcoMapDiagramMixin } from '../../../../bo/vdml/EcoMapDiagramMixin'
import { MappingWizardViewModel } from './MappingWizardViewModel'

/*define(["require", "jquery", "underscore", "backbone", "async", "knockout", "bootbox", "FileSaver", "jstree", "appcommon/com/vbee/data/DataManager", "app/global", "knockback", "d3", "appbo/beeppackage/CodeContainer", "appviews/strategyMap/views/designer/MappingWizardViewModel", "appbo/vdml/ValueDeliveryModelMixin", "appbo/vdml/EcoMapDiagramMixin", "jquery.DataTable", "dataTable.PageResize", "dataTable.ColReorderWithResize","tick-positioner"],
	function (require, $, _, Backbone, async, ko, bootbox, FileSaver, jstree, DataManager, global, kb, d3, CodeContainer, MappingWizardViewModel, ValueDeliveryModelMixin, EcoMapDiagramMixin) {*/
var path = DataManager.getDataManager().buildAppNsPath("strategyMap.views.designer", global.version);
export class StrategyMapTargetViewModel {
    constructor(model, options) {
        var self = this;
        this.init(model, options);
    }
    init(model, options) {
        var self = this;
        this.StrategyMapTargetViewModel = this;
        
    }

    static getInstance(model, options) {
        var view = new StrategyMapTargetViewModel(model, options);
        return view;
    };
}
path.StrategyMapTargetViewModel = StrategyMapTargetViewModel;