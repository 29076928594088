import * as _ from 'underscore'

import {ValueDefinitionMixin} from './ValueDefinitionMixin'
import {ValueDefinition2Mixin} from './ValueDefinition2Mixin'
import {Value} from './Value'
import {ValueCategory} from './ValueCategory'
import {Characteristic} from '../smm/Characteristic'
import {DataManager} from '../../../com/vbee/data/DataManager'


	
var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
if(!ValueCategory){
	var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(importPath.ValueCategory){
		ValueCategory = importPath.ValueCategory;
	}
	else{
		import('./ValueCategory').then(loadedModule => {
			ValueCategory = loadedModule;
		});
		/*require(["appbo/vdml/ValueCategory"],function(loadedModule){
			ValueCategory = loadedModule;
		});*/
	}
}
if(!Characteristic){
	var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(importPath.Characteristic){
		Characteristic = importPath.Characteristic;
	}
	else{
		import('../smm/Characteristic').then(loadedModule => {
			Characteristic = loadedModule;
		});
		/*require(["appbo/smm/Characteristic"],function(loadedModule){
			Characteristic = loadedModule;
		});*/
	}
}
var getMixinClass = function (){
	var toggleRouter = DataManager.getDataManager().get("toggleRouter");
	var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
	return sp2Enabled == true ? ValueDefinition2Mixin : ValueDefinitionMixin;
}
var valueDefinitionMixinClass = getMixinClass();
var ValueDefinition = Value.extend(utils.customExtends({
		relations:valueDefinitionMixinClass.getMixinRelations(),
		initialize: function(attributes, options) {
			Value.prototype.initialize.apply(this, arguments);
		}
	}
	, new valueDefinitionMixinClass()
));
ValueDefinition.getInstance = function (name, description, owner) {
	var cid = DataManager.getDataManager().guidGeneratorByOwner(owner);
	var valueDef = new ValueDefinition({ id: cid, name: owner.getUniqueProperty('name', name, 'value'), description: owner.getUniqueProperty('description', description, 'value'), version: owner.get('version'), valueOwner: owner });
	owner.get('value').add(valueDef);
	return valueDef;
};
ValueDefinition.abstract = false;
ValueDefinition.supported = true;
path.ValueDefinition = ValueDefinition;
export {ValueDefinition};
