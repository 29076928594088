import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmRelationship} from './SmmRelationship'
import {SmmRelationshipMixin} from './SmmRelationshipMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmRelationship","appbo/smm/SmmRelationshipMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmRelationship,SmmRelationshipMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	export class MeasurementRelationshipMixin {

	defaults(){
		var ret = {
			type: "smm_MeasurementRelationship"
		}
		return jQuery.extend(SmmRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
            key: "definition",
            containingClass: "smm_MeasurementRelationship",
			relatedModel:"smm.MeasureRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute
		}
		]);
	};
	static getCumulativeMixinRelations(){
		if (!MeasurementRelationshipMixin.cummulativeRelations) {
            MeasurementRelationshipMixin.cummulativeRelations = _.union(MeasurementRelationshipMixin.getMixinRelations()
                , SmmRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return MeasurementRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_SmmRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("measurementRelationshipsOwner") ? this.get("measurementRelationshipsOwner") : this.previousAttributes().measurementRelationshipsOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("measurementRelationshipsOwner") ? this.get("measurementRelationshipsOwner") : this.previousAttributes().measurementRelationshipsOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/MeasurementRelationshipPropertiesTemplate.html",
			templateName : "MeasurementRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/MeasurementRelationshipViewModel",
			tabId : "MeasurementRelationshipView",
			tabName: "MeasurementRelationship"
		}
	}
	
	}
	path.MeasurementRelationshipMixin = MeasurementRelationshipMixin;
	//return MeasurementRelationshipMixin;
//});