import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ConceptElementMixin } from '../concept/ConceptElementMixin'

/*define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin","appbo/concept/ConceptElement","appbo/concept/ConceptElementMixin","appbo/concept/Vocabulary"],
function(require,jQuery,_,async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin,ConceptElement,ConceptElementMixin,Vocabulary
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("concept",global.version);
	export class VocabularyLibraryMixin {
	defaults(){
		var ret = {
			type: "concept_VocabularyLibrary"
		};
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
            type :Backbone.HasMany,
            containingClass:"concept_VocabularyLibrary",
            key:"vocabulary",
            relatedModel:"concept.Vocabulary",
            reverseRelation: {
                key:"vocabularyOwner",
                type :Backbone.HasOne,
                includeInJSON:"id"
            }
        }
		]);
	};
	static getCumulativeMixinRelations(){
		if (!VocabularyLibraryMixin.cummulativeRelations) {
            VocabularyLibraryMixin.cummulativeRelations = _.union(VocabularyLibraryMixin.getMixinRelations()
                , ConceptElementMixin.getCumulativeMixinRelations()
            );
        }
		return VocabularyLibraryMixin.cummulativeRelations.slice();
	};
	static getSuperTypes(){
		return [
			"concept_ConceptElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("vocabularyLibraryOwner") ? this.get("vocabularyLibraryOwner") : this.previousAttributes().vocabularyLibraryOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("vocabularyLibraryOwner") ? this.get("vocabularyLibraryOwner") : this.previousAttributes().vocabularyLibraryOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')});
				return container.getPackagePath(path);
			}
		}
		return path;
	};
	getViewProperties(type){
		return {
			templatePath : "views/concept/views/properties/ConceptTemplate.html",
			templateName : "ConceptTemplate",
			viewTypeStr : "appviews/concept/views/properties/ConceptViewModel",
			tabId : "ConceptView",
			tabName: "Concept"
		}
	};

	getDialogViewProperties(type){
		/*if(type === 'OsterwalderDesigner'){
			return {
				templatePath : "views/concept/views/designer/OsterwalderDesignerTemplate.html",
				templateName : "OsterwalderDesignerTemplate",
				viewTypeStr : "appviews/concept/views/designer/OsterwalderDesignerViewModel",
				tabId : "OsterwalderDesignerView",
				tabName: "OsterwalderDesignerView"
			};
		}*/
	};
	//return VocabularyLibraryMixin;
}
path.VocabularyLibraryMixin = VocabularyLibraryMixin;