import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from '../vdml/VdmlElement'
import {InstantiationContextMixin} from './InstantiationContextMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("instdef",global.version);

var InstantiationContext = VdmlElement.extend(utils.customExtends({
        relations:InstantiationContextMixin.getMixinRelations(),
        initialize: function(attributes, options) {
            VdmlElement.prototype.initialize.apply(this, arguments);
            
        }
    }
    , new InstantiationContextMixin()
));
InstantiationContext.abstract = true;
path.InstantiationContext = InstantiationContext;
export {InstantiationContext};
