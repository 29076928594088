import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasureRelationship} from './MeasureRelationship'
import {MeasureRelationshipMixin} from './MeasureRelationshipMixin'
import {Measure} from './Measure'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasureRelationship","appbo/smm/MeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasureRelationship,MeasureRelationshipMixin
, Measure
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Measure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measure){
			Measure = importPath.Measure;
		}
		else{
			import('./Measure').then(({ default: Measure }) => {
				Measure = Measure;
			});
			/*require(["appbo/smm/Measure"],function(loadedModule){
				Measure = loadedModule;
			});*/
		}
	}
	
	export class RefinementMeasureRelationshipMixin {

	defaults(){
		var ret = {
			type: "smm_RefinementMeasureRelationship"
		}
		return jQuery.extend(MeasureRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!RefinementMeasureRelationshipMixin.cummulativeRelations) {
            RefinementMeasureRelationshipMixin.cummulativeRelations = _.union(RefinementMeasureRelationshipMixin.getMixinRelations()
                , MeasureRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return RefinementMeasureRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_MeasureRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "influence",type : "Influence",defaultValue : "null",containingClass : "smm_MeasureRelationship" }
		]
	}
	getParent(){
		var container;
		return MeasureRelationship.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return MeasureRelationship.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/RefinementMeasureRelationshipPropertiesTemplate.html",
			templateName : "RefinementMeasureRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/RefinementMeasureRelationshipViewModel",
			tabId : "RefinementMeasureRelationshipView",
			tabName: "RefinementMeasureRelationship"
		}
	}
	}
	path.RefinementMeasureRelationshipMixin = RefinementMeasureRelationshipMixin;
	
	//return RefinementMeasureRelationshipMixin;
//});