import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasure} from './DimensionalMeasure'
import {RankingMeasureMixin} from './RankingMeasureMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { RankingMeasureRelationship } from './RankingMeasureRelationship'
import { RankingInterval } from './RankingInterval'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasure","appbo/smm/RankingMeasureMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasure,RankingMeasureMixin
, RankingMeasureRelationship
, RankingInterval){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!RankingMeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RankingMeasureRelationship){
			RankingMeasureRelationship = importPath.RankingMeasureRelationship;
		}
        else {
            import('./RankingMeasureRelationship').then(({ default: RankingMeasureRelationship }) => {
                RankingMeasureRelationship = RankingMeasureRelationship;
            });
            /*require(["appbo/smm/RankingMeasureRelationship"], function (loadedModule) {
                RankingMeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!RankingInterval){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RankingInterval){
			RankingInterval = importPath.RankingInterval;
		}
        else {
            import('./RankingInterval').then(({ default: RankingInterval }) => {
                RankingInterval = RankingInterval;
            });
            /*require(["appbo/smm/RankingInterval"], function (loadedModule) {
                RankingInterval = loadedModule;
			});*/
		}
	}
	var RankingMeasure = DimensionalMeasure.extend(utils.customExtends({
		relations:RankingMeasureMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			DimensionalMeasure.prototype.initialize.apply(this, arguments);
			if(attributes.scale){
				this.set('scale',path.ScaleOfMeasurement[attributes.scale instanceof Object ? attributes.scale.name : attributes.scale]);
			}
		}
		}
		, new RankingMeasureMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	RankingMeasure.abstract = false;
	RankingMeasure.supported = !sp2Enabled;
	path.RankingMeasure = RankingMeasure;
	export {RankingMeasure};
//});