import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { MeasurableElement } from './MeasurableElement'
import { ParticipantMixin } from './ParticipantMixin'
import { VDMLCanvasElementMixin } from './VDMLCanvasElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Assignment } from './Assignment'
import { CalendarService } from './CalendarService'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/ParticipantMixin","appbo/vdml/VDMLCanvasElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,ParticipantMixin,VDMLCanvasElementMixin
, Assignment
, CalendarService){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Assignment){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Assignment){
			Assignment = importPath.Assignment;
		}
        else {
            import('./Assignment').then(({ default: Assignment }) => {
                Assignment = Assignment;
            });
			/*require(["appbo/vdml/Assignment"],function(loadedModule){
				Assignment = loadedModule;
			});*/
		}
	}
	if(!CalendarService){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CalendarService){
			CalendarService = importPath.CalendarService;
		}
        else {
            import('./CalendarService').then(({ default: CalendarService }) => {
                CalendarService = CalendarService;
            });
			/*
            require(["appbo/vdml/CalendarService"],function(loadedModule){
				CalendarService = loadedModule;
			});*/
		}
	}
	var Participant = MeasurableElement.extend(utils.customExtends({
		relations:ParticipantMixin.getMixinRelations(),
		subModelTypes: {
			vdml_Actor : 'vdml.Actor',
			vdml_Collaboration : 'vdml.Collaboration',
			vdml_Role : 'vdml.Role'
		},
		initialize: function(attributes, options) {
			MeasurableElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new VDMLCanvasElementMixin()
		, new ParticipantMixin()
	));
	Participant.abstract = true;
	path.Participant = Participant;
    export {Participant};
//});