import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import { BeepVocabulary } from '../../../../../version1/bo/concept/BeepVocabulary'
import { VocabularyDetailsViewModel } from './VocabularyDetailsViewModel'
import { BMGuidanceViewModel } from '../../../help/views/properties/BMGuidanceViewModel'
import * as enhsplitter from '../../../../../../libs/enhsplitter/jquery.enhsplitter'

/*define(["require", "jquery", "enhsplitter", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "app/global", "typeahead", "appbo/concept/BeepVocabulary",
	"appviews/help/views/properties/BMGuidanceViewModel","appviews/concept/views/properties/VocabularyDetailsViewModel"],
function(require,$,enhsplitter,_, Backbone,ko, koMapping,kb,bootbox,async,DataManager,global,typeahead,BeepVocabulary,
	BMGuidanceViewModel,VocabularyDetailsViewsModel)
{*/
	var path = DataManager.getDataManager().buildAppNsPath("concept.views.properties",global.version);
	export class ConceptViewModel{
		
		htmlEscape(str) {
    		return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
		}
		//vocabulary details		
    	
        cleanUp() {
            var self = this;
    		if(ko.components.isRegistered('VocabularyGuidance')){
    			ko.components.unregister('VocabularyGuidance');
			}
    		if(ko.components.isRegistered('VocabularyDetails')){
    			ko.components.unregister('VocabularyDetails');
    		}
			window.utils.loadTemplate(null,true);
    		$(window,".resize").unbind('resize');
    		self.spliter.enhsplitter('remove');
    	};
    			
        sortConceptsByName(left, right) {
            var self = this;
            return left.get('id').toLowerCase() == right.get('id').toLowerCase() ? 0 : (left.get('id').toLowerCase() < right.get('id').toLowerCase() ? -1 : 1);
        }
		
        saveVocabulary(view, event, callback) {
            var self = this;
			if(self.editVocabularyId) {
				var selectedVocabularyModel = self.vocabularies.findWhere({'id':self.editVocabularyId});
				if(selectedVocabularyModel) {
		            selectedVocabularyModel.set('name',self.name());
		            if(self.selectedVocabularyLocale() != '') {
		            	selectedVocabularyModel.set('locale',self.selectedVocabularyLocale());
		            }
		           // if($('#businessContextName').val() != '') {
		            	selectedVocabularyModel.set('businessContext',""+$('#businessContextName').val());
		          //  }
		            //self.saveConcept(self.editVocabularyId);
		            for(var i=0;i<self.vocabularies.length;i++){
		            	if(self.vocabularies.at(i).get('id') === self.editVocabularyId){
		            		var obj = {id:self.vocabularies.at(i).get('id'),name:selectedVocabularyModel.get('name'),locale:self.LANGUAGE_BY_LOCALE[selectedVocabularyModel.get('locale')],businessContext:selectedVocabularyModel.get('businessContext')};
		            		self.vocabularyList.replace(self.vocabularyList()[i],obj);
							break
						}
					}
	            }
			}else {
				if(self.name() != '') {
					BeepVocabulary.createVocabulary(self.name().trim(),self.name().trim(),self.model,self.selectedVocabularyLocale(),$('#businessContextName').val(),function(vocabulary){
						self.vocabularyList.push({id:vocabulary.get('id'),name:vocabulary.get('name'),locale:self.LANGUAGE_BY_LOCALE[vocabulary.get('locale')],businessContext:vocabulary.get('businessContext')});
						//self.saveConcept(concept.get('id'));
						if(!callback){
							self.setResizeHeight();	
						}

					});
				}
			}
			
			/*if(!self.localeTypeaheadColl.findWhere({'name':$('#localeName').val()})){
				self.localeTypeaheadColl.push({name:''+$('#localeName').val()});
			}*/
			if(!self.businessContextTypeaheadColl.findWhere({'name':$('#businessContextName').val()})){
				self.businessContextTypeaheadColl.push({name:''+$('#businessContextName').val()});
			}
			self.clearInputFields();
			if(callback){
				callback();
			}
		};
		
        cancelVocabulary() {
            var self = this;
			self.clearInputFields();
		};
		
		disableAllCompleteButtons() {
            var self = this;
            self.canClickCompleteVocabulary(false);
            self.canClickCompleteConcept(false);
        };
		
        clearInputFields() {
            var self = this;
			self.editVocabularyId = null;
			self.name('');
			//$('#localeName').val('');
			self.selectedVocabularyLocale('');
			$('#businessContextName').val('');
			self.conceptValue('');
			$('#valueName').val('');
			self.jsonSelected = null;
			self.selectedConcept(null);
			self.canClickCompleteVocabulary(false);
			self.canClickCompleteConcept(false);
			self.enableVocabularyCancel(false);
			self.enableVocabularyDelete(false);
			self.enableConceptDelete(false);
			self.enableDisplayAs(false);
			if(self.selectedVocabRow) {
				$(self.selectedVocabRow).removeClass('highlightrow');
			}
			self.selectedVocabRow = null;
		}
		
        editVocabularyHandler(view, event) {
            var self = this;
			self.editVocabularyId = view.id;
			self.name(''+view.name);
			//$('#localeName').val(''+view.locale);
			if(view.locale != '') {
				for (var key in self.LANGUAGE_BY_LOCALE) {
				  if (self.LANGUAGE_BY_LOCALE[key] == view.locale){
				  	self.selectedVocabularyLocale(key);
				  	break;
				  }
				}
			}else {
				self.selectedVocabularyLocale('');
			}
			$('#businessContextName').val(''+view.businessContext);
			self.enableVocabularyDelete(true);
			self.canClickCompleteVocabulary(true);
			self.enableVocabularyCancel(true);
			//self.selectedConcept(view.id);
			self.setResizeHeight();
			self.selectedVocabRow = event.currentTarget;
			if(event && event.currentTarget) {
				$(event.currentTarget).addClass('highlightrow').siblings().removeClass('highlightrow');
			}
		};
		
        deleteVocabulary(view, event) {
            var self = this;
			function doDeleteVocabulary(){
				var deletedIndex;
				for(var i=0;i<self.vocabularyList().length;i++){
					if(self.vocabularyList()[i].id === self.editVocabularyId){
						deletedIndex=i;
						//var deletedLocale=self.vocabularyList()[i].locale;
						var deletedBusinessContext=self.vocabularyList()[i].businessContext;
						self.vocabularyList.remove(self.vocabularyList()[i]);
						break;
					}
				}
				self.vocabularyList().splice(deletedIndex,1);
				var vocabularyModel = Backbone.Relational.store.getObjectByName('concept.Vocabulary').find({id:self.editVocabularyId});
				if(vocabularyModel){
					vocabularyModel.destroy();
					//self.vocabularies.remove(vocabularyModel);
				}						
				/*if(!self.model.get('vocabulary').findWhere({'locale':deletedLocale})){
					self.localeTypeaheadColl.remove(self.localeTypeaheadColl.where({'name':deletedLocale}));
				}*/
				if(!self.model.get('vocabulary').findWhere({'deletedBusinessContext':deletedBusinessContext})){
					self.businessContextTypeaheadColl.remove(self.businessContextTypeaheadColl.where({'name':deletedBusinessContext}));
				}
				self.setResizeHeight();
				self.clearInputFields();
				DataManager.getDataManager().get('currentPlan').applyConcepts();
			}
			if(self.editVocabularyId) {
                if (Backbone.Relational.store.getObjectByName('concept.Vocabulary').find({ id: self.editVocabularyId }) != null &&
                    Backbone.Relational.store.getObjectByName('concept.Vocabulary').find({ id: self.editVocabularyId }).get('data') === "{}") {
					doDeleteVocabulary();
				}
				else{
					bootbox.confirm(DataManager.getDataManager().get('localeManager').get('DeleteVocabulary'), function(result) {
					if(result){
						doDeleteVocabulary();
					}
					});
				}
			}
		};
		
		
        loadSelectedConcept(view, event) {
            var self = this;
			self.conceptValue(''+view.description);
			$('#valueName').val(''+view.name);
			self.jsonSelected = view.name;
			self.canClickCompleteConcept(true);
			self.enableConceptCancel(true);
			self.enableConceptDelete(true);
			self.setResizeHeight();
			self.enableDisplayAs(true);
			if(event && event.currentTarget) {
				$(event.currentTarget).addClass('highlightrow').siblings().removeClass('highlightrow');
			}
		};
		
		cancelConcept(view,event){
			var self = this;
			self.clearInputFields();
		};
		
        deleteConcept(view, event) {
            var self = this;
			var selectedConceptModel = self.vocabularies.findWhere({'id':self.selectedConcept()});
			if(selectedConceptModel) {
				var tempConcepts = JSON.parse(selectedConceptModel.get('data'));
				delete tempConcepts[''+self.jsonSelected];	  
				selectedConceptModel.set('data',JSON.stringify(tempConcepts));
			}
			self.setResizeHeight();
			self.canClickCompleteConcept(false);
			self.enableConceptCancel(false);
			self.enableConceptDelete(false);
			self.enableDisplayAs(false);
			self.clearInputFields();
			DataManager.getDataManager().get('currentPlan').applyConcepts();
		};
		
        saveConcept(view, event, callback) {
            var self = this;
			var selectedConceptModel = self.vocabularies.findWhere({'id':self.selectedConcept()});
			if(selectedConceptModel) {
				var tempConcepts = JSON.parse(selectedConceptModel.get('data'));
			    tempConcepts[''+self.jsonSelected] = {value:self.conceptValue(),c:1}; 
				selectedConceptModel.set('data',JSON.stringify(tempConcepts));
				DataManager.getDataManager().get('currentPlan').applyConcepts();
			}
			if(callback){
				callback();
			}else{
				self.canClickCompleteConcept(false);
				self.enableConceptCancel(false);
				self.enableConceptDelete(false);
				self.enableDisplayAs(false);
				self.clearInputFields();
				self.setResizeHeight();	
			}
		};
		
        hasUnsavedData() {
            var self = this;
        	if(self.canClickCompleteVocabulary() || self.canClickCompleteConcept()) {
        		return true;	
        	}else {
        		return false;
        	}	
        };
		
		saveDataBeforeClosing(callback) {
			var self = this;
			if(self.canClickCompleteVocabulary()){
				self.saveVocabulary(null,null,callback);
			}
			else if(self.canClickCompleteConcept()){
				self.saveConcept(null,null,callback);
			}
		};
		
        setResizeHeight() {
            var self = this;
			setTimeout(function(){
				var bmNode =  $('#conceptContainer');
	        	var propertiesNode = 0;
				var rightTab = $('#rightTab');
	        	if($('#rightTab').width() > 0){
					propertiesNode = $('.propertiesArea').outerHeight();
				}
				//var baseHeight = _.max([bmNode.outerHeight(),propertiesNode.outerHeight()])+20;
				var topAreaHeight = $('.navbar').outerHeight();
                var baseHeight = _.max([bmNode.outerHeight(), propertiesNode, window.innerHeight - topAreaHeight]) - 3;
                if (self.spliter) {
                    self.spliter.css('height', baseHeight + 'px');
                    if ($('#rightTab').width() < 5 && Math.abs($(window).width() - bmNode.width()) < 50) {
                        var windowWidth = $(window).width() - 15;
                        $('.splitter_bar').css('left', windowWidth);
                    }
                    //if($('#rightTab').width() > 5){
                    $("#splitPanels").enhsplitter('refresh');
                    //}
                }
				if(rightTab.width() > 0){
					if($('#rightTab'+self.encodeId+' .active a')[0]){
						var propDivId = $('#rightTab'+self.encodeId+' .active a')[0].hash;
						if(propDivId !== null && $('#rightTab .dropdown .active a')[0]){
			            	propDivId = $('#rightTab .dropdown .active a')[0].hash;
			            }
						var propNode = $(''+propDivId);
					}
				}
				if(rightTab.width() > 10 && rightTab.children().last()[0].classList.contains('dropdown') /*&& rightTab.height() < 50*/){
					var dropdownDiv = rightTab.children().last();
					var appendList = dropdownDiv.find('li');
					var removedList = [];
					for(var i=0;i<appendList.length;i++){
							dropdownDiv.before(appendList[i]);
							removedList.push(appendList[i]);
					}
					if(appendList.length === removedList.length){
						dropdownDiv.remove();
					}
				}
				if(rightTab.width() > 10 && rightTab.width() < 370 && rightTab.children().length > 2/* && rightTab.height() > 50*/){
					var rightChildren = rightTab.children();
					var appendList = [];
					for(var i=rightChildren.length;i>0;i--){
						if(!rightChildren[i-1].classList.contains('dropdown')){
							appendList.push(rightChildren[i-1]);
							if(rightTab.width() < 320 && rightChildren[i-2]){
								appendList.push(rightChildren[i-2]);
								break;
							}else {
								break;
							}
						}
					}
					if(!rightChildren.last()[0].classList.contains('dropdown')){
						rightTab.append('<li class="dropdown"><a class="dropdown-toggle" data-toggle="dropdown" href="#"><span class="caret"></span></a><ul style="right:0" class="dropdown-menu pull-right"></ul></li>');
					}
					for(var i=0;i<appendList.length;i++){
						rightTab.children().last().find('ul').prepend(appendList[i]);
					}
				}
				if(rightTab.children().last()[0] && rightTab.children().last()[0].classList.contains('dropdown') && propNode && propNode.parent()[0] && propNode.parent()[0].classList.contains('tab-content')){
					var dropdownDiv = rightTab.children().last();
					var appendList = dropdownDiv.find('li');
					for(var i=0;i<appendList.length;i++){
						appendList.eq(i).removeClass('active');
					}	
				}
			 },150);
	    };
		
        afterRenderView(node,view) {
            var self = view;
			var bmNode =  $('#conceptContainer');
        	var propertiesNode = $('.propertiesArea');
			var baseHeight = _.max([bmNode.outerHeight(),propertiesNode.outerHeight()])+2;
       		//var baseWidth = _.max([bmNode.outerWidth(),propertiesNode.outerWidth()]) + propertiesNode.outerWidth();
			//$('#splitPanels').css({width: baseWidth, height: baseHeight}).split({orientation:'vertical', limit:100,position:'56.7%'});
			self.spliter = $("#splitPanels").enhsplitter({minSize:0,onDrag:window.utils.splitterBoundary,collapse:'right',position: '64%',handle:'block',splitterSize:'9px',height:baseHeight, onDragEnd:self.setResizeHeight});
			/*var localeName = Backbone.Typeahead.extend({
			  template: '<input type="text" style="width:83%" id="localeName" placeholder="Locale" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
			});
			var localeNametypeahead = new localeName({collection:self.localeTypeaheadColl});
		    localeNametypeahead.setElement('#localeDiv').render();
		    localeNametypeahead.on('selected', function(fetchmodel) {
		    	
		    });*/
		    
		    var businessContextName = Backbone.Typeahead.extend({
			  template: '<input type="text" style="width:83%" id="businessContextName" placeholder="Business Context" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
			});
			var businessContexttypeahead = new businessContextName({collection:self.businessContextTypeaheadColl});
		    businessContexttypeahead.setElement('#businessContextDiv').render();
		    businessContexttypeahead.on('selected', function(fetchmodel) {
		    	
		    });	
		    
        	var valueNameExtended = Backbone.Typeahead.extend({
              template: '<input type="text" style="width:83%" id="valueName" placeholder="Concept" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            var valueNameTypeahead = new valueNameExtended({collection:self.localeNames});
            valueNameTypeahead.setElement('#conceptNameDiv').render();
            valueNameTypeahead.on('selected', function(fetchmodel) {
            	self.enableDisplayAs(true);
            	self.jsonSelected = fetchmodel.get('name');
            	self.conceptValue(''+DataManager.getDataManager().get('localeManager').get(''+fetchmodel.get('name')));
            	self.canClickCompleteConcept(true);
				self.enableConceptCancel(true);
           });
           
           $('#rightTab a').click(function (e) {
	            e.preventDefault();
	            if($(this).text() === "Collaboration"){
	     			return false; 
	            }
	            $(this).tab('show');
	            if($(this).text()==="Guidance"){
         			if($('#guidanceTab').is(':empty')){
		    			$.get('js/app/version1/views/help/views/properties/VocabularyGuidanceTemplate.html',function(viewStr){
						    $('#guidanceTab').empty();
							$('#guidanceTab').append(viewStr);
							window.utils.applyFontSize($('#guidanceTab'));
							self.setResizeHeight();
						})
					}else {
		    			self.setResizeHeight();
		    		}
                }else {
                	self.setResizeHeight();
                }            
				       
	       });
	       $('#myTab a').click(function (e) {
                e.preventDefault();
                if($(this).text() === "Concepts"){
                	$("#nextTab").hide();
                }
                else{
                	$("#nextTab").show();
                }
                $(this).tab('show');
                self.setResizeHeight();	
            });
            self.nextTab = function(){
                var nextTab = $('#myTab > .active').next('li').find('a');
                nextTab.trigger('click');
            }; 
            $("#vocabularyName").keyup(function (view) {
                var newValue = view.currentTarget.value;
                if(newValue.trim() !== '') {
                	self.canClickCompleteVocabulary(true);
					self.enableVocabularyCancel(true);
                }else {
                	self.canClickCompleteVocabulary(false);
                }
            });
            /*$("#valueName").keyup(function (view) {debugger;
                var newValue = view.currentTarget.value;
                if(newValue === '') {
                	self.enableDisplayAs(false);
                	$("#conceptTextValue").val('');
                }             
            });*/
            
			$(window).resize(function(){
				self.setResizeHeight();	
			});
			setTimeout(function(){
				window.utils.applyFontSize($('#content'));
				self.setResizeHeight();
			 },50);
			 
			function loadVocabularyLocales(){
				self.vocabularyLocaleList.push({id:'',name:'Select a Locale ...'});
				for (var key in self.LANGUAGE_BY_LOCALE) {
			  		self.vocabularyLocaleList.push({id:''+key,name:''+self.LANGUAGE_BY_LOCALE[key]});
				}
			}
			loadVocabularyLocales();
        }
		
init(model,options){
    var self = this;
    this.ConceptViewModel = this;
    this.model = model;
    this.id = kb.observable(model, 'id');
    this.libName = kb.observable(model, 'name');
    this.vocabularyList = ko.observableArray([]);
    this.localeNames = new Backbone.Collection();
    /*this.localeTypeaheadColl = new Backbone.Collection();*/
    this.selectedVocabularyLocale = ko.observable();
    this.vocabularyLocaleList = ko.observableArray([]);
    this.businessContextTypeaheadColl = new Backbone.Collection();
    this.name = ko.observable();
    this.conceptValue = ko.observable();
    this.jsonSelected = null;
    this.selectedConcept = null;
    this.vocabularyCollection = ko.observableArray([]);
    this.selectedConcept = ko.observable();
    this.enableVocabularyDelete = ko.observable(false);
    this.enableVocabularyCancel = ko.observable(false);
    this.canClickCompleteVocabulary = ko.observable(false);
    this.enableConceptDelete = ko.observable(false);
    this.enableConceptCancel = ko.observable(false);
    this.canClickCompleteConcept = ko.observable(false);
    this.enableDisplayAs = ko.observable(false);
    this.editVocabularyId = null;
    this.selectedConcept.subscribe(function (value) {
        self.vocabularyCollection([]);
        if (value) {
            var selectedConceptModel = self.vocabularies.findWhere({ 'id': value });
            if (selectedConceptModel.get('data')) {
                var tempConcepts = JSON.parse(selectedConceptModel.get('data'));
                for (var key in tempConcepts) {
                    self.vocabularyCollection.push({ name: '' + key, description: '' + tempConcepts[key].value });
                }
            }
        }
    });
    window.utils.loadTemplate(function (name) {
    });
    if (!ko.components.isRegistered('VocabularyDetails')) {
        ko.components.register("VocabularyDetails", {
            viewModel: VocabularyDetailsViewModel,
            template: {
                fromUrl: "js/app/version1/views/concept/views/properties/VocabularyDetailsTemplate.html"
            },
            synchronous: true
        });
    }

    self.guidanceViewInstance = BMGuidanceViewModel.getInstance(this.model);
    if (!ko.components.isRegistered('VocabularyGuidance')) {
        ko.components.register("VocabularyGuidance", {
            viewModel: { instance: self.guidanceViewInstance },
            template: '<div id="guidanceTab"></div>',
            synchronous: true
        });
    }
    this.LANGUAGE_BY_LOCALE = utils.languageList();
    this.vocabularies = self.model.get('vocabulary');
    for (var i = 0; i < self.vocabularies.length; i++) {
        self.vocabularyList.push({ id: self.vocabularies.at(i).get('id'), name: self.vocabularies.at(i).get('name'), locale: self.LANGUAGE_BY_LOCALE[self.vocabularies.at(i).get('locale')], businessContext: self.vocabularies.at(i).get('businessContext') });
        /*if(!self.localeTypeaheadColl.findWhere({'name':self.vocabularies.at(i).get('locale')})){
            self.localeTypeaheadColl.push({name:''+self.vocabularies.at(i).get('locale')});
        }*/
        if (!self.businessContextTypeaheadColl.findWhere({ 'name': self.vocabularies.at(i).get('businessContext') })) {
            self.businessContextTypeaheadColl.push({ name: '' + self.vocabularies.at(i).get('businessContext') });
        }
    }
    var localManager = DataManager.getDataManager().get('localeManager');
    var localejson = localManager.getTranslationsByLocale(localManager.current_locale());
    for (var key in localejson) {
        if (localejson[key].c) {
            self.localeNames.push({ id: '' + key, name: '' + key });
        }
    }
    this.saveVocabulary = _.bind(this.saveVocabulary, self);
    this.editVocabulary = _.bind(this.editVocabularyHandler, self);
    this.loadSelectedConcept = _.bind(this.loadSelectedConcept, self);
    this.deleteVocabulary = _.bind(this.deleteVocabulary, self);
    collection: self.localeNames.models.sort(self.sortConceptsByName);
    this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
        'name'
        , 'description'
        , 'Close'
        , 'Complete'
        , 'Cancel'
        , 'next'
        , 'Collaboration'
        , 'createVocabulary'
        , 'Guidance'
        , 'Delete'
        , 'enterVocabularies'
        , 'VocabularyDetails'
        , 'Vocabularies'
        , 'VocabularyLibraryDetails'
        , 'library'
        , 'locale'
        , 'businessContext'
        , 'displayAs'
        , 'Details'
        , 'concepts'
        , 'Select'
        , 'concept'
		, 'DisplayText'
	]);
}
	
	static getInstance(model, options){
    var view = new ConceptViewModel(model, options);
    view.init(model, options);
    return view;
}
}
path.ConceptViewModel = ConceptViewModel;