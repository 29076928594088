import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class BMElementMixin {
	
	defaults(){
		var ret = {
			type: "vdml_BMElement"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	
	static getCumulativeMixinRelations(){
		if (!BMElementMixin.cummulativeRelations) {
            BMElementMixin.cummulativeRelations = _.union(BMElementMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return BMElementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		return VdmlElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return VdmlElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BMElementPropertiesTemplate.html",
			templateName : "BMElementPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BMElementViewModel",
			tabId : "BMElementView",
			tabName: "BMElement"
		}
	}
	
	}
	path.BMElementMixin = BMElementMixin;
	//return BMElementMixin;
//});