import React, { useEffect, useState } from 'react'

const Presentation = ({ node }) => {

  const [id,setId] = useState("")

  useEffect(()=>{
    setId(node.id)
  },[node])

  return <span id={`view${window.utils.htmlEscape(id)}`} style={{"height":"100%"}}></span>;
};

export default Presentation