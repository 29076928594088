import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {ConceptElementMixin} from './ConceptElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/concept/ConceptElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,ConceptElementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("concept",global.version);
	var ConceptElement = EObject.extend(utils.customExtends({
		relations:ConceptElementMixin.getMixinRelations(),
		subModelTypes: {
			concept_BeepVocabulary : 'concept.BeepVocabulary',
			concept_Vocabulary : 'concept.Vocabulary',
			concept_VocabularyLibrary : 'concept.VocabularyLibrary'
		},
		initialize: function(attributes, options) {
			var currentPlan = DataManager.getDataManager() ? DataManager.getDataManager().get("currentPlan") : null;
			if(currentPlan){
				this.set("planId",currentPlan.id);
			}
			if(!attributes.documentId){
				this.set("documentId",attributes.id);
				attributes.documentId = attributes.id;
			}
			if(attributes.documentVersion == null){
				if(currentPlan){
					this.set("documentVersion",currentPlan.get('documentVersion'));
					attributes.documentVersion = currentPlan.get('documentVersion');
				} else {
					this.set("documentVersion","0");
					attributes.documentVersion = "0";
				}
			}
			if(DataManager && DataManager.getDataManager() && !DataManager.getDataManager().get("artifactsDocuments")[this.get("documentId")] ){
				DataManager.getDataManager().get("artifactsDocuments")[this.get("documentId")] = {"artifactId":this.get("id") ,"documentId":this.get("documentId"),"documentVersion":this.get("documentVersion"),"workspaceId":DataManager.getDataManager().get("currentWorkspaceId")};
			}
			EObject.prototype.initialize.apply(this, arguments);
			if(!this.lawnchair){
				var repId = DataManager.getDataManager().getRepositoryId(this.id);
				this.lawnchair = DataManager.getDataManager().getVDMStore(repId);
			}
			
		}
		}
		, new ConceptElementMixin()
	));
	ConceptElement.abstract = false;
	path.ConceptElement = ConceptElement;
	//return ConceptElement;
	export {ConceptElement};
//});