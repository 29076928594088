import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import { AnalysisContextMixin } from "./AnalysisContextMixin";
import {DataManager} from '../../../com/vbee/data/DataManager'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin';
import { VdmlElement } from './VdmlElement';
import {BeepPackageMixin} from '../beeppackage/BeepPackageMixin'
import { ScenarioProxy } from '../transformation/ScenarioProxy';

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

export class AnalysisContext2Mixin {
    static getMixinRelations(){
        var ret = AnalysisContextMixin.getMixinRelations().filter(item =>{
            return item.key !== "contextObservation";
        });
        return ret;
    }
    
    static getCumulativeMixinRelations(){
        if (!AnalysisContext2Mixin.cummulativeRelations) {
            AnalysisContext2Mixin.cummulativeRelations = _.union(AnalysisContext2Mixin.getMixinRelations()
                ,DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
        return AnalysisContext2Mixin.cummulativeRelations.slice();
    }
    //measuredCharacteristic == valueElement
    getObservedMeasureWithMeasurements(measuredCharacteristic,withTimeStamp,timeStamp){
		var observationToCheck;
		if(!measuredCharacteristic){
			return null;
		}
        var objType = Backbone.Relational.store.getObjectByName("transformation.ScenarioProxy");
        var scenarioProxy = objType._collection.findWhere({scenario:this.id});
        var alt = scenarioProxy.get("designScenarioOwner");
        var currentPlan = DataManager.getDataManager().get("currentPlan");
        //currentPlan.getPlanScenarioFromBackend(timeStamp ? timeStamp : currentPlan.get("defaultScenario"));
        //assume the timestamp scenario is already cached
        var planScenarios = DataManager.getDataManager().get("planScenarios");
        var planScenario = planScenarios.get(timeStamp ? timeStamp : currentPlan.get("defaultScenario"));
        var step = planScenario.get("step").findWhere({alternativeId: alt.id});
        var startPeriod = step.startPeriod;
        var dataset = planScenario.getDefaultExecutionScenarioInput(alt,startPeriod);
        if(dataset){
            //return dataset.get("data")[measuredCharacteristic.id];
            return dataset.get(measuredCharacteristic.id);
        }else{
            return null;
        }
	}
    copyData(targetAC,doImport,timestamp,importPackage,measurementsCopied,incrementalUpdate){
		var self = this;
	
		if(!doImport){
			var contextAssignments = this.get('contextBasedAssignment');
			var currentAssignments = targetAC.get('contextBasedAssignment').models.concat();
			contextAssignments.each(function(assignment){
				var found = false;
				_.each(currentAssignments,function(currentAssignment){
					if(currentAssignment.get('name').indexOf(assignment.get('name')) >=0 
						&& (currentAssignment.get('role') === assignment.get('role')) 
						&& (currentAssignment.get('participant') === assignment.get('participant'))){
						found = true;
						return;
					}
				});
				if(!found){
					assignment.copy(this,targetAC,assignment.get('assignedRole'),assignment.get('assignedRole'));	
				}
				
			});
			var deleteAssignments = [];
			_.each(currentAssignments,function(currentAssignment){
				var found = false;
				contextAssignments.each(function(assignment){
					if(currentAssignment.get('name').indexOf(assignment.get('name')) >=0 
						&& (currentAssignment.get('role') === assignment.get('role')) 
						&& (currentAssignment.get('participant') === assignment.get('participant'))){
						found = true;
						return;
					}
				});
				if(!found){
					deleteAssignments.push(currentAssignment);
				}
			});
			_.each(deleteAssignments,function(delAsignment){
				delAsignment.destroy();
			});

			var contextStores = this.get('contextStore');	//already doing?
			var targetContextStores = targetAC.get('contextStore');
			targetContextStores.add(contextStores.models);
			var delStores = _.difference(targetContextStores.models,contextStores.models);
			targetContextStores.remove(delStores);	
		}
		
		var partOfCommon = this.get('isCommon');
 		if(!partOfCommon){
 			var parentSc = this.get('parentContext');
 			while(parentSc){
 				parentSc = parentSc.get('parentContext');
 				if(parentSc){
	 				partOfCommon = parentSc.get('isCommon');
	 				if(partOfCommon){
	 					break;
	 				}
 				}
 			}
 		}
        var contextColl = self.get('contextCollaboration');
        function handleContextCollaboration(collaboration){
            if(!doImport){
                if(targetAC.get('type') === 'vdml_Scenario'){
                    targetAC.get('contextCollaboration').add(collaboration);
                }else{
                    targetAC.set('contextCollaboration',collaboration);
                }
            }
            var activities = collaboration.get('activity');
            activities.each(function(activity){
                var activityDelegations = activity.get('delegationContext');
                activityDelegations.each(function(delegation){
                    var changeActivityScenario;
                    var activityContexts = activity.get('delegationContext');
                    for(var i=0;i<activityContexts.length;i++){
                        var scenarioContexts = targetAC.get('delegationContext');
                        for(var j=0;j<scenarioContexts.length;j++){
                            if(scenarioContexts.at(j) === activityContexts.at(i)){
                                changeActivityScenario = activityContexts.at(i);
                                break;
                            }
                            if(changeActivityScenario){
                                break;
                            }
                        }
                    }
                    if(!changeActivityScenario || (changeActivityScenario && !changeActivityScenario.get('localChange'))){
                        activity.copyDelegationContext(delegation,null,null,null,targetAC,measurementsCopied);	
                    }
                });
            });
        }
        
        if(contextColl instanceof Backbone.Collection){
            var currentContextColls = targetAC.get('contextCollaboration').models.concat();
            contextColl.each(function(coll){
                handleContextCollaboration(coll);
            });
            var deletedColl = _.difference(targetAC.get('contextCollaboration').models,currentContextColls);
            targetAC.get('contextCollaboration').remove(deletedColl);
        }else{
            handleContextCollaboration(contextColl);
        }				
	};

}
utils.customExtendClass (AnalysisContext2Mixin,new AnalysisContextMixin());

path.AnalysisContext2Mixin = AnalysisContext2Mixin;
