import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { MeasurableElement } from './MeasurableElement'
import { MeasurableElementMixin } from './MeasurableElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Port } from './Port'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/MeasurableElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,MeasurableElementMixin
, Port){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Port){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Port){
			Port = importPath.Port;
		}
        else {
            import('./Port').then(({ default: Port }) => {
                Port = Port;
            });
			/*require(["appbo/vdml/Port"],function(loadedModule){
				Port = loadedModule;
			});
            */
		}
	}
export class PortContainerMixin {

    defaults(){
        var ret = {
            type: "vdml_PortContainer"
        }
        return jQuery.extend(MeasurableElement.prototype.defaults.apply(this), ret);
    }
    static getMixinRelations(){
        return _.union([
            {
                type: Backbone.HasMany,
                containingClass: "vdml_PortContainer",
                key: "containedPort",
                relatedModel: "vdml.Port",
                reverseRelation: {
                    key: "containedPortOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            }
        ])
    }
    static getCumulativeMixinRelations() {
        if (!PortContainerMixin.cummulativeRelations) {
            PortContainerMixin.cummulativeRelations = _.union(PortContainerMixin.getMixinRelations()
                , MeasurableElementMixin.getCumulativeMixinRelations()
            );
        }
        return PortContainerMixin.cummulativeRelations.slice();
    }
    static getSuperTypes() {
        return [
            "vdml_MeasurableElement"
        ];
    }
    static getProperties() {
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "represents", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" }
        ]
    }
    getParent() {
        var container;
        return MeasurableElement.prototype.getParent.apply(this, arguments);
    }
    getPackagePath(path) {
        if (!path) {
            path = [];
        }
        return MeasurableElement.prototype.getPackagePath.apply(this, arguments);
    }
    getViewProperties(type) {
        return {
            templatePath: "views/vdml/views/properties/PortContainerPropertiesTemplate.html",
            templateName: "PortContainerPropertiesTemplate",
            viewTypeStr: "appviews/vdml/views/properties/PortContainerViewModel",
            tabId: "PortContainerView",
            tabName: "PortContainer"
        }
    }
    
}
path.PortContainerMixin = PortContainerMixin;
	//return PortContainerMixin;
//});