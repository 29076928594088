import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as async from 'async'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import { ValueType } from '../../../../bo/vdml/ValueType'
import {EcoMapMixin} from '../../../../bo/ecomap/EcoMapMixin'
import * as EcoNavigatedViewer from '../../../../../../libs/bower-vdml-js/dist/vdml-navigated-viewer.min'
// import * as colResize from '../../../../../../libs/DataTable/dataTables.colResize'

	var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties", global.version);
	export class Values2ViewModel {
        constructor(model, options) {
            var self = this;
            this.init(model, options);
        }
		dispose(){
			var self = this;
			window.cleanViewModel(self);
		}
		/*getValueModalFromPreviousPhases(valId,altModel){
			var preAlt = altModel.getPreviousPhasePrimary();
			if(preAlt){
				valId = preAlt.id + window.utils.getSuffix(valId);
				var valueModal = window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
				if(!valueModal){
					valueModal = self.getValueModalFromPreviousPhases(valId,preAlt);
				} else {
					return valueModal;
				}
			} else {
				return valueModal;
			}
		}*/

		callToJulia(url,json,callback){
			window.utils.startSpinner('refreshValues', 'Refreshing ...');
			DataManager.getDataManager().get('vmpServerService').postAllPromise(url, JSON.stringify(json)).then(function(res) {
			//save res to period dataset
			callback(res);			
			}).catch(error => {
				bootbox.alert("failed "+ error);
				console.error('An error occurred:', error);
				window.utils.stopSpinner('refreshValues');
			});
		}

		extrapolateBaseline(valId,selectedHorizon,qty){
			var self = this;
			var url = "/vdmbee/intrapolation/baseline";
			var params = {};
			var valueModal = window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
			const dataManager = DataManager.getDataManager();
			const currentPlan = dataManager.get('currentPlan');
			const scenarioId = currentPlan.get("defaultScenario");
			const scenarioData = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
			var totalPlanPeriods = currentPlan.getNoOfPeriodDatasets();
			var params = valueModal.getProjectionData(qty,totalPlanPeriods);
			var periodDatasets = scenarioData.getPlanDatasets(currentPlan);
			self.callToJulia(url,params,function(res){
				if(res.forecast.length>0){
					for(let i = 0;i<periodDatasets.length;i++){
						var periodDataset = self.defaultExecutionScenaro.get("input").findWhere({period: periodDatasets[i].period,year: periodDatasets[i].year,periodKind : currentPlan.get("periodKind")});
						if(periodDataset){
							periodDataset.set(valId,res.forecast[i]);
						}						
					}
				}
				self.destroyTable('#offlineTable');
				self.renderDynamicRows();
				self.createOfflineTable("offlineTable");
				$('#offlineTable').DataTable().draw('page')
				window.utils.stopSpinner('refreshValues');
			});
				
		}

		extrapolateTrend(valId,selectedHorizon){
			var self = this
			var url = "/vdmbee/intrapolation/sparsedata";
			var params = {};
			const hashMap = {1: 1.1, 2: 2.2, 3: 3.3};
			const jsonString = JSON.stringify(hashMap);
			params.known_quantities = jsonString;
			params.planning_horizon = 2;
			params.pattern = "abc";
			params.seasonal_factors = [1.25,2.25];
			self.callToJulia(url,params);
		}

		interpolate(valId,selectedHorizon){
			var self = this
			var url = "/vdmbee/intrapolation/timeSeries";
			var params = {};
			params.season_length = 2;
			params.periods = 2;
			params.growth_rate = 1.00;
			params.time_series = [1.25,2.25];
			self.callToJulia(url,params);
		}

		interpolateextrapolate(valId,selectedHorizon){
			var self = this
			var url = "/vdmbee/intrapolation/twopoint";
			var params = {};
			params.start_quantity = 1.00;
			params.end_quantity = 1.00;
			params.planning_horizon = 2;
			params.pattern = "abc";
			params.seasonal_factors = [1.25,2.25];
			self.callToJulia(url,params);
		}
					
			buildOrderObject(dt, key, column) {
				var self = this;
				//var rowData = dt.row("#" + key).data();
				var rowData = dt.row("#" + key).data();
				if (typeof rowData === 'undefined') {
					return {};
				} else {
					var object = self.buildOrderObject(dt, rowData['parent'], column);
					var a = object;
					while (typeof a.child !== 'undefined') {
						a = a.child;
					}
					a.child = {};
					a.child.key = rowData['key'];
					a.child.value = rowData[column];
					return object;
				}
			};

			getQuantity(comp,inputPeriodDataset,resultPeriodDataset){
				var self = this;
				var qty = undefined;
				if (!comp){
					return qty
				}
				if(self.enableDirectEditing){
					if(inputPeriodDataset && comp.get("valueType") == ValueType.Atomic){
						qty = inputPeriodDataset.get(comp.get('id'));
					} else if(resultPeriodDataset && comp.get("valueType") == ValueType.Aggregated) {
						qty = resultPeriodDataset.get(comp.get('id'));
					}
				}		
				if(comp.get('unit') && comp.get('unit').get('significantDecimals')){
					var roundingDigit = comp.get('unit').get('significantDecimals')
					if(qty){
						qty = parseFloat(parseFloat(qty).toFixed(roundingDigit));
					}
				}
				return qty
			}

			subComponentValues(subComp,keyIndex,valObj,altColumnList,inputPeriodDataset, resultPeriodDataset, dynamicRows,phaseAltName,name,comp){
				var self = this;
				var calc = false;
				var subCompVal
				var childValObj 

				var compSuffix = comp.get('id').substr(comp.get('id').lastIndexOf('@') + 1);

				for (var z = 0; z < dynamicRows.length; z++) {
					if (dynamicRows[z]["suffix"] == name+compSuffix) {
						childValObj = dynamicRows[z];
					}
					if (childValObj != null) {
						break;
					}
				}

				subCompVal = self.getQuantity(subComp,inputPeriodDataset,resultPeriodDataset)

				var subCompName

				if(name ==='Recipient Opinion'){
					if(subComp && subComp.get('unit')){
						subCompName =  name + " " + "("+subComp.get('unit').get('name')+")"
					}else {
						if(comp.get('unit')){
							subCompName = name + " " + "("+comp.get('unit').get('name')+")"
						}
						
					}
				}

				if(name ==='Weight'){
					subCompName = name + " " + "(%)"
					if(subComp && subComp.get('unit')){
						subCompName = name + " " + "("+subComp.get('unit').get('name')+")"
					}
				}

				if(name === 'Satisfaction'){
					calc = true;
					subCompName = name + ""
					if(subComp && subComp.get('isNominal')===false && subComp.get('unit')){
						subCompName = name + " " + "("+subComp.get('unit').get('name')+")"
					}
				}

				/*if (subComp && subComp.get('valueType') != ValueType.Atomic) {
					calc = true;
				}*/

				if(childValObj==undefined){
					childValObj = { "DT_RowId": keyIndex, "level": 5, "key": keyIndex, "parent": valObj.key, "name":subCompName, "type": 'Value', "suffix": name+compSuffix, "calc": calc, "subComponentVal":true };
					for (var j = 0; j < altColumnList.length; j++) {
						var altName = altColumnList[j];
						if(!childValObj[altName]){
							childValObj[altName] = "-";
						}
					}
					if(subCompVal !== undefined && subCompVal){
						// if(name === 'Satisfaction'){
						// 	var expressionConfig = contextModel.get('formula') ? contextModel.get('formula').get('expressionConfig') : '';
						// 	var intervals = JSON.parse(expressionConfig);
						// 	const resultInterval = intervals.filter(item => item.level === subCompVal)[0];
						// 	if(resultInterval){
						// 		childValObj[phaseAltName] = resultInterval.levelName
						// 	}
						// }else {
						// 	childValObj[phaseAltName] = subCompVal
						// }	
						childValObj[phaseAltName] = subCompVal					
					}else {
						childValObj[phaseAltName] = "-"
					}	
					dynamicRows.push(childValObj);
				}else if (phaseAltName) {
					if(subCompVal!==undefined && subCompVal){
						// if(name === 'Satisfaction'){
						// 	var contextModel = subCompVal.getValueContext(yearPeriod.alternativeId);
						// 	if(contextModel){
						// 		var expressionConfig = contextModel.get('formula') ? contextModel.get('formula').get('expressionConfig') : '';
						// 		var intervals = JSON.parse(expressionConfig);
						// 		const resultInterval = intervals.filter(item => item.level === subCompVal)[0];
						// 		if(resultInterval){
						// 			childValObj[phaseAltName] = resultInterval.levelName
						// 		}
						// 	}
						// }else {
						// 	childValObj[phaseAltName] = subCompVal
						// }
						childValObj[phaseAltName] = subCompVal
					}else {
						childValObj[phaseAltName] = "-"
					}
				}		
				keyIndex = keyIndex + 1;
				return keyIndex
			}

			checkValues(planComponents, valueLevel, dynamicRows, keyIndex, phaseAltName, inputPeriodDataset,resultPeriodDataset, altColumnList, actValueList) {
				var self = this;
				console.log(actValueList);
				_.each(planComponents,function (comp) {
					if(actValueList && !actValueList.includes(comp)){
						return;
					}
					var valObj;
					var compSuffix = comp.id.substr(comp.id.lastIndexOf('@') + 1);
					for (var z = 0; z < dynamicRows.length; z++) {
						if (dynamicRows[z]["suffix"] == compSuffix) {
							valObj = dynamicRows[z];
						}
						if (valObj != null) {
							break;
						}
					}
					var mstValueUnit = "-";
					var calc = false;
					var smileyValue = "";
					var smileyColor = "";
					var isterative = false;
					var mstValueUnit;

					mstValueUnit = self.getQuantity(comp,inputPeriodDataset,resultPeriodDataset);
					
					// 
					/*var valueMsmt = null;//self.getComponentMeasurement(comp, 'valueMeasurement', scenario);
					if(valueList && valueMsmt){
						var compValues = valueList[valueMsmt.id];
						if(compValues){
							var significantDecimals = 2;//TODO
							mstValueUnit = window.utils.getRoundedValues(compValues[0]['Value'], significantDecimals) + ' ' + compValues[0]['Unit'];
							
						}
					} else {
						var selectedInterval = null;//comp.getSatisfactionInterval(scenario);
						if(selectedInterval){
							smileyValue = selectedInterval.get('smiley') ? selectedInterval.get('smiley') : '';
							smileyColor = selectedInterval.get('smileyColor') ? selectedInterval.get('smileyColor') : '#000000';
							//satisfactionSmiley = comp.fetchSmileySrc(smileyValue);
						}
						if(valueMsmt){
							var observedMeasure = valueMsmt.getParent();
							var measure = observedMeasure.get('measure');
							if (comp.get('aggregatedFrom').length > 0) {
								calc = true;
								if (comp.get('isIterative')) {
									var cyclePath = valueMsmt.getCyclicPath();
									if (cyclePath && cyclePath.length > 0){
										isterative = true;
									}
									
								}
							}
							var unit = measure.get('unit');
							var significantDecimals = unit ? unit.get('significantDecimals') : 2;
							var mstVal = window.utils.getRoundedValues(valueMsmt.get('value'), significantDecimals);
							if(mstVal && mstVal != '-'){
								mstVal = window.utils.thousandSeparator(mstVal);
							}
							mstValueUnit = mstVal + ' ' + unit.get('name');
						} else if(selectedInterval){//Gradeonly
							mstValueUnit = selectedInterval.get('symbol');
						}
						
					}*/
					if (comp.get('valueType') != ValueType.Atomic) {
						calc = true;
					}
					var level = 4
					
					if (valObj == null) {
						var isplanValue = false;
						var isActivityValue = false;
						if (DataManager.getDataManager().getRepositoryId(comp.id) == window.plansKey) {
							isplanValue = true;
						}
						if(comp.get('type')==="vdml_ValueAdd"){
							isActivityValue = true;
						}
						
						var unitName = comp.get('unit') ? comp.get('unit').get('name'):null;
						var compName = comp.get('name');
						if(unitName){
							compName = compName + " " + "("+unitName+")";
						}
						valObj = { "DT_RowId": keyIndex, "level": level, "key": keyIndex, "parent": valueLevel, "name": compName, "type": self.titleMap[comp.get('type')], "suffix": compSuffix, "calc": calc, planValue:isplanValue, 'subComponentVal':true,isActivityValue:isActivityValue };
						for (var j = 0; j < altColumnList.length; j++) {
							var altName = altColumnList[j];
							if(!valObj[altName]){
								valObj[altName] = "-";
							}
						}
						if(mstValueUnit){
							valObj[phaseAltName] = mstValueUnit
						}else{
							valObj[phaseAltName] = "-"
						};
						//valObj[phaseAltName] = {'value':mstValueUnit,"altId":altId,"customId":comp.id,"whenObserved": null};
						if(smileyValue){
							valObj[phaseAltName + '-satisfactionSmiley'] = smileyValue;
							valObj[phaseAltName + '-smileyColor'] = smileyColor;
						}
						// valObj[phaseAltName + '-isIterative'] = isterative;
						dynamicRows.push(valObj);
						keyIndex = keyIndex + 1;
						
					} else if(phaseAltName){
						if(smileyValue){
							valObj[phaseAltName + '-satisfactionSmiley'] = smileyValue;
							valObj[phaseAltName + '-smileyColor'] = smileyColor;
						}
						// valObj[phaseAltName + '-isIterative'] = isterative;
						if(mstValueUnit){
							valObj[phaseAltName] = mstValueUnit
						}else {
							valObj[phaseAltName] = "-"
						};

					
					}
					if(comp.get('type')==="vdml_ValuePropositionComponent" && !comp.get('isNominal')){
							keyIndex = self.subComponentValues(comp.get("satisfactionLevel"),keyIndex,valObj,altColumnList,inputPeriodDataset,resultPeriodDataset,dynamicRows,phaseAltName,"Satisfaction",comp)
							keyIndex = self.subComponentValues(comp.get("percentageWeight"),keyIndex,valObj,altColumnList,inputPeriodDataset,resultPeriodDataset,dynamicRows,phaseAltName,"Weight",comp) ;
							keyIndex = self.subComponentValues(comp.get("recipientOpinion"),keyIndex,valObj,altColumnList,inputPeriodDataset,resultPeriodDataset,dynamicRows,phaseAltName,"Recipient Opinion",comp);
					}
					
				});
				return keyIndex;
			};

			

	generateMetricsCSV() {
		var self = this;
		var fromPeriodDataset = self.periodSets().find(d=>d.year===self.selectedFromYear() && d.period===self.selectedFromPeriod());
		var toPeriodDataset = self.periodSets().find(d=>d.year===self.selectedToYear() && d.period===self.selectedToPeriod());
		var params = {fromYear:self.selectedFromYear(),toYear:self.selectedToYear(),fromPeriod:fromPeriodDataset.periodNumber,toPeriod:toPeriodDataset.periodNumber,scenarioId:self.scenario.get('id'),scenarioExecutionId:self.scenario.get("defaultExecutionScenario")}
		DataManager.getDataManager().get('currentPlan').scenarioExportDialog([params]);
	};

	firstScenarioTable(tableName){
		var self = this;
		var dynamicColumns = [{ title: 'Path', targets: 0 },{ title: 'Key', targets: 1 },
			{ title: 'Parent', targets: 2 }];
				var columnRendering = [ {
					"data": "level",
					"visible": false
				}, {
					"data": "key",
					"visible": false
				}, {
					"data": "parent",
					"visible": false
				}]
				var plan = DataManager.getDataManager().get('currentPlan');
				var targetIndex = 3;
				let filteredPeriods = self.getFilterPeriodDatasets()


				if(self.selectedPeriodValues().length>0){
					filteredPeriods = self.selectedPeriodValues().map(d=>{
						for(var i=0;i<self.periodSets().length;i++){
							if(`${self.periodSets()[i].year}-${self.periodSets()[i].period}`=== d){
								return self.periodSets()[i]
						}
					}
				})}

			  	filteredPeriods.map((d)=>{
					var phaseAltName = `${d.year}-${d.period}`;
					var periodSetColumns;
					if(plan.get('periodKind')===self.labels["Yearly"]()){
						periodSetColumns = d.year
					}else{
						periodSetColumns = phaseAltName
					}		
					self.altMap[phaseAltName] = d.altId;
					self.yearPeriodMap[phaseAltName] = d;
					
					dynamicColumns.push({
						title: periodSetColumns,
						targets: targetIndex
					});

					columnRendering.push({
						"data": phaseAltName,
						"type": "custom",
						"className": "phase-alt-name text-right",
						"render": function (data, type, full, meta) {
							switch (type) {
								case "display":
									return data;
							}
							return data;
						}
					});
					targetIndex++;
				})
				
				let displayed;
				var dt = $('#'+tableName).DataTable({
					"data": self.dynamicRows,
					"bLengthChange": false,
					"pageLength": 50,
					"ordering": false,
					"pageResize": true,
					"columnDefs": dynamicColumns,
					"columns": columnRendering,
					'dom': 'lrt',
                    "bStateSave": true,                    
                    "paging":false,
					"searching":true,
                    "scrollCollapse": true,
					"initComplete": function(settings, json) {
						var rowsToHide = [];
						displayed = new Set(_.pluck(this.dynamicRows, 'key'));
						var table = $('#'+tableName).DataTable();
						table.rows().every(function () {
							var data = this.data(); 
							var key = data.key; 

							if (!data.subComponentVal) {
								rowsToHide.push(key); 
							}
						});
						if(rowsToHide.length>0){
							rowsToHide.forEach(function(key) {
								var rowIndex = table.rows().indexes().filter(function(i) {
									return table.row(i).data().key === key; 
								})[0];
						
								if (rowIndex !== undefined) {
									var tr = $(table.row(rowIndex).node());
									displayed.add(key);
									tr.addClass('details'); 
								}
							});
						
							var regex = "^(0";
							displayed.forEach(function (value) {
								regex = regex + "|" + value;
							});
							regex = regex + ")$";
							table.columns([2]).search(regex, true, false).draw();
						}
					},
					"fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
						if(aData["type"] === "Value"){
							//var svgSmiliesList = window.utils.getSmiliesList();
							//var theadChildrens = $("#offlineTable").children().find('tr').children();
							// $('td', nRow).each(function () {
							// 	if (this.className == "phase-alt-name text-right") {
							// 			var a = document.createElement('a');
							// 			a.style.color = 'inherit';
							// 			a.style.cursor= 'pointer';
							// 			a.setAttribute('id',aData["suffix"]);
							// 			var val = this.innerText;
							// 			a.appendChild(document.createTextNode(val));
							// 			this.innerText = '';
							// 			this.append(a);
							// 			$(a).on('dblclick',function(event){
							// 				event.stopPropagation();
							// 				var qty = event.currentTarget.innerText;
							// 				var cellIndex = event.currentTarget.parentElement.cellIndex;
							// 				var phaseAltName = $('thead tr th').eq(cellIndex+2).find('.dt-column-title').text();
							// 				var currentPlan = DataManager.getDataManager().get('currentPlan');
							// 				if(currentPlan.get("periodKind")==="Yearly"){
							// 					phaseAltName = phaseAltName+"-1"
							// 				}
							// 				var altId = self.altMap[phaseAltName];
							// 				var suffix = event.currentTarget.parentElement.parentElement.getAttribute('suffix');
							// 				var valId = altId+suffix;
							// 				var planValue = event.currentTarget.parentElement.parentElement.getAttribute('planValue');
							// 				if(planValue == 'true'){
							// 					valId = window.plansKey+suffix;
							// 				}
							// 				self.showProjectionDropdown(event,valId,qty);	
							// 			});
							// 		/*var colu = theadChildrens[this.cellIndex].innerText;
							// 		if(aData[colu + "-satisfactionSmiley"]){
							// 			var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
							// 			svg.setAttribute("width", 21);
							// 			svg.setAttribute("height", 20);
							// 			svg.setAttribute("style",'vertical-align:bottom;');
							// 			svg.setAttribute("viewBox", "-3 -2 26 26");
							// 			svg.setAttribute("fill", aData[colu + "-smileyColor"]);
							// 			//svg.setAttribute("correctiveActions",found[8]);
							// 			//svg.style.cursor = 'pointer';
							// 			var link = document.createElementNS("http://www.w3.org/2000/svg", "path");
							// 			var smileySrc = '';
							// 			for(var j=0;j<svgSmiliesList.length;j++){
							// 				if(svgSmiliesList[j].value === aData[colu + "-satisfactionSmiley"]) {
							// 					smileySrc = svgSmiliesList[j].src;
							// 					break;
							// 				}
							// 			}
							// 			link.setAttribute("d", smileySrc);
							// 			svg.appendChild(link);
							// 			//$('td:eq(3)', nRow).append(svg);
							// 			this.append(svg);
							// 		}
							// 		if(aData[colu + "-isIterative"] == true){
							// 			var span = document.createElement('span');
							// 			span.style.color = 'orange';
							// 			span.style.cursor= 'pointer';
							// 			span.className = "glyphicon glyphicon-repeat";
							// 			//span.setAttribute('id',aData["suffix"]);
							// 			this.append(span);
							// 		}*/
							// 		if(aData["calc"] != true && self.enableDirectEditing){
							// 			this.setAttribute('contenteditable', true);
							// 			$(this).on("keydown", function (event) {
							// 				$(this).prop('oldValue', $(this).val());
							// 				window.utils.checkNumericValue(event, this);
							// 			});
							// 			$(this).on("keyup", function (event) {
							// 				let currentValue = $(event.target).val();
							// 				var decimalLimit = 4;
							// 				var rgx = new RegExp(`^\\-?[0-9]*\\.?[0-9]{0,${decimalLimit}}$`);
							// 				if (!rgx.test(currentValue)) {
							// 					$(event.target).val($(this).prop('oldValue'));
							// 				}
							// 			});
							// 			$(this).blur(function(event){
							// 				//var tr = event.currentTarget.parentElement;
							// 				var qty = event.currentTarget.innerText;
							// 				var phaseAltName = $('tr th').eq(event.currentTarget.cellIndex).find('span.dt-column-title').text();
							// 				var currentPlan = DataManager.getDataManager().get('currentPlan');
							// 				if(currentPlan.get("periodKind")==="Yearly"){
							// 					phaseAltName = phaseAltName+"-1"
							// 				}
							// 				var altId = self.altMap[phaseAltName];
							// 				var suffix = event.currentTarget.parentElement.getAttribute('suffix');
							// 				var valId = altId+suffix;
							// 				var planValue = event.currentTarget.parentElement.getAttribute('planValue');
							// 				if(planValue == 'true'){
							// 					valId = window.plansKey+suffix;
							// 				}
							// 				self.valueChange(valId,qty,phaseAltName);
							// 			});
							// 		}
							// 		/*$(a).click(function(event){
							// 			var currentTarget = event.currentTarget;
							// 			var index = currentTarget.parentElement.cellIndex;
							// 			var phaseAltName = $('tr th').eq(index)[0].innerHTML;
							// 			var altId = self.altMap[phaseAltName];
							// 			var suffix = currentTarget.getAttribute('id');
							// 			var valId = altId+suffix;
							// 			var planValue = $(currentTarget.parentElement.parentElement).children()[0].getAttribute('planValue');
							// 			if(planValue == 'true'){
							// 				valId = window.plansKey+suffix;
							// 			}
							// 			var obj1 = {id:valId,altId:altId,timeStamp:null};
							// 			self.showAggregationView(obj1);
							// 		});*/
							// 	}
							// });
						}
					}
				});

				$('#'+tableName).on('init.dt', function () {
					dt.columns([2]).search('^(0)$', true, false).draw();
				});
				
				$('#'+"fixedColumns"+' tbody').on('click', 'tr td:first-child', function () {
					var tr = $(this).closest('tr');
					var row = $('#fixedColumns').DataTable().row(tr);
					var key = row.data().key;
					
					if (displayed.has(key)) {
						var childKeyList = [];
						function getChildKeys(childKeyList,key){
							for (var k = 0; k < self.dynamicRows.length; k++) {
								if (self.dynamicRows[k]["parent"] == key) {
									var childKey = self.dynamicRows[k].key;
									childKeyList.push(childKey);
									getChildKeys(childKeyList,childKey);
								}
							}
							return childKeyList
						}
						getChildKeys(childKeyList,key);
						var childList = childKeyList.length;
						while(childList--){
							displayed.delete(childKeyList[childList]);
						}						
						displayed.delete(key);
						tr.removeClass('details');
					} else {
						displayed.add(key);
						tr.addClass('details');
					}
					
					var regex = "^(0";
					displayed.forEach(function (value) {
						regex = regex + "|" + value;
					});
					regex = regex + ")$";
					dt.columns([2]).search(regex, true, false).draw();
				});		
				$('#firstScenarioTableContainer').on('scroll', function() {
					var scrollLeft = $(this).scrollLeft();
					$('#secondScenarioTableContainer').scrollLeft(scrollLeft);
				});
	}


	createFixedColumnTable(tableName){
		var self = this;
		var dynamicColumns = [{ title: 'Select', targets: 0 },{ title: 'Path', targets: 1 },{ title: 'Key', targets: 2 },
					{ title: 'Parent', targets: 3 },{ title: 'Name', targets: 4},{ title: 'Type', targets: 5}];
				var columnRendering = [{
					"class": "details-control",
					"orderable": false,
					"data": null,
					"defaultContent": "",
					"createdCell": function (td, cellData, rowData, row, col) {
						if (rowData.level > 0) {
							td.className = td.className + ' level-' + rowData.level;
							if (rowData.type == "Value"){
								td.parentElement.setAttribute("suffix",rowData.suffix);
								td.parentElement.setAttribute("planValue",rowData.planValue);
							} /*else if(self.showOnlyValues){
								td.parentElement.style.display = "none";
							}*/
						}/* else if(self.showOnlyValues){
							td.parentElement.style.display = "none";
						}*/
					}
				}, {
					"data": "level",
					"visible": false
				}, {
					"data": "key",
					"visible": false
				}, {
					"data": "parent",
					"visible": false
				}, {
					"data": "name",
					"render": function (data, type, full, meta) {
							var order = self.buildOrderObject($('#'+tableName).DataTable(), full['key'], 'name').child;
							switch (type) {
								case "display":
									return data;
								case "filter":
									return data;
								case "sort":
									return order;
							}
							return data;
						}
					},
					{
						"data": "type",
                         "visible" : true
					}
				]
				var plan = DataManager.getDataManager().get('currentPlan');
				var targetIndex = 6;
				let filteredPeriods = this.getFilterPeriodDatasets();


				if(self.selectedPeriodValues().length>0){
					filteredPeriods = self.selectedPeriodValues().map(d=>{
						for(var i=0;i<self.periodSets().length;i++){
							if(`${self.periodSets()[i].year}-${self.periodSets()[i].period}`=== d){
								return self.periodSets()[i]
						}
					}
				})}

			  	filteredPeriods.map((d)=>{
					var phaseAltName = `${d.year}-${d.period}`;
					var periodSetColumns;
					if(plan.get('periodKind')===self.labels["Yearly"]()){
						periodSetColumns = d.year
					}else{
						periodSetColumns = phaseAltName
					}		
					self.altMap[phaseAltName] = d.altId;
					self.yearPeriodMap[phaseAltName] = d;
					
					targetIndex++;
				})
				
				let displayedFixedRow
				var dt = $('#'+tableName).DataTable({
					"data": self.dynamicRows,
					"bLengthChange": false,
					"pageLength": 50,
					"ordering": false,
					"pageResize": true,
					"columnDefs": dynamicColumns,
					"columns": columnRendering,
                    "bStateSave": true,
					"fixedColumns": {
                        start: 3,
                    },
                    dom: 'lrt',
                    "paging":false,
					"searching":true,
                    "scrollCollapse": true,
					
					"initComplete": function(settings, json) {
						var rowsToHide = [];
						displayedFixedRow = new Set(_.pluck(this.dynamicRows, 'key'));
						var table = $('#fixedColumns').DataTable();
						table.rows().every(function () {
							var data = this.data(); 
							var key = data.key; 

							if (!data.subComponentVal) {
								rowsToHide.push(key); 
							}
						});
						if(rowsToHide.length>0){
							rowsToHide.forEach(function(key) {
								var rowIndex = table.rows().indexes().filter(function(i) {
									return table.row(i).data().key === key; 
								})[0];
						
								if (rowIndex !== undefined) {
									var tr = $(table.row(rowIndex).node());
									displayedFixedRow.add(key);
									tr.addClass('details'); 
								}
							});
						
							var regex = "^(0";
							displayedFixedRow.forEach(function (value) {
								regex = regex + "|" + value;
							});
							regex = regex + ")$";
							table.columns([3]).search(regex, true, false).draw();
						}
					},
					"fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
						if(aData["type"] === "Value"){
							if(aData["calc"] == true){
								var imageUrl = '/img/calculator.png';
								$('td:eq(1)', nRow).css('background', 'white url(' + imageUrl + ') no-repeat right');
							}							
						}
					}					
				});

				$('#'+tableName).on('init.dt', function () {
					dt.columns([3]).search('^(0)$', true, false).draw();
				});
				$('#'+tableName+' tbody').on('click', 'tr td:first-child', function () {
					var tr = $(this).closest('tr');
					var row = dt.row(tr);
					var key = row.data().key;
					
					
					if (displayedFixedRow.has(key)) {
						var childKeyList = [];
						function getChildKeys(childKeyList,key){
							for (var k = 0; k < self.dynamicRows.length; k++) {
								if (self.dynamicRows[k]["parent"] == key) {
									var childKey = self.dynamicRows[k].key;
									childKeyList.push(childKey);
									getChildKeys(childKeyList,childKey);
								}
							}
							return childKeyList
						}
						getChildKeys(childKeyList,key);
						var childList = childKeyList.length;
						while(childList--){
							displayedFixedRow.delete(childKeyList[childList]);
						}							
						displayedFixedRow.delete(key);
						tr.removeClass('details');
					} else {
						displayedFixedRow.add(key);
						tr.addClass('details');
					}
					
					var regex = "^(0";
					displayedFixedRow.forEach(function (value) {
						regex = regex + "|" + value;
					});
					regex = regex + ")$";
					dt.columns([3]).search(regex, true, false).draw();
				});			
		}


	getFilterPeriodDatasets(){
		var self = this;
		const fromObj = self.periodSets().find(d=>d.year===self.selectedFromYear() && d.period === self.selectedFromPeriod());
			const toObj =  self.periodSets().find(d=>d.year===self.selectedToYear() && d.period === self.selectedToPeriod());
			let filteredPeriods = self.periodSets().filter(period => {
				const fromYear = fromObj.year;
				const fromPeriod = fromObj.periodNumber;
				const toYear = toObj.year;
				const toPeriod = toObj.periodNumber;
				
				const isAfterFrom = period.year > fromYear || (period.year === fromYear && period.periodNumber >= fromPeriod);
				const isBeforeTo = period.year < toYear || (period.year === toYear && period.periodNumber <= toPeriod);
				
				return isAfterFrom && isBeforeTo;
			});
		return filteredPeriods
	}
	
	
	createOfflineTable(tableName){
		var self = this;
		/*self.showOnlyValues = false;
		if(self.selectedChartType() == "Show Only Values"){
			self.showOnlyValues = true;
		}*/
		var dynamicColumns = [{ title: 'Select', targets: 0 },{ title: 'Path', targets: 1 },{ title: 'Key', targets: 2 },
					{ title: 'Parent', targets: 3 },{ title: 'Name', targets: 4},{ title: 'Type', targets: 5}];
				var columnRendering = [{
					"class": "details-control",
					"orderable": false,
					"data": null,
					"defaultContent": "",
					"createdCell": function (td, cellData, rowData, row, col) {
						if (rowData.level > 0) {
							td.className = td.className + ' level-' + rowData.level;
							if(rowData.isActivityValue){
								td.className = td.className + ' activity';
							}
							if (rowData.type == "Value"){
								td.parentElement.setAttribute("suffix",rowData.suffix);
								td.parentElement.setAttribute("planValue",rowData.planValue);
							} /*else if(self.showOnlyValues){
								td.parentElement.style.display = "none";
							}*/
						}/* else if(self.showOnlyValues){
							td.parentElement.style.display = "none";
						}*/
					}
				}, {
					"data": "level",
					"visible": false
				}, {
					"data": "key",
					"visible": false
				}, {
					"data": "parent",
					"visible": false
				}, {
					"data": "name",
					"render": function (data, type, full, meta) {
							var order = self.buildOrderObject($('#'+tableName).DataTable(), full['key'], 'name').child;
							switch (type) {
								case "display":
									return data;
								case "filter":
									return data;
								case "sort":
									return order;
							}
							return data;
						}
					},
					{
						"data": "type",
                         "visible" : true
					}
				]
				var plan = DataManager.getDataManager().get('currentPlan');
				var targetIndex = 6;
				let filteredPeriods = this.getFilterPeriodDatasets()

				if(self.selectedPeriodValues().length>0){
					filteredPeriods = self.selectedPeriodValues().map(d=>{
						for(var i=0;i<self.periodSets().length;i++){
							if(`${self.periodSets()[i].year}-${self.periodSets()[i].period}`=== d){
								return self.periodSets()[i]
						}
					}
				})}


			  	filteredPeriods.map((d)=>{
					var phaseAltName = `${d.year}-${d.period}`;
					var periodSetColumns;
					if(plan.get('periodKind')===self.labels["Yearly"]()){
						periodSetColumns = d.year
					}else{
						periodSetColumns = phaseAltName
					}		
					self.altMap[phaseAltName] = d.altId;
					self.yearPeriodMap[phaseAltName] = d;
					
					dynamicColumns.push({
						title: periodSetColumns,
						targets: targetIndex
					});

					columnRendering.push({
						"data": phaseAltName,
						"type": "custom",
						"className": "phase-alt-name text-right",
						"render": function (data, type, full, meta) {
							//var order = self.buildOrderObject($('#'+tableName).DataTable(), full['key'], phaseAltName).child
							switch (type) {
								case "display":
									return data;
								/*case "filter":
									var val = data != '-'?data.value:data;
									return val;
								case "sort":
									return order;*/
							}
							return data;
						}
					});
					targetIndex++;
				})
				
				let displayed;
				var dt = $('#'+tableName).DataTable({
					"data": self.dynamicRows,
					"bLengthChange": false,
					"pageLength": 50,
					"ordering": false,
					"pageResize": true,
					"columnDefs": dynamicColumns,
					"columns": columnRendering,
                    "bStateSave": true,
					"fixedColumns": {
                        start: 3,
                    },
                    'dom': 'lrt',
                    "paging":false,
					"searching":true,
                    "scrollCollapse": true,
                    "scrollX":true,
					"initComplete": function(settings, json) {
						var rowsToHide = [];
						displayed = new Set(_.pluck(this.dynamicRows, 'key'));
						var table = $('#'+tableName).DataTable();
						table.rows().every(function () {
							var data = this.data(); 
							var key = data.key; 

							if (!data.subComponentVal) {
								rowsToHide.push(key); 
							}
						});
						if(rowsToHide.length>0){
							rowsToHide.forEach(function(key) {
								var rowIndex = table.rows().indexes().filter(function(i) {
									return table.row(i).data().key === key; 
								})[0];
						
								if (rowIndex !== undefined) {
									var tr = $(table.row(rowIndex).node());
									displayed.add(key);
									tr.addClass('details'); 
								}
							});
						
							var regex = "^(0";
							displayed.forEach(function (value) {
								regex = regex + "|" + value;
							});
							regex = regex + ")$";
							table.columns([3]).search(regex, true, false).draw();
						}
					},
					"fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
						if(aData["type"] === "Value"){
							if(aData["calc"] == true){
								var imageUrl = '/img/calculator.png';
								$('td:eq(1)', nRow).css('background', 'white url(' + imageUrl + ') no-repeat right');
							}							
							$('td', nRow).each(function () {
								if (this.className == "phase-alt-name text-right" ) {
										var a = document.createElement('a');
										a.style.color = 'inherit';
										a.style.cursor= 'pointer';
										a.setAttribute('id',aData["suffix"]);
										var val = this.innerText;
										a.appendChild(document.createTextNode(val));
										this.innerText = '';
										this.append(a);
										$(a).on('contextmenu',function(event){
											event.preventDefault();
											var qty = event.currentTarget.innerText;
											var cellIndex = event.currentTarget.parentElement.cellIndex;
											var phaseAltName = $('thead tr th').eq(cellIndex+2).find('.dt-column-title').text();
											var currentPlan = DataManager.getDataManager().get('currentPlan');
											if(currentPlan.get("periodKind")==="Yearly"){
												phaseAltName = phaseAltName+"-1"
											}
											var altId = self.altMap[phaseAltName];
											var suffix = event.currentTarget.parentElement.parentElement.getAttribute('suffix');
											var valId = altId+suffix;
											var planValue = event.currentTarget.parentElement.parentElement.getAttribute('planValue');
											if(planValue == 'true'){
												valId = window.plansKey+suffix;
											}
											self.showProjectionDropdown(event,valId,qty);	
										});
									/*var colu = theadChildrens[this.cellIndex].innerText;
									if(aData[colu + "-satisfactionSmiley"]){
										var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
										svg.setAttribute("width", 21);
										svg.setAttribute("height", 20);
										svg.setAttribute("style",'vertical-align:bottom;');
										svg.setAttribute("viewBox", "-3 -2 26 26");
										svg.setAttribute("fill", aData[colu + "-smileyColor"]);
										//svg.setAttribute("correctiveActions",found[8]);
										//svg.style.cursor = 'pointer';
										var link = document.createElementNS("http://www.w3.org/2000/svg", "path");
										var smileySrc = '';
										for(var j=0;j<svgSmiliesList.length;j++){
											if(svgSmiliesList[j].value === aData[colu + "-satisfactionSmiley"]) {
												smileySrc = svgSmiliesList[j].src;
												break;
											}
										}

										link.setAttribute("d", smileySrc);
										svg.appendChild(link);
										//$('td:eq(3)', nRow).append(svg);
										this.append(svg);
									}
									if(aData[colu + "-isIterative"] == true){
										var span = document.createElement('span');
										span.style.color = 'orange';
										span.style.cursor= 'pointer';
										span.className = "glyphicon glyphicon-repeat";
										//span.setAttribute('id',aData["suffix"]);
										this.append(span);
									}*/
									if(aData["calc"] != true && self.enableDirectEditing){
										$(this).on("keydown", function (event) {
											$(this).prop('oldValue', $(this).val());
											window.utils.checkNumericValue(event, this);
										});
										$(this).on("keyup", function (event) {
											let currentValue = $(event.target).val();
											var decimalLimit = 4;
											var rgx = new RegExp(`^\\-?[0-9]*\\.?[0-9]{0,${decimalLimit}}$`);
											if (!rgx.test(currentValue)) {
												$(event.target).val($(this).prop('oldValue'));
											}
										});

										$(this).on('dblclick', function() {
											var cell = $(this);
											if (!cell.is('[contenteditable]')) {
												cell.attr('contenteditable', 'true').focus();
											}
										});
						
										$(this).blur(function(event){
											//var tr = event.currentTarget.parentElement;
											var qty = event.currentTarget.innerText;
											var phaseAltName = $('tr th').eq(event.currentTarget.cellIndex).find('span.dt-column-title').text();
											var currentPlan = DataManager.getDataManager().get('currentPlan');
											if(currentPlan.get("periodKind")==="Yearly"){
												phaseAltName = phaseAltName+"-1"
											}
											var altId = self.altMap[phaseAltName];
											var suffix = event.currentTarget.parentElement.getAttribute('suffix');
											var valId = altId+suffix;
											var planValue = event.currentTarget.parentElement.getAttribute('planValue');
											if(planValue == 'true'){
												valId = window.plansKey+suffix;
											}
											self.valueChange(valId,qty,phaseAltName);
											$(this).removeAttr('contenteditable');
										});
									}
									$(a).click(function(event){
										var currentTarget = event.currentTarget;
										// var index = currentTarget.parentElement.cellIndex;
										var phaseAltName = $('tr th').eq(event.currentTarget.parentNode.cellIndex).find('span.dt-column-title').text();
										var currentPlan = DataManager.getDataManager().get('currentPlan');
										if(currentPlan.get("periodKind")==="Yearly"){
											phaseAltName = phaseAltName+"-1"
										}
										var altId = self.altMap[phaseAltName];
										var suffix = currentTarget.getAttribute('id');
										var valId = altId+suffix;
										// var obj1 = {id:valId,altId:altId,timeStamp:null};
										var altModel = window.utils.getElementModel(altId,['transformation.Alternative']);
										var planValue = event.currentTarget.parentElement.parentElement.getAttribute('planValue');
											if(planValue == 'true'){
												valId = window.plansKey+suffix;
											}
										var cell = $(this).parent();
										if (!$(cell).is('[contenteditable]')) {
											altModel.showAggregationView(valId);
										}

									});
								}
							});
						}
					}
				});

				$('#'+tableName).on('init.dt', function () {
					dt.columns([3]).search('^(0)$', true, false).draw();
				});
			
				$('#'+tableName+' tbody').on('click', 'tr td:first-child', function () {
					var tr = $(this).closest('tr');
					var row = dt.row(tr);
					var key = row.data().key;
					
					if (displayed.has(key)) {
						var childKeyList = [];
						function getChildKeys(childKeyList,key){
							for (var k = 0; k < self.dynamicRows.length; k++) {
								if (self.dynamicRows[k]["parent"] == key) {
									var childKey = self.dynamicRows[k].key;
									childKeyList.push(childKey);
									getChildKeys(childKeyList,childKey);
								}
							}
							return childKeyList
						}
						getChildKeys(childKeyList,key);
						var childList = childKeyList.length;
						while(childList--){
							displayed.delete(childKeyList[childList]);
						}							
						displayed.delete(key);
						tr.removeClass('details');
					} else {
						displayed.add(key);
						tr.addClass('details');
					}
					
					var regex = "^(0";
					displayed.forEach(function (value) {
						regex = regex + "|" + value;
					});
					regex = regex + ")$";
					dt.columns([3]).search(regex, true, false).draw();
				});		
			}

			showProjectionDropdown(event,valId,qty){
				var self = this;
				const arr = []
				//var altId = self.altMap[phaseAltName];
				var values =  window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
				if(values && values.get('valueType') == ValueType.Atomic && qty!=="-"){
					const currentPlan = DataManager.getDataManager().get('currentPlan');
					const scenarioId = currentPlan.get("defaultScenario");
					const scenarioData = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
					var periodDatasets = scenarioData.getPlanDatasets(currentPlan);
					for(let i = 0;i<periodDatasets.length;i++){
						var periodDataset = self.defaultExecutionScenaro.get("input").findWhere({period: periodDatasets[i].period,year: periodDatasets[i].year,periodKind : currentPlan.get("periodKind")});
						if(periodDataset.get(valId)!==undefined){
							arr.push(periodDataset.get(valId));
							
						}
					}
					// var options = ["Extrapolate baseline", "Extrapolate trend", "Interpolate", "Interpolate-extrapolate"];
					// if(arr.length>1 || periodDatasets.length===1){
					// 	options = ["Extrapolate trend", "Interpolate", "Interpolate-extrapolate"]
					// }

					var options

					if(arr.filter(d=>d!=='').length===1 && periodDatasets.length>1){
						options = ["Extrapolate baseline"];
					}

					function populateDropdown(menuElement, options, event) {
						menuElement.innerHTML = ""; // Clear existing buttons
						options.forEach(option => {
							const button = document.createElement("button");
							button.textContent = option;
							button.style.display = "block";
							button.style.width = "100%";
							button.style.border = "none";
							button.style.background = "none";
							button.style.padding = "10px";
							button.style.cursor = "pointer";
							button.style.textAlign = "left";
							button.onmouseover = () => button.style.backgroundColor = "#f0f0f0";
							button.onmouseout = () => button.style.backgroundColor = "white";
							button.onclick = () => {
								var phaseAltName = $('tr th').eq(event.currentTarget.parentElement.cellIndex)[0].innerHTML;
								menuElement.style.display = "none";
								if(option == "Extrapolate baseline") {
									window.vdmModelView.extrapolateBaseline(valId,phaseAltName,event.currentTarget.innerHTML);
								} else if(option == "Extrapolate trend") {
									window.vdmModelView.extrapolateTrend(valId,phaseAltName);
								} else if(option == "Interpolate") {
									window.vdmModelView.interpolate(valId,phaseAltName);
								} else if(option == "Interpolate-extrapolate") {
									window.vdmModelView.interpolateextrapolate(valId,phaseAltName);
								} else {
									console.log(`You clicked: ${option}`);
								}
								
							};
							menuElement.appendChild(button);
						});
					}

					function showDropdownMenu(event) {
						const dropdownMenu = document.getElementById("dropdownMenu");
						const cellRect = event.target.getBoundingClientRect();
						populateDropdown(dropdownMenu, options, event);
						var left = cellRect.left - 50;
						var top = cellRect.bottom - 50;
						dropdownMenu.style.display = "block";
						dropdownMenu.style.left = `${left}px`;
						dropdownMenu.style.top = `${top}px`;

						function handleClickOutside(event) {
							var self = this;
							if (!dropdownMenu.contains(event.target) && !event.target.classList.contains('dropdown-cell')) {
								dropdownMenu.style.display = "none";
								document.removeEventListener('click', handleClickOutside);
							}
						}
						document.addEventListener('click', handleClickOutside);
					}
					options && showDropdownMenu(event);
				}else {
					bootbox.alert("values are incorrect");
				}
			}

			destroyTable(table){
				if ($.fn.dataTable.isDataTable(table)) {
					$(table).DataTable().destroy();
				}
				$(table).html('');
				/*window.utils.setDatatableEmpty('offlineTable');*/
				$(table).html('');
				$("<table id="+table+" class='display' width='100%' cellspacing='0'>    </table>").insertAfter($(table+"_wrapper"));
				$(table+"_wrapper").remove();
			}

			valueChange(valId,qty,phaseAltName){
				var self = this;
				const currentPlan = DataManager.getDataManager().get('currentPlan');
				var yearPeriod = self.yearPeriodMap[phaseAltName];
				var	inputPeriodDataset = self.defaultExecutionScenaro.get("input").findWhere({period: yearPeriod.periodNumber,year: yearPeriod.year,periodKind : currentPlan.get("periodKind")});
				var valModal = window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
				if(inputPeriodDataset && valModal){
					inputPeriodDataset.set(valId,qty);
				}
			}

		afterRenderView(node, view) {
			var self = view;
			self.viewloaded = true;
			if(self.showMultipleScenarioTable()){
				self.createFixedColumnTable("fixedColumns");
				self.firstScenarioTable("firstScenarioTable");
				self.firstScenarioTable("secondScenarioTable");
			}else {
				self.createOfflineTable("offlineTable");
			}
			$('#selectPeriod' + self.encodeId).multiselect({
				enableClickableOptGroups: false,
				numberDisplayed : 0,
				nonSelectedText: '0 Selected',
				
			});
			$('#selectedScenario' + self.encodeId).multiselect({
				enableClickableOptGroups: false,
				numberDisplayed : 0,
				
			});
			$('#selectedScenario' + self.encodeId).multiselect('select', [self.scenarioOptions()[0].value]);

			$('#selectedScenario' + self.encodeId).change(function() {
				var selectedOptions = $('#selectedScenario' + self.encodeId + ' option:selected');
				self.selectedScenarioTables.removeAll();
				selectedOptions.each(function() {
					self.selectedScenarioTables().push($(this).val());
				})
				if(self.selectedScenarioTables().length>1){
					self.showMultipleScenarioTable(true);
					self.destroyTable('#fixedColumns');
					self.createFixedColumnTable("fixedColumns");
					self.destroyTable('#firstScenarioTable');
					self.firstScenarioTable("firstScenarioTable");
					self.destroyTable('#secondScenarioTable');
					self.firstScenarioTable("secondScenarioTable");
					self.showExportButton(false);
				}else{
					self.showMultipleScenarioTable(false);
					self.reRenderTables('offlineTable',function(){self.createOfflineTable('offlineTable')});
					self.showExportButton(true);
				}
            });
			
			$('#selectPeriod' + self.encodeId).change(function() {
				var selectedOptions = $('#selectPeriod' + self.encodeId + ' option:selected');
				self.selectedPeriodValues.removeAll()
				selectedOptions.each(function() {
					self.selectedPeriodValues.push($(this).text());
				})
				if(self.showMultipleScenarioTable()){
					self.reRenderTables('fixedColumns',function(){self.createFixedColumnTable('fixedColumns')});
					self.reRenderTables('firstScenarioTable',function(){self.firstScenarioTable('firstScenarioTable')});
					self.reRenderTables('secondScenarioTable',function(){self.firstScenarioTable('secondScenarioTable')});
				}else{
					self.reRenderTables('offlineTable',function(){self.createOfflineTable('offlineTable')});
				}
            });
			$(".switch-input").change(function() {
				// self.enableDirectEditing = !self.enableDirectEditing;
				self.destroyTable('#offlineTable');
				self.renderDynamicRows();
				self.createOfflineTable("offlineTable");
			});
			
			var dropdownMenu = $("#dropdownMenu"+self.encodeId);
			$('#dropdown'+self.encodeId).on('click', function(event) {
    			event.stopPropagation();
    			dropdownMenu.toggle();
			});
			$('#ValuesView').on('click', function(event) {
			    if (!dropdownMenu.is(event.target) && dropdownMenu.has(event.target).length === 0) {
			        dropdownMenu.hide();
			    }
			});
			window.utils.stopSpinner('valueView');
		}
		optimize(){
			var defaultScenario = DataManager.getDataManager().get('defaultScenario');
			var id = window.utils.htmlEscape(window.guidGenerator()) + "optimize";
			window.getAndCreateModalDialog(null, id, defaultScenario.getMixinClass(), defaultScenario, "Optimize", null, null);
			
			/*var dataManager = DataManager.getDataManager();
			var opType = dataManager.get('defaultScenario').get('optimizationType');
			var opObj = dataManager.get('defaultScenario').get("optimizationObjective");
			var opPeriod = dataManager.get('defaultScenario').get("optimizationPeriod");
			var exp = opObj ? opObj.get('expressionStr') : null;
			if(exp != null){
				var valModel = window.utils.getElementModel(exp, ['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']);
				var valName = valModel ? valModel.get('name') : exp;
				var dialogContent = `
					<div>
						<ul>
							<li>optimization Type : `+opType+`</li>
							<li>optimization Period : `+opPeriod+`</li>
							<li>optimization Objective : `+valName+`</li>
						</ul>
						<button id="optimizeButton" class="btn btn-primary">Optimize</button>
					</div>
				`;
			}
			bootbox.dialog({
				title: "Optimization",
				message: dialogContent,
				size: 'large'
			});	
			$('#optimizeButton').click(function () {
				//const option1 = $('#select1').val();
				//const option2 = $('#select2').val();
				bootbox.alert(`Under development`);
			});*/
		}
		calculate(){
			var self = this;
			var dataManager = DataManager.getDataManager();
			if(self.defaultExecutionScenaro.get('lastCalculated') && self.skipBuiding){
				window.utils.startSpinner('calculate', "Calculating...");
				dataManager.calculateJuliaModel(function(){
					self.showLastCalulatedDate();
					self.destroyTable('#offlineTable');
					self.renderDynamicRows();
					self.createOfflineTable("offlineTable");
					window.utils.stopSpinner('calculate');
				});
			} else {
				window.utils.startSpinner('calculate', "Building...");
				dataManager.buidlJuliaModel(function(err){
					if(err){
						window.utils.stopSpinner('calculate');
						return;
					}
					window.utils.showSpinnerText("Calculating...");
					window.setTimeout(function () {
						dataManager.calculateJuliaModel(function(){
							self.showLastCalulatedDate();
							self.destroyTable('#offlineTable');
							self.renderDynamicRows();
							self.createOfflineTable("offlineTable");
							window.utils.stopSpinner('calculate');
						});
					}, 500);
				});
			}
		}
		showLastCalulatedDate(){
			var self = this;
			if(self.defaultExecutionScenaro.get('lastCalculated') != null){
				var lastCalc = new Date(self.defaultExecutionScenaro.get('lastCalculated'));
				self.lastCalculate('Last Calculated on '+ lastCalc.toLocaleDateString() + " "+ lastCalc.toLocaleTimeString());
			} else {
				self.lastCalculate('');
			}
		}

		getRowData(altColumnList,keyIndex,dynamicRow){
			var self = this
			var plan = DataManager.getDataManager().get('currentPlan');
			var steps = self.scenario.get("step");
				for(var step of steps.models){
					var periodsLen = step.get('noPeriods');
					var period = step.get("startPeriod");
					for(var x = 0; x < periodsLen; x++){
						if(x != 0){
							period++;
						}
						let yearPeriod = utils.calculateYearPeriod(self.scenario.get("startTime") , period,plan.get("periodKind") );
						//var phaseAltName = phases.at(j).get('name') + "/" + alternatives.at(i).get('name');
						var periodKind =  self.model.getPeriodKinds(yearPeriod.year,yearPeriod.period,plan.get("periodKind"));
						var periodKindText = periodKind?.text || yearPeriod.period;
						var phaseAltName = yearPeriod.year + "-" + periodKindText;
						var	 inputPeriodDataset = self.defaultExecutionScenaro.get("input").findWhere({period: yearPeriod.period,year: yearPeriod.year,periodKind : plan.get("periodKind")});
						var resultPeriodDataset = self.defaultExecutionScenaro.get("result").findWhere({period: yearPeriod.period,year: yearPeriod.year,periodKind : plan.get("periodKind")});
						var altModel = window.utils.getElementModel(step.get('alternativeId'),['transformation.Alternative']);
						var phaseDesignPart = altModel.get("phaseDesignPart");
						for (var k = 0; k < phaseDesignPart.length; k++) {
							var packId = phaseDesignPart.at(k).get("beepReference");
							if (packId.indexOf("Common") == -1 && phaseDesignPart.at(k).get("beepType") === "vdml_ValueDeliveryModel") {
								var packageModel = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: packId });
								if(!packageModel){
									continue;
								}
								for (var l = 0; l < packageModel.get("businessModel").models.length; l++) {
									var bm = packageModel.get("businessModel").models.at(l);
									var bm1Row = self.createDynamicRow(bm.get("name"), bm.get("id"), keyIndex, 1, 0, phaseAltName, self.titleMap[bm.get('type')],altColumnList);
									var bmLevel = keyIndex;
									if(!dynamicRow.includes(bm1Row)){
										dynamicRow.push(bm1Row);
										keyIndex++;
									}else {
										bmLevel = bm1Row.key;
									}
									//var scenario =  alternatives.at(i).getMainScenario(bm.getNestedParent());
									keyIndex = fillValueProposition(bm, keyIndex, bmLevel, inputPeriodDataset,resultPeriodDataset, phaseAltName, altColumnList);
								
									keyIndex = fillMyValueProposition(bm, keyIndex, bmLevel, inputPeriodDataset,resultPeriodDataset, phaseAltName, altColumnList)
								}
							}
						}
					}
				}
				function fillValueProposition(bm, keyIndex, bmLevel, inputPeriodDataset, resultPeriodDataset, phaseAltName, altColumnList) {
					for (var m = 0; m < bm.get("bmValueProposition").length; m++) {
						var bmVp = bm.get("bmValueProposition").models.at(m);
						var vp = bmVp.get("valueProposition");
						var component = bmVp.get("valueProposition").get("component");
						if(component.length == 0){
							continue;
						}
						var bmChild = self.createDynamicRow(bmVp.get("name"), bmVp.get("id"), keyIndex, 2, bmLevel, phaseAltName, self.titleMap[bmVp.get('type')],altColumnList);
						var valueLevel = keyIndex;
						if(!dynamicRow.includes(bmChild)){
							dynamicRow.push(bmChild);
							keyIndex++;
						}else {
							valueLevel = bmChild.key;
						}
						if (inputPeriodDataset || resultPeriodDataset) {
							keyIndex = self.checkValues(component.models, valueLevel, dynamicRow, keyIndex, phaseAltName,inputPeriodDataset,resultPeriodDataset,altColumnList);
							var vpStream = bm.get("bmValueStream").findWhere({"persuedProposition":vp});
							console.log(vpStream);
							if(vpStream){
								var vpActivity = vpStream.get("valueStreamActivity")
								for(var k=0;k<=vpActivity.length;k++){
									var bmAct = bm.get("bmActivity").findWhere({'activity':vpActivity.at(k)});
									if(bmAct){
										keyIndex = fillActivity(bmAct, keyIndex, valueLevel, inputPeriodDataset,resultPeriodDataset, phaseAltName, altColumnList,vpStream)
									}
								}
							}
							
							
						}
					}
					return keyIndex			
				}
				function fillActivity(bmAct, keyIndex, bmLevel, inputPeriodDataset, resultPeriodDataset, phaseAltName, altColumnList,stream) {
						var actValueList = stream.get("activityValue").models;
						var component = bmAct.get("activityValue");
						// if(component.length == 0){
						// 	continue;
						// }
						var bmChild = self.createDynamicRow(bmAct.get("name"), bmAct.get("id")+stream.get('name'), keyIndex, 3, bmLevel, phaseAltName,self.titleMap[bmAct.get('type')],altColumnList);
						var valueLevel = keyIndex;
						if(!dynamicRow.includes(bmChild)){
							dynamicRow.push(bmChild);
							keyIndex++;
						}else {
							valueLevel = bmChild.key;
						}
						if (inputPeriodDataset||resultPeriodDataset) {
							keyIndex = self.checkValues(component.models, valueLevel, dynamicRow, keyIndex, phaseAltName, inputPeriodDataset, resultPeriodDataset, altColumnList,actValueList);
						}
					return keyIndex;
				}

				function fillMyValueProposition(bm, keyIndex, bmLevel, inputPeriodDataset, resultPeriodDataset, phaseAltName, altColumnList) {
					for (var o = 0; o < bm.get("bmValueFormula").length; o++) {
						var bmMyVP = bm.get("bmValueFormula").models.at(o);
						var mp = bmMyVP.get("valueFormula");
						var component = bmMyVP.get("valueFormula").get("component");
						if(component.length == 0){
							continue;
						}
						var bmChild = self.createDynamicRow(bmMyVP.get("name"), bmMyVP.get("id"), keyIndex, 2, bmLevel, phaseAltName, self.titleMap[bmMyVP.get('type')],altColumnList);
						var valueLevel = keyIndex;
						if(!dynamicRow.includes(bmChild)){
							dynamicRow.push(bmChild);
							keyIndex++;
						}else {
							valueLevel = bmChild.key;
						}
						if (inputPeriodDataset || resultPeriodDataset) {
							keyIndex = self.checkValues(component.models, valueLevel,dynamicRow, keyIndex, phaseAltName, inputPeriodDataset, resultPeriodDataset, altColumnList);
							var mpStream = bm.get("bmValueStream").findWhere({"persuedProposition":mp});
							if(mpStream){
								var mpActivity = mpStream.get("valueStreamActivity")
								for(var k=0;k<=mpActivity.length;k++){
									var bmAct = bm.get("bmActivity").findWhere({'activity':mpActivity.at(k)});
									if(bmAct){
										keyIndex = fillActivity(bmAct, keyIndex, valueLevel, inputPeriodDataset,resultPeriodDataset, phaseAltName, altColumnList, mpStream)
									}
									
								}
							}
						}
					}
					return keyIndex;
				}
		}

		createDynamicRow(name,id,key,level,parent,phaseAltName,type,altColumnList){
			var self = this;
			var valObj;
			var compSuffix = id.substr(id.lastIndexOf('@') + 1);
			for (var k = 0; k <	self.dynamicRows.length; k++) {
				if (self.dynamicRows[k]["suffix"] == compSuffix) {
					valObj = self.dynamicRows[k];
				}
				if (valObj != null) {
					break;
				}
			}
			if (valObj == null) {
				valObj = {};
				valObj["DT_RowId"] = key;
				valObj["level"] = level;
				valObj["key"] = key;
				valObj["parent"] = parent;
				valObj["name"] = name;
				valObj["type"] = type;
				valObj["suffix"] = compSuffix;
				if(altColumnList && altColumnList.length) {
					for (var j = 0; j < altColumnList.length; j++) {
						var altName = altColumnList[j];
						if(!valObj[altName]){
							valObj[altName] = "-";
						}
					}
				}
			}
			if(phaseAltName){
				valObj[phaseAltName] = "-";
			}
			return valObj;
		}

		

		renderDynamicRows(){
			var self = this;
			var plan = DataManager.getDataManager().get('currentPlan');
			self.dynamicRows = []; 
			
			//---------------- plan section start---------------//
			var altColumnList = [];
			var keyIndex = 1;
			var planObj = self.createDynamicRow(plan.get('name'), plan.get('id'), keyIndex, 0, 0, null ,self.titleMap[plan.get("type")]);
			//planObj["type"] = self.titleMap[plan.get("type")]
			self.dynamicRows.push(planObj);
			keyIndex++;
			var planCriterionSet = plan.getOrCreatePlanCriterionSet();
			
			var planComponents = planCriterionSet.get('component');
				self.periodSets().map((d)=>{
					var phaseAltName = d.year + "-" + d.period;
					altColumnList.push(phaseAltName);
					planObj[phaseAltName] = "-";
					var	 inputPeriodDataset = self.defaultExecutionScenaro.get("input").findWhere({period: d.periodNumber,year: d.year,periodKind : plan.get("periodKind")});
					var resultPeriodDataset = self.defaultExecutionScenaro.get("result").findWhere({period: d.periodNumber,year: d.year,periodKind : plan.get("periodKind")});
					keyIndex = self.checkValues(planComponents.models, 1, self.dynamicRows, keyIndex, phaseAltName, inputPeriodDataset,resultPeriodDataset, altColumnList);
				})				
			
			//---------------- plan section end---------------//
			//---------------- package section start---------------//
			self.getRowData(altColumnList,keyIndex,self.dynamicRows)
				
		}

		getColumnPeriodDataSets(){
			var self = this
			var plan = DataManager.getDataManager().get('currentPlan');
			
			var steps = self.scenario.get("step")
			for (var step of steps.models){
				var periodsLen = step.get('noPeriods');
				var period = step.get("startPeriod");
				for(var l = 0; l < periodsLen; l++){
					if(l != 0){
						period++;
					}
					let yearPeriod = utils.calculateYearPeriod(self.scenario.get("startTime") , period,plan.get("periodKind"));
					//var phaseAltName = phases.at(j).get('name') + "/" + alternatives.at(i).get('name');
					var periodKind =  self.model.getPeriodKinds(yearPeriod.year,yearPeriod.period,plan.get("periodKind"));
					var periodKindText = periodKind?.text || yearPeriod.period;
					self.periodSets().push({year:yearPeriod.year,period:periodKindText,periodNumber:periodKind.value,altId:step.get('alternativeId')})
				}
			}	
		}

		getSelectPeriodOptions(){
			var self =  this;
			const fromObj = self.periodSets().find(d=>d.year===self.selectedFromYear() && d.period === self.selectedFromPeriod());
			const toObj =  self.periodSets().find(d=>d.year===self.selectedToYear() && d.period === self.selectedToPeriod());
			const filteredPeriods = self.periodSets().filter(period => {
				const fromYear = fromObj.year;
				const fromPeriod = fromObj.periodNumber;
				const toYear = toObj.year;
				const toPeriod = toObj.periodNumber;
				
				const isAfterFrom = period.year > fromYear || (period.year === fromYear && period.periodNumber >= fromPeriod);
				const isBeforeTo = period.year < toYear || (period.year === toYear && period.periodNumber <= toPeriod);
				
				return isAfterFrom && isBeforeTo;
			});
			filteredPeriods.map((d)=>{
				var text = `${d.year}-${d.period}`;
				self.selectedPeriodOptions().push({text:text,value:text});
			})

		}

		getPeriodsByFromYear(year){
			var self = this;
			let selectedYear = year
			const periods = self.periodSets().filter(d=>d.year===+selectedYear)
			self.fromPeriods(periods.map(d=>d.period));
			self.selectedFromPeriod(self.fromPeriods()[0])
		}

		getToPeriodsByFromPeriods(period){
			var self = this;
			if(self.selectedFromYear() && self.selectedToYear() && self.selectedFromYear() === self.selectedToYear()){
				var periodObj =  self.periodSets().find((d)=>d.year===self.selectedFromYear()&&d.period===period)
				const periods = self.periodSets().filter(d=>d.year===+self.selectedToYear())
				self.toPeriods(periods.filter(d=>d.periodNumber>=periodObj.periodNumber).map(d=>d.period));
				self.selectedToPeriod(self.toPeriods()[self.toPeriods().length-1])
			}
			
		}

		getPeriodsByToYear(year){
			var self = this;
			let selectedYear = year
			const periods = self.periodSets().filter(d=>d.year===+selectedYear)
			self.toPeriods(periods.map(d=>d.period));
			self.selectedToPeriod(self.toPeriods()[self.toPeriods().length-1])
		}

		reRenderSelectPeriod(){
			var self = this;
			var selectElement = $('#selectPeriod' + self.encodeId);
				selectElement.empty();
	
				self.selectedPeriodOptions().forEach(function(option) {
					selectElement.append($('<option>', {
						value: option.value,
						text: option.text
					}));
				});
				selectElement.multiselect('destroy');
				selectElement.multiselect({
					enableClickableOptGroups: false,
					numberDisplayed: 0,
					nonSelectedText: '0 Selected',
				});
		}

		reRenderTables(table,createTableCallBack){
			var self = this;
			self.destroyTable('#'+table);
			self.getSelectPeriodOptions();
			createTableCallBack()
			$('#'+table).DataTable().draw('page')
		}

		init(model,options){
			var self = this;
			this.Values2ViewModel = this;
			self.model = model;
			this.alternativeModel = options.addOptions;
			this.selectedChartType = ko.observable();
			this.periodSets = ko.observableArray([])
			this.fromYears = ko.observableArray([]);
			this.selectedPeriodOptions = ko.observableArray([]);
			this.selectedScenarioTables =  ko.observableArray([]);
			this.scenarioOptions = ko.observableArray([]);
			this.selectedPeriodValues = ko.observableArray([]);
			this.toPeriods = ko.observableArray([]);
			this.toYears = ko.observableArray([]);
			this.fromPeriods = ko.observableArray([]);
			this.selectedFromYear = ko.observable("");
			this.selectedFromPeriod = ko.observable("");
			this.selectedToYear = ko.observable("");
			this.selectedToPeriod = ko.observable("");
			this.showMultipleScenarioTable = ko.observable(false);
			this.periodKind = DataManager.getDataManager().get('currentPlan').get("periodKind")
			this.encodeId = this.model ? window.utils.htmlEscape(this.model.id) : null;
			this.skipBuiding = true;
			this.enableDirectEditing = true;
			this.showInputValues = ko.observable(true);
			this.showExportButton = ko.observable(true);
			this.titleMap = {};
			this.altMap = {};
			this.yearPeriodMap = {};
			this.titleMap['vdml_Community'] = "Market Segment";
			this.titleMap['vdml_OrgUnit'] = "Enterprise";
			this.titleMap['vdml_Actor'] = "Individual";
			this.titleMap['transformation_Plan'] = "Plan";
			this.titleMap['vdml_Party'] = "Role";
			this.titleMap['vdml_BusinessModel'] = "Business Model";
			this.titleMap['vdml_BusinessModelActivity'] = "Activity";
			this.titleMap['vdml_BusinessNetwork'] = "Participant Network";
			this.titleMap['vdml_BusinessModelValueProposition'] = "Value Proposition";
			this.titleMap['vdml_BusinessModelValueFormula'] = "My Proposition";
			this.titleMap['vdml_Activity'] = "Activity";
			this.titleMap['vdml_ValuePropositionComponent'] = "Value";
			this.titleMap['vdml_ValueAdd'] = "Value";
			this.showViewMap = ko.observable(true);
            if(DataManager.getDataManager().get('debugMode')){
                this.showViewMap(true);
            }
			self.packages = [];
			var dataManager = DataManager.getDataManager();
			var scenarioId = dataManager.get("currentPlan").get("defaultScenario");
			this.scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
			var defaultExecutionScenaroId = this.scenario.get("defaultExecutionScenario");
			this.defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
			self.scenarioOptions().push({text: this.defaultExecutionScenaro.get('name'),value:defaultExecutionScenaroId});
			self.scenarioOptions().push({text: "Target Scenario",value:defaultExecutionScenaroId+"target"});
			var setPackage = function (package1) {	
		    	var packageModel = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: package1.id });
				self.packages.push(packageModel);	
			}
			this.lastCalculate = ko.observable();
			self.showLastCalulatedDate();
			function loadDocuments(results) {
				for (var i = 0; i < results.length; i++) {
					var type = results.at(i).get('type');
					if (type === "vdml_ValueDeliveryModel") {
						setPackage(results.at(i));
					}
				}
			}

			var searchTypes = 'vdml_ValueDeliveryModel';
			var nameNS = 'vbc:cmof_EObject-name';
			var alt = self.model.get("phase").at(0).get("phaseAlternative").at(0)
			DataManager.getDataManager().getAllDocumentsCollectionOfType(alt, searchTypes, loadDocuments, true, null, null, nameNS);
			this.selectedChartType.subscribe(function (selectedType) {
				if(selectedType == "Show Only Values"){
					self.destroyTable('#offlineTable');
					self.renderDynamicRows();
					self.createOfflineTable("offlineTable");
				} else if(self.viewloaded){
					self.destroyTable('#offlineTable');
					self.renderDynamicRows();
					self.createOfflineTable("offlineTable");
				}
			});	
			self.selectedPeriodValues.subscribe(function (newValues) {
				console.log('Selected values: ', newValues); 
			});

			self.getColumnPeriodDataSets()

			const filteredYears = this.periodSets().filter((item, index, self) =>
				index === self.findIndex((t) => t.year === item.year)
			);

			
			// this.periodSets().map(d=>{
			// 	self.selectedPeriodOptions.push({text:d.year+"-"+d.period, value:d.year-d.period})
			// });

			self.fromYears(filteredYears.map(d=>d.year));

			var defaultPeriods = this.scenario.fillDefaultPeriods(this.periodSets(),self.periodKind);
             var defaultPeriod = defaultPeriods.period

			 self.periodSets().map(d=>{
				self.selectedPeriodOptions().push({text:`${d.year}-${d.period}`,value:`${d.year}-${d.period}`})
			 })

			self.selectedFromYear.subscribe(function(value){
				if(value!==undefined){
					self.getPeriodsByFromYear(value)
					var toYears = filteredYears.filter(d=>d.year>=value).map(d=>d.year)
					self.toYears(toYears);
					self.selectedToYear(self.toYears()[self.toYears().length-1]);
					defaultPeriod=undefined
					if(self.viewloaded){
						self.selectedPeriodOptions.removeAll();
						self.selectedPeriodValues.removeAll();
						if(self.showMultipleScenarioTable()){
							self.reRenderTables('fixedColumns',function(){self.createFixedColumnTable('fixedColumns')});
							self.reRenderTables('firstScenarioTable',function(){self.firstScenarioTable('firstScenarioTable')});
							self.reRenderTables('secondScenarioTable',function(){self.firstScenarioTable('secondScenarioTable')});
						}else{
							self.reRenderTables('offlineTable',function(){self.createOfflineTable('offlineTable')});
						}
						
					}
				}
				self.reRenderSelectPeriod()
			});

			self.selectedFromPeriod.subscribe(function(value){
				if(value!==undefined){
					self.selectedFromPeriod(value)
					self.getToPeriodsByFromPeriods(value)

					if(self.viewloaded){
						self.selectedPeriodOptions.removeAll();
						self.selectedPeriodValues.removeAll();
						if(self.showMultipleScenarioTable()){
							self.reRenderTables('fixedColumns',function(){self.createFixedColumnTable('fixedColumns')});
							self.reRenderTables('firstScenarioTable',function(){self.firstScenarioTable('firstScenarioTable')});
							self.reRenderTables('secondScenarioTable',function(){self.firstScenarioTable('secondScenarioTable')});
						}else{
							self.reRenderTables('offlineTable',function(){self.createOfflineTable('offlineTable')});
						}
						
					}
				}
				self.reRenderSelectPeriod()
				
			});

			
			self.selectedToYear.subscribe(function(value){
				if(value!==undefined){
					self.getPeriodsByToYear(value);
					self.selectedToYear(value);
					if(self.viewloaded){
						self.selectedPeriodOptions.removeAll();
						self.selectedPeriodValues.removeAll();
						if(self.showMultipleScenarioTable()){
							self.reRenderTables('fixedColumns',function(){self.createFixedColumnTable('fixedColumns')});
							self.reRenderTables('firstScenarioTable',function(){self.firstScenarioTable('firstScenarioTable')});
							self.reRenderTables('secondScenarioTable',function(){self.firstScenarioTable('secondScenarioTable')});
						}else{
							self.reRenderTables('offlineTable',function(){self.createOfflineTable('offlineTable')});
						}
					}
				}
				self.reRenderSelectPeriod();
			});
			self.selectedToPeriod.subscribe(function(value){
				if(value!==undefined){
					self.selectedToPeriod(value);
					if(self.viewloaded){
						self.selectedPeriodOptions.removeAll();
						self.selectedPeriodValues.removeAll();
						if(self.showMultipleScenarioTable()){
							self.reRenderTables('fixedColumns',function(){self.createFixedColumnTable('fixedColumns')});
							self.reRenderTables('firstScenarioTable',function(){self.firstScenarioTable('firstScenarioTable')});
							self.reRenderTables('secondScenarioTable',function(){self.firstScenarioTable('secondScenarioTable')});
						}else{
							self.reRenderTables('offlineTable',function(){self.createOfflineTable('offlineTable')});
						}
					}
				}
				self.reRenderSelectPeriod();
			});

			self.renderDynamicRows();
			this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['ExportCSV', 'PresentationMode', 'Overview', 'Scenarios', 'Yearly', 'SelectValues']);
		}

		viewMap(){
			var self = this;
			var lastPhase = self.model.get('phase').findWhere({ nextPhase: null });
            var altModel = lastPhase.get('primary');
			var phaseDesignPart = altModel.get("phaseDesignPart");
			for (var k = 0; k < phaseDesignPart.length; k++) {
				var packId = phaseDesignPart.at(k).get("beepReference");
				if (packId.indexOf("Common") == -1 && phaseDesignPart.at(k).get("beepType") === "ecomap_EcoMap") {
					var packageModel = Backbone.Relational.store.getObjectByName("ecomap.EcoMap").find({ id: packId });
					EcoMapMixin.viewMap(EcoNavigatedViewer,packageModel,null);
					break;
				}
			}
		}

		static getInstance(model,options){
			var view = new Values2ViewModel(model,options);
			return view;
		};
	}
	path.Values2ViewModel = Values2ViewModel;
