import { DataManager } from '../../../../../com/vbee/data/DataManager'
import {StrategyMapDesignerViewModel} from './StrategyMapDesignerViewModel'
import {StrategyMapDetails2ViewModel} from './StrategyMapDetails2ViewModel'
import * as ko from 'knockout'
import * as _ from 'underscore'

var path = DataManager.getDataManager().buildAppNsPath("strategyMap.views.properties", global.version);

export class StrategyMapDesigner2ViewModel extends StrategyMapDesignerViewModel{

    
    init(model, options){
        super.init(model,options);
        var self = this;
        var dataManager = DataManager.getDataManager();
        this.selectedYear = ko.observable();
        this.selectedPeriod = ko.observable();
        var currentPlan = dataManager.get('currentPlan');
        this.periodKind = currentPlan.get("periodKind");
        var currentAlternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: dataManager.get('viewAlternative')})
        var scenarioId = currentPlan.get("defaultScenario");
        var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
        this.dataSets = currentAlternative.getPhaseAlternativeSteps(scenario);
        var defaultPeriodList = scenario.fillDefaultPeriods(this.dataSets,self.periodKind);
        self.defaultPeriod = defaultPeriodList?.period
        self.defaultYear = defaultPeriodList?.year;

        var defaultExecutionScenaroId = scenario.get("defaultExecutionScenario");
        self.defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
        //self.loadDatasets(self.defaultPeriod, self.defaultYear);

        
        self.objectiveLabel(DataManager.getDataManager().get('localeManager').get('Targets'));
    }

    loadStrategyValuesChart = function(){
        var self = this;
        var StrategyMapValues2Chart = window.utils.getView('StrategyMapValues2Chart');
        self.chartViewInstance = StrategyMapValues2Chart.getInstance(this.model);
        if (!ko.components.isRegistered('StrategyValuesChart')) {
            ko.components.register("StrategyValuesChart", {
                viewModel: { instance: self.chartViewInstance },
                template: window.utils.getHtmlPage("StrategyMapValuesChartTemplate"),
                synchronous: true
            });
        }
    }
    loadStrategyMapDetails = function(){
        var self = this;
        self.detailsViewInstance = StrategyMapDetails2ViewModel.getInstance(this.model);
        if (!ko.components.isRegistered('StrategyMapDetails')) {
            ko.components.register("StrategyMapDetails", {
                viewModel: { instance: self.detailsViewInstance },
                template: window.utils.getHtmlPage("StrategyMapDetails2Template"),
                synchronous: true
            });
        }
    }
    loadStrategyObjectives = function(){
        var self = this;
        var targetViewModel = window.utils.getView('StrategyMapTargetViewModel')
        self.strategyMapTargetViewModel = targetViewModel.getInstance(this.model);
        if (!ko.components.isRegistered("StrategyMapTargetTemplate")) {
            ko.components.register("StrategyMapTargetTemplate", {
                viewModel : { instance: self.strategyMapTargetViewModel },
                template: window.utils.getHtmlPage("StrategyMapTargetTemplate"),
                synchronous : true
            })
        }
    }

    static getInstance(model, options) {
        var view = new StrategyMapDesigner2ViewModel();
        view.init(model, options);
        return view;
    };
}


path.StrategyMapDesigner2ViewModel = StrategyMapDesigner2ViewModel;