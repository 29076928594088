import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasureRelationship} from './MeasureRelationship'
import {MeasureRelationshipMixin} from './MeasureRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { Measure } from './Measure'
import { Operation } from './Operation'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasureRelationship","appbo/smm/MeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasureRelationship,MeasureRelationshipMixin
, Measure

, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Measure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measure){
			Measure = importPath.Measure;
		}
        else {
            import('./Measure').then(({ default: Measure }) => {
                Measure = Measure;
            });
			/*require(["appbo/smm/Measure"],function(loadedModule){
				Measure = loadedModule;
			});*/
		}
	}
	
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
        else {
            import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });
			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	export class EquivalentMeasureRelationshipMixin {
	
	defaults(){
		var ret = {
			type: "smm_EquivalentMeasureRelationship"
		}
		return jQuery.extend(MeasureRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!EquivalentMeasureRelationshipMixin.cummulativeRelations) {
            EquivalentMeasureRelationshipMixin.cummulativeRelations = _.union(EquivalentMeasureRelationshipMixin.getMixinRelations()
                , MeasureRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return EquivalentMeasureRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_MeasureRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "influence",type : "Influence",defaultValue : "null",containingClass : "smm_MeasureRelationship" }
		]
	}
	getParent(){
		var container;
		return MeasureRelationship.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return MeasureRelationship.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/EquivalentMeasureRelationshipPropertiesTemplate.html",
			templateName : "EquivalentMeasureRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/EquivalentMeasureRelationshipViewModel",
			tabId : "EquivalentMeasureRelationshipView",
			tabName: "EquivalentMeasureRelationship"
		}
        }
    }
	path.EquivalentMeasureRelationshipMixin = EquivalentMeasureRelationshipMixin;
	
	//return EquivalentMeasureRelationshipMixin;
//});