import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class AttributeMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.AttributeMixin = AttributeMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_Attribute"
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!AttributeMixin.cummulativeRelations) {
            AttributeMixin.cummulativeRelations = _.union(AttributeMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return AttributeMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
		return [
			{name : "tag",type : "EString",defaultValue : "null",containingClass : "vdml_Attribute" },
			{name : "value",type : "EString",defaultValue : "null",containingClass : "vdml_Attribute" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("attributeOwner") ? this.get("attributeOwner") : this.previousAttributes().attributeOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("attributeOwner") ? this.get("attributeOwner") : this.previousAttributes().attributeOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/AttributePropertiesTemplate.html",
			templateName : "AttributePropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/AttributeViewModel",
			tabId : "AttributeView",
			tabName: "Attribute"
		}
	}
	
	}
	path.AttributeMixin = AttributeMixin;
	//return AttributeMixin;
//});