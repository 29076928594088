import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {CapabilityDefinition} from './CapabilityDefinition'
import {BusinessItemDefinition} from './BusinessItemDefinition'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, BusinessItemDefinition

, CapabilityDefinition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!BusinessItemDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemDefinition){
			BusinessItemDefinition = importPath.BusinessItemDefinition;
		}
		else{
			import('./BusinessItemDefinition').then(loadedModule => {
				//BusinessItemDefinition = loadedModule;
			});
			/*require(["appbo/vdml/BusinessItemDefinition"],function(loadedModule){
				BusinessItemDefinition = loadedModule;
			});*/
		}
	}
	if(!CapabilityDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityDefinition){
			CapabilityDefinition = importPath.CapabilityDefinition;
		}
		else{
			import('./CapabilityDefinition').then(loadedModule => {
				//CapabilityDefinition = loadedModule;
			});
			/*require(["appbo/vdml/CapabilityDefinition"],function(loadedModule){
				CapabilityDefinition = loadedModule;
			});*/
		}
	}
	export class CapabilityDependencyMixin {

	defaults(){
		var ret = {
			type: "vdml_CapabilityDependency"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!CapabilityDependencyMixin.cummulativeRelations) {
            CapabilityDependencyMixin.cummulativeRelations = _.union(CapabilityDependencyMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return CapabilityDependencyMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "isTangible",type : "EBoolean",defaultValue : "null",containingClass : "vdml_CapabilityDependency" },
			{name : "isFromExternalSource",type : "EBoolean",defaultValue : "null",containingClass : "vdml_CapabilityDependency" },
			{name : "isForExternalTarget",type : "EBoolean",defaultValue : "null",containingClass : "vdml_CapabilityDependency" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("capabilityDependencyOwner") ? this.get("capabilityDependencyOwner") : this.previousAttributes().capabilityDependencyOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("capabilityDependencyOwner") ? this.get("capabilityDependencyOwner") : this.previousAttributes().capabilityDependencyOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/CapabilityDependencyPropertiesTemplate.html",
			templateName : "CapabilityDependencyPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/CapabilityDependencyViewModel",
			tabId : "CapabilityDependencyView",
			tabName: "CapabilityDependency"
		}
	}
	
	}
	path.CapabilityDependencyMixin = CapabilityDependencyMixin;
	//return CapabilityDependencyMixin;
//});