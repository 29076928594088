import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VDMLDiagram} from './VDMLDiagram'
import {VDMLDiagramMixin} from './VDMLDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VDMLDiagram","appbo/vdml/VDMLDiagramMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VDMLDiagram,VDMLDiagramMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class CapabilityLibraryDiagramMixin {

    defaults() {
        var ret = {
            type: "vdml_CapabilityLibraryDiagram"
        }
        return jQuery.extend(VDMLDiagram.prototype.defaults.apply(this), ret);
    }
    static getMixinRelations() {
        return _.union([
            {
                type: Backbone.HasOne,
                containingClass: "vdml_CapabilityLibraryDiagram",
                key: "capabilityLibrary",
                relatedModel: "vdml.CapabilityLibrary",
                includeInJSON: Backbone.Model.prototype.idAttribute,
                reverseRelation: {
                    key: "capabilityLibraryDiagrams",
                    type: Backbone.HasMany,
                    includeInJSON: "id"
                }
            },
            {
                type: Backbone.HasMany,
                containingClass: "vdml_CapabilityLibraryDiagram",
                key: "capabilities",
                relatedModel: "vdml.Capability",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            },
            {
                type: Backbone.HasMany,
                containingClass: "vdml_CapabilityLibraryDiagram",
                key: "capability",
                relatedModel: "vdml.Capability",
                includeInJSON: Backbone.Model.prototype.idAttribute,
            }
        ]);
    };
	static getCumulativeMixinRelations(){
		if (!CapabilityLibraryDiagramMixin.cummulativeRelations) {
            CapabilityLibraryDiagramMixin.cummulativeRelations = _.union(CapabilityLibraryDiagramMixin.getMixinRelations()
                , VDMLDiagramMixin.getCumulativeMixinRelations()
            );
        }
		return CapabilityLibraryDiagramMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_CollaborationDiagram"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "progress", type: "double", defaultValue: "0", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "showNameAndDescription",type : "EString",defaultValue : "null",containingClass : "vdml_CapabilityLibraryDiagram" }
		]
    }
    calculateProgress() {

    }
	getParent(){
		var container;
		if(!container){
			container = this.get("diagramOwner") ? this.get("diagramOwner") : this.previousAttributes().diagramOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("diagramOwner") ? this.get("diagramOwner") : this.previousAttributes().diagramOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
			return CapabilityLibraryDiagramMixin.getDialogViewProperties(type);
	}
	handleRemoveCapabilities(model, coll, options) {
		var self = this;
		var xml = this.get('data');
		if (!xml || DataManager.getDataManager().get('suppressLogging'))
			return;
		var xmlDoc = jQuery.parseXML(xml);
		var $xml = jQuery(xmlDoc);
		var nodes = $xml.find('[mid="' + model.id + '"]')
		//var re = new RegExp(model.id, 'g');
		var parentId = model.getNestedParent().id;
		//var pre = new RegExp(parentId, 'g');
		if (options && options.creatingRevision && options.revisionCollectionNewId) {
			var parentRep = DataManager.getDataManager().getRepositoryId(options.revisionCollectionNewId);
			//var parentSuffix = parentId.substr(parentId.indexOf('@') + 1);
			var parentSuffix = window.utils.getSuffix(parentId);
			//xml = xml.replace(re, options.revisionCollectionNewId);
			//xml = xml.replace(pre, parentRep + parentSuffix);
			nodes.each(function (callback, node) {
				node.setAttribute('mid', options.revisionCollectionNewId);
				node.setAttribute('mpid', parentRep + parentSuffix);
			})
		} else {
			nodes.each(function (callback, node) {
				node.setAttribute('mid', '');
				node.setAttribute('mpid', '');
			});
		}
		xml = (new XMLSerializer()).serializeToString(xmlDoc);
		self.set('data', xml);
	};
	static getDialogViewProperties(type){
		if (type && type === "ShapeProperties") {
	        return {
	            templatePath: "views/capabilityLibrary/views/designer/ShapePropertiesTemplate.html",
	            templateName: "ShapePropertiesTemplate",
	            viewTypeStr: "appviews/capabilityLibrary/views/designer/ShapePropertiesViewModel",
	            tabId: "ShapePropertiesView",
	            tabName: "ShapeProperties"
	        }
	    }else{
			return {
				templatePath : "views/capabilityLibrary/views/designer/CapabilityLibraryDiagramTemplate.html",
				templateName : "CapabilityLibraryDiagramTemplate",
				viewTypeStr : "appviews/capabilityLibrary/views/designer/CapabilityLibraryDiagramViewModel",
				tabId : "CapabilityLibraryDiagramView",
				tabName: "CapabilityLibraryDiagram"
			}
		}
	}
	
	}
	path.CapabilityLibraryDiagramMixin = CapabilityLibraryDiagramMixin;
	//return CapabilityLibraryDiagramMixin;
//});