import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BMCanvasDiagramMixin} from './BMCanvasDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {BMCanvasBlock} from './BMCanvasBlock'
import {BMCanvasItem} from './BMCanvasItem'
import {VdmlElement} from '../vdml/VdmlElement'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/canvas/BMCanvasDiagramMixin","appbo/canvas/BMCanvasBlock","appbo/canvas/BMCanvasItem","appbo/vdml/BusinessModel","appbo/vdml/AnalysisContext"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,BMCanvasDiagramMixin,BMCanvasBlock,BMCanvasItem,BusinessModel,AnalysisContext
//){
	
	var path = DataManager.getDataManager().buildAppNsPath("canvas",global.version);
	var BMCanvasDiagram = VdmlElement.extend(utils.customExtends({
		relations:BMCanvasDiagramMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new BMCanvasDiagramMixin()
	));
	BMCanvasDiagram.getInstance = function(canvasType,name,owner,bm,context,description){
		if(!canvasType){
			return;
		}
		if(canvasType){
	        var cid = DataManager.getDataManager().guidGeneratorByOwner(owner);
	        var canvas;
	        if(owner.get('type') === 'vdml_ValueDeliveryModel'){
	        	canvas = new BMCanvasDiagram({id:cid,name:owner.getUniqueProperty('name',name,'canvas'),description:owner.getUniqueProperty('description',description,'canvas'),canvasOwner:owner});
	        }else{
	        	canvas = new BMCanvasDiagram({id:cid,name:owner.getUniqueProperty('name',name,'diagram'),description:owner.getUniqueProperty('description',description,'diagram'),diagramOwner:owner});
	        }
			
			//var config = DataManager.getDataManager().canvasModules[DataManager.getDataManager().get('currentPlan').id][canvasType];
			var config = window.utils.getCanvasJson(canvasType);
			if(config){
				//var configObj = JSON.parse(config);
				this.dimensions = config.dimensions;
			}
			else{
				return;
			}
			//canvas.set('context',context);
			var isSwat = false;
			if(canvasType === "SWOTAnalysisCanvas"){
				isSwat = true;
				canvas.set('colors',true);
			}
			canvas.set({'colors':isSwat,'canvasType':canvasType,'businessModel':bm});
			
			_.each(this.dimensions,function(dimension){
				cid = DataManager.getDataManager().guidGeneratorByOwner(owner);
				new BMCanvasBlock({id:cid,name:dimension.name,bmCanvasBlockOwner:canvas});
			});
		}
		return canvas;
	}
	BMCanvasDiagram.prototype.getSystemTags = function(){
	    return ['VDM', 'Business Canvas', 'Discover'];
	};	
	BMCanvasDiagram.abstract = false;
	path.BMCanvasDiagram = BMCanvasDiagram;
	export {BMCanvasDiagram};