import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {UserPreferences} from '../../../../../version1/bo/preference/UserPreferences'
import {DataManager} from '../../../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("tickets.views.properties",global.version);
export class ProductListViewModel{

	/*fetchCloudContent(callback){
		var self = this;
		var xhttp = new XMLHttpRequest();
		//window.utils.startSpinner('fetchingIndexFromCloud','Fetching list from Cloud');
		xhttp.onreadystatechange = function() {
			if (xhttp.readyState === 4) {
				if(xhttp.status === 200){
					var fileObject = this.response;
					var configObj = JSON.parse(fileObject);
					var productExtensions = configObj.productExtensions;	
					//window.utils.stopSpinner('fetchingIndexFromCloud','Fetching list from Cloud');
					callback(productExtensions);
				}else{
					window.utils.stopSpinner('fetchingIndexFromCloud','Fetching list from Cloud');
					bootbox.alert("Unable to fetch from Cloud");
					return;
				}
			}
		};
		var hostBaseUrl = DataManager.getDataManager().get('vmpServerHost');
		xhttp.open("GET",hostBaseUrl  +  "/wp-content/vmp-library/index/CloudContent.json");
		xhttp.setRequestHeader("Content-Type", "application/json");
		xhttp.send();*/
		
		/*$.get("locale/CloudContent.json",function(config){
			var configObj = config;
			if (typeof config !== "object") {
				configObj = JSON.parse(config);
			}
			var productExtensions = configObj.productExtensions;
			callback(productExtensions);
		}).fail(function(config){
			var configObj = config;
			if (typeof config !== "object") {
				configObj = JSON.parse(config);
			}
			var productExtensions = configObj.productExtensions;
			callback(productExtensions);
		});*/
	//};

	afterRenderView(node, view) {
		var self = view;
		self.statusDiv = $("#status");
		self.productsOutput = $('#productsOutput');
		//if(DataManager.getDataManager().get('useAzure')){
			if(DataManager.getDataManager().get("currentLegalEntity") && DataManager.getDataManager().get("currentLegalEntity")["name"]){
				$('#productTitle').html('Product List for '+DataManager.getDataManager().get("currentLegalEntity")["name"]);
				//$("#googleText").hide();
				//$("#googleText").html("* per User");
			}
			self.onSkuDetails();
		/*}else {
			DataManager.getDataManager().getProductList({'success': self.onSkuDetails,'error': self.onSkuDetailsFailed});
		}*/
		//self.onSkuDetails(JSON.parse('{"response":{"details":{"kind":"chromewebstore#inAppProductList","inAppProducts":[{"kind":"chromewebstore#inAppProduct","sku":"kljoaohhfdfinfijeafmpbcbfeclichj_01","item_id":"kljoaohhfdfinfijeafmpbcbfeclichj","type":"subs","state":"ACTIVE","prices":[{"valueMicros":"60000000","currencyCode":"INR","regionCode":"IN"}],"localeData":[{"title":"Enterprise Edition (old and mistake)","description":"Unlimited version of the VDMbee Value Management Platform, expandable with Value Management Modules.","languageCode":"all"}]},{"kind":"chromewebstore#inAppProduct","sku":"kljoaohhfdfinfijeafmpbcbfeclichj_02","item_id":"kljoaohhfdfinfijeafmpbcbfeclichj","type":"subs","state":"ACTIVE","prices":[{"valueMicros":"2134000000","currencyCode":"INR","regionCode":"IN"}],"localeData":[{"title":"Enterprise Edition","description":"Unlimited version of the VDMbee Value Management Platform, expandable with Value Management Modules","languageCode":"all"}]}]}}}'));
	};

	addAzureProductToUI(product) {
		var self = this;
		function addToTable(title,desc,labelName,disableClick,purchaseId,uom,price,productId){
			var row = $("<tr></tr>");
			var colDesc = $("<td></td>").text(desc);
			var colPrice = $("<td width=\"18%\"></td>").text(price);
			var text = labelName;
			var butAct = $("<button type='button'></button>")
			.data("sku", uom)
			.attr("id", purchaseId)
			.attr("productId", productId)
			.attr("productName", title)
			.addClass("btn btn-sm ")
			.prop('disabled', disableClick)
			.click(self.onActionButton)
			.text(text)
			.addClass("btn-success");
			if(uom){
				//title = title.concat('('+uom+')');
			}
			var colName = $("<td></td>").text(title);
			var butInfo = $("<button type='button'></button>")
			.data("url", "http://vdmbee.com/work/value-management-platform")
			.addClass("btn btn-sm")
			.click(self.onShowInfo)
			.text("Info")
			.addClass("btn-success");
			/*var buttonGuidance = $("<button type='button'></button>")
			.data("url", "https://vdmbee.com/wp-content/vmp-library/guides/User Guide VMP.pdf")
			.addClass("btn btn-sm")
			.click(self.onShowGuidance)
			.text("User Guide")
			.addClass("btn-success");*/

			var colBut1 = $("<td></td>").append(butAct);
			var colBut2 = $("<td></td>").append(butInfo);
			//var colBut3 = $("<td></td>").append(buttonGuidance);
			row
			.append(colName)
			.append(colDesc)
			.append(colPrice)
			.append(colBut1)
			.append(colBut2)
			//.append(colBut3);
			$("#productsList").append(row);
		}
		
		var prodButPrefix = "btnProdID-";
		var title = product.name.replace(/[^a-zA-Z ]/g, "");
		var desc = title;
		var disableYearClick = false;
		var disableMonthClick = false;
		var monthChangeLabel = "Subscribe";
		var yearChangeLabel = "Subscribe";
		var monthPurchaseId =  "";
		var yearPurchaseId = "";
		var purchasedMonthDisable = "";
		var purchasedYearDisable = "";
		var isVdmbeeUniversity = false;
		if(title == "Trialpublic"){
			//purchasedMonthDisable = "disabled";
			disableMonthClick = true;
			addToTable(title,desc,"Free",purchasedMonthDisable,"","","");
		}else {
			var productList = DataManager.getDataManager().get('products');
			var currentLegalEntity = DataManager.getDataManager().get("currentLegalEntity");
			if(productList && currentLegalEntity){
				if(currentLegalEntity['parentEntityId'] == window.university){
					isVdmbeeUniversity = true;
				}
				for(var i=0;i<productList.length;i++){
					if(productList[i].name == product.name){
						desc = productList[i].description;
						yearPurchaseId = productList[i].subscriptionId;
						yearChangeLabel = "Active";
						/*var legalList = productList[i].legalEntityList;
						for(var j=0;j<legalList.length;j++){
							if(legalList[j].entityId == currentLegalEntity["entityId"]){
								if(legalList[j].uom == "Month"){
									if(legalList[j].purchaseId){
										monthChangeLabel = "Active";
										monthPurchaseId = legalList[j].purchaseId;
									}
								}else {
									if(legalList[j].purchaseId){
										yearChangeLabel = "Active";
										yearPurchaseId = legalList[j].purchaseId;
									}
								}
								//break;
							}
						}*/
						/*if(disableClick){
							break;
						}*/
					}
				}
			}		
			if(DataManager.getDataManager().get("currentLegalEntity") && DataManager.getDataManager().get("currentLegalEntity")["entityId"] == window.vdmbee){
				disableMonthClick = true;
				disableYearClick = true;
			}
			var monthPrice = "49 \u20AC/mo (*)";
			var	yearPrice = "200 \u20AC/yr (*)";
			if(isVdmbeeUniversity){
				monthPrice = "Free";
				yearPrice = "Free";
			}
			var selfLink = product._links.self.href;
            var productId = selfLink.substr(selfLink.lastIndexOf("/") + 1);
			//addToTable(title, title, monthChangeLabel, disableMonthClick, monthPurchaseId, 'Month',monthPrice);
			addToTable(title, title, yearChangeLabel, disableYearClick, yearPurchaseId, 'Year',yearPrice,productId);
		}
	};
	
	/*addProductToUI(product,showCommunityEdition,productExtensionsJson) {
		var self = this;
			var prodButPrefix = "btnProdID-";
			var title = product.localeData[0].title.replace(/[^a-zA-Z ]/g, "");
			if(showCommunityEdition) {
				var row1 = $("<tr></tr>");
				var colName1 = $("<td></td>").text("Community Edition");
				var colDesc1 = $("<td></td>").text("Community Edition");
				var colPrice1 = $("<td></td>").text(""+productExtensionsJson["Community Edition"].duration);
				var butAct1 = $("<button type='button'></button>")
				.data("sku", product.sku)
				.attr("id", "community" + prodButPrefix + product.sku)
				.addClass("btn btn-sm disabled")
				.text("Purchased");
				var butInfo1 = $("<button type='button'></button>")
				.data("url", productExtensionsJson["Community Edition"].infoUrl)
				.addClass("btn btn-sm")
				.click(self.onShowInfo)
				.text("Info")
				.addClass("btn-success");
				var colBut1 = $("<td></td>").append(butAct1);
				var colBut2 = $("<td></td>").append(butInfo1);
				//var colBut3 = $("<td></td>").append(buttonGuidance1);
				row1.append(colName1)
				.append(colDesc1)
				.append(colPrice1)
				.append(colBut1)
				.append(colBut2)
				//.append(colBut3);
					$("#productsList").append(row1);
			}   
			var row = $("<tr></tr>");
			var colName = $("<td></td>").text(title);
			var colDesc = $("<td></td>").text(title);
			var price = parseInt(product.prices[0].valueMicros, 10) / 1000000;
			
			var duration = productExtensionsJson[title].duration;
			var colPrice = $("<td></td>").text(product.prices[0].currencyCode + ' '+price + duration).css('white-space','nowrap');
			var butAct = $("<button type='button'></button>")
			.data("sku", product.sku)
			.attr("id", prodButPrefix + product.sku)
			.addClass("btn btn-sm")
			.click(self.onActionButton)
			.text("Subscribe")
			.addClass("btn-success");
			var butInfo = $("<button type='button'></button>")
			.data("url", productExtensionsJson[title].infoUrl)
			.addClass("btn btn-sm")
			.click(self.onShowInfo)
			.text("Info")
			.addClass("btn-success");

			var colBut1 = $("<td></td>").append(butAct);
			var colBut2 = $("<td></td>").append(butInfo);
			//var colBut3 = $("<td></td>").append(buttonGuidance);
			row
			.append(colName)
			.append(colDesc)
			.append(colPrice)
			.append(colBut1)
			.append(colBut2)
			//.append(colBut3);
			$("#productsList").append(row);
	};*/
	
	/*function lowerCaseFirstLetter(string) {
		return string.charAt(0).toLowerCase() + string.slice(1);
	}*/
	onShowInfo(evt) {
		var infoButton = $(evt.currentTarget);
		var url = infoButton.data("url");
		
		if (url) {
			window.open(url, "Product Information");
		}
	};
	/*onShowGuidance(evt) {
		var guideButton = $(evt.currentTarget);
		var url = guideButton.data("url");
		
		if (url) {
			if (!window.utils.checkChrome()) {
				var BrowserWindow = require('electron').remote.BrowserWindow;
				var PDFWindow = require('electron-pdf-window');
				var win = new BrowserWindow({ width: 700, height: 800, icon: './img/VDMBee-bij 96px.png' });
				PDFWindow.addSupport(win);
				win.loadURL(url);
			}else{
				window.showUrlDialog(url, "Extension Information");
			}
		}
	};*/

	onActionButton(evt) {
		var self = this;
		//if(DataManager.getDataManager().get('useAzure')){
			if(!DataManager.getDataManager().get("currentLegalEntity") || !DataManager.getDataManager().get("currentLegalEntity")["entityId"]){
				return;
			}
			var uom = $(evt.currentTarget).data("sku");
			var purchaseId = $(evt.currentTarget).attr('id');
			var productId = $(evt.currentTarget).attr('productId');
			var productName = $(evt.currentTarget).attr('productName');
			if(!purchaseId){
				$.get("locale/TermsAndConditions.html", function (data) {
					bootbox.confirm({
						title: "Terms And Conditions",
						message: data,
						className: "bootboxModal",
						buttons: {
							cancel: {
								label: '<i class="fa fa-times"></i> Cancel'
							},
							confirm: {
								label: '<i class="fa fa-check"></i> Confirm'
							}
						},
						callback: function (result) {
							if (result) {
								var params = {};
								params.invoicePeriodUOM = uom;
								//params.product = product; in future
								$(evt.currentTarget).prop('disabled',true);
								//window.utils.startSpinner('VMPPurchaseSpinner', "Purchasing VMP Enterprise Edition...");
								DataManager.getDataManager().get('vmpServerService').postAllPromise("/vdmbee/purchase/generatepurchaseorder/" + DataManager.getDataManager().get("currentLegalEntity")["entityId"]+"/product/"+productId, JSON.stringify(params)).then(function (data) {
									if (data) {
										DataManager.getDataManager().getLicenses({
											'success': function(response){
												UserPreferences.setUserPreference(window.plansKey,'License','License',JSON.stringify(response));
												UserPreferences.setUserPreference(window.plansKey,'LicenseTimestamp','LicenseTimestamp',new Date().getTime());
												DataManager.getDataManager().onLicenseUpdate(response);
												//window.utils.stopSpinner('VMPPurchaseSpinner');	
												//self.cleanModal();
												bootbox.alert("You are currently subscribed for "+productName+"(" + params.invoicePeriodUOM + ")");
												$(evt.currentTarget).text('Active');
												$(evt.currentTarget).prop('disabled',false);
											},'error': function(response){
												//window.utils.stopSpinner('VMPPurchaseSpinner');
												bootbox.alert('Purchase Order has been created');
											}
										},false,true);
									} else {
										//window.utils.stopSpinner('VMPPurchaseSpinner');
										bootbox.alert('Purchase Order Already Exists');
									}
									//self.progress();
								}).catch(function (request) {
									//window.utils.stopSpinner('VMPPurchaseSpinner');
									if (request.response.apierror) {
										//window.utils.stopSpinner('VMPPurchaseSpinner');
										alert(request.response.apierror.message);
									}
								});
							}else {
								$(evt.currentTarget).removeAttr("disabled");
							}
						}
					});
				});
			}
			else{
				var params = {};
				params.invoicePeriodUOM = uom;
				DataManager.getDataManager().get('vmpServerService').getAllPromise("/vdmbee/purchase/" + purchaseId).then(function (data) {
					if (data) {
						var htmlContent = "<table class = 'table table-bordered'><tr><td>Name</td><td>"+data.name+"";
						htmlContent += "<tr><td>Purchased By</td><td>"+data.createdBy+"</td></tr>";
						htmlContent +=  "<tr><td>Purchase Date</td><td>"+window.utils.convertToSimpleDate(data.purchaseDate)+"</td></tr>";
						htmlContent +=  "<tr><td>End Date</td><td>"+window.utils.convertToSimpleDate(data.endDate)+"</td></tr></table>";
						bootbox.dialog({
							title: "<div>&nbsp;&nbsp;Details </div>",
							message: htmlContent,
							buttons: {
								main: {
									label: "Close",
									className: "btn btn-default"
								},
								success: {
									label: "Unsubscribe",
									className: "btn btn-danger unSubscribe",
									callback: function () {
										bootbox.confirm("Do you want stop this subscription", function (result) {
											if (result) {
												var spinnerText = "Stopping Subscription...";
												//window.utils.startSpinner('unsubscribe', spinnerText);
												DataManager.getDataManager().get("vmpServerService").postAllPromise("/vdmbee/purchase/endSubscription/" + productId, null, "PUT").then(function(data) {
													if(data){
														var message = "Your subscription ends on "+ window.utils.convertToSimpleDate(data.endDate);
														DataManager.getDataManager().getLicenses({
															'success': function(response){
																UserPreferences.setUserPreference(window.plansKey,'License','License',JSON.stringify(response));
																UserPreferences.setUserPreference(window.plansKey,'LicenseTimestamp','LicenseTimestamp',new Date().getTime());
																DataManager.getDataManager().onLicenseUpdate(response);
																//window.utils.stopSpinner('unsubscribe');
																//self.cleanModal();
																bootbox.alert(message);
																//$(evt.currentTarget).text('Purchase');
																//$(evt.currentTarget).prop('disabled',false);
															},'error': function(response){
																//window.utils.stopSpinner('unsubscribe');
																bootbox.alert(message);
															}
														},false);
													}
												}).catch(function (request) {
													if (request.response.apierror) {
														//window.utils.stopSpinner('unsubscribe');
														alert(request.response.apierror.message);
													}
												});
											}
										});
									}
								}
							}
						})
						$('.unSubscribe').css('float','left');
					}
				})
			}
		/*}else {
			console.log("onActionButton", evt);
			var actionButton = $(evt.currentTarget);
			if (actionButton.data("license")) {
			//self.showLicense(actionButton.data("license"));
			} else {
			var sku = actionButton.data("sku");
			DataManager.getDataManager().buyProduct(sku,{'success': self.onPurchase,'error': self.onPurchaseFailed});
			}
		}*/
	};
	/*showLicense(license) {
		var self = this;
		console.log("showLicense", license);
		var modal = $("#modalLicense");
		modal.find(".license").text(JSON.stringify(license, null, 2));
		modal.modal('show');
		self.productsOutput.val(self.productsOutput.val() + JSON.stringify(license, null, 2));
	};	*/	
	onSkuDetails(response) {
		var self = this;
		//if(DataManager.getDataManager().get('useAzure')){
			//window.utils.startSpinner('VMPPurchaseLoadSpinner', "Purchasing VMP Enterprise Edition...");
			DataManager.getDataManager().get('vmpServerService').getAllPromise("/vdmbee/product").then(function(data) {
					for(var i = data._embedded.product.length-1; i > 0 ; i--){
						var product = data._embedded.product[i];
						if(product.isPurchasable){
							self.addAzureProductToUI(product);
						}
					}
					//window.utils.stopSpinner('VMPPurchaseLoadSpinner');
			});
		/*}else {
			self.fetchCloudContent(function(productExtensionsJson){
				var products = response.response.details.inAppProducts;
				var count = products.length;
				for (var i = 0; i < count; i++) {
					var product = products[i];
					var showCommunityEdition = false;
					if(i === 0){
						showCommunityEdition = true;
					}
					self.addProductToUI(product,showCommunityEdition,productExtensionsJson);
				}
				self.statusDiv.text("");
				self.productsOutput.val(self.productsOutput.val() + JSON.stringify(response));
				DataManager.getDataManager().getLicenses({'success': self.onLicenseUpdate,'error': self.onLicenseUpdateFailed});
			});
		}*/
	};
	
	/*onSkuDetailsFailed(response) {
		var self = this;
		console.log("onSkuDetailsFailed", response);
		//self.statusDiv.text("Error retreiving product list. (" + response.response.errorType + ")");
		self.statusDiv.text("*Please Sign in to your Chrome browser to get product List.");
		self.productsOutput.val(self.productsOutput.val() + JSON.stringify(response));
	};*/

	/*****************************************************************************
	* Get the list of purchased products from the Chrome Web Store
	*****************************************************************************/
	
	/*onLicenseUpdate(response) {
		var self = this;
		console.log("onLicenseUpdate", response);
		var licenses = response.response.details;
		var count = licenses.length;
		for (var i = 0; i < count; i++) {
			var license = licenses[i];
			self.addLicenseDataToProduct(license);
		}
		self.statusDiv.text("");
		self.productsOutput.val(self.productsOutput.val() + JSON.stringify(response));
		if(count === 0 && DataManager.getDataManager().isEnterpriseEdition()){
			var license = {};
			license.sku[0] = "090db416-81f8-4829-a639-804f9b49663f";
			license.sku[1] = "9678867d-5772-4021-9253-441bda53c180";
			license.sku[2] = "1d0eda4e-495b-4236-a9a8-7efbb18e5e0f";
			license.sku[3] = "ca431aaa-c384-4bea-bb56-4f5fb2f19fe8";
			license.sku[4] = "3ba45e7e-3650-4a38-9b61-4e0376073106";
			license.sku[5] = "36ed492a-6fab-4c8d-8b18-c32523add0cf";
			license.sku[6] = "10519f44-cde7-416f-a483-05d42c6ef6a1";
			license.sku[7] = "3b2015c0-9f15-4940-bebe-de23ddc3fca6";
			license.sku[8] = "bc90ed28-bfd8-4141-97d1-b07fcca56741";
			license.sku[9] = "3b869cf7-6b75-4f70-9f1f-e97c057208c5";
			license.sku[10] = "e665ae9d-cfab-4743-a6c9-54efc3d60bc5";
			license.sku[11] = "bbb4485e-636c-4037-bd47-1bcf11d6d8e6";		
			self.addLicenseDataToProduct(license);
			
		}
	};
	addLicenseDataToProduct(license) {
		var self = this;
		var prodButPrefix = "btnProdID-";
		var butAction = $("#" + prodButPrefix + license.sku);
		butAction
			.text("Purchased")
			.removeClass("btn-success")
			.removeClass("btn-default")
			.addClass("disabled")
			.data("license", license);
	}		
	onLicenseUpdateFailed(response) {
		var self = this;
		console.log("onLicenseUpdateFailed", response);
		self.statusDiv.text("Error retreiving list of purchased products.");
		self.productsOutput.val(self.productsOutput.val() + JSON.stringify(response));
	};*/
	
	/*****************************************************************************
	* Purchase an item
	*****************************************************************************/
	/*onPurchase(purchase) {
		var self = this;
		console.log("onPurchase", purchase);
		var jwt = purchase.jwt;
		var cartId = purchase.request.cardId;
		var orderId = purchase.response.orderId;
		self.statusDiv.text("Purchase completed. Order ID: " + orderId);
		bootbox.alert('Payment Successful');
		DataManager.getDataManager().getLicenses({'success': self.onLicenseUpdate,'error': self.onLicenseUpdateFailed});
		self.productsOutput.val(self.productsOutput.val() + JSON.stringify(purchase));
	};
	
	onPurchaseFailed(purchase) {
		var self = this;
		console.log("onPurchaseFailed", purchase);
		var reason = purchase.response.errorType;
		//self.statusDiv.text("*Purchase failed.");
		var message = '<br/> In Case you have Issues Please Follow these steps <br/> <br/> 1) Log out chrome gmail and clear your browser cache. <br/>';
		message = message + "2) Open Google Chrome, go to settings / extensions and disable them all (you can enable them later) <br/>";
		message = message + "3) Update Chrome to latest version <br/>";
		message = message + "4) Login to chrome with you gmail account <br/>";
		message = message + "5) Now install VMP by downloading from store and try <br/>";
		message = message + "5) Go to extensions and purchase...after payment is done successfully(With out error), reopen the extensions dialog. You should now see the status purchased.";
		bootbox.alert('Payment Unsuccessful <br/>'+message);
		self.productsOutput.val(self.productsOutput.val() + JSON.stringify(purchase));
	};	*/	
	
	
	cleanModal(){
		var self = this;
		window.cleanDialogModel(self.encodeId,self);
	};		
	init(model, options,detailedView){
		var self = this;
		this.ProductListViewModel = this;
		this.model = model;
		this.editable = ko.observable(false);
		this.id = kb.observable(model, 'id');
		this.options = options;
		function htmlEscape(str) {
			return String(str)
			.replace(/@/g, '')
			.replace(/ /g, '');
		}
		this.encodeId = options.addOptions.viewId;
		this.name = kb.observable(model, 'name');
		this.description = kb.observable(model, 'description');

		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),[
		'name'
		,'description'
		,'Close'
		]);
	};
	
	static getInstance(model,options){
		var view = new ProductListViewModel(model,options);
		view.init(model, options);
		return view;
	};
}
path.ProductListViewModel = ProductListViewModel;