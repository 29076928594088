import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Measure} from './Measure'
import {DimensionalMeasureMixin} from './DimensionalMeasureMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { RankingMeasureRelationship } from './RankingMeasureRelationship'
import { Base1MeasureRelationship } from './Base1MeasureRelationship'
import { Base2MeasureRelationship } from './Base2MeasureRelationship'
import { BaseNMeasureRelationship } from './BaseNMeasureRelationship'
import { RescaledMeasureRelationship } from './RescaledMeasureRelationship'
import { UnitOfMeasure } from './UnitOfMeasure'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/Measure","appbo/smm/DimensionalMeasureMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Measure,DimensionalMeasureMixin
, RankingMeasureRelationship
, Base1MeasureRelationship
, Base2MeasureRelationship
, BaseNMeasureRelationship
, RescaledMeasureRelationship
, UnitOfMeasure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!RankingMeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RankingMeasureRelationship){
			RankingMeasureRelationship = importPath.RankingMeasureRelationship;
		}
        else {
            import('./RankingMeasureRelationship').then(({ default: RankingMeasureRelationship }) => {
                RankingMeasureRelationship = RankingMeasureRelationship;
            });

			/*require(["appbo/smm/RankingMeasureRelationship"],function(loadedModule){
				RankingMeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!Base1MeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Base1MeasureRelationship){
			Base1MeasureRelationship = importPath.Base1MeasureRelationship;
		}
        else {
            import('./Base1MeasureRelationship').then(({ default: Base1MeasureRelationship }) => {
                Base1Base1MeasureRelationship = Base1MeasureRelationship;
            });

			/*require(["appbo/smm/Base1MeasureRelationship"],function(loadedModule){
				Base1MeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!Base2MeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Base2MeasureRelationship){
			Base2MeasureRelationship = importPath.Base2MeasureRelationship;
		}
        else {
            import('./Base2MeasureRelationship').then(({ default: Base2MeasureRelationship }) => {
                Base2MeasureRelationship = Base2MeasureRelationship;
            });

			/*require(["appbo/smm/Base2MeasureRelationship"],function(loadedModule){
				Base2MeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!BaseNMeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.BaseNMeasureRelationship){
			BaseNMeasureRelationship = importPath.BaseNMeasureRelationship;
		}
        else {
            import('./BaseNMeasureRelationship').then(({ default: BaseNMeasureRelationship }) => {
                BaseNMeasureRelationship = BaseNMeasureRelationship;
            });

			/*require(["appbo/smm/BaseNMeasureRelationship"],function(loadedModule){
				BaseNMeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!RescaledMeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RescaledMeasureRelationship){
			RescaledMeasureRelationship = importPath.RescaledMeasureRelationship;
		}
        else {

			/*require(["appbo/smm/RescaledMeasureRelationship"],function(loadedModule){
				RescaledMeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!UnitOfMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.UnitOfMeasure){
			UnitOfMeasure = importPath.UnitOfMeasure;
		}
        else {
            import('./UnitOfMeasure').then(({ default: UnitOfMeasure }) => {
                UnitOfMeasure = UnitOfMeasure;
            });

			/*require(["appbo/smm/UnitOfMeasure"],function(loadedModule){
				UnitOfMeasure = loadedModule;
			});*/
		}
	}
	var DimensionalMeasure = Measure.extend(utils.customExtends({
		relations:DimensionalMeasureMixin.getMixinRelations(),
		subModelTypes: {
			smm_RankingMeasure : 'smm.RankingMeasure',
			smm_DirectMeasure : 'smm.DirectMeasure',
			smm_BinaryMeasure : 'smm.BinaryMeasure',
			smm_CollectiveMeasure : 'smm.CollectiveMeasure',
			smm_RescaledMeasure : 'smm.RescaledMeasure',
			smm_NamedMeasure : 'smm.NamedMeasure'
		},
		initialize: function(attributes, options) {
			Measure.prototype.initialize.apply(this, arguments);
			if(attributes.scale){
				this.set('scale',path.ScaleOfMeasurement[attributes.scale instanceof Object ? attributes.scale.name : attributes.scale]);
			}
			//this.on('destroy',this.checkUnitReferencesAndDestroy);
			//this.on('change:unit',this.checkUnitReferencesAndDestroy);
		}
		}
		, new DimensionalMeasureMixin()
	));
    DimensionalMeasure['destroy'] = function (model) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.checkUnitReferencesAndDestroy(model);
		}
    }
    DimensionalMeasure['change:unit'] = function (model) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.checkUnitReferencesAndDestroy(model);
		}
    }
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	DimensionalMeasure.abstract = sp2Enabled;
	DimensionalMeasure.supported = !sp2Enabled;
	path.DimensionalMeasure = DimensionalMeasure;
	export {DimensionalMeasure};
//});