import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BMElement} from './BMElement'
import {BusinessModelItemMixin} from './BusinessModelItemMixin'
import {VdmlElement} from './VdmlElement'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BMElement","appbo/vdml/BusinessModelItemMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BMElement,BusinessModelItemMixin
, VdmlElement){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!VdmlElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.VdmlElement){
			VdmlElement = importPath.VdmlElement;
		}
		else{
			import('./VdmlElement').then(({ default: VdmlElement }) => {
                VdmlElement = VdmlElement;
            });
			/*require(["appbo/vdml/VdmlElement"],function(loadedModule){
				VdmlElement = loadedModule;
			});*/
		}
	}
	var BusinessModelItem = BMElement.extend(utils.customExtends({
		relations:BusinessModelItemMixin.getMixinRelations(),
		subModelTypes: {
			vdml_BusinessModelCustomer : 'vdml.BusinessModelCustomer',
			vdml_BusinessModelValueProposition : 'vdml.BusinessModelValueProposition',
			vdml_BusinessModelActivity : 'vdml.BusinessModelActivity',
			vdml_BusinessModelCompetence : 'vdml.BusinessModelCompetence',
			vdml_BusinessModelNetworkPartner : 'vdml.BusinessModelNetworkPartner',
			vdml_BusinessModelValueFormula : 'vdml.BusinessModelValueFormula',
			vdml_ParticipantNetwork : 'vdml.ParticipantNetwork'
		},
		initialize: function(attributes, options) {
			BMElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new BusinessModelItemMixin()
	));
	BusinessModelItem.abstract = true;
	path.BusinessModelItem = BusinessModelItem;
	export {BusinessModelItem};
//});