import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {AnalysisContextMixin} from './AnalysisContextMixin'
import {AnalysisContext2Mixin} from './AnalysisContext2Mixin'

//import {DelegationContext} from './DelegationContext'
import {Observation} from '../smm/Observation'
import {Store} from './Store'
import {ApplicationInterface} from './ApplicationInterface'
import {Assignment} from './Assignment'
import {BindingOperationReference} from './BindingOperationReference'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/AnalysisContextMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,AnalysisContextMixin
, DelegationContext
, Observation
, Store
, ApplicationInterface
, BindingOperationReference
, Assignment){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	//if(!DelegationContext){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.DelegationContext){
			//DelegationContext = importPath.DelegationContext;
		}
		else{
			import('./DelegationContext').then(({ default: DelegationContext }) => {
                DelegationContext = DelegationContext;
            });
			/*require(["appbo/vdml/DelegationContext"],function(loadedModule){
				DelegationContext = loadedModule;
			});*/
		}
	//}
	if(!Observation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Observation){
			Observation = importPath.Observation;
		}
		else{
			import('../smm/Observation').then(({ default: Observation }) => {
                Observation = Observation;
            });
			/*require(["appbo/smm/Observation"],function(loadedModule){
				Observation = loadedModule;
			});*/
		}
	}
	if(!Store){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Store){
			Store = importPath.Store;
		}
		else{
			import('./Store').then(({ default: Store }) => {
                Store = Store;
            });
			/*require(["appbo/vdml/Store"],function(loadedModule){
				Store = loadedModule;
			});*/
		}
	}
	if(!ApplicationInterface){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ApplicationInterface){
			ApplicationInterface = importPath.ApplicationInterface;
		}
		else{
			import('./ApplicationInterface').then(({ default: ApplicationInterface }) => {
                ApplicationInterface = ApplicationInterface;
            });
			/*require(["appbo/vdml/ApplicationInterface"],function(loadedModule){
				ApplicationInterface = loadedModule;
			});*/
		}
	}
	if(!BindingOperationReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BindingOperationReference){
			BindingOperationReference = importPath.BindingOperationReference;
		}
		else{
			import('./BindingOperationReference').then(({ default: BindingOperationReference }) => {
                BindingOperationReference = BindingOperationReference;
            });
			/*require(["appbo/vdml/BindingOperationReference"],function(loadedModule){
				BindingOperationReference = loadedModule;
			});*/
		}
	}
	if(!Assignment){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Assignment){
			Assignment = importPath.Assignment;
		}
		else{
			import('./Assignment').then(({ default: Assignment }) => {
                Assignment = Assignment;
            });
			/*require(["appbo/vdml/Assignment"],function(loadedModule){
				Assignment = loadedModule;
			});*/
		}
	}
	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? AnalysisContext2Mixin : AnalysisContextMixin;
	}
	var acMixinClass = getMixinClass();
	var AnalysisContext = VdmlElement.extend(utils.customExtends({
		relations:acMixinClass.getMixinRelations(),
		subModelTypes: {
			vdml_Scenario : 'vdml.Scenario',
			vdml_DelegationContext : 'vdml.DelegationContext'
		},
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
			/*this.on("destroy", this.destroyObservation);*/
		}
		}
		, new acMixinClass()
	));
	AnalysisContext.abstract = true;
	path.AnalysisContext = AnalysisContext;
	export {AnalysisContext};
//});