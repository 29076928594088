import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {DashboardMixin} from './DashboardMixin'
/*
define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/VdmlElement", "appbo/vdml/VdmlElementMixin", "appbo/dashboard/Presentation"],
function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, VdmlElement, VdmlElementMixin, Presentation
){*/
    var path = DataManager.getDataManager().buildAppNsPath("dashboard", global.version);
	export class Dashboard2Mixin{
		getMixinClass(){
			return Dashboard2Mixin;
		}
		getViewProperties(type) {
			return {
				templatePath : "views/dashboard/views/chart/Dashboard2Template.html",
				templateName: "Dashboard2Template",
				viewTypeStr: "appviews/dashboard/views/chart/Dashboard2ViewModel",
				tabId: "DashboardView",
				tabName: "Dashboard"
			};	
		}
	/*static getDialogViewProperties(type) {
	    return DashboardMixin.getDialogViewProperties(type);
	};*/
}
	
	utils.customExtendClass (Dashboard2Mixin,new DashboardMixin());

	path.Dashboard2Mixin = Dashboard2Mixin;