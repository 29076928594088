import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { VdmlElement } from './VdmlElement'
import { PracticeDefinitionMixin } from './PracticeDefinitionMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { BusinessItemDefinition } from './BusinessItemDefinition'
import { PracticeCategory } from './PracticeCategory'
import { CapabilityDefinition } from './CapabilityDefinition'
import { CapabilityMethod } from './CapabilityMethod'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/PracticeDefinitionMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,PracticeDefinitionMixin
, BusinessItemDefinition
, PracticeCategory
, CapabilityDefinition
, CapabilityMethod){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!BusinessItemDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemDefinition){
			BusinessItemDefinition = importPath.BusinessItemDefinition;
		}
        else {
            import('./BusinessItemDefinition').then(loadedModule => {
             //   BusinessItemDefinition = loadedModule;
            });
			/*require(["appbo/vdml/BusinessItemDefinition"],function(loadedModule){
				BusinessItemDefinition = loadedModule;
			});*/
		}
	}
	if(!PracticeCategory){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PracticeCategory){
			PracticeCategory = importPath.PracticeCategory;
		}
        else {
            import('./PracticeCategory').then(loadedModule => {
                PracticeCategory = loadedModule;
            });
			/*require(["appbo/vdml/PracticeCategory"],function(loadedModule){
				PracticeCategory = loadedModule;
			});*/
		}
	}
	if(!CapabilityDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityDefinition){
			CapabilityDefinition = importPath.CapabilityDefinition;
		}
        else {
            import('./CapabilityDefinition').then(loadedModule => {
                CapabilityDefinition = loadedModule;
            });
			/*require(["appbo/vdml/CapabilityDefinition"],function(loadedModule){
				CapabilityDefinition = loadedModule;
			});*/
		}
	}
	if(!CapabilityMethod){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityMethod){
			CapabilityMethod = importPath.CapabilityMethod;
		}
        else {
            import('./CapabilityDefinition').then(loadedModule => {
                CapabilityDefinition = loadedModule;
            });
			/*require(["appbo/vdml/CapabilityMethod"],function(loadedModule){
				CapabilityMethod = loadedModule;
			});*/
		}
	}
	var PracticeDefinition = VdmlElement.extend(utils.customExtends({
		relations:PracticeDefinitionMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new PracticeDefinitionMixin()
	));
	
	PracticeDefinition.abstract = false;
	PracticeDefinition.supported = false;
path.PracticeDefinition = PracticeDefinition;
export { PracticeDefinition };
//});