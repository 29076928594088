import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DirectMeasure} from './DirectMeasure'
import {CountingMeasureMixin} from './CountingMeasureMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DirectMeasure","appbo/smm/CountingMeasureMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DirectMeasure,CountingMeasureMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var CountingMeasure = DirectMeasure.extend(utils.customExtends({
		relations:CountingMeasureMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			DirectMeasure.prototype.initialize.apply(this, arguments);
			if(attributes.scale){
				this.set('scale',path.ScaleOfMeasurement[attributes.scale instanceof Object ? attributes.scale.name : attributes.scale]);
			}
		}
		}
		, new CountingMeasureMixin()
	));
	
	CountingMeasure.abstract = false;
	CountingMeasure.supported = false;
	path.CountingMeasure = CountingMeasure;
	export {CountingMeasure};
//});