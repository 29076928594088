import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
//import * as  backboneLawnchair from 'backbonelawnchair'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {ElementMixin} from '../cmof/ElementMixin'
import {EObject} from './EObject'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/ElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,ElementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("cmof",global.version);
	var Element = EObject.extend(utils.customExtends({
		relations:ElementMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			EObject.prototype.initialize.apply(this, arguments);
		}
		}
		, new ElementMixin()
	));
	Element.abstract = true;
	path.Element = Element;
	export {Element};
//});