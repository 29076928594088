import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {BusinessItemLibraryElement} from './BusinessItemLibraryElement'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, BusinessItemLibraryElement){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!BusinessItemLibraryElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemLibraryElement){
			BusinessItemLibraryElement = importPath.BusinessItemLibraryElement;
		}
		else{
			import('./BusinessItemLibraryElement').then(({ default: BusinessItemLibraryElement }) => {
                BusinessItemLibraryElement = BusinessItemLibraryElement;
            });
			/*require(["appbo/vdml/BusinessItemLibraryElement"],function(loadedModule){
				BusinessItemLibraryElement = loadedModule;
			});*/
		}
	}
	export class BusinessItemLibraryMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.BusinessItemLibraryMixin = BusinessItemLibraryMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_BusinessItemLibrary"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessItemLibrary",
			key:"businessItemLibraryElement",
			relatedModel:"vdml.BusinessItemLibraryElement",
			reverseRelation: {
				key:"businessItemLibraryElementOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	
	static getCumulativeMixinRelations(){
		if (!BusinessItemLibraryMixin.cummulativeRelations) {
            BusinessItemLibraryMixin.cummulativeRelations = _.union(BusinessItemLibraryMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return BusinessItemLibraryMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("businessItemLibraryOwner") ? this.get("businessItemLibraryOwner") : this.previousAttributes().businessItemLibraryOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("businessItemLibraryOwner") ? this.get("businessItemLibraryOwner") : this.previousAttributes().businessItemLibraryOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BusinessItemLibraryPropertiesTemplate.html",
			templateName : "BusinessItemLibraryPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BusinessItemLibraryViewModel",
			tabId : "BusinessItemLibraryView",
			tabName: "BusinessItemLibrary"
		}
	}
	
	}
	path.BusinessItemLibraryMixin = BusinessItemLibraryMixin;
	//return BusinessItemLibraryMixin;
//});