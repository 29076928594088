import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AbstractMeasureElement} from './AbstractMeasureElement'
import {UnitOfMeasureMixin} from './UnitOfMeasureMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/AbstractMeasureElement","appbo/smm/UnitOfMeasureMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AbstractMeasureElement,UnitOfMeasureMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var UnitOfMeasure = AbstractMeasureElement.extend(utils.customExtends({
		relations:UnitOfMeasureMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			AbstractMeasureElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new UnitOfMeasureMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	UnitOfMeasure.abstract = false;
	UnitOfMeasure.supported =  !sp2Enabled;
	path.UnitOfMeasure = UnitOfMeasure;
	export {UnitOfMeasure};
//});