import * as $ from 'jquery'
import {DataManager} from '../data/DataManager'
//define(["appcommon/com/vbee/data/DataManager","app/global","jquery"],
//function(DataManager,global,$) {
export class DateFormat {
		dateArrays = {"en-US" : "m/d/yy",
							"zh-CN" : "yy/m/d",
							"ru-RU" : "dd.mm.yy",
							"fr-FR" : "dd/mm/yy",
							"es-ES" : "dd/mm/yy",
							"en-GB" : "dd/mm/yy",
							"de-DE" : "dd.mm.yy",
							"pt-BR" : "d/m/yy",
							"en-CA" : "dd/mm/yy",
							"es-mX" : "dd/mm/yy",
							"it-IT" : "dd/mm/yy",
							"ja-JP" : "yy/mm/dd",
							"ko-KR" : "yy-mm-dd",
							"nl-NL" : "d-m-yy",
						
						   "ar-SA" : "dd/mm/yy",
						   "bg-BG" : "dd.m.yy",
						   "ca-ES" : "dd/mm/yy",
						   "cs-CZ" : "d.m.yy",
						   "da-DK" : "dd-mm-yy",
						   "el-GR" : "d/m/yy",
						   "fi-FI" : "d.m.yy",
						   "he-IL" : "dd/mm/yy",
						   "hu-HU" : "yy. mm. dd.",
						   "is-IS" : "d.m.yy",
						   "nb-NO" : "dd.mm.yy",
						   "pl-PL" : "yy-mm-dd",
						   "ro-RO" : "dd.mm.yy",
						   "hr-HR" : "d.m.yy",
						   "sk-SK" : "d. m. yy",
						   "sq-AL" : "yy-mm-dd",
						   "sv-SE" : "yy-mm-dd",
						   "th-TH" : "d/m/yy",
						   "tr-TR" : "dd.mm.yy",
						   "ur-PK" : "dd/mm/yy",
						   "id-ID" : "dd/mm/yy",
						   "uk-UA" : "dd.mm.yy",
						   "be-BY" : "dd.mm.yy",
						   "sl-SI" : "d.m.yy",
						   "et-EE" : "d.mm.yy",
						   "lv-LV" : "yy.mm.dd.",
						   "lt-LT" : "yy.mm.dd",
						   "fa-IR" : "mm/dd/yy",
						   "vi-VN" : "dd/mm/yy",
						   "hy-Am" : "dd.mm.yy",
						   "az-Latn-AZ" : "dd.mm.yy",
						   "eu-ES" : "yy/mm/dd",
						   "mk-mK" : "dd.mm.yy",
						   "af-ZA" : "yy/mm/dd",
						   "ka-GE" : "dd.mm.yy",
						   "fo-FO" : "dd-mm-yy",
						   "hi-IN" : "dd-mm-yy",
						   "ms-mY" : "dd/mm/yy",
						   "kk-KZ" : "dd.mm.yy",
						   "ky-KG" : "dd.mm.yy",
						   "sw-KE" : "m/d/yy",
						   "uz-Latn-UZ" : "dd/mm yy",
						   "tt-RU" : "dd.mm.yy",
						   "pa-IN" : "dd-mm-yy",
						   "gu-IN" : "dd-mm-yy",
						   "ta-IN" : "dd-mm-yy",
						   "te-IN" : "dd-mm-yy",
						   "kn-IN" : "dd-mm-yy",
						   "mr-IN" : "dd-mm-yy",
						   "sa-IN" : "dd-mm-yy",
						   "mn-mN" : "yy.mm.dd",
						   "gl-ES" : "dd/mm/yy",
						   "kok-IN" : "dd-mm-yy",
						   "syr-SY" : "dd/mm/yy",
						   "dv-mV" : "dd/mm/yy",
						   "it-CH" : "dd.mm.yy",
						   "nn-NO" : "dd.mm.yy",
						   "pt-PT" : "dd-mm-yy",
						   "sr-Latn-CS" : "d.m.yy",
						   "sv-FI" : "d.m.yy",
						   "az-Cyrl-AZ" : "dd.mm.yy",
						   "ms-BN" : "dd/mm/yy",
						   "uz-Cyrl-UZ" : "dd.mm.yy",
						   "en-AU" : "d/mm/yy",
						   "sr-Cyrl-CS" : "d.m.yy",
						   "es-GT" : "dd/mm/yy",
						   "en-NZ" : "d/mm/yy",
						   "es-CR" : "dd/mm/yy",
						   "en-IE" : "dd/mm/yy",
						   "es-PA" : "mm/dd/yy",
						   "en-ZA" : "yy/mm/dd",
						   "es-DO" : "dd/mm/yy",
						   "en-Jm" : "dd/mm/yy",
						   "es-VE" : "dd/mm/yy",
						   "en-029" : "mm/dd/yy",
						   "es-CO" : "dd/mm/yy",
						   "en-BZ" : "dd/mm/yy",
						   "es-PE" : "dd/mm/yy",
						   "en-TT" : "dd/mm/yy",
						   "es-AR" : "dd/mm/yy",
						   "en-ZW" : "m/d/yy",
						   "es-EC" : "dd/mm/yy",
						   "en-PH" : "m/d/yy",
						   "es-CL" : "dd-mm-yy",
						   "es-UY" : "dd/mm/yy",
						   "es-PY" : "dd/mm/yy",
						   "es-BO" : "dd/mm/yy",
						   "es-SV" : "dd/mm/yy",
						   "es-HN" : "dd/mm/yy",
						   "es-NI" : "dd/mm/yy",
						   "es-PR" : "dd/mm/yy",
						   "am-ET" : "d/m/yy",
						   "tzm-Latn-DZ" : "dd-mm-yy",
						   "iu-Latn-CA" : "d/mm/yy",
						   "sma-NO" : "dd.mm.yy",
						   "mn-mong-CN" : "yy/m/d",
						   "gd-GB" : "dd/mm/yy",
						   "en-mY" : "d/m/yy",
						   "prs-AF" : "dd/mm/yy",
						   "bn-BD" : "dd-mm-yy",
						   "wo-SN" : "dd/mm/yy",
						   "rw-RW" : "m/d/yy",
						   "qut-GT" : "dd/mm/yy",
						   "sah-RU" : "mm.dd.yy",
						   "gsw-FR" : "dd/mm/yy",
						   "co-FR" : "dd/mm/yy",
						   "oc-FR" : "dd/mm/yy",
						   "mi-NZ" : "dd/mm/yy",
						   "ga-IE" : "dd/mm/yy",
						   "se-SE" : "yy-mm-dd",
						   "br-FR" : "dd/mm/yy",
						   "smn-FI" : "d.m.yy",
						   "moh-CA" : "m/d/yy",
						   "arn-CL" : "dd-mm-yy",
						   "ii-CN" : "yy/m/d",
						   "dsb-DE" : "d. m. yy",
						   "ig-NG" : "d/m/yy",
						   "kl-GL" : "dd-mm-yy",
						   "lb-LU" : "dd/mm/yy",
						   "ba-RU" : "dd.mm.yy",
						   "nso-ZA" : "yy/mm/dd",
						   "quz-BO" : "dd/mm/yy",
						   "yo-NG" : "d/m/yy",
						   "ha-Latn-NG" : "d/m/yy",
						   "fil-PH" : "m/d/yy",
						   "ps-AF" : "dd/mm/yy",
						   "fy-NL" : "d-m-yy",
						   "ne-NP" : "m/d/yy",
						   "se-NO" : "dd.mm.yy",
						   "iu-Cans-CA" : "d/m/yy",
						   "sr-Latn-RS" : "d.m.yy",
						   "si-LK" : "yy-mm-dd",
						   "sr-Cyrl-RS" : "d.m.yy",
						   "lo-LA" : "dd/mm/yy",
						   "km-KH" : "yy-mm-dd",
						   "cy-GB" : "dd/mm/yy",
						   "bo-CN" : "yy/m/d",
						   "sms-FI" : "d.m.yy",
						   "as-IN" : "dd-mm-yy",
						   "ml-IN" : "dd-mm-yy",
						   "en-IN" : "dd-mm-yy",
						   "or-IN" : "dd-mm-yy",
						   "bn-IN" : "dd-mm-yy",
						   "tk-Tm" : "dd.mm.yy",
						   "bs-Latn-BA" : "d.m.yy",
						   "mt-mT" : "dd/mm/yy",
						   "sr-Cyrl-mE" : "d.m.yy",
						   "se-FI" : "d.m.yy",
						   "zu-ZA" : "yy/mm/dd",
						   "xh-ZA" : "yy/mm/dd",
						   "tn-ZA" : "yy/mm/dd",
						   "hsb-DE" : "d. m. yy",
						   "bs-Cyrl-BA" : "d.m.yy",
						   "tg-Cyrl-TJ" : "dd.mm.yy",
						   "sr-Latn-BA" : "d.m.yy",
						   "smj-NO" : "dd.mm.yy",
						   "rm-CH" : "dd/mm/yy",
						   "smj-SE" : "yy-mm-dd",
						   "quz-EC" : "dd/mm/yy",
						   "quz-PE" : "dd/mm/yy",
						   "hr-BA" : "d.m.yy.",
						   "sr-Latn-mE" : "d.m.yy",
						   "sma-SE" : "yy-mm-dd",
						   "en-SG" : "d/m/yy",
						   "ug-CN" : "yy-m-d",
						   "sr-Cyrl-BA" : "d.m.yy"};	
	static setDateFormat(){
		var locale = navigator.language;
		var localeDateFormat;

		for(var prop in DateFormat.dateArrays){
			if(prop.indexOf(locale)>=0){
				localeDateFormat = DateFormat.dateArrays[prop];
				break;
			}
		}
		localeDateFormat = localeDateFormat || "dd/mm/yy";
		$.datepicker.regional[locale];
		$.datepicker.setDefaults({'dateFormat': localeDateFormat});
	}

	static getDateFromTimestamp(timestamp){
		var newDate = '';
		if(timestamp) {
			var locale = navigator.language;
			var localeDateFormat;

			for(var prop in DateFormat.dateArrays){
				if(prop.indexOf(locale)>=0){
					localeDateFormat = DateFormat.dateArrays[prop];
					break;
				}
			}
			localeDateFormat = localeDateFormat || "dd/mm/yy";
			newDate = $.datepicker.formatDate(localeDateFormat, new Date(+timestamp));
		}
		return newDate;
	}
	static getInstance(){
		return new DateFormat();
	};
}


