//import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../bootbox/bootbox'
import * as async from 'async'
import * as EcoNavigatedViewer from '../../../libs/bower-vdml-js/dist/vdml-navigated-viewer.min'
import * as Highcharts from 'highcharts';
import * as modeler from '../../../libs/bower-vdml-js/dist/vdml-modeler.min'
import {DataManager} from '../../../app/com/vbee/data/DataManager'
import {BMCanvasDiagram} from '../../../app/version1/bo/canvas/BMCanvasDiagram'
import {BMCanvasDiagramMixin} from '../../../app/version1/bo/canvas/BMCanvasDiagramMixin'
import {DashboardMixin} from '../../../app/version1/bo/dashboard/DashboardMixin'
import {EcoMapDiagram} from '../../../app/version1/bo/vdml/EcoMapDiagram'
import {EcoMapDiagramMixin} from '../../../app/version1/bo/vdml/EcoMapDiagramMixin'
import {ValueStreamMixin} from '../../../app/version1/bo/vdml/ValueStreamMixin'

(function (factory) {
  /* global define */
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node/CommonJS
    module.exports = factory(require('jquery'));
  } else {
    // Browser globals
    factory(window.jQuery);
  }
}(function ($) {
    jQuery.extend(jQuery.summernote.plugins, {
    /**
     * @param {Object} context - context object has status of editor.
     */
    'importText': function (context) {
      var self = this;
	  if(context.options && context.options.viewModel){
	  	self.linkModels = context.options.viewModel.labels['linkText']();
	  }
      // ui has renders to build ui elements.
      //  - you can create a button with `ui.button`
      var ui = jQuery.summernote.ui;

      // add importText button
      context.memo('button.importText', function () {
        // create button
        self.context = arguments[0];
        var button = ui.button({
            contents: '<i class="glyphicon glyphicon-link"/> ',//+ self.linkModels,
            tooltip: '' + self.linkModels,
            click: function () {
			var dataManager = DataManager.getDataManager();
			var workspace = dataManager.get('currentWorkspace');
			var plan = dataManager.get('currentPlan');
			var ret = [];
			function loadCapData(capCallback){
				var packages =[];
				var planId = DataManager.getDataManager().get('currentPlan').id;
				var commonAltId = planId.substr(planId.lastIndexOf('@')) + "-Common@";
				DataManager.getDataManager().getAllDocumentsCollectionOfType(commonAltId, 'vdml_ValueDeliveryModel', function (result) {
					if (result && result.length > 0) {
						for(var i=0;i<result.length;i++){
							if (result.models[i].get('id').indexOf("Common") > -1) {
								if(result.models[i].get('name') != "Common Package"){
										packages.push(result.models[i]);
								}
							}
						};
						async.eachSeries(packages,function(pack,ecoCallback){
							var repId = dataManager.getRepositoryId(pack.id);
							var vdmStore = dataManager.getVDMStore(repId);
							DataManager.getDataManager().fetchDocumentFromPackage(pack.get('id'), 'appbo/vdml/ValueDeliveryModel', pack.get('version'),pack.get('id'), 'appbo/vdml/ValueDeliveryModel', vdmStore, {
								success: function (model) {
									ecoCallback();	
								},
							});
						},function(err){
							capCallback(packages);
						})
					}
				});
				
			}
			function loadEcoMap(capData,callback){
				var packageList = [];
				var phases = dataManager.get('currentPlan').get('phase').models;
				for(var i =0;i<phases.length;i++){
					var designModels = phases[i].get('phaseAlternative').models[0].get('phaseDesignPart').models;
					for(var j =0;j<designModels.length;j++){
						if((designModels[j].get('beepType') == "ecomap_EcoMap" || designModels[j].get('beepType') == "dashboard_Analytics")&&  _.filter(packageList, function (pack) { if (pack.id === designModels[j].get('beepReference')){return pack} })){
							packageList.push({version:designModels[j].get('version'),id:designModels[j].get('beepReference'),type:designModels[j].get('beepType')});
						}
					}
					if(packageList.length == 0){
						break;
					}
				}
				var dashboardPackages = [];
				async.eachSeries(packageList,function(pack,ecoCallback){
					var repId = dataManager.getRepositoryId(pack.id);
					var vdmStore = dataManager.getVDMStore(repId);
					if(pack.type == "ecomap_EcoMap"){
						dataManager.fetchDocumentFromPackage(pack.id,"appbo/ecomap/EcoMap",pack.version,pack.id,"appbo/ecomap/EcoMap",vdmStore,{
						success:function(model){
								ecoCallback();					  
						},
						});
					}else if(pack.type == "dashboard_Analytics"){
						dataManager.fetchDocumentFromPackage(pack.id,"appbo/dashboard/Analytics",pack.version,pack.id,"appbo/dashboard/Analytics",vdmStore,{
							success:function(model){
								dashboardPackages.push(model);
								ecoCallback();					  
							},
						});
					}
				},function(err){
					callback(plan);
				})
			}
			
			function getModelDataInJSTreeFormat(capData){
				var jsonData = {};
				var ret = [];
				ret.push(jsonData);
				var plan =dataManager.get('currentPlan');
				jsonData.id = htmlEscape(plan.get('id'));
				jsonData.text = plan.get('name');
				jsonData.tooltip = "Plan";
				jsonData.children = addPhase(plan.get('phase'));
				jsonData.capLibs = addCapLibs(capData);
				ret[0].icon = "img/Small/icons_01.png";
				return ret;
			}
			function htmlEscape(str) {
				return String(str)
				.replace(/@/g, '')
				.replace(/ /g, '');
			} 
			
			function addBMChilds(bmModel,altId,mappingObject){
				var child =[];
				for(var a=0;a<bmModel.length;a++){
					child.push({text: bmModel.models[a].get('name'), id: altId + bmModel.models[a].get('id'), originalId: bmModel.models[a].get('id'), icon : "img/Small/icons-14-1.png", type: bmModel.models[a].get('type'),tooltip:DataManager.getDataManager().get('localeManager').get('BusinessModel'), altId : altId, parentId : bmModel.models[a].getNestedParent().id})
					child[a].children = addDetails(bmModel.models[a],altId,mappingObject);
				}
				return child;
			}
			function addBMElements(bm, element,altid,bmArray){
				var childs = [];var model;var modelChild;var icon = "img/object.png";
				for(var i=0;i<bmArray.length;i++){
					if(bmArray[i] == element){
							var list = bm.get(bmArray[i]).models;
							for(var j=0;j<list.length;j++){
								model = list[j];
								if(model.get('type') === 'vdml_ParticipantNetwork'){
									icon = "img/Small/icons-04.png";
								}else if(model.get('type') === 'vdml_BusinessModelCustomer'){
									icon = 'img/Small/icons-06.png';
								}else if(model.get('type') === 'vdml_BusinessModelNetworkPartner'){
									icon = "img/Small/icons-07.png";
								}else if(model.get('type') === 'vdml_Party'){
									icon = "img/Small/icons-05.png";
								}
								if(bmArray[i] == 'bmValueProposition'){
								model = list[j].get('valueProposition');
								modelChild = model.get('component').models
								icon = "img/Small/icons-09.png"
								}else if(bmArray[i] == 'bmActivity'){
									model = list[j].get('activity');
									modelChild = list[j].get('activityValue').models;
									icon = "img/Small/icons-11.png"
								}else if(bmArray[i] == 'bmCompetence'){
									model = list[j].get('competence');
									icon = "img/Small/icons-12.png"
								}else if(bmArray[i] == 'bmValueFormula'){
									model = list[j].get('valueFormula')
									modelChild = model.get('component').models;
									icon = "img/Small/icons-10.png"
								}
								childs.push({text:model.get('name'),id:altid + model.get('id'),originalId: model.get('id'),type:model.get('type'),icon:icon,tooltip:bmArray[i], altId : altid, parentId : model.getNestedParent().id})
								for(var a=0;a<childs.length;a++){
									if(childs[a].id === altid + model.get('id') && !modelChild){
										childs[a].children = addDetails(model,altid);
									}else if(childs[a].id === altid + model.get('id') && modelChild){
										childs[a].children = addbmComponent(modelChild,altid,model);
									}
								}
							}
							break;
					}
				}
				return childs;
			}
			function addDetails(model,altid,mappingObject){
					var child =[];
					var attributes = Object.getOwnPropertyNames(model.attributes);
					for(var i=0;i<attributes.length;i++){
						if (attributes[i] === 'name' || attributes[i] === 'description') {
							if (altid != null) {
								child.push({ text: attributes[i] + " : " + model.get('name'), id: attributes[i] + altid + model.get('id'), originalId: model.get('id'), type: model.get('type'), icon: "img/leaf.png", Tag: attributes[i], tagText: model.get('name'), altId: altid, parentId: model.getNestedParent().id });
							}
							else {
								child.push({ text: attributes[i] + " : " + model.get('name'), id: attributes[i] + model.get('id'), originalId: model.get('id'), type: model.get('type'), icon: "img/leaf.png", Tag: attributes[i], tagText: model.get('name'), altId: altid, parentId: model.getNestedParent().id });
							}
						}
					}
					if(model.get('type') == "vdml_ValuePropositionComponent" || model.get('type') == "vdml_ValueAdd"){// adding values of BM and Plan
						var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({id:altid})
						var scenario = alt.getMainScenario(model.getNestedParent());
						var obs = scenario.getObservedMeasureWithMeasurements(model.get('valueMeasurement'), null)
						if (obs) {
						var value = obs.get('measurements').at(0);
						var valUnit = value.get('observedMeasure').get('measure').get('unit');
						var CompAttributes = Object.getOwnPropertyNames(value.attributes);
						for(var i=0;i<CompAttributes.length;i++){
								if(CompAttributes[i] === 'value'){
								var roundedValue = window.utils.getRoundedValues(value.get('value'), valUnit.get('significantDecimals'), true);
								child.push({text:CompAttributes[i]+" : "+roundedValue,id:value.get('id')+altid,originalId:model.get('id'),type:model.get('type'),icon:"img/leaf.png",Tag:CompAttributes[i],altId : altid, tagText :roundedValue, parentId : model.getNestedParent().id})
								}else if(CompAttributes[i] === 'observedMeasure'){
									var unitAttributes = Object.getOwnPropertyNames(value.get('observedMeasure').get('measure').attributes);
									var exists = _.find(unitAttributes, function(ar){return ar ==='unit';})
									if(exists){
									child.push({text:exists+" : "+valUnit.get('name'),id:'unit'+value.get('id'),originalId:model.get('id'),type:model.get('type'),icon:"img/leaf.png",Tag:CompAttributes[i],altId : altid, tagText : valUnit.get('name'), parentId : model.getNestedParent().id})
									}
								}
						}
						}
					}else if(model.get('type') === "vdml_ValueDeliveryModel"){ // For adding Packages
						var bmAttributes = Object.getOwnPropertyNames(model.attributes);
						for(var i=0;i<bmAttributes.length;i++){
							if(bmAttributes[i] === 'businessModel' && altid){
								child.push({text:mappingObject[bmAttributes[i]],id:"bm"+model.id+altid,icon:"img/object.png"})
								for(var a =0;a<child.length;a++){
									if(child[a].id == 'bm' + model.get('id')+ altid){
										child[a].children = addBMChilds(model.get('businessModel'),altid,mappingObject);
									}
								}
							}
						}
					}else if(model.get('type') == 'vdml_BusinessModel'){ // For adding Business Models
						var bmArray = ['participantNetwork','bmRole','bmCustomer','bmValueProposition','bmActivity','bmCompetence','bmNetworkPartner','bmValueFormula','bmValueStream'];
						var bmodelAttributes = Object.getOwnPropertyNames(model.attributes);
						for(var j = 0;j<bmArray.length;j++){
						var exists= _.find(bmodelAttributes, function(ar){return ar === bmArray[j];})
						if(exists){
							child.push({text:mappingObject[bmArray[j]], id :bmArray[j]+model.get('id') + altid, icon : "img/object.png"});
							for(var a =0;a<child.length;a++){
								if(child[a].id === bmArray[j]+model.get('id')+altid){
									child[a].children = addBMElements(model, bmArray[j],altid,bmArray);
								}
							}
						}
						}
					}else if(model.get('type') === "vdml_CapabilityLibrary"){ // For adding capability Elements(Cate and Defi)
						var capElements = model.get('capability');
						for(var j=0;j<capElements.length;j++){
						var type = DataManager.getDataManager().get('localeManager').get('CapabilityCategory');
						if(capElements.models[j].get('type') === "vdml_CapabilityDefinition"){
							type = DataManager.getDataManager().get('localeManager').get('CapabilityDefinition');
						}
						child.push({text: capElements.models[j].get('name'), id: capElements.models[j].get('id'), originalId: capElements.models[j].get('id'), type: capElements.models[j].get('type'), icon: "img/object.png", tooltip : type});
						for(var i=0;i<child.length;i++){
							if(child[i].originalId === capElements.models[j].get('id')){
								child[i].children = addDetails(capElements.models[j]);
							}
						}
						}
					}
					return child;
			}
			function addbmComponent(component,altId,model){
				var c = addDetails(model,altId);
				for(var i=0;i<component.length;i++){
					c.push({text:component[i].get('name'),id:altId+component[i].get('id'),originalId:component[i].get('id'),type:component[i].get('type'),icon:"img/Small/icons-15.png",tooltip:"Component", altId : altId,parentId : component[i].getNestedParent().id});
					for(var j=0;j<c.length;j++){
						if(c[j].originalId === component[i].get('id')){
							c[j].children = addDetails(component[i],altId);
						}
					}
				}	
				return c;
			}
			function addPhase(modelList){
				ret.push({text:"Name : " +modelList.phaseOwner.get('name'), id : 'text'+modelList.phaseOwner.get('id')+'', originalId : modelList.phaseOwner.get('id'), type : modelList.phaseOwner.get('type'), icon:'img/leaf.png', Tag:'name', tagText : modelList.phaseOwner.get('name')});
				ret.push({text:"Description : " +modelList.phaseOwner.get('description'), id : 'des'+modelList.phaseOwner.get('id')+'', originalId : modelList.phaseOwner.get('id'), type : modelList.phaseOwner.get('type'), icon:'img/leaf.png', Tag: 'description', tagText : modelList.phaseOwner.get('description')});
				for(var i=0;i<modelList.length;i++){
					ret.push({text:modelList.at(i).get('name'), icon:"img/Small/icons_02.png",id:modelList.at(i).id,type:modelList.at(i).get('type'),tooltip:DataManager.getDataManager().get('localeManager').get('Phase')});
					for(var j=0;j<ret.length;j++){
						if(ret[j].id == modelList.at(i).id){
							ret[j].children = addAlternative(modelList.at(i));
						}
					}
				}
				return ret;
			}
			function addAlternative(model){
					var alt = addDetails(model);
					for(var i=0;i< model.get('phaseAlternative').length;i++){
					alt.push({text:model.get('phaseAlternative').at(i).get('name'), icon:"img/object.png",id:model.get('phaseAlternative').at(i).id, type : model.get('phaseAlternative').at(i).get('type'),tooltip:DataManager.getDataManager().get('localeManager').get('Alternative')});
					for(var j=0;j<alt.length;j++){
						if(alt[j].id === model.get('phaseAlternative').at(i).id){
								alt[j].children =  addChildren(model.get('phaseAlternative').at(i));
						} 
					}
					}
					return alt;
			}
			function addPlanValues(list,altid){
				var child = [];
				for(var i=0;i<list.length;i++){
					child.push({text:list.at(i).get('name'), id : list.at(i).get('id')+altid, originalId : list.at(i).get('id'), type: list.at(i).get('type'), icon : "img/Small/icons-15.png", tooltip : DataManager.getDataManager().get('localeManager').get('value')});
					child[i].children = addDetails(list.at(i),altid);
				}
				return child;
			}
			function addChildren(children){ 
				var child = addDetails(children,children.get('id'));var canvasCount = 0;var ecoCount =0;var dashBoardCount =0;
				for(var i =0;i < children.get('phaseDesignPart').length;i++){
					if(children.get('phaseDesignPart').models[i].get('beepType') == "vdml_ValueDeliveryModel" && children.get('phaseDesignPart').models[i].get('name') != "commonScenario"){
						var mypackage = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find(children.get('phaseDesignPart').models[i].get('beepReference'));
						if(mypackage && mypackage.get('name')!= "Common Package"){
							child.push({ text: mypackage.get('name'), id: children.get('id') + mypackage.get('id'), originalId: mypackage.get('id'), icon:"img/object.png", type : mypackage.get('type'), altId : children.get('id')});
							if(canvasCount == 0){
								child.push({text:DataManager.getDataManager().get('localeManager').get('BusinessCanvases'),id:children.get('id')+'canvas',icon:"img/object.png"});
								canvasCount++;
							}
							for(var j=0;j<child.length;j++){
							if(child[j].id === children.get('id')+'canvas'){
								if(!child[j].children){
									child[j].children = addSubChilds(mypackage,children.get('phaseDesignPart').models[i].get('id'),"",children.id);
								}else{
									child[j].children = addSubChilds(mypackage,children.get('phaseDesignPart').models[i].get('id'),child[j].children,children.id);
								}
							}
							if(child[j].id === children.get('id') + mypackage.get('id')){
								var mappingObject = {};
								mappingObject['businessModel'] = DataManager.getDataManager().get('localeManager').get('BusinessModel');
								mappingObject['participantNetwork'] = DataManager.getDataManager().get('localeManager').get('ParticipantNetwork');
								mappingObject['bmRole'] = DataManager.getDataManager().get('localeManager').get('Role');
								mappingObject['bmCustomer'] = DataManager.getDataManager().get('localeManager').get('Customer');
								mappingObject['bmValueProposition'] = DataManager.getDataManager().get('localeManager').get('ValueProposition');
								mappingObject['bmActivity'] = DataManager.getDataManager().get('localeManager').get('Activity');
								mappingObject['bmCompetence'] = DataManager.getDataManager().get('localeManager').get('Competence');
								mappingObject['bmNetworkPartner'] = DataManager.getDataManager().get('localeManager').get('Partner');
								mappingObject['bmValueFormula'] = DataManager.getDataManager().get('localeManager').get('MyProposition');
								mappingObject['bmValueStream'] = DataManager.getDataManager().get('localeManager').get('ValueStream');
								child[j].children = addDetails(mypackage,children.get('id'),mappingObject)
							}
							}
						}
					}else if(children.get('phaseDesignPart').models[i].get('beepType') == "ecomap_EcoMap"){
						var ecoPackage = Backbone.Relational.store.getObjectByName("ecomap.EcoMap").find(children.get('phaseDesignPart').models[i].get('beepReference'));
						if(ecoCount == 0){
							child.push({text:DataManager.getDataManager().get('localeManager').get('EcoMaps'), id: children.get('phaseDesignPart').models[i].get('id')+'EcoMap',icon:"img/object.png"});
							child.push({text: DataManager.getDataManager().get('localeManager').get('StrategyMaps'), id: children.get('phaseDesignPart').models[i].get('id')+'StrategyMap',icon:"img/object.png"});
							child.push({text: DataManager.getDataManager().get('localeManager').get('ValueStreamMaps'), id: children.get('phaseDesignPart').models[i].get('id')+'ValueStreamMap',icon:"img/object.png"});
							ecoCount++;
						}
						for(var j=0;j<child.length;j++){
							if(child[j].id != children.get('id')+'canvas' && child[j].id != children.get('id')+'dashboard'){
								if(!child[j].children){
									child[j].children = addSubEcoChilds(child[j],ecoPackage,children.get('phaseDesignPart').models[i].get('id'),children.get('id'))
								}else{
									child[j].children = addSubEcoChilds(child[j],ecoPackage,children.get('phaseDesignPart').models[i].get('id'),children.get('id'))
								}
							}
						}
					}else if(children.get('phaseDesignPart').models[i].get('beepType') == "dashboard_Analytics"){
						var dashboardPackage = Backbone.Relational.store.getObjectByName('dashboard.Analytics').find(children.get('phaseDesignPart').models[i].get('beepReference'));
						if(dashboardPackage && dashboardPackage.get('name') != "Common Package"){
							if(dashBoardCount == 0){ 
								child.push({text:DataManager.getDataManager().get('localeManager').get('Dashboards'),id:children.get('id')+'dashboard',icon:"img/object.png"});
								dashBoardCount++;
							}
							for(var b=0;b<child.length;b++){
								if(child[b].id == children.get('id')+'dashboard'){
									if(!child[b].children){ 
									child[b].children = addDashBoards(dashboardPackage.get('dashboard'), children.get('phaseDesignPart').models[i].get('id'),"",children.get('id'));
									}else{
									child[b].children = addDashBoards(dashboardPackage.get('dashboard'), children.get('phaseDesignPart').models[i].get('id'),child[b].children,children.get('id'));
									}
								}
							}
						} 
					}
				}
				var planValueList = children.get('phaseAlternativeOwner').get('phaseOwner').get('planCriterionSet')? children.get('phaseAlternativeOwner').get('phaseOwner').get('planCriterionSet').get('component') : "";
				if(planValueList && planValueList.length > 0){
						child.push({text:"Plan Values", icon: "img/object.png", id : children.get('phaseAlternativeOwner').get('phaseOwner').get('planCriterionSet').id + children.get('id')})
						for(var a = 0;a<child.length;a++){
							if(child[a].id == children.get('phaseAlternativeOwner').get('phaseOwner').get('planCriterionSet').id + children.get('id')){
								child[a].children = addPlanValues(planValueList,children.get('id'));
							}
						}
				}
				return child;
			}
			function addSubChilds(mypackage,canvasId,child,altId){
					var diagram = [];
					for(var i =0;i<mypackage.get('canvas').length;i++){
						if(!child){
							diagram.push({text:mypackage.get('canvas').models[i].get('name'),icon:"img/Small/icons-20.png",id:canvasId+mypackage.get('canvas').models[i].get('id'),originalId:mypackage.get('canvas').models[i].get('id'),
							type:mypackage.get('canvas').models[i].get('type'),tooltip:DataManager.getDataManager().get('localeManager').get('BusinessCanvas'), altId : altId, parentId : mypackage.get('canvas').models[i].getNestedParent().id});
							for(var j=0;j<diagram.length;j++){
								if(diagram[j].originalId === mypackage.get('canvas').models[i].get('id')){
									diagram[j].children = addDetails(mypackage.get('canvas').models[i],altId);
								}
							}
						}else{
							child.push({text:mypackage.get('canvas').models[i].get('name'),icon:"img/Small/icons-20.png",id:canvasId+mypackage.get('canvas').models[i].get('id'),originalId:mypackage.get('canvas').models[i].get('id'),
							type:mypackage.get('canvas').models[i].get('type'),tooltip:DataManager.getDataManager().get('localeManager').get('BusinessCanvas'), altId : altId, parentId : mypackage.get('canvas').models[i].getNestedParent().id})
						}
					}
					if(!child){
						return diagram;
					}
					return child;
			}
			function addSubEcoChilds(child,ecoPackage,ecoId,altId){
					var eco =[];var icon = "img/leaf.png";
					for(var i=0;i<ecoPackage.get('diagrams').length;i++){
						if(!child.children){
							if(ecoPackage.get('diagrams').models[i].get('type') == "vdml_StrategyMapDiagram" && child.id === ecoId+'StrategyMap'){
								eco.push({text:ecoPackage.get('diagrams').models[i].get('name'),icon:"img/Small/icons-23.png",id:ecoId+ecoPackage.get('diagrams').models[i].id,originalId:ecoPackage.get('diagrams').models[i].id,type:ecoPackage.get('diagrams').models[i].get('type'),tooltip:DataManager.getDataManager().get('localeManager').get('StrategyMap'), altId : altId, parentId : ecoPackage.get('diagrams').models[i].getNestedParent().id});
							}else if(ecoPackage.get('diagrams').models[i].get('type') == "vdml_EcoMapDiagram" && child.id === ecoId+'EcoMap'){
								eco.push({text:ecoPackage.get('diagrams').models[i].get('name'),icon:"img/Small/icons-21.png",id:ecoId+ecoPackage.get('diagrams').models[i].id,originalId:ecoPackage.get('diagrams').models[i].id,type:ecoPackage.get('diagrams').models[i].get('type'),tooltip:DataManager.getDataManager().get('localeManager').get('BusinessEcosystemMap'), altId : altId, parentId : ecoPackage.get('diagrams').models[i].getNestedParent().id})
							}else if(child.id === ecoId+'ValueStreamMap' && ecoPackage.get('diagrams').models[i].get('type') == "vdml_ValueStreamMapDiagram"){
								eco.push({text:ecoPackage.get('diagrams').models[i].get('name'),icon:"img/Small/icons-24.png",id:ecoId+ecoPackage.get('diagrams').models[i].id,originalId:ecoPackage.get('diagrams').models[i].id,type:ecoPackage.get('diagrams').models[i].get('type'),tooltip:DataManager.getDataManager().get('localeManager').get('ValueStreamMap'), altId : altId, parentId : ecoPackage.get('diagrams').models[i].getNestedParent().id})
							}
							for(var j=0;j<eco.length;j++){
								if(eco[j].originalId == ecoPackage.get('diagrams').models[i].id){
										eco[j].children = addDetails(ecoPackage.get('diagrams').models[i],altId);
								}
							}
						}else{
							if(ecoPackage.get('diagrams').models[i].get('type') == "vdml_StrategyMapDiagram" && child.text === DataManager.getDataManager().get('localeManager').get('StrategyMaps')){
								child.children.push({text:ecoPackage.get('diagrams').models[i].get('name'),icon:"img/Small/icons-23.png",id:ecoId+ecoPackage.get('diagrams').models[i].id,originalId:ecoPackage.get('diagrams').models[i].id,type:ecoPackage.get('diagrams').models[i].get('type'),tooltip:DataManager.getDataManager().get('localeManager').get('StrategyMap'), altId : altId, parentId : ecoPackage.get('diagrams').models[i].getNestedParent().id})
							}else if(ecoPackage.get('diagrams').models[i].get('type') == "vdml_EcoMapDiagram" && child.text === DataManager.getDataManager().get('localeManager').get('EcoMaps')){
								child.children.push({text:ecoPackage.get('diagrams').models[i].get('name'),icon:"img/Small/icons-21.png",id:ecoId+ecoPackage.get('diagrams').models[i].id,originalId:ecoPackage.get('diagrams').models[i].id,type:ecoPackage.get('diagrams').models[i].get('type'),tooltip:DataManager.getDataManager().get('localeManager').get('BusinessEcosystemMap'), altId : altId, parentId : ecoPackage.get('diagrams').models[i].getNestedParent().id})
							}else if(child.text === DataManager.getDataManager().get('localeManager').get('ValueStreamMaps') && ecoPackage.get('diagrams').models[i].get('type') == "vdml_ValueStreamMapDiagram"){
								child.children.push({text:ecoPackage.get('diagrams').models[i].get('name'),icon:"img/Small/icons-24.png",id:ecoId+ecoPackage.get('diagrams').models[i].id,originalId:ecoPackage.get('diagrams').models[i].id,type:ecoPackage.get('diagrams').models[i].get('type'),tooltip:DataManager.getDataManager().get('localeManager').get('ValueStreamMap'), altId : altId, parentId : ecoPackage.get('diagrams').models[i].getNestedParent().id})
							}
							for(var j=0;j<child.children.length;j++){
								if(child.children[j].originalId == ecoPackage.get('diagrams').models[i].id){
										child.children[j].children = addDetails(ecoPackage.get('diagrams').models[i],altId);
								}
							}
						}
					}
					if(!child.children){
						return eco;
					}
					return child.children;
			}
			function addCapLibs(capData){
				for(var i =0;i<capData.length;i++){
					var capModels = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({id:capData[i].id})
					if(capModels && capModels.get('capabilityLibrary').length > 0){
						ret.push({ text: capModels.get('name'), id: capModels.id, icon:"img/object.png",type:capModels.get('type'),tooltip:"Capability Library Package"});
						for(var a =0;a<ret.length;a++){
							if(ret[a].id == capModels.id){
							ret[a].children = addCapMaps(capModels);
							}
						}
					}
				}
				return ret;
			}
			function addCapMaps(capMaps){
				var maps= addDetails(capMaps);
				for(var i=0;i<capMaps.get('capabilityLibrary').length;i++){
					maps.push({text:capMaps.get('capabilityLibrary').models[i].get('name'),id:capMaps.get('capabilityLibrary').models[i].id,icon:"img/Small/icons-25.png",type:capMaps.get('capabilityLibrary').models[i].get('type'),tooltip:DataManager.getDataManager().get('localeManager').get('CapabilityLibrary')})
					for(var j=0;j<maps.length;j++){
						if(maps[j].id === capMaps.get('capabilityLibrary').models[i].get('id')){
							maps[j].children = addDetails(capMaps.get('capabilityLibrary').models[i]);
						}
					}
				}
				return maps;
			}
			function addDashBoards(boardPackage, dashId, child,altId){
					var dashBoards =[];
					for(var i =0;i<boardPackage.length;i++){
						if(!child){
							dashBoards.push({text:boardPackage.models[i].get('name'),id:dashId+boardPackage.models[i].id,originalId:boardPackage.models[i].id,icon:"img/Small/icons-22.png",tooltip:DataManager.getDataManager().get('localeManager').get('Dashboard'), altId : altId, parentId :boardPackage.models[i].getNestedParent().id});
							for(var a=0;a<dashBoards.length;a++){
								if(dashBoards[a].id === dashId+boardPackage.models[i].id){
									dashBoards[a].children = addBoards(boardPackage.models[i].get('presentation'),dashId,boardPackage.models[i],altId);
								}
							}
						}else{
							child.push({text:boardPackage.models[i].get('name'),id:dashId+boardPackage.models[i].id,originalId:boardPackage.models[i].id,icon:"img/Small/icons-22.png", altId : altId, parentId :boardPackage.models[i].getNestedParent().id});
							for(var a=0;a<child.length;a++){
								if(child[a].id === dashId+boardPackage.models[i].id){
									child[a].children = addBoards(boardPackage.models[i].get('presentation'),dashId,boardPackage.models[i],altId);
								}
							}
						}
					}
					if(!child){
							return dashBoards;
					}
					return child;
			}
			function addBoards(boards,dashId,model,altId){
				var boardsList = addDetails(model,altId);
				for(var j=0;j<boards.length;j++){
					var presentation = Backbone.Relational.store.getObjectByName('dashboard.Presentation').find({id:boards.models[j].id});
					if(presentation.get('presentationType') == "Chart/Table"){
						boardsList.push({text:presentation.get('name'),id:dashId+presentation.id,originalId:presentation.id,type:presentation.get('type'),icon:"img/object.png",tooltip:DataManager.getDataManager().get('localeManager').get('Views'), altId : altId, parentId :presentation.getNestedParent().id});
						for(var i=0;i<boardsList.length;i++){
							if(boardsList[i].originalId === presentation.id){
								boardsList[i].children = addDetails(presentation,altId);
							}
						}
					}
				}
				return boardsList;
			}
			
			var htmlContent='<input type="text"  placeholder="Search" id="treeSearch" value="" class="input " style="padding:1px; border-radius:4px; border:1px solid silver;float:right">'
			htmlContent =  htmlContent+"<div id='jstree_div' class=\"row\"></div>";
			
			var box = bootbox.dialog({
					title: "<div ><img src='img/Small/icons-20.png'>&nbsp;&nbsp;&nbsp;Link Text Selector</div>",
					message: htmlContent,
					show: false,
					buttons: {
						main: {
							label: "Close",
							className: "btn btn-default",
						},
						success: {
							label: '<i id="linkModalCompleteBtn" class="fa fa-check"></i> Complete',
							className: "btn btn-complete",
							callback: function () {
								var selectedCan = jQuery('.jstree-clicked').attr('id');
								window.utils.startSpinner('importing', 'Importing...');
								if(selectedCan){
									selectedCan = jQuery('#jstree_div').jstree("get_selected",true)[0].original.originalId;
									var tagName = jQuery('#jstree_div').jstree("get_selected",true)[0].original.Tag;
									if(!selectedCan){
										selectedCan = jQuery('#jstree_div').jstree("get_selected",true)[0].original.id;
									}
									var selectedType = jQuery('#jstree_div').jstree("get_selected",true)[0].original.type.replace('_','.');
									var altId = jQuery('#jstree_div').jstree("get_selected",true)[0].original.altId ? jQuery('#jstree_div').jstree("get_selected",true)[0].original.altId : "";
									var parentId = jQuery('#jstree_div').jstree("get_selected", true)[0].original.parentId ? jQuery('#jstree_div').jstree("get_selected", true)[0].original.parentId :"";
									var randomId = htmlEscape(window.guidGenerator());
									var textNodeData = "";
									if (tagName) {
										textNodeData += ("<kbd class = 'custText' contenteditable='false' id = " + randomId + " originalId =  \"" + selectedCan + "\" type = \"" + selectedType + "\" Tag = \"" + tagName + "\" altId = \"" + altId + "\" parentId = \"" + parentId + "\">" + jQuery('#jstree_div').jstree("get_selected", true)[0].original.tagText + "&nbsp;</kbd>");
									}
									var checkImage = jQuery('#jstree_div').jstree()._model.data[jQuery('#jstree_div').jstree("get_selected", true)[0].original.id].icon;
									if (checkImage != "img/Small/icons-14-1.png" && checkImage != "img/leaf.png") {
										var noOfChildren = jQuery('#jstree_div').jstree("get_selected", true)[0].children.length;
										if (noOfChildren) {
											textNodeData = "";
										}
										for (var i = 0; i < noOfChildren; i++) {
											var iconType = jQuery('#jstree_div').jstree()._model.data[jQuery('#jstree_div').jstree("get_selected", true)[0].children[i]].icon;
											var parentId1 = jQuery('#jstree_div').jstree()._model.data[jQuery('#jstree_div').jstree("get_selected", true)[0].children[i]].original.parentId;
											var altId1 = jQuery('#jstree_div').jstree()._model.data[jQuery('#jstree_div').jstree("get_selected", true)[0].children[i]].original.altId;
											if (iconType != "img/object.png") {
												//if (jQuery('#jstree_div').jstree()._model.data[jQuery('#jstree_div').jstree("get_selected", true)[0].children[i]].text.split(" :").length)
												var textOfTreeElement = jQuery('#jstree_div').jstree()._model.data[jQuery('#jstree_div').jstree("get_selected", true)[0].children[i]].text;
												if (textOfTreeElement.split(":")[1]) {
													tagName = textOfTreeElement.split(" :")[0] + " :";
													var valueOfKbdElement = textOfTreeElement.split(" :")[1];
												}
												else {
													tagName = " ";
													var valueOfKbdElement = textOfTreeElement;
												}
												var randomId = htmlEscape(window.guidGenerator());
												textNodeData += ("<span>&nbsp;" + tagName.charAt(0).toUpperCase() + tagName.slice(1) + " </span><kbd class = 'custText' contenteditable='false' id = " + randomId + " originalId =  \"" + selectedCan + "\" type = \"" + selectedType + "\" Tag = \"" + textOfTreeElement.split(" :")[0] + "\" altId = \"" + altId1 + "\" parentId = \"" + parentId1 + "\">" + valueOfKbdElement + "</kbd>");

											}
										}
									}
									else if (iconType == "img/Small/icons-14-1.png") {
										//tdDochanges in if statement as well
									}
									jQuery('#summernote').summernote('restoreRange');
									var textNode = jQuery(textNodeData);
									for (var i = 0; i < textNode.length; i++) {

										jQuery('#summernote').summernote('insertNode', textNode[i]);
										//  jQuery('#summernote').summernote('insertNode', textNode[1]);
										// textNode = null;
									}
									window.utils.stopSpinner('importing');
								}
							}
							}
					}
			});  
			box.modal('show');jQuery('.btn-complete').attr('disabled','disabled');
			
			jQuery('#jstree_div').on('hover_node.jstree',function(e,data){
					var toolTip = data.node.original.tooltip? data.node.original.tooltip : "";
					data.instance.element.prop('title',toolTip);
			});
			jQuery('#treeSearch').change(function () {
				window.utils.startSpinner('jsonSearch','Searching...');
				setTimeout(function(){
					var v = jQuery('#treeSearch').val();
					jQuery('#jstree_div').jstree(true).search(v);
					window.utils.applyFontSize(jQuery('#content'));
					window.utils.stopSpinner('jsonSearch');
					jQuery('.jstree-anchor').css('text-overflow','ellipsis');
					jQuery('.jstree-anchor').css('overflow','hidden');
					jQuery('.jstree-anchor').css('max-width','97%');
				},10);
			});
			var tree = jQuery('#jstree_div').on("changed.jstree", function (e, data) {
				if(data.selected.length) {
					var selectedNode = data.instance.get_node(data.selected[0]);
					if(!selectedNode.original.type){
						jQuery('.btn-complete').attr('disabled','disabled');
					}else{
						jQuery('.btn-complete').removeAttr('disabled','disabled');
					}
				}
			}).jstree({ 'core' : {
						'check_callback': true,
						'data' : function (obj,callback) {
							callback.call(this, []);
						}
						},
						"plugins": ["search"],
					"search" : {
							'case_sensitive' : false
						}
				});
				jQuery('#summernote').summernote('saveRange');
				loadCapData(function(capData){
					loadEcoMap(capData,function(data){
							
							var model = getModelDataInJSTreeFormat(capData)
							jQuery('#jstree_div').jstree(true).create_node(null,model[0]);
							
					})
				})
          	}
        });
		
        // create jQuery object from button instance.
        var $importCanvas = button.render();
        return $importCanvas;
      });

      // This events will be attached when editor is initialized.
      this.events = {
        // This will be called after modules are initialized.
        'summernote.init': function (we, e) {
          //console.log('summernote initialized', we, e);
        },
        // This will be called when user releases a key on editable.
        'summernote.keyup': function (we, e) {
          //console.log('summernote keyup', we, e);
        }
      };

      // This method will be called when editor is initialized by $('..').summernote();
      // You can create elements for plugin
      this.initialize = function () {
      };

      // This methods will be called when editor is destroyed by $('..').summernote('destroy');
      // You should remove elements on `initialize`.
      this.destroy = function () {
      };
    }
  });
}));