import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {ValueCategoryMixin} from './ValueCategoryMixin'
import {Value} from './Value'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/ValueCategoryMixin","appbo/vdml/Value"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,ValueCategoryMixin,Value
, ValueDefinition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var ValueCategory = Value.extend(utils.customExtends({
		relations:ValueCategoryMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Value.prototype.initialize.apply(this, arguments);
		}
		}
		, new ValueCategoryMixin()
	));
	ValueCategory.getInstance = function (name, description, owner) {
		var vid = DataManager.getDataManager().guidGeneratorByOwner(owner);
		var valueCat = new ValueCategory({ id: vid, name: owner.getUniqueProperty('name', name, 'value'), description: owner.getUniqueProperty('description', description, 'value'), version: owner.get('version'), valueOwner: owner });
		owner.get('value').add(valueCat);
		return valueCat;
	};
	ValueCategory.abstract = false;
	ValueCategory.supported = true;
	path.ValueCategory = ValueCategory;
	export {ValueCategory};
//});
