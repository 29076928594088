import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as  bootbox  from '../../../../../../libs/bootbox/bootbox'
import { DataManager } from '../../../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","async","appcommon/com/vbee/data/DataManager","app/global"],
function(require,$,_, Backbone,ko, koMapping,kb,bootbox,async,DataManager,global)
{*/
var path = DataManager.getDataManager().buildAppNsPath("report.views.properties",global.version);
export class ReportDetailsViewModel {
	constructor(model, options){
		this.init(model,options)
	}
	dispose(){
		var self = this;
		window.cleanViewModel(self);		
	}; 	
	
	
	saveReportDetails(){
		var self = this;
		if(self.model){
			if(self.name().trim() != self.model.get('name')){
				self.model.set('name',self.name().trim());
				window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'),DataManager.getDataManager().get('viewAlternative'),self.ReportDetailsViewModel);
			}
			self.model.set('description',self.description().trim());
		}
	};
	deleteReport(){
		var self = this;
		function doDeleteReport(){
			DataManager.getDataManager().acquireSaveLock(function () {
				window.utils.startSpinner('reportDeleteSpinner', 'Deleting Report ...');
				window.setTimeout(function () {
					window.vdmModelView.delRep = true;
					self.model.deleteReport(function () {
						window.utils.stopSpinner('reportDeleteSpinner');
						DataManager.getDataManager().releaseSaveLock();
						DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
					});
				}, 100);   
			});
		}
		
		var content = self.model.get('content');
		if(content && content.length > 0){               
			bootbox.confirm('' + DataManager.getDataManager().get('localeManager').get('MapDeleteAlert','Report'), function(result) {
				if(result) {
					doDeleteReport();
				}    
			});		
		}else{
			doDeleteReport();
		}
	}
	checkUniqueValue(view, event) {
		var self = this;
		var name = event.currentTarget.value.trim();
		if(self.reports.length == 0){
			self.fetchReports();
		}
		if(name !== ""){
			if(self.model.get('name') === name){
				$('#duplicateName'+self.encodeId).text("");
				$("#complete").removeAttr('disabled');
				return;
			}
			var unique = window.isUniqueName(name,null,self.id(),self.reports);
			if(unique){
				$('#duplicateName'+self.encodeId).text("");
				$("#complete").removeAttr('disabled');
			}
			else{
				$('#duplicateName'+self.encodeId).text(""+DataManager.getDataManager().get('localeManager').get('ReportDuplicate'));
				$("#complete").attr('disabled','disabled');
			}
		}
		else{
			$("#complete").attr('disabled','disabled');
		}
	}
	fetchReports() {
		var self = this;
		var commonRepId = DataManager.getDataManager().get('currentPlan').getCommonRepositoryId();
		var wsData = DataManager.getDataManager().getWorkspaceDataWithIdSync(commonRepId);
		wsData.get('rdfModel').getAllDocumentsCollectionOfType('report_BeepReport', function (reports) {
			self.reports.add(reports.models);
		}, false, null, 'vbc:cmof_EObject-name', null, true);
	}
	afterRenderView() {
	}

	init(model, options){
		var self = this;
		this.ReportDetailsViewModel = this;
		this.saveReportDetails = _.bind(this.saveReportDetails,this);
		this.deleteReport = _.bind(this.deleteReport, this);
		this.model = model.model;
		this.id = kb.observable(self.model, 'id');
		//this.enableDelete = ko.observable(true); //not used
		function htmlEscape(str) {
			return String(str)
				.replace(/@/g, '')
				.replace(/ /g, '');
		}
		this.encodeId = htmlEscape(this.id());
		this.enableComplete = ko.observable(true);
		this.name = ko.observable(self.model ? self.model.get('name') : null);
		this.description = ko.observable(self.model ? self.model.get('description') : "");
		self.reports = new Backbone.Collection;

			//fetchReports();
		this.name.subscribe(function (val) {
			self.description(val);
		});
	}
	static getInstance(model,options){
		var view = new ReportDetailsViewModel(model, options);
		return view;
	};
}
path.ReportDetailsViewModel = ReportDetailsViewModel;
