import * as _ from 'underscore'
import { BeepPackageMixin } from "./BeepPackageMixin";
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("beeppackage",global.version);

export class BeepPackage2Mixin {
    static getSubModelTypes(){
        var ret = BeepPackageMixin.getSubModelTypes();
        ret['transformation_PlanScenario'] = 'transformation.PlanScenario';
        ret['transformation_ScenarioExecution'] = 'transformation.ScenarioExecution';
        ret['transformation_PeriodDataset'] = 'transformation.PeriodDataset';
        ret['instdef_PlanInstantiationPackage'] = 'instdef.PlanInstantiationPackage';
        return ret;
    }

    updateScenarioDataForNextAlts(currentAltenative,copyAlternative,copyPck,callback){
        var self = this;
        var dataManager = DataManager.getDataManager();
        var periodKind = dataManager.get('currentPlan').get("periodKind");
		var scenarioId = dataManager.get("currentPlan").get("defaultScenario");
        var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
        var defaultExecutionScenaroId = scenario.get("defaultExecutionScenario");
		var defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
        var dataSets = copyAlternative.getPhaseAlternativeSteps(scenario);
        var currentAltId = currentAltenative.get('id');
        var components = [];
        _.each(copyPck.get('businessModel').models,function(bm){
            bm.getValues(components,true);
        });
        var nextAlts = copyAlternative.getAlternativesWhereICanBeReffered();
        _.each(nextAlts,function(alt){
            self.updatePeriodDatasets(dataSets,alt,components,periodKind,defaultExecutionScenaro,currentAltId);
        });
        callback();
    }

    updatePeriodDatasets(dataSets,copyAlternative,components,periodKind,defaultExecutionScenaro,currentAltId){
        var self = this;
        _.each(dataSets,function(yearPeriod){
            var	inputPeriodDataset = defaultExecutionScenaro.get("input").findWhere({period: yearPeriod.period,year: yearPeriod.year,periodKind : periodKind});
			var resultPeriodDataset = defaultExecutionScenaro.get("result").findWhere({period: yearPeriod.period,year: yearPeriod.year,periodKind : periodKind});
			_.each(components,function(comp){
                self.updateQuantity(comp,inputPeriodDataset,resultPeriodDataset,currentAltId);
                self.updateQuantity(comp.get("satisfactionLevel"),inputPeriodDataset,resultPeriodDataset,currentAltId);
                self.updateQuantity(comp.get("percentageWeight"),inputPeriodDataset,resultPeriodDataset,currentAltId);
                self.updateQuantity(comp.get("recipientOpinion"),inputPeriodDataset,resultPeriodDataset,currentAltId);
            });            
        });
    }

    updateQuantity(comp,inputPeriodDataset,resultPeriodDataset,currentAltId){
        if(!comp){
            return;
        }
        var oldCompId = currentAltId + window.utils.getSuffix(comp.get('id'));
        var inputQty = inputPeriodDataset ? inputPeriodDataset.get(oldCompId):null;
        var resultQty = resultPeriodDataset ? resultPeriodDataset.get(oldCompId):null;
        if(inputQty){
            inputPeriodDataset.set(comp.get('id'),inputQty);
            inputPeriodDataset.unset(oldCompId);
        }
        if(resultQty){
            resultPeriodDataset.set(comp.get('id'),resultQty);
            resultPeriodDataset.unset(oldCompId);
        }
    }
}
utils.customExtendClass (BeepPackage2Mixin,new BeepPackageMixin());

path.BeepPackage2Mixin = BeepPackage2Mixin;