import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmElement} from './SmmElement'
import {BusinessIndicatorLibraryMixin} from './BusinessIndicatorLibraryMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/smm/SmmElement", "appbo/smm/BusinessIndicatorLibraryMixin"],
//    function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, SmmElement, BusinessIndicatorLibraryMixin, SmmModel) {

        var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);

        var BusinessIndicatorLibrary = SmmElement.extend(utils.customExtends({
            relations: BusinessIndicatorLibraryMixin.getMixinRelations(),
            initialize: function (attributes, options) {
                SmmElement.prototype.initialize.apply(this, arguments);
            }
        }
            , new BusinessIndicatorLibraryMixin()
        ));
        BusinessIndicatorLibrary.prototype.getSystemTags = function () {
            return ['VDM', 'BusinessIndicator Library', 'Admin'];
        };
        /*BusinessIndicatorLibrary.getInstance = function (name, description, vdm, id) {
            var mId = id ? id : DataManager.getDataManager().guidGeneratorByOwner(vdm);
	        var measure = vdm.createDefaultMetricModel().getOrCreateBusinessIndicatorLibrary(name);
		    return measure;
	    };*/
        var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
        BusinessIndicatorLibrary.abstract = false;
        BusinessIndicatorLibrary.supported = !sp2Enabled;
        path.BusinessIndicatorLibrary = BusinessIndicatorLibrary;
        export {BusinessIndicatorLibrary};
    //});