import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { RankingMeasureRelationship } from './RankingMeasureRelationship';
import { RankingInterval } from './RankingInterval';
import {DimensionalMeasure} from './DimensionalMeasure'
import {DimensionalMeasureMixin} from './DimensionalMeasureMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasure","appbo/smm/DimensionalMeasureMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasure,DimensionalMeasureMixin
, RankingMeasureRelationship
, RankingInterval){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!RankingMeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RankingMeasureRelationship){
			RankingMeasureRelationship = importPath.RankingMeasureRelationship;
		}
        else {
            import('./RankingMeasureRelationship').then(({ default: RankingMeasureRelationship }) => {
                RankingMeasureRelationship = RankingMeasureRelationship;
            });
            /*require(["appbo/smm/RankingMeasureRelationship"], function (loadedModule) {
                RankingMeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!RankingInterval){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RankingInterval){
			RankingInterval = importPath.RankingInterval;
		}
        else {
            import('./RankingInterval').then(({ default: RankingInterval }) => {
                RankingInterval = RankingInterval;
            });
            /*require(["appbo/smm/RankingInterval"], function (loadedModule) {
                RankingInterval = loadedModule;
			});*/
		}
	}
	export class RankingMeasureMixin {

	defaults(){
		var ret = {
			type: "smm_RankingMeasure"
		}
		return jQuery.extend(DimensionalMeasure.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"smm_RankingMeasure",
			key:"rankingTo",
			relatedModel:"smm.RankingMeasureRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"from",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_RankingMeasure",
			key:"interval",
			relatedModel:"smm.RankingInterval",
			reverseRelation: {
				key:"intervalOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!RankingMeasureMixin.cummulativeRelations) {
            RankingMeasureMixin.cummulativeRelations = _.union(RankingMeasureMixin.getMixinRelations()
                , DimensionalMeasureMixin.getCumulativeMixinRelations()
            );
        }
		return RankingMeasureMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_DimensionalMeasure"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			//{name : "definition",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "scale",type : "ScaleOfMeasurement",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "customScale",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "source",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "visible",type : "EBoolean",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "formula",type : "EString",defaultValue : "null",containingClass : "smm_DimensionalMeasure" }
		]
	}
	getParent(){
		var container;
		return DimensionalMeasure.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return DimensionalMeasure.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/RankingMeasurePropertiesTemplate.html",
			templateName : "RankingMeasurePropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/RankingMeasureViewModel",
			tabId : "RankingMeasureView",
			tabName: "RankingMeasure"
		}
	}
//#startCustomMethods		
	createInterval(maximumOpenVal,minimumOpenVal,maximumEndPointVal,minimumEndPointVal,val,smiley,smileyColor,correctiveActions){
		var intId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var interval = new RankingInterval({id:intId,name:this.getUniqueProperty('name',this.get('name')+ " GradeInterval",'interval'),maximumOpen:maximumOpenVal,minimumOpen:minimumOpenVal,maximumEndPoint:maximumEndPointVal,minimumEndPoint:minimumEndPointVal, value:val,smiley:smiley,smileyColor:smileyColor,correctiveActions:correctiveActions,intervalOwner:this});
		return interval
	}	
//#endCustomMethods	
    }
	path.RankingMeasureMixin = RankingMeasureMixin;
	
	//return RankingMeasureMixin;
//});