import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as Lawnchair from '../../../../libs/lawnchair/Lawnchair.js'
import {UserPreferences} from '../../../../app/version1/bo/preference/UserPreferences'
import {VMPServerService} from './VMPServerService'
import {PackageReference} from './PackageReference'
import {DataManager} from '../data/DataManager'
import * as bootbox from '../../../../libs/bootbox/bootbox'
//define(["jquery","underscore", "backbone", "appcommon/com/vbee/filesystem/PackageReference", "appcommon/com/vbee/filesystem/GraphService", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "bootbox", "appcommon/com/vbee/utils/gdocs/GDocs", "appbo/preference/UserPreferences", "appcommon/com/vbee/filesystem/VMPServerService","uuidv4","gapiCallback","js/libs/google/buy.js","js/libs/gapiloader/gapi-chrome-apps.js"],

    //function (jQuery, _, Backbone, PackageReference, GraphService, Lawnchair, backboneLawnchair, DataManager, bootbox, GDocs, UserPreferences, VMPServerService,uuidv4) {

	/*if(!UserPreferences){
		require(["appbo/preference/UserPreferences"],function(loadedModule){
			UserPreferences = loadedModule;
		});
	}*/
	
	var Workspace = Backbone.RelationalModel.extend({
    	relations:[
	    	{
				type: Backbone.HasOne, // Use the type, or the string 'HasOne' or 'HasMany'.
				key: 'lastChangeSet',
				relatedModel: 'com.vbee.data.ChangeSet',
				includeInJSON: Backbone.Model.prototype.idAttribute
	    	},
	    	{
				type: Backbone.HasOne, // Use the type, or the string 'HasOne' or 'HasMany'.
				key: 'lastUndoneChangeSet',
				relatedModel: 'com.vbee.data.ChangeSet',
				includeInJSON: Backbone.Model.prototype.idAttribute
	    	},
			{
				type: Backbone.HasMany,
				key: 'packageReference',
				relatedModel: 'com.vbee.filesystem.PackageReference',
				includeInJSON: true,
				reverseRelation: {
					key:"packageReferenceOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
	    	},
			{
				type: Backbone.HasMany,
				key: 'userPreference',
				relatedModel: 'preference.UserPreferences',
				includeInJSON: true,
				reverseRelation: {
					key:"userPreferenceOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
	    	}	    	
    	],
        defaults: function(){
            return {
	            isDefaultRepository: false,
	            s:1,
	            type: "com_vbee_filesystem_Workspace",
	            name:"",
	            description:"",
	            parent:null,
	            version:1000
	        };
        },
        getVersion:function(){
        	return Math.round(this.get('version')/1000);	
        },
        isPersisted:function(){
        	return true;
        },
        initialize: function() {
        	Backbone.Model.prototype.initialize.apply(this, arguments);

            DataManager.getDataManager().addNewObjectToChangedObjects(this);
            //this.on("change",DataManager.getDataManager().addObjectToChangedObjects);
            //this.on("destroy",DataManager.getDataManager().addDeletedObjectToChangedObjects);
            var relations = this.getRelations();
            for(var i=0;i<relations.length;i++){
            	var rel = relations[i];
            	if(rel.collectionType){
            		this.on("add:" + rel.key, DataManager.getDataManager().addRelatedObjectToChangedObjects);
            		this.on("remove:" + rel.key, DataManager.getDataManager().addRelatedDeletedObjectToChangedObjects);
            	}
            }
			var workspaceStore = DataManager.getDataManager().get("workspaceStore");
			if(workspaceStore){
				this.lawnchair = workspaceStore;
			}else{
	            this.lawnchair = new Lawnchair.default({
	                name: "WorkspacesStore",
	                adapter: DataManager.getLanwnchairAdapters()
	            });
	            this.lawnchair.projectId = this.get('id');
	            DataManager.getDataManager().set("workspaceStore",this.lawnchair);
			}
        },
        getNestedParent: function(){
        	return this;
        },
        getParent:function(){
        	return this;
        },
		unregisterModel:function(){
			var self = this;
			var references = self.get('packageReference').models ? self.get('packageReference').models.concat() : [];
			_.each(references,function(reference){
				Backbone.Relational.store.unregister(reference);	
			});
			references.length = 0;
			var userPreferencesModels = [];
			var userPreferences = self.get('userPreference');
			//console.log('unregister ' + userPreferences);
			if(userPreferences instanceof Backbone.Model && userPreferences.models){
				//console.log('unregister done');
				userPreferencesModels = userPreferences.models.concat();
			}
			_.each(userPreferencesModels,function(userPref){
				var preferences = userPref.get('preference') ? userPref.get('preference').models.concat() : [];
				_.each(preferences,function(pref){
					Backbone.Relational.store.unregister(pref);	
				})
				preferences.length = 0;
				Backbone.Relational.store.unregister(userPref);	
			});
			userPreferencesModels.length = 0;
			Backbone.Relational.store.unregister(self);	
		},
/*        commitProject2:function(callback){
        	var self= this;
        	var beepDocumentsFolder;
        	var synchronizer = Synchronizer.getInstance();
			function pickerCallback(id) {
		    		beepDocumentsFolder = id;
		    		DataManager.getDataManager().get('currentWorkspace').set('beepDocumentsFolder',beepDocumentsFolder);
		    		synchronizer.commit(self,beepDocumentsFolder,callback);
			};

			beepDocumentsFolder = DataManager.getDataManager().get('currentWorkspace').get('beepDocumentsFolder');
			if(!beepDocumentsFolder || beepDocumentsFolder === ""){
				var fileSelectorViewModel = GDriveFileSelectorModel.getInstance(DataManager.getDataManager().accessToken,synchronizer,pickerCallback);
				fileSelectorViewModel.loadCommitWizard(beepDocumentsFolder,callback);
        	}else{
        		function commitUsingFolderId(){
        			synchronizer.commit(self,beepDocumentsFolder,callback);
        		}
        		if(!DataManager.getDataManager().get('gapiId')){
        			Workspace.authenticate(commitUsingFolderId);
        		}else{
        			commitUsingFolderId();
        		}
        	}
        },
        commitProject:function(callback){
        	var self = this;
        	var beepDocumentsFolder;
        	var synchronizer = Synchronizer.getInstance();
			function pickerCallback(data) {
			    var url = 'nothing';
			    if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
			      var doc = data[google.picker.Response.DOCUMENTS][0];
			      url = doc[google.picker.Document.URL];
			    }
			    var message = 'You picked: ' + url;
			    if(url !== 'nothing'){
		    		beepDocumentsFolder = data.docs[0].id;
		    		DataManager.getDataManager().get('currentWorkspace').set('beepDocumentsFolder',beepDocumentsFolder);
		    		synchronizer.commit(self,beepDocumentsFolder,callback);
			    }
			};
			beepDocumentsFolder = DataManager.getDataManager().get('currentWorkspace').get('beepDocumentsFolder');
			if(!beepDocumentsFolder || beepDocumentsFolder === ""){
        		this.selectGDriveFile(pickerCallback,"Select Beep documents folder",true);
        	}else{
        		function commitUsingFolderId(){
        			synchronizer.commit(self,beepDocumentsFolder,callback);
        		}
        		if(!DataManager.getDataManager().get('gapiId')){
        			Workspace.authenticate(commitUsingFolderId);
        		}else{
        			commitUsingFolderId();
        		}
        	}
        },
        updateProject: function(callback){
        	var beepDocumentsFolder;
        	var synchronizer = Synchronizer.getInstance();
			beepDocumentsFolder = DataManager.getDataManager().get('currentWorkspace').get('beepDocumentsFolder');
			if(!beepDocumentsFolder || beepDocumentsFolder === ""){
        		alert("Please add the project to repository first.");
        	}else{
        		function updateUsingFolderId(){
        			synchronizer.update(this,beepDocumentsFolder,callback);
        		}
        		if(!DataManager.getDataManager().get('gapiId')){
        			Workspace.authenticate(updateUsingFolderId);
        		}else{
        			updateUsingFolderId();
        		}
        	}

        },
        mergeProject: function(callback){
        	var beepDocumentsFolder;
        	var synchronizer = Synchronizer.getInstance();
			beepDocumentsFolder = DataManager.getDataManager().get('currentWorkspace').get('beepDocumentsFolder');
			if(!beepDocumentsFolder || beepDocumentsFolder === ""){
        		alert("Please add the project to repository first.");
        	}else{
        		function mergeUsingFolderId(){
        			synchronizer.merge(this,beepDocumentsFolder,callback);
        		}
        		if(!DataManager.getDataManager().get('gapiId')){
        			Workspace.authenticate(mergeUsingFolderId);
        		}else{
        			mergeUsingFolderId();
        		}
        	}
        },
        selectGDriveFile: function(handleSelectedFile,title,selectOnlyFolders){
			var viewModel = this;
			var clientId;
  			var pickerApiLoaded = false;
			var oauthToken;
			function loadAPiAndCheckout(authResult){
					oauthToken = authResult.access_token;
					gapi.load('picker', {'callback': onPickerApiLoad});
			};
			Workspace.authenticate(loadAPiAndCheckout);

			function onPickerApiLoad(){
				pickerApiLoaded = true;
        			createPicker();
			}
			var googleapi = {
			    authorize: function(options) {
			        var deferred = jQuery.Deferred();

			        //Build the OAuth consent page URL
			        var authUrl = 'https://accounts.google.com/o/oauth2/auth?' + jQuery.param({
			            client_id: options.client_id,
			            redirect_uri: options.redirect_uri,
			            response_type: 'code',
			            scope: options.scope
			        });

			        //Open the OAuth consent page in the InAppBrowser
			        var authWindow = window.open(authUrl, '_blank', 'location=no,toolbar=no');

			        //The recommendation is to use the redirect_uri "urn:ietf:wg:oauth:2.0:oob"
			        //which sets the authorization code in the browser's title. However, we can't
			        //access the title of the InAppBrowser.
			        //
			        //Instead, we pass a bogus redirect_uri of "http://localhost", which means the
			        //authorization code will get set in the url. We can access the url in the
			        //loadstart and loadstop events. So if we bind the loadstart event, we can
			        //find the authorization code and close the InAppBrowser after the user
			        //has granted us access to their data.
			        jQuery(authWindow).on('loadstart', function(e) {
			            var url = e.originalEvent.url;
			            var code = /\?code=(.+)$/.exec(url);
			            var error = /\?error=(.+)$/.exec(url);

			            if (code || error) {
			                //Always close the browser when match is found
			                authWindow.close();
			            }

			            if (code) {
			                //Exchange the authorization code for an access token
			                jQuery.post('https://accounts.google.com/o/oauth2/token', {
			                    code: code[1],
			                    client_id: options.client_id,
			                    redirect_uri: options.redirect_uri,
			                    grant_type: 'authorization_code'
			                }).done(function(data) {
			                    deferred.resolve(data);
			                }).fail(function(response) {
			                    deferred.reject(response.responseJSON);
			                });
			            } else if (error) {
			                //The user denied access to the app
			                deferred.reject({
			                    error: error[1]
			                });
			            }
			        });

			        return deferred.promise();
			    }
			};

		      function onAuthApiLoad() {
		        window.gapi.auth.authorize(
		            {
		              'client_id': clientId,
		              'scope': scopeData,
		              'immediate': false
		              //redirect_uri: 'urn:ietf:wg:oauth:2.0:oob',
		            },
		            handleAuthResult);
		      }

		      function handleAuthResult(authResult) {
		        if (authResult && !authResult.error) {
		          oauthToken = authResult.access_token;
		          createPicker();
		        }
		      }

		      function retrieveFilesAnded(callback,q1,q2){
		      	var result1;
		      	function executeQ2(result){
		      		result1 = result;
		      		retrieveFiles(mergeByAnding,q2);
		      	}
		      	function mergeByAnding(result){
		      		var mergedResults = new Object();
		      		mergedResults.items = new Array();
		      		for(var i=0;i<result.items.length;i++){
		      			for(var j=0;j<result1.items.length;i++){
		      				if(result.items[i].id === result1.items[j]){
		      					mergedResults.items.push(result.items[i]);
		      					break;
		      				}
		      			}

		      		}
		      		return callback(mergedResults);
		      	}
		      	retrieveFiles(executeQ2,q1);
		      }
			  function retrieveFiles(callback,query) {
				return function(){
				  var retrievePageOfFiles = function(request, result) {
				    request.execute(function(resp) {
				      result = result.concat(resp.items);
				      var nextPageToken = resp.nextPageToken;
				      if (nextPageToken) {
				        request = gapi.client.drive.files.list({
				          'pageToken': nextPageToken
				        });
				        retrievePageOfFiles(request, result);
				      } else {
				        callback(result);
				      }
				    });
				  }
				  var initialRequest = gapi.client.drive.files.list({
				  	'q': query,
				  	'maxResults':5

				  });
				  retrievePageOfFiles(initialRequest, []);
			  };
			};

		      function createPicker() {

		        if (pickerApiLoaded && oauthToken) {
		        	var picker = google.picker;
		        	var viewId = picker.ViewId;
		        	var folderViewId = viewId.FOLDERS;

		        	var docsView = new picker.DocsView(selectOnlyFolders? folderViewId: null);
		        	docsView.setSelectFolderEnabled(true);
		          var picker = new google.picker.PickerBuilder().
		              addView(docsView).
		              setOAuthToken(oauthToken).
		              //setDeveloperKey("AIzaSyDSBsFq-wOLt_HlM2Sp_QdbskiYaFFARaU").
		              setCallback(handleSelectedFile).
		              setTitle(title).
		              build();
		          picker.setVisible(true);
		        }
		      }
        }
        

    Workspace.getWorkspaceWithName = function(name,callback){
    	var self = this;
		var workspaceStore = DataManager.getDataManager().get("workspaceStore");
		workspaceStore.exists (name, function(exists){
			if(exists){
				workspaceStore.get(name,function(){
					DataManager.getDataManager().set('suppressLogging',true);
	    			Workspace.fetch(self.key,workspaceStore,{
	    				success:function(workspaceModel){
	    					DataManager.getDataManager().set('suppressLogging',false);
	    					if(!Backbone.Relational.store.getCollection(Workspace).get(self.key)){
	    						Backbone.Relational.store.register(workspaceModel);
	    					}
	    					callback(workspaceModel)
	    				},
	    				error:function(){
	    					DataManager.getDataManager().set('suppressLogging',false);
	    					console.log('Could not fetch existing project with name:' + this.name);
	    				}
	    			});
				});
			}
			else{
				callback(null);
			}
		});

	};
	
	Workspace.prototype.unregisterModel = function(){
		var self = this;
		var references = self.get('packageReference').models ? self.get('packageReference').models.concat() : [];
		_.each(references,function(reference){
			Backbone.Relational.store.unregister(reference);	
		});
		references.length = 0;
		var userPreferencesModels = [];
		var userPreferences = self.get('userPreference');
		//console.log('unregister ' + userPreferences);
		if(userPreferences instanceof Backbone.Model && userPreferences.models){
			//console.log('unregister done');
			userPreferencesModels = userPreferences.models.concat();
		}
		_.each(userPreferencesModels,function(userPref){
			var preferences = userPref.get('preference') ? userPref.get('preference').models.concat() : [];
			_.each(preferences,function(pref){
				Backbone.Relational.store.unregister(pref);	
			})
			preferences.length = 0;
			Backbone.Relational.store.unregister(userPref);	
		});
		userPreferencesModels.length = 0;
		Backbone.Relational.store.unregister(self);	
	};*/
	}, {});
	Workspace["change"] = function (model, related, destroyedObj, reverseKey) {
        DataManager.getDataManager().addObjectToChangedObjects(model, related, destroyedObj, reverseKey);
    }
    Workspace["destroy"] = function (model, response, options) {
        DataManager.getDataManager().addDeletedObjectToChangedObjects(model, response, options);
    }
	Workspace.prototype.getViewProperties = function(type){
		return {
			templatePath : "appcommon/com/vbee/filesystem/views/explorer/PlansWorkspaceExplorerTemplate.html",
			templateName : "PlansWorkspaceExplorerTemplate",
			viewTypeStr : "appcommon/com/vbee/filesystem/views/explorer/PlansWorkspaceExplorerViewModel",
			tabId : "PlansWorkspaceExplorerView",
			tabName: "PlansWorkspaceExplorerView"
		}
	};
	Workspace.getLastAuthenticatedUser = function(callback){
		DataManager.getDataManager().getWorkspaceDataWithId(window.plansKey,function(plansWSData){
			if(callback){
				callback(plansWSData.get('workspace').get('lastAuthenticatedUserEmail'));
			}
		});
	};
	Workspace.setLastAuthenticatedUser = function(email,callback){
		DataManager.getDataManager().getWorkspaceDataWithId(window.plansKey,function(plansWSData){
			var ws = plansWSData.get('workspace');
			if(ws.get('lastAuthenticatedUserEmail') !== email){
				ws.set('lastAuthenticatedUserEmail',email);
				if(!ws.lanwchair){
					ws.lawnchair = DataManager.getDataManager().get('workspaceStore');
				}
				ws.save();
			}
			if(callback){
				callback();
			}
		});
	};
	/*Workspace.showRegistrationSteps = function () {
        var isRead = UserPreferences.getUserPreference(window.plansKey, "RegistrationStepsRead", "RegistrationStepsRead");
        if (!DataManager.getDataManager().get('useAzure') && DataManager.getDataManager().get('enterpriseEdition') && !isRead) {
            jQuery.get("locale/RegistrationSteps.html", function (data) {
                UserPreferences.getUserPreference(window.plansKey, "RegistrationStepsRead", "RegistrationStepsRead", true);
                bootbox.dialog({
                    title: "<h4 style=\"font-family:Roboto; display: inline-block;\"><b>Registration Steps</b></h4>",
                    message: data,
                    className: "bootboxModal",
                    buttons: {
                        cancel: {
                            label: '<i class="fa fa-times"></i> Close'
                        } 
                    }
                });
                jQuery(".modal-content").draggable({
                    handle: ".modal-header"
                });
                jQuery("#loggedCustomerName").text("Dear " + DataManager.getDataManager().get('gapiDisplayName'));
            }); 
        }
    }*/
	
	/*Workspace.offlineAuthentication = function () {
        jQuery('#products').hide();
        Workspace.getLastAuthenticatedUser(function (emailOffline) {
			function showLoginLink(){
				jQuery('#userFullName').html('Unknown');
                jQuery("#loginHere").remove();
				jQuery('<a style="cursor: pointer;" id="loginHere">Login/Register</a>').insertAfter('#userMailId')
				jQuery('#loginHere').on('click',function(){
					window.showRegistration(true);
				})
			}
            if (emailOffline) {
                DataManager.getDataManager().set('email', emailOffline);
				window.showOpeningPage();
				window.utils.stopSpinner('AuthSpinner');
				jQuery('#RegistrationTemplate').hide();
                var licenseValue = UserPreferences.getUserPreference(window.plansKey, 'License', 'License');
                if (licenseValue != null && licenseValue != "[]") {
                    var lastTimeStamp = UserPreferences.getUserPreference(window.plansKey, 'LicenseTimestamp', 'LicenseTimestamp');
                    var lastLoginDate = new Date(lastTimeStamp);
                    var currentDate = new Date();
                    var days = 86400000;//milliseconds in a day
                    var noofDaysInOffline = 7;
                    var compareDate = new Date(currentDate - (noofDaysInOffline * days));
                    var products = JSON.parse(licenseValue);
                    DataManager.getDataManager().set('products', products);
                    // var purchseEndDate = new Date(products[0].legalEntityList[0].purchaseEndDate);
                    if (compareDate <= lastLoginDate) {  //&& !currentDate <= purchseEndDate
                        var entityArray = new Array();
                        jQuery('#userFullName').text(emailOffline.split('@')[0]);
                        jQuery('#userMailId').text(emailOffline);
                        jQuery("#loginHere").remove();
                        for (var i = 0; i < products.length; i++) {
                            for (var j = 0; j < products[i].legalEntityList.length; j++) {
                                if (entityArray.indexOf(products[i].legalEntityList[j].entityId) == -1) {
									jQuery('#legalEntitySelection').append("<option " + "value=\"" + products[i].legalEntityList[j].entityId + "\">" + products[i].legalEntityList[j].entityName + "</option>");
									entityArray.push(products[i].legalEntityList[j].entityId);
								}
                            }
                        }
						if(entityArray.length > 0){
                            DataManager.getDataManager().setProductLicence(entityArray[0]);
							jQuery('#showLogout').show();
						}
						Workspace.getPhoto(null, emailOffline, function () {
                        });
                    }
                    else {
                        bootbox.alert("Please Re-Login again to use the Enterprise edition.");
						showLoginLink();
                    }
                }else{
                    showLoginLink();
				}
            }else{
                showLoginLink();
			}
            DataManager.getDataManager().set('offLineMode', true);
        });
	}*/
    /*Workspace.authenticate2 = function(callback){
			function opt_callback(access_token){
	        	if(access_token){
        			DataManager.getDataManager().accessToken = access_token.access_token;
					gapi.client.load('plus', 'v1', function() {
	        			var request = gapi.client.plus.people.get({
						  'userId' : 'me'
						});
						request.execute(function(resp) {
							if(!resp || resp.error){
								DataManager.getDataManager().gdocs = null;
								Workspace.getOfflineEmail(callback);
							}else{
								DataManager.getDataManager().set('gapiId',resp.id);
								DataManager.getDataManager().set('email',resp.emails[0].value);
								DataManager.getDataManager().set('gapiDisplayName',resp.displayName);
								DataManager.getDataManager().set('gapiImageUrl',resp.image.url);
								DataManager.getDataManager().set('gapiProfileUrl',resp.url);
								//jQuery("#userImage").attr("src",resp.image.url);
								
								var loadImage = function(uri) {
								  jQuery('#userProfileDiv').show();
								  var xhr = new XMLHttpRequest();
								  xhr.responseType = 'blob';
								  xhr.onload = function() {
								    //document.getElementById("userImage").src = window.URL.createObjectURL(xhr.response);
								    var fileReader = new FileReader();
						            fileReader.onload = function (evt) {
						                // Read out file contents as a Data URL
						                var result = evt.target.result;
						                // Set image src to Data URL
										jQuery("#userNameDP").hide();
										jQuery('#userNameDPBig').hide();
										jQuery("#userImage").show();
										jQuery("#userImageBig").show();
										document.getElementById("userImage").src = result;
										document.getElementById("userImageBig").src = result;
										jQuery('#userImage').css({
									  		  width: '35px',
											  height: '35px',
											  'border-radius': '50%',
											  cursor: 'pointer'
										})
										jQuery('#userImageBig').css({
									  		  width: '70px',
											  height: '70px',
											  'border-radius': '50%'
										})
						                // Store Data URL in localStorage
						                try {
						                    //localStorage.setItem("rhino", result);
						                    DataManager.getDataManager().set('gapiIcon',result);
											UserPreferences.setUserPreference(window.plansKey,"userImage","userImage",result)
						                }
						                catch (e) {
						                    console.log("Storage failed: " + e);
						                }
						            };
								    fileReader.readAsDataURL(xhr.response);
								  }
								  xhr.open('GET', uri, true);
								  xhr.send();
								}
								var drawImage = function(name){
									jQuery('#userProfileDiv').show();
									jQuery('#userImage').remove();
									jQuery('#userImageBig').remove();
									var firstName = name.givenName;
							  		var lastName = name.familyName;
							  		var intials = firstName.charAt(0) + lastName.charAt(0);
								  	jQuery('#userNameDP').text(intials);
								  	jQuery('#userNameDPBig').text(intials);
								  	jQuery('#userNameDPBig').css({
								  		  width: '70px',
										  height: '70px',
										  'border-radius': '50%',
										  background: '#B2E4BD',
										  'font-size': '35px',
										  color: '#0B5D18',
										  'text-align': 'center',
										  'line-height': '68px'
									})
								}

								if(resp.image.isDefault){
									drawImage(resp.name);
								}else{
									loadImage(resp.image.url);
								}
								jQuery('#userFullName').text(resp.displayName);
                                jQuery('#userMailId').text(resp.emails[0].value);
								jQuery('#deleteImage').show();
                                Workspace.setLastAuthenticatedUser(resp.emails[0].value,function(){
                                    var rep = window.plansKey;
									var zoomStr = UserPreferences.getUserPreference(rep,'Zoom','Zoom');
								    if(zoomStr && zoomStr !== ""){
								    	document.documentElement.style.zoom = zoomStr;	
								    }			        		  		
                                    DataManager.getDataManager().getLicenses({
                                        'success': function(response){
                                            if (response.response.details.length == 0)
                                                response = [];
                                            else
                                                response = response.response.details;
                                            UserPreferences.setUserPreference(window.plansKey, 'License', 'License', JSON.stringify(response));
											UserPreferences.setUserPreference(window.plansKey,'LicenseTimestamp','LicenseTimestamp',new Date().getTime());
                                            DataManager.getDataManager().onLicenseUpdate(response);
                                            UserPreferences.getUserPreference(window.plansKey, "RegistrationStepsRead", "RegistrationStepsRead", false);
                                            Workspace.showRegistrationSteps();
						        		  	if(callback){
						        			  	callback(resp);
						        		  	}									
										},
                                        'error': function(response){
                                            var cacheResponse = UserPreferences.getUserPreference(window.plansKey,'License','License');
											if(cacheResponse){
												var cacheTimeStampStr = UserPreferences.getUserPreference(window.plansKey,'LicenseTimestamp','LicenseTimestamp');
												if(cacheTimeStampStr){
													var cacheTimeStamp = parseInt(cacheTimeStampStr);
													var monthTime = 30*24*60*60*1000;
													if(new Date().getTime() - cacheTimeStamp > monthTime){
														DataManager.getDataManager().onLicenseUpdateFailed("License Expired");
														DataManager.getDataManager().licenses = null;
													}else{
														DataManager.getDataManager().onLicenseUpdate(JSON.parse(cacheResponse));
													}
												}else{
													DataManager.getDataManager().onLicenseUpdate(JSON.parse(cacheResponse));
												}
											}else{
												DataManager.getDataManager().onLicenseUpdateFailed(response);	
											}
						        		  	if(callback){
						        			  	callback(resp);
						        		  	}									
										}
									});
								});
							}
						});
					});
        		}else
        		if(callback){
        			callback();
        		}
			}
        	if(!DataManager.getDataManager().gdocs){
	        	DataManager.getDataManager().gdocs = new GDocs();
				var scopeData = ["https://www.googleapis.com/auth/drive.file",
								"https://www.googleapis.com/auth/plus.login",
								"https://www.googleapis.com/auth/plus.me",
								"https://www.googleapis.com/auth/userinfo.email",
								"https://www.googleapis.com/auth/userinfo.profile",
								"https://www.googleapis.com/auth/drive",
								"https://www.googleapis.com/auth/drive.appdata",
								"https://www.googleapis.com/auth/drive.apps.readonly",
								"https://www.googleapis.com/auth/drive.metadata.readonly",
								"https://www.googleapis.com/auth/drive.readonly"];

				function authorize(params, callback) {
					  gapi.auth.authorize(params, function(accessToken) {
					    if (!accessToken) {
					    	console.log("Unauthorized");
					    	DataManager.getDataManager().gdocs = null;
						  	Workspace.getOfflineEmail(callback);
		        		  	if(callback){
		        			  	callback(null);
		        		  	}
					    } else {
					      callback(accessToken);
					    }
					  });
				}
				var params = { 'immediate': false };
                if (!window.utils.checkChrome() || !(chrome && chrome.app && chrome.app.runtime)) {
			    	params.scope = scopeData.join(' ');
			    	params.client_id = "883546729918-9jet22kv56g2rgdd2ooqbged2chin2fr.apps.googleusercontent.com";
			    }
		    	gapi.auth.init(authorize.bind(null, params, opt_callback));
		    }else{
		    	opt_callback();
		    }
        }*/
        /*Workspace.authenticate = function(callback){
			debugger;
			var dataManager = DataManager.getDataManager();
            if (window.ipcRenderer) {
                Workspace.authenticateWindows(callback);
            }else {
				var onAuthenticate = function(response){
					dataManager.set('accessToken',response.idToken);
					dataManager.set('email',response.account.idTokenClaims.emails[0]);
					dataManager.set('gapiDisplayName',response.account.name);
					dataManager.set('gapiId',response.account.homeAccountId);

					/*DataManager.getDataManager().set('gapiId',resp.id);
					DataManager.getDataManager().set('email',resp.emails[0].value);
					DataManager.getDataManager().set('gapiDisplayName',resp.displayName);
					DataManager.getDataManager().set('gapiImageUrl',resp.image.url);
					DataManager.getDataManager().set('gapiProfileUrl',resp.url);
					DataManager.getDataManager().set('accessToken',authResult.access_token);//
				

					var loadImage = function(uri) {
						var xhr = new XMLHttpRequest();
						xhr.responseType = 'blob';
						xhr.onload = function() {
						//document.getElementById("userImage").src = window.URL.createObjectURL(xhr.response);
						var fileReader = new FileReader();
						fileReader.onload = function (evt) {
							// Read out file contents as a Data URL
							var result = evt.target.result;
							document.getElementById("userImage").src = result;
							document.getElementById("userImageBig").src = result;
							jQuery('#userImage').css({
								width: '35px',
								height: '35px',
								'border-radius': '50%',
								cursor: 'pointer'
							})
							jQuery('#userImageBig').css({
								width: '70px',
								height: '70px',
								'border-radius': '50%'
							})
							try {
								DataManager.getDataManager().set('gapiIcon',result);
							}
							catch (e) {
								console.log("Storage failed: " + e);
							}
						};
						fileReader.readAsDataURL(xhr.response);
						}
						xhr.open('GET', uri, true);
						xhr.send();
					}

					//loadImage(resp.image.url);
					Workspace.setLastAuthenticatedUser(dataManager.get('email'),function(){
						var rep = window.plansKey;
						var zoomStr = UserPreferences.getUserPreference(rep,'Zoom','Zoom');
						if(zoomStr && zoomStr !== ""){
							document.documentElement.style.zoom = zoomStr;	
						}
						//if (window.utils.checkChrome()) {
							DataManager.getDataManager().getLicenses({
								'success': function (response) {
									UserPreferences.setUserPreference(window.plansKey, 'License', 'License', JSON.stringify(response));
									UserPreferences.setUserPreference(window.plansKey, 'LicenseTimestamp', 'LicenseTimestamp', new Date().getTime());
									DataManager.getDataManager().onLicenseUpdate(response);
									if (callback) {
										callback(authResult);
									}
								},
								'error': function (response) {
									var cacheResponse = UserPreferences.getUserPreference(window.plansKey, 'License', 'License');
									if (cacheResponse) {
										var cacheTimeStampStr = UserPreferences.getUserPreference(window.plansKey, 'LicenseTimestamp', 'LicenseTimestamp');
										if (cacheTimeStampStr) {
											var cacheTimeStamp = parseInt(cacheTimeStampStr);
											var monthTime = 30 * 24 * 60 * 60 * 1000;
											if (new Date().getTime() - cacheTimeStamp > monthTime) {
												DataManager.getDataManager().onLicenseUpdateFailed("License Expired");
												DataManager.getDataManager().licenses = null;
											} else {
												DataManager.getDataManager().onLicenseUpdate(JSON.parse(cacheResponse));
											}
										} else {
											DataManager.getDataManager().onLicenseUpdate(JSON.parse(cacheResponse));
										}
									} else {
										DataManager.getDataManager().onLicenseUpdateFailed(response);
									}
									if (callback) {
										callback(authResult);
									}
								}
							});
						/*} else {
							if (callback) {
								callback(authResult);
							}
						}//
					});
				
				}
				/*var myParams = {
					'clientid' : '883546729918-9jet22kv56g2rgdd2ooqbged2chin2fr.apps.googleusercontent.com',
					'cookiepolicy' : 'single_host_origin',
					'callback' : onAuthenticate,
					'scope' : scopeData.join(' ')
				};
				gapi.auth.signIn(myParams);//
				DataManager.getDataManager().authorize(onAuthenticate)
			}/*else{
				Workspace.getOfflineEmail(callback);
			}//

        };*/
        Workspace.logoutcall = function () {
            var dataManager = DataManager.getDataManager();
            if (dataManager) {
                DataManager.getDataManager().get('router').navigate("/", { trigger: true });
                jQuery('#userFullName').html('Unknown');
                jQuery('#loginHere').remove();
                jQuery('<a style="cursor: pointer;" id="loginHere">Sign In/Sign Up</a>').insertAfter('#userMailId')
                jQuery('#loginHere').on('click', function () {
                    self.showRegistration(true);
                })
                dataManager.set('appCache', {});
                DataManager.getDataManager().set("legalEntityDetails", null);
                DataManager.getDataManager().set("currentLegalEntity", null);
                window.utils.stopSpinner('logout');
                DataManager.getDataManager().onLicenseUpdate([]);
            }
        }
        Workspace.logoutWindows = function () {
            var dataManager = DataManager.getDataManager();
            if (dataManager) {
                dataManager.set('email', null);
                dataManager.set('displayName', null);
                dataManager.set('accessToken', null);
                dataManager.set("serverRoles", null);
                dataManager.clearContextMenus();
                jQuery('#products').hide();
            }    
			window.removeLocalStorage("myRoles");
            window.removeLocalStorage("id_token");
            window.removeLocalStorage("access_token");
            window.removeLocalStorage("server_access_token" + "_vmpServerHost");
            window.removeLocalStorage("server_access_token" + "_vmpSnapshotHost");
            //jQuery('#userProfileDiv').hide();
			jQuery('#showLogout').hide();
			jQuery('#userMailId').text('');
			Workspace.setLastAuthenticatedUser(null,function(){
				Workspace.getPhoto(null, '', function () {});
				//var redirectUrl = "https://localhost:3000/login"
				var redirectUrl = DataManager.getDataManager().get('vmpServerHost')+'/signout';
				var url = "https://login.microsoftonline.com/"+ DataManager.getDataManager().getWindowsTenant() +"/oauth2/logout?post_logout_redirect_uri="+encodeURI(redirectUrl);	
				//var url = "https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri="+encodeURI(redirectUrl);
				/*utils.httpRequest("GET", url,function(response){//not asking password second time
					logCallback();
				});*/
				if (!window.utils.checkChrome()) {
					/*var remote = require('electron').remote;
					var BrowserWindow = remote.getGlobal('browserWindow');

					logoutWindow = new BrowserWindow({
						autoHideMenuBar: true,
						frame: true,
						height: 600,
						show: false,
						icon: './img/VMP-96px.png',
						webPreferences: {
							nodeIntegration: false,
							devTools: false
						},
						width: 800,
					});
					//logoutWindow.webContents.openDevTools();
					
					logoutWindow.webContents.on("will-navigate", function (event, newUrl) {debugger
						utils.httpRequest("GET", newUrl,function(response){
							//logoutWindow.destroy();
                        });
                        Workspace.logoutcall();
						setTimeout(function(){
							if(logoutWindow){
								logoutWindow.destroy();
							}
						},1000);
					});
					
					logoutWindow.on("closed", function () {
                        logoutWindow.destroy();
						logoutWindow = null;
                    });

					logoutWindow.loadURL(url);
					logoutWindow.show();*/
				} else {
					window.showUrlDialog(url, "Logout", null, function (arg) {
						//window.closeUrlDialog(arguments[1]);
						}, null,  function () {
					});
					setTimeout(function(){
						bootbox.hideAll();
						var webview = jQuery.find('#webview');
						if(webview && webview.length > 0){
							webview[0].parentElement.remove();
						}
                        Workspace.logoutcall();
					},5000)
				}
				if(dataManager){
					dataManager.logout();
				}
				utils.httpRequest("GET", redirectUrl,function(response){
				});
			});
        }    

        Workspace.refreshAccessToken = function(callback) {
            Workspace.authenticateWindows(callback); // force login, assume that renewToken.html didn't work which is why dev is calling this.
        }
        Workspace.authenticateAzureWithChrome = function (callback) {
            var handleValidAccessToken = function (idToken,userData,accessCallback) {
				var dataManager = DataManager.getDataManager();
				var mail = dataManager.get('email');
				var displayName = dataManager.get('gapiDisplayName')
				var id = dataManager.get('gapiId');
                jQuery('#userFullName').text(displayName);
                if (mail) {
                    Workspace.setEmailAndGetPreferences(mail);
                    jQuery('#userMailId').text(mail);
					jQuery("#loginHere").remove();
                    jQuery('#showLogout').show();
                } else {
                    if (displayName) {
                        dataManager.set('email', displayName);
                        jQuery('#userMailId').text(displayName);
                        Workspace.setEmailAndGetPreferences(displayName);
                    } else {
                        Workspace.getOfflineEmail(callback);
                        if (accessCallback) {
                            accessCallback(null);
                        }
                        return;
                    }
                }
				Workspace.getPhoto(null, displayName, function(){
					/*if (accessCallback) {
                        accessCallback(null);
                    }*/
				    var service = new VMPServerService();
					service.init(function (token) {
						DataManager.getDataManager().set('vmpServerService', service);
                        DataManager.getDataManager().getLicenses({
                            'success': function (response) {
                                /*var licenseString = JSON.stringify(response);
                                var licenseTimeStamp = new Date().getTime();
                                if (response == null) {
                                    licenseString = null;
                                    licenseTimeStamp = null;
                                }
                                UserPreferences.setUserPreference(window.plansKey, 'License', 'License', licenseString);
                                UserPreferences.setUserPreference(window.plansKey, 'LicenseTimestamp', 'LicenseTimestamp', licenseTimeStamp);*/
								DataManager.getDataManager().onLicenseUpdate(response);
								if (accessCallback) {
									accessCallback(response);
								}
								if(DataManager.getDataManager().get('debugMode')){
									//createSnapshotService();
								}
							},
                            'error': function (response) {
                                var cacheResponse = UserPreferences.getUserPreference(window.plansKey, 'License', 'License');
								if (cacheResponse) {
									var cacheTimeStampStr = UserPreferences.getUserPreference(window.plansKey, 'LicenseTimestamp', 'LicenseTimestamp');
									if (cacheTimeStampStr) {
										var cacheTimeStamp = parseInt(cacheTimeStampStr);
										var monthTime = 30 * 24 * 60 * 60 * 1000;
										if (new Date().getTime() - cacheTimeStamp > monthTime) {
											DataManager.getDataManager().onLicenseUpdateFailed("License Expired");
											DataManager.getDataManager().licenses = null;
										} else {
											DataManager.getDataManager().onLicenseUpdate(JSON.parse(cacheResponse));
										}
									} else {
										DataManager.getDataManager().onLicenseUpdate(JSON.parse(cacheResponse));
									}
								} else {
									DataManager.getDataManager().onLicenseUpdateFailed(response);
								}
								if (accessCallback) {
									accessCallback(resp);
								}
							}
						},null,true);
						//Snapshot service initialization
						function createSnapshotService() {
							var snapshotService = new VMPServerService();
							snapshotService.init(function () {
								DataManager.getDataManager().set('vmpSnapshotService', snapshotService);
							}, DataManager.getDataManager().getWindowsSnapshotClientId(), "vmpSnapshotHost");
						}
					});
				});
            }
            function getNewOrExpiredToken() {
				var dataManager = DataManager.getDataManager();
				dataManager.authorize(function(response){
					dataManager.set('accessToken',response.idToken);
					window.setLocalStorage("access_token", response.idToken );
					dataManager.set('email',response.account.idTokenClaims.emails[0]);
					var displayName = response.account.name;
					if(displayName == "unknown"){
						displayName = response.account.username
					}
					dataManager.set('gapiDisplayName',displayName);
					dataManager.set('gapiId',response.account.homeAccountId);
					handleValidAccessToken(response.idToken, null, callback)
				})
            }
            var token = window.getLocalStorage("access_token", function (result) {
                if (result && typeof result === 'object') {
                    token = result.access_token;
                } else {
                    token = result;
                }
                
                if (!token) {
                    getNewOrExpiredToken();
                } else {
					window.utils.startSpinner('AuthSpinner', "Loading...");
					var dataManager = DataManager.getDataManager();
					var ret = dataManager.selectAccount();
					if(ret == 1){
						handleValidAccessToken(token, null, callback);
					}else{
						getNewOrExpiredToken();
					}
					
                    /*var gs = new GraphService(null, options);
                    var authPromise = gs.isUserAuthenticated();
                    authPromise.then(function (userData) {
					    if(!window.utils.checkChrome()){
						  token = window.localStorage.getItem("access_token");  
						}
                        handleValidAccessToken(token, userData, callback);
                    }).catch(function () {
                        window.removeLocalStorage("id_token");
                        window.removeLocalStorage("access_token");
						DataManager.getDataManager().set("legalEntityDetails", null);
                        DataManager.getDataManager().set("currentLegalEntity", null);
						//bootbox.alert("Your email Id is not supported. Please use a different emailId")
						window.utils.stopSpinner('AuthSpinner');
                        getNewOrExpiredToken();
                    });*/
                }
            });
        };
		Workspace.getPhoto = function (gs,displayName,accessCallback) {
			var me_photo_url = '/v1.0/me/photo/$value';
            var fail_user_photo = "Failed to get your photo!";
            var fail_graph_api = "Failed to the Microsoft Graph API.";
			function drawImage(name){
                jQuery('#userProfileDiv').show();
                //jQuery('#userImage').remove();
                //given name and surname are null so displaying just displayname unlike the name used using google api
                //var splitFirstName = name.split(' ');
				//var lastName = name.familyName; 
                var result =  UserPreferences.getUserPreference(window.plansKey, 'userImage', 'userImage');
				jQuery("#userImage").show();
				jQuery("#userImageBig").show();
				if(result){
					jQuery("#userImage").attr("src", result);
					jQuery("#userNameDP").hide();
					jQuery("#userImageBig")[0].src = result;
					jQuery("#userNameDPBig").hide();
					jQuery('#deleteImage').show();
				}else if(!result && DataManager.getDataManager().get('email')){
					var splitFirstName = DataManager.getDataManager().get('email').split(' ');
					jQuery("#userImage").hide();
					jQuery("#userImageBig").hide();
				    jQuery("#userNameDP").show();
					jQuery("#userNameDPBig").show();
					var initials = splitFirstName[0].charAt(0);
					if(splitFirstName.length > 1){
						initials = initials.concat(splitFirstName[1].charAt(1));
					}
					jQuery('#userNameDP').text(initials);
					jQuery('#userNameDPBig').text(initials);
					jQuery('#userNameDPBig').css({
				  			    background: '#B2E4BD',
							'font-size': '35px',
							color: '#0B5D18',
							'text-align': 'center',
							'line-height': '68px'
					});
				} else {
					jQuery('#userImage').attr('src','img/icons/profileImg_36.png');
					jQuery('#userImageBig').attr('src','img/icons/profileImg_36.png');
					jQuery("#userNameDP").hide();
					jQuery("#userNameDPBig").hide();
					jQuery('#deleteImage').hide();
				}
			}
            if(!gs){//for basic auth in registration page
				drawImage(displayName);
				accessCallback();
			}else {
				drawImage(displayName);
				accessCallback(null);
				// not working for azure
				/*gs.getPhotoRequestPromise(me_photo_url).then(function(data){
					if (data) {
						jQuery('#userProfileDiv').show();
						document.getElementById("userImage").src = data;
						if (accessCallback) {
							accessCallback(null);
						}
					} else {
						console.log(fail_user_photo);
						drawImage(displayName);
						if (accessCallback) {
							accessCallback(null);
						}
					}
				}).catch(function(){
					console.log(fail_graph_api);
					drawImage(displayName);
					if (accessCallback) {
						accessCallback(null);
					}
				});*/
			}
		};
        Workspace.authenticateWindows = function (callback) {
        }


        Workspace.setEmailAndGetPreferences = function(email){
            Workspace.setLastAuthenticatedUser(email,function(){
                /*var rep = window.plansKey;
				var zoomStr = UserPreferences.getUserPreference(rep,'Zoom','Zoom');
			    if(zoomStr && zoomStr !== ""){
                    document.documentElement.style.zoom = zoomStr;
                } 
				var cacheResponse = UserPreferences.getUserPreference(window.plansKey,'License','License');
                if(cacheResponse){
                    var cacheTimeStampStr = UserPreferences.getUserPreference(window.plansKey,'LicenseTimestamp','LicenseTimestamp');
					if(cacheTimeStampStr){
						var cacheTimeStamp = parseInt(cacheTimeStampStr);
						var monthTime = 30*24*60*60*1000;
						if(new Date().getTime() - cacheTimeStamp > monthTime){
							DataManager.getDataManager().onLicenseUpdateFailed("License Expired");
							DataManager.getDataManager().licenses = null;
						}else{
							DataManager.getDataManager().onLicenseUpdate(JSON.parse(cacheResponse));
						}
					}else{
						DataManager.getDataManager().onLicenseUpdate(JSON.parse(cacheResponse));
					}									
				}*/							    
		    });        	
        };
        Workspace.getOfflineEmail = function(callback){
			function setEmailPreference(email){
				DataManager.getDataManager().set('email',email);
				Workspace.setEmailAndGetPreferences(email);
				if(callback){
					  callback(null);
				}
			}
        	Workspace.getLastAuthenticatedUser(function(email){
        		if(!email || email === ""){
        			function promptEmail(){
						bootbox.prompt("You are offline! Please enter your email id to be used to link the offline data", function(result) {
						  if (result === null || result === "") {
						    promptEmail();
						  } else {
						    setEmailPreference(result);
						  }
						});
        			}
        			promptEmail();
        		}else{
					setEmailPreference(email);
        		}
        	});
        };
    DataManager.getDataManager().get('appNS').com.vbee.filesystem.Workspace = Workspace;
    //return Workspace;
	export {Workspace};
//});
