import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasurement} from './DimensionalMeasurement'
import {DimensionalMeasurementMixin} from './DimensionalMeasurementMixin'
import {Operation} from './Operation'
import { DataManager } from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasurement","appbo/smm/DimensionalMeasurementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasurement,DimensionalMeasurementMixin
, Base1MeasurementRelationship
, Base2MeasurementRelationship
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
        else {
            import('./Operation').then(loadedModule => {
                Operation = loadedModule;
            });

			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	export class BinaryMeasurementMixin {
        /*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            path.BinaryMeasurementMixin = BinaryMeasurementMixin;
        }*/
	defaults(){
		var ret = {
			type: "smm_BinaryMeasurement"
		}
		return jQuery.extend(DimensionalMeasurement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		/*{
			type :Backbone.HasOne,
			containingClass:"smm_BinaryMeasurement",
			key:"baseQuery",
			relatedModel:"smm.Operation",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}*/
		])
	}
	static getCumulativeMixinRelations(){
		if (!BinaryMeasurementMixin.cummulativeRelations) {
            BinaryMeasurementMixin.cummulativeRelations = _.union(BinaryMeasurementMixin.getMixinRelations()
                , DimensionalMeasurementMixin.getCumulativeMixinRelations()
            );
        }
		return BinaryMeasurementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_DimensionalMeasurement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "error",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			{name : "breakValue",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			{name : "value",type : "EDouble",defaultValue : "null",containingClass : "smm_DimensionalMeasurement" },
			{name : "isBaseSupplied",type : "EBoolean",defaultValue : "null",containingClass : "smm_BinaryMeasurement" }
		]
	}
	getParent(){
		var container;
		return DimensionalMeasurement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return DimensionalMeasurement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/BinaryMeasurementPropertiesTemplate.html",
			templateName : "BinaryMeasurementPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/BinaryMeasurementViewModel",
			tabId : "BinaryMeasurementView",
			tabName: "BinaryMeasurement"
		}
	}
//#startCustomMethods
	calculate(skipParentCalculation){
		var self = this;
		var oldValue = self.get('value');
		var baseMeasurement1 = self.get('baseMeasurement1To');
		var baseMeasurement2 = self.get('baseMeasurement2To');

		var observerdMeasure = self.get('observedMeasure');
		var measure = observerdMeasure.get('measure');
		
		var baseMeasureRel1 = measure.get('baseMeasure1To');
		var baseMeasureRel2 = measure.get('baseMeasure2To'); 
		
		var rescaledMeasure1 =  baseMeasureRel1.get('rescaledMeasure');
		var multiplier1 = rescaledMeasure1.get('multiplier') || 1;
		var offset1 = rescaledMeasure1.get('offset') || 0; 
		var measurement1Value = baseMeasurement1.get('to').get('value') * multiplier1 + offset1;
		
		var rescaledMeasure2 =  baseMeasureRel2.get('rescaledMeasure');
		var multiplier2 = rescaledMeasure2.get('multiplier') || 1;
		var offset2 = rescaledMeasure2.get('offset') || 0; 
		var measurement2Value = baseMeasurement2.get('to').get('value') * multiplier2 + offset2;
		
		
		var binaryFunctor = measure.get('functor');
		var computedValue = self.calculateValue(binaryFunctor, measurement1Value, measurement2Value);
		self.set('value', computedValue);
		if(oldValue !== computedValue && !skipParentCalculation){
			self.calculateParents();	
		}
	};
	
	calculateValue(operation, measurement1Value, measurement2Value){
		var measurementValue = 0;
			switch(operation){
				case "plus":
					measurementValue = measurement1Value+measurement2Value;
					break;
				case "minus":
				measurementValue = Math.abs(measurement1Value-measurement2Value);
				break;
				case "multiply":
				measurementValue = measurement1Value*measurement2Value;
				break;
				case "divide":
				measurementValue = measurement1Value/measurement2Value;
				break;
			}
		return measurementValue;
	}
//#endCustomMethods	
    }
	path.BinaryMeasurementMixin = BinaryMeasurementMixin;
	
	//return BinaryMeasurementMixin;
//});