import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import { DataManager } from '../../../com/vbee/data/DataManager'
import {Enum} from '../../../../libs/enums/enums'
//define(["require","appcommon/com/vbee/data/DataManager","app/global","enums" ], 
//function(require,DataManager,global){
	var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
	if(!path.Status){
		var Status = new Enum("Initiated","InProgress","Closed");
		path.Status = Status;
	}
	//return path.Status
	export {Status};
//});