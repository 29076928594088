import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BaseMeasurementRelationship} from './BaseMeasurementRelationship'
import {BaseMeasurementRelationshipMixin} from './BaseMeasurementRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { DimensionalMeasurement } from './DimensionalMeasurement'
import { CollectiveMeasurement } from './CollectiveMeasurement'

/*define(["require", "jquery", "underscore", "async", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/smm/BaseMeasurementRelationship", "appbo/smm/BaseMeasurementRelationshipMixin"],
function(require,jQuery,_,async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BaseMeasurementRelationship,BaseMeasurementRelationshipMixin
, DimensionalMeasurement
, CollectiveMeasurement){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!DimensionalMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasurement){
			DimensionalMeasurement = importPath.DimensionalMeasurement;
		}
        else {
            import('./DimensionalMeasurement').then(({ default: DimensionalMeasurement }) => {
                DimensionalMeasurement = DimensionalMeasurement;
            });

			/*require(["appbo/smm/DimensionalMeasurement"],function(loadedModule){
				DimensionalMeasurement = loadedModule;
			});*/
		}
	}
	if(!CollectiveMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.CollectiveMeasurement){
			CollectiveMeasurement = importPath.CollectiveMeasurement;
		}
        else {
            import('./CollectiveMeasurement').then(({ default: CollectiveMeasurement }) => {
                CollectiveMeasurement = CollectiveMeasurement;
            });

			/*require(["appbo/smm/CollectiveMeasurement"],function(loadedModule){
				CollectiveMeasurement = loadedModule;
			});*/
		}
	}
	export class BaseNMeasurementRelationshipMixin {
        /*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            path.BaseNMeasurementRelationshipMixin = BaseNMeasurementRelationshipMixin;
        }*/
	defaults(){
		var ret = {
			type: "smm_BaseNMeasurementRelationship"
		}
		return jQuery.extend(BaseMeasurementRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"smm_BaseNMeasurementRelationship",
			key:"to",
			relatedModel:"smm.DimensionalMeasurement",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"baseNMeasurementRelationship",
				type :Backbone.HasMany,
				includeInJSON:"id"
			}				
		},
		])
	}
	static getCumulativeMixinRelations(){
		if (!BaseNMeasurementRelationshipMixin.cummulativeRelations) {
            BaseNMeasurementRelationshipMixin.cummulativeRelations = _.union(BaseNMeasurementRelationshipMixin.getMixinRelations()
                , BaseMeasurementRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return BaseNMeasurementRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_BaseMeasurementRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            //{ name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" }
		]
	}
	getParent(){
		var container;
		return BaseMeasurementRelationship.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return BaseMeasurementRelationship.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/BaseNMeasurementRelationshipPropertiesTemplate.html",
			templateName : "BaseNMeasurementRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/BaseNMeasurementRelationshipViewModel",
			tabId : "BaseNMeasurementRelationshipView",
			tabName: "BaseNMeasurementRelationship"
		}
	}
//#startCustomMethods
    onToRemoved(model, newTo, options) {
        
		if(Backbone.skipPersistance){
			return;
		}
		if(!newTo && options.__related && !options.creatingRevision){
			var parent = model.get('from');
			model.destroy();
			var mc = parent?parent.get('measuredCharacteristic'):null;
			if(mc && mc.getMeasurand()){
				var measurand = mc.getMeasurand();
				if(measurand && measurand.cacheUpdateValueMeasure){
					if(!parent.get('observedMeasure') || !parent.get('observedMeasure').get('observation')){
						return;
					}
					var context = parent.get('observedMeasure').get('observation').getContext();
					if(!context){
						return;
					}
					while(context.get('parentContext')){
						context = context.get('parentContext');
					}
					var viewAlternative = context.getAlternative();
					//var oldBaseMeasure = model.previousAttributes().to.previousAttributes().observedMeasure.previousAttributes().measure;
					//var oldBaseComponent = model.previousAttributes().to.previousAttributes().measuredCharacteristic.getMeasurand();
					if(viewAlternative){
						measurand.cacheUpdateValueMeasure(viewAlternative,function(){
						});
					}
					else {
						context.getAlternativeAsync(function(viewAlternative){
							if(viewAlternative) {
								measurand.cacheUpdateValueMeasure(viewAlternative,function(){
								});
							}
						});
					}
					
				}else{
					parent.calculate();
				}				
			}
		}
	};
	clone(data, mest, callback) {
	    var relId = DataManager.getDataManager().guidGeneratorByOwner(this);
        var mestRel = new this.constructor({ id: relId, name: this.get('name'), description: this.get('description'), measurementRelationshipsOwner: mest });
        mestRel.set('definition', this.get('definition'));
	    var dimMestOld = this.get('to');
	    var mesChar = dimMestOld.get('measuredCharacteristic');
	    var timestamp = mest.get('observedMeasure').get('observation').get('whenObserved');
	    var mestObsContext = dimMestOld.get('observedMeasure').get('observation').getContext();
	    function findAndSetDimMest() {
	        var mests = mesChar.get('measurement');
	        var found = false;
	        var targetMest;
	        for (var i = 0; i < mests.length; i++) {
	            targetMest = mests.at(i);
	            var obs = targetMest.get('observedMeasure').get('observation');
	            var targetMestContext = obs.getContext();
	            if (obs.get('whenObserved') === timestamp && targetMestContext===mestObsContext) {
            		 found = true;
            		 break;
	            }
	        }
	        if (found) {
	            mestRel.set('to', targetMest);
                mestRel.set('from', mest);
                mestRel.get('from').get('baseMeasurements').add(mestRel.get('to'));
                //mest.calculate(); fails for cyclic calc(tax payer)
	            if (callback) {
	                callback(mestRel);
	            }
	        } else {
	            setTimeout(findAndSetDimMest, 50);
	        }
	    }
	    findAndSetDimMest();
	}
//#endCustomMethods	
    }
	path.BaseNMeasurementRelationshipMixin = BaseNMeasurementRelationshipMixin;
	
	//return BaseNMeasurementRelationshipMixin;
//});