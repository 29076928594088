import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasurement} from './DimensionalMeasurement'
import {DimensionalMeasurementMixin} from './DimensionalMeasurementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { RankingMeasurementRelationship } from './RankingMeasurementRelationship'
import { Operation } from './Operation'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasurement","appbo/smm/DimensionalMeasurementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasurement,DimensionalMeasurementMixin
, RankingMeasurementRelationship
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!RankingMeasurementRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RankingMeasurementRelationship){
			RankingMeasurementRelationship = importPath.RankingMeasurementRelationship;
		}
        else {
            import('./RankingMeasurementRelationship').then(({ default: RankingMeasurementRelationship }) => {
                RankingMeasurementRelationship = RankingMeasurementRelationship;
            });
            /*require(["appbo/smm/RankingMeasurementRelationship"], function (loadedModule) {
                RankingMeasurementRelationship = loadedModule;
			});*/
		}
	}
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
        else {
            import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });
            /*require(["appbo/smm/Operation"], function (loadedModule) {
                Operation = loadedModule;
			});*/
		}
	}
	export class RankingMeasurementMixin {

	defaults(){
		var ret = {
			type: "smm_RankingMeasurement"
		}
		return jQuery.extend(DimensionalMeasurement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"smm_RankingMeasurement",
			key:"rankingTo",
			relatedModel:"smm.RankingMeasurementRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"from",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}/*,
		{
			type :Backbone.HasOne,
			containingClass:"smm_RankingMeasurement",
			key:"baseQuery",
			relatedModel:"smm.Operation",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}*/
		])
	}
	static getCumulativeMixinRelations(){
		if (!RankingMeasurementMixin.cummulativeRelations) {
            RankingMeasurementMixin.cummulativeRelations = _.union(RankingMeasurementMixin.getMixinRelations()
                , DimensionalMeasurementMixin.getCumulativeMixinRelations()
            );
        }
		return RankingMeasurementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_DimensionalMeasurement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "error",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			{name : "breakValue",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			{name : "value",type : "EDouble",defaultValue : "null",containingClass : "smm_DimensionalMeasurement" },
			{name : "isBaseSupplied",type : "EBoolean",defaultValue : "null",containingClass : "smm_RankingMeasurement" }
		]
	}
	getParent(){
		var container;
		return DimensionalMeasurement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return DimensionalMeasurement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/RankingMeasurementPropertiesTemplate.html",
			templateName : "RankingMeasurementPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/RankingMeasurementViewModel",
			tabId : "RankingMeasurementView",
			tabName: "RankingMeasurement"
		}
	}
//#startCustomMethods
	createRankingMesurementRelationship(relName,relDescription,sourceMeasurement){
		var relId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var rel = new RankingMeasurementRelationship({id:relId,name:relName,description:relDescription,measurementRelationshipsOwner:this});
		this.set('rankingTo', rel);//to trigger changelog as reverse association not triggering changelog
		rel.set({'from':this,'to':sourceMeasurement});
		return rel;
	};
	
	calculate(skipParentCalculation){
		var self = this;
		var oldValue = self.get('value');
		var observerdMeasure = self.get('observedMeasure');
		var rankingMeasurementRel = self.get('rankingTo');
		var measure = observerdMeasure.get('measure');
		var rankingInterval = measure.get('interval');
		var rankValue = 0;
		if(rankingMeasurementRel && rankingMeasurementRel.get('to')) {
			rankValue = parseFloat(rankingMeasurementRel.get('to').get('value'));
		}
		self.set('value', null);
		var intervalSet = false;
		rankingInterval.each(function(intv){
			var maxep = parseFloat(intv.get('maximumEndPoint'));
			var maxOpen = intv.get('maximumOpen');
			var minep = parseFloat(intv.get('minimumEndPoint'));
			var minOpen = intv.get('minimumOpen');
			var intervalValue = intv.get('value');
			var inInterval = false;
			
			if(maxOpen && minOpen){
				if(rankValue >= minep && rankValue <= maxep ){
					inInterval = true;
				}
			}else if(maxOpen){
				if(rankValue > minep && rankValue <= maxep ){
					inInterval = true;
				}
			}else if(minOpen){
				if(rankValue >= minep && rankValue < maxep ){
					inInterval = true;
				}
			}
			else{
				if(rankValue > minep && rankValue < maxep ){
					inInterval = true;
				}
			
			}
			if(inInterval && !intervalSet){
				intervalSet = true;
				self.set('value', intervalValue);
			}
		});
		if(self.get('value') !== oldValue && !skipParentCalculation){
			self.calculateParents();	
		}
	};

    clone(data, obsMes, callback) {
        var self = this;
	    var mestId = DataManager.getDataManager().guidGeneratorByOwner(this);
        var mest = new this.constructor({ id: mestId, name: this.get('name'), description: this.get('description'), observedMeasure: obsMes });
        var val = data ? data[this.get('measuredCharacteristic').id] : undefined;
        mest.set('value', val ? val : this.get('value'));
	    this.get('measuredCharacteristic').get('measurement').add(mest);
        this.get('rankingTo').clone(data, mest, function () {
            //mest.cloneParentRelationships(function () {
                if (callback) {
                    callback(mest);
                }
            //});
	    });
	    return mest;
	}
//#endCustomMethods	
    }
	path.RankingMeasurementMixin = RankingMeasurementMixin;
	
	//return RankingMeasurementMixin;
//});