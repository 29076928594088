import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import {UserPreferences} from '../../../../bo/preference/UserPreferences'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'

//define([/*"require",*/"jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global", "appbo/preference/UserPreferences"],
//function(/*require,*/$,_, Backbone,ko, koMapping,kb,bootbox,DataManager,global,UserPreferences)
//{
	var path = DataManager.getDataManager().buildAppNsPath("tickets.views.properties",global.version);
	export class CoursesViewModel {
		
		afterRenderView(node,view) {
			var self = view;
			if(self.options.selectedId){
			   $('#userStoresTable').show();
			}
		};

		onShowInfo(evt) {
		    var url;
		    var infoButton = $(evt.currentTarget);
			if(infoButton && infoButton.length == 0){
				infoButton = event.currentTarget;
				url = infoButton.getAttribute('url');
			}
		    if (url) {
		        window.open(url, "Info");
		    }
		};
		onShowGuidance(evt) {
		    var url;
		    var guideButton = $(evt.currentTarget);
			if(guideButton && guideButton.length == 0){
				guideButton = event.currentTarget;
				url = guideButton.getAttribute('url');
			}
		    if (url) {
		        /*if (!window.utils.checkChrome()) {
					var BrowserWindow = require('electron').remote.BrowserWindow;
                    var PDFWindow = require('electron-pdf-window');
                    var win = new BrowserWindow({ width: 700, height: 800,icon: './img/VMP-96px.png'});
					PDFWindow.addSupport(win);
					win.loadURL(url);
				}else{*/
                    //window.showUrlDialog(url, "User Guide");
					window.open(url, "User Guide");
				//}
			}
		};
		
		cleanModal(){
			var self=this;
			window.cleanDialogModel(self.encodeId,self);
		};

	init(model,options,detailedView){
		var self = this;
		this.CoursesViewModel = this;
		this.model = model;
		this.id = kb.observable(model, 'id');
		this.options = options;
		function htmlEscape(str) {
    		return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '');
		}
		this.encodeId = options.addOptions.viewId;

		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),[
		   'Close'
		]);	
	}
	
	static getInstance (model,options){
		var view = new CoursesViewModel(model,options);
		view.init(model, options);
		return view;
	};
}
path.CoursesViewModel = CoursesViewModel;