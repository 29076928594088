import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelItem} from './BusinessModelItem'
import {BusinessModelItemMixin} from './BusinessModelItemMixin'
import {Participant} from './Participant'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelItem","appbo/vdml/BusinessModelItemMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelItem,BusinessModelItemMixin
, Participant){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Participant){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Participant){
			Participant = importPath.Participant;
		}
		else{
			import('./Participant').then(({ default: Participant }) => {
                Participant = Participant;
            });
			/*require(["appbo/vdml/Participant"],function(loadedModule){
				Participant = loadedModule;
			});*/
		}
	}
	export class BusinessModelNetworkPartnerMixin {

	defaults(){
		var ret = {
			type: "vdml_BusinessModelNetworkPartner"
		}
		return jQuery.extend(BusinessModelItem.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"vdml_BusinessModelNetworkPartner",
			key:"networkPartner",
			relatedModel:"vdml.Participant",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessModelNetworkPartner",
			key:"networkPartnerRole",
			relatedModel:"vdml.Role",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!BusinessModelNetworkPartnerMixin.cummulativeRelations) {
            BusinessModelNetworkPartnerMixin.cummulativeRelations = _.union(BusinessModelNetworkPartnerMixin.getMixinRelations()
                , BusinessModelItemMixin.getCumulativeMixinRelations()
            );
        }
		return BusinessModelNetworkPartnerMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_BusinessModelItem"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("bmNetworkPartnerOwner") ? this.get("bmNetworkPartnerOwner") : this.previousAttributes().bmNetworkPartnerOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("bmNetworkPartnerOwner") ? this.get("bmNetworkPartnerOwner") : this.previousAttributes().bmNetworkPartnerOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BusinessModelNetworkPartnerPropertiesTemplate.html",
			templateName : "BusinessModelNetworkPartnerPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BusinessModelNetworkPartnerViewModel",
			tabId : "BusinessModelNetworkPartnerView",
			tabName: "BusinessModelNetworkPartner"
		}
	}
//#startCustomMethods	
	createNetworkPartner(type){
		var bm = this.get('bmNetworkPartnerOwner');
		var bmPack = bm.getNestedParent();
		var networkPartner;
		if(type === Community || type === OrgUnit){
			networkPartner = new type({name:this.get('name'),description:this.get('description'),collaborationOwner:bmPack});
		}else{
			networkPartner = new type({name:this.get('name'),description:this.get('description'),actorOwner:bmPack});
		}
		this.set('networkPartner',networkPartner);
		return networkPartner;
	};

	getRelatedNetworkPartnerBMCs(callback){
		function funnel(oldDocs,newDocs){
			var ret = [];
			for(var i=0;i<oldDocs.length;i++){
				for(var j=0;j<newDocs.length;j++){
					if(oldDocs[i] === newDocs[j]){
						ret.push(newDocs[i]);
					}
				}
			}
			oldDocs.splice(0,oldDocs.length);
			newDocs.splice(0,newDocs.length);
			return ret;
		}	
		function createCollection(ret){
			var retColl = new Backbone.Collection();
			for(var i=0;i<ret.length;i++){
				var model = new Backbone.Model({id:ret[i].id,name:ret[i].name,version:ret[i].version,parent:ret[i].parent,parentName:ret[i].parentName,type:ret[i].type});
				retColl.push(model);
			}
			callback(retColl);
		}		
		var bm = this.get('bmNetworkPartnerOwner');
		
		var dataManager = DataManager.getDataManager();
		var rdfModel = DataManager.getDataManager().get("rdfModel");
		
		var ounUri = rdfModel.rdf.resolve("vbi:" + this.get('networkPartner').get('id'));
		var oubUri = rdfModel.rdf.resolve("vbi:" + bm.get('business').get('id'));
		var bmUri = rdfModel.rdf.resolve("vbi:" + bm.get('id'));
		
		
		var ouBmUri = rdfModel.rdf.resolve("vbc:" + "vdml_OrgUnit-businessModel");
		var collNetUri = rdfModel.rdf.resolve("vbc:" + "vdml_BusinessModel-participantNetwork");
		var collNetCollUri = rdfModel.rdf.resolve("vbc:" + "vdml_ParticipantNetwork-collaboration");
		var bmCustUri = rdfModel.rdf.resolve("vbc:" + "vdml_BusinessModel-bmCustomer");
		var bmRoleUri = rdfModel.rdf.resolve("vbc:" + "vdml_BusinessModel-bmRole");
		var bmRoleProvidedPropUri = rdfModel.rdf.resolve("vbc:" + "vdml_Role-providedProposition");
		var bmRoleReceivedPropUri = rdfModel.rdf.resolve("vbc:" + "vdml_Role-receivedProposition");
		var bmActivityUri = rdfModel.rdf.resolve("vbc:" + "vdml_BusinessModel-bmActivity");
		var bmVPUri = rdfModel.rdf.resolve("vbc:" + "vdml_BusinessModel-bmValueProposition");
		var bmVPVPUri = rdfModel.rdf.resolve("vbc:" + "vdml_BusinessModelValueProposition-valueProposition");
		var bmActivityActivityUri = rdfModel.rdf.resolve("vbc:" + "vdml_BusinessModelActivity-activity");
		var bmCustCustUri = rdfModel.rdf.resolve("vbc:" + "vdml_BusinessModelCustomer-customer");
		
		function checkSharedNetworkPartners(){
			var queryExtension = "FILTER EXISTS { ";
			queryExtension = queryExtension + " <" + ounUri + "> <" + ouBmUri + "> " + " ?document . ";
			queryExtension = queryExtension + " ?document <" + bmCustUri + "> " + " ?bmCustomer . ";
			queryExtension = queryExtension + " ?bmCustomer <" + bmCustCustUri + "> <"  + oubUri + "> . ";
			queryExtension = queryExtension + " } ";
			function checkUnspecifiedNPAndFunnel(docs){
				if(docs.length === 0){
					checkUnspecifiedNetworkPartners();
				}
				checkSharedCollaborations(docs);
			}
			DataManager.getDataManager().get('rdfModel').getAllDocumentsOfType("vdml_BusinessModel",checkUnspecifiedNPAndFunnel,queryExtension);			
		}
		function checkUnspecifiedNetworkPartners(){
			var queryExtension = "FILTER EXISTS { ";
			queryExtension = queryExtension + " <" + ounUri + "> <" + ouBmUri + "> " + " ?document . ";
			queryExtension = queryExtension + " ?document <" + bmCustUri + "> " + " ?bmCustomer . ";
			queryExtension = queryExtension + " } ";
			function checkUnspecifiedNPAndFunnel(docs){
				if(docs.length === 0){
					createCollection(docs);
				}else{
					checkSharedCollaborations(docs);	
				}
				
			}
			DataManager.getDataManager().get('rdfModel').getAllDocumentsOfType("vdml_BusinessModel",checkUnspecifiedNPAndFunnel,queryExtension);			
		}
		
		
		function checkSharedCollaborations(docs){
			var queryExtension = " FILTER EXISTS { <" + ounUri + "> <" + ouBmUri + "> " + " ?document . ";
			queryExtension = queryExtension + " ?document <" + collNetUri + "> " + " ?collNetc . ";
			queryExtension = queryExtension + " ?collNetc <" + collNetCollUri + "> " + " ?colaboration . ";
			queryExtension = queryExtension + " <" + bmUri + "> <" + collNetUri + "> " + " ?collNetb .  ";
			queryExtension = queryExtension + " ?collNetb <" + collNetCollUri + "> " + " ?colaboration . ";
			queryExtension = queryExtension + " } ";
			function funnelInput(newDocs){
				var funneledDocs = funnel(docs,newDocs);
				if(funneledDocs.length > 0){
					checkSharedRoles(funneledDocs);	
				}else{
					createCollection(newDocs);
				}
			}
			DataManager.getDataManager().get('rdfModel').getAllDocumentsOfType("vdml_BusinessModel",funnelInput,queryExtension);			
		}
		function checkSharedRoles(docs){
			var queryExtension = " FILTER EXISTS { <" + ounUri + "> <" + ouBmUri + "> " + " ?document . ";
			queryExtension = queryExtension + " ?document <" + bmRoleUri + "> " + " ?bmRole . ";
			queryExtension = queryExtension + " <" + bmUri + "> <" + bmRoleUri + "> " + " ?bmRole . } ";
			function funnelInput(newDocs){
				var funneledDocs = funnel(docs,newDocs);
				if(funneledDocs.length > 0){
					checkSharedActivities(funneledDocs);
				}else{
					createCollection(newDocs);
				}
			}
			DataManager.getDataManager().get('rdfModel').getAllDocumentsOfType("vdml_BusinessModel",funnelInput,queryExtension);				
		}
		
		function checkSharedActivities(docs){
			var queryExtension = " FILTER EXISTS { <" + ounUri + "> <" + ouBmUri + "> " + " ?document . ";
			queryExtension = queryExtension + " ?document <" + bmActivityUri + "> " + " ?bmActivityc . ";
			queryExtension = queryExtension + " ?bmActivityc <" + bmActivityActivityUri + "> " + " ?activity . } ";
			queryExtension = queryExtension + " <" + bmUri + "> <" + bmActivityUri + "> " + " ?bmActivityb . ";
			queryExtension = queryExtension + " ?bmActivityb <" + bmActivityActivityUri + "> " + " ?activity . } ";
			
			function funnelInput(newDocs){
				var funneledDocs = funnel(docs,newDocs)
				
				if(funneledDocs.length > 0){
					checkSharedVPProvided(funneledDocs);
				}else{
					createCollection(newDocs);
				}				
			}
			DataManager.getDataManager().get('rdfModel').getAllDocumentsOfType("vdml_BusinessModel",funnelInput,queryExtension);				
		}
		function checkSharedVPProvided(docs){
			var queryExtension = " FILTER EXISTS { <" + ounUri + "> <" + ouBmUri + "> " + " ?document . ";
			queryExtension = queryExtension + " ?document <" + bmVPUri + "> " + " ?bmVPr . ";
			queryExtension = queryExtension + " ?bmVPr <" + bmVPVPUri + "> " + " ?valuePropositionReceived . ";
			queryExtension = queryExtension + " ?document <" + bmRoleUri + "> " + " ?bmRoler . } ";	
			queryExtension = queryExtension + " ?bmRoler <" + bmRoleReceivedPropUri + "> " + " ?valuePropositionReceived . } ";
			
			queryExtension = queryExtension + " ?bmUri <" + bmVPUri + "> " + " ?bmVPp . ";
			queryExtension = queryExtension + " ?bmVPp <" + bmVPVPUri + "> " + " ?valuePropositionProvided . ";
			queryExtension = queryExtension + " ?bmUri <" + bmRoleUri + "> " + " ?bmRolep . } ";	
			queryExtension = queryExtension + " ?bmRolep <" + bmRoleProvidedPropUri + "> " + " ?valuePropositionProvided . } ";			
			function funnelInput(newDocs){
				var funneledDocs = funnel(docs,newDocs);
				
				if(funneledDocs.length > 0){
					checkSharedVPReceived(funneledDocs);
				}else{
					createCollection(newDocs);
				}				
			}
			DataManager.getDataManager().get('rdfModel').getAllDocumentsOfType("vdml_BusinessModel",funnelInput,queryExtension);			
		}
		
		function checkSharedVPReceived(docs){
			var queryExtension = " FILTER EXISTS { <" + ounUri + "> <" + ouBmUri + "> " + " ?document . ";
			queryExtension = queryExtension + " ?document <" + bmVPUri + "> " + " ?bmVPp . ";
			queryExtension = queryExtension + " ?bmVPp <" + bmVPVPUri + "> " + " ?valuePropositionProvided . ";
			queryExtension = queryExtension + " ?document <" + bmRoleUri + "> " + " ?bmRolep . } ";	
			queryExtension = queryExtension + " ?bmRolep <" + bmRoleProvidedPropUri + "> " + " ?valuePropositionProvided . ";			
			queryExtension = queryExtension + " ?bmUri <" + bmVPUri + "> " + " ?bmVPr . ";
			queryExtension = queryExtension + " ?bmVPr <" + bmVPVPUri + "> " + " ?valuePropositionReceived . ";
			queryExtension = queryExtension + " ?bmUri <" + bmRoleUri + "> " + " ?bmRoler . } ";	
			queryExtension = queryExtension + " ?bmRoler <" + bmRoleReceivedPropUri + "> " + " ?valuePropositionReceived . } ";			
			function funnelInput(newDocs){
				var funneledDocs = funnel(docs,newDocs);
				if(funneledDocs.length > 0){
					createCollection(funneledDocs);
				}else{
					createCollection(newDocs);
				}				
			}
			DataManager.getDataManager().get('rdfModel').getAllDocumentsOfType("vdml_BusinessModel",funnelInput,queryExtension);				
		}
		checkSharedNetworkPartners();
	};
//#endCustomMethods
	
	}
	path.BusinessModelNetworkPartnerMixin = BusinessModelNetworkPartnerMixin;
	//return BusinessModelNetworkPartnerMixin;
//});