import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AbstractMeasureElement} from './AbstractMeasureElement'
import {AbstractMeasureElementMixin} from './AbstractMeasureElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/AbstractMeasureElement","appbo/smm/AbstractMeasureElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AbstractMeasureElement,AbstractMeasureElementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	export class UnitOfMeasureMixin {

	defaults(){
		var ret = {
			type: "smm_UnitOfMeasure",
			roundingDigits : 4,
			significantDecimals : 2
		}
		return jQuery.extend(AbstractMeasureElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!UnitOfMeasureMixin.cummulativeRelations) {
            UnitOfMeasureMixin.cummulativeRelations = _.union(UnitOfMeasureMixin.getMixinRelations()
                , AbstractMeasureElementMixin.getCumulativeMixinRelations()
            );
        }
		return UnitOfMeasureMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_AbstractMeasureElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            //{ name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" }
			//{name : "roundingDigits",type : "EString",defaultValue : 6 ,containingClass : "smm_UnitOfMeasure" },
			//{name : "significantDecimals",type : "EString",defaultValue : 0 ,containingClass : "smm_UnitOfMeasure" }
		]
	}
	getParent(){
		var container;
		return AbstractMeasureElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return AbstractMeasureElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/UnitOfMeasurePropertiesTemplate.html",
			templateName : "UnitOfMeasurePropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/UnitOfMeasureViewModel",
			tabId : "UnitOfMeasureView",
			tabName: "UnitOfMeasure"
		}
	}
	
	}
	path.UnitOfMeasureMixin = UnitOfMeasureMixin;
	//return UnitOfMeasureMixin;
//});