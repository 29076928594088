import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { MeasurableElement } from './MeasurableElement'
import { PortMixin } from './PortMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { MeasuredCharacteristic } from './MeasuredCharacteristic'
import { Expression } from './Expression'
import { Role } from './Role'
import { BindingOperationReference } from './BindingOperationReference'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/PortMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,PortMixin
, MeasuredCharacteristic

, Expression
, Role
, BindingOperationReference){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
        else {
            import('./MeasuredCharacteristic').then(loadedModule => {
                MeasuredCharacteristic = loadedModule;
            });
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	
	if(!Expression){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Expression){
			Expression = importPath.Expression;
		}
        else {
            import('./Expression').then(loadedModule => {
                Expression = loadedModule;
            });
			/*require(["appbo/vdml/Expression"],function(loadedModule){
				Expression = loadedModule;
			});*/
		}
	}
	if(!Role){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Role){
			Role = importPath.Role;
		}
        else {
            import('./Role').then(loadedModule => {
               // Role = loadedModule;
            });
			/*require(["appbo/vdml/Role"],function(loadedModule){
				Role = loadedModule;
			});*/
		}
	}
	if(!BindingOperationReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BindingOperationReference){
			BindingOperationReference = importPath.BindingOperationReference;
		}
        else {
            import('./BindingOperationReference').then(loadedModule => {
                BindingOperationReference = loadedModule;
            });
			/*require(["appbo/vdml/BindingOperationReference"],function(loadedModule){
				BindingOperationReference = loadedModule;
			});*/
		}
	}
	var Port = MeasurableElement.extend(utils.customExtends({
		relations:PortMixin.getMixinRelations(),
		subModelTypes: {
			vdml_InputPort : 'vdml.InputPort',
			vdml_OutputPort : 'vdml.OutputPort'
		},
		initialize: function(attributes, options) {
			MeasurableElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new PortMixin()
	));
	
	Port.abstract = true;
	path.Port = Port;
    export { Port };
//});