import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import { PackageReference } from '../../../../../com/vbee/filesystem/PackageReference'
import { BeepPackageMixin } from '../../../../bo/beeppackage/BeepPackageMixin'
import { StrategyMapDiagram } from '../../../../bo/vdml/StrategyMapDiagram'
import { EcoMap } from '../../../../bo/ecomap/EcoMap'
//import {PackageReference as PlanPackageReference} from '../../../../../version1/bo/transformation/PackageReference'

/*define(["require", "jquery", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "appbo/beeppackage/BeepPackageMixin", "appbo/transformation/PackageReference", "appcommon/com/vbee/filesystem/PackageReference","appbo/vdml/StrategyMapDiagram","appbo/ecomap/EcoMap","app/global"],
	function (require, $, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, BeepPackageMixin, PlanPackageReference, PackageReference, StrategyMapDiagram, EcoMap, global)
{*/
	var path = DataManager.getDataManager().buildAppNsPath("strategyMap.views.designer",global.version);
		export class StrategyMapDetailsViewModel {
    init(model, options) {
		var self = this;
		this.StrategyMapDetailsViewModel = this;
		this.model = model;
		this.id = kb.observable(self.model, 'id');
		function htmlEscape(str) {
    		return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '');
		}
		this.encodeId = htmlEscape(this.id());
		this.enableDelete = ko.observable(true);
		this.enableComplete = ko.observable(true);
		this.name = ko.observable(self.model ? self.model.get('name') : null);
		this.description = ko.observable(self.model ? self.model.get('description') : "");
		var fsize = self.model ? self.model.get('fontsize') : 12;
		this.fontsize = ko.observable(fsize ? fsize : 12);
		this.enableCopy = ko.observable( true);
		this.currentAlt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
		/*this.importDiagramList = ko.observableArray();
		this.importDiagram = ko.observable();
		this.alternativeModel = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));*/

		StrategyMapDetailsViewModel.prototype.dispose = function(){
			var self = this;
			window.cleanViewModel(self);		
		}; 
		
		this.name.subscribe(function(val){
			self.description(val);
		});
		
		/*if(window.checkContextForRevision()) {
			self.enableDelete(false);
		}*/
		
	    this.saveStrategyMapDetails = function(){
	    	if(self.name() !== ""){
	    		if(self.name().trim() != self.model.get('name')){
	    			self.model.set('name',self.name().trim());
	    			window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'),DataManager.getDataManager().get('viewAlternative'),self.StrategyMapDetailsViewModel);
	    		}
				self.model.set('description',self.description().trim());
				var defaultFontSize = self.model.get('fontsize') ? self.model.get('fontsize') : 12;
				if(self.fontsize() && defaultFontSize != self.fontsize()){
					self.model.set('fontsize',self.fontsize());
					window.utils.startSpinner('fontsizespinner', "Applying Font Size ...");
					self.model.getNestedParent().refreshLabels(window.vdmModelView.modeler,self.fontsize(),function(){
						window.utils.stopSpinner('fontsizespinner');
					});
				}	
			}		

		};

        if (DataManager.getDataManager().get('viewAlternative') !== DataManager.getDataManager().get('currentWorkspace').get('id')) {
            $('#copy').hide();
		}
		this.copyStrategyMap = function(){
			function savePackage(view, modalId, type, moduleTypes) {
		        window.utils.startSpinner('copyStrategyMapSpinner', "Copying Strategy Map...");
			    setTimeout(function () {
                    self.onAddStrategyMap(view.name(), view.description(), view.selectedEcoMapPackage(), null, null, self.currentAlt, type, moduleTypes);
			        window.cleanDialogModel(modalId, view);
			        window.utils.stopSpinner('copyStrategyMapSpinner');
		        },100)
	        }
		    var plan = DataManager.getDataManager().get('currentPlan');
			var mapTitle = DataManager.getDataManager().get('localeManager').get("StrategyMap");
			var addOptions = { 'alternative': self.currentAlt, 'plusIconType': 'valueCreationArrow', onlyMap:mapTitle};
			//window.getAndCreateModalDialog(self.model, htmlEscape(self.id()), BusinessModelMixin, plan, "CreatePackage", savePackage, addOptions);
			window.getAndCreateModalDialog(self.model, htmlEscape(self.id()), BeepPackageMixin, plan, "Discover", savePackage, addOptions);
		};
		this.onAddStrategyMap = function (name, description, pack, phase, alternative, currentAlternative, type, moduleTypes) {
		    var self = this;
		    if (pack !== null && pack.id) {
		        var altId = DataManager.getDataManager().getRepositoryId(pack.id);
		        DataManager.getDataManager().fetchDocumentFromPackage(pack.id, "appbo/ecomap/EcoMap", pack.version, pack.id, "appbo/ecomap/EcoMap", DataManager.getDataManager().getVDMStore(altId), {
		            success: function (model) {
		                if (altId !== currentAlternative.get('id')) {
		                	var ecoPackage = self.model.getNestedParent();
							var depPacks = ecoPackage.get('dependentPackage')?ecoPackage.get('dependentPackage').models.concat():[];
		                    self.createRevisionBasedOnContext(model,depPacks,function (newPackage) {
		                        var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
		                        var altId = DataManager.getDataManager().getRepositoryId(newPackage.id);
								var strategyMap = newPackage.get('diagrams').findWhere({ 'id': altId + bmId });
								var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
		                        var diagrams = newPackage.get('diagrams');
		                        for (var i = 0; i < diagrams.length; i++) {
		                            newPackage.fixDiagramOnRevision(diagrams.at(i), altId, oldAltId);
		                        }
		                        //window.utils.startSpinner('revisionSpinner', "Creating a copy...");
		                        //setTimeout(function(){
		
		                        //model.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), function (newPackage) {
		                        window.utils.stopSpinner('revisionSpinner');
		                        self.createStrategyMap(name, description, newPackage, type, moduleTypes);
		                        //});
		                        //},10);
		                    });
		                } else {
		                    self.createStrategyMap(name, description, model, type, moduleTypes);
		                }
		            },
		            error: function (error) {
		                console.log('Unable to load selected Package');
		            }
		        });
		    } else {
		        self.createStrategyMap(name, description, null, currentAlternative, type, moduleTypes);
		    }
		};
    	
		this.createStrategyMap = function (name, description, strategyMapPackage, currentAlternative, type) {
	        var self = this;
	        if (!strategyMapPackage) {
	                var preWorkspace = DataManager.getDataManager().get('currentWorkspace');
					if(window.checkContextForRevision()){
					    var workspaceAlt = DataManager.getDataManager().getWorkspaceDataWithIdSync(self.currentAlt.get('id')).get('workspace');
					    DataManager.getDataManager().set('currentWorkspace', workspaceAlt);
					    DataManager.getDataManager().set('currentWorkSpaceKey', workspaceAlt.get('id'));
					}
					strategyMapPackage = EcoMap.createPackageWithName(name + " Package", description, currentAlternative);
					DataManager.getDataManager().get('currentWorkspace').save();
	                self.createStrategyMapDiagram(strategyMapPackage, name, description, null);
	                bootbox.alert("Copy of Strategy Map Completed");
					DataManager.getDataManager().set('currentWorkspace', preWorkspace);
					DataManager.getDataManager().set('currentWorkSpaceKey', preWorkspace.get('id'));
	        } else {
                self.createStrategyMapDiagram(strategyMapPackage, name, description, null);
	            bootbox.alert("Copy of Strategy Map Completed");
			}
	    };

	    this.createStrategyMapDiagram = function (pack, name, description, valternative) {
	        var self = this;
	        var altId = DataManager.getDataManager().get('viewAlternative');
	        if (altId) {
	            DataManager.getDataManager().getAlternative(altId, function (alternative) {
	                var diagramInstance = StrategyMapDiagram.getInstance(name, description, pack);
	                copyDataToStrategyMap(diagramInstance,self);
	            });
	        }
	    }
        function copyDataToStrategyMap(diagramInstance,self) {
            //var self = this;
	    	var xml = self.model.get('data');
			var oldId = "s"+ window.utils.htmlEscape(self.model.id);
			var newId = "s"+ window.utils.htmlEscape(diagramInstance.id);
			xml = self.model.getNestedParent().fixXMLOnRevision(xml,oldId,newId);
			diagramInstance.set('data',xml);
            if (self.model.get('value').length>0){
                _.each(self.model.get('value').models,function(bm){
                    diagramInstance.get('value').push(bm);
	    		});	
	    	}
            if (self.model.get('activity').length>0){
                _.each(self.model.get('activity').models,function(part){
                    diagramInstance.get('activity').push(part);
	    		});	
	    	}
            if (self.model.get('competence').length>0){
                _.each(self.model.get('competence').models,function(vp){
                    diagramInstance.get('competence').push(vp);
	    		});	
	    	}
	    }
		/*this.importStrategyMapDiagrams = function(){
			var self = this;
			function loadStrategyMapPackage(strategyMapPackage) {
                var referenceExists = self.alternativeModel.get('phaseDesignPart').findWhere({ beepReference: strategyMapPackage.get('id') });
                if (!referenceExists || strategyMapPackage.get('id').indexOf("Common") > -1) {
                    return;
                } else {
                	var pack = Backbone.Relational.store.getObjectByName('ecomap.StrategyMap').find({id:strategyMapPackage.get('id')});
                	if(pack){
                		var diag = pack.get('diagrams');
                		for(var j=0;j<diag.length;j++){
                			if(diag.at(j) !== self.model){
                				self.importDiagramList.push({ id: diag.at(j).get('id'), name: diag.at(j).get('name')});
                			}
                		}
                	}
                }
            }
            DataManager.getDataManager().getAllDocumentsCollectionOfType(self.alternativeModel, 'ecomap_StrategyMap', function(results){
            	self.importDiagramList.push({ id: '', name: 'Select Diagram'});
            	for (var i = 0; i < results.length; i++) {
                        var type = results.at(i).get('type');
                        if (type === "ecomap_StrategyMap") {
                            loadStrategyMapPackage(results.at(i));
                        }
           		}
            	
            }, true, null, null, 'vbc:cmof_EObject-name');

		};
		
		self.importStrategyMapDiagrams();*/
		
		this.createRevisionBasedOnContext = function(pack,skipDependentPackages,callback){
			if(window.checkContextForRevision()) {
				window.utils.startSpinner('revisionSpinner', "Creating a copy...");
				function fetchBmModel(newVdmPackage){
						if(newVdmPackage) {
                            DataManager.getDataManager().releaseSaveLock();
		        			DataManager.getDataManager().set('isActive',false);
							window.utils.stopSpinner('revisionSpinner');
		        			callback(newVdmPackage);
							/*DataManager.getDataManager().saveData({
								success:function(){
									window.utils.stopSpinner('revisionSpinner');
		        					callback(newVdmPackage);
								}, 
								error:function(){
									window.utils.stopSpinner('revisionSpinner');
		        					callback(newVdmPackage);
								}, 
								persist:true});	*/
						}
					}
				window.setTimeout(function(){
                    DataManager.getDataManager().acquireSaveLock(function () {
                        pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel, skipDependentPackages);
                    });
	        	},100);	
        	}
        	else {
        		callback(pack);
        	}
		};
		
        this.deleteStrategyMap = function () {
            var self = this;
			window.vdmModelView.enableComplete(false);
			if(window.checkContextForRevision()) {
				var ecoPackage = self.model.getNestedParent();
				var depPacks = ecoPackage.get('dependentPackage')?ecoPackage.get('dependentPackage').models.concat():[];
				/*if(ecoPackage.get('diagrams').length === 1) {
					var alternativeModel = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
					var phaseDesignPartRef = alternativeModel.get('phaseDesignPart').findWhere({'beepReference':ecoPackage.id});
					phaseDesignPartRef && phaseDesignPartRef.destroy();
					DataManager.getDataManager().get('router').navigate(DataManager.getDataManager().get('viewAlternative'), {trigger: true});
				}else {*/
					self.createRevisionBasedOnContext(ecoPackage,depPacks,function(newEcoPackage){
						var len = depPacks.length;
						while(len--){
							newEcoPackage.get('dependentPackage').remove(depPacks[len]);
						}
						var bmId = self.model.id.substr(self.model.id.lastIndexOf('@')+1,self.model.id.length);
			       		var altId = DataManager.getDataManager().getRepositoryId(newEcoPackage.id);			                	
			   			var ecoToBeDeleted = newEcoPackage.get('diagrams').findWhere({'id':altId+bmId});
			   			ecoToBeDeleted.destroy();
			   			DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});	
					});

				/*}*/
			}else {	
				if(self.model.get('data')){					
                    bootbox.confirm('' + DataManager.getDataManager().get('localeManager').get('MapDeleteAlert', 'Strategy Map'), function(result) {
					if(result) {
						self.model.destroy();
						DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});			        									    				    	
					}    
					});
				}else{					
					self.model.destroy();
					DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});
				}
			}
		};			
		
		       
		this.afterRenderView = function(){ 
		}
		if(window.checkContextForRevision()) {
			self.enableDelete(false);
		}
	}
	checkUniqueValue = function(view,event){
		var self = this;
		var name = event.currentTarget.value.trim();
		if(name !== ""){
			if(self.model.get('name') === name){
				$('#duplicateName').text("");
				$("#complete").removeAttr('disabled');
				return;
			}
			var unique = window.isUniqueName(name,null,self.id(),self.model.getNestedParent().get('diagrams'));
			if(unique){
				$('#duplicateName').text("");
				$("#complete").removeAttr('disabled');
			}
			else{
				$('#duplicateName').text("" + DataManager.getDataManager().get('localeManager').get('DuplicateAlert','Strategy Map'));
				$("#complete").attr('disabled','disabled');
			}
		}
		else{
			$("#complete").attr('disabled','disabled');
		}
	};
	limiter(view,event) {
		var input = event.currentTarget;
		if (input.value < 10) input.value = 10;
		if (input.value > 50) input.value = 50;
	}
	
	static getInstance(model,options){
		var view = new StrategyMapDetailsViewModel();
		view.init(model, options);
		return view;
	};
}
path.StrategyMapDetailsViewModel = StrategyMapDetailsViewModel;