import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { PackageReference } from '../../../../../com/vbee/filesystem/PackageReference'
import { ValueDeliveryModelMixin } from '../../../../bo/vdml/ValueDeliveryModelMixin'
import { ValueDeliveryModel } from '../../../../bo/vdml/ValueDeliveryModel'
import { CapabilityCategory } from '../../../../bo/vdml/CapabilityCategory'
import { CapabilityDefinition } from '../../../../bo/vdml/CapabilityDefinition'
import { EcoMapDiagramMixin } from '../../../../bo/vdml/EcoMapDiagramMixin'
import { BusinessModel } from '../../../../bo/vdml/BusinessModel'
import { ValueProposition } from '../../../../bo/vdml/ValueProposition'
import { Participant } from '../../../../bo/vdml/Participant'
import { OrgUnit } from '../../../../bo/vdml/OrgUnit'
import { ScenarioProxy } from '../../../../bo/transformation/ScenarioProxy'

/*define(["require", "jquery", "underscore", 'async', "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/BusinessModel", "appbo/vdml/ValueProposition", "appbo/vdml/Participant", "appbo/vdml/ValueDeliveryModel", "appcommon/com/vbee/filesystem/PackageReference", "appbo/transformation/PackageReference", "appbo/transformation/ScenarioProxy", "appbo/vdml/EcoMapDiagramMixin","appbo/vdml/CapabilityCategory", "appbo/vdml/CapabilityDefinition", "appbo/vdml/OrgUnit","appbo/vdml/ValueDeliveryModelMixin"],
function (require, $, _, async, Backbone, ko, koMapping, kb, bootbox, DataManager, global, BusinessModel, ValueProposition, Participant, ValueDeliveryModel, PackageReference, PlanPackageReference, ScenarioProxy, EcoMapDiagramMixin, CapabilityCategory,CapabilityDefinition,OrgUnit,ValueDeliveryModelMixin) {*/

	var path = DataManager.getDataManager().buildAppNsPath("capabilityLibrary.views.designer",global.version);
export class CapabilityMappingWizardViewModel {
    constructor(modelElement, model, options,  diagramBO, callback){
		this.init(modelElement, model, options,  diagramBO, callback);
	}
	init(modelElement, model, options,  diagramBO, callback) {
        var self = this;
        this.CapabilityMappingWizardViewModel = this;
        this.model = model;
        this.modelElement = modelElement;
        this.callback = callback;
        this.shapeTypeFromWizard = ko.observable();
        this.id = ko.observable();
        this.showRadioButtons = ko.observable(false);
        if (options.shapeTypeFromWizard) {
            this.shapeTypeFromWizard(options.shapeTypeFromWizard);
            this.id(options.idFromWizard);
        } else {
            this.id(self.model ? self.model.get('id') : null);
        }
        this.diagramBO = diagramBO;
        this.options = options;
        this.mappedDiagramBO = null;
        if (diagramBO.mid) {
            this.mappedDiagramBO = diagramBO;
        }
        this.capabilityColl = new Backbone.Collection();
        this.capLibList = ko.observableArray([]);
        this.parentView = options.parentView;
        //this.allShapesList = self.parentView.shapesList();
        this.packagesList = ko.observableArray([]);
        //this.selectedPackage = ko.observable();
        this.selectedCapLib = ko.observable();
        this.enableComplete = ko.observable(false);
        this.selectedMappingCL;
        if (!self.parentView.modeler) {
            var presentationModels = self.parentView.charts()
            for (var i = 0; i < presentationModels.length; i++) {
                if (self.model == presentationModels[i].viewInstance.model) {
                    self.parentView = presentationModels[i].viewInstance;
                }
            }
        }
        this.eventBus = self.parentView.modeler.get('eventBus');
        this.elementFactory = self.parentView.modeler.get('elementFactory');
        this.modeling = self.parentView.modeler.get('modeling');
        this.elementRegistry = self.parentView.modeler.get('elementRegistry');
        function htmlEscape(str) {
            return String(str)
                .replace(/@/g, '')
                .replace(/ /g, '');
        }
        this.encodeId = htmlEscape(this.id());
        this.mappingObject = {};
        this.mappingObject['vdml:CapabilityCategory'] = { name: 'Capability Category', type: 'vdml.CapabilityCategory' };
        this.mappingObject['vdml:CapabilityDefinition'] = { name: 'Capability Definition', type: 'vdml.CapabilityDefinition' };
        this.mappingObject['vdml:Capability'] = { name: 'Capability ', type: 'vdml.Capability' };
        this.shapeTypesList = ko.observableArray([{ name: 'Capability Category', type: 'vdml.CapabilityCategory' }, { name: 'Capability Definition', type: 'vdml.CapabilityDefinition' }, { name: 'Capability ', type: 'vdml.Capability' }]);
        var currentAltId = DataManager.getDataManager().get('viewAlternative');
        var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
        this.currentAlternative = docTypeObj.find({ id: currentAltId });
        self.parentCapabilities = [];
        this.selectedCapLib.subscribe(function (cl) {
            if ($('#capLibField' + self.encodeId) && !$('#capLibField' + self.encodeId).prop('disabled')) {
                self.loadcapabilities(cl);
                if (self.bmTypeahead) {
                    self.bmTypeahead.rerender([]);
                }
                $('#selectCap' + self.encodeId).val(self.options.shape.businessObject.name);
                $('#selectCap' + self.encodeId).keyup();
            }
        });


        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'mapItem',
            'prev',
            'next',
            'selectMappingElement',
            'selectDimensionTitle'
            , 'provider'
            , 'recipient'
            , 'SelectRole'
            , 'selectCreateVP'
            , 'BusinessModel'
            , 'selectCreateBM'
            , 'selectCreatePN'
            , 'selectCreateParticipant'
            , 'selectCreatePN'
            , 'selectElementType'
            , 'Role'
            , 'SelectParticipant'
            , 'inRole'
            , 'MyProposition'
            , 'Details'
            , 'Complete'
        ]);

    }

    cleanUp() {
        var self = this;
        self.parentView = null;
    }
    /*this.selectedPackage.subscribe(function(pack){
          loadCapabilityLibraries(pack);
    });*/

    startWizard(cond) {
        var self = this;
        var viewModel = this;
        var modalContentNode = $('#wizardModalContent');
        modalContentNode.css('width', 'auto');
        modalContentNode.css('height', 'auto');
        modalContentNode.css('minHeight', 'auto');
        ko.unapplyBindings(modalContentNode, false);
        modalContentNode.children().remove();
        window.utils.startSpinner('createSpinner', "Loading Data...");
        var templates = window.utils.getHtmlPage('CapabilityMappingWizardTemplate');
        //$.get("js/app/version1/views/capabilityLibrary/views/designer/CapabilityMappingWizardTemplate.html", function (templates) {
            if (document.getElementById('wizardTemplateContent')) {
                return;
            }
            modalContentNode.append(templates);
            var templateNode = $('#wizardTemplateContent');
            ko.applyBindings(viewModel, templateNode[0]);
            viewModel.modelElement.modal('toggle');
            viewModel.$wizard = $('#NewWizard').wizard(cond);
            viewModel.handleWizardButtons();
            viewModel.afterRenderView();
        //});
    }
    fillPackagesBM() {
        var self = this;
    self.packagesList().length = 0;
    var packages = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: self.diagramBO.$parent.mid });
    //var packages = _.filter(self.currentAlternative.get('phaseDesignPart').models, function(packagesRef){  if(packagesRef.get('beepType') === 'vdml_ValueDeliveryModel' && packagesRef.get('beepReference').indexOf('Common') == -1){return packagesRef} });
    var tempPackageArray = [];
    if (self.diagramBO.$parent && self.diagramBO.$parent.mpid) {
        var parentPack = _.filter(packages, function (pack) { if (pack.get('beepReference') === self.diagramBO.$parent.mpid) { return pack; } });
        var tempObj = { id: parentPack[0].get('beepReference'), name: parentPack[0].get('name'), version: parentPack[0].get('version'), type: parentPack[0].get('beepType') };
        tempPackageArray.push(tempObj);
        self.packagesList(tempPackageArray);
    } else {
        for (var i = 0; i < packages.length; i++) {
            /*var referenceExists = self.currentAlternative? self.currentAlternative.get('phaseDesignPart').findWhere({beepReference:result.at(i).get('id')}) : null;
            if(!referenceExists){
                continue;
            }*/
            var obj = packages[i];
            tempObj = { id: obj.get('beepReference'), name: obj.get('name'), version: obj.get('version'), type: obj.get('beepType') };
            tempPackageArray.push(tempObj);
        }
        if (self.mappedDiagramBO) {
            self.packagesList(tempPackageArray);
            $('#packageField' + self.encodeId).attr('disabled', 'disabled');
        } else {
            self.packagesList(tempPackageArray);
            self.packagesList.push({ name: "New" });
            /*if(!self.providerVPShape && !self.receiverVPShape){
                self.packagesList.push({name:"New"});
            }*/
        }
    }

    //}, false,null,true);
}
    loadcapabilities(cl) {
        var self = this;
        self.capabilityColl.reset();
        if (self.matchField) {
            self.matchField.hide();
            $('#showMappedInfoCL' + self.encodeId).hide();
        }
        if (cl.name === "New") {
            return
        } else {
            if (self.diagramBO.$parent.mid) {
                var type = self.diagramBO.$parent.$type.replace(':', '.');
                var parentCap = Backbone.Relational.store.getObjectByName(type).find({ 'id': self.diagramBO.$parent.mid });
                //var capLib = Backbone.Relational.store.getObjectByName("vdml.CapabilityLibrary").find({ id: cl.id });
                //$('#showMappedInfoCL'+self.encodeId).show();
                if (parentCap.get('capabilityOwner').id === cl.id) {
                    _.each(parentCap.get('childCapability').models, function (cap) {
                        if (self.diagramBO.$type === "vdml:CapabilityCategory") {
                            if (cap.get('type') === "vdml_CapabilityCategory") {
                                self.capabilityColl.add(cap);
                            }
                        } else if (self.diagramBO.$type === "vdml:CapabilityDefinition") {
                            if (cap.get('type') === "vdml_CapabilityDefinition") {
                                self.capabilityColl.add(cap);
                            }
                        } else {
                            self.capabilityColl.add(cap);
                        }
                    });
                }
            } else {
                var capLib = Backbone.Relational.store.getObjectByName("vdml.CapabilityLibrary").find({ id: cl.id });
                //$('#showMappedInfoCL'+self.encodeId).show();
                _.each(capLib.get('capability').models, function (cap) {
                    if (self.diagramBO.$type === "vdml:CapabilityCategory") {
                        if (cap.get('type') === "vdml_CapabilityCategory") {
                            if (cap.get('parentCapability').length > 0) {
                                var exits;
                                _.each(cap.get('parentCapability').models, function (parentCap) {
                                    exits = _.filter(self.parentView.shapesList(), function (shape) { if (shape.businessObject.mid === parentCap.get('id')) { return true } });
                                    if (exits.length > 0) {
                                        return exits;
                                    }
                                })
                                if (exits.length === 0) {
                                    self.capabilityColl.add(cap);
                                }
                            } else {
                                self.capabilityColl.add(cap);
                            }
                            self.capabilityColl.add(cap);
                        }
                    } else if (self.diagramBO.$type === "vdml:CapabilityDefinition") {
                        if (cap.get('type') === "vdml_CapabilityDefinition") {
                            if (cap.get('parentCapability').length > 0) {
                                var exits;
                                _.each(cap.get('parentCapability').models, function (parentCap) {
                                    exits = _.filter(self.parentView.shapesList(), function (shape) { if (shape.businessObject.mid === parentCap.get('id')) { return true } });
                                    if (exits.length > 0) {
                                        return exits;
                                    }
                                })
                                if (exits.length === 0) {
                                    self.capabilityColl.add(cap);
                                }
                            } else {
                                self.capabilityColl.add(cap);
                            }
                        }
                    } else {
                        if (cap.get('parentCapability').length > 0) {
                            var exits;
                            _.each(cap.get('parentCapability').models, function (parentCap) {
                                exits = _.filter(self.parentView.shapesList(), function (shape) { if (shape.businessObject.mid === parentCap.get('id')) { return true } });
                                if (exits.length > 0) {
                                    return exits;
                                }
                            })
                            if (exits.length === 0) {
                                self.capabilityColl.add(cap);
                            }
                        } else {
                            self.capabilityColl.add(cap);
                        }
                    }
                });
            }
        }
    }
    loadAllCapabilityLibraries() {
        var self = this;
        var currentPlan = DataManager.getDataManager().get('currentPlan');
        currentPlan.loadCapabilityLibraries(function (capLibsCollectionArray) {
            async.eachSeries(capLibsCollectionArray, function (capLibPack, capLibPackPackHandled) {
                async.eachSeries(capLibPack.models, function (capLib, cPackHandled) {
                    self.capLibList.push({ id: capLib.get('id'), name: capLib.get('name'), version: capLib.get('version'), type: capLib.get('type') });
                    cPackHandled();
                }, function () {
                    capLibPackPackHandled();
                });
            });
            self.capLibList.push({ name: "New" });
        });
    }

    afterRenderView() {
        var self = this;
        var wizardName = "wizardModalContent";
        if (self.shapeTypeFromWizard()) {
            wizardName = "wizardModalContent1";
        }

        $('#' + wizardName).resizable();
        $('#' + wizardName).draggable({
            containment: "#content"
        });
        $('#' + wizardName).on("resize", function (event, ui) {// setting min height of the template, to prevent buttons and content from overflowing
            ui.element.css("minHeight", $("#wizardTemplateContent").actual('outerHeight'));
        });
        //$('.ui-icon').hide();
        if (self.shapeTypeFromWizard()) {
            self.selectedShapeType = self.shapeTypesList()[_.findLastIndex(self.shapeTypesList(), { name: self.shapeTypeFromWizard().name })];
        } else if (self.diagramBO) {
            self.selectedShapeType = self.shapeTypesList()[_.findLastIndex(self.shapeTypesList(), { name: self.mappingObject[self.diagramBO.$type].name })];
        }
        self.loadAllCapabilityLibraries();
        $('#showMappedInfoCL' + self.encodeId).click(function (ev) {
            var mappedElement = self.selectedMappingCL;
            self.createDetailsTemplate(mappedElement);
        });
        $('#selectCap' + self.encodeId).keyup(function (view) {
            self.matchField = $(view.currentTarget).closest('.row').find('.match');
            if (view.currentTarget.value.trim() !== "") {
                self.matchField.show();
                self.enableComplete(true);
            } else {
                self.matchField.hide();
                self.enableComplete(false);
                $('#showMappedInfoCL' + self.encodeId).hide();
            }
            $('#capLibField' + self.encodeId).prop('disabled', false);
            self.selectedMappingCL = null;
            self.matchField[0].innerHTML = "New";
            $('#showMappedInfoCL' + self.encodeId).hide();
        });
        if (self.options.shape.businessObject.name && self.options.shape.businessObject.name !== '') {
            if (self.options.shape.businessObject.$type == "vdml:CapabilityDefinition" || self.options.shape.businessObject.$type == "vdml:CapabilityCategory" || self.options.shape.businessObject.$type == "vdml:Capability") {
                $('#selectCap' + self.encodeId).val(self.options.shape.businessObject.name);
            } else {
                $('#selectCap' + self.encodeId).val(null);
            }
            self.matchField = $('#selectCap' + self.encodeId).closest('.row').find('.match');
            if (self.options.shape.businessObject.name !== "") {
                self.enableComplete(true);
                self.matchField.show();
            } else {
                self.matchField.hide();
            }
            self.selectedMappingCL = null;
            self.matchField[0].innerHTML = "New";
        }
        if (self.options.shape.businessObject.$type == "vdml:Capability") {
            self.showRadioButtons(true);
        }
        var autoCompAttr = 'new-password';
        if(window.browser == "chrome"){
            autoCompAttr = 'off';
        }
        $('input[type="text"]').attr('autocomplete',autoCompAttr);
        window.utils.stopSpinner('createSpinner');
    };

    getDocumentWithNamePath(namePath, type, currentAlt, isValue, pack, callback) {
        var self = this;
        var alts = currentAlt.getMasterPrimaryAlternativesOfPackage(currentAlt, pack);
        var rdfModels = [];
        async.each(alts, function (alt, altWSLoaded) {
            DataManager.getDataManager().getModelIdInitializedWSData((typeof alt === 'string') ? alt : alt.id, function (wsData) {
                if (wsData) {
                    rdfModels.push(wsData.get('rdfModel'));
                }
                altWSLoaded();
            });
        }, function () {
            alts.length = 0;
            if (rdfModels.length > 0) {
                var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
                if (altRDFModel) {
                    /*if(isValue) {
                      rdfModels.push(altRDFModel);
                    }*/
                    altRDFModel.getDocumentWithNamePath(namePath, type, rdfModels, isValue, callback);
                } else {
                    callback();
                }
            } else {
                callback();
            }

        });
    };
    mapCapability(capElement, mappedElementName, pack, capLib, diagBO, callback) {
        var self = this;
        var vdm = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ 'id': pack.id });
        if (!capLib.id) {
            var capLibrary = vdm.getCapabilityLibraryInstance(mappedElementName, mappedElementName, null);;
        } else {
            capLibrary = Backbone.Relational.store.getObjectByName("vdml.CapabilityLibrary").find({ 'id': capLib.id });
        }
        if (!capElement) {
            if (diagBO.$type === 'vdml:CapabilityCategory' || ($("#selectRadio1" + self.encodeId).is(':checked') && $('#selectRadio' + self.encodeId).is(':visible'))) {
                var cap = CapabilityCategory.getInstance(mappedElementName, mappedElementName, capLibrary);
            } else if (diagBO.$type === 'vdml:CapabilityDefinition' || ($("#selectRadio2" + self.encodeId).is(':checked') && $('#selectRadio' + self.encodeId).is(':visible'))) {
                cap = CapabilityDefinition.getInstance(mappedElementName, mappedElementName, capLibrary);
            }
            self.setDiagramId(diagBO, cap, vdm);
        } else {
            cap = capElement;
            self.setDiagramId(diagBO, cap, vdm);
        }
        if (diagBO.$parent.mid) {
            var type = diagBO.$parent.$type.replace(':', '.');
            var parentCap = Backbone.Relational.store.getObjectByName(type).find({ 'id': diagBO.$parent.mid });
            parentCap.get('childCapability').add(cap);
            cap.get('parentCapability').add(parentCap);
        } else {
            var parentCaps = cap.get('parentCapability');
            var childCaps = cap.get('childCapability');
            for (var i = 0; i < self.parentView.shapesList().length; i++) {
                var shapeMid = self.parentView.shapesList()[i].businessObject.mid;
                if (shapeMid) {
                    var parentExists = _.filter(cap.get('parentCapability').models, function (parCap) { if (parCap.get('id') === shapeMid) { return parCap } });
                    if (parentExists.length > 0) {
                        cap.get('parentCapability').remove(parentExists[0]);
                        parentExists[0].get('childCapability').remove(cap);
                    }
                    var childExists = _.filter(childCaps.models, function (childCap) { if (childCap.get('id') === shapeMid) { return childCap } });
                    if (childExists.length > 0) {
                        cap.get('childCapability').remove(childExists[0]);
                        childExists[0].get('parentCapability').remove(cap);
                    }
                }
            }
        }
        if (callback) {
            callback(cap);
        }
    };
    checkAndRemoveParentCapability(parentCap) {
        var self = this;
        var parentCapabilities = [];
        function parentCaps(parentCap) {
            _.each(parentCap.get('parentCapability').models, function (parentCap) {
                var exists = _.filter(parentCapabilities, function (parent) { if (parent.id === parentCap.id) { return parentCap } });
                if (exists.length === 0) {
                    parentCapabilities.push(parentCap);
                    parentCaps(parentCap);
                }
            })
        }
        parentCapabilities.push(parentCap);
        parentCaps(parentCap);
        return parentCapabilities;
        /* var exists = _.filter(parentCapabilities, function (parentCap) {if (parentCap.id === cap.id) { return parentCap}});
        if (exists.length > 0){
            _.each(cap.get('childCapability').models, function (childCap) {
                 var exists = _.filter(parentCapabilities, function (parentCap) {
                     if (parentCap.id === childCap.id) {
                         return childCap
                     }
                 });
                 if (exists.length>0){
                     childCapabilities.push(childCap);
                 }
            });
            _.each(childCapabilities,function(childCap){
                cap.get('childCapability').remove(childCap);
                childCap.get('parentCapability').remove(cap);
            })
        } */
    }
    checkAndRemoveChildCapability(parentCap) {
        var self = this;
        var childCapabilities = [];
        function childCaps(childCap) {
            _.each(childCap.get('childCapability').models, function (childCap) {
                var exists = _.filter(childCapabilities, function (child) { if (child.id === childCap.id) { return childCap } });
                if (exists.length === 0) {
                    childCapabilities.push(childCap);
                    childCaps(childCap);
                }
            })
        }
        _.each(parentCap.get('childCapability').models, function (childCap) {
            childCaps(childCap);
        })
        return childCapabilities;
    }
    setDiagramId(diagBO, cap, vdm) {
        var self = this;
        if (diagBO && cap) {
            diagBO.set('vdml:mid', cap.id);
            diagBO.set('vdml:mpid', vdm.id);
            self.model.get('capabilities').add(cap);
            if (!diagBO.get('name') || diagBO.get('name') === '') {
                diagBO.set('name', cap.get('name'));
            }
            if (!diagBO.get('description') || diagBO.get('description') === '') {
                diagBO.set('description', cap.get('description'));
            }
        }
    }

    createRevisionBasedOnContext(pack, createRevision, reviCallback) {
        var self = this;
        if (window.checkContextForRevision() || createRevision) {
            window.utils.startSpinner('revisionSpinner', "Creating a copy...");
            function fetchBmModel(newVdmPackage) {
                if (newVdmPackage) {
                    DataManager.getDataManager().releaseSaveLock();
                    DataManager.getDataManager().set('isActive', false);
                    reviCallback(newVdmPackage);
                    /*DataManager.getDataManager().saveData({
                        success: function () {
                            reviCallback(newVdmPackage);
                        },
                        error: function () {
                            reviCallback(newVdmPackage);
                        },
                        persist: true
                    });*/
                }
            }
            window.setTimeout(function () {
                DataManager.getDataManager().acquireSaveLock(function () {
                    pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
                });
            }, 100);
        }
        else {
            reviCallback(pack);
        }
    };



    checkForEcoMapRevision(revCallback) {
        var self = this;
        if (self.checkAddMappingDuplicates()) {
            var ecoMapPackage = self.parentView.model.getNestedParent();
            self.createRevisionBasedOnContext(ecoMapPackage, false, function (newEcoMapPackage) {
                var altId = DataManager.getDataManager().getRepositoryId(newEcoMapPackage.id);
                var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
                if (altId != oldAltId) {
                    var modelId = window.utils.getSuffix(self.parentView.model.id);
                    var ecoMap = newEcoMapPackage.get('diagrams').findWhere({ 'id': altId + modelId });
                    self.parentView.model = ecoMap;
                    self.model = ecoMap;
                    window.utils.stopSpinner('revisionSpinner');
                    revCallback(ecoMap, newEcoMapPackage);
                } else {
                    revCallback(null);
                }
            });
        } else {
            revCallback(null);
        }
    };
    calculateSize(h, w, n, size, constant) {
        var self = this;
        var r = 1, c = 1;
        while ((r * (size * 3 + constant / 4) + (constant / 2)) < w) {
            if (r <= n) {
                r++;
            } else {
                break;
            }
        }
        r = r - 1;
        c = r !== 0 ? Math.round(n / r) : 0;
        if ((c * (size / 3 + constant / 4) + (constant / 2)) > h) {
            if (c > n / r) {
                size = size - size / 20;
            } else {
                size = size - size / 30;
            }
            return self.calculateSize(h, w, n, size, constant);
        } else if (((c + 1) * (size + constant / 4) + (constant / 2)) < h) {
            c = c + 1;
        }
        if (n - c * r >= 1) {
            size = size - size / 30;
            return self.calculateSize(h, w, n, size, constant);
        }
        if (size > 10) {
            return size + ',' + r + ',' + c;
        } else {
            return 10 + ',' + r + ',' + c;
        }
    }
    mapChildCapability(cap, parentElement) {
        var self = this;
        var childCaps = cap.get('childCapability');
        var constant;
        var parentWidth = parentElement.width;
        var parentHeight = parentElement.height;
        if (parentWidth <= 200 || parentHeight <= 200) {
            if (parentWidth < parentHeight) {
                constant = parentWidth / 3;
            } else {
                constant = parentHeight / 3;
            }
        } else {
            constant = 80;
        }
        var x = parentElement.x;
        var y = parentElement.y;
        var size = Math.sqrt((parentHeight * parentWidth) / childCaps.length);
        var r = 0;
        var c = 0;
        var result = self.calculateSize(parentHeight, parentWidth, childCaps.length, size, constant);
        var childSize = parseFloat(result.split(',')[0]);
        if (childSize < 50) {
            return;
        }
        var R = result.split(',')[1];

        childCaps.each(function (childCap) {
            var type = childCap.get('type').replace('_', ':');
            var shape = self.elementFactory.createShape({ type: type });
            self.setDiagramId(shape.businessObject, childCap, childCap.getNestedParent());
            var childSizeHeight = childSize / 3;
            var childSizeWidth = childSize * 3;
            var childx = x + r * (childSizeWidth + constant / 4) + constant / 2;
            var childy = y + c * (childSizeHeight + constant / 4) + constant / 2;
            if (r + 1 < R) {
                r++;
            } else {
                c++;
                r = 0;
            }
            shape = self.modeling.createShape(shape, { x: childx, y: childy, width: childSizeWidth, height: childSizeHeight }, parentElement);
            shape.dontDraw = false;
            self.mapChildCapability(childCap, shape);
        });
    }
    handleWizardButtons() {
        var self = this;
        var dummyChildCaps = [];
        var dummyArray = [];
        var childShapes = [];
        var childCapModels = [];
        self.$wizard.on('finished.fu.wizard', function (e, data) {
            if (!self.selectedShapeType) {
                self.hideWizard();
                return;
            }
            if (self.mappedDiagramBO) {
                self.hideAndCallback();
                return;
            }
            var mappedElementName = self.selectedMappingCL ? self.selectedMappingCL.name : $('#selectCap' + self.encodeId).val().trim();
            self.mapCapability(self.selectedMappingCL, mappedElementName, self.model.getNestedParent(), self.selectedCapLib(), self.diagramBO, function (cap) {
                if (!cap.get('definition') && self.diagramBO.definition && self.diagramBO.definition.text) {
                    cap.set('definition', self.diagramBO.definition.text);
                }
                if (!cap.get('notes')) {
                    if (self.diagramBO.$attrs.notes) {
                        cap.set('notes', self.diagramBO.$attrs.notes);
                        delete self.diagramBO.$attrs.notes;
                    }
                }
                if (self.diagramBO.$type === 'vdml:Capability') {
                    var caps = cap.get('childCapability');
                    var shapeHighLevel;
                    var type = cap.get('type').replace('_', ':');
                    var parentElement = self.elementRegistry.get(self.diagramBO.id)
                    shapeHighLevel = self.elementFactory.createShape({ type: type });
                    shapeHighLevel.dontDraw = true;
                    self.modeling.createShape(shapeHighLevel, { x: parentElement.x, y: parentElement.y, width: parentElement.width, height: parentElement.height }, parentElement.parent);
                    self.setDiagramId(shapeHighLevel.businessObject, cap, cap.getNestedParent());
                    self.mapChildCapability(cap, shapeHighLevel);
                    self.modeling.removeElements([self.elementRegistry.get(self.diagramBO.id)]);
                    self.diagramBO = shapeHighLevel.businessObject;
                }
                window.utils.stopSpinner('revisionSpinner');
                self.hideAndCallback(cap, self.diagramBO);
            });
            function dynamicMapping(childShapes, cap) {
                if (self.diagramBO && self.diagramBO.$type === 'vdml:Capability') {
                    var createdShapes = [];
                    var parentElement = self.elementRegistry.get(self.diagramBO.id);
                    var type = cap.get('type').replace('_', ':');
                    var shapeHighLevel = self.elementFactory.createShape({ type: type });
                    shapeHighLevel.dontDraw = true;
                    self.modeling.createShape(shapeHighLevel, { x: parentElement.x, y: parentElement.y, width: parentElement.width, height: parentElement.height }, parentElement.parent);
                    self.setDiagramId(shapeHighLevel.businessObject, cap, cap.getNestedParent());
                    _.each(childShapes, function (children) {
                        _.each(children, function (child) {
                            type = child.get('type').replace('_', ':');
                            var shape = self.elementFactory.createShape({ type: type });
                            self.setDiagramId(shape.businessObject, child, child.getNestedParent());
                            createdShapes.push(shape);
                        });
                    });
                    _.each(createdShapes, function (shape) {
                        shape.dontDraw = true;
                        var capability = _.filter(childCapModels, function (cap) { if (cap.id === shape.businessObject.mid) { return cap; } });
                        var parentCap = capability[0].get('parentCapability').models[0];
                        var parentShape = _.filter(createdShapes, function (shape) { if (shape.businessObject.mid === parentCap.id) { return shape; } });
                        if (parentShape.length > 0) {
                            self.modeling.createShape(shape, { x: 0, y: 0 }, parentShape[0]);
                        } else {
                            self.modeling.createShape(shape, { x: 0, y: 0 }, shapeHighLevel);
                        }
                    });
                    self.modeling.removeElements([self.elementRegistry.get(self.diagramBO.id)]);
                    self.diagramBO = shapeHighLevel.businessObject;
                }
            }
        });
        function fillChildCaps(caps) {
            dummyChildCaps.length = 0;
            _.each(caps, function (cap) {
                if (cap.get('childCapability') && cap.get('childCapability').length > 0) {
                    for (var i = 0; i < cap.get('childCapability').length; i++) {
                        dummyChildCaps.push(cap.get('childCapability').models[i]);
                    }
                }
            });
            if (dummyChildCaps && dummyChildCaps.length > 0) {
                dummyArray.length = 0;
                var copyChildShapes = [];
                $.extend(true, copyChildShapes, dummyChildCaps);
                childShapes.push(copyChildShapes);
                _.each(dummyChildCaps, function (cap) {
                    childCapModels.push(cap);
                    dummyArray.push(cap);
                });
                fillChildCaps(dummyArray);
            }
        }
    };
    createDetailsTemplate(mappedElement) {
        var self = this;
        var id = window.utils.htmlEscape(window.guidGenerator());
        var options = {};
        //options.context = scenario;
        options.mappedModal = mappedElement;
        options.width = '800px';
        window.getAndCreateModalDialog(self.model, id, EcoMapDiagramMixin, self.model, "TargetDetails", null, options);
    }
    enableCompleteForOtherShapes() {
        var self = this;
        if ($('#selectBMOther' + self.encodeId).val().trim() !== "" && $('#selectParticipant' + self.encodeId).val().trim() !== "" && $('#selectPN' + self.encodeId).val().trim() !== "" && $('#selectRole' + self.encodeId).val().trim() !== "") {
            $('.btn-next').removeAttr('disabled');
        }
        else {
            $('.btn-next').attr('disabled', 'disabled');
        }
    }

    checkDuplicatesPath(models, element, path) {
        var self = this;
        for (var i = 0; i < models.length; i++) {
            if ((element.get('name') === models[i].get('name') && (element.get('id') !== models[i].get('id')))) {
                for (var k = 0; k < element.getPackagePath().length - 1; k++) {
                    path = path.concat(" [" + element.getPackagePath()[k].name);
                    if (element.getPackagePath()[k + 2]) {
                        path = path.concat("/");
                    }
                }
                path = path.concat("]");
                break;
            }
        }
        return path;
    };

    mapCapabilityTree(element) {
        var self = this;
        var id = window.utils.htmlEscape(window.guidGenerator());
        var parentCapabilities = [];
        var childCapabilities = [];
        var currentPlan = DataManager.getDataManager().get('currentPlan');
        currentPlan.loadCapabilityLibraries(function (capabilityLibraries) {
            self.capabilityLibs = capabilityLibraries;
            var type = self.diagramBO.$type.replace(':', '_');
            var mappedCapabilities = [];
            if (self.diagramBO.$parent.mid) {
                var parentType = self.diagramBO.$parent.$type.replace(':', '.');
                var parentCap = Backbone.Relational.store.getObjectByName(parentType).find({ 'id': self.diagramBO.$parent.mid });
                parentCapabilities = self.checkAndRemoveParentCapability(parentCap);
                childCapabilities = self.checkAndRemoveChildCapability(parentCap);
            }
            function getNestedDiagramFlowElementsDiag(diag) {
                var parentDiag = null;
                if (diag.$type == "vdml:CapabilityLibraryDiagram") {
                    parentDiag = diag;
                }
                if (parentDiag) {
                    return parentDiag.flowElements;
                } else {
                    return getNestedDiagramFlowElementsDiag(diag.$parent);
                }
            }
            var flowElements = getNestedDiagramFlowElementsDiag(self.diagramBO.$parent);
            if (flowElements) {
                for (var i = 0; i < flowElements.length; i++) {
                    mappedCapabilities.push(flowElements[i].mid);
                }
            }

            var capId = self.selectedMappingCL ? self.selectedMappingCL.id : '';

            var options = { capabilityLibs: self.capabilityLibs, type: type, mappedCapabilities: mappedCapabilities, parentCaps: parentCapabilities, childCaps: childCapabilities, callback: mapCapability, showCapabilityId: capId };
            window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "CapabilityExplorerJson", null, options);
        });

        function mapCapability(capId, capName, capDesc, capParentId) {
            if (!capId) {
                return;
            } else {
                self.matchField = $('#selectCap' + self.encodeId).closest('.row').find('.match');
                self.matchField.show();
                self.enableComplete(true);
                var type = self.diagramBO.$type.replace(':', '.');
                if (!type) {
                    var type = self.diagramBO.$type.replace('_', '.');
                }
                var capElement = Backbone.Relational.store.getObjectByName(type).find({ 'id': capId });
                if (capElement.get('type') == "vdml_CapabilityCategory") {
                    $("#selectRadio1" + self.encodeId).prop("checked", true);
                } else if (capElement.get('type') == "vdml_CapabilityDefinition") {
                    $("#selectRadio2" + self.encodeId).prop("checked", true);
                }
                self.selectedMappingCL = capElement;
                $('#selectCap' + self.encodeId).val(capName);
                $('#mapCapability' + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
                var capParent = _.filter(self.capLibList(), function (cap) { if (cap.id === capParentId) { return cap } });
                if (capParent && capParent[0]) {
                    $('#capLibField' + self.encodeId).prop('disabled', 'disabled');
                    self.selectedCapLib(capParent[0]);
                }
                self.matchField[0].innerHTML = "Match";
                $('#showMappedInfoCL' + self.encodeId).show();
            }
        }
    };

    hideAndCallback = function(cap, diagBo) {
        var self = this;
        self.eventBus.fire('elements.changed', {
            elements: [self.options.shape]
        });
        self.modelElement.modal("hide");
        if (self.parentView) {
            self.parentView.loadTable();
            self.parentView.saveDiagram(null, null, null, false, false);
        }
        if (self.callback) {
            self.callback(cap, diagBo);
        }
    };

    hideWizard = function(){
        var self = this;
        self.modelElement.modal("hide");
        window.cleanViewModel(this);
    };

    static getInstance(model, options,  diagramBO, callback){
        var view = new CapabilityMappingWizardViewModel($('#wizardModal'), model, options,  diagramBO, callback);
        return view;
    }
}
path.CapabilityMappingWizardViewModel = CapabilityMappingWizardViewModel;
