import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Measurement} from './Measurement'
import {DimensionalMeasurementMixin} from './DimensionalMeasurementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/Measurement","appbo/smm/DimensionalMeasurementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Measurement,DimensionalMeasurementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var DimensionalMeasurement = Measurement.extend(utils.customExtends({
		relations:DimensionalMeasurementMixin.getMixinRelations(),
		subModelTypes: {
			smm_RankingMeasurement : 'smm.RankingMeasurement',
			smm_BinaryMeasurement : 'smm.BinaryMeasurement',
			smm_DirectMeasurement : 'smm.DirectMeasurement',
			smm_CollectiveMeasurement : 'smm.CollectiveMeasurement',
			smm_RescaledMeasurement : 'smm.RescaledMeasurement',
			smm_NamedMeasurement : 'smm.NamedMeasurement'
		},
		initialize: function(attributes, options) {
			Measurement.prototype.initialize.apply(this, arguments);
		}
		}
		, new DimensionalMeasurementMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	DimensionalMeasurement.abstract = sp2Enabled;
	DimensionalMeasurement.supported = !sp2Enabled
	path.DimensionalMeasurement = DimensionalMeasurement;
	export {DimensionalMeasurement};
//});