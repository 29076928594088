import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {EObject} from '../cmof/EObject'
import {SmmElementMixin} from './SmmElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//import {Attribute} from './Attribute'
//import {Annotation} from './Annotation'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/smm/SmmElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,SmmElementMixin
, Attribute
, Annotation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	//if(!Attribute){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Attribute){
			Attribute = importPath.Attribute;
		}
		else{
			/*import('./Attribute').then(({ default: Attribute }) => {
                Attribute = Attribute;
            });*/
			/*require(["appbo/smm/Attribute"],function(loadedModule){
				Attribute = loadedModule;
			});*/
		}
	//}
	//if(!Annotation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Annotation){
			Annotation = importPath.Annotation;
		}
		else{
			import('./Annotation').then(({ default: Annotation }) => {
                Annotation = Annotation;
            });
			/*require(["appbo/smm/Annotation"],function(loadedModule){
				Annotation = loadedModule;
			});*/
		}
	//}
	var SmmElement = EObject.extend(utils.customExtends({
		relations:SmmElementMixin.getMixinRelations(),
		subModelTypes: {
			smm_SmmModel : 'smm.SmmModel',
			smm_Measurement : 'smm.Measurement',
			smm_Observation : 'smm.Observation',
			smm_ObservedMeasure : 'smm.ObservedMeasure',
            smm_MeasureLibrary: 'smm.MeasureLibrary',
            smm_BusinessIndicatorLibrary: 'smm.BusinessIndicatorLibrary',
            smm_BusinessIndicatorLibraryElement: 'smm.BusinessIndicatorLibraryElement',
			smm_AbstractMeasureElement : 'smm.AbstractMeasureElement',
			smm_ObservationScope : 'smm.ObservationScope',
			smm_SmmRelationship : 'smm.SmmRelationship',
			smm_Attribute : 'smm.Attribute',
			smm_Annotation : 'smm.Annotation',
			smm_Interval : 'smm.Interval'
		},
		initialize: function(attributes, options) {
			EObject.prototype.initialize.apply(this, arguments);
		}
		}
		, new SmmElementMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	SmmElement.abstract =  sp2Enabled;
	SmmElement.supported =  !sp2Enabled;
	path.SmmElement = SmmElement;
	export {SmmElement};
//});