import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Capability} from './Capability'
import {CapabilityCategoryMixin} from './CapabilityCategoryMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Capability","appbo/vdml/CapabilityCategoryMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Capability,CapabilityCategoryMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var CapabilityCategory = Capability.extend(utils.customExtends({
		relations:CapabilityCategoryMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Capability.prototype.initialize.apply(this, arguments);
		}
		}
		, new CapabilityCategoryMixin()
	));
	CapabilityCategory.getInstance = function(name,description,owner){
		var cid = DataManager.getDataManager().guidGeneratorByOwner(owner);
		var CapabilityCat = new CapabilityCategory({id:cid,name:owner.getUniqueProperty('name',name,'capability'),description:owner.getUniqueProperty('description',description,'capability'),version: owner.get('version'), capabilityOwner:owner});
		owner.get('capability').add(CapabilityCat);
		//CapabilityLiDiagram.set('capabilityLibrary',capabilityLibrary);
		return CapabilityCat;
	};
	CapabilityCategory.abstract = false;
	CapabilityCategory.supported = true;
	path.CapabilityCategory = CapabilityCategory;
	export {CapabilityCategory};
//});