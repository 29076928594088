import * as jQuery from 'jquery'
import * as _ from 'underscore'

import {VdmlElement} from './VdmlElement'
import {ValueElementContextMixin} from './ValueElementContextMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

	
var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

var ValueElementContext = VdmlElement.extend(utils.customExtends({
        relations:ValueElementContextMixin.getMixinRelations(),
        subModelTypes: {
        },
        initialize: function(attributes, options) {
            VdmlElement.prototype.initialize.apply(this, arguments);
        }
    }
    , new ValueElementContextMixin()
));

ValueElementContext.abstract = false;
path.ValueElementContext = ValueElementContext;
export {ValueElementContext};
