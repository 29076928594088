import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {BusinessModelMixin} from '../../../../../version1/bo/vdml/BusinessModelMixin'
import {ValueElementMixin} from '../../../../../version1/bo/vdml/ValueElementMixin'
import {EcoMapDiagram} from '../../../../../version1/bo/vdml/EcoMapDiagram'
import {EcoMap} from '../../../../../version1/bo/ecomap/EcoMap'

//define(["require", "jquery", "underscore", 'async', "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/BusinessModelMixin", "appbo/vdml/ValueElementMixin", "appbo/vdml/EcoMapDiagram", "appbo/ecomap/EcoMap", "jquery.DataTable", "summernote"],
//	function (require, $, _, async, Backbone, ko, koMapping, kb, bootbox, DataManager, global, BusinessModelMixin, ValueElementMixin, EcoMapDiagram, EcoMap) {

var path = DataManager.getDataManager().buildAppNsPath("canvas.views.properties", global.version);
export class CanvasTargetDetailsViewModel{
	constructor(model, options) {
		var self = this;
		this.CanvasTargetDetailsViewModel = this;
		self.model = model;
		self.callback = options.callback
		self.id = kb.observable(self.model, 'id');
		self.encodeId = "" + options.modalId;
		self.mappedModal = options.addOptions.mappedModal;
		self.editValues = false;
		/*if(options.addOptions.disableValues){
			self.editValues = false;
		}*/
		self.name = ko.observable(self.mappedModal.get('name'));
		self.description = ko.observable(self.mappedModal.get('description'));
		this.measurementMapDetails1 = ko.observableArray([]);
		this.showCapPackage = ko.observable();
		this.isMeasurementLibrary = ko.observable(false);
		this.parentCapability = ko.observableArray([]);
		this.childCapability = ko.observableArray([]);
		this.mappingObject = {};
		this.mapList = [];
		this.mappingObject['vdml_CapabilityLibrary'] = {name : DataManager.getDataManager().get('localeManager').get('CapabilityLibrary'), type : "Capability Library"}
		this.mappingObject['vdml_CapabilityCategory'] = { name: DataManager.getDataManager().get('localeManager').get('CapabilityCategory'), type: "Capability Category" };
		this.mappingObject['vdml_CapabilityDefinition'] = { name: DataManager.getDataManager().get('localeManager').get('CapabilityDefinition'), type: "Capability Definition" };
		this.mappingObject['vdml:capabilityCategory'] = { name: DataManager.getDataManager().get('localeManager').get('CapabilityCategory'), type: "capability Category" };
		this.mappingObject['vdml:capabilityDefinition'] = { name: DataManager.getDataManager().get('localeManager').get('CapabilityDefinition'), type: "capability Definition" };
		this.mappingObject['vdml_CapabilityLibraryDiagram'] = { name: DataManager.getDataManager().get('localeManager').get('CapabilityMap'), type: "Capability Library"};
		this.mappingObject['vdml_StrategyMapDiagram'] = { name: DataManager.getDataManager().get('localeManager').get('StrategyMap'), type: "Strategy Map" };
		this.mappingObject['vdml_ValueStreamMapDiagram'] = { name: DataManager.getDataManager().get('localeManager').get('ValueStreamMap'), type: "Value Stream Map" };
		this.mappingObject['vdml_BusinessModelCompetence'] = {name: DataManager.getDataManager().get('localeManager').get('Competence'), type: "Business Model Competence" };
		this.mappingObject['vdml_BusinessModelActivity'] = {name: DataManager.getDataManager().get('localeManager').get('Activity'), type: "Business Model Activity" };
		this.mappingObject['vdml_BusinessModel'] = {name:DataManager.getDataManager().get('localeManager').get('BusinessModel'), type: "Business Map" };
		this.mappingObject['canvas_BMCanvasDiagram'] = { name: DataManager.getDataManager().get('localeManager').get('BusinessCanvas'), type: "BM Canvas" };
		this.mappingObject['vdml_Activity'] = { name: DataManager.getDataManager().get('localeManager').get('Activity'), type: "Activity" };
		this.mappingObject['vdml_CapabilityOffer'] = { name : DataManager.getDataManager().get('localeManager').get('Capability'),type: "Capability" };
		this.mappingObject['vdml_Community'] = {name : DataManager.getDataManager().get('localeManager').get('marketSegment'), type : "Market Segment"};
		this.mappingObject['vdml_OrgUnit'] = {name : DataManager.getDataManager().get('localeManager').get('enterprise'), type : "Enterprise"};
		this.mappingObject['vdml_Actor'] = {name : DataManager.getDataManager().get('localeManager').get('individual'), type : "Individual"};
		this.mappingObject['vdml_ValueProposition'] = {name : DataManager.getDataManager().get('localeManager').get('ValueProposition'), type : "Value Proposition"};
		this.mappingObject['vdml_Party'] = {name : DataManager.getDataManager().get('localeManager').get('Role'), type : "Role"};
		this.mappingObject['vdml_BusinessNetwork'] = {name : DataManager.getDataManager().get('localeManager').get('ParticipantNetwork'), type : "Participant Network"};
		this.mappingObject['vdml_ValueDeliveryModel'] = {name : DataManager.getDataManager().get('localeManager').get('Package'), type : "Package"};
		this.mappingObject['vdml_ValuePropositionComponent'] = {name : DataManager.getDataManager().get('localeManager').get('value'), type : "value"};
		this.mappingObject['vdml_ValueAdd'] = {name : DataManager.getDataManager().get('localeManager').get('value'), type : "value"};
		this.mappingObject['vdml_BusinessItem'] = {name : DataManager.getDataManager().get('localeManager').get('Resource'), type : "Resource"};
		this.mappingObject['vdml_ValueStream'] = {name : DataManager.getDataManager().get('localeManager').get('ValueProposition'), type : "Value Proposition"};
		this.mappingObject['transformation_Plan'] = {name : DataManager.getDataManager().get('localeManager').get('Plan'), type : "Plan"};
		this.mappingObject['transformation_Phase'] = {name : DataManager.getDataManager().get('localeManager').get('Phase'), type : "Phase"};
		this.mappingObject['transformation_Alternative'] = {name : DataManager.getDataManager().get('localeManager').get('Alternative'), type : "Alternative"};
		this.mappingObject['vdml_EcoMapDiagram'] = {name : DataManager.getDataManager().get('localeManager').get('BusinessEcosystemMap'), type : "Business Ecosystem Map"};
		this.mappingObject['dashboard_Dashboard'] = {name : DataManager.getDataManager().get('localeManager').get('Dashboard'), type : "Dashboard"};
		this.mappingObject['dashboard_Presentation'] = {name : DataManager.getDataManager().get('localeManager').get('Views'), type : "Presentation"};
		this.mappingObject['vdml_ValueLibrary'] = { name: DataManager.getDataManager().get('localeManager').get('ValueLibrary'), type: "Value Library" }
		this.mappingObject['vdml_ValueCategory'] = { name: DataManager.getDataManager().get('localeManager').get('ValueCategory'), type: "Value Category" };
		this.mappingObject['vdml_ValueDefinition'] = { name: DataManager.getDataManager().get('localeManager').get('ValueDefinition'), type: "Value Definition" };
		this.mappingObject['vdml:valueCategory'] = { name: DataManager.getDataManager().get('localeManager').get('ValueCategory'), type: "Value Category" };
		this.mappingObject['vdml:valueDefinition'] = { name: DataManager.getDataManager().get('localeManager').get('ValueDefinition'), type: "Value Definition" };
		self.selectMapList = ko.observableArray([]);
		this.vpNames = ko.observable("");
		self.mappedModalType = self.mappedModal.get('type');
		self.PNRoleColl = ko.observableArray([]);
		self.participantName = ko.observable("");
		self.roleName = ko.observable("");
		self.sourceName = ko.observable("");
		self.sourceTypeName = ko.observable("");
		self.valueAndUnit = ko.observable("");
		self.usedActivitiesNames = ko.observable("");
		self.selectedDefiniton = ko.observable("");
		self.providerName = ko.observable("");
		self.providerRoleName = ko.observable("");
		self.recipientName = ko.observable("");
		self.recipientRoleName = ko.observable("");
		self.businessName = ko.observable("");
		self.roleNamesInPN = ko.observable("");
		self.isCapabilityDetails = ko.observable(false);
		self.isPartnerOrCustomer = ko.observable(false);
		self.showVsmActivityDetails = ko.observable(false);
		self.showStrategyActivityDetails = ko.observable(false);
		self.isActivity = ko.observable(false);
		self.isValue = ko.observable(false);
		self.isCompetence = ko.observable(false);
		self.isVP = ko.observable(false);
		self.isVPAct = ko.observable(false);
		self.isPN = ko.observable(false);
		self.isRole = ko.observable(false);
		self.isBusinessModel = ko.observable(false);
		self.showRelatedValues = ko.observable(false);
		self.showPNTable = ko.observable(false);
		self.isPackage = ko.observable(false);
		self.businessModels = ko.observable("");
		self.isBusinessModel = ko.observable(false);
		self.packageName = ko.observable("");
		self.businessName = ko.observable("");
		self.pnName = ko.observable("");
		self.bmsNames = ko.observableArray("");
		self.valueStreams = ko.observableArray('');
		self.strategies = ko.observableArray('');
		self.displayValues = ko.observableArray([]);
		self.altAccordionList = ko.observableArray([]);
		self.vpActivities = ko.observableArray([]);
		self.svgSmiliesList = window.utils.getSmiliesList();
		self.definitionSummernote = ko.observable("");
		self.afterRenderView = _.bind(self.afterRenderView,self);
		this.options = options;
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
			'name'
			, 'description'
			, 'Close'
			, 'Delete'
			, 'Complete'
			, 'source'
			, 'PursuedVPMP'
			, 'isUsedBy'
			, 'CapabilityLibrary'
			, 'childCapability'
			, 'parentCapability'
			, 'WhereUsed'
			, 'Parent'
			, 'BusinessModels'
			, 'Package'
			, 'business'
			, 'Details'
			, 'ValueStreamMaps'
			, 'StrategyMaps'
			, 'Activities'
			, 'Performer'
			, 'PerformerRole'
			, 'provider'
			, 'providerRole'
			, 'recipient'
			, 'recipientRole'
			, 'Participant'
			, 'ParticipantNetwork'
			, 'UserRoles'
			, 'sourceType'
		]);
		if (self.mappedModalType === "vdml_Community" || self.mappedModalType === "vdml_OrgUnit" || self.mappedModalType === "vdml_Actor") {
			self.isPartnerOrCustomer(true);
			self.showPNTable(true);
			var roles;
			var roleId = [];
			self.mappedModal.getBusinessModelsInvolved(function (bms) {
				for (var i = 0; i < bms.length; i++) {
					roles = bms[i].getRolesofParticipant(self.mappedModal);
					if (roles && roles.length > 0) {
						_.each(roles, function (role) {
							var pnName = role.get('collaborationRoleOwner').get('name');
							var roleNames = role.get('name');
							var PNAlreadyExists = self.PNRoleColl()[_.findLastIndex(self.PNRoleColl(), { 'pnName': pnName })];
							
							if (PNAlreadyExists) {
								if (_.filter(PNAlreadyExists['roleId'], function (pnRoleId) { return pnRoleId === role.id }).length == 0) {
									var oldRolesText = PNAlreadyExists.roleNames;
									PNAlreadyExists['roleNames'] = oldRolesText + ", " + roleNames;
									PNAlreadyExists['roleId'].push(role.id);
									self.PNRoleColl.remove(PNAlreadyExists);
									self.PNRoleColl.push(PNAlreadyExists);
								}
							} else {
								roleId.push(role.id);
								self.PNRoleColl.push({ pnName: pnName, roleNames: roleNames, roleId: roleId});
							}
						});
						self.bmsNames.push({ 'name': bms[i].get('name'), 'type': bms[i].getPaticipantBMType(bms[i], self.mappedModal),'valueStrems':'','activityValues': ''});
					}

				}
			});   
		} else if (self.mappedModalType === "vdml_Activity") {
			self.isActivity(true);
			self.mappedModal.getBusinessModelsInvolved(function(bms){
				_.each(bms, function (bm) {
					var valueStreamsModels = bm.get('bmValueStream').models;
					_.each(valueStreamsModels, function (vs) {
						if (vs.get('valueStreamActivity').findWhere({ 'id': self.mappedModal.id })) {
							var valueStreams = vs.get('persuedProposition').get('name');
							var activityValues="";
							for(var i=0;i<bm.get('bmActivity').length;i++){
								if(bm.get('bmActivity').models[i].get('activity').id === self.mappedModal.id){
									var activityValueModels = bm.get('bmActivity').at(i).get('activityValue');
									for(var a=0;a<vs.get('activityValue').length;a++){
										for(var b=0;b<activityValueModels.length;b++){
											if(activityValueModels.at(b).id === vs.get('activityValue').at(a).id){
													if(activityValues === ""){
														activityValues = vs.get('activityValue').at(a).get('name');
													}else{
														activityValues += " , " + vs.get('activityValue').at(a).get('name');
													}
											}
										}
									}
								}
							}
							self.bmsNames.push({ 'name': bm.get('name'), 'valueStrems':valueStreams, 'activityValues': activityValues,'type':''});
						}
					});
				});
			});
			var altModel = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: window.utils.getPrefix(self.mappedModal.id) });
			async.eachSeries(altModel.get('phaseDesignPart').models,function(pack,ecoCallback){
					var dataManager = DataManager.getDataManager();
					var repId = dataManager.getRepositoryId(pack.get('beepReference'));
					var vdmStore = dataManager.getVDMStore(repId);
					if(pack.get('beepType') == "ecomap_EcoMap"){
						dataManager.fetchDocumentFromPackage(pack.get('beepReference'), 'ecomap_EcoMap', pack.get('version'),pack.get('beepReference'), 'ecomap_EcoMap', vdmStore, {
						success: function (model) {
							var valueStreams = model.get('diagrams').models;
							for(var i=0;i<valueStreams.length;i++){
								if(valueStreams[i].get('type') != 'vdml_EcoMapDiagram'){
									if(valueStreams[i].get('activity')){
										if (valueStreams[i].get('activity').findWhere({ 'id': self.mappedModal.id })) {
											if(valueStreams[i].get('type') === 'vdml_ValueStreamMapDiagram'){
												self.showVsmActivityDetails(true);
												self.valueStreams.push({'name':valueStreams[i].get('name')});
											}else if(valueStreams[i].get('type') === 'vdml_StrategyMapDiagram'){
												self.showStrategyActivityDetails(true);
												self.strategies.push({'name': valueStreams[i].get('name')});
											}
										}
									}
								}
							}
							ecoCallback();	
						},
						});
					}else{
					ecoCallback();
					}
			});
			var role = self.mappedModal.get('performingRole');
			var assignment = role.get('roleAssignment').models[0];
			var part = assignment.get('participant');
			self.participantName(part.get('name'));
			self.roleName(role.get('name'));
		}else if (self.mappedModalType === "vdml_ValuePropositionComponent") {
			self.isValue(true);
			//var valueUnit = getValueAndUnitString(self.mappedModal);
			//self.valueAndUnit(valueUnit);
			var scenario = self.fetchScenario(self.mappedModal);
			var satisfaction = self.mappedModal.getMeasurement('satisfactionLevel', scenario);
			var selectedInterval = self.getSatSelectedInterval(satisfaction);
			self.correctiveActions = selectedInterval ? selectedInterval.get('correctiveActions') : '';
			self.smileySrc = selectedInterval ? self.fetchSmileySrc(selectedInterval.get('smiley')) : '';
			self.smileyColor = selectedInterval ? selectedInterval.get('smileyColor') : '';
			if (self.mappedModal.getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
				self.sourceName(DataManager.getDataManager().get('currentPlan').get('name'));
				self.sourceTypeName(DataManager.getDataManager().get('localeManager').get('PlanValue'));
			} else {
				var vp = self.mappedModal.get('componentOwner');
				if (vp.get('isValueFormula')) {
					_.each(vp.get('provider').get('roleAssignment').at(0).get('participant').get('businessModel').models, function (bms) {
						self.bmsNames.push({ 'name': bms.get('name'), 'type':'','valueStrems':'','activityValues': ''});
					});
				} else {
					self.fetchBMListForVp(vp);
				}
				self.sourceName(vp.get('name'));
				if (self.mappedModal.get('componentOwner').get('isValueFormula')) {
					self.sourceTypeName(DataManager.getDataManager().get('localeManager').get('MyProposition'));
				} else {
					self.sourceTypeName(DataManager.getDataManager().get('localeManager').get('ValueProposition'));
				}
			}
		} else if (self.mappedModalType === "vdml_ValueAdd") {
			self.isValue(true);
			//valueUnit = getValueAndUnitString(self.mappedModal);
			//self.valueAndUnit(valueUnit);
			var act = self.mappedModal.get('valueAddOwner').get('containedPortOwner');
			self.sourceName(act.get('name'));
			self.sourceTypeName(DataManager.getDataManager().get('localeManager').get('Activity'));
			var persuedVPs = [];
			var currentAltId = DataManager.getDataManager().get('viewAlternative');
			self.currentAlternativeModel = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: currentAltId });
			DataManager.getDataManager().getAllDocumentsCollectionOfType(currentAltId, 'vdml_ValueStream', function (result) {
				for (var i = 0; i < result.length; i++) {
					if (!result.at(i).get('parent')) {
						continue;
					}
					var vs = Backbone.Relational.store.getObjectByName('vdml.ValueStream').find({ id: result.at(i).get('id') });
					if (vs.get('activityValue').findWhere({ 'id': self.mappedModal.id })) {
						persuedVPs.push(vs.get('persuedProposition'));
						self.fetchBMListForVp(vs.get('persuedProposition'), act, self.mappedModal);
					}						
				}
				_.each(persuedVPs, function (vp) {
					if (self.vpNames() === "") {
						self.vpNames(vp.get('name'));
					} else {
						self.vpNames(self.vpNames() + ", " + vp.get('name'));
					}
				});
			}, false, null, false);
		} else if (self.mappedModalType === "vdml_BusinessItem") {
			self.isCompetence(true);
			var usedActivities = [];
			var flows = self.mappedModal.get('flow').models;
			self.mappedModal.getBusinessModelsInvolved(function (bms) {
				_.each(bms, function (bm) {
					var bmActivties = bm.get("bmActivity");
					_.each(flows, function (flow) {
						var act = flow.get('recipient').get('containedPortOwner');
						if (bmActivties.findWhere({ 'activity': act })) {
							usedActivities.push(act);
						}
					});
					self.bmsNames.push({ 'name': bm.get('name'),'type':'','valueStrems':'' ,'activityValues': ''});
				})
			});
			usedActivities = _.uniq(usedActivities);
			_.each(usedActivities, function (act) {
				if (self.usedActivitiesNames() === "") {
					self.usedActivitiesNames(act.get('name'));
				} else {
					self.usedActivitiesNames(self.usedActivitiesNames() + ", " + act.get('name'));
				}
			});
		} else if (self.mappedModalType === "vdml_CapabilityOffer") {
			self.isCompetence(true);
			var usedActivities = [];
			function checkActivity(activity, oriActivity, usedActivities) {
				if (activity.get('appliedCapabilityOffer') === self.mappedModal) {
					usedActivities.push(oriActivity);
				}
				var delContext = activity.get("delegationContext");
				for (var j = 0; j < delContext.length; j++) {
					var parentContext = delContext.models[j];
					if (parentContext.get('parentContext')) {
						parentContext = parentContext.get('parentContext');
					}
					if (self.options.context !== parentContext) {
						continue;
					}
					var coll = delContext.at(j).get("contextCollaboration");
					var activityColls = coll.get('activity');
					for (var k = 0; k < activityColls.length; k++) {
						checkActivity(activityColls.at(k), activity, usedActivities);
					}
				}
				return usedActivities;
			};
			self.mappedModal.getBusinessModelsInvolved(function (bms) {
				_.each(bms, function (bm) {
					var bmActivities = bm.get("bmActivity");
					for (var i = 0; i < bmActivities.models.length; i++) {
						var activities = bmActivities.at(i).get('activity');
						if (activities) {
							usedActivities = checkActivity(activities, activities, usedActivities);
						}
					}
					self.bmsNames.push({ 'name': bm.get('name'),'type':'','valueStrems':'','activityValues': '' });
				})
			});				
			usedActivities = _.uniq(usedActivities);
			_.each(usedActivities, function (act) {
				if (self.usedActivitiesNames() === "") {
					self.usedActivitiesNames(act.get('name'));
				} else {
					self.usedActivitiesNames(self.usedActivitiesNames() + ", " + act.get('name'));
				}
			});
		} else if (self.mappedModalType === "vdml_ValueProposition" || self.mappedModalType === "vdml_ValueStream") {
			var vp = self.mappedModal;
			if (self.mappedModalType === "vdml_ValueStream"){
				vp = self.mappedModal.get('persuedProposition');
				self.name(vp.get('name'));
				self.description(vp.get('description'));
			}
			if (vp.get('isValueFormula')) {
				self.mappingObject['vdml_ValueStream'].name = DataManager.getDataManager().get('localeManager').get('MyProposition');
				self.mappingObject['vdml_ValueProposition'].name = DataManager.getDataManager().get('localeManager').get('MyProposition');
			}
			self.isVP(true);
			var providerRole = vp.get('provider');
			var providerAssignment = providerRole.get('roleAssignment').models[0];
			var providerParticipant = providerAssignment.get('participant');
			self.providerName(providerParticipant.get('name'));
			self.providerRoleName(providerRole.get('name'));
			var recipientRole = vp.get('recipient');
			if (recipientRole) {
				var recipientAssignment = recipientRole.get('roleAssignment').models[0];
				var recipientParticipant = recipientAssignment.get('participant');
				self.recipientName(recipientParticipant.get('name'));
				self.recipientRoleName(recipientRole.get('name'));
			}
			var mypackage = vp.getNestedParent();
			function fetchMappedBm(pack) {
				var bms = pack.get('businessModel');
				for (var i = 0; i < bms.length; i++) {
					if (!vp.get('isValueFormula') && bms.at(i).get('bmValueProposition').findWhere({ 'valueProposition': vp })) {
						self.bmsNames.push({ 'name': bms.at(i).get('name'),'type':'' ,'valueStrems':'','activityValues': ''});
					}
					else if (vp.get('isValueFormula') && bms.at(i).get('bmValueFormula').findWhere({ 'valueFormula': vp })) {
						self.bmsNames.push({'name': bms.at(i).get('name'),'type':'','valueStrems':'','activityValues': '' });
					}
				}
			}
			fetchMappedBm(mypackage);
			var depPack = mypackage.get('dependentPackage')?mypackage.get('dependentPackage'):[];
			for (var i = 0; i < depPack.length; i++) {
				if (depPack.at(i).get('type') === "vdml_ValueDeliveryModel") {
					fetchMappedBm(depPack.at(i));
				}
			}
			if (vp.getNestedParent().get('valueStream')) {
				var valueStreams = vp.getNestedParent().get('valueStream').models;
				self.vpActivities = [];
				_.each(valueStreams, function (vs) {
					if (vs.get('persuedProposition') === vp) {
						var activities = vs.get('valueStreamActivity').models;
						_.each(activities, function (act) {
							self.vpActivities.push({ id: act.id, name: act.get('name'), description: act.get('description') });
						});
					}
				});
			}
			if (self.vpActivities.length > 0) {
				self.isVPAct(true);
			}
		} else if (self.mappedModalType === "vdml_BusinessNetwork") {
			self.isPN(true);
			self.businessName(self.model.get('business') ? self.model.get('business').get('name') : '');
			var roles = self.mappedModal.get('collaborationRole').models;
			_.each(roles, function (role) {
				if (self.roleNamesInPN() === "") {
					self.roleNamesInPN(role.get('name'));
				} else {
					self.roleNamesInPN(self.roleNamesInPN() + ", " + role.get('name'));
				}
			});
		} else if (self.mappedModalType === "vdml_Party") {
			self.isRole(true);
			self.pnName(self.mappedModal.get('collaborationRoleOwner').get('name'));
			var assignment = self.mappedModal.get('roleAssignment').models[0];
			var participant = assignment.get('participant');
			participant.getBusinessModelsInvolved(function (bms) {
				for (var i = 0; i < bms.length; i++) {
					var roles = bms[i].get('bmRole').models;
					for (var j = 0; j < roles.length; j++) {
						if (roles[j].id === self.mappedModal.id) {
							self.bmsNames.push({ 'name': bms[i].get('name'), 'type': bms[i].getRoleBMRelationship(bms[i], participant, self.mappedModal),'valueStrems':'','activityValues': '' });
							break;
						}
					}
				}
			});
			self.participantName(participant.get('name'));
		} else if (self.mappedModalType === "vdml_BusinessModel") {
			self.isBusinessModel(true);
			self.showPNTable(true);
			self.packageName(self.mappedModal.getNestedParent().get('name'));
			self.businessName(self.mappedModal.get('business') ? self.mappedModal.get('business').get('name') : '');
			var roles = self.mappedModal.getRolesofParticipant(self.mappedModal.get('business'));
			_.each(roles, function (role) {
				var pnName = role.get('collaborationRoleOwner').get('name');
				var roleNames = role.get('name');
				var PNAlreadyExists = self.PNRoleColl()[_.findLastIndex(self.PNRoleColl(), { 'pnName': pnName })];
				if (PNAlreadyExists) {
					var oldRolesText = PNAlreadyExists.roleNames;
					PNAlreadyExists['roleNames'] = oldRolesText + ", " + roleNames;
					self.PNRoleColl.remove(PNAlreadyExists);
					self.PNRoleColl.push(PNAlreadyExists);
				} else {
					self.PNRoleColl.push({ pnName: pnName, roleNames: roleNames });
				}
			});
		} else if (self.mappedModalType === "vdml_ValueDeliveryModel") {
			self.isPackage(true);
			var businessModels = self.mappedModal.get('businessModel').models;
			for (var j = 0; j < businessModels.length; j++) {
				if (self.businessModels() === "") {
					self.businessModels(businessModels[j].get('name'));
				} else {
					self.businessModels(self.businessModels() + ", " + businessModels[j].get('name'));
				}
			}
		} //"smm_MeasurementLibraryDiagram"
		//else if (self.mappedModalType === "vdml_ValueStream") {
		//	//TODO
		//}	
	}
	afterRenderView() {
		var self = this;
		if (self.mappedModalType === "vdml_ValueProposition") {
			self.valueComponents = self.mappedModal ? self.mappedModal.get('component') : null;

		} /*else if (self.mappedModalType === "smm_BusinessIndicatorDefinition") {
			self.isMeasurementLibrary(true);
			self.getUsedMapList1(self.mappedModal.id);
		}*/ else if (self.mappedModalType === "vdml_Activity") {
			self.valueComponents = new Backbone.Collection;
			self.mappedModal.get('containedPort').each(function (port) {
				if (port.get('type') === "vdml_OutputPort") {
					port.get('valueAdd').each(function (valueAdd) {
						self.valueComponents.push(valueAdd);
					});
				}
			});
		} else if (self.mappedModalType === "vdml_ValueAdd" || self.mappedModalType === "vdml_ValuePropositionComponent") {
			self.valueComponents = new Backbone.Collection;
			self.valueComponents.push(self.mappedModal);
		}
		else if (self.mappedModalType === "vdml_ValueStream") {
			self.valueComponents = self.mappedModal ? self.mappedModal.get('persuedProposition').get('component') : null;
		}                
		if (self.valueComponents && self.valueComponents.length) {
			self.loadTableData(self.valueComponents, function (data) {
				self.displayTable(data);
			});
		}
		if(self.mappedModalType === "vdml_CapabilityLibrary"){
			self.showCapPackage(self.mappedModal.get('capabilityLibraryOwner').get('name'));
		}
		if(self.mappedModal.get('definition')){
			$('#capdef_div').show();
			self.definitionSummernote(self.mappedModal.get('definition'));
		}
		$('#CanvasTargetDetailsView' + self.encodeId + ' .modal-title span').text(self.mappingObject[self.mappedModalType].name + " Details");
		$('#wizardModal').addClass('push-back');
		$('#modal' + self.encodeId).css('z-index','2000')
		$('#modal' + self.encodeId).on('shown.bs.modal', function () {
			if (self.definitionSummernote()) {
				self.showDefinitionSection(self.definitionSummernote());
			}
		});
	}
	getCapElementModal(id) { 
		var self = this;
		var capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityCategory').find({ id: id });
		if (!capModel) {
			capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityDefinition').find({ id: id });
		}
		return capModel;
	}

	/*getMapElementModal(id) {
		var self = this;
		var mestModel = Backbone.Relational.store.getObjectByName('smm.BusinessIndicatorCategory').find({ id: id });
		if (!mestModel) {
			mestModel = Backbone.Relational.store.getObjectByName('smm.BusinessIndicatorDefinition').find({ id: id });
		}
		return mestModel;
	}*/
	showDefinitionSection(contentDefinition) { 
		var self = this;
		var summernote = $('#summernoteTargetDetailsDefinition');
		summernote.summernote('destroy');
		summernote.summernote(
			{
				airMode: true,
				// dialogsInBody: true,
				height: 100,
				minHeight: null,             // set minimum height of editor
				maxHeight: null,             // set maximum height of editor
				focus: true,
				placeholder: ' ',
				disableResizeEditor: false, //disable resize 
				toolbar: [
					['style', ['bold', 'italic', 'underline', 'clear']],
					['font', ['fontname', 'fontsize', 'color', 'strikethrough', 'superscript', 'subscript']],
					['para', ['style', 'ul', 'ol', 'paragraph']],
					['height', ['height']]
					//['insert', ['link', 'table', 'hr']]
				]
			});
		//summernote.find('.note-statusbar').hide();
		summernote.summernote('disable');
		$('.note-insert').hide();
		$('.note-table').hide();
		if (contentDefinition) {
			summernote.summernote('code', contentDefinition);
			summernote.summernote("editor.clearHistory", 'editor');
		}
		$('.note-editor').css({ "border-color": "#C1E0FF", "border-width": "1px", "border-style": "solid" });
		$("#summernotecss").attr("href", "js/libs/summernote/dist/summernote.css");
	}

	/*getUsedMapList1(id) {
		var self = this;
		var tempId = id;
		var modelNode = self.getMapElementModal(tempId);
		self.measurementMapDetails1([]);
		self.measurementMapDetails1.push({ 'unit': modelNode.get("unit"), 'value': modelNode.get("value"), 'valueName': modelNode.get("valueName"), 'valueType': modelNode.get("valueType") });
	}*/
	getUsedMapList(id) {
		var self = this;
		var tempId = id;
		var mapList = '';
		self.selectMapList([]);
		
		var plan = DataManager.getDataManager().get('currentPlan');
		//var sample = this.model.get('id');
		var modelNode = self.getCapElementModal(tempId);
		DataManager.getDataManager().getReferencesToModel2(modelNode, function (data) {
			for (var i = 0; i < data.length; i++) {
				if(data[i].predicate.indexOf("Capability-childCapability") != -1 || data[i].predicate.indexOf("CapabilityLibrary-capability") != -1 || data[i].predicate.indexOf("Capability-parentCapability") != -1 || data[i].predicate.indexOf("vdml_CapabilityMethod-capability") != -1) {
					continue;
				} else {
					var altId = DataManager.getDataManager().getRepositoryId(data[i].id);
					var alternative =  Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: altId });
					if(alternative) {
						var alternativeName = alternative.get('name');
						var phaseName = alternative.get('phaseAlternativeOwner').get('name');
						data[i].namePath = phaseName + "/" + alternativeName + "/" + data[i].namePath;
					}
					var type = data[i].type.substring(data[i].type.lastIndexOf('_') + 1);
					type = type.split(/(?=[A-Z])/).join(' ');
					if(type == "Capability Library Diagram"){
						type = self.mappingObject[data[i].type].name
					}else{
							type = self.mappingObject[data[i].type]?self.mappingObject[data[i].type].type:null;
					}
					if(type){
						self.selectMapList.push({ 'name': data[i].name, 'type': type , 'shapeName': data[i].namePath });
					}
				}
			}
		}, function () {

				//callback(imageFoundList);
				self.selectMapList.sort(function (a, b) {
					if (a.name.toLowerCase() < b.name.toLowerCase()) {
						return -1;
					}
					else if (a.name.toLowerCase() > b.name.toLowerCase()) {
						return 1;
					}
					else if (a.name.toLowerCase() === b.name.toLowerCase()) {

						if (a.shapeName.toLowerCase() < b.shapeName.toLowerCase()) {
							return -1;
						}
						else if (a.shapeName.toLowerCase() > b.shapeName.toLowerCase()) {
							return 1;
						}
						return 0;
					}
					return 0;
			});
			});
		
		if (self.mapList.length < 1) {
			plan.getAllDocumentsCollectionOfType(plan, 'ecomap_EcoMap,vdml_ValueDeliveryModel', function (results) {
				results.each(function (pack) {
					self.mapList.push(pack);                            
				});
			}, null, null, null, null, true);
		} else {
			// getReferencesToModel2(results);
		}
		return mapList;
	}
	cleanModal() {
		var self = this;
		$('.slipDetailsBox').removeClass('push-back');
		 if ($('#wizardModal').length > 0) {
		 	$('#wizardModal').removeClass('push-back');
		 }
		if (self.callback) {
			self.callback(self.changeInValue);
		}
		window.cleanDialogModel(self.encodeId, self);
	}
		
	getSatSelectedInterval(satisfactionMeasurement) {
		var self = this;
		var selectedInterval;
		var satType = 'symbol';
		var satMeasure = satisfactionMeasurement ? satisfactionMeasurement.get('observedMeasure').get('measure') : null;
		if (satisfactionMeasurement && satisfactionMeasurement.get('value') && satMeasure.get('unit')) {//Ranking
			satType = 'value';
		}
		if (satMeasure) {
			var intervals = satMeasure.get('interval');
			for (var i = 0; i < intervals.length; i++) {
				if (intervals.at(i).get('' + satType) === satisfactionMeasurement.get('' + satType)) {
					selectedInterval = intervals.at(i);
					break;
				}
			}

		}
		return selectedInterval;
	};
	fetchScenario(value) {
		var self = this;
		var scenario;
		var currentAltId = DataManager.getDataManager().get('viewAlternative');
		var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
		var currentAlt = docTypeObj.find({ id: currentAltId });
		scenario = currentAlt.getOrCreatePhaseObjectiveSet();
		/*if (value.getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
			scenario = currentAlt.getOrCreatePhaseObjectiveSet();
		} else */if (self.options.context) {
			scenario = self.options.context;
		}
		return scenario;
	};
	getAlternatives() {
		var self = this;
		var alts = [];
		var currentPlan = DataManager.getDataManager().get('currentPlan');
		var phase = currentPlan.get('phase').findWhere({ nextPhase: null });
		while (phase) {
			var phaseAltsArray = [];
			var phaseAlts = phase.get('phaseAlternative');
			var masterAlt = phase.get('master');
			phaseAlts.each(function (phaseAlt) {
				if (phaseAlt !== masterAlt) {
					phaseAltsArray.push(phaseAlt);
				}
			});
			phaseAltsArray.unshift(masterAlt);
			alts = phaseAltsArray.concat(alts);
			phase = phase.get('previousPhase');
		}
		return alts;
	};
	addMeasurandData(measure, valObj, unitName, measurandData) {
		var self = this;
		var valueData = {};
		valueData.valueName = measure.getMeasurand().get('name');
		valueData.name = measure.getMeasurand().get('name');
		valueData.valueId = measure.getParent().get('id');
		valueData.altId = measure.altId;
		valueData.displayName = valObj.namePath ? valueData.valueName + " [" + valObj.namePath + "]" : valueData.valueName;
		valueData.label = valObj.namePath ? valueData.valueName + " [" + valObj.namePath + "]" + " (" + unitName + ")" : valueData.valueName + " (" + unitName + ")";
		valueData.data = [];
		valueData.valId = measure.id;
		measurandData[unitName] = valueData;
		return measurandData;
	}

	loadSelectedValues(alt, valObj, val, measurandData, callback) {
		var self = this;
		var altObs, dataStr, data, unit;
		if (val) {
			var measure = val.get('valueMeasurement');
			var altComp = alt.getModelLinkedSync(val);
			if (altComp) {
				val = altComp;
			}
			var preAltScenario = altComp ? alt.getMainScenario(altComp.getNestedParent()) : null;
			var observedMeasure = preAltScenario ? preAltScenario.getObservedMeasureWithMeasurements(altComp.get('valueMeasurement')) : null;
			var mest = observedMeasure ? observedMeasure.get('measurements').at(0) : null;
			if (!observedMeasure) {
				observedMeasure = preAltScenario ? preAltScenario.getObservedMeasureWithMeasurements(altComp.get('satisfactionLevel')) : null;
				mest = observedMeasure ? observedMeasure.get('measurements').at(0) : null;
				var compSatisfactionMeasure = mest ? mest.get('observedMeasure').get('measure') : null;
			}
			measure.altId = alt.id;
		}
		var valData = [];
		var phaseName = alt.get('phaseAlternativeOwner').get('name');
		var altName = alt.get('name');
		var altProp = phaseName + " / " + altName;
		valData.push(altProp);
		valData.push(phaseName);
		valData['phaseAlt'] = altProp;
		if (!_.has(self.altIndex, altProp)) {
			self.altIndex[altProp] = (_.keys(self.altIndex)).length;
		}

		function fillValueData(valData) {
			var vm = val ? val.get('valueMeasurement') : null;
			var selectedInterval = preAltScenario && val ? val.getSatisfactionInterval(preAltScenario) : null;
			var smileySrc = '';
			var smileyColor = '';
			var correctiveActions = '';
			if (selectedInterval) {
				smileySrc = val.fetchSmileySrc(selectedInterval.get('smiley'));
				smileyColor = selectedInterval.get('smileyColor');
				correctiveActions = selectedInterval.get('correctiveActions');
			}
			valData.push(smileySrc);
			valData.push(smileyColor);
			valData.push(alt.id);
			valData.push(correctiveActions);
			var valueData;
			if (mest || data) {
				var unitName;
				if (unit) {
					unitName = window.utils.replaceEscapeCharacters(unit.get('name'));
				}

				valueData = measurandData[unitName];
				if (!valueData) {
					unitName = unitName ? unitName : 'Grade only';
					var measureData = self.addMeasurandData(measure, valObj, unitName, measurandData);
					valueData = measureData ? measureData[unitName] : null;
					valueData.significantDecimals = unit ? unit.get('significantDecimals') : '2';
					valueData.unit = unitName;
				}
				valueData.data.push(valData);
			}
			else{
				unitName = " ";
				measureData = self.addMeasurandData(measure, valObj, unitName, measurandData);
				valueData = measureData ? measureData[unitName] : null;
				valueData.significantDecimals = unit ? unit.get('significantDecimals') : '2';
				valueData.unit = unitName;
				valueData.data.push(valData);
			}
			callback(measurandData);
		}

		var vm = val ? val.get('valueMeasurement') : null;
		if (mest && mest.get('observedMeasure').get('measure') && (mest.get('value') !== null || mest.get('symbol') !== null)) {
			unit = mest.get('observedMeasure').get('measure').get('unit');
			if (mest.get('value') === Number.MAX_VALUE) {
				valData.push('####');
			} else if (mest.get('value') === '') {
				valData.push('-');
			} else {
				if (mest.get('value')) {
					valData.push(parseFloat(mest.get('value')));
				} else if (compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
					valData.push(mest.get('symbol'));
				} else {
					valData.push("-");
				}
			}
		} else {
			valData.push("-");
		}
		fillValueData(valData);


	}
	fetchValuesForAlt(valueComponents, callback) {
		var self = this;
		self.altIndex = {};
		var dataSet = {};
		async.each(self.alts, function (altObj, altHandleCallback) {
			var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: altObj.id });
			if (!alt) {
				altHandleCallback();
				return;
			}
			async.each(valueComponents.models, function (valObj, valHandleCallback) {
				var measurandData = {};
				var measure;						
				var val = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: valObj.id });
				if (!val) {
					val = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: valObj.id });
				}
				if (val) {
					measure = val.get('valueMeasurement');
				}
				if (!val) {
					valHandleCallback();
					return;
				}
				self.loadSelectedValues(alt, valObj, val, measurandData, function (measurandDataCalculated) {
					measurandData = measurandDataCalculated;
					var emptyData = measurandData[""];
					if (emptyData) {
						delete measurandData[""];
					}

					Object.getOwnPropertyNames(measurandData).forEach(function (key) {
						var valueData = measurandData[key];
						if (emptyData) {
							valueData.data = valueData.data.concat(emptyData.data);
						}
						if (dataSet[measure.id]) {
							if((dataSet[measure.id].unit == " " || !dataSet[measure.id]) && (valueData.unit != " ")){
								dataSet[measure.id].label = valueData.label;
								dataSet[measure.id].unit = valueData.unit;
							}
							dataSet[measure.id].data = dataSet[measure.id].data.concat(valueData.data);
						} else {
							dataSet[measure.id] = valueData;
						}
					});
					valHandleCallback();
				});
			}, function () {
				altHandleCallback();
			});
		}, function () {
			self.datasets = dataSet;
			var values = Object.getOwnPropertyNames(self.datasets);
			var data = [];
			for (var i = 0; i < values.length; i++) {
				var valueData = self.datasets[values[i]];
				data.push(valueData);
			}
			callback && callback(data);
		});				
	};
	loadTableData(components, callback) {
		var self = this;
		self.alts = self.getAlternatives();
		self.fetchValuesForAlt(components, callback);
	};

	displayTable(data) {
		var self = this;
		if (!data || data.length === 0) {
			return;
		}
		self.showRelatedValues(true);
		$('#valueTable').empty();
		var phaseAltCombos = ["valueName"];
		var tr1Headers = [];
		var uniqueDataForTR2 = [];
		for (var i = 0; i < data.length; i++) {
			for (var j = 0; j < data[i].data.length; j++) {
				uniqueDataForTR2.push(data[i].data[j]);
			}
		}
		uniqueDataForTR2 = _.uniq(uniqueDataForTR2, "phaseAlt");
		_.each(uniqueDataForTR2, function (data) {
			phaseAltCombos.push(data[0]);
			tr1Headers.push(data[1]);
		});
		phaseAltCombos = _.uniq(phaseAltCombos);
		tr1Headers = _.uniq(tr1Headers);
		
		var myTableDiv = $('#valueTable');

		var table = document.createElement('TABLE');
		table.id = 'table';
		table.className = "display";
		//table.className="pageResize";
		table.cellspacing = "0";
		table.width = "100%";

		var tableHeader = document.createElement('THEAD');
		table.appendChild(tableHeader);
		var headerTR1 = document.createElement('TR');
		tableHeader.appendChild(headerTR1);
		//for first values header
		var th = document.createElement('TH');
		th.appendChild(document.createTextNode("Values"));
		th.rowSpan = '2';
		headerTR1.appendChild(th);
		//for phase headers
		_.each(tr1Headers, function (name) {
			var th = document.createElement('TH');
			th.appendChild(document.createTextNode(name));
			var colSpan = phaseAltCombos.reduce(function (n, val) {
				return n + (val.substr(0, val.indexOf('/') - 1) === name);
			}, 0);
			th.colSpan = colSpan;
			headerTR1.appendChild(th);
		});
		
		//to create a new row for alt headers
		var headerTR2 = document.createElement('TR');
		tableHeader.appendChild(headerTR2);

		var tableBody = document.createElement('TBODY');
		table.appendChild(tableBody);

		var trTBody = {};
		for (var k = 0; k < data.length; k++) {
			trTBody[k] = document.createElement('TR');
			tableBody.appendChild(trTBody[k]);
			var td = document.createElement('TD');
			td.align = "left";
            td.style.color = 'inherit';
            td.setAttribute('valueId', data[k].valueId);
            td.appendChild(document.createTextNode(data[k].label));
			/*var nameLink = document.createElement('a');
			nameLink.appendChild(document.createTextNode(data[k].label));
			nameLink.style.color = 'inherit';
			//nameLink.style.cursor = 'pointer';
			nameLink.setAttribute('valueId', data[k].valueId);
			td.appendChild(nameLink);*/
			if(self.editValues){
				$(nameLink).click(function(event){
					self.editValueDetails(event);
				});
			}
			trTBody[k].appendChild(td);
		}

		for (var i = 1; i < headerTR1.children.length; i++) {
			for (var k = 0; k < uniqueDataForTR2.length; k++) {
				if (uniqueDataForTR2[k][1] === headerTR1.children[i].innerText) {
					th = document.createElement('TH');
					th.appendChild(document.createTextNode(uniqueDataForTR2[k][0]));
					headerTR2.appendChild(th);
				}
			}
		}
		var cumulativeColSpan = 0;
		for (var i = 1; i < headerTR1.children.length; i++) {
			for (var j = 0; j < headerTR1.children[i].colSpan; j++) {
				for (var k = 0; k < data.length; k++) {
					var found = _.find(data[k].data, function (e) { return e[1] === headerTR1.children[i].innerText && headerTR2.children[j + cumulativeColSpan].innerText === e[0]; });
					if (found) {
						var td = document.createElement('TD');
						td.align = "right";
						var text = window.utils.thousandSeparator(window.utils.getRoundedValues(found[2], data[k].significantDecimals));
						//var a = document.createElement('a');
						td.appendChild(document.createTextNode(text));
						td.style.color = 'inherit';
						td.setAttribute('id', data[k].valueId);
						td.setAttribute('altId', found[5]);
						if (found[2] != '-') {
							if(self.editValues){
								a.style.cursor = 'pointer';
								$(a).click(function (event) {
									var currentTarget = event.currentTarget;
									var obj1 = { valueId: currentTarget.getAttribute('id'), altId: currentTarget.getAttribute('altId') };
									self.showAggregationView(obj1);
								});
							}
							//td.appendChild(a);
						}
						else{
							td.appendChild(document.createTextNode(text));
						}
						if (found[3]) {
							var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
							svg.setAttribute("width", 21);
							svg.setAttribute("height", 20);
							svg.setAttribute("style", 'vertical-align:bottom;');
							svg.setAttribute("viewBox", "-3 -2 26 26");
							svg.setAttribute("fill", "" + found[4]);
							svg.setAttribute("correctiveActions", found[6]);
							svg.style.cursor = 'pointer';
							var link = document.createElementNS("http://www.w3.org/2000/svg", "path");
							link.setAttribute("d", found[3]);
							svg.appendChild(link);
							$(svg).click(function (event) {
								var correctiveActions = event.currentTarget.getAttribute('correctiveActions') ? event.currentTarget.getAttribute('correctiveActions') : '';
								var htmlContent = "<div class='row'><div class='col-xs-1'></div><div class='col-xs-10'><span class='control-label' style='word-wrap: break-word;'>" + correctiveActions + "</span></div></div>";
								var box = bootbox.dialog({
									message: htmlContent,
									title: "<div><img class='smallIcon' src='img/icons/icons_15.png'>&nbsp;&nbsp;&nbsp;" + DataManager.getDataManager().get('localeManager').get('CorrectiveActions') + "</div>",
									buttons: {
										cancel: {
											label: "Close",
											className: 'btn vtn-default',
											callback: function () {

											}
										}
									}
								});
								box.show();
							});
							td.appendChild(svg);
						}
						trTBody[k].appendChild(td);
					} else {
						var td = document.createElement('TD');
						td.appendChild(document.createTextNode('-'));
						td.align = "right";
						trTBody[k].appendChild(td);
					}
				}
			}
			cumulativeColSpan = cumulativeColSpan + headerTR1.children[i].colSpan;
		}
		myTableDiv.append(table);
		self.dataTable = $('#table').DataTable({
				//"sDom": "Rlfrtip",
				//"bLengthChange": false,
				//"pageResize": true
		});
	};
	cleanUp() {
		var self = this;
		if ($("#summernoteTargetDetailsDefinition").next('.note-editor').length !== 0) {
			$("#summernoteTargetDetailsDefinition").summernote('destroy');
			$("#summernotecss").attr("href", "");
		}
	};
	fetchBMListForVp(vp,activity,actValue) {
		var self = this;
		var mypackage = vp.getNestedParent();
		function fetchMappedBm(pack) {
			var bms = pack.get('businessModel');
			for (var i = 0; i < bms.length; i++) {
				if (activity) {
					var bmAct = bms.at(i).get('bmActivity').findWhere({ 'activity': activity });
					if (bmAct && bmAct.get('activityValue').findWhere({ 'id': actValue.id })) {
						self.bmsNames.push({ 'name': bms.at(i).get('name'),'type':'' ,'valueStrems':'','activityValues': ''});
					}
				} else {
					if (!vp.get('isValueFormula') && bms.at(i).get('bmValueProposition').findWhere({ 'valueProposition': vp })) {
						self.bmsNames.push({ 'name': bms.at(i).get('name'),'type':'' ,'valueStrems':'','activityValues': ''});
					}
					else if (vp.get('isValueFormula') && bms.at(i).get('bmValueFormula').findWhere({ 'valueFormula': vp })) {
						self.bmsNames.push({ 'name': bms.at(i).get('name'),'type':'' ,'valueStrems':'','activityValues': ''});
					}
				}
			}
		}
		fetchMappedBm(mypackage);
		var depPack = mypackage.get('dependentPackage');
		for (var i = 0; i < depPack.length; i++) {
			if (depPack.at(i).get('type') === "vdml_ValueDeliveryModel") {
				fetchMappedBm(depPack.at(i));
			}
		}
	}

		

	fetchSmileySrc(smileyValue) {
		var self = this;
		//var smileyValue = smileyValue?smileyValue:'1';
		var smileySrc = '';
		for (var j = 0; j < self.svgSmiliesList.length; j++) {
			if (self.svgSmiliesList[j].value === smileyValue) {
				smileySrc = self.svgSmiliesList[j].src;
				break;
			}
		}
		return smileySrc;
	};
	showAggregationView(view, event) {
		var self= this;
		var valueId, altId;
		if (!view) {
			valueId = self.mappedModal.id;
			altId = DataManager.getDataManager().get('viewAlternative');
		} else {
			valueId = view.valueId;
			altId = view.altId;
		}
		var altModel = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: altId });
		altModel.showAggregationView(valueId, function(){
			window.utils.startSpinner('refreshValues', 'Refreshing ...');
			DataManager.getDataManager().invokeValueUpdates(function () {
				if (self.parentView) {
					if (self.valueComponents && self.valueComponents.length) {
						self.loadTableData(self.valueComponents, function (data) {
							self.displayTable(data);
						});
					}
					setTimeout(function () {
						window.utils.stopSpinner('refreshValues');
					}, 300);
				} else {
					window.utils.stopSpinner('refreshValues');
				}
			});
		});
	};

	showCorrectiveActions(view) {
		var self = this;
		var correctiveActions = view ? view.correctiveActions : self.correctiveActions;
		var htmlContent = "<div class='row'><div class='col-xs-1'></div><div class='col-xs-10'><span class='control-label' style='word-wrap: break-word;'>" + correctiveActions + "</span></div></div>";
		var box = bootbox.dialog({
			message: htmlContent,
			title: "<div><img class='smallIcon' src='img/icons/icons_15.png' >&nbsp;&nbsp;&nbsp;" + DataManager.getDataManager().get('localeManager').get('CorrectiveActions') + "</div>",
			buttons: {
				cancel: {
					label: "Close",
					className: 'btn vtn-default',
					callback: function () {

					}
				}
			}
		});
		box.show();
	};
		
	getAggregatedColl = function(callback){
		var self = this;
		if(self.aggregatedColl){
			callback(self.aggregatedColl);
		}else {
			var plan = DataManager.getDataManager().get('currentPlan');
			if(!plan){
				plan  = self.currentAlternativeModel.getNestedParent();
			}
			plan.getValuesThatCanBeRefferedTo(function(values){
				self.aggregatedColl = new Backbone.Collection(values);
				callback(self.aggregatedColl);
			});
		}
	};
		
	saveValue = function(view,modalId,type,del){
		var self = this;
		window.utils.startSpinner('values','Saving Value ...');
		window.setTimeout(function(){
			var parent = view.model.getParent();
			
				var previousWorkspace = DataManager.getDataManager().getWorkspace();
				DataManager.getDataManager().getWorkspaceDataWithId(view.currentAlternativeModel.id, function (wsData) {
					var altWorkspace = wsData.get('workspace'); 
					self.currentPlan = DataManager.getDataManager().get('currentPlan');
					DataManager.getDataManager().set('currentPlan', view.currentAlternativeModel.getNestedParent());
					DataManager.getDataManager().setWorkspace(altWorkspace,function(){
						function refreshModalWindow(changeInValue) {
							if (self.nonPrimedValue){
								changeInValue = true;
							}
							DataManager.getDataManager().setWorkspace(previousWorkspace, function () {
								if (changeInValue) {
									self.changeInValue = true;
									DataManager.getDataManager().invokeValueUpdates(function () {
										var obsMeasure = view.currentAlternativeModel.getMainScenario(self.modalEle.getNestedParent()).getObservedMeasureWithMeasurements(self.modalEle.get('valueMeasurement'), null)
										if (obsMeasure) {
											self.selectedValue = obsMeasure.get('measurements').at(0);
										}
										window.cleanDialogModel(modalId, view);
										window.utils.stopSpinner('values');
										if (self.valueComponents && self.valueComponents.length) {
											self.loadTableData(self.valueComponents, function (data) {
												self.displayTable(data);
											});
										}
									});
								} else {
									if (!self.currentPlan) {
										DataManager.getDataManager().set('currentPlan', null);
									}
									window.cleanDialogModel(modalId, view);
									window.utils.stopSpinner('values');
								}
							});
						}
						if (parent.get('type') === 'vdml_ValueProposition') {
							view.saveData(parent.getNestedParent(), parent, refreshModalWindow, null);
						} else {
							var activity = parent.getParent();
							view.saveWhatValuesData(activity, [{ modalId: window.utils.htmlEscape(activity.id), originalId: activity.id }], [], function (actValue, changeInValue) {
								refreshModalWindow(changeInValue);
							}, null);

						}
					});
				});
		},100);        
	}
		
	editValueDetails = function(event){
		var self = this;
		self.currentAlternativeModel = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
		var valueId = event.currentTarget.getAttribute('valueId');
		//for(var i=0; i<self.mappedModal.get('component').models.length; i++){
		//	if(self.mappedModal.get('component').models[i].id === valueId){
		//		self.modalEle = self.mappedModal.get('component').models[i];
		//		break;
		//	}
//          }
		self.modalEle = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: valueId });
		if (!self.modalEle) {
			self.modalEle = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: valueId });
		}
		var type = 'Activity';
		if(self.modalEle.get('type') === 'vdml_ValuePropositionComponent') {
			if(self.modalEle.get('componentOwner').get('isValueFormula')) {
				type = 'valueFormula';
			}
			else if(DataManager.getDataManager().getRepositoryId(self.modalEle.get('id')) === window.plansKey){
				type = 'planValues';
			}
			else {
				type = 'valueProposition';
			}
		}
		self.getAggregatedColl(function(aggregatedColl){
			var scenario = self.mest? self.mest.get('observedMeasure').get('observation').getContext() : self.currentAlternativeModel.getMainScenario(self.modalEle.getNestedParent());
			var addOptions = {};
			if(!scenario){
				var altComp = self.currentAlternativeModel.getModelLinkedSync(self.modalEle);
				if(altComp){
					scenario = self.currentAlternativeModel.getMainScenario(altComp.getNestedParent());
					self.modalEle = altComp;
				}
			}
			if (!scenario.getObservedMeasureWithMeasurements(self.modalEle.get('valueMeasurement'), null)){
				self.nonPrimedValue = true;
			}
			var alt = scenario.getAlternative();
			if(!alt) {
				alt = self.currentAlternativeModel;
			}
			if(type == "Activity"){
				var actModel = self.modalEle.get('valueAddOwner').get('containedPortOwner');
				var actObj = [{originalId:actModel.get('id'),modalId: window.utils.htmlEscape(actModel.get('id')),name:actModel.get('name')}];
				addOptions = { 'type': 'createWhatValues', 'aggTo': false, 'activities': actObj, 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, view: self, 'valuesColl': [], 'bm': null, 'currentAlternativeModel': alt, 'readOnly': false, 'disableDelete': true, 'aggregationView': true};
			} else {
				var overAllSatisfaction = self.modalEle.get('componentOwner').get('overAllSatisfaction');
				if(self.modalEle.id.startsWith(self.currentAlternativeModel.id)){
					addOptions = { 'type': type, 'aggTo': false, 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'overAllSatisfaction': overAllSatisfaction, 'readOnly': false, 'currentAlternativeModel': alt, 'aggregationView': true, 'disableAggr': true,'disableDelete':true};
				}
				else{
					addOptions = { 'type': type, 'aggTo': false, 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'overAllSatisfaction': overAllSatisfaction, 'readOnly': false, 'currentAlternativeModel': alt, 'disableDelete': true, 'aggregationView': true, 'disableAggr': true, 'disableDelete': true};
				}
			}
			window.getAndCreateModalDialog(self.model, 'edit' + window.utils.htmlEscape(self.modalEle.get('id')), BusinessModelMixin, self.modalEle, "ValueDetails", self.saveValue, addOptions);
		});
	};
	
	static getInstance(model, configObj, callback) {
		return new CanvasTargetDetailsViewModel(model, configObj, callback);
	};
}
path.CanvasTargetDetailsViewModel = CanvasTargetDetailsViewModel;
