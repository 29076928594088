import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasure} from './DimensionalMeasure'
import {DimensionalMeasureMixin} from './DimensionalMeasureMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { Operation } from './Operation'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasure","appbo/smm/DimensionalMeasureMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasure,DimensionalMeasureMixin
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
		else{
            import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });
			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	export class DirectMeasureMixin {
        /*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            path.DirectMeasureMixin = DirectMeasureMixin;
        }*/
	defaults(){
		var ret = {
			type: "smm_DirectMeasure"
		}
		return jQuery.extend(DimensionalMeasure.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!DirectMeasureMixin.cummulativeRelations) {
            DirectMeasureMixin.cummulativeRelations = _.union(DirectMeasureMixin.getMixinRelations()
                , DimensionalMeasureMixin.getCumulativeMixinRelations()
            );
        }
		return DirectMeasureMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_DimensionalMeasure"
		];
	}
	static getProperties(){
		return [
			{name : "version",type : "EString",defaultValue : "null",containingClass : "cmof_EObject" },
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			//{name : "definition",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "scale",type : "ScaleOfMeasurement",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "customScale",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "source",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "visible",type : "EBoolean",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "formula",type : "EString",defaultValue : "null",containingClass : "smm_DimensionalMeasure" }
		]
	}
    getParent(){
		var container;
		return DimensionalMeasure.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return DimensionalMeasure.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/DirectMeasurePropertiesTemplate.html",
			templateName : "DirectMeasurePropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/DirectMeasureViewModel",
			tabId : "DirectMeasureView",
			tabName: "DirectMeasure"
		}
    }
	//#startCustomMethods
	constructBaseMeasureTuple(measuredCharacteristic,baseMeasure,newOffset,newMultiplier,newOperation,usePreviousAlternative,isDeleted,isNewTuple,baseMeasureRel){
		var tuple = {};
		if(!isNewTuple){
			tuple.old = {};	
		}
		
		var comp = null;
		if(measuredCharacteristic){
			comp = measuredCharacteristic.get('valueMeasurementOwner')?measuredCharacteristic.get('valueMeasurementOwner'):measuredCharacteristic.get('satisfactionLevelOwner');
			if(!comp){
				comp = measuredCharacteristic.previousAttributes().valueMeasurementOwner;
			}
		}
		if(isNewTuple || (!isDeleted && comp)){
			tuple.new = {};	
		}
		var oldNode = tuple.old;
		var newNode = tuple.new;
		var bmRel;
		if(oldNode){
			oldNode.component = comp;
			oldNode.measure = baseMeasure;
			oldNode.usePreviousAlternative = usePreviousAlternative;
			oldNode.measuredCharacteristic = measuredCharacteristic;
			oldNode.baseMeasureRel = bmRel;
		}
		if(isNewTuple || (!isDeleted && comp)){
			newNode.component = comp;
			newNode.measure = baseMeasure;	
			newNode.measuredCharacteristic = measuredCharacteristic;
			newNode.usePreviousAlternative = usePreviousAlternative;
			newNode.baseMeasureRel = bmRel;
		}
		if(oldNode){
			oldNode.offset = newOffset ? newOffset : 0;
			oldNode.multiplier = newMultiplier ? newMultiplier : 1;
			oldNode.operation = newOperation ? newOperation.get('name') : null;
		}
		if(isNewTuple || (!isDeleted && comp)){
			newNode.offset = newOffset ? newOffset : 0;
			newNode.multiplier = newMultiplier ? newMultiplier : 1;
			newNode.operation = newOperation ? newOperation.get('name') : null;
		}
		return tuple;
	};
	}
	path.DirectMeasureMixin = DirectMeasureMixin;
	
	//return DirectMeasureMixin;
//});