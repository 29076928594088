(function (factory) {
  /* global define */
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node/CommonJS
    module.exports = factory(require('jquery'));
  } else {
    // Browser globals
    factory(window.jQuery);
  }
}(function ($) {

  // Extends plugins for adding editmode.
  //  - plugin is external module for customizing.
  $.extend($.summernote.plugins, {
    /**
     * @param {Object} context - context object has status of editor.
     */
    'editmode': function (context) {
      var self = this;

      // ui has renders to build ui elements.
      //  - you can create a button with `ui.button`
      var ui = $.summernote.ui;

      // add editmode button
      context.memo('button.editmode', function () {
        // create button
        var button = ui.button({
          className: 'read-access',
          contents: '<i class="fa fa-child"/> <div id = "isEditMode">Edit Mode</div>',
          tooltip: 'Toggle Model Edit',
          click: function (edit) {
            if (jQuery(".note-editable").attr("contenteditable") == "false") {
              jQuery("#isEditMode").text("Read Mode");
              jQuery(".note-editable").attr("contenteditable", true);
              jQuery("kbd").removeClass("SummernoteToggleEdit");
              jQuery("kbd").addClass("SummernoteToggle");
              jQuery(".note-toolbar").removeAttr("style");
              jQuery(".note-toolbar").css('float','none');
          } else {              
              jQuery("#isEditMode").text("Edit Mode");
              jQuery(".note-editable").attr("contenteditable", false);  
              jQuery("kbd").removeClass("SummernoteToggle");
              jQuery("kbd").addClass("SummernoteToggleEdit");
              jQuery("kbd").css("background-color", "inherit");
              jQuery(".note-toolbar").css({
                'transform' : 'translateX(0%)',
                'position' : 'absolute',
                'border-color':'transparent ',
                'background-color': 'transparent',
                'right' : '0'
             });
          }
          var hideList = ['style','font','para','height','insert','custom','Canvas','cleaner'];
          for(var x in hideList){
            jQuery(".note-"+hideList[x]).toggle();
          }
/*            self.$panel.show();
            self.$panel.hide(500);
            // invoke insertText method with 'editmode' on editor module.
            context.invoke('editor.insertText', 'editmode');*/
            //$('.note-handle').css('display') === 'none' ? $('.note-handle').css('display','block') : $('.note-handle').css('display','none');
          }
        });

        // create jQuery object from button instance.
        var $editmode = button.render();
        return $editmode;
      });

      // This events will be attached when editor is initialized.
      this.events = {
        // This will be called after modules are initialized.
        'summernote.init': function (we, e) {
          //console.log('summernote initialized', we, e);
        },
        // This will be called when user releases a key on editable.
        'summernote.keyup': function (we, e) {
          //console.log('summernote keyup', we, e);
        }
      };

      // This method will be called when editor is initialized by $('..').summernote();
      // You can create elements for plugin
      this.initialize = function () {
      };

      // This methods will be called when editor is destroyed by $('..').summernote('destroy');
      // You should remove elements on `initialize`.
      this.destroy = function () {
      };
    }
  });
}));
