import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","async","appcommon/com/vbee/data/DataManager","app/global"],*/
//function(require,jquery,_, Backbone,ko, koMapping,kb,bootbox,async,DataManager,global)
var path = DataManager.getDataManager().buildAppNsPath("help.views.properties",global.version);
export class BMGuidanceViewModel {
	constructor(model,options,detailedView){
		var self = this;
		this.BMGuidanceViewModel = this;
		this.parentView = model;
		this.model = model.model;
		this.id = kb.observable(self.model, 'id');
		function htmlEscape(str) {
    		return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '');
		}
		this.encodeId = htmlEscape(this.id());
	}
	
	dispose(){
		var self = this;
		window.cleanViewModel(self);		
	};
	static getInstance(model,options,detailedView){
		var view = new BMGuidanceViewModel(model, options,detailedView);
		return view;
	};
}
path.BMGuidanceViewModel = BMGuidanceViewModel;