import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global","appbo/vdml/ValueDeliveryModel","appbo/vdml/BusinessModel","appbo/vdml/ValueStream","typeahead","appbo/vdml/ValueDeliveryModelMixin", "summernote"],
function(require,$,_, Backbone,ko, koMapping,kb,bootbox,DataManager,global,ValueDeliveryModel,BusinessModel,ValueStream,typeahead,ValueDeliveryModelMixin
){*/
    
    var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties",global.version);
    
    export class BusinessModelActivityDetailsViewModel{
        
        htmlEscape(str) {
            return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '');
        }
		

		showDefinitionSection(){
			var self = this;
            var summernote = $('#summernoteDefinition' + self.encodeId);
            summernote.summernote('destroy');
            summernote.summernote(
                {
                    airMode: true,
                    // dialogsInBody: true,
					height: 100, 
                    minHeight: null,             // set minimum height of editor
                    maxHeight: null,             // set maximum height of editor
                    focus: true,
					placeholder: 'write here...',
                    disableResizeEditor: true, //disable resize 
                    toolbar: [
                        ['style', ['bold', 'italic', 'underline', 'clear']],
                        ['font', ['fontname', 'fontsize', 'color', 'strikethrough', 'superscript', 'subscript']],
                        ['para', ['style', 'ul', 'ol', 'paragraph']],
                        ['height', ['height']]
                        //['insert', ['link', 'table', 'hr']]
                    ]
                });
            summernote.summernote('code', self.model.get('activity').get('definition'));
            summernote.summernote("editor.clearHistory", 'editor');
			$('.note-insert').hide();$('.note-table').hide();
			$('.note-editor').css({"border-color": "#C1E0FF", "border-width":"1px", "border-style":"solid"});
            $("#summernotecss").attr("href", "js/libs/summernote/dist/summernote.css");
			//summernote.summernote('disable');
		}

        
        
		
        getUniqueValue(val){
			var self = this;
        	if(val.trim() != ''){
	        	var childrenColl = self.options.addOptions?self.options.addOptions.activityColl:null;
	        	var activityProviderRole = self.options.addOptions?self.options.addOptions.activityProviderRole:null;
	        	if(childrenColl && activityProviderRole) {
	        		var currentCollaborationActivities = activityProviderRole.get('collaborationRoleOwner').get('activity');
                    var unique = window.isUniqueName(val.trim(), childrenColl, self.options.modalId, currentCollaborationActivities);
                    if (unique && self.disallowedNames.includes(val.trim())) {
                        unique = false;
                    }
	        		if(unique){
                        if (val.trim() !== "" && self.matchField){
		        			self.matchField.show();
		        			self.matchField[0].innerHTML = 'New';
		        		}
		        		else{
                            self.matchField && self.matchField.hide();
		        		}
	        			$('#message'+self.encodeId).text("");
	        			 self.enableComplete(true);
	        		}
	        		else{
	        			if(self.matchField) {
							self.matchField.show();
	        				self.matchField[0].innerHTML = 'Match';
						}
	        			$('#message'+self.encodeId).text(DataManager.getDataManager().get('localeManager').get('ActivityDuplicate'));
	        			self.enableComplete(false);
	        			$('#activity'+self.encodeId).find('li :contains('+val+')').click();
	        		}
	        	}
        	}
        	else {
        		self.enableComplete(false);
        	}
        }
		
        afterRenderView(node, view){
			var self = view;
        	$('.match').hide();
			$('#deleteAct' + self.encodeId).hide();
			if(self.showDefinition()){
				self.showDefinitionSection();
            }
            $('#editDetailsModalContent' + self.encodeId).resize(function () {
                $('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
            })
            //activities typeAhead
            var ActivityExtended = Backbone.Typeahead.extend({
               template: '<input type="text" id="activity'+self.encodeId+'" placeholder="Name" autofocus="autofocus" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
            });
			var activitiesTypeahead = new ActivityExtended({collection:self.displayactivitiesTypeahead});
            activitiesTypeahead.setElement('#activitiesDiv'+self.encodeId).render();
            $('#activity'+self.encodeId).val(self.name());
            activitiesTypeahead.on('selected', function(fetchmodel) {
            	$('#message'+self.encodeId).text("");
        		self.enableComplete(true);
            	var tempmodel=fetchmodel.get('model');
                self.selectedactivity =tempmodel;
                self.checkActivityRoleChange(tempmodel,function(cond){
                	if(cond){
	                	self.enableComplete(true);
	                }
	                else{
	                	self.enableComplete(false);
	                }
	                self.name(tempmodel.get('name'));
	               	self.description(tempmodel.get('description'));
                });
                if(self.selectedactivity.get('capabilityRequirement')){
					self.usedCapability = self.selectedactivity.get('capabilityRequirement').id;
					self.usedCapabilityComName(self.selectedactivity.get('capabilityRequirement').get('name'));
                }
                //$('#description'+self.encodeId).val(tempmodel.get('name'));
            });
			if(self.usedCapability){
				$('#mapCapability'+ self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
				$('#mapCapability'+ self.encodeId).css('margin-left','10px');
				$('#deleteAct'+ self.encodeId).show();
			}
		    $('#activity'+self.encodeId).change(function (view) {
                var newValue = view.currentTarget.value;
                self.name(newValue);
                if(self.matchField && self.matchField[0].innerHTML == 'New' && self.selectedactivity && self.selectedactivity.get('name') != newValue){
                	self.selectedactivity =null;
            	} else {
					self.getUniqueValue(view.currentTarget.value);
				}
            });
            $('#activity'+self.encodeId).keyup(function (view) {
            	self.matchField = $(view.currentTarget).closest('.row').find('.match');
				if(!self.selectedactivity){
					self.getUniqueValue(view.currentTarget.value);
				}
            });
            $('#activity' + self.encodeId).dblclick(function (view) {
                $('#activity' + self.encodeId).trigger('keyup');
                $('#activity' + self.encodeId).focus();
            });
            var selectedPersued = self.options.addOptions.vp;
            if(selectedPersued.get('isValueFormula')){
            	if(self.model){
            		$("#activityTitleLabel"+self.encodeId).text(self.labels['EditMPActivityDetails']());
            	}else{
            		$("#activityTitleLabel"+self.encodeId).text(self.labels['EnterMPActivityDetails']());
        		}
            }
            else{
            	if(self.model){
            		$("#activityTitleLabel"+self.encodeId).text(self.labels['EditVPActivityDetails']());
            	}else{
            		$("#activityTitleLabel"+self.encodeId).text(self.labels['EnterVPActivityDetails']());
        		}
            }
            if(self.model) {
				$("#titleLabel"+self.encodeId).text(DataManager.getDataManager().get('localeManager').get('EditPartnerDetails'));
			}else {
				$("#titleLabel"+self.encodeId).text(DataManager.getDataManager().get('localeManager').get('EnterPartnerDetails'));	
			}
        };
            
        saveActivityDetails(){  
		    var self = this;
            if(self.parentView){
                return self.options.callback(this,self.encodeId,'activity');
            }
        };
        
        mapCapability(){
			var self = this;
			var id = window.utils.htmlEscape(window.guidGenerator());
			var options = {
				capabilityLibs: self.capabilityTypeAhead, callback: function (capId, capName, capDesc, capParentId) {
					if (!capId) {
						return;
					}
					self.usedCapability = capId;
					self.usedCapabilityComName(capName);
					$('#mapCapability'+ self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
					$('#mapCapability'+ self.encodeId).css('margin-left','10px');
					$("#deleteAct" + self.encodeId).show();
					if (self.name() == null || self.name() == "") {
						self.name(capName);
						$('#activity' + self.encodeId).val(capName);
						self.description(capName);
						self.getUniqueValue(capName);
					}
				}, showCapabilityId: self.usedCapability
			};
			var ValueDeliveryModelMixin = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModelMixin');
			window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "CapabilityExplorerJson", null, options);		
        };
        deleteActCapability(view,event) {debugger
			var self = this;
				var cap = self.usedCapability;
				if(cap){
					self.usedCapability = null;
					self.usedCapabilityComName(null);
					$('#deleteAct'+self.encodeId).hide();
					$("#mapCapability" + self.encodeId).removeClass('glyphicon-pencil').addClass('glyphicon-plus-sign');
					$("#mapCapability" + self.encodeId).css('margin-left','-5px');
				}
		};
        
        cleanModal(){
			var self = this;
			var childrens = window.vdmModelView.children;
			var found = false;
			for(var i=0;i<childrens.length;i++){
				if(childrens[i].modalId == self.encodeId){
					found = true;
					break;
				}
			}
            if(!self.enableDelete()) {
				if ($("#summernoteDefinition"+self.encodeId).next('.note-editor').length !== 0) {
					$("#summernoteDefinition").summernote('destroy');
					$("#summernotecss").attr("href", "");
				}
                window.cleanDialogModel(self.encodeId,self);
            }
            else if(!found && self.model){
				if ($("#summernoteDefinition"+self.encodeId).next('.note-editor').length !== 0) {
					$("#summernoteDefinition").summernote('destroy');
					$("#summernotecss").attr("href", "");
				}
            	window.cleanDialogModel(self.encodeId,self);
            }else {
				$('#modal'+self.encodeId).modal('hide');
            }
        };
        
        deleteModal(){	
			var self = this;
        	if(self.model){
        		if(self.model.get('activity').get('containedPort').length > 0 || self.model.get('activity').get('delegationContext').length > 0){
		        	bootbox.confirm(DataManager.getDataManager().get('localeManager').get('DeleteActivity'), function(result) {
						if(result){
							$('#modal'+self.encodeId).modal('hide');
							return self.options.callback(self,self.encodeId,'activity',true);
						}
					});
				} else {
					return self.options.callback(self,self.encodeId,'activity',true);
				}
			}else {
				$('#modal'+self.encodeId).modal('hide');
				return options.callback(self,self.encodeId,'activity',true);
			}
            
        }
        CheckCompetencyExistInBM(competencies,bm){
        	for(var i=0;i<bm.get('bmCompetence').length;i++){
        		for(var j=0;j<competencies.length;j++){
        			if(bm.get('bmCompetence').models[i].get('competence').get('id') === competencies[j].id){
        				return true;
        			}
        		}
        	}
        	return false;
        }
        checkActivityRoleChange(existingActivity,callback){
			var self = this;
        	var newRole = self.options.addOptions.activityProviderRole;
            var role = existingActivity.get('performingRole');
            if(role !== newRole){
            	var comptencies = existingActivity.getActivityCompetences(); 
        		var roleAssignments = existingActivity.get('performingRole').get('roleAssignment').models;
        		var participantName;
        		var oldParticcipant = roleAssignments[0].get('participant');
        		var newParticipant = newRole.get('roleAssignment').at(0).get('participant');
        		var bmNames = "";
        		participantName = roleAssignments[0]?roleAssignments[0].get('participant').get('name'):'';
        		if(comptencies.length > 0 && oldParticcipant != newParticipant){
		    		for(var i=0;i<roleAssignments.length;i++){
							var bmModels = roleAssignments[i].get('participant').get('businessModel').models;
							for(var j=0;j<bmModels.length;j++){
		    					if(j>0 && bmNames !== ""){
		        					bmNames = bmNames.concat(",  ");
		        				}
		    					if(bmModels[j].get('bmCompetence').length>0 && CheckCompetencyExistInBM(comptencies,bmModels[j])){
		    						bmNames = bmNames.concat(bmModels[j].get('name'));
		    					}
		    					
		    				}
						}
						bootbox.alert(DataManager.getDataManager().get('localeManager').get('ActivityCompetencyBlock',existingActivity.get('name'),participantName,bmNames));
        				callback(false);
					}	
    				else{
    					role.getBusinessModelsInvolved(function(businessModels) {
    						for(var j=0;j<businessModels.length;j++){
    							var bm = businessModels.at(j);
	        					var bmActivities = bm.get('bmActivity');
	    						if(bmActivities.findWhere({'activity':existingActivity})){
	        						if(j>0 && bmNames !== ""){
			        					bmNames = bmNames.concat(",  ");
			        				}
	        						bmNames = bmNames.concat(bm.get('name'));
	        						if((j+1)<bm.length){
			        					bmNames.concat("  ,");
			        				}
		        				}
	        				}
	        				var msg = DataManager.getDataManager().get('localeManager').get('ActivityRoleBlock',existingActivity.get('name'),role.get('name'),bmNames);
	        				bootbox.confirm(msg, function(result) {
								if(result){
									callback(true);
								}else {
									callback(false);
								}
							});
        					
	        			});
    				}
            }else {
            	callback(true);
            }
	    }
        
        saveData(bm,role,valueStream,currentAlt,callback){
			var self = this;
			var activity, capModel;
			if (self.usedCapability) {
				capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityCategory').find({ id: self.usedCapability });
				if (!capModel) {
					capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityDefinition').find({ id: self.usedCapability });
				}
			}
            if(self.id()) {
                activity = self.model.get("activity");
                if(this.name() !=  self.model.get("name")){
                	self.model.set("name",this.name());
                	activity.set('name',this.name());
                }
                if(this.description() !=  self.model.get("description")){
                	self.model.set("description",this.description());
                	activity.set('description',this.description());
				}
				if(self.showDefinition()){
					var summernote = $('#summernoteDefinition' + self.encodeId);
					var contentData = summernote.summernote('code');
					if(contentData == "<p><br></p>"){
						contentData = null;
					}
					activity.set('definition', contentData);
				}
				
				activity.set('capabilityRequirement', capModel);
                callback(activity);
            }
            else {
            	if(!self.selectedactivity) {
	                 bm.addBMActivities(this.name(),this.description(),role,currentAlt,function(activity){
						activity.set('capabilityRequirement', capModel);
	                	callback(activity);
	                }); 
                }
                else {
                	activity = self.selectedactivity;
                	if(activity.get('performingRole') && activity.get('performingRole') !== role) {
                		//create auto-generating tickets	
                		bm.addExistingActivities(activity,role);
                		valueStream.get('valueStreamActivity').add(activity);
                		activity.updateBMActivities(role);
                		
                	}else{
                		bm.addExistingActivities(activity,role);	
					}
					activity.set('capabilityRequirement', capModel);
                	callback(activity);
                	
                }
            }
            
           
            //selectedPersued.set('activity',activity); ToDo selectedPersued
            //return activity;
        }           
        
    init(model, options){
		var self = this;
        this.BusinessModelActivityDetailsViewModel = this;
        this.model = model;
        this.id = ko.observable(self.model ? self.model.get('id') : null);
        this.encodeId = window.utils.htmlEscape(this.id());
        if(options && options.parentView){
            this.parentView = options.parentView;
            this.encodeId = ""+options.modalId;
			self.options = options;
        }
        this.name = ko.observable(self.model ? self.model.get('name') : "");
        this.description = ko.observable(self.model ? self.model.get('description') : "");
        this.enableDelete = ko.observable(false);
        this.enableComplete = ko.observable(false);
        this.selectedactivity = null;
        this.displayactivitiesTypeahead = new Backbone.Collection();
        this.name.subscribe(function(name){
			self.description(name);
		});
		this.capabilityTypeAhead = options.capabilityTypeAhead.slice();
		this.showCapabilityLibList = ko.observable(false);
		if (self.capabilityTypeAhead.length > 0) {
			this.showCapabilityLibList(true);
		}
		self.showDefinition = ko.observable(false);
		
		//this.capabilityTypeAhead = ko.observableArray();
  //      _.each(options.capabilityTypeAhead, function (cap) {
  //          var dispName = cap.get('name');
  //          if (dispName != cap.get('description')){
  //              dispName = cap.get('name') + "-" + cap.get('description');
  //          }
  //          self.capabilityTypeAhead.push({ id: cap.id, name: cap.get('name'), displayName: dispName });
		//});
		this.usedCapabilityComName = ko.observable();
		this.usedCapability = null;
		this.disallowedNames = [];
		if(!self.model) {
			self.displayactivitiesTypeahead._reset();
        	if(options.addOptions && options.addOptions.activityTypeAhead) {
        		var actTypeAhead = options.addOptions.activityTypeAhead;
	            for(var j=0;j<actTypeAhead.length;j++){
               // 	var displayActivityname = checkDuplicatesPath(actTypeAhead,actTypeAhead[j]);
					self.displayactivitiesTypeahead.push({id:actTypeAhead[j].id,name:actTypeAhead[j].get('name'),model:actTypeAhead[j],description:actTypeAhead[j].get('description')});
	            }
            }
        }else {
			if (self.model.get('activity') && self.model.get('activity').get('definition') && self.model.get('activity').get('definition').length > 0){
				self.showDefinition(true);
			}
            if (self.model.get('activity') && self.model.get('activity').get('capabilityRequirement')) {
				self.usedCapability = self.model.get('activity').get('capabilityRequirement').id;
				self.usedCapabilityComName(self.model.get('activity').get('capabilityRequirement').get('name'));
            }
            self.enableComplete(true);
            var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: DataManager.getDataManager().get('viewAlternative') });
            self.disallowedNames = alt.getDisallowedNames(self.model.get('activity'), 'activity');
        }
		$('#modal'+self.encodeId).on('shown.bs.modal', function () {
            window.utils.focusElement("#newModal .focus-ele");
            $("#activity"+self.encodeId).focus();
            if(self.name() != null && self.name() !== '') {
                self.enableDelete(true);
            }
            //for modal window close functionality
			$('#activity'+self.encodeId).val(self.name());
        });
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['name'
        ,'description'
        ,'EnterMPActivityDetails'
        ,'EditMPActivityDetails'
        ,'EnterVPActivityDetails'
        ,'EditVPActivityDetails'
        ,'Close'
        ,'Complete'
        ,'Delete'
        ]);
	}
    
    static getInstance(model,options){
        var view = new BusinessModelActivityDetailsViewModel(model,options);
		view.init(model, options);
		return view;
    };
}
path.BusinessModelActivityDetailsViewModel = BusinessModelActivityDetailsViewModel;