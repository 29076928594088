import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasure} from './DimensionalMeasure'
import {DimensionalMeasurementMixin} from './DimensionalMeasurementMixin'
import {RescaledMeasurementRelationship} from './RescaledMeasurementRelationship'
import {Operation} from './Operation'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasurement","appbo/smm/DimensionalMeasurementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasurement,DimensionalMeasurementMixin
, RescaledMeasurementRelationship
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!RescaledMeasurementRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RescaledMeasurementRelationship){
			RescaledMeasurementRelationship = importPath.RescaledMeasurementRelationship;
		}
		else{
			import('./RescaledMeasurementRelationship').then(({ default: RescaledMeasurementRelationship }) => {
				RescaledMeasurementRelationship = RescaledMeasurementRelationship;
			});
			/*require(["appbo/smm/RescaledMeasurementRelationship"],function(loadedModule){
				RescaledMeasurementRelationship = loadedModule;
			});*/
		}
	}
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
		else{
			import('./Operation').then(({ default: Operation }) => {
				Operation = Operation;
			});
			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	export class RescaledMeasurementMixin{
	
	defaults(){
		var ret = {
			type: "smm_RescaledMeasurement"
		}
		return jQuery.extend(DimensionalMeasurement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		/*{
			type :Backbone.HasOne,
			containingClass:"smm_RescaledMeasurement",
			key:"baseQuery",
			relatedModel:"smm.Operation",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}*/
		])
	}
	static getCumulativeMixinRelations(){
		if (!RescaledMeasurementMixin.cummulativeRelations) {
            RescaledMeasurementMixin.cummulativeRelations = _.union(RescaledMeasurementMixin.getMixinRelations()
                , DimensionalMeasurementMixin.getCumulativeMixinRelations()
            );
        }
		return RescaledMeasurementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_DimensionalMeasurement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "error",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			{name : "breakValue",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			{name : "value",type : "EDouble",defaultValue : "null",containingClass : "smm_DimensionalMeasurement" },
			{name : "isBaseSupplied",type : "EBoolean",defaultValue : "null",containingClass : "smm_RescaledMeasurement" }
		]
	}
	getParent(){
		var container;
		return DimensionalMeasurement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return DimensionalMeasurement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/RescaledMeasurementPropertiesTemplate.html",
			templateName : "RescaledMeasurementPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/RescaledMeasurementViewModel",
			tabId : "RescaledMeasurementView",
			tabName: "RescaledMeasurement"
		}
	};
	calculate(skipParentCalculation){
		var self = this;
		var oldValue = self.get('value');
		var observerdMeasure = self.get('observedMeasure');
		var rescaleMeasurementRel = self.get('rescaleFrom');
		var measure = observerdMeasure.get('measure');
		var multiplier = measure.get('multiplier') || 1;
		var offset = measure.get('offset') || 0; 
		var measurementValue = rescaleMeasurementRel.get('from').get('value') * multiplier + offset;
		self.set('value', measurementValue);
		if(measurementValue !== oldValue && !skipParentCalculation){
			self.calculateParents();
		}
	};
	}
	path.RescaledMeasurementMixin = RescaledMeasurementMixin;
	
	//return RescaledMeasurementMixin;
//});