import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AbstractMeasureElement} from './AbstractMeasureElement'
import {ScopeMixin} from './ScopeMixin'
import {Operation} from './Operation'
import {Stereotype} from './Stereotype'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/AbstractMeasureElement","appbo/smm/ScopeMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AbstractMeasureElement,ScopeMixin
, Operation
, Stereotype){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
		else{
			import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });
			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	/*if(!Operation){  // Duplicate as same as above
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
		else{
			require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});
		}
	}*/
	if(!Stereotype){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Stereotype){
			Stereotype = importPath.Stereotype;
		}
		else{
			import('./Stereotype').then(({ default: Stereotype }) => {
                Stereotype = Stereotype;
            });
			/*require(["appbo/smm/Stereotype"],function(loadedModule){
				Stereotype = loadedModule;
			});*/
		}
	}
	var Scope = AbstractMeasureElement.extend(utils.customExtends({
		relations:ScopeMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			AbstractMeasureElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new ScopeMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	Scope.abstract = false;
	Scope.supported =  !sp2Enabled;
	path.Scope = Scope;
	export {Scope};
//});