import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {CalendarServiceMixin} from './CalendarServiceMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/CalendarServiceMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,CalendarServiceMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var CalendarService = VdmlElement.extend(utils.customExtends({
		relations:CalendarServiceMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new CalendarServiceMixin()
	));
	
	CalendarService.abstract = false;
	CalendarService.supported = false;
	path.CalendarService = CalendarService;
	export {CalendarService};
//});