import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessItemLibraryElement} from './BusinessItemLibraryElement'
import {BusinessItemDefinitionMixin} from './BusinessItemDefinitionMixin'

import {PracticeDefinition} from './PracticeDefinition'
import {CapabilityDependency} from './CapabilityDependency'
import {BusinessItemCategory} from './BusinessItemCategory'
import {CapabilityDefinition} from './CapabilityDefinition'
import {EntityReference} from './EntityReference'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessItemLibraryElement","appbo/vdml/BusinessItemDefinitionMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessItemLibraryElement,BusinessItemDefinitionMixin
, PracticeDefinition
, CapabilityDependency
, BusinessItemCategory
, CapabilityDefinition
, EntityReference){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!PracticeDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PracticeDefinition){
			PracticeDefinition = importPath.PracticeDefinition;
		}
		else{
			import('./PracticeDefinition').then(({ default: PracticeDefinition }) => {
                PracticeDefinition = PracticeDefinition;
            });
			/*require(["appbo/vdml/PracticeDefinition"],function(loadedModule){
				PracticeDefinition = loadedModule;
			});*/
		}
	}
	if(!CapabilityDependency){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityDependency){
			CapabilityDependency = importPath.CapabilityDependency;
		}
		else{
			import('./CapabilityDependency').then(({ default: CapabilityDependency }) => {
                CapabilityDependency = CapabilityDependency;
            });
			/*require(["appbo/vdml/CapabilityDependency"],function(loadedModule){
				CapabilityDependency = loadedModule;
			});*/
		}
	}
	if(!BusinessItemCategory){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemCategory){
			BusinessItemCategory = importPath.BusinessItemCategory;
		}
		else{
			import('./BusinessItemCategory').then(({ default: BusinessItemCategory }) => {
                BusinessItemCategory = BusinessItemCategory;
            });
			/*require(["appbo/vdml/BusinessItemCategory"],function(loadedModule){
				BusinessItemCategory = loadedModule;
			});*/
		}
	}
	if(!CapabilityDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityDefinition){
			CapabilityDefinition = importPath.CapabilityDefinition;
		}
		else{
			import('./CapabilityDefinition').then(({ default: CapabilityDefinition }) => {
                CapabilityDefinition = CapabilityDefinition;
            });
			/*require(["appbo/vdml/CapabilityDefinition"],function(loadedModule){
				CapabilityDefinition = loadedModule;
			});*/
		}
	}
	if(!EntityReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.EntityReference){
			EntityReference = importPath.EntityReference;
		}
		else{
			import('./EntityReference').then(({ default: EntityReference }) => {
                EntityReference = EntityReference;
            });
			/*require(["appbo/vdml/EntityReference"],function(loadedModule){
				EntityReference = loadedModule;
			});*/
		}
	}
	var BusinessItemDefinition = BusinessItemLibraryElement.extend(utils.customExtends({
		relations:BusinessItemDefinitionMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BusinessItemLibraryElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new BusinessItemDefinitionMixin()
	));
	
	BusinessItemDefinition.abstract = false;
	BusinessItemDefinition.supported = true;
	path.BusinessItemDefinition = BusinessItemDefinition;
	export {BusinessItemDefinition};
//});