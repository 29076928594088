import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {MeasurementRelationship} from './MeasurementRelationship'
import {BaseMeasurementRelationshipMixin} from './BaseMeasurementRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasurementRelationship","appbo/smm/BaseMeasurementRelationshipMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurementRelationship,BaseMeasurementRelationshipMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var BaseMeasurementRelationship = MeasurementRelationship.extend(utils.customExtends({
		relations:BaseMeasurementRelationshipMixin.getMixinRelations(),
		subModelTypes: {
			smm_Base1MeasurementRelationship : 'smm.Base1MeasurementRelationship',
			smm_Base2MeasurementRelationship : 'smm.Base2MeasurementRelationship',
			smm_BaseNMeasurementRelationship : 'smm.BaseNMeasurementRelationship'
		},
		initialize: function(attributes, options) {
			MeasurementRelationship.prototype.initialize.apply(this, arguments);
		}
		}
		, new BaseMeasurementRelationshipMixin()
	));
	BaseMeasurementRelationship.abstract = true;
	path.BaseMeasurementRelationship = BaseMeasurementRelationship;
	export {BaseMeasurementRelationship};
//});