import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurableElement} from './MeasurableElement'
import {BusinessItemMixin} from './BusinessItemMixin'

import {Competence} from './Competence'
import {CompetenceMixin} from './CompetenceMixin'
import {DeliverableFlow} from './DeliverableFlow'
import {BusinessItemDefinition} from './BusinessItemDefinition'
import {Store} from './Store'
import {CapabilityMethod} from './CapabilityMethod'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/BusinessItemMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,BusinessItemMixin
, Competence
, CompetenceMixin
, DeliverableFlow
, BusinessItemDefinition
, Store
, CapabilityMethod){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Competence){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Competence){
			Competence = importPath.Competence;
		}
		else{
			import('./Competence').then(({ default: Competence }) => {
                Competence = Competence;
            });
			/*require(["appbo/vdml/Competence"],function(loadedModule){
				Competence = loadedModule;
			});*/
		}
	}
	if(!CompetenceMixin){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CompetenceMixin){
			CompetenceMixin = importPath.CompetenceMixin;
		}
		else{
			import('./CompetenceMixin').then(({ default: CompetenceMixin }) => {
                CompetenceMixin = CompetenceMixin;
            });
			/*require(["appbo/vdml/CompetenceMixin"],function(loadedModule){
				CompetenceMixin = loadedModule;
			});*/
		}
	}
	if(!DeliverableFlow){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.DeliverableFlow){
			DeliverableFlow = importPath.DeliverableFlow;
		}
		else{
			import('./DeliverableFlow').then(({ default: DeliverableFlow }) => {
                DeliverableFlow = DeliverableFlow;
            });
			/*require(["appbo/vdml/DeliverableFlow"],function(loadedModule){
				DeliverableFlow = loadedModule;
			});*/
		}
	}
	if(!BusinessItemDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemDefinition){
			BusinessItemDefinition = importPath.BusinessItemDefinition;
		}
		else{
			import('./BusinessItemDefinition').then(({ default: BusinessItemDefinition }) => {
                BusinessItemDefinition = BusinessItemDefinition;
            });
			/*require(["appbo/vdml/BusinessItemDefinition"],function(loadedModule){
				BusinessItemDefinition = loadedModule;
			});*/
		}
	}
	if(!Store){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Store){
			Store = importPath.Store;
		}
		else{
			import('./Store').then(({ default: Store }) => {
                Store = Store;
            });
			/*require(["appbo/vdml/Store"],function(loadedModule){
				Store = loadedModule;
			});*/
		}
	}
	if(!CapabilityMethod){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityMethod){
			CapabilityMethod = importPath.CapabilityMethod;
		}
		else{
			import('./CapabilityMethod').then(({ default: CapabilityMethod }) => {
                CapabilityMethod = CapabilityMethod;
            });
			/*require(["appbo/vdml/CapabilityMethod"],function(loadedModule){
				CapabilityMethod = loadedModule;
			});*/
		}
	}
	var BusinessItem = MeasurableElement.extend(utils.customExtends({
		relations:BusinessItemMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			MeasurableElement.prototype.initialize.apply(this, arguments);
			//this.on("change:name", this.handleResourceNameChange);
			//this.on('destroy',destroyAssociatedStores);
		}
		}
		, new CompetenceMixin()
		, new BusinessItemMixin()
    ));
    BusinessItem["change:name"] = function (model,newName) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
			var previousName = model._previousAttributes ? model._previousAttributes.name:"";
			if(previousName != newName) {
				this.handleResourceNameChange(model, newName);
			}
		}
    }
    BusinessItem['destroy'] = function destroyAssociatedStores(model){
		if(Backbone.skipPersistance){
			return;
		}
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
			var stores = model.previousAttributes().store;
			var storeModels = stores.models.concat();
			_.each(storeModels,function(store){
				store.destroy();
			});
		}
	}	
	
	BusinessItem.abstract = false;
	path.BusinessItem = BusinessItem;
	export {BusinessItem};
//});