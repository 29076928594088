import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import {DataManager} from '../../../data/DataManager'
import * as async from 'async'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as jszip from '../../../../../../libs/jszip/jszip.min'
import { Plan } from '../../../../../version1/bo/transformation/Plan'
import { PlanMixin } from '../../../../../version1/bo/transformation/PlanMixin'
import DynamicClass from '../../../../../DynamicClass';
//import { Workspace } from '../../../../../com/vbee/filesystem/Workspace'
//define(["require","jquery","underscore","async","jszip","spin","backbone","knockout","knockoutMapping","knockback","bootbox","jquerycarousel","slick","jquerycRes","appcommon/com/vbee/data/DataManager","app/global","appbo/transformation/Plan","appbo/transformation/Role","appbo/tickets/User","appbo/transformation/Phase","appbo/transformation/PackageReference","appcommon/com/vbee/filesystem/PackageReference","appbo/vdml/ValueDeliveryModel","appviews/transformation/views/properties/PlanViewModel","appviews/transformation/views/properties/PhaseViewModel","appbo/transformation/PlanType","appbo/transformation/PlanMixin","appcommon/com/vbee/filesystem/Workspace"],
//function(require,$,_,async,jszip,Spinner, Backbone,ko, koMapping,kb,bootbox,jquerycarousel,slick,jquerycRes,DataManager,global,Plan,Role,User,Phase,PlanPackageReference,PackageReference,ValueDeliveryModel,PlanViewModel,PhaseViewModel,PlanType,PlanMixin,Workspace
//){

var path = DataManager.getDataManager().buildAppNsPath("cmof.views.properties",global.version);
export class PlansWorkspaceExplorerViewModel {

		filterPlans(results, callback) {
			var self = this;
			var ret = [];
			if (results) {
				for (var i = 0; i < results.length; i++) {
					ret.push(results[i]);
					var planModalId = window.utils.htmlEscape(results[i].id);
					var found = _.find(self.planNameCollection, function(item) {return item.modalId == planModalId});
					if(!found){
						self.planNameCollection.push({'name':results[i].label,'modalId':planModalId});
					}
				}
				results.length = 0;
			}
			if (callback) {
				callback(ret);
			}
		}
		createPlanView(view, event) {
			var self = this;
			event.stopPropagation();
			localStorage.setItem("marketplaceDetails",JSON.stringify({details:false,marketplaceId:""}))
			var soption = { 'parent': self, 'width': window.innerWidth-window.innerWidth/5+'px' };
			var id = window.utils.htmlEscape(window.guidGenerator()) + "createPlan";
			var Plan = Backbone.Relational.store.getObjectByName("transformation.Plan");
			//import('../../../../../version1/bo/transformation/PlanMixin').then(PlanMixin => {
				if(DataManager.getDataManager().isOnlyReviewer()){
					bootbox.alert("You do not have Permission");
				} else {
					window.getAndCreateModalDialog(true, id, Plan, null, "PlanDetails", null, soption);
				}
			//});
		}
		handlePublicMenus() {
			/*if(window['AuthSpinner'] && !window['AuthSpinner'].el){
				window.showSettings();
			}*/
		}
		cleanUp() {
			var self = this;
			for (var i = 0; i < this.plan().length; i++) {
				var plaView = this.plan()[i];
				var phaseViews = plaView.phase();
				for (var j = 0; j < phaseViews.length; j++) {
					var phaseView = phaseViews[j];
					var altViews = phaseView.phaseAlternative();
					for (var k = 0; k < altViews.length; k++) {
						var altView = altViews[j];
						window.cleanViewModel(altView);
					}
					window.cleanViewModel(phaseView);
				}
				window.cleanViewModel(plaView);
			}
			//var plans = this.plansCollection.models.concat();
			/*			_.each(plans,function(plan){
							plan.destroy();
						});*/
			this.plansCollection.reset();
			jQuery('.visual_search').remove();
			/*$('.visual_search').detach();
			$('.visual_search').empty();*/
		}
		/*createDocumentsCollection(modelArray) {
			var self = this;
			var ret = self.plansCollection;
			ret.reset();
			async.each(modelArray, function (modelItem, loadedPlan) {
				var plansRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
				plansRDFModel.getPlanBackboneModelFromRdf(modelItem.id, function (planModel) {
					ret.add(planModel);
					var encodeId = window.utils.htmlEscape(planModel.id);
					var slickWrapper = $("#customPlanslickWrapper"+encodeId);
					var isInitialized = slickWrapper.hasClass('slick-initialized');
					if (!isInitialized) {
						utils.initializeSlick(slickWrapper);
						var phaseTemplate = $("#customPlanWrapper"+encodeId).children().find('.phaseblock');
						_.each(phaseTemplate, function (template) {
							slickWrapper.slick('slickAdd', template);                    
						});
						$("#customPlanWrapper"+encodeId).hide(50);
					}
					loadedPlan();
				});
			}, function () {
				var parentOptions = { 'parent': self, 'width': window.innerWidth-window.innerWidth/5+'px' };
				var planViews = self.plan();
				for (var i = 0; i < planViews.length; i++) {
					var view = planViews[i];
					view.parentOptions = parentOptions;
				}
				modelArray.length = 0;
				window.setTimeout(function () {
					window.utils.applyFontSize(jQuery('#content'));
					if(window['loadPlanSpinner']){
						window.utils.stopSpinner('loadPlanSpinner');
					}
				}, 500);
				self.plansCollection.sort();
			});
		}
		displayResults(results) {
			var self = this;
			self.createDocumentsCollection(results);
		}*/
		showAccordion(view,event) {
			var planEncodeId = event.currentTarget.getAttribute("encodeId");
			$("#customPlanWrapper"+planEncodeId).toggle();
			var slickWrapper = $("#customPlanslickWrapper"+planEncodeId);
			var isInitialized = slickWrapper.hasClass('slick-initialized');
			if (!isInitialized) {
				utils.initializeSlick(slickWrapper);
			}
			if($("#customPlanWrapper"+planEncodeId).is(":visible")){
				var phaseTemplate = slickWrapper.children().find('.phaseblock');
				_.each(phaseTemplate, function (template) {
					slickWrapper.slick('slickAdd', template);                    
				});
				slickWrapper.slick('refresh');
				$($.find('a[href="#collapse'+planEncodeId+'"]')).addClass('glyphicon-chevron-up').removeClass('glyphicon-chevron-down');
			}else {
				$($.find('a[href="#collapse'+planEncodeId+'"]')).addClass('glyphicon-chevron-down').removeClass('glyphicon-chevron-up');
			}
		}
		
		navigateToDashboard(view,event) {
			event.stopPropagation();
			var phaseId = event.currentTarget.getAttribute("dashPhaseId");
			var currentAltId = event.currentTarget.getAttribute("dashAltId");
			var planId = event.currentTarget.getAttribute('dashPlanId');
			window.utils.startSpinner('fetchPlanSpinner', "Fetching Plan...");
			window.utils.loadPlan(planId, function (plan) {
			window.utils.stopSpinner('fetchPlanSpinner');
			//DataManager.getDataManager().getPlanInstance(currentPlanId, DataManager.getDataManager().get('currentPlanVersion'), function(plan){
				//DataManager.getDataManager().set('currentPlan',plan);
				//window.setTimeout(function () {
					var currentAlt;
					if(!currentAltId){
						var phases = plan.get('phase');
						_.each(phases.models, function (phase) {
							if(phaseId == phase.id){
								currentAlt = phase.get('phaseAlternative').at(0);
							}
						});
					} else {
						currentAlt = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({id: currentAltId});
					}
					window.utils.navigateToDashboard(currentAlt);
				//}, 500);
			});
		}
		
		/*initializeSearch(searchValue) {
			var self = this;
			searchValue = searchValue ? searchValue : (self.options ? self.options.searchValue : null);
			var currentWorkSpaceKey = DataManager.getDataManager().get('currentWorkSpaceKey');
			var nameNS;
			nameNS = 'vbc:cmof_EObject-name';
	
			self.visualSearch = window.VS.init({
				container: jQuery('.visual_search'),
				query: 'Tag:"Plan"',
				showFacets: true,
				remainder: 'Name',
				unquotable: [],
				callbacks: {
					search: function (query, searchCollection) {
						self.getMatchingNamesList("", searchCollection.toArray(), _.bind(self.displayResults,self), true, nameNS);
					},
					facetMatches: function (callback) {
						var facets;
						facets = ['Document', 'Tag', 'Name', 'DocumentOwner'];
	
						var categories = self.visualSearch.searchBox.app.searchQuery.models;
						for (var i = 0; i < categories.length; i++) {
							var category = categories[i].get('category');
							if (category === "Document") {
								facets.splice(facets.indexOf("Document"), 1);
							}
							if (category === "Name") {
								facets.splice(facets.indexOf("Name"), 1);
							}
                            if (category === "DocumentOwner") {
								facets.splice(facets.indexOf("DocumentOwner"), 1);
							}
						}
						callback(facets);
					},
					valueMatches: function (facet, searchTerm, callback) {
						switch (facet) {
							case 'Package':
								var packages = self.getMatchingPackageList(searchTerm, self.visualSearch.searchBox.app.searchQuery.models, callback);
								break;
							case 'Document':
								callback(['Plan']);
								break;
							case 'Tag':
								function returnUniqueTags(result) {
									var result = _.uniq(result, false, function (r) {
										return r.value;
									});
									callback(result);
								}
								self.getMatchingTagsList(searchTerm, self.visualSearch.searchBox.app.searchQuery.models, nameNS, returnUniqueTags);
								break;
							case 'Name':
								self.getMatchingNamesList(searchTerm, self.visualSearch.searchBox.app.searchQuery.models, callback, false, nameNS);
								break;
                            case 'DocumentOwner':
								callback(["My Plans", "Shared Plans"]);
								break;
	
						}
					}
				}
			});
			//self.visualSearch.searchQuery.trigger("search")
			if (searchValue) {
				self.visualSearch.searchBox.value(searchValue);
			}
			self.visualSearch.searchBox.searchEvent({});
		}
		getMatchingTagsList(searchTerm, categories, namePropertyNS, callback) {
			var self = this;
			var docType;
			var name;
			var documentOwner;
			var packages = new Array();
			var tags = new Array();
			for (var i = 0; i < categories.length - 1; i++) {
				var category = categories[i].get('category');
				var value = categories[i].get('value');
				if (category === "Package") {
					if (value !== "") {
						packages.push(value)
					}
				} else if (category === "Document") {
					if (!docType && value !== "") {
						docType = value;
					}
				} else if (category === "Tag") {
					if (value !== "") {
						tags.push(value)
					}
				} else if (category === "Name") {
					if (value !== "") {
						name = value;
					}
				} else if (category === "DocumentOwner") {
					if (value !== "") {
						documentOwner = value;
					}
				}
			}
			var rdfModel = DataManager.getDataManager().getRDFModel();
			rdfModel.getTags(searchTerm, docType, packages, tags, name, namePropertyNS, callback);
		}
		getMatchingNamesList(searchTerm, categories, callback, includeLastCategory, namePropertyNS) {
			var self = this;
			var docType;
			var name;
			var documentOwner;
			var packages = new Array();
			var tags = new Array();
			var length = includeLastCategory ? categories.length : categories.length - 1;
			for (var i = 0; i < length; i++) {
				var category = categories[i].get('category');
				var value = categories[i].get('value');
				if (category === "Package") {
					if (value !== "") {
						packages.push(value)
					}
				} else if (category === "Document") {
					if (!docType && value !== "") {
						docType = value;
					}
				} else if (category === "Tag") {
					if (value !== "") {
						tags.push(value)
					}
				} else if (category === "Name") {
					if (value !== "") {
						name = value;
					}
				} else if (category === "DocumentOwner") {
					if (value !== "") {
						documentOwner = value;
					}
				}
			}
			function getPlansFromRdf(){
				var rdfModel = DataManager.getDataManager().getRDFModel();
				if (rdfModel) {
					//var planTypeProperty = [{property : "planType",predicate : "transformation_Plan-planType"}];
					function filterPlansCallback(results) {
						self.filterPlans && self.PlansWorkspaceExplorerViewModel && self.filterPlans(results, callback);
					}
					//rdfModel.getDocumentNames(searchTerm,docType,packages,tags,name,filterPlansCallback,namePropertyNS,planTypeProperty);			
					rdfModel.getDocumentNames(searchTerm, docType, packages, tags, name, filterPlansCallback, namePropertyNS, null, null, documentOwner);
				}
			}
			//var initPackages = DataManager.getDataManager().get('initializedPackages');
			//if(initPackages.length > 0){
			//	getPlansFromRdf();
			//}else{
				//var query = {tags:[],type: docType,name : name,documentOwner : documentOwner};
				//query.tags = tags;
				//query.tags = [];
				//query.type = docType;
				//query.name = name;
				//query.documentOwner = documentOwner;
				//window.refresh = false;
				loadHomePagePlans(true,getPlansFromRdf)
			//}
		}*/
		importAllPlans(lazyLoad,artifacts,callback){
            var self = this;
			//DataManager.getDataManager().set('suppressLogging', true);
			//DataManager.getDataManager().set('suppressChangeLogging', true);
			async.eachSeries(artifacts, function (artifact, generatedRDF) {
				var planModel;
				if(lazyLoad){
					planModel = DataManager.getDataManager().get("homePlans").findWhere({'id':artifact.id});
					if(!planModel){
						//TODO fetch
					}
				} else {
					planModel = self.generateDummyPlanObject(artifact);
					DataManager.getDataManager().get("homePlans").add(planModel);
				}
				self.addPlanToHomePage(planModel);
				generatedRDF();
			},function(err){
				//DataManager.getDataManager().set('suppressLogging', false);
				//DataManager.getDataManager().set('suppressChangeLogging', false);
				callback();
			});
		}
		/*getMatchingPackageList(searchTerm, categories, callback) {
			var rdfModel = DataManager.getDataManager().getRDFModel();
			rdfModel.getPackages(searchTerm, callback);
		}*/
		generateDummyPlanObject(artifact){
			var planModel = new Backbone.Model({id:artifact.id,name:artifact.name,description:artifact.description,version:artifact.version});
			//planModel.set('status',stat);
			planModel.set('goal',artifact.goal);
			planModel.set('asIs',artifact.asIs);
			planModel.set('documentVersion',artifact.documentVersion);
			planModel.set('version',artifact.version);
			var phases = new Backbone.Collection();
			var phaseList = artifact.phase;
			if(phaseList){
				phases.comparator = function(ph1,ph2){
					var next = ph1.get('nextPhase');
					if(next){
						var nextModel = phases.get(next);
						while(nextModel){
							if(nextModel.id === ph2.id){
								return -1;
							}else{
								next = nextModel.get('nextPhase');
								if(next){
									nextModel = phases.get(next);	
								}
								else{
									return 1;
								}
							}
						}
						return 1;
					}else{
						return 1;
					}
				};
				for(var i=0;i<phaseList.length;i++){
					var phase = phaseList[i];
					var phaseModel = new Backbone.Model({id:phase.id,name:phase.name,status:phase.statust,description:phase.description,phaseOwner:artifact.id,primary:phase.primary});
					phaseModel.phaseOwner = planModel;
					if(phase.nextPhase){
						phaseModel.set('nextPhase',phase.nextPhase);
					}
					if(phase.previousPhase){
						phaseModel.set('previousPhase',phase.prevPhase);
					}
					var alts = new Backbone.Collection();
					var altList = phase.phaseAlternative;
					for(var j=0;j<altList.length;j++){
						var alt = altList[j];
						var altModel = new Backbone.Model({id:alt.id,name:alt.name,description:alt.description,phaseAlternativeOwner:phase.id});
						altModel.phaseAlternativeOwner = phaseModel;
						alts.add(altModel);
					}
					phaseModel.set('phaseAlternative',alts);
					phases.add(phaseModel);
				}
			}
			planModel.set('phase',phases);
			return planModel;
		}
		addPlanToHomePage(planModel,deleteOld){
			var self = this;
			if(!DataManager.getDataManager().checkPlanVersion(planModel.get('version'))){ return }
			if(deleteOld){
				var planColl = window.vdmModelView.PlansWorkspaceExplorerViewModel.plansCollection;
				for(var i=0;i<planColl.length;i++){
					if(planModel.id == planColl.at(i).id){
						window.vdmModelView.PlansWorkspaceExplorerViewModel.plansCollection.remove(planColl.at(i));
						planModel = self.generateDummyPlanObject(planModel.toJSON());
						break;
					}
				}
			}
			self.plansCollection.add(planModel);
			var parentOptions = { 'parent': self, 'width': window.innerWidth-window.innerWidth/5+'px' };
			var planViews = self.plan();
			for (var i = 0; i < planViews.length; i++) {
				var view = planViews[i];
				if(view.id == planModel.id){
					view.parentOptions = parentOptions;
					break;
				}				
			}
			var encodeId = window.utils.htmlEscape(planModel.id);
			var slickWrapper = $("#customPlanslickWrapper"+encodeId);
			var isInitialized = slickWrapper.hasClass('slick-initialized');
			if (!isInitialized) {
				utils.initializeSlick(slickWrapper);
				var phaseTemplate = $("#customPlanWrapper"+encodeId).children().find('.phaseblock');
				var linked = false;
				var planArtDoc = DataManager.getDataManager().get("artifactsDocuments")[planModel.id];
				if(planArtDoc && planArtDoc.ref){
					linked = true;
					$("#customPlanWrapper"+encodeId).children().find('.phaseblock').find('.customPhaseblock').addClass("btn-opace");
					$("#customPlanWrapper"+encodeId).children().find('.phaseblock').find('.customAltblock').addClass("btn-opace");
				}
				var phaseIdList = planModel.get('phase').map((phModal) => 'customPlanphaseblock'+window.utils.htmlEscape(phModal.id));
				_.each(phaseTemplate, function (template) {
					if(phaseIdList.indexOf(template.id) >= 0){
						slickWrapper.slick('slickAdd', template);
					}else {
						slickWrapper.slick('slickRemove', template);
					}              
				});
				if(!deleteOld){
					$("#customPlanWrapper"+encodeId).hide(50);
				}
				else{
					$('a[href="#collapse'+encodeId+'"]').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
				}
			}
		}
		togglePublicPlans() {
			var self = this;
			var dataManager = DataManager.getDataManager();
			var pb = !dataManager.get("public");
			dataManager.set("public",pb);
			dataManager.getWorkspacesFromBackend(function(){
				dataManager.getPublicWorkspaces(function(){
					self.loadHomePagePlans(false);
				});
			});
		}
		loadHomePagePlans(lazyload,callback){
			var self = this;
			var dataManager = DataManager.getDataManager();
			window.utils.startSpinner('loadPlanSpinner', "Loading Plans...");
			self.plansCollection.reset();
			var query = {tags:[],type: '',name : '',documentOwner : ''};
			if(lazyload){
				query.lazyload = true;
				var workspaceList = [];
				for(var key in dataManager.get("workspaceNames")){
					workspaceList.push(key);
				}
				query.workspaceId = workspaceList;
			} else {
				dataManager.get("homePlans").reset();
			}
			dataManager.getPlansFromBackend(query,function(artifacts){
				self.importAllPlans(lazyload,artifacts,function(){
					window.utils.stopSpinner('loadPlanSpinner');
					callback && callback();
				});
			});
			
		}

		afterRenderView(node,view) {
			var self = view;
			try {
				if(self.showPublic() && DataManager.getDataManager().get("public")){
					$('.switch-input').click();
				}
				$(".switch-input").change(function() {
					self.togglePublicPlans();
				});
				//if(jQuery('#regproductHeader') && jQuery('#regproductHeader').length == 0){
					window.utils.startSpinner('loadPlanSpinner', "Loading Plans...");
				//}
				//var searchNode = jQuery('#searchPlans');
				//var searchFieldNode = $("<div><div class=\"visual_search\"></div><div id=\"search_query\"></div></div>");
				//var searchFieldNode = jQuery("<div class=\"visual_search\"></div>");
				//searchNode.append(searchFieldNode);
                //var dataManager = DataManager.getDataManager();
                /*if (dataManager.get('useAzure')) {
			        window.showAdminMenus();
		        }*/
				window.showLoginOption(function(rolesFetched){
					var lazyLoad = false;//DataManager.getDataManager().get("homePlans").length > 0 ? true : false; 
					self.loadHomePagePlans(lazyLoad);
				});
			} catch (e) {
				/*if (window.utils.checkChrome()) {
					chrome.runtime.reload();
				}*/
				console.log(e);
			}
		}
        /*checkAndBootboxMSg(artifactLength, planImportSucessCount){
            var self = this;
            if(artifactLength == planImportSucessCount){
                bootbox.alert(DataManager.getDataManager().get("localeManager").get("ImportComplete", "Plan(s)"));
            }
        }*/
		plansCollectionComparator(m1, m2) {
			var m1Name = m1.get('name');
			var m2Name = m2.get('name');
			if (m1Name.toLowerCase() < m2Name.toLowerCase()) {
				return -1;
			}
			if (m1Name.toLowerCase() > m2Name.toLowerCase()) {
				return 1;
			}
			return 0;
		}

		init(model, options) {
			var self = this;
			this.PlansWorkspaceExplorerViewModel = this;
			this.model = model;
			model = null;
			this.options = options;
			options = null;
			this.editable = ko.observable(false);
			this.version = kb.observable(self.model, 'version');
			this.selectedPlan = ko.observable();
			this.planNameCollection = [];
			this.plansCollection = new Backbone.Collection();
			this.plansCollection.comparator = _.bind(self.plansCollectionComparator, self);
			var PlanViewModel = DynamicClass.getView('PlanViewModel');
			this.plan = kb.collectionObservable(this.plansCollection, { view_model: PlanViewModel });
			this.showPublic = ko.observable(false);
			//if(DataManager.getDataManager().isEnterpriseEdition()){
				this.showPublic(true);
			//}
			//var initPackages = DataManager.getDataManager().get('initializedPackages');

			this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), ['Plan', 'Phase', 'version', 'show'
				, 'Status'
				, 'Values'
				, 'Filter'
				, 'NewPlan'
				, 'MyPlans'
				, 'AllPlans'
			]);
		}
		static getInstance(model,options){
			var view = new PlansWorkspaceExplorerViewModel();
			view.init(model, options);
			return view;
		};
	}
	path.PlansWorkspaceExplorerViewModel = PlansWorkspaceExplorerViewModel;
