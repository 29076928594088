import * as $ from 'jquery'
import * as _ from 'underscore'
import * as Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","knockout","appcommon/com/vbee/data/DataManager","app/global","appbo/vdml/BusinessModel","knockback"],
function(require,$,_, Backbone,ko, DataManager,global,BusinessModel,kb){*/
var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties", global.version);
export class BusinessModelReportViewModel {
    init(model, options) {
        var self = this;
        this.name = ko.observable("");
        this.BusinessModelReportViewModel = this;
        this.currentBM = options.addOptions;
        this.encodeId = options.encodeId;
        this.bmNamespace = ko.observable();
        this.relativepath = ko.observable();
        this.businessModel = ko.observable(options.parentView.get('name'));
        self.options = options;
        this.pColl = [];
        _.each(this.currentBM.participantCollection(), function(item) {
            item.attached && self.pColl.push(item);
        });
        this.partCollection = ko.observableArray([]);
        self.fillbmParticipant(self.pColl);
        this.bmVPListCollection = ko.observableArray([]);
        self.fillbmValueProposition();
        this.bmVFListCollection = ko.observableArray([]);
        self.fillbmValueFormula();
        this.bmActivityListCollection = ko.observableArray([]);
        self.fillbmActivityList();
        this.bmCompetencyListCollection = ko.observableArray();
        self.fillbmCompetency();
        this.bmValues = ko.observableArray();
        if (options.addOptions.bmValues()) {
            self.bmValues(options.addOptions.bmValues());
        }

        this.enableDelete = ko.observable(false);

        var iframeEle = $('#BMReportFrame');
        if (!iframeEle.length) {
            $('<iframe>', {
                src: '',
                id: 'BMReportFrame',
                frameborder: 0,
                scrolling: 'no'
            }).appendTo('body');
            $('#BMReportFrame').contents().find('head').append('<link rel="stylesheet" type="text/css" href="js/css/print.css" media="print" />');
            $('#BMReportFrame').css('display', 'none');
        }
        self.getBMPath();
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'name', 'description', 'Role', 'ValueFormula','source','type', 'print', 'Complete', 'Close', 'EnterCustomerDetails', 'MyPropositions', 'MyProposition', 'fromRole', 'values', 'Pursued', 'participantRole', 'Activity', 'Activities', 'Competencies', 'ValuePropositions', 'ParticipantNetwork', 'YourBusiness', 'CustomerRole', 'PartnerRole', 'NotAssignedRole', 'ValueProposition', 'ParticipantNetworks', 'toRole', 'satisfaction', 'recipientOpinion', 'BusinessModelReport', 'BusinessModel'
        ]);

    }
    getBMPath() {
        var self = this;
        var plan = DataManager.getDataManager().get('currentPlan');
        var alternative;
        var altId = DataManager.getDataManager().get('viewAlternative') ? DataManager.getDataManager().get('viewAlternative') : DataManager.getDataManager().get('currentWorkspace').get("id");
        DataManager.getDataManager().getAlternative(altId, function(alt) {
            alternative = alt;
        });
        var phase = alternative.get('phaseAlternativeOwner');
        self.relativepath(plan.get('name') + " / " + phase.get('name') + " / " + alternative.get('name'));
    }

    afterRenderView() {}

    print() {
        var self = this;
        var BMReportFrame = $('#BMReportFrame');
        var iframeBody = BMReportFrame.contents().find('body');
        iframeBody.empty();
        iframeBody.append($('#BMReportContainer').clone());
        setTimeout(function() {
            BMReportFrame[0].contentWindow.print();
            self.cleanModal();
        }, 100);
    }

    fillbmParticipant(pColl) {
        var self = this;
        var partColl = pColl;
        var partCollObj = [];
        var bmPartners = self.currentBM.model.get('bmNetworkPartner');
        var bmCustomers = self.currentBM.model.get('bmCustomer');
        var PNs = new Backbone.Collection();
        PNs = self.options.parentView.get('participantNetwork').models;
        var partNames = [];
        for (var i = 0; i < partColl.length; i++) {
            var displayPnName = self.getPnName(PNs, partColl[i]);
            partNames.push({
                name: partColl[i].name,
                originalId: partColl[i].originalId,
                nsPath: displayPnName
            })
            var businessRoleId = partColl[i].businessColl[0].roles[0].originalId;
            var businessRoleName = self.getRoleDispName(businessRoleId);
            if (businessRoleName) {
                partColl[i].businessColl[0].roles[0].name = businessRoleName.displayRolename;
            }
            var custColl = [];
            for (var j = 0; j < partColl[i].customerColl.length; j++) {
                var customer = bmCustomers.findWhere({
                    id: partColl[i].customerColl[j].originalId
                });
                var custObj = self.getParticipantDispName(customer.get('customer')) || {};
                partColl[i].customerColl[j].custObj = custObj;
                var cust = _.findWhere(custColl, {
                    partName: custObj.partName
                });
                custColl.push({
                    partName: custObj.partName,
                    nsPath: custObj.nsPath,
                    index: j
                });
                if (cust) {
                    partColl[i].customerColl[cust.index].custObj.nsPath = cust.nsPath;
                } else {
                    //partColl[i].customerColl[j].custObj.nsPath = undefined;
                }
                for (var k = 0; k < partColl[i].customerColl[j].roles.length; k++) {
                    var roleId = partColl[i].customerColl[j].roles[k].originalId;
                    var roleObj = self.getRoleDispName(roleId) || {};
                    if (k === partColl[i].customerColl[j].roles.length - 1) {
                        if (j !== partColl[i].customerColl.length - 1) {
                            roleObj.displayRolename = roleObj.displayRolename + "),";
                        } else {
                            roleObj.displayRolename = roleObj.displayRolename + ")";
                        }

                    }
                    partColl[i].customerColl[j].roles[k].roleObj = roleObj;
                }
            }
            var pColl = [];
            for (var j = 0; j < partColl[i].partnerColl.length; j++) {
                var partner = bmPartners.findWhere({
                    id: partColl[i].partnerColl[j].originalId
                });
                var partObj = self.getParticipantDispName(partner.get('networkPartner')) || {};
                partColl[i].partnerColl[j].partObj = partObj;
                var cust = _.findWhere(pColl, {
                    partName: partObj.partName
                });
                pColl.push({
                    partName: partObj.partName,
                    nsPath: partObj.nsPath,
                    index: j
                });
                if (cust) {
                    partColl[i].partnerColl[cust.index].partObj.nsPath = cust.nsPath;
                } else {
                    //partColl[i].partnerColl[j].partObj.nsPath = undefined;
                }
                for (var k = 0; k < partColl[i].partnerColl[j].roles.length; k++) {
                    var roleId = partColl[i].partnerColl[j].roles[k].originalId;
                    var roleObj = self.getRoleDispName(roleId) || {};
                    if (k === partColl[i].partnerColl[j].roles.length - 1) {
                        if (j !== partColl[i].partnerColl.length - 1) {
                            roleObj.displayRolename = roleObj.displayRolename + "),";
                        } else {
                            roleObj.displayRolename = roleObj.displayRolename + ")";
                        }

                    }
                    partColl[i].partnerColl[j].roles[k].roleObj = roleObj;
                }
            }
            partCollObj.push({
                seqNo: partColl[i].seqNo,
                originalId: partColl[i].originalId,
                name: partColl[i].name,
                nsPath: partNames[i].nsPath,
                businessColl: partColl[i].businessColl,
                customerColl: partColl[i].customerColl,
                partnerColl: partColl[i].partnerColl,
                attached: true,
                notAssigned: partColl[i].notAssignedRoles
            });
        }
        /*var tempPart = partNames;
        for(var i=0;i<partNames.length;i++){
        	for(var j=0;j<tempPart.length;j++){
        		if(partNames[i].name === tempPart[j].name && partNames[i].originalId !== tempPart[j].originalId){
        			for(var k=0;k<partCollObj.length;k++){
        				if(partCollObj[i].originalId === partNames[i].originalId && !partCollObj[i].isNameSpaceAdded){
        					var bmName = partCollObj[i].pNModel.get('participantNetworkOwner').get('name');
        					partCollObj[i].name = partCollObj[i].name + " ["+bmName+"]";
        					partCollObj[i].isNameSpaceAdded = true;
        				}
        			}
        			break;
        		}
        	}
        }*/
        self.partCollection(partCollObj);
    }
    fillbmValueProposition() {
        var self = this;
        var bmVPList = self.currentBM.bmVPListCollection();
        var bmVPs = self.currentBM.model.get('bmValueProposition');
        var vps = new Backbone.Collection();
        bmVPs.each(function(bmVP) {
            vps.add(bmVP.get('valueProposition'));
        });
        var vpListObj = [],
            vpColl = [],
            pColl = [],
            rColl = [];
        for (var i = 0; i < bmVPList.length; i++) {
            var vp = vps.findWhere({
                id: bmVPList[i].id
            });
            var vpDispName = self.getPropositionDispName(vps, vp);
            var cust = _.findWhere(vpColl, {
                vpName: vpDispName.vpName
            });
            vpColl.push({
                vpName: vpDispName.vpName,
                displayname: vpDispName.displayname,
                index: i
            });
            if (cust) {
                vpListObj[cust.index].bmVPName = {
                    vpName: cust.vpName,
                    displayname: cust.displayname
                };
            } else {
                vpDispName.displayname = undefined;
            }

            var providerParticipant = self.currentBM.model.getRoleParticipant(vp.get('provider'));
            var partDispName = self.getParticipantDispName(providerParticipant) || {};

            var cust = _.findWhere(pColl, {
                partName: partDispName.partName
            });
            pColl.push({
                partName: partDispName.partName,
                nsPath: partDispName.nsPath,
                index: i
            });
            if (cust) {
                vpListObj[cust.index].provider.nsPath = cust.nsPath;
            } else {
                partDispName.nsPath = undefined;
            }

            var providerRoleDispName = self.getRoleDispName(vp.get('provider'));
            providerRoleDispName = self.fillRoleTypeName(vp.get('provider'), providerRoleDispName);
            var providerName = _.extend(partDispName, providerRoleDispName);

            var receiverParticipant = self.currentBM.model.getRoleParticipant(vp.get('recipient'));
            if (receiverParticipant) {
                var receiverParticipantName = self.getParticipantDispName(receiverParticipant) || {};
                var rcust = _.findWhere(rColl, {
                    partName: receiverParticipantName.partName
                });
                rColl.push({
                    partName: receiverParticipantName.partName,
                    nsPath: receiverParticipantName.nsPath,
                    index: i
                });
                if (rcust) {
                    vpListObj[rcust.index].receiver.nsPath = rcust.nsPath;
                } else {
                    receiverParticipantName.nsPath = undefined;
                }
                var recieverRoleDispName = self.getRoleDispName(vp.get('recipient'));
                recieverRoleDispName = self.fillRoleTypeName(vp.get('recipient'), recieverRoleDispName);
                var receiverName = _.extend(receiverParticipantName, recieverRoleDispName);
            }
            vpListObj.push({
                id: vp.get('id'),
                bmVPName: vpDispName,
                bmVPDescription: vp.get('description'),
                provider: providerName,
                receiver: receiverName,
                values: bmVPList[i].values
            });
            providerName = "";
            receiverName = "";
        }
        self.bmVPListCollection(vpListObj);
    }
    fillbmValueFormula() {
        var self = this;
        var bmVFList = self.currentBM.bmVFListCollection();
        var bmVFs = self.currentBM.model.get('bmValueFormula');
        var vfs = new Backbone.Collection();
        var vfListObj = [];
        bmVFs.each(function(bmVF) {
            vfs.add(bmVF.get('valueFormula'));
        });
        var vfColl = [];
        for (var i = 0; i < bmVFList.length; i++) {
            var vf = vfs.findWhere({
                id: bmVFList[i].id
            });
            if (vf) {
                var vfDispName = self.getPropositionDispName(vfs, vf);
                var cust = _.findWhere(vfColl, {
                    vpName: vfDispName.vpName
                });
                vfColl.push({
                    vpName: vfDispName.vpName,
                    displayname: vfDispName.displayname,
                    index: i
                });
                if (cust) {
                    vfListObj[cust.index].bmVFName = {
                        vpName: cust.vpName,
                        displayname: cust.displayname
                    };
                } else {
                    vfDispName.displayname = undefined;
                }
                var roleObj = self.getRoleDispName(vf.get('provider'));

                vfListObj.push({
                    id: vf.get('id'),
                    bmVFName: vfDispName,
                    bmVFDescription: vf.get('description'),
                    business: roleObj,
                    values: bmVFList[i].values
                });
            }
        }
        self.bmVFListCollection(vfListObj);
    }
    fillRoleTypeName(role, displayName) {
        var self = this;
        var found = false;
        for (var i = 0; i < self.currentBM.model.get('bmCustomer').length; i++) {
            var bmCustomer = self.currentBM.model.get('bmCustomer').at(i).get('customerRole').findWhere({
                id: role.get('id')
            });
            if (bmCustomer) {
                displayName.displayRoleType = displayName.displayRoleType.concat(" (Customer) ");
                found = true;
                break;
            }
        }
        if (!found) {
            for (var i = 0; i < self.currentBM.model.get('bmNetworkPartner').length; i++) {
                var bmCustomer = self.currentBM.model.get('bmNetworkPartner').at(i).get('networkPartnerRole').findWhere({
                    id: role.get('id')
                });
                if (bmCustomer) {
                    displayName.displayRoleType = displayName.displayRoleType.concat(" (Partner) ");
                    found = true;
                    break;
                }
            }
        }
        if (!found) {
            displayName.displayRoleType = displayName.displayRoleType.concat(" (Business) ");
        }
        return displayName;
    }
    getPnName(PNs, participantNetwork) {
        var self = this;
        var pn;
        for (var j = 0; j < PNs.length; j++) {
            if (PNs[j].get('collaboration').id === participantNetwork.originalId) {
                pn = PNs[j];
                break;
            }
        }
        var displayname = "";
        for (var i = 0; i < PNs.length; i++) {
            if ((PNs[i].get('name') == pn.get('name')) && (PNs[i].id !== pn.id)) {
                displayname = "   [";
                for (var k = 0; k < pn.getPackagePath().length - 1; k++) {
                    displayname = displayname.concat(pn.getPackagePath()[k].name);
                    if (pn.getPackagePath()[k + 2]) {
                        displayname = displayname.concat("/");
                    }
                }
                displayname = displayname.concat("]");
            }
        }
        return displayname;
    }
    getPropositionDispName(vps, vp) {
        var self = this;
        var vpName = "";
        if (vp) {
            vpName = vp.get('name');
        }
        var displayname = "";
        for (var i = 0; i < vps.length; i++) {
            if ((vps.at(i).get('name') == vp.get('name')) && (vps.at(i).get('id') !== vp.get('id'))) {
                displayname = "   [";
                for (var k = 0; k < vp.getPackagePath().length - 1; k++) {
                    displayname = displayname.concat(vp.getPackagePath()[k].name);
                    if (vp.getPackagePath()[k + 2]) {
                        displayname = displayname.concat("  /  ");
                    }
                }
                displayname = displayname.concat("]");
            }
        }
        return {
            displayname: displayname,
            vpName: vpName
        };
    }
    getRoleDispName(role) {
        var self = this;
        var roleModel;
        var roles = self.currentBM.model.get('bmRole');
        if (typeof role == "string") {
            roleModel = roles.findWhere({
                id: role
            });
        } else {
            roleModel = role;
        }
        if (roleModel) {
            var displayRolename = roleModel.get('name');
            var roleNS = "";
            for (var i = 0; i < roles.length; i++) {
                if ((roles.at(i).get('name') === roleModel.get('name')) && (roles.at(i).get('id') !== roleModel.get('id'))) {
                    roleNS = " [";
                    for (var k = 0; k < roleModel.getPackagePath().length - 1; k++) {
                        roleNS = roleNS.concat(roleModel.getPackagePath()[k].name);
                        if (roleModel.getPackagePath()[k + 2]) {
                            roleNS = roleNS.concat("/");
                        }
                    }
                    roleNS = roleNS.concat("]");
                }
            }
            return {
                displayRolename: displayRolename,
                displayRoleType: "",
                roleNS: roleNS
            };
        } else {
            return {
                displayRolename: "",
                displayRoleType: "",
                roleNS: ""
            };
        }
    }
    getParticipantDispName(participant) {
        var self = this;
        var participantModel;
        if (self.currentBM.model) {
            var participantsArray = self.currentBM.model.getParticipants();
        }
        if (typeof participant == "string") {
            var participants = new Backbone.Collection();
            for (var i = 0; i < participantsArray.length; i++) {
                participants.add(participantsArray[i]);
            }
            participantModel = participants.findWhere({
                id: participant
            });
        } else {
            participantModel = participant;
        }
        if (participantModel) {
            var participantName1 = participantModel.getPackagePath()[0].name;
            var nsPath1 = "";
            for (var i = 0; i < participantsArray.length; i++) {
                if ((participantsArray[i].get('name') === participantModel.get('name')) && (participantsArray[i].get('id') !== participantModel.get('id'))) {
                    nsPath1 = "[" + participantModel.getNestedParent().get('name') + "]";
                }
            }
            return {
                partName: participantName1,
                nsPath: nsPath1
            };
        } else {
            return {
                partName: "",
                nsPath: ""
            };
        }
    }
    fillbmActivityList() {
        var self = this;
        var activityList = self.options.addOptions.bmActivityListCollection();
        var finalActivityList = [];
        var bmValueStreams = self.options.parentView.get('bmValueStream');
        var vps = new Backbone.Collection();
        bmValueStreams.each(function(bmValueStream) {
            vps.add(bmValueStream.get('persuedProposition'));
        });
        var rColl = [];
        for (var i = 0; i < activityList.length; i++) {
            var vp = vps.findWhere({
                id: activityList[i].id
            });
            if (vp) {
                var persuedName = self.getPropositionDispName(vps, vp);
            }
            var participantId = activityList[i].bmParticipantId;
            var participantName1 = self.getParticipantDispName(participantId) || {};
            var rcust = _.findWhere(rColl, {
                partName: participantName1.partName
            });
            rColl.push({
                partName: participantName1.partName,
                nsPath: participantName1.nsPath,
                index: i
            });
            if (rcust) {
                finalActivityList[rcust.index].bmParticipant.nsPath = rcust.nsPath;
            } else {
                participantName1.nsPath = undefined;
            }
            var roleId = activityList[i].bmActivityRoleId;

            var displayRolename = self.getRoleDispName(roleId) || {};
            var roleModel;
            var roles = self.currentBM.model.get('bmRole');
            if (typeof roleId == "string") {
                roleModel = roles.findWhere({
                    id: roleId
                });
            }
            if (roleModel) {
                displayRolename = self.fillRoleTypeName(roleModel, displayRolename);
            }
            var obj = {
                id: activityList[i].id,
                bmPersued: persuedName,
                bmParticipantId: participantId,
                bmParticipant: participantName1,
                bmActivityRoleId: roleId,
                bmActivityRole: displayRolename,
                bmActivityColl: activityList[i].bmActivityColl,
                bmCreatingValuesColl: activityList[i].bmCreatingValuesColl
            };
            finalActivityList.push(obj);
        }
        self.bmActivityListCollection(finalActivityList);
    }
    fillbmCompetency() {
        var self = this;
        var compList = self.currentBM.bmCompetencyListCollection();
        var comptObj = [];
        var bmActivities = self.currentBM.model.get('bmActivity');
        var activities = new Backbone.Collection();
        bmActivities.each(function(bmActivity) {
            activities.add(bmActivity.get('activity'));
        });
        for (var i = 0; i < compList.length; i++) {
            var activity = activities.findWhere({
                id: compList[i].originalId
            });
            if (activity) {
                var actDispName = activity.get('name');
                var nsPath = "";
                for (var k = 0; k < activities.length; k++) {
                    if ((activities.at(i).get('name') === activity.get('name')) && (activities.at(i).get('id') !== activity.get('id'))) {
                        nsPath = " [" + activity.get('activityOwner').get('name') + "]";
                    }
                }
                var roleDisp = self.getRoleDispName(activity.get('performingRole')) || {};
                var actObj = {
                    originalId: activity.get('id'),
                    bmActivity: actDispName,
                    nsPath: nsPath,
                    bmParticipantRole: roleDisp,
                    bmCompetencyColl: compList[i].bmCompetencyColl
                };
                comptObj.push(actObj);
            }
        }
        self.bmCompetencyListCollection(comptObj);
    }
    cleanModal() {
        var self = this;
        if (!self.enableDelete()) {
            //resizable property is stored in businessmodel view , so removing it when print is closed.
            if (self.options.addOptions.resizable) {
                delete self.options.addOptions.resizable;
            }
            $("#modal" + self.options.modalId).modal('hide');
            setTimeout(function() {
                $('#modal' + self.options.modalId).remove();
            }, 1000);
            //commenting temp, its removing the refrence of business model's observable refrence
            //window.cleanDialogModel(options.modalId,this);
        }
        //$(document.documentElement).css('overflow', '');
    }


    deleteModal() {
        var self = this;
        return self.options.callback(this, self.encodeId, 'Report', true);
    }


    static getInstance(model, options) {
        var view = new BusinessModelReportViewModel(model, options);
        view.init(model, options);
        return view;
    }
}
path.BusinessModelReportViewModel = BusinessModelReportViewModel;