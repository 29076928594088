import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
export class ScenarioProxyMixin{
    constructor() {
        var path = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
        path.ScenarioProxyMixin = ScenarioProxyMixin;
    }
	
	defaults(){
		var ret = {
			type: "transformation_ScenarioProxy"
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!ScenarioProxyMixin.cummulativeRelations) {
            ScenarioProxyMixin.cummulativeRelations = _.union(ScenarioProxyMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return ScenarioProxyMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "scenario",type : "EString",defaultValue : "null",containingClass : "transformation_ScenarioProxy" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("designScenarioOwner") ? this.get("designScenarioOwner") : this.previousAttributes().designScenarioOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("designScenarioOwner") ? this.get("designScenarioOwner") : this.previousAttributes().designScenarioOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/transformation/views/properties/ScenarioProxyPropertiesTemplate.html",
			templateName : "ScenarioProxyPropertiesTemplate",
			viewTypeStr : "appviews/transformation/views/properties/ScenarioProxyViewModel",
			tabId : "ScenarioProxyView",
			tabName: "ScenarioProxy"
		}
	}
	
	//return ScenarioProxyMixin;
}
path.ScenarioProxyMixin = ScenarioProxyMixin;