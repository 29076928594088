import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessItemLibraryElement} from './BusinessItemLibraryElement'
import {BusinessItemLibraryElementMixin} from './BusinessItemLibraryElementMixin'
import {BusinessItemDefinition} from './BusinessItemDefinition'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessItemLibraryElement","appbo/vdml/BusinessItemLibraryElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessItemLibraryElement,BusinessItemLibraryElementMixin
, BusinessItemDefinition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!BusinessItemDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemDefinition){
			BusinessItemDefinition = importPath.BusinessItemDefinition;
		}
		else{
			import('./BusinessItemDefinition').then(({ default: BusinessItemDefinition }) => {
                BusinessItemDefinition = BusinessItemDefinition;
            });
			/*require(["appbo/vdml/BusinessItemDefinition"],function(loadedModule){
				BusinessItemDefinition = loadedModule;
			});*/
		}
	}
	export class BusinessItemCategoryMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.BusinessItemCategoryMixin = BusinessItemCategoryMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_BusinessItemCategory"
		}
		return jQuery.extend(BusinessItemLibraryElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessItemCategory",
			key:"categoryItem",
			relatedModel:"vdml.BusinessItemDefinition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessItemCategory",
			key:"parentCategory",
			relatedModel:"vdml.BusinessItemCategory",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessItemCategory",
			key:"childCategory",
			relatedModel:"vdml.BusinessItemCategory",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!BusinessItemCategoryMixin.cummulativeRelations) {
            BusinessItemCategoryMixin.cummulativeRelations = _.union(BusinessItemCategoryMixin.getMixinRelations()
                , BusinessItemLibraryElementMixin.getCumulativeMixinRelations()
            );
        }
		return BusinessItemCategoryMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_BusinessItemLibraryElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		return BusinessItemLibraryElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return BusinessItemLibraryElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BusinessItemCategoryPropertiesTemplate.html",
			templateName : "BusinessItemCategoryPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BusinessItemCategoryViewModel",
			tabId : "BusinessItemCategoryView",
			tabName: "BusinessItemCategory"
		}
	}
	
	}
	path.BusinessItemCategoryMixin = BusinessItemCategoryMixin;
	//return BusinessItemCategoryMixin;
//});