import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BaseMeasurementRelationship} from './BaseMeasurementRelationship'
import {BaseNMeasurementRelationshipMixin} from './BaseNMeasurementRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { DimensionalMeasurement } from './DimensionalMeasurement'
import { CollectiveMeasurement } from './CollectiveMeasurement'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/BaseMeasurementRelationship","appbo/smm/BaseNMeasurementRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BaseMeasurementRelationship,BaseNMeasurementRelationshipMixin
, DimensionalMeasurement
, CollectiveMeasurement){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!DimensionalMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasurement){
			DimensionalMeasurement = importPath.DimensionalMeasurement;
		}
        else {
            import('./DimensionalMeasurement').then(({ default: DimensionalMeasurement }) => {
                DimensionalMeasurement = DimensionalMeasurement;
            });

			/*require(["appbo/smm/DimensionalMeasurement"],function(loadedModule){
				DimensionalMeasurement = loadedModule;
			});*/
		}
	}
	if(!CollectiveMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.CollectiveMeasurement){
			CollectiveMeasurement = importPath.CollectiveMeasurement;
		}
        else {
            import('./CollectiveMeasurement').then(({ default: CollectiveMeasurement }) => {
                CollectiveMeasurement = CollectiveMeasurement;
            });

			/*require(["appbo/smm/CollectiveMeasurement"],function(loadedModule){
				CollectiveMeasurement = loadedModule;
            });*/
		}
	}
	var BaseNMeasurementRelationship = BaseMeasurementRelationship.extend(utils.customExtends({
		relations:BaseNMeasurementRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BaseMeasurementRelationship.prototype.initialize.apply(this, arguments);
			//this.on("change:to",this.onToRemoved);
		}
		}
		, new BaseNMeasurementRelationshipMixin()
    ));
    BaseNMeasurementRelationship["change:to"] = function (model, newTo, options) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.onToRemoved(model, newTo, options);
		}
    }
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	BaseNMeasurementRelationship.abstract = false;
	BaseNMeasurementRelationship.supported = !sp2Enabled;
	path.BaseNMeasurementRelationship = BaseNMeasurementRelationship;
	export {BaseNMeasurementRelationship};
//});