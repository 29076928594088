import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelItem} from './BusinessModelItem'
import {BusinessModelItemMixin} from './BusinessModelItemMixin'
import {Activity} from './Activity'
import {ValueElement} from './ValueElement'
import {OutputPort} from './OutputPort'
import {ValueAdd} from './ValueAdd'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelItem","appbo/vdml/BusinessModelItemMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelItem,BusinessModelItemMixin
, Activity
, ValueElement
, OutputPort
, ValueAdd){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Activity){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Activity){
			Activity = importPath.Activity;
		}
		else{
			import('./Activity').then(({ default: Activity }) => {
                Activity = Activity;
            });
            /*require(["appbo/vdml/Activity"],function(loadedModule){
                Activity = loadedModule;
            });*/
		}
	}
	if(!ValueElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueElement){
			ValueElement = importPath.ValueElement;
		}
		else{
			import('./ValueElement').then(({ default: ValueElement }) => {
                ValueElement = ValueElement;
            });
			/*require(["appbo/vdml/ValueElement"],function(loadedModule){
				ValueElement = loadedModule;
			});*/
		}
	}
	if(!OutputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OutputPort){
			OutputPort = importPath.OutputPort;
		}
		else{
			import('./OutputPort').then(({ default: OutputPort }) => {
                OutputPort = OutputPort;
            });
			/*require(["appbo/vdml/OutputPort"],function(loadedModule){
				OutputPort = loadedModule;
			});*/
		}
	}
	if(!ValueAdd){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueAdd){
			ValueAdd = importPath.ValueAdd;
		}
		else{
			import('./ValueAdd').then(({ default: ValueAdd }) => {
                ValueAdd = ValueAdd;
            });
			/*require(["appbo/vdml/ValueAdd"],function(loadedModule){
				ValueAdd = loadedModule;
			});*/
		}
	}
	export class BusinessModelActivityMixin {

	defaults(){
		var ret = {
			type: "vdml_BusinessModelActivity"
		}
		return jQuery.extend(BusinessModelItem.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"vdml_BusinessModelActivity",
			key:"activity",
			relatedModel:"vdml.Activity",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_BusinessModelActivity",
			key:"defaultOutput",
			relatedModel:"vdml.OutputPort",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessModelActivity",
			key:"activityValue",
			relatedModel:"vdml.ValueAdd",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!BusinessModelActivityMixin.cummulativeRelations) {
            BusinessModelActivityMixin.cummulativeRelations = _.union(BusinessModelActivityMixin.getMixinRelations()
                , BusinessModelItemMixin.getCumulativeMixinRelations()
            );
        }
		return BusinessModelActivityMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_BusinessModelItem"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("bmActivityOwner") ? this.get("bmActivityOwner") : this.previousAttributes().bmActivityOwner;
			if(!container){
				container = this.get("activity") ? this.get("activity") : this.previousAttributes().activity;
			}
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("bmActivityOwner") ? this.get("bmActivityOwner") : this.previousAttributes().bmActivityOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BusinessModelActivityPropertiesTemplate.html",
			templateName : "BusinessModelActivityPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BusinessModelActivityViewModel",
			tabId : "BusinessModelActivityView",
			tabName: "BusinessModelActivity"
		}
	}
 //#startCustomMethods	
	
	handleOnActivityDeleted(bmAct) {
		if(Backbone.skipPersistance){
    		return;
    	}
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
			if (bmAct.get('activity') == null && bmAct.get('bmActivityOwner')) {
				bmAct.destroy();
			}
		}
	}
//#endCustomMethods
}
	path.BusinessModelActivityMixin = BusinessModelActivityMixin;
	
	//return BusinessModelActivityMixin;
//});