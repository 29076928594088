import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import { AnalyticsMixin } from '../../../../../version1/bo/dashboard/AnalyticsMixin'
import * as jszip from '../../../../../../libs/jszip/jszip.min'

/*define(["require","jquery","underscore","backbone","knockout","bootbox","async","jszip","knockoutMapping","knockback","appcommon/com/vbee/data/DataManager","app/global","appbo/dashboard/AnalyticsMixin"],
function(require,$,_, Backbone,ko,bootbox,async,jszip,koMapping,kb,DataManager,global,AnalyticsMixin)
{*/
    var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.properties", global.version);
export class DashboardPackageViewModel{
    constructor(model, options) {
        this.init(model, options);
    }
    fillAnalyticsCharts() {
        var self = this;
		var analyticsChartsList = self.model.get('dashboard').models.concat();
		for (var i = 0; i < analyticsChartsList.length; i++)
		{
			self.analyticsChartsColl.push({name:analyticsChartsList[i].get('name'),description:analyticsChartsList[i].get('description')})	
		}
	}
	
    cleanAndReturnModal() {
        var self = this;
		self.cleanModal();
	}

    deleteAnalyticsPackage() {
        var self = this;
		function doDelete(){
			self.model.destroy = self.model.constructor.prototype.destroy;
			self.model.destroy({success:function(){
				//DataManager.getDataManager().get('changedParentObjects').remove(self.model);
				//DataManager.getDataManager().get('changedObjects').remove(self.model);
				DataManager.getDataManager().get('artifactsDocuments')[self.model.id] = null;
				self.cleanAndReturnModal();
				window.utils.stopSpinner('deletePackage');  
			},error:function(){
				console.log('Failed to remove package');
				DataManager.getDataManager().removeInitializedPackage(self.model.get('id'));
				self.cleanAndReturnModal();
				window.utils.stopSpinner('deletePackage');
			}});
		}

		if(self.model.get('dashboard').length>0){
			var message = DataManager.getDataManager().get('localeManager').get('PackageDeleteAlert',DataManager.getDataManager().get('localeManager').get('Dashboard'));
			bootbox.confirm(message, function(result) {
				if(result){
					window.utils.startSpinner('deletePackage','Deleting Dashboard Package...');
					setTimeout(function(){
						var scenarioList = [];
						var dashboardList = self.model.get('dashboard');
						for(var i=0;i<dashboardList.length;i++){
							var config = dashboardList.at(i).get('config');
							if(config) {
								var configJson = JSON.parse(config);
								for(var alt in configJson){
									for(var whenObserved in configJson[alt]){
										scenarioList.push(configJson[alt][whenObserved]);
									}	
								}
								
							}
						}
						scenarioList = _.uniq(scenarioList);
						DataManager.getDataManager().get('currentPlan').deleteScenario(scenarioList, function () {
							DataManager.getDataManager().calculateValuesCache = {};
							doDelete();
						});
					},100);
				}
				else {
					self.cleanAndReturnModal();
				}
			});
		}
		else{
			setTimeout(doDelete,0);
		}
	};
	
	showobjectExplorerTree(){
		var self = this;
		//console.log(JSON.stringify(self.model.toJSON()));
		var addOptions= this;
		var id = window.utils.htmlEscape(window.guidGenerator()) + self.encodeId;
		window.getAndCreateModalDialog(true,id,AnalyticsMixin,self.options.addOptions.model,"explorerModelJson",closeView,addOptions);
		function closeView(){
			window.cleanDialogModel(id,AnalyticsMixin);
		}
	};
    saveAnalyticsPackageDetails() {
        var self = this;
		if(self.model){
			self.model.set('name',self.name());
			self.model.set('description',self.description());
		}
		self.cleanAndReturnModal();
	};
	
    cleanModal() {
        var self = this;
        var options = self.options;
		if(self.parentView && self.id()){
			window.cleanDialogModel(self.encodeId,self);
		}
		options.callback(options.addOptions.searchValue);
	};

    afterRenderView(node, view) {
        var self = view;
		$("#analyticsPackageName"+self.encodeId).keyup(function (view) {
			var name = view.currentTarget.value.trim();
			var found = false;
			if (name !== "") {    
				for (var i = 0; i < self.packageList.length; i++) {
					if (self.packageList[i].label === name && self.packageList[i].id !== self.id()){
						found = true;
						$('.warning').removeClass('hide');
						$('#complete').prop("disabled", true);
						break;
					}
				}
				if(!found){
					$('.warning').addClass('hide');
					$('#complete').prop("disabled", false);
				}
			}
		});
		window.utils.applyFontSizeInDialog();
	}
		

	init(model, options){
		var self = this;
		
		this.DashboardPackageViewModel = this;
		this.model = model;
		this.id = kb.observable(model, 'id');
		function htmlEscape(str) {
			return String(str)
				.replace(/@/g, '')
				.replace(/ /g, '');
		}
		this.encodeId = htmlEscape(this.id());
		if (options && options.parentView) {
			this.parentView = options.parentView;
			this.encodeId = "" + options.modalId;
			this.packageList = options.packages;
		}
		this.version = kb.observable(model, 'version');
		this.name = ko.observable(self.model ? self.model.get('name') : null);
		this.description = ko.observable(model ? model.get('description') : "");
		this.analyticsChartsColl = ko.observableArray([]);

		$('#modal' + self.encodeId).on('shown.bs.modal', function () {
			window.utils.focusElement("#newModal .focus-ele");
		});

		this.name.subscribe(function (val) {
			self.description(val);
			if (val == '') {
				$("#complete").attr('disabled', 'disabled');
			}
			else {
				$("#complete").removeAttr('disabled');
			}
        });
        this.options = options;
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
			'name'
			, 'description'
			, 'Close'
			, 'Delete'
			, 'Complete'
			, 'EditVDMLpackage'
			, 'Dashboards']);
		self.fillAnalyticsCharts();

	}
		
	static getInstance(model, options) {
		var view= new DashboardPackageViewModel(model, options);
		return view;
	}

}
path.DashboardPackageViewModel = DashboardPackageViewModel;