import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BinaryMeasurement} from './BinaryMeasurement'
import {BinaryMeasurementMixin} from './BinaryMeasurementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/BinaryMeasurement","appbo/smm/BinaryMeasurementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BinaryMeasurement,BinaryMeasurementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	export class RatioMeasurementMixin {

	defaults(){
		var ret = {
			type: "smm_RatioMeasurement"
		}
		return jQuery.extend(BinaryMeasurement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!RatioMeasurementMixin.cummulativeRelations) {
            RatioMeasurementMixin.cummulativeRelations = _.union(RatioMeasurementMixin.getMixinRelations()
                , BinaryMeasurementMixin.getCumulativeMixinRelations()
            );
        }
		return RatioMeasurementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_BinaryMeasurement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "error",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			{name : "breakValue",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			{name : "value",type : "EDouble",defaultValue : "null",containingClass : "smm_DimensionalMeasurement" },
			{name : "isBaseSupplied",type : "EBoolean",defaultValue : "null",containingClass : "smm_BinaryMeasurement" }
		]
	}
	getParent(){
		var container;
		return BinaryMeasurement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return BinaryMeasurement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/RatioMeasurementPropertiesTemplate.html",
			templateName : "RatioMeasurementPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/RatioMeasurementViewModel",
			tabId : "RatioMeasurementView",
			tabName: "RatioMeasurement"
		}
        }
    }
	path.RatioMeasurementMixin = RatioMeasurementMixin;
	
	//return RatioMeasurementMixin;
//});