import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as async from 'async'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { BMCanvasDiagram } from '../../../../bo/canvas/BMCanvasDiagram'
import { BMCanvasDiagramMixin } from '../../../../bo/canvas/BMCanvasDiagramMixin'
import { ValueDeliveryModel } from '../../../../bo/vdml/ValueDeliveryModel'
import { ReportDetailsViewModel } from '../properties/ReportDetailsViewModel'
import { BMGuidanceViewModel } from '../../../help/views/properties/BMGuidanceViewModel'
import { DashboardMixin } from '../../../../bo/dashboard/DashboardMixin'
import { Analytics } from '../../../../bo/dashboard/Analytics'
import { CodeContainer } from '../../../../bo/beeppackage/CodeContainer'
import { EcoMapDiagramMixin } from '../../../../bo/vdml/EcoMapDiagramMixin'
import * as EcoNavigatedViewer from '../../../../../../libs/bower-vdml-js/dist/vdml-navigated-viewer.min'
import {summernote} from '../../../../../../libs/summernote/dist/summernote'
import * as summernoteeditmode from '../../../../../../libs/summernote/plugin/summernote-edit-mode'
import * as summernoteimportText from '../../../../../../libs/summernote/plugin/summernote-import-text'
import * as summernoteimportModels from '../../../../../../libs/summernote/plugin/summernote-import-Models'
import * as summernoteobjectexplorer from '../../../../../../libs/summernote/plugin/summernote-object-explorer'
import * as summernoteprint from '../../../../../../libs/summernote/plugin/summernote-ext-print'
import * as summernotefindnreplac from '../../../../../../libs/summernote/plugin/summernote-text-findnreplace'
import * as summernoteBackgroundColor from '../../../../../../libs/summernote/plugin/summernote-background-color'
import * as summernotePageBreak from '../../../../../../libs/summernote/plugin/summernote-pagebreak'
import * as summernotecaseconverter from '../../../../../../libs/summernote/plugin/summernote-case-converter'
import * as summernoteSpecialChars from '../../../../../../libs/summernote/plugin/summernote-ext-specialchars'
import * as summernoteLayout from '../../../../../../libs/summernote/plugin/summernote-Layout'
import * as summernotetableofcontent from '../../../../../../libs/summernote/plugin/summernote-table-of-content'
import * as summernoteimportImage from '../../../../../../libs/summernote/plugin/summernote-import-image'
import * as summernoteCleaner from '../../../../../../libs/summernote/plugin/summernote-cleaner'
import * as summernoteTableFloat from '../../../../../../libs/summernote/plugin/summernote-table-float'
import * as summernoteTableHeader from '../../../../../../libs/summernote/plugin/summernote-table-headers'
import * as summernoteTableColor from '../../../../../../libs/summernote/plugin/summernote-table-color'
import DynamicClass from '../../../../../../app/DynamicClass'
import domtoimage from '../../../../../../libs/dom-to-image/dom-to-image' 
import {CanvasDesignerViewModel} from '../../../canvas/views/designer/CanvasDesignerViewModel'

/*define(["require", "jquery", "enhsplitter", "underscore", "backbone", "knockout", "async", "appcommon/com/vbee/data/DataManager", "app/global", "knockback", "summernote", "appbo/canvas/BMCanvasDiagram", "appbo/canvas/BMCanvasDiagramMixin", "appbo/vdml/ValueDeliveryModel", "appviews/report/views/properties/ReportDetailsViewModel", "appviews/help/views/properties/BMGuidanceViewModel", "appbo/dashboard/DashboardMixin", "appbo/dashboard/Analytics", "appbo/beeppackage/Container", "appbo/vdml/EcoMapDiagramMixin", "summernoteeditmode", "summernoteimportText", "summernoteimportModels", "summernoteobjectexplorer", "summernoteprint", "summernotefindnreplac", "summernoteBackgroundColor", "summernotePageBreak", "summernoteremovereport", "summernoteimportImage", "summernotetableofcontent", "summernotecaseconverter", "summernotedetailsView", "summernoteCleaner", "summernoteTableHeader", "summernoteTableStyles", "summernoteImageShapes", "summernoteSpecialChars", "summernoteMergeCell", "summernoteMergeRow", "colResizable", "summernoteTableColor", "summernoteTableFloat", "domtoimage", "summernoteLayout"],
    function (require, $, enhsplitter, _, Backbone, ko, async, DataManager, global, kb, summernote, BMCanvasDiagram, BMCanvasDiagramMixin, ValueDeliveryModel, ReportDetailsViewModel, BMGuidanceViewModel, DashboardMixin, Analytics, CodeContainer, EcoMapDiagramMixin) {*/
        
var path = DataManager.getDataManager().buildAppNsPath("report.views.summernote", global.version);
export class SummernoteReportViewModel {
    constructor(model,options){
        this.init(model,options);
    }
    /*isEditMode(val) {
        var self = this;
        // var kbd = jQuery('kbd');
        //jQuery('kbd').css("color", "black");
        if (jQuery(".note-editable").attr("contenteditable") == "false") {
            jQuery("#isEditMode").html("").text("Edit Mode");
            jQuery(".note-editable").attr("contenteditable", true);
            jQuery(".note-toolbar").show();
            //   jQuery("kbd").css("background-color", "#rgb(255, 112, 77)");
            //     jQuery("kbd").css("box-shadow", "rgba(0, 0, 0, 0.25) 0px -1px 0px inset");
            jQuery("kbd").removeClass("SummernoteToggleEdit");
            jQuery("kbd").addClass("SummernoteToggle");
            //   jQuery("kbd").addClass("SummernoteToggleEdit");
        } else {
            jQuery("#isEditMode").html("").text("Read Mode");
            jQuery(".note-editable").attr("contenteditable", false);
            //jQuery(".note-toolbar").hide();
            jQuery(".glyphicon-print").show();

            //  jQuery("kbd").css("background-color", "rgb(255, 112, 77,0)");
            //  jQuery("kbd").css("box-shadow", "rgba(0, 0, 0, 0.25) 0px 0px 0px inset");

            jQuery("kbd").removeClass("SummernoteToggle");
            jQuery("kbd").addClass("SummernoteToggleEdit");
            jQuery("kbd").css("background-color", "inherit");
        }
    }*/
    
    
    changeHeader(event1) {
        var tagName = event1.tagName;
        if ($('.header-value').length == 0) {
            $('.note-icon-magic').after("<span class='header-value'><span>");
            $('.note-icon-magic').remove();
        }
        else {
            $(".header-value").html("");
        }
        if(tagName == "DL"){  
            $(".header-value").append("Title");
        } else if(tagName == "PRE"){
            $(".header-value").append("Code");
        } else if(tagName == "BLOCKQUOTE"){
            $(".header-value").append("Quote");
        } else {
            for (var i = 1; i < 8; i++) {
                if (event1.closest("H" + i)) {
                    $(".header-value").append("Header" + i);
                    break;
                }
                if (i == 7) {
                    $(".header-value").append("Normal");
                    break;
                }
            }
        }
    }
    setResizeHeight(view, event, customHeight) {
        var self = this;
        setTimeout(function () {
            if (self.spliter) {
                var propertiesNode = 0;
                var rightTab = jQuery('#rightTab');
                if (rightTab.width() > 0) {
                    propertiesNode = jQuery('.propertiesArea').outerHeight();
                }
                var lhsHeight = jQuery('.note-editable').outerHeight() + jQuery('.navbar').outerHeight();
                //var windowHeight = window.innerHeight;
                var reportHeight = _.max([propertiesNode, lhsHeight]) - jQuery('.navbar').outerHeight()/3;
                self.spliter.css('height', reportHeight);
                //if(jQuery('#rightTab').width() > 5){
                jQuery("#splitPanels").enhsplitter('refresh');
                //}

                if (rightTab.width() > 0) {
                    if (jQuery('#rightTab .active a')[0]) {
                        var propDivId = jQuery('#rightTab .active a')[0].hash;
                        if (propDivId !== null && jQuery('#rightTab .dropdown .active a')[0]) {
                            propDivId = jQuery('#rightTab .dropdown .active a')[0].hash;
                        }
                        var propNode = jQuery('' + propDivId);
                    }
                }
                if (rightTab.width() > 10 && rightTab.children().last()[0].classList.contains('dropdown') /*&& rightTab.height() < 50*/) {
                    var dropdownDiv = rightTab.children().last();
                    var appendList = dropdownDiv.find('li');
                    var removedList = [];
                    for (var i = 0; i < appendList.length; i++) {
                        dropdownDiv.before(appendList[i]);
                        removedList.push(appendList[i]);
                    }
                    if (appendList.length === removedList.length) {
                        dropdownDiv.remove();
                    }
                }
                if (rightTab.width() > 10 && rightTab.width() < 300 && rightTab.children().length > 2/* && rightTab.height() > 50*/) {
                    var rightChildren = rightTab.children();
                    var appendList = [];
                    for (var i = rightChildren.length; i > 0; i--) {
                        if (!rightChildren[i - 1].classList.contains('dropdown')) {
                            appendList.push(rightChildren[i - 1]);
                            if (rightTab.width() < 250 && rightChildren[i - 2]) {
                                appendList.push(rightChildren[i - 2]);
                                break;
                            } else {
                                break;
                            }
                        }
                    }
                    if (!rightChildren.last()[0].classList.contains('dropdown')) {
                        rightTab.append('<li class="dropdown"><a class="dropdown-toggle" data-toggle="dropdown" href="#"><span class="caret"></span></a><ul style="right:0" class="dropdown-menu pull-right"></ul></li>');
                    }
                    for (var i = 0; i < appendList.length; i++) {
                        rightTab.children().last().find('ul').prepend(appendList[i]);
                    }
                }
                if (rightTab.children().last()[0] && rightTab.children().last()[0].classList.contains('dropdown') && propNode && propNode.parent()[0] && propNode.parent()[0].classList.contains('tab-content')) {
                    var dropdownDiv = rightTab.children().last();
                    var appendList = dropdownDiv.find('li');
                    for (var i = 0; i < appendList.length; i++) {
                        appendList.eq(i).removeClass('active');
                    }
                }
            }
        }, 150);
    };
    changeReferralModelId(canvasImg, diagramId, parentId, altId, diagramType, id) {
        if (diagramType == "dashboard_Presentation" || diagramType == "dashboard.Presentation") {
            var chartList = jQuery('[id^="chartDuplicate_"]').find('img');
            if (chartList.length > 1) {
                for (var i = 0; i < chartList.length; i++) {
                    if (chartList[i].getAttribute('diagramid') === canvasImg.getAttribute('diagramid') && chartList[i].getAttribute('alternativeId') === altId) {
                        var alt = chartList[i].parentElement.id.substr(chartList[i].parentElement.id.length - 8, chartList[i].parentElement.id.length)
                        if (alt == altId) {
                            chartList[i].parentElement.id = 'chartDuplicate_' + diagramId + altId
                        }
                        else {
                            chartList[i].parentElement.id = 'chartDuplicate_' + diagramId + id;
                        }
                        break;
                    }
                }
            } else if (chartList.length == 1) {
                var alt = chartList[0].parentElement.id.substr(chartList[0].parentElement.id.length - 8, chartList[0].parentElement.id.length)
                if (alt == altId) {
                    chartList[0].parentElement.id = 'chartDuplicate_' + diagramId + altId
                }
                else {
                    chartList[0].parentElement.id = 'chartDuplicate_' + diagramId + id;
                }
            } else {
                var chartList = jQuery('[id^="chartDuplicate_"]');
                if (chartList.length > 0) {
                    var alt = chartList.attr('id').substr(chartList.attr('id').length - 8, chartList.attr('id').length)
                    if (alt == altId) {
                        jQuery('[id^="chartDuplicate_"]').attr('id', 'chartDuplicate_' + diagramId + altId)
                    }
                    else {
                        jQuery('[id^="chartDuplicate_"]').attr('id', 'chartDuplicate_' + diagramId + id)
                    }
                }
            }
        }
        var imgs = jQuery("img[diagramid = \"" + canvasImg.getAttribute('diagramid') + "\"]");
        if (imgs.length > 1) {
            for (var i = 0; i < imgs.length; i++) {
                if (imgs[i].getAttribute('alternativeId') === altId) {
                    imgs[i].setAttribute('diagramid', diagramId);
                    imgs[i].setAttribute('parentid', parentId);
                }
            }
        } else {
            jQuery("img[diagramid = \"" + canvasImg.getAttribute('diagramid') + "\"]").attr('diagramid', diagramId);
            jQuery("img[diagramid = \"" + canvasImg.getAttribute('diagramid') + "\"]").attr('parentid', parentId);
        }
    }
    afterRenderView(node, view) {
        var self = view;
        function getViewportSize(w) {

            // Use the specified window or the current window if no argument
            w = w || window;
    
            // This works for all browsers except IE8 and before
            if (w.innerWidth != null) return { w: w.innerWidth, h: w.innerHeight };
    
            // For IE (or any browser) in Standards mode
            var d = w.document;
            if (document.compatMode == "CSS1Compat")
                return {
                    w: d.documentElement.clientWidth,
                    h: d.documentElement.clientHeight
                };
    
            // For browsers in Quirks mode
            return { w: d.body.clientWidth, h: d.body.clientHeight };
    
        }
        //jQuery('.note-link-popover').remove();
        self.spliter = jQuery("#splitPanels").enhsplitter({ minSize: 0, onDrag: window.utils.splitterBoundary, collapse: 'right', handle: 'block', splitterSize: '9px', position: '65%', height: getViewportSize().h - jQuery('.navbar').height(), onDragEnd: self.setResizeHeight });
        self.spliter.enhsplitter('collapse');
        //var availableOptions = ['objectexplorer','print','removereport'];
        var availableOptions = ['importImage','objectexplorer'];
        //if(DataManager.getDataManager().isCanvasPurchased()){
        //availableOptions.push('detailsView');
        //availableOptions.push('editmode');

        availableOptions.push('importCanvas');
        availableOptions.push('importText');
        availableOptions.push('backgroundColor');
        //availableOptions.push('watermark');
        availableOptions.push('pagebreak');
        //availableOptions.push('print');
        //availableOptions.push('editmode');
        //  availableOptions.push('paperSize');
        if(jQuery('#summernote').summernote){
            jQuery('#summernote').summernote('destroy');
        }
        jQuery('#summernote').summernote(
            {
                height: getViewportSize().h - jQuery('.navbar').outerHeight() - 15,                 // set editor height
                minHeight: null,             // set minimum height of editor
                maxHeight: null,             // set maximum height of editor
                focus: true,
                viewModel: self,
                toolbar: [
                    ['style', ['bold', 'italic', 'underline']],
                    ['font', ['fontname', 'fontsize', 'color', 'strikethrough', 'superscript', 'subscript', 'title']],
                    ['para', ['style', 'ul', 'ol', 'paragraph']],
                    ['height', ['height']],
                    //['custom', ['findnreplace']],
                    ['insert', ['link', 'table', 'hr', 'specialchars', 'tableofcontent']],

                    //['view', ['codeview']],
                    ['cleaner',['cleaner']], // The Button
                    ['custom', ['caseConverter', 'Layout']],
                    ['Canvas', availableOptions],
                    ['read', ['findnreplace','print', 'editmode']]
                ],
                cleaner: {
                    action: 'button', // both|button|paste 'button' only cleans via toolbar button, 'paste' only clean when pasting content, both does both options.
                    newline: '<br>', // Summernote's default is to use '<p><br></p>'
                    notStyle: 'position:absolute;top:0;left:0;right:0', // Position of Notification
                    icon: '<i class="note-icon">Clear Formatting</i>',
                    keepHtml: false, // Remove all Html formats
                    keepOnlyTags: ['<p>', '<br>', '<ul>', '<li>', '<b>', '<strong>', '<i>', '<a>'], // If keepHtml is true, remove all tags except these
                    keepClasses: false, // Remove Classes
                    badTags: ['style', 'script', 'applet', 'embed', 'noframes', 'noscript', 'html'], // Remove full tags with contents
                    badAttributes: ['style', 'start'], // Remove attributes from remaining tags
                    limitChars: false, // 0/false|# 0/false disables option
                    limitDisplay: 'both', // text|html|both
                    limitStop: false // true/false
                },
                popover: {
                    table: [
                        ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
                        ['delete', ['deleteRow', 'deleteCol', 'deleteTable']],
                        ['custom', ['tableFloat', 'tableStyles', 'tableHeaders', 'mergeCell', 'mergeRow', 'tableColor']]
                    ],
                    image: [
                        ['custom', ['imageShapes']],
                        ['imagesize', ['imageSize100', 'imageSize50', 'imageSize25']],
                        ['float', ['floatLeft', 'floatRight', 'floatNone']],
                        ['remove', ['removeMedia']]
                    ],
                    link: [
                        ['link', ['linkDialogShow', 'unlink']]
                    ],
                },
                lang: 'en-US', // Change to your chosen language
                spellCheck: true,
                disableGrammar: false,
                callbacks: {}
            });
        jQuery("#summernote").viewModel = self;
        jQuery("#summernote").summernote('code', self.model.get('content'));
        
        jQuery("#summernote").summernote("editor.clearHistory", 'editor');
        jQuery("#summernote").summernote({
            onMediaDelete: function ($target, editor, $editable) {
                console.log($target[0].src); // img 

                // remove element in editor 
                $target.remove();
            }
        });
        jQuery('.note-editable').css('background-color', self.model.get('bgcolor'));
		jQuery("#selectedBackColor").attr('value',self.model.get('bgcolor'));
        var htmlContent = "";
        htmlContent += '<div class = report-watermark ></div><br><br><div id="reportHeader"></div><div id="reportFooter"></div>';
        jQuery('.note-codable').next().after(htmlContent);
        if (self.model.get('wmJsonObj')) {
            var jsonObj = JSON.parse(self.model.get('wmJsonObj'));
            if (jsonObj.imgData != null) {
                jQuery('.report-watermark').text(jsonObj.ucName).append((jsonObj.imgData).split('\\"').join('"')); // ucName- user choice name
            } else {
                jQuery('.report-watermark').text(jsonObj.ucName);
            }
            jQuery('.report-watermark').css('color', jsonObj.wmColor);
            jQuery('.report-watermark').css('transform', jsonObj.transform);
            jQuery('.report-watermark').css('opacity', jsonObj.opacity);
        }
        jQuery('#reportHeader').text("").append(self.model.get('header'));
        jQuery('#reportFooter').text("").append(self.model.get('footer'));
        jQuery('#summernote').on('summernote.keyup', function (we, e) {
            if (e.keyCode == 37 || e.keyCode == 38 || e.keyCode == 39 || e.keyCode == 40) {
                self.changeHeader(document.getSelection().focusNode.parentElement);
            }
            if (e.keyCode == 70 && e.ctrlKey) {
                jQuery('[title="Find & Replace"]').click();
            }
            if (e.keyCode === 13 && jQuery('.custText').length > 0) {
                var list = jQuery('.custText').length;
                callbacks: {
                    setTimeout(function () {
                        var len = jQuery('.custText').length - 1;
                        for (var i = len; i >= 0; i--) {
                            if (jQuery('.custText')[i].innerHTML === '<br>' || jQuery('.custText')[i].innerText === "") {
                                jQuery('.custText')[i].parentElement.innerHTML = jQuery('.custText')[i].parentElement.innerText + "<br>"
                            }
                        }
                    }, 200);
                }
            }
        });
        jQuery('#summernote').on('summernote.scroll', function (we, event) {
            if (jQuery('#pop').find('.popuptext').length != 0) {
                if (jQuery('#pop').find('.popuptext').is(':visible')) {
                    jQuery('.popuptext').hide();
                    jQuery('#pop').remove();
                }
            }
        });
        jQuery('#summernote').on('summernote.mousedown', function (we, event) {
            if (event.target && (event.target.tagName == "KBD" || event.target.tagName === "I")) {
                jQuery(".note-editable").append('<div id="pop"></div>');
                var namePath = "";
                var id = event.target.getAttribute('originalId');
                var custId = event.target.getAttribute('id');
                var model = event.target.getAttribute('type') ? Backbone.Relational.store.getObjectByName(event.target.getAttribute('type')).find({ id: id }) : "";
                if (id && model && !event.target.className.includes("custText")) {
                    var currentAlt = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: window.utils.getPrefix(id) });
                    var scenario = currentAlt ? currentAlt.getMainScenario(model.getNestedParent()) : "";
                    var id = window.utils.htmlEscape(window.guidGenerator());
                    var options = {};
                    options.context = scenario;
                    options.mappedModal = model;
                    options.disableValues = true;
                    options.width = '800px';
                    window.getAndCreateModalDialog(model, id, EcoMapDiagramMixin, model, "TargetDetails", function (refresh) {
                    }, options);
                } else if (id && model && event.target.className.includes('custText')) {
                    if (jQuery('#pop').find('.popuptext').length != 0) {
                        if (jQuery('#pop').find('.popuptext').is(':visible')) {
                            jQuery('.popuptext').hide();
                            jQuery('#pop').remove();
                        }
                    } else if (id != null) {
                        var loc = jQuery(event.target).position();
                        var htmlContent = '<i style="width: 0; height: 0; border-top: 10px solid transparent; border-bottom: 10px solid transparent; border-right:10px inset grey; position: absolute; left: ' + (parseInt(loc.left) + parseInt(event.target.clientWidth)).toString() + 'px; top: ' + (parseInt(loc.top)).toString() + 'px;"></i><span style="border-top-right-radius: 50px; border-bottom-right-radius: 50px; background-color: grey; position: absolute; left: ' + (parseInt(loc.left) + parseInt(event.target.clientWidth) + 9).toString() + 'px; top: ' + (parseInt(loc.top)).toString() + 'px;" class = "popuptext" id = "text' + window.utils.htmlEscape(model.id) + '"></span>&nbsp';
                        var isExist = jQuery('#pop').find('.popuptext');
                        if (model.get('type') == "vdml_ValueDeliveryModel") {
                            var altId = window.utils.getPrefix(model.id);
                            namePath = model.getNamePath(altId);
                        } else {
                            var pathList = model.getPackagePath();
                            for (var i = 0; i < pathList.length; i++) {
                                namePath += pathList[i].name;
                                if (i != pathList.length - 1) {
                                    namePath += " / "
                                }
                            }
                        }
                        if (!namePath || model.get('type') == "transformation_Plan" || model.get('type') == "canvas_BMCanvasDiagram") {
                            var name = event.target.getAttribute('tag') ? event.target.getAttribute('tag') : "name";
                            namePath = model.get(name);
                        }
                        if (isExist.length == 0) {
                            jQuery('#pop').append(htmlContent);
                            jQuery('#pop').find('.popuptext').html('&nbsp&nbsp&nbsp' + namePath);
                            jQuery('#pop').find('.popuptext').append('&nbsp<i id = "info' + custId + '" class="glyphicon glyphicon-info-sign glyphicon-button" style="font-size: small;color:orange" originalId = ' + id + ' type = ' + event.target.getAttribute('type') + '></i>&nbsp &nbsp&nbsp');
                            jQuery('#pop').hide();
                            jQuery('#pop').show();
                        }
                    }
                }
            } else {
                if (event.target != null) {

                    jQuery('#pop').hide();
                    jQuery('#pop').remove();
                    self.changeHeader(event.target);


                }
            }
        })

        /*jQuery('.note-statusbar').on('mouseup', function (event) {debugger
                this.timer = clearTimeout();
            this.timer = setTimeout(function(){self && self.setResizeHeight && self.setResizeHeight(null,null,event.pageY)}, 400);
        });*/
        /*jQuery('.note-statusbar').draggable({
                stop: function( event, ui ) {
                    self.setResizeHeight(); 
                }
        });*/
        //window.resizeBy(-1000,-1000);
        //window.resizeBy(1000,1000);
        /*var parent = jQuery('#content').parent();
        var content = jQuery('#content').detach();
        parent.append(content);
        jQuery('.page-header').hide();*/


        jQuery(window).resize(function (view, event) {
            if (!event) {
                this.timer = clearTimeout();
                this.timer = setTimeout(function () { self && self.setResizeHeight && self.setResizeHeight() }, 300);
            }
        });
        jQuery('#rightTab a').click(function (e) {
            e.preventDefault();
            if (jQuery(this).text() === "Collaboration") {
                return false;
            } else if (jQuery(this).text() === "Guidance") {
                jQuery(".tab-content").children().removeClass('in active');
                jQuery("#guidance").addClass('in active');
                if (jQuery('#guidanceTab').is(':empty')) {
                    jQuery.get('js/app/version1/views/help/views/properties/ReportGuidanceTemplate.html',function(viewStr){
                        jQuery('#guidanceTab').empty();
                        jQuery('#guidanceTab').append(viewStr);
                        window.utils.applyFontSize(jQuery('#guidanceTab'));
                    })
                }
            } else {
                jQuery(".tab-content").children().removeClass('in active');
                jQuery("#reportDetails").addClass('in active');
            }
            jQuery(this).tab('show');
            //self.setResizeHeight();   
        });
        self.updateLinkedText();
        self.updateCanvasPictures(function () {
            var dupTableIdList = [];
            _.each(jQuery(".table"), function (table) {
                if(dupTableIdList.includes(table.id)) {//to fix copypaste of tables
                    table.id = table.id + Math.floor((Math.random() * 100));
                }
                if (table.style.float == '') {
                    jQuery(table).colResizable({ resizeMode: 'overflow', liveDrag: true });
                }
                dupTableIdList.push(table.id);
            });
            jQuery(".dataTable").colResizable({ resizeMode: 'overflow', liveDrag: true });
            if (self.model.get('content') != null) {
                jQuery("#isEditMode").click();
            }else {
                jQuery("#isEditMode").parent().parent().hide();
            }
            jQuery(".note-frame").css({ 'height': '97%', 'margin-left': '3px', 'margin-top': '3px' });
            self.setResizeHeight();
            window.utils.stopSpinner('reportSpinner');
            window.utils.applyFontSize(jQuery('#content'));
        });
    };
    openDiagram(xml, container, ecoMapInstance, id, callback) {
        var self = this;
        async function myDisplay(modeler) {
            let result = new Promise(function(myResolve, myReject) {
              myResolve("Works");
            });
            try {
                result = await modeler.importXML(xml);
                var { warnings } = result;
                console.log(warnings);
                const { svg } = await modeler.saveSVG();
                if (svg) {
                    modeler.destroy();
                    var blobSVG = new Blob([svg], { type: "image/svg+xml;charset=utf-8" });
                    var domURL = URL || webkitURL || self;
                    var dataUrl = domURL.createObjectURL(blobSVG);
                    var oldImages = jQuery("img[diagramid = \"" + ecoMapInstance.id + "\"]");
                    var oldImgQuery;
                    var oldImg;
                    _.each(oldImages, function (oldImage) {
                        oldImgQuery = jQuery(oldImage);
                        oldImg = oldImage;
                        if (oldImgQuery.attr('customId') === id) {
                            oldImgQuery.attr('src', dataUrl);
                            oldImage.style['display'] = 'block';
                            (oldImage.style['width'] == '')? oldImage.style['width']='50%' :  oldImage.style['width'];
                            return false;
                        }
                    });
                    setTimeout(function () {
                        var canvas = document.createElement("canvas");
                        canvas.setAttribute("id", "deletecanvas");
                        if (oldImgQuery != null && oldImgQuery.attr('customId') === id) {
                            canvas.width = oldImg.width*2;
                            canvas.height = oldImg.height*2;
                            canvas.getContext('2d').drawImage(oldImg, 0, 0, canvas.width,canvas.height);
                            var dt = canvas.toDataURL('image/png');
                            oldImgQuery.attr('src', dt);
                            jQuery("#deletecanvas").remove();
                        }
                        callback();
                    }, 500);
                }
            } catch (err) {
                console.log(err.message, err.warnings);
            }
        }
        var fontSize = '12';
        if(ecoMapInstance.get('fontsize')){
            fontSize = ecoMapInstance.get('fontsize');
        }
        var modeler = new EcoNavigatedViewer({
            container: container,
            type: ecoMapInstance.get('type'),
            textRenderer: {
                defaultStyle: {
                    fontSize: fontSize,
                },
                externalStyle: {
                    fontSize: fontSize,
                }
            }
        });
        myDisplay(modeler);
        /*modeler.importXML(xml, function (err) {
            if (err) {
                console.error(err);
            }
            setTimeout(function () {
                modeler.saveSVG({}, function (err, svg) {
                    
                });

            }, 800);
        });*/

    };
    
    checkLinkText(list,model,alt) {
        if (list.getAttribute('Tag')) {
            var scenario;
            var isTimestamp = true;
            if(list.getAttribute('Tag') == "value"){
                var whenobserved = list.getAttribute('whenobserved');
                var objective = false;
                if(whenobserved == "undefined" || !whenobserved){
                    whenobserved = null;
                    isTimestamp = false;
                }
                if(whenobserved && whenobserved.indexOf("Common") != -1){
                    objective = true;
                }
                if(objective){
                    //var CodeContainer = Backbone.Relational.store.getObjectByName('beeppackage.CodeContainer');
                    var codeContainer = window.utils.getElementModel(whenobserved,['beeppackage.CodeContainer']);
                    //CodeContainer.getBlobInstanceWithId(whenobserved, DataManager.getDataManager().get('currentPlan'), function (codeContainer) {
                        var roundedValue = "-";
                        if (codeContainer && codeContainer.get('code')) {
                            var json = JSON.parse(codeContainer.get('code'));
                            var altPhaseId = alt.getParent().get("id");
                            for (var phaseId in json) {
                                if (json[phaseId].phaseMapId === altPhaseId) {
                                    var isExist = false;
                                    var valsuffix = window.utils.getSuffix(model.id);
                                    for (var key in json[phaseId]['fileContent']) {
                                        if (json[phaseId]['fileContent'][key] && json[phaseId]['fileContent'][key].valueTargetId === valsuffix) {
                                            isExist = true;
                                            roundedValue = json[phaseId]['fileContent'][key].Value ? parseFloat(json[phaseId]['fileContent'][key].Value) : "-";
                                            break;
                                        }
                                    }
                                }
                                if(isExist){
                                    break;
                                }
                            }
                        }
                        list.innerHTML = roundedValue;
                    //})
                }else {
                    scenario = alt ? alt.getMainScenario(model.getNestedParent()) : "";
                }
            }
            function getValueSimley(selectedInterval){
                var smileyValue = selectedInterval.get('smiley') ? selectedInterval.get('smiley') : '';
                var smileyColor = selectedInterval.get('smileyColor') ? selectedInterval.get('smileyColor') : '#000000';
                var svgSmiliesList = window.utils.getSmiliesList();;
                var satisfactionSmiley = '';
                for (var j = 0; j < svgSmiliesList.length; j++) {
                    if (svgSmiliesList[j].value === smileyValue) {
                        satisfactionSmiley = svgSmiliesList[j].src;
                        break;
                    }
                }
                var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                svg.setAttribute("width", 21);
                svg.setAttribute("height", 20);
                svg.setAttribute("style",'vertical-align:bottom;');
                svg.setAttribute("viewBox", "-3 -2 26 26");
                svg.setAttribute("fill", ""+smileyColor);
                var link = document.createElementNS("http://www.w3.org/2000/svg", "path");
                link.setAttribute("d", satisfactionSmiley);
                svg.appendChild(link);
                return svg;
            }
            if(isTimestamp && whenobserved != null && !isNaN(whenobserved)){
                whenobserved = parseInt(whenobserved);
            }
            var obs = scenario ? scenario.getObservedMeasureWithMeasurements(model.get('valueMeasurement'), isTimestamp, whenobserved) : "";
            if (!objective && obs  && (list.getAttribute('Tag') === 'value' || list.getAttribute('Tag') === 'unit')) {
                var value = obs.get('measurements').at(0).get('value');
                var valUnit = obs.get('measurements').at(0).get('observedMeasure').get('measure').get('unit')
                var roundedValue = window.utils.getRoundedValues(value, valUnit.get('significantDecimals'), true);
                if (list.getAttribute('Tag') === 'value') {
                    list.innerHTML = roundedValue;
                    var selectedInterval = model.getSatisfactionInterval(scenario);
                    if(selectedInterval){
                        var svg = getValueSimley(selectedInterval);
                        list.appendChild(svg);
                    }
                    
                } else if (list.getAttribute('Tag') === 'unit' && list.innerHTML != valUnit) {
                    list.innerHTML = valUnit.get('name');;
                }
            } else if(list.getAttribute('Tag') !== 'value'){
                list.innerHTML = model.get(list.getAttribute('Tag')) ? model.get(list.getAttribute('Tag'))+ '&nbsp' : model.get('name') + '&nbsp';
            } else if(list.getAttribute('Tag') == 'value' && isTimestamp && whenobserved && alt && model){//timestamp scenario
                debugger
                var altObs;
                if (model.id.indexOf(window.plansKey) >= 0) {
                    altObs = _.find(alt.getOrCreatePhaseObjectiveSet().get('objectiveObservation').models, function (a) { if (a.get('whenObserved')) { return a.get('whenObserved') === whenobserved; } });
                } else {
                    var scenarioObj = Backbone.Relational.store.getObjectByName('vdml.Scenario').find({ id: alt.get('designScenario').models[0].get('scenario') });
                    var altBmobsModels = scenarioObj.get('contextObservation').models;
                    altObs = _.find(altBmobsModels, function (a) { if (a.get('whenObserved')) { return a.get('whenObserved') === whenobserved; } });
                }
                var dataRef = altObs ? altObs.get('dataReference') : null;
                var codeContainer = window.utils.getElementModel(dataRef,['beeppackage.CodeContainer']);
                var dataStr = codeContainer ? codeContainer.get('code') : null;
                var vm = model ? model.get('valueMeasurement') : null;
                if (dataStr && vm) {
                    var data = JSON.parse(dataStr);
                    var importTimeStampValue = data[vm.id];
                    list.innerHTML = importTimeStampValue;
                    var selectedInterval = model.getSatisfactionInterval(scenario, whenobserved, importTimeStampValue) 
                    if(selectedInterval){
                        var svg = getValueSimley(selectedInterval);
                        list.appendChild(svg);
                    }
                }
            }
        }
    }
    updateLinkedText() {
        var self = this;
        var list = jQuery('.custText');
        var tempArray =[];
        async.eachSeries(list,function(list,callback){
            var modelId = list.getAttribute('originalId'); var model;
            var parentId = list.getAttribute('parentId');
            var altId = list.getAttribute('altId');
            if(!altId){
                altId = modelId
            }
            var diagramType = list.getAttribute('type')
            var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: altId })
            if(alt && altId != window.utils.getPrefix(list.getAttribute('parentId')) && diagramType != "vdml.ValuePropositionComponent" && diagramType != "vdml.ValueAdd") { //For revision
                var phaseDesignPartModels = alt.get('phaseDesignPart');
                var exists = self.getPhaseDesignModels(phaseDesignPartModels, parentId)
                if (!exists) {
                    var parentModelId = parentId.substr(parentId.lastIndexOf('@') + 1);
                    var originalParentId = altId + parentModelId;
                    var exist = self.getPhaseDesignModels(phaseDesignPartModels, originalParentId)
                    if (exist) {
                        var modelId = modelId.substr(modelId.lastIndexOf('@') + 1);
                        modelId = altId + modelId;
                        parentId = originalParentId;
                    }
                }
                var type = list.getAttribute('type').replace('_', '.');
                model = Backbone.Relational.store.getObjectByName(type).find({ id: modelId });
                list.setAttribute('originalId', modelId)
                list.setAttribute('parentId', parentId)
                callback();
            }else{
                model = Backbone.Relational.store.getObjectByName(list.getAttribute('type')).find({ id: modelId });
                if(!model){
                    var dataManager =  DataManager.getDataManager();
                    var repId = dataManager.getRepositoryId(parentId);
                    var vdmStore = dataManager.getVDMStore(repId);
                    if(diagramType === "vdml.StrategyMapDiagram" || diagramType === "vdml.CapabilityLibraryDiagram" || diagramType === "vdml.EcoMapDiagram" || diagramType === "vdm.ValueStreamMapDiagram"){
                        //var parentPath = (diagramType === "vdml.CapabilityLibraryDiagram") ? "appbo/vdml/ValueDeliveryModel" :"appbo/ecomap/EcoMap";
                        var model = window.utils.getElementModel(modelId,[diagramType]);
                        if(model) {
                            if(model && !alt && list.getAttribute('type') !== "transformation.Phase"){
                                tempArray.push({id :list.getAttribute('id'),originalId : list.getAttribute('originalId')})
                            }
                            else if (!model) {
                                tempArray.push({id :list.getAttribute('id'),originalId : list.getAttribute('originalId')})
                            }else {
                                self.checkLinkText(list,model,alt);
                            }
                            callback()
                        } else {
                            console.log('could not load contained map:' + modelId);
                            tempArray.push({id :list.getAttribute('id'),originalId : list.getAttribute('originalId')})
                            callback();
                        }
                    }else if(diagramType === "dashboard.Presentation"){
                        var model = window.utils.getElementModel(modelId,[diagramType]);
                        if(model) {
                            if(model && !alt && list.getAttribute('type') !== "transformation.Phase"){
                            tempArray.push({id :list.getAttribute('id'),originalId : list.getAttribute('originalId')})
                            }
                            else if (!model) {
                                tempArray.push({id :list.getAttribute('id'),originalId : list.getAttribute('originalId')})
                            }else{
                                self.checkLinkText(list,model,alt);
                            }
                            callback()
                        } else {
                            console.log('could not load contained dashboard:' + modelId);
                            tempArray.push({id :list.getAttribute('id'),originalId : list.getAttribute('originalId')})
                            callback();
                        }
                    }else{
                        var model = window.utils.getElementModel(modelId,['canvas.BMCanvasDiagram']);
			            if (model) {
                            if(model && !alt && list.getAttribute('type') !== "transformation.Phase"){
                                tempArray.push({id :list.getAttribute('id'),originalId : list.getAttribute('originalId')})
                            }
                            else if (!model) {
                                tempArray.push({id :list.getAttribute('id'),originalId : list.getAttribute('originalId')})
                            }else{
                                self.checkLinkText(list,model,alt);
                            }
                            callback()
                        } else {
                            console.log('could not load contained canvas:' + modelId);
                            tempArray.push({id :list.getAttribute('id'),originalId : list.getAttribute('originalId')})
                            callback();
                        }
                    }
                }else{
                    self.checkLinkText(list,model,alt);
                    callback()
                }
            } 
        },function(err){
            for(var i = list.length-1;i>=0;i--){
                for(var j=0;j<tempArray.length;j++){
                    if(tempArray[j].id === list[i].getAttribute('id') && tempArray[j].originalId === list[i].getAttribute('originalId')){
                        list[i].remove();
                    }
                }
            }
        })
    }
    updateCanvasPictures(callback) {
        var self = this;
        var canvasImgs = jQuery('.note-editable img');
        async.eachSeries(canvasImgs, function (canvasImg, updatedImage) {
            var diagramId = canvasImg.getAttribute('diagramid');
            var diagramType = canvasImg.getAttribute('type');
            var id = canvasImg.getAttribute('id'); var isReferralModel = false;
            if (diagramType == 'beeppackage.CodeContainer') {
                var codeContainer = window.utils.getElementModel(diagramId,['beeppackage.CodeContainer']);
                //CodeContainer.getBlobInstanceWithId(diagramId, DataManager.getDataManager().get('currentPlan'), function (codeContainer) {
                    if (codeContainer) {
                        jQuery('#' + id).show();
                        jQuery('#' + id).attr("src", "" + codeContainer.get('code'));
                        jQuery('#' + id).attr("type", diagramType);
                    } else {
                        jQuery('#' + id).remove();
                    }
                //});
                updatedImage();
                return;
            }
            if (!diagramId) {
                updatedImage();
                return;
            }

            var parentId = canvasImg.getAttribute('parentid');
            var repId = DataManager.getDataManager().getRepositoryId(parentId);
            var vdmStore = DataManager.getDataManager().getVDMStore(repId);
            //var lastUpdate = canvasImg.getAttribute('lastUpdateTime');
            var altId = canvasImg.getAttribute('alternativeId');
            if (!altId) {
                altId = canvasImg.getAttribute('altid');
            }
            var altModel = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: altId });
            if (!altModel && diagramType != "vdml_CapabilityLibraryDiagram") {
                updatedImage();
                return;
            }
            if (altId != window.utils.getPrefix(diagramId) && diagramType != "vdml_CapabilityLibraryDiagram") {
                var phaseDesignPartModels = altModel.get('phaseDesignPart');
                var exists = self.getPhaseDesignModels(phaseDesignPartModels, parentId)
                if (!exists) {
                    var parentModelId = parentId.substr(parentId.lastIndexOf('@') + 1);
                    var originalParentId = altId + parentModelId;
                    var exist = self.getPhaseDesignModels(phaseDesignPartModels, originalParentId)
                    if (exist) {
                        var modelId = diagramId.substr(diagramId.lastIndexOf('@') + 1);
                        diagramId = altId + modelId;
                        isReferralModel = true;
                        parentId = originalParentId;
                    } else {
                        updatedImage();
                        return;
                    }
                }
            }
            var dataManager = DataManager.getDataManager();
            var repId = DataManager.getDataManager().getRepositoryId(parentId);
            var vdmStore = DataManager.getDataManager().getVDMStore(repId);
            if (!vdmStore) {
                canvasImg.parentElement.remove();
                updatedImage();
                return;
            }
            if (diagramType && (diagramType != "dashboard_Presentation" && diagramType != "dashboard.Presentation" && diagramType != "canvas_BMCanvasDiagram")) {
                var type = "appbo/vdml/EcoMapDiagram";
                var parentPath = "appbo/ecomap/EcoMap";
                if (diagramType == "vdml_StrategyMapDiagram") {
                    type = "appbo/vdml/StrategyMapDiagram";
                } else if (diagramType == "vdml_ValueStreamMapDiagram") {
                    type = "appbo/vdml/ValueStreamMapDiagram";
                } else if (diagramType == "vdml_CapabilityLibraryDiagram") {
                    type = "appbo/vdml/CapabilityLibraryDiagram";
                    parentPath = "appbo/vdml/ValueDeliveryModel"
                }
                var version = canvasImg.getAttribute('version');
                if (!version) {
                    version = dataManager.get('currentVDMVersion');
                }
                var id = canvasImg.getAttribute('customId');
                if (!id) {
                    canvasImg.parentElement.remove();
                    updatedImage();
                }
                var model = window.utils.getElementModel(diagramId,['vdml.EcoMapDiagram','vdml.StrategyMapDiagram','vdml.ValueStreamMapDiagram','vdml.CapabilityLibraryDiagram']);
                //dataManager.fetchDocumentFromPackage(parentId, parentPath, version, diagramId, type, vdmStore, {
                    if(model) {
                        if (isReferralModel) {
                            self.changeReferralModelId(canvasImg, diagramId, parentId, altId);
                        }
                        var data = model.get('data');
                        if(!data){
                            data = window.utils.getXmlData(diagramType.replace("_","."), model.get('id'))
                        }
                        self.openDiagram(data, canvasImg.parentElement, model, id, updatedImage);
                    }
                    else {
                        console.log('could not load contained ecomap:' + diagramId);
                        canvasImg.parentElement.remove();
                        updatedImage();
                    }
                //});
            } else if (diagramType == "dashboard_Presentation" || diagramType == "dashboard.Presentation") {
                var id = canvasImg.getAttribute('customId');
                var diagramInstance = window.utils.getElementModel(diagramId,['dashboard.Presentation']);
                //dataManager.fetchDocumentFromPackage(parentId, "appbo/dashboard/Analytics", dataManager.get('currentVDMVersion'), diagramId, "appbo/dashboard/Presentation", vdmStore, {
                    if(diagramInstance) {
                        if (isReferralModel) {
                            self.changeReferralModelId(canvasImg, diagramId, parentId, altId, diagramType, id);
                        }
                        var oldImages = jQuery("img[diagramid = \"" + diagramId + "\"]");
                        var viewProperties = DashboardMixin.getDialogViewProperties('presentation', diagramInstance);
                        var modalContentNode = jQuery('#editDetailsModalContent' + id);
                        var version = "1";
                        var newtemplatePath = "js/app/version" + version + "/" + viewProperties.templatePath;
                        var textNode1 = jQuery("<div class='test' id='bind" + id + "'></div>")[0];
                        jQuery(textNode1).insertAfter(".note-editable");
                        // jQuery('#summernote').summernote('pasteHTML', textNode1);
                        var appContentNode = jQuery("#bind" + id);
                        modalContentNode.append(appContentNode);
                        function createModal1(viewModel) {
                            jQuery.get(newtemplatePath, function (templates) {
                                appContentNode.append('<div style=\"display:none\">' + templates + '<\/div>');
                                var bindNode = jQuery("<div data-bind=\"template: { name:\'" + viewProperties.templateName + "\', data: " + viewProperties.tabId + "Model , afterRender: afterRenderView}\"></div>");
                                appContentNode.append(bindNode);
                                var dashModel = diagramInstance.getNestedParent();//self.dashboardViewModel(diagramInstance);
                                var options = { 'parentView': self, 'viewAlt': altId, 'tableId': id, 'dashboardModel': dashModel };
                                var viewInstance = viewModel.getInstance(diagramInstance, options);
                                var bindId = 'bind' + id;
                                kb.applyBindings(viewInstance, document.getElementById(bindId));
                                //window.setTimeout(viewInstance.initializePresentation, 500);
                                var node = document.getElementById(bindId);
                                self.initializePresentation = _.bind(self.initializePresentation,self);
                                viewInstance.initializePresentation(node, function () {
                                    setTimeout(function () {
                                        if (viewInstance.selectedChartType() != "Table") {
                                            domtoimage.toPng(node)
                                                .then(function (dataUrl) {
                                                    _.each(oldImages, function (oldImage) {
                                                        var oldImgQuery = jQuery(oldImage);
                                                        if (oldImgQuery.attr('customId') === id) {
                                                            oldImgQuery.attr('src', dataUrl);
                                                            oldImage.style['display'] = 'block';
                                                            //oldImgQuery.attr('lastUpdateTime',new Date().getTime());
                                                        }
                                                    });
                                                    self.removeIsEmptyP(jQuery("#bind" + id).prev());
                                                    window.cleanViewModel(viewInstance, jQuery("#bind" + id), true);
                                                    jQuery("#bind" + id).empty();
                                                    updatedImage();
                                                })
                                                .catch(function (error) {
                                                    window.cleanViewModel(viewInstance, jQuery("#bind" + id), true);
                                                    console.error('oops, something went wrong!', error);
                                                });
                                        } else {
                                            var dId = diagramInstance.id;
                                            var TableID = 'chartDuplicate_' + diagramInstance.id + altId;
                                            if (!document.getElementById(TableID)) {
                                                TableID = 'chartDuplicate_' + diagramInstance.id + id;
                                            }
                                            //set kbd tag for header rows
                                            var theaders = jQuery('#table' + id).find('th');
                                            theaders[0].style.textAlign = 'center';
                                            for (var i = 1; i < theaders.length; i++) {
                                                if (theaders[i].innerHTML.includes('span')) {
                                                    theaders[i].innerHTML = theaders[i].innerHTML.replace('span', 'kbd style="color:black"');
                                                }
                                            }
                                            //set kbd tag for even rows
                                            var evenTR = jQuery('#table' + id).find('tr.even');
                                            self.addKbdTag(evenTR);
                                            //set kbd tag for odd rows
                                            var oddTR = jQuery('#table' + id).find('tr.odd');
                                            self.addKbdTag(oddTR);
                                            document.getElementById('table' + id).removeAttribute('class');
                                            document.getElementById('table' + id).setAttribute('class', 'table table-bordered');
                                            jQuery('#table' + id).find('thead').css('background', 'rgba(178, 228, 189, 0.35');
                                            document.getElementById(TableID).innerHTML = document.getElementById(TableID).innerHTML + document.getElementById('table' + id + '_wrapper').innerHTML;
                                            jQuery('#chart' + viewInstance.encodeId).remove();
                                            document.getElementById(bindId).innerHTML = '';
                                            self.removeIsEmptyP(jQuery("#bind" + id).prev());
                                            window.cleanViewModel(viewInstance, jQuery('#' + bindId), true);
                                            jQuery("#bind" + id).remove();
                                            updatedImage();
                                        }
                                    }, 1200);
                                });
                            });
                        }
                        //var regConfiguration = dataManager.get("require1");
                        //regConfiguration([viewProperties.viewTypeStr], createModal1);
                        var className = viewProperties.viewTypeStr.substring(viewProperties.viewTypeStr.lastIndexOf('/')+1);
					    createModal1(window.utils.getView(className));
                    } else {
                        jQuery(canvasImg).parent().remove();
                        var diagramId = canvasImg.getAttribute('diagramid'); var id = canvasImg.getAttribute('customId');
                        if (jQuery('[id^="chartDuplicate_' + diagramId + id + '"]')) {
                            jQuery('[id^="chartDuplicate_' + diagramId + id + '"]').parent().remove()
                        }
                        jQuery("#bind" + canvasImg.getAttribute('customId')).remove();
                        updatedImage();
                    }
                //});
            }
            else {
                var diagramInstance = window.utils.getElementModel(diagramId,['canvas.BMCanvasDiagram']);
                //dataManager.fetchDocumentFromPackage(parentId, "appbo/vdml/ValueDeliveryModel", dataManager.get('currentVDMVersion'), diagramId, "appbo/canvas/BMCanvasDiagram", vdmStore, {
                    if(diagramInstance) {
                        if (isReferralModel) {
                            self.changeReferralModelId(canvasImg, diagramId, parentId, altId);
                        }
                        var oldImages = jQuery("img[diagramid = \"" + diagramId + "\"]");
                        //var id = window.utils.htmlEscape(window.guidGenerator()) + "designer";
                        var id = canvasImg.getAttribute('customId');
                        if (!id) {//backward compatability
                            var dataUrl = diagramInstance.get('picture' + altId);
                            _.each(oldImages, function (oldImage) {
                                var oldImgQuery = jQuery(oldImage);
                                oldImgQuery.attr('src', dataUrl);
                                //oldImgQuery.attr('lastUpdateTime',new Date().getTime());
                            });
                            updatedImage();
                            return;
                        }
                        var viewProperties = BMCanvasDiagramMixin.getDialogViewProperties('CanvasDesigner', diagramInstance);
                        var modalContentNode = jQuery('#editDetailsModalContent' + id);
                        var version = diagramInstance ? diagramInstance.getNestedParent().getVersion() : "1";
                        var newtemplatePath = "js/app/version" + version + "/" + viewProperties.templatePath;
                        var textNode1 = jQuery("<div class='test' id='bind" + id + "'></div>")[0];
                        jQuery(textNode1).insertAfter(".note-editable");
                        // jQuery('#summernote').summernote('pasteHTML', textNode1);
                        var appContentNode = jQuery("#bind" + id);
                        //appContentNode.attr("id",viewProperties.tabId+id);
                        modalContentNode.append(appContentNode);
                        function createModal2(viewModel) {
                            jQuery.get(newtemplatePath, function (templates) {
                                appContentNode.append('<div style=\"display:none\">' + templates + '<\/div>');
                                var bindNode = jQuery("<div data-bind=\"template: { name:\'" + viewProperties.templateName + "\', data: " + viewProperties.tabId + "Model , afterRender: afterRenderView}\"></div>");
                                appContentNode.append(bindNode);
                                var options = { 'parentView': self, 'viewAlt': altId };
                                var viewInstance = viewModel.getInstance(diagramInstance, options);
                                var bindId = 'bind' + id;
                                kb.applyBindings(viewInstance, document.getElementById(bindId));
                                jQuery("#js-canvas" + window.utils.htmlEscape(diagramInstance.id)).height('600px');
                                var node = document.getElementById('canvasDesigner' + window.utils.htmlEscape(diagramInstance.id));

                                domtoimage.toPng(node)
                                    .then(function (dataUrl) {

                                        //jQuery("#bind"+id).children.remove();
                                        _.each(oldImages, function (oldImage) {
                                            var oldImgQuery = jQuery(oldImage);
                                            if (oldImgQuery.attr('customId') === id) {
                                                oldImgQuery.attr('src', dataUrl);
                                                oldImage.style['display'] = 'block';
                                                //oldImgQuery.attr('lastUpdateTime',new Date().getTime());
                                            }
                                        });
                                        //canvasBindList.push("bind"+id);
                                        self.removeIsEmptyP(jQuery("#bind" + id).prev());
                                        window.cleanViewModel(viewInstance, jQuery("#bind" + id), true);
                                        jQuery("#bind" + id).remove();
                                        updatedImage();
                                    });
                            });
                        }
                        //var regConfiguration = dataManager.get("require1");
                        //regConfiguration([viewProperties.viewTypeStr], createModal2);
                        var className = viewProperties.viewTypeStr.substring(viewProperties.viewTypeStr.lastIndexOf('/')+1);
					    createModal2(window.utils.getView(className));
                    }else {
                        jQuery(canvasImg).parent().remove();
                        jQuery("#bind" + canvasImg.getAttribute('customId')).remove();
                        updatedImage();
                    }
                //});
            }
        },
            function () {
                if (callback) {
                    callback();
                }
            });
    };
    
    addKbdTag(tableRow) {
        for (var i = 0; i < tableRow.length; i++) {
            for (var j = 0; j < tableRow[i].childNodes.length; j++) {
                if (tableRow[i].childNodes[j].innerHTML.includes('<span')) {
                    tableRow[i].childNodes[j].innerHTML = tableRow[i].childNodes[j].innerHTML.replace('<span', '<kbd style="color:black"');
                } else if (tableRow[i].childNodes[j].innerHTML.includes('</span>')) {
                    tableRow[i].childNodes[j].innerHTML = tableRow[i].childNodes[j].innerHTML.replace('</span>', '</kbd>');
                }
            }
        }
    }
    hasUnsavedData() {
        var self = this;
        jQuery(".table").colResizable({ disable: true });
        //jQuery(".dataTable").colResizable({disable:true});
        jQuery('.JCLRgrips').remove();
        var summernote = self.showDashboardReport ? jQuery('#view' + self.encodeId).find('#summernote') : jQuery("#summernote");
        /*var tableDuplicate =  jQuery('[id^="chartDuplicate_"]');
        tableDuplicate.find('table').remove();
        var tabDupLen = tableDuplicate.length-1;
        for(var i=tabDupLen;i>0;i--){ // For charts
            if(!tableDuplicate[i].innerHTML || tableDuplicate[i].innerHTML == "<p></p>"){
                tableDuplicate[i].remove();
            }
        }
        for(var a = jQuery('p').length-1;a>0;a--){
            if(jQuery('p')[a].innerHTML == "<br>"){
                jQuery('p')[a].remove();
            }
        }*/
        if(window.vdmModelView.delRep){
            return;
        }
        var contentData = summernote.summernote('code');
        var contentJQuery = jQuery(contentData);
        contentData = "";
        var existingCodeContainersIds = [];
        var canvasImgs = contentJQuery.find('img').each(function () {
            if ((this.getAttribute('diagramId') || this.getAttribute('id'))) {
                if (!this.getAttribute('src')) {
                    this.remove();
                } else {
                    this.src = "";
                    this.style['display'] = 'none';
                }
                if(this.getAttribute('type') == 'beeppackage.CodeContainer'){
                    existingCodeContainersIds.push(this.getAttribute('diagramId'));
                }
            }
        });
        contentJQuery.find('table').find('kbd').find('svg').remove();//to remove smilies
        contentJQuery.each(function () {
            contentData = contentData + this.outerHTML;
        });
        if (contentJQuery.length == 0) {
            contentData = summernote.summernote('code');
        }
        existingCodeContainersIds = _.uniq(existingCodeContainersIds);
        var selectedBackgroundColor = jQuery('.note-editable').css('background-color');
        self.model.set({'bgcolor': selectedBackgroundColor,'content': contentData});
		jQuery("#selectedBackColor").attr('value',self.model.get('bgcolor'));

        if (!self.showDashboardReport) {
            var deletedCodedContainers = [];
            _.each(self.model.get('dependentPackage').models,function(dep){
                if(dep.get('type') == "beeppackage_CodeContainer" && !_.contains(existingCodeContainersIds, dep.id)){
                    deletedCodedContainers.push(dep.id);
                }
            });
            var EcoMapMixin = Backbone.Relational.store.getObjectByName("ecomap.EcoMapMixin");
            EcoMapMixin.removeImageArtifact(deletedCodedContainers,self.model.getNestedParent());

            if (jQuery('.report-watermark')[0] && jQuery('.report-watermark')[0].innerHTML != jQuery(".report-watermark").text()) {
                var imgData = (jQuery('.report-watermark')["0"].innerHTML.substr(jQuery(".report-watermark").text().length)).split('"').join('\\"');
            }
            else {
                var imgData = null;
            }
            // var paperSize = "Default";
            //if (jQuery('.note-editing-area')[0].classList.length > 1) {
            //    paperSize = jQuery('.note-editing-area')[0].classList[1].toUpperCase();
            //}
            
            if (jQuery('.report-watermark')[0] && jQuery('.report-watermark')[0].innerHTML) {
                var transform = (jQuery('.report-watermark')[0]) ? jQuery('.report-watermark')[0].style.transform : "";
                var wmObj = '{"ucName"  : "' + jQuery(".report-watermark").text() + '", "imgData" : "' + imgData + '" , "wmColor" : "' + jQuery('.report-watermark').css('color') + '", "transform": "' + transform + '", "opacity": "' + jQuery('.report-watermark').css('opacity') + '"}';
                self.model.set('wmJsonObj', wmObj);
            } else {
                self.model.set('wmJsonObj', null);
            }
            var headetString = $('#reportHeader')["0"] ? $('#reportHeader')["0"].innerText : "";
            var footerString = $('#reportFooter')["0"] ? $('#reportFooter')["0"].innerText : ""
            self.model.set({'header': headetString,'footer': footerString})
        }
        contentJQuery.remove();
    };

    cleanUp() {
        var self = this;
        var summernote = self.showDashboardReport ? jQuery('#view' + self.encodeId).find('#summernote') : jQuery("#summernote");
        if (!self.showDashboardReport) {
            this.model.get('diagram').reset();
            //this.model.get('dependentPackage').reset();
        }
        jQuery("note-findnreplace-find").html("");
        jQuery("note-findnreplace-replace").html("");
        if (self.showDashboardReport) {
            self.hasUnsavedData();
        }
        /*_.each(self.ecoMaps, function (ecoMap) {
            ecoMap.destroy();
        })*/
        summernote.summernote('destroy');
        jQuery(window, ".resize").unbind('resize');
        if (!self.showDashboardReport) {
            self.spliter.enhsplitter('remove');
            jQuery("#summernotecss").attr("href", "");
        }

        summernote.remove();
        if (ko.components.isRegistered('ReportDetailsWidget')) {
            ko.components.unregister('ReportDetailsWidget');
        }
        if (ko.components.isRegistered('ReportGuidance')) {
            ko.components.unregister('ReportGuidance');
        }
        //window.utils.loadTemplate(null,true);
    };

    saveSummernoteContent(summernote) {
        var self = this;
        jQuery('#view' + self.encodeId).find('.note-editable img').off('click', null);
        var contentData = summernote.summernote('code');

        var contentJQuery = jQuery(contentData);
        contentData = "";
        contentJQuery.each(function () {
            contentData = contentData + this.outerHTML;
        });
        self.model.set('content', contentData);
        contentJQuery.remove();
    }
    
    initializePresentation(redraw) {
        var self = this;
        var summernote = jQuery('#view' + self.encodeId).find('#summernote');
        var gridItem = jQuery('#view' + self.encodeId).parent();
        var chartItem = jQuery('#view' + self.encodeId);
        var airMode = !(self.options.editMode || jQuery('#editMode' + self.encodeId).is(':checked'));

        if (redraw === null || redraw === undefined || redraw === false) {
            summernote.summernote('destroy');
            var availableOptions = ['importImage']
            summernote.summernote(
                {
                    airMode: airMode,
                    dialogsInBody: true,
                    minHeight: null,             // set minimum height of editor
                    maxHeight: null,             // set maximum height of editor
                    focus: true,
                    viewModel: self,
                    disableResizeEditor: true, //disable resize 
                    toolbar: [
                        ['style', ['bold', 'italic', 'underline', 'clear']],
                        ['font', ['fontname', 'fontsize', 'color', 'strikethrough', 'superscript', 'subscript', 'title']],
                        ['para', ['style', 'ul', 'ol', 'paragraph']],
                        ['height', ['height']],
                        ['insert', ['picture', 'link', 'table', 'hr', 'tableofcontent']],
                        //['Canvas', availableOptions],
                        ['custom', ['caseConverter']]
                    ],
                    //removing popover in air mode
                    popover: {
                        image: [
                            ['imagesize', ['imageSize100', 'imageSize50', 'imageSize25']],
                            ['float', ['floatLeft', 'floatRight', 'floatNone']],
                            ['remove', ['removeMedia']]
                        ],
                        link: [
                            ['link', ['linkDialogShow', 'unlink']]
                        ],
                        table: [
                            ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
                            ['delete', ['deleteRow', 'deleteCol', 'deleteTable']]
                        ],
                        air: [

                        ]
                    },
                    callbacks: {
                        onChange: function (e) {
                            self.options.parentView && window.vdmModelView.setResizeHeight && window.vdmModelView.setResizeHeight();
                            chartItem.css('overflow-y', 'auto');
                            chartItem.css('overflow-x', 'hidden');
                        }
                    },
                    onMediaDelete: function ($target, editor, $editable) {
                        console.log($target[0].src);
                        $target.remove();
                    }
                }
            );
            jQuery('.note-statusbar-output').hide();
            summernote.viewModel = self;
            summernote.summernote('code', self.model.get('content'));
        }
        //jQuery('#view'+self.encodeId).outerHeight(gridItem.height());

        var totalHeight = redraw !== null || redraw !== undefined ? gridItem.height() : 450;
        var detailsLegendHeight = jQuery('#details' + self.encodeId).outerHeight(true);
        var toolbarHeight = chartItem.find('.note-toolbar').outerHeight(true);
        var summernoteHeight = totalHeight - detailsLegendHeight - (toolbarHeight ? toolbarHeight : 0) - 25;
        chartItem.find('.note-toolbar').addClass('summernoteToolbar');
        chartItem.find('.note-editable').outerHeight(summernoteHeight);
        chartItem.find('.note-editable').css('width', '100%');
        chartItem.find('.note-editable').css('overflow-y', 'auto');
        chartItem.find('.note-editor').css('margin-left', '5px');
        summernote.summernote('maxHeight', summernoteHeight);
        gridItem.height(totalHeight);
        if (!redraw && self.options.parentView) {// when notes is added
            window.vdmModelView.setResizeHeight();
        }
        chartItem.css('overflow-y', 'auto');
        chartItem.css('overflow-x', 'hidden');
        //for drop down
        jQuery('#dropdown' + self.encodeId).on('click', function () {
            event.stopPropagation();
            jQuery("#dropdownMenu" + self.encodeId).show();

        });
        jQuery("#DashboardView").on('click', function (e) {
            if (!e.target.closest('.dropdown-menu-right') && !(e.target.id === 'dropdown' + self.encodeId)) {
                jQuery("#dropdownMenu" + self.encodeId).hide();
            }
        });
        if (self.options.editMode) {
            jQuery('#editMode' + self.encodeId).prop('checked', true);
            summernote.summernote('enable');
            //$(".note-editable").attr("contenteditable","false")
        } else if (jQuery('#editMode' + self.encodeId).is(':checked')) {
            summernote.summernote('enable');
            //$(".note-editable").attr("contenteditable","true")
        } else {
            summernote.summernote('disable');
            //$(".note-editable").attr("contenteditable","false")
        }
        jQuery('#editMode' + self.encodeId).unbind('click');
        jQuery('#editMode' + self.encodeId).click(function () {
            self.options.editMode = this.checked;
            var charts = window.vdmModelView.charts();
            _.each(charts, function (presentationView) {
                if (presentationView.viewInstance && presentationView.viewInstance.encodeId == self.encodeId) {
                    if($("#view"+presentationView.encodeId).is(':visible')){
                        if(self.options.editMode === false) self.saveSummernoteContent(summernote);
                        presentationView.viewInstance.initializePresentation(false);
                    }
                }
            });
            //window.vdmModelView.setResizeHeight(true);
        });

    }
    cleanModal() {
        var self = this;
        jQuery("#modal" + self.options.modalId).modal('hide');
        setTimeout(function () { jQuery('#modal' + self.options.modalId).remove(); }, 1000);
    };
    getPhaseDesignModels(phaseDesignPartModels, originalParentId) {
        var self = this;
        var exist = false;
        for (var i = 0; i < phaseDesignPartModels.length; i++) {
            if (phaseDesignPartModels.at(i).get('beepReference') === originalParentId) {
                exist = true;
                break;
            }
        }
        return exist;
    }
    /*dashboardViewModel(diagramInstance) {
        var self = this;
        var dashboardModel;
        var dashboard = diagramInstance.get('analytics').get('dashboard').models;
        for (var i = 0; i < dashboard.length; i++) {
            var present = dashboard[i].get('presentation').models;
            var dashModel = _.find(present, function (model) { return model.id == diagramInstance.id; });
            if (dashModel) {
                dashboardModel = dashboard[i];
                break;
            }
        }
        return dashboardModel;
    }*/
    getType(diagramType) {
        var type = "appbo/vdml/EcoMapDiagram";
        if (diagramType == "vdml.StrategyMapDiagram") {
            type = "appbo/vdml/StrategyMapDiagram";
        } else if (diagramType == "vdml.ValueStreamMapDiagram") {
            type = "appbo/vdml/ValueStreamMapDiagram";
        } else if (diagramType == "vdml.CapabilityLibraryDiagram") {
            type = "appbo/vdml/CapabilityLibraryDiagram";
        }
        return type;
    }
    removeIsEmptyP(selection) {
        if (selection[0].tagName == 'P' && selection[0].innerHTML == '<br>') {
            selection.remove();
        }
        /*if(jQuery('span').find('br').length > 0){
            jQuery('span').find('br').parent().remove();
        }*/
        return;
    }
    init(model, options){
        var self = this;
        this.afterRenderView = _.bind(this.afterRenderView,self);
        //this.name = ko.observable("raj");
        this.SummernoteReportViewModel = this;
        this.model = model;
        this.options = options;
        options = null;
        model = null;
        this.encodeId = window.utils.htmlEscape(self.model.id);
        //this.isEditMode = ko.observable(true);
        self.guidanceViewInstance = BMGuidanceViewModel.getInstance(self.model);
        if (!ko.components.isRegistered('ReportGuidance')) {
            ko.components.register("ReportGuidance", {
                viewModel: { instance: self.guidanceViewInstance },
                template: '<div id="guidanceTab"></div>',
                synchronous: true
            });
        }
        if (self.options.parentView && self.options.parentView == "Dashboard") {
            self.showDashboardReport = true;
            if (!ko.components.isRegistered('ReportDetailsWidget')) {
                ko.components.register("ReportDetailsWidget", {
                    viewModel: ReportDetailsViewModel,
                    template: '<div></div>'
                });
            }
        } else {
            /*window.utils.loadTemplate(function (name) {
            })*/
            if (!ko.components.isRegistered('ReportDetailsWidget')) {
                ko.components.register("ReportDetailsWidget", {
                    viewModel: ReportDetailsViewModel,
                    template: window.utils.getHtmlPage("ReportDetailsTemplate")
                });
            }
        }
		//this.isEditMode = _.bind(this.isEditMode,self);
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'name'
            , 'description'
            , 'Guidance'
            , 'Collaboration'
            , 'Complete'
            , 'Delete'
            , 'ReportDetails'
            , 'BusinessEcosystemMap'
            , 'linkModels'
            , 'linkText'
            , 'BusinessCanvas'
            , 'findnReplace'
        ]);

    }
    static getInstance(model, options) {
        var view = new SummernoteReportViewModel(model, options);
        //view.init(model, options);
        return view;
    }
}
path.SummernoteReportViewModel = SummernoteReportViewModel;

