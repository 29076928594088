import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import {SmmElement} from './SmmElement'
import {SmmElementMixin} from './SmmElementMixin'
//import { ObservedMeasure } from './ObservedMeasure'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { RefinementMeasurementRelationship } from './RefinementMeasurementRelationship'
import { EquivalentMeasurementRelationship } from './EquivalentMeasurementRelationship'
import { MeasurementRelationship } from './MeasurementRelationship'

/*define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/SmmElementMixin","appbo/smm/MeasureLibrary","appbo/smm/MeasureLibrary","appbo/smm/Base1MeasureRelationship","appbo/smm/Base2MeasureRelationship","appbo/smm/BaseNMeasureRelationship","appbo/smm/GradeMeasureRelationship","appbo/smm/RankingMeasureRelationship","appbo/smm/RescaledMeasureRelationship"],
function(require,jQuery,_, async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,SmmElementMixin,MeasureLibrary,Base1MeasureRelationship,Base2MeasureRelationship,BaseNMeasureRelationship,GradeMeasureRelationship,RankingMeasureRelationship,RescaledMeasureRelationship
, ObservedMeasure
, RefinementMeasurementRelationship
, EquivalentMeasurementRelationship
, MeasurementRelationship){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	//if(!ObservedMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.ObservedMeasure){
			ObservedMeasure = importPath.ObservedMeasure;
		}
        else {
            import('./ObservedMeasure').then(({ default: ObservedMeasure }) => {
                ObservedMeasure = ObservedMeasure;
            });
           /* require(["appbo/smm/ObservedMeasure"], function (loadedModule) {
                ObservedMeasure = loadedModule;
			});*/
		}
	//}
	if(!RefinementMeasurementRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RefinementMeasurementRelationship){
			RefinementMeasurementRelationship = importPath.RefinementMeasurementRelationship;
		}
        else {
            import('./RefinementMeasurementRelationship').then(({ default: RefinementMeasurementRelationship }) => {
                RefinementMeasurementRelationship = RefinementMeasurementRelationship;
            });
            /*require(["appbo/smm/RefinementMeasurementRelationship"], function (loadedModule) {
                RefinementMeasurementRelationship = loadedModule;
			});*/
		}
	}
	if(!EquivalentMeasurementRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.EquivalentMeasurementRelationship){
			EquivalentMeasurementRelationship = importPath.EquivalentMeasurementRelationship;
		}
		else{
			import('./EquivalentMeasurementRelationship').then(({ default: EquivalentMeasurementRelationship }) => {
                EquivalentMeasurementRelationship = EquivalentMeasurementRelationship;
            });
            /*require(["appbo/smm/EquivalentMeasurementRelationship"], function (loadedModule) {
                EquivalentMeasurementRelationship = loadedModule;
			});*/
		}
	}
	if(!MeasurementRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.MeasurementRelationship){
			MeasurementRelationship = importPath.MeasurementRelationship;
		}
        else {
            import('./MeasurementRelationship').then(({ default: MeasurementRelationship }) => {
                MeasurementRelationship = MeasurementRelationship;
            });
            /*require(["appbo/smm/MeasurementRelationship"], function (loadedModule) {
                MeasurementRelationship = loadedModule;
			});*/
		}
	}
	export class MeasurementMixin {

	defaults(){
		var ret = {
			type: "smm_Measurement"
		}
		return jQuery.extend(SmmElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		/*{
			type :Backbone.HasMany,
			containingClass:"smm_Measurement",
			key:"refinementTo",
			relatedModel:"smm.RefinementMeasurementRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"from",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_Measurement",
			key:"refinementFrom",
			relatedModel:"smm.RefinementMeasurementRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"to",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_Measurement",
			key:"equivalentTo",
			relatedModel:"smm.EquivalentMeasurementRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"from",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_Measurement",
			key:"equivalentFrom",
			relatedModel:"smm.EquivalentMeasurementRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"to",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},*/
		{
			type :Backbone.HasMany,
			containingClass:"smm_Measurement",
			key:"measurementRelationships",
			relatedModel:"smm.MeasurementRelationship",
			reverseRelation: {
				key:"measurementRelationshipsOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!MeasurementMixin.cummulativeRelations) {
            MeasurementMixin.cummulativeRelations = _.union(MeasurementMixin.getMixinRelations()
                , SmmElementMixin.getCumulativeMixinRelations()
            );
        }
		return MeasurementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_SmmElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "error",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			{name : "breakValue",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("observedMeasure") ? this.get("observedMeasure") : this.previousAttributes().observedMeasure;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("observedMeasure") ? this.get("observedMeasure") : this.previousAttributes().observedMeasure;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/MeasurementPropertiesTemplate.html",
			templateName : "MeasurementPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/MeasurementViewModel",
			tabId : "MeasurementView",
			tabName: "Measurement"
		}
	}
//#startCustomMethods
    cloneParentRelationships (callback) {
        var valueComponent = this.get('measuredCharacteristic').getParent();
        var currentObservation = this.get('observedMeasure').get('observation');
        var measureLibrary = currentObservation.get('smmModel');
        var viewAlternative = currentObservation.getContext().getAlternative();
        valueComponent.addBaseRelationshipsToParents(measureLibrary, this, viewAlternative, function () {
            if (callback) {
                callback();
            }
        })
    }

	getNextPathNodes(pathNodes,paths,origin){
		var self = this;
		var nextNodes = self.getParents(true);
		for(var i=0;i<nextNodes.length;i++){
			if(nextNodes[i] === origin){
				paths.push(pathNodes.concat());
			}else{
				if(_.indexOf(pathNodes,nextNodes[i]) >= 0){
					continue;
				}
				var newPathNodes = pathNodes.concat(nextNodes[i]);
				nextNodes[i].getNextPathNodes(newPathNodes, paths, origin);
			}			
		}
		pathNodes.length = 0;
	};
	
	getCyclicPath(){
		var paths = [];
		var self = this;
		self.getNextPathNodes([self],paths,self);
		if(paths.length>0){
			return paths;
		}else{
			return null;
		}
	};
	
	getCyclicPath1(element,paths,path){
		function addPathToPaths(){
			var found = false;
			for(var i=0;i<paths.length;i++){
				var currentPath = paths[i];
				var commonPath = _.intersection(currentPath,path);
				if(commonPath.length === path.length && commonPath.length === currentPath.length){
					found = true;
					break;
				}
			}
			if(!found && path.length > 1 ){
				paths.push(path);
			}
		}	
		var self = this;
		if(!element){
			element = this;
		}
		if(!path){
			path = [];
			paths = [];
			path.push(this);
		}
		var aggregatedTos = self.getParents(true);
		for(var i=0;i<aggregatedTos.length;i++){
			var aggregatedTo = aggregatedTos[i];
			if(aggregatedTo === element){
				addPathToPaths();
				path = [];
				continue;
			}else{
				if(_.indexOf(path,aggregatedTo) >= 0){
					path = [];
					continue;					
				}
				path.push(aggregatedTo);
				var parentCyclic = aggregatedTo.getCyclicPath(element,paths,path);
				if(parentCyclic && parentCyclic.length>0){
					addPathToPaths();
					path = [];
					continue;
				}else{
					path.pop();
				}
			}
		}
		if(paths.length>0){
			return paths;
		}else{
			return null;
		}
	};
	//var replacedSet = [];
	replaceMeasure(measureLibrary,measurementDerived,charToMatch,unitToMatch,valFormula,accToMatch,baseRelSetToMatch,isRecursion,satisfactionTuple,intervalTupleSet,nonDimensional,replacedSet){
		if(!measurementDerived){
			measurementDerived = this;
		}
		var baseRelSetToMatch = baseRelSetToMatch;
		var observedMeasureDerived = measurementDerived.get('observedMeasure');
		var measureToBeReplaced = observedMeasureDerived.get('measure');
		var observedMeasure =  this.get('observedMeasure');
		var scenario = measurementDerived.get('observedMeasure').get('observation').getContext();
		var measureOfObservedMeasure =  observedMeasure.get('measure');
		observedMeasureDerived.set('measure',null,{creatingRevision:true});	// this is triggering destroy of measure and observed measure.
		var measureReplaced;
		if(measureOfObservedMeasure === measureToBeReplaced){
			replacedSet = replacedSet?replacedSet:[];
			measureReplaced = measureLibrary.findOrCreateMeasure(charToMatch,unitToMatch,valFormula,accToMatch,baseRelSetToMatch,satisfactionTuple,intervalTupleSet,nonDimensional);
		}else{
			var measType = measureToBeReplaced.get('type');
			if(measType === 'smm_CollectiveMeasure'){	//TODO for binary measure
				var lCharToMatch = measureToBeReplaced.get('trait').get('name');
				var lUnitToMatch = {unitName:measureToBeReplaced.get('unit').get('name'), roundingDigits:measureToBeReplaced.get('unit').get('roundingDigits'), significantDecimals:measureToBeReplaced.get('unit').get('significantDecimals')};
				var lAccToMatch = measureToBeReplaced.get('accumulator');
				var lSatisfactionTuple = null;
				var lIntervalTupleSet = null;
				var valueComponent = measurementDerived.get('measuredCharacteristic').getMeasurand();
				var lBaseRelSetToMatch = valueComponent.constructBaseMeasureTupleSet(measurementDerived.get('characteristic'),scenario);
				var rels = measureToBeReplaced.get('baseMeasureTo');
				//var count = 1;
				rels.each(function(rel){
					var baseMeasure = rel.get('to');
					//var relId = DataManager.getDataManager().guidGeneratorByOwner(measureReplaced);
					//var replRel = new BaseNMeasureRelationship({id:relId,measure:measureReplaced,name:baseMeasure.get('name') + "reln" + count++});
					_.each(replacedSet,function(tuple){
						if(tuple.measureToBeReplaced === baseMeasure){
							for(var i=0;i<lBaseRelSetToMatch.length;i++){
								if(lBaseRelSetToMatch[i].old.measure === baseMeasure){
									lBaseRelSetToMatch[i].old.measuretuple = tuple.measureReplaced;
								}
								break;
							}
						}
					});
				});
				measureReplaced = measureLibrary.findOrCreateMeasure(lCharToMatch,lUnitToMatch,null,lAccToMatch,lBaseRelSetToMatch,lSatisfactionTuple,lIntervalTupleSet,null);
			}
			if(measType === 'smm_RankingMeasure'){
				var lCharToMatch = measureToBeReplaced.get('trait').get('name');
				var lUnitToMatch = {unitName:measureToBeReplaced.get('unit').get('name'), roundingDigits:measureToBeReplaced.get('unit').get('roundingDigits'), significantDecimals:measureToBeReplaced.get('unit').get('significantDecimals')};
				var lAccToMatch = null;
				var lSatisfactionTuple = null;
				var lIntervalTupleSet = measureToBeReplaced.constructIntervalTupleSet('smm_RankingMeasure');
				var lBaseRelSetToMatch = null;
				var rel = measureToBeReplaced.get('rankingTo');
				var toMes = rel.get('to');
				_.each(replacedSet,function(tuple){
					if(tuple.measureToBeReplaced === toMes){
						lSatisfactionTuple = {type:'smm_RankingMeasure',measurement:tuple.measurement};
					}else{
						lSatisfactionTuple = {type:'smm_RankingMeasure',measurement:measurementDerived.get('rankingTo').get('to')};
					}
				});
				measureReplaced = measureLibrary.findOrCreateMeasure(lCharToMatch,lUnitToMatch,null,lAccToMatch,lBaseRelSetToMatch,lSatisfactionTuple,lIntervalTupleSet,null);
			}
			if(measType === 'smm_GradeMeasure'){
				var lCharToMatch = measureToBeReplaced.get('trait').get('name');
				var lUnitToMatch;
				if(measureToBeReplaced.get('unit')) {
					lUnitToMatch = {unitName:measureToBeReplaced.get('unit').get('name'), roundingDigits:measureToBeReplaced.get('unit').get('roundingDigits'), significantDecimals:measureToBeReplaced.get('unit').get('significantDecimals')};
				}
				var lAccToMatch = null;
				var lSatisfactionTuple = null;
				var lIntervalTupleSet = measureToBeReplaced.constructIntervalTupleSet('smm_GradeMeasure');
				var lBaseRelSetToMatch = null;
				var rel = measureToBeReplaced.get('gradeTo');
				var toMes = rel.get('to');
				_.each(replacedSet,function(tuple){
					if(tuple.measureToBeReplaced === toMes){
						lSatisfactionTuple = {type:'smm_GradeMeasure',measurement:tuple.measurement};
					}else{
						lSatisfactionTuple = {type:'smm_GradeMeasure',measurement:measurementDerived.get('gradeTo').get('to')};
					}
				});
				measureReplaced = measureLibrary.findOrCreateMeasure(lCharToMatch,lUnitToMatch,null,lAccToMatch,lBaseRelSetToMatch,lSatisfactionTuple,lIntervalTupleSet,null);
			}
			if(measType === 'smm_BinaryMeasure'){
				
			}
			if(measType === 'smm_RescaledMeasure'){
				
			}
		}
		replacedSet.push({'measureToBeReplaced':measureToBeReplaced,'measureReplaced':measureReplaced,measurement:measurementDerived});
		observedMeasureDerived.set('measure',measureReplaced);
		if(measureToBeReplaced != measureReplaced){
			measurementDerived.set('oldMeasureId',measureToBeReplaced.id);
		}
		if(measureToBeReplaced != measureReplaced && measureReplaced.get('type') === 'smm_CollectiveMeasure' && this.get('type') === "smm_CollectiveMeasurement"){//definition update
			var parentMesurementRels = this.get('baseMeasurementTo');
			var measureRelationships = measureReplaced.get('baseMeasureTo');
			parentMesurementRels.each(function (mstRel) {
			    getMatchedMeasureRelation(mstRel, measureRelationships);
			});
		}
		function getMatchedMeasureRelation(mstRel, measureRelationships) {
		    //var replacedSetMeasure;
		    var measure = mstRel.get('to')?mstRel.get('to').get('observedMeasure').get('measure'):null;
		    var oldMeasure = measure;
		    for(var i=0;i<replacedSet.length;i++){
		        if (replacedSet[i].measureReplaced === measure && replacedSet[i].measurement === mstRel.get('to')) {
		            oldMeasure = replacedSet[i].measureToBeReplaced;
		            break;
		        }
		    }
		    var foundDefinition = false;
		    for (var i = 0; i < measureRelationships.length; i++) {
		        if (measureRelationships.at(i).get('to') === measure || measureRelationships.at(i).get('to') === oldMeasure) {
		            if (mstRel.get('definition') &&
                        (measureRelationships.at(i).get('usePreviousAlternative') == mstRel.get('definition').get('usePreviousAlternative') ||
                        measureRelationships.at(i).get('usePreviousAlternative') == undefined && !mstRel.get('definition').get('usePreviousAlternative'))) {
		                var rescaledMeasure = measureRelationships.at(i).get('rescaledMeasure');
		                var relRescaledMeasure = mstRel.get('definition').get('rescaledMeasure');

		                var offset = rescaledMeasure ? rescaledMeasure.get('offset') : 0;
		                var multiplier = rescaledMeasure ? rescaledMeasure.get('multiplier') : 1;
		                var operation = rescaledMeasure ? rescaledMeasure.get('operation') : null;

		                var reloffset = relRescaledMeasure ? relRescaledMeasure.get('offset') : 0;
		                var relmultiplier = relRescaledMeasure ? relRescaledMeasure.get('multiplier') : 1;
		                var reloperation = relRescaledMeasure ? relRescaledMeasure.get('operation') : null;

		                if (offset === reloffset && multiplier === relmultiplier && operation === reloperation) {
		                	var alreadyAssigned = false;
							//if(measureRelationships.at(i).get('to').get('observedMeasure').length > 1){//measure resued
								var parentMsmtRel = mstRel.get('from').get('measurementRelationships');	
								for(var n = 0; n < parentMsmtRel.length; n++){
									if(parentMsmtRel.at(n) != mstRel && parentMsmtRel.at(n).get('definition') === measureRelationships.at(i)){
										alreadyAssigned = true;
										break;
									}
								}
			            	//}
		            		if(!alreadyAssigned){
								mstRel.set('definition', measureRelationships.at(i));
								foundDefinition = true;
		                    	break;
							}
		                }
		            }
		        }
		    }
		    if(!foundDefinition){
		    	/*if(mstRel.get('definition') && mstRel.get('to') && mstRel.get('to').get('observedMeasure').get('measure') === mstRel.get('definition').get('to')){
		    		mstRel.get('definition').set('from',mstRel.get('from').get('observedMeasure').get('measure'));
		    	}*/
		    }
		}

		function resetBaseTupleMeasures(oldMeasure,newMeasure){//for cycle
			if(baseRelSetToMatch) {
				for(var i=0;i<baseRelSetToMatch.length;i++){
					if(baseRelSetToMatch[i].new && baseRelSetToMatch[i].new.measure === oldMeasure){
						baseRelSetToMatch[i].new.measure = newMeasure;
					}
				}
			}
		}
		if(isRecursion){
			var parents = measurementDerived.getParents();
			var measurementsParents = [];
			var observedMeasureParents = [];
			var changeFromRankToGrade = false;
			if(measureReplaced.get('type') === "smm_GradeMeasure" && measureToBeReplaced.get('type') === "smm_RankingMeasure"){
				changeFromRankToGrade = true;
			}
			
			//async.eachSeries(parents,function(measurementParent,measurementHandledCallback){
			_.each(parents,function(measurementParent){
				var parentMC = measurementParent.get('measuredCharacteristic');
				if(!parentMC){
					return;
				}
				var parentMCMeasurements = parentMC.get('measurement');
				parentMCMeasurements.each(function(parentMst){
					var parentMstRels = parentMst.get('measurementRelationships');
					parentMstRels.each(function(rel){
						if(rel && rel.get('to') && rel.get('to').get('observedMeasure') && (rel.get('to').get('observedMeasure').get('measure') === measureReplaced ||
							rel.get('from').get('observedMeasure').get('measure') === measureReplaced) && (measurementParent.get('observedMeasure').get('observation').get('whenObserved') === rel.get('from').get('observedMeasure').get('observation').get('whenObserved'))){
							measurementsParents.push(parentMst);
							observedMeasureParents.push(parentMst.get('observedMeasure'));
						}
					});
				});
			});
			measurementsParents = _.uniq(measurementsParents);
			observedMeasureParents = _.uniq(observedMeasureParents);
			//async.eachSeries(measurementsParents,function(measurementParent,handleMeasurementCallback){
			_.each(measurementsParents,function(measurementParent){
				var observedMeasureParent = measurementParent.get('observedMeasure');
                if (!observedMeasureParent || measurementParent.id === measurementDerived.id){
					return;
				}
				var measureParent = observedMeasureParent.get('measure');
				var mesParentObsMeasures = _.filter(measureParent.get('observedMeasure').models,function(obsMeasure){if(obsMeasure.get('observation').get('whenObserved') === observedMeasureParent.get('observation').get('whenObserved')){return obsMeasure}});
				var partOfSet = true;
				_.each(mesParentObsMeasures,function(mesParentMeasure){
					if(observedMeasureParents.indexOf(mesParentMeasure) < 0){
						partOfSet = false;
					}
				});
               
				if(!partOfSet){
					var parentLib = measureParent.get('libraries');
					var parentCharToMatch = measureParent.get('trait').get('name');
					var parentUnitToMatch;
					if(measureParent.get('unit')){
						parentUnitToMatch = {unitName:measureParent.get('unit').get('name'), roundingDigits:measureParent.get('unit').get('roundingDigits'), significantDecimals:measureParent.get('unit').get('significantDecimals')};
					}
					var parentValFormula = measureParent.get('formula');
					var parentAcc = measureParent.get('accumulator');
					var parentTuples;
					var satisfactionTuple;
					var intervalTupleSet;
					if(measurementParent.get('type') === 'smm_CollectiveMeasurement'){
						parentTuples = measurementParent.get('measuredCharacteristic').getMeasurand().constructBaseMeasureTupleSet( measureParent.get('measuredCharacteristic'),measurementParent.get('observedMeasure').get('observation').getContext());	
					}else{
						var rel = measurementParent.get('measurementRelationships');
						var collMeasurement = rel?rel.at(0).get('to'):null;
						satisfactionTuple = {measure:measureParent,type:measureParent.get('type'),measurement:collMeasurement};
						intervalTupleSet = measureParent.getIntervalTupleSet();
					}
					if(parentTuples){
						var usedRels = [];
						var comp = measurementDerived.get('measuredCharacteristic').getParent();
						for(var i=0;i<parentTuples.length;i++){
							var tuple = parentTuples[i];
							if(tuple.old.measure === measureToBeReplaced && comp === tuple.old.component){
								//tuple.old.measure = measureReplaced;
								if(!changeFromRankToGrade){
									if(!tuple.new){
										tuple.new = {};
									}
									tuple.new.measure = measureReplaced;
									//tuple.old.measuredCharacteristic = measurementDerived.get('measuredCharacteristic');
									tuple.new.measuredCharacteristic = measurementDerived.get('measuredCharacteristic');										
								}

								measureParent.get('baseMeasureTo').each(function(rel){
									for(var z=0;z<usedRels.length;z++){
										if(rel === usedRels[z]){
											return;
										}
									}
									var relRescaledMeasure = rel.get('rescaledMeasure');
									var reloffset = relRescaledMeasure ? relRescaledMeasure.get('offset') : 0;
					                var relmultiplier = relRescaledMeasure ? relRescaledMeasure.get('multiplier') : 1;
					                var reloperation = relRescaledMeasure ? relRescaledMeasure.get('operation') : null;
									if(rel.get('to') === measureToBeReplaced && reloffset === tuple.old.offset && relmultiplier === tuple.old.multiplier && reloperation === tuple.old.operation){
										usedRels.push(rel);
										tuple.new.usePreviousAlternative = rel.get('usePreviousAlternative');
										if(relRescaledMeasure){
											tuple.old.multiplier = relRescaledMeasure.get('multiplier');
											tuple.old.offset = relRescaledMeasure.get('offset');
											tuple.old.operation = relRescaledMeasure.get('operation');
											if(!changeFromRankToGrade){
												tuple.new.multiplier = relRescaledMeasure.get('multiplier');
												tuple.new.offset = relRescaledMeasure.get('offset');
												tuple.new.operation = relRescaledMeasure.get('operation');													
											}

										}
									}
								});
								//break;	// as to avoid the case where we replace all the children with same measure with same new one.
							}
						}
						usedRels = [];
					}
					var parentMeasureReplaced = measurementParent.replaceMeasure(parentLib,measurementParent,parentCharToMatch,parentUnitToMatch,parentValFormula,parentAcc,parentTuples,true,satisfactionTuple,intervalTupleSet,null,replacedSet);
					resetBaseTupleMeasures(measureParent,parentMeasureReplaced);
				}else{
					var measureRelationShips = measureParent.get('measureRelationships');
					var destroyRelation = [];
					
					if (measurementParent.get('type') === 'smm_CollectiveMeasurement') {
					    var mestParentRels = measurementParent.get('baseMeasurementTo');
					    mestParentRels.each(function (mstParentRel) {
					        var baseMst = mstParentRel.get('to');
					        //var baseMes = baseMst.get('observedMeasure').get('measure');
					        //if (mstParentRel.get('from').get('observedMeasure').get('measure')  !== measureParent) {
					            getMatchedMeasureRelation(mstParentRel, measureRelationShips);
                            //}
					        for (var i = 0; i < replacedSet.length; i++) {
					            if (replacedSet[i]['measurement'] === baseMst && baseMst.get('observedMeasure').get('measure') === replacedSet[i].measureReplaced && mstParentRel.get('definition')) {
					                mstParentRel.get('definition').set('to', replacedSet[i].measureReplaced);
					                break;
					            }
					        }
					    });
					} else {
					    measureRelationShips.each(function (rel) {
					        var relToBeChanged = null;
					        if (rel.get('type') === 'smm_RescaledMeasureRelationship') {
					            if (rel.get('from') === measureToBeReplaced) {
					                relToBeChanged = rel;
					            }
					        } else {
					            if (rel.get('to') === measureToBeReplaced) {
					                relToBeChanged = rel;
					            }
					        }
					        if (relToBeChanged && !changeFromRankToGrade) {
					            if (relToBeChanged.get('type') === "smm_RescaledMeasureRelationship") {
					                relToBeChanged.set('from', measureReplaced);
					            } else {
					                relToBeChanged.set('to', measureReplaced);
					            }
					        }
					        if (changeFromRankToGrade) {
					            destroyRelation.push(relToBeChanged);
					        }
					    });
					}

					if(destroyRelation.length > 0 && changeFromRankToGrade){
						var len = destroyRelation.length;
						while(len--){
							destroyRelation[len].destroy();
						}
					}
				}
            });
            if (DataManager.getDataManager().get('debugMode') && measureReplaced && this.getRelationDetails && measureReplaced.get('type') == 'smm_CollectiveMeasure' ) {
                this.set("expression", this.getRelationDetails()['expression']);
            }
			return measureReplaced;						
        } else {
            if (DataManager.getDataManager().get('debugMode') && measureReplaced && this.getRelationDetails && measureReplaced.get('type') == 'smm_CollectiveMeasure' ) {
                this.set("expression", this.getRelationDetails()['expression']);
            }
			return measureReplaced;
		}
	};
	
	getViewAlternative(){
		var viewAlternative;
		var observation = this.get('observedMeasure').get('observation');
		if(!observation) {
			return;
		}
		var observationContext = observation.get('observationContext');
		if(observationContext){
			while(observationContext.get('parentContext')){
				observationContext = observationContext.get('parentContext');
			}
			var scenarioId = observationContext.get('id');
			var currentPlan = DataManager.getDataManager().get('currentPlan');
			var phases = currentPlan.get('phase');
			for(var i=0;i<phases.length;i++){
				var phase = phases.at(i);
				var alts = phase.get('phaseAlternative');
				for(var j=0;j<alts.length;j++){
					var alt = alts.at(j);
					var scenarios = alt.get('designScenario');
					for(var k=0;k<scenarios.length;k++){
						var scenario = scenarios.at(k);
						if(scenario.get('scenario') === scenarioId){
							return alt;
						}
					}
				}
			}
		}else{
			observationContext = observation.get('phaseObjectiveSet');
			viewAlternative = observationContext.get('phaseObjectiveSetOwner');
			return viewAlternative;
		}
	};

	calculate(skipParentCalculation){
		if(!skipParentCalculation){
			this.calculateParents();	
		}
	};	
	
	getParents(skipPreviousAlternative){
		var self = this;
		var parents = [];
		var rankingRels = self.get('rankingMeasurementRelationship');
		if(rankingRels){
			rankingRels.each(function(rel){
				var fromMst = rel.get('from');
				if(fromMst){
					parents.push(fromMst);
				}
			});
		}
		var gradeRels = self.get('gradeMeasurementRelationship');
		if(gradeRels){
			gradeRels.each(function(rel){
				var fromMst = rel.get('from');
				if(fromMst){
					parents.push(fromMst);
				}
			});
		}
		var rescaleRels = self.get('rescaleTo');
		if(rescaleRels){
			rescaleRels.each(function(rel){
				var toMst = rel.get('to');
				if(toMst){
					parents.push(toMst);
				}
			});
		}	

		var baseNRels = self.get('baseNMeasurementRelationship');
		if(baseNRels){
			var skipMes = [];
			if(skipPreviousAlternative && self.get('observedMeasure')){
				var measure = self.get('observedMeasure').get('measure');
				var mesRels = measure?measure.get('baseMeasureFrom'):[];
				mesRels.each(function(mesRel){
					if(mesRel.get('usePreviousAlternative')){
						skipMes.push(mesRel.get('from'));
					}
				});
			}			
			baseNRels.each(function(rel){
				var fromMst = rel.get('from');
				if(fromMst){
					if(skipPreviousAlternative && fromMst.get('observedMeasure')){
						var fromMes = fromMst.get('observedMeasure').get('measure');
						if(fromMes && _.indexOf(skipMes,fromMes) < 0){
							parents.push(fromMst);	
						}
					}else{
						parents.push(fromMst);	
					}
					
				}
			});
		}
		/*var base1Rels = self.get('baseMeasurement1From');
		if(base1Rels){
			base1Rels.each(function(rel){
				var fromMst = rel.get('from');
				if(fromMst){
					parents.push(fromMst);
				}
			});
		}
		var base2Rels = self.get('baseMeasurement2From');
		if(base2Rels){
			base2Rels.each(function(rel){
				var fromMst = rel.get('from');
				if(fromMst){
					parents.push(fromMst);
				}
			});
		}*/
		return parents;
	};
	
	calculateParents(){
		var self = this;
		var skipParents = []
		var skipParentPaths;
		//var measurand = self.get('measuredCharacteristic').getMeasurand();
		//if(measurand.getCyclicPath){
			//skipParents = measurand.getCyclicPath();
		//}
		skipParentPaths = self.getCyclicPath();
		_.each(skipParentPaths,function(path){
			skipParents = skipParents.concat(path);
		});
		
		var parents = self.getParents();
		for(var i=0;i<parents.length;i++){
			var measurementParent = parents[i];
			var parentMeasChar = measurementParent.get('measuredCharacteristic');
			if(!parentMeasChar){
				continue;
			}
			var parentElement = parentMeasChar.getMeasurand();
			//if(skipParents && _.indexOf(skipParents,parentElement) >=0){
			if(skipParents && _.indexOf(skipParents,measurementParent) >=0){
				continue;
			}
			var parentCyclicPath = measurementParent.getCyclicPath();
			if(parentElement && parentElement.get('isIterative') && parentCyclicPath && parentCyclicPath.length>0){
				var cyclicPaths = parentElement.getCyclicPath();
				var viewAlternative = self.getViewAlternative();
				if(cyclicPaths && cyclicPaths.length>0){
					var cmTimeStamp = parentCyclicPath[0][0].get('observedMeasure').get('observation').get('whenObserved');
					for(var k=0;k<cyclicPaths.length;k++){
						var cyclicPath = cyclicPaths[k];
						for(var j=0;j<cyclicPath.length;j++){
							var cyclicElement = cyclicPath[j];
							if(viewAlternative) {
								var cycleElementPackage = cyclicElement.getNestedParent();
								if(window.utils.getPrefix(cyclicElement.id) != window.plansKey && !viewAlternative.get('phaseDesignPart').findWhere({'beepReference':cycleElementPackage.id})){
									continue;
								}
								var eleScenario = viewAlternative.getMainScenario(cycleElementPackage);
								cyclicElement.calculate(eleScenario, cmTimeStamp);
							}
						}
					}
				}
			}else{
				measurementParent.calculate();	
			}
		}
	};
	
	getDependentMeasurements(callback){
		if(callback){
			callback(this.getParents());	
		}
	
/*		DataManager.getDataManager().getMeasurementParents(this.get('id'),function(parents){
			var measurementsParents = [];
			async.eachSeries(parents,function(measurementObj,measurementHandledCallback){
				var repId = DataManager.getDataManager().getRepositoryId(measurementObj.parent);
				var vdmStore = DataManager.getDataManager().getRDFModel(repId);
				DataManager.getDataManager().fetchDocumentFromPackage(measurementObj.parent,"appbo/vdml/ValueDeliveryModel",measurementObj.version,measurementObj.id,measurementObj.type,vdmStore,{
					success:function(measurementParent){
						measurementsParents.push(measurementParent);
						measurementHandledCallback();
					},
					error:function(){
						measurementHandledCallback();
					}
				});					
			},function(){
				callback(measurementsParents);
			});
		});	*/	
	};
	
	//TODO convery to async
	copyBaseMeasurementRelationships(measurementCopy,copiedMeasurements,callback,sourceAlt,ownedPackageList){
		var self = this;		
		var replaceMeasure = false;
		var measure = this.get('observedMeasure').get('measure');
		var measRels = measure.get('measureRelationships');
		var msmtRels = this.get('measurementRelationships');
		if(!measurementCopy.get('observedMeasure')){
			callback();
			return;
		}
		var copyAlt  = measurementCopy.get('observedMeasure').get('observation').getContext().getAlternative();
		var measuresToBeReplaced = {};
		
		async.each(msmtRels.models,function(msmtRel,relationHandled){
			var childMeas;
			var childMest;
			var rel = null;
			if(msmtRel.get('type') === 'smm_RescaledMeasurementRelationship'){
				childMest = msmtRel.get('from');
			}else{
				childMest = msmtRel.get('to');
			}
			if(childMest && childMest.get('observedMeasure')){
				var dimMes = childMest.get('observedMeasure').get('measure');
			}
			var rel = msmtRel.get('definition');
			if(!rel){
				_.each(measRels.models,function(mesRel){
					if(mesRel.get('to') === dimMes){
						var rescaledMeasure = mesRel.get('to').get('rescaledMeasure');
		                var relRescaledMeasure = dimMes.get('rescaledMeasure');

		                var offset = rescaledMeasure ? rescaledMeasure.get('offset') : 0;
		                var multiplier = rescaledMeasure ? rescaledMeasure.get('multiplier') : 1;
		                var operation = rescaledMeasure ? rescaledMeasure.get('operation') : null;

		                var reloffset = relRescaledMeasure ? relRescaledMeasure.get('offset') : 0;
		                var relmultiplier = relRescaledMeasure ? relRescaledMeasure.get('multiplier') : 1;
		                var reloperation = relRescaledMeasure ? relRescaledMeasure.get('operation') : null;

		                if (offset === reloffset && multiplier === relmultiplier && operation === reloperation) {
							rel = mesRel;
						}
					}
				});				
			}
			if(!rel){
				for(var i=0;i< measRels.length;i++){
					var measRel = measRels.at(i);
					if(measRel.get('type') === 'smm_RescaledMeasureRelationship'){
						childMeas = measRel.get('from');
					}else{
						childMeas = measRel.get('to');
					}
					var childMestMC = childMest.get('measuredCharacteristic');
					var childMestMCId = childMestMC?childMestMC.get('id'):null;
					var childCharSuffix = null;
					if(childMestMCId){
						childCharSuffix = childMestMCId.substr(childMestMCId.lastIndexOf('@')+1);
					}
	
					var bMeasureObsMeasures = childMeas?childMeas.get('observedMeasure'):[];
					for(var j=0;j<bMeasureObsMeasures.length;j++){
						var obsMes = bMeasureObsMeasures.at(j);
						var mestChar = obsMes.get('measurements').at(0).get('measuredCharacteristic');
						var mestCharId = mestChar.get('id');
						var mestCharSuffix = mestCharId.substr(mestCharId.lastIndexOf('@')+1);
						if(mestCharSuffix !== childCharSuffix){
							continue;
						}
						rel = measRel;
						break;
					}
					if(rel){
						break;
					}
				}
			}			
			if(rel && !rel.get('usePreviousAlternative')){
				var from = msmtRel.get('from');
				var to = msmtRel.get('to');
				var msrmtBase = to;
				if(msrmtBase === self){
					msrmtBase = from;
				}
				
				var msrmtBaseCopy;
				for(var i=0;i<copiedMeasurements.length;i++){
					if(msrmtBase && copiedMeasurements[i].measurementCopy.get('measuredCharacteristic') === msrmtBase.get('measuredCharacteristic')){
						msrmtBaseCopy = copiedMeasurements[i].measurementCopy;
						copiedMeasurements.splice(i, 1);
						break;
					}
				}
				var usePreviousAlternative = rel.get('usePreviousAlternative');
				if(!msrmtBaseCopy && !usePreviousAlternative && msrmtBase){
					var context = msrmtBase.get('observedMeasure').get('observation').getContext();	
					if(context.get('type') === 'transformation_PhaseObjectiveSet'){
						var contextAlt = context.get('phaseObjectiveSetOwner');
						//var copyAlt = measurementCopy.get('observedMeasure').get('observation').getContext().getAlternative();
						if(copyAlt !== contextAlt){
							var phaseObjectiveSet = copyAlt.get('phaseObjectiveSet');
							var copyAltObsMes = phaseObjectiveSet ? phaseObjectiveSet.getObservedMeasureWithMeasurements(msrmtBase.get('measuredCharacteristic')) : null;
							if(!copyAltObsMes){
								relationHandled();
								return;
							}else{
								var copymeasurements = copyAltObsMes.get('measurements');
								if(copymeasurements.length > 0){
									msrmtBaseCopy = copyAltObsMes.get('measurements').at(0);	
								}
							}			
						}
					}
				}
				var relId = DataManager.getDataManager().guidGeneratorByOwner(measurementCopy);
				var relCopy = new msmtRel.constructor({id:relId,name:msmtRel.get('name'),description:msmtRel.get('description'),measurementRelationshipsOwner:measurementCopy});
				relCopy.set('definition',msmtRel.get('definition'));
				if(msrmtBaseCopy){
					if(msrmtBase === to){
						relCopy.set({'from':measurementCopy,'to':msrmtBaseCopy});
					}else{
						relCopy.set({'from':msrmtBaseCopy,'to':measurementCopy});
					}
				}else{
					var targetMest = msrmtBase;
					if(!usePreviousAlternative) {
						var copyScearioMsrmts = msrmtBase ? msrmtBase.get('measuredCharacteristic').get('measurement'):[];
						for(var i=0;i<copyScearioMsrmts.length;i++){
							if(copyScearioMsrmts.at(i).get('observedMeasure').get('observation').getContext().getAlternative() === copyAlt){
								targetMest = copyScearioMsrmts.at(i);
								break;
							}
						}
					}
					if(msrmtBase === to){
						relCopy.set('to',targetMest);
						relCopy.set('from',measurementCopy);	
					}else{
						relCopy.set('to',measurementCopy);
						relCopy.set('from',targetMest);
					}				
				}
				if(relCopy.get('type') === 'smm_BaseNMeasurementRelationship'){
					relCopy.get('from').get('baseMeasurements').add(relCopy.get('to'));
					var toMeasure = relCopy.get('to')?relCopy.get('to').get('observedMeasure').get('measure'):null;
					if(toMeasure && relCopy.get('definition') && relCopy.get('definition').get('to') != toMeasure){
						relCopy.get('definition').set('to',toMeasure);
						console.log('fixed');
					}					
					if(sourceAlt && DataManager.getDataManager().getRepositoryId(relCopy.get('from').id) === window.plansKey &&
						DataManager.getDataManager().getRepositoryId(relCopy.get('to').id) !== window.plansKey && ownedPackageList.indexOf(relCopy.get('to').getNestedParent()) >= 0){
						//TODO add new measure relationship also
						var relId = DataManager.getDataManager().guidGeneratorByOwner(rel);
						var mesRelCopy = new rel.constructor({id:relId,name:rel.get('name'),description:rel.get('description'),measure:rel.get('measure')});
						mesRelCopy.set('from',rel.get('measure'));
						var targetMeasureSuffix = rel.get('to').id.substr(rel.get('to').id.lastIndexOf('@') + 1);
						var prefix = sourceAlt.id;
						var targetMeasure = Backbone.Relational.store.getObjectByName('smm.DimensionalMeasure').find({id:prefix + targetMeasureSuffix});
                        mesRelCopy.set('to', targetMeasure);
                        if (rel.get('rescaledMeasure')) {
                            var rescaledMes = rel.get('rescaledMeasure');
                            var rescaledMesId = DataManager.getDataManager().guidGeneratorByOwner(rel);
                            var rescaledMesureCopy = new rescaledMes.constructor({ id: rescaledMesId, name: rescaledMes.get('name'), description: rescaledMes.get('description'), rescaledMeasureOwner: mesRelCopy });
                            rescaledMesureCopy.set('multiplier', rescaledMes.get('multiplier'));
                            rescaledMesureCopy.set('offset', rescaledMes.get('offset'));
                            rescaledMesureCopy.set('operation', rescaledMes.get('operation'));
                        }
					} 
				}
				relationHandled();
			}else{
				var relId = DataManager.getDataManager().guidGeneratorByOwner(measurementCopy);
				if(msmtRel.get('type') === "smm_GradeMeasurementRelationship" &&  msmtRel.get('from') && !msmtRel.get('to')){//for gradeOnly
					var relCopy = new msmtRel.constructor({id:relId,name:msmtRel.get('name'),description:msmtRel.get('description'),measurementRelationshipsOwner:measurementCopy});
					relCopy.set('definition',msmtRel.get('definition'));
					relCopy.set('from',measurementCopy);
				}
				var usePreviousAlternative = rel?rel.get('usePreviousAlternative'):null;
				if(!usePreviousAlternative && !rel){
					relationHandled();
					return;
				}
				var measurand = measurementCopy.get('measuredCharacteristic').getMeasurand();
				if(measurand.get('type') === 'vdml_ValueElement' || measurand.get('type') === 'vdml_ValuePropositionComponent' || measurand.get('type') === "vdml_ValueAdd"){
					var childComponents = measurand.get('aggregatedFrom');
					var context = measurementCopy.get('observedMeasure').get('observation').getContext();
					var contextPack = context.getNestedParent();
					while(context.get('parentContext')){
						context = context.get('parentContext');
					}
					var contextAlt = context.getAlternative();
					var prevAlt = contextAlt.getPreviousPhasePrimary();
					
					if(prevAlt){
						prevAlt.getModelLinked(contextPack,function(linkedPack){
							var prevContext = prevAlt.getMainScenario(linkedPack);
							if(prevContext){
								var prevObsMeasure;
								var prevCompVM;
								for(var i=0;i<childComponents.length;i++){
									var linkedPackageAltId = DataManager.getDataManager().getRepositoryId(childComponents.at(i).get('id'));
									var linkedComponent;
									var compVM;
									var tempObsMes;
									if(linkedPackageAltId === window.plansKey) {
										linkedComponent = childComponents.at(i);
										compVM = linkedComponent.get('valueMeasurement');
										tempObsMes = prevAlt.get('phaseObjectiveSet').getObservedMeasureWithMeasurements(compVM);
									}else {
										linkedComponent = linkedPack.getPreviousComponent(childComponents.at(i));
										compVM = linkedComponent.get('valueMeasurement');
										tempObsMes = prevContext.getObservedMeasureWithMeasurements(compVM);
									}
									/*var compId = childComponents.at(i).get('id');
									var modelSuffix = compId.substr(compId.lastIndexOf('@')+1);
									var linkedPackageAltId = DataManager.getDataManager().getRepositoryId(childComponents.at(i).get('id'));
									var linkedComponent = childComponents.at(i).constructor.find({id:linkedPackageAltId + modelSuffix});*/
										
									if(tempObsMes){
										var tempObsMesMCId = tempObsMes.get('measurements').at(0).get('measuredCharacteristic').get('id');
										var tempObsCharSuffix = tempObsMesMCId.substr(tempObsMesMCId.lastIndexOf('@')+1);
										var childMestMCId = childMest.get('measuredCharacteristic').get('id');
										var childCharSuffix = childMestMCId.substr(childMestMCId.lastIndexOf('@')+1);
										if(tempObsCharSuffix === childCharSuffix){
											prevObsMeasure = tempObsMes;
											prevCompVM = compVM;
											break;										
										}
									}
								}
								if(prevObsMeasure){
									var mest = prevObsMeasure.get('measurements').at(0);
									if(mest){
										var relName = measurementCopy.getUniqueProperty('name',mest.get('name') + "Base",'baseMeasurementTo');
										var baseRel = measurementCopy.createBaseMesurementRelationship(relName,relName,mest,rel);
										var newMeasure = mest.get('observedMeasure').get('measure');
										var oldMeasureId = measurementCopy.getMeasureRelationForMeasurementRelation(baseRel).get('to').id;
										if(!replaceMeasure && newMeasure.id !== oldMeasureId){
											replaceMeasure = true;
											measuresToBeReplaced[oldMeasureId] = newMeasure;
										}
										//measurementCopy.calculate();
									}
								}
							}
							relationHandled();
						});
					}else{
						relationHandled();
					}
				}else{
					relationHandled();
				}
			}			
		},function(err){
			if(replaceMeasure){
/*				measurementCopy.replaceMeasureToHandleChangedPreviousAltMeasure(measuresToBeReplaced,function(){
					measurementCopy.calculate();
					if(callback){
						callback();
					}						
				});*/
				if(callback){
					callback({measurement:measurementCopy,measures:measuresToBeReplaced});
				}				
			}else{
				//measurementCopy.calculate();
				if(callback){
					callback();
				}				
			}
		});
	};
	replaceMeasureToHandleChangedPreviousAltMeasure(measuresToBeReplaced){
		var measureParent;
		var measurementParent = this;
		measureParent = measurementParent.get('observedMeasure').get('measure');	

		var parentLib = measureParent.get('libraries');
		var parentCharToMatch = measureParent.get('trait').get('name');
		var parentUnitToMatch;
		if(measureParent.get('unit')){
			parentUnitToMatch = {unitName:measureParent.get('unit').get('name'), roundingDigits:measureParent.get('unit').get('roundingDigits'), significantDecimals:measureParent.get('unit').get('significantDecimals')};
		}
		var parentValFormula = measureParent.get('formula');
		var parentAcc = measureParent.get('accumulator');
		var parentTuples;
		var satisfactionTuple;
		var intervalTupleSet;
		if(measurementParent.get('type') === 'smm_CollectiveMeasurement'){
			parentTuples = measurementParent.get('measuredCharacteristic').getMeasurand().constructBaseMeasureTupleSet( measureParent.get('measuredCharacteristic'),measurementParent.get('observedMeasure').get('observation').getContext());	
		}else{
			satisfactionTuple = {measure:measureParent,type:measureParent.get('type')};
			intervalTupleSet = measureParent.getIntervalTupleSet();
		}
		if(parentTuples){
			for(var i=0;i<parentTuples.length;i++){
				var tuple = parentTuples[i];
				var measureToBeReplaced;
				if(measuresToBeReplaced[tuple.old.measure.id] != null){
					if(!tuple.new){
						tuple.new = {};
					}
					tuple.new.measure = measuresToBeReplaced[tuple.old.measure.id];
					//tuple.old.measuredCharacteristic = measurementDerived.get('measuredCharacteristic');
					tuple.new.measuredCharacteristic = measurementParent.get('measuredCharacteristic');
					measureParent.get('baseMeasureTo').each(function(rel){
						var rescaledMeasure = rel.get('rescaledMeasure');
						var offset = rescaledMeasure ? rescaledMeasure.get('offset') : 0;
		                var multiplier = rescaledMeasure ? rescaledMeasure.get('multiplier') : 1;
		                var operation = rescaledMeasure ? rescaledMeasure.get('operation') : null;
						if(rel.get('to').id === tuple.old.measure.id && offset === tuple.old.offset && multiplier === tuple.old.multiplier && operation === tuple.old.operation){
							if(rescaledMeasure){
								tuple.old.multiplier = rescaledMeasure.get('multiplier');
								tuple.old.offset = rescaledMeasure.get('offset');
								tuple.old.operation = rescaledMeasure.get('operation');
								tuple.new.multiplier = rescaledMeasure.get('multiplier');
								tuple.new.offset = rescaledMeasure.get('offset');
								tuple.new.operation = rescaledMeasure.get('operation');
							}
						}
					});
					break;	// as to avoid the case where we replace all the children with same measure with same new one.
				}
			}
			
		}
		return measurementParent.replaceMeasure(parentLib,measurementParent,parentCharToMatch,parentUnitToMatch,parentValFormula,parentAcc,parentTuples,true,satisfactionTuple,intervalTupleSet,null);
	};
	
//#endCustomMethods
	
	}
	path.MeasurementMixin = MeasurementMixin;
	//return MeasurementMixin;
//});
