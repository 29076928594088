import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as fnon from 'fnon';
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","asyncsimple","Lawnchair","backbone-lawnchair","bootbox","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/ValueDeliveryModel","appbo/transformation/Plan","appbo/preference/UserPreferences"],
//function(require,jQuery,_,Backbone,ko, koMapping,kb,asyncsimple,Lawnchair, backboneLawnchair,bootbox,DataManager,global,ValueDeliveryModel,Plan,UserPreferences){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml.views.correction",global.version);
	export class BusinessModelValidationViewModel{
		
		cleanModal(){
			var self = this;
			window.cleanDialogModel(self.encodeId,self);
        }		

		deleteDisposableMeasures(){
			var self = this;
			var mesLength = self.junkObjects.length;
			while(mesLength--){
				self.junkObjects[mesLength].destroy();
			}
			self.junkMeasureRelationshipDetails([]);
		}

		getComponentMeasurement(component, mc, useScenario, timestamp){
            var measurement = null;
            var mCharacteristic = component.get(mc);
			var isTimeStamp = false;
			if(timestamp){
				isTimeStamp = true;
			}
            var observedMeasure = useScenario.getObservedMeasureWithMeasurements(mCharacteristic, isTimeStamp, timestamp);
            if(observedMeasure){
                measurement = observedMeasure.get('measurements').at(0);
            }
            return measurement;
        };
        
        checkDefinitionAssociation(mest,msmtRelationship){
			var self = this;
        	var definition = msmtRelationship.get('definition');
        	var fromMeasure = msmtRelationship.get('from').get('observedMeasure').get('measure');
        	var toMeasure =  msmtRelationship.get('to')?msmtRelationship.get('to').get('observedMeasure').get('measure'):null;
        	if(definition && (definition.get('from') != fromMeasure || definition.get('to') != toMeasure)) {
        		self.bmValueDetails.push('Mest Name:' + mest.get('name')+ ' ; Mest id:' + mest.id + ' ; Rel Name: '+msmtRelationship.get('name') + ' ; Value name: ' + msmtRelationship.getParent().get('measuredCharacteristic').getParent().get('name') +' ; Owner: ' +msmtRelationship.getParent().get('measuredCharacteristic').getParent().getParent().get('name'));
        	}
        };
        
        checkDefinitionMissing(msmtRelationship){
			var self = this;
        	var definition = msmtRelationship.get('definition');
        	if(!definition){
        		self.definitionMissingDetails.push("Mssing Def:"+msmtRelationship.get('name'));
        	}
        };
        
        getViewAlternativeModel() {
            var self = this;
			var phase = DataManager.getDataManager().get('currentPlan').get('phase');
			var tempAlt = DataManager.getDataManager().get('viewAlternative') ? DataManager.getDataManager().get('viewAlternative'): DataManager.getDataManager().get('currentWorkspace').get("id");
			for(var j=0;j<phase.length;j++){
				var alts = phase.at(j).get('phaseAlternative');
				var viewAlternativeModel = alts.findWhere({'id':tempAlt});
				if(viewAlternativeModel){
					self.currentAlternativeModel = viewAlternativeModel;
					break;
				}
			}    		
    	};  
    	
        checkJunkMeasureRelationShips() {
            var self = this;
        	var libraries = self.model.getNestedParent().get('defaultMetricsModel').get('libraries');
			if(libraries && libraries.length > 0){
				for(var i=0;i<libraries.length;i++){
					var measureElements = libraries.at(i).get('measureElements');
					for(var j=0;j<measureElements.length;j++){
						if(measureElements.at(j).get('type') === 'smm_CollectiveMeasure'){
							var measureRelationship = measureElements.at(j).get('measureRelationships');
							for(var k=0;k<measureRelationship.length;k++){
					        	if(!measureRelationship.at(k).get('to')){
					        		self.junkMeasureRelationshipDetails.push(measureRelationship.at(k).get('name'));
									self.junkObjects.push(measureRelationship.at(k));
					        	}
				        	}
			        	}
		        	}
	        	}
        	}
        };
		
		checkJunkObservedMeasure(observation) {
            var self = this;
			var observedMeasures = observation.get('observedMeasures');
			if(observedMeasures){
				for(var i=0;i<observedMeasures.length;i++){
					var obs = observedMeasures.at(i);
					var mst = obs.get("measurements") ? obs.get("measurements").at(0):null;
					if(mst && mst.get('measuredCharacteristic') == null){
						self.junkMeasureRelationshipDetails.push("mCharacteristic missing:"+obs.get('name'));
						self.junkObjects.push(obs);
					}
				}
			}
        };

        checkJunkMeasurementRelationShips(comp, valueMsmt, msmtRels) {
            var self = this;
			var measure = valueMsmt.get('observedMeasure').get('measure');
			var measureRel = measure.get('measureRelationships');
			var mesaureReused = comp.checkMeasureReuse(measure);
			if(!mesaureReused && measureRel.length != msmtRels.length){
				for(var i=0;i<measureRel.length;i++){
					if(!msmtRels.findWhere({'definition':measureRel.at(i)})){
						self.junkMeasureRelationshipDetails.push(measureRel.at(i).get('name'));
						self.junkObjects.push(measureRel.at(i));
					}
				}
				for(var i=0;i<msmtRels.length;i++){
					if(!msmtRels.at(i).get("to")){
						self.junkMeasureRelationshipDetails.push("Relation to missing:"+msmtRels.at(i).get('name'));
						self.junkObjects.push(msmtRels.at(i));
					}
					if(msmtRels.at(i).get("to") && !msmtRels.at(i).get("to").get('measuredCharacteristic')){
						self.junkMeasureRelationshipDetails.push("Relation to measuredCharacteristic missing:"+msmtRels.at(i).get('name'));
						self.junkObjects.push(msmtRels.at(i).get("to").getParent());
					}
				}
			}
        };
        checkObservedMeasureDuplicate(obsMeasure, comp) {
            var self = this;
			var nameExists = _.filter(obsMeasure.getParent().get('observedMeasures').models, function(obs) { return obs.get('name') == obsMeasure.get('name'); });
			if(nameExists && nameExists.length > 1){
				for(var i=0;i<nameExists.length;i++){
					if(nameExists[i].id != obsMeasure.id){
						self.duplicateObservedMeasure.push('name:' + comp.get('name') +' vpName:'+ comp.getParent().get('name'));
					}
				}
			}			
		}
		
        checkBmValues(timestamp, obsName) {
            var self = this;
			self.checkJunkMeasureRelationShips();
			var values = [];
			var previousPhasePrimary = self.currentAlternativeModel.getPreviousPhasePrimary();
			self.model.getValues(values,true);
			/*if(values[0]){
				var scenario = self.currentAlternativeModel.getMainScenario(values[0].getNestedParent());
				var obs = scenario.get('contextObservation');
				if(obs){
					for(var x=0; x<obs.length; x++){
						self.checkJunkObservedMeasure(obs.at(x));
					}
				}
			}*/
			_.each(values,function(comp){
				var scenario = self.currentAlternativeModel.getMainScenario(comp.getNestedParent());
                var valueMsmt = self.getComponentMeasurement(comp, 'valueMeasurement', scenario, timestamp);
                self.checkValueNameChange(comp);
				if(valueMsmt && !timestamp){
					self.checkObservedMeasureDuplicate(valueMsmt.get('observedMeasure'),comp);
					var valMeasure = valueMsmt.get('observedMeasure').get('measure');
					if(valMeasure.get('type') == "smm_DirectMeasure" && comp.get('aggregatedFrom').length > 0){
						self.definitionMissingDetails.push(" Wrong Direct Measure type: "+comp.get('name'));
					}else if(valueMsmt.get('type') === "smm_CollectiveMeasurement"){
						var msmtRelationships = valueMsmt.get('measurementRelationships');
						if(msmtRelationships.length < 1 && comp.get('aggregatedFrom').length == 0){
							self.definitionMissingDetails.push(" Wrong Collective Measure type: "+valMeasure.get('name'));
						}else {
							self.checkEmptyCalc(valueMsmt);
							self.checkBaseMeasurementMissing(valueMsmt);
                            self.checkValueFormulaMissing(valueMsmt);
                            self.compareValueFormula(valueMsmt);
							self.checkAccumulatorMissing(valueMsmt);
							self.checkMeasureRelationReused(msmtRelationships);
							self.checkUnitMissing(valueMsmt);
							self.checkJunkMeasurementRelationShips(comp,valueMsmt,msmtRelationships);
							for(var i=0;i<msmtRelationships.length;i++){
								self.checkUsePreviousAssociation(msmtRelationships.at(i),previousPhasePrimary);
								self.checkDefinitionMissing(msmtRelationships.at(i));
				        		self.checkDefinitionAssociation(valueMsmt,msmtRelationships.at(i));
				        	}
							var cyclePath = valueMsmt.getCyclicPath();
							if (cyclePath && cyclePath.length > 0 && !comp.get('isIterative')) {
								self.definitionMissingDetails.push(" Wrong Cycle Val: "+comp.get('name'));
							}
						}
					}
					/*if(previousPhasePrimary && !scenario.get("localChange")){
						var prescenario = self.currentAlternativeModel.getMainScenario(comp.getNestedParent());
						var valueMsmt = self.getComponentMeasurement(comp, 'valueMeasurement', prescenario);
					}*/
				}
				var satMsmt = self.getComponentMeasurement(comp,'satisfactionLevel',scenario,timestamp);
				if(satMsmt){
					//var satMeasure = satMsmt.get('observedMeasure').get('measure');
					var valSatisfaction = satMsmt.get('symbol') ? satMsmt.get('symbol') : satMsmt.get('value');
					if(!valSatisfaction) {
						self.satisfactionCalMissing.push(comp.get('name'));
						self.satisfactionMissingFix.push(satMsmt.id);
					}
					var satMsmtRel = satMsmt.get('measurementRelationships');
					if((satMsmt.get('type') == "smm_RankingMeasurement" || satMsmt.get('type') == "smm_GradeMeasurement") && satMsmtRel.length > 0){
						var satMeasure = satMsmt.get('observedMeasure').get('measure');
						var satMsRel = satMeasure.get('measureRelationships');
						var valMeasure = valueMsmt?valueMsmt.get('observedMeasure').get('measure'):null;
						if(satMsmt.get('type') == "smm_RankingMeasurement"){
							if(!satMsmt.get('rankingTo')){
								var msgText = "RankingTo Missing: "+comp.get('name');
								if(timestamp){
									msgText = msgText + " Sc:" + obsName
								}
								self.satisfactionMissingDetails.push(msgText);
								self.satisfactionMissingFix.push(satMsmt.id);
							}
						} else {
							if(!satMsmt.get('gradeTo')){
								var msgText = "gradeTo Missing: "+comp.get('name');
								if(timestamp){
									msgText = msgText + " Sc:" + obsName
								}
								self.satisfactionMissingDetails.push(msgText);
								self.satisfactionMissingFix.push(satMsmt.id);
							}
						}
						for(var i=0;i<satMsRel.length;i++){
							if(!satMsRel.at(i).get('to')) {
								self.satisfactionMeasureDetails.push('Relation to missing:' + satMsRel.at(i).get('name'));
							}
							if(satMsRel.at(i).get('to') && satMsRel.at(i).get('to') != valMeasure) {
								var msgText = 'Satifaction Measure Wrong:' + satMsRel.at(i).get('to').get('name') + " instead of "+valMeasure.get("name")
								if(timestamp){
									msgText = msgText + " Sc:" + obsName
								}
								self.satisfactionMeasureDetails.push(msgText);
							}
						}
						if(valueMsmt) {//to void gr only
							for(var i=0;i<satMsmtRel.length;i++){
								if(!satMsmtRel.at(i).get('to')) {
									self.satisfactionMissingDetails.push('Relation to missing:' + satMsmtRel.at(i).get('name'));
									self.satisfactionMissingFix.push(satMsmt.id);
								}
							}
						}
					} else if(satMsmtRel && satMsmtRel.length == 0){
						self.satisfactionMissingDetails.push('Relation missing:' + comp.get('name'));
						self.satisfactionMissingFix.push(satMsmt.id);
					}
				}
				
				//var weightMsmt = self.getComponentMeasurement(comp,'percentageWeight',scenario);
				//var recipentMsmt = self.getComponentMeasurement(comp,'recipientOpinion',scenario);
			});
		};

		fixSatisfaction(){
			var self = this;
			for (var i = 0; i < self.satisfactionMissingFix.length; i++) {
				var satMstId = self.satisfactionMissingFix[i];
				var satMst = window.utils.getElementModel(satMstId,['smm.RankingMeasurement','smm.GradeMeasurement']);
				if(satMst){
					var satMsmtRel = satMst.get('measurementRelationships');
					var rel = satMsmtRel?satMsmtRel.at(0):null;
					if(rel){
						if(satMst.get('type') == "smm_RankingMeasurement" && !satMst.get('rankingTo')){
							satMst.set('rankingTo',rel);
						}
						else if(!satMst.get('gradeTo')){
							satMst.set('gradeTo',rel);
						}
						else if(satMst.get('gradeTo') && !rel.get('to')){
							var comp = satMst.get('measuredCharacteristic').getParent();
							var scenario = self.currentAlternativeModel.getMainScenario(comp.getNestedParent());
							var valueMsmt = self.getComponentMeasurement(comp, 'valueMeasurement', scenario);
							rel.set('to',valueMsmt);
						}
					} else {
						var comp = satMst.get('measuredCharacteristic').getParent();
						var scenario = self.currentAlternativeModel.getMainScenario(comp.getNestedParent());
                		var valueMsmt = self.getComponentMeasurement(comp, 'valueMeasurement', scenario);
						if(valueMsmt && satMst.get('type') == "smm_RankingMeasurement"){
							var relName = satMst.get('name') + "RankingRel"; 
							satMst.createRankingMesurementRelationship(relName,relName,valueMsmt);	
						}else if(satMst.get('type') == "smm_GradeMeasurement"){
							var relName = satMst.get('name') + "GradeRel";
							satMst.createGradeMesurementRelationship(relName,relName,valueMsmt);
						}
					}
					satMst.calculate();
				}
			}
			self.satisfactionMissingDetails([]);
			self.satisfactionCalMissing([]);
			fnon.Hint.Light("fixed",{displayDuration: 1500});
		}
		
        checkAccumulatorMissing(valueMsmt) {
            var self = this;
			if(valueMsmt.get('measurementRelationships').length > 1) {
				var measure = valueMsmt.get('observedMeasure').get('measure');
				if(measure && !measure.get('accumulator')){
					self.accumulatorMissingDetails.push(valueMsmt.get('measuredCharacteristic').getParent().get('name'));
				}
			}
		};

		checkEmptyCalc(valueMsmt){
			var self = this;
			var mstmRels = valueMsmt.get('measurementRelationships');
			if(mstmRels.length > 0 && (valueMsmt.get('value') == null || valueMsmt.get('value') == "")) {
				self.calcMissingDetails.push(valueMsmt.get('measuredCharacteristic').getParent().get('name'));
			}
		}
		
        checkBaseMeasurementMissing(valueMsmt) {
            var self = this;
			var mstmRels = valueMsmt.get('measurementRelationships');
			if(mstmRels.length != valueMsmt.get('baseMeasurements').length) {
				var msmtToArray = [];
				for(var i=0;i<mstmRels.length;i++){
					var msmtTo = mstmRels.at(i).get('to');
					if(msmtTo){
						msmtToArray.push(msmtTo);
					}
				}
				msmtToArray = _.uniq(msmtToArray);
				if(msmtToArray.length != valueMsmt.get('baseMeasurements').length){
					self.baseMeasurementsMissingDetails.push(valueMsmt.get('measuredCharacteristic').getParent().get('name'));
				}
			}
		};
		
        checkUsePreviousAssociation(msmtRelationship, previousPhasePrimary) {
            var self = this;
			var definition = msmtRelationship.get('definition');
			var usePre = definition? definition.get('usePreviousAlternative') : false;
			if(usePre == "false"){
				definition.set('usePreviousAlternative',false);
				self.usePreviousBoolean.push(msmtRelationship.get('name'));
			}
        	if(definition && usePre && usePre != "false"){
	        	var toMsmt = msmtRelationship.get('to');
	        	var toMeasure = definition.get('to');
	        	if(toMsmt && toMeasure){
	        		var scenario = toMsmt.get('observedMeasure').get('observation').getContext();
	        		var phaseobjectiveSetOwner = scenario.get('phaseObjectiveSetOwner');
	        		var scenarioExists = false;
	        		if(phaseobjectiveSetOwner && phaseobjectiveSetOwner === previousPhasePrimary){
						scenarioExists = true;	        			 
	        		}else {
	        			scenarioExists = previousPhasePrimary.get('designScenario').findWhere({'scenario':scenario.id});
	        		}
	        		if(!scenarioExists || toMsmt.get('observedMeasure').get('measure') !== toMeasure){
						self.usePreviousMissingDetails.push(msmtRelationship.get('name'));	        			
	        		}
	        	}
        	}
		};
		
        checkMeasureRelationReused(msmtRelationships) {	
            var self = this;
			for(var i=0;i<msmtRelationships.length;i++){
				var def = msmtRelationships.at(i).get('definition');
				if(def){
					var dup = _.filter(msmtRelationships.models,function(rel){if(rel != msmtRelationships.at(i) && rel.get('definition') === def){return rel}});
					if(dup && dup.length > 0){
						self.measureRelationReused.push(msmtRelationships.at(i).get('name')+ ' '+msmtRelationships.at(i).id);
					}
				}
        	}
		};

		checkUnitMissing(valueMsmt){
			var self = this;
			var measure = valueMsmt.get('observedMeasure') ? valueMsmt.get('observedMeasure').get('measure') : null;
			if(measure && !measure.get('unit')){
				self.unitMissing.push(valueMsmt.get('measuredCharacteristic').getParent().get('name'))
			}
		}
		
        checkValueFormulaMissing(valueMsmt) {
            var self = this;
			//if(valueMsmt.get('measurementRelationships').length > 1) {
				var measure = valueMsmt.get('observedMeasure').get('measure');
				if(!measure){
					self.measureMissingDetails.push(valueMsmt.get('measuredCharacteristic').getParent().get('name'));
				}
                else if (!measure.get('formula') && !valueMsmt.get('measuredCharacteristic').get('valueFormula') && !valueMsmt.getParent().get('valueFormula')){
					self.valueFormulaMissingDetails.push(valueMsmt.get('measuredCharacteristic').getParent().get('name'));
				}
			//}
        };

		compareValueFormula(valueMsmt) {
			var self = this;
            var mstRel = valueMsmt.get('measurementRelationships');
            if (mstRel.length > 0) {
                var givenFormula = '';
                var expectedFormula = '';
                var measure = valueMsmt.get('observedMeasure').get('measure');
                var acc = measure.get('accumulator');
				if(!acc){
					return;
				}
                var accSymbol = '+';
                if (acc && acc.name == 'product') {
                    accSymbol = '*'
                } else if (acc && acc.name == 'average') {
                    accSymbol = 'Average'
                } else if (acc && acc.name == 'maximum') {
                    accSymbol = 'Maximum'
                } else if (acc && acc.name == 'minimum') {
                    accSymbol = 'Minimum'
                } else if (acc && acc.name == 'standardDeviation') {
                    accSymbol = 'Standard Deviation'
                } else if (acc && acc.name == 'WeightedAverage') {
                    accSymbol = 'Weighted Average'
                }
                if (accSymbol !== '*' && accSymbol !== '+') {
                    expectedFormula = expectedFormula.concat(accSymbol + ' {');
                }
				var removedAcc = false;
                _.each(mstRel.models, function (msmtRel, index, list) {
					var initialOperation = false;
					var brackets = false;
                    var reScale = msmtRel.get('definition')?msmtRel.get('definition').get('rescaledMeasure'):null;
                    if (reScale && reScale.get('operation')) {
                        var opSymbol = '';
                        var opName = reScale.get('operation').get('name');
                        if (opName == 'InvertOperation' || opName == 'invertOperation') {
                            opSymbol = '/';
                            var checkPreviousAccSymbol = expectedFormula[expectedFormula.length - 1];
                            if (checkPreviousAccSymbol && !removedAcc && checkPreviousAccSymbol == '*' || checkPreviousAccSymbol == '+') {
                                expectedFormula = expectedFormula.slice(0, -2);
								removedAcc = true;
                            }
                        } else if (opName == 'SquareOperation' || opName == 'squareOperation') {
                            opSymbol = '*2';
                        } else if (opName == 'SquareRootOperation' || opName == 'squareRootOperation') {
                            opSymbol = 'Sqrt';
                        }
                        if(expectedFormula.length != 0 || opSymbol == 'Sqrt'){
							var checkPreviousAccSymbol = opSymbol == '/' ? expectedFormula[expectedFormula.length - 1]:null;
							if(checkPreviousAccSymbol != "*"){
								expectedFormula = expectedFormula.concat(' ' + opSymbol);
							}
						}else if(mstRel.length == 1 && opSymbol == '/'){
							expectedFormula = (' 1 / (' ).concat(expectedFormula);
							brackets = true;
						} else {
							initialOperation = true;
						}
                    }
					if(msmtRel.get('to') && msmtRel.get('to').get('measuredCharacteristic')) {
						expectedFormula = reScale ? expectedFormula.concat(' ('):expectedFormula;
						expectedFormula = expectedFormula.concat(' ' + msmtRel.get('to').get('measuredCharacteristic').getParent().get('name'));
					}
					if(msmtRel.get('definition') && msmtRel.get('definition').get('usePreviousAlternative')){
						expectedFormula = expectedFormula.concat(' (previous Phase)');
					}
					if(opSymbol && initialOperation && !removedAcc){
						expectedFormula = expectedFormula.concat(' ' + opSymbol);
					}
                    if (reScale) {
						
                        if (reScale.get('multiplier') != 1) {
                            var mul = '' + reScale.get('multiplier');
                            /*if (mul.includes(".") && Number(mul) < 1) {
                                var newMul = '';
                                var multArr = mul.split(".");
                                newMul = newMul.concat(multArr[1][multArr[1].length - 1]);
                                for (var i = 0; i < multArr[1].length; i++) {
                                    newMul = newMul.concat('0');
                                }
								if(mul.charAt(0) == "-"){
									var pos = expectedFormula.lastIndexOf('+');
									if (pos != '-1') {
										expectedFormula = expectedFormula.substring(0, pos) + '-' + expectedFormula.substring(pos + 1);
									} else {
										var pos = expectedFormula.lastIndexOf(''+msmtRel.get('to').get('measuredCharacteristic').getParent().get('name'));
										if (pos != '-1') {
											brackets = true;
											expectedFormula = expectedFormula.substring(0, pos) + '(1- ' + expectedFormula.substring(pos);
										}
									}
								}
								expectedFormula = expectedFormula.concat(' / ' + newMul);
                                
                            } else*/ if (mul == '-1' && accSymbol == '+') {
                                var pos = expectedFormula.lastIndexOf('+');
                                if (pos != '-1') {
                                    expectedFormula = expectedFormula.substring(0, pos) + '-' + expectedFormula.substring(pos + 1);
                                } else {
                                    expectedFormula = expectedFormula.concat(' * ' + reScale.get('multiplier'));
                                }
                                
                            } else {
                                expectedFormula = expectedFormula.concat(' * ' + reScale.get('multiplier'));
                            }
                        }

                        if (reScale.get('offset') != 0) {
                            expectedFormula = expectedFormula.concat(' + ' + reScale.get('offset'));
                        }
						if(brackets){
							expectedFormula = expectedFormula.concat(' )');
						}
						expectedFormula = reScale ? expectedFormula.concat(' )'):expectedFormula;
                    }
                    if (index != list.length - 1) {
                        if (accSymbol == '*' || accSymbol == '+') {
                            expectedFormula = expectedFormula.concat(' ' + accSymbol);
                        } else {
                            expectedFormula = expectedFormula.concat(' ,');
                        }
                    } else {
                        if (accSymbol !== '*' && accSymbol !== '+') {
                            expectedFormula = expectedFormula.concat(' }');
                        }
                    }
                });
                if (measure) {
                    givenFormula = valueMsmt.getParent().get('valueFormula');
                    if (!givenFormula) {
                        givenFormula = valueMsmt.get('measuredCharacteristic').get('valueFormula');
                        if (!givenFormula) {
                            givenFormula = '';
                        }
                    }
                }
                if (givenFormula.trim() != expectedFormula.trim()) {
                    self.compareValueFormulaDetails.push({ 'valueName': valueMsmt.get('measuredCharacteristic').getParent().get('name'), 'givenFormula': givenFormula, 'expectedFormula': expectedFormula });
                }
            }
        }

        checkValueNameChange(comp) {
            var self = this;
            var name1 = comp.get('name');
            var compSuffixId = window.utils.getSuffix(comp.id);
            var name2 = comp.get('name');
            var phase = DataManager.getDataManager().get('currentPlan').get('phase');
            for (var j = 0; j < phase.length; j++) {
                var alts = phase.at(j).get('phaseAlternative').models;
                for (var i = 0; i < alts.length; i++) {
                    var valueModal = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: alts[i].get('id') + compSuffixId });
                    if (!valueModal) {
                        valueModal = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: alts[i].get('id') + compSuffixId });
                    }
                    if (valueModal && name1 != valueModal.get('name')) {
                        self.valueNameChange.push({ 'name1': name1, 'name2': valueModal.get('name') });
                        var nameChange = true;
                        break;
                    }
                }
                if (nameChange){
                    break;
                }
            } 
        }

		checkVP() {
            var self = this;
			var vplist = self.model.get('bmValueProposition').models;
			_.each(vplist,function(bmVP){
				var vp = bmVP.get('valueProposition');
				var vf = vp.get('isValueFormula');
				if(vf == "false"){
					vp.set('isValueFormula',false);
					self.usePreviousBoolean.push(vp.get('name')+ " :valuePropFixed");
				}
			});
		}
		
		afterRenderView(){
			//bootbox.alert('hi');
		};
		init(model, options){
			var self = this;
		    this.BusinessModelValidationViewModel = this;
		    this.model = model;
		    if(options && options.parentView){
			    this.parentView = options.parentView;
			    this.encodeId = ""+options.modalId;
		    }

		    this.bmValueDetails = ko.observableArray();
		    this.accumulatorMissingDetails = ko.observableArray();
		    this.definitionMissingDetails = ko.observableArray();
		    this.valueFormulaMissingDetails = ko.observableArray();
		    this.junkMeasureRelationshipDetails = ko.observableArray();
		    this.baseMeasurementsMissingDetails = ko.observableArray();
			this.calcMissingDetails = ko.observableArray();
			this.satisfactionMissingDetails = ko.observableArray();
			this.satisfactionMeasureDetails = ko.observableArray();
			this.satisfactionMissingFix = [];
		    this.usePreviousMissingDetails = ko.observableArray();
		    this.measureRelationReused = ko.observableArray();
		    this.unitMissing = ko.observableArray();
            this.measureMissingDetails = ko.observableArray();
            this.compareValueFormulaDetails = ko.observableArray();
            this.valueNameChange = ko.observableArray();
		    this.duplicateObservedMeasure = ko.observableArray();
			this.usePreviousBoolean = ko.observableArray();
			this.satisfactionCalMissing = ko.observableArray();
            this.junkObjects = [];
			self.getViewAlternativeModel();
			var scenario = self.currentAlternativeModel.getMainScenario(self.model.getNestedParent());
			var obs = scenario.get('contextObservation');
			if(obs){
				for(var x=0; x<obs.length; x++){
					self.checkJunkObservedMeasure(obs.at(x));
					self.checkBmValues(obs.at(x).get("whenObserved"),obs.at(x).get("name"));
				}
			}			
			//self.checkBmValues();
			self.checkVP();
		}
	
	static getInstance(model,options){
		var view = new BusinessModelValidationViewModel(model,options);
		view.init(model, options);
		return view;
	};
}
path.BusinessModelValidationViewModel = BusinessModelValidationViewModel;