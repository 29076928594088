import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Interval} from './Interval'
import {GradeIntervalMixin} from './GradeIntervalMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/Interval","appbo/smm/GradeIntervalMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Interval,GradeIntervalMixin){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var GradeInterval = Interval.extend(utils.customExtends({
		relations:GradeIntervalMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Interval.prototype.initialize.apply(this, arguments);
		}
		}
		, new GradeIntervalMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	GradeInterval.abstract = false;
	GradeInterval.supported = !sp2Enabled
	path.GradeInterval = GradeInterval;
	export {GradeInterval};
//});