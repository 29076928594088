export class Queue {
    constructor(maxSize) {
        this.items = {};
        this.maxSize = maxSize;
        this.frontIndex = 0;
        this.backIndex = 0;
    }
    enqueue(item) {
        if(this.maxSize && ((this.backIndex - this.frontIndex) >= this.maxSize -1)){
            this.dequeue();
        }
        this.items[this.backIndex] = item;
        this.backIndex++;
        return item + ' inserted';
    }
    dequeue() {
        const item = this.items[this.frontIndex];
        delete this.items[this.frontIndex];
        this.frontIndex++;
        return item;
    }
    peek() {
        return this.items[this.frontIndex];
    }
    get printQueue() {
        return this.items;
    }
    contains(item){
        for(var i=0;i<this.backIndex-1;i++){
            if(this.items[i]== item){
                return true;
            }
        }
        return false;
    }
}