import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurableElement} from './MeasurableElement'
import {ValuePropositionMixin} from './ValuePropositionMixin'
import {ValueProposition2Mixin} from './ValueProposition2Mixin'
import {VDMLCanvasElementMixin} from './VDMLCanvasElementMixin'
import {Role} from './Role'
import {ValuePropositionComponent} from './ValuePropositionComponent'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/ValuePropositionMixin","appbo/vdml/VDMLCanvasElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,ValuePropositionMixin,VDMLCanvasElementMixin
, ValuePropositionComponent
, Role
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ValuePropositionComponent){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValuePropositionComponent){
			ValuePropositionComponent = importPath.ValuePropositionComponent;
		}
		else{
			import('./ValuePropositionComponent').then(loadedModule => {
				ValuePropositionComponent = loadedModule;
			});
			/*require(["appbo/vdml/ValuePropositionComponent"],function(loadedModule){
				ValuePropositionComponent = loadedModule;
			});*/
		}
	}
	if(!Role){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Role){
			Role = importPath.Role;
		}
		else{
			import('./Role').then(loadedModule => {
				Role = loadedModule;
			});
			/*require(["appbo/vdml/Role"],function(loadedModule){
				Role = loadedModule;
			});*/
		}
	}
	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? ValueProposition2Mixin : ValuePropositionMixin;
	}
	var vpMixin = getMixinClass();
	var ValueProposition = MeasurableElement.extend(utils.customExtends({
		relations:vpMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			MeasurableElement.prototype.initialize.apply(this, arguments);
			//this.on("change:name", this.handleVPNameChange)
		}
		}
		, new VDMLCanvasElementMixin()
		, new vpMixin()
	));
    ValueProposition["change:name"] = function (model, newName) {
		var previousName = model._previousAttributes ? model._previousAttributes.name:"";
		if(previousName != newName) {
			this.handleVPNameChange(model,newName);
		}
    } 
	ValueProposition.abstract = false;
	path.ValueProposition = ValueProposition;
	export {ValueProposition};
//});