import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BaseMeasureRelationship} from './BaseMeasureRelationship'
import {Base1MeasureRelationshipMixin} from './Base1MeasureRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
//import { DimensionalMeasure } from './DimensionalMeasure'
//import { BinaryMeasure } from './BinaryMeasure'


//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/BaseMeasureRelationship","appbo/smm/Base1MeasureRelationshipMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BaseMeasureRelationship,Base1MeasureRelationshipMixin
//, BinaryMeasure
//, DimensionalMeasure){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	//if(!BinaryMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.BinaryMeasure){
			BinaryMeasure = importPath.BinaryMeasure;
		}
        else {
            import('./BinaryMeasure').then(({ default: BinaryMeasure }) => {
                BinaryMeasure = BinaryMeasure;
            });

			/*require(["appbo/smm/BinaryMeasure"],function(loadedModule){
				BinaryMeasure = loadedModule;
			});*/
		}
	//}
	//if(!DimensionalMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasure){
			DimensionalMeasure = importPath.DimensionalMeasure;
		}
		else {
            import('./DimensionalMeasure').then(({ default: DimensionalMeasure }) => {
              //  DimensionalMeasure = loadedModule;
        });

			/*require(["appbo/smm/DimensionalMeasure"],function(loadedModule){
				DimensionalMeasure = loadedModule;
            });*/
		}
	//}
	var Base1MeasureRelationship = BaseMeasureRelationship.extend(utils.customExtends({
		relations:Base1MeasureRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BaseMeasureRelationship.prototype.initialize.apply(this, arguments);
			if(attributes.influence){
				this.set('influence',path.Influence[attributes.influence instanceof Object ? attributes.influence.name : attributes.influence]);
			}
		}
		}
		, new Base1MeasureRelationshipMixin()
	));
	Base1MeasureRelationship.abstract = false;
	Base1MeasureRelationship.supported = false;	// as we are not using it yet
	path.Base1MeasureRelationship = Base1MeasureRelationship;
	export {Base1MeasureRelationship};
//});