import * as _ from 'underscore'
import { BeepPackage } from "../beeppackage/BeepPackage";
import { BeepPackageMixin } from "../beeppackage/BeepPackageMixin";
import {DataManager} from '../../../com/vbee/data/DataManager'
import { PeriodDataset } from './PeriodDataset';


export class ScenarioExecutionMixin{
    constructor(){
        var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
        path.ScenarioExecutionMixin = ScenarioExecutionMixin;
    }
    defaults(){
		var ret = {
			type: "transformation_ScenarioExecution",
			version: DataManager.getDataManager().get('currentVDMVersion'),
			//changeSetEnabled: false
		}
		return jQuery.extend(BeepPackage.prototype.defaults.apply(this),ret);
	}
    static getMixinRelations(){
		return _.union([
		{
		    type: Backbone.HasMany,
		    containingClass: "transformation_ScenarioExecution",
		    key: "input",
		    relatedModel: "transformation.PeriodDataset",
			includeInJSON: "id",
			reverseRelation: {
		        key: "inputOwner",
		        type: Backbone.HasOne,
		        includeInJSON: "id"
		    }
		},
        {
		    type: Backbone.HasMany,
		    containingClass: "transformation_ScenarioExecution",
		    key: "result",
		    relatedModel: "transformation.PeriodDataset",
			includeInJSON: "id",
			reverseRelation: {
		        key: "resultOwner",
		        type: Backbone.HasOne,
		        includeInJSON: "id"
		    }
		}
		]);
	};
	static getCumulativeMixinRelations(){
		if (!ScenarioExecutionMixin.cummulativeRelations) {
            ScenarioExecutionMixin.cummulativeRelations = _.union(ScenarioExecutionMixin.getMixinRelations()
                , BeepPackageMixin.getCumulativeMixinRelations()
            );
        }
		return ScenarioExecutionMixin.cummulativeRelations.slice();
	};
	static getSuperTypes(){
		return [
			"beeppackage_BeepPackage"
		];
	}
	static getProperties(){
		return [
			{name : "version",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "label",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "parentLabel",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "syncedChangeLog",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "synchedChangeSet",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			//{name : "changeSetEnabled",type : "EBoolean",defaultValue : false,containingClass : "transformation_ScenarioExecution" },
			{name : "scenarioType",type : "ScenarioType",defaultValue : "null",containingClass : "transformation_ScenarioExecution" }
		]
	}
	getParent(){
		return this;	
    }
	getCalculationChangeType(operationType){
		return 2;
	}
	getCalculationChangeArtifact(operationType){
		return this.id;
	}

	getPerioDatasetObject(periodDatasetJson){
		var dataManager = DataManager.getDataManager();
		dataManager.set('suppressLogging', true);
		dataManager.set('suppressChangeLogging', true);
		var objType = Backbone.Relational.store.getObjectByName("transformation.PeriodDataset");
		var copyPck = new objType(periodDatasetJson, { silent: true });
		dataManager.set('suppressLogging', false);
		dataManager.set('suppressChangeLogging', false);
		return copyPck;
	}
}