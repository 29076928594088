import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from '../vdml/VdmlElement'
import {VdmlElementMixin} from '../vdml/VdmlElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("instdef",global.version);

export class LookupTableInstanceMixin {

    defaults(){
        var ret = {
            type: "instdef_LookupTableInstance"
        }
        return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
    }
    static getMixinRelations(){
        return _.union([
        ])
    }
    
    static getCumulativeMixinRelations(){
        if (!LookupTableInstanceMixin.cummulativeRelations) {
            LookupTableInstanceMixin.cummulativeRelations = _.union(LookupTableInstanceMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
        return LookupTableInstanceMixin.cummulativeRelations.slice();
    }
    static getSuperTypes(){
        return [
            "vdml_VdmlElement"
        ];
    }
    static getProperties(){
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            {name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
            {name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
        ]
    }
    getParent(){
        var container;
		if(!container){
			container = this.get("lookupTableInstanceOwner") ? this.get("lookupTableInstanceOwner") : this.previousAttributes().lookupTableInstanceOwner;
			if(container){
				return container;
			}
		}
    }
    getPackagePath(path){
        if(!path){
            path = [];
        }
        return VdmlElement.prototype.getPackagePath.apply(this, arguments);
    }
    getViewProperties(type){
        return {
            templatePath : "views/vdml/views/properties/LookupTableInstancePropertiesTemplate.html",
            templateName : "LookupTableInstancePropertiesTemplate",
            viewTypeStr : "appviews/vdml/views/properties/LookupTableInstanceViewModel",
            tabId : "LookupTableInstanceView",
            tabName: "LookupTableInstance"
        }
    }

}
path.LookupTableInstanceMixin = LookupTableInstanceMixin;
