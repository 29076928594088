import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {ValueElement} from './ValueElement'
import {ValueAddMixin} from './ValueAddMixin'
import {ValueAdd2Mixin} from './ValueAdd2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/ValueElement","appbo/vdml/ValueAddMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,ValueElement,ValueAddMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? ValueAdd2Mixin : ValueAddMixin;
	}
	var valueAddMixinClass = getMixinClass();
	var ValueAdd = ValueElement.extend(utils.customExtends({
		relations:valueAddMixinClass.getMixinRelations(),
		initialize: function(attributes, options) {
			ValueElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new valueAddMixinClass()
	));
	utils.customExtendStaticMethods(ValueAdd,valueAddMixinClass);
	ValueAdd.abstract = false;
	path.ValueAdd = ValueAdd;
	export {ValueAdd};
//});