import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmElement} from './SmmElement'
import {IntervalMixin} from './IntervalMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/IntervalMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,IntervalMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var Interval = SmmElement.extend(utils.customExtends({
		relations:IntervalMixin.getMixinRelations(),
		subModelTypes: {
			smm_RankingInterval : 'smm.RankingInterval',
			smm_GradeInterval : 'smm.GradeInterval'
		},
		initialize: function(attributes, options) {
			SmmElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new IntervalMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	Interval.abstract = false;
	Interval.supported = !sp2Enabled;
	path.Interval = Interval;
	export {Interval};
//});