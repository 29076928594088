import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BMCanvasItemMixin} from './BMCanvasItemMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {VdmlElement} from '../vdml/VdmlElement'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/canvas/BMCanvasItemMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,BMCanvasItemMixin
//){
	
	var path = DataManager.getDataManager().buildAppNsPath("canvas",global.version);
	var BMCanvasItem = VdmlElement.extend(utils.customExtends({
		relations:BMCanvasItemMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new BMCanvasItemMixin()
	));
	BMCanvasItem.abstract = false;
	path.BMCanvasItem = BMCanvasItem;
	export {BMCanvasItem};
//});