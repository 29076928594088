import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BeepPackage} from '../beeppackage/BeepPackage'
import {BeepPackageMixin} from '../beeppackage/BeepPackageMixin'
import {PackageReference} from '../../../com/vbee/filesystem/PackageReference'
import {ScenarioProxy} from '../transformation/ScenarioProxy'
import {MeasuredCharacteristic} from './MeasuredCharacteristic'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {SmmModel} from '../smm/SmmModel'
import {ValueLibrary} from './ValueLibrary'
import {CapabilityLibrary} from './CapabilityLibrary'
import {BusinessItemLibrary} from './BusinessItemLibrary'
//import {Actor} from './Actor'
import {PracticeLibrary} from './PracticeLibrary'
import {Scenario} from './Scenario'
import {Collaboration} from './Collaboration'
import {BusinessModel} from './BusinessModel'
import {CollaborationDiagram} from './CollaborationDiagram'
import {ObjectReference} from './ObjectReference'
import {RoleLibrary} from './RoleLibrary'
import {OrgUnit} from './OrgUnit'
import {MeasureLibrary} from '../smm/MeasureLibrary'
import {ValueStream} from './ValueStream'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/beeppackage/BeepPackage","appbo/beeppackage/BeepPackageMixin", "appbo/transformation/ScenarioProxy","appcommon/com/vbee/filesystem/PackageReference","appbo/transformation/PackageReference", "appbo/vdml/MeasuredCharacteristic"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BeepPackage,BeepPackageMixin,ScenarioProxy,PackageReference,PlanPackageReference,MeasuredCharacteristic
, VdmlElement
, VdmlElementMixin
, SmmModel
, ValueLibrary
, CapabilityLibrary
, BusinessItemLibrary
, Actor
, PracticeLibrary
, Scenario
, Collaboration
, BusinessModel
, CollaborationDiagram
, ObjectReference
, RoleLibrary
, OrgUnit
, MeasureLibrary
, ValueStream){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!VdmlElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.VdmlElement){
			VdmlElement = importPath.VdmlElement;
		}
		else{
			import('./VdmlElement').then(loadedModule => {
				VdmlElement = loadedModule;
			});
			/*require(["appbo/vdml/VdmlElement"],function(loadedModule){
				VdmlElement = loadedModule;
			});*/
		}
	}
	if(!VdmlElementMixin){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.VdmlElementMixin){
			VdmlElementMixin = importPath.VdmlElementMixin;
		}
		else{
			import('./VdmlElementMixin').then(loadedModule => {
				VdmlElementMixin = loadedModule;
				});
				/*require(["appbo/vdml/VdmlElementMixin"], function (loadedModule) {
					VdmlElementMixin = loadedModule;
				});*/
		}
	}
	
	if(!ValueLibrary){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueLibrary){
			ValueLibrary = importPath.ValueLibrary;
		}
		else{
			import('./ValueLibrary').then(loadedModule => {
					ValueLibrary = loadedModule;
				});
				/*require(["appbo/vdml/ValueLibrary"], function (loadedModule) {
					ValueLibrary = loadedModule;
				});*/
		}
	}
	if(!CapabilityLibrary){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityLibrary){
			CapabilityLibrary = importPath.CapabilityLibrary;
		}
		else{
			import('./CapabilityLibrary').then(loadedModule => {
				CapabilityLibrary = loadedModule;
				});
				/*require(["appbo/vdml/CapabilityLibrary"], function (loadedModule) {
					CapabilityLibrary = loadedModule;
				});*/
		}
	}
	if(!BusinessItemLibrary){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemLibrary){
			BusinessItemLibrary = importPath.BusinessItemLibrary;
		}
		else{
			import('./BusinessItemLibrary').then(loadedModule => {
				BusinessItemLibrary = loadedModule;
				});
				/*require(["appbo/vdml/BusinessItemLibrary"], function (loadedModule) {
					BusinessItemLibrary = loadedModule;
				});*/
		}
	}
	//if(!Actor){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Actor){
		//	Actor = importPath.Actor;
		}
		else{
			import('./Actor').then(loadedModule => {
			//		Actor = loadedModule;
				});
				/*require(["appbo/vdml/Actor"], function (loadedModule) {
					Actor = loadedModule;
				});*/
		}
	//}
	if(!PracticeLibrary){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PracticeLibrary){
			PracticeLibrary = importPath.PracticeLibrary;
		}
		else{
			import('./PracticeLibrary').then(loadedModule => {
				PracticeLibrary = loadedModule;
				});
				/*require(["appbo/vdml/PracticeLibrary"], function (loadedModule) {
					PracticeLibrary = loadedModule;
				});*/
		}
	}
	if(!Scenario){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Scenario){
			Scenario = importPath.Scenario;
		}
		else{
			import('./Scenario').then(loadedModule => {
				Scenario = loadedModule;
				});
				/*require(["appbo/vdml/Scenario"], function (loadedModule) {
					Scenario = loadedModule;
				});*/
		}
	}
	if(!Collaboration){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Collaboration){
			Collaboration = importPath.Collaboration;
		}
		else{
			import('./Collaboration').then(loadedModule => {
				Collaboration = loadedModule;
				});
				/*require(["appbo/vdml/Collaboration"], function (loadedModule) {
					Collaboration = loadedModule;
				});*/
		}
	}
	if(!BusinessModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModel){
			BusinessModel = importPath.BusinessModel;
		}
		else{
			import('./BusinessModel').then(loadedModule => {
				BusinessModel = loadedModule;
				});
				/*require(["appbo/vdml/BusinessModel"], function (loadedModule) {
					BusinessModel = loadedModule;
				});*/
		}
	}
	if(!CollaborationDiagram){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CollaborationDiagram){
			CollaborationDiagram = importPath.CollaborationDiagram;
		}
		else{
			import('./CollaborationDiagram').then(loadedModule => {
			//		CollaborationDiagram = loadedModule;
				});
				/*require(["appbo/vdml/CollaborationDiagram"], function (loadedModule) {
					CollaborationDiagram = loadedModule;
				});*/
		}
	}
	if(!ObjectReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ObjectReference){
			ObjectReference = importPath.ObjectReference;
		}
		else{
			import('./ObjectReference').then(loadedModule => {
				ObjectReference = loadedModule;
				});
				/*require(["appbo/vdml/ObjectReference"], function (loadedModule) {
					ObjectReference = loadedModule;
				});*/
		}
	}
	if(!RoleLibrary){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.RoleLibrary){
			RoleLibrary = importPath.RoleLibrary;
		}
		else{
				import('./RoleLibrary').then(loadedModule => {
				RoleLibrary = loadedModule;
				});
				/*require(["appbo/vdml/RoleLibrary"], function (loadedModule) {
					RoleLibrary = loadedModule;
				});*/
		}
	}
	if(!OrgUnit){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OrgUnit){
			OrgUnit = importPath.OrgUnit;
		}
		else{
			import('./OrgUnit').then(loadedModule => {
				OrgUnit = loadedModule;
				});
				/*require(["appbo/vdml/OrgUnit"], function (loadedModule) {
					OrgUnit = loadedModule;
				});*/
		}
	}
	if(!SmmModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.SmmModel){
			SmmModel = importPath.SmmModel;
		}
		else{
			import('../smm/SmmModel').then(loadedModule => {
				SmmModel = loadedModule;
				});
				/*require(["appbo/smm/SmmModel"], function (loadedModule) {
					SmmModel = loadedModule;
				});*/
		}
	}
	if(!MeasureLibrary){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.MeasureLibrary){
			MeasureLibrary = importPath.MeasureLibrary;
		}
		else{
			import('../smm/MeasureLibrary').then(loadedModule => {
				MeasureLibrary = loadedModule;
				});
				/*require(["appbo/smm/MeasureLibrary"], function (loadedModule) {
					MeasureLibrary = loadedModule;
				});*/
		}
	}
	if(!ValueStream){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueStream){
			ValueStream = importPath.ValueStream;
		}
		else{
			import('./ValueStream').then(loadedModule => {
				ValueStream = loadedModule;
				});
				/*require(["appbo/vdml/ValueStream"], function (loadedModule) {
					ValueStream = loadedModule;
				});*/
		}
	}
	export class ValueDeliveryModelMixin {
			/*constructor() {
				var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
				path.ValueDeliveryModelMixin = ValueDeliveryModelMixin;
			}*/
		defaults(){
			var ret = {
				type: "vdml_ValueDeliveryModel",
				version: DataManager.getDataManager().get('currentVDMVersion')
			}
			return jQuery.extend(BeepPackage.prototype.defaults.apply(this),ret);
		}
		static getMixinRelations(){
			return _.union([
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueDeliveryModel",
				key:"metricsModel",
				relatedModel:"smm.SmmModel",
				reverseRelation: {
					key:"metricsModelOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			},
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueDeliveryModel",
				key:"collaboration",
				relatedModel:"vdml.Collaboration",
				reverseRelation: {
					key:"collaborationOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			},		
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueDeliveryModel",
				key:"valueStream",
				relatedModel:"vdml.ValueStream",
				reverseRelation: {
					key:"valueStreamOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			},
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueDeliveryModel",
				key:"valueLibrary",
				relatedModel:"vdml.ValueLibrary",
				reverseRelation: {
					key:"valueLibraryOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			},
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueDeliveryModel",
				key:"capabilityLibrary",
				relatedModel:"vdml.CapabilityLibrary",
				reverseRelation: {
					key:"capabilityLibraryOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			},
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueDeliveryModel",
				key:"businessItemLibrary",
				relatedModel:"vdml.BusinessItemLibrary",
				reverseRelation: {
					key:"businessItemLibraryOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			},
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueDeliveryModel",
				key:"actor",
				relatedModel:"vdml.Actor",
				reverseRelation: {
					key:"actorOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			},
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueDeliveryModel",
				key:"practiceLibrary",
				relatedModel:"vdml.PracticeLibrary",
				reverseRelation: {
					key:"practiceLibraryOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			},
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueDeliveryModel",
				key:"scenario",
				relatedModel:"vdml.Scenario",
				reverseRelation: {
					key:"scenarioOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			},	
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueDeliveryModel",
				key:"businessModel",
				relatedModel:"vdml.BusinessModel",
				reverseRelation: {
					key:"businessModelOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			},
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueDeliveryModel",
				key:"referenceLibrary",
				relatedModel:"vdml.ObjectReference",
				reverseRelation: {
					key:"referenceLibraryOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			},
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueDeliveryModel",
				key:"roleLibrary",
				relatedModel:"vdml.RoleLibrary",
				reverseRelation: {
					key:"containingModel",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			},
			{
				type :Backbone.HasOne,
				containingClass:"vdml_ValueDeliveryModel",
				key:"defaultMetricsModel",
				relatedModel:"smm.SmmModel",
				includeInJSON: Backbone.Model.prototype.idAttribute,
			},
			{
				type :Backbone.HasOne,
				containingClass:"vdml_ValueDeliveryModel",
				key:"defaultMeasureLibrary",
				relatedModel:"smm.MeasureLibrary",
				includeInJSON: Backbone.Model.prototype.idAttribute,
			},
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueDeliveryModel",
				key:"canvas",
				relatedModel:"canvas.BMCanvasDiagram",
				reverseRelation: {
					key:"canvasOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			},
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueDeliveryModel",
				key:"diagrams",
				relatedModel:"vdml.CollaborationDiagram",
				//includeInJSON: Backbone.Model.prototype.idAttribute,
				reverseRelation: {
					key:"diagramOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			}
			])
		}
		
		static getCumulativeMixinRelations(){
			if (!ValueDeliveryModelMixin.cummulativeRelations) {
				ValueDeliveryModelMixin.cummulativeRelations = _.union(ValueDeliveryModelMixin.getMixinRelations()
					,DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
					,BeepPackageMixin.getCumulativeMixinRelations()
				);
			}
			return ValueDeliveryModelMixin.cummulativeRelations.slice();
		}
		static getSuperTypes(){
			return [
				"vdml_VdmlElement",
				"beeppackage_BeepPackage"
			];
		}
		static getProperties(){
			return [
				{name : "version",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
				{ name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
				{ name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
				{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
				{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
				{name : "label",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
				{name : "parentLabel",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
				{name : "syncedChangeLog",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
				{name : "synchedChangeSet",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" }
			]
		}
		getParent(){
			var container;
			return BeepPackage.prototype.getParent.apply(this, arguments);
		}
		getPackagePath(path){
			if(!path){
				path = [];
			}
			return BeepPackage.prototype.getPackagePath.apply(this, arguments);
		}
		static getDialogViewProperties(type){
			if (type === "explorerModelJson") {
				return {
					templatePath: "views/beeppackage/views/properties/explorerModelJsonTemplate.html",
					templateName: "explorerModelJsonTemplate",
					viewTypeStr: "appviews/beeppackage/views/properties/explorerModelJsonViewModel",
					tabId : "explorerModelJsonView",
					tabName: "explorerModelJson"
				}
			}
			else if (type === "CapabilityExplorerJson") {
				return {
					templatePath: "views/capabilityLibrary/views/designer/CapabilityExplorerJsonPropertiesTemplate.html",
					templateName: "CapabilityExplorerJsonPropertiesTemplate",
					viewTypeStr: "appviews/capabilityLibrary/views/designer/CapabilityExplorerJsonViewModel",
					tabId: "CapabilityExplorerJsonView",
					tabName: "CapabilityExplorerJson"
				};
			}
			else if (type === "CapabilityTreeConstruction") {
				return {
					templatePath: "views/capabilityLibrary/views/properties/CapabilityTreeConstructionTemplate.html",
					templateName: "CapabilityTreeConstructionTemplate",
					viewTypeStr: "appviews/capabilityLibrary/views/properties/CapabilityTreeConstructionViewModel",
					tabId: "CapabilityTreeConstructionView",
					tabName: "CapabilityTreeConstruction"
				};
			}
			else if (type === "UpdateCapabilityTree") {
				return {
					templatePath: "views/capabilityLibrary/views/properties/UpdateCapabilityTreeTemplate.html",
					templateName: "UpdateCapabilityTreeTemplate",
					viewTypeStr: "appviews/capabilityLibrary/views/properties/UpdateCapabilityTreeViewModel",
					tabId: "UpdateCapabilityTreeView",
					tabName: "UpdateCapabilityTree"
				};
			} else if (type === "BusinessIndicatorExplorerJson") {
				return {
					templatePath: "views/businessIndicatorLibrary/views/designer/BusinessIndicatorExplorerJsonPropertiesTemplate.html",
					templateName: "BusinessIndicatorExplorerJsonPropertiesTemplate",
					viewTypeStr: "appviews/businessIndicatorLibrary/views/designer/BusinessIndicatorExplorerJsonViewModel",
					tabId: "BusinessIndicatorExplorerJsonView",
					tabName: "BusinessIndicatorExplorerJson"
				}
			} else if (type === "ValueExplorerJson") {
				return {
					templatePath: "views/valueLibrary/properties/ValueExplorerJsonPropertiesTemplate.html",
					templateName: "ValueExplorerJsonPropertiesTemplate",
					viewTypeStr: "appviews/valueLibrary/properties/ValueExplorerJsonViewModel",
					tabId: "ValueExplorerJsonView",
					tabName: "ValueExplorerJson"
				}
			}
		}
		getViewProperties(type){
			/*return {
				templatePath : "views/vdml/views/properties/ValueDeliveryModelPropertiesTemplate.html",
				templateName : "ValueDeliveryModelPropertiesTemplate",
				viewTypeStr : "appviews/vdml/views/properties/ValueDeliveryModelViewModel",
				tabId : "ValueDeliveryModelView",
				tabName: "ValueDeliveryModel"
			}*/
		}
	//#startCustomMethods
		handlePackageNameChange(model,newName){
			var self = this;
			var alternativeId = DataManager.getDataManager().getRepositoryId(self.get('id'));
			if(!(alternativeId.indexOf("Common") > -1)){
				DataManager.getDataManager().getAlternative(alternativeId,function(alternative){
					var phase = alternative.get('phaseAlternativeOwner');
					if(phase.get('master') === alternative || phase.get('primary') === alternative) {
						var plan = phase.get('phaseOwner');
						for(var j=0;j<plan.get('phase').length;j++){
							var alts = plan.get('phase').at(j).get('phaseAlternative');
							for(var k=0;k<alts.length;k++){
								var designParts = alts.at(k).get('phaseDesignPart');
								var packRef = designParts.findWhere({'beepReference':self.get('id')});
								if(packRef){
									packRef.set('name',newName);
									packRef.set('description',newName);
									break;
								}
							}	
						}
					}
					else {
						var designParts = alternative.get('phaseDesignPart');
						var packRef = designParts.findWhere({'beepReference':self.get('id')});
						if(packRef){
							packRef.set('name',newName);
							packRef.set('description',newName);
						}
					}
				});	
				var metricModel = self.get('metricsModel');
				if(metricModel) {
					for(var i=0;i<metricModel.length;i++){
						metricModel.at(i).set('name',newName + 'metrics');
					}
				}
			}
		};

		fixXMLOnRevision(xml,oldId,newId){
			var self = this;
			if(xml){
				var xmlDoc = jQuery.parseXML(xml);
				var $xml = jQuery(xmlDoc);
				var nodes = $xml.find('[id="' + oldId + '"]');
				//TODO for cmmn element
				var nodes1 = $xml.find('[bpmnElement="' + oldId + '"]')
				nodes.each(function (callback, node) {
					node.setAttribute('id', newId);
				})
				nodes1.each(function (callback, node) {
					node.setAttribute('bpmnElement', newId);
				})
				xml = (new XMLSerializer()).serializeToString(xmlDoc);
			}
			return xml;
		};

		fixDiagramOnRevision(diagram,viewAltId,oldAltId){
			var self = this;
			var diagramType = diagram.get('type');
			var diagramTypeSuffix = "Process_";
			if(diagram.get('type') === "vdml_CmmnDiagram"){
				diagramTypeSuffix = "Case_";
			}
			var suffixId = window.utils.getSuffix(diagram.id);
			var oldId = diagramTypeSuffix + window.utils.htmlEscape(oldAltId + suffixId);
			var newId = diagramTypeSuffix + window.utils.htmlEscape(diagram.id);
			var newXML = diagram.get('data');
			newXML = self.fixXMLOnRevision(newXML,oldId,newId);
			diagram.set('data',newXML);
		};
		
		getPreviousComponent(component){//fetching component from previous package
			var compId = component.get('id');
			var modelSuffix = compId.substr(compId.lastIndexOf('@')+1);
			var linkedPackageAltId = DataManager.getDataManager().getRepositoryId(this.get('id'));
			var linkedComponent = component.constructor.find({id:linkedPackageAltId + modelSuffix});
			return linkedComponent;
		};
		
		/*createPhaseDesignPart(vdm,scenarioPath,scenario,currentAlternative){//fetching component from previous package
			var packRefId = DataManager.getDataManager().guidGeneratorByOwner(DataManager.getDataManager().get('currentWorkspace'));
			var pacRef = new PackageReference({ id: packRefId, beepReference: vdm, name: vdm.get('name'), description: vdm.get('description'), version: vdm.get('version'), beepType: vdm.get('type'), label: 'Base' });
			DataManager.getDataManager().get('currentWorkspace').get('packageReference').add(pacRef);
			var currentphase = currentAlternative.get('phaseAlternativeOwner');
			var pprId = DataManager.getDataManager().guidGeneratorByOwner(currentphase);
			var PlanPackageReference = Backbone.Relational.store.getObjectByName("transformation.PackageReference");
			new PlanPackageReference({ id: pprId, beepReference: vdm.get('id'), name: vdm.get('name'), description: vdm.get('description'), version: vdm.get('version'), beepType: vdm.get('type'), label: 'Base', isMain: true, phaseDesignPartOwner: currentAlternative });
			//alternative.get('phaseDesignPart').add(planPacRef);
			var scenarioProxyId = DataManager.getDataManager().guidGeneratorByOwner(currentAlternative.get('phaseAlternativeOwner'));
			new ScenarioProxy({ id: scenarioProxyId, name: scenarioPath + " scenario", description: scenarioPath + " scenario", designScenarioOwner: currentAlternative, scenario: scenario.get('id') });
		};*/
		
		createScenario(snapshotId){
			var scenarioId = DataManager.getDataManager().guidGeneratorByOwner(this);
			var scenarioName = this.getUniqueProperty('name','Base Scenario','scenario');
			var scenario = new Scenario({id:scenarioId,name:scenarioName,scenarioOwner:this});
			var observaton = scenario.createContextObservation(null,snapshotId);
			return scenario;
		};
		createCommonScenario(){
			var scenarioId = DataManager.getDataManager().guidGeneratorByOwner(this);
			var scenario = new Scenario({id:scenarioId,name:this.getUniqueProperty('name',this.get('name') + ' CommonScenario','scenario'),scenarioOwner:this,isCommon:true});
			var observaton = scenario.createContextObservation();
			return scenario;
		};
		createDefaultMetricModel(){
			var defMetricModel = this.get('defaultMetricsModel'); 
			if(!defMetricModel){
				var mmId = DataManager.getDataManager().guidGeneratorByOwner(this);
				defMetricModel = new SmmModel({id:mmId,name:this.getUniqueProperty('name',this.get('name') + "metrics",'metricsModel'),metricsModelOwner:this,type: "smm_SmmModel"});
				this.set('defaultMetricsModel',defMetricModel);
			}
			return defMetricModel;
		};
		createDefaultBusiness(bm,businessName){
			var orgId = DataManager.getDataManager().guidGeneratorByOwner(this);
			var name = businessName;
			if(!name){
				name = this.getUniqueProperty('name','My Business','collaboration');
			}
			var defBusiness = new OrgUnit({id:orgId,name:name,collaborationOwner:this});
			bm.set('business',defBusiness);	
			return defBusiness;
		};
        getBusinessCollOfPackage(VDMPack, currentAlternative, businessColl){
            DataManager.getDataManager().getAllDocumentsCollectionOfType(VDMPack, 'vdml_OrgUnit', function(result) {
                var repId = DataManager.getDataManager().get('viewAlternative');
                for (var i = 0; i < result.length; i++) {
                    if (repId !== window.utils.getPrefix(result.at(i).get('id'))) {
                        var newId = repId + window.utils.getSuffix(result.at(i).get('id'));
                        if (window.utils.getElementModel(newId, ['vdml.OrgUnit'])) {
                            continue;
                        }
                    }
                    var relOrgUnit = Backbone.Relational.store.getObjectByName('vdml.OrgUnit').find({
                        id: result.at(i).get('id')
                    });
                    if (relOrgUnit && currentAlternative.get('phaseDesignPart').findWhere({
                            'beepReference': relOrgUnit.getNestedParent().id
                        })) {
                        businessColl.push(relOrgUnit);
                    }
                }
            }, false, null, false, null, null, true);
        }
		addExistingBMContextsToNewScenario(model,collection){
			var vdm = this;
			var isCommon = model.get('isCommon');
			if( !isCommon || isCommon === false){
				var bms = vdm.get('businessModel');			
				bms.each(function(bm){
					var participantNetworks = bm.get('participantNetwork');
					participantNetworks.each(function(pn){
						var coll = pn.get('collaboration');
						if(coll){
							model.get('contextCollaboration').add(coll);	
						}
					});
					var bmResStores = bm.get('bmResourceStore');
					bmResStores.each(function(store){
						model.get('contextStore').add(store);
					});
				});			
			}
		}
		linkOrCreateAndLinkCharacteristic(charName,measuredChar){
			var measureLibrary = this.get('defaultMeasureLibrary');
			var characteristic;
			if(!measureLibrary){
				measureLibrary = this.createDefaultMeasureLibrary();
			}
			characteristic = measureLibrary.getOrCreateCharacteristic(charName);
			measuredChar.set('characteristicDefinition',characteristic);
			return characteristic;
		}
		findOrCreateUnit(unitName,existingUnitObj){
			if(!unitName || unitName === ""){
				return null;
			}
			var measureLibrary = this.get('defaultMeasureLibrary');
			if(!measureLibrary){
				measureLibrary = this.createDefaultMeasureLibrary();
			}
			var unitToLink = measureLibrary.getOrCreateUnit(unitName,existingUnitObj);
			return unitToLink;
		}
		createDefaultMeasureLibrary(){
			var metricsModel = this.get('defaultMetricsModel');
			if(!metricsModel){
				metricsModel = this.createDefaultMetricModel();
			}
			var measureLibrary = this.get('defaultMeasureLibrary');
			if(measureLibrary){
				return measureLibrary;
			}
			measureLibrary = metricsModel.createMeasureLibrary(this.get('name') + ' measures');
			this.set('defaultMeasureLibrary',measureLibrary);
			return measureLibrary;
		};

		createValueDetails(valueModal, oldVpc,satisfaction, weight, recipientValue){
			var self = this;
			self.createMeasurementCharacteristics(valueModal, oldVpc,satisfaction, weight, recipientValue);
		}
		
		createMeasurementCharacteristics(valueModal, oldVpc,satisfaction, weight, recipientValue){
			var repId = DataManager.getDataManager().getRepositoryId(valueModal.id);
			var mcId;
			var valueName = valueModal.get('name');
			var nameVal;
			if (!valueModal.get('valueMeasurement')) {
				if (oldVpc && oldVpc.get('valueMeasurement')) {
					mcId = repId + window.utils.getSuffix(oldVpc.get('valueMeasurement').id);
				} else {
					mcId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
				}

				
				nameVal = valueName + ' Value MeasurementMC';
				var valueMeasurement = new MeasuredCharacteristic({ id: mcId, name: nameVal, description: nameVal,valueMeasurementOwner:valueModal });
				valueModal.set('valueMeasurement', valueMeasurement);
				this.linkOrCreateAndLinkCharacteristic(valueName, valueMeasurement);
			}

			if (valueModal.get('type') != "vdml_ValueAdd") {
				if (!valueModal.get('satisfactionLevel')) {
					if (satisfaction && satisfaction !== "") {
						if (oldVpc && oldVpc.get('satisfactionLevel')) {
							mcId = repId + window.utils.getSuffix(oldVpc.get('satisfactionLevel').id);
						} else {
							mcId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
						}

						nameVal = valueName + ' Satisfaction Level';
						var satisfactionLevel = new MeasuredCharacteristic({ id: mcId, name: nameVal, description: nameVal,satisfactionLevelOwner:valueModal });
						valueModal.set('satisfactionLevel', satisfactionLevel);
						this.linkOrCreateAndLinkCharacteristic(valueName + ' Satisfaction', satisfactionLevel);
					}
				}
				if (!valueModal.get('percentageWeight')) {
					if (weight && weight !== "") {
						if (oldVpc && oldVpc.get('percentageWeight')) {
							mcId = repId + window.utils.getSuffix(oldVpc.get('percentageWeight').id);
						} else {
							mcId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
						}

						nameVal = valueName + ' Percentage Weight';
						var percentageWeight = new MeasuredCharacteristic({ id: mcId, name: nameVal, description: nameVal,percentageWeightOwner:valueModal });
						valueModal.set('percentageWeight', percentageWeight);
						this.linkOrCreateAndLinkCharacteristic(valueName + " Weight", percentageWeight);
					}
				}
				if (!valueModal.get('recipientOpinion')) {
					if (recipientValue && recipientValue !== "") {
						if (oldVpc && oldVpc.get('recipientOpinion')) {
							mcId = repId + window.utils.getSuffix(oldVpc.get('recipientOpinion').id);
						} else {
							mcId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
						}
						var nameVal = valueName + ' Recipient MeasurementMC';
						var recipientMeasurement = new MeasuredCharacteristic({ id: mcId, name: nameVal, description: nameVal,recipientOpinionOwner:valueModal });
						valueModal.set('recipientOpinion', recipientMeasurement);
						this.linkOrCreateAndLinkCharacteristic(valueName + ' Recipient Opinion', recipientMeasurement);
					}
				}
			}
		};

		removeBusinessModel(bm,callback){
			//bm.getModelsIntroducedWithOutReuse(function(refParents){
				var refParentIds = [];
				/*refParentIds.push(bm.get('id'));
				_.each(refParents,function(parent){
					refParentIds.push(parent.get('id'));
				});*/
				bm.cleanBMValues(refParentIds);
				
				bm.destroy(); 
				setTimeout(function(){
					if(/*bm.get('participantNetwork').length === 0 && */bm.get('business')){
						DataManager.getDataManager().getReferencesToModel2(bm.get('business'),function(result){
							var len = result.length;
							while (len--) {
								if(result[len].id === bm.id || result[len].id === bm.getNestedParent().id) {
									result.splice(len,1);
								}
							}
							if(result.length === 0){
								bm.get('business').destroy();
							}
							if(callback){
								callback();
							}
						});	
					}else {
						if(callback){
							callback();
						}
					}
				},100);
				
			//});
			
		}
		
		showDiagram(map,EcoNavigatedViewer,encodeId,callback){
			var encodeId = window.utils.htmlEscape(map.id);
			var container = $("#js-canvas" + encodeId);
			container.empty();
			var dialogModeler = new EcoNavigatedViewer({
				container: container,
				type:map.get('type'),
				additionalModules: [
					{ moveCanvas: [ 'value', '' ], zoomScroll: [ 'value', '' ]}
				]
			});
			async function myDisplay(dialogModeler) {
				let result = new Promise(function(myResolve, myReject) {
				  myResolve("Works");
				});
				try {
					var xml = map.get('data');
					result = await dialogModeler.importXML(xml);
					var { warnings } = result;
					callback(dialogModeler);
				} catch (err) {
					console.log(err.message, err.warnings);
				}
			}
			myDisplay(dialogModeler);
		}
		//#endCustomMethods
		
	}
	path.ValueDeliveryModelMixin = ValueDeliveryModelMixin;
	//return ValueDeliveryModelMixin;
//});