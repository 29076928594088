import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelDimension} from './BusinessModelDimension'
import {CompetenceDimensionMixin} from './CompetenceDimensionMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelDimension","appbo/vdml/CompetenceDimensionMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelDimension,CompetenceDimensionMixin){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var CompetenceDimension = BusinessModelDimension.extend(utils.customExtends({
		relations:CompetenceDimensionMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BusinessModelDimension.prototype.initialize.apply(this, arguments);
		}
		}
		, new CompetenceDimensionMixin()
	));
	
	CompetenceDimension.abstract = false;
	CompetenceDimension.supported = false;
	path.CompetenceDimension = CompetenceDimension;
	export {CompetenceDimension};
//});