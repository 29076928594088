import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'

/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","async","appcommon/com/vbee/data/DataManager","app/global"],
function(require,$,_, Backbone,ko, koMapping,kb,bootbox,async,DataManager,global)
{*/
	var path = DataManager.getDataManager().buildAppNsPath("concept.views.properties",global.version);
export class VocabularyDetailsViewModel{
        constructor(model, options) {
            var self = this;
            this.init(model, options);
        }
		dispose(){
			var self = this;
			window.cleanViewModel(self);		
		}; 		
        saveVocabularyLibDetails() {
            var self = this;
	    	if(self.model){
	    		if(self.name().trim() != self.model.get('name')){
	    			self.model.set('name',self.name().trim());
	    			window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'),DataManager.getDataManager().get('viewAlternative'),self.VocabularyDetailsViewModel);
	    		}
	    		self.model.set('description',self.description());
	    	}
		};
        deleteVocabularyLib() {
            var self = this;
			if(self.parentView.disableAllCompleteButtons){
				self.parentView.disableAllCompleteButtons();
			}
			if(self.model.get('vocabulary').length === 0){
			  	self.model.destroy();
                    DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});			        									    				    	
				
			}else{
				bootbox.confirm(''+DataManager.getDataManager().get('localeManager').get('VocabularyLibDelete'), function(result) {
                if(result) {
                	self.model.destroy();
                	DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});
	            }    
				});
			}		
		};
		
        checkUniqueValue(view, event) {
            var self = this;
			var name = event.currentTarget.value.trim();
			if(name !== ""){
                if (self.model != null && self.model.get('name') === name){
					$('#duplicateName'+self.encodeId).text("");
					$("#complete").removeAttr('disabled');
					return;
				}
	    		var unique = window.isUniqueName(name,null,self.id(),self.model.get('vocabularyLibraryOwner').get('vocabularyLibrary'));
	    		if(unique){
	    			$('#duplicateName'+self.encodeId).text("");
	    			$("#complete").removeAttr('disabled');
	    		}
	    		else{
					var lcManager = DataManager.getDataManager().get('localeManager');
	    			$('#duplicateName'+self.encodeId).text(lcManager.get('DuplicateAlert',lcManager.get('VocabularyLibrary')));
	    			$("#complete").attr('disabled','disabled');
	    		}
			}
			else{
				$("#complete").attr('disabled','disabled');
			}
        };
        
		afterRenderView() {
			window.utils.applyFontSizeInDialog();
		}
		/*this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['general','Alternative'
		,'version'
		,'name'
		,'description'
		,'Close'
		,'Complete'
		,'Delete']);*/
        init(model, options) {
            var self = this;
            this.VocabularyDetailsViewModel = this;
            this.parentView = model;
            this.model = model.model;
            this.id = kb.observable(self.model, 'id');
            function htmlEscape(str) {
                return String(str)
                    .replace(/@/g, '')
                    .replace(/ /g, '');
            }
            this.encodeId = htmlEscape(this.id());
            this.enableDelete = ko.observable(true);
            this.enableComplete = ko.observable(true);
            this.name = ko.observable(self.model ? self.model.get('name') : null);
            this.description = ko.observable(self.model ? self.model.get('description') : "");
            this.name.subscribe(function (val) {
                self.description(val);
            });
            this.checkUniqueValue = _.bind(self.checkUniqueValue, self);
        }
	
	static getInstance(model,options){
        var view= new VocabularyDetailsViewModel(model, options);
        //view.init(model, options);
        return view;
	};
}
path.VocabularyDetailsViewModel = VocabularyDetailsViewModel;