import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as math from 'mathjs'
import * as async from 'async'
import {DimensionalMeasurement} from './DimensionalMeasurement'
import {DimensionalMeasurementMixin} from './DimensionalMeasurementMixin'
import {Accumulator} from './Accumulator'
import { DataManager } from '../../../com/vbee/data/DataManager'

/*define(["require", "jquery", "underscore", "async","math", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/smm/DimensionalMeasurement", "appbo/smm/DimensionalMeasurementMixin", "appbo/smm/Accumulator"],
    function (require, jQuery, _, async, math, Backbone, Lawnchair, backboneLawnchair, DataManager, global, DimensionalMeasurement, DimensionalMeasurementMixin, Accumulator

, Operation
, BaseNMeasurementRelationship){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!DimensionalMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasurement){
			DimensionalMeasurement = importPath.DimensionalMeasurement;
		}
        else {
            import('./DimensionalMeasurement').then(({ default: DimensionalMeasurement }) => {
                DimensionalMeasurement = DimensionalMeasurement;
            });

			/*require(["appbo/smm/DimensionalMeasurement"],function(loadedModule){
				DimensionalMeasurement = loadedModule;
			});*/
		}
	}

	export class CollectiveMeasurementMixin {
        /*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            path.AlternativeMixin = AlternativeMixin;
        }*/
	defaults(){
		var ret = {
			type: "smm_CollectiveMeasurement"
		}
		return jQuery.extend(DimensionalMeasurement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"smm_CollectiveMeasurement",
			key:"baseMeasurements",
			relatedModel:"smm.DimensionalMeasurement",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		/*{
			type :Backbone.HasOne,
			containingClass:"smm_CollectiveMeasurement",
			key:"baseQuery",
			relatedModel:"smm.Operation",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},*/
		{
			type :Backbone.HasMany,
			containingClass:"smm_CollectiveMeasurement",
			key:"baseMeasurementTo",
			relatedModel:"smm.BaseNMeasurementRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"from",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!CollectiveMeasurementMixin.cummulativeRelations) {
            CollectiveMeasurementMixin.cummulativeRelations = _.union(CollectiveMeasurementMixin.getMixinRelations()
                , DimensionalMeasurementMixin.getCumulativeMixinRelations()
            );
        }
		return CollectiveMeasurementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_DimensionalMeasurement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            //{ name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "error",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			{name : "breakValue",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			//{name : "value",type : "EDouble",defaultValue : "null",containingClass : "smm_DimensionalMeasurement" },
            { name: "isBaseSupplied", type: "EBoolean", defaultValue: "null", containingClass: "smm_CollectiveMeasurement" }
		]
	}
	getParent(){
		var container;
		return DimensionalMeasurement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return DimensionalMeasurement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/CollectiveMeasurementPropertiesTemplate.html",
			templateName : "CollectiveMeasurementPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/CollectiveMeasurementViewModel",
			tabId : "CollectiveMeasurementView",
			tabName: "CollectiveMeasurement"
		}
	}
//#startCustomMethods
	createBaseMesurementRelationship(relName,relDescription,sourceMeasurement,mesRel){
		if(this === sourceMeasurement){//special case 2nd phase value having self aggregation(use previous)
			return null;
		}
		var relId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var BaseNMeasurementRelationship = Backbone.Relational.store.getObjectByName("smm.BaseNMeasurementRelationship");
		var rel = new BaseNMeasurementRelationship({id:relId,name:relName,description:relDescription,measurementRelationshipsOwner:this});
		rel.set({'from':this,'to':sourceMeasurement,'definition':mesRel});
		//rel.set('to',sourceMeasurement);
		//rel.set('definition',mesRel);
		this.get('baseMeasurements').add(sourceMeasurement);
		/*if(!mesRel){
			var measureRelationship = this.getMeasureRelationForMeasurementRelation(rel);
			if(measureRelationship){
				rel.set('definition',measureRelationship);
			}
		}*/
		return rel;
	};

    getRelationDetails() {
        var self = this;
        var valueFormula = {};
        
        var baseMeasurementRels = self.get('baseMeasurementTo');
        var observerdMeasure = self.get('observedMeasure');
        var measure = observerdMeasure.get('measure');
        var accumulator = self.getAccumulator(measure);
        
        valueFormula['measure'] = measure.id;
        valueFormula['library'] = measure.get('libraries').id;
        valueFormula['id'] = this.id;
        valueFormula['relations'] = {};
        var componentId = self.get('measuredCharacteristic').getMeasurand().get('id');
        var relations = valueFormula['relations'];
        var relationExps = [];
        var weights = [];
        
        baseMeasurementRels.each(function (baseMeasurementRel) { 
            var baseMeasurement = baseMeasurementRel.get('to');
            if (!baseMeasurement) {
                return;
            }
            var baseMeasureRel = self.getMeasureRelationForMeasurementRelation(baseMeasurementRel);
            if (baseMeasureRel) {
                var relExp = self.getExpressionForRelation(baseMeasurement, baseMeasureRel);
                if (relExp) {
                    relationExps.push(relExp);
                }
                relations[baseMeasurementRel.id] = {};
                relations[baseMeasurementRel.id].measureRelationship = baseMeasureRel.id;
                relations[baseMeasurementRel.id].baseMeasurement = baseMeasurement.id;
                var vpComponent = baseMeasurement.get('measuredCharacteristic') ? baseMeasurement.get('measuredCharacteristic').getMeasurand() : null;
                if (vpComponent && vpComponent.get('componentOwner')) {
                    var ovs = vpComponent.get('componentOwner').get('overAllSatisfaction');
                    if (ovs && ovs.get('id') === componentId && self.get('observedMeasure').get('observation')) {
                        var scenario = self.get('observedMeasure').get('observation').getContext();
                        var timestamp = self.get('observedMeasure').get('observation').get('whenObserved');
                        var weightMeasurement = scenario ? vpComponent.getMeasurement('percentageWeight', scenario, timestamp) : null;
                        if (weightMeasurement) {
                            relations[baseMeasurementRel.id].weightMeasurement = weightMeasurement.id;
                            if (accumulator == "6.0") {
                                weights.push(weightMeasurement.id)
                            }
                        } 
                    }
                }
            }
        });
        if (accumulator == "6.0" && relationExps.length != weights.length) {
            accumulator = "0.0";
        }
        var roundingFactor = measure.get('unit') ? measure.get('unit').get('roundingDigits') : '4';
        var expression = "toFixed(measurement(";
        expression = expression + accumulator;
        for (var i = 0; i < relationExps.length; i++) {
            expression = expression + "," + relationExps[i];
            if (accumulator == "6.0") {
                expression = expression + ","+ weights[i];
            }
        }
        expression = expression + ")," + roundingFactor + ")";
        //valueFormula['expression'] = expression;	// for python calculation engine
		valueFormula['pythonScript'] = expression;
		valueFormula['expression'] = "pythonExecutor";

        return valueFormula;
    }
    getAccumulator(measure) {
        var accumulator = measure.get('accumulator');
        if (!measure.get('accumulator') && measure.get('type') === "smm_CollectiveMeasure" && measure.get('measureRelationships').length > 0) {
            measure.set('accumulator', Accumulator.symbols()[0]);
            accumulator = Accumulator.symbols()[0];
            var measuredCharacteristic = measure.get('measuredCharacteristic');
            var vp = measuredCharacteristic ? measuredCharacteristic.getParent() : null;
            if (vp && vp.checkOVS && vp.checkOVS()) {
                //accumulator = Accumulator.symbols()[6];
                measure.set('accumulator', Accumulator.symbols()[6]);
            }
        }
        if (accumulator && !accumulator.name && measure.get('type') === "smm_CollectiveMeasure" && measure.get('measureRelationships').length > 0) {
            accumulator = _.filter(Accumulator.symbols(), function (sym) { if (sym.name === accumulator) { return sym } })[0];
        }
		if(!accumulator){
			return "0.0";
		}
        switch (accumulator.name) {
            case "sum":
                return "0.0";
            case "product":
                return "1.0";
            case "average":
                return "2.0";
            case "maximum":
                return "3.0";
            case "minimum":
                return "4.0";
            case "standardDeviation":
                return "5.0";
            default:
                return "6.0";
        }
    }
    getExpressionForRelation(baseMeasurement, baseMeasureRel) {
        var self = this;
        //var measurementValues = [], weightMeasurementValues = [];
        var baseMeasure = baseMeasureRel.get('to');
        if (!baseMeasure) {
            return null;
        }
        var rescaledMeasure = baseMeasureRel.get('rescaledMeasure');
        var multiplier = 1;
        var offset = 0;
        var operation;
        if (rescaledMeasure) {
            multiplier = parseFloat(rescaledMeasure.get('multiplier') || 1);
            offset = parseFloat(rescaledMeasure.get('offset') || 0);
            operation = rescaledMeasure.get('operation');
        }
        var operationVal;
        if (operation && operation.get('name')) {
            switch (operation.get('name')) {
                case "invertOperation":
                    operationVal = "0.0";
                    break;
                case "squareOperation":
                    operationVal = "1.0";
                    break;
                case "squareRootOperation":
                    operationVal = "2.0";
                    break;
                default:
                    operationVal = "3.0";
                    break;
            }
        } else {
            operationVal = "3.0";
        }
        return "measureRelation(" + multiplier + "," + offset + "," + operationVal + "," + baseMeasurement.id + ")";
    };
    getEscapedExpression() {
        var self = this;
        var expression = self.get('expression');
        var symbols = expression.match(/@[-@0-9a-zA-Z]+[-#0-9a-zA-Z]+/g);
        if (!symbols) {
            return expression;
        }
        for (var i = 0; i < symbols.length; i++) {
            var escSymbol = symbols[i].replace(/[-@\\\\#]/g, '_');
            expression = expression.replace(new RegExp(symbols[i], 'g'), escSymbol);
        }
        return expression;
    };
	calculate(skipParentCalculation){
		var self = this;
		var measurementValues = [], weightMeasurementValues = [];
		var newValue = '';
		var oldValue = self.get('value');
		var baseMeasurementRels = self.get('baseMeasurementTo');
		var observerdMeasure = self.get('observedMeasure');
		var measure = observerdMeasure.get('measure');
		if(!self.get('measuredCharacteristic')){
			return;
		}
		var componentId = self.get('measuredCharacteristic').getMeasurand().get('id');
		/*var expression = "";
		if(DataManager.getDataManager().get('debugMode')){
			expression = this.getRelationDetails()['expression'];
		}*/
		baseMeasurementRels.each(function(baseMeasurementRel){
			var baseMeasurement = baseMeasurementRel.get('to');
			if(!baseMeasurement){
				return;
            }
			var vpComponent = baseMeasurement.get('measuredCharacteristic')? baseMeasurement.get('measuredCharacteristic').getMeasurand():null;
			if(vpComponent && vpComponent.get('componentOwner')){
				var ovs = vpComponent.get('componentOwner').get('overAllSatisfaction');
				if(ovs && ovs.get('id') === componentId && self.get('observedMeasure').get('observation')){
				    var scenario = self.get('observedMeasure').get('observation').getContext();
				    var timestamp = self.get('observedMeasure').get('observation').get('whenObserved');
				    var weightMeasurement = scenario ? vpComponent.getMeasurement('percentageWeight', scenario, timestamp) : null;
					if(weightMeasurement) {
						var wtVal = weightMeasurement.get('value');
						weightMeasurementValues.push(parseFloat(wtVal));
					}
				}				
			}
			/*if (DataManager.getDataManager().get('debugMode') && self.get('expression') && self.get('expression') != "" && expression == self.get('expression')) {
                var escid = baseMeasurement.id.replace(/[-@\\\\#]/g, '_');
                window.parser.set(escid, baseMeasurement.get('value'));
				if(weightMeasurement) {
					var escwid = weightMeasurement.id.replace(/[-@\\\\#]/g, '_');
					window.parser.set(escwid, weightMeasurement.get('value'));
				}
                return;
            }*/
			var baseMeasureRel = self.getMeasureRelationForMeasurementRelation(baseMeasurementRel);
			if(baseMeasureRel){
				var rescaledMeasure = baseMeasureRel.get('rescaledMeasure');
				var multiplier = 1;
				var offset = 0;
				var operation;
				if(rescaledMeasure){
					multiplier = parseFloat(rescaledMeasure.get('multiplier') || 1);
					offset = parseFloat(rescaledMeasure.get('offset') || 0); 
					operation = rescaledMeasure.get('operation');
				}
				var measurementValue;
				if(baseMeasurement.get('value') !== '' && baseMeasurement.get('value')){
					measurementValue= parseFloat(baseMeasurement.get('value'));	
				}else{
					//baseMeasurement.set('value',0); //not sure why this was done.
					measurementValue = '';
				}
				if(measurementValue != Number.MAX_VALUE && measurementValue !== ''){
					measurementValue = measurementValue * multiplier + offset;
				}
				var Operation = Backbone.Relational.store.getObjectByName('smm.Operation');
				if(operation && Operation[operation.get('name')] && measurementValue !== ''){
					measurementValue = Operation[operation.get('name')](measurementValue);
				}
				if(measurementValue !== '') {
					measurementValues.push(measurementValue);
				}
				//if(baseMeasureRel.get('usePreviousAlternative') && baseMeasurement.get('measuredCharacteristic').get('valueMeasurementOwner') && baseMeasurement.get('measuredCharacteristic').get('valueMeasurementOwner').id === componentId){
					//skipParentCalculation = true;//special case 2nd phase value having self aggregation(use previous)
				//}
			}
        });
        /*if (DataManager.getDataManager().get('debugMode') && self.get('expression') && self.get('expression') != "" && expression == self.get('expression')) {
            if (baseMeasurementRels.length !== 0) {
                try {
                    newValue = parser.evaluate(self.getEscapedExpression());
                    self.set('value', newValue);
                } catch (e) {
                    console.log("Unable to evaluate the expression:" + e);
                }
            } else if (newValue === '') {
                self.set('value', newValue);
            }
        } else {*/
            if (baseMeasurementRels.length !== 0) {
				if(DataManager.getDataManager().get('debugMode')){
					var expression = this.getRelationDetails()['pythonScript'];
					self.set('expression', expression);
				}
                var accumulator = measure.get('accumulator');
                if (!measure.get('accumulator') && measure.get('type') === "smm_CollectiveMeasure" && measure.get('measureRelationships').length > 1) {
                    measure.set('accumulator', Accumulator.symbols()[0]);
                    accumulator = Accumulator.symbols()[0];
                    var measuredCharacteristic = self.get('measuredCharacteristic');
                    var vp = measuredCharacteristic ? measuredCharacteristic.getParent() : null;
                    if (vp && vp.checkOVS && vp.checkOVS()) {
                        measure.set('accumulator', Accumulator.symbols()[6]);
                    }
                    console.log("accumulator null for existing " + measure.get('name'));
                }
                if (accumulator && !accumulator.name && measure.get('type') === "smm_CollectiveMeasure" && measure.get('measureRelationships').length > 0) {
                    accumulator = _.filter(Accumulator.symbols(), function (sym) { if (sym.name === accumulator) { return sym } })[0];
                }
                var roundingFactor = measure.get('unit') ? measure.get('unit').get('roundingDigits') : '4';
                if (measurementValues.length > 0) {
                    newValue = self.calculateValue(accumulator, measurementValues, weightMeasurementValues, roundingFactor);
                }
                self.set('value', newValue);
				//console.log('mest:' + self.get('name') + " val:" + newValue);								
            } else if (newValue === '') {
                self.set('value', newValue);
            }
        //}
		var timestamp = this.get('observedMeasure').get('observation').get('whenObserved');//VDM-6445
		if(oldValue !== newValue || timestamp/* && newValue !== ''*/){
			var mc = this.get('measuredCharacteristic');
			var measurand = mc.getMeasurand();
			if(measurand.get('type') === 'vdml_ValuePropositionComponent'){
				var measurandValueMeasurement  = measurand.get('valueMeasurement');
				if(measurandValueMeasurement === mc && self.get('observedMeasure').get('observation')){
					var satLevel = measurand.get('satisfactionLevel');
					var context = self.get('observedMeasure').get('observation').getContext();
					var satObservedMes;
					if(context){
					    satObservedMes = context.getObservedMeasureWithMeasurements(satLevel,timestamp ? true:false,timestamp);
					}
					if(satObservedMes){
						var satMest = satObservedMes.get('measurements').at(0);
						satMest.calculate(skipParentCalculation);
					}
				}
			}
			if(!skipParentCalculation){
				self.calculateParents();	
			}
		}
		
	};

	calculateValue(operation, measurementValues, weightMeasurementValues, roundingFactor){
		if(!operation){
			if(measurementValues.length === 1){
				return measurementValues[0];
			}else {
				return;
			}
		}
        var measurementValue = 0;
            switch(operation.name){
                case "sum":
                    for(var i=0;i<measurementValues.length;i++){
                        measurementValue += measurementValues[i];
                    }
                    break;
                case "maximum":
                    measurementValue = _.max(measurementValues);
                    break;
                case "minimum":
                    measurementValue = _.min(measurementValues);
                    break;
                case "average":
                    for(var i=0;i<measurementValues.length;i++){
                        measurementValue += measurementValues[i];
                    }
                    measurementValue /= measurementValues.length;
                    break;
                case "product":
                	measurementValue = 1;
                    for(var i=0;i<measurementValues.length;i++){
                    	if(measurementValues[i] === Number.MAX_VALUE){
            				measurementValue = Number.MAX_VALUE;
            				break;
            			}
                        measurementValue *= measurementValues[i];
                    }
                    break;
                case "standardDeviation":
					measurementValue = math.std(measurementValues);//standardDeviation(measurementValues);
                    break;
				case "WeightedAverage":
                    var weightNotDefined = false;
                    var sumOfWeights = 0;

					for(var i=0;i<measurementValues.length;i++){
						if(weightMeasurementValues[i] || weightMeasurementValues[i] === 0){
                            measurementValue += measurementValues[i] * weightMeasurementValues[i];
                            sumOfWeights += weightMeasurementValues[i];
						}else{
							weightNotDefined = true;
						}
                    }
                    if (weightNotDefined) {
                        measurementValue = 0;
                        for (var i = 0; i < measurementValues.length; i++) {
                            measurementValue += measurementValues[i];
                        }
                        measurementValue /= measurementValues.length;
                    } else {
                        measurementValue /= sumOfWeights;
                    }
					break;
            }
        if(measurementValues.length > 0 && roundingFactor){
        	if(isNaN(measurementValue) || measurementValue === Infinity || measurementValue === -Infinity || measurementValue == Number.MAX_VALUE){
        		measurementValue = Number.MAX_VALUE;
    		}else if(measurementValue !== ''){
    			measurementValue = measurementValue.toFixed(roundingFactor);
    		}
        }
        return measurementValue;
		
		/*function standardDeviation(values){
			var avg = average(values);

			var squareDiffs = values.map(function(value){
			var diff = value - avg;
			var sqrDiff = diff * diff;
			return sqrDiff;
			});

			var avgSquareDiff = average(squareDiffs);

			var stdDev = Math.sqrt(avgSquareDiff);
			return stdDev;
			
			function average(data){
				var sum = data.reduce(function(sum, value){
					return sum + value;
				}, 0);
				var avg = sum / data.length;
				return avg;
			}
		}*/
    };
	
	getRescaledMeasureForMeasurementRelation(relation){
		var measureRelation = this.getMeasureRelationForMeasurementRelation(relation);
		return measureRelation.get('resacaledMeasure');
	};
	getMeasureRelationForMeasurementRelation(relation){
		var ret;
		if(relation.get('definition')){
			return relation.get('definition');
		}/*else {
			return null;
		}*/
		var dimMest = relation.get('to');
		if(!dimMest){
			return ret;
		}
		var planRelation = false;
		if(DataManager.getDataManager().getRepositoryId(relation.get('id')) === window.plansKey){
			planRelation = true;
		}
		var collMes = this.get('observedMeasure').get('measure');
		if(!collMes.get('baseMeasureTo')){
			console.log("def missing and measure:"+collMes.get('name'));
			return ret;
		}
		var mesRels = collMes.get('baseMeasureTo').models.concat();
		var baseMestChar = dimMest.get('measuredCharacteristic');
		var baseMestCharId = baseMestChar.get('id');
		var baseMestCharSuffix = baseMestCharId.substr(baseMestCharId.lastIndexOf('@')+1);
		var dimMes = dimMest.get('observedMeasure')?dimMest.get('observedMeasure').get('measure'):null;
		var dimMesOldId = dimMest.get('oldMeasureId');
		_.each(mesRels,function(mesRel){
			if((mesRel.get('to') === dimMes || (mesRel.get('to') && mesRel.get('to').id === dimMesOldId)) && mesRel.get('to').get('trait') === dimMes.get('trait')){
				ret = mesRel;
			}
		});
		if(ret){
			return ret;
		}
		for(var i=0;i<mesRels.length;i++){
			var baseMeasureRel = mesRels[i];
			var bMeasure = baseMeasureRel.get('to');
			if(!bMeasure) {
				continue;
			}
			var bMeasureObsMeasures = bMeasure.get('observedMeasure');
			
			var foundMesRel = false;
			for(var j=0;j<bMeasureObsMeasures.length;j++){
				var obsMes = bMeasureObsMeasures.at(j);
				var mestCharId;
				var mestChar;
				var mestCharSuffix;
				if(obsMes.get('measurements').at(0)){
					mestChar = obsMes.get('measurements').at(0).get('measuredCharacteristic');
					mestCharId = mestChar.get('id');	
					mestCharSuffix = mestCharId.substr(mestCharId.lastIndexOf('@')+1);
				}
				
				if(planRelation){
					if(!mestChar || mestChar !== baseMestChar){
						continue;
					}
				}else {
					if(!mestCharId || mestCharSuffix !== baseMestCharSuffix){
						continue;
					}
				}
				foundMesRel = true;
				break;
			}
			if(foundMesRel){
				ret = baseMeasureRel;
			}
		}
		return ret;
	};
	
    clone(data, obsMes, callback) {
        var self = this;
	    var mestId = DataManager.getDataManager().guidGeneratorByOwner(this);
        var mest = new this.constructor({ id: mestId, name: this.get('name'), description: this.get('description'), observedMeasure: obsMes });
        var val = data ? data[this.get('measuredCharacteristic').id] : undefined;
        mest.set('value', val ? val : this.get('value'));
        self.get('measuredCharacteristic').get('measurement').add(mest);
	    var oldMestRels = this.get('baseMeasurementTo');
	    async.each(oldMestRels.models, function (oldRel, relationHandled) {
	        oldRel.clone(data, mest, function () {
	            relationHandled();
	        });
        }, function () {
        	var cond = DataManager.getDataManager().get('newObservations') ? DataManager.getDataManager().get('newObservations').indexOf(obsMes.get('observation').id) === -1 : true;
            if(cond){
	        	mest.cloneParentRelationships(function () {
		            if (callback) {
		                callback(mest);
		            }
		        });
	        }else{
	        	if (callback) {
		        	callback(mest);
		        }
	        }
	    });
	    return mest;
	}
//#endCustomMethods	
    }
	path.CollectiveMeasurementMixin = CollectiveMeasurementMixin;
	
	//return CollectiveMeasurementMixin;
//});
