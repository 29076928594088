import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasure} from './DimensionalMeasure'
import {DimensionalMeasureMixin} from './DimensionalMeasureMixin'
import {Operation} from './Operation'
import {RescaledMeasureRelationship} from './RescaledMeasureRelationship'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasure","appbo/smm/DimensionalMeasureMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasure,DimensionalMeasureMixin
, Operation
, RescaledMeasureRelationship){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
		else{
			import('./Operation').then(({ default: Operation }) => {
				Operation = Operation;
			});
			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	if(!RescaledMeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RescaledMeasureRelationship){
			RescaledMeasureRelationship = importPath.RescaledMeasureRelationship;
		}
		else{
			import('./RescaledMeasureRelationship').then(({ default: RescaledMeasureRelationship }) => {
				RescaledMeasureRelationship = RescaledMeasureRelationship;
			});
			/*require(["appbo/smm/RescaledMeasureRelationship"],function(loadedModule){
				RescaledMeasureRelationship = loadedModule;
			});*/
		}
	}
	export class RescaledMeasureMixin {
	
	defaults(){
		var ret = {
			type: "smm_RescaledMeasure"
		}
		return jQuery.extend(DimensionalMeasure.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"smm_RescaledMeasure",
			key:"operation",
			relatedModel:"smm.Operation",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_RescaledMeasure",
			key:"rescaleFrom",
			relatedModel:"smm.RescaledMeasureRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"to",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!RescaledMeasureMixin.cummulativeRelations) {
            RescaledMeasureMixin.cummulativeRelations = _.union(RescaledMeasureMixin.getMixinRelations()
                , DimensionalMeasureMixin.getCumulativeMixinRelations()
            );
        }
		return RescaledMeasureMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_DimensionalMeasure"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			//{name : "definition",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "scale",type : "ScaleOfMeasurement",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "customScale",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "source",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "visible",type : "EBoolean",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "formula",type : "EString",defaultValue : "null",containingClass : "smm_DimensionalMeasure" }
			/*{name : "offset",type : "EString",defaultValue : "null",containingClass : "smm_RescaledMeasure" },
			{name : "multiplier",type : "EString",defaultValue : "null",containingClass : "smm_RescaledMeasure" }*/
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("rescaledMeasureOwner") ? this.get("rescaledMeasureOwner") : this.previousAttributes().rescaledMeasureOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("rescaledMeasureOwner") ? this.get("rescaledMeasureOwner") : this.previousAttributes().rescaledMeasureOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/RescaledMeasurePropertiesTemplate.html",
			templateName : "RescaledMeasurePropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/RescaledMeasureViewModel",
			tabId : "RescaledMeasureView",
			tabName: "RescaledMeasure"
		}
	}
	
	}
	path.RescaledMeasureMixin = RescaledMeasureMixin;
	//return RescaledMeasureMixin;
//});