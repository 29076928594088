import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessIndicatorLibraryElement} from './BusinessIndicatorLibraryElement'
import {BusinessIndicatorLibraryElementMixin} from './BusinessIndicatorLibraryElementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { BusinessIndicatorDefinition } from './BusinessIndicatorDefinition'

/*define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/smm/BusinessIndicatorLibraryElement", "appbo/smm/BusinessIndicatorLibraryElementMixin"],
    function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, BusinessIndicatorLibraryElement, BusinessIndicatorLibraryElementMixin
        , BusinessIndicatorDefinition) {*/

        var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
        if (!BusinessIndicatorDefinition) {
            var importPath = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            if (importPath.BusinessIndicatorDefinition) {
                BusinessIndicatorDefinition = importPath.BusinessIndicatorDefinition;
            }
            else {
                import('./BusinessIndicatorDefinition').then(({ default: BusinessIndicatorDefinition }) => {
                //    BusinessIndicatorDefinition = loadedModule;
                });

                /*require(["appbo/smm/BusinessIndicatorDefinition"], function (loadedModule) {
                    BusinessIndicatorDefinition = loadedModule;
                });*/
            }
        }
        export class BusinessIndicatorCategoryMixin {
            /*constructor() {
                var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
                path.AlternativeMixin = AlternativeMixin;
            }*/
       defaults() {
            var ret = {
                type: "smm_BusinessIndicatorCategory"
            }
           return jQuery.extend(BusinessIndicatorLibraryElement.prototype.defaults.apply(this), ret);
        }
           static getMixinRelations() {
                return _.union([
                ])
            }
        static getCumulativeMixinRelations() {
            if (!BusinessIndicatorCategoryMixin.cummulativeRelations) {
                BusinessIndicatorCategoryMixin.cummulativeRelations = _.union(BusinessIndicatorCategoryMixin.getMixinRelations()
                    , BusinessIndicatorLibraryElementMixin.getCumulativeMixinRelations()
				);
			}
            return BusinessIndicatorCategoryMixin.cummulativeRelations.slice();
		}
        static getSuperTypes() {
            return [
                "smm_BusinessIndicatorLibraryElement"
            ];
        }
        static getProperties() {
            return [
                { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
                { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" }
            ]
        }
        getParent() {
            var container;
            return BusinessIndicatorLibraryElement.prototype.getParent.apply(this, arguments);
        }
        getPackagePath(path) {
            if (!path) {
                path = [];
            }
            return BusinessIndicatorLibraryElement.prototype.getPackagePath.apply(this, arguments);
        }
        getViewProperties(type) {
            return {
                templatePath: "views/smm/views/properties/BusinessIndicatorCategoryPropertiesTemplate.html",
                templateName: "BusinessIndicatorCategoryPropertiesTemplate",
                viewTypeStr: "appviews/smm/views/properties/BusinessIndicatorCategoryViewModel",
                tabId: "BusinessIndicatorCategoryView",
                tabName: "BusinessIndicatorCategory"
            }
        }
    }
        path.BusinessIndicatorCategoryMixin = BusinessIndicatorCategoryMixin;
		
        //return BusinessIndicatorCategoryMixin;
    //});