import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as Muuri  from '../../../../../../libs/muuri/muuri.min'
import * as async from 'async'
import * as jszip from '../../../../../../libs/jszip/jszip.min'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {CodeContainer} from '../../../../../version1/bo/beeppackage/CodeContainer'
/*define(["jquery", "underscore", "backbone", "knockout", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/beeppackage/CodeContainer","domtoimage" ],
function ($, _, Backbone, ko, kb, bootbox, async, DataManager, global, CodeContainer)
{*/
    var path = DataManager.getDataManager().buildAppNsPath("ecoMap.views.designer", global.version);
	export class ImageSelectorViewModel {
		constructor(model,options){
			var self = this;
			this.init(model, options);
		}
		init(model, options) {
			var self = this;
			this.ImageSelectorViewModel = this;
			this.model = model;
			this.options = options;
			this.imageName = ko.observable();
			this.imageSize = ko.observable();
			this.selectedImageTagList = ko.observableArray([]);
			this.imagePath = ko.observable();
			this.imageOwner = ko.observable();
			this.imageModified = ko.observable();
			this.imageStored = ko.observable();
			this.editImageView = ko.observable(true);
			this.saveImageView = ko.observable(false);
			this.showDeleteImage = ko.observable(false);
			this.mapList = [];
			this.whereUsedObs = ko.observable();
			this.imgContainersList = {};
			//this.selectedContainer = ko.observable();
			this.encodeId = htmlEscape(options.modalId);
			this.selectedTag = ko.observable();
			this.tagList = ko.observableArray([]); 
			//this.deleteTag = _.bind(self.deleteTag, self);
			this.searchLocationList = ko.observableArray([]);
			
			function htmlEscape(str) {
				return String(str)
				.replace(/@/g, '')
				.replace(/ /g, '')
				.replace(/#/g, '');
			}
			$('#modal'+self.encodeId).on('shown.bs.modal', function (view,event) {
				/*var self = this;
				self.grid.refreshItems().layout();
				self.grid.on('add', function (item) {
					self.addEventListener(item[0]);
				});
				var hgt = window.innerHeight/2 + 'px';
				$('#grid').height(hgt);*/
			});
			
			this.saveImage = function(view,event){debugger
				var fileObject = event.target.files[0];
                var self = this;
		        if(!fileObject){
			        return;
		        }
		        var plan = DataManager.getDataManager().get('currentPlan');
		        var type = fileObject.name.substring(fileObject.name.lastIndexOf('.') + 1);
		        var currentLegalEntityName = DataManager.getDataManager().get("currentLegalEntity").name;
                if(window.vdmModelView.SummernoteReportViewModel){
				    self.saveImageToBackend(fileObject, plan, type, 'plan');
                }else{
                    self.readFile(fileObject, plan, type, currentLegalEntityName);
                }
			};
			
			this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),[
				'name'
				,'description'
				,'Close'
				,'Complete'
				,'Cancel'
			]);
		}

	deleteImage(callback){
		var self = this;
		bootbox.confirm('Are you sure you want to delete the image?', function(result) { 
			if(result){
				var imgDelUrl = "/vdmbee/image/"+self.selectedImgId;
				//TODO backend call to search in all plans
				window.utils.startSpinner('delImage', "Deleting Image... ");
				DataManager.getDataManager().get('vmpServerService').deleteAllPromiseForUriList(imgDelUrl,{},true).then(function (data) {
					self.imgContainersList[self.selectedImgId] = null;
					self.getCodeContainerId(self.selectedImgId,function(codeContainer){
						if(codeContainer){
							codeContainer.destroy();
						}
					});
					window.utils.stopSpinner('delImage');
					$("#imgdetails").hide();
					$('#showImage').attr("src", "");
					$('#showImage').hide();
					self.selectedImgId = null;
					//self.grid.refreshItems().layout();
					self.searchForImage();
				});
			}
		});
	}

	getAllTags(callback){
		var self = this;
		if(self.tagList().length > 0){
			callback(self.tagList);
		}
		var dataManager = DataManager.getDataManager();
		var currentLegalEntity = dataManager.get("currentLegalEntity");
		var plan = dataManager.get('currentPlan');
		var url = encodeURI("/vdmbee/image/getTags?workspaceId="+ dataManager.get("currentWorkspaceId") +"&legalEntityId="+currentLegalEntity.entityId+"&planId="+plan.id);
		dataManager.get('vmpServerService').getAllPromise(url).then(function(response) {
			if(response && response._embedded){
				var tags = response._embedded.tagResources;
				/*for(var i=0;i<tags.length; i++){
					self.tagList.push({'id':tags[i].id,'name':tags[i].name});
				}*/
			}
			callback(tags);
		});
	}

	editImage(){
		var self = this;
		self.editImageView(false);
		self.saveImageView(true);
		$('#tagDetailsInput').closest('div').find('.bootstrap-tagsinput').removeClass('disabled');
		//self.imageTag(true);
	}
	saveEditedImage(){
		var self = this;
		var name = self.imageName().trim();
		var imgResource = self.imgContainersList[self.selectedImgId];
		/*var tagNames = [];
		for(var i=0;i<self.selectedImageTagList().length; i++){
			self.tagList.push({'id':tags[i].id,'name':tags[i].name});
			tagNames
		}*/
		//if(name && imgResource && imgResource.name != name +'.'+  imgResource.type){
			var params = {name : name, inputTags : self.selectedImageTagList(), packageId : imgResource.packageId, legalEntityId : imgResource.legalEntityId};
			DataManager.getDataManager().get('vmpServerService').putAllPromise("/vdmbee/image/updateImage/" + self.selectedImgId + "?workspaceId="+ DataManager.getDataManager().get("currentWorkspaceId"), JSON.stringify(params), true).then(function (updateImage) {
				self.imgContainersList[self.selectedImgId] = updateImage;
				$("#imgName"+self.selectedImgId).html(name);
				self.showImageDetails(updateImage);
			}).catch(function (response) {
				bootbox.alert('failed to save Image'+response);
			});
		//}
		self.editImageView(true);
		self.saveImageView(false);
		$('#tagDetailsInput').closest('div').find('.bootstrap-tagsinput').addClass('disabled');
		//self.imageTag(false);
	}

	createTag(tagName){
		var self = this;
		//var typeAheadCreated = $('#newTagName' + self.encodeId).length > 0 ? true: false
		//var tagName = typeAheadCreated ? $('#newTagName' + self.encodeId).val().trim() : '';
        if (tagName.length > 0) {
			var id = null;
			for (var i = 0; i < self.tagList().length; i++) {
                if (self.tagList()[i].name == tagName) {
                    id = self.tagList()[i].id;
					break;
                }
            }
            id = id? id : '';
            self.selectedImageTagList.push({id: id,name: tagName });
            //$('#newTagName' + self.encodeId).val("");
		}
		/*if(!typeAheadCreated){
			var extendedroleName = Backbone.Typeahead.extend({
				template: '<input type="text" style="width:85%" id="newTagName' + self.encodeId + '" placeholder="Tag Name" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
			});
			self.getAllTags(function(tags){
				var displaytagListTypeahead = new Backbone.Collection();
				for(var i=0;i<tags.length; i++){
					displaytagListTypeahead.push({'id':tags[i].id,'name':tags[i].name});
					self.tagList.push({'id':tags[i].id,'name':tags[i].name});
				}
				var roleNametypeahead = new extendedroleName({collection: displaytagListTypeahead});
				roleNametypeahead.setElement('#tagDetailsDiv' + self.encodeId).render();
			})
		}*/
		//$('#tagDetailsDiv'+ self.encodeId).show();
	}
	deleteTag(name){
		//var id = '';
		var self = this;
		for (var i = 0; i < self.selectedImageTagList().length; i++) {
			if (self.selectedImageTagList()[i].name == name) {
				//id = self.selectedImageTagList()[i].id;
				self.selectedImageTagList.remove(self.selectedImageTagList()[i]);
				break;
			}
		}
		//self.selectedImageTagList.destroy({'id':id,'name':name});
	}

	searchForImage(){
		var self = this;
		if(!self.grid){
			return;
		}
		var searchText = $("#searchText").val().trim();
		var searchLocation = $('#searchLocation').val();
		var searchTagNamesObj = $('#searchTag').val();
		var searchTag = [];
		if(searchTagNamesObj && searchTagNamesObj.length > 0){
			var searchTagNames = searchTagNamesObj.split(',');
			for(var i=0;i<searchTagNames.length;i++){
				var tajObj = self.tagList().filter(obj => {return obj.name === searchTagNames[i] })
				if(tajObj  && tajObj.length > 0){
					searchTag.push(tajObj[0].id);
				}
			}
		}
		var repo = false;
		var plan = DataManager.getDataManager().get('currentPlan');
		var currentLegalEntity = DataManager.getDataManager().get("currentLegalEntity");
		var constUrl = "/vdmbee/image/searchImages?workspaceId="+DataManager.getDataManager().get("currentWorkspaceId")+"&name="+searchText+"&tags="+searchTag;
		if(searchLocation.length == 0){
			repo = true;
			constUrl = constUrl +"&legalEntityId="+currentLegalEntity.entityId+"&planId="+plan.id+"&repo="+repo
		} else {
			if(searchLocation == "Plan"){
				constUrl = constUrl +"&planId="+plan.id+"&repo="+repo
			}else {
				constUrl = constUrl +"&legalEntityId="+currentLegalEntity.entityId+"&repo="+repo
			}
		}
		
		var allItems = self.grid.getItems();
		self.grid.remove(allItems);
		$(".grid-item").remove();
		if(self.intersectionObserver) {
			self.intersectionObserver.disconnect();
		}
		
		var url = encodeURI(constUrl);
		self.pageNum = 0;
		self.fillCodeContainersFromBackend(url,0,function(){
        });
	}

	fillCodeContainersFromBackend(url,pno,callback){
		var self = this;
		//var outPut1 = '';
		var pageSize = 25;
		self.pageNum = self.pageNum ? self.pageNum : 0;
		self.pageNum = self.pageNum + parseInt(pno);
		var pagedUrl = url + "&size="+ pageSize +"&page="+ self.pageNum;
		var imgList = [];
		var id = 'page'+self.pageNum;//window.utils.htmlEscape(window.guidGenerator());
		DataManager.getDataManager().get('vmpServerService').getAllPromise(pagedUrl).then(function(response) {
			if(response && response._embedded){
				imgList = response._embedded.imageResources;
				const myWork = document.querySelector('.grid');
				for(var i=0;i<imgList.length;i++){
					var type = imgList[i].type;
					if(type == 'svg'){
						type = 'svg+xml'
					}
					var headerRaw = "data:image/"+type+";base64,";
             		var imgCode = headerRaw.concat(imgList[i].code);
					self.imgContainersList[imgList[i].dataReference] = imgList[i];
					const item = document.createElement('DIV');
					//var dynamicClass= "grid-item";
					item.classList.add("grid-item");
					if(imgList.length - 1 == i && imgList.length >= pageSize){
						//dynamicClass="grid-item " + id;
						item.classList.add(id);
					}					
					item.setAttribute("codeId", imgList[i].dataReference);
					item.setAttribute("style", "min-width:120px;");
					const gallery = document.createElement('DIV');
					gallery.classList.add('item-content');
					gallery.classList.add('gridView');
					const img = document.createElement('IMG');
					img.src = imgCode;
					img.setAttribute("style", "max-width: 120px;height: 80px;display: block;margin-left: auto;margin-right: auto;");
					//img.setAttribute("style", "width:120px;height:80px;border-radius: 9px;");
					const imgName = document.createElement('DIV');+
					imgName.setAttribute("style", "text-align: center; max-width:120px");
					imgName.setAttribute("id", "imgName"+imgList[i].dataReference);
					imgName.classList.add('ellipsisText');
					var extPos = imgList[i].name.lastIndexOf(".");
					var name = imgList[i].name.substring(0,extPos);
					imgName.innerHTML = name;
					gallery.append(img);
					gallery.append(imgName);
					item.append(gallery);				
					//outPut1 += '<div codeId = "'+imgList[i].dataReference+'" class="' + dynamicClass + '" style="min-width:120px;"><div class="item-content gridView"><img style="width:120px;height:80px;border-radius: 9px;" src="'+imgCode+'" alt=""/><div class="ellipsisText" style="text-align: center; max-width:120px">'+imgList[i].name+'</div></div></div>';
					if(self.grid){
						self.grid.add([item]);
						//self.grid.add([$(outPut1)[0]]);
						//outPut1 = '';
					} else {
						myWork.append(item);
					}
				}
				
			}/* else {
				console.log("failed to load images");
			}*/
			if(!self.grid){
				//document.getElementById('grid').innerHTML = outPut1;
				self.grid = new Muuri('.grid',{  
					//layout:{fillGaps: true}
				});
				self.grid.getItems().forEach(function (item) {
					self.addEventListener(item);
				});
				self.grid.on('add', function (item) {
					self.addEventListener(item[0]);
				});
				var hgt = window.innerHeight/2 + window.innerHeight/10 + 'px';
				$('#grid').height(hgt);
				$('#grid').css("max-height",hgt);
				self.grid.refreshItems().layout();
			}			
			
			if(imgList && imgList.length >= pageSize){
				if(!self.intersectionObserver) {
					self.intersectionObserver = new IntersectionObserver(entries => {
						if (entries[0].intersectionRatio <= 0) return;
							// load more content;
							self.fillCodeContainersFromBackend(url,1,function(){
							});
					});
				}else {
					self.intersectionObserver.disconnect();
				}
				// start observing
				self.intersectionObserver.observe(document.querySelector('.'+ id));
			} else if(self.intersectionObserver) {
				self.intersectionObserver.disconnect();
			}
			callback();
		});
	}

	/*importLib(fileZipObject){
		var self = this;
		var tagName = "Plan";
		var reader = new FileReader();
		reader.onload = function (ev) {
			var zipContent = reader.result;
			var newplanZip = new jszip();
			newplanZip.loadAsync(zipContent).then(function (imageList) {
				var obj = imageList.files;
				var files = jQuery.map(obj, function(el) { return el });//converting json to array
				async.eachSeries(files, function (fileObject, packHandleCallback) {
					var extPos = fileObject.name.lastIndexOf(".");
					var type = fileObject.name.substring(extPos + 1);
					if (['jpg', 'png'].indexOf(type) != -1 && window.utils.checkImageSize(fileObject)) {
						fileObject.async("base64").then(function (contentBuffer) {
							var content = "data:image/" + type + ";base64," + contentBuffer;
							var folderPosition = fileObject.name.lastIndexOf("/");
							var name = fileObject.name;
							var path;
							if(folderPosition != -1){
								name = fileObject.name.substring(folderPosition+1);
								path = fileObject.name.substring(0,folderPosition);
							}
							var codeContainer = CodeContainer.getBlobInstance(name, name, DataManager.getDataManager().get('currentPlan'));
							codeContainer.set({'code': content,'createdDate': (new Date()).getTime(),'owner': DataManager.getDataManager().get('email'),'path': path});
							//var tagObj = new Tag({name:tagName,tagOwner:codeContainer.getNestedParent()});
			  				//codeContainer.get('tag').add(tagObj);
							self.imgContainersList.push({id:codeContainer.id, name: codeContainer.get('name')});
							var name = codeContainer.get('name').substring(0,extPos);
							var outPut1 = '<div codeId = "'+codeContainer.get('id')+'" class="grid-item" style="min-width:120px;"><div class="item-content gridView"><img style="width:120px;height:120px;border-radius: 9px;" src="'+codeContainer.get('code')+'" alt=""><div class="ellipsisText" style="text-align: center; max-width:120px">'+name+'</div></div></div>';
							self.grid.add([$(outPut1)[0]], { index: 0 });
							packHandleCallback();
						});							
					}else {
						packHandleCallback();
					}
				}, function (err) {
					self.grid.refreshItems().layout();
					var hgt = window.innerHeight/2 + 'px';
					$('#grid').height(hgt);
					bootbox.alert("Image Library Imported");
				});
			})
		};
		reader.readAsBinaryString(fileZipObject);
	}*/
	readFile(fileObject, plan, type, currentLegalEntityName){
		//TODO add tag
        var self = this;
		var message = "Import "+fileObject.name+" to ?<br>Repository:<br><input type='radio' checked name='location' value='plan'>&nbsp;"+plan.get('name')+"<br><input type='radio' name='location' value='le'>&nbsp;"+currentLegalEntityName;
		bootbox.dialog({
			title: "<h4 style=\"font-family:Roboto; display: inline-block;\"><b>"+DataManager.getDataManager().get('localeManager').get('import')+"</b></h4>",
			message: message,
			className: "bootboxModal",
			buttons: {
				success: {
					label: 'Complete',
					className: "btn btn-complete complete-btn",
					callback: function () {
                        var location = $('input[name="location"]:checked').val();
					    self.saveImageToBackend(fileObject,plan,type,location);
					}
				}
			}
		});
	}
    saveImageToBackend(fileObject, plan, type, whereToSave){
        var self = this;
        var planId = whereToSave == "plan" ? plan.id : '';
		if(planId){
			var planDoc = DataManager.getDataManager().get("artifactsDocuments")[planId];
			planId = planDoc ? planDoc.artifactId : planId;
		}
        if (['jpg', 'png', 'svg'].indexOf(type) != -1 && fileObject && window.utils.checkImageSize(fileObject)) {
            var reader = new FileReader();
            reader.onload = function(ev) {
                window.utils.startSpinner('imageUP', "Uploading Image... ");
                window.utils.postImageToBackend(planId, [], planId, '', fileObject, function(codeContainerList) {
                    var codeContainer = JSON.parse(codeContainerList)[0];
                    self.selectedImgId = codeContainer.dataReference;
                    self.imgContainersList[codeContainer.dataReference.toLocaleUpperCase()] = codeContainer;
                    //self.imgContainersList[codeContainer.dataReference] = codeContainer;//.push({id:codeContainer.dataReference, name: codeContainer.name});
                    //var extPos = codeContainer.name.lastIndexOf(".");
                    //var name = codeContainer.name.substring(0,extPos);
                    //var type = codeContainer.type;
                    //if(type == 'svg'){
                    //	type = 'svg+xml'
                    //}
                    //var headerRaw = "data:image/"+type+";base64,";
                    // var imgCode = headerRaw.concat(codeContainer.code);
                    //var outPut1 = '<div codeId = "'+codeContainer.dataReference+'" class="grid-item" style="min-width:120px;"><div class="item-content gridView"><img style="max-width: 120px;height: 80px;display: block;margin-left: auto;margin-right: auto;" src="'+imgCode+'" alt=""><div class="ellipsisText" style="text-align: center; max-width:120px">'+ name +'</div></div></div>';
                    //self.grid.add([$(outPut1)[0]], { index: 0 });
                    //self.grid.refreshItems().layout();
                    //var hgt = window.innerHeight/2 + 'px';
                    //$('#grid').height(hgt);
                    //self.selectedImgId = codeContainer.dataReference;
                    //$('div[codeid="' + codeContainer.dataReference + '"]').trigger("click");
                    //self.searchForImage();
                    self.complete();
                    window.utils.stopSpinner('imageUP');
                    
                })
                //var codeContainer = CodeContainer.getBlobInstance(fileObject.name, fileObject.name, plan);
                //codeContainer.set({'code': reader.result,'createdDate': (new Date()).getTime(),'owner': DataManager.getDataManager().get('email')});

                //var tagObj = new Tag({name:'Plan',tagOwner:codeContainer.getNestedParent()});
                //codeContainer.get('tag').add(tagObj);//issue with changelog
                /*self.codeContainersList.push({id:codeContainer.id, name: codeContainer.get('name')});
								        var extPos = codeContainer.get('name').lastIndexOf(".");
								        var name = codeContainer.get('name').substring(0,extPos);
								        var outPut1 = '<div codeId = "'+codeContainer.get('id')+'" class="grid-item" style="min-width:120px;"><div class="item-content gridView"><img style="width:120px;height:120px;border-radius: 9px;" src="'+codeContainer.get('code')+'" alt=""><div class="ellipsisText" style="text-align: center; max-width:120px">'+name+'</div></div></div>';
								        self.grid.add([$(outPut1)[0]], { index: 0 });
								        self.grid.refreshItems().layout();
								        var hgt = window.innerHeight/2 + 'px';
								        $('#grid').height(hgt);
								        self.selectedImgId = codeContainer.get('id');
								        $('div[codeid="' + codeContainer.get('id') + '"]').trigger("click");*/
            };
            reader.readAsDataURL(fileObject);
        } else {
            if (['jpg', 'png'].indexOf(type) != -1) {
                bootbox.alert("File Size limit exceeded");
            } else {
                bootbox.alert("Invalid File Format");
            }
        }
    }
	showImageDetails(codeContainer){
		var self = this;		
		var byteCode = codeContainer.code;
		var size = new Blob([byteCode]).size/1000 + " KB";
		$("#imgdetails").show();
		$('a[href="#DetailsOverview"]').trigger("click");
		var extPos = codeContainer.name.lastIndexOf(".");
		var name = codeContainer.name.substring(0,extPos);
		self.imageName(name);
		self.imageSize(size);
		self.selectedImageTagList.removeAll();
		$('#tagDetailsInput').tagsinput('removeAll');
		$('#tagDetailsInput').closest('div').find('.bootstrap-tagsinput').addClass('disabled');
		var tag = codeContainer.inputTags;
		if(tag && tag.length > 0){
			for (var i = 0; i < tag.length; i++) {
				self.selectedImageTagList.push({id: tag[i].id,name: tag[i].name });
				$('#tagDetailsInput').tagsinput('add', tag[i].name,true);
			}
			//self.imageTag(codeContainer.inputTags[0].name);
		}
		self.imagePath(codeContainer.fullyQualifiedName);
		var storedLocName = codeContainer.legalEntityName ? codeContainer.legalEntityName : codeContainer.packageName;
		self.imageStored(storedLocName);
		self.saveImageView(false);
		if(!DataManager.getDataManager().get("readMode") && (codeContainer.packageId || codeContainer.createdBy == DataManager.getDataManager().get('email'))){
			self.editImageView(true);
			var delImage = false;
			if(codeContainer.packageId && codeContainer.createdBy == DataManager.getDataManager().get("email")){
				delImage = true;
			}
			self.showDeleteImage(delImage);
		} else {
			self.editImageView(false);
			self.showDeleteImage(false);
		}
		self.imageOwner(codeContainer.createdBy);
		//if(codeContainer.get('createdDate')){
		//	self.imageModified(new Date(codeContainer.get('createdDate')).toDateString());
		//}
	}
	addEventListener(item){
		var self = this;
		item.getElement().addEventListener('click', function () {
			$(item._element.parentElement).find('.item-content').each(function(view,itemContent){
				itemContent.style.border = '';
			})
			item._element.childNodes[0].style.border = "3px solid #73a580";
			item._element.childNodes[0].style.borderRadius = "9px";
			var codeId = item._element.getAttribute('codeId');
			self.selectedImgId = codeId;
			var imgResource = self.imgContainersList[codeId];
			if(imgResource){
				$('#showImage').css('display','block');
				var type = imgResource.type;
				if(type == 'svg'){
					type = 'svg+xml'
				}
				var headerRaw = "data:image/"+type+";base64,";
				 var imgCode = headerRaw.concat(imgResource.code);
				$('#showImage').attr("src", "" + imgCode);
				self.showImageDetails(imgResource);
			}
			/*var url = encodeURI("/vdmbee/image/getImage?id="+codeId);
			DataManager.getDataManager().get('vmpServerService').getAllPromise(url).then(function(imgResource) {
				if(imgResource){
					debugger
					$('#showImage').css('display','block');
					var headerRaw = "data:image/"+imgResource.type+";base64,";
             		var imgCode = headerRaw.concat(imgResource.code);
					$('#showImage').attr("src", "" + imgCode);
					self.showImageDetails(imgResource);
				}
			});*/
			/*CodeContainer.getBlobInstanceWithId(codeId, DataManager.getDataManager().get('currentPlan'), function (codeContainer1) {
				if (codeContainer1) {
					$('#showImage').css('display','block');
					$('#showImage').attr("src", "" + codeContainer1.get('code'));
					self.showImageDetails(codeContainer1);
				}
			});*/
			
		});
	}

	whereUsed(view,event){
		var self = this;
		function displayWhereUsed(imageFoundList){
			self.whereUsedObs(imageFoundList);
		}
		self.getCodeContainerId(self.selectedImgId,function(codeContainer){
			if(codeContainer){
				var plan = DataManager.getDataManager().get('currentPlan');
				if(self.mapList.length < 1) {
					plan.getAllDocumentsCollectionOfType(plan,'ecomap_EcoMap,vdml_ValueDeliveryModel,report_BeepReport', function(results){
						results.each(function (pack) {
							self.mapList.push(pack);
						});
						window.utils.searchImage(plan,codeContainer.id,self.mapList,displayWhereUsed);
					}, null,null,null,null,true);
				}else {
					window.utils.searchImage(plan,codeContainer.id,self.mapList,displayWhereUsed);
				}
			} else {
				displayWhereUsed([]);
				//TODO
			}
		});
	}
	afterRenderView(node, view) {
		var self = view;
		var plan = DataManager.getDataManager().get('currentPlan');
		var currentLegalEntity = DataManager.getDataManager().get("currentLegalEntity");
		var constUrl = "/vdmbee/image/searchImages?workspaceId="+DataManager.getDataManager().get("currentWorkspaceId");
		constUrl = constUrl +"&legalEntityId="+currentLegalEntity.entityId+"&planId="+plan.id+"&repo="+true;
		//var url = encodeURI("/vdmbee/image/getImages?workspaceId="+DataManager.getDataManager().get("currentWorkspaceId")+"&legalEntityId="+currentLegalEntity.entityId+"&planId="+plan.id);
		setTimeout(function(){
			self.fillCodeContainersFromBackend(constUrl,0,function(){
				/*setTimeout(function () {
					//self.grid.refreshItems().layout();
					//self.grid.add([$(outPut1)[0]]);
					
					//$('#grid').css('overflow','visible');
				}, 400);*/
			});
		},200)
		
		$("#grid").on("contextmenu",function(){
			return false;
		});
		
		self.getAllTags(function(tags){
			//var elt = $('#searchTag');
			var sourceList = [];
			if(tags){
				for(var i=0;i<tags.length; i++){
					self.tagList.push({'id':tags[i].id,'name':tags[i].name});
					sourceList.push(tags[i].name);
					//elt.tagsinput('add', tags[i].name);
				}
			}
			$('#tagDetailsInput').tagsinput({
				//itemValue: 'id',freeInput becomes false
				//itemText: 'name',
				tagClass:'badge badge-warning',
				freeInput:true,
				addOnBlur:true,
				maxTags: 10,
				confirmKeys: [13, 44],// ENTER and comma
				maxChars: 30,
				typeahead: {
					minLength: 0,
					highlight: false,
					afterSelect: function() {
						//console.log(this.$element[0].value);
						this.$element[0].value = '';
					},
					source: sourceList
				}
			});
			
			$('#tagDetailsInput').on('itemAdded',function(event) {
				var self = this;
				if(!event.options){
					self.createTag(event.item);
				}
			}.bind(self));
			$('#tagDetailsInput').on('itemRemoved',function(event) {
				var self = this;
				self.deleteTag(event.item);
			}.bind(self));
			$('#tagDetailsInput').closest('div').find('.bootstrap-tagsinput').addClass('disabled');
			self.searchLocationList.push({id: '',name: 'All' },{id: 'Plan',name: plan.get('name') },{id: 'Organization',name: currentLegalEntity.name });
			
			$('#searchTag').tagsinput({
				tagClass:'badge badge-warning',
				freeInput:false,
				addOnBlur:true,
				maxTags: 10,
				confirmKeys: [13, 44],// ENTER and comma
				maxChars: 30,
				typeahead: {
					minLength: 0,
					highlight: false,
					afterSelect: function() {
						this.$element[0].value = '';
					},
					source: sourceList
					//source: ["I'm done trying...", "Jhon", "Smith"]
				}
			});
		/*$('.my-focus-class').on('focus',function(event) {
			debugger
			if(self.tagList().length == 0){
				self.getAllTags(function(tags){
					var elt = $('#searchTag');
					for(var i=0;i<tags.length; i++){
						self.tagList.push({'id':tags[i].id,'name':tags[i].name});
						elt.tagsinput('add', tags[i].name);
					}
				})
			}
		}.bind(self));*/
		$('#searchTag').on('itemAdded',function(event) {
			var self = this;
			self.searchForImage(event.item);
		}.bind(self));
		$('#searchTag').on('itemRemoved',function(event) {
			var self = this;
			self.searchForImage(event.item);
		}.bind(self));
	})
		/*$('#searchTag').multiselect({
			enableFiltering: true,
			includeFilterClearBtn: false,
			enableCaseInsensitiveFiltering: true,
			buttonContainer: '<div class="btn-group" />',
			buttonText: function(options, select) {
                if (options.length === 0) {
                    return 'Select Tag ...';
                }
                else if (options.length > 3) {
                    return 'More than 3 options selected!';
                }
                else {
                     var labels = [];
                     options.each(function() {
                         if ($(this).attr('label') !== undefined) {
                             labels.push($(this).attr('label'));
                         }
                         else {
                             labels.push($(this).html());
                         }
                     });
                     return labels.join(', ') + '';
                 }
            },
			maxHeight: 200,
			onDropdownShow: function(event) {
				if($('#searchTag option').length == 0){
					var currentLegalEntity = DataManager.getDataManager().get("currentLegalEntity");
					var plan = DataManager.getDataManager().get('currentPlan');
					var url = encodeURI("/vdmbee/image/getTags?legalEntityId="+currentLegalEntity.entityId+"&planId="+plan.id);
					DataManager.getDataManager().get('vmpServerService').getAllPromise(url).then(function(response) {
						var htm = '';
						if(response && response._embedded){
							var tags = response._embedded.tagResources;
							for(var i=0;i<tags.length; i++){
								//self.tagList.push({'id':tags[i].id,'name':tags[i].name});
								htm += '<option id='+tags[i].id+'>' + tags[i].name + '</option>';
							}
						}
						$('#searchTag').append(htm);
						$('#searchTag').multiselect('rebuild');
					});
				}
            }
		});*/
		$('#imageTab a').click(function (e) { 
			e.preventDefault();
			$(this).tab('show');
			self.selectedRightTab = $(this).text();
			if ($(this).text() === "Where-used") {           
				self.whereUsed();
			}
		});
	};
	
	cleanUp(){
	};
	
	cleanModal() {
		var self = this;
		window.cleanDialogModel(self.encodeId,self);		
	};
	getFileSize(size){
		return Math.round((size / ImageSelectorViewModel.ONE_MB));
	}
	getCodeContainerId(imageId,callback){
		var plan = DataManager.getDataManager().get('currentPlan');
		
		//plan.fetchCustomPlanPackages(['beeppackage_CodeContainer'], function(codeList){
			/*for (var j = 0; j < codeList.length; j++) {
				if(imageId == codeList[j].get('dataReference')){
					codeContainer = codeList[j];
					break;
				}
			}*/
			function loadImageList(codeContainers) {
				var codeContainer;
				for (var j = 0; j < codeContainers.length; j++) {
					var codeContainerObj = window.utils.getElementModel(codeContainers.at(j).id,['beeppackage.CodeContainer']);
					if(codeContainerObj && codeContainerObj.get('dataReference') && imageId && imageId.toUpperCase() == codeContainerObj.get('dataReference').toUpperCase()){
						codeContainer = codeContainerObj;
						break;
					}
				}
				callback(codeContainer);
			}
			var planId = plan.id.substr(plan.id.lastIndexOf('@'));
			var commonAltId = planId + "-Common@";
			var commonRdfModel = DataManager.getDataManager().getRDFModel(commonAltId);
			commonRdfModel.getAllDocumentsCollectionOfType('beeppackage_CodeContainer', loadImageList, null, null, null, null, true);
		//},true);
	}
	complete(){
		var self = this;
		var imageId = self.selectedImgId;
		if (imageId && imageId !== "") {
			var imgResource = self.imgContainersList[imageId.toLocaleUpperCase()];
			if(imgResource){
				self.getCodeContainerId(imageId,function(codeContainer){
					if(!codeContainer){
						var plan = DataManager.getDataManager().get('currentPlan');
						var headerRaw = "data:image/"+imgResource.type+";base64,";
						var imgCode = headerRaw.concat(imgResource.code);
						codeContainer = CodeContainer.getBlobInstance(imgResource.name, imgResource.name, plan);
						codeContainer.set({'dataReference':imgResource.dataReference,'createdDate': (new Date()).getTime(),'owner': DataManager.getDataManager().get('email')});
						codeContainer.set('code', imgCode, { silent: true });
					}
					if(!window.vdmModelView.SummernoteReportViewModel && !window.vdmModelView.DashboardDetailsViewModel){
						var backImgUrl = self.options.businessObject.get('vdml:backgroundUrl');
						if(backImgUrl && backImgUrl != codeContainer.id && window.vdmModelView.deletedCodedContainers){
							window.vdmModelView.deletedCodedContainers.push(backImgUrl);
						}
						self.options.businessObject.set('vdml:backgroundUrl', codeContainer.id);
						self.options.modeling.updateProperties(self.options.element, {});
						window.vdmModelView.model.getNestedParent().get('dependentPackage').add(codeContainer);	
						window.vdmModelView.saveDiagram(null,null);
					} else if(window.vdmModelView.SummernoteReportViewModel || window.vdmModelView.DashboardDetailsViewModel){
						var id = window.utils.htmlEscape(window.guidGenerator())
						var textNode = $("<img src='' diagramid = "+codeContainer.id+" type = 'beeppackage.CodeContainer' id ="+id+">")[0];
						$('#summernote').summernote('restoreRange');
						$('#summernote').summernote('insertNode', textNode);
						textNode = null;
						//CodeContainer.getBlobInstanceWithId(imageId, plan, function (codeContainer) {
						if (codeContainer) {
							if(window.vdmModelView.model.getNestedParent().get('type') == 'report_BeepReport'){
								window.vdmModelView.model.getNestedParent().get('dependentPackage').add(codeContainer);	
							}
							$('#'+id).attr("src", "" + codeContainer.get('code'));
						} else {
							$('#'+id).remove();
							$('#'+id).attr("src", "");
						}
						//});
					}
					self.cleanModal();
				},true);				
				/*if (!self.options.parentView.newCodedContainers) {
					self.options.parentView.newCodedContainers = {};
				}*/
			} else {
				self.cleanModal();
			}
			
			//options.parentView.newCodedContainers[codeContainer.id] = codeContainer;
		} else {
			self.cleanModal();
		}
		
	}
	loadLib(){	
		var self = this;	
		if (!window.utils.checkChrome()) {
			$('#importImage').click();
		} else {
			chrome.fileSystem.chooseEntry({
				type: 'openFile', accepts: [
					{ description: "Image", extensions: ['jpg', 'png'] }
				], acceptsAllTypes: true
			}, function (f) {
				if (chrome.runtime.lastError) {
					console.log(chrome.runtime.lastError);
					//fileHandled('Error opening Image');
				}
				else {
					f.file(function (fileObject) {
						self.readFile(fileObject);
					});
				}
			});
		}
	};
	/*loadImage(){	
		var self = this;	
		if (!window.utils.checkChrome()) {
			$('#importImage').click();
		} else {
			chrome.fileSystem.chooseEntry({
				type: 'openFile', accepts: [
					{ description: "Image", extensions: ['jpg', 'png'] }
				], acceptsAllTypes: true
			}, function (f) {
				if (chrome.runtime.lastError) {
					console.log(chrome.runtime.lastError);
					//fileHandled('Error opening Image');
				}
				else {
					f.file(function (fileObject) {
						self.readFile(fileObject);
					});
				}
			});
		}
	};*/
	
	static getInstance(model,options){
		var view = new ImageSelectorViewModel(model, options);
		//view.init(model, options);
		return view;
	};
	
}
ImageSelectorViewModel.TWO_MB = 2048;
ImageSelectorViewModel.ONE_MB = 1024;
path.ImageSelectorViewModel =  ImageSelectorViewModel;