import * as jQuery from 'jquery';
import * as _ from 'underscore';
(function(){
window.JST = window.JST || {};

window.JST['search_box'] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }

__p += '<div class="VS-search ';
 if (obj.readOnly) { ;
__p += 'VS-readonly';
 } ;
__p += '">\r\n  <div class="VS-search-box-wrapper VS-search-box">\r\n    <div class="VS-icon VS-icon-search"></div>\r\n    <div class="VS-placeholder"></div>\r\n    <div class="VS-search-inner"></div>\r\n    <div class="VS-icon VS-icon-cancel VS-cancel-search-box" title="clear search"></div>\r\n  </div>\r\n</div>';


return __p
};

window.JST['search_facet'] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }


 if (obj.model.has('category')) { ;
__p += '\r\n  <div class="category">' +
((__t = ( obj.model.get('category') )) == null ? '' : __t) +
':</div>\r\n';
 } ;
__p += '\r\n\r\n<div class="search_facet_input_container">\r\n  <input type="text" class="search_facet_input ui-menu VS-interface" value="" ';
 if (obj.readOnly) { ;
__p += 'disabled="disabled"';
 } ;
__p += ' />\r\n</div>\r\n\r\n<div class="search_facet_remove VS-icon VS-icon-cancel"></div>';


return __p
};

window.JST['search_input'] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }

__p += '<input type="text" class="ui-menu" ';
 if (obj.readOnly) { ;
__p += 'disabled="disabled"';
 } ;
__p += ' />';


return __p
};

})();