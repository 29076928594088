import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BMCanvasDiagram} from '../canvas/BMCanvasDiagram'
import {BeepPackage} from '../beeppackage/BeepPackage'
import {DataManager} from '../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/beeppackage/BeepPackage","appbo/beeppackage/BeepPackageMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BeepPackage,BeepPackageMixin
//,BMCanvasDiagram
//){
	
	var path = DataManager.getDataManager().buildAppNsPath("report",global.version);
	/*if(!BMCanvasDiagram){
		var importPath = DataManager.getDataManager().buildAppNsPath("canvas",global.version);
		if(importPath.BMCanvasDiagram){
			BMCanvasDiagram = importPath.BMCanvasDiagram;
		}
		else{
			require(["appbo/canvas/BMCanvasDiagram"],function(loadedModule){
				BMCanvasDiagram = loadedModule;
			});
		}
	}*/
	export class BeepReportMixin{
		constructor(){
			var path = DataManager.getDataManager().buildAppNsPath("report",global.version);
			path.BeepReportMixin = BeepReportMixin;
		}
	
	defaults(){
		var ret = {
			type: "report_BeepReport",
			version: DataManager.getDataManager().get('currentVDMVersion')
		}
		return jQuery.extend(BeepPackage.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
/*		{
			type :Backbone.HasMany,
			containingClass:"cmof_EObject",
			key:"reportModel",
			relatedModel:"cmof.EObject",
		},*/
		{
            type :Backbone.HasMany,
            containingClass:"vdml_BeepReport",
            key:"diagram",
            relatedModel:"canvas.BMCanvasDiagram",
            includeInJSON:Backbone.Model.prototype.idAttribute
        }
		]);
	};
	static getCumulativeMixinRelations(){
		if (!BeepReportMixin.cummulativeRelations) {
			var BeepPackageMixin = Backbone.Relational.store.getObjectByName('beeppackage.BeepPackageMixin');
            BeepReportMixin.cummulativeRelations = _.union(BeepReportMixin.getMixinRelations()
                , BeepPackageMixin.getCumulativeMixinRelations()
            );
        }
		return BeepReportMixin.cummulativeRelations.slice();
	};
	static getSuperTypes(){
		return [
			"beeppackage_BeepPackage"
		];
	}
	static getProperties(){
		return [
			{name : "version",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "progress", type: "double", defaultValue: "0", containingClass: "cmof_EObject" },
			{name : "label",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "parentLabel",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "syncedChangeLog",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "synchedChangeSet",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" }
		]
    }
    calculateProgress() {

    }
	getParent(){
		var container;
		return BeepPackage.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return BeepPackage.prototype.getPackagePath.apply(this, arguments);
	}
	static getDialogViewProperties(type){
		if(type==="explorerModelJson"){
			return{
				templatePath : "views/beeppackage/views/properties/explorerModelJsonTemplate.html",
			    templateName : "explorerModelJsonTemplate",
			    viewTypeStr : "appviews/beeppackage/views/properties/explorerModelJsonViewModel",
                tabId: "explorerModelJsonView",
                tabName: "explorerModelJson"
			}
		}		
	}
	getViewProperties(type){
		jQuery("#summernotecss").attr("href", "js/libs/summernote/dist/summernote.css");
		return {
			templatePath : "views/report/views/summernote/SummernoteReportTemplate.html",
			templateName : "SummernoteReportTemplate",
			viewTypeStr : "appviews/report/views/summernote/SummernoteReportViewModel",
			tabId : "SummernoteReportView",
			tabName: "SummernoteReport"
		}
	}
//#startCustomMethods
	handlePackageNameChange(model,newName){
		if(Backbone.skipPersistance){
			return;
		}		
		var self = this;
		var alternativeId = DataManager.getDataManager().getRepositoryId(self.get('id'));
		if(!(alternativeId.indexOf("Common") > -1)){
		    DataManager.getDataManager().getAlternative(alternativeId,function(alternative){
		    	var phase = alternative.get('phaseAlternativeOwner');
		    	if(phase.get('master') === alternative || phase.get('primary') === alternative) {
			    	var plan = phase.get('phaseOwner');
					for(var j=0;j<plan.get('phase').length;j++){
						var alts = plan.get('phase').at(j).get('phaseAlternative');
						for(var k=0;k<alts.length;k++){
							var designParts = alts.at(k).get('phaseDesignPart');
							var packRef = designParts.findWhere({'beepReference':self.get('id')});
							if(packRef){
								packRef.set('name',newName);
								packRef.set('description',newName);
								break;
							}
						}	
					}
				}
				else {
					var designParts = alternative.get('phaseDesignPart');
					var packRef = designParts.findWhere({'beepReference':self.get('id')});
					if(packRef){
						packRef.set('name',newName);
						packRef.set('description',newName);
					}
				}
		    });	
	    }
	};
	
	deleteReport(callback){
		var self = this;
		var packAlts = [];
		var currentPlan = DataManager.getDataManager().get('currentPlan');
		var phases = currentPlan.get('phase');
        for(var i=0;i<phases.length;i++) {
            var alternatives = phases.models[i].get('phaseAlternative');
            for(var j=0;j<alternatives.length;j++){
            	var packageRefs = alternatives.at(j).get('phaseDesignPart');
            	packageRefs.each(function(packageRef){
	            	if(packageRef.get('beepReference') === self.get('id')){
	            		packAlts.push(alternatives.at(j));
	            	}
            	});
            }
        }
		self.destroy({success:function(){
			DataManager.getDataManager().removeInitializedPackage(self.get('id'));
			_.each(packAlts,function(alt){
    			var packRef = alt.get('phaseDesignPart').findWhere({'beepReference':self.get('id')});
				if(packRef){
					packRef.destroy();
				}
    		});
    		callback();
		},error:function(){
			console.log('Failed to remove package');
			DataManager.getDataManager().removeInitializedPackage(self.get('id'));
			callback();
		}});	
	};	
	
//#endCustomMethods
	//path.BeepReportMixin = BeepReportMixin;
	//return BeepReportMixin;
}