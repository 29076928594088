/* https://github.com/DiemenDesign/summernote-image-shapes */
(function (factory) {
  if (typeof define === 'function' && define.amd) {
    define(['jquery'],factory)
  } else if (typeof module === 'object' && module.exports) {
    module.exports = factory(require('jquery'));
  } else {
    factory(window.jQuery)
  }
}
(function ($) {
  $.extend(true,$.summernote.lang, {
    'en-US': {
      tableFloat: {
        tooltip: 'Float',
        tooltipShapeOptions: ['Left', 'Center', 'Right', 'None']
      }
    }
  });
  $.extend($.summernote.options, {
    tableFloat: {
      icon: '<i class="note-icon-align-left"/>',
      /* Must keep the same order as in lang.imageAttributes.tooltipShapeOptions */
      shapes: ['left', 'center', 'right', '']
    }
  });
  $.extend($.summernote.plugins, {
    'tableFloat': function(context) {
      var ui        = $.summernote.ui,
          $editable = context.layoutInfo.editable,
          options   = context.options,
          lang      = options.langInfo;
      context.memo('button.tableFloat', function() {
        var button = ui.buttonGroup([
          ui.button({
            className: 'dropdown-toggle',
            contents: options.tableFloat.icon + '&nbsp;&nbsp;<span class="caret"></span>',
            tooltip: lang.tableFloat.tooltip,
            data: {
              toggle: 'dropdown'
            }
          }),
          ui.dropdown({
            className: 'dropdown-shape',
            items: lang.tableFloat.tooltipShapeOptions,
            click: function (e) {
              e.preventDefault();
              var $button = $(e.target);
              var rng = context.invoke('createRange', $editable);
              var dom = $.summernote.dom;
              if (/*rng.isCollapsed() &&*/ rng.isOnCell()) {
                context.invoke('beforeCommand');
                var table = dom.ancestor(rng.commonAncestor(), dom.isTable)
                var $table = $(table);
				$table.colResizable({disable:true});
				var index = $.inArray($button.data('value'),lang.tableFloat.tooltipShapeOptions);
				var selectedOption = options.tableFloat.shapes[index];
				if(selectedOption == "center"){
					$table.css({'margin-left':'auto', 'margin-right':'auto'});
					$table.css('float','');
				}else {
					$table.css('float',selectedOption);
					if(selectedOption == ""){
						$table.css({'margin-left':'', 'margin-right':''});
						$table.colResizable({resizeMode: 'overflow', liveDrag:true});
					}
				}				
			  }
              context.invoke('editor.afterCommand');
            }
          })
        ]);
        return button.render();
      });
    }
  });
}));