import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {UserMixin} from './UserMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/tickets/UserMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,UserMixin
//, Ticket){
	var path = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
	/*if(!Ticket){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.Ticket){
			Ticket = importPath.Ticket;
		}
		else{
			require(["appbo/tickets/Ticket"],function(loadedModule){
				Ticket = loadedModule;
			});
		}
	}*/
	var User = EObject.extend(utils.customExtends({
		relations:UserMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			EObject.prototype.initialize.apply(this, arguments);
		}
		}
		, new UserMixin()
	));
//#startCustomMethods
	/*User.getInstanceFromPackageId = function(userId,packageId,version){
		var dataManager = DataManager.getDataManager();
		dataManager.fetchDocumentFromPackage(packageId,"appbo/vdml/ValueDeliveryModel",version,packageId,"appbo/vdml/ValueDeliveryModel",dataManager.get('vdmStore'),{
			success:function(beepPackage){
				var user = beepPackage.get("user").findWhere({'userId':userId});
				if(user){
					return user;
				}				
				user = new User({'userId':userId,userOwner:beepPackage});
				if(dataManager.get("gapiId") === userId){
					user.set('name',dataManager.get('gapiDisplayName'));
					user.set('pictureUrl',dataManager.get('gapiImageUrl'));
					user.set('icon',dataManager.get('gapiIcon'));
				}
				return user;
			},
			error:function(error){
				console.log("Colud not load current package");
			}
		});
	};
	User.getInstanceFromPackage = function(userId,beepPackage){
			
			var dataManager = DataManager.getDataManager();
			var user = beepPackage.get("user").findWhere({'userId':userId});
			if(user){
				return user;
			}				
			user = new User({'userId':userId,userOwner:beepPackage});
			if(dataManager.get("gapiId") === userId){
				user.set('name',dataManager.get('gapiDisplayName'));
				user.set('pictureUrl',dataManager.get('gapiImageUrl'));
				user.set('icon',dataManager.get('gapiIcon'));
			}
			return user;
	};*/
//#endCustomMethods
	User.abstract = false;
	path.User = User;
	//return User;
	export {User};
//});