import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasurement} from './DimensionalMeasurement'
import {DimensionalMeasurementMixin} from './DimensionalMeasurementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasurement","appbo/smm/DimensionalMeasurementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasurement,DimensionalMeasurementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	export class DirectMeasurementMixin {
        /*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            path.DirectMeasurementMixin = DirectMeasurementMixin;
        }*/
	defaults(){
		var ret = {
			type: "smm_DirectMeasurement"
		}
		return jQuery.extend(DimensionalMeasurement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!DirectMeasurementMixin.cummulativeRelations) {
            DirectMeasurementMixin.cummulativeRelations = _.union(DirectMeasurementMixin.getMixinRelations()
                , DimensionalMeasurementMixin.getCumulativeMixinRelations()
            );
        }
		return DirectMeasurementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_DimensionalMeasurement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            //{ name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "error",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			{name : "breakValue",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" }
			//{name : "value",type : "EDouble",defaultValue : "null",containingClass : "smm_DimensionalMeasurement" }
		]
	}
	getParent(){
		var container;
		return DimensionalMeasurement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return DimensionalMeasurement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/DirectMeasurementPropertiesTemplate.html",
			templateName : "DirectMeasurementPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/DirectMeasurementViewModel",
			tabId : "DirectMeasurementView",
			tabName: "DirectMeasurement"
		}
	}
	clone(data, obsMes,callback) {
	    var mestId = DataManager.getDataManager().guidGeneratorByOwner(this);
        var mest = new this.constructor({ id: mestId, name: this.get('name'), description: this.get('description'), observedMeasure: obsMes });
        var val = data ? data[this.get('measuredCharacteristic').id] : undefined;
	    mest.set('value', val ? val : this.get('value'));
        this.get('measuredCharacteristic').get('measurement').add(mest);
        var cond = DataManager.getDataManager().get('newObservations') ? DataManager.getDataManager().get('newObservations').indexOf(obsMes.get('observation').id) === -1 : true;
        if(cond){
        	mest.cloneParentRelationships(function () {
	            if (callback) {
	                callback(mest);
	            }
	        });
        }else{
        	if (callback) {
	        	callback(mest);
	        }
        }
	    return mest;
	}
    }
	path.DirectMeasurementMixin = DirectMeasurementMixin;
	
	//return DirectMeasurementMixin;
//});