import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AbstractMeasureElement} from './AbstractMeasureElement'
import {AbstractMeasureElementMixin} from './AbstractMeasureElementMixin'
import {Operation} from './Operation'
import {Stereotype} from './Stereotype'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/AbstractMeasureElement","appbo/smm/AbstractMeasureElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AbstractMeasureElement,AbstractMeasureElementMixin
, Operation

, Stereotype){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
		else{
			import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });
			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	
	if(!Stereotype){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Stereotype){
			Stereotype = importPath.Stereotype;
		}
		else{
			import('./Stereotype').then(({ default: Stereotype }) => {
                Stereotype = Stereotype;
            });
			/*require(["appbo/smm/Stereotype"],function(loadedModule){
				Stereotype = loadedModule;
			});*/
		}
	}
	export class ScopeMixin {

	defaults(){
		var ret = {
			type: "smm_Scope"
		}
		return jQuery.extend(AbstractMeasureElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"smm_Scope",
			key:"streotype",
			relatedModel:"smm.Stereotype",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!ScopeMixin.cummulativeRelations) {
            ScopeMixin.cummulativeRelations = _.union(ScopeMixin.getMixinRelations()
                , AbstractMeasureElementMixin.getCumulativeMixinRelations()
            );
        }
		return ScopeMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_AbstractMeasureElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "class",type : "EString",defaultValue : "null",containingClass : "smm_Scope" }
		]
	}
	getParent(){
		var container;
		return AbstractMeasureElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return AbstractMeasureElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/ScopePropertiesTemplate.html",
			templateName : "ScopePropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/ScopeViewModel",
			tabId : "ScopeView",
			tabName: "Scope"
		}
	}
	
	}
	path.ScopeMixin = ScopeMixin;
	//return ScopeMixin;
//});