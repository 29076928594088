import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {DataManager} from '../../../../com/vbee/data/DataManager'
//define(["require", "jquery", "underscore","async", "backbone", "Lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/transformation/NamedPlanElement", "appbo/transformation/AlternativeMixin", "appbo/ecomap/EcoMap"],
//function (require, jQuery, _,async, Backbone, Lawnchair, DataManager, global, NamedPlanElement, AlternativeMixin, EcoMap) {

    var path = DataManager.getDataManager().buildAppNsPath("ecomap.migration", global.version);
    var EcoMap1003 = function () {
    }

    EcoMap1003.migrate = function (ecoPackage,callback) {
        var self = this;
        var altId = DataManager.getDataManager().getRepositoryId(ecoPackage.id);
        var diagrams = ecoPackage.get('diagrams');
        if(ecoPackage.get('copySource')) {
            var oldAltId = DataManager.getDataManager().getRepositoryId(ecoPackage.get('copySource'));
            for (var i = 0; i < diagrams.length; i++) {
                ecoPackage.fixDiagramOnRevision(diagrams.at(i), altId, oldAltId);
            }
        }else {
            for (var i = 0; i < diagrams.length; i++) {
                var diagram = diagrams.at(i);
                var diagramType = diagram.get('type').split("_");
                var diagramTypeSuffix = "e";
                if(diagramType[1]){
                    diagramTypeSuffix = diagramType[1].charAt(0).toLowerCase();
                }
                //var suffixId = window.utils.getSuffix(diagram.id);
                var oldId = diagram.id;//window.utils.htmlEscape(oldAltId + suffixId);
                var newId = diagramTypeSuffix + window.utils.htmlEscape(diagram.id);
                var newXML = diagram.get('data');
                newXML = ecoPackage.fixXMLOnRevision(newXML,oldId,newId);
                diagram.set('data',newXML);
            }
        }
		callback(ecoPackage);

    }
    path.EcoMap1003 = EcoMap1003;

   // return EcoMap1003;
	export {EcoMap1003};
//});