import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasure} from './DimensionalMeasure'
import {NamedMeasureMixin} from './NamedMeasureMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasure","appbo/smm/NamedMeasureMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasure,NamedMeasureMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var NamedMeasure = DimensionalMeasure.extend(utils.customExtends({
		relations:NamedMeasureMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			DimensionalMeasure.prototype.initialize.apply(this, arguments);
			if(attributes.scale){
				this.set('scale',path.ScaleOfMeasurement[attributes.scale instanceof Object ? attributes.scale.name : attributes.scale]);
			}
		}
		}
		, new NamedMeasureMixin()
	));
	
	NamedMeasure.abstract = false;
	NamedMeasure.supported = false;
	path.NamedMeasure = NamedMeasure;
	export {NamedMeasure};
//});