// Generated from ExprLang.g4 by ANTLR 4.13.2
// jshint ignore: start
import antlr4 from 'antlr4';

import ExprLangLexer from './ExprLangLexer.js'
import ExprLangParser from './ExprLangParser.js'
import ExprLangListener from './ExprLangListener.js';

// This class defines a complete listener for a parse tree produced by ExprLangParser.
export default class ExprLangToJuliaListener extends ExprLangListener {

	constructor() {
        super();
        this.exprStack = [];
        this.ternStack = [];
        this.juliaCode = '';  // Collects the entire output
        this.binaryVarCounter = 1;  // Initialize binary variable counter
        this.containsIfElse = false;    // Flag for if-elseif-else expressions
        this.containsTernary = false;   // Flag for ternary expressions
        this.inTernary = false
    }
	// Enter a parse tree produced by ExprLangParser#program.
	enterProgram(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#program.
    exitProgram(ctx) {
        while (this.exprStack.length > 0) {
            this.juliaCode += this.popFromStack() + "\n";
        }
    }


	// Enter a parse tree produced by ExprLangParser#statement.
	enterStatement(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#statement.
    exitStatement(ctx) {
        const expr = this.popFromStack();
        //this.juliaCode += `${expr};\n`;
        this.juliaCode += `${expr}\n`;
    }


	// Enter a parse tree produced by ExprLangParser#parenvmpExpression.
	enterParenvmpExpression(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#parenvmpExpression.
    exitParenvmpExpression(ctx) {
        const expr = this.popFromStack();
        this.pushToStack(`(${expr})`)
    }

	exitMethodFunctionCall(ctx) {
        const objectName = this.popFromStack();
        const methodName = ctx.IDENTIFIER().getText();

        let expr = `${methodName}(`;
        if (ctx.vmpExprList() != null) {
            expr += `${this.popFromStack()}`;
            expr += `, ${objectName}`;
        }else{
            expr += '${objectName}'
        }
        expr += ')';

        this.pushToStack(expr);
    }

    exitSimpleFunctionCall(ctx) {
        const functionName = ctx.IDENTIFIER().getText();

        let expr = `${functionName}(`;
        if (ctx.vmpExprList() != null) {
            expr += this.popFromStack();
        }
        expr += ')';

        this.pushToStack(expr);
    }

	// Enter a parse tree produced by ExprLangParser#number.
	enterNumber(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#number.
    exitNumber(ctx) {
        this.pushToStack(ctx.NUMBER().getText())
    }


	// Enter a parse tree produced by ExprLangParser#string.
	enterString(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#string.
    exitString(ctx) {
        this.pushToStack(ctx.STRING().getText())
    }


	// Enter a parse tree produced by ExprLangParser#functionCall.
	enterFunctionCall(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#functionCall.
	exitFunctionCall(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#variable.
	enterVariable(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#variable.
    exitVariable(ctx) {
        this.pushToStack(ctx.IDENTIFIER().getText())
    }

	// Enter a parse tree produced by ExprLangParser#objectAccess.
	enterObjectAccess(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#objectAccess.
	exitObjectAccess(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#ternaryExpression.
	enterTernaryExpression(ctx) {
        this.inTernary = true;  // Start of ternary expression
	}

	// Exit a parse tree produced by ExprLangParser#ternaryExpression.
    exitTernaryExpression(ctx) {
        // Pop the false expression (right of ':')
        let falseExpr = this.popFromStack();
    
        // Pop the true expression (between '?' and ':')
        let trueExpr = this.popFromStack();
    
        // Pop the condition (left of '?')
        const condition = this.popFromStack();
        const assignVar = this.popFromStack();
        falseExpr = assignVar + " = " + falseExpr;
        trueExpr = assignVar + " = " + trueExpr;

        const M = "M"; // Placeholder for the Big-M constant
    
        // Create a unique binary variable for the condition
        const binaryVarName = `y_${this.binaryVarCounter++}`;
        let constraints = [];
        constraints.push(binaryVarName + '= @variable(model, binary=true, base_name=\"' + binaryVarName+ '_$(objectid(model))_$(randstring(5))\")');
    
        // Split the condition and handle operators
        const splitCondition = this.splitCondition(condition);
        const { lhs, operator, rhs } = splitCondition;
        
        //for (const cond of splitConditions) {
            if (operator.includes('>')) {
                constraints.push(`@constraint(model, ${lhs} >= ${rhs} - ${M} * (1 - ${binaryVarName}))`);
                constraints.push(`@constraint(model, ${lhs} <= ${rhs} + ${M} * ${binaryVarName})`);
            } else if (operator === '<') {
                // Condition is true when lhs < rhs
                constraints.push(`@constraint(model, ${lhs} <= ${rhs} + ${M} * (1 - ${binaryVarName}))`); // When condition is true
                constraints.push(`@constraint(model, ${lhs} >= ${rhs} - ${M} * ${binaryVarName})`);       // When condition is false
            } else if (operator === '==') {
                // Condition is true when lhs == rhs
                constraints.push(`@constraint(model, ${lhs} == ${rhs} + ${M} * (1 - ${binaryVarName}))`); // When condition is true
                constraints.push(`@constraint(model, ${lhs} != ${rhs} + ${M} * ${binaryVarName})`);       // When condition is false
            }
        //}
    
        // Create constraints for the true expression (when condition is true)
        if (trueExpr.includes('=')) {
            const [assignedVar, assignedValue] = trueExpr.split('=').map(s => s.trim());
            constraints.push(`@constraint(model, ${assignedVar} <= ${assignedValue} + ${M} * (1 - ${binaryVarName}))`);
            constraints.push(`@constraint(model, ${assignedVar} >= ${assignedValue} - ${M} * (1 - ${binaryVarName}))`);
        } 
        // else {
        //     constraints.push(`@constraint(model, ${trueExpr} <= ${M} * ${binaryVarName})`);
        //     constraints.push(`@constraint(model, ${trueExpr} >= -${M} * (1 - ${binaryVarName}))`);
        // }
    
        // Create constraints for the false expression (when condition is false)
        if (falseExpr.includes('=')) {
            const [assignedVar, assignedValue] = falseExpr.split('=').map(s => s.trim());
            constraints.push(`@constraint(model, ${assignedVar} <= ${assignedValue} + ${M} * ${binaryVarName})`);
            constraints.push(`@constraint(model, ${assignedVar} >= ${assignedValue} - ${M} * ${binaryVarName})`);
        } 
        // else {
        //     constraints.push(`@constraint(model, ${falseExpr} <= ${M} * (1 - ${binaryVarName}))`);
        //     constraints.push(`@constraint(model, ${falseExpr} >= -${M} * ${binaryVarName})`);
        // }
    
        // Push the generated constraints onto the expression stack
        this.pushToStack(constraints.join('\n'))
        this.containsTernary = true;
        this.inTernary = false;
    }
    


	// Enter a parse tree produced by ExprLangParser#unaryvmpExpression.
	enterUnaryvmpExpression(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#unaryvmpExpression.
    exitUnaryvmpExpression(ctx) {
        const operand = this.popFromStack();
        const op = ctx.unaryOp().getText();

        const expr = `${op}${operand}`;
        this.pushToStack(expr);
    }


	// Enter a parse tree produced by ExprLangParser#binaryvmpExpression.
	enterBinaryvmpExpression(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#binaryvmpExpression.
    exitBinaryvmpExpression(ctx) {
        const right = this.popFromStack();
        const left = this.popFromStack();
        if(left != undefined){
            const op = ctx.binaryOp().getText();
            const expr = `${left} ${op} ${right}`;
            this.pushToStack(expr);
        }else{
            this.pushToStack(right);    // to handle ternary expression
        }
    }


	// Enter a parse tree produced by ExprLangParser#binaryOp.
	enterBinaryOp(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#binaryOp.
	exitBinaryOp(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#unaryOp.
	enterUnaryOp(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#unaryOp.
	exitUnaryOp(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#func.
	enterFunc(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#func.
	exitFunc(ctx) {
	}


	// Enter a parse tree produced by ExprLangParser#vmpExprList.
	enterVmpExprList(ctx) {
	}

	// Exit a parse tree produced by ExprLangParser#vmpExprList.
    exitVmpExprList(ctx) {
        if (!ctx.vmpExpr() || ctx.vmpExpr().length == 0 ) {
            // If the list is empty, do nothing
            return;
        }
        let args = [];
        for (let i = 0; i < ctx.vmpExpr().length; i++) {
            args.push(this.popFromStack());
        }
        args = args.reverse().join(', ');
        this.pushToStack(args);
    }

    exitArray(ctx) {
        // Create an array to hold the arguments
        let args = [];
        let argsStr = ""; // String to build the final array representation
        
        // Pop the top value from the exprStack and split it by commas to get array elements
        let arrElements = this.popFromStack().split(",");
    
        // Start the array string representation with an opening bracket
        argsStr += "[";
    
        // Loop through array elements and construct the array string
        for (let i = 0; i < arrElements.length; i++) {
            argsStr += (i === 0) ? arrElements[i] : ("," + arrElements[i]);
        }
    
        // Close the array string with a closing bracket
        argsStr += "]";
    
        // Push the constructed array string back onto the exprStack
        this.pushToStack(argsStr);
    }

	// Exit a parse tree produced by ExprLangParser#objAccess.
    exitObjAccess(ctx) {
        let expr = '';

        //const hasSelf = ctx.getText().startsWith('self');
        //const startIndex = hasSelf ? 1 : 0;
        const startIndex =0;
        let firstToken = true;

        for (let i = startIndex; i < ctx.getChildCount(); i++) {
            const child = ctx.getChild(i);

            if (child instanceof antlr4.tree.TerminalNode) {
                const tokenStr = child.getText();

                if (
                    child.symbol.type === ExprLangLexer.IDENTIFIER ||
                    ['self','bm', 'vp', 'activity', 'value', 'quantity'].includes(tokenStr)
                ) {
                    if (firstToken) {
                        expr += tokenStr;
                        firstToken = false;
                    } else {
                        expr += `["${tokenStr}"]`;
                    }
                }
            } else if (child instanceof ExprLangParser.ObjAccessContext) {
                // Handle nested object access
                let nestedExpr;
                nestedExpr = this.popFromStack();
                expr += `["${nestedExpr}"]`;
            } else if (child instanceof ExprLangParser.VmpExprContext) {
                // Handle array access like x[y] or x[1]
                let arrayIndex;
                arrayIndex = this.popFromStack();
                expr += `[${arrayIndex}]`;
            }
        }
        this.pushToStack(expr)
    }

    pushToStack(expr){
        // if (this.inTernary) {
        //     this.ternStack.push(expr);
        // } else {
            this.exprStack.push(expr);
        //}
    }
    popFromStack(){
        // if(this.inTernary){
        //     return this.ternStack.pop();
        // }else{
            return this.exprStack.pop();    
        //}
    }
    exitVmpExpr(ctx) {
        // Push the expression onto the stack, handling ternary expressions, etc.
        if (ctx.ternaryExpression()) {
            const falseExpr = this.popFromStack();
            const trueExpr = this.popFromStack();
            const condition = this.popFromStack();
            const expr = `${condition} ? ${trueExpr} : ${falseExpr}`;
            this.pushToStack(expr);            
        } else {
            const expr = ctx.getText();
            this.pushToStack(expr);
        }
        
    }

    exitIfElseExpr(ctx) {
        const childCount = ctx.getChildCount();
        const conditions = [];
        const thenBlocks = [];
    
        // Check if there is an 'else' block
        let hasElse = childCount >= 6 && ctx.getChild(childCount - 3).getText() === 'else';
    
        // Capture the else block (if present)
        let elseBlock = '';
        if (hasElse) {
            elseBlock = this.popFromStack(); // Pop the else action
        }
    
        // Now pop the last 'then' block and its condition
        const thenBlock = this.popFromStack(); // Pop the last then expression
        const condition = this.popFromStack(); // Pop the last condition
        conditions.push(condition);
        thenBlocks.push(thenBlock);
    
        // Handle any elseif cases
        let index = 3;
        while (index < childCount) {
            if (ctx.getChild(index).getText() === 'elseif') {
                const elseifThenBlock = this.popFromStack(); // Pop the elseif action
                const elseifCondition = this.popFromStack(); // Pop the elseif condition
                conditions.push(elseifCondition);
                thenBlocks.push(elseifThenBlock);
                index += 2;
            } else {
                break;
            }
        }
    
        // Create constraints based on conditions and then blocks
        let constraints = [];
        const M = "M"; // Placeholder for the Big-M constant
        // Binary variables for each condition
        const binaryVars = conditions.map((_, i) => `y_${this.binaryVarCounter++}`);
        binaryVars.forEach((binaryVar, i) => {
            constraints.push(`${binaryVar} = @variable(model, binary=true, base_name=\"${binaryVar}_$(objectid(model))_$(randstring(5))\")`);
        });
        // Iterate through each condition and then block
        for (let i = 0; i < conditions.length; i++) {
            const condition = conditions[i];
            const thenBlockExpr = thenBlocks[i];
            const binaryVarName = binaryVars[i];
            //constraints.push(`${binaryVarName} = @variable(model, binary=true, base_name=\"${binaryVarName}_$(objectid(model))_$(randstring(5))\")`);
    
            // Split the condition to get lhs, operator, and rhs
            const splitCondition = this.splitCondition(condition);
            const { lhs, operator, rhs } = splitCondition;
    
            // Depending on the operator, generate two constraints (one for true, one for false)
            if (operator === '>') {
                // Condition is true when lhs > rhs
                constraints.push(`@constraint(model, ${lhs} >= ${rhs} - ${M} * (1 - ${binaryVarName}))`); // When condition is true
                constraints.push(`@constraint(model, ${lhs} <= ${rhs} + ${M} * ${binaryVarName})`);       // When condition is false
            } else if (operator === '<') {
                // Condition is true when lhs < rhs
                constraints.push(`@constraint(model, ${lhs} <= ${rhs} + ${M} * (1 - ${binaryVarName}))`); // When condition is true
                constraints.push(`@constraint(model, ${lhs} >= ${rhs} - ${M} * ${binaryVarName})`);       // When condition is false
            } else if (operator === '==') {
                // Condition is true when lhs == rhs
                constraints.push(`@constraint(model, ${lhs} == ${rhs} + ${M} * (1 - ${binaryVarName}))`); // When condition is true
                constraints.push(`@constraint(model, ${lhs} != ${rhs} + ${M} * ${binaryVarName})`);       // When condition is false
            }
    
            // Process assignments in the then blocks
            if (thenBlockExpr.includes('=')) {
                const [assignedVar, assignedValue] = thenBlockExpr.split('=').map(s => s.trim());
    
                // Create Big-M constraints for assignment within the 'then' block
                constraints.push(`@constraint(model, ${assignedVar} <= ${assignedValue} + ${M} * (1 - ${binaryVarName}))`);
                constraints.push(`@constraint(model, ${assignedVar} >= ${assignedValue} - ${M} * (1 - ${binaryVarName}))`);
            } else {
                // Regular condition handling without assignment (expression in the block)
                constraints.push(`@constraint(model, ${thenBlockExpr} <= ${M} * ${binaryVarName})`); // If true, apply the block
                constraints.push(`@constraint(model, ${thenBlockExpr} >= -${M} * (1 - ${binaryVarName}))`); // If false, deactivate
            }
        }
    
        // Handle the 'else' block if present
        if (hasElse) {
            // Process assignments in the else block
            if (elseBlock.includes('=')) {
                const [assignedVar, assignedValue] = elseBlock.split('=').map(s => s.trim());
    
                // Create Big-M constraints for assignment within the 'else' block
                constraints.push(`@constraint(model, ${assignedVar} <= ${assignedValue} + ${M} * (${binaryVars.join(' + ')}))`); // If true
                constraints.push(`@constraint(model, ${assignedVar} >= ${assignedValue} - ${M} * (${binaryVars.join(' + ')}))`); // If false
            } else {
                // Regular else handling without assignment
                constraints.push(`@constraint(model, ${elseBlock} <= ${M} * (${binaryVars.join(' + ')}))`); // If true
                constraints.push(`@constraint(model, ${elseBlock} >= -${M} * (1 - (${binaryVars.join(' + ')})))`); // If false
            }
        }
    
        // Ensure that only one condition is active at a time
        constraints.push(`@constraint(model, ${binaryVars.join(' + ')} <= 1)`);
    
        // Push the generated constraints onto the expression stack
        this.pushToStack(constraints.join('\n'))
        this.containsIfElse = true;
    }
    
    // Helper function to split conditions into valid comparison operators
    splitCondition(condition) {
        // Trim whitespace
        condition = condition.trim();
    
        // Check if the condition starts and ends with parentheses
        if (condition.startsWith('(') && condition.endsWith(')')) {
            // Ensure the parentheses are balanced
            if (this.areParenthesesBalanced(condition)) {
                // Remove the outermost parentheses
                condition = condition.substring(1, condition.length - 1).trim();
            }
        }
    
        // Handle binary or complex conditions
        let lhs, rhs, operator;
    
        // List of binary operators to check
        const operators = ['<=', '>=', '==', '!=', '<', '>'];
        
        for (let op of operators) {
            if (condition.includes(op)) {
                // Split the condition based on the operator
                [lhs, rhs] = condition.split(op);
                operator = op;
                break;
            }
        }
    
        // Return an object with the left-hand side, operator, and right-hand side
        return {
            lhs: lhs ? lhs.trim() : condition, // Default to condition if no operator found
            operator: operator || '', // If no operator found, return an empty string
            rhs: rhs ? rhs.trim() : '' // Default to empty string if no rhs found
        };
    }
    
    areParenthesesBalanced(expression) {
        let stack = [];
        
        for (let char of expression) {
            if (char === '(') {
                stack.push(char);
            } else if (char === ')') {
                if (stack.length === 0) {
                    return false; // Unmatched closing parenthesis
                }
                stack.pop();
            }
        }
    
        return stack.length === 0; // If the stack is empty, parentheses are balanced
    }
        

    getResult() {
        return {
            code: this.juliaCode.trim(),
            containsIfElse: this.containsIfElse,
            containsTernary: this.containsTernary
        };
    }

}