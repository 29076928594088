import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurementRelationship} from './MeasurementRelationship'
import {RefinementMeasurementRelationshipMixin} from './RefinementMeasurementRelationshipMixin'
import {Measurement} from './Measurement'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasurementRelationship","appbo/smm/RefinementMeasurementRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurementRelationship,RefinementMeasurementRelationshipMixin
, Measurement
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Measurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measurement){
			Measurement = importPath.Measurement;
		}
		else{
			import('./Measurement').then(({ default: Measurement }) => {
                Measurement = Measurement;
            });
			/*require(["appbo/smm/Measurement"],function(loadedModule){
				Measurement = loadedModule;
			});*/
		}
	}
	
	var RefinementMeasurementRelationship = MeasurementRelationship.extend(utils.customExtends({
		relations:RefinementMeasurementRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			MeasurementRelationship.prototype.initialize.apply(this, arguments);
		}
		}
		, new RefinementMeasurementRelationshipMixin()
	));
	
	RefinementMeasurementRelationship.abstract = false;
	RefinementMeasurementRelationship.supported = false;
	path.RefinementMeasurementRelationship = RefinementMeasurementRelationship;
	export {RefinementMeasurementRelationship};
//});