import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
//import * as  backboneLawnchair from 'backbonelawnchair'
import {SmmElement} from './SmmElement'
import {AbstractMeasureElementMixin} from './AbstractMeasureElementMixin'
import * as LocaleManager from '../../../com/vbee/data/LocaleManager'
import { DataManager } from '../../../com/vbee/data/DataManager'
//import { MeasureLibrary } from './MeasureLibrary';


//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/AbstractMeasureElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,AbstractMeasureElementMixin
//, MeasureLibrary){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	//if(!MeasureLibrary){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.MeasureLibrary){
			MeasureLibrary = importPath.MeasureLibrary;
		}
        else {
            /*import('./MeasureLibrary').then(({ default: MeasureLibrary }) => {
                MeasureLibrary = MeasureLibrary;
            });*/
			/*require(["appbo/smm/MeasureLibrary"],function(loadedModule){
				MeasureLibrary = loadedModule;
			});*/
		}
	//}
	var AbstractMeasureElement = SmmElement.extend(utils.customExtends({
		relations:AbstractMeasureElementMixin.getMixinRelations(),
		subModelTypes: {
			smm_Characteristic : 'smm.Characteristic',
			smm_Scope : 'smm.Scope',
			smm_OCLOperation : 'smm.OCLOperation',
			smm_UnitOfMeasure : 'smm.UnitOfMeasure',
			smm_Operation : 'smm.Operation',
			smm_Measure : 'smm.Measure',
			smm_MeasureCategory : 'smm.MeasureCategory',
		},
		initialize: function(attributes, options) {
			SmmElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new AbstractMeasureElementMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	AbstractMeasureElement.abstract = true;
	AbstractMeasureElement.supported = !sp2Enabled;
	path.AbstractMeasureElement = AbstractMeasureElement;
	export {AbstractMeasureElement};
//});