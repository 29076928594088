import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurementRelationship} from './MeasurementRelationship'
import {MeasurementRelationshipMixin} from './MeasurementRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { Measurement } from './Measurement'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasurementRelationship","appbo/smm/MeasurementRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurementRelationship,MeasurementRelationshipMixin
, Measurement
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Measurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measurement){
			Measurement = importPath.Measurement;
		}
        else {
            import('./Measurement').then(({ default: Measurement }) => {
                Measurement = Measurement;
            });
			/*require(["appbo/smm/Measurement"],function(loadedModule){
				Measurement = loadedModule;
			});*/
		}
	}
	
	export class EquivalentMeasurementRelationshipMixin {
        /*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            path.EquivalentMeasurementRelationshipMixin = EquivalentMeasurementRelationshipMixin;
        }*/
	defaults(){
		var ret = {
			type: "smm_EquivalentMeasurementRelationship"
		}
		return jQuery.extend(MeasurementRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!EquivalentMeasurementRelationshipMixin.cummulativeRelations) {
            EquivalentMeasurementRelationshipMixin.cummulativeRelations = _.union(EquivalentMeasurementRelationshipMixin.getMixinRelations()
                , MeasurementRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return EquivalentMeasurementRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_MeasurementRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" }
		]
	}
	getParent(){
		var container;
		return MeasurementRelationship.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return MeasurementRelationship.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/EquivalentMeasurementRelationshipPropertiesTemplate.html",
			templateName : "EquivalentMeasurementRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/EquivalentMeasurementRelationshipViewModel",
			tabId : "EquivalentMeasurementRelationshipView",
			tabName: "EquivalentMeasurementRelationship"
		}
        }
    }
	path.EquivalentMeasurementRelationshipMixin = EquivalentMeasurementRelationshipMixin;
	
	//return EquivalentMeasurementRelationshipMixin;
//});