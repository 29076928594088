import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelDimension} from './BusinessModelDimension'
import {BusinessModelDimensionMixin} from './BusinessModelDimensionMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { Role } from './Role'


/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelDimension","appbo/vdml/BusinessModelDimensionMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelDimension,BusinessModelDimensionMixin
, Role){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Role){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Role){
			Role = importPath.Role;
		}
        else {
            import('./Role').then(({ default: Role }) => {
                Role = Role;
            });
			/*require(["appbo/vdml/Role"],function(loadedModule){
				Role = loadedModule;
			});*/
		}
	}
	export class CustomerDimensionMixin {

	defaults(){
		var ret = {
			type: "vdml_CustomerDimension"
		}
		return jQuery.extend(BusinessModelDimension.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CustomerDimension",
			key:"users",
			relatedModel:"vdml.Role",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!CustomerDimensionMixin.cummulativeRelations) {
            CustomerDimensionMixin.cummulativeRelations = _.union(CustomerDimensionMixin.getMixinRelations()
                , BusinessModelDimensionMixin.getCumulativeMixinRelations()
            );
        }
		return CustomerDimensionMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_BusinessModelDimension"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("customerDimensionOwner") ? this.get("customerDimensionOwner") : this.previousAttributes().customerDimensionOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/CustomerDimensionPropertiesTemplate.html",
			templateName : "CustomerDimensionPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/CustomerDimensionViewModel",
			tabId : "CustomerDimensionView",
			tabName: "CustomerDimension"
		}
	}
	
	}
	path.CustomerDimensionMixin = CustomerDimensionMixin;
	//return CustomerDimensionMixin;
//});