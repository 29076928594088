import * as $ from "jquery";
import * as _ from "underscore";
import * as Backbone from "backbone";
import * as ko from "knockout";
import * as kb from "knockback";
import { DataManager } from "../../../../../com/vbee/data/DataManager";
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import { AlternativeInputValuesViewModel } from "./AlternativeInputValuesViewModel";

var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties",global.version);


export class AlternativeInputValues2ViewModel extends AlternativeInputValuesViewModel{

    init(model,options){
        super.init(model,options);
        var self = this;
		self.options = options;
		this.AlternativeInputValues2ViewModel = this;
    }
    showDirectValues(callback){
    }
    afterRenderView(node,view){
        super.afterRenderView(node,view);
        var self = this;
    }
    static getInstance(model, options) {
        var view = new AlternativeInputValues2ViewModel(model, options);
        // view.init(model, options);
        return view;
    }
}
path.AlternativeInputValues2ViewModel = AlternativeInputValues2ViewModel;