import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {PresentationMixin} from '../dashboard/PresentationMixin'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {VdmlElement} from '../vdml/VdmlElement'
/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/dashboard/PresentationMixin"],
function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, VdmlElement, PresentationMixin
){*/
	
    var path = DataManager.getDataManager().buildAppNsPath("dashboard", global.version);
	var Presentation = VdmlElement.extend(utils.customExtends({
		relations:PresentationMixin.getMixinRelations(),
		initialize: function(attributes, options) {
		    VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new PresentationMixin()
	));
	
	Presentation.getInstance = function(name,description,owner, chartConfig ){
		var aid = DataManager.getDataManager().guidGeneratorByOwner(owner);
		//var analytics = new Presentation({ id: aid, name: owner.getUniqueProperty('name', name, 'presentation'), description: owner.getUniqueProperty('description', description, 'presentation'), analytics: owner,  });
		var analytics = new Presentation({ id: aid, name: name, description: description, analytics: owner, 'chartConfig': chartConfig });
		/*if(chartConfig){
			analytics.set('chartConfig', chartConfig);
		}*/
		/*if (planBM && planBM.id) {
		    analytics.set('bmContext', planBM.id);
		    analytics.set('bmContextParent', planBM.parent);
		    analytics.set('bmContextVersion', planBM.version);
		}*/

		return analytics;
	}
	/*Presentation.prototype.getSystemTags = function () {
	    return ['VDM', 'Analytics Chart','Adopt'];
	};*/
	
	Presentation.abstract = false;
	Presentation.supported = true;
	path.Presentation = Presentation;
	export {Presentation};
	//return Presentation;
//});