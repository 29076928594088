import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmRelationship} from './SmmRelationship'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Measure } from './Measure'
import { Operation } from './Operation'
import {MeasureRelationshipMixin} from './MeasureRelationshipMixin'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmRelationship","appbo/smm/MeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmRelationship,MeasureRelationshipMixin
, Measure
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Measure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measure){
			Measure = importPath.Measure;
		}
        else {
            import('./Measure').then(({ default: Measure }) => {
                Measure = Measure;
            });
            /*require(["appbo/smm/Measure"], function (loadedModule) {
                Measure = loadedModule;
			});*/
		}
	}
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
        else {
            import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });
			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	var MeasureRelationship = SmmRelationship.extend(utils.customExtends({
		relations:MeasureRelationshipMixin.getMixinRelations(),
		subModelTypes: {
			smm_RefinementMeasureRelationship : 'smm.RefinementMeasureRelationship',
			smm_EquivalentMeasureRelationship : 'smm.EquivalentMeasureRelationship',
			smm_RankingMeasureRelationship : 'smm.RankingMeasureRelationship',
			smm_GradeMeasureRelationship : 'smm.GradeMeasureRelationship',
			smm_RescaledMeasureRelationship : 'smm.RescaledMeasureRelationship',
			smm_BaseMeasureRelationship : 'smm.BaseMeasureRelationship'
		},
		initialize: function(attributes, options) {
			SmmRelationship.prototype.initialize.apply(this, arguments);
			if(attributes.influence){
				this.set('influence',path.Influence[attributes.influence instanceof Object ? attributes.influence.name : attributes.influence]);
			}
		}
		}
		, new MeasureRelationshipMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	MeasureRelationship.abstract = sp2Enabled;
	MeasureRelationship.supported = !sp2Enabled
	path.MeasureRelationship = MeasureRelationship;
	export {MeasureRelationship};
//});