import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasure} from './DimensionalMeasure'
import {DirectMeasureMixin} from './DirectMeasureMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { Operation } from './Operation'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasure","appbo/smm/DirectMeasureMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasure,DirectMeasureMixin
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
        else {
            import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });
			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	var DirectMeasure = DimensionalMeasure.extend(utils.customExtends({
		relations:DirectMeasureMixin.getMixinRelations(),
		subModelTypes: {
			smm_CountingMeasure : 'smm.CountingMeasure'
		},
		initialize: function(attributes, options) {
			DimensionalMeasure.prototype.initialize.apply(this, arguments);
			if(attributes.scale){
				this.set('scale',path.ScaleOfMeasurement[attributes.scale instanceof Object ? attributes.scale.name : attributes.scale]);
			}
		}
		}
		, new DirectMeasureMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	DirectMeasure.abstract = false;
	DirectMeasure.supported = !sp2Enabled;
	path.DirectMeasure = DirectMeasure;
	export {DirectMeasure};
//});