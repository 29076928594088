import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelActivityMixin} from './BusinessModelActivityMixin'
import {BusinessModelItem} from './BusinessModelItem'
import {Activity} from './Activity'
import {ValueElement} from './ValueElement'
import {OutputPort} from './OutputPort'
import {ValueAdd} from './ValueAdd'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelItem","appbo/vdml/BusinessModelActivityMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelItem,BusinessModelActivityMixin
, Activity
, ValueElement
, OutputPort
, ValueAdd){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Activity){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Activity){
			Activity = importPath.Activity;
		}
		else{
			import('./Activity').then(({ default: Activity }) => {
                Activity = Activity;
            });
            /*require(["appbo/vdml/Activity"],function(loadedModule){
                Activity = loadedModule;
            });*/
		}
	}
	if(!ValueElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueElement){
			ValueElement = importPath.ValueElement;
		}
		else{
			import('./ValueElement').then(({ default: ValueElement }) => {
                ValueElement = ValueElement;
            });
			/*require(["appbo/vdml/ValueElement"],function(loadedModule){
				ValueElement = loadedModule;
			});*/
		}
	}
	if(!OutputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OutputPort){
			OutputPort = importPath.OutputPort;
		}
		else{
			import('./OutputPort').then(({ default: OutputPort }) => {
                OutputPort = OutputPort;
            });
			/*require(["appbo/vdml/OutputPort"],function(loadedModule){
				OutputPort = loadedModule;
			});*/
		}
	}
	if(!ValueAdd){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueAdd){
			ValueAdd = importPath.ValueAdd;
		}
		else{
			import('./ValueAdd').then(({ default: ValueAdd }) => {
                ValueAdd = ValueAdd;
            });
			/*require(["appbo/vdml/ValueAdd"],function(loadedModule){
				ValueAdd = loadedModule;
			});*/
		}
	}
	var BusinessModelActivity = BusinessModelItem.extend(utils.customExtends({
		relations:BusinessModelActivityMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BusinessModelItem.prototype.initialize.apply(this, arguments);
			this.on("change:activity", this.handleOnActivityDeleted);
		}
		}
		, new BusinessModelActivityMixin()
	));
	
	BusinessModelActivity.abstract = false;
	path.BusinessModelActivity = BusinessModelActivity;
	export {BusinessModelActivity};
//});