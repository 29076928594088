import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as  bootbox  from '../../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import * as BpmnModeler from '../../../../../../../libs/bower-bpmn-js/dist/bpmn-modeler.development'
import * as bpmnnavigatedviewer from '../../../../../../../libs/bower-bpmn-js/dist/bpmn-navigated-viewer.development'
import { DataManager } from '../../../../../../com/vbee/data/DataManager'
import { ProcessDiscoveryDiagramMixin } from '../../../../../bo/vdml/ProcessDiscoveryDiagramMixin'
import {ProcessDiscoveryDiagram} from '../../../../../bo/vdml/ProcessDiscoveryDiagram'
import {ProcessDiagramDetailsViewModel} from './ProcessDiagramDetailsViewModel'
import {BMGuidanceViewModel} from '../../../../help/views/properties/BMGuidanceViewModel'
import {EcoMapDiagramMixin} from '../../../../../bo/vdml/EcoMapDiagramMixin'

/*define(["require", "jquery", "underscore", "backbone", "knockout", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/BusinessModel", "appbo/vdml/Participant", "appbo/vdml/ValueElement", "appbo/vdml/ProcessDiscoveryDiagram", "appbo/vdml/ProcessDiscoveryDiagramMixin", "knockback", "bpmnmodeler", "bpmnnavigatedviewer", "appviews/help/views/properties/BMGuidanceViewModel", "appbo/beeppackage/CodeContainer", "appviews/vdml/views/designer/processModel/ProcessDiagramDetailsViewModel", "appbo/vdml/EcoMapDiagramMixin"],
    function (require, $, _, Backbone, ko, bootbox, DataManager, global, BusinessModel, Participant, ValueElement, ProcessDiscoveryDiagram, ProcessDiscoveryDiagramMixin, kb, BpmnModeler, BpmnNavigatedViewer, BMGuidanceViewModel, CodeContainer, ProcessDiagramDetailsViewModel, EcoMapDiagramMixin) {*/
	var path = DataManager.getDataManager().buildAppNsPath("vdml.views.designer.processModel", global.version);
export class ProcessDiscoveryDiagramViewModel {
	constructor(model, options) {
		var self = this;
		this.init(model, options);
	}
	getMappedObj(type) {
		var shapeType1 = type.split(':');
		var shapeType = shapeType1[1];
		var typeToDisplay = shapeType.split(/(?=[A-Z])/).join(' ');
		return typeToDisplay;

	}

	createRevisionBasedOnContext(pack,callback){
		if (window.checkContextForRevision()) {
			window.utils.stopSpinner('processDiscoverySpinner');
			window.utils.startSpinner('revisionSpinner', "Creating a copy...");
			function fetchBmModel(newVdmPackage){
					if(newVdmPackage) {
						DataManager.getDataManager().releaseSaveLock();
						DataManager.getDataManager().set('isActive',false);
						callback(newVdmPackage);
						/*DataManager.getDataManager().saveData({
							success:function(){
								callback(newVdmPackage);
							}, 
							error:function(){
								callback(newVdmPackage);
							}, 
							persist:true});	*/
					}
				}
			window.setTimeout(function(){
				DataManager.getDataManager().acquireSaveLock(function () {
					pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
				});
			},100);	
		}
		else {
			callback(pack);
		}
	};
	getViewportSize(w) {

		// Use the specified window or the current window if no argument
		w = w || window;

		// This works for all browsers except IE8 and before
		if (w.innerWidth != null) return { w: w.innerWidth, h: w.innerHeight };

		// For IE (or any browser) in Standards mode
		var d = w.document;
		if (document.compatMode == "CSS1Compat")
			return {
				w: d.documentElement.clientWidth,
				h: d.documentElement.clientHeight
			};

		// For browsers in Quirks mode
		return { w: d.body.clientWidth, h: d.body.clientHeight };

	}
	async openDiagram(xml,callback) {
		var self = this;
		try{
			const result = await self.modeler.importXML(xml);
			const {warnings } = result;
			console.log(warnings);
			self.modeler.get("canvas").zoom("fit-viewport", true);
			//$('svg').css('margin-left', 70);
			if(!self.showDashboardProcessDiscovery){
					var height = self.model.get('height');
					if (!height) {
						height = self.getViewportSize().h * 0.90 + $('.navbar').height();
					}
					$("#js-canvas" + self.encodeId).height(height);
			}
			self.loadTable();
			window.utils.stopSpinner('processSpinner');
			if (callback) {
				callback();
			}
		}catch(err){
			console.log(err.message, err.warnings);
			window.utils.stopSpinner('processSpinner');
			if (callback) {
				callback();
			}
		}
	}
	showProperties(data,ev) {
		var self = window.vdmModelView;
		if(self.editMode){
			var id = window.utils.htmlEscape(window.guidGenerator());
			var elementRegistry = self.modeler.get('elementRegistry');
			var options = { businessObject: data.businessObject, element: elementRegistry.get(data.businessObject.id), width: '660px' };
			window.getAndCreateModalDialog(self, id,EcoMapDiagramMixin, self.model, "ShapeProperties", null, options);
		}
	}
	loadTable(event){
		var self = this;
		self.shapesList.removeAll();
		self.unsortedShapesList = [];
		var shapes ,definitions ,processDiscovery ,modeler = self.modeler;
		if(modeler) {
			definitions = modeler._definitions;
		}
		if(definitions) {
			processDiscovery = definitions.rootElements;
		}			
		if(processDiscovery && processDiscovery.length) {
			for(var a = 0;a<processDiscovery.length;a++){
				shapes = processDiscovery[a].flowElements ? processDiscovery[a].flowElements : processDiscovery[a].participants;
			
				if(shapes){ 
				for (var i = 0; i < shapes.length; i++) {
					if (event && event.type === 'shape.remove' && event.element.id === shapes[i].id) {
						continue;
					}
					var type = shapes[i].$type;
					if (type === "bpmn:DataObject" || type === 'bpmn:SequenceFlow') { 
						continue;
					}
					var typeToDisplay = self.getMappedObj(type);
					var name = shapes[i].name;
					var mappingEleId = shapes[i].mid;
					var packId = shapes[i].mpid;
					var mappingEleName = '';
					if(typeToDisplay && shapes[i]){
						self.shapesList.push({ businessObject: shapes[i], name: name, type: typeToDisplay, mappedEle: mappingEleName });
						self.unsortedShapesList.push({ businessObject: shapes[i], name: name, type: typeToDisplay, mappedEle: mappingEleName });
					}
				}
				}
			}
		}
		self.setResizeHeight();
		self.shapesList.sort(self.sortShapesInTableByName);
	}
	
	cleanUp() {
		var self = this;
		if (self.modeler) {
			self.modeler.destroy();
		}
		if(ko.components.isRegistered('ProcessDiscoveryDetails')){
			ko.components.unregister('ProcessDiscoveryDetails');
		}
		if(ko.components.isRegistered('ProcessDiscoveryGuidance')){
			ko.components.unregister('ProcessDiscoveryGuidance');
		}
		window.utils.loadTemplate(null,true);
		if(self.spliter){
			self.spliter.enhsplitter('remove');
		}
		/*if(self.loadedCodedContainers){
			var codeContainersList = self.loadedCodedContainers;
			_.each(codeContainersList, function (codeContainer) {
				codeContainer.save(null,{
					success: function () {
						DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
					},
					error:function(error){
						console.log(error);
						//DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
					}
				});
			});
			codeContainersList.length = 0;
		}*/
	}
	createRevision(newXML,callback,depPacks) {
		var self = this;
		window.utils.startSpinner('revisionSpinner', "Creating a copy...");
		setTimeout(function(){
			self.model.getNestedParent().createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), function (newPackage) {
				var newRep = DataManager.getDataManager().getRepositoryId(newPackage.id);
				var modelSuffix = self.model.id.substr(self.model.id.lastIndexOf('@') + 1);
				var newDiagram = Backbone.Relational.store.getObjectByName("vdml.ProcessDiscoveryDiagram").find({ id: newRep + modelSuffix });
				var oldId = "Process_"+ window.utils.htmlEscape(self.model.id);
				var newId = "Process_"+ window.utils.htmlEscape(newDiagram.id);
				newXML = newPackage.fixXMLOnRevision(newXML,oldId,newId);
				newDiagram.set('data', newXML, { silent: true });
				window.utils.stopSpinner('revisionSpinner');
				callback(newPackage,newDiagram);
			},depPacks);
		},10);
	}
	saveDiagram(view,event,callback,revisionProcessDiscovery,vdmlPackageRevision,enableComplete){
		var self = this;
		if (self.model) {
			window.utils.startSpinner('saveSpinner', "Saving Process...");
			setTimeout(async function () {
				try{
					const result = await self.modeler.saveXML({ format: true });
					const { xml } = result;
					if (xml) {
						//var oldData = self.model.get('data');
						if (window.checkContextForRevision()) {
							if(revisionProcessDiscovery){
								if(vdmlPackageRevision){
									var newXml = window.utils.replaceAll(xml,DataManager.getDataManager().get('currentWorkspace').id,DataManager.getDataManager().get('viewAlternative'));
									self.model.set('data', newXml);
								}else{
									self.model.set('data', xml);
								}
								self.enableComplete(false);
								window.utils.stopSpinner('saveSpinner');
								if(callback){
									callback();
								}else {
									DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + revisionProcessDiscovery.getNestedParent().get('id') + "/ecomap_ProcessDiscovery/" + revisionProcessDiscovery.getNestedParent().get('version') + "/" + revisionProcessDiscovery.get('id') + "/vdml_ProcessDiscoveryDiagram/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
								}	
							}else {
								//self.model.set('data', newXml, { silent: true });
								if(!revisionProcessDiscovery && !vdmlPackageRevision && !self.enableComplete()) {
									window.utils.stopSpinner('saveSpinner');
									if(callback){
										callback();
									} 		
								}else {
									var ecoPackage = self.model.getNestedParent();
									var depPacks = ecoPackage.get('dependentPackage')?ecoPackage.get('dependentPackage').models.concat():[];
									self.createRevision(xml,function(newPackage,newDiagram){
										self.enableComplete(false);
										window.utils.stopSpinner('saveSpinner');
										if(callback){
											callback();
										}else{
											DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newPackage.get('id') + "/ecomap_ProcessDiscovery/" + newPackage.get('version') + "/" + newDiagram.get('id') + "/vdml_ProcessDiscoveryDiagram/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
										}
									},depPacks);
								}
							}
						} else {
							self.model.set('data', xml);
							self.enableComplete(false);
							window.utils.stopSpinner('saveSpinner');
							if(callback){
								callback();
							}
						}
					}else {
						self.enableComplete(false);
						window.utils.stopSpinner('saveSpinner');
						if(callback){
							callback();
						}
					}	
				}catch(err){
					console.log(err);
					window.utils.stopSpinner('saveSpinner');
					if(callback){
						callback();
					}
				}
			},100);
		}else {
			callback();
		}
	};
	
	hasUnsavedData(){
		if(this.enableComplete() /*&& !self.viewMode()*/) {
			return true;	
		}else {
			return false;
		}	
	};
	
	saveDataBeforeClosing(callback) {
		var self = this;
		self.saveDiagram(null,null,callback);
	};
	
	setResizeHeight(view, event) {
        var self = this;
		if (!self.showDashboardProcessDiscovery){
			setTimeout(function () {
				var propertiesNode = 0;
				if ($('.propertiesArea').width() > 10) {
					propertiesNode = $('.propertiesArea').outerHeight();
				}
				//var baseHeight = _.max([bmNode.outerHeight(),propertiesNode.outerHeight()])+20;
				//var topAreaHeight = $('.navbar').outerHeight();
				var lhsHeight = $("#js-canvas"+self.encodeId).outerHeight()+ 100;
				if($('#mainTab' +self.encodeId).find('.active a span').text() === DataManager.getDataManager().get('localeManager').get('shapeDetails')){
					lhsHeight = $('.table-responsive').outerHeight() + $('.table-responsive').outerHeight()/3 ;
				}
				var windowHeight = window.innerHeight;
				var baseHeight = _.max([lhsHeight, propertiesNode, windowHeight]);
				if (self.spliter) {
					self.spliter.css('height', baseHeight + 'px');
					if ($('.propertiesArea').width() < 5 && Math.abs($(window).width() - $('.modeler').width()) < 30) {
						var windowWidth = $(window).width() - 15;
						$('.splitter_bar').css('left', windowWidth);
					}
					$("#splitPanels"+self.encodeId).enhsplitter('refresh');
				}
				var rightTab = $('#rightTab'+self.encodeId);
				if(rightTab.width() > 0){
					if($('#rightTab'+self.encodeId+' .active a')[0]){
						var propDivId = $('#rightTab'+self.encodeId+' .active a')[0].hash;
						if(propDivId !== null && $('#rightTab .dropdown .active a')[0]){
							propDivId = $('#rightTab .dropdown .active a')[0].hash;
						}
						var propNode = $(''+propDivId);
					}
				}
				if(rightTab.width() > 10 && rightTab.children().last()[0].classList.contains('dropdown') /*&& rightTab.height() < 50*/){
					var dropdownDiv = rightTab.children().last();
					var appendList = dropdownDiv.find('li');
					var removedList = [];
					for(var i=0;i<appendList.length;i++){
							dropdownDiv.before(appendList[i]);
							removedList.push(appendList[i]);
					}
					if(appendList.length === removedList.length){
						dropdownDiv.remove();
					}
				}
				if(rightTab.width() > 10 && rightTab.width() < 415 && rightTab.children().length > 2/* && rightTab.height() > 50*/){
					var rightChildren = rightTab.children();
					var appendList = [];
					for(var i=rightChildren.length;i>0;i--){
						if(!rightChildren[i-1].classList.contains('dropdown')){
							appendList.push(rightChildren[i-1]);
							if(rightTab.width() < 375 && rightChildren[i-2]){
								appendList.push(rightChildren[i-2]);
								break;
							}else {
								break;
							}
						}
					}
					if(!rightChildren.last()[0].classList.contains('dropdown')){
						rightTab.append('<li class="dropdown"><a class="dropdown-toggle" data-toggle="dropdown" href="#"><span class="caret"></span></a><ul style="right:0" class="dropdown-menu pull-right"></ul></li>');
					}
					for(var i=0;i<appendList.length;i++){
						rightTab.children().last().find('ul').prepend(appendList[i]);
					}
				}
				if(rightTab.children().last()[0]&& rightTab.children().last()[0].classList.contains('dropdown') && propNode && propNode.parent()[0] && propNode.parent()[0].classList.contains('tab-content')){
					var dropdownDiv = rightTab.children().last();
					var appendList = dropdownDiv.find('li');
					for(var i=0;i<appendList.length;i++){
						appendList.eq(i).removeClass('active');
					}	
				}
				if($('#mainTab' +self.encodeId).find('.active a span').text() === "showMap"){
					self.modeler.get("canvas").zoom("fit-viewport", true);
				}
			}, 300);	
		}
	};
	/*this.getNumberOfMappings = function (mid,type,event) {
		var shapes = self.modeler._definitions.rootElements[0].flowElements;
		var count =0;
		
		for (var i = 0; i < shapes.length; i++) {
			if (event && event.type === 'shape.remove' && event.element.id === shapes[i].id) {
				continue;
			}
			if (type !== 'vdml:BusinessItem') {
				if(shapes[i].get('mid') === mid){
					count++;
				}
			}else{
				var flows = shapes.get('flows');
				for(var j=0;j<flows.length;j++){
					if(flows[j].get('mid') === mid){
						count++;
					}
				}
			}
		}
		return count;
	}*/
	afterRenderView() {
		var self = this;
		var CustomContextPadProvider = function(injector, connect, translate) {
			function showSubProcess(event, element, autoActivate) {
				var processId = element.businessObject.calledElement;
				var pack = window.vdmModelView.model.getNestedParent();
				if (processId) {
					if (!pack.get('diagrams').findWhere({ 'id': processId })) {
						bootbox.alert("Sub-Process Diagram has been deleted");
					} else {
						//window.require1(['appcommon/com/vbee/data/DataManager'], function (DataManager) {
							var altId = DataManager.getDataManager().getRepositoryId(processId);
							DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + pack.get('id') + "/vdml_ValueDeliveryModel/" + pack.get('version') + "/" + processId + "/vdml_ProcessDiscoveryDiagram/" + altId + "", { trigger: true });
						//})
					}
				}
			}
			function showPropertySheet(event, element, autoActivate) {
				var id = element.businessObject.id;
				if (id) {
					var id = window.utils.htmlEscape(window.guidGenerator());
					var user;
					var options = {};
					options.businessObject = element.businessObject;
					options.element = element;
					options.width = '660px';
					function getProcessMapId(element) {
						if (element.parent.type == "bpmndi:BPMNDiagram") {
							return element.parent.id;
						} else {
							return getProcessMapId(element.parent);
						}
					}
					var parentView = window.vdmModelView.DashboardViewModel ? window.vdmModelView.mapInstance[getProcessMapId(element)] : window.vdmModelView;
					window.getAndCreateModalDialog(parentView, id, EcoMapDiagramMixin, parentView.model, "ShapeProperties", null, options);
				}
		
			}
			//var baseFunction = injector._providers.contextPadProvider[1].prototype.getContextPadEntries.bind(this);
			injector.invoke(injector._providers.contextPadProvider[1], this);
			var cached = injector._providers.contextPadProvider[1].prototype.getContextPadEntries.bind(this);
		
			this.getContextPadEntries = function (element) {
				var actions = cached(element);
				var businessObject = element.businessObject;
				if (businessObject.$type == 'bpmn:CallActivity') {
					actions['subprocess'] = {
						group: 'view',
						className: 'bpmn-icon-sub-process-marker',
						title: translate('Navigate to Sub-process'),
						action: {
							click: showSubProcess,
						}
					}
				}
				if (businessObject.$type == "bpmn:Task" || businessObject.$type == "bpmn:Participant" || businessObject.$type == "bpmn:SubProcess" || (businessObject.$type !== "bpmn:Association" && businessObject.$type !== "bpmn:TextAnnotation" && element.type === "label")) {
					actions['property'] = {
						group: 'view',
						className: 'icon-edit-property',
						title: translate('Property Sheet'),
						action: {
							click: showPropertySheet,
						}
					}
				}
				return actions;
			}
			//injector._providers.contextPadProvider[1].prototype.getContextPadEntries = this.getContextPadEntries;
		}

		/*if (window.checkContextForRevision()) {
			self.viewMode(true);
		} else {
			self.viewMode(false);
			//self.enableComplete(false);
		}*/
		var container = $("#js-canvas"+self.encodeId);
		container.empty();
		/*if(redraw != true && self.showDashboardProcessDiscovery){
			container.height(options.jsCanvasHeight);
		}*/
		$('.dashboardProcessDiscovery').hide();
		
		self.getJSONObject(function(extModel){
			if (!self.editMode) {
				self.modeler = new BpmnNavigatedViewer({
					container: container,
					moddleExtensions: {
						vmp: extModel
					}
				});
				$('#canvas-modal-footer' + self.encodeId + ' .btn-complete').hide();
			} else {
				self.modeler = new BpmnModeler({
					keyboard: { bindTo: document },
					container: container,
					moddleExtensions: {
						vmp: extModel
					},
					additionalModules: [{__init__: ['CustomContextPadProvider'],CustomContextPadProvider: ['type', CustomContextPadProvider]}]
					/*propertiesPanel: {
						parent: '#js-properties'
					}*/
				});
				$('#canvas-modal-footer' + self.encodeId + ' .btn-complete').show();
				self.modeler.get('eventBus')
					.on(['commandStack.shape.create.postExecute', 'commandStack.shape.delete.postExecute', 'connection.remove', 'connection.changed', 'shape.changed', 'shape.remove'],
						function (event, element) {
							self.loadTable(event);
							if (event.context) {
								var bo = arguments[0].context.shape.businessObject;
								var mid = bo.get('mid');
								if (mid) {
									/*var type = bo.$type;
									if(self.getNumberOfMappings(mid,type,event) === 0){
										if (type === 'vdml:MarketSegment' || type === 'vdml:Enterprise' || type === 'vdml:Role' || type === 'vdml:Individual') {
											self.model.get('participant').remove(mid);
										} else if (type === 'vdml:BusinessModel') {
											self.model.get('businessModel').remove(mid);
										} else if (type === 'vdml:ValueProposition') {
											self.model.get('valueProposition').remove(mid);
										}
		
									}*/
								}
							}
							self.enableComplete(true);
						});
			}
			var data = self.model.get('data') ? self.model.get('data') : self.data;
			self.openDiagram(data, function () {
				if (!self.showDashboardProcessDiscovery) {
					self.spliter = $("#splitPanels" + self.encodeId).enhsplitter({ minSize: 0, onDrag: window.utils.splitterBoundary, collapse: 'right', position: '64%', handle: 'block', splitterSize: '9px', height: $("#js-canvas" + self.encodeId).outerHeight() + $('.table-responsive').outerHeight(), onDragEnd: self.setResizeHeight });
					$('.dashboardProcessDiscovery').show();
					self.spliter.enhsplitter('collapse');
					$(window).resize(function (view, event) {
						this.timer = clearTimeout();
						this.timer = setTimeout(function () { self && self.setResizeHeight && self.setResizeHeight(); }, 200);
					});
					$('#mainTab' + self.encodeId + ' a').click(function (e) {
						e.preventDefault();
						$(this).tab('show');
						self.setResizeHeight();
					});
					$('#rightTab' + self.encodeId + ' a').click(function (e) {
						e.preventDefault();
						if ($(this).text() === "Collaboration") {
							return false;
						}
						$(this).tab('show');
						if ($(this).text() === "Guidance") {
							if ($('#guidanceTab' + self.encodeId).is(':empty')) {
								$.get('js/app/version1/views/help/views/properties/ProcessDiscoveryGuidanceTemplate.html',function(viewStr){
									self.countGuidanceClicks++;
									$('#guidanceTab' + self.encodeId).empty();
									$('#guidanceTab' + self.encodeId).append(viewStr);
									if (self.configObj && self.configObj.guidance) {
										$('#guidanceTab' + self.encodeId).append(self.configObj.guidance);
									}
									window.utils.applyFontSize($('#guidanceTab' + self.encodeId));
									self.setResizeHeight();
								})
							} else {
								self.setResizeHeight();
							}
						} else {
							self.setResizeHeight();
						}
					});
					container.resizable({
						stop: _.bind(function (event, ui) {
						if (ui.size.height > ui.originalSize.height) {
							var self = this;
							self.model.set('height', ui.size.height);
						}
						}, self)
					});
				} else {
					$('#splitPanels' + self.encodeId).contents().unwrap();
					$('#mainTab' + self.encodeId).hide();
					if(self.editMode){
						self.completeButton(true);
						$('#complete' + self.encodeId).css('margin-top','-20px')
					}else{
						self.completeButton(false);
					}
					// if (redraw !== true && options.parentView && options.parentView.setResizeHeight) {// when ecomap is added
					//    options.parentView.setResizeHeight();
					//}
				}
			});
		});
	}
	handlePublicMenus(){
		var self = this;
		DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-wrench","Show Object Explorer",$.proxy(this.showobjectExplorerTree,this));
	};
	showobjectExplorerTree(){
		var self = this;
		var vdm = self.model.getNestedParent();
		var addOptions= this;
		var id = window.utils.htmlEscape(window.guidGenerator());
		window.getAndCreateModalDialog(true,id,ProcessDiscoveryDiagramMixin,vdm,"explorerModelJson",closeView,addOptions);
		function closeView(){
			window.cleanDialogModel(id,ProcessDiscoveryDiagramMixin);
		}
	};
	initializePresentation(){
        var self = this;
		self.afterRenderView();
	};
	
	resizeEcoElement(shapeId,newBounds){
        var self =this;
		var shape = self.modeler.get('elementRegistry')._elements[shapeId].element;
		self.modeler.get('modeling').resizeShape(shape, newBounds);
	};
	getJSONObject(callback) {
		var self = this;
		if (self.jsonObject) {
			callback(self.jsonObject)
		} else {
			$.getJSON('js/libs/bower-bpmn-js/dist/vmpextension.json', function (extModel) {
			//$.getJSON('../../../../../../../libs/bower-bpmn-js/dist/vmpextension.json', function (extModel) {
				self.jsonObject = extModel;
				callback(extModel);
			})
		}
	}
	
	sortShapesInTableByName(left, right) {
		if (left.name && right.name) {
			return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
		}
	}
	htmlEscape(str) {
		return String(str)
			.replace(/@/g, '')
			.replace(/ /g, '');
	}
	init(model, options){
		var self = this;
		this.ProcessDiscoveryDiagramViewModel = this;
		this.afterRenderView = _.bind(this.afterRenderView,self);
		self.model = model;
		this.enableComplete = ko.observable(false);
		this.shapesList = ko.observableArray([]);
		this.unsortedShapesList = [];
		this.showDashboardProcessDiscovery = false;
		this.completeButton = ko.observable(true);
		this.encodeId = this.htmlEscape(self.model.id);
		this.editMode = DataManager.getDataManager().get('readMode')?false:true;
		if(window.utils.checkIsLinked(null,true)){
            self.editMode = false;
        }
		if (options.parentView && options.parentView == "Dashboard") {
			this.showDashboardProcessDiscovery = true;
			this.editMode = options.editMode;
		}
		if (window.checkContextForRevision() && !self.showDashboardProcessDiscovery) {
			//var pack = self.model.getNestedParent();
			//pack.checkRevision(model, function (revision) {
			//	if(revision){
			//		self.createRevisionBasedOnContext(pack, function (newPack) {
			//			var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
			//			var altId = DataManager.getDataManager().getRepositoryId(newPack.id);
			//			var map = newPack.get('diagrams').findWhere({ 'id': altId + bmId });
			//			var diagrams = newPack.get('diagrams');
			//			for (var i = 0; i < diagrams.length; i++) {
			//				newPack.fixDiagramOnRevision(diagrams.at(i), altId);
			//			}
			//			window.utils.stopSpinner('revisionSpinner');
			//			DataManager.getDataManager().get('router').navigate("views/" + newPack.get('id') + "/" + newPack.get('type') + "/" + newPack.get('version') + "/" + map.id + "/" + map.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
			//		});
			//	}
			//});
		}

		if (!self.model.get('data')) {
			self.data = window.utils.getXmlData(model.get('type').replace("_", "."), window.utils.htmlEscape(model.id));
		}
		window.utils.loadTemplate(function (name) {
		})
		if (!ko.components.isRegistered('ProcessDiscoveryDetails')) {
			ko.components.register("ProcessDiscoveryDetails", {
				viewModel: ProcessDiagramDetailsViewModel,
				template: {
					fromUrl: "js/app/version1/views/vdml/views/designer/processModel/ProcessDiagramDetailsTemplate.html"
				},
				synchronous: true
			});
		}

		if (!ko.components.isRegistered('ProcessDiscoveryGuidance')) {
			self.guidanceViewInstance = BMGuidanceViewModel.getInstance(this.model);
			ko.components.register("ProcessDiscoveryGuidance", {
				viewModel: { instance: self.guidanceViewInstance },
				template: '<div id="guidanceTab' + self.encodeId + '"></div>',
				synchronous: true
			});
		}
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
			'name'
			, 'description'
			, 'Delete'
			, 'Complete'
			, 'ProcessDiscoveryDetails'
			, 'Guidance'
			, 'Collaboration'
			, 'shapeDetails'
		]);

	}

	static getInstance(model, options) {
		var view = new ProcessDiscoveryDiagramViewModel(model, options);
	 	//view.init(model, options);
		return view;
	};
}

path.ProcessDiscoveryDiagramViewModel = ProcessDiscoveryDiagramViewModel;
