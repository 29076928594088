import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessIndicatorLibraryElement} from './BusinessIndicatorLibraryElement'
import {BusinessIndicatorLibraryElementMixin} from './BusinessIndicatorLibraryElementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { BusinessIndicatorCategory } from './BusinessIndicatorCategory'

/*define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/smm/BusinessIndicatorLibraryElement","appbo/smm/BusinessIndicatorLibraryElementMixin"],
    function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, BusinessIndicatorLibraryElement,BusinessIndicatorLibraryElementMixin
        , BusinessIndicatorCategory
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
        if (!BusinessIndicatorCategory){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
            if (importPath.BusinessIndicatorCategory){
                BusinessIndicatorCategory = importPath.BusinessIndicatorCategory;
		}
            else {
                import('./BusinessIndicatorCategory').then(({ default: BusinessIndicatorCategory }) => {
                    BusinessIndicatorCategory = BusinessIndicatorCategory;
                });

            /*require(["appbo/smm/BusinessIndicatorCategory"],function(loadedModule){
				BusinessIndicatorCategory = loadedModule;
			});*/
		}
	}
    export class BusinessIndicatorDefinitionMixin {
        /*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            path.BusinessIndicatorDefinitionMixin = BusinessIndicatorDefinitionMixin;
        }*/
	 defaults(){
		var ret = {
            type: "smm_BusinessIndicatorDefinition"
		}
        return jQuery.extend(BusinessIndicatorLibraryElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"smm_BusinessIndicatorDefinition",
			key:"category",
			relatedModel:"smm.BusinessIndicatorCategory",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
        if (!BusinessIndicatorDefinitionMixin.cummulativeRelations) {
            BusinessIndicatorDefinitionMixin.cummulativeRelations = _.union(BusinessIndicatorDefinitionMixin.getMixinRelations()
                , BusinessIndicatorLibraryElementMixin.getCumulativeMixinRelations()
            );
        }
        return BusinessIndicatorDefinitionMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_BusinessIndicatorLibraryElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" }
		]
	}
	getParent(){
		var container;
        return BusinessIndicatorLibraryElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
        return BusinessIndicatorLibraryElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
            templatePath: "views/smm/views/properties/BusinessIndicatorDefinitionPropertiesTemplate.html",
            templateName: "BusinessIndicatorDefinitionPropertiesTemplate",
            viewTypeStr: "appviews/smm/views/properties/BusinessIndicatorDefinitionViewModel",
            tabId: "BusinessIndicatorDefinitionView",
            tabName: "BusinessIndicatorDefinition"
		}
        }
    }
        path.BusinessIndicatorDefinitionMixin = BusinessIndicatorDefinitionMixin;
	
        //return BusinessIndicatorDefinitionMixin;
//});