import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {BusinessItemLibraryMixin} from './BusinessItemLibraryMixin'
import {BusinessItemLibraryElement} from './BusinessItemLibraryElement'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/BusinessItemLibraryMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,BusinessItemLibraryMixin
, BusinessItemLibraryElement){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!BusinessItemLibraryElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemLibraryElement){
			BusinessItemLibraryElement = importPath.BusinessItemLibraryElement;
		}
		else{
			import('./BusinessItemLibraryElement').then(({ default: BusinessItemLibraryElement }) => {
                BusinessItemLibraryElement = BusinessItemLibraryElement;
            });
			/*require(["appbo/vdml/BusinessItemLibraryElement"],function(loadedModule){
				BusinessItemLibraryElement = loadedModule;
			});*/
		}
	}
	var BusinessItemLibrary = VdmlElement.extend(utils.customExtends({
		relations:BusinessItemLibraryMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new BusinessItemLibraryMixin()
	));
	
	BusinessItemLibrary.abstract = false;
	BusinessItemLibrary.supported = true;
	path.BusinessItemLibrary = BusinessItemLibrary;
	export {BusinessItemLibrary};
//});