import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {CollaborationDiagram} from './CollaborationDiagram'
import {ActivityNetworkDiagramMixin} from './ActivityNetworkDiagramMixin'
import {ProcessDiscoveryDiagram} from './ProcessDiscoveryDiagram'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/ActivityNetworkDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,ActivityNetworkDiagramMixin
, ProcessDiscoveryDiagram){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ProcessDiscoveryDiagram){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ProcessDiscoveryDiagram){
			ProcessDiscoveryDiagram = importPath.ProcessDiscoveryDiagram;
		}
		else{
			import('./ProcessDiscoveryDiagram').then(({ default: ProcessDiscoveryDiagram }) => {
                ProcessDiscoveryDiagram = ProcessDiscoveryDiagram;
            });
			/*require(["appbo/vdml/ProcessDiscoveryDiagram"],function(loadedModule){
				ProcessDiscoveryDiagram = loadedModule;
			});*/
		}
	}
	var ActivityNetworkDiagram = CollaborationDiagram.extend(utils.customExtends({
		relations:ActivityNetworkDiagramMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			CollaborationDiagram.prototype.initialize.apply(this, arguments);
		}
		}
		, new ActivityNetworkDiagramMixin()
	));
	
	ActivityNetworkDiagram.abstract = false;
	ActivityNetworkDiagram.supported = false;
	path.ActivityNetworkDiagram = ActivityNetworkDiagram;
	export {ActivityNetworkDiagram};
//});