import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {ValueElement} from './ValueElement'
import {ValueElementMixin} from './ValueElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/ValueElement","appbo/vdml/ValueElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,ValueElement,ValueElementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class ValueAddMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.ValueAddMixin = ValueAddMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_ValueAdd"
		}
		return jQuery.extend(ValueElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!ValueAddMixin.cummulativeRelations) {
            ValueAddMixin.cummulativeRelations = _.union(ValueAddMixin.getMixinRelations()
                , ValueElementMixin.getCumulativeMixinRelations()
            );
        }
		return ValueAddMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_ValueElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("valueAddOwner") ? this.get("valueAddOwner") : this.previousAttributes().valueAddOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("valueAddOwner") ? this.get("valueAddOwner") : this.previousAttributes().valueAddOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ValueAddPropertiesTemplate.html",
			templateName : "ValueAddPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ValueAddViewModel",
			tabId : "ValueAddView",
			tabName: "ValueAdd"
		}
	}
//#startCustomMethods
	prime(value,valueUnit,valFormula,accumulator,baseTuples,timestamp,viewAlternative,maxChange,currentBM,expression,callback){
		var self = this;
		var currentPackage = this.getNestedParent();
		var measureLibrary = currentPackage.createDefaultMeasureLibrary();
		var valueMeasurement = self.get('valueMeasurement');
		self.addBaseTuple(baseTuples);
		var scenario = viewAlternative.getMainScenario(self.getNestedParent());
		var measurementComponent = self.primeValueMeasurement(valueMeasurement,self,value,valueUnit,valFormula,accumulator,baseTuples,timestamp,viewAlternative,maxChange,false,expression);
		self.addBaseRelationshipsToParents(measureLibrary,measurementComponent,viewAlternative,function(){
			if(baseTuples.length > 0) {
				self.calculate(scenario);
			}
			if(callback){
				callback();
			}
		});
	};
	
	addBaseTuple(baseTuples){
		for(var i=0;i<baseTuples.length;i++) {
			if(!baseTuples[i].old && baseTuples[i].new) {
				var component = baseTuples[i].new.component;
				this.get('aggregatedFrom').add(component);
				if(component && !component.get('aggregatedTo').findWhere({id:this.get('id')})) {
					component.get('aggregatedTo').add(this);
				}
			}else if(baseTuples[i].old && !baseTuples[i].new){
				var component = baseTuples[i].old.component;
				var compCount = 0;
				for(var j=0;j<baseTuples.length;j++) {
					if(baseTuples[j].old && baseTuples[j].old.component === component && baseTuples[j].new){
						compCount++;
					}
				}
				if(compCount === 0) {
					if(component && component.get('aggregatedTo').findWhere({id:this.get('id')})) {
						component.get('aggregatedTo').remove(this);
					}
					this.get('aggregatedFrom').remove(component);
				}
			}else if(baseTuples[i].old && baseTuples[i].new){
				if(baseTuples[i].old.usePreviousAlternative != baseTuples[i].new.usePreviousAlternative){
					var comp = baseTuples[i].new.component;
					if(comp){
						comp.onAggregatedFromAdded(comp);
					}
				}
			}
		}
	};
	
	update(name,value,valueUnit,valFormula, accumulator,baseTuples,timestamp,viewAlternative,maxChange,currentBM,expression,callback){
		var self = this;
		var commonScenario = DataManager.getDataManager().get('currentCommonScenario');
		var scenario = viewAlternative.getMainScenario(self.getNestedParent());
		var  useCommon = (commonScenario === scenario) ? true :false ;
		var beepPackage = self.getNestedParent();
		var valueMeasurement = self.get('valueMeasurement');
		valueMeasurement.determineEffectiveScenario(useCommon,beepPackage,viewAlternative.get('id'),function(currentScenario){
			DataManager.getDataManager().getMainScenario(beepPackage,viewAlternative.get('id'),function(mainScenario){
				self.updateValueElementChanges(currentScenario,mainScenario,name,value,valueUnit,valFormula,accumulator,baseTuples,timestamp,viewAlternative,maxChange,expression,callback);
			});		
		});
	};
	
	destroyAssosciatedMeasurement(mainScenario){
		var self = this;
		var valueMC = self.get('valueMeasurement');
		var benchmarks = self.get('benchmark');
		
		benchmarks.each(function(benchmark){
			benchmark.destroy();
		});		
		
		if(valueMC){
			valueMC.destroy();
		}
		else {
			self.get('valueAddOwner').destroy();
		}
	};
//#endCustomMethods

	
	}
	path.ValueAddMixin = ValueAddMixin;
	//return ValueAddMixin;
//});