import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AbstractMeasureElement} from './AbstractMeasureElement'
import {MeasureCategoryMixin} from './MeasureCategoryMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Measure } from './Measure'
import { CategoryRelationship } from './CategoryRelationship'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/AbstractMeasureElement","appbo/smm/MeasureCategoryMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AbstractMeasureElement,MeasureCategoryMixin
, Measure
, CategoryRelationship){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Measure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measure){
			Measure = importPath.Measure;
		}
        else {
            import('./Measure').then(({ default: Measure }) => {
                Measure = Measure;
            });
            /*require(["appbo/smm/Measure"], function (loadedModule) {
                Measure = loadedModule;
			});*/
		}
	}
	if(!CategoryRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.CategoryRelationship){
			CategoryRelationship = importPath.CategoryRelationship;
		}
        else {
            import('./CategoryRelationship').then(({ default: CategoryRelationship }) => {
                CategoryRelationship = CategoryRelationship;
            });
			/*require(["appbo/smm/CategoryRelationship"],function(loadedModule){
				CategoryRelationship = loadedModule;
			});*/
		}
	}
	var MeasureCategory = AbstractMeasureElement.extend(utils.customExtends({
		relations:MeasureCategoryMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			AbstractMeasureElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new MeasureCategoryMixin()
	));
	
	MeasureCategory.abstract = false;
	MeasureCategory.supported = false;
	path.MeasureCategory = MeasureCategory;
	export {MeasureCategory};
//});