import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {CompetenceMixin} from './CompetenceMixin'
import {VDMLCanvasElementMixin} from './VDMLCanvasElementMixin'
import {MeasurableElement} from './MeasurableElement'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/CompetenceMixin","appbo/vdml/VDMLCanvasElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,CompetenceMixin,VDMLCanvasElementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var Competence = MeasurableElement.extend(utils.customExtends({
		relations:CompetenceMixin.getMixinRelations(),
		subModelTypes: {
			vdml_BusinessItem : 'vdml.BusinessItem',
			vdml_CapabilityOffer : 'vdml.CapabilityOffer'
		},
		initialize: function(attributes, options) {
			MeasurableElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new VDMLCanvasElementMixin()
		, new CompetenceMixin()
	));
	
	Competence.abstract = false;
	path.Competence = Competence;
	export {Competence};
//});