import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmRelationship} from './SmmRelationship'
import {MeasurementRelationshipMixin} from './MeasurementRelationshipMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmRelationship","appbo/smm/MeasurementRelationshipMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmRelationship,MeasurementRelationshipMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var MeasurementRelationship = SmmRelationship.extend(utils.customExtends({
		relations:MeasurementRelationshipMixin.getMixinRelations(),
		subModelTypes: {
			smm_RefinementMeasurementRelationship : 'smm.RefinementMeasurementRelationship',
			smm_EquivalentMeasurementRelationship : 'smm.EquivalentMeasurementRelationship',
			smm_RankingMeasurementRelationship : 'smm.RankingMeasurementRelationship',
			smm_GradeMeasurementRelationship : 'smm.GradeMeasurementRelationship',
			smm_RescaledMeasurementRelationship : 'smm.RescaledMeasurementRelationship',
			smm_BaseMeasurementRelationship : 'smm.BaseMeasurementRelationship'
		},
		initialize: function(attributes, options) {
			SmmRelationship.prototype.initialize.apply(this, arguments);
		}
		}
		, new MeasurementRelationshipMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	MeasurementRelationship.abstract = sp2Enabled;
	MeasurementRelationship.supported = !sp2Enabled
	path.MeasurementRelationship = MeasurementRelationship;
	export {MeasurementRelationship};
//});