import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {SmmElement} from './SmmElement'
import {AttributeMixin} from './AttributeMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/AttributeMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,AttributeMixin
//){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!SmmElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.SmmElement){
			SmmElement = importPath.SmmElement;
		}
        else {
            import('./SmmElement').then(({ default: SmmElement }) => {
                SmmElement = SmmElement;
            });

			/*require(["appbo/smm/SmmElement"],function(loadedModule){
				SmmElement = loadedModule;
			});*/
		}
	}
	var Attribute = SmmElement.extend(utils.customExtends({
		relations:AttributeMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			SmmElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new AttributeMixin()
	));
	Attribute.abstract = false;
	Attribute.supported = false; 	//As we are not using it yet	
	path.Attribute = Attribute;
	export {Attribute};
//});