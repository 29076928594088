import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
//import {DelegationContext} from './DelegationContext'
import {Observation} from '../smm/Observation'
import {Store} from './Store'
import {ApplicationInterface} from './ApplicationInterface'
import {Assignment} from './Assignment'
import {BindingOperationReference} from './BindingOperationReference'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_,async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, DelegationContext
, Observation
, Store
, ApplicationInterface
, BindingOperationReference
, Assignment){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	//if(!DelegationContext){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.DelegationContext){
			//DelegationContext = importPath.DelegationContext;
		}
		else{
			import('./DelegationContext').then(({ default: DelegationContext }) => {
                DelegationContext = DelegationContext;
            });
			/*require(["appbo/vdml/DelegationContext"],function(loadedModule){
				DelegationContext = loadedModule;
			});*/
		}
	//}
	if(!Observation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Observation){
			Observation = importPath.Observation;
		}
		else{
			import('../smm/Observation').then(({ default: Observation }) => {
                Observation = Observation;
            });
			/*require(["appbo/smm/Observation"],function(loadedModule){
				Observation = loadedModule;
			});*/
		}
	}
	if(!Store){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Store){
			Store = importPath.Store;
		}
		else{
			import('./Store').then(({ default: Store }) => {
                Store = Store;
            });
			/*require(["appbo/vdml/Store"],function(loadedModule){
				Store = loadedModule;
			});*/
		}
	}
	if(!ApplicationInterface){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ApplicationInterface){
			ApplicationInterface = importPath.ApplicationInterface;
		}
		else{
			import('./ApplicationInterface').then(({ default: ApplicationInterface }) => {
                ApplicationInterface = ApplicationInterface;
            });
			/*require(["appbo/vdml/ApplicationInterface"],function(loadedModule){
				ApplicationInterface = loadedModule;
			});*/
		}
	}
	if(!BindingOperationReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BindingOperationReference){
			BindingOperationReference = importPath.BindingOperationReference;
		}
		else{
			import('./BindingOperationReference').then(({ default: BindingOperationReference }) => {
                BindingOperationReference = BindingOperationReference;
            });
			/*require(["appbo/vdml/BindingOperationReference"],function(loadedModule){
				BindingOperationReference = loadedModule;
			});*/
		}
	}
	if(!Assignment){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Assignment){
			Assignment = importPath.Assignment;
		}
		else{
			import('./Assignment').then(({ default: Assignment }) => {
                Assignment = Assignment;
            });
			/*require(["appbo/vdml/Assignment"],function(loadedModule){
				Assignment = loadedModule;
			});*/
		}
	}
	export class AnalysisContextMixin{
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.AnalysisContextMixin = AnalysisContextMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_AnalysisContext"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_AnalysisContext",
			key:"delegationContext",
			relatedModel:"vdml.DelegationContext",
			reverseRelation: {
				key:"parentContext",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_AnalysisContext",
			key:"contextObservation",
			relatedModel:"smm.Observation",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"observationContext",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_AnalysisContext",
			key:"contextStore",
			relatedModel:"vdml.Store",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_AnalysisContext",
			key:"applicationInterface",
			relatedModel:"vdml.ApplicationInterface",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_AnalysisContext",
			key:"bindingOperationReference",
			relatedModel:"vdml.BindingOperationReference",
			reverseRelation: {
				key:"bindingOperationReferenceOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_AnalysisContext",
			key:"contextBasedAssignment",
			relatedModel:"vdml.Assignment",
			reverseRelation: {
				key:"contextBasedAssignmentOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!AnalysisContextMixin.cummulativeRelations) {
            AnalysisContextMixin.cummulativeRelations = _.union(AnalysisContextMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return AnalysisContextMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "localChange",type : "EBoolean",defaultValue : false,containingClass : "vdml_AnalysisContext" }
		]
	}
	getParent(){
		var container;
		return VdmlElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return VdmlElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/AnalysisContextPropertiesTemplate.html",
			templateName : "AnalysisContextPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/AnalysisContextViewModel",
			tabId : "AnalysisContextView",
			tabName: "AnalysisContext"
		}
	}
//#startCustomMethods
	getTimeLessObservation(observations,timeStamp){
		for(var i=0;observations && i<observations.length;i++){
			var obs = observations.at(i);
			var whenObs = obs.get('whenObserved');
			if(whenObs == timeStamp){
				return obs;
			}
		}
	}
	getAllTimeLessObservations(observations,timeStamp){
		var ret = [];
		for(var i=0;observations && i<observations.length;i++){
			var obs = observations.at(i);
			var whenObs = obs.get('whenObserved');
			if(whenObs == timeStamp){
				ret.push(obs) ;
			}
		}
		return ret;
	}
		
	/*destroyObservation(model,coll,options){debugger
		var self = this;
		var observation = self.previousAttributes().contextObservation;
		if(observation){
			var len = observation.length;
			while(len > 0 ){
				observation.at(--len).destroy();
			}
		}
		else if(options && options.creatingRevision){
			var obsKeyContents = self._relations.contextObservation.keyContents;
			if(obsKeyContents){
				var obsLen = obsKeyContents.length;
				while(obsLen > 0){
					var obsContent = obsKeyContents[--obsLen];
					if(typeof obsContent == "string"){
						var obs = self._relations.contextObservation.relatedModel.find({id:obsContent});
						if(obs){
							obs.destroy();
						}
					}
				}
			}
		}
	};*/
	//check of observed measure can copied to current analysis context
	canCopyObservedMeasure(obsMes){
		var self = this;
		var canCopy = true;
		var measurement = obsMes.get('measurements').at(0);
		var mestRels = 	measurement.get('measurementRelationships');
/*		mestRels.each(function(rel){
			if(!canCopy){
				return;
			}
			if(rel.get('type') === 'smm_RescaledMeasurementRelationship'){
				return;
			}
			var toMeasurement = rel.get('to');
			if(toMeasurement){
				var hasToMeasurement = false;
				var allToMests = toMeasurement.get('measuredCharacteristic').get('measurement');
				allToMests.each(function(toMest){
					var mestContext = toMest.get('observedMeasure').get('observation').getContext();
					if(mestContext === self){
						hasToMeasurement = true;
					}
				});
				if(!hasToMeasurement){
					canCopy = false;
				}
			}
		});*/
		if(canCopy && measurement){
			var mc = obsMes.get('measurements').at(0).get('measuredCharacteristic');
			if(mc){
				var measurand = mc.getMeasurand();
				if(measurand && (measurand.get('type') === 'vdml_ValuePropositionComponent' || measurand.get('type') === 'vdml_ValueAdd')){
					var valueMest = measurand.get('valueMeasurement');
					if(valueMest !== obsMes.get('measurements').at(0).get('measuredCharacteristic')){
						var valueestObsMes = obsMes.get('observation').getContext().getObservedMeasureWithMeasurements(valueMest);
						if(valueestObsMes) {
							canCopy = self.canCopyObservedMeasure(valueestObsMes);
						}
					}
				}
			}
		}
		return canCopy;
	};
 	compareObservedMeasures(a,b){
 			var aMes = a.get('measure');
 			var bMes = b.get('measure');
			if(!aMes || !bMes){
				return 0;
			}
 			var aType = aMes.get('type');
 			var bType = bMes.get('type');
 			if(aType === 'smm_GradeMeasure' || aType === 'smm_RankingMeasure'){
 				return -1;
 			}
 			if(bType === 'smm_GradeMeasure' || bType === 'smm_RankingMeasure'){
 				return 1;
 			}
 			if(aType === 'smm_DirectMeasure' && bType !== 'smm_DirectMeasure'){
 				return -1;
 			}
 			if(aType !== 'smm_DirectMeasure' && bType === 'smm_DirectMeasure'){
 				return 1;
 			}
 			if(aType === 'smm_DirectMeasure' && bType === 'smm_DirectMeasure'){
 				return aMes.get('baseMeasureFrom').length - bMes.get('baseMeasureFrom').length;
 			}
 			var aBaseRels = aMes.get('baseMeasureTo');
 			var bBaseRels = bMes.get('baseMeasureTo');
 			var aBaseOnPrev = 0;
 			var bBaseOnPrev = 0;
 			for(var i=0;i<aBaseRels.length;i++){
 				var aBaseRel = aBaseRels.at(i);
 				var aBaseMes = aBaseRel.get('to');
 				if(aBaseMes === bMes){
 					return 1;
 				}
 				for(var j=0;j<bBaseRels.length;j++){
 					var bBaseRel = bBaseRels.at(j);
 					if(!bBaseRel){
 						continue;
 					} 					
 					var bBaseMes = bBaseRel.get('to');
 					if(bBaseMes === aMes){
 						return -1;
 					}
	 				if(bBaseRel.get('usePreviousAlternative')){
	 					bBaseOnPrev = 1;
	 				} 					
 				}
	 			if(aBaseRel.get('usePreviousAlternative')){
	 				aBaseOnPrev = 1;
	 			} 				
 			}
 			if(bBaseOnPrev - aBaseOnPrev !== 0){
 				return bBaseOnPrev - aBaseOnPrev;
 			} 			
 		return 0;	
 	}	
	copyData(targetAC,doImport,timestamp,importPackage,measurementsCopied,incrementalUpdate){
		var self = this;
		var obs = self.getTimeLessObservation(self.get('contextObservation'));
		var metricsModel;
		if(doImport){
			metricsModel = importPackage;
		}else{
			if(obs){
				metricsModel = obs.get('smmModel');	
			}else{
				metricsModel = self.getNestedParent().createDefaultMetricModel();
			}
			
		}
		var obsId = DataManager.getDataManager().guidGeneratorByOwner(metricsModel);
		var obsCopy;
		var possibleObs;
		if(!doImport){
			if(metricsModel){
				possibleObs = self.getAllTimeLessObservations(metricsModel.get('observations'));
				_.each(possibleObs,function(obs){
					if(obs.get('observationContext') === targetAC){
						obsCopy = obs;
					}
				});
			}
		}else{
			if(metricsModel){
				possibleObs = self.getTimeLessObservation(metricsModel.get('observations'),timestamp);
				_.each(possibleObs,function(obs){
					if(obs.get('observationContext') === targetAC){
						obsCopy = obs;
					}
				});	
			}
		}
		if(!obsCopy){/*metricsModel.getUniqueProperty('name',targetAC.get('name') + ' Scenario','observations')*/
			var name = this.get('name');
			if(!timestamp){
				name = "Base Scenario";
			}
			obsCopy = new Observation({id:obsId,name:name,'snapshotId':window.uuidGenerator(),smmModel:metricsModel});	
			if(doImport){
				obsCopy.set('whenObserved',timestamp);
			}
			targetAC.get('contextObservation').add(obsCopy);
		}
		
		if(!doImport){
			var contextAssignments = this.get('contextBasedAssignment');
			var currentAssignments = targetAC.get('contextBasedAssignment').models.concat();
			contextAssignments.each(function(assignment){
				var found = false;
				_.each(currentAssignments,function(currentAssignment){
					if(currentAssignment.get('name').indexOf(assignment.get('name')) >=0 
						&& (currentAssignment.get('role') === assignment.get('role')) 
						&& (currentAssignment.get('participant') === assignment.get('participant'))){
						found = true;
						return;
					}
				});
				if(!found){
					assignment.copy(this,targetAC,assignment.get('assignedRole'),assignment.get('assignedRole'));	
				}
				
			});
			var deleteAssignments = [];
			_.each(currentAssignments,function(currentAssignment){
				var found = false;
				contextAssignments.each(function(assignment){
					if(currentAssignment.get('name').indexOf(assignment.get('name')) >=0 
						&& (currentAssignment.get('role') === assignment.get('role')) 
						&& (currentAssignment.get('participant') === assignment.get('participant'))){
						found = true;
						return;
					}
				});
				if(!found){
					deleteAssignments.push(currentAssignment);
				}
			});
			_.each(deleteAssignments,function(delAsignment){
				delAsignment.destroy();
			});
/*			var contextStores = this.get('contextStore');
			contextStores.each(function(store){
				targetAC.get('contextStore').add(store);
			})*/
			var contextStores = this.get('contextStore');	//already doing?
			var targetContextStores = targetAC.get('contextStore');
			targetContextStores.add(contextStores.models);
			var delStores = _.difference(targetContextStores.models,contextStores.models);
			targetContextStores.remove(delStores);	
		}
		
		var partOfCommon = this.get('isCommon');
 		if(!partOfCommon){
 			var parentSc = this.get('parentContext');
 			while(parentSc){
 				parentSc = parentSc.get('parentContext');
 				if(parentSc){
	 				partOfCommon = parentSc.get('isCommon');
	 				if(partOfCommon){
	 					break;
	 				}
 				}
 			}
 		}

 		function handlePlanParents(obsMes,mest,copiedObsMes,copiedMest){
 			var parents = mest.getParents();
 			var copiedParents = copiedMest.getParents();
 			var copiedMes = copiedObsMes.get('measure');
 			for(var i=0;i<parents.length;i++){
 				var parent = parents[i];
 				if(parent.get('type') !== 'smm_CollectiveMeasurement'){
 					continue;
 				}
 				var parentRep = DataManager.getDataManager().getRepositoryId(parent.id);
 				if(parentRep !== window.plansKey){
 					continue;
 				}
 				var found = false;
 				var parentMC = parent.get('measuredCharacteristic');
 				for(var j=0;j<copiedParents.length;j++){
 					var copiedParent = copiedParents[j];
 					var copiedParentMC = copiedParent.get('measuredCharacteristic');
 					if(parentMC !== copiedParentMC){
 						continue;
 					}
 					var parentMeasure = copiedParent.get('observedMeasure').get('measure');
 					var parentMesRels = parentMeasure.get('baseMeasureTo');
 					for(var k=0;k<parentMesRels.length;k++){
 						var baseRelMes = parentMesRels.at(k).get('to');
 						if(baseRelMes === copiedMes){
 							found = true;
 							break;
 						}
 					}
 					if(found){
 						break;
 					}
 				}
 				if(!found){
 					var targetAlt = targetAC.getScenario().getAlternative();
 					var targetPhaseObjSet = targetAlt.getOrCreatePhaseObjectiveSet();
 					var targetPlanObsMeasure = targetPhaseObjSet.getObservedMeasureWithMeasurements(parentMC);
 					if(targetPlanObsMeasure && !targetPhaseObjSet.get('localChange') && targetPlanObsMeasure != parent.get('observedMeasure')){
 						var parentObsCopy = targetPlanObsMeasure.get('observation');
 						var targetMest = targetPlanObsMeasure.get('measurement');
 						measurementsCopied.splice(_.indexOf(targetMest),1);
 						targetPlanObsMeasure.destroy();
						parent.get('observedMeasure').copyObservedMeasureExclusive(parentObsCopy,measurementsCopied);
 					}
 				}
 			}
 		}
		if((!partOfCommon || doImport) && obs){
			var observedMeasures = obs.get('observedMeasures');
			var sortedObsMes = observedMeasures.models.concat();
			sortedObsMes.sort(self.compareObservedMeasures);
			var newMeasures = {};
			var copiedObsMeasures = obsCopy.get('observedMeasures');
			var currentCopiedObsMeasures = copiedObsMeasures.models.concat();
			var updatedElements = [];
			async.eachSeries(sortedObsMes,function(obsMes,obsMesHandled){
					var copiedObsMes = copiedObsMeasures.findWhere({name:obsMes.get('name')});
					if(!copiedObsMes && targetAC.canCopyObservedMeasure(obsMes) && obsMes.get('measure')){
						var newCopiedObsMes = obsMes.copyObservedMeasureExclusive(obsCopy,measurementsCopied);
						var mest = obsMes.get('measurements').at(0);
						var newCopiedMest = newCopiedObsMes.get('measurements').at(0);
						if(incrementalUpdate){
							handlePlanParents(obsMes,mest,newCopiedObsMes,newCopiedMest);	
						}
						newMeasures[obsMes.get('measure').id] = newCopiedObsMes.get('measure');
					}
					if(copiedObsMes){
						var copiedMeasure = copiedObsMes.get('measure');
						var copiedMest = copiedObsMes.get('measurements').at(0);
						var mest = obsMes.get('measurements').at(0);
						//TODO add timestamp on obs mes and compare it.
						if((!copiedObsMes.get('lastChange') && obsMes.get('lastChange')) || (obsMes.get('lastChange') > copiedObsMes.get('lastChange'))){
/*						if((copiedMeasure !== obsMes.get('measure')) 
							|| (obsMes.get('lastChange') > copiedObsMes.get('lastChange'))
							|| (copiedMest.get('baseMeasurementTo') && (copiedMest.get('baseMeasurementTo').length !== mest.get('baseMeasurementTo').length))){
*/							
							var element = obsMes.get('measurements').at(0).get('measuredCharacteristic').getMeasurand();
							if(_.indexOf(updatedElements,element)<0){
								updatedElements.push(element);
								element.incrementallyUpdate(obsMes,copiedObsMes,newMeasures,function(){
									newMeasures[obsMes.get('measure').id] = copiedObsMes.get('measure');
									obsMesHandled();
								});	
								return;
							}
							//copiedObsMes.destroy();
							//obsMes.copyObservedMeasureExclusive(obsCopy,measurementsCopied);
						}else{
							var obsMesVal = mest.get('value');
							if(copiedMeasure.get('type') === 'smm_DirectMeasure'){
								var obsMesVal = mest.get('value');
								if(copiedMest.get('value') !== obsMesVal){
									copiedMest.set('value',obsMesVal);
									copiedMest.calculateParents();
								}
							}
							if(incrementalUpdate && (!copiedObsMes.get('lastChange') && obsMes.get('lastChange')) || (obsMes.get('lastChange') > copiedObsMes.get('lastChange'))){
								handlePlanParents(obsMes,mest,copiedObsMes,copiedMest);	
							}
						}
						newMeasures[obsMes.get('measure').id] = copiedObsMes.get('measure');
					}
					obsMesHandled();
			},function(){
				var delObsMes = [];
				_.each(currentCopiedObsMeasures,function(obsMes){
					var existingObsMes = observedMeasures.findWhere({name:obsMes.get('name')});
					if(!existingObsMes){
						delObsMes.push(obsMes);
					}
				});
				observedMeasures.remove(delObsMes);		
				
				var contextColl = self.get('contextCollaboration');
				function handleContextCollaboration(collaboration){
					if(!doImport){
						if(targetAC.get('type') === 'vdml_Scenario'){
							targetAC.get('contextCollaboration').add(collaboration);
						}else{
							targetAC.set('contextCollaboration',collaboration);
						}
					}
					var activities = collaboration.get('activity');
					activities.each(function(activity){
						var activityDelegations = activity.get('delegationContext');
						activityDelegations.each(function(delegation){
							var changeActivityScenario;
							var activityContexts = activity.get('delegationContext');
							for(var i=0;i<activityContexts.length;i++){
								var scenarioContexts = targetAC.get('delegationContext');
								for(var j=0;j<scenarioContexts.length;j++){
									if(scenarioContexts.at(j) === activityContexts.at(i)){
										changeActivityScenario = activityContexts.at(i);
										break;
									}
									if(changeActivityScenario){
										break;
									}
								}
							}
							if(!changeActivityScenario || (changeActivityScenario && !changeActivityScenario.get('localChange'))){
								activity.copyDelegationContext(delegation,null,null,null,targetAC,measurementsCopied);	
							}
						});
					});
				}
				
				if(contextColl instanceof Backbone.Collection){
					var currentContextColls = targetAC.get('contextCollaboration').models.concat();
					contextColl.each(function(coll){
						handleContextCollaboration(coll);
					});
					var deletedColl = _.difference(targetAC.get('contextCollaboration').models,currentContextColls);
					targetAC.get('contextCollaboration').remove(deletedColl);
				}else{
					handleContextCollaboration(contextColl);
				}				
			});
		}
	};
	getMeasurementsReferringToMeasuredCharteristic(mchar) {
	    var observatons = this.get('contextObservation');
	    var mests = [];
	    for (var i = 0; observatons && (i < observatons.length); i++) {
	        var obs = observatons.at(i);
	        var obsMeasures = obs.get('observedMeasures');
	        for (var j = 0; j < obsMeasures.length; j++) {
	            var obsMes = obsMeasures.at(j);
	            var mest = obsMes.get('measurements').at(0);
	            if (mest && mchar && mest.get('measuredCharacteristic') === mchar) {
	                mests.push(mest);
	                break;
	            }
	        }
	    }
	    return mests;
	}
	getTSLessMeasurementReferringToMeasuredCharteristic(mchar){
		var ret;
		if(!mchar){
			return null;
		}
		var tsLessObservation = this.getTimeLessObservation(this.get('contextObservation'));
		if(tsLessObservation){
			var obsrMeasures = tsLessObservation.get('observedMeasures');
			obsrMeasures.each(function(obsMes){
				var measurements = obsMes.get('measurements');
				measurements.each(function(mest){
					if(mest.get('measuredCharacteristic') === mchar){
						ret = mest;
					}				
				})
			});
		}
		if(!ret){
			var childContexts = this.get('delegationContext');
			childContexts.each(function(context){
				if(!ret){
					ret = context.getTSLessMeasurementReferringToMeasuredCharteristic(mchar);
				}
			});
		}
		return ret;		 
	}
	hasMeasurements(measuredCharacteristic,withTimeStamp,timeStamp){
		if(this.getObservedMeasureWithMeasurements(measuredCharacteristic,withTimeStamp,timeStamp)){
			return true;
		}else{
			return false;
		}
	}
	getObservation(timestamp){
		var observations = this.get('contextObservation');
		var ret;
		observations.each(function(observation){
			if(!ret){
				var whenObserved = observation.get('whenObserved');
				if(((!timestamp || timestamp == "") && (!whenObserved || whenObserved === null || whenObserved === ""))
					|| (timestamp && (whenObserved === timestamp))){
					ret = observation;
				}
			}
		});
		return ret;
	};

	getAllObservedMeasureWithMeasurements(measuredCharacteristic) {
	    var observationToCheck;
	    var obsMeasures = [];
	    var observations = this.get('contextObservation');
	    observations.each(function (observation) {
	        var obsrMeasures = observation.get('observedMeasures');
	        for (var i = 0; i < obsrMeasures.length; i++) {
	            var obsMes = obsrMeasures.at(i);
	            var measurements = obsMes.get('measurements');
	            if (measurements.length > 0 && measurements.at(0).get('measuredCharacteristic') === measuredCharacteristic) {
	                obsMeasures.push(obsMes);
	            }
	        }
	    });

	    return obsMeasures;
	}

	getObservedMeasureWithMeasurements(measuredCharacteristic,withTimeStamp,timeStamp){
		var observationToCheck;
		if(!measuredCharacteristic){
			return null;
		}
		var observations = this.get('contextObservation');
		for(var i=0;observations && i<observations.length;i++){
			var whenObserved = observations.at(i).get('whenObserved');
			if((!withTimeStamp && (!whenObserved || whenObserved === null || whenObserved === "")) || (withTimeStamp && (whenObserved === timeStamp))){
				observationToCheck = observations.at(i);
				break;
			}
		}
		if(observationToCheck){
			var obsrMeasures = observationToCheck.get('observedMeasures');
			var obsMesWithMeasurements;
			for(var i=0;i<obsrMeasures.length;i++){
				var obsMes = obsrMeasures.at(i);
				var measurements = obsMes.get('measurements');
				if(measurements.length > 0 && measurements.at(0).get('measuredCharacteristic') === measuredCharacteristic){
					obsMesWithMeasurements = obsMes;
					break;
				}				
			}			
		    if(obsMesWithMeasurements){
		    	return obsMesWithMeasurements;
		    }
		}else{
			var childContexts = this.get('delegationContext');
			for(var i=0;i<childContexts.length;i++){
				var context = childContexts.at(i);
				obsMesWithMeasurements = context.getObservedMeasureWithMeasurements(measuredCharacteristic,withTimeStamp,timeStamp);
				if(obsMesWithMeasurements){
					return obsMesWithMeasurements;
				}
			}
		}
		return null;		
	}

	calculate(measuredCharacteristic){
		var self = this;
		var obsMeasure = self.getObservedMeasureWithMeasurements(measuredCharacteristic,false);
		if(obsMeasure){
			obsMeasure.get('measurement').at(0).calculate();
		}		
	}

	getScenario(){
		var context = this;
		while(context.get('parentContext')){
			context = context.get('parentContext');
		}	
		return context;
	};
	
//#endCustomMethods	
	
	}
	path.AnalysisContextMixin = AnalysisContextMixin;
	//return AnalysisContextMixin;
//});