import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {DataManager} from '../../../../com/vbee/data/DataManager'
//define(["require", "jquery", "underscore","async", "backbone", "Lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/transformation/NamedPlanElement", "appbo/transformation/AlternativeMixin", "appbo/ecomap/EcoMap"],
//function (require, jQuery, _,async, Backbone, Lawnchair, DataManager, global, NamedPlanElement, AlternativeMixin, EcoMap) {

    var path = DataManager.getDataManager().buildAppNsPath("ecomap.migration", global.version);
    var EcoMap1002 = function () {
    }

    EcoMap1002.migrate = function (ecoPackage,callback) {
        var self = this;
		callback(ecoPackage);

    }
    path.EcoMap1002 = EcoMap1002;

    //return EcoMap1002;
	export {EcoMap1002};
//});