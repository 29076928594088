import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { MeasurableElement } from './MeasurableElement'
import { MeasurableElementMixin } from './MeasurableElementMixin'
import { VDMLCanvasElementMixin } from './VDMLCanvasElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//import { Assignment } from './Assignment';
//import { CalendarService } from './CalendarService';

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/MeasurableElementMixin","appbo/vdml/VDMLCanvasElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,MeasurableElementMixin,VDMLCanvasElementMixin
, Assignment
, CalendarService){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	
export class ParticipantMixin {

    defaults() {
        var ret = {
            type: "vdml_Participant"
        }
        return jQuery.extend(MeasurableElement.prototype.defaults.apply(this), ret);
    }
    static getMixinRelations() {
        return _.union([
/*		{
			type :Backbone.HasMany,
			containingClass:"vdml_Participant",
			key:"assignment",
			relatedModel:"vdml.Assignment",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"participant",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
*/		/*{
                type: Backbone.HasOne,
                containingClass: "vdml_Participant",
                key: "participantCalendar",
                relatedModel: "vdml.CalendarService",
                reverseRelation: {
                    key: "participantCalendarOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            }*/
        ])
    }
    static getCumulativeMixinRelations() {
        if (!ParticipantMixin.cummulativeRelations) {
            ParticipantMixin.cummulativeRelations = _.union(ParticipantMixin.getMixinRelations()
                , MeasurableElementMixin.getCumulativeMixinRelations()
                , VDMLCanvasElementMixin.getCumulativeMixinRelations()
            );
        }
        return ParticipantMixin.cummulativeRelations.slice();
    }
    static getSuperTypes() {
        return [
            "vdml_MeasurableElement",
            "vdml_VDMLCanvasElement"
        ];
    }
    static getProperties() {
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "represents", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" }
        ]
    }
    getParent() {
        var container;
        return MeasurableElement.prototype.getParent.apply(this, arguments);
    }
    getPackagePath(path) {
        if (!path) {
            path = [];
        }
        return MeasurableElement.prototype.getPackagePath.apply(this, arguments);
    }
    getViewProperties(type) {
        return {
            templatePath: "views/vdml/views/properties/ParticipantPropertiesTemplate.html",
            templateName: "ParticipantPropertiesTemplate",
            viewTypeStr: "appviews/vdml/views/properties/ParticipantViewModel",
            tabId: "ParticipantView",
            tabName: "Participant"
        }
    }
    //#startCustomMethods	
    getBusinessModelsInvolved(callback) {
        var self = this;
        /*var ret = [];
        DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_BusinessModelCustomer-customer', 'vdml_BusinessModelCustomer', "vbc:" + "cmof_EObject-name",function(businessModelCustomers){
           businessModelCustomers.each(function(businessModelCustomer){
               ret.push(businessModelCustomer.get('bmCustomerOwner'));
           });
           DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_BusinessModelNetworkPartner-networkPartner', 'vdml_BusinessModelNetworkPartner', "vbc:" + "cmof_EObject-name",function(businessModelNetworkPartners){
               businessModelNetworkPartners.each(function(businessModelNetworkPartner){
                   ret.push(businessModelNetworkPartner.get('bmNetworkPartnerOwner'));
               });		
               DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_PartcipantNetwork-collaboration', 'vdml_PartcipantNetwork', "vbc:" + "cmof_EObject-name",function(pns){
                   pns.each(function(pn){
                       ret.push(pn.get('participantNetworkOwner'));
                   });		
                   var businessModels = self.get('businessModel');
                   if(businessModels){
                       businessModels.each(function(bm){
                           ret.push(bm);
                       })
                   }
                   ret = _.uniq(ret);
                   if(callback){
                       callback(ret);
                   }
               },true);				
           },true);			
       },true);*/
        var businessModels = [];
        DataManager.getDataManager().getAllDocumentsCollectionOfType(self, 'vdml_BusinessModel', function (refs) {
            var count = refs.length;
            function getBMInstance() {
                count--;
                if (count >= 0 && refs.at(count).get('parent')) {
                    var ref = refs.at(count);
                    var bm = window.utils.getElementModel(ref.get('id'),["vdml.BusinessModel"]);
					if(bm){
						if (bm.get('id') !== self.get('id')) {
                            businessModels.push(bm);
                        }
					}
                    getBMInstance();
                   /* var parentId = ref.get('parent');
                    var repId = DataManager.getDataManager().getRepositoryId(parentId);
                    var vdmStore = DataManager.getDataManager().getVDMStore(repId);
                    DataManager.getDataManager().fetchDocumentFromPackage(parentId, "appbo/vdml/ValueDeliveryModel", ref.get('version'), ref.get('id'), "appbo/vdml/BusinessModel", vdmStore, {
                        success: function (model) {
                            //router.DataManager.getDataManager().set('currentPlan',model);
                            if (model.get('id') !== self.get('id')) {
                                businessModels.push(model);
                            }
                            getBMInstance();
                        },
                        error: function (error) {
                            if (error) {
                                console.log(error);
                            }
                            getBMInstance();
                        }
                    });*/
                } else {
                    if (count >= 0) {
                        getBMInstance();
                    } else {
                        callback(businessModels);
                    }
                }
            }
            getBMInstance();
        }, null, null, true, null, false);
    };
    getInvolvedBMCustomers(callback) {
        var self = this;
        DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_BusinessModelCustomer-customer', 'vdml_BusinessModelCustomer', "vbc:" + "cmof_EObject-name", function (businessModelCustomers) {
            if (callback) {
                callback(businessModelCustomers);
            }
        }, true);
    };
    getInvolvedBMNPs(callback) {
        var self = this;
        DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_BusinessModelNetworkPartner-networkPartner', 'vdml_BusinessModelNetwotkPartner', "vbc:" + "cmof_EObject-name", function (businessModelNetworkPartners) {
            if (callback) {
                callback(businessModelNetworkPartners);
            }
        }, true);
    };
    getInvolvedPNs(callback) {
        var self = this;
        DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_PartcipantNetwork-collaboration', 'vdml_PartcipantNetwork', "vbc:" + "cmof_EObject-name", function (pns) {
            if (callback) {
                callback(pns);
            }
        }, true);
    };
    canConnectToTargetInEcoMap(sourceElement, targetElement, connection) {
        debugger
        var self = this;
        var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: targetElement.businessObject.get('mid') });
        if (vp && targetElement.businessObject.$type === 'vdml:ValueProposition') {
            var provider = vp.get('provider');
            var sourceType = sourceElement.businessObject.$type;
            if ((sourceType === "vdml:Enterprise" || sourceType === "vdml:MarketSegment" || sourceType === "vdml:Individual") && provider.get('roleAssignment').at(0).get('participant') === self) {
                return true;
            } else if (sourceElement.businessObject.$type === "vdml:Role" && provider === self) {
                return true;
            }
        }
        return false;
    };
    //#startCustomMethods	
    
}
path.ParticipantMixin = ParticipantMixin;
	//return ParticipantMixin;
//});