import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {SolutionMixin} from './SolutionMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//
/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/tickets/SolutionMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,SolutionMixin
, User

, Vote){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
	/*if(!User){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.User){
			User = importPath.User;
		}
		else{
			import('./User').then(loadedModule => {
				  User = loadedModule;
            })
		}
	}
	
	if(!Vote){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.Vote){
			Vote = importPath.Vote;
		}
		else{
			 import('./Vote').then(loadedModule => {
				  Vote = loadedModule;
            })
		}
	}*/
	var Solution = EObject.extend(utils.customExtends({
		relations:SolutionMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			EObject.prototype.initialize.apply(this, arguments);
		}
		}
		, new SolutionMixin()
	));
	
	Solution.abstract = false;
	Solution.supported = false;
	path.Solution = Solution;
	//return Solution;
	export {Solution};
//});