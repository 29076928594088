import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import {VdmlElement} from './VdmlElement'
import {MeasuredCharacteristicMixin} from './MeasuredCharacteristicMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { Measurement } from '../smm/Measurement'
import { Characteristic } from '../smm/Characteristic'


/*define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/MeasuredCharacteristicMixin"],
function(require,jQuery,_,async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,MeasuredCharacteristicMixin
, Measurement
, Characteristic){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Measurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measurement){
			Measurement = importPath.Measurement;
		}
		else{
            import('../smm/Measurement').then(({ default: Measurement })=>{
                Measurement = Measurement;
			});
			/*require(["appbo/smm/Measurement"],function(loadedModule){
				Measurement = loadedModule;
			});*/
		}
	}
	if(!Characteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Characteristic){
			Characteristic = importPath.Characteristic;
		}
		else{
            import('../smm/Characteristic').then(({ default: Characteristic }) => {
                Characteristic = Characteristic;
			});
			/*require(["appbo/smm/Characteristic"],function(loadedModule){
				Characteristic = loadedModule;
			});*/
		}
	}
	var MeasuredCharacteristic = VdmlElement.extend(utils.customExtends({
		relations:MeasuredCharacteristicMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
			//this.on('destroy',onMeasuredCharacteristicRemoved);
			
		}
		}
		, new MeasuredCharacteristicMixin()
	));
	
	MeasuredCharacteristic['destroy'] = function onMeasuredCharacteristicRemoved(model){
		var measurements = model.previousAttributes().measurement;
		if(!measurements){
			return;
		}
		var mestModels = measurements.models.concat();
		async.eachSeries(mestModels,function(measurement,handleMeasurementCallback){
			
			measurement.getDependentMeasurements(function(parents){
				async.eachSeries(parents,function(parent,calculateCallback){
					if(!parent.get('measuredCharacteristic')){
						calculateCallback();
						return;
					}
					if(parent.get('type') === 'smm_CollectiveMeasurement'){
						var measurand = parent.get('measuredCharacteristic').getMeasurand();
						var context = parent.get('observedMeasure').get('observation').getContext();
						if(!context || !measurand){
							calculateCallback();
							return;
						}						
						while(context.get('parentContext')){
							context = context.get('parentContext');
						}
						var viewAlternative = context.getAlternative();						
						if(measurand.cacheUpdateValueMeasure){
							measurand.cacheUpdateValueMeasure(viewAlternative,calculateCallback);
						}else{
							parent.calculate();
							calculateCallback();
						}
					}else{
						parent.calculate();
						calculateCallback();
					}
				},function(err){
                    if(measurement.get('observedMeasure')){
						measurement.get('observedMeasure').destroy();	
					}
					handleMeasurementCallback();
				});
			});
		},function(err){
			
		});
	}
    MeasuredCharacteristic["change:name"] = function (mc) {
        var measurements = mc.get('measurement');
        var charDefinition = mc.get('characteristicDefinition');
        var valueName = '';
        if (mc.get('valueMeasurementOwner')) {
            valueName = mc.get('valueMeasurementOwner').get('name');
        } else if (mc.get('satisfactionLevelOwner')) {
            valueName = mc.get('satisfactionLevelOwner').get('name') + ' Satisfaction';
        } else if (mc.get('recipientOpinionOwner')) {
            valueName = mc.get('recipientOpinionOwner').get('name') + ' Recipient';
        } else if (mc.get('percentageWeightOwner')) {
            valueName = mc.get('percentageWeightOwner').get('name') + ' Weight';
        } else {
            return;
        }
        for (var i = 0; i < measurements.length; i++) {
            measurements.at(i).set('name', valueName + ' Measurement');
            measurements.at(i).set('description', valueName + ' Measurement');
            var measureRelationShips = measurements.at(i).get('measurementRelationships');
            for (var j = 0; j < measureRelationShips.length; j++) {
                var relation = measureRelationShips.at(j);
                if (relation.get('type') === 'smm_GradeMeasurementRelationship') {
                    relation.set('name', valueName + ' MeasurementGradeRel');
                    relation.set('description', valueName + ' MeasurementGradeRel');
                } else if (relation.get('type') === 'smm_RankingMeasurementRelationship') {
                    relation.set('name', valueName + ' MeasurementRankingRel');
                    relation.set('description', valueName + ' MeasurementRankingRel');
                }
            }
            var baseNmeasurementRel = measurements.at(i).get('baseNMeasurementRelationship');
            if (baseNmeasurementRel) {
                for (var k = 0; k < baseNmeasurementRel.length; k++) {
                    var count = k + 1;
                    baseNmeasurementRel.at(k).set('name', valueName + ' MeasurementBase' + count);
                    baseNmeasurementRel.at(k).set('description', valueName + ' MeasurementBase' + count);
                }
            }
            var obsMeasure = measurements.at(i).get('observedMeasure');
            if (obsMeasure) {
				var name = obsMeasure.getParent().getUniqueProperty('name',"Observed " + valueName + " Measure",'observedMeasures');
                obsMeasure.set('name', name);
            }
        }
        if (charDefinition && charDefinition.get('measuredCharacteristic').length === 1) {
            charDefinition.set('name', valueName);
            charDefinition.set('description', valueName);
            if (charDefinition.get('measure').length === 1) {
                var measure = charDefinition.get('measure').at(0);
                measure.set('name', valueName + ' Measure');
                var intervals = measure.get('interval');
                if (intervals) {
                    for (var k = 0; k < intervals.length; k++) {
                        var measureType = intervals.at(k).get('type');
                        measureType = measureType.substring(measureType.indexOf("_") + 1);
                        //intervals.at(k).set('name',valueName+ ' Measure '+measureType);
                        intervals.at(k).set('name', valueName + ' Measure Relationship');
                    }
                }
            }
        }
    };
	MeasuredCharacteristic.abstract = false;
	path.MeasuredCharacteristic = MeasuredCharacteristic;
	export {MeasuredCharacteristic};
	//}
	//return MeasuredCharacteristic;
//});