import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelDimension} from './BusinessModelDimension'
import {CustomerDimensionMixin} from './CustomerDimensionMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { Role } from './Role'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelDimension","appbo/vdml/CustomerDimensionMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelDimension,CustomerDimensionMixin
, Role){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Role){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Role){
			Role = importPath.Role;
		}
        else {
            import('./Role').then(({ default: Role }) => {
                Role = Role;
            });
			/*require(["appbo/vdml/Role"],function(loadedModule){
				Role = loadedModule;
			});*/
		}
	}
	var CustomerDimension = BusinessModelDimension.extend(utils.customExtends({
		relations:CustomerDimensionMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BusinessModelDimension.prototype.initialize.apply(this, arguments);
		}
		}
		, new CustomerDimensionMixin()
	));
	
	CustomerDimension.abstract = false;
	CustomerDimension.supported = false;
	path.CustomerDimension = CustomerDimension;
	export {CustomerDimension};
//});