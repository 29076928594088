import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Competence} from './Competence'
import {CapabilityOfferMixin} from './CapabilityOfferMixin'
import {OrgUnit} from './OrgUnit'
import {MeasuredCharacteristic} from './MeasuredCharacteristic'
/*import {Store} from './Store'
import {CapabilityMethod} from './CapabilityMethod'
import {ReleaseControl} from './ReleaseControl'
import {Activity} from './Activity'
import {Capability} from './Capability'*/
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Competence","appbo/vdml/CapabilityOfferMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Competence,CapabilityOfferMixin
, OrgUnit
, Capability
, Store
, CapabilityMethod
, ReleaseControl
, Activity
, MeasuredCharacteristic){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!OrgUnit){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OrgUnit){
			OrgUnit = importPath.OrgUnit;
		}
		else{
			import('./OrgUnit').then(loadedModule => {
		//		OrgUnit = loadedModule;
			});
			/*require(["appbo/vdml/OrgUnit"],function(loadedModule){
				OrgUnit = loadedModule;
			});*/
		}
	}
	//if(!Capability){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Capability){
			Capability = importPath.Capability;
		}
		else{
			import('./Capability').then(loadedModule => {
	//			Capability = loadedModule;
			});
			/*require(["appbo/vdml/Capability"],function(loadedModule){
				Capability = loadedModule;
			});*/
		}
	//}
	//if(!Store){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Store){
			//Store = importPath.Store;
		}
		else{
			import('./Store').then(loadedModule => {
				Store = loadedModule;
			});
			/*require(["appbo/vdml/Store"],function(loadedModule){
				Store = loadedModule;
			});*/
		}
	//}
	//if(!CapabilityMethod){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityMethod){
			//CapabilityMethod = importPath.CapabilityMethod;
		}
		else{
			import('./CapabilityMethod').then(loadedModule => {
		//		CapabilityMethod = loadedModule;
			});
			/*require(["appbo/vdml/CapabilityMethod"],function(loadedModule){
				CapabilityMethod = loadedModule;
			});*/
		}
	//}
	//if(!ReleaseControl){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ReleaseControl){
			//ReleaseControl = importPath.ReleaseControl;
		}
		else{
			import('./ReleaseControl').then(loadedModule => {
				ReleaseControl = loadedModule;
			});
			/*require(["appbo/vdml/ReleaseControl"],function(loadedModule){
				ReleaseControl = loadedModule;
			});*/
		}
	//}
//	if(!Activity){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Activity){
			//Activity = importPath.Activity;
		}
		else{
			import('./Activity').then(loadedModule => {
		//		Activity = loadedModule;
			});
			/*require(["appbo/vdml/Activity"],function(loadedModule){
				Activity = loadedModule;
			});*/
		}
//	}
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
		else{
			import('./MeasuredCharacteristic').then(loadedModule => {
				MeasuredCharacteristic = loadedModule;
			});
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	var CapabilityOffer = Competence.extend(utils.customExtends({
		relations:CapabilityOfferMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			//this.on("change:name", this.handleCapabilityOfferNameChange);
			Competence.prototype.initialize.apply(this, arguments);
		}
		}
		, new CapabilityOfferMixin()
	));
    CapabilityOffer["change:name"] = function (model, newName) {
		var previousName = model._previousAttributes ? model._previousAttributes.name:"";
		if(previousName != newName) {
			this.handleCapabilityOfferNameChange(model,newName);
		}
    }
	CapabilityOffer.abstract = false;
	path.CapabilityOffer = CapabilityOffer;
	export {CapabilityOffer};
//});