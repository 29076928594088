import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as  bootbox from '../../../../../../libs/bootbox/bootbox'
//import { moment } from '../../../../../../libs/moment/moment.min'
import { BMCanvasDiagramMixin } from '../../../../../version1/bo/canvas/BMCanvasDiagramMixin'
import {EcoMapDesignerViewModel} from '../../../ecoMap/views/designer/EcoMapDesignerViewModel'
import {SummernoteReportViewModel} from '../../../report/views/summernote/SummernoteReportViewModel'
import {DashboardValuesChart} from './DashboardValuesChart'
import {ValueStreamMapDesignerViewModel} from '../../../valueStreamMap/views/designer/ValueStreamMapDesignerViewModel'
import {StrategyMapDesignerViewModel} from '../../../strategyMap/views/designer/StrategyMapDesignerViewModel'
import {CapabilityLibraryDiagramViewModel} from '../../../capabilityLibrary/views/designer/CapabilityLibraryDiagramViewModel'
import {CanvasDesignerViewModel} from '../../../canvas/views/designer/CanvasDesignerViewModel'

//define(["require", "jquery", "enhsplitter", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "app/global", "typeahead", "appviews/dashboard/views/chart/DashboardValuesChart", "appviews/report/views/summernote/SummernoteReportViewModel", "appviews/canvas/views/designer/CanvasDesignerViewModel", "appbo/canvas/BMCanvasDiagramMixin", "appviews/ecoMap/views/designer/EcoMapDesignerViewModel", "appviews/capabilityLibrary/views/designer/CapabilityLibraryDiagramViewModel", "appviews/valueStreamMap/views/designer/ValueStreamMapDesignerViewModel", "appviews/strategyMap/views/designer/StrategyMapDesignerViewModel", "domtoimage"],
//function (require, $, enhsplitter, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, global, typeahead, DashboardValuesChart, SummernoteReportViewModel, CanvasDesignerViewModel, BMCanvasDiagramMixin, EcoMapDesignerViewModel, CapabilityLibraryDiagramViewModel, ValueStreamMapDesignerViewModel, StrategyMapDesignerViewModel) {
        var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart", global.version);
export class PresentationViewModel {
    constructor(model,options){
        this.init(model,options);
    }
    htmlEscape(str) {
        return String(str)
                .replace(/@/g, '')
                .replace(/ /g, '')
                .replace(/#/g, '');
    }
    
    handleGridResize(canvasEncodeId, canvasMinHeight, extraSpace) {
        var self = this;
        if (!extraSpace) {
            extraSpace = 0;
        }
        var totalHeight = $('#view' + self.encodeId).parent().height();
        var detailsLegendHeight = $('#details' + self.encodeId).outerHeight();
        var licenseFooter = $('#canvas_footer' + canvasEncodeId).is(':visible') ? $('#canvas_footer' + canvasEncodeId).outerHeight(true) : 0;
        var footerHeight = $('#canvas-modal-footer' + canvasEncodeId).is(':visible') ? $('#canvas-modal-footer' + canvasEncodeId).outerHeight() : 0;
        var canvasHeight = totalHeight - detailsLegendHeight - footerHeight - licenseFooter - extraSpace;
        var minHeightOfGrid = detailsLegendHeight + footerHeight + canvasMinHeight + licenseFooter + extraSpace;
        $('#view' + self.encodeId).parent().css('min-height', minHeightOfGrid);
        $('#view' + self.encodeId).css('min-height', minHeightOfGrid);
        $('#view' + self.encodeId).css('height', totalHeight);
        if (minHeightOfGrid >= totalHeight) {
            totalHeight = $('#view' + self.encodeId).parent().height();
            return canvasMinHeight;
            //canvasHeight = totalHeight - detailsLegendHeight - footerHeight;
        }
        return canvasHeight;
    };
    
    loadContext() {
        var self = this;
        var editModeEnable = "";
        var cursorStyle = "pointer";
        if (window.checkContextForRevision()) {
            editModeEnable = "disabled='disabled'";
            cursorStyle = "no-drop";
        }
        var parentView = window.vdmModelView;
        var addOptions = {};
        addOptions.parentView = "Dashboard";
        var dataManager = DataManager.getDataManager();
        if (self.model) {
            var preference = JSON.parse(self.model.get('chartConfig'));
            var presentationType = self.model.get('presentationType');
            if (presentationType === "Chart" || presentationType === "Chart/Table") {
                addOptions.selectedGroupType = preference['selectedGroupType'];
                addOptions.selectedChartType = preference['selectedChartType'];
                addOptions.showValues = preference['showValues'];
                addOptions.savedMinimum = preference['savedMinimum'];
                addOptions.savedMaximum = preference['savedMaximum'];
                addOptions.savedInterval = preference['savedInterval'];
                addOptions.showLegend = preference['showLegend'];
                // self.model.getParent().get("treeStructure")
                /*if (!ko.components.isRegistered('Chart' + self.encodeId)) {
                    ko.components.register("Chart" + self.encodeId, {
                        viewModel: { instance: self.viewInstance },
                        template: window.utils.getHtmlPage("DashboardValuesChartTemplate"),
                        synchronous: true
                    });
                }
                self.viewInstance.initializePresentation = _.bind(self.viewInstance.initializePresentation,self.viewInstance);
                window.setTimeout(self.viewInstance.initializePresentation, 500);*/
                //window.setTimeout(function () {
                    var textNode1 = $("<div class='test chart' id='bind" + self.encodeId + "'></div>")[0];
                    /*var name = $('<legend style="margin-bottom:0px"><div class="row" id="details' + self.encodeId + '" style="padding-top: 5px; margin-bottom: -10px;"><div class=\"col-xs-10\"><div class=\"lead draggable\" style=\"font-family:Roboto medium; margin-left: 10px;\"><span>' + self.model.get('name') + '</span></div></div>' +
                    '<icon id="toggleFullScreen' + self.encodeId + '" class="glyphicon glyphicon-button glyphicon-resize-full pull-right" style="margin-right: 20px;" data-toggle="tooltip" title="Resize"></icon>' +
                    '<div class="dropdown">' +
                    '<icon id="dropdown' + self.encodeId + '" class="glyphicon glyphicon-button glyphicon-cog context dropdown-toggle pull-right" style="margin-right:10px" data-toggle="tooltip" title="Settings" aria-haspopup="true" aria-expanded="false"></icon>' +
                    '<div id="dropdownMenu' + self.encodeId + '" class="dropdown-menu dropdown-menu-right" style="width:100px;height:auto;margin-right:15px;margin-top: 25px">' +
                    '<div class="row" style="padding:10px">' +
                    '<div class="col-xs-8"><label style="font-size: 14px">Edit Mode</label></div>' +
                    '<div class="col-xs-1"><input id="editMode' + self.encodeId + '" style="cursor: pointer;" type="checkbox"></div>' +
                    '</div>' +
                    '</div></div>' +
                    '</div></legend>');
                    textNode1.append(name[0]);*/
                    textNode1.append($(""+window.utils.getHtmlPage("DashboardValuesChartTemplate"))[0]);
                    $('#view' + self.encodeId).append(textNode1);
                    self.viewInstance = DashboardValuesChart.getInstance(self.model, addOptions);
                    kb.applyBindings(self.viewInstance, document.getElementById('bind' + self.encodeId));
                    self.viewInstance.initializePresentation = _.bind(self.viewInstance.initializePresentation,self.viewInstance);
                    //window.setTimeout(self.viewInstance.initializePresentation, 50);
                    self.viewInstance.initializePresentation();
                    self.viewInstance.resizeChartWithGridResize();
                //}, 1);
            } else if (presentationType === "Notes") {
                if (self.bbModel.get('newlyCreated')) {
                    addOptions.editMode = true;
                }
                self.viewInstance = SummernoteReportViewModel.getInstance(self.model, addOptions);
                $("#summernotecss").attr("href", "js/libs/summernote/dist/summernote.css");
                //window.setTimeout(function () {
                    var textNode1 = $("<div class='test chart' id='bind" + self.encodeId + "'></div>")[0];
                    var name = $('<legend style="margin-bottom:0px"><div class="row" id="details' + self.encodeId + '" style="padding-top: 5px; margin-bottom: -10px;"><div class=\"col-xs-10\"><div class=\"lead draggable\" style=\"font-family:Roboto medium; margin-left: 10px;\"><span>' + self.model.get('name') + '</span></div></div>' +
                    '<icon id="toggleFullScreen' + self.encodeId + '" class="glyphicon glyphicon-button glyphicon-fullscreen pull-right" style="margin-right: 20px;" data-toggle="tooltip" title="Full Screen"></icon>' +
                    '<div class="dropdown">' +
                    '<icon id="dropdown' + self.encodeId + '" class="glyphicon glyphicon-button glyphicon-cog context dropdown-toggle pull-right" style="margin-right:10px" data-toggle="tooltip" title="Settings" aria-haspopup="true" aria-expanded="false"></icon>' +
                    '<div id="dropdownMenu' + self.encodeId + '" class="dropdown-menu dropdown-menu-right" style="width:100px;height:auto;margin-right:15px;margin-top: 25px">' +
                    '<div class="row" style="padding:10px">' +
                    '<div class="col-xs-8"><label style="font-size: 14px">Edit Mode</label></div>' +
                    '<div class="col-xs-1"><input id="editMode' + self.encodeId + '" style="cursor: pointer;" type="checkbox"></div>' +
                    '</div>' +
                    '</div></div>' +
                    '</div></legend><div id="summernote"></div>');
                    textNode1.append(name[0]);
                    textNode1.append(name[1]);
                    $('#view' + self.encodeId).append(textNode1);
                    kb.applyBindings(self.viewInstance, document.getElementById('bind' + self.encodeId));
                    self.viewInstance.initializePresentation = _.bind(self.viewInstance.initializePresentation,self.viewInstance);
                    //window.setTimeout(self.viewInstance.initializePresentation, 50);
                    self.viewInstance.initializePresentation();
                    self.toggleFullScreen();
                //}, 1);
                /*if (!ko.components.isRegistered('Chart' + self.encodeId)) {
                    ko.components.register("Chart" + self.encodeId, {
                        viewModel: { instance: self.viewInstance },
                        template: '<div class="chart" id="chart' + self.encodeId + '"><legend style="margin-bottom:0px"><div class="row" id="details' + self.encodeId + '" style="padding-top: 5px; margin-bottom: -10px;"><div class=\"col-xs-10\"><div class=\"lead draggable\" style=\"font-family:Roboto medium; margin-left: 10px;\"><span>' + self.model.get('name') + '</span></div></div>' +
                            '<icon id="toggleFullScreen' + self.encodeId + '" class="glyphicon glyphicon-button glyphicon-resize-full pull-right" style="margin-right: 20px;" data-toggle="tooltip" title="Resize"></icon>' +
                            '<div class="dropdown">' +
                            '<icon id="dropdown' + self.encodeId + '" class="glyphicon glyphicon-button glyphicon-cog context dropdown-toggle pull-right" style="margin-right:10px" data-toggle="tooltip" title="Settings" aria-haspopup="true" aria-expanded="false"></icon>' +
                            '<div id="dropdownMenu' + self.encodeId + '" class="dropdown-menu dropdown-menu-right" style="width:100px;height:auto;margin-right:15px;margin-top: 25px">' +
                            '<div class="row" style="padding:10px">' +
                            '<div class="col-xs-8"><label style="font-size: 14px">Edit Mode</label></div>' +
                            '<div class="col-xs-1"><input id="editMode' + self.encodeId + '" style="cursor: pointer;" type="checkbox"></div>' +
                            '</div>' +
                            '</div></div>' +
                            '</div></legend><div id="summernote"></div></div>',
                        synchronous: true
                    });
                }*/
            } else if (presentationType === "Business Canvas") {
                var canvasId = self.model.get('presentationReference');
                var canvasParentId = self.model.get('presentationReferenceParent');
                var viewAlt = self.model.get('presentationReferenceAlt');
                var altModel = dataManager.getAlternativeSync(viewAlt);
                var phaseDesignPart = altModel ? altModel.get('phaseDesignPart') : null;
                var packageExists = phaseDesignPart ? phaseDesignPart.findWhere({ 'beepReference': canvasParentId }) : null;
                if (!packageExists && altModel) {
                    var newCanvasId = viewAlt + window.utils.getSuffix(canvasId);
                    var newCanvasParentId = viewAlt + window.utils.getSuffix(canvasParentId);
                    packageExists = phaseDesignPart.findWhere({ 'beepReference': newCanvasParentId });
                    if (packageExists) {
                        self.model.set('presentationReference', newCanvasId);
                        self.model.set('presentationReferenceParent', newCanvasParentId);
                        canvasId = newCanvasId;
                    }
                }
                if (!packageExists) {
                    self.model.destroy();
                    self.bbModel.destroy();
                    return;
                }
                addOptions.viewAlt = viewAlt;
                addOptions.presentationId = self.htmlEscape(self.model.id);
                if (dataManager.get('viewAlternative') != dataManager.getRepositoryId(canvasId)) {
                    addOptions.editMode = false;
                    editModeEnable = "disabled='disabled'";
                    cursorStyle = "no-drop";
                }
                var escapeId = self.htmlEscape(canvasId);
                var packId = self.model.get('presentationReferenceParent');
                var repId = dataManager.getRepositoryId(packId);
                var vdmStore = dataManager.getVDMStore(repId);
                var version = dataManager.get('currentVDMVersion');
                var bbModel = window.utils.getElementModel(canvasId, ["canvas.BMCanvasDiagram"]);
                if (!bbModel) {
                    self.model.destroy();
                    self.bbModel.destroy();
                    return;
                }
                //var canvasTemplate;
                /*dataManager.fetchDocumentFromPackage(packId, "appbo/vdml/ValueDeliveryModel", version, canvasId, "appbo/canvas/BMCanvasDiagram", vdmStore, {
                    success: function (bbModel) {*/
                        drawCanvas(bbModel, function () {
                            $('#viewAnnotation' + self.encodeId).click(function () {
                                self.viewAnnotation(self.model, self.encodeId);
                            });
                            /*var canvasHeight = self.handleGridResize(escapeId, 400);
                            $('#resizeCanvas' + escapeId).outerHeight(canvasHeight);
                            var areaOfCanvas = $('#resizeCanvas' + escapeId).width() * $('#resizeCanvas' + escapeId).height();
                            $('#canvas-group' + escapeId + ' .post-it-text').css('font-size', 0.015 * Math.sqrt(areaOfCanvas));
                            if (parentView && parentView.setResizeHeight) {
                                parentView.setResizeHeight();
                            }*/
                            self.postTemplateBinding(preference, parentView, $('#resizeCanvas' + escapeId), 400, escapeId);
                        });
                /*    },
                    error: function (error) {
                        self.model.destroy();
                        self.bbModel.destroy();
                        console.log(error);
                    }
                });*/
                
                function drawCanvas(canvasModel, callback) {
                    var textNode1 = $("<div class='test chart' id='bind" + self.encodeId + "'></div>")[0];
                    var name = $('<legend style="margin-bottom:0px"><div class="row" id="details' + self.encodeId + '" style="padding-top: 5px; margin-bottom: -10px;"><div class=\"col-xs-9\"><div class=\"lead draggable\" style=\"font-family:Roboto medium; margin-left: 10px;\"><span>' + self.model.get('name') + '</span></div></div>' +
                        '<icon id="toggleFullScreen' + self.encodeId + '" class="glyphicon glyphicon-button glyphicon-fullscreen pull-right" style="margin-right: 20px;" data-toggle="tooltip" title="Full Screen"></icon>' +
                        '<div class="dropdown">' +
                        // '<icon id="dropdown' + self.encodeId + '" class="glyphicon glyphicon-button glyphicon-cog context dropdown-toggle pull-right" style="margin-right:10px" data-toggle="tooltip" title="Settings" aria-haspopup="true" aria-expanded="false"></icon>' +
                        '<div id="dropdownMenu' + self.encodeId + '" class="dropdown-menu dropdown-menu-right" style="width:100px;height:auto;margin-right:15px;margin-top: 25px">' +
                        '<div class="row" style="padding:10px">' +
                        '<div class="col-xs-8"><label style="font-size: 14px">Edit Mode</label></div>' +
                        '<div class="col-xs-1"><input id="editMode' + self.encodeId + '" ' + editModeEnable + '" style="cursor: ' + cursorStyle + ';" type="checkbox"></div>' +
                        '</div>' +
                        /*'<div class="row" style="padding:10px">'+
                        '<div class="col-xs-8"><label style="font-size: 14px">Annotation</label></div>'+
                        '<div class="btn glyphicon glyphicon-pencil col-xs-3" style="font-size: 130%; margin-top: -2.5%;" aria-label="Edit" id="editAnnotation'+ self.encodeId+'"></div>'+
                        '</div>'+*/
                        '</div></div>' +
                        '<div class="glyphicon glyphicon-list-alt glyphicon-button draggable pull-right" id="viewAnnotation' + self.encodeId + '" data-toggle="tooltip" title="Annotation" style="font-size: large;margin-right:10px"></div>' +
                        '</div></legend>');
                    textNode1.append(name[0]);
                    $('#view' + self.encodeId).append(textNode1);
                    //window.setTimeout(function () {
                        var viewProperties = BMCanvasDiagramMixin.getDialogViewProperties('CanvasDesigner', canvasModel);
                        var version = canvasModel.getNestedParent() ? canvasModel.getNestedParent().getVersion() : 1;
                        //var newtemplatePath = "js/app/version" + version + "/" + viewProperties.templatePath;
                        var appContentNode = $("#bind" + self.encodeId);
                        var templates = window.utils.getHtmlPage(viewProperties.templateName);
                        //$.get(newtemplatePath, function (templates) {
                            appContentNode.append('<div style=\"display:none\">' + templates + '<\/div>');
                            var bindNode = $("<div data-bind=\"template: { name:\'" + viewProperties.templateName + "\', data: " + viewProperties.tabId + "Model , afterRender: afterRenderView}\"></div>");
                            appContentNode.append(bindNode);
                            self.viewInstance = CanvasDesignerViewModel.getInstance(canvasModel, addOptions);
                            kb.applyBindings(self.viewInstance, document.getElementById('bind' + self.encodeId));
                            // if(DataManager.getDataManager().get("dashboardType")==="old"){
                            //      kb.applyBindings(self.viewInstance, document.getElementById('bind' + self.encodeId));
                            // }else{
                            //     React.createElement(appContentNode.html(),document.getElementById('bind' + self.encodeId));
                            //     // self.model.getParent().get("treeStructure")
                            // }
                            callback && callback();
                        //});
                    //}, 1);
                }
            } else if (presentationType === "Business Ecosystem Map" || presentationType === "Strategy Map" || presentationType === "Value Stream Map" || presentationType === "Capability Map") {
                var mapId = self.model.get('presentationReference');
                var mapParentId = self.model.get('presentationReferenceParent');
                var viewAlt = self.model.get('presentationReferenceAlt');
                var altModel = dataManager.getAlternativeSync(viewAlt);
                var phaseDesignPart = altModel ? altModel.get('phaseDesignPart') : null;
                var packageExists = phaseDesignPart ? phaseDesignPart.findWhere({ 'beepReference': mapParentId }) : null;
                if (!packageExists && altModel) {
                    var newMapId = viewAlt + window.utils.getSuffix(mapId);
                    var newMapParentId = viewAlt + window.utils.getSuffix(mapParentId);
                    packageExists = phaseDesignPart.findWhere({ 'beepReference': newMapParentId });
                    if (packageExists) {
                        self.model.set('presentationReference', newMapId);
                        self.model.set('presentationReferenceParent', newMapParentId);
                        mapId = newMapId;
                    }
                }
                if (!packageExists && presentationType !== "Capability Map") {
                    self.model.destroy();
                    self.bbModel.destroy();
                    return;
                }
                if (dataManager.get('viewAlternative') != dataManager.getRepositoryId(mapId) && presentationType !== "Capability Map") {
                    addOptions.editMode = false;
                    editModeEnable = "disabled='disabled'";
                    cursorStyle = "no-drop";
                }
                var escapeId = self.htmlEscape(mapId);
                var packId = self.model.get('presentationReferenceParent');
                var repId = dataManager.getRepositoryId(packId);
                var vdmStore = dataManager.getVDMStore(repId);
                var version = dataManager.get('currentVDMVersion');
                var textNode1 = $("<div class='test chart' id='bind" + self.encodeId + "'></div>")[0];
                var name = $('<legend style="margin-bottom:0px"><div class="row" id="details' + self.encodeId + '" style=" margin-bottom: -10px;"><div class=\"col-xs-9\"><div class=\"lead draggable\" style=\"font-family:Roboto medium; margin-left: 10px;\"><span>' + self.model.get('name') + '</span></div></div>' +
                    '<icon id="toggleFullScreen' + self.encodeId + '" class="glyphicon glyphicon-button glyphicon-fullscreen pull-right" style="margin-right: 20px;" data-toggle="tooltip" title="Full Screen"></icon>' +
                    '<div class="dropdown">' +
                    // '<icon id="dropdown' + self.encodeId + '" class="glyphicon glyphicon-button glyphicon-cog context dropdown-toggle pull-right" style="margin-right:10px" data-toggle="tooltip" title="Settings" aria-haspopup="true" aria-expanded="false"></icon>' +
                    '<div id="dropdownMenu' + self.encodeId + '" class="dropdown-menu dropdown-menu-right" style="width:100px;height:auto;margin-right:15px;margin-top: 25px">' +
                    '<div class="row" style="padding:10px">' +
                    '<div class="col-xs-8"><label style="font-size: 14px">Edit Mode</label></div>' +
                    '<div class="col-xs-1"><input id="editMode' + self.encodeId + '" ' + editModeEnable + '" style="cursor: ' + cursorStyle + ';" type="checkbox"></div>' +
                    '</div>' +
                    /*'<div class="row" style="padding:10px">'+
                    '<div class="col-xs-8"><label style="font-size: 14px">Annotation</label></div>'+
                    '<div class="btn glyphicon glyphicon-pencil col-xs-3" style="font-size: 130%; margin-top: -2.5%;" aria-label="Edit" id="editAnnotation'+ self.encodeId+'"></div>'+
                    '</div>'+*/
                    '</div></div>' +
                    '<div class="glyphicon glyphicon-list-alt glyphicon-button draggable pull-right" id="viewAnnotation' + self.encodeId + '" data-toggle="tooltip" title="Annotation" style="font-size: large;margin-right:10px"></div>' +
                    '</div></legend>');
                textNode1.append(name[0]);
                if (presentationType === "Strategy Map") {
                    var bbModel = window.utils.getElementModel(mapId, ["vdml.StrategyMapDiagram"]);
                    if (!bbModel) {
                        self.model.destroy();
                        self.bbModel.destroy();
                        return;
                    }
                    /*dataManager.fetchDocumentFromPackage(packId, "appbo/ecomap/EcoMap", version, mapId, "appbo/vdml/StrategyMapDiagram", vdmStore, {
                        success: function (bbModel) {*/
                            drawStrategyMap(bbModel, function () {
                                $('#viewAnnotation' + self.encodeId).click(function () {
                                    self.viewAnnotation(self.model, self.encodeId);
                                });
                                /*var canvasHeight = self.handleGridResize(escapeId, 0);
                                $('#js-canvas' + escapeId).outerHeight(canvasHeight);*/
                                self.postTemplateBinding(preference, parentView, $('#js-canvas' + escapeId), 0, escapeId);
                            });
                 /*       },
                        error: function (error) {
                            self.model.destroy();
                            self.bbModel.destroy();
                            console.log(error);
                        }
                    });*/
                    function drawStrategyMap(strategyMapModel, callback) {
                        self.viewInstance = StrategyMapDesignerViewModel.getInstance(strategyMapModel, addOptions);
                        //window.setTimeout(function () {
                            self.parentView = parentView;
                            self.parentView.mapInstance['s' + self.htmlEscape(strategyMapModel.id)] = self.viewInstance;
                            $('#view' + self.encodeId).append(textNode1);
                            var viewProperties = strategyMapModel.getViewProperties();
                            var version = strategyMapModel.getNestedParent() ? strategyMapModel.getNestedParent().getVersion() : 1;
                            //var newtemplatePath = "js/app/version" + version + "/" + viewProperties.templatePath;
                            var appContentNode = $("#bind" + self.encodeId);
                            var templates = window.utils.getHtmlPage(viewProperties.templateName);
                            //$.get(newtemplatePath, function (templates) {
                                appContentNode.append('<div style=\"display:none\">' + templates + '<\/div>');
                                var bindNode = $("<div data-bind=\"template: { name:\'" + viewProperties.templateName + "\', data: " + viewProperties.tabId + "Model , afterRender: afterRenderView}\"></div>");
                                appContentNode.append(bindNode);
                                kb.applyBindings(self.viewInstance, document.getElementById('bind' + self.encodeId));
                                callback && callback();
                            //});
                        //}, 1);
                    }
                } else if (presentationType === "Capability Map") {
                    var bbModel = window.utils.getElementModel(mapId, ["vdml.CapabilityLibraryDiagram"]);
                    if (!bbModel) {
                        self.model.destroy();
                        self.bbModel.destroy();
                        return;
                    }
                    /*dataManager.fetchDocumentFromPackage(packId, "appbo/vdml/ValueDeliveryModel", version, mapId, "appbo/vdml/CapabilityLibraryDiagram", vdmStore, {
                        success: function (bbModel) {*/
                            drawCapMap(bbModel, function () {
                                $('#viewAnnotation' + self.encodeId).click(function () {
                                    self.viewAnnotation(self.model, self.encodeId);
                                });
                                /*var canvasHeight = self.handleGridResize(escapeId, 0);
                                $('#js-canvas' + escapeId).outerHeight(canvasHeight);*/
                                self.postTemplateBinding(preference, parentView, $('#js-canvas' + escapeId), 0, escapeId);
                            });
                    /*    },
                        error: function (error) {
                            self.model.destroy();
                            self.bbModel.destroy();
                            console.log(error);
                        }
                    });*/
                    function drawCapMap(capMapModel, callback) {
                        self.viewInstance = CapabilityLibraryDiagramViewModel.getInstance(capMapModel, addOptions);
                        //window.setTimeout(function () {
                            self.parentView = parentView;
                            self.parentView.mapInstance['c' + self.htmlEscape(capMapModel.id)] = self.viewInstance;
                            $('#view' + self.encodeId).append(textNode1);
                            var viewProperties = capMapModel.getViewProperties();
                            var version = capMapModel.getNestedParent() ? capMapModel.getNestedParent().getVersion() : 1;
                            //var newtemplatePath = "js/app/version" + version + "/" + viewProperties.templatePath;
                            var appContentNode = $("#bind" + self.encodeId);
                            var templates = window.utils.getHtmlPage(viewProperties.templateName);
                            //$.get(newtemplatePath, function (templates) {
                                appContentNode.append('<div style=\"display:none\">' + templates + '<\/div>');
                                var bindNode = $("<div data-bind=\"template: { name:\'" + viewProperties.templateName + "\', data: " + viewProperties.tabId + "Model , afterRender: afterRenderView}\"></div>");
                                appContentNode.append(bindNode);
                                kb.applyBindings(self.viewInstance, document.getElementById('bind' + self.encodeId));
                                callback && callback();
                            //});
                        //}, 1);
                    }
                } else if (presentationType === "Value Stream Map") {
                    var bbModel = window.utils.getElementModel(mapId, ["vdml.ValueStreamMapDiagram"]);
                    if (!bbModel) {
                        self.model.destroy();
                        self.bbModel.destroy();
                        return;
                    }
                    /*dataManager.fetchDocumentFromPackage(packId, "appbo/ecomap/EcoMap", version, mapId, "appbo/vdml/ValueStreamMapDiagram", vdmStore, {
                        success: function (bbModel) {*/
                            drawVsMap(bbModel, function () {
                                $('#viewAnnotation' + self.encodeId).click(function () {
                                    self.viewAnnotation(self.model, self.encodeId);
                                });
                                /*var canvasHeight = self.handleGridResize(escapeId, 0);
                                $('#js-canvas' + escapeId).outerHeight(canvasHeight);*/
                                self.postTemplateBinding(preference, parentView, $('#js-canvas' + escapeId), 0, escapeId);
                            });
                    /*    },
                        error: function (error) {
                            self.model.destroy();
                            self.bbModel.destroy();
                            console.log(error);
                        }
                    });*/
                    function drawVsMap(vsMapModel, callback) {
                        self.viewInstance = ValueStreamMapDesignerViewModel.getInstance(vsMapModel, addOptions);
                        //window.setTimeout(function () {
                            self.parentView = parentView;
                            self.parentView.mapInstance['v' + self.htmlEscape(vsMapModel.id)] = self.viewInstance;
                            $('#view' + self.encodeId).append(textNode1);
                            var viewProperties = vsMapModel.getViewProperties();
                            var version = vsMapModel.getNestedParent() ? vsMapModel.getNestedParent().getVersion() : 1;
                            //var newtemplatePath = "js/app/version" + version + "/" + viewProperties.templatePath;
                            var appContentNode = $("#bind" + self.encodeId);
                            var templates = window.utils.getHtmlPage(viewProperties.templateName);
                            //$.get(newtemplatePath, function (templates) {
                                appContentNode.append('<div style=\"display:none\">' + templates + '<\/div>');
                                var bindNode = $("<div data-bind=\"template: { name:\'" + viewProperties.templateName + "\', data: " + viewProperties.tabId + "Model , afterRender: afterRenderView}\"></div>");
                                appContentNode.append(bindNode);
                                kb.applyBindings(self.viewInstance, document.getElementById('bind' + self.encodeId));
                                callback && callback();
                            //});
                        //}, 1);
                    }
                } else if (presentationType === "Business Ecosystem Map") {
                    var bbModel = window.utils.getElementModel(mapId, ["vdml.EcoMapDiagram"]);
                    if (!bbModel) {
                        self.model.destroy();
                        self.bbModel.destroy();
                        return;
                    }
                    /*dataManager.fetchDocumentFromPackage(packId, "appbo/ecomap/EcoMap", version, mapId, "appbo/vdml/EcoMapDiagram", vdmStore, {
                        success: function (bbModel) {*/
                            drawEcoMap(bbModel, function () {
                                $('#viewAnnotation' + self.encodeId).click(function () {
                                    self.viewAnnotation(self.model, self.encodeId);
                                });
                                /*var canvasHeight = self.handleGridResize(escapeId, 0);
                                $('#js-canvas' + escapeId).outerHeight(canvasHeight);*/
                                self.postTemplateBinding(preference, parentView, $('#js-canvas' + escapeId), 0, escapeId);
                            });
                    /*    },
                        error: function (error) {
                            self.model.destroy();
                            self.bbModel.destroy();
                            console.log(error);
                        }
                    });*/
                    function drawEcoMap(ecoMapModel, callback) {
                        self.viewInstance = EcoMapDesignerViewModel.getInstance(ecoMapModel, addOptions);
                        //window.setTimeout(function () {
                            self.parentView = parentView;
                            self.parentView.mapInstance['e' + self.htmlEscape(ecoMapModel.id)] = self.viewInstance;
                            $('#view' + self.encodeId).append(textNode1);
                            var viewProperties = ecoMapModel.getViewProperties();
                            var version = ecoMapModel.getNestedParent() ? ecoMapModel.getNestedParent().getVersion() : 1;
                            //var newtemplatePath = "js/app/version" + version + "/" + viewProperties.templatePath;
                            var appContentNode = $("#bind" + self.encodeId);
                            var templates = window.utils.getHtmlPage(viewProperties.templateName);
                            //$.get(newtemplatePath, function (templates) {
                                appContentNode.append('<div style=\"display:none\">' + templates + '<\/div>');
                                var bindNode = $("<div data-bind=\"template: { name:\'" + viewProperties.templateName + "\', data: " + viewProperties.tabId + "Model , afterRender: afterRenderView}\"></div>");
                                appContentNode.append(bindNode);
                                kb.applyBindings(self.viewInstance, document.getElementById('bind' + self.encodeId));
                                callback && callback();
                            //});
                        //}, 1);
                    }
                }
            }
        }
    }
    htmlEscape(str) {
        return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
    }

    cleanUp() {
        var self = this;
        self.context = null;
        //ko.components.unregister("Chart" + self.encodeId);
    };

    createPicture() {
        var self = this;
        var node = $('.chart')[0];
        domtoimage.toPng(node, { width: $('.chart').width() + 20 })
            .then(function (dataUrl) {
                self.model.set('picture', dataUrl);
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    setResizeHeight() {
        var self = this;
        setTimeout(function () {
            self.initializeChart(true);
            var splitterNode = $('#splitPanels');
            var baseHeight = window.innerHeight;
            splitterNode.css('height', baseHeight + 'px');

        }, 150);
    }

    postTemplateBinding(json, parentView, container, canvasMinHeight, escapeId) {
        var self = this;
        var totalHeight = $('#view'+ self.encodeId).parent().height();
        var detailsLegendHeight = $('#view'+ self.encodeId + ' legend').outerHeight(true)+10;
        $('#js-canvas'+self.viewInstance.encodeId).outerHeight(totalHeight - detailsLegendHeight);
        self.viewInstance.initializePresentation();
        /*var canvasHeight = self.handleGridResize(escapeId, 400);
        $('#resizeCanvas' + escapeId).outerHeight(canvasHeight);
        var areaOfCanvas = $('#resizeCanvas' + escapeId).width() * $('#resizeCanvas' + escapeId).height();
        $('#canvas-group' + escapeId + ' .post-it-text').css('font-size', 0.015 * Math.sqrt(areaOfCanvas));
        if (parentView && parentView.setResizeHeight) {
            parentView.setResizeHeight();
        }*/
        $('#dropdown' + self.encodeId).on('click', function () {
            event.stopPropagation();
            $("#dropdownMenu" + self.encodeId).show();
        });
        $('#editMode' + self.encodeId).change(function () {
            self.viewInstance.editMode = this.checked;
            self.viewInstance.initializePresentation = _.bind(self.viewInstance.initializePresentation,self.viewInstance);
            self.viewInstance.initializePresentation();
            var canvasHeight = self.handleGridResize(escapeId, canvasMinHeight, this.checked ? 0 : 10);
            container.outerHeight(canvasHeight);
            $('#view' + self.encodeId).outerHeight($('#view' + self.encodeId).parent().height());
            var areaOfCanvas = $('#resizeCanvas' + escapeId).width() * $('#resizeCanvas' + escapeId).height();
            $('#canvas-group' + escapeId + ' .post-it-text').css('font-size', 0.015 * Math.sqrt(areaOfCanvas));
            if (parentView && parentView.setResizeHeight) {
                parentView.setResizeHeight();
            }
        });
        self.toggleFullScreen();
    }
    toggleFullScreen(){
        var self = this;
        $('#toggleFullScreen' + self.encodeId).unbind('click');
        $('#toggleFullScreen' + self.encodeId).click(function (view, event) {
            var parentGrid = $('#view' + self.encodeId).parent();
            if (!document.fullscreenElement) {
                self.normalViewHeight = parentGrid.height();
            }
            var height = self.normalViewHeight;
            // $(this).toggleClass('glyphicon glyphicon-fullscreen glyphicon glyphicon-resize-small');
            if (document.fullscreenElement) {
                document.exitFullscreen();
            }else{
                //parentGrid.resizable( "disable" );
                // parentGrid.height(window.innerHeight - 32);
                // $('#view'+data.encodeId).outerHeight(window.innerHeight - 32);
                height = window.innerHeight - 32;
                parentGrid.get(0).requestFullscreen();
            }
            if(self.viewInstance){
                $("#js-canvas"+self.viewInstance.encodeId).height(height);
                setTimeout(function () {
                    self.viewInstance.initializePresentation(true);
                }, 100);
            }
            // if (parentGrid.hasClass('viewFullScreen')) {
            //     //parentGrid.resizable("enable");
            //     parentGrid.height(self.normalViewHeight);
            //     $('#js-canvas' + self.encodeId).height(self.normalViewHeight);
            //     $('#view' + self.encodeId).outerHeight(self.normalViewHeight);
            //     parentGrid.toggleClass('viewFullScreen');
            //     var canvasMinHeight = 400;
            //     var canvasHeight = self.handleGridResize(self.encodeId, canvasMinHeight);
            //     $('#resizeCanvas' + self.encodeId).outerHeight(canvasHeight);
            //     //$('#bind' + self.encodeId).parent().height(canvasHeight);
            //     //$('#view'+self.encodeId).outerHeight($('#view'+ self.encodeId).parent().height());
            // } else {
            //     //parentGrid.resizable("disable");
            //     $('#js-canvas' + self.encodeId).height(window.innerHeight - 32);
            //     //$('#bind' + self.encodeId).parent().height(window.innerHeight - 32);
            //     parentGrid.height(window.innerHeight - 32);
            //     parentGrid.toggleClass('viewFullScreen');
            //     $('#view' + self.encodeId).outerHeight(window.innerHeight - 32);
            //     $('#resizeCanvas' + self.encodeId).outerHeight(window.innerHeight - 32);
            // }
            // if (window.vdmModelView && window.vdmModelView.setResizeHeight) { //grids gets adjusted when returned from full screen view
            //     window.vdmModelView.setResizeHeight(true);
            // }
            
        });
    }
    editAnnotation(model, encodeId) {
        var self = this;
        var config = JSON.parse(model.get('chartConfig'));
        var annotation = config['annotations'];
        var dataGuid = DataManager.getDataManager().guidGenerator();
        var htmlContent = "<div id='summernote" + encodeId + "'></div>";
        const box =  bootbox.dialog({
            title: '  &nbsp;Edit Annotation',
            message: htmlContent,
            backdrop: false,
            buttons: {
                main: {
                    label: "Close",
                    className: "btn btn-default",
                },
                success: {
                    label: '<i id="annotationCompleteBtn" class="fa fa-check"></i> Complete',
                    className: "btn btn-complete",
                    callback: function () {
                        var contentData = summernote.summernote('code');
                        var contentJQuery = $(contentData);
                        contentData = "";
                        var canvasImgs = contentJQuery.find('img').each(function () {
                            if (this.getAttribute('diagramId')) {
                                this.src = "";
                            }
                        });
                        contentJQuery.each(function () {
                            contentData = contentData + this.outerHTML;
                        });
                        if (contentJQuery.length == 0) {
                            contentData = summernote.summernote('code');
                        }
                        config['annotations'] = contentData;
                        model.set('chartConfig', JSON.stringify(config));
                    }
                }
            }
        });
        if(document.fullscreenElement){
			box.appendTo(`#${document.fullscreenElement.id}`)
		}else{
			box.show();
		} 

        var summernote = $('#summernote' + encodeId);
        summernote.summernote(
            {
                dialogsInBody: true,
                minHeight: null,             // set minimum height of editor
                maxHeight: null,             // set maximum height of editor
                focus: true,
                disableResizeEditor: true, //disable resize 
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['font', ['fontname', 'fontsize', 'color', 'strikethrough', 'superscript', 'subscript']],
                    ['para', ['style', 'ul', 'ol', 'paragraph']],
                    ['height', ['height']],
                    ['insert', ['picture', 'link', 'table', 'hr']]
                ],
                onMediaDelete: function ($target, editor, $editable) {
                    console.log($target[0].src);
                    $target.remove();
                }
            });
        summernote.find('.note-statusbar').hide();
        summernote.summernote('code', annotation);
        summernote.summernote("editor.clearHistory", 'editor');
        var modalBody = summernote.parents('.modal-body');
        modalBody.css('max-height', '400px');
        modalBody.css('overflow-y', 'auto');
        $("#summernotecss").attr("href", "js/libs/summernote/dist/summernote.css");
    }
    viewAnnotation() {
        var self = this;
        var config = JSON.parse(self.model.get('chartConfig'));
        var annotation = config['annotations'];
        var dataGuid = DataManager.getDataManager().guidGenerator();
        var htmlContent = "<div id='summernote" + self.encodeId + "'+></div>";
        const box = bootbox.dialog({
            title: '  &nbsp;Annotation',
            message: htmlContent,
            buttons: {
                main: {
                    label: "Close",
                    className: "btn btn-default pull-left",
                },
                edit: {
                    label: "Edit",
                    className: "annotationEdit btn btn-default",
                    callback: function () {
                        initializeSummernote(false);
                        $('.annotationComplete').show();
                        $('.annotationEdit').hide();
                        return false;
                    }
                },
                success: {
                    label: "Complete",
                    className: "annotationComplete btn btn-default",
                    callback: function () {
                        var summernote = $('#summernote' + self.encodeId);
                        var contentData = summernote.summernote('code');
                        var contentJQuery = $(contentData);
                        contentData = "";
                        var canvasImgs = contentJQuery.find('img').each(function () {
                            if (this.getAttribute('diagramId')) {
                                this.src = "";
                            }
                        });
                        contentJQuery.each(function () {
                            contentData = contentData + this.outerHTML;
                        });
                        if (contentJQuery.length == 0) {
                            contentData = summernote.summernote('code');
                        }
                        config['annotations'] = contentData;
                        self.model.set('chartConfig', JSON.stringify(config));
                    }
                }
            }
        });
        if(document.fullscreenElement){
			box.appendTo(`#${document.fullscreenElement.id}`)
		}else{
			box.show();
		} 
        function initializeSummernote(airMode) {
            var summernote = $('#summernote' + self.encodeId);
            summernote.summernote('destroy');
            summernote.summernote(
                {
                    airMode: airMode,
                    dialogsInBody: true,
                    minHeight: null,             // set minimum height of editor
                    maxHeight: null,             // set maximum height of editor
                    focus: true,
                    disableResizeEditor: true, //disable resize 
                    toolbar: [
                        ['style', ['bold', 'italic', 'underline', 'clear']],
                        ['font', ['fontname', 'fontsize', 'color', 'strikethrough', 'superscript', 'subscript']],
                        ['para', ['style', 'ul', 'ol', 'paragraph']],
                        ['height', ['height']],
                        ['insert', ['picture', 'link', 'table', 'hr']]
                    ]
                });
            summernote.find('.note-statusbar').hide();
            if (airMode) {
                summernote.summernote('disable');
            }
            summernote.summernote('code', annotation);
            summernote.summernote("editor.clearHistory", 'editor');
            var modalBody = summernote.parents('.modal-body');
            modalBody.css('max-height', '400px');
            modalBody.css('overflow-y', 'auto');
            $("#summernotecss").attr("href", "js/libs/summernote/dist/summernote.css");
        }
        initializeSummernote(true);
        $('.annotationComplete').hide();
        if (self.model.getNestedParent().id.startsWith(DataManager.getDataManager().get('viewAlternative'))) {
            $('.annotationEdit').prop('disabled', false);
        }
        else {
            $('.annotationEdit').prop('disabled', true);
        }
    }
    
    
    init(model,options){
        var self = this;
        this.PresentationViewModel = this;
        this.name = kb.observable(model, 'name');
        this.description = kb.observable(model, 'description');
        this.id = kb.observable(model, 'id');
        this.configChanged = true;
        //if (model.get('presentationType') === "Business Canvas" || model.get('presentationType') === "Business Ecosystem Map" || model.get('presentationType') === "Strategy Map" || model.get('presentationType') === "Value Stream Map" || model.get('presentationType') === "Capability Map"|| model.get('presentationType') === "Notes") {
            this.bindType = "template";
        //} else {
        //    this.bindType = "component";
        //}
        this.bbModel = model;
        //this.chartType = kb.observable(model, 'chartType');
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'name'
            , 'description'
            , 'Cancel'
            , 'next'
            , 'Collaboration'
            , 'Guidance'
            , 'Delete'
            , 'Details'
            , 'select'
            , 'Complete'
            , 'PresentationType'
        ]);
        this.encodeId = self.htmlEscape(model.id);
        if (model instanceof Backbone.RelationalModel) {
            this.model = model;
            self.loadContext();
        } else {
            self.model = window.utils.getElementModel(model.get('id'), ["dashboard.Presentation"]);
            self.loadContext();
            /*var repId = DataManager.getDataManager().getRepositoryId(model.id);
            var vdmStore = DataManager.getDataManager().getVDMStore(repId);
            DataManager.getDataManager().fetchDocumentFromPackage(model.get('parent'), "appbo/dashboard/Analytics", DataManager.getDataManager().get('currentVDMVersion'), model.get('id'), "appbo/dashboard/Presentation", vdmStore, {
                success: function (chart) {
                    self.model = chart;
                    self.loadContext();
                },
                error: function (error) {
                    console.log('Unable to load selected Package');
                }
            });*/
        }
    }
    static getInstance(model, options) {
        var view = new PresentationViewModel(model, options);
        //view.init(model, options);
        return view;
    };
}
path.PresentationViewModel = PresentationViewModel;