import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {ValueStreamMapDiagramMixin} from './ValueStreamMapDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/CollaborationDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,CollaborationDiagramMixin
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class ValueStreamMapDiagram2Mixin {

		getMixinClass(){
			return ValueStreamMapDiagram2Mixin;
		}
		
		getViewProperties(type){
			return {
				templatePath : "views/valueStreamMap/views/designer/ValueStreamMapDesignerTemplate.html",
				templateName : "ValueStreamMapDesignerTemplate",
				viewTypeStr : "appviews/valueStreamMap/views/designer/ValueStreamMapDesigner2ViewModel",
				tabId : "ValueStreamMapDesignerView",
				tabName: "ValueStreamMapDesigner"
			}
		}
	}
	utils.customExtendClass (ValueStreamMapDiagram2Mixin,new ValueStreamMapDiagramMixin());

	path.ValueStreamMapDiagram2Mixin = ValueStreamMapDiagram2Mixin;