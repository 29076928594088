import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as jszip from '../../../../libs/jszip/jszip.min'
import * as async from 'async'
import {BeepPackage} from '../beeppackage/BeepPackage'
import {BeepPackageMixin} from '../beeppackage/BeepPackageMixin'
import {BeepVocabularyMixin} from './BeepVocabularyMixin'
import {ConceptElement} from './ConceptElement'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { PackageReference } from '../../../com/vbee/filesystem/PackageReference'
import { VocabularyLibrary } from '../concept/VocabularyLibrary'
import { Vocabulary } from '../concept/Vocabulary'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","async","bootbox","app/global" ,"appbo/beeppackage/BeepPackage","appbo/concept/BeepVocabularyMixin","appbo/concept/ConceptElement","appbo/concept/VocabularyLibrary","appbo/concept/Vocabulary"],
/*function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,async,bootbox,global,BeepPackage,BeepVocabularyMixin,ConceptElement,VocabularyLibrary,Vocabulary
,PlanPackageReference
,PackageReference
, BeepPackageMixin
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("concept",global.version);
	/*if(!BeepPackage){
		var importPath = DataManager.getDataManager().buildAppNsPath("beeppackage",global.version);
		if(importPath.BeepPackage){
			BeepPackage = importPath.BeepPackage;
		}
		else{
			require(["appbo/beeppackage/BeepPackage"],function(loadedModule){
				BeepPackage = loadedModule;
			});
		}
	}
	if(!BeepPackageMixin){
		var importPath = DataManager.getDataManager().buildAppNsPath("beeppackage",global.version);
		if(importPath.BeepPackageMixin){
			BeepPackageMixin = importPath.BeepPackageMixin;
		}
		else{
			require(["appbo/beeppackage/BeepPackageMixin"],function(loadedModule){
				BeepPackageMixin = loadedModule;
			});
		}
	}
	if(!PackageReference){
		var importPath = DataManager.getDataManager().buildCommonNsPath("com/vbee/filesystem",global.version);
		if(importPath.PackageReference){
			PackageReference = importPath.PackageReference;
		}
		else{
			require(["appcommon/com/vbee/filesystem/PackageReference"],function(loadedModule){
				PackageReference = loadedModule;
			});
		}
	}
	if(!PlanPackageReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.PackageReference){
			PlanPackageReference = importPath.PackageReference;
		}
		else{
			require(["appbo/transformation/PackageReference"],function(loadedModule){
				PlanPackageReference = loadedModule;
			});
		}
	}*/	

	var BeepVocabulary = ConceptElement.extend(utils.customExtends({
		relations:BeepVocabularyMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			if(this.isNew()){
				var dm = DataManager.getDataManager();
				this.set('version',dm.get('currentVDMVersion'));
				var currentPlan = 	dm.get("currentPlan")
				if(currentPlan){
					this.set("planId",currentPlan.id);
				}
				if(!attributes.documentId){
					this.set("documentId",attributes.id);
					attributes.documentId = attributes.id;
				}
				if(attributes.documentVersion == null){
					if(currentPlan){
						this.set("documentVersion",currentPlan.get('documentVersion'));
						attributes.documentVersion = currentPlan.get('documentVersion');
					} else {
						this.set("documentVersion","0");
						attributes.documentVersion = "0";
					}
				}
				if(!dm.get("artifactsDocuments")[this.get("documentId")] ){
					dm.get("artifactsDocuments")[this.get("documentId")] = {"artifactId":this.get("id") ,"documentId":this.get("documentId"),"documentVersion":this.get("documentVersion"),"workspaceId":dm.get("currentWorkspaceId")};
					if(currentPlan && dm.get('planPackages')[currentPlan.id]){
						const index = dm.get('planPackages')[currentPlan.id].findIndex(item => item.documentId === this.get("id"));
						if (index == -1) {
							dm.get('planPackages')[currentPlan.id].push({"id":this.get("id"),type:this.get('type'),"licenseDocumentId":null,"documentId":this.get("id"),"documentVersion":this.get("documentVersion"),"ref":false});
						}
					}
				}
			}			
			ConceptElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new BeepPackageMixin()
		, new BeepVocabularyMixin()
	));
//#startCustomMethods			
	BeepVocabulary.prototype.getSystemTags = function(){
	    return ['Vocabulary Package','VDM','Admin'];
	};
//#endCustomMethods
	BeepVocabulary.getName = function(){
		return "BeepVocabulary";
	};
	
	BeepVocabulary.createVocabularyLibrary = function(currentPlan,name,description,vocabularyPackage,callback){
		if(name !== null) {
        	var planId = currentPlan.id.substr(currentPlan.id.lastIndexOf('@'));
        	var commonAltId = planId + "-Common@";
        	DataManager.getDataManager().getWorkspaceDataWithId(commonAltId,function(wsData){
        		var commonWorkspace = wsData.get('workspace');
        		var previousWorkspace = DataManager.getDataManager().getWorkspace();
        		DataManager.getDataManager().setWorkspace(commonWorkspace,function(){
					var packCreated = false;
        			if(!vocabularyPackage) {
	        			var docId = window.guidGenerator(); 
			            vocabularyPackage = new BeepVocabulary({
			                'name':name + ' Package',
			                'description':description + ' Package',
			                'id':docId,
			                'label':"Base"});
							packCreated = true;
	        		}
					var packRefId = DataManager.getDataManager().guidGeneratorByOwner(DataManager.getDataManager().get('currentWorkspace'));
	        		var pacRef = new PackageReference({id:packRefId,beepReference:vocabularyPackage,name:vocabularyPackage.get('name'),description:vocabularyPackage.get('description'),version:vocabularyPackage.get('version'),beepType:vocabularyPackage.get('type'),label:'Base'});
		            var docId = DataManager.getDataManager().guidGeneratorByOwner(vocabularyPackage); 
		            var vocabularyLibrary = new VocabularyLibrary({
		                'name':name,
		                'description':description,
		                'id':docId,
		                vocabularyLibraryOwner:vocabularyPackage
		            });
		            DataManager.getDataManager().get('currentWorkspace').get('packageReference').add(pacRef);
					DataManager.getDataManager().get('initializedPackages').add({'id':vocabularyLibrary.get('id'),'version':parseInt(vocabularyLibrary.get('version')),'model':vocabularyLibrary});
    				DataManager.getDataManager().setWorkspace(previousWorkspace,function(){
						if(packCreated) {
							DataManager.getDataManager().saveChangeSetToBackend(DataManager.getDataManager().get(DataManager.getDataManager().CURRENT_CHANGESET),function(response){
								DataManager.getDataManager().applyWorkspaceById(DataManager.getDataManager().get("currentWorkspaceId"),function(){
									callback(vocabularyLibrary);
								});
							}); 
						} else {
							callback(vocabularyLibrary);
						}
    					
    				});
        		});
        	});
        }else{
        	if(callback){
        		callback();
        	}
        }  
    };
    
	BeepVocabulary.createVocabulary = function(name,description,vocabularyLibrary,locale,businessContext,callback){
        if(name !== null) {
        	var docId = DataManager.getDataManager().guidGeneratorByOwner(vocabularyLibrary); 
	            var vocabulary = new Vocabulary({
	                'name':name,
	                'description':description,
	                'id':docId,
	                vocabularyOwner:vocabularyLibrary
	            }); 
	            vocabulary.set('data',JSON.stringify({}));
	            var localeAtt = locale?locale:'';
	            vocabulary.set('locale',""+localeAtt);
	            var businessContextAtt = businessContext?businessContext:'';
	            vocabulary.set('businessContext',""+businessContextAtt);
	            vocabularyLibrary.get('vocabulary').add(vocabulary);
				if(callback){
					callback(vocabulary);
				}
        }else{
        	if(callback){
        		callback();
        	}
        }  		
	}
	
	BeepVocabulary.unzipVocFiles = function(fileObject, callback){
		var originalId;
		var reader = new FileReader();
		reader.onload = function(ev) {
			//document.getElementById('contents').value = reader.result;
			var vocabularyContent = reader.result;
			var newplanZip = new jszip();
			/*planZip.readFile(planContent);*/
			newplanZip.loadAsync(vocabularyContent).then(function (vocZip) {
			var obj = vocZip.files;
			delete obj['manifest.json'];
			var files = jQuery.map(obj, function(el) { return el });//converting json to array
			var beepPackages = [];	              	
			async.each(files,function(vocFile,handledFile){
				vocFile.async("string").then(function (fileText) {
					var vocPack = JSON.parse(fileText);
					if(vocPack.type==="concept_BeepVocabulary")
					{
						beepPackages.push(vocPack);
						handledFile();
					}else{
						handledFile(DataManager.getDataManager().get('localeManager').get('ImportVocabularyCorrupt'));
					}
				});
			},function(err){
				if(!err){
					callback(beepPackages);
				}else{
					bootbox.alert(err);
					callback();
					return;	 
				}
			});          	
			
			
			});	
		};
		reader.readAsBinaryString(fileObject);
	};

	BeepVocabulary.createVocabularyFromFiles = function (beepPackages, model, finalCallback) {
			function handleFiles(){
				var newVocabularyId = model.id;
				var newVocabularySuffix = newVocabularyId.substr(newVocabularyId.lastIndexOf('@') + 1);
				var commonAlt = '@' + newVocabularySuffix + "-Common@";	    
				DataManager.getDataManager().getWorkspaceDataWithId(commonAlt,function(wsData){
					var count = 0;
					function getRDFModel() {
                    var commonRDFModel = DataManager.getDataManager().getRDFModel(wsData.get('id'));
                    if (commonRDFModel) {
		        			commonRDFModel.getAllDocumentsCollectionOfType('concept_BeepVocabulary',function(vocColl){
		        				var skipPacks = [];
		        				_.each(beepPackages,function(vocPack){
			        				var packExists = false;
			        				var vocPackSuffix = vocPack.id.substr(vocPack.id.lastIndexOf('@') + 1);
									vocColl.each(function(voc){
										var vocSuffix = voc.id.substr(voc.id.lastIndexOf('@') +1);
										if(vocSuffix === vocPackSuffix){
											packExists = true;
										}
									});
									if(packExists){
										skipPacks.push(vocPack);
									}
		        				});
		        				for(var i=0;i<skipPacks.length;i++){
		        					beepPackages.splice(beepPackages.indexOf(skipPacks[i]),1);
		        				}
								if(skipPacks.length){
									window.utils.stopSpinner('importVocabulary');
									bootbox.alert(DataManager.getDataManager().get('localeManager').get('PackageExists','VocabularyLibrary'));
									return;
								}
								copyVocabulary();
								vocColl.reset();
		        			},false,null,'vbc:cmof_EObject-name');
							} else {
							count++;
							if (count > 10) {
							   // finalCallback();
							}
							setTimeout(getRDFModel, 3);
						}
					}
					getRDFModel();
		        });				              	
	        }
			function replaceVocabularyIds(commonAlt){
				var replaceIds = {};
				function replacePackageIds(pack){
					var keys = Object.getOwnPropertyNames(replaceIds);
					var packStr = JSON.stringify(pack);
					for(var k=0;k<keys.length;k++){
						var re = new RegExp(keys[k], 'g');
						packStr = packStr.replace(re,replaceIds[keys[k]]);	
					}
					return JSON.parse(packStr);
				}
				for(var i=0;i<beepPackages.length;i++){
					var currentPackAlt = beepPackages[i].id.substr(0,beepPackages[i].id.lastIndexOf('@') + 1);
					replaceIds[currentPackAlt] = commonAlt;
					beepPackages[i] = replacePackageIds(beepPackages[i]);
				}
			}
	        function copyVocabulary(){
					var newVocabularyId = model.id;
					var newVocabularySuffix = newVocabularyId.substr(newVocabularyId.lastIndexOf('@') + 1);
					var commonAlt = '@' + newVocabularySuffix + "-Common@";
	              	replaceVocabularyIds(commonAlt);
              		var vocabularyAlts = [];
					vocabularyAlts.push(commonAlt);
					var workspaces = {};
					DataManager.getDataManager().set('suppressLogging',true);
					async.eachSeries(vocabularyAlts,function(altId,altHandleCallback){
						var workspaceStore = DataManager.getDataManager().get("workspaceStore");
						utils.invokeGetDefaultDirectory(workspaceStore,function(workspace){
							workspaces[altId] = workspace;
							workspace.save();
							Backbone.Relational.store.register(workspace);
							var initialiseWorkspaceDataCount = 0;
							function getAltWorkspaceData(altId){
								DataManager.getDataManager().getWorkspaceDataWithId(altId,function(wsData){
									function sleepAndCheckModel(){
										if(wsData.get('rdfModel')){
											altHandleCallback();
										}else{
											setTimeout(sleepAndCheckModel,100);
										}
									}
									if(!wsData) {
										initialiseWorkspaceDataCount++;
										if(initialiseWorkspaceDataCount > 10) {
											altHandleCallback('Failed to initialise Workspace. Import of Vocabulary failed');
											return;
										}else {
											setTimeout(function(){getAltWorkspaceData(altId);},300);
										}
									}else {
										sleepAndCheckModel();
									}
									//altHandleCallback();	
								});
							}
							getAltWorkspaceData(altId);
						},altId);
					},function(err){
						vocabularyAlts.length = 0;
						DataManager.getDataManager().set('suppressLogging',false);
						if(err) {
							//window.utils.stopSpinner('importVocabulary');
							bootbox.alert(''+err);
							finalCallback();
						}else {
							createPackages(DataManager.getDataManager().get('currentPlan'));
						}
					});

					function createPackages(plan){
						DataManager.getDataManager().set('suppressLogging',false);
						var packageModels = [];
						var serverChangeSet = DataManager.getDataManager().get(DataManager.getDataManager().CURRENT_CHANGESET);
						async.eachSeries(beepPackages,function(pack,fileHandleCallback){
							DataManager.getDataManager().clearSaveInterval();
							var packId = pack.id;
							var altId = packId.substr(0,packId.lastIndexOf('@')+ 1);
									
							DataManager.getDataManager().getWorkspaceData(workspaces[altId],function(wsData){
								DataManager.getDataManager().set('suppressLogging',true);
								DataManager.getDataManager().set('suppressChangeLogging',true);
								var copyPck;
								if(pack.type === 'concept_BeepVocabulary'){
									pack['planId'] = plan.id
									copyPck = new BeepVocabulary(pack,{silent:true});
								}
								packageModels.push(copyPck);
								DataManager.getDataManager().get('initializedPackages').add({'id':copyPck.get('id'),'version':parseInt(copyPck.get('version')),'model':copyPck});
								DataManager.getDataManager().set('suppressLogging',false);
								DataManager.getDataManager().set('suppressChangeLogging',false);
								serverChangeSet = DataManager.getDataManager().addChangeObjectForFile(serverChangeSet,pack,plan);
								fileHandleCallback();
							});
						},function(){
							beepPackages.length = 0;
							async.eachSeries(packageModels,function(packModel,generatedRDF){
								var packId = packModel.get('id');
								var altId = packId.substr(0,packId.lastIndexOf('@')+ 1);
								DataManager.getDataManager().getWorkspaceData(workspaces[altId],function(wsData){
									var packRDFModel = DataManager.getDataManager().getRDFModel(altId);
									function checkAndAddRDFForModel(){
										if(packRDFModel){
											addRDFFormModel();
										}else{
											setTimeout(checkAndAddRDFForModel,10);
										}
									}
									function addRDFFormModel(){
										packRDFModel.addRDFForModel(packModel,function(){
											function savePackageRDF(){
											    packRDFModel.save();
												DataManager.getDataManager().getWorkspaceDataWithId(altId,function(wsData){
												    var copyAltVDMStore  = wsData.get('vdmStore');
												    packModel.lawnchair = copyAltVDMStore;
												    DataManager.getDataManager().checkForCorrection(packModel,[], function () {
												      	packModel.save();
											  	    	generatedRDF();
											  	    });	
												});											    		
											}
											savePackageRDF();
										},true);											
									}
									checkAndAddRDFForModel();
								});
							},function(){
								packageModels.length = 0;
								if (finalCallback) {
									finalCallback();
								}
								/*DataManager.getDataManager().saveData({
									success:function(){
										if (finalCallback) {
											finalCallback();
										}
							        },
									error:function(){
										console.log("Error saving data before creating Revision");
									}
								});	*/
							});
						});												
					}							
	        }
			handleFiles();
	};
	
	function printStackSize(method){
		console.log('method:' + method)
		var i=0;
		function testStack(){
			i++
			testStack();
		}		
		try{
			testStack();
		}catch(e){
			console.log('stak size:' + i)
		}
	}
	BeepVocabulary.abstract = false;
	path.BeepVocabulary = BeepVocabulary;
	//return BeepVocabulary;
	export {BeepVocabulary};
//});