import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { Actor } from './Actor'
import { ActorMixin } from './ActorMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Actor","appbo/vdml/ActorMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Actor,ActorMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
export class PersonMixin {

    defaults() {
        var ret = {
            type: "vdml_Person"
        }
        return jQuery.extend(Actor.prototype.defaults.apply(this), ret);
    }
    static getMixinRelations() {
        return _.union([
        ])
    }
    static getCumulativeMixinRelations() {
        if (!PersonMixin.cummulativeRelations) {
            PersonMixin.cummulativeRelations = _.union(PersonMixin.getMixinRelations()
                , ActorMixin.getCumulativeMixinRelations()
            );
        }
        return PersonMixin.cummulativeRelations.slice();
    }
    static getSuperTypes() {
        return [
            "vdml_Actor"
        ];
    }
    static getProperties() {
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "represents", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" }
        ]
    }
    getParent() {
        var container;
        return Actor.prototype.getParent.apply(this, arguments);
    }
    getPackagePath(path) {
        if (!path) {
            path = [];
        }
        return Actor.prototype.getPackagePath.apply(this, arguments);
    }
    getViewProperties(type) {
        return {
            templatePath: "views/vdml/views/properties/PersonPropertiesTemplate.html",
            templateName: "PersonPropertiesTemplate",
            viewTypeStr: "appviews/vdml/views/properties/PersonViewModel",
            tabId: "PersonView",
            tabName: "Person"
        }
    }
    
}
path.PersonMixin = PersonMixin;
	//return PersonMixin;
//});