import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {PortDelegation} from './PortDelegation'
import {PortDelegationMixin} from './PortDelegationMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { OutputPort } from './OutputPort'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/PortDelegation","appbo/vdml/PortDelegationMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,PortDelegation,PortDelegationMixin
, OutputPort
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!OutputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OutputPort){
			OutputPort = importPath.OutputPort;
		}
		else{
            import('./OutputPort').then(({ default: OutputPort }) => {
                OutputPort = OutputPort;
			});
			/*require(["appbo/vdml/OutputPort"],function(loadedModule){
				OutputPort = loadedModule;
			});*/
		}
	}
	
	export class OutputDelegationMixin {

	defaults(){
		var ret = {
			type: "vdml_OutputDelegation"
		}
		return jQuery.extend(PortDelegation.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!OutputDelegationMixin.cummulativeRelations) {
            OutputDelegationMixin.cummulativeRelations = _.union(OutputDelegationMixin.getMixinRelations()
                , PortDelegationMixin.getCumulativeMixinRelations()
            );
        }
		return OutputDelegationMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_PortDelegation"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		return PortDelegation.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return PortDelegation.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/OutputDelegationPropertiesTemplate.html",
			templateName : "OutputDelegationPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/OutputDelegationViewModel",
			tabId : "OutputDelegationView",
			tabName: "OutputDelegation"
		}
	}
//#startCustomMethods
	copyPortDelegation(copyContext,sourcePort,targetPort){
		var copy;
		copyContext.get('contextBasedPortDelegation').each(function(portDel){
			if(portDel.get('source') === sourcePort && portDel.get('target') === targetPort){
				copy = portDel;
			}
		});
		if(!copy){		
			var copyId = DataManager.getDataManager().guidGeneratorByOwner(copyContext);
			copy = new this.constructor({id:copyId,contextBasedPortDelegationOwner:copyContext,source:sourcePort,target:targetPort,name:copyContext.getUniqueProperty('name',sourcePort.get('name') + "Delegation",'contextBasedPortDelegation'),description:sourcePort.get('description')});
		}
		return copy;
	};
//#endCustomMethods	
	
	}
	path.OutputDelegationMixin = OutputDelegationMixin;
	//return OutputDelegationMixin;
//});