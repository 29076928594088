import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {ValueMixin} from './ValueMixin'
import {Value2Mixin} from './Value2Mixin'
import {Characteristic} from '../smm/Characteristic'
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
if (!Characteristic) {
	var importPath = DataManager.getDataManager().buildAppNsPath("smm", global.version);
	if (importPath.Characteristic) {
		Characteristic = importPath.Characteristic;
	}
	else {
		import('../smm/Characteristic').then(loadedModule => {
		Characteristic = loadedModule;
		});
		/*require(["appbo/smm/Characteristic"], function (loadedModule) {
			Characteristic = loadedModule;
		});*/
	}
}
var getMixinClass = function (){
	var toggleRouter = DataManager.getDataManager().get("toggleRouter");
	var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
	return sp2Enabled == true ? Value2Mixin : ValueMixin;
}
var valueMixinClass = getMixinClass();

var Value = VdmlElement.extend(utils.customExtends({
		relations: valueMixinClass.getMixinRelations(),
		subModelTypes: {
			vdml_ValueCategory: 'vdml.ValueCategory',
			vdml_ValueDefinition: 'vdml.ValueDefinition'
		},
		initialize: function (attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
	}
	, new valueMixinClass()
));

Value.abstract = false;
path.Value = Value;
export {Value};
