import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("tickets", global.version);
export class AuditViewModel{
        constructor(model, options, detailedView) {
            window.utils.startSpinner('AuditSpinner', 'Loading Audit data...');
            var self = this;
            this.AuditViewModel = this;
            this.fileId = ko.observable();           
            this.fileType = ko.observable();
            this.fileTypesList = ['Partner', 'Customer', 'Contract', 'Credits', 'Product', 'Product Cluster', 'Purchase', 'Legal Entity Cluster', 'Invoice', 'Invoice Item',
            'Pricing Rule','Address','Role','User']
            this.modifiedBy = ko.observable();
            this.modifiedDate = ko.observable();
            this.action = ko.observable();    
            this.actionsList = ['UPDATED', 'DELETED','INSERTED'];                           
            this.enableAuditCancel = ko.observable(false);
            this.enableDetails = ko.observable(false);
            this.clearAuditHistory = ko.observable(true);
            this.encodeId = "" + options.modalId;
            this.userEntities = []; 
            this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
                'Close'
                , 'Complete'
                , 'Progress'
                , 'Details'
                , 'Collaboration'
                , 'Guidance'
            ]); 
            this.afterRenderView = _.bind(this.afterRenderView,this);
        }

        setAuditToDefault() {
            var self = this;
            $('#createAuditButton').html("Create");
            self.fileId('');
            self.fileType('Partner');
            self.modifiedBy('');
            self.modifiedDate('');
            self.action("INSERTED");
            self.enableAuditCancel(false);
            self.enableDetails(false);
            self.clearAuditHistory(true);
            $('#AuditTable1 tr').removeClass('selected');
            
        }

        setBreadCrumb(breadCrumbItems) {
            var self = this;
            $('#breadcrumb').addClass('breadcrumb');
            breadCrumbItems.push('<li><a style="font-size: x-large;" href="#adminviews/Audit">' + 'Audit' + '</a></li>');
        };

        convertToSimpleDate(dateFormat) {
            var self = this;
            if (dateFormat == null)
                return null;
            var newDate = new Date(dateFormat);
            var month = String(newDate.getMonth() + 1).length > 1 ? newDate.getMonth() + 1 : '0' + (newDate.getMonth() + 1);
            var date = String(newDate.getDate()).length > 1 ? newDate.getDate() : '0' + newDate.getDate();
            var hours = String(newDate.getHours()).length < 2 ? ('0' + newDate.getHours()) : newDate.getHours();
            var minutes = String(newDate.getMinutes()).length < 2 ? ('0' + newDate.getMinutes()) : newDate.getMinutes();
            var seconds = String(newDate.getSeconds()).length < 2 ? ('0' + newDate.getSeconds()) : newDate.getSeconds();
            var time = hours + ":" + minutes + ":" + seconds;
            return date + '-' + month + '-' + newDate.getFullYear() + ' ' + time;
        };

        isAdminView() {
            return true;
        };

        createDataTableViaAjax(callback) {
            var self = this;
            $('#AuditTable1').dataTable({
                "sAjaxSource": '/vdmbee/auditablehistory',
                "sAjaxDataProp": 'results',
                "bFilter": false,
                "bSort": true,
                "aoColumns": [{
                    mDataProp: 'fileType', bSortable: true, mRender: function (data, type, full) {
                        if (data == null)
                            return null;                            
                        var type = (data.substr(data.lastIndexOf('.') + 1).split(/(?=[A-Z])/).join(' '));
                        return type;
                    }
                }, {
                    mDataProp: 'modifiedBy', bSortable: true
                }, {                            
                    mDataProp: 'modifiedDate', bSortable: true, mRender: function (data, type, full) {
                        if (data == null)
                            return null;
                        var newDate = new Date(data);
                        var month = (newDate.getUTCMonth()) >= 9 ? newDate.getUTCMonth() + 1 : '0' + (newDate.getUTCMonth() + 1);
                        var date = String(newDate.getUTCDate()).length > 1 ? newDate.getUTCDate() : '0' + newDate.getUTCDate();
                        var hours = String(newDate.getUTCHours()).length < 2 ? ('0' + newDate.getUTCHours()) : newDate.getUTCHours();
                        var minutes = String(newDate.getUTCMinutes()).length < 2 ? ('0' + newDate.getUTCMinutes()) : newDate.getUTCMinutes();
                        var seconds = String(newDate.getUTCSeconds()).length < 2 ? ('0' + newDate.getUTCSeconds()) : newDate.getUTCSeconds();
                        var time = hours + ":" + minutes + ":" + seconds;
                        return date + '-' + month + '-' + newDate.getUTCFullYear() + ' ' + time;
                    }
                }, {
                    mDataProp: 'action', bSortable: true
                    }
                ],
                "bServerSide": true,
                "fnServerData": function (sSource, aoData, fnCallback) {
                    DataManager.getDataManager().get('vmpServerService').getDataTableAjaxPromise(sSource,aoData).then(function(data) {
                        var results = data._embedded ? data._embedded.auditableHistoryResources : [];
                        self.userEntities=[];
                        self.userEntities.push(results);
                        var test = { 'recordsTotal': data.page.totalElements,'recordsFiltered':data.page.totalElements,'results':results};
                        fnCallback(test);
                    });
                },
                "fnCreatedRow": function (nRow, aData, iDataIndex) {
                    var selfLink = aData._links.self;
                    $(nRow).attr('id', selfLink.href.substr(selfLink.href.lastIndexOf("/") + 1));
                    $(nRow).attr('data-fileId', aData.fileId);                         
                    $(nRow).attr('data-fileType', aData.fileType);
                    $(nRow).attr('data-modifiedBy', aData.modifiedBy);
                    $(nRow).attr('data-modifiedDate', aData.modifiedDate);
                    $(nRow).attr('data-action', aData.action);
                },
                "fnInitComplete": function (oSettings, json) {
                    if (callback) {
                        callback();
                    }
                },
            });
        }

        afterRenderView() {              
            var self = this;             
            $(window).bind("resize", function (event) {
                if (this == event.target) {
                    $('.sp').removeAttr('style');
                }
                
            });	
            $('#AuditTable1').on('click', 'tr', function () {
                if (this.parentElement.tagName == 'TBODY' && this.textContent != "No data available in table") {                       
                    $('#createAuditButton').html("Create");
                    if ($(this).hasClass('selected')) {
                        $(this).removeClass('selected');
                        self.selectedAuditId = '';
                        self.setAuditToDefault();
                    }
                    else {
                        $('tr.selected').removeClass('selected');
                        $(this).addClass('selected');
                        $('#createAuditButton').html("Update");
                        self.selectedAuditId = this.id;

                        var arr = [];
                        var i = 0;

                        $('.selected td').each(function () {
                            var param = $(this).html();                               
                            arr[i++] = param;
                        });
                        
                        self.fileId(this.dataset.fileid);
                        var file_type = this.dataset.filetype;
                        self.fileType(file_type.substr(file_type.lastIndexOf('.') + 1).split(/(?=[A-Z])/).join(' '));
                        self.modifiedBy(this.dataset.modifiedby);
                        self.modifiedDate(self.convertToSimpleDate(this.dataset.modifieddate));
                        self.action(this.dataset.action);
                        self.enableAuditCancel(true);
                        self.enableDetails(true);
                        self.clearAuditHistory(false);
                    }
                }
            });

            self.createDataTableViaAjax();
            window.utils.stopSpinner('AuditSpinner');
            var windowHeight = window.innerHeight - $('.navbar').outerHeight();
            var propertiesNode = $('.propertiesArea');
            var auditsNode = $('#maintainAudit');
            var tabNode = $('.tab-content');
            var adminAreaNode = $('.adminArea');
            var baseHeight = _.max([windowHeight, adminAreaNode.outerHeight(), tabNode.outerHeight(), propertiesNode.outerHeight(), auditsNode.outerHeight()]);
            //self.spliter = $("#splitPanels").enhsplitter({ minSize: 2, onDrag: window.utils.splitterBoundary, collapse: 'right', position: '56%', handle: 'block', splitterSize: '9px', height: baseHeight, onDragEnd: self.setResizeHeight });
        };

        createAudit(selfLink) {
            var self = this;
            var params = {};
            params.fileId = self.fileId();
            params.fileType = self.fileType();
            params.modifiedBy = self.modifiedBy();
            params.modifiedDate = self.modifiedDate();
            params.action = self.action();
            var spinnerText = "Creating Audit...";
            if (self.selectedAuditId) {
                spinnerText = "Creating Audit..."
            }
            window.utils.startSpinner('auditSpinner', spinnerText);
            window.setTimeout(function () {
                var currentLegalEntity = DataManager.getDataManager().get("currentLegalEntity");
                if ($("#createAuditButton").html() == "Create") {
                    if (currentLegalEntity && currentLegalEntity.entityId) {
                        params.currentEntityId = currentLegalEntity.entityId;
                    }
                    DataManager.getDataManager().get('vmpServerService').postAllPromise("/vdmbee/Audit", JSON.stringify(params)).then(function (data) {
                        self.setAuditToDefault();
                        $('#AuditTable1').DataTable().ajax.reload();
                        // self.createDataTableViaAjax(function () {
                        window.utils.stopSpinner('auditSpinner');
                        // }); 
                    }).catch(function (request) {
                        if (request.response.apierror) {
                            self.setAuditToDefault();
                            window.utils.stopSpinner('auditSpinner');
                            alert(request.response.apierror.message);
                        }

                    });
                }
                else {
                    params.id = self.selectedAuditId;
                    if (currentLegalEntity && currentLegalEntity.entityId) {
                        params.currentEntityId = currentLegalEntity.entityId;
                    }
                    DataManager.getDataManager().get('vmpServerService').postAllPromise("/vdmbee/Audit", JSON.stringify(params)).then(function (data) {
                        self.setAuditToDefault();
                        $('#AuditTable1').DataTable().ajax.reload();
                        //self.createDataTableViaAjax(function () {                               
                        window.utils.stopSpinner('auditSpinner');
                        // });
                    }).catch(function (request) {
                        if (request.response.apierror) {
                            self.setAuditToDefault();
                            window.utils.stopSpinner('auditSpinner');
                            alert(request.response.apierror.message);
                        }
                    });
                }
            }, 100)

        };           

        clearAuditHistoryView() {
            var self = this;
            var htmlContent = '<div class="row">\
                            <div class="col-xs-1"></div>\
                            <div class="col-xs-3"><span class="control-label"><span>Date</span></span></div>\
                            <div class="col-xs-7"><input id="uptoDate" class="form-control focus-ele" type="date"></div>\
                            <div class="col-xs-1"></div>\
                            </div>';
            bootbox.dialog({
                title: "Select the date upto where you want delete",
                message: htmlContent,
                buttons: {
                    add: {
                        label: "Ok",
                        className: "btn btn-complete",
                        callback: function () {
                            var uptoDate = document.getElementById("uptoDate").value;                               
                            DataManager.getDataManager().get('vmpServerService').postAllPromise("/vdmbee/auditablehistory/delete" + "?uptoDate="  + uptoDate, "POST").then(function (data) {                               
                                $('#AuditTable1').DataTable().ajax.reload();
                                self.setAuditToDefault();                                    
                            })
                        }
                    }
                }
            });
        };

        openDetailsView() {
            var self = this;
            var dialog = bootbox.dialog({
                title: 'Details',
                message: '<p><i class="fa fa-spin fa-spinner"></i> Loading...</p>'
            });
            dialog.init(function () {
                //setTimeout(function () {                       
                    for (var i = 0; i < self.userEntities[0].length; i++) {
                        if (self.fileId() === self.userEntities[0][i].fileId && self.action() === self.userEntities[0][i].action) {                                                              
                                dialog.find('.bootbox-body').html('<div class="row">\
                                                                <div class="col-xs-1"></div>\
                                                                <div class="col-xs-3"><span class="control-label">File Id</span></div>\
                                                                <div class="col-xs-7"><span class="control-label">' + self.userEntities[0][i].fileId + '</span></div>\
                                                                <div class="col-xs-1"></div>\
                                                                </div ><br/>\
                                                                <div class="row">\
                                                                <div class="col-xs-1"></div>\
                                                                <div class="col-xs-3"><span class="control-label">File Type</span></div>\
                                                                <div class="col-xs-7"><span class="control-label">' + self.userEntities[0][i].fileType + '</span></div>\
                                                                <div class="col-xs-1"></div>\
                                                                </div ><br />\
                                                                <div class="row">\
                                                                <div class="col-xs-1"></div>\
                                                                <div class="col-xs-3"><span class="control-label">Modified By</span></div>\
                                                                <div class="col-xs-7"><span class="control-label">' + self.userEntities[0][i].modifiedBy + '</span></div>\
                                                                <div class="col-xs-1"></div>\
                                                                </div ><br />\
                                                                <div class="row">\
                                                                <div class="col-xs-1"></div>\
                                                                <div class="col-xs-3"><span class="control-label">Modified Date</span></div>\
                                                                <div class="col-xs-7"><span class="control-label">' + self.convertToSimpleDate(self.userEntities[0][i].modifiedDate) + '</span></div>\
                                                                <div class="col-xs-1"></div>\
                                                                </div ><br />\
                                                                <div class="row">\
                                                                <div class="col-xs-1"></div>\
                                                                <div class="col-xs-3"><span class="control-label">Action</span></div>\
                                                                <div class="col-xs-7"><span class="control-label">' + self.userEntities[0][i].action + '</span></div>\
                                                                <div class="col-xs-1"></div>\
                                                                </div ><br />\
                                                                <div class="row">\
                                                                <div class="col-xs-1"></div>\
                                                                <div class="col-xs-3"><span class="control-label">File Content</span></div><br /><br />\
                                                                <div class="col-xs-1"></div>\
                                                                <div class="col-xs-7"><textarea rows="20" cols="60" value:"htmlBody" class="control-label">' + JSON.stringify(self.userEntities[0][i].fileContent, null, '\n') + '</textarea></div>\
                                                                <div class="col-xs-1"></div>\
                                                                </div ><br />\
                                                                <div class="modal-footer">\
                                                                <button type="button" data-bind="click: $root.cleanModalDialog" data-dismiss="modal" class="btn btn-default btn-editPlanClose"><span>Close</span></button>\
                                                                </div >');
                        }
                    }
                //}, 3000);
            });
            
        };
        cleanModalDialog() {
            var self = this;
            self.spliter.enhsplitter('remove');
            $(window, ".resize").unbind('resize');
            window.cleanDialogModel(options.modalId, self);
        }

        /*AuditViewModel.prototype.setResizeHeightHandler() {
            var self = this;
            setTimeout(function () {
                if (self.spliter) {
                    var windowHeight = window.innerHeight - $('.navbar').outerHeight();
                    var propertiesNode = $('.propertiesArea');
                    var auditsNode = $('#maintainAudit');

                    var tabNode = $('.tab-content');
                    var adminAreaNode = $('.adminArea');
                    var baseHeight = _.max([windowHeight, adminAreaNode.outerHeight(), tabNode.outerHeight(), auditsNode.outerHeight(), propertiesNode.outerHeight()]);
                    //self.spliter.css('height', (baseHeight) + 'px');
                    //$("#splitPanels").enhsplitter('refresh');
                }
            }, 150)
        };*/
        static getInstance(model, options, detailedView) {
            return new AuditViewModel(model, options, detailedView);
        };
    }
path.AuditViewModel = AuditViewModel;
