import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasurement} from './DimensionalMeasurement'
import {DirectMeasurementMixin} from './DirectMeasurementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasurement","appbo/smm/DirectMeasurementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasurement,DirectMeasurementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var DirectMeasurement = DimensionalMeasurement.extend(utils.customExtends({
		relations:DirectMeasurementMixin.getMixinRelations(),
		subModelTypes: {
			smm_CountingMeasurement : 'smm.CountingMeasurement'
		},
		initialize: function(attributes, options) {
			DimensionalMeasurement.prototype.initialize.apply(this, arguments);
		}
		}
		, new DirectMeasurementMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	DirectMeasurement.abstract = false;
	DirectMeasurement.supported = !sp2Enabled;
	path.DirectMeasurement = DirectMeasurement;
	export {DirectMeasurement};
//});