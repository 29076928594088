import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import {MeasurableElement} from './MeasurableElement'
import {MeasurableElementMixin} from './MeasurableElementMixin'
//import {Decision} from 'appcommon/com/vbee/utils/Decision'
import {BMElementMixin} from './BMElementMixin'
import {BMElement} from './BMElement'
import {BusinessModelState} from './BusinessModelState'
import {OrgUnit} from './OrgUnit'
import {Scenario} from './Scenario'
import {BusinessModelDiagram} from './BusinessModelDiagram'
import {ParticipantNetwork} from './ParticipantNetwork'
import {Role} from './Role'
import {BusinessModelCustomer} from './BusinessModelCustomer'
import {BusinessModelValueProposition} from './BusinessModelValueProposition'
import {BusinessModelActivity} from './BusinessModelActivity'
import {BusinessModelCompetence} from './BusinessModelCompetence'
import {BusinessModelNetworkPartner} from './BusinessModelNetworkPartner'
import {BusinessModelValueFormula} from './BusinessModelValueFormula'
import {Store} from './Store'
import {ValueStream} from './ValueStream'
import {Pool} from './Pool'
import {BusinessNetwork} from './BusinessNetwork'
import {Community} from './Community'
import {Activity} from './Activity'
import {ValueProposition} from './ValueProposition'
import {CapabilityOffer} from './CapabilityOffer'
import {Ticket} from '../tickets/Ticket'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","async","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/MeasurableElementMixin","appcommon/com/vbee/utils/Decision","appbo/vdml/BMElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,async,DataManager,global,MeasurableElement,MeasurableElementMixin,Decision
, BMElement
, BMElementMixin
, BusinessModelState
, OrgUnit
, Scenario
, BusinessModelDiagram
, ParticipantNetwork
, Role
, BusinessModelCustomer
, BusinessModelValueProposition
, BusinessModelActivity
, BusinessModelCompetence
, BusinessModelNetworkPartner
, BusinessModelValueFormula
, Store
, Pool
, ValueStream
, BusinessNetwork
, Community
,Activity
,ValueProposition
,CapabilityOffer
, Ticket
){*/
    
    var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
    if(!BMElement){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.BMElement){
            BMElement = importPath.BMElement;
        }
        else{
           import('./BMElement').then(({ default: BMElement }) => {
                BMElement = BMElement;
            });
			/*require(["appbo/vdml/BMElement"],function(loadedModule){
				BMElement = loadedModule;
			});*/
        }
    }
    if(!BMElementMixin){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.BMElementMixin){
            BMElementMixin = importPath.BMElementMixin;
        }
        else{
            import('./BMElementMixin').then(({ default: BMElementMixin }) => {
                BMElementMixin = BMElementMixin;
            });
			/*require(["appbo/vdml/BMElementMixin"],function(loadedModule){
				BMElementMixin = loadedModule;
			});*/
        }
    }
    if(!BusinessModelState){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.BusinessModelState){
            BusinessModelState = importPath.BusinessModelState;
        }
        else{
            import('./BusinessModelState').then(({ default: BusinessModelState }) => {
                BusinessModelState = BusinessModelState;
            });
			/*require(["appbo/vdml/BusinessModelState"],function(loadedModule){
				BusinessModelState = loadedModule;
			});*/
        }
    }
    if(!OrgUnit){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.OrgUnit){
            OrgUnit = importPath.OrgUnit;
        }
        else{
            import('./OrgUnit').then(({ default: OrgUnit }) => {
                OrgUnit = OrgUnit;
            });
			/*require(["appbo/vdml/OrgUnit"],function(loadedModule){
				OrgUnit = loadedModule;
			});*/
        }
    }
    if(!Scenario){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.Scenario){
            Scenario = importPath.Scenario;
        }
        else{
            import('./Scenario').then(({ default: Scenario }) => {
                Scenario = Scenario;
            });
			/*require(["appbo/vdml/Scenario"],function(loadedModule){
				Scenario = loadedModule;
			});*/
        }
    }
    if(!BusinessModelDiagram){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.BusinessModelDiagram){
            BusinessModelDiagram = importPath.BusinessModelDiagram;
        }
        else{
           import('./BusinessModelDiagram').then(({ default: BusinessModelDiagram }) => {
                BusinessModelDiagram = BusinessModelDiagram;
            });
			/*require(["appbo/vdml/BusinessModelDiagram"],function(loadedModule){
				BusinessModelDiagram = loadedModule;
			});*/
        }
    }
    if(!ParticipantNetwork){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.ParticipantNetwork){
            ParticipantNetwork = importPath.ParticipantNetwork;
        }
        else{
            import('./ParticipantNetwork').then(({ default: ParticipantNetwork }) => {
                ParticipantNetwork = ParticipantNetwork;
            });
			/*require(["appbo/vdml/ParticipantNetwork"],function(loadedModule){
				ParticipantNetwork = loadedModule;
			});*/
        }
    }
    if(!Role){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.Role){
            Role = importPath.Role;
        }
        else{
            import('./Role').then(({ default: Role }) => {
                Role = Role;
            });
			/*require(["appbo/vdml/Role"],function(loadedModule){
				Role = loadedModule;
			});*/
        }
    }
    if(!BusinessModelCustomer){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.BusinessModelCustomer){
            BusinessModelCustomer = importPath.BusinessModelCustomer;
        }
        else{
           import('./BusinessModelCustomer').then(({ default: BusinessModelCustomer }) => {
                BusinessModelCustomer = BusinessModelCustomer;
            });
			/*require(["appbo/vdml/BusinessModelCustomer"],function(loadedModule){
				BusinessModelCustomer = loadedModule;
			});*/
        }
    }
    if(!BusinessModelValueProposition){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.BusinessModelValueProposition){
            BusinessModelValueProposition = importPath.BusinessModelValueProposition;
        }
        else{
            import('./BusinessModelValueProposition').then(({ default: BusinessModelValueProposition }) => {
                BusinessModelValueProposition = BusinessModelValueProposition;
            });
			/*require(["appbo/vdml/BusinessModelValueProposition"],function(loadedModule){
				BusinessModelValueProposition = loadedModule;
			});*/
        }
    }
    if(!BusinessModelActivity){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.BusinessModelActivity){
            BusinessModelActivity = importPath.BusinessModelActivity;
        }
        else{
           import('./BusinessModelActivity').then(({ default: BusinessModelActivity }) => {
                BusinessModelActivity = BusinessModelActivity;
            });
			/*require(["appbo/vdml/BusinessModelActivity"],function(loadedModule){
				BusinessModelActivity = loadedModule;
			});*/
        }
    }
    if(!BusinessModelCompetence){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.BusinessModelCompetence){
            BusinessModelCompetence = importPath.BusinessModelCompetence;
        }
        else{
            import('./BusinessModelCompetence').then(({ default: BusinessModelCompetence }) => {
                BusinessModelCompetence = BusinessModelCompetence;
            });
			/*require(["appbo/vdml/BusinessModelCompetence"],function(loadedModule){
				BusinessModelCompetence = loadedModule;
			});*/
        }
    }
    if(!BusinessModelNetworkPartner){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.BusinessModelNetworkPartner){
            BusinessModelNetworkPartner = importPath.BusinessModelNetworkPartner;
        }
        else{
            import('./BusinessModelNetworkPartner').then(({ default: BusinessModelNetworkPartner }) => {
                BusinessModelNetworkPartner = BusinessModelNetworkPartner;
            });
			/*require(["appbo/vdml/BusinessModelNetworkPartner"],function(loadedModule){
				BusinessModelNetworkPartner = loadedModule;
			});*/
        }
    }
    if(!BusinessModelValueFormula){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.BusinessModelValueFormula){
            BusinessModelValueFormula = importPath.BusinessModelValueFormula;
        }
        else{
           import('./BusinessModelValueFormula').then(({ default: BusinessModelValueFormula }) => {
                BusinessModelValueFormula = BusinessModelValueFormula;
            });
			/*require(["appbo/vdml/BusinessModelValueFormula"],function(loadedModule){
				BusinessModelValueFormula = loadedModule;
			});*/
        }
    }
    if(!Store){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.Store){
            Store = importPath.Store;
        }
        else{
           import('./Store').then(({ default: Store }) => {
                Store = Store;
            });
			/*require(["appbo/vdml/Store"],function(loadedModule){
				Store = loadedModule;
			});*/
        }
    }
    if(!Pool){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.Pool){
            Pool = importPath.Pool;
        }
        else{
			import('./Pool').then(({ default: Pool }) => {
                Pool = Pool;
            });
            /*require(["appbo/vdml/Pool"],function(loadedModule){
                Pool = loadedModule;
            });*/
        }
    }   
    if(!ValueStream){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.ValueStream){
            ValueStream = importPath.ValueStream;
        }
        else{
            import('./ValueStream').then(({ default: ValueStream }) => {
                ValueStream = ValueStream;
            });
			/*require(["appbo/vdml/ValueStream"],function(loadedModule){
				ValueStream = loadedModule;
			});*/
        }
    }
    if(!BusinessNetwork){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.BusinessNetwork){
            BusinessNetwork = importPath.BusinessNetwork;
        }
        else{
			import('./BusinessNetwork').then(({ default: BusinessNetwork }) => {
                BusinessNetwork = BusinessNetwork;
            });
            /*require(["appbo/vdml/BusinessNetwork"],function(loadedModule){
                BusinessNetwork = loadedModule;
            });*/
        }
    }
    if(!Community){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.Community){
            Community = importPath.Community;
        }
        else{
			import('./Community').then(({ default: Community }) => {
                Community = Community;
            });
            /*require(["appbo/vdml/Community"],function(loadedModule){
                Community = loadedModule;
            });*/
        }
    }
    if(!ValueProposition){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.ValueProposition){
            ValueProposition = importPath.ValueProposition;
        }
        else{
			import('./ValueProposition').then(({ default: ValueProposition }) => {
                ValueProposition = ValueProposition;
            });
            /*require(["appbo/vdml/ValueProposition"],function(loadedModule){
                ValueProposition = loadedModule;
            });*/
        }
    }   
    
    if(!Activity){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.Activity){
            Activity = importPath.Activity;
        }
        else{
			import('./Activity').then(({ default: Activity }) => {
                Activity = Activity;
            });
            /*require(["appbo/vdml/Activity"],function(loadedModule){
                Activity = loadedModule;
            });*/
        }
    }
    
    if(!CapabilityOffer){
        var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
        if(importPath.CapabilityOffer){
            CapabilityOffer = importPath.CapabilityOffer;
        }
        else{
			import('./CapabilityOffer').then(({ default: CapabilityOffer }) => {
                CapabilityOffer = CapabilityOffer;
            });
            /*require(["appbo/vdml/CapabilityOffer"],function(loadedModule){
                CapabilityOffer = loadedModule;
            });*/
        }
    }
    if(importPath.Ticket){
        Ticket = importPath.Ticket;
    }
    else{
		import('../tickets/Ticket').then(({ default: Ticket }) => {
            Ticket = Ticket;
        });
        /*require(["appbo/tickets/Ticket"],function(loadedModule){
            Ticket = loadedModule;
        });*/
    }
    export class BusinessModelMixin {

    defaults(){
        var ret = {
            type: "vdml_BusinessModel"
        }
        return jQuery.extend(MeasurableElement.prototype.defaults.apply(this),ret);
    }
    static getMixinRelations(){
        return _.union([
        {
            type :Backbone.HasOne,
            containingClass:"vdml_BusinessModel",
            key:"state",
            relatedModel:"vdml.BusinessModelState",
            includeInJSON: Backbone.Model.prototype.idAttribute,
        },
        {
            type :Backbone.HasOne,
            containingClass:"vdml_BusinessModel",
            key:"diagram",
            relatedModel:"vdml.BusinessModelDiagram",
            reverseRelation: {
                key:"businessModel",
                type :Backbone.HasOne,
                includeInJSON:"id"
            }
        },
        {
            type :Backbone.HasMany,
            containingClass:"vdml_BusinessModel",
            key:"participantNetwork",
            relatedModel:"vdml.ParticipantNetwork",
            reverseRelation: {
                key:"participantNetworkOwner",
                type :Backbone.HasOne,
                includeInJSON:"id"
            }
        },
        {
            type :Backbone.HasMany,
            containingClass:"vdml_BusinessModel",
            key:"bmRole",
            relatedModel:"vdml.Role",
            includeInJSON: Backbone.Model.prototype.idAttribute,
        },
        {
            type :Backbone.HasMany,
            containingClass:"vdml_BusinessModel",
            key:"bmCustomer",
            relatedModel:"vdml.BusinessModelCustomer",
            reverseRelation: {
                key:"bmCustomerOwner",
                type :Backbone.HasOne,
                includeInJSON:"id"
            }
        },
        {
            type :Backbone.HasMany,
            containingClass:"vdml_BusinessModel",
            key:"bmValueProposition",
            relatedModel:"vdml.BusinessModelValueProposition",
            reverseRelation: {
                key:"bmValuePropositionOwner",
                type :Backbone.HasOne,
                includeInJSON:"id"
            }
        },
        {
            type :Backbone.HasMany,
            containingClass:"vdml_BusinessModel",
            key:"bmActivity",
            relatedModel:"vdml.BusinessModelActivity",
            reverseRelation: {
                key:"bmActivityOwner",
                type :Backbone.HasOne,
                includeInJSON:"id"
            }
        },
        {
            type :Backbone.HasMany,
            containingClass:"vdml_BusinessModel",
            key:"bmCompetence",
            relatedModel:"vdml.BusinessModelCompetence",
            reverseRelation: {
                key:"bmCompetenceOwner",
                type :Backbone.HasOne,
                includeInJSON:"id"
            }
        },
        {
            type :Backbone.HasMany,
            containingClass:"vdml_BusinessModel",
            key:"bmNetworkPartner",
            relatedModel:"vdml.BusinessModelNetworkPartner",
            reverseRelation: {
                key:"bmNetworkPartnerOwner",
                type :Backbone.HasOne,
                includeInJSON:"id"
            }
        },
        {
            type :Backbone.HasMany,
            containingClass:"vdml_BusinessModel",
            key:"bmValueFormula",
            relatedModel:"vdml.BusinessModelValueFormula",
            reverseRelation: {
                key:"bmValueFormulaOwner",
                type :Backbone.HasOne,
                includeInJSON:"id"
            }
        },
        {
            type :Backbone.HasMany,
            containingClass:"vdml_BusinessModel",
            key:"bmResourceStore",
            relatedModel:"vdml.Store",
            includeInJSON: Backbone.Model.prototype.idAttribute,
        },
        {
            type :Backbone.HasMany,
            containingClass:"vdml_BusinessModel",
            key:"bmValueStream",
            relatedModel:"vdml.ValueStream",
            includeInJSON: Backbone.Model.prototype.idAttribute,
        }
        ]);
    };
    static getCumulativeMixinRelations(){
        if (!BusinessModelMixin.cummulativeRelations) {
            BusinessModelMixin.cummulativeRelations = _.union(BusinessModelMixin.getMixinRelations()
                ,BMElementMixin.getCumulativeMixinRelations()
                ,MeasurableElementMixin.getCumulativeMixinRelations()
            );
        }
		return BusinessModelMixin.cummulativeRelations.slice();
	};
    static getSuperTypes(){
        return [
            "vdml_BMElement",
            "vdml_MeasurableElement"
        ];
    }
    static getProperties(){
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            {name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
            {name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
            {name : "picturePosition",type : "EString",defaultValue : "null",containingClass : "vdml_BusinessModel" }
        ]
    }
    getParent(){
        var container;
        if(!container){
            container = this.get("businessModelOwner") ? this.get("businessModelOwner") : this.previousAttributes().businessModelOwner;
            if(container){
                return container;
            }
        }
        return this;
    }
    getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("businessModelOwner") ? this.get("businessModelOwner") : this.previousAttributes().businessModelOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}            
    getViewProperties(type){
        return {
            templatePath : "views/vdml/views/properties/BusinessModelPropertiesTemplate.html",
            templateName : "BusinessModelPropertiesTemplate",
            viewTypeStr : "appviews/vdml/views/properties/BusinessModelViewModel",
            tabId : "BusinessModelView",
            tabName: "BusinessModel"
        }
    }
//#startCustomMethods   
    getMixinClass(){
        return BusinessModelMixin;
    }
    getViewProperties(type){
        return BusinessModelMixin.getDialogViewProperties(type);
    };
     static getDialogViewProperties(type){
     	if(type && type === "BusinessDetails"){
            return {
                templatePath : "views/vdml/views/properties/BusinessDetailsPropertiesTemplate.html",
                templateName : "BusinessDetailsPropertiesTemplate",
                viewTypeStr : "appviews/vdml/views/properties/BusinessDetailsViewModel",
                tabId : "BusinessDetailsView",
                tabName: "BusinessDetails"
            }
        }
		if(type==="explorerModelJson"){
			return{
				templatePath : "views/beeppackage/views/properties/explorerModelJsonTemplate.html",
			    templateName : "explorerModelJsonTemplate",
			    viewTypeStr : "appviews/beeppackage/views/properties/explorerModelJsonViewModel",
			     tabId: "explorerModelJsonView",
			    tabName: "explorerModelJson"
			}
		}
        if(type && type === "BusinessModelSummernoteReportDetails"){
            return {
                templatePath : "views/vdml/views/report/BusinessModelSummernoteReportTemplate.html",
                templateName : "BusinessModelSummernoteReportTemplate",
                viewTypeStr : "appviews/vdml/views/report/BusinessModelSummernoteReportViewModel",
                tabId : "BusinessModelSummernoteReportView",
                tabName: "BusinessModelSummernoteReportView"
            }
        }
        if(type && type === "CanvasDesigner"){
            return {
                templatePath : "views/vdml/views/designer/canvasTemplates/NineDimensionsDesignerTemplate.html",
                templateName : "NineDimensionsDesignerTemplate",
                viewTypeStr : "appviews/vdml/views/designer/CanvasDesignerViewModel",
                tabId : "CanvasDesignerView",
                tabName: "CanvasDesignerView"
            }
        } 		
        if(type && type === "NetworkPartner"){
            return {
                templatePath : "views/vdml/views/properties/NetworkPartnerPropertiesTemplate.html",
                templateName : "NetworkPartnerPropertiesTemplate",
                viewTypeStr : "appviews/vdml/views/properties/NetworkPartnerViewModel",
                tabId : "NetworkPartnerView",
                tabName: "NetworkPartner"
            }
        }
        if(type && type === "CustomerDetails"){
            return {
                templatePath : "views/vdml/views/properties/CustomerDetailsPropertiesTemplate.html",
                templateName : "CustomerDetailsPropertiesTemplate",
                viewTypeStr : "appviews/vdml/views/properties/CustomerDetailsViewModel",
                tabId : "CustomerDetailsView",
                tabName: "CustomerDetails"
            }
        }
        if(type && type === "BusinessModelReportDetails"){
            return {
                templatePath : "views/vdml/views/properties/BusinessModelReportTemplate.html",
                templateName : "BusinessModelReportTemplate",
                viewTypeStr : "appviews/vdml/views/properties/BusinessModelReportViewModel",
                tabId : "BusinessModelReportView",
                tabName: "BusinessModelReportView"
            }
        }
        if(type && type === "ActivityDetails"){
            return {
                templatePath : "views/vdml/views/properties/BusinessModelActivityDetailsPropertiesTemplate.html",
                templateName : "BusinessModelActivityDetailsPropertiesTemplate",
                viewTypeStr : "appviews/vdml/views/properties/BusinessModelActivityDetailsViewModel",
                tabId : "BusinessModelActivityDetailsView",
                tabName: "BusinessModelActivityDetails"
            }
        }
        if(type && type === "CompetencyDetails"){
            return {
                templatePath : "views/vdml/views/properties/BusinessModelCompetencyDetailsPropertiesTemplate.html",
                templateName : "BusinessModelCompetencyDetailsPropertiesTemplate",
                viewTypeStr : "appviews/vdml/views/properties/BusinessModelCompetencyDetailsViewModel",
                tabId : "BusinessModelCompetencyDetailsView",
                tabName: "BusinessModelCompetencyDetails"
            }
        }
        if(type && type === "Cube"){
            return {
                templatePath : "views/vdml/views/bmcube/BMCubeTemplate.html",
                templateName : "BMCubeTemplate",
                viewTypeStr : "appviews/vdml/views/bmcube/BMCubeViewModel",
                tabId : "BMCubeView",
                tabName: "BusinessModelCube"
            }
        }
        if(type && type === "ValueDetails"){
            return {
                templatePath : "views/vdml/views/properties/ValueDetailsPropertiesTemplate.html",
                templateName : "ValueDetailsPropertiesTemplate",
                viewTypeStr : "appviews/vdml/views/properties/ValueDetailsViewModel",
                tabId : "ValueDetailsView",
                tabName: "ValueDetails"
            }
        }
		if(type==="vdml_ValueDeliveryModel"){
			return{
				templatePath : "views/vdml/views/properties/EditValuedeliveryModelTemplate.html",
			    templateName : "EditValuedeliveryModelTemplate",
			    viewTypeStr : "appviews/vdml/views/properties/EditValueDeliveryModelDetailsViewModel",
			    tabId : "EditValueDeliveryModelDetailsView",
			    tabName: "EditValueDeliveryModelDetails"
			}
		}
		if(type && type==="ValueUnitDetails"){
			return{
				templatePath : "views/vdml/views/properties/ValueUnitDetailsPropertiesTemplate.html",
			    templateName : "ValueUnitDetailsPropertiesTemplate",
			    viewTypeStr : "appviews/vdml/views/properties/ValueUnitDetailsViewModel",
			    tabId : "ValueUnitDetailsView",
			    tabName: "ValueUnitDetails"
			}
         }
         if (type && type === "ValueExpressionDetails") {
             return {
                 templatePath: "views/vdml/views/properties/ValueExpressionDetailsPropertiesTemplate.html",
                 templateName: "ValueExpressionDetailsPropertiesTemplate",
                 viewTypeStr: "appviews/vdml/views/properties/ValueExpressionDetailsViewModel",
                 tabId: "ValueExpressionDetailsView",
                 tabName: "ValueExpressionDetails"
             }
         }
         if (type && type === "ecoMap") {
            return {
                templatePath : "views/ecoMap/views/designer/EcoMapDesignerTemplate.html",
                templateName : "EcoMapDesignerTemplate",
                viewTypeStr : "appviews/ecoMap/views/designer/EcoMapDesignerViewModel",
                tabId : "EcoMapDesignerView",
                tabName: "EcoMapDesignerView"
            }
        }if(type && type === "businessModelValidation"){
            return {
                templatePath : "views/vdml/views/correction/BusinessModelValidationTemplate.html",
                templateName : "BusinessModelValidationTemplate",
                viewTypeStr : "appviews/vdml/views/correction/BusinessModelValidationViewModel",
                tabId : "BusinessModelValidationView",
                tabName: "BusinessModelValidation"
            }
        }if(type && type==="moveValue"){
             return{
                 templatePath : "views/vdml/views/properties/MoveValueTemplate.html",
                 templateName : "MoveValueTemplate",
                 viewTypeStr : "appviews/vdml/views/properties/MoveValueViewModel",
                 tabId : "MoveValueView",
                 tabName: "MoveValue"
             }
         }
        return {
            templatePath : "views/vdml/views/properties/BusinessModelPropertiesTemplate.html",
            templateName : "BusinessModelPropertiesTemplate",
            viewTypeStr : "appviews/vdml/views/properties/BusinessModelViewModel",
            tabId : "BusinessModelView",
            tabName: "BusinessModel"
        }
    };

	capabilityOfferScenarioChange(scenario,activity){
		var self = this;
        var changeActivityScenario;
        var activityContexts = activity.get('delegationContext');
        for (var i = 0; i < activityContexts.length; i++) {
            var scenarioContexts = scenario.get('delegationContext');
            for (var j = 0; j < scenarioContexts.length; j++) {
                if (scenarioContexts.at(j) === activityContexts.at(i)) {
                    changeActivityScenario = activityContexts.at(i);
                    break;
                }
                if (changeActivityScenario) {
                    break;
                }
            }
        }
        if (changeActivityScenario) {
            changeActivityScenario.set('localChange', true);
        }
	}

	deleteBMCompetency(competence,activity,scenario,callback){
		var self = this;
		if (competence.get('type') === 'vdml_BusinessItem') {
            self.comptenceReUsedWithActivity(competence, function (reused) {
                if (!reused && activity) {
                    activity.deleteResourceInput(self, competence, scenario, function () {
                        //resourcesToBeDeleted.push(competence);
                        callback(true);
                    });
                } else if(reused && activity){
                    self.businessCanvasDeletions([competence]);
                    //competencyModal.destroy();
                    callback(false);
                }else{
					callback(false);
				}
            },activity);
        } else {
			var activityContext;
            scenario.get('delegationContext').each(function (delCont) {
                if (delCont.get('delegatedActivity') === activity) {
                    activityContext = delCont;
                }
            });
            var contextColl = activityContext.get('contextCollaboration');
            activity.deleteCapabilityUse(self, competence, scenario, function () {
                self.businessCanvasDeletions(competence);
                //competencyModal.destroy();(since BMcompetencyModal is reused in bm)
                function checkForEmptyCapabilityMethods(deleteComptencyCallback) {
                    var deleContexts = activity.get('delegationContext');
                    if (deleContexts.length > 0) {
                        var contextModels = deleContexts.models.concat();
                        async.eachSeries(contextModels, function (delContext, contextHandled) {
                            var cm = delContext.get('contextCollaboration');
                            var cmDelContexts = cm.get('delegationContext');
                            var delToOnlyActivity = true;
                            cmDelContexts.each(function (cmDelContext) {
                                if (cmDelContext.get('delegatedActivity') !== activity) {
                                    delToOnlyActivity = false;
                                }
                            });
                            if (delToOnlyActivity && (cm.get('type') === 'vdml_CapabilityMethod') && (cm.get('activity').length === 0)) {
                                self.activityReUsed(activity, function (reUsed) {
                                    if (!reUsed) {
                                        cmDelContexts.each(function (cmDelContext) {
                                            cmDelContext.destroy();
                                        });
                                        cm.destroy();
                                    }
                                    contextHandled();
                                });
                            } else {
                                contextHandled();
                            }
                        }, function (err) {
                            if (deleteComptencyCallback) {
                                deleteComptencyCallback();
                            }
                        });
                    } else {
                        if (deleteComptencyCallback) {
                            deleteComptencyCallback();
                        }
                    }
                }
				checkForEmptyCapabilityMethods(callback);
            });
		}
	}

    createParticipantNetwork(name,collaboration,alt,callback){
    	var self = this;
        var vdml = this.getNestedParent();
        var uniqueCollName = vdml.getUniqueProperty('name',name,'collaboration');
        function createPN(coll,callback){
        	var pnId = DataManager.getDataManager().guidGeneratorByOwner(self);
	        var participantNetwork = new ParticipantNetwork({id:pnId,name:coll.get('name'),participantNetworkOwner:self});
	        
	        participantNetwork.set('collaboration',coll);
	    	callback(participantNetwork);
        }
        if(!collaboration) {
        	var namePath = vdml.get('name') +'>'+uniqueCollName;
        	var collId = DataManager.getDataManager().guidGeneratorByOwner(vdml);
        	vdml.getDocumentWithNamePath(namePath, "vdml_BusinessNetwork", alt, false, vdml, function (result) {
                if (result && result[0]) {
                    collId = alt.id + window.utils.getSuffix(result[0].doc);
                }
            	collaboration = new BusinessNetwork({id:collId,name:uniqueCollName,collaborationOwner:vdml});
            	collaboration.set('collaborationOwner',vdml);
            	createPN(collaboration,callback);
            });
        }else {
        	createPN(collaboration,callback);
        }
    };
    
    createBmCustomer(name,participantDescription,customer){
		var self = this;
    	var bmCustomer = self.get('bmCustomer').findWhere({'customer':customer});
        if(!bmCustomer) {
        	var bmCustId = DataManager.getDataManager().guidGeneratorByOwner(self);
	        bmCustomer = new BusinessModelCustomer({
	                    id:bmCustId,
	                    'name':name,
	                    'description':participantDescription,
	                    bmCustomerOwner:self});
	        bmCustomer.set('customer',customer);
        }
        return bmCustomer;
    };
    
    createNewCustomer(participantName,participantDescription,type,customer,partNetwork,alt,callback){
        // based on type create corresponding participant, add bmCustomer pointing to this participant, return bmCustomer
        var self = this;
        var name = participantName;
        var vdml = this.getNestedParent();
        var typeId = DataManager.getDataManager().guidGeneratorByOwner(vdml);
        if(!customer) {
        	var coll = partNetwork.getNestedParent().get('collaboration');
			for(var i=0;i<coll.length;i++){
				if(coll.at(i).get('name') === participantName && coll.at(i) instanceof type){
					customer = coll.at(i);
					break;
				}
			}
		}	
        if(!customer){
	        if(type === Community || type === OrgUnit){
	        	name = vdml.getUniqueProperty('name',participantName,'collaboration');
	        	var namePath = vdml.get('name') + '>'+name;
	        	var vdmlType = "vdml_Community";
	        	if(type === OrgUnit){
	        		vdmlType = "vdml_OrgUnit";
	        	}
		        vdml.getDocumentWithNamePath(namePath, vdmlType, alt, false, vdml, function (result) {
		        	if (result && result[0] && alt && alt.id) {
		                typeId = alt.id + window.utils.getSuffix(result[0].doc);
		            }
		            customer = new type({id:typeId,name:name,description:participantDescription,collaborationOwner:vdml});
					var bmCustomer = self.createBmCustomer(name, participantDescription, customer);
		            callback(bmCustomer);
		        });
	             
	        }else{
	        	name = vdml.getUniqueProperty('name',participantName,'actor');
	        	var namePath = vdml.get('name') + '>'+name;
		        vdml.getDocumentWithNamePath(namePath, "vdml_Actor", alt, false, vdml, function (result) {
		        	if (result && result[0] && alt && alt.id) {
		                typeId = alt.id + window.utils.getSuffix(result[0].doc);
		            }
		            customer = new type({id:typeId,name:name,description:participantDescription,actorOwner:vdml}); 
					var bmCustomer = self.createBmCustomer(name, participantDescription, customer);
		            callback(bmCustomer);
		        });
	            
	        }
        }else {
			var bmCustomer = self.createBmCustomer(name, participantDescription, customer);
		    callback(bmCustomer);
        }
    };
    
    createNewCompetency(name,description,type,activity,orgUnit,mainscenario,alt,callback, capability){
    	var self = this;
        var competence;
        var vdml = self.getNestedParent();
        var bmPack = this.getNestedParent();
        var business = this.get('business');
        var typeId = DataManager.getDataManager().guidGeneratorByOwner(bmPack);
        var competenceName = name;
        if(type === CapabilityOffer){
        	competenceName = business.getUniqueProperty('name',name,'capabilityOffer');
	    	var namePath = vdml.get('name') + '>'+ self.get('business').get('name') + '>'+competenceName;
	        vdml.getDocumentWithNamePath(namePath, "vdml_CapabilityOffer", alt, false, vdml, function (result) {
	        	if (result && result[0] && alt && alt.id) {
	                typeId = alt.id + window.utils.getSuffix(result[0].doc);
	            }
	            competence = new type({id:typeId,name:competenceName,description:description,capabilityProvider:self.get('business')});
				self.createBmCompetency(competenceName, description, competence, callback);
				if (capability && capability.get('type') !== "vdml_CapabilityOffer") {
					competence.set('capability', capability);
				}
	        });
//            activity.createCapabilityUse(this,competence,orgUnit,mainscenario);
        }else{
        	competenceName = business.getUniqueProperty('name',name,'businessItem');
        	var namePath = vdml.get('name') + '>'+ self.get('business').get('name') + '>'+competenceName;
	        vdml.getDocumentWithNamePath(namePath, "vdml_BusinessItem", alt, false, vdml, function (result) {
	        	if (result && result[0] && alt && alt.id) {
	                typeId = alt.id + window.utils.getSuffix(result[0].doc);
	            }
	            competence = new type({id:typeId,name:competenceName,description:description,businessItemOwner:self.get('business')});
	            self.createBmCompetency(competenceName,description,competence,callback);
	        });
//            activity.createResourceInput(this,competence,orgUnit);
        }
    };
    
    createBmCompetency(competenceName,description,competence,callback){
    	var self = this;
    	var bmCompId = DataManager.getDataManager().guidGeneratorByOwner(this);
        var bmCompetency = new BusinessModelCompetence({
                    id:bmCompId,
                    'name':competenceName,
                    'description':description,
                    bmCompetenceOwner:self});
        bmCompetency.set('competence',competence);
        callback(competence);
    };
    
    addExistingCompetency (competence, type, activity, orgUnit, mainscenario, capability){
/*        if(type === CapabilityOffer){ 
            activity.createCapabilityUse(this,competence,orgUnit,mainscenario);
        }else{
            activity.createResourceInput(this,competence,orgUnit);
        }*/
		/*if(activity.getActivityCompetences().indexOf(competence) >= 0){
			return;
		}*/
		if (capability && competence.get('type') == "vdml_CapabilityOffer") {
            competence.set('capability', capability);
        }
		if(this.get('bmCompetence').findWhere({'competence':competence})){
		       return;
		}
		var bmCompId = DataManager.getDataManager().guidGeneratorByOwner(this);
        var bmCompetency = new BusinessModelCompetence({
            id:bmCompId,
            'name':competence.get('name'),
            'description':competence.get('description'),
            bmCompetenceOwner:this});
        bmCompetency.set('competence', competence);

        
    };
    
    addNewCustomerRole(bmCustomer,roleName,roleDesc,partNetwork,alt,callback){
        //create a new role in the collaboration linked to PN, assign role to bmCustomer particiapnt, add role to bmRole.
        var self = this;
        var pnColl = partNetwork.get('collaboration');
        var vdml = self.getNestedParent();
    	var namePath = vdml.get('name') + '>'+ pnColl.get('name') + '>'+roleName;
        var roleId;
        vdml.getDocumentWithNamePath(namePath, "vdml_Role", alt, false, vdml, function (result) {
        	if (result && result[0] && alt && alt.id) {
                roleId = alt.id + window.utils.getSuffix(result[0].doc);
            }
            var role = pnColl.createDefaultRole(roleName,roleId);
	        role.set('description',roleDesc);
	        self.get('bmRole').add(role);
	        var assignment = pnColl.assignRoleToParticipant(role,bmCustomer.get('customer'));
			var newRole = assignment.get("assignedRole");
			bmCustomer.get('customerRole').add(newRole);
            callback(assignment);
        });
    }
    addExistingTypeaheadCustomerRole(bmCustomer,role,partNetwork){
        //create a new role in the collaboration linked to PN, assign role to bmCustomer particiapnt, add role to bmRole.
        var self = this;
        var pnColl = partNetwork.get('collaboration');
        var roleExists = self.get('bmRole').findWhere({id: role.get('id')});
        if(!roleExists){
        	self.get('bmRole').add(role);
        }
        var assignment;
        var participant = bmCustomer.get('customer');
        if(role.get('roleAssignment').length == 0){
			assignment = pnColl.assignRoleToParticipant(role, participant);
        }
        else {
        	assignment = role.get('roleAssignment').at(0);
        	if(assignment.get('participant') === participant){
        		return assignment;
        	}
        	else {
        		role.getBusinessModelsInvolved(function(businessModels) {
        			businessModels.each(function(bm){
        				if(self !== bm){
        					bm.get('bmRole').remove(role);
        				}
        				else {
        					var propList = role.get('providedProposition');
        					var bmValueFormulas = self.get('bmValueFormula');
        					for(var i=0;i<propList.length;i++){
        						var vf = propList.at(i).get('isValueFormula');
        						if(vf) {
        							var bmVF = bmValueFormulas.findWhere({'valueFormula': propList.at(i)});
        							if(bmVF) {
        								bmVF.destroy();
        							}
        						}
        					}
        				}
        			});
        			assignment.destroy();
				});
        		return pnColl.assignRoleToParticipant(role,participant);
        	}
        }
        return assignment;
    }
    
    createBmNetworkPartner(name,partnerDescription,networkPartner){
    	var self = this;
    	var bmPartner = self.get('bmNetworkPartner').findWhere({'networkPartner':networkPartner});
        if(!bmPartner) {
	        var bmPartId = DataManager.getDataManager().guidGeneratorByOwner(self);
	        bmPartner = new BusinessModelNetworkPartner({
	                    id:bmPartId,
	                    'name':name,
	                    'description':partnerDescription,
	                    bmNetworkPartnerOwner:self});
	                    bmPartner.set('networkPartner',networkPartner);
		}
        return bmPartner;
    };
    
    createNewPartner(partnerName,partnerDescription,type,networkPartner,partNetwork,alt,callback){
        // based on type create corresponding participant, add bmCustomer pointing to this participant, return bmCustomer
        var self = this;
        var vdml = this.getNestedParent();
        var name = partnerName;
        var typeId = DataManager.getDataManager().guidGeneratorByOwner(vdml);
        if(!networkPartner) {
	        var coll = partNetwork.getNestedParent().get('collaboration');
			for(var i=0;i<coll.length;i++){
				if(coll.at(i).get('name') === partnerName && coll.at(i) instanceof type){
					networkPartner = coll.at(i);
					break;
				}
			}
		}
        if(!networkPartner){
	        if(type === Community || type === OrgUnit){
	        	name = vdml.getUniqueProperty('name',partnerName,'collaboration');
	        	var namePath = vdml.get('name') + '>'+name;
	        	var vdmlType = "vdml_Community";
	        	if(type === OrgUnit){
	        		vdmlType = "vdml_OrgUnit";
	        	}
		        vdml.getDocumentWithNamePath(namePath, vdmlType, alt, false, vdml, function (result) {
		        	if (result && result[0] && alt && alt.id) {
		                typeId = alt.id + window.utils.getSuffix(result[0].doc);
		            }
		            networkPartner = new type({id:typeId,name:name,description:partnerDescription,collaborationOwner:vdml});
		            var bmPartner = self.createBmNetworkPartner(name,partnerDescription,networkPartner);
		            callback(bmPartner);
		        });
	        }else{
	        	name = vdml.getUniqueProperty('name',partnerName,'actor');
	        	var namePath = vdml.get('name') + '>'+name;
		        vdml.getDocumentWithNamePath(namePath, 'vdml_Actor', alt, false, vdml, function (result) {
		        	if (result && result[0] && alt && alt.id) {
		                typeId = alt.id + window.utils.getSuffix(result[0].doc);
		            }
		            networkPartner = new type({id:typeId,name:name,description:partnerDescription,actorOwner:vdml});
		            var bmPartner = self.createBmNetworkPartner(name,partnerDescription,networkPartner);
		            callback(bmPartner);
		        });
	        }
        }else {
        	var bmPartner = self.createBmNetworkPartner(name,partnerDescription,networkPartner);
		    callback(bmPartner);
        }
    }
    
    addNewPartnerRole(bmPartner,roleName,roleDesc,partNetwork,alt,callback){
        //create a new role in the collaboration linked to PN, assign role to bmCustomer particiapnt, add role to bmRole.               
        var self = this;
        var pnColl = partNetwork.get('collaboration');
        var vdml = self.getNestedParent();
        var namePath = vdml.get('name') + '>'+ pnColl.get('name') + '>'+roleName;
        var roleId;
        vdml.getDocumentWithNamePath(namePath, "vdml_Role", alt, false, vdml, function (result) {
        	if (result && result[0] && alt && alt.id) {
                roleId = alt.id + window.utils.getSuffix(result[0].doc);
            }
            var role = pnColl.createDefaultRole(roleName,roleId);
	        role.set('description',roleDesc);
	        self.get('bmRole').add(role);
	        var assignment = pnColl.assignRoleToParticipant(role,bmPartner.get('networkPartner'));
			var newRole = assignment.get("assignedRole");
			bmPartner.get('networkPartnerRole').add(newRole);   
			callback(assignment);
		});
    }
    addExistingTypeaheadPartnerRole(bmPartner,role,partNetwork){
        //Adding a Existing typeahead role in the collaboration linked to PN, assign role to bmCustomer particiapnt, add role to bmRole.                
        var self = this;
        var pnColl = partNetwork.get('collaboration');
        var roleExists = self.get('bmRole').findWhere({id: role.get('id')});
        if(!roleExists){
        	self.get('bmRole').add(role);
        }
        var assignment;
        var participant = bmPartner.get('networkPartner');
        if(role.get('roleAssignment').length == 0){
        	assignment = pnColl.assignRoleToParticipant(role,participant);
        }
        else {
        	assignment = role.get('roleAssignment').at(0);
        	if(assignment.get('participant') === participant){
        		return assignment;
        	}
        	else {
        		role.getBusinessModelsInvolved(function(businessModels) {
        			businessModels.each(function(bm){
        				if(self !== bm){
        					bm.get('bmRole').remove(role);
        				}
        				else {
        					var propList = role.get('providedProposition');
        					var bmValueFormulas = self.get('bmValueFormula');
        					for(var i=0;i<propList.length;i++){
        						var vf = propList.at(i).get('isValueFormula');
        						if(vf) {
        							var bmVF = bmValueFormulas.findWhere({'valueFormula': propList.at(i)});
        							if(bmVF) {
        								bmVF.destroy();
        							}
        						}
        					}
        				}
        			});
        			assignment.destroy();
        		});
        		return pnColl.assignRoleToParticipant(role,participant);
        	}
        }
        return assignment;
    };
    
    deleteRole(roleObj,callback){
        var self= this;
        var role = roleObj.role;
        var refIds = roleObj.refId;
        var assignment = roleObj.assign;
        if(role && !role.get("collaborationRoleOwner")){
        	if(assignment){
        		assignment.destroy();
        	}
            role.destroy();
            callback();
            return;
        }        
        if(role){
        	DataManager.getDataManager().getReferencesToModel2(role,function(result){
                var len = result.length;
                while (len--) {
                    if (result[len].id === role.get("collaborationRoleOwner").get("id") || result[len].id === self.id || result[len].predicate === "vdml_EcoMapDiagram-participant") {
                        result.splice(len,1);
                    }
                    else {
                        for(var i=0;i<refIds.length;i++){
                            if(result[len].id === refIds[i]){
                                result.splice(len,1);
                                break;
                            }
                        }
                    }
                 }   
                if(result.length==0) {
                	if(assignment){
                		assignment.destroy();
                	}
                    role.destroy();
                    callback();
                }
                else {
                    callback();
                }
             });
        }
    };
    
    checkBmCustomerReused(bmCustomer,collaboration){
    		var bmCustomersRoles = bmCustomer.get('customerRole');
    		var used = false;
    		for(var i=0;i<bmCustomersRoles.length;i++){
    			if(bmCustomersRoles.at(i).get('collaborationRoleOwner') !== collaboration){
    				used = true;
    				break;
    			}
    		}
    		return used;
    };	
    
    checkBmNetworkPartnerReused(bmNetworkPartner,collaboration){
    		var bmPartnerRoles = bmNetworkPartner.get('networkPartnerRole');
    		var used = false;
    		for(var i=0;i<bmPartnerRoles.length;i++){
    			if(bmPartnerRoles.at(i).get('collaborationRoleOwner') !== collaboration){
    				used = true;
    				break;
    			}
    		}
    		return used;
    };
    
    checkRoleExistsForPartner(participant,role,removeRole){
    	var roleUsedInBm;
    	var self = this;
    	var partExists = self.get('bmNetworkPartner').findWhere({'networkPartner':participant});
        if(partExists) {
           	roleUsedInBm = partExists.get('networkPartnerRole').findWhere({'id':role.get('id')});
           	if(removeRole) {
				partExists.get('networkPartnerRole').remove(role);
				if (partExists.get('networkPartnerRole').length == 0) {
					partExists.destroy();
				}
        	}
        }	
    	return roleUsedInBm;
    };	
    
    checkRoleExistsForCustomer(participant,role,removeRole){
    	var roleUsedInBm;
    	var self = this;
    	var custExists = self.get('bmCustomer').findWhere({'customer':participant});
        if(custExists) {
        	roleUsedInBm = custExists.get('customerRole').findWhere({'id':role.get('id')});
        	if(removeRole) {
				custExists.get('customerRole').remove(role);  
				if (custExists.get('customerRole').length == 0) {
					custExists.destroy();
				}
        	}
        }
    	return roleUsedInBm;
    };	

    deleteParticipant(paticipantArray,callback){
            var self= this;
            var bmParticipant = paticipantArray.object;
            var assign = paticipantArray.assignment;
            var type = paticipantArray.type;
            DataManager.getDataManager().getReferencesToModel2(bmParticipant.get(type),function(result){
                var len = result.length;
                while (len--) {
                    if (result[len].id === bmParticipant.id || result[len].id === bmParticipant.getNestedParent().id || result[len].predicate === "vdml_EcoMapDiagram-participant") {
                        result.splice(len,1);
                    } else {
                         if(assign.findWhere({'id':result[len].id})) {
                            result.splice(len,1);
                         }
                    }
                }
                var participant = bmParticipant.get(type);
                var roleArray = [];
                var collaboration = null;
                for(var j=0;j<assign.length;j++){
                    var role = assign.at(j).get('assignedRole');
                    collaboration = role.get('collaborationRoleOwner');
                    //special cond needed when customer and role are added to partner
                    var roleUsedInBm = false;
                    if(type == 'customer'){
	                    roleUsedInBm = self.checkRoleExistsForPartner(participant,role);
                    }
                    else {
                    	roleUsedInBm = self.checkRoleExistsForCustomer(participant,role);
                    }
                    if(!roleUsedInBm) {
                    	self.get("bmRole").remove(role);
                    	roleArray.push({'role':role,'refId':[assign.at(j).get("id"),bmParticipant.get('id')],'assign':assign.at(j)});
                    }
                }
                async.eachSeries(roleArray, self.deleteRole.bind(self), function(err){  
                });
                var reUsed;
                if(type == 'customer'){
                	reUsed = self.checkBmCustomerReused(bmParticipant,collaboration);
                }else {
                	reUsed = self.checkBmNetworkPartnerReused(bmParticipant,collaboration);
                }
                if(!reUsed) {
                	bmParticipant.destroy();
                	self.businessCanvasDeletions([participant]);
                }
                if(result.length==0) {
                	if(participant){
                    	participant.destroy();
                    }
                    callback();
                }
                else {
                    callback();
                }
            });  
    }
    
    /*BusinessModelMixin.prototype.deleteActivityModel(paticipantArray,callback){
        var self= this;
        var bmParticipant = paticipantArray.object;
        var role = paticipantArray.role;
        var collId = role?role.get("collaborationRoleOwner").get("id"):null;
        var activity = paticipantArray.activity;
        DataManager.getDataManager().getReferencesToModel2(activity,function(result){
            var len = result.length;
            while (len--) {
                if(result[len].id === collId || result[len].id === role.id || result[len].id === bmParticipant.id) {
                    result.splice(len,1);
                }
                else {
                    if(result[len].type === "vdml_ValueStream") {
                        result.splice(len,1);
                    }
                }
            }
            var roleArray = [{'role':role,'refId':[activity.get("id")]}];
            if(result.length==0) {
                activity.destroy();
                async.eachSeries(roleArray, self.deleteRole.bind(self), function(err){
                    if(callback) {
                        callback();
                     }
                });
             }
           else {
                if(callback) {
                    callback();
                 }
            }
        });  
    };*/
    
    deleteValueProposition(bmValProp,valueProposition,callback){
        var self= this;
        var provider = valueProposition.get("provider");
        var reciever = valueProposition.get("recipient");
        var recieverId = reciever?reciever.get('id'):null;
        if(!provider) {
        	if(callback){
            	callback();
            }
        	return;
        }
        provider.getBusinessModelsInvolved(function(businessModels) {
    		var roleUsed = false;
    		var vpDel = true;
			businessModels.each(function(bm){
				if(self !== bm){
					if(bm.get('bmValueProposition').findWhere({'valueProposition':valueProposition})){
						vpDel = false;
					}
					roleUsed = true;
				}
			});
			var roleArray = [];
            roleArray.push({'role':provider,'refId':[bmValProp.get("id"),recieverId]});
            if(reciever){
            	roleArray.push({'role':reciever,'refId':[valueProposition.get("id")]});
            }
			bmValProp.destroy();
			var vpCanvasDelList = valueProposition.get('component').models.concat();
			vpCanvasDelList.push(valueProposition);
			self.businessCanvasDeletions(vpCanvasDelList);
            if(vpDel) {
                var vpVSDelList = self.get('bmValueStream')?self.get('bmValueStream').where({'persuedProposition':valueProposition}):null;
                if(vpVSDelList){
                    _.each(vpVSDelList,function(vs){
                        vs.destroy();
                    });
                }
				valueProposition.destroy();  
             }
             if(!roleUsed) {
				async.eachSeries(roleArray, self.deleteRole.bind(self), function(err){
                	if(callback){
                    	callback();
                    }
                });	
			}else {
             	if(callback){
                	callback();
                }
             }
        });     
        /*DataManager.getDataManager().getReferencesToModel2(valueProposition,function(result){
            var destroyVP = true;
			for(var i=0;i<result.length;i++){
			   if(result[i].type === "vdml_BusinessModelValueProposition" && result[i].id !== bmValProp.get('id')) {
		           destroyVP = false;
		           break;
				}
			}
            var roleArray = [];
            roleArray.push({'role':provider,'refId':[bmValProp.get("id"),recieverId]});
            if(reciever){
            	roleArray.push({'role':reciever,'refId':[valueProposition.get("id")]});
            }
			bmValProp.destroy();
            if(destroyVP) {
				valueProposition.destroy();
                async.eachSeries(roleArray, self.deleteRole.bind(self), function(err){
                	if(callback){
                    	callback();
                    }
                });
             }
        }); */
    }
    
    deleteValueFormula(bmValFormula,valueFormula,callback){
        var self= this;
        var role = valueFormula.get('provider');
        if(!role) {
        	if(callback){
            	callback();
            }
        	return;
        }
		role.getBusinessModelsInvolved(function(businessModels) {
    		var roleUsed = false;
    		var vfDel = true;
			businessModels.each(function(bm){
				if(self !== bm){
					if(bm.get('bmValueFormula').findWhere({'valueFormula':valueFormula})){
						vfDel = false;
					}
					roleUsed = true;
				}
			});
			bmValFormula.destroy();
			var vfCanvasDelList = valueFormula.get('component').models.concat();
			vfCanvasDelList.push(valueFormula);
			self.businessCanvasDeletions(vfCanvasDelList);
			if(vfDel){
                var vfVSDelList = self.get('bmValueStream')?self.get('bmValueStream').where({'persuedProposition':valueFormula}):null;
                if(vfVSDelList){
                    _.each(vfVSDelList,function(vs){
                        vs.destroy();
                    });
                }
				valueFormula.destroy();
			}
			if(!roleUsed){
        		var roleArray = [{'role':role,'refId':[bmValFormula.get("id")]}];
			   	async.eachSeries(roleArray, self.deleteRole.bind(self), function(err){
                	if(callback) {
                    	callback();
                    }
                });
        	}else {
        		if(callback) {
                	callback();
                }
        	}
		});
        /*DataManager.getDataManager().getReferencesToModel2(valueFormula,function(result){
			var destroyVF = true;
			for(var i=0;i<result.length;i++){
			   if(result[i].type === "vdml_BusinessModelValueFormula" && result[i].id !== bmValFormula.get('id')) {
		           destroyVF = false;
		           break;
				}
			}
			bmValFormula.destroy();
            
            if(destroyVF) {
				valueFormula.destroy();
				var roleArray = [{'role':role,'refId':[bmValFormula.get("id")]}];
			   	async.eachSeries(roleArray, self.deleteRole.bind(self), function(err){
                	if(callback) {
                    	callback();
                    }
                });
            }
        }); */
    };    
    
    removeExistingRole(participant,role,assignment,assignmentsColl,bmParticipant){
        var self = this;
        assignmentsColl[participant.get("id")].remove(assignment);
      //  assignment.destroy();
        self.get('bmRole').remove(role);
        var ref = [];
        if(bmParticipant){
        	ref.push(bmParticipant.get('id'));
        }
        ref.push(assignment.get("id"));
        var roleArray = [{'role':role,'refId':ref,'assign':assignment}];
        async.eachSeries(roleArray, self.deleteRole.bind(self), function(err){  
        });     
    }
    
    removeExistingBusiness(oldOrgUnit,assignmentsColl,participantNetwork,refarray){
    	var self = this;
    	DataManager.getDataManager().getReferencesToModel2(oldOrgUnit,function(result){
    			var colId =participantNetwork.get("collaboration").get("id");
    			var nestedParentId = oldOrgUnit.getNestedParent().get("id");
    			var len = result.length;
				while (len--) {
                    if (result[len].id === nestedParentId || result[len].id === colId || result[len].id === self.get("id") || result[len].predicate === "vdml_EcoMapDiagram-participant"){
    					result.splice(len,1);
    				}
    				else {
	    				for(var j=0;j<refarray.length;j++){
	    					if(result[len].id === refarray[j]){
	    						result.splice(len,1);
	    						break;
	    					}
	    				}
    				}
    			}
    			var assignments = assignmentsColl[oldOrgUnit.get("id")];
    			var roleArray = [];
		        if(assignments && assignments.models){
		            for(var y=0;y<assignments.models.length;y++){
		                var busrole = assignments.models[y].get("assignedRole");
		                roleArray.push({'role':busrole,'refId':[assignments.models[y].get("id")],'assign':assignments.models[y]});
		            }
		        }
    			if(result.length==0) {
			        oldOrgUnit.destroy();
    			}
    			async.eachSeries(roleArray, self.deleteRole.bind(self), function(err){
	            });
    			
    	});     
    };
    
    createDefaultBusinessRole(orgUnit,roleName,roleDesc,partNetwork){
    	var pnColl = partNetwork.get('collaboration');
    	var role = pnColl.createDefaultRole(roleName);
        role.set('description',roleDesc);
        this.get('bmRole').add(role);
        var assignment = pnColl.assignRoleToParticipant(role,orgUnit); 
		return assignment;
    }

    addNewRoleToBusiness(orgUnit,roleName,roleDesc,partNetwork,alt,callback){
        //create a new role in business orgunit and and link it to bmRole
        var self = this;
        var pnColl = partNetwork.get('collaboration');
        var vdml = self.getNestedParent();
        var namePath = vdml.get('name') + '>'+ pnColl.get('name') + '>'+roleName;
        var roleId;
        vdml.getDocumentWithNamePath(namePath, "vdml_Role", alt, false, vdml, function (result) {
        	if (result && result[0] && alt && alt.id) {
                roleId = alt.id + window.utils.getSuffix(result[0].doc);
            }
            var role = pnColl.createDefaultRole(roleName,roleId);
	        role.set('description',roleDesc);
	        self.get('bmRole').add(role);
	        var assignment = pnColl.assignRoleToParticipant(role,orgUnit); 
			callback(assignment);
		});
    };
    
    addExistingRoleToBusiness(orgUnit,role,partNetwork){
        //adding a already existing typeahead role in business orgunit and and link it to bmRole
        var self = this;
        var pnColl = partNetwork.get('collaboration');
        var bmModel = this.get('bmRole').findWhere({id: role.get("id")});
        if(!bmModel){   
            this.get('bmRole').add(role);
        }
        var assignment;
        if(role.get('roleAssignment').length == 0){
        	assignment = pnColl.assignRoleToParticipant(role,orgUnit);
        }
        else {
        	assignment = role.get('roleAssignment').at(0);
        	if(assignment.get('participant') === orgUnit){
        		return assignment;
        	}
        	else {
        		role.getBusinessModelsInvolved(function(businessModels) {
        			businessModels.each(function(bm){
        				if(self !== bm){
        					bm.get('bmRole').remove(role);
        				}
        			});
        			assignment.destroy();
        		});
        		return pnColl.assignRoleToParticipant(role,orgUnit);
        	}
        }
    };
    
    createDefaultBusiness(){
        var vdml = this.get('businessModelOwner');
        var defaultBusiness = vdml.get('defaultBusiness');
        var orgId = DataManager.getDataManager().guidGeneratorByOwner(vdml);
        if(!defaultBusiness){
            defaultBusiness = new OrgUnit({id:orgId,name:vdml.getUniqueProperty('name',"My Business",'collaboration'),collaborationOwner:vdml});
        }
        //vdml.set('defaultBusiness',defaultBusiness);
        return defaultBusiness;
    };  
    
    addBMActivities(name,desc,role,alt,callback){
    	var self = this;
    	var vdml = self.getNestedParent();
        var actOwner = role.get('collaborationRoleOwner');
        var namePath = vdml.get('name') + '>'+ actOwner.get('name') + '>'+name;
        var actId = DataManager.getDataManager().guidGeneratorByOwner(actOwner);
        vdml.getDocumentWithNamePath(namePath, "vdml_Activity", alt, false, vdml, function (result) {
        	if (result && result[0]) {
                actId = alt.id + window.utils.getSuffix(result[0].doc);
            }
            var activityName = actOwner.getUniqueProperty('name',name,'activity');
            //var test = new Backbone.Collection();
	        var activity = new Activity({id:actId,name:activityName,description:desc,activityOwner:actOwner});
	        activity.set('performingRole',role);
	        var bmActId = DataManager.getDataManager().guidGeneratorByOwner(self);
	        var bmActivity = new BusinessModelActivity({
	                    id:bmActId,
	                    'name':activityName,
	                    'description':desc,
	                    bmActivityOwner:self});
	        bmActivity.set('activity',activity);
	        callback(activity);
        });
    }
    
    addExistingActivities(activity,role){
		var self = this;
		if(role){
			activity.set('performingRole', role);
			activity.set('activityOwner', role.get('collaborationRoleOwner'));
		}
        var activities = self.get("bmActivity");
        var bmActivity = activities.findWhere({activity:activity});
        if(!bmActivity){
	        var bmActId = DataManager.getDataManager().guidGeneratorByOwner(this);
	        bmActivity = new BusinessModelActivity({
	                    id:bmActId,
	                    'name':activity.get('name'),
	                    'description':activity.get('description'),
	                    bmActivityOwner:self});
        }
        bmActivity.set('activity',activity);
        return activity;
    };   
    addActivityRoleAndValidate(activity){
        var performingRole = activity.get('performingRole');
        if(performingRole){
            this.get('bmRole').add(performingRole);
            this.validateRole(performingRole);
        }
    };
    addValuePropositionProviderAndValidate(provider){
        if(!provider){
            return;
        }
        var bmRoles = this.get('bmRole');
        var found = bmRoles.get(provider.get('id'));
        if(!found){
            bmRoles.add(provider);
            this.validateRole(provider);
        }
    };
    
    canCollaborationBeParticipantNetwork(collaboration,mainScenario){
        var self = this;
        if(self.isCustomer(collaboration)){
            return false;
        }else if(self.isNetworkPartner(collaboration)){
            return false;
        }else if(self.isBusiness(collaboration)){
            return false;
        }
        else if(self.isContextCollaboration(collaboration,mainScenario)){
            return false;
        }
        return true;
    }
    isContextCollaboration(collaboration,mainScenario){
        var self = this;    
        var delContext = mainScenario.get("delegationContext");
        for(var j=0;j<delContext.length;j++){
            if(delContext.models[j].get("contextCollaboration") === collaboration) {
                return true;
            }
        }
        return false;
    }
    isCustomer(collaboration){
        var self = this;    
        var customers = self.get('bmCustomer');
        for(var i=0;i<customers.length;i++){
            var participant = customers.at(i).get('customer');
            if(participant === collaboration){
                return true;
            }
        }
        return false;
    }
    isNetworkPartner(collaboration){
        var self = this;    
        var customers = self.get('bmNetworkPartner');
        for(var i=0;i<customers.length;i++){
            var participant = customers.at(i).get('networkPartner');
            if(participant === collaboration){
                return true;
            }
        }
        return false;
    }   
    isBusiness(collaboration){
        var self = this;
        if(self.get('business') === collaboration){
            return true;
        }
        return false;       
    }   
       getParticipants(){
            var self = this;
			var ret = [];
			var delPartObjects = [];
			self.get('bmCustomer').each(function (element) {
				if (element.get('customer')) {
					ret.push(element.get('customer'));
				}
				if (!element.get('customer')) {
					delPartObjects.push(element);
				}
            });
            self.get('bmNetworkPartner').each(function(element){
				if (element.get('networkPartner')) {
					ret.push(element.get('networkPartner'));
				}
				if (!element.get('networkPartner')) {
					delPartObjects.push(element);
				}
            });
            if(self.get('business')){
            	ret.push(self.get('business'));
			}
			var delPartObjectsLength = delPartObjects.length;
			while (delPartObjectsLength--) {
				delPartObjects[delPartObjectsLength].destroy();
			}
            return _.uniq(ret);
        };  
     getParticipantRoles(participant,assignmentsColl){
            var self = this;
			var participantModel;
			var delPartObjects = [];
            if(typeof participant  === 'string'){
                self.get('bmCustomer').each(function(bmCustomer){
					if (bmCustomer.get('customer') && bmCustomer.get('customer').get('id') === participant){
                        participantModel = bmCustomer.get('customer');
					}
					if (!bmCustomer.get('customer')) {
						delPartObjects.push(bmCustomer);
					}
                })
                
                if(!participantModel){
                    self.get('bmNetworkPartner').each(function(bmNetworkPartner){
						if (bmNetworkPartner.get('networkPartner') && bmNetworkPartner.get('networkPartner').get('id') === participant){
                            participantModel = bmNetworkPartner.get('networkPartner');
						}
						if (!bmNetworkPartner.get('networkPartner')) {
							delPartObjects.push(bmNetworkPartner);
						}
                    })
                }
                if(!participantModel){
					if (self.get('business') && self.get('business').get('id') === participant){
                        participantModel =  self.get('business');            
                    }
                }
            }else{
                participantModel = participant;
            }
            var ret = [];
            if(participantModel){
                var bmRoles = self.get('bmRole');
                var assignments = assignmentsColl[participantModel.get('id')];
                assignments.each(function(assignment){
                    if(assignment.get('assignedRole') && bmRoles.get(assignment.get('assignedRole').get('id'))){
                        ret.push(assignment.get('assignedRole'));
                    }
                });
			}
			var delPartObjectsLength = delPartObjects.length;
			while (delPartObjectsLength--) {
				delPartObjects[delPartObjectsLength].destroy();
			}
            return ret;
        };
     getRoleParticipant(role){
            var self = this;
            var ret;
            if(!role){
                return;
            }       
            var assignments = role.get('roleAssignment');
            assignments.each(function(assignment){
                var participant = assignment.get('participant');
                if(participant){
                    if(self.isCustomer(participant) || self.isNetworkPartner(participant) || self.isBusiness(participant)){
                        ret = participant;
                    }
                }
            });
            return ret;
        };
     createBMValueProposition(name,description,valueProposition){
        	var self = this;
        	var bmvpId = DataManager.getDataManager().guidGeneratorByOwner(self);
            var bmValueProposition = new BusinessModelValueProposition({id:bmvpId,'name':name,description:description,bmValuePropositionOwner:self});
            bmValueProposition.set('valueProposition',valueProposition);
            return bmValueProposition;
        };
        
     addBMValueProposition(valueProposition,vpName,providerRole, receiverRole,vpDescription,alt,callback){
        	var self = this;
            var name;
            var description;
            var bmvp;
            if(!providerRole || !vpName || vpName.length == 0){
                return null;
            }            
            if(valueProposition == null){
            	name = providerRole.getUniqueProperty('name',vpName,'providedProposition');
            	if(vpDescription){
            		description = vpDescription;
            	}else{
            		description=name;
            	}
            	var vdml = self.getNestedParent();
            	var namePath = vdml.get('name') + '>'+ providerRole.getParent().get('name') + '>'+ providerRole.get('name') + '>'+name;
		        var vpId = DataManager.getDataManager().guidGeneratorByOwner(providerRole);
		        vdml.getDocumentWithNamePath(namePath, "vdml_ValueProposition", alt, false, vdml, function (result) {
		        	if (result && result[0] && alt && alt.id) {
		                vpId = alt.id + window.utils.getSuffix(result[0].doc);
		            }
	                valueProposition = new ValueProposition({id:vpId,'name':name,description:description,provider:providerRole});
	                valueProposition.set('provider',providerRole);
	                if(receiverRole){
	                	valueProposition.set('recipient',receiverRole);
	            	}else{
	            		valueProposition.set('recipient',"");
	            	}
	                valueProposition.set('isValueFormula',false);
	                bmvp = self.createBMValueProposition(name,description,valueProposition);
	                callback(bmvp);
                });
            }else {
            	name = valueProposition.get('name');
                description = valueProposition.get('description');
            	bmvp = self.createBMValueProposition(name,description,valueProposition);
	            callback(bmvp);
            }
        };
        
     createBMValueFormula(name,description,valueProposition){
        	var self = this;
        	var bmvfId = DataManager.getDataManager().guidGeneratorByOwner(self);
            var bmVaueFormula = new BusinessModelValueFormula({id:bmvfId,'name':name,description:description,bmValueFormulaOwner:self});
            bmVaueFormula.set('valueFormula',valueProposition);
            return bmVaueFormula;
        };
        
        addBMValueFormula(valueProposition,vfName,businessRole,vfDescription,alt,callback){
        	var self = this;
            var name;
            var description;
            var bmvf;
            if(!businessRole){
                return null;
            } 
            if(valueProposition == null){
            	name = businessRole.getUniqueProperty('name',vfName,'providedProposition');
                if(vfDescription){
            		description = vfDescription;
            	}else{
            		description=name;
            	}
            	var vdml = self.getNestedParent();
	        	var namePath = vdml.get('name') + '>'+ businessRole.getParent().get('name') + '>'+ businessRole.get('name') + '>'+name;
		        var vpId = DataManager.getDataManager().guidGeneratorByOwner(businessRole);
		        vdml.getDocumentWithNamePath(namePath, "vdml_ValueProposition", alt, false, vdml, function (result) {
		        	if (result && result[0] && alt && alt.id) {
		                vpId = alt.id + window.utils.getSuffix(result[0].doc);
		            }
		            valueProposition = new ValueProposition({id:vpId,'name':name,description:description,provider:businessRole});
	                valueProposition.set('provider',businessRole);
	                valueProposition.set('isValueFormula',true);
	                bmvf = self.createBMValueFormula(name,name,valueProposition);
	                callback(bmvf);
		        }); 
            }else {
            	name = valueProposition.get('name');
                description = valueProposition.get('description');
            	bmvf = self.createBMValueFormula(name,name,valueProposition);
            	callback(bmvf);
            }
        };  
        
        getOrCreateBIStore(businessItem,callback){
            var self = this;
            var bmResourceStores = this.get('bmResourceStore');
            for(var i=0;i<bmResourceStores.length;i++){
                if(bmResourceStores.at(i).get('resource') === businessItem){
                    return bmResourceStores.at(i);
                }
            }
            var store = this.get('business').createStoreforResource(businessItem,Pool);         
            this.get('bmResourceStore').add(store);
            DataManager.getDataManager().getMainScenario(self.getNestedParent(),DataManager.getDataManager().get('viewAlternative'),function(mainScenario){
            	mainScenario.get('contextStore').add(store);
                if(callback){
                    callback(store);
                }
            });
            return store;
        };
        businessCanvasDeletions(items){
        	var self = this;
        	var canvasList = this.getNestedParent().get('canvas');
        	var linkedCanvas = [];
        	canvasList.each(function(bmCanvas){
        		if(bmCanvas.get('businessModel') === self){
        			linkedCanvas.push(bmCanvas);
        		}
        	});	
        	_.each(linkedCanvas,function(canvas){
        		var dimensionBlocks = canvas.get('bmCanvasBlock');
        		dimensionBlocks.each(function(dimension){
        			var blockItems = dimension.get('blockItem');
        			if(blockItems && blockItems.length > 0){
        				blockItems.each(function(blockItem){
	        				if(_.lastIndexOf(items, blockItem.get('canvasItem')) !== -1){
	        					blockItem.set('canvasItem',null);
	        				}
        				});
        			}
        		});	
        	});	
        };
        createCapabilityMethodForActivityToDelegate(activity){
            var business = this.get('business');
            var capMethod = business.createCapabilityMethod(activity.get('name'));
            if (activity.get('capabilityRequirement') != null) {
                capMethod.set('capability', activity.get('capabilityRequirement'));
                capMethod.set('name', activity.get('capabilityRequirement').get('name'));
                capMethod.set('description', activity.get('capabilityRequirement').get('description'));
            }
            return capMethod;
            
        };  
        addStoreToScenarioContexts(store){
			if(Backbone.skipPersistance){
				return;
			}
            var vdm = this.getNestedParent();
            var scenarios = vdm.get('scenario');
            scenarios.each(function(scenario){
                scenario.get('contextStore').add(store);
                var isCommon = scenario.get('isCommon');
                if(!isCommon || isCommon === false){
                    scenario.get('contextStore').add(store);    
                }
                
            });
        };
        
        comptenceReUsedWithActivity(competence,callback,checkActivity){
            var self = this;
            function reusedWithActivity(businessModel){
            	var usedInBM = false;
	          	var bmCompetences = businessModel.get('bmCompetence');
	            for(var j=0;j<bmCompetences.length;j++){
	        		if(bmCompetences.at(j).get('competence') === competence){
	        			var bmActivities = businessModel.get('bmActivity');
	        			bmActivities.each(function(bmActivity){
	        				var activity = bmActivity.get('activity');
	        				if(activity !== checkActivity){
	        					return;
	        				}
	        				var ports = activity.get('containedPort');
	        				ports.each(function(actPort){
	        					if(actPort.get('type') === 'vdml_InputPort'){
	        						var flow = actPort.get('input');
	        						if(flow && flow.get('deliverable') === competence){
	   			     					usedInBM = true;		
	        						}
	        					}
	        				});
	        			});
	        			if(usedInBM === true){
	        				return usedInBM;
	        			}
	        		}
	        	}
	        	return usedInBM;
            }
            var used = false;
            self.getAllInstances(function(businessModels){
                for(var i=0;i<businessModels.length;i++){
                	if(businessModels[i] === self){
                		continue;
                	}
                	used = reusedWithActivity(businessModels[i]);
					if(used){
						break;
					}				
	            }
	            if(callback){
                	callback(used);
           		 }
        	},competence.getNestedParent());
        }
        
       	comptenceReUsed(competence,callback,skipActivity){
            var self = this;
            var used = false;
          	var bmCompetences = self.get('bmCompetence');
            for(var j=0;j<bmCompetences.length;j++){
        		if(bmCompetences.at(j).get('competence') === competence){
        			var bmActivities = self.get('bmActivity');
        			bmActivities.each(function(bmActivity){
        				var activity = bmActivity.get('activity');
        				if(activity === skipActivity){
        					return;
        				}
        				var ports = activity?activity.get('containedPort'):null;
        				if(ports) {
	        				ports.each(function(actPort){
	        					if(actPort.get('type') === 'vdml_InputPort'){
	        						var flow = actPort.get('input');
	        						if(flow && flow.get('deliverable') === competence){
	   			     					used = true;		
	        						}
	        					}
	        				});
        				}
        			});
        			if(used === true){
        				break;
        			}
        		}
        	}
        	if(!used) {
	            self.getAllInstances(function(businessModels){
	                for(var i=0;i<businessModels.length;i++){
	                	if(businessModels[i] === self){
	                		continue;
	                	}
	                	var otherbmCompetences = businessModels[i].get('bmCompetence');
	                	for(var j=0;j<otherbmCompetences.length;j++){
	                		if(otherbmCompetences.at(j).get('competence') === competence){
	                			used = true;
	                			break;
	                		}
	                	}	
		            }
		            if(callback){
	                	callback(used);
	           		 }
	            },competence.getNestedParent());
            }else{
            	callback(used);
            }
        };
        
        fillPlanValues(aggregatedColl){
            var plan = DataManager.getDataManager().get('currentPlan');
	    	var planCriterionSet = plan.get('planCriterionSet');
	    	if(planCriterionSet) {
	    		var planComponents = planCriterionSet.get('component');
	    			planComponents.each(function(component){
	    			aggregatedColl.push(component);
	    		});
	    	}
        };        
        
        activityReUsed(activity,callback){
            var self = this;
            self.getAllInstances(function(businessModels){
                self.checkActivityReuse(businessModels,activity,callback);
            },activity.getNestedParent());
        };
        
        checkCrossCollaborationUse(businessModels,activity,callback){
            var self = this;
            var crossActivities = [];
            var activityPorts = activity.get('containedPort');
            activityPorts.each(function(port){
                var container;
                if(port.get('type') === 'vdml_InputPort'){
               		container = port.get('input')? port.get('input').get('provider').get('containerPortOwner') : null;	
                }else{
                    container = port.get('output')?port.get('output').get('receiver').get('containedPortOwner') : null;
                }
                if(container instanceof Store){
                    var storePorts = container.get('containedPort');
                    storePorts.each(function(storePort){
                        if(port.get('type') === 'vdml_InputPort'){
                            crossActivities.push(storePort.get('input').get('provider').get('containerPortOwner'));
                        }else{
                            crossActivities.push(storePort.get('output').get('receiver').get('containerPortOwner'))
                        }
                    });
                }
            });
            var activityCount = crossActivities.length;
            function handlerCrossActivity(){
                activityCount--;
                if(activityCount>=0){
                    var handleActivityReuseCallback= function(used){
                        if(used){
                            callback(used);
                        }else{
                            handlerCrossActivity();
                        }
                    }
                    self.checkActivityReuse(businessModels,crossActivities[activityCount],handleActivityReuseCallback);
                }else{
                    callback(false);
                }
            }
            handlerCrossActivity();
        };
        
        checkActivityReuse(businessModels,activity,callback){
            var self = this;
            if(!activity){
            	callback(false);
            	return;
            }
            var collaboration = activity.get('activityOwner')?activity.get('activityOwner'):activity.previousAttributes().activityOwner;
            
            self.checkCollaborationReuse(businessModels,collaboration,function(used){
                if(used){
                	var activityUsed = false;
                	for(var i= 0;i<businessModels.length;i++){
                		var bmActs = businessModels[i].get('bmActivity');
		                if(bmActs.findWhere({'activity':activity})){
		                	activityUsed = true;
		                    break;
		                }           
		            }
                    callback(activityUsed);
                }else{
                    self.checkCrossCollaborationUse(businessModels,activity,function(used){
                        if(used){
                            callback(used);
                        }else{
                            var delegationContexts = collaboration.get('delegationContext');
                            function handleDCUsedCallback(used){
                                if(used){
                                    callback(used);
                                }else{
                                    callback(false);
                                }
                            }
                            self.checkDelegationContextsReuse(businessModels,delegationContexts,handleDCUsedCallback);
                        }
                    });
                }               
            });         
        }
        checkCollaborationReuse(businessModels,collaboration,callback){
        	if(collaboration.get('delegationContext').length > 1){
        		callback(true);
        		return;
        	}
            for(var i= 0;i<businessModels.length;i++){
                if(businessModels[i].isUsed(collaboration)){
                    if(callback){
                        callback(true);
                    }
                    return;
                }           
            }
            callback(false);
        }
        checkDelegationContextsReuse(businessModels,delegationContexts,callback){
            var self = this;
            var count = delegationContexts.length;
            function checkDelegationContextReUse(){
                count--;
                if(count>=0){
                    var dc = delegationContexts.at(count);
                    var delegatedActivity = dc.get('delegatedActivity');
                    self.checkActivityReuse(businessModels, delegatedActivity, callback);
                    
                }else{
                    if(callback){
                        callback(false) 
                    }
                }
            }
            checkDelegationContextReUse();
        }
        
        isParticipantNetwork(collaboration){
            var self = this;
            var pns = self.get('participantNetwork');
            var used = false;
            pns.each(function(pn){
                if(pn.get('collaboration') === collaboration){
                    used = true;
                }
            });
            return used;
        }
        isUsed(collaboration){
            var self = this;
            if(self.isCustomer(collaboration)){
                return true;
            }else if(self.isNetworkPartner(collaboration)){
                return true;
            }else if(self.isBusiness(collaboration)){
                return true;
            }else if(self.isParticipantNetwork(collaboration)){
                return true;
            };
            return false;
        }
        //get all instances other than current bm,from alternative/package(optional)
        getAllInstances(callback,alternative){
            var self = this;
            var businessModels = [];
            DataManager.getDataManager().getAllDocumentsCollectionOfType(alternative ? alternative :self.getNestedParent(),'vdml_BusinessModel',function(refs){
                var count = refs.length;
                function getBMInstance(){
                    count--;
                    if(count >=0  && refs.at(count).get('parent')){
                        var ref = refs.at(count);
                        var parentId = ref.get('parent');
                        var repId = DataManager.getDataManager().getRepositoryId(parentId);
                        var vdmStore = DataManager.getDataManager().getVDMStore(repId);
                        DataManager.getDataManager().fetchDocumentFromPackage(parentId,"appbo/vdml/ValueDeliveryModel",ref.get('version'),ref.get('id'),"appbo/vdml/BusinessModel",vdmStore,{
                            success:function(model){
                                //router.DataManager.getDataManager().set('currentPlan',model);
                                if(model.get('id') !== self.get('id')){
                                    businessModels.push(model);
                                }
                                getBMInstance();
                            },
                            error: function (error) {
                                if (error) {
                                    console.log(error);
                                }
                                getBMInstance();
                            }
                        });                     
                    }else{
                    	if(count >=0){
                    		getBMInstance();
                    	}else {
                    		callback(businessModels);
                    	}
                    }
                }
                getBMInstance();
            },null,null,true);         
        }       
        /*BusinessModelMixin.prototype.getModelsIntroducedWithOutReuse(callback){
        	var self = this;
        	var ret = [];
        	var bmCollabs = [];
        	if(self.get('business')){
        		bmCollabs.push(self.get('business'));
        	}
        	var pns = self.get('participantNetwork');
			pns.each(function(pn){
				bmCollabs.push(pn.get('collaboration'));
			});
			var bmCustomers = self.get('bmCustomer');
			bmCustomers.each(function(bmCustomer){
				bmCollabs.push(bmCustomer.get('customer'));
			});
			var bmNPs = self.get('bmNetworkPartner');
			bmNPs.each(function(bmNP){
				bmCollabs.push(bmNP.get('networkPartner'));
			});
			self.getAllInstances(function(businessModels){
				_.each(bmCollabs,function(bmCollab){
					var used = false;
					for(var i=0;i<businessModels.length;i++){
						var bm = businessModels[i];
						if(self !== bm){
							if(bm.isUsed(bmCollab)){
								used = true;
								break;
							}
						}
					}
					_.each(businessModels,function(bm){
						if(self !== bm){
							if(bm.isUsed(bmCollab)){
								used = true;
							}
						}
					});
					if(!used){
						ret.push(bmCollab);
					}
				});
				if(callback){
					ret = _.uniq(ret);
					callback(ret);
				}
			});
        };*/
        
       /* BusinessModelMixin.prototype.cleanBMCompetences(parentModels,callback){
        	var self = this;
        	var competences = [];
        	var bmCompetences = self.get('bmCompetence');
        	bmCompetences.each(function(competence){
        		competences.push(competence);
        	});
        	self.cleanCompetenceSet(competences,parentModels,function(){
				DataManager.getDataManager().saveData({success:callback,error:function(){
        			console.log('Error saving data');
        		}});        		
        	});
        };
        
        BusinessModelMixin.prototype.cleanCompetenceSet(competences,parentModels,callback){
        	var self = this;
        	async.eachSeries(competences,function(competence,competenceCallback){
        		var altId = DataManager.getDataManager().getRepositoryId(self.get('id'));
	        	DataManager.getDataManager().getReferencesToModelFiltedByParents(competence.get('id'),parentModels,function(references){
	        	 	if(references.length === 0){
	        	 		competence.destroy();
	        	 	}
	        	 	competenceCallback();
	        	});
        	},function(err){
        		if(callback){
        			callback();	
        		}
        	});        	
        }    */     
        
        cleanBMActivities(parentModels,callback){
        	var self = this;
        	/*var bmVFs = this.get('bmRole');
	        bmVFs.each(function(role){
	        	self.get('bmRole').remove(role);
	        });
	        var bmPNs = this.get('participantNetwork');
	        bmPNs.each(function(pn){
	        	pn.destroy();
	        });*/
        	/*var activities = [];
        	var bmActivities = self.get('bmActivity');
        	bmActivities.each(function(activity){
        		activities.push(activity);
        		self.get('bmActivity').remove(activity);
        	});*/
        	if(callback){
       			callback();	
        	}
        	/*self.cleanActivitiesSet(activities,parentModels,function(){
				DataManager.getDataManager().saveData({success:callback,error:function(){
        			console.log('Error saving data');
        		}});        		
        	});*/
        };
        
        /*BusinessModelMixin.prototype.cleanActivitiesSet(activities,parentModels,callback){
        	var self = this;
        	async.eachSeries(activities,function(activity,activityCallback){
        		var altId = DataManager.getDataManager().getRepositoryId(self.get('id'));
	        	DataManager.getDataManager().getReferencesToModelFiltedByParents(activity.get('id'),parentModels,function(references){
	        	 	if(references.length === 0){
	        	 		activity.destroy();
	        	 	}
	        	 	activityCallback();
	        	});
        	},function(err){
        		if(callback){
        			callback();	
        		}
        	});        	
        }*/        
        cleanBMValues(parentModels){
        	var self = this;
        	/*var values = [];
        	var bmValuePropositions = self.get('bmValueProposition');
        	bmValuePropositions.each(function(bmVP){
        		var vp = bmVP.get('valueProposition');
        		values = values.concat(vp.get('component').models);
        	});
        	var bmActivities = self.get('bmActivity');
        	bmActivities.each(function(bmActivity){
        		var actValues = bmActivity.get('activityValue');
        		values = values.concat(actValues.models);
        	});
        	//self.cleanValuesSet(values, parentModels);
        	var len = bmValuePropositions.length;
			while(len--){
				bmValuePropositions.at(len).destroy();
			}*/
			var vdmPackage = self.getNestedParent();
			var bmPN = self.get('participantNetwork');
        	bmPN.each(function(pn){
        		if(pn.get('collaboration') && pn.get('collaboration').getNestedParent() != vdmPackage){
        			pn.removeCollaborationToScenarioContexts(self);
        		}
        	});
        	/*bmValuePropositions.each(function(bmVP){
        		var vp = bmVP.get('valueProposition');
        		if(vp.get('component').length === 0){
					var vpPackage = vp.getNestedParent();
					var vpValueStreams = vpPackage.get('valueStream').models.concat();
					_.each(vpValueStreams,function(valueStream){
						if(valueStream.get('persuedProposition') === vp){
							valueStream.destroy();
						}
					});
        			vp.destroy();
        		}
        	});*/
        	
        	
        }        
        
       /* BusinessModelMixin.prototype.cleanValuesSet(values,parentModels){
        	_.each(values,function(value){
	        	DataManager.getDataManager().getReferencesToModelFiltedByParents(value,parentModels,function(references){
	        		var valueRefs = [];
	        		_.each(references,function(ref){
	        			_.each(values,function(value){
	        				if(ref.id === value.get('id')){
	        					valueRefs.push(ref);
	        				}
	        			});
	        		});
	        		_.each(valueRefs,function(ref){
	        			references = _.without(references,ref);
	        		});
	        	 	if(references.length === 0){
	        	 		value.destroy();
	        	 	}
	        	});
        	})
        }; */       
        hasActivity(activity){
        	var bmActivities = this.get('bmActivity');
        	for(var i=0;i<bmActivities.length;i++){
        		if(bmActivities.at(i).get('activity') === activity){
        			return true;
        		}
        	}
        	return false;
        };
        hasCompetence(competence){
        	var found = false;
        	var bmCompetences = this.get('bmCompetence');
        	bmCompetences.each(function(bmComp){
        		if(bmComp.get('competence') === competence){
        			found = true;
        		}
        	});
        	return found;
        };
        hasCompetences(competences){
        	var self = this;
        	var ret = [];
        	_.each(competences,function(competence){
        		if(self.hasCompetence(competence)){
        			ret.push(competence);
        		}
        	})
        	return ret;
        };
       /* BusinessModelMixin.prototype.hasVPVF(vp){
        	var bmVPVF;
        	var bmVPs = this.get('bmValueProposition');
        	bmVPs.each(function(bmVP){
        		if(bmVP.get('valueProposition') === vp){
        			bmVPVF = bmVP;
        		}
        	});
        	if(!bmVPVF){
	        	var bmVFs = this.get('bmValueFormula');
	        	bmVFs.each(function(bmVF){
	        		if(bmVF.get('valueFormula') === vp){
	        			bmVPVF = bmVF;
	        		}
	        	});        		
        	}
        	return bmVPVF;
        };        
        BusinessModelMixin.prototype.getVPVFs(vps){
        	var self = this;
        	var ret = [];
        	_.each(vps,function(vp){
        		var vpVF = self.hasVPVF(vp);
        		if(vpVF){
        			ret.push(vpVF);
        		}
        	});
        	return ret;
        };*/
        
        competenceUsedInOtherActivities(competence,skipActivity){
        	var self = this;
        	var used = false;
        	var bmActivities = self.get('bmActivity');
        	bmActivities.each(function(bmActivity){
        		if(bmActivity.get('activity') === skipActivity){
        			return;
        		}
        		var activity = bmActivity.get('activity');
        		var actCompetences = activity?activity.getActivityCompetences():[];
        		_.each(actCompetences,function(actComp){
        			if(actComp === competence){
        				used = true;
        			}
        		});
        	});
        	return used;
        };
     	
        handleOnBMActivityDeleted(bmActivity){
			if(Backbone.skipPersistance){
				return;
			}        	
        	var self = this;
        	var activityValues = bmActivity.previousAttributes().activityValue;
        	var activity = bmActivity.get('activity')?bmActivity.get('activity'):bmActivity.previousAttributes().activity;
        	/*if(!activity){
        		var activityKeyContents = bmActivity._relations.activity.keyContents;
				if(activityKeyContents instanceof Backbone.Model){
					activity = activityKeyContents;	
				}else {
					activity = bmActivity._relations.activity.relatedModel.find({id:bmActivity._relations.activity.keyContents});
				}
        	}*/	
        	if(activity){
        		self.businessCanvasDeletions([activity]);
        		var activityCompetences = activity.getActivityCompetences();
        		var bmCompetences = self.get('bmCompetence');
        		var delBMCompetences = [];
    			_.each(activityCompetences,function(actComp){
        			bmCompetences.each(function(bmCompetence){
        				var competence = bmCompetence.get('competence');
        				if(actComp === competence){
        					var usedInOtherActivity = self.competenceUsedInOtherActivities(competence,activity);
        					if(!usedInOtherActivity){
        						delBMCompetences.push(bmCompetence);
        					}
        				}
        			});
        		});
        		_.each(delBMCompetences,function(bmComp){
        			self.businessCanvasDeletions([bmComp.get('competence')]);
        			bmComp.destroy();
        		});
	        	var valueStreams = self.get('bmValueStream');
	        	var removeActivitiesFromValueStream = [];
	        	var unLinkValueStream = [];
	        	var bmActivities = self.get('bmActivity');
	        	valueStreams.each(function(valueStream){
	        		var valueStreamActivities = valueStream.get('valueStreamActivity');
	        		var actExists = valueStreamActivities.findWhere({'id':activity.get('id')});
	        		if(actExists) {
	        			removeActivitiesFromValueStream.push(valueStream);
	        			var unlinkStream = true;
	        			
			            for(var i=0;i<valueStreamActivities.length;i++){
			            	if(bmActivities.findWhere({'activity':valueStreamActivities.at(i)})) {
			            		unlinkStream = false;
			            		break;
			            	}
			            }
			            if(unlinkStream){
			            	unLinkValueStream.push(valueStream);
			            }
	        		}
	        	}); 
	        	
	        	for(var i=0;i<unLinkValueStream.length;i++){
	        		valueStreams.remove(unLinkValueStream[i]);
	        	}


				function hanldeActivityComptence(activityCompetenceHandled){
					async.eachSeries(activityCompetences,function(activityComptence,callback){
						if(!activityComptence){
							callback();
							return;
						}
						if(activityComptence.get('type') === 'vdml_BusinessItem'){
							activity.deleteResourceInput(self,activityComptence,null,function(){
								self.comptenceReUsed(activityComptence,function(reused){
               						if(!reused){
               							activityComptence.destroy();
               						}
               					},activity);	
								callback();
							});
						}else{
							activity.deleteCapabilityUse(self,activityComptence,null,function(){
									self.comptenceReUsed(activityComptence,function(reused){
	               						if(!reused){
	               							activityComptence.destroy();
	               						} 
	                				},activity);
			                		function checkForEmptyCapabilityMethods(deleteComptencyCallback){
										var deleContexts = activity.get('delegationContext').models.concat();
										if(deleContexts.length >0){
                                            async.eachSeries(deleContexts, function (delContext, contextHandled) {
											//_.each(deleContexts,function(delContext){
												var cm = delContext.get('contextCollaboration');
												if(!cm){
													return;
												}
												var cmDelContexts = cm.get('delegationContext');
												var delToOnlyActivity = true;
												cmDelContexts.each(function(cmDelContext){
													if(cmDelContext.get('delegatedActivity') !== activity){
														delToOnlyActivity = false;
													}
												});
												if(delToOnlyActivity && (cm.get('type')  === 'vdml_CapabilityMethod') && (cm.get('activity').length === 0)){
													self.activityReUsed(activity,function(reUsed){
														if(!reUsed){
															var delContexts = cmDelContexts.models.concat();
															_.each(delContexts,function(cmDelContext){
																cmDelContext.destroy();
															});
															cm.destroy();
														}
														/*if(deleteComptencyCallback){
															deleteComptencyCallback();
                                                            return false;
														}*/
                                                        contextHandled();
													});
												}else{
													/*if(deleteComptencyCallback){
														deleteComptencyCallback();
                                                        return false;
													}*/
                                                    contextHandled();
												}
											},function(){
                                                if(deleteComptencyCallback){
                                                    deleteComptencyCallback();
                                                }
                                            });
										}else{
											if(deleteComptencyCallback){
												deleteComptencyCallback();
											}
										}
			                		}
			                		checkForEmptyCapabilityMethods(callback);
							});								
						}			
					},function(){
						var roleArray = [{'role':activity.previousAttributes().performingRole,'refId':[activity.get("id")]}];
						async.eachSeries(roleArray, self.deleteRole.bind(self), function (err) {
						    activityCompetenceHandled();
				         });
					});
				}
				hanldeActivityComptence(function () {
				    removeActivitiesFromValueStream = _.uniq(removeActivitiesFromValueStream);
				    async.each(removeActivitiesFromValueStream, function (vStream, callback) {
                        DataManager.getDataManager().getReverseAssociationInstances(vStream, 'vdml_BusinessModel-bmValueStream', 'vdml_BusinessModel', "vbc:" + "cmof_EObject-name",
                            function (businessModels) {
                                var removeAct = true;
                                businessModels.each(function (bm) {
                                    var bmActs = bm.get('bmActivity');
                                    if (bm !== self && bmActs.findWhere({ 'activity': activity })) {
                                        removeAct = false;
                                    }
                                });
                                if (removeAct) {
                                    vStream.get('valueStreamActivity').remove(activity);
                                }
                                if (activityValues) {
                                    for (var k = 0; k < activityValues.length; k++) {
                                        vStream.get('activityValue').remove(activityValues.at(k));
                                    }
                                }
                                callback();
                            },
                        true);
				    }, function () {
				    });
				});
        	}
        };
        handleOnBMVPDeleted(bmVP){
			if(Backbone.skipPersistance){
				return;
			}        	
        	var self = this;
        	var vp = bmVP.get('valueProposition')?bmVP.get('valueProposition'):bmVP.previousAttributes().valueProposition;
        	if(!vp && bmVP._relations.valueProposition){
        		var valuePropositionKeyContents = bmVP._relations.valueProposition.keyContents;
				if(valuePropositionKeyContents instanceof Backbone.Model){
					vp = valuePropositionKeyContents;	
				}else {
					vp = bmVP._relations.valueProposition.relatedModel.find({id:bmVP._relations.valueProposition.keyContents});
				}
        	}
        	if(vp){
	        	var valueStreams = self.get('bmValueStream');
	        	var bmActivities = self.get('bmActivity');
	        	var vpActivityList = [];
	        	var delBmAct = [];
				var delValueStream = [];
	        	valueStreams.each(function(valueStream){
	        		if(valueStream && valueStream.get('persuedProposition') === vp){
	        			var valueStreamActivities = valueStream.get('valueStreamActivity');
	        			valueStreamActivities.each(function(activity){
  							vpActivityList.push(activity);
	        			});
						if(valueStreamActivities.length == 0){
							delValueStream.push(valueStream);
						}
	        		}
	        	});
	        	for(var i=0;i<vpActivityList.length;i++){
	        		var foundAct = false;
	    	    	valueStreams.each(function(valueStream){
			    		if(valueStream && valueStream.get('persuedProposition') && valueStream.get('persuedProposition') !== vp && valueStream.get('valueStreamActivity').findWhere({'id':vpActivityList[i].id})){
							foundAct = true;							
						}else if(valueStream){
							valueStream.get('valueStreamActivity').remove(vpActivityList[i]);
						}
					});
					if(!foundAct){
						var bmActExists = bmActivities.findWhere({'activity':vpActivityList[i]});
  						if(bmActExists){
  							delBmAct.push(bmActExists);
  						}
					}
				}
        		
				var delBmActLen = delBmAct.length;
				while(delBmActLen--){
					delBmAct[delBmActLen].destroy();
				}
				var delBmValueStreamLen = delValueStream.length;
				while(delBmValueStreamLen--){
					delValueStream[delBmValueStreamLen].destroy();
				}
        		self.deleteValueProposition(bmVP,vp);
        	}
        };
        handleOnBMVFDeleted(bmVF){
			if(Backbone.skipPersistance){
				return;
			}        	
        	var self = this;
        	var vf = bmVF.get('valueFormula')?bmVF.get('valueFormula'):bmVF.previousAttributes().valueFormula;
        	if(!vf){
        		var valueFormulaKeyContents = bmVF._relations.valueFormula ? bmVF._relations.valueFormula.keyContents : null;
				if(valueFormulaKeyContents && valueFormulaKeyContents instanceof Backbone.Model){
					vf = valueFormulaKeyContents;	
				}else if(bmVF._relations.valueFormula){
					vf = bmVF._relations.valueFormula.relatedModel.find({id:bmVF._relations.valueFormula.keyContents});
				}
        	}
        	if(vf){
        		function checkBmActivityReused(valueStreams,persuedProposition,activity){
        			var reused = false;
        			for(var i=0;i<valueStreams.length;i++){
        				if(valueStreams.at(i).get('persuedProposition') != persuedProposition) {
        					var valueStreamActivities = valueStreams.at(i).get('valueStreamActivity')
        					if(valueStreamActivities.findWhere({'id': activity.id})){
        						reused = true;
        						break;
        					}
        				}
        			}
        			return reused;
        		}
	        	var valueStreams = this.get('bmValueStream');
	        	var bmActivities = self.get('bmActivity');
	        	var removeActivityFromStream = [];
				var delValueStream = [];
	        	valueStreams.each(function(valueStream){
	        		if(valueStream && valueStream.get('persuedProposition') === vf){
	        			var bmActivityList = [];
	        			var valueStreamActivities = valueStream.get('valueStreamActivity');
						if(valueStreamActivities.length == 0){
							delValueStream.push(valueStream);
						}
	        			valueStreamActivities.each(function(activity){
	        				var bmActExists = bmActivities.findWhere({'activity':activity});
	        				var bmActivityReused = checkBmActivityReused(valueStreams,vf,activity);
	        				
  							if(bmActExists){
  								if(!bmActivityReused){
		        					bmActivityList.push(bmActExists);
		        				}else {
		        					removeActivityFromStream.push({'activity':activity,'valueStream':valueStream});
		        				}
  							}
	        			});
	        			for(var i=0;i<bmActivityList.length;i++){
	        				bmActivityList[i].destroy();
	        			}
	        		}
	        	});
                if(removeActivityFromStream) {
                    for(var i=0;i<removeActivityFromStream.length;i++){
                        var valStream = removeActivityFromStream[i].valueStream;
                        valStream.get('valueStreamActivity').remove(removeActivityFromStream[i].activity);
                    }
                }
				var delBmValueStreamLen = delValueStream.length;
				while(delBmValueStreamLen--){
					delValueStream[delBmValueStreamLen].destroy();
				}
        		self.deleteValueFormula(bmVF, vf, null, null);
        	}
        };        
        
        handleOnBMRoleDeleted(role,collection,options){
			if(Backbone.skipPersistance){
				return;
			}        	
        	if(options && options.creatingRevision){
        		return;
        	} 
        	var self = this;
        	var modelsToDelete = [];
        	var performedActivities = role.get('performedWork');
        	var providedPropositions = role.get('providedProposition');
        	var receivedPropositions = role.get('receivedProposition');
        	performedActivities.each(function(performedActivity){
        		var bmActivities = self.get('bmActivity');
        		bmActivities.each(function(bmActivity){
        			if(bmActivity.get('activity') === performedActivity){
        				modelsToDelete.push(bmActivity);
        			}
        		});
        	});
        	var deleteVfList = [];
        	var deleteVpList = [];
            var removeVPToRole = [];
        	receivedPropositions.each(function(vp){
        		var bmVPs = self.get('bmValueProposition');
        		bmVPs.each(function(bmVP){
        			if(bmVP.get('valueProposition') === vp){
                        removeVPToRole.push(vp);
        				//deleteVpList.push(bmVP);
        			}
        		});
        	});
            _.each(removeVPToRole,function(vp){
    			vp.set('recipient',null);
    		});
        	providedPropositions.each(function(vp){
        		var bmVPs = self.get('bmValueProposition');
        		bmVPs.each(function(bmVP){
        			if(bmVP.get('valueProposition') === vp){
        				deleteVpList.push(bmVP);
        			}
        		});
        		var bmVFs = self.get('bmValueFormula');
        		bmVFs.each(function(bmVF){
        			if(bmVF.get('valueFormula') === vp){
        				deleteVfList.push(bmVF);
        			}
        		});        		
        	});
        	_.uniq(deleteVpList);
			_.each(deleteVpList,function(bmVP){
    			self.get('bmValueProposition').remove(bmVP);
    		});
    		_.each(deleteVfList,function(bmVf){
        		self.get('bmValueFormula').remove(bmVf);
        	});
        	_.each(modelsToDelete,function(modelToDel){
        		modelToDel.destroy();
        	});
        };
        
        /*BusinessModelMixin.prototype.buildDecisionTree(role){
        	var partExistDT = new Decision(role,role.hasAssignments,[],true,function(){
        		
        	});
        	var bmInvolvedD = new Decision(role,role.getBusinessModelsInvolved,[],true,function(){
        		
        	});
        	partExistDT.addChildDecision('true',bmInvolvedD);
        };*/
        getRolesofParticipant(participant){
        	var ret=[];
        	var roles = this.get('bmRole');
        	roles.each(function(role){
        		var assignments = role.get('roleAssignment');
        		assignments.each(function(assignment){
        			if(assignment.get('participant') === participant){
        				ret.push(role);
        			}
        		});
        	});
        	return ret;
        };
        
        getBusinessRoles(){
        	var roles = [];
        	roles = roles.concat(this.get('bmRole').models);
        	var bmCustomers = this.get('bmCustomer');
        	bmCustomers.each(function(bmCustomer){
        		var custRoles = bmCustomer.get('customerRole').models;
				roles = _.difference(roles,custRoles);
        	});
        	var bmNPs = this.get('bmNetworkPartner');
        	bmNPs.each(function(bmNP){
        		var npRoles = bmNP.get('networkPartnerRole').models;
        		roles = _.difference(roles,npRoles);
        	});
        	return roles;
        };
		getValues(values,allRoles){
			var self = this;
			var bmVPValues = self.get('bmValueProposition');
			var bmVFValues = self.get('bmValueFormula');
			var bmActivities = self.get('bmActivity');
			
			var bmRoles = self.getBusinessRoles();
			if(allRoles){
				bmRoles = self.get('bmRole').models;
			}
			/*for(var i=0;i<bmRoles.length;i++){
				var vps = bmRoles[i].get('providedProposition');
				vps.each(function(vp){
					var components = vp.get('component');
					components.each(function(comp){
						self.addValue(values,comp);
					});
				});
			}*/
			bmVPValues.each(function(bmVPValue){
				var vp = bmVPValue.get('valueProposition');
                if(vp){
                    var vpProvider = vp.get('provider');
                    if(_.indexOf(bmRoles,vpProvider) >=0){
                        var vpComponents = vp.get('component');
                        vpComponents.each(function(vpComp){
                            self.addValue(values,vpComp);
                        });
                    }
                }
			});
			bmVFValues.each(function(bmVFValue){
				var vp = bmVFValue.get('valueFormula');
				if(vp) {
					var vpProvider = vp.get('provider');
					if(_.indexOf(bmRoles,vpProvider) >=0){
						var vpComponents = vp.get('component');
						vpComponents.each(function(vpComp){
							self.addValue(values,vpComp);
						});
					}
				}
			});
			bmActivities.each(function(bmActivity){
				var activityValues = bmActivity.get('activityValue');
				activityValues.each(function(activityValue){
					self.addValue(values,activityValue);
				});
			});
		}; 
		addValue(values,val){
			/*var modelId = val.get('id');
			var valSuffix = modelId.substr(modelId.lastIndexOf('@')+1);
			var found = false;
			_.each(values,function(addedVal){
				var addedModelId = addedVal.get('id');
				var addedValSuffix = addedModelId.substr(addedModelId.lastIndexOf('@')+1);
				if(addedValSuffix === valSuffix && !val.getNestedParent().get('copySource')){
					found = true;
				}			
			});
			if(!found){
				values.push(val);
			}*/
			values.push(val);
		}
        getPaticipantBMType(bm,participant){
			var type;
   			if(bm.get('business') === participant){
   				type = 'business';
   			}else {
   				if(bm.get('bmCustomer').findWhere({'customer':participant})){
   					type = 'customer';
   				}
   				if(bm.get('bmNetworkPartner').findWhere({'networkPartner':participant})){
   					if(!type){
   						type = 'partner';
   					}else {
   						type = type.concat(', partner');
   					}
   					
   				}
   			}
   			return type;
		};
        getRoleBMRelationship(bm, participant, role) {
            var self = this;
            var type;
            if (bm.get('business') === participant) {
                type = 'business';
            } else {
                var cust = bm.get('bmCustomer').findWhere({'customer': participant});
                if (cust && cust.get('customerRole').findWhere({'id': role.id})) {
                    type = 'customer';
                } else {
                    var part = bm.get('bmNetworkPartner').findWhere({
                        'networkPartner': participant
                    });
                    if (part && part.get('networkPartnerRole').findWhere({'id': role.id})) {
                        type = 'partner';
                    }
                }
            }
            return type;
        };
//#endCustomMethods

		/* Start-Selinium-Test-Methods */		
		getExportJsonsForSelenium(phase, alternative){
			var planModel = DataManager.getDataManager().get('currentPlan');
			var packages = planModel.getExportJsonsForSelenium(phase, alternative);
			return packages;
		};
		
		/* End-Selinium-Test-Methods */
    
	}
	path.BusinessModelMixin = BusinessModelMixin;
    //return BusinessModelMixin;
//});
