import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { MeasurableElement } from './MeasurableElement'
import { PortContainerMixin } from './PortContainerMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Port } from './Port';

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/PortContainerMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,PortContainerMixin
, Port){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Port){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Port){
			Port = importPath.Port;
		}
        else {
            import('./Port').then(({ default: Port }) => {
                Port = Port;
            });
			/*require(["appbo/vdml/Port"],function(loadedModule){
				Port = loadedModule;
			});*/
		}
	}
	var PortContainer = MeasurableElement.extend(utils.customExtends({
		relations:PortContainerMixin.getMixinRelations(),
		subModelTypes: {
			vdml_Activity : 'vdml.Activity',
			vdml_Collaboration : 'vdml.Collaboration',
			vdml_Store : 'vdml.Store'
		},
		/*preinitialize: function(instance){
			if(!PortContainer._subModels[instance.type]){
				PortContainer._subModels[instance.type] = this.constructor;
			}
		},*/
		initialize: function(attributes, options) {
			/*if(!PortContainer._subModels[arguments[0].type]){
				PortContainer._subModels[arguments[0].type] = this.constructor;
			}*/
			MeasurableElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new PortContainerMixin()
	));
	PortContainer.abstract = true;
	path.PortContainer = PortContainer;
    export { PortContainer };
//});