import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {MeasuredCharacteristic} from './MeasuredCharacteristic'

/*define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, MeasuredCharacteristic){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
		else{
            import('./MeasuredCharacteristic').then(({ default: MeasuredCharacteristic }) => {
                MeasuredCharacteristic = MeasuredCharacteristic;
			});
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	export class MeasurableElementMixin {

	defaults(){
		var ret = {
			type: "vdml_MeasurableElement"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_MeasurableElement",
			key:"measuredCharacteristic",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"measuredCharacteristicOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	
	static getCumulativeMixinRelations(){
		if (!MeasurableElementMixin.cummulativeRelations) {
            MeasurableElementMixin.cummulativeRelations = _.union(MeasurableElementMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return MeasurableElementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		return VdmlElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return VdmlElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/MeasurableElementPropertiesTemplate.html",
			templateName : "MeasurableElementPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/MeasurableElementViewModel",
			tabId : "MeasurableElementView",
			tabName: "MeasurableElement"
		}
	}
	
	}
	path.MeasurableElementMixin = MeasurableElementMixin;
	//return MeasurableElementMixin;
//});