import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelDimension} from './BusinessModelDimension'
import {ActivityDimensionMixin} from './ActivityDimensionMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelDimension","appbo/vdml/ActivityDimensionMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelDimension,ActivityDimensionMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var ActivityDimension = BusinessModelDimension.extend(utils.customExtends({
		relations:ActivityDimensionMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BusinessModelDimension.prototype.initialize.apply(this, arguments);
		}
		}
		, new ActivityDimensionMixin()
	));
	
	ActivityDimension.abstract = false;
	path.ActivityDimension = ActivityDimension;
	export {ActivityDimension};
//});