import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Participant} from './Participant'
import {ParticipantMixin} from './ParticipantMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'


/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Participant","appbo/vdml/ParticipantMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Participant,ParticipantMixin
, Activity
, Assignment
, ValueProposition

, RoleDefinition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	
	
	export class RoleMixin {

	defaults(){
		var ret = {
			type: "vdml_Role"
		}
		return jQuery.extend(Participant.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Role",
			key:"performedWork",
			relatedModel:"vdml.Activity",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"performingRole",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Role",
			key:"roleAssignment",
			relatedModel:"vdml.Assignment",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"assignedRole",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Role",
			key:"providedProposition",
			relatedModel:"vdml.ValueProposition",
			reverseRelation: {
				key:"provider",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Role",
			key:"receivedProposition",
			relatedModel:"vdml.ValueProposition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"recipient",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_Role",
			key:"roleDefnition",
			relatedModel:"vdml.RoleDefinition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!RoleMixin.cummulativeRelations) {
            RoleMixin.cummulativeRelations = _.union(RoleMixin.getMixinRelations()
                , ParticipantMixin.getCumulativeMixinRelations()
            );
        }
		return RoleMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_Participant"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "isLead",type : "EBoolean",defaultValue : "null",containingClass : "vdml_Role" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("collaborationRoleOwner") ? this.get("collaborationRoleOwner") : this.previousAttributes().collaborationRoleOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("collaborationRoleOwner") ? this.get("collaborationRoleOwner") : this.previousAttributes().collaborationRoleOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/RolePropertiesTemplate.html",
			templateName : "RolePropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/RoleViewModel",
			tabId : "RoleView",
			tabName: "Role"
		}
	}
	getParentScenario(assignmentModel){
		var parentScenario = assignmentModel.get('contextBasedAssignmentOwner');
		while(parentScenario && parentScenario.get('parentContext')){
			parentScenario = parentScenario.get('parentContext');
		}
		return parentScenario;
	}
	getParticipants(BM_ref){
		var self = this;
		var roleRef = self ;
		var isParticipantAmigious = false;
		var participantList = [];
		var assignments = roleRef.get('roleAssignment');
		var bmParticipants = BM_ref.getParticipants();	
		var participantToDisplay = [];
			//Get the assignemnts list. If more than one assignment present for a role, assignments should not be from same owner(collaboration or analysis context). 
		if( assignments.length >0 ){
			var assignmentOwnerArray = [];
			assignments.each(function(assignment){
				var assignedOwner = self.getParentScenario(assignment) || assignment.get('ownedAssignmentOwner');
				var isAmigious = _.contains(assignmentOwnerArray, assignedOwner);
				assignmentOwnerArray.push(assignedOwner);
				if(!isAmigious){
					participantList.push(assignment.get('participant'));
				}else{
					isParticipantAmigious = true;
				}
			}); 
		}
		
		//check for each participant, is a BM participant or not. Parse the tree if particpant is orgUnit or community. getHierarchy also returns actor,position or members if present in the subUnit or subCommunity.
		_.each(participantList, function(participant){
			var partType = participant.get('type');
			var isBMParticipant = false;
			if(partType == "vdml_OrgUnit" || partType == "vdml_Community"){
				isBMParticipant = _.contains(bmParticipants, participant);
				if(isBMParticipant){
					participantToDisplay.push(participant);
				}else{
					var participantTree = participant.getHierarchy();
					_.each(participantTree, function(subunitParticipant){
						isBMParticipant = _.contains(bmParticipants, subunitParticipant);
						if(isBMParticipant){
							participantToDisplay.push(subunitParticipant);
						}
					});
				}
			}else{
				isBMParticipant = _.contains(bmParticipants, participant);
				if(isBMParticipant){
					participantToDisplay.push(participant);
				}
			}
		});
		if(isParticipantAmigious){
			participantToDisplay = [];
		}
		return participantToDisplay;
	}
	getBusinessModelsInvolved(callback){
		var self = this;
        DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_BusinessModel-bmRole', 'vdml_BusinessModel', "vbc:" + "cmof_EObject-name",function(businessModels){
			if(callback){
				callback(businessModels);
			}
		},true);		
	};
	
	hasAssignments(){
		return this.get('roleAssignment').length >0;
	};
	
	copy(targetCollaboration,measurementsCopied,valuesCopied){
		var roleId = DataManager.getDataManager().guidGeneratorByOwner(targetCollaboration);
		var sourceCollaboration = this.get('collaborationRoleOwner');
		var nameStr;
		if(sourceCollaboration === targetCollaboration){
			nameStr = targetCollaboration.getUniqueProperty('name','Copy of ' + this.get('name'),'collaborationRole');
		}else{
			nameStr = targetCollaboration.getUniqueProperty('name',this.get('name'),'collaborationRole');
		}
		var copyRole = new this.constructor({id:roleId,name:nameStr,description:this.get('description'), collaborationRoleOwner:targetCollaboration});
		/*var calendSer = this.get('participantCalendar');
		if(calendSer){
			calendSer.copy(copyRole);
		}*/
		var assignments = this.get('assignment');
		assignments.each(function(assignment){
			var sourceAssignedRole = assignment.get('assignedRole');
			var targetAssignedRole = sourceAssignedRole;
			var owner = assignment.get('ownedAssignmentOwner');
			if(!owner){
				owner = assignment.get('contextBasedAssignmentOwner');
			}			
			assignment.copy(owner,owner,sourceAssignedRole,targetAssignedRole);
		});
		var roleAssignments = this.get('roleAssignment');
		roleAssignments.each(function(roleAssignment){
			var owner = roleAssignment.get('ownedAssignmentOwner');
			if(!owner){
				owner = roleAssignment.get('contextBasedAssignmentOwner');
			}
			roleAssignment.copy(this,copyRole,sourceCollaboration,sourceCollaboration);
		});
		copyRole.set('roleDefinition',this.get('roleDefinition'));
		var mesChars = this.get('measuredCharacteristic');
		mesChars.each(function(mesChar){
			mesChar.copy(copyRole,measurementsCopied);			
		});	
		var providedPropositions = this.get('providedProposition');
		providedPropositions.each(function(vp){
			vp.copy(copyRole,measurementsCopied,valuesCopied);
		});
		return copyRole;
	};
	isAssignedToOrTarget(targetId,rolesChecked) {
	    var result = false;
	    if (!rolesChecked) {
	        rolesChecked = [];
	    }
	    if (_.indexOf(rolesChecked, this) >= 0) {
	        return false;
	    } else {
	        rolesChecked.push(this);
	    }
	    if (targetId === this.get('id')) {
	        return true;
	    } else {
	        var roleAssignments = this.get('roleAssignment');
	        for(var i=0;i<roleAssignments.length;i++){
	            var assignment = roleAssignments.at(i);
	            var participant = assignment.get('participant');
	            if (participant.id === targetId) {
	                return true;
	            }
	            if (participant.get('type') === 'vdml_Actor' || participant.get('type') === 'vdml_Person') {
	                if (targetId === participant.id) {
	                    return true;
	                } else {
	                    return false;
	                }
	            } else if (participant.get('type') === 'vdml_Role') {
	                result = participant.isAssignedToOrTarget(targetId, rolesChecked);
	                if (result) {
	                    break;
	                }
	            } else {
	                var collRoles = participant.get('collaborationRole');
	                for (var j = 0; j < collRoles.length; j++) {
	                    result = collRoles.at(j).isAssignedToOrTarget(targetId, rolesChecked);
	                    if (result) {
	                        break;
	                    }
	                }
	                if (result) {
	                    break;
	                }
	            }
	        };
	    }
	    return result;
	}
	
	
	}
	path.RoleMixin = RoleMixin;
	//return RoleMixin;
//});