import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {ConceptElement} from './ConceptElement'
import {VocabularyMixin} from './VocabularyMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/concept/ConceptElement","appbo/concept/VocabularyMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,ConceptElement,VocabularyMixin
//){
	
	var path = DataManager.getDataManager().buildAppNsPath("concept",global.version);
	var Vocabulary = ConceptElement.extend(utils.customExtends({
		relations:VocabularyMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			ConceptElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new VocabularyMixin()
	));
	Vocabulary.getInstance = function(name,owner){
        var cid = DataManager.getDataManager().guidGeneratorByOwner(owner);
		var vocabulary = new Vocabulary({id:cid,name:owner.getUniqueProperty('name',name,'concept'),vocabularyOwner:owner});
		return vocabulary;
	};	
	Vocabulary.abstract = false;
	path.Vocabulary = Vocabulary;
	//return Vocabulary;
	export {Vocabulary};
//});