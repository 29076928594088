import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {ValueElement} from '../vdml/ValueElement'
import {ValueInstanceMixin} from './ValueInstanceMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("instdef",global.version);

var ValueInstance = ValueElement.extend(utils.customExtends({
        relations:ValueInstanceMixin.getMixinRelations(),
        initialize: function(attributes, options) {
            ValueElement.prototype.initialize.apply(this, arguments);
            
        }
    }
    , new ValueInstanceMixin()
));
ValueInstance.abstract = true;
path.ValueInstance = ValueInstance;
export {ValueInstance};
