import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {PortContainer} from './PortContainer'
import {CollaborationMixin} from './CollaborationMixin'
//import {Participant} from './Participant'
import {ParticipantMixin} from './ParticipantMixin'

/*
import {Role} from './Role'
import {Activity} from './Activity'
import {DeliverableFlow} from './DeliverableFlow'
import {PortDelegation} from './PortDelegation'
import {DelegationContext} from './DelegationContext'
import {Scenario} from './Scenario'
import {CollaborationDiagram} from './CollaborationDiagram'*/
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/PortContainer","appbo/vdml/CollaborationMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,PortContainer,CollaborationMixin
, Participant
, ParticipantMixin
, Role
, Activity
, DeliverableFlow
, BusinessItem
, PortDelegation
, DelegationContext
, Scenario
, CollaborationDiagram){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	
	var Collaboration = PortContainer.extend(utils.customExtends({
		relations:CollaborationMixin.getMixinRelations(),
		subModelTypes: {
			vdml_BusinessNetwork : 'vdml.BusinessNetwork',
			vdml_CapabilityMethod : 'vdml.CapabilityMethod',
			vdml_Community : 'vdml.Community',
			vdml_OrgUnit : 'vdml.OrgUnit'
		},
		initialize: function(attributes, options) {
			PortContainer.prototype.initialize.apply(this, arguments);
			//this.on("change:name", this.handleCollNameChange);
		}
		}
		, new ParticipantMixin()
		, new CollaborationMixin()
	));
    Collaboration["change:name"] = function (model, newName) {
		var previousName = model._previousAttributes ? model._previousAttributes.name:"";
		if(previousName != newName) {
			this.handleCollNameChange(model, newName);
		}
    }
	Collaboration.abstract = false;
	path.Collaboration = Collaboration;
	export {Collaboration};
//});