import * as $ from "jquery";
import * as _ from "underscore";
import * as Backbone from "backbone";
import * as ko from "knockout";
import * as kb from "knockback";
import * as bootbox from "../../../../../../libs/bootbox/bootbox";
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { DataManager } from "../../../../../com/vbee/data/DataManager";
import * as moment from 'moment'
import MarketPlace from "./MarketPlace";
var path = DataManager.getDataManager().buildAppNsPath("tickets",global.version);

export class MarketViewModel {
  constructor(model, options, detailedView) {
    window.utils.startSpinner("MarketSpinner", "Loading Market data...");
    var self = this;
    this.MarketViewModel = this;
    this.encodeId = options.viewId;
    this.labels = kb.viewModel(DataManager.getDataManager().get("localeManager"),[
        "Close",
        "update",
        "Cancel"
      ]);
    this.afterRenderView = _.bind(this.afterRenderView, this);
    self.eventBus = {
      listeners: {},
      on: function (event, callback) {
        if (!this.listeners[event]) {
        this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
      },
      emit: function (event, data) {
        const listeners = this.listeners[event];
        if (listeners) {
        listeners.forEach((callback) => callback(data));
        }
      },
    };

    self.marketPlaceContainer = MarketPlace;

    ko.bindingHandlers.marketPlace = {
      init() {
        return { controlsDescendantBindings: true };
      },
      update: function (element, valueAccessor) {
        var component = ko.unwrap(valueAccessor().component);
        var renderComponent = () => {
        ReactDOM.render(React.createElement(component), element);
        };
        const eventListener = () => {
        renderComponent();
        };
        self.eventBus.on("marketProps", eventListener);
        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
        ReactDOM.unmountComponentAtNode(element);
        });
      },
    };
  }

  cleanUp(){
		delete ko.bindingHandlers.marketPlace;
	};


  setBreadCrumb(breadCrumbItems) {
    var self = this;
    $("#breadcrumb").addClass("breadcrumb");
    breadCrumbItems.push(
      '<li><a style="font-size: x-large;" href="#adminviews/Market">' +
        "Market" +
        "</a></li>"
    );
  }

  isAdminView() {
    return true;
  }

  afterRenderView() {
    var self = this;
    self.eventBus.emit("marketProps", { component: MarketPlace });
    window.utils.stopSpinner("MarketSpinner");

  }

  setResizeHeightHandler() {
    /*var self = this;
        setTimeout(function () {
            if (self.spliter) {
                var windowHeight = window.innerHeight - $('.navbar').outerHeight();
                var propertiesNode = $('.propertiesArea');
                var HistoryNode = $('#maintainHistory');
                var tabNode = $('.tab-content');
                var adminAreaNode = $('.adminArea');
                var baseHeight = _.max([windowHeight, adminAreaNode.outerHeight(), tabNode.outerHeight(), HistoryNode.outerHeight(), propertiesNode.outerHeight()]);
                //self.spliter.css('height', (baseHeight) + 'px');
                //$("#splitPanels").enhsplitter('refresh');
            }
        }, 150)*/
  }

  static getInstance(model, options, detailedView) {
    return new MarketViewModel(model, options, detailedView);
  }
}
path.MarketViewModel = MarketViewModel;
