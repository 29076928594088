import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BeepPackage} from '../beeppackage/BeepPackage'
import {EcoMapMixin} from './EcoMapMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {PackageReference as PlanPackageReference}  from '../transformation/PackageReference'
import {PackageReference}  from '../../../com/vbee/filesystem/PackageReference'

//define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/beeppackage/BeepPackage", "appbo/ecomap/EcoMapMixin", "appbo/vdml/EcoMapDiagram"],
/*function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, BeepPackage, EcoMapMixin, EcoMapDiagram
,PlanPackageReference
,PackageReference
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("ecomap",global.version);

	/*if(!PackageReference){
		var importPath = DataManager.getDataManager().buildCommonNsPath("com/vbee/filesystem",global.version);
		if(importPath.PackageReference){
			PackageReference = importPath.PackageReference;
		}
		else{
			require(["appcommon/com/vbee/filesystem/PackageReference"],function(loadedModule){
				PackageReference = loadedModule;
			});
		}
	}
	if(!PlanPackageReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.PackageReference){
			PlanPackageReference = importPath.PackageReference;
		}
		else{
			require(["appbo/transformation/PackageReference"],function(loadedModule){
				PlanPackageReference = loadedModule;
			});
		}
	}*/	
/*	if(!ScenarioProxy){
		var importPath = DataManager.getDataManager().buildCommonNsPath("transformation",global.version);
		if(importPath.ScenarioProxy){
			ScenarioProxy = importPath.ScenarioProxy;
		}
		else{
			require(["appbo/transformation/ScenarioProxy"],function(loadedModule){
				PackageReference = loadedModule;
			});
		}
	}*/
	
	var EcoMap = BeepPackage.extend(utils.customExtends({
		relations:EcoMapMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BeepPackage.prototype.initialize.apply(this, arguments);
			//this.on("change:name", this.handlePackageNameChange);
		}
		}
		, new EcoMapMixin()
	));
//#startCustomMethods	
    EcoMap["change:name"] = function (model, newName) {
		var previousName = model._previousAttributes ? model._previousAttributes.name:"";
		if(previousName != newName) {
			this.handlePackageNameChange(model, newName);
		}
    }
	EcoMap.prototype.getSystemTags = function(){
	    return ['Business Ecosystem Map Package', 'VDM', 'Admin'];
	};
//#endCustomMethods
	EcoMap.getName = function(){
		return "EcoMap";
	};
	EcoMap.createPackageWithName = function(name,description,currentAlternative){
		name = window.utils.getUniquePackageName(name, name, currentAlternative, "ecomap_EcoMap");
		var docId = window.guidGenerator(); 
		var ecomap = new EcoMap({
			'name':name,
			'description':description,
			'id':docId,
			'label':"Base"});
		var pacRef = new PackageReference({beepReference:ecomap,name:ecomap.get('name'),description:ecomap.get('description'),version:ecomap.get('version'),beepType:ecomap.get('type'),label:'Base'});
		DataManager.getDataManager().get('currentWorkspace').get('packageReference').add(pacRef);
		DataManager.getDataManager().get('initializedPackages').add({'id':ecomap.get('id'),'version':parseInt(ecomap.get('version')),'model':ecomap});
		var currentphase = currentAlternative.get('phaseAlternativeOwner');
		var pprId = DataManager.getDataManager().guidGeneratorByOwner(currentphase);
		var planPacRef = new PlanPackageReference({id:pprId,beepReference:ecomap.get('id'),name:ecomap.get('name'),description:ecomap.get('description'),version:ecomap.get('version'),beepType:ecomap.get('type'),label:'Base',isMain:true,phaseDesignPartOwner:currentAlternative});
		currentAlternative.get('phaseDesignPart').add(planPacRef);
		if(currentphase.get('primary') === currentAlternative || currentphase.get('master') === currentAlternative) {
			ecomap.referPackageToNextPhases(currentAlternative);
		}
		return ecomap;
	}

	EcoMap.createValueStreamMapDiagram = function (pack, name, description, valternative, ValueStreamMapDiagram, callback) {
		var self = this;
		var altId = valternative.id;//DataManager.getDataManager().get('viewAlternative');
		if (altId) {
			DataManager.getDataManager().getAlternative(altId, function (alternative) {
				var diagramInstance = ValueStreamMapDiagram.getInstance(name, description, pack);
				if (valternative && valternative !== alternative) {
					DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + pack.get('id') + "/ecomap_EcoMap/" + pack.get('version') + "/" + diagramInstance.get('id') + "/vdml_ValueStreamMapDiagram/" + valternative.get('id') + "", { trigger: true });
					callback();
				} else {
					DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + pack.get('id') + "/ecomap_EcoMap/" + pack.get('version') + "/" + diagramInstance.get('id') + "/vdml_ValueStreamMapDiagram/" + altId + "", { trigger: true });
					setTimeout(callback, 1500);
				}
			});
		}
    };
	
	function printStackSize(method){
		console.log('method:' + method)
		var i=0;
		function testStack(){
			i++;
			testStack();
		}		
		try{
			testStack();
		}catch(e){
			console.log('stak size:' + i)
		}
	}
	EcoMap.abstract = false;
	path.EcoMap = EcoMap;
	//return EcoMap;
	export {EcoMap};
//});