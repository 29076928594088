(function (factory) {
  /* global define */
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node/CommonJS
    module.exports = factory(require('jquery'));
  } else {
    // Browser globals
    factory(window.jQuery);
  }
}(function ($) {

  // Extends plugins for adding importCanvas.
  //  - plugin is external module for customizing.
  $.extend($.summernote.plugins, {
    /**
     * @param {Object} context - context object has status of editor.
     */
    'importImage': function (context) {
      var self = this;
	  
      // ui has renders to build ui elements.
      //  - you can create a button with `ui.button`
      var ui = $.summernote.ui;

      // add importCanvas button
      context.memo('button.importImage', function () {
        // create button
        self.context = arguments[0];
        var button = ui.button({
            contents: '<i class="note-icon-picture"/> ',
            tooltip: 'Image',
            click: function () {
		        var EcoMapDiagramMixin = Backbone.Relational.store.getObjectByName("vdml.EcoMapDiagramMixin");
            EcoMapDiagramMixin.showDialog({},"ImageSelector");
			}
        });
		var $importImage = button.render();
        return $importImage;
      }); 

      // This events will be attached when editor is initialized.
      this.events = {
        // This will be called after modules are initialized.
        'summernote.init': function (we, e) {
          //console.log('summernote initialized', we, e);
        },
        // This will be called when user releases a key on editable.
        'summernote.keyup': function (we, e) {
          //console.log('summernote keyup', we, e);
        }
      };

      // This method will be called when editor is initialized by $('..').summernote();
      // You can create elements for plugin
      this.initialize = function () {
      };

      // This methods will be called when editor is destroyed by $('..').summernote('destroy');
      // You should remove elements on `initialize`.
      this.destroy = function () {
      };
    }
  });
}));