import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../../com/vbee/data/DataManager'
import * as  bootbox  from '../../../../../../../libs/bootbox/bootbox'
//import {PackageReference as PlanPackageReference} from '../../../../../bo/transformation/PackageReference'
import {PackageReference} from '../../../../../../com/vbee/filesystem/PackageReference'
import {BeepPackageMixin} from '../../../../../bo/beeppackage/BeepPackageMixin'
import {ProcessDiscoveryDiagram} from '../../../../../bo/vdml/ProcessDiscoveryDiagram'
import {ValueDeliveryModel} from '../../../../../bo/vdml/ValueDeliveryModel'

/*define(["require", "jquery", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "FileSaver", "async", "appcommon/com/vbee/data/DataManager", "appbo/beeppackage/BeepPackageMixin", "appbo/transformation/PackageReference", "appcommon/com/vbee/filesystem/PackageReference", "appbo/vdml/ProcessDiscoveryDiagram", "appbo/vdml/ValueDeliveryModel", "app/global"],
	function (require, $, _, Backbone, ko, koMapping, kb, bootbox, FileSaver, async, DataManager, BeepPackageMixin, PlanPackageReference, PackageReference, ProcessDiscoveryDiagram, ValueDeliveryModel, global)
{*/
	var path = DataManager.getDataManager().buildAppNsPath("vdml.views.designer.processModel",global.version);
	export class ProcessDiagramDetailsViewModel {
        constructor(model, options) {
            var self = this;
            this.init(model, options);
        }
	    dispose(){
			var self = this;
			window.cleanViewModel(self);		
        }; 
        
		/*if(window.checkContextForRevision()) {
			self.enableDelete(false);
		}*/
		
	    saveProcessDiagramDetails(){
			var self=this;
	    	if(self.name() !== ""){
	    		if(self.name().trim() != self.model.get('name')){
	    			self.model.set('name',self.name().trim());
	    			window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'),DataManager.getDataManager().get('viewAlternative'),self.ProcessDiagramDetailsViewModel);
	    		}
				self.model.set('description',self.description().trim());	
			}		

		};
		
		importXML(view, event) {//Electron
            var self = this;
			var fileObject = event.target.files[0];
			var reader = new FileReader();
			reader.onload = function (ev) {
				var xmlContent = reader.result;
				var oParser = new DOMParser();
				var oDOM = oParser.parseFromString(xmlContent, "text/xml");
				if (oDOM.documentElement.nodeName == "parsererror" || oDOM.documentElement.nodeName == "html") {
					bootbox.alert('XML is corrupt');
				} else if (oDOM.documentElement.nodeName == "bpmn2:definitions") {
					self.model.set('data', xmlContent);
					self.parentView.initializePresentation();
				} else {
					bootbox.alert('No Valid XML');
				}
			}
			reader.readAsBinaryString(fileObject);
		}
		importProcessDiagram() {
			var allowedExt = ['bpmn'];
			var self = this;
			if (window.utils.checkChrome() && chrome.fileSystem) {
				chrome.fileSystem.chooseEntry({
					type: 'openFile', accepts: [
						{ description: "bpmn", extensions: allowedExt }
					], acceptsAllTypes: false
				}, function (f) {
					if (chrome.runtime.lastError) {
						console.log(chrome.runtime.lastError);
					}
					else {
						f.file(function (fileObject) {
							var reader = new FileReader();
							reader.onload = function (ev) {
								var xmlContent = reader.result;
								var oParser = new DOMParser();
								var oDOM = oParser.parseFromString(xmlContent, "text/xml");
								if (oDOM.documentElement.nodeName == "parsererror" || oDOM.documentElement.nodeName == "html") {
									bootbox.alert('XML is corrupt');
								} else if (oDOM.documentElement.nodeName == "bpmn2:definitions") {
									self.model.set('data', xmlContent);
									self.parentView.initializePresentation();
								} else {
									bootbox.alert('No Valid XML');
								}
							}
							reader.readAsBinaryString(fileObject);
						});
					}
				});
			}
			else {
				document.getElementById("file1").click();
			}
		}

        exportProcessDiagram() {
            var self = this;
            function saveDiagram(done) {
                /*window.vdmModelView.modeler.saveXML({ format: true }, function (err, xml) {
                    done(err, xml);
                });*/
				window.vdmModelView.modeler.saveXML({ format: true }).then(function(xml){  done(xml);});
            }

            saveDiagram(function (err, xml) {
				/*if (window.utils.checkChrome() && chrome.fileSystem && chrome.fileSystem.chooseEntry) {
                    chrome.fileSystem.chooseEntry({ type: 'saveFile', suggestedName: self.model.get('name'), accepts: [{ extensions: ['bpmn'] }] }, function (f) {
                        if (!chrome.runtime.lastError) {
                            f.remove(function () {
                                window.utils.saveCurrentEntry(f,xml,'BPMN','text');
                            }, function (e) {
                                console.log(e);
                                bootbox.alert('Export error: ' + e);
                            });
                        }
                        else {
                            console.log(chrome.runtime.lastError);
                            if (chrome.runtime.lastError.message !== 'User cancelled') {
                                bootbox.alert('Export error: ' + chrome.runtime.lastError);
                            }
                        }
                    });
                } else {*/
					saveDiagram(function (xml) {
						xml = xml.xml;
						var blob = new Blob([xml], { type: 'text/plain' });
						var exportMsg = DataManager.getDataManager().get('localeManager').get('exportComplete',self.model.get('name'));
						window.utils.exportFileToSystem(self.model.get('name'),'BPMN File',".bpmn",blob,exportMsg);
					});
                //}
            });
        }
		copyProcessDiagram(){
            var self = this;
			function savePackage(view, modalId, type, moduleTypes) {
		        /*var workspaceAlt = DataManager.getDataManager().getWorkspaceDataWithIdSync(self.currentAlt.get('id')).get('workspace');
		        DataManager.getDataManager().set('currentWorkspace', workspaceAlt);
		        DataManager.getDataManager().set('currentWorkSpaceKey', workspaceAlt.get('id'));*/
		        window.utils.startSpinner('copyProcessDiagramSpinner', "Copying Process...");
			    setTimeout(function () {
                    self.onAddProcessDiagram(view.name(), view.description(), view.selectedPackage(), null, null, self.currentAlt, type, moduleTypes);
			        window.cleanDialogModel(modalId, view);
			        window.utils.stopSpinner('copyProcessDiagramSpinner');
		        },100)
	        }
		    var plan = DataManager.getDataManager().get('currentPlan');
			var mapTitle = DataManager.getDataManager().get('localeManager').get("ProcessModel");
			var addOptions = { 'alternative': self.currentAlt, 'plusIconType': 'capabilityMethod', onlyMap:mapTitle};
			//window.getAndCreateModalDialog(self.model, htmlEscape(self.id()), BusinessModelMixin, plan, "CreatePackage", savePackage, addOptions);
			window.getAndCreateModalDialog(self.model, window.utils.htmlEscape(self.id()), BeepPackageMixin, plan, "Prototype", savePackage, addOptions);
		};
		onAddProcessDiagram(name, description, pack, phase, alternative, currentAlternative, type, moduleTypes) {
		    var self = this;
		    if (pack !== null && pack.id) {
		        var altId = DataManager.getDataManager().getRepositoryId(pack.id);
                DataManager.getDataManager().fetchDocumentFromPackage(pack.id, "appbo/vdml/ValueDeliveryModel", pack.version, pack.id, "appbo/vdml/ValueDeliveryModel", DataManager.getDataManager().getVDMStore(altId), {
		            success: function (model) {
		                if (altId !== currentAlternative.get('id')) {
		                	var ecoPackage = self.model.getNestedParent();
							var depPacks = ecoPackage.get('dependentPackage')?ecoPackage.get('dependentPackage').models.concat():[];
		                    self.createRevisionBasedOnContext(model,depPacks,function (newPackage) {
		                        var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
		                        var altId = DataManager.getDataManager().getRepositoryId(newPackage.id);
								var ecoMap = newPackage.get('diagrams').findWhere({ 'id': altId + bmId });
								var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
		                        var diagrams = newPackage.get('diagrams');
		                        for (var i = 0; i < diagrams.length; i++) {
		                            newPackage.fixDiagramOnRevision(diagrams.at(i), altId, oldAltId);
                                }
                                window.utils.stopSpinner('revisionSpinner');
		                        self.createProcessDiagram(name, description, newPackage, type, moduleTypes);
		                    });
		                } else {
		                    self.createProcessDiagram(name, description, model, type, moduleTypes);
		                }
		            },
		            error: function (error) {
		                console.log('Unable to load selected Package');
		            }
		        });
		    } else {
		        self.createProcessDiagram(name, description, null, currentAlternative, type, moduleTypes);
		    }
		};
    	
		createProcessDiagram(name, description, ecoMapPackage, currentAlternative, type) {
	        var self = this;
	        var saveWorkspace = !ecoMapPackage;
	        DataManager.getDataManager().clearSaveInterval();
	        if (!ecoMapPackage) {
				ecoMapPackage = ValueDeliveryModel.createPackageWithName(name, description, currentAlternative, null);
				DataManager.getDataManager().get('currentWorkspace').save();
				self.createProcessDiagramDiagram(ecoMapPackage, name, description, null);
	        } else {
	            self.createProcessDiagramDiagram(ecoMapPackage, name, description, null);
	        }
	    };

	    createProcessDiagramDiagram(pack, name, description, valternative) {
	        var self = this;
	        var altId = DataManager.getDataManager().get('viewAlternative');
	        if (altId) {
	            DataManager.getDataManager().getAlternative(altId, function (alternative) {
	                var diagramInstance = ProcessDiscoveryDiagram.getInstance(name, description, pack);
	                self.copyDataToProcess(diagramInstance); 
	            });
	        }
	    }
        copyDataToProcess(diagramInstance) {
            var self =this;
				var xml = self.model.get('data');
				if (xml) {
					var xmlDoc = jQuery.parseXML(xml);
					var $xml = jQuery(xmlDoc);
					var nodes = $xml.find('[id="' + self.model.id + '"]');
					var nodes1 = $xml.find('[bpmnElement="' + self.model.id + '"]')
					nodes.each(function (callback, node) {
						node.setAttribute('id', diagramInstance.get('id'));
					})
					nodes1.each(function (callback, node) {
						node.setAttribute('bpmnElement', diagramInstance.get('id'));
					})
					xml = (new XMLSerializer()).serializeToString(xmlDoc);
				}
				diagramInstance.set('data', xml);
                //Copy suggestions
                var suggestions = self.model.get('suggestion');
                if(suggestions){
                    diagramInstance.set('suggestion', suggestions);
                }
				/*TODO when doing mapping
				if(self.model.get('businessModel').length>0){
					_.each(self.model.get('businessModel').models,function(bm){
						diagramInstance.get('businessModel').push(bm);
					});	
				}
				if(self.model.get('participant').length>0){
					_.each(self.model.get('participant').models,function(part){
						diagramInstance.get('participant').push(part);
					});	
				}
				if(self.model.get('valueProposition').length>0){
					_.each(self.model.get('valueProposition').models,function(vp){
						diagramInstance.get('valueProposition').push(vp);
					});	
				}*/
			}
	    
		deleteProcessDiagram(){
			var self = this;
			window.vdmModelView.enableComplete(false);
			if(window.checkContextForRevision()) {
				var ecoPackage = self.model.getNestedParent();
				var depPacks = ecoPackage.get('dependentPackage')?ecoPackage.get('dependentPackage').models.concat():[];
				/*if(ecoPackage.get('diagrams').length === 1) {
					var alternativeModel = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
					var phaseDesignPartRef = alternativeModel.get('phaseDesignPart').findWhere({'beepReference':ecoPackage.id});
					phaseDesignPartRef && phaseDesignPartRef.destroy();
					DataManager.getDataManager().get('router').navigate(DataManager.getDataManager().get('viewAlternative'), {trigger: true});
				}else {*/
					self.createRevisionBasedOnContext(ecoPackage,depPacks,function(newEcoPackage){
						var len = depPacks.length;
						while(len--){
							newEcoPackage.get('dependentPackage').remove(depPacks[len]);
						}
						var bmId = self.model.id.substr(self.model.id.lastIndexOf('@')+1,self.model.id.length);
			       		var altId = DataManager.getDataManager().getRepositoryId(newEcoPackage.id);			                	
			   			var ecoToBeDeleted = newEcoPackage.get('diagrams').findWhere({'id':altId+bmId});
			   			ecoToBeDeleted.destroy();
			   			DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});	
					});

				/*}*/
			}else {	
				if(self.model.get('data')){					
					bootbox.confirm(''+DataManager.getDataManager().get('localeManager').get('ProcessDiagramDelete'), function(result) {
					if(result) {
						self.model.destroy();
						DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});			        									    				    	
					}    
					});
				}else{					
					self.model.destroy();
					DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});
				}
			}
		};
        createRevisionBasedOnContext(pack, skipDependentPackages, callback) {
                var self = this;
				if (window.checkContextForRevision()) {
					window.utils.startSpinner('revisionSpinner', "Creating a copy...");
					function fetchBmModel(newVdmPackage) {
						if (newVdmPackage) {
							DataManager.getDataManager().releaseSaveLock();
							DataManager.getDataManager().set('isActive', false);
							window.utils.stopSpinner('revisionSpinner');
							callback(newVdmPackage);
							/*DataManager.getDataManager().saveData({
								success: function () {
									window.utils.stopSpinner('revisionSpinner');
									callback(newVdmPackage);
								},
								error: function () {
									window.utils.stopSpinner('revisionSpinner');
									callback(newVdmPackage);
								},
								persist: true
							});*/
						}
					}
					window.setTimeout(function () {
						DataManager.getDataManager().acquireSaveLock(function () {
							pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel, skipDependentPackages);
						});
					}, 100);
				}
				else {
					callback(pack);
				}
			};
		
		checkUniqueValue(view,event){
			var self=this;
			var name = event.currentTarget.value.trim();
			if(name !== ""){
				if(self.model != null && self.model.get('name') === name){
					$('#duplicateName'+self.encodeId).text("");
					$("#complete").removeAttr('disabled');
					return;
				}
	    		var unique = window.isUniqueName(name,null,self.id(),self.model.getNestedParent().get('diagrams'));
	    		if(unique){
	    			$('#duplicateName'+self.encodeId).text("");
	    			$("#complete").removeAttr('disabled');
	    		}
	    		else{
					var lcManager = DataManager.getDataManager().get('localeManager');
                    $('#duplicateName' + self.encodeId).text(lcManager.get('localeManager').get('DuplicateAlert',lcManager.get('ProcessModel')));
	    			$("#complete").attr('disabled','disabled');
	    		}
			}
			else{
				$("#complete").attr('disabled','disabled');
			}
        };       
		afterRenderView(){ 
        }

		init(model, options){
			var self = this;
			this.ProcessDiagramDetailsViewModel = this;
			this.deleteProcessDiagram = _.bind(this.deleteProcessDiagram,self);
			this.model = model.model;
			this.parentView = model;
			this.id = kb.observable(self.model, 'id');
			function htmlEscape(str) {
				return String(str)
					.replace(/@/g, '')
					.replace(/ /g, '');
			}
			this.encodeId = htmlEscape(this.id());
			this.enableDelete = ko.observable(true);
			this.enableComplete = ko.observable(true);
			this.name = ko.observable(self.model ? self.model.get('name') : null);
			this.description = ko.observable(self.model ? self.model.get('description') : "");
			this.currentAlt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
			this.name.subscribe(function (val) {
				self.description(val);
			});
			if (DataManager.getDataManager().get('viewAlternative') !== DataManager.getDataManager().get('currentWorkspace').get('id')) {
				$('#copyProcessDiagram').hide();
				$("#importProcessDiagram").hide();
			}

			if(window.checkContextForRevision()) {
				self.enableDelete(false);
			}
		}
		static getInstance(model,options){
			var view = new ProcessDiagramDetailsViewModel(model, options);
			//view.init(model, options);
			return view;
		};
}
path.ProcessDiagramDetailsViewModel = ProcessDiagramDetailsViewModel;
