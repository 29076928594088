import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox  from '../../../../../../libs/bootbox/bootbox'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { EcoMapDetailsViewModel } from './EcoMapDetailsViewModel'

//define(["require", "jquery", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "appbo/beeppackage/BeepPackageMixin", "appbo/transformation/PackageReference", "appcommon/com/vbee/filesystem/PackageReference", "appbo/vdml/ValueStreamMapDiagram", "appbo/ecomap/EcoMap", "app/global", "appviews/valueStreamMap/views/designer/ProcessTree"],
//	function (require, $, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, BeepPackageMixin, PlanPackageReference, PackageReference, ValueStreamMapDiagram, EcoMap, global, ProcessTree)
//{
	var path = DataManager.getDataManager().buildAppNsPath("ecoMap.views.designer",global.version);
export class EcoMapDetails2ViewModel extends EcoMapDetailsViewModel {
	

	init(model, options){
		var self = this;
		super.init(model,options);
	}
    static getInstance(model, options){
        var view = new EcoMapDetails2ViewModel(model, options);
        view.init(model, options);
        return view;
    };
}
path.EcoMapDetails2ViewModel = EcoMapDetails2ViewModel;
