import * as _ from 'underscore'
import { ValueLibraryMixin } from "./ValueLibraryMixin";
import { VdmlElement } from './VdmlElement';
import { VdmlElementMixin } from "./VdmlElementMixin";
import { VdmlElement2Mixin } from './VdmlElement2Mixin';
import { Unit } from "./Unit";
import { ProjectionProfile } from './ProjectionProfile'
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

export class ValueLibrary2Mixin{
	getMixinClass(){
		return ValueLibrary2Mixin;
	}
    static getMixinRelations(){
        return _.union(ValueLibraryMixin.getMixinRelations(),[
            {
				type: Backbone.HasMany,
				containingClass: "vdml_Unit",
				key: "unit",
				relatedModel: "vdml.Unit",
				reverseRelation: {
					key: "unitOwner",
					type: Backbone.HasOne,
					includeInJSON: "id"
				}
			},
			/*{
                type :Backbone.Hasmany,
                containingClass:"vdml_ValueLibrary",
                key:"projectionProfile",
                relatedModel:"vdml.ProjectionProfile",
                reverseRelation: {
                    key:"projectionProfileOwner",
                    type :Backbone.HasOne,
                    includeInJSON:"id"
                }
            }*/
        ]);
    }
	
	
	static getCumulativeMixinRelations(){
		if (!ValueLibraryMixin.cummulativeRelations) {
			ValueLibraryMixin.cummulativeRelations = _.union(ValueLibrary2Mixin.getMixinRelations()
				, DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
			);
		}
		return ValueLibraryMixin.cummulativeRelations.slice();
	}
	static getMyInstance = function (cls,name, description, vdm, id) {
		var dataManager = DataManager.getDataManager();
		var vlId = id ? id : dataManager.guidGeneratorByOwner(vdm);
		var vl = new cls({
			'id': vlId,
			'name': name,
			'description': description,
			'version': vdm.get('version'),
			'valueLibraryOwner': vdm
		});
		dataManager.get("currentPlan").get("valueLibrary").add(vl);
		return vl;
	};
	static getDialogViewProperties(type){
		return {
			templatePath: "views/valueLibrary/properties/ValueLibrary2Template.html",
			templateName: "ValueLibrary2Template",
			viewTypeStr: "appviews/valueLibrary/properties/ValueLibrary2ViewModel",
			tabId: "ValueLibrary2View",
			tabName: "ValueLibrary2"
		}
	}
}
utils.customExtendClass (ValueLibrary2Mixin,new ValueLibraryMixin());

path.ValueLibrary2Mixin = ValueLibrary2Mixin;