import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { VdmlElement } from './VdmlElement'
import { PracticeCategoryMixin } from './PracticeCategoryMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { PracticeDefinition } from './PracticeDefinition'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/PracticeCategoryMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,PracticeCategoryMixin
, PracticeDefinition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!PracticeDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PracticeDefinition){
			PracticeDefinition = importPath.PracticeDefinition;
		}
        else {
            import('./PracticeDefinition').then(({ default: PracticeDefinition }) => {
                PracticeDefinition = PracticeDefinition;
            });
			/*require(["appbo/vdml/PracticeDefinition"],function(loadedModule){
				PracticeDefinition = loadedModule;
			});*/
		}
	}
	var PracticeCategory = VdmlElement.extend(utils.customExtends({
		relations:PracticeCategoryMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new PracticeCategoryMixin()
	));
	
	PracticeCategory.abstract = false;
	PracticeCategory.supported = false;
	path.PracticeCategory = PracticeCategory;
    export { PracticeCategory };
//});