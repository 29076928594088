import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import {DataManager} from '../../../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global"],
//function(require,$,_, Backbone,ko, koMapping,kb,bootbox,DataManager,global){
	var path = DataManager.getDataManager().buildAppNsPath("beeppackage.views.properties",global.version);
	export class DiscoverViewModel {
    constructor(model,options){
		var self = this;
		this.DiscoverViewModel = this;
		this.model = model;
		this.name = ko.observable("");
		this.description = ko.observable("");
		this.localeManager = DataManager.getDataManager().get('localeManager');
		this.ecoMapTitle = self.localeManager.get("BusinessEcosystemMap");
		this.strategyMapTitle = self.localeManager.get("StrategyMap");
		this.valueStreamMapTitle = self.localeManager.get("ValueStreamMap");
		//this.canvasTitle = DataManager.getDataManager().get('localeManager').get("BusinessCanvas");
		this.analyticsTitle = self.localeManager.get("Dashboard");
		this.reportTitle = self.localeManager.get("Report");
		if(options && options.parentView){
			this.parentView = options.parentView;
			this.encodeId = ""+options.modalId;
			this.alternativeModel = options.addOptions.alternative;
			this.plusIconType = options.addOptions.plusIconType;
		}
		this.enableComplete = ko.observable(false);
		this.enablePackage = ko.observable(true);
		this.vocabularyPackage = ko.observable(false);
		this.ecoMapPackage = ko.observable(false);
		this.analyticsPackage = ko.observable(false);
		//this.enableCanvasType = ko.observable(false);
		var allowedTypes = [];
		var canvases = []; 
		var moduleTypes = {};  
		this.types = ko.observableArray();

		this.addCanvasAllowedTypes = function(){
			var dataManager = DataManager.getDataManager();
			var leId = dataManager.get('currentLegalEntity').entityId;
			var canvasList = dataManager.get("canvasModules")[leId];
			if(canvasList) {
				for(var i=0;i < canvasList.length; i++){
					var modConfig = canvasList[i].artifact[0].code;
					var modObj = JSON.parse(modConfig);
					if (modObj.allowCreation && modObj.allowCreation === true) {
						if(modObj.name == "Lean Startup Canvas"){
							moduleTypes[modObj.name] = "LeanStartupCanvas";//temp
						} else {
							moduleTypes[modObj.name] = modObj.type;
						}
						allowedTypes.push(modObj.name);
					}
				}
			}
			allowedTypes = _.uniq(allowedTypes);
			/*var loadedModules = DataManager.getDataManager().canvasModules[DataManager.getDataManager().get('currentPlan').id];
			var modules = Object.getOwnPropertyNames(loadedModules);
			_.each(modules, function (modName) {
				var modConfig = loadedModules[modName];
				var modObj = JSON.parse(modConfig);
				if (modObj.allowCreation && modObj.allowCreation === true) {
					moduleTypes[modObj.name] = modObj.type;
					allowedTypes.push(modObj.name);
				}
			});*/
		};
		this.addBusinessCanvasTypes = function(limitCanvas,removeCanvas){
			var dataManager = DataManager.getDataManager();
			var leId = dataManager.get('currentLegalEntity').entityId;
			var canvasList = dataManager.get("canvasModules")[leId];
			var canvasArr = [];
			if(canvasList) {
				for(var i=0;i < canvasList.length; i++){
					var modConfig = canvasList[i].artifact[0].code;
					var modObj = JSON.parse(modConfig);
					if (modObj.allowCreation && modObj.allowCreation === true) {
						if (limitCanvas && limitCanvas.includes(modObj.type)) {
							canvasArr.push(modObj.name);
						}
						if(removeCanvas && !removeCanvas.includes(modObj.type)){
							canvasArr.push(modObj.name);
						}
					}
				}
			}
			canvasArr = _.uniq(canvasArr);
			canvasArr.map(can => self.types.push(can));			
			/*var loadedModules = DataManager.getDataManager().canvasModules[DataManager.getDataManager().get('currentPlan').id];
			var modules = Object.getOwnPropertyNames(loadedModules);
			_.each(modules,function(modName){
				var modConfig = loadedModules[modName];
				var modObj = JSON.parse(modConfig);
				if(modObj.allowCreation && modObj.allowCreation === true){
					self.types.push(modObj.name);
				}
			});*/
			canvases.sort();
		};
		

		self.addCanvasAllowedTypes();
		allowedTypes.push(self.strategyMapTitle);
		if (options.onlyMap){
			self.types.push(options.onlyMap);
		} else {
			/*self.types.push(self.strategyMapTitle);
			self.addBusinessCanvasTypes();
			self.types.push(self.ecoMapTitle);
			self.types.push(self.valueStreamMapTitle);
			self.types.push(self.analyticsTitle);
			self.types.push(self.reportTitle);	*/

			var targetLetter = 'S';
			var selectedArrow = $('#DiscoverHeading').find('.arrow_active')[0];
			if(selectedArrow && selectedArrow.id){
				targetLetter = selectedArrow.id.charAt(0);
				if(selectedArrow.id == "contextArrow"){
					targetLetter = "R";
					self.types.push(self.reportTitle);
					self.addBusinessCanvasTypes(['SWOTAnalysisCanvas']);
				}
				if(selectedArrow.id == "callToActionArrow"){
					targetLetter = "D";
					//self.addBusinessCanvasTypes();
					self.types.push(self.analyticsTitle);
					self.addBusinessCanvasTypes(['LeanChangeCanvas']);
				}
				if(selectedArrow.id == "valueCreationArrow"){
					targetLetter = "S";
					self.types.push(self.strategyMapTitle);
				} if(selectedArrow.id == "valueStreamArrow"){
					//targetLetter = "S";
					self.types.push(self.valueStreamMapTitle);
				} if(selectedArrow.id == "ecoSystemArrow"){
					self.types.push(self.ecoMapTitle);
					//self.addBusinessCanvasTypes();
					self.addBusinessCanvasTypes(false,['LeanChangeCanvas', 'SWOTAnalysisCanvas']);
				}
			}
			self.types().sort((a, b) => {
				const aStartsWithLetter = a.toLowerCase().startsWith(targetLetter.toLowerCase());
				const bStartsWithLetter = b.toLowerCase().startsWith(targetLetter.toLowerCase());
			  
				if (aStartsWithLetter && !bStartsWithLetter) {
					return -1;
				} else if (!aStartsWithLetter && bStartsWithLetter) {
					return 1;
				} else {
					// If both start with the target letter or neither does, sort them alphabetically.
					return a.localeCompare(b);
				}
			});
		}		

		if (DataManager.getDataManager().isDiscoverPurchased()){
			allowedTypes.push(self.ecoMapTitle);
			allowedTypes.push(self.valueStreamMapTitle);
			allowedTypes.push(self.analyticsTitle);
			allowedTypes.push(self.reportTitle);
		}
		//self.types.sort();
		this.selectedType = ko.observable();
		this.packages = ko.observableArray();
		this.analyticsPackages = ko.observableArray();
		this.ecoMapPackages =  ko.observableArray();
		//this.selectedCanvasType = ko.observable();
		//this.canvasTypes = ko.observableArray(canvases);
		this.canvasNames = [];
		this.reportNames = [];
		this.ecoMapNames = [];
		this.strategyMapNames = [];
		this.valueStreamMapNames = [];
		this.dashboardNames = [];
		this.capabilityLibraries =  ko.observableArray();
		this.selectedPackage = ko.observable();
		this.selectedVocabularyPackage = ko.observable();
		this.selectedEcoMapPackage = ko.observable();
		this.selectedAnalyticsPackage = ko.observable();
		
		$('#modal'+self.encodeId).on('shown.bs.modal', function () {
	          window.utils.focusElement("#newModal .focus-ele");
	    });
	    
        this.selectedType.subscribe(function (val) {
            if (val === 'Package' || val === self.reportTitle) {
                //self.enableBM(false);
                //self.enableCanvasType(false);
                self.enablePackage(false);
                self.ecoMapPackage(false);
                self.analyticsPackage(false);
            }
            /*else if (val === self.canvasTitle) {
               //self.enableCanvasType(true);
                self.enablePackage(true);
                self.ecoMapPackage(false);
                self.analyticsPackage(false);
            }*/
            else if (val === self.ecoMapTitle) {
                //self.enableCanvasType(false);
                self.enablePackage(false);
                self.analyticsPackage(false);
                self.ecoMapPackage(true);
            }
            else if (val === self.strategyMapTitle) {
                //self.enableCanvasType(false);
                self.enablePackage(false);
                self.analyticsPackage(false);
                self.ecoMapPackage(true);
            }
            else if (val === self.valueStreamMapTitle) {
                //self.enableCanvasType(false);
                self.enablePackage(false);
                self.analyticsPackage(false);
                self.ecoMapPackage(true);
            }
            else if (val === self.analyticsTitle) {
                //self.enableCanvasType(false);
                self.enablePackage(false);
                self.analyticsPackage(true);
                self.ecoMapPackage(false);
            }
	    	else {
	    	    //self.enableCanvasType(false);
	    	    self.enablePackage(true);
	    	    self.ecoMapPackage(false);
	    	    self.analyticsPackage(false);
	    	}
	    	checkUniqueName(self.name());
	    });

	    this.selectedEcoMapPackage.subscribe(function (val) {
	        checkUniqueName(self.name());
	    });

	    this.selectedAnalyticsPackage.subscribe(function (val) {
	        checkUniqueName(self.name());
	    });
	    this.name.subscribe(function(val){
        	self.description(val);
        });

        if (self.alternativeModel) {
            var setPackage = function (package1) {
                var referenceExists = self.alternativeModel.get('phaseDesignPart').findWhere({ beepReference: package1.get('id') });
                if (!referenceExists || package1.get('id').indexOf("Common") > -1) {
                    return;
                }
                else {
                    var name = package1.get('name');
                    var packageModel = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: package1.id });
                    self.packages.push({ id: package1.get('id'), name: name, version: package1.get('version') });
                }
            };

            function loadEcoMapPackage(ecoMapPackage) {
                var referenceExists = self.alternativeModel.get('phaseDesignPart').findWhere({ beepReference: ecoMapPackage.get('id') });
                if (!referenceExists || ecoMapPackage.get('id').indexOf("Common") > -1) {
                    return;
                } else {
                    if (options.onlyMap){
                		if(referenceExists.get('beepReference').substring(0,referenceExists.get('beepReference').lastIndexOf('@')+1) !== self.alternativeModel.get('id') ){
                			return;
                		}
                	}
                    self.ecoMapPackages.push({ id: ecoMapPackage.get('id'), name: ecoMapPackage.get('name'), version: ecoMapPackage.get('version') });
                }
            }
            function handleAddClick(commonAlt) {
                function loadDocuments(results) {
                    for (var i = 0; i < results.length; i++) {
                        var type = results.at(i).get('type');
                        if (type === "ecomap_EcoMap") {
                            loadEcoMapPackage(results.at(i));
                        } else if (type === "vdml_EcoMapDiagram") {
                            self.ecoMapNames.push({ id: results.at(i).get('id'), name: results.at(i).get('name'), parent: results.at(i).get('parent') });
                        }else if (type === "vdml_StrategyMapDiagram") {
                            self.strategyMapNames.push({ id: results.at(i).get('id'), name: results.at(i).get('name'), parent: results.at(i).get('parent') });
                        } else if (type === "vdml_ValueStreamMapDiagram") {
                            self.valueStreamMapNames.push({ id: results.at(i).get('id'), name: results.at(i).get('name'), parent: results.at(i).get('parent') });
                        }else if (type === "canvas_BMCanvasDiagram") {
                            self.canvasNames.push({ name: results.at(i).get('name'), parent: results.at(i).get('parent') });
                        } else if (type === "vdml_ValueDeliveryModel") {
                            setPackage(results.at(i));
                        } else if (type === "dashboard_Dashboard") {
                            self.dashboardNames.push({ id: results.at(i).get('id'), name: results.at(i).get('name'), parent: results.at(i).get('parent') });
                        } else if (type === "dashboard_Analytics" && results.at(i).get('parent')) {
                            self.analyticsPackages.push({ id: results.at(i).get('id'), name: results.at(i).get('name'), version: results.at(i).get('version') });
                        }else if (type === "report_BeepReport") {
                            self.reportNames.push({ name: results.at(i).get('name'), parent: results.at(i).get('parent') });
                        }
					}
                }
               
				var searchTypes = '';
				if (DataManager.getDataManager().isDiscoverPurchased()){
					searchTypes = 'report_BeepReport,vdml_ValueStreamMapDiagram,dashboard_Dashboard,dashboard_Analytics,ecomap_EcoMap,vdml_EcoMapDiagram,vdml_StrategyMapDiagram,canvas_BMCanvasDiagram,vdml_ValueDeliveryModel';
				} else {
					searchTypes = 'canvas_BMCanvasDiagram,vdml_StrategyMapDiagram,ecomap_EcoMap,vdml_ValueDeliveryModel';
				}
				/*if (self.plusIconType == "contextArrow") {
					searchTypes = 'report_BeepReport,canvas_BMCanvasDiagram,vdml_ValueDeliveryModel';
				}
				else if (self.plusIconType == "ecoSystemArrow") {
					searchTypes = 'ecomap_EcoMap,vdml_EcoMapDiagram,canvas_BMCanvasDiagram,vdml_ValueDeliveryModel';
				} else if (self.plusIconType == "valueStreamArrow") {
					searchTypes = 'vdml_ValueStreamMapDiagram,ecomap_EcoMap';
				} else if (self.plusIconType == "valueCreationArrow") {
					searchTypes = 'vdml_StrategyMapDiagram,ecomap_EcoMap';
				} else if (self.plusIconType == "callToActionArrow") {
					searchTypes = 'dashboard_Dashboard,dashboard_Analytics,canvas_BMCanvasDiagram,vdml_ValueDeliveryModel';
				} else {
					searchTypes = 'report_BeepReport,vdml_ValueStreamMapDiagram,dashboard_Dashboard,dashboard_Analytics,ecomap_EcoMap,vdml_EcoMapDiagram,vdml_StrategyMapDiagram,canvas_BMCanvasDiagram,vdml_ValueDeliveryModel';
				}*/
                var nameNS = 'vbc:cmof_EObject-name';
                function loadDiscoveryDocuments(results) {
                    self.packages([]);
                    loadDocuments(results);
                    self.ecoMapPackages.push({ name: "New" });
                    self.packages.push({ name: "New" });
                    self.analyticsPackages.push({ name: "New" });
                }
                DataManager.getDataManager().getAllDocumentsCollectionOfType(self.alternativeModel, searchTypes, loadDiscoveryDocuments, true, null, null, nameNS, commonAlt);

            }

            var planId = self.model.id.substr(self.model.id.lastIndexOf('@'));
            var commonAltId = planId + "-Common@";
            function waitAndLoadRDFModel() {
                var commonRdfModel = DataManager.getDataManager().getRDFModel(commonAltId);
                if (commonRdfModel) {
                    handleAddClick(commonAltId);
                } else {
                    setTimeout(waitAndLoadRDFModel, 5);
                }
            }
            waitAndLoadRDFModel();

        }		
		
		this.cleanModal = function(){
			window.cleanDialogModel(self.encodeId,self);	 
		}
		
		this.createPackage = function(){
			if(self.parentView){
				if(self.name() !== null && self.name() !== "") {
					if(allowedTypes.indexOf(self.selectedType()) === -1){
						if(self.selectedType() === "Business Canvas") {
							var loadedModules = DataManager.getDataManager().get("canvasModules");
							var modules = Object.getOwnPropertyNames(loadedModules);
							if(modules.length <= 0){
								bootbox.alert(self.localeManager.get("importBusinessCanvasTypes"));	
							}else{
								window.utils.limitExceeded();
							}
						}else {
                            window.utils.limitExceeded();
						}
						window.cleanDialogModel(self.encodeId,self);
						return false;
					}else {
						if(self.selectedType() === "Business Canvas"){
							return options.callback(self, self.encodeId, self.selectedType(),moduleTypes);
						}else{
							return options.callback(self,self.encodeId,self.selectedType(),moduleTypes);
						}
					}		
				}
			}
		}
		function checkUniqueName(name){
			var items = [];
			if(self.selectedType() === self.reportTitle){
				items = self.reportNames;
			}else if(self.selectedType() === self.ecoMapTitle){
				items = self.ecoMapNames;
			}else if(self.selectedType() === self.strategyMapTitle){
				items = self.strategyMapNames;
			}else if(self.selectedType() === self.valueStreamMapTitle){
				items = self.valueStreamMapNames;
			}else if (self.selectedType() === self.analyticsTitle) {
			    items = self.dashboardNames;
			} else {
				items = self.canvasNames;
			}

			for(var i=0;i<items.length;i++){			
				if(self.selectedType() === self.reportTitle && items[i].name === name.trim()){
					$('#duplicateName').text(self.localeManager.get('DuplicateAlert', self.selectedType()));
					self.enableComplete(false);
					return;
				}
				else if(self.selectedType() === self.ecoMapTitle && items[i].name === name.trim() && items[i].parent === self.selectedEcoMapPackage().id){
                    $('#duplicateName').text(self.localeManager.get('DuplicateAlert', self.selectedType()));
					self.enableComplete(false);
					return;
				}
				else if(self.selectedType() === self.strategyMapTitle && items[i].name === name.trim() && items[i].parent === self.selectedEcoMapPackage().id){
                    $('#duplicateName').text(self.localeManager.get('DuplicateAlert', self.selectedType()));
					self.enableComplete(false);
					return;
				}else if(self.selectedType() === self.valueStreamMapTitle && items[i].name === name.trim() && items[i].parent === self.selectedEcoMapPackage().id){
                    $('#duplicateName').text(self.localeManager.get('DuplicateAlert', self.selectedType()));
					self.enableComplete(false);
					return;
				} else if (self.selectedType() === self.analyticsTitle && items[i].name === name.trim() && items[i].parent === self.selectedAnalyticsPackage().id) {
                    $('#duplicateName').text(self.localeManager.get('DuplicateAlert', self.selectedType()));
				    self.enableComplete(false);
				    return;
				} else if(items[i].name === name.trim() && items[i].parent === self.selectedPackage().id){
					$('#duplicateName').text(self.localeManager.get('DuplicateAlert', self.selectedType()));
					self.enableComplete(false);
					return;
				}

			}
			$('#duplicateName').text("");
			if(name.trim() !== "" && self.selectedType()){
				self.enableComplete(true);				
			}else{
				self.enableComplete(false);
			}
		}
		this.afterRenderView = function(){
			$("#packageName"+self.encodeId).keyup(function (view) {
                var newValue = view.currentTarget.value;
                if(newValue.trim() !== '') {
                    checkUniqueName(newValue);
                }
                else {
                	self.enableComplete(false);
                }
			});
			$('#editDetailsModalContent' + self.encodeId).resize(function () {
				$('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
			})
            if (options.onlyMap){
            	$('#type').attr('disabled',true);
            }
            window.utils.applyFontSizeInDialog();
			//self.loadBMs();
		}
				
//#endCustomMethods
		
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),[
		,'name'
		,'description'
		,'Complete'
		,'Close'
		,'selectType'
		,'selectPackage'
		,'CreateModel'
		,'selectCanvasType'
		,'importBusinessCanvasTypes'
		,'selectPlanBusinessModel']);
	}
	
	static getInstance(model,options){
		var view = new DiscoverViewModel(model,options);
		return view;
	}
}
path.DiscoverViewModel = DiscoverViewModel;