import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {ValueElement} from './ValueElement'
import {ValueElementMixin} from './ValueElementMixin'
import {MeasuredCharacteristic} from './MeasuredCharacteristic'
import {Measure} from '../smm/Measure'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/ValueElement","appbo/vdml/ValueElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,ValueElement,ValueElementMixin
, MeasuredCharacteristic
, Measure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
		else{
			import('./MeasuredCharacteristic').then(loadedModule => {
				MeasuredCharacteristic = loadedModule;
			});
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	if(!Measure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measure){
			Measure = importPath.Measure;
		}
		else{
			import('../smm/Measure').then(loadedModule => {
				Measure = loadedModule;
			});
			/*require(["appbo/smm/Measure"],function(loadedModule){
				Measure = loadedModule;
			});*/
		}
	}	
	export class ValuePropositionComponentMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.ValuePropositionComponentMixin = ValuePropositionComponentMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_ValuePropositionComponent"
		}
		return jQuery.extend(ValueElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"vdml_ValuePropositionComponent",
			key:"percentageWeight",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"percentageWeightOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_ValuePropositionComponent",
			key:"satisfactionLevel",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"satisfactionLevelOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_ValuePropositionComponent",
			key:"recipientOpinion",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"recipientOpinionOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!ValuePropositionComponentMixin.cummulativeRelations) {
            ValuePropositionComponentMixin.cummulativeRelations = _.union(ValuePropositionComponentMixin.getMixinRelations()
                , ValueElementMixin.getCumulativeMixinRelations()
            );
        }
		return ValuePropositionComponentMixin.cummulativeRelations.slice();
    }
	static getSuperTypes(){
		return [
			"vdml_ValueElement"
		];
	};
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("componentOwner") ? this.get("componentOwner") : this.previousAttributes().componentOwner;
			if(container){
				return container;
			}
		}
		return this;
	};
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("componentOwner") ? this.get("componentOwner") : this.previousAttributes().componentOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ValuePropositionComponentPropertiesTemplate.html",
			templateName : "ValuePropositionComponentPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ValuePropositionComponentViewModel",
			tabId : "ValuePropositionComponentView",
			tabName: "ValuePropositionComponent"
		};
	};
//#startCustomMethods

	destroyAssosciatedMeasurement(){
		var self = this;
		self.get('satisfactionLevel').destroy();
    	self.get('percentageWeight').destroy();
    	self.get('recipientOpinion').destroy();
		ValueElement.prototype.destroyAssosciatedMeasurement.apply(this, arguments);
	};
	
	/*ValuePropositionComponentMixin.prototype.destroySatisfactionMeasure(satisfactionObsMes, valueObsMes, callback){
		var self = this;
		var satisfactionObsMesId = satisfactionObsMes.get('id');
		var valueMeasureId = valueObsMes.get('measure').get('id');
		var satisfactionMeasure = satisfactionObsMes.get('measure');
		var observedSatisfactionMeasures = satisfactionMeasure.get('observedMeasure');
		var mcharDef = satisfactionMeasure.get('trait');
		if(satisfactionMeasure) {
			DataManager.getDataManager().getReferencesToModel(satisfactionMeasure.get('id'),function(result){debugger
				var len = result.length;
				var satisfactionUnit = satisfactionMeasure.get('unit');
				var satisfactionUnitId = satisfactionUnit?satisfactionUnit.get('id'):null;
	            while (len--) {
	                if(result[len].id === satisfactionMeasure.get('libraries').get('id') || result[len].id === satisfactionUnitId || result[len].id === satisfactionMeasure.get('scope').get('id')) {
	                    result.splice(len,1);
	                }
	                else if(result[len].id === mcharDef.get('id') || result[len].id === self.get('satisfactionLevel').get('id') || result[len].id === satisfactionObsMesId || result[len].id === valueMeasureId){
	                	result.splice(len,1);
	                }
	                else {
	                	for(var i=0;i<observedSatisfactionMeasures.length;i++){
	                		if(result[len].id === observedSatisfactionMeasures.at(i).get('id') || result[len].id === observedSatisfactionMeasures.at(i).get('measure').get('id')){
	                			result.splice(len,1);
	                		}
	                	}
	                }
	            }  
	            if(satisfactionUnit) {
					self.destroySatisfactionMeasureUnit(satisfactionUnit,satisfactionMeasure.get('id'));
				}
	            if(result.length==0) {
	        		satisfactionMeasure.destroy();
	        		callback(mcharDef);
	        	}
	        	
			});
		}
	}
	
	ValuePropositionComponentMixin.prototype.destroySatisfactionMeasureUnit(satisfactionUnit,satisfactionMeasureId){
		/*if(satisfactionUnit && satisfactionUnit.get('libraries')) {
			var satisfactionLibId = satisfactionUnit.get('libraries').get('id') ;
			DataManager.getDataManager().getReferencesToModel2(satisfactionUnit,function(result){
				var len = result.length;
		        while (len--) {
		            if(result[len].id === satisfactionLibId || result[len].id === satisfactionMeasureId) {
		                result.splice(len,1);
		            }
		        }    
		        if(result.length==0) {
		    		satisfactionUnit.destroy();
		    	}
			});
		}
	}*/	
	
	/*ValuePropositionComponentMixin.prototype.destroyWeightMeasure(weightObsMes, valueObsMes){
		var self = this;
		var weightObsMesId = weightObsMes.get('id');
		var valueMeasureId = valueObsMes.get('measure').get('id');
		var weightMeasure = weightObsMes.get('measure');
		var observedWeightMeasures = weightMeasure.get('observedMeasure');
		var weightMc = weightMeasure.get('trait');
		if(weightMeasure) {
			DataManager.getDataManager().getReferencesToModel(weightMeasure.get('id'),function(result){debugger
				var len = result.length;
	            while (len--) {
	                if(result[len].id === weightMeasure.get('libraries').get('id') || result[len].id === weightMeasure.get('scope').get('id') || result[len].id === weightMeasure.get('unit').get('id')) {
	                    result.splice(len,1);
	                }
	                else if(result[len].id === weightMc.get('id') || result[len].id === self.get('percentageWeight').get('id') || result[len].id === weightObsMesId || result[len].id === valueMeasureId){
	                	result.splice(len,1);
	                }
	                else {
	                	for(var i=0;i<observedWeightMeasures.length;i++){
	                		if(result[len].id === observedWeightMeasures.at(i).get('id') || result[len].id === observedWeightMeasures.at(i).get('measure').get('id')){
	                			result.splice(len,1);
	                		}
	                	}
	                }
	            }  
	            if(result.length==0) {
	            	if(weightMc.get('measure').length === 1) {
	            		weightMc.destroy();
	            	}
	        		weightMeasure.destroy();
	        	}
	        	
			});
		}
	}*/
	
	updateBaseMeasureTupleSet(baseMesureTupleSet,tupleOld,tupleNew){	
		if(tupleOld){
			var matchIndex;
			_.each(baseMesureTupleSet,function(tuple,index){
				if(!matchIndex && tuple.measure === tupleOld.measure){
					matchIndex = index;
				}
			});
			if(matchIndex){
				baseMesureTupleSet.splice(matchIndex,1);
				if(tupleNew){
					baseMesureTupleSet.push(tupleNew);	
				}
			}
		}else{
			baseMesureTupleSet.push(tupleNew);
		}
	}
	
	
	update(name,value,valueUnit,valFormula,satisfactionType,isDimensional,satisfactionValue,satisfactionUnit,weight,recipientValue,accumulator,baseTuples,intervalTuples,timestamp,viewAlternative,maxChange,currentBM,expression,callback){
		var self = this;
		var replaceSatMeasurement = false;
		var updateOtherSatScenarios = false;
		var replaceSatMeasure = false;
		var destroyOldSatMeasure = false;
		var rescaleSat = false;
		var calculateSat = false;
		var changeInValue = false;
		var obsMesScenario;

		var obsMesSatisfactionCurrentScenario;
		var obsMesSatisfactionScenario;
		var measureSatisfaction;
		var mestWeight;
		var obsMesWeightCurrentScenario;
		var scenario = viewAlternative.getMainScenario(self.getNestedParent());
		var commonScenario = DataManager.getDataManager().get('currentCommonScenario');
		var  useCommon = (commonScenario === scenario) ? true :false ;
		var beepPackage = self.getNestedParent();
		var valueMeasurement = self.get('valueMeasurement');
		var satisfactionLevel = self.get('satisfactionLevel');
		var percentageWeight = self.get('percentageWeight');
		var recipientMC = self.get('recipientOpinion');
		var deleteValueMeasurment = false;
		var isTimeStamp = false;
		if(timestamp){
			isTimeStamp = true;
		}
		
		valueMeasurement.determineEffectiveScenario(useCommon,beepPackage,viewAlternative.get('id'),function(currentScenario){
			DataManager.getDataManager().getMainScenario(beepPackage,viewAlternative.get('id'),function(mainScenario){
				/*obsMesSatisfactionCurrentScenario = currentScenario.getObservedMeasureWithMeasurements(satisfactionLevel,isTimeStamp,timestamp);
				if(obsMesSatisfactionCurrentScenario){
					measureSatisfaction = obsMesSatisfactionCurrentScenario.get('measure');	
					var oldDimensional = measureSatisfaction ? measureSatisfaction.get('measureRelationships').length > 0:null;
					var valueObsMes = currentScenario.getObservedMeasureWithMeasurements(valueMeasurement,isTimeStamp,timestamp);
					if(measureSatisfaction && isDimensional !== oldDimensional && !isDimensional){//normal to grade-only
						deleteValueMeasurment = true;
					}
					else if(isDimensional && isDimensional !== oldDimensional && !valueObsMes) {//grade-Only to normal value(without base tuple)
                        valueMeasurement = self.primeValueMeasurement(valueMeasurement, self, value, valueUnit, valFormula, accumulator, [], timestamp, viewAlternative, maxChange, false, expression);
						deleteRecipientMeasurement();
                        var recipientMeasurement = self.primeValueMeasurement(recipientMC, self, recipientValue, valueUnit, null, accumulator, [], timestamp, viewAlternative, maxChange, true, expression);
					}
				}
				if(deleteValueMeasurment){
					var valueObsMes = currentScenario.getObservedMeasureWithMeasurements(valueMeasurement,isTimeStamp,timestamp);		
					if(valueObsMes){
						var valueMeasure = valueObsMes.get('measure');
						var trait = valueMeasure.get('trait');
						valueMeasure.destroy();
						if(trait.get('measure').length === 0) {
				            trait.destroy();
				        }
						var measurement = valueObsMes.get('measurements').at(0);
                        measurement.destroy();
                        valueObsMes.destroy();
					}
					//delete recipient measurement
					deleteRecipientMeasurement();
				}
				function deleteRecipientMeasurement(){
					var recipientObsMes = currentScenario.getObservedMeasureWithMeasurements(recipientMC,isTimeStamp,timestamp);		
					if(recipientObsMes){
						var recipientMeasure = recipientObsMes.get('measure');
						if(recipientMeasure){
							var trait = recipientMeasure.get('trait');
							recipientMeasure.destroy();
							if(trait && trait.get('measure').length === 0) {
								trait.destroy();
							}
						}
						var recMeasurement = recipientObsMes.get('measurements').at(0);
						recMeasurement.destroy();
						recipientObsMes.destroy();
					}
				}*/
				handleChanges(currentScenario,mainScenario);
			});
		});	

			

        function handleChanges(currentScenario, mainScenario) {
            if (!satisfactionLevel || !percentageWeight || !recipientMC) {
                self.getNestedParent().createValueDetails(self, null, intervalTuples.length > 0, weight, recipientValue);
                satisfactionLevel = self.get('satisfactionLevel');
                percentageWeight = self.get('percentageWeight');
                recipientMC = self.get('recipientOpinion');
                if (intervalTuples.length > 0) {
                    var valueObsMes = currentScenario.getObservedMeasureWithMeasurements(valueMeasurement, isTimeStamp, timestamp);
                    if (valueObsMes) {
						obsMesSatisfactionCurrentScenario = currentScenario.getObservedMeasureWithMeasurements(satisfactionLevel, isTimeStamp, timestamp);
						if(!obsMesSatisfactionCurrentScenario){
							var allObsMeasures = currentScenario.getAllObservedMeasureWithMeasurements(valueMeasurement);
							_.each(allObsMeasures,function(obsMeas){
								var whenObserved = obsMeas.getParent().get('whenObserved');
								if(whenObserved != timestamp){
									var scenarioMeasurement = obsMeas.get('measurements').at(0);
									self.primeSatisfactionLevel(satisfactionLevel, satisfactionType, isDimensional, satisfactionValue, satisfactionUnit, scenario, scenarioMeasurement, intervalTuples, whenObserved);
								}
							});	
							var measurement = valueObsMes.get('measurements').at(0);
							self.primeSatisfactionLevel(satisfactionLevel, satisfactionType, isDimensional, satisfactionValue, satisfactionUnit, scenario, measurement, intervalTuples, timestamp);
						}
                    }
                }
                if (weight && weight !== "" && percentageWeight) {
					obsMesWeightCurrentScenario = currentScenario.getObservedMeasureWithMeasurements(percentageWeight,isTimeStamp,timestamp);	
					if(!obsMesWeightCurrentScenario){
						var allObsMeasures = currentScenario.getAllObservedMeasureWithMeasurements(valueMeasurement);
						_.each(allObsMeasures,function(obsMeas){
							var whenObserved = obsMeas.getParent().get('whenObserved');
							if(whenObserved != timestamp){
								self.primeWeight(percentageWeight, weight, scenario, whenObserved);
							}
						});	
						self.primeWeight(percentageWeight, weight, scenario, timestamp);
					}
                }
				if (recipientValue && recipientValue !== "" && recipientMC) {
					var obsMesRecipientCurrentScenario = currentScenario.getObservedMeasureWithMeasurements(recipientMC,isTimeStamp,timestamp);	
					if(!obsMesRecipientCurrentScenario){
						var allObsMeasures = currentScenario.getAllObservedMeasureWithMeasurements(valueMeasurement);
						_.each(allObsMeasures,function(obsMeas){
							var whenObserved = obsMeas.getParent().get('whenObserved');
							if(whenObserved != timestamp){
								self.primeValueMeasurement(recipientMC, self, recipientValue, valueUnit, null, accumulator, [], whenObserved, viewAlternative, maxChange, true, expression);
							}
						});	
						self.primeValueMeasurement(recipientMC, self, recipientValue, valueUnit, null, accumulator, [], timestamp, viewAlternative, maxChange, true, expression);
					}
				}
            }
			//if(!skipValue){
                self.updateValueElementChanges(currentScenario, mainScenario, name, value, valueUnit, valFormula, accumulator, baseTuples, timestamp, viewAlternative, maxChange, expression,function(iObsMesScenario,iMestVM,iMeasureVM,icalculateSat,ichangeInValue){
					changeInValue = ichangeInValue;
					obsMesScenario = iObsMesScenario;
					calculateSat = icalculateSat;
					handleRecipientValueChange();
					handleSatisfactionAndWeight();
				});
			//}
			/*else {
				obsMesScenario = null;
				self.primeSatisfactionLevel(recipientMC,satisfactionType,isDimensional,recipientValue,null,scenario,null,[],timestamp);
				handleSatisfactionAndWeight();
			}*/
			function handleRecipientValueChange(){
				var valueObsMes = currentScenario.getObservedMeasureWithMeasurements(recipientMC,isTimeStamp,timestamp);
				var obsMesScenario = scenario.getObservedMeasureWithMeasurements(valueMeasurement,isTimeStamp,timestamp);
                /*var recipientMeasurement, recipientMeasure;
                if (recipientValue && !valueObsMes) {
                    var recipientMest;
                    if (isDimensional) {
                        var measurementComponent = self.primeValueMeasurement(valueMeasurement, self, value, valueUnit, valFormula, accumulator, baseTuples, timestamp, viewAlternative, maxChange, false, expression);
                        if (recipientValue && recipientValue != "") {
                            recipientMest = self.primeValueMeasurement(recipientMC, self, recipientValue, valueUnit, null, accumulator, [], timestamp, viewAlternative, maxChange, true, expression);
                        }
                    } else {
                        recipientMest = self.primeSatisfactionLevel(recipientMC, satisfactionType, isDimensional, recipientValue, null, scenario, null, [], timestamp);
                    }
                    valueObsMes = recipientMest.get('observedMeasure');
                }*/
				if(valueObsMes){
					var recipientMeasurement = valueObsMes.get('measurements').at(0);
					var recipientMeasure = valueObsMes.get('measure');	
					var roundingFactor = valueUnit?valueUnit.roundingDigits:null;
					if(recipientValue && !isNaN(recipientValue) && roundingFactor){
						recipientValue = parseFloat(recipientValue).toFixed(roundingFactor);
					}
					if(recipientMeasurement && recipientMeasurement.get('value') !== recipientValue){
						if(recipientMeasure.get('type') === "smm_GradeMeasure"){
							recipientMeasurement.set('symbol',recipientValue);
						}else{
							recipientMeasurement.set('value',recipientValue);
						 }
					}
					if(recipientMeasure && recipientMeasure.get('unit') && recipientMeasure.get('unit').get('name') !== valueUnit.unitName){
						var unit = beepPackage.findOrCreateUnit(valueUnit.unitName,valueUnit.unitObj);
						if(!(obsMesScenario && recipientMeasure.get('observedMeasure').length > 1)){
							recipientMeasure.set('unit',unit);
						}
					}
				}
			}
            function handleSatisfactionAndWeight() {
				obsMesSatisfactionCurrentScenario = currentScenario.getObservedMeasureWithMeasurements(satisfactionLevel,isTimeStamp,timestamp);
				obsMesSatisfactionScenario = scenario.getObservedMeasureWithMeasurements(satisfactionLevel,isTimeStamp,timestamp);
				if(obsMesSatisfactionCurrentScenario){
					measureSatisfaction = obsMesSatisfactionCurrentScenario.get('measure');					
				}			
				handleSatisfactionUnitChange(currentScenario);
				handleSatisfactionTypeChange(currentScenario,mainScenario,function(){
					handleSatisfactionIntervalsChange(currentScenario,mainScenario,function(){
						handleSatisfactionOtherScenarios(function(){
							obsMesWeightCurrentScenario = currentScenario.getObservedMeasureWithMeasurements(percentageWeight,isTimeStamp,timestamp);	
							if(obsMesWeightCurrentScenario){
								mestWeight = obsMesWeightCurrentScenario.get('measurements').at(0);
							}							
							removeOVSAssosciations();
							removeAssociationsForSatisfactionOnly();
							handleWeightChange(currentScenario);
							
							function removeOVSAssosciations(){
								if(satisfactionType === "smm_GradeMeasure"){
									var aggToColl = self.get('aggregatedTo');
									var ovs = self.get('componentOwner').get('overAllSatisfaction');
									aggToColl.each(function(agg){
										if(ovs && ovs.get('id') == agg.get('id')){
											ovs.get('aggregatedFrom').remove(self);
										}
									});
								}
							}
							function removeAssociationsForSatisfactionOnly(){
								if(!isDimensional && satisfactionType === "smm_GradeMeasure" && valueMeasurement) {
									var aggToColl = self.get('aggregatedTo');
									aggToColl.each(function(agg){
										agg.get('aggregatedFrom').remove(self);
									});
								}
							}
						});
					});
				});
			}
			
		}
		
		function handleSatisfactionOtherScenarios(satScenarioCallback){
			if (updateOtherSatScenarios) {
				var satMeasure = obsMesSatisfactionScenario.get('measure');
				var currentObs = obsMesSatisfactionScenario.get('observation');
				var context = currentObs.getContext();
				var obsList;
				if(context.get('type') === "transformation_PhaseObjectiveSet"){
					obsList = context.get('objectiveObservation');
				}else{
					obsList = context.get('contextObservation');
				}
				if(obsList) {
					for(var i=0;i<obsList.length;i++){
						if(obsList.at(i) !== currentObs){
							var obsrMeasures = obsList.at(i).get('observedMeasures');
							for(var j=0;j<obsrMeasures.length;j++){
								var obsMes = obsrMeasures.at(j);
								var measurements = obsMes.get('measurements');
								if(measurements.length > 0 && measurements.at(0).get('measuredCharacteristic') === satisfactionLevel){
									var oldMeasure = obsMes.get('measure');
									obsMes.set('measure',satMeasure);
									measurements.at(0).calculate();
									if(oldMeasure.get('observedMeasure').length == 0){
										oldMeasure.destroy();
									}
									break;
								}
							}
						}
					}
				}
				satScenarioCallback();			
			}else {
				satScenarioCallback();
			}
		}
		
        function handleSatisfactionIntervalsChange(currentScenario, mainScenario, satCallback) {
                if (!obsMesSatisfactionScenario) {
                    satCallback();
                    return;
                }
				var netTuples = 0;
				var newTuples = 0;
				var updateTuples = 0;
				var oldTuples = 0;
				var measureSatScenario = obsMesSatisfactionScenario.get('measure');
				var satisfactionMest = obsMesSatisfactionScenario.get('measurements').at(0);
				var intervals;
				if(!isDimensional && satisfactionMest && satisfactionMest.get('symbol') != satisfactionValue){
					changeInValue = true;
					satisfactionMest.set('symbol',satisfactionValue);
				}
				intervals = measureSatScenario.get('interval');	
				
				
				_.each(intervalTuples,function(intervalTuple, i){
					if(intervalTuple.old && intervalTuple.new){
						netTuples++;
						var interval = intervals.get(intervalTuple.old.id);	
						if(interval){
							var changed = false;
							for(var prop in intervalTuple.old){
								if(intervalTuple.old[prop] !== intervalTuple.new[prop]){
									changed = true;
									if(measureSatScenario.get('observedMeasure').length <=1){
										if(prop !== 'id'){
											interval.set(prop,intervalTuple.new[prop]);		
										}
									}
								}
							}
							if(changed){
								updateTuples++;
							}
						}else {//For scenario update
							var changed = false;
							if(measureSatScenario.get('observedMeasure').length <=1) {
								for(var prop in intervalTuple.old){
									if(intervals.at(i) && intervals.at(i).get(''+prop) !== intervalTuple.new[prop] && prop !== 'id'){
										changed = true;
										intervals.at(i).set(prop,intervalTuple.new[prop]);		
									}else if(!intervals.at(i)){
										changed = true;
										if(measureSatScenario.get('type') === 'smm_GradeMeasure'){
											measureSatScenario.createInterval(intervalTuple.new.maximumOpen,intervalTuple.new.minimumOpen,intervalTuple.new.maximumEndPoint,intervalTuple.new.minimumEndPoint, intervalTuple.new.symbol, intervalTuple.new.smiley, intervalTuple.new.smileyColor, intervalTuple.new.correctiveActions);
										}else{
											measureSatScenario.createInterval(intervalTuple.new.maximumOpen,intervalTuple.new.minimumOpen,intervalTuple.new.maximumEndPoint,intervalTuple.new.minimumEndPoint, intervalTuple.new.value, intervalTuple.new.smiley, intervalTuple.new.smileyColor, intervalTuple.new.correctiveActions);
										}
									}
								}
								if(changed){
									updateTuples++;
								}
							}else {
								updateTuples++;
							}
						}
					}else if(intervalTuple.new && !intervalTuple.old){
						netTuples++;
						newTuples++;
						if(measureSatScenario.get('observedMeasure').length <=1){
							if(measureSatScenario.get('type') === 'smm_GradeMeasure'){
								measureSatScenario.createInterval(intervalTuple.new.maximumOpen,intervalTuple.new.minimumOpen,intervalTuple.new.maximumEndPoint,intervalTuple.new.minimumEndPoint, intervalTuple.new.symbol, intervalTuple.new.smiley, intervalTuple.new.smileyColor, intervalTuple.new.correctiveActions);
							}else{
								measureSatScenario.createInterval(intervalTuple.new.maximumOpen,intervalTuple.new.minimumOpen,intervalTuple.new.maximumEndPoint,intervalTuple.new.minimumEndPoint, intervalTuple.new.value, intervalTuple.new.smiley, intervalTuple.new.smileyColor, intervalTuple.new.correctiveActions);
							}
						}						
					}else{
						var interval = intervals.get(intervalTuple.old.id);	
						if(interval){
							oldTuples++;
							if(measureSatScenario.get('observedMeasure').length <=1){
								interval.destroy();		
							}						
						}
					}
				});
				if(intervals.length > intervalTuples.length){//For scenario update
					for(var i=intervals.length-1;i>-1;i--){
						if(!intervalTuples[i]){
							intervals.at(i).destroy();
						}
						
					}
				}
				if(oldTuples> 0 || newTuples > 0 || updateTuples> 0){
					replaceSatMeasure = true;
					calculateSat = true;
					changeInValue = true;
					updateOtherSatScenarios = true;
				}
				if(replaceSatMeasure && (measureSatScenario.get('observedMeasure').length >1 || destroyOldSatMeasure)){
					var newSatMeasure = invokeReplaceSatMeasure(satisfactionType);
					if(destroyOldSatMeasure){
						newSatMeasure.set('name',measureSatisfaction.get('name'));
						measureSatisfaction.destroy();
					}
					measureSatisfaction = newSatMeasure;
					if(rescaleSat){
						measureSatisfaction.rescaleToFixUnitDifference(true,true)
					}
					if(calculateSat && !replaceSatMeasurement){
						if(isDimensional){
							satisfactionMest.calculate();	
						}else{
							satisfactionMest.set('symbol',satisfactionValue);
						}
					}
					checkReplacement();
				}else{
					if(rescaleSat){
						measureSatisfaction.rescaleToFixUnitDifference(true,true)
					}
					if(calculateSat && !replaceSatMeasurement){
						if(isDimensional){
							satisfactionMest.calculate();	
						}else{
							satisfactionMest.set('symbol',satisfactionValue);
						}
						
					}
					checkReplacement();
				}
				function checkReplacement(){
					if(replaceSatMeasurement){
							var valMest = obsMesScenario?obsMesScenario.get('measurements').at(0):null;
							var oldSatMeasurement = obsMesSatisfactionScenario.get('measurements').at(0);
							var newMeasurement = obsMesSatisfactionScenario.replaceMeasurement(satisfactionType,null,valMest);
							if(destroyOldSatMeasure){
								oldSatMeasurement.destroy();
							}
							if(!isDimensional){
								newMeasurement.set('symbol',satisfactionValue);
							}
							else {
								newMeasurement.calculate();
							}
							satCallback();
					}else{
						satCallback();
					}					
				}
				calculateSat = true;
		}			
		
        function handleWeightChange(currentScenario, mainScenario) {
        	var weightCalc = false;
			if(mestWeight && mestWeight.get('value') !== weight){
				//copyObservedMeasureIfRequired(currentScenario,mainScenario,percentageWeight);
				mestWeight.set('value',weight);
				weightCalc = true;
			}
			if(weightCalc){
				changeInValue = true;
				var ovs = self.get('componentOwner').get('overAllSatisfaction');
				var aggToColl = self.get('aggregatedTo');
				if(ovs){
					var isAggregatedToOVS = aggToColl.findWhere({id:ovs.get('id')});
					if(isAggregatedToOVS){
						var measurement = ovs.getMeasurement("valueMeasurement",currentScenario);
						if(measurement){
							measurement.calculate();
						}
					}
				}
			}
			if(changeInValue || weightCalc){
				if(obsMesScenario)
					obsMesScenario.set('lastChange',new Date().getTime());
				else {
					obsMesSatisfactionScenario?obsMesSatisfactionScenario.set('lastChange',new Date().getTime()):null;
				}
			}
			callback(changeInValue);
		}		

		
		function handleSatisfactionTypeChange(currentScenario,mainScenario,satTypeChangeCallback){
            var oldDimensional = measureSatisfaction?measureSatisfaction.get('measureRelationships').length > 0:null;
			if(measureSatisfaction && (measureSatisfaction.get('type') !== satisfactionType || isDimensional !== oldDimensional)){
				//copyObservedMeasureIfRequired(currentScenario,mainScenario,satisfactionLevel);
				changeInValue = true;
				if(obsMesSatisfactionScenario && measureSatisfaction.get('observedMeasure').length > 1){
					replaceSatMeasurement = true;
					replaceSatMeasure = true;
					updateOtherSatScenarios = true;
					satTypeChangeCallback();
				}else{
					destroyOldSatMeasure = true;
					replaceSatMeasure = true;
					replaceSatMeasurement = true;
					updateOtherSatScenarios = true;
					satTypeChangeCallback();
				}
			}else{
				satTypeChangeCallback();
			}
		}
		
		function handleSatisfactionUnitChange(currentScenario,mainScenario){
			var unit = beepPackage.findOrCreateUnit(satisfactionUnit.unitName,satisfactionUnit.unitObj);
			if(measureSatisfaction && measureSatisfaction.get('unit') != unit){
				//copyObservedMeasureIfRequired(currentScenario,mainScenario,satisfactionLevel);
				changeInValue = true;
				if(obsMesSatisfactionScenario && measureSatisfaction.get('observedMeasure').length > 1){
					replaceSatMeasure = true;
					updateOtherSatScenarios = true;
				}else{
					/*var oldUnit = measureSatisfaction.get('unit');
					if(oldUnit) {
						self.destroySatisfactionMeasureUnit(oldUnit,measureSatisfaction.get('id'));
					}*/
					measureSatisfaction.set('unit',unit);
				}
				rescaleSat = true;
			}
			if(unit){
				if(unit.get('roundingDigits') != satisfactionUnit.roundingDigits){
					unit.set('roundingDigits',satisfactionUnit.roundingDigits);
					rescaleSat = true;
				}
				unit.set('significantDecimals',satisfactionUnit.significantDecimals);
			}
		}		
		function invokeReplaceSatMeasure(measureType){
			var defaultMeasureLibrary = beepPackage.createDefaultMeasureLibrary();
			var unitToMatch;
			var satisfactionTuple;
			var satisfactionMest = obsMesSatisfactionScenario.get('measurements').at(0);
			var collMeasurement = obsMesScenario?obsMesScenario.get('measurements').at(0):null;
			if(measureType === 'smm_RankingMeasure'){
				//unitToMatch = measureSatisfaction.get('unit').get('name');
				unitToMatch = satisfactionUnit;
				satisfactionTuple ={type:'smm_RankingMeasure',measurement:collMeasurement};
			}else{
				satisfactionTuple ={type:'smm_GradeMeasure',measurement:collMeasurement};
			}
			return satisfactionMest.replaceMeasure(defaultMeasureLibrary,null,measureSatisfaction.get('trait').get('name'),unitToMatch,null,null,null,true,satisfactionTuple,intervalTuples,!isDimensional);
		}		
		function copyObservedMeasureIfRequired(currentScenario,mainScenario,mc){	
			if(!useCommon && currentScenario === commonScenario){	//TODO may not be satisfied at all?
				var obsMesCommonScenario = commonScenario.getObservedMeasureWithMeasurements(mc,false);
				if(obsMesCommonScenario){
					var mainScenarioTSLessObservation;
					var observaions = mainScenario.get('observation');
					observaions.each(function(obs){
						if(!obs.get('whenObserved') || !obs.get('whenObserved') === ""){
							mainScenarioTSLessObservation = obs;
						}
					});
					if(mainScenarioTSLessObservation){
						obsMesCommonScenario.copyObservedMeasureInclusive(mainScenarioTSLessObservation);
					}
					
				}								
			}
			
		}
		
	};
	
/*	ValuePropositionComponentMixin.prototype.shouldChangeSatisfactionMeasure(satisfactionType,isDimensional,satisfactionUnit,interValTuples,timestamp,scenario){
		var satisfactionObsMes = scenario.getObservedMeasureWithMeasurements(this.get('satisfactionLevel'),timestamp ? true:false ,timestamp);
		if(!satisfactionObsMes){
			return false;
		}
		if((satisfactionType === 'smm_GradeMeasure' && satisfactionObsMes.get('type') !== 'smm_GradeMeasure') || (satisfactionType === 'smm_RankingMeasure' && satisfactionObsMes.get('type') !== 'smm_RankingMeasure')) {
			return true;
		}
		if(satisfactionType === 'smm_GradeMeasure'){
			var modelHasRelationships = satisfactionObsMes.get('measure').get('measureRelationships').length;
			if((!isDimensional && modelHasRelationships) || (isDimensional && !modelHasRelationships)){
				return true;
			}
		}else{
			if(satisfactionObsMes.get('measure').get('unit').get('name') !== satisfactionUnit){
				return true;
			}			
		}
		return false;
	};*/
	
	prime(value,valueUnit,valFormula,satisfactionValue,satisfactionType,isDimensional,satisfactionUnit,weight,recipient,accumulator,baseTuples,interValTuples,timestamp,viewAlternative,maxChange,currentBM,expression,callback){
		var self = this;
		var currentPackage = this.getNestedParent();
		var measureLibrary = currentPackage.createDefaultMeasureLibrary();
		var valueMeasurement = self.get('valueMeasurement');
		var recipientMC = self.get('recipientOpinion');
        var satisfactionLevel = self.get('satisfactionLevel');
        var percentageWeight = self.get('percentageWeight');

		var scenario = viewAlternative.getMainScenario(self.getNestedParent());
		var objObs = scenario.get('objectiveObservation')?scenario.get('objectiveObservation'):scenario.get('contextObservation');
		if(objObs){
			for(var i=0; i < objObs.length; i++){
				var timeStamp = objObs.at(i).get('whenObserved');
				if(timeStamp && isNaN(timeStamp)){
					objObs.at(i).set('triggerUpdate',true);
				}
			}
		}		
		if(isDimensional) {
            var measurementComponent = self.primeValueMeasurement(valueMeasurement, self, value, valueUnit, valFormula, accumulator, baseTuples, timestamp, viewAlternative, maxChange, false, expression);
            if (recipient && recipient != "") {
				if(!recipientMC) {
					self.getNestedParent().createValueDetails(self, null, interValTuples.length > 0, weight, recipient);
					recipientMC = self.get('recipientOpinion');
				}
                self.primeValueMeasurement(recipientMC, self, recipient, valueUnit, null, accumulator, [], timestamp, viewAlternative, maxChange, true, expression);
            }
		}else{
			if (recipient && recipient != "") {
				if(!recipientMC) {
					self.getNestedParent().createValueDetails(self, null, interValTuples.length > 0, weight, recipient);
					recipientMC = self.get('recipientOpinion');
				}
				self.primeSatisfactionLevel(recipientMC,satisfactionType,isDimensional,recipient,null,scenario,null,interValTuples,timestamp);
			}
		}
		
		if(interValTuples.length > 0) {
			if (!satisfactionLevel) {
				self.getNestedParent().createValueDetails(self, null, interValTuples.length > 0, weight, recipient);
				satisfactionLevel = self.get('satisfactionLevel');
			}
			self.primeSatisfactionLevel(satisfactionLevel, satisfactionType, isDimensional, satisfactionValue, satisfactionUnit, scenario, measurementComponent, interValTuples, timestamp);
		}
        
        if (weight && weight !== "") {
			if(!percentageWeight) {
				self.getNestedParent().createValueDetails(self, null, interValTuples.length > 0, weight, recipient);
				percentageWeight = self.get('percentageWeight');
			}
            self.primeWeight(percentageWeight, weight, scenario, timestamp);
        }
		if(isDimensional) {
			self.addBaseRelationshipsToParents(measureLibrary,measurementComponent,viewAlternative,function(){
				if(baseTuples.length > 0) {
					self.calculate(scenario);
				}
				if(callback){
					callback(true);
				}
			});
		}
		else {
			callback();
		}
	};
	
	primeWeight(percentageWeight,weight,scenario,timestamp){
		var self = this;
		var charToMatch;
		var mcCharacteristic  = percentageWeight.get('characteristicDefinition');
		if(mcCharacteristic){
			charToMatch = mcCharacteristic.get('name');
		}else{
			charToMatch = self.get('name') + " Percentage Weight";
		}
		var unitToMatch = {unitName: "%",roundingDigits:"4", significantDecimals:"2"};
		
		var currentPackage = self.getNestedParent();
		var measureLibrary = currentPackage.createDefaultMeasureLibrary();
		var measurement = scenario.createMeasurement(currentPackage,self,percentageWeight,measureLibrary,charToMatch,unitToMatch,null,null,[],null,null,timestamp);
		measurement.set('value',weight);
		return measurement;
	};		
	primeSatisfactionLevel(satisfactionLevel,satisfactionType,isDimensional,satisfactionValue,satisfactionUnit,scenario,valueMeasurement,intervalTuples,timestamp){
		var self = this;
		var charToMatch;
		var mcCharacteristic  = satisfactionLevel.get('characteristicDefinition');
		if(mcCharacteristic){
			charToMatch = mcCharacteristic.get('name');
		}else{
			charToMatch = self.get('name') + " Recipient Satisfaction";
		}
		var satisfactionTuple = {measurement:valueMeasurement,type:satisfactionType};
		
		var currentPackage = self.getNestedParent();
		var measureLibrary = currentPackage.createDefaultMeasureLibrary();
		var measurement = scenario.createMeasurement(currentPackage,self,satisfactionLevel,measureLibrary,charToMatch,satisfactionUnit,null,null,null,satisfactionTuple,intervalTuples,timestamp,isDimensional? false:true);
		if(satisfactionType === 'smm_GradeMeasure'){
			measurement.set('symbol',satisfactionValue);
		}else{
			measurement.set('value',satisfactionValue);
		}
		return measurement;
	};
	
	addBaseTuple(baseTuples,measureChange){
		var self = this;
		for(var i=0;i<baseTuples.length;i++) {
			if(!baseTuples[i].old && baseTuples[i].new) {
				var component = baseTuples[i].new.component;
				this.get('aggregatedFrom').add(component);
				if(component && !component.get('aggregatedTo').findWhere({id:this.get('id')})) {
					component.get('aggregatedTo').add(this);
				}
			}else if(baseTuples[i].old && !baseTuples[i].new){
				var component = baseTuples[i].old.component;
				var compCount = 0;
				for(var j=0;j<baseTuples.length;j++) {
					if(baseTuples[j].old && baseTuples[j].old.component === component && baseTuples[j].new){
						compCount++;
					}
				}
				if(compCount === 0) {
					this.get('aggregatedFrom').remove(component);
					if(component && component.get('aggregatedTo').findWhere({id:this.get('id')})) {
						component.get('aggregatedTo').remove(this);
					}
				}
			}else if(baseTuples[i].old && baseTuples[i].new){
				if(baseTuples[i].old.usePreviousAlternative != baseTuples[i].new.usePreviousAlternative){
					var comp = baseTuples[i].new.component;
					if(comp){
						comp.onAggregatedFromAdded(comp);
					}
				}
			}
		}
		if(measureChange) {
			var aggTo = this.get('aggregatedTo');
			var ovs = null;
			for(var i=0;i<aggTo.length;i++){
				if(aggTo.at(i).checkOVS()){
					ovs = aggTo.at(i);
					break;
				}	
			}
			if(ovs) {
				this.get('aggregatedTo').remove(ovs);
				ovs.get('aggregatedFrom').remove(this);
			}else {
				var aggToConcat = aggTo.models.concat();
				_.each(aggToConcat,function(agg){
					self.get('aggregatedTo').remove(agg);
					agg.get('aggregatedFrom').remove(self);
				});
			}
		}
	};
	changeValFormula(model,previousName){
		var self = this;
	}
	
	

//#endCustomMethods	
	
	}
	path.ValuePropositionComponentMixin = ValuePropositionComponentMixin;
	//return ValuePropositionComponentMixin;
//});
