import * as $ from "jquery";
import * as _ from "underscore";
import * as Backbone from "backbone";
import * as ko from "knockout";
import * as kb from "knockback";
import * as bootbox from "../../../../../../libs/bootbox/bootbox";
import { DataManager } from "../../../../../com/vbee/data/DataManager";
import * as moment from 'moment'
var path = DataManager.getDataManager().buildAppNsPath("tickets",global.version);

export class HistoryViewModel {
  constructor(model, options, detailedView) {
    window.utils.startSpinner("HistorySpinner", "Loading History data...");
    var self = this;
    this.HistoryViewModel = this;
    this.afterRenderViewLoaded = false;
    //this.workspaceMsg = ko.observable();
    this.selectedWorkspace = ko.observable();
    this.workspaceCollection = ko.observableArray([]);
    var workspacesData = DataManager.getDataManager().get("workspaceNames");
    var legalEntityDetails = DataManager.getDataManager().get("legalEntityDetails");
    for (var key in workspacesData) {
      var leId = DataManager.getDataManager().get("workspaceLegal")[key]
      if(leId && legalEntityDetails[leId].roles.length != 0){
        self.workspaceCollection.push({ id: key, name: workspacesData[key] });
      }
    }
    this.productName = ko.observable();
    this.productDescription = ko.observable();
    this.productTime = ko.observable();
    this.enableProductCancel = ko.observable(false);
    this.enableProductCreate = ko.observable(false);
    this.showAdminButtons = ko.observable(false);
    if(window.utils.isVMPUser()){
      this.showAdminButtons(true);
    }
    this.encodeId = options.viewId;
    this.selectedProductId = ko.observable();
    this.currentChangeSet = ko.observable();
    this.currentChangeSetSeqNo = ko.observable();
    this.changeSetJson = {};
    this.labels = kb.viewModel(DataManager.getDataManager().get("localeManager"),[
        "Close",
        "update",
        "Cancel",
        "Delete"
      ]);
    this.afterRenderView = _.bind(this.afterRenderView, this);

    this.selectedWorkspace.subscribe(function (value) {
      window.vdmModelView.workspaceHeading(value);
    });
    this.size = 100;
    this.exportData = [];
  }

  setToDefault() {
    var self = this;
    self.enableProductCancel(false);
    self.enableProductCreate(false);
    self.productName("");
    self.productTime("");
    self.productDescription("");
    if ($("#invoiceItems")[0]) {
      $("#invoiceItems")[0].innerHTML = "";
    }
  }

  setBreadCrumb(breadCrumbItems) {
    var self = this;
    $("#breadcrumb").addClass("breadcrumb");
    breadCrumbItems.push(
      '<li><a style="font-size: x-large;" href="#adminviews/History">' +
        "History" +
        "</a></li>"
    );
  }

  isAdminView() {
    return true;
  }

  reset(){
    var self = this;
    var dataManager = DataManager.getDataManager();
    /*var wid = wsId;
    if(wsId instanceof Object){
      wid = dataManager.get("currentWorkspaceId");
    }*/
    var wid = self.selectedWorkspace();
    if (wid != null && wid != undefined) {
      var workspaceName = dataManager.getWorkspaceName(wid);
      var msg = "Reset will delete the plans. Do you want to continue?";
      msg = msg.concat("<br/> Current Workspace: " + workspaceName);
      var artifactsData = dataManager.get("artifactsData");
      var artList = artifactsData ? artifactsData[wid] : [];
      if (artList) {
        msg = msg.concat("<br/> List of Plans:");
        for (var i = 0; i < artList.length; i++) {
          if (artList[i].startsWith(window.plansKey)) {
            var plan = window.utils.getElementModel(artList[i], ["transformation.Plan"]);
            if (plan) {
              msg = msg.concat("<br/>" + plan.get("name"));
            } else {
              var planDoc = dataManager.get("artifactsDocuments")[artList[i]];
              var planName = planDoc ? planDoc.name : artList[i];
              msg = msg.concat("<br/>" + planName);
            }
          }
        }
      }
      bootbox.confirm(msg, function (result) {
        if (result) {
          var le = dataManager.get("currentLegalEntity");
          if (wid != null && wid != undefined && le != null) {
            var workspaceUrl = "/vdmbee/workspace/" + wid + "?entityId=" + le.entityId+"&retainChangeset="+1;
            window.utils.startSpinner("purge", "Resetting Plan Data.. ");
            dataManager.resetWorkspaceChangeset(true);
            dataManager.get("vmpServerService").deleteAllPromiseForUriList(workspaceUrl, {}, true).then(function (data) {
                window.utils.stopSpinner("purge");
                if(data.message){
                  bootbox.alert(data.message);
                }
                window.location.reload();
                //window.utils.navigatePage(true);
                //
            });
          }
        }
      });
    } else {
      bootbox.alert("There are no Current workspaces");
    }
  }

  purge() {
    var self = this;
    var dataManager = DataManager.getDataManager();
    /*var wid = wsId;
    if(wsId instanceof Object){
      wid = dataManager.get("currentWorkspaceId");
    }*/
    var wid = self.selectedWorkspace();
    if (wid != null && wid != undefined) {
      var workspaceName = dataManager.getWorkspaceName(wid);
      var msg = "Purging will delete the plans and cannot be reverted. Do you want to continue?";
      msg = msg.concat("<br/> Current Workspace: " + workspaceName);
      var artifactsData = dataManager.get("artifactsData");
      var artList = artifactsData ? artifactsData[wid] : [];
      if (artList) {
        msg = msg.concat("<br/> List of Plans:");
        for (var i = 0; i < artList.length; i++) {
          if (artList[i].startsWith(window.plansKey)) {
            var plan = window.utils.getElementModel(artList[i], ["transformation.Plan"]);
            if (plan) {
              msg = msg.concat("<br/>" + plan.get("name"));
            } else {
              var planDoc = dataManager.get("artifactsDocuments")[artList[i]];
              var planName = planDoc ? planDoc.name : artList[i];
              msg = msg.concat("<br/>" + planName);
            }
          }
        }
      }
      bootbox.confirm(msg, function (result) {
        if (result) {
          var le = dataManager.get("currentLegalEntity");
          if (wid != null && wid != undefined && le != null) {
            var workspaceUrl = "/vdmbee/workspace/" + wid + "?entityId=" + le.entityId;
            window.utils.startSpinner("purge", "Purging Plan Data.. ");
            dataManager.resetWorkspaceChangeset(true);
            dataManager.get("vmpServerService").deleteAllPromiseForUriList(workspaceUrl, {}, true).then(function (data) {
                window.utils.stopSpinner("purge");
                if(data.message){
                  bootbox.alert(data.message);
                }
                if(data.del){
                  window.location.reload();
                } else {
                  if ($("#workspaceMsg")[0]) {
                    $("#workspaceMsg").html('');
                  }
                }
                //window.utils.navigatePage(true);
                //
            });
          }
        }
      });
    } else {
      bootbox.alert("There are no Current workspaces");
    }
    //window.purgeWorkspace(self.selectedWorkspace());
  }

  moveWorkspace(){
      var self = this;
      var dataManager = DataManager.getDataManager();
      var artifactsData = dataManager.get("artifactsData");
      var artList = artifactsData ? artifactsData[self.selectedWorkspace()] : [];
      
      var content = "<div><div class='col-xs-4'><span style='margin-left:-16px'>Target Workspace:</span></div>";
		  content += "<div class='col-xs-7'><select id='targetWorkspace'>";
      var isWorkspaceAvailable = false;
      for (var ob = 0; ob < self.workspaceCollection().length; ob++) {
        if(self.workspaceCollection()[ob].id != self.selectedWorkspace()){
          content += "<option id=" + self.workspaceCollection()[ob].id + ">" + self.workspaceCollection()[ob].name + "</option>";
          isWorkspaceAvailable = true;
        }
      }
      content += "</select></div></div></br></br>";
      content += "<div>List of Plans:</button></div>"
      
      content += "<table id='myTable' class='table table-bordered'>";
      content += "<thead><tr id='myTableRow'><th><span style='padding-left:5px'><input type='checkbox' id='masterCheckbox' style='cursor: pointer' checked /><span></th><th>Plan Name</th></tr></thead>";
      content += "<tbody id='valueContent'>";
      for (var i = 0; i < artList?.length; i++) {
        if (artList[i].startsWith(window.plansKey)) {
            var planDoc = dataManager.get("artifactsDocuments")[artList[i]];
            var planName = planDoc ? planDoc.name : artList[i];
            var planId = planDoc ? planDoc.artifactId : artList[i];
            content += "<tr><td><span style = 'padding-left:5px'><input type='checkbox' class = 'listOfPlans'  value='" + planId + "' style=\"cursor: pointer;\" checked /><span></td><td>" + planName + "</td></tr>"
        }
      }
      content += "</tbody></table>";

      var dialogBox = bootbox.dialog({
        title: '<img src="img/Small/icons-15.png">  Move Plan',
        className: "dialogWide",
        message: content,
        buttons: {
          main: {
            label: "Close",
            className: "btn btn-default",
          },
          success: {
            label: '<i class="fa fa-check"></i> Move',
            className: "btn btn-complete",
            callback: function () {
              var isChecked = false;
              for(var list =0;list<$('.listOfPlans').length;list++){
                if($('.listOfPlans')[list].checked){
                    isChecked = true;
                    break;
                }
              }
              if(!isWorkspaceAvailable) {
                bootbox.alert("No Workspace is selected.");
              } else if(isChecked) {
                var selectedMapList =[];
                for(var list =0;list<$('.listOfPlans').length;list++){
                  if($('.listOfPlans')[list].checked && !$('.listOfPlans')[list].disabled){
                      selectedMapList.push($('.listOfPlans')[list].value)
                  }
                }
                var dataManager = DataManager.getDataManager();
                //var params = {};
                var tenantId = dataManager.get("currentLegalEntity") ? dataManager.get("currentLegalEntity").entityId: null;
                var planList = selectedMapList;
                var targetWorkspaceId = $('#targetWorkspace option:selected').attr('id');
                dataManager.get("vmpServerService").postAllPromise("/vdmbee/workspace/moveplans/" + self.selectedWorkspace() + "/" + targetWorkspaceId + "?entityId=" + tenantId+"&planList="+planList,{}).then(function (data) {
                    bootbox.alert("Plan moved to the workspace successfully.");
                    window.location.reload();
                });
              } else {
                bootbox.alert("No Plan is selected.");
              }
            }
          }
        }
      });
      $('.listOfPlans').on('change', function() {
        var isChecked = true;
        console.log("isChecked: ", isChecked);
        for(var list =0;list<$('.listOfPlans').length;list++){
          if(!$('.listOfPlans')[list].checked){
              isChecked = false;
              break;
          }
        }
        $('#masterCheckbox').prop('checked', isChecked);
      });
      $('#masterCheckbox').on('change', function() {
        var isChecked = $(this).prop('checked');
        for(var list =0;list<$('.listOfPlans').length;list++){
          $('.listOfPlans')[list].checked=isChecked;
        }
      });

  }

  breakStringToColumn(strChange){
    const changeColumn = {};
    const strChangeLength = strChange?.length;
    let startIndex = 0;
    let endIndex = startIndex + 32767;
    let j = 1;
    while(startIndex < strChangeLength) {
      if (startIndex + 32767 > strChangeLength) endIndex = strChangeLength;
      else endIndex = startIndex + 32767;
      let part = strChange.substring(startIndex, endIndex);
      if (part.lastIndexOf("}") !== -1 || part.lastIndexOf(",") !== -1) {
        endIndex = startIndex + (part.lastIndexOf(",") > part.lastIndexOf("}") ? part.lastIndexOf(",") + 1 : part.lastIndexOf("}") + 1);
      }
      part = strChange.substring(startIndex, endIndex);
      changeColumn["changeColumn" + j] = part;
      startIndex = endIndex;
      j += 1;
    }
    return changeColumn;
  }
  exportChangeSet() {
    var self = this;
    var spinnerText = "Exporting Workspace Changes...";
    window.utils.startSpinner("Export", spinnerText);
    self.getChangeSet("");
  }

  getChangeSet(changsetId) {
    var self = this;
    var wid = self.selectedWorkspace();
    const csId = changsetId === "" ? "" : `changesetid=${changsetId}&`;
    var leId = DataManager.getDataManager().get("workspaceLegal")[wid];
    DataManager.getDataManager().get("vmpServerService").getAllPromise(`/vdmbee/workspace/export/${wid}/appliedchanges?${csId}size=${self.size + 1}&sort=changeSet.seqNo%2Casc&changeSet.seqNo.dir=asc&entityId=` + leId).then(function (apiResponse) {
        if (!apiResponse) {
          return;
        }
        let responseData = apiResponse;
        const lastChageSet = responseData[responseData?.length - 1];
        if (lastChageSet.changeSet.seqNo !== 0) {
          responseData.pop();
        }
        const chengeSetFields = responseData?.map((ele) => ({
          seqNo: ele?.changeSet?.seqNo,
          id: ele?.changeSet?.id,
          changedBy: ele?.changeSet?.changedBy,
          timestamp: new Date(ele?.changeSet?.timestamp).toLocaleString(),
          name: ele?.changeSet?.name,
          description: ele?.changeSet?.description,
          skipReason: ele?.changeSet?.skipReason,
          skipped: ele?.changeSet?.skipped,
        }));
        responseData = responseData?.map((ele, ind) => {
          const temp = ele?.changes?.map((row, index) => {
            const cs = index === 0 ? chengeSetFields[ind]
                : {
                    seqNo: "",
                    id: "",
                    changedBy: "",
                    timestamp: "",
                    name: "",
                    description: "",
                    skipReason: "",
                    skipped: "",
                  };
            const strChange = row?.change?.toString();
            const strChangeLength = strChange?.length;
            let changeColumns = {};
            if (strChangeLength > 32767 ) {
              changeColumns = self.breakStringToColumn(strChange);
            } else {
              changeColumns["changeColumn1"] = strChange;
            }
            return {
              ...cs,
              currentChangeSet: ele?.currentChangeSet,
              changeId: row?.id,
              changeTimestamp: row?.timestamp,
              changeDescription: row?.description,
              changeObjectId: row?.changeObjectId,
              changeObjectType: row?.changeObjectType,
              operationType: row?.operationType,
              isJsonReferenced: row?.isJsonReferenced,
              versionChange: row?.versionChange,
              parent: row?.parent,
              beepPackage: row?.beepPackage,
              beepPackageType: row?.beepPackageType,
              artifactId: row?.artifactId,
              documentVersion: row?.documentVersion,
              refParents: row?.refParents?.toString(),
              ...changeColumns,
            };
          });
          const blankRow = {};
          temp.push(blankRow);
          return temp;
        });
        const preparedData = [].concat(...responseData);
        self.exportData = [...self.exportData, ...preparedData];
        if (lastChageSet.changeSet.seqNo !== 0) {
          self.chagesetId = self.getChangeSet(lastChageSet.changeSet.id);
        } else {
          self.createExcelFile([...self.exportData], "changeSets");
        }
      });
  }

  createExcelFile(excelData, fileName) {
    var self = this;
    import("xlsx").then((XLSX) => {
      var wb = XLSX.utils.book_new();
      const sheetLength = self.size;
      const fileType = "application/hal+json";
      const fileExtension = ".xlsx";
      const columnWidths = [];
      if (excelData[0]) {
        const header = Object.keys(excelData[0]);
        for (let i = 0; i < header.length; i++) {
          columnWidths.push({ wch: header[i].length + 5 });
        }
        let firstIndex = 0;
        for (let i = excelData[0].seqNo, j = 1; i >= 0; i -= sheetLength, j += 1) {
          if (i - sheetLength >= 0) {
            const lastIndex = excelData.findIndex(
              (element) => element.seqNo === i - sheetLength
            );
            const data = excelData.slice(firstIndex, lastIndex);
            const ws = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(wb, ws, `Sheet${j}`);
            ws["!cols"] = columnWidths;
            firstIndex = lastIndex;
          } else {
            const lastIndex = excelData.length;
            const data = excelData.slice(firstIndex, lastIndex);
            const ws = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(wb, ws, `Sheet${j}`);
            ws["!cols"] = columnWidths;
            firstIndex = lastIndex;
          }
        }
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        window.utils.stopSpinner("Export");
        saveAs(data, fileName + fileExtension);
      }
    });
  }

  afterRenderView() {
    var self = this;
    //DataManager.getDataManager().set("autoSave",false);
    var wid = DataManager.getDataManager().get("currentWorkspaceId");
    self.selectedWorkspace(wid);
    self.afterRenderViewLoaded = true;
    if (self.selectedWorkspace()) {
      self.workspaceHeading(self.selectedWorkspace());
    } else {
      window.utils.stopSpinner("HistorySpinner");
    }
    $(window).bind("resize", function (event) {
      if (this == event.target) {
        $(".sp").removeAttr("style");
      }
      if (self.setResizeHeightHandler) {
        self.setResizeHeightHandler();
      }
    });
    /*$('#productName').on('keyup', function (view) {
            var nameval = view.currentTarget.value.trim();          
            if (($.trim(nameval) != "") && ($.trim(self.productDescription()) != "")) {
                self.enableProductCreate(true);
                self.enableProductCancel(true);
            } else {
                self.enableProductCreate(false);
                self.enableProductCancel(true);
            }
        });

        $('#productDescription').on('keyup', function (view) {
            var descval = view.currentTarget.value.trim();
            if (($.trim(descval) != "") && ($.trim(self.productName()) != "")) {
                self.enableProductCreate(true);
                self.enableProductCancel(true);
            } else {
                self.enableProductCreate(false);
                self.enableProductCancel(true);
            }
        });*/

    $("#productsTable").on("click", "tr", function () {
      if (this.parentElement.tagName == "TBODY" && this.textContent != "No data available in table") {
        if ($(this).hasClass("selected")) {
          $(this).removeClass("selected");
          self.selectedProductId(null);
          self.setToDefault();

          var ex = document.getElementById("productsTable");
          if (!$.fn.DataTable.fnIsDataTable(ex)) {
            $(ex).dataTable();
          } else {
            //$('#productsTable').DataTable().ajax.reload();
          }
        } else {
          $("tr.selected").removeClass("selected");
          $(this).addClass("selected");
          //var ex = document.getElementById('productsTable');
          //var rowData = this.children;
          var dataset = self.changeSetJson[$(this).attr("hid")];
          self.productName(dataset.changeSet.seqNo);
          self.productDescription(dataset.changeSet.changedBy);
          //var duration = "";
          //var results = dataset.changes;
          var legalId = DataManager.getDataManager().get("workspaceLegal")[self.selectedWorkspace()];
          var url = "/vdmbee/workspace/getChangesByChangeset/"+self.selectedWorkspace()+"?changesetid="+dataset.changeSet.id+"&entityId="+legalId;
          url = url + "&size=1000&page=0&sort=changeSet.seqNo%2Casc&changeSet.seqNo.dir=asc";
          DataManager.getDataManager().get('vmpServerService').getAllPromise(url).then(function(data) {
						function truncateString(str, num) {
              if (str && str.length > num) {
                return str.slice(0, num) + "...";
              } else {
                return str;
              }
            }
            if (data) {
              var results = data._embedded.changeResources;
              var fromDate = new Date(Date.parse(results[0]["timestamp"]));
              self.productTime(fromDate);
              var tableHtml = "<table class='table table-bordered' style='border: 1px solid black'><thead><tr><th>changeObjectType</th><th>operationType</th><th>change</th></tr></thead>";
              tableHtml += "<tbody>";
              //table generation
              for (var key in results) {
                var rowHtml = "<tr style='border: 1px solid black'>";
                var quantity = results[key]["changeObjectType"]? results[key]["changeObjectType"]: "-";
                var changeTrunc = truncateString(JSON.stringify(results[key]["change"]), 1000);
                rowHtml += "<td>"
                  .concat(quantity, "</td><td>")
                  .concat(results[key]["operationType"],"</td><td style='white-space: nowrap;'>" )
                  .concat(changeTrunc, "</td></tr>");
                tableHtml += rowHtml;
              }
              tableHtml += "</tbody></table>";
              var cr = data.page.size;
              var tr = data.page.totalElements;
              if(tr < cr){
                cr = tr;//Temp
              }
              $("#invoiceItems")[0].innerHTML = '<div class="row"><div class="col-xs-1"></div><div class="col-xs-10">' +
                tableHtml +
                '</div><div class="col-xs-1"></div></div><br/><div>Showing '+cr+' of '+tr+' records</div>';
            }
					});
          self.selectedProductId(this.id);
          self.enableProductCancel(true);
          self.enableProductCreate(true);
        }
      }
    });

    /*self.updateTable(function(){
      window.utils.stopSpinner('HistorySpinner');
      var windowHeight = window.innerHeight - $('.navbar').outerHeight();
      var propertiesNode = $('.propertiesArea');
      var productNode = $('#maintainProducts');
      var tabNode = $('.tab-content');
      var adminAreaNode = $('.adminArea');
      var baseHeight = _.max([windowHeight,productNode.outerHeight(),adminAreaNode.outerHeight(), tabNode.outerHeight(), propertiesNode.outerHeight()]);
      //self.spliter = $("#splitPanels").enhsplitter({ minSize: 2, onDrag: window.utils.splitterBoundary, collapse: 'right', position: '56%', handle: 'block', splitterSize: '9px', height: baseHeight, onDragEnd: self.setResizeHeight });
    });*/
  }

  undo(model, event, skipChangeSets) {
    var self = this;
    //bootbox.alert("Not yet Implemented");
    //return;
    var params = {};
    if (skipChangeSets == undefined) {
      skipChangeSets = true;
    }
    var prevJson = self.getPreviousSequenceJson(
      self.currentChangeSetSeqNo(),
      self.currentChangeSet()
    );
    params.tenantId = DataManager.getDataManager().get("currentLegalEntity")
      ? DataManager.getDataManager().get("currentLegalEntity").entityId
      : null;
    DataManager.getDataManager().get("vmpServerService").postAllPromise(
        "/vdmbee/workspace/undo/" +
          DataManager.getDataManager().get("currentWorkspaceId") +
          "/" +
          (prevJson != null ? prevJson.changeSet.id : "null") +
          "?entityId=" +
          params.tenantId +
          "&skipChangeSets=" +
          skipChangeSets,
        JSON.stringify(params)
      ).then(function (data) {
        $("#" + window.utils.htmlEscape(self.currentChangeSet())).css("background-color","");
        if (prevJson != null) {
          self.currentChangeSet(prevJson.changeSet.id);
          self.currentChangeSetSeqNo(prevJson.changeSet.seqNo);
        } else {
          self.currentChangeSet(null);
          self.currentChangeSetSeqNo(null);
        }
        $("#" + window.utils.htmlEscape(self.currentChangeSet())).css("background-color","rgb(148 148 197)");
      });
  }
  undoNoSkip(model, e) {
    this.undo(model, e, false);
  }
  redo() {
    var self = this;
    //bootbox.alert("Not yet Implemented");
    //return;
    var params = {};
    var dataManager = DataManager.getDataManager();
    params.tenantId = dataManager.get("currentLegalEntity") ? dataManager.get("currentLegalEntity").entityId: null;
    dataManager.get("vmpServerService").postAllPromise("/vdmbee/workspace/" + dataManager.get("currentWorkspaceId") + "?howMany=1&entityId=" + params.tenantId,JSON.stringify(params)).then(function (data) {
        $("#" + window.utils.htmlEscape(self.currentChangeSet())).css("background-color", "");
        var nextJson = self.getNextSequenceJson(self.currentChangeSetSeqNo(), self.currentChangeSet());
        if (nextJson) {
          self.currentChangeSet(nextJson.changeSet.id);
          self.currentChangeSetSeqNo(nextJson.changeSet.seqNo);
        } else {
          self.currentChangeSet(null);
          self.currentChangeSetSeqNo(null);
        }
        $("#" + window.utils.htmlEscape(self.currentChangeSet())).css("background-color","rgb(148 148 197)");
    });
  }
  workspaceHeading(wid) {
    var self = this;
    var dataManager = DataManager.getDataManager();
    if (wid != null && wid != undefined && self.afterRenderViewLoaded) {
      self.setToDefault();
      window.utils.startSpinner("HistorySpinner", "Loading History data...");
      //var workspaceName = dataManager.getWorkspaceName(wid);
      var msg = "";
      var artifactsData = dataManager.get("artifactsData");
      var artList = artifactsData ? artifactsData[wid] : [];
      if (artList) {
        msg = msg.concat("List of Plans:");
        for (var i = 0; i < artList.length; i++) {
          if (artList[i] && artList[i].startsWith(window.plansKey)) {
            var plan = window.utils.getElementModel(artList[i], ["transformation.Plan"]);
            if (plan) {
              msg = msg.concat("<br/>" + plan.get("name"));
            } else {
              var planDoc = dataManager.get("artifactsDocuments")[artList[i]];
              var planName = planDoc ? planDoc.name : artList[i];
              msg = msg.concat("<br/>" + planName);
            }
          }
        }
      }
      if ($("#workspaceMsg")[0]) {
        $("#workspaceMsg").html(msg);
      }
      //self.workspaceMsg(msg);
      self.updateTable(wid, function () {
        window.utils.stopSpinner("HistorySpinner");
      });
    } else if (self.workspaceMsg) {
      self.workspaceMsg("There are no workspaces");
    }
  }
  getNextSequenceJson(seqNo, currentCSId) {
    var self = this;
    var ret;
    var retJson;
    var keys = Object.getOwnPropertyNames(self.changeSetJson);
    for (var i = 0; i < keys.length; i++) {
      if (self.changeSetJson[keys[i]].changeSet.id != currentCSId && self.changeSetJson[keys[i]].changeSet.seqNo > seqNo && (!ret || self.changeSetJson[keys[i]].changeSet.seqNo < ret) ) {
        ret = self.changeSetJson[keys[i]].changeSet.seqNo;
        retJson = self.changeSetJson[keys[i]];
      }
    }
    return retJson;
  }
  getPreviousSequenceJson(seqNo, currentCSId) {
    var self = this;
    var ret;
    var retJson;
    var keys = Object.getOwnPropertyNames(self.changeSetJson);
    for (var i = 0; i < keys.length; i++) {
      if ( self.changeSetJson[keys[i]].changeSet.id != currentCSId && self.changeSetJson[keys[i]].changeSet.seqNo < seqNo && (!ret || self.changeSetJson[keys[i]].changeSet.seqNo > ret) ) {
        ret = self.changeSetJson[keys[i]].changeSet.seqNo;
        retJson = self.changeSetJson[keys[i]];
      }
    }
    return retJson;
  }
  updateTable(wid, callback) {
    var self = this;
    if (self.dataTable) {
      self.dataTable.destroy();
    }
    //var offset = 10;
    self.dataTable = $("#productsTable").DataTable({
        ajax: {
          url: `/vdmbee/workspace/${wid}/appliedchanges`,
          dataSrc: 'results',
        },
        ordering: true,
        pageLength: 10,
        processing: true,
        serverSide: true,
        language: {
          loadingRecords: "&nbsp;",
          processing: "Loading DataTable...",
        },
        columns: [
          { 
            data: "changeSet.seqNo", 
            className: "text-center", 
            orderable: true 
          },
          { 
            data: "changeSet.timestamp", 
            title: 'Date(MM-DD-YYYY)', 
            className: "text-center", 
            orderable: true, 
            render: function (data) {
              return moment(data).format("MM-DD-YYYY HH:mm:ss");
            } 
          },
          { 
            data: "changeSet.skipped", 
            className: "text-center", 
            orderable: false 
          },
          { 
            data: "changeSet.changedBy", 
            className: "text-center", 
            orderable: true, 
            render: function (data) {
              const legalId = DataManager.getDataManager().get("workspaceLegal")[wid];
              const userList = DataManager.getDataManager().get("userList")[legalId];
              var changeUser = userList ? userList[data] : data;
              return changeUser ? changeUser : data;
            } 
          }
        ],
        "sDom": '<"pull-right"f><"table-container"t><"bottom d-flex"<"pull-left"i><"pull-right"p>>', // Adds search box (f), table (t), pagination (p), and info (i)
        rowCallback: function (row, data) {
          const selfLink = data._links.self;
          const id = selfLink.href.substr(selfLink.href.lastIndexOf("/") + 1);
          $(row).attr("hid", id);
          $(row).attr("id", window.utils.htmlEscape(id));
    
          if (self.currentChangeSet() === id) {
            $(row).css("background-color", "rgb(148 148 197)");
          }
    
          self.changeSetJson[id] = data;
        },
        initComplete: function () {
          if (callback) {
            callback();
          }
        },
        drawCallback: function () {
          self.setResizeHeightHandler();
          $("#refresh_productsTable").on("click", function () {
            table.ajax.reload();
            self.setToDefault();
          });
        },
        error: function (xhr, error, code) {
          console.error("DataTables error:", error);
          console.log(xhr.responseText);
        },
        ajax: function (data, callback) {
          DataManager.getDataManager()
            .get("vmpServerService")
            .getDataTableAjaxPromise(`/vdmbee/workspace/${wid}/appliedchanges`, data)
            .then(function (response) {
              const results = response._embedded?.changeDataModelResources || [];
              const recordsTotal = response.page?.totalElements || "All";
    
              const formattedData = {
                recordsTotal,
                recordsFiltered: recordsTotal,
                data: results,
              };
    
              results.forEach((result) => {
                if (result.currentChangeSet) {
                  self.currentChangeSet(result.changeSet.id);
                  self.currentChangeSetSeqNo(result.changeSet.seqNo);
                }
              });
    
              callback(formattedData);
            });
        },
      });
    
      $("div.toolbar").html(
        '&nbsp;&nbsp;<button type="button" class="btn btn-default" id="refresh_productsTable" style="padding: 1px 5px; margin-bottom: 5px; font-weight: 600;"><span class="glyphicon glyphicon-refresh"></span> Refresh</button>'
      );
    };
    

  createProduct() {
    /*var self = this;
        var params = {};
        params.name=self.productName();
        params.description = self.productDescription();        
        var currentLegalEntity = DataManager.getDataManager().get("currentLegalEntity");
        if (currentLegalEntity && currentLegalEntity.entityId) {
            params.currentEntityId = currentLegalEntity.entityId;
        }
        var spinnerText = "Saving Product...";
        window.utils.startSpinner('CreateProductSpinner', spinnerText);
        window.setTimeout(function () {

            if (self.selectedProductId() == null || self.selectedProductId() == "") {
                DataManager.getDataManager().get('vmpServerService').postAllPromise("/vdmbee/product", JSON.stringify(params)).then(function (data) {
                    $('#productsTable').DataTable().ajax.reload();
                    window.utils.stopSpinner('CreateProductSpinner');
                    self.setToDefault();
                });
            }
            else {
                params.id = self.selectedProductId();
                DataManager.getDataManager().get('vmpServerService').postAllPromise("/vdmbee/product/" + params.id, JSON.stringify(params), "PATCH").then(function (data) {
                    $('#productsTable').DataTable().ajax.reload();
                    window.utils.stopSpinner('CreateProductSpinner');
                    self.setToDefault();
                });
            }
        },100)*/
  }

  updateProduct() {
    var self = this;
    var params = {};
    params.id = self.selectedProductId();
    var dataset = self.changeSetJson[self.selectedProductId()];
    var seq = dataset.changeSet.seqNo;
    var changeBy = dataset.changeSet.changedBy;
    var results = dataset.changes;
    /*params.name = self.productName();
        params.description = self.productDescription();
        var currentLegalEntity = DataManager.getDataManager().get("currentLegalEntity");
        if (currentLegalEntity && currentLegalEntity.entityId) {
            params.currentEntityId = currentLegalEntity.entityId;
        }
        window.utils.startSpinner('UpdateProductSpinner', 'Updating Product...');
        window.setTimeout(function () {
            DataManager.getDataManager().get('vmpServerService').postAllPromise("/vdmbee/product/" + params.id, JSON.stringify(params), "PATCH").then(function (data) {
                $('#productsTable').DataTable().ajax.reload();
                window.utils.stopSpinner('UpdateProductSpinner');
                self.setToDefault();
            });
        },100)*/
  }

  deleteProduct() {
    /*var self = this;
        var params = { id: self.selectedProductId() };
        window.utils.startSpinner('DeleteProductSpinner', 'Deleting Product...');
        window.setTimeout(function () {
            DataManager.getDataManager().get('vmpServerService').postAllPromise("/vdmbee/product/" + params.id, JSON.stringify(params), "DELETE").then(function (data) {
                $('#productsTable').DataTable().ajax.reload();
                window.utils.stopSpinner('DeleteProductSpinner');
                self.setToDefault();
            });
        },100)*/
  }
  cleanModalDialog() {
    var self = this;
    //not called
    //DataManager.getDataManager().set("autoSave",true);
    self.spliter.enhsplitter("remove");
    $(window, ".resize").unbind("resize");
    window.cleanDialogModel(options.modalId, self);
  }
  setResizeHeightHandler() {
    /*var self = this;
        setTimeout(function () {
            if (self.spliter) {
                var windowHeight = window.innerHeight - $('.navbar').outerHeight();
                var propertiesNode = $('.propertiesArea');
                var HistoryNode = $('#maintainHistory');
                var tabNode = $('.tab-content');
                var adminAreaNode = $('.adminArea');
                var baseHeight = _.max([windowHeight, adminAreaNode.outerHeight(), tabNode.outerHeight(), HistoryNode.outerHeight(), propertiesNode.outerHeight()]);
                //self.spliter.css('height', (baseHeight) + 'px');
                //$("#splitPanels").enhsplitter('refresh');
            }
        }, 150)*/
  }

  static getInstance(model, options, detailedView) {
    return new HistoryViewModel(model, options, detailedView);
  }
}
path.HistoryViewModel = HistoryViewModel;
