import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
//import * as  backboneLawnchair from 'backbonelawnchair'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {BeepPackageMixin} from './BeepPackageMixin'
import {BeepPackage} from './BeepPackage'
import {CodeContainer} from './CodeContainer'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/beeppackage/BeepPackage","appbo/beeppackage/BeepPackageMixin","appbo/beeppackage/CodeContainer"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BeepPackage,BeepPackageMixin,CodeContainer
//){
	

	export class BeepModuleMixin {
		constructor(){
			var path = DataManager.getDataManager().buildAppNsPath("beeppackage",global.version);
			path.BeepModuleMixin = BeepModuleMixin;
		}
	
		defaults(){
			var ret = {
				type: "beeppackage_BeepModule"
			};
			return jQuery.extend(BeepPackage.prototype.defaults.apply(this),ret);
		}
		static getMixinRelations(){
			return _.union([
			{
				type :Backbone.HasMany,
				containingClass:"beeppackage_BeepModule",
				key:"artifact",
				relatedModel:"beeppackage.CodeContainer",
				reverseRelation: {
					key:"artifactOwner",
					type :Backbone.HasOne,
					includeInJSON:Backbone.Model.prototype.idAttribute
				}			
			}		
			]);
		};
		static getCumulativeMixinRelations(){
			if (!BeepModuleMixin.cummulativeRelations) {
				BeepModuleMixin.cummulativeRelations = _.union(BeepModuleMixin.getMixinRelations()
					, BeepPackageMixin.getCumulativeMixinRelations()
				);
			}
			return BeepModuleMixin.cummulativeRelations.slice();
		};
		static getSuperTypes(){
			return [
				"beeppackage_BeepPackage"
			];
		};
		static getProperties(){
			return [
				{ name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
				{ name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
				{name : "version",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" }
			]
		}
		getParent(){
			return this;
		}
		getPackagePath(path){
			if(!path){
				path = [];
			}
			return path;
		}
		getViewProperties(type){
			return {
				templatePath : "views/smm/views/properties/BeepModulePropertiesTemplate.html",
				templateName : "BeepModulePropertiesTemplate",
				viewTypeStr : "appviews/smm/views/properties/BeepModuleViewModel",
				tabId : "BeepModuleView",
				tabName: "BeepModule"
			}
		}
//#startCustomMethods
//#endCustomMethods
	//path.BeepModuleMixin = BeepModuleMixin;
	//return BeepModuleMixin;
//});
}