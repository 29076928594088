import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {WhatIfValueMixin} from './WhatIfValueMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
var WhatIfValue = EObject.extend(utils.customExtends({
        relations:WhatIfValueMixin.getMixinRelations(),
        initialize: function(attributes, options) {
            EObject.prototype.initialize.apply(this, arguments);
        }
    }
    , new WhatIfValueMixin()
));
//#startCustomMethods			
//#endCustomMethods
WhatIfValue.getName = function(){
    return "WhatIfValue";
};

var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
WhatIfValue.abstract = !sp2Enabled;
WhatIfValue.supported = sp2Enabled;
path.WhatIfValue = WhatIfValue;
export {WhatIfValue};
