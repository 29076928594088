import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {VdmlElementMixin} from '../vdml/VdmlElementMixin'
import { VdmlElement } from '../vdml/VdmlElement'
import { VdmlElement2Mixin } from '../vdml/VdmlElement2Mixin'
import {Presentation} from './Presentation'
/*
define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/VdmlElement", "appbo/vdml/VdmlElementMixin", "appbo/dashboard/Presentation"],
function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, VdmlElement, VdmlElementMixin, Presentation
){*/
    var path = DataManager.getDataManager().buildAppNsPath("dashboard", global.version);
	export class DashboardMixin{
		constructor(){
			var path = DataManager.getDataManager().buildAppNsPath("dashboard",global.version);
			path.DashboardMixin = DashboardMixin;
		}
	defaults(){
		var ret = {
			type: "dashboard_Dashboard"
		}
		var VdmlElement = Backbone.Relational.store.getObjectByName("vdml.VdmlElement");
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
	    return _.union([
		{
		    type: Backbone.HasMany,
		    containingClass: "dashboard_Dashboard",
		    key: "presentation",
		    relatedModel: "dashboard.Presentation",
		    includeInJSON: "id"
		}
		])
	}
	
	static getCumulativeMixinRelations(){
		if (!DashboardMixin.cummulativeRelations) {
            DashboardMixin.cummulativeRelations = _.union(DashboardMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return DashboardMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "progress", type: "double", defaultValue: "0", containingClass: "cmof_EObject" }
		]
    }
    calculateProgress () {

    }
	getParent(){
		var container;
		if(!container){
		    container = this.get("analytics") ? this.get("analytics") : this.previousAttributes().analytics;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
		    container = this.get("analytics") ? this.get("analytics") : this.previousAttributes().analytics;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type) {
	    if(type==="CreateChart"){
			return {
				templatePath : "views/dashboard/views/chart/CreateChartTemplate.html",
				templateName: "CreateChartTemplate",
				viewTypeStr: "appviews/dashboard/views/chart/CreateChartViewModel",
				tabId: "CreateChartView",
				tabName: "CreateChartView"
			  };			  
		}else{
			return {
				templatePath : "views/dashboard/views/chart/DashboardTemplate.html",
				templateName: "DashboardTemplate",
				viewTypeStr: "appviews/dashboard/views/chart/DashboardViewModel",
				tabId: "DashboardView",
				tabName: "DashboardView"
			  };
		}		
	}
	static getDialogViewProperties(type) {
	    if(type == "presentation"){
			return{
			   templatePath : "views/report/charts/ReportValuesChartTemplate.html",
			   templateName: "ReportValuesChartTemplate",
			   viewTypeStr: "appviews/report/charts/ReportValuesChartViewModel",
			   tabId: "ReportValuesChartView",
			   tabName: "ReportValuesChartView"
			};
		}
	    if(type==="CreateChart"){
			return {
				templatePath : "views/dashboard/views/chart/CreateChartTemplate.html",
				templateName: "CreateChartTemplate",
				viewTypeStr: "appviews/dashboard/views/chart/CreateChartViewModel",
				tabId: "CreateChartView",
				tabName: "CreateChartView"
			  };			  
	    }
	    if(type==="AddAlternative"){
			return {
				templatePath : "views/dashboard/views/chart/AddAlternativeTemplate.html",
				templateName: "AddAlternativeTemplate",
				viewTypeStr: "appviews/dashboard/views/chart/AddAlternativeViewModel",
				tabId: "AddAlternativeView",
				tabName: "AddAlternativeView"
			  };			  
	    }
	    if(type==="SelectChartValues"){
			return {
				templatePath : "views/dashboard/views/chart/SelectChartValuesTemplate.html",
				templateName: "SelectChartValuesTemplate",
				viewTypeStr: "appviews/dashboard/views/chart/SelectChartValuesViewModel",
				tabId: "SelectChartValuesView",
				tabName: "SelectChartValuesView"
			  };			  
	    }
	    if(type==="ImportData"){
			return {
				templatePath : "views/dashboard/views/chart/ImportDataTemplate.html",
				templateName: "ImportDataTemplate",
				viewTypeStr: "appviews/dashboard/views/chart/ImportDataViewModel",
				tabId: "ImportDataView",
				tabName: "ImportDataView"
			  };			  
	    }
	    if (type === "explorerModelJson") {
	        return {
	            templatePath: "views/beeppackage/views/properties/explorerModelJsonTemplate.html",
	            templateName: "explorerModelJsonTemplate",
	            viewTypeStr: "appviews/beeppackage/views/properties/explorerModelJsonViewModel",
	            tabId : "explorerModelJsonView",
	            tabName: "explorerModelJson"
	        };
	    }
	    if(type==="EditScenario"){
			return {
				templatePath : "views/dashboard/views/chart/ScenarioDetailsTemplate.html",
				templateName: "ScenarioDetailsTemplate",
				viewTypeStr: "appviews/dashboard/views/chart/ScenarioDetailsViewModel",
				tabId: "ScenarioDetailsView",
				tabName: "ScenarioDetailsView"
			  };			  
	    }
		return {
			templatePath : "views/dashboard/views/chart/DashboardTemplate.html",
			templateName: "DashboardTemplate",
			viewTypeStr: "appviews/dashboard/views/chart/DashboardViewModel",
			tabId: "DashboardView",
			tabName: "DashboardView"
		};	
	};
	
	removeScenarioJson(whenObserved,alt){
		var self= this;
		var new_list = self.get('config')?JSON.parse(self.get('config')):{};
		if(alt && new_list[alt]){
			var index = new_list[alt].indexOf(whenObserved);
			new_list[alt].splice(index,1);
		}
		if(!alt){
			for(var alt in new_list){
				var index = new_list[alt].indexOf(whenObserved);
				new_list[alt].splice(index,1);
			}
		}
		
		self.set('config',JSON.stringify(new_list));
	};


	//path.DashboardMixin = DashboardMixin;
	//return DashboardMixin;
}