import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurableElement} from '../vdml/MeasurableElement'
import {MeasurableElementMixin} from '../vdml/MeasurableElementMixin'
import {ValueInstance} from './ValueInstance'
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("instdef",global.version);

export class MeasurableElementInstanceMixin {

    defaults(){
        var ret = {
            type: "instdef_MeasurableElementInstance"
        }
        return jQuery.extend(MeasurableElement.prototype.defaults.apply(this),ret);
    }
    static getMixinRelations(){
        return _.union([
            {
                type: Backbone.HasMany,
                containingClass: "instdef_MeasurableElementInstance",
                key: "valueInstance",
                relatedModel: "instdef.ValueInstance",
                reverseRelation: {
                    key: "valueInstanceOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            }
        ])
    }
    static getCumulativeMixinRelations(){
        if (!MeasurableElementInstanceMixin.cummulativeRelations) {
            MeasurableElementInstanceMixin.cummulativeRelations = _.union(MeasurableElementInstanceMixin.getMixinRelations()
                , MeasurableElementMixin.getCumulativeMixinRelations()
            );
        }
        return MeasurableElementInstanceMixin.cummulativeRelations.slice();
    }
    static getSuperTypes(){
        return [
            "vdml_MeasurableElement"
        ];
    }
    static getProperties(){
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            {name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_ValueElement" },
            {name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_ValueElement" },
            {name : "measurableElementId",type : "EString",defaultValue : "null",containingClass : "instdef_MeasurableElementInstance" }
        ]
    }
    getParent(){
        var container;
		if(!container){
			container = this.get("measurableElementInstanceOwner") ? this.get("measurableElementInstanceOwner") : this.previousAttributes().measurableElementInstanceOwner;
			if(container){
				return container;
			}
		}
    }
    getPackagePath(path){
        if(!path){
            path = [];
        }
        return MeasurableElement.prototype.getPackagePath.apply(this, arguments);
    }
    getViewProperties(type){
        return {
            templatePath : "views/vdml/views/properties/MeasurableElementInstancePropertiesTemplate.html",
            templateName : "MeasurableElementInstancePropertiesTemplate",
            viewTypeStr : "appviews/vdml/views/properties/MeasurableElementInstanceViewModel",
            tabId : "MeasurableElementInstanceView",
            tabName: "MeasurableElementInstance"
        }
    }

}
path.MeasurableElementInstanceMixin = MeasurableElementInstanceMixin;
