import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {Measurement} from '../smm/Measurement'
import {Characteristic} from '../smm/Characteristic'


/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, Measurement
, Characteristic){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Measurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measurement){
			Measurement = importPath.Measurement;
		}
		else{
            import('../smm/Measurement').then(({ default: Measurement }) => {
                Measurement = Measurement;
			});
			/*require(["appbo/smm/Measurement"],function(loadedModule){
				Measurement = loadedModule;
			});*/
		}
	}
	if(!Characteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Characteristic){
			Characteristic = importPath.Characteristic;
		}
		else{
            import('../smm/Characteristic').then(({ default: Characteristic }) => {
                Characteristic = Characteristic;
			});
			/*require(["appbo/smm/Characteristic"],function(loadedModule){
				Characteristic = loadedModule;
			});*/
		}
	}
	export class MeasuredCharacteristicMixin {

	
	
	//Effective Scenario for Time stampLess data
	determineEffectiveScenario(useCommon,beepPackage,viewAlternative,callback){
		var self = this;
		var commonScenario = DataManager.getDataManager().get('currentCommonScenario');
		if(useCommon){
			checkMeasurements(commonScenario);
		}else{
			if(viewAlternative instanceof Backbone.Model){
				viewAlternative = viewAlternative.get('id');
			}
			DataManager.getDataManager().getMainScenario(beepPackage,viewAlternative,function(mainScenario){
				checkMeasurements(mainScenario);
			});
		}
		function checkMeasurements(defaultScenario){
			if(defaultScenario){
				if(defaultScenario.hasMeasurements(self,false)){
					callback(defaultScenario);
				}else if(commonScenario && commonScenario.hasMeasurements(self,false)){
					callback(commonScenario);
				}else{
					callback(defaultScenario);
				}
			}else{
				callback(commonScenario);
			}
		}

	};
	determineEffectiveScenarioTimeStamped(useCommon,beepPackage,viewAlternative,timeStamp,callback){
		var self = this;
		var commonScenario = DataManager.getDataManager().get('currentCommonScenario');
		if(useCommon){
			checkMeasurements(commonScenario);
		}else{
			DataManager.getDataManager().getMainScenario(beepPackage,viewAlternative,function(mainScenario){
				checkMeasurements(mainScenario);
			});
		}		
		function checkMeasurements(defaultScenario){
			if(defaultScenario){
				if(defaultScenario.hasMeasurements(self,true,timeStamp)){
					callback(defaultScenario);
				}else if(commonScenario && commonScenario.hasMeasurements(self,true,timeStamp)){
					callback(commonScenario);
				}else{
					callback(defaultScenario);
				}
			}else{
				callback(commonScenario);
			}
		};
	};	

	defaults(){
		var ret = {
			type: "vdml_MeasuredCharacteristic"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_MeasuredCharacteristic",
			key:"measurement",
			relatedModel:"smm.Measurement",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"measuredCharacteristic",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_MeasuredCharacteristic",
			key:"characteristicDefinition",
			relatedModel:"smm.Characteristic",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"measuredCharacteristic",
				type :Backbone.HasMany,
				includeInJSON:"id"
			}			
		}
		])
	}
	
	static getCumulativeMixinRelations(){
		if (!MeasuredCharacteristicMixin.cummulativeRelations) {
            MeasuredCharacteristicMixin.cummulativeRelations = _.union(MeasuredCharacteristicMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return MeasuredCharacteristicMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            //{ name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "progress", type: "double", defaultValue: "0", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
			//{name : "valueFormula",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
    }

    calculateProgress () {

    }
    getParent () {
        var container;
        var previousAttributes = this.previousAttributes();
        container = this.get("valueMeasurementOwner") ? this.get("valueMeasurementOwner") : previousAttributes.valueMeasurementOwner;
        if (container) {
            return container;
        }
        container = this.get("percentageWeightOwner") ? this.get("percentageWeightOwner") : previousAttributes.percentageWeightOwner;
        if (container) {
            return container;
        }
        container = this.get("satisfactionLevelOwner") ? this.get("satisfactionLevelOwner") : previousAttributes.satisfactionLevelOwner;
        if (container) {
            return container;
        }
        container = this.get("recipientOpinionOwner") ? this.get("recipientOpinionOwner") : previousAttributes.recipientOpinionOwner;
        if (container) {
            return container;
        }
        container = this.get("durationOwnerActivity") ? this.get("durationOwnerActivity") : previousAttributes.durationOwnerActivity;
        container = this.get("durationOwnerFlow") ? this.get("durationOwnerFlow") : previousAttributes.durationOwnerFlow;
        if (container) {
            return container;
        }
        container = this.get("durationOwnerResourceUse") ? this.get("durationOwnerResourceUse") : previousAttributes.durationOwnerResourceUse;
        if (container) {
            return container;
        }
        container = this.get("durationOwnerStore") ? this.get("durationOwnerStore") : previousAttributes.durationOwnerStore;
        if (container) {
            return container;
        }
        container = this.get("recurrenceIntervalOwner") ? this.get("recurrenceIntervalOwner") : previousAttributes.recurrenceIntervalOwner;
        if (container) {
            return container;
        }
        container = this.get("heatIndexOwner") ? this.get("heatIndexOwner") : previousAttributes.heatIndexOwner;
        if (container) {
            return container;
        }
        container = this.get("durationOwner") ? this.get("durationOwner") : previousAttributes.durationOwner;
        if (container) {
            return container;
        }
        container = this.get("poolSizeOwner") ? this.get("poolSizeOwner") : previousAttributes.poolSizeOwner;
        if (container) {
            return container;
        }
        container = this.get("planningPercentageOwnerPort") ? this.get("planningPercentageOwnerPort") : previousAttributes.planningPercentageOwnerPort;
        if (container) {
            return container;
        }
        container = this.get("batchSizeOwner") ? this.get("batchSizeOwner") : previousAttributes.batchSizeOwner;
        if (container) {
            return container;
        }
        container = this.get("offsetOwner") ? this.get("offsetOwner") : previousAttributes.offsetOwner;
        if (container) {
            return container;
        }
        container = this.get("quantityOwner") ? this.get("quantityOwner") : previousAttributes.quantityOwner;
        if (container) {
            return container;
        }
        container = this.get("durationOwner") ? this.get("durationOwner") : previousAttributes.durationOwner;
        if (container) {
            return container;
        }
        container = this.get("planningPercentageOwnerResourceUse") ? this.get("planningPercentageOwnerResourceUse") : previousAttributes.planningPercentageOwnerResourceUse;
        if (container) {
            return container;
        }
        container = this.get("durationOwner") ? this.get("durationOwner") : previousAttributes.durationOwner;
        if (container) {
            return container;
        }
        container = this.get("inventoryLevelOwner") ? this.get("inventoryLevelOwner") : previousAttributes.inventoryLevelOwner;
        if (container) {
            return container;
        }
        container = this.get("measuredCharacteristicOwner") ? this.get("measuredCharacteristicOwner") : previousAttributes.measuredCharacteristicOwner;
        if (container) {
            return container;
        }
        container = this.get("horizonOwner") ? this.get("horizonOwner") : previousAttributes.horizonOwner;
        if (container) {
            return container;
        }
        container = this.get("heatThresholdOwner") ? this.get("heatThresholdOwner") : previousAttributes.heatThresholdOwner;
        if (container) {
            return container;
        }
        container = this.get("benchmarkOwner") ? this.get("benchmarkOwner") : previousAttributes.benchmarkOwner;
        if (container) {
            return container;
        }
        return this;
    };
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
        var previousAttributes = this.previousAttributes();
        container = this.get("valueMeasurementOwner") ? this.get("valueMeasurementOwner") : previousAttributes.valueMeasurementOwner;
        if (container) {
            path.unshift({ id: this.get('id'), name: this.get('name') })
            return container.getPackagePath(path);
        }
		if(!container){
			container = this.get("percentageWeightOwner") ? this.get("percentageWeightOwner") : previousAttributes.percentageWeightOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("satisfactionLevelOwner") ? this.get("satisfactionLevelOwner") : previousAttributes.satisfactionLevelOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("recipientOpinionOwner") ? this.get("recipientOpinionOwner") : previousAttributes.recipientOpinionOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}		
		if(!container){
			container = this.get("durationOwner") ? this.get("durationOwner") : previousAttributes.durationOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("recurrenceIntervalOwner") ? this.get("recurrenceIntervalOwner") : previousAttributes.recurrenceIntervalOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("heatIndexOwner") ? this.get("heatIndexOwner") : previousAttributes.heatIndexOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("durationOwner") ? this.get("durationOwner") : previousAttributes.durationOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("poolSizeOwner") ? this.get("poolSizeOwner") : previousAttributes.poolSizeOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("planningPercentageOwner") ? this.get("planningPercentageOwner") : previousAttributes.planningPercentageOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("batchSizeOwner") ? this.get("batchSizeOwner") : previousAttributes.batchSizeOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("offsetOwner") ? this.get("offsetOwner") : previousAttributes.offsetOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("quantityOwner") ? this.get("quantityOwner") : previousAttributes.quantityOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("durationOwner") ? this.get("durationOwner") : previousAttributes.durationOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("planningPercentageOwner") ? this.get("planningPercentageOwner") : previousAttributes.planningPercentageOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("durationOwner") ? this.get("durationOwner") : previousAttributes.durationOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("inventoryLevelOwner") ? this.get("inventoryLevelOwner") : previousAttributes.inventoryLevelOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("measuredCharacteristicOwner") ? this.get("measuredCharacteristicOwner") : previousAttributes.measuredCharacteristicOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("horizonOwner") ? this.get("horizonOwner") : previousAttributes.horizonOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("heatThresholdOwner") ? this.get("heatThresholdOwner") : previousAttributes.heatThresholdOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("benchmarkOwner") ? this.get("benchmarkOwner") : previousAttributes.benchmarkOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/MeasuredCharacteristicPropertiesTemplate.html",
			templateName : "MeasuredCharacteristicPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/MeasuredCharacteristicViewModel",
			tabId : "MeasuredCharacteristicView",
			tabName: "MeasuredCharacteristic"
		}
	}
	
	getObservedMeasure(timeStamp,scenario){
		var self = this;
		var obsMeasure;
		
		//TODO timeStamp,scenario
		return obsMeasure;
	};
//#startCustomMethods
	copy(container,measurementsCopied){
		var measurand = this.getMeasurandDetails();
		if(measurand){
			var reverseRole = measurand.reverseRole;
			var id = DataManager.getDataManager().guidGeneratorByOwner(this);
			var mcCopy = new this.constructor({'id':id,name:'Copy of ' + this.get('name'),description:this.get('description'),reverseRole:container});
			var mests = this.get('measurement');
			mests.each(function(mest){
				var obsMeas = mest.get('observedMeasure');
				var measure = obsMeas.get('measure');
				var observation = obsMeas.get('observation');
				var obsMeasId =  DataManager.getDataManager().guidGeneratorByOwner(obsMeas);
				var obsMeasCopy = new obsMeas.constructor({id:obsMeasId,name:observation.getUniqueProperty('name','Observed ' + measure.get('name'),'observedMeasure'),'observation':observation});
				obsMeasCopy.set('measure',measure);
				var mestId =  DataManager.getDataManager().guidGeneratorByOwner(obsMeasCopy);
				var mestCopy = new mest.constructor({id:mestId,name:'Copy of ' + mest.get('name'),observedMeasure:obsMeasCopy});
				mcCopy.get('measurement').add(mestCopy);
				if(measurementsCopied){
					var copyObj = {'measurement':mest,'measurementCopy':mestCopy};
					measurementsCopied.push(copyObj);
				}				
			});
			return mcCopy;
		}
	};
	getMeasurandDetails(){
		var container;
		var previousAttributes = this.previousAttributes();
		if(!container){
			container = this.get("valueMeasurementOwner") ? this.get("valueMeasurementOwner") : previousAttributes.valueMeasurementOwner;
			if(container){
				return {measurand:container,role:"valueMeasurement",reverseRole:"valueMeasurementOwner"};
			}
		}
		if(!container){
			container = this.get("satisfactionLevelOwner") ? this.get("satisfactionLevelOwner") : previousAttributes.satisfactionLevelOwner;
			if(container){
				return {measurand:container,role:"satisfactionLevel",reverseRole:"satisfactionLevelOwner"};
			}
		}
		if(!container){
			container = this.get("recipientOpinionOwner") ? this.get("recipientOpinionOwner") : previousAttributes.recipientOpinionOwner;
			if(container){
				return {measurand:container,role:"recipientOpinion",reverseRole:"satisfactionLevelOwner"};
			}
		}
		if(!container){
			container = this.get("percentageWeightOwner") ? this.get("percentageWeightOwner") : previousAttributes.percentageWeightOwner;
			if(container){
				return {measurand:container,role:"percentageWeight",reverseRole:"percentageWeightOwner"};
			}
		}		
		if(!container){
			container = this.get("measuredCharacteristicOwner") ? this.get("measuredCharacteristicOwner") : previousAttributes.measuredCharacteristicOwner;
			if(container){
				return {measurand:container,role:"measuredCharacteristic",reverseRole:"measuredCharacteristicOwner"};
			}
		}
		if(!container){
			container = this.get("durationOwnerActivity") ? this.get("durationOwnerActivity") : previousAttributes.durationOwnerActivity;
			if(container){
				return {measurand:container,role:"duration",reverseRole:"durationOwnerActivity"};
			}				
			container = this.get("durationOwnerFlow") ? this.get("durationOwnerFlow") : previousAttributes.durationOwnerFlow;
			if(container){
				return {measurand:container,role:"duration",reverseRole:"durationOwnerFlow"};
			}
			container = this.get("durationOwnerResourceUse") ? this.get("durationOwnerResourceUse") : previousAttributes.durationOwnerResourceUse;
			if(container){
				return {measurand:container,role:"duration",reverseRole:"durationOwnerResourceUse"};
			}				
			container = this.get("durationOwnerStore") ? this.get("durationOwnerStore") : previousAttributes.durationOwnerStore;
			if(container){
				return {measurand:container,role:"duration",reverseRole:"durationOwnerStore"};
			}
		}
		if(!container){
			container = this.get("recurrenceIntervalOwner") ? this.get("recurrenceIntervalOwner") : previousAttributes.recurrenceIntervalOwner;
			if(container){
				return {measurand:container,role:"recurrenceInterval",reverseRole:"recurrenceIntervalOwner"};
			}
		}
		if(!container){
			container = this.get("heatIndexOwner") ? this.get("heatIndexOwner") : previousAttributes.heatIndexOwner;
			if(container){
				return {measurand:container,role:"heatIndex",reverseRole:"heatIndexOwner"};
			}
		}
		if(!container){
			container = this.get("durationOwner") ? this.get("durationOwner") : previousAttributes.durationOwner;
			if(container){
				return {measurand:container,role:"duration",reverseRole:"durationOwner"};
			}
		}
		if(!container){
			container = this.get("poolSizeOwner") ? this.get("poolSizeOwner") : previousAttributes.poolSizeOwner;
			if(container){
				return {measurand:container,role:"poolSize",reverseRole:"poolSizeOwner"};
			}
		}
		if(!container){
			container = this.get("planningPercentageOwnerPort") ? this.get("planningPercentageOwnerPort") : previousAttributes.planningPercentageOwnerPort;
			if(container){
				return {measurand:container,role:"planningPercentage",reverseRole:"planningPercentageOwnerPort"};
			}
		}
		if(!container){
			container = this.get("batchSizeOwner") ? this.get("batchSizeOwner") : previousAttributes.batchSizeOwner;
			if(container){
				return {measurand:container,role:"batchSize",reverseRole:"batchSizeOwner"};
			}
		}
		if(!container){
			container = this.get("offsetOwner") ? this.get("offsetOwner") : previousAttributes.offsetOwner;
			if(container){
				return {measurand:container,role:"offset",reverseRole:"offsetOwner"};
			}
		}
		if(!container){
			container = this.get("quantityOwner") ? this.get("quantityOwner") : previousAttributes.quantityOwner;
			if(container){
				return {measurand:container,role:"quantity",reverseRole:"offsetOwner"};
			}
		}
		if(!container){
			container = this.get("durationOwner") ? this.get("durationOwner") : previousAttributes.durationOwner;
			if(container){
				return {measurand:container,role:"duration",reverseRole:"durationOwner"};
			}
		}
		if(!container){
			container = this.get("planningPercentageOwnerResourceUse") ? this.get("planningPercentageOwnerResourceUse") : previousAttributes.planningPercentageOwnerResourceUse;
			if(container){
				return {measurand:container,role:"planningPercentage",reverseRole:"planningPercentageOwnerResourceUse"};
			}
		}
		if(!container){
			container = this.get("durationOwner") ? this.get("durationOwner") : previousAttributes.durationOwner;
			if(container){
				return {measurand:container,role:"duration",reverseRole:"durationOwner"};
			}
		}
		if(!container){
			container = this.get("inventoryLevelOwner") ? this.get("inventoryLevelOwner") : previousAttributes.inventoryLevelOwner;
			if(container){
				return {measurand:container,role:"inventoryLevel",reverseRole:"inventoryLevelOwner"};
			}
		}
		if(!container){
			container = this.get("horizonOwner") ? this.get("horizonOwner") : previousAttributes.horizonOwner;
			if(container){
				return {measurand:container,role:"horizon",reverseRole:"horizonOwner"};
			}
		}
		if(!container){
			container = this.get("heatThresholdOwner") ? this.get("heatThresholdOwner") : previousAttributes.heatThresholdOwner;
			if(container){
				return {measurand:container,role:"heatThreshold",reverseRole:"heatThresholdOwner"};
			}
		}
		if(!container){
			container = this.get("benchmarkOwner") ? this.get("benchmarkOwner") : previousAttributes.benchmarkOwner;
			if(container){
				return {measurand:container,role:"benchmark",reverseRole:"benchmarkOwner"};
			}
		}
	};
	
	/*MeasuredCharacteristicMixin.prototype.onBeforeDestroy(){
		debugger;
		return true;
	};*/
	getMeasurand(){
		var details = this.getMeasurandDetails();
		if(details){
			return details.measurand;
		}
	};

	calculate(mainScenario,commonScenario){
		var self = this;
		if(mainScenario){
			mainScenario.calculate(self);
		}
		if(commonScenario){
			commonScenario.calculate(self);
		}
	};
	
//#endCustomMethods	
	
	}
	path.MeasuredCharacteristicMixin = MeasuredCharacteristicMixin;
	//return MeasuredCharacteristicMixin;
//});