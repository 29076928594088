import * as _ from 'underscore'
import { EObjectMixin } from "../cmof/EObjectMixin";
import {DataManager} from '../../../com/vbee/data/DataManager'
import { BeepPackage } from '../beeppackage/BeepPackage';
import {WhatIfValue} from './WhatIfValue';
import { EObject } from '../cmof/EObject';
import { ScenarioValueConstraints } from './ScenarioValueConstraints'; 

var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);

export class ScenarioPathStepMixin{
    defaults(){
		var ret = {
			type: "transformation_ScenarioPathStep",
			version: DataManager.getDataManager().get('currentVDMVersion')
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
    static getMixinRelations(){
		return _.union([
        {
		    type: Backbone.HasOne,
		    containingClass: "transformation_ScenarioPathStep",
		    key: "next",
		    relatedModel: "transformation.ScenarioPathStep",
            includeInJSON: "id",
		    reverseRelation: {
		        key: "previous",
		        type: Backbone.HasOne,
		        includeInJSON: "id"
		    }
		},
        {
		    type: Backbone.HasMany,
		    containingClass: "transformation_ScenarioPathStep",
		    key: "WhatIfValue",
		    relatedModel: "transformation.WhatIfValue",
		    reverseRelation: {
		        key: "WhatIfValueOwner",
		        type: Backbone.HasOne,
		        includeInJSON: "id"
		    }
		},
		{
		    type: Backbone.HasMany,
		    containingClass: "transformation_ScenarioPathStep",
		    key: "constraints",
		    relatedModel: "transformation.ScenarioValueConstraints",
		    reverseRelation: {
		        key: "constraintsOwner",
		        type: Backbone.HasOne,
		        includeInJSON: "id"
		    }
		}
		]);
	};
	static getCumulativeMixinRelations(){
		if (!ScenarioPathStepMixin.cummulativeRelations) {
            ScenarioPathStepMixin.cummulativeRelations = _.union(ScenarioPathStepMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return ScenarioPathStepMixin.cummulativeRelations.slice();
	};
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            {name : "phaseId",type : "EString",defaultValue : "null",containingClass : "transformation_ScenarioPathStep" },
            {name : "alternativeId",type : "EString",defaultValue : "null",containingClass : "transformation_ScenarioPathStep" },
            {name : "startPeriod",type : "int",defaultValue : "null",containingClass : "transformation_ScenarioPathStep" },
            {name : "noPeriods",type : "int",defaultValue : "null",containingClass : "transformation_ScenarioPathStep" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("stepOwner") ? this.get("stepOwner") : this.previousAttributes().stepOwner;
			if(container){
				return container;
			}
		}
		return this;	
    }
    // constructor(id,ScenarioPathStep,phaseId,alternativeId,startPeriod,noPeriods,nextId,previousId){
    //     this.id = id;
    //     this.phaseId = phaseId;
    //     this.alternativeId = alternativeId;
    //     this.startPeriod = startPeriod;
    //     this.noPeriods = noPeriods;
    //     this.next = null;
    //     this.previous = null;
    //     this.ScenarioPathStep = ScenarioPathStep;
    //     this.nextId = nextId;
    //     this.previousId = previousId;
    //     this.periodDataset = {};
    //     this.input = {};
    // }
    setNext(nextPathStep){
        if(nextPathStep){
            this.setNext("next",nextPathStep);
            nextPathStep.set("previous" , this);
        }else{
            
        }
    }
    setPrevious(previousPathStep){
        if(previousPathStep){
            this.set("previous" , previousPathStep);
            previousPathStep.set("next",this);
        }else{
            this.set("previous",null);
        }
    }
}
path.ScenarioPathStepMixin = ScenarioPathStepMixin;