import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { ValueDeliveryModelMixin } from '../../../../../version1/bo/vdml/ValueDeliveryModelMixin'
import { CodeContainer } from '../../../../../version1/bo/beeppackage/CodeContainer'
import * as jszip from '../../../../../../libs/jszip/jszip.min'
import {PlanMixin} from '../../../../../version1/bo/transformation/PlanMixin'

/*define(["require", "jquery", "underscore", "backbone", "jszip", "knockout", "bootbox", "async", "knockoutMapping", "knockback", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/ValueDeliveryModelMixin", "appbo/beeppackage/CodeContainer"],
	function (require, $, _, Backbone, jszip, ko, bootbox, async, koMapping, kb, DataManager, global, ValueDeliveryModelMixin, CodeContainer)
{*/
	var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties",global.version);
	export class EditValueDeliveryModelDetailsViewModel{
		
        fillBusinessModels() {
            var self = this;
			var bmList = self.model.get('businessModel');
			bmList.each(function(bm){
				self.businessModelColl.push({name:bm.get('name'), description: bm.get('description'), seqId:bm.get('id')});
			});
		}
		
        fillBusinessCanvases() {
            var self = this;
			var bmcList = self.model.get('canvas');
			bmcList.each(function(bmc){
				self.businessCanvasColl.push({name:bmc.get('name'), description: bmc.get('description'), seqId:bmc.get('id')});
			});
		}
        fillCapMaps() {
            var self = this;
			var capMapList = self.model.get('diagrams');
			capMapList.each(function (cm) {
			   if(cm.get('type') == 'vdml_ProcessDiscoveryDiagram'){
				 self.processModel.push({ name: cm.get('name'), description: cm.get('description'), seqId: cm.get('id') });
				}
				else if(cm.get('type') == 'vdml_CmmnDiagram'){
					self.caseModel.push({ name: cm.get('name'), description: cm.get('description'), seqId: cm.get('id') })
				}
				else if(cm.get('type') == 'vdml_CapabilityLibraryDiagram'){
				     self.capMap.push({ name: cm.get('name'), description: cm.get('description'), seqId: cm.get('id') });
				}
			});
		}
        fillCapLibs() {
            var self = this;
			var capLibList = self.model.get('capabilityLibrary');
			capLibList.each(function (capLib) {
			    if(capLib.get('type') == 'vdml_CapabilityLibrary'){
				     self.capLibColl.push({ name: capLib.get('name'), description: capLib.get('description'), seqId: capLib.get('id') });
				}
			});
		}

		fillValLibs() {
            var self = this;
			var valLibList = self.model.get('valueLibrary');
			valLibList.each(function (valLib) {
			    if(valLib.get('type') == 'vdml_ValueLibrary'){
				     self.valLibColl.push({ name: valLib.get('name'), description: valLib.get('description'), seqId: valLib.get('id') });
				}
			});
		}
        
        /*fillMeasurements() {
            var self = this;
            var measurementList = self.model.get('metricsModel');//.get('measurementLibraries');
            measurementList.each(function (measurement) {
                var measure = measurement.get('businessIndicatorLibraries');
                measure.each(function (mea) {
                    if (mea.get('type') == 'smm_BusinessIndicatorLibrary') {
                        self.measureColl.push({ name: mea.get('name'), description: mea.get('description'), seqId: mea.get('id') });
                    }
                });
            });
        }*/
		
        cleanAndReturnModal() {
            var self = this;
			self.cleanModal && self.cleanModal();
		}		
		
        deleteVDM() {
            var self = this;
	    	function doDelete(){
				var selfModel = self.options.addOptions.model;
				var packAlts = [];
				var currentPlan = DataManager.getDataManager().get('currentPlan');
				var phases = currentPlan.get('phase');
		        for(var i=0;i<phases.length;i++) {
		            var alternatives = phases.models[i].get('phaseAlternative');
		            for(var j=0;j<alternatives.length;j++){
		            	var packageRefs = alternatives.at(j).get('phaseDesignPart');
		            	packageRefs.each(function(packageRef){
			            	if(packageRef.get('beepReference') === selfModel.get('id')){
			            		packAlts.push(alternatives.at(j));
			            	}
		            	});
		            }
		        }
		        async.eachSeries(packAlts,function(packAlt,altHandleCallback){
		        	DataManager.getDataManager().getRDFModel(packAlt.get('id')).getOneLevelDependentOnPackages(selfModel.get('id'),selfModel.get('version'),function(result){
		        		var len = result.length;
		                while (len--) {
		                    if(result[len].id === DataManager.getDataManager().get("currentPlan").get("id") || result[len].type === "ecomap_EcoMap") {
		                        result.splice(len,1);
		                    }
		                }    
		        		if(result.length>0){
		        			altHandleCallback(""+DataManager.getDataManager().get('localeManager').get('DeletePackageBlocked'));
		        		}else{
		        			altHandleCallback();
		        		}
		        	});
		        },function(err){
		        	if(err){
		        		window.utils.stopSpinner('deletePackage');
		        		bootbox.alert(err);
		        	}else{
						var packScenarios = selfModel.get('scenario');
	        			var scenarioIds = [];
	        			packScenarios.each(function(packScenario){
	        				scenarioIds.push(packScenario.get('id'));
	        			});
	        			selfModel.destroy = selfModel.constructor.prototype.destroy;
	        			selfModel.destroy({success:function(){
							//DataManager.getDataManager().get('changedParentObjects').remove(self.model);
	    					//DataManager.getDataManager().get('changedObjects').remove(self.model);
							var dataManager = DataManager.getDataManager();
							dataManager.removeArtifacts(selfModel.id);
							
							if(selfModel.get('libraryPackage')){
								self.cleanAndReturnModal();
								window.utils.stopSpinner('deletePackage');
							}else {
								_.each(packAlts,function(alt){
			        				var packRefs = alt.get('phaseDesignPart').models.concat();
			        				_.each(packRefs,function(packRef){
			        					if(packRef.get('beepReference') === selfModel.get('id')){
			        						packRef.destroy();
			        					}
			        				});
		        				
		        					_.each(scenarioIds,function(scenarioId){
		        						var altScenarios = alt.get('designScenario').models.concat();
		        						_.each(altScenarios,function(altScenario){
		        							if(altScenario.get('scenario') === scenarioId){
		        								altScenario.destroy();
		        							}
		        						});
		        					});

			        			});
			        			dataManager.invokeValueUpdates(function(){
				        			self.cleanAndReturnModal();
				        			dataManager.loadAltScenarios(dataManager,dataManager.get('currentPlan'),function(){
										window.utils.showSpinnerText('Saving Data ... ');
										dataManager.saveChangeSetToBackend(dataManager.get(dataManager.CURRENT_CHANGESET),function(response){
											dataManager.applyWorkspaceById(dataManager.get("currentWorkspaceId"),function(){
												window.utils.stopSpinner('deletePackage');					                	
											});
										});
									});
								});
							}
	        				
						},error:function(){
							console.log('Failed to remove package');
							DataManager.getDataManager().removeInitializedPackage(selfModel.get('id'));
							self.cleanAndReturnModal();
							window.utils.stopSpinner('deletePackage');
						}});	
		        	}
		        });
				
			}
			if(self.model.get('capabilityLibrary').length>0 || self.model.get('businessModel').length>0 || self.model.get('canvas').length>0){
				var message = DataManager.getDataManager().get('localeManager').get('DeletePackage');
				if(self.checkDependentPackages()){
					message = DataManager.getDataManager().get('localeManager').get('DeletePackageBlocked');
					bootbox.alert(message, function() {
		                self.cleanAndReturnModal();
		            });
				}
				else {
						bootbox.confirm(message, function(result) {
		                if(result){
		                	window.utils.startSpinner('deletePackage','Deleting Package...');
		                    setTimeout(doDelete,100);
		                }
		                else {
		                	self.cleanAndReturnModal();
		                }
		            });
				}
			}
			else{
				setTimeout(doDelete,0);
			}
		};
		
        exportPackage(view, event) {
            var self = this;
			event.stopPropagation();
			//var suggestion = self.model.get('name').replace(/\"/g, "");
			window.utils.startSpinner('exportCapabilityLibraryPackage','Exporting Package ...');
			window.setTimeout(function(){
				createZip(self.model,function(content){
					window.utils.stopSpinner('exportCapabilityLibraryPackage');
					/*if(window.utils.checkChrome() && chrome.fileSystem && chrome.fileSystem.chooseEntry){
						chrome.fileSystem.chooseEntry({type: 'saveFile',suggestedName:suggestion,accepts:[{extensions:['vpk']}]}, function(f) {
							if (!chrome.runtime.lastError) {
							  f.remove(function() {
								  window.utils.saveCurrentEntry(f,content,self.model.get('name'));
								}, function(e){
					    			console.log(e);
					    			bootbox.alert('Export error: '+e);
								});
							}
							else{
				    			console.log(chrome.runtime.lastError);
				    			if(chrome.runtime.lastError.message !== 'User cancelled') {
				    				bootbox.alert('Export error: '+chrome.runtime.lastError);
				    			}
							}
						});			
					}else {*/
						var exportMsg = DataManager.getDataManager().get('localeManager').get('exportComplete',self.model.get('name'));
						window.utils.exportFileToSystem(self.model.get('name'),'VPK File',".vpk",content,exportMsg);
					//}
				});
			},10);
			
			function createZip(model,callback){
				var text = JSON.stringify(model);
				var zip = new jszip();
				zip.file(self.model.get('id') + ".json", text);
				var manifestJson = window.utils.generateManifest(self.model);
				zip.file("manifest.json",manifestJson);
				var planId = self.model.id.substr(0,self.model.id.lastIndexOf('@')+1);
				var commonAltId = planId;
				function loadCodeContainers(codeContainers) {
					async.eachSeries(codeContainers.models, function (codeContainer, dataHandled) {
						var containerExists = false;
						_.each(self.model.get('diagrams').models,function(diag){
							var xml = diag.get('data');
							if(xml){
								var xmlDoc = jQuery.parseXML(xml);
								var $xml = jQuery(xmlDoc);
								var nodes = $xml.find('[backgroundUrl="' + codeContainer.id + '"]');
								if(nodes && nodes.length > 0){
									containerExists = true;
								}
							}
						});
						if(containerExists){
							CodeContainer.getBlobInstanceWithId(codeContainer.id, DataManager.getDataManager().get('currentPlan'), function (codeContainer) {
								if (codeContainer && codeContainer.getParent() && codeContainer.getParent().get('type') != 'beeppackage_BeepModule') {
									var ext = codeContainer.get('name').substr(codeContainer.get('name').lastIndexOf('.') + 1);
									if (ext !== "html") {
										var text = JSON.stringify(codeContainer.toJSON());
										zip.file(codeContainer.get('id') + ".json", text);
										DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
									}
								} else {
									console.log('Unable to load selected Package');
								}
								dataHandled();
							});
						} else {
							dataHandled();
						}
					}, function () {
						zip.generateAsync({ type: "blob" })
							.then(function (content) {
								callback(content);
							});
					});
				}
				DataManager.getDataManager().getRDFModel(commonAltId).getAllDocumentsCollectionOfType('beeppackage_CodeContainer', loadCodeContainers, null, null, 'vbc:cmof_EObject-name', null, true);
			}					
		};
		
        checkDependentPackages() {
            var self = this;
			var depPackExists = false;
			var packSuffix = window.utils.getSuffix(self.model.get('id'));
			var dependentPackage = self.model.get('dependentPackage');
			for(var i=0;i<dependentPackage.length;i++){
				if(dependentPackage.at(i).get('type') !== "beeppackage_CodeContainer"){
					var depSuffix = window.utils.getSuffix(dependentPackage.at(i).get('id'));
					if(depSuffix !== packSuffix){
						depPackExists = true;
						break;
					}
				}
			}
			return depPackExists;
		};
		
		showobjectExplorerTree(){
			var self = this;
			//console.log(JSON.stringify(self.model.toJSON()));
			var addOptions= this;
			var id = window.utils.htmlEscape(window.guidGenerator()) + self.encodeId;
			window.getAndCreateModalDialog(true,id,ValueDeliveryModelMixin,self.options.addOptions.model,"explorerModelJson",closeView,addOptions);
			function closeView(){
				window.cleanDialogModel(id,ValueDeliveryModelMixin);
			}
		};
        saveVDMdetails() {
            var self = this;
	    	if(self.model){
	    		self.model.set('name',self.name());
	    		self.model.set('description',self.description());
	    	}
	    	self.cleanAndReturnModal();
		};
		
        cleanModal(){
            var self = this;
            var options = self.options;
			if(self.parentView && self.id()){
				window.cleanDialogModel(self.encodeId,self);
			}
			options.callback(options.addOptions.searchValue);
		};
		/*createLabel(view, event){
			var self = this;
			var dataManager = DataManager.getDataManager();
			var currentPlan = dataManager.get('currentPlan');
			event.stopPropagation();
			window.utils.startSpinner('createLabel','Creating Label ...');
			window.setTimeout(function(){
				var serverChangeSet = dataManager.get(dataManager.CURRENT_CHANGESET);
				dataManager.saveChangeSetToBackend(serverChangeSet,function(){
					currentPlan.generatePlanJsons(function(planJsons){
						var newVersion = window.revisionGuidGenerator();
						var planName = currentPlan.get("name");
						var planDescription = currentPlan.get("description");

						currentPlan.unregisterPlan(false,function(){
							PlanMixin.createPlanFromFiles(planJsons, null, planName, planDescription,true,false, function () {
								//DataManager.getDataManager().get('router').navigate("search/Tag: \"Plan\"", { trigger: true });
								window.utils.stopSpinner('createLabel');
								self.cleanAndReturnModal();
							},true);
						})
					});
				});
			},100);
		}*/

        afterRenderView(node, view){
            var self = view;
			$("#packageName"+self.encodeId).keyup(function (view) {
				var name = view.currentTarget.value.trim();
				var found = false;
	            if (name !== "") {    
	                for (var i = 0; i < self.packageList.length; i++) {
	                    if (self.packageList[i].label === name && self.packageList[i].id !== self.id()){
	                        found = true;
	                        $('.warning').removeClass('hide');
	 	                    $('#complete').prop("disabled", true);
	 	                    break;
	                    }
	                }
	                if(!found){
	                	$('.warning').addClass('hide');
	                	$('#complete').prop("disabled", false);
	                }
	            }
		    });
		    if(self.model.get('libraryPackage')){
		    	self.enableExportPackage(true);
		    }
		};
		submitToMarket() {
			var self = this;
			var dataManager = DataManager.getDataManager();
			var packDoc = dataManager.get("artifactsDocuments")[self.model.id];
			window.utils.startSpinner('checking','Checking...');
			dataManager.saveChangeSetToBackend(dataManager.get(dataManager.CURRENT_CHANGESET),function(response){
				dataManager.applyWorkspaceById(dataManager.get("currentWorkspaceId"),function(){
					dataManager.checkOwnedPackage(self.model,function(resp){
						window.utils.stopSpinner('checking');
						if(resp && self){
							/*if(resp.productId){
								self.productId = resp.productId;
							}*/
							if(resp.owner){
								var planId = dataManager.get('currentPlan').id;
								var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
								if(resp.marketplaceId){
									dataManager.navigateToCrmPage("workspace/"+currentWorkspaceId+"/submitted/"+resp.marketplaceId+"/"+packDoc.artifactId);
								} else {
									dataManager.navigateToCrmPage("workspace/"+currentWorkspaceId+"/"+packDoc.artifactId+"/marketplace");
								}
							}else {
								bootbox.alert("Package Submission is not allowed");
							}
							/*if(resp.published){
								self.publishedDate(new Date(resp.publishedDate).toDateString());
							}*/                     
						}
					});
				});
			});
			//var planDocVersion = planDoc ? planDoc.documentVersion : self.model.get('documentVersion');
		};
		
		
init(model, options){
    var self = this;
    this.EditValueDeliveryModelDetailsViewModel = this;
    this.model = model;
    this.id = kb.observable(model, 'id');
    this.encodeId = window.utils.htmlEscape(this.id());
	this.canExport = ko.observable(true);
	this.canExport(DataManager.getDataManager().canExport(self.model));
    if (options && options.parentView) {
        this.parentView = options.parentView;
        this.encodeId = "" + options.modalId;
        this.packageList = options.packages;
    }
	this.options=options;
    this.version = kb.observable(model, 'version');
    this.enableDelete = ko.observable(true);
    this.name = ko.observable(self.model ? self.model.get('name') : null);
    this.description = ko.observable(model ? model.get('description') : "");
    this.businessModelColl = ko.observableArray([]);
    this.businessCanvasColl = ko.observableArray([]);
    this.processModel = ko.observableArray([]);
    this.caseModel = ko.observableArray([]);
    this.capLibColl = ko.observableArray([]);
	this.valLibColl = ko.observableArray([]);
    //this.measureColl = ko.observableArray([]);
    this.capMap = ko.observableArray([]);
    this.enableExportPackage = ko.observable(false);
	/*this.enableCreateRevision = ko.observable(false);
	if(DataManager.getDataManager().get('debugMode')){
		this.enableCreateRevision(true);
	}*/
	this.showMarketSubmit = ko.observable(false);
	this.showSwitch = ko.observable(false);
	if(self.model.get('libraryPackage')) {
		if(!DataManager.getDataManager().get('readMode') && !window.utils.checkIsLinked(null,true) && this.canExport()){
			var roles = DataManager.getDataManager().get('serverRoles');
			var show = false;
			if((roles && roles.indexOf('LEGAL_ENTITY_ADMIN') != -1) || window.utils.isVMPUser()){
				show = true;
			}
			if(!DataManager.getDataManager().isEnterpriseEdition()){
				show = false;
			}
			self.showMarketSubmit(show);
		}
	}

    $('#modal' + self.encodeId).on('shown.bs.modal', function () {
        window.utils.focusElement("#newModal .focus-ele");
    });

    this.name.subscribe(function (val) {
        self.description(val);
        /*if(val === ''){
            $("#complete").attr('disabled','disabled');
        }
        else{
            $("#complete").removeAttr('disabled');
        }*/
    });
    self.fillBusinessModels();
    self.fillBusinessCanvases();
    self.fillCapMaps();
    self.fillCapLibs();
	self.fillValLibs();
    //self.fillMeasurements();
    if (DataManager.getDataManager().get('viewAlternative') !== DataManager.getDataManager().getRepositoryId(self.model.get('id'))) {
        if (self.model.get('id').indexOf('Common') === -1) {
            self.enableDelete(false);
        }
    }

    this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), ['Alternative'
        , 'name'
        , 'description'
        , 'Close'
        , 'Delete'
        , 'Complete'
        , 'EditVDMLpackage'
        , 'BusinessModels'
        , 'BusinessCanvases'
        , 'Copy', 'export', 'CapLibs', 'ValueLibrary', 'Publish']);


}
	
    static getInstance(model, options){
		var view = new EditValueDeliveryModelDetailsViewModel(model, options);
		view.init(model, options);
		return view;
    }
}
path.EditValueDeliveryModelDetailsViewModel = EditValueDeliveryModelDetailsViewModel;