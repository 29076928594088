import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Collaboration} from './Collaboration'
import {CommunityMixin} from './CommunityMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Collaboration","appbo/vdml/CommunityMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Collaboration,CommunityMixin
, Member){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

	var Community = Collaboration.extend(utils.customExtends({
		relations:CommunityMixin.getMixinRelations(),
		initialize: function(attributes, options) {
		    Collaboration.prototype.initialize.apply(this, arguments);
		}
		}
		, new CommunityMixin()
	));
	Community["change:name"] = function (model, newName) {
		var previousName = model._previousAttributes ? model._previousAttributes.name:"";
		if(previousName != newName) {
			this.setCommunityNameChange(newName);
		}
	}
	Community.abstract = false;
	path.Community = Community;
	export {Community};
//});