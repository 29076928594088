import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {NamedPlanElement} from './NamedPlanElement'
import {PhaseMixin} from './PhaseMixin'
import {Phase2Mixin} from './Phase2Mixin'
import {Alternative} from './Alternative'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/transformation/NamedPlanElement","appbo/transformation/PhaseMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,NamedPlanElement,PhaseMixin
//, Alternative
//){
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
	if(!Alternative){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.Alternative){
			Alternative = importPath.Alternative;
		}
        else {
            import('./Alternative').then(loadedModule => {
                Alternative = loadedModule;
            });

			/*require(["appbo/transformation/Alternative"],function(loadedModule){
				Alternative = loadedModule;
			});*/
		}
	}
	if(!Alternative){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.Alternative){
			Alternative = importPath.Alternative;
		}
        else {
            import('./Alternative').then(loadedModule => {
                Alternative = loadedModule;
            });
            /*
			require(["appbo/transformation/Alternative"],function(loadedModule){
				Alternative = loadedModule;
			});*/
		}
	}
	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? Phase2Mixin : PhaseMixin;
	}
	var phaseMixinClass = getMixinClass();

	var Phase = NamedPlanElement.extend(utils.customExtends({
		relations:phaseMixinClass.getMixinRelations(),
		initialize: function(attributes, options) {
			NamedPlanElement.prototype.initialize.apply(this, arguments);
			if(attributes.status){
				this.set('status',path.Status[attributes.status instanceof Object ? attributes.status.name : attributes.status]);
			}
			//this.on("change:name", this.handlePhaseNameChange);
		}
		}
		, new phaseMixinClass()
	));
    Phase["change:name"] = function (model, newName) {
		var previousName = model._previousAttributes ? model._previousAttributes.name:"";
		if(previousName != newName) {
			this.handlePhaseNameChange(model, newName);
			if(!window.vdmModelView.PlansWorkspaceExplorerViewModel){
				window.setBreadCrumb(model.id, DataManager.getDataManager().get('viewAlternative'), window.vdmModelView);
				window.vdmModelView.refreshWidgetData(true);
			}
		}
    }
	utils.customExtendStaticMethods(Phase,phaseMixinClass);
	Phase.abstract = false;
	path.Phase = Phase;
	//return Phase;
	export {Phase};
