import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import jwt from 'jwt-decode';
import {DataManager} from '../data/DataManager'
import {InteractionRequiredAuthError} from "@azure/msal-browser"

var USER_MESSAGES = {
    no_access_token: "No access token available. Please login.",
    fail_graph_api: "Failed to the Microsoft Graph API."
};
import * as bootbox from '../../../../libs/bootbox/bootbox'
export class VMPServerService{
    constructor () {
        //this.http = remote ? remote.getGlobal('requestAPI') : null;
        this.serverHostVariable = 'vmpServerHost';
        this.options = {
            client_id: DataManager.getDataManager().getWindowsClientId(),
            scope: 'openid User.Read User.ReadBasic.All User.Read.All User.ReadWrite.All Mail.Read offline_access',
            tenant: DataManager.getDataManager().getWindowsTenant(),
            endpoints: {
                graphApiUri: DataManager.getDataManager().getWindowsGraphApiUri(),
                serverApiUri: DataManager.getDataManager().get(this.serverHostVariable)
            }
        };
    };
    getTokenPopup() {
        var dataManager = DataManager.getDataManager();
        var  request = dataManager.getTokenRequest();
        /**
        * See here for more information on account retrieval: 
        * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
        */
       var accountId = dataManager.get('gapiId');
       var myMSALObj = dataManager.get('mSALObj');
        request.account = myMSALObj.getAccountByHomeId(accountId);
        return myMSALObj.acquireTokenSilent(request)
            .then((response) => {
                // In case the response from B2C server has an empty accessToken field
                // throw an error to initiate token acquisition
                if (!response.accessToken || response.accessToken === "") {
                    throw new InteractionRequiredAuthError;
                }
                return response;
            })
            .catch(error => {
                console.log("Silent token acquisition fails. Acquiring token using popup. \n", error);
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return myMSALObj.acquireTokenPopup(request)
                        .then(response => {
                            console.log(response);
                            return response;
                        }).catch(error => {
                            console.log(error);
                        });
                } else {
                    console.log(error);
                    bootbox.alert("Please check network and try again");
                }
            });
    }
    passTokenToApi(callback) {
        this.getTokenPopup(DataManager.getDataManager().getTokenRequest())
            .then(response => {
                if (response) {
                    console.log("access_token acquired at: " + new Date().toString());
                    callback(response.accessToken);
                }
            });
    }
    init(callback, application_id, serverHostVariable){
        var self = this;
        if (callback) {
            this.passTokenToApi(function(token){
                //var accessToken = DataManager.getDataManager().get("accessToken");
                var user = jwt(token);
                if (user && user.exp * 1000 < new Date().getTime()) {
                    debugger
                }
                self.serverHostVariable = (serverHostVariable?serverHostVariable: self.serverHostVariable);
                window.localStorage.setItem("server_access_token" + "_" + self.serverHostVariable, token);
                window.setLocalStorage("server_access_token" + "_" + self.serverHostVariable, token);
                self.options.endpoints.serverApiUri = DataManager.getDataManager().get(self.serverHostVariable);
                callback(token);
            })
            return;
        }
        
        if (application_id) {
            self.options.endpoints.graphApiUri = application_id;
        }
        if (serverHostVariable) {
            self.serverHostVariable = serverHostVariable;
            self.options.endpoints.serverApiUri = DataManager.getDataManager().get(this.serverHostVariable);
        }
        var remote;
        var BrowserWindow;
        var uuid;
        if (!window.utils.checkChrome()) {
            /*remote = require('electron').remote;
            BrowserWindow = remote.getGlobal('browserWindow');
            uuid = remote.getGlobal('uuid');*/
        }
    
        function getAuthUrl() {
            var client_id = self.options.client_id;
            var scope = self.options.scope;
    
            return authority + authorize_endpoint +
                '?response_type=id_token' +
                '&client_id=' + client_id +
                "&resource=" + self.options.endpoints.graphApiUri +
                '&redirect_uri=' + encodeURIComponent(redirect_uri) +
                '&state=/' +
                '&scope=' + self.options.scope +
                '&response_mode=fragment' +
                '&nonce=' + nonceid;
        }
        var authority = 'https://login.microsoftonline.com/' + self.options.tenant;
        //this.authorize_endpoint = '/oauth2/v2.0/authorize';
        var authorize_endpoint = '/oauth2/authorize';
        //var redirect_uri = 'https://localhost:3000/login';
        var redirect_uri = 'https://localhost:3000/login';
        var token_endpoint = '/oauth2/v2.0/token';
    
        var fail_user_photo = "Failed to get your photo!";
        var fail_graph_api = "Failed to get VDMBee Server API.";
        var nonceid = uuid ? uuid.v4() : uuidv4();
    
        var authWindow;
        if (remote) {
            var app = remote.getGlobal('app');
            //console.log("User Data Path:" + app.getPath('userData'));
            //console.log("app Data Path:" + app.getPath('appData'));
        }
        var parseQueryString = function (url) {
            var params = {};
            var queryString = "";
    
            if (url.search("#") !== -1) {
                queryString = url.substring(url.search("#") + 1);
            } else {
                queryString = url.substring(url.indexOf("?") + 1);
            }
    
            var a = queryString.split("&");
            for (var i = 0; i < a.length; i++) {
                var b = a[i].split("=");
                params[decodeURIComponent(b[0])] = decodeURIComponent(b[1] || "");
            }
            if(params.SAMLRequest){
                //bootbox.alert("SAMLRequest "+params)
                params.id_token = params.SAMLRequest;//for maxime
            }
            return params;
        }
        // Create the browser window.
        var closedWindow = true;
    
        /*function printEvent(e) {
            var event = e.originalEvent;
            console.log('response status:' + event.status);
            console.log('newURL:' + event.newURL);
            console.log('httpResponseCode :' + event.httpResponseCode);
            console.log('requestMethod  :' + event.requestMethod);
            console.log('referrer:' + event.referrer);
            console.log('headers:' + JSON.stringify(event.headers));
            console.log('resourceType:' + event.resourceType);
        }*/
        /*var handleValidAccessToken = function (accessToken, accessCallback) {
            var gs = new VMPServerService(remote.getGlobal('requestAPI'), self.options);
            gs.getAllPromise(me_photo_url).then((data) => {
                if (accessCallback) {
                    accessCallback(data);
                }
            }).catch(() => {
                console.log(fail_graph_api);
                if (accessCallback) {
                    accessCallback(null);
                }
            });
        }*/
        var getNewAccessToken = function (state) {
    
            var originalURL = location.href;
            var accessTokenUrl = "https://login.microsoftonline.com/" + self.options.tenant +
                "/oauth2/authorize?response_type=token&client_id=" + self.options.client_id +
                "&resource=" + self.options.endpoints.graphApiUri +
                "&redirect_uri=" + encodeURIComponent(redirect_uri) +
                "&prompt=none&state=" + state + "&nonce=" + nonceid;
            if (!window.utils.checkChrome()) {
                /*var accessWindow = new BrowserWindow({
                    autoHideMenuBar: true,
                    frame: false,
                    height: 600,
                    show: false,
                    icon: './img/VMP-96px.png',
                    webPreferences: {
                        nodeIntegration: false,
                        devTools: false
                    },
                    width: 800,
                });
                //accessWindow.webContents.openDevTools();
                accessWindow.on("closed", function () {
                    accessWindow = null;
                });
    
                accessWindow.webContents.on("will-redirect", function (event, newUrl) {
                    accessWindow.destroy();
    
                    var params = parseQueryString(newUrl);
                    var accessToken = params.access_token;
                    if (accessToken != null) {
                        window.localStorage.setItem("server_access_token" + "_" + self.serverHostVariable, accessToken);
                        window.setLocalStorage("server_access_token" + "_" + self.serverHostVariable, accessToken);
                        var gs = new VMPServerService();
                        if (callback) {
                            callback(accessToken);
                        }
                    } else {
                        if (params.error) {
                            console.log("error:" + params.error);
                            console.log("error description:" + params.error_description);
                        }
                        window.localStorage.removeItem("server_access_token" + "_" + self.serverHostVariable);
                        if (callback) {
                            callback(null);
                        }
                    }
                });
                accessWindow.loadURL(accessTokenUrl);*/
                //accessWindow.show();
            } else {
                window.showUrlDialog(accessTokenUrl, "Server Access Token", null, null, function () {
                    var newUrl = arguments[0][0].newUrl;
                    window.closeUrlDialog(arguments[1]);
                    var params = parseQueryString(newUrl);
                    var accessToken = params.access_token;
                    if (accessToken !== null) {
                        var valName = "server_access_token" + "_" + self.serverHostVariable;
                        window.setLocalStorage(valName, accessToken);
                        var gs = new VMPServerService();
                        if (callback) {
                            callback(accessToken);
                        }
                    } else {
                        if (params.error) {
                            console.log("error:" + params.error);
                            console.log("error description:" + params.error_description);
                        }
                        window.removeLocalStorage("server_access_token" + "_" + self.serverHostVariable);
                        if (callback) {
                            callback(null);
                        }
                    }
                });
            }
    
        }
        function getNewOrExpiredToken() {
            if (!window.utils.checkChrome()) {
                /*authWindow = new BrowserWindow({
                    autoHideMenuBar: true,
                    frame: true,
                    height: 600,
                    show: false,
                    icon: './img/VMP-96px.png',
                    webPreferences: {
                        nodeIntegration: false,
                        devTools: false
                    },
                    width: 800,
                });
    
                authWindow.webContents.on("will-redirect", function (event, newUrl) {
                    //printEvent(event);
                    closedWindow = false;
                    authWindow.destroy();
                    var params = parseQueryString(newUrl);
                    var idToken = params.id_token;
                    if (idToken != null) {
                        window.localStorage.setItem("id_token", idToken);
                        window.localStorage.removeItem("server_access_token" + "_" + self.serverHostVariable);
    
                        getNewAccessToken("/");
                    } else {
                        window.localStorage.removeItem("id_token");
                        window.localStorage.removeItem("server_access_token" + "_" + self.serverHostVariable);
                    }
                });
                //authWindow.webContents.on('did-get-response-details', printEvent);
    
                authWindow.on("closed", function () {
                    authWindow = null;
                    if (closedWindow && callback) {
                        callback();
                    }
                });
    
                authWindow.loadURL(getAuthUrl());*/
                //authWindow.show();
                //authWindow.webContents.openDevTools();
            } else {
                window.showUrlDialog(getAuthUrl(), "Server Access Token", null, null, function () {
                    var newUrl = arguments[0][0].newUrl;
                    window.closeUrlDialog(arguments[1]);
                    var params = parseQueryString(newUrl);
                    var idToken = params.id_token;
                    if (idToken != null) {
                        window.setLocalStorage("id_token", idToken);
                        window.removeLocalStorage("server_access_token" + "_" + self.serverHostVariable);
    
                        getNewAccessToken("/");
                    } else {
                        window.removeLocalStorage("id_token");
                        window.removeLocalStorage("server_access_token" + "_" + self.serverHostVariable);
                    }
                }, function () {
                    if (callback) {
                        callback();
                    }
                });
            }
        }
        var token;
        if (!window.utils.checkChrome()) {
            token = window.localStorage.getItem("server_access_token" + "_" + self.serverHostVariable);
            handleToken(token);
        } else {
            window.getLocalStorage("server_access_token" + "_" + self.serverHostVariable, function (result) {
                handleToken(result);
            });
        }
        function handleToken(token) {
            if (token && typeof token === 'object') {
                token = token.access_token;
            }
            if (!token) {
                getNewOrExpiredToken();
            } else {
                var gs = new VMPServerService();
                if (callback) {
                    callback(token);
                }
            }
        }
    }
    postAllPromise(reqUrl, params, method) {
        var self = this;
        var p = new Promise(function (resolve, reject) {
            var tokenPromise = self.tokenPromise(self.options.endpoints.graphApiUri);
            tokenPromise.then(function (token) {
                self.makePostRequest(reqUrl, params, token, resolve, reject, method);
            });
        });
        return p;
    };
    

    makeRequest(reqUrl, params, method, token, resolve, reject) {
        this.makePostRequest(reqUrl, params, token, resolve, reject, method);
    }
    makePostRequest (reqUrl, params, token, resolve, reject, method) {
        var self = this;
        var request = new XMLHttpRequest();
        request.open(method ? method : "POST", self.options.endpoints.serverApiUri + reqUrl);
        if(!reqUrl.includes("image/upload")){
            request.responseType = "json";
            request.setRequestHeader("Content-Type", "application/json");
        }
       
        if(DataManager.getDataManager().get("basicAuthMode")) {
            request.setRequestHeader("Authorization", "Basic " + window.seleniumAuth);
        } else {
            request.setRequestHeader("Authorization", "Bearer " + token);
        }
        
        
        request.onload = function () {
            if (request.readyState === 4 && (request.status === 200 || request.status === 201 || request.status === 204)) {
                resolve(request.response);
            } else {
                if (request.status === 500 && request.response != undefined && request.response.message != undefined && (request.response.message === "com.nimbusds.jwt.proc.BadJWTException: Expired JWT" ||
                    request.response.message.indexOf("Read timed out") > 0)) {
                    if (!window.utils.checkChrome()) {
                        window.localStorage.removeItem("server_access_token" + "_" + self.serverHostVariable);
                    } else {
                        window.removeLocalStorage("server_access_token" + "_" + self.serverHostVariable);
                    }
    
                    self.init(function (newToken) {
                        self.makePostRequest(reqUrl, params, newToken, resolve, reject, method);
                    });
                } else {
                    window.utils.stopSpinner();
                    if (request.status === 500) {
                        if(request.response != undefined && request.response.message && request.response.message !== ""){
                            bootbox.alert(request.response.message);
                        }else
                        if(request.response != undefined && request.response.error != undefined){
                            bootbox.alert(request.response.error);
                        }else{
                            bootbox.alert("Server returned status 500");
                        }
                    }
                    if (request.status === 403 || request.status === 400) {
                        var msg = 'Request is Denied';
                        if(request.response != undefined){
                            if(request.response.apierror){
                                msg = request.response.apierror.message;
                            } else {
                                var jsonResponse = JSON.parse(request.response);
                                msg = jsonResponse?jsonResponse.apierror.message:msg;
                            }
                        }
                        bootbox.alert(msg);
                    }
                    if (request.status === 404 && request.response.apierror) {
                        bootbox.alert(request.response.apierror.message);
                    }
                    reject(request);
                }
            }
        };
        request.onerror = function () {
            bootbox.alert("Error making POST request to:" + self.options.endpoints.serverApiUri + reqUrl);
        };
        request.send(params);
    }
    getAllPromise(reqUrl, params) {
        var self = this;
        var p = new Promise(function (resolve, reject) {
            var tokenPromise = self.tokenPromise(self.options.endpoints.graphApiUri);
            tokenPromise.then(function (token) {
                if (params) {
                    reqUrl = reqUrl + params;
                }
                self.makeGetRequest(reqUrl, token, resolve, reject)
            });
        });
        return p;
    };
    putAllPromise(reqUrl, params, jsonBody) {
        var self = this;
        var p = new Promise(function (resolve, reject) {
            var tokenPromise = self.tokenPromise(self.options.endpoints.graphApiUri);
            tokenPromise.then(function (token) {
                self.makePutRequest(reqUrl, params, token, resolve, reject, jsonBody);
            });
        });
    
        return p;
    };
    
    //post for urilist
    postAllPromiseForUriList(reqUrl, params, method) {
        var self = this;
        var p = new Promise(function (resolve, reject) {
            var tokenPromise = self.tokenPromise(self.options.endpoints.graphApiUri);
            tokenPromise.then(function (token) {
                self.makePostRequestForUriList(reqUrl, params, token, resolve, reject, method);
            });
        });
        return p;
    };
    
    makePostRequestForUriList(reqUrl, params, token, resolve, reject, method) {
        var self = this;
        var request = new XMLHttpRequest();
        request.open(method ? method : "POST", self.options.endpoints.serverApiUri + reqUrl);
        if(DataManager.getDataManager().get("basicAuthMode")) {
            request.setRequestHeader("Authorization", "Basic " + window.seleniumAuth);
        } else {
            request.setRequestHeader("Authorization", "Bearer " + token);
        }
        request.responseType = "json";
        request.setRequestHeader("Content-Type", "text/uri-list");
        request.onload = function () {
            if (request.readyState === 4 && (request.status === 200 || request.status === 201 || request.status === 204)) {
                resolve(request.response);
            } else {
                if (request.status === 500 && request.response != undefined && request.response.message != undefined && (request.response.message === "com.nimbusds.jwt.proc.BadJWTException: Expired JWT" ||
                    request.response.message.indexOf("Read timed out") > 0)) {
                    if (!window.utils.checkChrome()) {
                        window.localStorage.removeItem("server_access_token" + "_" + self.serverHostVariable);
                    } else {
                        window.removeLocalStorage("server_access_token" + "_" + self.serverHostVariable);
                    }
    
                    self.init(function (newToken) {
                        self.makePutRequest(reqUrl, params, newToken, resolve, reject);
                    });
                } else {
                    window.utils.stopSpinner();
                    if (request.status === 500) {
                        if(request.response != undefined && request.response.message !== ""){
                            bootbox.alert(request.response.message);
                        }else if(request.response.error != undefined){
                            bootbox.alert(request.response.error);
                        }else{
                            bootbox.alert("Server returned status 500");
                        }
                    }
                    if (request.status === 403) {
                        bootbox.alert('Request is Denied')
                    }
                    reject(request);
                }
            }
        };
        request.onerror = function () {
            bootbox.alert("Error making POST request to:" + self.options.endpoints.serverApiUri + reqUrl);
        }
        request.send(params);
    }
    
    putAllPromiseForUriList(reqUrl, params, method) {
        var self = this;
        var p = new Promise(function (resolve, reject) {
            var tokenPromise = self.tokenPromise(self.options.endpoints.graphApiUri);
            tokenPromise.then(function (token) {
                self.makePutRequestForUriList(reqUrl, params, token, resolve, reject, method);
            });
        });
        return p;
    };
    
    makePutRequestForUriList(reqUrl, params, token, resolve, reject, method) {
        var self = this;
        var request = new XMLHttpRequest();
        request.open(method ? method : "PUT", self.options.endpoints.serverApiUri + reqUrl);
        if(DataManager.getDataManager().get("basicAuthMode")) {
            request.setRequestHeader("Authorization", "Basic " + window.seleniumAuth);
        } else {
            request.setRequestHeader("Authorization", "Bearer " + token);
        }
        request.responseType = "json";
        request.setRequestHeader("Content-Type", "text/uri-list");
        request.onload = function () {
            if (request.readyState === 4 && (request.status === 200 || request.status === 201 || request.status === 204)) {
                resolve(request.response);
            } else {
                if (request.status === 500 && request.response != undefined &&  request.response.message != undefined && (request.response.message === "com.nimbusds.jwt.proc.BadJWTException: Expired JWT" ||
                    request.response.message.indexOf("Read timed out") > 0)) {
                    if (!window.utils.checkChrome()) {
                        window.localStorage.removeItem("server_access_token" + "_" + self.serverHostVariable);
                    } else {
                        window.removeLocalStorage("server_access_token" + "_" + self.serverHostVariable);
                    }
    
                    self.init(function (newToken) {
                        self.makePutRequest(reqUrl, params, newToken, resolve, reject);
                    });
                } else {
                    window.utils.stopSpinner();
                    if (request.status === 500) {
                        if(request.response != undefined && request.response.message !== ""){
                            bootbox.alert(request.response.message);
                        }else if(request.response.error != undefined){
                            bootbox.alert(request.response.error);
                        }else{
                            bootbox.alert("Server returned status 500");
                        }
                    }
                    if (request.status === 403) {
                        bootbox.alert('Request is Denied')
                    }
                    reject(request);
                }
            }
        };
        request.onerror = function () {
            bootbox.alert("Error making POST request to:" + self.options.endpoints.serverApiUri + reqUrl);
        }
        request.send(params);
    }
    
    deleteAllPromiseForUriList(reqUrl, params, jsonBody) {
        var self = this;
    
        var p = new Promise(function (resolve, reject) {
            var tokenPromise = self.tokenPromise(self.options.endpoints.graphApiUri);
            tokenPromise.then(function (token) {
                self.makeDeleteRequestForUriList(reqUrl, params, token, resolve, reject, jsonBody);
            });
        });
        return p;
    };
    
    makeDeleteRequestForUriList(reqUrl, params, token, resolve, reject, jsonBody) {
        var self = this;
        var request = new XMLHttpRequest();
        request.open("DELETE", self.options.endpoints.serverApiUri + reqUrl);
        if(DataManager.getDataManager().get("basicAuthMode")) {
            request.setRequestHeader("Authorization", "Basic " + window.seleniumAuth);
        } else {
            request.setRequestHeader("Authorization", "Bearer " + token);
        }
        request.responseType = "json";
        if(jsonBody){
            request.setRequestHeader("Content-Type", "application/json");
        } else {
            request.setRequestHeader("Content-Type", "text/uri-list");
        }
        
        request.onload = function () {
            if (request.readyState === 4 && (request.status === 200 || request.status === 201 || request.status === 204)) {
                resolve(request.response);
            } else {
                if (request.status === 500 && request.response != undefined && request.response.message != undefined && (request.response.message === "com.nimbusds.jwt.proc.BadJWTException: Expired JWT" ||
                    request.response.message.indexOf("Read timed out") > 0)) {
                    if (!window.utils.checkChrome()) {
                        window.localStorage.removeItem("server_access_token" + "_" + self.serverHostVariable);
                    } else {
                        window.removeLocalStorage("server_access_token" + "_" + self.serverHostVariable);
                    }
    
                    self.init(function (newToken) {
                        self.makePutRequest(reqUrl, params, newToken, resolve, reject);
                    });
                } else {
                    window.utils.stopSpinner();
                    if (request.status === 500) {
                        if(request.response && request.response.apierror){
                            var msg = request.response.apierror.message;
                            bootbox.alert(msg);
                        }                        
                        if(request.response != undefined && request.response.message && request.response.message !== ""){
                            bootbox.alert(request.response.message);
                        }else if(request.response.error != undefined){
                            bootbox.alert(request.response.error);
                        }else{
                            bootbox.alert("Server returned status 500");
                        }
                    }
                    if (request.status === 403) {
                        var msg = 'Request is Denied';
                        if(request.response && request.response.apierror){
                            msg = request.response.apierror.message;
                        }
                        bootbox.alert(msg);
                    }
                    reject(request);
                }
            }
        };
        request.onerror = function () {
            bootbox.alert("Error making DELETE request to:" + self.options.endpoints.serverApiUri + reqUrl);
            window.utils.stopSpinner();
        }
        request.send(params);
    }
    
    //
    makeGetRequest(reqUrl, token, resolve, reject) {
        var self = this;
        try {
            var request = new XMLHttpRequest();
            request.open("GET", self.options.endpoints.serverApiUri + reqUrl, true);
            if(DataManager.getDataManager().get("basicAuthMode")) {
                request.setRequestHeader("Authorization", "Basic " + window.seleniumAuth);
            } else {
                request.setRequestHeader("Authorization", "Bearer " + token);
            }
            if(reqUrl.includes("image/planImageArtifacts") || reqUrl.includes("image/download")){
                request.responseType = "blob";
                request.setRequestHeader("Content-Type", "application/zip");
            } else {
                request.responseType = "json";
                request.setRequestHeader("Content-Type", "application/json");
            }
            //request.setRequestHeader("Content-Type", "application/json");
            //request.setRequestHeader("Access-Control-Allow-Origin","http://localhost:8090");
            //request.setRequestHeader('Access-Control-Allow-Credentials', 'true');
		    //request.setRequestHeader("Access-Control-Allow-Headers", "Authorization, Origin, X-Requested-With, Content-Type, Accept");
            //request.responseType = "json";
            request.onload = function () {
                if (request.readyState === 4 && request.status === 200) {
                    window.setLocalStorage("server_status", 1);
                    resolve(request.response);
                } else {
                    if (request.status === 401) {
                        if(!request.response){
                            var tokenPromise = self.tokenPromise(self.options.endpoints.graphApiUri);
                            tokenPromise.then(function(token) {
                                self.makeGetRequest(reqUrl,token,resolve,reject)
                            });
                        }else {
                            bootbox.alert(request.response.message);
                        }
                    } else {
                        window.utils.stopSpinner();
                        if (request.status === 500){
                            if (request.response != undefined && request.response.message != undefined &&  request.response.message.indexOf("Proper Roles not assigned to user") != -1){
                                bootbox.alert("No Roles assigned to this User. Please contact support");
                            }else 
                            if (request.response != undefined && request.response.message != undefined && request.response.message.indexOf("Invitation Accepted") != -1){
                                bootbox.alert(request.response.message.split(':')[1] ? request.response.message.split(':')[1] :request.response.message);
                            }else
                            if (request.response != undefined && request.response.message != undefined && request.response.message.indexOf("Invitation Not Accepted") != -1){
                                bootbox.alert(request.response.message.split(':')[1] ? request.response.message.split(':')[1] : request.response.message);
                            }else
                            if(request.response.error != undefined){
                                bootbox.alert(request.response.error);
                            }else{
                                bootbox.alert("Server returned error 500");
                            }
                        }
                        if (request.status === 403) {
                            bootbox.alert('Request is Denied. Navigate to Home Page?', function() { 
                                window.utils.navigatePage(false);
                            });
                        }
                        if (request.status === 405) {
                            bootbox.alert('405 Method Not Allowed'+request.responseURL);
                        }
                        if (request.status === 404 && request.response && request.response.apierror) {
                            console.log(request.response.apierror.message);
                        }
                        reject(request);
                    }
                }
            };
            request.onreadystatechange = function () {
                if (request.readyState == 4 && request.status == 0) {
                    window.getLocalStorage("server_status", function (result) {
                        if (result === null || result === 1 || result === 0) {
                            window.setLocalStorage("server_status", 0);
                            DataManager.getDataManager().set('offLineMode',true);
                            window.recheckConnection();
                            reject(request);
                            window.utils.stopSpinner('AuthSpinner');
                        }
                        if (typeof result === 'object' && result) {
                            result = result.server_status;
                        }
                    });
                }
            };
            /*request.onerror = function () {
                bootbox.alert("Error making GET request to:" + self.options.endpoints.serverApiUri + reqUrl);
            }*/
            request.send(null);
        }
        catch (e) {
            console.log('catch', e);
            DataManager.getDataManager().releaseSaveLock();
        }
    };
    
    getDataTableAjaxPromise(reqUrl, aoData) {
        var self =this;
        //extract name/value pairs into a simpler map for use later
        var paramMap = {};
        for (var i = 0; i < aoData.length; i++) {
            paramMap[aoData[i].name] = aoData[i].value;
        }
    
        //page calculations
        var pageSize = paramMap.iDisplayLength;
        var start = paramMap.iDisplayStart;
        var pageNum = (start == 0) ? 0 : (start / pageSize) + 0; // pageNum is 1 based
    
        // extract sort information
        var sortCol = paramMap.iSortCol_0;
        var sortDir = paramMap.sSortDir_0;
        var sortName = paramMap['mDataProp_' + sortCol];
    
        /*var restParams = new Array();
        restParams.push({ "name": "size", "value": pageSize });
        restParams.push({ "name": "page", "value": pageNum });
        restParams.push({ "name": "sort", "value": sortName + "," + sortDir });
        restParams.push({ "name": sortName + ".dir", "value": sortDir });*/
        
        reqUrl = reqUrl + "?size="+ pageSize +"&page="+ pageNum +"&sort="+ sortName +"%2C"+ sortDir +"&"+ sortName +".dir="+ sortDir;
        if(paramMap.offset){
            reqUrl = reqUrl + "&offset="+paramMap.offset;
        }
        var p = new Promise(function(resolve, reject) {
            var tokenPromise = self.tokenPromise(self.options.endpoints.graphApiUri);
            tokenPromise.then(function(token) {
                self.makeGetRequest(reqUrl,token,resolve,reject)
            });
        });
        return p;
    };
    
    makePutRequest(reqUrl, params, token, resolve, reject, jsonBody) {
        var self = this;
        var request = new XMLHttpRequest();
        var tokenValueName = "server_access_token" + "_" + self.serverHostVariable;
        request.open("PUT", self.options.endpoints.serverApiUri + reqUrl);
        if(DataManager.getDataManager().get("basicAuthMode")) {
            request.setRequestHeader("Authorization", "Basic " + window.seleniumAuth);
        } else {
            request.setRequestHeader("Authorization", "Bearer " + token);
        }
        request.responseType = "json";
        if(jsonBody){
            request.setRequestHeader("Content-Type", "application/json");
        } else {
            request.setRequestHeader("Content-Type", "text/uri-list");
        }
        request.onload = function () {
            if (request.readyState === 4 && (request.status === 200 || request.status === 201 || request.status === 204)) {
                resolve(request.response);
            } else {
                if (request.status === 500 && request.response != undefined && request.response.message != undefined &&(request.response.message === "com.nimbusds.jwt.proc.BadJWTException: Expired JWT" ||
                    request.response.message.indexOf("Read timed out") > 0)) {
                    if (!window.utils.checkChrome()) {
                        window.removeLocalStorage(tokenValueName);
                    } else {
                        window.removeLocalStorage(tokenValueName);
                    }
                    
                    self.init(function (newToken) {
                        self.makePutRequest(reqUrl, params, newToken, resolve, reject, jsonBody);
                    });
                } else {
                    window.utils.stopSpinner();
                    if (request.status === 500) {
                        if(request.response != undefined && request.response.message){
                            bootbox.alert(request.response.message);
                           
                        }else if(request.response != undefined && request.response.error != undefined){
                            bootbox.alert(request.response.error);
                        }else{
                            bootbox.alert("Server returned status 500");
                        }
                    }
                    if(request.status === 403){
                        bootbox.alert('Request is Denied')
                    }
                    reject(request);
                }
            }
        };
        request.onerror = function () {
            bootbox.alert("Error making PUT request to:" + self.options.endpoints.serverApiUri + reqUrl);
        }
        request.send(params);
    }
    tokenPromise(endpoint) {
        var self = this;
        var tokenValueName = "server_access_token" + "_" + self.serverHostVariable;
        var p = new Promise(function (resolve, reject) {
            if(DataManager.getDataManager().get("basicAuthMode")) {
                resolve();
            }else {
                window.getLocalStorage(tokenValueName, function (token) {
                    if (typeof token === 'object' && token !== null) {
                        token = token.access_token;
                    }
                    if (token && token !== "undefined") {
                        var user = jwt(token);
                        if (user && user.exp * 1000 < new Date().getTime()) {
                            if (!window.utils.checkChrome()) {
                                window.localStorage.removeItem("server_access_token" + "_" + self.serverHostVariable);
                            } else {
                                window.removeLocalStorage("server_access_token" + "_" + self.serverHostVariable);
                            }
                            self.init(function (newToken) {
                                resolve(newToken);
                            });
                        } else {
                            resolve(token);
                        }
                    } else {
                        reject(USER_MESSAGES.no_access_token);
                    }
                });
            }
        });
    
        return p;
    }
}

