import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {Enum} from '../../../../libs/enums/enums'
//define(["require","appcommon/com/vbee/data/DataManager","app/global","enums" ], 
//function(require,DataManager,global){
	var path = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
	if(!path.TicketType){
		var TicketType = new Enum("Dispute","Suggestion","Validation","TODO");
		path.TicketType = TicketType;
	}
	//return path.TicketType;
	export {TicketType};
//});