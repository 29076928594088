import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelItem} from './BusinessModelItem'
import {BusinessModelItemMixin} from './BusinessModelItemMixin'
import {ValueProposition} from './ValueProposition'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelItem","appbo/vdml/BusinessModelItemMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelItem,BusinessModelItemMixin
, ValueProposition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ValueProposition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueProposition){
			ValueProposition = importPath.ValueProposition;
		}
		else{
			import('./ValueProposition').then(({ default: ValueProposition }) => {
                ValueProposition = ValueProposition;
            });
			/*require(["appbo/vdml/ValueProposition"],function(loadedModule){
				ValueProposition = loadedModule;
			});*/
		}
	}
	export class BusinessModelValuePropositionMixin {

	defaults(){
		var ret = {
			type: "vdml_BusinessModelValueProposition"
		}
		return jQuery.extend(BusinessModelItem.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"vdml_BusinessModelValueProposition",
			key:"valueProposition",
			relatedModel:"vdml.ValueProposition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!BusinessModelValuePropositionMixin.cummulativeRelations) {
            BusinessModelValuePropositionMixin.cummulativeRelations = _.union(BusinessModelValuePropositionMixin.getMixinRelations()
                , BusinessModelItemMixin.getCumulativeMixinRelations()
            );
        }
		return BusinessModelValuePropositionMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_BusinessModelItem"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("bmValuePropositionOwner") ? this.get("bmValuePropositionOwner") : this.previousAttributes().bmValuePropositionOwner;
			if(!container){
				container = this.get("valueProposition") ? this.get("valueProposition").getNestedParent() : null;
			}
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("bmValuePropositionOwner") ? this.get("bmValuePropositionOwner") : this.previousAttributes().bmValuePropositionOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BusinessModelValuePropositionPropertiesTemplate.html",
			templateName : "BusinessModelValuePropositionPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BusinessModelValuePropositionViewModel",
			tabId : "BusinessModelValuePropositionView",
			tabName: "BusinessModelValueProposition"
		}
	}
//#startCustomMethods	
	updateBMValueProposition(valueProposition,vpName,providerRole,receiverRole){
		var name;
		var self = this;
		if(!providerRole){
			return null;
		}
		
		if(valueProposition){
			name = valueProposition.get('name');
			if(providerRole){
				if(providerRole !== valueProposition.get('provider') || receiverRole !== valueProposition.get('recipient')){
					updateVP(providerRole,receiverRole,function(){
						updateValues(vpName);
					});
				}
				//providerRole.get('providedProposition').add(valueProposition);
				valueProposition.set('provider',providerRole);
			}
			//if(receiverRole){
				//receiverRole.get('receivedProposition').add(valueProposition);
				valueProposition.set('recipient',receiverRole);
			//}
			if(vpName != undefined && vpName != '' && name != vpName){
				self.set({'name':vpName,'description':vpName});
				self.get('valueProposition').set({'name':vpName,'description':vpName});
			}
		}else{
			updateValues(vpName);
		}
		
		function updateValues(name){
			var oldVP = self.get('valueProposition');
			if(name != undefined && name != '' && oldVP !== valueProposition){
				self.set({'name':name,'description':name});
				self.get('valueProposition').set({'name':name,'description':name});
				self.get('valueProposition').set('provider',providerRole);
				self.get('valueProposition').set('recipient',receiverRole);
				//self.set('valueProposition',valueProposition);
			}
			//self.get('valueProposition').deleteValue(values,mainScenario);
		}
		/*if(valueProposition === null){
			valueProposition = new ValueProposition({'name':name,description:name,provider:providerRole,recipient:receiverRole});
		}		
		if(valueProposition && oldVP !== valueProposition){
			this.set('name',name);
			this.set('description',name);
			this.set('valueProposition',valueProposition);
		}*/
		function updateVP(provider, recipient, callback){
            DataManager.getDataManager().getReverseAssociationInstances(self.get('valueProposition'), 'vdml_BusinessModelValueProposition-valueProposition', 'vdml_BusinessModelValueProposition', "vbc:" + "cmof_EObject-name", 
			function(bmVPs){
				var modelsToDelete = [];
				bmVPs.each(function(bmVP){
					var bm = bmVP.get('bmValuePropositionOwner');
					var bmRoles = bm.get('bmRole');
					var pRole = bmRoles.findWhere({id:provider.get('id')});
					var rRole;
					if(!pRole){
						modelsToDelete.push(bmVP);
					}
					if(recipient && pRole){
						rRole = bmRoles.findWhere({id:recipient.get('id')});
						if(!rRole){
							modelsToDelete.push(bmVP);
						}
					}
				});
				_.each(modelsToDelete,function(modelToDel){
        			modelToDel.destroy();
        		});	
				callback();
			}, true);
		}

	};
	

	
//#endCustomMethods	
	
	}
	path.BusinessModelValuePropositionMixin = BusinessModelValuePropositionMixin;
	//return BusinessModelValuePropositionMixin;
//});