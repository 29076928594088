import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {BusinessModel} from '../../../../../version1/bo/vdml/BusinessModel'
import {ValueDeliveryModel} from '../../../../../version1/bo/vdml/ValueDeliveryModel'
import {User} from '../../../../../version1/bo/tickets/User'
import {Community} from '../../../../../version1/bo/vdml/Community'
import {OrgUnit} from '../../../../../version1/bo/vdml/OrgUnit'
import {Actor} from '../../../../../version1/bo/vdml/Actor'

/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","async","appcommon/com/vbee/data/DataManager","app/global","appbo/vdml/ValueDeliveryModel","appbo/vdml/BusinessModel","appbo/tickets/User","appbo/vdml/Community","appbo/vdml/OrgUnit","appbo/vdml/Actor",
		"typeahead"],
function(require,$,_, Backbone,ko, koMapping,kb,bootbox,async,DataManager,global,ValueDeliveryModel,BusinessModel,User,Community,OrgUnit,Actor,
		typeahead
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties",global.version);
	var importPath;
	
	export class CustomerDetailsViewModel{

		htmlEscape(str) {
    		return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '');
		}
		init(model, options){
			var self = this;
			this.CustomerDetailsViewModel = this;
			this.model = model;
		
			this.id = ko.observable(self.model ? self.model.get('id') : null);
			this.encodeId = window.utils.htmlEscape(this.id);
			if(options && options.parentView){
				this.parentView = options.parentView;
				this.encodeId = ""+options.modalId;
			}
			this.name = ko.observable(self.model ? self.model.get('name') : "");
			this.description = ko.observable(self.model ? self.model.get('description') : "");
			this.roleDescription = ko.observable("");
			this.Rolename = ko.observable("");
			this.rolesArray = [];
			this.rolesColl = ko.observableArray(this.rolesArray);	
			this.originalId = ko.observable();
			this.typeAheadOriginalId = ko.observable("");
			this.typeaheadParentId = ko.observable();
			this.enableComplete = ko.observable(false);
			this.editRoleName = ko.observable(false);
			this.enableDelete = ko.observable(false);
			this.enableSelectOptions = ko.observable(true);
			this.enableShowCustomerRoleDiv = ko.observable(false);
			this.disallowedNames = [];
			this.disallowedRoleNames = [];
			this.customerTypeaheadRoles = new Backbone.Collection();
			this.customerTypeahead = new Backbone.Collection();
			this.displaycustomerTypeahead = new Backbone.Collection();
			this.displayRoleTypeahead = new Backbone.Collection();
			this.customerRoleOriginalId="";
			this.canReuseCustomer = true;
			this.roleId="";
			this.modalShown = 0;
			this.CustomerType = function(name,type,typeStr){
			  this.name = name;
			  this.type = type;
			  this.typeStr = typeStr;
			};
			this.custTypeArray = [new this.CustomerType("Market Segment",Community,"Community"),
								 new this.CustomerType("Enterprise",OrgUnit,"OrgUnit"),
								 new this.CustomerType("Individual",Actor,"Actor")
								];
			this.customerTypeOptions = ko.observableArray(this.custTypeArray);
            this.customerType = ko.observable();
            this.options = options;
			this.editRole = _.bind(self.editRole, self);
			this.deleteRole = _.bind(self.deleteRole, self);
			this.customerType.subscribe(function(val){
        		if(self.name() != '' && self.originalId() === ''){
                    //var unique = getUniqueValue(self.name());
                    if (!self.getUniqueValue(self.name().trim()) && self.rolesColl().length>0 && $('#message'+self.encodeId).html().length == 0) {
						self.enableComplete(true);
					}
					else {
                		self.enableComplete(false);
					}
        		}
			});
	    
			self.filterIdList = options.addOptions.filterTypeahead;
			this.setOptions = function(options){
				self.filterIdList = options.filterTypeahead;
			}
		
			if(self.model){
        		var customerModel = self.model.get('customer');
				var currentCustomerType = this.customerType();
				if(!customerModel || currentCustomerType){
					return;
				}
				if(customerModel instanceof OrgUnit){
					self.customerType(this.customerTypeOptions()[1].typeStr);
				}else if(customerModel instanceof Community){
					self.customerType(this.customerTypeOptions()[0].typeStr);
				}else if(customerModel instanceof Actor){
					self.customerType(this.customerTypeOptions()[2].typeStr);
				}
				self.enableSelectOptions(false);
				var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: DataManager.getDataManager().get('viewAlternative') });
				self.disallowedNames = alt.getDisallowedNames(self.model.get('customer'), 'collaboration');
				self.disallowedRoleNames = alt.getDisallowedNames(self.model.get('customerRole').at(0), 'collaborationRole');
			}
			if(!self.model && self.name()==""){
        		checkReusability();
			}
			$('#modal'+self.encodeId).on('shown.bs.modal', function () {
				self.customerTypeaheadRoles._reset();
				fillRoleTypeAheadDetails();
				if(!self.model){
					self.fillCustomerTypeaheadDetails();
				}
				window.utils.focusElement("#newModal .focus-ele");
				if(self.name() != null && self.name() !== '') {
					self.enableDelete(true);
				}
				self.modalShown++;
				//for modal window close functionality
				$('#name'+self.encodeId).val(self.name());
            });
            fillRoles();
			this.name.subscribe(function(val){
				self.description(val);
			});
			function checkReusability(){
        		var alreadyAttached=false;
        		if(options.addOptions.pNModel){
        			var businessModels = options.parentView.getNestedParent().get('businessModel').models;
 					for(var i=0;i<businessModels.length;i++){
 						var businessModel=businessModels[i];
 						var collaboration = "";
 						var participantNetworkId="";
 						var participantNetworks = businessModel.get('participantNetwork');
 						for(var j=0;j<participantNetworks.length;j++){
 							if(participantNetworks.models[j].get('collaboration').id===options.addOptions.pNModel.get('collaboration').id){
 								collaboration = participantNetworks.models[j].get('collaboration');
 								participantNetworkId=participantNetworks.models[j].get('collaboration').id;
 							}
 						}
 						if(collaboration!==""){
 							var bmPartners = businessModel.get('bmNetworkPartner');
	 						bmPartners.each(function(bmPartner){
									if (bmPartner.get('networkPartner') && bmPartner.get('networkPartner').id===options.parentView.get('business').id){
	 								var participantNetworkFound=false;
	 								var roles=bmPartner.get('networkPartnerRole').models;
	 								for(var k=0;k<roles.length;k++){
	 									if(roles[k].get('collaborationRoleOwner').id === participantNetworkId){
	 										participantNetworkFound=true;
	 									}
	 								}
	 								if(participantNetworkFound && !alreadyAttached){
		 								alreadyAttached = self.checkReusabilityIfPartNetworkFound(businessModel);
						    		}
	 							}	
	 						})
 						}
 					}   
 				}
			}
			
			function fillRoleTypeAheadDetails(){
        		self.displayRoleTypeahead._reset();
				var roles = options.addOptions.collRoles;
				if(roles){
					for(var i=0;i<roles.length;i++){
						var rolcond = false;
						for(var j=0;j<self.rolesColl().length;j++){
							if(self.rolesColl()[j].originalId ==roles.at(i).get('id')){
								rolcond =true;
							}
						}
						if (!rolcond && roles.at(i).get('roleAssignment').at(0) && roles.at(i).get('roleAssignment').at(0).get('participant') !== self.parentView.get('business')){
							self.customerTypeaheadRoles.push(roles.at(i));
						}
					}
				}
				for(var i=0;i<self.customerTypeaheadRoles.length;i++){
					var displayRolename = self.checkRoleDuplicates(self.customerTypeaheadRoles,self.customerTypeaheadRoles.at(i));
					self.displayRoleTypeahead.push({id:self.customerTypeaheadRoles.at(i).id,name:displayRolename});
				}
			}
			
            function fillRoles(){
                if (self.options && self.options.addOptions && self.options.addOptions.pNModel && self.model) {
                    var pn = options.addOptions.pNModel;
                    var assignments = options.addOptions.assignments;
                    var assignmentColl = assignments[self.model.get("customer").get("id")];
                    self.rolesColl([]);
                    var roleColl = pn.get("collaboration").get("collaborationRole");
                    if (assignmentColl.models) {
                        for (var x = 0; x < assignmentColl.models.length; x++) {
                            var assignedRole = assignmentColl.models[x].get("assignedRole");
                            var roleExists = self.model.get('customerRole').findWhere({ id: assignedRole.get('id') });
                            if (roleExists && roleColl.indexOf(assignedRole) !== -1) {
                                var id = window.utils.htmlEscape(window.guidGenerator());
                                self.rolesColl.push({ id: id, originalId: assignedRole.get("id"), name: assignedRole.get("name"), desc: assignedRole.get("description") });
                            }
                        }
                    }
                    var removeRoleArray = options.addOptions.removeRole;
                    if (removeRoleArray && removeRoleArray.length > 0) {
                        for (var i = 0; i < self.rolesColl().length; i++) {
                            for (var j = 0; j < removeRoleArray.length; j++) {
                                if (removeRoleArray[j].participantId == self.id() && removeRoleArray[j].roleId === self.rolesColl()[i].originalId) {
                                    self.rolesColl.destroy(self.rolesColl()[i]);
                                    break;
                                }
                            }
                        }
                    }
                    self.rolesColl.sort(self.sortRolesByName);
                }
            }
			
			this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['name'
			,'description'
			,'Role'
			,'type'
			,'Close'
			,'Complete'
			,'x'
			,'EnterCustomerDetails'
			,'EditCustomerDetails'
			,'addAnother'
			,'Delete'
            ]);       
            

		}

        fillCustomerTypeaheadDetails() {
            var self = this;
            self.customerTypeahead._reset();
            self.displaycustomerTypeahead._reset();
            if (self.options.addOptions.customerTypeahead) {
                var typeaheads = self.options.addOptions.customerTypeahead.models;
                var temptypeaheads = self.options.addOptions.tempCustomerTypeahead;
                for (var i = 0; i < temptypeaheads.length; i++) {
                    for (var j = 0; j < typeaheads.length; j++) {
                        if (temptypeaheads[i].id === typeaheads[j].get('id')) {
                            if (temptypeaheads[i].utilized === false) {
                                self.customerTypeahead.push(typeaheads[j]);
                                self.displaycustomerTypeahead.push({ id: temptypeaheads[i].id, name: temptypeaheads[i].path });
                            }
                        }
                    }
                }
                for (var i = 0; i < self.filterIdList.length; i++) {
                    self.displaycustomerTypeahead.remove(self.filterIdList[i].id);
                }
            }
            if (self.options.addOptions.tempPartnerTypeAheadColl) {
                var tempPartnerTypeAheadColl = self.options.addOptions.tempPartnerTypeAheadColl;
                for (var i = 0; i < tempPartnerTypeAheadColl.length; i++) {
                    self.displaycustomerTypeahead.push({ id: tempPartnerTypeAheadColl[i].id, name: tempPartnerTypeAheadColl[i].name, description: tempPartnerTypeAheadColl[i].description, type: tempPartnerTypeAheadColl[i].type });
                }
            }
        }
        checkWhetherExpectedAsPartner(partner) {
            var self = this;
        	var partnerId="";
		    if (self.options.addOptions.pNModel) {
		        var businessModels = self.options.parentView.getNestedParent().get('businessModel').models;
		        var alreadyAttached = false;
		        for (var i = 0; i < businessModels.length; i++) {
		            var businessModel = businessModels[i];
		            var collaboration = "";
		            var participantNetworkId = "";
		            var participantNetworks = businessModel.get('participantNetwork');
		            for (var j = 0; j < participantNetworks.length; j++) {
		                if (participantNetworks.models[j].get('collaboration').id === self.options.addOptions.pNModel.get('collaboration').id) {
		                    collaboration = participantNetworks.models[j].get('collaboration');
		                    participantNetworkId = participantNetworks.models[j].get('collaboration').id;
		                }
		            }
		            if (collaboration !== "") {
		                var bmCustomers = businessModel.get('bmCustomer');
		                bmCustomers.each(function(bmCustomer) {
		                    if (bmCustomer.get('customer').id === self.options.parentView.get('business').id) {
		                        var participantNetworkFound = false;
		                        var roles = bmCustomer.get('customerRole').models;
		                        for (var k = 0; k < roles.length; k++) {
		                            if (roles[k].get('collaborationRoleOwner').id === participantNetworkId) {
		                                participantNetworkFound = true;
		                            }
		                        }
		                        if (participantNetworkFound) {
		                            var business = businessModel.get('business');
		                            var count = 0;
		                            var originalId = "";
		                            for (var m = 0; m < self.options.addOptions.partnerCollection.length; m++) {
		                                originalId = "";
		                                for (var n = 0; n < self.options.parentView.get('bmNetworkPartner').length; n++) {
											if (self.options.parentView.get('bmNetworkPartner').models[n].id === self.options.addOptions.partnerCollection[m].originalId && self.options.parentView.get('bmNetworkPartner').models[n].get('networkPartner')) {
		                                        originalId = self.options.parentView.get('bmNetworkPartner').models[n].get('networkPartner').id;
		                                    }
		                                }
		                                if (originalId == "") {
		                                    originalId = self.options.addOptions.partnerCollection[m].originalId;
		                                }
		                                if (originalId === business.id && !self.options.addOptions.partnerCollection[m].del) {
		                                    count = 1;
		                                }
		                            }
		                            if (count == 0 && !alreadyAttached) {
		                                partnerId = business.get('id');
		                                alreadyAttached=true;
		                            }
		                        }
		                    }
		                })
		            }
		        }
		    }
		    if(partner.id===partnerId){
		    	return true;
		    }
		    else{
		    	return false;
		    }
		}
        
        
        checkReusabilityIfPartNetworkFound(businessModel) {
            var self = this;
        	var alreadyAttached = false;
        	if(businessModel.get('business') && businessModel.get('business')!==null){
	        	var business = businessModel.get('business');
				var count=0;
				var originalId="";
				for(var i=0;i<self.filterIdList.length;i++){
					originalId="";
					for(var n=0;n<self.options.parentView.get('bmCustomer').length;n++){
						if(self.options.parentView.get('bmCustomer').models[n].get('customer').id===self.filterIdList[i].id){
							originalId=self.options.parentView.get('bmCustomer').models[n].get('customer').id;
						}
					}
					if(originalId==""){
						originalId=self.filterIdList[i].id;
					}
					if(originalId===business.id){
						count=1;
					}
				}
				if(count==0 && !alreadyAttached){
					if(self.checkWhetherExpectedAsPartner(business)){
						self.canReuseCustomer=false;
					}
					else{
						self.fillReusabilityDataIfExist(business,businessModel);
						alreadyAttached=true;
					}
				}
			}
			return alreadyAttached;
        }
        fillReusabilityDataIfExist(business,businessModel){
            var self = this;
        	self.originalId(business.get('id'));
			$('#name'+self.encodeId).val(business.get('name'));
			self.name(business.get('name'));
			self.description(business.get('description') ? business.get('description'):business.get('name'));
			self.typeAheadOriginalId(business.get('id'));
			self.customerType(self.customerTypeOptions()[1].typeStr);
			self.enableSelectOptions(false);
			self.typeaheadParentId(business.getNestedParent().id);
			/*var bmRoles = businessModel.get('bmRole').models;
			for(var i=0;i<bmRoles.length;i++){
				var assignments = bmRoles[i].get('roleAssignment').models;
				for(var j=0;j<assignments.length;j++){
					if(assignments[j].get('participant').id==business.get('id')){
						for(var k=0;k<options.addOptions.collRoles.models.length;k++){
							if(options.addOptions.collRoles.models[k].id===bmRoles[i].id){
								var id = htmlEscape(window.guidGenerator());
								self.rolesColl.push({id:id,originalId:bmRoles[i].id,name:bmRoles[i].get('name'),desc:bmRoles[i].get('name'),existing:true});
								self.enableComplete(true);
							}
						}
					}
				}
			}
			self.rolesColl.sort(sortRolesByName);*/
        }
        
        checkRoleDuplicates(models,element){
        	var path=element.get('name');
        	for(var i=0;i<models.length;i++){
        		if((element.get('name')===models.at(i).get('name') && (element.get('id')!==models.at(i).get('id')))){
        			path = path.concat("   [");
		        	for(var k=0;k<element.getPackagePath().length-1;k++){
		        		path = path.concat(element.getPackagePath()[k].name);
		        		if(element.getPackagePath()[k+2]){
		        			path = path.concat("/");
		        		}
		        	}
		            path = path.concat("]");
					
        		}
        		
        	}
        	return path;
        }
		

        deleteRole(view) {
            var self = this;;
			var count = 0;
			var role = self.options.parentView.get('bmRole').findWhere({'id':view.originalId});
			function doDeleteRole(){
				self.rolesColl.destroy(view);
				 for(var i=0;i<self.rolesColl().length;i++){
				 	if(!self.rolesColl()[i]._destroy){
						count=count+1;
					}
				 }
				 if(self.options.addOptions.collRoles){
				 	 self.customerTypeaheadRoles.push(self.options.addOptions.collRoles.get(view.originalId));
				 }
				 if(count===0){
					self.enableComplete(false);
				 }
			}
			if(view.originalId !== "" && role && self.options.addOptions.pNModel){
				var pn = self.options.addOptions.pNModel;
				var vpMessage = '';
				var vfMessage = '';
				var rvpMessage ='';
				var bm = pn.get('participantNetworkOwner');
				var bmVPList = bm.get('bmValueProposition');
				var vpcount = [];
				var rvpcount = [];
				for(var j=0;j<bmVPList.length;j++){
					var bmvp = bmVPList.at(j).get('valueProposition');
					if(bmvp && bmvp.get('provider') == role){
						vpcount.push(bmVPList.at(j).get('name'));
					}else if(bmvp && bmvp.get('recipient') == role){
						rvpcount.push(bmVPList.at(j).get('name'));
					}
				}
				for(var j=0;j<rvpcount.length;j++){
					if(j > 0){
						rvpMessage = rvpMessage.concat(', ');
					}
					else{
						if(rvpcount.length > 1) {
							rvpMessage = rvpMessage.concat(', receives Value Propositions '); 
						}else {
							rvpMessage = rvpMessage.concat(', receives Value Proposition '); 
						}
					}
					rvpMessage = rvpMessage.concat(''+rvpcount[j]);
				}
				for(var j=0;j<vpcount.length;j++){
					if(j > 0){
						vpMessage = vpMessage.concat(', ');
					}
					else{
						if(vpcount.length > 1) {
							vpMessage = vpMessage.concat(', provides Value Propositions '); 
						}else {
							vpMessage = vpMessage.concat(', provides Value Proposition '); 
						}
					}
					vpMessage = vpMessage.concat(''+vpcount[j]);
				}
				
				var bmVFList = bm.get('bmValueFormula');
				var vfcount = [];
				for(var j=0;j<bmVFList.length;j++){
					if(bmVFList.at(j).get('valueFormula') && bmVFList.at(j).get('valueFormula').get('provider') == role){
						vfcount.push(bmVFList.at(j).get('name'));
					}
				}
				for(var j=0;j<vfcount.length;j++){
					if(j > 0){
						vfMessage = vfMessage.concat(', ');
					}
					else{
						if(vfcount.length > 1) {
							vfMessage = vfMessage.concat(', provides My Propositions '); 
						}else {
							vfMessage = vfMessage.concat(', provides My Proposition '); 
						}
					}
					vfMessage = vfMessage.concat(''+vfcount[j]);
				}
				var actMessage = '';
				var actCount = [];
				for(var i=0;i<role.get('performedWork').length;i++){
					actCount.push(role.get('performedWork').at(i).get('name'));
				}
				for(var j=0;j<actCount.length;j++){
					if(j > 0){
						actMessage = actMessage.concat(', ');
					}
					else{
						if(actCount.length > 1) {
							actMessage = actMessage.concat(', and performs Activities '); 
						}else {
							actMessage = actMessage.concat(', and performs Activity '); 
						}
					}
					actMessage = actMessage.concat(''+actCount[j]);
				}
				var message = '';
				if(actMessage.length > 0 || vpMessage.length > 0 || vfMessage.length > 0 || rvpMessage.length > 0){
					message = message.concat('Role '+role.get('name')+' of Participant '+self.name());
					message = message.concat(''+vpMessage);
					message = message.concat(''+rvpMessage);
					message = message.concat(''+vfMessage);
					message = message.concat(''+actMessage);
					message = message.concat('. '+DataManager.getDataManager().get('localeManager').get('ConfirmMessage'));
				}
				if(message.length > 0) {
					bootbox.confirm(''+message, function(result) {
		            	if(result) {
		            		doDeleteRole();
		            	}
		            });
	            }else {
	            	doDeleteRole();
	            }
			}
			else{
				doDeleteRole();
			}
		}
		
        cleanModal() { 
            var self = this;
            if (!self.enableDelete() || self.modalShown == 1) {
				window.cleanDialogModel(self.encodeId,this);
			}	 
		}
		
		deleteModal(){
			var self=this;
			bootbox.confirm(DataManager.getDataManager().get('localeManager').get('DeleteCustomer'), function(result) {
				if(result){
					if(self.typeAheadOriginalId()!==""){
						var tempCustomerTypeahead = self.options.addOptions.tempCustomerTypeahead;
						for(var i=0;i<tempCustomerTypeahead.length;i++){
							if(tempCustomerTypeahead[i].id===self.originalId()){
								tempCustomerTypeahead[i].utilized=false;
							}
						}
					}
					return self.options.callback(self,self.encodeId,'customer',true);
				}
			});
		}
		
        saveEditRole() {
            var self = this;
			if($('#roleMessage'+self.encodeId).text().length == 0 && $('#editRoleName'+self.encodeId).val().trim() != ''){
				var roleName = $('#editRoleName'+self.encodeId).val().trim();
				var roleDescription = $('#editRoleName'+self.encodeId).val().trim();
				//var roleDescription = $('#roleDescription'+self.encodeId).val();
				$('#customerRolesName'+self.roleId).text(roleName);
				var match = _.filter(self.rolesColl(),function(obj){return obj.id === self.roleId;});
				match[0].name=roleName;
				match[0].desc=roleDescription;
//				self.rolesColl()[self.roleId].name=roleName;
//				self.rolesColl()[self.roleId].desc=roleDescription;			
				$("#cancelRoles"+self.encodeId).hide();
		        $("#saveEditedRoles"+self.encodeId).hide();
		        $("#addRoles"+self.encodeId).show();
		        $('#editRoleName'+self.encodeId).val("");
		        $('#roleName'+self.encodeId).val("");
		        $('#roleDescription'+self.encodeId).val("");
		        self.editRoleName(false);
		        self.roleId="";
		        self.enableShowCustomerRoleDiv(false);
		        if(self.rolesColl().length>0 && $('#message'+self.encodeId).html().length == 0 && $('#name'+self.encodeId).val() !== ""){
					self.enableComplete(true);
				}
	        }
		}
		
		/*this.cancelRole = function(){
			$("#cancelRoles"+self.encodeId).hide();
	        $("#saveEditedRoles"+self.encodeId).hide();
	        $("#addRoles"+self.encodeId).show();
	        self.roleId="";
	        $('#roleName'+self.encodeId).val("");
	        $('#roleDescription'+self.encodeId).val("");
	        self.editRoleName(false);
	        self.roleDescription("");
		}*/
		
        editRole(view, event) {
             var self = this;
			 self.enableShowCustomerRoleDiv(true);
		     self.roleId=view.id;
		     self.editRoleName(true);
		     self.roleDescription("");
		     $('#roleMessage'+self.encodeId).text("");
		     $('#editRoleName'+self.encodeId).val(view.name);
	 		 $('#roleName'+self.encodeId).val(view.name);
	 		 if(view.desc && (view.desc)!== ""){
				$('#roleDescription'+self.encodeId).val(view.desc);
				self.roleDescription(view.desc);
			 }else{
				$('#roleDescription'+self.encodeId).val(view.name);
		 	 }
	         $("#cancelRoles"+self.encodeId).show();
	         $("#saveEditedRoles"+self.encodeId).show();
	         $("#addRoles"+self.encodeId).hide();
	         $('#editRoleName'+self.encodeId).focus();
	         $('#roleName'+self.encodeId).closest('.row').find('.match').hide();
		}
		
        checkRoleExist(roleName, existing) {
            var self = this;
        	var count = 0;
        	for(var i=0;i<self.rolesColl().length;i++){
        		if(!self.rolesColl()[i]._destroy && self.rolesColl()[i].name === roleName && self.rolesColl()[i].id !== self.roleId){
        			count++;
        		}
        	}
        	for(var i=0;i<self.customerTypeaheadRoles.length;i++){
        		if(self.customerTypeaheadRoles.at(i).get('name') === roleName){
        			count++;
        		}
            }
            if (self.model && existing && self.disallowedRoleNames.includes(roleName)) {
                return false;
            }
        	if(count>0){
        		return false;
        	}
        	if(!self.model){
	        	self.matchField.show();
	        	self.matchField[0].innerHTML = 'New';
	        }
        	return true;
        }

        addRole() {
            var self = this;
			if($('#roleMessage'+self.encodeId).text().length == 0){
				var roleName = $('#roleName'+self.encodeId).val();
				var roleDescription = $('#roleName'+self.encodeId).val();
				//var roleDescription = $('#roleDescription'+self.encodeId).val();
				if(roleName !== null && roleName !== "") {
					var existing = self.customerRoleOriginalId?true:false;
					var id = window.utils.htmlEscape(window.guidGenerator());
					self.rolesColl.push({id:id,originalId:self.customerRoleOriginalId,name:roleName,desc:roleDescription,existing:existing});
					if(self.name()!==""  && $('#message'+self.encodeId).html().length == 0){
						self.enableComplete(true);
					}
					self.customerTypeaheadRoles.remove(self.customerRoleOriginalId);
					self.displayRoleTypeahead.remove(self.customerRoleOriginalId);
					$('#roleName'+self.encodeId).val("");
					$('#roleDescription'+self.encodeId).val("");
					self.enableShowCustomerRoleDiv(false);
					self.roleDescription("");
					self.rolesColl.sort(self.sortRolesByName);
				}
			}
		}
		sortRolesByName(left, right) {
            return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
        }
        fetchCustomerType(typeStr) {
            var self = this;
			var type;
			for(var i=0;i<self.custTypeArray.length;i++){
				if(self.custTypeArray[i].typeStr == typeStr){
					type = self.custTypeArray[i].type;
					break;
				}
			}
			return type;
		}
		
        saveCustomerDetails() {
            var self = this;
			if(self.parentView){
				if(self.name() !== null && self.name() !== "") {
					self.fillCustomerTypeaheadDetails();
					return self.options.callback(this,self.encodeId,'customer');
				}
			}
		}
		
		/*function fetchCustomerTypeaheadModel(callback){
			if(self.typeaheadParentId() === 'new'){
				callback(null);
			}
			else {
				var parentAltId = self.typeaheadParentId().substr(0,self.typeaheadParentId().lastIndexOf('@')+1);
	            var vdmStore = DataManager.getDataManager().getVDMStore(parentAltId);
				DataManager.getDataManager().fetchDocumentFromPackage(self.typeaheadParentId(),"appbo/vdml/ValueDeliveryModel",DataManager.getDataManager().get('currentVDMVersion'),self.typeAheadOriginalId(),"appbo/vdml/Participant",vdmStore,{
	                success:function(model){
	                    callback(model);
	                },
	                error:function(error){
	                	callback(null);
	                    console.log(error);
	                }
	            });
            }
		}*/
			
		
		saveData(bm,partNetwork,assignments,participantColl,removeRoleArray,revision,alt,callback){
            var self = this;
            return self.createCustomers(bm, partNetwork, assignments, participantColl, removeRoleArray, revision, alt, callback);
		}
				
		createCustomers(bm,partNetwork,assignments,participantColl,removeRoleArray,revision,alt,callback){
            var self = this;
            var customer;
			if(self.id() && self.id() !== "" && self.typeAheadOriginalId()==""){
				customer = self.model;
				self.model.set('name',this.name().trim());
				self.model.get('customer').set('name',this.name().trim());
				self.model.set('description', this.description().trim());
				self.saveCustomerRoles(bm,customer,partNetwork,assignments,removeRoleArray,revision,alt,function(customerJson){
					callback(customerJson);
				});
			}
			else if(self.typeAheadOriginalId()!==""){
				//var custType = fetchCustomerType(self.customerType());
				var vdmlCustType = 'vdml.'+self.customerType();
				var existCustomerModal = Backbone.Relational.store.getObjectByName(''+vdmlCustType).find({ id: self.typeAheadOriginalId()});
				if(!existCustomerModal && self.typeAheadOriginalId() === "new"){
					var participantList = Object.keys(assignments);
					for(var i=0;i<participantList.length;i++){
						var custModal = Backbone.Relational.store.getObjectByName(''+vdmlCustType).find({ id: participantList[i]}); 
					    if(custModal && custModal.get('name') === self.name().trim()){
					    	existCustomerModal = custModal;
					    	break;
					    }
					}
				}
				var typeaheadCustomer = bm.createBmCustomer(self.name().trim(),self.description().trim(),existCustomerModal);
			 	self.saveCustomerRoles(bm,typeaheadCustomer,partNetwork,assignments,removeRoleArray,revision,alt,function(customerJson){
			 		if(self.model){
						for(var j=0;j<participantColl.length;j++){
		            		for(var k=0;k<participantColl[j].customerColl.length;k++){
		            			if(participantColl[j].customerColl[k].originalId===self.model.id){
		            				var bmCustomer = self.model;
									var customerDeletionArray = []; 
									var customerAssignments = assignments[bmCustomer.get('customer').id];
				                    var assign = self.fetchAssignmentsFromRoles(participantColl[j].customerColl[k].roles,customerAssignments,bm);
				                    customerDeletionArray.push({'object':bmCustomer,'type':'customer','assignment':assign}); 
				                    assignments[bmCustomer.get('customer').get('id')].remove(assign);
		            				participantColl[j].customerColl.splice(k,1);
		            				break;
		            			}
		            		}
		            		if(participantColl[j].id === self.typeAheadOriginalId()){
		            			participantColl.splice(j,1);
		            		}
	            		}
	            		async.eachSeries(customerDeletionArray, bm.deleteParticipant.bind(bm), function(err){
						});
					}else {
						for(var j=0;j<participantColl.length;j++){
							if(participantColl[j].originalId === self.typeAheadOriginalId()){
			            		participantColl.splice(j,1);
			            		break;
			            	}
	            		}
					}
					callback(customerJson);
			 	});
			}
			else {
				 bm.createNewCustomer(self.name().trim(),self.description().trim(),self.fetchCustomerType(self.customerType()),null,partNetwork,alt,function(customer){
					assignments[customer.get('customer').get("id")] = new Backbone.Collection();
					self.saveCustomerRoles(bm,customer,partNetwork,assignments,removeRoleArray,revision,alt,function(customerJson){
						callback(customerJson);
					});
				});
			}
		}
		
		saveCustomerRoles(bm,customer,partNetwork,assignments,removeRoleArray,revision,alt,callback){
            var self = this;
            var cusRoleColl = [];
			var createNewRoles = [];
			var roleDestroyArray = [];
			if(revision){
				for(var i=0;i<self.rolesColl().length;i++){
					if(self.rolesColl()[i].originalId !== ""){
						var rollSuffix = window.utils.getSuffix(self.rolesColl()[i].originalId);
						self.rolesColl()[i].originalId = ''+DataManager.getDataManager().get('viewAlternative')+''+rollSuffix;
					}
				}
			}
			for(var i=0;i<self.rolesColl().length;i++){
				if(self.rolesColl()[i].originalId !== "" && !self.rolesColl()[i]._destroy){
					var bmRole = bm.get('bmRole').findWhere({id: self.rolesColl()[i].originalId});
					if(bmRole && bmRole.get('roleAssignment').length > 0 && bmRole.get('roleAssignment').at(0).get('participant') == customer.get('customer')){
						bmRole.set('name',self.rolesColl()[i].name);
						bmRole.set('description',self.rolesColl()[i].desc);
						customer.get('customerRole').add(bmRole);
						bm.checkRoleExistsForPartner(customer.get('customer'),bmRole,true);
						cusRoleColl.push({name:self.rolesColl()[i].name,originalId:self.rolesColl()[i].originalId});
					}
					else{
						var role = partNetwork.get('collaboration').get('collaborationRole').findWhere({'id':self.rolesColl()[i].originalId});
						var existingAssignment = bm.addExistingTypeaheadCustomerRole(customer,role,partNetwork);
						customer.get('customerRole').add(role);
						if(!assignments[customer.get('customer').get("id")]){
							assignments[customer.get('customer').get("id")]= new Backbone.Collection;
						}
						assignments[customer.get('customer').get("id")].push(existingAssignment);
						cusRoleColl.push({name:role.get("name"),originalId:role.get("id")});
					}
					
				}
				else {
					if(self.rolesColl()[i]._destroy && self.rolesColl()[i].originalId !== ""){
						var assignmentColl = assignments[customer.get("customer").get("id")];
						if(assignmentColl.models){
							for(var x=0;x<assignmentColl.models.length;x++){
								var assignedRole = assignmentColl.models[x].get("assignedRole");
								if(self.rolesColl()[i].originalId === assignedRole.get("id")){
									customer.get('customerRole').remove(assignedRole);
									roleDestroyArray.push(assignedRole);
									//bm.removeExistingRole(self.model.get("customer"),assignedRole,assignmentColl.models[x],assignments,customer);
								}
							}
						}
						
					}
					else {
						if(!self.rolesColl()[i]._destroy){
							createNewRoles.push(self.rolesColl()[i]);
							/*var newAssignment = bm.addNewCustomerRole(customer,self.rolesColl()[i].name,self.rolesColl()[i].desc,partNetwork);
							var newRole = newAssignment.get("assignedRole");
							//customer.get('customerRole').add(newRole);
							cusRoleColl.push({name:newRole.get("name"),originalId:newRole.get("id")});
							if(!assignments[customer.get('customer').get("id")]){
								assignments[customer.get('customer').get("id")]= new Backbone.Collection;
							}
							assignments[customer.get('customer').get("id")].push(newAssignment);*/
						}
					}
				}
			}
			async.eachSeries(createNewRoles,function(role,roleCallback){
				bm.addNewCustomerRole(customer,role.name,role.desc,partNetwork,alt,function(newAssignment){
					var newRole = newAssignment.get("assignedRole");
					cusRoleColl.push({name:newRole.get("name"),originalId:newRole.get("id")});
					if(!assignments[customer.get('customer').get("id")]){
						assignments[customer.get('customer').get("id")]= new Backbone.Collection;
					}
					assignments[customer.get('customer').get("id")].push(newAssignment);
					roleCallback();
				});
			},function(err){
				for(var j=0;j<roleDestroyArray.length;j++){
					var roleDelete = roleDestroyArray[j];
					var roleDeleteAssignment = roleDestroyArray[j].get('roleAssignment').at(0);
					var deleteRole = true;
					for(var k=0;k<removeRoleArray.length;k++){
						if(removeRoleArray[k].roleId == roleDelete.get('id') && removeRoleArray[k].participantId == roleDeleteAssignment.get('participant').get('id')){
							deleteRole = false;
							break;
						}
					}
					if(deleteRole){
						bm.removeExistingRole(customer.get("customer"),roleDelete,roleDeleteAssignment,assignments,customer);
					}
				}
				var customerJson = {modalId:window.utils.htmlEscape(customer.get("id")), name:customer.get("name"),originalId:customer.get("id"),roles:cusRoleColl};
				//return customerJson;
				callback(customerJson);
			});
			
		}
		
        fetchAssignmentsFromRoles(roles, assignList, bm) {
            var self = this;
        	var participantAssignments = new Backbone.Collection();
        	var bmRoles = bm.get('bmRole');
        	for(var j=0;j<self.roles.length;j++) {            
            	var role = bmRoles.findWhere({'id':self.roles[j].originalId});
                if(role){
                     var assign = assignList.findWhere({'assignedRole':role});
                     if(assign) {
                     	participantAssignments.push(assign);
                     }
                }
			}
			return participantAssignments;
        }


        getUniqueValue(val) {
            var self = this;
    			var unique = true;
    			for(var i=0;i<self.filterIdList.length;i++){
    				if(self.filterIdList[i].name === val.trim() && self.filterIdList[i].type.indexOf(self.customerType()) > -1 && self.filterIdList[i].modalId != self.encodeId){
    					unique = false;
        				break;
    				}	
    			}
    			if(unique) {
	    			var participantList = new Backbone.Collection();
	    			var nestedpack = self.parentView.getNestedParent();
	    			participantList.add(nestedpack.get('actor').models);
	    			var colls = nestedpack.get('collaboration');
	    			for(var i=0;i<colls.length;i++){
	    				if(colls.at(i).get('type') === 'vdml_OrgUnit' || colls.at(i).get('type') === 'vdml_Community'){
	    					participantList.add(colls.at(i));
	    				}
	    			}
	        		for(var i=0;i<participantList.length;i++){
	        			if(participantList.at(i).get('name') === val.trim()){
	        				var type = participantList.at(i).get('type');
		        			if(self.customerType && type.indexOf(self.customerType()) > 1){
		        				unique = false;
		        				break;
		        			}
	        			}
        			}
        		}
        		if(!self.model){
        			if(unique){
	        			$('#message'+self.encodeId).text("");
	        			if(val!==""){
		        			self.matchField.show();
		        			self.matchField[0].innerHTML = 'New';
	        			}
	        			else{
	        				self.matchField.hide();
	        			}
	        			return false;
        			}
					if($('#name'+self.encodeId).next().find('li :contains('+val+')').length > 0){
	    				self.matchField.show();
						self.matchField[0].innerHTML = 'Match';
						$('#name'+self.encodeId).next().find('li :contains('+val+')').click();
    					return false;
					}
					self.matchField.hide();
					$('#message'+self.encodeId).text(""+DataManager.getDataManager().get('localeManager').get('ParticipantDuplicate'));
					return true;
        		}
        		else if(!unique && self.model && self.model.get('name')!==val){
    				$('#message'+self.encodeId).text(""+DataManager.getDataManager().get('localeManager').get('ParticipantDuplicate'));
    				return true;
                } else if (unique && self.disallowedNames.includes(val.trim())) {
                    $('#message' + self.encodeId).text("" + DataManager.getDataManager().get('localeManager').get('ParticipantDuplicate'));
                    return false;
                }
				$('#message'+self.encodeId).text('');
				return false;
        }
        showCustomerRoleDiv() {
            var self = this;
    		self.enableShowCustomerRoleDiv(true);
 			$('#roleName'+self.encodeId).focus();
            $('#roleName' + self.encodeId).closest('.row').find('.match').hide();
        }
		afterRenderView(node, view){
            var self = view;
			$('.match').hide();
	    	var Extended = Backbone.Typeahead.extend({
			  template: '<input type="text" value ="'+self.name()+'" id="name'+self.encodeId+'" placeholder="Name" class="form-control focus-ele"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
			});
			$('#editDetailsModalContent' + self.encodeId).resize(function () {
				$('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
			})
			$("#cancelRoles"+self.encodeId).hide();
			$("#saveEditedRoles"+self.encodeId).hide();
			var bmCustomertypeahead = new Extended({collection:self.displaycustomerTypeahead});
			bmCustomertypeahead.setElement('#BmCustomerDiv'+self.encodeId).render();
			bmCustomertypeahead.on('selected', function(fetchmodel) {
				var customer=self.customerTypeahead.findWhere({id:fetchmodel.get('id')});
				if(self.canReuseCustomer && customer){
					var res = self.checkWhetherExpectedAsPartner(customer);
					if(res){
						bootbox.alert(DataManager.getDataManager().get('localeManager').get('ExpectingPartner'));
					}
				}
				self.rolesColl.removeAll();
				self.enableComplete(false);
				var custTypeAheadType;
				if(customer) {
					self.originalId(customer.get("id"));
                    self.typeAheadOriginalId(customer.get("id"));
                    self.typeaheadName = customer.get("name");
					self.typeaheadParentId(customer.get('parent'));
					custTypeAheadType = customer.get('type');	
					$('#name'+self.encodeId).val(customer.get("name"));
					self.name(customer.get("name"));
					self.description(customer.get("description") ? customer.get("description"):customer.get("name"));
				}else {
					self.originalId('new');
					self.typeAheadOriginalId('new');
					self.typeaheadParentId('new');
					$('#name'+self.encodeId).val(fetchmodel.get("name"));
					self.name(fetchmodel.get('name'));
					custTypeAheadType = 'vdml_'+ fetchmodel.get('type');
					self.description(fetchmodel.get("description") ? fetchmodel.get("description"):fetchmodel.get("name"));
				}
				if(custTypeAheadType === "vdml_OrgUnit"){
					self.customerType(self.customerTypeOptions()[1].typeStr);
				}else if(custTypeAheadType === "vdml_Community"){
					self.customerType(self.customerTypeOptions()[0].typeStr);
				}else if(custTypeAheadType === "vdml_Actor"){
					self.customerType(self.customerTypeOptions()[2].typeStr);
				}
				$('#message'+self.encodeId).text("");
				self.enableSelectOptions(false);
				if(!self.model){
	        		self.matchField.show();
	        		self.matchField[0].innerHTML = 'Match';
        		}
			});
			
			if(self.model) {
				$("#titleLabel"+self.encodeId).text(DataManager.getDataManager().get('localeManager').get('EditCustomerDetails'));
			}else {
				$("#titleLabel"+self.encodeId).text(DataManager.getDataManager().get('localeManager').get('EnterCustomerDetails'));	
			}
			
			$("#name"+self.encodeId).change(function (view) {
				var newValue = view.currentTarget.value;
		        if(newValue !== '') {
		        	self.name(newValue);
		        	self.originalId("");
		        	if(self.typeAheadOriginalId()!==""){
		        		self.rolesColl([]);
		        		self.enableComplete(false);
                    }
                    if(self.typeaheadName != newValue){
                        self.typeAheadOriginalId("");
                    }
		        	if(newValue !== '' && self.rolesColl().length > 0){
		        		self.enableComplete(true);
		        	}
		        	else {
		        		self.enableComplete(false);
		        	}
		        }
		        /*if(self.originalId()=="" && self.rolesColl().length==0){
		        	fillRoles();
		        }*/
		    });
			$("#name"+self.encodeId).keyup(function (view) {
				self.matchField = $(view.currentTarget).closest('.row').find('.match');
                var newValue = view.currentTarget.value;
                //self.getUniqueValue(newValue.trim());
                if (!self.getUniqueValue(newValue.trim()) && newValue.trim() !== '' && self.rolesColl().length>0 && $('#message'+self.encodeId).html().length == 0) {
                    self.enableComplete(true);
                }
                else {
                	self.enableComplete(false);
                }
	        	if(!self.model && (self.matchField[0].innerHTML == 'New' || newValue == '')){
	        		self.enableSelectOptions(true);
	        	}
            });
			var ExtendedroleName = Backbone.Typeahead.extend({
			  template: '<input type="text"  value="'+self.Rolename()+'" style="width:170px" id="roleName'+self.encodeId+'" placeholder="Role Name" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
			});
			var roleNametypeahead = new ExtendedroleName({collection:self.displayRoleTypeahead});
		    roleNametypeahead.setElement('#BmCustomerRoleNameDiv'+self.encodeId).render();
		    roleNametypeahead.on('selected', function(fetchmodel) {
		    	$('#roleMessage'+self.encodeId).text("");
		    	var customerRole=self.customerTypeaheadRoles.findWhere({id:fetchmodel.get('id')});
		    	if(customerRole.get("description")){
					$('#roleDescription'+self.encodeId).val(customerRole.get("description"));
					self.roleDescription(customerRole.get("description"));
				}
				else{
					$('#roleDescription'+self.encodeId).val($('#roleName'+self.encodeId).val());
				}
                var rolesAssignment = customerRole.get('roleAssignment');
                //var customerModel = self.model.get('customer');
		    	var customerId = self.model?self.model.get('customer').get('id'):'';
		    	if(self.typeAheadOriginalId()){
		    		customerId = self.typeAheadOriginalId();
		    	}
		    	if(rolesAssignment.length > 0){
		    		var participant = rolesAssignment.at(0).get('participant');
		    		if(participant.get('id') === customerId){
                        self.customerRoleOriginalId = customerRole.get("id");
                        self.typeaheadRoleName = customerRole.get("name");
						$('#roleName'+self.encodeId).val(customerRole.get("name"));
						if(customerRole.get("description")){
							$('#roleDescription'+self.encodeId).val(customerRole.get("description"));
							self.roleDescription(customerRole.get("description"));
						}
						else{
							$('#roleDescription'+self.encodeId).val(customerRole.get("name"));
                        }
                        if ($('#name' + self.encodeId).val() !== "" && $('#message' + self.encodeId).text().length == 0) {
                            self.enableComplete(true);
                        }
		    		}
		    		else {
		    			var message = '';
		    			var blockRole = false;
		    			participant.getBusinessModelsInvolved(function(bms){
		    				for(var i=0;i<bms.length;i++){
		    					var bm = bms[i];
								if(bm.get('bmRole').findWhere({id:customerRole.get('id')})){
									var bmMessage = '';
					    			var enableVpMessage = false;
					    			var enableVfMessage = false;
					    			var enableActivityMessage = false;
					    			var enableComptenencyMessage = false;
					    			var participantMessage = '';
					    			var vpMessage = '';
					    			var vfMessage = '';
					    			var activityMessage = '';
					    			var comptenencyMessage = '';
									participantMessage = participantMessage.concat('Role '+customerRole.get('name')+', as assigned to '+participant.get('name'));
									
									var bmVPList = bm.get('bmValueProposition');
									var vpcount = 0;
									for(var j=0;j<bmVPList.length;j++){
										if(bmVPList.at(j).get('valueProposition') && bmVPList.at(j).get('valueProposition').get('provider') == customerRole){
											if(vpcount > 0){
												vpMessage = vpMessage.concat(', ');
											}
											else{
												vpMessage = vpMessage.concat(', provides Value Proposition '); 
											}
											vpMessage = vpMessage.concat(''+bmVPList.at(j).get('name'));
											enableVpMessage = true;
											vpcount++;
										}
									}
									
									var bmVFList = bm.get('bmValueFormula');
									var vfcount = 0;
									for(var j=0;j<bmVFList.length;j++){
										if(bmVFList.at(j).get('valueFormula') && bmVFList.at(j).get('valueFormula').get('provider') == customerRole){
											if(vfcount > 0){
												blockRole = true;
												vfMessage = vfMessage.concat(', ');
											}
											else{
												vfMessage = vfMessage.concat(', provides My Proposition '); 
											}
											vfMessage = vfMessage.concat(''+bmVFList.at(j).get('name'));
											enableVfMessage = true;
											vfcount++;
										}
									}
									
									var bmActList = bm.get('bmActivity');
									var actcount = 0;
									var compcount = 0;
									for(var j=0;j<bmActList.length;j++){
										if(bmActList.at(j).get('activity') && bmActList.at(j).get('activity').get('performingRole') == customerRole){
											if(actcount > 0){
												activityMessage = activityMessage.concat(', ');
											}
											else{
												activityMessage = activityMessage.concat(' and performs Activity '); 
											}
											activityMessage = activityMessage.concat(''+bmActList.at(j).get('name'));
											enableActivityMessage = true;
											var competences = bmActList.at(j).get('activity').getActivityCompetences();
											if(competences.length > 0){
												blockRole = true;
												var bmCompList = bm.get('bmCompetence');
												if(bmCompList.length > 0 && (comptenencyMessage.indexOf('Competency') == -1 && comptenencyMessage.indexOf('Competencies') == -1)){
													if(blockRole){
							    						comptenencyMessage = comptenencyMessage.concat(' and also');
							    					}else {
							    						comptenencyMessage = comptenencyMessage.concat(' using');
							    					}
							    					if(bmCompList.length > 1){
							    						comptenencyMessage = comptenencyMessage.concat(' Competencies ');
							    					}else {
							    						comptenencyMessage = comptenencyMessage.concat(' Competency ');
							    					}
												}
												for(var n=0;n<competences.length;n++){
													if(bmCompList.findWhere({'competence':competences[n]})) {
														if(compcount > 0){
															comptenencyMessage = comptenencyMessage.concat(', ');
														}
														comptenencyMessage = comptenencyMessage.concat(''+ competences[n].get('name'));
														enableComptenencyMessage = true;
														compcount++;
													}
												}
											}
											actcount++;
										}
									}
									bmMessage = bmMessage.concat(''+ participantMessage);
				    				if(enableVpMessage && !blockRole){
				    					bmMessage = bmMessage.concat(''+ vpMessage);
				    				}
				    				if(enableVfMessage){
				    					bmMessage = bmMessage.concat(''+ vfMessage);
				    				}
				    				if(enableActivityMessage && !blockRole){
				    					bmMessage = bmMessage.concat(''+ activityMessage);
				    				}
				    				if(enableComptenencyMessage){
				    					bmMessage = bmMessage.concat(''+ comptenencyMessage);
				    				}
				    				if(!enableVpMessage && !enableVfMessage && !enableActivityMessage && !enableComptenencyMessage){
										bmMessage = 'Role '+customerRole.get('name')+', is assigned to '+participant.get('name');
									}
									bmMessage = bmMessage.concat(' in Business Model '+bm.get('name'));
									message = message.concat(''+ bmMessage + '<br/>');
								}	
								
		    				}
		    				if(blockRole){
		    					bootbox.alert(''+message);
		    					$('#roleName'+self.encodeId).val('');
								$('#roleDescription'+self.encodeId).val('');
		    				}
		    				else {
		    					bootbox.confirm(''+message, function(result) {
				                	if(result) {
                                        self.customerRoleOriginalId = customerRole.get("id");
                                        self.typeaheadRoleName = customerRole.get("name");
										$('#roleName'+self.encodeId).val(customerRole.get("name"));
                                        $('#roleDescription' + self.encodeId).val(customerRole.get("name"));
                                        if ($('#name' + self.encodeId).val() !== "" && $('#message' + self.encodeId).text().length == 0) {
                                            self.enableComplete(true);
                                        }
				                	}
				                	else {
				                		$('#roleName'+self.encodeId).val('');
                                        $('#roleDescription' + self.encodeId).val('');
                                        self.Rolename('');
                                        self.roleDescription('');
                                        self.matchField && self.matchField.hide();
                                        if (window.utils.getObservableLength(self.rolesColl()) == 0) {
                                            self.enableComplete(false);
                                        }
				                	}
				                });	
		    				}
							
						});	
		    		}	
		    	}
		    	else {
                    self.customerRoleOriginalId = customerRole.get("id");
                    self.typeaheadRoleName = customerRole.get("name");
					$('#roleName'+self.encodeId).val(customerRole.get("name"));
					if(customerRole.get("description") && customerRole.get("description")!==""){
						$('#roleDescription'+self.encodeId).val(customerRole.get("description"));
					}
					else{
						$('#roleDescription'+self.encodeId).val(customerRole.get("name"));
                    }
                    if ($('#name' + self.encodeId).val() !== "" && $('#message' + self.encodeId).text().length == 0) {
                        self.enableComplete(true);
                    }
		    	}
		    	if(!self.model){
			    	self.matchField.show();
			    	self.matchField[0].innerHTML = 'Match';
                }
			});
			$("#roleName"+self.encodeId).change(function (view) {
				var newValue = view.currentTarget.value;
				$('#roleDescription'+self.encodeId).val(newValue);
				self.roleDescription(newValue);
				self.Rolename(newValue);
                if (newValue !== '' && self.typeaheadRoleName != newValue) {
		        	self.customerRoleOriginalId="";
		        }
		    });
		    $("#editRoleName"+self.encodeId).change(function (view) {
				var newValue = view.currentTarget.value;
				$('#roleDescription'+self.encodeId).val(newValue);
		    });
		    $("#editRoleName"+self.encodeId).keyup(function (view) {
		    	self.matchField = $(view.currentTarget).closest('.row').find('.match');
				var newValue = view.currentTarget.value;
				if(view.currentTarget.value.length>0){
                    if (!self.checkRoleExist(view.currentTarget.value.trim(), true)){
		    			$('#roleMessage'+self.encodeId).text(""+DataManager.getDataManager().get('localeManager').get('RoleDuplicate'));
						self.enableShowCustomerRoleDiv(true);
						self.enableComplete(false);
					}
					else{
						$('#roleMessage'+self.encodeId).text("");
						if(view.currentTarget.value.length>0 && $('#name'+self.encodeId).val() !== "" && $('#message'+self.encodeId).html().length == 0){
				    		self.enableComplete(true);
				    	}
					}
		    	}
		    	else if (self.matchField){
		    		self.matchField.hide();
		    	}
		    });
		    $("#roleName"+self.encodeId).keyup(function (view) {
		    	self.matchField = $(view.currentTarget).closest('.row').find('.match');
		    	if(view.currentTarget.value.length>0 && $('#name'+self.encodeId).val() !== "" && $('#message'+self.encodeId).html().length == 0){
		    		self.enableComplete(true);
		    	}
		    	if(view.currentTarget.value.length>0){
                    if (!self.checkRoleExist(view.currentTarget.value.trim(), false)){
		    			$('#roleMessage'+self.encodeId).text(""+DataManager.getDataManager().get('localeManager').get('RoleDuplicate'));
						self.enableShowCustomerRoleDiv(true);
						self.enableComplete(false);
					}
					else{
						$('#roleMessage'+self.encodeId).text("");
					}
		    	}
		    	else{
                    self.matchField.hide();
                    if (window.utils.getObservableLength(self.rolesColl()) == 0 || $('#name' + self.encodeId).val() == "") {
                        self.enableComplete(false);
                    }
		    	}
            });
            $('#roleName' + self.encodeId).dblclick(function (view) {
                $('#roleName' + self.encodeId).trigger('keyup');
                $('#roleName' + self.encodeId).focus();
            });
		    $("#roleDescription"+self.encodeId).keyup(function (view) {
				var newValue = view.currentTarget.value;
				self.roleDescription(newValue);
		    });
		    if(self.rolesColl().length>0){
				self.enableComplete(true);
			}
			self.attachMouseDown();
			
			window.utils.applyFontSizeInDialog();
		};
        attachMouseDown() {
            var self = this;
            var eleID = ('modal' + self.encodeId);
            $('#' + eleID).mouseup(function (event) {
            if ($('#customerRoleDiv' + self.encodeId).has($(event.target)).length > 0) {
                self.enableShowCustomerRoleDiv(true);
            } else {
                if ($("#roleName" + self.encodeId).val() && ($("#roleName" + self.encodeId).val().trim() !== "" || $("#editRoleName" + self.encodeId).val().trim() !== "")) {
                    if (self.editRoleName() && self.roleId !== "") {
                        if (self.roleDescription() == "") {
                            $("#roleDescription" + self.encodeId).val($("#roleName" + self.encodeId).val());
                        }
                        else {
                            $("#roleDescription" + self.encodeId).val(self.roleDescription());
                        }
                        self.saveEditRole();
                    }
                    else if ($("#roleName" + self.encodeId).val() !== "") {
                        if (self.roleDescription() == "") {
                            $("#roleDescription" + self.encodeId).val($("#editRoleName" + self.encodeId).val());
                        }
                        else {
                            $("#roleDescription" + self.encodeId).val(self.roleDescription());
                        }
                        self.addRole();
                    }
                }
                else {
                    self.enableShowCustomerRoleDiv(true);
                }
        }
    });
}
	static getInstance(model,options){
		var view = new CustomerDetailsViewModel(model,options);
		view.init(model, options);
		return view;
	};
}
path.CustomerDetailsViewModel = CustomerDetailsViewModel;