import * as $ from 'jquery'
import * as _ from 'underscore'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
var path = DataManager.getDataManager().buildAppNsPath("tickets.views.properties", global.version);
export class Market{
    constructor () {
    }
    static getViewProperties = function (type) {
            return {
                templatePath: "views/tickets/views/properties/MarketTemplate.html",
                templateName: "MarketTemplate",
                viewTypeStr: "appviews/tickets/views/properties/MarketViewModel",
                tabId: "MarketView",
                tabName: "Market"
            }

        }
    }
path.Market = Market;
