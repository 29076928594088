import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
//import * as  backboneLawnchair from 'backbonelawnchair'
import {BeepPackage} from './BeepPackage'
import {BeepModuleMixin} from './BeepModuleMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/beeppackage/BeepPackage","appbo/beeppackage/BeepModuleMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BeepPackage,BeepModuleMixin
//){
	
	var path = DataManager.getDataManager().buildAppNsPath("beeppackage",global.version);
	var BeepModule = BeepPackage.extend(utils.customExtends({
		relations:BeepModuleMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BeepPackage.prototype.initialize.apply(this, arguments);
		}
		}
		, new BeepModuleMixin()
	));
	
	BeepModule.abstract = false;
	path.BeepModule = BeepModule;
	export {BeepModule};
	//return BeepModule;
//});