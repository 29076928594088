import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BinaryMeasure} from './BinaryMeasure'
import {RatioMeasureMixin} from './RatioMeasureMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/BinaryMeasure","appbo/smm/RatioMeasureMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BinaryMeasure,RatioMeasureMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var RatioMeasure = BinaryMeasure.extend(utils.customExtends({
		relations:RatioMeasureMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BinaryMeasure.prototype.initialize.apply(this, arguments);
			if(attributes.scale){
				this.set('scale',path.ScaleOfMeasurement[attributes.scale instanceof Object ? attributes.scale.name : attributes.scale]);
			}
			if(attributes.functor){
				this.set('functor',path.BinaryFunctor[attributes.functor instanceof Object ? attributes.functor.name : attributes.functor]);
			}
		}
		}
		, new RatioMeasureMixin()
	));
	
	RatioMeasure.abstract = false;
	RatioMeasure.supported = false;
	path.RatioMeasure = RatioMeasure;
	export {RatioMeasure};
//});