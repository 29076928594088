import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {MeasurableElementMixin} from './MeasurableElementMixin'
import {MeasurableElement2Mixin} from './MeasurableElement2Mixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { MeasuredCharacteristic } from './MeasuredCharacteristic'

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
if(!MeasuredCharacteristic){
	var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(importPath.MeasuredCharacteristic){
		MeasuredCharacteristic = importPath.MeasuredCharacteristic;
	}
	else{
		import('./MeasuredCharacteristic').then(({ default: MeasuredCharacteristic }) => {
			MeasuredCharacteristic = MeasuredCharacteristic;
		});
		/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
			MeasuredCharacteristic = loadedModule;
		});*/
	}
}
var getMixinClass = function (){
	var toggleRouter = DataManager.getDataManager().get("toggleRouter");
	var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
	return sp2Enabled == true ? MeasurableElement2Mixin : MeasurableElementMixin;
}
var meMixinClass = getMixinClass();
var MeasurableElement = VdmlElement.extend(utils.customExtends({
		relations:meMixinClass.getMixinRelations(),
		subModelTypes: {
			vdml_ValueProposition : 'vdml.ValueProposition',
			vdml_BusinessItem : 'vdml.BusinessItem',
			vdml_DeliverableFlow : 'vdml.DeliverableFlow',
			vdml_Participant : 'vdml.Participant',
			vdml_Port : 'vdml.Port',
			vdml_PortContainer : 'vdml.PortContainer',
			vdml_ResourceUse : 'vdml.ResourceUse',
			vdml_BusinessModel : 'vdml.BusinessModel',
			vdml_Competence : 'vdml.Competence',
			vdml_ValueElement : 'vdml.ValueElement'
		},
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
			
		}
	}
	, new meMixinClass()
));
MeasurableElement.abstract = true;
path.MeasurableElement = MeasurableElement;
export {MeasurableElement};
