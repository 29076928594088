import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Operand } from './Operand'
import { RuleReference } from './RuleReference'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, Operand
, RuleReference){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Operand){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Operand){
			Operand = importPath.Operand;
		}
        else {
            import('./Operand').then(({ default: Operand }) => {
                Operand = Operand;
            });
			/*require(["appbo/vdml/Operand"],function(loadedModule){
				Operand = loadedModule;
			});*/
		}
	}
	if(!RuleReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.RuleReference){
			RuleReference = importPath.RuleReference;
		}
        else {
            import('./RuleReference').then(({ default: RuleReference }) => {
                RuleReference = RuleReference;
            });
			/*require(["appbo/vdml/RuleReference"],function(loadedModule){
				RuleReference = loadedModule;
			});*/
		}
	}
	export class ExpressionMixin {

	defaults(){
		var ret = {
			type: "vdml_Expression"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		// {
		// 	type :Backbone.HasMany,
		// 	containingClass:"vdml_Expression",
		// 	key:"operand",
		// 	relatedModel:"vdml.Operand",
		// 	reverseRelation: {
		// 		key:"operandOwner",
		// 		type :Backbone.HasOne,
		// 		includeInJSON:"id"
		// 	}
		// },
		// {
		// 	type :Backbone.HasOne,
		// 	containingClass:"vdml_Expression",
		// 	key:"ruleReference",
		// 	relatedModel:"vdml.RuleReference",
		// 	reverseRelation: {
		// 		key:"ruleReferenceOwner",
		// 		type :Backbone.HasOne,
		// 		includeInJSON:"id"
		// 	}
		// }
		])
	}
	
	static getCumulativeMixinRelations(){
		if (!ExpressionMixin.cummulativeRelations) {
            ExpressionMixin.cummulativeRelations = _.union(ExpressionMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return ExpressionMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_Expression" },
			{name : "type",type : "EString",defaultValue : "Fixed",containingClass : "vdml_Expression"},
			{name : "expressionStr",type : "EString",defaultValue : "null",containingClass : "vdml_Expression"},
			{name : "initialValue",type : "float",defaultValue : 0,containingClass : "vdml_Expression"},
			{name : "tableName",type : "EString",defaultValue : "Fixed",containingClass : "vdml_Expression"},
			{name : "expressionConfig",type : "EString",defaultValue : "Fixed",containingClass : "vdml_Expression"}
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("expressionOwner") ? this.get("expressionOwner") : this.previousAttributes().expressionOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("formulaOwner") ? this.get("formulaOwner") : this.previousAttributes().formulaOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("optimizationObjectiveOwner") ? this.get("optimizationObjectiveOwner") : this.previousAttributes().optimizationObjectiveOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("correlationExpressionOwner") ? this.get("correlationExpressionOwner") : this.previousAttributes().correlationExpressionOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("conditionOwner") ? this.get("conditionOwner") : this.previousAttributes().conditionOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		/*if(!container){
			container = this.get("conditionOwner") ? this.get("conditionOwner") : this.previousAttributes().conditionOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}*/
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ExpressionPropertiesTemplate.html",
			templateName : "ExpressionPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ExpressionViewModel",
			tabId : "ExpressionView",
			tabName: "Expression"
		}
	}
	
	copy(targetContainer){
		var expId = DataManager.getDataManager().guidGeneratorByOwner(targetContainer);
		var containerRole;
		var expCopy;
		if(this.get('portOwner')){
			expCopy = new this.constructor({id:expId,name:this.get('name'),description:this.get('name'),condition:targetContainer});
		}else{
			expCopy = new this.constructor({id:expId,name:this.get('name'),description:this.get('name'),correlationExpression:targetContainer});
		}
		return expCopy;
	}
	getCalculationChangeType(operationType){
		if(this.get("formulaOwner") != null){
			return 0;
		}else if(this.get("derivedExpressionOwner") != null){
			return 2;
		} else if(this.get("optimizationObjectiveOwner") != null){
			return 1;
		}
		return 0;
	}

	getCalculationChangeArtifact(operationType){
		let dataManager = DataManager.getDataManager();
		if(this.get("formulaOwner") != null){
			return dataManager.get("artifactsDocuments")[dataManager.get("currentPlan").id].artifactId;
		}else if(this.get("derivedExpressionOwner") != null){
			return this.get("derivedExpressionOwner").id;
		} else if(this.get("optimizationObjectiveOwner") != null){
			return this.get("optimizationObjectiveOwner").id;
		}
	}
	
	}
	path.ExpressionMixin = ExpressionMixin;
	//return ExpressionMixin;
//});