import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {MeasureRelationship} from './MeasureRelationship'
import {BaseMeasureRelationshipMixin} from './BaseMeasureRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
//import { RescaledMeasure } from './RescaledMeasure'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasureRelationship","appbo/smm/BaseMeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasureRelationship,BaseMeasureRelationshipMixin
, RescaledMeasure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	//if(!RescaledMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RescaledMeasure){
			RescaledMeasure = importPath.RescaledMeasure;
		}
        else {
            /*import('./RescaledMeasure').then(({ default: RescaledMeasure }) => {
                RescaledMeasure = RescaledMeasure;
            });*/

			/*require(["appbo/smm/RescaledMeasure"],function(loadedModule){
				RescaledMeasure = loadedModule;
			});*/
		}
	//}
	var BaseMeasureRelationship = MeasureRelationship.extend(utils.customExtends({
		relations:BaseMeasureRelationshipMixin.getMixinRelations(),
		subModelTypes: {
			smm_BaseNMeasureRelationship : 'smm.BaseNMeasureRelationship',
			smm_Base1MeasureRelationship : 'smm.Base1MeasureRelationship',
			smm_Base2MeasureRelationship : 'smm.Base2MeasureRelationship'
		},
		initialize: function(attributes, options) {
			MeasureRelationship.prototype.initialize.apply(this, arguments);
			if(attributes.influence){
				this.set('influence',path.Influence[attributes.influence instanceof Object ? attributes.influence.name : attributes.influence]);
			}
		}
		}
		, new BaseMeasureRelationshipMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	BaseMeasureRelationship.abstract = sp2Enabled; 
	BaseMeasureRelationship.supported = !sp2Enabled;
	path.BaseMeasureRelationship = BaseMeasureRelationship;
	export {BaseMeasureRelationship};
//});