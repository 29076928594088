import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmElement} from './SmmElement'
import {ObservationMixin} from './ObservationMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ObservedMeasure } from './ObservedMeasure'
import { SmmModel } from './SmmModel'
import { AbstractMeasureElement } from './AbstractMeasureElement'
import { ObservationScope } from './ObservationScope'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/ObservationMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,ObservationMixin
, ObservedMeasure
, SmmModel
, ObservationScope
, AbstractMeasureElement){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!ObservedMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.ObservedMeasure){
			ObservedMeasure = importPath.ObservedMeasure;
		}
        else {
            import('./ObservedMeasure').then(({ default: ObservedMeasure }) => {
                ObservedMeasure = ObservedMeasure;
            });
            /*require(["appbo/smm/ObservedMeasure"], function (loadedModule) {
                ObservedMeasure = loadedModule;
			});*/
		}
	}
	if(!SmmModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.SmmModel){
			SmmModel = importPath.SmmModel;
		}
        else {
            import('./SmmModel').then(({ default: SmmModel }) => {
                SmmModel = SmmModel;
            });
           /* require(["appbo/smm/SmmModel"], function (loadedModule) {
                SmmModel = loadedModule;
			});*/
		}
	}
	if(!ObservationScope){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.ObservationScope){
			ObservationScope = importPath.ObservationScope;
		}
        else {
            import('./ObservationScope').then(({ default: ObservationScope }) => {
                ObservationScope = ObservationScope;
            });
			/*require(["appbo/smm/ObservationScope"],function(loadedModule){
				ObservationScope = loadedModule;
			});*/
		}
	}
	if(!AbstractMeasureElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.AbstractMeasureElement){
			AbstractMeasureElement = importPath.AbstractMeasureElement;
		}
        else {
            import('./AbstractMeasureElement').then(({ default: AbstractMeasureElement }) => {
                AbstractMeasureElement = AbstractMeasureElement;
            });
            /*require(["appbo/smm/AbstractMeasureElement"], function (loadedModule) {
                AbstractMeasureElement = loadedModule;
			});*/
		}
	}
	var Observation = SmmElement.extend(utils.customExtends({
		relations:ObservationMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			SmmElement.prototype.initialize.apply(this, arguments);
			/*this.on("change:observationContext",function(model,relatedModel,options){
				if(Backbone.skipPersistance){
					return;
				}				
				if(!relatedModel){
					model.destroy();
				}
			});*/
            //this.on("change:name", this.onNameChanged);
		}
		}
		, new ObservationMixin()
	));
    Observation["change:observationContext"] = function (model, relatedModel, options) {
        if (Backbone.skipPersistance) {
            return;
        }
        if (!relatedModel) {
			var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
			if(!suppressChangeLogging) {
				model.destroy();
			}
        }
    };
    /*Observation["change:name"] = function (model, newName) {
		var previousName = model._previousAttributes ? model._previousAttributes.name:"";
		if(previousName != newName) {
			var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
			if(!suppressChangeLogging) {
				this.onNameChanged(model, newName);
			}
		}
    }*/
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	Observation.abstract = false;
	Observation.supported = !sp2Enabled
	path.Observation = Observation;
	export {Observation};
//});