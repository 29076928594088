import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmElement} from './SmmElement'
import {MeasureLibraryMixin} from './MeasureLibraryMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { SmmModel } from './SmmModel'
import { AbstractMeasureElement } from './AbstractMeasureElement'
import { CategoryRelationship } from './CategoryRelationship'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/MeasureLibraryMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,MeasureLibraryMixin
, SmmModel
, AbstractMeasureElement
, CategoryRelationship){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!SmmModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.SmmModel){
			SmmModel = importPath.SmmModel;
		}
        else {
            import('./SmmModel').then(({ default: SmmModel }) => {
                SmmModel = SmmModel;
            });
			/*require(["appbo/smm/SmmModel"],function(loadedModule){
				SmmModel = loadedModule;
			});*/
		}
	}
	if(!AbstractMeasureElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.AbstractMeasureElement){
			AbstractMeasureElement = importPath.AbstractMeasureElement;
		}
        else {
            import('./AbstractMeasureElement').then(({ default: AbstractMeasureElement }) => {
                AbstractMeasureElement = AbstractMeasureElement;
            });
           /* require(["appbo/smm/AbstractMeasureElement"], function (loadedModule) {
                AbstractMeasureElement = loadedModule;
			});*/
		}
	}
	if(!CategoryRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.CategoryRelationship){
			CategoryRelationship = importPath.CategoryRelationship;
		}
        else {
            import('./CategoryRelationship').then(({ default: CategoryRelationship }) => {
                CategoryRelationship = CategoryRelationship;
            });
            /*require(["appbo/smm/CategoryRelationship"], function (loadedModule) {
                CategoryRelationship = loadedModule;
			});*/
		}
	}
	var MeasureLibrary = SmmElement.extend(utils.customExtends({
		relations:MeasureLibraryMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			SmmElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new MeasureLibraryMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	MeasureLibrary.abstract = false;
	MeasureLibrary.supported = !sp2Enabled
	path.MeasureLibrary = MeasureLibrary;
	export {MeasureLibrary};
//});