import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../libs/bootbox/bootbox'
import { DataManager } from '../../../../com/vbee/data/DataManager'
import { EcoMapDiagramMixin } from '../../../bo/vdml/EcoMapDiagramMixin'

//define(["require", "jquery", "jstree", "jqueryui", "underscore", "backbone", "async", "knockout", "knockoutMapping", "knockback", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/EcoMapDiagramMixin"],
	//function (require, $, jstree, jqueryui, _, Backbone, async, ko, koMapping, kb, bootbox, DataManager, global, EcoMapDiagramMixin) {
		var path = DataManager.getDataManager().buildAppNsPath("valueLibrary.properties", global.version);
export class ValueExplorerJsonViewModel {
    constructor(model, options) {
        var self = this;
        this.init(model, options);
    }

    init(model, options) {
        var self = this;
        this.ValueExplorerJsonViewModel = this;
        this.model = model;
        this.jsTreeModelData = null;
        this.enableComplete = ko.observable(true);
        this.showComplete = ko.observable(true);
        this.showValueLibrary = ko.observable(false);
        this.editPhaseName = ko.observable(false);
        this.phasesColl = ko.observableArray([]);
        this.phaseName = ko.observable("");
        this.title = ko.observable("");
        this.disallowedRoleNames = [];
        this.networkPartnerRoleOriginalId = "";
        this.displayPhaseTypeahead = new Backbone.Collection();
        this.roleId = "";
        if (options && options.parentView) {
            this.encodeId = "" + options.modalId;
            this.callback = options.callback;
            this.valLibList = options.valueLibs.slice();
        }
        if (options && options.targetDetailsMode) {
            self.showComplete(false);
        }
        this.options = options;
        function htmlEscape(str) {
            return String(str)
                .replace(/@/g, '')
                .replace(/ /g, '');
        }
        if (self.valLibList) {
            for (var i = 0; i < self.valLibList.length; i++) {
                var valueLib = self.valLibList[i].models;
                if (valueLib.length > 0) {
                    self.showValueLibrary(true)
                }
            }
        
        }
        this.options = options;
        this.editPhase = _.bind(self.editPhase, self);
		this.deletePhase = _.bind(self.deletePhase, self);
		this.addPhases = _.bind(self.addPhases, self);
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'Complete'
            , 'Close', 'ValueDefinition', 'ValueDefinition(s)'
            , 'Phase(s)/Value Definition(s)']);
        this.title(self.labels['Phase(s)/Value Definition(s)']());
    }

    saveEditPhase() {
        var self = this;
        if ($('#phaseMessage' + self.encodeId).text().length == 0 && $('#editPhaseName' + self.encodeId).val().trim() != '') {
            var roleName = $('#editPhaseName' + self.encodeId).val().trim();
            //var roleDescription = $('#editPhaseName' + self.encodeId).val().trim();
            $('#phaseListName' + self.roleId).text(roleName);
            var match = _.filter(self.phasesColl(), function (obj) { return obj.id === self.roleId; });
            match[0].name = roleName;
            //match[0].desc = roleDescription;
            $('#editPhaseName' + self.encodeId).val("");
            $('#phaseName' + self.encodeId).val("");
            self.editPhaseName(false);
            self.roleId = "";
            $('#showAddPhases' + self.encodeId).css('display', 'none')
            if (self.phasesColl().length > 0) {
                self.enableComplete(true);
            }
        }
    }

    editPhase(view, event) {
        var self = this;
        $('#showAddPhases' + self.encodeId).css('display', 'block')
        self.roleId = view.id;
        self.editPhaseName(true);
        $('#phaseMessage' + self.encodeId).text("");
        $('#editPhaseName' + self.encodeId).val(view.name);
        $('#phaseName' + self.encodeId).val(view.name);
        $('#editPhaseName' + self.encodeId).focus();
        $('#phaseName' + self.encodeId).closest('.row').find('.match').hide();
    }

    addPhase() {
        var self = this;
        if ($('#phaseMessage' + self.encodeId).text().length == 0) {
            var roleName = $('#phaseName' + self.encodeId).val();
            if (roleName !== null && roleName !== "") {
                var id = window.utils.htmlEscape(window.guidGenerator());
                self.phasesColl.push({ id: id, name: roleName});
                $('#phaseName' + self.encodeId).val("");
                //self.phasesColl.sort(self.sortRolesByName);
            }
            $('#showAddPhases' + self.encodeId).css('display', 'none');
            self.enableComplete(true);
        }
    }
    sortRolesByName(left, right) {
        return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
    }

    afterRenderView(event, view) {
        var self = view;
        if ($('#wizardModal').length > 0) {
            $('#wizardModal').addClass('push-back');
            $('#modal' + self.encodeId).css('z-index', '1070')
        }
        self.selectedValuesList = [];
        if (self.options.showPhases) {
            $('#showPhaseSelect').hide()
            self.title(self.labels['ValueDefinition(s)']());
        } else {
            $('#showPhaseSelect').show();
            fillPhases();
            var ExtendedPhaseName = Backbone.Typeahead.extend({
                template: '<input type="text" value="' + self.phaseName() + '" style="width:170px" id="phaseName' + self.encodeId + '" placeholder="Phase Name" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            var phaseNametypeahead = new ExtendedPhaseName({ collection: self.displayPhaseTypeahead });
            phaseNametypeahead.setElement('#editPhaseNameDiv' + self.encodeId).render();
    
            $("#phaseName" + self.encodeId).change(function (view) {
                var newValue = view.currentTarget.value;
                self.phaseName(newValue);
                if (newValue !== '' && self.typeaheadPhaseName != newValue) {
                    self.networkPartnerRoleOriginalId = "";
                }
            });
            $("#editPhaseName" + self.encodeId).keyup(function (view) {
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                var newValue = view.currentTarget.value;
                if (view.currentTarget.value.length > 0) {
                    if (!self.checkPhaseExist(view.currentTarget.value.trim(), true)) {
                        $('#phaseMessage' + self.encodeId).text("" + DataManager.getDataManager().get('localeManager').get('PhaseDuplicate'));
                        self.enableComplete(false);
                        $('#showAddPhases' + self.encodeId).css('display', 'block')
                    }
                    else {
                        $('#phaseMessage' + self.encodeId).text("");
                        if (view.currentTarget.value.length > 0) {
                            self.enableComplete(true);
                        }
                    }
                }
                else {
                    self.matchField.hide();
                }
            });
            $("#phaseName" + self.encodeId).keyup(function (view) {
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                if (view.currentTarget.value.length > 0) {
                    self.enableComplete(true);
                }
                if (view.currentTarget.value.length > 0) {
                    if (!self.checkPhaseExist(view.currentTarget.value.trim(), false)) {
                        $('#phaseMessage' + self.encodeId).text("" + DataManager.getDataManager().get('localeManager').get('PhaseDuplicate'));
                        $('#showAddPhases' + self.encodeId).css('display', 'block')
                        self.enableComplete(false);
                    }
                    else {
                        $('#phaseMessage' + self.encodeId).text("");
                    }
                }
                else {
                    self.matchField.hide();
                    if (window.utils.getObservableLength(self.phasesColl()) == 0) {
                        self.enableComplete(false);
                    }
                }
            });
            $('#phaseName' + self.encodeId).dblclick(function (view) {
                $('#phaseName' + self.encodeId).trigger('keyup');
                $('#phaseName' + self.encodeId).focus();
            });
        }
        


        $('#editDetailsModalContent' + self.encodeId).resize(function () {
            $('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
        })
        function fillPhases() {
            var currentPlan = DataManager.getDataManager().get('currentPlan');
            var phases = currentPlan.get('phase');
            var strategyMapObjectiveViewModel = window.vdmModelView.strategyMapObjectiveViewModel;
            if (strategyMapObjectiveViewModel) {
                var codeConId = strategyMapObjectiveViewModel.commonCodeContainer;
                var codeContainer = Backbone.Relational.store.getObjectByName('beeppackage.CodeContainer').find({id: codeConId });
                var phaseMapIdList = [];debugger
                if (codeContainer && codeContainer.get('code')) {
                    var json = JSON.parse(codeContainer.get('code'));
                    for(var phaseId in json){
                        //csvRow['period'] = json[phaseId].period;
                        var phaseMapName = json[phaseId].phaseMapName;
                        var phaseMapId = json[phaseId].phaseMapId;
                        phaseMapIdList.push(phaseMapId);
                        self.phasesColl.push({ name: phaseMapName, id: window.utils.htmlEscape(phaseId), mapId: phaseMapId});
                    }
                }//else {
                    for (var i = 0; i < phases.length; i++) {
                        if(phaseMapIdList.indexOf(phases.at(i).id ) == -1){
                            var id = window.utils.htmlEscape(window.guidGenerator());
                            self.phasesColl.push({ id: id, name: phases.at(i).get('name'), mapId: phases.at(i).id });
                        }
                        /*var isExist = false;
                        for (var j = 0; j < options.parentView.selectedPhases.length; j++) {
                            if (phases.at(i).id === options.parentView.selectedPhases[j].mapId) {
                                isExist = true;
                                break;
                            }
                        }
                        if (isExist) {
                            var htmlContent = "<input type=checkbox id=" + htmlEscape(window.guidGenerator()) + " phaseId= " + phases.at(i).id + " value=" + phases.at(i).get('name') + " checked disabled/> " + phases.at(i).get('name') + "<br/>"
                            $('#phasesCheck').append(htmlContent);
                        } else {
                            var htmlContent = "<input type=checkbox id=" + htmlEscape(window.guidGenerator()) + " phaseId= " + phases.at(i).id + " value=" + phases.at(i).get('name') + " > " + phases.at(i).get('name') + "<br/>"
                            $('#phasesCheck').append(htmlContent);
                        }*/
                    }
                //}
                /*for (var i = 0; i < strategyMapObjectiveViewModel.selectedPhases.length; i++) {
                    var id = window.utils.htmlEscape(window.guidGenerator());
                    var isExist = false;
                    for (var j = 0; j < phases.length; j++) {
                        if (phases.at(j).id === strategyMapObjectiveViewModel.selectedPhases[i].mapId) {
                            isExist = true;
                            break;
                        }
                    }
                    if (!isExist) {
                        var mapId = strategyMapObjectiveViewModel.selectedPhases[i].mapId;
                        if (mapId) {
                            self.phasesColl.push({ name: strategyMapObjectiveViewModel.selectedPhases[i].name, id: window.utils.htmlEscape(strategyMapObjectiveViewModel.selectedPhases[i].id), phaseId: strategyMapObjectiveViewModel.selectedPhases[i].mapId });
                        } else {
                            self.phasesColl.push({ name: strategyMapObjectiveViewModel.selectedPhases[i].name, id: window.utils.htmlEscape(strategyMapObjectiveViewModel.selectedPhases[i].id) });
                        }
                    }
                }*/
            }
        }
        attachMouseDown();
        function attachMouseDown() {
            var eleID = ('modal' + self.encodeId);
            $('#' + eleID).mouseup(function (event) {
                if ($('#phaseDiv' + self.encodeId).has($(event.target)).length > 0) {
                    $('#showAddPhases' + self.encodeId).css('display', 'block')
                } else {
                    if ($("#phaseName" + self.encodeId).val() && $("#phaseName" + self.encodeId).val().trim() !== "") {
                        if (self.editPhaseName() && self.roleId !== "") {
                            self.saveEditPhase();
                        }
                        else if ($("#phaseName" + self.encodeId).val() !== "") {
                            self.addPhase();
                        }
                    }

                }
            });
        }
        self.jsTreeModelData = self.getModelDataInJSTreeFormat(self.valLibList);
        //console.log(JSON.stringify(options.addOptions.model.toJSON()));
        var pluginList = ["search"];
        var arr = [];
        for (var i = 0; i < self.jsTreeModelData.length; i++) {
            arr.push(self.jsTreeModelData[i]);
        }
        var valueCheck = self.options.hideCheck ? false : true;
        var tree = $('#jstree_value_lib_div').on("select_node.jstree", function (e, data) {
            if (data.selected.length) {
                var htmlContent = "<span id='showValMapped' title="+DataManager.getDataManager().get('localeManager').get('ValueDetails')+"class='glyphicon glyphicon-info-sign glyphicon-button' style='font-size: small;color:orange;margin-left:7px'></span>"
                var selectedNode = data.instance.get_node(data.selected[0]);
                var parentsLength = selectedNode.parents.length;
                if (parentsLength > 1 && selectedNode && (selectedNode.original.type === "vdml_ValueDefinition" || selectedNode.original.type === "vdml_ValueCategory")) {
                    //if (parentsLength > 2) {
                        if ((self.options.type && self.options.type === selectedNode.original.type || self.options.type === 'vdml_Value') || (!self.options.type)) {
                            var exists = _.filter(self.options.parentCaps, function (parentCap) { if (parentCap.id === selectedNode.original.id) { return parentCap } });
                            var mapExists = _.filter(self.options.mappedCapabilities, function (parentCap) { if (parentCap === selectedNode.original.id) { return parentCap } });
                            if (exists.length > 0 || mapExists.length > 0) {
                                self.enableComplete(false);
                                bootbox.alert(selectedNode.text + " is not allowed for mapping");
                            } else {
                                var exists = _.filter(self.options.childCaps, function (childCap) { if (childCap.id === selectedNode.original.id) { return childCap } });
                                if (exists.length > 0) {
                                    self.enableComplete(false);
                                    bootbox.alert(selectedNode.text + " is not allowed for mapping");
                                } else {
                                    self.enableComplete(true);
                                    self.selectedValId = selectedNode.id;
                                    
                                    self.selectedValName = selectedNode.text;
                                    //self.valParentId = selectedNode.parents[parentsLength - 3];
                                    self.valDescription = selectedNode.original.description;
                                    if (!$('#showValMapped').length) {
                                        if ($('[id^=\'' + self.selectedValId + "_" + '\']').length == 1) {
                                            $('[id^=\'' + self.selectedValId + "_" + '\']').append(htmlContent);
                                        }
                                    } else {
                                        $('#showValMapped').remove();
                                        if ($('[id^=\'' + self.selectedValId + "_" + '\']').length == 1) {
                                            $('[id^=\'' + self.selectedValId + "_" + '\']').append(htmlContent);
                                        }
                                    }
                                    $('#showValMapped').click(function () { 
                                        var currentAltId = DataManager.getDataManager().get('viewAlternative');
                                        var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
                                        var currentAlt = docTypeObj.find({ id: currentAltId });
                                        var valModel = window.utils.getElementModel(self.selectedValId,['vdml.ValueCategory','vdml.ValueDefinition']);
                                        if(!valModel){
                                            bootbox.alert("No such Element");
                                            return;
                                        }
                                        var scenario = currentAlt.getMainScenario(valModel.getNestedParent());
                                        var id = window.utils.htmlEscape(window.guidGenerator());
                                        var options = {};
                                        options.context = scenario;
                                        options.mappedModal = valModel;
                                        options.width = '800px';
                                        window.getAndCreateModalDialog(self.model, id, EcoMapDiagramMixin, self.model, "TargetDetails", function (refresh) {
                                        }, options);
                                    })
                                }
                            }
                        } else {
                            self.enableComplete(false);
                            bootbox.alert(selectedNode.text + " is not allowed for mapping");
                        }
                    /*} else {
                        self.enableComplete(false);
                    }*/
                }
                else {
                    self.enableComplete(false);
                }
            }
        }).jstree({
            'core': {
                'check_callback': true,
                'two_state': valueCheck,
                'data': arr
            },
            "plugins": valueCheck ? ['search', 'checkbox'] : ['search'],
            "search": {
                'case_sensitive': false
            }
        });

        //$('#jstree_value_lib_div').jstree(true).create_node(null, self.jsTreeModelData[0]);
        $('#treeSearch').change(function () {
            window.utils.startSpinner('jsonSearch', 'Searching...');
            setTimeout(function () {
                var v = $('#treeSearch').val();
                $('#jstree_value_lib_div').jstree(true).search(v);
                window.utils.applyFontSize($('#content'));
                window.utils.stopSpinner('jsonSearch');
                $('.jstree-anchor').css('text-overflow', 'ellipsis'); //mrajender //preventing strings going out of band
                $('.jstree-anchor').css('overflow', 'hidden');
                $('.jstree-anchor').css('max-width', '97%');
            }, 10);
        });
        $('#jstree_value_lib_div').on('dblclick.jstree', function (e) {
            var text = e.target.text;
            if (text && text.length > 0) {
                var textSub = text.substr(text.indexOf(":") + 2);
                navigator.clipboard.writeText(textSub);
            }
        });
        $('#jstree_value_lib_div').on('loaded.jstree', function (e) {
            if(self.model && self.model.id){
                $("#jstree_value_lib_div").jstree('open_node', self.jsTreeModelData);
            }
        });
        $('#jstree_value_lib_div').bind('ready.jstree', function(e, data) {
            var strategyMapObjectiveViewModel = window.vdmModelView.strategyMapObjectiveViewModel;
            var selectedValueLibList = strategyMapObjectiveViewModel?strategyMapObjectiveViewModel.selectedValueLibList:null;
            if ((self.options.showValueElementId) || selectedValueLibList && selectedValueLibList.length > 0) {
                //for (var i = 0; i < options.parentView.selectedValueLibList.length; i++) {
                if (self.options.showValueElementId) {
                    $('#jstree_value_lib_div').jstree('select_node', self.options.showValueElementId);
                } else {
                    $("#jstree_value_lib_div").jstree("disable_node", selectedValueLibList);
                    $('#jstree_value_lib_div').jstree('select_node', selectedValueLibList);
                }
                self.enableComplete(false)
                // }
            }
        });
        $('#jstree_value_lib_div').on('hover_node.jstree', function (e, data) {
            var toolTip;
            if (data.node.original.type && data.node.original.type == "vdml_ValueLibrary") {
                toolTip = DataManager.getDataManager().get('localeManager').get('ValueLibrary');
            }
            if (data.node.original.type && data.node.original.type == "vdml_ValueDefinition") {
                toolTip = DataManager.getDataManager().get('localeManager').get('ValueDefinition');
            } else if (data.node.original.type && data.node.original.type == "vdml_ValueCategory") {
                toolTip = DataManager.getDataManager().get('localeManager').get('ValueCategory');
            }
            data.instance.element.prop('title', toolTip);
            if (!data.node.original.type) {
                data.instance.element.removeAttr('title', toolTip)
            }
        });



    };
    checkPhaseExist(phaseName, existing) {
        var self = this;
        var count = 0;
        for (var i = 0; i < self.phasesColl().length; i++) {
            if (!self.phasesColl()[i]._destroy && self.phasesColl()[i].name === phaseName && self.phasesColl()[i].id !== self.roleId) {
                count++;
            }
        }
        if (self.model && existing && self.disallowedRoleNames.includes(phaseName)) {
            return false;
        }
        if (count > 0) {
            return false;
        }
        if (!self.model) {
            self.matchField.show();
            self.matchField[0].innerHTML = 'New';
        }
        return true;
    }
    getModelDataInJSTreeFormat(valueLibs) {
        var self = this;
        var ret = [];
        if (!valueLibs.length && valueLibs instanceof Backbone.Model) {
            jsonData = {};
            ret.push(jsonData);
            jsonData.id = valueLibs.get('id');
            jsonData.text = valueLibs.get('name');
            jsonData.description = valueLibs.get('description');
            jsonData.type = valueLibs.get('type');
            jsonData.definition = valueLibs.get('definition');
            if (valueLibs.get('unit')) {
                jsonData.Unit = valueLibs.get('unit');
            }
            var typeMixinCls = DataManager.getDataManager().getModelType(valueLibs, true);
            jsonData.children = [];
            if (valueLibs instanceof Backbone.Model && valueLibs.get('type') !== "vdml_ValueLibrary" && valueLibs.get('childValue').length > 0) {
                jsonData.children = jsonData.children.concat(this.getModelDataInJSTreeFormat(valueLibs.get('childValue').models));
                ret[0].icon = "img/object.png";
            } else if (valueLibs instanceof Backbone.Model && valueLibs.get('type') === "vdml_ValueLibrary") {
                jsonData.children = jsonData.children.concat(this.getModelDataInJSTreeFormat(valueLibs.get('value').models));
                ret[0].icon = "img/object.png";
            }
            else {
                ret[0].icon = "img/leaf.png";
            }
            //}
        } else {
            for (var i = 0; i < valueLibs.length; i++) {
                var jsonData = {};
                var valLibModels = valueLibs[i].models ? valueLibs[i].models : valueLibs[i];
                if ((!valLibModels.length && valLibModels instanceof Backbone.Model) || (valLibModels.length && valLibModels.length > 0)) {
                    ret.push(jsonData);
                }
                for (var j = 0; j < valLibModels.length; j++) {
                    var valueLib = valLibModels[j];
                    if (j > 0 && valueLib.get('type') == "vdml_ValueLibrary") {
                        var jsonData = {};
                        ret.push(jsonData);
                        ret[j].icon = "img/object.png";
                    }
                    jsonData.id = valueLib.get('id');
                    jsonData.text = valueLib.get('name');
                    jsonData.description = valueLib.get('description');
                    jsonData.type = valueLib.get('type');
                    jsonData.definition = valueLib.get('definition');
                    if (valueLib.get('unit')) {
                        jsonData.Unit = valueLib.get('unit');
                    }
                    var typeMixinCls = DataManager.getDataManager().getModelType(valueLib, true);
                    if (valueLib.get('type') !== "vdml_ValueLibrary") {
                        jsonData.children = [];
                        jsonData.children = jsonData.children.concat(this.addRelationsShipsForAppCls(valueLib, typeMixinCls));
                        if (!jsonData.children.text) {
                            jsonData.children = jsonData.children[0].children;
                        }
                    } else {
                        jsonData.children = [];
                        var valList = valueLib.get('value').models;
                        var newValList = [];
                        for (var k = 0; k < valList.length; k++) {
                            var addtolist = false;
                            _.each(valList[k].get('parentValue').models, function (parentVal) {
                                if (parentVal.getParent() != valueLib) {
                                    addtolist = true;
                                }
                            });
                            if (valList[k].get('parentValue').length == 0 || addtolist) {
                                newValList.push(valList[k]);
                            }
                        }
                        jsonData.children = this.getModelDataInJSTreeFormat(newValList);
                        ret[j] ? ret[j].icon = "img/object.png" : '';
                    }
                    ret[i] ? ret[i].icon = "img/object.png" : '';
                }
                if (!valLibModels.length && valLibModels instanceof Backbone.Model) {
                    jsonData.id = valLibModels.get('id');
                    jsonData.text = valLibModels.get('name');
                    jsonData.description = valLibModels.get('description');
                    jsonData.type = valLibModels.get('type');
                    jsonData.definition = valLibModels.get('definition');
                    if (valLibModels.get('unit')) {
                        jsonData.Unit = valLibModels.get('unit');
                    }
                    var typeMixinCls = DataManager.getDataManager().getModelType(valLibModels, true);
                    if (valLibModels instanceof Backbone.Model && valLibModels.get('childValue').length > 0) {
                        jsonData.children = this.getModelDataInJSTreeFormat(valLibModels.get('childValue').models);
                        ret[i].icon = "img/object.png";
                    }
                    else {
                        ret[i].icon = "img/leaf.png";
                    }
                }
            }

        }
        ret.sort(self.sortByName);
        return ret;
    };
    sortByName(left, right) {
        var self = this;
        if (left.text && right.text) {
            return left.text.toLowerCase() === right.text.toLowerCase() ? 0 : (left.text.toLowerCase() < right.text.toLowerCase() ? -1 : 1);
        }
    }

    addPhases() {
        var self =this;
        $('#showAddPhases' + self.encodeId).css('display', 'block')
        $('#phaseName' + self.encodeId).focus();
        $('#phaseName' + self.encodeId).closest('.row').find('.match').hide();
    }

    /*this.addPhase = function () {
        var text = $('#addPhaseName').val()
        if (text && text.trim()) {
            $('#addPhaseDes').val(text);
            var htmlContent = "<input type=checkbox id= " + htmlEscape(window.guidGenerator()) + " value=" + text + "> " + text + ""
            $('#phasesCheck').append(htmlContent);
            self.showAddPhases(false);

            $('input[type="checkbox"]').change(function () {
                if (this.checked) {
                    self.enableComplete(true);
                    self.selectedPhases = [];
                    $('#phasesCheck input:checked').each(function () {
                        if (!this.disabled) {
                            self.selectedPhases.push({ id: this.id, name: this.value, mapId: this.getAttribute('phaseId') });
                        }
                    })
                } else {
                    self.enableComplete(false)
                }
            });
        }
    }*/

    addRelationsShipsForAppCls(model, typeMixinCls) {
        var ret = [];
        var value = model;
        addRelationShip(value);

        function addRelationShip(relationValue) {
            if (relationValue instanceof Backbone.Model) {
                ret.push(self.getModelDataInJSTreeFormat(relationValue)[0]);
            } else {
                var relationValues = relationValue;
                var jsonData = {};
                ret.push(jsonData);
                if (relationValues && relationValues.length > 0) {
                    jsonData.children = [];
                }
                var valueCount = 0;
                function handleNextRelationshipValue(children) {
                    if (relationValues && valueCount < relationValues.length) {
                        var newRelationValue = relationValues.at(valueCount);
                        valueCount++;
                        //var children = jsonData.children ? jsonData.children : jsonData;
                        addRelationshipValue(newRelationValue, children);
                    }
                }
                function addRelationshipValue(relationValue, children) {
                    if (relationValue && relationValue.get('parentValue') && relationValue.get('parentValue').length == 0) {
                        if (typeof children.length == "undefined") {
                            children.children = [];
                            children.children.push(self.getModelDataInJSTreeFormat(relationValue)[0]);
                        }
                        else {
                            children.push(self.getModelDataInJSTreeFormat(relationValue)[0]);
                        }
                        handleNextRelationshipValue();
                    } else {
                        children.push(self.getModelDataInJSTreeFormat(relationValue));
                        handleNextRelationshipValue();
                    }
                }
                handleNextRelationshipValue(jsonData.children);
            }
        }
        return ret;
    };

    deletePhase(view, event) {
        var self = this;
        var id = view.id;
        self.enableComplete(true);
        $('#' + id).remove();
        var strategyMapObjectiveViewModel = window.vdmModelView.strategyMapObjectiveViewModel;
        strategyMapObjectiveViewModel.deleteFromJSON(id);
        for (var i = self.phasesColl().length - 1; i >= 0; i--) {
            if (id === self.phasesColl()[i].id) {
                self.phasesColl.splice(i, 1);
                break;
            }
        }
    }

    cleanModal() {
        var self = this;
        window.cleanDialogModel(self.encodeId, self);
        if ($('#wizardModal').length > 0) {
            $('#wizardModal').removeClass('push-back');
        }
        if ($('.slipDetailsBox').length == 0 || self.options.infoIcon) {
            $('.slipDetailsBox').removeClass('push-back');
        }
        if (event.currentTarget.textContent == 'Close' && $('.slipDetailsBox').length > 0) {
            $('.slipDetailsBox').removeClass('push-back');
        }
    };
    showValues() {
        var self = this;
        var htmlContent = "<div class = row style='font-size:14px'><div class=col-xs-1></div>";
        htmlContent += "<div class = col-xs-3><span class = control-label>";
        htmlContent += "<label>Name</label></span></div>"
        htmlContent += "<div class=col-xs-5><input id = valueName class ='form-control focus-ele' style=border-radius:5px;height:30px;font-size:15px;width:150%>";
        htmlContent += "</div></div><br>";
        htmlContent += "<div class = row style='font-size:14px'><div class=col-xs-1></div>";
        htmlContent += "<div class = col-xs-3><span class = control-label>";
        htmlContent += "<label>Description</label></span></div>"
        htmlContent += "<div class=col-xs-5><input id = valueDesc class ='form-control focus-ele' style=border-radius:5px;height:30px;font-size:15px;width:150%>";
        htmlContent += "</div></div><br>";
        htmlContent += "<div class = row style='font-size :14px'><div class=col-xs-1></div>";
        htmlContent += "<div class =col-xs-3><span class= control-label>";
        htmlContent += "<label>"+DataManager.getDataManager().get('localeManager').get('Unit')+"</label></span></div>";
        htmlContent += "<div class=col-xs-5><span><input type='text' class ='form-control focus-ele' id='valueUnit' style=border-radius:5px;font-size:14px;width:150%></span>";
        htmlContent += "</div></div><br>";
        htmlContent += "<div id =showValueElement class = row style='font-size :14px'><div class=col-xs-1></div>";
        htmlContent += "<div class =col-xs-3><span class= control-label>";
        htmlContent += "<label>Value Definition</label></span></div>";
        htmlContent += "<div class=col-xs-5><a href=javascript:void(0) id=showValMappedInfo><span id=valueElementName></span></a><span class='glyphicon glyphicon-plus' style='padding-left: 10px' id='showValueElements'></span>";
        htmlContent += "</div></div><br>";

        var dialog = bootbox.dialog({
            title: "<div>&nbsp;&nbsp;&nbsp;Objective</div>",
            message: htmlContent,
            show: true,
            buttons: {
                main: {
                    label: "Cancel",
                    className: "btn btn-default",
                    callback: function () {
                        self.showValueElementId = null;
                    }
                },
                success: {
                    label: '<i class="fa fa-check"></i> Complete',
                    className: "btn btn-complete showComplete disabled",
                    callback: function () {
                        if ($('#valueName').val()) {
                            var data = $('#valueName').val();
                            var randomId = "average_" + window.utils.htmlEscape(window.guidGenerator())

                            var table = $('#myTable' + self.encodeId)
                            if (!$('#objBody') || ($('#objBody') && $('#objBody').length === 0)) {
                                var tableBody = document.createElement('TBODY');
                                tableBody.setAttribute('id', "objBody")
                                table.append(tableBody);
                            }

                            var codeId = self.commonCodeContainer;
                            var json = {};
                            var header = table.find('th'), keyContent = "fileContent";
                            CodeContainer.getBlobInstanceWithId(codeId, DataManager.getDataManager().get('currentPlan'), function (codeContainer) {
                                if (codeContainer) {
                                    if (codeContainer.get('code')) {
                                        json = JSON.parse(codeContainer.get('code'));
                                    }

                                    var valName = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.showValueElementId })
                                    self.selectedValueLibList.push(valName ? valName.id : null);
                                    var tr = self.createRow(randomId, $('#valueName').val(), (valName ? valName.get('name') : ""));
                                    for (var i = 2; i < header.length; i++) {
                                        self.objectiveColumnCreate(randomId + header[i].id, tr, header[i].id);
                                        if (json[header[i].id]) {
                                            if (json[header[i].id][keyContent]) {
                                                json[header[i].id][keyContent][randomId] = {};
                                            } else {
                                                json[header[i].id][keyContent] = {};
                                            }
                                            json[header[i].id][keyContent][randomId] = {};
                                            json[header[i].id][keyContent][randomId].ValueName = data;
                                            json[header[i].id][keyContent][randomId].id = randomId;
                                            json[header[i].id][keyContent][randomId].unit = $('#valueUnit').val();
                                            json[header[i].id][keyContent][randomId].valueElementId = self.showValueElementId;
                                            //json[header[i].id][keyContent][randomId].valueDefinitionName = valName ? valName.get('name') : ""
                                        }
                                    }
                                    codeContainer.set('code', JSON.stringify(json));

                                }
                            })
                        }
                    }
                }
            }
        })
        dialog.attr("id", "bootbox_id");
        $('#bootbox_id').draggable();

        $('#showValueElements').on('click', function () {
            var randId = window.utils.htmlEscape(window.guidGenerator());
            var currentPlan = DataManager.getDataManager().get('currentPlan');
            currentPlan.loadValueLibraries(function (valueLibraries) {
                var options = {
                    valueLibs: valueLibraries, callback: function (values, phases) {
                        if (values) {
                            var text = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: values[0] }) ? Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: values[0] }).get('name') : "";
                            $('#showValueElement').show();
                            self.showValueElementId = values[0];
                            $('#valueElementName').text(text);
                            $('#showValueElements').hide();
                            if (!$('#valueName').val()) {
                                $('#valueName').val(text);
                                $('#valueDesc').val(text);
                                $('.showComplete').removeClass('disabled');
                            }
                        }
                    }
                };
                options.selectedList = self.selectedValueLibList;
                self.options.showPhases = true;
                window.getAndCreateModalDialog(self, randId, ValueDeliveryModelMixin, self.model, "ValueExplorerJson", null, options);
            });
        })

        $('#valueName').keyup(function (eve) {
            var val = eve.currentTarget.value;
            if (val && val.trim()) {
                $('.showComplete').removeClass('disabled');
            } else {
                $('.showComplete').addClass('disabled');
            }
        })
    }
    mapValue() {
        var self = this;
        /*self.selectedPhases = [];
        for (var i = 0; i < self.phasesColl().length; i++) {
            var isExist = false;
            var strategyMapObjectiveViewModel = window.vdmModelView.strategyMapObjectiveViewModel;
            if(strategyMapObjectiveViewModel && strategyMapObjectiveViewModel.selectedPhases){
                for (var j = 0; j < strategyMapObjectiveViewModel.selectedPhases.length; j++) {
                    if (strategyMapObjectiveViewModel.selectedPhases[j].mapId === self.phasesColl()[i].phaseId) {
                        isExist = true;
                        break;
                    }
                }
            }
            if (!isExist) {
                self.selectedPhases.push({ id: self.phasesColl()[i].id, name: self.phasesColl()[i].name, mapId: self.phasesColl()[i].phaseId })
            }
        }*/
        self.selectedValuesList = [];
        var dataSelected = $('#jstree_value_lib_div').jstree(true).get_selected();
        for (var i = 0; i < dataSelected.length; i++) {
            var nodeInfo = $('#jstree_value_lib_div').jstree(true).get_node(dataSelected[i]);
            if (nodeInfo && !nodeInfo.state.disabled) {
                self.selectedValuesList.push(dataSelected[i])
            }
        }
        /*var newPhaseList = [];
        for (var i = 0; i < self.phasesColl().length; i++) {
            if(!self.phasesColl().at(i).mapId){
                newPhaseList.push({ id: id, name: self.phasesColl().at(i).get('name')});
            }
        }*/
        self.callback(self.selectedValuesList, self.phasesColl());
        self.cleanModal();

    };

    static getInstance(model, options) {
        var view = new ValueExplorerJsonViewModel(model, options);
        return view;
    };
}
path.ValueExplorerJsonViewModel = ValueExplorerJsonViewModel;