import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelDimension} from './BusinessModelDimension'
import {BusinessModelDimensionMixin} from './BusinessModelDimensionMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelDimension","appbo/vdml/BusinessModelDimensionMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelDimension,BusinessModelDimensionMixin){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class CompetenceDimensionMixin {
	defaults(){
		var ret = {
			type: "vdml_CompetenceDimension"
		}
		return jQuery.extend(BusinessModelDimension.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!CompetenceDimensionMixin.cummulativeRelations) {
            CompetenceDimensionMixin.cummulativeRelations = _.union(CompetenceDimensionMixin.getMixinRelations()
                , BusinessModelDimensionMixin.getCumulativeMixinRelations()
            );
        }
		return CompetenceDimensionMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_BusinessModelDimension"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("competenceDimensionOwner") ? this.get("competenceDimensionOwner") : this.previousAttributes().competenceDimensionOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/CompetenceDimensionPropertiesTemplate.html",
			templateName : "CompetenceDimensionPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/CompetenceDimensionViewModel",
			tabId : "CompetenceDimensionView",
			tabName: "CompetenceDimension"
		}
	}
	
}
path.CompetenceDimensionMixin = CompetenceDimensionMixin;