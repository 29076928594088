import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global"],
//function(require,$,_, Backbone,ko, koMapping,kb,bootbox,DataManager,global){
	var path = DataManager.getDataManager().buildAppNsPath("beeppackage.views.properties",global.version);
	export class AdoptViewModel {
    constructor(model,options){
		var self = this;
		this.AdoptViewModel = this;
		this.model = model;
		this.name = ko.observable("");
		this.description = ko.observable("");
		this.localManager = DataManager.getDataManager().get('localeManager');
		this.analyticsTitle = self.localManager.get("Dashboard");
		this.reportTitle = self.localManager.get("Report");
		if(options && options.parentView){
			this.parentView = options.parentView;
			this.encodeId = ""+options.modalId;
			this.alternativeModel = options.addOptions.alternative;
			this.plusIconType = options.addOptions.plusIconType;
		}
		this.enableComplete = ko.observable(false);
		this.analyticsPackage = ko.observable(false);
		var allowedTypes = [];
		
		this.types = ko.observableArray();
		self.types.push(self.analyticsTitle);
		self.types.push(self.reportTitle);
		var targetLetter = '';
		var selectedArrow = $('#AdoptHeading').find('.arrow_active')[0];
		if(selectedArrow && selectedArrow.id){
			targetLetter = selectedArrow.id.charAt(0);
			if(selectedArrow.id == "dashboardArrow"){
				targetLetter = "d";
			}
			if(selectedArrow.id == "reportArrow"){
				targetLetter = "r";
			}
		}
		self.types().sort((a, b) => {
			const aStartsWithLetter = a.toLowerCase().startsWith(targetLetter.toLowerCase());
			const bStartsWithLetter = b.toLowerCase().startsWith(targetLetter.toLowerCase());
			if (aStartsWithLetter && !bStartsWithLetter) {
				return -1;
			} else if (!aStartsWithLetter && bStartsWithLetter) {
				return 1;
			} else {
				return a.localeCompare(b);
			}
		});
		if (DataManager.getDataManager().isAdoptPurchased()) {
		    allowedTypes.push(self.analyticsTitle);
			allowedTypes.push(self.reportTitle);
		} 

		this.selectedType = ko.observable();
		
		this.analyticsPackages = ko.observableArray();
		this.reportNames = [];
		this.dashboardNames = [];
		this.selectedAnalyticsPackage = ko.observable();
		
		
		$('#modal'+self.encodeId).on('shown.bs.modal', function () {
	          window.utils.focusElement("#newModal .focus-ele");
	    });
	    
        this.selectedType.subscribe(function (val) {
            if ( val === self.reportTitle) {
                self.analyticsPackage(false);
            }
           
            else if (val === self.analyticsTitle) {
                self.analyticsPackage(true);
            }
	    	
	    	checkUniqueName(self.name());
	    });
	    
	    this.selectedAnalyticsPackage.subscribe(function (val) {
	        checkUniqueName(self.name());
	    });
	    
	    this.name.subscribe(function(val){
        	self.description(val);
        });
       
        if (self.alternativeModel) {
            var setPackage = function (package1) {
                var referenceExists = self.alternativeModel.get('phaseDesignPart').findWhere({ beepReference: package1.get('id') });
                if (!referenceExists || package1.get('id').indexOf("Common") > -1) {
                    return;
                }
                else {
                    var name = package1.get('name');
                    var packageModel = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: package1.id });
                    self.packages.push({ id: package1.get('id'), name: name, version: package1.get('version') });
                }
            };

            function handleAddClick(commonAlt) {
                function loadDocuments(results) {
                    for (var i = 0; i < results.length; i++) {
                        var type = results.at(i).get('type');
                        if (type === "dashboard_Dashboard") {
                            self.dashboardNames.push({ id: results.at(i).get('id'), name: results.at(i).get('name'), parent: results.at(i).get('parent') });
                        } else if (type === "dashboard_Analytics" && results.at(i).get('parent')) {
                            self.analyticsPackages.push({ id: results.at(i).get('id'), name: results.at(i).get('name'), version: results.at(i).get('version') });
                        } else if (type === "report_BeepReport") {
                            self.reportNames.push({ name: results.at(i).get('name'), parent: results.at(i).get('parent') });
                        }
                    }
				}
				var searchTypes = 'report_BeepReport,dashboard_Dashboard,dashboard_Analytics';
                var nameNS = 'vbc:cmof_EObject-name';
                function loadAdoptDocuments(results) {
                    loadDocuments(results);
                    self.analyticsPackages.push({ name: "New" });
                }
				DataManager.getDataManager().getAllDocumentsCollectionOfType(self.alternativeModel, searchTypes, loadAdoptDocuments, true, null, null, nameNS, commonAlt);
			}

            var planId = self.model.id.substr(self.model.id.lastIndexOf('@'));
            var commonAltId = planId + "-Common@";
            function waitAndLoadRDFModel() {
                var commonRdfModel = DataManager.getDataManager().getRDFModel(commonAltId);
                if (commonRdfModel) {
                    handleAddClick(commonAltId);
                } else {
                    setTimeout(waitAndLoadRDFModel, 5);
                }
            }
            waitAndLoadRDFModel();

        }		
		
		this.cleanModal = function(){
			window.cleanDialogModel(self.encodeId,self);	 
		}
		
		this.createPackage = function(){
			if(self.parentView){
				if(self.name() !== null && self.name() !== "") {
					if(allowedTypes.indexOf(self.selectedType()) === -1){
                        window.utils.limitExceeded();
						window.cleanDialogModel(self.encodeId,self);
						return false;
					}else {
						return options.callback(self,self.encodeId,self.selectedType());
					}		
				}
			}
		}
		function checkUniqueName(name){
			var items;
			if (self.selectedType() === self.analyticsTitle) {
			    items = self.dashboardNames;
			} else {
				items = self.reportNames;
			}

			for(var i=0;i<items.length;i++){			
				if(self.selectedType() === self.reportTitle && items[i].name === name.trim()){
					$('#duplicateName').text(""+self.localManager.get('DuplicateAlert',self.selectedType()));
					self.enableComplete(false);
					return;
				} else if (self.selectedType() === self.analyticsTitle && items[i].name === name.trim() && items[i].parent === self.selectedAnalyticsPackage().id) {
                    $('#duplicateName').text("" + self.localManager.get('DuplicateAlert', self.selectedType()));
				    self.enableComplete(false);
				    return;
				}

			}
			$('#duplicateName').text("");
			if(name.trim() !== "" && self.selectedType()){
				self.enableComplete(true);				
			}else{
				self.enableComplete(false);
			}
		}
		this.afterRenderView = function(){
			$("#packageName"+self.encodeId).keyup(function (view) {
                var newValue = view.currentTarget.value;
                if(newValue.trim() !== '') {
                    checkUniqueName(newValue);
                }
                else {
                	self.enableComplete(false);
                }
			});
			$('#editDetailsModalContent' + self.encodeId).resize(function () {
				$('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
			})
            window.utils.applyFontSizeInDialog();
			//self.loadBMs();
		}	
				
//#endCustomMethods
		
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),[
		,'name'
		,'description'
		,'Complete'
		,'Close'
		,'selectType'
		,'selectPackage'
		,'CreateModel'
		,'selectPlanBusinessModel']);
	}
	
	static getInstance(model,options){
		var view = new AdoptViewModel(model,options);
		return view;
	}
}
path.AdoptViewModel = AdoptViewModel;