import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
//import * as  backboneLawnchair from 'backbonelawnchair'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {BeepPackageMixin} from './BeepPackageMixin'
import {BeepPackage2Mixin} from './BeepPackage2Mixin'
import {EObject} from '../cmof/EObject'
import {Tag} from '../cmof/Tag'
import {Ticket} from '../tickets/Ticket'
import {User} from '../tickets/User'
//import {PlanPackageReference} from '.transformation/PlanPackageReference'
//import {PackageReference} from '../../../com/vbee/filesystem/PackageReference'
//import {BeepModule} from './BeepModule'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/beeppackage/BeepPackageMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,BeepPackageMixin
//, Ticket
//, User
//, Tag
//, PlanPackageReference
//, PackageReference){
	
	var path = DataManager.getDataManager().buildAppNsPath("beeppackage",global.version);		
	
	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? BeepPackage2Mixin : BeepPackageMixin;
	}
	var bpMixinClass = getMixinClass();
	var BeepPackage = EObject.extend(utils.customExtends({
		relations:bpMixinClass.getMixinRelations(),
		subModelTypes: bpMixinClass.getSubModelTypes(),
		initialize: function(attributes, options) {
			if(attributes.type != "smm_SmmModel"){
				var currentPlan = DataManager.getDataManager() ? DataManager.getDataManager().get("currentPlan") : null;
				if(currentPlan){
					this.set("planId",currentPlan.id);
				}
				if(!attributes.documentId){
					this.set("documentId",attributes.id);
					attributes.documentId = attributes.id;
				}
				if(attributes.documentVersion == null){
					if(currentPlan){
						this.set("documentVersion",currentPlan.get('documentVersion'));
						attributes.documentVersion = currentPlan.get('documentVersion');
					} else {
						this.set("documentVersion","0");
						attributes.documentVersion = "0";
					}
				}
				if(DataManager && DataManager.getDataManager() && !DataManager.getDataManager().get("artifactsDocuments")[this.get("documentId")] ){
					DataManager.getDataManager().get("artifactsDocuments")[this.get("documentId")] = {"artifactId":this.get("id") ,"documentId":this.get("documentId"),"documentVersion":this.get("documentVersion"),"workspaceId":DataManager.getDataManager().get("currentWorkspaceId")};
				}
			}
			EObject.prototype.initialize.apply(this, arguments);
			//this.on('destroy',this.destroyPackageReferences)
		}
		}
		, new bpMixinClass()
	));
    BeepPackage['destroy'] = function () {
        this.destroyPackageReferences()
    }
	BeepPackage.createPackage = function(copyAlternative,copyPckStr,callback){
	}	
	BeepPackage.abstract = true;
	path.BeepPackage = BeepPackage;
	export {BeepPackage};
	//return BeepPackage;
//});