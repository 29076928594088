'use strict';

(function (factory) {
    /* global define */
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Browser globals
        factory(window.jQuery);
    }
})(function ($) {
    // Extends lang for print plugin.
    $.extend(true, $.summernote.lang, {
        'en-US': {
            print: {
                print: 'Print'
            }
        },
        'ko-KR': {
            print: {
                print: '인쇄'
            }
        },
        'pt-BR': {
            print: {
                print: 'Imprimir'
            }
        }
    });
    $.extend($.summernote.plugins, {
        /**
         * @param {Object} context
         */
        'print': function print(context) {
            var self = this;
            var ui = $.summernote.ui;
            var $editor = context.layoutInfo.editor;
            var options = context.options;
            var lang = options.langInfo;
            var getPrintframe = function getPrintframe(fhColor) {
                var reportName = window.vdmModelView.SummernoteReportViewModel.model.get('name');
                document.title = reportName;
                var $frame = $('<iframe name="summernotePrintFrame"' + 'width="0" height="0" frameborder="0" src="about:blank" style="visibility:hidden">' + '</iframe>');
                $frame.appendTo($editor.parent());
                var $head = $frame.contents().find('head');
                $head.append('<style type="text/css"> body { counter-reset: section; }  table {page-break-inside:avoid;}  tr { page-break-inside:avoid; page-break-after:auto}  table, th, td { border-collapse: collapse; } ' + '  .note-editable.page-break,.JCLRgrips { display: none; }' + // *:not(.noColor) { background-color: ' + $(".note-editable")["0"].style.backgroundColor + '; }  ' +
                    '  .page-footer { position: fixed; bottom: 0px; width: 100%; border-top: 1px solid black; background: ' + fhColor + '; }' + ' .page-header { position: fixed; top: 0px; width: 100%; border-bottom: 1px solid black;  background: ' + fhColor + ';  }' + ' .table-bordered th, .table-bordered td { border: 1px solid #ddd !important; } .table-bordered { border: 1px solid #ddd; } .table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td { border: 1px solid #ddd; } .table-bordered > thead > tr > th, .table-bordered > thead > tr > td { border-bottom-width: 2px; }  '
                    + '.page - header, .page - header - space { height: 40px; }.page - footer, .page - footer - space { height: 40px; }'
                    + ' .page-break,.page { page-break-after: always; } @page { margin-left: 50px; margin-right: 50px;} @page {margin-top: 65px; margin-bottom: 65px; } '
                    + '@media print { table { border-collapse: collapse; } thead {display: table-header-group;} tfoot {display: table-footer-group;} button {display: none;}  } }</style>'); //mrajender to disable header/footer// class=".ignoreBorder"
                if (options.print && options.print.stylesheetUrl) {
                    var css = document.createElement('link');
                    css.href = options.print.stylesheetUrl;
                    css.rel = 'stylesheet';
                    css.type = 'text/css';
                    $head.append(css);
                } else {
                    $('style, link[rel=stylesheet]', document).each(function () {
                        $head.append($(this).clone());
                    });
                }
                return $frame;
            };
            context.memo('button.print', function () {
                var button = ui.button({
                    contents: '<i class="glyphicon glyphicon-print"/> ',
                    tooltip: lang.print.print,
                    click: function click() {
                        window.utils.startSpinner('print', 'Printing...');
                        //window.require1(["jquery", "bootbox", "regerator-runtime"], function ($, bootbox, regeneratorRuntime) {
                            var $frame = getPrintframe($('.bgColor').val());
                            $('.page-break').css('display', 'block');
                            $('.page-break').css('page-break-after', 'always');
                            var div = document.createElement('div');
                            div.innerHTML = context.invoke('code');
                            var images = div.getElementsByTagName('img');
                            for (var i = 0; i < images.length; i++) {
                                if (images[i].style.width = '' || images[i].style.width.split('px')[0] > 700) {
                                    images[i].style.width = '670px';
                                    images[i].style.height = 'auto';
                                }
                                images[i].style.paddingTop = '10px';
                            };
                            var tables = div.getElementsByTagName('table');
                            for (var i = 0; i < tables.length; i++) {
                                tables[i].style.wordWrap = 'break-word';
                                tables[i].style.width = 'auto';
                                tables[i].style.minWidth = '';
                            };
                            if ($('#reportHeader')["0"].innerHTML.trim() !== "" && $('#reportHeader')["0"].innerHTML.trim() !== "<p><br></p><p></p>" && $('#reportHeader')["0"].innerHTML.trim() !== "<p><br></p>") {
                                var headerTagData = '<div class="page-header" style="text-align: center; background-color: ' + "#ffffff" + ' !important;" > ' + $('#reportHeader')["0"].innerHTML + '</div>';
                            } else {
                                var headerTagData = '';
                            }
                            if ($('#reportFooter')["0"].innerHTML.trim() !== "" && $('#reportHeader')["0"].innerHTML.trim() !== "<p><br></p><p></p>" && $('#reportHeader')["0"].innerHTML.trim() !== "<p><br></p>") {
                                var footerTagData = '<div class="page-footer" style="text-align: center; background-color: ' + "#ffffff" + ' !important;">' + $('#reportFooter')["0"].innerHTML + '</div>';
                            } else {
                                var footerTagData = '';
                            }

                            $frame.contents().find('body').html(headerTagData + footerTagData + '<table><thead><tr><td> <div class="header-space">&nbsp;</div> </td></tr></thead> <tbody style="background-color: ' + $(".note-editable")["0"].style.backgroundColor + ' !important;"><tr style="line-height: 1; background: ' + $(".note-editable")["0"].style.backgroundColor + '"><td> <div class="content"> <div class="page">' + div.innerHTML + '</div> </td></tr></tbody> <tfoot><tr><td> <div class="footer-space">&nbsp;</div> </td></tr></tfoot> </table>');
                            $frame.contents().find('body').append('<div class = "bgLabel"></div>');
                            $frame.contents().find('body').find('.bgLabel').text($('.report-watermark').text());
                            if ($('.report-watermark')["0"].innerHTML != $(".report-watermark").text()) {
                                $frame.contents().find('body').find('.bgLabel').append("<div>" + $('.report-watermark')["0"].innerHTML.substr($(".report-watermark").text().length) + "</div>");
                            }
                            $frame.contents().find('body').find('#pop').remove();
                            $frame.contents().find('body').find('.bgLabel').css('font-size', '120px');
                            $frame.contents().find('body').find('kbd').css('font-family', 'Roboto');
                            $frame.contents().find('body').find('kbd').removeClass("SummernoteToggle");
                            $frame.contents().find('body').find('kbd').addClass("SummernoteToggleEdit");
                            $frame.contents().find('body').find('kbd').css('background-color', 'inherit');
                            $frame.contents().find('body').find('.bgLabel').css('color', $('.report-watermark').css('color'));
                            $frame.contents().find('body').find('.bgLabel').css('transform', $('.report-watermark').css('transform'));
                            $frame.contents().find('body').find('.bgLabel').css('right', '35%');
                            $frame.contents().find('body').find('.bgLabel').css('bottom', "35%");
                            $frame.contents().find('body').find('.bgLabel').css('position', 'fixed');
                            $frame.contents().find('body').find('.bgLabel').css('background-repeat', 'repeat');
                            $frame.contents().find('body').find('.bgLabel').css('page-break-after', 'always');
                            $frame.contents().find('body').find('.bgLabel').css('opacity', $('.report-watermark').css('opacity'));
                            $frame.contents().find('body').find('.header-space').css('background-color', $(".note-editable")["0"].style.backgroundColor);
                            setTimeout(function () {
                                //if (typeof chrome !== 'undefined' && chrome.fileSystem && chrome.fileSystem.chooseEntry) {
                                    $frame[0].contentWindow.focus();
                                    $frame[0].contentWindow.print();
                                    document.title = "Strategyplanner";
                                    window.utils.stopSpinner('print');
                                /*} else {
                                    var _print = function _print(html) {
                                        var url, win, data, fileName;
                                        return regeneratorRuntime.async(function _print$(context$7$0) {
                                            while (1) switch (context$7$0.prev = context$7$0.next) {
                                                case 0:
                                                    url = "data: text/html;base64,".concat(Buffer.from(html).toString('base64'));
                                                    win = new BrowserWindow({
                                                        show: false
                                                    });
                                                    context$7$0.next = 4;
                                                    return regeneratorRuntime.awrap(win.loadURL(url));

                                                case 4:
                                                    context$7$0.next = 6;
                                                    return regeneratorRuntime.awrap(win.webContents.printToPDF({
                                                        marginsType: 0,
                                                        printBackground: true,
                                                        printSelectionOnly: false,
                                                        landscape: false
                                                    }));

                                                case 6:
                                                    data = context$7$0.sent;
													var pdfFileName = "temprepo";
													var path = require('path');
													// app.getPath('userData') will return a string of the user's app data directory path.
													var userDataPath = (electron.app || electron.remote.app).getPath('userData');
													// We'll use the `configName` property to set the file name and path.join to bring it all together as a string
                                                    var fileName = path.join(userDataPath, pdfFileName + '.pdf');

                                                    fs.writeFile(fileName, data, function (error) {
                                                        if (error) throw error;
                                                        console.log('Write PDF successfully.');
                                                        win.close();
                                                        setTimeout(function () {
                                                            var preview = bootbox.dialog({
                                                                title: "<span style=\"font-family:Roboto; display: inline-block;\">Print Preview</span>",
                                                                message: "<div class='viewer' id='viewer'> </div>",
                                                                className: "bootboxModal",
                                                                buttons: {
                                                                    close: function close() {
                                                                        //fs.unlink('temprepo.pdf', function (err) {
                                                                        //    if (err) throw err;
                                                                        //     console.log('File deleted!');
                                                                        //});
                                                                    }
                                                                },
                                                                onEscape: function onEscape() {
                                                                   // fs.unlink('temprepo.pdf', function (err) {
                                                                   //     if (err) throw err;
                                                                   //     console.log('File deleted!');
                                                                   //});
                                                                }
                                                            }).find("div.modal-dialog").css({
                                                                "width": "40%",
                                                                "height": "100%"
                                                            });

                                                            // "overflow": "hidden !important"
                                                            preview.init(function () {
                                                                window.utils.stopSpinner('print');
                                                                var path = require('path');
                                                                var viewerEle = document.getElementById('viewer');
                                                                viewerEle.innerHTML = ''; // destroy the old instance of PDF.js (if it exists)
                                                                var iframe = document.createElement('iframe');
                                                                //var filepath = __dirname + "\\temprepo.pdf";
																var pdfFileName = "temprepo";
																var userDataPath = (electron.app || electron.remote.app).getPath('userData');
																// We'll use the `configName` property to set the file name and path.join to bring it all together as a string
																var fileName = path.join(userDataPath, pdfFileName + '.pdf');
                                                                iframe.src = path.resolve(__dirname, "\js\\libs\\pdfJs\\web\\viewer.html?file=".concat(fileName));
                                                                viewerEle.appendChild(iframe);
                                                            });
                                                        }, 500);
                                                    });

                                                case 9:
                                                case 'end':
													if(context$7$0.method == "throw"){
														window.utils.stopSpinner('print');
														bootbox.alert('Printing Failed.');
													}
                                                    return context$7$0.stop();
                                            }
                                        }, null, this);
                                    };

                                    var electron = require('electron');
                                    var BrowserWindow = electron.remote.BrowserWindow;
                                    var fs = require('fs');

                                    _print("<!DOCTYPE html>" + $frame.contents()["0"].firstElementChild.innerHTML);
                                }*/
                                $frame.remove();
                                $frame = null;
                            }, 1500);
                       // });
                    }
                });
                var $print = button.render();
                return $print;
            });
        }
    });
});