import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
//import * as  backboneLawnchair from 'backbonelawnchair'
import {EObject} from './EObject'
import {TagMixin} from './TagMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/TagMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,TagMixin

//, BeepPackage){
	
	var path = DataManager.getDataManager().buildAppNsPath("cmof",global.version);


	var Tag = EObject.extend(utils.customExtends({
		relations:TagMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			EObject.prototype.initialize.apply(this, arguments);
		}
		}
		, new TagMixin()
	));
	Tag.abstract = false;
	path.Tag = Tag;
	//return Tag;
//});
export {Tag};