import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import * as jszip from '../../../../libs/jszip/jszip.min'
//import * as  backboneLawnchair from 'backbonelawnchair'
import {EObject} from '../cmof/EObject'
import {BeepPackage} from './BeepPackage'
import {CodeContainerMixin} from './CodeContainerMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/beeppackage/CodeContainerMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,CodeContainerMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("beeppackage",global.version);
	var CodeContainer = BeepPackage.extend(utils.customExtends({
		relations:CodeContainerMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BeepPackage.prototype.initialize.apply(this, arguments);			
		}
		}
		, new CodeContainerMixin()
	));
	CodeContainer.getBlobInstanceWithId = function (id,plan,callback) {
        var vdmStore = DataManager.getDataManager().getVDMStore(plan.getCommonRepositoryId());
        DataManager.getDataManager().fetchDocumentFromPackage(id, "appbo/beeppackage/CodeContainer", DataManager.getDataManager().get('currentPlanVersion'), id, "appbo/beeppackage/CodeContainer", vdmStore, {
            success: function (codeContainer) {
                callback(codeContainer);
            },
            error: function (error) {
                callback(null);
            }
        });
    }
    CodeContainer.getBlobInstance = function (name,description,plan,dataId) {
        var dataId = dataId ? dataId : DataManager.getDataManager().guidGenerator(plan.getCommonRepositoryId());
        var codeContainer = new CodeContainer({ id: dataId, name: name, description: description, planId: plan.id, 'version': DataManager.getDataManager().get('currentPlanVersion') });
        DataManager.getDataManager().get('initializedPackages').add({'id':codeContainer.get('id'),'version':parseInt(codeContainer.get('version')),'model':codeContainer});
        return codeContainer;
    }
	CodeContainer.fetchPlanCodeContainers = function(plan,callback){
        var self = plan;
        var beepPackages = [];
        var planId = self.id;
        var dataManager = DataManager.getDataManager();
        var planWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
       // var legalId = dataManager.get("workspaceLegal")[planWorkspaceId];
        var docVersion = self.get('documentVersion');
        var zip = true;
        var url = encodeURI("/vdmbee/image/planImageArtifacts/"+planWorkspaceId+"/wsartifact/"+planId+"/"+docVersion+"/"+zip);
        dataManager.get('vmpServerService').getAllPromise(url).then(function(imgPackagesZip) {
            if(imgPackagesZip){
                var reader = new FileReader();
                reader.onload = function (ev) {
                    var vpkContent = reader.result;
                    var newclPackZip = new jszip();
                    newclPackZip.loadAsync(vpkContent).then(function (clpackZip) {
                        var obj = clpackZip.files;
                        var files = jQuery.map(obj, function(el) { return el });
                        async.each(files,function(vocFile,handledFile){
                            vocFile.async("string").then(function (fileText) {
                                var clPack = JSON.parse(fileText);
                                beepPackages.push(clPack);
                                handledFile();
                            });
                        }, function (err){
                            callback(beepPackages);
                        });          	
                    });
                };
                reader.readAsArrayBuffer(imgPackagesZip);
            } else {
                callback();
            }
        });
    }
	CodeContainer.abstract = true;
	path.CodeContainer = CodeContainer;
	export {CodeContainer};
	//return CodeContainer;
//});