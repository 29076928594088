import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {EObject} from '../cmof/EObject'
import {AttributeMixin} from './AttributeMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/vdml/AttributeMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,AttributeMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var Attribute = EObject.extend(utils.customExtends({
		relations:AttributeMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			EObject.prototype.initialize.apply(this, arguments);
		}
		}
		, new AttributeMixin()
	));
	
	Attribute.abstract = false;
	Attribute.supported = false;
	path.Attribute = Attribute;
	export {Attribute};
//});