import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import {PortContainerMixin} from './PortContainerMixin'
import {ParticipantMixin} from './ParticipantMixin'
import {Role} from './Role'
/*import {Activity} from './Activity'
import {DeliverableFlow} from './DeliverableFlow'
import {PortDelegation} from './PortDelegation'
import {DelegationContext} from './DelegationContext'
import {Scenario} from './Scenario'
import {CollaborationDiagram} from './CollaborationDiagram'*/
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/PortContainer","appbo/vdml/PortContainerMixin","appbo/vdml/Participant"],
function(require,jQuery,_,async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,PortContainer,PortContainerMixin,Participant
, ParticipantMixin
, Role
, Activity
, DeliverableFlow
, BusinessItem
, PortDelegation
, DelegationContext
, Scenario
, Assignment
, CollaborationDiagram){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	/*if(!Participant){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Participant){
			Participant = importPath.Participant;
		}
		else{
			require(["appbo/vdml/Participant"],function(loadedModule){
				Participant = loadedModule;
			});
		}
	}
	if(!ParticipantMixin){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ParticipantMixin){
			ParticipantMixin = importPath.ParticipantMixin;
		}
		else{
			require(["appbo/vdml/ParticipantMixin"],function(loadedModule){
				ParticipantMixin = loadedModule;
			});
		}
	}
	if(!Role){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Role){
			Role = importPath.Role;
		}
		else{
			require(["appbo/vdml/Role"],function(loadedModule){
				Role = loadedModule;
			});
		}
	}
	if(!Activity){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Activity){
			Activity = importPath.Activity;
		}
		else{
			require(["appbo/vdml/Activity"],function(loadedModule){
				Activity = loadedModule;
			});
		}
	}
	if(!DeliverableFlow){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.DeliverableFlow){
			DeliverableFlow = importPath.DeliverableFlow;
		}
		else{
			require(["appbo/vdml/DeliverableFlow"],function(loadedModule){
				DeliverableFlow = loadedModule;
			});
		}
	}
	if(!BusinessItem){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItem){
			BusinessItem = importPath.BusinessItem;
		}
		else{
			require(["appbo/vdml/BusinessItem"],function(loadedModule){
				BusinessItem = loadedModule;
			});
		}
	}
	if(!PortDelegation){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PortDelegation){
			PortDelegation = importPath.PortDelegation;
		}
		else{
			require(["appbo/vdml/PortDelegation"],function(loadedModule){
				PortDelegation = loadedModule;
			});
		}
	}
	if(!DelegationContext){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.DelegationContext){
			DelegationContext = importPath.DelegationContext;
		}
		else{
			require(["appbo/vdml/DelegationContext"],function(loadedModule){
				DelegationContext = loadedModule;
			});
		}
	}
	if(!Scenario){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Scenario){
			Scenario = importPath.Scenario;
		}
		else{
			require(["appbo/vdml/Scenario"],function(loadedModule){
				Scenario = loadedModule;
			});
		}
	}
	if(!Assignment){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Assignment){
			Assignment = importPath.Assignment;
		}
		else{
			require(["appbo/vdml/Assignment"],function(loadedModule){
				Assignment = loadedModule;
			});
		}
	}
	if(!CollaborationDiagram){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CollaborationDiagram){
			CollaborationDiagram = importPath.CollaborationDiagram;
		}
		else{
			require(["appbo/vdml/CollaborationDiagram"],function(loadedModule){
				CollaborationDiagram = loadedModule;
			});
		}
	}*/
	export class CollaborationMixin {

	defaults(){
		var ret = {
			type: "vdml_Collaboration"
		}
		var PortContainer = Backbone.Relational.store.getObjectByName('vdml.PortContainer');
		return jQuery.extend(PortContainer.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Collaboration",
			key:"collaborationRole",
			relatedModel:"vdml.Role",
			reverseRelation: {
				key:"collaborationRoleOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Collaboration",
			key:"activity",
			relatedModel:"vdml.Activity",
			reverseRelation: {
				key:"activityOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Collaboration",
			key:"flow",
			relatedModel:"vdml.DeliverableFlow",
			reverseRelation: {
				key:"flowOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Collaboration",
			key:"businessItem",
			relatedModel:"vdml.BusinessItem",
			reverseRelation: {
				key:"businessItemOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Collaboration",
			key:"internalPortDelegation",
			relatedModel:"vdml.PortDelegation",
			reverseRelation: {
				key:"internalPortDelegationOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Collaboration",
			key:"delegationContext",
			relatedModel:"vdml.DelegationContext",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"contextCollaboration",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Collaboration",
			key:"scenario",
			relatedModel:"vdml.Scenario",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Collaboration",
			key:"ownedAssignment",
			relatedModel:"vdml.Assignment",
			reverseRelation: {
				key:"ownedAssignmentOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Collaboration",
			key:"diagrams",
			relatedModel:"vdml.CollaborationDiagram",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"defaultShapeReferenceOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		]);
	}
	static getCumulativeMixinRelations(){
		if (!CollaborationMixin.cummulativeRelations) {
            CollaborationMixin.cummulativeRelations = _.union(CollaborationMixin.getMixinRelations()
                ,ParticipantMixin.getCumulativeMixinRelations()
		        ,PortContainerMixin.getCumulativeMixinRelations()
            );
        }
		return CollaborationMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_Participant",
			"vdml_PortContainer"
		];
	}
	static getProperties(){
		return [
			{name : "version",type : "EString",defaultValue : "null",containingClass : "cmof_EObject" },
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "progress", type: "double", defaultValue: "0", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
    }
    calculateProgress () {

    }
	getParent(){
		var container;
		if(!container){
			container = this.get("collaborationOwner") ? this.get("collaborationOwner") : this.previousAttributes().collaborationOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("collaborationOwner") ? this.get("collaborationOwner") : this.previousAttributes().collaborationOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/CollaborationPropertiesTemplate.html",
			templateName : "CollaborationPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/CollaborationViewModel",
			tabId : "CollaborationView",
			tabName: "Collaboration"
		}
	}
//#startCustomMethods
	createDefaultRole(){
		var roleId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var role = new Role({id:roleId,name:this.getUniqueProperty('name',"My Business Role",'collaborationRole'),collaborationRoleOwner:this});
		return role;
	};
	createAndAssignDefaultRole(businessModel){debugger
		var role = this.createDefaultRole();
		if(businessModel){
			businessModel.get('bmRole').add(role);
			var business = businessModel.get('business');
			if(!business){
				business = businessModel.createDefaultBusiness();
				businessModel.set('business',business);
			}
			this.assignRoleToParticipant(role,business);
		}
		//this.set('defaultRole',role);
		return role;
	};
	assignRoleToParticipant(role,participant){
		if(!role || !participant){
			return;
		}
		var Assignment = Backbone.Relational.store.getObjectByName("vdml.Assignment");
		var assignmentId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var assignment = new Assignment({id:assignmentId,name:this.getUniqueProperty('name',"Assignment",'ownedAssignment'),ownedAssignmentOwner:this});	
		assignment.set("assignedRole",role);
		assignment.set("participant",participant);
		return assignment;
	};
	assignDeliverableFlow(businessItem,outputPort,inputPort){
		var biName;
		if(businessItem){
			biName = businessItem.get('name');
		}else{
			this.get('name');
		}
		var dfId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var DeliverableFlow = Backbone.Relational.store.getObjectByName("vdml.DeliverableFlow");
		var deliverableFlow = new DeliverableFlow({id:dfId,name:this.getUniqueProperty('name',biName,'flow'),description:biName,flowOwner:this});
		deliverableFlow.set("deliverable",businessItem);
		deliverableFlow.set("provider",outputPort);
		deliverableFlow.set("recipient",inputPort);
		return deliverableFlow;
	};
	createCapabilityUseAtDelegationLevel(capability){
		var actId =  DataManager.getDataManager().guidGeneratorByOwner(this);
		var underlyingActivities = this.get('activity');
		var capabilityExists = false;
		//1 level checking not sure whether to check multi levels
		for(var i=0;i<underlyingActivities.length;i++){
			if(underlyingActivities.at(i).get('appliedCapabilityOffer') == capability && capability.get('applyingActivity').findWhere({id:underlyingActivities.at(i).get('id')})){
				capabilityExists = true;
				break;
			}
		}
		if(!capabilityExists){
			var Activity = Backbone.Relational.store.getObjectByName("vdml.Activity");
			var act = new Activity({id:actId,name:this.getUniqueProperty('name',capability.get('name'),'activity'),description:capability.get('description'),activityOwner:this});
			act.applyCapability(capability);
		}
	};
	getHierarchy(){
	};
	isInHierarchy(){	
	};
	handleCollNameChange(modelRef, newName){
		if(Backbone.skipPersistance){
			return;
		}
		//if(modelRef.setCollborationName && modelRef.get('name') != newName){
		//	modelRef.setCollborationName(newName);
		//}
		var self = this; 
		if (self.getNestedParent().get('type') === "vdml_ValueDeliveryModel") {
			DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_ParticipantNetwork-collaboration', 'vdml_ParticipantNetwork', "vbc:" + "cmof_EObject-name",
				function (bmPNs) {
					bmPNs.each(function (bmPN) {
						if (bmPN.get('name') !== newName) {
							bmPN.set('name', newName);
						}
					});
				}, true);
		}
	}
	getCurrentCapabilities(){
		var capabilities = [];
		var contextActivities = this.get('activity');
		contextActivities.each(function(act){
			var capability = act.get('appliedCapabilityOffer');
			if(capability){
				capabilities.push(capability);
			}
		});
		return capabilities;
	};	
	
	copy(sourcePackage,targetPackage,targetCapabilityMethodContainer,measurementsCopied,valuesCopied,callback){
		var self = this;
		var isCapabilityMethod = self.get('type') === 'vdml_CapabilityMethod';
		var sourceCapabilityMethodContainer;
		if(isCapabilityMethod){
			sourceCapabilityMethodContainer = self.get('methodOwner');
		}
		var copyContainer = isCapabilityMethod ? targetCapabilityMethodContainer : targetPackage;
		var copyName = copyContainer.getUniqueProperty('name',self.get('name'),isCapabilityMethod ? 'ownedMethod' :'collaboration' );
		var collId = DataManager.getDataManager().guidGeneratorByOwner(targetPackage);
		var attrs = {id:collId,name:copyName,description:self.get('description')};
		if(isCapabilityMethod){
			attrs.methodOwner = copyContainer;
		}else{
			attrs.collaborationOwner = copyContainer;
		}
		var collCopy = new self.constructor(attrs);
		
		self.linkCollaborationCopyToContexts(collCopy);
		//TODO copy Calendar service
		self.copyAssignments(collCopy,function(){
			self.copyBusinessItems(collCopy,measurementsCopied);
			self.copyPorts(collCopy,measurementsCopied,valuesCopied);
			self.copyInternalPortDelegations(collCopy);
			var mesChars = self.get('measuredCharacteristic');
			mesChars.each(function(mesChar){
				mesChar.copy(collCopy,measurementsCopied);			
			});	
			var roles = self.get('collaborationRole');
			roles.each(function(role){
				role.copy(collCopy,measurementsCopied,valuesCopied);
			});
			self.get('activity').each(function(activity){
				activity.copy(collCopy,measurementsCopied,valuesCopied);
			});
			self.copyOwnedAssignments(collCopy);
			if(self.get('type') === 'vdml_OrgUnit'){
				self.get('store').each(function(store){
					store.copy(collCopy,measurementsCopied,valuesCopied);
				});
				async.each(self.get('ownedMethod').models,function(ownedMethod,methodCopied){
					ownedMethod.copy(self.getNestedParent(),collCopy.getNestedParent(),collCopy,measurementsCopied,valuesCopied,methodCopied);
				},function(){
					self.get('capabilityOffer').each(function(capOffer){
						capOffer.copy(collCopy,measurementsCopied);
					});
					copyOtherData();
				});
			}else{
				copyOtherData();
			}
			
			function copyOtherData(){
				self.copyFlows(collCopy,measurementsCopied);
				self.copyInternalPortDelegations(collCopy);
				if(self.get('type') === 'vdml_CapabilityMethod'){
					collCopy.set('capability',self.get('capability'));
					self.get('methodResource').each(function(methodResource){
						var methodContainer = methodResource.get('businessItemOwner');
						if((methodContainer === self) || (methodContainer === sourceCapabilityMethodContainer) && (targetCapabilityMethodContainer !== sourceCapabilityMethodContainer)){
							var targetMethodResource = collCopy.get('businessItem').findWhere({name:methodResource.get('name')});
							collCopy.get('methodResource').add(targetMethodResource);
							targetMethodResource.get('method').add(collCopy);
						}else{
							collCopy.get('methodResource').add(methodResource);
							methodResource.get('method').add(collCopy);
						}
					});
				}
				if(callback){
					callback(collCopy);
				}
			}
		});
		
	};

	copyInternalPortDelegations(collCopy){
		var intPortDels = this.get('internalPortDelegation');
		intPortDels.each(function(portDel){
			var sourcePort = portDel.get('source');
			sourcePort = collCopy.get('internalPortDelegation').findWhere({name:sourcePort.get('name')});
			var targetPort = portDel.get('target');
			var collActivites = collCopy.get('activity');
			var targetContainerPort;
			collActivites.each(function(activity){
				if(!targetContainerPort){
					targetContainerPort = activity.get('containedPort').findWhere({name:targetPort.get('name')});
				}
			});
			portDel.copy(sourcePort,targetPort,targetContainerPort);
		});
	};
	
	copyFlows(collCopy,measurementsCopied){
		var flows = this.get('flow');
		flows.each(function(flow){
			var flowProviderContainer = flow.get('provider').get('containedPortOwner');
			var flowRecContainer = flow.get('recipient').get('containedPortOwner');
			var providerName= flow.get('provider').get('name');
			var recipientName = flow.get('recipient').get('name');
			var providerPort;
			var recipientPort;
			function setProvider(){
				var copyActs = collCopy.get('activity');
				copyActs.each(function(copyAct){
					if(flowProviderContainer.get('name') === copyAct.get('name')){
						var actPorts = copyAct.get('containedPort');
						actPorts.each(function(actPort){
							if(actPort.get('name') === providerName){
								if(!providerPort){
									providerPort = actPort;	
								}
							}
						});
					}
				});				
			}
			function setRecipient(){
				var copyActs = collCopy.get('activity');
				copyActs.each(function(copyAct){
					if(flowRecContainer.get('name') === copyAct.get('name')){
						var actPorts = copyAct.get('containedPort');
						actPorts.each(function(actPort){
							if(actPort.get('name') === recipientName){
								recipientPort = actPort;	
							}
						});
					}
				});				
			}			
			if(flowProviderContainer.get('type') === 'vdml_Store'){
				providerPort = flow.get('provider');
			}else{
				setProvider();
			}
			setRecipient();
			flow.copy(providerPort,recipientPort,this,collCopy,measurementsCopied);
		});
	};
	
	copyPorts(collCopy,measurementsCopied,valuesCopied){
		var ports = this.get('containedPort');
		ports.each(function(port){
			port.copy(collCopy,this,collCopy,measurementsCopied,valuesCopied);
		});
	};
	
	copyBusinessItems(collCopy,measurementsCopied){
		var businessItems = this.get('businessItem');
		businessItems.each(function(businessItem){
			businessItem.copy(this,collCopy,measurementsCopied);
		});
	};

	copyAssignments(collCopy,callback){
		DataManager.getDataManager().getAssignments(this,this.getNestedParent().get('id'),function(assignments){
			assignments.each(function(assignment){
				var assignmentContainer = assignment.get('ownedAssignmentOwner');
				if(!assignmentContainer){
					assignmentContainer = assignment.get('contextBasedAssignmentOwner');
				}
				if(assignmentContainer){
					var targetAssignedRole = collCopy.get('collaborationRle').findWhere({name:assignment.get('assignedRole').get('name')});
					assignment.copy(assignmentContainer,assignmentContainer,assignment.get('assignedRole'),targetAssignedRole);	
				}
			});
			if(callback){
				callback();
			}
		});
	};
	
	copyOwnedAssignments(collCopy){
		var assignments = this.get('ownedAssignment');
		assignments.each(function(assignment){
			var assignmentContainer = assignment.get('ownedAssignmentOwner');
			if(assignmentContainer){
				var targetAssignedRole = collCopy.get('collaborationRle').findWhere({name:assignment.get('assignedRole').get('name')});
				assignment.copy(this,collCopy,assignment.get('assignedRole'),targetAssignedRole);	
			}
		});
	};
	
	linkCollaborationCopyToContexts(collCopy){
		var scenarios = this.get('scenario');
		scenarios.each(function(scenario){
			scenario.get('contextCollaboration').add(collCopy);
		});
	};
	
//#endCustomMethods
	
	}
	path.CollaborationMixin = CollaborationMixin;
	//return CollaborationMixin;
//});