import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Performer} from './Performer'
import {PerformerMixin} from './PerformerMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Performer","appbo/vdml/PerformerMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Performer,PerformerMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class ApplicationRoleMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.ApplicationRoleMixin = ApplicationRoleMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_ApplicationRole"
		}
		return jQuery.extend(Performer.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!ApplicationRoleMixin.cummulativeRelations) {
            ApplicationRoleMixin.cummulativeRelations = _.union(ApplicationRoleMixin.getMixinRelations()
                , PerformerMixin.getCumulativeMixinRelations()
            );
        }
		return ApplicationRoleMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_Performer"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "isLead",type : "EBoolean",defaultValue : "null",containingClass : "vdml_Role" }
		]
	}
	getParent(){
		var container;
		return Performer.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return Performer.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ApplicationRolePropertiesTemplate.html",
			templateName : "ApplicationRolePropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ApplicationRoleViewModel",
			tabId : "ApplicationRoleView",
			tabName: "ApplicationRole"
		}
	}
	
	}
	path.ApplicationRoleMixin = ApplicationRoleMixin;
	//return ApplicationRoleMixin;
//});