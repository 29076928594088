
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from './EObject'
import {EObjectMixin} from './EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin

//, BeepPackage){
	
	var path = DataManager.getDataManager().buildAppNsPath("cmof",global.version);

	export class TagMixin {
	
	defaults(){
		var ret = {
			type: "cmof_Tag"
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
/*		{
			type :Backbone.HasMany,
			containingClass:"cmof_Tag",
			key:"taggedObject",
			relatedModel:"cmof.EObject",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},*/
		])
	}
	static getCumulativeMixinRelations(){
		if (!TagMixin.cummulativeRelations) {
            TagMixin.cummulativeRelations = _.union(TagMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return TagMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("tagOwner") ? this.get("tagOwner") : this.previousAttributes().tagOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("tagOwner") ? this.get("tagOwner") : this.previousAttributes().tagOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/cmof/views/properties/TagPropertiesTemplate.html",
			templateName : "TagPropertiesTemplate",
			viewTypeStr : "appviews/cmof/views/properties/TagViewModel",
			tabId : "TagView",
			tabName: "Tag"
		}
	}
}
	path.TagMixin = TagMixin;
//	return TagMixin;
//});