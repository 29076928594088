import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {ValueElement} from './ValueElement'
import {ValueElementMixin} from './ValueElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

	export class SuggestionMixin {

	defaults(){
		var ret = {
			type: "vdml_Suggestion"
		}
		return jQuery.extend(ValueElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
			
		])
	}
	static getCumulativeMixinRelations(){
		if (!SuggestionMixin.cummulativeRelations) {
            SuggestionMixin.cummulativeRelations = _.union(SuggestionMixin.getMixinRelations()
                , ValueElementMixin.getCumulativeMixinRelations()
            );
        }
		return SuggestionMixin.cummulativeRelations.slice();
    }
	static getSuperTypes(){
		return [
			"vdml_ValueElement"
		];
	};
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "shapeId",type : "EString",defaultValue : "null" ,containingClass : "vdml_Suggestion" },
			{name : "targetId",type : "EString",defaultValue : "null" ,containingClass : "vdml_Suggestion" },
			{name : "targetType",type : "EString",defaultValue : "null" ,containingClass : "vdml_Suggestion" },
			//{name : "data",type : "EString",defaultValue : "null" ,containingClass : "vdml_Suggestion" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("suggestionOwner") ? this.get("suggestionOwner") : this.previousAttributes().suggestionOwner;
			if(container){
				return container;
			}
		}
		return this;
	};


//#startCustomMethods

//#endCustomMethods	
	
	}
	path.SuggestionMixin = SuggestionMixin;

