import * as _ from 'underscore'
import { BeepPackageMixin } from "./BeepPackageMixin";
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ValueType } from '../vdml/ValueType';

var path = DataManager.getDataManager().buildAppNsPath("beeppackage",global.version);

export class BeepPackage2Mixin {
    static getSubModelTypes(){
        var ret = BeepPackageMixin.getSubModelTypes();
        ret['transformation_PlanScenario'] = 'transformation.PlanScenario';
        ret['transformation_ScenarioExecution'] = 'transformation.ScenarioExecution';
        ret['transformation_PeriodDataset'] = 'transformation.PeriodDataset';
        ret['instdef_PlanInstantiationPackage'] = 'instdef.PlanInstantiationPackage';
        return ret;
    }

    updateScenarioDataForNextAlts(currentAltenative,copyAlternative,copyPck,callback){
        var self = this;
        var dataManager = DataManager.getDataManager();
        var periodKind = dataManager.get('currentPlan').get("periodKind");
		var scenarioId = dataManager.get("currentPlan").get("defaultScenario");
        var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
        var defaultExecutionScenaroId = scenario.get("defaultExecutionScenario");
		var defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
        //var dataSets = copyAlternative.getPhaseAlternativeSteps(scenario);
        //var currentAltId = dataManager.htmlEscape(currentAltenative.get("id"));
        //var currentAltReg = new RegExp(currentAltId, 'g');
        var components = self.getPackageValues(true);
        var nextAlts = copyAlternative.getAltsHavingPackage(copyPck.id);
        nextAlts.push(copyAlternative);
        _.each(nextAlts,function(alt){
            self.updatePeriodDatasets(alt,copyAlternative,scenario,components,periodKind,defaultExecutionScenaro);
            self.removeValueContext(components,alt,copyAlternative);
            self.fixValueFormulaAndAltAssociation(components,alt,copyAlternative);
        });
        self.removeAggregatedAssociation(components,copyAlternative);
        callback();
    }

    fixValueFormulaAndAltAssociation(components,alt,copyAlternative){
        _.each(components,function(comp){
            alt.get('alternativeValues').remove(comp);
            var copyAltComp =  window.utils.getElementModel(copyAlternative.id + window.utils.getSuffix(comp.id),['vdml.ValuePropositionComponent','vdml.ValueAdd']);
            alt.get('alternativeValues').add(copyAltComp);
            /*if(comp.get('valueType') == ValueType.Aggregated){
                var context = copyAltComp ? copyAltComp.getValueContext(alt.id) : null;
                if(context){
                    var formula = context.get("formula");
                    var exp = formula ? formula.get('expressionStr') : null;
                    //var components = comp.getFromAggregations(alt.id);
                    if(exp){
                        var newExp = exp.replace(currentAltReg, DataManager.getDataManager().htmlEscape(alt.id));
                        formula.set("expressionStr",newExp);
                    }
                }
            }*/
        });
    }

    removeAggregatedAssociation(components,copyAlternative){
        _.each(components,function(comp){
            if(comp.get('valueType') == ValueType.Aggregated){
                var copyAltComp =  window.utils.getElementModel(copyAlternative.id + window.utils.getSuffix(comp.id),['vdml.ValuePropositionComponent','vdml.ValueAdd']);
                var context = copyAltComp ? copyAltComp.getValueContext(copyAlternative.id) : null;
                if(context){
                    copyAltComp.get('aggregatedFrom').reset();
                    //copyAltComp.get('aggregatedTo').reset();
                }
            }
        });
    }

    getPackageValues(sublevel,type){
        var self = this;
        var subCompLevel = ["satisfactionLevel","percentageWeight","recipientOpinion"];
        var components = [];
        var valuesList = [];
        self.get("businessModel").forEach(bm => {
            bm.getValues(components,true);
            _.each(components,function(comp){
                if(type && comp.get('valueType') == type){
                    valuesList.push(comp);
                } else {
                    valuesList.push(comp);
                }
                if(sublevel){
                    _.each(subCompLevel,function(level){
                        if(type && comp.get(level) && comp.get(level).get('valueType') == type){
                            valuesList.push(comp.get(level));
                        } else if(comp.get(level)){
                            valuesList.push(comp);
                        }
                    });
                }
            });
        })
        return valuesList;
    }

    removeValueContext(components,alt){
        _.each(components,function(comp){
            if(comp.get('valueType') == ValueType.Aggregated){
                var context = comp.getValueContext(alt.id);
                if(context){
                    context.destroy();
                }
            }
        });
    }

    updatePeriodDatasets(alt,copyAlternative,scenario,components,periodKind,defaultExecutionScenaro){
        var self = this;
        var step = scenario.get("step").findWhere({alternativeId: alt.id});
        if(step){
            var datasetsArr = [];
            var periodsLen = step.get('noPeriods');
            var period = step.get("startPeriod");			
            for(var l = 0; l < periodsLen; l++){
                if(l != 0){
                    period++;
                }
                let yearPeriod = utils.calculateYearPeriod(scenario.get("startTime") , period, periodKind );
                datasetsArr.push({...yearPeriod});
            }
            _.each(datasetsArr,function(yearPeriod){
                var	inputPeriodDataset = defaultExecutionScenaro.get("input").findWhere({period: yearPeriod.period,year: yearPeriod.year,periodKind : periodKind});
                var resultPeriodDataset = defaultExecutionScenaro.get("result").findWhere({period: yearPeriod.period,year: yearPeriod.year,periodKind : periodKind});
                _.each(components,function(comp){
                    self.updateQuantity(comp,inputPeriodDataset,resultPeriodDataset,copyAlternative);
                    //self.updateQuantity(comp.get("satisfactionLevel"),inputPeriodDataset,resultPeriodDataset,currentAltId);
                    //self.updateQuantity(comp.get("percentageWeight"),inputPeriodDataset,resultPeriodDataset,currentAltId);
                    //self.updateQuantity(comp.get("recipientOpinion"),inputPeriodDataset,resultPeriodDataset,currentAltId);
                });            
            });
        }
    }

    updateQuantity(comp,inputPeriodDataset,resultPeriodDataset,copyAlternative){
        if(!comp){
            return;
        }
        var oldCompId = comp.get('id');
        var newCompId = copyAlternative.id + window.utils.getSuffix(comp.get('id'));
        var inputQty = inputPeriodDataset ? inputPeriodDataset.get(oldCompId):null;
        var resultQty = resultPeriodDataset ? resultPeriodDataset.get(oldCompId):null;
        if(inputQty){
            inputPeriodDataset.set(newCompId,inputQty);
            inputPeriodDataset.unset(oldCompId);
        }
        if(resultQty){
            resultPeriodDataset.set(newCompId,resultQty);
            resultPeriodDataset.unset(oldCompId);
        }
    }

    /*getAltDatasetByPackage(scenario,periodKind,packAltList){
        var self = this;
        var datasetsArr = [];
        _.each(nextAlts,function(alt){
            var step = scenario.get("step").findWhere({alternativeId: alt.id});
            datasetsArr.push({"year":endYear,"period":period});
        });
        var periodsLen = step.get('noPeriods');
        var period = step.get("startPeriod");			
        for(var l = 0; l < periodsLen; l++){
            if(l != 0){
                period++;
            }
            let yearPeriod = utils.calculateYearPeriod(scenario.get("startTime") , period, periodKind );
            datasetsArr.push({...yearPeriod});
        }
		return datasetsArr;	
	};*/
}
utils.customExtendClass (BeepPackage2Mixin,new BeepPackageMixin());

path.BeepPackage2Mixin = BeepPackage2Mixin;