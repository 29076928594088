import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
//define(["underscore", "backbone", "Lawnchair","backbone-lawnchair","backbone-relational"],
//function(_, Backbone, Lawnchair,backboneLawnchair) {
    var ChangeObject = function (properties) {
        this.type = "com_vbee_data_ChangeObject";
        this.id = window.guidGenerator();
        this.documentId = this.id;
        this.documentVersion = 0;
        this.operationType = "add";
        this.change = null;
        this.previous = null;
        this.changeObjectId = "";
        this.changeObjectType = "";
        this.isJsonReferenced = true;
        this.versionChange = false;
        this.oldVersion = null;
        this.description = "";
        if (properties) {
            var keys = Object.getOwnPropertyNames(properties);
            for (var i = 0; i < keys.length; i++) {
                if (keys[i] === 'changeSet' && properties['changeSet']) {
                    //properties['changeSet'].changes.push(this);
                } else {
                    this[keys[i]] = properties[keys[i]];
                }
            }
        }
    }
    ChangeObject.prototype.get = function(property){
        return this[property];
    }
    ChangeObject.prototype.set = function(property,value){
        this[property] = value;
    }
    ChangeObject.prototype.getVersion = function () {
        return Math.round(this['version'] / 1000);
    }

    //return ChangeObject;
	export {ChangeObject};
//});