import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import { BeepVocabularyMixin } from '../../../../../version1/bo/concept/BeepVocabularyMixin'
import * as jszip from '../../../../../../libs/jszip/jszip.min'

/*define(["require","jquery","underscore","backbone","knockout","bootbox","async","jszip","knockoutMapping","knockback","appcommon/com/vbee/data/DataManager","app/global","appbo/concept/BeepVocabularyMixin"],
function(require,$,_, Backbone,ko,bootbox,async,jszip,koMapping,kb,DataManager,global,BeepVocabularyMixin)
{*/
var path = DataManager.getDataManager().buildAppNsPath("concept.views.properties", global.version);
export class VocabularyPackageViewModel{
        constructor(model, options) {
            var self = this;
            this.init(model, options);
        }		
        fillVocabularyModels() {
            var self = this;
			var vocabularyLibList = self.model.get('vocabularyLibrary').models.concat();
			for(var i=0;i<vocabularyLibList.length;i++)
			{
				self.vocabularyLibColl.push({name:vocabularyLibList[i].get('name'),description:vocabularyLibList[i].get('description')})	
			}
			/*vocabularyLibList.each(function(vocLib){
				self.vocabularyLibColl.push({name:vocLib.get('name'),description:vocLib.get('description')})
				var vocList = vocLib.get('vocabulary');
				vocList.each(function(voc){
					self.vocabularlyModelColl.push({name:voc.get('name'), businessContext: voc.get('businessContext'), locale: voc.get('locale'),seqId:voc.get('id')})
				});
			});*/
		}
        cleanAndReturnModal() {
            var self = this;
			self.cleanModal();
		}

        deleteVocabulary() {
            var self = this;
	    	function doDelete(){
	    		self.model.destroy = self.model.constructor.prototype.destroy;
	    		self.model.destroy({success:function(){
					var dataManager = DataManager.getDataManager();
					dataManager.removeArtifacts(self.model.id);
					dataManager.get('currentPlan').applyConcepts();
					window.utils.showSpinnerText('Saving Data ... ');
					dataManager.saveChangeSetToBackend(dataManager.get(dataManager.CURRENT_CHANGESET),function(response){
						dataManager.applyWorkspaceById(dataManager.get("currentWorkspaceId"),function(){
							window.utils.stopSpinner('deletePackage');					                	
						});
					});
					//Backbone.Relational.store._types['concept.BeepVocabulary']._collection.remove({id:self.model.id});	
	    			//DataManager.getDataManager().get('changedParentObjects').remove(self.model);
	    			//DataManager.getDataManager().get('changedObjects').remove(self.model);
                  self.cleanAndReturnModal();
                  //window.utils.stopSpinner('deletePackage');  
                },error:function(){
							console.log('Failed to remove package');
							DataManager.getDataManager().removeInitializedPackage(self.model.get('id'));
							self.cleanAndReturnModal();
							window.utils.stopSpinner('deletePackage');
				}});
        	}

			if(self.model.get('vocabularyLibrary').length>0){		
				bootbox.confirm(''+DataManager.getDataManager().get('localeManager').get('VocabularyLibDelete'), function(result) {
	                if(result){
	                	window.utils.startSpinner('deletePackage','Deleting Vocabulary Library Package...');
	                    setTimeout(doDelete,0);
	                }
	                else {
	                	self.cleanAndReturnModal();
	                }
	            });
			}
			else{
				setTimeout(doDelete,0);
			}
		};
		
        exportPackage(view, event) {
            var self = this;
			event.stopPropagation();
			//var suggestion = self.model.get('name').replace(/\"/g, "");
			window.utils.startSpinner('exportVocabulary', 'Exporting Vocabulary...');
			window.setTimeout(function () { 
				function createZip(model,callback){
					var text = JSON.stringify(model);
					var zip = new jszip();
					zip.file(self.model.get('id') + ".json", text);
					var manifestJson = window.utils.generateManifest(self.model);
					zip.file("manifest.json",manifestJson);
					zip.generateAsync({type:"blob"})
					.then(function(content) {
						callback(content);
					});
				}
                createZip(self.model, function (content) {	
					window.utils.stopSpinner('exportVocabulary');
					/*if(window.utils.checkChrome() && chrome.fileSystem && chrome.fileSystem.chooseEntry){
						chrome.fileSystem.chooseEntry({type: 'saveFile',suggestedName:suggestion,accepts:[{extensions:['vpk']}]}, function(f) {
							if (!chrome.runtime.lastError) {
							  f.remove(function() {
								  window.utils.saveCurrentEntry(f,content,'Vocabulary Library Package');
								}, function(e){
					    			console.log(e);
					    			bootbox.alert('Export error: '+e);
								});
							}
							else{
				    			console.log(chrome.runtime.lastError);
				    			if(chrome.runtime.lastError.message !== 'User cancelled') {
				    				bootbox.alert('Export error: '+chrome.runtime.lastError);
				    			}
							}
						});			
					}else {*/
						window.utils.stopSpinner('exportVocabulary');
						var exportMsg = DataManager.getDataManager().get('localeManager').get('exportComplete','Vocabulary');
                        window.utils.exportFileToSystem(self.model.get('name'),'VPK File',".vpk",content,exportMsg);
					//}
				});
			},100);
		}

		showobjectExplorerTree(){
		    var self = this;
			//console.log(JSON.stringify(self.model.toJSON()));
			var addOptions= this;
			var id = window.utils.htmlEscape(window.guidGenerator()) + self.encodeId;
			window.getAndCreateModalDialog(true,id,BeepVocabularyMixin,self.options.addOptions.model,"explorerModelJson",closeView,addOptions);
			function closeView(){
				window.cleanDialogModel(id,ValueDeliveryModelMixin);
			}
		};
        saveVocabularydetails() {
            var self = this;
	    	if(self.model){
	    		self.model.set('name',self.name());
	    		self.model.set('description',self.description());
	    	}
	    	self.cleanAndReturnModal();
		};
		
        cleanModal() {
            var self = this;
            var options = self.options;
            if (self.parentView && self.id()) {
                window.cleanDialogModel(self.encodeId, self);
            }
			options.callback(options.addOptions.searchValue);
		};

        afterRenderView(node, view) {
            var self = view;
			$("#vocPackageName"+self.encodeId).keyup(function (view) {
				var name = view.currentTarget.value.trim();
				var found = false;
	            if (name !== "") {    
	                for (var i = 0; i < self.packageList.length; i++) {
	                    if (self.packageList[i].label === name && self.packageList[i].id !== self.id()){
	                        found = true;
	                        $('.warning').removeClass('hide');
	 	                    $('#complete').prop("disabled", true);
	 	                    break;
	                    }
	                }
	                if(!found){
	                	$('.warning').addClass('hide');
	                	$('#complete').prop("disabled", false);
	                }
	            }
		    });
			window.utils.applyFontSizeInDialog();
		}

		submitToMarket() {
			var self = this;
			var dataManager = DataManager.getDataManager();
			var packDoc = dataManager.get("artifactsDocuments")[self.model.id];
			window.utils.startSpinner('checking','Checking...');
			dataManager.saveChangeSetToBackend(dataManager.get(dataManager.CURRENT_CHANGESET),function(response){
				dataManager.applyWorkspaceById(dataManager.get("currentWorkspaceId"),function(){
					dataManager.checkOwnedPackage(self.model,function(resp){
						window.utils.stopSpinner('checking');
						if(resp && self){
							/*if(resp.productId){
								self.productId = resp.productId;
							}*/
							if(resp.owner){
								var planId = dataManager.get('currentPlan').id;
								var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
								if(resp.marketplaceId){
									dataManager.navigateToCrmPage("workspace/"+currentWorkspaceId+"/submitted/"+resp.marketplaceId+"/"+packDoc.artifactId);
								} else {
									dataManager.navigateToCrmPage("workspace/"+currentWorkspaceId+"/"+packDoc.artifactId+"/marketplace");
								}
							}else {
								bootbox.alert("Package Submission is not allowed");
							}
							/*if(resp.published){
								self.publishedDate(new Date(resp.publishedDate).toDateString());
							}*/                     
						}
					});
				});
			});
			//var planDocVersion = planDoc ? planDoc.documentVersion : self.model.get('documentVersion');
		};
		
        init(model, options){
            var self = this;
            this.VocabularyPackageViewModel = this;
            this.model = model;
			this.canExport = ko.observable(true);
			this.canExport(DataManager.getDataManager().canExport(self.model));
            this.id = kb.observable(model, 'id');
            this.name = ko.observable(self.model ? self.model.get('name') : null);
            this.description = ko.observable(model ? model.get('description') : "");
            this.vocabularyLibColl = ko.observableArray([]);
            function htmlEscape(str) {
                return String(str)
                    .replace(/@/g, '')
                    .replace(/ /g, '');
            }
            $('#modal' + self.encodeId).on('shown.bs.modal', function () {
                window.utils.focusElement("#newModal .focus-ele");
            });
            this.name.subscribe(function (val) {
                self.description(val);
                if (val == '') {
                    $("#complete").attr('disabled', 'disabled');
                }
                else {
                    $("#complete").removeAttr('disabled');
                }
            });
            this.encodeId = htmlEscape(this.id());
            if (options && options.parentView) {
                this.parentView = options.parentView;
                this.encodeId = "" + options.modalId;
                this.packageList = options.packages;
            }
            this.cleanModal = _.bind(this.cleanModal, self);
            self.fillVocabularyModels();
            this.options = options;
			this.showMarketSubmit = ko.observable(false);
			this.showSwitch = ko.observable(false);
			if(!DataManager.getDataManager().get('readMode') && !window.utils.checkIsLinked(null,true) && this.canExport()){
				var roles = DataManager.getDataManager().get('serverRoles');
				var show = false;
                if((roles && roles.indexOf('LEGAL_ENTITY_ADMIN') != -1) || window.utils.isVMPUser()){
                    show = true;
                }
                if(!DataManager.getDataManager().isEnterpriseEdition()){
                    show = false;
                }
                self.showMarketSubmit(show);
			}
            this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), ['Alternative'
                , 'name'
                , 'description'
                , 'Close'
                , 'Delete'
                , 'Complete'
                , 'EditVDMLpackage'
                , 'VocabularyModels'
                , 'VocabularyLibraries'
                , 'Copy'
                , 'businessContext'
                , 'locale'
                , 'export'
                , 'exportVocabulary'
				, 'Publish'
			]);
        }
	
	static getInstance(model,options){
        var view= new VocabularyPackageViewModel(model, options);
        //view.init(model, options);
        return view;
	};
}
path.VocabularyPackageViewModel = VocabularyPackageViewModel;