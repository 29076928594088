import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as async from 'async'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {BMValuesChart} from '../chart/BMValuesChart'
import {BMGuidanceViewModel} from '../../../help/views/properties/BMGuidanceViewModel'
import {BMValueDetailsViewModel} from '../properties/BMValueDetailsViewModel'
import {BMDetailsViewModel} from '../properties/BMDetailsViewModel'
import {BusinessModelMixin} from '../../../../bo/vdml/BusinessModelMixin'
import {ValueElementMixin} from '../../../../bo/vdml/ValueElementMixin'
import {BMCanvasDiagramMixin} from '../../../../bo/canvas/BMCanvasDiagramMixin'
import * as Highcharts from 'highcharts';
//import theme from 'highcharts/themes/dark-unica';
//theme(Highcharts);
import {HighChartThemes} from '../../../../../com/vbee/utils/HighChartThemes'
import { BMCubeViewModel } from './BMCubeViewModel'
import { BMDetails2ViewModel } from '../properties/BMDetails2ViewModel'
/*define(["require","jquery","enhsplitter","underscore","backbone","async","knockout","knockoutMapping","knockback","bootbox","spin","appcommon/com/vbee/data/DataManager","app/global","appbo/vdml/BusinessModel","appviews/vdml/views/chart/BMValuesChart","appbo/vdml/BusinessModelMixin",
		"appviews/vdml/views/properties/BMDetailsViewModel","appbo/tickets/User","appviews/vdml/views/properties/BMValueDetailsViewModel","appviews/help/views/properties/BMGuidanceViewModel","appbo/canvas/BMCanvasDiagramMixin","appbo/vdml/ValueElementMixin","appcommon/com/vbee/utils/HighChartThemes", "highcharts-common"],
function(require,$,enhsplitter,_,Backbone,async,ko,koMapping,kb,bootbox,Spinner,DataManager,global,BusinessModel,BMValuesChart,BusinessModelMixin
, BMDetailsViewModel,User,BMValueDetailsViewModel,BMGuidanceViewModel,BMCanvasDiagramMixin,ValueElementMixin,HighChartThemes, Highcharts
){*/
	
    var path = DataManager.getDataManager().buildAppNsPath("vdml.views.bmcube", global.version);
    export class BMCube2ViewModel extends BMCubeViewModel {
    
        loadBusinessDetailComponent(){
            var self = this;
            self.bmDetails2ViewInstance = BMDetails2ViewModel.getInstance(this);
            if(!ko.components.isRegistered('BMDetailsWidget')){
                ko.components.register("BMDetailsWidget", {
                    viewModel: {instance:self.bmDetails2ViewInstance},
                    template: window.utils.getHtmlPage("BMDetails2Template"),
                });
            }
        }

    init(model, options){
        super.init(model,options);
    }

	static getInstance(model,options){
			var view = new BMCube2ViewModel(model, options);
			view.init(model, options);
			return view;
	};
}
path.BMCube2ViewModel = BMCube2ViewModel;
