import * as _ from 'underscore'
import { MeasurableElementMixin } from "./MeasurableElementMixin";
import {DataManager} from '../../../com/vbee/data/DataManager'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement } from './VdmlElement';

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

export class MeasurableElement2Mixin {
    static getMixinRelations(){
        var ret = _.union(MeasurableElementMixin.getMixinRelations(),[

        ])
        return ret;
    }
    
    static getCumulativeMixinRelations(){
        if (!MeasurableElement2Mixin.cummulativeRelations) {
            MeasurableElement2Mixin.cummulativeRelations = _.union(MeasurableElement2Mixin.getMixinRelations()
                ,DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
        return MeasurableElement2Mixin.cummulativeRelations.slice();
    }
}
utils.customExtendClass (MeasurableElement2Mixin,new MeasurableElementMixin());

path.MeasurableElement2Mixin = MeasurableElement2Mixin;