import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmElement} from './SmmElement'
import {SmmElementMixin} from './SmmElementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { BusinessIndicatorLibraryElement } from './BusinessIndicatorLibraryElement'

/*define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/smm/SmmElement", "appbo/smm/SmmElementMixin"],
    function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, SmmElement, SmmElementMixin
        , BusinessIndicatorLibraryElement) {*/

        var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
        if (!BusinessIndicatorLibraryElement) {
            var importPath = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            if (importPath.BusinessIndicatorLibraryElement) {
                BusinessIndicatorLibraryElement = importPath.BusinessIndicatorLibraryElement;
            }
            else {
                import('./BusinessIndicatorLibraryElement').then(({ default: BusinessIndicatorLibraryElement }) => {
                    BusinessIndicatorLibraryElement = BusinessIndicatorLibraryElement;
                });

                /*require(["appbo/smm/BusinessIndicatorLibraryElement"], function (loadedModule) {
                    BusinessIndicatorLibraryElement = loadedModule;
                });*/
            }
        }
        export class BusinessIndicatorLibraryMixin {
            /*constructor() {
                var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
                path.BusinessIndicatorLibraryMixin = BusinessIndicatorLibraryMixin;
            }*/
        defaults() {
            var ret = {
                type: "smm_BusinessIndicatorLibrary"
            }
            return jQuery.extend(SmmElement.prototype.defaults.apply(this), ret);
        }
            static getMixinRelations() {
                return _.union([
                    {
                        type: Backbone.HasMany,
                        containingClass: "smm_BusinessIndicatorLibrary",
                        key: "businessIndicatorLibraryElement",
                        relatedModel: "smm.BusinessIndicatorLibraryElement",
                        reverseRelation: {
                            key: "businessIndicatorLibraryElementOwner",
                            type: Backbone.HasOne,
                            includeInJSON: "id"
                        }
                    },
                    {
                        type: Backbone.HasMany,
                        containingClass: "smm_BusinessIndicatorLibrary",
                        key: "element",
                        relatedModel: "smm.BusinessIndicatorLibraryElement",
                        includeInJSON: "id"
                    }
                ])
            }
        static getCumulativeMixinRelations() {
            if (!BusinessIndicatorLibraryMixin.cummulativeRelations) {
                BusinessIndicatorLibraryMixin.cummulativeRelations = _.union(BusinessIndicatorLibraryMixin.getMixinRelations()
					, SmmElementMixin.getCumulativeMixinRelations()
				);
			}
            return BusinessIndicatorLibraryMixin.cummulativeRelations.slice();
		}
        static getSuperTypes() {
            return [
                "smm_SmmElement"
            ];
        }
        static getProperties() {
            return [
                { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
                { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" }
            ]
        }
        getParent() {
            var container;
            if (!container) {
                container = this.get("smmModel") ? this.get("smmModel") : this.previousAttributes().smmModel;
                if (container) {
                    return container;
                }
            }
            return this;
        }
        getPackagePath(path) {
            if (!path) {
                path = [];
            }
            var container;
            if (!container) {
                container = this.get("smmModel") ? this.get("smmModel") : this.previousAttributes().smmModel;
                if (container) {
                    path.unshift({ id: this.get('id'), name: this.get('name') });
                    return container.getPackagePath(path);
                }
            }
            return path;
        }
        getViewProperties(type) {
            return {
                templatePath: "views/businessIndicatorLibrary/views/properties/BusinessIndicatorLibraryTemplate.html",
                templateName: "BusinessIndicatorLibraryTemplate",
                viewTypeStr: "appviews/businessIndicatorLibrary/views/properties/BusinessIndicatorLibraryViewModel",
                tabId: "BusinessIndicatorLibraryView",
                tabName: "BusinessIndicatorLibrary"
            }
        }
       
		}
		 path.BusinessIndicatorLibraryMixin = BusinessIndicatorLibraryMixin;
        //return BusinessIndicatorLibraryMixin;
    //});