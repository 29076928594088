import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("concept",global.version);
	export class ConceptElementMixin {
	
	defaults(){
		var ret = {
			type: "concept_ConceptElement"
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!ConceptElementMixin.cummulativeRelations) {
            ConceptElementMixin.cummulativeRelations = _.union(ConceptElementMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return ConceptElementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" }
		]
	}
	getParent(){
		var container;
		return EObject.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return EObject.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/concept/views/properties/ConceptTemplate.html",
			templateName : "ConceptTemplate",
			viewTypeStr : "appviews/concept/views/properties/ConceptViewModel",
			tabId : "ConceptView",
			tabName: "Concept"
		}
	}
	
	//return ConceptElementMixin;
}
path.ConceptElementMixin = ConceptElementMixin;