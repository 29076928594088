import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmElement} from './SmmElement'
import {SmmElementMixin} from './SmmElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Observation } from './Observation'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/SmmElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,SmmElementMixin
, Observation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Observation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Observation){
			Observation = importPath.Observation;
		}
        else {
            import('./Observation').then(({ default: Observation }) => {
                Observation = Observation;
            });
            /*require(["appbo/smm/Observation"], function (loadedModule) {
                Observation = loadedModule;
			});*/
		}
	}
	export class ObservationScopeMixin {

	defaults(){
		var ret = {
			type: "smm_ObservationScope"
		}
		return jQuery.extend(SmmElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!ObservationScopeMixin.cummulativeRelations) {
            ObservationScopeMixin.cummulativeRelations = _.union(ObservationScopeMixin.getMixinRelations()
                , SmmElementMixin.getCumulativeMixinRelations()
            );
        }
		return ObservationScopeMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_SmmElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "scopeUri",type : "EString",defaultValue : "null",containingClass : "smm_ObservationScope" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("observation") ? this.get("observation") : this.previousAttributes().observation;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("observation") ? this.get("observation") : this.previousAttributes().observation;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/ObservationScopePropertiesTemplate.html",
			templateName : "ObservationScopePropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/ObservationScopeViewModel",
			tabId : "ObservationScopeView",
			tabName: "ObservationScope"
		}
        }
    }
	path.ObservationScopeMixin = ObservationScopeMixin;
	
	//return ObservationScopeMixin;
//});