import * as _ from 'underscore'

import {VdmlElement} from './VdmlElement'
import {ValueLibraryMixin} from './ValueLibraryMixin'
import {ValueLibrary2Mixin} from './ValueLibrary2Mixin'
import {ValueDefinition} from './ValueDefinition'
import {Value} from './Value'
import {ValueCategory} from './ValueCategory'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/ValueLibraryMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,ValueLibraryMixin
,Value
, ValueDefinition
, ValueCategory){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ValueDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueDefinition){
			ValueDefinition = importPath.ValueDefinition;
		}
		else{
			import('./ValueDefinition').then(loadedModule => {
				ValueDefinition = loadedModule;
			});
			/*require(["appbo/vdml/ValueDefinition"],function(loadedModule){
				ValueDefinition = loadedModule;
			});*/
		}
	}
	if (!Value) {
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
		if (importPath.Value) {
			Value = importPath.Value;
		}
		else {
			import('./Value').then(loadedModule => {
				Value = loadedModule;
			});
			/*require(["appbo/vdml/Value"], function (loadedModule) {
				Value = Value;
			});*/
		}
	}
	if(!ValueCategory){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueCategory){
			ValueCategory = importPath.ValueCategory;
		}
		else{
			import('./ValueCategory').then(loadedModule => {
				ValueCategory = loadedModule;
			});
			/*require(["appbo/vdml/ValueCategory"],function(loadedModule){
				ValueCategory = loadedModule;
			});*/
		}
	}
	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? ValueLibrary2Mixin : ValueLibraryMixin;
	}
	var valueLibraryMixinClass = getMixinClass();
	var ValueLibrary = VdmlElement.extend(utils.customExtends({
			relations: valueLibraryMixinClass.getMixinRelations(),
			initialize: function (attributes, options) {
				if (this.isNew()) {
					this.set('version', DataManager.getDataManager().get('currentVDMVersion'));
				}
				VdmlElement.prototype.initialize.apply(this, arguments);
			}
	    }
        , new valueLibraryMixinClass()
	));
	utils.customExtendStaticMethods(ValueLibrary,valueLibraryMixinClass);
	ValueLibrary.getName = function () {
		return "ValueLibrary";
	};
	ValueLibrary.prototype.getSystemTags = function () {
		return ['VDM', 'Value Library', 'Admin'];
	};
	ValueLibrary.getInstance = function (name, description, vdm, id) {
		return ValueLibrary.getMyInstance(ValueLibrary,name,description,vdm,id);
	};
	
	ValueLibrary.abstract = false;
	ValueLibrary.supported = true;
	path.ValueLibrary = ValueLibrary;
	export {ValueLibrary};
//});