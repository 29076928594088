import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {ConceptElement} from './ConceptElement'
import {Vocabulary} from './Vocabulary'
import {VocabularyLibraryMixin} from './VocabularyLibraryMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/concept/ConceptElement","appbo/concept/Vocabulary","appbo/concept/VocabularyLibraryMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,ConceptElement,Vocabulary,VocabularyLibraryMixin
//){
	
	var path = DataManager.getDataManager().buildAppNsPath("concept",global.version);
	var VocabularyLibrary = ConceptElement.extend(utils.customExtends({
		relations:VocabularyLibraryMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			if(this.isNew()){
				this.set('version',DataManager.getDataManager().get('currentVDMVersion'));	
			}			
			ConceptElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new VocabularyLibraryMixin()
	));
//#startCustomMethods			
	VocabularyLibrary.prototype.getSystemTags = function(){
		return ['Vocabulary Library','VDM','Admin'];
	};
//#endCustomMethods
	VocabularyLibrary.getName = function(){
		return "VocabularyLibrary";
	};
	
	
	VocabularyLibrary.abstract = false;
	path.VocabularyLibrary = VocabularyLibrary;
	//return VocabularyLibrary;
	export {VocabularyLibrary};
//});