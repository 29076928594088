import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DirectMeasurement} from './DirectMeasurement'
import {CountingMeasurementMixin} from './CountingMeasurementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DirectMeasurement","appbo/smm/CountingMeasurementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DirectMeasurement,CountingMeasurementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var CountingMeasurement = DirectMeasurement.extend(utils.customExtends({
		relations:CountingMeasurementMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			DirectMeasurement.prototype.initialize.apply(this, arguments);
		}
		}
		, new CountingMeasurementMixin()
	));
	
	CountingMeasurement.abstract = false;
	CountingMeasurement.supported = false;
	path.CountingMeasurement = CountingMeasurement;
	export {CountingMeasurement};
//});