import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BaseMeasurementRelationship} from './BaseMeasurementRelationship'
import {Base1MeasurementRelationshipMixin} from './Base1MeasurementRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { DimensionalMeasurement } from './DimensionalMeasurement'
import { BinaryMeasurement } from './BinaryMeasurement'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/BaseMeasurementRelationship","appbo/smm/Base1MeasurementRelationshipMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BaseMeasurementRelationship,Base1MeasurementRelationshipMixin
//, DimensionalMeasurement
//, BinaryMeasurement){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!DimensionalMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasurement){
			DimensionalMeasurement = importPath.DimensionalMeasurement;
		}
        else {
            import('./DimensionalMeasurement').then(({ default: DimensionalMeasurement }) => {
                DimensionalMeasurement = DimensionalMeasurement;
            });

			/*require(["appbo/smm/DimensionalMeasurement"],function(loadedModule){
				DimensionalMeasurement = loadedModule;
			});*/
		}
	}
	if(!BinaryMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.BinaryMeasurement){
			BinaryMeasurement = importPath.BinaryMeasurement;
		}
        else {
            import('./BinaryMeasurement').then(({ default: BinaryMeasurement }) => {
                BinaryMeasurement = BinaryMeasurement;
            });

			/*require(["appbo/smm/BinaryMeasurement"],function(loadedModule){
				BinaryMeasurement = loadedModule;
			});*/
		}
	}
	var Base1MeasurementRelationship = BaseMeasurementRelationship.extend(utils.customExtends({
		relations:Base1MeasurementRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BaseMeasurementRelationship.prototype.initialize.apply(this, arguments);
			//this.on("change:to",this.onToRemoved);
		}
		}
		, new Base1MeasurementRelationshipMixin()
    ));
    Base1MeasurementRelationship["change:to"] = function (model, newTo, options) {
        this.onToRemoved(model, newTo, options);
    }
	Base1MeasurementRelationship.abstract = false;
	//Base1MeasurementRelationship.supported = false;	// as we are not using it yet
	path.Base1MeasurementRelationship = Base1MeasurementRelationship;
	export {Base1MeasurementRelationship};
//});