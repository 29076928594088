import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasure} from './DimensionalMeasure'
import {MeasureRelationship} from './MeasureRelationship'
import {MeasureRelationshipMixin} from './MeasureRelationshipMixin'
//import {RescaledMeasure} from './RescaledMeasure'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasureRelationship","appbo/smm/MeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasureRelationship,MeasureRelationshipMixin
, RescaledMeasure
, DimensionalMeasure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	//if(!RescaledMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RescaledMeasure){
			RescaledMeasure = importPath.RescaledMeasure;
		}
		else{
			import('./RescaledMeasure').then(({ default: RescaledMeasure }) => {
				RescaledMeasure = RescaledMeasure;
			});
			/*require(["appbo/smm/RescaledMeasure"],function(loadedModule){
				RescaledMeasure = loadedModule;
			});*/
		}
	//}
	if(!DimensionalMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasure){
			DimensionalMeasure = importPath.DimensionalMeasure;
		}
		else{
			import('./DimensionalMeasure').then(({ default: DimensionalMeasure }) => {
				DimensionalMeasure = DimensionalMeasure;
			});
			/*require(["appbo/smm/DimensionalMeasure"],function(loadedModule){
				DimensionalMeasure = loadedModule;
			});*/
		}
	}
	export class RescaledMeasureRelationshipMixin {
	
	defaults(){
		var ret = {
			type: "smm_RescaledMeasureRelationship"
		}
		return jQuery.extend(MeasureRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!RescaledMeasureRelationshipMixin.cummulativeRelations) {
            RescaledMeasureRelationshipMixin.cummulativeRelations = _.union(RescaledMeasureRelationshipMixin.getMixinRelations()
                , MeasureRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return RescaledMeasureRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_MeasureRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "influence",type : "Influence",defaultValue : "null",containingClass : "smm_MeasureRelationship" }
		]
	}
	getParent(){
		var container;
		return MeasureRelationship.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return MeasureRelationship.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/RescaledMeasureRelationshipPropertiesTemplate.html",
			templateName : "RescaledMeasureRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/RescaledMeasureRelationshipViewModel",
			tabId : "RescaledMeasureRelationshipView",
			tabName: "RescaledMeasureRelationship"
		}
	}
	
	}
	path.RescaledMeasureRelationshipMixin = RescaledMeasureRelationshipMixin;
	//return RescaledMeasureRelationshipMixin;
//});