import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {LookupTable} from './LookupTable'
import {LookupTableMixin} from './LookupTableMixin'
import {MeasurableElement} from '../vdml/MeasurableElement'
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("lookup",global.version);

export class PatternLookupTableDefinitionMixin {

    defaults(){
        var ret = {
            type: "lookup_PatternLookupTableDefinition"
        }
        return jQuery.extend(LookupTable.prototype.defaults.apply(this),ret);
    }
    static getMixinRelations(){
        return _.union([
            {
                type: Backbone.HasMany,
                containingClass: "lookup_PatternLookupTableDefinition",
                key: "measurableElement",
                relatedModel: "vdml.MeasurableElement",
                includeInJSON: Backbone.Model.prototype.idAttribute,
                reverseRelation: {
                    key: "patternDefinition",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            }
        ])
    }
    static getCumulativeMixinRelations(){
        if (!PatternLookupTableDefinitionMixin.cummulativeRelations) {
            PatternLookupTableDefinitionMixin.cummulativeRelations = _.union(PatternLookupTableDefinitionMixin.getMixinRelations()
                , LookupTableMixin.getCumulativeMixinRelations()
            );
        }
        return PatternLookupTableDefinitionMixin.cummulativeRelations.slice();
    }
    static getSuperTypes(){
        return [
            "lookup_LookupTable"
        ];
    }
    static getProperties(){
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            {name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
            {name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
        ]
    }
    getParent(){
        return LookupTable.prototype.getParent.apply(this, arguments);
    }
    getPackagePath(path){
        if(!path){
            path = [];
        }
        return LookupTable.prototype.getPackagePath.apply(this, arguments);
    }
    getViewProperties(type){
        return {
            templatePath : "views/vdml/views/properties/PatternLookupTableDefinitionPropertiesTemplate.html",
            templateName : "PatternLookupTableDefinitionPropertiesTemplate",
            viewTypeStr : "appviews/vdml/views/properties/PatternLookupTableDefinitionViewModel",
            tabId : "PatternLookupTableDefinitionView",
            tabName: "PatternLookupTableDefinition"
        }
    }

}
path.PatternLookupTableDefinitionMixin = PatternLookupTableDefinitionMixin;
