import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasure} from './DimensionalMeasure'
import {DimensionalMeasureMixin} from './DimensionalMeasureMixin'
import {Accumulator} from './Accumulator'
import { DataManager } from '../../../com/vbee/data/DataManager'
import {BaseNMeasureRelationship} from './BaseNMeasureRelationship'
import {Operation} from './Operation'

/*define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/smm/DimensionalMeasure", "appbo/smm/DimensionalMeasureMixin", "appbo/smm/Accumulator"],
function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, DimensionalMeasure, DimensionalMeasureMixin, Accumulator
, BaseNMeasureRelationship
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!BaseNMeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.BaseNMeasureRelationship){
			BaseNMeasureRelationship = importPath.BaseNMeasureRelationship;
		}
		else{
            import('./BaseNMeasureRelationship').then(({ default: BaseNMeasureRelationship }) => {
                BaseNMeasureRelationship = BaseNMeasureRelationship;
            });

            /*require(["appbo/smm/BaseNMeasureRelationship"], function (loadedModule) {
                BaseNMeasureRelationship = loadedModule;
            });*/
		}
	}
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
        else {
            import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });
			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	export class CollectiveMeasureMixin {
        /*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            path.CollectiveMeasureMixin = CollectiveMeasureMixin;
        }*/
	defaults(){
		var ret = {
			type: "smm_CollectiveMeasure"
		}
		return jQuery.extend(DimensionalMeasure.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"smm_CollectiveMeasure",
			key:"baseMeasureTo",
			relatedModel:"smm.BaseNMeasureRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"from",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"smm_CollectiveMeasure",
			key:"customAccumulator",
			relatedModel:"smm.Operation",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!CollectiveMeasureMixin.cummulativeRelations) {
            CollectiveMeasureMixin.cummulativeRelations = _.union(CollectiveMeasureMixin.getMixinRelations()
                , DimensionalMeasureMixin.getCumulativeMixinRelations()
            );
        }
		return CollectiveMeasureMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_DimensionalMeasure"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "scale",type : "ScaleOfMeasurement",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "customScale",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "source",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "visible",type : "EBoolean",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "formula",type : "EString",defaultValue : "null",containingClass : "smm_DimensionalMeasure" },
			{name : "accumulator",type : "Accumulator",defaultValue : "null",containingClass : "smm_CollectiveMeasure" }
		]
	}
	getParent(){
		var container;
		return DimensionalMeasure.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return DimensionalMeasure.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/CollectiveMeasurePropertiesTemplate.html",
			templateName : "CollectiveMeasurePropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/CollectiveMeasureViewModel",
			tabId : "CollectiveMeasureView",
			tabName: "CollectiveMeasure"
		}
	}
//#startCustomMethods
	measureUsesPreviousAltRelation(){
		var mesRels = this.get('baseMeasureTo');
		for(var i=0;i<mesRels.length;i++){
			var rel = mesRels.at(i);
			if(rel.get('usePreviousAlternative')){
				return true;
			}
		}
		return false;
	};
	constructSimpleBaseMeasureTuple(measuredCharacteristic,baseMeasure,usePreviousAlternative){
		var tuple = {};
		var comp = null;
		if(measuredCharacteristic){
			comp = measuredCharacteristic.get('valueMeasurementOwner');
			if(!comp){
				comp = measuredCharacteristic.previousAttributes().valueMeasurementOwner;
			}
		}
		tuple.new = {};	
		var newNode = tuple.new;
		newNode.component = comp;
		newNode.measure = baseMeasure;	
		newNode.measuredCharacteristic = measuredCharacteristic;
		newNode.usePreviousAlternative = usePreviousAlternative;
		newNode.offset = 0;
		newNode.multiplier = 1;
		newNode.operation = null;
		return tuple;
	}
	constructBaseMeasureTuple(measuredCharacteristic,baseMeasure,newOffset,newMultiplier,newOperation,usePreviousAlternative,isDeleted,isNewTuple,baseMeasureRel){
		var tuple = {};
		if(!isNewTuple){
			tuple.old = {};	
		}
		
		var comp = null;
		if(measuredCharacteristic){
			comp = measuredCharacteristic.get('valueMeasurementOwner')?measuredCharacteristic.get('valueMeasurementOwner'):measuredCharacteristic.get('satisfactionLevelOwner');
			if(!comp){
				comp = measuredCharacteristic.previousAttributes().valueMeasurementOwner;
			}
		}
		if(isNewTuple || (!isDeleted && comp)){
			tuple.new = {};	
		}
		var oldNode = tuple.old;
		var newNode = tuple.new;
		var bmRel;
		if(baseMeasureRel){
			bmRel = baseMeasureRel;
		}else{
			var baseMeasureRels = this.get('baseMeasureTo');
			baseMeasureRels.each(function(rel){
				if(rel.get('to') === baseMeasure){
					bmRel = rel.get('to');
				}
			});
			if(!bmRel && baseMeasure){
				return;
			}
		}
		if(oldNode){
			oldNode.component = comp;
			oldNode.measure = baseMeasure;
			oldNode.usePreviousAlternative = usePreviousAlternative;
			oldNode.measuredCharacteristic = measuredCharacteristic;
			oldNode.baseMeasureRel = bmRel;
		}
		if(isNewTuple || (!isDeleted && comp)){
			newNode.component = comp;
			newNode.measure = baseMeasure;	
			newNode.measuredCharacteristic = measuredCharacteristic;
			newNode.usePreviousAlternative = usePreviousAlternative;
			newNode.baseMeasureRel = bmRel;
		}
		var inLinedRescaledMes = bmRel ? bmRel.get('rescaledMeasure') : null;
		if(inLinedRescaledMes){
			var offs = inLinedRescaledMes.get('offset');
			var multiplier = bmRel.get('multiplier');
			var operation = bmRel.get('operation');
			if(newOffset){
				offs = newOffset;
			}
			if(newMultiplier){
				multiplier = newMultiplier;
			}
			if(newOperation){
				operation = newOperation.get('name');
			}
			if(oldNode){
				oldNode.offset = offs;
				oldNode.multiplier = multiplier;
				oldNode.operation = operation;
			}
			if(isNewTuple || (!isDeleted && comp)){
				newNode.offset = offs;
				newNode.multiplier = multiplier;
				newNode.operation = operation;
			}
		}else{
			if(oldNode){
				oldNode.offset = newOffset ? newOffset : 0;
				oldNode.multiplier = newMultiplier ? newMultiplier : 1;
				oldNode.operation = newOperation ? newOperation.get('name') : null;
			}
			if(isNewTuple || (!isDeleted && comp)){
				newNode.offset = newOffset ? newOffset : 0;
				newNode.multiplier = newMultiplier ? newMultiplier : 1;
				newNode.operation = newOperation ? newOperation.get('name') : null;
			}
		}
		return tuple;
	};
	
 	hasBaseMeasure(baseMeasure){
 		var rels = this.get('baseMeasureTo');
 		var ret = false;
 		rels.each(function(rel){
 			if(rel.get('to') === baseMeasure){
 				ret = true;
 			}
 		});
 		return ret;
 	}	
 	
 	checkAccumulatorForNull(measure){
		if(Backbone.skipPersistance){
			return;
		} 		
 		var self = this;
		if(!measure.get('accumulator') && measure.get('type')==="smm_CollectiveMeasure"){
			console.log("accumulator null for "+measure.get('name'));
			self.set('accumulator',Accumulator.symbols()[0]);
		}
	};

	createBaseMeasureRelationship(baseMeasure){
		var baseId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var name = baseMeasure.get('name');
		var trait = baseMeasure.get('trait');
		if(trait){
			name = trait.get('name');		
		}
		//var bmrel = new BaseNMeasureRelationship({id:baseId,name:this.getUniqueProperty('name',baseMeasure.get('name')+ " BaseNMeasureRelationship",'measureRelationships'),'measure':this});
		var bmrel = new BaseNMeasureRelationship({id:baseId,name:this.getUniqueProperty('name',name + " Measure Relationship",'measureRelationships'),'measure':this,'to': baseMeasure,'from': this});
		//bmrel.set('from', this);
		//bmrel.set('to', baseMeasure);
		return bmrel;
	}
    getMeasureDetails() {
        var self = this;
        //var measurementValues = [], weightMeasurementValues = [];
        var valueFormula = {};
        var oldValue = self.get('value');
        var baseMeasureRels = self.get('baseMeasureTo');
        valueFormula.id = self.id;
        var expression = "measure(";
        valueFormula.name = self.get('name');
        valueFormula.relations = {};
        var relationExps = [];
        var relations = valueFormula.relations;

        baseMeasureRels.each(function (baseMeasureRel) {
            var baseMeasure = baseMeasureRel.get('to');
            if (!baseMeasure) {
                return;
            }
            var relExp = self.getExpressionForRelation(baseMeasureRel);
            if (relExp) {
                relationExps.push(relExp);
            }
            var rescaledMeasure = baseMeasureRel.get('rescaledMeasure');
            var multiplier = 1;
            var offset = 0;
            var operation;
            if (rescaledMeasure) {
                multiplier = parseFloat(rescaledMeasure.get('multiplier') || 1);
                offset = parseFloat(rescaledMeasure.get('offset') || 0);
                operation = rescaledMeasure.get('operation');
            }
            //valueFormula = "(" + baseMeasure.id + " * (" + multiplier + ") + (" + offset + "))";
            relations[baseMeasureRel.id] = {};
            relations[baseMeasureRel.id].multiplier = multiplier;
            relations[baseMeasureRel.id].offset = offset;
            if (operation && operation.get('name')) {
                relations[baseMeasureRel.id].operation = operation.get('name');
            }

        });
        valueFormula.accumulator = self.getAccumulator();
        if (valueFormula.accumulator === "6.0") {
            valueFormula.accumulator = "0.0";
        }
        expression = expression + valueFormula.accumulator;
        for (var i = 0; i < relationExps.length; i++) {
            expression = expression + "," + relationExps[i];
        }
        expression = expression + ")";
        valueFormula['expression'] = expression;
        return valueFormula;
    };
    getAccumulator() {
        var measure = this;
        var accumulator = measure.get('accumulator');
        if (!measure.get('accumulator') && measure.get('type') === "smm_CollectiveMeasure" && measure.get('measureRelationships').length > 0) {
            measure.set('accumulator', Accumulator.symbols()[0]);
            accumulator = Accumulator.symbols()[0];
            var measuredCharacteristic = measure.get('measuredCharacteristic');
            var vp = measuredCharacteristic ? measuredCharacteristic.getParent() : null;
            if (vp && vp.checkOVS && vp.checkOVS()) {
                //accumulator = Accumulator.symbols()[6];
                measure.set('accumulator', Accumulator.symbols()[6]);
            }
        }
        if (accumulator && !accumulator.name && measure.get('type') === "smm_CollectiveMeasure" && measure.get('measureRelationships').length > 0) {
            accumulator = _.filter(Accumulator.symbols(), function (sym) { if (sym.name === accumulator) { return sym } })[0];
        }
        switch (accumulator.name) {
            case "sum":
                return "0.0";
            case "product":
                return "1.0";
            case "average":
                return "2.0";
            case "maximum":
                return "3.0";
            case "minimum":
                return "4.0";
            case "standardDeviation":
                return "5.0";
            default:
                return "6.0";
        }
    }
    getExpressionForRelation(baseMeasureRel) {
        var self = this;
        //var measurementValues = [], weightMeasurementValues = [];
        var baseMeasure = baseMeasureRel.get('to');
        if (!baseMeasure) {
            return null;
        }
        var rescaledMeasure = baseMeasureRel.get('rescaledMeasure');
        var multiplier = 1;
        var offset = 0;
        var operation;
        if (rescaledMeasure) {
            multiplier = parseFloat(rescaledMeasure.get('multiplier') || 1);
            offset = parseFloat(rescaledMeasure.get('offset') || 0);
            operation = rescaledMeasure.get('operation');
        }
        var operationVal;
        if (operation && operation.get('name')) {
            switch (operation.get('name')) {
                case "Reciprocal":
                    operationVal = "0.0";
                    break;
                case "Square":
                    operationVal = "1.0";
                    break;
                case "Square root":
                    operationVal = "2.0";
                    break;
                default:
                    operationVal = "3.0";
                    break;
            }
        } else {
            operationVal = "3.0";
        }
        return "measureRelation(" + multiplier + "," + offset + "," + operationVal + "," + baseMeasure.id + ")";
    };
//#endCustomMethods
	
	}
	path.CollectiveMeasureMixin = CollectiveMeasureMixin;
	//return CollectiveMeasureMixin;
//});