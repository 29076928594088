import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AbstractMeasureElement} from './AbstractMeasureElement'
import {OCLOperationMixin} from './OCLOperationMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/AbstractMeasureElement","appbo/smm/OCLOperationMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AbstractMeasureElement,OCLOperationMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var OCLOperation = AbstractMeasureElement.extend(utils.customExtends({
		relations:OCLOperationMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			AbstractMeasureElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new OCLOperationMixin()
	));
	
	OCLOperation.abstract = false;
	OCLOperation.supported = false;
	path.OCLOperation = OCLOperation;
	export {OCLOperation};
//});