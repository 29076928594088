import { DataManager } from '../../../com/vbee/data/DataManager'
import {Enum} from '../../../../libs/enums/enums'

//define(["require","appcommon/com/vbee/data/DataManager","app/global","enums" ], 
//function(require,DataManager,global){
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!path.Accumulator){
		var Accumulator = new Enum("sum","maximum","minimum","average","product","standardDeviation","WeightedAverage");
		path.Accumulator = Accumulator;
	}
	export {Accumulator}
//});