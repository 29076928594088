import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { Role } from './Role'
import { PerformerMixin } from './PerformerMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Role","appbo/vdml/PerformerMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Role,PerformerMixin){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var Performer = Role.extend(utils.customExtends({
		relations:PerformerMixin.getMixinRelations(),
		subModelTypes: {
			vdml_ProcessRole : 'vdml.ProcessRole',
			vdml_CaseRole : 'vdml.CaseRole',
			vdml_ApplicationRole : 'vdml.ApplicationRole'
		},
		initialize: function(attributes, options) {
			Role.prototype.initialize.apply(this, arguments);
		}
		}
		, new PerformerMixin()
	));
	
	Performer.abstract = false;
	path.Performer = Performer;
    export { Performer };
//});