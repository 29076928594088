import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {StrategyMapDiagramMixin} from './StrategyMapDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/CollaborationDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,CollaborationDiagramMixin
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class StrategyMapDiagram2Mixin {

		getMixinClass(){
			return StrategyMapDiagram2Mixin;
		}
		
		getViewProperties(type){
			return {
				templatePath : "views/strategyMap/views/designer/StrategyMapDesignerTemplate.html",
				templateName : "StrategyMapDesignerTemplate",
				viewTypeStr : "appviews/strategyMap/views/designer/StrategyMapDesigner2ViewModel",
				tabId : "StrategyMapDesignerView",
				tabName: "StrategyMapDesigner"
			}
		}
	}
	utils.customExtendClass (StrategyMapDiagram2Mixin,new StrategyMapDiagramMixin());

	path.StrategyMapDiagram2Mixin = StrategyMapDiagram2Mixin;