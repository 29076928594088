import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import { OptimizationType } from '../../../../bo/vdml/OptimizationType'
import { ValueType } from '../../../../bo/vdml/ValueType'
import * as monaco from 'monaco-editor';
import { DashboardScenariosViewModel } from '../../../dashboard/views/chart/DashboardScenariosViewModel'
/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global","appbo/vdml/ValueDeliveryModel","appbo/vdml/BusinessModel","appbo/vdml/ValueStream","typeahead","appbo/vdml/ValueDeliveryModelMixin", "summernote"],
function(require,$,_, Backbone,ko, koMapping,kb,bootbox,DataManager,global,ValueDeliveryModel,BusinessModel,ValueStream,typeahead,ValueDeliveryModelMixin
){*/
    
    var path = DataManager.getDataManager().buildAppNsPath("scenario.views.properties",global.version);
    
    export class OptimizationViewModel{
        
		
    afterRenderView(node, view){
        var self = view;
        self.initialiseMonaco();
    }
          
    cleanModalHandler() {
        var self = this;
        window.cleanDialogModel(self.encodeId, this);
    }
        
    init(model, options){
		var self = this;
        this.OptimizationViewModel = this;
        this.model = model;
        self.model = this.model;
        this.id = ko.observable(self.model.get('id'));
        this.encodeId = options.modalId;
        this.name = ko.observable(self.model.get('name'));
        this.showBounds = ko.observable(false);
        this.boundSummaryDetails = ko.observableArray([]);
        self.loadBoundSummaryDetails();
        this.formula = ko.observable("");
        this.expBuilderOptions = { variables: [], expression: ""};
        this.defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: self.model.get("defaultExecutionScenario") });
        this.scName = ko.observable(this.defaultExecutionScenario.get('name'));
        this.lastCalculated = ko.observable();
        var calcResults = DataManager.getDataManager().get('calcInfo');
        if(calcResults && calcResults.modelChange && calcResults.modelChange.lastCalculated){
            var modelChange = calcResults.modelChange;
            var lastCalc = new Date(modelChange.lastCalculated).toLocaleDateString() + " "+ new Date(modelChange.lastCalculated).toLocaleTimeString();
            self.lastCalculated(lastCalc);
        }
        this.cleanModal = _.bind(self.cleanModalHandler, self);
        this.enableOptmize = ko.observable(true);
        this.formulaErrorMsg = ko.observable();
        this.enableConfigDiv = ko.observable(false);
        this.configColl = ko.observableArray([]);
        this.optimizationTypeOptions = OptimizationType.symbols();
        this.optimizationType = ko.observable(self.model.get('optimizationType'));
        this.agrigatedValueOptions = self.getAllValues();
        this.originalExp = "";
        this.objType = self.model.get('optimizationType');
        this.configErrorMsg = ko.observable();
        this.configValue = ko.observable();
        this.configParam = ko.observable();
        this.configOptions = ko.observableArray([]);
        this.showExistingConfigs();
        self.fillConfigOptions();
        this.fillFormula();
        this.editConfig = _.bind(self.editConfig, self);
		this.deleteConfig = _.bind(self.deleteConfig, self);
        //this.agrigatedValue = ko.observable((self.model && self.model?.get('optimizationObjective')) ? self.model?.get('optimizationObjective').get('expressionStr'): null);
        //this.isOptimizationType = ko.observable(self.optimizationType() ? true : false);
        self.optimizationType.subscribe((val)=>{
            if(val === undefined || val === null){
                //self.isOptimizationType(false);
                //self.agrigatedValue(null);
                self.enableOptmize(false);
            } else {
                self.enableOptmize(true);
                //self.isOptimizationType(true);
            }
        });

        self.configValue.subscribe((val)=>{
            if(val != '' && val != null && self.configParam() != null){
                self.addToConfig();
                self.enableConfigDiv(false);
            } else {
                
            }
        });

		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['Close'
        ,'Optimize'
        , 'OptimizationType'
        , 'OptimizationObjective'
        , 'Maximize'
        , 'Minimize'
        , 'Objective'
        , 'type'
        ,'Complete'
        , 'MinBound'
        , 'MaxBound'
        , 'EqualValue'
        , 'value'
        , 'addAnother'
        ]);
        for (var i = 0; i < self.optimizationTypeOptions.length; i++) {
            self.optimizationTypeOptions[i].description =
                self.labels[self.optimizationTypeOptions[i].name]();
        }
	}

    showExistingConfigs(){
        var self = this;
        var formula = self.model.get('optimizationObjective');
        var expConfig = formula ? formula.get("expressionConfig") : null;
        var expConfigJson = expConfig ? JSON.parse(expConfig) : null;
        if(expConfigJson && expConfigJson.modalConfig){
            var modalConfig = expConfigJson.modalConfig;
            for (const [name, variable] of Object.entries(modalConfig)) {
                //for(var i=0;i<self.configOptions().length; i++){
                //    if(self.configOptions()[i].name == name){
                        self.configColl.push({name:name,value:variable});
                        //break;
                   // }
                //}
            }
        }
    }

    fillConfigOptions(){
        var self = this;
        var juliaList = ['limits/time','limits/gap','limits/absgap','limits/primal','limits/dual'];
        var configList = [];
        _.each(juliaList,function(val){
            var found = false;
            for(var i=0;i<self.configColl().length; i++){
                if(self.configColl()[i].name == val && !self.configColl()[i]._destroy){
                    found = true;
                    break;
                }
            }
            if(!found){
                configList.push({'id':configList.length,'name':val});
            }
        });
        self.configOptions(configList);
    }

    addToConfig(){
        var self = this;
        self.configColl.push({name:self.configParam(),value:self.configValue()});
        self.configValue('');
        self.fillConfigOptions();
    }

    showModalConfigDiv() {
        var self = this;
        self.enableConfigDiv(true);
    }

    deleteConfig(view,event) {
        var self = this;
        self.configColl.destroy(view);
        self.fillConfigOptions();
    }

    editConfig(view,event){
        var self = this;
        self.enableConfigDiv(true);
        for(var i=0;i<self.configColl().length;i++){
            if(self.configColl()[i].name == view.name){
                self.configValue(self.configColl()[i].value);
                self.configParam(self.configColl()[i].name);
                break;
           }
        }
    }

    loadBoundSummaryDetails(){
        var self = this;
        var boundSummaryArray = [];
        _.each(self.model.get("step").models,function(step){
            _.each(step.get('constraints').models,function(cons){
                var maxBound = cons.get("maxBound");
                var minBound = cons.get("minBound");
                var equalValue = cons.get("equalValue");
                var valId = cons.get("valueId");
                var valueObj = window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']);
                var valueName = valueObj ? valueObj.get('name') : valId;
                boundSummaryArray.push({'name':valueName,'maxBound':maxBound,'minBound':minBound,'equalValue':equalValue});
            });
        });
        self.boundSummaryDetails(boundSummaryArray);
        if(boundSummaryArray.length > 0){
            self.showBounds(true);
        }
    }

    fillFormula(){
        var self = this;
        var formula = self.model.get('optimizationObjective');
        var exp = formula ? formula.get("expressionStr") : null;
        self.originalExp = exp;
        var agg  = self.expBuilderOptions.variables.map((d)=>{return {...d,variableId:window.utils.htmlEscape(d.variableId)}});
        const updatedExpression = utils.replaceIdsWithNames(exp, agg);
        if(updatedExpression){
            self.formula(updatedExpression);
        }
    }

    /*genOptmizedExpression(expresionStr){
        var self = this;
        //var aggregatedFromValues = self.agrigatedValueOptions;
        var scenario = self.model;
        var dataManager = DataManager.getDataManager();
        var plan = dataManager.get('currentPlan');
        let escValId = plan.htmlEscape(scenario);

        expresionStr = expresionStr.replaceAll("currentPeriod()","currentPeriod(t)");
        expresionStr = expresionStr.replaceAll("previousPeriod()","previousPeriod(t)");
        const pattern = /indexYearPeriod\((.*?),\s*(.*?)\)/g;
        // Replacement string
        const replacement = 'indexYearPeriod('+ scenario.get("startTime") + '$1, $2, \"' + plan.get("periodKind") + '\")';
        expresionStr = expresionStr.replace(pattern,replacement);
        let result = plan.getJuliaExpr(expresionStr);
        if(!result.containsIfElse && !result.containsTernary){
            result.code =  plan.getConstraintStr(result.code) +  "(pm.model, " + escValId + "[t] == " + result.code + ")"
        }
        var urlEncodeString = encodeURI(result.code);
        return urlEncodeString;
    }*/

    initialiseMonaco() {
        var self = this;
        //var autoSuggester = autosuggest.autosuggester(ExprLangLexer, ExprLangParser,"LOWER");
        utils.setMonacoEnv();
        //var pack = self.parentView.get('type') === "vdml_BusinessModel" ? self.parentView.getNestedParent() : DataManager.getDataManager().get('currentPlan')
        var value = self.formula() ? self.formula() : ''; 
        const monacoBuilder = document.getElementById('formula' + self.encodeId);
        if(monacoBuilder){
            self.editor = monaco.editor.create(monacoBuilder, {
                value,
                automaticLayout: true,
                lineNumbers: 'off',
                minimap: { enabled: false },
                language: 'exprLang',
                overviewRulerLanes: 0,
                scrollbar: {
                    vertical:"hidden",
                    horizontal: "hidden",
                    handleMouseWheel:true,
                },
                wordWrap: 'on'
            });
            self.editor.onDidChangeModelContent((event) => {
                const newValue = self.editor.getValue();
                self.formula(newValue);
                /*const newText = event.changes[0]?.text;
                if (newText) {
                    self.addAggregateIfNeeded(newText,flattenedAggrOptions);
                    self.validateExp = true;
                }*/
            });
            self.editor.updateOptions({
                quickSuggestions: {
                    other: true,
                    comments: false,
                    strings: true
                }
            });
        }
        monaco.languages.register({ id: 'exprLang' });
        this.completionItemProvider =  monaco.languages.registerCompletionItemProvider('exprLang', {
            triggerCharacters: ['.', '(', '[', '?'],
            provideCompletionItems: function(model, position) {
                const word = model.getWordUntilPosition(position);
                const range = new monaco.Range(
                    position.lineNumber,
                    word.startColumn,
                    position.lineNumber,
                    word.endColumn 
                );
                const inputText = model.getValue();
                var suggestions = [];
                //Uncomment these changes to test out antlr4 autoSuggest.
                /*const antlr4Suggestions = autoSuggester.autosuggest(inputText);
                const filteredSuggestions = antlr4Suggestions.filter(item => {
                    return !/^[a-zA-Z0-9]$/.test(item) &&
                           !/^\d+\.\d+$/.test(item) &&
                           !/^:\.\d+$/.test(item);
                });*/
                var antlrSuggestions = [];
                /*antlrSuggestions = filteredSuggestions.map(suggestion => ({
                    label: suggestion,
                    kind: monaco.languages.CompletionItemKind.Text,
                    insertText: suggestion,
                    range: range
                }));*/
                utils.getbuiltinFunctions().forEach(func => {
                    suggestions.push({
                        label: func.name,
                        detail: func.detail,
                        kind: monaco.languages.CompletionItemKind.Function,
                        insertText: `${func.name}()`,
                        range: range
                    });
                });
                /*if(self.name && self.name()!==""){
                    suggestions.push({
                        label: self.name(),
                        kind: monaco.languages.CompletionItemKind.Variable,
                        insertText: self.name(),
                        range: range
                    });
                }*/
                if(self.agrigatedValueOptions && self.agrigatedValueOptions.length > 0){
                    self.agrigatedValueOptions.forEach(function(option) {
                        suggestions.push({
                            label: option.name,
                            kind: monaco.languages.CompletionItemKind.Variable,
                            insertText: option.name,
                            range: range
                        });
                        if (option.children && option.children.length > 0) {
                            option.children.forEach(child => {
                                suggestions.push({
                                    label: child.name,
                                    kind: monaco.languages.CompletionItemKind.Variable,
                                    insertText: child.name,
                                    range: range
                                });
                            });
                        }
                    });
                }
                return { suggestions: [...antlrSuggestions, ...suggestions] };
            }
        });
    }

    getModalConfigJson(){
        var self = this;
        var config = {};
        for(var i=0;i<self.configColl().length;i++){
            if(!self.configColl()[i]._destroy){
                var name = self.configColl()[i].name;
                var value = self.configColl()[i].value;
                //var obj = {name,value};
                config[name] = value;
            }
        }
        return config;
    }

    getAllValues() {
        var self = this;
        let agriValueList = [];
        var phases = DataManager.getDataManager().get('currentPlan').get('phase');
        for(var i=0; i<phases.length; i++){
            var alternatives = phases.at(i).get('phaseAlternative');
            for(var j=0;j<alternatives.length;j++){
                _.each(alternatives.at(j).get("alternativeValues").models,function(component){
                    var variableId = DataManager.getDataManager().htmlEscape(component);
                    agriValueList.push({id: component.id, name: component.get('name'), variableId:variableId});
                    self.expBuilderOptions.variables.push({variableId: variableId, name:component.get('name')});
                });
            }
        }

        /*const valueContexts = self.model.getValuesContexts();
        let keys = Object.getOwnPropertyNames(valueContexts);
        for (let key in keys) {
            let altContext = valueContexts[key];
            altContext.valueElementContext.forEach(component => {
                let context = component.getValueContext(altContext.id);
                if(context){
                    //const val = context.getParent();
                    var variableId = DataManager.getDataManager().get('currentPlan').htmlEscape(component);
                    agriValueList.push({id: component.id, name: component.get('name'), variableId:variableId})
                    self.expBuilderOptions.variables.push({variableId: variableId, name:component.get('name')})
                }
            });
        }*/
        return agriValueList;
    }

    optmize() {
        var self = this;
        var dataManager = DataManager.getDataManager();
        //var plan = dataManager.get('currentPlan');
        var objMinMax = self.optimizationType();
        if(self.formula().length == 0 || objMinMax == null){
            self.formulaErrorMsg("Objective Missing...");
            return;
        }
        var ids = self.expBuilderOptions.variables;
        var objective = self.formula();
        const idMap = ids.reduce((acc, { variableId, name }) => {
            acc[name] = variableId;
            return acc;
        }, {});
        var expjson = {};
        var operands = [];
        for (const [name, variableId] of Object.entries(idMap)) {
            const regex = new RegExp(`\\b${name}\\b`, 'g'); 
            objective = objective.replace(regex, variableId);
            if(objective.includes(variableId)){
                var compFound = self.agrigatedValueOptions.filter(obj => obj.variableId == variableId);
                if(compFound){
                    operands.push(compFound[0].id);
                }
            }
        }
        expjson.aggregatedFromValue = operands;
        var modalConfig = self.getModalConfigJson();
        expjson.modalConfig = modalConfig;
        //var objective = utils.replaceNamesWithIds( self.formula(),self.expBuilderOptions.variables);
        DataManager.getDataManager().validateExpression(self.formula(),self.expBuilderOptions,null,function(response){
            var expValid = false;
            if(response && response.isValid){
                self.formulaErrorMsg('');
                expValid = true;
            } else {
                var errmsg = response.error;
                if(!errmsg && response.response && response.response.apierror){
                    errmsg = response.response.apierror;
                }
                self.formulaErrorMsg(errmsg);
                expValid = false;
            }
            if (expValid) {
                window.utils.startSpinner('Optmizing', 'Optimizing ...');
                //var juliaExp = self.genOptmizedExpression(objective);
                window.setTimeout(function () {
                    var execId = self.defaultExecutionScenario.id;
                    self.saveOptimization(objMinMax,objective,expjson);
                    if(self.originalExp != objective || self.objType != objMinMax) {
                        dataManager.buidlJuliaModel(function(err){
                            if(err){
                                window.utils.stopSpinner('Optmizing');
                                return;
                            } else {
                                self.calculateOptmiziedModel(execId,modalConfig,function(response){
                                    var msg = self.displayCalculatedValues(response);
                                    self.showMessageOutput(msg,response);
                                    window.utils.stopSpinner('Optmizing');
                                });
                            }
                        })
                    } else {
                        self.calculateOptmiziedModel(execId,modalConfig,function(response){
                            var msg = self.displayCalculatedValues(response);
                            self.showMessageOutput(msg,response);
                            window.utils.stopSpinner('Optmizing');
                        });
                    }
                    
                }, 100);
            }
        });
    };

    saveOptimization(objMinMax,objective,expjson){
        var self = this;
        expjson = expjson ? JSON.stringify(expjson) : null;
        if(objMinMax && objective) {
            self.model.set('optimizationType', objMinMax);
            var exp = self.model.get("optimizationObjective");
            if(exp){
                exp.set("expressionStr",objective);
                exp.set("expressionConfig",expjson);
            } else {
                exp = self.model.createExpression(objective, expjson);
            }
            //self.model.set("optimizationObjective",exp);
        } else if(!objMinMax && !objective) {
            self.model.set('optimizationType', null);
            self.model.get("optimizationObjective")?.destroy();
        }
    }

    showMessageOutput(msg,response){
        var self = this;
        bootbox.dialog({
            title: "Optimize Summary",
            message: msg,
            size: 'large',
            buttons: {
                main: {label: "Close",className: "btn btn-default"},
                success: {
                    label: "Accept",
                    className: "btn btn-complete accept complete-btn ",
                    callback: function () {
                        self.acceptOptimiziedResult();
                    }
                },
                cancel: {
                    label: "Reject",
                    className: "btn btn-complete reject complete-btn ",
                    callback: function () {
                        self.optResult = null;
                    }
                }
            }
        });
        $('.bootbox-body').css({
            height: '400px',
            overflowY: 'scroll'
        });
        if((response.error && response.stacktrace) || response.error == 'NO_SOLUTION'){
            $('.accept').hide();
            $('.reject').hide();
        }
    }

    displayCalculatedValues(response){
        var self = this;
        self.optResult = response;
        var scenario = self.model;
        //var execId = self.defaultExecutionScenario.id;
        var msg = "<div>";
        var dataManager = DataManager.getDataManager();
        let plan = dataManager.get("currentPlan");
        var currentAltModel = plan.get('phase').at(0).get('primary');
        var altValues = currentAltModel.get("alternativeValues");
        var valMsgs = '<ul>';
        let valueContexts = scenario.getValuesContexts();
        //scenarioExecution.set('lastCalculated',new Date().getTime());
        var altsDataKeys = Object.getOwnPropertyNames(valueContexts);
        altsDataKeys.forEach(altKey =>{
            let altData = valueContexts[altKey];
            //let contexts = altData.valueElementContext;
            let startPeriod = altData.startPeriod;
            let noPeriods = altData.noPeriods;
            for(let i=startPeriod;i<startPeriod + noPeriods;i++){
                altValues.forEach(val =>{
                    let valId = val.get("id");
                    if(val.get("valueType") == ValueType.Atomic){
                        valId = dataManager.htmlEscape(valId) + "_var[" + i + "]";
                    }else{
                        valId = dataManager.htmlEscape(valId) + "[" + i + "]";
                    }
                    if(response[valId] /*&& (response[valId] != periodDataset.get(val.get("id")))*/){
                        //periodDataset.set(val.get("id"),response[valId]);
                        var valMsg = "<li><strong>"+val.get("name") + "</strong> = " + response[valId] + "</li>";
                        valMsgs = valMsgs.concat(valMsg);
                    }
                });
            }
        });
        valMsgs += '</ul>';
        msg = msg.concat(valMsgs);
        var calculationData = response['summary'];
        if (calculationData) {
            msg = msg.concat("<br/><h3>Summary </h3>");
            var calculationSummary1 = '<ul>';
            //calculationData = JSON.parse(calculationData);
            for (const [key, value] of Object.entries(calculationData)) {
                calculationSummary1 += `<li><strong>${key}:</strong> ${value}</li>`;
            }
            calculationSummary1 += '</ul>';
            msg = msg.concat(calculationSummary1);
        }
        var errorMsg = response['error'];
        if (errorMsg) {
            msg = msg.concat("<br/><h3>Error </h3>");
            /*var errorMsgSummary1 = '<ul>';
            for (const [key, value] of Object.entries(errorMsg)) {
                errorMsgSummary1 += `<li><strong>${key}:</strong> ${value}</li>`;
            }
            errorMsgSummary1 += '</ul>';*/
            msg = msg.concat(errorMsg);
        }
        msg = msg.concat(" </div>");
        return msg;
    }

    calculateOptmiziedModel(execId,modalConfig,callback){
        var dataManager = DataManager.getDataManager();
        var plan = dataManager.get('currentPlan');
        var packArtDoc = dataManager.get("artifactsDocuments")[plan.id];
        var planArtId = packArtDoc ? packArtDoc.artifactId : currentPlanId;
        var optUrl = "/vdmbee/calculation/calculatese";
        optUrl = optUrl + "?planName="+ plan.get('name') + "&planId="+plan.id + "&planArtifactId="+planArtId + "&scenarioId="+dataManager.get("defaultScenario").id + "&scenarioExecutionId="+execId + "&doOptimize="+true ;
        dataManager.get('vmpServerService').postAllPromise(optUrl, JSON.stringify(modalConfig)).then(function(response) {
            callback(response);
        }).catch(error => {
            console.error('An error occurred:', error);
            window.utils.stopSpinner('Optmizing');
            /*if(error.response && error.response.apierror && error.response.apierror.message){
                bootbox.alert(error.response.apierror.message);
                window.utils.stopSpinner('Optmizing');
            }*/
            //callback(error);
        });
    }

    acceptOptimiziedResult(callback){
        var self = this;
        var execId = self.defaultExecutionScenario.id;
        window.utils.startSpinner('SaveResult', 'Saving ...');
        window.setTimeout(function () {
            var dataManager = DataManager.getDataManager();
            var plan = dataManager.get('currentPlan');
            var packArtDoc = dataManager.get("artifactsDocuments")[plan.id];
            var planArtId = packArtDoc ? packArtDoc.artifactId : currentPlanId;
            var resultJson = self.optResult;
            var currentLegalEntity = DataManager.getDataManager().get("currentLegalEntity");
            var optUrl = "/vdmbee/calculation/updateperioddataset";
            optUrl = optUrl + "?planId="+planArtId + "&scenarioId="+dataManager.get("defaultScenario").id + "&scenarioExecutionId="+execId + "&currentEntityId="+currentLegalEntity.entityId ;
            dataManager.get('vmpServerService').postAllPromise(optUrl, JSON.stringify({resultJson})).then(function(response) {
                window.utils.stopSpinner('SaveResult');
                callback && callback(response);
            }).catch(error => {
                window.utils.stopSpinner('SaveResult');
                console.error('An error occurred:', error);
                callback && callback(error);
            });
        },100);
    }

    reset(){
        var self = this;
        self.saveOptimization(null,null,null);
        self.configColl([]);
        self.optimizationType("");
        self.formula("");
        //$("#formula")
        self.editor.getModel().setValue("");
    }
    
    static getInstance(model,options){
        var view = new OptimizationViewModel(model,options);
		view.init(model, options);
		return view;
    };
}
path.OptimizationViewModel = OptimizationViewModel;