import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AbstractMeasureElement} from './AbstractMeasureElement'
import {MeasureMixin} from './MeasureMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
/*import { ObservedMeasure } from './ObservedMeasure'
import { Characteristic } from './Characteristic'
import { Scope } from './Scope'
import { MeasureCategory } from './MeasureCategory'
import { RefinementMeasureRelationship } from './RefinementMeasureRelationship'
import { EquivalentMeasureRelationship } from './EquivalentMeasureRelationship'
import { MeasureRelationship } from './MeasureRelationship'
import { Operation } from './Operation'*/



/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/AbstractMeasureElement","appbo/smm/MeasureMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AbstractMeasureElement,MeasureMixin
, ObservedMeasure
, Characteristic
, Scope
, MeasureCategory
, RefinementMeasureRelationship
, EquivalentMeasureRelationship
, MeasureRelationship
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	
	var Measure = AbstractMeasureElement.extend(utils.customExtends({
		relations:MeasureMixin.getMixinRelations(),
		subModelTypes: {
			smm_DimensionalMeasure : 'smm.DimensionalMeasure',
			smm_GradeMeasure : 'smm.GradeMeasure'
		},
		initialize: function(attributes, options) {
			AbstractMeasureElement.prototype.initialize.apply(this, arguments);
			if(attributes.scale){
				this.set('scale',path.ScaleOfMeasurement[attributes.scale instanceof Object ? attributes.scale.name : attributes.scale]);
			}
			/*this.on('remove:observedMeasure',function(model,coll,options){
				if(Backbone.skipPersistance || options.creatingRevision){
					return;
				}				
				if(this.get('observedMeasure').length === 0){
					this.destroy();
				}
			});*/			
		}
		}
		, new MeasureMixin()
    ));
    Measure['remove:observedMeasure'] = function (model, coll, options) {
        if (Backbone.skipPersistance || options.creatingRevision) {
            return;
        }
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
			if (this.get('observedMeasure').length === 0) {
				this.set("nestedParent",model.get("nestedParent"),{silent:true});
				this.destroy();
			}
		}
    };
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	Measure.abstract = sp2Enabled;
	Measure.supported = !sp2Enabled;
//startCustomMethods	
	
//endCustomMethods		
	path.Measure = Measure;
	export {Measure};
//});