import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmRelationship} from './SmmRelationship'
import {CategoryRelationshipMixin} from './CategoryRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { MeasureLibrary } from './MeasureLibrary'
import { MeasureCategory } from './MeasureCategory'
import {AbstractMeasureElement} from './AbstractMeasureElement'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmRelationship","appbo/smm/CategoryRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmRelationship,CategoryRelationshipMixin
, MeasureLibrary
, MeasureCategory
, AbstractMeasureElement){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!MeasureLibrary){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.MeasureLibrary){
			MeasureLibrary = importPath.MeasureLibrary;
		}
        else {
            import('./MeasureLibrary').then(({ default: MeasureLibrary }) => {
                MeasureLibrary = MeasureLibrary;
            });

			/*require(["appbo/smm/MeasureLibrary"],function(loadedModule){
				MeasureLibrary = loadedModule;
			});*/
		}
	}
	if(!MeasureCategory){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.MeasureCategory){
			MeasureCategory = importPath.MeasureCategory;
		}
        else {
            import('./MeasureCategory').then(({ default: MeasureCategory }) => {
                MeasureCategory = MeasureCategory;
            });

			/*require(["appbo/smm/MeasureCategory"],function(loadedModule){
				MeasureCategory = loadedModule;
			});*/
		}
	}
	if(!AbstractMeasureElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.AbstractMeasureElement){
			AbstractMeasureElement = importPath.AbstractMeasureElement;
		}
        else {
            import('./AbstractMeasureElement').then(({ default: AbstractMeasureElement }) => {
                AbstractMeasureElement = AbstractMeasureElement;
            });

			/*require(["appbo/smm/AbstractMeasureElement"],function(loadedModule){
				AbstractMeasureElement = loadedModule;
			});*/
		}
	}
	var CategoryRelationship = SmmRelationship.extend(utils.customExtends({
		relations:CategoryRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			SmmRelationship.prototype.initialize.apply(this, arguments);
		}
		}
		, new CategoryRelationshipMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	CategoryRelationship.abstract = false;
	CategoryRelationship.supported = !sp2Enabled; 	//As we are not using it yet
	path.CategoryRelationship = CategoryRelationship;
	export {CategoryRelationship};
//});