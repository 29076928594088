import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {DataManager} from '../data/DataManager'
import {BeepPackage} from '../../../version1/bo/beeppackage/BeepPackage'
//define(["jquery","underscore", "backbone","Lawnchair", "backbone-lawnchair","appcommon/com/vbee/data/DataManager","backbone-relational","appbo/beeppackage/BeepPackage"],

//function(jQuery,_, Backbone, Lawnchair, backboneLawnchair,DataManager) {
    var PackageReference = Backbone.RelationalModel.extend({
    	relations:[
			{ 
				type: Backbone.HasOne,
				key: 'beepReference',
				relatedModel: 'beeppackage.BeepPackage',
				includeInJSON: Backbone.Model.prototype.idAttribute,
			}    	
    	],    	
        defaults: function(){
            var ret = {
	            type:"com_vbee_filesystem_PackageReference",
	            beepType:"vdml_ValueDeliveryModel",
	            id:window.guidGenerator(),
	            parent:null,
	            version:DataManager.getDataManager().get('currentVDMVersion')
            };
            return ret;
        },
		initialize: function() {
        	Backbone.Model.prototype.initialize.apply(this, arguments);
            //this.on("change:beepReference",checkAndDestroy)
        }
    });
    //TODO: test this?
    PackageReference["change:beepReference"] = checkAndDestroy;
    function checkAndDestroy(model, relatedModel) {
    	if(Backbone.skipPersistance){
    		return;
    	}
		if(!relatedModel){
			model.destroy();
		}    	
    }
	PackageReference.prototype.getNestedParent = function(){
		var container;
		if(!container){
			container = this.get("packageReferenceOwner") ? this.get("packageReferenceOwner") : this.previousAttributes().packageReferenceOwner;
			if(container){
				return container.getNestedParent();
			}
		}
		return this;
	}
    PackageReference.prototype.getVersion = function(){
    	return Math.round(this.get('version')/1000);
    };
    
    DataManager.getDataManager().get('appNS').com.vbee.filesystem.PackageReference = PackageReference;
    //return PackageReference;
	export {PackageReference};
//});