import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Collaboration} from './Collaboration'
import {OrgUnitMixin} from './OrgUnitMixin'
import {OrgUnit2Mixin} from './OrgUnit2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { CapabilityOffer } from './CapabilityOffer'
//import { Store } from '../../../../libs/rdfstore-0.8.4/rdf_store'
//import { Position } from './Position'
import { CapabilityMethod } from './CapabilityMethod'
import { BusinessModelPortfolio } from './BusinessModelPortfolio'
import { BusinessModel } from './BusinessModel'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Collaboration","appbo/vdml/OrgUnitMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Collaboration,OrgUnitMixin
, CapabilityOffer
, Store
, Position
, CapabilityMethod
, BusinessModelPortfolio
, BusinessModel){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!CapabilityOffer){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityOffer){
			CapabilityOffer = importPath.CapabilityOffer;
		}
		else{
            import('./CapabilityOffer').then(loadedModule => {
                CapabilityOffer = loadedModule;
			});
			/*require(["appbo/vdml/CapabilityOffer"],function(loadedModule){
				CapabilityOffer = loadedModule;
			});*/
		}
	}
	//if(!Store){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Store){
			//Store = importPath.Store;
		}
		else{
            import('./Store').then(loadedModule => {
                Store = loadedModule;
			});
			/*require(["appbo/vdml/Store"],function(loadedModule){
				Store = loadedModule;
			});*/
		}
//	}
	//if(!Position){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Position){
			Position = importPath.Position;
		}
		else{
            import('./Position').then(loadedModule => {
             //   Position = loadedModule;
			});
			/*require(["appbo/vdml/Position"],function(loadedModule){
				Position = loadedModule;
			});*/
		}
	//}
	if(!CapabilityMethod){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityMethod){
			CapabilityMethod = importPath.CapabilityMethod;
		}
		else{
            import('./CapabilityMethod').then(loadedModule => {
                CapabilityMethod = loadedModule;
			});
			/*require(["appbo/vdml/CapabilityMethod"],function(loadedModule){
				CapabilityMethod = loadedModule;
			});*/
		}
	}
	if(!BusinessModelPortfolio){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModelPortfolio){
			BusinessModelPortfolio = importPath.BusinessModelPortfolio;
		}
		else{
            import('./BusinessModelPortfolio').then(loadedModule => {
                BusinessModelPortfolio = loadedModule;
			});
			/*require(["appbo/vdml/BusinessModelPortfolio"],function(loadedModule){
				BusinessModelPortfolio = loadedModule;
			});*/
		}
	}
	if(!BusinessModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModel){
			BusinessModel = importPath.BusinessModel;
		}
		else{
            import('./BusinessModel').then(loadedModule => {
          //      BusinessModel = loadedModule;
			});
			/*require(["appbo/vdml/BusinessModel"],function(loadedModule){
				BusinessModel = loadedModule;
			});*/
		}
	}
	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? OrgUnit2Mixin : OrgUnitMixin;
	}
	var orgUnitMixinClass = getMixinClass();
	var OrgUnit = Collaboration.extend(utils.customExtends({
		relations:orgUnitMixinClass.getMixinRelations(),
		initialize: function(attributes, options) {
			Collaboration.prototype.initialize.apply(this, arguments);
		}
		}
		, new orgUnitMixinClass()
	));
	OrgUnit["change:name"] = function (model, newName) {
		var previousName = model._previousAttributes ? model._previousAttributes.name:"";
		if(previousName != newName) {
			this.setOrgUnitNameChange(newName);
		}
	}
	OrgUnit.abstract = false;
	path.OrgUnit = OrgUnit;
	export {OrgUnit};
//});