import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Performer} from './Performer'
import {ApplicationRoleMixin} from './ApplicationRoleMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Performer","appbo/vdml/ApplicationRoleMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Performer,ApplicationRoleMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var ApplicationRole = Performer.extend(utils.customExtends({
		relations:ApplicationRoleMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Performer.prototype.initialize.apply(this, arguments);
		}
		}
		, new ApplicationRoleMixin()
	));
	
	ApplicationRole.abstract = false;
	ApplicationRole.supported = false;
	path.ApplicationRole = ApplicationRole;
	export {ApplicationRole};
//});