import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global"],
//function(require,$,_, Backbone,ko, koMapping,kb,bootbox,DataManager,global){
	var path = DataManager.getDataManager().buildAppNsPath("beeppackage.views.properties",global.version);
	export class AdminViewModel {
		constructor(model,options){
		var self = this;
		this.AdminViewModel = this;
		this.model = model;
		this.name = ko.observable("");
		this.description = ko.observable("");
		this.capabilityLibraryTitle = DataManager.getDataManager().get('localeManager').get("CapabilityLibrary");
		this.capabilityLibraryDiagramTitle = DataManager.getDataManager().get('localeManager').get("CapabilityMap");
		if(options && options.parentView){
			this.parentView = options.parentView;
			this.encodeId = ""+options.modalId;
			this.alternativeModel = options.addOptions.alternative;
			this.plusIconType = options.addOptions.plusIconType;
		}
		this.enableComplete = ko.observable(false);
		this.enableCapabilityLibraryDiagramPackage = ko.observable(false);
		this.enableValueLibraryDiagramPackage = ko.observable(false);
		this.vocabularyPackage = ko.observable(false);
		
		this.capabilityLibrary = ko.observable(false);
		this.valueLibrary = ko.observable(false);
		var allowedTypes = [];
		
		this.types = ko.observableArray();
		if (options.onlyMap){
			self.types.push(options.onlyMap);
		} else {
			self.types.push(self.capabilityLibraryDiagramTitle);
			self.types.push(self.capabilityLibraryTitle);
			self.types.push('Vocabulary Library');
			self.types.push('Value Library');
		}
		
		if(DataManager.getDataManager().isAdminPortalPurchased()){
			allowedTypes.push(self.capabilityLibraryDiagramTitle);
			allowedTypes.push('Vocabulary Library');
			allowedTypes.push('Value Library')
			allowedTypes.push(self.capabilityLibraryTitle);
		}
		
		this.selectedType = ko.observable();
		this.capabilityLibraryPackages = ko.observableArray();
		this.vocabularyPackages = ko.observableArray();
		
		this.vocabularyModels = [];
		this.capabilityLibraryNames = [];
		this.capabilityLibraryDiagramNames = [];
		this.capabilityLibraries = ko.observableArray();
		this.selectedVocabularyPackage = ko.observable();
		this.selectedCapabilityLibrary = ko.observable();
		this.selectedValueLibrary = ko.observable();
		this.selectedCapabilityLibraryPackage = ko.observable();
		
		$('#modal'+self.encodeId).on('shown.bs.modal', function () {
	          window.utils.focusElement("#newModal .focus-ele");
	    });
	    
        this.selectedType.subscribe(function (val) {
			if (val === 'Vocabulary Library') {
				self.vocabularyPackage(true);
				self.enableCapabilityLibraryDiagramPackage(false);
				self.capabilityLibrary(false);
			}
			else if (val === self.capabilityLibraryTitle) {
				self.vocabularyPackage(false);
				self.capabilityLibrary(false);
				self.enableCapabilityLibraryDiagramPackage(true);
			}
			else if (val === self.capabilityLibraryDiagramTitle /*|| val === 'BusinessIndicator Library'*/ || val === 'Value Library') {
				self.vocabularyPackage(false);
				self.capabilityLibrary(false);
				self.enableCapabilityLibraryDiagramPackage(true);
				self.enableValueLibraryDiagramPackage(false)
			}
	    	checkUniqueName(self.name());
	    });
	    this.selectedCapabilityLibraryPackage.subscribe(function(val){
	    	if(self.selectedType() === self.capabilityLibraryDiagramTitle){
	    		self.capabilityLibraries().length = 0;
	    		if(val.name !=="New"){
	    			loadCapabilityLibraries(val);
	    		}
	    		self.capabilityLibraries.push({ name: "New" });
	    	}
    		checkUniqueName(self.name());
		});
		this.selectedCapabilityLibrary.subscribe(function(val){
    		checkUniqueName(self.name());
		});
		this.selectedValueLibrary.subscribe(function () {
			checkUniqueName(self.name())
		})
	    this.selectedVocabularyPackage.subscribe(function(val){
    		checkUniqueName(self.name());
	    });
	   
	    this.name.subscribe(function(val){
        	self.description(val);
        });
        function loadCapabilityLibraries(vdmPackage) {
	        var repId = DataManager.getDataManager().getRepositoryId(vdmPackage.id);
		    var vdmStore = DataManager.getDataManager().getVDMStore(repId);
	        DataManager.getDataManager().fetchDocumentFromPackage(vdmPackage.id,'vdml_ValueDeliveryModel',vdmPackage.version,vdmPackage.id,'vdml_ValueDeliveryModel',vdmStore,{
				success:function(model){
					var capabilityLibraries = model.get('capabilityLibrary').models;
		        	_.each(capabilityLibraries,function(cl){
		        		self.capabilityLibraries.push({ id: cl.get('id'), name: cl.get('name'), version: cl.get('version')});
		        	});
				},
				error:function(error){
					console.log('Unable to load selected Package');
				}
			}, true, null);
	    };
	    function loadLibraryPackages(){
	    	planId = self.model.id.substr(self.model.id.lastIndexOf('@'));
	    	self.capabilityLibraryPackages().length = 0;
	        commonAltId = planId + "-Common@";
	        DataManager.getDataManager().getAllDocumentsCollectionOfType(commonAltId, 'vdml_ValueDeliveryModel', function (result) {
			    if(result && result.length > 0){
			    	_.each(result.models,function(pack){
			    		if(pack.get('id').indexOf("Common") > -1){
			    			if(pack.get('id').indexOf('CommonPackage')===-1){
			    				self.capabilityLibraryPackages.push({ id: pack.get('id'), name:pack.get('name') , version: pack.get('version') });
			    			}
			    		}
			    	});
			    }
	        }, false,null,true);
	        self.capabilityLibraryPackages.push({ name: "New" });
	    }
        if (self.alternativeModel) {

            function handleAddClick(commonAlt) {
                function loadDocuments(results) {
                    for (var i = 0; i < results.length; i++) {
                        var type = results.at(i).get('type');
                        if (type === "vdml_CapabilityLibraryDiagram") {
                            self.capabilityLibraryDiagramNames.push({ id: results.at(i).get('id'), name: results.at(i).get('name'), parent: results.at(i).get('parent') });
                        }else if (type === "vdml_CapabilityLibrary") {
                            self.capabilityLibraryNames.push({ id: results.at(i).get('id'), name: results.at(i).get('name'), parent: results.at(i).get('parent') });
                        } else if (type === "vdml_ValueDeliveryModel" && results.at(i).get('parent')) {
                            self.capabilityLibraries.push({ id: results.at(i).get('id'), name: results.at(i).get('name'), version: results.at(i).get('version') });
                        } else if (type === "concept_BeepVocabulary") {
                            self.vocabularyPackages.push({ id: results.at(i).get('id'), name: results.at(i).get('name') });
                        } else if (type === "concept_VocabularyLibrary") {
                            self.vocabularyModels.push({ name: results.at(i).get('name') });
                        }
                    }
                }
                function loadAdminDocuments(results) {
					loadDocuments(results);
					loadLibraryPackages();
                    self.vocabularyPackages.push({ name: "New" });
				}
				var searchTypes = 'concept_BeepVocabulary,concept_VocabularyLibrary,vdml_ValueDeliveryModel';
                var nameNS = 'vbc:cmof_EObject-name';
				DataManager.getDataManager().getAllDocumentsCollectionOfType(self.alternativeModel, searchTypes, loadAdminDocuments, true, null, null, nameNS, commonAlt);
			}

            var planId = self.model.id.substr(self.model.id.lastIndexOf('@'));
            var commonAltId = planId + "-Common@";
            function waitAndLoadRDFModel() {
                var commonRdfModel = DataManager.getDataManager().getRDFModel(commonAltId);
                if (commonRdfModel) {
                    handleAddClick(commonAltId);
                } else {
                    setTimeout(waitAndLoadRDFModel, 5);
                }
            }
            waitAndLoadRDFModel();

        }		
		
		this.cleanModal = function(){
			window.cleanDialogModel(self.encodeId,self);	 
		}
		
		this.createPackage = function(){
			if(self.parentView){
				if(self.name() !== null && self.name() !== "") {
					if(allowedTypes.indexOf(self.selectedType()) === -1){
                        window.utils.limitExceeded();
						window.cleanDialogModel(self.encodeId,self);
						return false;
					}else {
						return options.callback(self,self.encodeId,self.selectedType());
					}		
				}
			}
		}
		function checkUniqueName(name){
			var items = [];
			if(self.selectedType() === 'Vocabulary Library'){
				items = self.vocabularyModels;
            } else if (self.selectedType() === self.capabilityLibraryDiagramTitle) {
                items = self.capabilityLibraryDiagramNames;
            } else if (self.selectedType() === self.capabilityLibraryTitle) {
                items = self.capabilityLibraryNames;
            }

			for(var i=0;i<items.length;i++){			
				if(self.selectedVocabularyPackage() && self.selectedType() === 'Vocabulary Library' && items[i].name === name.trim()){
					$('#duplicateName').text(""+DataManager.getDataManager().get('localeManager').get('DuplicateAlert', self.selectedType()));
					self.enableComplete(false);
					return;
				}
                else if (self.selectedType() === self.capabilityLibraryTitle && items[i].name === name.trim() && items[i].parent === self.selectedCapabilityLibraryPackage().id){
					$('#duplicateName').text(""+DataManager.getDataManager().get('localeManager').get('DuplicateAlert', self.selectedType()));
					self.enableComplete(false);
					return;
				}else if(self.selectedType() === self.capabilityLibraryDiagramTitle && items[i].name === name.trim() && items[i].parent === self.selectedCapabilityLibraryPackage().id){
					$('#duplicateName').text(""+DataManager.getDataManager().get('localeManager').get('DuplicateAlert', self.selectedType()));
					self.enableComplete(false);
					return;
				}

			}
			$('#duplicateName').text("");
			if(name.trim() !== "" && self.selectedType()){
				self.enableComplete(true);				
			}else{
				self.enableComplete(false);
			}
		}
		this.afterRenderView = function(){
			$("#packageName"+self.encodeId).keyup(function (view) {
                var newValue = view.currentTarget.value;
                if(newValue.trim() !== '') {
                    checkUniqueName(newValue);
                }
                else {
                	self.enableComplete(false);
                }
			});
			$('#editDetailsModalContent' + self.encodeId).resize(function () {
				$('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
			})
            if(options.onlyMap){
            	$('#type').attr('disabled',true);
            }
            window.utils.applyFontSizeInDialog();
			//self.loadBMs();
		}
				
//#endCustomMethods
		
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),[
		,'name'
		,'description'
		,'Complete'
		,'Close'
		,'selectType'
		,'selectPackage'
		,'CreateModel'
		,'selectCapabilityLibrary']);
	}
	static getInstance(model,options){
		var view = new AdminViewModel(model,options);
		return view;
	}
}
path.AdminViewModel = AdminViewModel;