import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmElement} from './SmmElement'
import {SmmElementMixin} from './SmmElementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/smm/SmmElement", "appbo/smm/SmmElementMixin"],
 //   function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, SmmElement, SmmElementMixin) {

        var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
        export class BusinessIndicatorLibraryElementMixin {
            /*constructor() {
                var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
                path.BusinessIndicatorLibraryElementMixin = BusinessIndicatorLibraryElementMixin;
            }*/
        defaults() {
            var ret = {
                type: "smm_BusinessIndicatorLibraryElement"
            }
            return jQuery.extend(SmmElement.prototype.defaults.apply(this), ret);
        }
        static getMixinRelations() {
            return _.union([
                {
                    type: Backbone.HasMany,
                    containingClass: "smm_BusinessIndicatorLibraryElement",
                    key: "parent",
                    relatedModel: "smm.BusinessIndicatorLibraryElement",
                    includeInJSON: Backbone.Model.prototype.idAttribute,
                },
                {
                    type: Backbone.HasMany,
                    containingClass: "smm_BusinessIndicatorLibraryElement",
                    key: "child",
                    relatedModel: "smm.BusinessIndicatorLibraryElement",
                    includeInJSON: Backbone.Model.prototype.idAttribute,
                }
            ]);
        }
        static getCumulativeMixinRelations() {
            if (!BusinessIndicatorLibraryElementMixin.cummulativeRelations) {
                BusinessIndicatorLibraryElementMixin.cummulativeRelations = _.union(BusinessIndicatorLibraryElementMixin.getMixinRelations()
					, SmmElementMixin.getCumulativeMixinRelations()
				);
			}
            return BusinessIndicatorLibraryElementMixin.cummulativeRelations.slice();
		}
       static getSuperTypes() {
            return [
                "smm_SmmElement"
            ];
        }
        static getProperties() {
            return [
                { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
                { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" }
            ]
        }
        getParent() {
            var container;
            if (!container) {
                container = this.get("businessIndicatorLibraryElementOwner") ? this.get("businessIndicatorLibraryElementOwner") : this.previousAttributes().BusinessIndicatorLibraryElementOwner;
                if (container) {
                    return container;
                }
            }
            return this;
        }
        getPackagePath(path) {
            if (!path) {
                path = [];
            }
            var container;
            if (!container) {
                container = this.get("businessIndicatorLibraryElementOwner") ? this.get("businessIndicatorLibraryElementOwner") : this.previousAttributes().businessIndicatorLibraryElementOwner;
                if (container) {
                    path.unshift({ id: this.get('id'), name: this.get('name') })
                    return container.getPackagePath(path);
                }
            }
            return path;
        }
        getViewProperties(type) {
            return {
                templatePath: "views/smm/views/properties/BusinessIndicatorLibraryElementPropertiesTemplate.html",
                templateName: "BusinessIndicatorLibraryElementPropertiesTemplate",
                viewTypeStr: "appviews/smm/views/properties/BusinessIndicatorLibraryElementViewModel",
                tabId: "BusinessIndicatorLibraryElementView",
                tabName: "BusinessIndicatorLibraryElement"
            }
        }
        
		}
		path.BusinessIndicatorLibraryElementMixin = BusinessIndicatorLibraryElementMixin;
        //return BusinessIndicatorLibraryElementMixin;
    //});