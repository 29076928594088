
import * as _ from 'underscore'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
var path = DataManager.getDataManager().buildAppNsPath("tickets.views.properties", global.version);
export class Audit{
    constructor () {
    }
    static getViewProperties = function (type) {
        return {
            templatePath: "views/tickets/views/properties/AuditTemplate.html",
            templateName: "AuditTemplate",
            viewTypeStr: "appviews/tickets/views/properties/AuditViewModel",
            tabId: "AuditView",
            tabName: "Audit"
        }
    }

}
path.Audit = Audit;
