import * as $ from 'jquery'
import * as _ from 'underscore'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
var path = DataManager.getDataManager().buildAppNsPath("tickets.views.properties", global.version);
export class Support{
    constructor () {
    }
    static getViewProperties = function (type) {
        return {
            templatePath: "views/tickets/views/properties/SupportTemplate.html",
            templateName: "SupportTemplate",
            viewTypeStr: "appviews/tickets/views/properties/SupportViewModel",
            tabId: "SupportView",
            tabName: "Support"
        }
    }

}
path.Support = Support;
