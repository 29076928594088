import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasure} from './DimensionalMeasure'
import {RescaledMeasureMixin} from './RescaledMeasureMixin'
import {Operation} from './Operation'
import {RescaledMeasureRelationship} from './RescaledMeasureRelationship'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasure","appbo/smm/RescaledMeasureMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasure,RescaledMeasureMixin
, Operation
, RescaledMeasureRelationship){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
		else{
			import('./Operation').then(({ default: Operation }) => {
				Operation = Operation;
			});
			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	if(!RescaledMeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RescaledMeasureRelationship){
			RescaledMeasureRelationship = importPath.RescaledMeasureRelationship;
		}
		else{
			import('./RescaledMeasureRelationship').then(({ default: RescaledMeasureRelationship }) => {
				RescaledMeasureRelationship = RescaledMeasureRelationship;
			});
			/*require(["appbo/smm/RescaledMeasureRelationship"],function(loadedModule){
				RescaledMeasureRelationship = loadedModule;
			});*/
		}
	}
	var RescaledMeasure = DimensionalMeasure.extend(utils.customExtends({
		relations:RescaledMeasureMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			DimensionalMeasure.prototype.initialize.apply(this, arguments);
			if(attributes.scale){
				this.set('scale',path.ScaleOfMeasurement[attributes.scale instanceof Object ? attributes.scale.name : attributes.scale]);
			}
		}
		}
		, new RescaledMeasureMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	RescaledMeasure.abstract = false;
	RescaledMeasure.supported = !sp2Enabled;
	path.RescaledMeasure = RescaledMeasure;
	export {RescaledMeasure};
//});