import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasureRelationship} from './MeasureRelationship'
import {MeasureRelationshipMixin} from './MeasureRelationshipMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { DimensionalMeasure } from './DimensionalMeasure'
//import { RankingMeasure } from './RankingMeasure'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasureRelationship","appbo/smm/MeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasureRelationship,MeasureRelationshipMixin
, DimensionalMeasure
, RankingMeasure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!DimensionalMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasure){
			DimensionalMeasure = importPath.DimensionalMeasure;
		}
        else {
            import('./DimensionalMeasure').then(({ default: DimensionalMeasure }) => {
                DimensionalMeasure = DimensionalMeasure;
            });
			/*require(["appbo/smm/DimensionalMeasure"],function(loadedModule){
				DimensionalMeasure = loadedModule;
			});*/
		}
	}
	//if(!RankingMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RankingMeasure){
			RankingMeasure = importPath.RankingMeasure;
		}
        else {
            import('./RankingMeasure').then(({ default: RankingMeasure }) => {
                RankingMeasure = RankingMeasure;
            });
			/*require(["appbo/smm/RankingMeasure"],function(loadedModule){
				RankingMeasure = loadedModule;
			});*/
		}
	//}
	export class RankingMeasureRelationshipMixin {

	defaults(){
		var ret = {
			type: "smm_RankingMeasureRelationship"
		}
		return jQuery.extend(MeasureRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!RankingMeasureRelationshipMixin.cummulativeRelations) {
            RankingMeasureRelationshipMixin.cummulativeRelations = _.union(RankingMeasureRelationshipMixin.getMixinRelations()
                , MeasureRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return RankingMeasureRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_MeasureRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "influence",type : "Influence",defaultValue : "null",containingClass : "smm_MeasureRelationship" }
		]
	}
	getParent(){
		var container;
		return MeasureRelationship.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return MeasureRelationship.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/RankingMeasureRelationshipPropertiesTemplate.html",
			templateName : "RankingMeasureRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/RankingMeasureRelationshipViewModel",
			tabId : "RankingMeasureRelationshipView",
			tabName: "RankingMeasureRelationship"
		}
	}
	
	}
	path.RankingMeasureRelationshipMixin = RankingMeasureRelationshipMixin;
	//return RankingMeasureRelationshipMixin;
//});