import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {UserPreferencesMixin} from './UserPreferencesMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {Preference} from './Preference'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/preference/UserPreferencesMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,UserPreferencesMixin
//, Preference){
	
	var path = DataManager.getDataManager().buildAppNsPath("preference",global.version);
	/*if(!Preference){
		var importPath = DataManager.getDataManager().buildAppNsPath("preference",global.version);
		if(importPath.Preference){
			Preference = importPath.Preference;
		}
		else{
			require(["appbo/preference/Preference"],function(loadedModule){
				Preference = loadedModule;
			});
		}
	}*/
	var UserPreferences = EObject.extend(utils.customExtends({
		relations:UserPreferencesMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			EObject.prototype.initialize.apply(this, arguments);
		}
		}
		, new UserPreferencesMixin()
	));
    UserPreferences.getUserPreference = function(workspaceId,context,name){
		var email = DataManager.getDataManager().get('email');
		if(!email || email === ""){
			return null;
		}
		var workspaceData = DataManager.getDataManager().getWorkspaceDataWithIdSync(workspaceId);
		if(!workspaceData){
			return null;
		}
		var workspace = workspaceData.get('workspace');
		if(workspace && DataManager.getDataManager().get("workspaceStore") && workspace.get('userPreference') && workspace.get('userPreference').findWhere){
			var userPreferences = workspace.get('userPreference').findWhere({email:email});
			if(!userPreferences){
				var oldSuppress = DataManager.getDataManager().get('suppressLogging');
				DataManager.getDataManager().set('suppressLogging',true);
				userPreferences = new UserPreferences({email:email,userName:DataManager.getDataManager().get('gapiDisplayName'),name:DataManager.getDataManager().get('gapiDisplayName') + " Preferences",userPreferenceOwner:workspace});
				if(!workspace.lawnchair){
					workspace.lawnchair = DataManager.getDataManager().get('workspaceStore');
				}
				workspace.save();
				DataManager.getDataManager().set('suppressLogging',oldSuppress);
			}
			if(userPreferences.get('preference') && userPreferences.get('preference').findWhere){
				var preferenceModel = userPreferences.get('preference').findWhere({'context':context,'name':name});
				if(preferenceModel){
					return preferenceModel.get('value');
				}
			}
			return null;
		}        	
    };
	UserPreferences.setUserPreference = function(workspaceId,context,name,value){
		var dataManager = DataManager.getDataManager();
		var email = dataManager.get('email');
		if(!email || email === ""){
			return;
		}		
		var workspaceData = dataManager.getWorkspaceDataWithIdSync(workspaceId);
		if(!workspaceData){
			return null;
		}
		var workspace = workspaceData.get('workspace');
		if(workspace && workspace.get('userPreference') && workspace.get('userPreference').findWhere){
			var userPreferences = workspace.get('userPreference').findWhere({email:email});
			var oldSuppress = dataManager.get('suppressLogging');
			if(!userPreferences){
				dataManager.set('suppressLogging',true);
				userPreferences = new UserPreferences({email:email,userName:dataManager.get('gapiDisplayName'),name:dataManager.get('gapiDisplayName') + " Preferences",userPreferenceOwner:workspace});
			}
			if(userPreferences.get('preference') && userPreferences.get('preference').findWhere){
				var preferenceModel = userPreferences.get('preference').findWhere({'context':context,'name':name});
			}
			if(!preferenceModel){
				dataManager.set('suppressLogging',true);
				preferenceModel = new Preference({'context':context,'name':name,preferenceOwner:userPreferences});
			}
			if(preferenceModel.get('value') != value){
				dataManager.set('suppressLogging',true);
				preferenceModel.set('value',value);
				if(!workspace.lawnchair){
					workspace.lawnchair = dataManager.get('workspaceStore');
				}			
				workspace.save();
			}
			dataManager.set('suppressLogging',oldSuppress);
		}        	
    };
    UserPreferences.abstract = false;
	path.UserPreferences = UserPreferences;
	//return UserPreferences;
	export {UserPreferences};
//});