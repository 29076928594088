import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {NamedPlanElement} from './NamedPlanElement'
import {RoleMixin} from './RoleMixin'
import {User} from '../tickets/User'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/transformation/NamedPlanElement","appbo/transformation/RoleMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,NamedPlanElement,RoleMixin
//, User){
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
	if(!User){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.User){
			User = importPath.User;
		}
        else {
            import('../tickets/User').then(loadedModule => {
                User = loadedModule;
            });
			/*require(["appbo/tickets/User"],function(loadedModule){
				User = loadedModule;
			});*/
		}
	}
	var Role = NamedPlanElement.extend(utils.customExtends({
		relations:RoleMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			NamedPlanElement.prototype.initialize.apply(this, arguments);
			if(attributes.type){
				this.set('type',attributes.type instanceof Object ? path.RoleType[attributes.type.name] : attributes.type);
			}
		}
		}
		, new RoleMixin()
	));
	
	Role.abstract = false;
	path.Role = Role;
	//return Role;
	export {Role};
//});