import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Port} from './Port'
import {OutputPortMixin} from './OutputPortMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { DeliverableFlow } from './DeliverableFlow'
import { ResourceUse } from './ResourceUse'
import { BusinessItemDefinition } from './BusinessItemDefinition'
import { ValueAdd } from './ValueAdd'
import { OutputDelegation } from './OutputDelegation'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Port","appbo/vdml/OutputPortMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Port,OutputPortMixin
, DeliverableFlow
, ResourceUse
, BusinessItemDefinition
, ValueAdd
, OutputDelegation
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!DeliverableFlow){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.DeliverableFlow){
			DeliverableFlow = importPath.DeliverableFlow;
		}
		else{
            import('./DeliverableFlow').then(loadedModule => {
        //        DeliverableFlow = loadedModule;
			});
			/*require(["appbo/vdml/DeliverableFlow"],function(loadedModule){
				DeliverableFlow = loadedModule;
			});*/
		}
	}
	if(!ResourceUse){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ResourceUse){
			ResourceUse = importPath.ResourceUse;
		}
		else{
            import('./ResourceUse').then(loadedModule => {
        //        ResourceUse = loadedModule;
			});
			/*require(["appbo/vdml/ResourceUse"],function(loadedModule){
				ResourceUse = loadedModule;
			});*/
		}
	}
	if(!BusinessItemDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemDefinition){
			BusinessItemDefinition = importPath.BusinessItemDefinition;
		}
		else{
            import('./BusinessItemDefinition').then(loadedModule => {
       //         BusinessItemDefinition = loadedModule;
			});
			/*require(["appbo/vdml/BusinessItemDefinition"],function(loadedModule){
				BusinessItemDefinition = loadedModule;
			});*/
		}
	}
	if(!ValueAdd){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueAdd){
			ValueAdd = importPath.ValueAdd;
		}
		else{
            import('./ValueAdd').then(loadedModule => {
                ValueAdd = loadedModule;
			});
			/*require(["appbo/vdml/ValueAdd"],function(loadedModule){
				ValueAdd = loadedModule;
			});*/
		}
	}
	if(!OutputDelegation){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OutputDelegation){
			OutputDelegation = importPath.OutputDelegation;
		}
		else{
            import('./OutputDelegation').then(loadedModule => {
                OutputDelegation = loadedModule;
			});
			/*require(["appbo/vdml/OutputDelegation"],function(loadedModule){
				OutputDelegation = loadedModule;
			});*/
		}
	}

	var OutputPort = Port.extend(utils.customExtends({
		relations:OutputPortMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Port.prototype.initialize.apply(this, arguments);
/*			this.on('remove:valueAdd',function(model){
				if((this.get('valueAdd').length === 0) && !this.get('output') && (this.get('outputDelegation').length === 0) && (this.get('delegatedOutput').length === 0)){
					this.destroy();
				}
			});*/
			//this.on('destroy',checkAndRemoveFlow);
			//this.on("change:name", this.handleOutputPortNameChange);
		}
		}
		, new OutputPortMixin()
	));
    OutputPort["change:name"] = function (model,newName) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
			var previousName = model._previousAttributes ? model._previousAttributes.name:"";
			if(previousName != newName) {
				this.handleOutputPortNameChange(model, newName);
			}
		}
    }
    OutputPort['destroy'] = function checkAndRemoveFlow(model){
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
			var flow = model.previousAttributes().output;
			if(flow){
				flow.destroy();
			}
		}
	}
	
	OutputPort.abstract = false;
	path.OutputPort = OutputPort;
	export {OutputPort};
//});