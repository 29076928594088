import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Measure} from './Measure'
import {MeasureMixin} from './MeasureMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { GradeMeasureRelationship } from './GradeMeasureRelationship'
import { GradeInterval } from './GradeInterval'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/Measure","appbo/smm/MeasureMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Measure,MeasureMixin
, GradeMeasureRelationship
, GradeInterval){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!GradeMeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.GradeMeasureRelationship){
			GradeMeasureRelationship = importPath.GradeMeasureRelationship;
		}
		else{
            import('./GradeMeasureRelationship').then(({ default: GradeMeasureRelationship }) => {
                GradeMeasureRelationship = GradeMeasureRelationship;
            });
			/*require(["appbo/smm/GradeMeasureRelationship"],function(loadedModule){
				GradeMeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!GradeInterval){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.GradeInterval){
			GradeInterval = importPath.GradeInterval;
		}
		else{
            import('./GradeInterval').then(({ default: GradeInterval }) => {
                GradeInterval = GradeInterval;
            });
			/*require(["appbo/smm/GradeInterval"],function(loadedModule){
				GradeInterval = loadedModule;
			});*/
		}
	}
	export class GradeMeasureMixin {
	
	defaults(){
		var ret = {
			type: "smm_GradeMeasure"
		}
		return jQuery.extend(Measure.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"smm_GradeMeasure",
			key:"gradeTo",
			relatedModel:"smm.GradeMeasureRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"from",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_GradeMeasure",
			key:"interval",
			relatedModel:"smm.GradeInterval",
			reverseRelation: {
				key:"intervalOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!GradeMeasureMixin.cummulativeRelations) {
            GradeMeasureMixin.cummulativeRelations = _.union(GradeMeasureMixin.getMixinRelations()
                , MeasureMixin.getCumulativeMixinRelations()
            );
        }
		return GradeMeasureMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_Measure"
		];
	}
	static getProperties(){
		return [
			{name : "version",type : "EString",defaultValue : "null",containingClass : "cmof_EObject" },
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			//{name : "definition",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "scale",type : "ScaleOfMeasurement",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "customScale",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "source",type : "EString",defaultValue : "null",containingClass : "smm_Measure" },
			{name : "visible",type : "EBoolean",defaultValue : "null",containingClass : "smm_Measure" }
		]
	}
	getParent(){
		var container;
		return Measure.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return Measure.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/GradeMeasurePropertiesTemplate.html",
			templateName : "GradeMeasurePropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/GradeMeasureViewModel",
			tabId : "GradeMeasureView",
			tabName: "GradeMeasure"
		}
	}
//#startCustomMethods
	createInterval(maximumOpenVal,minimumOpenVal,maximumEndPointVal,minimumEndPointVal,symbolVal,smiley,smileyColor,correctiveActions){
		var intId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var interval = new GradeInterval({id:intId,name:this.getUniqueProperty('name',this.get('name')+ " GradeInterval",'interval'),maximumOpen:maximumOpenVal,minimumOpen:minimumOpenVal,maximumEndPoint:maximumEndPointVal,minimumEndPoint:minimumEndPointVal, symbol:symbolVal,smiley:smiley,smileyColor:smileyColor,correctiveActions:correctiveActions,intervalOwner:this});
		return interval;
	}	
//#endCustomMethods		
	
	}
	path.GradeMeasureMixin = GradeMeasureMixin;
	//return GradeMeasureMixin;
//});