import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {ScenarioPathStepMixin} from './ScenarioPathStepMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {ScenarioValueConstraints} from './ScenarioValueConstraints'

var path = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
var ScenarioPathStep = EObject.extend(utils.customExtends({
        relations:ScenarioPathStepMixin.getMixinRelations(),
        initialize: function(attributes, options) {
            EObject.prototype.initialize.apply(this, arguments);
        }
    }
    , new ScenarioPathStepMixin()
));
//#startCustomMethods			
//#endCustomMethods
ScenarioPathStep.getName = function(){
    return "ScenarioPathStep";
};
var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
ScenarioPathStep.abstract = !sp2Enabled;
ScenarioPathStep.supported = sp2Enabled;
path.ScenarioPathStep = ScenarioPathStep;
export {ScenarioPathStep};
