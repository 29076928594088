import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
//import * as  backboneLawnchair from 'backbonelawnchair'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {BeepPackageMixin} from './BeepPackageMixin'
import {BeepPackage} from './BeepPackage'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {EObject} from '../cmof/EObject'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("beeppackage",global.version);
	export class CodeContainerMixin{
	defaults(){
		var ret = {
			type: "beeppackage_CodeContainer"
		}
		return jQuery.extend(BeepPackage.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!CodeContainerMixin.cummulativeRelations) {
            CodeContainerMixin.cummulativeRelations = _.union(CodeContainerMixin.getMixinRelations()
                , BeepPackageMixin.getCumulativeMixinRelations()
            );
        }
		return CodeContainerMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"beeppackage_BeepPackage"
		];
	}
	static getProperties(){
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{ name: "dataReference", type: "EString", defaultValue: "null", containingClass: "beeppackage_CodeContainer" },
			{ name: "version", type: "EString", defaultValue: "null", containingClass: "beeppackage_CodeContainer" }];
	};
    isPersisted() {
        /*if (this.getNestedParent() !== this) {
            return false;
        } else {*/
            return true;
        //}
    };
	getParent(){
		var container;
		if(!container){
			container = this.get("artifactOwner") ? this.get("artifactOwner") : this.previousAttributes().artifactOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("artifactOwner") ? this.get("artifactOwner") : this.previousAttributes().artifactOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
    };
    
	getViewProperties(type){
		return {
			templatePath : "views/cmof/views/properties/CodeContainerPropertiesTemplate.html",
			templateName : "CodeContainerPropertiesTemplate",
			viewTypeStr : "appviews/cmof/views/properties/CodeContainerViewModel",
			tabId : "CodeContainerView",
			tabName: "CodeContainer"
		}
	}
	//
}
path.CodeContainerMixin = CodeContainerMixin;