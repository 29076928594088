import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Port} from './Port'
import {InputPortMixin} from './InputPortMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ResourceUse } from './ResourceUse'
import { InputDelegation } from './InputDelegation'
import { Assignment } from './Assignment'
import { BusinessItemDefinition } from './BusinessItemDefinition'
import { Expression } from './Expression'
import { DeliverableFlow } from './DeliverableFlow'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Port","appbo/vdml/InputPortMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Port,InputPortMixin
, DeliverableFlow
, ResourceUse
, InputDelegation
, Assignment
, BusinessItemDefinition
, Expression){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!DeliverableFlow){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.DeliverableFlow){
			DeliverableFlow = importPath.DeliverableFlow;
		}
        else {
            import('./DeliverableFlow').then(({ default: DeliverableFlow }) => {
                DeliverableFlow = DeliverableFlow;
            });
			/*require(["appbo/vdml/DeliverableFlow"],function(loadedModule){
				DeliverableFlow = loadedModule;
			});*/
		}
	}
	if(!ResourceUse){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ResourceUse){
			ResourceUse = importPath.ResourceUse;
		}
        else {
            import('./ResourceUse').then(({ default: ResourceUse }) => {
                ResourceUse = ResourceUse;
            });
            /*require(["appbo/vdml/ResourceUse"], function (loadedModule) {
                ResourceUse = loadedModule;
			});*/
		}
	}
	if(!InputDelegation){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.InputDelegation){
			InputDelegation = importPath.InputDelegation;
		}
        else {
            import('./InputDelegation').then(({ default: InputDelegation }) => {
                InputDelegation = InputDelegation;
            });
            /*require(["appbo/vdml/InputDelegation"], function (loadedModule) {
                InputDelegation = loadedModule;
			});*/
		}
	}
	if(!Assignment){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Assignment){
			Assignment = importPath.Assignment;
		}
        else {
            import('../vdml/Assignment').then(({ default: Assignment }) => {
                Assignment = Assignment;
            });
            /*require(["appbo/vdml/Assignment"], function (loadedModule) {
                Assignment = loadedModule;
			});*/
		}
	}
	if(!BusinessItemDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemDefinition){
			BusinessItemDefinition = importPath.BusinessItemDefinition;
		}
        else {
            import('../vdml/BusinessItemDefinition').then(({ default: BusinessItemDefinition }) => {
                BusinessItemDefinition = BusinessItemDefinition;
            });
            /*require(["appbo/vdml/BusinessItemDefinition"], function (loadedModule) {
                BusinessItemDefinition = loadedModule;
			});*/
		}
	}
	if(!Expression){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Expression){
			Expression = importPath.Expression;
		}
        else {
            import('../vdml/Expression').then(({ default: Expression }) => {
                Expression = Expression;
            });
           /* require(["appbo/vdml/Expression"], function (loadedModule) {
                Expression = loadedModule;
			});*/
		}
	}
	var InputPort = Port.extend(utils.customExtends({
		relations:InputPortMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Port.prototype.initialize.apply(this, arguments);
			/*this.on('destroy',checkAndRemoveFlow);
			this.on("change:name", this.handleInputPortNameChange);*/
		}
		}   
		, new InputPortMixin()
	));
    InputPort['destroy'] = function checkAndRemoveFlow(model) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
			var flow = model.previousAttributes().input;
			if (flow) {
				if (flow.get('provider')) {
					flow.get('provider').destroy();
				}
				flow.destroy();
			}
		}
    }	
    InputPort["change:name"] = function (model,newName) {
		var previousName = model._previousAttributes ? model._previousAttributes.name:"";
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
			if(previousName != newName) {
				this.handleInputPortNameChange(model, newName);
			}
		}
    };
	
	
	InputPort.abstract = false;
	path.InputPort = InputPort;
	export {InputPort};
//});