import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import * as bootbox from '../../../../libs/bootbox/bootbox'
import {NamedPlanElement} from './NamedPlanElement'
import {NamedPlanElementMixin} from './NamedPlanElementMixin'
import {PhaseObjectiveSet} from './PhaseObjectiveSet'
import {ScenarioProxy} from './ScenarioProxy'
import {PackageReference} from './PackageReference'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { ValueElementMixin } from '../vdml/ValueElementMixin'


//define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/transformation/NamedPlanElement","appbo/transformation/NamedPlanElementMixin","appcommon/com/vbee/rdf/RDFModel"],
/*function(require,jQuery,_,async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,NamedPlanElement,NamedPlanElementMixin,RDFModel
, PhaseObjectiveSet
, ScenarioProxy
, PackageReference){*/
	
	if(!PhaseObjectiveSet){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.PhaseObjectiveSet){
			PhaseObjectiveSet = importPath.PhaseObjectiveSet;
		}
        else {
            import('./PhaseObjectiveSet').then(loadedModule => {
                PhaseObjectiveSet = loadedModule;
            });
			/*require(["appbo/transformation/PhaseObjectiveSet"],function(loadedModule){
				PhaseObjectiveSet = loadedModule;
			});*/
		}
	}
	if(!ScenarioProxy){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.ScenarioProxy){
			ScenarioProxy = importPath.ScenarioProxy;
		}
		else{
            import('./ScenarioProxy').then(loadedModule => {
                ScenarioProxy = loadedModule;
            });
			/*require(["appbo/transformation/ScenarioProxy"],function(loadedModule){
				ScenarioProxy = loadedModule;
			});*/
		}
	}
	if(!PackageReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.PackageReference){
			PackageReference = importPath.PackageReference;
		}
		else{
			import('./PackageReference').then(loadedModule => {
                PackageReference = loadedModule;
            });
			/*require(["appbo/transformation/PackageReference"],function(loadedModule){
				PackageReference = loadedModule;
			});*/
		}
	}
	var path = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
	export class AlternativeMixin{
	defaults(){
		var ret = {
			type: "transformation_Alternative",
			id:window.projectGuidGenerator()
		}
		return jQuery.extend(NamedPlanElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"transformation_Alternative",
			key:"phaseObjectiveSet",
			relatedModel:"transformation.PhaseObjectiveSet",
			reverseRelation: {
				key:"phaseObjectiveSetOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"transformation_Alternative",
			key:"designScenario",
			relatedModel:"transformation.ScenarioProxy",
			reverseRelation: {
				key:"designScenarioOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"transformation_Alternative",
			key:"phaseDesignPart",
			relatedModel:"transformation.PackageReference",
			reverseRelation: {
				key:"phaseDesignPartOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!AlternativeMixin.cummulativeRelations) {
            AlternativeMixin.cummulativeRelations = _.union(AlternativeMixin.getMixinRelations()
                , NamedPlanElementMixin.getCumulativeMixinRelations()
            );
        }
		return AlternativeMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"transformation_NamedPlanElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("phaseAlternativeOwner") ? this.get("phaseAlternativeOwner") : this.previousAttributes().phaseAlternativeOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("phaseAlternativeOwner") ? this.get("phaseAlternativeOwner") : this.previousAttributes().phaseAlternativeOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
        return AlternativeMixin.getDialogViewProperties(type);
    }
	getMixinClass(){
		return AlternativeMixin;
	}

	showAggregationView(valueId, callBack) {
		// var valueId = valModal ? valModal.id : valId
		var self = this;
		// const altModel = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: self.id });
		let valueModal = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: valueId});
		if(!valueModal){
			valueModal = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: valueId });
		}
		if (valueModal) {
			let id = "aggregation" +  window.utils.htmlEscape(valueId);
			var mainScenario = self.getMainScenario(valueModal.getNestedParent());
			let mest = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(valueModal.get('valueMeasurement'));
			if (!mest) {
				mest = valueModal;
			}
			let addOptions = { 'currentAlternativeModel': self};
			window.getAndCreateModalDialog(true, id, ValueElementMixin, mest, "AggregationView", function (changeInValue) {
				if (changeInValue) {
					callBack()
				}
			}, addOptions);
		} else {
			bootbox.alert('Value does not exist');
		}
	};
	
	static getDialogViewProperties(type){
		if(type=="AlternativeDetails")
		{
		return {
			templatePath : "views/transformation/views/properties/AlternativeDetailsTemplate.html",
			templateName : "AlternativeDetailsTemplate",
			viewTypeStr : "appviews/transformation/views/properties/AlternativeDetailsViewModel",
			tabId : "AlternativeDetailsView",
			tabName: "Alternative"
			}
		}
		else if(type=="AlternativeInputValues")
		{
		return {
			templatePath : "views/transformation/views/properties/AlternativeInputValuesTemplate.html",
			templateName : "AlternativeInputValuesTemplate",
			viewTypeStr : "appviews/transformation/views/properties/AlternativeInputValuesViewModel",
			tabId : "AlternativeInputValuesView",
			tabName: "AlternativeInputValues"
			}
		}
		else
		{
		return {
			templatePath : "views/transformation/views/properties/AlternativePropertiesTemplate.html",
			templateName : "AlternativePropertiesTemplate",
			viewTypeStr : "appviews/transformation/views/properties/AlternativeViewModel",
			tabId : "AlternativeView",
			tabName: "Alternative"
		}
    }
    
  }
	
//#startCustomMethods

    getDisallowedNames (vdmlObj, type) {
        var self = this;
        var disallowedNames = []; 
        var modelType = vdmlObj.get('type').replace('_', '.');
        var parentModelType = vdmlObj.getParent().get('type').replace('_', '.');
          var previousPhasePrimary = self.getPreviousPhasePrimary();
        if (previousPhasePrimary) {
            var parentPreviousPhaseComp = Backbone.Relational.store.getObjectByName(parentModelType).find({ id: previousPhasePrimary.id + window.utils.getSuffix(vdmlObj.getParent().id) });
            var previousPhaseVal = Backbone.Relational.store.getObjectByName(modelType).find({ id: previousPhasePrimary.id + window.utils.getSuffix(vdmlObj.id) });
            if (parentPreviousPhaseComp) {
                //self.previousPhaseValName = previousPhaseVal ? previousPhaseVal.get('name') : null;
                var compParent = parentPreviousPhaseComp.get('' + type);
                if (compParent) {
                    _.each(compParent.models, function (comp) {
                        if (comp.get('name') != vdmlObj.get('name') && vdmlObj.get('type') == comp.get('type')) {
                            if (vdmlObj.get('type') === "vdml_ValueProposition" && vdmlObj.get('isValueFormula') != comp.get('isValueFormula')) {
                                return;
                            }
							if(window.utils.getSuffix(vdmlObj.get('id')) != window.utils.getSuffix(comp.get('id'))){
								disallowedNames.push(comp.get('name'));
							}
                        }
                    });
                }
            }
        }
        var nextPhase = self.getParent().get('nextPhase');
        var nextPhasePrimary = nextPhase ? nextPhase.get('primary') : null;
        if (nextPhasePrimary) {
            var parentNextPhaseComp = Backbone.Relational.store.getObjectByName(parentModelType).find({ id: nextPhasePrimary.id + window.utils.getSuffix(vdmlObj.getParent().id) });
            if (parentNextPhaseComp) {
                var nextPhaseVal = Backbone.Relational.store.getObjectByName(modelType).find({ id: nextPhasePrimary.id + window.utils.getSuffix(vdmlObj.id) });
                //self.nextPhaseValName = nextPhaseVal ? nextPhaseVal.get('name') : null;
                var compParent = parentNextPhaseComp.get('' + type);
                if (compParent) {
                    _.each(compParent.models, function (comp) {
                        if (comp.get('name') != vdmlObj.get('name') && vdmlObj.get('type') == comp.get('type')) {
                            if (vdmlObj.get('type') === "vdml_ValueProposition" && vdmlObj.get('isValueFormula') != comp.get('isValueFormula')) {
                                return;
                            }
							if(window.utils.getSuffix(vdmlObj.get('id')) != window.utils.getSuffix(comp.get('id'))){
								disallowedNames.push(comp.get('name'));
							}
                        }
                    });
                }
            }
        }
        return disallowedNames;
    }

	getOwnedVDMLPackages(){
		var self = this;
		var ownedVDMLPackagesIds = [];
		var packRef = self.get('phaseDesignPart');
		for(var i=0;i<packRef.length;i++){
			var pacRef = packRef.at(i);
			var beepRef = pacRef.get('beepReference');
			if(beepRef.indexOf('Common') == -1 && pacRef.get('beepType') === "vdml_ValueDeliveryModel"){
				if(DataManager.getDataManager().getRepositoryId(pacRef.get('beepReference')) == self.get('id')){
					ownedVDMLPackagesIds.push(beepRef);
				}				
			}
		}
		return ownedVDMLPackagesIds;
	}

	getPreviousAlternativeForScenario(){
		var currentAlt = this;
		var currentPhase = this.get('phaseAlternativeOwner');
		var master = currentPhase.get('master');
		if(currentAlt !== master){
			currentAlt = master;
		}else{
			currentPhase = currentPhase.get('previousPhase');
			if(currentPhase){
				currentAlt = currentPhase.get('primary');	
			}
		}
		if(currentAlt === this){
			return null;
		}
		return currentAlt;
	};

	getUsePreviousRel(callback){
		var self = this;	
		var obsList = self.getOrCreatePhaseObjectiveSet().get('objectiveObservation');
		var usePreviousRelList = [];
		function checkPreviousRel(obsList){
			_.each(obsList.models,function(observation){
				if(!observation.get('whenObserved')){
					_.each(observation.get('observedMeasures').models,function(obsMeasure){
						if(obsMeasure.get('measure').get('type') == "smm_CollectiveMeasure"){
							_.each(obsMeasure.get('measurements').models,function(msmt){
								_.each(msmt.get('measurementRelationships').models,function(rel){
									if(rel.get('definition') && rel.get('definition').get('usePreviousAlternative')){
										usePreviousRelList.push(rel);
									}
								});
							});
						}
					});
				}
			});
		}
		checkPreviousRel(obsList);
			
		self.getMainScenariosofAllPackages(null,function(scenario){
			for (var key in scenario){
				if(key) {//TODO not check referred
					checkPreviousRel(scenario[key].get('contextObservation'));
				}
			}
			callback(usePreviousRelList);
		});
	}
	
	saveDirectValues(valueList,timestamp){
		var self = this;
		var isTimeStamp = false;
		if(timestamp){
			isTimeStamp = true;
		}
		for(var i=0;i<valueList.length;i++){
			var newValue = valueList[i].value;
			var valueId = valueList[i].id;
			var valueModal = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: valueId });
			if(!valueModal){
				valueModal = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: valueId });
			}
			if(valueModal) {
				var altComp = self.getModelLinkedSync(valueModal);
				var altScenario = altComp?self.getMainScenario(altComp.getNestedParent()):null;
				var currentMestObs = altScenario?altScenario.getObservedMeasureWithMeasurements(altComp.get('valueMeasurement'),isTimeStamp,timestamp):null;
				var currentMest = currentMestObs?currentMestObs.get('measurements').at(0):null;
				if(currentMest) {
					altScenario.set('localChange',true);
					currentMest.set('value',newValue);
					currentMest.calculate();
				}else{
					currentMestObs = altScenario?altScenario.getObservedMeasureWithMeasurements(altComp.get('satisfactionLevel'),isTimeStamp,timestamp):null;
					currentMest = currentMestObs?currentMestObs.get('measurements').at(0):null;
					if(currentMest){
						altScenario.set('localChange',true);
						currentMest.set('symbol',newValue);
					}
				}
			}
		}
	};
	
	destroyScenariosAndPackages(callback){
		var self = this;
		var plan = self.get('phaseAlternativeOwner').get('phaseOwner');
		var designParts = self.get('phaseDesignPart').models.concat();
		var designScenario = self.get('designScenario');
		var refAlts = self.getAlternativesWhereICanBeReffered();
		var beepPackages = [];
		async.eachSeries(designParts, function(packageRef,packageCallback){
				if(packageRef.get('isMain')) {
				    self.getBeepPackageWithId(packageRef.get('beepReference'), packageRef.get('beepType'), function (beepPackage) {
				    	if(beepPackage && beepPackage.get('type') !== 'report_BeepReport'){
							beepPackages.push(beepPackage);
						}
						packageCallback();
					});
				}else{
					packageCallback();
				}
			}, function(err){
				function handlePackages(){
					_.each(beepPackages,function(beepPackage){
					//for(var i=0;i<beepPackages.length;i++){
						//var beepPackage = beepPackages[i];
						/*if(beepPackage.get('type') === 'report_BeepReport'){
							continue;
						}*/
						beepPackage.destroy = beepPackage.constructor.prototype.destroy;
						if(beepPackage) {
							var packRepId = DataManager.getDataManager().getRepositoryId(beepPackage.get('id'));
							if(packRepId === self.get('id')) {//only it's own packages
								for(var j=0;j<refAlts.length;j++){
								    for(var i=0;beepPackage.get('scenario') && i<beepPackage.get('scenario').length;i++){
										var scenarioId = beepPackage.get('scenario').at(i).get('id');
										var forwardDesignScenario = refAlts[j].get('designScenario').findWhere({'scenario':scenarioId});
										if(forwardDesignScenario){
											forwardDesignScenario.destroy();
											break;
										}
									}	
								}
								if(beepPackage.get('dependentPackage')) {
									beepPackage.get('dependentPackage').reset();
								}
								//plan.get('dependentPackage').remove(beepPackage);
								var planCriterionSet = plan.get('planCriterionSet');
								if(planCriterionSet && beepPackage.get('scenario')) {
									var planComponents = planCriterionSet.get('component');
									planComponents.each(function(comp){
										var aggTo = comp.get('aggregatedTo');
										var aggToLength = aggTo.length;
										while(aggToLength--){
											if(DataManager.getDataManager().getRepositoryId(aggTo.at(aggToLength).get('id')) === packRepId) {
												aggTo.remove(aggTo.at(aggToLength));
											}
										}	
										var aggFrom = comp.get('aggregatedFrom');
										var aggFromLength = aggFrom.length;
										while(aggFromLength--){
											if(DataManager.getDataManager().getRepositoryId(aggFrom.at(aggFromLength).get('id')) === packRepId) {
												aggFrom.remove(aggFrom.at(aggFromLength));
											}
										}
									});
								}
								DataManager.getDataManager().removeInitializedPackage(beepPackage.get('id'));								
								beepPackage.destroy();
								
							}
							else {
							    var beepPackageScenariosLength = beepPackage.get('scenario') ? beepPackage.get('scenario').length : 0;
								while(beepPackageScenariosLength--){
								//for(var i=0;i<beepPackage.get('scenario').length;i++){
									var scenarioId = beepPackage.get('scenario').at(beepPackageScenariosLength).get('id');
									var scenarioExists = designScenario.findWhere({'scenario':scenarioId});
									if(scenarioExists) {
										beepPackage.get('scenario').at(beepPackageScenariosLength).destroy();
									}
								}
							}						
						}					
					});
					var phaseObjectiveSet = self.get('phaseObjectiveSet');
					if(phaseObjectiveSet){
						var objObservation = phaseObjectiveSet.get('objectiveObservation');
						var len = objObservation.length;
						while(len--){
							objObservation.at(len).destroy();
						}
						phaseObjectiveSet.destroy();
					}
					beepPackages.length = 0;
			        callback();					
				}
				setTimeout(handlePackages,1000);
		});		
	};	

	// get alternatives that have packages refering to old beeppackage in this alternative

	
	getAlternativesWhereICanBeReffered(limitToSinglePhase){
		var ret = [];
		var currentPhase = this.get('phaseAlternativeOwner');
		var master = currentPhase.get('master');
		var primary = currentPhase.get('primary');
		if(master === this){
			ret = ret.concat(currentPhase.get('phaseAlternative').models);
		}else{
			ret.push(this);	
		}
		if (limitToSinglePhase) {
		    return ret;
		}
		if(this === master || this === primary){
		    var nextPhase = currentPhase.get('nextPhase');
		    while (nextPhase) {
				ret = ret.concat(nextPhase.get('phaseAlternative').models);
				nextPhase = nextPhase.get('nextPhase');
			}
		}
		return ret;		
	};
	getClonedAltsWhereICanBeReffered() {
		//to get all the alternatives which are linked from current alternative recursively
		const ids = [this.get('id')];
		const result = [];
		function traverseWithinPhase(phase, ids) {
			const phaseAlternatives = phase.get('phaseAlternative').models;
			phaseAlternatives.forEach(alternative => {
				const clonedFrom = alternative.get('clonedFrom');
				const alternativeId = alternative.get('id');
				if (ids.includes(clonedFrom) && !ids.includes(alternativeId)) {
					ids.push(alternativeId);
					result.push(alternative);
				}
			});
		}
		function traverseAllPhases(thisPhase, ids) {
			let currentPhase = thisPhase;
			while (currentPhase) {
				traverseWithinPhase(currentPhase, ids);
				currentPhase = currentPhase.get('nextPhase');
			}
		}
		const currentPhase = this.get('phaseAlternativeOwner');
		traverseAllPhases(currentPhase, ids);
		return result;
	}
	getAltsHavingPackage(packId){
		var self = this;
        var nextAlts = self.getClonedAltsWhereICanBeReffered();
        var packAltList = [];
        _.each(nextAlts,function(alt){
            var altPhModels= alt.get('phaseDesignPart').models;
            for(var i=0; i < altPhModels.length; i++){
                if(altPhModels.at(i).get('beepReference') == packId){
                    packAltList.push(alt);
                    break;
                }
            }
        });
        packAltList = _.uniq(packAltList);
        return packAltList;
	}
	getFilterPackageIdsWhereICanBeReffered(targetAlt){
		var packageIds = [];
		var currentAlt = targetAlt;
		function addPackages(alt){
			var designParts = alt.get('phaseDesignPart');
			designParts.each(function(packRef){
				var beepRef = packRef.get('beepReference');
				var beepRefSuffix = beepRef.substr(beepRef.lastIndexOf('@')+ 1);
				var skip = false;
				_.each(packageIds,function(packId){
					var packIdSuffix = packId.substr(packId.lastIndexOf('@')+ 1);
					if(!skip && packIdSuffix === beepRefSuffix){
						skip = true;
					}
				});
				if(!skip){
					packageIds.push(beepRef);
				}
			});			
		}
		while(currentAlt !== this){
			if(!currentAlt){
				break;
			}
			addPackages(currentAlt);
			var currentPhase = currentAlt.get('phaseAlternativeOwner');
			var master = currentPhase.get('master');
			if(currentAlt !== master){
				currentAlt = master;
			}else{
				currentPhase = currentPhase.get('previousPhase');
				if(currentPhase){
					currentAlt = currentPhase.get('primary');
				}else{
					break;
				}
				
			}
		}
		if(currentAlt === this){
			addPackages(currentAlt);
		}	
		return _.uniq(packageIds);		
	};
	getPreviousPhasePrimary(){
		var currentAlt = this;
		var currentPhase = this.get('phaseAlternativeOwner');
		/*var primary = currentPhase.get('primary');
		if(currentAlt !== primary){
			currentAlt = primary;
		}else{*/
			currentPhase = currentPhase.get('previousPhase');
			if(currentPhase){
				currentAlt = currentPhase.get('primary');	
			}
		/*}*/
		if(currentAlt === this){
			return null;
		}
		return currentAlt;
	};
	
	getMasterPrimaryAlternativesOfPackage(currentAlternative,pack){
		var altsList = [];		
		function fetchNext(alt, notion) {
			var phase = alt.getParent();
			var master = phase.get(''+notion)?phase.get(''+notion).get('master'):null;
			if(master) {
				altsList.push(master);
				var primary = phase.get(''+notion).get('primary');
				if(primary != master){
					altsList.push(primary);
				}
				fetchNext(primary,notion);
			}
		}
		fetchNext(currentAlternative,'previousPhase');
		fetchNext(currentAlternative,'nextPhase');
		altsList = _.uniq(altsList);
        /*for(var i=0;i<altsWithRefs.length;i++){
        	var revisionExists = false;
        	if(altsWithRefs[i].get('phaseAlternativeOwner') === currentPhase && altsWithRefs[i].get('phaseAlternativeOwner').get('master') !== currentAlternative){
				continue;
			}
        	if(altsWithRefs[i] !== currentAlternative) {
        		var designParts = altsWithRefs[i].get('phaseDesignPart'); 
        		for(var j=0;j<designParts.length;j++){
        			var designbeepId = designParts.at(j).get('beepReference');
        			var beepRefSuffix = designbeepId.substr(designbeepId.lastIndexOf('@')+ 1);
        			var packageSuffix = self.get('id').substr(self.get('id').lastIndexOf('@')+ 1);
        			if(packageSuffix === beepRefSuffix) {
	        			revisionExists = true;
	        			break;
        			}
        		}
        	}
        }*/
       return altsList;
	}	
	
	getFilterPackageIdsThatICanReffer(targetAlt){
		var packageIds = [];
		var currentAlt = this;
		function addPackages(alt){
			var designParts = alt.get('phaseDesignPart');
			designParts.each(function(packRef){
				var beepRef = packRef.get('beepReference');
				var beepRefSuffix = beepRef.substr(beepRef.lastIndexOf('@')+ 1);
				var skip = false;
				_.each(packageIds,function(packId){
					var packIdSuffix = packId.substr(packId.lastIndexOf('@')+ 1);
					if(!skip && packIdSuffix === beepRefSuffix){
						skip = true;
					}
				});
				if(!skip){
					packageIds.push(beepRef);
				}
			});			
		}

		
		while(currentAlt !== targetAlt){
			addPackages(currentAlt);
			var currentPhase = currentAlt.get('phaseAlternativeOwner');
			var master = currentPhase.get('master');
			if(currentAlt !== master){
				currentAlt = master;
			}else{
				currentPhase = currentPhase.get('previousPhase');
				if(currentPhase){
					currentAlt = currentPhase.get('primary');
				}else{
					break;
				}
			}
		}
		if(currentAlt === targetAlt){
			addPackages(currentAlt);
		}		
		return _.uniq(packageIds);		
	};
	
	getAlternativesThatICanReffer(){
		var ret = [];
		function getPhaseAlts(alt){
			ret.push(alt);
			var altPhase = alt.get('phaseAlternativeOwner');
			var master = altPhase.get('master');
			if(master !== alt){
				ret.push(master);
			}
		}
		getPhaseAlts(this);
		var currentPhase = this.get('phaseAlternativeOwner');
		var prevPhase = currentPhase.get('previousPhase');
		while(prevPhase){
			var primary = prevPhase.get('primary');
			if(primary) {
				getPhaseAlts(primary);
				prevPhase =  primary.get('phaseAlternativeOwner').get('previousPhase');
			}
			else {
				break;
			}
		}
		return ret;
	};

	getMainScenarioOfBeepPackageWithId(beepPackageId,version,callback,packageCollectionCache){
		var self = this;
		if(packageCollectionCache){
			var cacheModel = packageCollectionCache.get(beepPackageId);
			if(cacheModel){
				if(callback){
					callback(self.getMainScenario(cacheModel));	
				}
				return;
			}			
		}
		var fetcModelAndGetMainScenario=function(wsData){
			var vdmStore = wsData.get('vdmStore');
			if(vdmStore){
				var model = DataManager.getDataManager().fetchDocumentFromStoreCache(beepPackageId,"appbo/vdml/ValueDeliveryModel");
				if(model){
					if(packageCollectionCache){
						packageCollectionCache.push(model);	
					}
					if(callback){
						callback(self.getMainScenario(model));
					}
				}else{
					DataManager.getDataManager().fetchDocumentFromPackage(beepPackageId,"appbo/vdml/ValueDeliveryModel",version,beepPackageId,"appbo/vdml/ValueDeliveryModel",vdmStore,{
						success:function(model){
							if(packageCollectionCache){
								packageCollectionCache.push(model);	
							}
							if(callback){
								callback(self.getMainScenario(model));
							}
						},
						error:function(error){
							console.log('Unable to load selected Package');
							if(callback){
								callback();
							}				
						}
					});
				}
			}else{
				if(callback){
					callback();	
				}
			}
		};
		
		var wsId = beepPackageId.substr(0,beepPackageId.lastIndexOf('@')+ 1);
		var wsData = DataManager.getDataManager().getWorkspaceDataWithIdSync(wsId);
		if(wsData){
			fetcModelAndGetMainScenario(wsData);
		}else{
			DataManager.getDataManager().getWorkspaceDataWithId(wsId,fetcModelAndGetMainScenario);
		}
	};
	
	getMainScenariosofAllPackages(packageCollectionCache,callback){
		var self = this;
		var scenarios = {};
		var packRefs = self.get('phaseDesignPart');
		async.eachSeries(packRefs.models,function(packRef,packageHandled){
			var beepRef = packRef.get('beepReference');
			var version = packRef.get('version')
			if(packRef.get('beepType') === 'vdml_ValueDeliveryModel'){
				self.getMainScenarioOfBeepPackageWithId(beepRef,version,function(mainScenario){
					if(mainScenario){
						scenarios[beepRef] = mainScenario;
					}
					packageHandled();
				},packageCollectionCache);
			}else{
				packageHandled();				
			}
		},function(){
			callback(scenarios);
		});
	};
	
	unregisterPackages(callback){
		var self = this;
		var packRefs = self.get('phaseDesignPart').models.concat();
		async.each(packRefs,function(packRef,packageHandled){
			var beepRef = packRef.get('beepReference');
			var beepRefRepId = DataManager.getDataManager().getRepositoryId(beepRef);
			if(beepRefRepId === self.get('id')){
			    self.getBeepPackageWithId(beepRef,packRef.get('beepType'), function(pack){
					if(pack){
						pack.unregisterModel(pack);
						DataManager.getDataManager().removeInitializedPackage(pack.get('id'));
						packageHandled();	
					}else{
						packageHandled();	
					}
				},true);
			}else{
				packageHandled();	
			}
		},function(err){
			callback();		
		});
	}
	
	deletePackages(callback){
		var self = this;
		var packRefs = self.get('phaseDesignPart').models.concat();
		//var packagesDestroy = [];
		var serverChangeSet = DataManager.getDataManager().get(DataManager.getDataManager().CURRENT_CHANGESET);
		async.eachSeries(packRefs,function(packRef,packageHandled){
			var beepRef = packRef.get('beepReference');
			var beepRefRepId = DataManager.getDataManager().getRepositoryId(beepRef);
			if(beepRefRepId === self.get('id')){
			    self.getBeepPackageWithId(beepRef,packRef.get('beepType'), function(pack){
					if(pack){
						//packagesDestroy.push(pack);
						var plan = self.get('phaseAlternativeOwner').get('phaseOwner');
						DataManager.getDataManager().addpackageForDelete(serverChangeSet,pack,plan);
						if(window.myWorker){
							window.myWorker.postMessage("destroy:"+packRef.id+new Date().getTime());
						}						
						setTimeout(function(){
							pack.destroy({silent:true});
							if(window.myWorker){
								window.myWorker.postMessage("destroyed:"+packRef.id+new Date().getTime());
							}
							packageHandled();
						},300);
					}else{
						packageHandled();	
					}
				});
			}else{
				packageHandled();	
			}
		},function(err){
			/*var packagesDestroyLen = packagesDestroy.length;
			while(packagesDestroyLen--){
				packagesDestroy[packagesDestroyLen].destroy({silent:true});
				DataManager.getDataManager().removeInitializedPackage(packagesDestroy[packagesDestroyLen].get('id'));
			}*/
			//setTimeout(callback,10);
			callback();		
		});
	}

	getBeepPackageWithId(beepPackageId,beepType,callback,skipIfNotLoaded){
	    var self = this;
	    if (!beepType || beepType === 'vdml_ValueDeliveryModel') {
	        self.getVDMBeepPackageWithId(beepPackageId, callback, skipIfNotLoaded);
	    } else if (beepType === 'report_BeepReport') {
	        self.getReportBeepPackageWithId(beepPackageId, callback, skipIfNotLoaded);
	    } else if (beepType === 'dashboard_Analytics') {
	        self.getDashboardBeepPackageWithId(beepPackageId, callback, skipIfNotLoaded);
	    } else {
	        self.getEcoMapBeepPackageWithId(beepPackageId, callback, skipIfNotLoaded);
	    }
	};	
	
	getVDMBeepPackageWithId(beepPackageId,callback,skipIfNotLoaded){
		var self = this;
		var dataManager = DataManager.getDataManager();
		var wsId = beepPackageId.substr(0,beepPackageId.lastIndexOf('@')+ 1);
		var vdmStore = DataManager.getDataManager().getVDMStore(wsId);
		var model = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({id:beepPackageId});
		if(model || skipIfNotLoaded){
			if(callback){
				callback(model);
				return;
			}
		}
		function fetchDoc(vdmStore){
			DataManager.getDataManager().fetchDocumentFromPackage(beepPackageId,"appbo/vdml/ValueDeliveryModel",DataManager.getDataManager().get('currentVDMVersion'),beepPackageId,"appbo/vdml/ValueDeliveryModel",vdmStore,{
				success:function(model){
					if(callback){
						callback(model);
					}
				},
				error:function(error){
					console.log('Unable to load selected Package');
					if(callback){
						callback();
					}				
				}
			});
		}
		if(!vdmStore){
			var wsId = beepPackageId.substr(0,beepPackageId.lastIndexOf('@')+ 1);
			dataManager.getWorkspaceDataWithId(wsId,function(wsData){
				if(!wsData) {
					callback();
					return;
				}else {
					fetchDoc(wsData.get('vdmStore'));
				}
			});
		}else {
			fetchDoc(vdmStore);
		}
	};	
	getReportBeepPackageWithId(beepPackageId,callback,skipIfNotLoaded){
		var self = this;
		var dataManager = DataManager.getDataManager();
		var wsId = beepPackageId.substr(0,beepPackageId.lastIndexOf('@')+ 1);
		var vdmStore = DataManager.getDataManager().getVDMStore(wsId);
		var model = Backbone.Relational.store.getObjectByName('report.BeepReport').find({id:beepPackageId});
		if(model || skipIfNotLoaded){
			if(callback){
				callback(model);
				return;
			}
		}
		DataManager.getDataManager().fetchDocumentFromPackage(beepPackageId,"appbo/report/BeepReport",DataManager.getDataManager().get('currentVDMVersion'),beepPackageId,"appbo/report/BeepReport",vdmStore,{
			success:function(model){
				if(callback){
					callback(model);
				}
			},
			error:function(error){
				console.log('Unable to load selected Package');
				if(callback){
					callback();
				}				
			}
		});
	};	
	getEcoMapBeepPackageWithId(beepPackageId,callback,skipIfNotLoaded){
	    var self = this;
	    var dataManager = DataManager.getDataManager();
	    var wsId = beepPackageId.substr(0,beepPackageId.lastIndexOf('@')+ 1);
	    var vdmStore = DataManager.getDataManager().getVDMStore(wsId);
	    var model = Backbone.Relational.store.getObjectByName('ecomap.EcoMap').find({id:beepPackageId});
	    if(model || skipIfNotLoaded){
	        if(callback){
	            callback(model);
	            return;
	        }
	    }
	    DataManager.getDataManager().fetchDocumentFromPackage(beepPackageId,"appbo/ecomap/EcoMap",DataManager.getDataManager().get('currentVDMVersion'),beepPackageId,"appbo/ecomap/EcoMap",vdmStore,{
	        success:function(model){
	            if(callback){
	                callback(model);
	            }
	        },
	        error:function(error){
	            console.log('Unable to load selected Package');
	            if(callback){
	                callback();
	            }				
	        }
	    });
	};
	
	getDashboardBeepPackageWithId(beepPackageId,callback,skipIfNotLoaded){
	    var self = this;
	    var wsId = beepPackageId.substr(0,beepPackageId.lastIndexOf('@')+ 1);
	    var vdmStore = DataManager.getDataManager().getVDMStore(wsId);
	    var model = Backbone.Relational.store.getObjectByName('dashboard.Analytics').find({id:beepPackageId});
	    if(model || skipIfNotLoaded){
	        if(callback){
	            callback(model);
	            return;
	        }
	    }
	    DataManager.getDataManager().fetchDocumentFromPackage(beepPackageId,"appbo/dashboard/Analytics",DataManager.getDataManager().get('currentVDMVersion'),beepPackageId,"appbo/dashboard/Analytics",vdmStore,{
	        success:function(model){
	            if(callback){
	                callback(model);
	            }
	        },
	        error:function(error){
	            console.log('Unable to load selected Package');
	            if(callback){
	                callback();
	            }				
	        }
	    });
	};
	getMainScenario(beepPackage){
		if(beepPackage.get('type') === 'transformation_Plan'){
			return this.getOrCreatePhaseObjectiveSet();
		}
		var designParts = this.get('phaseDesignPart');
		for(var i=0;i<designParts.length;i++){
			var designPart = designParts.at(i);
			if(designPart.get('beepReference') === beepPackage.get('id')){
				if(designPart.get('isMain') === true){
					var designScenarios = this.get('designScenario');
					for(var j=0;j<designScenarios.length;j++){
						var proxyScenario = designScenarios.at(j);
						var packageScenarios = beepPackage.get('scenario');
						if (!packageScenarios) {
						    continue;
						}
						for(var k=0;k<packageScenarios.length;k++){
							var pacScenario = packageScenarios.at(k);
							if(pacScenario.get('id') === proxyScenario.get('scenario')){
								this.cacheMainScenario(beepPackage.id,pacScenario);
								return pacScenario;
							}
						}				
					}			
				}
				break;
			}
		}		
	};
	cacheMainScenario(packageId,pacScenario){
		var altScenarios = DataManager.getDataManager().altScenarios;
		if(!altScenarios){
			altScenarios = {};
			DataManager.getDataManager().altScenarios = altScenarios;
		}
		var planId = this.getNestedParent().id;
		if(!altScenarios[planId]){
			altScenarios[planId] = {};
		}
		if(!altScenarios[planId][this.id]){
			altScenarios[planId][this.id] = {};
		}
		altScenarios[planId][this.id][packageId] = pacScenario;
	};
	/*getCountOfDocumentsOfType(type,callback,skipPlanDocs,includeCommonAlt){
		var self = this;
		var alts;
		alts = self.getAlternativesThatICanReffer();
		if(!skipPlanDocs){
			var plansWsData = DataManager.getDataManager().getWorkspaceDataWithIdSync(window.plansKey);	// some object with id = window.planskey
			alts = alts.concat(plansWsData);		
		}
		alts = _.uniq(alts);
		var ret = {count:0};
		if(alts.length>0){
			var rdfModels = [];
			_.each(alts,function(alt){
				rdfModels.push(DataManager.getDataManager().getRDFModel(alt.get('id')));
			});
			if (includeCommonAlt) {
                var commonWSData = DataManager.getDataManager().getWorkspaceDataWithIdSync(DataManager.getDataManager().get('currentPlan').getCommonRepositoryId());
                rdfModels.push(commonWSData.get('rdfModel'));
            }
			var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
			function handledResult(result){
				if(result && result.count){
					ret.count = (ret.count ? ret.count : 0) + result.count;
				}
				rdfModels.length = 0;
				callCallback();				
			}			
			if(altRDFModel){
				altRDFModel.getCountOfDocumentsOfType(type,handledResult,rdfModels);	
				return;
			}else{
				callCallback();
			}			
		}else{
			callCallback();
		}
		function callCallback(){
			alts.length = 0;
			if(callback){
				callback(ret);
			}				
		}
	};*/
	getClonedAltsThatICanReffer() {
		//To get the cloned alternatives using clonedFrom attribute
		var self = this;
		var ret = [];
		ret.push(self);
		function getClonedAlternative(clonedFromAltId) {
			var clonedFromAlt = window.utils.getElementModel(clonedFromAltId, ['transformation.Alternative']);
			if(!clonedFromAlt){
				return;
			}
			ret.push(clonedFromAlt);
			var clonedFromClonedId = clonedFromAlt.get('clonedFrom');
			if (clonedFromClonedId) {
				getClonedAlternative(clonedFromClonedId);
			}
		}
		var clonedFromAltId = self.get('clonedFrom');
		if (clonedFromAltId) {
			getClonedAlternative(clonedFromAltId);
		}
		return ret;
	}
	
    getAllDocumentsCollectionOfType (type, skipPackages, callback, queryExt, refferedTo, nameNS, includeCommonAlt, skipParents, getDesc){
		var self = this;
		var alts;
		if(!refferedTo){
			alts = self.getClonedAltsThatICanReffer();
			// alts = self.getAlternativesThatICanReffer();	
		}else{
			alts = self.getAlternativesWhereICanBeReffered();
        }
		alts = _.uniq(alts);
		var ret = new Backbone.Collection();
		var rdfModels = [];		
		var filterPackages = [];
		async.each(alts,function(alt,altWSLoaded){
			DataManager.getDataManager().getModelIdInitializedWSData(alt.id,function(wsData){
				if(wsData) {
					rdfModels.push(wsData.get('rdfModel'));
					if(!refferedTo){
						filterPackages = filterPackages.concat(self.getFilterPackageIdsThatICanReffer(alt));
					}else{
						filterPackages = filterPackages.concat(self.getFilterPackageIdsWhereICanBeReffered(alt));
					}	
				}
				altWSLoaded();
			});
        }, function () {
            if (includeCommonAlt) {
                var commonWSData = DataManager.getDataManager().getWorkspaceDataWithIdSync(includeCommonAlt);
                rdfModels.push(commonWSData.get('rdfModel'));
            }
			filterPackages = _.uniq(filterPackages);
			if(rdfModels.length>0){
				var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
				function handledResult(resultCollection){
					if(resultCollection){
						ret.add(resultCollection.models);	
					}		
					callCallback();
				}
				if(altRDFModel){
                    altRDFModel.getAllDocumentsCollectionOfType(type, handledResult, queryExt, filterPackages, nameNS, rdfModels, skipParents, getDesc);
				}else{
					callCallback();
				}			
			}else{
				callCallback();
			}
			function callCallback(){
				filterPackages.length = 0;
				rdfModels.length = 0;
				if(callback){
					callback(ret);
				}			
			}			
		});
	};

	getReferencesToModelFiltedByParents(model,parents,callback,getParents,namePropertyNS){
		var dataManager = DataManager.getDataManager();
		var alts =this.getAlternativesWhereICanBeReffered();
		var plansWsData = DataManager.getDataManager().getWorkspaceDataWithIdSync(window.plansKey);	// some object with id = window.planskey
		alts = alts.concat(plansWsData);		
		alts = _.uniq(alts);
		var rdfModels = [];	
		_.each(alts,function(alt){
			rdfModels.push(DataManager.getDataManager().getRDFModel(alt.get('id')));
		});		
		
		if(rdfModels.length>0){
			var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
			if(altRDFModel){
				altRDFModel.getReferencesToModelFiltedByParents(model,parents,callCallback,getParents,namePropertyNS,rdfModels);
			}else{
				callCallback();
			}			
		}else{
			callCallback();
		}
		function callCallback(result){
			rdfModels.length = 0;
			if(callback){
				callback(result);
			}			
		}		
	};
	
	/*getReferencesToModel2WithId(parentId,parentType,version,modelId,modelType,callback,getParents,namePropertyNS){
    	var self = this;
        var parentAltId = parentId.substr(0,parentId.lastIndexOf('@')+1);
        var vdmStore = DataManager.getDataManager().getVDMStore(parentAltId);        	
		DataManager.getDataManager().fetchDocumentFromPackage(parentId,parentType,version,modelId,modelType,vdmStore,{
			success:function(model){
				self.getReferencesToModel2(model, callback, getParents, namePropertyNS);
			},
			error:function(){
				if(callback){
					callback([]);
				}
			}
		});	        	
    },*/
    modelHasReferenceToPackage(model,packageId,callback){
    	var rdfModels = [];	
		function checkResults(refs){
			async.each(refs,function(ref,checkedReference){
				var refRDFModel = DataManager.getDataManager().getRDFModel(DataManager.getDataManager().getRepositoryId(ref.id));
				refRDFModel.getAndFilterByNestedParentForSingleResult(ref,[],function(ret){
					if(ret && (ret.parent === packageId) && (ret.predicate !== 'beeppackage_BeepPackage-dependentPackage' && ret.predicate !== 'transformation_ScenarioProxy-scenario' && ret.predicate !== 'transformation_PackageReference-beepReference')){
						checkedReference("Found Dependency");
					}else{
						checkedReference();
					}
				},'vbc:cmof_EObject-name',null,filterIds);
			},function(err){
				if(callback){
					callCallback(err);
				}						
			});
		}	
		function callCallback(err){
			rdfModels.length = 0;
			if(callback){
				callback(err ? true: false);
			}			
		}		
		//var repId = DataManager.getDataManager().getRepositoryId(model.get('id'));
		var alts = this.getAlternativesWhereICanBeReffered();
		var packRepId = DataManager.getDataManager().getRepositoryId(packageId);
		if(packRepId.indexOf('-Common@')>0){
			alts.push(DataManager.getDataManager().getWorkspaceDataWithIdSync(packRepId));
		}
		var filterIds = [];
		filterIds.push(packageId);
		var plansWsData = DataManager.getDataManager().getWorkspaceDataWithIdSync(window.plansKey);	// some object with id = window.planskey
		alts = alts.concat(plansWsData);
		
		
		async.eachSeries(alts,function(alt,gotRDFModel){
			var count = 0;
			function getRDFModel(){
				var altRDFModel = DataManager.getDataManager().getRDFModel(alt.get('id'));
				if(altRDFModel){
	 				rdfModels.push(altRDFModel)
	 				gotRDFModel();
	 			}else{
					count++;
					if(count > 10){
						gotRDFModel();
					} else {
						setTimeout(getRDFModel,3);
					}
	 			}
			}
			getRDFModel();
		},function(){
			if(rdfModels.length>0){
				var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
				if(altRDFModel){
					altRDFModel.executeReferencesToModelQuery(model,checkResults,rdfModels);
				}else{
					callCallback();
				}			
			}else{
				callCallback();
			}
		});
    }
	getReferencesToModel2(model,callback,getParents,namePropertyNS){
		var ret = [];
		var alts = this.getAlternativesWhereICanBeReffered();
		var plansWsData = DataManager.getDataManager().getWorkspaceDataWithIdSync(window.plansKey);	// some object with id = window.planskey
		alts = alts.concat(plansWsData);
		var rdfModels = [];
		async.eachSeries(alts,function(alt,gotRDFModel){
			function getRDFModel(){
				var rdfModel = 	DataManager.getDataManager().getRDFModel(alt.get('id'));
				if(rdfModel){
					rdfModels.push(rdfModel);
					gotRDFModel();
				}else{
					DataManager.getDataManager().getModelIdInitializedWSData(alt.get('id'),function(){
						var rdfModel = 	DataManager.getDataManager().getRDFModel(alt.get('id'));
						rdfModel && rdfModels.push(rdfModel);
						gotRDFModel();
					});	
					//setTimeout(getRDFModel,10)
				}
			}
			getRDFModel();
		},function(err){
			if(rdfModels.length>0){
				var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
				if(altRDFModel){
					altRDFModel.getReferencesToModel2(model,callCallback,getParents,namePropertyNS,null,null,rdfModels);
				}else{
					callCallback();
				}			
			}else{
				callCallback();
			}
			function callCallback(result){
				rdfModels.length = 0;
				if(callback){
					callback(result);
				}			
			}			
		});		
	};
	
	getReferencesToModel(modelId,callback,getParents,namePropertyNS){
		var ret = [];
		var alts = this.getAlternativesWhereICanBeReffered();
		var plansWsData = DataManager.getDataManager().getWorkspaceDataWithIdSync(window.plansKey);	// some object with id = window.planskey
		alts = alts.concat(plansWsData);
		async.each(alts,function(alt,altHandlerCallback){
			function addResults(result){
				ret = ret.concat(result);
				altHandlerCallback();
			}
			function checkAndAddRDFForModel(){
				var altRDFModel = DataManager.getDataManager().getRDFModel(alt.get('id'));
				if(altRDFModel){
					altRDFModel.getReferencesToModel(modelId,addResults,getParents,namePropertyNS);
				}else{
					setTimeout(checkAndAddRDFForModel,10);
				}
			}
			checkAndAddRDFForModel();
		},
		function(err){
			if(callback){
				callback(ret);
			}			
		});
	};

	getMeasurementParents(measurementId,callback){
		var alts = this.getAlternativesWhereICanBeReffered();
		var plansWsData = DataManager.getDataManager().getWorkspaceDataWithIdSync(window.plansKey);	// some object with id = window.planskey
		alts = alts.concat(plansWsData);		
		alts = _.uniq(alts);
		var rdfModels = [];	
		_.each(alts,function(alt){
			rdfModels.push(DataManager.getDataManager().getRDFModel(alt.get('id')));
		});
		if(rdfModels.length>0){
			var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
			if(altRDFModel){
				altRDFModel.getMeasurementParents(measurementId,callCallback,rdfModels);
			}else{
				callCallback();
			}			
		}else{
			callCallback();
		}
		function callCallback(result){
			rdfModels.length = 0;
			if(callback){
				callback(result);
			}			
		}		
	};

	getReverseAssociationInstances(modelId,associationPredicate,namePropertyNS,skipPackages,callback){
		var alts = this.getAlternativesWhereICanBeReffered();
		var plansWsData = DataManager.getDataManager().getWorkspaceDataWithIdSync(window.plansKey);	// some object with id = window.planskey
		alts = alts.concat(plansWsData);		
		alts = _.uniq(alts);
		var rdfModels = [];	
		_.each(alts,function(alt){
			rdfModels.push(DataManager.getDataManager().getRDFModel(alt.get('id')));
		});	
		if(rdfModels.length>0){
			var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
			if(altRDFModel){
				altRDFModel.getReverseAssociationInstances(modelId,associationPredicate,namePropertyNS,callCallback,[],rdfModels);
			}else{
				callCallback();
			}			
		}else{
			callCallback();
		}
		function callCallback(result){
			rdfModels.length = 0;
			if(callback){
				callback(result);
			}			
		}
	};
	
	getParticipantAssignments(participantId,skipPackages,callback){
		var alts = this.getAlternativesWhereICanBeReffered();
		alts.push.apply(alts,this.getAlternativesThatICanReffer());
		//var plansWsData = DataManager.getDataManager().getWorkspaceDataWithIdSync(window.plansKey);	// some object with id = window.planskey
		//alts = alts.concat(plansWsData);		
		alts = _.uniq(alts);
		//var count = alts.length;
		var rdfModels = [];
		async.eachSeries(alts,function(alt,gotRDFModel){
			var count = 0;
			function getRDFModel(){
				var altRDFModel = DataManager.getDataManager().getRDFModel(alt.get('id'));
				if(altRDFModel){
	 				rdfModels.push(altRDFModel);
	 				gotRDFModel();
	 			}else{
					count++;
					if(count > 10){
						gotRDFModel();
					} else {
						setTimeout(getRDFModel,30);
					}	 				
	 			}
			}
			getRDFModel();
		},function(){
			if(rdfModels.length>0){
				var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
				if(altRDFModel){
					altRDFModel.getParticipantAssignments(participantId,callCallback,rdfModels);
				}else{
					callCallback();
				}			
			}else{
				callCallback();
			}
			function callCallback(results){
				alts.length = 0;
				rdfModels.length = 0;
				if(callback){
					callback(results);
				}			
			}				
		});
	};

	getModelLinkedSync(model){
		var self = this;
		var modelId = model.get('id');
		var modelAltId = DataManager.getDataManager().getRepositoryId(modelId);
		if(modelAltId === self.get('id') || modelAltId === window.plansKey){
			return model;
		}else{
			var packageId = model.getNestedParent().get('id');
			var packageSuffix = packageId.substr(packageId.lastIndexOf('@')+1);
			var modelSuffix = modelId.substr(modelId.lastIndexOf('@')+1);
			var packRefs = self.get('phaseDesignPart');
			var linkedPackageId;
			packRefs.each(function(packRef){
				var beepRef = packRef.get('beepReference');
				if(beepRef.indexOf(packageSuffix) > 0){
					linkedPackageId = beepRef;	
				}
			});
			if(linkedPackageId){
				if(linkedPackageId === packageId){
					return model;
				}else{
					var beepPackage = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({id:linkedPackageId});
					if(beepPackage){
						//beepPackage.toJSON();	// force the initialiazion of complete model in case its loaded first time but this is causing performance issues??
						var linkedPackageAltId = DataManager.getDataManager().getRepositoryId(linkedPackageId);
						if(linkedPackageId === linkedPackageAltId + modelSuffix){
							return beepPackage;
						}else{
							var linkedModel = model.constructor.find({id:linkedPackageAltId + modelSuffix});
							return linkedModel;
						}						
					}
				}
			}else{
				var linkedModel = model.constructor.find({id:self.id + modelSuffix});
				return linkedModel;
			}
		}
	}
	
	getModelLinked(model,callback){
		var self = this;
		var modelId = model.get('id');
		var modelAltId = DataManager.getDataManager().getRepositoryId(modelId);
		if(modelAltId === self.get('id') || modelAltId === window.plansKey){
			callback(model);
		}else{
			var packageId = model.getNestedParent().get('id');
			var packageSuffix = packageId.substr(packageId.lastIndexOf('@')+1);
			var modelSuffix = modelId.substr(modelId.lastIndexOf('@')+1);
			var packRefs = self.get('phaseDesignPart');
			var linkedPackageId;
			var beepType;
			packRefs.each(function(packRef){
				var beepRef = packRef.get('beepReference');
				if(beepRef.indexOf(packageSuffix) > 0){
				    linkedPackageId = beepRef;
				    beepType = packRef.get('beepType');
				}
			});
			if(linkedPackageId){
				if(linkedPackageId === packageId){
					callback(model);
				}else{
					self.getBeepPackageWithId(linkedPackageId,beepType,function(beepPackage){
						//beepPackage.toJSON();	// force the initialiazion of complete model in case its loaded first time but this is causing performance issues??
						var linkedPackageAltId = DataManager.getDataManager().getRepositoryId(linkedPackageId);
						if(linkedPackageId === linkedPackageAltId + modelSuffix){
							callback(beepPackage);
						}else{
							var linkedModel = model.constructor.find({id:linkedPackageAltId + modelSuffix});
							callback(linkedModel);
						}
					});						
				}
			}else{
				var linkedModel = model.constructor.find({id:self.id + modelSuffix});
				callback(linkedModel);
			}
		}
	}
	
	getOrCreatePhaseObjectiveSet(){
		var phaseObjectiveSet = this.get('phaseObjectiveSet'); 
		if(!phaseObjectiveSet){
			var osId = DataManager.getDataManager().guidGeneratorByOwner(this);
			var plan = this.get('phaseAlternativeOwner').get('phaseOwner');
			var planCriterionSet = plan.getOrCreatePlanCriterionSet();
			phaseObjectiveSet = new PhaseObjectiveSet({id:osId ,name:this.get('name') + " Objective Set", phaseObjectiveSetOwner:this, localChange:false});
			phaseObjectiveSet.set('planObjectiveSet',planCriterionSet);
			//phaseObjectiveSet.set('localChange',false);
			phaseObjectiveSet.createContextObservation();
		}
		return phaseObjectiveSet;		
	}

	fetchNonCommonPackagesFromAlternative(){//Fetch all packages from alternative except common scenario
		var packages = new Backbone.Collection();
		var phaseDesignPartColl = this.get('phaseDesignPart');
		phaseDesignPartColl.each(function(designPart){
			var beep = designPart.get('beepReference');
			if(beep.indexOf("Common") < 0){
				packages.add(designPart);
			}
		});
		return packages;
	};
	
	fetchNonCommonVDMPackagesFromAlternative(){//Fetch all VDM packages from alternative except common scenario
		var packages = [];
		var phaseDesignPartColl = this.get('phaseDesignPart');
		phaseDesignPartColl.each(function(designPart){
			var beep = designPart.get('beepReference');
			if(beep.indexOf("Common") < 0 && designPart.get('beepType') === "vdml_ValueDeliveryModel"){
				packages.push(designPart);
			}
		});
		return packages;
	};
	
	checkPackageDependency(beepPackage,callback){
		var needCopy = false;
		var self = this;
		var count = 0;
		function checkResults(refs){
			var refAlts = self.getAlternativesThatICanReffer();
			for(var i=0;i<refs.length;i++){
				var refId = DataManager.getDataManager().getRepositoryId(refs[i].id);
				if(refId !== self.get('id') && refId !== window.plansKey){	
					var foundDep = true;
					for(var j=0;j<refAlts.length;j++){
						if(refAlts[j].get('id') === refId){
							foundDep = false;
							break;
						}
					}
					if(foundDep){
						needCopy = true;
						break;
					}
				}
			}
			refAlts.length = 0;
			callback(needCopy);
		}	
		function checkRDFAndExecuteQuery(){
			var altRDFModel = DataManager.getDataManager().getRDFModel(self.get('id'));
			var refAlts = self.getAlternativesWhereICanBeReffered();
			var rdfModels = [];
			for(var i=0;i<refAlts.length;i++){
				rdfModels.push(DataManager.getDataManager().getRDFModel(refAlts[i].get('id')));
			}
			if(altRDFModel){
				altRDFModel.executeReferencesToModelQuery(beepPackage,checkResults,rdfModels);
			}else{
				count++;
				if(count > 10){
					callback(needCopy);
				} else {
					setTimeout(checkRDFAndExecuteQuery,3);
				}
			}
		}
		checkRDFAndExecuteQuery();
	};
	
	copyLinkedPackages(phaseModel, callback){
		var self = this;
		if(!phaseModel.get('nextPhase')){
			callback();
			return;
		}
		var currentPrimary = phaseModel.get('primary');
		var packagesColl = currentPrimary.fetchNonCommonPackagesFromAlternative();
			var packageArray = [];
			packagesColl.each(function(pack){
				var altId = pack.get('phaseDesignPartOwner').get('id');  
				if(self.get('id') == altId){
					packageArray.push(pack);
				}
			});
			async.eachSeries(packageArray,function(pack,packageHandlerCallback){
			    currentPrimary.getBeepPackageWithId(pack.get('beepReference'), pack.get('beepType'), function (beepPackage) {
		             if(beepPackage){
						function checkResults(needCopy){
							var nextPhaseModel = phaseModel.get('nextPhase');
							if(needCopy && nextPhaseModel){
								var copyAlternative = nextPhaseModel.get('master');
								beepPackage.copyToAlternative(currentPrimary,copyAlternative,function(newPack){
									packageHandlerCallback();
								},false);    
							}else{
			                    packageHandlerCallback();
							}
						}
						if(phaseModel.get('nextPhase') && phaseModel.get('nextPhase').get('master').get('phaseDesignPart').findWhere({'beepReference':beepPackage.get('id')})){
							self.checkPackageDependency(beepPackage,checkResults);
						}else {
							 packageHandlerCallback();
						}
					}
					else {
						packageHandlerCallback();
					}
				});
			},
			function(err){
				if(callback){
					callback();
				}			
			});
	};
	handleAlternativeNameChange(model,newName){
		var self = this;
		if(self.get('phaseObjectiveSet')){
			self.get('phaseObjectiveSet').set('name',newName+' Objective Set');
		}
		//self.handleDesignPartNameChange();
	};
	deleteValueContext(model){
		var self = this;
	}
	
	/*handleDesignPartNameChange(){
		var self= this;
		var phase = self.get('phaseAlternativeOwner');
		var plan = phase.get('phaseOwner');
		for(var k=0;k<self.get('designScenario').length;k++){
			var scenarioPath = plan.get('name') + "_" + phase.get('name') + "_" + self.get('name') +" Scenario";
			self.get('designScenario').at(k).set('name',scenarioPath);
			self.get('designScenario').at(k).set('description',scenarioPath);
		}
	};*/	
	alternativeAfterThis(altId){
		var nextAlts = this.getAlternativesWhereICanBeReffered();
		for(var i=0;i<nextAlts.length;i++){
			if(nextAlts[i].get('id') === altId){
				return true;
			}
		}
		return false;
	};	
	
	/*reGenerateRDF(callback){
		var self = this;
		var rdfModel = DataManager.getDataManager().getRDFModel(this.get('id'));
		if(rdfModel) {
		window.utils.startSpinner('reGenerateRDFSpinner', "reGenerating RDF...");
			rdfModel.clear(function(){
				var packageRefs = self.get('phaseDesignPart');
				async.each(packageRefs.models,function(packageRef,handledPackageRef){
					var beepRef = packageRef.get('beepReference');
					var beepRefRepId = DataManager.getDataManager().getRepositoryId(beepRef);
					if(beepRefRepId !== self.get('id')){
						handledPackageRef();
						return;
					}
					var vdmStore = DataManager.getDataManager().getVDMStore(self.get('id'));
			        DataManager.getDataManager().fetchDocumentFromPackage(beepRef,"appbo/vdml/ValueDeliveryModel",DataManager.getDataManager().get('currentVDMVersion'),beepRef,"appbo/vdml/ValueDeliveryModel",vdmStore,{
			                success:function(packModel){
							    rdfModel.addRDFForModel(packModel,function(){
						    		handledPackageRef();
							  	},true);		                	
			                },
			                error:function(error){
			                	console.log('failed to fetch common package');
			                	handledPackageRef();
			                }
			        });				
				},function(){
					rdfModel.save();
					window.utils.stopSpinner('reGenerateRDFSpinner');
					if(callback){
						callback();
					}
				});
			});
		}else {
			if(callback){
				callback();
			}
		}
	};
	*/
	
	
//#endCustomMethods	
	//path.AlternativeMixin = AlternativeMixin;
	//return AlternativeMixin;
}
path.AlternativeMixin = AlternativeMixin;
