import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
//import {enum} from '../../../../libs/enums/enums'
//import {enhsplitter} from '../../../../libs/enhsplitter/jquery.enhsplitter'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {jszip} from '../../../../../../libs/jszip/jszip.min'
//import {typeahead} from '../../../../libs/typeahead/backbone.typeahead'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
//import {VMPServerService} from '../../../../../com/vbee/filesystem/VMPServerService'
import {Workspace} from '../../../../../com/vbee/filesystem/Workspace'

//define(["require","jquery","underscore","enhsplitter","backbone","typeahead","knockout","knockoutMapping","knockback","bootbox","jszip","appbo/preference/UserPreferences","FileSaver","spin","appcommon/com/vbee/data/DataManager","appcommon/com/vbee/filesystem/VMPServerService","app/global", "appcommon/com/vbee/filesystem/Workspace"],
//function(require,$,_, enhsplitter, Backbone,typeahead,ko, koMapping,kb,bootbox,jszip,UserPreferences,FileSaver,Spinner,DataManager,VMPServerService,global, Workspace){

    var path = DataManager.getDataManager().buildAppNsPath("tickets",global.version);

	export class RegistrationViewModel {     

		getEntityList(university) {
            debugger
            var self = this;
            var request = new XMLHttpRequest();
            var endExt = "";
			var parentEntId = self.parentEntityId;
			//if(!self.parentEntityId) {
				if(university){
					parentEntId = self.parentEntityId?self.parentEntityId:window.university;
				}else {
					parentEntId = self.parentEntityId?self.parentEntityId:window.enterprise;
				}
			//}
            if (parentEntId == window.vdmbee || parentEntId == window.university || parentEntId == window.enterprise) {
                endExt = university ? '/invitation/universityList' : '/invitation/customerList'
                request.open("GET", DataManager.getDataManager().get('vmpServerHost') + endExt);
            }/* else {
                endExt = '/joinOrganizationList'
                request.open("GET", DataManager.getDataManager().get('vmpServerHost') + endExt + "?parentEntityId=" + parentEntId, true);
            }*/
            request.onload = function (data) {
                if (request.response && this.readyState === 4 && (this.status === 200 || this.status === 201 || this.status === 204)) {
                    var myJSON = JSON.parse(request.response);
                    if (self.customersIdsArray) {
                        for (i in self.customersIdsArray) {
                            myJSON = _.omit(myJSON, self.customersIdsArray[i]);
                        }
                    }
					if(university) {
                        var universityExist = false;
                        //self.joinUniversityList.removeAll();
						for (key in myJSON) {
						   if(self.universityList.models.length == 1){ 
                               self.universityList.add({ id: key, name: myJSON[key] });
                               //(self.requestToJoin) ? self.joinUniversityList.push({ id: key, name: myJSON[key] }) : "";
						   }else{
						   	   for(var i=0;i<self.universityList.models.length;i++){
							   	   if(self.universityList.models.id === key){
								   	   universityExist = true;
								   }
							   }
							   if(!universityExist){
                                   self.universityList.add({ id: key, name: myJSON[key] });
                                   //self.requestToJoin ? self.joinUniversityList.push({ id: key, name: myJSON[key] }) : "";
							   }
						   }
						}
					}else {
                        var customerExist = false;
                        //self.joinCustomerList.removeAll();
						for (key in myJSON) {
						   if(self.customerList.models.length == 1){ 
                               self.customerList.add({ id: key, name: myJSON[key] });
                               //self.requestToJoin ? self.joinCustomerList.push({ id: key, name: myJSON[key] }) : "";
						   }else{
						   	   for(var i=0;i<self.customerList.models.length;i++){
							   	   if(self.customerList.models.id === key){
								   	   customerExist = true;
								   }
							   }
							   if(!customerExist){
                                   self.customerList.add({ id: key, name: myJSON[key] });
                                   //self.requestToJoin ? self.joinCustomerList.push({ id: key, name: myJSON[key] }) : "";
							   }
						   }
						}
					}
                    
                } else {
                    if (this.status === 500 && this.response.message !== "") {
                        bootbox.alert(request.response.message);
                    }
                    if (this.status === 403) {
                        bootbox.alert('Request is Denied');
                    }
                }
                window.utils.stopSpinner('universitySpinner');
            };
            request.setRequestHeader("Content-Type", "application/json", true);
            request.send();
        };

        getProductList(){
            var self = this;
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function() {
                if (xhttp.readyState === 4) {
                    if(xhttp.status === 200){
                        var fileObject = this.response;
                        var configObj = JSON.parse(fileObject);//.reverse();
                        _.each(configObj,function(pr){
                            self.productList.push(pr);
                        })
                        //window.utils.stopSpinner('fetchingIndexFromCloud','Fetching list from Cloud');
                        //callback(productExtensions);
                    }else{
                        bootbox.alert("Unable to fetch from Cloud");
                        return;
                    }
                }
            };
            var hostBaseUrl = DataManager.getDataManager().get('vmpServerHost');
            xhttp.open("GET",hostBaseUrl  +  "/invitation/productList");
            xhttp.setRequestHeader("Content-Type", "application/json");
            xhttp.send();
        }

		registerUser() {
		    //options.loadList = true;
			var self = this;
            self.RegistrationView(true);
            $('#registerName').text(DataManager.getDataManager().get('localeManager').get('register'));
			self.showLoginOrRegister(false);
			self.getEntityList(false);
            self.getProductList();
		};


		VerifyCode() {
		    //options.loadList = true;
			var self = this;
            self.RegistrationView(true);
            $('#registerName').text(DataManager.getDataManager().get('localeManager').get('register'));
			self.showLoginOrRegister(false);
			self.getEntityList(false);
            self.showCode(true)
		};

		/*saveBasicAuthDetails() {
            var self = this;
			if(self.username() != null && self.password() != null) {
				var dataManager = DataManager.getDataManager();
				var userName = self.username();
				dataManager.set('vmpServerHost','http://localhost:8882');
				dataManager.set('vmpServerLocation','http://localhost:8882/vdmbee/');
				dataManager.set('vmpSnapshotHost','http://localhost:8090');
				dataManager.set('vmpSnapshotLocation','http://localhost:8090/vdmbee/');
				window.seleniumAuth = window.btoa(userName + ':' + self.password());
				dataManager.set("basicAuthMode",true);
				dataManager.set("email",userName);
				var service = new VMPServerService();
                    DataManager.getDataManager().set('vmpServerService', service);
                    DataManager.getDataManager().getLicenses({
                        'success': function (response) {
                            UserPreferences.setUserPreference(window.plansKey, 'License', 'License', JSON.stringify(response));
                            UserPreferences.setUserPreference(window.plansKey, 'LicenseTimestamp', 'LicenseTimestamp', new Date().getTime());
                            DataManager.getDataManager().onLicenseUpdate(response);
                            var products = DataManager.getDataManager().get('products');
                            $('#userFullName').text(DataManager.getDataManager().get('email').split('@')[0]);
                            $('#userMailId').text(DataManager.getDataManager().get('email'));
                            if (DataManager.getDataManager().get('email')) {
                                $("#loginHere").remove();
                            }
                            var entityArray = [];
                            for (var i = 0; i < products.length; i++) {
                                for (var j = 0; j < products[i].legalEntityList.length; j++) {
                                    if (entityArray.indexOf(products[i].legalEntityList[j].entityId) == -1) {
                                        $('#legalEntitySelection').append("<option " + "value=\"" + products[i].legalEntityList[j].entityId + "\">" + products[i].legalEntityList[j].entityName + "</option>");
                                        entityArray.push(products[i].legalEntityList[j].entityId);
                                    }
                                }
                            }
                            if (entityArray.length > 0) {
                                $('#showLogout').show();
                            }

							Workspace.getPhoto(null,userName,function(){
								window.showSettings();
								if(DataManager.getDataManager().get('debugMode')){
									//var snapshotService = new VMPServerService();
									//snapshotService.init(function () {
									//	DataManager.getDataManager().set('vmpSnapshotService', snapshotService);
									//}, DataManager.getDataManager().getWindowsSnapshotClientId(), "vmpSnapshotHost");
								}
							});                            
                        },
                        'error': function (response) {
                            var cacheResponse = UserPreferences.getUserPreference(window.plansKey, 'License', 'License');
                            if (cacheResponse) {
                                var cacheTimeStampStr = UserPreferences.getUserPreference(window.plansKey, 'LicenseTimestamp', 'LicenseTimestamp');
                                if (cacheTimeStampStr) {
                                    var cacheTimeStamp = parseInt(cacheTimeStampStr);
                                    var monthTime = 30 * 24 * 60 * 60 * 1000;
                                    if (new Date().getTime() - cacheTimeStamp > monthTime) {
                                        DataManager.getDataManager().onLicenseUpdateFailed("License Expired");
                                        DataManager.getDataManager().licenses = null;
                                    } else {
                                        DataManager.getDataManager().onLicenseUpdate(JSON.parse(cacheResponse));
                                    }
                                } else {
                                    DataManager.getDataManager().onLicenseUpdate(JSON.parse(cacheResponse));
                                }
                            } else {
                                DataManager.getDataManager().onLicenseUpdateFailed(response);
                            }
                            window.showSettings();
                        }
                    });		
				window.cleanDialogModel(self.encodeId);
			}
		};*/

        loginUserWithAzure() {
			var self = this;
			if(window.previousEmail) {
				bootbox.alert('Please close all Windows to re-login');
				return;
			}
            function callback(resp) {
                if (resp) {
                  //  setTimeout(function () {
                    window.showSettings();
						window.utils.stopSpinner('AuthSpinner');
                   // }, 1000);
				   DataManager.getDataManager().set('offLineMode', false)
				   $('#loadImage').show();
				   $('#loginHere').remove();
				   $('#showLogout').show();
                   window.initializeRouter();
                }
            }
			self.RegistrationView(false);
			//if (window.chrome && window.chrome.app && window.chrome.app.runtime) {
                Workspace.authenticateAzureWithChrome(callback);
			/*}else{
				Workspace.authenticate(callback);
			}*/
			window.cleanDialogModel(self.encodeId);
			
		}
        saveRegistration() {
            var self = this;
            $('#RegistrationTemplate').hide();
            self.checkForAvailability(function (avaliable) {
                if (avaliable) {
                    var url = '/invitation/registration';
                    var params = {};
                    params.name = self.name().trim();
                    params.email = self.email().trim();
                    params.university = false;
                    params.productId = self.selectedProduct();
                    params.invoicePeriodUOM = self.selectedInvoicePeriod();
                    if ($('#university').prop('checked')) {
						params.university = true;
                        if(self.selcteduniversity && self.selcteduniversity.id){
                            params.selectedUniversity = self.selcteduniversity.get('id');
						}else{
						    params.entityName = $('#selectUniversity'+self.encodeId).val()
						}
					}else if($('#customer').prop('checked')){
						if(self.selctedcustomer && self.selctedcustomer.id){
						     params.legalEntityId = self.selctedcustomer.get('id');
						}else{
						    params.legalEntityId = null;
							params.entityName = $('#selectCustomer'+self.encodeId).val()
						}
                    }
                    window.utils.startSpinner('invitationSpinner', "Creating Invitation...");
                    window.setTimeout(function () {
                        var request = new XMLHttpRequest();
                        request.open("POST", DataManager.getDataManager().get('vmpServerHost') + url);
                        request.responseType = "json";
                        request.setRequestHeader("Content-Type", "application/json", true);
                        request.onload = function () {
                            window.utils.stopSpinner('invitationSpinner');
                            /*if (request.response != null && request.response.length > 0) {
                                bootbox.alert(request.response);
                            } else if (request.readyState === 4 && (request.status === 200 || request.status === 201 || request.status === 204)) {
                                bootbox.alert("A Verification Email has been sent.");
                            } else {
                                if (request.status === 500 && request.response.message !== "") {
                                    bootbox.alert(request.response.message);
                                }
                                if (request.status === 403) {
                                    bootbox.alert('Request is Denied');
                                }
                            }                            
                            self.cleanModal();*/
                            if(!self.showLoginOrRegister() && request.status === 200){
                                self.RegistrationView(false)
                                self.showLoginOrRegister(true);
                            }else {
                                var msg = request.response.apierror ? request.response.apierror.message : "Error processing Invitation."
                                bootbox.alert(msg);
                                console.log(request.status);
                                self.cleanModal();
                            }
                        };
                        request.send(JSON.stringify(params));
                    }, 100);
                } else {
                    $('#RegistrationTemplate').show();
                    bootbox.alert("Organization already exists. Please choose a different name")
                };
            });
        };

        switchToCode(){
            var self = this;
            if(!self.showCode()){
                self.showCode(true);
            }
        }

        registerWithCode() {
            var self = this;
            var url = '/invitations/confirm';
            var params = {};
            params.code = $("#code"+ self.encodeId).val();
            params.email = self.email();
            if(!params.code || !params.email || !self.validateEmail(params.email)){
                bootbox.alert("Invalid Info");
                return;
            }
            window.utils.startSpinner('invitationSpinner', "Verifying Email...");
            window.setTimeout(function () {
                var request = new XMLHttpRequest();
                request.open("POST", DataManager.getDataManager().get('vmpServerHost') + url);
                request.responseType = "json";
                request.setRequestHeader("Content-Type", "application/json", true);
                request.onload = function () {
                    window.utils.stopSpinner('invitationSpinner');
                    if(request.status === 200){
                        bootbox.alert("Thank You. Please wait for Confirmation email from Admin.");
                        self.cleanModal();
                    } else {
                        bootbox.alert("Invalid Code.");
                    }
                };
                request.send(JSON.stringify(params));
            }, 100);            
        };
		
        /*signlater(){
            var self = this;
            window.initializeRouter(true);
            self.cleanModal();
        }*/
        validateEmail(mail) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
                return (true)
            }
            return (false)
        };

        checkForAvailability(callback) {
            var self = this;
			if((self.selcteduniversity && self.selcteduniversity.id && $('#university').prop('checked')) || (self.selctedcustomer && self.selctedcustomer.id && $('#customer').prop('checked'))){
				callback(true);
				return;
            }
            var emailId = self.email().trim();
            var name = $('#selectCustomer' + self.encodeId).val();
            if ($('#university').prop('checked')) {
                name = $('#selectUniversity' + self.encodeId).val();
            }
            var request = new XMLHttpRequest();           
            request.open('POST', DataManager.getDataManager().get('vmpServerHost') + '/invitation/checkUnique/' + emailId + '/' + name );
            request.setRequestHeader("Content-Type", "application/json", true);
            request.onload = function (unique) {
                var isunique = request.responseText;
                if (request.status === 500 && request.response.message !== "") {
                    bootbox.alert(request.response.message);
                }
                if(request.status === 403){
                    bootbox.alert('Request is Denied');
                }
                if(document.getElementById('AvailabilityIcon')){
				    document.getElementById('AvailabilityIcon').style.display = "block";
			    }
                if(isunique == "true") {
                    isunique = true;
                    self.enableRegistrationComplete(true);
                    if(document.getElementById('AvailabilityIcon')){
					   document.getElementById('AvailabilityIcon').classList.remove('glyphicon-remove');
                       document.getElementById('AvailabilityIcon').classList.add('glyphicon-ok');
                    }
				} else {
                    isunique = false;
                    self.enableRegistrationComplete(false);
                    if(document.getElementById('AvailabilityIcon')){
					   document.getElementById('AvailabilityIcon').classList.remove('glyphicon-remove');
                       document.getElementById('AvailabilityIcon').classList.add('glyphicon-ok');
                    }
                }
				callback(isunique)
            };

            request.send();
        }
		
        afterRenderView(options, view){
			var self = view;
		    $('#customer').prop('checked','true');
			if(!self.options.registerPage){
				self.RegistrationView(false);
                //$('#loginWithGoogleView').hide();
                $("#electronWindow").show();
                self.showLoginOrRegister(true);
			}
			if(DataManager.getDataManager().get('email')){
				$('#email' + self.encodeId).val(DataManager.getDataManager().get('email'));
				self.email(''+DataManager.getDataManager().get('email'));
			}
			$("#name" + self.encodeId).keyup(function (view) {
                var val = view.currentTarget.value.trim();
				var custName = true;
				if($('#university').prop('checked')){
                    if (!$('#selectUniversity' + self.encodeId).val()){
						custName = false;
					 }
				}else{
                    if (!$('#selectCustomer' + self.encodeId).val()){
						custName = false;
					 }
				}
                if (val !== "" && self.validateEmail(self.email()) && custName) {
                    if (self.customerList.length && self.requestToJoin) {
                        for (var i = 0; i < self.customerList.length; i++) {
                            if ($('#selectCustomer' + self.encodeId).val() == self.customerList.models[i].get('name')) {
                                self.enableRegistrationComplete(true);
                                break;
                            }
                        }
                    } else {
                        self.requestToJoin ? self.enableRegistrationComplete(false) : self.enableRegistrationComplete(true);
                    }
                    //self.enableRegistrationComplete(true);
                } else {
                    self.enableRegistrationComplete(false);
                }
            });
            $("#email" + self.encodeId).keyup(function (view) {
                var emailVal = view.currentTarget.value.trim();
                $("#duplicateName" + self.encodeId).text("");
                if (!window.utils.validateEmail(emailVal)) {
                    $("#duplicateName" + self.encodeId).text("E-mail not valid.");
                }
                var custName = true;
                if ($('#university').prop('checked')) {
                    if (!$('#selectUniversity' + self.encodeId).val()) {
                        custName = false;
                    }
                } else {
                    if (!$('#selectCustomer' + self.encodeId).val()) {
                        custName = false;
                    }
                }
                    
                if (emailVal !== "" && self.validateEmail(emailVal) && self.name() != null && custName) {
                    if (self.customerList.length && self.requestToJoin) {
                        for (var i = 0; i < self.customerList.length; i++) {
                            if ($('#selectCustomer' + self.encodeId).val() == self.customerList.models[i].get('name')) {
                                self.enableRegistrationComplete(true);
                                break;
                            }
                        }
                    } else {
                        self.enableRegistrationComplete(true);
                    }
                    //self.enableRegistrationComplete(true);
                } else {
                    self.enableRegistrationComplete(false);
                }
            });
            $('#customerList' + self.encodeId).dblclick(function (view) {
                $('#customerList' + self.encodeId).trigger('keyup');
                $('#customerList' + self.encodeId).focus();
            });
            $('#customerList' + self.encodeId).keyup(function (event) {
                self.selctedcustomer = null;
				self.selcteduniversity = null;
				self.enableMatchField(true);
                self.matchField = $(event.target).closest('.row').find('.match');
                if (!self.checkCustomerListExist(event.target.value.trim(), true)) {
                    self.matchField[0].innerHTML = 'Match';
                } else {
                    self.enableMatchField(false);
                }
                self.enableRegistrationComplete(false);
                if (!event.target.value.trim() == "" && self.requestToJoin) {
                    //$('#selectCustomer'+self.encodeId).text(view.target.value.trim());
                    if (/*!event.keyCode &&*/self.matchField[0].innerHTML == "Match" && self.name() && self.name() != "" && $('#email' + self.encodeId).val() != "") {
                        self.enableRegistrationComplete(true);
                    } else {
                        self.enableRegistrationComplete(false);
                    }
                } else {
                    if (!event.target.value.trim() == "" && event.target.value.trim().length >= 4 && !self.matchField[0].innerHTML == "" && self.name() && self.name() != "" && $('#email' + self.encodeId).val() != "") {
                        self.enableRegistrationComplete(true);
                    } else {
                        self.enableRegistrationComplete(false);
                    }
                }
            });
			$('#universityList' + self.encodeId).keyup(function (view) {
				self.selcteduniversity = null;
				self.selctedcustomer = null;
				self.enableMatchField(true);
                self.matchField = $(event.target).closest('.row').find('.universityMatch');
                if (event.target.value.trim().length >= 4) {
                    if (!self.checkUniversityListExist(event.target.value.trim(), true)) {
                        self.matchField[0].innerHTML = 'Match';
                    }
                } else {
                    self.enableMatchField(false);
                }
                self.enableRegistrationComplete(false);
				if (!view.target.value.trim() == "" && view.target.value.trim().length >= 4) {
				     //$('#selectUniversity'+self.encodeId).text(view.target.value.trim());
                     if(self.name()  && self.name() != "" && $('#email'+self.encodeId).val() != "" && self.validateEmail($('#email'+self.encodeId).val())){
						  self.enableRegistrationComplete(true);
					 }else{
						  self.enableRegistrationComplete(false);
					 }
                }
            });

			if(!self.loadList){
                $('#registerName').text('Create a New Organization');
                self.showUsage(false);
			}
            if (self.loadList) {
                if (self.requestToJoin) {
                    $('#registerName').text('Request to join an existing Organization');
                    self.showUsage(false);
                }
				var customerExtended = Backbone.Typeahead.extend({
						template: '<input type="text" id="selectCustomer' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
				});
				self.customerExtended = new customerExtended({ collection: self.customerList});
				self.customerExtended.setElement('#customerList' + self.encodeId).render();
			
				var universityExtended = Backbone.Typeahead.extend({
						template: '<input type="text" id="selectUniversity' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
				});
				self.universityExtended = new universityExtended({ collection: self.universityList});
				self.universityExtended.setElement('#universityList' + self.encodeId).render();
			
				self.customerExtended.on('selected', function (selectedCustomer) {
                    self.selctedcustomer = selectedCustomer;
                    if ($('#customerList' + self.encodeId).text() != "" && self.name() && self.name() != "" && self.validateEmail($('#email'+self.encodeId).val()) != "") {
					  self.enableRegistrationComplete(true);
					}else{
						self.enableRegistrationComplete(false);
					}
					self.enableMatchField(true);
					self.matchField = $(event.target).closest('.row').find('.match');
					if(self.matchField[0]){
						self.matchField.show();
						self.matchField[0].innerHTML = 'Match';
					}
				});
				self.universityExtended.on('selected', function (selectedUniversity) {
					self.selcteduniversity = selectedUniversity;
                    if ($('#universityList' + self.encodeId).text() != "" && self.name()  && self.name() != "" && self.validateEmail($('#email'+self.encodeId).val()) != ""){
					  self.enableRegistrationComplete(true);
					}else{
						self.enableRegistrationComplete(false);
					}
					self.enableMatchField(true);
					self.matchField = $(event.target).closest('.row').find('.universityMatch');
					if(self.matchField[0]){
						self.matchField.show();
						self.matchField[0].innerHTML = 'Match';
					}
				});
			}
        };

        checkCustomerListExist(name, existing) {
            var self = this;
            var count = 0;
            if (self.customerList.length) {
                for (var i = 0; i < self.customerList.models.length; i++) {
                    if (self.customerList.models[i].get('name') === name) {
                        self.customer = self.customerList.models[i];
                        count++;
                    }
                }
            }
            if (count > 0) {
                return false;
            }
            if(!self.matchField[0]){
				self.enableMatchField(true);
				self.matchField = $(event.target).closest('.row').find('.match');
			}else {
				self.matchField.show();
                self.matchField[0].innerHTML = 'New';
			}
            return true;
        }

		checkUniversityListExist(name, existing) {
            var self = this;
            var count = 0;
            if (self.universityList.length) {
                for (var i = 0; i < self.universityList.models.length; i++) {
                    if (self.universityList.models[i].get('name') === name) {
                        self.university = self.universityList.models[i];
                        count++;
                    }
                }
            }
            if (count > 0) {
                return false;
            }
			if(!self.matchField[0]){
				self.enableMatchField(true);
				self.matchField = $(event.target).closest('.row').find('.match');
			}else {
				self.matchField.show();
                self.matchField[0].innerHTML = 'New';
			}
            return true;
        }

		cleanModal(){
            var self = this;
	        window.cleanDialogModel(self.encodeId,self);
		};
    
	init(model, options, detailedView) {
		var self = this;
        this.loadList = true; // always true for Register
        if (options.loadList === false) {
            self.loadList = options.loadList;
        }
        this.RegistrationViewModel = this;
        this.requestToJoin = false;
        this.customersIdsArray = [];
        if (options.requestToJoin) {
            self.requestToJoin = options.requestToJoin;
            self.customersIdsArray = options.customersIdsArray;
        }
        self.options = options;
        var currentLegalEntity = DataManager.getDataManager().get("currentLegalEntity");
        this.parentEntityId = "";
        if (currentLegalEntity) {
            if (currentLegalEntity.parentEntityId) {
                this.parentEntityId = currentLegalEntity.parentEntityId;
            } else {
                this.parentEntityId = currentLegalEntity.entityId;
            }
        }
        if (self.parentEntityId == "VDMBeeUniversity") {
            $("#customer").attr('checked', false);
            $("#university").attr('checked', true);
             //$('input[name=type][value=No]').attr('checked', false); 
        } else {
            $("#customer").attr('checked', true);
            $("#university").attr('checked', false);
        }
        this.enableMatchField = ko.observable(false);
		this.name = ko.observable();
		this.email = ko.observable();
		this.university = ko.observable();
		this.customer = ko.observable();
		this.enableRegistrationComplete = ko.observable(false);
		this.id = kb.observable(model, 'id');
		this.universityOptions = ko.observableArray(['No','Yes']);
		this.universityList = new Backbone.Collection();
		this.showLoginOrRegister = ko.observable(false);
        this.customerList = new Backbone.Collection();
		this.selectedUniversity = ko.observable();
        this.RegistrationView = ko.observable(true);
        this.showUsage = ko.observable(true);
        this.showUniversity = ko.observable(false);
        this.joinUniversityList = ko.observableArray([]);
        this.joinCustomerList = ko.observableArray([]);
        this.selectedCustomer = ko.observable();
        this.productList = ko.observableArray([]);
        this.selectedProduct = ko.observable();
        this.showInvoicePeriod = ko.observable(false);
        this.invoicePeriodList = ko.observableArray(["Month", "Year"]);
        this.selectedInvoicePeriod = ko.observable();
        this.showCode = ko.observable(false);
        this.code = ko.observable();
        /*this.enablebasicAuthMode = ko.observable(false);
        var debugModeCheck = DataManager.getDataManager().get("debugMode");
        if (debugModeCheck) {
            self.enablebasicAuthMode(true);
        }
		this.basicAuthMode = ko.observable();
		this.authOption = ko.observableArray(['None', 'Basic Auth']);
		this.enableBasicAuthField = ko.observable(false);
		this.username = ko.observable();
        this.password = ko.observable();
        this.basicAuthMode.subscribe(function (val) {
			if(val == "Basic Auth") {
				self.enableBasicAuthField(true);
			} else {
				self.enableBasicAuthField(false);
			}
        });*/
		if(options){
			this.encodeId = ""+options.modalId;
			//this.registerPage = options.registerPage;
			if(options.registerPage){
				self.getEntityList(false);
			}
		}

		this.selectedProduct.subscribe(function (val) {
            if(val && val != "F22183C9-62FF-43B3-A186-E2B913BA9623"){
                self.showInvoicePeriod(true);
            } else {
                self.showInvoicePeriod(false);
            }
        });
       
		this.university.subscribe(function (val) {
		    var selectedUniOrCust = false;
            if(val == "Yes"){
			    window.utils.startSpinner('universitySpinner', "Fetching Universities..."); 
                self.showUniversity(true);
                self.getEntityList(true);
                if ($('#selectUniversity' + self.encodeId).val() != "") {
				   selectedUniOrCust = true;
				}
			}else {
                window.utils.startSpinner('universitySpinner', "Fetching Customers...");
                self.showUniversity(false);
                self.getEntityList(false);
                if ($('#selectCustomer' + self.encodeId).val() != "") {
				   selectedUniOrCust = true;
				}
            }
			if(self.name() != "" && self.validateEmail($('#email'+self.encodeId).val()) != "" && selectedUniOrCust){
				self.enableRegistrationComplete(true);
			}else{
				self.enableRegistrationComplete(false);
			}
        });

		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),[
            'name'
            ,'description'
            ,'Close'
            ,'Complete'
        ]);
	}
    //path.RegistrationViewModel = RegistrationViewModel;
    static getInstance(model,options){
        var view = new RegistrationViewModel(model,options);
		view.init(model, options);
		return view;
    };
    //return RegistrationViewModel;
//});
}
 path.RegistrationViewModel = RegistrationViewModel;