import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from '../vdml/VdmlElement'
import {VdmlElementMixin} from '../vdml/VdmlElementMixin'
import { VdmlElement2Mixin } from '../vdml/VdmlElement2Mixin'
import {MeasurableElementInstance} from './MeasurableElementInstance'
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("instdef",global.version);

export class InstantiationContextMixin {

    defaults(){
        var ret = {
            type: "instdef_InstantiationContext"
        }
        return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
    }
    static getMixinRelations(){
        return _.union([
            {
                type: Backbone.HasMany,
                containingClass: "instdef_InstantiationContext",
                key: "measurableElementInstance",
                relatedModel: "instdef.MeasurableElementInstance",
                reverseRelation: {
                    key: "measurableElementInstanceOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            }
        ])
    }
    
    static getCumulativeMixinRelations(){
        if (!InstantiationContextMixin.cummulativeRelations) {
            InstantiationContextMixin.cummulativeRelations = _.union(InstantiationContextMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
        return InstantiationContextMixin.cummulativeRelations.slice();
    }
    static getSuperTypes(){
        return [
            "vdml_VdmlElement"
        ];
    }
    static getProperties(){
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            {name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
            {name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
        ]
    }
    getParent(){
        var container;
		if(!container){
			container = this.get("instantiationContextOwner") ? this.get("instantiationContextOwner") : this.previousAttributes().instantiationContextOwner;
			if(container){
				return container;
			}
		}
    }
    getPackagePath(path){
        if(!path){
            path = [];
        }
        return VdmlElement.prototype.getPackagePath.apply(this, arguments);
    }
    getViewProperties(type){
        return {
            templatePath : "views/vdml/views/properties/InstantiationContextPropertiesTemplate.html",
            templateName : "InstantiationContextPropertiesTemplate",
            viewTypeStr : "appviews/vdml/views/properties/InstantiationContextViewModel",
            tabId : "InstantiationContextView",
            tabName: "InstantiationContext"
        }
    }

}
path.InstantiationContextMixin = InstantiationContextMixin;
