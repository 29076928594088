import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import {MeasurableElement} from './MeasurableElement'
import {ValueElementMixin} from './ValueElementMixin'
import {ValueElement2Mixin} from './ValueElement2Mixin'
import {VDMLCanvasElementMixin} from './VDMLCanvasElementMixin'
import {ValueDefinition} from './ValueDefinition'
import {MeasuredCharacteristic} from './MeasuredCharacteristic'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ValueElementContext } from './ValueElementContext'

/*define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/ValueElementMixin","appbo/vdml/VDMLCanvasElementMixin"],
function(require,jQuery,_,async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,ValueElementMixin,VDMLCanvasElementMixin
, MeasuredCharacteristic
, ValueDefinition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
		else{
			import('./MeasuredCharacteristic').then(loadedModule => {
				MeasuredCharacteristic = loadedModule;
			});
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	if(!ValueDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueDefinition){
			ValueDefinition = importPath.ValueDefinition;
		}
		else{
			import('./ValueDefinition').then(loadedModule => {
				ValueDefinition = loadedModule;
			});
			/*require(["appbo/vdml/ValueDefinition"],function(loadedModule){
				ValueDefinition = loadedModule;
			});*/
		}
	}
	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? ValueElement2Mixin : ValueElementMixin;
	}
	var veMixinClass = getMixinClass();
	var ValueElement = MeasurableElement.extend(utils.customExtends({
		relations:veMixinClass.getMixinRelations(),
		subModelTypes: veMixinClass.getSubModelTypes(),
		initialize: function(attributes, options) {
			MeasurableElement.prototype.initialize.apply(this, arguments);
			/*this.on('destroy',onValueElementRemoved);
			this.on('change:isIterative',this.onChangeIsIterative);
			this.on('add:aggregatedFrom',this.onAggregatedFromAdded);
			this.on('remove:aggregatedFrom',this.onAggregatedFromRemoved);
			this.on('add:aggregatedTo',this.onAggregatedToAdded);
			this.on('remove:aggregatedTo',this.onAggregatedToRemoved);*/			
		}
		}
		, new VDMLCanvasElementMixin()
		, new veMixinClass()
	));
    /*ValueElement['change:isIterative'] = function (model, newValue) {
		console.log(model.get('name') + "="+newValue);
        //this.onChangeIsIterative(model, newValue);
    };*/
    ValueElement['add:aggregatedFrom'] = function () {
        this.onAggregatedFromAdded();
    }
    ValueElement['remove:aggregatedFrom'] = function (model) {
        this.onAggregatedFromRemoved(this);
    }
    ValueElement['add:aggregatedTo'] = function (model) {
        this.onAggregatedToAdded();
		model.onAggregatedToAdded();
    }
    ValueElement['remove:aggregatedTo'] = function (model) {
        this.onAggregatedToRemoved(model);
    }

//#startCustomMethods	

    ValueElement['destroy'] = function onValueElementRemoved(model){
		if(Backbone.skipPersistance){
			return;
		}
		this.onDestroyElement(model);
	};	
	
/*	function recalcualteParents(model){
		var valueMeasurement = model.get('valueMeasurement');
		var measurements = valueMeasurement.get('measurement');
		async.eachSeries(measurements.models,function(measurement,handleMeasurementCallback){
			measurement.getDependentMeasurements(function(parents){
				measurement.get('observedMeasure').destroy();
				async.eachSeries(parents,function(depMest,calculateCallback){
					depMest.calculate(calculateCallback);						
				},function(err){
					handleMeasurementCallback();
				});
			});
		},function(err){
			
		});		
	}*/
//#endCustomMethods	
	ValueElement.getMixinClass = getMixinClass;
	ValueElement.abstract = true;
	path.ValueElement = ValueElement;
	export {ValueElement};
//});