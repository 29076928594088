import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class AnnotationMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.AnnotationMixin = AnnotationMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_Annotation"
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!AnnotationMixin.cummulativeRelations) {
            AnnotationMixin.cummulativeRelations = _.union(AnnotationMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return AnnotationMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			//{name : "text",type : "EString",defaultValue : "null",containingClass : "vdml_Annotation" },
			{name : "Url",type : "EString",defaultValue : "null",containingClass : "vdml_Annotation" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("annotationOwner") ? this.get("annotationOwner") : this.previousAttributes().annotationOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("annotationOwner") ? this.get("annotationOwner") : this.previousAttributes().annotationOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/AnnotationPropertiesTemplate.html",
			templateName : "AnnotationPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/AnnotationViewModel",
			tabId : "AnnotationView",
			tabName: "Annotation"
		}
	}
	
	}
	path.AnnotationMixin = AnnotationMixin;
	//return AnnotationMixin;
//});