﻿import * as bootbox from '../../bootbox/bootbox'
import * as $ from 'jquery'
(function (factory) {
    /* global define */
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Browser globals
        factory(window.jQuery);
    }
}(function (jQuery) {
    // Extends lang for print plugin.
    $.extend(true, $.summernote.lang, {
        'en-US': {
            Layout: {
                tooltip: 'Background-Color'
            }
        }
    });
    $.extend($.summernote.plugins, {
        'Layout': function (context) {
            var options = context.options;
            var lang = options.langInfo;
            var ui = $.summernote.ui;
            var self = this;
            context.memo('button.Layout', function () {
                var button = ui.button({
                    contents: '<i class="glyphicon glyphicon-copyright-mark"/> ',
                    tooltip: "Layout",
                    click: function () {
                        //window.require1(["jquery", "bootbox"], function ($, bootbox) {
                            var imgdata = null;
                            if ($('.report-watermark')["0"].innerHTML != $(".report-watermark").text() + '<div id="fileDisplayArea"></div>' && $('.report-watermark')["0"].innerHTML != "") {
                                var imgData = ($('.report-watermark')["0"].innerHTML.substr($(".report-watermark").text().length));
                            }
                            var transValue = $(".report-watermark")[0].style.transform ? $(".report-watermark")[0].style.transform.replace(/[^0-9]/g, "") : "300";
                            var opacityValue = $('.report-watermark')[0].style.opacity ? $('.report-watermark')[0].style.opacity : "0.5";
                            var colorValue = $('.report-watermark')[0].style.color ? window.utils.rgbToHex($('.report-watermark')[0].style.color) : "#FFFF00";
                            var htmlContent = "<div><span>Header:</span><br>";
                            htmlContent += "<div id='summernote1'></div></div>";
                            htmlContent += "<span>Footer:</span><br><div id='summernote2'></div></div>";
                            htmlContent += "<br><b>WaterMark Options</b><br><br><div class='row'><span class='col-xs-1'>Name:</span>";
                            if ($('.report-watermark')["0"]) {
                                htmlContent += '<span class="col-xs-2"><input type = "input" name = "userChoice" id = "userChoiceName" value = ' + $('.report-watermark')["0"].innerText + '></span>';
                            }
                            else {
                                htmlContent += '<span class="col-xs-2"><input type = "input" name = "userChoice" id = "userChoiceName" ></span>';
                            }
                            htmlContent += '<span class="col-xs-3"></span><span class="col-xs-4">Color:<input type="color" id="backColorPicker" class="note-btn note-color-select-btn bgColor" value= ' + colorValue + ' data-event="backColorPalette"></span><span class="col-xs-6"></span>';
                            htmlContent += '</div><br>';
                            htmlContent += '<div class="row"><span class="col-xs-6">Position:';
                            htmlContent += '<input type = "number" name = "transform" id = "transformId" value = ' + transValue + '></span><span class="col-xs-3"></span>';
                            htmlContent += '<span class="col-xs-6">Opacity: <input type = "number" name = "opacity" id = "opacityId"  value = ' + opacityValue + '></span></div>';
                            htmlContent += '<br><div>Select an image file:</div>';
                            if (imgData != null) {
                                imgData = imgData.replace("width=\"200\" height=\"200\"", "width=\"70\" height=\"70\"");
                                htmlContent += '<br><div id="selectImg">' + imgData + "<input type=\"button\" id=\"removeImg\" value=\"Delete\" style=\" width =\"70\" height=\"70\"\">" + '</div>';
                            } else {
                                htmlContent += '<br><div id="selectImg">' + "<input type=\"file\" id=\"fileInput\">" + '</div>';
                            }
                          function initializeSummernote(frequency) {
                                var summernote = $('#summernote' + (frequency + 1));
                                summernote.summernote('destroy');

                                summernote.summernote(
                                    {
                                        airMode: false,
                                        dialogsInBody: true,
                                        minHeight: null,             // set minimum height of editor
                                        maxHeight: null,             // set maximum height of editor
                                        focus: true,
                                        placeholder: 'write here...',
                                        disableResizeEditor: true, //disable resize 
                                        toolbar: [
                                            ['style', ['bold', 'italic', 'underline']],
                                            ['font', ['fontname', 'fontsize', 'color', 'superscript', 'subscript']],
                                            ['para', ['paragraph']],
                                            ['insert', ['picture']]
                                        ]
                                    });
                                summernote.find('.note-statusbar').hide();
                                summernote.summernote("editor.clearHistory", 'editor');
                                summernote.summernote('code', '');
                                if (frequency == 0 && $('#reportHeader')["0"].innerHTML != "") {
                                    summernote.summernote('code', $('#reportHeader')["0"].innerHTML);
                                }
                                if (frequency == 1 && $('#reportFooter')["0"].innerHTML.trim() != "") {
                                    summernote.summernote('code', $('#reportFooter')["0"].innerHTML);
                                }
                              
                                var modalBody = summernote.parents('.modal-body');
                                modalBody.css('max-height', '400px');
                                modalBody.css('overflow-y', 'auto');
                                //$("#summernotecss").attr("href", "js/libs/summernote/dist/summernote.css"); 
                          };
                            var box = bootbox.dialog({
                                title: "<div>Layout Options</div>",
                                message: htmlContent,
                                show: true,
                                buttons: {
                                    submit: {
                                        label: '<i id="linkModalCompleteBtn" class="fa fa-check"></i> Complete',
                                        className: "btn btn-complete",
                                        callback: function (e) {
                                           if (document.getElementById('fileInput') != null) {
                                                $('.report-watermark').text($('#userChoiceName').val()).append("<div id=\"fileDisplayArea\"></div>");
                                                $('#fileDisplayArea').css('margin - top', ' 2em');
                                                var fileInput = document.getElementById('fileInput');
                                                var fileDisplayArea = document.getElementById('fileDisplayArea');
                                                var file = fileInput.files[0];
                                                var imageType = /image.*/;
                                                if (file) {
                                                    if (file.type.match(imageType)) {
                                                        if(window.utils.checkImageSize(file)){
															var reader = new FileReader();
															reader.onload = function (e) {
																fileDisplayArea.innerHTML = "";
																var img = new Image(200, 200);
																img.src = reader.result;
																fileDisplayArea.appendChild(img);
															}
															reader.readAsDataURL(file);
														}
                                                    } else {
                                                        fileDisplayArea.innerHTML = "File not supported!"
                                                    }
                                                }
                                            }
                                            else {
                                                imgdata = imgData.replace("width=\"70\" height=\"70\"", "width=\"200\" height=\"200\"");
                                                $('.report-watermark').text($('#userChoiceName').val()).append("<div id=\"fileDisplayArea\">" + imgdata + "</div>");
                                            }
                                            $('.report-watermark').css('color', $('.bgColor').val());
                                            $('.report-watermark').css('transform', 'rotate(' + $('#transformId').val() + 'deg)');
                                            $('.report-watermark').css('opacity', $('#opacityId').val());
                                            $('#reportHeader').text("").append($("#summernote1").summernote("code"));
                                            $('#reportFooter').text("").append($("#summernote2").summernote("code"));
                                            
                                        }
                                    }
                                }
                                
                            })
                            box.init(function () {
                                initializeSummernote(0);
                                initializeSummernote(1);
                                /*if ($('.note-editing-area')[0].classList[1]) {
                                    $('#paperSizeId').val($('.note-editing-area')[0].classList[1].toUpperCase());
                                }*/
                            });
                           // $('#paperSizeId').val($('.note-editing-area')[0].classList[1].toUpperCase());
                            $('#removeImg').unbind('click').click(function () {
                                $('#selectImg').html("");
                                $('#selectImg').append("<input type=\"file\" id=\"fileInput\">");
                            });
                        //})
                    }
                });
               return button.render();
            });
        }
    });
}));


