import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VDMLDiagram} from './VDMLDiagram'
import {VDMLDiagramMixin} from './VDMLDiagramMixin'
import {BusinessModel} from './BusinessModel'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VDMLDiagram","appbo/vdml/VDMLDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VDMLDiagram,VDMLDiagramMixin
, BusinessModel){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!BusinessModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModel){
			BusinessModel = importPath.BusinessModel;
		}
		else{
			import('./BusinessModel').then(({ default: BusinessModel }) => {
                BusinessModel = BusinessModel;
            });
			/*require(["appbo/vdml/BusinessModel"],function(loadedModule){
				BusinessModel = loadedModule;
			});*/
		}
	}
	export class BusinessModelDiagramMixin {

	defaults(){
		var ret = {
			type: "vdml_BusinessModelDiagram"
		}
		return jQuery.extend(VDMLDiagram.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!BusinessModelDiagramMixin.cummulativeRelations) {
            BusinessModelDiagramMixin.cummulativeRelations = _.union(BusinessModelDiagramMixin.getMixinRelations()
                , VDMLDiagramMixin.getCumulativeMixinRelations()
            );
        }
		return BusinessModelDiagramMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VDMLDiagram"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "propertyShown",type : "EString",defaultValue : "null",containingClass : "vdml_BusinessModelDiagram" },
			{name : "nameFontSize",type : "EInt",defaultValue : "null",containingClass : "vdml_BusinessModelDiagram" },
			{name : "isNameItalic",type : "EBoolean",defaultValue : "null",containingClass : "vdml_BusinessModelDiagram" },
			{name : "isNameUnderlined",type : "EBoolean",defaultValue : "null",containingClass : "vdml_BusinessModelDiagram" },
			{name : "isNameBold",type : "EBoolean",defaultValue : "null",containingClass : "vdml_BusinessModelDiagram" },
			{name : "descriptionFontSize",type : "EInt",defaultValue : "null",containingClass : "vdml_BusinessModelDiagram" },
			{name : "isDescriptionItalic",type : "EBoolean",defaultValue : "null",containingClass : "vdml_BusinessModelDiagram" },
			{name : "isDescriptionBold",type : "EBoolean",defaultValue : "null",containingClass : "vdml_BusinessModelDiagram" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("businessModel") ? this.get("businessModel") : this.previousAttributes().businessModel;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("businessModel") ? this.get("businessModel") : this.previousAttributes().businessModel;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BusinessModelDiagramPropertiesTemplate.html",
			templateName : "BusinessModelDiagramPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BusinessModelDiagramViewModel",
			tabId : "BusinessModelDiagramView",
			tabName: "BusinessModelDiagram"
		}
	}
	}
	path.BusinessModelDiagramMixin = BusinessModelDiagramMixin;
	
	//return BusinessModelDiagramMixin;
//});