import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { Store } from './Store'
import { PoolMixin } from './PoolMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//import { Position } from './Position'
import { CalendarService } from './CalendarService'
import { MeasuredCharacteristic } from './MeasuredCharacteristic'
import { WorklistReference } from './WorklistReference'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Store","appbo/vdml/PoolMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Store,PoolMixin
, Position
, CalendarService
, MeasuredCharacteristic
, WorklistReference
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	//if(!Position){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Position){
			Position = importPath.Position;
		}
        else {
            import('./Position').then(loadedModule => {
           //     Position = loadedModule;
            });
			/*require(["appbo/vdml/Position"],function(loadedModule){
				Position = loadedModule;
			});*/
		}
	//}
	if(!CalendarService){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CalendarService){
			CalendarService = importPath.CalendarService;
		}
        else {
            import('./CalendarService').then(loadedModule => {
                CalendarService = loadedModule;
            });
			/*require(["appbo/vdml/CalendarService"],function(loadedModule){
				CalendarService = loadedModule;
			});*/
		}
	}
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
        else {
            import('./MeasuredCharacteristic').then(loadedModule => {
                MeasuredCharacteristic = loadedModule;
            });
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	if(!WorklistReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.WorklistReference){
			WorklistReference = importPath.WorklistReference;
		}
        else {
            import('./WorklistReference').then(loadedModule => {
                WorklistReference = loadedModule;
            });
			/*require(["appbo/vdml/WorklistReference"],function(loadedModule){
				WorklistReference = loadedModule;
			});*/
		}
	}
	
	var Pool = Store.extend(utils.customExtends({
		relations:PoolMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Store.prototype.initialize.apply(this, arguments);
		}
		}
		, new PoolMixin()
	));
	
	Pool.abstract = false;
	path.Pool = Pool;
    export { Pool };
//});