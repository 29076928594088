import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BMElement} from './BMElement'
import {BusinessModelDimensionMixin} from './BusinessModelDimensionMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BMElement","appbo/vdml/BusinessModelDimensionMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BMElement,BusinessModelDimensionMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var BusinessModelDimension = BMElement.extend(utils.customExtends({
		relations:BusinessModelDimensionMixin.getMixinRelations(),
		subModelTypes: {
			vdml_CustomerDimension : 'vdml.CustomerDimension',
			vdml_ValuePropositionDimension : 'vdml.ValuePropositionDimension',
			vdml_ActivityDimension : 'vdml.ActivityDimension',
			vdml_ValueFormulaDimension : 'vdml.ValueFormulaDimension',
			vdml_CompetenceDimension : 'vdml.CompetenceDimension',
			vdml_NetworkPartnersDimension : 'vdml.NetworkPartnersDimension'
		},
		initialize: function(attributes, options) {
			BMElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new BusinessModelDimensionMixin()
	));
	BusinessModelDimension.abstract = true;
	path.BusinessModelDimension = BusinessModelDimension;
	export {BusinessModelDimension};
//});