import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {SeasonalFactorMixin} from './SeasonalFactorMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { MeasuredCharacteristic } from './MeasuredCharacteristic'

	
var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

var SeasonalFactor = VdmlElement.extend(utils.customExtends({
    relations:SeasonalFactorMixin.getMixinRelations(),
    initialize: function(attributes, options) {
        VdmlElement.prototype.initialize.apply(this, arguments);
    }
    }
    , new SeasonalFactorMixin()
));
SeasonalFactor.getInstance = function(period,quantity,owner){
    var factorId = DataManager.getDataManager().guidGeneratorByOwner(owner);
	var seasonalFactors = new SeasonalFactor({id:factorId,period:period,quantity:quantity,seasonalFactorsOwner:owner});
	return seasonalFactors;
}

var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
SeasonalFactor.abstract = !sp2Enabled;
SeasonalFactor.supported = sp2Enabled;
path.SeasonalFactor = SeasonalFactor;
export {SeasonalFactor};
