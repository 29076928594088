import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {Characteristic} from '../smm/Characteristic'
import {DataManager} from '../../../com/vbee/data/DataManager'



var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
if (!Characteristic) {
	var importPath = DataManager.getDataManager().buildAppNsPath("smm", global.version);
	if (importPath.Characteristic) {
		Characteristic = importPath.Characteristic;
	}
	else {
		import('../smm/Characteristic').then(loadedModule => {
		Characteristic = loadedModule;
		});
		/*require(["appbo/smm/Characteristic"], function (loadedModule) {
			Characteristic = loadedModule;
		});*/
	}
}
export class ValueMixin {
	/*constructor() {
	var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
	path.ValueMixin = ValueMixin;
	}*/
	defaults() {
		var ret = {
			type: "vdml_Value"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this), ret);
	}
	static getMixinRelations() {
		return _.union([
			{
				type: Backbone.HasMany,
				containingClass: "vdml_Value",
				key: "parentValue",
				relatedModel: "vdml.Value",
				includeInJSON: Backbone.Model.prototype.idAttribute,
			},
			{
				type: Backbone.HasMany,
				containingClass: "vdml_Value",
				key: "childValue",
				relatedModel: "vdml.Value",
				includeInJSON: Backbone.Model.prototype.idAttribute,
			},
			{
				type: Backbone.HasMany,
				containingClass: "vdml_Value",
				key: "characteristicDefinition",
				relatedModel: "smm.Characteristic",
				includeInJSON: Backbone.Model.prototype.idAttribute,
			}
		])
	}
	
	static getCumulativeMixinRelations() {
		if (!ValueMixin.cummulativeRelations) {
			ValueMixin.cummulativeRelations = _.union(ValueMixin.getMixinRelations()
				, DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
			);
		}
		return ValueMixin.cummulativeRelations.slice();
	}
	static getSuperTypes() {
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties() {
		return [
			{ name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{ name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{ name: "represents", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
			{ name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
			{ name: "color", type: "EString", defaultValue: "null", containingClass: "vdml_Value" }
		]
	}
	getParent() {
		var container;
		if (!container) {
			container = this.get("valueOwner") ? this.get("valueOwner") : this.previousAttributes().valueOwner;
			if (container) {
				return container;
			}
		}
		return this;
	}
	getPackagePath(path) {
		if (!path) {
			path = [];
		}
		var container;
		if (!container) {
			container = this.get("valueOwner") ? this.get("valueOwner") : this.previousAttributes().valueOwner;
			if (container) {
				path.unshift({ id: this.get('id'), name: this.get('name') })
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type) {
		return {
			templatePath: "views/vdml/views/properties/CapabilityPropertiesTemplate.html",
			templateName: "CapabilityPropertiesTemplate",
			viewTypeStr: "appviews/vdml/views/properties/CapabilityViewModel",
			tabId: "CapabilityView",
			tabName: "Capability"
		}
	}

}
path.ValueMixin = ValueMixin;
