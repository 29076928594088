import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasureRelationship} from './MeasureRelationship'
import {EquivalentMeasureRelationshipMixin} from './EquivalentMeasureRelationshipMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Measure } from './Measure'
import { Operation } from './Operation'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasureRelationship","appbo/smm/EquivalentMeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasureRelationship,EquivalentMeasureRelationshipMixin
, Measure

, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Measure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measure){
			Measure = importPath.Measure;
		}
        else {
            import('./Measure').then(({ default: Measure }) => {
                Measure = Measure;
            });
			/*require(["appbo/smm/Measure"],function(loadedModule){
				Measure = loadedModule;
			});*/
		}
	}
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
        else {
            import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });
			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	var EquivalentMeasureRelationship = MeasureRelationship.extend(utils.customExtends({
		relations:EquivalentMeasureRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			MeasureRelationship.prototype.initialize.apply(this, arguments);
			if(attributes.influence){
				this.set('influence',path.Influence[attributes.influence instanceof Object ? attributes.influence.name : attributes.influence]);
			}
		}
		}
		, new EquivalentMeasureRelationshipMixin()
	));
	
	EquivalentMeasureRelationship.abstract = false;
	EquivalentMeasureRelationship.supported = false;
	path.EquivalentMeasureRelationship = EquivalentMeasureRelationship;
	export {EquivalentMeasureRelationship};
//});