import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
//import * as  backboneLawnchair from 'backbonelawnchair'
import {SmmElement} from './SmmElement'
import {SmmElementMixin} from './SmmElementMixin'
import * as LocaleManager from '../../../com/vbee/data/LocaleManager'
import { DataManager } from '../../../com/vbee/data/DataManager'
//import { MeasureLibrary } from './MeasureLibrary'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/SmmElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,SmmElementMixin
//, MeasureLibrary){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	//if(!MeasureLibrary){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.MeasureLibrary){
			MeasureLibrary = importPath.MeasureLibrary;
		}
        else {
            import('./MeasureLibrary').then(({ default: MeasureLibrary }) => {
                MeasureLibrary = MeasureLibrary;
            });

			/*require(["appbo/smm/MeasureLibrary"],function(loadedModule){
				MeasureLibrary = loadedModule;
			});*/
		}
	//}
export class AbstractMeasureElementMixin{
    /*constructor() {
        var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
        path.AbstractMeasureElementMixin = AbstractMeasureElementMixin;
    }*/

	defaults() {
		var ret = {
			type: "smm_AbstractMeasureElement"
		}
		return jQuery.extend(SmmElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations() {
		return _.union([
		])
	}
	static getCumulativeMixinRelations() {
		if (!AbstractMeasureElementMixin.cummulativeRelations) {
            AbstractMeasureElementMixin.cummulativeRelations = _.union(AbstractMeasureElementMixin.getMixinRelations()
                , SmmElementMixin.getCumulativeMixinRelations()
            );
        }
		return AbstractMeasureElementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes() {
		return [
			"smm_SmmElement"
		];
	}
	static getProperties() {
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("libraries") ? this.get("libraries") : this.previousAttributes().libraries;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("libraries") ? this.get("libraries") : this.previousAttributes().libraries;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/AbstractMeasureElementPropertiesTemplate.html",
			templateName : "AbstractMeasureElementPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/AbstractMeasureElementViewModel",
			tabId : "AbstractMeasureElementView",
			tabName: "AbstractMeasureElement"
		}
	}
	
	}
	path.AbstractMeasureElementMixin = AbstractMeasureElementMixin;
	//return AbstractMeasureElementMixin;
//});