import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AnalysisContext} from './AnalysisContext'
import {ScenarioMixin} from './ScenarioMixin'
import {Collaboration} from './Collaboration'
import {MeasuredCharacteristic} from './MeasuredCharacteristic'
import {ReleaseControl} from './ReleaseControl'
//import {Actor} from './Actor'
import {BusinessModel} from './BusinessModel'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/AnalysisContext","appbo/vdml/ScenarioMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AnalysisContext,ScenarioMixin
, Collaboration
, MeasuredCharacteristic
, ReleaseControl

, Actor
, BusinessModel){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Collaboration){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Collaboration){
			Collaboration = importPath.Collaboration;
		}
		else{
			import('./Collaboration').then(loadedModule => {
				Collaboration = loadedModule;
			});
			/*require(["appbo/vdml/Collaboration"],function(loadedModule){
				Collaboration = loadedModule;
			});*/
		}
	}
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
		else{
			import('./MeasuredCharacteristic').then(loadedModule => {
				MeasuredCharacteristic = loadedModule;
			});
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	if(!ReleaseControl){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ReleaseControl){
			ReleaseControl = importPath.ReleaseControl;
		}
		else{
			import('./ReleaseControl').then(loadedModule => {
				ReleaseControl = loadedModule;
			});
			/*require(["appbo/vdml/ReleaseControl"],function(loadedModule){
				ReleaseControl = loadedModule;
			});*/
		}
	}
	

	if(!BusinessModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModel){
			BusinessModel = importPath.BusinessModel;
		}
		else{
			import('./BusinessModel').then(loadedModule => {
				BusinessModel = loadedModule;
			});
			/*require(["appbo/vdml/BusinessModel"],function(loadedModule){
				BusinessModel = loadedModule;
			});*/
		}
	}
	var Scenario = AnalysisContext.extend(utils.customExtends({
		relations:ScenarioMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			AnalysisContext.prototype.initialize.apply(this, arguments);
			//this.on("change:localChange",this.disablePackagesUpdates);
		}
		}
		, new ScenarioMixin()
	));
    Scenario["change:localChange"] = function (value, update) {
        this.disablePackagesUpdates(value, update);
    }
	Scenario.abstract = false;
	path.Scenario = Scenario;
	export {Scenario};
//});