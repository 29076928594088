import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmRelationship} from './SmmRelationship'
import {SmmRelationshipMixin} from './SmmRelationshipMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Measure } from './Measure'
import { Operation } from './Operation'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmRelationship","appbo/smm/SmmRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmRelationship,SmmRelationshipMixin
, Measure
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Measure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measure){
			Measure = importPath.Measure;
		}
        else {
            import('./Measure').then(({ default: Measure }) => {
                Measure = Measure;
            });
			/*require(["appbo/smm/Measure"],function(loadedModule){
				Measure = loadedModule;
			});*/
		}
	}
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
        else {
            import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });
			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	export class MeasureRelationshipMixin {

	defaults(){
		var ret = {
			type: "smm_MeasureRelationship"
		}
		return jQuery.extend(SmmRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!MeasureRelationshipMixin.cummulativeRelations) {
            MeasureRelationshipMixin.cummulativeRelations = _.union(MeasureRelationshipMixin.getMixinRelations()
                , SmmRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return MeasureRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_SmmRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "influence",type : "Influence",defaultValue : "null",containingClass : "smm_MeasureRelationship" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("measure") ? this.get("measure") : this.previousAttributes().measure;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("measure") ? this.get("measure") : this.previousAttributes().measure;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/MeasureRelationshipPropertiesTemplate.html",
			templateName : "MeasureRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/MeasureRelationshipViewModel",
			tabId : "MeasureRelationshipView",
			tabName: "MeasureRelationship"
		}
	}
	
	}
	path.MeasureRelationshipMixin = MeasureRelationshipMixin;
	//return MeasureRelationshipMixin;
//});