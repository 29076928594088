import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelItem} from './BusinessModelItem'
import {BusinessModelNetworkPartnerMixin} from './BusinessModelNetworkPartnerMixin'
import {Participant} from './Participant'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelItem","appbo/vdml/BusinessModelNetworkPartnerMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelItem,BusinessModelNetworkPartnerMixin
, Participant){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Participant){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Participant){
			Participant = importPath.Participant;
		}
		else{
			import('./Participant').then(({ default: Participant }) => {
                Participant = Participant;
            });
			/*require(["appbo/vdml/Participant"],function(loadedModule){
				Participant = loadedModule;
			});*/
		}
	}
	var BusinessModelNetworkPartner = BusinessModelItem.extend(utils.customExtends({
		relations:BusinessModelNetworkPartnerMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BusinessModelItem.prototype.initialize.apply(this, arguments);
		}
		}
		, new BusinessModelNetworkPartnerMixin()
	));
	
	BusinessModelNetworkPartner.abstract = false;
	path.BusinessModelNetworkPartner = BusinessModelNetworkPartner;
	export {BusinessModelNetworkPartner};
//});