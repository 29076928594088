import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BaseMeasurementRelationship} from './BaseMeasurementRelationship'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { DimensionalMeasurement } from './DimensionalMeasurement'
import { BinaryMeasurement } from './BinaryMeasurement'
import { BaseMeasurementRelationshipMixin } from './BaseMeasurementRelationshipMixin'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/BaseMeasurementRelationship","appbo/smm/BaseMeasurementRelationshipMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BaseMeasurementRelationship,BaseMeasurementRelationshipMixin
//, DimensionalMeasurement
//, BinaryMeasurement){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!DimensionalMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasurement){
			DimensionalMeasurement = importPath.DimensionalMeasurement;
		}
        else {
            import('./DimensionalMeasurement').then(({ default: DimensionalMeasurement }) => {
                DimensionalMeasurement = DimensionalMeasurement;
            });

			/*require(["appbo/smm/DimensionalMeasurement"],function(loadedModule){
				DimensionalMeasurement = loadedModule;
			});*/
		}
	}
	if(!BinaryMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.BinaryMeasurement){
			BinaryMeasurement = importPath.BinaryMeasurement;
		}
        else {
            import('./BinaryMeasurement').then(({ default: BinaryMeasurement }) => {
                BinaryMeasurement = BinaryMeasurement;
            });

			/*require(["appbo/smm/BinaryMeasurement"],function(loadedModule){
				BinaryMeasurement = loadedModule;
			});*/
		}
	}
export class Base1MeasurementRelationshipMixin {

	defaults(){
		var ret = {
			type: "smm_Base1MeasurementRelationship"
		}
		return jQuery.extend(BaseMeasurementRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		/*{
			type :Backbone.HasOne,
			containingClass:"smm_Base1MeasurementRelationship",
			key:"to",
			relatedModel:"smm.DimensionalMeasurement",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"baseMeasurement1From",
				type :Backbone.HasMany,
				includeInJSON:"id"
			}				
		},
		{
			type :Backbone.HasOne,
			containingClass:"smm_Base1MeasurementRelationship",
			key:"from",
			relatedModel:"smm.BinaryMeasurement",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"baseMeasurement1To",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}*/
		])
	}
	static getCumulativeMixinRelations(){
		if (!Base1MeasurementRelationshipMixin.cummulativeRelations) {
            Base1MeasurementRelationshipMixin.cummulativeRelations = _.union(Base1MeasurementRelationshipMixin.getMixinRelations()
                , BaseMeasurementRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return Base1MeasurementRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_BaseMeasurementRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" }
		]
	}
	getParent(){
		var container;
		return BaseMeasurementRelationship.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return BaseMeasurementRelationship.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/Base1MeasurementRelationshipPropertiesTemplate.html",
			templateName : "Base1MeasurementRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/Base1MeasurementRelationshipViewModel",
			tabId : "Base1MeasurementRelationshipView",
			tabName: "Base1MeasurementRelationship"
		}
	}
//#startCustomMethods
    onToRemoved(model, newTo, options) {
		if(Backbone.skipPersistance){
			return;
		}		
		if(!newTo && options.__related){
			var parent = model.get('from');
			model.destroy();	
			parent.destroy();
		}
	}
    //#endCustomMethods	
}
	path.Base1MeasurementRelationshipMixin = Base1MeasurementRelationshipMixin;
	
	//return Base1MeasurementRelationshipMixin;
//});