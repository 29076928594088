import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BeepPackage} from '../beeppackage/BeepPackage'
import {BeepPackageMixin} from '../beeppackage/BeepPackageMixin'
import {Operation} from './Operation'
import {SmmElement} from './SmmElement'
import {SmmElementMixin} from './SmmElementMixin'
import {MeasureLibrary} from './MeasureLibrary'
import {Observation} from './Observation'
import {BusinessIndicatorLibrary} from './BusinessIndicatorLibrary'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/beeppackage/BeepPackage", "appbo/beeppackage/BeepPackageMixin", "appbo/smm/Operation", "appbo/smm/SmmElementMixin","appbo/smm/BusinessIndicatorLibrary"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BeepPackage,BeepPackageMixin,Operation

    , SmmElementMixin
    , BusinessIndicatorLibrary
    , SmmElement
, Observation
,MeasureLibrary
) {*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!SmmElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.SmmElement){
			SmmElement = importPath.SmmElement;
		}
		else{
			import('./SmmElement').then(({ default: SmmElement }) => {
                SmmElement = SmmElement;
            });
			/*require(["appbo/smm/SmmElement"],function(loadedModule){
				SmmElement = loadedModule;
			});*/
		}
	}
	if(!SmmElementMixin){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.SmmElementMixin){
			SmmElementMixin = importPath.SmmElementMixin;
		}
		else{
			import('./SmmElementMixin').then(({ default: SmmElementMixin }) => {
                SmmElementMixin = SmmElementMixin;
            });
			/*require(["appbo/smm/SmmElementMixin"],function(loadedModule){
				SmmElementMixin = loadedModule;
			});*/
		}
	}
	if(!Observation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Observation){
			Observation = importPath.Observation;
		}
		else{
			import('./Observation').then(({ default: Observation }) => {
                Observation = Observation;
            });
			/*require(["appbo/smm/Observation"],function(loadedModule){
				Observation = loadedModule;
			});*/
		}
	}
	if(!MeasureLibrary){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.MeasureLibrary){
			MeasureLibrary = importPath.MeasureLibrary;
		}
		else{
			import('./MeasureLibrary').then(({ default: MeasureLibrary }) => {
                MeasureLibrary = MeasureLibrary;
            });
			/*require(["appbo/smm/MeasureLibrary"],function(loadedModule){
				MeasureLibrary = loadedModule;
			});*/
		}
    }
    if (!BusinessIndicatorLibrary) {
        var importPath = DataManager.getDataManager().buildAppNsPath("smm", global.version);
        if (importPath.BusinessIndicatorLibrary) {
            BusinessIndicatorLibrary = importPath.BusinessIndicatorLibrary;
        }
        else {
			import('./BusinessIndicatorLibrary').then(({ default: MeasureLibrary }) => {
                BusinessIndicatorLibrary = BusinessIndicatorLibrary;
            });
            /*require(["appbo/smm/BusinessIndicatorLibrary"], function (loadedModule) {
                BusinessIndicatorLibrary = loadedModule;
            });*/
        }
    }
	export class SmmModelMixin {

	defaults(){
		var ret = {
			type: "smm_SmmModel"
		}
		return jQuery.extend(BeepPackage.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"smm_SmmModel",
			key:"observations",
			relatedModel:"smm.Observation",
			reverseRelation: {
				key:"smmModel",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_SmmModel",
			key:"libraries",
			relatedModel:"smm.MeasureLibrary",
			reverseRelation: {
				key:"smmModel",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
        },
        {
            type: Backbone.HasMany,
            containingClass: "smm_SmmModel",
            key: "businessIndicatorLibraries",
            relatedModel: "smm.BusinessIndicatorLibrary",
            reverseRelation: {
                key: "smmModel",
                type: Backbone.HasOne,
                includeInJSON: "id"
            }
        }
	    ])
	}
	static getCumulativeMixinRelations(){
		if (!SmmModelMixin.cummulativeRelations) {
            SmmModelMixin.cummulativeRelations = _.union(SmmModelMixin.getMixinRelations()
                ,SmmElementMixin.getCumulativeMixinRelations()
		        ,BeepPackageMixin.getCumulativeMixinRelations()
            );
        }
		return SmmModelMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_SmmElement",
			"beeppackage_BeepPackage"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "label",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "parentLabel",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "syncedChangeLog",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "synchedChangeSet",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("planObservationContainerOwner") ? this.get("planObservationContainerOwner") : this.previousAttributes().planObservationContainerOwner;
			if(container){
				return container;
			}
		}
		if(!container){
			container = this.get("metricsModelOwner") ? this.get("metricsModelOwner") : this.previousAttributes().metricsModelOwner;
			if(container && container instanceof Backbone.Model){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("planObservationContainerOwner") ? this.get("planObservationContainerOwner") : this.previousAttributes().planObservationContainerOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		if(!container){
			container = this.get("metricsModelOwner") ? this.get("metricsModelOwner") : this.previousAttributes().metricsModelOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/SmmModelPropertiesTemplate.html",
			templateName : "SmmModelPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/SmmModelViewModel",
			tabId : "SmmModelView",
			tabName: "SmmModel"
		}
	}
//#startCustomMethods
    createObservation(name, obsTimeStampId, snapshotId, altId) {
        var observations = this.get("observations").where({ whenObserved: obsTimeStampId });
        for(var i=0;i<observations.length;i++) {
        	var observation = observations[i];
            if (altId) {
                var context = observation.getContext();
                var alt = context.get('phaseObjectiveSetOwner');
                var obsAltId;
                if (alt) {
                    obsAltId = alt.id;
                } else {
                    while (context.get('parentContext')) {
                        context = context.get('parentContext');
                    }
                    var contextAltId = DataManager.getDataManager().getRepositoryId(context.id);
                    var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
                    alt = docTypeObj.find({ id: contextAltId });
                    var plan = alt.get('phaseAlternativeOwner').get('phaseOwner');
                    var proxy = plan.getScenarioProxy(context.id);
                    if (proxy) {
                    	var obsAlt = proxy.get('designScenarioOwner');
                        obsAltId = obsAlt.id;
                    }
                }
                if (obsAltId === altId) {
                    if (observation.get('name') !== name) {
                        observation.set('name', name);
                    }
                    return observation;
                }
            } else {
                if (observation.get('name') !== name) {
                    observation.set('name', name);
                }
                return observation;
            }
        }
		var obsId = DataManager.getDataManager().guidGeneratorByOwner(this);
		//this.getUniqueProperty('name',name,'observations')
		if(!obsTimeStampId){
			name = 'Base Scenario';
		}
		/*if(!snapshotId){
			snapshotId = window.uuidGenerator();
		}*/
		observation = new Observation({id:obsId,name:name,smmModel:this});
		if(obsTimeStampId){
			observation.set({'whenObserved':obsTimeStampId,'whenObservedGuid':obsTimeStampId});
		}
		return observation;
	}
    createMeasureLibrary (nameVal) {
        var mlId = DataManager.getDataManager().guidGeneratorByOwner(this);
        var measureLibrary = new MeasureLibrary({ id: mlId, name: nameVal, description: nameVal, smmModel: this });
        var invOperationId = DataManager.getDataManager().guidGeneratorByOwner(this);
        var invOperation = new Operation({ id: invOperationId, libraries: measureLibrary, name: 'invertOperation', description: 'Invert Operation', language: 'javaScript' });
        //invOperation.set('body', Operation.invertOperation);
        var squareOperationId = DataManager.getDataManager().guidGeneratorByOwner(this);
        var squareOperation = new Operation({ id: squareOperationId, libraries: measureLibrary, name: 'squareOperation', description: 'Square Operation', language: 'javaScript' });
        //squareOperation.set('body', Operation.squareOperation);
        var sqrtOperationId = DataManager.getDataManager().guidGeneratorByOwner(this);
        var sqrtOperation = new Operation({ id: sqrtOperationId, libraries: measureLibrary, name: 'squareRootOperation', description: 'Square Root Operation', language: 'javaScript' });
        //sqrtOperation.set('body', Operation.sqrtOperation);
        return measureLibrary;
    };
    /*getOrCreateBusinessIndicatorLibrary (nameVal, whenObservedGuid) {
        var mestLibrary = this.get('businessIndicatorLibraries').find({ scenario: whenObservedGuid });
        if (mestLibrary) {
            return mestLibrary;
        } else if (!whenObservedGuid || whenObservedGuid === ""){
            mestLibrary = this.get('businessIndicatorLibraries').find({ 'name': nameVal });
            if (mestLibrary) {
                return mestLibrary;
            }
        }
       var mlId = DataManager.getDataManager().guidGeneratorByOwner(this);
        var businessIndicatorLibrary = new BusinessIndicatorLibrary({ id: mlId, name: nameVal, description: nameVal, smmModel: this });
        businessIndicatorLibrary.set('scenario', whenObservedGuid);
        return businessIndicatorLibrary;
    };*/
//#endCustomMethods
	
	}
	path.SmmModelMixin = SmmModelMixin;
	//return SmmModelMixin;
//});