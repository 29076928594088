// Generated from ./src/main/antlr4/ExprLang.g4 by ANTLR 4.13.2
// jshint ignore: start
import antlr4 from 'antlr4';
import ExprLangListener from './ExprLangListener.js';
const serializedATN = [4,1,42,199,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,
4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,1,0,4,0,22,8,0,11,0,12,0,23,1,
0,1,0,1,1,1,1,3,1,30,8,1,1,1,1,1,3,1,34,8,1,3,1,36,8,1,1,2,1,2,1,2,1,2,1,
2,1,2,1,2,5,2,45,8,2,10,2,12,2,48,9,2,1,2,1,2,3,2,52,8,2,1,2,1,2,1,3,1,3,
1,3,1,3,1,3,1,3,1,3,1,3,1,3,1,3,1,3,1,3,1,3,3,3,69,8,3,1,3,1,3,1,3,1,3,1,
3,1,3,1,3,1,3,1,3,1,3,5,3,81,8,3,10,3,12,3,84,9,3,1,4,1,4,1,5,1,5,1,6,1,
6,1,6,3,6,93,8,6,1,6,1,6,1,6,1,6,1,6,1,6,3,6,101,8,6,1,6,1,6,3,6,105,8,6,
1,7,1,7,3,7,109,8,7,1,7,1,7,1,7,3,7,114,8,7,5,7,116,8,7,10,7,12,7,119,9,
7,1,8,1,8,3,8,123,8,8,1,8,1,8,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,
9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,5,9,148,8,9,10,9,12,9,151,9,9,
1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,5,9,
170,8,9,10,9,12,9,173,9,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,5,9,182,8,9,10,9,12,
9,185,9,9,1,9,1,9,1,9,1,9,1,9,5,9,192,8,9,10,9,12,9,195,9,9,3,9,197,8,9,
1,9,0,1,6,10,0,2,4,6,8,10,12,14,16,18,0,2,1,0,10,28,2,0,11,11,29,29,216,
0,21,1,0,0,0,2,35,1,0,0,0,4,37,1,0,0,0,6,68,1,0,0,0,8,85,1,0,0,0,10,87,1,
0,0,0,12,104,1,0,0,0,14,108,1,0,0,0,16,120,1,0,0,0,18,196,1,0,0,0,20,22,
3,2,1,0,21,20,1,0,0,0,22,23,1,0,0,0,23,21,1,0,0,0,23,24,1,0,0,0,24,25,1,
0,0,0,25,26,5,0,0,1,26,1,1,0,0,0,27,29,3,4,2,0,28,30,5,1,0,0,29,28,1,0,0,
0,29,30,1,0,0,0,30,36,1,0,0,0,31,33,3,6,3,0,32,34,5,1,0,0,33,32,1,0,0,0,
33,34,1,0,0,0,34,36,1,0,0,0,35,27,1,0,0,0,35,31,1,0,0,0,36,3,1,0,0,0,37,
38,5,2,0,0,38,39,3,6,3,0,39,46,3,6,3,0,40,41,5,3,0,0,41,42,3,6,3,0,42,43,
3,6,3,0,43,45,1,0,0,0,44,40,1,0,0,0,45,48,1,0,0,0,46,44,1,0,0,0,46,47,1,
0,0,0,47,51,1,0,0,0,48,46,1,0,0,0,49,50,5,4,0,0,50,52,3,6,3,0,51,49,1,0,
0,0,51,52,1,0,0,0,52,53,1,0,0,0,53,54,5,5,0,0,54,5,1,0,0,0,55,56,6,3,-1,
0,56,57,3,10,5,0,57,58,3,6,3,7,58,69,1,0,0,0,59,60,5,8,0,0,60,61,3,6,3,0,
61,62,5,9,0,0,62,69,1,0,0,0,63,69,3,12,6,0,64,69,3,18,9,0,65,69,5,39,0,0,
66,69,5,40,0,0,67,69,5,41,0,0,68,55,1,0,0,0,68,59,1,0,0,0,68,63,1,0,0,0,
68,64,1,0,0,0,68,65,1,0,0,0,68,66,1,0,0,0,68,67,1,0,0,0,69,82,1,0,0,0,70,
71,10,9,0,0,71,72,5,6,0,0,72,73,3,6,3,0,73,74,5,7,0,0,74,75,3,6,3,10,75,
81,1,0,0,0,76,77,10,8,0,0,77,78,3,8,4,0,78,79,3,6,3,9,79,81,1,0,0,0,80,70,
1,0,0,0,80,76,1,0,0,0,81,84,1,0,0,0,82,80,1,0,0,0,82,83,1,0,0,0,83,7,1,0,
0,0,84,82,1,0,0,0,85,86,7,0,0,0,86,9,1,0,0,0,87,88,7,1,0,0,88,11,1,0,0,0,
89,90,5,39,0,0,90,92,5,8,0,0,91,93,3,14,7,0,92,91,1,0,0,0,92,93,1,0,0,0,
93,94,1,0,0,0,94,105,5,9,0,0,95,96,3,18,9,0,96,97,5,30,0,0,97,98,5,39,0,
0,98,100,5,8,0,0,99,101,3,14,7,0,100,99,1,0,0,0,100,101,1,0,0,0,101,102,
1,0,0,0,102,103,5,9,0,0,103,105,1,0,0,0,104,89,1,0,0,0,104,95,1,0,0,0,105,
13,1,0,0,0,106,109,3,6,3,0,107,109,3,16,8,0,108,106,1,0,0,0,108,107,1,0,
0,0,109,117,1,0,0,0,110,113,5,31,0,0,111,114,3,6,3,0,112,114,3,16,8,0,113,
111,1,0,0,0,113,112,1,0,0,0,114,116,1,0,0,0,115,110,1,0,0,0,116,119,1,0,
0,0,117,115,1,0,0,0,117,118,1,0,0,0,118,15,1,0,0,0,119,117,1,0,0,0,120,122,
5,32,0,0,121,123,3,14,7,0,122,121,1,0,0,0,122,123,1,0,0,0,123,124,1,0,0,
0,124,125,5,33,0,0,125,17,1,0,0,0,126,127,5,34,0,0,127,128,5,30,0,0,128,
129,5,35,0,0,129,130,5,30,0,0,130,131,5,39,0,0,131,132,5,30,0,0,132,133,
5,36,0,0,133,134,5,30,0,0,134,135,5,39,0,0,135,136,5,30,0,0,136,137,5,37,
0,0,137,138,5,30,0,0,138,139,5,39,0,0,139,140,5,30,0,0,140,141,5,38,0,0,
141,142,5,30,0,0,142,149,5,39,0,0,143,144,5,32,0,0,144,145,3,6,3,0,145,146,
5,33,0,0,146,148,1,0,0,0,147,143,1,0,0,0,148,151,1,0,0,0,149,147,1,0,0,0,
149,150,1,0,0,0,150,197,1,0,0,0,151,149,1,0,0,0,152,153,5,34,0,0,153,154,
5,30,0,0,154,155,5,35,0,0,155,156,5,30,0,0,156,157,5,39,0,0,157,158,5,30,
0,0,158,159,5,36,0,0,159,160,5,30,0,0,160,161,5,39,0,0,161,162,5,30,0,0,
162,163,5,38,0,0,163,164,5,30,0,0,164,171,5,39,0,0,165,166,5,32,0,0,166,
167,3,6,3,0,167,168,5,33,0,0,168,170,1,0,0,0,169,165,1,0,0,0,170,173,1,0,
0,0,171,169,1,0,0,0,171,172,1,0,0,0,172,197,1,0,0,0,173,171,1,0,0,0,174,
175,5,34,0,0,175,176,5,30,0,0,176,183,5,39,0,0,177,178,5,32,0,0,178,179,
3,6,3,0,179,180,5,33,0,0,180,182,1,0,0,0,181,177,1,0,0,0,182,185,1,0,0,0,
183,181,1,0,0,0,183,184,1,0,0,0,184,197,1,0,0,0,185,183,1,0,0,0,186,193,
5,39,0,0,187,188,5,32,0,0,188,189,3,6,3,0,189,190,5,33,0,0,190,192,1,0,0,
0,191,187,1,0,0,0,192,195,1,0,0,0,193,191,1,0,0,0,193,194,1,0,0,0,194,197,
1,0,0,0,195,193,1,0,0,0,196,126,1,0,0,0,196,152,1,0,0,0,196,174,1,0,0,0,
196,186,1,0,0,0,197,19,1,0,0,0,21,23,29,33,35,46,51,68,80,82,92,100,104,
108,113,117,122,149,171,183,193,196];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

const sharedContextCache = new antlr4.atn.PredictionContextCache();

export default class ExprLangParser extends antlr4.Parser {

    static grammarFileName = "ExprLang.g4";
    static literalNames = [ null, "';'", "'if'", "'elseif'", "'else'", "'end'", 
                            "'?'", "':'", "'('", "')'", "'+'", "'-'", "'*'", 
                            "'/'", "'||'", "'&&'", "'^'", "'<'", "'>'", 
                            "'<='", "'>='", "'=='", "'!='", "'='", "'+='", 
                            "'-='", "'*='", "'/='", "'&'", "'!'", "'.'", 
                            "','", "'['", "']'", "'self'", "'bm'", "'vp'", 
                            "'activity'", "'value'" ];
    static symbolicNames = [ null, null, null, null, null, null, null, null, 
                             null, null, null, null, null, null, null, null, 
                             null, null, null, null, null, null, null, null, 
                             null, null, null, null, null, null, null, null, 
                             null, null, null, null, null, null, null, "IDENTIFIER", 
                             "NUMBER", "STRING", "WS" ];
    static ruleNames = [ "program", "statement", "ifElseExpr", "vmpExpr", 
                         "binaryOp", "unaryOp", "func", "vmpExprList", "array", 
                         "objAccess" ];

    constructor(input) {
        super(input);
        this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
        this.ruleNames = ExprLangParser.ruleNames;
        this.literalNames = ExprLangParser.literalNames;
        this.symbolicNames = ExprLangParser.symbolicNames;
    }

    sempred(localctx, ruleIndex, predIndex) {
    	switch(ruleIndex) {
    	case 3:
    	    		return this.vmpExpr_sempred(localctx, predIndex);
        default:
            throw "No predicate with index:" + ruleIndex;
       }
    }

    vmpExpr_sempred(localctx, predIndex) {
    	switch(predIndex) {
    		case 0:
    			return this.precpred(this._ctx, 9);
    		case 1:
    			return this.precpred(this._ctx, 8);
    		default:
    			throw "No predicate with index:" + predIndex;
    	}
    };




	program() {
	    let localctx = new ProgramContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 0, ExprLangParser.RULE_program);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 21; 
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        do {
	            this.state = 20;
	            this.statement();
	            this.state = 23; 
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        } while((((_la) & ~0x1f) === 0 && ((1 << _la) & 536873220) !== 0) || ((((_la - 34)) & ~0x1f) === 0 && ((1 << (_la - 34)) & 225) !== 0));
	        this.state = 25;
	        this.match(ExprLangParser.EOF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	statement() {
	    let localctx = new StatementContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 2, ExprLangParser.RULE_statement);
	    var _la = 0;
	    try {
	        this.state = 35;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 2:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 27;
	            this.ifElseExpr();
	            this.state = 29;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===1) {
	                this.state = 28;
	                this.match(ExprLangParser.T__0);
	            }

	            break;
	        case 8:
	        case 11:
	        case 29:
	        case 34:
	        case 39:
	        case 40:
	        case 41:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 31;
	            this.vmpExpr(0);
	            this.state = 33;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===1) {
	                this.state = 32;
	                this.match(ExprLangParser.T__0);
	            }

	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	ifElseExpr() {
	    let localctx = new IfElseExprContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 4, ExprLangParser.RULE_ifElseExpr);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 37;
	        this.match(ExprLangParser.T__1);
	        this.state = 38;
	        this.vmpExpr(0);
	        this.state = 39;
	        this.vmpExpr(0);
	        this.state = 46;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===3) {
	            this.state = 40;
	            this.match(ExprLangParser.T__2);
	            this.state = 41;
	            this.vmpExpr(0);
	            this.state = 42;
	            this.vmpExpr(0);
	            this.state = 48;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 51;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===4) {
	            this.state = 49;
	            this.match(ExprLangParser.T__3);
	            this.state = 50;
	            this.vmpExpr(0);
	        }

	        this.state = 53;
	        this.match(ExprLangParser.T__4);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


	vmpExpr(_p) {
		if(_p===undefined) {
		    _p = 0;
		}
	    const _parentctx = this._ctx;
	    const _parentState = this.state;
	    let localctx = new VmpExprContext(this, this._ctx, _parentState);
	    let _prevctx = localctx;
	    const _startState = 6;
	    this.enterRecursionRule(localctx, 6, ExprLangParser.RULE_vmpExpr, _p);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 68;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,6,this._ctx);
	        switch(la_) {
	        case 1:
	            localctx = new UnaryvmpExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;

	            this.state = 56;
	            this.unaryOp();
	            this.state = 57;
	            this.vmpExpr(7);
	            break;

	        case 2:
	            localctx = new ParenvmpExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 59;
	            this.match(ExprLangParser.T__7);
	            this.state = 60;
	            this.vmpExpr(0);
	            this.state = 61;
	            this.match(ExprLangParser.T__8);
	            break;

	        case 3:
	            localctx = new FunctionCallContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 63;
	            this.func();
	            break;

	        case 4:
	            localctx = new ObjectAccessContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 64;
	            this.objAccess();
	            break;

	        case 5:
	            localctx = new VariableContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 65;
	            this.match(ExprLangParser.IDENTIFIER);
	            break;

	        case 6:
	            localctx = new NumberContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 66;
	            this.match(ExprLangParser.NUMBER);
	            break;

	        case 7:
	            localctx = new StringContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 67;
	            this.match(ExprLangParser.STRING);
	            break;

	        }
	        this._ctx.stop = this._input.LT(-1);
	        this.state = 82;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,8,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                if(this._parseListeners!==null) {
	                    this.triggerExitRuleEvent();
	                }
	                _prevctx = localctx;
	                this.state = 80;
	                this._errHandler.sync(this);
	                var la_ = this._interp.adaptivePredict(this._input,7,this._ctx);
	                switch(la_) {
	                case 1:
	                    localctx = new TernaryExpressionContext(this, new VmpExprContext(this, _parentctx, _parentState));
	                    this.pushNewRecursionContext(localctx, _startState, ExprLangParser.RULE_vmpExpr);
	                    this.state = 70;
	                    if (!( this.precpred(this._ctx, 9))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 9)");
	                    }
	                    this.state = 71;
	                    this.match(ExprLangParser.T__5);
	                    this.state = 72;
	                    this.vmpExpr(0);
	                    this.state = 73;
	                    this.match(ExprLangParser.T__6);
	                    this.state = 74;
	                    this.vmpExpr(10);
	                    break;

	                case 2:
	                    localctx = new BinaryvmpExpressionContext(this, new VmpExprContext(this, _parentctx, _parentState));
	                    this.pushNewRecursionContext(localctx, _startState, ExprLangParser.RULE_vmpExpr);
	                    this.state = 76;
	                    if (!( this.precpred(this._ctx, 8))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 8)");
	                    }
	                    this.state = 77;
	                    this.binaryOp();
	                    this.state = 78;
	                    this.vmpExpr(9);
	                    break;

	                } 
	            }
	            this.state = 84;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,8,this._ctx);
	        }

	    } catch( error) {
	        if(error instanceof antlr4.error.RecognitionException) {
		        localctx.exception = error;
		        this._errHandler.reportError(this, error);
		        this._errHandler.recover(this, error);
		    } else {
		    	throw error;
		    }
	    } finally {
	        this.unrollRecursionContexts(_parentctx)
	    }
	    return localctx;
	}



	binaryOp() {
	    let localctx = new BinaryOpContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 8, ExprLangParser.RULE_binaryOp);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 85;
	        _la = this._input.LA(1);
	        if(!((((_la) & ~0x1f) === 0 && ((1 << _la) & 536869888) !== 0))) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	unaryOp() {
	    let localctx = new UnaryOpContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 10, ExprLangParser.RULE_unaryOp);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 87;
	        _la = this._input.LA(1);
	        if(!(_la===11 || _la===29)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	func() {
	    let localctx = new FuncContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 12, ExprLangParser.RULE_func);
	    var _la = 0;
	    try {
	        this.state = 104;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,11,this._ctx);
	        switch(la_) {
	        case 1:
	            localctx = new SimpleFunctionCallContext(this, localctx);
	            this.enterOuterAlt(localctx, 1);
	            this.state = 89;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 90;
	            this.match(ExprLangParser.T__7);
	            this.state = 92;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if((((_la) & ~0x1f) === 0 && ((1 << _la) & 536873216) !== 0) || ((((_la - 32)) & ~0x1f) === 0 && ((1 << (_la - 32)) & 901) !== 0)) {
	                this.state = 91;
	                this.vmpExprList();
	            }

	            this.state = 94;
	            this.match(ExprLangParser.T__8);
	            break;

	        case 2:
	            localctx = new MethodFunctionCallContext(this, localctx);
	            this.enterOuterAlt(localctx, 2);
	            this.state = 95;
	            this.objAccess();
	            this.state = 96;
	            this.match(ExprLangParser.T__29);
	            this.state = 97;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 98;
	            this.match(ExprLangParser.T__7);
	            this.state = 100;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if((((_la) & ~0x1f) === 0 && ((1 << _la) & 536873216) !== 0) || ((((_la - 32)) & ~0x1f) === 0 && ((1 << (_la - 32)) & 901) !== 0)) {
	                this.state = 99;
	                this.vmpExprList();
	            }

	            this.state = 102;
	            this.match(ExprLangParser.T__8);
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	vmpExprList() {
	    let localctx = new VmpExprListContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 14, ExprLangParser.RULE_vmpExprList);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 108;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 8:
	        case 11:
	        case 29:
	        case 34:
	        case 39:
	        case 40:
	        case 41:
	            this.state = 106;
	            this.vmpExpr(0);
	            break;
	        case 32:
	            this.state = 107;
	            this.array();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	        this.state = 117;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===31) {
	            this.state = 110;
	            this.match(ExprLangParser.T__30);
	            this.state = 113;
	            this._errHandler.sync(this);
	            switch(this._input.LA(1)) {
	            case 8:
	            case 11:
	            case 29:
	            case 34:
	            case 39:
	            case 40:
	            case 41:
	                this.state = 111;
	                this.vmpExpr(0);
	                break;
	            case 32:
	                this.state = 112;
	                this.array();
	                break;
	            default:
	                throw new antlr4.error.NoViableAltException(this);
	            }
	            this.state = 119;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	array() {
	    let localctx = new ArrayContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 16, ExprLangParser.RULE_array);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 120;
	        this.match(ExprLangParser.T__31);
	        this.state = 122;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if((((_la) & ~0x1f) === 0 && ((1 << _la) & 536873216) !== 0) || ((((_la - 32)) & ~0x1f) === 0 && ((1 << (_la - 32)) & 901) !== 0)) {
	            this.state = 121;
	            this.vmpExprList();
	        }

	        this.state = 124;
	        this.match(ExprLangParser.T__32);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	objAccess() {
	    let localctx = new ObjAccessContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 18, ExprLangParser.RULE_objAccess);
	    try {
	        this.state = 196;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,20,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 126;
	            this.match(ExprLangParser.T__33);
	            this.state = 127;
	            this.match(ExprLangParser.T__29);
	            this.state = 128;
	            this.match(ExprLangParser.T__34);
	            this.state = 129;
	            this.match(ExprLangParser.T__29);
	            this.state = 130;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 131;
	            this.match(ExprLangParser.T__29);
	            this.state = 132;
	            this.match(ExprLangParser.T__35);
	            this.state = 133;
	            this.match(ExprLangParser.T__29);
	            this.state = 134;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 135;
	            this.match(ExprLangParser.T__29);
	            this.state = 136;
	            this.match(ExprLangParser.T__36);
	            this.state = 137;
	            this.match(ExprLangParser.T__29);
	            this.state = 138;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 139;
	            this.match(ExprLangParser.T__29);
	            this.state = 140;
	            this.match(ExprLangParser.T__37);
	            this.state = 141;
	            this.match(ExprLangParser.T__29);
	            this.state = 142;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 149;
	            this._errHandler.sync(this);
	            var _alt = this._interp.adaptivePredict(this._input,16,this._ctx)
	            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	                if(_alt===1) {
	                    this.state = 143;
	                    this.match(ExprLangParser.T__31);
	                    this.state = 144;
	                    this.vmpExpr(0);
	                    this.state = 145;
	                    this.match(ExprLangParser.T__32); 
	                }
	                this.state = 151;
	                this._errHandler.sync(this);
	                _alt = this._interp.adaptivePredict(this._input,16,this._ctx);
	            }

	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 152;
	            this.match(ExprLangParser.T__33);
	            this.state = 153;
	            this.match(ExprLangParser.T__29);
	            this.state = 154;
	            this.match(ExprLangParser.T__34);
	            this.state = 155;
	            this.match(ExprLangParser.T__29);
	            this.state = 156;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 157;
	            this.match(ExprLangParser.T__29);
	            this.state = 158;
	            this.match(ExprLangParser.T__35);
	            this.state = 159;
	            this.match(ExprLangParser.T__29);
	            this.state = 160;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 161;
	            this.match(ExprLangParser.T__29);
	            this.state = 162;
	            this.match(ExprLangParser.T__37);
	            this.state = 163;
	            this.match(ExprLangParser.T__29);
	            this.state = 164;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 171;
	            this._errHandler.sync(this);
	            var _alt = this._interp.adaptivePredict(this._input,17,this._ctx)
	            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	                if(_alt===1) {
	                    this.state = 165;
	                    this.match(ExprLangParser.T__31);
	                    this.state = 166;
	                    this.vmpExpr(0);
	                    this.state = 167;
	                    this.match(ExprLangParser.T__32); 
	                }
	                this.state = 173;
	                this._errHandler.sync(this);
	                _alt = this._interp.adaptivePredict(this._input,17,this._ctx);
	            }

	            break;

	        case 3:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 174;
	            this.match(ExprLangParser.T__33);
	            this.state = 175;
	            this.match(ExprLangParser.T__29);
	            this.state = 176;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 183;
	            this._errHandler.sync(this);
	            var _alt = this._interp.adaptivePredict(this._input,18,this._ctx)
	            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	                if(_alt===1) {
	                    this.state = 177;
	                    this.match(ExprLangParser.T__31);
	                    this.state = 178;
	                    this.vmpExpr(0);
	                    this.state = 179;
	                    this.match(ExprLangParser.T__32); 
	                }
	                this.state = 185;
	                this._errHandler.sync(this);
	                _alt = this._interp.adaptivePredict(this._input,18,this._ctx);
	            }

	            break;

	        case 4:
	            this.enterOuterAlt(localctx, 4);
	            this.state = 186;
	            this.match(ExprLangParser.IDENTIFIER);
	            this.state = 193;
	            this._errHandler.sync(this);
	            var _alt = this._interp.adaptivePredict(this._input,19,this._ctx)
	            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	                if(_alt===1) {
	                    this.state = 187;
	                    this.match(ExprLangParser.T__31);
	                    this.state = 188;
	                    this.vmpExpr(0);
	                    this.state = 189;
	                    this.match(ExprLangParser.T__32); 
	                }
	                this.state = 195;
	                this._errHandler.sync(this);
	                _alt = this._interp.adaptivePredict(this._input,19,this._ctx);
	            }

	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


}

ExprLangParser.EOF = antlr4.Token.EOF;
ExprLangParser.T__0 = 1;
ExprLangParser.T__1 = 2;
ExprLangParser.T__2 = 3;
ExprLangParser.T__3 = 4;
ExprLangParser.T__4 = 5;
ExprLangParser.T__5 = 6;
ExprLangParser.T__6 = 7;
ExprLangParser.T__7 = 8;
ExprLangParser.T__8 = 9;
ExprLangParser.T__9 = 10;
ExprLangParser.T__10 = 11;
ExprLangParser.T__11 = 12;
ExprLangParser.T__12 = 13;
ExprLangParser.T__13 = 14;
ExprLangParser.T__14 = 15;
ExprLangParser.T__15 = 16;
ExprLangParser.T__16 = 17;
ExprLangParser.T__17 = 18;
ExprLangParser.T__18 = 19;
ExprLangParser.T__19 = 20;
ExprLangParser.T__20 = 21;
ExprLangParser.T__21 = 22;
ExprLangParser.T__22 = 23;
ExprLangParser.T__23 = 24;
ExprLangParser.T__24 = 25;
ExprLangParser.T__25 = 26;
ExprLangParser.T__26 = 27;
ExprLangParser.T__27 = 28;
ExprLangParser.T__28 = 29;
ExprLangParser.T__29 = 30;
ExprLangParser.T__30 = 31;
ExprLangParser.T__31 = 32;
ExprLangParser.T__32 = 33;
ExprLangParser.T__33 = 34;
ExprLangParser.T__34 = 35;
ExprLangParser.T__35 = 36;
ExprLangParser.T__36 = 37;
ExprLangParser.T__37 = 38;
ExprLangParser.IDENTIFIER = 39;
ExprLangParser.NUMBER = 40;
ExprLangParser.STRING = 41;
ExprLangParser.WS = 42;

ExprLangParser.RULE_program = 0;
ExprLangParser.RULE_statement = 1;
ExprLangParser.RULE_ifElseExpr = 2;
ExprLangParser.RULE_vmpExpr = 3;
ExprLangParser.RULE_binaryOp = 4;
ExprLangParser.RULE_unaryOp = 5;
ExprLangParser.RULE_func = 6;
ExprLangParser.RULE_vmpExprList = 7;
ExprLangParser.RULE_array = 8;
ExprLangParser.RULE_objAccess = 9;

class ProgramContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_program;
    }

	EOF() {
	    return this.getToken(ExprLangParser.EOF, 0);
	};

	statement = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(StatementContext);
	    } else {
	        return this.getTypedRuleContext(StatementContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterProgram(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitProgram(this);
		}
	}


}



class StatementContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_statement;
    }

	ifElseExpr() {
	    return this.getTypedRuleContext(IfElseExprContext,0);
	};

	vmpExpr() {
	    return this.getTypedRuleContext(VmpExprContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterStatement(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitStatement(this);
		}
	}


}



class IfElseExprContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_ifElseExpr;
    }

	vmpExpr = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(VmpExprContext);
	    } else {
	        return this.getTypedRuleContext(VmpExprContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterIfElseExpr(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitIfElseExpr(this);
		}
	}


}



class VmpExprContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_vmpExpr;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class ParenvmpExpressionContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	vmpExpr() {
	    return this.getTypedRuleContext(VmpExprContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterParenvmpExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitParenvmpExpression(this);
		}
	}


}

ExprLangParser.ParenvmpExpressionContext = ParenvmpExpressionContext;

class NumberContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	NUMBER() {
	    return this.getToken(ExprLangParser.NUMBER, 0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterNumber(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitNumber(this);
		}
	}


}

ExprLangParser.NumberContext = NumberContext;

class StringContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	STRING() {
	    return this.getToken(ExprLangParser.STRING, 0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterString(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitString(this);
		}
	}


}

ExprLangParser.StringContext = StringContext;

class FunctionCallContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	func() {
	    return this.getTypedRuleContext(FuncContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterFunctionCall(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitFunctionCall(this);
		}
	}


}

ExprLangParser.FunctionCallContext = FunctionCallContext;

class VariableContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	IDENTIFIER() {
	    return this.getToken(ExprLangParser.IDENTIFIER, 0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterVariable(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitVariable(this);
		}
	}


}

ExprLangParser.VariableContext = VariableContext;

class ObjectAccessContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	objAccess() {
	    return this.getTypedRuleContext(ObjAccessContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterObjectAccess(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitObjectAccess(this);
		}
	}


}

ExprLangParser.ObjectAccessContext = ObjectAccessContext;

class TernaryExpressionContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	vmpExpr = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(VmpExprContext);
	    } else {
	        return this.getTypedRuleContext(VmpExprContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterTernaryExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitTernaryExpression(this);
		}
	}


}

ExprLangParser.TernaryExpressionContext = TernaryExpressionContext;

class UnaryvmpExpressionContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	unaryOp() {
	    return this.getTypedRuleContext(UnaryOpContext,0);
	};

	vmpExpr() {
	    return this.getTypedRuleContext(VmpExprContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterUnaryvmpExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitUnaryvmpExpression(this);
		}
	}


}

ExprLangParser.UnaryvmpExpressionContext = UnaryvmpExpressionContext;

class BinaryvmpExpressionContext extends VmpExprContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	vmpExpr = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(VmpExprContext);
	    } else {
	        return this.getTypedRuleContext(VmpExprContext,i);
	    }
	};

	binaryOp() {
	    return this.getTypedRuleContext(BinaryOpContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterBinaryvmpExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitBinaryvmpExpression(this);
		}
	}


}

ExprLangParser.BinaryvmpExpressionContext = BinaryvmpExpressionContext;

class BinaryOpContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_binaryOp;
    }


	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterBinaryOp(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitBinaryOp(this);
		}
	}


}



class UnaryOpContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_unaryOp;
    }


	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterUnaryOp(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitUnaryOp(this);
		}
	}


}



class FuncContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_func;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class MethodFunctionCallContext extends FuncContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	objAccess() {
	    return this.getTypedRuleContext(ObjAccessContext,0);
	};

	IDENTIFIER() {
	    return this.getToken(ExprLangParser.IDENTIFIER, 0);
	};

	vmpExprList() {
	    return this.getTypedRuleContext(VmpExprListContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterMethodFunctionCall(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitMethodFunctionCall(this);
		}
	}


}

ExprLangParser.MethodFunctionCallContext = MethodFunctionCallContext;

class SimpleFunctionCallContext extends FuncContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	IDENTIFIER() {
	    return this.getToken(ExprLangParser.IDENTIFIER, 0);
	};

	vmpExprList() {
	    return this.getTypedRuleContext(VmpExprListContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterSimpleFunctionCall(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitSimpleFunctionCall(this);
		}
	}


}

ExprLangParser.SimpleFunctionCallContext = SimpleFunctionCallContext;

class VmpExprListContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_vmpExprList;
    }

	vmpExpr = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(VmpExprContext);
	    } else {
	        return this.getTypedRuleContext(VmpExprContext,i);
	    }
	};

	array = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ArrayContext);
	    } else {
	        return this.getTypedRuleContext(ArrayContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterVmpExprList(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitVmpExprList(this);
		}
	}


}



class ArrayContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_array;
    }

	vmpExprList() {
	    return this.getTypedRuleContext(VmpExprListContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterArray(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitArray(this);
		}
	}


}



class ObjAccessContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = ExprLangParser.RULE_objAccess;
    }

	IDENTIFIER = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(ExprLangParser.IDENTIFIER);
	    } else {
	        return this.getToken(ExprLangParser.IDENTIFIER, i);
	    }
	};


	vmpExpr = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(VmpExprContext);
	    } else {
	        return this.getTypedRuleContext(VmpExprContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.enterObjAccess(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof ExprLangListener ) {
	        listener.exitObjAccess(this);
		}
	}


}




ExprLangParser.ProgramContext = ProgramContext; 
ExprLangParser.StatementContext = StatementContext; 
ExprLangParser.IfElseExprContext = IfElseExprContext; 
ExprLangParser.VmpExprContext = VmpExprContext; 
ExprLangParser.BinaryOpContext = BinaryOpContext; 
ExprLangParser.UnaryOpContext = UnaryOpContext; 
ExprLangParser.FuncContext = FuncContext; 
ExprLangParser.VmpExprListContext = VmpExprListContext; 
ExprLangParser.ArrayContext = ArrayContext; 
ExprLangParser.ObjAccessContext = ObjAccessContext; 
