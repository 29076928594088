import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as  XLSX from 'xlsx'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as jszip from '../../../../../../libs/jszip/jszip.min'
import * as FileSaver from '../../../../../../libs/FileSaver/FileSaver'
import * as async from 'async'
import * as slick from '../../../../../../libs/slick/slick'
import * as ko from 'knockout'
import * as kb from 'knockback'
//import {Plan} from '../../../../../version1/bo/transformation/Plan'
import {Role} from '../../../../../version1/bo/transformation/Role'
import {User} from '../../../../../version1/bo/tickets/User'
import {Phase} from '../../../../../version1/bo/transformation/Phase'
import {Alternative} from '../../../../../version1/bo/transformation/Alternative'
//import {PackageReference as PlanPackageReference} from '../../../../../version1/bo/transformation/PackageReference'
import {BeepReport} from '../../../../../version1/bo/report/BeepReport'
import {BeepVocabulary} from '../../../../../version1/bo/concept/BeepVocabulary'
import {BusinessModel} from '../../../../../version1/bo/vdml/BusinessModel'

import {Status} from '../../../../../version1/bo/transformation/Status'
import {PlanType} from '../../../../../version1/bo/transformation/PlanType'
import {RoleType} from '../../../../../version1/bo/transformation/RoleType'
import {PhaseMixin} from '../../../../../version1/bo/transformation/PhaseMixin'
import {PlanMixin} from '../../../../../version1/bo/transformation/PlanMixin'
import {ScenarioProxy} from '../../../../../version1/bo/transformation/ScenarioProxy'
import {Scenario} from '../../../../../version1/bo/vdml/Scenario'
import {UserPreferences} from '../../../../../version1/bo/preference/UserPreferences'
import {SmmModel} from '../../../../../version1/bo/smm/SmmModel'
import {Observation} from '../../../../../version1/bo/smm/Observation'
import {BeepModule} from '../../../../../version1/bo/beeppackage/BeepModule'
import {CodeContainer} from '../../../../../version1/bo/beeppackage/CodeContainer'
import {UserMixin} from '../../../../../version1/bo/tickets/UserMixin'
import {EcoMapDiagramMixin} from '../../../../../version1/bo/vdml/EcoMapDiagramMixin'
import {ValueDeliveryModel} from '../../../../../version1/bo/vdml/ValueDeliveryModel'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import { Phase2Mixin } from '../../../../bo/transformation/Phase2Mixin'

//define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","jszip","FileSaver","spin","async","appcommon/com/vbee/data/DataManager","app/global","appbo/transformation/Plan","appbo/transformation/Role","appbo/tickets/User","appbo/transformation/Phase","appviews/transformation/views/properties/PhaseViewModel","appbo/transformation/Alternative","appbo/transformation/PackageReference","appbo/vdml/ValueDeliveryModel","appbo/report/BeepReport","appbo/concept/BeepVocabulary","appbo/vdml/BusinessModel","appcommon/com/vbee/filesystem/PackageReference","appbo/transformation/Status","appbo/transformation/RoleType","appbo/transformation/PlanType","appbo/transformation/PhaseMixin","appbo/transformation/PlanMixin","appbo/transformation/ScenarioProxy","appbo/vdml/Scenario","appcommon/com/vbee/filesystem/Workspace","appbo/preference/UserPreferences","appcommon/com/vbee/rdf/RDFModel","appbo/smm/SmmModel","appbo/beeppackage/BeepModule", "appbo/smm/Observation", "appbo/beeppackage/CodeContainer","appbo/tickets/UserMixin", "appbo/vdml/EcoMapDiagramMixin", "jsencrypt","domtoimage"],
 //   function (require, $, _, Backbone, ko, koMapping, kb, bootbox, jszip, FileSaver, Spinner, async, DataManager, global, Plan, Role, User, Phase, PhaseViewModel, Alternative, PlanPackageReference, ValueDeliveryModel, BeepReport, BeepVocabulary, BusinessModel, PackageReference, Status, RoleType, PlanType, PhaseMixin, PlanMixin, ScenarioProxy, Scenario, Workspace, UserPreferences, RDFModel, SmmModel, BeepModule, Observation, CodeContainer, UserMixin, EcoMapDiagramMixin) {

        var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties", global.version);
        var importPath;

        export class PlanDetailsViewModel {

        cleanUp() {
            this.phaseArray.length = 0;
            this.userArray.length = 0;
            this.children.length = 0;
            this.phaseCollection.removeAll();
            this.asIsCollection.removeAll();
            this.goalCollection.removeAll();
            this.userCollArray.removeAll();
            delete ko.bindingHandlers.numeric;
        };

        deleteUser() {
            var self = this;
            var index = $("#selectUser option:selected").attr("value");
            self.userArray[index].del = true;
            $("#selectUser option[value=" + index + "]").remove();
            self.showUserDiv(false);
        };

        deletePhase() {
            if ($("#selectPhase option:selected").length > 0) {
                var self = this;
                var index = $("#selectPhase option:selected").attr("value");
                self.phaseCollection()[index].del = true;
                var obj = self.phaseCollection()[index];
                self.checkPhasesLength();
                $("#selectPhase option[value=" + index + "]").remove();
                $("#selectasIs option[value=" + index + "]").remove();
                $("#selectGoal option[value=" + index + "]").remove();
                self.asIsCollection.remove(obj);
                self.goalCollection.remove(obj);
                /*var size = $('#selectPhase > option').length;
                $("#selectPhase").attr("size",""+size);*/
                var id = "" + window.utils.htmlEscape(self.phaseCollection()[index].modalId);
                for (var i = 0; i < self.children.length; i++) {
                    if (id === self.children[i].modalId) {
                        window.cleanDialogModel(self.children[i].modalId, self.children[i].view);
                        self.children.splice(i, 1);
                        break;
                    }
                }
            }
        };

        checkPhasesLength() {
            var self = this;
            var phasecount = 0;
            for (var i = 0; i < self.phaseArray.length; i++) {
                if (self.phaseArray[i].del === false) {
                    phasecount = phasecount + 1;
                }
            }
            if (phasecount > 1) {
                self.editable(true);
            }
            else {
                self.editable(false);
            }
            return phasecount;
        }

        editAdvancedDiv() {
            var self = this;
            var advancedId = 'advanced';
            if ($.find("#modal" + advancedId).length > 0) {
                if (self.advancedView) {
                    window.updateView(self.advancedView.view, self.advancedView.oldData);
                }
                $("#modal" + advancedId).modal('show');
            }
            else {
                //			var options = {parentView:this};
                var options = {};
                window.getAndCreateModalDialog(self, advancedId, PlanMixin, self.model, "AdvancedPlanDetails", function (view) {
                    var oldData = window.getViewData(view);
                    self.advancedView = { view: view, oldData: oldData };
                }, options);
            }
        }

        deletePlanFromUI(updateLicence,callback) {
            var self = this;
            var dataManager = DataManager.getDataManager();
            var selfModel = this.model;
            self.parentOptions && self.parentOptions.parent.plansCollection.remove(self.parentOptions.parent.plansCollection.get(selfModel.id));
            var planNameCollection = self.parentOptions.parent.planNameCollection;
            var modalId = window.utils.htmlEscape(selfModel.id);
            for (var i = 0; i < planNameCollection.length; i++) {
                if (planNameCollection[i].modalId === modalId) {
                    planNameCollection.splice(i, 1);
                    break;
                }
            }
            $("#planWrapper"+modalId).remove();
            if (dataManager.get('lastUsedPlan') === selfModel.id) {
                dataManager.set('lastUsedPlan', null);
            }
            dataManager.get('planPackages')[selfModel.id] = null;
            dataManager.get('artifactsDocuments')[selfModel.id] = null;
            self.saveAndUpdateLicence(updateLicence,false,callback);
        }

        deletePlan() {
            var self = this;
            var view = this;
            var selfModel = this.model;
            var dataManager = DataManager.getDataManager();
            /*var planObjExists = false;
            if (self.model.get('planCriterionSet') && self.model.get('planCriterionSet').get('component').length > 0) {
                planObjExists = true;
            }
            if (!planObjExists) {
                var phases = self.model.get('phase');
                for (var i = 0; i < phases.length; i++) {
                    var alts = phases.at(i).get('phaseAlternative');
                    for (var j = 0; j < alts.length; j++) {
                        if (alts.at(j).get('phaseDesignPart').length > 1) {
                            planObjExists = true;
                            break;
                        }
                    }
                    if (planObjExists) {
                        break;
                    }
                }
            }
            if (!planObjExists) {
                dataManager.getWorkspaceDataWithId(self.model.getCommonRepositoryId(), function (wsData) {
                    wsData.get('rdfModel').getAllDocumentsCollectionOfType('beeppackage_BeepPackage', function (packs) {
                        if (packs.length > 2) {//packagemetric,commonpackage
                            bootbox.confirm(dataManager.get('localeManager').get('deletePlanMsg'), function (result) {
                                if (result) {
                                    doDelete();
                                }
                            });
                        } else {
                            doDelete();
                        }
                    }, false, null, 'vbc:cmof_EObject-name', null, true);
                });
            }
            else if (planObjExists) {*/
                bootbox.confirm(dataManager.get('localeManager').get('deletePlanMsg'), function (result) {
                    if (result) {
                        window.utils.startSpinner('planDelete', 'Deleting Plan...');
                        self.enableComplete(false);
                        function startDeleting() {
                            window.utils.showSpinnerPercentage(20);
                            var planWorkspaceId = dataManager.getWorkspaceIdByPlanId(selfModel.id);
                            var planWorkspaceName = dataManager.getWorkspaceName(planWorkspaceId);
                            dataManager.saveAndSwitchWorkspace(planWorkspaceName, function(){
                                dataManager.set('currentPlan',selfModel);
                                function waitForPlanLoad(){
                                    setTimeout(function(){
                                        if(window.loadedPackages[selfModel.id]){
                                            self.deletePlanObject(selfModel ,function () {
                                                var self = view;
                                                window.utils.showSpinnerPercentage(90);
                                                self.deletePlanFromUI(true,function () {
                                                    window.utils.stopSpinner('planDelete');
                                                    self.cleanModalDialog();
                                                });
                                            });
                                        } else {
                                            waitForPlanLoad();
                                        }
                                    },100);
                                }
                                waitForPlanLoad();
                            },planWorkspaceId);
                        }
                        window.utils.showSpinnerPercentage(10);
                        dataManager.set('isActive', false);
                        dataManager.invokeValueUpdates(startDeleting);
                    }
                });
            //}
        };

        deletePlanObject = function(planModel,callback){
            planModel.deletePlan(function(){
                callback();
            });
        }

        createPhase(view, event) {
            var self = this;
            if(self.goalValue() !== undefined){
                bootbox.alert("Cannot create Phase after Goal Phase");
                return false;
            }
            if ($.find("#modal" + self.phaseArray.length).length > 0) {
                $("#modal" + self.phaseArray.length).modal('show');
            }
            else {
                var options = { "planDetails": "plan", "phaseArray": self.phaseArray };
                window.getAndCreateModalDialog(self.model, self.phaseArray.length, PhaseMixin, null, "PhaseDetails", _.bind(self.addChildModel, self), options);
            }
        };

        cleanModalDialog() {
            var self = this;
            self.clearChildrenModals(self.children);
            var adView = self.advancedView ? self.advancedView.view : null;
            window.cleanDialogModel('advanced', adView);
            window.cleanDialogModel(self.encodeId, self);
            DataManager.getDataManager().set('currentPlan', null);
        };

        /*exportSupportPlan(view, event) {
            var self = this;
            self.exportPlan(view, event, true);
        };*/
        exportPlan(view, event, encode) {
            var self = this;
            event.stopPropagation();
            window.utils.startSpinner('exportPlan', 'Exporting Plan...');
            window.setTimeout(function () {
                var planWorkspaceId = DataManager.getDataManager().getWorkspaceIdByPlanId(self.model.id);
                var planWorkspaceName = DataManager.getDataManager().getWorkspaceName(planWorkspaceId);
                DataManager.getDataManager().saveAndSwitchWorkspace(planWorkspaceName, function(){
                    DataManager.getDataManager().set('currentPlan',self.model,{silent:true});
                //DataManager.getDataManager().set('isActive', false);
                //DataManager.getDataManager().invokeValueUpdates(startExporting);
                //function startExporting() {
                    self.model.createZip(function (content) {
                        window.utils.stopSpinner('exportPlan');
                       /* if (window.utils.checkChrome() && chrome.fileSystem && chrome.fileSystem.chooseEntry) {
                            var suggestion = self.model.get('name').replace(/\"/g, "");
                            suggestion = suggestion.replace(/[`~%^*|\=?;:'",.<>\{\}\[\]\\\/]/gi, '');
                            //suggestion = suggestion.replace('*', '');
                            chrome.fileSystem.chooseEntry({ type: 'saveFile', suggestedName: suggestion, accepts: [{ extensions: ['vpk'] }] }, function (currentEntry) {
                                if (!chrome.runtime.lastError) {
                                    currentEntry.remove(function () {
                                        window.utils.saveCurrentEntry(currentEntry, content, 'Plan');
                                        DataManager.getDataManager().set('currentPlan', null);
                                    }, function (e) {
                                        console.log(e);
                                        bootbox.alert('Export error: ' + e);
                                        DataManager.getDataManager().set('currentPlan', null);
                                    });
                                }
                                else {
                                    console.log(chrome.runtime.lastError);
                                    if (chrome.runtime.lastError.message !== 'User cancelled') {
                                        bootbox.alert('Export error: ' + chrome.runtime.lastError);
                                    }
                                    DataManager.getDataManager().set('currentPlan', null);
                                }
                            });
                        } else {*/
                        var exportMsg = DataManager.getDataManager().get('localeManager').get('exportComplete', 'Plan');
                        window.utils.exportFileToSystem(self.model.get('name'),'VPK File',".vpk",content,exportMsg);
                                  
                           
						   
                            //self.cleanModalDialog();
                            DataManager.getDataManager().set('currentPlan', null);
                        //}
                    });
                //}
                },planWorkspaceId);
            }, 100);
        
            
        }
		
		
		
        importData(view, event) {
            var self = this;
            self.checkingPlanSlots(function(availableSlots){
                if(availableSlots){
                    document.getElementById("file1").click();
                    document.getElementById("file1").value = null;
                }
            });
        }
		importFromCloud(){
        	var self = this;
            self.checkingPlanSlots(function(availableSlots){
                if(availableSlots){
                    var id = window.utils.htmlEscape(window.guidGenerator());
                    var newWorkspaceName = $('#workspace' + self.encodeId).val().trim();
                    var options = {viewId:id, planName:self.name(), planDescription:self.description(),workspaceName : newWorkspaceName,selectedOrg : self.selectedOrg(), width: window.innerWidth-window.innerWidth/5+'px'};
                    window.getAndCreateModalDialog(self.model,window.utils.htmlEscape(id),PlanMixin,self.model,"ImportFromCloud",null,options);      	
                }
            });
        }
        // Extensions(){
        // 	var self = this;
        //     self.checkingPlanSlots(function(availableSlots){
        //         if(availableSlots){
        //             var id = window.utils.htmlEscape(window.guidGenerator());
        //             var newWorkspaceName = $('#workspace' + self.encodeId).val().trim();
        //             var options = {viewId:id, planName:self.name(), planDescription:self.description(), canvas: true, workspaceName : newWorkspaceName,selectedOrg : self.selectedOrg(), width: window.innerWidth-window.innerWidth/5+'px'};
        //             window.getAndCreateModalDialog(self.model,window.utils.htmlEscape(id),PlanMixin,self.model,"ImportFromCloud",null,options);      	
        //         }
        //     });
        // }
        /*importFromOldCloud(){
        	var self = this;
            if(self.checkingPlanSlots()){
                var id = window.utils.htmlEscape(window.guidGenerator());
                var newWorkspaceName = $('#workspace' + self.encodeId).val().trim();
                var options = {viewId:id, planName:self.name(), planDescription:self.description(),workspaceName : newWorkspaceName,selectedOrg : self.selectedOrg(), width: window.innerWidth-window.innerWidth/5+'px'};
                window.getAndCreateModalDialog(self.model,window.utils.htmlEscape(id),PlanMixin,self.model,"ImportFromOldCloud",null,options);      	
            }
        }*/
        
		openFile(view,event){
			var self = this;
			var reader = new FileReader();
			var fileObject = event.target.files[0];
            if(fileObject){
                var type = fileObject.name.substring(fileObject.name.lastIndexOf('.') + 1);
                var PlanMixin = Backbone.Relational.store.getObjectByName('transformation.PlanMixin');
                PlanMixin.importFile(type, reader, fileObject, self.model, (function (valid, type, fileObject, operation,isRgs,fileName) {
                    /*if (type === 'xlsx' && isRgs) {
                        PlanMixin.importRGSLibrary(valid, fileObject, operation, self.model, fileName);
                    } else {*/
                    self.saveWorkspaceChanges(function(workspaceCreated){
                        PlanMixin.checkTypeOfImportFunction(valid, type, fileObject, operation, self.model, self.name(), self.description(),fileName,function(plan){
                            if(type === 'Plan'){
                                self.saveAndUpdateLicence(true,workspaceCreated,function(){
                                    DataManager.getDataManager().getWorkspacesFromBackend(function(){
                                        window.vdmModelView.loadHomePagePlans();
                                        window.utils.stopSpinner('importPlan');
                                    });
                                    //window.utils.stopSpinner('importPlan');
                                    //window.vdmModelView.addPlanToHomePage(plan);
                                });
                            }
                        });
                    },true)
                }));
            }
		};
		
		addChildModel(view,modalId,del){
			var self = this;
        	if(del){
        		self.deletePhase(view,modalId,del);
        	}
        	else{
        		var count = 0;
	            for(var i=0;i<self.children.length;i++){
	                if(self.children[i].modalId === view.encodeId){
	                    count = 1;
	                    self.children[i].view = view;
	                    self.children[i].oldData = window.getViewData(view);
	                    var childModalId = self.children[i].modalId;
	                    for(var j=0;j<self.phaseArray.length;j++){
	                        if(window.utils.htmlEscape(self.phaseArray[j].modalId) == childModalId){
	                            $("#selectPhase option[value="+self.phaseArray[j].seqNo+"]").text(view.name());
	                            break;
	                        }
	                    }
	                    break;
	                }
	            }
				if(count === 0){
					var oldData = window.getViewData(view);
					self.children.push({modalId:modalId,view:view,existing:false,originalId:"",oldData:oldData});
					if(view.name() !== null && view.name() !== ""){
	                    var obj = {seqNo:self.phaseArray.length,originalId:'',name:view.name(),del:false,modalId:modalId};
						var goal, asIs;
						if(self.model){
							goal = self.model.get('goal');
							asIs = self.model.get('asIs');
						}
						if(goal){
							self.phaseCollection.splice(self.phaseCollection().length-1,0,obj);
							if(goal !== asIs){
								self.goalCollection.push(obj);
							}
						}else{
							self.phaseCollection.push(obj);
							self.goalCollection.push(obj);
						}
						self.asIsCollection.push(obj);
	                }
	                self.checkPhasesLength();
	                /*var size = $('#selectPhase > option').length;
	                $("#selectPhase").attr("size",""+size);*/
				}
        	}
         };
		
		saveAsIsAndGoal(finalCallback){
			var self= this;
            if(self.asIs() || self.asIs() == 0){
                var currentAsIsId = self.phaseArray[self.asIs()].originalId;
				var asIsPhase = self.model.get('phase').get(currentAsIsId);
                self.model.set('asIs', asIsPhase);   
            }else{
				self.model.set('asIs',null); 
            }
			var phaseColl = self.model.get('phase');
			var newGoalModal = null;
			if(self.goal()){
				for(var i=0;i<self.phaseCollection().length;i++){
					if(self.phaseCollection()[i].modalId === self.goal()) {
						newGoalModal = phaseColl.findWhere({id:self.phaseCollection()[i].originalId});
						break;
					}
				}
				if(newGoalModal) {
					deleteGoalsNextPhases(newGoalModal, function(){
                        //refreshPage(finalCallback);
                        finalCallback(self.model);
                    });
				}else {
					//refreshPage(finalCallback);
                    finalCallback(self.model);
				}			
			}else{
				self.model.set('goal',null);
				//refreshPage(finalCallback);
                finalCallback(self.model);
			}
					
			
			function deleteGoalsNextPhases(goalModel, callback){
				var nextPhaseModel = goalModel.get('nextPhase');
				if(nextPhaseModel){
					bootbox.confirm(DataManager.getDataManager().get('localeManager').get('GoalChange'), function(result) {
						if(result){
							self.model.set('goal', goalModel);
							deleteNextPhase(nextPhaseModel);
						}else{
							callback && callback();
						}
			        });
				}else{
					self.model.set('goal', goalModel);
					callback && callback();
				}
				function deleteNextPhase(phaseModel){
					var nextPhase = phaseModel.get('nextPhase');
					var phaseEncodeId = window.utils.htmlEscape(phaseModel.get('id')) + "phase";
					phaseModel.deletePhase(navigate);
					function navigate(){
						var phaseSlick  =  $("[encodeid='"+phaseEncodeId+"']");
						var index = phaseSlick.parent().children().index(phaseSlick);
						var encodeId = self.encodeId + "plan";
						var planSlick  =  $("[encodeid='"+encodeId+"']");
						planSlick.slick('slickRemove', index);
						if(nextPhase){
							deleteNextPhase(nextPhase);
						}else{
							callback && callback();
						}
					}
				}
	        }
        };
        
        /*setUserDetails(model1,model2){
            model1.set('name',model2.name);
            model1.set('email',model2.email);
            if(model2.roleId !== '' && model2.roleId !== null){
                model1.set('role',model2.roleId);
            }
            else if(model2.roleId === null){
                var role = new Role({
                    'name' :model2.roleName,
                    'roleType' :model2.roleType,
                    planRoleOwner:model});
                model1.set('role',role);
            }
            else {
                model1.get('role').set('name',model2.roleName);
                model1.get('role').set('roleType',model2.roleType);
            }
        };
        
        saveUsers(){
        	var self= this;
            for(var i=0;i<self.userArray.length;i++){
                if(self.userArray[i].originalId !== ""){
                    if(self.userArray[i].del) {
                        var newModel = self.model.get("user").get(self.userArray[i].originalId);
                        newModel.destroy();
                    }
                    else {
                        newModel = self.model.get("user").get(self.userArray[i].originalId);
                        this.setUserDetails(newModel,self.userArray[i]);
                    }
                }
                else {
                    if(!self.userArray[i].del){
                        var role = new Role({
                            'name' :self.userArray[i].roleName,
                            'type' :self.userArray[i].roleType,
                            planRoleOwner:model});
                            
                        
                        var user = new User({
                            'name':self.userArray[i].name,
                            'email':self.userArray[i].email,
                            'role' :role,
                            userOwner:model});
                     }
                }
            }
        };*/
        
        deletePhases(callback){
        	var self = this;
        	var phaseDeletionList = [];
            for(var i=0;i<self.phaseArray.length;i++){
                if(self.phaseArray[i].originalId !== "" && self.phaseArray[i].del){
                    var phaseModel = self.model.get('phase').get(self.phaseArray[i].originalId);
                    phaseDeletionList.push(phaseModel);
                }   
            }
            async.eachSeries(phaseDeletionList, function(phaseModel, handlerCallBack){
            	phaseModel.deletePhase(handlerCallBack);
            	
            },function(err){	
	            self.model.get('phase').sort(); 
	            phaseDeletionList.length = 0;
	            callback(); 
            });
        };
        saveWorkspaceChanges(wsCallBack,forceSave){
            var self = this;
            var workspaceCreated = false;
            var newWorkspaceName = $('#workspace' + self.encodeId).val().trim();
            var dataManager = DataManager.getDataManager();
            if(self.currentWorkspaceName() == null || self.currentWorkspaceName().trim() == "" || self.currentWorkspaceName() == undefined){// If the WS name is not given
                newWorkspaceName = dataManager.get("email") + "_Workspace";
            }
            var workspaceNameExists = false;
            var currentWorkspaceId;// = dataManager.get("currentWorkspaceId");
            var workspaceData = dataManager.get("workspaceNames");
            for(var key in workspaceData){
                //var leId = dataManager.get("workspaceLegal")[key];
                if(workspaceData[key] == newWorkspaceName /*&& self.selectedOrg() == leId*/){
                    currentWorkspaceId = key;
                    workspaceNameExists = true;
                    break;
                }
            }
            if(!workspaceNameExists){
                currentWorkspaceId = window.uuidGenerator().toUpperCase();
                workspaceCreated = true;
                dataManager.get("workspaceLegal")[currentWorkspaceId] = self.selectedOrg();
            }
            dataManager.saveAndSwitchWorkspace(newWorkspaceName, function(){
                if(workspaceCreated){
                    dataManager.saveWorkspaceNamesData(currentWorkspaceId, newWorkspaceName);
                }
                wsCallBack(workspaceCreated);
            },currentWorkspaceId,forceSave);
        }
		savePlanDetails(model,finalCallback) {
			var self = this;
			DataManager.getDataManager().set('currentPlan',self.model,{silent:true});
            //model.set('name',this.name().trim());
            $('#'+window.utils.htmlEscape(self.model.get('id'))+'planName').text(this.name().trim());
            var desc = this.description().trim();
            if(!desc){
            	desc = this.name().trim();
            }
           // self.model.set('description',desc);
           // model.set('planType', this.planSelected());
           function statusFromSymbols(status){
	        	for(var i=0;i< self.statusOptions.length;i++){
	        		if(self.statusOptions[i].name===status){
	        			return self.statusOptions[i];       			
	        		}
	        	}
	    	}
            self.model.set({'name':this.name().trim(),'description':desc,'status': statusFromSymbols(self.planStatus()),'purpose': this.purpose()});           
            //self.model.set('purpose', this.purpose());
            function saveUsersAndDeletePhases(finalCallback){
            	// this.saveUsers();  not needed until version 3
        		self.deletePhases(function(){
        			if(self.children) {
						for(var j=0;j<self.children.length;j++){
							if(self.children[j].view) {
								var altChildren = self.children[j].view.children;
								self.children[j].view.saveDirectMeasurementValues(altChildren);
							}
						}
					}
					if(self.advancedView) {
        				self.advancedView.view.saveAdvancedView(model,function(){
        					window.cleanDialogModel('advanced',self.advancedView.view);
        					self.saveAsIsAndGoal(finalCallback);
        				});
        			}else {
        				self.saveAsIsAndGoal(finalCallback);
        			}
        		}); 
           		
            }
            
            this.saveChildrenDetails = function(callback){
	        	async.eachSeries(self.phaseArray,function(phase,phaseCallback){
	        		if(phase.del !== true){
	        			var childView = null;
		        		for(var i=0;i<self.children.length;i++){
		                    if((window.utils.htmlEscape(phase.modalId) === self.children[i].modalId)){
		                        if(self.children[i].view){
									childView = self.children[i].view;
		                            break;
		                        }
		                    }
		                }
		                if(childView) {
		                	function savePhaseId(phaseRelModal){
								for(var j=0;j<self.children.length;j++){
									if((window.utils.htmlEscape(phase.modalId) === self.children[j].modalId)){
										phase.originalId = phaseRelModal.id;
		                            	self.children[j].originalId = phaseRelModal.id;
		                            	break;
	                            	}
	                        	}
	                        	phaseCallback();
							}
		                	childView.saveData(self.model,savePhaseId);
		                }
		                else {
		                	phaseCallback();
		                }
	                }
					else {
						phaseCallback();
					}
	        	}, function(err){
	        		var createRef = [];
		            for(var j=0;j<self.phaseArray.length;j++){
		                var prevArrayIndex =j-1;
		                var nextArrayIndex = j+1;
		                while(self.phaseArray[prevArrayIndex] && self.phaseArray[prevArrayIndex].del === true && prevArrayIndex>=0){
		                    prevArrayIndex = prevArrayIndex -1;
		                }
		                while(self.phaseArray[nextArrayIndex] && self.phaseArray[nextArrayIndex].del === true && nextArrayIndex<self.phaseArray.length){
		                    nextArrayIndex = nextArrayIndex + 1;
		                }
		                if(self.phaseArray[j].del === false){
		                    var presModal = self.model.get('phase').get(self.phaseArray[j].originalId);             
		                    if(prevArrayIndex >=0){
		                        var prevPhaseModel = self.model.get('phase').get(self.phaseArray[prevArrayIndex].originalId);
		                        presModal.set('previousPhase',prevPhaseModel);
		                        if(!self.phaseArray[j].existing){
		                        	createRef.push({'presModal':presModal,'prevPhaseModel':prevPhaseModel});
		                        	//presModal.createReferencesForNewPhase(prevPhaseModel);
		                        }
		                    }else{
		                        presModal.set('previousPhase',null);
		                    }   
		                    if(nextArrayIndex <self.phaseArray.length){
		                        var nextPhaseModel = self.model.get('phase').get(self.phaseArray[nextArrayIndex].originalId);
		                        presModal.set('nextPhase',nextPhaseModel);
		                    }else{
		                        presModal.set('nextPhase',null);
		                        //self.model.set('goal',presModal);
		                    }
		                }
		            }
		            async.eachSeries(createRef,function(phaseModels,phaseChildCallback){
		       			var presModal = phaseModels.presModal;
		       			var prevPhaseModel = phaseModels.prevPhaseModel;
		       			presModal.createReferencesForNewPhase(prevPhaseModel,function(){
		       					//presModal.copyPlanScenario(null,measurementsCopied,phaseChildCallback);
		       					phaseChildCallback();
		       				});
	            	}, function(err){
	            		createRef.length = 0;
	        			callback();
	            	});
	            });
	        };
            this.saveChildrenDetails(function(){
                saveUsersAndDeletePhases(finalCallback)
            }); 
		}

        /*unlinkPlan(){
            var self = this;
            window.utils.startSpinner('Unlink','Unlinking Plan...');
            setTimeout(function () {
                var dataManager = DataManager.getDataManager();
                var planWorkspaceId = self.model ? dataManager.getWorkspaceIdByPlanId(self.model.id):dataManager.get('currentWorkspaceId');
                dataManager.unlinkPackage(self.model.id,planWorkspaceId,function(){
                    self.deletePlanFromUI(false,function () {
                        window.utils.stopSpinner('Unlink');
                        self.cleanModalDialog();
                    });
                });
            },100);
        }*/
        checkingPlanSlots(callback){
            var self = this;
            if(self.model){
                callback(true);
                return;
            }
            var legalEntityId = self.selectedOrg();
            var dataManager = DataManager.getDataManager();
            //var planCount = wsId ? dataManager.getPlansByWorkspaceId(wsId).length : 0;         
            if(window.utils.isVMPUser() || legalEntityId == window.vdmbee){
                callback(true);
                return;
            }
            var prodList = [];
			var products = dataManager.get('products');
			if(products && legalEntityId){
				for (var i = 0; i < products.length; i++){
                    if (products[i].legalEntity === legalEntityId) {
                        prodList.push(products[i]);
                    }
				}
			}
            if(!dataManager.hasPlanSlots(prodList)){
                var isEnterprise = dataManager.isEnterpriseEdition(prodList);
			    if (isEnterprise) {
                    var slotUrl = "/vdmbee/workspace/updateSlots/" + legalEntityId;
                    dataManager.get('vmpServerService').postAllPromise(slotUrl, {}).then(function(slotResponse) {
                        if(slotResponse && slotResponse.availableSlots > 0){
                            dataManager.getLicenses({
                                'success': function(response){
                                    dataManager.onLicenseUpdate(response);
                                },
                                'error': function(err){
                                    console.log(err);
                                    dataManager.onLicenseUpdate(response);
                                }
                            });
                            callback(true);
                        } else {
                            var planSlotCount = dataManager.countPlanSlots(prodList);
                            bootbox.alert("All "+planSlotCount+" Plan Slots are used. You need to purchase more plan slots");
                            callback(false);
                        }
                    }).catch(error => {
                        callback(false);
                        console.error('An error occurred:', error);
                    });
                    //return false;
                }else {
                    window.utils.limitExceeded();
                    //return false;
                    callback(false);
                }
			} else {
                callback(true); 
            }
            //return true;
        }	
		
        createPlanCheck() {
            var self = this;
            self.checkingPlanSlots(function(availableSlots){
                if(availableSlots){
                    var numberOfPhase = self.checkPhasesLength();
                    if (!self.model && numberOfPhase === 0) {
                        bootbox.confirm("Would you like to proceed with creating a plan without any phases?", function (result) {
                            if (result) {
                                self.createPlan();
                            }
                        });
                    } else self.createPlan();
                }
            })
        }

		createPlan() {
			var self = this;
			window.utils.startSpinner('planComplete','Saving Plan...');
            self.saveWorkspaceChanges(function(workspaceCreated){
                //setTimeout(function(){
                if(!self.model){
                    if(!self.description()){
                        self.description(self.name());
                    }
                    PlanMixin.createPlanModel(self.name(),self.description().trim(),function(plan){
                        DataManager.getDataManager().savePlanArifactsData(plan.get("id"),DataManager.getDataManager().get("currentWorkspaceId"));
                        //plan.trigger('sync', plan);
                        self.model = plan;
                        plan.loadCommonWorkspace(true,function(){
                            self.savePlanDetails(plan,function(){
                                self.refreshPage();
                                //var dataManager = DataManager.getDataManager();
                                //dataManager.saveChangeSetToBackend(dataManager.get(dataManager.CURRENT_CHANGESET),function(response){
                                    self.saveAndUpdateLicence(true,workspaceCreated,function(){
                                        //window.legalEntitySelectionChange();
                                        window.utils.stopSpinner('planComplete');
                                        window.vdmModelView.addPlanToHomePage(plan);                                        
                                    });
                                //});
                            });
                        });
                    });
                } else {
                    self.savePlanDetails(self.model,function(plan){
                        self.refreshPage();
                        self.saveAndUpdateLicence(false,false,function(){
                            window.utils.stopSpinner('planComplete');
                            window.vdmModelView.addPlanToHomePage(plan,true);
                        });
                    });
                }
            });	
		}

        refreshPage(){
            var self = this;
            function initSlick(){
                var encodeId = self.encodeId + "plan";
                var planSlick  =  $("[encodeid='"+encodeId+"']");
                var isInitialized = planSlick.hasClass('slick-initialized');
                var options = {
                    dots: false,
                    infinite: false,
                    speed: 300,
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    arrows: true,
                    responsive: [
                    {
                        breakpoint: 2048,
                        settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5	
                        }
                    },
                    {
                        breakpoint: 1400,
                        settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4	
                        }
                    },
                    {
                    breakpoint: 1100,
                    settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3	
                        }
                    },
                    {
                        breakpoint: 700,
                        settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 520,
                        settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                        }	
                    }
                ]
                };
                if(!isInitialized){
                    planSlick = $("[encodeid='"+encodeId+"']").slick(options);	
                }else{
                    for(var i=0;i<self.phaseArray.length;i++){
                        if(self.phaseArray[i].originalId !== ""){
                            if(self.phaseArray[i].del) {
                                var encodeId = window.utils.htmlEscape(self.phaseArray[i].originalId) + "phase";
                                var phaseSlick  =  $("[encodeid='"+encodeId+"']");
                                var index = phaseSlick.parent().children().index(phaseSlick);
                                planSlick.slick('slickRemove', index);
                            }
                        }   
                    }
                }
            }
            initSlick();
            //var planWorkspace = self.parentOptions.parent;
               //planWorkspace.initializeSearch(null);
            DataManager.getDataManager().set('currentPlan',null);
            //var plan = self.model;
            if(self.clearChildrenModals){
                self.clearChildrenModals(self.children);
                window.cleanDialogModel(self.encodeId,self);
            }
            //finalCallback(plan);
        }
        

        saveAndUpdateLicence(updateLicence,workspaceCreated,pcallback){
            var dataManager = DataManager.getDataManager();
            dataManager.saveChangeSetToBackend(dataManager.get(dataManager.CURRENT_CHANGESET),function(response){
                if(response && response.status == 500){
                    pcallback();
                } else {
                    dataManager.applyWorkspaceById(dataManager.get("currentWorkspaceId"),function(){
                        if(workspaceCreated){
                            dataManager.getGroupsFromBackend(function(){
                                dataManager.getLicenses({
                                    'success': function(response){
                                        dataManager.onLicenseUpdate(response);
                                        pcallback();
                                    },
                                    'error': function(err){
                                        console.log(err);
                                        dataManager.onLicenseUpdate(response);
                                        pcallback();
                                    }
                                });
                            });
                        } else if(updateLicence){
                            dataManager.getLicenses({
                                'success': function(response){
                                    dataManager.onLicenseUpdate(response);
                                    pcallback();
                                },
                                'error': function(err){
                                    console.log(err);
                                    dataManager.onLicenseUpdate(response);
                                    pcallback();
                                }
                            });
                        } else {
                            pcallback();
                        }
                    });
                    /*var timestamp = dataManager.get("workspaceAppliedChageSetDateData")[dataManager.get("currentWorkspaceId")] ;
                    dataManager.get("workspaceAppliedChageSetDateData")[dataManager.get("currentWorkspaceId")] = timestamp - 1000;//to force apply workspace
                    dataManager.acquireSaveLock(function(){
                        dataManager.pullServerChanges(function(){
                            dataManager.clearSaveInterval();
                            dataManager.releaseSaveLock();
                            if(updateLicence){
                                dataManager.getLicenses({
                                    'success': function(response){
                                        dataManager.onLicenseUpdate(response);
                                        pcallback();
                                    },
                                    'error': function(err){
                                        console.log(err);
                                        pcallback();
                                    }
                                });
                            } else {
                                pcallback();
                            }
                        },true);
                    });*/
                }
            });
        }

        deletePermission(view,event){
            var self = this;
            var pId = event.currentTarget.id;
            bootbox.confirm("Are you sure you want to Unlink the Group/User?", function (result) {
                if (result) {
                    var url = '/vdmbee/permission/workspace/'+pId;
                    var requestData = {};
                    var currentLegalEntity = DataManager.getDataManager().get("currentLegalEntity");
                    if (currentLegalEntity && currentLegalEntity.entityId) {
                        requestData.currentEntityId = currentLegalEntity.entityId;
                    }
                    DataManager.getDataManager().get('vmpServerService').deleteAllPromiseForUriList(url,JSON.stringify(requestData),true).then(function() {
                        DataManager.getDataManager().getGroupsFromBackend(function(){
                            var newWorkspaceName = $('#workspace' + self.encodeId).val().trim();
                            var wId = self.getWorkspaceId(newWorkspaceName);
                            self.updateTeamArray(wId);
                        });
                    });
                }
            });
        }
        infoTeam(view,event){
            var groupId = event.currentTarget.id;
            if(groupId){
                var url = '/vdmbee/group/'+groupId+'/userList';
                DataManager.getDataManager().get('vmpServerService').getAllPromise(url).then(function(userList) {
                    var msg = "<table class='table table-bordered'><thead><tr><th>Name</th><th>E-Mail</th></tr></thead><tbody>";
                    var uList = userList._embedded ? userList._embedded.userResources:[];
                    if(uList && uList.length > 0){
                        uList.map(function(user){
                            msg=msg.concat("<tr><td>"+user.name+"</td><td>"+user.email+"</td></tr>");
                        });
                        msg=msg.concat("</tbody></table><br/>");
                    }
                    bootbox.alert(msg, function() { 
                    });
                });
            }
        }
        /*planInfo(){
            var self = this
            var uList = self.model.get("user");
            var msg = "<table class='table table-bordered'><thead><tr><th>Name</th><th>E-Mail</th></tr></thead><tbody>";
            if(uList && uList.length > 0){
                var user = uList.at(0);
                var dispName = user.get('gapiDisplayName');
                dispName = dispName ? dispName : '';
                msg=msg.concat("<tr><td>"+dispName+"</td><td>"+user.get('email')+"</td></tr>");
                msg=msg.concat("</tbody></table><br/>");
            }
            bootbox.alert(msg, function() { 
            });
        }*/
        getWorkspaceId(wsName){
            var self = this;
            var workspaceNameExists = false;
            var currentWorkspaceId;
            var workspaceData = DataManager.getDataManager().get("workspaceNames");
            for(var key in workspaceData){
                //var leId = DataManager.getDataManager().get("workspaceLegal")[key];
                if(workspaceData[key] == wsName /*&& leId == self.selectedOrg()*/){
                    currentWorkspaceId = key;
                    workspaceNameExists = true;
                    break;
                }
            }
            return currentWorkspaceId;
        }
        /*addUser(view,event){
            var self = this;
            if(!self.selectedUser()){
                bootbox.alert('User Not Selected');
                return;
            }
            var currentWorkspaceId = self.getWorkspaceId();
            if(!currentWorkspaceId){
                bootbox.alert('Workspace Not Selected');
                return;
            }
            
            var url = '/vdmbee/permission/workspace';
            var params = {};
            params.workspaceProxyId = currentWorkspaceId;
            params.roleType = self.selectedRole();
            if(self.selectedUser().get("type") == "GROUP_ACCESS"){
                params.groupId = self.selectedUser().id;
            } else {
                params.userId = self.selectedUser().id;
            }
            DataManager.getDataManager().get('vmpServerService').postAllPromise(url,JSON.stringify(params)).then(function(data) {
                bootbox.alert('Permission Added');
                DataManager.getDataManager().getGroupsFromBackend(function(){
                    self.updateTeamArray(currentWorkspaceId);
                });
                $('#addUserSection' + self.encodeId).hide();
            });
        }*/
        showUserSectionDiv(view,event){
            var self = this;
            var dataManager = DataManager.getDataManager();
            //var currentLegalEntity = dataManager.get("currentLegalEntity");
            var newWorkspaceName = $('#workspace' + self.encodeId).val().trim();	
            var currentWorkspaceId = self.getWorkspaceId(newWorkspaceName);
            if(!currentWorkspaceId){
                bootbox.alert('Workspace Not Selected');
                return;
            }
            dataManager.navigateToCrmPage("workspace/"+currentWorkspaceId+"/permissions")
            // if(!$('#addUserSection' + self.encodeId).is(":visible")){
            //     self.getLeUsers(function(userGroupList){
            //         self.userListOptions = userGroupList;
            //         if($('#userDetails' + self.encodeId).length > 0){
            //             $('#userDetails' + self.encodeId).remove();
            //         }
            //         self.createTypeahead("userDetailsDiv"+self.encodeId,self.userListOptions,'userDetails' + self.encodeId,"");
            //         $('#addUserSection' + self.encodeId).show();
            //     });
            // } else {
            //     $('#addUserSection' + self.encodeId).hide();
            // }
        }
        /*getLeUsers(callback) {
            var self = this;
            DataManager.getDataManager().get('vmpServerService').getAllPromise('/vdmbee/user/usersbylegalentity/' + DataManager.getDataManager().get("currentLegalEntity").entityId).then(function (data) {
                var userGroupList = new Backbone.Collection();
                if (data && data._embedded) {
                    for (var i = 0; i < data._embedded.userResources.length; i++) {
                        if(!data._embedded.userResources[i].expired){
                            var selfLink = data._embedded.userResources[i]._links.self.href;
                            var userId = selfLink.substr(selfLink.lastIndexOf("/") + 1);
                            if(!_.findWhere(self.userTeamCollection(), {'id':userId})){
                                userGroupList.push({ name: data._embedded.userResources[i].name, email: data._embedded.userResources[i].email, id: userId, type:'USER_ACCESS'});
                            }
                        }
                    }
                }
                DataManager.getDataManager().get('vmpServerService').getAllPromise("/vdmbee/group/groupsbylegalentity/" + DataManager.getDataManager().get("currentLegalEntity").entityId).then(function (data) {
                    if (data && data._embedded) {
                        for (var i = 0; i < data._embedded.group.length; i++) {
                            var selfLink = data._embedded.group[i]._links.self.href;
                            var gId = selfLink.substr(selfLink.lastIndexOf("/") + 1);
                            if(!_.findWhere(self.userTeamCollection(), {'id':gId})){
                                userGroupList.push({ name: data._embedded.group[i].name, email: data._embedded.group[i].name, id: gId, type: 'GROUP_ACCESS'});
                            }
                        }
                    }
                    callback(userGroupList);
                })
            })
        }*/
        createTypeahead(id,coll,newId,val){
            var self = this;
            var Extendedname = Backbone.Typeahead.extend({
                template: '<input type="text" id="' +newId + '" placeholder="Name" value="' + val + '" class="form-control" autofocus><ul class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            //$('#workspace' + self.encodeId).focus();
            var workspaceDetailstypeahead = new Extendedname({ collection: coll });
            workspaceDetailstypeahead.setElement('#' + id).render();
            /*if(newId == "userDetails"+self.encodeId){
                workspaceDetailstypeahead.on('selected', function(val) {
                    self.selectedUser(val);
                });
                $("#userDetails" + self.encodeId).keyup(function (view) {
                    var name = view.target.value.trim();
                    var user = self.userListOptions.findWhere({'name':name})
                    if(user) {
                        self.selectedUser(user);
                    }else {
                        self.selectedUser(null);
                    }
                });
            }else {*/
                workspaceDetailstypeahead.on('selected', function(val) {
                    var legalId = DataManager.getDataManager().get("workspaceLegal")[val.id];
                    self.showUserTeamCollection(true);
                    if(legalId && legalId != self.selectedOrg()){
                        self.selectedOrg(legalId);
                        $("div.selectOrg select").val(legalId);
                    }
                    self.currentWorkspaceName(val.get('name'));
                    window.utils.getOrFetchWsGroup(val.id,function(groupList){
                        self.updateTeamArray(val.id);
                    });
                    
                    //$('#message' + self.encodeId).text('');
                });
                $("#workspace" + self.encodeId).keyup(function (view) {
                    self.showUserTeamCollection(false);
                    var name = view.target.value;
                    self.currentWorkspaceName(name);
                });
           // }
        }
		
		afterRenderView(node,view){
			var self = view;
        	//$('#editDetailsModalContent'+self.encodeId).css('width',"650px");
        	
           /* var Extended = Backbone.Typeahead.extend({//until version 3
              template: '<input type="text" id="roleName" data-bind="value: roleName" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            var retColl = new Backbone.Collection();
                for(var i=0;i<self.model && self.model.get("user").length;i++){
                    retColl.push(self.model.get("user").models[i].get("role"));
                }
            var pstypeahead = new Extended({collection:retColl});
            pstypeahead.setElement('#roleNameDiv').render();
            pstypeahead.on('selected', function(fetchmodel) {
                var index = $("#selectUser option:selected").attr("value");
                self.userArray[index].roleType = fetchmodel.get("type");
                self.userArray[index].roleName = fetchmodel.get("name");
                self.userArray[index].roleId = fetchmodel;
                $("#roleType").val(fetchmodel.get("roleType"));
            });
            $("#roleName").change(function (view) {
                var newValue = view.currentTarget.value;
                if(newValue !== '') {
                    var index = $("#selectUser option:selected").attr("value");
                    self.userArray[index].roleId = null;
                    self.userArray[index].roleName = newValue;
                }
            });
            $("#roleType").change(function (view) {
                var newValue = view.currentTarget.value;
                if(newValue !== '') {
                    var index = $("#selectUser option:selected").attr("value");
                    self.userArray[index].roleType = newValue;
                }
            });*/
            //$("#selectPhase").attr("size",""+self.phaseCollection().length);
            /*if(self.id()){
                var currentWorkspaceId = DataManager.getDataManager().setAndGetCurrentWorkspaceIdByPlanId(self.id());
                self.currentWorkspaceName(DataManager.getDataManager().get("currentWorkspaceName"));
            }*/
            
            self.createTypeahead("WorkspaceDetailsDiv"+self.encodeId,self.workspaceTypeaheadColl,'workspace' + self.encodeId,self.currentWorkspaceName());
            if(self.id()){
                $('#workspace' + self.encodeId).prop('disabled', true);
            }else{
                $('#workspace' + self.encodeId).prop('disabled', false);
            }
            function getUniqueValue(name){
				if(self.model){
					if(self.model.get('name') === name){
						$('#duplicateName'+self.encodeId).text("");
						return false;
					}
				}
				var plans = self.parentOptions.parent?self.parentOptions.parent.planNameCollection:null;
	    		var unique = window.isUniqueName(name,plans,self.id(),null);
	    		
	    		if(unique){
	    			$('#duplicateName'+self.encodeId).text("");
	    			return false;
	    		}
	    		else{
	    			$('#duplicateName'+self.encodeId).text(DataManager.getDataManager().get('localeManager').get('PlanDuplicate'));
	    			return true;
	    		}
	        }
            function isPlanNameValid(name){
                const startsWithAlphabet = /^[A-Za-z]/.test(name);
                if(!startsWithAlphabet){
                    $('#duplicateName'+self.encodeId).text(DataManager.getDataManager().get('localeManager').get('NameWarning'));
	    			return false;
                }
                // const isJuliaKeyWord = juliaKeywords.some(str => str === name);
                // if(isJuliaKeyWord){
                //     $('#duplicateName'+self.encodeId).text(DataManager.getDataManager().get('localeManager').get('InvalidName'));
	    		// 	return false;
                // }
                $('#duplicateName'+self.encodeId).text("");
                return true;
            }
            $("#planNameModal").keyup(function (view) {
             	var newValue = view.currentTarget.value.trim();
             	var unique = getUniqueValue(newValue);
                if(!unique && newValue.trim() !== '' && $('#duplicateName'+self.encodeId).html().length == 0 && isPlanNameValid(newValue)) {
                    self.enableComplete(true);
                }
                else {
                	self.enableComplete(false);
                }
		    });
			if(self.model){
			    var asIs = self.model.get('asIs');
				if(asIs){
					for(var i=0;i<self.phaseCollection().length;i++){
						if(self.phaseCollection()[i].originalId === asIs.id){
							var phaseEle = document.getElementById("selectPhase").children[i];
							phaseEle && (phaseEle.setAttribute("selected", "selected"));
						}
					}
						
					var goalArray = [];
					for(var i=0;i<self.phaseCollection().length;i++){
						if(self.phaseCollection()[i].modalId ===  window.utils.htmlEscape(asIs.get('id'))) {
							goalArray.push(self.phaseCollection()[i]);
							break;
						}else{
							goalArray.push(self.phaseCollection()[i]);
						}
					}
					self.goalCollection(goalArray);
				}else{
					self.goalCollection(self.phaseCollection().slice());
				}
					
				var goal = self.model.get('goal');
				if(goal){
					for(var i=0;i<self.phaseCollection().length;i++){
						if(self.phaseCollection()[i].originalId === goal.get('id')){
							self.goalValue(i);
							break;
						}
					}
				}
				self.asIsCollection(self.phaseCollection().slice());
			}else{
				self.goalCollection(self.phaseCollection().slice());
			}
			window.utils.applyFontSizeInDialog();
        };
    updateTeamArray(planWorkspaceId){
        var self = this;
        var userTeamArray = [];
        var dataManager = DataManager.getDataManager();
        if(planWorkspaceId){
            var groupList = dataManager.get('workspaceGroups')[planWorkspaceId];
            if(groupList && groupList.length > 0){
                var currentLegalEntityId = self.selectedOrg();
                groupList.map(function(workspaceObj){
                    var showDel = true;
                    var showInfo = true;
                    if(!workspaceObj.GroupId || dataManager.get('readMode')){
                        showInfo = false;
                    }
                    if(workspaceObj.RoleType == "LEGAL_ENTITY_ADMIN"){
                        showDel = false;
                    }
                    var name = workspaceObj.UserName;
                    name = name ? name : workspaceObj.GroupName;
                    var id = workspaceObj.UserId;
                    id = id ? id : workspaceObj.GroupId;
                    var leId = workspaceObj.LegalEntityId;
                    if(leId == window.university || leId == window.enterprise || leId == window.community){
                        if(window.utils.isVMPUser()){
                            userTeamArray.push({id:id,showDelete:showDel,showInfo:showInfo,pid:workspaceObj.PermissionId,name:name,role:' ('+workspaceObj.RoleType+")"})
                        }
                    } else {
                        if(leId != currentLegalEntityId){ //partner cst group
                            showInfo = false;
                            showDel = false;
                        }
                        userTeamArray.push({id:id,showDelete:showDel,showInfo:showInfo,pid:workspaceObj.PermissionId,name:name,role:' ('+workspaceObj.RoleType+")"})
                    }
                });
            }
            
        } else {
            self.showUserTeamCollection(false);
        }
        self.userTeamCollection(userTeamArray);
    }
    getPlanVersions(previousDocumentVersion, callback){
        var self = this;
        var dataManager = DataManager.getDataManager();
        var planId = self.model.id;
        var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
        var legalEntityId = dataManager.get("workspaceLegal")[currentWorkspaceId];
        var productId = dataManager.getPurchasedProductId(planId);
        var url = '/vdmbee/marketplaceinfo/product/' + productId;			
        dataManager.get('vmpServerService').getAllPromise(url).then(function(data) {
            self.packageVersionsList([]);
            if(data) {
                self.packageVersionsList.push({id: data.get("id"), docId : data.get("documentId"), docVersion: data.get("version")});
                if(self.model.get("documentVersion") != data.get("version")){
                    self.showUpgrade(true);
                }
            }
            callback(data);
        });
    }
    upgradePlan(){
        var self = this;
        var dataManager = DataManager.getDataManager();
        var planId = self.model.id;
        var previousDocumentVersion = self.model.get("previousDocumentVersion");
        var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
        //var planDoc = dataManager.get("artifactsDocuments")[planId];
        //var artId = planDoc ? planDoc.artifactId : planId;
        self.getPlanVersions(previousDocumentVersion, function(){
            var htmlContent = "<div class=\"row\">";
            bootbox.confirm(DataManager.getDataManager().get('localeManager').get('UpgradePlanMsg'), function (result) {
                if(result) {
                    var docId = self.packageVersionsList()[0].documentId;
                    var docVersion = self.packageVersionsList()[0].version;
                    window.utils.startSpinner('upgradePlanVersionSpinner', "Upgrading Plan vesion...");
                    setTimeout(function() {
                        var url = '/vdmbee/artifact?docId=' + docId + '&docVer=' + docVersion + '&workspaceId=' + currentWorkspaceId;
                        DataManager.getDataManager().get('vmpServerService').getAllPromise(url).then(function(res) {
                            if (res && res.packageId) {
                                var packArtId = res.packageId;
                                var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
                                var legalEntityId = dataManager.get("workspaceLegal")[currentWorkspaceId];
                                var packArtDoc = dataManager.get("artifactsDocuments")[planId];
                                var existingPackArtId = packArtDoc ? packArtDoc.artifactId : planId;
                                var url = '/vdmbee/workspace/copymarketplacepackage/' + currentWorkspaceId + '/' + packArtId + '/' + legalEntityId;
                                dataManager.get('vmpServerService').postAllPromise(url).then(function(data) {
                                    dataManager.applyWorkspaceById(currentWorkspaceId, function() {
                                        window.utils.stopSpinner("upgradePlanVersionSpinner");
                                        self.saveAdvancedDetails();
                                        jQuery('.btn-editPlanClose').click();
                                        dataManager.applyWorkspaceById(currentWorkspaceId, function() {
                                            window.vdmModelView.loadHomePagePlans(false, function() {
                                                    bootbox.alert('Package has been installed.');
                                                    window.utils.stopSpinner('upgradePlanVersionSpinner');
                                            });
                                        });
                                    });
                                });
                            } else {
                                bootbox.alert("There are no such package");
                            }
                        });
                    }, 10);
                }
            });
        });
    }
    init(model,options,detailedView){
        var self = this;
        this.PlanDetailsViewModel = this;
        this.parentOptions = options;
        options = null;
        this.model = model;
        model = null;
        this.editable = ko.observable(false);
        this.id = kb.observable(self.model, 'id');
        this.advancedView = null;
        this.encodeId = "" + self.parentOptions.modalId;
        this.name = ko.observable(self.model ? self.model.get('name') : "");
        this.editable = ko.observable(false);
        this.description = ko.observable(self.model ? self.model.get('description') : "");
        // this.planOptions = PlanType.symbols();
        this.purpose = ko.observable(self.model ? self.model.get('purpose') : "");
        //this.planSelected = ko.observable(self.model.get('planType'));
        this.showUserDiv = ko.observable(false);
        //this.roleOptions = RoleType.symbols();
        this.role = ko.observable('Owner');
        this.userName = ko.observable();
        this.email = ko.observable();
        //this.roleName = ko.observable('aaa');
        this.phaseArray = [];
        this.userArray = [];
        this.children = [];
		this.goalValue = ko.observable();
		this.goal = ko.observable(self.model && self.model.get('goal') ? self.model.get('goal').get('name'): "");
        this.enableComplete = ko.observable(self.model ? true : false);
        this.userTeamCollection = ko.observableArray([]);
        this.showUserTeamCollection = ko.observable(true);
        //this.showOwner = ko.observable(false);
        /*var isLinked = window.utils.checkIsLinked(self.model,true);
        this.showUnlink = ko.observable(isLinked);*/
        this.showUpgrade = ko.observable(false);
        var dataManager = DataManager.getDataManager();
        this.packageVersionsList = ko.observableArray([]);
        var planWorkspaceId = self.model ? dataManager.getWorkspaceIdByPlanId(self.model.id):dataManager.get('currentWorkspaceId');
        
        
        function getWorkspaceName(planWorkspaceId){
            var planWorkspaceName = dataManager.getWorkspaceName(planWorkspaceId);
            if(!planWorkspaceName){
                planWorkspaceName = dataManager.get("email") + "_Workspace";
            }
            return planWorkspaceName;
        }
        var planWorkspaceName = getWorkspaceName(planWorkspaceId);
        
        this.currentWorkspaceName = ko.observable(planWorkspaceName);
        
        this.selectedOrg = ko.observable('');
        //this.selectedRole = ko.observable();
        //this.selectedUser = ko.observable();
        this.orgArray = [];
        var legalEntityDetails = dataManager.get("legalEntityDetails");
        if(legalEntityDetails){
            var legalEntityIds = Object.getOwnPropertyNames(legalEntityDetails);
            var legalId = planWorkspaceId ? dataManager.get("workspaceLegal")[planWorkspaceId] : null;
            var currentLegalEntityId = legalId ? legalId : dataManager.get("currentLegalEntity").entityId;
            if(self.model){
                $('#orgSelect'+self.encodeId).prop('disabled', 'disabled');
                /*if(self.model.get("user").at(0) && dataManager.get("email") !== self.model.get("user").at(0).get('email')){
                    self.showOwner(true);
                }*/
                for(var i=0;i<legalEntityIds.length;i++){
                    if (currentLegalEntityId == legalEntityIds[i]) {
                        this.orgArray.push({'id':legalEntityDetails[legalEntityIds[i]].entityId,'name':legalEntityDetails[legalEntityIds[i]].name });
                        this.selectedOrg(legalEntityDetails[legalEntityIds[i]].entityId);
                    }
                }
            }
            else {
                var currentEnt = dataManager.get("currentLegalEntity")
                if(currentEnt && dataManager.get('readMode')){//to switch from current workspace
                    var leId = currentEnt.entityId;
                    var myWorkspaceId;
                    var wkLegal = dataManager.get("workspaceLegal");
                    for(var i=0;i<legalEntityIds.length;i++){
                        if(legalEntityDetails[legalEntityIds[i]].roles.length != 0){
                            leId = legalEntityDetails[legalEntityIds[i]].entityId;
                            for(var key in wkLegal){
                                if(wkLegal[key] == leId){
                                    myWorkspaceId = key;
                                    break;
                                }
                            }
                            if(myWorkspaceId){
                                break;
                            }
                        }
                    }
                    if(!myWorkspaceId){
                        currentLegalEntityId = leId;
                        planWorkspaceId = myWorkspaceId;
                        planWorkspaceName = getWorkspaceName(myWorkspaceId);
                        dataManager.set("currentWorkspaceName", planWorkspaceName);
                        self.currentWorkspaceName(planWorkspaceName);
                        dataManager.set("currentWorkspaceId" , myWorkspaceId);
                        dataManager.setAccessRights(leId);
                    } else {
                        window.utils.getOrFetchWsGroup(myWorkspaceId,function(){
                            currentLegalEntityId = leId;
                            planWorkspaceId = myWorkspaceId;
                            planWorkspaceName = getWorkspaceName(myWorkspaceId);
                            dataManager.set("currentWorkspaceName", planWorkspaceName);
                            self.currentWorkspaceName(planWorkspaceName);
                            dataManager.set("currentWorkspaceId" , myWorkspaceId);
                            dataManager.setAccessRights(leId);
                        });
                    }
                }
                for(var i=0;i<legalEntityIds.length;i++){
                    if(legalEntityDetails[legalEntityIds[i]].roles.length != 0){
                        var leId = legalEntityDetails[legalEntityIds[i]].entityId;
                        this.orgArray.push({'id':leId,'name':legalEntityDetails[legalEntityIds[i]].name });
                        if (currentLegalEntityId == legalEntityIds[i]) {
                            this.selectedOrg(leId);
                        }
                    }
                }
            }
        }
        if(!dataManager.get('readMode')){
            if(!planWorkspaceId) {
                planWorkspaceId = self.getWorkspaceId(self.currentWorkspaceName());
            }
            window.utils.getOrFetchWsGroup(planWorkspaceId,function(groupList){
                self.updateTeamArray(planWorkspaceId);
            });
        }

        this.orgCollection = ko.observableArray(this.orgArray);
        //this.roleCollection = ko.observableArray([{'id':'CONTRIBUTOR','name':'CONTRIBUTOR'},{'id':'REVIEWER','name':'REVIEWER'},{'id':'LEGAL_ENTITY_ADMIN','name':'LEGAL_ENTITY_ADMIN'}]);
        this.deletePermission = _.bind(this.deletePermission,this);

        this.selectedOrg.subscribe(function(value){
            var dataManager = DataManager.getDataManager();
            var legList = dataManager.get("workspaceLegal");
            $('#workspace' + self.encodeId).val("");
            self.showUserTeamCollection(false);
            var foundWsId = null;
            for(var i in legList){
                if(legList[i] == value){
                    $('#workspace' + self.encodeId).val(dataManager.get("workspaceNames")[i]);
                    self.currentWorkspaceName(dataManager.get("workspaceNames")[i])
                    foundWsId = i;
                    break;
                }
            }
            if(!foundWsId){
                var workspaceData = dataManager.get("workspaceNames");
                var newWorkspaceName = dataManager.get("email") + "_Workspace";
                for(var key in workspaceData){
                    if(workspaceData[key] == newWorkspaceName){
                        var le = dataManager.get("legalEntityDetails")[value];
                        var lename = le?le.name:"1";
                        newWorkspaceName = newWorkspaceName.concat("_"+lename);
                        break;
                    }
                }
                $('#workspace' + self.encodeId).val(newWorkspaceName);
                self.currentWorkspaceName(newWorkspaceName)
            } else {
                window.utils.getOrFetchWsGroup(foundWsId,function(groupList){
                    self.updateTeamArray(foundWsId);
                    self.showUserTeamCollection(true);
                });
            }
        });
		this.goalValue.subscribe(function(value){
			var selectedPhaseObj;
			if(typeof value != "undefined"){
				selectedPhaseObj = self.phaseCollection()[value];
				self.goal(selectedPhaseObj.modalId);
			}else{
				self.goal("");
			}
			if(self.goal()){
				var asIsArray = [];
				for(var i=0;i<self.phaseCollection().length;i++){
					if(self.phaseCollection()[i].modalId ===  selectedPhaseObj.modalId) {
						asIsArray.push(self.phaseCollection()[i]);
						break;
					}else{
						asIsArray.push(self.phaseCollection()[i]);
					}
				}
				self.asIsCollection(asIsArray);
			}else{
				self.asIsCollection(self.phaseCollection());
			}
		});
		      
        $('#modal'+self.encodeId).on('shown.bs.modal', function () {
                window.utils.focusElement("#newModal .focus-ele");
                self.workspaceTypeaheadColl.reset();
                fillWorkspaceTypeaheadColl();
        });
        function fillWorkspaceTypeaheadColl(){
            //var currentWorkspaceId = DataManager.getDataManager().setAndGetCurrentWorkspaceIdByPlanId(self.id());
            var workspacesData = DataManager.getDataManager().get("workspaceNames");
           /* for(var key in workspacesData){
                self.workspaceTypeaheadColl.push({id:key, name: workspacesData[key]});
            }*/
            var legalEntityDetails = DataManager.getDataManager().get("legalEntityDetails");
            if(legalEntityDetails){
                var legalEntityIds = Object.getOwnPropertyNames(legalEntityDetails);
                var wkLegal = dataManager.get("workspaceLegal");
                for(var i=0;i<legalEntityIds.length;i++){
                    if(legalEntityDetails[legalEntityIds[i]].roles.length != 0){
                        var leId = legalEntityDetails[legalEntityIds[i]].entityId;
                        for(var key in wkLegal){
                            if(wkLegal[key] == leId){
                                self.workspaceTypeaheadColl.push({id:key, name: workspacesData[key]});
                            }
                        }
                    }
                }
            }
        }
        this.name.subscribe(function(val){
        	self.description(val);
        });
        
        this.clearChildrenModals = function(childrens){
            for(var i=0;i<childrens.length;i++){
                if(childrens[i].view && childrens[i].view.children && childrens[i].view.children.length > 0)
                    {
                        self.clearChildrenModals(childrens[i].view.children);
                    }
                window.cleanDialogModel(childrens[i].modalId,childrens[i].view);
            }
        }
        
        if(self.model){
        	self.phaseArray.splice(0,self.phaseArray.length);
            var phases = self.model.get('phase');
            phases.sort();
            for(var i=0;i<phases.length;i++){
                var obj = {seqNo:i,originalId:phases.at(i).get('id'),name:phases.at(i).get('name'),del:false,modalId:phases.at(i).get('id'),existing:true};
                self.phaseArray.push(obj);
                self.children.push({modalId:window.utils.htmlEscape(phases.at(i).get('id')),view:null,existing:true,originalId:phases.at(i).get('id')});
                if(self.model.get('asIs') && obj.originalId === self.model.get('asIs').get('id')){
                    self.asIs = ko.observable(obj.seqNo);       
                }
            }
        }
        
        this.workspaceTypeaheadColl = new Backbone.Collection();
        //this.userListOptions = new Backbone.Collection();
        this.phaseCollection = ko.observableArray(this.phaseArray);
		this.asIsCollection = ko.observableArray(this.phaseArray.slice());
		this.goalCollection = ko.observableArray(this.phaseArray.slice());
        self.checkPhasesLength();
        
        
        /*function addUsersToArray(){ not needed until version 3
            for(var i=0;i<self.model.get('user').length;i++){
                var usermodel = self.model.get('user').models[i];
                self.userArray.push({seqNo:i,originalId:usermodel.get("id"),name:usermodel.get("name"),email:usermodel.get("email"),roleName:usermodel.get("role").get("name"),roleType:usermodel.get("role").get("roleType"),roleId:'',del:false});
            }
        }
        
        model && addUsersToArray();*/
        this.userCollArray = ko.observableArray(this.userArray);
        if(!this.asIs){
            this.asIs = ko.observable();
        }
        this.asIs.subscribe(function(value){
			var selectedPhaseObj;
			if(typeof value != "undefined"){
				selectedPhaseObj = self.phaseCollection()[value];
				self.asIs(value);
			}else{
				self.asIs(null);
			}
			if(self.asIs()){
				var goalArray = [];
				for(var i=self.phaseCollection().length-1;i>=0;i--){
					if(self.phaseCollection()[i].modalId ===  selectedPhaseObj.modalId) {
						goalArray.push(self.phaseCollection()[i]);
						break;
					}else{
						goalArray.push(self.phaseCollection()[i]);
					}
				}
				self.goalCollection(goalArray);
			}else{
				self.goalCollection(self.phaseCollection());
			}
		});
        this.userName.subscribe(function(newValue){
                var index = $("#selectUser option:selected").attr("value");
                self.userArray[index].name = newValue;
                $("#selectUser option:selected").text(newValue);                
        });
        
        this.email.subscribe(function(newValue){
                var index = $("#selectUser option:selected").attr("value");
                self.userArray[index].email = newValue;
        });
        
        /*this.roleName.subscribe(function(newValue){
            if(newValue !== '') {
                    var index = $("#selectUser option:selected").attr("value");
                    self.userArray[index].roleName = newValue;
                }
        });*/
        
        this.role.subscribe(function(newValue){
                var index = $("#selectUser option:selected").attr("value");
                self.userArray[index].roleType = newValue;
        });
                                
        this.phaseSelected = ko.observable();

        this.statusOptions = Status.symbols();
        this.planStat = self.model?self.model.get('status'):'';
        this.planStatus = ko.observable(self.planStat ? self.planStat.name : "");
        this.editPhaseButtonClick = function(){
        	var self = this;
            if($("#selectPhase option:selected").length > 0){
                 var index = $("#selectPhase option:selected").attr("value");
                 var id;
                 if(self.phaseCollection()[index].originalId !== ""){
                     id = self.phaseCollection()[index].originalId; 
                     if($.find("#modal"+window.utils.htmlEscape(id)).length > 0){
                     	for(var i=0;i<self.children.length;i++){
		    				if(window.utils.htmlEscape(id) === self.children[i].modalId){
			     				window.updateView(self.children[i].view,self.children[i].oldData);
			    				break;
		    				}
			    		}
                        $("#modal"+window.utils.htmlEscape(id)).modal('show');
                     }
                     else {
                     	var options = {"planDetails" : "plan"};
                     	var phase = self.model.get('phase').findWhere({id:id});
                        const mixinClass = dataManager.getModelTypeByTypeStr(phase.get('type'),phase.getNestedParent().get('version'),true );
                        window.getAndCreateModalDialog(self.model,window.utils.htmlEscape(id),mixinClass ,phase,"PhaseDetails",_.bind(self.addChildModel,self),options);
                    }
                 }
                 else{
                    id = self.phaseCollection()[index].modalId;  
                    for(var i=0;i<self.children.length;i++){
	    				if(id === self.children[i].modalId){
		     				window.updateView(self.children[i].view,self.children[i].oldData);
		    				break;
	    				}
		    		}
                    $("#modal"+id).modal('show');
                 } 
            }
        };
        //this.canExport = ko.observable(DataManager.getDataManager().get('enterpriseEdition'));
        
        this.canExport = ko.observable(true);
        if(self.model){
           this.canExport(dataManager.canExport(self.model));
        }          
        //collapase edit form   
        /*this.editUser = function(){
            var selectedId = $( "#selectUser option:selected" ).attr("value");
            if(selectedId){
                self.showUserDiv(true); 
                $("#userName").val(self.userCollArray()[selectedId].name);
                $("#email").val(self.userCollArray()[selectedId].email);
                $("#roleName").val(self.userCollArray()[selectedId].roleName);
                $("#roleType").val(self.userCollArray()[selectedId].roleType);
            }
        };
        
        this.hideUserDiv = function(){
            self.showUserDiv(false);
        };      
        
        this.createNewUser = function(){
            self.userCollArray.push({seqNo:self.userCollArray().length,originalId:'',name:'user',email:'',roleName:'Plan Implementor',roleType:'Implementor',roleId:'',del:false});
        };*/		
		
		//import button

	      ko.bindingHandlers.numeric = {
	        init: function (element, valueAccessor) {
	            $(element).on("keydown", function (event) {
	                window.utils.checkNumericValue(event,element);
	            });
	        }
	    };   
		
	
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['Plan'
        ,'name'
        ,'description'
        ,'userName'
        ,'users'
        ,'email'
        ,'roleName'
        ,'roleType'
        ,'Phases'
        ,'planType'
        ,'planStatus'
        ,'Initiated'
        ,'InProgress'
        ,'Closed'   
        ,'Close'
        ,'Complete'
        ,'editPlan'
        ,'asIs'
        ,'userDetails'
        ,'export'
		,'goal'
		,'import'
		,'CreateNewPlan'
		,'SelectAsIs'
		,'SelectGoal'
		,'Delete'
		,'Advanced',
		'importModuleCompleted'
		,'Purpose'
		,'importFromCloud'
        // ,'Extensions'
		,'Workspace'
        ,'Upgrade'
        ]);
         
        /* for(var i=0;i<this.planOptions.length;i++){
            this.planOptions[i].description = this.labels[this.planOptions[i].name]();
        }*/
        for(var i=0;i<this.statusOptions.length;i++){
            this.statusOptions[i].description = this.labels[this.statusOptions[i].name]();
        }
        /*for(var i=0;i<this.roleOptions.length;i++){
            this.roleOptions[i].description = this.labels[this.roleOptions[i].name]();
        }*/ 
        this.title = self.model ? this.labels["editPlan"]() : this.labels["CreateNewPlan"]();
    }
        
    static getInstance(model,options){
        var view = new PlanDetailsViewModel(model,options);
		view.init(model, options);
		return view;
    };
}
 path.PlanDetailsViewModel = PlanDetailsViewModel;