import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as  bootbox  from '../../../../../../libs/bootbox/bootbox'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import moment from 'moment';
import { Scenario } from '../../../../bo/vdml/Scenario'
import { DashboardMixin } from '../../../../bo/dashboard/DashboardMixin'
import { AlternativeMixin } from '../../../../bo/transformation/AlternativeMixin'
import { Plan } from '../../../../bo/transformation/Plan'
import { PlanMixin } from '../../../../bo/transformation/PlanMixin'
import { Analytics } from '../../../../bo/dashboard/Analytics'


/*define(["require","jquery","async","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global","typeahead","appbo/transformation/AlternativeMixin", "appbo/dashboard/DashboardMixin","appbo/transformation/Plan","appbo/vdml/Scenario","appbo/smm/Observation","appbo/transformation/PlanMixin","moment"],
function(require,$,async,_, Backbone,ko, koMapping,kb,bootbox,DataManager,global,typeahead,AlternativeMixin,DashboardMixin,Plan,Scenario,Observation,PlanMixin,moment
){*/
var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart",global.version);

export class DashboardValuesViewModel {
	

	htmlEscape(str) {
		return String(str)
		.replace(/@/g, '')
		.replace(/ /g, '')
		.replace(/#/g, '');
	}
	
	cleanUp(){
		var self = this;
	};
	dispose(){
		var self = this;
		window.cleanViewModel(self);		
	};

	init(model, options){
		var self = this;
		this.options = options;
		this.DashboardValuesViewModel = this;
		this.model = model.model;
		
	}
	
	static getInstance(model,options){
		var view = new DashboardValuesViewModel(model, options);
		view.init(model, options);
		return view;
	
	};		
}
path.DashboardValuesViewModel = DashboardValuesViewModel;