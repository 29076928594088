import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { Performer } from './Performer'
import { ProcessRoleMixin } from './ProcessRoleMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Performer","appbo/vdml/ProcessRoleMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Performer,ProcessRoleMixin){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var ProcessRole = Performer.extend(utils.customExtends({
		relations:ProcessRoleMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Performer.prototype.initialize.apply(this, arguments);
		}
		}
		, new ProcessRoleMixin()
	));
	
	ProcessRole.abstract = false;
	ProcessRole.supported = false;
	path.ProcessRole = ProcessRole;
    export { ProcessRole };
//});