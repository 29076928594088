import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {ValueElement} from '../vdml/ValueElement'
import {ValueElementMixin} from '../vdml/ValueElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("instdef",global.version);

export class ValueInstanceMixin {

    defaults(){
        var ret = {
            type: "instdef_ValueInstance"
        }
        return jQuery.extend(ValueElement.prototype.defaults.apply(this),ret);
    }
    static getMixinRelations(){
        return _.union([
            {
                type: Backbone.HasMany,
                containingClass: "instdef_MeasurableElementInstance",
                key: "measurableElementInstance",
                relatedModel: "instdef.MeasurableElementInstance",
                reverseRelation: {
                    key: "measurableElementInstanceOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            }
        ])
    }
    static getCumulativeMixinRelations(){
        if (!ValueInstanceMixin.cummulativeRelations) {
            ValueInstanceMixin.cummulativeRelations = _.union(ValueInstanceMixin.getMixinRelations()
                , ValueElementMixin.getCumulativeMixinRelations()
            );
        }
        return ValueInstanceMixin.cummulativeRelations.slice();
    }
    static getSuperTypes(){
        return [
            "vdml_ValueElement"
        ];
    }
    static getProperties(){
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            {name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
            {name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
            {name : "valueElementID",type : "EString",defaultValue : "null",containingClass : "instdef_ValueInstance" }
        ]
    }
    getParent(){
        var container;
		if(!container){
			container = this.get("valueInstanceOwner") ? this.get("valueInstanceOwner") : this.previousAttributes().valueInstanceOwner;
			if(container){
				return container;
			}
		}
    }
    getPackagePath(path){
        if(!path){
            path = [];
        }
        return ValueElement.prototype.getPackagePath.apply(this, arguments);
    }
    getViewProperties(type){
        return {
            templatePath : "views/vdml/views/properties/ValueInstancePropertiesTemplate.html",
            templateName : "ValueInstancePropertiesTemplate",
            viewTypeStr : "appviews/vdml/views/properties/ValueInstanceViewModel",
            tabId : "ValueInstanceView",
            tabName: "ValueInstance"
        }
    }

}
path.ValueInstanceMixin = ValueInstanceMixin;
