import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {EObject} from '../cmof/EObject'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
export class NamedPlanElementMixin{
    constructor() {
        var path = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
        path.NamedPlanElementMixin = NamedPlanElementMixin;
    }

	defaults(){
		var ret = {
			type: "transformation_NamedPlanElement"
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!NamedPlanElementMixin.cummulativeRelations) {
            NamedPlanElementMixin.cummulativeRelations = _.union(NamedPlanElementMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return NamedPlanElementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" }
		]
	}
	getParent(){
		var container;
		return EObject.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return EObject.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/transformation/views/properties/NamedPlanElementPropertiesTemplate.html",
			templateName : "NamedPlanElementPropertiesTemplate",
			viewTypeStr : "appviews/transformation/views/properties/NamedPlanElementViewModel",
			tabId : "NamedPlanElementView",
			tabName: "NamedPlanElement"
		}
	}
	//path.NamedPlanElementMixin = NamedPlanElementMixin;
	//return NamedPlanElementMixin;
}
path.NamedPlanElementMixin = NamedPlanElementMixin;