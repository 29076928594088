import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {CommentMixin} from './CommentMixin'
import {EObject} from '../cmof/EObject'
import {DataManager} from '../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/tickets/CommentMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,CommentMixin
//, User){
	
	var path = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
	/*if(!User){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.User){
			User = importPath.User;
		}
		else{
			require(["appbo/tickets/User"],function(loadedModule){
				User = loadedModule;
			});
		}
	}*/
	var Comment = EObject.extend(utils.customExtends({
		relations:CommentMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			EObject.prototype.initialize.apply(this, arguments);
		}
		}
		, new CommentMixin()
	));
	
	Comment.abstract = false;
	Comment.supported = false;
	path.Comment = Comment;
	//return Comment;
	export {Comment};
//});