import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasureRelationship} from './MeasureRelationship'
import {GradeMeasureRelationshipMixin} from './GradeMeasureRelationshipMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { GradeMeasure } from './GradeMeasure'
import { DimensionalMeasure } from './DimensionalMeasure'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasureRelationship","appbo/smm/GradeMeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasureRelationship,GradeMeasureRelationshipMixin
, DimensionalMeasure
, GradeMeasure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!DimensionalMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasure){
			DimensionalMeasure = importPath.DimensionalMeasure;
		}
        else {
            import('./DimensionalMeasure').then(({ default: DimensionalMeasure }) => {
                DimensionalMeasure = DimensionalMeasure;
            });
			/*require(["appbo/smm/DimensionalMeasure"],function(loadedModule){
				DimensionalMeasure = loadedModule;
			});*/
		}
	}
	if(!GradeMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.GradeMeasure){
			GradeMeasure = importPath.GradeMeasure;
		}
        else {
            import('./GradeMeasure').then(({ default: GradeMeasure }) => {
                GradeMeasure = GradeMeasure;
            });
			/*require(["appbo/smm/GradeMeasure"],function(loadedModule){
				GradeMeasure = loadedModule;
			});*/
		}
	}
	var GradeMeasureRelationship = MeasureRelationship.extend(utils.customExtends({
		relations:GradeMeasureRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			MeasureRelationship.prototype.initialize.apply(this, arguments);
			if(attributes.influence){
				this.set('influence',path.Influence[attributes.influence instanceof Object ? attributes.influence.name : attributes.influence]);
			}
		}
		}
		, new GradeMeasureRelationshipMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	GradeMeasureRelationship.abstract = false;
	GradeMeasureRelationship.supported = !sp2Enabled;
	path.GradeMeasureRelationship = GradeMeasureRelationship;
	export {GradeMeasureRelationship};
//});