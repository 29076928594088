import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {MeasurementRelationship} from './MeasurementRelationship'
import {MeasurementRelationshipMixin} from './MeasurementRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasurementRelationship","appbo/smm/MeasurementRelationshipMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurementRelationship,MeasurementRelationshipMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
export class BaseMeasurementRelationshipMixin {
    /*constructor() {
        var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
        path.BaseMeasurementRelationshipMixin = BaseMeasurementRelationshipMixin;
    }*/

	defaults(){
		var ret = {
			type: "smm_BaseMeasurementRelationship"
		}
		return jQuery.extend(MeasurementRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!BaseMeasurementRelationshipMixin.cummulativeRelations) {
            BaseMeasurementRelationshipMixin.cummulativeRelations = _.union(BaseMeasurementRelationshipMixin.getMixinRelations()
                , MeasurementRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return BaseMeasurementRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_MeasurementRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" }
		]
	}
	getParent(){
		var container;
		return MeasurementRelationship.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return MeasurementRelationship.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/BaseMeasurementRelationshipPropertiesTemplate.html",
			templateName : "BaseMeasurementRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/BaseMeasurementRelationshipViewModel",
			tabId : "BaseMeasurementRelationshipView",
			tabName: "BaseMeasurementRelationship"
		}
    }
}
	path.BaseMeasurementRelationshipMixin = BaseMeasurementRelationshipMixin;
	
	//return BaseMeasurementRelationshipMixin;
//});