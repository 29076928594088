import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { CodeContainer } from '../../../../../version1/bo/beeppackage/CodeContainer'


/*define(["require", "jquery", "enhsplitter", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "app/global", "typeahead", "appviews/dashboard/views/chart/PresentationViewModel", "appbo/vdml/BusinessModel","appbo/dashboard/Presentation", "appviews/vdml/views/chart/BMValuesChart", "appbo/dashboard/DashboardMixin", "appbo/beeppackage/CodeContainer"],
function (require, $, enhsplitter, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, global, typeahead, PresentationViewModel, BusinessModel, Presentation, BMValuesChart, DashboardMixin, CodeContainer)
{*/
var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart", global.version);
export class AddAlternativeViewModel {
	fetchDashboardTimeStampsList(){
		var self = this;
		var scenarioConfig = self.model.get('config');
		var dashboardTimeStamps = [];
		if(scenarioConfig !== undefined){
			var obj = JSON.parse(scenarioConfig);
			for(var alt in obj){
				dashboardTimeStamps = dashboardTimeStamps.concat(obj[alt]);
			}
			return dashboardTimeStamps;
		}else {
			return dashboardTimeStamps;
		}
	};
	
	htmlEscape(str) {
		return String(str)
		.replace(/@/g, '')
		.replace(/ /g, '')
		.replace(/#/g, '');
	}
	cleanUp() {
	};
	fillAlternatives(){
		var self = this;
		var alts, currentPlan = DataManager.getDataManager().get('currentPlan');
		var phase = currentPlan.get('phase').findWhere({previousPhase:null});
		while (phase) {
			alts = phase.get('phaseAlternative').models;
			for (var i = 0; i < alts.length; i++) {
				var found = _.find(this.parentView.selectedAlternativeArray, function (e) { return e.id === alts[i].id; });
				if (found) {
					self.alternatives.push({ 'id': alts[i].id, name: alts[i].get('name'), 'escapeId': self.htmlEscape(alts[i].id), checked: true, displayName: phase.get('name') + " / " + alts[i].get('name'), phaseName: phase.get('name') });
				} else {
					self.alternatives.push({ 'id': alts[i].id, name: alts[i].get('name'), 'escapeId': self.htmlEscape(alts[i].id), checked: false, displayName: phase.get('name') + " / " + alts[i].get('name'), phaseName: phase.get('name') });
				}
			}
			phase = phase.get('nextPhase');
		}
		self.sortedAlternatives(_.sortBy(self.alternatives, function (i) { return i.displayName.toLowerCase(); }));
	};
	/*getSnapshotScenarios(callback) {
		var self = this;
		//TODO do pagination
		if(!window.utils.checkChrome() && !DataManager.getDataManager().get('offLineMode') && DataManager.getDataManager().get('vmpSnapshotService')){
			var promise = DataManager.getDataManager().get('vmpSnapshotService').getAllPromise("/vdmbee/snapshot");
			promise.then(function (data) {
				if (data._embedded && data._embedded.snapshot) {
					for (var i = 0; i < data._embedded.snapshot.length; i++) {
						var sid = data._embedded.snapshot[i]._links.self.href.substr(data._embedded.snapshot[i]._links.self.href.lastIndexOf('/') + 1);
						var name = data._embedded.snapshot[i].name + " " + (data._embedded.snapshot[i].unit ? "(" + data._embedded.snapshot[i].unit + " " : " ") + (data._embedded.snapshot[i].period ? data._embedded.snapshot[i].period + ")" : "");
						var period = data._embedded.snapshot[i].period;
						var unit = data._embedded.snapshot[i].unit;
						var timeStamp = self.getTimeStampOfSnapshot(period, unit);
						if (!timeStamp) {
							continue;
						}
						checked = _.find(self.parentView.selectedObservationArray, function (e) {
							return new Date(e.timeStamp).getTime() === new Date(timeStamp).getTime();
						});
						self.observations.push({ 'name': name, 'id': sid, 'timeStamp': timeStamp, 'escapeId': self.htmlEscape(sid), 'checked': checked, snapshot: true });
					}
				}
				if (callback) {
					callback();
				}
			});
			promise.catch(function (error) {
				console.log(error);
				if (callback) {
					callback();
				}
			});
		}else {
			if (callback) {
				callback();
			}
		}

	};
	getTimeStampOfSnapshot(period, unit) {
		var self = this;
		var date;
		if (!period || !unit) {
			return new Date();
		}
		if (unit === "YEARLY") {
			date = new Date(parseInt(period), 0, 0, 0, 0, 0, 0);
		} else if (unit === "MONTHLY") {
			date = new Date(parseInt(period.substr(period.lastIndexOf('/') + 1)), parseInt(period.substr(0, period.indexOf('/'))), 0, 0, 0, 0, 0);
		} else if (unit === "QUARTERLY") {
			var quarter = parseInt(period.substr(0, period.indexOf('/')));
			date = new Date(parseInt(period.substr(period.lastIndexOf('/') + 1)), parseInt(period.substr(0, period.indexOf('/'))) * 3, 0, 0, 0, 0, 0);
		} 
		return date;
	}*/
	getActualScenario(callback) {
		var self = this;
		var plan = DataManager.getDataManager().get('currentPlan');
		plan.getCommonScenario(function (commonScenario) {
			var actualObs = commonScenario.get('contextObservation') ? commonScenario.get('contextObservation').findWhere({ 'name': "Actual" }) : "";
			if (actualObs) {
				callback(actualObs);
			} else {
				callback(null)
			}
		});
	}
	fillObjectives(callback) {
		var self = this;
		self.getActualScenario(function (actualObs) {
			if (actualObs) {
				CodeContainer.getBlobInstanceWithId(actualObs.get('codeContainerId'), DataManager.getDataManager().get('currentPlan'), function (codeContainer) {
					if (codeContainer && codeContainer.get('code')) {
						var json = {};
						json = JSON.parse(codeContainer.get('code'));
						for (var key in json) {
							var phaseId = key;
							if (json[key] && json[key].phaseMapName) {
								var baseScenarioObj = { 'phaseName': json[key].phaseMapName, 'name': "Objective / " + json[key].phaseMapName, 'id': json[key].phaseMapId, 'timeStamp': actualObs.get('whenObserved'), 'escapeId': self.htmlEscape(codeContainer.get('id')) };
								self.objectives.push(baseScenarioObj);
							}
						}
						if (callback) {
							callback(self.objectives);
						}
					} else if (callback) {
						callback(null)
					}
				})
			} else if (callback) {
				callback(null)
			}
		})
	}
	fillObservations(){
		var self = this;
		var alts, observationModels, currentPlan = DataManager.getDataManager().get('currentPlan');
		var phase = currentPlan.get('phase').findWhere({previousPhase:null});
		while(phase){
			alts = phase.get('phaseAlternative').models;
			for(var i=0;i<alts.length;i++){
				observationModels = alts[i].getOrCreatePhaseObjectiveSet().get('objectiveObservation').models;
				for(var j=0;j<observationModels.length;j++){
					var name = observationModels[j].get('name');
					var timeStamp = observationModels[j].get('whenObserved');
					if(!timeStamp){
						name = name.concat(' (Applied)');
					}
					var checked;
					if(timeStamp){
						checked = _.find(self.parentView.selectedObservationArray, function(e) {return e.timeStamp == timeStamp;});
					}else{
						checked = _.find(self.parentView.selectedObservationArray, function(e) {return e.timeStamp == undefined;});
					}
					var whenObservedGuid = observationModels[j].get('whenObservedGuid');
					if(timeStamp && _.contains(self.timeStampArray, whenObservedGuid) || !timeStamp){
						if(checked){
							self.observations.push({'name':name,'id':observationModels[j].get('id'),'timeStamp':timeStamp, 'escapeId': self.htmlEscape(observationModels[j].get('id')), checked: true});
						}else{
							self.observations.push({'name':name,'id':observationModels[j].get('id'),'timeStamp':timeStamp, 'escapeId': self.htmlEscape(observationModels[j].get('id')), checked: false});
						}
					}
				}	
			}
			phase = phase.get('nextPhase');
		}
		
		/*this.getSnapshotScenarios(function () {
			self.observations = _.uniq(self.observations, function (obs) { return obs.timeStamp; });
			self.sortedObservations(_.sortBy(self.observations, function (i) { return i.name.toLowerCase(); }));
		});*/
		self.observations = _.uniq(self.observations, function (obs) { return obs.timeStamp; });
		self.sortedObservations(_.sortBy(self.observations, function (i) { return i.name.toLowerCase(); }));
	};
	
	afterRenderView() {
		var self = this;
		if (this.parentView.selectedObjectivePhases.length > 0) {
			self.selectedObjective = true;
			$('#selectObjective').prop('checked',true)
		}
		if (self.observationViewType) {
			$('#editDetailsModalContent' + self.encodeId + ' .modal-title').html('<img class="smallIcon" src="img/icons/icons_16.png">  Select Scenario(s)');
			$('#selectAllAltDiv').hide();
			if ($(".observationsList input").length == $(".observationsList input:checked").length) {
				$("#selectAllObs").prop("checked", true);
			}
			$('#selectAllObs').click(function () {
				$(".observationsList input").prop('checked', $(this).prop('checked'));
			});
			$('.observationsList').click(function () {
				if ($(".observationsList input").length == $(".observationsList input:checked").length) {
					$("#selectAllObs").prop("checked", true);
				} else {
					$("#selectAllObs").prop("checked", false);
				}
			});
			$('#selectObjective').click(function () {
				if ($('#selectObjective').prop('checked')) {
					self.selectedObjective = true;
				} else {
					self.parentView.selectedObjectivePhases.length = 0;
					self.selectedObjective = false;
				}
			})
		} else {
			$('#selectAllObsDiv').hide();
			if($(".alternativesList input").length == $(".alternativesList input:checked").length) {
				$("#selectAllAlt").prop("checked", true);
			}
			$('#selectAllAlt').click(function(){
				$(".alternativesList input").prop('checked', $(this).prop('checked'));
			});
			$('.alternativesList').click(function(){
				if($(".alternativesList input").length == $(".alternativesList input:checked").length) {
					$("#selectAllAlt").prop("checked", true);
				}else {
					$("#selectAllAlt").prop("checked", false);            
				}
			});
		}
	};            
	cleanModal(){
		var self = this;
		self.parentView = null;
		window.cleanDialogModel(self.encodeId,self);
	};
	/*this.deleteAlternative = function(){
		this.selectedAlternativeArray.remove(function (item) { return item.id === self.selectedAlt().id; });
		this.parentView.refreshTable();
		self.cleanModal();
	};*/
	saveSelection(){
		var self = this;
		if(self.observationViewType){
			self.parentView.selectedObservationArray.length = 0;
			var selectedObservations = $(".observationsList input:checked");
			_.each(self.observations, function(obs){
				var obsFound = _.find(selectedObservations, function(selectedObservation){ return selectedObservation.id === obs.escapeId;});
				if(obsFound){
					self.parentView.selectedObservationArray.push(obs);
				}
			});
			if (self.selectedObjective) {
				self.parentView.selectedObjectivePhases.length = 0;
				if ($('#selectObjective').prop('checked')) {
					self.selectedObjective = true;
					_.each(self.objectives, function (obj) {
						self.parentView.selectedObjectivePhases.push(obj);
					});
				}
			}
		} 
		else {
			//this.parentView.selectedAlternativeArray.length = 0;
			var selectedAlternatives = $(".alternativesList input:checked");
			var newAltsArray = [];
			_.each(self.alternatives, function(alt){
				var existingAlt = _.find(self.parentView.selectedAlternativeArray, function(selectedAlt){return selectedAlt.id === alt.id});
				var altFound = _.find(selectedAlternatives, function(selectedAlternative){ return selectedAlternative.id === alt.escapeId;});
				if(altFound && existingAlt){
					newAltsArray.push(existingAlt);
				}
				else if(altFound && !existingAlt){
					newAltsArray.push(alt);
				}
			});
			self.parentView.selectedObjectivePhases.length = 0;
			if (self.selectedObjective) {
				self.fillObjectives(function () {
					for (var i = 0; i < newAltsArray.length; i++) {
						for (var j = 0; j < self.objectives.length; j++) {
							if (self.objectives[j].phaseName === newAltsArray[i].phaseName) {
								self.parentView.selectedObjectivePhases.push(self.objectives[j]);
							}
						}
					}
				})
			}
			self.parentView.selectedAlternativeArray = newAltsArray;
		}
		self.parentView.refreshTable();
		self.cleanModal();
	};
	
	
	init(model, options){
		var self = this;
		this.afterRenderView = _.bind(this.afterRenderView,self);
		this.saveSelection = _.bind(this.saveSelection,self);
		this.AddAlternativeViewModel = this;
		this.model = model;
		this.id = kb.observable(model, 'id');
		this.encodeId = options.modalId;
		this.type = ko.observable();
		this.name = ko.observable('');
		this.description = ko.observable('');
		//this.enableComplete = ko.observable(false);
		this.label = ko.observable("");
		this.selectedObjective = false;
		this.observations = [];
		this.objectives = [];
		this.enableObjective = ko.observable(true);
		this.sortedObservations = ko.observableArray([]);
		this.alternatives = [];
		this.sortedAlternatives = ko.observableArray([]);
		this.timeStampArray = [];
		self.timeStampArray = self.fetchDashboardTimeStampsList();
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
			'name'
			, 'description'
			, 'Plan'
			, 'BusinessModel'
			, 'Close'
			, 'Complete'
			, 'Cancel'
			, 'type'
			, 'selectBusinessModel'
			, 'selectType'
			, 'Delete'
			, 'scenario'
			, 'phaseAlternative'
            , 'Objective'
		]);
		this.parentView = options.parentView;
		if(options.addOptions){
			if(options.addOptions.viewType === "addAlternative"){
				self.fillAlternatives();
			}  else {
				self.observationViewType = true;
				self.fillObservations();
				self.fillObjectives(function(obj){
					if(!obj){
						self.enableObjective(false);
					}
				});
			}
		}
	}
	static getInstance(model,options){
		var view = new AddAlternativeViewModel(model, options);
		view.init(model, options);
		return view;
	};
}

path.AddAlternativeViewModel = AddAlternativeViewModel;
