import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Participant} from './Participant'
import {ParticipantMixin} from './ParticipantMixin'
import {Scenario} from './Scenario'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Participant","appbo/vdml/ParticipantMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Participant,ParticipantMixin
, Scenario){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Scenario){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Scenario){
			Scenario = importPath.Scenario;
		}
		else{
			import('./Scenario').then(({ default: Scenario }) => {
                Scenario = Scenario;
            });
			/*require(["appbo/vdml/Scenario"],function(loadedModule){
				Scenario = loadedModule;
			});*/
		}
	}
	export class ActorMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.ActorMixin = ActorMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_Actor"
		}
		return jQuery.extend(Participant.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Actor",
			key:"scenario",
			relatedModel:"vdml.Scenario",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!ActorMixin.cummulativeRelations) {
            ActorMixin.cummulativeRelations = _.union(ActorMixin.getMixinRelations()
                , ParticipantMixin.getCumulativeMixinRelations()
            );
        }
		return ActorMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_Participant"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("actorOwner") ? this.get("actorOwner") : this.previousAttributes().actorOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("actorOwner") ? this.get("actorOwner") : this.previousAttributes().actorOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ActorPropertiesTemplate.html",
			templateName : "ActorPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ActorViewModel",
			tabId : "ActorView",
			tabName: "Actor"
		}
	}

//#startCustomMethods
	handleActorNameChange(modelRef, newName){
		if(Backbone.skipPersistance){
			return;
		}
		var self = this;
        DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_BusinessModelCustomer-customer', 'vdml_BusinessModelCustomer', "vbc:" + "cmof_EObject-name", 
		function(bmCustomers){	
			bmCustomers.each(function(bmCustomer){
				if(bmCustomer.get('name') !== newName){
					bmCustomer.set('name',newName);
				}
			});
			renameNetworkPartners();
		}, true);
		
		function renameNetworkPartners(){
            DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_BusinessModelNetworkPartner-networkPartner', 'vdml_BusinessModelNetworkPartner', "vbc:" + "cmof_EObject-name", 			
			function(bmNetworkPartners){
				bmNetworkPartners.each(function(bmNetworkPartner){
					if(bmNetworkPartner.get('name') !== newName){
						bmNetworkPartner.set('name',newName);
					}
			   });
			}, true);
		}
	}
	
//#endCustomMethods
	
	}
	path.ActorMixin = ActorMixin;
	//return ActorMixin;
//});