import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Collaboration} from './Collaboration'
import {BusinessNetworkMixin} from './BusinessNetworkMixin'
//import {Party} from './Party'
import {BusinessModelPortfolio} from './BusinessModelPortfolio'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Collaboration","appbo/vdml/BusinessNetworkMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Collaboration,BusinessNetworkMixin
, Party
, BusinessModelPortfolio){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	//if(!Party){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Party){
			Party = importPath.Party;
		}
		else{
			import('./Party').then(({ default: Party }) => {
                Party = Party;
            });
			/*require(["appbo/vdml/Party"],function(loadedModule){
				Party = loadedModule;
			});*/
		}
	//}
	if(!BusinessModelPortfolio){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModelPortfolio){
			BusinessModelPortfolio = importPath.BusinessModelPortfolio;
		}
		else{
			import('./BusinessModelPortfolio').then(({ default: BusinessModelPortfolio }) => {
                BusinessModelPortfolio = BusinessModelPortfolio;
            });
			/*require(["appbo/vdml/BusinessModelPortfolio"],function(loadedModule){
				BusinessModelPortfolio = loadedModule;
			});*/
		}
	}
	var BusinessNetwork = Collaboration.extend(utils.customExtends({
		relations:BusinessNetworkMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Collaboration.prototype.initialize.apply(this, arguments);
		}
		}
		, new BusinessNetworkMixin()
	));
	
	BusinessNetwork.abstract = false;
	path.BusinessNetwork = BusinessNetwork;
	export {BusinessNetwork};
//});