import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {NamedPlanElement} from './NamedPlanElement'
import {PhaseObjectiveSetMixin} from './PhaseObjectiveSetMixin'
import {ValueProposition} from '../vdml/ValueProposition'
import {Observation} from '../smm/Observation'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/transformation/NamedPlanElement","appbo/transformation/PhaseObjectiveSetMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,NamedPlanElement,PhaseObjectiveSetMixin
//, ValueProposition
//, Observation){
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
	if(!ValueProposition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueProposition){
			ValueProposition = importPath.ValueProposition;
		}
        else {
            import('../vdml/ValueProposition').then(loadedModule => {
                ValueProposition = loadedModule;
            });

			/*require(["appbo/vdml/ValueProposition"],function(loadedModule){
				ValueProposition = loadedModule;
			});*/
		}
	}
	if(!Observation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Observation){
			Observation = importPath.Observation;
		}
        else {
            import('../smm/Observation').then(loadedModule => {
                Observation = loadedModule;
            });

			/*require(["appbo/smm/Observation"],function(loadedModule){
				Observation = loadedModule;
			});*/
		}
	}
	var PhaseObjectiveSet = NamedPlanElement.extend(utils.customExtends({
		relations:PhaseObjectiveSetMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			NamedPlanElement.prototype.initialize.apply(this, arguments);
			//this.on("change:localChange",this.disablePackagesUpdates);
		}
		}
		, new PhaseObjectiveSetMixin()
    ));
    PhaseObjectiveSet["change:localChange"] = function (value, update) {
        this.disablePackagesUpdates(value, update);
    }
	PhaseObjectiveSet.abstract = false;
	path.PhaseObjectiveSet = PhaseObjectiveSet;
	//return PhaseObjectiveSet;
	export {PhaseObjectiveSet};
//});