import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {RoleLibraryMixin} from './RoleLibraryMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { RoleDefinition } from './RoleDefinition'
import { RoleCategory } from './RoleCategory'
import { ValueDeliveryModel } from './ValueDeliveryModel'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/RoleLibraryMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,RoleLibraryMixin
, RoleDefinition
, RoleCategory
, ValueDeliveryModel){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!RoleDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.RoleDefinition){
			RoleDefinition = importPath.RoleDefinition;
		}
		else{
            import('./RoleDefinition').then(loadedModule => {
                RoleDefinition = loadedModule;
			});
			/*require(["appbo/vdml/RoleDefinition"],function(loadedModule){
				RoleDefinition = loadedModule;
			});*/
		}
	}
	if(!RoleCategory){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.RoleCategory){
			RoleCategory = importPath.RoleCategory;
		}
		else{
            import('./RoleCategory').then(loadedModule => {
                RoleCategory = loadedModule;
			});
			/*require(["appbo/vdml/RoleCategory"],function(loadedModule){
				RoleCategory = loadedModule;
			});*/
		}
	}
	if(!ValueDeliveryModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueDeliveryModel){
			ValueDeliveryModel = importPath.ValueDeliveryModel;
		}
		else{
            import('./ValueDeliveryModel').then(loadedModule => {
         //       ValueDeliveryModel = loadedModule;
			});
			/*require(["appbo/vdml/ValueDeliveryModel"],function(loadedModule){
				ValueDeliveryModel = loadedModule;
			});*/
		}
	}
	var RoleLibrary = VdmlElement.extend(utils.customExtends({
		relations:RoleLibraryMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new RoleLibraryMixin()
	));
	
	RoleLibrary.abstract = false;
	RoleLibrary.supported = false;
	path.RoleLibrary = RoleLibrary;
	export {RoleLibrary};
//});