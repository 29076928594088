import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurementRelationship} from './MeasurementRelationship'
import {RescaledMeasurementRelationshipMixin} from './RescaledMeasurementRelationshipMixin'
import {RescaledMeasurement} from './RescaledMeasurement'
import {DimensionalMeasurement} from './DimensionalMeasurement'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasurementRelationship","appbo/smm/RescaledMeasurementRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurementRelationship,RescaledMeasurementRelationshipMixin
, DimensionalMeasurement
, RescaledMeasurement){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!DimensionalMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasurement){
			DimensionalMeasurement = importPath.DimensionalMeasurement;
		}
		else{
			import('./DimensionalMeasurement').then(({ default: DimensionalMeasurement }) => {
				DimensionalMeasurement = DimensionalMeasurement;
			});
			/*require(["appbo/smm/DimensionalMeasurement"],function(loadedModule){
				DimensionalMeasurement = loadedModule;
			});*/
		}
	}
	if(!RescaledMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RescaledMeasurement){
			RescaledMeasurement = importPath.RescaledMeasurement;
		}
		else{
			import('./RescaledMeasurement').then(({ default: RescaledMeasurement }) => {
				RescaledMeasurement = RescaledMeasurement;
			});
			/*require(["appbo/smm/RescaledMeasurement"],function(loadedModule){
				RescaledMeasurement = loadedModule;
			});*/
		}
	}
	var RescaledMeasurementRelationship = MeasurementRelationship.extend(utils.customExtends({
		relations:RescaledMeasurementRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			MeasurementRelationship.prototype.initialize.apply(this, arguments);
			//this.on("change:from",this.onFromRemoved);?? no implementation? 
		}
		}
		, new RescaledMeasurementRelationshipMixin()
	));
    RescaledMeasurementRelationship["change:from"] = function () {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.onFromRemoved();
		}
    }
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	RescaledMeasurementRelationship.abstract = false;
	RescaledMeasurementRelationship.supported =  !sp2Enabled;
	path.RescaledMeasurementRelationship = RescaledMeasurementRelationship;
	export {RescaledMeasurementRelationship};
//});