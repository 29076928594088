import * as $ from 'jquery'
import * as _ from 'underscore'
import * as Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { BeepPackageMixin } from '../../../../bo/beeppackage/BeepPackageMixin'
import { PackageReference } from '../../../../bo/transformation/PackageReference'
import { ProcessTree } from '../../../../views/valueStreamMap/views/designer/ProcessTree'
//define(["require", "jquery", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "appbo/beeppackage/BeepPackageMixin", "appbo/transformation/PackageReference", "appcommon/com/vbee/filesystem/PackageReference", "appbo/vdml/ValueStreamMapDiagram", "app/global", "appviews/valueStreamMap/views/designer/ProcessTree"],
//	function (require, $, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, BeepPackageMixin, PlanPackageReference, PackageReference, ValueStreamMapDiagram, global, ProcessTree){
var path = DataManager.getDataManager().buildAppNsPath("capabilityLibrary.views.properties", global.version);
export class CapabilityLibraryDetailsViewModel {
    constructor(model, options) {
        var self = this;
        this.init(model, options);
    }
    init(model, options) {
        var self = this;
        this.CapabilityLibraryDetailsViewModel = this;
        this.model = model.model;
        this.id = kb.observable(self.model, 'id');
        this.jsTreeModelData = model.jsTreeModelData;
        function htmlEscape(str) {
            return String(str)
                .replace(/@/g, '')
                .replace(/ /g, '');
        }
        this.encodeId = htmlEscape(this.id());
        this.enableDelete = ko.observable(true);
        this.enableComplete = ko.observable(true);
        this.enableProcess = ko.observable(this.model.get('process') ? true : false);
        this.name = ko.observable(self.model ? self.model.get('name') : null);
        this.description = ko.observable(self.model ? self.model.get('description') : "");
        this.existingPackages = [];
        this.currentAlt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
        this.name.subscribe(function(val) {
            self.description(val);
        });
        this.checkUniqueValue = function(view, event) {
            var name = event.currentTarget.value.trim();
            if (name !== "") {
                if (self.model.get('name') === name) {
                    $('#duplicateName' + self.encodeId).text("");
                    $("#complete").removeAttr('disabled');
                    return;
                }
                var unique = window.isUniqueName(name, null, self.id(), self.model.getNestedParent().get('capabilityLibrary'));
                if (unique) {
                    $('#duplicateName' + self.encodeId).text("");
                    $("#complete").removeAttr('disabled');
                } else {
                    var lcManager = DataManager.getDataManager().get('localeManager');
                    $('#duplicateName' + self.encodeId).text(lcManager.get('DuplicateAlert', lcManager.get('CapabilityLibrary')));
                    $("#complete").attr('disabled', 'disabled');
                }
            } else {
                $("#complete").attr('disabled', 'disabled');
            }
        };

        this.dispose = function() {
            window.cleanViewModel(self);
        };

        this.saveCapLibDetails = function() {
            if (self.name() !== "") {
                if (self.name().trim() != self.model.get('name')) {
                    self.model.set('name', self.name().trim());
                    var jsTreeModelDataObj = self.jsTreeModelData[0];
                    jsTreeModelDataObj.text = self.name().trim();
                    jsTreeModelDataObj.description = self.description().trim();
                    $('#jstree_div').jstree(true).settings.core.data = jsTreeModelDataObj;
                    $('#jstree_div').jstree(true).refresh(false, true);
                    window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'), DataManager.getDataManager().get('viewAlternative'), self.CapabilityLibraryDetailsViewModel);
                }
                self.model.set('description', self.description().trim());
            }

        };


        this.deleteCapLib = function() {
            function doDelete() {
                self.model.destroy();
                DataManager.getDataManager().get('router').navigate(window.utils.getPlanNavigateUrl() + "/" + DataManager.getDataManager().get('viewAlternative'), {
                    trigger: true
                });
                window.utils.stopSpinner('deleteCapLib');
            }
            if ((self.model.get('capabilityLibraryDiagrams') && self.model.get('capabilityLibraryDiagrams').length > 0) || (self.model.get('capability') && self.model.get('capability').length > 0)) {
                var message = 'Deleting a Capability Library will delete all the model data in the Library and cannot be recovered. Are you sure you want to continue?';
                bootbox.confirm(message, function(result) {
                    if (result) {
                        window.utils.startSpinner('deleteCapLib', 'Deleting Capability Library...');
                        setTimeout(doDelete, 0);
                    } else {
                        //self.cleanModal();
                    }
                });
            } else {
                setTimeout(doDelete, 0);
            }
        };


        this.afterRenderView = function() {}
    }
    static getInstance(model, options, detailedView) {
        var view = new CapabilityLibraryDetailsViewModel(model, options, detailedView);
        //view.init(model, options);
        return view;
    };
}
path.CapabilityLibraryDetailsViewModel = CapabilityLibraryDetailsViewModel;