import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {MeasureRelationship} from './MeasureRelationship'
import {MeasureRelationshipMixin} from './MeasureRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
//import { RescaledMeasure } from './RescaledMeasure'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasureRelationship","appbo/smm/MeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasureRelationship,MeasureRelationshipMixin
, RescaledMeasure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	//if(!RescaledMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RescaledMeasure){
			RescaledMeasure = importPath.RescaledMeasure;
		}
        else {
            import('./RescaledMeasure').then(({ default: RescaledMeasure }) => {
                RescaledMeasure = RescaledMeasure;
            });

			/*require(["appbo/smm/RescaledMeasure"],function(loadedModule){
				RescaledMeasure = loadedModule;
			});*/
		}
	//}
	export class BaseMeasureRelationshipMixin {
        /*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            path.BaseMeasureRelationshipMixin = BaseMeasureRelationshipMixin;
        }*/
	defaults(){
		var ret = {
			type: "smm_BaseMeasureRelationship"
		}
		return jQuery.extend(MeasureRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"smm_BaseMeasureRelationship",
			key:"rescaledMeasure",
			relatedModel:"smm.RescaledMeasure",
			reverseRelation: {
				key:"rescaledMeasureOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!BaseMeasureRelationshipMixin.cummulativeRelations) {
            BaseMeasureRelationshipMixin.cummulativeRelations = _.union(BaseMeasureRelationshipMixin.getMixinRelations()
                , MeasureRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return BaseMeasureRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_MeasureRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "influence",type : "Influence",defaultValue : "null",containingClass : "smm_MeasureRelationship" },
			{name : "usePreviousAlternative",type : "EBoolean",defaultValue : false,containingClass : "smm_MeasureRelationship" }
		]
	}
	getParent(){
		var container;
		return MeasureRelationship.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return MeasureRelationship.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/BaseMeasureRelationshipPropertiesTemplate.html",
			templateName : "BaseMeasureRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/BaseMeasureRelationshipViewModel",
			tabId : "BaseMeasureRelationshipView",
			tabName: "BaseMeasureRelationship"
		}
	}
//#startCustomMethods
	createRescaledMeasure(offsetValue,multiplierValue,operationValue){
		if (offsetValue != "0" || multiplierValue != "1" || operationValue) {
			var reMeasureId = DataManager.getDataManager().guidGeneratorByOwner(this);
			var RescaledMeasure = Backbone.Relational.store.getObjectByName('smm.RescaledMeasure');
			var rescaledMeasure = new RescaledMeasure({id:reMeasureId,name:this.get('name')+" RescaledMeasure",rescaledMeasureOwner:this,offset: offsetValue,multiplier:multiplierValue});
			if (operationValue) {
				if(operationValue instanceof Backbone.Model) {
					var operation = operationValue;
				}else {
					var operation = this.get('measure').get('libraries').get('measureElements').findWhere({ name: operationValue });
				}
				rescaledMeasure.set('operation', operation);
			}
			return rescaledMeasure;
		}
	}
//#endCustomMethods	
	
	}
	path.BaseMeasureRelationshipMixin = BaseMeasureRelationshipMixin;
	//return BaseMeasureRelationshipMixin;
//});