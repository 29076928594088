import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurementRelationship} from './MeasurementRelationship'
import {MeasurementRelationshipMixin} from './MeasurementRelationshipMixin'
import {RescaledMeasurement} from './RescaledMeasurement'
import {DimensionalMeasurement} from './DimensionalMeasurement'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasurementRelationship","appbo/smm/MeasurementRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurementRelationship,MeasurementRelationshipMixin
, DimensionalMeasurement
, RescaledMeasurement){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!DimensionalMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasurement){
			DimensionalMeasurement = importPath.DimensionalMeasurement;
		}
		else{
			import('./DimensionalMeasurement').then(({ default: DimensionalMeasurement }) => {
				DimensionalMeasurement = DimensionalMeasurement;
			});
			/*require(["appbo/smm/DimensionalMeasurement"],function(loadedModule){
				DimensionalMeasurement = loadedModule;
			});*/
		}
	}
	if(!RescaledMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RescaledMeasurement){
			RescaledMeasurement = importPath.RescaledMeasurement;
		}
		else{
			import('./RescaledMeasurement').then(({ default: RescaledMeasurement }) => {
				RescaledMeasurement = RescaledMeasurement;
			});
			/*require(["appbo/smm/RescaledMeasurement"],function(loadedModule){
				RescaledMeasurement = loadedModule;
			});*/
		}
	}
	export class RescaledMeasurementRelationshipMixin {
	
	defaults(){
		var ret = {
			type: "smm_RescaledMeasurementRelationship"
		}
		return jQuery.extend(MeasurementRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"smm_RescaledMeasurementRelationship",
			key:"from",
			relatedModel:"smm.DimensionalMeasurement",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"rescaleTo",
				type :Backbone.HasMany,
				includeInJSON:"id"
			}				
		},
		{
			type :Backbone.HasOne,
			containingClass:"smm_RescaledMeasurementRelationship",
			key:"to",
			relatedModel:"smm.RescaledMeasurement",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"rescaleFrom",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!RescaledMeasurementRelationshipMixin.cummulativeRelations) {
            RescaledMeasurementRelationshipMixin.cummulativeRelations = _.union(RescaledMeasurementRelationshipMixin.getMixinRelations()
                , MeasurementRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return RescaledMeasurementRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_MeasurementRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" }
		]
	}
	getParent(){
		var container;
		return MeasurementRelationship.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return MeasurementRelationship.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/RescaledMeasurementRelationshipPropertiesTemplate.html",
			templateName : "RescaledMeasurementRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/RescaledMeasurementRelationshipViewModel",
			tabId : "RescaledMeasurementRelationshipView",
			tabName: "RescaledMeasurementRelationship"
		}
	}
//#startCustomMethods
	onFromoRemoved(model,newFrom,options){
		if(!newFrom && options.__related){
			var parent = model.get('to');
			model.destroy();	
			parent.destroy();
		}
	}
//#endCustomMethods	
}
	path.RescaledMeasurementRelationshipMixin = RescaledMeasurementRelationshipMixin;
	
	//return RescaledMeasurementRelationshipMixin;
//});