import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {CollaborationDiagram} from './CollaborationDiagram'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {CollaborationDiagramMixin} from './CollaborationDiagramMixin'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/CollaborationDiagramMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,CollaborationDiagramMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class CapabilityOfferDiagramMixin {

	defaults() {
		var ret = {
			type: "vdml_CapabilityOfferDiagram"
		}
		return jQuery.extend(CollaborationDiagram.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!CapabilityOfferDiagramMixin.cummulativeRelations) {
            CapabilityOfferDiagramMixin.cummulativeRelations = _.union(CapabilityOfferDiagramMixin.getMixinRelations()
                , CollaborationDiagramMixin.getCumulativeMixinRelations()
            );
        }
		return CapabilityOfferDiagramMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_CollaborationDiagram"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		return CollaborationDiagram.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return CollaborationDiagram.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/CapabilityOfferDiagramPropertiesTemplate.html",
			templateName : "CapabilityOfferDiagramPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/CapabilityOfferDiagramViewModel",
			tabId : "CapabilityOfferDiagramView",
			tabName: "CapabilityOfferDiagram"
		}
	}
	
	}
	path.CapabilityOfferDiagramMixin = CapabilityOfferDiagramMixin;
	//return CapabilityOfferDiagramMixin;
//});