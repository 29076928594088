import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Measurement} from './Measurement'
import {MeasurementMixin} from './MeasurementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { Operation } from './Operation'
import { GradeMeasurementRelationship } from './GradeMeasurementRelationship'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/Measurement","appbo/smm/MeasurementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Measurement,MeasurementMixin
, GradeMeasurementRelationship
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!GradeMeasurementRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.GradeMeasurementRelationship){
			GradeMeasurementRelationship = importPath.GradeMeasurementRelationship;
		}
		else{
            import('./GradeMeasurementRelationship').then(({ default: GradeMeasurementRelationship }) => {
                GradeMeasurementRelationship = GradeMeasurementRelationship;
            });
			/*require(["appbo/smm/GradeMeasurementRelationship"],function(loadedModule){
				GradeMeasurementRelationship = loadedModule;
			});*/
		}
	}
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
		else{
            import('./Operation').then(({ default: Operation })  => {
                Operation = Operation;
            });
			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	export class GradeMeasurementMixin {
	
	defaults(){
		var ret = {
			type: "smm_GradeMeasurement"
		}
		return jQuery.extend(Measurement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"smm_GradeMeasurement",
			key:"gradeTo",
			relatedModel:"smm.GradeMeasurementRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"from",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		/*{
			type :Backbone.HasOne,
			containingClass:"smm_GradeMeasurement",
			key:"baseQuery",
			relatedModel:"smm.Operation",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}*/
		])
	}
	static getCumulativeMixinRelations(){
		if (!GradeMeasurementMixin.cummulativeRelations) {
            GradeMeasurementMixin.cummulativeRelations = _.union(GradeMeasurementMixin.getMixinRelations()
                , MeasurementMixin.getCumulativeMixinRelations()
            );
        }
		return GradeMeasurementMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_Measurement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "error",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			{name : "breakValue",type : "EString",defaultValue : "null",containingClass : "smm_Measurement" },
			{name : "value",type : "EString",defaultValue : "null",containingClass : "smm_GradeMeasurement" },
			{name : "isBaseSupplied",type : "EBoolean",defaultValue : "null",containingClass : "smm_GradeMeasurement" }
		]
	}
	getParent(){
		var container;
		return Measurement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return Measurement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/GradeMeasurementPropertiesTemplate.html",
			templateName : "GradeMeasurementPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/GradeMeasurementViewModel",
			tabId : "GradeMeasurementView",
			tabName: "GradeMeasurement"
		}
	}
//#startCustomMethods
	createGradeMesurementRelationship(relName,relDescription,sourceMeasurement){
		var relId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var rel = new GradeMeasurementRelationship({id:relId,name:relName,description:relDescription,measurementRelationshipsOwner:this});
		this.set('gradeTo', rel);//to trigger changelog as reverse association not triggering changelog 
		rel.set({'from':this,'to':sourceMeasurement});
		return rel;
	}
	calculate(){
		var self = this;
		var observerdMeasure = self.get('observedMeasure');
		var gradeMeasurementRel = self.get('gradeTo');
		var measure = observerdMeasure.get('measure');
		if(measure && gradeMeasurementRel && gradeMeasurementRel.get('to')) {
			var gradeInterval = measure.get('interval');
			var gradeValue = (gradeMeasurementRel && gradeMeasurementRel.get('to')) ? parseFloat(gradeMeasurementRel.get('to').get('value')) :0;
			var inInterval = false;
			
			self.set('symbol', null);
			var intervalSet = false;
			gradeInterval.each(function(intv){
				var maxep = parseFloat(intv.get('maximumEndPoint'));
				var maxOpen = intv.get('maximumOpen');
				var minep = parseFloat(intv.get('minimumEndPoint'));
				var minOpen = intv.get('minimumOpen');
				var intervalSymbol = intv.get('symbol');
				
				if(maxOpen && minOpen){
					if(gradeValue >= minep && gradeValue <= maxep ){
						inInterval = true;
					}
				}else if(maxOpen){
					if(gradeValue > minep && gradeValue <= maxep ){
						inInterval = true;
					}
				}
				else{
					if(gradeValue > minep && gradeValue < maxep ){
						inInterval = true;
					}
				}
				
				if(inInterval && !intervalSet){
					intervalSet = true;
					self.set('symbol', intervalSymbol);
				}
			});
		}
	};
	
    clone(data, obsMes, callback) {
        var self = this;
	    var mestId = DataManager.getDataManager().guidGeneratorByOwner(this);
	    var mest = new this.constructor({ id: mestId, name: this.get('name'), description: this.get('description'), observedMeasure: obsMes });
        var val = data ? data[this.get('measuredCharacteristic').id] : undefined;
        mest.set('symbol', val ? val : this.get('symbol'));
        self.get('measuredCharacteristic').get('measurement').add(mest);
        if (self.get('gradeTo')) {
            self.get('gradeTo').clone(data, mest, function () {
                //mest.cloneParentRelationships(function () {
                    if (callback) {
                        callback(mest);
                    }
                //});
            });
        } else {
            //mest.cloneParentRelationships(function () {
                if (callback) {
                    callback(mest);
                }
            //});
        }
	    return mest;
	}
//#endCustomMethods		
    }
	path.GradeMeasurementMixin = GradeMeasurementMixin;
	
	//return GradeMeasurementMixin;
//});