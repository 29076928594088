import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {BMCanvasItem} from '../canvas/BMCanvasItem'
import {VdmlElement} from '../vdml/VdmlElement'
import {VdmlElementMixin} from '../vdml/VdmlElementMixin'
import { VdmlElement2Mixin } from '../vdml/VdmlElement2Mixin'
//define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
//function(require,jQuery,_,async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
//,BMCanvasItem
//){
	
	var path = DataManager.getDataManager().buildAppNsPath("canvas",global.version);
	
	/*if(!BMCanvasItem){
		var importPath = DataManager.getDataManager().buildAppNsPath("canvas",global.version);
		if(importPath.BMCanvasItem){
			BMCanvasItem = importPath.BMCanvasItem;
		}
		else{
			require(["appbo/canvas/BMCanvasItem"],function(loadedModule){
				BMCanvasItem = loadedModule;
			});
		}
	}*/	
	export class BMCanvasBlockMixin{
	
	defaults(){
		var ret = {
			type: "canvas_BMCanvasBlock"
		}
		return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"canvas_BMCanvasBlock",
			key:"blockItem",
			relatedModel:"canvas.BMCanvasItem",
			reverseRelation: {
				key:"blockItemOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}			
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!BMCanvasBlockMixin.cummulativeRelations) {
            BMCanvasBlockMixin.cummulativeRelations = _.union(BMCanvasBlockMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
		return BMCanvasBlockMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_VdmlElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("bmCanvasBlockOwner") ? this.get("bmCanvasBlockOwner") : this.previousAttributes().bmCanvasBlockOwner;
			if(container){
				return container;
			}
		}
		return this;
	};
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("bmCanvasBlockOwner") ? this.get("bmCanvasBlockOwner") : this.previousAttributes().bmCanvasBlockOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')});
				return container.getPackagePath(path);
			}
		}
		return path;
	};
	
	}
	path.BMCanvasBlockMixin = BMCanvasBlockMixin;
	//return BMCanvasBlockMixin;
//});