import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { VdmlElement } from './VdmlElement'
import { VdmlElementMixin } from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ObjectReference } from './ObjectReference'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, ObjectReference){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ObjectReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ObjectReference){
			ObjectReference = importPath.ObjectReference;
		}
        else {
            import('./ObjectReference').then(({ default: ObjectReference }) => {
                ObjectReference = ObjectReference;
            });
			/*require(["appbo/vdml/ObjectReference"],function(loadedModule){
				ObjectReference = loadedModule;
			});*/
		}
	}
export class ProcessReferenceMixin {

    defaults () {
        var ret = {
            type: "vdml_ProcessReference"
        }
        return jQuery.extend(VdmlElement.prototype.defaults.apply(this), ret);
    }
    static getMixinRelations () {
        return _.union([
            {
                type: Backbone.HasOne,
                containingClass: "vdml_ProcessReference",
                key: "businessProcess",
                relatedModel: "vdml.ObjectReference",
                reverseRelation: {
                    key: "businessProcessOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            }
        ])
    }
    
    static getCumulativeMixinRelations () {
        if (!ProcessReferenceMixin.cummulativeRelations) {
            ProcessReferenceMixin.cummulativeRelations = _.union(ProcessReferenceMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
        return ProcessReferenceMixin.cummulativeRelations.slice();
    }
    static getSuperTypes () {
        return [
            "vdml_VdmlElement"
        ];
    }
    static getProperties () {
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "represents", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "documentID", type: "EString", defaultValue: "null", containingClass: "vdml_VdmlElement" },
            { name: "Url", type: "EString", defaultValue: "null", containingClass: "vdml_ProcessReference" }
        ]
    }
    getParent () {
        var container;
        if (!container) {
            container = this.get("processReferenceOwner") ? this.get("processReferenceOwner") : this.previousAttributes().processReferenceOwner;
            if (container) {
                return container;
            }
        }
        return this;
    }
    getPackagePath (path) {
        if (!path) {
            path = [];
        }
        var container;
        if (!container) {
            container = this.get("processReferenceOwner") ? this.get("processReferenceOwner") : this.previousAttributes().processReferenceOwner;
            if (container) {
                path.unshift({ id: this.get('id'), name: this.get('name') })
                return container.getPackagePath(path);
            }
        }
        return path;
    }
    getViewProperties (type) {
        return {
            templatePath: "views/vdml/views/properties/ProcessReferencePropertiesTemplate.html",
            templateName: "ProcessReferencePropertiesTemplate",
            viewTypeStr: "appviews/vdml/views/properties/ProcessReferenceViewModel",
            tabId: "ProcessReferenceView",
            tabName: "ProcessReference"
        }
    }
    
}
path.ProcessReferenceMixin = ProcessReferenceMixin;
//return ProcessReferenceMixin;
//});