import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BMElement} from './BMElement'
import {BusinessModelPortfolioMixin} from './BusinessModelPortfolioMixin'
import {BusinessModel} from './BusinessModel'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BMElement","appbo/vdml/BusinessModelPortfolioMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BMElement,BusinessModelPortfolioMixin
, BusinessModel){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!BusinessModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModel){
			BusinessModel = importPath.BusinessModel;
		}
		else{
			import('./BusinessModel').then(({ default: BusinessModel }) => {
                BusinessModel = BusinessModel;
            });
			/*require(["appbo/vdml/BusinessModel"],function(loadedModule){
				BusinessModel = loadedModule;
			});*/
		}
	}
	var BusinessModelPortfolio = BMElement.extend(utils.customExtends({
		relations:BusinessModelPortfolioMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BMElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new BusinessModelPortfolioMixin()
	));
	
	BusinessModelPortfolio.abstract = false;
	BusinessModelPortfolio.supported = false;
	path.BusinessModelPortfolio = BusinessModelPortfolio;
	export {BusinessModelPortfolio};
//});