  import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AnalysisContext} from './AnalysisContext'
import {DelegationContextMixin} from './DelegationContextMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//import { Collaboration } from './Collaboration'
import { PortDelegation } from './PortDelegation'
import { Activity } from './Activity'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/AnalysisContext","appbo/vdml/DelegationContextMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AnalysisContext,DelegationContextMixin

, Collaboration
, PortDelegation
, Activity){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!AnalysisContext){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.AnalysisContext){
			AnalysisContext = importPath.AnalysisContext;
		}
        else {
            import('./AnalysisContext').then(({ default: AnalysisContext }) => {
                AnalysisContext = AnalysisContext;
            });
            /*require(["appbo/vdml/AnalysisContext"], function (loadedModule) {
                AnalysisContext = loadedModule;
			});*/
		}
	}
	//if(!Collaboration){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Collaboration){
			//Collaboration = importPath.Collaboration;
		}
        else {
           /* import('./Collaboration').then(({ default: Collaboration }) => {
                Collaboration = Collaboration;
            });
            require(["appbo/vdml/Collaboration"], function (loadedModule) {
                Collaboration = loadedModule;
			});*/
		}
	//}
	if(!PortDelegation){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PortDelegation){
			PortDelegation = importPath.PortDelegation;
		}
        else {
            import('./PortDelegation').then(({ default: PortDelegation }) => {
                PortDelegation = PortDelegation;
            });
           /* require(["appbo/vdml/PortDelegation"], function (loadedModule) {
                PortDelegation = loadedModule;
			});*/
		}
	}
	if(!Activity){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Activity){
			Activity = importPath.Activity;
		}
        else {
            import('./Activity').then(({ default: Activity }) => {
                Activity = Activity;
            });
            /*require(["appbo/vdml/Activity"], function (loadedModule) {
                Activity = loadedModule;
			});*/
		}
	}
	var DelegationContext = AnalysisContext.extend(utils.customExtends({
		relations:DelegationContextMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			AnalysisContext.prototype.initialize.apply(this, arguments);

		}
		}
		, new DelegationContextMixin()
	));
    DelegationContext["change:delegatedActivity"] =  function (model, relatedModel, options) {
        if (Backbone.skipPersistance) {
            return;
        }
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
			if (!relatedModel) {
				model.destroy();
			}
		}
    };	
	DelegationContext.abstract = false;
	path.DelegationContext = DelegationContext;
	export {DelegationContext};
//});