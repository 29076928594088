import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasureRelationship} from './MeasureRelationship'
import {MeasureRelationshipMixin} from './MeasureRelationshipMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { GradeMeasure } from './GradeMeasure'
import { DimensionalMeasure } from './DimensionalMeasure'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasureRelationship","appbo/smm/MeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasureRelationship,MeasureRelationshipMixin
, DimensionalMeasure
, GradeMeasure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!DimensionalMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasure){
			DimensionalMeasure = importPath.DimensionalMeasure;
		}
		else{
            import('./DimensionalMeasure').then(({ default: DimensionalMeasure }) => {
                DimensionalMeasure = DimensionalMeasure;
            });
			/*require(["appbo/smm/DimensionalMeasure"],function(loadedModule){
				DimensionalMeasure = loadedModule;
			});*/
		}
	}
	if(!GradeMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.GradeMeasure){
			GradeMeasure = importPath.GradeMeasure;
		}
		else{
            import('./GradeMeasure').then(({ default: GradeMeasure }) => {
                GradeMeasure = GradeMeasure;
            });
			/*require(["appbo/smm/GradeMeasure"],function(loadedModule){
				GradeMeasure = loadedModule;
			});*/
		}
	}
	export class GradeMeasureRelationshipMixin {

	defaults(){
		var ret = {
			type: "smm_GradeMeasureRelationship"
		}
		return jQuery.extend(MeasureRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"smm_GradeMeasureRelationship",
			key:"to",
			relatedModel:"smm.DimensionalMeasure",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		])
	}
	static getCumulativeMixinRelations(){
		if (!GradeMeasureRelationshipMixin.cummulativeRelations) {
            GradeMeasureRelationshipMixin.cummulativeRelations = _.union(GradeMeasureRelationshipMixin.getMixinRelations()
                , MeasureRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return GradeMeasureRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_MeasureRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "influence",type : "Influence",defaultValue : "null",containingClass : "smm_MeasureRelationship" }
		]
	}
	getParent(){
		var container;
		return MeasureRelationship.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return MeasureRelationship.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/GradeMeasureRelationshipPropertiesTemplate.html",
			templateName : "GradeMeasureRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/GradeMeasureRelationshipViewModel",
			tabId : "GradeMeasureRelationshipView",
			tabName: "GradeMeasureRelationship"
		}
	}
	
	}
	path.GradeMeasureRelationshipMixin = GradeMeasureRelationshipMixin;
	//return GradeMeasureRelationshipMixin;
//});