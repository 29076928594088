import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { VdmlElement } from './VdmlElement'
import { PracticeLibraryMixin } from './PracticeLibraryMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { PracticeDefinition } from './PracticeDefinition'
import { PracticeCategory } from './PracticeCategory'


/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/PracticeLibraryMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,PracticeLibraryMixin
, PracticeDefinition
, PracticeCategory){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!PracticeDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PracticeDefinition){
			PracticeDefinition = importPath.PracticeDefinition;
		}
        else {
            import('./PracticeDefinition').then(({ default: PracticeDefinition }) => {
                PracticeDefinition = PracticeDefinition;
            });
			/*require(["appbo/vdml/PracticeDefinition"],function(loadedModule){
				PracticeDefinition = loadedModule;
			});*/
		}
	}
	if(!PracticeCategory){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PracticeCategory){
			PracticeCategory = importPath.PracticeCategory;
		}
        else {
            import('./PracticeCategory').then(({ default: PracticeCategory }) => {
                PracticeCategory = PracticeCategory;
            });
			/*require(["appbo/vdml/PracticeCategory"],function(loadedModule){
				PracticeCategory = loadedModule;
			});*/
		}
	}
	var PracticeLibrary = VdmlElement.extend(utils.customExtends({
		relations:PracticeLibraryMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new PracticeLibraryMixin()
	));
	
	PracticeLibrary.abstract = false;
	PracticeLibrary.supported = false;
    path.PracticeLibrary = PracticeLibrary;
    export { PracticeLibrary };
//});