import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {CapabilityMixin} from './CapabilityMixin'
import {Characteristic} from '../smm/Characteristic'
import {CapabilityLibraryDiagram} from './CapabilityLibraryDiagram'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/CapabilityMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,CapabilityMixin
, Characteristic
, CapabilityLibraryDiagram){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Characteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Characteristic){
			Characteristic = importPath.Characteristic;
		}
		else{
			import('../smm/Characteristic').then(loadedModule => {
				Characteristic = loadedModule;
			});
			/*require(["appbo/smm/Characteristic"],function(loadedModule){
				Characteristic = loadedModule;
			});*/
		}
	}
	if(!CapabilityLibraryDiagram){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityLibraryDiagram){
			CapabilityLibraryDiagram = importPath.CapabilityLibraryDiagram;
		}
		else{
			import('./CapabilityLibraryDiagram').then(loadedModule => {
				CapabilityLibraryDiagram = loadedModule;
			});
			/*require(["appbo/vdml/CapabilityLibraryDiagram"],function(loadedModule){
				CapabilityLibraryDiagram = loadedModule;
			});*/
		}
	}
	var Capability = VdmlElement.extend(utils.customExtends({
		relations:CapabilityMixin.getMixinRelations(),
		subModelTypes: {
			vdml_CapabilityCategory : 'vdml.CapabilityCategory',
			vdml_CapabilityDefinition : 'vdml.CapabilityDefinition'
		},
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new CapabilityMixin()
	));
	
	Capability.abstract = false;
	path.Capability = Capability;
	export {Capability};
//});