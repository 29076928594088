import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","asyncsimple","Lawnchair","backbone-lawnchair","bootbox","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/ValueDeliveryModel","appbo/transformation/Plan","appbo/preference/UserPreferences"],
function(require,$,_,Backbone,ko, koMapping,kb,asyncsimple,Lawnchair, backboneLawnchair,bootbox,DataManager,global,ValueDeliveryModel,Plan,UserPreferences){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation.views.correction",global.version);
	export class PlanValidation2ViewModel {
		init(model, options,detailedView) {
			var self = this;
			this.PlanValidation2ViewModel = this;
			this.model = model;
			if(options && options.parentView){
				this.parentView = options.parentView;
				this.encodeId = ""+options.modalId;
			}
			this.alternativeArray = ko.observableArray();
			this.checkPhaseDetails();
			this.selectedAlternative = ko.observable();
			this.periodNo = ko.observable();
			utils.defineKoNumeric(ko);
		}

		checkPhaseDetails(){
            var self = this;
            var phases = self.model.get('phase');
			for(var i=0;i<phases.length;i++){
				self.checkAlternativeDetails(phases.at(i));
			}
		};

		checkAlternativeDetails(phaseModel){
			var self = this;
			var alternatives = phaseModel.get('phaseAlternative');
			for(var i=0;i<alternatives.length;i++){
				var PhaseAlternateModel = alternatives.at(i);
				var obj = {id:self.alternativeArray.length,originalId:PhaseAlternateModel.get("id"),modalId:window.utils.htmlEscape(PhaseAlternateModel.get("id"))+"alternative", name:PhaseAlternateModel.getParent().get("name") + "-" + PhaseAlternateModel.get("name")};		
				if(PhaseAlternateModel.get("id") === PhaseAlternateModel.getParent().get("primary").get('id')){
					self.alternativeArray.push(obj);
				}
			}
		};

		createAltScenario(){
			var self = this;
			var scenarioStepData = [];
			var altId;
			for(var i=0;i<self.alternativeArray().length;i++){
				var alt = self.alternativeArray().at(i);
				if(alt.modalId === self.selectedAlternative()){
					altId = alt.originalId;
					break;
				}
			}
			var currentAlternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: altId});
			var phName = currentAlternative.getParent().get('name');
			var phModalId = window.utils.htmlEscape(currentAlternative.getParent().get('id'));
			var obj = {modalId: phModalId,name: phName,noOfPeriods: self.periodNo()};
			scenarioStepData.push(obj);
			var scenarioId = self.model.get("defaultScenario");
			var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
			DataManager.getDataManager().set(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING, true);
			var planScenario = self.model.creatPlanDefaultScenarioInstance(scenario.get("startTime"), scenarioStepData);
			planScenario.set("name", "custom "+new Date());
			DataManager.getDataManager().set(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING, false);
			var url = "/vdmbee/package/planscenarios";
			self.saveScenarioToBackend(url,planScenario,function(){
				var defaultExecutionScenaroId = planScenario.get("defaultExecutionScenario");
				var executionScenario = window.utils.getElementModel(defaultExecutionScenaroId,["transformation.ScenarioExecution"]);
				var executionUrl = "/vdmbee/package/scenarioexecutions";
				self.saveScenarioToBackend(executionUrl,executionScenario,function(){
					async.eachSeries(executionScenario.get("input").models, function(periodDataset, phaseHandled){
						//var periodDataset = window.utils.getElementModel(periodDatasetId,["transformation.PeriodDataset"]);
						var periodUrl = "/vdmbee/package/perioddatasets";
						self.saveScenarioToBackend(periodUrl,periodDataset,function(){
							phaseHandled();
						});
					},function(){
						bootbox.alert("Scenario created");
					});
				});
			});
			
		}

		saveScenarioToBackend(url,planScenario,callback){
			window.utils.startSpinner('saveSc', "Saving Scenario.. ");
			let plan = DataManager.getDataManager().get("currentPlan");
			var dataManager = DataManager.getDataManager();
			dataManager.get('vmpServerService').postAllPromise(url, JSON.stringify(planScenario)).then(function(response) {
				window.utils.stopSpinner('saveSc');
				callback();
			}).catch(error => {
				console.error('An error occurred:', error);
				window.utils.stopSpinner('saveSc');
				callback();
			});
		}
		
		afterRenderView(node, view){
            var self = view;

		};
	static getInstance(model,options){
		var view = new PlanValidation2ViewModel(model, options);
		view.init(model, options);
		return view;
	};
}
	path.PlanValidation2ViewModel = PlanValidation2ViewModel;