import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BaseMeasureRelationship} from './BaseMeasureRelationship'
import {BaseMeasureRelationshipMixin} from './BaseMeasureRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
//import { BinaryMeasure } from './BinaryMeasure'
//import { DimensionalMeasure } from './DimensionalMeasure'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/BaseMeasureRelationship","appbo/smm/BaseMeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BaseMeasureRelationship,BaseMeasureRelationshipMixin
, BinaryMeasure
, DimensionalMeasure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	//if(!BinaryMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.BinaryMeasure){
			BinaryMeasure = importPath.BinaryMeasure;
		}
        else {
            import('./BinaryMeasure').then(({ default: BinaryMeasure }) => {
                BinaryMeasure = BinaryMeasure;
            });

			/*require(["appbo/smm/BinaryMeasure"],function(loadedModule){
				BinaryMeasure = loadedModule;
			});*/
		}
	//}
	//if(!DimensionalMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasure){
			DimensionalMeasure = importPath.DimensionalMeasure;
		}
        else {
            import('./DimensionalMeasure').then(({ default: DimensionalMeasure }) => {
                DimensionalMeasure = DimensionalMeasure;
            });

			/*require(["appbo/smm/DimensionalMeasure"],function(loadedModule){
				DimensionalMeasure = loadedModule;
			});*/
		}
	//}
export class Base2MeasureRelationshipMixin {
    /*constructor() {
        var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
        path.Base2MeasureRelationshipMixin = Base2MeasureRelationshipMixin;
    }*/

	defaults(){
		var ret = {
			type: "smm_Base2MeasureRelationship"
		}
		return jQuery.extend(BaseMeasureRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"smm_Base2MeasureRelationship",
			key:"from",
			relatedModel:"smm.BinaryMeasure",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"baseMeasure2To",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		])
	}
	static getCumulativeMixinRelations(){
		if (!Base2MeasureRelationshipMixin.cummulativeRelations) {
            Base2MeasureRelationshipMixin.cummulativeRelations = _.union(Base2MeasureRelationshipMixin.getMixinRelations()
                , BaseMeasureRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return Base2MeasureRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_BaseMeasureRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "influence",type : "Influence",defaultValue : "null",containingClass : "smm_MeasureRelationship" }
		]
	}
	getParent(){
		var container;
		return BaseMeasureRelationship.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return BaseMeasureRelationship.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/Base2MeasureRelationshipPropertiesTemplate.html",
			templateName : "Base2MeasureRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/Base2MeasureRelationshipViewModel",
			tabId : "Base2MeasureRelationshipView",
			tabName: "Base2MeasureRelationship"
		}
	}
	
	}
	path.Base2MeasureRelationshipMixin = Base2MeasureRelationshipMixin;
	//return Base2MeasureRelationshipMixin;
//});