import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../bootbox/bootbox'
import * as async from 'async'
import * as EcoNavigatedViewer from '../../../libs/bower-vdml-js/dist/vdml-navigated-viewer.min'
import * as Highcharts from 'highcharts';
import DynamicClass from '../../../app/DynamicClass'
import domtoimage from '../../../libs/dom-to-image/dom-to-image' 

(function (factory) {
  /* global define */
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node/CommonJS
    module.exports = factory(require('jquery'));
  } else {
    // Browser globals
    factory(window.jQuery);
  }
}(function (jQuery) {

  // Extends plugins for adding importCanvas.
  //  - plugin is external module for customizing.
  $.extend($.summernote.plugins, {
    /**
     * @param {Object} context - context object has status of editor.
     */
    'importCanvas': function (context) {
      var self = this;
	  self.canvasTitle = "Business Canvas";
	  if(context.options && context.options.viewModel){
	  	self.canvasTitle = context.options.viewModel.labels['BusinessCanvas']();
		self.linkModels = context.options.viewModel.labels['linkModels']();
	  }
      // ui has renders to build ui elements.
      //  - you can create a button with `ui.button`
      var ui = $.summernote.ui;

      // add importCanvas button
      context.memo('button.importCanvas', function () {
        // create button
        self.context = arguments[0];
        var button = ui.button({
            contents: '<i class="glyphicon glyphicon-copy"/> ',//+ self.linkModels,
            tooltip: '' + self.linkModels,
            click: function () {
		    
          	/*window.require(["async","jquery","backbone","knockback","bootbox",
			"appcommon/com/vbee/data/DataManager","appbo/canvas/BMCanvasDiagram","appbo/canvas/BMCanvasDiagramMixin","appbo/dashboard/DashboardMixin",
			"appbo/vdml/EcoMapDiagram", "appbo/vdml/EcoMapDiagramMixin","appbo/vdml/ValueStreamMixin",
			"appbo/beeppackage/BeepPackageMixin", "appcommon/com/vbee/utils/DateFormat","appbo/dashboard/Analytics", "appbo/beeppackage/CodeContainer",
                    "econavigatedviewer","highcharts"],
            function (async, $, Backbone, kb, bootbox,DataManager,BMCanvasDiagram,BMCanvasDiagramMixin, DashboardMixin, 
			EcoMapDiagram, EcoMapDiagramMixin, ValueStreamMixin, BeepPackageMixin, Analytics,CodeContainer,
                EcoNavigatedViewer, highcharts){*/
				var DataManager = Backbone.Relational.store.getObjectByName("com.vbee.data.DataManager");
                var CodeContainer = Backbone.Relational.store.getObjectByName("beeppackage.CodeContainer");
                var BMCanvasDiagram = Backbone.Relational.store.getObjectByName("canvas.BMCanvasDiagram");
                var BMCanvasDiagramMixin = Backbone.Relational.store.getObjectByName("canvas.BMCanvasDiagramMixin");
                var DashboardMixin = Backbone.Relational.store.getObjectByName("dashboard.DashboardMixin");
                var EcoMapDiagram = Backbone.Relational.store.getObjectByName("vdml.EcoMapDiagram");
                var EcoMapDiagramMixin = Backbone.Relational.store.getObjectByName("vdml.EcoMapDiagramMixin");
                var ValueStreamMixin = Backbone.Relational.store.getObjectByName("vdml.ValueStreamMixin");
                var BeepPackageMixin = Backbone.Relational.store.getObjectByName("beeppackage.BeepPackageMixin");
                var DateFormat = Backbone.Relational.store.getObjectByName("com.vbee.utils.DateFormat");
                var Analytics = Backbone.Relational.store.getObjectByName("dashboard.Analytics");
                var dataManager = DataManager.getDataManager();
				var workspace = dataManager.get('currentWorkspace');
				var plan = dataManager.get('currentPlan');
				var ret = [];
				
				function loadCapData(capCallback){
					var packages =[]; 
					var planId = DataManager.getDataManager().get('currentPlan').id;
                    var commonAltId = planId.substr(planId.lastIndexOf('@')) + "-Common@";
                    DataManager.getDataManager().getAllDocumentsCollectionOfType(commonAltId, 'vdml_ValueDeliveryModel', function (result) {
                       if (result && result.length > 0) {
                           for(var i=0;i<result.length;i++){
                                if (result.models[i].get('id').indexOf("Common") > -1) {
                                    if(result.models[i].get('name') != "Common Package"){
                                         packages.push(result.models[i]);
									}
								}
						   };
						   async.eachSeries(packages,function(pack,ecoCallback){
					           var repId = dataManager.getRepositoryId(pack.id);
	                           var vdmStore = dataManager.getVDMStore(repId);
						       DataManager.getDataManager().fetchDocumentFromPackage(pack.get('id'), 'appbo/vdml/ValueDeliveryModel', pack.get('version'),pack.get('id'), 'appbo/vdml/ValueDeliveryModel', vdmStore, {
                                    success: function (model) {
										ecoCallback();	
									},
                               });
					       },function(err){
						      capCallback(packages);
					       })
					   }
					});
					
				}
				function loadEcoMap(capData,callback){
                    var packageList = [];
					var phases = dataManager.get('currentPlan').get('phase').models;
					for(var i =0;i<phases.length;i++){
						var designModels = phases[i].get('phaseAlternative').models[0].get('phaseDesignPart').models;
						for(var j =0;j<designModels.length;j++){
						    if((designModels[j].get('beepType') == "ecomap_EcoMap" || designModels[j].get('beepType') == "dashboard_Analytics")&&  _.filter(packageList, function (pack) { if (pack.id === designModels[j].get('beepReference')){return pack} })){
							    packageList.push({version:designModels[j].get('version'),id:designModels[j].get('beepReference'),type:designModels[j].get('beepType')});
							}
						}
						if(packageList.length == 0){
						  break;
						}
					}
					var dashboardPackages = [];
					async.eachSeries(packageList,function(pack,ecoCallback){
					   var repId = dataManager.getRepositoryId(pack.id);
	                   var vdmStore = dataManager.getVDMStore(repId);
					   if(pack.type == "ecomap_EcoMap"){
					     dataManager.fetchDocumentFromPackage(pack.id,"appbo/ecomap/EcoMap",pack.version,pack.id,"appbo/ecomap/EcoMap",vdmStore,{
						    success:function(model){
							     ecoCallback();					  
						    },
							error:function(error){
								console.log(error);
								ecoCallback();
							}
					     });
					   }else if(pack.type == "dashboard_Analytics"){
					        dataManager.fetchDocumentFromPackage(pack.id,"appbo/dashboard/Analytics",pack.version,pack.id,"appbo/dashboard/Analytics",vdmStore,{
						       success:function(model){
								  dashboardPackages.push(model);
							      ecoCallback();					  
						       },
							   error:function(error){
								   console.log(error);
								   ecoCallback();
							   }
					        });
					   }
					},function(err){
						callback(plan);
					})
				}
				
				function getModelDataInJSTreeFormat(capData,imageList){
				   var jsonData = {};
			       var ret = [];
				   ret.push(jsonData);
				   var plan =dataManager.get('currentPlan');
			       jsonData.id = htmlEscape(plan.get('id'));
			       jsonData.text = plan.get('name');
			       jsonData.children = addPhase(plan.get('phase'));
				   jsonData.capLibs = addCapLibs(capData);
				   ret[0].icon = "img/object.png";
			       return ret;
				}
	            function htmlEscape(str) {
    		       return String(str)
                   .replace(/@/g, '')
                   .replace(/ /g, '');
		        }   
				function addPhase(modelList){
					for(var i=0;i<modelList.length;i++){
						ret.push({text:modelList.at(i).get('name'), icon:"img/object.png",id:modelList.at(i).id});
						ret[i].children = addAlternative(modelList.at(i));
					}
					return ret;
				}
				function addAlternative(model){
				     var alt =[];
				     for(var i=0;i< model.get('phaseAlternative').length;i++){
					    alt.push({text:model.get('phaseAlternative').at(i).get('name'), icon:"img/object.png",id:model.get('phaseAlternative').at(i).id});
						alt[i].children =  addChildren(model.get('phaseAlternative').at(i));
					 }
					 return alt;
				}
				function addChildren(children){ 
					var child = [];var canvasCount = 0;var ecoCount =0;var dashBoardCount =0;
					for(var i =0;i < children.get('phaseDesignPart').length;i++){
					    if(children.get('phaseDesignPart').models[i].get('beepType') == "vdml_ValueDeliveryModel" && children.get('phaseDesignPart').models[i].get('name') != "commonScenario"){
						    var mypackage = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find(children.get('phaseDesignPart').models[i].get('beepReference'));
							if(mypackage && mypackage.get('canvas').length > 0 && mypackage.get('name')!= "Common Package"){
							  if(canvasCount == 0){
							     child.push({text:DataManager.getDataManager().get('localeManager').get('BusinessCanvases'),id:children.get('id')+'canvas'});
								 canvasCount++;
							  }
							  for(var j=0;j<child.length;j++){
							    if(child[j].id === children.get('id')+'canvas'){
								  if(!child[j].children){
								      child[j].children = addSubChilds(mypackage,children.get('phaseDesignPart').models[i].get('id'));
							      }else{
								      child[j].children = addSubChilds(mypackage,children.get('phaseDesignPart').models[i].get('id'),child[j].children);
								  }
							    }
							  }
							}
						}else if(children.get('phaseDesignPart').models[i].get('beepType') == "ecomap_EcoMap"){
							var ecoPackage = Backbone.Relational.store.getObjectByName("ecomap.EcoMap").find(children.get('phaseDesignPart').models[i].get('beepReference'));
							if(ecoCount == 0){
							   child.push({text:DataManager.getDataManager().get('localeManager').get('EcoMaps'), id: children.get('phaseDesignPart').models[i].get('id')+'EcoMap'});
							   child.push({text: DataManager.getDataManager().get('localeManager').get('StrategyMaps'), id: children.get('phaseDesignPart').models[i].get('id')+'StrategyMap'});
							   child.push({text: DataManager.getDataManager().get('localeManager').get('ValueStreamMaps'), id: children.get('phaseDesignPart').models[i].get('id')+'ValueStreamMap'});
							   ecoCount++;
							}
							for(var j=0;j<child.length;j++){
								if(child[j].id != children.get('id')+'canvas' && child[j].id != children.get('id')+'dashboard'){
									if(!child[j].children){
										child[j].children = addSubEcoChilds(child[j],ecoPackage,children.get('phaseDesignPart').models[i].get('id'))
									}else{
										child[j].children = addSubEcoChilds(child[j],ecoPackage,children.get('phaseDesignPart').models[i].get('id'))
									}
								}
							}
						}else if(children.get('phaseDesignPart').models[i].get('beepType') == "dashboard_Analytics"){
							var dashboardPackage = Backbone.Relational.store.getObjectByName('dashboard.Analytics').find(children.get('phaseDesignPart').models[i].get('beepReference'));
							if(dashboardPackage && dashboardPackage.get('name') != "Common Package"){
								if(dashBoardCount == 0){ 
							       child.push({text:DataManager.getDataManager().get('localeManager').get('Dashboards'),id:children.get('id')+'dashboard'});
							       dashBoardCount++;
							    }
								for(var b=0;b<child.length;b++){
								  if(child[b].id == children.get('id')+'dashboard'){
									 if(!child[b].children){ 
									    child[b].children = addDashBoards(dashboardPackage.get('dashboard'), children.get('phaseDesignPart').models[i].get('id'));
								     }else{
									    child[b].children = addDashBoards(dashboardPackage.get('dashboard'), children.get('phaseDesignPart').models[i].get('id'),child[b].children);
									 }
								  }
								}
							} 
						}
					}
					return child;
				}
				function addSubChilds(mypackage,canvasId,child){
					    var diagram = [];
						for(var i =0;i<mypackage.get('canvas').length;i++){
							if(!child){
							    diagram.push({text:mypackage.get('canvas').models[i].get('name'),icon:"img/leaf.png",id:canvasId+mypackage.get('canvas').models[i].get('id'),originalId:mypackage.get('canvas').models[i].get('id'),
							    type:mypackage.get('canvas').models[i].get('type')})
							}else{
							    child.push({text:mypackage.get('canvas').models[i].get('name'),icon:"img/leaf.png",id:canvasId+mypackage.get('canvas').models[i].get('id'),originalId:mypackage.get('canvas').models[i].get('id'),
							    type:mypackage.get('canvas').models[i].get('type')})
							}
						}
						if(!child){
							return diagram;
						}
						return child;
				}
				function addSubEcoChilds(child,ecoPackage,ecoId){
					    var eco =[];var icon = "img/leaf.png";
						for(var i=0;i<ecoPackage.get('diagrams').length;i++){
							if(!child.children){
								if(ecoPackage.get('diagrams').models[i].get('type') == "vdml_StrategyMapDiagram" && child.id === ecoId+'StrategyMap'){
									eco.push({text:ecoPackage.get('diagrams').models[i].get('name'),icon:icon,id:ecoId+ecoPackage.get('diagrams').models[i].id,originalId:ecoPackage.get('diagrams').models[i].id,type:ecoPackage.get('diagrams').models[i].get('type')})
								}else if(ecoPackage.get('diagrams').models[i].get('type') == "vdml_EcoMapDiagram" && child.id === ecoId+'EcoMap'){
									eco.push({text:ecoPackage.get('diagrams').models[i].get('name'),icon:icon,id:ecoId+ecoPackage.get('diagrams').models[i].id,originalId:ecoPackage.get('diagrams').models[i].id,type:ecoPackage.get('diagrams').models[i].get('type')})
								}else if(child.id === ecoId+'ValueStreamMap' && ecoPackage.get('diagrams').models[i].get('type') == "vdml_ValueStreamMapDiagram"){
									eco.push({text:ecoPackage.get('diagrams').models[i].get('name'),icon:icon,id:ecoId+ecoPackage.get('diagrams').models[i].id,originalId:ecoPackage.get('diagrams').models[i].id,type:ecoPackage.get('diagrams').models[i].get('type')})
								}
							}else{
							    if(ecoPackage.get('diagrams').models[i].get('type') == "vdml_StrategyMapDiagram" && child.text === "Strategy Maps"){
									child.children.push({text:ecoPackage.get('diagrams').models[i].get('name'),icon:icon,id:ecoId+ecoPackage.get('diagrams').models[i].id,originalId:ecoPackage.get('diagrams').models[i].id,type:ecoPackage.get('diagrams').models[i].get('type')})
								}else if(ecoPackage.get('diagrams').models[i].get('type') == "vdml_EcoMapDiagram" && child.text === "Eco Maps"){
									child.children.push({text:ecoPackage.get('diagrams').models[i].get('name'),icon:icon,id:ecoId+ecoPackage.get('diagrams').models[i].id,originalId:ecoPackage.get('diagrams').models[i].id,type:ecoPackage.get('diagrams').models[i].get('type')})
								}else if(child.text === "Value Stream Maps" && ecoPackage.get('diagrams').models[i].get('type') == "vdml_ValueStreamMapDiagram"){
									child.children.push({text:ecoPackage.get('diagrams').models[i].get('name'),icon:icon,id:ecoId+ecoPackage.get('diagrams').models[i].id,originalId:ecoPackage.get('diagrams').models[i].id,type:ecoPackage.get('diagrams').models[i].get('type')})
								}
							}
						}
						if(!child.children){
							return eco;
						}
						return child.children;
				}
				function addCapLibs(capData){
				    for(var i =0;i<capData.length;i++){
						var capModels = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({id:capData[i].id})
						if(capModels && capModels.get('diagrams').length > 0){
						   ret.push({text:capModels.get('name'),id:capModels.id,icon:"img/object.png",type:capModels.get('type')});
						   for(var a =0;a<ret.length;a++){
							  if(ret[a].id == capModels.id){
								ret[a].children = addCapMaps(capModels);
							  }
						   }
					    }
					}
					return ret;
				}
				function addCapMaps(capMaps){
				    var maps=[];
					for(var i=0;i<capMaps.get('diagrams').length;i++){
					   maps.push({text:capMaps.get('diagrams').models[i].get('name'),id:capMaps.get('diagrams').models[i].id,icon:"img/Small/icons-28.png",type:capMaps.get('diagrams').models[i].get('type')})
					}
					return maps;
				}
				function addDashBoards(boardPackage, dashId, child){
					    var dashBoards =[];
						for(var i =0;i<boardPackage.length;i++){
						   if(!child){
						      dashBoards.push({text:boardPackage.models[i].get('name'),id:dashId+boardPackage.models[i].id,originalId:boardPackage.models[i].id});
						      for(var a=0;a<dashBoards.length;a++){
						   		   if(dashBoards[a].id === dashId+boardPackage.models[i].id){
							   		   dashBoards[a].children = addBoards(boardPackage.models[i].get('presentation'),dashId);
								   }
							  }
						   }else{
						       child.push({text:boardPackage.models[i].get('name'),id:dashId+boardPackage.models[i].id,originalId:boardPackage.models[i].id,icon:"img/leaf.png"});
							   for(var a=0;a<child.length;a++){
						   		   if(child[a].id === dashId+boardPackage.models[i].id){
							   		   child[a].children = addBoards(boardPackage.models[i].get('presentation'),dashId);
								   }
							   }
						   }
						}
						if(!child){
						       return dashBoards;
						}
					    return child;
				}
				function addBoards(boards,dashId){
					var boardsList =[];
					for(var j=0;j<boards.length;j++){
					    var presentation = Backbone.Relational.store.getObjectByName('dashboard.Presentation').find({id:boards.models[j].id});
						if(presentation.get('presentationType') == "Chart/Table"){
						   boardsList.push({text:presentation.get('name'),id:dashId+presentation.id,originalId:presentation.id,type:presentation.get('type'),icon:"img/leaf.png"});
					    }
					}
					return boardsList;
				}
				function dashboardViewModel(diagramInstance){
					var dashboardModel;
					var dashboard = diagramInstance.get('analytics').get('dashboard').models;
					for(var i =0;i<dashboard.length;i++){
						var present = dashboard[i].get('presentation').models;
						var dashModel = _.find(present, function(model){ return model.id == diagramInstance.id; });
						if(dashModel){
							dashboardModel = dashboard[i];
							break;
						}
					}
					return dashboardModel;
				}
				function templateView(diagramInstance,selectedAlternative,id,selectedType){
                    var viewProperties, version;
                    var textNode1 = $("<div class='test' id='bind" + id + "'></div>")[0];
                    $('#summernote').summernote('insertNode', textNode1);
					if(diagramInstance.get('type') == 'dashboard_Presentation'){
					   viewProperties = DashboardMixin.getDialogViewProperties('presentation',diagramInstance);
					   version = "1";
					}else{
						viewProperties = BMCanvasDiagramMixin.getDialogViewProperties('CanvasDesigner',diagramInstance);
						version = diagramInstance?diagramInstance.getNestedParent().getVersion() :"1";
					}
					var modalContentNode = $('#editDetailsModalContent'+id);
					var newtemplatePath = "js/app/version" + version + "/" + viewProperties.templatePath;
					var appContentNode = $("#bind"+id);
					function createModal(viewModel){
						$.get(newtemplatePath, function(templates) {
                            appContentNode.append('<div style=\"display:none\">' + templates + '<\/div>');
                            var bindNode = $("<div data-bind=\"template: { name:\'" + viewProperties.templateName + "\', data: " + viewProperties.tabId + "Model , afterRender: afterRenderView}\"></div>");
							appContentNode.append(bindNode);var options,node;
							if(diagramInstance.get('type') == 'dashboard_Presentation'){
							  var dashModel = dashboardViewModel(diagramInstance);
							  options = {'parentView':self.context.options.viewModel,'tableId':id,'dashboardModel':dashModel,'viewAlt':selectedAlternative.id};
							}else{
							   options = {'parentView':self.context.options.viewModel,'viewAlt':selectedAlternative.id};
							}
							var viewInstance = viewModel.getInstance(diagramInstance,options);
                            var bindId = 'bind'+id;
							kb.applyBindings(viewInstance, document.getElementById(bindId));
							if(diagramInstance.get('type') == 'dashboard_Presentation'){
							  node = document.getElementById(bindId);
							}else{
							   node = document.getElementById('canvasDesigner'+htmlEscape(diagramInstance.id));
							   domtoimage.toPng(node)
                                   .then(function (dataUrl) {
                                       $('#summernote').summernote('editor.insertImage', dataUrl, function ($image) {
														$image.attr('diagramId',diagramInstance.id);
														$image.attr('customId',id);diagramInstance.customId = id;
														$image.attr('type',diagramInstance.get('type'));
														$image.attr('version',diagramInstance.get('version'));
														$image.attr('alternativeId',selectedAlternative.id);
														//$image.attr('lastUpdateTime',new Date().getTime());
														$image.attr('parentId',diagramInstance.get('canvasOwner').id);
														$image.attr('data-filename', 'retriever');
														window.cleanViewModel(viewInstance,$("#bind"+id),true);
														window.utils.stopSpinner('importing');
												});
									   })
                                   .catch(function (error) {
                                       window.cleanViewModel(viewInstance, $("#bind" + id), true);
										   self.console.error('oops, something went wrong!', error);
									   });
							}
							if(diagramInstance.get('type') == "dashboard_Presentation"){
								viewInstance.initializePresentation(node,function(){
									setTimeout(function(){
										if(viewInstance.selectedChartType() !=  "Table"){
										   domtoimage.toPng(node)
                                               .then(function (dataUrl) {
                                                   $('#summernote').summernote('editor.insertImage', dataUrl, function ($image) {
															$image.attr('diagramId',diagramInstance.id);
															$image.attr('customId',id);diagramInstance.customId = id;
															$image.attr('type',diagramInstance.get('type'));
															$image.attr('version',diagramInstance.get('version'));
															$image.attr('alternativeId',selectedAlternative.id);
															//$image.attr('lastUpdateTime',new Date().getTime());
															$image.attr('parentId',diagramInstance.get('analytics').get('id'));
															$image.attr('data-filename', 'retriever');
															var TableID = 'chartDuplicate_' + diagramInstance.id + id;
											                $("#"+bindId).next().append("<div id = \"" + TableID + "\"><p></p></div>");
															window.cleanViewModel(viewInstance,$("#bind"+id),true);
													});
										   })
										   .catch(function (error) {
											   window.cleanViewModel(viewInstance,$("#bind"+id),true);
											   self.console.error('oops, something went wrong!', error);
										   });
										}else{
											/*var dId = diagramInstance.id;
											var version = diagramInstance.get('version');
											var parenId = diagramInstance.get('analytics').get('id');
											var alterId = selectedAlternative.id;*/
											var TableID = 'chartDuplicate_' + diagramInstance.id + id; 
                                            //$("#" + bindId).next().append("<div id = \"" + TableID + "\"><img src ='' diagramId=\"" + dId + "\"  customId=\"" + id + "\" parentId=\"" + parenId + "\" version=\"" + version + "\" type=\"" + selectedType + "\" alternativeId=\"" + alterId + "\"></img></div>");
                                            $("#" + bindId).next().append("<div id=\"" + TableID + "\"></div>");
                                            //set kbd tag for header rows
                                            var theaders = $('#table' + id).find('th');
                                            theaders[0].style.textAlign = 'center';
                                            for (var i = 1; i < theaders.length; i++) {
                                                if (theaders[i].innerHTML.includes('span')) {
                                                    theaders[i].innerHTML = theaders[i].innerHTML.replace('span', 'kbd style="color : black"');
                                                }
                                            }
                                            //set kbd tag for even rows
                                            var evenTR = $('#table' + id).find('tr.even');
                                            self.addKbdTag(evenTR);
                                            //set kbd tag for odd rows
                                            var oddTR = $('#table' + id).find('tr.odd');
                                            self.addKbdTag(oddTR);
                                            document.getElementById('table' + id).removeAttribute('class');
                                            document.getElementById('table' + id).setAttribute('class', 'table table-bordered');
                                            $('#table' + id).find('thead').css('background', 'rgba(178, 228, 189, 0.35');
                                            document.getElementById(TableID).innerHTML = document.getElementById(TableID).innerHTML + document.getElementById('table' + id + '_wrapper').innerHTML;
                                            window.cleanViewModel(viewInstance, $('#' + bindId), true);
                                            $("#table" + id).colResizable({ liveDrag: true });
                                        }
										window.utils.stopSpinner('importing');
									},1200);
								});
							}
						});
					}
					//var regConfiguration = dataManager.get("require1");
					//regConfiguration([viewProperties.viewTypeStr],createModal);
					var className = viewProperties.viewTypeStr.substring(viewProperties.viewTypeStr.lastIndexOf('/')+1);
					createModal(DynamicClass.getView(className));
				}
				var htmlContent='<input type="text"  placeholder="Search" id="treeSearch" value="" class="input " style="padding:1px; border-radius:4px; border:1px solid silver;float:right">'
				htmlContent =  htmlContent+"<div id='jstree_div' class=\"row\"></div>";
				
                var box = bootbox.dialog({
		                title: "<div ><img src='img/Small/icons-20.png'>&nbsp;&nbsp;&nbsp;Business Model Selector</div>",
		                message: htmlContent,
						show: false,
		                buttons: {
		                	main: {
						      label: "Close",
						      className: "btn btn-default",
						    },
		                    success: {
                                label: '<i id="linkModalCompleteBtn" class="fa fa-check"></i> Complete',
		                        className: "btn btn-complete",
                                callback: function () {
                                    var selectedCan = $('.jstree-clicked').attr('id');
									window.utils.startSpinner('importing', 'Importing...');
									if(selectedCan){
                                        var id = htmlEscape(window.guidGenerator()) + "designer";
                                        selectedCan = $('#jstree_div').jstree("get_selected", true)[0].original.originalId;
                                        var selectedType = $('#jstree_div').jstree("get_selected", true)[0].original.type.replace('_', '.');
                                        if (!selectedCan && selectedType == "vdml.CapabilityLibraryDiagram" || selectedType == "beeppackage.CodeContainer") {
                                            selectedCan = $('#jstree_div').jstree("get_selected", true)[0].original.id;
									  }
									  var diagramInstance = Backbone.Relational.store.getObjectByName(selectedType).find({id:selectedCan});
                                        var selectedAlternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: $('#jstree_div').jstree("get_selected",true)[0].parents[1]});
									  if(selectedType == "dashboard.Presentation"){
                                          selectedAlternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: $('#jstree_div').jstree("get_selected",true)[0].parents[2]});
                                          var textNode = $("<div>Name: <kbd class= 'custText' contenteditable='false' Tag = 'name' id = " + id + " originalId =  \"" + diagramInstance.id + "\" type = \"" + selectedType + "\" altId = \"" + selectedAlternative.id + "\" parentId = \"" + diagramInstance.get('analytics').get('id') + "\">" + diagramInstance.get('name') + "</kbd>&nbsp;<br/><br/>"  
                                              + "Phase/Alternative: <kbd class= 'custText' contenteditable='false' Tag = 'name' id = " + id + " originalId =  \"" + $('#jstree_div').jstree("get_selected",true)[0].parents[3] + "\" type = 'transformation.Phase' altId = \"" + selectedAlternative.id + "\" parentId = \""+selectedAlternative.get('phaseAlternativeOwner').get('phaseOwner').id+"\">" + selectedAlternative.get('phaseAlternativeOwner').get('name') +"</kbd> / <kbd class= 'custText' contenteditable='false' id = " + id + " Tag = 'name' originalId =  \"" + selectedAlternative.id + "\" type = 'transformation.Alternative' altid = \"" +selectedAlternative.id+"\" parentId = \""+selectedAlternative.get('phaseAlternativeOwner').id+"\">" + selectedAlternative.get('name')+"</kbd>&nbsp;<br/><br/>" 
										  + "</div>")[0];
									  }
									  else if(selectedType == "vdml.CapabilityLibraryDiagram"){
                                          var textNode = $("<div>Name: <kbd class= 'custText' Tag = 'name' contenteditable='false' id = " + id + " originalId =  \"" + diagramInstance.id + "\" type = \"" + selectedType + "\"  parentId = \"" + diagramInstance.getNestedParent().id + "\">" + diagramInstance.get('name') + "</kbd>&nbsp;<br/><br/>"
										 + "</div>")[0];
									  }
									  else if(selectedType == "beeppackage.CodeContainer"){
                                          var textNode = $("<img id = "+id+" src=''>")[0];
									  }
									  else{
                                          var textNode = $("<div>Name: <kbd class= 'custText' contenteditable='false' id = " + id + " originalId =  \"" + diagramInstance.id + "\" type = \"" + selectedType + "\" altId = \"" + selectedAlternative.id + "\" Tag = 'name' parentId = \"" + diagramInstance.getNestedParent().id + "\">" + diagramInstance.get('name') + "</kbd>&nbsp;<br/><br/>"  
                                              + "Phase/Alternative: <kbd class= 'custText' contenteditable='false' id = " + id + " originalId =  \"" + $('#jstree_div').jstree("get_selected",true)[0].parents[2] + "\" type = 'transformation.Phase' altId = \"" + selectedAlternative.id + "\"  Tag = 'name' parentId = \""+selectedAlternative.get('phaseAlternativeOwner').get('phaseOwner').id+"\">" + selectedAlternative.get('phaseAlternativeOwner').get('name') +"</kbd> / <kbd class= 'custText' contenteditable='false' id = " + id + " originalId =  \"" + selectedAlternative.id + "\" type = 'transformation.Alternative' Tag = 'name' parentId = \""+selectedAlternative.get('phaseAlternativeOwner').id+"\">" + selectedAlternative.get('name')+"</kbd>&nbsp;<br/><br/>" 
										+ "</div>")[0];
									  }
                                      $('#summernote').summernote('restoreRange');
                                      $('#summernote').summernote('insertNode', textNode);
                                      textNode = null;

									  if(selectedType == "canvas.BMCanvasDiagram"){
									     templateView(diagramInstance,selectedAlternative,id,selectedType);
									  } else if(selectedType == "dashboard.Presentation"){
									     templateView(diagramInstance,selectedAlternative,id,selectedType);
									  }
									  else if(selectedType == "beeppackage.CodeContainer"){
									        if (selectedCan) {
												CodeContainer.getBlobInstanceWithId(selectedCan, DataManager.getDataManager().get('currentPlan'), function (codeContainer) {
													if (codeContainer) {
                                                        $('#'+id).show();
                                                        $('#'+id).attr("src", "" + codeContainer.get('code'));
                                                        $('#'+id).attr("type", selectedType);
                                                        $('#'+id).attr("diagramid",selectedCan);
                                                        $('#'+id).attr("id",id);
													} else {
                                                        $('#'+id).remove();
                                                        $('#'+id).attr("src", "");
													}
													window.utils.stopSpinner('importing');
												});
												return;
											} 
									  }
									  else {
											var className = 'canvas';
											var type;
									        if(selectedType == "vdml.EcoMapDiagram"){
											     className = 'canvas';
												 type= 'vdml_EcoMapDiagram'
											}else if(selectedType == "vdml.ValueStreamMapDiagram"){
												className = 'ValueStreamMap';
												type= 'vdml_ValueStreamMapDiagram'
											}else if(selectedType == "vdml.StrategyMapDiagram"){
												className = "StrategyMap";
												type = 'vdml_StrategyMapDiagram'
											}else if(selectedType == "vdml.CapabilityLibraryDiagram"){
												className = "CapabilityDiagram";
												type = "vdml_CapabilityLibraryDiagram"
										    }

                                          var ecoMapNode = $("<div class=\'"+className+"\'></div>");
										    ecoMapNode.attr('diagramId', diagramInstance.id);
                                          $('#summernote').summernote('insertNode', ecoMapNode[0]);
										    
									   	    var container = $("."+className+"[diagramId='" + diagramInstance.id + "']");
											self.modeler = new EcoNavigatedViewer({
										        container: container,
												type: type
										    });
											var data = diagramInstance.get('data')
											if(!data){
											    data = window.utils.getXmlData(selectedType, diagramInstance.get('id'));
											}
											openDiagram(data, self.modeler, container, diagramInstance);

											function openDiagram(xml, modeler, container, diagramInstance) {
												async function myDisplay() {
													let result = new Promise(function(myResolve, myReject) {
													  myResolve("Works");
													});
													try {
														result = await self.modeler.importXML(xml);
														var { warnings } = result;
														console.log(warnings);
														self.modeler.get("canvas").zoom("fit-viewport", true);
														//setTimeout(function () {
														//	async function myDisplay1() {
														const { svg } = await self.modeler.saveSVG();
														if (svg) {
															var blobSVG = new Blob([svg], { type: "image/svg+xml;charset=utf-8" });
															var domURL = URL || webkitURL || self;
															var dataUrl = domURL.createObjectURL(blobSVG);
															$('#summernote').summernote('editor.insertImage', dataUrl, function ($image) {
																self.modeler.destroy();
																container.remove();
																var xhr = new XMLHttpRequest(); // for converting svg into png
																xhr.responseType = 'blob';
																xhr.onload = function () {
																	var fileReader = new FileReader();
																	fileReader.onload = function (evt) {
																		var result = evt.target.result;
																		$image[0].src = result;
																		var isAlt = selectedAlternative ? selectedAlternative.id : "";
																		var imgWidth = "" + $image[0].width + "px";
																		$image.css({ display: 'block', margin: '0 auto', width: imgWidth });
																		$image.attr('diagramId', diagramInstance.id);
																		$image.attr('customId', id);
																		$image.attr('type', diagramInstance.get('type'));
																		$image.attr('version', diagramInstance.get('version'));
																		$image.attr('alternativeId', isAlt);
																		//$image.attr('lastUpdateTime',new Date().getTime());
																		$image.attr('parentId', diagramInstance.getNestedParent().id);
																		$image.attr('data-filename', 'retriever');
																		setTimeout(function () {
																			var canvas = document.createElement("canvas");
																			var oldImages = $("img[diagramid = \"" + diagramInstance.id + "\"]");
																			canvas.setAttribute("id", "deletecanvas");
																			_.each(oldImages, function (oldImage) {
																				var oldImgQuery = $(oldImage);
																				if (oldImgQuery.attr('customId') === id) {
																					canvas.width = oldImage.width;
																					canvas.height = oldImage.height;
																					canvas.getContext('2d').drawImage(oldImage, 0, 0);
																					var dt = canvas.toDataURL('image/png');
																					oldImgQuery.attr('src', dt);
																					$("#deletecanvas").remove();
																					return false;
																				}
																			});
																		}, 1000);
																	};
																	fileReader.readAsDataURL(xhr.response);
																}
																xhr.open('GET', $image[0].src, true);
																xhr.send();
															});
														}
													//}
													//myDisplay1();
													//},800);
													  } catch (err) {
														console.log(err.message, err.warnings);
													  }
												}
												myDisplay();
                                            }
                                            window.utils.stopSpinner('importing');
                                        }
                                    }
                                }
                            }
                        }
                    });    
                box.modal('show'); $('.btn-complete').attr('disabled','disabled');
                $('#jstree_div').on('hover_node.jstree',function(e,data){
				     var toolTip = DataManager.getDataManager().get('localeManager').get('ValueStreamMap');
				     if(data.node.original.type && data.node.original.type == "vdml_EcoMapDiagram"){
					 	 toolTip = DataManager.getDataManager().get('localeManager').get('BusinessEcosystemMap');
					 }else if(data.node.original.type && data.node.original.type == "vdml_StrategyMapDiagram"){
					 	 toolTip  = DataManager.getDataManager().get('localeManager').get('StrategyMap');
					 }else if(data.node.original.type && data.node.original.type == "canvas_BMCanvasDiagram"){
					 	 toolTip = DataManager.getDataManager().get('localeManager').get('BusinessCanvas');
					 }else if(data.node.original.type && data.node.original.type == "vdml_CapabilityLibraryDiagram"){
					 	 toolTip = DataManager.getDataManager().get('localeManager').get('CapabilityMap');
					 }else if(data.node.original.type && data.node.original.type == "dashboard_Presentation"){
					 	 toolTip = DataManager.getDataManager().get('localeManager').get('Dashboard');
					 }
					 data.instance.element.prop('title',toolTip);
					 if(!data.node.original.type){
					 	 data.instance.element.removeAttr('title',toolTip)
					 }
                });
                $('#treeSearch').change(function () {
				   window.utils.startSpinner('jsonSearch','Searching...');
				   setTimeout(function(){
                       var v = $('#treeSearch').val();
                       $('#jstree_div').jstree(true).search(v);
                       window.utils.applyFontSize($('#content'));
					  window.utils.stopSpinner('jsonSearch');
					  $('.jstree-anchor').css('text-overflow','ellipsis');
					  $('.jstree-anchor').css('overflow','hidden');
					  $('.jstree-anchor').css('max-width','97%');
				   },10);
			    });
				var tree = $('#jstree_div').on("changed.jstree", function (e, data) {
				if(data.selected.length) {
					var selectedNode = data.instance.get_node(data.selected[0]);
                    if ((!selectedNode.original.type && selectedNode.original.id) || selectedNode.children.length != 0) {
                        $('.btn-complete').attr('disabled', 'disabled');
                    } else {
                        $('.btn-complete').removeAttr('disabled', 'disabled');
					}
				}
			}).jstree({ 'core' : {
							'check_callback': true,
						    'data' : function (obj,callback) {
            					callback.call(this, []);
        					}
						 },
						 "plugins": ["search"],
						"search" : {
				              'case_sensitive' : false
				         }
                    });
                    $('#summernote').summernote('saveRange');
					loadCapData(function(capData){
						loadEcoMap(capData,function(data){
                            var model = getModelDataInJSTreeFormat(capData)
                            $('#jstree_div').jstree(true).create_node(null, model[0]);
						})
					})
					 
			//});
            
          }
        });
		
        // create jQuery object from button instance.
        var $importCanvas = button.render();
        return $importCanvas;
        });

        this.addKbdTag = function (tableRow) {
            for (var i = 0; i < tableRow.length; i++) {
                for (var j = 0; j < tableRow[i].childNodes.length; j++) {
                    if (tableRow[i].childNodes[j].innerHTML.includes('<span')) {
                        tableRow[i].childNodes[j].innerHTML = tableRow[i].childNodes[j].innerHTML.replace('<span', '<kbd style="color : black"');
                    } else if (tableRow[i].childNodes[j].innerHTML.includes('</span>')) {
                        tableRow[i].childNodes[j].innerHTML = tableRow[i].childNodes[j].innerHTML.replace('</span>', '</kbd>');
                    }
                }
            }
        }

      // This events will be attached when editor is initialized.
      this.events = {
        // This will be called after modules are initialized.
        'summernote.init': function (we, e) {
          //console.log('summernote initialized', we, e);
        },
        // This will be called when user releases a key on editable.
        'summernote.keyup': function (we, e) {
          //console.log('summernote keyup', we, e);
        }
      };

      // This method will be called when editor is initialized by $('..').summernote();
      // You can create elements for plugin
      this.initialize = function () {
      };

      // This methods will be called when editor is destroyed by $('..').summernote('destroy');
      // You should remove elements on `initialize`.
      this.destroy = function () {
      };
    }
  });
}));
