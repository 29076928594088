import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmElement} from './SmmElement'
import { Measure } from './Measure'
import {ObservedMeasureMixin} from './ObservedMeasureMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Observation } from './Observation'
import { Measurement } from './Measurement'


/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/ObservedMeasureMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,ObservedMeasureMixin
, Observation
, Measurement
, Measure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Observation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Observation){
			Observation = importPath.Observation;
		}
        else {
            import('./Observation').then(({ default: Observation }) => {
                Observation = Observation;
            });
           /* require(["appbo/smm/Observation"], function (loadedModule) {
                Observation = loadedModule;
			});*/
		}
	}
	if(!Measurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measurement){
			Measurement = importPath.Measurement;
		}
        else {
            import('./Measurement').then(({ default: Measurement }) => {
                Measurement = Measurement;
            });
            /*require(["appbo/smm/Measurement"], function (loadedModule) {
                Measurement = loadedModule;
			});*/
		}
	}
	if(!Measure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measure){
			Measure = importPath.Measure;
		}
        else {
            import('./Measure').then(({ default: Measure }) => {
                Measure = Measure;
            });
           /* require(["appbo/smm/Measure"], function (loadedModule) {
                Measure = loadedModule;
			}); */
		}
	}
	var ObservedMeasure = SmmElement.extend(utils.customExtends({
		relations:ObservedMeasureMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			SmmElement.prototype.initialize.apply(this, arguments);
			//this.on('destroy',onObservedMeasureDestroyed);			
		}
		}
		, new ObservedMeasureMixin()
	));
    ObservedMeasure['destroy'] = function onObservedMeasureDestroyed(model){
		if(Backbone.skipPersistance){
			return;
		}
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(suppressChangeLogging) {
			return;
		}		
		var oldBaseMeasure = model.previousAttributes().measure;
		if(!oldBaseMeasure){
			return;
		}
		var oldBaseMeasurement = model.previousAttributes().measurements.at(0);
		if(!oldBaseMeasurement){
			return;
		}
		var mc = oldBaseMeasurement.get('measuredCharacteristic');
		if(!mc){
			return;
		}
		var oldBaseComponent = mc.getMeasurand();
		var parentMesRelations = oldBaseMeasure.get('baseMeasureFrom');
		var parentMestRelations  = oldBaseMeasurement.get('baseNMeasurementRelationship');
		if(!parentMestRelations){
			return;
		}
		var mestRel;
		var mesRel;
		for(var i=0;i<parentMesRelations.length;i++){
			var parentMesRel = parentMesRelations.at(i);
			if(parentMesRel.get('usePreviousAlternative')){
				var parentMeasure  = parentMesRel.get('from');
				for(var j=0;j<parentMestRelations.length;j++){
					var parentMestRel = parentMestRelations.at(j);
					var parentMest = parentMestRel.get('from');
					if(parentMest.get('observedMeasure').get('measure') === parentMeasure){
						mestRel = parentMestRel;
						mesRel = parentMesRel;
						break
					}
				}
			}
			if(mestRel){
				break;
			}
		}
		if(mestRel){
			if(!DataManager.getDataManager().deletedMesRelDetails){
				DataManager.getDataManager().deletedMesRelDetails = {};
			}
			DataManager.getDataManager().deletedMesRelDetails[mesRel.id] = {baseMeasurement:oldBaseMeasurement,baseMeasure:oldBaseMeasure,baseComponent:oldBaseComponent,measureRelation:mesRel,measurementRel:mestRel};
		}
	}	
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	ObservedMeasure.abstract = false;
	ObservedMeasure.supported = !sp2Enabled;
	path.ObservedMeasure = ObservedMeasure;
	export {ObservedMeasure};
//});