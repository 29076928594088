import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasure} from './DimensionalMeasure'
import {BinaryMeasureMixin} from './BinaryMeasureMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { Base1MeasureRelationship } from './Base1MeasureRelationship'
import { Base2MeasureRelationship } from './Base2MeasureRelationship'
import { Operation } from './Operation'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasure","appbo/smm/BinaryMeasureMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasure,BinaryMeasureMixin
, Base1MeasureRelationship
, Base2MeasureRelationship
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Base1MeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Base1MeasureRelationship){
			Base1MeasureRelationship = importPath.Base1MeasureRelationship;
		}
        else {
            import('./Base1MeasureRelationship').then(({ default: Base1MeasureRelationship }) => {
                Base1Base1MeasureRelationship = Base1MeasureRelationship;
            });

			/*require(["appbo/smm/Base1MeasureRelationship"],function(loadedModule){
				Base1MeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!Base2MeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Base2MeasureRelationship){
			Base2MeasureRelationship = importPath.Base2MeasureRelationship;
		}
        else {
            import('./Base2MeasureRelationship').then(({ default: Base2MeasureRelationship }) => {
                Base2Base1MeasureRelationship = Base2MeasureRelationship;
            });

			/*require(["appbo/smm/Base2MeasureRelationship"],function(loadedModule){
				Base2MeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
        else {
            import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });

			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	var BinaryMeasure = DimensionalMeasure.extend(utils.customExtends({
		relations:BinaryMeasureMixin.getMixinRelations(),
		subModelTypes: {
			smm_RatioMeasure : 'smm.RatioMeasure'
		},
		initialize: function(attributes, options) {
			DimensionalMeasure.prototype.initialize.apply(this, arguments);
			if(attributes.scale){
				this.set('scale',path.ScaleOfMeasurement[attributes.scale instanceof Object ? attributes.scale.name : attributes.scale]);
			}
			if(attributes.functor){
				this.set('functor',path.BinaryFunctor[attributes.functor instanceof Object ? attributes.functor.name : attributes.functor]);
			}
		}
		}
		, new BinaryMeasureMixin()
	));
	
	BinaryMeasure.abstract = false;
	BinaryMeasure.supported = false;	//As we are not using it yet
	path.BinaryMeasure = BinaryMeasure;
	export {BinaryMeasure};
//});