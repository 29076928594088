import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BusinessModelItem} from './BusinessModelItem'
import {BusinessModelCompetenceMixin} from './BusinessModelCompetenceMixin'
import {Capability} from './Capability'
import {Competence} from './Competence'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/BusinessModelItem","appbo/vdml/BusinessModelCompetenceMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BusinessModelItem,BusinessModelCompetenceMixin
, Capability
, Competence){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Capability){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Capability){
			Capability = importPath.Capability;
		}
		else{
			import('./Capability').then(({ default: Capability }) => {
                Capability = Capability;
            });
			/*require(["appbo/vdml/Capability"],function(loadedModule){
				Capability = loadedModule;
			});*/
		}
	}
	if(!Competence){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Competence){
			Competence = importPath.Competence;
		}
		else{
			import('./Competence').then(({ default: Competence }) => {
                Competence = Competence;
            });
			/*require(["appbo/vdml/Competence"],function(loadedModule){
				Competence = loadedModule;
			});*/
		}
	}
	var BusinessModelCompetence = BusinessModelItem.extend(utils.customExtends({
		relations:BusinessModelCompetenceMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BusinessModelItem.prototype.initialize.apply(this, arguments);
			this.on("change:competence", this.checkEmptyCompetence);
		}
		}
		, new BusinessModelCompetenceMixin()
	));
    BusinessModelCompetence["change:competence"] = function (model, newCompetence) {
        this.checkEmptyCompetence(model, newCompetence);
    }
	BusinessModelCompetence.abstract = false;
	path.BusinessModelCompetence = BusinessModelCompetence;
	export {BusinessModelCompetence};
//});