import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { Role } from './Role'
import { PositionMixin } from './PositionMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Pool } from './Pool'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Role","appbo/vdml/PositionMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Role,PositionMixin
, Pool){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Pool){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Pool){
			Pool = importPath.Pool;
		}
        else {
            import('./Pool').then(({ default: Pool }) => {
                Pool = Pool;
            });
			/*require(["appbo/vdml/Pool"],function(loadedModule){
				Pool = loadedModule;
			});*/
		}
	}
	var Position = Role.extend(utils.customExtends({
		relations:PositionMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Role.prototype.initialize.apply(this, arguments);
		}
		}
		, new PositionMixin()
	));
	
	Position.abstract = false;
	path.Position = Position;
    export { Position };
//});