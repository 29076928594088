import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'

//define(["require", "jquery", "underscore", "backbone", "Lawnchair", "knockout", "knockback", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global"],
   // function (require, $, _, Backbone, Lawnchair, ko, kb, backboneLawnchair, DataManager, global) {

var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties", global.version);
export class ValueExpressionDetailsViewModel {             
            

            calculateExpr() {
                self.parentView.expression(self.expression());
            };
            cleanModal() {
                var self = this;
                self.parentView = null;
                window.cleanDialogModel(self.encodeId, this);
                };
            saveExpressionDetails() {
                self.parentView.expression(self.expression());
                self.parentView = null;
                window.cleanDialogModel(self.encodeId, this);
            };
            
            afterRenderView() { 
                $("#formula" + self.encodeId).keyup(function (view) {
                    var exprValue = view.target.value;
                    if (exprValue) {
                        self.expression(exprValue);
                    }
                });
            }
           
init(model, options){
    var self = this;
    this.ValueExpressionDetailsViewModel = this;
    this.model = model;
    model = null;
    this.options = options;
    options = null;
    function htmlEscape(str) {
        return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
    }
    if (self.options && self.options.parentView) {
        self.parentView = self.options.parentView;
    }
    this.encodeId = "" + self.options.modalId;
    this.enableExpr = ko.observable(false);
    this.toggleEditOfExpression = function () {
        if (self.parentView.aggregateColl().length === 0) {
            self.enableExpr(!this.enableExpr());
        }
    };
    this.expression = ko.observable(self.parentView.expression());
    this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), ['general'
        , 'version'
        , 'name'
        , 'description'
        , 'type'
        , 'Close'
        , 'Complete'
        , 'expressionDetails'
        , 'expression'
        , 'Cancel'
    ]);

}
       
        static getInstance = function (model, options) {
            var view = new ValueExpressionDetailsViewModel(model, options);
            view.init(model, options);
            return view;
        };
}
path.ValueExpressionDetailsViewModel = ValueExpressionDetailsViewModel;