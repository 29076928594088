import { Grid, Paper, Typography,Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Presentation from "./Presentation";
import './PresentationWrapper.scss'

function PresentationWrapper({ node, arr, parentId }) {
  const [nodeElement, setNodeElement] = useState(node);
  const [containerName,setContainerName] = useState(node.name)

  function handleReceivedData(id){
    if(id){
      const data = {...window.vdmModelView.hashTreeData.get(id)};
      if(data!==undefined){
        setNodeElement(data);
      }
    }
  };

  function handleContainerName(id) {
    if(id){
      const data = {...window.vdmModelView.hashTreeData.get(id)};
      if(data!==undefined){
        setContainerName(data.name);
      }
    }
  };

  useEffect(()=>{
    setNodeElement(node);
    setContainerName(node.name);
    window.vdmModelView.eventBus.on(`${node?.uId}changeHeight`, handleReceivedData);
    window.vdmModelView.eventBus.on(`${node?.uId}editContainerName`, handleContainerName);
    window.vdmModelView.eventBus.on(`${node?.uId}addPresentation`, handleReceivedData);
    window.vdmModelView.eventBus.on(`${node?.uId}deleteContainer`, handleReceivedData);
    window.vdmModelView.eventBus.on(`${node?.uId}changeWidth`, handleReceivedData);
    window.vdmModelView.eventBus.on(`${node?.uId}changeBorder`, handleReceivedData);
    window.vdmModelView.eventBus.on(`${node?.uId}changeType`, handleReceivedData);
    window.vdmModelView.eventBus.on(`${node?.uId}addChild`, handleReceivedData);
    window.vdmModelView.eventBus.on(`${node?.uId}move`, handleReceivedData);
  },[node]);

  useEffect(()=>{
    if(nodeElement!==null){
      window.vdmModelView.eventBus.on(`${nodeElement?.uId}addPresentation`, handleReceivedData);
      window.vdmModelView.eventBus.on(`${nodeElement?.uId}changeType`, handleReceivedData);
      window.vdmModelView.eventBus.on(`${nodeElement?.uId}changeHeight`, handleReceivedData);
      window.vdmModelView.eventBus.on(`${nodeElement?.uId}editContainerName`, handleContainerName);
      window.vdmModelView.eventBus.on(`${nodeElement?.uId}changeWidth`, handleReceivedData);
      window.vdmModelView.eventBus.on(`${nodeElement?.uId}changeBorder`, handleReceivedData);
      window.vdmModelView.eventBus.on(`${nodeElement?.uId}addChild`, handleReceivedData);
      window.vdmModelView.eventBus.on(`${nodeElement?.uId}deleteContainer`, handleReceivedData);
      window.vdmModelView.eventBus.on(`${nodeElement?.uId}move`, handleReceivedData);
    }    
  },[nodeElement]);
 

  function getHeight(nodes, targetNodeId) {
    let height = null;
    nodes.forEach((node) => {
      if(node.id!=="root"){
        if (node?.id === targetNodeId) {
          height = node.h;
        } else if (node.subRows && node.subRows.length > 0) {
          const subRowHeight = getHeight(node.subRows, targetNodeId);
          if (subRowHeight !== null) {
            height = subRowHeight;
          }
        } 
      }
    });
    return height;
  }

  let width = 0;

  if(nodeElement){
    var bindingId = window.utils.htmlEscape(nodeElement?.id);
    width = nodeElement?.pId !== "root" ? width + nodeElement.w : nodeElement.w;
    if (width > 100 && nodeElement?.pId !== "root") {
      width = 100 - width;
    } else {
      width = +nodeElement.w > 0 && +nodeElement.w;
    }    
  }

    
    return (
      <>
        {nodeElement!==null && nodeElement?.type === 0 && (
          <Grid
            item
            xs={12 / (100 / +width)}
            sx={{maxHeight:getHeight(arr,nodeElement?.pId)?`${getHeight(arr,nodeElement?.pId)+16}px !important`:"100%",
            overflowY:"auto",
            overflowX:"hidden",
            position:"relative",
            width:"100% !important",
            paddingLeft: nodeElement.b && node.pId==="root" ? "auto": "0 !important"
            }}
            // md={index === 0 && 12 / (100 / +width)}
          >
            <Box
              className="container"
              sx={{
                boxShadow: nodeElement?.b ? "rgba(0, 0, 0, 0.24) 0px 3px 8px" : "",
                marginBottom: node.b && "10px",
                width:nodeElement?.b?"98.5% !important":"100% !important",
                padding:nodeElement?.b ? "30px 0" : "0",
              }}
              id={bindingId}
            >
              {containerName !== "" && (
                <Typography className="containerName">{containerName}</Typography>
              )}
              <Grid
                container
                sx={{width:"100%", marginLeft:"0 !important",maxHeight:getHeight(arr,nodeElement?.pId)?`${getHeight(arr,nodeElement?.pId)+16}px !important`:"100%"}}
                columnSpacing={2}
              >
                {nodeElement?.subRows &&
                  nodeElement?.subRows?.length > 0 &&
                  nodeElement?.subRows.map((subNode, index) => {
                  return(
                    <PresentationWrapper
                      node={subNode}
                      arr={arr}
                      key={subNode?.uId}
                    />
                )})}
              </Grid>
            </Box>
          </Grid>
        )}

        {nodeElement!== null && nodeElement?.type === 1 && (
          <Grid
            item
            xs={12 / (100 / +width)}
            // lg={index === 0 && 12 / (100 / +width)}
            sx={{
              paddingLeft:"10px !important",
              paddingRight:"10px !important",
              maxWidth: "100%",
              minWidth: "50px",
              height: getHeight(arr,nodeElement?.pId)?`${getHeight(arr,nodeElement?.pId)}px !important`:"100%",
              display: "flex",
              marginBottom:"8px",
              marginTop:"8px",
              flex: 1,
            }}
          >
            {nodeElement?.id !== "" ? (
              <Box
                sx={{
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark" ? "#101010" : "#fff",
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                  minHeight:getHeight(arr,nodeElement?.pId)?`${getHeight(arr,nodeElement?.pId)}px !important`:"100%",
                  height:getHeight(arr,nodeElement?.pId)?`${getHeight(arr,nodeElement?.pId)}px !important`:"100%",
                  borderRadius: 2,
                  p:1,
                  overflow:"hidden",
                  paddingBottom: "0 !important",
                  width: "100% !important",
                }}
                id={`presWrapper${window.utils.htmlEscape(node.id)}`}
                className="item-content gridView"
              >
                  <Presentation
                    node={nodeElement}
                  />
              </Box>
            ) : (
              <div></div>
            )}
          </Grid>
        )}
      </>
    );
  }


  export default PresentationWrapper