import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import { ValueType } from '../../../../bo/vdml/ValueType'

var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties",global.version);
    //var importPath;
    export class PlanValueDetails2ViewModel {
            constructor(model,options){
                var self = this;
                this.init(model, options);
            }
            dispose() {
                var self = this;
                window.cleanViewModel(self);
            };
            fetchValueforAlt(period,year,alternative) {
                var self = this;
                var planCriterionSet = self.model.get('planCriterionSet');
                var planComponents = planCriterionSet ? planCriterionSet.get('component') : null;
                var mainScenario = alternative.getOrCreatePhaseObjectiveSet();
                if (planComponents) {
                    var defaultExecutionScenaroId = mainScenario.get("defaultExecutionScenario");
                    var defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
                    var periodDataset = defaultExecutionScenario.get("input").findWhere({period: period, periodKind : self.model.get("periodKind"), year:year});
                    var calcPeriodDataset = defaultExecutionScenario.get("result").findWhere({period: period, periodKind : self.model.get("periodKind"), year:year});
                    
                    planComponents.each(function (component) {
                        var displayValue = ' ';
                        var isCollective = false;
                        var smileySrc = '';
                        var smileyColor = '';
                        var correctiveActions = '';
                        if (mainScenario) {
                            //var mest = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(component.get('valueMeasurement'));
                            var value = periodDataset?periodDataset.get(component.id):null;
                            if(component.get("valueType") != ValueType.Atomic){
                                isCollective = true;
                                value = calcPeriodDataset?calcPeriodDataset.get(component.id):null;
                            }
                            if (value) {
                                displayValue = value;
                                var unit = component.get('unit');
                                var significantDecimals = unit ? unit.get('significantDecimals') : 2;
                                displayValue = '' + window.utils.getRoundedValues(displayValue, significantDecimals);
                                if (unit) {
                                    displayValue = displayValue.concat(' ' + unit.get('name'));
                                }
                                var satisfactionLevel = component.get('satisfactionLevel');
                                var contextModel = satisfactionLevel ? satisfactionLevel.getValueContext(alternative.id) : null;
                                if(contextModel){
                                    var expressionConfig = contextModel.get('formula') ? contextModel.get('formula').get('expressionConfig') : null;
                                    if(expressionConfig){
                                        var intervals = JSON.parse(expressionConfig);
                                        var qty = calcPeriodDataset ? calcPeriodDataset.get(satisfactionLevel.id): "";
                                        const resultInterval = intervals.filter(item => item.level === qty);
                                        if (resultInterval.length > 0) {
                                            smileySrc = self.fetchSmileySrc(resultInterval[0].smiley);
                                            smileyColor = resultInterval[0].color;
                                            correctiveActions = resultInterval[0].actions ? resultInterval[0].actions : "";
                                        }
                                    }
                                }
                            }
                            else {
                                var aggFrom = component.get('aggregatedFrom');
                                for (var j = 0; j < aggFrom.length; j++) {
                                    var parent = aggFrom.at(j).getNestedParent();
                                    var repositoryId = DataManager.getDataManager().getRepositoryId(parent.get('id'))
                                    if (repositoryId === window.plansKey) {
                                        isCollective = true;
                                        break;
                                    }
                                    else if (alternative.get('phaseDesignPart').findWhere({ 'beepReference': parent.get('id') })) {
                                        isCollective = true;
                                        break;
                                    }
                                }
                                /*var satisfaction = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(component.get('satisfactionLevel'));
                                var compSatisfactionMeasure = satisfaction ? satisfaction.get('observedMeasure').get('measure') : null;
                                if (compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
                                    displayValue = '' + satisfaction.get('symbol');
                                }*/
                            }
                        }
                        self.valueList.push({ id: component.get('id'), escapeId: window.utils.htmlEscape(component.id), name: component.get('name'), value: displayValue, altId: alternative.get('id'), isCollective: isCollective, smileySrc: smileySrc, smileyColor: smileyColor, correctiveActions: correctiveActions});
                    });
                }
                self.sortValueList();
            };
            cleanUpHandler() {
                var self = this;
                self.valueList.removeAll();
            };
            /*getSatSelectedInterval(satisfactionMeasurement) {
                var self = this;
                var selectedInterval;
                var satType = 'symbol';
                var satMeasure = satisfactionMeasurement.get('observedMeasure').get('measure');
                if (satisfactionMeasurement.get('value') && satMeasure.get('unit')) {//Ranking
                    satType = 'value';
                }
                if (satMeasure) {
                    var intervals = satMeasure.get('interval');
                    for (var i = 0; i < intervals.length; i++) {
                        if (intervals.at(i).get('' + satType) === satisfactionMeasurement.get('' + satType)) {
                            selectedInterval = intervals.at(i);
                            break;
                        }
                    }
        
                }
                return selectedInterval;
            };*/
            fetchSmileySrc(smileyValue) {
                var self = this;
                var smileySrc = '';
                for (var j = 0; j < self.svgSmiliesList.length; j++) {
                    if (self.svgSmiliesList[j].value === smileyValue) {
                        smileySrc = self.svgSmiliesList[j].src;
                        break;
                    }
                }
                return smileySrc;
            };
            initialize(planModel,period,year,currentAlternativeModel) {
                var self = this;
                if (!self.model) {
                    self.model = planModel;
                }
                self.period = period;
                self.year = year;
                self.valueList([]);
                self.fetchValueforAlt(period,year,currentAlternativeModel)
            };
            editValueDetailsHandler(view, event) {
                var self = this;
                var planCriterionSet = self.model.get('planCriterionSet');
                var planComponents = planCriterionSet.get('component');
                var modalEle = planComponents.findWhere({ 'id': view.id });
        
                DataManager.getDataManager().set('viewAlternative', view.altId);
                DataManager.getDataManager().getAlternative(DataManager.getDataManager().get('viewAlternative'), function (alternative) {
                    self.model.getValuesThatCanBeRefferedTo(function (values) {
                        var aggregatedColl = new Backbone.Collection(values);
                        //var currentPlan = DataManager.getDataManager().get('currentPlan');
                        var addOptions = { 'period':self.period, 'year':self.year, 'type': 'planValues', 'aggregatedColl': aggregatedColl, planValue: true, 'currentAlternativeModel': alternative};
                        //var BusinessModelMixin = Backbone.Relational.store.getObjectByName('vdml.BusinessModelMixin');
                        var BusinessModelMixin = DataManager.getDataManager().getMixinClass("BusinessModel","vdml");
                        window.getAndCreateModalDialog(self.model, window.utils.htmlEscape(modalEle.get('id')), BusinessModelMixin, modalEle, "ValueDetails", self.saveValue, addOptions);
                    });
                });
                self.sortValueList();
            };
            showAggregationViewHandler(view) {
                var self = this;
                var alternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: view.altId });
                alternative.showAggregationView(view.id, function(){
                    window.utils.startSpinner('refreshValues', 'Refreshing ...');
                    DataManager.getDataManager().invokeValueUpdates(function () {
                        if (self.parentView) {
                            self.parentView.refreshWidgetData(true);
                            window.utils.stopSpinner('refreshValues');
                        }
                    });
                });        
            };    
            correctiveActions(view){
                var htmlContent = "<div class='row'><div class='col-xs-1'></div><div class='col-xs-10'><span class='control-label' style='word-wrap: break-word;'>"+view.correctiveActions+"</span></div></div>";
                var box = bootbox.dialog({
                    message: htmlContent,
                    title: "<div><img class='smallIcon' src='img/icons/icons_15.png'>&nbsp;&nbsp;&nbsp;Corrective Actions</div>",
                    buttons: {
                        cancel : {
                            label: "Close",
                            className : 'btn vtn-default',
                            callback : function(){
                                
                            }
                        }
                    }
                });
                box.show();
            };      
            /*isPlanValue(valueComp) {
                var self = this;
                var planCriterionSet = self.model.get('planCriterionSet');
                var comp = planCriterionSet.get('component').findWhere({ 'id': valueComp.get('id') });
                if (comp) {
                    return true;
                } else {
                    return false;
                }
            };*/
            saveValueHandler(view, modalId, type, del) {
                var self = this;
                window.utils.startSpinner('planValues', 'Saving Plan Value ...');
                window.setTimeout(function () { self.saveValueProposition(view, modalId, type, del); }, 100);
            };
            saveValueProposition(view, modalId, type, del) {
                var self = this;
                if (del) {
                    view.currentAlternativeModel.getOrCreatePhaseObjectiveSet().set('localChange', true);
                    view.model.get('componentOwner').deleteValue([view.id()]);
                    for (var i = 0; i < self.valueList().length; i++) {
                        if (self.valueList()[i].id === view.id()) {
                            self.valueList.remove(self.valueList()[i]);
                            break;
                        }
                    }
                    DataManager.getDataManager().invokeValueUpdates(function () {
                        //self.parentView.refreshWidgetData(true);
                        window.utils.stopSpinner('planValues');
                        window.cleanDialogModel(modalId, view);
                    }, true);
                }
                else {
                    var compAggtoLen = view.model.get('aggregatedTo').length;
                    view.saveData(self.model, view.model.get('componentOwner'), function (changeInValue) { 
                        if (changeInValue) {
                            view.currentAlternativeModel.getOrCreatePhaseObjectiveSet().set('localChange', true);
                        }
                        self.initialize(self.model,self.period,self.year,view.currentAlternativeModel);
                        window.utils.stopSpinner('planValues');
                        window.cleanDialogModel(modalId, view);
                        /*function refreshData(incrementUpdate){
                            if (view.structuralChangeCount > 0 || compAggtoLen != view.model.get('aggregatedTo').length || incrementUpdate) {
                                self.parentView.refreshWidgetData(true);
                            } else {
                                self.updateValues(view.model, view.model, view.model, updatedComps, view.currentAlternativeModel);
                                self.parentView.refreshWidgetData(true);
                                self.sortValueList();
                            }
                            window.utils.stopSpinner('planValues');
                            window.cleanDialogModel(modalId, view);
                        }*/
                    });
                }
            };
            sortValueList() {
                var self = this;
                self.valueList.sort(function (left, right) {
                    return left.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() == right.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ? 0 : (left.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() < right.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ? -1 : 1);
                });
            }
            /*addNewValueHandler(comp, alt) {
                var self = this;
                var plan = DataManager.getDataManager().get('currentPlan');
                var defaultScenario = DataManager.getDataManager().get('defaultScenario');
                var defaultExecutionScenaroId = defaultScenario.get("defaultExecutionScenario");
                var defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
                var periodDataset = defaultExecutionScenario.get("input").findWhere({period: self.period, periodKind : plan.get("periodKind"), year:self.year});
                var displayValue = periodDataset?periodDataset.get(comp.id):null;
                var isCollective = false;
                var smileySrc = '';
                var smileyColor = '';
                var correctiveActions = '';
                    if(comp.get("valueType") != ValueType.Atomic){
                        isCollective = true;
                    }
                    var satisfaction = mainScenario.getTSLessMeasurementReferringToMeasuredCharteristic(comp.get('satisfactionLevel'));
                    if (satisfaction && comp.getParent().get('overAllSatisfaction') !== comp) {
                        var selectedInterval = self.getSatSelectedInterval(satisfaction);
                        if (selectedInterval) {
                            smileySrc = self.fetchSmileySrc(selectedInterval.get('smiley'));
                            smileyColor = selectedInterval.get('smileyColor');
                            correctiveActions = selectedInterval.get('correctiveActions');
                        }
                    }
                var unit = comp.get('unit');
                var significantDecimals = unit ? unit.get('significantDecimals') : 2;
                displayValue = '' + window.utils.getRoundedValues(displayValue, significantDecimals);
                if (unit) {
                    displayValue = displayValue.concat(' ' + unit.get('name'));
                }
                var obj = { id: comp.get('id'),escapeId:window.utils.htmlEscape(comp.get('id')), name: comp.get('name'), value: displayValue, altId: alt.get('id'), isCollective: isCollective, smileySrc: smileySrc, smileyColor: smileyColor, correctiveActions: correctiveActions };
                self.valueList.push(obj);
                self.sortValueList();
            };*/
        init(model,options){
                var self = this;
                this.PlanValueDetails2ViewModel = this;
                this.parentView = window.vdmModelView;
                this.model = model;
                this.id = ko.observable(self.model ? self.model.get('id') : null);
                this.encodeId = window.utils.htmlEscape(this.id());
                this.valueList = ko.observableArray([]);
                this.selectedObservation = ko.observable();
                this.observationList = ko.observableArray([]);
                this.svgSmiliesList = window.utils.getSmiliesList();
        
                this.editValueDetails = _.bind(self.editValueDetailsHandler, self);
                this.showAggregationView = _.bind(self.showAggregationViewHandler, self);
                this.saveValue = _.bind(self.saveValueHandler, self);
                //this.addNewValue = _.bind(self.addNewValueHandler, self);
                this.cleanUp = _.bind(self.cleanUpHandler, self);
                    /*this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),[
                ]);*/               
            }
                
            static getInstance(model,options){
                var view = new PlanValueDetails2ViewModel(model,options);
                return view;
            };
        }
        path.PlanValueDetails2ViewModel = PlanValueDetails2ViewModel;