import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasureRelationship} from './MeasureRelationship'
import {RefinementMeasureRelationshipMixin} from './RefinementMeasureRelationshipMixin'
import {Measure} from './Measure'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasureRelationship","appbo/smm/RefinementMeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasureRelationship,RefinementMeasureRelationshipMixin
, Measure
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!Measure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measure){
			Measure = importPath.Measure;
		}
		else{
			import('./Measure').then(({ default: Measure }) => {
				Measure = loadedModule;
			});
			/*require(["appbo/smm/Measure"],function(loadedModule){
				Measure = loadedModule;
			});*/
		}
	}
	
	var RefinementMeasureRelationship = MeasureRelationship.extend(utils.customExtends({
		relations:RefinementMeasureRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			MeasureRelationship.prototype.initialize.apply(this, arguments);
			if(attributes.influence){
				this.set('influence',path.Influence[attributes.influence instanceof Object ? attributes.influence.name : attributes.influence]);
			}
		}
		}
		, new RefinementMeasureRelationshipMixin()
	));
	
	RefinementMeasureRelationship.abstract = false;
	RefinementMeasureRelationship.supported = false;
	path.RefinementMeasureRelationship = RefinementMeasureRelationship;
	export {RefinementMeasureRelationship};
//});