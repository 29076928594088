import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VDMLCanvasElementMixin} from './VDMLCanvasElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VDMLCanvasElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VDMLCanvasElementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var dataManager = DataManager.getDataManager();
	var VDMLCanvasElement = VdmlElement.extend(utils.customExtends({
		relations:VDMLCanvasElementMixin.getMixinRelations(),
		subModelTypes: {
			vdml_ValueProposition : 'vdml.ValueProposition',
			vdml_Activity : 'vdml.Activity',
			vdml_Participant : 'vdml.Participant',
			vdml_Competence : 'vdml.Competence',
			vdml_ValueElement : 'vdml.ValueElement'
		},
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new VDMLCanvasElementMixin()
	));
	path.VDMLCanvasElement = VDMLCanvasElement;
	export {VDMLCanvasElement};
//});