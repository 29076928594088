import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import { Role } from './Role'
import { PartyMixin } from './PartyMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Role","appbo/vdml/PartyMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Role,PartyMixin){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var Party = Role.extend(utils.customExtends({
		relations:PartyMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Role.prototype.initialize.apply(this, arguments);
		}
		}
		, new PartyMixin()
	));
	
	Party.abstract = false;
	path.Party = Party;
    export { Party };
//});