import * as $ from 'jquery';
import * as ko from 'knockout';
import * as _ from 'underscore';
import * as kb from 'knockback';
import { DataManager } from '../../../../../com/vbee/data/DataManager';
import * as bootbox from '../../../../../../libs/bootbox/bootbox';
import {UserMixin} from '../../../../bo/tickets/UserMixin';

var path = DataManager.getDataManager().buildAppNsPath("tickets.views.properties",global.version);

export class CoursesViewModel {

	afterRenderView(node,view) {
		var self = view;
		if(self.options.selectedId){
		   $('#CoursesTable').show();
		}
	};

	init(model, options) {
		var self = this;
		this.CoursesViewModel = this;
		this.model = model;
		this.options = options;
		this.modId = this.options.modalId
		this.prodId = ko.observable('');
		this.courses = ko.observableArray([]);
		this.navigate = _.bind(self.navigate, self);
		var dataManager = DataManager.getDataManager();
		var subscribed = dataManager.get('subscribed');
		subscribed.map((data) => {
			if(data.packageType === 14) {
				self.prodId = data.id;
			}
		})
		if(self.prodId !== undefined && self.prodId !== null) {
			dataManager.getMarketPlaceProductDetails(self.prodId, function(data){
				var courseArray = [];
				var obj;
				for (var i = 0; i < dataManager.licenses.length; i++) {
					if (dataManager.licenses[i].ouuserId !== null) {
						obj = { name: data.productName, pur: data.subscribedToELearning };
						courseArray.push(obj)
						break;
					}
					
				}
				self.courses(courseArray)
			})
		} else {
			var courseArray = [];
			var obj = { name: "Strategyplanner Basics Course", pur: false };
			courseArray.push(obj);
			self.courses(courseArray);
		}
		
        this.labels = {
			Close: "Close"
		};
		
	}
	
	navigate(){
		var self = this;
		localStorage.setItem('marketplaceDetails', JSON.stringify({ details: true, marketplaceId: self.prodId }));
		DataManager.getDataManager().get('router').navigate("adminviews/" + "Market" , { trigger: true });
		window.cleanDialogModel(self.modId,self);
	}

	launch(){
		window.open(`http://localhost:8089/auth/oidc/`, "_blank");
	}

	showDemo(){
        var id = window.utils.htmlEscape(window.guidGenerator());
        var user;
        var options = { viewId: id , selectedId: 'demoCourse', 'width': '1000px' };
        window.getAndCreateModalDialog(false, id, UserMixin, user, "demoCourse", null, options);
    }
	
	cleanModal(){
		var self=this;
		window.cleanDialogModel(self.encodeId,self);
	};	  

    static getInstance(model, options) {
        const view = new CoursesViewModel(model, options);
		view.init(model, options);
        return view;
    }
}
path.CoursesViewModel = CoursesViewModel;