import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {EObject} from '../cmof/EObject'
import {ObjectReferenceMixin} from './ObjectReferenceMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/vdml/ObjectReferenceMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,ObjectReferenceMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var ObjectReference = EObject.extend(utils.customExtends({
		relations:ObjectReferenceMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			EObject.prototype.initialize.apply(this, arguments);
		}
		}
		, new ObjectReferenceMixin()
	));
	
	ObjectReference.abstract = false;
	ObjectReference.supported = false;
	path.ObjectReference = ObjectReference;
	export {ObjectReference};
//});