import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {NamedMeasurementMixin} from './NamedMeasurementMixin'
import {DimensionalMeasurement} from './DimensionalMeasurement'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasurement","appbo/smm/NamedMeasurementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasurement,NamedMeasurementMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	var NamedMeasurement = DimensionalMeasurement.extend(utils.customExtends({
		relations:NamedMeasurementMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			DimensionalMeasurement.prototype.initialize.apply(this, arguments);
		}
		}
		, new NamedMeasurementMixin()
	));
	
	NamedMeasurement.abstract = false;
	NamedMeasurement.supported = false;
	path.NamedMeasurement = NamedMeasurement;
	export {NamedMeasurement};
//});