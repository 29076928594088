import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Collaboration} from './Collaboration'
import {CollaborationMixin} from './CollaborationMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
//import { Member } from './Member'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Collaboration","appbo/vdml/CollaborationMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Collaboration,CollaborationMixin
, Member){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	//if(!Member){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Member){
			Member = importPath.Member;
		}
        else {
            import('./Member').then(({ default: Member }) => {
                Member = Member;
            });
		}
	//}
	export class CommunityMixin {

	defaults(){
		var ret = {
			type: "vdml_Community"
		}
		return jQuery.extend(Collaboration.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_Community",
			key:"member",
			relatedModel:"vdml.Member",
			reverseRelation: {
				key:"memberOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!CommunityMixin.cummulativeRelations) {
            CommunityMixin.cummulativeRelations = _.union(CommunityMixin.getMixinRelations()
                , CollaborationMixin.getCumulativeMixinRelations()
            );
        }
		return CommunityMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_Collaboration"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "progress", type: "double", defaultValue: "0", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		return Collaboration.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = "";
		}
		return Collaboration.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/CommunityPropertiesTemplate.html",
			templateName : "CommunityPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/CommunityViewModel",
			tabId : "CommunityView",
			tabName: "Community"
		}
	}
//#startCustomMethods

	getHierarchy(subCommunities){
		if(!subCommunities){
			subCommunities = new Backbone.Collection();		
		}
		//this.getHierarchy();
		var collRoles = this.get('collaborationRole');
		collRoles.each(function(member){
			var assignments = member.get('roleAssignment');
			assignments.each(function(assignment){
				var participant = assignment.get('participant');
				if(participant){
					var participantType = participant.get('type');
					if(participantType === "vdml_Community"){
						if(!subCommunities.get(participant.get('id'))){
							subCommunities.add(participant);
							participant.getHierarchy(subCommunities);
						}
					}else if(participantType != "vdml_OrgUnit"){
						subCommunities.add(participant);
					}
				}
			});
		});
		var actorParticipantsColl = this.get('collaborationOwner').get('actor');
		actorParticipantsColl.each(function(actorParticipant){
			subCommunities.add(actorParticipant);
		});
		return subCommunities;
	};
	getNestedParent(){
		return Collaboration.prototype.getNestedParent.apply(this, arguments);
	};
	getPackagePath(path){
		return Collaboration.prototype.getPackagePath.apply(this, arguments);
	}
	createDefaultRole(name){
		var roleId = DataManager.getDataManager().guidGeneratorByOwner(this);
		var role = new Member({id:roleId,name:this.getUniqueProperty('name',name ? name: "Business",'collaborationRole'),collaborationRoleOwner:this});
		return role;
	};	
	setCommunityNameChange(newName){
		var self = this;
        DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_BusinessModelCustomer-customer', 'vdml_BusinessModelCustomer', "vbc:" + "cmof_EObject-name", 
		function(bmCustomers){	
			bmCustomers.each(function(bmCustomer){
				if(bmCustomer.get('name') !== newName){
					bmCustomer.set('name',newName);
				}
			});
			renameNetworkPartners();
		}, true);
		
		function renameNetworkPartners(){
            DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_BusinessModelNetworkPartner-networkPartner', 'vdml_BusinessModelNetworkPartner', "vbc:" + "cmof_EObject-name", 			
			function(bmNetworkPartners){
				bmNetworkPartners.each(function(bmNetworkPartner){
					if(bmNetworkPartner.get('name') !== newName){
						bmNetworkPartner.set('name',newName);
					}
			   });
			}, true);
		}
	}

//#endCustomMethods
}
	path.CommunityMixin = CommunityMixin;
	
	//return CommunityMixin;
//});