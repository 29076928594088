import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {NamedPlanElementMixin} from './NamedPlanElementMixin'
import {NamedPlanElement} from './NamedPlanElement'
import {ValueProposition} from '../vdml/ValueProposition'
import {Observation} from '../smm/Observation'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/transformation/NamedPlanElement","appbo/transformation/NamedPlanElementMixin"],
//function(require,jQuery,_,async , Backbone,Lawnchair, backboneLawnchair,DataManager,global,NamedPlanElement,NamedPlanElementMixin
//, ValueProposition
//, Observation){
	
	if(!ValueProposition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueProposition){
			ValueProposition = importPath.ValueProposition;
		}
		else{
        import('../vdml/ValueProposition').then(loadedModule => {
                ValueProposition = loadedModule;
            });

			/*require(["appbo/vdml/ValueProposition"],function(loadedModule){
				ValueProposition = loadedModule;
			});*/
		}
	}
	if(!Observation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Observation){
			Observation = importPath.Observation;
		}
        else {
            import('../smm/Observation').then(loadedModule => {
                Observation = loadedModule;
            });

			/*require(["appbo/smm/Observation"],function(loadedModule){
				Observation = loadedModule;
			});*/
		}
	}
	var path = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
export class PhaseObjectiveSetMixin{

	defaults(){
		var ret = {
			type: "transformation_PhaseObjectiveSet"
		}
		return jQuery.extend(NamedPlanElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"transformation_PhaseObjectiveSet",
			key:"planObjectiveSet",
			relatedModel:"vdml.ValueProposition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"transformation_PhaseObjectiveSet",
			key:"objectiveObservation",
			relatedModel:"smm.Observation",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"phaseObjectiveSet",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}			
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!PhaseObjectiveSetMixin.cummulativeRelations) {
            PhaseObjectiveSetMixin.cummulativeRelations = _.union(PhaseObjectiveSetMixin.getMixinRelations()
                , NamedPlanElementMixin.getCumulativeMixinRelations()
            );
        }
		return PhaseObjectiveSetMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"transformation_NamedPlanElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "localChange",type : "EBoolean",defaultValue : false,containingClass : "transformation_PhaseObjectiveSet" }			
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("phaseObjectiveSetOwner") ? this.get("phaseObjectiveSetOwner") : this.previousAttributes().phaseObjectiveSetOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("phaseObjectiveSetOwner") ? this.get("phaseObjectiveSetOwner") : this.previousAttributes().phaseObjectiveSetOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/transformation/views/properties/PhaseObjectiveSetPropertiesTemplate.html",
			templateName : "PhaseObjectiveSetPropertiesTemplate",
			viewTypeStr : "appviews/transformation/views/properties/PhaseObjectiveSetViewModel",
			tabId : "PhaseObjectiveSetView",
			tabName: "PhaseObjectiveSet"
		}
	}
//#startCustomMethods
	getTimeLessObservation(observations,timeStamp){
		for(var i=0;i<observations.length;i++){
			var obs = observations.at(i);
			var whenObs = obs.get('whenObserved');
			if(whenObs == timeStamp){
				return obs;
			}
		}
	}
	getAllTimeLessObservations(observations,timeStamp){
		var ret = [];
		for(var i=0;i<observations.length;i++){
			var obs = observations.at(i);
			var whenObs = obs.get('whenObserved');
			if(whenObs == timeStamp){
				ret.push(obs);
			}
		}
		return ret;
	}
	disablePackagesUpdates(value,update){
		var self = this;
		if(update) {
			var phaseAlt = self.get('phaseObjectiveSetOwner');
			var designScenario = phaseAlt?phaseAlt.get('designScenario').models.concat():[];
			for(var i=0;i<designScenario.length;i++){
				if(DataManager.getDataManager().getRepositoryId(designScenario[i].get('scenario')) !== phaseAlt.get('id')){
					var scenario = Backbone.Relational.store.getObjectByName("vdml.Scenario").find({id:designScenario[i].get('scenario')});
					if(scenario){
						scenario.set('localChange',true);
					}
				}
			}
		}
		
	};
	
	copyData(targetObjecttiveSet,doImport,timestamp,importPackage,measurementsCopied){
		if(!measurementsCopied){
			var measurementsCopied = [];
		}
		if(targetObjecttiveSet.get('localChange') === true){
			return measurementsCopied;
		}
		var obs = this.getTimeLessObservation(this.get('objectiveObservation'));
		var metricsModel;
		if(doImport){
			metricsModel = importPackage;
		}else{
			if(obs){
				metricsModel = obs.get('smmModel');	
			}else{
				metricsModel = this.getNestedParent().createDefaultMetricModel();
			}
		}
		
		//var obsId = DataManager.getDataManager().guidGeneratorByOwner(metricsModel);
		var obsCopy;
		var possibleObs;
		if(!doImport){
			if(metricsModel){
				possibleObs = this.getAllTimeLessObservations(metricsModel.get('observations'));
				_.each(possibleObs,function(obs){
					if(obs.get('phaseObjectiveSet') === targetObjecttiveSet){
						obsCopy = obs;
					}
				});
			}
		}else{
			if(metricsModel){
				possibleObs = this.getTimeLessObservation(metricsModel.get('observations'),timestamp);
				_.each(possibleObs,function(obs){
					if(obs.get('phaseObjectiveSet') === targetObjecttiveSet){
						obsCopy = obs;
					}
				});	
			}
		}
		if(!obsCopy){
			var altName = targetObjecttiveSet.get('phaseObjectiveSetOwner').get('name');/*metricsModel.getUniqueProperty('name',altName + ' Scenario','observations')*/
			obsCopy = metricsModel.createObservation("Base Scenario", null, null, null);
			//obsCopy = new Observation({id:obsId,name:"Base Scenario",'snapshotId':window.uuidGenerator(),smmModel:metricsModel});	
			if(doImport){
				obsCopy.set('whenObserved',timestamp);
			}
			targetObjecttiveSet.get('objectiveObservation').add(obsCopy);
		}
		
		if(obs){
			var observedMeasures = obs.get('observedMeasures');
			var copiedObsMeasures = obsCopy.get('observedMeasures');
			var currentCopiedObsMeasures = copiedObsMeasures.models.concat();
			observedMeasures.each(function(obsMes){
					var copiedObsMes = copiedObsMeasures.findWhere({name:obsMes.get('name')});
					if(!copiedObsMes){
						obsMes.copyObservedMeasureExclusive(obsCopy,measurementsCopied);
					}
					if(copiedObsMes){
						var copiedMeasure = copiedObsMes.get('measure');
						if(copiedMeasure !== obsMes.get('measure')){
							copiedObsMes.destroy();
							obsMes.copyObservedMeasureExclusive(obsCopy,measurementsCopied);
						}else{
							if(copiedMeasure.get('type') === 'smm_DirectMeasure'){
								copiedObsMes.get('measurements').at(0).set('value',obsMes.get('measurements').at(0).get('value'));
							}
						}
					}
			});
			var delObsMes = [];
			_.each(currentCopiedObsMeasures,function(obsMes){
				var existingObsMes = observedMeasures.findWhere({name:obsMes.get('name')});
				if(!existingObsMes){
					delObsMes.push(obsMes);
				}
			});
			observedMeasures.remove(delObsMes);
		}
		return measurementsCopied;
	};
	getMeasurementsReferringToMeasuredCharteristic(mchar) {
	    var observatons = this.get('objectiveObservation');
	    var mests = [];
	    for (var i = 0; i < observatons.length; i++) {
	        var obs = observatons.at(i);
	        var obsMeasures = obs.get('observedMeasures');
	        for (var j = 0; j < obsMeasures.length; j++) {
	            var obsMes = obsMeasures.at(j);
	            var mest = obsMes.get('measurements').at(0);
	            if (mest.get('measuredCharacteristic') === mchar) {
	                mests.push(mest);
	                break;
	            }
	        }
	    }
	    return mests;
	}
	getTSLessMeasurementReferringToMeasuredCharteristic(mchar){
		var ret;
		var tsLessObservation = this.getTimeLessObservation(this.get('objectiveObservation'));
		if(tsLessObservation && mchar){
			var obsrMeasures = tsLessObservation.get('observedMeasures');
			obsrMeasures.each(function(obsMes){
				var measurements = obsMes.get('measurements');
				measurements.each(function(mest){
					if(mest.get('measuredCharacteristic') === mchar){
						ret = mest;
					}				
				})
			});
		}
		return ret;		 
	}
	hasMeasurements(measuredCharacteristic,withTimeStamp,timeStamp){
		if(this.getObservedMeasureWithMeasurements(measuredCharacteristic,withTimeStamp,timeStamp)){
			return true;
		}else{
			return false;
		}
	}
	getObservation(timestamp){
		var observations = this.get('objectiveObservation');
		var ret;
		observations.each(function(observation){
			if(!ret){
				var whenObserved = observation.get('whenObserved');
				if(((!timestamp || timestamp == "") && (!whenObserved || whenObserved === null || whenObserved === ""))
					|| (timestamp && (whenObserved === timestamp))){
					ret = observation;
				}
			}
		});
		return ret;
	};
	getAllObservedMeasureWithMeasurements(measuredCharacteristic) {
	    var observationToCheck;
	    var obsMeasures = [];
	    var observations = this.get('objectiveObservation');
	    observations.each(function (observation) {
	        var obsrMeasures = observation.get('observedMeasures');
	        for (var i = 0; i < obsrMeasures.length; i++) {
	            var obsMes = obsrMeasures.at(i);
	            var measurements = obsMes.get('measurements');
	            if (measurements.length > 0 && measurements.at(0).get('measuredCharacteristic') === measuredCharacteristic) {
	                obsMeasures.push(obsMes);
	            }
	        }
	    });

	    return obsMeasures;
	}
	getObservedMeasureWithMeasurements(measuredCharacteristic,withTimeStamp,timeStamp){
		var observationToCheck;
		var observations = this.get('objectiveObservation');
		var obsMesWithMeasurements;
		observations.each(function(observation){
			var whenObserved = observation.get('whenObserved');
			if((!withTimeStamp && (!whenObserved || whenObserved === null || whenObserved === ""))
				|| (withTimeStamp && (whenObserved === timeStamp))){
				observationToCheck = observation;
			}
		})
		if(observationToCheck){
			var obsrMeasures = observationToCheck.get('observedMeasures');
			for(var i=0;i<obsrMeasures.length;i++){
				var obsMes = obsrMeasures.at(i);
				var measurements = obsMes.get('measurements');
				if(measurements.length > 0 && measurements.at(0).get('measuredCharacteristic') === measuredCharacteristic){
					obsMesWithMeasurements = obsMes;
					break;
				}
			}
		    if(obsMesWithMeasurements){
		    	return obsMesWithMeasurements;
		    }
		}
		if(!obsMesWithMeasurements){
			var currentPlan = DataManager.getDataManager().get('currentPlan');
			if(currentPlan){
				if(DataManager.getDataManager().altScenarios){
					var planScenariosObj = DataManager.getDataManager().altScenarios[currentPlan.get('id')];
				}
			    if (!planScenariosObj) {
			        return;
			    }
				var currentAlt = planScenariosObj[this.get('phaseObjectiveSetOwner').get('id')];
				if(!currentAlt){
					return null;
				}
				var altPackages = _.keys(currentAlt);
				var observedMeasure;
				_.each(altPackages,function(packageId){
					var scenario = currentAlt[packageId];
					observedMeasure	= scenario.getObservedMeasureWithMeasurements(measuredCharacteristic,withTimeStamp,timeStamp);
				});
				/*var planScenarios = _.values(planScenariosObj);
				
				_.each(planScenarios,function(scenario){
					if(!observedMeasure && scenario[_.keys(scenario)[0]]){
						observedMeasure	= scenario[_.keys(scenario)[0]].getObservedMeasureWithMeasurements(measuredCharacteristic,withTimeStamp,timeStamp);				
					}
				});*/
				return observedMeasure;
			}
		}
		return null;		
	};
	
	createContextObservation(timestamp){
		var beepPackage = this.getNestedParent();
		if(!beepPackage){
			return;
		}
		var defaultMetricModel = beepPackage.get('planObservationContainer');
		if(!defaultMetricModel){
			defaultMetricModel = beepPackage.createDefaultMetricModel();
		}
		var observations = this.get('objectiveObservation');
		var ret;
		observations.each(function(obs){
			if(!ret){
				if(obs.get('whenObserved') == timestamp){
					ret = obs;
				}
			}
		})
		if(ret){
			return ret;
		}
		//var altName = this.get('phaseObjectiveSetOwner').get('name');
		var observation = defaultMetricModel.createObservation("Base Scenario",timestamp);
		this.get('objectiveObservation').add(observation);
		return observation;
	};

	createMeasurement(packageCurrent,valueComponent,measuredCharecterstic,measureLibrary,charToMatch,unitToMatch,valFormula,accToMatch,baseRelSetToMatch,satisfactionTuple,intervalTupleSet,timeStamp,nonDimensional,isOVS,viewAlternative){
		var self = this;
		var measurement;
		var characteristic = measuredCharecterstic.get('characteristicDefinition');
		if(!characteristic){
			characteristic = packageCurrent.linkOrCreateAndLinkCharacteristic(charToMatch,measuredCharecterstic);
		}
		var measure = measureLibrary.findOrCreateMeasure(charToMatch,unitToMatch,valFormula,accToMatch,baseRelSetToMatch,satisfactionTuple,intervalTupleSet,nonDimensional);
		var currentObservation;
		function getCurrentObservation(scenario){
			var contextObservation;
			if(scenario.get('type') === "transformation_PhaseObjectiveSet"){
				contextObservation = scenario.get('objectiveObservation');
			}else{
				contextObservation = scenario.get('contextObservation');
			}
			contextObservation.each(function(observation){
				if(observation.get('whenObserved') == timeStamp){	// using == to equal null and undefined
					currentObservation = observation;
				}
			});
			if(!currentObservation){
				currentObservation = self.createContextObservation(timeStamp);
			}
			return currentObservation;
		}
		currentObservation = getCurrentObservation(this);
		
		var observedMeasure = currentObservation.createObservedMeasure(currentObservation.getUniqueProperty('name',"Observed " + measure.get('name'),'observedMeasures'));
		var name = observedMeasure.getUniqueProperty('name',charToMatch + " Measurement",'measurements');
		observedMeasure.set('measure',measure);
		observedMeasure.set('lastChange',new Date().getTime());
			if(!satisfactionTuple){
				if(baseRelSetToMatch && baseRelSetToMatch.length === 0){
					measurement = observedMeasure.createDirectMeasurement(name,name);
				}else{
					measurement = observedMeasure.createCollectiveMeasurement(name,name);
					if(measure.get('type') === "smm_CollectiveMeasure"){
/*						var components = valueComponent.get('aggregatedFrom');
						components.each(function(component){
							var compScenario = viewAlternative.getMainScenario(component.getNestedParent());
							var compObservation = getCurrentObservation(compScenario);							
							var observedMeasure = compObservation.get('observedMeasures');
							var count = 1;
							observedMeasure.each(function(obsMes){
								var oldmeasurement = obsMes.get('measurements').at(0);
								var mc = isOVS ? component.get('satisfactionLevel') : component.get('valueMeasurement');
								if(oldmeasurement && oldmeasurement.get('measuredCharacteristic') === mc){
									var basneName = oldmeasurement.get('name') + "Base" + count++;
									measurement.createBaseMesurementRelationship(basneName,basneName,oldmeasurement);
								}
							});
						});*/
						
						_.each(baseRelSetToMatch,function(rel){
                            var altToUse = viewAlternative;
                            if (!rel.new) {
                                return;
                            }
							var component = rel.new.component;
							if(rel.new.usePreviousAlternative === true){
								altToUse = viewAlternative.getPreviousPhasePrimary();
							}
							if(altToUse){
								altToUse.getModelLinked(component,function(linkedComponent){
									var compScenario = altToUse.getMainScenario(linkedComponent.getNestedParent());
									var observation = getCurrentObservation(compScenario);
									var observedMeasures = observation.get('observedMeasures');
									var count = 1;
									for(var i=0;i<observedMeasures.length;i++){
										var obsMes = observedMeasures.at(i);
										var mes = obsMes.get('measure');
										var oldmeasurement = obsMes.get('measurements').at(0);
										var mc = isOVS ? linkedComponent.get('satisfactionLevel') : linkedComponent.get('valueMeasurement');
										//if(oldmeasurement && oldmeasurement.get('measuredCharacteristic') === mc && rel.new.measure === mes){
										if(oldmeasurement && oldmeasurement.get('measuredCharacteristic') === mc){
											var basneName = oldmeasurement.get('name') + "Base" + count++;
											measurement.createBaseMesurementRelationship(basneName,basneName,oldmeasurement,rel.new.baseMeasureRel);
											break;
										}
									}
								});
							}
						});						
						
					}
				}
			}else{
				var relName;
				if(satisfactionTuple.type === "smm_GradeMeasure"){
					measurement = observedMeasure.createGradeMeasurement(name,name);
					relName = measurement.get('name') + "GradeRel" ;
					measurement.createGradeMesurementRelationship(relName,relName,satisfactionTuple.measurement);
				}else{
					measurement = observedMeasure.createRankingMeasurement(name,name);
					relName = measurement.get('name') + "RankingRel"; 
					measurement.createRankingMesurementRelationship(relName,relName,satisfactionTuple.measurement);				
				}
			}
		measuredCharecterstic.get('measurement').add(measurement);
		return measurement;
	};	
	getAlternative(){
		return this.get('phaseObjectiveSetOwner');
	};
//#endCustomMethods	
	//path.PhaseObjectiveSetMixin = PhaseObjectiveSetMixin;
	//return PhaseObjectiveSetMixin;
}
path.PhaseObjectiveSetMixin = PhaseObjectiveSetMixin;