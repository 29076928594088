import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {BeepPackage} from '../beeppackage/BeepPackage'
import {SmmModelMixin} from './SmmModelMixin'
import {SmmElement} from './SmmElement'
import {SmmElementMixin} from './SmmElementMixin'
import {MeasureLibrary} from './MeasureLibrary'
import {Observation} from './Observation'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/beeppackage/BeepPackage","appbo/smm/SmmModelMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BeepPackage,SmmModelMixin
, SmmElement
, SmmElementMixin
, Observation
, MeasureLibrary){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!SmmElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.SmmElement){
			SmmElement = importPath.SmmElement;
		}
		else{
			import('./SmmElement').then(({ default: SmmElement }) => {
                SmmElement = SmmElement;
            });
			/*require(["appbo/smm/SmmElement"],function(loadedModule){
				SmmElement = loadedModule;
			});*/
		}
	}
	if(!SmmElementMixin){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.SmmElementMixin){
			SmmElementMixin = importPath.SmmElementMixin;
		}
		else{
			import('./SmmElementMixin').then(({ default: SmmElementMixin }) => {
                SmmElementMixin = SmmElementMixin;
            });
			/*require(["appbo/smm/SmmElementMixin"],function(loadedModule){
				SmmElementMixin = loadedModule;
			});*/
		}
	}
	if(!Observation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Observation){
			Observation = importPath.Observation;
		}
		else{
			import('./Observation').then(({ default: Observation }) => {
                Observation = Observation;
            });
			/*require(["appbo/smm/Observation"],function(loadedModule){
				Observation = loadedModule;
			});*/
		}
	}
	if(!MeasureLibrary){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.MeasureLibrary){
			MeasureLibrary = importPath.MeasureLibrary;
		}
		else{
			import('./MeasureLibrary').then(({ default: MeasureLibrary }) => {
                MeasureLibrary = MeasureLibrary;
            });
			/*require(["appbo/smm/MeasureLibrary"],function(loadedModule){
				MeasureLibrary = loadedModule;
			});*/
		}
	}
	var SmmModel = BeepPackage.extend(utils.customExtends({
		relations:SmmModelMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			BeepPackage.prototype.initialize.apply(this, arguments);
		}
		}
		, new SmmElementMixin()
		, new SmmModelMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	SmmModel.abstract = false;
	SmmModel.supported =  !sp2Enabled;
	path.SmmModel = SmmModel;
	export {SmmModel};
//});