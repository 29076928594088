import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as async from 'async'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {TicketStatus} from '../../../../../version1/bo/tickets/TicketStatus'
import {BusinessModelMixin} from '../../../../../version1/bo/vdml/BusinessModelMixin'
import {BMValuesChart} from '../chart/BMValuesChart'
import {BMGuidanceViewModel} from '../../../help/views/properties/BMGuidanceViewModel'
import {BMValueDetailsViewModel} from '../properties/BMValueDetailsViewModel'
import {BMDetailsViewModel} from '../properties/BMDetailsViewModel'
import {ColloborationViewModel} from '../../../tickets/views/properties/ColloborationViewModel'
import * as Highcharts from 'highcharts';
import {HighChartThemes} from '../../../../../com/vbee/utils/HighChartThemes'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import { ValueElementMixin } from '../../../../bo/vdml/ValueElementMixin'
import { AlternativeMixin } from '../../../../bo/transformation/AlternativeMixin'

/*define(["require", "jquery", "enhsplitter", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "spin", "async", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/tickets/User",
    "appbo/tickets/TicketStatus", "appviews/tickets/views/properties/TicketViewModel", "typeahead", "appbo/vdml/BusinessModelMixin", "appbo/vdml/ValueStream", "appviews/vdml/views/properties/BMDetailsViewModel", "appviews/vdml/views/chart/BMValuesChart",
    "appviews/tickets/views/properties/ColloborationViewModel", "appviews/vdml/views/properties/BMValueDetailsViewModel", "appviews/help/views/properties/BMGuidanceViewModel","appcommon/com/vbee/utils/HighChartThemes", "appbo/vdml/ValueElementMixin", "highcharts-common","jquery.actual"],
    function (require, $, enhsplitter, _, Backbone, ko, koMapping, kb, bootbox, Spinner, async, DataManager, global, User,
        TicketStatus, TicketViewModel, typeahead, BusinessModelMixin, ValueStream, BMDetailsViewModel, BMValuesChart,
        ColloborationViewModel, BMValueDetailsViewModel, BMGuidanceViewModel, HighChartThemes, ValueElementMixin, Highcharts
        , Ticket
        , BusinessModelCustomer
        , BusinessModelValueProposition
        , BusinessModelActivity
        , BusinessModelCompetence
        , BusinessModelNetworkPartner
        , BusinessModelValueFormula
        , Comment
        , Community
        , Actor
    ) {
*/
        var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties", global.version);
	export class BusinessModelViewModel {
			constructor(model,options){
				var self = this;
				this.init(model, options);
			}

        htmlEscape(str) {
            return String(str)
                .replace(/@/g, '')
                .replace(/ /g, '')
                .replace(/#/g, '');
        }
        cleanUp() {
            var self = this;
            this.clearChildren();
			$('#newModal').empty();
            this.children.length = 0;
            this.sortedAggregatedValues = [];
            this.customerArray.length = 0;
            this.partnerArray.length = 0;
            this.paticipantArray.length = 0;
            this.partnerCollection.removeAll();;
            this.customerCollection.removeAll();
            this.businessCollection.removeAll();
            this.collaborations.reset();
            this.participantCollection.removeAll();
            this.selectedRoleFilter.removeAll();
            this.selectedRecieverRoleFilter.removeAll();
            this.fromRolesFilter.removeAll();
            this.toRolesFilter.removeAll();
            this.VPProviderFilter.removeAll();
            this.selectedVPProviderRoles.removeAll();
            this.VPRecieverFilter.removeAll();
            this.selectedVPRecieverRoles.removeAll();
            this.businessRolesArray.length = 0;
            this.businessRoles.removeAll();
            this.activityProvidersArray.length = 0;
            this.activityProviders.removeAll();
            this.tempCustomerTypeahead.length = 0;
            this.assignmentsColl = null;
            this.bmValues.removeAll();
            this.bmCompetencyListCollection.removeAll();
            this.bmVPListCollection.removeAll();
            this.bmVFListCollection.removeAll();
            this.valueFormulaValuesColl.removeAll();
            this.providersArray.length = 0;
            this.receiversArray.length = 0;
            this.bmActivityList.length = 0;
            this.removeRoleArray.length = 0;
            this.deleteParticipantArray.length = 0;
            this.valuePropositionValues.length = 0;
            this.valuePropositionValuesColl.removeAll();
            this.providerRolesArray.length = 0;
            this.capabilityTypeAhead = null;
            if (this.customerTypeahead) {
                this.customerTypeahead.reset();
            }
            self.spliter.enhsplitter('remove');
            $(window, ".resize").unbind('resize');
            if (ko.components.isRegistered('BMGuidance')) {
                ko.components.unregister('BMGuidance');
                ko.components.unregister('valuewidget');
                ko.components.unregister('BMDetailsWidget');
                ko.components.unregister('BMValuesChart');
				ko.components.unregister('Ticketwidget');
				//window.utils.loadTemplate(null,true);
            }
            if (self.highchartsDefaultTheme) {
                Highcharts.setOptions(HighChartThemes.getDefaultThemeFont());
                Highcharts.setOptions(self.highchartsDefaultTheme);
            }
        };
        
        loadLibraryPackages(callback) {
            var self = this;
            if (!self.capabilityTypeAhead) {
				var currentPlan = DataManager.getDataManager().get('currentPlan');
				currentPlan.loadCapabilityLibraries(function (capabilityLibraries) {
					self.capabilityTypeAhead = capabilityLibraries;
					callback(self.capabilityTypeAhead);
                });
            } else {
                callback(self.capabilityTypeAhead);
            }
        }
        getViewAlternativeModel() {
            var self = this;
            var phase = DataManager.getDataManager().get('currentPlan').get('phase');
            var tempAlt = DataManager.getDataManager().get('viewAlternative') ? DataManager.getDataManager().get('viewAlternative') : alternative;
            for (var j = 0; j < phase.length; j++) {
                var alts = phase.at(j).get('phaseAlternative');
                var viewAlternativeModel = alts.findWhere({ 'id': tempAlt });
                if (viewAlternativeModel) {
                    self.currentAlternativeModel = viewAlternativeModel;
                    break;
                }
            }
        };
		openAccordian() {
			var self = this;
			var phaseModel = null;
            var phase = DataManager.getDataManager().get('currentPlan').get('phase');
            var tempAlt = DataManager.getDataManager().get('viewAlternative') ? DataManager.getDataManager().get('viewAlternative') : self.alternative;
            for (var j = 0; j < phase.length; j++) {
                var alts = phase.at(j).get('phaseAlternative');
                if (alts.findWhere({ 'id': tempAlt })) {
                    phaseModel = phase.at(j);
                    break;
                }
            }
            $('#collapse' + self.htmlEscape(phaseModel.get('id'))).addClass('collapse in');
            $('#collapse' + self.htmlEscape(tempAlt)).addClass('collapse in');
		}
        refreshWidgetData(cond) {
            var self = this;
			//if ($('#rightTab').width() > 0) {
                self.chartViewInstance.initialize(self.model, cond, self);
           // }
            self.bmValueWidgetInstance.initialize(self.model);
            self.openAccordian();
        };
        getMeasurementValueandUnit(value, mCharacteristic) {
            var self = this;
            var measurement = null, val = {};
            var scenario = self.currentAlternativeModel.getMainScenario(value.getNestedParent());
            var observedMeasure = scenario ? scenario.getObservedMeasureWithMeasurements(mCharacteristic, null) : null;

            if (!observedMeasure) {
                scenario = DataManager.getDataManager().get('currentCommonScenario');
                observedMeasure = scenario?scenario.getObservedMeasureWithMeasurements(mCharacteristic, null):null;
            }
            if (observedMeasure && observedMeasure.get('measure')) {
                var measure = observedMeasure.get('measure');
                if (measure.get('type') === "smm_CollectiveMeasure") {
                    val['isCollective'] = true;
                }
                else {
                    val['isCollective'] = false;
                }
                var unit = measure.get('unit');
                val['unit'] = unit ? unit.get('name') : "";
                var significantDecimals = unit ? unit.get('significantDecimals') : 2;
                if (parseFloat(significantDecimals) < 0 || parseFloat(significantDecimals) > 20) {
                    significantDecimals = 2;
                }
                measurement = observedMeasure.get('measurements').at(0);
                if (measurement.get('type') === "smm_GradeMeasurement") {
                    val['value'] = measurement.get('symbol');//grade only
                } else {
                    val['value'] = window.utils.getRoundedValues(measurement.get('value'), significantDecimals);
                    val['measurement'] = measurement;
                }
                if (val['value'] == "") {
                    val['unit'] = "";
                }
				val['formula'] = measurement.get('observedMeasure').get('valueFormula');
                
                if (!val['formula'] || val['formula'] == "") {//back-end compatability
					val['formula'] = mCharacteristic.get('valueFormula');
					if (!val['formula'] || val['formula'] == "") {//back-end compatability
						val['formula'] = measure.get('formula');
					}
                }
            } else {
                val['unit'] = "";
                val['value'] = "";
                val['formula'] = "";
                if (value.get('aggregatedFrom').length > 0) {
                    val['isCollective'] = true;
                } else {
                    val['isCollective'] = false;
                }

            }
            return val;
        }
        sortCollection(collection) {
            collection.sort(function (left, right) {
                return left.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() == right.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ? 0 : (left.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() < right.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ? -1 : 1)
            });
        }
        sortRoles(left, right) {
            return (left.displayRolename).toLowerCase() == (right.displayRolename).toLowerCase() ? 0 : ((left.displayRolename).toLowerCase() < (right.displayRolename).toLowerCase() ? -1 : 1);
        }
        sortParticipantOptions(collection) {
            collection.sort(function (left, right) {
                return (left.displayName).toLowerCase() == (right.displayName).toLowerCase() ? 0 : ((left.displayName).toLowerCase() < (right.displayName).toLowerCase() ? -1 : 1);
            });
        }
        fillAggregationContainerPath(vp, type) {
            var self = this;
            var VFnspath = "";
            if (type === "Value Formula") {
                var bmVFs = self.model.get('bmValueFormula');
                var currentBMVFs = new Backbone.Collection();
                bmVFs.each(function (bmVF) {
                    var vp = bmVF.get('valueFormula');
                    currentBMVFs.add(vp);
                });
                VFnspath = self.getPropositionDispName(currentBMVFs, vp);
            }
            else {
                var bmVPs = self.model.get('bmValueProposition');
                var currentBMVPs = new Backbone.Collection();
                bmVPs.each(function (bmVP) {
                    var vp = bmVP.get('valueProposition');
                    currentBMVPs.add(vp);
                });
                VFnspath = self.getPropositionDispName(currentBMVPs, vp);
            }
            return VFnspath;
        }
        //  Fill aggregate From and To Values
        fillAggregateFromAndTo(valComps, vp, type, isAttached) {
            var self = this;
            var valuesName = [], valAndUnitObj, aggregatedVPColl = [], aggregatedFromColl = [];
            if (valComps) {
                for (var i = 0; i < valComps.length; i++) {
                    var valComp = valComps.at(i);
                    valAndUnitObj = self.getMeasurementValueandUnit(valComp, valComp.get('valueMeasurement'));
                    var components = valComp.get('aggregatedTo');
                    var valueName = "";
                    var valCompName = valComp.get('name');
                    var valAndUnitObjComp;
                    if (valAndUnitObj['value']) {
                        valueName = valueName.concat(valCompName + " " + window.utils.thousandSeparator(valAndUnitObj['value']) + " " + valAndUnitObj['unit']);
                    } else {
                        var scenario = self.currentAlternativeModel.getMainScenario(valComp.getNestedParent());
                        var satisfaction = scenario.getTSLessMeasurementReferringToMeasuredCharteristic(valComp.get('satisfactionLevel'));
                        var compSatisfactionMeasure = satisfaction ? satisfaction.get('observedMeasure').get('measure') : null;
                        if (compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('measureRelationships').length > 0) {
                            valueName = valueName.concat(valCompName + " " + satisfaction.get('symbol'));
                        }
                        else {
                            valueName = valCompName;
                        }
                    }
                    var dispName = valCompName.concat(" " + window.utils.thousandSeparator(valAndUnitObj['value']) + " " + valAndUnitObj['unit']);
                    valuesName.push({ id: valComps.at(i).get('id'), name: valueName, displayName: dispName, modalId: self.htmlEscape(valComps.at(i).get('id')), isCollective: false, smileySrc: false, smileyColor: false, correctiveActions: ''  });
                }
            }
            return valuesName;
        }
        fillBMVFList() {
            var self = this;
            self.bmVFListArray = [];
            var bmVFs = self.model.get('bmValueFormula');
            if (bmVFs.length > 1) {
                bmVFs.sort();
            }
            var currentBMVFs = new Backbone.Collection();
            bmVFs.each(function (bmVF) {
                var vp = bmVF.get('valueFormula');
                currentBMVFs.add(vp);
            });
            bmVFs.each(function (bmVF) {
                var vp = bmVF.get('valueFormula');
                if (vp) {
                    var valuesName = [];
                    var valComps = vp.get('component');
                    valuesName = self.fillAggregateFromAndTo(valComps, vp, 'Value Formula', true);
                    self.sortCollection(valuesName);
                    var VFnspath = self.getPropositionDispName(currentBMVFs, vp);
                    var VfRolenspath = self.getRoleDispName(vp.get('provider'));
                    self.bmVFListArray.push({ id: vp.get('id'), bmVFName: vp.get('name'), bmVFDescription: vp.get('description'), VFnspath: VFnspath, business: vp.get('provider').get('name'), VfRolenspath: VfRolenspath, values: valuesName });
                }
            });
            self.bmVFListArray = _.uniq(self.bmVFListArray);
            self.sortBMVFListArray();
            self.bmVFListCollection(self.bmVFListArray);
        };
        canPushVP(providerId, recieverId, isVpAttached) {
            var self = this;
            var found = false;
            var providerFound = false;
            var recieverFound = false;
            var vpFilter = false;
            if (self.selectedVPFilter() === "VPAttached" && isVpAttached === true) {
                vpFilter = true;
            }
            else if (self.selectedVPFilter() === "VPNon-Attached" && isVpAttached === false) {
                vpFilter = true;
            }
            else if (self.selectedVPFilter() === "VPShowAll") {
                vpFilter = true;
            }
            for (var i = 0; i < self.selectedVPProviderRoles().length; i++) {
                if (self.selectedVPProviderRoles()[i] === providerId) {
                    providerFound = true;
                    break;
                }
            }
            for (var i = 0; i < self.selectedVPRecieverRoles().length; i++) {
                if (self.selectedVPRecieverRoles()[i] === recieverId) {
                    recieverFound = true;
                    break;
                }
            }
            if (recieverId === "") {
                recieverFound = true;
            }
            if (providerFound && recieverFound && vpFilter) {
                found = true;
            }
            return found;
        }

        fillBMVPList() {
            var self = this;
            self.bmVPListCollection([]);
            self.bmVPListArray = [];
            var bmVPs = self.model.get('bmValueProposition');
            if (bmVPs.length > 1) {
                bmVPs.sort();
            }
            var currentBMVPs = new Backbone.Collection();
            bmVPs.each(function (bmVP) {
                var vp = bmVP.get('valueProposition');
                currentBMVPs.add(vp);
            });
            currentBMVPs.each(function (vp) {
                fillbmVPList2(vp, true);
            });
            fillNonAttachedVP();
            function fillbmVPList2(vp, isVpAttached) {
                var provider = vp.get('provider');
                var providerRoleId = provider.get('id');
                var providerParticipant = self.model.getRoleParticipant(provider);
                var providerName = "", providerId = "";
                var providerRoleName;
                var providerParticipantnspath = '';
                if (providerParticipant) {
                    providerParticipantnspath = self.getParticipantDispName(providerParticipant);
                    providerName = providerParticipant.get('name');
                    providerId = providerParticipant.get('id');
                }
                if (vp.get('provider')) {
                    providerRoleName = vp.get('provider').get('name');
                }
                var recipient = vp.get('recipient');
                var recipientRoleId = "";
                var receiverParticipant = self.model.getRoleParticipant(recipient);
                var recieverParticipantnspath = '';
                var recieverRoleName;
                var VpReciepientnspath;
                var VpRecipientType = '';
                var receiverName = "", recipientId = "";
                if (receiverParticipant) {
                    recieverParticipantnspath = self.getParticipantDispName(receiverParticipant);
                    receiverName = receiverParticipant.get('name');
                    recipientId = receiverParticipant.get('id');
                    recipientRoleId = recipient.get('id');
                    VpReciepientnspath = self.getRoleDispName(recipient);
                    VpRecipientType = self.fillRolepath(recipient);
                }
                if (vp.get('recipient')) {
                    recieverRoleName = vp.get('recipient').get('name');
                }
                var valuesName = [];
                var valComps = vp.get('component');
                valuesName = self.fillAggregateFromAndTo(valComps, vp, 'Value Proposition', isVpAttached);
                self.sortCollection(valuesName);
                var VPnspath = self.getPropositionDispName(currentBMVPs, vp);
                var VpProviderRolenspath = self.getRoleDispName(provider);
                var VpProviderType = self.fillRolepath(provider);
                if (self.canPushVP(providerRoleId, recipientRoleId, isVpAttached)) {
                    self.bmVPListArray.push({ id: vp.get('id'), bmVPName: vp.get('name'), bmVPDescription: vp.get('description'), provider: providerName, providerRoleName: providerRoleName, providerParticipantnspath: providerParticipantnspath, providerRoleID: providerRoleId, providerID: providerId, recipientRoleID: recipientRoleId, recipientID: recipientId, receiver: receiverName, recieverParticipantnspath: recieverParticipantnspath, recieverRoleName: recieverRoleName, values: valuesName, vpAttached: isVpAttached, vpModel: vp, VPnspath: VPnspath, VpProviderRolenspath: VpProviderRolenspath, VpReciepientnspath: VpReciepientnspath, VpProviderType: VpProviderType, VpRecipientType: VpRecipientType });
                }
            }
            function fillNonAttachedVP() {
                var bmRoles = self.model.get('bmRole');
                bmRoles.each(function (bmRole) {
                    var vps = bmRole.get('providedProposition');
                    vps.each(function (vp) {
                        if (vp) {
                            var recipientRole = vp.get('recipient');
                            if (recipientRole) {
                                var roleInBM = bmRoles.findWhere({ id: recipientRole.get('id') });
                                if (roleInBM) {
                                    var vpInBM = currentBMVPs.findWhere({ id: vp.get('id') });
                                    if (!vpInBM) {
                                        fillbmVPList2(vp, false);
                                    }
                                }
                            }
                        }
                    });
                });
            }
            self.bmVPListArray = _.uniq(self.bmVPListArray);
            self.bmVPListArray.sort(self.sortBmVPList);
            self.bmVPListCollection(self.bmVPListArray);
        };
        sortBmVPList(left, right) {
            var self = this;
            return left.bmVPName.toLowerCase() == right.bmVPName.toLowerCase() ? 0 : (left.bmVPName.toLowerCase() < right.bmVPName.toLowerCase() ? -1 : 1)
        };
        fillVPFilterRoles() {
            var self = this;
            self.VPProviderFilter([]);
            var uniqueProviderArray = [];
            self.VPRecieverFilter([]);
            var bmVPs = self.model.get('bmValueProposition');
            bmVPs.each(function (bmVP) {
                var vp = bmVP.get('valueProposition');
                if(vp){
                    var providerParticipant = self.model.getRoleParticipant(vp ? vp.get('provider'): "");
                    var providerParticipantName = providerParticipant ? providerParticipant.get('name') : '';
                    var providerType = "";
                    if (providerParticipantName !== '') {
                        providerType = self.fillRolepath(vp.get('provider'));
                    }
                    var VpProviderRolenspath = self.getRoleDispName(vp.get('provider'));
                    uniqueProviderArray.push({ id: vp.get('provider').get('id'), name: vp.get('provider').get('name'), providerParticipantName: providerParticipantName, providerType: providerType, VpProviderRolenspath: VpProviderRolenspath, check: true });
                    if (vp.get('recipient')) {
                        var recieverParticipant = self.model.getRoleParticipant(vp.get('recipient'));
                        var recieverParticipantName = recieverParticipant ? recieverParticipant.get('name') : '';
                        var recieverType = '';
                        if (recieverParticipantName !== '') {
                            recieverType = self.fillRolepath(vp.get('recipient'));
                        }
                        var VpRecieverRolenspath = self.getRoleDispName(vp.get('recipient'));
                        self.VPRecieverFilter.push({ id: vp.get('recipient').get('id'), name: vp.get('recipient').get('name'), recieverParticipantName: recieverParticipantName, recieverType: recieverType, VpRecieverRolenspath: VpRecieverRolenspath, check: true });
                    }
                }                
            });
            //To fill Non-attached Roles
            var bmRoles = self.model.get('bmRole');
            bmRoles.each(function (bmRole) {
                var vps = bmRole.get('providedProposition');
                vps.each(function (vp) {
                    if (vp) {
                        var recipientRole = vp.get('recipient');
                        if (recipientRole) {
                            var roleInBM = bmRoles.findWhere({ id: recipientRole.get('id') });
                            if (roleInBM) {
                                var vpInBM = bmVPs.findWhere({ 'valueProposition': vp });
                                if (!vpInBM) {
                                    var providerParticipant = self.model.getRoleParticipant(vp.get('provider'));
                                    var providerParticipantName = providerParticipant ? providerParticipant.get('name') : '';
                                    var providerType = "";
                                    if (providerParticipantName !== '') {
                                        providerType = self.fillRolepath(vp.get('provider'));
                                    }
                                    var VpProviderRolenspath = self.getRoleDispName(vp.get('provider'));
                                    uniqueProviderArray.push({ id: vp.get('provider').get('id'), name: vp.get('provider').get('name'), providerParticipantName: providerParticipantName, providerType: providerType, VpProviderRolenspath: VpProviderRolenspath, check: true });
                                    if (vp.get('recipient')) {
                                        var recieverParticipant = self.model.getRoleParticipant(vp.get('recipient'));
                                        var recieverParticipantName = recieverParticipant ? recieverParticipant.get('name') : '';
                                        var recieverType = "";
                                        if (recieverParticipantName !== '') {
                                            recieverType = self.fillRolepath(vp.get('recipient'));
                                        }
                                        var VpRecieverRolenspath = self.getRoleDispName(vp.get('recipient'));
                                        self.VPRecieverFilter.push({ id: vp.get('recipient').get('id'), name: vp.get('recipient').get('name'), recieverParticipantName: recieverParticipantName, recieverType: recieverType, VpRecieverRolenspath: VpRecieverRolenspath, check: true });
                                    }
                                }
                            }
                        }
                    }
                });
            });
            self.VPProviderFilter(self.getUniqueFilterArrayData(uniqueProviderArray));
            self.sortCollection(self.VPProviderFilter);
            var uniqueRecieverArray = self.getUniqueFilterArrayData(self.VPRecieverFilter());
            self.VPRecieverFilter(uniqueRecieverArray);
            self.sortCollection(self.VPRecieverFilter);
            for (var i = 0; i < self.VPProviderFilter().length; i++) {
                self.selectedVPProviderRoles.push(self.VPProviderFilter()[i].id);
            }
            for (var i = 0; i < self.VPRecieverFilter().length; i++) {
                self.selectedVPRecieverRoles.push(self.VPRecieverFilter()[i].id);
            }
            self.selectAllVPProviderRoles(true);
            self.selectAllVPRecieverRoles(true);
            self.fillBMVPList();
            self.VPProviderFilter.sort(function (left, right) {
                return (left.providerParticipantName + "" + left.name).toLowerCase() == (right.providerParticipantName + "" + right.name).toLowerCase() ? 0 : ((left.providerParticipantName + "" + left.name).toLowerCase() < (right.providerParticipantName + "" + right.name).toLowerCase() ? -1 : 1);
            });
            self.VPRecieverFilter.sort(function (left, right) {
                return (left.recieverParticipantName + "" + left.name).toLowerCase() == (right.recieverParticipantName + "" + right.name).toLowerCase() ? 0 : ((left.recieverParticipantName + "" + left.name).toLowerCase() < (right.recieverParticipantName + "" + right.name).toLowerCase() ? -1 : 1);
            });
        }
        checkTypeaheadDuplicates(models, element) {
            var self = this;
            var path = element.get('name');
            for (var i = 0; i < models.length; i++) {
                if ((element.get('name') === models.at(i).get('name') && (element.get('id') !== models.at(i).get('id')))) {
                    path = path.concat(" [");
                    if (element.get('type') === "vdml_Actor") {
                        path = path.concat("Individual");
                    } else if (element.get('type') === "vdml_OrgUnit") {
                        path = path.concat("Enterprise");
                    } else {
                        path = path.concat("Market Segment");
                    }
                    var vdmlType = element.get('type').replace('_', '.');
                    var elementModal = Backbone.Relational.store.getObjectByName('' + vdmlType).find({ id: element.get('id') });
                    if (elementModal) {
                        path = path.concat("/");
                        path = path.concat(elementModal.getNestedParent().get('name'));
                    }
                    path = path.concat("]");
                    break;
                }
            }
            return path;
        }
        fillCustomerTypeaheads(customer) {
            var self = this;
            self.tempCustomerTypeahead = [];
            var filteredTypeahead = new Backbone.Collection();

            var phaseDesignParts = self.currentAlternativeModel.get('phaseDesignPart');
            if (customer) {
                for (var i = 0; i < customer.length; i++) {
                    var type = customer.at(i).get('type');
                    if ((type === "vdml_Community" || type === "vdml_OrgUnit" || type === "vdml_Actor" || type === "vdml_Person")) {
                        if (self.currentAlternativeModel.id !== DataManager.getDataManager().getRepositoryId(customer.models[i].get('id'))) {
                            var vdmlType = type.replace('_', '.');
                            var custModal = Backbone.Relational.store.getObjectByName('' + vdmlType).find({ id: customer.models[i].get('id') });
                            if (custModal && !phaseDesignParts.findWhere({ 'beepReference': custModal.getNestedParent().id })) {
                                continue;
                            }
                        }
                        filteredTypeahead.push(customer.at(i));
                    }
                }
                self.customerTypeahead = filteredTypeahead;
            }
            if (self.customerTypeahead) {
                for (var j = 0; j < self.customerTypeahead.length; j++) {
                    var path = self.checkTypeaheadDuplicates(self.customerTypeahead, self.customerTypeahead.at(j));
                    self.tempCustomerTypeahead.push({ id: self.customerTypeahead.at(j).get('id'), name: self.customerTypeahead.at(j).get('name'), description: self.customerTypeahead.at(j).get('description') ? self.customerTypeahead.at(j).get('description') : self.customerTypeahead.at(j).get('name'), path: path, utilized: false });
                }
            }
        }
        fetchCustomerTypeaheads(fetch, callback) {
            var self = this;
            if (fetch) {
                if (!self.fetchedParticipantList) {
                    window.utils.startSpinner('fetchedParticipantList', 'Loading Participants ...');
                }
                window.setTimeout(function () {
                    DataManager.getDataManager().getAllDocumentsCollectionOfType(self.model.getNestedParent(), 'vdml_OrgUnit,vdml_Community,vdml_Actor', function (participantList) {
                        self.fillCustomerTypeaheads(participantList);
                        if (!self.fetchedParticipantList) {
                            window.utils.stopSpinner('fetchedParticipantList');
                        }
                        self.fetchedParticipantList = true;
                        if (callback) {
                            callback();
                        }
                    }, true, null, null, null, null, true, true);
                }, 100);
            } else {
                if (callback) {
                    callback();
                }
            }
        }

        fillActivityContainerPath(activityContainer) {
            var self = this;
            var containerPath = "";
            var bmActivities = self.model.get('bmActivity');
            for (var i = 0; i < bmActivities.length; i++) {
                var activity = bmActivities.models[i].get('activity');
                if (activityContainer.get('name') === activity.get('name') && activityContainer.get('id') === activity.get('id')) {
                    containerPath = containerPath.concat(" [" + activityContainer.get('performingRole').get('name') + "]");
                }
            }
            return containerPath;
        }
        fillActivityAggregateFromAndTo(valueAdd, activity) {
            var self = this;
            var valueAndUnit = self.getMeasurementValueandUnit(valueAdd, valueAdd.get('valueMeasurement'));
            var dispName = valueAdd.get('name') + " " + valueAndUnit['value'] + " " + valueAndUnit['unit'];
            var isCollective = valueAndUnit['isCollective'];
            if (activity) {
                dispName = dispName.concat(" (" + activity.get('name'));
                var duplicateActivity = false;
                for (var i = 0; i < self.model.get('bmActivity').length; i++) {
                    if (self.model.get('bmActivity').at(i).get('name') == activity.get('name') && self.model.get('bmActivity').at(i).get('activity').id != activity.id) {
                        duplicateActivity = true;
                        break;
                    }
                }
                if (duplicateActivity) {
                    dispName = dispName.concat(" [" + activity.get('activityOwner').get('name') + "]");
                }
                dispName = dispName.concat(")");
            }
            var nameAndDispNameObj;
            var valueName = dispName;
            /*var count = 0;
            var componentsAggTo = valueAdd.get('aggregatedTo');
            if (componentsAggTo.length > 0) {
                componentsAggTo.each(function (component) {
                    if (count === 0) {
                        valueName = valueName.concat(" -> ");
                        valueName = valueName.concat(component.get('name'));
                        count = 1;
                    }
                    else {
                        valueName = valueName.concat(", " + component.get('name'));
                    }
                    valueAndUnit = self.getMeasurementValueandUnit(component, component.get('valueMeasurement'));
                    valueName = valueName.concat(" " + valueAndUnit['value'] + " " + valueAndUnit['unit']);
                    if (component.get('valueAddOwner') && component.get('valueAddOwner').get('containedPortOwner').get('id') !== activity.get('id')) {
                        var componentOwnerAct = component.get('valueAddOwner').get('containedPortOwner');
                        var containerPath = '';
                        if (componentOwnerAct.get('performingRole') != activity.get('performingRole')) {
                            containerPath = self.fillActivityContainerPath(componentOwnerAct);
                        }
                        valueName = valueName.concat(" (" + component.get('valueAddOwner').get('containedPortOwner').get('name') + "" + containerPath + ")");
                    }
                    else if (component.get('valueAddOwner')) {
                        valueName = valueName.concat(" (" + component.get('valueAddOwner').get('containedPortOwner').get('name') + ")");
                    }
                    else if (component.get('componentOwner')) {
                        var type = component.get('componentOwner').get('isValueFormula') ? "Value Formula" : "Value Proposition";
                        var containerPath = self.fillAggregationContainerPath(component.get('componentOwner'), type);
                        var compOwnerName = component.get('componentOwner').get('name');
                        if (component.get('componentOwner').get('planCriterionSetOwner')) {
                            compOwnerName = component.get('componentOwner').get('planCriterionSetOwner').get('name');
                        }
                        valueName = valueName.concat(" (" + compOwnerName + "" + containerPath + ")");
                    }
                });
            }*/
            nameAndDispNameObj = { name: valueName, displayName: dispName, isCollective: isCollective };
            return nameAndDispNameObj;
        }
        fetchCreatingValues(activity, creatingValuesColl, bmVS, bmAct) {
            var self = this;
            var aggregatedActivityColl = [];//, aggregatedFromColl = [];
            activity.get('containedPort').each(function (port) {
                if (port.get('type') === "vdml_OutputPort") {
                    port.get('valueAdd').each(function (valueAdd) {
                        if (bmVS.get('activityValue').findWhere({ id: valueAdd.get('id') }) && bmAct.get('activityValue').findWhere({ id: valueAdd.get('id') })) {
                            var nameAndDispNameObj = self.fillActivityAggregateFromAndTo(valueAdd, activity);
							var hasAggregation = valueAdd.get('aggregatedTo').length > 0 ? true:false;
                            /*components.each(function (component) {
                                aggregatedFromColl.push({ id: component.get('id') });
                                hasAggregation = true;
                            });*/
                            aggregatedActivityColl.push({ id: valueAdd.get('id'), hasAggregation: hasAggregation });
                            creatingValuesColl.push({ name: valueAdd.get('name'), aggregatedisplayName: nameAndDispNameObj.name, displayName: '' + nameAndDispNameObj.displayName, originalId: valueAdd.get('id'), modalId: self.htmlEscape(valueAdd.get('id')), activityId: activity.get('id'), activityModalId: self.htmlEscape(activity.get('id')), isCollective: nameAndDispNameObj.isCollective });
                        }
                    });
                }
            });

            /*for (var i = 0; i < aggregatedActivityColl.length; i++) {
                var val = _.findWhere(aggregatedFromColl, { id: aggregatedActivityColl[i].id });
                if (val && !aggregatedActivityColl[i].hasAggregation) {
                    var index;
                    for (var j = 0; j < creatingValuesColl.length; j++) {
                        if (creatingValuesColl[j].originalId == val.id) {
                            index = j;
                            break;
                        }
                    }
                    creatingValuesColl[index].aggregatedisplayName = null;
                }
            }*/
            return creatingValuesColl;
        }
        fetchContributingValues(activity, contributingValuesColl, valueStream) {
            var self = this;
            var actPersuedPropositionComp = valueStream.get('persuedProposition') ? valueStream.get('persuedProposition').get('component') : null;
            activity.get('containedPort').each(function (port) {
                if (port.get('type') === "vdml_OutputPort") {
                    port.get('valueAdd').each(function (valueAdd) {
                        var bmActivity = self.model.get('bmActivity').findWhere({ 'activity': activity });
                        var components = valueAdd.get('aggregatedTo');
                        if (bmActivity.get('activityValue').findWhere({ 'id': valueAdd.get('id') }) && components.length > 0) {
                            components.each(function (component) {
                                if (actPersuedPropositionComp && actPersuedPropositionComp.findWhere({ 'id': component.get('id') })) {
                                    var valueAndUnit = self.getMeasurementValueandUnit(component, component.get('valueMeasurement'));
                                    var dispName = component.get('name') + " " + valueAndUnit['value'] + " " + valueAndUnit['unit'];

                                    var obj = { name: component.get('name'), isCollective: valueAndUnit['isCollective'], dispName: dispName, originalId: component.id, modalId: self.htmlEscape(component.get('id')), activityIds: [valueAdd.get('id')] };
                                    if (!self.checkExists(contributingValuesColl, obj)) {
                                        contributingValuesColl.push(obj);
                                    }
                                }
                            });
                        }
                    });
                }
            });
            return contributingValuesColl;
        }
        sortBMActivityList() {
            var self = this;
            self.bmActivityList.sort(function (left, right) {
            	if(left.bmPersued && right.bmPersued){
                	return left.bmPersued.toLowerCase() == right.bmPersued.toLowerCase() ? 0 : (left.bmPersued.toLowerCase() < right.bmPersued.toLowerCase() ? -1 : 1)
        		}
            });
        }
        sortBMVFListArray() {
            var self = this;
            self.bmVFListArray.sort(function (left, right) {
                return left.bmVFName.toLowerCase() == right.bmVFName.toLowerCase() ? 0 : (left.bmVFName.toLowerCase() < right.bmVFName.toLowerCase() ? -1 : 1)
            });
        }
        fetchActivityNsPath(activity) {
            var self = this;
            var activitynsPath = "";
            if (self.model.get('bmActivity').where({ 'name': activity.get('name') }).length > 1) {
                var activityPath = activity.getPackagePath();
                activitynsPath = "   [";
                if (activityPath[0] && activityPath[0].name && activityPath[1]) {
                    activitynsPath = activitynsPath.concat(activityPath[0].name + "  /  " + activity.get('performingRole').get('name'));
                }
                activitynsPath = activitynsPath.concat("]");
            }
            return activitynsPath;
        }
        fillBMActivityList() {
            var self = this;
            self.bmActivityList = [];
            var bmValueStreams = self.model.get('bmValueStream');
            var bmActivities = self.model.get('bmActivity');
            var vps = new Backbone.Collection();
            bmValueStreams.each(function (bmValueStream) {
                vps.add(bmValueStream.get('persuedProposition'));
            });

            bmValueStreams.each(function (bmVS) {
                var activities = bmVS.get('valueStreamActivity');
                var activityColl = [];
                var creatingValuesColl = [];
                var contributingWhatValuesColl = [];
                var participant = null;
                var participantId = null;
                var participantnsPath = '';
                var persuedName = bmVS.get('persuedProposition') ? bmVS.get('persuedProposition').get('name') : null;
                var persuednsPath = "";
                if (bmVS.get('persuedProposition')) {
                    persuednsPath = self.getPropositionDispName(vps, bmVS.get('persuedProposition'));
                }
                var persuedId = bmVS.get('persuedProposition') ? bmVS.get('persuedProposition').get('id') : null;
                for (var i = 0; i < activities.models.length; i++) {
                    var bmAct = bmActivities.findWhere({ activity: activities.models[i] });
                    if (bmAct) {
                        var objExists = false;
                        var role = activities.models[i].get('performingRole');
                        var roleName = role ? role.get('name') : null;
                        var rolensPath = "";
                        var roleType = "";
                        if (role) {
                            rolensPath = self.getRoleDispName(role);
                            roleType = self.fillRolepath(role);
                        }
                        var roleId = role ? role.get('id') : null;
                        //var actOwner = activities.models[i].get('activityOwner');
                        if (role) {
                            var assignment = role.get("roleAssignment") ? role.get("roleAssignment").at(0) : null;
                            if (assignment) {
                                participant = assignment.get("participant").get('name');
                                participantnsPath = self.getParticipantDispName(assignment.get("participant"));
                                participantId = assignment.get("participant").get('id');
                            }
                        }
                        for (var k = 0; k < self.bmActivityList.length; k++) {
                            if (self.bmActivityList[k].id === persuedId && self.bmActivityList[k].bmActivityRoleId === role.get('id')) {
                                var activitynsPath = self.fetchActivityNsPath(activities.models[i]);
                                activityColl = [{ name: activities.models[i].get('name'), originalId: activities.models[i].get('id'), modalId: self.htmlEscape(activities.models[i].get('id')), activitynsPath: activitynsPath }];
                                creatingValuesColl = [];
                                self.fetchCreatingValues(activities.models[i], creatingValuesColl, bmVS, bmAct);
                                creatingValuesColl.push.apply(creatingValuesColl, self.bmActivityList[k].bmCreatingValuesColl);
                                self.sortCollection(creatingValuesColl);
                                contributingWhatValuesColl = [];
                                self.fetchContributingValues(activities.models[i], contributingWhatValuesColl, bmVS);

                                for (var j = 0; j < self.bmActivityList[k].bmContributingValuesColl.length; j++) {
                                    var contributeAlreadyExists = false;
                                    var contributeObj = self.bmActivityList[k].bmContributingValuesColl[j];
                                    for (var m = 0; m < contributingWhatValuesColl.length; m++) {
                                        if (contributingWhatValuesColl[m].originalId === contributeObj.originalId) {
                                            contributeAlreadyExists = true;
                                            contributingWhatValuesColl[m].activityIds.push.apply(contributingWhatValuesColl[m].activityIds, contributeObj.activityIds);
                                            contributingWhatValuesColl[m].activityIds = _.uniq(contributingWhatValuesColl[m].activityIds);
                                            break;
                                        }
                                    }
                                    if (!contributeAlreadyExists) {
                                        contributingWhatValuesColl.push(contributeObj);
                                    }
                                    /*if(!self.checkExists(contributingWhatValuesColl,self.bmActivityList[k].bmContributingValuesColl[j])) {
                                contributingWhatValuesColl.push(self.bmActivityList[k].bmContributingValuesColl[j]);
                                }*/
                                }
                                //contributingWhatValuesColl.push.apply(contributingWhatValuesColl,self.bmActivityList[k].bmContributingValuesColl);
                                activityColl.push.apply(activityColl, self.bmActivityList[k].bmActivityColl);
                                self.sortCollection(activityColl);
                                var obj = { id: persuedId, bmPersued: persuedName, persuednsPath: persuednsPath, bmParticipantId: participantId, bmParticipant: participant, participantnsPath: participantnsPath, bmActivityRoleId: roleId, bmActivityRole: roleName, rolensPath: rolensPath, roleType: roleType, bmActivityColl: activityColl, bmCreatingValuesColl: creatingValuesColl, bmContributingValuesColl: contributingWhatValuesColl };
                                //self.bmActivityList.replace(self.bmActivityList[k],obj);
                                self.bmActivityList[k] = obj;
                                objExists = true;
                                break;
                            }
                        }
                        if (!objExists) {
                            for (var k = 0; k < self.bmActivityList.length; k++) {
                                if (self.bmActivityList[k].id === persuedId && self.bmActivityList[k].bmActivityRoleId !== role.get('id')) {
                                    var activitynsPath = self.fetchActivityNsPath(activities.models[i]);
                                    activityColl = [{ name: activities.models[i].get('name'), originalId: activities.models[i].get('id'), modalId: self.htmlEscape(activities.models[i].get('id')), activitynsPath: activitynsPath }];
                                    creatingValuesColl = [];
                                    self.fetchCreatingValues(activities.models[i], creatingValuesColl, bmVS, bmAct);
                                    self.sortCollection(creatingValuesColl);
                                    contributingWhatValuesColl = [];
                                    self.fetchContributingValues(activities.models[i], contributingWhatValuesColl, bmVS);
                                    self.sortCollection(activityColl);
                                    var obj = { id: persuedId, bmPersued: persuedName, persuednsPath: persuednsPath, bmParticipantId: participantId, bmParticipant: participant, participantnsPath: participantnsPath, bmActivityRoleId: roleId, bmActivityRole: roleName, rolensPath: rolensPath, roleType: roleType, bmActivityColl: activityColl, bmCreatingValuesColl: creatingValuesColl, bmContributingValuesColl: contributingWhatValuesColl };
                                    self.bmActivityList.push(obj);
                                    objExists = true;
                                    break;
                                }
                            }
                            if (!objExists) {
                                self.fetchCreatingValues(activities.models[i], creatingValuesColl, bmVS, bmAct);
                                self.sortCollection(creatingValuesColl);
                                self.fetchContributingValues(activities.models[i], contributingWhatValuesColl, bmVS);
                                var activitynsPath = self.fetchActivityNsPath(activities.models[i]);
                                activityColl.push({ name: activities.models[i].get('name'), originalId: activities.models[i].get('id'), modalId: self.htmlEscape(activities.models[i].get('id')), activitynsPath: activitynsPath });
                                self.sortCollection(activityColl);
                                var obj = { id: persuedId, bmPersued: persuedName, persuednsPath: persuednsPath, bmParticipantId: participantId, bmParticipant: participant, participantnsPath: participantnsPath, bmActivityRoleId: roleId, bmActivityRole: roleName, rolensPath: rolensPath, roleType: roleType, bmActivityColl: activityColl, bmCreatingValuesColl: creatingValuesColl, bmContributingValuesColl: contributingWhatValuesColl };
                                self.bmActivityList.push(obj);
                            }
                        }
                    }
                }
            });
            self.sortBMActivityList();
            self.bmActivityListCollection(self.bmActivityList);
        };
        fetchCustomers(roleColl, customerColl, customers, assignedRoles, rolesColl) {
            var self = this;
			for (var k = 0; k < customers.length; k++) {
				if (!customers.at(k).get('customer')) {
					continue;
				}
                var assignmentColl = self.assignmentsColl[customers.at(k).get('customer').get("id")];
                /*var participant = customers.at(k).get('customer');*/
                var cusRoleColl = [];
                var cond = false;
                if (assignmentColl && assignmentColl.models) {
                    for (var x = 0; x < assignmentColl.models.length; x++) {
                        var assignedRole = assignmentColl.models[x].get("assignedRole");
                        if (assignedRole && self.model.get('bmRole').findWhere({ id: assignedRole.get('id') })) {
                            var roleExists = customers.at(k).get('customerRole').findWhere({ id: assignedRole.get('id') });
                            if (roleExists && roleColl.indexOf(assignedRole) !== -1) {
                                cusRoleColl.push({ name: assignedRole.get("name"), originalId: assignedRole.get("id") });
                                assignedRoles.push({ name: assignedRole.get("name"), originalId: assignedRole.get("id") });
                                cond = true;
                            }
                        }
                    }
                }
                cusRoleColl.sort(self.sortRolesByName);
                if (cond) {
                    var customerNspath = self.getParticipantDispName(customers.at(k).get('customer'));
                    customerColl.push({ modalId: self.htmlEscape(customers.at(k).get("id")), name: customers.at(k).get("name"), customerNspath: customerNspath, originalId: customers.at(k).get("id"), roles: cusRoleColl });
                }
            }
        }

        fetchPartners(roleColl, partnerColl, partner, assignedRoles, rolesColl) {
            var self = this;
			for (var k = 0; k < partner.length; k++) {
				if (!partner.at(k).get('networkPartner')) {
					continue;
				}
                var assignmentColl = self.assignmentsColl[partner.at(k).get('networkPartner').get("id")];
                /* var participant = partner.at(k).get('networkPartner');*/
                var partRoleColl = [];
                var cond = false;
                if (assignmentColl && assignmentColl.models) {
                    for (var x = 0; x < assignmentColl.models.length; x++) {
                        var assignedRole = assignmentColl.models[x].get("assignedRole");
                        if (assignedRole && self.model.get('bmRole').findWhere({ id: assignedRole.get('id') })) {
                            var roleExists = partner.at(k).get('networkPartnerRole').findWhere({ id: assignedRole.get('id') });
                            if (roleExists && roleColl.indexOf(assignedRole) !== -1) {
                                partRoleColl.push({ name: assignedRole.get("name"), originalId: assignedRole.get("id") });
                                assignedRoles.push({ name: assignedRole.get("name"), originalId: assignedRole.get("id") });
                                cond = true;
                            }
                        }
                    }
                }
                partRoleColl.sort(self.sortRolesByName);
                if (cond) {
                    var partnerNspath = self.getParticipantDispName(partner.at(k).get('networkPartner'));
                    partnerColl.push({ modalId: self.htmlEscape(partner.at(k).get("id")), name: partner.at(k).get("name"), partnerNspath: partnerNspath, originalId: partner.at(k).get("id"), roles: partRoleColl });
                }
            }
        }

        fetchBusiness(roleColl, businessColl, orgBusiness, assignedRoles) {
            var self = this;
            var busRoleColl = [];
            var cond = false;
            var bmRoles = self.model.get('bmRole');
            var assignmentColl = self.assignmentsColl[orgBusiness.get("id")];
            var businessNspath = self.getParticipantDispName(orgBusiness);
            if (assignmentColl.models) {
                for (var x = 0; x < assignmentColl.models.length; x++) {
                    var assignedRole = assignmentColl.models[x].get("assignedRole");
                    if (assignedRole) {
                        var roleExists = bmRoles.findWhere({ id: assignedRole.get('id') });
                        if (roleExists && roleColl.indexOf(assignedRole) !== -1) {
                            busRoleColl.push({ name: assignedRole.get("name"), originalId: assignedRole.get("id") });
                            assignedRoles.push({ name: assignedRole.get("name"), originalId: assignedRole.get("id") });
                            cond = true;
                        }
                    }
                }
            }
            busRoleColl.sort(self.sortRolesByName);
            if (cond) {
                businessColl.push({ modalId: self.htmlEscape(orgBusiness.get("id")) + "business", name: orgBusiness.get("name"), businessNspath: businessNspath, originalId: orgBusiness.get("id"), businessId: orgBusiness.get("id"), roles: busRoleColl });
            }
        };
        checkunassignedRoles(notAssignedRoles, roleId) {
            var self = this;
            for (var m = 0; m < notAssignedRoles.length; m++) {
                if (notAssignedRoles[m].id === roleId) {
                    return false;
                }
            }
            return true;
        }

        checkExists(coll, value) {
            var self = this;
            for (var i = 0; i < coll.length; i++) {
                if (coll[i].originalId === value.originalId) {
                    return true;
                }
            }
            return false;
        };

        fetchUnattachedBusiness(roleColl, notAssigned, business) {
            var self = this;
            for (var i = 0; i < roleColl.length; i++) {
                var roleAssignments = roleColl[i].get('roleAssignment');
                for (var j = 0; j < roleAssignments.length; j++) {
                    if (roleAssignments.at(j).get('participant') === business) {
                        var cond = self.model.canCollaborationBeParticipantNetwork(roleAssignments.at(j).get('participant'), self.mainScenario());
                        if (cond && self.checkunassignedRoles(notAssigned, roleColl[i].get('id'))) {
                            self.insertUnassignedRoles(notAssigned, roleColl[i], roleAssignments.at(j).get('participant'));
                            break;
                        }
                    }
                }
            }
        };

        fetchUnattachedPartner(roleColl, notAssigned, partner) {
            var self = this;
            for (var i = 0; i < roleColl.length; i++) {
                for (var k = 0; k < partner.length; k++) {
                    var roleExists = partner.at(k).get('networkPartnerRole').findWhere({ id: roleColl[i].get('id') });
                    if (roleExists) {
                        var roleAssignments = roleColl[i].get('roleAssignment');
                        for (var j = 0; j < roleAssignments.length; j++) {
                            if (roleAssignments.at(j).get('participant') === partner.at(k).get('networkPartner')) {
                                var cond = self.model.canCollaborationBeParticipantNetwork(roleAssignments.at(j).get('participant'), self.mainScenario());
                                if (cond && self.checkunassignedRoles(notAssigned, roleColl[i].get('id'))) {
                                    self.insertUnassignedRoles(notAssigned, roleColl[i], roleAssignments.at(j).get('participant'));
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        };

        fetchUnattachedCustomer(roleColl, notAssigned, customer) {
            var self = this;
            for (var i = 0; i < roleColl.length; i++) {
                for (var k = 0; k < customer.length; k++) {
                    var roleExists = customer.at(k).get('customerRole').findWhere({ id: roleColl[i].get('id') });
                    if (roleExists) {
                        var roleAssignments = roleColl[i].get('roleAssignment');
                        for (var j = 0; j < roleAssignments.length; j++) {
                            if (roleAssignments.at(j).get('participant') === customer.at(k).get('customer')) {
                                var cond = self.model.canCollaborationBeParticipantNetwork(roleAssignments.at(j).get('participant'), self.mainScenario());
                                if (cond && self.checkunassignedRoles(notAssigned, roleColl[i].get('id'))) {
                                    self.insertUnassignedRoles(notAssigned, roleColl[i], roleAssignments.at(j).get('participant'));
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        };

        getPlanCollaborations(callback) {
            var self = this;
            var collabs = [];
            //var alternativeId = DataManager.getDataManager().get('currentWorkspace').get('id');
            DataManager.getDataManager().getAllDocumentsCollectionOfType(self.model.getNestedParent(), 'vdml_Collaboration', function (result) {
                var count = result.length;
                function fetchCollaboration() {
                    count--;
                    if (count >= 0) {
                        var vdmlType = result.at(count).get('type').replace('_', '.');
                        var collModal = Backbone.Relational.store.getObjectByName('' + vdmlType).find({ id: result.at(count).get('id') });
                        var phaseDesignParts = self.currentAlternativeModel.get('phaseDesignPart');
                        if (collModal && phaseDesignParts.findWhere({ 'beepReference': collModal.getNestedParent().id })) {
                            collabs.push(collModal);
                        }
                        fetchCollaboration();
                    } else {
                        callback(collabs);
                    }
                }
                fetchCollaboration();
            }, false, null, null, null, null, true, false);
        };
        fetchCollaborations(wsData) {
            var self = this;
            var rdfModel = wsData.get('rdfModel');
            if (rdfModel) {
                var addCollaborations = function (collaboration) {
                    var collCount = collaboration.length;
                    var unattachedCollaborationArray = [];
                    var participantCollectionArray = [];
                    function handleCollaboration() {
                        collCount--;
                        if (collCount >= 0 && self.model.canCollaborationBeParticipantNetwork(collaboration[collCount], self.mainScenario())) {
                            var attached = false;
                            var tempPNModel = null;
                            var roleColl = collaboration[collCount].get("collaborationRole").models;
                            var assignedRoles = [];
                            var customerColl = [];
                            var partnerColl = [];
                            var rolesColl = [];
                            var businessColl = [];
                            var notAssignedRoles = [];
                            var pnDisplayname = "";
                            function handleAttachedCollaborations() {
                                var participantNetwork = self.model.get("participantNetwork");
                                for (var j = 0; j < participantNetwork.length; j++) {
                                    if (participantNetwork.models[j].get("collaboration").get("id") === collaboration[collCount].get('id')) {
                                        attached = true;
                                        tempPNModel = participantNetwork.models[j];
                                        pnDisplayname = self.pnNamespacePath(participantNetwork.models, tempPNModel);
                                        if (self.model.get('business')) {
                                            self.fetchBusiness(roleColl, businessColl, self.model.get("business"), assignedRoles);
                                        }
                                        self.fetchCustomers(roleColl, customerColl, self.model.get('bmCustomer'), assignedRoles, rolesColl);
                                        self.fetchPartners(roleColl, partnerColl, self.model.get('bmNetworkPartner'), assignedRoles, rolesColl);
                                        self.collaborations.add(collaboration[collCount]);
                                        break;
                                    }
                                }
                                if (attached) {
                                    self.updateNotassignedRoles(roleColl, assignedRoles, notAssignedRoles, true);
                                    updateParticipant();
                                } else {
                                    self.collaborations.add(collaboration[collCount]);
                                    if (collaboration[collCount].get('type') === "vdml_BusinessNetwork") {
                                        unattachedCollaborationArray.push(collaboration[collCount]);
                                    }
                                    handleCollaboration();
                                }
                            }
                            function updateParticipant() {
                                var obj = { seqNo: collCount, originalId: collaboration[collCount].get('id'), name: tempPNModel.get('name'), pnDisplayname: pnDisplayname, businessColl: businessColl, customerColl: customerColl, partnerColl: partnerColl, attached: attached, notAssigned: notAssignedRoles, rolesColl: rolesColl };
                                participantCollectionArray.push(obj);

                                handleCollaboration();
                            }
                            handleAttachedCollaborations();
                        } else {
                            if (collCount >= 0) {
                                handleCollaboration();
                            }
                            else {
                                self.sortCollection(participantCollectionArray);
                                self.participantCollection.push.apply(self.participantCollection, participantCollectionArray);
                                async.eachSeries(unattachedCollaborationArray, _.bind(self.handleUnAttachedCollaboration, self), function (err) {
                                    for (var i = 0; i < unattachedCollaborationArray.length; i++) {
                                        for (var j = 0; j < self.participantCollection().length; j++) {
                                            if ((unattachedCollaborationArray[i].get('name') === self.participantCollection()[j].name) && (unattachedCollaborationArray[i].get('id') !== self.participantCollection()[j].originalId) && !self.participantCollection()[j].attached) {
                                                var PnDisplayname = "   [";
                                                PnDisplayname = PnDisplayname.concat(unattachedCollaborationArray[i].getNestedParent().get('name') + "]");
                                                var obj = { seqNo: self.participantCollection()[j].seqNo, originalId: self.participantCollection()[j].originalId, name: self.participantCollection()[j].name, pnDisplayname: PnDisplayname, businessColl: self.participantCollection()[j].businessColl, customerColl: self.participantCollection()[j].customerColl, partnerColl: self.participantCollection()[j].partnerColl, attached: false, notAssigned: self.participantCollection()[j].notAssigned, rolesColl: self.participantCollection()[j].rolesColl };
                                                self.participantCollection.replace(self.participantCollection()[j], obj);
                                            }
                                        }
                                    }
                                });
                                self.participantCollection.sort(self.sortRolesByName);
                                self.selectedFilter('Attached');
                            }
                        }
                    } // end handleCollaboration

                    handleCollaboration();
                };
                self.getPlanCollaborations(addCollaborations);
            }
        };
        getPackageNamespacepath(model) {
            var self = this;
            var displayname = "   [";
            for (var k = 0; k < model.getPackagePath().length - 1; k++) {
                displayname = displayname.concat(model.getPackagePath()[k].name);
                if (model.getPackagePath()[k + 2]) {
                    displayname = displayname.concat("/");
                }
            }
            displayname = displayname.concat("]");
            return displayname;
        }

        pnNamespacePath(pNs, pn) {
            var self = this;
            var displayname = "";
            for (var i = 0; i < pNs.length; i++) {
                if (pNs[i]) {
                    if ((pNs[i].get('name') == pn.get('name')) && (pNs[i].id !== pn.id)) {
                        displayname = self.getPackageNamespacepath(pn);
                        break;
                    }
                }
                else {
                    if ((pNs.at(i).get('name') == pn.get('name')) && (pNs.at(i).id !== pn.id)) {
                        displayname = self.getPackageNamespacepath(pn);
                        break;
                    }
                }
            }
            return displayname;
        }
        getPropositionDispName(vps, vp) {
            var self = this;
            var displayname = "";
            for (var i = 0; i < vps.length; i++) {
                if ((vps.at(i).get('name') == vp.get('name')) && (vps.at(i).get('id') !== vp.get('id'))) {
                    displayname = "   [";
                    for (var k = 0; k < vp.getPackagePath().length - 1; k++) {
                        displayname = displayname.concat(vp.getPackagePath()[k].name);
                        if (vp.getPackagePath()[k + 2]) {
                            displayname = displayname.concat("  /  ");
                        }
                    }
                    displayname = displayname.concat("]");
                }
            }
            return displayname;
        }
        getRoleDispName(role) {
            var self = this;
            var roleModel;
            var roleNS = "";
            var roles = self.model.get('bmRole');
            if (typeof role == "string") {
                roleModel = roles.findWhere({ id: role });
            } else {
                roleModel = role;
            }
            if (roleModel) {
                for (var i = 0; i < roles.length; i++) {
                    if ((roles.at(i).get('name') === roleModel.get('name')) && (roles.at(i).get('id') !== roleModel.get('id'))) {
                        roleNS = " [";
                        for (var k = 0; k < roleModel.getPackagePath().length - 1; k++) {
                            roleNS = roleNS.concat(roleModel.getPackagePath()[k].name);
                            if (roleModel.getPackagePath()[k + 2]) {
                                roleNS = roleNS.concat("/");
                            }
                        }
                        roleNS = roleNS.concat("]");
                    }
                }
            }
            return roleNS;
        }
        fillRolepath(role) {
            var self = this;
            var displayName = "";
            var found = false;
            for (var i = 0; i < self.model.get('bmCustomer').length; i++) {
                var bmCustomer = self.model.get('bmCustomer').at(i).get('customerRole').findWhere({ id: role.get('id') });
                if (bmCustomer) {
                    displayName = " (Customer)";
                    found = true;
                    break;
                }
            }
            if (!found) {
                for (var i = 0; i < self.model.get('bmNetworkPartner').length; i++) {
                    var bmPartner = self.model.get('bmNetworkPartner').at(i).get('networkPartnerRole').findWhere({ id: role.get('id') });
                    if (bmPartner) {
                        displayName = " (Partner)";
                        found = true;
                        break;
                    }
                }
            }
            if (!found) {
                displayName = " (Business)";
            }
            return displayName;
        }
        getParticipantDispName(participant) {
            var self = this;
            var nspath = "";
            var participantsArray = self.model.getParticipants();
            if (participant) {
                for (var i = 0; i < participantsArray.length; i++) {
                    if ((participantsArray[i].get('name') === participant.get('name')) && (participantsArray[i].get('id') !== participant.get('id'))) {
                        nspath = "[" + participant.getNestedParent().get('name') + "]";
                    }
                }
            }
            return nspath;
        }

        handleUnAttachedCollaboration(coll, callback) {
            var self = this;
            var roleColl = coll.get("collaborationRole").models;
            var collId = coll.get('id');
            var assignedRoles = [];
            var customerColl = [];
            var partnerColl = [];
            var rolesColl = [];
            var businessColl = [];
            var notAssignedRoles = [];
            var PnDisplayname = "";
            var tempPNModel = null;
            function addParticipantNetworks(pnModels) {
                for (var j = 0; j < pnModels.length; j++) {
                    if (pnModels[j].get("collaboration") && pnModels[j].get("collaboration").get("id") === collId) {
                        tempPNModel = pnModels[j];
                        var bm = pnModels[j].get("participantNetworkOwner");
                        self.fetchUnattachedBusiness(roleColl, notAssignedRoles, bm.get("business"));
                        self.fetchUnattachedCustomer(roleColl, notAssignedRoles, bm.get('bmCustomer'));
                        self.fetchUnattachedPartner(roleColl, notAssignedRoles, bm.get('bmNetworkPartner'));
                        self.updateNotassignedRoles(roleColl, assignedRoles, notAssignedRoles, false);
                        if (pnModels.length - 1 == j) {
                            var obj = { seqNo: self.participantCollection().length, originalId: coll.get('id'), name: tempPNModel.get('name'), pnDisplayname: PnDisplayname, businessColl: businessColl, customerColl: customerColl, partnerColl: partnerColl, attached: false, notAssigned: notAssignedRoles, rolesColl: rolesColl };
                            self.participantCollection.push(obj);
                            callback();
                        }
                    }
                }
                if (pnModels.length == 0) {
                    callback();
                }
            }
            self.participantCollection.sort(self.sortRolesByName);
            var tempRdfModel = DataManager.getDataManager().getRDFModel(DataManager.getDataManager().getRepositoryId(collId));
            tempRdfModel.getParticipantNetworks(collId, addParticipantNetworks);
        }
        updateNotassignedRoles(roleColl, assignedRoles, notAssignedRoles, handleAttached) {
            var self = this;
            var roleExists = false;
            var bmRoles = self.model.get('bmRole');
            for (var i = 0; i < roleColl.length; i++) {
                for (var j = 0; j < assignedRoles.length; j++) {
                    if (roleColl[i].id === assignedRoles[j].originalId) {
                        roleExists = true;
                        break;
                    }
                }
                if (!roleExists) {
                    var roleAssign = roleColl[i].get('roleAssignment');
                    if (roleAssign.length == 0 && self.checkunassignedRoles(notAssignedRoles, roleColl[i].get('id'))) {
                        notAssignedRoles.push({ id: roleColl[i].get('id'), name: roleColl[i].get('name'), utilized: false });
                    }
                    else {
                        if (handleAttached) {
                            if (!bmRoles.findWhere({ 'id': roleColl[i].id })) {
                                for (var k = 0; k < roleAssign.length; k++) {
                                    var part = roleAssign.at(k).get('participant');
                                    var partname = "";
                                    if (part && part.getNestedParent() == self.model.getNestedParent()) {
                                        partname = part.get('name');
                                    }
                                    var displayName = partname + ' (' + roleColl[i].get('name') + ')';
                                    self.insertUnassignedRoles(notAssignedRoles, roleColl[i], part);
                                }
                            }
                        }
                        else {
                            for (var k = 0; k < roleAssign.length; k++) {
                                var part = roleAssign.at(k).get('participant');
                                var partname = "";
                                if (part) {
                                    partname = part.get('name');
                                }
                                var displayName = partname + ' (' + roleColl[i].get('name') + ')';
                                var cond = self.model.canCollaborationBeParticipantNetwork(part, self.mainScenario());
                                if (!cond && self.checkunassignedRoles(notAssignedRoles, roleColl[i].get('id'))) {
                                    self.insertUnassignedRoles(notAssignedRoles, roleColl[i], part);
                                }
                            }
                        }
                    }
                }
                roleExists = false;
            }
        };
        insertUnassignedRoles(notAssignedRoles, role, particpant) {
            var self = this;
            var partname = "";
            if (particpant) {
                var insert = true;
                for (var i = 0; i < notAssignedRoles.length; i++) {
                    if (notAssignedRoles[i].partId == particpant.get('id')) {
                        var newRoleName = notAssignedRoles[i].name;
                        newRoleName = newRoleName.substring(0, newRoleName.length - 1);
                        newRoleName = newRoleName.concat(', ' + role.get('name') + ')');
                        notAssignedRoles[i].name = newRoleName;
                        insert = false;
                        break;
                    }
                }
                partname = particpant.get('name');
            }
            var displayName = partname + ' (' + role.get('name') + ')';
            if (insert) {
                notAssignedRoles.push({ id: role.get('id'), partId: particpant.get('id'), name: displayName, utilized: false });
            }
        }
        fetchParticipantNetworkNames(participant) {
            var self = this;
            var assignmentColl = self.assignmentsColl[participant.get("id")];
            var name = " (";
            for (var i = 0; i < assignmentColl.length; i++) {
                if (i > 0) {
                    name = name.concat(',');
                }
                var coll = assignmentColl.at(i).get('assignedRole').get('collaborationRoleOwner');
                name = name.concat('' + coll.get('name'));
                if (assignmentColl.length - 1 == i) {
                    name = name.concat(')');
                }
            }
            return name;
        };
        fetchAssignmentsforParticipants() {
            var self = this;
            var participantColl = [];
            if (self.model.get("business")) {
                participantColl.push(self.model.get("business"));
            }
            var bmCustomers = self.model.get("bmCustomer");
            for (var i = 0; i < bmCustomers.length; i++) {
                participantColl.push(bmCustomers.at(i).get("customer"));
            }
            var bmPartners = self.model.get("bmNetworkPartner");
            for (var j = 0; j < bmPartners.length; j++) {
                participantColl.push(bmPartners.at(j).get("networkPartner"));
            }
            function fetchAssigments(participant, callback) {
                if (!participant) {
                    callback();
                    return;
                }
                var assignmentCollection = this;
                function addAssignments(assignments) {
                    assignmentCollection[participant.get('id')] = assignments;
                    callback();
                }
                DataManager.getDataManager().getAssignments(participant, participant.getNestedParent().get('id'), addAssignments, true);
            }
            async.each(participantColl, fetchAssigments.bind(self.assignmentsColl), function (err) {
                //self.loadBmContents = true;
                if (self.mainScenario()) {
                    DataManager.getDataManager().getWorkspaceDataWithId(self.alternative, _.bind(self.fetchCollaborations, self));
                    //fillBMVPList();
                    self.fillVPFilterRoles();
                    self.fillBMVFList();
                    self.fillBMActivityList();
                    self.fillBusinessRoles();
                    self.fillBmCompetency();
                }
                self.fillProviders();
                self.fillReceivers();
                self.fillActivityProviders();
            });
        };

        updateValuesTabDetails(view) {
            var self = this;
            self.valueName(view.name());
            self.valueData(view.measurementValue() + " " + view.measurementUnit());
            self.valueFormula(view.formula());
            self.valueSatisfaction(view.satisfaction());
            self.valueSatisfactionSmiley($('#showSatisfactionImg' + view.encodeId).attr('d'));
            self.valueSmileyColor($('#showSatisfactionImg' + view.encodeId).attr('fill'));
            //self.valueRecipient(view.recipientValue());
            var isCollective = false;
            if (view.structuralChangeCount > 0 && !view.enableMeasurement()) {
                isCollective = true;
            }
            self.isCollective(isCollective);
        }
		getValueModal(id) {
			var model = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({id:id});
			if(!model){
				model = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({id:id});
			}
			return model;
		}
        loadValuesHandler(view, event) {
            var self = this;
            self.cancelValue();
            self.valueModel = self.getValueModal(view.id);
			if(!self.valueModel){
				bootbox.alert('Value does not exist.');
			}
            self.valueName(self.valueModel.get('name'));
            self.isCollective(view.isCollective);
            var valData = self.getMeasurementValueandUnit(self.valueModel, self.valueModel.get('valueMeasurement'));
            valData = valData['value'] ? valData['value'] + " " + valData['unit'] : "";
            self.valueData(valData);
            var compOwner = self.valueModel.get('componentOwner') ? self.valueModel.get('componentOwner') : self.valueModel.get('valueAddOwner');
            if (self.valueModel.get('valueAddOwner')) {
                var vdmlPackage = compOwner.getNestedParent();
                var valueStreams = vdmlPackage.get('valueStream');
                self.selectedValueStream(null);
                self.selectedValueStreamModel = null;
                for (var i = 0; i < valueStreams.length; i++) {
                    if (valueStreams.at(i).get('persuedProposition') && valueStreams.at(i).get('persuedProposition').get('provider').get('id') === self.valueModel.get('valueAddOwner').get('containedPortOwner').get('performingRole').id) {
                        self.selectedValueStream(valueStreams.at(i).get('id'));
                        self.selectedValueStreamModel = valueStreams.at(i);
                        break;
                    }
                }
            }
            self.valueFormula(view.formula);
            self.valueSatisfaction(view.satisfaction);
            self.valueSatisfactionSmiley(view.satisfactionSmiley);
            self.valueSmileyColor(view.smileyColor);
            //self.valueRecipient(view.recipient);
            self.valueSourceType(self.getSourceType(self.valueModel));
            if (self.valueSourceType() === "Activity") {
                self.valueSource(compOwner.get('containedPortOwner').get('name'));
            }
            else {
                self.valueSource(compOwner.get('name'));
            }
            self.valueFromRole(view.from);
            self.valueToRole(view.to);
            $('#valueFromRolePartName').text(view.providerParticipantName);
            $('#fromRoleType').text(view.providerType);
            $('#valueToRolePartName').text(view.recieverParticipantName);
            $('#toRoleType').text(view.recieverType);
            $(event.currentTarget).addClass('highlightrow').siblings().removeClass('highlightrow');
            self.canClickCompleteValues(true);
        }
        getSourceType(component) {
            var self = this;
            var type = "";
            if (component.get('type') === "vdml_ValueAdd") {
                type = self.labels['Activity']();
            }
            else {
                var componentOwner = component.get('componentOwner');
                if (componentOwner.get('isValueFormula')) {
                    type = self.labels['MyProposition']();
                }
                else {
                    if (componentOwner.get('planCriterionSetOwner')) {
                        type = self.labels['Plan']();
                    }
                    else {
                        type = self.labels['ValueProposition']();
                    }
                }
            }
            return type;
        }
        cancelValue() {
            var self = this;
            self.canClickCompleteValues(false);
            self.valueName("");
            self.valueData("");
            self.valueFormula("");
            self.valueSatisfaction("");
            self.valueSatisfactionSmiley("");
            self.valueSmileyColor("");
            //self.valueRecipient("");
            self.valueSource("");
            self.valueSourceType("");
            self.valueFromRole("");
            self.valueToRole("");
            self.valueModel = null;
            self.clearChildren("valueTab_valueFormula");
            self.clearChildren("valueTab_valueProposition");
            self.clearChildren("valueTab_activity");
            self.clearChildren("valueTab_createWhatValues");
            self.clearChildren("valueProposition");
            self.clearChildren("valueFormula");
            self.clearChildren("activity");
            self.clearChildren("createWhatValues");
            self.clearChildren("contributeWhatValues");
            self.clearChildren("competency");
            self.children = [];
            $('#valuesTable .highlightrow').removeClass('highlightrow');
        }

        saveValues() {
            var self = this;
            var child;
            for (var i = 0; i < self.children.length; i++) {
                if (self.children[i].type.indexOf("valueTab_") != -1) {
                    child = self.children[i].view;
                    break;
                }
            }
            if (child) {
                var structuralChange = child.structuralChangeCount > 0 ? true : false;
                self.createRevisionBasedOnContext(structuralChange, function () {
                    window.utils.startSpinner('saveValue', 'Saving Value ...');
                    window.setTimeout(function () {
                        if (self.valueModel.get('componentOwner')) {
                            child.saveData(self.model, self.valueModel.get('componentOwner').get('valueProposition'), saveCallback);
                        }
                        else {
                            var actModelId = self.valueModel.get('valueAddOwner').get('containedPortOwner').get('id');
                            var actColl = [{ modalId: self.htmlEscape(actModelId), originalId: actModelId }];
                            child.saveWhatValuesData(self.model, actColl, [], function (activityValue, changeInValue) {
                                saveCallback(changeInValue);
                            });
                        }
                    }, 100);
                });
            } else {
                self.cancelValue();
            }
            function saveCallback(changeInValue) {
                if (window.checkContextForRevision() && structuralChange) {
                    self.disableAllCompleteButtons();
                    window.utils.stopSpinner('saveValue');
                    DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                }
                else if (changeInValue) {
                    DataManager.getDataManager().invokeValueUpdates(function () {
                        self.refreshWidgetData(true);
                        self.cancelValue();
                        self.resetTables(function () {
                            window.utils.stopSpinner('saveValue');
                            //self.setResizeHeight();	
                        });
                    });
                } else {
                    self.cancelValue();
                    self.fillValuesTab();
                    window.utils.stopSpinner('saveValue');
                }
            }
        }
        clearChildren(type) {
            var self = this;
            for (var j = 0; j < self.children.length; j++) {
                if (!type) {
                    window.cleanDialogModel(self.children[j].modalId, self.children[j].view);
                }
                else if (self.children[j].view && self.children[j].type == type) {
                    window.cleanDialogModel(self.children[j].modalId, self.children[j].view);
                }
            }
        };

        updateView(type, modalId, addOptions) {
            var self = this;
            for (var i = 0; i < self.children.length; i++) {
                if (self.children[i].type == '' + type) {
                    if (modalId === self.children[i].modalId) {
                        window.updateView(self.children[i].view, self.children[i].oldData);
                        if (addOptions) {
                            self.children[i].view.setOptions(addOptions);
                        }
                        break;
                    }
                }
            }
        }
        addChildModel(view, modalId, type, del, isValTab) {
            var self = this;
            //$(document.documentElement).css('overflow', '');
            if (self.valueModel && isValTab) {
                type = "valueTab_" + type;
            }
            var count = 0;
            for (var i = 0; i < self.children.length; i++) {
                if (self.children[i].modalId === view.encodeId) {
                    if (del) {
                        self.children.splice(i, 1);
                        break;
                    }
                    else {
                        count = 1;
                        self.children[i].view = view;
                        self.children[i].oldData = window.getViewData(view);
                        var id = "" + modalId + "" + type + "Name";
                        $("#" + id).html(self.children[i].view.name());
                        self.updateExistingChildModel(view, modalId, type);
                        break;
                    }
                }
            }
            if (count === 0 && !del) {
                var existing = view.model ? true : false;
                var originalId = view.id() ? view.id() : "";
                var oldData = window.getViewData(view);
                self.children.push({ 'modalId': modalId, 'view': view, 'existing': existing, 'originalId': originalId, 'type': type, 'oldData': oldData });
                if (!view.model) {
                    self.updateNewChildModel(view, modalId, type);
                }
                else {
                    self.updateExistingChildModel(view, modalId, type);
                }
            }
            if (del) {
                self.deleteChildModel(view, modalId, type);
            }
            if (self.valueModel && isValTab) {
                self.updateValuesTabDetails(view);
            }
        }
        deleteChildModel(view, modalId, type, manual) {
            var self = this;
            var tempView;
            if (self.pNCollaborationModal && view.id()) {
                for (var i = 0; i < self.participantCollection().length; i++) {
                    if (self.participantCollection()[i].originalId == self.pNCollaborationModal.get("id")) {
                        tempView = self.participantCollection()[i];
                        break;
                    }
                }
            }
            if (type === "customer") {
                for (var i = 0; i < self.customerCollection().length; i++) {
                    if (self.customerCollection()[i].modalId == modalId) {
                        if (tempView) {
                            for (var j = 0; j < tempView.customerColl.length; j++) {
                                if (tempView.customerColl[j].modalId == modalId) {
                                    manual = manual ? manual : true;
                                    tempView.customerColl[j].manual = manual;
                                    self.customerCollection.replace(self.customerCollection()[i], tempView.customerColl[j]);
                                    self.customerCollection.destroy(self.customerCollection()[i]);
                                    self.deleteParticipantArray.push(self.customerCollection()[i]);
                                    break;
                                }
                            }
                        }
                        else {
                            self.customerCollection.splice(i, 1);
                        }
                        var len = self.removeRoleArray.length;
                        while (len) {
                            len--;
                            if (self.removeRoleArray[len].newModalId == modalId) {
                                self.removeRoleArray.splice(len, 1);
                            }
                        }
                        self.participantNetworkChanges = true;
                    }
                }
            }
            else if (type === "partner") {
                for (var x = 0; x < self.partnerCollection().length; x++) {
                    if (self.partnerCollection()[x].modalId == modalId) {
                        if (tempView) {
                            for (var y = 0; y < tempView.partnerColl.length; y++) {
                                if (tempView.partnerColl[y].modalId == modalId) {
                                    manual = manual ? manual : true;
                                    tempView.partnerColl[y].manual = manual;
                                    self.partnerCollection.replace(self.partnerCollection()[x], tempView.partnerColl[y]);
                                    self.partnerCollection.destroy(self.partnerCollection()[x]);
                                    self.deleteParticipantArray.push(self.partnerCollection()[x]);
                                    break;
                                }
                            }
                        }
                        else {
                            self.partnerCollection.splice(x, 1);
                        }
                        var len = self.removeRoleArray.length;
                        while (len) {
                            len--;
                            if (self.removeRoleArray[len].newModalId == modalId) {
                                self.removeRoleArray.splice(len, 1);
                            }
                        }
                        self.participantNetworkChanges = true;
                    }
                }
            }
            else if (type === "business") {
                for (var i = 0; i < self.businessCollection().length; i++) {
                    if (self.businessCollection()[i].modalId == modalId) {
                        if (tempView) {
                            for (var y = 0; y < tempView.businessColl.length; y++) {
                                self.businessCollection.replace(self.businessCollection()[i], tempView.businessColl[y]);
                            }
                        }
                        else {
                            self.businessCollection([]);
                            self.businessCollection.push({ modalId: modalId, name: self.business().get('name'), originalId: self.model.get('business').get("id"), businessId: self.model.get('business').get("id"), roles: self.businessRole });
                        }
                        if (manual == false) {
                            self.deleteParticipantArray.push(self.businessCollection()[i]);
                            self.businessCollection([]);
                        }
                        var len = self.removeRoleArray.length;
                        while (len) {
                            len--;
                            if (self.removeRoleArray[len].newModalId == modalId) {
                                self.removeRoleArray.splice(len, 1);
                            }
                        }
                        //self.participantNetworkChanges = true;
                    }
                }
            }
            else if (type === "activity") {
                if (view.id()) {
                    for (var i = 0; i < self.bmActivityListCollection().length; i++) {
                        if (self.bmActivityListCollection()[i].id == self.selectedPersued() && self.bmActivityListCollection()[i].bmActivityRoleId == self.selectedActivityProviderRole()) {
                            tempView = self.bmActivityListCollection()[i];
                            break;
                        }
                    }
                }
                for (var x = 0; x < self.activityCollection().length; x++) {
                    if (self.activityCollection()[x].modalId === modalId) {
                        if (tempView) {
                            for (var y = 0; y < tempView.bmActivityColl.length; y++) {
                                if (tempView.bmActivityColl[y].modalId === modalId) {
                                    self.activityCollection.replace(self.activityCollection()[x], tempView.bmActivityColl[y]);
                                    self.activityCollection.destroy(self.activityCollection()[x]);
                                    for (var z = 0; z < self.activityCreateWhatValuesColl().length; z++) {
                                        if (self.activityCreateWhatValuesColl()[z].activityModalId === modalId) {
                                            tempView = self.activityCreateWhatValuesColl()[z];
                                            if (tempView) {
                                                self.activityCreateWhatValuesColl.replace(self.activityCreateWhatValuesColl()[z], tempView);
                                                self.activityCreateWhatValuesColl.destroy(self.activityCreateWhatValuesColl()[z]);
                                            }
                                        }
                                    }
                                    self.sortCollection(self.activityCreateWhatValuesColl);
                                    break;
                                }
                            }
                        }
                        else {
                            self.activityCollection.splice(x, 1);
                            for (var y = 0; y < self.activityCreateWhatValuesColl().length; y++) {
                                if (self.activityCreateWhatValuesColl()[y].activityModalId === modalId) {
                                    tempView = self.activityCreateWhatValuesColl()[y];
                                    if (tempView) {
                                        self.activityCreateWhatValuesColl.replace(self.activityCreateWhatValuesColl()[y], tempView);
                                        self.activityCreateWhatValuesColl.destroy(self.activityCreateWhatValuesColl()[y]);
                                        if (!self.activityCreateWhatValuesColl()[y].originalId) {
                                            for (var i = 0; i < self.children.length; i++) {
                                                if (self.children[i].modalId === self.activityCreateWhatValuesColl()[y].modalId) {
                                                    self.children.splice(i, 1);
                                                    break;
                                                }
                                            }
                                        }
                                        self.sortCollection(self.activityCreateWhatValuesColl);
                                    }
                                }
                            }
                        }
                        for (var z = 0; z < self.activityContributeWhatValuesColl().length; z++) {
                            var activityIds = self.activityContributeWhatValuesColl()[z].activityIds;
                            var foundAnotherActivity = false;
                            for (var i = 0; i < activityIds.length; i++) {
                                var act = _.filter(self.activityCreateWhatValuesColl(), function (num) { if (num.originalId === activityIds[i] && !num._destroy) { return num } })
                                if (act && act.length > 0) {
                                    foundAnotherActivity = true;
                                    break;
                                }
                            }
                            if (!foundAnotherActivity) {
                                for (var j = 0; j < activityIds.length; j++) {
                                    var aggregatedId = self.activityContributeWhatValuesColl()[z].activityIds[j];
                                    var aggComp = self.getValueModal(aggregatedId);
                                    if (aggComp) {
                                        var aggCompActId = aggComp.get('valueAddOwner').get('containedPortOwner').get('id');
                                        if (self.htmlEscape(aggCompActId) === modalId) {
                                            tempView = self.activityContributeWhatValuesColl()[z];
                                            if (tempView) {
                                                for (var i = 0; i < self.children.length; i++) {
                                                    if (self.children[i].modalId === self.activityContributeWhatValuesColl()[z].modalId) {
                                                        window.cleanDialogModel(self.children[i].modalId, self.children[i].view);
                                                        self.children.splice(i, 1);
                                                        break;
                                                    }
                                                }
                                                self.activityContributeWhatValuesColl.replace(self.activityContributeWhatValuesColl()[z], tempView);
                                                self.activityContributeWhatValuesColl.destroy(self.activityContributeWhatValuesColl()[z]);
                                            }
                                            else {
                                                self.activityContributeWhatValuesColl.splice(z, 1);
                                                self.sortCollection(self.activityContributeWhatValuesColl);
                                            }
                                            break;
                                        }
                                    } else {
                                        for (var k = 0; k < self.activityCreateWhatValuesColl().length; k++) {
                                            if (self.activityCreateWhatValuesColl()[k].modalId === aggregatedId) {
                                                for (var i = 0; i < self.children.length; i++) {
                                                    if (self.children[i].modalId === self.activityContributeWhatValuesColl()[z].modalId) {
                                                        self.children.splice(i, 1);
                                                        break;
                                                    }
                                                }
                                                self.activityContributeWhatValuesColl.splice(z, 1);
                                            }
                                        }
                                    }
                                }
                            }

                        }
                        self.activityValueChanges = true;
                    }
                }
                if (self.activityCollection().length === 0) {
                    self.canClickCompleteActivity(false);
                }
                self.checkToEnableActivityValuesAddIcons();
            }
            else if (type === "competency") {
                if (view.id()) {
                    for (var i = 0; i < self.bmCompetencyListCollection().length; i++) {
                        if (self.bmCompetencyListCollection()[i].originalId == self.selectedCompetencyActivities()) {
                            tempView = self.bmCompetencyListCollection()[i];
                            break;
                        }
                    }
                }
                for (var x = 0; x < self.competencyCollection().length; x++) {
                    if (self.competencyCollection()[x].modalId === modalId) {
                        if (tempView) {
                            for (var y = 0; y < tempView.bmCompetencyColl.length; y++) {
                                if (tempView.bmCompetencyColl[y].modalId === modalId) {
                                    self.competencyCollection.replace(self.competencyCollection()[x], tempView.bmCompetencyColl[y]);
                                    self.competencyCollection.destroy(self.competencyCollection()[x]);
                                    break;
                                }
                            }
                        }
                        else {
                            self.competencyCollection.splice(x, 1);
                        }
                        self.competencyChanges = true;
                    }
                }
                self.checkCompetencyCompleteEnableClick();
            }
            else if (type === "valueProposition") {
                if (view.id()) {
                    for (var i = 0; i < self.bmVPListCollection().length; i++) {
                        if (self.bmVPListCollection()[i].id == self.selectedValueProposition().get('id')) {
                            tempView = self.bmVPListCollection()[i];
                            break;
                        }
                    }
                }
                for (var x = 0; x < self.valuePropositionValuesColl().length; x++) {
                    if (self.valuePropositionValuesColl()[x].modalId === modalId) {
                        if (tempView) {
                            /*for(var y=0;y<tempView.values.length;y++) {
                              if(tempView.values[y].modalId == modalId){
                                  self.valuePropositionValuesColl.replace(self.valuePropositionValuesColl()[x], tempView.values[y]);
                                  self.valuePropositionValuesColl.destroy(self.valuePropositionValuesColl()[x]);
                                  break;
                                 }
                              }*/
                            self.valuePropositionValuesColl.destroy(self.valuePropositionValuesColl()[x]);
                        }
                        else {
                            self.valuePropositionValuesColl.splice(x, 1);
                        }
                        self.valuePropositionChanges = true;
                    }
                }
            }
            else if (type === "valueFormula") {
                if (view.id()) {
                    for (var i = 0; i < self.bmVFListCollection().length; i++) {
                        if (self.bmVFListCollection()[i].id == self.selectedValueFormula().get('id')) {
                            tempView = self.bmVFListCollection()[i];
                            break;
                        }
                    }
                }
                for (var x = 0; x < self.valueFormulaValuesColl().length; x++) {
                    if (self.valueFormulaValuesColl()[x].modalId === modalId) {
                        if (tempView) {
                            /*for(var y=0;y<tempView.values.length;y++) {
                              if(tempView.values[y].modalId == modalId){
                                  self.valueFormulaValuesColl.replace(self.valueFormulaValuesColl()[x], tempView.values[y]);
                                  self.valueFormulaValuesColl.destroy(self.valueFormulaValuesColl()[x]);
                                  break;
                                 }
                              }*/
                            self.valueFormulaValuesColl.destroy(self.valueFormulaValuesColl()[x]);
                        }
                        else {
                            self.valueFormulaValuesColl.splice(x, 1);
                        }
                        self.myValuePropositionChanges = true;
                    }
                }
            }
            else if (type === "createWhatValues") {
                for (var x = 0; x < self.activityCreateWhatValuesColl().length; x++) {
                    if (self.activityCreateWhatValuesColl()[x].modalId === modalId) {
                        self.activityCreateWhatValuesColl.splice(x, 1);
                        self.sortCollection(self.activityCreateWhatValuesColl);
                        for (var z = 0; z < self.activityContributeWhatValuesColl().length; z++) {
                            var activityIds = self.activityContributeWhatValuesColl()[z].activityIds;
                            for (var j = 0; j < activityIds.length; j++) {
                                if (self.htmlEscape(self.activityContributeWhatValuesColl()[z].activityIds[j]) === modalId) {
                                    tempView = self.activityContributeWhatValuesColl()[z];
                                    if (tempView) {
                                        var foundAnotherActivity = false;
                                        for (var i = 0; i < activityIds.length; i++) {
                                            var act = _.filter(self.activityCreateWhatValuesColl(), function (num) { if (num.originalId === activityIds[i] && !num._destroy) { return num } })
                                            if (act && act.length > 0) {
                                                foundAnotherActivity = true;
                                                break;
                                            }
                                        }
                                        if (!foundAnotherActivity) {
                                            for (var i = 0; i < self.children.length; i++) {
                                                if (self.children[i].modalId === self.activityContributeWhatValuesColl()[z].modalId) {
                                                    window.cleanDialogModel(self.children[i].modalId, self.children[i].view);
                                                    self.children.splice(i, 1);
                                                    break;
                                                }
                                            }
                                            self.activityContributeWhatValuesColl.replace(self.activityContributeWhatValuesColl()[z], tempView);
                                            self.activityContributeWhatValuesColl.destroy(self.activityContributeWhatValuesColl()[z]);
                                        }

                                    }
                                    else {
                                        self.activityContributeWhatValuesColl.splice(z, 1);
                                        self.sortCollection(self.activityContributeWhatValuesColl);
                                    }
                                    self.activityValueChanges = true;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            else if (type === "contributeWhatValues") {
                for (var x = 0; x < self.activityContributeWhatValuesColl().length; x++) {
                    if (self.activityContributeWhatValuesColl()[x].modalId === modalId) {
                        tempView = self.activityContributeWhatValuesColl()[x];
                        if (tempView) {
                            self.activityContributeWhatValuesColl.replace(self.activityContributeWhatValuesColl()[x], tempView);
                            self.activityContributeWhatValuesColl.destroy(self.activityContributeWhatValuesColl()[x]);
                        }
                        else {
                            self.activityContributeWhatValuesColl.splice(x, 1);
                            self.sortCollection(self.activityContributeWhatValuesColl);
                        }
                        self.activityValueChanges = true;
                    }
                }
            }
            else {
            }
            window.cleanDialogModel(modalId, view);
        }
        updateExistingChildModel(view, modalId, type, recursive) {
            var self = this;
            if (type === "customer") {
                for (var i = 0; i < self.customerCollection().length; i++) {
                    if (self.customerCollection()[i].modalId == modalId) {
                        var obj = { modalId: modalId, name: view.name(), originalId: view.id(), roles: view.rolesColl(), del: false };
                        self.customerCollection.replace(self.customerCollection()[i], obj);
                        if (self.pNCollaborationModal && !recursive) {
                            self.checkRolesChanges(view.rolesColl(), modalId);
                        }
                        self.participantNetworkChanges = true;
                        break;
                    }
                }
            }
            else if (type === "partner") {
                for (var i = 0; i < self.partnerCollection().length; i++) {
                    if (self.partnerCollection()[i].modalId == modalId) {
                        var obj = { modalId: modalId, name: view.name(), originalId: view.id(), roles: view.rolesColl(), del: false };
                        self.partnerCollection.replace(self.partnerCollection()[i], obj);
                        if (self.pNCollaborationModal && !recursive) {
                            self.checkRolesChanges(view.rolesColl(), modalId);
                        }
                        self.participantNetworkChanges = true;
                        break;
                    }
                }
            }
            else if (type === "activity") {
                for (var i = 0; i < self.activityCollection().length; i++) {
                    if (self.activityCollection()[i].modalId == modalId) {
                        var obj = { id: view.id(), modalId: modalId, name: view.name(), description: view.description(), existingActivity: view.selectedactivity, del: false };
                        self.activityCollection.replace(self.activityCollection()[i], obj);
                        self.activityValueChanges = true;
                    }
                }
            }
            else if (type === "competency") {
                for (var i = 0; i < self.competencyCollection().length; i++) {
                    if (self.competencyCollection()[i].modalId == modalId) {
                        //special case
                        var obj = { originalId: view.comptenceId(), modalId: modalId, name: view.name(), description: view.description(), del: false};
                        self.competencyCollection.replace(self.competencyCollection()[i], obj);
                        self.competencyChanges = true;
                    }
                }
            }
            else if (type === "valueProposition") {
                for (var i = 0; i < self.valuePropositionValuesColl().length; i++) {
                    if (self.valuePropositionValuesColl()[i].modalId == modalId) {
                        var mName = view.measurementValue() ? view.measurementValue() + " " + view.measurementUnit() : "";
                        var dispName = view.name() + " " + mName;
                        var isCollective = false;
                        for (var j = 0; j < view.baseTuples.length; j++) {
                            if (view.baseTuples[j].new) {
                                isCollective = true;
                                break;
                            }
                        }
                        if (view.structuralChangeCount > 0 && !view.enableMeasurement()) {
                            isCollective = true;
                        }
                        var obj = { modalId: modalId, name: self.valuePropositionValuesColl()[i].name, displayName: dispName, id: view.id(), measurement: view.measurementValue(), del: false, isCollective: isCollective };
                        self.valuePropositionValuesColl.replace(self.valuePropositionValuesColl()[i], obj);
                        self.sortCollection(self.valuePropositionValuesColl);
                        self.valuePropositionChanges = true;
                    }
                }
            }
            else if (type === "valueFormula") {
                for (var i = 0; i < self.valueFormulaValuesColl().length; i++) {
                    if (self.valueFormulaValuesColl()[i].modalId == modalId) {
                        var measurementValue = view.measurementValue() ? view.measurementValue() + " " + view.measurementUnit() : "";
                        var dispName = view.checkPrime() ? view.name() + " " + measurementValue : view.name();
                        var isCollective = false;
                        for (var j = 0; j < view.baseTuples.length; j++) {
                            if (view.baseTuples[j].new) {
                                isCollective = true;
                                break;
                            }
                        }
                        if (view.structuralChangeCount > 0 && !view.enableMeasurement()) {
                            isCollective = true;
                        }
                        var obj = { modalId: modalId, name: self.valueFormulaValuesColl()[i].name, displayName: dispName, id: view.id(), measurement: view.measurementValue(), del: false, isCollective: isCollective };
                        self.valueFormulaValuesColl.replace(self.valueFormulaValuesColl()[i], obj);
                        self.sortCollection(self.valueFormulaValuesColl);
                        self.myValuePropositionChanges = true;
                    }
                }
            }
            else if (type === "createWhatValues") {
                for (var i = 0; i < self.activityCreateWhatValuesColl().length; i++) {
                    if (self.activityCreateWhatValuesColl()[i].modalId == modalId) {
                        var disName = "";
                        for (var j = 0; j < view.activityOptions().length; j++) {
                            if (view.activityOptions()[j].modalId === view.selectedActivity()) {
                                var measurementValue = view.measurementValue() ? view.measurementValue() + " " + view.measurementUnit() : "";
                                var dispName = view.checkPrime() ? view.name() + " " + measurementValue : view.name();
                                disName = '' + dispName + ' (' + view.activityOptions()[j].name + ')';
                                var activityModalId = view.activityOptions()[j].modalId
                                break;
                            }
                        }
                        var isCollective = false;
                        for (var j = 0; j < view.baseTuples.length; j++) {
                            if (view.baseTuples[j].new) {
                                isCollective = true;
                                break;
                            }
                        }
                        if (view.structuralChangeCount > 0 && !view.enableMeasurement()) {
                            isCollective = true;
                        }
                        var obj = { modalId: modalId, name: self.activityCreateWhatValuesColl()[i].name, displayName: disName, originalId: view.id(), existingValue: view.existingValueModal, activityModalId: activityModalId, del: false, isCollective: isCollective };
                        self.activityCreateWhatValuesColl.replace(self.activityCreateWhatValuesColl()[i], obj);
                        self.sortCollection(self.activityCreateWhatValuesColl);
                        self.activityValueChanges = true;
                    }
                }
            }
            else if (type === "contributeWhatValues") {
                var count = 0;
                var isCollective = false;
                if (view.structuralChangeCount > 0 && !view.enableMeasurement()) {
                    isCollective = true;
                }
                for (var i = 0; i < self.activityContributeWhatValuesColl().length; i++) {
                    if (self.activityContributeWhatValuesColl()[i].modalId === modalId) {
                        var dispName = view.name();
                        if (view.checkPrime()) {
                            dispName = dispName.concat(" " + view.measurementValue() + " " + view.measurementUnit());
                        }
                        var obj = { modalId: modalId, name: self.activityContributeWhatValuesColl()[i].name, dispName: dispName, originalId: view.id(), del: false, activityIds: self.activityContributeWhatValuesColl()[i].activityIds, isCollective: isCollective };
                        self.activityContributeWhatValuesColl.replace(self.activityContributeWhatValuesColl()[i], obj);
                        count++;
                        self.activityValueChanges = true;
                        break;
                    }
                }
                if (count == 0) {
                    var dispName = view.name();
                    if (view.checkPrime()) {
                        dispName = dispName.concat(" " + view.measurementValue() + " " + view.measurementUnit());
                    }
                    self.activityContributeWhatValuesColl.push({ modalId: modalId, dispName: dispName, name: view.name(), originalId: view.id(), del: false, activityIds: view.contributeWhatValuesAggregations, isCollective : isCollective});
                    self.sortCollection(self.activityContributeWhatValuesColl);
                    self.activityValueChanges = true;
                }
            }
            else {
                for (var i = 0; i < self.businessCollection().length; i++) {
                    if (self.businessCollection()[i].modalId == modalId) {
                        self.businessCollection([]);
                        var businessId = view.orgId() ? view.orgId() : view.id();
                        self.businessCollection.push({ modalId: modalId, name: view.name(), originalId: view.id(), businessId: businessId, roles: view.rolesColl() });
                        if (self.pNCollaborationModal && !recursive) {
                            self.checkRolesChanges(view.rolesColl(), modalId);
                        }
                    }
                }
                self.participantNetworkChanges = true;
                self.enableParticipantCancel(true);
            }
        };
        sortRolesByName(left, right) {
            var self = this;
            return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
        }
        updateNewChildModel(view, modalId, type) {
            var self = this;
            if (type === "customer") {
                self.enableParticipantCancel(true);
                self.customerCollection.push({ modalId: modalId, name: view.name(), originalId: view.id(), roles: view.rolesColl(), del: false });
                if (self.pNCollaborationModal) {
                    self.checkRolesChanges(view.rolesColl(), modalId);
                }
                self.participantNetworkChanges = true;
            }
            else if (type === "partner") {
                self.enableParticipantCancel(true);
                self.partnerCollection.push({ modalId: modalId, name: view.name(), originalId: view.id(), roles: view.rolesColl(), del: false });
                if (self.pNCollaborationModal) {
                    self.checkRolesChanges(view.rolesColl(), modalId);
                }
                self.participantNetworkChanges = true;
            }
            else if (type === "activity") {
                self.enableActivityCancel(true);
                self.activityCollection.push({ modalId: modalId, name: view.name(), originalId: view.id(), desc: view.description(), existingActivity: view.selectedactivity, del: false });
                self.canClickCompleteActivity(true);
                self.checkToEnableActivityValuesAddIcons();
                self.activityValueChanges = true;
            }
            else if (type === "competency") {
                self.enableCompCancel(true);
                self.competencyCollection.push({ modalId: modalId, name: view.name(), originalId: view.id(), desc: view.description(), del: false });
                self.checkCompetencyCompleteEnableClick();
                self.competencyChanges = true;
            }
            else if (type === "valueProposition") {
                self.enableVPCancel(true);
                var measurementValue = view.measurementValue() ? view.measurementValue() + " " + view.measurementUnit() : "";
                var name = view.checkPrime() ? view.name() + " " + measurementValue : view.name();
                var isCollective = false;
                if (view.structuralChangeCount > 0 && !view.enableMeasurement()) {
                    isCollective = true;
                }

                var SmileySrc = self.fetchSmileySrc('');
                self.valuePropositionValuesColl.push({ modalId: modalId, name: name, displayName: name, originalId: view.id(), measurement: view.measurementValue(), del: false, isCollective: isCollective, smileySrc: false, smileyColor:false });
                self.sortCollection(self.valuePropositionValuesColl);
                self.valuePropositionChanges = true;
            }
            else if (type === "valueFormula") {
                self.enableVFCancel(true);
                var measurementValue = view.measurementValue() ? view.measurementValue() + " " + view.measurementUnit() : "";
                var dispName = view.checkPrime() ? view.name() + " " + measurementValue : view.name();
                var isCollective = false;
                if (view.structuralChangeCount > 0 && !view.enableMeasurement()) {
                    isCollective = true;
                }
                self.valueFormulaValuesColl.push({ modalId: modalId, name: view.name(), displayName: dispName, originalId: view.id(), measurement: view.measurementValue(), del: false, isCollective: isCollective, smileySrc: false, smileyColor:false });
                self.sortCollection(self.valueFormulaValuesColl);
                self.myValuePropositionChanges = true;
            }
            else if (type === "createWhatValues") {
                self.enableActivityCancel(true);
                for (var i = 0; i < view.activityOptions().length; i++) {
                    if (view.activityOptions()[i].modalId === view.selectedActivity()) {
                        var measurementValue = view.measurementValue() ? view.measurementValue() + " " + view.measurementUnit() : "";
                        var dispName = view.checkPrime() ? view.name() + " " + measurementValue : view.name();
                        var isCollective = false;
                        if (view.structuralChangeCount > 0 && !view.enableMeasurement()) {
                            isCollective = true;
                        }
                        self.activityCreateWhatValuesColl.push({ modalId: modalId, displayName: '' + dispName + ' (' + view.activityOptions()[i].name + ')', name: '' + view.name(), originalId: view.id(), existingValue: view.existingValueModal, activityModalId: view.activityOptions()[i].modalId, del: false, isCollective: isCollective });
                        self.sortCollection(self.activityCreateWhatValuesColl);
                        self.activityValueChanges = true;
                        break;
                    }
                }

            }
            else if (type === "contributeWhatValues") {
                //never gets called
                self.enableActivityCancel(true);
                for (var i = 0; i < view.valueOptions().length; i++) {
                    if (view.valueOptions()[i].id === view.selectedvalue()) {
                        var isCollective = false;
                        if (view.structuralChangeCount > 0 && !view.enableMeasurement()) {
                            isCollective = true;
                        }
                        self.activityContributeWhatValuesColl.push({ modalId: modalId, dispName: view.valueOptions()[i].name, name: view.valueOptions()[i].name, originalId: view.id(), del: false, isCollective: isCollective, activityIds: view.contributeWhatValuesAggregations });
                        self.activityValueChanges = true;
                        break;
                    }
                }
            }
            else {
                self.businessCollection([]);
                var businessId = view.orgId() ? view.orgId() : view.id();
                self.businessCollection.push({ modalId: modalId, name: view.name(), originalId: view.id(), businessId: businessId, roles: view.rolesColl(), del: false });
                self.enableParticipantCancel(true);
                if (self.pNCollaborationModal) {
                    self.checkRolesChanges(view.rolesColl(), modalId);
                }
                $("#newBusinessDiv").hide();
                if (!self.business() && $('#participantName').val() != '') {
                    self.canClickCompleteParticipantNetwork(true);
                }
                self.participantNetworkChanges = true;
            }
            self.setResizeHeight(false);
        }
        checkRolesChanges(roleColl, modalId) {
            var self = this;
            var existingRoles = [];
            function checkRoleLength(array) {
                var count = 0;
                for (var i = 0; i < array.length; i++) {
                    if (!array[i]._destroy) {
                        count++;
                    }
                }
                return count;
            }
            for (var i = 0; i < roleColl.length; i++) {
                if (!roleColl[i]._destroy && roleColl[i].originalId != '' && roleColl[i].existing) {
                    existingRoles.push(roleColl[i].originalId);
                }
            }
            if (existingRoles.length > 0) {
                var len = self.children.length;
                while (len) {
                    len--;
                    if (self.children[len].view && self.children[len].view.rolesColl() && self.children[len].modalId != modalId) {
                        var tempChild = self.children[len];
                        var tempColl = self.children[len].view.rolesColl();
                        for (var k = 0; k < tempColl.length; k++) {
                            if (!tempColl[k]._destroy && tempColl[k].originalId != '' && _.contains(existingRoles, tempColl[k].originalId)) {
                                self.updateRemoveRoleArray(tempColl[k].originalId, tempColl[k].originalId, modalId);
                                self.children[len].view.rolesColl.destroy(tempColl[k]);
                                existingRoles.splice(existingRoles.indexOf(tempColl[k].originalId), 1);
                                if (checkRoleLength(tempColl) == 0) {
                                    self.children.splice(len, 1);
                                    self.deleteChildModel(tempChild.view, tempChild.modalId, tempChild.type, false);
                                }
                                else {
                                    self.updateExistingChildModel(self.children[len].view, self.children[len].modalId, self.children[len].type, true);
                                }
                            }
                        }
                    }
                }
            }
            if (self.pNModel && existingRoles.length > 0) {
                function checkCollections(participantColl) {
                    for (var m = 0; m < participantColl().length; m++) {
                        if (participantColl()[m].modalId !== modalId) {
                            var tempRoles = participantColl()[m].roles;
                            var len = tempRoles.length;
                            while (len) {
                                len--;
                                if (_.contains(existingRoles, tempRoles[len].originalId)) {
                                    existingRoles.splice(existingRoles.indexOf(tempRoles[len].originalId), 1);
                                    self.updateRemoveRoleArray(participantColl()[m].originalId, tempRoles[len].originalId, modalId);
                                    if (tempRoles.length == 1) {
                                        participantColl()[m].manual = false;
                                        if (self.business() && self.business().get('id') === participantColl()[m].originalId) {
                                            tempRoles.splice(len, 1);
                                            var obj = { modalId: participantColl()[m].modalId, name: participantColl()[m].name, originalId: participantColl()[m].originalId, roles: tempRoles, del: false };
                                            participantColl.replace(participantColl()[m], obj);
                                            self.deleteParticipantArray.push(participantColl()[m]);
                                        }
                                        else {
                                            self.deleteParticipantArray.push(participantColl()[m]);
                                            participantColl.destroy(participantColl()[m]);
                                        }
                                    }
                                    else {
                                        tempRoles.splice(len, 1);
                                        var obj = { modalId: participantColl()[m].modalId, name: participantColl()[m].name, originalId: participantColl()[m].originalId, roles: tempRoles, del: false };
                                        participantColl.replace(participantColl()[m], obj);
                                    }
                                }
                            }
                        }
                    }
                }

                checkCollections(self.customerCollection);
                if (existingRoles.length > 0) {
                    checkCollections(self.partnerCollection);
                }
                if (existingRoles.length > 0) {
                    checkCollections(self.businessCollection);
                }
            }
        }
        updateRemoveRoleArray(participantId, roleId, newModalId) {
            var self = this;
            var rolefound = false;
            for (var i = 0; i < self.removeRoleArray.length; i++) {
                if (self.removeRoleArray[i].roleId == roleId) {
                    rolefound = true;
                    if (participantId != '') {
                        self.removeRoleArray[i].participantId = participantId;
                    }
                    self.removeRoleArray[i].newModalId = newModalId;
                }
            }
            if (!rolefound) {
                self.removeRoleArray.push({ participantId: participantId, roleId: roleId, newModalId: newModalId });
            }
        }
        filterBusinessId() {
            var self = this;
            var filterBusinessArray = [];
            if (self.model.get('business')) {
                filterBusinessArray.push({ 'name': self.model.get('business').get('name'), 'id': self.model.get('business').get('id') });
            }
            var cust = self.model.get('bmCustomer');
			for (var i = 0; i < cust.length; i++) {
				if (cust.at(i).get('customer')) {
					filterBusinessArray.push({ 'name': cust.at(i).get('name'), 'id': cust.at(i).get('customer').get('id') });
				}
            }
            var part = self.model.get('bmNetworkPartner');
			for (var i = 0; i < part.length; i++) {
				if (part.at(i).get('networkPartner')) {
					filterBusinessArray.push({ 'name': part.at(i).get('name'), 'id': part.at(i).get('networkPartner').get('id') });
				}
            }
            for (var i = 0; i < self.children.length; i++) {
                if (self.children[i].type == 'customer') {
                    if (self.children[i].view) {
                        filterBusinessArray.push({ 'name': self.children[i].view.name(), 'id': self.children[i].view.typeAheadOriginalId() });
                    }
                }
                else if (self.children[i].type == 'partner') {
                    if (self.children[i].view) {
                        filterBusinessArray.push({ 'name': self.children[i].view.name(), 'id': self.children[i].view.typeAheadOriginalId() });
                    }
                }
            }
            return filterBusinessArray;
        }

        filterTypeAhead(collList, bmParticipantList, type, typeAhead) {
            var self = this;
            var filterList = [];
            for (var i = 0; i < collList.length; i++) {
                var bmCust = bmParticipantList.findWhere({ id: collList[i].originalId });
                if (bmCust) {
                    filterList.push({ 'id': bmCust.get('' + type).get('id'), 'name': bmCust.get('' + type).get('name'), 'type': bmCust.get('' + type).get('type'), 'modalId': self.htmlEscape(bmCust.id) });
                }
            }
            for (var i = 0; i < self.children.length; i++) {
                if ((self.children[i].type == 'customer' || self.children[i].type == 'partner') && self.children[i].view) {
                    var childType = 'vdml_OrgUnit';
                    if (self.children[i].view.customerType) {
                        childType = self.children[i].view.customerType();
                    }
                    else {
                        childType = self.children[i].view.networkPartnerType();
                    }
                    if (self.children[i].view.typeAheadOriginalId() !== "") {
                        filterList.push({ 'id': self.children[i].view.typeAheadOriginalId(), 'name': self.children[i].view.name(), 'type': childType, 'modalId': self.htmlEscape(self.children[i].view.typeAheadOriginalId()) });
                    }
                    else {
                        filterList.push({ 'id': self.children[i].modalId, 'name': self.children[i].view.name(), 'type': childType, 'modalId': self.children[i].modalId });
                        if (typeAhead && (self.children[i].type == 'partner' && type == 'customer' || self.children[i].type == 'customer' && type == 'networkPartner')) {
                            var addToList = true;
                            for (var j = 0; j < collList.length; j++) {
                                if (!collList[j].originalId && collList[j].name == self.children[i].view.name()) {
                                    addToList = false;
                                    break;
                                }
                            }
                            if (addToList) {
                                typeAhead.push({ id: i, name: self.children[i].view.name(), description: self.children[i].view.description(), type: childType, parent: '', path: 'current', utilized: false });
                            }
                        }
                    }
                }
            }
            if (self.businessCollection()[0] && self.businessCollection()[0].businessId) {
                filterList.push({ 'id': self.businessCollection()[0].businessId, 'name': self.businessCollection()[0].name, 'type': 'vdml_OrgUnit', 'modalId': self.htmlEscape(self.businessCollection()[0].businessId) });
            }

            return filterList;

        }
        addCustomerClick() {
            var self = this;
            self.fetchCustomerTypeaheads(!self.fetchedParticipantList, function () {
                var collRoles = self.pNCollaborationModal ? self.pNCollaborationModal.get('collaborationRole') : null;
                var tempPartnerTypeAheadColl = [];
                var filterId = self.filterTypeAhead(self.customerCollection(), self.model.get('bmCustomer'), 'customer', tempPartnerTypeAheadColl);
                var addOptions = { 'pNModel': self.pNModel, 'collRoles': collRoles, 'customerTypeahead': self.customerTypeahead, 'tempCustomerTypeahead': self.tempCustomerTypeahead, 'tempPartnerTypeAheadColl': tempPartnerTypeAheadColl, 'filterTypeahead': filterId, 'partnerCollection': self.partnerCollection() };
                var id = self.htmlEscape(window.guidGenerator()) + "customer";
                window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "CustomerDetails", _.bind(self.addChildModel, self), addOptions);
            });
        };



        createRevisionBasedOnContext(revisionCheck, callback) {
            var self = this;
            if (window.checkContextForRevision() && revisionCheck) {
                window.utils.startSpinner('revisionSpinner', "Creating a copy...");
                function fetchBmModel(newVdmPackage) {
                    if (newVdmPackage) {
                        var altId = DataManager.getDataManager().getRepositoryId(newVdmPackage.id);
						var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
						var diagrams = newVdmPackage.get('diagrams');
						for (var i = 0; i < diagrams.length; i++) {
							newVdmPackage.fixDiagramOnRevision(diagrams.at(i), altId, oldAltId);
						}
                        var modelSuffix = self.id().substr(self.id().lastIndexOf('@') + 1);
                        var newId = DataManager.getDataManager().get('viewAlternative') + '' + modelSuffix;
                        self.model = newVdmPackage.get('businessModel').findWhere({ 'id': newId });
                        var oldAltIdReg = new RegExp(DataManager.getDataManager().get('currentWorkspace').get('id'), 'g');
                        var filterObservables = ['mainScenario', 'selectedProviderRole', 'selectedReceiverRole', 'selectedProvider', 'selectedBusinessRole', 'selectedAggregate', 'selectedPersued', 'selectedActivityProviderRole', 'selectedActivityProvider', 'selectedCompetencyActivities', 'selectedvalue'];
                        window.utils.updateObservablesForRevision(self.BusinessModelViewModel, oldAltIdReg, filterObservables);
                        //setTimeout(function(){
                        DataManager.getDataManager().releaseSaveLock();
                        DataManager.getDataManager().set('isActive', false);
                        window.utils.stopSpinner('revisionSpinner');
                        callback(true);
                        /*DataManager.getDataManager().saveData({
                            success: function () {
                                window.utils.stopSpinner('revisionSpinner');
                                callback(true);
                            },
                            error: function () {
                                window.utils.stopSpinner('revisionSpinner');
                                callback();
                            },
                            persist: true
                        });*/
                        //},100);	
                    }
                }
                window.setTimeout(function () {
                    DataManager.getDataManager().acquireSaveLock(function () {
                        self.model.getNestedParent().createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
                    });
                }, 100);

            }
            else {
                callback();
            }
        }
        checkforStructuralChange(type) {
            var self = this;
            var structuralChange = false;
            if (type === 'participantNetwork' && window.checkContextForRevision()) {
                function checkForParticipantRoles(roleArray) {
                    for (var j = 0; j < roleArray.length; j++) {
                        if (roleArray[j].originalId === '') {
                            structuralChange = true;
                            break;
                        } else if (roleArray[j].originalId !== '' && roleArray[j]._destroy) {
                            structuralChange = true;
                            break;
                        } else if (roleArray[j].originalId !== '' && roleArray[j].existing) {
                            structuralChange = true;
                            break;
                        }
                    }
                    return structuralChange;
                }
                if (!self.pNCollaborationModal) {
                    structuralChange = true;
                } else if (self.pNCollaborationModal && !self.pNModel) {
                    structuralChange = true;
                } else {
                    for (var i = 0; i < self.customerCollection().length; i++) {
                        if (self.customerCollection()[i]._destroy && self.customerCollection()[i].originalId !== '') {
                            structuralChange = true;
                            break;
                        }
                    }
                    for (var i = 0; i < self.partnerCollection().length; i++) {
                        if (self.partnerCollection()[i]._destroy && self.partnerCollection()[i].originalId !== '') {
                            structuralChange = true;
                            break;
                        }
                    }
                    for (var i = 0; i < self.children.length; i++) {
                        if (self.children[i].type === 'customer' && self.children[i].view) {
                            if (self.children[i].originalId === '') {
                                structuralChange = true;
                                break;
                            } else {
                                structuralChange = checkForParticipantRoles(self.children[i].view.rolesColl());
                            }
                        }
                        else if (self.children[i].type === 'partner' && self.children[i].view) {
                            if (self.children[i].originalId === '') {
                                structuralChange = true;
                                break;
                            } else {
                                structuralChange = checkForParticipantRoles(self.children[i].view.rolesColl());
                            }
                        } else if (self.children[i].type === 'business' && self.children[i].view) {
                            structuralChange = checkForParticipantRoles(self.children[i].view.rolesColl());
                        }
                    }
                }
            }
            else if (type === 'valueProposition' && window.checkContextForRevision()) {
                if (self.bmValueProposition()) {
                    for (var i = 0; i < self.children.length; i++) {
                        if (self.children[i].type === 'valueProposition' && self.children[i].view) {
                            if (self.children[i].originalId === '') {
                                structuralChange = true;
                                break;
                            } else if (self.children[i].view.structuralChangeCount > 0) {
                                structuralChange = true;
                                break;
                            }
                        }
                    }
                    if (self.selectedValueProposition().get('recipient') && self.selectedValueProposition().get('recipient').get('id') !== self.selectedReceiverRole()) {
                        structuralChange = true;
                    }
                    else if (self.selectedValueProposition().get('provider').get('id') !== self.selectedProviderRole()) {
                        structuralChange = true;
                    }
                    for (var i = 0; i < self.valuePropositionValuesColl().length; i++) {
                        if (self.valuePropositionValuesColl()[i]._destroy) {
                            structuralChange = true;
                            break;
                        }
                    }
                }
                else {
                    structuralChange = true;
                }
            }
            else if (type === 'valueFormula' && window.checkContextForRevision()) {
                if (self.bmValueFormula()) {
                    for (var i = 0; i < self.children.length; i++) {
                        if (self.children[i].type === 'valueFormula' && self.children[i].view) {
                            if (self.children[i].originalId === '') {
                                structuralChange = true;
                                break;
                            } else if (self.children[i].view.structuralChangeCount > 0) {
                                structuralChange = true;
                                break;
                            }
                        }
                    }
                    if (self.selectedValueFormula().get('provider').get('id') !== self.selectedBusinessRole()) {
                        structuralChange = true;
                    }
                    for (var i = 0; i < self.valueFormulaValuesColl().length; i++) {
                        if (self.valueFormulaValuesColl()[i]._destroy) {
                            structuralChange = true;
                            break;
                        }
                    }
                }
                else {
                    structuralChange = true;
                }
            }
            else if (type === 'activity' && window.checkContextForRevision()) {
                if (self.selectedValueStreamModel) {
                    for (var i = 0; i < self.children.length; i++) {
                        if (self.children[i].type === 'activity' && self.children[i].view && self.children[i].originalId === '') {
                            structuralChange = true;
                            break;
                        } else if (self.children[i].type === 'createWhatValues' && self.children[i].view) {
                            if (self.children[i].originalId === '') {
                                structuralChange = true;
                                break;
                            } else if (self.children[i].view.structuralChangeCount > 0) {
                                structuralChange = true;
                                break;
                            }
                        } else if (self.children[i].type === 'contributeWhatValues' && self.children[i].view && self.children[i].view.structuralChangeCount > 0) {
                            structuralChange = true;
                            break;
                        }
                    }
                    for (var i = 0; i < self.activityCollection().length; i++) {
                        if (self.activityCollection()[i]._destroy) {
                            structuralChange = true;
                            break;
                        }
                    }
                    /*if(self.selectedValueStreamModel.get('valueStreamActivity').at(0).get('performingRole').get('id') !== self.selectedActivityProviderRole()){
                        structuralChange = true;
                    }*/
                    var actValues = self.selectedValueStreamModel.get('activityValue');
                    var bmActs = self.model.get('bmActivity');
                    var valStreamActs = self.selectedValueStreamModel.get('valueStreamActivity');
                    var actValCount = 0;
                    for (var i = 0; i < actValues.length; i++) {
                        for (var j = 0; j < valStreamActs.length; j++) {
                            if (valStreamActs.at(j).get('performingRole').get('id') === self.selectedActivityProviderRole()) {
                                var bmAct = bmActs.findWhere({ 'activity': valStreamActs.at(j) });
                                if (bmAct && bmAct.get('activityValue').findWhere({ 'id': actValues.at(i).get('id') })) {
                                    actValCount++;
                                    break;
                                }
                            }
                        }
                    }
                    var activityCreateWhatValuesCollLength = 0;
                    for (var i = 0; i < self.activityCreateWhatValuesColl().length; i++) {
                        if (!self.activityCreateWhatValuesColl()[i]._destroy) {
                            activityCreateWhatValuesCollLength++;
                        }
                    }
                    if (activityCreateWhatValuesCollLength !== actValCount) {
                        structuralChange = true;
                    }
                }
                else {
                    structuralChange = true;
                }
            }
            else if (type === 'competency' && window.checkContextForRevision()) {
                for (var i = 0; i < self.children.length; i++) {
                    if (self.children[i].type === 'competency' && self.children[i].view && self.children[i].view.competenceType() === 'BusinessItem') {
                        structuralChange = true;
                        break;
                    }
                }
            }
            return structuralChange;
        };
        checkUnsavedParticipantNetwork() {
            var self = this;
            if (self.canClickCompleteParticipantNetwork()) {
                if ($('#participantName').val().trim() != '' && !self.pNCollaborationModal) {
                    return true;
                } else if (!self.pnAttached && self.pNCollaborationModal) {
                    return true;
                }
                else {
                    if (self.pNModel.get('name').trim() !== $('#participantName').val().trim() || self.participantNetworkChanges) {
                        return true;
                    }
                }
            }
            return false;
        };

        checkUnsavedValueProposition() {
            var self = this;
            if (self.canClickCompleteValueProposition()) {
                if ($('#vpName').val().trim() != '' && !self.selectedValueProposition()) {
                    return true;
                } else {
                    if (self.selectedValueProposition()) {
                        var providerRole = self.model.get('bmRole').get(self.selectedProviderRole());
                        var receiverRole = self.selectedReceiverRole() ? self.model.get('bmRole').get(self.selectedReceiverRole()) : null;
                        if (self.selectedValueProposition().get('name').trim() !== $('#vpName').val().trim() || self.selectedValueProposition().get('provider') !== providerRole || self.selectedValueProposition().get('recipient') !== receiverRole || self.valuePropositionChanges) {
                            return true;
                        }
                    }
                }
            }
            return false;
        };
        checkUnsavedMyProposition() {
            var self = this;
            if (self.canClickCompleteValueFormula()) {
                if ($('#vfName').val().trim() != '' && !self.selectedValueFormula()) {
                    return true;
                } else {
                    if (self.selectedValueFormula()) {
                        var providerRole = self.model.get('bmRole').get(self.selectedBusinessRole());
                        if (self.selectedValueFormula().get('name').trim() !== $('#vfName').val().trim() || self.selectedValueFormula().get('provider') !== providerRole || self.myValuePropositionChanges) {
                            return true;
                        }
                    }
                }
            }
            return false;
        };

        checkUnsavedActivities() {
            var self = this;
            if (self.canClickCompleteActivity()) {
                if (!self.selectedValueStreamModel) {
                    return true;
                } else {
                    var performingRole = self.model.get('bmRole').get(self.selectedActivityProviderRole());
                    if (/*self.selectedValueStreamModel.get('valueStreamActivity').at(0).get('performingRole') !== performingRole || */self.activityValueChanges) {
                        return true;
                    }
                }
            }
            return false;
        };

        checkUnsavedCompetencies() {
            var self = this;
            if (self.enableCompetencyComplete()) {
                if (!self.competencyActivity) {
                    return true;
                } else {
                    if (self.competencyChanges) {
                        return true;
                    }
                }
            }
            return false;
        };

        hasUnsavedData() {
            var self = this;
            if (self.checkUnsavedParticipantNetwork() || self.checkUnsavedValueProposition() || self.checkUnsavedMyProposition() || self.checkUnsavedActivities() || self.checkUnsavedCompetencies()) {
                return true;
            } else {
                return false;
            }
        };
        saveDataBeforeClosing(callback, enableReload) {
            var self = this;
            if (enableReload) { callback = null;}
            if (self.enableCompetencyComplete() && self.checkUnsavedCompetencies()) {
                self.saveCompetency(null, null, callback);
            }
            else if (self.canClickCompleteActivity() && self.checkUnsavedActivities()) {
                self.saveActivity(null, null, callback);
            }
            else if (self.canClickCompleteValueFormula() && self.checkUnsavedMyProposition()) {
                self.saveValueFormula(null, null, callback);
            }
            else if (self.canClickCompleteValueProposition() && self.checkUnsavedValueProposition()) {
                self.saveValueProposition(null, null, callback);
            } else {
                self.saveParticipants(null, null, callback);
            }
        };

        checkOneCCArea(type, callback) {
            var self = this;
            function showMsg() {
                bootbox.confirm(DataManager.getDataManager().get('localeManager').get('OneCCConfirmMessage'), function (result) {
                    if (result) {
                        callback(true);
                    } else {
                        callback(false);
                    }
                });
            }
            if (type = 'ParticipantNetwork' && self.checkUnsavedParticipantNetwork() && self.editMode) {
                showMsg();
            } else if (type = 'ValueProposition' && self.checkUnsavedValueProposition() && self.editMode) {
                showMsg();
            } else if (type = 'MyProposition' && self.checkUnsavedMyProposition() && self.editMode) {
                showMsg();
            } else if (type = 'Activity' && self.checkUnsavedActivities() && self.editMode) {
                showMsg();
            } else if (type = 'Competency' && self.checkUnsavedCompetencies() && self.editMode) {
                showMsg();
            } else {
                callback(true);
            }
        }
        saveParticipants(view, event, callback) {
            var self = this;
            var structuralChange = self.checkforStructuralChange('participantNetwork');
            self.createRevisionBasedOnContext(structuralChange, function (revision) {
                window.utils.startSpinner('saveParticipants', 'Saving Participant Network...');
                window.setTimeout(function () { saveParticipant(revision) }, 100);
            });
            function saveParticipant(revision) {
                var participantNetwork;
                var newColl = false;
                if (self.participantName && self.participantName !== "" && !self.pNCollaborationModal) {
                    self.model.createParticipantNetwork(self.participantName, null, self.currentAlternativeModel, function (participantNetwork) {
                        newColl = true;
                        self.collaborations.add(participantNetwork.get("collaboration"));
                        self.saveChildren(participantNetwork, newColl, structuralChange, stopSpinner, revision);
                    });
                }
                else if (!self.pnAttached && self.pNCollaborationModal) {
                    self.model.createParticipantNetwork(self.participantName, self.pNCollaborationModal, self.currentAlternativeModel, function (participantNetwork) {
                        newColl = true;
                        self.saveChildren(participantNetwork, newColl, structuralChange, stopSpinner, revision);
                    });
                }
                else {
                    if (self.pNModel) {
                        participantNetwork = self.pNModel;
                        participantNetwork.set('name', self.participantName);
                        self.pNCollaborationModal.set('name', self.participantName);
                        self.saveChildren(participantNetwork, newColl, structuralChange, stopSpinner, revision);
                    }
                }
                function stopSpinner() {
                    window.utils.stopSpinner('saveParticipants');
                    if (callback) {
                        callback();
                    }
                }
            }
		};

        saveChildren(participantNetwork, newColl, structuralChange, callback, revision) {
            var self = this;
            var customerColl = [];
            var partnerColl = [];
            var businessColl = [];
            var assignedRoles = [];
            if (participantNetwork) {
                var participantArray = [];
                for (var i = 0; i < self.children.length; i++) {
                    if ((self.children[i].type == "customer" || self.children[i].type == "partner" || self.children[i].type == "business") && self.children[i].view) {
                        participantArray.push(self.children[i]);
                    }
                }
                async.eachSeries(participantArray, function (child, saveChildCallback) {
                    var type = child.type;
                    if (type === "customer") {
                        child.view.saveData(self.model, participantNetwork, self.assignmentsColl, self.participantCollection(), self.removeRoleArray, revision, self.currentAlternativeModel, function (customerJson) {
                            customerColl.push(customerJson);
                            assignedRoles.push.apply(assignedRoles, customerJson.roles);
                            saveChildCallback();
                        });
                    }
                    else if (type === "partner") {
                        child.view.saveData(self.model, participantNetwork, self.assignmentsColl, self.participantCollection(), self.removeRoleArray, revision, self.currentAlternativeModel, function (partnerJson) {
                            partnerColl.push(partnerJson);
                            assignedRoles.push.apply(assignedRoles, partnerJson.roles);
                            saveChildCallback();
                        });
                    }
                    else if (type === "business") {
                        child.view.saveData(self.model, participantNetwork, self.assignmentsColl, self.participantCollection, self.removeRoleArray, revision, self.currentAlternativeModel, function (businessJson) {
                            businessColl.push(businessJson);
                            assignedRoles.push.apply(assignedRoles, businessJson.roles);
                            saveChildCallback();
                        });
                    }
                }, function (err) {
                    if (self.model.get('business') && self.model.get('participantNetwork').length > 1 && !newColl) {
                        var collAssignments = participantNetwork.get('collaboration').get('ownedAssignment');
                        var partExists = collAssignments.findWhere({ 'participant': self.model.get('business') });
                        var partRole = partExists ? partExists.get('assignedRole') : null;
                        if (partRole) {
                            var partRoleExists = self.model.get('bmRole').findWhere({ 'id': partRole.get('id') });
                        }
                        if (!partExists || !partRoleExists) {
                            businessColl = [];
                            self.createDefaultBusiness(businessColl, participantNetwork);
                            assignedRoles.push.apply(assignedRoles, businessColl[0].roles);
                        }
                    }
                    if (businessColl.length < 1 && newColl) {
                        self.createDefaultBusiness(businessColl, participantNetwork);
                        assignedRoles.push.apply(assignedRoles, businessColl[0].roles);
                    }
                    self.deleteCustomers(self.customerCollection());
                    self.deletePartners(self.partnerCollection());
                    if (window.checkContextForRevision() && structuralChange) {
                        self.disableAllCompleteButtons();
                        setTimeout(function(){
                            DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                            if (callback) {
                                callback();
                            }
                        },1000);
                    }
                    else {
                        if (!newColl) {
                            self.refreshWidgetData(true);
                        }
                        self.refreshParticipantTable(participantNetwork, customerColl, partnerColl, businessColl, self.pNCollaborationModal, assignedRoles);
                        if (self.participantNetworkChanges || newColl) {
                            self.cancelParticipantNetwork();
                            self.setResizeHeight();
                            self.fetchCustomerTypeaheads(true);
                            self.resetTables(callback);
                        } else {
                            self.cancelParticipantNetwork();
                            self.setResizeHeight();
                            if (callback) {
                                callback();
                            }
                        }
                    }
                });
            }
        };

        disableAllCompleteButtons() {
            var self = this;
            self.canClickCompleteParticipantNetwork(false);
            self.canClickCompleteValueProposition(false);
            self.canClickCompleteValueFormula(false);
            self.canClickCompleteActivity(false);
            self.canClickCompleteValues(false);
            self.enableCompetencyComplete(false);
        };

        resetTables(callback) {
            var self = this;
            function allCollections() {
                if (self) {
                    self.resetVPData();
                    self.resetVFData();
                    self.resetActivityData();
                    self.bmActivityListCollection([]);
                    self.fillBMActivityList();
                    self.bmCompetencyListCollection([]);
                    self.fillBmCompetency();
                    self.clearChildren("valueProposition");
                    self.clearChildren("valueFormula");
                    self.clearChildren("activity");
                    self.clearChildren("createWhatValues");
                    self.clearChildren("contributeWhatValues");
                    self.clearChildren("competency");
                    self.cancelValue();
                    self.cancelValueFormula();
                    self.cancelValueProposition();
                    self.cancelCompetency();
                    self.cancelActivity();
                    self.fillValuesTableFilterRoles();
                }
                if (callback) {
                    callback();
                }
            }
            setTimeout(allCollections, 100);
        }
		deleteParticipantNetwork(view, event) {
            var self = this;
			if (self.pNModel) {
                function doDeleteParticipantNetwork() {
                    window.utils.startSpinner('participantNetworkSpinner', "Deleting Participant Network...");
                    window.setTimeout(function () {
                        var colId = self.pNCollaborationModal.get("id");
                        var colParent = self.pNCollaborationModal.getNestedParent();
                        self.pNModel.removeCollaborationToScenarioContexts(self.model);
                        self.model.businessCanvasDeletions([self.pNCollaborationModal]);
                        self.pNModel.destroy();
                        for (var i = 0; i < self.participantCollection().length; i++) {
                            if (self.participantCollection()[i].originalId == colId) {
                                self.participantCollection.splice(i, 1);
                                break;
                            }
                        }

                        if (colParent.get('collaboration').findWhere({ id: colId })) {
                            self.handleUnAttachedCollaboration(self.pNCollaborationModal, function () {
                                var selectedOption = self.selectedFilter();
                                var nonAttached = $('table tr.participantfalse');
                                if (selectedOption == 'Attached') {
                                    $('table tr.participanttrue').show();
                                    for (var i = 0; i < nonAttached.length; i++) {
                                        nonAttached[i].hidden = true;
                                    }
                                }
                                else if (selectedOption == 'Non-Attached') {
                                    for (var i = 0; i < nonAttached.length; i++) {
                                        nonAttached[i].hidden = false;
                                    }
                                    $('table tr.participanttrue').hide();
                                }
                                else {
                                    $('table tr.participanttrue').show();
                                    for (var i = 0; i < nonAttached.length; i++) {
                                        nonAttached[i].hidden = false;
                                    }
                                }
                            });
                        }

                        setTimeout(function () {
                            self.cancelParticipantNetwork();
                            self.refreshWidgetData(true);
                            self.setResizeHeight(false);
                            self.fetchCustomerTypeaheads(true);
							self.resetTables(function () {
                                window.utils.stopSpinner('participantNetworkSpinner');
                            });
						}, 200);
					}, 100);
                }
				bootbox.confirm(DataManager.getDataManager().get('localeManager').get('DeleteParticipantNetwork'), function (result) {
                    if (result) {
					    if(window.checkContextForRevision()){
						  self.createRevisionBasedOnContext(true, function (revision) {
								self.pNModel.removeCollaborationToScenarioContexts(self.model);
								self.model.businessCanvasDeletions([self.pNCollaborationModal]);
								self.pNModel.destroy();
                                DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
						  }) 
                        }else{
						  doDeleteParticipantNetwork();
						}
                    }
                });
			}
		};

        fetchAssignmentsFromRoles(roles, assignList) {
            var self = this;
            var participantAssignments = new Backbone.Collection();
            var bmRoles = self.model.get('bmRole');
            for (var j = 0; j < roles.length; j++) {
                var role = bmRoles.findWhere({ 'id': roles[j].originalId });
                if (role) {
                    var assign = assignList.findWhere({ 'assignedRole': role });
                    if (assign) {
                        participantAssignments.push(assign);
                    }
                }
            }
            return participantAssignments;
        }

        deleteCustomers(customersColl) {
            var self = this;
            var customerDeletionArray = [];
            for (var i = 0; i < customersColl.length; i++) {
                if (customersColl[i]._destroy && customersColl[i].originalId !== ''/* && customersColl[i].manual*/) {
                    var bmCustomer = self.model.get("bmCustomer").get(customersColl[i].originalId);
                    if (bmCustomer) {
                        var assignments = self.assignmentsColl[bmCustomer.get('customer').id];
                        var customerAssignments = self.fetchAssignmentsFromRoles(customersColl[i].roles, assignments);
                        customerDeletionArray.push({ 'object': bmCustomer, 'type': 'customer', 'assignment': customerAssignments });
                    }
                }
            }
            async.each(customerDeletionArray, self.model.deleteParticipant.bind(self.model), function (err) {
            });
        };

        deletePartners(partnersColl) {
            var self = this;
            var partnerDeletionArray = [];
            for (var i = 0; i < partnersColl.length; i++) {
                if (partnersColl[i]._destroy && partnersColl[i].originalId !== '' /*&& partnersColl[i].manual*/) {
                    var bmNetworkPartner = self.model.get("bmNetworkPartner").get(partnersColl[i].originalId);
                    if (bmNetworkPartner) {
                        var assignments = self.assignmentsColl[bmNetworkPartner.get('networkPartner').id];
                        var partnerAssignments = self.fetchAssignmentsFromRoles(partnersColl[i].roles, assignments);
                        partnerDeletionArray.push({ 'object': bmNetworkPartner, 'type': 'networkPartner', 'assignment': partnerAssignments });
                    }
                }
            }
            async.each(partnerDeletionArray, self.model.deleteParticipant.bind(self.model), function (err) {
            });
        };
        refreshParticipantTable(participantNetwork, customerColl, partnerColl, businessColl, pNCollaborationModal, assignedRoles) {
            var self = this;
            var notAssignedRoles = [];
            function removeDeletedRoles(participantId, modalId, roles) {
                for (var i = 0; i < self.removeRoleArray.length; i++) {
                    if (self.removeRoleArray[i].participantId === participantId && self.removeRoleArray[i].newModalId !== modalId) {
                        var len = roles.length;
                        while (len) {
                            len--;
                            if (roles[len].originalId === self.removeRoleArray[i].roleId) {
                                roles.splice(len, 1);
                            }
                        }
                    }
                }
            }
            var roleColl = participantNetwork.get("collaboration").get("collaborationRole").models;
            if (!pNCollaborationModal) {
                var pnDisplayname = self.pnNamespacePath(participantNetwork.collection.models, participantNetwork);
                var obj = { seqNo: self.paticipantArray.length, originalId: participantNetwork.get("collaboration").get('id'), name: participantNetwork.get("collaboration").get('name'), pnDisplayname: pnDisplayname, businessColl: businessColl, customerColl: customerColl, partnerColl: partnerColl, attached: true, notAssigned: notAssignedRoles };
                self.participantCollection.push(obj);
            }
            else {
                for (var i = 0; i < self.participantCollection().length; i++) {
                    if (self.participantCollection()[i].originalId == pNCollaborationModal.get("id")) {
                        if (customerColl.length < 1) {
                            for (var x = 0; x < self.participantCollection()[i].customerColl.length; x++) {
                                customerColl.push(self.participantCollection()[i].customerColl[x]);
                            }
                            for (var j = 0; j < customerColl.length; j++) {
                                removeDeletedRoles(customerColl[j].originalId, customerColl[j].modalId, customerColl[j].roles);
                                assignedRoles.push.apply(assignedRoles, customerColl[j].roles);
                            }
                        }
                        else {
                            for (var j = 0; j < self.participantCollection()[i].customerColl.length; j++) {
                                if (!self.checkExists(customerColl, self.participantCollection()[i].customerColl[j])) {
                                    customerColl.push(self.participantCollection()[i].customerColl[j]);
                                    assignedRoles.push.apply(assignedRoles, self.participantCollection()[i].customerColl[j].roles);
                                }
                            }
                        }
                        if (partnerColl.length < 1) {
                            partnerColl = self.participantCollection()[i].partnerColl;
                            for (var j = 0; j < partnerColl.length; j++) {
                                removeDeletedRoles(partnerColl[j].originalId, partnerColl[j].modalId, partnerColl[j].roles);
                                assignedRoles.push.apply(assignedRoles, self.participantCollection()[i].partnerColl[j].roles);
                            }
                        }
                        else {
                            for (var k = 0; k < self.participantCollection()[i].partnerColl.length; k++) {
                                if (!self.checkExists(partnerColl, self.participantCollection()[i].partnerColl[k])) {
                                    partnerColl.push(self.participantCollection()[i].partnerColl[k]);
                                    assignedRoles.push.apply(assignedRoles, self.participantCollection()[i].partnerColl[k].roles);
                                }
                            }
                        }
                        if (businessColl.length < 1 && self.businessCollection()[0]) {
                            businessColl = [self.businessCollection()[0]];
                            for (var j = 0; j < businessColl.length; j++) {
                                if (!self.participantCollection()[i].businessColl[j]) {
                                    self.participantCollection()[i].businessColl[j] = $.extend(true, {}, businessColl[j]);
                                }
                                assignedRoles.push.apply(assignedRoles, self.participantCollection()[i].businessColl[j].roles);
                            }
                        }
                        self.removeDeletedParticipants(customerColl, assignedRoles);
                        self.removeDeletedParticipants(partnerColl, assignedRoles);
                        if (!self.model.get('business')) {
                            self.removeDeletedParticipants(businessColl, assignedRoles);
                        }
                        self.updateNotassignedRoles(roleColl, assignedRoles, notAssignedRoles, true);
                        self.sortCollection(customerColl);
                        self.sortCollection(partnerColl);
                        var exisObj = { seqNo: self.participantCollection()[i].seqNo, originalId: participantNetwork.get("collaboration").get('id'), name: participantNetwork.get('name'), pnDisplayname: "", businessColl: businessColl, customerColl: customerColl, partnerColl: partnerColl, attached: true, notAssigned: notAssignedRoles };
                        self.participantCollection.replace(self.participantCollection()[i], exisObj);
                    }
                }
            }
        }
        removeDeletedParticipants(participantColl, assignedRoles) {
            var self = this;
            var len = participantColl.length;
            while (len) {
                len--;
                for (var j = 0; j < self.deleteParticipantArray.length; j++) {
                    if (participantColl[len].originalId === self.deleteParticipantArray[j].originalId) {
                        participantColl.splice(len, 1);
                        var roles = self.deleteParticipantArray[j].roles;
                        for (var i = 0; i < roles.length; i++) {
                            for (var k = 0; k < assignedRoles.length; k++) {
                                if (assignedRoles[k].originalId == roles[i].originalId) {
                                    assignedRoles.splice(k, 1);
                                    break;
                                }
                            }
                        }
                        break;
                    }
                }
            }
            return participantColl;
        }

        createDefaultBusiness(businessColl, participantNetwork) {
            var self = this;
            var busRoleColl = [];
            var defaultBusiness = self.model.get('business');
            var newAssignment = self.model.createDefaultBusinessRole(defaultBusiness, "My Business Role", "My Business Role", participantNetwork);
            var newRole = newAssignment.get("assignedRole");
            self.assignmentsColl[defaultBusiness.get("id")].push(newAssignment);
            busRoleColl.push({ name: newRole.get("name"), originalId: newRole.get("id") });
            var businessNspath = self.getParticipantDispName(defaultBusiness);
            businessColl.push({ modalId: self.htmlEscape(defaultBusiness.get("id")) + "business",businessNspath: businessNspath, name: defaultBusiness.get("name"), originalId: defaultBusiness.get("id"), roles: busRoleColl });
        }
        cancelParticipantNetwork() {
            var self = this;
            for (var i = 0; i < self.children.length; i++) {
                window.cleanDialogModel(self.children[i].modalId, self.children[i].view);
            }
            $("#PnMessage").text("");
            self.partnerCollection([]);
            self.customerCollection([]);
            if (self.model.get('business')) {
                if (!self.business()) {
                    self.business(self.model.get('business'));
                }
                self.businessName(self.model.get("business").get("name"));
                self.businessCollection([{ modalId: self.htmlEscape(self.model.get("business").get("id")) + "business", name: self.model.get("business").get("name"), originalId: self.model.get("business").get("id"), businessId: self.model.get("business").get("id"), roles: self.businessRole }]);
            }
            else {
                self.businessCollection([]);
                self.business(null);
                $("#newBusinessDiv").show();
            }
            self.customerArray = [];
            self.partnerArray = [];
            self.children = [];
            self.removeRoleArray = [];
            self.deleteParticipantArray = [];
            self.participantName = null;
            self.pNCollaborationModal = null;
            self.pNModel = null;
            self.pnAttached = null;
            $("#participantName").val("");
            self.canClickCompleteParticipantNetwork(false);
            self.fillCustomerTypeaheads();
            if (self.selectedPNRow) {
                $(self.selectedPNRow).removeClass('highlightrow');
            }
            self.selectedPNRow = null;
            self.enableParticipantDelete(false);
            self.enableParticipantCancel(false);
            self.participantNetworkChanges = false;
            self.disallowedNames = [];
        };
        //#startCustomMethods       
        handlePublicMenus() {
            var self = this;
            /*  function handleNew(){
                    if(!self.wizard){
                        self.wizard = WizardModel.getInstance([
                            //{type:'AssignedTicket',handler:$.proxy(this.createNewAssignedTicket,this),selectable:true,selectCollection:$.proxy(this.getAssignedTicket,this)},
                        //  {type:self.labels['assignedTicket'](),handler:$.proxy(this.createNewAssignedTicket,this)},
                            {type:self.labels['bmCustomer'](),handler:$.proxy(this.createNewBmCustomer,this),selectable:true,selectCollection:$.proxy(this.getBMCustomers,this)},
                            {type:self.labels['bmValueProposition'](),handler:$.proxy(this.createNewBmValueProposition,this),selectable:true,selectCollection:$.proxy(this.getBMValuePropositions,this)},
                            {type:self.labels['bmActivity'](),handler:$.proxy(this.createNewBmActivity,this),selectable:true,selectCollection:$.proxy(this.getBMActivities,this)},
                            {type:self.labels['bmCompetence'](),handler:$.proxy(this.createNewBmCompetence,this),selectable:true,selectCollection:$.proxy(this.getBMCompetencies,this)},
                            {type:self.labels['bmNetworkPartner'](),handler:$.proxy(this.createNewBmNetworkPartner,this),selectable:true,selectCollection:$.proxy(this.getBMNetworkPartners,this)},
                            {type:self.labels['bmValueFormula'](),handler:$.proxy(this.createNewBmValueFormula,this),selectable:true,selectCollection:$.proxy(this.getBMValuePropositions,this)}
                        ]);
                    }
                    self.wizard.startWizard();
                }*/

            /*function handleTickets(){
                if(!self.wizardTicket){
                    self.wizardTicket = WizardModel.getInstance([
                        {type:self.labels['Dispute'](),handler:$.proxy(this.createNewAssignedTicket,this,'Dispute')},
                        {type:self.labels['Suggestion'](),handler:$.proxy(this.createNewAssignedTicket,this,'Suggestion')},
                        {type:self.labels['Validation'](),handler:$.proxy(this.createNewAssignedTicket,this,'Validation')},
                        {type:self.labels['TODO'](),handler:$.proxy(this.createNewAssignedTicket,this,'TODO')}
                    ]);
                }
                self.wizardTicket.startWizard();
    
            }*/
            //  DataManager.getDataManager().addContextBarMenuToNode("glyphicon glyphicon-plus","Ticket","createTicket",$.proxy(handleTickets,this));
            //  DataManager.getDataManager().addContextBarMenuToNode("glyphicon glyphicon-plus","BusinessModel","createBee",$.proxy(handleTest,this));
            //  DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-plus","New",$.proxy(handleNew,this));
            //  DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-remove","Remove",$.proxy(this.removeSelectedItems,this));
            //	DataManager.getDataManager().addContextBarMenuToNode("glyphicon glyphicon-minus","Remove","removeTickets",$.proxy(self.removeSelectedItems,this));
            DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-wrench", "Show Object Explorer", $.proxy(this.showobjectExplorerTree, this));
            DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-print", "Print", $.proxy(this.printReport, this));
            DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-check", "Validation", $.proxy(this.autoValidation, this));
            //	DataManager.getDataManager().addContextMenu("","Delete",$.proxy(this.deleteModel,this),$("#actionsList"));
            //  DataManager.getDataManager().addContextMenu("glyphicon glyphicon-eye-open","Advanced Options",$.proxy(this.showMisclaneousDetails,this),$("#actionsList"));
            //DataManager.getDataManager().addContextMenu("glyphicon glyphicon-picture","Show Cube",$.proxy(this.showBMCube,this),$("#actionsList"));
            //	DataManager.getDataManager().addContextMenu("","Show Cube",$.proxy(this.showBMCube,this),$("#actionsList"));
        };

        autoValidation() {
            var self = this;
            var addOptions = this;
            window.getAndCreateModalDialog(true, self.encodeId, BusinessModelMixin, self.model, "businessModelValidation", closeView, addOptions);
            function closeView() {
                window.cleanDialogModel(self.encodeId, self.model.getMixinClass());
            }
        };

        printReport() {
            var self = this;
            var id = self.htmlEscape(window.guidGenerator()) + "print";
            self.resizable = false;
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "BusinessModelReportDetails", null, self);

        }

        showobjectExplorerTree() {
            var self = this;
            var addOptions = this;
            var vdm = self.model.getNestedParent();
            function closeView() {
                window.cleanDialogModel(self.encodeId, self.model.getMixinClass());
            }
            window.getAndCreateModalDialog(true, self.encodeId, self.model.getMixinClass(), vdm, "explorerModelJson", closeView, addOptions);
        }
        //#endCustomMethods     
        setResizeHeightHandler(reDraw) {
            var self = this;
            setTimeout(function () {
                    var rightTab = $('#rightTab');
                    if (rightTab.width() > 10 && rightTab.children().last()[0].classList.contains('dropdown') /*&& rightTab.height() < 50*/) {
                        var dropdownDiv = rightTab.children().last();
                        var appendList = dropdownDiv.find('li');
                        var removedList = [];
                        for (var i = 0; i < appendList.length; i++) {
                            /*if(rightTab.width() < 370 && rightTab.width() > 307){
                                dropdownDiv.before(appendList[i]);
                                removedList.push(appendList[i]);
                                break;	
                            }else {*/
                            dropdownDiv.before(appendList[i]);
                            removedList.push(appendList[i]);
                            if (self.selectedRightTab == appendList[i].textContent) {
                                (appendList.eq(i).addClass('active'))
                            }
                            /*if(rightTab.height() > 50){
                                break;
                            }
                        }*/
                        }
                        if (appendList.length === removedList.length) {
                            dropdownDiv.remove();
                        }/*else {
							var listlen = removedList.length;
							while(listlen--){
								dropdownDiv.find('li').remove(listlen);
							}
						}*/
                    }
                    if (rightTab.width() > 10 && rightTab.width() < 500 && rightTab.children().length > 2/* && rightTab.height() > 50*/) {
                        var rightChildren = rightTab.children();
                        var appendList = [];
                        for (var i = rightChildren.length; i > 0; i--) {
                            if (!rightChildren[i - 1].classList.contains('dropdown')) {
                                appendList.push(rightChildren[i - 1]);
                                if (rightTab.width() < 450 && rightChildren[i - 2]) {
                                    appendList.push(rightChildren[i - 2]);
                                    if (rightTab.width() < 330 && rightChildren[i - 3]) {
                                        appendList.push(rightChildren[i - 3]);
                                        if (rightTab.width() < 235 && rightChildren[i - 4]) {
                                            appendList.push(rightChildren[i - 4]);
                                        }
                                        break;
                                    }
                                    break;
                                } else {
                                    break;
                                }
                            }
                        }
                        if (!rightChildren.last()[0].classList.contains('dropdown')) {
                            rightTab.append('<li class="dropdown"><a class="dropdown-toggle" data-toggle="dropdown" href="#"><span class="caret"></span></a><ul style="right:0" class="dropdown-menu pull-right"></ul></li>');
                        }
                        for (var i = 0; i < appendList.length; i++) {
                            rightTab.children().last().find('ul').prepend(appendList[i]);
                        }
                }

                //var splitterNode = $('#splitPanels');
                if ($('#myTab .active a')[0] && self.spliter) {
                    var bmDivId = $('#myTab .active a')[0].hash;
                    var bmNode = $('' + bmDivId);
                    var propAreaHeight = 0, baseHeight = 0;
                    var rightTabHeight = 0;
                    
                    if (rightTab.width() > 0) {
                        rightTabHeight = rightTab.outerHeight();
                        if ($('#rightTab .active a')[0]) {
                            var propDivId = $('#rightTab .active a')[0].hash;
                            if (propDivId !== null && $('#rightTab .dropdown .active a')[0]) {
                                propDivId = $('#rightTab .dropdown .active a')[0].hash;
                            }
                            var propNode = $('' + propDivId);
                            propAreaHeight = rightTabHeight + propNode.outerHeight() + 60;
                        }
                    }
                    var pageHeaderNode = $('.page-header');
                    var bmNodeHeight = bmNode.actual('outerHeight');
                    var tabHeaderHeight = $('#myTab').actual('outerHeight');
                    var leftNodeHeight = bmNodeHeight + tabHeaderHeight + pageHeaderNode.actual('outerHeight');
                    var topAreaHeight = $('.navbar').outerHeight();
                    //There is margin-top 35px for the chart
                    baseHeight = _.max([propAreaHeight + topAreaHeight, leftNodeHeight + 55, window.innerHeight - topAreaHeight - 5]);
                    //splitterNode.css('height', baseHeight + 'px');
                    if ($("#Progress").hasClass('active') && $("#placeholder" + self.encodeId).width() > 0 && reDraw && rightTab.width() > 0) {
                        self.chartViewInstance.initialize(self.model, true, self);
                    }
                    var beforeScrollExists = $("body").height() > $(window).height();
                    self.spliter.css('height', (baseHeight) + 'px');
                    var afterScrollExists = $("body").height() > $(window).height();
                    if (rightTab.width() < 5 && beforeScrollExists != afterScrollExists && !afterScrollExists) {
                        var windowWidth = $(window).width() - 10;
                        $('.splitter_bar').css('left', windowWidth);
                    }
                    if (rightTab.width() > 5) {
                        $("#splitPanels").enhsplitter('refresh');
                    }
                    if (rightTab.children().last()[0] && rightTab.children().last()[0].classList.contains('dropdown') && propNode && propNode.parent()[0] && propNode.parent()[0].classList.contains('tab-content')) {
                        var dropdownDiv = rightTab.children().last();
                        var appendList = dropdownDiv.find('li');
                        for (var i = 0; i < appendList.length; i++) {
                            appendList.eq(i).removeClass('active');
                        }
                    }
                }
                window.utils.stopSpinner('refreshCharts');
            }, 150);
        };
        fillProviders() {
            var self = this;
            self.providers([]);
            var participants = self.model.getParticipants();
            participants.sort(function (left, right) {
                return left.get('name').toLowerCase() == right.get('name').toLowerCase() ? 0 : (left.get('name').toLowerCase() < right.get('name').toLowerCase() ? -1 : 1)
            });
            _.each(participants, function (participant, index, list) {
                var displayName = participant.get('name');
                for (var i = 0; i < self.providers().length; i++) {
                    if (self.providers()[i].providerName === participant.get('name')) {
                        displayName = displayName.concat(self.fetchParticipantNetworkNames(participant));
                        break;
                    }
                }
                self.providers.push({ id: participant.get('id'), providerName: participant.get('name'), providerDescription: participant.get('description'), displayName: displayName });
            });
        }

        checkDuplicatesPath(models, element, path) {
            var self = this;
            for (var i = 0; i < models.length; i++) {
                if ((element.get('name') === models[i].get('name') && (element.get('id') !== models[i].get('id')))) {
                    for (var k = 0; k < element.getPackagePath().length - 1; k++) {
                        path = path.concat(" [" + element.getPackagePath()[k].name);
                        if (element.getPackagePath()[k + 2]) {
                            path = path.concat("/");
                        }
                    }
                    path = path.concat("]");
                    break;
                }
            }
            return path;
        }
        sortValueAggregation(type) {
            var self = this;
            function checkAggregateFromExists(originalId) {
                for (var m = 0; m < self.sortedAggregatedValues.length; m++) {
                    var aggId = self.sortedAggregatedValues[m].originalId;
                    if (!aggId) {
                        aggId = self.sortedAggregatedValues[m].view.encodeId;
                    }
                    if (aggId === originalId) {
                        return self.sortedAggregatedValues[m];
                    }
                }
                return null;
            }
            self.sortedAggregatedValues = [];
            for (var i = 0; i < self.children.length; i++) {
                if (self.children[i].type == type && self.children[i].view.aggregateColl) {
                    if (self.children[i].view.aggregateColl().length == 0) {
                        self.sortedAggregatedValues.unshift(self.children[i]);
                    } else {
                        self.sortedAggregatedValues.push(self.children[i]);
                    }
                }
            }
            self.sortedAggregatedValues.sort(function (m1, m2) {
                if (m1.view.aggregateColl().length > 0) {
                    var tempArray = [];
                    tempArray.push(m1);
                    for (var i = 0; i < tempArray.length; i++) {
                        if (tempArray[i] && tempArray[i].view.aggregateColl().length > 0) {
                            for (var k = 0; k < tempArray[i].view.aggregateColl().length; k++) {
                                var value = tempArray[i].view.aggregateColl()[k].originalId;
                                if (!value) {
                                    value = tempArray[i].view.aggregateColl()[k].aggregateid;
                                }
                                if (value == m1.modalId) {
                                    return 0;
                                }
                                else {
                                    tempArray.push(checkAggregateFromExists(value));
                                }
                            }
                        }
                        if (tempArray[i] && tempArray[i].modalId === m2.modalId) {
                            return 1;
                        }
                        else if (tempArray[i] && i > 0 && tempArray[i].modalId === m1.modalId) {
                            return 0;
                        }
                    }
                }
                return -1;
            });
            return self.sortedAggregatedValues;
        }
        fillReceivers() {
            var self = this;
            self.receivers([]);
            var participants = self.model.getParticipants();
            participants.sort(function (left, right) {
                return left.get('name').toLowerCase() == right.get('name').toLowerCase() ? 0 : (left.get('name').toLowerCase() < right.get('name').toLowerCase() ? -1 : 1)
            });
            _.each(participants, function (element, index, list) {
                var displayRecieverName = element.get('name');
                for (var i = 0; i < self.receivers().length; i++) {
                    if (self.receivers()[i].receiverName === element.get('name')) {
                        displayRecieverName = displayRecieverName.concat(self.fetchParticipantNetworkNames(element));
                        break;
                    }
                }
                self.receivers.push({ id: element.get('id'), receiverName: element.get('name'), receiverDescription: element.get('description'), displayName: displayRecieverName });
            });
        }
        checkAggregationDeletion(newReciverRole, newProviderRole, vp, structuralChange, messageShown, bms, callback) {
            var self = this;
            if (vp) {
                var businessRoles = self.model.getBusinessRoles();
                var hasBusinessRole = false;
                for (var i = 0; i < businessRoles.length; i++) {
                    if (businessRoles[i] === newProviderRole || businessRoles[i] === newReciverRole) {
                        hasBusinessRole = true;
                        break;
                    }
                }
                
            }
            var aggTo = [];
            var agg = [];
            var removeAggList = [];

            if (messageShown) {
                var components = vp.get('component');
                for (var i = 0; i < components.length; i++) {
                    aggTo.push.apply(aggTo, components.at(i).get('aggregatedTo').models.concat());
                }
                for (var k = 0; k < aggTo.length; k++) {
                    var valueExists = false;
                    if (aggTo[k].get('type') === "vdml_ValuePropositionComponent") {
                        var aggVp = aggTo[k].get('componentOwner');
                    } else if (aggTo[k].get('type') === "vdml_ValueAdd") {
                        aggVp = aggTo[k].get('valueAddOwner').get('containedPortOwner');
                    }
                    if (aggVp && aggVp != vp) {
                        if (aggTo[k].getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
                            valueExists = true;
                            continue;
                        }
                        for (var j = 0; j < bms.length; j++) {
                            if (self.model !== bms[j]) {
                                var bmMyValueProp = bms[j].get('bmValueFormula');
                                if (aggVp.get('isValueFormula') && bmMyValueProp.findWhere({ 'valueFormula': aggVp })) {
                                    valueExists = true;
                                }
                                var bmValueProp = bms[j].get('bmValueProposition');
                                if (!aggVp.get('isValueFormula') && bmValueProp.findWhere({ 'valueProposition': aggVp })) {
                                    valueExists = true;
                                }
                                var activity = bms[j].get('bmActivity');
                                if (activity.findWhere({ 'activity': aggVp }) && (!bms[j].get('bmRole').findWhere(newReciverRole) || !bms[j].get('bmRole').findWhere(newProviderRole))) {
                                    valueExists = true;
                                }
                                if (valueExists) {
                                    break;
                                }
                            }
                        }
                        if (valueExists) {
                            removeAggList.push(aggTo[k]);
                        }
                    }
                }
                removeAggList = _.uniq(removeAggList);
            }
            if (removeAggList.length > 0) {
                for (var i = 0; i < removeAggList.length; i++) {
                    for (var j = 0; j < components.length; j++) {
                        var compExists = components.at(j).get('aggregatedTo').findWhere({ 'id': removeAggList[i].get('id') });
                        if (compExists) {
                            var found = false;
                            for (var k = 0; k < agg.length; k++) {
                                if (agg[k].removeagg === removeAggList[i]) {
                                    agg[k].sourceagg.push(components.at(j));
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                agg.push({ 'sourceagg': [components.at(j)], 'removeagg': removeAggList[i] });
                            }
                        }
                    }
                }
            }
            if (!hasBusinessRole && vp) {
                var components = vp.get('component');
                //var aggTo = [];
                for (var i = 0; i < components.length; i++) {
                    var compAggTo = components.at(i).get('aggregatedTo');
                    for (var j = 0; j < compAggTo.length; j++) {
                        var compOwner = compAggTo.at(j).get('componentOwner');
                        if (compOwner && compOwner.get('isValueFormula')) {
                            var found = false;
                            for (var k = 0; k < agg.length; k++) {
                                if (agg[k].removeagg === compAggTo.at(j)) {
                                    agg[k].sourceagg.push(components.at(i));
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                //if (compAggTo.at(j).getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
                                agg.push({ 'sourceagg': [components.at(i)], 'removeagg': compAggTo.at(j) });
                                //}
                            }
                        }
                    }
                }
                if (agg.length > 0) {
                    if (messageShown) {
                        self.createRevisionBasedOnContext(structuralChange, function () {
                            window.utils.startSpinner('saveValPropostion', "Saving Value Proposition...");
                            window.setTimeout(function () {
                                var oldAltIdReg = new RegExp(DataManager.getDataManager().get('currentWorkspace').get('id'), 'g');
                                async.eachSeries(agg, function (comp, saveCallback) {
                                    var removeAggComp = self.getNewViewBackboneModel(comp.removeagg, oldAltIdReg);
                                    var sourceAggArray = [];
                                    for (var i = 0; i < comp.sourceagg.length; i++) {
                                        sourceAggArray.push(self.getNewViewBackboneModel(comp.sourceagg[i], oldAltIdReg));
                                    }
                                    var scenario = self.currentAlternativeModel.getMainScenario(removeAggComp.getNestedParent());
                                    removeAggComp.reCalculateValues(sourceAggArray, scenario, function (changeInValue) { saveCallback(); });
                                }, function (err) {
                                    callback();
                                });
                            }, 100);
                        });
                    } else {
                        bootbox.confirm(DataManager.getDataManager().get('localeManager').get('UpdateValueProposition'), function (result) {
                            if (result) {
                                self.createRevisionBasedOnContext(structuralChange, function () {
                                    window.utils.startSpinner('saveValPropostion', "Saving Value Proposition...");
                                    window.setTimeout(function () {
                                        var oldAltIdReg = new RegExp(DataManager.getDataManager().get('currentWorkspace').get('id'), 'g');
                                        async.eachSeries(agg, function (comp, saveCallback) {
                                            var removeAggComp = self.getNewViewBackboneModel(comp.removeagg, oldAltIdReg);
                                            var sourceAggArray = [];
                                            for (var i = 0; i < comp.sourceagg.length; i++) {
                                                sourceAggArray.push(self.getNewViewBackboneModel(comp.sourceagg[i], oldAltIdReg));
                                            }
                                            var scenario = self.currentAlternativeModel.getMainScenario(removeAggComp.getNestedParent());
                                            removeAggComp.reCalculateValues(sourceAggArray, scenario, function (changeInValue) { saveCallback(); });
                                        }, function (err) {
                                            callback();
                                        });
                                    }, 100);
                                });
                            } else {
                                return;
                            }
                        });
                    }
                } else {
                    self.createRevisionBasedOnContext(structuralChange, function () {
                        window.utils.startSpinner('saveValPropostion', "Saving Value Proposition...");
                        window.setTimeout(callback, 100);
                    });
                }
            } else {
                self.createRevisionBasedOnContext(structuralChange, function () {
                    window.utils.startSpinner('saveValPropostion', "Saving Value Proposition...");
                    window.setTimeout(function () {
                        if (agg.length > 0 && messageShown) {
                            var oldAltIdReg = new RegExp(DataManager.getDataManager().get('currentWorkspace').get('id'), 'g');
                            async.eachSeries(agg, function (comp, saveCallback) {
                                var removeAggComp = self.getNewViewBackboneModel(comp.removeagg, oldAltIdReg);
                                var sourceAggArray = [];
                                for (var i = 0; i < comp.sourceagg.length; i++) {
                                    sourceAggArray.push(self.getNewViewBackboneModel(comp.sourceagg[i], oldAltIdReg));
                                }
                                var scenario = self.currentAlternativeModel.getMainScenario(removeAggComp.getNestedParent());
                                removeAggComp.reCalculateValues(sourceAggArray, scenario, function (changeInValue) { saveCallback(); });
                            }, function (err) {
                                callback();
                            });
                        } else {
                            callback();
                        }
                    }, 100);
                });
            }
        }
        checkMessageforVP(recipient, provider, valueProposition, callback) {
            var self = this;
            if (valueProposition && valueProposition.get('recipient') != recipient) {
                DataManager.getDataManager().getReverseAssociationInstances(valueProposition, 'vdml_BusinessModelValueProposition-valueProposition', 'vdml_BusinessModelValueProposition', "vbc:" + "cmof_EObject-name",
                    function (bmVPs) {
                        var showMessage = false;
                        var bms = [];
                        var completeMsg;
                        var msg = '';
                        var bmModelsCount = 0;
                        bmVPs.each(function (bmVP) {
                            var bm = bmVP.get('bmValuePropositionOwner');
                            var bmRoles = bm.get('bmRole');
                            var pRole = bmRoles.findWhere({ id: provider.get('id') });
                            if (!pRole) {
                                showMessage = true;
                                bmModelsCount++;
                                bms.push(bm);
                                msg = msg.concat('<br/>' + DataManager.getDataManager().get('localeManager').get('ProvidedRole', bm.get('name'), provider.get('name'), bm.get('name')));
                            } else {
                                if (recipient) {
                                    var rRole = bmRoles.findWhere({ id: recipient.get('id') });
                                    if (!rRole) {
                                        showMessage = true;
                                        bmModelsCount++;
                                        bms.push(bm);
                                        msg = msg.concat('<br/>' + DataManager.getDataManager().get('localeManager').get('ReceivedRole', bm.get('name'), recipient.get('name'), bm.get('name')));
                                    }
                                }
                            }
                        });
                        if (showMessage) {
                            if (bmModelsCount > 1) {
                                completeMsg = '' + DataManager.getDataManager().get('localeManager').get('UpdateValuePropositionOtherBm', valueProposition.get('name')) + 's';
                            } else {
                                completeMsg = DataManager.getDataManager().get('localeManager').get('UpdateValuePropositionOtherBm', valueProposition.get('name'));
                            }
                            completeMsg = completeMsg.concat('' + msg + '.<br/>' + DataManager.getDataManager().get('localeManager').get('ConfirmMessage'));
                            bootbox.confirm(completeMsg, function (result) {
                                if (result) {
                                    bms = _.uniq(bms);
                                    callback(showMessage, bms);
                                } else {
                                    return;
                                }
                            });
                        } else {
                            callback();
                        }
                    }, true);
            } else {
                callback();
            }
        }
        saveValueProposition(view, event, callback) {
            var self = this;
            if(self.vpName() == ""){
                self.vpName($("#vpName").val());
            }
            if(!self.vpName() || self.vpName().length == 0){
                callback && callback();
                return;
            }
            var structuralChange = self.checkforStructuralChange('valueProposition');
            var providerRole = self.model.get('bmRole').get(self.selectedProviderRole());
            var receiverRole = self.selectedReceiverRole() ? self.model.get('bmRole').get(self.selectedReceiverRole()) : null;
            var vp = self.selectedValueProposition();
            self.checkMessageforVP(receiverRole, providerRole, vp, function (messageShown, bms) {
                self.checkAggregationDeletion(receiverRole, providerRole, vp, structuralChange, messageShown, bms, saveValPropostion);
            });
            function saveValPropostion() {
                if (window.checkContextForRevision() && structuralChange) {
                    var oldAltIdReg = new RegExp(DataManager.getDataManager().get('currentWorkspace').get('id'), 'g');
                    var newProviderRoleId = providerRole.get('id').replace(oldAltIdReg, DataManager.getDataManager().get('viewAlternative'));
                    var newReceiverRoleId = receiverRole ? receiverRole.get('id').replace(oldAltIdReg, DataManager.getDataManager().get('viewAlternative')) : null;
                    providerRole = self.model.get('bmRole').get(newProviderRoleId);
                    receiverRole = newReceiverRoleId ? self.model.get('bmRole').get(newReceiverRoleId) : null;
                }
                if (!self.bmValueProposition()) {
                    self.model.addBMValueProposition(self.selectedValueProposition(), self.vpName(), providerRole, receiverRole, null, self.currentAlternativeModel, function (bmVP) {
                        self.bmValueProposition(bmVP);
                        saveValuePropositionValues();
                    });
                } else {
                    self.bmValueProposition().updateBMValueProposition(self.selectedValueProposition(), self.vpName(), providerRole, receiverRole);
                    saveValuePropositionValues();
                }
                function saveValuePropositionValues() {
                    var sortedvalues = self.sortValueAggregation("valueProposition");
                    var valueChange = false;
                    async.each(sortedvalues, function (child, saveCallback) {
                        if (child.type === 'valueProposition') {
                            child.view.saveData(self.model, self.bmValueProposition().get('valueProposition'), function (changeInValue) {
                                if (changeInValue) {
                                    valueChange = true;
                                }
                                saveCallback();
                            }, self.tempValuesForAggregation);
                        } else {
                            saveCallback();
                        }
                    }, function (err) {
                        self.deleteValuePropositionValues(self.valuePropositionValuesColl(), self.selectedValueProposition(), structuralChange);
                        var updateOtherScenarios = false;
                        for (var i = 0; i < self.valuePropositionValuesColl().length; i++) {
                            if (self.valuePropositionValuesColl()[i]._destroy && self.valuePropositionValuesColl()[i].id != '') {
                                valueChange = true;
                                updateOtherScenarios = true;
                            }
                        }
                        DataManager.getDataManager().invokeValueUpdates(handleView, updateOtherScenarios);
                        function handleView() {
                            if (!structuralChange && valueChange && window.checkContextForRevision()) {
                                var context = self.currentAlternativeModel.getMainScenario(self.model.getNestedParent());
                                context.set('localChange', true);
                            }
                            if (window.checkContextForRevision() && structuralChange && !callback) {
                                self.disableAllCompleteButtons();
                                DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                            }
                            else if (callback) {
                                callback();
                            }
                            else {
                                if (self.valuePropositionValuesColl().length > 0 && valueChange) {
                                    self.refreshWidgetData(true);
                                }
                                self.cancelValueProposition();
                                self.cancelValueFormula();
                                self.resetVPData();
                                self.resetVFData();
                                self.resetActivityData(true);
                                self.cancelActivity(); 
                                self.fillValuesTableFilterRoles();
                                self.setResizeHeight();
                            }
                            window.utils.stopSpinner('saveValPropostion');
                        }

                    });
                }
            };
        }
        getUniqueFilterArrayData(origArr) {
            var self = this;
            var newArr = [];
            for (var i = 0; i < origArr.length; i++) {
                var found = false;
                for (var j = 0; j < newArr.length; j++) {
                    if (origArr[i].id === newArr[j].id) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    newArr.push(origArr[i]);
                }
            }
            return newArr;
        }
        fetchSmileySrc(smileyValue) {
            var self = this;
            //var smileyValue = smileyValue?smileyValue:'1';
            var smileySrc = '';
            for (var j = 0; j < self.svgSmiliesList.length; j++) {
                if (self.svgSmiliesList[j].value === smileyValue) {
                    smileySrc = self.svgSmiliesList[j].src;
                    break;
                }
            }
            return smileySrc;
        };
        fillValuesTab() {
            var self = this;
            function canPushValue(providerId, recieverId) {
                var providerFound = false;
                var recieverFound = false;
                for (var i = 0; i < self.selectedRoleFilter().length; i++) {
                    if (self.selectedRoleFilter()[i] === providerId) {
                        providerFound = true;
                        break;
                    }
                }
                for (var i = 0; i < self.selectedRecieverRoleFilter().length; i++) {
                    if (self.selectedRecieverRoleFilter()[i] === recieverId) {
                        recieverFound = true;
                        break;
                    }
                }
                if (recieverId === "") {
                    recieverFound = true;
                }
                if (providerFound && recieverFound) {
                    return true;
                }
                return false;
            }
            self.bmValues([]);
            var bmRoles = self.model.get('bmRole');
            bmRoles.each(function (bmrole) {
                var propostions = bmrole.get('providedProposition');
                propostions.each(function (proposition) {
                    if (self.model.get('bmValueProposition').findWhere({ 'valueProposition': proposition }) || self.model.get('bmValueFormula').findWhere({ 'valueFormula': proposition })) {
                        var components = proposition.get('component');
                        components.each(function (valComp) {
                            var valAndUnitObj = self.getMeasurementValueandUnit(valComp, valComp.get('valueMeasurement'));
                            var recipientMeasurement = valComp.get('recipientOpinion');
                            var recipient = "";
                            if (recipientMeasurement) {
                                recipient = self.getMeasurementValueandUnit(valComp, recipientMeasurement);
                                recipient = recipient['value'] ? recipient['value'] + " " + valAndUnitObj['unit'] : "";
                            }
                            var val = valAndUnitObj['value'] ? window.utils.thousandSeparator(valAndUnitObj['value']) + " " + valAndUnitObj['unit'] : "";
                            var compOwner = valComp.get('componentOwner');
							var selectedInterval = valComp.getSatisfactionInterval(self.mainScenario());
							var valSatisfaction = "";
                            var satisfactionSmiley = "";
							if(selectedInterval){
								var smileyValue = selectedInterval.get('smiley') ? selectedInterval.get('smiley') : '';
                                var smileyColor = selectedInterval.get('smileyColor') ? selectedInterval.get('smileyColor') : '#000000';
                                satisfactionSmiley = self.fetchSmileySrc(smileyValue);
							}
                            /*var satisfactionMeasurement = self.mainScenario().getTSLessMeasurementReferringToMeasuredCharteristic(valComp.get('satisfactionLevel'));
                            if (satisfactionMeasurement && compOwner.get('overAllSatisfaction') !== valComp) {
                                valSatisfaction = satisfactionMeasurement.get('symbol') ? satisfactionMeasurement.get('symbol') : satisfactionMeasurement.get('value');
                                var satType = 'symbol';
                                var satMeasure = satisfactionMeasurement.get('observedMeasure').get('measure');
                                if (satisfactionMeasurement.get('value') && satMeasure && satMeasure.get('unit')) {//Ranking
                                    valSatisfaction = valSatisfaction.concat(" " + satMeasure.get('unit').get('name'));
                                    satType = 'value';
                                }
                                if (satMeasure && valSatisfaction != '') {
                                    var intervals = satMeasure.get('interval');
                                    for (var i = 0; i < intervals.length; i++) {
                                        if (intervals.at(i).get('' + satType) === satisfactionMeasurement.get('' + satType)) {
                                            var smileyValue = intervals.at(i).get('smiley') ? intervals.at(i).get('smiley') : '';
                                            var smileyColor = intervals.at(i).get('smileyColor') ? intervals.at(i).get('smileyColor') : '#000000';
                                            satisfactionSmiley = self.fetchSmileySrc(smileyValue);
                                            break;
                                        }
                                    }

                                }
                            }*/
                            var providerParticipant = self.model.getRoleParticipant(compOwner.get('provider'));
                            var providerParticipantName = providerParticipant ? providerParticipant.get('name') : '';
                            var providerType = "";
                            if (providerParticipantName !== '') {
                                providerType = self.fillRolepath(compOwner.get('provider'));
                            }
                            var recieverType = "";
                            var recieverParticipantName = "";
                            var recieverId = "";
                            var recieverRoleName = "";
                            if (compOwner.get('recipient')) {
                                var recieverParticipant = self.model.getRoleParticipant(compOwner.get('recipient'));
                                recieverParticipantName = recieverParticipant ? recieverParticipant.get('name') : '';
                                if (recieverParticipantName !== '') {
                                    recieverType = self.fillRolepath(compOwner.get('recipient'));
                                }
                                recieverId = compOwner.get('recipient').get('id');
                                recieverRoleName = compOwner.get('recipient').get('name');
                            }
                            var satisfactionMeasurement = self.mainScenario().getTSLessMeasurementReferringToMeasuredCharteristic(valComp.get('satisfactionLevel'));
                            if (satisfactionMeasurement /*&& compOwner.get('overAllSatisfaction') !== valComp*/) {
                                valSatisfaction = satisfactionMeasurement.get('symbol') ? satisfactionMeasurement.get('symbol') : satisfactionMeasurement.get('value');
                            }
                            var cycle = false;
                            if (valComp.get('isIterative')) {
                                cycle = true;
                            }
                            //var source = self.getSourceType(valComp);
                            var sourceName = compOwner.get('name');
                            if (canPushValue(compOwner.get('provider').get('id'), recieverId)) {
                                self.bmValues.push({ id: valComp.id,cycle:cycle, source : sourceName, name: valComp.get('name'), value: val, from: compOwner.get('provider').get('name'), to: recieverRoleName, satisfaction: valSatisfaction, satisfactionSmiley: satisfactionSmiley, smileyColor: smileyColor, formula: valAndUnitObj['formula'], recipient: recipient, providerParticipantName: providerParticipantName, providerType: providerType, recieverParticipantName: recieverParticipantName, recieverType: recieverType, isCollective: valAndUnitObj['isCollective'] });
                            }
                        });
                    }
                });
            });
            var bmActivities = self.model.get('bmActivity');
            bmActivities.each(function (bmActivity) {
                var actValues = bmActivity.get('activityValue');
                actValues.each(function (actValue) {
                    var providerName = actValue.get('valueAddOwner').get('containedPortOwner').get('performingRole').get('name');
                    var providerId = actValue.get('valueAddOwner').get('containedPortOwner').get('performingRole').get('id');
                    var providerParticipant = self.model.getRoleParticipant(actValue.get('valueAddOwner').get('containedPortOwner').get('performingRole'));
                    var providerParticipantName = providerParticipant ? providerParticipant.get('name') : '';
                    var providerType = "";
                    if (providerParticipantName !== '') {
                        providerType = self.fillRolepath(actValue.get('valueAddOwner').get('containedPortOwner').get('performingRole'));
                    }
                    var recieverType = "";
                    var recieverParticipantName = "";
                    var valAndUnitObj = self.getMeasurementValueandUnit(actValue, actValue.get('valueMeasurement'));
                    var val = valAndUnitObj['value'] ? window.utils.thousandSeparator(valAndUnitObj['value']) + " " + valAndUnitObj['unit'] : "";
                    var satisfactionMeasurement = self.mainScenario().getTSLessMeasurementReferringToMeasuredCharteristic(actValue.get('satisfactionLevel'));
                    var valSatisfaction = " ";
                    if (satisfactionMeasurement) {
                        valSatisfaction = satisfactionMeasurement.get('symbol') ? satisfactionMeasurement.get('symbol') : satisfactionMeasurement.get('value');
                    }
                    //var source = self.getSourceType(actValue);
                    var cycle = false;
                    if (actValue.get('isIterative')) {
                        cycle = true;
                    }
                    var sourceName = actValue.get('valueAddOwner').get('containedPortOwner').get('name');
                    if (canPushValue(providerId, "")) {
                        self.bmValues.push({ id: actValue.id,cycle:cycle,source : sourceName,satisfactionSmiley:false,smileyColor: "", name: actValue.get('name'), value: val, from: providerName, to: "", satisfaction: valSatisfaction, formula: valAndUnitObj['formula'], recipient: "", providerParticipantName: providerParticipantName, providerType: providerType, recieverParticipantName: recieverParticipantName, recieverType: recieverType, isCollective: valAndUnitObj['isCollective'] });
                    }
                });
            });
            self.sortCollection(self.bmValues);
        }
        fillValuesTableFilterRoles() {
            var self = this;
            self.fromRolesFilter([]);
            self.toRolesFilter([]);
            var bmRoles = self.model.get('bmRole');
            bmRoles.each(function (bmrole) {
                var propostions = bmrole.get('providedProposition');
                propostions.each(function (proposition) {
                    if (self.model.get('bmValueProposition').findWhere({ 'valueProposition': proposition }) || self.model.get('bmValueFormula').findWhere({ 'valueFormula': proposition })) {
                        var components = proposition.get('component');
                        components.each(function (valComp) {
                            var compOwner = valComp.get('componentOwner');
                            var providerParticipant = self.model.getRoleParticipant(compOwner.get('provider'));
                            var providerParticipantName = providerParticipant ? providerParticipant.get('name') : '';
                            var providerType = "";
                            if (providerParticipantName !== '') {
                                providerType = self.fillRolepath(compOwner.get('provider'));
                            }
                            var providerRolenspath = self.getRoleDispName(compOwner.get('provider'));
                            var recieverType = "";
                            var recieverParticipantName = "";
                            var recieverRoleName = "";
                            var recieverRolenspath = "";
                            if (compOwner.get('recipient')) {
                                recieverRoleName = compOwner.get('recipient').get('name');
                                var recieverParticipant = self.model.getRoleParticipant(compOwner.get('recipient'));
                                recieverParticipantName = recieverParticipant ? recieverParticipant.get('name') : '';
                                if (recieverParticipantName !== '') {
                                    recieverType = self.fillRolepath(compOwner.get('recipient'));
                                }
                                recieverRolenspath = self.getRoleDispName(compOwner.get('recipient'));
                            }
                            self.fromRolesFilter.push({ id: compOwner.get('provider').get('id'), name: compOwner.get('provider').get('name'), providerParticipantName: providerParticipantName, providerType: providerType, providerRolenspath: providerRolenspath });
                            if (recieverRoleName !== "") {
                                self.toRolesFilter.push({ id: compOwner.get('recipient').get('id'), name: compOwner.get('recipient').get('name'), recieverParticipantName: recieverParticipantName, recieverType: recieverType, recieverRolenspath: recieverRolenspath });
                            }
                        });
                    }
                });
            });
            var bmActivities = self.model.get('bmActivity');
            bmActivities.each(function (bmActivity) {
                var actValues = bmActivity.get('activityValue');
                actValues.each(function (actValue) {
                    var providerName = actValue.get('valueAddOwner').get('containedPortOwner').get('performingRole').get('name');
                    var providerId = actValue.get('valueAddOwner').get('containedPortOwner').get('performingRole').get('id');
                    var providerParticipant = self.model.getRoleParticipant(actValue.get('valueAddOwner').get('containedPortOwner').get('performingRole'));
                    var providerParticipantName = providerParticipant ? providerParticipant.get('name') : '';
                    var providerType = "";
                    if (providerParticipantName !== '') {
                        providerType = self.fillRolepath(actValue.get('valueAddOwner').get('containedPortOwner').get('performingRole'));
                    }
                    var providerRolenspath = self.getRoleDispName(actValue.get('valueAddOwner').get('containedPortOwner').get('performingRole'));
                    self.fromRolesFilter.push({ id: providerId, name: providerName, providerParticipantName: providerParticipantName, providerType: providerType, providerRolenspath: providerRolenspath });
                });
            });
            var uniqueFromRoles = self.getUniqueFilterArrayData(self.fromRolesFilter());
            self.sortCollection(uniqueFromRoles);
            self.fromRolesFilter(uniqueFromRoles);
            var uniqueToRoles = self.getUniqueFilterArrayData(self.toRolesFilter());
            self.sortCollection(uniqueToRoles);
            self.toRolesFilter(uniqueToRoles);
            for (var i = 0; i < self.fromRolesFilter().length; i++) {
                self.selectedRoleFilter.push(self.fromRolesFilter()[i].id);
            }
            for (var i = 0; i < self.toRolesFilter().length; i++) {
                self.selectedRecieverRoleFilter.push(self.toRolesFilter()[i].id);
            }
            self.checkAllRoles(true);
            self.checkAllRecieverRoles(true);
            self.fillValuesTab();
            self.fromRolesFilter.sort(function (left, right) {
                return (left.providerParticipantName + "" + left.name).toLowerCase() == (right.providerParticipantName + "" + right.name).toLowerCase() ? 0 : ((left.providerParticipantName + "" + left.name).toLowerCase() < (right.providerParticipantName + "" + right.name).toLowerCase() ? -1 : 1);
            });
            self.toRolesFilter.sort(function (left, right) {
                return (left.recieverParticipantName + "" + left.name).toLowerCase() == (right.recieverParticipantName + "" + right.name).toLowerCase() ? 0 : ((left.recieverParticipantName + "" + left.name).toLowerCase() < (right.recieverParticipantName + "" + right.name).toLowerCase() ? -1 : 1);
            });
        }
        enableOverallSatisfaction(type) {
            var self = this;
            var overAllSatisfactionExists = false;
            for (var i = 0; i < self.children.length; i++) {
                if (self.children[i].type === type && self.children[i].view && self.children[i].view.checkOVS()) {
                    overAllSatisfactionExists = self.children[i].view.id() ? self.children[i].view.id() : true;
                    break;
                }
            }
            return overAllSatisfactionExists;
        }

        addValuePropositionClick() {
            var self = this;
            if (self.selectedProviderRole()) {
                var id = self.htmlEscape(window.guidGenerator()) + "valueProposition";
                var overAllSatisfaction = self.selectedValueProposition() ? self.selectedValueProposition().get('overAllSatisfaction') : null;
                var scenario = self.selectedValueProposition() ? self.currentAlternativeModel.getMainScenario(self.selectedValueProposition().getNestedParent()) : self.mainScenario();
                var tempValuesAgg = self.addTempValuesForAggregation(self.vpName(), 'valueProposition', 'Value Proposition');
                var collaboration = self.model.get('bmRole').get(self.selectedProviderRole()).getParent();
				var aggregatedColl = self.getAggregatedColl();
                var addOptions = { 'type': 'valueProposition', 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'overAllSatisfaction': overAllSatisfaction, 'valuesColl': self.valuePropositionValuesColl(), 'enableOverallSatisfaction': self.enableOverallSatisfaction('valueProposition'), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
                window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
            }
        };


        loadBMValuePropositionHandler(view, event) {
            var self = this;
            self.checkOneCCArea('ValueProposition', function (load) {
                if (load) {
                    self.cancelValueProposition();
                    var bmVP;
                    self.model.get('bmValueProposition').each(function (element) {
                        if (element.get('valueProposition').get('id') === view.id) {
                            self.bmValueProposition(element);
                            bmVP = element;
                        }
                    })
                    if (bmVP) {
                        var vp = bmVP.get('valueProposition');
                        self.selectedValueProposition(vp);
                        $('#vpName').val(vp.get('name'));
                        self.vpName(vp.get('name'));
                        var providerRole = vp.get('provider');
                        var provider = self.model.getRoleParticipant(providerRole);
                        if (provider) {
                            self.selectedProvider(provider.get('id'));
                        }
                        self.selectedProviderRole(providerRole.get('id'));
                        self.enableVPProvider(false);
                        if (vp.get('recipient')) {
                            var receiverRole = self.model.getRoleParticipant(vp.get('recipient'));
                            if (receiverRole) {
                                self.selectedReceiver(receiverRole.get('id'));
                            } else {
                                self.selectedReceiver(null);
                            }
                            self.selectedReceiverRole(vp.get('recipient').get('id'));
                        } else {
                            self.selectedReceiver(null);
                            self.selectedReceiverRole(null);
                        }
                        var valComps = vp.get('component');
                        var valuesName = [];

                        for (var i = 0; i < valComps.length; i++) {
                            var valueName = '', valAndUnitObj;
                            var valComp = valComps.at(i);
                            //if (valComp.get('valueMeasurement')) {
                                valAndUnitObj = self.getMeasurementValueandUnit(valComp, valComp.get('valueMeasurement'));
                            //}
                            var valCompName = valComp.get('name');
                            var scenario = self.currentAlternativeModel.getMainScenario(valComp.getNestedParent());
                            if (valAndUnitObj['value']) {
                                valueName = valCompName + " " + window.utils.thousandSeparator(valAndUnitObj['value']) + " " + valAndUnitObj['unit'];
                            } else {
                                var satisfaction = scenario.getTSLessMeasurementReferringToMeasuredCharteristic(valComp.get('satisfactionLevel'));
                                var compSatisfactionMeasure = satisfaction ? satisfaction.get('observedMeasure').get('measure') : null;
                                if (compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('measureRelationships').length > 0) {
                                    valueName = valueName.concat(valCompName + " " + satisfaction.get('symbol'));
                                }
                                else {
                                    valueName = valueName.concat(valCompName);
                                }
                            }
                            var selectedInterval = valComp.getSatisfactionInterval(scenario);
                            var smileySrc = '';
                            var smileyColor = '';
                            var correctiveActions = '';
                            if (selectedInterval) {
                                smileySrc = self.fetchSmileySrc(selectedInterval.get('smiley'));
                                smileyColor = selectedInterval.get('smileyColor');
                                correctiveActions = selectedInterval.get('correctiveActions');
                            }
                            valuesName.push({ id: valComp.get('id'), name: valCompName, displayName: valueName, modalId: self.htmlEscape(valComp.get('id')), isCollective: valAndUnitObj['isCollective'], smileySrc: smileySrc, smileyColor: smileyColor, correctiveActions: correctiveActions });
                        }
                        self.valuePropositionValuesColl(valuesName);
                        self.disallowedNames = self.currentAlternativeModel.getDisallowedNames(vp, 'providedProposition');
                    } else if (view.id) {
                        $('#vpName').val(view.bmVPName);
                        self.vpName(view.bmVPName);
                        self.selectedValueProposition(view.vpModel);
                        self.selectedProvider(view.providerID);
                        self.selectedProviderRole(view.providerRoleID);
                        if (view.recipientID) {
                            self.selectedReceiver(view.recipientID)
                            self.selectedReceiverRole(view.recipientRoleID);
                        }
                        if (view.values) {
                            self.valuePropositionValuesColl(view.values);
                        }
                    } else {
                        self.selectedValueProposition(null);
                        self.selectedReceiver(null);
                        self.selectedProvider(null);
                        self.selectedProviderRole(null);
                        self.selectedReceiverRole(null);
                    }
                   
                    self.enableVPDelete(true);
                    self.sortCollection(self.valuePropositionValuesColl);
                    self.enableVPCancel(true);
                    /*if(self.canAddValueProp()){
                        self.canClickCompleteValueProposition(true);	
                    }*/
                    if (event) {
                        self.selectedVPRow = event.currentTarget;
                        $(event.currentTarget).addClass(' highlightrow').siblings().removeClass('highlightrow');
                    }
                    self.setResizeHeight(false);
                }
            });
        };
        resetVPData() {
            var self = this;
            self.bmVPListArray.splice(0, self.bmVPListArray.length);
            self.bmVPListCollection([]);
            self.fillVPFilterRoles();
            //fillBMVPList();
            self.providersArray.splice(0, self.providersArray.length);
            self.fillProviders();
            self.receiversArray.splice(0, self.receiversArray.length);
            self.fillReceivers();
        };
        cancelValueProposition() {
            var self = this;
            self.enableVPProvider(true);
            self.selectedValueProposition(null);
            self.selectedReceiver(null);
            self.receiverRoles([]);
            self.selectedProvider(null);
            self.providerRoles([]);
            self.selectedProviderRole(null);
            self.selectedReceiverRole(null);
            self.vpName(null);
            self.valuePropositionValuesColl([]);
            $('#vpName').val(null);
            self.clearChildren("valueProposition");
            // $('#newModal').children().remove();
            self.children = [];
            self.bmValueProposition(null);
            if (self.selectedVPRow) {
                $(self.selectedVPRow).removeClass('highlightrow');
            }
            self.selectedVPRow = null;
            self.enableVPDelete(false);
            self.enableVPCancel(false);
            self.enableVPErrorMessage(false);
            self.valuePropositionChanges = false;
            self.disallowedNames = [];
        };
        canDeleteValueProposition() {
            return this.bmValueProposition() != null;
        };
        deleteValuePropositionValues(valueColl, vp, structuralChange) {
            var self = this;
            var deleteValuesList = [];
            for (var i = 0; i < valueColl.length; i++) {
                if (valueColl[i]._destroy && valueColl[i].id != '') {
                    deleteValuesList.push(valueColl[i].id);
                }
            }
            /*var useScenario = self.mainScenario();
            if (window.checkContextForRevision() && structuralChange) {
                var oldAltIdReg = new RegExp(DataManager.getDataManager().get('currentWorkspace').get('id'), 'g');
                useScenario = self.currentAlternativeModel.getMainScenario(self.model.getNestedParent());
            }*/
            if (deleteValuesList.length > 0) {
                vp.deleteValue(deleteValuesList);
            }
        };

        deleteValueProposition() {
            var self = this;
			var bmValueProps = self.model.get('bmValueProposition');
            var bmVP = bmValueProps.findWhere({ 'valueProposition': self.selectedValueProposition() });
            var components = self.selectedValueProposition().get('component');
            var bmValuestream = self.model.get('bmValueStream').findWhere({ 'persuedProposition': self.selectedValueProposition() });
            var msg = '';
            if (bmValuestream || components.length > 0) {
                msg = msg.concat('' + DataManager.getDataManager().get('localeManager').get('DeleteValueProposition'));
            }
            function doDeleteValueProposition() {
                window.utils.startSpinner('delValuePropositionSpinner', "Deleting Value Proposition");
                window.setTimeout(function () {
                    self.model.get('bmValueProposition').remove(bmVP);
                    window.setTimeout(function () {
                        DataManager.getDataManager().invokeValueUpdates(function(){
                            self.resetTables(function () {
                                self.refreshWidgetData(true);
                                self.setResizeHeight(false);
                                window.utils.stopSpinner('delValuePropositionSpinner');
                            });
                        }, false);
                    }, 10);
                }, 100);
            }
            var providerRole = self.selectedValueProposition().get('provider');
            var aggTo = [];
            for (var i = 0; i < components.length; i++) {
                aggTo.push.apply(aggTo, components.at(i).get('aggregatedTo').models.concat());
            }
            if (aggTo.length > 0) {
                var removeAggList = [];
                providerRole.getBusinessModelsInvolved(function (businessModels) {
                    var vpExistsAcross = false;
                    for (var k = 0; k < aggTo.length; k++) {
                        var valueExists = false;
                        if (aggTo[k].get('type') === "vdml_ValuePropositionComponent") {
                            var aggVp = aggTo[k].get('componentOwner');
                        } else if (aggTo[k].get('type') === "vdml_ValueAdd") {
                            aggVp = aggTo[k].get('valueAddOwner').get('containedPortOwner');
                        }
                        if (aggVp && aggVp != self.selectedValueProposition()) {
                            if (aggTo[k].getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
                                valueExists = true;
                                continue;
                            }
                            for (var j = 0; j < businessModels.length; j++) {
                                if (self.model !== businessModels.at(j)) {
                                    var bmValueProp = businessModels.at(j).get('bmValueProposition');
                                    if (!vpExistsAcross && bmValueProp.findWhere({ 'valueProposition': self.selectedValueProposition() })) {
                                        vpExistsAcross = true;
                                    }
                                    var bmMyValueProp = businessModels.at(j).get('bmValueFormula');
                                    if (aggVp.get('isValueFormula') && bmMyValueProp.findWhere({ 'valueFormula': aggVp })) {
                                        valueExists = true;
                                    }
                                    if (!aggVp.get('isValueFormula') && bmValueProp.findWhere({ 'valueProposition': aggVp })) {
                                        valueExists = true;
                                    }
                                    var activity = businessModels.at(j).get('bmActivity');
                                    if (activity.findWhere({ 'activity': aggVp })) {
                                        valueExists = true;
                                    }
                                    if (valueExists) {
                                        break;
                                    }
                                }
                            }
                            if (!valueExists && vpExistsAcross) {
                                removeAggList.push(aggTo[k]);
                            }
                        }
                    }
                    if (removeAggList.length > 0) {
                        var message = '';
                        //message = message.concat(''+msg+'<br/>'+DataManager.getDataManager().get('localeManager').get('DeleteValueChange'));
                        bootbox.confirm('' + msg, function (result) {
                            if (result) {
                                window.utils.startSpinner('delValuePropositionSpinner', "Deleting Value Proposition");
                                var agg = [];
                                for (var i = 0; i < removeAggList.length; i++) {
                                    for (var j = 0; j < components.length; j++) {
                                        var compExists = components.at(j).get('aggregatedTo').findWhere({ 'id': removeAggList[i].get('id') });
                                        if (compExists) {
                                            var found = false;
                                            for (var k = 0; k < agg.length; k++) {
                                                if (agg[k].removeagg === removeAggList[i]) {
                                                    agg[k].sourceagg.push(components.at(j));
                                                    found = true;
                                                    break;
                                                }
                                            }
                                            if (!found) {
                                                agg.push({ 'sourceagg': [components.at(j)], 'removeagg': removeAggList[i] });
                                            }
                                        }
                                    }
                                }
                                async.each(agg, function (comp, saveCallback) {
                                    var removeAggComp = comp.removeagg;
                                    var sourceAggArray = comp.sourceagg;
                                    var scenario = self.currentAlternativeModel.getMainScenario(removeAggComp.getNestedParent());
                                    removeAggComp.reCalculateValues(sourceAggArray, scenario, function (changeInValue) { saveCallback(); });
                                }, function (err) {
                                    self.model.get('bmValueProposition').remove(bmVP);
                                    self.resetTables(function () {
                                        self.refreshWidgetData(true);
                                        window.utils.stopSpinner('delValuePropositionSpinner');
                                    });
                                });

                            } else {
                                return;
                            }
                        });
                    } else {
                        if (msg.length > 0) {
                            bootbox.confirm('' + msg, function (result) {
                                if (result) {
										if(window.checkContextForRevision()){
												self.createRevisionBasedOnContext(true,function(revision){
														var bmValueProps = self.model.get('bmValueProposition');
                                                        var bmVP = bmValueProps.findWhere({ 'valueProposition': self.selectedValueProposition() });
														self.model.get('bmValueProposition').remove(bmVP);
														self.disableAllCompleteButtons();
														DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
												})
										}else{
									       doDeleteValueProposition();
								        }
								}
                            });
                        } else {
                             if(window.checkContextForRevision()){
								self.createRevisionBasedOnContext(true,function(revision){
										var bmValueProps = self.model.get('bmValueProposition');
                                        var bmVP = bmValueProps.findWhere({ 'valueProposition': self.selectedValueProposition() });
										self.model.get('bmValueProposition').remove(bmVP);
										self.disableAllCompleteButtons();
										DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
								})
							 }else{
								doDeleteValueProposition();
							 }
						}
                    }
                });
            } else {
			    if(window.checkContextForRevision()){
					self.createRevisionBasedOnContext(true,function(revision){
							var bmValueProps = self.model.get('bmValueProposition');
                            var bmVP = bmValueProps.findWhere({ 'valueProposition': self.selectedValueProposition() });
							self.model.get('bmValueProposition').remove(bmVP);
							self.disableAllCompleteButtons();
							DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
					})
				}else{
					doDeleteValueProposition();
				}
            }
        };
		fillBusinessRoles() {
            var self = this;
            if (self.model.get('business')) {
                var roles = self.model.getParticipantRoles(self.model.get('business').get('id'), self.assignmentsColl);
                self.businessRolesArray.splice(0, self.businessRolesArray.length);
                _.each(roles, function (role, index, list) {
                    var displayRolename = role.get('name');
                    displayRolename = self.checkDuplicatesPath(roles, role, displayRolename);
                    self.businessRoles.push({ id: role.get('id'), businessRoleName: role.get('name'), businessRoleDescription: role.get('description'), displayRolename: displayRolename });
                });
            }
            self.businessRoles.sort(self.sortRoles);
        };

        addTempValuesForAggregation(sourceName, type, sourceType, contributeWhatValues) {
            var self = this;
            self.tempValuesForAggregation = [];
            for (var i = 0; i < self.children.length; i++) {
                if (self.children[i].type == type && self.children[i].view && !self.children[i].view.model && !self.children[i].view.satisfactionOnly() && (self.children[i].view.checkOVS && !self.children[i].view.checkOVS())) {
                    var originalId = "";
                    if (type === 'createWhatValues') {
                        if (self.children[i].view.existingValueModal && !contributeWhatValues) {
                            continue;
                        }
                        for (var j = 0; j < self.activityCollection().length; j++) {
                            if (self.children[i].view.selectedActivity() === self.activityCollection()[j].modalId) {
                                sourceName = '' + self.activityCollection()[j].name;
                                if (self.children[i].view.existingValueModal) {
                                    originalId = self.children[i].view.existingValueModal.get('id');
                                }
                                break;
                            }
                        }
                    }
                    var tempValue = self.children[i].view.measurementValue();
                    if (self.children[i].view.tempMeasurementRoundingValue) {
                        tempValue = self.children[i].view.tempMeasurementRoundingValue;
                    }
                    self.tempValuesForAggregation.push({ id: self.children[i].view.encodeId, name: self.children[i].view.name(), value: '' + tempValue, unit: '' + self.children[i].view.measurementUnit(), source: sourceName, prime: self.children[i].view.checkPrime(), sourceType: sourceType, satisfactionType: self.children[i].view.satisfactionType(), originalId: originalId, tempValuesAggregated: self.children[i].view.tempValuesAggregated });
                }
            }
            return self.tempValuesForAggregation;
        }
		
        addValueFormulaClick() {
            var self = this;
            if (self.selectedBusinessRole()) {
                var id = self.htmlEscape(window.guidGenerator()) + "valueFormula";
                var tempValuesAgg = self.addTempValuesForAggregation(self.vfName(), 'valueFormula', 'My Proposition');
                var overAllSatisfaction = self.selectedValueFormula() ? self.selectedValueFormula().get('overAllSatisfaction') : null;
                var scenario = self.selectedValueFormula() ? self.currentAlternativeModel.getMainScenario(self.selectedValueFormula().getNestedParent()) : self.mainScenario();
                var collaboration = self.model.get('bmRole').get(self.selectedBusinessRole()).getParent();
				var aggregatedColl = self.getAggregatedColl();
                var addOptions = { 'type': 'valueFormula', 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'overAllSatisfaction': overAllSatisfaction, 'valuesColl': self.valueFormulaValuesColl(), 'enableOverallSatisfaction': self.enableOverallSatisfaction('valueFormula'), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
                window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
            }
        };

        editValueFormulaValuesClickHandler(view, event) {
            var self = this;
            var id = view.modalId;
            var modalEle = self.selectedValueFormula() ? self.selectedValueFormula().get('component').findWhere({ 'id': view.id }) : null;
            var tempValuesAgg = self.addTempValuesForAggregation(self.vfName(), 'valueFormula', 'My Proposition');
            if ($.find("#modal" + id).length > 0) {
                self.updateView('valueFormula', id, tempValuesAgg);
                $("#modal" + id).modal('show');
            }
            else {
                var overAllSatisfaction = self.selectedValueFormula() ? self.selectedValueFormula().get('overAllSatisfaction') : null;
                var scenario = self.selectedValueFormula() ? self.currentAlternativeModel.getMainScenario(self.selectedValueFormula().getNestedParent()) : self.mainScenario();
                var collaboration = self.model.get('bmRole').get(self.selectedBusinessRole()).getParent();
				var aggregatedColl = self.getAggregatedColl();
                var addOptions = { 'type': 'valueFormula', 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'overAllSatisfaction': overAllSatisfaction, 'valuesColl': self.valueFormulaValuesColl(), 'enableOverallSatisfaction': self.enableOverallSatisfaction('valueFormula'), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
                window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), modalEle, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
            }
        };
        saveValueFormula(view, event, callback) {
            var self = this;
            var structuralChange = self.checkforStructuralChange('valueFormula');
            self.createRevisionBasedOnContext(structuralChange, function () {
                window.utils.startSpinner('saveMyPropostion', "Saving My Proposition...");
                window.setTimeout(saveMyPropostion, 100);
            });
            function saveMyPropostion() {
                var businessRole = self.model.get('bmRole').get(self.selectedBusinessRole());
                if (window.checkContextForRevision() && structuralChange) {
                    var oldAltIdReg = new RegExp(DataManager.getDataManager().get('currentWorkspace').get('id'), 'g');
                    var newbusinessRoleId = self.selectedBusinessRole().replace(oldAltIdReg, DataManager.getDataManager().get('viewAlternative'));
                    businessRole = self.model.get('bmRole').get(newbusinessRoleId);
                }

                if (!self.bmValueFormula()) {
                    self.model.addBMValueFormula(self.selectedValueFormula(), self.vfName(), businessRole, null, self.currentAlternativeModel, function (bmVf) {
                        self.bmValueFormula(bmVf);
                        saveValueFormulaValues();
                    });
                } else {
                    self.bmValueFormula().updateBMValueFormula(self.selectedValueFormula(), self.vfName(), businessRole);
                    saveValueFormulaValues();
                }
                function saveValueFormulaValues() {
                    var sortedvalues = self.sortValueAggregation("valueFormula");
                    var valueChange = false;
                    async.each(sortedvalues, function (child, saveCallback) {
                        if (child.type === 'valueFormula') {
                            child.view.saveData(self.model, self.bmValueFormula().get('valueFormula'), function (changeInValue) {
                                if (changeInValue) {
                                    valueChange = true;
                                }
                                saveCallback();
                            }, self.tempValuesForAggregation);
                        } else {
                            saveCallback();
                        }
                    }, function (err) {
                        self.deleteValuePropositionValues(self.valueFormulaValuesColl(), self.selectedValueFormula(), structuralChange);
                        var updateOtherScenarios = false;
                        for (var i = 0; i < self.valueFormulaValuesColl().length; i++) {
                            if (self.valueFormulaValuesColl()[i]._destroy && self.valueFormulaValuesColl()[i].id != '') {
                                valueChange = true;
                                updateOtherScenarios = true;
                            }
                        }
                        DataManager.getDataManager().invokeValueUpdates(handleView, updateOtherScenarios);
                        function handleView() {
                            if (!structuralChange && valueChange && window.checkContextForRevision()) {
                                var context = self.currentAlternativeModel.getMainScenario(self.model.getNestedParent());
                                context.set('localChange', true);
                            }
                            if (window.checkContextForRevision() && structuralChange && !callback) {
                                self.disableAllCompleteButtons();
                                DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                            }
                            else if (callback) {
                                callback();
                            }
                            else {
                                if (self.valueFormulaValuesColl().length > 0 && valueChange) {
                                    self.refreshWidgetData(true);
                                }
                                self.cancelValueFormula();
                                self.cancelValueProposition();
                                self.resetVFData();
                                if (valueChange) {
                                    self.resetVPData();
                                    self.fillValuesTableFilterRoles();
                                    self.resetActivityData();
                                    self.cancelActivity(true);
                                } else {
                                    self.cancelActivity(false);
                                }
                                self.setResizeHeight();
                            }
                            window.utils.stopSpinner('saveMyPropostion');
                        }
                    });
                }
            }
        }
        loadBMValueFormulaHandler(view, event) {
            var self = this;
            self.checkOneCCArea('MyValueProposition', function (load) {
                if (load) {
                    self.cancelValueFormula();
                    var bmVF;
                    self.model.get('bmValueFormula').each(function (element) {
                        if (element.get('valueFormula') && element.get('valueFormula').get('id') === view.id) {
                            self.bmValueFormula(element);
                            bmVF = element;
                        }
                    })
                    if (bmVF) {
                        var vp = bmVF.get('valueFormula');
                        self.selectedValueFormula(vp);
                        $('#vfName').val(vp.get('name'));
                        self.vfName(vp.get('name'));
                        var businessRole = vp.get('provider');
                        self.selectedBusinessRole(businessRole.get('id'));
                        var valComps = vp.get('component');
                        var valuesName = [];
                        for (var i = 0; i < valComps.length; i++) {
                            var valueName = '', valAndUnitObj;
                            var valComp = valComps.at(i);
                            //if (valComp.get('valueMeasurement')) {
                                valAndUnitObj = self.getMeasurementValueandUnit(valComp, valComp.get('valueMeasurement'));
                            //}
                            var valCompName = valComp.get('name');
                            var scenario = self.currentAlternativeModel.getMainScenario(valComp.getNestedParent());
                            if (valAndUnitObj['unit']) {
                                valueName = valCompName + " " + window.utils.thousandSeparator(valAndUnitObj['value']) + " " + valAndUnitObj['unit'];
                            } else {
                                var satisfaction = scenario.getTSLessMeasurementReferringToMeasuredCharteristic(valComp.get('satisfactionLevel'));
                                var compSatisfactionMeasure = satisfaction ? satisfaction.get('observedMeasure').get('measure') : null;
                                if (compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('measureRelationships').length > 0) {
                                    valueName = valueName.concat(valCompName + " " + satisfaction.get('symbol'));
                                }
                                else {
                                    valueName = valueName.concat(valCompName);
                                }
                            }
                            var selectedInterval = valComp.getSatisfactionInterval(scenario);
                            var smileySrc = '';
                            var smileyColor = '';
                            var correctiveActions = '';
                            if (selectedInterval) {
                                smileySrc = self.fetchSmileySrc(selectedInterval.get('smiley'));
                                smileyColor = selectedInterval.get('smileyColor');
                                correctiveActions = selectedInterval.get('correctiveActions');
                            }
                            valuesName.push({ id: valComp.get('id'), name: valCompName, displayName: valueName, modalId: self.htmlEscape(valComp.get('id')), isCollective: valAndUnitObj['isCollective'], smileySrc: smileySrc, smileyColor: smileyColor, correctiveActions: correctiveActions });
                        }
                        self.valueFormulaValuesColl(valuesName);
                        self.disallowedNames = self.currentAlternativeModel.getDisallowedNames(vp, 'providedProposition');
                    } else {
                        self.selectedValueFormula(null);
                        self.selectedBusinessRole(null);
                    }
                    self.sortCollection(self.valueFormulaValuesColl);
                    if (event) {
                        self.selectedVFRow = event.currentTarget;
                        $(event.currentTarget).addClass('highlightrow').siblings().removeClass('highlightrow');
                    }
                    
                    self.enableVFDelete(true);
                    self.enableVFCancel(true);
                    self.canClickCompleteValueFormula(true);
                    self.setResizeHeight(false);
                }
            });
        };

        resetVFData() {
            var self = this;
            self.bmVFListArray.splice(0, self.bmVFListArray.length);
            self.bmVFListCollection([]);
            self.fillBMVFList();
            self.fillBusinessRoles();
        };
        cancelValueFormula() {
            var self = this;
            self.selectedValueFormula(null);
            self.selectedBusinessRole(null);
            $('#vfName').val(null);
            self.bmValueFormula(null);
            self.vfName(null);
            self.clearChildren("valueFormula");
            self.children = [];
            //$('#newModal').children().remove();
            self.valueFormulaValuesColl([]);
            if (self.selectedVFRow) {
                $(self.selectedVFRow).removeClass('highlightrow');
            }
            self.selectedVFRow = null;
            self.enableVFDelete(false);
            self.enableVFCancel(false);
            self.enableVFErrorMessage(false);
            self.myValuePropositionChanges = false;
            self.disallowedNames = [];
        };
        canDeleteValueFormula() {
            return this.bmValueFormula() != null;
        }
        deleteValueFormula() {
            var self = this;
            var bmValueFormulas = self.model.get('bmValueFormula');
            var bmVF = bmValueFormulas.findWhere({ 'valueFormula': self.selectedValueFormula() });
            var components = self.selectedValueFormula().get('component');
            var bmValuestream = self.model.get('bmValueStream').findWhere({ 'persuedProposition': self.selectedValueFormula() });
            var msg = '';
            if (bmValuestream || components.length > 0) {
                msg = msg.concat('' + DataManager.getDataManager().get('localeManager').get('DeleteMyProposition'));
            }
            function doDeleteValueFormula() {
                window.utils.startSpinner('delValueFormulaSpinner', "Deleting My Proposition");
                window.setTimeout(function () {
                    self.model.get('bmValueFormula').remove(bmVF);
                    window.setTimeout(function () {
                        DataManager.getDataManager().invokeValueUpdates(function(){    
                            self.resetTables(function () {
                                self.refreshWidgetData(true);
                                self.setResizeHeight(false);
                                window.utils.stopSpinner('delValueFormulaSpinner');
                            });
                        },false);
                    }, 10);
                }, 100);
            }
			var providerRole = self.selectedValueFormula().get('provider');
            var aggTo = [];
            for (var i = 0; i < components.length; i++) {
                aggTo.push.apply(aggTo, components.at(i).get('aggregatedTo').models.concat());
            }
            if (aggTo.length > 0) {
                var removeAggList = [];
                providerRole.getBusinessModelsInvolved(function (businessModels) {
                    var vfExistsAcross = false;
                    for (var k = 0; k < aggTo.length; k++) {
                        var valueExists = false;
                        var aggVp = aggTo[k].get('componentOwner');
                        if (aggVp && aggVp != self.selectedValueFormula()) {
                            if (aggTo[k].getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
                                valueExists = true;
                                continue;
                            }
                            for (var j = 0; j < businessModels.length; j++) {
                                if (self.model !== businessModels.at(j)) {
                                    var bmValueProp = businessModels.at(j).get('bmValueProposition');
                                    var bmMyValueProp = businessModels.at(j).get('bmValueFormula');
                                    if (!vfExistsAcross && bmMyValueProp.findWhere({ 'valueFormula': self.selectedValueFormula() })) {
                                        vfExistsAcross = true;
                                    }
                                    if (aggVp.get('isValueFormula') && bmMyValueProp.findWhere({ 'valueFormula': aggVp })) {
                                        valueExists = true;
                                    }
                                    if (!aggVp.get('isValueFormula') && bmValueProp.findWhere({ 'valueProposition': aggVp })) {
                                        valueExists = true;
                                    }
                                    if (valueExists) {
                                        break;
                                    }
                                }
                            }
                            if (!valueExists && vfExistsAcross) {
                                removeAggList.push(aggTo[k]);
                            }
                        }
                    }
                    if (removeAggList.length > 0) {
                        var message = '';
                        //message = message.concat(''+msg+'<br/>'+DataManager.getDataManager().get('localeManager').get('DeleteValueChange'));
                        bootbox.confirm('' + msg, function (result) {
                            if (result) {
                                window.utils.startSpinner('delValueFormulaSpinner', "Deleting My Proposition");
                                var agg = [];
                                for (var i = 0; i < removeAggList.length; i++) {
                                    for (var j = 0; j < components.length; j++) {
                                        var compExists = components.at(j).get('aggregatedTo').findWhere({ 'id': removeAggList[i].get('id') });
                                        if (compExists) {
                                            var found = false;
                                            for (var k = 0; k < agg.length; k++) {
                                                if (agg[k].removeagg === removeAggList[i]) {
                                                    agg[k].sourceagg.push(components.at(j));
                                                    found = true;
                                                    break;
                                                }
                                            }
                                            if (!found) {
                                                agg.push({ 'sourceagg': [components.at(j)], 'removeagg': removeAggList[i] });
                                            }
                                        }
                                    }
                                }
                                async.each(agg, function (comp, saveCallback) {
                                    var removeAggComp = comp.removeagg;
                                    var sourceAggArray = comp.sourceagg;
                                    var scenario = self.currentAlternativeModel.getMainScenario(removeAggComp.getNestedParent());
                                    removeAggComp.reCalculateValues(sourceAggArray, scenario, function (changeInValue) { saveCallback(); });
                                }, function (err) {
                                    self.model.get('bmValueFormula').remove(bmVF);
                                    self.resetTables(function () {
                                        self.refreshWidgetData(true);
                                        window.utils.stopSpinner('delValueFormulaSpinner');
                                    });
                                });

                            } else {
                                return;
                            }
                        });
                    } else {
                        if (msg.length > 0) {
                            bootbox.confirm('' + msg, function (result) {
                                if (result) {
									if(window.checkContextForRevision()){
										self.createRevisionBasedOnContext(true,function(revision){
												var bmValueFormulas = self.model.get('bmValueFormula');
												var bmVF = bmValueFormulas.findWhere({ 'valueFormula': self.selectedValueFormula() });
												self.model.get('bmValueFormula').remove(bmVF);
												self.disableAllCompleteButtons();
												DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
										})
									}else{
									   doDeleteValueFormula();
									}
								}
                            });
                        } else {
                            if(window.checkContextForRevision()){
										self.createRevisionBasedOnContext(true,function(revision){
												var bmValueFormulas = self.model.get('bmValueFormula');
												var bmVF = bmValueFormulas.findWhere({ 'valueFormula': self.selectedValueFormula() });
												self.model.get('bmValueFormula').remove(bmVF);
												self.disableAllCompleteButtons();
												DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
										})
							}else{
								doDeleteValueFormula();
							}
						}
                    }
                });
            } else {
                if (msg.length > 0) {
                    bootbox.confirm('' + msg, function (result) {
                        if (result) {
						    if(window.checkContextForRevision()){
								self.createRevisionBasedOnContext(true,function(revision){
										var bmValueFormulas = self.model.get('bmValueFormula');
										var bmVF = bmValueFormulas.findWhere({ 'valueFormula': self.selectedValueFormula() });
										self.model.get('bmValueFormula').remove(bmVF);
										self.disableAllCompleteButtons();
										DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
								})
							}else{
							  if(window.checkContextForRevision()){
										self.createRevisionBasedOnContext(true,function(revision){
												var bmValueFormulas = self.model.get('bmValueFormula');
												var bmVF = bmValueFormulas.findWhere({ 'valueFormula': self.selectedValueFormula() });
												self.model.get('bmValueFormula').remove(bmVF);
												self.disableAllCompleteButtons();
												DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
										})
							  }else{
								doDeleteValueFormula();
							  }
							}
						}
                    });
                } else {
				    if(window.checkContextForRevision()){
						self.createRevisionBasedOnContext(true,function(revision){
                                var bmValueFormulas = self.model.get('bmValueFormula');
                                var bmVF = bmValueFormulas.findWhere({ 'valueFormula': self.selectedValueFormula() });
								self.model.get('bmValueFormula').remove(bmVF);
								self.disableAllCompleteButtons();
								DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
						})
					}else{
                       if(window.checkContextForRevision()){
										self.createRevisionBasedOnContext(true,function(revision){
												var bmValueFormulas = self.model.get('bmValueFormula');
												var bmVF = bmValueFormulas.findWhere({ 'valueFormula': self.selectedValueFormula() });
												self.model.get('bmValueFormula').remove(bmVF);
												self.disableAllCompleteButtons();
												DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
										})
					   }else{
						 doDeleteValueFormula();
					   }
				    }
				}
            }

        }
        fillBMPersuedOptions() {
            var self = this;
            var bmVFs = self.model.get('bmValueFormula');
            self.persuedOptions([]);
            bmVFs.each(function (bmVF) {
                var vp = bmVF.get('valueFormula');
                if (vp) {
                    var displayname = vp.get('name');
                    for (var p = 0; p < bmVFs.length; p++) {
                        if ((bmVFs.at(p).get('valueFormula') && bmVFs.at(p).get('valueFormula').id !== vp.id) && (bmVFs.at(p).get('valueFormula').get('name') === vp.get('name'))) {
                            displayname = displayname.concat("   [");
                            for (var k = 0; k < vp.getPackagePath().length - 1; k++) {
                                displayname = displayname.concat(vp.getPackagePath()[k].name);
                                if (vp.getPackagePath()[k + 2]) {
                                    displayname = displayname.concat(" / ");
                                }
                            }
                            displayname = displayname.concat("]");
                            break;
                        }
                    }
                    self.persuedOptions.push({ id: vp.get('id'), persuedName: vp.get('name'), persuedDescription: vp.get('description'), type: 'bmValueFormula', displayName: displayname });
                }
            });
            var bmVPs = self.model.get('bmValueProposition');
            bmVPs.each(function (bmVP) {
                var vp = bmVP.get('valueProposition');
                if (vp) {
                    var displayname = vp.get('name');
                    for (var p = 0; p < bmVPs.length; p++) {
                        if ((bmVPs.at(p).get('valueProposition').id !== vp.id) && (bmVPs.at(p).get('valueProposition').get('name') === vp.get('name'))) {
                            displayname = displayname.concat("   [");
                            for (var k = 0; k < vp.getPackagePath().length - 1; k++) {
                                displayname = displayname.concat(vp.getPackagePath()[k].name);
                                if (vp.getPackagePath()[k + 2]) {
                                    displayname = displayname.concat(" / ");
                                }
                            }
                            displayname = displayname.concat("]");
                            break;
                        }
                    }
                    self.persuedOptions.push({ id: vp.get('id'), persuedName: vp.get('name'), persuedDescription: vp.get('description'), type: 'bmValueProposition', displayName: displayname });
                }
            });
            self.sortParticipantOptions(self.persuedOptions());
        };
        updateActivityCollection(selRole, selPersued) {
            var self = this;
            self.clearChildren("activity");
            self.children = [];
            if (selRole && selPersued) {
                var exists = false;
                for (var k = 0; k < self.bmActivityListCollection().length; k++) {
                    if (self.bmActivityListCollection()[k].id === selPersued && self.bmActivityListCollection()[k].bmActivityRoleId === selRole) {
                        self.activityCollection(self.bmActivityListCollection()[k].bmActivityColl);
                        self.activityCreateWhatValuesColl(self.bmActivityListCollection()[k].bmCreatingValuesColl);
                        self.sortCollection(self.activityCreateWhatValuesColl);
                        exists = true;
                        self.canClickCompleteActivity(true);
                        break;
                    }
                }
                if (!exists) {
                    self.activityCollection([]);
                    self.activityCreateWhatValuesColl([]);
                    self.activityContributeWhatValuesColl([]);
                    self.canClickCompleteActivity(false);
                }
                //sortBMActivityList();
            }
            else {
                self.activityCollection([]);
                self.activityCreateWhatValuesColl([]);
                self.activityContributeWhatValuesColl([]);
                self.canClickCompleteActivity(false);
            }
        };
        fillActivityProviders() {
            var self = this;
            self.activityProviders([]);
            var participants = self.model.getParticipants();
            participants.sort(function (left, right) {
                return left.get('name').toLowerCase() == right.get('name').toLowerCase() ? 0 : (left.get('name').toLowerCase() < right.get('name').toLowerCase() ? -1 : 1)
            });
            _.each(participants, function (participant) {
                var displayName = participant.get('name');
                for (var i = 0; i < self.activityProviders().length; i++) {
                    if (self.activityProviders()[i].providerName === participant.get('name')) {
                        displayName = displayName.concat(self.fetchParticipantNetworkNames(participant));
                        break;
                    }
                }
                self.activityProviders.push({ id: participant.get('id'), providerName: participant.get('name'), providerDescription: participant.get('description'), displayName: displayName });
            });
        }
        fillActivityProviderRoles(providerId) {
            var self = this;
            var roles = self.model.getParticipantRoles(providerId, self.assignmentsColl);
            self.activityProviderRolesArray.splice(0, self.activityProviderRolesArray.length);
            _.each(roles, function (role) {
                var displayRolename = role.get('name');
                displayRolename = displayRolename.concat(self.fillRolepath(role));
                if (roles.length > 1) {
                    displayRolename = self.checkDuplicatesPath(roles, role, displayRolename);
                }
                self.activityProviderRoles.push({ id: role.get('id'), activityProviderRoleName: role.get('name'), activityProviderRoleDescription: role.get('description'), displayRolename: displayRolename });
            });
            self.activityProviderRoles.sort(self.sortRoles);
        }
        checkToEnableActivityValuesAddIcons() {
            var self = this;
            if (self.selectedPersued() && self.selectedActivityProvider() && self.selectedActivityProviderRole()) {
                var activityAddIcon = document.querySelector("#createActivity");
                activityAddIcon.style.cursor = "pointer";
                var str = "default";
                if (self.activityCollection().length > 0) {
                    str = "pointer";
                }
                var createWhatValuesAddIcon = document.querySelector("#createWhatValues");
                createWhatValuesAddIcon.style.cursor = str;
                var contributingWhatValuesAddIcon = document.querySelector("#contributingWhatValues");
                contributingWhatValuesAddIcon.style.cursor = str;
            }
            else {
                var activityAddIcon = document.querySelector("#createActivity");
                activityAddIcon.style.cursor = "default";
                var createWhatValuesAddIcon = document.querySelector("#createWhatValues");
                createWhatValuesAddIcon.style.cursor = "default";
                var contributingWhatValuesAddIcon = document.querySelector("#contributingWhatValues");
                contributingWhatValuesAddIcon.style.cursor = "default";
            }
        }
        fetchAllActivities() {
            var self = this;
            var allActivities = [];
            var pnList = self.model.get('participantNetwork');
            for (var j = 0; j < pnList.length; j++) {
                var coll = pnList.at(j).get('collaboration');
                var activities = coll.get('activity');
                for (var k = 0; k < activities.models.length; k++) {
                    allActivities.push(activities.models[k]);
                }
            }
            return allActivities;
        }

        fillActivityTypeAhead(actColl) {
            var self = this;
            var activitiesTypeaheadColl = self.fetchAllActivities();
            for (var i = 0; i < actColl.length; i++) {
                var actModel = actColl[i].originalId ? self.fetchActivityModal(actColl[i].originalId) : '';
                if (actModel) {
                    var arraylocation = _.indexOf(activitiesTypeaheadColl, actModel);
                    if (arraylocation != -1) {
                        activitiesTypeaheadColl.splice(arraylocation, 1);
                    }
                }
            }
            for (var m = 0; m < self.children.length; m++) {
                if (self.children[m].type == 'activity' && (self.children[m].view && self.children[m].view.selectedactivity)) {
                    activitiesTypeaheadColl.splice(activitiesTypeaheadColl.indexOf(self.children[m].view.selectedactivity), 1);
                }
            }
            return activitiesTypeaheadColl;
        }
        fetchActivityModal(activityId) {
            var self = this;
            var activityModal;
            var activities = self.model.get("bmActivity");
			for (var i = 0; i < activities.models.length; i++) {
				var act = activities.models[i].get('activity');
				if (act && act.get('id') === activityId) {
                    activityModal = activities.models[i].get('activity');
                    break;
                }
            }
            return activityModal;
        }
        fetchValueProposition(persuedList, id) {
            var self = this;
            var selectedPersued;
            var persuedjson;
            for (var k = 0; k < persuedList.length; k++) {
                if (persuedList[k].id === id) {
                    persuedjson = persuedList[k];
                    break;
                }
            }
            if (persuedjson.type === 'bmValueFormula') {
                var bmVFs = self.model.get('bmValueFormula');
                bmVFs.each(function (bmVF) {
                    var valueFormulas = bmVF.get('valueFormula');
                    if (valueFormulas && valueFormulas.get("id") === persuedjson.id) {
                        selectedPersued = valueFormulas;
                    }
                });
            }
            else {
                var bmVPs = self.model.get('bmValueProposition');
                bmVPs.each(function (bmVP) {
                    var valueProposition = bmVP.get('valueProposition');
                    if (valueProposition && valueProposition.get("id") === persuedjson.id) {
                        selectedPersued = valueProposition;
                    }
                });
            };
            return selectedPersued;
        }
        filterActivityAggregatedToValuesList(valueComponent, actAggToValues) {
            var self = this;
            var vpValues = new Backbone.Collection();
            vpValues.add(valueComponent.get('component').models);
            for (var x = 0; x < actAggToValues.length; x++) {
                var valExists = valueComponent.get('component').findWhere({ 'id': actAggToValues[x].originalId });
                if (valExists) {
                    vpValues.remove(valExists);
                }
            }
            return vpValues;
        }
        deleteActivity(activityCollection, valueStream) {
            var self = this;
            var activityReferrenceArray = [];
            var bmActivities = self.model.get('bmActivity');
            for (var i = 0; i < activityCollection.length; i++) {
                if (activityCollection[i]._destroy && activityCollection[i].originalId != "") {
					for (var j = 0; j < bmActivities.length; j++) {
						var act = bmActivities.at(j).get('activity');
						if (act && act.get('id') === activityCollection[i].originalId) {
                            activityReferrenceArray.push(bmActivities.models[j]);
                            break;
                        }
                    }
                }
            }
            var valueStreams = self.model.get('bmValueStream');
            for (var j = 0; j < activityReferrenceArray.length; j++) {
                var bmActivityused = false;
                for (var i = 0; i < valueStreams.length; i++) {
                    if (valueStreams.at(i) !== valueStream && valueStreams.at(i).get('valueStreamActivity').findWhere({ 'id': activityReferrenceArray[j].get('activity').get('id') })) {
                        //activityValueDeletion = activityValueDeletion.concat(activityReferrenceArray[j].get('activityValue').models.concat());
                        bmActivityused = true;
                        break;
                    }
                }
                var activityCanvasDelList = activityReferrenceArray[j].get('activityValue').models.concat();
                activityCanvasDelList.push(activityReferrenceArray[j].get('activity'));
                if (!bmActivityused) {
                    var act = activityReferrenceArray[j].get('activity');
                    activityReferrenceArray[j].destroy();
                    valueStream.get('valueStreamActivity').remove(act);

                } else {
                    var actValues = activityReferrenceArray[j].get('activityValue');
                    for (var k = 0; k < actValues.length; k++) {
                        if (valueStream.get('activityValue').findWhere({ 'id': actValues.at(k).get('id') })) {
                            valueStream.get('activityValue').remove(actValues.at(k));
                        }
                    }
                    valueStream.get('valueStreamActivity').remove(activityReferrenceArray[j].get('activity'));
                }

            }
        };
        deleteActivityValues(valueStream, roleId) {
            var self = this;
            var bmActivities = self.model.get('bmActivity').models;
            var values = self.activityCreateWhatValuesColl();
            for (var i = 0; i < bmActivities.length; i++) {
                var components = new Backbone.Collection([]);
                if (bmActivities[i].get('activity')) {
                    bmActivities[i].get('activity').get('containedPort').each(function (port) {
                        if (port.get('type') === "vdml_OutputPort") {
                            port.get('valueAdd').each(function (valueAdd) {
                                if (valueStream.get('activityValue').findWhere({ id: valueAdd.get('id') }) && bmActivities[i].get('activityValue').findWhere({ id: valueAdd.get('id') }) && bmActivities[i].get('activity').get('performingRole').get('id') === roleId) {
                                    components.push(valueAdd);
                                }
                            });
                        }
                    });
                }
                var delValues = [];
                components.each(function (component) {
                    var id = component.get('id');
                    var isDeleted = true;
                    for (var i = 0; i < values.length; i++) {
                        if (values[i].originalId === id || (values[i].existingValue && values[i].existingValue.get('id') === id)) {
                            isDeleted = false;
                        }
                    }
                    if (isDeleted) {
                        delValues.push(component);
                    }

                });
                for (var j = 0; j < delValues.length; j++) {
                    self.model.businessCanvasDeletions([delValues[j]]);
                    valueStream.get('activityValue').remove(delValues[j]);
                }
            }
        };
        saveActivity(view, event, callback) {
            var self = this;
            var structuralChange = self.checkforStructuralChange('activity');
            self.createRevisionBasedOnContext(structuralChange, function () {
                window.utils.startSpinner('saveActivity', "Saving Activity...");
                window.setTimeout(activitySave, 100);
            });
            function activitySave() {
                var activityColl = [];
                var selectedPersuedId = self.selectedPersued();
                var selectedActivityProviderRoleId = self.selectedActivityProviderRole();
                if (window.checkContextForRevision() && structuralChange) {
                    var oldAltIdReg = new RegExp(DataManager.getDataManager().get('currentWorkspace').get('id'), 'g');
                    selectedPersuedId = self.selectedPersued().replace(oldAltIdReg, DataManager.getDataManager().get('viewAlternative'));
                    selectedActivityProviderRoleId = self.selectedActivityProviderRole().replace(oldAltIdReg, DataManager.getDataManager().get('viewAlternative'));
                }
                var selectedPersued = self.fetchValueProposition(self.persuedOptions(), selectedPersuedId);
                var role = self.model.get('bmRole').findWhere({ id: selectedActivityProviderRoleId });
                if (self.selectedValueStreamModel == null) {
                    var persuedPackage = selectedPersued.getNestedParent();
                    var vsId = DataManager.getDataManager().guidGeneratorByOwner(persuedPackage);
                    var ValueStream = Backbone.Relational.store.getObjectByName("vdml.ValueStream");
                    self.selectedValueStreamModel = new ValueStream({ id: vsId, name: selectedPersued.get('name') + " Value Stream", description: selectedPersued.get('name') + " Value Stream", valueStreamOwner: persuedPackage });
                    self.selectedValueStream(self.selectedValueStreamModel.get('id'));
                }
                self.model.get('bmValueStream').add(self.selectedValueStreamModel);
                self.selectedValueStreamModel.set('persuedProposition', selectedPersued);
                async.eachSeries(self.children, function (child, saveChildCallback) {
                    if (child.view) {
                        var type = child.type;
                        if (type === "activity") {
                            child.view.saveData(self.model, role, self.selectedValueStreamModel, self.currentAlternativeModel, function (newActivity) {
                                for (var i = 0; i < self.activityCollection().length; i++) {
                                    if (self.activityCollection()[i].modalId === child.view.encodeId) {
                                        self.activityCollection()[i].originalId = newActivity.get("id");
                                        break;
                                    }
                                }
                                activityColl.push({ name: newActivity.get("name"), originalId: newActivity.get("id") });
                                self.selectedValueStreamModel.get('valueStreamActivity').add(newActivity);
                                saveChildCallback();
                            });
                        }
                        else {
                            saveChildCallback();
                        }
                    }
                }, function (err) {
                    self.deleteActivity(self.activityCollection(), self.selectedValueStreamModel);
                });
                var valueChange = false;
                var sortedvalues = self.sortValueAggregation("createWhatValues");
                async.each(sortedvalues, function (child, saveChildCallback) {
                    if (child.view) {
                        var type = child.type;
                        if (type === "createWhatValues") {
                            child.view.saveWhatValuesData(self.model, self.activityCollection(), self.activityCreateWhatValuesColl(), function (activityValue, changeInValue) {
                                self.selectedValueStreamModel.get('activityValue').add(activityValue);
                                if (changeInValue) {
                                    valueChange = true;
                                }
                                saveChildCallback();
                            }, self.tempValuesForAggregation);
                        }
                    }
                }, function (err) {
                    self.deleteActivityValues(self.selectedValueStreamModel, selectedActivityProviderRoleId);
                    sortedvalues = self.sortValueAggregation("contributeWhatValues");
                    async.each(sortedvalues, function (child, saveChildCallback) {
                        if (child.view) {
                            var type = child.type;
                            if (type === "contributeWhatValues") {
                                child.view.saveData(self.model, selectedPersued, function (changeInValue) {
                                    if (changeInValue) {
                                        valueChange = true;
                                    }
                                    saveChildCallback();
                                }, self.tempValuesForAggregation);
                            }
                        }
                    }, function (err) {
                        DataManager.getDataManager().invokeValueUpdates(handleView);
                        function handleView() {
                            if (!structuralChange && valueChange && window.checkContextForRevision()) {
                                var context = self.currentAlternativeModel.getMainScenario(self.model.getNestedParent());
                                context.set('localChange', true);
                            }
                            if (window.checkContextForRevision() && structuralChange && !callback) {
                                self.disableAllCompleteButtons();
                                DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                            }
                            else if (callback) {
                                callback();
                            }
                            else {
                                if (self.activityCreateWhatValuesColl().length > 0 || valueChange) {
                                    self.refreshWidgetData(true);
									self.resetVFData();
                                    self.fillValuesTableFilterRoles();
                                }
                                self.cancelActivity(true);
                                self.cancelValueProposition();
                                self.cancelValueFormula();
                                self.resetVPData();
                                self.fillBmCompetency();
                                self.setResizeHeight();
                            }
                            window.utils.stopSpinner('saveActivity');
                        }
                    });
                });

            }
        };
        fillBMCompetenciesOptions() {
            var self = this;
            self.competencyActivities([]);
            var bmCMPs = self.model.get('bmActivity');
            for (var i = 0; i < bmCMPs.length; i++) {
                var activity = bmCMPs.at(i).get('activity');
                if (activity) {
                    var role = activity.get('performingRole');
                    if (role && role.get('roleAssignment').length > 0) {
                        var participant = role.get('roleAssignment').at(0).get('participant');
                        var displayName = activity.get('name');
                        var activitynsPath = self.fetchActivityNsPath(activity);
                        displayName = displayName.concat(activitynsPath);
                        //   displayName= self.checkDuplicatesPath(bmCMPs,activity,displayName);
                        if (participant === self.model.get('business')) {
                            self.competencyActivities.push({ id: activity.get('id'), competenciesRoleName: activity.get('name'), competenciesRoleDescription: activity.get('description'), displayName: displayName });
                        }
                    }
                }
            }

            self.competencyActivities.sort(function (left, right) {
                return left.competenciesRoleName.toLowerCase() == right.competenciesRoleName.toLowerCase() ? 0 : (left.competenciesRoleName.toLowerCase() < right.competenciesRoleName.toLowerCase() ? -1 : 1)
            })
        };

        resetActivityData() {
            var self = this;
            self.bmPersuedArray.splice(0, self.bmPersuedArray.length);
            self.fillBMPersuedOptions();
            self.activityProvidersArray.splice(0, self.activityProvidersArray.length);
            self.fillActivityProviders();
            self.fillBMCompetenciesOptions();
        };
        cancelActivity(reload) {
            var self = this;
            self.cancelParticipantNetwork();
            self.selectedPersued(null);
            self.activityCollection([]);
            self.selectedActivityProvider(null);
            self.selectedActivityProviderRole(null);
            self.activityCreateWhatValuesColl([]);
            self.activityContributeWhatValuesColl([]);
            self.canClickCompleteActivity(false);
            self.resetActivityData();
            if (reload) {
                self.bmActivityListCollection([]);
                self.fillBMActivityList();
            }
            if (self.selectedActRow) {
                $(self.selectedActRow).removeClass('highlightrow');
            }
            self.selectedActRow = null;
            self.enableActivityDelete(false);
            self.enableActivityCancel(false);
            self.activityValueChanges = false;
        };
        checkCompetencyCompleteEnableClick() {
            var self = this;
            var count = 0;
            for (var i = 0; i < self.competencyCollection().length; i++) {
                if (self.competencyCollection()[i].del == false && !self.competencyCollection()[i]._destroy && self.selectedCompetencyActivities()) {
                    count = count + 1;
                }
                else if ((!self.competencyCollection()[i].del) && self.competencyCollection()[i].originalId !== "" && self.selectedCompetencyActivities()) {
                    count = count + 1;
                }
            }
            if (count > 0 && self.selectedCompetencyActivities()) {
                self.enableCompetencyComplete(true);
            }
            else {
                self.enableCompetencyComplete(false);
            }
        }
        fillCompetenceTypeAhead() {
            var self = this;
            var competenciesTypeAheads = new Backbone.Collection();
            if (self.model.get('business')) {
                var capabilityOfferCompetencies = self.model.get('business').get('capabilityOffer');
                if (capabilityOfferCompetencies && capabilityOfferCompetencies.models) {
                    for (var i = 0; i < capabilityOfferCompetencies.models.length; i++) {
                        competenciesTypeAheads.add(capabilityOfferCompetencies.at(i));
                        //competenciesTypeAheads.push({id:capabilityOfferCompetencies.at(i).get('id'),name:capabilityOfferCompetencies.at(i).get('name'),model:capabilityOfferCompetencies.at(i),utilized:false});
                    }
                }
                var resourceCompetencies = self.model.get('business').get('ownedStore');
                if (resourceCompetencies && resourceCompetencies.models) {
                    for (var i = 0; i < resourceCompetencies.models.length; i++) {
                        var resource = resourceCompetencies.at(i).get('resource');
                        competenciesTypeAheads.add(resource);
                        //competenciesTypeAheads.push({id:resource.get('id'),name:resource.get('name'),model:resource,utilized:false});
                    }
                }
                var subOrgUnits = self.model.get('business').getSubUnits();
                if (subOrgUnits.length > 0) {
                    for (var i = 0; i < subOrgUnits.length; i++) {
                        competenciesTypeAheads.add(subOrgUnits.models[i]);
                    }
                }
                //if (self.capabilityTypeAhead.length > 0) {
                //    competenciesTypeAheads.add(self.capabilityTypeAhead);
                //}

            }
            return competenciesTypeAheads;
        }
        getNewViewBackboneModel(val, oldAltIdReg) {
            var self = this;
            var docType = val.get('type');
            var pack = docType.substr(0, docType.indexOf('_'));
            docType = pack + '/' + docType;
            var typeLib = DataManager.getDataManager().buildAppNsPath(docType.substr(docType.indexOf('/') + 1).replace(/[\/]/g, '.'), "version" + 1);
            var newId = val.get('id');
            newId = newId.replace(oldAltIdReg, DataManager.getDataManager().get('viewAlternative'));
            var ret = typeLib.find({ id: newId }, { silent: true });
            if (ret) {
                return ret;
            } else {
                return val;
            }
        };
        cancelCompetency() {
            var self = this;
            self.cancelParticipantNetwork();
            self.competencyCollection([]);
            self.selectedCompetencyActivities(null);
            self.competencyActivity = null;
            if (self.selectedBMComptRow) {
                self.selectedBMComptRow.removeClass('highlightrow');
                self.selectedBMComptRow = null;
            }
            self.enableCompDelete(false);
            self.enableCompCancel(false);
            self.comptBusiness(null);
            self.activityProvider(null);
            self.competencyChanges = false;
        };
        fillBmCompetency() {
            var self = this;
            self.bmCompetencyListCollection([]);
            self.bmCompetencyListArray = [];
            var bmComp = self.model.get("bmCompetence");
            function checkActivity(activity, compCoColl) {
                var co = activity.get('appliedCapabilityOffer');
                if (co && bmComp.findWhere({ competence: co })) {
                    var competencePresent = false;
                    _.each(compCoColl, function (comp) {
                        if (comp.originalId === co.get("id")) {
                            competencePresent = true;
                        }
                    })
                    if (!competencePresent) {
                        compCoColl.push({ modalId: self.htmlEscape(co.get("id")), name: co.get("name"), originalId: co.get("id") });
                    }
                }
                var delContext = activity.get("delegationContext");
                for (var j = 0; j < delContext.length; j++) {
                    var parentContext = delContext.models[j];
                    if (parentContext.get('parentContext')) {
                        parentContext = parentContext.get('parentContext');
                    }
                    if (self.mainScenario() !== parentContext) {
                        continue;
                    }
                    var coll = delContext.models[j].get("contextCollaboration");
                    var activityColls = coll.get('activity');
                    for (var k = 0; k < activityColls.length; k++) {
                        checkActivity(activityColls.models[k], compCoColl);
                    }
                }
                return compCoColl;
            };

            var bmActivties = self.model.get("bmActivity");
            for (var i = 0; i < bmActivties.models.length; i++) {
                var activities = bmActivties.models[i].get('activity');
                var compCoColl = [];
                if (activities) {
                    checkActivity(activities, compCoColl);
                    checkCompetencyListCollection(activities, compCoColl);
                }
            }
            function checkCompetencyListCollection(activity, compColl) {
                var exists = false;
                var rolensPath = self.getRoleDispName(activity.get('performingRole'));
                var roleType = self.fillRolepath(activity.get('performingRole'));
                for (var k = 0; k < self.bmCompetencyListArray.length; k++) {
                    if (self.bmCompetencyListArray[k].originalId === activity.get('id')) {
                        compColl.push.apply(compColl, self.bmCompetencyListArray[k].bmCompetencyColl);
                        self.sortCollection(compColl);
                        var obj = { originalId: activity.get('id'), bmActivity: activity.get('name'), bmParticipantRole: activity.get('performingRole').get('name'), rolensPath: rolensPath, roleType: roleType, bmCompetencyColl: compColl, comptBusiness: self.model.get('business').get('name') };
                        //self.bmCompetencyListCollection.replace(self.bmCompetencyListCollection()[k],obj);  
                        self.bmCompetencyListArray[k] = obj;
                        exists = true;
                        break;
                    }
                }
                if (!exists && compColl.length > 0) {
                    self.sortCollection(compColl);
                    var activitynsPath = self.fetchActivityNsPath(activity);
                    var newobj = { originalId: activity.get('id'), bmActivity: activity.get('name'), activitynsPath: activitynsPath, bmParticipantRole: activity.get('performingRole').get('name'), rolensPath: rolensPath, roleType: roleType, bmCompetencyColl: compColl, comptBusiness: self.model.get('business').get('name') };
                    self.bmCompetencyListArray.push(newobj);
                }
            }
            var bmResourceStores = self.model.get('bmResourceStore');
            for (var j = 0; j < bmResourceStores.length; j++) {
                var bi = bmResourceStores.at(j).get('resource');
                var biFlows = bi.get('flow');
                for (var k = 0; k < biFlows.models.length; k++) {
                    var resourceColl = [];
                    var activity = biFlows.models[k].get('recipient') ? biFlows.models[k].get('recipient').get('containedPortOwner') : '';
                    var bmActivity = bmActivties.findWhere({ 'activity': activity });
                    if (bmActivity && bmComp.findWhere({ competence: bi })) {
                        resourceColl.push({ modalId: self.htmlEscape(bi.get("id")), name: bi.get("name"), originalId: bi.get("id") });
                        checkCompetencyListCollection(activity, resourceColl);
                    }
                }
            }
            function sortBMCompetencyList() {
                self.bmCompetencyListArray.sort(function (left, right) {
                    return left.bmActivity.toLowerCase() == right.bmActivity.toLowerCase() ? 0 : (left.bmActivity.toLowerCase() < right.bmActivity.toLowerCase() ? -1 : 1);
                });
            }

            sortBMCompetencyList();
            self.bmCompetencyListCollection(self.bmCompetencyListArray);
        };
        addPartnerClickHandler() {
            var self = this;
            self.fetchCustomerTypeaheads(!self.fetchedParticipantList, function () {
                var collRoles = self.pNCollaborationModal ? self.pNCollaborationModal.get('collaborationRole') : null;
                var tempCustomerTypeAheadColl = [];
                var filterId = self.filterTypeAhead(self.partnerCollection(), self.model.get('bmNetworkPartner'), 'networkPartner', tempCustomerTypeAheadColl);
                var addOptions = { 'pNModel': self.pNModel, 'collRoles': collRoles, 'customerTypeahead': self.customerTypeahead, 'tempCustomerTypeahead': self.tempCustomerTypeahead, 'tempCustomerTypeAheadColl': tempCustomerTypeAheadColl, 'filterTypeahead': filterId, 'customerCollection': self.customerCollection() };
                var id = self.htmlEscape(window.guidGenerator()) + "partner";
                window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "NetworkPartner", _.bind(self.addChildModel, self), addOptions);
            });
        };
        editCustomerClickHandler(view, event) {
            var self = this;
            var id = view.modalId;
            var filterId = self.filterTypeAhead(self.customerCollection(), self.model.get('bmCustomer'), 'customer');
            if ($.find("#modal" + id).length > 0) {
                var addOptions = { 'filterTypeahead': filterId };
                self.updateView('customer', id, addOptions);
                $("#modal" + id).modal('show');
            }
            else {
                var customerModal = self.model.get("bmCustomer").get(view.originalId);
                var collRoles = self.pNCollaborationModal ? self.pNCollaborationModal.get('collaborationRole') : null;
                var addOptions = { 'pNModel': self.pNModel, 'collRoles': collRoles, 'assignments': self.assignmentsColl, 'removeRole': self.removeRoleArray, 'partnerCollection': self.partnerCollection(), 'filterTypeahead': filterId };
                window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), customerModal, "CustomerDetails", _.bind(self.addChildModel, self), addOptions);
            }
        };
        editPartnerClickHandler(view, event) {
            var self = this;
            var id = view.modalId;
            var filterId = self.filterTypeAhead(self.partnerCollection(), self.model.get('bmNetworkPartner'), 'networkPartner');
            if ($.find("#modal" + id).length > 0) {
                var addOptions = { 'filterTypeahead': filterId };
                self.updateView('partner', id, addOptions);
                $("#modal" + id).modal('show');
            }
            else {
                var networkModal = self.model.get("bmNetworkPartner").get(view.originalId);
                var collRoles = self.pNCollaborationModal ? self.pNCollaborationModal.get('collaborationRole') : null;
                var addOptions = { 'pNModel': self.pNModel, 'collRoles': collRoles, 'assignments': self.assignmentsColl, 'removeRole': self.removeRoleArray, 'customerCollection': self.customerCollection(), 'filterTypeahead': filterId };
                window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), networkModal, "NetworkPartner", _.bind(self.addChildModel, self), addOptions);
            }
        };
        editBusinessClickHandler(view, event) {
            var self = this;
            var id;
            if (self.business()) {
                id = self.htmlEscape(self.model.get("business").get("id")) + "business";
            } else {
                for (var i = 0; i < self.children.length; i++) {
                    if (self.children[i].type == 'business') {
                        id = self.children[i].modalId;
                        break;
                    }
                }
            }
            var filterBusinessArray = self.filterBusinessId();
            if ($.find("#modal" + id).length > 0) {
                var addOptions = { 'filterTypeahead': filterBusinessArray };
                self.updateView('business', id, addOptions);
                $("#modal" + id).modal('show');
            }
            else {
                var collRoles = self.pNCollaborationModal ? self.pNCollaborationModal.get('collaborationRole') : null;
                var businessAttached = false;
                var business = businessAttached ? null : self.collaborations;
                self.pnAttached = self.pNModel ? true : false;

                var addOptions = { 'pNModel': self.pNModel, 'collRoles': collRoles, 'orgUnits': business, 'notAttached': self.pnAttached, 'assignments': self.assignmentsColl, 'filterTypeahead': filterBusinessArray, 'removeRole': self.removeRoleArray };
                window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), self.model.get("business"), "BusinessDetails", _.bind(self.addChildModel, self), addOptions);
            }
        };
        editValuePropositionValuesClickHandler(view, event) {
            var self = this;
            var id = view.modalId;
            var modalEle = self.selectedValueProposition() ? self.selectedValueProposition().get('component').findWhere({ 'id': view.id }) : null;
            var tempValuesAgg = self.addTempValuesForAggregation(self.vpName(), 'valueProposition', 'Value Proposition');
            if ($.find("#modal" + id).length > 0) {
                self.updateView('valueProposition', id, tempValuesAgg);
                $("#modal" + id).modal('show');
            }
            else {
                var overAllSatisfaction = self.selectedValueProposition() ? self.selectedValueProposition().get('overAllSatisfaction') : null;
                var scenario = self.selectedValueProposition() ? self.currentAlternativeModel.getMainScenario(self.selectedValueProposition().getNestedParent()) : self.mainScenario();
                var collaboration = self.model.get('bmRole').get(self.selectedProviderRole()).getParent();
				var aggregatedColl = self.getAggregatedColl();
                var addOptions = { 'type': 'valueProposition', 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'overAllSatisfaction': overAllSatisfaction, 'valuesColl': self.valuePropositionValuesColl(), 'enableOverallSatisfaction': self.enableOverallSatisfaction('valueProposition'), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
                window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), modalEle, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
            }
        };
        addBusinessClickHandler(view, event) {
            var self = this;
            var collRoles = self.pNCollaborationModal ? self.pNCollaborationModal.get('collaborationRole') : null;
            var businessAttached = true;
            if (self.bmVPListCollection().length == 0 && self.bmVFListCollection.length == 0 && self.bmActivityListCollection.length == 0 && self.bmCompetencyListCollection.length == 0) {
                businessAttached = false;
            }
            var business = businessAttached ? null : self.collaborations;
            self.pnAttached = self.pNModel ? true : false;
            var filterBusinessArray = self.filterBusinessId();
            var addOptions = { 'pNModel': self.pNModel, 'collRoles': collRoles, 'orgUnits': business, 'notAttached': self.pnAttached, 'assignments': self.assignmentsColl, 'filterTypeahead': filterBusinessArray };
            var id = self.htmlEscape(window.guidGenerator()) + "business";
            self.fetchCustomerTypeaheads(!self.fetchedParticipantList, function () {
                window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "BusinessDetails", _.bind(self.addChildModel, self), addOptions);
            });
        };
        loadParticipantNetworkHandler(view, event) {
            var self = this;
            self.checkOneCCArea('ParticipantNetwork', function (load) {
                if (load) {
                    self.cancelParticipantNetwork();
                    if (self.model.get('business')) {
                        self.canClickCompleteParticipantNetwork(true);
                    }
                    self.pNCollaborationModal = self.collaborations.get(view.originalId);
                    self.participantName = view.name;
                    self.pnAttached = view.attached;
                    if (self.pnAttached) {
                        var pnList = self.model.get('participantNetwork');
                        self.pNModel = pnList.findWhere({ 'collaboration': self.pNCollaborationModal });
                        var roleColl = self.pNCollaborationModal.get("collaborationRole").models;
                        if (self.model.get('business')) {
                            self.businessCollection([]);
                            self.fetchBusiness(roleColl, self.businessCollection, self.model.get("business"), []);
                            if (self.businessCollection().length == 0) {
                                self.businessName(self.model.get("business").get("name"));
                                self.businessCollection([{ modalId: self.htmlEscape(self.model.get("business").get("id")) + "business", name: self.model.get("business").get("name"), originalId: self.model.get("business").get("id"), businessId: self.model.get("business").get("id"), roles: [] }]);
                            }
                        }
                        self.fetchCustomers(roleColl, self.customerCollection, self.model.get('bmCustomer'), [], []);
                        self.fetchPartners(roleColl, self.partnerCollection, self.model.get('bmNetworkPartner'), [], []);
                    }
                    $("#participantName").val(view.name);
                    self.selectedPNRow = event.currentTarget;
                    $(event.currentTarget).addClass('highlightrow').siblings().removeClass('highlightrow');
                    self.enableParticipantCancel(true);
					self.enableParticipantDelete(true);
                    self.setResizeHeight(false);
                    self.disallowedNames = self.currentAlternativeModel.getDisallowedNames(self.pNCollaborationModal, 'collaboration');
                }
            });
        };
        editValuesHandler(view, event) {
            var self = this;
            var component = view.valueModel;
            var id = self.htmlEscape(component.get('id') + "_valuesTab");
            if (component.get('componentOwner')) {
                var type = component.get('componentOwner').get('isValueFormula') ? "valueFormula" : "valueProposition";
                if ($.find("#modal" + id).length > 0) {
                    self.updateView(type, id);
                    $("#modal" + id).modal('show');
                }
                else {
                    var overAllSatisfaction = component.get('componentOwner') ? component.get('componentOwner').get('overAllSatisfaction') : null;
                    var scenario = component.get('componentOwner') ? self.currentAlternativeModel.getMainScenario(component.get('componentOwner').getNestedParent()) : self.mainScenario();
                    var aggregatedColl = self.getAggregatedColl();
					var addOptions = { 'type': type, 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'overAllSatisfaction': overAllSatisfaction, 'enableOverallSatisfaction': self.enableOverallSatisfaction(type), 'currentAlternativeModel': self.currentAlternativeModel, 'disableDelete': true };
                    window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), component, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
                }
            }
            else {
                if ($.find("#modal" + id).length > 0) {
                    self.updateView('createWhatValues', id);
                    $("#modal" + id).modal('show');
                }
                else {
                    var act = component.get('valueAddOwner').get('containedPortOwner');
                    var role = act.get('performingRole');
                    var scenario = self.currentAlternativeModel.getMainScenario(component.getNestedParent());
                    var activities = [{ 'id': act.get('id'), 'name': act.get('name'), 'modalId': self.htmlEscape(act.get('id')) }];
                    var aggregatedColl = self.getAggregatedColl();
					var addOptions = { 'type': 'createWhatValues', 'activityProviderRole': role, 'activities': activities, 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'currentAlternativeModel': self.currentAlternativeModel, 'disableDelete': true };
                    window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), component, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
                }
            }
        }
        addActivityClickHandler() {
            var self = this;
            if (self.selectedPersued() && self.selectedActivityProviderRole()) {
                var id = self.htmlEscape(window.guidGenerator()) + "activity";
                var activitiesTypeaheadColl = self.fillActivityTypeAhead(self.activityCollection());
                var role = self.model.get('bmRole').findWhere({ id: self.selectedActivityProviderRole() });
                var vp = self.fetchValueProposition(self.persuedOptions(), self.selectedPersued());
                self.loadLibraryPackages(function (capabilityLibList) {
                    var addOptions = { 'activityTypeAhead': activitiesTypeaheadColl, 'activityColl': self.activityCollection(), 'allActivities': self.fetchAllActivities(), 'activityProviderRole': role, 'vp': vp, 'capabilityTypeAhead': capabilityLibList };
                    window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "ActivityDetails", _.bind(self.addChildModel, self), addOptions);
                });
            }
        };
        editActivityClickHandler(view, event) {
            var self = this;
            var id = view.modalId;
            if ($.find("#modal" + id).length > 0) {
                self.updateView('activity', id);
                $("#modal" + id).modal('show');
            }
            else {
                var bmActivityModal = null;
                var activities = self.model.get("bmActivity");
				for (var i = 0; i < activities.models.length; i++) {
					var act = activities.models[i].get('activity');
					if (act && act.get('id') === view.originalId) {
                        bmActivityModal = activities.models[i];
                        break;
                    }
                }
                var role = self.model.get('bmRole').findWhere({ id: self.selectedActivityProviderRole() });
                var vp = self.fetchValueProposition(self.persuedOptions(), self.selectedPersued());
                self.loadLibraryPackages(function (capabilityLibList) {
                    var addOptions = { 'activityColl': self.activityCollection(), 'allActivities': self.fetchAllActivities(), 'activityProviderRole': role, 'vp': vp, 'capabilityTypeAhead': capabilityLibList };
                    window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), bmActivityModal, "ActivityDetails", _.bind(self.addChildModel, self), addOptions);
                });
                
            }
        };
        createWhatValuesActivitiesClickHandler() {
            var self = this;
            var openWhatValuesDialog = false;
            for (var i = 0; i < self.activityCollection().length; i++) {
                if (!self.activityCollection()[i]._destroy) {
                    openWhatValuesDialog = true;
                    break;
                }
            }
            if (openWhatValuesDialog) {
                var id = self.htmlEscape(window.guidGenerator()) + "activity";
                var role = self.model.get('bmRole').findWhere({ id: self.selectedActivityProviderRole() });
                var tempValuesAgg = self.addTempValuesForAggregation(null, 'createWhatValues', 'Activity');
				var aggregatedColl = self.getAggregatedColl();
                var addOptions = { 'type': 'createWhatValues', 'activityProviderRole': role, 'activities': self.activityCollection(), 'aggregatedColl': aggregatedColl, 'mainScenario': self.mainScenario(), 'valuesColl': self.activityCreateWhatValuesColl(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg };
                window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
            }
        };
        contributingWhatValuesActivitiesClickHandler() {
            var self = this;
            if (self.selectedPersued()) {
                var id = self.htmlEscape(window.guidGenerator()) + "contributingActivity";
                var vp = self.fetchValueProposition(self.persuedOptions(), self.selectedPersued());
                var vpValues = self.filterActivityAggregatedToValuesList(vp, self.activityContributeWhatValuesColl());
                var tempValuesAgg = self.addTempValuesForAggregation(null, 'createWhatValues', 'Activity', true);
                //DataManager.getDataManager().get('currentPlan').getValuesThatCanBeRefferedTo(function(values){
                //	var valuesColl = new Backbone.Collection();
                //	valuesColl.add(values);
				var aggregatedColl = self.getAggregatedColl();
                var addOptions = { 'type': 'contributeWhatValues', 'activityValueProposition': vp, 'activitiesValues': self.activityCreateWhatValuesColl(), 'vpValue': vpValues, 'aggregatedColl': aggregatedColl, 'mainScenario': self.mainScenario(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg };
                window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
                //});
            }
        };
        editActivityCreateValuesClickHandler(view, event) {
            var self = this;
            var id = view.modalId;
            var tempValuesAgg = self.addTempValuesForAggregation(null, 'createWhatValues', 'Activity');
            if ($.find("#modal" + id).length > 0) {
                self.updateView('createWhatValues', id, tempValuesAgg);
                $("#modal" + id).modal('show');
            }
            else {
                var modalEle = null;
                var activityModal = self.fetchActivityModal(view.activityId);
                var ports = activityModal.get('containedPort');
                for (var i = 0; i < ports.length; i++) {
                    if (ports.at(i).get('type') === "vdml_OutputPort") {
                        modalEle = ports.at(i).get('valueAdd').findWhere({ id: view.originalId });
                        if (modalEle) {
                            break;
                        }
                    }
                }
                var role = self.model.get('bmRole').findWhere({ id: self.selectedActivityProviderRole() });
                var scenario = self.currentAlternativeModel.getMainScenario(modalEle.getNestedParent());
                var collaboration = activityModal.getNestedParent();
				var aggregatedColl = self.getAggregatedColl();
                var addOptions = { 'type': 'createWhatValues', 'activityProviderRole': role, 'activities': self.activityCollection(), 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'valuesColl': self.activityCreateWhatValuesColl(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
                window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), modalEle, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
            }
        };
        editActivityContributingValuesClickHandler(view, event) {
            var self = this;
            var id = view.modalId;
            var tempValuesAgg = self.addTempValuesForAggregation(null, 'createWhatValues', 'Activity', true);
            if ($.find("#modal" + id).length > 0) {
                self.updateView('contributeWhatValues', id, tempValuesAgg);
                $("#modal" + id).modal('show');
            }
            else {
                var modalEle = null;
                if (!view.activityIds) {
                    return;
                }
                var activityComp = self.getValueModal(view.activityIds[0]);
                if (activityComp) {
                    modalEle = activityComp.get('aggregatedTo').findWhere({ id: view.originalId });
                }
                if (!modalEle) {
                    return;
                }
                var scenario = self.currentAlternativeModel.getMainScenario(modalEle.getNestedParent());
                var vp = self.fetchValueProposition(self.persuedOptions(), self.selectedPersued());
                var vpValues = vp.get('component');
				var aggregatedColl = self.getAggregatedColl();
                var addOptions = { 'type': 'contributeWhatValues', 'activityValueProposition': vp, 'activitiesValues': self.activityCreateWhatValuesColl(), 'vpValue': vpValues, 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg };
                window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), modalEle, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
            }
        };
        loadBMActivityHandler(view, event) {
            var self = this;

            self.checkOneCCArea('Activity', function (load) {
                if (load) {
                    self.cancelActivity(true);
                    self.selectedPersued(view.id);
                    $("#persued").val(view.id).prop('selected', true);
                    self.activityCollection(view.bmActivityColl);
                    self.activityCreateWhatValuesColl(view.bmCreatingValuesColl);
                    self.activityContributeWhatValuesColl(view.bmContributingValuesColl);
                    if (view.bmParticipantId) {
                        self.selectedActivityProvider(view.bmParticipantId);
                        self.selectedActivityProviderRole(view.bmActivityRoleId);
                    }
                    self.selectedActRow = $('tr[custId="' + view.bmActivityRoleId + '' + view.id + '"]');
                    //$(event.currentTarget).addClass('highlightrow').siblings().removeClass('highlightrow');
                    self.selectedActRow.addClass('highlightrow');
                    
                    self.enableActivityDelete(true);
                    self.enableActivityCancel(true);
                    self.sortCollection(self.activityCreateWhatValuesColl);
                    self.sortCollection(self.activityContributeWhatValuesColl);
                    self.setResizeHeight(false);
                }
            });
        };
        deleteValueStreamHandler() {
				var self = this;
				var bmActivities = self.model.get('bmActivity');
				var valStreamActivities = self.selectedValueStreamModel.get('valueStreamActivity');
				function doDeleteActivity() {
					window.utils.startSpinner('deleteValueStreams', "Deleting Value Stream...");
                    window.setTimeout(function () {
                        self.activityCollection.destroyAll();
                        self.deleteActivity(self.activityCollection(), self.selectedValueStreamModel);
                        window.setTimeout(function () {
                            DataManager.getDataManager().invokeValueUpdates(function(){
                                self.resetTables(function () {
                                    self.refreshWidgetData(true);
                                    self.setResizeHeight(false);
                                    window.utils.stopSpinner('deleteValueStreams');
                                });
                            },false);
                        }, 10);
                    }, 100);
				}
				var msg = '';
				for (var i = 0; i < valStreamActivities.length; i++) {
					if (bmActivities.findWhere({ 'activity': valStreamActivities.at(i) }) && (valStreamActivities.at(i).get('containedPort').length > 0 || valStreamActivities.at(i).get('delegationContext').length > 0)) {
						msg = msg.concat('' + DataManager.getDataManager().get('localeManager').get('DeleteValueStream'));
						break;
					}
				}
				if (msg.length > 0) {
					bootbox.confirm('' + msg, function (result) {
						if (result) {
								if(window.checkContextForRevision()){
								  self.createRevisionBasedOnContext(true, function (revision) {
									var bmActivities = self.model.get('bmActivity');
									var valStreamActivities = self.selectedValueStreamModel.get('valueStreamActivity');
									self.activityCollection.destroyAll();
									self.deleteActivity(self.activityCollection(), self.selectedValueStreamModel);
								    DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
								  })
								}else{
						           doDeleteActivity();
								}
						}
					});
				} else {
					if(window.checkContextForRevision()){
					  self.createRevisionBasedOnContext(true, function (revision) {
						var bmActivities = self.model.get('bmActivity');
						var valStreamActivities = self.selectedValueStreamModel.get('valueStreamActivity');
						self.activityCollection.destroyAll();
						self.deleteActivity(self.activityCollection(), self.selectedValueStreamModel);
						DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
					  });
					}else{
						 doDeleteActivity();
					}
				}
				
        };
        addCompetencyClickHandler() {
            var self = this;
            var id = self.htmlEscape(window.guidGenerator()) + "competency";
            if ($.find("#modal" + id).length > 0) {
                $("#modal" + id).modal('show');
            }
            else {
                self.loadLibraryPackages(function (capabilityLibList) {
                    var competenciesTypeAheads = self.fillCompetenceTypeAhead();
                    var addOptions = { 'competencyTypeAhead': competenciesTypeAheads, 'competencyCollection': self.competencyCollection(), 'children': self.children, 'capabilityTypeAhead': capabilityLibList };
                    window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "CompetencyDetails", _.bind(self.addChildModel, self), addOptions);
                });
                }
        };

        editCompetencyClickHandler(view, event) {
            var self = this;
            var id = view.modalId;
            if ($.find("#modal" + id).length > 0) {
                self.updateView('competency', id);
                $("#modal" + id).modal('show');
            }
            else {
                var competencyModal;
                var competencies = self.model.get("bmCompetence");
                for (var i = 0; i < competencies.models.length; i++) {
                    if (competencies.models[i].get('competence').get('id') === view.originalId) {
                        competencyModal = competencies.models[i];
                        break;
                    }
                }
                self.loadLibraryPackages(function (capabilityLibList) {
                    var competenciesTypeAheads = self.fillCompetenceTypeAhead();
                    var addOptions = { 'competencyTypeAhead': competenciesTypeAheads, 'competencyCollection': self.competencyCollection(), 'children': self.children, 'capabilityTypeAhead': capabilityLibList };
                    window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), competencyModal, "CompetencyDetails", _.bind(self.addChildModel, self), addOptions);
                });
                }

        };

        loadBMCompetencyHandler(view, event) {
            var self = this;
            self.checkOneCCArea('Competency', function (load) {
                if (load) {
                    self.selectedCompetencyActivities(view.originalId);
                   
                    self.enableCompDelete(true);
                    if(window.checkContextForRevision()){
					  self.enableCompDelete(true);
					}
                    self.enableCompCancel(true);
                    self.selectedBMComptRow = $(event.currentTarget);
                    $(event.currentTarget).addClass('highlightrow').siblings().removeClass('highlightrow');
                    self.setResizeHeight(false);
                }
            });
        };
        deleteCompetenciesHandler() {
            var self = this;
            if (self.competencyActivity) {
			    if(window.checkContextForRevision()){
				  self.createRevisionBasedOnContext(true, function (revision) {
				        var comptencyColl = [];
						for (var k = 0; k < self.bmCompetencyListCollection().length; k++) {
							if (self.bmCompetencyListCollection()[k].originalId === self.competencyActivity.get('id')) {
								var compColl = self.bmCompetencyListCollection()[k].bmCompetencyColl;
								for (var j = 0; j < compColl.length; j++) {
									var model = _.filter(self.model.get('bmCompetence').models, function (num) { if (num.get('competence').id === compColl[j].originalId) { return num } })
								    if(model){
									    self.model.get('bmCompetence').remove(model);
								    }
								}
							}
						}
						var compActId = self.competencyActivity.get('id');
						for (var k = 0; k < self.bmCompetencyListCollection().length; k++) {
								if (self.bmCompetencyListCollection()[k].originalId === compActId) {
									self.bmCompetencyListCollection.splice(k, 1);
									break;
								}
					    }
						self.cancelCompetency();
						self.disableAllCompleteButtons();
					    DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
				  });
				}else{
					competencyCheck(self);
				}
				function competencyCheck(self){
					window.utils.startSpinner('deleteCompetenciesSpinner', "Deleting Competencies...");
					window.setTimeout(function () {
						var comptencyColl = [];
						for (var k = 0; k < self.bmCompetencyListCollection().length; k++) {
							if (self.bmCompetencyListCollection()[k].originalId === self.competencyActivity.get('id')) {
								var compColl = self.bmCompetencyListCollection()[k].bmCompetencyColl;
								for (var j = 0; j < compColl.length; j++) {
									var obj = { 'originalId': compColl[j].originalId, '_destroy': true };
									comptencyColl.push(obj);
								}
								//   	self.bmCompetencyListCollection.splice(k, 1);
								break;
							}
						}
						var compActId = self.competencyActivity.get('id');
						self.deleteComptency(comptencyColl, self.mainScenario(), true, function () {
							for (var k = 0; k < self.bmCompetencyListCollection().length; k++) {
								if (self.bmCompetencyListCollection()[k].originalId === compActId) {
									self.bmCompetencyListCollection.splice(k, 1);
									break;
								}
							}
							self.cancelCompetency();
							self.setResizeHeight(false);
							window.utils.stopSpinner('deleteCompetenciesSpinner');
						});
					}, 100);
				}
            }
        };

        deleteComptencyHandler(comptencyColl, scenario, structuralChange, callback) {
            var self = this;
			var compDelList = [];
			var resourcesToBeDeleted = [];
            var capabilityOfferDeleted = false;
			var competencies = self.model.get("bmCompetence");
			for(var k=0;k<comptencyColl.length;k++){
				if (comptencyColl[k]._destroy && comptencyColl[k].originalId !== '') {
                    for (var j = 0; j < competencies.models.length; j++) {
                        if (competencies.at(j).get('competence').get('id') === comptencyColl[k].originalId) {
							compDelList.push({'bmComp':competencies.at(j)});
							if(competencies.at(j).get('competence').get('type') !== "vdml_BusinessItem"){
								capabilityOfferDeleted = true;
							}
                            break;
                        }
                    }
				}
			}
			async.eachSeries(compDelList,function(compDel,handleNext){
				var bmCompetenceModal = compDel['bmComp'];
				var competence = bmCompetenceModal.get('competence');
				var resource = competence.get('type') === 'vdml_BusinessItem'?true:false;
				self.model.deleteBMCompetency(competence,self.competencyActivity,scenario,function(reused){
					if(resource) {
						if (reused) {
							resourcesToBeDeleted.push(competence);
						}else {
							bmCompetenceModal.destroy();
						}
					}
					handleNext();
				});
			},function(err){
				if (!structuralChange && capabilityOfferDeleted) {
                    self.model.capabilityOfferScenarioChange(scenario,self.competencyActivity);
                }
                async.each(resourcesToBeDeleted, function (resource, resourceHandled) {
                    self.model.comptenceReUsed(resource, function (reused) {
                        if (!reused) {
                            resource.destroy();
                        }
                        resourceHandled();
                    }, self.competencyActivity);
                }, function () {
                    if (callback) {
                        callback();
                    }
                });
			})
        };
        saveCompetencyHandler(view, event, callback) {
            var self = this;
            var structuralChange = self.checkforStructuralChange('competency');
            for (var i = 0; i < self.competencyCollection().length; i++){
                if (self.competencyCollection()[i]._destroy && self.competencyCollection()[i].originalId !== '') {
                    structuralChange = true;
                    break;
                }
            }
            self.createRevisionBasedOnContext(structuralChange, function () {
                window.utils.startSpinner('saveCompetency', "Saving Competency...");
                window.setTimeout(competenceSave, 100);
            });
            function competenceSave() {
                var scenario = self.mainScenario();
                if (window.checkContextForRevision() && structuralChange) {
                    var oldAltIdReg = new RegExp(DataManager.getDataManager().get('currentWorkspace').get('id'), 'g');
                    scenario = self.currentAlternativeModel.getMainScenario(self.model.getNestedParent());
                    self.competencyActivity = self.getNewViewBackboneModel(self.competencyActivity, oldAltIdReg);
                }
                var createCompetences = [];

                async.eachSeries(self.children, function (child, saveCallback) {
                    if (child.type === 'competency') {
                        child.view.saveData(self.model, self.competencyActivity, scenario, self.currentAlternativeModel, function checkCreateCompetence(competence, createUse) {
                            if (createUse) {
                                createCompetences.push(competence);
                            }
                            saveCallback();
                        });
                    } else {
                        saveCallback();
                    }
                }, function (err) {
                    var orgUnit = self.model.get('business');
                    var capOffers = [];
                    _.each(createCompetences, function (competence) {
                        if (competence.get('type') === 'vdml_CapabilityOffer') {
                            capOffers.push(competence);
                        } else {
                            self.competencyActivity.createResourceInput(self.model, competence, orgUnit);
                        }
                    });
                    if (capOffers.length > 0) {
                        self.competencyActivity.createCapabilityUse(self.model, capOffers, orgUnit, scenario);
                        if (!structuralChange) {
                            self.model.capabilityOfferScenarioChange(scenario, self.competencyActivity);
                        }
                    }
                });

                self.deleteComptency(self.competencyCollection(), scenario, structuralChange, function () {
                    DataManager.getDataManager().invokeValueUpdates(handleView);
                    function handleView() {
                        if (window.checkContextForRevision() && structuralChange && !callback) {
                            self.disableAllCompleteButtons();
                            DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + self.model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + self.model.getNestedParent().get('version') + "/" + self.model.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                            window.utils.stopSpinner('saveCompetency');
                        }
                        else if (callback) {
                            window.utils.stopSpinner('saveCompetency');
                            callback();
                        }
                        else {
                            self.cancelCompetency();
                            self.cancelActivity();
                            self.cancelValueProposition();
                            self.cancelValueFormula();
                            self.bmCompetencyListCollection([]);
                            self.fillBmCompetency();
                            self.setResizeHeight();
                            window.utils.stopSpinner('saveCompetency');
                        }
                    }
                });
            };
        };

        loadBusinessDetailComponent(){
            var self = this;
            self.bmDetailsViewInstance = BMDetailsViewModel.getInstance(this);
			if(!ko.components.isRegistered('BMDetailsWidget')){
				ko.components.register("BMDetailsWidget", {
					viewModel: {instance:self.bmDetailsViewInstance},
                    template: window.utils.getHtmlPage("BMDetailsTemplate"),
				});
			}
        }

        loadBMValuesComponents(){
            var self = this;
            self.bmValueWidgetInstance = BMValueDetailsViewModel.getInstance(self);      
            if(!ko.components.isRegistered('valuewidget')){
                ko.components.register("valuewidget", {
                    viewModel: {instance:self.bmValueWidgetInstance},
                    template: window.utils.getHtmlPage("BMValueDetailsTemplate"),
                    synchronous: true
                });
            }
        }


       
        init(model, options) {
            var self = this;
            this.BusinessModelViewModel = this;
            this.model = model;
            model = null;
            this.editable = ko.observable(false);
            this.name = kb.observable(self.model, 'name');
            this.id = kb.observable(self.model, 'id');
            this.encodeId = self.htmlEscape(this.model.id);
            this.description = kb.observable(self.model, 'description');
            this.business = ko.observable(self.model.get('business') ? self.model.get('business') : null);
            this.businessName = ko.observable(self.model.get('business') ? self.model.get('business').get('name') : null);
            this.children = [];
            this.showPost = false;
            this.sortedAggregatedValues = [];
            this.customerArray = [];
            this.partnerArray = [];
            this.paticipantArray = [];
            this.partnerCollection = ko.observableArray(this.partnerArray);
            this.customerCollection = ko.observableArray(this.customerArray);
            this.ticketStatusOptions = TicketStatus.symbols();
            this.businessCollection = ko.observableArray([]);
            this.collaborations = new Backbone.Collection();
            this.participantCollection = ko.observableArray(this.paticipantArray);
            //this.currentEditableTicket = ko.observable(null);
            //this.currentEditableComment = ko.observable(null);
            this.canClickCompleteParticipantNetwork = ko.observable(false);
            this.canClickCompleteValueProposition = ko.observable(false);
            this.canClickCompleteValueFormula = ko.observable(false);
            this.canClickCompleteActivity = ko.observable(false);
            this.selectedFilter = ko.observable();
            this.selectedRoleFilter = ko.observableArray([]);
            this.selectedRecieverRoleFilter = ko.observableArray([]);
            this.selectVPRecieverFilter = ko.observable();
            this.selectVPProviderFilter = ko.observable();
            this.fromRolesFilter = ko.observableArray([]);
            this.toRolesFilter = ko.observableArray([]);
            this.checkAllRoles = ko.observable();
            this.checkAllRecieverRoles = ko.observable();
            this.editMode = DataManager.getDataManager().get('readMode')?false:true;
            if(window.utils.checkIsLinked(null,true)){
                self.editMode = false;
            }
            //this.filterRoles = ko.observableArray(['Attached','Non-Attached','Show All']);
            this.selectedVPFilter = ko.observable();

            this.VPProviderFilter = ko.observableArray([]);
            this.selectedVPProviderRoles = ko.observableArray([]);
            this.selectAllVPProviderRoles = ko.observable();
            this.VPRecieverFilter = ko.observableArray([]);
            this.selectedVPRecieverRoles = ko.observableArray([]);
            this.selectAllVPRecieverRoles = ko.observable();
            //this.filterVP = ko.observableArray(['Attached','Non-Attached','Show All']);
            this.businessRolesArray = [];
            this.businessRoles = ko.observableArray(this.businessRolesArray);
            this.activityProvidersArray = [];
            this.activityProviders = ko.observableArray(this.activityProvidersArray);
            this.pNCollaborationModal;
            self.participantName = "";
            this.pNModel;
            this.pnAttached = null;
            this.alternative = DataManager.getDataManager().get('currentWorkspace').get("id");
            this.customerTypeahead;
            this.tempCustomerTypeahead = [];
            this.assignmentsColl = {};
            this.bmValues = ko.observableArray([]);
            this.valueName = ko.observable();
            this.valueData = ko.observable();
            this.valueFormula = ko.observable();
            this.valueSatisfaction = ko.observable();
            this.valueSatisfactionSmiley = ko.observable();
            this.valueSmileyColor = ko.observable();
            this.svgSmiliesList = window.utils.getSmiliesList();
            //this.valueRecipient = ko.observable();
            this.valueSource = ko.observable();
            this.valueSourceType = ko.observable();
            this.valueFromRole = ko.observable();
            this.valueToRole = ko.observable();
            this.isCollective = ko.observable(false);
            self.bmCompetencyListArray = [];
            this.bmCompetencyListCollection = ko.observableArray(self.bmCompetencyListArray);
            self.bmVPListArray = [];
            self.bmVPListCollection = ko.observableArray(self.bmVPListArray);
            self.bmVFListArray = [];
            self.bmVFListCollection = ko.observableArray(self.bmVFListArray);
            this.valueFormulaValuesColl = ko.observableArray([]);
            this.providersArray = [];
            this.providers = ko.observableArray(this.providersArray);
            this.receiversArray = [];
            this.enableVPProvider = ko.observable(true);
            this.enableVPErrorMessage = ko.observable(false);
            this.enableVFErrorMessage = ko.observable(false);
            this.receivers = ko.observableArray(this.receiversArray);
            this.enableParticipantDelete = ko.observable(false);
            this.enableVPDelete = ko.observable(false);
            this.enableVFDelete = ko.observable(false);
            this.enableActivityDelete = ko.observable(false);
            this.enableCompDelete = ko.observable(false);
            this.enableParticipantCancel = ko.observable(false);
            this.enableVPCancel = ko.observable(false);
            this.enableVFCancel = ko.observable(false);
            this.enableActivityCancel = ko.observable(false);
            this.enableCompCancel = ko.observable(false);
            this.enableCopy = ko.observable(false);
            this.canClickCompleteValues = ko.observable(false);
            this.valueModel = null;
            this.planValuesClickCount = 0;
            self.selectedRightTab = "";

            this.businessRole = [{ id: '1', name: 'My Business Role', desc: 'My Business Role', originalId: '' }];
            if (self.model.get('business')) {
                var business = [{ modalId: self.htmlEscape(self.model.get('business').get("id")) + "business", name: self.business().get('name'), originalId: self.model.get('business').get("id"), businessId: self.model.get('business').get("id"), roles: self.businessRole }];
                self.businessCollection(business);
            }
			/*window.utils.loadTemplate(function(name){
				if(name == "BMValuesChart"){
					self.chartViewInstance.initialize(self.model, false, self);
				}else if(name == "valuewidget") {
					self.bmValueWidgetInstance.initialize(self.model);
					self.openAccordian();
				}
			})*/
            self.chartViewInstance = BMValuesChart.getInstance(this.model);
            if (!ko.components.isRegistered('BMValuesChart')) {
                ko.components.register("BMValuesChart", {
                    viewModel: { instance: self.chartViewInstance },
                    template: window.utils.getHtmlPage("BMValuesChartTemplate"),
                    synchronous: true
                });
            }
            self.loadBMValuesComponents();
			self.loadBusinessDetailComponent();
			self.colloborationViewInstance = ColloborationViewModel.getInstance(this.model);
            if(!ko.components.isRegistered('Ticketwidget')){
                ko.components.register("Ticketwidget", {
                    viewModel: {instance:self.colloborationViewInstance},
                    template: window.utils.getHtmlPage("ColloborationPropertiesTemplate"),
                });
            }
            self.guidanceViewInstance = BMGuidanceViewModel.getInstance(this.model);
            if (!ko.components.isRegistered('BMGuidance')) {
                ko.components.register("BMGuidance", {
                    viewModel: { instance: self.guidanceViewInstance },
                    template: '<div id="guidanceTab"></div>',
                    synchronous: true
                });
            }
            self.setResizeHeight = _.bind(self.setResizeHeightHandler, self);
            this.getViewAlternativeModel();
            this.name.subscribe(function (name) {
                window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'), DataManager.getDataManager().get('viewAlternative'), self.BusinessModelViewModel);
            });

            this.customerCollection.subscribe(function (customers) {
                self.sortCollection(customers);
            });
            this.partnerCollection.subscribe(function (partners) {
                self.sortCollection(partners);
            });

            self.model.get('bmValueProposition').comparator = function (m1, m2) {
                return m1.get('name') === m2.get('name') ? 0 : (m1.get('name') < m2.get('name') ? -1 : 1);
            };
            self.model.get('bmValueFormula').comparator = function (m1, m2) {
                return m1.get('name') === m2.get('name') ? 0 : (m1.get('name') < m2.get('name') ? -1 : 1);
            };
            self.selectedVPFilter('VPAttached');
            this.selectAllVPProviderRoles.subscribe(function (cond) {
                if (!event || ($(event.target) && $(event.target).attr('id') !== "selectAllVPProvider")) {
                    return;
                }
                if (cond) {
                    for (var i = 0; i < self.VPProviderFilter().length; i++) {
                        var roleId = self.VPProviderFilter()[i].id;
                        roleId = roleId.slice(roleId.indexOf("@"));
                        var roleIdIndex = _.indexOf(self.selectedVPProviderRoles(), roleId);
                        if (roleIdIndex == -1) {
                            self.selectedVPProviderRoles.push(roleId);
                        }
                        $("[id = 'vpFromRole" + self.VPProviderFilter()[i].id + "']").prop("checked", true);

                    }
                } else {
                    for (var i = 0; i < self.VPProviderFilter().length; i++) {
                        self.selectedVPProviderRoles([]);
                        var roleId = self.VPProviderFilter()[i].id
                        roleId = roleId.slice(roleId.indexOf("@"));
                        $("[id = 'vpFromRole" + self.VPProviderFilter()[i].id + "']").prop("checked", false);
                    }
                }
                self.fillBMVPList();
            });
            this.selectAllVPRecieverRoles.subscribe(function (cond) {
                if (!event || $(event.target).attr('id') !== "selectAllVPReciever") {
                    return;
                }
                if (cond) {
                    for (var i = 0; i < self.VPRecieverFilter().length; i++) {
                        var roleId = self.VPRecieverFilter()[i].id;
                        roleId = roleId.slice(roleId.indexOf("@"));
                        var roleIdIndex = _.indexOf(self.selectedVPRecieverRoles(), roleId);
                        if (roleIdIndex == -1) {
                            self.selectedVPRecieverRoles.push(roleId);
                        }
                        $("[id = 'vpToRole" + self.VPRecieverFilter()[i].id + "']").prop("checked", true);
                    }
                } else {
                    for (var i = 0; i < self.VPRecieverFilter().length; i++) {
                        self.selectedVPRecieverRoles([]);
                        var roleId = self.VPRecieverFilter()[i].id;
                        roleId = roleId.slice(roleId.indexOf("@"));
                        $("[id = 'vpToRole" + self.VPRecieverFilter()[i].id + "']").prop("checked", false);
                    }
                }
                self.fillBMVPList();
            });
            this.addPartnerClick = _.bind(self.addPartnerClickHandler, self);
            this.editCustomerClick = _.bind(self.editCustomerClickHandler, self);
            this.editPartnerClick = _.bind(self.editPartnerClickHandler, self);
            this.editBusinessClick = _.bind(self.editBusinessClickHandler, self);
            this.editValuePropositionValuesClick = _.bind(self.editValuePropositionValuesClickHandler, self);

            this.loadBMValueProposition = _.bind(self.loadBMValuePropositionHandler, self);
            this.loadBMValueFormula = _.bind(self.loadBMValueFormulaHandler, self);
            this.editValueFormulaValuesClick = _.bind(self.editValueFormulaValuesClickHandler, self);
            this.loadValues = _.bind(self.loadValuesHandler, self);
            this.addBusinessClick = _.bind(self.addBusinessClickHandler, self);

            this.selectVPRecieverFilter.subscribe(function (view) {
                if (!event) {
                    return;
                }
                var isChecked = $(event.target).prop('checked');
                var roleId = $(event.target).attr('id');
                roleId = roleId.slice(roleId.indexOf("@"));
                var roleIdIndex = _.indexOf(self.selectedVPRecieverRoles(), roleId);
                if (isChecked) {
                    if (roleIdIndex == -1) {
                        self.selectedVPRecieverRoles().push(roleId);
                    }
                } else {
                    var index = _.indexOf(self.selectedVPRecieverRoles(), roleId);
                    self.selectedVPRecieverRoles().splice(index, 1);
                    $("#selectAllVPReciever").prop('checked', false);
                    self.selectAllVPRecieverRoles(false);
                }
                self.fillBMVPList();
            });

            this.selectVPProviderFilter.subscribe(function (view) {
                if (!event) {
                    return;
                }
                var isChecked = $(event.target).prop('checked');
                var roleId = $(event.target).attr('id');
                roleId = roleId.slice(roleId.indexOf("@"));
                var roleIdIndex = _.indexOf(self.selectedVPProviderRoles(), roleId);
                if (isChecked) {
                    if (roleIdIndex == -1) {
                        self.selectedVPProviderRoles().push(roleId);
                    }
                } else {
                    var index = _.indexOf(self.selectedVPProviderRoles(), roleId);
                    self.selectedVPProviderRoles().splice(index, 1);
                    $("#selectAllVPProvider").prop('checked', false);
                    self.selectAllVPProviderRoles(false);
                }
                self.fillBMVPList();
            });

            self.bmActivityList = [];
            self.bmActivityListCollection = ko.observableArray(self.bmActivityList);

            //Adding customer typeaheads to temporary array

			this.getAggregatedColl = function(){
				var aggregatedColl = new Backbone.Collection;
				var allValues = [];
				self.model.getValues(allValues,true);
				aggregatedColl.add(allValues);
				self.model.fillPlanValues(aggregatedColl);
				return aggregatedColl;
			};
            this.selectedFilter.subscribe(function (value) {
                var nonAttached = $('table tr.participantfalse');
                if (value != "") {
                    if (value == 'Attached') {
                        $('table tr.participanttrue').show();
                        for (var i = 0; i < nonAttached.length; i++) {
                            nonAttached[i].hidden = true;
                        }
                    }
                    else if (value == 'Non-Attached') {
                        for (var i = 0; i < nonAttached.length; i++) {
                            nonAttached[i].hidden = false;
                        }
                        $('table tr.participanttrue').hide();
                    }
                    else {
                        $('table tr.participanttrue').show();
                        for (var i = 0; i < nonAttached.length; i++) {
                            nonAttached[i].hidden = false;
                        }
                        self.setResizeHeight(false);
                    }
                }
                else {
                    $('table tr.participanttrue').show();
                    for (var i = 0; i < nonAttached.length; i++) {
                        nonAttached[i].hidden = false;
                    }
                }

            });
            self.selectedFilter('Attached');
            this.selectValuesTabFilter = ko.observable();
            this.selectValuesFromRoleFilter = ko.observable();
            this.selectValuesToRoleFilter = ko.observable();
            this.selectValuesFromRoleFilter.subscribe(function (view) {
                if (!event) {
                    return;
                }
                var isChecked = $(event.target).prop('checked');
                var roleId = $(event.target).attr('id');
                roleId = roleId.slice(roleId.indexOf("@"));
                var roleIdIndex = _.indexOf(self.selectedRoleFilter(), roleId);
                if (isChecked) {
                    if (roleIdIndex == -1) {
                        self.selectedRoleFilter.push(roleId);
                    }
                } else {
                    var index = _.indexOf(self.selectedRoleFilter(), roleId);
                    self.selectedRoleFilter().splice(index, 1);
                    $("#selectAllValuesProvider").prop('checked', false);
                    self.checkAllRoles(false);
                }
                self.fillValuesTab();
            });
            this.selectValuesToRoleFilter.subscribe(function (view) {
                if (!event) {
                    return;
                }
                var isChecked = $(event.target).prop('checked');
                var roleId = $(event.target).attr('id');
                roleId = roleId.slice(roleId.indexOf("@"));
                var roleIdIndex = _.indexOf(self.selectedRecieverRoleFilter(), roleId);
                if (isChecked) {
                    if (roleIdIndex == -1) {
                        self.selectedRecieverRoleFilter.push(roleId);
                    }
                } else {
                    var index = _.indexOf(self.selectedRecieverRoleFilter(), roleId);
                    self.selectedRecieverRoleFilter().splice(index, 1);
                    $("#selectAllValuesReciever").prop('checked', false);
                    self.checkAllRecieverRoles(false);
                }
                self.fillValuesTab();
            });
            this.loadParticipantNetwork = _.bind(self.loadParticipantNetworkHandler, self);

            this.checkAllRecieverRoles.subscribe(function (isChecked) {
                if (!event || $(event.target).attr('id') !== "selectAllValuesReciever") {
                    return;
                }
                if ($(event.target).attr('id') !== "selectAllValuesReciever") {
                    return;
                }
                if (isChecked) {
                    for (var i = 0; i < self.toRolesFilter().length; i++) {
                        self.selectedRecieverRoleFilter.push(self.toRolesFilter()[i].id);
                        var roleIdIndex = _.indexOf(self.selectedRecieverRoleFilter(), self.toRolesFilter()[i].id);
                        if (roleIdIndex == -1) {
                            self.selectedRecieverRoleFilter.push(self.toRolesFilter()[i].id);
                        }
                        $("[id = 'valuesToRole" + self.toRolesFilter()[i].id + "']").prop("checked", true);
                    }
                } else {
                    for (var i = 0; i < self.toRolesFilter().length; i++) {
                        self.selectedRecieverRoleFilter([]);
                        $("[id = 'valuesToRole" + self.toRolesFilter()[i].id + "']").prop("checked", false);
                    }
                }
                self.fillValuesTab();
            });
            this.checkAllRoles.subscribe(function (isChecked) {
                if (!event || $(event.target).attr('id') !== "selectAllValuesProvider") {
                    return;
                }
                if ($(event.target).attr('id') !== "selectAllValuesProvider") {
                    return;
                }
                if (isChecked) {
                    for (var i = 0; i < self.fromRolesFilter().length; i++) {
                        var roleIdIndex = _.indexOf(self.selectedRoleFilter(), self.fromRolesFilter()[i].id);
                        if (roleIdIndex == -1) {
                            self.selectedRoleFilter.push(self.fromRolesFilter()[i].id);
                        }
                        $("[id = 'valuesFromRole" + self.fromRolesFilter()[i].id + "']").prop("checked", true);
                    }
                } else {
                    for (var i = 0; i < self.fromRolesFilter().length; i++) {
                        self.selectedRoleFilter([]);
                        $("[id = 'valuesFromRole" + self.fromRolesFilter()[i].id + "']").prop("checked", false);
                    }
                }
                self.fillValuesTab();
            });

            this.mainScenario = ko.observable(self.currentAlternativeModel.getMainScenario(self.model.getNestedParent()));
            this.fetchAssignmentsforParticipants();
            this.removeRoleArray = [];
            this.deleteParticipantArray = [];
            self.highchartsDefaultTheme = $.extend(true, {}, Highcharts.defaultOptions);
            this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), ['BusinessModel'
                , 'name'
                , 'description'
                , 'Business'
                , 'assignedTicket'
                , 'Ticket'
                , 'bmRole'
                , 'Role'
                , 'bmCustomer'
                , 'bmValueProposition'
                , 'bmActivity'
                , 'bmCompetence'
                , 'bmNetworkPartner'
                , 'bmValueFormula'
                , 'BMValues'
                , 'BMDetails'
                , 'Non-Attached'
                , 'ShowAll'
                , 'Collaboration'
                , 'Dispute'
                , 'Suggestion'
                , 'Validation'
                , 'TODO'
                , 'Participants'
                , 'ValuePropositions'
                , 'MyProposition'
                , 'MyPropositions'
                , 'Activities'
                , 'Competencies'
                , 'ParticipantNetworkQ'
                , 'YourBusiness'
                , 'CustomersQ'
                , 'PartnersQ'
                , 'addAnother'
                , 'Partners'
                , 'Complete'
                , 'Cancel'
                , 'Delete'
                , 'Copy'
                , 'ParticipantNetwork'
                , 'YourBusiness'
                , 'CustomerRole'
                , 'PartnerRole'
                , 'NotAssignedRole'
                , 'RoleParticipants'
                , 'Attached'
                , 'EnterParticipants'
                , 'EnterValueProposition'
                , 'WhoParticipant'
                , 'WhoParticipantRole'
                , 'OffersWhat'
                , 'ToWhomParticipant'
                , 'ToWhomParticipantRole'
                , 'createValues'
                , 'ValueProposition'
                , 'SelectVPorMP'
                , 'SelectParticipant'
                , 'SelectRole'
                , 'SelectActivity'
                , 'fromRole'
                , 'toRole'
                , 'values'
                , 'EnterMyProposition'
                , 'Gain'
                , 'IParticipantRole'
                , 'IParticipant'
                , 'EnterCompetencies'
                , 'ThisActivity'
                , 'PerformedBy'
                , 'UsingCompetencies'
                , 'Activity'
                , 'participantRole'
                , 'next'
                , 'Pursued'
                , 'Progress'
                , 'CaptureValues'
                , 'ContributingWhatValues'
                , 'addPNtoCurrentBusiness'
                , 'DoesWhat'
                , 'inOrderToPursue'
                , 'EnterActivities'
                , 'Open'
                , 'InProgress'
                , 'Fixed'
                , 'Closed'
                , 'Guidance'
                , 'satisfaction'
                , 'recipientOpinion'
                , 'source'
                , 'sourceType'
                , 'ValueFormula'
                , 'value'
                , 'ParticipantNetworkName'
                , 'SelectValues'
                , 'Plan'
                , 'deliverValues'
                , 'PresentationType'
                , 'year/period'
                , 'week'
                , 'Quarter',
                , 'month'
                
            ]);
            //#startCustomMethods 


            function checkValuePropositionNameUniqueOrNot(vpName) {
                var bmRoles = self.model.get('bmRole');
                var providerRole = bmRoles.findWhere({ id: self.selectedProviderRole() });
                if (providerRole) {
                    var vp = providerRole.get('providedProposition');
                    var selectedVP = false;
                    if (self.selectedValueProposition()) {
                        var selectedValueProposition = vp.findWhere({ id: self.selectedValueProposition().id });
                        if (selectedValueProposition && selectedValueProposition.get('name') === vpName.trim()) {
                            selectedVP = true;
                        }
                    }
                    if (selectedValueProposition && self.disallowedNames.includes(vpName.trim())) {
                        var providerName = "";
                        for (var i = 0; i < self.providers().length; i++) {
                            if (self.providers()[i].id === self.selectedProvider()) {
                                providerName = self.providers()[i].displayName;
                                break;
                            }
                        }
                        $('#message').text(DataManager.getDataManager().get('localeManager').get('VPDuplicateName', providerName, providerRole.get('name')));
                        self.enableVPErrorMessage(true);
                        self.canClickCompleteValueProposition(false);
                        return;
                    }
                    var vpExist = vp.findWhere({ name: vpName.trim() });
                    if (!selectedVP && vpExist && !vpExist.get('isValueFormula')) {
                        var providerName = "";
                        for (var i = 0; i < self.providers().length; i++) {
                            if (self.providers()[i].id === self.selectedProvider()) {
                                providerName = self.providers()[i].displayName;
                                break;
                            }
                        }
                        $('#message').text(DataManager.getDataManager().get('localeManager').get('VPDuplicateName', providerName, providerRole.get('name')));
                        self.enableVPErrorMessage(true);
                        self.canClickCompleteValueProposition(false);
                    } else {
                        $('#message').text('');
                        self.enableVPErrorMessage(false);
                        self.canClickCompleteValueProposition(true);
                    }
                }
                else {
                    self.canClickCompleteValueProposition(true);
                }
            }
            function checkValueFormulaNameUniqueOrNot(vfName) {
                var bmRoles = self.model.get('bmRole');
                var businessRole = bmRoles.findWhere({ id: self.selectedBusinessRole() });
                if (businessRole) {
                    var vf = businessRole.get('providedProposition');
                    var selectedVF = false;
                    if (self.selectedValueFormula()) {
                        var selectedValueFormula = vf.findWhere({ id: self.selectedValueFormula().id });
                        if (selectedValueFormula && selectedValueFormula.get('name') === vfName.trim()) {
                            selectedVF = true;
                        }
                    }
                    if (selectedValueFormula && self.disallowedNames.includes(vfName.trim())) {
                        $('#vfErrorMessage').text(DataManager.getDataManager().get('localeManager').get('MPDuplicateName'));
                        self.enableVFErrorMessage(true);
                        self.canClickCompleteValueFormula(false);
                        return;
                    }
                    var vfExist = vf.findWhere({ name: vfName.trim() });
                    if (!selectedVF && vfExist && vfExist.get('component').componentOwner.get('isValueFormula')) {
                        $('#vfErrorMessage').text(DataManager.getDataManager().get('localeManager').get('MPDuplicateName'));
                        self.enableVFErrorMessage(true);
                        self.canClickCompleteValueFormula(false);
                    } else {
                        $('#vfErrorMessage').text('');
                        self.enableVFErrorMessage(false);
                        self.canClickCompleteValueFormula(true);
                    }
                }
                else {
                    self.canClickCompleteValueFormula(true);
                }
            }
            function checkParticipantNameExists(partNetworkName) {
                var colls = self.model.getNestedParent().get('collaboration');
                var originalId = "";
                if (self.pNCollaborationModal) {
                    originalId = self.pNCollaborationModal.id;
                    if (self.disallowedNames.includes(partNetworkName)) {
                        return true;
                    }
                }
                for (var i = 0; i < colls.length; i++) {
                    if (colls.at(i).get('name') === partNetworkName.trim() && colls.at(i).get('type') === 'vdml_BusinessNetwork' && colls.at(i).id !== originalId) {
                        return true;
                    }
                }
                return false;
            }
            this.afterRenderView = function () {
                //self.fetchValuesThatCanBeRefferedTo();
                if (self.mainScenario()) {
                    self.fillValuesTableFilterRoles();
                }
                self.showPost = true;
                self.refreshWidgetData(false);
                var nonAttached = $('table tr.participantfalse');
                $('table tr.participanttrue').show();
                for (var i = 0; i < nonAttached.length; i++) {
                    nonAttached[i].hidden = true;
                }
                var nonAttachedVP = $('table tr.valuePropostionfalse');
                $('table tr.valuePropostiontrue').show();
                for (var i = 0; i < nonAttachedVP.length; i++) {
                    nonAttachedVP[i].hidden = true;
                }
                /*if (DataManager.getDataManager().get('viewAlternative') !== DataManager.getDataManager().get('currentWorkspace').get('id')) {
                    self.enableDelete(false);
                }*/
                $("#participantName").keyup(function (view) {
                    var newValue = view.currentTarget.value.trim();
                    if (newValue !== '') {
                        var exists = checkParticipantNameExists(newValue);
                        if (!exists) {
                            self.participantName = newValue;
                            self.canClickCompleteParticipantNetwork(true);
                            if (!self.business() && self.businessCollection().length == 0) {
                                self.canClickCompleteParticipantNetwork(false);
                            }
                            $("#PnMessage").text("");
                        }
                        else {
                            self.canClickCompleteParticipantNetwork(false);
                            $("#PnMessage").text(DataManager.getDataManager().get('localeManager').get('ParticipantNetworkDuplicateName'));
                        }

                    }
                    else {
                        $("#PnMessage").text("");
                        self.canClickCompleteParticipantNetwork(false);
                    }
                    self.enableParticipantCancel(true);
                });

                var VPExtended = Backbone.Typeahead.extend({
                    template: '<input type="text" id="vpName" placeholder="' + DataManager.getDataManager().get('localeManager').get('ValueProposition') + '" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
                });
                var vpTypeahead = new VPExtended({ collection: self.selectableValuePropositions });
                vpTypeahead.setElement('#valuePropositionDiv').render();
                vpTypeahead.on('selected', function (fetchmodel) {
                    self.selectedValueProposition(fetchmodel);
                    self.vpName(fetchmodel.get("name"));
                    var vpExists = self.model.get('bmValueProposition').findWhere({ 'valueProposition': fetchmodel });
                    if (vpExists) {
                        var view = { id: fetchmodel.get('id') };
                        self.loadBMValueProposition(view);
                    }
                    else {
                        if (fetchmodel.get('provider').id === self.selectedProviderRole()) {
                            var receiverRole = self.model.getRoleParticipant(fetchmodel.get('recipient'));
                            if (receiverRole) {
                                self.selectedReceiver(receiverRole.get('id'));
                                self.selectedReceiverRole(fetchmodel.get('recipient').get('id'));
                            } else {
                                self.selectedReceiver(null);
                            }
                        }
                        self.enableVPCancel(true);
                        self.canClickCompleteValueProposition(true);

                        self.enableVPErrorMessage(false);
                    }
                });
                $("#vpName").change(function (view) {
                    var newValue = view.currentTarget.value;
                    /*var bmRoles = self.model.get('bmRole');
                    var providerRole = bmRoles.findWhere({ id: self.selectedProviderRole() });
                    if (providerRole) {
                        var vp = providerRole.get('providedProposition');
                        var vpExist = vp.findWhere({ name: newValue });
                        if (vpExist && !vpExist.get('isValueFormula')) {
                            self.vpName("");
                        } else {
                            self.vpName(newValue);
                        }
                    } else {*/
                        self.vpName(newValue);
                    //}
                    if (!self.bmValueProposition() && self.selectedValueProposition() && self.selectedValueProposition().get('name') != newValue) {
                        self.selectedValueProposition(null);
                    }
                    self.enableVPCancel(true);
                });
                $("#vpName").keyup(function (view) {
                    if ((view.currentTarget.value.trim() !== "") && self.selectedProvider() && self.selectedProviderRole()) {
                        checkValuePropositionNameUniqueOrNot(view.currentTarget.value);
                    }
                    else {
                        self.canClickCompleteValueProposition(false);
                    }
                    if (event.keyCode === 13) {//prevent for "enter" keypress
                        event.stopPropagation();
                    }
                });
                var VFExtended = Backbone.Typeahead.extend({
                    template: '<input type="text" id="vfName" placeholder="My Proposition" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
                });
                var vfTypeahead = new VFExtended({ collection: self.selectableValueFormulas });
                vfTypeahead.setElement('#valueFormulaDiv').render();
                vfTypeahead.on('selected', function (fetchmodel) {
                    self.selectedValueFormula(fetchmodel);
                    self.vfName(fetchmodel.get("name"));
                    var vfExists = self.model.get('bmValueFormula').findWhere({ 'valueFormula': fetchmodel });
                    if (vfExists) {
                        var view = { id: fetchmodel.get('id') };
                        self.loadBMValueFormula(view);
                    }
                    self.enableVFCancel(true);
                    self.canClickCompleteValueFormula(true);

                    self.enableVFErrorMessage(false);
                });
                $("#vfName").change(function (view) {
                    var newValue = view.currentTarget.value;
                    self.vfName(newValue);
                    if (!self.bmValueFormula()) {
                        self.selectedValueFormula(null);
                    }
                    self.enableVFCancel(true);
                });
                $("#vfName").keyup(function (view) {
                    if ((view.currentTarget.value.trim() !== "") && self.selectedBusinessRole()) {
                        checkValueFormulaNameUniqueOrNot(view.currentTarget.value);
                    }
                    else {
                        self.canClickCompleteValueFormula(false);
                    }
                    if (event.keyCode === 13) {//prevent for "enter" keypress
                        event.stopPropagation();
                    }
                });
                $(window).bind("resize", function (event) {
                    if (this == event.target) {
                        $('.sp').removeAttr('style');
                    }
                    var viewportWidth = $(window).width();
                    $('#monitor').html(viewportWidth);
                    self.setResizeHeight();
                });

                $('#myTab a').click(function (e) {
                    e.preventDefault();
                    if (self.hasUnsavedData() && self.editMode) {
                        bootbox.confirm(DataManager.getDataManager().get('localeManager').get('SaveDataBeforeClosing'), function (result) {
                            if (result) {
                                window.vdmModelView.saveDataBeforeClosing(function () {
                                },true);
                            } else {
                                self.clearChildren("customer");
                                self.clearChildren("business");
                                self.clearChildren("partner");
                                self.clearChildren("valueProposition");
                                self.clearChildren("valueFormula");
                                self.clearChildren("activity");
                                self.clearChildren("createWhatValues");
                                self.clearChildren("contributeWhatValues");
                                self.clearChildren("competency");
                                self.cancelValue();
                                self.cancelValueFormula();
                                self.cancelValueProposition();
                                self.cancelCompetency();
                                self.cancelActivity();
                                self.cancelParticipantNetwork();
                            }
                        });
                    }
                    if ($(this).text() === "Competencies") {
                        $("#nextTab").hide();
                    }
                    else {
                        if (!self.business() && $(this).text() === "Values") {
                            $("#nextTab").hide();
                        }
                        else {
                            $("#nextTab").show();
                        }
                    }
                    $(this).tab('show');
                    $("input[name='valuesFilterRoles']").prop("checked", true);
                    $("#splitPanels").enhsplitter('refresh');
                    //load guidance content.
                    if ($("#Guidance").hasClass('active')) {
                        var tabId = $('#myTab .active a')[0].hash;
                        tabId = tabId.substr(1);
                        $.get('js/app/version1/views/help/views/properties/BM'+ tabId + "Guidance.html",function(viewStr){
						    $('#guidanceTab').empty();
							$('#guidanceTab').append(viewStr);
							window.utils.applyFontSize($('#guidanceTab'));
							self.setResizeHeight();
						})
					} else {
                        self.setResizeHeight();
                    }
                });

                self.nextTab = function () {
                    var nextTab = $('#myTab > .active').next('li').find('a');
                    if (self.business()) {
                        nextTab.trigger('click');
                    }
                    else {
                        if (nextTab[0].text == 'Value Formulas') {
                            $('#myTab > .active').next('li').next('li').find('a').trigger('click');
                        }
                        else {
                            nextTab.trigger('click');
                        }
                    }
                };
                $('#rightTab a').click(function (e) {
                    e.preventDefault();
                    $(this).tab('show');
                    self.selectedRightTab = $(this).text();
                    if (this.getAttribute('href') === '#Progress') {
                        self.setResizeHeight(true);
                    } else if ($(this).text() === "Guidance") {
                        var tabId = $('#myTab .active a')[0].hash;
                        tabId = tabId.substr(1);
                        $.get('js/app/version1/views/help/views/properties/BM'+ tabId + "Guidance.html",function(viewStr){
						    $('#guidanceTab').empty();
							$('#guidanceTab').append(viewStr);
							window.utils.applyFontSize($('#guidanceTab'));
							self.setResizeHeight();
						})
					} else {
                        if (self.planValuesClickCount < 1) {
                            window.utils.applyFontSize($('.panel-heading'));
                        }
                        self.planValuesClickCount++;
                        $("#chartTooltip").hide();
                        self.setResizeHeight();
					}
                });

                self.checkToEnableActivityValuesAddIcons();
                var bmAreaNode = $('.bmArea');
                var divId = $('#myTab .active a')[0].hash;
                var bmNode = $('' + divId);
                var propertiesNode = $('.propertiesArea');
                //var treeNode = $("#xaxis");
                var baseHeight = _.max([bmNode.outerHeight(), propertiesNode.outerHeight(), bmAreaNode.outerHeight(), propertiesNode.outerHeight()]);
                //var baseWidth = _.max([bmNode.outerWidth(),propertiesNode.outerWidth(),bmAreaNode.outerWidth()]) + propertiesNode.outerWidth();
                //$('#splitPanels').css({width: baseWidth, height: baseHeight}).split({orientation:'vertical', limit:100,position:'56.7%'});
                self.spliter = $("#splitPanels").enhsplitter({ minSize: 1, onDrag: window.utils.splitterBoundary, collapse: 'right', position: '59%', handle: 'block', splitterSize: '9px', height: baseHeight, onDragEnd: self.setResizeHeight });
                //self.refreshWidgetData(false);
                //$('#splitPanels').bind('splitter.resize',self.setResizeHeight);
                $('.panel').on('hidden.bs.collapse', function (e) {
                    self.setResizeHeight(false);
                });
                $('.panel').on('shown.bs.collapse', function (e) {
                    self.setResizeHeight(false);
                });
                window.utils.applyFontSize($('#content'));
                window.utils.stopSpinner('bmCubeSpinner');
            };

            //value proposition declarations

            self.bmValueProposition = ko.observable();
            self.selectableValuePropositions = new Backbone.Collection();
            self.selectedValueProposition = ko.observable();
            self.vpName = ko.observable();
            this.valuePropositionValues = [];
            this.valuePropositionValuesColl = ko.observableArray(this.valuePropositionValues);
            this.providerRolesArray = [];

            this.selectedProvider = ko.observable();

            this.providerRoles = ko.observableArray(this.providerRolesArray);
            this.selectedProvider.subscribe(function (providerId) {
                function fillProviderRoles(providerId) {
                    var roles = self.model.getParticipantRoles(providerId, self.assignmentsColl);
                    self.providerRolesArray.splice(0, self.providerRolesArray.length);
                    _.each(roles, function (role, index, list) {
                        var displayRolename = role.get('name');
                        displayRolename = displayRolename.concat(self.fillRolepath(role));
                        //if(roles.length>1){
                        var bmroles = self.model.get('bmRole').models;
                        displayRolename = self.checkDuplicatesPath(bmroles, role, displayRolename);
                        //}
                        if (self.selectedProviderRole() !== role.get('id')) {
                            self.providerRoles.push({ id: role.get('id'), providerRoleName: role.get('name'), providerRoleDescription: role.get('description'), displayRolename: displayRolename });
                        }
                    });
                    self.providerRoles.sort(self.sortRoles);
                };
                self.enableVPCancel(true);
                if (providerId === self.selectedReceiver() && self.selectedProviderRole() === self.selectedReceiverRole()) {
                    self.receivers([]);
                    self.fillReceivers();
                    var participantRoles = self.model.getParticipantRoles(providerId, self.assignmentsColl);
                    if (participantRoles.length == 1) {
                        for (var i = 0; i < self.receivers().length; i++) {
                            if (self.receivers()[i].id === providerId) {
                                self.receivers.destroy(self.receivers()[i]);
                            }
                        }
                    }
                    self.selectedReceiverRole(null);
                }
                self.providerRoles([]);
                fillProviderRoles(providerId);
            });
            this.selectedProviderRole = ko.observable();

            this.selectedProviderRole.subscribe(function (providerRoleId) {
                var providerRole = self.model.get('bmRole').get(providerRoleId);
                self.selectableValuePropositions.reset();
                if (providerRole) {
                    var providedPropositions = providerRole.get('providedProposition');
                    for (var i = 0; i < providedPropositions.length; i++) {
                        var receiverRoleId = providedPropositions.at(i).get('recipient') ? providedPropositions.at(i).get('recipient').get('id') : null;
                        var showVP = true;
                        if (receiverRoleId && !self.model.get('bmRole').get(receiverRoleId)) {
                            showVP = false;
                        }
                        if (!providedPropositions.at(i).get('isValueFormula') && showVP) {
                            self.selectableValuePropositions.add(providedPropositions.at(i));
                        }
                    }
                    if (self.selectedValueProposition()) {
                        self.selectableValuePropositions.remove(self.selectableValuePropositions.findWhere({ id: self.selectedValueProposition().id }));
                    }

                    if ((self.vpName() && self.vpName() !== "") && self.selectedProvider() && self.selectedProviderRole() && !self.bmValueProposition()) {
                        checkValuePropositionNameUniqueOrNot(self.vpName());
                        //self.canClickCompleteValueProposition(true);
                    }
                    else if ((self.vpName() && self.vpName() !== "") && self.selectedProvider() && self.selectedProviderRole()) {
                        self.canClickCompleteValueProposition(true);
                    }
                    else if ($('#vpName').val() == "") {
                        self.canClickCompleteValueProposition(false);
                    }
                    if (self.selectedReceiver() === self.selectedProvider() && providerRoleId === self.selectedReceiverRole()) {
                        self.selectedReceiverRole(null);
                        self.receiverRoles([]);
                        fillReceiverRoles(self.selectedReceiver());
                    }
                }
                else {
                    self.canClickCompleteValueProposition(false);
                }
            });

            this.receiverRolesArray = [];


            this.selectedReceiver = ko.observable();
            this.receiverRoles = ko.observableArray(this.receiverRolesArray);
            function fillReceiverRoles(receiverId) {
                var roles = self.model.getParticipantRoles(receiverId, self.assignmentsColl);
                self.receiverRolesArray.splice(0, self.receiverRolesArray.length);
                _.each(roles, function (role, index, list) {
                    if (self.selectedProviderRole() !== role.get('id')) {
                        var displayRolename = role.get('name');
                        displayRolename = displayRolename.concat(self.fillRolepath(role));
                        var bmroles = self.model.get('bmRole').models;
                        displayRolename = self.checkDuplicatesPath(bmroles, role, displayRolename);
                        if (self.selectedProvider() !== role.get('id')) {
                            self.receiverRoles.push({ id: role.get('id'), receiverRoleName: role.get('name'), receiverRoleDescription: role.get('description'), displayRolename: displayRolename });
                        }
                    }
                });
                self.receiverRoles.sort(self.sortRoles);
            };
            this.selectedReceiver.subscribe(function (receiverId) {
                self.enableVPCancel(true);
                self.selectedReceiverRole(null);
                self.receiverRoles([]);
                fillReceiverRoles(receiverId);
            });
            this.selectedReceiverRole = ko.observable();



            this.selectedVPFilter.subscribe(function (value) {
                self.fillBMVPList();
                if (self.setResizeHeight) {
                    self.setResizeHeight();
                }
            });


            //Value Formula declerations

            self.bmValueFormula = ko.observable();
            self.selectableValueFormulas = new Backbone.Collection();
            self.selectedValueFormula = ko.observable();
            self.vfName = ko.observable();
            this.selectedBusinessRole = ko.observable();
            this.editValues = _.bind(self.editValuesHandler, self);

            this.tempValuesForAggregation = [];

            this.selectedBusinessRole.subscribe(function (businessRoleId) {
                var businessRole = self.model.get('bmRole').get(businessRoleId);
                self.selectableValueFormulas.reset();
                if (businessRole) {
                    var businessPropositions = businessRole.get('providedProposition');
                    var VFList = getVFFromProvidedProposition(businessPropositions);
                    self.selectableValueFormulas.add(VFList.models);
                    if (self.selectedValueFormula()) {
                        self.selectableValueFormulas.remove(self.selectableValueFormulas.findWhere({ id: self.selectedValueFormula().id }));
                    }
                    if ((self.vfName() && self.vfName() !== "") && self.selectedBusinessRole()) {
                        checkValueFormulaNameUniqueOrNot(self.vfName());
                        //self.canClickCompleteValueFormula(true);
                    }
                    else {
                        self.canClickCompleteValueFormula(false);
                    }
                }
                else {
                    self.canClickCompleteValueFormula(false);
                }
                self.enableVFCancel(true);
                function getVFFromProvidedProposition(businessPropositions) {
                    var VFList = new Backbone.Collection();
                    _.each(businessPropositions.models, function (proposition) {
                        var provider = proposition.get('provider');
                        var assignment = provider.get('roleAssignment').models[0];
                        if (assignment) {
                            var part = assignment.get('participant');
                            var bmColl = part.get('businessModel');
                            if (bmColl) {
                                bmColl.each(function (bm) {
                                    var bmVF = bm.get('bmValueFormula');
                                    var propositionExists = bmVF.findWhere({ valueFormula: proposition });
                                    if (propositionExists) {
                                        VFList.add(proposition);
                                    }
                                });
                            }
                        }
                    });
                    return VFList;
                }
            });

            //activities declerations
            self.selectedValueStream = ko.observable();
            self.selectedValueStreamModel;

            self.bmPersuedArray = [];
            self.persuedOptions = ko.observableArray(self.bmPersuedArray);


            self.fillBMPersuedOptions();
            self.selectedPersued = ko.observable();
            this.selectedPersued.subscribe(function (vp) {
                var vpModel;
                if (vp) {
                    vpModel = self.fetchValueProposition(self.persuedOptions(), vp);
                    var vdmlPackage = vpModel.getNestedParent();
                    var valueStreams = vdmlPackage.get('valueStream');
                    self.selectedValueStream(null);
                    self.selectedValueStreamModel = null;
                    for (var i = 0; i < valueStreams.length; i++) {
                        if (valueStreams.at(i).get('persuedProposition') && valueStreams.at(i).get('persuedProposition').get('id') === vp) {
                            self.selectedValueStream(valueStreams.at(i).get('id'));
                            self.selectedValueStreamModel = valueStreams.at(i);
                            break;
                        }
                    }
                    self.updateActivityCollection(self.selectedActivityProviderRole(), self.selectedPersued());
                }
                else {
                    self.canClickCompleteActivity(false);
                }
                self.enableActivityCancel(true);
                self.checkToEnableActivityValuesAddIcons();
            });

            this.activityProviderRolesArray = [];

            this.selectedActivityProvider = ko.observable();

            this.activityProviderRoles = ko.observableArray(this.activityProviderRolesArray);
            this.selectedActivityProvider.subscribe(function (providerId) {
                self.fillActivityProviderRoles(providerId);
                self.enableActivityCancel(true);
                self.checkToEnableActivityValuesAddIcons();
            });
            this.selectedActivityProviderRole = ko.observable();
            this.selectedActivityProviderRole.subscribe(function (providerRoleId) {
                self.updateActivityCollection(self.selectedActivityProviderRole(), self.selectedPersued());
                self.enableActivityCancel(true);
                self.checkToEnableActivityValuesAddIcons();
            });

            this.activityArray = [];
            this.activityCollection = ko.observableArray(this.activityArray);
            this.activityCreateWhatValues = [];
            this.activityCreateWhatValuesColl = ko.observableArray(this.activityCreateWhatValues);
            this.activityContributeWhatValues = [];
            this.activityContributeWhatValuesColl = ko.observableArray(this.activityContributeWhatValues);

            this.activityCollection.subscribe(function (activities) {
                self.sortCollection(activities);
            });

            this.addActivityClick = _.bind(self.addActivityClickHandler, self);
            this.editActivityClick = _.bind(self.editActivityClickHandler, self);
            this.createWhatValuesActivitiesClick = _.bind(self.createWhatValuesActivitiesClickHandler, self);
            this.contributingWhatValuesActivitiesClick = _.bind(self.contributingWhatValuesActivitiesClickHandler, self);
            this.editActivityCreateValuesClick = _.bind(self.editActivityCreateValuesClickHandler, self);
            this.editActivityContributingValuesClick = _.bind(self.editActivityContributingValuesClickHandler, self);
            this.loadBMActivity = _.bind(self.loadBMActivityHandler, self);
            this.deleteValueStream = _.bind(self.deleteValueStreamHandler, self);

            //competencies starts here
            self.competenciesArray = [];
            self.competencyActivities = ko.observableArray(self.competenciesArray);

            self.fillBMCompetenciesOptions();
            self.selectedCompetencyActivities = ko.observable();
            this.competencyArray = [];
            this.competencyCollection = ko.observableArray(this.competencyArray);
            this.enableCompetencyComplete = ko.observable(false);
            this.competencyActivity = null;
            this.activityProvider = ko.observable();
            this.comptBusiness = ko.observable();
            this.selectedCompetencyActivities.subscribe(function (compActivity) {
                if (self.competencyActivity !== null) {
                    self.clearChildren("competency");
                    self.children = [];
                    self.competencyCollection([]);
                }
                for (var i = 0; i < self.model.get('bmActivity').models.length; i++) {
                    var activity = self.model.get('bmActivity').models[i].get('activity');
                    if (activity.id === compActivity) {
                        self.competencyActivity = activity;
                        var displayRolename = activity.get('performingRole').get('name');
                        self.activityProvider(" (" + displayRolename + ")");
                        self.comptBusiness(self.model.get('business').get('name'));
                        break;
                    }
                }
                var competencyCollectionExists = false;
                if (compActivity) {
                    for (var k = 0; k < self.bmCompetencyListCollection().length; k++) {
                        if (self.bmCompetencyListCollection()[k].originalId === compActivity) {
                            self.competencyCollection([]);
                            var comp = self.bmCompetencyListCollection()[k].bmCompetencyColl;
                            for (var i = 0; i < comp.length; i++) {
                                var obj = { 'name': comp[i].name, 'originalId': comp[i].originalId, 'modalId': comp[i].modalId };
                                self.competencyCollection.push(obj);
                                competencyCollectionExists = true;
                            }
                        }
                    }
                    /*if(!competencyCollectionExists){
                        self.competencyCollection([]);
                    }*/
                    self.enableCompCancel(true);
                }
                else {
                    self.competencyCollection([]);
                    self.comptBusiness(null);
                    self.activityProvider(null);
                    competencyCollectionExists ? self.enableCompCancel(true) : self.enableCompCancel(false);
                }
                self.checkCompetencyCompleteEnableClick();
            });

            this.competencyCollection.subscribe(function (competencies) {
                self.sortCollection(competencies);
            });

            this.addCompetencyClick = _.bind(self.addCompetencyClickHandler, self);
            this.editCompetencyClick = _.bind(self.editCompetencyClickHandler, self);
            this.loadBMCompetency = _.bind(self.loadBMCompetencyHandler, self);
            this.deleteCompetencies = _.bind(self.deleteCompetenciesHandler, self);
            this.deleteComptency = _.bind(self.deleteComptencyHandler, self);
            this.saveCompetency = _.bind(self.saveCompetencyHandler, self);

            this.showAggregationView = function (view, event) {
                event.stopPropagation();
                const viewId = view.id ? view.id : view.originalId;
                self.currentAlternativeModel.showAggregationView(viewId, function(){
                    window.utils.startSpinner('refreshValues', 'Refreshing ...');
                    DataManager.getDataManager().invokeValueUpdates(function () {
                        self.refreshWidgetData(true);
                        self.cancelValue();
                        self.resetTables(function () {
                            window.utils.stopSpinner('refreshValues');
                        });
                    });
                });
            }

		}

            showCorrectiveActions(view) {
                var correctiveActions = view.correctiveActions;
                if (!correctiveActions) {
                    var alt = view.currentAlternativeModel;
                    if (view.valueModel && alt) {
                        var selectedInterval = view.valueModel.getSatisfactionInterval(view.mainScenario());
                        if (selectedInterval) {
                            correctiveActions = selectedInterval.get('correctiveActions') ? selectedInterval.get('correctiveActions') : '';
                        }
                    }
                }
                if (correctiveActions) {
                    var htmlContent = "<div class='row'><div class='col-xs-1'></div><div class='col-xs-10'><span class='control-label' style='word-wrap: break-word;'>" + correctiveActions + "</span></div></div>";
                    var box = bootbox.dialog({
                        message: htmlContent,
                        title: "<div><img class='smallIcon' src='img/icons/icons_15.png'>&nbsp;&nbsp;&nbsp;" + DataManager.getDataManager().get('localeManager').get('CorrectiveActions') + "</div>",
                        buttons: {
                            cancel: {
                                label: "Close",
                                className: 'btn vtn-default',
                                callback: function () {

                                }
                            }
                        }
                    });
                    box.show();
                }
            };
     
		static getInstance(model,options){
            var view = new BusinessModelViewModel(model, options);
            //view.init(model, options);
			return view;
		}
    }
	path.BusinessModelViewModel = BusinessModelViewModel;