// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0px !important;
  flex-direction: column;
}

.containerName {
  min-height: 20px;
  font-size: 21px;
  color: grey;
  font-family: Roboto medium;
  padding-left: 10px !important;
  min-width: 450px;
}

.presentationGrid {
  max-width: 100%;
  min-width: 450px;
  display: flex;
  flex: 1;
}

.item-content .gridView {
  box-shadow: 3px;
  padding: 1px;
  margin: auto 0;
  border-radius: 2px;
}`, "",{"version":3,"sources":["webpack://./js/app/version1/views/dashboard/views/chart/PresentationWrapper.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,0BAAA;EACA,2BAAA;EACA,2BAAA;EACA,sBAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,eAAA;EACA,WAAA;EACA,0BAAA;EACA,6BAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,eAAA;EACA,gBAAA;EACA,aAAA;EACA,OAAA;AAAJ;;AAGA;EACI,eAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;AAAJ","sourcesContent":["\n.container {\n    display: flex;\n    padding-left: 0 !important;\n    padding-right: 0 !important;\n    padding-top: 0px !important;\n    flex-direction: column;\n}\n\n.containerName {\n    min-height: 20px;\n    font-size: 21px;\n    color: grey;\n    font-family: Roboto medium;\n    padding-left: 10px !important;\n    min-width: 450px,\n}\n\n.presentationGrid {\n    max-width: 100%;\n    min-width: 450px;\n    display: flex;\n    flex: 1;\n}\n\n.item-content .gridView {\n    box-shadow: 3px;\n    padding: 1px;\n    margin: auto 0;\n    border-radius: 2px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
