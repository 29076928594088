import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BaseMeasureRelationship} from './BaseMeasureRelationship'
import {BaseMeasureRelationshipMixin} from './BaseMeasureRelationshipMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'
//import { DimensionalMeasure } from './DimensionalMeasure'
//import { CollectiveMeasure } from './CollectiveMeasure'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/BaseMeasureRelationship","appbo/smm/BaseMeasureRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,BaseMeasureRelationship,BaseMeasureRelationshipMixin
, DimensionalMeasure
, CollectiveMeasure){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	//if(!DimensionalMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasure){
			DimensionalMeasure = importPath.DimensionalMeasure;
		}
        else {
            import('./DimensionalMeasure').then(({ default: DimensionalMeasure }) => {
                DimensionalMeasure = DimensionalMeasure;
            });

			/*require(["appbo/smm/DimensionalMeasure"],function(loadedModule){
				DimensionalMeasure = loadedModule;
			});*/
		}
	//}
	//if(!CollectiveMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.CollectiveMeasure){
			CollectiveMeasure = importPath.CollectiveMeasure;
		}
        else {
            import('./CollectiveMeasure').then(({ default: CollectiveMeasure }) => {
                CollectiveMeasure = CollectiveMeasure;
            });

			/*require(["appbo/smm/CollectiveMeasure"],function(loadedModule){
				CollectiveMeasure = loadedModule;
            });*/
		}
	//}
	export class BaseNMeasureRelationshipMixin {
        /*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
            path.BaseNMeasureRelationshipMixin = BaseNMeasureRelationshipMixin;
        }*/
	defaults(){
		var ret = {
			type: "smm_BaseNMeasureRelationship"
		}
		return jQuery.extend(BaseMeasureRelationship.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!BaseNMeasureRelationshipMixin.cummulativeRelations) {
            BaseNMeasureRelationshipMixin.cummulativeRelations = _.union(BaseNMeasureRelationshipMixin.getMixinRelations()
                , BaseMeasureRelationshipMixin.getCumulativeMixinRelations()
            );
        }
		return BaseNMeasureRelationshipMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_BaseMeasureRelationship"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "influence",type : "Influence",defaultValue : "null",containingClass : "smm_MeasureRelationship" }
		]
	}
	getParent(){
		var container;
		return BaseMeasureRelationship.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return BaseMeasureRelationship.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/BaseNMeasureRelationshipPropertiesTemplate.html",
			templateName : "BaseNMeasureRelationshipPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/BaseNMeasureRelationshipViewModel",
			tabId : "BaseNMeasureRelationshipView",
			tabName: "BaseNMeasureRelationship"
		}
	}
	handleTargetChange(model, related, options) {
	    if (!related && !options.creatingRevision) {
			this.set("nestedParent",model.get("nestedParent"),{silent:true});
	        this.destroy();
	    }
	}
	
	}
	path.BaseNMeasureRelationshipMixin = BaseNMeasureRelationshipMixin;
	//return BaseNMeasureRelationshipMixin;
//});