import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import { DataManager } from '../../../../../com/vbee/data/DataManager'

/*
define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","async","appcommon/com/vbee/data/DataManager","app/global", "appbo/vdml/MeasuredCharacteristic"],
    function (require, $, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, global, MeasuredCharacteristic){*/
        var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties",global.version);
        export class MoveValueViewModel {
            
			getBMList(mappedObj) {
                var mypackage = mappedObj.getNestedParent();
                var bmList = [];
                function fetchMappedBm(pack) {
                    var bms = pack.get('businessModel');
                    for (var i = 0; i < bms.length; i++) {
                        var bmActs = bms.at(i).get('bmActivity');
                        for (var j = 0; j < bmActs.length; j++) {
                            var act = bmActs.at(j).get('activity');
                            if (act === mappedObj) {
                                bmList.push(bms.at(i));
                            }
                        }
                    }
                }
                fetchMappedBm(mypackage);
                var depPack = mypackage.get('dependentPackage');
                for (var i = 0; i < depPack.length; i++) {
                    if (depPack.at(i).get('type') === "vdml_ValueDeliveryModel") {
                        fetchMappedBm(depPack.at(i));
                    }
                }
                return bmList;
            }

			createRevisionBasedOnContext(parentView, callback) {
				if(parentView.model.getNestedParent() != DataManager.getDataManager().get('currentPlan') && window.checkContextForRevision()) {
					window.utils.startSpinner('revisionSpinner', "Creating a copy...");
					function fetchBmModel(newVdmPackage){
						if(newVdmPackage) {
							DataManager.getDataManager().releaseSaveLock();
		        			DataManager.getDataManager().set('isActive',false);
							window.utils.stopSpinner('revisionSpinner');
		        			callback(newVdmPackage);
							/*DataManager.getDataManager().saveData({
								success:function(){
									window.utils.stopSpinner('revisionSpinner');
		        					callback(newVdmPackage);
								}, 
								error:function(){
									window.utils.stopSpinner('revisionSpinner');
		        					callback(newVdmPackage);
								}, 
								persist:true});	*/
						}
					}
					window.setTimeout(function(){
						DataManager.getDataManager().acquireSaveLock(function () {
							parentView.model.getNestedParent().createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
						});
	        		},100);
				}else {
					callback();
				}
			}

            createVPC(vp, oldVpc, valueName, view, actTuples, revision, callback) {
                var self = this;
				var isOVS = view.checkOVS();
				var newVPCIdSuffix = oldVpc ? window.utils.getSuffix(oldVpc.id) : null;
                var newValueModal = vp.createValuePropositionComponent(valueName, valueName, isOVS, newVPCIdSuffix);

				var vdm = vp.getNestedParent();
				vdm.createValueDetails(newValueModal,oldVpc);

				var accumulatorJson = view.fetchAccumulatorJson(view.accumulator());				
				var measurementValue = view.measurementValue();
				if (view.enableMeasurement() && !isNaN(view.measurementValue())) {
					measurementValue = view.fullMeasurementValue ? view.fullMeasurementValue : view.measurementValue();
				}
				
                //view.setComponentforTempAgg(tempValuesForAggregation);
                var expression = view.expression();
                view.reconstructBaseTuples(view.baseTuples);
				var baseTupleCount = 0;
				_.each(view.baseTuples,function(tuple){
					if(actTuples){
						_.each(actTuples,function(actValue){
							if(tuple.new && tuple.new.component == actValue){
								tuple.new = null;
							}
						});
					}
					tuple.old = null;
					if(tuple.new){
						tuple.new.baseMeasureRel = null;
						baseTupleCount++;
					}
					if(revision && tuple.new && tuple.new.component && DataManager.getDataManager().getRepositoryId(tuple.new.component.get('id')) != window.plansKey){
						if(!tuple.new.usePreviousAlternative) {
							var newComponent = self.fetchComponentById(view.currentAlternativeModel.id + window.utils.getSuffix(tuple.new.component.id));
							tuple.new.component = newComponent;
						}
						tuple.new.measure = null;
					}
				});
				if(baseTupleCount == 0){
					expression = "";
				}else {
					newValueModal.addBaseTuple(view.baseTuples);
				}
				_.each(view.intervalTuples,function(tuple){
					tuple.old = null;
					tuple.new.id = null;
				});
				
                var valUnitObj = { unitName: view.measurementUnit(), roundingDigits: view.roundingDigits(), significantDecimals: view.significantDecimals(), unitObj: null };
                var satUnitObj = { unitName: view.satisfactionUnitValue(), roundingDigits: view.satRoundingDigits(), significantDecimals: view.satSignificantDecimals(), unitObj: null };
                if (view.checkPrime()) {
					newValueModal.prime(measurementValue, valUnitObj, view.formula(), view.satisfaction(), view.satisfactionType(), view.checkDimensional(), satUnitObj, view.weight(), view.checkDimensional() ? view.recipientValue() : view.recipientSatValue(), accumulatorJson, view.baseTuples, view.intervalTuples, null, view.currentAlternativeModel, view.maxChange(), null, expression, function(){
						callback(newValueModal);
					});
				}else {
					callback(newValueModal);
				}
            }

			fetchNamePathValue(count, results, component, valueName, callback) {
				var self = this;
				var doc = results[count].doc;
				var parent = results[count].parent;
				var altId = DataManager.getDataManager().getRepositoryId(doc);
				var vdmStore = DataManager.getDataManager().getVDMStore(altId);
				var version = DataManager.getDataManager().get('currentVDMVersion');
				DataManager.getDataManager().fetchDocumentFromPackage(parent, "appbo/vdml/ValueDeliveryModel", version, doc, "appbo/vdml/ValuePropositionComponent", vdmStore, {
					success: function (valModel) {
						if (window.utils.getSuffix(valModel.getParent().id) === window.utils.getSuffix(component.id)) {
							callback(component, valModel, valueName);
						} else {
							count++;
							if (results[count]) {
								self.fetchNamePathValue(count, results, component, valueName, callback);
							} else {
								callback(component, null, null, valueName);
							}
						}
					},
					error: function (error) {
						console.log('Unable to load Value document');
						callback(null, null, valueName);
					}
				});
			}

            createVPValue(vp, view, actTuples, revision, finalCallback) {
                var self = this;
				var valueName = view.name().trim();
				valueName = this.getUniqueValueName(valueName,valueName,vp);
				var providerRole = vp.get('provider');
				if(providerRole) {
					var collaboration = providerRole.getParent();
					var vdml = collaboration.getNestedParent();
					var namePath = vdml.get('name') + '>' + collaboration.get('name') + '>' + providerRole.get('name') + '>' + vp.get('name') + '>' + valueName;
					vdml.getDocumentWithNamePath(namePath, "vdml_ValuePropositionComponent", view.currentAlternativeModel, true, vdml, function (results) {
						if (results && results[0]) {
							self.fetchNamePathValue(0, results, vp, valueName, function(){
								self.createVPC(vp, null, valueName, view, actTuples,revision, function(newValueModal){
									finalCallback(newValueModal);
								});
							});
						} else {
							self.createVPC(vp, null, valueName, view, actTuples,revision, function(newValueModal){
								finalCallback(newValueModal);
							});
						}
					});
				}else{//Plan Value
					self.createVPC(vp, null, valueName, view, actTuples,revision, function(newValueModal){
						finalCallback(newValueModal);
					});
				}
			};

			getUniqueValueName(originalValueName, valueName, valueContainer, count) {
				var self = this;
				if(!count){
					var count = 0;
				}
				var found = false;
				
				if(valueContainer.get('containedPort')){
					valueContainer.get('containedPort').each(function (port) {
						if (port.get('type') === "vdml_OutputPort" && !found) {
							found = port.get('valueAdd').findWhere({'name':valueName});
						}
					});
				}else {
					var found = valueContainer.get('component').findWhere({'name':valueName});
				}
				if (found) {
					valueName = originalValueName.concat(count);
					valueName = this.getUniqueValueName(originalValueName, valueName, valueContainer, ++count);
				} else {
					return valueName;
				}
				return valueName;
			};

			createActivityValue(activity,view,actTuples,revision,finalCallback){

				var accumulatorJson = view.fetchAccumulatorJson(view.accumulator());
				view.reconstructBaseTuples(view.baseTuples);
                var currentAlternativeModel = view.currentAlternativeModel;

                var activityValueName = view.name().trim();
				activityValueName = this.getUniqueValueName(activityValueName,activityValueName,activity);

                var checkPrimary = view.checkPrime();
                var measurementValue = view.measurementValue();
                if (view.enableMeasurement() && !isNaN(view.measurementValue())) {
                    measurementValue = view.fullMeasurementValue ? view.fullMeasurementValue : view.measurementValue();
                }
                var measurementUnit = view.measurementUnit();
                var roundingDigits = view.roundingDigits();
                var significantDecimals = view.significantDecimals();
                var formula = view.formula();
                var maxChange = view.maxChange();
				var vdm = activity.getNestedParent();
				var expression = view.expression();
				var baseTupleCount = 0;
				_.each(view.baseTuples,function(tuple){
					if(actTuples){
						_.each(actTuples,function(actValue){
							if(tuple.new && tuple.new.component == actValue){
								tuple.new = null;
							}
						});
					}
					tuple.old = null;
					if(tuple.new){
						tuple.new.baseMeasureRel = null;
						baseTupleCount++;
					}
					if(revision && tuple.new && tuple.new.component && DataManager.getDataManager().getRepositoryId(tuple.new.component.get('id')) != window.plansKey){
						if(!tuple.new.usePreviousAlternative) {
							var newComponent = self.fetchComponentById(view.currentAlternativeModel.id + window.utils.getSuffix(tuple.new.component.id));
							tuple.new.component = newComponent;
						}
						tuple.new.measure = null;
					}
				});
				if(baseTupleCount == 0){
					expression = "";
				}
				//newValueModal.addBaseTuple(view.baseTuples);
				
				var bmActivityModal = null;

				function createActValue(oldValModel, activityValueName) {
                    var activityValue = activity.createWhatValues(vdm, currentAlternativeModel, activityValueName, checkPrimary, measurementValue, { unitName: measurementUnit, roundingDigits: roundingDigits, significantDecimals: significantDecimals }, formula, accumulatorJson, view.baseTuples, maxChange, bmActivityModal, oldValModel, expression);
                    finalCallback(activityValue);
                }

                var outputPort = bmActivityModal?bmActivityModal.get('defaultOutput'):null;
                var outputPortName = '';
                if (!outputPort) {
                    outputPortName = activity.getUniqueProperty('name', activity.get('name'), 'containedPort') + " port";
                } else {
                    outputPortName = outputPort.get('name');
                }
                var collaboration = activity.getParent();
                var vdml = collaboration.getNestedParent();
                var namePath = vdml.get('name') + '>' + collaboration.get('name') + '>' + activity.get('name') + '>' + outputPortName + '>' + activityValueName;
                vdml.getDocumentWithNamePath(namePath, "vdml_ValueAdd", view.currentAlternativeModel, true, vdml, function (results) {
                    if (results && results[0]) {
                        var doc = results[0].doc;
                        var parent = results[0].parent;
                        var altId = DataManager.getDataManager().getRepositoryId(doc);
                        var vdmStore = DataManager.getDataManager().getVDMStore(altId);
                        var version = DataManager.getDataManager().get('currentVDMVersion');
                        DataManager.getDataManager().fetchDocumentFromPackage(parent, "appbo/vdml/ValueDeliveryModel", version, doc, "appbo/vdml/ValueAdd", vdmStore, {
                            success: function (valModel) {
                                 createActValue(valModel, activityValueName);
                            },
                            error: function (error) {
                                console.log('Unable to load Value document');
                                createActValue(null, activityValueName);
                            }
                        });
                    } else {
                        createActValue(null, activityValueName);
                    }
                });
			};

			/*this.checkOvsAggregation = function(valueView){
				var ovsTuples = [];
				var ovsVal = valueView.model.getParent().get('overAllSatisfaction');
				if(ovsVal) {
					_.each(valueView.baseTuples,function(tuple){
						if(tuple.old && tuple.old.component && tuple.old.component == ovsVal){
							ovsTuples.push(tuple.old.component);
						} else if(!tuple.old && tuple.new && tuple.new.component && tuple.new.component == ovsVal){
							ovsTuples.push(tuple.new.component);
						}
					});
				}
				return ovsTuples;
			}*/
			getContainerAct(newContainer,valStreams){
				var newContainerActValues = [];
				for(var i=0;i<valStreams.length;i++){
					if(valStreams.at(i).get('persuedProposition') == newContainer) {
						newContainerActValues.push.apply(newContainerActValues,valStreams.at(i).get('activityValue').models.concat());
					}
				}
				return _.uniq(newContainerActValues);
			}

			getFilteredAggregation(valueView, type, vf){
				var actAggTuples = [];
				_.each(valueView.baseTuples,function(tuple){
					if(tuple.old && tuple.old.component && tuple.old.component.get('type') == type){
						var comp = tuple.old.component;
						if(vf && comp.getParent().get('isValueFormula')){
							actAggTuples.push(comp);
						}else if(type == "vdml_ValueAdd"){
							actAggTuples.push(comp);
						}
						
					} else if(!tuple.old && tuple.new && tuple.new.component && tuple.new.component.get('type') == type){
						var comp = tuple.new.component;
						if(vf && comp.getParent().get('isValueFormula')){
							actAggTuples.push(comp);
						}else if(type == "vdml_ValueAdd"){
							actAggTuples.push(comp);
						}
					}
				});
				return actAggTuples;
			}

			checkConstraints(valueView,callback){
				var self = this;
				var actTuples = [];
				var removeToAggList = [];
				var msg = DataManager.getDataManager().get('localeManager').get('MoveWarningMessage');
				var lossAgg = false;
				if (self.selectedType() === self.valuePropositionName && self.selectedVP()) {
					var newContainer =  Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: self.selectedVP().id });					
				} else if (self.selectedType() === self.myPropositionName && self.selectedMP()) {
					var newContainer = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: self.selectedMP().id });
				} else if (self.selectedType() === self.activityName && self.selectedActivity()) {
					var newContainer = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: self.selectedActivity().id });
				} else if(self.selectedType() === self.planName){
					var newContainer = DataManager.getDataManager().get('currentPlan');
				} else {
					callback();
					return;
				}

				if((self.selectedType() == self.myPropositionName || self.selectedType() == self.valuePropositionName)  && valueView.model.get('type') == "vdml_ValuePropositionComponent" && valueView.model.getNestedParent() != DataManager.getDataManager().get('currentPlan')){
					actTuples = self.getFilteredAggregation(valueView,"vdml_ValueAdd");
					if(actTuples.length > 0) {
						var valStreams = valueView.model.getNestedParent().get('valueStream');
						var newContainerActivities = self.getContainerAct(newContainer,valStreams);
						for(var i=0;i<valStreams.length;i++){
							if(valStreams.at(i).get('persuedProposition') == self.currentContainer) {
								for(var j=0;j<actTuples.length;j++){
									if(valStreams.at(i).get('activityValue').findWhere({'id':actTuples[j].id}) && !newContainerActivities.includes(actTuples[j])){
										msg = msg.concat("<br/>"+actTuples[j].get('name'));
										lossAgg = true;
									}
								}
							}
						}
					}
					/*var ovsAgg = self.checkOvsAggregation(valueView);
					if(ovsAgg && ovsAgg.length > 0){
						msg = msg.concat("<br/>"+ovsAgg[0].get('name'));
						lossAgg = true;
						actTuples.push.apply(actTuples, ovsAgg);
					}*/
				}
				if((self.selectedType() === self.valuePropositionName || self.selectedType() === self.activityName) && (self.currentContainer.get('isValueFormula') || self.currentContainer.getNestedParent() == DataManager.getDataManager().get('currentPlan'))){//to remove mybusiness agg values
					var vfTuples = self.getFilteredAggregation(valueView,"vdml_ValuePropositionComponent",true);
					for(var j=0;j<vfTuples.length;j++){
						msg = msg.concat("<br/>"+vfTuples[j].get('name'));
						lossAgg = true;
					}
					actTuples.push.apply(actTuples, vfTuples);
				}else if(newContainer && self.selectedType() === self.activityName && self.currentContainer.get('type') == "vdml_Activity"){//act to act
					var vpList = [];
					var bmValueStreams = valueView.model.getNestedParent().get('valueStream');
					for(var i=0;i<bmValueStreams.length;i++){
						if(bmValueStreams.at(i).get('valueStreamActivity').findWhere({'id':newContainer.id})){
							vpList.push(bmValueStreams.at(i).get('persuedProposition'));
						}
					}
					_.each(valueView.aggregateToColl(),function(toColl){
						if(!toColl.usePreviousRelation && (toColl.aggregateToType == self.valuePropositionName || toColl.aggregateToType == self.myPropositionName)){
							var compId = toColl.originalId;
							var comp =  Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: compId });
							if(comp && vpList.indexOf(comp.getParent()) == -1){
								removeToAggList.push(compId);
								msg = msg.concat("<br/>"+comp.get('name'));
								lossAgg = true;
							}
						}
					});
				}
				if(lossAgg) {
					bootbox.confirm(msg, function(result,event) {
						if(result) {
					  		callback(newContainer,actTuples,removeToAggList);
						}else {
					  		self.cleanModal();
						}
					});
				}else {
					callback(newContainer,actTuples,removeToAggList);
				}
			}

            moveValue(){
				var self = this;
				self.checkConstraints(self.parentView,function(newContainer,actTuples,removeToAggList){
					if(newContainer) {
						self.createRevisionBasedOnContext(self.parentView, function (revision) {
							if(revision) {
								var newAlt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().getRepositoryId(revision.get('id')));
								self.parentView.currentAlternativeModel = newAlt;
								if(newContainer.get('type') == "vdml_ValueProposition"){
									newContainer =  Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: newAlt.id + window.utils.getSuffix(newContainer.id) });
								} else if(newContainer.get('type') == "vdml_Activity"){
									newContainer = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: newAlt.id + window.utils.getSuffix(newContainer.id) });
								}
								//var newPack = newVdmPackage;
								//var bmId = self.model.id.substr(self.model.id.lastIndexOf('@')+1,self.model.id.length);
								//var altId = DataManager.getDataManager().getRepositoryId(newPack.id);
								//var bmToBeDeleted = window.checkContextForRevision() ? newPack.get('businessModel').findWhere({'id':altId+bmId}) : self.model;
							}
							window.utils.startSpinner('moveValue', 'Moving Value...');
							window.setTimeout(function () {
								if(newContainer && newContainer.get('type') == "vdml_ValueProposition"){
									self.createVPValue(newContainer,self.parentView,actTuples,revision,function(newValueModal){
										self.fixAggregateTo(newValueModal,self.parentView,revision,function(){
											self.refreshPage(revision);
										},removeToAggList);
									});
								} else if(newContainer && newContainer.get('type') == "vdml_Activity"){
									var activity = newContainer;
									self.createActivityValue(activity,self.parentView,actTuples,revision,function(activityValue){
										var actBMList = self.getBMList(activity);
										_.each(actBMList, function (bm) {
											var bmAct = bm.get('bmActivity').findWhere({ 'activity': activity });
											if (bmAct) {
												_.each(bm.get('bmValueStream').models, function (valStream) {
													var actExists = valStream.get('valueStreamActivity').findWhere({ 'id': activity.id });
													if (actExists){
														valStream.get('activityValue').add(activityValue);
														bmAct.get('activityValue').add(activityValue);
													}
												});         
											}
										});
										self.fixAggregateTo(activityValue,self.parentView,revision,function(){
											self.refreshPage(revision);
										},removeToAggList);
									});
								} else if(newContainer && newContainer.get('type') == "transformation_Plan"){
									self.createVPValue(newContainer.getOrCreatePlanCriterionSet(),self.parentView,actTuples,revision,function(newValueModal){
									self.parentView.enablePrime(true);
										newContainer.incrementOtherAlts(newValueModal,self.parentView,function(){
											self.fixAggregateTo(newValueModal,self.parentView,revision,function(){
												self.refreshPage(revision);
											},removeToAggList);
										});
									});
								} else {
									window.utils.stopSpinner('moveValue');
									self.cleanModal();
									//Do Nothing
								}
							}, 100);
						});
					}else{
						window.utils.stopSpinner('moveValue');
						self.cleanModal();
					}
				});
            }

			fixAggregateTo(newValueModal,view,revision,finalCallback,removeToAggList){
				var self = this;
				var currentAlternativeModal = view.currentAlternativeModel;
				var aggToList = [];
				_.each(view.aggregateToColl(),function(aggTo){
					var aggToId = aggTo.originalId;
					if(aggToId && removeToAggList.indexOf(aggToId) == -1){//Todo use previous
						if(revision && DataManager.getDataManager().getRepositoryId(aggToId) != window.plansKey){
							aggToList.push(currentAlternativeModal.id + window.utils.getSuffix(aggToId));
						}else {
							aggToList.push(aggToId);
						}
					}
				});
				var oldModalId = window.utils.getSuffix(view.id());
				var multiplier = 1;
				var offset = 0;
				var operation = null;
				var usePreviousAlternative = false;
				self.measure = null;
				var mc = newValueModal.get('valueMeasurement');
				if(mc){
					var nestParent = newValueModal.getNestedParent();
					if(nestParent == DataManager.getDataManager().get('currentPlan')){
						var useScenario = view.fetchPlanScenario(false);
					}else {
						var useScenario = currentAlternativeModal.getMainScenario(nestParent);
					}
					var obsMeasure = useScenario.getObservedMeasureWithMeasurements(mc, null);
					self.measure = obsMeasure ? obsMeasure.get('measure') : null;
				}

                async.eachSeries(aggToList, function (valId, callback) {
					var valModal = self.fetchComponentById(valId);
					if(valModal){
						var valueMeasurement = valModal.get('valueMeasurement');
						if(valueMeasurement){
							var nestedParent = valModal.getNestedParent();
							var nestedParentAltId = DataManager.getDataManager().getRepositoryId(nestedParent.id);
							var scenario;
							if(currentAlternativeModal.id != nestedParentAltId){
								if(nestedParent == DataManager.getDataManager().get('currentPlan')){
									scenario = currentAlternativeModal.getOrCreatePhaseObjectiveSet()
								}else {
									var packAlt = DataManager.getDataManager().getAlternativeSync(nestedParentAltId);
									scenario = packAlt.getMainScenario(nestedParent);
								}
							}else {
								scenario = currentAlternativeModal.getMainScenario(nestedParent);
							}
							var obsMes = scenario?scenario.getObservedMeasureWithMeasurements(valModal.get('valueMeasurement'), null):null;	
							var baseTuples = valModal.constructBaseMeasureTupleSet(valModal.get('valueMeasurement'),scenario,null,true);
							_.each(valModal.get('aggregatedFrom').models,function(component){
								var compScenario = currentAlternativeModal.getMainScenario(component.getNestedParent());
								var obsMeasure = compScenario ? compScenario.getObservedMeasureWithMeasurements(component.get('valueMeasurement'), null) : null;
								if (!obsMeasure) {
									var obj = {};
									obj.old = { seqNo: baseTuples.length, id: component.id, component: component, measure: null, offset: 0, multiplier: 1, existing: true, operation: null, usePreviousAlternative: null, baseMeasureRel: null };
									obj.new = { seqNo: baseTuples.length, id: component.id, component: component, measure: null, offset: 0, multiplier: 1, existing: true, operation: null, usePreviousAlternative: null, baseMeasureRel: null };
									baseTuples.push(obj);
								}
							});
							
							if(baseTuples){
								var newTuples = [];
								for(var i=0; i < baseTuples.length; i++){
									var obj = {};
									if(baseTuples[i].old && window.utils.getSuffix(baseTuples[i].old.component.id) == oldModalId){
										multiplier = baseTuples[i].old.multiplier;
										offset = baseTuples[i].old.offset;
										operation = baseTuples[i].old.operation;
										usePreviousAlternative = baseTuples[i].old.usePreviousAlternative;
										baseTuples[i].new = null;
										//break;
										obj.new = { seqNo: baseTuples.length + newTuples.length , id: newValueModal.id, component: newValueModal, measure: self.measure, offset: offset, multiplier: multiplier, existing: false, operation: operation, usePreviousAlternative: usePreviousAlternative, baseMeasureRel: null };
										newTuples.push(obj);
									}
								}
								baseTuples.push.apply(baseTuples,newTuples);
								valModal.addBaseTuple(baseTuples);
							}
							if(obsMes){
								var value = obsMes.get('measurements').at(0).get('value');
								var measure = obsMes.get('measure');
								var expression = obsMes.get('measurements').at(0).get('expression');
								var valueUnit = measure.get('unit');
								var unitName = valueUnit?valueUnit.get('name'):'';
								var roundingDigits = valueUnit?valueUnit.get('roundingDigits'):4;
								var significantDecimals = valueUnit?valueUnit.get('significantDecimals'):2;
								var valUnitObj = {unitName:unitName, roundingDigits:roundingDigits, significantDecimals:significantDecimals};
								var valueFormula = obsMes.get('valueFormula') ? obsMes.get('valueFormula'):valueMeasurement.get('valueFormula');
								var skipOtherScenarios = true;
								var altModal = packAlt ? packAlt : currentAlternativeModal
								var accumulator;
								if(measure.get('type') === 'smm_CollectiveMeasure'){
									accumulator = measure.get('accumulator');
								}
								valModal.updateValueElementChanges(scenario, scenario, valModal.get('name'), value, valUnitObj, valueFormula, accumulator, baseTuples, null, altModal, valModal.get('maxChange'),expression,function(){
									callback();
								},skipOtherScenarios);	
							}else {
								callback();
							}
						}else {
							callback();
						}
					}else{
						callback();
					}
				},function(err){
					finalCallback();
				});
			}

			refreshPage(revision){
				var self = this;
				window.cleanDialogModel(self.parentView.encodeId, self.parentView);
				if(revision && window.vdmModelView.disableAllCompleteButtons){
					var altId = DataManager.getDataManager().getRepositoryId(revision.id);
					var newValueModalId = altId + window.utils.getSuffix(self.model.id);
					var comp = self.fetchComponentById(newValueModalId);
					if(comp){
						comp.destroy();
					}
					self.cleanModal();
					window.vdmModelView.disableAllCompleteButtons();
					var bmId = window.vdmModelView.model.id.substr(window.vdmModelView.model.id.lastIndexOf('@')+1,window.vdmModelView.model.id.length);
					var newBmModel = revision.get('businessModel').findWhere({'id':altId+bmId});
					window.setTimeout(function () {
						window.utils.stopSpinner('moveValue');
						DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + revision.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + revision.getNestedParent().get('version') + "/" + newBmModel.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
					},300);
				}else {
					self.model.destroy();
					self.cleanModal();
					DataManager.getDataManager().invokeValueUpdates(function () {
						if(window.vdmModelView.refreshWidgetData) {
							window.vdmModelView.refreshWidgetData(true);
							if(window.vdmModelView.resetTables) {
								window.vdmModelView.resetTables(function () {
									window.utils.stopSpinner('moveValue');
								});
							}else {//Plan page
								window.utils.stopSpinner('moveValue');
							}
						}else {
							window.utils.stopSpinner('moveValue');
						}
					});
				}
			}

			fetchComponentById(aggregateId) {
				var self = this;
				var aggComp = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: aggregateId });
				if (!aggComp) {
					aggComp = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: aggregateId });
				}
				return aggComp;
			}

            loadBms() {
                var self = this;
				if(!self.isPlanValue){
					var bm = self.parentView.parentView;
					if(bm && bm.get('type') == "vdml_BusinessModel"){
						self.bmList.push(bm);
					}
					/*_.each(self.package.get('businessModel').models,function(bm){
						self.bmList.push(bm);
					});*/
				}else {
					var altModel = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: DataManager.getDataManager().get('viewAlternative') });
					var phaseDesignPart = altModel.fetchNonCommonPackagesFromAlternative();
					for(var i=0; i < phaseDesignPart.length; i++){
						var beepRef = phaseDesignPart.at(i).get('beepReference');
						if(phaseDesignPart.at(i).get('beepType') == "vdml_ValueDeliveryModel" && DataManager.getDataManager().getRepositoryId(beepRef) == altModel.id){
							var bmPack = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: beepRef});
							_.each(bmPack.get('businessModel').models,function(businessModel){
								self.bmList.push(businessModel);
							})
							
						}
					}
				}
                
            }
            loadVps(bmList) {
                var self = this;
                _.each(bmList,function (bm) {
                    _.each(bm.get('bmValueProposition').models,function(vp){
						if(self.currentContainer != vp.get('valueProposition')){
							self.allVps.push({id: vp.get('valueProposition').id,name: vp.get('valueProposition').get('name')});
						}
                    })
                })
            }
            loadMps(bmList) {
                var self = this;
                _.each(bmList,function (bm) {
                    _.each(bm.get('bmValueFormula').models,function(mp){
						if(self.currentContainer != mp.get('valueFormula')){
							self.allMps.push({id: mp.get('valueFormula').id,name: mp.get('valueFormula').get('name')});
						}
                    })
                })
            }
            loadActivities(bmList) {
                var self = this;
                _.each(bmList,function (bm) {
                    _.each(bm.get('bmActivity').models,function(act){
						if(self.currentContainer != act.get('activity')){
							self.allActivities.push({id: act.get('activity').id,name: act.get('activity').get('name')});
						}
                    })
                })
            }

            cleanModal() {
                var self = this;
				self.parentView = null;
                window.cleanDialogModel(self.encodeId,self);
            }
            afterRenderView() {

            }

			

            
init(model,options){
    var self = this;
    this.MoveValueViewModel = this;
    this.model = model;
    model = null;
    this.options = options;
    options = null;
    if (self.options && self.options.parentView) {
        this.parentView = self.options.parentView;
    }
    this.id = "moveValue" + self.options.modalId;
    this.isPlanValue = false;
    this.name = ko.observable(self.model ? self.model.get('name') : null);
    this.description = ko.observable(self.model ? self.model.get('description') : "");
    this.enableComplete = ko.observable(true);
    this.mypackage = self.model.getNestedParent();
    this.selectedType = ko.observable();
    this.allVps = ko.observableArray();
    this.allMps = ko.observableArray();
    this.allActivities = ko.observableArray();
    self.vpContainer = ko.observable(true);
    self.mpContainer = ko.observable(false);
    self.activityContainer = ko.observable(false);
    this.selectedVP = ko.observable();
    this.selectedMP = ko.observable();
    this.selectedActivity = ko.observable();
    this.myPropositionName = DataManager.getDataManager().get('localeManager').get('MyProposition');
    this.valuePropositionName = DataManager.getDataManager().get('localeManager').get('ValueProposition');
    this.activityName = DataManager.getDataManager().get('localeManager').get('Activity');
    this.planName = 'Plan Value';
    this.bmList = [];
    self.type = self.model.get('type');
    if (self.type == "vdml_ValuePropositionComponent") {
        this.currentContainer = self.model.getParent();
    } else {
        this.currentContainer = self.model.getParent().getParent();
    }

    this.encodeId = "" + self.options.modalId;
    self.types = ko.observableArray();

    this.selectedType.subscribe(function (val) {
        if (val === self.valuePropositionName) {
            self.vpContainer(true);
            self.mpContainer(false);
            self.activityContainer(false);
        }
        else if (val === self.myPropositionName) {
            self.vpContainer(false);
            self.mpContainer(true);
            self.activityContainer(false);
        }
        else if (val === self.activityName) {
            self.vpContainer(false);
            self.mpContainer(false);
            self.activityContainer(true);
        } else {
            self.vpContainer(false);
            self.mpContainer(false);
            self.activityContainer(false);
        }
    });
    if (self.mypackage == DataManager.getDataManager().get('currentPlan')) {
        self.isPlanValue = true;
    }

    self.loadBms();
    self.loadMps(self.bmList);
    if (!self.parentView.satisfactionOnly()) {
        self.loadActivities(self.bmList);
    }
    self.loadVps(self.bmList);

    if (self.allActivities().length > 0) {
        self.types.push(self.activityName);
    }
    if (self.allMps().length > 0) {
        self.types.push(self.myPropositionName);
    }
    if (!self.isPlanValue) {
        self.types.push(self.planName);
    }
    if (self.allVps().length > 0) {
        self.types.push(self.valuePropositionName);
    }
    self.types.sort();

    
    this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
        , 'name'
        , 'description'
        , 'Complete'
        , 'Close'
        , 'selectPackage']);

            }

static getInstance(model, options){
    var view = new MoveValueViewModel(model, options);
    view.init(model, options);
    return view;
};
}
path.MoveValueViewModel = MoveValueViewModel;