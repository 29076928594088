import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {Value} from './Value'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/VdmlElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,VdmlElementMixin
, Value){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Value){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueDefinition){
			Value = importPath.Value;
		}
		else{
			import('./Value').then(loadedModule => {
				Value = loadedModule;
			});
			/*require(["appbo/vdml/Value"], function (loadedModule) {
				Value = Value;
			});*/
		}
	}
	export class ValueLibraryMixin {
		getMixinClass(){
			return ValueLibraryMixin;
		}
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.ValueLibraryMixin = ValueLibraryMixin;
        }*/
		defaults(){
			var ret = {
				type: "vdml_ValueLibrary",
				version: DataManager.getDataManager().get('currentVDMVersion')
			}
			return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
		}
		static getMixinRelations(){
			return _.union([
				{
					type: Backbone.HasMany,
					containingClass: "vdml_ValueLibrary",
					key: "value",
					relatedModel: "vdml.Value",
					reverseRelation: {
						key: "valueOwner",
						type: Backbone.HasOne,
						includeInJSON: "id"
					}
				}
			/*{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueLibrary",
				key:"valueDefinition",
				relatedModel:"vdml.ValueDefinition",
				reverseRelation: {
					key:"valueDefinitionOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			},
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueLibrary",
				key:"valueCategory",
				relatedModel:"vdml.ValueCategory",
				reverseRelation: {
					key:"valueCategoryOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			}*/
			])
		}
		
		static getCumulativeMixinRelations(){
			if (!ValueLibraryMixin.cummulativeRelations) {
				ValueLibraryMixin.cummulativeRelations = _.union(ValueLibraryMixin.getMixinRelations()
					, DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
				);
			}
			return ValueLibraryMixin.cummulativeRelations.slice();
		}
		static getSuperTypes(){
			return [
				"vdml_VdmlElement"
			];
		}
		static getProperties(){
			return [
				{ name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
				{ name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
				{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
				{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
			]
		}
		getParent(){
			var container;
			if(!container){
				container = this.get("valueLibraryOwner") ? this.get("valueLibraryOwner") : this.previousAttributes().valueLibraryOwner;
				if(container){
					return container;
				}
			}
			return this;
		}
		getPackagePath(path){
			if(!path){
				path = [];
			}
			var container;
			if(!container){
				container = this.get("valueLibraryOwner") ? this.get("valueLibraryOwner") : this.previousAttributes().valueLibraryOwner;
				if(container){
					path.unshift({id:this.get('id'),name:this.get('name')})
					return container.getPackagePath(path);
				}
			}
			return path;
		}
		getViewProperties(type) {
			return this.getMixinClass().getDialogViewProperties(type);
		};
		static getName = function () {
			return "ValueLibrary";
		};
		getSystemTags = function () {
			return ['VDM', 'Value Library', 'Admin'];
		};
		static getMyInstance = function (cls,name, description, vdm, id) {
			var vlId = id ? id : DataManager.getDataManager().guidGeneratorByOwner(vdm);
			var vl = new cls({
				'id': vlId,
				'name': name,
				'description': description,
				'version': vdm.get('version'),
				'valueLibraryOwner': vdm
			});
			return vl;
		};
		static getDialogViewProperties(type){
			if(type==="explorerModelJson"){
				return{
					templatePath : "views/beeppackage/views/properties/explorerModelJsonTemplate.html",
					templateName : "explorerModelJsonTemplate",
					viewTypeStr : "appviews/beeppackage/views/properties/explorerModelJsonViewModel",
					tabId: "explorerModelJsonView",
					tabName: "explorerModelJson"
				}
			}
			else{
			return {
				templatePath: "views/valueLibrary/properties/ValueLibraryTemplate.html",
				templateName: "ValueLibraryTemplate",
				viewTypeStr: "appviews/valueLibrary/properties/ValueLibraryViewModel",
				tabId: "ValueLibraryView",
				tabName: "ValueLibrary"
			}
		}
		}
	
	}
	path.ValueLibraryMixin = ValueLibraryMixin;
	//return ValueLibraryMixin;
//});