export class ScenarioMeasurement{
    constructor(valueElement,dataset){
        this.valueElement = valueElement;
        this.dataset = dataset;
    }

    setValue(value){
        //this.dataset.get("data")[this.valueElement.id] = value;
        if(value == null || value == ""){
            this.dataset.unset(this.valueElement.id);
        } else {
            this.dataset.set(this.valueElement.id , value);
        }
        
    }

}