import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
//import * as DataTable from '../../../../../../libs/DataTable/jquery.dataTables'
import * as async from 'async'
import * as jstree from '../../../../../../libs/jstree/jstree'
//import * as d3 from '../../../../../../libs/d3/d3.min'
//import {select, selectAll} from 'd3-selection';
//import {scaleOrdinal, scaleLinear} from 'd3-scale';
//import {RadarChart} from '../../../../../../libs/d3/RadarChart'
import * as multiselect from '../../../../../../libs/bootstrap-multiselect/js/bootstrap-multiselect'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {UserPreferences} from '../../../../../version1/bo/preference/UserPreferences'
import {ValueElementMixin} from '../../../../../version1/bo/vdml/ValueElementMixin'
import * as Highcharts from 'highcharts';
import {HighChartThemes} from '../../../../../com/vbee/utils/HighChartThemes'
//const d3 = {select, selectAll, scaleOrdinal, scaleLinear}
import more from 'highcharts/highcharts-more';
more(Highcharts);
// Alternatively, this is how to load Highstock. Highmaps and Highcharts Gantt are similar.
// import Highcharts from 'highcharts/highstock';

// Load the exporting module.
//import Exporting from 'highcharts/modules/exporting';
// Initialize exporting module. (CommonJS only)
//Exporting(Highcharts);
/*define(["require", "jquery", "jstree", "underscore",  "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async",  "appcommon/com/vbee/data/DataManager", "app/global", "appbo/preference/UserPreferences", "appbo/preference/Preference", "d3", "RadarChart", "appbo/vdml/ValueElementMixin", "appcommon/com/vbee/utils/HighChartThemes", "highcharts-common", "d3-legend", "bootstrap-multiselect", "jquery.DataTable", "dataTable.PageResize", "dataTable.ColReorderWithResize",  "tick-positioner"],
function (require, $, jstree, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, global, UserPreferences, Preference, d3, RadarChart, ValueElementMixin, HighChartThemes, Highcharts
) {*/
var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties",global.version);
export class PlanValuesChart {
		constructor(model,options){
			var self = this;
			this.init(model, options);
		}
    init(model, options) {
        var self = this;
        this.datasets = {};
        this.values = [];
		this.disabledValues = []
        this.PlanValuesChart = this;
        this.model = model;
        this.editable = ko.observable(false);
        this.name = kb.observable(model, 'name');
        this.id = kb.observable(model, 'id');
        this.encodeId = this.model ? self.htmlEscape(this.model.id) : null;
		this.alignAxis = 1;
        this.description = kb.observable(model, 'description');
        self.xAxisSelected = [];
        this.selectedChartType = ko.observable();
        this.chartTypes = ko.observableArray(['Curves','Stacked bars','Stacked columns','Bars','Columns',/*,'Radar',*/'Table','Polar','Spiderweb']);
        this.selectedTheme = ko.observable();
        this.themesArray = ['Default', 'Dark Unica', 'Sand Signika', 'Grid Light'];
        this.chartTypes.sort();
		this.chartColors = ["#EDC951", "#F08080", "#FFA07A","#4169E1","#98FB98","#EDC951","#87CEFA","#ceaeff","#003366","#0066ff"]
        this.selectedChartType.subscribe(function (selectedType) {
			if($("#placeholder" + self.encodeId).length > 0) {
            var selectedOptions = $('#yaxis' + self.encodeId + ' option:selected');
            self.maxUnitsPermitted = selectedType === "Stacked bars" || selectedType === "Stacked columns"? 1: 2;
            if (selectedType === 'Radar' && selectedOptions.length < 3) {
                bootbox.alert(DataManager.getDataManager().get('localeManager').get('radarMessage'));
                self.selectedChartType('Curves');
            } else {
                self.getValueMeasurements(self.alts, function(){
            		self.plotValues();
                	self.plotAccordingToChoices();
            	});
            }
			}
        });
        
        this.selectedTheme.subscribe(function (theme){
        	if(self.parentView){
				Highcharts.setOptions(HighChartThemes.getDefaultThemeFont());
			}
        	switch(theme){
        		case 'Default':
        			if(self.parentView){
						Highcharts.theme = self.parentView.highchartsDefaultTheme;
						Highcharts.setOptions(Highcharts.theme);
						window.utils.startSpinner('refreshCharts','Refreshing ...');
						self.parentView.setResizeHeight(true);
					}
        			break;
        			
        		case 'Dark Unica':
        			Highcharts.theme =  HighChartThemes.DarkUnica();
					Highcharts.setOptions(Highcharts.theme);
					window.utils.startSpinner('refreshCharts','Refreshing ...');
					self.parentView.setResizeHeight(true);
        			break;
        			
        		case 'Sand Signika':
        			Highcharts.theme = HighChartThemes.SandSignika();
					Highcharts.setOptions(Highcharts.theme);
					window.utils.startSpinner('refreshCharts','Refreshing ...');
					self.parentView.setResizeHeight(true);
        			break;
        			
        		case 'Grid Light':
        			Highcharts.theme = HighChartThemes.GridLight();
					Highcharts.setOptions(Highcharts.theme);
					window.utils.startSpinner('refreshCharts','Refreshing ...');
					self.parentView.setResizeHeight(true);
        			break;
        	}
        });
        
        self.showRadar = ko.observable(false);
    	
        /*this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['general','BusinessModel'
        ,'name'
        ,'description'
        ,'business'
		]);*/
    }  
    //#startCustomMethods    
    htmlEscape(str) {
        return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
    }
    showConfig() {
        var self = this;
        if ($('#chartConfig' + self.encodeId).is(":visible")) {
            $("#chartConfig" + self.encodeId).hide();
            $("#showConfigButton")[0].title = "Show Configuration";
        } else {
            $("#chartConfig" + self.encodeId).show();
            $("#showConfigButton")[0].title = "Hide Configuration";
        }
        if (self.parentView && self.parentView.setResizeHeight) {
            self.parentView.setResizeHeight();
        }
    }
	dispose(){
		var self = this;
		if(!this.model){
			return;
		}
		function cleanViewData(self){
			if(self.values){
				self.values.length = 0;
			} 
			self.datasets = null;
			if(self.xAxisSelected){
				self.xAxisSelected.length = 0;	
			}
			window.cleanViewModel(self);
		}
		if(self.dataTable){//destroy previously created datatable
			self.dataTable.destroy(true);
		}
		$.each(Highcharts.charts, function(i, chart){
        	if(chart != undefined){
        		chart.destroy();
        	}
        });
        Highcharts.charts.length = 0;
		if(self.plot){
			//self.plot.shutdown();
		}
		$("#xaxis"+self.encodeId).jstree('destroy');
		$(window,".resize").unbind('resize');
		//$('#splitDiv').unbind('splitter.resize');
		var email = DataManager.getDataManager().get('email');
		if(this.savedPreference === true || this.savedPreference === undefined || !email || email === ""){
			this.savedPreference = true;
			cleanViewData(self);
			return;
		}
		this.savedPreference = true;
		var rep = DataManager.getDataManager().getRepositoryId(this.model.get('id'));
		if (self.chartDocument) {
		    self.chartDocument.set('configuration', JSON.stringify(this.getPreferenceValueToStore()));
		} else {
		    UserPreferences.setUserPreference(rep, 'PlanValuesChart', this.model.get('id'), JSON.stringify(this.getPreferenceValueToStore()));
		}
		self.chartDocument = null;
		cleanViewData(self);
	};
	
	
	getPreferenceValueToStore() {
	    var self = this;
		var preference = {};
		preference.xAxisSelected = this.xAxisSelected;
		var selectedValues = $("#yaxis" + self.encodeId + " option:selected");
		preference.selectedValues = [];
		_.each(selectedValues,function(valueOption){
			preference.selectedValues.push(valueOption.value);
		});
		preference.selectedChartType = self.selectedChartType();
		preference.selectedTheme = self.selectedTheme();
		//preference.yaxis1Value = $("#yaxis1").val();
		//preference.yaxis2Value = $("#yaxis2").val();
		return preference;
	};
	getPlanValues(plan){
		var self = this;
		var vp = plan.get('planCriterionSet');
		if(vp){
			var components = vp.get('component');
			components.each(function(valueComponent){
				var value = valueComponent.get('valueMeasurement');
				self.values.push(value);
			});
		}
		return self.values;
	};
	

	initialize(plan,refresh,parentView,chartDocument,chartConfig){
		var self = this;
		self.parentView = parentView;
		self.savedPreference = false;
		if(!self.model){
			self.model = plan;
		}
		var preference;
		var xAxisData;
		var instance;
		if (chartDocument) {
		    self.chartDocument = chartDocument;
		}
		var selectedChartType = self.selectedChartType();
		if (!refresh) {
		    if (self.chartDocument && chartConfig) {
		        preference = JSON.parse(chartConfig);
		    } else {
				preference = window.getSavedPreference(self.model.get('id'), null, 'PlanValuesChart');
		    }
		    if(preference && preference.selectedTheme){
		    	self.selectedTheme(preference.selectedTheme);
		    }
		    // var container = $("#chart-container" + self.encodeId);
		    // container.resizable({
		    //     handles: 'n, s',
		    //     resize: function () {
		    //         self.plotAccordingToChoices();
		    //     }
		    // })
		}else{
			instance = $.jstree.reference("#xaxis" + self.encodeId);	
		}
		xAxisData = self.getXAxisData(preference,refresh);
		var yaxisNode = $("#yaxis" + self.encodeId);
		//var yaxis1Node = $("#yaxis1");
		//var yaxis2Node = $("#yaxis2");
		var xaxisNode = $("#xaxis" + self.encodeId);
		var reDraw = false;
		//self.showBars = false;
		self.showBars = selectedChartType === "Bars" || selectedChartType === "Stacked bars" || selectedChartType === "Columns" || selectedChartType === "Stacked columns"? true : false;
		self.maxUnitsPermitted = selectedChartType === "Stacked bars" || selectedChartType === "Stacked columns"? 1: 2;
		self.showRadar(false);
		var currentPlan = DataManager.getDataManager().get('currentPlan');
		var phase = currentPlan.get('phase').findWhere({nextPhase:null});
		var phasesCount =0;
		while(phase){
			var alts = phase.get('phaseAlternative');
			for(var i=0;i<alts.length;i++){
				var alt = alts.at(i);
				if(preference && (_.indexOf(preference.xAxisSelected,alt.get('id')) >= 0)){
					phasesCount++;
					break;
				}
				if(!preference){
					if(refresh && instance){
						if(instance.is_selected(alt.get('id'))){
							phasesCount++;
							break;
						}
					}else{
						phasesCount++;	
						break;
					}
				}				
			}
			phase = phase.get('previousPhase');
		}
		/*if(phasesCount <=1){
			self.showBars = true;
		    //self.showRadar(false);
		}*/
		this.alts = self.getAlternatives();
		if(refresh){
			if(self.plot){
	            /*self.plot.resize();
	            self.plot.setupGrid();
	            self.plot.draw();*/			
			}			
		}else{
			$("#chartTooltip").css({
				position: "absolute",
				display: "none",
				border: "1px solid #fdd",
				padding: "2px",
				"background-color": "#fee",
				opacity: 0.80
			});		
			xaxisNode.jstree({
			  'core' : {'data' : xAxisData,'themes':{"icons":false,"dots":false}},
			  "checkbox" : {
			    "three_state" : true
			  },		
			"plugins" : [ "wholerow", "checkbox" ]
			}).on("changed.jstree", function (e, data) {
				if(reDraw === true){
					self.xAxisSelected = data.selected;
					showValues();
					reDraw = false;				
				}else{
					self.xAxisSelected = data.selected;
				}
			}).on("select_node.jstree", function (e, data) {
				var currentNode = data.node.id;
				var parentNode = data.node.parent;
				if(parentNode === '#'){
					//self.showBars = true;
					self.showRadar(false);
					var phases = self.model.get('phase');
					var deSelectedNodes = 0;
					phases.each(function(phase){
						if(phase.get('id') !== currentNode){
							data.instance.deselect_node(phase.get('id'));
							deSelectedNodes++;
							var phaseAlts = phase.get('phaseAlternative');
							phaseAlts.each(function(alt){
								data.instance.deselect_node(alt.get('id'));
							});
						}
					});
					if(deSelectedNodes >= 0){
						reDraw = true;
					}
				} else {
					var parentPhase = self.model.get('phase').get(parentNode);
					if (parentPhase) {
					    var children = data.instance.get_node(parentNode).children;
					    _.each(children, function (childNode) {
					        if (childNode !== currentNode) {
					            data.instance.deselect_node(childNode);
					        }
					    });
						reDraw = true;
					}
					var phases = self.model.get('phase');
					phases.each(function(phase){
						var selectedNodes = 0;
						if (phase.get('id') !== parentPhase) {

						    var children = data.instance.get_node(phase.get('id')).children;
						    _.each(children, function (childNode) {
						        if (data.instance.is_selected(childNode)) {
						            if (selectedNodes > 0) {
						                data.instance.deselect_node(childNode);
						            }
						            selectedNodes++;
						            //self.showBars = false;
						        }
						    });
						}
						if (selectedNodes > 2) {
						    self.showRadar(true);
                        }
					});
					
					
				}
			}).on("deselect_node.jstree", function (e, data) {
				reDraw = true;
			}).on("open_node.jstree", function (e, data) {
				if(self.parentView && self.parentView.setResizeHeight && $("#PlanValues").hasClass('active')){
					self.parentView.setResizeHeight();
				}
			}).on("close_node.jstree", function (e, data) {
				if(self.parentView && self.parentView.setResizeHeight){
					self.parentView.setResizeHeight();
				}
			});			
			if(self.parentView && self.parentView.setResizeHeight){
				self.parentView.setResizeHeight();
			}					
		}					
		if(refresh){
			this.values = [];
		}
		self.getPlanValues(self.model);
		showValues(preference,!refresh);
		
		function showValues(preference,initializeMultiselect){

			self.getValueMeasurements(self.alts,function(){
				
				if(initializeMultiselect){
					self.refreshValueMeasurements = false;
					$('#yaxis' + self.encodeId).multiselect({
		            	enableClickableOptGroups: false,
						numberDisplayed : 0,
		            	//includeSelectAllOption: true,
		            	onDropdownShow:function(){
							$('.multiselect-container').css({ 'left': "auto", 'right': "0"  });
		            		if(self.plot){
		            		    $("#placeholder" + self.encodeId).css('min-width', '10px');
					            /*self.plot.resize();
					            self.plot.setupGrid();
					            self.plot.draw();*/
		            		}
		            	},
						optionClass: function () {
							return 'chartLiWidth';
						},
						onDropdownShown : function(){
							var newHeight =  $('#Progress').height() + $('.multiselect-container.dropdown-menu').height();
							$('#Progress').css('height', newHeight);
							if(self.parentView && self.parentView.setResizeHeight){
								self.parentView.setResizeHeight();
							}
						},
						onDropdownHidden : function(){
							var newHeight =  $('#Progress').height() - $('.multiselect-container.dropdown-menu').height();
							$('#Progress').css('height', "auto");
							if(self.parentView && self.parentView.setResizeHeight){
								self.parentView.setResizeHeight();
							}
						},
		            	onChange: function(option, checked) {
		            		if(self.refreshValueMeasurements){
		            			self.refreshValueMeasurements = false;
			            		self.getValueMeasurements(self.alts,function(){
									self.plotValues();
									self.plotAccordingToChoices();
							    });
		            		}else{
		            			self.plotValues();
		            			self.plotAccordingToChoices();
		            			var newHeight = $('#Progress').height() + $('div#Progress div#legend' + self.encodeId).height();
		            			if(checked) {
		            				newHeight = newHeight + 5;
		            			}else {
		            				newHeight = newHeight - 5;
		            			}	
								$('#Progress').css('height', newHeight);
								if(self.parentView && self.parentView.setResizeHeight){
									self.parentView.setResizeHeight(true);
								}
		            		}
						}
		        	});
		        	/*self.selectedChartType.subscribe = function (newValue) {
	                    self.plotValues();
	                    self.plotAccordingToChoices();
                    }*/
				}else{
					self.refreshValueMeasurements = true;
				}				
				var i = 0;
				$.each(self.datasets, function(key, val) {
					val.color = i;
					++i;
				});
				// insert checkboxes 
				//var oldYAxis1Val = yaxis1Node.val();
				//var oldYAxis2Val = yaxis2Node.val();
				var selectedValues = $("#yaxis" + self.encodeId + " option:selected");
				var oldSelectedValues = [];
				_.each(selectedValues,function(valueOption){
					oldSelectedValues.push({id:valueOption.attributes['valSuffix'].value,value:valueOption.value});
				});
		
				yaxisNode.empty();
				//yaxis1Node.empty();
				//yaxis2Node.empty();
				var count =0;
				var count1Key;
				//yaxis2Node.append("<option value=''>Empty</option>");
				//sorting object key value pair
				var values = [];
				$.each(self.datasets, function(key, val) {
					values.push({'key':key, 'value':val});	
				});
				
				values.sort(function(a,b){
					if(a.value.valueName.toLowerCase() < b.value.valueName.toLowerCase()){
						return -1;
					}
					else if(a.value.valueName.toLowerCase() > b.value.valueName.toLowerCase()){
						return 1;
					}
					return 0;					
				});
				/*values.sort(function(a,b){
					if(a.value.unit.toLowerCase() < b.value.unit.toLowerCase()){
						return -1;
					}
					else if(a.value.unit.toLowerCase() > b.value.unit.toLowerCase()){
						return 1;
					}
					return 0;					
				});*/
				self.datasets = {};
                for(var i=0; i < values.length; i++){
                	 self.datasets[values[i].key] = values[i].value;
                }
				$.each(self.datasets, function(key, val) {
					var showValue = false;
					for(var i=0;i<val.data.length;i++){
						if(self.showBars && val.data[i][0].indexOf('Ph') > -1){
							var replacedString = val.data[i][0].replace('Ph','Alt');
							val.data[i][0] = replacedString;
						}
						if(val.data[i][1] != null){
							showValue = true;
							break;
						}
					}
					if(showValue) {
                        /*if(yaxis1Node.has(">option[value='" + val.unit + "']").length === 0){
							yaxis1Node.append("<option"+ " value='" +  val.unit +"'>"+ val.unit + "</option>");	
							yaxis2Node.append("<option"+ " value='" +  val.unit +"'>"+ val.unit + "</option>");	
						}*/
                        //TODO
					    var multiselectNode = $('#yaxis' + self.encodeId);
					    var unitString = window.utils.restoreEscapeCharacters(val.unit);
						var unitOptionGroupNode = multiselectNode.children('optgroup[label="' + unitString + '"]');
						var optionGroupNode;
						if(unitOptionGroupNode.length === 0){
							multiselectNode.append('<optgroup'+ ' label="' +  unitString + '"></optgroup>');
							unitOptionGroupNode = multiselectNode.children('optgroup[label="' + unitString + '"]');
						}
						optionGroupNode = unitOptionGroupNode.first();
						var label = window.utils.restoreEscapeCharacters(val.label);
						var valSuffix = window.utils.restoreEscapeCharacters(key.substr(key.lastIndexOf('@') + 1));
						valSuffix = valSuffix.substr(valSuffix.lastIndexOf('#') + 1);
						if(optionGroupNode.has('>option[value="' + label + '"]').length === 0){
							optionGroupNode.append('<option'+ ' value="' +  label + '"' + ' valSuffix = "' + valSuffix +'" label="' +  val.label +'">'+ val.label + '</option>');	
						}
						for(var i=0;i<oldSelectedValues.length;i++){
						    if (oldSelectedValues[i].id === valSuffix) {
						        oldSelectedValues[i].value = label;
						        break;
							}
						}
						if(count === 1){
							count1Key = key;
						}
						count++;
					}
				});
				$('#yaxis' + self.encodeId).multiselect('rebuild');
                /*yaxis1Node.on('change',plotAccordingToChoices);		
				
				if(count <= 1){
					yaxis2Node.parent().hide();
				}else{
					yaxis2Node.parent().show();
					yaxis2Node.val(count1Key);
					yaxis2Node.on('change',plotAccordingToChoices);			
				}*/
				if(preference && preference.selectedValues && preference.selectedValues.length > 0){
					for(var i=0;i<preference.selectedValues.length;i++){
						var label = window.utils.restoreEscapeCharacters(preference.selectedValues[i]);
					    if ($('#yaxis' + self.encodeId + ' option[value="' + label + '"]').length > 0) {
					        $('#yaxis' + self.encodeId).multiselect('select', label);
						}						
					}
				}else if(oldSelectedValues.length > 0){
					for(var i=0;i<oldSelectedValues.length;i++){
					    if ($('#yaxis' + self.encodeId + ' option[valSuffix="' + oldSelectedValues[i].id + '"]').length > 0) {
					        $('#yaxis' + self.encodeId).multiselect('select', oldSelectedValues[i].value);
						}
					}					
				}else if(preference && preference.selectedValues && preference.selectedValues.length === 0){
					var defaultUnitsArray = [];
					var units = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input");
					if(units.length>0){
						defaultUnitsArray.push(units[0].value);
					}
					if(units.length>1 && self.maxUnitsPermitted !== 1){
						defaultUnitsArray.push(units[1].value);
					}
					preference.selectedValues = defaultUnitsArray;
					for(var i=0;i<preference.selectedValues.length;i++){
						var label = window.utils.restoreEscapeCharacters(preference.selectedValues[i]);
					    if ($('#yaxis' + self.encodeId + ' option[value="' + label + '"]').length > 0) {
					        $('#yaxis' + self.encodeId).multiselect('select', label);
						}						
					}
				}
				if(preference && preference.selectedChartType){
                	self.selectedChartType(preference.selectedChartType);
                }
				self.plotValues();
				self.plotAccordingToChoices();
			});
			
			$('#dropdown' + self.encodeId).off('click').on('click', function(e){
				$("#dropdownMenu" + self.encodeId).toggle();
			});
			$('#splitPanels').unbind('click');
			$('#splitPanels').on('click', function (e) {
			    if (!e.target.closest('.dropdown-menu-right') && !(e.target.id ==='dropdown'+self.encodeId) && !e.target.closest('.ui-datepicker-header') && !e.target.closest('.ui-datepicker')){
			        $(".dropdown-menu-right").hide();
			    }
			});
		}
     };
	plotValues() {
		var self = this;
		var group1 = "";
		var group2 = "";
		var selectedOptions = $('#yaxis' + self.encodeId + ' option:selected');
		selectedOptions.each(function() {
			if(group1 == ""){
					group1 = $(this).parent()[0].label;
			}
			else if(group2 == "" && $(this).parent()[0].label !== group1 && $(this).parent()[0].label != 'Grade only'){
				if(self.selectedChartType() === "Stacked bars" || self.selectedChartType() === "Stacked columns"){
					//unselect group2 if selected
					//$('#yaxis' + self.encodeId).multiselect('deselect', $(this)[0].label);
				}else{
					group2 = $(this).parent()[0].label;
				}
			}
		    if(self.isSatisfactionOnly($(this).parent()[0].label)){
		    	var input = $('input[value="' + $(this).val() + '"]');
				/*input.prop('checked', false);
				$(this).removeAttr('selected');
				input.closest('li').removeClass('active');*/
				input.parent().css({'cursor' :"no-drop"});
				if (self.selectedChartType() === 'Table') {
					input.closest('li').addClass('active');
				}else {
					input.closest('li').removeClass('active');
				}
				//$('#yaxis' + self.encodeId).multiselect('updateButtonText');
			}
		    
		});
		if (selectedOptions.length > 2) {
		    self.showRadar(true);
        } else {
		    self.showRadar(false);
		    if (self.selectedChartType() === 'Radar') {
		        self.selectedChartType('Curves');
		    }
        }
		
		function disableCheckBoxes(nonSelectedGroups){
        	nonSelectedGroups.each(function() {
				var children = $(this).children();
				children.each(function() {
						var input = $('input[value="' + $(this).val() + '"]');
						input.prop('disabled', true);
						input.parent().css({'cursor' :"no-drop"});
						input.parent('li').addClass('disabled');
				 });
			});
		}
		
		//if two groups selected
		if (group1 !== "" && group2 !== "" && (self.selectedChartType() === 'Curves' || self.selectedChartType() === 'Bars' || self.selectedChartType() === 'Columns')) {
	        var allgroups = $('#yaxis' + self.encodeId + ' optgroup');
							    	
			//filtering other groups for disabling childs
	        var nonSelectedGroups = $('#yaxis' + self.encodeId + ' optgroup').filter(function () {
					return !(($(this)[0].label == group1) || ($(this)[0].label == group2));
				});
							        
			//disabling childs
			disableCheckBoxes(nonSelectedGroups);
		}
		else if(group1 !== "" && (self.selectedChartType() === 'Stacked bars' || self.selectedChartType() === 'Stacked columns' )){
			//disable other checkboxes
			var nonSelectedGroups = $('#yaxis' + self.encodeId + ' optgroup').filter(function () {
				return !($(this)[0].label == group1);
			});
			disableCheckBoxes(nonSelectedGroups);
		}
		else {
				 // Enable all checkboxes.
	        var dropdown = $('#yaxis' + self.encodeId).siblings('.multiselect-container');
	        $('#yaxis' + self.encodeId + ' option').each(function () {
					var input = $('input[value="' + $(this).val() + '"]');
					if(self.isSatisfactionOnly($(this).closest('optgroup').attr('label'))){
						input.prop('disabled', true);
						input.parent().css({'cursor' :"no-drop"});
					}else {
						input.prop('disabled', false);
						input.parent().css({'cursor' :"pointer"});
						input.parent('li').addClass('disabled');
					}
				});
			}						    	
	};
	
	isSatisfactionOnly(valParentName) {
		var self = this;
		if(self.selectedChartType() !== 'Table' && valParentName === 'Grade only'/*valName.indexOf('SatisfactionOnly') > -1*/){
			return true;
		}else {
			return false;
		}	
	};	
	
	plotFlotChart() {
		var self = this;
		var selectedChartType = self.selectedChartType();
		var data = [];
		var yaxis = 1;
		var legendNode = $('#legend' + self.encodeId);
		legendNode.show();
		self.legendColumnCount = 1;
		if(self.datasets && Object.keys(self.datasets).length > 10){
			self.legendColumnCount = 2;
		}
		/*var options = {
			legend:{
				show:true,
				noColumns:self.legendColumnCount,
				container:legendNode,
				sorted:function(a,b){
					var aLabel,aUnit;
					var bLabel,bUnit;
					aLabel = a.label.substr(0,a.label.indexOf('('));
					aUnit = a.label.substr(aLabel.length);
					bLabel = b.label.substr(0,b.label.indexOf('('));
					bUnit = b.label.substr(bLabel.length);
					if(aUnit === bUnit){
						if(aLabel < bLabel){
							return -1;
						}
						if(aLabel > bLabel){
							return 1;
						}
						return 0;
					}
					if(aUnit < bUnit){
						return -1;
					}
					if(aUnit > bUnit){
						return 1;
					}
					return 0;
				}							
			},
		    series: {
		        lines: { show: true,lineWidth: 2 },
		        points: { show: true,lineWidth: 2 }
		    },
			xaxis: {
				mode: "categories",
				tickLength: 0,
				//rotateTicks: 140,
				autoscaleMargin: self.margin
			},
			yaxes:[{},{position:'right',alignTicksWithAxis:self.alignAxis}],
			grid: {
			    hoverable: true
			},
			colors: self.chartColors
		};
        if(self.showBars){
			delete options.series;
			$.each(self.datasets, function(key, val) {
				val.bars = {show: true,barWidth:0.2,fill:true,lineWidth:1,align:'center'};
			});					
		}*/
		var yaxis1Val;// = yaxis1Node.val();
		var yaxis2Val;// = yaxis2Node.val();
		var selectedUnits = $("#yaxis" + self.encodeId + " option:selected").parent();
		var units = [];
		for(var i=0;i<selectedUnits.length;i++){
			if(selectedUnits[i].label !== 'Grade only'){
				units.push(selectedUnits[i]);
			}			
		}
		if(units.length > 0){
		    yaxis1Val =  units[0].label;
		}
		if(units.length > 1 && self.maxUnitsPermitted !== 1){
		    yaxis2Val =  units[1].label;
		}					
		if(yaxis2Val === yaxis1Val){
			yaxis2Val = null;
		}

		var values = Object.getOwnPropertyNames(self.datasets);
		var color = 0;
        for(var i=0;i<values.length;i++){
			if (values[i].indexOf('Grade only') != -1 && self.selectedChartType() !== 'Table') {
	            continue;
	        }
			var valueData = self.datasets[values[i]];
			var label = window.utils.restoreEscapeCharacters(valueData.label);
			if( $('#yaxis'+ self.encodeId + ' option:selected[value="' + label +'"]').length===0){
				//var unit = $("#yaxis option:selected[value='" + valueData.label +"']").parent()[0].label;
				continue;
			}
			var unit = window.utils.restoreEscapeCharacters(valueData.unit);
			valueData.yAxis = undefined;
			if (selectedChartType !== 'Radar' && selectedChartType !== 'Table' && selectedChartType !== 'Polar' && selectedChartType !== 'Spiderweb') {
			    if (yaxis1Val && yaxis1Val === unit) {
			        //valueData.yaxis = 1;
			        valueData.color = color++;
			        data.push(valueData);

			    }
			    if (yaxis2Val && yaxis2Val === unit) {
			        valueData.yAxis = 1;
			        valueData.color = color++;
			        data.push(valueData);
			    }
			} else {
			    data.push(valueData);
			}
		}

							
		if (data.length > 0) {
		    var placeholder = $("#placeholder" + self.encodeId);
			data.sort(function(a,b){
				if(a.yaxis === b.yaxis){
					if(a.label < b.label){
						return -1;
					}
					if(a.label > b.label){
						return 1;
					}
					return 0;
				}
				if(a.yaxis < b.yaxis){
					return -1;
				}
				if(a.yaxis > b.yaxis){
					return 1;
				}
				return 0;
			});
			$('#altLegend' + self.encodeId).show().empty();
			var altIndexes = Object.getOwnPropertyNames(self.altIndex);
			_.each(altIndexes,function(key){
				var altIndexVal = self.altIndex[key];
				//if(self.showBars){
				    $('#altLegend' + self.encodeId).append($("<div>Alt-" + altIndexVal + " : " + key + "</div>"));
				/*}else{
				    $('#altLegend' + self.encodeId).append($("<div>Ph-" + altIndexVal + " : " + key + "</div>"));
				}*/
				
			});
			/*if (!self.showBars && self.selectedChartType() !== 'Table') {
		        var checked = self.alignAxis > 1;
		        $('#altLegend' + self.encodeId).append($("<div><input id='alignMargin' type='checkbox'> Align Scales</div>"));
		        $('#alignMargin').attr('checked', !checked);
		    }*/

			if (self.selectedChartType() === 'Table') {
			    self.displayTable(data);
			    $('#selectTheme'+self.encodeId).hide();
			} else {
			    /*$('#alignMargin').click(function (view, event) {
		            if (view.target.checked) {
		                self.alignAxis = 1;
		                self.plotAccordingToChoices();
		            } else {
		                self.alignAxis = 2;
		                self.plotAccordingToChoices();
		            }
		        });*/

				$('#selectTheme'+self.encodeId).show();

			    var unit1 = undefined, unit2 = undefined, chartData = [];
			    //var selectedCount = self.alts.length;

		        _.each(data, function(val, i){
		    		var newDataset = {};
		    		newDataset.name = val.label;
	    			newDataset.tooltip = {
		               headerFormat: '<b>'+val.label+'</b><br>',
		               pointFormat: "Value: {point.y:."+val.significantDecimals+"f} ("+val.unit + ')'
		            };
		    		if(val.yAxis === 1){
		    			unit2 = val.unit;
		    			newDataset.yAxis = val.yAxis;
		    		}else{
		    			unit1 = val.unit;
		    		}
		    		var newDataArray = [];
		    		//var altOrScenarioArray = [];
		    		_.each(val.data, function(valData){
		    			var newData = [];
		    			newData.push(valData[0]);
		    			//altOrScenarioArray.push(valData[0]);
						newData.push(valData[1] === "-"? null : valData[1]);
		    			newDataArray.push(newData);
		    		});
		    		/*if(selectedCount){
		    			var count = selectedCount;
		    			while(count > 0){
	    					var altName = "Alt-"+(count-1);
	    					if(altOrScenarioArray.indexOf(altName) === -1){
	    						var newData = [];
				    			newData.push(altName);
				    			newData.push(null);
				    			newDataArray.push(newData);
	    					}
		    				count--;
		    			}
		    		}*/
		    		newDataArray.sort();
		    		newDataset.data = newDataArray;
		    		chartData.push(newDataset);
		    	});

		    	var chartType;
		    	if(selectedChartType === "Bars" || selectedChartType === "Stacked bars"){
		    		chartType = 'bar';
		    	}else if(selectedChartType === "Columns" || selectedChartType === "Stacked columns"){
		    		chartType = 'column';
		    	}else if(selectedChartType === "Curves"){
		    		chartType = 'spline';
		    	}
		    	var xAxisObj = {
			    	tickInterval : 1,
			        labels: {
			        	style :{
			            		fontSize: '12px'
			            	},
			            enabled: true,
			            formatter: function(){ 
							var colName = chartData[0].data[this.value]? chartData[0].data[this.value][0] : null;
							if(!colName && chartData[i]){
								for(var i=1;i<chartData.length;i++){
									colName = chartData[i].data[this.value]? chartData[i].data[this.value][0] : null;
									if(colName){
										break;
									}
								}
							}
							return colName?colName:'';
						},
			        },
			        minRange:1
			    }
		    	var yaxesObj;
		    	if(units.length > 1){
				    yaxesObj = [{
				    	title: {
				            text: unit1
				        },
				    	labels: {
				    		style :{
			            		fontSize: '12px'
			            	},
				            format: '{value}'
				        },
        				gridLineWidth: 1

				    },
				    {
				    	title: {
				            text: unit2
				        },
				    	labels: {
						    style :{
			            		fontSize: '12px'
			            	},
				            format: '{value}'
				        },
    					opposite: true
				    }]
				}else{
					yaxesObj = {
				    	title: {
				            text: unit1
				        },
				    	labels: {
				    		style :{
			            		fontSize: '12px'
			            	},
				            format: '{value}'
				        },
        				gridLineWidth: 1
				    }
				}
				var container = $("#chart" + self.encodeId);


				self.plot = new Highcharts.Chart({
		    		colors: self.chartColors,
					chart: {
                        type: chartType,
                        zoomEnabled: true,
                        renderTo: "placeholder"  + self.encodeId,
						zoomType: 'xy',
                        resetZoomButton: {
                            position: {
                                align: 'right', // by default
                                verticalAlign: 'top' // by default
                            }
                        },
					events:{
					load:function () {
					this.series.map((d)=>{
						self.disabledValues.map((val)=>{
							if(d.name===val){
								d.update({
									visible: false
								});
							}
						})
					})
						const updateChartHeight = () => {
							const newHeight = 300 + this.series.length * 50;
							document.getElementById(("chart-container" + self.encodeId)).style.height = newHeight+"px"
						};
						updateChartHeight();
						}
					}
                    },
		    		title: {
					    text: '',
					    style: {
					        display: 'none'
					    }
					},
					credits: {
				        enabled: false
				    },
					subtitle: {
					    text: '',
					    style: {
					        display: 'none'
					    }
					},
					legend: {
				        itemStyle: {
				            fontWeight: 'normal'
				        }
				    },
				    yAxis: yaxesObj,
				    xAxis: xAxisObj,
				    plotOptions: {
				        series: {
						    label: {
				                connectorAllowed: false
				            },
							events:{
								legendItemClick:function(e){
									if(!self.disabledValues.includes(e.target.name)){
										self.disabledValues.push(e.target.name)
									}else {
										const index = self.disabledValues.indexOf(e.target.name);
										if (index > -1) {
											self.disabledValues.splice(index, 1);
										}
									}
								},
								
							},
							
							// pointWidth: 40,
							pointPadding: 0,
							groupPadding: 0,
							borderWidth: 0,
				            stacking: selectedChartType === "Stacked bars" || selectedChartType === "Stacked columns"? 'normal' :  null
				        },
						
				    },

				    series: chartData,


				    responsive: {
				        rules: [{
				            condition: {
				                maxWidth: 500
				            },
				            chartOptions: {
				                legend: {
				                    layout: 'horizontal',
				                    align: 'center',
				                    verticalAlign: 'bottom'
				                }
				            }
				        }]
				    }
                });
			}
			
			data.length = 0;
		}else{
		    var placeholder = $("#placeholder" + self.encodeId);
		    
			self.plot = new Highcharts.Chart({
			            colors: self.chartColors,
						chart :{
							type : chartType,
                            zoomEnabled: true,
							renderTo : "placeholder" + self.encodeId,
							zoomType: 'xy',
							resetZoomButton: {
								position: {
									align: 'right', // by default
									verticalAlign: 'top' // by default
								}
							}
						},
						title: {
					        text: '',
						    style: {
						        display: 'none'
						    }
						},
						subtitle: {
						    text: '',
						    style: {
						        display: 'none'
						    }
						},
						credits: {
					        enabled: false
					    },
						series:[]
			});
		    $('#altLegend' + self.encodeId).empty().hide();
		}
		
		/*$('#splitDiv').unbind('splitter.resize');
		$('#splitDiv').bind('splitter.resize',handleResize);						
		$(window).resize(handleResize);
		function handleResize(){
			$("#placeholder").css('min-width','10px');
            self.plot.resize();
            self.plot.setupGrid();
            self.plot.draw();
            $('.legend-container').width($('.chart-container').width());
		}*/						
	};

	plotPolarChart() {
	    var self = this;
	    $('#altLegend' + self.encodeId).show().empty();
	    var altIndexes = Object.getOwnPropertyNames(self.altIndex);
	    _.each(altIndexes, function (key) {
	        var altIndexVal = self.altIndex[key];
            $('#altLegend' + self.encodeId).append($("<div>Alt-" + altIndexVal + " : " + key + "</div>"));
	    });	
        var values = Object.getOwnPropertyNames(self.datasets);

        var selectedValueList = [], polarData = [], categoriesArray = [];//creating new data to populate charts

        //var selectedCount = self.alts.length;
	    async.each(altIndexes, function (key, keyHandled) {
	    	var newDataset = {};
	    	var newDataArray = [];
	    	var altIndexVal = self.altIndex[key];
            newDataset.name = "Alt-" + altIndexVal;
         	newDataset.pointPlacement = 'on';
	    	async.each(values, function (value, valueHandled) {
	    		var dataset = self.datasets[value];
	    		var valueSuffix = window.utils.getSuffix(value);
	    		var val = undefined;
	    		async.each(dataset.data, function(valData, dataHandled){
	    			if(valData[0] === newDataset.name){
		    			val = valData[1] === "-"? null : valData[1];
		    			var valueIndex =_.filter(selectedValueList,function(obj){return obj.id === valueSuffix;});
		                if(valueIndex && valueIndex[0]){
		                	valueIndex[0].value.push(val);
		                }else {
		                	selectedValueList.push({'id':valueSuffix,value:[val],valId:dataset.valId});
		                }
		    			dataHandled();
		    			return;
	    			}else{
	    				dataHandled();
	    			}
	    		},function(){
	    			var valData = {};
	    			valData['valSuffix'] = valueSuffix;
	    			if(val != undefined){
	    				valData['value'] = val;
	    			}else{
	    				valData['value'] = null;
	    			}
	    			var selectedOptions = $('#yaxis' + self.encodeId + ' option:selected');
	    			var selected = false;
	    			for (var i = 0; i < selectedOptions.length; i++){
	    				if(selectedOptions[i].attributes['valSuffix'].value === valData.valSuffix){
	    					selected = true;
	    					break;
	    				}
	    			}
    				var indivisualDataWithTooltip = {};
	    			indivisualDataWithTooltip['y'] = valData;
	    			indivisualDataWithTooltip['tooltip'] = window.utils.getRoundedValues(valData.value, dataset.significantDecimals,true);
	    			if(selected){
		    			newDataArray.push(indivisualDataWithTooltip);
		    			categoriesArray.push(dataset.label);
		    			categoriesArray = _.uniq(categoriesArray);
	    			}
	    			valueHandled();
	    		})
	    	}, function () {
	    		newDataset.data = newDataArray;
	    		polarData.push(newDataset);
	    		keyHandled();
	    	})
	    }, function () {
	    	for(var m=0;m<selectedValueList.length;m++){
	    		var min = Math.min.apply(Math,selectedValueList[m].value.map(function(o){return o;}));
		    	if(min < 0){
		    		selectedValueList[m].value = selectedValueList[m].value.map(function(o){
		    			return o = o - min;
		    		});
		    	}
		    	var max = Math.max.apply(Math,selectedValueList[m].value.map(function(o){return o;}));	    	
			    for(var i=0;i<polarData.length;i++){
			    	var valueList = polarData[i].data;
			    	var valueListIndex =_.filter(valueList,function(obj){return obj['y'] && obj['y'].valSuffix === selectedValueList[m].id;});
			    	if(min < 0 && valueListIndex && valueListIndex[0] && valueListIndex[0]['y'].value !== selectedValueList[m].value[i]){
			    		//Replacing polardata values when selectedvaluelist is changed
			    		valueListIndex[0]['y'].value = selectedValueList[m].value[i];
			    	}
			    	if(valueListIndex && valueListIndex[0] && valueListIndex[0]['y']){
			    		var oldValue = valueListIndex[0]['y'].value;
			    		if(max == 0 || isNaN(max)){
			    			max = 1;
			    		}
			    		if(oldValue !== '' || !isNaN(oldValue) || !isNaN(max)){
			    			var valuePercentage = oldValue/max * 100;
			    			if(isNaN(valuePercentage)){
			    				valuePercentage = 0
			    			}
				    		if(valuePercentage < -100){
				    			valuePercentage = -100;
				    		}
				    		var index = valueList.map(function (val) { return val['y'] && val['y'].valSuffix ; }).indexOf(selectedValueList[m].id);
				    		valueList[index]['y'] = window.utils.getRoundedValues(valuePercentage, 2,true);
			    		}
			    	}
			    }
			}
	    	var polarChartType;
	    	if(self.selectedChartType() === "Polar"){
	    		polarChartType = 'area';
	    	}else if(self.selectedChartType() === "Spiderweb"){
	    		polarChartType = 'line';
	    	}
	        new Highcharts.Chart({
			    colors: self.chartColors,
				chart: {
				        polar : true,
                        type: polarChartType,
                        zoomEnabled: true,
                        renderTo: "placeholder"  + self.encodeId,
						zoomType: 'xy',
                        resetZoomButton: {
                            position: {
                                align: 'right', // by default
                                verticalAlign: 'top' // by default
                            }
                        },
						events:{
							load:function () {
							this.series.map((d)=>{
								self.disabledValues.map((val)=>{
									if(d.name===val){
										d.update({
											visible: false
										});
									}
								})
							})
							const updateChartHeight = () => {
								const newHeight = 300 + this.series.length * 50;
								document.getElementById(("chart-container" + self.encodeId)).style.height = newHeight+"px"
							};
							updateChartHeight();
							}
						}
                },
			    title: {
				    text: '',
				    style: {
				        display: 'none'
				    }
				},
				credits: {
			        enabled: false
			    },
				legend: {
			        itemStyle: {
			            color: '#000000',
			            fontWeight: 'normal'
			        }
			    },
				subtitle: {
				    text: '',
			    style: {
				        display: 'none'
				    }
				},
			    pane: {
			        size: '70%'
			    },
			    responsive: {
			        rules: [{
			            condition: {
			                maxWidth: 500
			            },
			            chartOptions: {
			                legend: {
			                    layout: 'horizontal',
			                    align: 'center',
			                    verticalAlign: 'bottom'
			                }
			            }
			        }]
			    },

			    xAxis: {
			    	categories: categoriesArray,
			        tickmarkPlacement: 'on',
			        labels: {
			            enabled: true,
		            	style :{
		            		fontSize: '12px'
		            	},
			            labels: {
			                align: 'center',
			                distance: 43
			            }
			        },
			        lineWidth: 0
			    },

			    yAxis: {
			    	gridLineInterpolation: 'polygon',
			    	lineWidth: 0,
			        endOnTick: true,
            		showLastLabel: true,
            		labels: {
            			style :{
			            	fontSize: '12px'
			            },
		                enabled: false,
		            },
		            max: 100
			    },tooltip: {
				    formatter: function() {
				        return this.key+'<br>Value: '+this.point.tooltip;
				    }
				},
				plotOptions: {
					series: {
						events:{
							legendItemClick:function(e){
								if(!self.disabledValues.includes(e.target.name)){
									self.disabledValues.push(e.target.name)
								}else {
									const index = self.disabledValues.indexOf(e.target.name);
									if (index > -1) {
										self.disabledValues.splice(index, 1);
									}
								}
							},
							
						},
					},
					
				},
			    series: polarData
			});
		})
	}
	displayTable(data) {
	    var self = this;
	    //self.plot.shutdown();
	    $("#placeholder" + self.encodeId).empty();
	    $('#legend' + self.encodeId).empty().hide();
	    var columnNames = ["Values"];
	    var columns = ["valueName"];
	    if(data && data.length > 0) {
	    	for (var j = 0; j < data.length; j++) {
			    //if(data[j].data.length > columns.length-1){
			    	for (var i = 0; i < data[j].data.length; i++) {
			    		columnNames.push(data[j].data[i][0]);
			        	columns.push(data[j].data[i][0]);
			    	}
			   // }
		    }
			columnNames = _.uniq(columnNames);
			columns = _.uniq(columns);
			var selectedOptions = $('#yaxis' + self.encodeId + ' option:selected');
			selectedOptions.each(function() {
				if($(this).parent()[0].label === 'Grade only'){
			    	var input = $('input[value="' + $(this).val() + '"]');
					/*input.prop('checked', false);
					$(this).removeAttr('selected');
					input.closest('li').removeClass('active');*/
					input.parent().css({'cursor' :"pointer"});
					input.closest('li').addClass('active');
					//$('#yaxis' + self.encodeId).multiselect('updateButtonText');
				}
			    
			});
    	}
    	if(!data || !data.length){
	    	return;
	    }
	    var myTableDiv = $('#placeholder' + self.encodeId);
     
	    var table = document.createElement('TABLE');
	    table.id = 'table' + self.encodeId;
	    table.className="display";
	    //table.className="pageResize";
	    table.cellspacing="0";
	    table.width="100%";
	    
	    var tableHeader = document.createElement('THEAD');
	    table.appendChild(tableHeader);
	    var headerTR = document.createElement('TR');
	    tableHeader.appendChild(headerTR);
	    _.each(columnNames, function(header){
	    	   var th = document.createElement('TH');
	    	   th.appendChild(document.createTextNode(header));           
	           headerTR.appendChild(th);
	    });
	    
	    var tableBody = document.createElement('TBODY');
	    table.appendChild(tableBody);
	    for (var i=0; i<data.length; i++){
	       var tr = document.createElement('TR');
	       tableBody.appendChild(tr);
	       var value = data[i];
	       for (var j=0; j<columns.length; j++){
	       	   var column = columns[j];
               var td = document.createElement('TD');
               if (column === "valueName") {
                   td.align = "left";
	           	td.appendChild(document.createTextNode(value.label));
               } else {
                   td.align = "right";
                   var text = value.data[j - 1] ? window.utils.thousandSeparator(window.utils.getRoundedValues(value.data[j - 1][1], value.significantDecimals,true)) : '-';
                   var a = document.createElement('a');
                   a.appendChild(document.createTextNode(text));
                   if (value.data[j - 1] && value.data[j - 1][5]) { 
                       var valId = value.data[j - 1][5];
                       var altId = value.data[j - 1][4];
                       a.setAttribute('valId', valId);
                       a.setAttribute('altId', altId);
                   }
                    a.style.cursor = "pointer";
                    a.style.color = "inherit";
                    $(a).click(function (event) {
                        var obj = { id: event.currentTarget.getAttribute('valid'), altId: event.currentTarget.getAttribute('altid') };
                        self.showAggregationView(obj);
                    });
                    td.appendChild(a);
		           	if(value.data[j-1] && value.data[j-1][2]){
		           		var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
						svg.setAttribute("width", 20);
						svg.setAttribute("height", 20);
						svg.setAttribute("style",'vertical-align:bottom;');
						svg.setAttribute("viewBox", "-2 -2 27 27");
						svg.setAttribute("fill", ""+value.data[j-1][3]);
						svg.setAttribute("correctiveActions",value.data[j-1][6]);
						svg.style.cursor = 'pointer';
						$(svg).click(function(event){
							var correctiveActions = event.currentTarget.getAttribute('correctiveActions') ? event.currentTarget.getAttribute('correctiveActions') : '';
							var htmlContent = "<div class='row'><div class='col-xs-1'></div><div class='col-xs-10'><span class='control-label' style='word-wrap: break-word;'>"+correctiveActions+"</span></div></div>";
							var box = bootbox.dialog({
								message: htmlContent,
								title: "<div><img class='smallIcon' src='img/icons/icons_15.png'>&nbsp;&nbsp;&nbsp;"+DataManager.getDataManager().get('localeManager').get('CorrectiveActions')+"</div>",
								buttons: {
									cancel : {
										label: "Close",
										className : 'btn vtn-default',
										callback : function(){
											
										}
									}
								}
							});
							box.show();
						});
						var link = document.createElementNS("http://www.w3.org/2000/svg", "path");
						link.setAttribute("d", value.data[j-1][2]);
						svg.appendChild(link);
		           		td.appendChild(svg);		           		
		           	}
		           	
	           }	           
	           tr.appendChild(td);
	       }
	    }
		myTableDiv.append(table);
		self.dataTable = $('#table' + self.encodeId).DataTable({
			"bLengthChange": false,
			"pageResize": true,
			//"sDom": "Rlfrtip"
			"sDom": '<"pull-right"f><"table-container"t><"bottom d-flex"<"pull-left"i><"pull-right"p>>', // Adds search box (f), table (t), pagination (p), and info (i)
    	});	    
	}
	/*plotRadarChart() {
	    var self = this;
	    var selectedOptions = $('#yaxis' + self.encodeId + ' option:selected');
	    var chartContainerNode = $('#chart-container' + self.encodeId);
	    var w = chartContainerNode.width();
	    var h = chartContainerNode.height();
	    var margin = { top: 60, right: 20, bottom: 60, left: 20 },
                        legendPosition = { x: 10, y: 25 },
                        width = Math.min(700, w - 10) - margin.left - margin.right,
                        height = Math.min(width, h - margin.top - margin.bottom - 20);
	
	    var color = d3.scaleOrdinal()
				.range(["#EDC951", "#F08080", "#FFA07A","#4169E1","#98FB98","#EDC951","#87CEFA","#ceaeff","#003366","#0066ff"]);
	    var LegendOptions = [];
	    var values = Object.getOwnPropertyNames(self.datasets);
	    var radarData = [];
	    var noOfAlts = 0;
	
	    for (var j = 0; j < values.length; j++) {
	        if (values[j].indexOf('Grade only') != -1) {
	            continue;
	        }
	        var val = self.datasets[values[j]];
	        for (var k = 0; k < selectedOptions.length; k++) {
                if (selectedOptions[k].label === self.datasets[values[j]].label) {
                    if (val.data && val.data.length > 0 && noOfAlts < val.data.length) {
			            LegendOptions = [];
			            for (var i = 0; i < val.data.length; i++) {
			            	if(val.data[i][1] !== null){
			            		LegendOptions.push(val.data[i][0]);
			            	}
			            }
			            if(noOfAlts < LegendOptions.length) {
			            	noOfAlts = LegendOptions.length;
			            }
			            //break;
			        }
                    //break;
                }
            }
	    }
	    $('#altLegend' + self.encodeId).show().empty();
	    var altIndexes = Object.getOwnPropertyNames(self.altIndex);
	    _.each(altIndexes, function (key) {
	        var altIndexVal = self.altIndex[key];
	        //if (self.showBars) {
	            $('#altLegend' + self.encodeId).append($("<div>Alt-" + altIndexVal + " : " + key + "</div>"));
	        /*} else {
	            $('#altLegend' + self.encodeId).append($("<div>Ph-" + altIndexVal + " : " + key + "</div>"));
	        }//

	    });
	    legendPosition.y = h - altIndexes.length * 30;
	    var maxVal;
	    var altIndexes = Object.getOwnPropertyNames(self.altIndex);
	    var selectedValueList = [];
	    for (var i = 0; i < noOfAlts; i++) {
	        var valDataArr = [];
	        var altName;
	        var index = parseInt(LegendOptions[i].substr(LegendOptions[i].lastIndexOf('-') + 1));
	        _.each(altIndexes, function (key) {
	            var altIndexVal = self.altIndex[key];
	            if (altIndexVal === index) {
	                altName = key;
	            }
	        });
	        for (var j = 0; j < values.length; j++) {
	            var selected = false;
	            for (var k = 0; k < selectedOptions.length; k++) {
	            	var valueSuffix = window.utils.getSuffix(values[j]);
	                if (selectedOptions[k].label === self.datasets[values[j]].label && values[j].indexOf('Grade only') == -1) {
	                    selected = true;
	                    break;
	                }
	            }
	            if (selected) {
	                var data = self.datasets[values[j]].data;
	                var foundValue = false;
	            	for(var k = 0; k < data.length; k++){
	            		if(data[k][0] === LegendOptions[i]){
	            			foundValue = true;
	            			//var val = self.datasets[values[j]].data[i]?self.datasets[values[j]].data[i][1]:'';
	            			var val = data[k]?data[k][1]:'';
			                val = window.utils.getRoundedValues(val, self.datasets[values[j]].significantDecimals, true);
			                var valueIndex =_.filter(selectedValueList,function(obj){return obj.id === valueSuffix;});
			                if(valueIndex && valueIndex[0]){
			                	valueIndex[0].value.push(val);
			                }else {
			                	selectedValueList.push({'id':valueSuffix,value:[val]});
			                }
			                var radarHover = self.datasets[values[j]].valueName + ' ' + val + ' ' + self.datasets[values[j]].unit;
			                valDataArr.push({valueSuffix:valueSuffix, axis: self.datasets[values[j]].valueName, radarHover: radarHover, value: val, areaName: LegendOptions[i] });
			                if (!maxVal || maxVal < val) {
			                    maxVal = val;
			                }
	            			break;
	            		}
	            	}
	            	if(!foundValue){
	            		valDataArr.push({valueSuffix:valueSuffix, axis: self.datasets[values[j]].valueName, radarHover: self.datasets[values[j]].valueName, value: '', areaName: LegendOptions[i] });
	            	}
	            }
	        }
	        
	        radarData.push({ values: valDataArr, key: LegendOptions[i] });
	    }
	    for(var m=0;m<selectedValueList.length;m++){
	    	var max = _.max(selectedValueList[m].value);
		    for(var i=0;i<radarData.length;i++){
		    	var valueList = radarData[i].values;
		    	var valueListIndex =_.filter(valueList,function(obj){return obj.valueSuffix === selectedValueList[m].id;});
		    	if(valueListIndex && valueListIndex[0]){
		    		var oldValue = valueListIndex[0].value;
		    		if(max == 0){
		    			max = 1;
		    		}
		    		if(oldValue !== '' || !isNaN(oldValue) || !isNaN(max) /*|| oldValue == Number.MAX_VALUE || oldValue == Infinity//){
		    			var valuePercentage = oldValue/max * 100;
			    		if(valuePercentage < -100){
			    			valuePercentage = -100;
			    		}
			    		valueListIndex[0].value = window.utils.getRoundedValues(valuePercentage, 2, true);
		    		}
		    	}
		    }
		}    
	    //Options for the Radar chart, other than default
	    var radarChartOptions = {
	        w: width,
	        h: height,
	        margin: margin,
	        legendPosition: legendPosition,
	        //maxValue: 0.5,
	        wrapWidth: 120,
	        levels: 5,
	        roundStrokes: true,
	        color: color,
	        axisName: "axis",
	        areaName: "areaName",
	        value: "value"
	    };
        RadarChart.draw("#placeholder" + self.encodeId, radarData, radarChartOptions);
	}*/

	plotAccordingToChoices() {
	    var self = this;
	    var placeholder = $("#placeholder" + self.encodeId);
	    var selectedOptions = $('#yaxis'+ self.encodeId + ' option:selected');
	    var selectedChartType = self.selectedChartType();
	    if (selectedChartType != 'Table') {
	        placeholder.css('overflow', 'visible');
	    } else {
	    	
	    	if(selectedOptions && selectedOptions.length > 0) {
	    		placeholder.css('overflow', 'auto');
	    	}else {
	    		placeholder.css('overflow', '');
	    	}
	    }
		if(self.dataTable){//destroy previously created datatable
			self.dataTable.destroy(true);
		}
	    /*if (self.showRadar() && selectedChartType === 'Radar') {
	    	//self.plot.shutdown();
	    	placeholder.empty();
	        self.plotRadarChart();
	        $('#selectTheme'+self.encodeId).hide();
	    } else*/ if(selectedChartType === 'Polar' || selectedChartType === "Spiderweb"){
	        placeholder.empty();
	        self.plotPolarChart();
	        $('#selectTheme'+self.encodeId).show();	        
 	    } else {
	        //d3.select("#placeholder" + self.encodeId + ' svg').remove();
			self.plotFlotChart();
			if(selectedOptions && selectedOptions.length == 0 && self.selectedChartType() === 'Table') {
				self.displayTable([]);
			}
	       
	    }
	};
	
	getValueMeasurements(alts,callback){
		var self = this;
		var dataSet = {};
		var hasMoreThanOneValue = false;
		self.margin = 0.05;
		self.altIndex = {};
		var selectedChartType = self.selectedChartType();
		self.showBars = selectedChartType === "Bars" || selectedChartType === "Stacked bars" || selectedChartType === "Columns" || selectedChartType === "Stacked columns"? true : false;
		if(!self.alts){
			callback();
    		return;
    	}
		async.each(self.values,function(val,valHandleCallback){
			var measurandData = {};
			function addMeasurandData(unit){
				var valueData = {};
				valueData.label = val.getMeasurand().get('name');
				valueData.valueName = val.getMeasurand().get('name');
				valueData.data = [];
				measurandData[unit] = valueData;
				return valueData;
			}
			
			async.eachSeries(alts,function(alt,altHandleCallback){
				var unit = "";
				function pushEmpty(valueData){
					altHandleCallback();
				}
				var mainScenario = alt.get('phaseObjectiveSet');
				if(!mainScenario){
					pushEmpty();
					return;
				}
				var mests = mainScenario.getMeasurementsReferringToMeasuredCharteristic(val);
				if(mests.length == 0){
					var satMc = val.getParent().get('satisfactionLevel');
					mests = mainScenario.getMeasurementsReferringToMeasuredCharteristic(satMc);
				}
				_.each(mests, function (mest) {
				    var valData = [];
				    var planName = alt.get('phaseAlternativeOwner').get('name');
				    var altName = alt.get('name');
				    var altProp = planName + " / " + altName;
				    var whenObs = mest.get('observedMeasure').get('observation').get('whenObserved');
				    if (whenObs != null) {
				        altProp = altProp + "(" + whenObs+ ")";
				    }
				    if (_.indexOf(self.xAxisSelected, alt.id + (whenObs != null ? whenObs : "")) < 0) {
				        return;
                    }
				    if (_.has(self.altIndex, altProp)) {
				        valData.push("Alt-" + self.altIndex[altProp]);
				    } else {
				        self.altIndex[altProp] = (_.keys(self.altIndex)).length;
				        valData.push("Alt-" + self.altIndex[altProp]);
				    }
				    //valData.push(planName+ " / " +altName);
				    if (mest && mest.get('observedMeasure').get('measure') && (mest.get('value') !== null || mest.get('symbol') !== null)) {
				        unit = mest.get('observedMeasure').get('measure').get('unit');
				        if (mest.get('value') === Number.MAX_VALUE || mest.get('value') === '') {
				            valData.push(null);
				        } else {
				        	if(mest.get('value')) {
				        		valData.push(parseFloat(mest.get('value')));
				        	}else {
				        		valData.push(mest.get('symbol'));
				        	}
				            
				        }
				        var selectedInterval = val.getParent().getSatisfactionInterval(mainScenario);
						var smileySrc = '';
						var smileyColor = '';
						var correctiveActions = '';
						if(selectedInterval){
							smileySrc = val.getParent().fetchSmileySrc(selectedInterval.get('smiley'));
		                	smileyColor = selectedInterval.get('smileyColor');
		                	correctiveActions = selectedInterval.get('correctiveActions');
		                }
		                valData.push(smileySrc);
		                valData.push(smileyColor);
		                valData.push(alt.id);
		                valData.push(val.getParent().id);
		                valData.push(correctiveActions);
				    } else {
				        valData.push(null);
				    }
				    var valueData;
				    if (mest) {
				    	var unitName;
				        if (unit) {
				           unitName = window.utils.replaceEscapeCharacters(unit.get('name'));
				        }/*else {
				        	unitName = 'SatisfactionOnly';
				        }*/
				         
				        valueData = measurandData[unitName];
				        if (!valueData) {
				            valueData = addMeasurandData(unitName);
				            valueData.significantDecimals = unit?unit.get('significantDecimals'):'2';
				            var label = valueData.label;
				            if(unitName){
				            	label = label.concat(" (" + unitName + ")");
				            }
				            valueData.label = label;
				            valueData.unit = unitName?unitName:'Grade only';
				        }
				        valueData.data.push(valData);
				    } else {
				        valueData = addMeasurandData("");
				        valueData.data.push(valData);
				    }
				});

				//valueData.data.push(valData);
				altHandleCallback();
			},function(){
				var emptyData = measurandData[""];
				if(emptyData){
					delete measurandData[""];
				}
				
				Object.getOwnPropertyNames(measurandData).forEach(function(key) {
					var valueCount =0;
					var valueData = measurandData[key];
					if(emptyData){
					  	valueData.data = valueData.data.concat(emptyData.data);
					}
					for(var i=0;i<valueData.data.length;i++){
						if(valueData.data[i][1] != null){
							valueCount++;	
						}
					}
					var unitString = window.utils.replaceEscapeCharacters(valueData.unit);
				  	dataSet[val.get('id') + unitString ] = valueData;
					if(valueCount > 1){
						hasMoreThanOneValue = true;
					}				  	
				});				
				valHandleCallback();
			});	
		},function(){
			/*if(!self.showBars && !hasMoreThanOneValue){
				self.showBars = true;
			}*/
			if(self.showBars && !hasMoreThanOneValue){
				self.margin = 0.5;
			}
			self.datasets = dataSet;
			callback();
		});
	};
	
	getAlternatives(){
		var alts = [];
		var currentPlan = DataManager.getDataManager().get('currentPlan');
		var phase = currentPlan.get('phase').findWhere({nextPhase:null});
		while(phase){
			var phaseAltsArray = [];
			var phaseAlts = phase.get('phaseAlternative');
			var masterAlt = phase.get('master');
			phaseAlts.each(function(phaseAlt){
				if(phaseAlt !== masterAlt){
					phaseAltsArray.push(phaseAlt);
				}
			});
			phaseAltsArray.unshift(masterAlt);
			alts = phaseAltsArray.concat(alts);
			phase = phase.get('previousPhase');
		}		
		return alts;
	};

	getXAxisData(preference, refresh) {
	    var self = this;
	    var xAxisData = [];
	    var currentPlan = DataManager.getDataManager().get('currentPlan');
	    var phase = currentPlan.get('phase').findWhere({ nextPhase: null });
	    while (phase) {
	        var phaseData = {};
	        xAxisData.unshift(phaseData);
	        phaseData.id = phase.get('id');
	        phaseData.parent = '#';
	        phaseData.text = phase.get('name');
	        if (preference && (_.indexOf(preference.xAxisSelected, phaseData.id) >= 0)) {
	            phaseData.selected = true;
	            self.xAxisSelected.push(phaseData.id);
	        }

	        var phasePrimary = phase.get('primary');
	        if (phasePrimary && !preference && !refresh) {
	            self.xAxisSelected.push(phasePrimary.get('id'));
	        }


	        var phaseAlts = phase.get('phaseAlternative');
	        phaseAlts.each(function (phaseAlt) {
	            var altData = {};
	            altData.id = phaseAlt.get('id');
	            altData.parent = phase.get('id');
	            altData.text = phaseAlt.get('name');
	            xAxisData.push(altData);
	            if (!preference && (phasePrimary === phaseAlt)) {
	                altData.state = { opened: true, selected: true };
	            }
	            if (preference && (_.indexOf(preference.xAxisSelected, phaseAlt.get('id')) >= 0)) {
	                altData.state = { opened: true, selected: true };
	                self.xAxisSelected.push(phaseAlt.get('id'));
	            }
	            /*var phObjSet = phaseAlt.get('phaseObjectiveSet');
	            if(phObjSet) {
		            var objObservations = phObjSet.get('objectiveObservation');
		            objObservations.each(function (objObs) {
		                if (objObs.get('whenObserved') != null) {
		                    var altData = {};
		                    altData.id = phaseAlt.get('id');
		                    altData.parent = phase.get('id');
		                    altData.text = phaseAlt.get('name') + "(" + objObs.get('whenObserved') + ")";
		                    altData.timeStamp = objObs.get('whenObserved');
		                    altData.id = altData.id + altData.timeStamp;
		                    xAxisData.push(altData);
		                    if (preference && (_.indexOf(preference.xAxisSelected, phaseAlt.get('id') + altData.timeStamp) >= 0)) {
		                        altData.state = { opened: true, selected: true };
		                        self.xAxisSelected.push(phaseAlt.get('id') + altData.timeStamp);
		                    }
		                }
		            });
		            self.xAxisSelected = _.uniq(self.xAxisSelected);
	            }*/
	        });
	        phase = phase.get('previousPhase');
	    }
	    return xAxisData;
	};
	
	showAggregationView(view) {
		var self = this;

		var valueId = view.id;
		/*if(DataManager.getDataManager().getRepositoryId(view.id) !== window.plansKey){
			valueId = view.altId + window.utils.getSuffix(view.id);
		}*/
		var altModel = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: view.altId });
		altModel.showAggregationView(valueId, function(){
			window.utils.startSpinner('refreshValues','Refreshing ...');
			DataManager.getDataManager().invokeValueUpdates(function(){
				if(self.parentView) {
					self.parentView.refreshWidgetData(true);
					window.utils.stopSpinner('refreshValues');	
				}else {
					window.utils.stopSpinner('refreshValues');	
				}
			});
		});
    };
	
    //#endCustomMethods
    
    static getInstance(model,options){
		var view = new PlanValuesChart(model, options);
		//view.init(model, options);
		return view;
	};
}
path.PlanValuesChart = PlanValuesChart;

