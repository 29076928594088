import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {VoteMixin} from './VoteMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/tickets/VoteMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,VoteMixin
//, User){
	
	var path = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
	/*if(!User){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.User){
			User = importPath.User;
		}
		else{
			require(["appbo/tickets/User"],function(loadedModule){
				User = loadedModule;
			});
		}
	}*/
	var Vote = EObject.extend(utils.customExtends({
		relations:VoteMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			EObject.prototype.initialize.apply(this, arguments);
			if(attributes.inFavour){
				this.set('inFavour',path.VoteStatus[attributes.inFavour instanceof Object ? attributes.inFavour.name : attributes.inFavour]);
			}
		}
		}
		, new VoteMixin()
	));
	
	Vote.abstract = false;
	Vote.supported = false;
	path.Vote = Vote;
	//return Vote;
	export {Vote};
//});