import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {CollaborationDiagram} from './CollaborationDiagram'
import {CapabilityOfferDiagramMixin} from './CapabilityOfferDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/CapabilityOfferDiagramMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,CapabilityOfferDiagramMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var CapabilityOfferDiagram = CollaborationDiagram.extend(utils.customExtends({
		relations:CapabilityOfferDiagramMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			CollaborationDiagram.prototype.initialize.apply(this, arguments);
		}
		}
		, new CapabilityOfferDiagramMixin()
	));
	
	CapabilityOfferDiagram.abstract = false;
	CapabilityOfferDiagram.supported = false;
	path.CapabilityOfferDiagram = CapabilityOfferDiagram;
	export {CapabilityOfferDiagram};
//});