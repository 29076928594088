import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as jszip from '../../../../../../libs/jszip/jszip.min'
import { CodeContainer } from '../../../../bo/beeppackage/CodeContainer'
import { EcoMapMixin } from '../../../../bo/ecomap/EcoMapMixin'
/*define(["require","jquery","underscore","backbone","knockout","bootbox","async","jszip","knockoutMapping","knockback","appcommon/com/vbee/data/DataManager","app/global","appbo/ecomap/EcoMapMixin", "appbo/beeppackage/CodeContainer"],
function(require,$,_, Backbone,ko,bootbox,async,jszip,koMapping,kb,DataManager,global,EcoMapMixin,CodeContainer)
{*/
	var path = DataManager.getDataManager().buildAppNsPath("ecoMap.views.designer",global.version);
	export class EcoMapPackageViewModel {
		constructor(model,options){
			var self = this;
			this.init(model, options);
        }
        showobjectExplorerTree() {
            var self = this;
            var addOptions = this;
            var id = window.utils.htmlEscape(window.guidGenerator()) + self.encodeId;
            window.getAndCreateModalDialog(true, id, EcoMapMixin, self.options.addOptions.model, "explorerModelJson", closeView, addOptions);
            function closeView() {
                window.cleanDialogModel(id, EcoMapMixin);
            }
        };

		submitToMarket() {
			var self = this;
			var dataManager = DataManager.getDataManager();
			var packDoc = dataManager.get("artifactsDocuments")[self.model.id];
			window.utils.startSpinner('checking','Checking...');
			dataManager.saveChangeSetToBackend(dataManager.get(dataManager.CURRENT_CHANGESET),function(response){
				dataManager.applyWorkspaceById(dataManager.get("currentWorkspaceId"),function(){
					dataManager.checkOwnedPackage(self.model,function(resp){
						window.utils.stopSpinner('checking');
						if(resp && self){
							/*if(resp.productId){
								self.productId = resp.productId;
							}*/
							if(resp.owner){
								var planId = dataManager.get('currentPlan').id;
								var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
								if(resp.marketplaceId){
									dataManager.navigateToCrmPage("workspace/"+currentWorkspaceId+"/submitted/"+resp.marketplaceId+"/"+packDoc.artifactId);
								} else {
									dataManager.navigateToCrmPage("workspace/"+currentWorkspaceId+"/"+packDoc.artifactId+"/marketplace");
								}
							}else {
								bootbox.alert("Package Submission is not allowed");
							}
							/*if(resp.published){
								self.publishedDate(new Date(resp.publishedDate).toDateString());
							}*/                     
						}
					});
				});
			});
			//var planDocVersion = planDoc ? planDoc.documentVersion : self.model.get('documentVersion');
		};

    init(model, options) {
		var self = this;
		this.EcoMapPackageViewModel = this;
		this.model = model;
		this.id = kb.observable(model, 'id');
		this.canExport = ko.observable(true);
		this.canExport(DataManager.getDataManager().canExport(self.model));
		this.encodeId = window.utils.htmlEscape(this.id());
		if(options && options.parentView){
			this.parentView = options.parentView;
			this.encodeId = ""+options.modalId;
			this.packageList = options.packages;
		}
		this.name = ko.observable(self.model ? self.model.get('name') : null);
		this.description =ko.observable(model ? model.get('description') : "");
		this.ecoMapsColl = ko.observableArray([]);
		this.showMarketSubmit = ko.observable(false);
		this.showSwitch = ko.observable(false);
		if(!DataManager.getDataManager().get('readMode') && !window.utils.checkIsLinked(null,true) && this.canExport()){
			var roles = DataManager.getDataManager().get('serverRoles');
			var show = false;
			if((roles && roles.indexOf('LEGAL_ENTITY_ADMIN') != -1) || window.utils.isVMPUser()){
				show = true;
			}
			if(!DataManager.getDataManager().isEnterpriseEdition()){
				show = false;
			}
			self.showMarketSubmit(show);
		}
        this.options = options;
		$('#modal'+self.encodeId).on('shown.bs.modal', function () {
			window.utils.focusElement("#newModal .focus-ele");
		});
	
		this.name.subscribe(function(val){
			self.description(val);
			if(val == ''){
				$("#complete").attr('disabled','disabled');
			}
			else{
				$("#complete").removeAttr('disabled');
			}
		});
		function fillEcoMaps(){
			var ecoMapsList = self.model.get('diagrams').models.concat();
			for(var i=0;i<ecoMapsList.length;i++)
            {
                var type = "Business Ecosystem Map";
                if (ecoMapsList[i].get('type') === "vdml_StrategyMapDiagram") {
                    type = "Strategy Map";
                } else if (ecoMapsList[i].get('type') === "vdml_ValueStreamMapDiagram"){
                    type = "Value Stream Map";
                }
                self.ecoMapsColl.push({ name: ecoMapsList[i].get('name'), description: ecoMapsList[i].get('description'), type: type})	
			}
		}
		fillEcoMaps();
		function cleanAndReturnModal(){
			self.cleanModal();
		}

	    this.deleteEcoMapPackage = function(){
	    	function doDelete(){
	    		self.model.destroy = self.model.constructor.prototype.destroy;
	    		self.model.destroy({success:function(){
	    			//DataManager.getDataManager().get('changedParentObjects').remove(self.model);
	    			//DataManager.getDataManager().get('changedObjects').remove(self.model);
					var dataManager = DataManager.getDataManager();
					dataManager.removeArtifacts(self.model.id);
					window.utils.showSpinnerText('Saving Data ... ');
					dataManager.saveChangeSetToBackend(dataManager.get(dataManager.CURRENT_CHANGESET),function(response){
						dataManager.applyWorkspaceById(dataManager.get("currentWorkspaceId"),function(){
							window.utils.stopSpinner('deletePackage');					                	
						});
					});
					cleanAndReturnModal();
					//window.utils.stopSpinner('deletePackage');  
                },error:function(){
					console.log('Failed to remove package');
					DataManager.getDataManager().removeInitializedPackage(self.model.get('id'));
					cleanAndReturnModal();
					window.utils.stopSpinner('deletePackage');
				}});
        	}

			if(self.model.get('diagrams').length>0){
				var message = DataManager.getDataManager().get('localeManager').get('PackageDeleteAlert',DataManager.getDataManager().get('localeManager').get('BusinessEcosystemMap'));
				bootbox.confirm(message, function(result) {
	                if(result){
	                	window.utils.startSpinner('deletePackage','Deleting Business Ecosystem Map Package...');
	                    setTimeout(doDelete,100);
	                }
	                else {
	                	cleanAndReturnModal();
	                }
	            });
			}
			else{
				setTimeout(doDelete,0);
			}
		};
		
		
	    this.saveEcoMapPackagedetails = function(){
	    	if(self.model){
	    		self.model.set('name',self.name());
	    		self.model.set('description',self.description());
	    	}
	    	cleanAndReturnModal();
		};
		
		this.cleanModal = function(){
			if(self.parentView && self.id()){
				window.cleanDialogModel(self.encodeId,self);
			}
			options.callback(options.addOptions.searchValue);
		};
		
		this.exportPackage = function(view,event){
			event.stopPropagation();
			//var suggestion = self.model.get('name').replace(/\"/g, "");
			window.setTimeout(function(){
				createZip(self.model,function(content){
					window.utils.stopSpinner('exportCapabilityLibraryPackage');
					/*if(window.utils.checkChrome() && chrome.fileSystem && chrome.fileSystem.chooseEntry){
						chrome.fileSystem.chooseEntry({type: 'saveFile',suggestedName:suggestion,accepts:[{extensions:['vpk']}]}, function(f) {
							if (!chrome.runtime.lastError) {
							  f.remove(function() {
								  window.utils.saveCurrentEntry(f,content,self.model.get('name'));
								}, function(e){
					    			console.log(e);
					    			bootbox.alert('Export error: '+e);
								});
							}
							else{
				    			console.log(chrome.runtime.lastError);
				    			if(chrome.runtime.lastError.message !== 'User cancelled') {
				    				bootbox.alert('Export error: '+chrome.runtime.lastError);
				    			}
							}
						});			
					}else {*/
					var exportMsg = DataManager.getDataManager().get('localeManager').get('exportComplete',self.model.get('name'));
					window.utils.exportFileToSystem(self.model.get('name'),'VPK File',".vpk",content,exportMsg);
					//}
				});
			},100);
			function getCapLibraryList(model){
				var libraryList =[];
				var diagrams = model.get('diagrams').models;
				for(var i=0;i<diagrams.length;i++){
					var xml = diagrams[i].get('data');
					var diagType = diagrams[i].get('type');
					if(xml){
						if(diagType == "vdml_ValueStreamMapDiagram"){
							var xmlDoc = jQuery.parseXML(xml);
							var $xml = jQuery(xmlDoc);	
							var nodes = $xml.find('[mcpid]');
							for(var n=0;n<nodes.length;n++){
							   var isExist =  false;
							   var model = Backbone.Relational.store.getObjectByName('vdml.CapabilityLibrary').find({id:nodes[n].getAttribute('mcpid')});
							   if(model){
							     for(var j=0;j<libraryList.length;j++){
									 	 if(libraryList[j].id === model.get('capabilityLibraryOwner').get('id')){
										 	 isExist = true;
										 }
								 }
								 if(!isExist){
									libraryList.push({text:model.get('capabilityLibraryOwner').get('name'), id: model.get('capabilityLibraryOwner').get('id'), version : model.get('capabilityLibraryOwner').get('version')});
								 }
							   }
							}
						}
					}
				}
				return libraryList;
			}
			
			function createZip(model,callback){
				var libList = getCapLibraryList(model);
				var zip = new jszip();
				var text = JSON.stringify(model);
				zip.file(self.model.get('id') + ".json", text);
			    var manifestJson =  window.utils.generateManifest(self.model);
			    zip.file("manifest.json",manifestJson);
				var newclPackId = DataManager.getDataManager().get('currentPlan').id;
				var newclPackSuffix = newclPackId.substr(newclPackId.lastIndexOf('@') + 1);
			    var commonAltId ='@' + newclPackSuffix + "-Common@";
				if(libList.length > 0){
					        var htmlContent = "<p>List of Capability Packages</p>";
							for(var value =0;value<libList.length;value++){
								htmlContent +=  "<input type='checkbox' class = 'listOfCapPacks' value = \"" + libList[value].id + "\" version = \"" + libList[value].version + "\"checked/>" + " " + libList[value].text +'</br>';
							}
							var dialog = bootbox.dialog({
								title: "<div>&nbsp;&nbsp;Do want to Export Capability Package?</div>",
								message: htmlContent,
								buttons: {
									main: {
									   label: "Close",
									   className: "btn btn-default",
									   callback: function(){
									   	   DataManager.getDataManager().getRDFModel(commonAltId).getAllDocumentsCollectionOfType('beeppackage_CodeContainer', loadCodeContainers, null, null, 'vbc:cmof_EObject-name', null, true);
									   }
									},
									success: {
										label: "Complete",
										className: "btn btn-complete complete-btn",
										callback: function () {
											window.utils.startSpinner('exportCapabilityLibraryPackage','Exporting Package ...');
											async.eachSeries($('.listOfCapPacks'), function(list, listCallback){
											     if(list.checked){
													var repId = DataManager.getDataManager().getRepositoryId(list.value);
								                    var vdmStore = DataManager.getDataManager().getVDMStore(repId);
													DataManager.getDataManager().fetchDocumentFromPackage(list.value, 'vdml_ValueDeliveryModel', list.getAttribute('version'), list.value, 'vdml_ValueDeliveryModel', vdmStore, {
														success: function (model) {
															var text = JSON.stringify(model);
													        zip.file(model.get('id') + ".json", text);
															listCallback();
														},
														error: function (error) {
															console.log('Unable to load selected Package');
															listCallback();
														}
													}, true, null);
												 }else{
												 	 listCallback();
												 }
											})
											DataManager.getDataManager().getRDFModel(commonAltId).getAllDocumentsCollectionOfType('beeppackage_CodeContainer', loadCodeContainers, null, null, 'vbc:cmof_EObject-name', null, true);
										}
									}
								}
							});
				}else{
				    window.utils.startSpinner('exportCapabilityLibraryPackage','Exporting Package ...');
				    DataManager.getDataManager().getRDFModel(commonAltId).getAllDocumentsCollectionOfType('beeppackage_CodeContainer', loadCodeContainers, null, null, 'vbc:cmof_EObject-name', null, true);
				}
				function loadCodeContainers(codeContainers) {
					async.eachSeries(codeContainers.models, function (codeContainer, dataHandled) {
						var containerExists = false;
						_.each(self.model.get('diagrams').models,function(diag){
							var xml = diag.get('data');
							if(xml){
								var xmlDoc = jQuery.parseXML(xml);
								var $xml = jQuery(xmlDoc);
								var nodes = $xml.find('[backgroundUrl="' + codeContainer.id + '"]');
								if(nodes && nodes.length > 0){
									containerExists = true;
								}
							}
						});
						if(containerExists){
							CodeContainer.getBlobInstanceWithId(codeContainer.id, DataManager.getDataManager().get('currentPlan'), function (codeContainer) {
								if (codeContainer && codeContainer.getParent() && codeContainer.getParent().get('type') != 'beeppackage_BeepModule') {
									var ext = codeContainer.get('name').substr(codeContainer.get('name').lastIndexOf('.') + 1);
									if (ext !== "html") {
										var text = JSON.stringify(codeContainer.toJSON());
										zip.file(codeContainer.get('id') + ".json", text);
										DataManager.getDataManager().unRegisterCodeContainer(codeContainer);
									}
								} else {
									console.log('Unable to load selected Package');
								}
								dataHandled();
							});
						} else {
							dataHandled();
						}
					}, function () {
						zip.generateAsync({ type: "blob" })
							.then(function (content) {
								callback(content);
							});
					});
				}
			}					
		};
		
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),[
		'name'
		,'description'
		,'Close'
		,'Delete'
		,'Complete'
		,'export'
		,'EditVDMLpackage'
		,'EcoMaps'
		,'Publish'
		,'type'
	]);
	}
		
	afterRenderView(){
		$("#ecoMapPackageName"+self.encodeId).keyup(function (view) {
			var name = view.currentTarget.value.trim();
			var found = false;
			if (name !== "") {    
				for (var i = 0; i < self.packageList.length; i++) {
					if (self.packageList[i].label === name && self.packageList[i].id !== self.id()){
						found = true;
						$('.warning').removeClass('hide');
						$('#complete').prop("disabled", true);
						break;
					}
				}
				if(!found){
					$('.warning').addClass('hide');
					$('#complete').prop("disabled", false);
				}
			}
		});
		window.utils.applyFontSizeInDialog();
	}
	
	
	static getInstance(model,options){
		var view = new EcoMapPackageViewModel(model, options);
		//view.init(model, options);
		return view;
	};
}
path.EcoMapPackageViewModel = EcoMapPackageViewModel;
