import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {NamedPlanElement} from './NamedPlanElement'
import {NamedPlanElementMixin} from './NamedPlanElementMixin'
import {User} from '../tickets/User'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/transformation/NamedPlanElement","appbo/transformation/NamedPlanElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,NamedPlanElement,NamedPlanElementMixin
//, User){
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
	if(!User){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.User){
			User = importPath.User;
		}
        else {
            import('../tickets/User').then(loadedModule => {
                User = loadedModule;
            });
			/*require(["appbo/tickets/User"],function(loadedModule){
				User = loadedModule;
			});*/
		}
	}
export class RoleMixin{

	defaults(){
		var ret = {
			type: "transformation_Role"
		}
		return jQuery.extend(NamedPlanElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"transformation_Role",
			key:"assignedUser",
			relatedModel:"tickets.User",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"role",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}			
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!RoleMixin.cummulativeRelations) {
            RoleMixin.cummulativeRelations = _.union(RoleMixin.getMixinRelations()
                , NamedPlanElementMixin.getCumulativeMixinRelations()
            );
        }
		return RoleMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"transformation_NamedPlanElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "progress", type: "double", defaultValue: "0", containingClass: "cmof_EObject" },
			{name : "roleType",type : "RoleType",defaultValue : "null",containingClass : "transformation_Role" }
		]
    }
    static calculateProgress() {

    }
	getParent(){
		var container;
		if(!container){
			container = this.get("planRoleOwner") ? this.get("planRoleOwner") : this.previousAttributes().planRoleOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("planRoleOwner") ? this.get("planRoleOwner") : this.previousAttributes().planRoleOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/transformation/views/properties/RolePropertiesTemplate.html",
			templateName : "RolePropertiesTemplate",
			viewTypeStr : "appviews/transformation/views/properties/RoleViewModel",
			tabId : "RoleView",
			tabName: "Role"
		}
	}
	
	//return RoleMixin;
}
path.RoleMixin = RoleMixin;