import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Participant} from './Participant'
import {ActorMixin} from './ActorMixin'
import {Scenario} from './Scenario'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Participant","appbo/vdml/ActorMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Participant,ActorMixin
, Scenario){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Scenario){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Scenario){
			Scenario = importPath.Scenario;
		}
		else{
			import('./Scenario').then(({ default: Scenario }) => {
                Scenario = Scenario;
            });
			/*require(["appbo/vdml/Scenario"],function(loadedModule){
				Scenario = loadedModule;
			});*/
		}
	}
	var Actor = Participant.extend(utils.customExtends({
		relations:ActorMixin.getMixinRelations(),
		subModelTypes: {
			vdml_Person : 'vdml.Person'
		},
		initialize: function(attributes, options) {
			Participant.prototype.initialize.apply(this, arguments);
			//this.on("change:name", this.handleActorNameChange);
		}
		}
		, new ActorMixin()
    ));
    Actor["change:name"] = function (model, newName) {
		var previousName = model._previousAttributes ? model._previousAttributes.name:"";
		if(previousName != newName) {
			this.handleActorNameChange(model, newName);
		}
    }
	Actor.abstract = false;
	path.Actor = Actor;
	export {Actor};
//});