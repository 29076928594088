import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurableElement} from './MeasurableElement'
import {BusinessModelMixin} from './BusinessModelMixin'
import {BusinessModel2Mixin} from './BusinessModel2Mixin'

import {BMElement} from './BMElement'
import {BMElementMixin} from './BMElementMixin'
import {BusinessModelState} from './BusinessModelState'
import {OrgUnit} from './OrgUnit'
import {Scenario} from './Scenario'
import {BusinessModelDiagram} from './BusinessModelDiagram'
import {ParticipantNetwork} from './ParticipantNetwork'
import {Role} from './Role'
import {BusinessModelCustomer} from './BusinessModelCustomer'
import {BusinessModelValueProposition} from './BusinessModelValueProposition'
import {BusinessModelActivity} from './BusinessModelActivity'
import {BusinessModelCompetence} from './BusinessModelCompetence'
import {BusinessModelNetworkPartner} from './BusinessModelNetworkPartner'
import {BusinessModelValueFormula} from './BusinessModelValueFormula'
import {Store} from './Store'
import {ValueStream} from './ValueStream'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/BusinessModelMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,BusinessModelMixin
, BMElement
, BMElementMixin
, BusinessModelState
, OrgUnit
, Scenario
, BusinessModelDiagram
, ParticipantNetwork
, Role
, BusinessModelCustomer
, BusinessModelValueProposition
, BusinessModelActivity
, BusinessModelCompetence
, BusinessModelNetworkPartner
, BusinessModelValueFormula
, Store
, ValueStream){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!BMElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BMElement){
			BMElement = importPath.BMElement;
		}
		else{
			import('./BMElement').then(({ default: BMElement }) => {
                BMElement = BMElement;
            });
			/*require(["appbo/vdml/BMElement"],function(loadedModule){
				BMElement = loadedModule;
			});*/
		}
	}
	if(!BMElementMixin){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BMElementMixin){
			BMElementMixin = importPath.BMElementMixin;
		}
		else{
			import('./BMElementMixin').then(({ default: BMElementMixin }) => {
                BMElementMixin = BMElementMixin;
            });
			/*require(["appbo/vdml/BMElementMixin"],function(loadedModule){
				BMElementMixin = loadedModule;
			});*/
		}
	}
	if(!BusinessModelState){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModelState){
			BusinessModelState = importPath.BusinessModelState;
		}
		else{
			import('./BusinessModelState').then(({ default: BusinessModelState }) => {
                BusinessModelState = BusinessModelState;
            });
			/*require(["appbo/vdml/BusinessModelState"],function(loadedModule){
				BusinessModelState = loadedModule;
			});*/
		}
	}
	if(!OrgUnit){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.OrgUnit){
			OrgUnit = importPath.OrgUnit;
		}
		else{
			import('./OrgUnit').then(({ default: OrgUnit }) => {
                OrgUnit = OrgUnit;
            });
			/*require(["appbo/vdml/OrgUnit"],function(loadedModule){
				OrgUnit = loadedModule;
			});*/
		}
	}
	if(!Scenario){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Scenario){
			Scenario = importPath.Scenario;
		}
		else{
			import('./Scenario').then(({ default: Scenario }) => {
                Scenario = Scenario;
            });
			/*require(["appbo/vdml/Scenario"],function(loadedModule){
				Scenario = loadedModule;
			});*/
		}
	}
	if(!BusinessModelDiagram){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModelDiagram){
			BusinessModelDiagram = importPath.BusinessModelDiagram;
		}
		else{
			import('./BusinessModelDiagram').then(({ default: BusinessModelDiagram }) => {
                BusinessModelDiagram = BusinessModelDiagram;
            });
			/*require(["appbo/vdml/BusinessModelDiagram"],function(loadedModule){
				BusinessModelDiagram = loadedModule;
			});*/
		}
	}
	if(!ParticipantNetwork){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ParticipantNetwork){
			ParticipantNetwork = importPath.ParticipantNetwork;
		}
		else{
			import('./ParticipantNetwork').then(({ default: ParticipantNetwork }) => {
                ParticipantNetwork = ParticipantNetwork;
            });
			/*require(["appbo/vdml/ParticipantNetwork"],function(loadedModule){
				ParticipantNetwork = loadedModule;
			});*/
		}
	}
	if(!Role){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Role){
			Role = importPath.Role;
		}
		else{
			import('./Role').then(({ default: Role }) => {
                Role = Role;
            });
			/*require(["appbo/vdml/Role"],function(loadedModule){
				Role = loadedModule;
			});*/
		}
	}
	if(!BusinessModelCustomer){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModelCustomer){
			BusinessModelCustomer = importPath.BusinessModelCustomer;
		}
		else{
			import('./BusinessModelCustomer').then(({ default: BusinessModelCustomer }) => {
                BusinessModelCustomer = BusinessModelCustomer;
            });
			/*require(["appbo/vdml/BusinessModelCustomer"],function(loadedModule){
				BusinessModelCustomer = loadedModule;
			});*/
		}
	}
	if(!BusinessModelValueProposition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModelValueProposition){
			BusinessModelValueProposition = importPath.BusinessModelValueProposition;
		}
		else{
			import('./BusinessModelValueProposition').then(({ default: BusinessModelValueProposition }) => {
                BusinessModelValueProposition = BusinessModelValueProposition;
            });
			/*require(["appbo/vdml/BusinessModelValueProposition"],function(loadedModule){
				BusinessModelValueProposition = loadedModule;
			});*/
		}
	}
	if(!BusinessModelActivity){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModelActivity){
			BusinessModelActivity = importPath.BusinessModelActivity;
		}
		else{
			import('./BusinessModelActivity').then(({ default: BusinessModelActivity }) => {
                BusinessModelActivity = BusinessModelActivity;
            });
			/*require(["appbo/vdml/BusinessModelActivity"],function(loadedModule){
				BusinessModelActivity = loadedModule;
			});*/
		}
	}
	if(!BusinessModelCompetence){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModelCompetence){
			BusinessModelCompetence = importPath.BusinessModelCompetence;
		}
		else{
			import('./BusinessModelCompetence').then(({ default: BusinessModelCompetence }) => {
                BusinessModelCompetence = BusinessModelCompetence;
            });
			/*require(["appbo/vdml/BusinessModelCompetence"],function(loadedModule){
				BusinessModelCompetence = loadedModule;
			});*/
		}
	}
	if(!BusinessModelNetworkPartner){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModelNetworkPartner){
			BusinessModelNetworkPartner = importPath.BusinessModelNetworkPartner;
		}
		else{
			import('./BusinessModelNetworkPartner').then(({ default: BusinessModelNetworkPartner }) => {
                BusinessModelNetworkPartner = BusinessModelNetworkPartner;
            });
			/*require(["appbo/vdml/BusinessModelNetworkPartner"],function(loadedModule){
				BusinessModelNetworkPartner = loadedModule;
			});*/
		}
	}
	if(!BusinessModelValueFormula){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessModelValueFormula){
			BusinessModelValueFormula = importPath.BusinessModelValueFormula;
		}
		else{
			import('./BusinessModelValueFormula').then(({ default: BusinessModelValueFormula }) => {
                BusinessModelValueFormula = BusinessModelValueFormula;
            });
			/*require(["appbo/vdml/BusinessModelValueFormula"],function(loadedModule){
				BusinessModelValueFormula = loadedModule;
			});*/
		}
	}
	if(!Store){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Store){
			Store = importPath.Store;
		}
		else{
			import('./Store').then(({ default: Store }) => {
                Store = Store;
            });
			/*require(["appbo/vdml/Store"],function(loadedModule){
				Store = loadedModule;
			});*/
		}
	}
	if(!ValueStream){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueStream){
			ValueStream = importPath.ValueStream;
		}
		else{
			import('./ValueStream').then(({ default: ValueStream }) => {
                ValueStream = ValueStream;
            });
			/*require(["appbo/vdml/ValueStream"],function(loadedModule){
				ValueStream = loadedModule;
			});*/
		}
	}

	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? BusinessModel2Mixin : BusinessModelMixin;
	}
	var bmMixinClass = getMixinClass();
	var BusinessModel = MeasurableElement.extend(utils.customExtends({
		relations:bmMixinClass.getMixinRelations(),
		initialize: function(attributes, options) {
			MeasurableElement.prototype.initialize.apply(this, arguments);
			/*this.on("add:bmResourceStore", this.addStoreToScenarioContexts);
			this.on("remove:bmRole", this.handleOnBMRoleDeleted);
			this.on("remove:bmActivity", this.handleOnBMActivityDeleted);
			this.on("remove:bmValueProposition", this.handleOnBMVPDeleted);
			this.on("remove:bmValueFormula", this.handleOnBMVFDeleted);*/
		}
		}
		, new BMElementMixin()
		, new bmMixinClass()
	));
	
//#startCustomMethods		
	BusinessModel.prototype.getSystemTags = function(){
		return ['VDM','Business Model','Prototype'];
	};
	BusinessModel.getInstance = function(name,description,vdm,id){
		var bmId = id?id:DataManager.getDataManager().guidGeneratorByOwner(vdm);
	    var bm = new BusinessModel({'id':bmId,
	    	'name':name,
	    	'description':description,
	    	'businessModelOwner' : vdm});
	    vdm.get('businessModel').add(bm);
		return bm;
	};	
    BusinessModel["add:bmResourceStore"] = function (store) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.addStoreToScenarioContexts(store);
		}
    }
    BusinessModel["remove:bmRole"] = function (role, collection, options) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.handleOnBMRoleDeleted(role, collection, options);
		}
    }
    BusinessModel["remove:bmActivity"] = function (bmActivity) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.handleOnBMActivityDeleted(bmActivity);
		}
    }
    BusinessModel["remove:bmValueProposition"] = function (bmVP) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.handleOnBMVPDeleted(bmVP);
		}
    }
    BusinessModel["remove:bmValueFormula"] = function (bmVF) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.handleOnBMVFDeleted(bmVF);
		}
    }
	BusinessModel.abstract = false;
//#endCustomMethods
	path.BusinessModel = BusinessModel;
	export {BusinessModel};
//});