import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {CollaborationDiagram} from './CollaborationDiagram'
import {EcoMapDiagramMixin} from './EcoMapDiagramMixin'
import {EcoMapDiagram2Mixin} from './EcoMapDiagram2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/EcoMapDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,EcoMapDiagramMixin
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? EcoMapDiagram2Mixin : EcoMapDiagramMixin;
	}
	var mixinClass = getMixinClass();
	var EcoMapDiagram = CollaborationDiagram.extend(utils.customExtends({
		relations:mixinClass.getMixinRelations(),
		initialize: function(attributes, options) {
		    CollaborationDiagram.prototype.initialize.apply(this, arguments);
		    /*this.on('remove:participant', this.handleRemoveParticipant); 
		    this.on('remove:businessModel', this.handleRemoveBusinessModel);
		    this.on('remove:valueProposition', this.handleRemoveValueProposition);*/
		}
		}
		, new mixinClass()
	));
    EcoMapDiagram['remove:participant'] = function (model, coll, options) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.handleRemoveParticipant(model, coll, options);
		}
    }
    EcoMapDiagram['remove:businessModel'] = function(model, coll, options) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.handleRemoveBusinessModel(model, coll, options);
		}
    }
    EcoMapDiagram['remove:valueProposition'] = function (model, coll, options) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.handleRemoveValueProposition(model, coll, options);
		}
    };
	EcoMapDiagram.getInstance = function(name,description,owner,id){
		var eid = id ? id : DataManager.getDataManager().guidGeneratorByOwner(owner);
		var ecoMap = new EcoMapDiagram({id:eid,name:owner.getUniqueProperty('name',name,'diagrams'),description:owner.getUniqueProperty('description',description,'diagrams'), ecoMap:owner});
		//var ownerProgress = owner.get('progress') + DataManager.getDataManager().get('progress').BusinessEcosystemMap.Value;
		//owner.set('progress', ownerProgress);
		return ecoMap;
	}
	var ecoMapTitle = "Business Ecosystem Map";//DataManager.getDataManager().get('localeManager').get("EcoMap");
	EcoMapDiagram.prototype.getSystemTags = function () {
	    return ['VDM', ecoMapTitle,'Discover'];
	};
	
	EcoMapDiagram.abstract = false;
	EcoMapDiagram.supported = true;
	path.EcoMapDiagram = EcoMapDiagram;
	export {EcoMapDiagram};
//});