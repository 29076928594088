import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {SmmElement} from './SmmElement'
import {BusinessIndicatorLibraryElementMixin} from './BusinessIndicatorLibraryElementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/smm/SmmElement", "appbo/smm/BusinessIndicatorLibraryElementMixin"],
//    function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, SmmElement, BusinessIndicatorLibraryElementMixin) {

        var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);

        var BusinessIndicatorLibraryElement = SmmElement.extend(utils.customExtends({
            relations: BusinessIndicatorLibraryElementMixin.getMixinRelations(),
            subModelTypes: {
                smm_BusinessIndicatorCategory: 'smm.BusinessIndicatorCategory',
                smm_BusinessIndicatorDefinition: 'smm.BusinessIndicatorDefinition'
            },
            initialize: function (attributes, options) {
                SmmElement.prototype.initialize.apply(this, arguments);
            }
        }
            , new BusinessIndicatorLibraryElementMixin()
        ));
        var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
       BusinessIndicatorLibraryElement.abstract = sp2Enabled;
       BusinessIndicatorLibraryElement.supported = !sp2Enabled;
        path.BusinessIndicatorLibraryElement = BusinessIndicatorLibraryElement;
        export {BusinessIndicatorLibraryElement};true
    //});