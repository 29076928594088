import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurableElement} from './MeasurableElement'
import {MeasurableElementMixin} from './MeasurableElementMixin'
import {CompetenceMixin} from './CompetenceMixin'
import {Competence} from './Competence'
import {DeliverableFlow} from './DeliverableFlow'
import {BusinessItemDefinition} from './BusinessItemDefinition'
import {Store} from './Store'
import {CapabilityMethod} from './CapabilityMethod'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/MeasurableElementMixin","appbo/vdml/CompetenceMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,MeasurableElementMixin
, Competence
, CompetenceMixin
, DeliverableFlow
, BusinessItemDefinition
, Store
, CapabilityMethod){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Competence){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Competence){
			Competence = importPath.Competence;
		}
		else{
			import('./Competence').then(({ default: Competence }) => {
                Competence = Competence;
            });
			/*require(["appbo/vdml/Competence"],function(loadedModule){
				Competence = loadedModule;
			});*/
		}
	}
	if(!CompetenceMixin){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CompetenceMixin){
			CompetenceMixin = importPath.CompetenceMixin;
		}
		else{
			import('./CompetenceMixin').then(({ default: CompetenceMixin }) => {
                CompetenceMixin = CompetenceMixin;
            });
			/*require(["appbo/vdml/CompetenceMixin"],function(loadedModule){
				CompetenceMixin = loadedModule;
			});*/
		}
	}
	if(!DeliverableFlow){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.DeliverableFlow){
			DeliverableFlow = importPath.DeliverableFlow;
		}
		else{
			import('./DeliverableFlow').then(({ default: DeliverableFlow }) => {
                DeliverableFlow = DeliverableFlow;
            });
			/*require(["appbo/vdml/DeliverableFlow"],function(loadedModule){
				DeliverableFlow = loadedModule;
			});*/
		}
	}
	if(!BusinessItemDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemDefinition){
			BusinessItemDefinition = importPath.BusinessItemDefinition;
		}
		else{
			import('./BusinessItemDefinition').then(({ default: BusinessItemDefinition }) => {
                BusinessItemDefinition = BusinessItemDefinition;
            });
			/*require(["appbo/vdml/BusinessItemDefinition"],function(loadedModule){
				BusinessItemDefinition = loadedModule;
			});*/
		}
	}
	if(!Store){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Store){
			Store = importPath.Store;
		}
		else{
			import('./Store').then(({ default: Store }) => {
                Store = Store;
            });
			/*require(["appbo/vdml/Store"],function(loadedModule){
				Store = loadedModule;
			});*/
		}
	}
	if(!CapabilityMethod){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityMethod){
			CapabilityMethod = importPath.CapabilityMethod;
		}
		else{
			import('./CapabilityMethod').then(({ default: CapabilityMethod }) => {
                CapabilityMethod = CapabilityMethod;
            });
			/*require(["appbo/vdml/CapabilityMethod"],function(loadedModule){
				CapabilityMethod = loadedModule;
			});*/
		}
	}
	export class BusinessItemMixin{
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.BusinessItemMixin = BusinessItemMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_BusinessItem"
		}
		return jQuery.extend(MeasurableElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessItem",
			key:"flow",
			relatedModel:"vdml.DeliverableFlow",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"deliverable",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_BusinessItem",
			key:"definition",
			relatedModel:"vdml.BusinessItemDefinition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessItem",
			key:"store",
			relatedModel:"vdml.Store",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"resource",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_BusinessItem",
			key:"method",
			relatedModel:"vdml.CapabilityMethod",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!BusinessItemMixin.cummulativeRelations) {
            BusinessItemMixin.cummulativeRelations = _.union(BusinessItemMixin.getMixinRelations()
               ,CompetenceMixin.getCumulativeMixinRelations()
		       ,MeasurableElementMixin.getCumulativeMixinRelations()
            );
        }
		return BusinessItemMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_Competence",
			"vdml_MeasurableElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "isFungible",type : "EBoolean",defaultValue : "null",containingClass : "vdml_BusinessItem" },
			{name : "isShareable",type : "EBoolean",defaultValue : "null",containingClass : "vdml_BusinessItem" },
			{name : "forAutomation",type : "EBoolean",defaultValue : "null",containingClass : "vdml_BusinessItem" },
			{name : "type",type : "EString",defaultValue : "null",containingClass : "vdml_BusinessItem" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("businessItemOwner") ? this.get("businessItemOwner") : this.previousAttributes().businessItemOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("businessItemOwner") ? this.get("businessItemOwner") : this.previousAttributes().businessItemOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	handleResourceNameChange(model,newName){
		if(Backbone.skipPersistance){
			return;
		}
		var self= this;
        DataManager.getDataManager().getReverseAssociationInstances(self, 'vdml_BusinessModelCompetence-competence', 'vdml_BusinessModelCompetence', "vbc:" + "cmof_EObject-name", 
		function(bmCompetencies){	
			bmCompetencies.each(function(bmCompetence){
				if(bmCompetence.get('name') !== newName){
					bmCompetence.set('name',newName);
				}
			});
		}, true);
		var store = self.get('store').findWhere({'resource':model});
		if(store){
			store.set('name',newName+' Store');
			store.set('description',newName+' Store');
		}
		var resourceFlows = self.get('flow');
		if(resourceFlows){
			for(var i=0;i<resourceFlows.length;i++){
				var flow = resourceFlows.at(i);
				if(flow.get('deliverable') === model){
					var counterString = '';
					if(i >= 1){
						counterString = ''+i;
					}
					flow.set('name',newName+''+counterString);
					flow.set('description',newName+''+counterString);
					if(flow.get('provider')){
						flow.get('provider').set('name',newName+' Output'+counterString);
						flow.get('provider').set('description',newName+' Output'+counterString);
					}
					if(flow.get('recipient')){
						flow.get('recipient').set('name',newName+' Input');
						flow.get('recipient').set('description',newName+' Input');
					}
				}
			}
		}
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/BusinessItemPropertiesTemplate.html",
			templateName : "BusinessItemPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/BusinessItemViewModel",
			tabId : "BusinessItemView",
			tabName: "BusinessItem"
		};
	};
	
	copy(sourceColl,targetColl,measurementsCopied){
		var biId = DataManager.getDataManager().guidGeneratorByOwner(targetColl);
		var nameBase = (sourceColl === targetColl) ? 'Copy of ' + this.get('name') : this.get('name');
		var nameStr = targetColl.getUniqueProperty('name',nameBase,'businessItem');
		var copyBi = new this.constructor({id:biId,name:nameStr,description:this.get('name'),businessItemOwner:targetColl});
		var mesChars = this.get('measuredCharacteristic');
		mesChars.each(function(mesChar){
			mesChar.copy(copyBi,measurementsCopied);			
		});
		return copyBi;
	};
	}
	path.BusinessItemMixin = BusinessItemMixin;
	//return BusinessItemMixin;
//});