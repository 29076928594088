import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin){
	

	export class PreferenceMixin{
		constructor(){
			var path = DataManager.getDataManager().buildAppNsPath("preference",global.version);
			path.PreferenceMixin = PreferenceMixin;
		}
	
	defaults(){
		var ret = {
			type: "preference_Preference"
		}
		return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!PreferenceMixin.cummulativeRelations) {
            PreferenceMixin.cummulativeRelations = _.union(PreferenceMixin.getMixinRelations()
                , EObjectMixin.getCumulativeMixinRelations()
            );
        }
		return PreferenceMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"cmof_EObject"
		];
	}
	static getProperties(){
		return [
			{name : "context",type : "EString",defaultValue : "null",containingClass : "preference_Preference" },
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			//{name : "value",type : "EString",defaultValue : "null",containingClass : "preference_Preference" } commenting this as it is not required in rdf.
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("preferenceOwner") ? this.get("preferenceOwner") : this.previousAttributes().preferenceOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("preferenceOwner") ? this.get("preferenceOwner") : this.previousAttributes().preferenceOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	static getViewProperties(type){
		return {
			templatePath : "views/preference/views/properties/PreferencePropertiesTemplate.html",
			templateName : "PreferencePropertiesTemplate",
			viewTypeStr : "appviews/preference/views/properties/PreferenceViewModel",
			tabId : "PreferenceView",
			tabName: "Preference"
		}
	}
	//path.PreferenceMixin = PreferenceMixin;
	//return PreferenceMixin;
}