import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {AbstractMeasureElement} from './AbstractMeasureElement'
import {AbstractMeasureElementMixin} from './AbstractMeasureElementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { DirectMeasure } from './DirectMeasure';
import { Scope } from './Scope';
import { Measure } from './Measure';
import { EquivalentMeasureRelationship } from './EquivalentMeasureRelationship';
import { MeasureRelationship } from './MeasureRelationship';

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/AbstractMeasureElement","appbo/smm/AbstractMeasureElementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,AbstractMeasureElement,AbstractMeasureElementMixin
, DirectMeasure
, Scope

, Measure
, EquivalentMeasureRelationship
, MeasureRelationship){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!DirectMeasure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DirectMeasure){
			DirectMeasure = importPath.DirectMeasure;
		}
        else {
            import('./DirectMeasure').then(({ default: DirectMeasure }) => {
                DirectMeasure = DirectMeasure;
            });
            /*require(["appbo/smm/DirectMeasure"], function (loadedModule) {
                DirectMeasure = loadedModule;
			});*/
		}
	}
	if(!Scope){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Scope){
			Scope = importPath.Scope;
		}
        else {
            import('./Scope').then(({ default: Scope }) => {
                Scope = Scope;
            });
            /*require(["appbo/smm/Scope"], function (loadedModule) {
                Scope = loadedModule;
			});*/
		}
	}
	
	if(!Measure){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Measure){
			Measure = importPath.Measure;
		}
        else {
            import('./Measure').then(({ default: Measure }) => {
                Measure = Measure;
            });
           /* require(["appbo/smm/Measure"], function (loadedModule) {
                Measure = loadedModule;
			});*/
		}
	}
	if(!EquivalentMeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.EquivalentMeasureRelationship){
			EquivalentMeasureRelationship = importPath.EquivalentMeasureRelationship;
		}
        else {
            import('./EquivalentMeasureRelationship').then(({ default: EquivalentMeasureRelationship }) => {
                EquivalentMeasureRelationship = EquivalentMeasureRelationship;
            });
            /*require(["appbo/smm/EquivalentMeasureRelationship"], function (loadedModule) {
                EquivalentMeasureRelationship = loadedModule;
			});*/
		}
	}
	if(!MeasureRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.MeasureRelationship){
			MeasureRelationship = importPath.MeasureRelationship;
		}
        else {
            import('./MeasureRelationship').then(({ default: MeasureRelationship }) => {
                MeasureRelationship = MeasureRelationship;
            });
            /*require(["appbo/smm/MeasureRelationship"], function (loadedModule) {
                MeasureRelationship = loadedModule;
			});*/
		}
	}
	export class OperationMixin {

	defaults(){
		var ret = {
			type: "smm_Operation"
		}
		return jQuery.extend(AbstractMeasureElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"smm_Operation",
			key:"directMeasure",
			relatedModel:"smm.DirectMeasure",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"operation",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		/*{
			type :Backbone.HasMany,
			containingClass:"smm_Operation",
			key:"breakConditionScope",
			relatedModel:"smm.Scope",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"breakCondition",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"smm_Operation",
			key:"recognizerScope",
			relatedModel:"smm.Scope",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"recognizer",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},*/
		{
			type :Backbone.HasMany,
			containingClass:"smm_Operation",
			key:"measure",
			relatedModel:"smm.Measure",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"defaultQuery",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		/*{
			type :Backbone.HasMany,
			containingClass:"smm_Operation",
			key:"equivalentMeasureRelationship",
			relatedModel:"smm.EquivalentMeasureRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"mapping",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},*/
		{
			type :Backbone.HasMany,
			containingClass:"smm_Operation",
			key:"measureRelationship",
			relatedModel:"smm.MeasureRelationship",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"measuradQuery",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!OperationMixin.cummulativeRelations) {
            OperationMixin.cummulativeRelations = _.union(OperationMixin.getMixinRelations()
                , AbstractMeasureElementMixin.getCumulativeMixinRelations()
            );
        }
		return OperationMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_AbstractMeasureElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            //{ name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			//{name : "body",type : "EString",defaultValue : "null",containingClass : "smm_Operation" },
			{name : "language",type : "EString",defaultValue : "null",containingClass : "smm_Operation" }
		]
	}
	getParent(){
		var container;
		return AbstractMeasureElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return AbstractMeasureElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/OperationPropertiesTemplate.html",
			templateName : "OperationPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/OperationViewModel",
			tabId : "OperationView",
			tabName: "Operation"
		}
	}
	
	}
	path.OperationMixin = OperationMixin;
	//return OperationMixin;
//});