import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VDMLDiagram} from './VDMLDiagram'
import {CollaborationDiagramMixin} from './CollaborationDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ValueDeliveryModel } from './ValueDeliveryModel';
//import { Collaboration } from './Collaboration';

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VDMLDiagram","appbo/vdml/CollaborationDiagramMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VDMLDiagram,CollaborationDiagramMixin
, ValueDeliveryModel
, Collaboration){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!ValueDeliveryModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueDeliveryModel){
			ValueDeliveryModel = importPath.ValueDeliveryModel;
		}
        else {
            import('./ValueDeliveryModel').then(({ default: ValueDeliveryModel }) => {
                ValueDeliveryModel = ValueDeliveryModel;
            });
            /*require(["appbo/vdml/ValueDeliveryModel"], function (loadedModule) {
                ValueDeliveryModel = loadedModule;
			});*/
		}
	}
	//if(!Collaboration){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Collaboration){
			//Collaboration = importPath.Collaboration;
		}
		else{
           /* import('./Collaboration').then(({ default: Collaboration }) => {
                Collaboration = Collaboration;
            });
            require(["appbo/vdml/Collaboration"], function (loadedModule) {
                Collaboration = loadedModule;
			});*/
		}
	//}
	var CollaborationDiagram = VDMLDiagram.extend(utils.customExtends({
		relations:CollaborationDiagramMixin.getMixinRelations(),
		subModelTypes: {
		    vdml_EcoMapDiagram: 'vdml.EcoMapDiagram',
		    vdml_ActivityNetworkDiagram : 'vdml.ActivityNetworkDiagram',
			vdml_ProcessDiscoveryDiagram : 'vdml.ProcessDiscoveryDiagram',
			vdml_RoleCollaborationDiagram : 'vdml.RoleCollaborationDiagram',
			vdml_CmmnDiagram : 'vdml.CmmnDiagram',
			vdml_CapabilityOfferDiagram : 'vdml.CapabilityOfferDiagram',
			vdml_CapabilityLibraryDiagram : 'vdml.CapabilityLibraryDiagram',
			vdml_ValuePropositionDiagram : 'vdml.ValuePropositionDiagram',
			vdml_StrategyMapDiagram: 'vdml.StrategyMapDiagram',
            vdml_ValueStreamMapDiagram: 'vdml.ValueStreamMapDiagram',
            smm_BusinessIndicatorLibraryDiagram: 'smm.BusinessIndicatorLibraryDiagram'
		},
		initialize: function(attributes, options) {
			VDMLDiagram.prototype.initialize.apply(this, arguments);
		}
		}
		, new CollaborationDiagramMixin()
	));
	CollaborationDiagram["change:data"] = function (model,newData) {
		var previousData = model._previousAttributes ? model._previousAttributes.data:"";
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(previousData != newData && suppressChangeLogging) {
			utils.updateDiagram(model, false);
		}
    }
	CollaborationDiagram['destroy'] = function (model) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(suppressChangeLogging) {
			utils.updateDiagram(model,true);
		} else {
			var EcoMapMixin = Backbone.Relational.store.getObjectByName("ecomap.EcoMapMixin");
			var deletedCodedContainers = EcoMapMixin.fetchImagesFromXML(model.get('data'));
			EcoMapMixin.removeImageArtifact(deletedCodedContainers,model,[model.id]);
		}
    }
	
	CollaborationDiagram.abstract = false;
	CollaborationDiagram.supported = true;
	path.CollaborationDiagram = CollaborationDiagram;
	export {CollaborationDiagram};
//});