import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {DimensionalMeasurement} from './DimensionalMeasurement'
import {RankingMeasurementMixin} from './RankingMeasurementMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {RankingMeasurementRelationship} from './RankingMeasurementRelationship'
import {Operation} from './Operation'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/DimensionalMeasurement","appbo/smm/RankingMeasurementMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,DimensionalMeasurement,RankingMeasurementMixin
, RankingMeasurementRelationship
, Operation){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!RankingMeasurementRelationship){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.RankingMeasurementRelationship){
			RankingMeasurementRelationship = importPath.RankingMeasurementRelationship;
		}
        else {
            import('./RankingMeasurementRelationship').then(({ default: RankingMeasurementRelationship }) => {
                RankingMeasurementRelationship = RankingMeasurementRelationship;
            });
			/*require(["appbo/smm/RankingMeasurementRelationship"],function(loadedModule){
				RankingMeasurementRelationship = loadedModule;
			});*/
		}
	}
	if(!Operation){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.Operation){
			Operation = importPath.Operation;
		}
        else {
            import('./Operation').then(({ default: Operation }) => {
                Operation = Operation;
            });
			/*require(["appbo/smm/Operation"],function(loadedModule){
				Operation = loadedModule;
			});*/
		}
	}
	var RankingMeasurement = DimensionalMeasurement.extend(utils.customExtends({
		relations:RankingMeasurementMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			DimensionalMeasurement.prototype.initialize.apply(this, arguments);
		}
		}
		, new RankingMeasurementMixin()
	));
	var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
	RankingMeasurement.abstract = false;
	RankingMeasurement.supported =  !sp2Enabled;
	path.RankingMeasurement = RankingMeasurement;
	export {RankingMeasurement};
//});