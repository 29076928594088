import * as _ from 'underscore'
import { ValuePropositionComponentMixin } from "./ValuePropositionComponentMixin";
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ValueElement2Mixin } from './ValueElement2Mixin';
import { Unit } from './Unit';
import { ValueType } from './ValueType';
import { ScenarioMeasurement } from '../transformation/ScenarioMeasurement';

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
export class ValuePropositionComponent2Mixin {
    static getMixinRelations(){
        var ret = ValuePropositionComponentMixin.getMixinRelations().filter(item =>{
            return item.key !== "percentageWeight" && item.key !== "satisfactionLevel" && item.key !== "recipientOpinion";
        });
        ret = _.union(ret,[
                {
                    type :Backbone.HasOne,
                    containingClass:"vdml_ValuePropositionComponent",
                    key:"percentageWeight",
                    relatedModel:"vdml.ValueElement",
                    reverseRelation: {
                        key:"percentageWeightOwner",
                        type :Backbone.HasOne,
                        includeInJSON:"id"
                    }
                },
                {
                    type :Backbone.HasOne,
                    containingClass:"vdml_ValuePropositionComponent",
                    key:"satisfactionLevel",
                    relatedModel:"vdml.ValueElement",
                    reverseRelation: {
                        key:"satisfactionLevelOwner",
                        type :Backbone.HasOne,
                        includeInJSON:"id"
                    }
                },
                {
                    type :Backbone.HasOne,
                    containingClass:"vdml_ValuePropositionComponent",
                    key:"recipientOpinion",
                    relatedModel:"vdml.ValueElement",
                    reverseRelation: {
                        key:"recipientOpinionOwner",
                        type :Backbone.HasOne,
                        includeInJSON:"id"
                    }
                }
            ])
        return ret;
    }
    
    static getCumulativeMixinRelations(){
		if (!ValuePropositionComponent2Mixin.cummulativeRelations) {
            ValuePropositionComponent2Mixin.cummulativeRelations = _.union(ValuePropositionComponent2Mixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("ValueElement","vdml").getCumulativeMixinRelations()
            );
        }
		return ValuePropositionComponent2Mixin.cummulativeRelations.slice();
    }
	changeValFormula(model,previousName){
		var self = this;
		var aggrToValues = model.get('aggregatedTo').models;
		if(!aggrToValues){ return };
		debugger
		var newVariableId = DataManager.getDataManager().htmlEscape(model);
		var oldVariableId = utils.valFormulaHtmlEscape(previousName,model.id);
		for(var i=0;i<aggrToValues.length;i++){
			var context = aggrToValues[i].get('context');
			for(var j=0;j<context.length;j++){
				var formula = context.models[j].get('formula');
				if(formula){
					var expr = formula.get('expressionStr');
					if(expr){
						var replacedExp = expr.replace(oldVariableId,newVariableId);
						formula.set('expressionStr',replacedExp);
					}
				}
			}
		}
	}
	primeWeight(year,period,percentageWeight, weight, scenario ,viewAlternative){
		var self = this;
		percentageWeight.set('valueType',ValueType.Atomic);
		percentageWeight.addRefToAlternative(viewAlternative);
		var unitToMatch = { unitName: '%', roundingDigits: 4, significantDecimals: 2 };
		var weightQty = self.getRoundingQty(percentageWeight,weight);
		self.addScenarioMeasurement(year,period,scenario,percentageWeight,weightQty,viewAlternative,unitToMatch);
	}
	primeRecipientOpinion(year, period, recipientValue, recipientOpinion, scenario, viewAlternative,valueUnit){
		var self = this;
		recipientOpinion.set('valueType',ValueType.Atomic);
		recipientOpinion.addRefToAlternative(viewAlternative);
		var repQty = self.getRoundingQty(recipientOpinion,recipientValue);
		self.addScenarioMeasurement(year,period,scenario,recipientOpinion,repQty,viewAlternative,valueUnit);
	}
	addScenarioMeasurement(year,period,scenario,valModel,value,viewAlternative,unitToMatch){
		var measurement = scenario.createMeasurement(valModel,viewAlternative,year, period, null,unitToMatch,[]);
		if(measurement && year!==undefined){
			measurement.setValue(value);
		}
	}
	primeSatisfaction(year, period, satisfactionLevel,satisfactionType,isDimensional,satisfactionValue,satisfactionUnit,scenario,intervalTuples,viewAlternative){
        var self = this;
        var context = satisfactionLevel ? satisfactionLevel.getValueContext(viewAlternative.id):null;
        if(!context && isDimensional){
			var plan = DataManager.getDataManager().get("currentPlan");
			var defaultExecutionScenaroId = plan.get("defaultExecutionScenario");
			var scenarioExecution = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
			satisfactionLevel.set('valueType',ValueType.Aggregated);
			satisfactionLevel.addRefToAlternative(viewAlternative);
			satisfactionLevel.addSatisfactionBaseTuple(self);
            context = satisfactionLevel.createValueElementContext(viewAlternative);
			let periodDataset = scenarioExecution.get("result").findWhere({ year:year,period:period,periodKind:plan.get("periodKind")});
			if(self.get('valueType')==ValueType.Atomic && periodDataset){
				periodDataset.set(satisfactionLevel.get("id"),parseFloat(satisfactionValue));
			}
			if (satisfactionType === 'Grade') {
				satisfactionLevel.set("isNominal",true);
			} else if (satisfactionType === 'Ranking') {
				satisfactionLevel.set("isNominal",false);
				var plan = DataManager.getDataManager().get("currentPlan");
				var defaultValLib = plan.get("defaultValueLibrary");
				var unit = defaultValLib.get('unit').findWhere({name:satisfactionUnit.unitName});
				if(satisfactionUnit && satisfactionUnit.unitName != "" && !unit){
					unit = Unit.getInstance(satisfactionUnit.unitName,satisfactionUnit.unitName,satisfactionUnit.roundingDigits,satisfactionUnit.significantDecimals,defaultValLib);
					satisfactionLevel.set("unit",unit);
				}
				else if(satisfactionUnit && satisfactionUnit.unitName != "" && unit){
					satisfactionLevel.set('unit',unit);
				}
			}
			satisfactionLevel.createExpression(context, null,JSON.stringify(intervalTuples));
        }else if(!context && !isDimensional){
            context = self.createValueElementContext(viewAlternative);
            self.set("isNominal",true);
			self.set('valueType',ValueType.Atomic);
            self.createExpression(context, null,JSON.stringify(intervalTuples));
			self.addRefToAlternative(viewAlternative);
			self.addScenarioMeasurement(year,period,scenario,self,satisfactionValue,viewAlternative);
        }
    }

    prime(year,period, value,valueUnit,valFormula,satisfactionValue,satisfactionType,isDimensional,satisfactionUnit,weight,recipient,baseTuples,interValTuples,viewAlternative,maxChange,projectionProfile,valueConstraint,callback){
		var self = this;
		var recipientOpinion = self.get('recipientOpinion');
        var satisfactionLevel = self.get('satisfactionLevel');
        var percentageWeight = self.get('percentageWeight');
		self.addBaseTuple(baseTuples);
		var scenario = viewAlternative.getDefaultScenario();
		if(isDimensional) {
           self.primeValueMeasurement(year, period, value, valueUnit, valFormula, baseTuples, viewAlternative, maxChange, projectionProfile,valueConstraint);
            if (recipient && recipient != "") {
				if(!recipientOpinion) {
					self.getNestedParent().createValueDetails(self, null, interValTuples.length > 0, weight, recipient);
					recipientOpinion = self.get('recipientOpinion');
				}
				self.primeRecipientOpinion(year, period, recipient, recipientOpinion, scenario, viewAlternative,valueUnit);
			}
		}
		
		if(interValTuples.length > 0) {
			if (!satisfactionLevel && isDimensional) {
				self.getNestedParent().createValueDetails(self, null, interValTuples.length > 0, weight, recipient);
				satisfactionLevel = self.get('satisfactionLevel');
			}
			self.primeSatisfaction(year,period,satisfactionLevel, satisfactionType, isDimensional, satisfactionValue, satisfactionUnit, scenario, interValTuples, viewAlternative);
		}
        
        if (weight && weight !== "") {
			if(!percentageWeight) {
				self.getNestedParent().createValueDetails(self, null, interValTuples.length > 0, weight, recipient);
				percentageWeight = self.get('percentageWeight');
			}
            self.primeWeight(year, period,percentageWeight, weight, scenario ,viewAlternative);
        }
		if(isDimensional) {
            if(callback){
                callback(true);
            }
		}
		else {
			callback();
		}
	};

    update(period, year, name,value,valueUnit,valFormula,satisfactionType,isDimensional,satisfactionValue,satisfactionUnit,weight,recipientValue,baseTuples,intervalTuples,viewAlternative,maxChange,projectionProfile,constraintValue,callback){
		var self = this;
		var plan = DataManager.getDataManager().get("currentPlan");
        var defaultValLib = plan.get("defaultValueLibrary");
		var defaultExecutionScenaroId = plan.get("defaultExecutionScenario");
		var defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
		var periodDataset = defaultExecutionScenaro.get("input").findWhere({period: period,year:year,periodKind : plan.get("periodKind")});
		var changeInValue = false;
		var scenario = viewAlternative.getDefaultScenario();
		var satisfactionLevel = self.get('satisfactionLevel');
		var percentageWeight = self.get('percentageWeight');
		var recipientOpinion = self.get('recipientOpinion');
		var nominalVal = self.get('isNominal');
		
		if (!recipientOpinion) {
			self.getNestedParent().createValueDetails(self, null, intervalTuples.length > 0  && isDimensional, weight, recipientValue);
			recipientOpinion = self.get('recipientOpinion');
			if (recipientValue && recipientValue !== "" && recipientOpinion) {
				self.primeRecipientOpinion(year, period, recipientValue, recipientOpinion, scenario, viewAlternative, valueUnit);
			}
		}
		if(!satisfactionLevel && !nominalVal){
			self.getNestedParent().createValueDetails(self, null, intervalTuples.length > 0  && isDimensional, weight, recipientValue);
			satisfactionLevel = self.get('satisfactionLevel');
			if (intervalTuples.length > 0 && satisfactionLevel) {
				self.primeSatisfaction(year,period,satisfactionLevel, satisfactionType, isDimensional, satisfactionValue, satisfactionUnit, scenario, intervalTuples,viewAlternative);
			}
		}
		if(!percentageWeight){
			self.getNestedParent().createValueDetails(self, null, intervalTuples.length > 0  && isDimensional, weight, recipientValue);
			percentageWeight = self.get('percentageWeight');
			if (weight && weight !== "" && percentageWeight) {
				self.primeWeight(year,period,percentageWeight, weight, scenario ,viewAlternative);
			}
		}
		self.updateValueElementChanges(period, year, name, value, valueUnit, valFormula, baseTuples, viewAlternative, maxChange,constraintValue, function(ichangeInValue){
			changeInValue = ichangeInValue;
			if(recipientOpinion){
				handleRecipientValueChange(recipientValue);
			}
			if(satisfactionLevel){
				handleSatisfaction(intervalTuples);
			}
			if(nominalVal){
				handleNominalValues();
			}
			if(percentageWeight){
				handleWeightChange(weight);
			}
			callback(changeInValue);
		})
		function handleSatisfaction() {
			handleSatisfactionUnitChange(defaultValLib);
			handleSatisfactionTypeChange();
			handleSatisfactionIntervalsChange(intervalTuples);
			removeAssociationsForSatisfactionOnly();
		}
		function handleNominalValues(){
			var nominalContext = self.getValueContext(viewAlternative.id);
			if(!nominalContext){
				nominalContext = self.createValueElementContext(viewAlternative);
			}
			var exp = nominalContext.get('formula');
			if(intervalTuples.length == 0){
				exp.set('expressionConfig',null);
			}
			else{
				exp.set('expressionConfig',JSON.stringify(intervalTuples));
				self.addScenarioMeasurement(year,period,scenario,self,satisfactionValue,viewAlternative);
			}
		}
		function removeAssociationsForSatisfactionOnly() {
			if (!isDimensional && satisfactionType === "Grade") {
				var aggToColl = self.get('aggregatedTo');
				aggToColl.each(function (agg) {
					agg.get('aggregatedFrom').remove(self);
				});
			}
		}
		
        function handleSatisfactionIntervalsChange(intervalTuples) {
			var context = satisfactionLevel.getValueContext(viewAlternative.id);
			if(!context && intervalTuples.length>0){
				var context = satisfactionLevel.createValueElementContext(viewAlternative);
				satisfactionLevel.createExpression(context, null,JSON.stringify(intervalTuples));
			}else if(context){
				var exp = context.get('formula');
				if(intervalTuples.length==0){
					exp.set('expressionConfig',null);
				}
				else{
					exp.set('expressionConfig',JSON.stringify(intervalTuples))
				}
			}
		}
		
        function handleWeightChange(weight) {
			if(weight!=null || weight!=undefined){
				var weightQty = self.getRoundingQty(percentageWeight,weight);
				self.addScenarioMeasurement(year,period,scenario,percentageWeight,weightQty,viewAlternative);
			}
		}		
		function handleRecipientValueChange(recipientValue){
			if(recipientValue!=null || recipientValue!=undefined){
				var repQty = self.getRoundingQty(recipientOpinion,recipientValue);
				self.addScenarioMeasurement(year,period,scenario,recipientOpinion,repQty,viewAlternative);
			}
		}

		
		function handleSatisfactionTypeChange(){
            if(satisfactionType == 'Grade' && isDimensional){
				satisfactionLevel.set('isNominal',true);
				satisfactionLevel.set("unit",null);
			}
			else if(satisfactionType == 'Ranking' && isDimensional){
				satisfactionLevel.set('isNominal',false);
			}
		}
		
		function handleSatisfactionUnitChange(defaultValLib){
			var unit = defaultValLib.get('unit').findWhere({name:satisfactionUnit.unitName});
			if(satisfactionUnit && satisfactionUnit.unitName != "" && !unit){
				unit = Unit.getInstance(satisfactionUnit.unitName,satisfactionUnit.unitName,satisfactionUnit.roundingDigits,satisfactionUnit.significantDecimals,defaultValLib);
				satisfactionLevel.set("unit",unit);
			}
			else if(satisfactionUnit && satisfactionUnit.unitName != "" && unit){
				satisfactionLevel.set("unit",unit);
			}
		}

		
	};
}
utils.customExtendClass (ValuePropositionComponent2Mixin,new ValuePropositionComponentMixin());

path.ValuePropositionComponent2Mixin = ValuePropositionComponent2Mixin;