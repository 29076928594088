import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BeepPackage} from '../beeppackage/BeepPackage'
import {ScenarioExecutionMixin} from './ScenarioExecutionMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
var ScenarioExecution = BeepPackage.extend(utils.customExtends({
        relations:ScenarioExecutionMixin.getMixinRelations(),
        initialize: function(attributes, options) {
            BeepPackage.prototype.initialize.apply(this, arguments);
        }
    }
    , new ScenarioExecutionMixin()
));
//#startCustomMethods			
//#endCustomMethods
ScenarioExecution.getName = function(){
    return "ScenarioExecution";
};

ScenarioExecution.abstract = false;
ScenarioExecution.supported = true;
path.ScenarioExecution = ScenarioExecution;
export {ScenarioExecution};
