import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {TicketStatus} from '../../../../../version1/bo/tickets/TicketStatus'
import {TicketViewModel} from './TicketViewModel'

/*define(["require", "jquery", "underscore", "backbone", "knockout", "knockback", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global","appviews/tickets/views/properties/TicketViewModel","appbo/tickets/User","appbo/tickets/TicketStatus","appbo/tickets/Ticket","appbo/tickets/Comment"],
function(require, $, _, Backbone, ko, kb, bootbox, DataManager, global,TicketViewModel,User,TicketStatus,Ticket,Comment){
*/	
	var path = DataManager.getDataManager().buildAppNsPath("ticket.views.properties",global.version);
export class ColloborationViewModel {
    constructor(model,options){
		var self = this;
		model = model.relModel?model.relModel:model.getNestedParent();
		this.ColloborationViewModel = this;
		this.model = model;
		this.editable = ko.observable(false);
		//this.name = kb.observable(model, 'name');
		//this.description = kb.observable(model, 'description');
		this.currentEditableTicket = ko.observable(null);
		this.showSaveTicket = ko.observable(false);
		this.currentEditableComment = ko.observable(null);
		this.ticketStatusOptions = TicketStatus.symbols();

		//this.assignedTicket = ko.observableArray([]);
		/*var ticketType = "Validation";
		var assignedTicket = new Ticket({
			'name':'Plan Value',
			'description':'use previous aggregation from p1 to p4 lost',
			'ticketType':DataManager.getDataManager().buildAppNsPath("tickets.TicketType","version1")[ticketType],
			'referredElement':model,
			'ticketOwner':model
			//reporter:self.user
		});
			//assignedTicket.set('ticketType',ticketType);
			model.get('assignedTicket').add(assignedTicket);*/
			
			self.assignedTicketsCollection = new Backbone.Collection();

		/*this.getChildrenTickets(model){
			var typeMixinCls = DataManager.getDataManager().getModelTypeByTypeStr(model.get("type"),model.getNestedParent().get("version"),true);
			var relations = typeMixinCls.getCumulativeMixinRelations();
			
	    	for(var i=0;i<relations.length;i++){
	    			var includeInJSON = relations[i].includeInJSON;
	    			if(!includeInJSON || includeInJSON === true){
	    				var childmodel = model.get(""+relations[i].key);
	    				if(childmodel != null && childmodel.models != null) {
	    					for(var j=0;j<childmodel.models.length;j++) {
	    						if(childmodel.models[j].get("assignedTicket").models.length > 0) {
	    							self.assignedTicketsCollection.add(childmodel.models[j].get("assignedTicket").models);
	    						}
	    					}
	    				}
	    				if(childmodel != null && childmodel.get("type") !== undefined) {
	    					this.getChildrenTickets(childmodel);
	    				}
	    			}
	    	}
		}*/
		//self.assignedTicketsCollection.add(model.get("assignedTicket").models);
		//this.getChildrenTickets(model);
	//	DataManager.getDataManager().get("rdfModel").getAllTickets(model.get('id'),this.getTicketModels);
		_.each(model.get('assignedTicket').models,function(ticket){
			//self.assignedTicket.push({'id':window.utils.htmlEscape(ticket.id),'description':ticket.get('description'),'ticketColor':});
			self.assignedTicketsCollection.add(ticket);
		});
		self.assignedTicket = kb.collectionObservable([]);
		if(self.assignedTicketsCollection.length == 0){
			$("#CollaborationTab").hide();
		}else{
			self.assignedTicket = kb.collectionObservable(self.assignedTicketsCollection,{view_model : TicketViewModel});
		}
		this.editTicket = _.bind(this.editTicket,this);
		this.deleteTicket = _.bind(this.deleteTicket,this);
		this.submitTicket = _.bind(this.submitTicket,this);
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['assignedTicket'
		,'Ticket'
		,'Dispute'
		,'Suggestion'
		,'Validation'
		,'TODO'
		,'Open'
		,'InProgress'
        ,'Fixed'
        ,'Closed']);
	}
		
		dispose(){
			var self = this;
			var assignedTicketViews = self.assignedTicket();
            for (var j = 0; j < assignedTicketViews.length; j++) {
                window.cleanViewModel(assignedTicketViews[j]);
            }		
		}; 
		cleanUp(){
			var self = this;
			window.cleanViewModel(self);	
		}
		editTicket(data,event){debugger
			var self = this;
			//if(data.model.get('reporter').get('userId') === DataManager.getDataManager().get('gapiId')){
				self.currentEditableTicket(data);
				self.showSaveTicket(true);
				event.target.readOnly = false;
			//}
		};
		showValuePathDivRow(data,event){
			var value = data.model.get('referredElement').at(0);
			if(value) {
        		var partcipantNetwork="",valuePathRole="",valuePathSource="",receiverValuePathRole="",ProviderRole="",receiverRole="",PN="Participant Network";
        		if(value.getNestedParent() != DataManager.getDataManager().get('currentPlan')){
					fillValuePath();
				}
				if(valuePathSource === ""){
					var componentOwner = value.get('componentOwner');
					if(componentOwner && componentOwner.get('planCriterionSetOwner')){
						valuePathSource = componentOwner.get('planCriterionSetOwner').get('name');
						PN = "";
						partcipantNetwork = "";
					}
				}
        		var msg = "<div>\
					<div class=\"row\">\
						<div class=\"col-xs-1\"></div>\
						<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+DataManager.getDataManager().get('localeManager').get('source')+"</span></span></div>\
						<div class=\"col-xs-6\"><span> "+valuePathSource+"</div><br>\
						<div class=\"col-xs-1\"></div>\
					</div><br>\
					<div class=\"row\">\
						<div class=\"col-xs-1\"></div>\
						<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+DataManager.getDataManager().get('localeManager').get('sourceType')+"</span></span></div>\
						<div class=\"col-xs-6\"><span> "+window.utils.getSourceType(value)+"</div><br>\
						<div class=\"col-xs-1\"></div>\
					</div>";

					var pnDiv = "<div class=\"row\">\
						<br>\
						<div class=\"col-xs-1\"></div>\
						<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+PN+"</span></span></div>\
						<div class=\"col-xs-6\"><span>"+partcipantNetwork+"</div><br>\
						<div class=\"col-xs-1\"></div>\
					</div>";

					var providerDiv = "<div class=\"row\">\
						<br>\
						<div class=\"col-xs-1\"></div>\
						<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+ProviderRole+"</span><span></div>\
						<div class=\"col-xs-6\"><span>"+valuePathRole+"</div><br>\
						<div class=\"col-xs-1\"></div>\
					</div>";

					var recieverDiv = "<div class=\"row\">\
						<br>\
						<div class=\"col-xs-1\"></div>\
						<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+receiverRole+"</span><span></div>\
						<div class=\"col-xs-6\"><span>"+receiverValuePathRole+"</div><br>\
						<div class=\"col-xs-1\"></div><br>\
					</div>";

					if(PN !== ""){
						msg = msg.concat(pnDiv);
					}
					if(ProviderRole !== ""){
						msg = msg.concat(providerDiv);
					}
					if(receiverRole !== ""){
						msg = msg.concat(recieverDiv);
					}
					msg = msg.concat("</div>");
					bootbox.dialog({
					  message: msg,
					  title: value.get('name')+" "+DataManager.getDataManager().get('localeManager').get('SourceDetails'),
					  buttons: {
						main: {
						  label: "Close",
						  className: "btn btn-default",
						}
					  }
					});
				function fillValuePath(){
					var packPath = value.getPackagePath()
					for(var k=0;k<packPath.length-1;k++){
						if(k === 0){
							partcipantNetwork = packPath[k].name;
						}
						if(value.get('type') === "vdml_ValueAdd" && k === 1  && value.get('valueAddOwner')){
							var performingRole = value.get('valueAddOwner').get('containedPortOwner').get('performingRole');
							valuePathRole = performingRole.get('roleAssignment').models[0].get('participant').get('name') +"  <b><i>("+performingRole.get('name')+")</b></i>";
							if(packPath[k]){
								valuePathSource = packPath[k].name;
							}
							ProviderRole = "Performer";
							k++;
						}
						else if(k === 1){
							valuePathRole = value.get('componentOwner').get('provider').get('roleAssignment').models[0].get('participant').get('name') +"  <b><i>("+ packPath[k].name+")</i></b>";
							if(value.get('componentOwner').get('recipient')) {
								var participantName = '';
								if(value.get('componentOwner').get('recipient').get('roleAssignment').models[0]){
									participantName = value.get('componentOwner').get('recipient').get('roleAssignment').models[0].get('participant').get('name');
								}
								receiverValuePathRole = participantName +"  ("+ value.get('componentOwner').get('recipient').get('name')+")";
								receiverRole = "To (Role)";
							}
							k++;
							if(packPath[k]){
								valuePathSource = packPath[k].name;
							}
							ProviderRole = "From (Role)";
						}
		    		}
				}
			}else {
				bootbox.alert('Value Already Deleted');
			}
		};
		deleteTicket(data,event){
			var self = this;
			self.assignedTicketsCollection.remove(data.model);
			if(data && data.model){
				data.model.destroy();
			}
			self.showSaveTicket(false);
			//self.assignedTicket.remove(data);
		};
		autoGrow(data,event) {
			var oField = event.target;
			if (oField.scrollHeight > oField.clientHeight) {
				oField.style.height = oField.scrollHeight + "px";
			}
		};
		submitTicket(){
			var self = this;
			self.currentEditableTicket(null);
			self.showSaveTicket(false);
		};
		/*this.commentEditable = ko.computed(function(data,event){
			if(!DataManager.getDataManager().get('gapiId')){
				return false;
			}
			if(!self.user){
				self.user = User.getInstanceFromPackage(DataManager.getDataManager().get('gapiId'),self.model.getNestedParent());
			}

			return (data === self.currentEditableComment() && data.get('commentor') === self.user);
		});

		

		this.ticketEditable = ko.computed(function(data,event){
			if(!DataManager.getDataManager().get('gapiId')){
				return false;
			}
			if(!self.user){
				self.user = User.getInstanceFromPackage(DataManager.getDataManager().get('gapiId'),self.model.getNestedParent());
			}
			return (data === self.currentEditableTicket() && data.get('reporter') === self.user);
		});

		
		this.editComment = function(data,event){
			if(data.model.get('commentor').get('userId') === DataManager.getDataManager().get('gapiId')){
				self.currentEditableComment(data);
			}
		};
		

		this.submitComment(){
			self.currentEditableComment(null);
		};

		this.createComment(data,event){
			if(!self.user){
				if(!DataManager.getDataManager().get('gapiId')){
					return;
				}
				self.user = User.getInstanceFromPackage(DataManager.getDataManager().get('gapiId'),self.model.getNestedParent());
			}
			var comments = data.model.get('comment');
			if(comments){
				new Comment({commentOwner:data.model,commentor:self.user});
			}else{
				var replys = data.model.get('reply');
				if(replys){
					new Comment({replyOwner:data.model,commentor:self.user});
				}
			}
		};
		function handleTickets(){
				if(!self.wizardTicket){
					self.wizardTicket = WizardModel.getInstance([
						{type:self.labels['Dispute'](),handler:$.proxy(self.createNewAssignedTicket,self,'Dispute')},
						{type:self.labels['Suggestion'](),handler:$.proxy(self.createNewAssignedTicket,self,'Suggestion')},
						{type:self.labels['Validation'](),handler:$.proxy(self.createNewAssignedTicket,self,'Validation')},
						{type:self.labels['TODO'](),handler:$.proxy(self.createNewAssignedTicket,self,'TODO')}
					]);
				}
				self.wizardTicket.startWizard();

			}*/
			
		/*this.removeComment(data,event){
				data.model.destroy()
		};		
		this.removeFromCollection(itemsToBeRemoved,collection,removeOnlyRef){
			var model = this.model;
			var items = new Array();
			for(var i=0;i<itemsToBeRemoved.length;i++)
			{
				for(var j=0;j<collection.length;j++){
					if(collection.at(j).id === itemsToBeRemoved[i]){
						items.push(collection.at(j));
					}
				}
			}
			for(var k=0;k<items.length;k++){
				items[k].lawnchair = DataManager.getDataManager().vdmStore;
				if(removeOnlyRef){
					collection.remove(items[k]);
					if(items[k].get("type") == "tickets_Ticket") {
						self.assignedTicketsCollection.remove(items[k]);
					}
				}else{
					items[k].destroy();
				}
			}
		};*/
		
		
//#startCustomMethods		
		afterRenderView(){
			//DataManager.getDataManager().addContextBarMenuToNode("glyphicon glyphicon-plus","Ticket","createTicket",$.proxy(handleTickets,this));
			//DataManager.getDataManager().addContextBarMenuToNode("glyphicon glyphicon-minus","Remove","removeTickets",$.proxy(self.removeSelectedItems,this));
		}
		handlePublicMenus(){
			var self = this;		
			
			/*function handleTickets(){
				if(!self.wizardTicket){
					self.wizardTicket = WizardModel.getInstance([
						{type:self.labels['Dispute'](),handler:$.proxy(this.createNewAssignedTicket,this,'Dispute')},
						{type:self.labels['Suggestion'](),handler:$.proxy(this.createNewAssignedTicket,this,'Suggestion')},
						{type:self.labels['Validation'](),handler:$.proxy(this.createNewAssignedTicket,this,'Validation')},
						{type:self.labels['TODO'](),handler:$.proxy(this.createNewAssignedTicket,this,'TODO')}
					]);
				}
				self.wizardTicket.startWizard();

			}
	        $('span[id^="addBm"]').css({'cursor':'pointer','padding':'0px 10px 0px 0px','top':'-1px'});*/
	
		};
		
//#endCustomMethods		
		
		
		//this.selectedAssignedTicket = ko.observableArray();
		//model.getAsync("assignedTicket");
	
		/*this.removeAssignedTicket(){
			self.removeFromCollection(this.selectedAssignedTicket(),this.model.get('assignedTicket'),true);
		};
		this.removeSelectedItems(){
			self.removeAssignedTicket();
		}
		this.showDetailedViewAssignedTicket= function(entity){
			var bo = entity.model;
			DataManager.getDataManager().get('router').navigate("views/"+ bo.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + bo.get('version')+ "/" + bo.get('id') + "/tickets.Ticket/" + DataManager.getDataManager().get('currentWorkspace').get('id'), {trigger: true});
		};*/
		

		
//#startCustomMethods	
		/*this.status=function(view,event){
        	for(var i=0;i<self.ticketStatusOptions.length;i++){
        		if(self.ticketStatusOptions[i].name===event.currentTarget.innerText){
        			view.model.set('status',self.ticketStatusOptions[i]);
        		}
        	};
	    };
	    
		this.createNewAssignedTicket(ticketType,name,description){
			var model = self.model;
			var beepPackage = model.getNestedParent();
			if(name !== null && DataManager.getDataManager().get('gapiId'))
			{
				if(!self.user){
					self.user = User.getInstanceFromPackage(DataManager.getDataManager().get('gapiId'),self.model.getNestedParent());
				}
			}
			var assignedTicket = new Ticket({
				'name':name,
				'description':description,
				'ticketType':DataManager.getDataManager().buildAppNsPath("tickets.TicketType","version1").ticketType,
				referredElement:model,
				ticketOwner:beepPackage,
				reporter:self.user});
				assignedTicket.set('ticketType',ticketType);
				model.get('assignedTicket').add(assignedTicket);
				self.assignedTicketsCollection.add(assignedTicket);
		 }*/
//#endCustomMethods

	
	
	static getInstance(model,options){
        var view = new ColloborationViewModel(model,options);
		return view;
    };
}
path.ColloborationViewModel = ColloborationViewModel;
