import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Performer} from './Performer'
import {CaseRoleMixin} from './CaseRoleMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Performer","appbo/vdml/CaseRoleMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Performer,CaseRoleMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	var CaseRole = Performer.extend(utils.customExtends({
		relations:CaseRoleMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			Performer.prototype.initialize.apply(this, arguments);
		}
		}
		, new CaseRoleMixin()
	));
	
	CaseRole.abstract = false;
	CaseRole.supported = false;
	path.CaseRole = CaseRole;
	export {CaseRole};
//});