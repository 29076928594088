import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {SmmElement} from './SmmElement'
import {SmmElementMixin} from './SmmElementMixin'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/SmmElement","appbo/smm/SmmElementMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,SmmElement,SmmElementMixin
//){
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!SmmElement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.SmmElement){
			SmmElement = importPath.SmmElement;
		}
        else {
            import('./SmmElement').then(({ default: SmmElement }) => {
                SmmElement = SmmElement;
            });
			/*require(["appbo/smm/SmmElement"],function(loadedModule){
				SmmElement = loadedModule;
			});*/
		}
	}
export class AnnotationMixin{
    /*constructor() {
        var path = DataManager.getDataManager().buildAppNsPath("smm", global.version);
        path.AnnotationMixin = AnnotationMixin;
    }*/

	defaults(){
		var ret = {
			type: "smm_Annotation"
		}
		return jQuery.extend(SmmElement.prototype.defaults.apply(this),ret);
	}    
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!AnnotationMixin.cummulativeRelations) {
            AnnotationMixin.cummulativeRelations = _.union(AnnotationMixin.getMixinRelations()
                , SmmElementMixin.getCumulativeMixinRelations()
            );
        }
		return AnnotationMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"smm_SmmElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "shortDescription",type : "EString",defaultValue : "null",containingClass : "smm_SmmElement" },
			{name : "text",type : "EString",defaultValue : "null",containingClass : "smm_Annotation" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("owner") ? this.get("owner") : this.previousAttributes().owner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("owner") ? this.get("owner") : this.previousAttributes().owner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}
	getViewProperties(type){
		return {
			templatePath : "views/smm/views/properties/AnnotationPropertiesTemplate.html",
			templateName : "AnnotationPropertiesTemplate",
			viewTypeStr : "appviews/smm/views/properties/AnnotationViewModel",
			tabId : "AnnotationView",
			tabName: "Annotation"
		}
    }
}
	path.AnnotationMixin = AnnotationMixin;
	
	//return AnnotationMixin;
//});