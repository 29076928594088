import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {BeepPackage} from '../beeppackage/BeepPackage'
import {BeepPackageMixin} from '../beeppackage/BeepPackageMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {Presentation} from './Presentation'

/*
define(["require", "jquery", "underscore", "backbone", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/beeppackage/BeepPackage", "appbo/beeppackage/BeepPackageMixin", "appbo/dashboard/Presentation", "appbo/dashboard/Dashboard"],
function (require, jQuery, _, Backbone, Lawnchair, backboneLawnchair, DataManager, global, BeepPackage, BeepPackageMixin, Presentation, Dashboard
){*/
	
   /*if(!Presentation){
	    var importPath = DataManager.getDataManager().buildAppNsPath("dashboard", global.version);
		if(importPath.Presentation){
			Presentation = importPath.Presentation;
		}
		else{
			require(["appbo/dashboard/Presentation"],function(loadedModule){
				Presentation = loadedModule;
			});
		}
	}*/
	export class AnalyticsMixin{
		constructor(){
			var path = DataManager.getDataManager().buildAppNsPath("dashboard",global.version);
			path.AnalyticsMixin = AnalyticsMixin;
		}
	defaults(){
		var ret = {
			type: "dashboard_Analytics",
			version: DataManager.getDataManager().get('currentVDMVersion')
		}
		return jQuery.extend(BeepPackage.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
		    type: Backbone.HasMany,
		    containingClass: "dashboard_Analytics",
		    key: "presentation",
		    relatedModel: "dashboard.Presentation",
		    reverseRelation: {
		        key: "analytics",
		        type: Backbone.HasOne,
		        includeInJSON: "id"
		    }
		},
		{
		    type: Backbone.HasMany,
		    containingClass: "dashboard_Analytics",
		    key: "dashboard",
		    relatedModel: "dashboard.Dashboard",
		    reverseRelation: {
		        key: "analytics",
		        type: Backbone.HasOne,
		        includeInJSON: "id"
		    }
		}
		]);
	};
	static getCumulativeMixinRelations(){
		if (!AnalyticsMixin.cummulativeRelations) {
            AnalyticsMixin.cummulativeRelations = _.union(AnalyticsMixin.getMixinRelations()
                , BeepPackageMixin.getCumulativeMixinRelations()
            );
        }
		return AnalyticsMixin.cummulativeRelations.slice();
	};
	static getSuperTypes(){
		return [
			"beeppackage_BeepPackage"
		];
	}
	static getProperties(){
		return [
			{name : "version",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "label",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "parentLabel",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "syncedChangeLog",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "synchedChangeSet",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" }
		]
	}
	getParent(){
		var container;
		return BeepPackage.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return BeepPackage.prototype.getPackagePath.apply(this, arguments);
	}
	static getDialogViewProperties(type){
		if(type && type==="dashboard_Analytics"){
			return{
				templatePath : "views/dashboard/views/properties/DashboardPackageTemplate.html",
			    templateName : "DashboardPackageTemplate",
			    viewTypeStr : "appviews/dashboard/views/properties/DashboardPackageViewModel",
			    tabId : "DashboardPackageView",
			    tabName: "DashboardPackage"
			}
		}
		else if(type==="explorerModelJson"){
			return{
				templatePath : "views/beeppackage/views/properties/explorerModelJsonTemplate.html",
			    templateName : "explorerModelJsonTemplate",
			    viewTypeStr : "appviews/beeppackage/views/properties/explorerModelJsonViewModel",
			     tabId: "explorerModelJsonView",
			    tabName: "explorerModelJson"
			}
		}		
	}
	static getViewProperties(type){
		jQuery("#summernotecss").attr("href", "js/libs/summernote/dist/summernote.css");
		return {
			templatePath : "views/dashboard/views/summernote/SummernoteReportTemplate.html",
			templateName : "SummernoteReportTemplate",
			viewTypeStr : "appviews/dashboard/views/summernote/SummernoteReportViewModel",
			tabId : "SummernoteReportView",
			tabName: "SummernoteReport"
		}
	}
//#startCustomMethods
	handlePackageNameChange(model,newName){
		if(Backbone.skipPersistance){
			return;
		}		
		var self = this;
		var alternativeId = DataManager.getDataManager().getRepositoryId(self.get('id'));
		if(!(alternativeId.indexOf("Common") > -1)){
		    DataManager.getDataManager().getAlternative(alternativeId,function(alternative){
		    	var phase = alternative.get('phaseAlternativeOwner');
		    	if(phase.get('master') === alternative || phase.get('primary') === alternative) {
			    	var plan = phase.get('phaseOwner');
					for(var j=0;j<plan.get('phase').length;j++){
						var alts = plan.get('phase').at(j).get('phaseAlternative');
						for(var k=0;k<alts.length;k++){
							var designParts = alts.at(k).get('phaseDesignPart');
							var packRef = designParts.findWhere({'beepReference':self.get('id')});
							if(packRef){
								packRef.set('name',newName);
								packRef.set('description',newName);
								break;
							}
						}	
					}
				}
				else {
					var designParts = alternative.get('phaseDesignPart');
					var packRef = designParts.findWhere({'beepReference':self.get('id')});
					if(packRef){
						packRef.set('name',newName);
						packRef.set('description',newName);
					}
				}
		    });	
	    }
	};
	
	
//#endCustomMethods
	//path.AnalyticsMixin = AnalyticsMixin;
	//return AnalyticsMixin;
}