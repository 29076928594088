import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {AssignmentMixin} from './AssignmentMixin'
import {Participant} from './Participant'
import {Role} from './Role'
import {InputPort} from './InputPort'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/AssignmentMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,AssignmentMixin
, Participant
, Role
, InputPort){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Participant){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Participant){
			Participant = importPath.Participant;
		}
		else{
			import('./Participant').then(({ default: Participant }) => {
                Participant = Participant;
            });
			/*require(["appbo/vdml/Participant"],function(loadedModule){
				Participant = loadedModule;
			});*/
		}
	}
	if(!Role){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Role){
			Role = importPath.Role;
		}
		else{
			import('./Role').then(({ default: Role }) => {
                Role = Role;
            });
			/*require(["appbo/vdml/Role"],function(loadedModule){
				Role = loadedModule;
			});*/
		}
	}
	if(!InputPort){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.InputPort){
			InputPort = importPath.InputPort;
		}
		else{
			import('./InputPort').then(({ default: InputPort }) => {
                InputPort = InputPort;
            });
			/*require(["appbo/vdml/InputPort"],function(loadedModule){
				InputPort = loadedModule;
			});*/
		}
	}
	var Assignment = VdmlElement.extend(utils.customExtends({
		relations:AssignmentMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
			//this.on("destroy", handleOnAssignmentDestroyed);
			//this.on("change:participant",destroyWhenNoParticipant)
		}
		}
		, new AssignmentMixin()
    ));
    
    Assignment["change:participant"] = function destroyWhenNoParticipant(model,relatedModel,options){
		if(Backbone.skipPersistance){
			return;
		}
		if(options && options.creatingRevision){
    		return;
    	}
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(suppressChangeLogging) {
			return;
		} 
		if(!relatedModel){
			model.destroy();
		}
    }
    Assignment["destroy"] = function handleOnAssignmentDestroyed(assignment) {
        if (Backbone.skipPersistance) {
            return;
        }
        var role = assignment.previousAttributes().assignedRole;
        var participant = assignment.previousAttributes().participant;
        if (!participant) {
            return;
        }
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(suppressChangeLogging) {
			return;
		}
        participant.getBusinessModelsInvolved(function (bms) {
            var deleteParticipant = true;
            _.each(bms, function (bm) {
                var noCustomerRoles = 0;
                var noPartnerRoles = 0;
                //var roles = bm.getRolesofParticipant(participant);
                var bmCustomers = bm.get('bmCustomer').models.concat();
                _.each(bmCustomers, function (bmCustomer) {
                    if (bmCustomer.get('customer') === participant) {
                        if (role && bmCustomer.get('customerRole').findWhere({ id: role.get('id') })) {
                            bmCustomer.get('customerRole').remove(role);
                            noCustomerRoles = bmCustomer.get('customerRole').length;
                            if (noCustomerRoles === 0 || (noCustomerRoles === 1 && bmCustomer.get('customerRole').at(0) === role)) {
                                bmCustomer.destroy();
                            } else {
                                deleteParticipant = false;
                            }
                        } else {
                            deleteParticipant = false;
                        }
                    }
                });
                var bmNps = bm.get('bmNetworkPartner').models.concat();
                _.each(bmNps, function (bmNp) {
                    if (bmNp.get('networkPartner') === participant) {
                        if (role && bmNp.get('networkPartnerRole').findWhere({ id: role.get('id') })) {
                            bmNp.get('networkPartnerRole').remove(role);
                            noPartnerRoles = bmNp.get('networkPartnerRole').length;
                            if (noPartnerRoles === 0 || (noPartnerRoles === 1 && bmNp.get('networkPartnerRole').at(0) === role)) {
                                bmNp.destroy();
                            } else {
                                deleteParticipant = false;
                            }
                        } else {
                            deleteParticipant = false;
                        }
                    }
                });
                if (bm.get('business') === participant) {
                    var businessRoles = bm.getBusinessRoles();
                    if (businessRoles.length === 0 || (businessRoles.length === 1 && businessRoles[0] === role)) {
                        bm.set('business', null);
                    } else {
                        deleteParticipant = false;
                    }
                }
            });
            if (deleteParticipant) {
                participant.destroy();
            }
			/*DataManager.getDataManager().getReferencesToModel2(participant,function(result){
				var len = result.length;
				var roleId = role?role.id:null;
	            while (len--) {
	                if(result[len].id === roleId || result[len].id === participant.getNestedParent().id || result[len].id === assignment.id) {
	                    result.splice(len,1);
	                }
	                else {
	                	for(var i=0;i<bms.length;i++){
	                		if(result[len].id === bms[i].id){
	                			result.splice(len,1);
	                			break;
	                		} 
	                	}
	                }
	            } 
                if(result.length==0) {
                	participant.destroy();
                }
			});*/
        });
    }
	
	
	Assignment.abstract = false;
	path.Assignment = Assignment;
	export {Assignment};
//});