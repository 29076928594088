import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {Capability} from './Capability'
import {CapabilityMixin} from './CapabilityMixin'
import {CapabilityDependency} from './CapabilityDependency'
import {PracticeDefinition} from './PracticeDefinition'
import {BusinessItemDefinition} from './BusinessItemDefinition'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/Capability","appbo/vdml/CapabilityMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,Capability,CapabilityMixin
, CapabilityDependency

, PracticeDefinition
, BusinessItemDefinition){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!CapabilityDependency){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.CapabilityDependency){
			CapabilityDependency = importPath.CapabilityDependency;
		}
		else{
			import('./CapabilityDependency').then(loadedModule => {
				//CapabilityDependency = loadedModule;
			});
			/*require(["appbo/vdml/CapabilityDependency"],function(loadedModule){
				CapabilityDependency = loadedModule;
			});*/
		}
	}
	
	if(!PracticeDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.PracticeDefinition){
			PracticeDefinition = importPath.PracticeDefinition;
		}
		else{
			import('./PracticeDefinition').then(loadedModule => {
		//		PracticeDefinition = loadedModule;
			});
			/*require(["appbo/vdml/PracticeDefinition"],function(loadedModule){
				PracticeDefinition = loadedModule;
			});*/
		}
	}
	if(!BusinessItemDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.BusinessItemDefinition){
			BusinessItemDefinition = importPath.BusinessItemDefinition;
		}
		else{
			import('./BusinessItemDefinition').then(loadedModule => {
		//		BusinessItemDefinition = loadedModule;
			});
			/*require(["appbo/vdml/BusinessItemDefinition"],function(loadedModule){
				BusinessItemDefinition = loadedModule;
			});*/
		}
	}
	export class CapabilityDefinitionMixin {

	defaults(){
		var ret = {
			type: "vdml_CapabilityDefinition"
		}
		return jQuery.extend(Capability.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CapabilityDefinition",
			key:"output",
			relatedModel:"vdml.CapabilityDependency",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"source",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CapabilityDefinition",
			key:"input",
			relatedModel:"vdml.CapabilityDependency",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"target",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CapabilityDefinition",
			key:"practiceDefinition",
			relatedModel:"vdml.PracticeDefinition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_CapabilityDefinition",
			key:"capabilityResourceDefinition",
			relatedModel:"vdml.BusinessItemDefinition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!CapabilityDefinitionMixin.cummulativeRelations) {
            CapabilityDefinitionMixin.cummulativeRelations = _.union(CapabilityDefinitionMixin.getMixinRelations()
                , CapabilityMixin.getCumulativeMixinRelations()
            );
        }
		return CapabilityDefinitionMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_Capability"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			//{name : "definition",type : "EString",defaultValue : "null",containingClass : "vdml_Capability" },
			{name : "color",type : "EString",defaultValue : "null",containingClass : "vdml_Capability" }
		]
	}
	getParent(){
		var container;
		return Capability.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return Capability.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/CapabilityDefinitionPropertiesTemplate.html",
			templateName : "CapabilityDefinitionPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/CapabilityDefinitionViewModel",
			tabId : "CapabilityDefinitionView",
			tabName: "CapabilityDefinition"
		}
	}
	
	}
	path.CapabilityDefinitionMixin = CapabilityDefinitionMixin;
	//return CapabilityDefinitionMixin;
//});