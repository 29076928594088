import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {MeasurementRelationship} from './MeasurementRelationship'
import {GradeMeasurementRelationshipMixin} from './GradeMeasurementRelationshipMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { DimensionalMeasurement } from './DimensionalMeasurement'
import { GradeMeasurement } from './GradeMeasurement'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/smm/MeasurementRelationship","appbo/smm/GradeMeasurementRelationshipMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurementRelationship,GradeMeasurementRelationshipMixin
, DimensionalMeasurement
, GradeMeasurement){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("smm",global.version);
	if(!DimensionalMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.DimensionalMeasurement){
			DimensionalMeasurement = importPath.DimensionalMeasurement;
		}
        else {
            import('./DimensionalMeasurement').then(({ default: DimensionalMeasurement }) => {
                DimensionalMeasurement = DimensionalMeasurement;
            });
			/*require(["appbo/smm/DimensionalMeasurement"],function(loadedModule){
				DimensionalMeasurement = loadedModule;
			});*/
		}
	}
	if(!GradeMeasurement){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.GradeMeasurement){
			GradeMeasurement = importPath.GradeMeasurement;
		}
        else {
            import('./GradeMeasurement').then(({ default: GradeMeasurement }) => {
                GradeMeasurement = GradeMeasurement;
            });
			/*require(["appbo/smm/GradeMeasurement"],function(loadedModule){
				GradeMeasurement = loadedModule;
			});*/
		}
	}
	var GradeMeasurementRelationship = MeasurementRelationship.extend(utils.customExtends({
		relations:GradeMeasurementRelationshipMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			MeasurementRelationship.prototype.initialize.apply(this, arguments);
			//this.on("change:to",this.onToRemoved);
		}
		}
		, new GradeMeasurementRelationshipMixin()
	));
	
    GradeMeasurementRelationship.abstract = false;
    GradeMeasurementRelationship["change:to"] = function (model, newTo, options) {
		var suppressChangeLogging = DataManager.getDataManager().get(DataManager.getDataManager().SUPPRESS_CHANGE_LOGGING);
		if(!suppressChangeLogging) {
        	this.onToRemoved(model, newTo, options);
		}
    }
	path.GradeMeasurementRelationship = GradeMeasurementRelationship;
	export {GradeMeasurementRelationship};
//});