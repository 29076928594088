import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {BusinessModel} from '../../../../../version1/bo/vdml/BusinessModel'
import {ValueStream} from '../../../../../version1/bo/vdml/ValueStream'
import {BusinessItem} from '../../../../../version1/bo/vdml/BusinessItem'
import {ValueDeliveryModelMixin} from '../../../../../version1/bo/vdml/ValueDeliveryModelMixin'
import {MeasuredCharacteristic} from '../../../../../version1/bo/vdml/MeasuredCharacteristic'
import {OrgUnit} from '../../../../../version1/bo/vdml/OrgUnit'
import {ScenarioProxy} from '../../../../../version1/bo/transformation/ScenarioProxy'
import {CapabilityOffer} from '../../../../../version1/bo/vdml/CapabilityOffer'
import {EcoMapDiagramMixin} from '../../../../bo/vdml/EcoMapDiagramMixin'

/*define(["require", "jquery", "underscore", 'async', "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/BusinessModel", "appbo/vdml/ValueProposition", "appbo/vdml/Participant", "appbo/vdml/ValueDeliveryModel", "appcommon/com/vbee/filesystem/PackageReference", "appbo/transformation/PackageReference", "appbo/transformation/ScenarioProxy", "appbo/vdml/EcoMapDiagramMixin", "appbo/vdml/OrgUnit", "appbo/vdml/ValueStream", "appbo/vdml/BusinessItem", "appbo/vdml/CapabilityOffer", "appbo/vdml/MeasuredCharacteristic", "appbo/vdml/ValueDeliveryModelMixin"],
	function (require, $, _, async, Backbone, ko, koMapping, kb, bootbox, DataManager, global, BusinessModel, ValueProposition, Participant, ValueDeliveryModel, PackageReference, PlanPackageReference, ScenarioProxy, EcoMapDiagramMixin, OrgUnit, ValueStream, BusinessItem, CapabilityOffer, MeasuredCharacteristic, ValueDeliveryModelMixin) {
*/
		var path = DataManager.getDataManager().buildAppNsPath("strategyMap.views.designer", global.version);
export class MappingWizardViewModel {
	constructor(modelElement, model, options,  diagramBO, callback){
		var self = this;
		this.init(modelElement, model, options,  diagramBO, callback);
	}
	init(modelElement, model, options,  diagramBO, callback) {
		var self = this;
		this.MappingWizardViewModel = this;
		this.model = model;
		this.modelElement = modelElement;
		this.callback = callback;
		this.shapeTypeFromWizard = ko.observable();
		this.id = ko.observable();
		if (options.shapeTypeFromWizard) {
			this.shapeTypeFromWizard(options.shapeTypeFromWizard);
			this.id(options.idFromWizard);
		} else {
			this.id(self.model ? self.model.get('id') : null);
		}
		this.mappedDiagramBO = null;
		if (diagramBO.mid) {
			this.mappedDiagramBO = diagramBO;
		}
		this.parentView = options.parentView;
		//this.allShapesList = self.parentView.shapesList();
		this.selectedVDMPackage;
		this.BMsColl = new Backbone.Collection();
		this.businessColl = new Backbone.Collection();
		this.PNsColl = new Backbone.Collection();

		this.participantsColl = new Backbone.Collection();
		this.rolesColl = new Backbone.Collection();
		this.resourceTypes = ko.observableArray([{ 'name': 'Resource', type: 'vdml_BusinessItem' }, { 'name': 'Capability', type: 'vdml_CapabilityOffer' }]);
		this.selectedResourceType = ko.observable();
		this.relationTypesList = ko.observableArray(["Partner", "Customer"]);
		this.selectedRelType = ko.observable();
		this.selectedPartType = ko.observable();
		this.showPartType = ko.observable(false);
		this.enablePartType = ko.observable(true);
		this.showValueProposition = ko.observable(true);
		this.showValuePropositionRoles = ko.observable(false);
		this.showActivityDiv = ko.observable(true);
		this.showCapabilityElement = ko.observable(false);
		this.showValueElement = ko.observable(false);
		this.capabilityLibs = [];
		if (options.parentView.capabilityLibs && options.parentView.capabilityLibs.length > 0) {
			self.showCapabilityElement(true);
			self.capabilityLibs = options.parentView.capabilityLibs.slice();
		};
		this.valueLibs = [];
		this.fetchedObjects = {};
		this.updateVPBasedOnTheRecipientMapping;
		if (!self.parentView.modeler) {
			var presentationModels = self.parentView.charts()
			for (var i = 0; i < presentationModels.length; i++) {
				if (self.model == presentationModels[i].viewInstance.model) {
					self.parentView = presentationModels[i].viewInstance;
				}
			}
		}
		this.eventBus = self.parentView.modeler.get('eventBus');
		function htmlEscape(str) {
			return String(str)
				.replace(/@/g, '')
				.replace(/ /g, '');
		}
		model = null;
		this.encodeId = htmlEscape(this.id());
		this.mappingObject = {};
		this.mappingObject['vdml:Value'] = { name:'Value', collectionName: 'value', type: 'vdml.Value' };
		this.mappingObject['vdml:Activity'] = { name: 'Activity', collectionName: 'activity', type: 'vdml.Activity' };
		this.mappingObject['vdml:Competency'] = { name: 'Competency', collectionName: 'competence', type: 'vdml.Competence' };
		this.mappingObject['vdml:ValueStream'] = { name: 'Value Stream', collectionName: 'valueStream', type: 'vdml.ValueStream' };
		this.mappingObject['vdml:Capability'] = { name:  'Capability', collectionName: 'competence', type: 'vdml.Capability' };

		this.shapeTypesList = ko.observableArray([]);

		Object.getOwnPropertyNames(this.mappingObject).forEach(function (key) {
			self.shapeTypesList.push(self.mappingObject[key]);
		});

		this.usedCapabilityName = ko.observable();
		this.usedCapabilityComName = ko.observable();
		this.usedValueElementName = ko.observable();
		this.usedCapability = ko.observable();
		this.usedValue = ko.observable();
		this.usedCapabilityCom = ko.observable();	
		this.objectiveMapping = ko.observable(options.objectiveMapping ? true:false);
		this.existingValList = options.existingMapTargetList

		//loading alternatives
		var phase = DataManager.getDataManager().get('currentPlan').get('phase');
		var alternative = DataManager.getDataManager().get('currentWorkspace').get("id");
		var tempAlt = DataManager.getDataManager().get('viewAlternative') ? DataManager.getDataManager().get('viewAlternative') : alternative;
		for (var j = 0; j < phase.length; j++) {
			var alts = phase.at(j).get('phaseAlternative');
			var viewAlternativeModel = alts.findWhere({ 'id': tempAlt });
			if (viewAlternativeModel) {
				self.currentAlternativeModel = viewAlternativeModel;
				break;
			}
		}

		//Activity arrays
		this.packagesListVP = ko.observableArray([]);
		this.selectedPackageVP = ko.observable();

		this.businessModelsVP = ko.observableArray([]);
		this.selectedBMVP = ko.observable();

		this.vpProviders = ko.observableArray([]);
		this.vpProvider = ko.observable();
		this.vpProviderRoles = ko.observableArray([]);
		this.vpReceiverRoles = ko.observableArray([]);
		this.selectedProviderRole = ko.observable();
		this.selectedReceiverRole = ko.observable();
		this.vpReceivers = ko.observableArray([]);
		this.vpReceiver = ko.observable();
		this.myProposition = ko.observable(false);

		this.activitiesColl = new Backbone.Collection();
		this.VPsColl = new Backbone.Collection();
		this.competenciesColl = new Backbone.Collection();
		this.valuesColl = new Backbone.Collection();

		this.activityPerformers = ko.observableArray([]);
		this.activityPerformer = ko.observable();

		this.activityPerformerRoles = ko.observableArray([]);
		this.activityPerformerRole = ko.observable();
		this.competenceMap = ko.observable(false);
		this.selectedValueType = ko.observable('Activity');
		this.selectedValueCreation = ko.observable('New');
		this.rolesLoadedForCompetency = false;

		this.setWizardNextButton = function () {
			var shape = self.selectedShapeType;
			if (!shape) {
				return;
			}
			var wizard = self.$wizard.data('wizard');
			var stepsNode = $('.steps');
			var isPlanValueOrExisting = self.selectedValueType() === 'Plan' || (self.selectedValue && !self.mappedDiagramBO);
			if (isPlanValueOrExisting && shape.name === 'Value') {
				var children = stepsNode.children();
				if (children[1]) {
					children[1].remove();
				}
				wizard.numSteps = wizard.$element.find('.steps li').length;
				wizard.setState();
				self.enableCompleteForValue();
				return;
			}
			if (stepsNode.children().length === 1) {
				var stepStr = "<li class=\"wizardLibLi\" data-step=\"stepNo\"><span class=\"badge\">2</span><span class=\"dynamicHeader\">header</span><span class=\"chevron\"></span></li>";
				var reStep = new RegExp('stepNo', 'g');
				var reHeader = new RegExp('header', 'g');
				if (shape.name === 'Value') {
					stepStr = stepStr.replace(reHeader, self.selectedValueType() + " Details");
					stepStr = stepStr.replace(reStep, 1);
					stepsNode.append($(stepStr));
					wizard.numSteps = wizard.$element.find('.steps li').length;
					wizard.setState();
					self.enableCompleteForValue();
				}
			}
		}

		this.onSelectedShapeTypeChange = function () {
			if (self.selectedValue) {
				var valueModal = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: self.selectedValue.id });
				if (!valueModal) {
					valueModal = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: self.selectedValue.id });
				}
				if (valueModal) {
					if (valueModal.get('valueDefinition')) {
						var valDef = valueModal.get('valueDefinition');
						if (valDef) {
							self.usedValue(valDef.id);
							self.usedValueElementName(valDef.get('name'));
							$("#mapValueElement" + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
							$('#deleteValueElement' + self.encodeId).show();
						}
					}
				}
				$('#showMappedInfoValue' + self.encodeId).show();
				self.matchField = $('#showMappedInfoValue' + self.encodeId).closest('.row').find('.match');
				if (self.matchField) {
					self.matchField[0].innerHTML = "Match";
					self.matchField.show();
				}
				self.selectedValueCreation('Existing');
			} else {
				$('#showMappedInfoValue' + self.encodeId).hide();
				if (self.matchField) {
					self.matchField.hide();
				}
			}
			self.setWizardNextButton();
		};
		function sortByName(left, right) {
			return left.name.toLowerCase() === right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
		}
		function sortCollectionByName(left, right) {
			return left.get('name').toLowerCase() === right.get('name').toLowerCase() ? 0 : (left.get('name').toLowerCase() < right.get('name').toLowerCase() ? -1 : 1);
		}
		function sortRolesByName(left, right) {
			return left.displayRolename.toLowerCase() == right.displayRolename.toLowerCase() ? 0 : (left.displayRolename.toLowerCase() < right.displayRolename.toLowerCase() ? -1 : 1);
		}
		function getSourceType(component) {
			var type = "";
			if (component.get('type') === "vdml_ValueAdd") {
				type = 'Activity';
			}
			else {
				var componentOwner = component.get('componentOwner');
				if (componentOwner.get('isValueFormula')) {
					type = 'My Proposition';
				}
				else {
					if (componentOwner.get('planCriterionSetOwner')) {
						type = 'Plan';
					}
					else {
						type = 'Value Proposition';
					}
				}
			}
			return type;
		}
		function getFullPathOfValue(element) {
			var fullPath = [];
			while (fullPath.length === 0 || fullPath[fullPath.length - 1].id !== element.getParent().id) {
				element = element.getParent();
				fullPath.push({ id: element.id, name: element.get('name') });
			}
			return fullPath;
		}
		function getValueList(model) {
			var valueList = [];
			if (model.get('type') === "vdml_StrategyMapDiagram") {
				valueList = model.get('value').models.concat();
			} else if (model.get('type') === "vdml_ValueStreamMapDiagram") {
				_.each(self.model.get('valueStream').models, function (vs) {
					valueList = valueList.concat(vs.get('persuedProposition').get('component').models.concat());
				});
				_.each(self.model.get('activity').models, function (act) {
					act.get('containedPort').each(function (port) {
						if (port.get('type') === "vdml_OutputPort") {
							port.get('valueAdd').each(function (valueAdd) {
								valueList.push(valueAdd);
							});
						}
					});
				});
			}
			return valueList;
		}
		function setDiagramId(diagramBO, mappedModel, mappedModelPack, callback, revisionMapPackage) {
			diagramBO.set('vdml:mid', mappedModel.id);
			diagramBO.set('vdml:mpid', mappedModelPack.id);
			var modelType = self.mappingObject[diagramBO.$type];
			var modelColl = modelType ? self.model.get(modelType.collectionName) : null;
			if (modelColl && !self.objectiveMapping()) {
				modelColl.add(mappedModel);
				if ((modelType.collectionName == "value" || modelType.collectionName == "activity" || modelType.collectionName == "valueStream") && self.parentView.chartViewInstance && self.parentView.chartViewInstance.initialize) {
					if(diagramBO.get('definition')){
						mappedModel.set('definition',diagramBO.get('definition').text);
					}
					self.parentView.chartViewInstance.initialize(self.parentView.model, true, self.parentView, getValueList(self.parentView.model));
				}
			}
			if (!diagramBO.get('name') || diagramBO.get('name') === '') {
				var name = mappedModel.get('name');
				if (mappedModel.get('type') === 'vdml_ValueStream') {
					name = mappedModel.get('persuedProposition').get('name');
				}
				diagramBO.set('name', name);
			}
			if (mappedModel.get('type') === 'vdml_Activity' || mappedModel.get('type') === 'vdml_CapabilityOffer') {
				var mcid = self.usedCapability();
				if (mappedModel.get('type') === 'vdml_CapabilityOffer') {
					mcid = self.usedCapabilityCom();
				}
				if (self.usedCapabilityModel) {
					diagramBO.set('mcid', undefined);
					diagramBO.set('mcpid', undefined);
					var shapes = self.parentView.modeler._definitions.rootElements[0].flowElements;
					var hasMoreMappings = null;
					if (shapes) {
						for (var i = 0; i < shapes.length; i++) {
							if (shapes[i].$type === "vdml:Perspective") {
								var subChilds = shapes[i].flowElements;
								if (subChilds) {
									for (var j = 0; j < subChilds.length; j++) {
										if (self.usedCapabilityModel.id === subChilds[j].get('mcid')) {
											hasMoreMappings = true;
											break;
										}
									}
								}
							}
						}
					}
					if (!hasMoreMappings) {
						self.model.get('capability').remove(self.usedCapabilityModel);
					}
				}
			}
			callback && callback(revisionMapPackage);
		}

		this.cleanUp = function () {
			self.parentView = null;
		}


		//Loading functions for activity mapping

		this.loadActivities = function () {
			if (!self.selectedBMVP()) {
				return;
			}
			var competenceMap = self.competenceMap();
			var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.selectedBMVP().id });
			self.activitiesColl.reset();
			self.activityTypeahead.rerender([]);
			var bmPNs = bm.get('participantNetwork');
			bmPNs.forEach(function (pn) {
				var collaboration = pn.get('collaboration');
				var activitiesColl = collaboration.get('activity');
				activitiesColl.forEach(function (activity) {
					if (competenceMap) {
						var role = activity.get('performingRole');
						if (role.get('roleAssignment').length > 0) {
							var participant = role.get('roleAssignment').at(0).get('participant');
							var displayName = activity.get('name');
							if (participant === bm.get('business')) {
								if (diagramBO.get('mcid') && diagramBO.$type == "vdml:Activity") {
									if (activity.get('capabilityRequirement') && activity.get('capabilityRequirement').id === diagramBO.get('mcid')) {
										self.activitiesColl.add({ id: activity.id, name: activity.get('name'), type: activity.get('type') });
									}
								} else {
									self.activitiesColl.add({ id: activity.id, name: activity.get('name'), type: activity.get('type') });
								}
							}
						}
					} else {
						if (diagramBO.get('mcid') && diagramBO.$type == "vdml:Activity") {
							if (activity.get('capabilityRequirement') && activity.get('capabilityRequirement').id === diagramBO.get('mcid')) {
								self.activitiesColl.add({ id: activity.id, name: activity.get('name'), type: activity.get('type') });
							}
						} else {
							self.activitiesColl.add({ id: activity.id, name: activity.get('name'), type: activity.get('type') });
						}
					}
				});
			});
			if (self.selectedShapeType.name === 'Competency' && diagramBO.$parent && diagramBO.$parent.$type === "vdml:CapabilityContainer") {
				if (diagramBO.$parent.incoming[0] && diagramBO.$parent.incoming[0].sourceRef && diagramBO.$parent.incoming[0].sourceRef.mid) {
					var actId = diagramBO.$parent.incoming[0].sourceRef.mid;
					var actName = diagramBO.$parent.incoming[0].sourceRef.name;
					$('#selectActivity' + self.encodeId).attr('disabled', 'disabled');
					//var activityFound = _.filter(self.activitiesColl.models, function (obj) { return obj.get('id') === actId });//fails
					var activityFound = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: actId });
					if (activityFound) {
						self.selectedActivity = activityFound;
						self.fillActivityPerformerRole(); //to disable performer role
					}
					$('#selectActivity' + self.encodeId).val(actName);
				}
			}
			if (diagramBO.get('name') && self.selectedShapeType.name === 'Activity') {
				$('#selectActivity' + self.encodeId).val(diagramBO.get('name'));
				if (self.mappedDiagramBO) {
					$('#selectActivity' + self.encodeId).attr('disabled', 'disabled');
				}
				var activityFound = _.filter(self.activitiesColl.models, function (obj) { return obj.get('name').toLowerCase() === diagramBO.get('name').toLowerCase() });
				if (activityFound && activityFound.length) {
					self.selectedActivity = activityFound[0];
					self.fillActivityPerformerRole(); //to disable performer role
					var bmActExists = _.filter(bm.get('bmActivity').models, function (obj) { return obj.get('activity').id === self.selectedActivity.id });
					$('#showMappedInfoActivity' + self.encodeId).show();
					if (bmActExists && bmActExists.length > 0) {
						if (!self.mappedDiagramBO && !self.isValueStreamMap) {
							self.showValueProposition(false);
						}

					} else {
						if (self.matchField) {
							self.matchField.hide();
						}
						self.showValueProposition(true);
					}
				}
			} else if (self.mappedDiagramBO && self.selectedShapeType.name === 'Value') {
				var valueModal = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: self.mappedDiagramBO.mid });
				var activityPort = valueModal.get('valueAddOwner');
				var activity = activityPort ? activityPort.get('containedPortOwner') : null;
				if (activity) {
					$('#selectActivity' + self.encodeId).val(activity.get('name'));
					$('#selectActivity' + self.encodeId).attr('disabled', 'disabled');
					var activityFound = _.filter(self.activitiesColl.models, function (obj) { return obj.get('name').toLowerCase() === activity.get('name').toLowerCase() });
					if (activityFound && activityFound.length) {
						self.selectedActivity = activityFound[0];
						self.fillActivityPerformerRole(); //to disable performer role
					}
				}
			}
			if (diagramBO.$type == 'vdml:Activity' && diagramBO.get('mcid')) {
				self.usedCapability(diagramBO.get('mcid'));
				var capModel = self.getCapabilityElementModel(diagramBO.get('mcid'));
				if (capModel) {
					self.usedCapabilityName(capModel.get('name'));
					self.usedCapabilityModel = capModel;
					$("#mapActCapability" + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
					$("#deleteAct" + self.encodeId).show();
				}
				$("#capabilityLibActDiv" + self.encodeId).show();
				//$('#selectCapability' + self.encodeId).attr('disabled', 'disabled');
			}
			if (diagramBO.$type === 'vdml:Value' && diagramBO.get('mcid')) {
				self.usedValue(diagramBO.get('mcid'))
				var valModel = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue() })
				if (valModel) {
					self.usedValueElementName(valModel ? valModel.get('name') : "");
					$("#mapValueElement" + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
					$('#deleteValueElement' + self.encodeId).show();
				}
				
				
				if ($('#selectValue' + self.encodeId).val() === "") {
					$('#selectValue' + self.encodeId).val(valModel ? valModel.get('name') : "")
				}
			}
			self.enableCompleteForActivity();
			self.activitiesColl.comparator = function (model) {
				return model.get('name');
			}
			self.activitiesColl.sort();
		};

		this.fillPackagesVP = function () {
			self.packagesListVP().length = 0;
			var packages = _.filter(self.currentAlternativeModel.get('phaseDesignPart').models, function (packagesRef) { if (packagesRef.get('beepType') === 'vdml_ValueDeliveryModel' && packagesRef.get('beepReference').indexOf('Common') == -1) { return packagesRef } });
			var tempPackageArray = [];
			for (var i = 0; i < packages.length; i++) {
				var obj = packages[i];
				tempPackageArray.push({ id: obj.get('beepReference'), name: obj.get('name'), version: obj.get('version'), type: obj.get('beepType') });
			}
			tempPackageArray.sort(sortByName);
			self.packagesListVP(tempPackageArray);
		}
		function fillVDMPackages(pack, callback) {
			var repId = DataManager.getDataManager().getRepositoryId(pack.id);
			var vdmStore = DataManager.getDataManager().getVDMStore(repId);
			var version = pack.version || pack.get('version');
			DataManager.getDataManager().fetchDocumentFromPackage(pack.id, "appbo/vdml/ValueDeliveryModel", version, pack.id, "appbo/vdml/ValueDeliveryModel", vdmStore, {
				success: function (model) {
					callback(model);
				},
				error: function (error) {
					console.log(error);
				}
			});
		}

		this.loadVPs = function (selBm) {
			self.VPsColl.reset();
			var filteredVPsArray = [];
			self.vpTypeahead.rerender([]);
			$('#selectVP' + self.encodeId).val('');
			self.selectedVP = null;
			self.onChangeSelectedVP();
			if (self.packagesListVP().length === 0) {
				return;
			}
			function filterRecipients(vp) {
				filteredVPsArray.push({ id: vp.id, name: vp.get('name'), type: vp.get('type'), path: getFullPath(vp) });
			}
			function getFullPath(vp) {
				var path = [];
				path.push(vp.get('provider').get('name'));
				path.push(vp.getParent().getParent().get('name'));
				path.push(vp.getParent().getParent().getParent().get('name'));
				var altId = window.utils.getPrefix(vp.id);
				var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: altId });
				path.push(alt.get('name'));
				var phase = alt.get('phaseAlternativeOwner');
				path.push(phase.get('name'));
				return path;
			}
			var VPs = new Backbone.Collection();
			if (selBm) {
				var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: selBm });
			} else if (self.selectedBMVP()) {
				var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.selectedBMVP().id });
			}
			if (bm) {
				if (self.myProposition()) {
					_.each(bm.get('bmValueFormula').models, function (bmVF) {
						VPs.push(bmVF.get('valueFormula'));
					})
				} else {
					_.each(bm.get('bmValueProposition').models, function (bmVP) {
						VPs.push(bmVP.get('valueProposition'));
					})
				}
			}

			if (!self.myProposition()) {
				VPs.each(function (vp) {
					filterRecipients(vp);
				});
			} else {
				VPs.each(function (vf) {
					filteredVPsArray.push({ id: vf.id, name: vf.get('name'), shortname: vf.get('name'), type: vf.get('type'), path: getFullPath(vf) });
				});
			}
			VPs.reset();
			filteredVPsArray.sort(sortByName);
			_.map(_.groupBy(filteredVPsArray, function (vp) { return vp.name; }), function (grouped) {
				addNamePath(grouped);
				_.each(grouped, function (group) {
					self.VPsColl.add({ id: group.id, name: group.displayName, type: group.type, shortName: group.name });
				});
			});
			function addNamePath(grouped) {
				var index;
				_.each(grouped, function (val) {
					val.displayName = val.name;
					index = val.path ? val.path.length : 0;
				});
				if (grouped.length > 1) {
					var filteredArray = grouped;
					_.each(filteredArray, function (element) {
						element.displayName = element.displayName + ' [';
						element.namePath = "";
					});
					var duplicates = true, y = 0;
					while (duplicates) {
						_.each(filteredArray, function (element) {
							if (y === 0) {
								element.displayName = element.displayName + element.path[y];
								element.namePath = element.namePath + element.path[y];
							}
							else {
								element.displayName = element.displayName + ' /' + element.path[y];
								element.namePath = element.namePath + ' /' + element.path[y];
							}
						});
						var z = _.groupBy(filteredArray, function (a) {
							return a.displayName;
						});
						if (Object.keys(z).length > 1) {
							filteredArray = [];
							_.each(z, function (v) {
								if (v.length > 1) {
									_.each(v, function (vals) {
										filteredArray.push(vals);
									});
								}
								else {
									if (v[0].displayName === v[0].name + ' [') {
										v[0].displayName = v[0].displayName + v[0].path[1] + ']';
									}
									else {
										v[0].displayName = v[0].displayName + ']';
									}
								}
							});
						}
						if (filteredArray.length === 0) {
							duplicates = false;
						}
						y++;
					}
				}
			}
			if (self.isValueStreamMap && diagramBO.$parent.mid && self.selectedShapeType.name === 'Activity') {
				var valueStreamId = diagramBO.$parent.mid;
				var valueStream = Backbone.Relational.store.getObjectByName('vdml.ValueStream').find({ 'id': valueStreamId });
				var persuedVP = valueStream.get('persuedProposition');
				if (persuedVP) {
					$('#selectVP' + self.encodeId).val(persuedVP.get('name'));
					$('#selectVP' + self.encodeId).attr('disabled', 'disabled');
					if (persuedVP.get('isValueFormula')) {
						self.myProposition(true);
						$('#myProposition' + self.encodeId).prop('checked', true);
						var VPFound = _.filter(self.VPsColl.models, function (obj) { return obj.get('id') === persuedVP.get('id') });
					} else {
						var VPFound = _.filter(self.VPsColl.models, function (obj) { return obj.get('id') === persuedVP.get('id') });
					}
					if (VPFound && VPFound.length) {
						$('#myProposition' + self.encodeId).attr('disabled', 'disabled');
						self.selectedVP = VPFound[0];
						self.onChangeSelectedVP();
					}
					if (self.selectedBMVP() && VPFound.length === 0) { //*********
						var vpText = self.myProposition() ? 'My Proposition' : 'Value Proposition';
						$('#vpWarningMessage').html('*' + vpText + ' ' + persuedVP.get('name') + ' is not present in ' + self.selectedBMVP().name + '.');
					} else { $('#vpWarningMessage').html("");}
				}
			} else if (diagramBO.get('name') && self.selectedShapeType.name === 'Value Stream') {
				$('#selectVP' + self.encodeId).val(diagramBO.get('name'));
				if (self.mappedDiagramBO) {
					$('#selectVP' + self.encodeId).attr('disabled', 'disabled');
				}
				var VPFound = _.filter(self.VPsColl.models, function (obj) { return obj.get('shortName').toLowerCase() === diagramBO.get('name').toLowerCase() });
				if (VPFound && VPFound.length) {
					self.selectedVP = VPFound[0];
					self.onChangeSelectedVP();
				} else {
					self.showValuePropositionRoles(true);
				}
				self.enableCompleteForActivity();
			}else if(diagramBO.name && self.BMsColl.length == 0 && !self.selectedBMVP()){
				$('#selectActivity' +self.encodeId).val(diagramBO.name);
				if(diagramBO.$type == "vdml:Activity"){
					self.usedCapabilityName(diagramBO.name);
					self.usedCapability(diagramBO.mcid);
				}/*else if(diagramBO.$type == "vdml:Value"){
					self.usedValueElementName(diagramBO.name);
					self.usedValue(diagramBO.mcid);
				}*/
				if(self.selectedShapeType.name == 'Competency'){
					$('#selectCompetence'+self.encodeId).val(diagramBO.name);$('#selectCompetencyType' + self.encodeId).attr('disabled', 'disabled');
					$("#mapCompCapability" + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
					$('#deleteComp' + self.encodeId).show();
					self.usedCapabilityComName(diagramBO.name);self.usedCapabilityCom(diagramBO.mcid);
				}
			}
			
			//}, false, null, false);

		};

		this.loadProviders = function (packId, limitToType, limitToBusiness, selectedBM) {
			var selProvider = self.vpProvider();
			self.vpProviders.removeAll();
			//var participants;
			if (!packId) {
				return;
			}
			function filterParticipants(type1, type2, callback) {
				var participants = [];
				if (self.fetchedObjects && self.fetchedObjects[packId] && self.fetchedObjects[packId][type1]) {
					participants = self.fetchedObjects[packId][type1];
					callback(participants);
				} else {
					DataManager.getDataManager().getAllDocumentsCollectionOfType(packId, type1, function (result) {
						for (var i = 0; i < result.length; i++) {
							/*if(!result.at(i).get('parent')){
								continue;
							}*/
							var relOrgUnit = Backbone.Relational.store.getObjectByName(type2).find({ id: result.at(i).get('id') });
							if (relOrgUnit) {
								participants.push(relOrgUnit);
							}
						}
						if (!self.fetchedObjects[packId]) {
							self.fetchedObjects[packId] = {};
						}
						self.fetchedObjects[packId][type1] = participants;
						callback(participants);
					}, false, null, false, null, null, true);
				}
			}
			function setParticipants(participants) {
				if (participants && participants.length > 0) {
					//var tempVpProvidersArray = [];
					for (var i = 0; i < participants.length; i++) {
						self.vpProviders.push({ name: participants[i].get('name'), id: participants[i].id });
					}
					self.vpProviders.sort(sortByName);
					//self.vpProviders(tempVpProvidersArray);
				}
				if (selProvider) {
					var item = $.grep(self.vpProviders(), function (e) { return e.name === selProvider.name; });
					if (item[0]) {
						self.vpProvider(item[0]);
					}
				}
			}
			if (self.myProposition()) {
				limitToType = 'vdml.OrgUnit'
			}
			if (limitToType === 'vdml.Actor') {
				filterParticipants('vdml_Actor', 'vdml.Actor', setParticipants);
			} else if (limitToType === 'vdml.Community') {
				filterParticipants('vdml_Community', 'vdml.Community', setParticipants);
			} else if (limitToType === 'vdml.OrgUnit') {
				//filterParticipants('vdml_OrgUnit', 'vdml.OrgUnit', function (participants) {
				if (limitToBusiness && self.selectedBMVP()) {
					var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.selectedBMVP().id });
					var participants = [bm.get('business')];
					participants = $.grep(participants, function (part) { return part.id === bm.get('business').id; });
				}
				setParticipants(participants);
				//});

			} else if (selectedBM) {
				var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: selectedBM.id });
				if (!bm || !bm.get('business')) {
					return;
				}
				//var bmCustomers = _.map(bm.get('bmCustomer').models, function (bmCust) { return bmCust.get('customer') });
				//var bmPartners = _.map(bm.get('bmNetworkPartner').models, function (bmPart) { return bmPart.get('networkPartner') });
				//var participants = _.union(bmCustomers, bmPartners);
				//participants.unshift(bm.get('business'));
				setParticipants(bm.getParticipants());
			} else {
				filterParticipants('vdml_Community', 'vdml.Community', setParticipants);
				filterParticipants('vdml_OrgUnit', 'vdml.OrgUnit', setParticipants);
				filterParticipants('vdml_Actor', 'vdml.Actor', setParticipants);
			}
		};
		this.loadReceivers = function (packId, limitToType, selectedBM) {
			var selReceiver = self.vpReceiver();
			self.vpReceivers.removeAll();
			var participants = [];
			function filterParticipants(type1, type2, callback) {
				if (self.fetchedObjects && self.fetchedObjects[packId] && self.fetchedObjects[packId][type1]) {
					participants = self.fetchedObjects[packId][type1];
					callback(participants);
				} else {
					DataManager.getDataManager().getAllDocumentsCollectionOfType(packId, type1, function (result) {
						for (var i = 0; i < result.length; i++) {
							var relOrgUnit = Backbone.Relational.store.getObjectByName(type2).find({ id: result.at(i).get('id') });
							if (relOrgUnit) {
								participants.push(relOrgUnit);
							}
						}
						if (!self.fetchedObjects[packId]) {
							self.fetchedObjects[packId] = {};
						}
						self.fetchedObjects[packId][type1] = participants;
						callback(participants);
					}, false, null, false, null, null, true);
				}
				return participants;
			}
			function setParticipants(participants) {
				if (participants && participants.length > 0) {
					for (var i = 0; i < participants.length; i++) {
						self.vpReceivers.push({ name: participants[i].get('name'), id: participants[i].id });
					}
					self.vpReceivers.sort(sortByName);
				}
				if (selReceiver) {
					var item = $.grep(self.vpReceivers(), function (e) { return e.name === selReceiver.name; });
					if (item[0]) {
						self.vpReceiver(item[0]);
					}
				}
			}
			if (limitToType === 'vdml.Actor') {
				filterParticipants('vdml_Actor', 'vdml.Actor', setParticipants);
			} else if (limitToType === 'vdml.Community') {
				filterParticipants('vdml_Community', 'vdml.Community', setParticipants);
			} else if (limitToType === 'vdml.OrgUnit') {
				participants = filterParticipants('vdml_OrgUnit', 'vdml.OrgUnit', setParticipants);
			} else if (selectedBM) {
				var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: selectedBM.id });
				if (!bm || !bm.get('business')) {
					return;
				}
				//var bmCustomers = _.map(bm.get('bmCustomer').models, function (bmCust) { return bmCust.get('customer') });
				//var bmPartners = _.map(bm.get('bmNetworkPartner').models, function (bmPart) { return bmPart.get('networkPartner') });
				//var participants = _.union(bmCustomers, bmPartners);
				//participants.push(bm.get('business'));
				setParticipants(bm.getParticipants());
			} else {
				filterParticipants('vdml_Community', 'vdml.Community', setParticipants);
				filterParticipants('vdml_OrgUnit', 'vdml.OrgUnit', setParticipants);
				filterParticipants('vdml_Actor', 'vdml.Actor', setParticipants);
			}
		};

		this.loadProviderRoles = function (provider, receiverRole) {
			var selProviderRole = self.selectedProviderRole();
			self.vpProviderRoles.removeAll();
			if (!self.selectedBMVP()) {
				return;
			}
			var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.selectedBMVP().id });
			if (!provider) {
				return;
			}
			var bmRoles = bm.get('bmRole');
			bmRoles.each(function (role) {
				var roleAssignments = role.get('roleAssignment');
				for (var i = 0; i < roleAssignments.length; i++) {
					var assignment = roleAssignments.at(i);
					if (assignment.get('participant').id === provider.id) {
						var allBMRoles = bm.get('bmRole').models;
						var displayRolename = self.checkDuplicatesPath(allBMRoles, role, role.get('name'));
						if (receiverRole && receiverRole.id === role.id) {
							break;
						}
						self.vpProviderRoles.push({ id: role.id, name: role.get('name'), displayRolename: displayRolename });
					}
				}
			});
			self.vpProviderRoles.sort(sortRolesByName);
			if (selProviderRole) {
				var item = $.grep(self.vpProviderRoles(), function (e) { return e.name === selProviderRole.name; });
				self.selectedProviderRole(item[0]);
			}
		};

		this.loadReceiverRoles = function (receiver, providerRole) {
			var selReceiverRole = self.selectedReceiverRole();
			self.vpReceiverRoles.removeAll();
			if (!self.selectedBMVP()) {
				return;
			}
			var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.selectedBMVP().id });
			if (!receiver) {
				return;
			}
			var bmRoles = bm.get('bmRole');
			bmRoles.each(function (role) {
				var roleAssignments = role.get('roleAssignment');
				for (var i = 0; i < roleAssignments.length; i++) {
					var assignment = roleAssignments.at(i);
					if (assignment.get('participant').id === receiver.id) {
						var allBMRoles = bm.get('bmRole').models;
						var displayRolename = self.checkDuplicatesPath(allBMRoles, role, role.get('name'));
						if (providerRole && providerRole.id === role.id) {
							break;
						}
						self.vpReceiverRoles.push({ id: role.id, name: role.get('name'), displayRolename: displayRolename });
					}
				}
			});
			self.vpReceiverRoles.sort(sortRolesByName);
			if (selReceiverRole) {
				var item = $.grep(self.vpReceiverRoles(), function (e) { return e.name === selReceiverRole.name; });
				self.selectedReceiverRole(item[0]);
			}
		};

		this.refreshProviderAndRecipientBasedOnSelectedVP = function (vpModel) {
			self.selectedProviderRole(null);
			self.vpReceiver(null);
			self.selectedReceiverRole(null);
			var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: vpModel.get('id') });
			//self.loadProviders(self.packagesListVP()[0].id);
			//self.loadReceivers(self.packagesListVP()[0].id);
			var providerRole = vp.get('provider');
			var assignment = providerRole.get('roleAssignment').at(0);
			var participant = assignment ? assignment.get('participant') : null;
			var provider = $.grep(self.vpProviders(), function (participantProvider1) { return participantProvider1.id === participant.id; });
			if (provider && provider.length) {
				self.vpProvider(provider[0]);
			}
			var roleToDisplay = $.grep(self.vpProviderRoles(), function (role1) { return role1.id === providerRole.id; });
			if (roleToDisplay && roleToDisplay.length) {
				self.selectedProviderRole(roleToDisplay[0]);
			}
			var recipientRole = vp.get('recipient');
			if (recipientRole) {
				assignment = recipientRole.get('roleAssignment').at(0);
				participant = assignment ? assignment.get('participant') : null;
				var recipient = $.grep(self.vpReceivers(), function (participantReceiver1) { return participantReceiver1.id === participant.id; });
				if (recipient && recipient.length) {
					self.vpReceiver(recipient[0]);
				}
				var roleToDisplay = $.grep(self.vpReceiverRoles(), function (role1) { return role1.id === recipientRole.id; });
				if (roleToDisplay && roleToDisplay.length) {
					self.selectedReceiverRole(roleToDisplay[0]);
				}
			}
			$('#providerField' + self.encodeId).attr('disabled', 'disabled');
			$('#providerRoleField' + self.encodeId).attr('disabled', 'disabled');
			$('#recipientField' + self.encodeId).attr('disabled', 'disabled');
			$('#recipientRoleField' + self.encodeId).attr('disabled', 'disabled');
		}

		this.loadPerformers = function (limitToBusiness) {
			self.activityPerformers.removeAll();
			var activityPerformersList = [];
			if (limitToBusiness) {
				var bmModel = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.selectedBMVP().id });
				var bmRoles = bmModel.get('bmRole');
				bmRoles.each(function (role) {
					var roleAssignments = role.get('roleAssignment');
					if (roleAssignments && roleAssignments.at(0)) {
						var part = roleAssignments.at(0).get('participant');
						if (_.filter(activityPerformersList, function (performer) { if (performer.id === part.id) { return performer } }).length == 0) {
							activityPerformersList.push({ id: part.id, name: part.get('name') });
						}

					}
				});
			} else {
				var colls = self.selectedVDMPackage.get('collaboration');
				colls.each(function (coll) {
					if (coll.get('type') !== 'vdml_BusinessNetwork') {
						activityPerformersList.push({ name: coll.get('name'), id: coll.id });
					}
				});
			}
			activityPerformersList = _.uniq(activityPerformersList);
			activityPerformersList.sort(sortByName);
			self.activityPerformers(activityPerformersList);
		};
		this.loadPerformerRoles = function (provider) {
			var selPerformerRoles = self.activityPerformerRole();
			self.activityPerformerRoles.removeAll();
			//$('#performer'+self.encodeId).val('');
			if (!provider || !self.selectedBMVP()) {
				return;
			}
			var bmModel = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.selectedBMVP().id });
			var bmRoles = bmModel.get('bmRole');
			bmRoles.each(function (role) {
				var roleAssignments = role.get('roleAssignment');
				roleAssignments.each(function (assignment) {
					if (assignment.get('participant').id === provider.id) {
						var roleName = role.get('name').concat(getRoleDispName(role, provider));
						self.activityPerformerRoles.push({ id: role.id, name: roleName });
					}
				});
				self.activityPerformerRoles.sort(sortByName);
			});
			if (selPerformerRoles) {
				var item = $.grep(self.activityPerformerRoles(), function (e) { return e.name === selPerformerRoles.name; });
				self.activityPerformerRole(item[0]);
			}
		};

		function getRoleDispName(role, participant) {
			var roleNS = "";
			var bmModel = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.selectedBMVP().id });;
			var roles = bmModel.get('bmRole');
			var roleModel = roles.findWhere({ id: role.id });
			if (roleModel) {
				for (var i = 0; i < roles.length; i++) {
					var roleAssignment = roles.at(i).get('roleAssignment').at(0);
					var roleParticipant = roleAssignment ? roleAssignment.get('participant').get('id') : null;
					if ((roles.at(i).get('name') === roleModel.get('name')) && (roles.at(i).get('id') !== roleModel.get('id') && participant.id === roleParticipant)) {
						roleNS = " [";
						for (var k = 0; k < roleModel.getPackagePath().length - 1; k++) {
							roleNS = roleNS.concat(roleModel.getPackagePath()[k].name);
							if (roleModel.getPackagePath()[k + 2]) {
								roleNS = roleNS.concat("/");
							}
						}
						roleNS = roleNS.concat("]");
					}
				}
			}
			return roleNS;
		}

		//load saving functions
		this.mapActivity = function (diagramBO, callback, revisionMapPackage) {
			self.createVP(revisionMapPackage, function (createdVP, selectedVDMPackage, vdmlPackageRevision) {
				self.createActivity(createdVP, vdmlPackageRevision, function (activity, selectedValueStreamModel, vdmlPackageRevision) {
					if (self.isValueStreamMap && !diagramBO.$parent.mid) {
						setDiagramId(diagramBO.$parent, selectedValueStreamModel, self.selectedVDMPackage);
						var elementRegistry = self.parentView.modeler.get('elementRegistry');
						var element = elementRegistry.get(diagramBO.$parent.id);
						self.eventBus.fire('elements.changed', {
							elements: [element]
						});
					}
					var packageRevision = vdmlPackageRevision
					if(revisionMapPackage){
						packageRevision = revisionMapPackage;
					}
					setDiagramId(diagramBO, activity, selectedVDMPackage, callback, packageRevision);
				});
			});
		}
		this.mapCompetency = function (diagramBO, callback, revisionMapPackage) {
			self.createVP(revisionMapPackage, function (createdVP, selectedVDMPackage, vdmlPackageRevision) {
				self.createActivity(createdVP, vdmlPackageRevision, function (activity,selectedValueStreamModel,vdmlPackageRevision) {
					self.updateResource(activity, vdmlPackageRevision, function (competence, vdmlPackageRevision) {
						var packageRevision = vdmlPackageRevision
						if(revisionMapPackage){
							packageRevision = revisionMapPackage;
						}
						setDiagramId(diagramBO, competence, selectedVDMPackage, callback, packageRevision);
					});
				});
			});
		}
		this.mapValue = function (diagramBO, callback, revisionMapPackage) {
			if (!self.selectedValue) {
				if (self.selectedValueType() === "Plan") {
					self.updatePlanValue(function (value) {
						var pack = value.getNestedParent();
						if (self.usedValue()) {
							var valModel = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue() });
							value.set('valueDefinition', valModel);
						}
						setDiagramId(diagramBO, value, pack, callback, revisionMapPackage);
					});
				} else if (self.selectedValueType() === "Activity") {
					self.createVP(revisionMapPackage, function (createdVP, selectedVDMPackage, vdmlPackageRevision) {
						self.createActivity(createdVP, vdmlPackageRevision, function (activity, selectedValueStreamModel,vdmlPackageRevision) {
							self.updateActivityValue(activity, selectedValueStreamModel, vdmlPackageRevision, function (value,vdmlPackageRevision) {
								var packageRevision = vdmlPackageRevision
								if(revisionMapPackage){
									packageRevision = revisionMapPackage;
								}
								if (self.usedValue()) {
									var valModel = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue() });
									value.set('valueDefinition', valModel);
								}
								setDiagramId(diagramBO, value, selectedVDMPackage, callback, packageRevision);
							});
						});
					});
				} else {
					self.createVP(revisionMapPackage, function (createdVP, selectedVDMPackage, vdmlPackageRevision) {
						self.updateVPValue(createdVP, vdmlPackageRevision, function (value, vdmlPackageRevision) {
							var packageRevision = vdmlPackageRevision
							if(revisionMapPackage){
								packageRevision = revisionMapPackage;
							}
							if (self.usedValue()) {
								var valModel = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue() });
								value.set('valueDefinition', valModel);
							}
							setDiagramId(diagramBO, value, selectedVDMPackage, callback, packageRevision);
						});
					});
				}
			} else if (self.mappedDiagramBO) {
				self.createVP(revisionMapPackage, function (createdVP, selectedVDMPackage, vdmlPackageRevision) {
					self.createActivity(createdVP, vdmlPackageRevision, function (activity, selectedValueStreamModel,vdmlPackageRevision) {
						var vs = Backbone.Relational.store.getObjectByName('vdml.ValueStream').find({ id: selectedValueStreamModel.id });
						var actValId, bmId;
						if (vdmlPackageRevision) {
							if (DataManager.getDataManager().getRepositoryId(self.selectedValue.id) != window.plansKey) {
								actValId = DataManager.getDataManager().get('viewAlternative') + window.utils.getSuffix(self.selectedValue.id);
							}
							bmId = DataManager.getDataManager().get('viewAlternative') + window.utils.getSuffix(self.selectedBMVP().id);
						} else {
							actValId = self.selectedValue.id;
							bmId = self.selectedBMVP().id;
						}
						var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': bmId });
						var valueModal = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: actValId });
						vs.get('activityValue').add(valueModal);
						var bmAct = bm.get('bmActivity').findWhere({ 'activity': activity });
						if (bmAct) {
							bmAct.get('activityValue').add(valueModal);
						}
						var packageRevision = vdmlPackageRevision
						if(revisionMapPackage){
							packageRevision = revisionMapPackage;
						}
						callback(packageRevision);
					});
				});
			} else {
				function getValueModal(valId){
					var valueModal = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: valId });
					if (!valueModal) {
						valueModal = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: valId });
					}
					return valueModal
				}
				var valId = self.selectedValue.id;
				var valueModal = getValueModal(valId);
				var isRevision = false;
				var pack = valueModal.getNestedParent();
				if (revisionMapPackage && DataManager.getDataManager().getRepositoryId(self.selectedValue.id) != window.plansKey) {
					var currentAltId = DataManager.getDataManager().get('viewAlternative');
					var altModel  = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({id:currentAltId});
					var packExists = altModel.get('phaseDesignPart').findWhere({'beepReference':pack.id});
					if(!packExists) {
						valId = DataManager.getDataManager().get('viewAlternative') + window.utils.getSuffix(self.selectedValue.id);
						valueModal = getValueModal(valId);
						pack = valueModal.getNestedParent();
						isRevision = true;
					}
				}
				if (self.usedValue()) {
					var valModel = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue() });
					valueModal.set('valueDefinition', valModel);
				}
				setDiagramId(diagramBO, valueModal, pack, callback, isRevision);
			}
		}

		this.mapValueStream = function (diagramBO, callback, revisionMapPackage) {
			var bm;
			if (revisionMapPackage) {
				var altId = DataManager.getDataManager().get('viewAlternative');
				var bmId = altId + window.utils.getSuffix(self.selectedBMVP().id);
				bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': bmId });
			} else {
				bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': self.selectedBMVP().id })
			}
			self.createVP(revisionMapPackage, function (persuedVP, vdmlPackage) {
				var vdmlPackage = persuedVP.getNestedParent();
				var valueStreams = vdmlPackage.get('valueStream');
				var selectedValueStreamModel = null;
				for (var i = 0; i < valueStreams.length; i++) {
					if (valueStreams.at(i).get('persuedProposition') && valueStreams.at(i).get('persuedProposition').get('id') === persuedVP.id) {
						selectedValueStreamModel = valueStreams.at(i);
						break;
					}
				}
				if (selectedValueStreamModel == null) {
					var vsId = DataManager.getDataManager().guidGeneratorByOwner(vdmlPackage);
					selectedValueStreamModel = new ValueStream({ id: vsId, name: persuedVP.get('name'), description: persuedVP.get('name'), valueStreamOwner: vdmlPackage });
				}
				bm.get('bmValueStream').add(selectedValueStreamModel);
				selectedValueStreamModel.set('persuedProposition', persuedVP);
				setDiagramId(diagramBO, selectedValueStreamModel, vdmlPackage, callback, revisionMapPackage);
			});
		}
		this.createActivity = function (vp, vdmlPackageRevision, callback) {
			var bm, performerRole, selActivity, capModel,activityModel;
			if (self.usedCapability()) {
				capModel = self.getCapabilityElementModel(self.usedCapability());
			}
			if (!self.showActivityDiv()) {
				callback(self.selectedActivity,vdmlPackageRevision);
				return;
			}
			if (vdmlPackageRevision) {
				var altId = DataManager.getDataManager().get('viewAlternative');
				var bmId = altId + window.utils.getSuffix(self.selectedBMVP().id);
				bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': bmId });
				var performerRoleId = altId + window.utils.getSuffix(self.activityPerformerRole().id);
				performerRole = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: performerRoleId });
				if (self.selectedActivity) {
					var activityModelId = altId + window.utils.getSuffix(self.selectedActivity.get('id'));
					activityModel = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: activityModelId });
				}
			} else {
				bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': self.selectedBMVP().id });
				performerRole = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: self.activityPerformerRole().id });
				if (self.selectedActivity) {
					activityModel = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: self.selectedActivity.get('id') });
				}
			}
			if (!bm || !self.activityPerformerRole()) {
				return;
			}
			var activityName = $("#selectActivity" + self.encodeId).val().trim();
			var newActivity;
			function updateValueStream(newActivity) {
				var persuedVP = vp;
				var vdmlPackage = persuedVP.getNestedParent();
				var valueStreams = vdmlPackage.get('valueStream');
				var selectedValueStreamModel = null;
				for (var i = 0; i < valueStreams.length; i++) {
					if (valueStreams.at(i).get('persuedProposition') && valueStreams.at(i).get('persuedProposition').get('id') === persuedVP.id) {
						selectedValueStreamModel = valueStreams.at(i);
						break;
					}
				}
				if (selectedValueStreamModel == null) {
					var vsId = DataManager.getDataManager().guidGeneratorByOwner(vdmlPackage);
					selectedValueStreamModel = new ValueStream({ id: vsId, name: persuedVP.get('name'), description: persuedVP.get('name'), valueStreamOwner: vdmlPackage });
				}
				bm.get('bmValueStream').add(selectedValueStreamModel);
				selectedValueStreamModel.set('persuedProposition', persuedVP);
				selectedValueStreamModel.get('valueStreamActivity').add(newActivity);
				callback(newActivity, selectedValueStreamModel,vdmlPackageRevision);
			}
			if (self.selectedActivity) {
				var activityExistsInBM = bm.get('bmActivity').find({ 'activity': activityModel });
				activityModel.set('capabilityRequirement', capModel);
				if (activityExistsInBM) {
					if (vp) {
						updateValueStream(activityModel);
					} else {
						callback(activityModel, null, vdmlPackageRevision);
					}
				} else {
					bm.addExistingActivities(activityModel);
					if (vp) {
						updateValueStream(activityModel);
					} else {
						callback(activityModel, null, vdmlPackageRevision);
					}
				}
			} else {
				bm.addBMActivities(activityName, activityName, performerRole, self.currentAlternativeModel, function (newActivity) {
					newActivity.set('capabilityRequirement', capModel);
					updateValueStream(newActivity);
				});
			}
		};

		this.createVP = function (revisionMapPackage, callback) {
			var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.selectedBMVP().id });
			function checkIfVPAlreadyExistsInBM() {
				if (self.selectedBMVP() && self.selectedVP) {
					var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': self.selectedBMVP().id });
					var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: self.selectedVP.id });
					if (bm.get('bmValueProposition').findWhere({ 'valueProposition': vp })) {
						return true;
					} else {
						return false;
					}
				} else {
					return false;
				}
			}
			function replaceVPObjects(newPackage, vdmlPackageRevision) {
				var altId = DataManager.getDataManager().getRepositoryId(newPackage.id);
				if (self.selectedBMVP && self.selectedBMVP()) {
					var selectedBMVPSuffix = window.utils.getSuffix(self.selectedBMVP().id);
					var bmId = altId + selectedBMVPSuffix;
				}
				var selectedVP, vpProvider, vpReceiver, vpProviderRole, vpReceiverRole;
				if (self.selectedVP /*&& self.selectedVP instanceof Backbone.RelationalModel*/) {
					var selectedVPSuffix = window.utils.getSuffix(self.selectedVP.id);
					selectedVP = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: altId + selectedVPSuffix });
				}
				var providerSuffix = window.utils.getSuffix(self.vpProvider().id);
				vpProvider = self.vpProvider();
				vpProvider.id = altId + providerSuffix;
				var providerRoleSuffix = window.utils.getSuffix(self.selectedProviderRole().id);
				vpProviderRole = self.selectedProviderRole();
				vpProviderRole.id = altId + providerRoleSuffix;
				if (self.vpReceiver()) {
					var receiverSuffix = window.utils.getSuffix(self.vpReceiver().id);
					vpReceiver = self.vpReceiver();
					vpReceiver.id = altId + receiverSuffix;
					var receiverRoleSuffix = window.utils.getSuffix(self.selectedReceiverRole().id);
					vpReceiverRole = self.selectedReceiverRole();
					vpReceiverRole.id = altId + receiverRoleSuffix;
				}
				saveMapVPObjects(diagramBO, bmId, newPackage, selectedVP, vpProvider, vpProviderRole, vpReceiver, vpReceiverRole, callback, vdmlPackageRevision);
			}
			var viewAltId = DataManager.getDataManager().get('viewAlternative');
			var pack = self.selectedVDMPackage;
			if (revisionMapPackage && pack) {
				var replacePackage = self.checkIfVDMPackageIsDependentOfEcoMap(viewAltId, revisionMapPackage, pack);
				if (replacePackage) {
					pack = replacePackage;
				}
			}
			if (pack && pack.id && DataManager.getDataManager().getRepositoryId(pack.id) !== viewAltId) {
				var packModel = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: pack.id });
				self.createRevisionBasedOnContext(packModel, true, function (newPackage) {
					window.utils.stopSpinner('revisionSpinner');
					if (!self.showValueProposition()) {
						callback(null, self.selectedVDMPackage, true);
						return;
					} else {
						replaceVPObjects(newPackage, true);
					}
				});
			} else if (replacePackage) {
				if (!self.showValueProposition()) {
					callback(null, self.selectedVDMPackage, true);
					return;
				} else {
					replaceVPObjects(pack, true);
				}
			} else {
				if (!self.showValueProposition()) {
					callback(null, self.selectedVDMPackage, null);
					return;
				} else {
					var selectedVP = self.selectedVP ? Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: self.selectedVP.get('id') }) : null;
					saveMapVPObjects(diagramBO, self.selectedBMVP().id, self.selectedVDMPackage, selectedVP, self.vpProvider(), self.selectedProviderRole(), self.vpReceiver(), self.selectedReceiverRole(), callback);
				}
			}
			function saveMapVPObjects(diagramBO, bmId, selectedVDMPackage, selectedVP, vpProvider, vpProviderRole, vpReceiver, vpReceiverRole, callback, vdmlPackageRevision) {
				var vp, bmVF, bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: bmId }), VPName = $("#selectVP" + self.encodeId).val().trim();
				var VPDescription = diagramBO.description || VPName;
				var providerRole;
				if (self.selectedProviderRole()) {
					providerRole = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: vpProviderRole.id });
				}
				if (!self.myProposition()) {
					var vpExists = bm.get('bmValueProposition').findWhere({ 'valueProposition': selectedVP });
					var receiverRole;
					if (self.selectedReceiverRole()) {
						receiverRole = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: vpReceiverRole.id });
					}
					if (vpExists) {
						vp = selectedVP;
						callback(vp, selectedVDMPackage, vdmlPackageRevision);
					} else {
						if (selectedVP) {
							bm.addBMValueProposition(selectedVP, VPName, providerRole, receiverRole, VPDescription, self.currentAlternative, function (bmVP) {
								vp = bmVP.get('valueProposition');
								callback(vp, selectedVDMPackage, vdmlPackageRevision);
							});
						} else {
							if (!providerRole) {
								callback(vp, selectedVDMPackage, vdmlPackageRevision);
							} else {
								bm.addBMValueProposition(null, VPName, providerRole, receiverRole, VPDescription, self.currentAlternativeModel, function (bmVP) {
									vp = bmVP.get('valueProposition');
									callback(vp, selectedVDMPackage, vdmlPackageRevision);
								});
							}
						}
					}
				} else {
					var vfExists = bm.get('bmValueFormula').findWhere({ 'valueFormula': selectedVP });
					if (vfExists) {
						vp = selectedVP;
						callback(vp, selectedVDMPackage, vdmlPackageRevision);
					} else {
						if (selectedVP) {
							bm.addBMValueFormula(selectedVP, VPName, providerRole, VPDescription, self.currentAlternativeModel, function (bmVF) {
								vp = bmVF.get('valueFormula');
								callback(vp, selectedVDMPackage, vdmlPackageRevision);
							});
						} else {
							bm.addBMValueFormula(null, VPName, providerRole, VPDescription, self.currentAlternativeModel, function (bmVF) {
								vp = bmVF.get('valueFormula');
								callback(vp, selectedVDMPackage, vdmlPackageRevision);
							});
						}
					}
				}
			}
		};
		this.updatePlanValue = function (callback) {
			var value, valueName = $('#selectValue' + self.encodeId).val().trim();
			var planCriterionSet = DataManager.getDataManager().get('currentPlan').getOrCreatePlanCriterionSet();
			value = self.createVPC(planCriterionSet, null, valueName);
			callback(value);
		}
		this.updateActivityValue = function (valueProvider, activityVP, vdmlPackageRevision, callback) {
			var bm;
			if (vdmlPackageRevision) {
				var altId = DataManager.getDataManager().get('viewAlternative');
				var bmId = altId + window.utils.getSuffix(self.selectedBMVP().id);
				bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': bmId });
			} else {
				bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': self.selectedBMVP().id });
			}
			var value, valueName = $('#selectValue' + self.encodeId).val().trim();
			var activity = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: valueProvider.id });
			var bmActivities = bm.get('bmActivity');
			var bmActivity = bmActivities.findWhere({ 'activity': activity });
			var outputPort = bmActivity ? bmActivity.get('defaultOutput') : null;
			var outputPortName;
			if (!outputPort) {
				outputPortName = activity.getUniqueProperty('name', activity.get('name'), 'containedPort') + " port";
			} else {
				outputPortName = outputPort.get('name');
			}
			var collaboration = activity.getParent();
			var vdml = collaboration.getNestedParent();
			var namePath = vdml.get('name') + '>' + collaboration.get('name') + '>' + activity.get('name') + '>' + outputPortName + '>' + valueName;
			vdml.getDocumentWithNamePath(namePath, "vdml_ValueAdd", self.currentAlternativeModel, true, vdml, function (results) {
				if (results && results[0]) {
					var doc = results[0].doc;
					var parent = results[0].parent;
					var altId = DataManager.getDataManager().getRepositoryId(doc);
					var vdmStore = DataManager.getDataManager().getVDMStore(altId);
					var version = DataManager.getDataManager().get('currentVDMVersion');
					DataManager.getDataManager().fetchDocumentFromPackage(parent, "appbo/vdml/ValueDeliveryModel", version, doc, "appbo/vdml/ValueAdd", vdmStore, {
						success: function (valModel) {
							value = self.createValueAdd(bm, activity, valueName, bmActivity, valModel);
							var vs = Backbone.Relational.store.getObjectByName('vdml.ValueStream').find({ id: activityVP.id });
							vs.get('activityValue').add(value);
							callback(value,vdmlPackageRevision);
						},
						error: function (error) {
							console.log('Unable to load Value document');
							value = self.createValueAdd(bm, activity, valueName, bmActivity);
							var vs = Backbone.Relational.store.getObjectByName('vdml.ValueStream').find({ id: activityVP.id });
							vs.get('activityValue').add(value);
							callback(value,vdmlPackageRevision);
						}
					});

				} else {
					value = self.createValueAdd(bm, activity, valueName, bmActivity);
					var vs = Backbone.Relational.store.getObjectByName('vdml.ValueStream').find({ id: activityVP.id });
					vs.get('activityValue').add(value);
					callback(value,vdmlPackageRevision);
				}
			});
		}
		this.updateVPValue = function (valueProvider, vdmlPackageRevision, callback) {
			var bm;
			if (vdmlPackageRevision) {
				var altId = DataManager.getDataManager().get('viewAlternative');
				var bmId = altId + window.utils.getSuffix(self.selectedBMVP().id);
				bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': bmId });
			} else {
				bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': self.selectedBMVP().id });
			}
			var value, valueName = $('#selectValue' + self.encodeId).val().trim();
			var vpc = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: valueProvider.id });
			var providerRole = vpc.get('provider');
			var collaboration = providerRole.getParent();
			var vdml = collaboration.getNestedParent();
			var namePath = vdml.get('name') + '>' + collaboration.get('name') + '>' + providerRole.get('name') + '>' + vpc.get('name') + '>' + valueName;
			vdml.getDocumentWithNamePath(namePath, "vdml_ValuePropositionComponent", self.currentAlternativeModel, true, vdml, function (results) {
				if (results && results[0]) {
					self.fetchNamePathValue(0, results, vpc, valueName, function (vpc, oldVpc, valueName) {
						value = self.createVPC(vpc, oldVpc, valueName);
						callback(value,vdmlPackageRevision);
					});
				} else {
					value = self.createVPC(vpc, null, valueName);
					callback(value,vdmlPackageRevision);
				}
			});

		};

		this.createVPC = function (vp, oldVpc, valueName) {
			var newVPCIdSuffix = oldVpc ? window.utils.getSuffix(oldVpc.id) : null;
			var vpc = vp.createValuePropositionComponent(valueName, valueName, false, newVPCIdSuffix);
			var vdm = vp.getNestedParent();
			vdm.createValueDetails(vpc,oldVpc);
			return vpc;
		};
		this.createValueAdd = function (bm, activity, activityValueName, bmActivityModal, oldValModel) {
			var activityValue = activity.createWhatValues(bm, self.currentAlternativeModel, activityValueName, null, null, null, null, null, null, null, bmActivityModal, oldValModel);
			bmActivityModal.get('activityValue').add(activityValue);
			return activityValue;
		}

		this.fetchNamePathValue = function (count, results, component, valueName, callback) {
			var doc = results[count].doc;
			var parent = results[count].parent;
			var altId = DataManager.getDataManager().getRepositoryId(doc);
			var vdmStore = DataManager.getDataManager().getVDMStore(altId);
			var version = DataManager.getDataManager().get('currentVDMVersion');
			DataManager.getDataManager().fetchDocumentFromPackage(parent, "appbo/vdml/ValueDeliveryModel", version, doc, "appbo/vdml/ValuePropositionComponent", vdmStore, {
				success: function (valModel) {
					if (window.utils.getSuffix(valModel.getParent().id) === window.utils.getSuffix(component.id)) {
						callback(component, valModel, valueName);
					} else {
						count++;
						if (results[count]) {
							self.fetchNamePathValue(count, results, component, valueName, callback);
						} else {
							callback(component, null, valueName);
						}
					}
				},
				error: function (error) {
					console.log('Unable to load Value document');
					callback(component, null, valueName);
				}
			});
		}

		//load functions for competence
		this.loadCompetencies = function (includeBusinessItem) {
			self.competenciesColl.reset();
			if (!self.selectedBMVP()) {
				return;
			}
			var capUsed = diagramBO.get('mcid') ? true : false;
			var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': self.selectedBMVP().id });
			var business = bm.get('business');
			if (!self.mappedDiagramBO) {
				_.each(bm.get('bmCompetence').models, function (comp) {
					if (capUsed) {
						if (comp.get('competence').get('type') === "vdml_CapabilityOffer") {
							if (comp.get('competence').get('capability') && comp.get('competence').get('capability').id === diagramBO.get('mcid')) {
								self.competenciesColl.push(comp.get('competence'));
							}
						}
					} else {
						self.competenciesColl.push(comp.get('competence'));
					}
				});
			}
			else if (business) {
				var capabilityOfferCompetencies = business.get('capabilityOffer');
				if (capabilityOfferCompetencies && capabilityOfferCompetencies.models) {
					for (var i = 0; i < capabilityOfferCompetencies.models.length; i++) {
						if (capUsed) {
							if (capabilityOfferCompetencies.at(i).get('capability') && capabilityOfferCompetencies.at(i).get('capability').id === diagramBO.get('mcid')) {
								self.competenciesColl.add(capabilityOfferCompetencies.at(i));
							}
						} else {
							self.competenciesColl.add(capabilityOfferCompetencies.at(i));
						}
					}
				}
				if (includeBusinessItem && !capUsed) {
					var resourceCompetencies = business.get('ownedStore');
					if (resourceCompetencies && resourceCompetencies.models) {
						for (var i = 0; i < resourceCompetencies.models.length; i++) {
							var resource = resourceCompetencies.at(i).get('resource');
							self.competenciesColl.add(resource);
						}
					}
				}
				var subOrgUnits = business.getSubUnits();
				if (subOrgUnits.length > 0 && !capUsed) {
					for (var i = 0; i < subOrgUnits.length; i++) {
						self.competenciesColl.add(subOrgUnits.models[i]);
					}
				}
			}
			if (diagramBO.get('name') && self.competenceMap()) {
				$('#selectCompetence' + self.encodeId).val(diagramBO.get('name'));
				if (self.mappedDiagramBO) {
					$('#selectCompetence' + self.encodeId).attr('disabled', 'disabled');
				}
				var competencyFound = _.filter(self.competenciesColl.models, function (obj) { return obj.get('name').toLowerCase() === diagramBO.get('name').toLowerCase() });
				if (competencyFound && competencyFound.length) {
					self.selectedCompetence = competencyFound[0];
					var type = self.selectedCompetence.get('type');
					var typeObj = _.find(self.resourceTypes(), function (e) { return e.type === type; });
					self.selectedResourceType(typeObj);
					$('#showMappedInfoCompetency' + self.encodeId).show();
					$('#selectCompetencyType' + self.encodeId).attr('disabled', 'disabled');
					var bmCompExists = _.filter(bm.get('bmCompetence').models, function (obj) { return obj.get('competence') === self.selectedCompetence });
					if (bmCompExists && bmCompExists.length > 0 && !self.mappedDiagramBO && !self.isValueStreamMap) {
						self.showActivityDiv(false);
					} else {
						self.showActivityDiv(true);
					}
				} else {
					$('#showMappedInfoCompetency' + self.encodeId).hide();
					$('#selectCompetencyType' + self.encodeId).removeAttr('disabled');
					self.showActivityDiv(true);
				}
				if (diagramBO.get('mcid')) {
					var typeObj = _.find(self.resourceTypes(), function (e) { return e.type === 'vdml_CapabilityOffer'; });
					self.selectedResourceType(typeObj);
					$('#selectCompetencyType' + self.encodeId).attr('disabled', 'disabled');
				} else {
					//$('#selectCompetencyType' + self.encodeId).removeAttr('disabled');
				}
				self.enableCompleteForCompetency();
			}
			var compModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityOffer').find({ id: diagramBO.get('mid') });
			var capModel = compModel?compModel.get('capability'):null;
			if (capUsed || capModel) {
				var capModel = capModel?capModel:self.getCapabilityElementModel(diagramBO.get('mcid'));
				if (capModel) {
					self.usedCapabilityComName(capModel.get('name'));
					self.usedCapabilityCom(capModel.get('id'));
					self.usedCapabilityModel = capModel;
					$("#mapCompCapability" + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
					$("#deleteComp" + self.encodeId).show();
				}
				$("#capabilityLibCompDiv" + self.encodeId).show();
				//$('#selectCapabilityCom' + self.encodeId).attr('disabled', 'disabled');
			} else {
				if(self.selectedCompetence && self.selectedCompetence.get('capability')){
					self.usedCapabilityComName(self.selectedCompetence.get('capability').get('name'));
					self.usedCapabilityModel = self.selectedCompetence.get('capability');
					$("#mapCompCapability" + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
					$("#deleteComp" + self.encodeId).show();
					$("#capabilityLibCompDiv" + self.encodeId).show();
				}else if(self.selectedCompetence && self.selectedCompetence.get('capability') == null){
					$("#capabilityLibCompDiv" + self.encodeId).show();
				}
				else {
					$("#capabilityLibCompDiv" + self.encodeId).hide();
				}
			}
			self.competenciesColl.comparator = function (model) {
				return model.get('name');
			};
			self.competenciesColl.sort();
		}

		this.updateResource = function (activityRM, vdmlPackageRevision, callback) {
			var bm, competenceModel;
			var competence, resourceType = self.selectedResourceType().type === "vdml_BusinessItem" ? BusinessItem : CapabilityOffer;
			if (vdmlPackageRevision) {
				var altId = DataManager.getDataManager().get('viewAlternative');
				var bmId = altId + window.utils.getSuffix(self.selectedBMVP().id);
				bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': bmId });
				if (self.selectedCompetence) {
					var competenceModelId = altId + window.utils.getSuffix(self.selectedCompetence.id);
					if (resourceType === BusinessItem) {
						competenceModel = Backbone.Relational.store.getObjectByName('vdml.BusinessItem').find({ id: competenceModelId });
					} else {
						competenceModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityOffer').find({ id: competenceModelId });
					}
				}
			} else {
				bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': self.selectedBMVP().id });
				if (self.selectedCompetence) {
					if (resourceType === BusinessItem) {
						competenceModel = Backbone.Relational.store.getObjectByName('vdml.BusinessItem').find({ id: self.selectedCompetence.id });
					} else {
						competenceModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityOffer').find({ id: self.selectedCompetence.id });
					}
				}
			}
			if (!bm) {
				return;
			}
			var resourceName = $('#selectCompetence' + self.encodeId).val().trim();
			var scenario = self.currentAlternativeModel.getMainScenario(bm.getNestedParent());
			if (self.selectedCompetence) {
				if (competenceModel) {
					bm.addExistingCompetency(competenceModel);
					setMappedCompetency(competenceModel, callback);
				}
			} else {
				if (self.usedCapabilityCom()) {
					var capModel = self.getCapabilityElementModel(self.usedCapabilityCom());
				}
				bm.createNewCompetency(resourceName, resourceName, resourceType, null, bm.get('business'), scenario, self.currentAlternativeModel, function (competence) {
					setMappedCompetency(competence, callback);
				}, capModel);
			}

			function setMappedCompetency(competence, callback) {
				if (activityRM) {
					var activity = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: activityRM.id });
					if (resourceType === BusinessItem) {
						activity.createResourceInput(bm, competence, bm.get('business'));
					} else {
						if (self.usedCapabilityCom()) {
							capModel = self.getCapabilityElementModel(self.usedCapabilityCom());
						}
						competence.set('capability', capModel);
						activity.createCapabilityUse(bm, [competence], bm.get('business'), scenario);
					}
				}
				callback && callback(competence,vdmlPackageRevision);
			}
		};


		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
			'mapItem',
			'Activity',
			'SelectActivity',
			'selectCompetency',
			'prev',
			'next',
			'selectMappingElement',
			'selectDimensionTitle'
			, 'provider'
			, 'recipient'
			, 'SelectRole'
			, 'selectCreateVP'
			, 'BusinessModel'
			, 'selectCreateBM'
			, 'selectCreatePN'
			, 'selectCreateParticipant'
			, 'selectCreatePN'
			, 'selectElementType'
			, 'Role'
			, 'isType'
			, 'SelectParticipant'
			, 'inRole'
			, 'MyProposition'
			, 'isPerformedBy',
			, 'isUsedBy'
			, 'ValueProposition'
			, 'PlanValue'
			, 'Plan'
			, 'Package'
			, 'ActivityDetails'
			, 'ValueStreamDetails'
			, 'CapabilityElement'
			, 'ValueDefinition'
		]);

		this.startWizard = function (cond) {
			var viewModel = this;
			var modalContentNode = $('#wizardModalContent');
			modalContentNode.css('width', 'auto');
			modalContentNode.css('height', 'auto');
			modalContentNode.css('minHeight', 'auto');
			ko.unapplyBindings(modalContentNode, false);
			modalContentNode.children().remove();
			window.utils.startSpinner('createSpinner', "Loading Data...");
			var templates = window.utils.getHtmlPage('MappingWizardTemplate');
			//$.get("js/app/version1/views/strategyMap/views/designer/MappingWizardTemplate.html", function (templates) {
				if(document.getElementById('wizardTemplateContent')){
					return;
				}
				modalContentNode.append(templates);
				var templateNode = $('#wizardTemplateContent');
				ko.applyBindings(viewModel, templateNode[0]);
				if (self.shapeTypeFromWizard()) {
					self.selectedShapeType = self.shapeTypesList()[_.findLastIndex(self.shapeTypesList(), { name: self.shapeTypeFromWizard().name })];
				} else if (diagramBO) {
					self.selectedShapeType = self.shapeTypesList()[_.findLastIndex(self.shapeTypesList(), { name: self.mappingObject[diagramBO.$type].name })];
				}
				if (self.selectedShapeType.name === 'Competency' || self.selectedShapeType.name === 'Capability') {
					self.competenceMap(true);
				}
				var stepsNode = $('.steps');
				while ($('.steps li').length > 1) {
					$('.steps li').last().remove();
				}
				if (self.selectedShapeType.name === 'Value') {
					var stepStr = "<li class=\"wizardLibLi\" data-step=\"stepNo\"><span class=\"badge\">1</span><span>header</span><span class=\"chevron\"></span></li>";
					var reStep = new RegExp('stepNo', 'g');
					var reHeader = new RegExp('header', 'g');
					stepStr = stepStr.replace(reStep, 2);
					stepsNode.prepend($(stepStr));
					stepsNode.children().eq(1).find('.badge-info').text(2);
				} else {
					$('.badge-info').text('');
					$('.wizardLibLi').css('padding-left', '20px');
					//if (!self.mappedDiagramBO && self.selectedShapeType.name === 'Activity') {
					//    self.showValueProposition(false);
					//}
					if (self.selectedShapeType.name === 'Activity' && diagramBO.$parent.$type === 'vdml:ValueStream') {
						self.isValueStreamMap = true;
					} else if (self.competenceMap()) {
						self.showValueProposition(false);
						$('.dynamicHeader').text(self.selectedShapeType.name + ' Details');
					} else if (self.selectedShapeType.name === 'Value Stream') {
						$('#bmActivityDiv' + self.encodeId).hide();
						$('.dynamicHeader').text(self.labels['ValueStreamDetails']());
					}
				}
				viewModel.modelElement.modal('toggle');
				viewModel.$wizard = $('#NewWizard').wizard(cond);
				viewModel.handleWizardButtons();
				viewModel.afterRenderView();
			//});
		};

		this.afterRenderView = function (activityModel) { 
			$('#deleteAct' + self.encodeId).hide();
			$('#deleteComp' + self.encodeId).hide();
			$('#deleteValueElement' + self.encodeId).hide();
			var wizardName = "wizardModalContent";
			if (self.shapeTypeFromWizard()) {
				wizardName = "wizardModalContent1";
			}
			$('#' + wizardName).resizable();
			$('#' + wizardName).draggable({
				containment: "#content"
			});
			
			var currentPlan = DataManager.getDataManager().get('currentPlan');
			currentPlan.loadValueLibraries(function (valLibraries) {
				if (valLibraries) {
					self.valueLibs = valLibraries;
					self.showValueElement(true);
				}
			})
			$('#' + wizardName).on("resize", function (event, ui) {// setting min height of the template, to prevent buttons and content from overflowing
				ui.element.css("minHeight", $("#wizardTemplateContent").actual('outerHeight'));
			});

			var activityExtended = Backbone.Typeahead.extend({
				template: '<input type="text" id="selectActivity' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%; position: inherit; overflow: scroll;" class="dropdown-menu typeahead-scrollbar"></ul>',
			});
			self.activityTypeahead = new activityExtended({ collection: this.activitiesColl });
			self.activityTypeahead.setElement('#bmActivity' + self.encodeId).render();

			self.activityTypeahead.on('selected', function (selectedActivity) {
				if (self.matchField) {
					self.matchField.hide();
				}
				self.selectedActivity = selectedActivity

				self.fillActivityPerformerRole(); //to disable performer role
				if (self.selectedBMVP()) {
					var bm = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({ id: self.selectedBMVP().id });
					var actFound = _.filter(bm.get('bmActivity').models, function (bmAct) { if (bmAct.get('activity').id == selectedActivity.id) { return bmAct } });
					$('#showMappedInfoActivity' + self.encodeId).show();
					if (actFound && actFound.length > 0 && !self.isValueStreamMap) {
						if (self.selectedShapeType.name !== 'Value') {
							self.showValueProposition(false);
						}
					} else {
						//$('#showMappedInfoActivity' + self.encodeId).hide();
						self.showValueProposition(true);
					}
				}
				self.enableCompleteForActivity();
			});
			if(self.BMsColl.length == 0 && diagramBO.mcid && !self.selectedBMVP()){
				$("#selectActivity" + self.encodeId).val(diagramBO.name);
				self.usedCapabilityName(diagramBO.name);self.usedCapability(diagramBO.mcid);
				$("#mapActCapability" + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
				$('#deleteAct' + self.encodeId).show();
			}
			$("#selectActivity" + self.encodeId).keyup(function (view) {
				var currentValue = view.currentTarget.value.trim().toLowerCase();
				self.matchField = $(view.currentTarget).closest('.row').find('.match');
				if (view.currentTarget.value.trim() !== "") {
					self.matchField.show();
				} else {
					self.matchField.hide();
				}
				$('#selectPerformingRole' + self.encodeId).removeAttr('disabled');
				if (!self.competenceMap()) {
					$('#selectPerformer' + self.encodeId).removeAttr('disabled');
				}
				var matchFound = _.filter(self.activitiesColl.models, function (obj) { return obj.get('name').toLowerCase() === currentValue; });
				if (matchFound && matchFound.length) {
					self.selectedActivity = matchFound[0];
					self.fillActivityPerformerRole(); //to disable performer role
					self.matchField[0].innerHTML = "Match";
					if (self.selectedBMVP() && self.selectedActivity) {
						var bm = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({ id: self.selectedBMVP().id });
						var actFound = _.filter(bm.get('bmActivity').models, function (bmAct) { if (bmAct.get('activity').id == self.selectedActivity.id) { return bmAct } });
						if (actFound && actFound.length > 0 && self.selectedShapeType.name !== 'Value' && !self.isValueStreamMap) {
							self.showValueProposition(false);
						} else {
							self.showValueProposition(true);
						}
					}
				} else {
					self.selectedActivity = null;
					self.matchField[0].innerHTML = "New";
					if (currentValue != "") {
						self.showValueProposition(true);
					}
					$('#showMappedInfoActivity' + self.encodeId).hide();
				}
				if (diagramBO.$type == 'vdml:Activity') {
					if (diagramBO.get('mcid')) {
						self.usedCapability(diagramBO.get('mcid'));
						var capModel = self.getCapabilityElementModel(diagramBO.get('mcid'));
						if (capModel) {
							self.usedCapabilityName(capModel.get('name'));
						}
						//$('#selectCapability' + self.encodeId).attr('disabled', 'disabled');
						$("#capabilityLibActDiv" + self.encodeId).show();
					} else {
						//$('#selectCapability' + self.encodeId).removeAttr('disabled');
						if (!self.showCapabilityElement()) {
							$("#capabilityLibActDiv" + self.encodeId).hide();
						}
					}
				}
				self.enableCompleteForActivity();
			});
			$('#selectActivity' + self.encodeId).change(function (view) {
				if (self.matchField) {
					self.matchField.hide();
				}
			});
			$('#selectActivity' + self.encodeId).dblclick(function (view) {
				$('#selectActivity' + self.encodeId).trigger('keyup');
				$('#selectActivity' + self.encodeId).focus();
			});

			var vpExtended = Backbone.Typeahead.extend({
				template: '<input type="text" id="selectVP' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%; position: inherit; overflow: scroll;" class="dropdown-menu typeahead-scrollbar"></ul>',
			});
			self.vpTypeahead = new vpExtended({ collection: this.VPsColl });
			self.vpTypeahead.setElement('#vp' + self.encodeId).render();

			self.vpTypeahead.on('selected', function (selectedVP) {
				if (self.matchField) {
					self.matchField.hide();
				}
				self.selectedVP = selectedVP;
				self.onChangeSelectedVP();
				self.showValuePropositionRoles(false);
				self.enableCompleteForActivity();
			});
			$("#selectVP" + self.encodeId).keyup(function (view) {
				var currentValue = view.currentTarget.value.trim().toLowerCase();
				self.matchField = $(view.currentTarget).closest('.row').find('.match');
				if (view.currentTarget.value.trim() !== "") {
					self.matchField.show();
				} else {
					self.matchField.hide();
				}
				var matchFound = _.filter(self.VPsColl.models, function (obj) { return obj.get('shortName').toLowerCase() === currentValue; });
				if (matchFound && matchFound.length) {
					if (matchFound.length > 1 && self.selectedBMVP()) {
						var BmModel = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.selectedBMVP().get('id') });
						if (BmModel) {
							var bmVPs = BmModel.get('bmValueProposition').models;
							for (var i = 0; i < bmVPs.length; i++) {
								if (bmVPs[i].get('valueProposition').get('name') && bmVPs[i].get('valueProposition').get('name').toLowerCase() == currentValue) {
									self.selectedVP = bmVPs[i].get('valueProposition');
									self.matchField[0].innerHTML = "Match";
									self.showValuePropositionRoles(false);
									break;
								}
							}
						}
					}
					else {
						self.selectedVP = matchFound[0];
						self.matchField[0].innerHTML = "Match";
						self.showValuePropositionRoles(false);
					}
				} else {
					self.selectedVP = null;
					self.matchField[0].innerHTML = "New";
					self.showValuePropositionRoles(true);
				}
				self.onChangeSelectedVP();
				self.enableCompleteForActivity();
			});
			$('#selectVP' + self.encodeId).change(function (view) {
				if (self.matchField) {
					self.matchField.hide();
				}
				if (!self.selectedVP) {
					self.showValuePropositionRoles(true);
				}
			});
			$('#selectVP' + self.encodeId).dblclick(function (view) {
				$('#selectVP' + self.encodeId).trigger('keyup');
				$('#selectBVP' + self.encodeId).focus();
			});
			var competenceExtended = Backbone.Typeahead.extend({
				template: '<input type="text" id="selectCompetence' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%; position: inherit; overflow: scroll;" class="dropdown-menu typeahead-scrollbar"></ul>',
			});
			self.competenceTypeahead = new competenceExtended({ collection: this.competenciesColl });
			self.competenceTypeahead.setElement('#bmComptence' + self.encodeId).render();

			self.competenceTypeahead.on('selected', function (selectedCompetence) {
				self.selectedCompetence = selectedCompetence;
				$('#showMappedInfoCompetency' + self.encodeId).show();
				var type = selectedCompetence.get('type');
				var typeObj = _.find(self.resourceTypes(), function (e) { return e.type === type; });
				self.selectedResourceType(typeObj);
				$('#selectCompetencyType' + self.encodeId).attr('disabled', 'disabled');
				if (selectedCompetence.get('capability')) {
					self.usedCapabilityCom(selectedCompetence.get('capability').id);
					var capModel = self.getCapabilityElementModel(selectedCompetence.get('capability').id);
					if (capModel) {
						self.usedCapabilityComName(capModel.get('name'));
						$("#mapCompCapability" + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
						$("#deleteComp" + self.encodeId).show();
					}
					$("#capabilityLibCompDiv" + self.encodeId).show();
				} else if (diagramBO.get('mcid')) {
					self.usedCapabilityCom(diagramBO.get('mcid'));
					var capModel = self.getCapabilityElementModel(diagramBO.get('mcid'));
					if (capModel) {
						self.usedCapabilityName(capModel.get('name'));
					}
					$("#capabilityLibCompDiv" + self.encodeId).show();
					//$('#selectCapabilityCom' + self.encodeId).attr('disabled', 'disabled');
				} else {
					if (!self.showCapabilityElement()) {
						$("#capabilityLibCompDiv" + self.encodeId).hide();
					}
					//$('#selectCapabilityCom' + self.encodeId).removeAttr('disabled');
				}
				if (self.selectedBMVP() && self.selectedCompetence) {
					var bm = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({ id: self.selectedBMVP().id });
					var compFound = _.filter(bm.get('bmCompetence').models, function (bmCom) { if (bmCom.get('competence').id == self.selectedCompetence.id) { return bmCom } });
					if (compFound && compFound.length > 0 && self.selectedShapeType.name !== 'Value' && diagramBO.$parent.$type !== "vdml:CapabilityContainer") {
						self.showActivityDiv(false);
					} else {
						self.showActivityDiv(true);
					}
				}
				self.enableCompleteForCompetency();
			});
			if(self.BMsColl.length == 0 && diagramBO.mcid && self.selectedShapeType.name == "Competency" && !self.selectedBMVP()){
				$("#selectCompetence" +self.encodeId).val(diagramBO.name);
				var typeObj = _.find(self.resourceTypes(), function (e) { return e.type === "vdml_CapabilityOffer"; });
				self.selectedResourceType(typeObj);$('#selectCompetencyType' + self.encodeId).attr('disabled', 'disabled');
				$("#mapCompCapability" + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
				$('#deleteComp' + self.encodeId).show();
				self.usedCapabilityComName(diagramBO.name);self.usedCapabilityCom(diagramBO.mcid);
			}
			$("#selectCompetence" + self.encodeId).keyup(function (view) {
				var currentValue = view.currentTarget.value.trim().toLowerCase();
				self.matchField = $(view.currentTarget).closest('.row').find('.match');
				if (view.currentTarget.value.trim() !== "") {
					self.matchField.show();
				} else {
					self.matchField.hide();
				}
				var matchFound = _.filter(self.competenciesColl.models, function (obj) { return obj.get('name').toLowerCase() === currentValue; });
				if (matchFound && matchFound.length) {
					self.selectedCompetence = matchFound[0];
					$('#showMappedInfoCompetency' + self.encodeId).show();
					self.matchField[0].innerHTML = "Match";
					if (self.selectedBMVP() && self.selectedCompetence) {
						var bm = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({ id: self.selectedBMVP().id });
						var compFound = _.filter(bm.get('bmCompetence').models, function (bmCom) { if (bmCom.get('competence').id == self.selectedCompetence.id) { return bmCom } });
						if (compFound && compFound.length > 0 && self.selectedShapeType.name !== 'Value' && diagramBO.$parent.$type !== "vdml:CapabilityContainer") {
							self.showActivityDiv(false);
						} else {
							self.showActivityDiv(true);
						}
					}
					var type = self.selectedCompetence.get('type');
					var typeObj = _.find(self.resourceTypes(), function (e) { return e.type === type; });
					self.selectedResourceType(typeObj);
					$('#selectCompetencyType' + self.encodeId).attr('disabled', 'disabled');
				} else {
					self.selectedCompetence = null;
					self.matchField[0].innerHTML = "New";
					self.showActivityDiv(true);
					$('#showMappedInfoCompetency' + self.encodeId).hide();
					if (diagramBO.get('mcid')) {
						var typeObj = _.find(self.resourceTypes(), function (e) { return e.type === 'vdml_CapabilityOffer'; });
						self.selectedResourceType(typeObj);
						$('#selectCompetencyType' + self.encodeId).attr('disabled', 'disabled');
					} else {
						$('#selectCompetencyType' + self.encodeId).removeAttr('disabled');
					}
				}
				if (diagramBO.get('mcid')) {
					self.usedCapabilityCom(diagramBO.get('mcid'));
					var capModel = self.getCapabilityElementModel(diagramBO.get('mcid'));
					if (capModel) {
						self.usedCapabilityComName(capModel.get('name'));
					}
					$("#capabilityLibCompDiv" + self.encodeId).show();
					//$('#selectCapabilityCom' + self.encodeId).attr('disabled', 'disabled');
				} else {
					if (!self.showCapabilityElement()) {
						$("#capabilityLibActDiv" + self.encodeId).hide();
					}
					//$('#selectCapabilityCom' + self.encodeId).removeAttr('disabled');
				}
				self.enableCompleteForCompetency();
			});
			$('#selectCompetence' + self.encodeId).change(function (view) {
				if (self.matchField) {
					self.matchField.hide();
				}
			});
			$('#selectCompetence' + self.encodeId).dblclick(function (view) {
				$('#selectCompetence' + self.encodeId).trigger('keyup');
				$('#selectCompetence' + self.encodeId).focus();
			});

			var valueExtended = Backbone.Typeahead.extend({
				template: '<input type="text" id="selectValue' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%; position: inherit; overflow: scroll;" class="dropdown-menu typeahead-scrollbar"></ul>',
			});
			self.valueTypeahead = new valueExtended({ collection: this.valuesColl });
			self.valueTypeahead.setElement('#value' + self.encodeId).render();

			self.valueTypeahead.on('selected', function (selectedValue) {
				self.selectedValue = selectedValue;
				self.onSelectedShapeTypeChange();
			});
			$('#selectValue' + self.encodeId).dblclick(function (view) {
				$('#selectValue' + self.encodeId).trigger('keyup');
				$('#selectValue' + self.encodeId).focus();
			});
			$("#selectValue" + self.encodeId).keyup(function (view) {
				var currentValue = view.currentTarget.value.trim().toLowerCase();
				self.matchField = $(view.currentTarget).closest('.row').find('.match');
				if (view.currentTarget.value.trim() !== "") {
					self.matchField.show();
				} else {
					self.matchField.hide();
				}
				var matchFound = _.filter(self.valuesColl.models, function (obj) { return obj.get('shortName').toLowerCase() === currentValue; });
				if (matchFound && matchFound.length) {
					self.selectedValue = matchFound[0];
					self.matchField[0].innerHTML = "Match";
				} else {
					self.selectedValue = null;
					self.matchField[0].innerHTML = "New";
				}
				self.onSelectedShapeTypeChange();
				self.enableCompleteForValue();
			});
			$('#selectValue' + self.encodeId).change(function (view) {
				if (self.matchField) {
					self.matchField.hide();
				}
			});
			window.utils.stopSpinner('createSpinner');
			//for first page
			self.fillPackagesVP();
			self.loadVPs();
			if (self.competenceMap()) {
				self.enableCompleteForCompetency();
			} else if (self.selectedShapeType.name === 'Value') {
				if (self.mappedDiagramBO) {
					$('.valueType').attr('disabled', 'disabled');
				}
				self.fetchValuesFromAlts();
				self.enableCompleteForValue();
			} else {
				self.enableCompleteForActivity();
			}
			if (self.selectedResourceType().name != 'Capability' && self.showCapabilityElement()) {
				$("#capabilityLibCompDiv" + self.encodeId).hide();
			}
			var autoCompAttr = 'new-password';
			if(window.browser == "chrome"){
				autoCompAttr = 'off';
			}
			$('input[type="text"]').attr('autocomplete',autoCompAttr);
			$('input[type="text"]').bind("paste", function(e){
				var validTxt = window.utils.pasteHandle(e);
				if(validTxt !== null){
					e.stopPropagation();
					e.preventDefault();
					$(e.target).val(window.utils.pasteContent(e,validTxt));
					return false;
				}
			});
		};           

		this.fetchValuesFromAlts = function () {
			self.valuesColl.reset();
			self.valueTypeahead && self.valueTypeahead.rerender([]);
			//var altIds = [];
			var bmArray = [];
			var alt = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: DataManager.getDataManager().get('viewAlternative') });
			var packBBObj, phaseDesignPart = alt.get('phaseDesignPart');
			for (var i = 0; i < phaseDesignPart.length; i++) {
				if (phaseDesignPart.at(i).get('beepReference').indexOf("Common") == -1 && phaseDesignPart.at(i).get('beepType') !== 'report_BeepReport' && phaseDesignPart.at(i).get('beepType') !== 'ecomap_EcoMap' && phaseDesignPart.at(i).get('beepType') !== 'dashboard_Analytics') {
					packBBObj = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: phaseDesignPart.at(i).get('beepReference') });
					var bms = packBBObj.get('businessModel').models;
					_.each(bms, function (bm) {
						bmArray.push(bm.id);
					});
				}
			}
			self.fillValues(bmArray);// to fill all values initially
		};

		this.selectedValueType.subscribe(function (val) {
			self.selectedValue = null;
			$('#selectValue' + self.encodeId).val('');
			$('.dynamicHeader').text(val + ' Details');
			self.onSelectedShapeTypeChange();
			self.fetchValuesFromAlts();
			// $('.btn-next').attr('disabled', 'disabled')
			if (self.selectedValueTypeChangeCount) {
				self.selectedValueTypeChange = true;
			}
		});

		this.selectedValueCreation.subscribe(function (val) {
			if (val == "New") {
				$('#showMappedInfoValue' + self.encodeId).hide();
				if (self.matchField) {
					self.matchField.hide();
				}
				self.selectedValue = null;
				self.setWizardNextButton();
			} else {

			}
		});

		this.selectedResourceType.subscribe(function (val) {
			if ((val && val.name == "Capability") && self.showCapabilityElement()) {
				$("#capabilityLibCompDiv" + self.encodeId).show();
			} else {
				$("#capabilityLibCompDiv" + self.encodeId).hide();
			}
		});
		this.fetchPlanDirectforAlt = function (plan, alt) {
			var values = [];
			var planCriterionSet = plan.getOrCreatePlanCriterionSet();
			var planComponents = planCriterionSet.get('component').models;
			for (var i = 0; i < planComponents.length; i++) {
				var value = planComponents[i];
				/*if (self.objectiveMapping()) {
					var mCharacteristic = value.get('valueMeasurement');
					if(mCharacteristic && mCharacteristic.get('measurement').length > 0 && self.existingValList.indexOf(window.utils.getSuffix(value.get('id'))) == -1){
						var obj = { id: value.get('id'), name: value.get('name'), displayName: value.get('name'), path: getFullPathOfValue(value), type: 'Plan', planName: plan.get('name') };
						values.push(obj);
					}
				}else {*/
					var obj = { id: value.get('id'), name: value.get('name'), displayName: value.get('name'), path: getFullPathOfValue(value), type: 'Plan', planName: plan.get('name') };
					values.push(obj);
				//}
			}
			return values;
		};
		this.fillBMValues = function (bmArray, alt, valueType) {
			var bm, values = [];
			_.each(bmArray, function (bmId) {
				bm = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({ id: bmId });
				var bmValues = [];
				bm.getValues(bmValues, true);
				for (var i = 0; i < bmValues.length; i++) {
					if (valueType && getSourceType(bmValues[i]) !== valueType) {
						continue;
					}
					if (_.filter(values, function (val) { if (val.id === bmValues[i].get('id')) { return val } }).length == 0) {
						/*if (self.objectiveMapping()) {
							var mCharacteristic = bmValues[i].get('valueMeasurement');
							if(mCharacteristic && mCharacteristic.get('measurement').length > 0 && self.existingValList.indexOf(window.utils.getSuffix(bmValues[i].get('id'))) == -1){
								var obj = { id: bmValues[i].get('id'), name: bmValues[i].get('name'), path: getFullPathOfValue(bmValues[i]), displayName: bmValues[i].get('name'), type: 'BMValue' };
								values.push(obj);
							}
						}else {*/
							var obj = { id: bmValues[i].get('id'), name: bmValues[i].get('name'), path: getFullPathOfValue(bmValues[i]), displayName: bmValues[i].get('name'), type: 'BMValue' };
							values.push(obj);
						//}
						
					}
				}
			});
			return values;
		};
		this.fillValues = function (bmArray) {
			var values = [];
			var valueType = self.selectedValueType();
			var alt, plan = DataManager.getDataManager().get('currentPlan');
			//for (var i = 0; i < altArray.length; i++) {
			alt = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: DataManager.getDataManager().get('viewAlternative') });
			if (valueType === "Plan") {
				values = values.concat(self.fetchPlanDirectforAlt(plan, alt));
			} else {
				values = values.concat(self.fillBMValues(bmArray, alt, valueType));
			}
			// }
			//var uniqueValues = [];
			//_.map(_.groupBy(values, 'suffixId'), function (grouped) {
			//    uniqueValues.push(grouped[0]);
			//});

			function addNamePath(grouped) {
				var index;
				_.each(grouped, function (val) {
					val.nameWithoutUnit = val.name;
					index = val.path ? val.path.length : 0;
				});
				if (grouped.length > 1) {
					var filteredArray = grouped;
					_.each(filteredArray, function (element) {
						element.nameWithoutUnit = element.nameWithoutUnit + ' [';
						element.namePath = "";
					});
					var duplicates = true, y = 0;
					while (duplicates) {
						_.each(filteredArray, function (element) {
							if (y > 0 && y < 4) {
								if (element.nameWithoutUnit === element.name + ' [') {
									element.nameWithoutUnit = element.nameWithoutUnit + element.path[y].name;
									element.namePath = element.namePath + element.path[y].name;
								}
								else if (element.path[y].name.indexOf('Package') < 0) {
									element.nameWithoutUnit = element.nameWithoutUnit + ' /' + element.path[y].name;
									element.namePath = element.namePath + ' /' + element.path[y].name;
								}
							}
							else if (y === 4) {
								element.nameWithoutUnit = element.nameWithoutUnit + ' /' + element.altName;
								element.namePath = element.namePath + ' /' + element.altName;
							}
							else if (y === 5) {
								element.nameWithoutUnit = element.nameWithoutUnit + ' /' + element.phaseName;
								element.namePath = element.namePath + ' /' + element.phaseName;
							}
							else {
								if (element.path[y] && element.path[y].name.indexOf('port') < 0) {
									if (element.type == 'Plan') {
										element.nameWithoutUnit = element.nameWithoutUnit + element.planName;
										element.namePath = element.namePath + element.planName;
									}
									else {
										element.nameWithoutUnit = element.nameWithoutUnit + element.path[y].name;
										element.namePath = element.namePath + element.path[y].name;
									}
								}
							}
						});
						var z = _.groupBy(filteredArray, function (a) {
							return a.nameWithoutUnit;
						});
						if (Object.keys(z).length > 1) {
							filteredArray = [];
							_.each(z, function (v) {
								if (v.length > 1) {
									_.each(v, function (vals) {
										filteredArray.push(vals);
									});
								}
								else {
									if (v[0].nameWithoutUnit === v[0].name + ' [') {
										v[0].nameWithoutUnit = v[0].nameWithoutUnit + v[0].path[1].name + ']';
										v[0].displayName = v[0].nameWithoutUnit;
									}
									else {
										v[0].nameWithoutUnit = v[0].nameWithoutUnit + ']';
										v[0].displayName = v[0].nameWithoutUnit;
									}
								}
							});
						}
						if (filteredArray.length === 0) {
							duplicates = false;
						}
						y++;
					}
				}
			}
			values.sort(sortByName);
			_.map(_.groupBy(values, function (value) { return value.name; }), function (grouped) {
				addNamePath(grouped);
				_.each(grouped, function (group) {
					self.valuesColl.add({ id: group.id, name: group.displayName, type: group.type, shortName: group.name });
				});
			});
			if (diagramBO.get('name')) {
				$('#selectValue' + self.encodeId).val(diagramBO.get('name'));
				if (self.mappedDiagramBO) {
					$('#selectValue' + self.encodeId).attr('disabled', 'disabled');
					$('#valueCreationNew' + self.encodeId).attr("disabled", true);
				}
				var valueFound = _.filter(self.valuesColl.models, function (obj) { return obj.get('shortName').toLowerCase() === diagramBO.get('name').toLowerCase() });
				if (valueFound && valueFound.length) {
					self.selectedValue = valueFound[0];
					var mappedElement = Backbone.Relational.store.getObjectByName("vdml.ValuePropositionComponent").find({ id: self.selectedValue.id });
					if (!mappedElement) {
						mappedElement = Backbone.Relational.store.getObjectByName("vdml.ValueAdd").find({ id: self.selectedValue.id });
					}
					if (mappedElement && mappedElement.get('valueDefinition')) {
						self.usedValue(mappedElement.get('valueDefinition').id);
						self.usedValueElementName(mappedElement.get('valueDefinition').get('name'));
					}
					self.onSelectedShapeTypeChange();
				}
				self.enableCompleteForValue();
			}

		};
		this.getDocumentWithNamePath = function (namePath, type, currentAlt, isValue, pack, callback) {
			var alts = currentAlt.getMasterPrimaryAlternativesOfPackage(currentAlt, pack);
			var rdfModels = [];
			async.each(alts, function (alt, altWSLoaded) {
				DataManager.getDataManager().getModelIdInitializedWSData((typeof alt === 'string') ? alt : alt.id, function (wsData) {
					if (wsData) {
						rdfModels.push(wsData.get('rdfModel'));
					}
					altWSLoaded();
				});
			}, function () {
				alts.length = 0;
				if (rdfModels.length > 0) {
					var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
					if (altRDFModel) {
						/*if(isValue) {
							rdfModels.push(altRDFModel);
						}*/
						altRDFModel.getDocumentWithNamePath(namePath, type, rdfModels, isValue, callback);
					} else {
						callback();
					}
				} else {
					callback();
				}

			});
		};
		this.createRevisionBasedOnContext = function (pack, createRevision, reviCallback) {
			if (window.checkContextForRevision() || createRevision) {
				window.utils.startSpinner('revisionSpinner', "Creating a copy...");
				function fetchBmModel(newVdmPackage) {
					if (newVdmPackage) {
						DataManager.getDataManager().releaseSaveLock();
						DataManager.getDataManager().set('isActive', false);
						reviCallback(newVdmPackage);
						/* DataManager.getDataManager().saveData({
							success: function () {
								reviCallback(newVdmPackage);
							},
							error: function () {
								reviCallback(newVdmPackage);
							},
							persist: true
						});*/
					}
				}
				window.setTimeout(function () {
					DataManager.getDataManager().acquireSaveLock(function () {
						pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
					});
				}, 100);
			}
			else {
				reviCallback(pack);
			}
		};

		this.checkForMapRevision = function (revCallback) {
			//if (!self.mappedDiagramBO){
			var mapPackage = self.parentView.model.getNestedParent();
			self.createRevisionBasedOnContext(mapPackage, false, function (newMapPackage) {
				var altId = DataManager.getDataManager().getRepositoryId(newMapPackage.id);
				var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
				if (altId != oldAltId) {
					var modelId = window.utils.getSuffix(self.parentView.model.id);
					var map = newMapPackage.get('diagrams').findWhere({ 'id': altId + modelId });
					self.parentView.model = map;
					self.model = map;
					window.utils.stopSpinner('revisionSpinner');
					revCallback(map, newMapPackage);
				} else {
					revCallback(null);
				}
			});
			/*}  else {
				revCallback(null);
			} */
		};

		this.fillActivityPerformerRole = function () { 
			if (!self.selectedActivity || !self.selectedBMVP()) {
				return;
			}
			var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.selectedBMVP().id });
			var activityModel = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: self.selectedActivity.get('id') });
			var performingRole = activityModel.get('performingRole');
			if (performingRole) {
				if (!self.competenceMap()) {
					if (performingRole.get('roleAssignment').length > 0) {
						var participant = performingRole.get('roleAssignment').at(0).get('participant');
						var participantToDisplay = $.grep(self.activityPerformers(), function (participant1) { return participant1.id === participant.id; });
						if (participantToDisplay && participantToDisplay.length) {
							self.activityPerformer(participantToDisplay[0]);
						}
					}
				}
				var roleToDisplay = $.grep(self.activityPerformerRoles(), function (role1) { return role1.id === performingRole.id; });
				if (roleToDisplay && roleToDisplay.length) {
					self.activityPerformerRole(roleToDisplay[0]);
					self.rolesLoadedForCompetency = true;
				}

				$('#selectPerformer' + self.encodeId).attr('disabled', 'disabled');
				$('#selectPerformingRole' + self.encodeId).attr('disabled', 'disabled');                    
			}
			if (activityModel.get('capabilityRequirement')) {
				self.usedCapability(activityModel.get('capabilityRequirement').id);
				var capModel = activityModel.get('capabilityRequirement');
				if (capModel) {
					self.usedCapabilityName(capModel.get('name'));
					$("#mapActCapability" + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
					$('#deleteAct' + self.encodeId).show();
				}
				$("#capabilityLibActDiv" + self.encodeId).show();
				//$('#selectCapability' + self.encodeId).attr('disabled', 'disabled');
			}
		}

		this.fillActivityPerformerForCompetencyMapping = function () {
			if (!self.selectedBMVP()) {
				return;
			}
			var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.selectedBMVP().id });
			var providerToDisplay = $.grep(self.activityPerformers(), function (participantProvider1) { return participantProvider1.id === bm.get('business').id; });
			if (providerToDisplay && providerToDisplay.length) {
				self.activityPerformer(providerToDisplay[0]);
				if (self.competenceMap()) {
					self.fillActivityPerformerRole();
				}
			}
			$('#selectPerformer' + self.encodeId).attr('disabled', 'disabled');
		}
		this.checkIfVDMPackageIsDependentOfEcoMap = function (viewAltId, revisionMapPackage, pack) {
			var newPackId = viewAltId + window.utils.getSuffix(pack.id);
			var depPacksList = [];
			var ecoDepPacks = revisionMapPackage.get('dependentPackage');
			function getDepPacks(depPacks) {
				depPacks.each(function (depPack) {
					if (_.find(depPacksList, function (dpack) { return dpack === depPack; })) {
						return;
					}
					depPacksList.push(depPack);
					var depPackDependencies = depPack.get('dependentPackage');
					for (var j = 0; j < depPackDependencies.length; j++) {
						if (depPackDependencies.at(j).get('type') !== "ecomap_EcoMap" && !_.find(depPacksList, function (dpack) { return dpack === depPackDependencies.at(j); })) {
							depPacksList.push(depPackDependencies.at(j));
							getDepPacks(depPackDependencies.at(j).get('dependentPackage'));
						}
					}
				});
			}
			getDepPacks(ecoDepPacks);
			depPacksList = _.uniq(depPacksList);
			var packageRevised = _.find(depPacksList, function (dpack) { return dpack.id === newPackId; });
			//var packageRevised = revisionEcoMapPackage.get('dependentPackage').findWhere({ id: newPackId});
			return packageRevised;
		};
		this.checkAddMappingDuplicatesForActivity = function (callback) {
			var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': self.selectedBMVP().id });
			var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: self.selectedVP.get('id') });
			var vpExistsInBM = bm.get('bmValueProposition').find({ 'valueProposition': vp });
			var activityModel = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: self.selectedActivity.get('id') });
			var activityExistsInBM = bm.get('bmActivity').find({ 'activity': activityModel });
			if (!activityExistsInBM || !vpExistsInBM) {
				callback();
				return;
			}
			var vdmlPackage = vp.getNestedParent();
			var valueStreams = vdmlPackage.get('valueStream');
			var selectedValueStreamModel = null;
			for (var i = 0; i < valueStreams.length; i++) {
				if (valueStreams.at(i).get('persuedProposition') && valueStreams.at(i).get('persuedProposition').get('id') === vp.id) {
					selectedValueStreamModel = valueStreams.at(i);
					if (selectedValueStreamModel.get('valueStreamActivity').findWhere({ id: self.selectedActivity.id })) {
						var checkCapability = selectedValueStreamModel.get('valueStreamActivity').findWhere({ id: self.selectedActivity.id });
						if((checkCapability.get('capabilityRequirement') && !self.usedCapabilityName()) || (!checkCapability.get('capabilityRequirement') && self.usedCapabilityName())){
							callback();
						}else if(checkCapability.get('capabilityRequirement') && self.usedCapabilityName() && checkCapability.get('capabilityRequirement').get('name') != self.usedCapabilityName()){
							callback();
						}
						else{
							bootbox.alert("No new updates");
							return;
						}
					} else {
						callback();
					}
					break;
				}
			}
			if (!selectedValueStreamModel) {
				callback();
			}
		}
		this.checkAddMappingDuplicatesForCompetence = function (callback) {
			var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': self.selectedBMVP().id });
			var compModel = Backbone.Relational.store.getObjectByName("vdml.BusinessItem").find({ id: self.selectedCompetence.id });
			if (!compModel) {
				compModel = Backbone.Relational.store.getObjectByName("vdml.CapabilityOffer").find({ id: self.selectedCompetence.id });
			}
			if (!compModel) {
				callback();
			}
			if (compModel) {
				var activityModel = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: self.selectedActivity.get('id') });
				var activityExistsInBM = bm.get('bmActivity').find({ 'activity': activityModel });
				var competenceExistsInBM = bm.get('bmCompetence').findWhere({ competence: compModel });
				if (!competenceExistsInBM || !activityExistsInBM) {
					callback();
					return;
				}
				var capOff = activityModel.getActivityCompetences();
				if(compModel.get('type') == "vdml_CapabilityOffer" && capOff.indexOf(compModel) != -1 && activityExistsInBM && competenceExistsInBM){
					var capModel = self.getCapabilityElementModel(self.usedCapabilityCom());
					if(compModel.get('capability') == capModel){
						bootbox.alert("No new updates");
					}else {
						callback();
					}
				}else {
					if(compModel.get('type') == "vdml_BusinessItem" && activityExistsInBM && competenceExistsInBM){
						var biFlows = compModel.get('flow');
						var competencePresent = false;
						for (var k = 0; k < biFlows.length; k++) {
							var resourceColl = [];
							var resourceActivity = biFlows.at(k).get('recipient') ? biFlows.at(k).get('recipient').get('containedPortOwner') : '';
							if (activityModel == resourceActivity) {
								competencePresent = true;
								break;
							}
						}
						if(competencePresent) {
							bootbox.alert("No new updates");
						}else {
							callback();
						}
					}else {
						callback();
					}
				}
			}
			
		}
		this.checkAddMappingDuplicatesForValue = function (callback) {
			var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': self.selectedBMVP().id });
			var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: self.selectedVP.get('id') });
			var vpExistsInBM = bm.get('bmValueProposition').find({ 'valueProposition': vp });
			var activityModel = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: self.selectedActivity.get('id') });
			var activityExistsInBM = bm.get('bmActivity').find({ 'activity': activityModel });
			if (!vpExistsInBM || !activityExistsInBM) {
				callback();
				return;
			}
			//var vdmlPackage = vp.getNestedParent();
			var valueStreams = bm.get('bmValueStream');
			var selectedValueStreamModel = null;
			for (var i = 0; i < valueStreams.length; i++) {
				if (valueStreams.at(i).get('persuedProposition') && valueStreams.at(i).get('persuedProposition').get('id') === vp.id) {
					selectedValueStreamModel = valueStreams.at(i);
					if (selectedValueStreamModel.get('valueStreamActivity').findWhere({ id: self.selectedActivity.id }) && selectedValueStreamModel.get('activityValue').findWhere({ id: self.selectedValue.id })) {
						bootbox.alert("No new updates");
						return;
					} else {
						callback();
					}
					break;
				}
			}
			if (!selectedValueStreamModel) {
				callback();
			}
		}

		this.hideAndCallback = function (revisionMap, vdmlPackageRevision, diagramBO) {
			if (!revisionMap && !vdmlPackageRevision && !self.parentView.enableComplete() && self.mappedDiagramBO) {
				//Do Nothing
			} else {
				self.eventBus.fire('elements.changed', {
					elements: [options.shape]
				});
			}
			modelElement.modal("hide");
			if (self.parentView && !self.objectiveMapping()) {
				self.parentView.saveDiagram(null, null, null, revisionMap, vdmlPackageRevision, self.parentView.enableComplete());
			}
			if (self.callback) {
				self.callback(self.model, revisionMap, diagramBO);
			}
		};

		this.getCapabilityElementModel = function (id) {
			if (!id) {
				return null;
			}
			var capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityCategory').find({ id: id });
			if (!capModel) {
				capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityDefinition').find({ id: id });
			}
			return capModel;
		}
		this.mapActCapability = function () {
			var id = htmlEscape(window.guidGenerator());
			var currentPlan = DataManager.getDataManager().get('currentPlan');
			var options = {
				capabilityLibs: self.capabilityLibs, callback: function (capId, capName, capDesc, capParentId) {
					if (!capId) {
						return;
					}
					self.usedCapability(capId);
					self.usedCapabilityName(capName);
					$("#mapActCapability" + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
					$("#deleteAct" + self.encodeId).show();
				}
			};
			if (self.usedCapability()) {
				options.showCapabilityId = self.usedCapability();
			}
			window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "CapabilityExplorerJson", null, options);
		};
		this.mapValueElement = function () {
			var id = htmlEscape(window.guidGenerator());
				var options = {
					valueLibs: self.valueLibs,callback: function (valId, phases) {
						if (!valId) {
							return;
						}
						$('.btn-next').removeAttr('disabled');
						var valName = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: valId[0] });
						self.usedValue(valId[0]);
						self.usedValueElementName(valName ? valName.get('name') : "");
						$("#mapValueElement" + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
						$('#deleteValueElement' + self.encodeId).show();
						if ($('#selectValue' + self.encodeId).val() === "") {
							$('#selectValue' + self.encodeId).val(valName ? valName.get('name') : "")
						}
					}
				};
				if (self.usedValue()) {
					options.showValueElementId = self.usedValue();
				}
			options.showPhases = true;
			options.hideCheck = true;

				window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "ValueExplorerJson", null, options);
		};
		this.deleteValueElement = function () {
			self.usedValueElementName("");
			$("#mapValueElement" + self.encodeId).removeClass('glyphicon-pencil').addClass('glyphicon-plus-sign');
			$('#deleteValueElement' + self.encodeId).hide();
		}
		this.showMapValueElement = function () {
			var id = htmlEscape(window.guidGenerator());
			var options = {
				valueLibs: self.valueLibs, targetDetailsMode: true, callback: function (valId, valName) {
					if (!valId) {
						return;
					}
					
				}
			};
			if (self.usedValue()) {
				options.showValueElementId = self.usedValue();
			}
			options.showPhases = true;
			options.hideCheck = true;
			window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "ValueExplorerJson", null, options);
		};
		this.mapCompCapability = function () {
			var id = htmlEscape(window.guidGenerator());
			var currentPlan = DataManager.getDataManager().get('currentPlan');
			var options = {
				capabilityLibs: self.capabilityLibs, callback: function (capId, capName, capDesc, capParentId) {
					if (!capId) {
						return;
					}
					self.usedCapabilityCom(capId);
					self.usedCapabilityComName(capName);
					$("#mapCompCapability" + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
					$("#deleteComp" + self.encodeId).show();
				}
			};
			if (self.usedCapabilityCom()) {
				options.showCapabilityId = self.usedCapabilityCom();
			}
			window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "CapabilityExplorerJson", null, options);
		};
		this.deleteActCapability = function (view,event) {
			var cap = self.usedCapability();
			if(cap){
				self.usedCapability(null);
				self.usedCapabilityName(null);
				$('#deleteAct'+self.encodeId).hide();
				$("#mapActCapability" + self.encodeId).removeClass('glyphicon-pencil').addClass('glyphicon-plus-sign');
			}
		};
		this.deleteCompCapability = function (view,event) {
			var cap = self.usedCapabilityCom() ? self.usedCapabilityCom() : self.usedCapabilityComName();
			if(cap){
				self.usedCapabilityComName(null);
				self.usedCapabilityCom(null);
				$('#deleteComp'+self.encodeId).hide();
				$("#mapCompCapability" + self.encodeId).removeClass('glyphicon-pencil').addClass('glyphicon-plus-sign');
			}
		};
		this.hideWizard = function () {
			modelElement.modal("hide");
			window.cleanViewModel(this);
		};
		
		this.handleWizardButtons = function () {
			self.$wizard.on('finished.fu.wizard', function (e, data) {
				if (!self.selectedShapeType) {
					self.hideWizard();
					return;
				}
				var bm;
				if (self.selectedShapeType.name === "Activity") {
					if (self.mappedDiagramBO && self.selectedActivity && self.selectedVP) {
						self.checkAddMappingDuplicatesForActivity(activityMapAllowed);
					} else {
						activityMapAllowed();
					}
					function activityMapAllowed() {
						self.checkForMapRevision(function (revisionMap, revisionMapPackage) {
							self.mapActivity(diagramBO, function (vdmlPackageRevision) {
								self.hideAndCallback(revisionMap, vdmlPackageRevision);
							}, revisionMapPackage);
						});
					}
				} else if (self.selectedShapeType.name === "Competency" || self.selectedShapeType.name === "Capability") {
					if (self.mappedDiagramBO && self.selectedActivity && self.selectedCompetence) {
						self.checkAddMappingDuplicatesForCompetence(competenceMapAllowed);
					} else {
						competenceMapAllowed();
					}
					function competenceMapAllowed() {
						self.checkForMapRevision(function (revisionMap, revisionMapPackage) {
							self.mapCompetency(diagramBO, function (vdmlPackageRevision) {
								self.hideAndCallback(revisionMap, vdmlPackageRevision);
							}, revisionMapPackage);
						});
					}
				} else if (self.selectedShapeType.name === "Value") {                 
					if (self.mappedDiagramBO && self.selectedVP) {
						self.checkAddMappingDuplicatesForValue(valueMapAllowed);
					} else {
						valueMapAllowed();
					}
					function valueMapAllowed() {
						self.checkForMapRevision(function (revisionMap, revisionMapPackage) {
							self.mapValue(diagramBO, function (vdmlPackageRevision) {
								self.hideAndCallback(revisionMap, vdmlPackageRevision,diagramBO);
							}, revisionMapPackage);
						});
					}
				} else if (self.selectedShapeType.name === 'Value Stream') {
					if (self.mappedDiagramBO && self.selectedBMVP() && self.selectedVP) {
						var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ 'id': self.selectedBMVP().id });
						var vp = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: self.selectedVP.get('id') });
						if (bm.get('bmValueProposition').findWhere({ 'valueProposition': vp })) {
							bootbox.alert("No new updates");
							return;
						} else {
							mapVPAllowed();
						}
					} else {
						self.checkForMapRevision(function (revisionMap, revisionMapPackage) {
							self.mapValueStream(diagramBO, function (vdmlPackageRevision) {
								self.hideAndCallback(revisionMap, vdmlPackageRevision);
							}, revisionMapPackage);
						});
					}
				}
			});

			self.$wizard.on('clicked.fu.wizard.action', function (e, data) {
				if (!self.selectedShapeType) {
					self.hideWizard();
					return;
				}
				if (data.step === 1) {
					$('.btn-next').attr('disabled', 'disabled');
				}
				else {
					$('.btn-next').removeAttr('disabled');
				}
				var reloadData = true;
				if (self.selectedShapeType.name === "Value") {
					if (data.direction == "previous") {
						self.selectedValueTypeChangeCount = true;
					}
					if (data.direction == "next" && !self.selectedValueTypeChange && self.selectedValueTypeChangeCount) {
						reloadData = false;
					}
					if (self.selectedValueType() === 'Activity') {
						$('#bmActivityDiv' + self.encodeId).show();
					} else if (data.direction != "previous" && reloadData) {
						$('#bmActivityDiv' + self.encodeId).hide();
						if (self.selectedValueType() === 'My Proposition') {
							self.myProposition(true);
						} else {
							self.myProposition(false);
						}
					}
				}

				if (data.step === 1 && reloadData) {
					self.fillPackagesVP();
					self.loadVPs();
					self.enableCompleteForActivity();
					self.selectedValueTypeChange = false;
					self.selectedValueTypeChangeCount = false;
					if (!self.usedValueElementName()) {
						self.usedValue(null)
					}
				}
			});
			$('#showMappedInfoVP' + self.encodeId).click(function (ev) {
				var mappedElement = Backbone.Relational.store.getObjectByName("vdml.ValueProposition").find({ id: self.selectedVP.id });
				self.createDetailsTemplate(mappedElement);
			});
			$('#showMappedInfoBM' + self.encodeId).click(function (ev) {
				var mappedElement = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({ id: self.selectedMappingBM.id });
				self.createDetailsTemplate(mappedElement);
			});
			$('#showMappedInfoBMOther' + self.encodeId).click(function (ev) {
				var mappedElement = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({ id: self.selectedBMOther.id });
				self.createDetailsTemplate(mappedElement);
			});
			$('#showMappedInfoBMVP' + self.encodeId).click(function (ev) {
				var mappedElement = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({ id: self.selectedBMVP().id });
				self.createDetailsTemplate(mappedElement);
			});
			$('#showMappedInfoPN' + self.encodeId).click(function (ev) {
				var mappedElement = Backbone.Relational.store.getObjectByName("vdml.BusinessNetwork").find({ id: self.selectedPN.id });
				self.createDetailsTemplate(mappedElement);
			});
			$('#showMappedInfoPackageBM' + self.encodeId).click(function (ev) {
				var mappedElement = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: self.selectedPackageBM().id });
				self.createDetailsTemplate(mappedElement);
			});
			$('#showMappedInfoPackageOther' + self.encodeId).click(function (ev) {
				var mappedElement = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: self.selectedPackageOther().id });
				self.createDetailsTemplate(mappedElement);
			});
			$('#showMappedInfoPackageVP' + self.encodeId).click(function (ev) {
				var mappedElement = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: self.selectedPackageVP().id });
				self.createDetailsTemplate(mappedElement);
			});
			$('#showMappedInfoBusiness' + self.encodeId).click(function (ev) {
				var mappedElement = Backbone.Relational.store.getObjectByName('vdml.Participant').find({ id: self.selectedBusiness.id });
				self.createDetailsTemplate(mappedElement);
			});
			$('#showMappedInfoBusinessOther' + self.encodeId).click(function (ev) {
				var mappedElement = Backbone.Relational.store.getObjectByName('vdml.Participant').find({ id: self.selectedBusinessOther.id });
				self.createDetailsTemplate(mappedElement);
			});
			$('#showMappedInfoBusinessOther' + self.encodeId).click(function (ev) {
				var mappedElement = Backbone.Relational.store.getObjectByName('vdml.Participant').find({ id: self.selectedBusinessOther.id });
				self.createDetailsTemplate(mappedElement);
			});
			$('#showMappedInfoActivity' + self.encodeId).click(function (ev) {
				var mappedElement = Backbone.Relational.store.getObjectByName("vdml.Activity").find({ id: self.selectedActivity.id });
				self.createDetailsTemplate(mappedElement);
			});
			$('#showMappedInfoCompetency' + self.encodeId).click(function (ev) {
				var mappedElement = Backbone.Relational.store.getObjectByName("vdml.BusinessItem").find({ id: self.selectedCompetence.id });
				if (!mappedElement) {
					mappedElement = Backbone.Relational.store.getObjectByName("vdml.CapabilityOffer").find({ id: self.selectedCompetence.id });
				}
				self.createDetailsTemplate(mappedElement);
			});
			$('#showMappedInfoValue' + self.encodeId).click(function (ev) {
				var mappedElement = Backbone.Relational.store.getObjectByName("vdml.ValuePropositionComponent").find({ id: self.selectedValue.id });
				if (!mappedElement) {
					mappedElement = Backbone.Relational.store.getObjectByName("vdml.ValueAdd").find({ id: self.selectedValue.id });
				}
				self.createDetailsTemplate(mappedElement);
			})
			this.createDetailsTemplate = function (mappedElement) {
				var currentAltId = DataManager.getDataManager().get('viewAlternative');
				var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
				var currentAlt = docTypeObj.find({ id: currentAltId });
				var scenario = currentAlt.getMainScenario(mappedElement.getNestedParent());
				var id = window.utils.htmlEscape(window.guidGenerator());
				var options = {};
				options.context = scenario;
				options.mappedModal = mappedElement;
				window.getAndCreateModalDialog(self.model, id, EcoMapDiagramMixin, self.model, "TargetDetails", null, options);
			}
			this.enableCompleteForActivity = function () { 
				if (self.selectedPackageVP() && self.selectedBMVP()) {
					if (($('#selectActivity' + self.encodeId).val().trim() != '' && self.activityPerformer() && self.activityPerformerRole()) ||
						(self.selectedValueType() === "Value Proposition" || self.selectedValueType() === "My Proposition" || self.selectedShapeType.name === 'Value Stream')) {
						if ($('#selectVP' + self.encodeId).val().trim() != '' && self.vpProvider() && self.selectedProviderRole()) {
							if (!self.selectedReceiverRole() && self.vpReceiver()) {
								$('.btn-next').attr('disabled', 'disabled');
							} else if (self.selectedReceiverRole() && self.selectedProviderRole().id === self.selectedReceiverRole().id) {
								$('.btn-next').attr('disabled', 'disabled');
							} else {
								$('.btn-next').removeAttr('disabled');
							}
						} else {
							if (self.competenceMap() && self.selectedActivity && $('#selectCompetence' + self.encodeId).val().trim() != '' && !self.showValueProposition()) {
								$('.btn-next').removeAttr('disabled');
							} else if (!self.competenceMap() && self.selectedActivity && !self.showValueProposition()) {
								$('.btn-next').removeAttr('disabled');
							} else {
								$('.btn-next').attr('disabled', 'disabled');
							}
						}
					} else {
						$('.btn-next').attr('disabled', 'disabled');
					}
				} else {
					$('.btn-next').attr('disabled', 'disabled');
				}
			}
			function getDataStep() {
				if (!$('.steps')) {
					return;
				}
				var children = $('.steps').children();
				return children.filter('.active').attr('data-step');
			}
			this.enableCompleteForCompetency = function () {
				var dataStep = getDataStep();
				if (dataStep == 3) {
					if ($('#selectCompetence' + self.encodeId).val().trim() != '') {
						$('.btn-next').removeAttr('disabled');
					} else {
						$('.btn-next').attr('disabled', 'disabled');
					}
				} else {
					if (!self.showActivityDiv() && $('#selectCompetence' + self.encodeId).val().trim() != '' && self.selectedCompetence) {
						$('.btn-next').removeAttr('disabled');
					} else {
						self.enableCompleteForActivity();
					}
				}
			}
			this.enableCompleteForValue = function () {
				var dataStep = getDataStep();
				if (dataStep == 2) {
					if ($('#selectValue' + self.encodeId).val().trim() != '') {
						if (self.objectiveMapping()) {
							var matchField = $('#selectValue' + self.encodeId).closest('.row').find('.match');
							if(matchField && matchField[0] && matchField[0].innerHTML == "Match"){
								$('.btn-next').removeAttr('disabled');
							}
						}else {
							$('.btn-next').removeAttr('disabled');
						}
					} else {
						$('.btn-next').attr('disabled', 'disabled');
					}
				} else {
					self.enableCompleteForActivity();
				}
			}
			this.checkDuplicatesPath = function (models, element, path) {
				for (var i = 0; i < models.length; i++) {
					if ((element.get('name') === models[i].get('name') && (element.get('id') !== models[i].get('id')))) {
						for (var k = 0; k < element.getPackagePath().length - 1; k++) {
							path = path.concat(" [" + element.getPackagePath()[k].name);
							if (element.getPackagePath()[k + 2]) {
								path = path.concat("/");
							}
						}
						path = path.concat("]");
						break;
					}
				}
				return path;
			};

			//Subscribe functions
			this.selectedPackageVP.subscribe(function (pack) {
				self.selectedBMVP(null);
				self.vpProviders.removeAll();
				self.vpReceivers.removeAll();
				self.vpProviderRoles.removeAll();
				self.vpReceiverRoles.removeAll();
				self.businessModelsVP.removeAll();
				self.vpProvider(null);
				self.vpReceiver(null);
				self.selectedProviderRole(null);
				self.selectedReceiverRole(null);
				self.selectedVDMPackage = null;
				//if (self.packagesListVP().length > 0 && self.packagesListVP()[0].id) {
				//    self.loadProviders(self.packagesListVP()[0].id);
				//    self.loadReceivers(self.packagesListVP()[0].id);
				//}
				if (!pack || pack.name === "New" && !pack.id) {
					$('#showMappedInfoPackageVP' + self.encodeId).hide();
				} else {
					$('#showMappedInfoPackageVP' + self.encodeId).show();
					fillVDMPackages(pack, function (VDMPack) {
						self.selectedVDMPackage = VDMPack;
						//self.loadPerformers();
						var bms = [];
						_.each(VDMPack.get('businessModel').models, function (bm) {
							if (bm.get('business') || bm.get('name')) {
								if (self.selectedShapeType.name === 'Activity' && self.mappedDiagramBO) {// filter packs for bm which has pn of mapped activity
									var activity = Backbone.Relational.store.getObjectByName("vdml.Activity").find({ id: self.mappedDiagramBO.mid });
									var collaboration = activity.get('activityOwner');
									var bmPNs = bm.get('participantNetwork');
									var pnExistsInBM = bmPNs.findWhere({ 'collaboration': collaboration });
									if (pnExistsInBM) {
										bms.push({ id: bm.id, name: bm.get('name') });
									}
								} else if (self.competenceMap() && self.mappedDiagramBO) {// filter packs for bm which has pn of mapped activity
									var competencyModel = Backbone.Relational.store.getObjectByName("vdml.BusinessItem").find({ id: self.mappedDiagramBO.mid });
									if (competencyModel) {
										var business = competencyModel.get('businessItemOwner');
									} else {
										competencyModel = Backbone.Relational.store.getObjectByName("vdml.CapabilityOffer").find({ id: self.mappedDiagramBO.mid });
										business = competencyModel.get('capabilityProvider');
									}
									if (business.id === bm.get('business').id) {
										bms.push({ id: bm.id, name: bm.get('name') });
									}
								} else {
									bms.push({ id: bm.id, name: bm.get('name') });
								}
							}
						});
						bms.sort(sortByName);
						self.businessModelsVP(bms);
					});
				}
			});

			this.selectedBMVP.subscribe(function (bmObj) {
				self.vpProviderRoles.removeAll();
				self.vpReceiverRoles.removeAll();
				self.selectedProviderRole(null);
				self.selectedReceiverRole(null);
				self.selectedActivity = null;
				self.selectedVP = null;
				self.selectedCompetence = null;
				$('#showMappedInfoCompetency' + self.encodeId).hide();
				$("#selectCompetence" + self.encodeId).val('');
				$('#showMappedInfoActivity' + self.encodeId).hide();
				$("#selectActivity" + self.encodeId).val('');
				$("#selectVP" + self.encodeId).val('');
				$('#showMappedInfoVP' + self.encodeId).hide();
				$('#selectPerformer' + self.encodeId).removeAttr('disabled');
				$('#selectPerformingRole' + self.encodeId).removeAttr('disabled');
				//$('#selectCapability' + self.encodeId).removeAttr('disabled');
				self.usedCapabilityCom(null);
				self.usedCapability(null);
				self.usedCapabilityComName('');
				self.usedCapabilityName('');
				self.competenciesColl.reset();
				if (self.packagesListVP().length > 0 && self.packagesListVP()[0].id && bmObj) {
					self.loadProviders(self.packagesListVP()[0].id, null, self.myProposition(), bmObj);
					self.loadReceivers(self.packagesListVP()[0].id, null, bmObj);
				}
				if (bmObj && self.vpProvider()) {
					self.loadPerformers(true);
					self.loadActivities();
					$('#showMappedInfoBMVP' + self.encodeId).show();
					self.loadProviderRoles(self.vpProvider(), self.selectedReceiverRole());
					if (self.vpReceiver() && self.selectedBMVP) {
						self.loadReceiverRoles(self.vpReceiver(), self.selectedProviderRole());
					}
					self.loadPerformerRoles(self.activityPerformer());
					if ($('#providerField' + self.encodeId).is('[disabled=disabled]')) {
						var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: bmObj.id });
						if (bm.get('business').id !== self.vpProvider().id) {
							$('#myProposition' + self.encodeId).prop('checked', false);
							self.myProposition(false);
							//$('#myProposition' + self.encodeId).attr('disabled', 'disabled');
						} else if (!self.receiverShape) {
							$('#myProposition' + self.encodeId).removeAttr('disabled');
						}
					}
					if (self.selectedVP) {
						self.refreshProviderAndRecipientBasedOnSelectedVP(self.selectedVP);
					} else {
						//load vp
						self.loadVPs(bmObj.id);
					}
					if (self.competenceMap()) {
						self.loadCompetencies(/*self.selectedShapeType.name === 'Capability' ? false : */true);
						self.fillActivityPerformerForCompetencyMapping();
						if (!self.rolesLoadedForCompetency && self.model.get('type') === "vdml_ValueStreamMapDiagram" && self.selectedActivity && (!self.activityPerformer() || !self.activityPerformerRole())) {
							for (var i = 0; i < self.businessModelsVP().length; i++) {
								if (self.businessModelsVP()[i] == self.selectedBMVP() && (i + 1) < self.businessModelsVP().length) {
									self.selectedBMVP(self.businessModelsVP()[i + 1]);
									break;
								}
							}
						}
					}
				} else {
					$('#showMappedInfoBMVP' + self.encodeId).hide();
				}
			});

			this.activityPerformer.subscribe(function (performer) {
				self.loadPerformerRoles(performer);
			});

			this.vpProvider.subscribe(function (provider) {
				if (self.selectedBMVP()) {
					self.loadProviderRoles(provider, self.selectedReceiverRole());
				}
			});
			this.vpReceiver.subscribe(function (receiver) {
				if (self.selectedBMVP()) {
					self.loadReceiverRoles(receiver, self.selectedProviderRole());
				}
				self.enableCompleteForActivity();
			});
			this.selectedProviderRole.subscribe(function (role) {
				if (self.selectedProviderRole() && $("#selectVP" + self.encodeId).val().trim() !== "") {
					if (!self.selectedReceiverRole() || (self.selectedReceiverRole() && role && role.id === self.selectedReceiverRole().id)) {
						self.loadReceiverRoles(self.vpReceiver(), role);
					}
				}
				self.enableCompleteForActivity();
			});

			this.selectedReceiverRole.subscribe(function (role) {
				if (self.selectedProviderRole() && $("#selectVP" + self.encodeId).val().trim() !== "") {
					if (!self.selectedProviderRole() || (self.selectedProviderRole() && role && role.id === self.selectedProviderRole().id)) {
						self.loadProviderRoles(self.vpProvider(), role);
					}
				}
				self.enableCompleteForActivity();
			});
			this.activityPerformerRole.subscribe(self.enableCompleteForActivity);
			this.myProposition.subscribe(function (newVal) {
				if (newVal) {
					$('#recipientLegend' + self.encodeId).hide();
					$('#recipients' + self.encodeId).hide();
					$('#recipientRoles' + self.encodeId).hide();
					$('#showPropositionSubHeading' + self.encodeId).text(DataManager.getDataManager().get('localeManager').get("selectCreateMP"));
					$('#showPropositionHeading' + self.encodeId).text(DataManager.getDataManager().get('localeManager').get("MyProposition"));
					self.vpReceivers.removeAll();
					if (!self.selectedBMVP()) {
						return;
					}
					var previouslySelectedRole = self.selectedProviderRole();
					self.loadProviders(self.selectedVDMPackage.id, 'vdml.OrgUnit', true, self.selectedBMVP());
					var bm = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({ id: self.selectedBMVP().id });
					if (previouslySelectedRole && previouslySelectedRole.id) {//logic to set previously set role by user
						var businessRole = false;
						if (bm && bm.get('business')) {
							var bmRoles = bm.get('bmRole');
							var role = bmRoles.findWhere({ 'id': previouslySelectedRole.id })
							var roleAssignments = role.get('roleAssignment');
							for (var i = 0; i < roleAssignments.length; i++) {
								var assignment = roleAssignments.at(i);
								if (assignment.get('participant').id === bm.get('business').get('id')) {
									businessRole = true;
								}
							}
						}
						if (businessRole) {
							for (var i = 0; i < self.vpProviderRoles().length; i++) {
								if (previouslySelectedRole.id === self.vpProviderRoles()[i].id) {
									self.selectedProviderRole(self.vpProviderRoles()[i]);
									break;
								}
							}
						}
						previouslySelectedRole = null;
					}
					//self.loadVPs();
				} else {
					$('#showPropositionSubHeading' + self.encodeId).text(DataManager.getDataManager().get('localeManager').get("selectCreateVP"));
					$('#showPropositionHeading' + self.encodeId).text(DataManager.getDataManager().get('localeManager').get("ValueProposition"));
					$('#providerLegend' + self.encodeId).show();
					$('#providers' + self.encodeId).show();
					$('#providerRoles' + self.encodeId).show();
					$('#recipientLegend' + self.encodeId).show();
					$('#recipients' + self.encodeId).show();
					$('#recipientRoles' + self.encodeId).show();
					if (!self.mappedDiagramBO) {
						//self.loadProviders(self.packagesListVP()[0].id);
						//self.loadReceivers(self.packagesListVP()[0].id);
					}
					if (self.selectedVP) {
						self.refreshProviderAndRecipientBasedOnSelectedVP(self.selectedVP);
					}
				}
				self.loadVPs();
			});

			//On change functions
			this.onChangeSelectedVP = function () {
				if (self.selectedVP) {
					self.refreshProviderAndRecipientBasedOnSelectedVP(self.selectedVP);
					$('#showMappedInfoVP' + self.encodeId).show();
				} else {
					$('#providerField' + self.encodeId).removeAttr('disabled');
					$('#providerRoleField' + self.encodeId).removeAttr('disabled');
					$('#recipientField' + self.encodeId).removeAttr('disabled');
					$('#recipientRoleField' + self.encodeId).removeAttr('disabled');
					$('#showMappedInfoVP' + self.encodeId).hide();
					$('#providerLegend' + self.encodeId).show();
					$('#providers' + self.encodeId).show();
					$('#providerRoles' + self.encodeId).show();
					if (!self.myProposition()) {
						$('#recipientLegend' + self.encodeId).show();
						$('#recipients' + self.encodeId).show();
						$('#recipientRoles' + self.encodeId).show();
					}
				}
			};
		};
	}

	static getInstance(model, options,  diagramBO, callback){
		var view = new MappingWizardViewModel($('#wizardModal'), model, options,  diagramBO, callback);
		//view.init(model, options);
		return view;
	};
}
path.MappingWizardViewModel = MappingWizardViewModel;
