import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {ValueElement} from './ValueElement'
import {SuggestionMixin} from './SuggestionMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'


	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

	var Suggestion = ValueElement.extend(utils.customExtends({
		relations:SuggestionMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			ValueElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new SuggestionMixin()
	));
	Suggestion.getInstance = function(shapeId,targetId,targetType,data,owner){
		var id = DataManager.getDataManager().guidGeneratorByOwner(owner);
		var suggestionIns = new Suggestion({id:id,shapeId:shapeId,targetId:targetId,targetType:targetType,data:data,suggestionOwner:owner});
		return suggestionIns;
	}
	
	Suggestion.abstract = false;
	path.Suggestion = Suggestion;
	export {Suggestion};
//});