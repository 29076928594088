import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {TicketStatus} from '../../../../../version1/bo/tickets/TicketStatus'
//import {TicketViewModel} from './TicketViewModel'

/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global","appbo/tickets/Ticket","appbo/tickets/TicketStatus","appbo/tickets/TicketType","appbo/tickets/Comment","appviews/tickets/views/properties/CommentViewModel"],
function(require,$,_, Backbone,ko,koMapping,kb,bootbox,DataManager,global,Ticket,TicketStatus,TicketType, Comment, CommentViewModel
){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("tickets.views.properties",global.version);

	export class TicketViewModel {
		constructor(model,options){
			var self = this;
			this.init(model, options);
		}
	 removeFromCollection(itemsToBeRemoved,collection,removeOnlyRef){
			var model = this.model;
			var items = new Array();
			for(var i=0;i<itemsToBeRemoved.length;i++)
			{
				for(var j=0;j<collection.length;j++){
					if(collection.at(j).id === itemsToBeRemoved[i]){
						items.push(collection.at(j));
					}
				}
			}
			for(var k=0;k<items.length;k++){
				items[k].lawnchair = DataManager.getDataManager().vdmStore;
				if(removeOnlyRef){
					collection.remove(items[k]);
				}else{
					items[k].destroy();
				}
			}
		};


	init(model, options){
		var self = this;
		this.id = kb.observable(model, 'id');
		function htmlEscape(str) {
    		return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '');
		}
		this.encodeTicketId = htmlEscape(this.id());
		this.TicketViewModel = this;
		this.model = model;
		this.editable = ko.observable(false);
		this.description = kb.observable(model, 'description');
		this.ticketType = kb.observable(model, 'ticketType');
		this.status = kb.observable(model,'status');

		this.statusColor = ko.computed(function(){
			var type = this.status();
			if(type == "Closed"){
				return "red";	
			}
			else if(type == "InProgress"){
				return "yellow";	
			}
			else if(type == "VotingOpen"){
				return "yellowgreen";	
			}
			else if(type == "VotingClosed"){
				return "coral";	
			}
			else if(type == "Fixed"){
				return "blue";	
			}
			else{
				return "green";
			}
		},this);
		this.ticketColor = ko.computed(function(){
			var type = this.ticketType();
			if(type == "Dispute"){
				return "red";	
			}
			else if(type == "Suggestion"){
				return "yellow";	
			}
			else if(type == "Validation"){
				return "green";	
			} else{
				return "blue";
			}
			
		},this);
		this.name = kb.observable(model, 'name');
		
		//this.comment = kb.collectionObservable(model.get('comment'),{view_model : CommentViewModel});

		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['Ticket'
		,'type'
		,'fixedInVersion'
		,'description'
		,'selectedSolutionCriteria'
		,'name'
		,'assignedTicket'
		,'Ticket'
		,'creationDate'
		,'Date'
		,'lastUpdate'
		,'Date'
		,'closingDate'
		,'Date'
		,'comment'
		,'Comment'
		,'reporter'
		,'User'
		,'closedBy'
		,'User'
		,'referredElement'
		,'EObject'
		,'possilbeSolution'
		,'Solution'
		,'acceptedSolution'
		,'Solution'
		,'assignedTo'
		,'User'
		,'fixingDate'
		,'Date'
		,'Open'
		,'InProgress'
		,'VotingOpen'
		,'VotingClosed'
		,'Fixed'
		,'Closed'
		,'Dispute'
		,'Suggestion'
		,'Validation'
		,'TODO']);
		/*for(var i=0;i<this.statusOptions.length;i++){
			this.statusOptions[i].description = this.labels[this.statusOptions[i].name]();
		}
		for(var i=0;i<this.typeOptions.length;i++){
			this.typeOptions[i].description = this.labels[this.typeOptions[i].name]();
		}*/
	}
	
	static getInstance(model, options){
		var view = new TicketViewModel(model,options);
		return view;
	};
}
path.TicketViewModel = TicketViewModel;
