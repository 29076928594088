import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {CollaborationDiagram} from './CollaborationDiagram'
import {CollaborationDiagramMixin} from './CollaborationDiagramMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/CollaborationDiagram","appbo/vdml/CollaborationDiagramMixin"],
//function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,CollaborationDiagram,CollaborationDiagramMixin){
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class CmmnDiagramMixin {

	defaults(){
		var ret = {
			type: "vdml_CmmnDiagram"
		}
		return jQuery.extend(CollaborationDiagram.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		])
	}
	static getCumulativeMixinRelations(){
		if (!CmmnDiagramMixin.cummulativeRelations) {
            CmmnDiagramMixin.cummulativeRelations = _.union(CmmnDiagramMixin.getMixinRelations()
                , CollaborationDiagramMixin.getCumulativeMixinRelations()
            );
        }
		return CmmnDiagramMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"vdml_CollaborationDiagram"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		return CollaborationDiagram.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return CollaborationDiagram.prototype.getPackagePath.apply(this, arguments);
	}
	static getDialogViewProperties (type) {
		if (type === "explorerModelJson") {
			return {
				templatePath: "views/beeppackage/views/properties/explorerModelJsonTemplate.html",
				templateName: "explorerModelJsonTemplate",
				viewTypeStr: "appviews/beeppackage/views/properties/explorerModelJsonViewModel",
				tabId : "explorerModelJsonView",
				tabName: "explorerModelJson"
			}
		}
	}
    getParent () {
        var container;
        if (!container) {
            container = this.get("diagramOwner") ? this.get("diagramOwner") : this.previousAttributes().diagramOwner;
            if (container) {
                return container;
            }
        }
        return this;
    }
	getViewProperties(type){
		return {
			templatePath: "views/vdml/views/designer/caseModel/CmmnDiagramTemplate.html",
			templateName : "CmmnDiagramTemplate",
			viewTypeStr : "appviews/vdml/views/designer/caseModel/CmmnDiagramViewModel",
			tabId : "CmmnDiagramView",
			tabName: "CmmnDiagram"
		}
	}
	
	}
	path.CmmnDiagramMixin = CmmnDiagramMixin;
	//return CmmnDiagramMixin;
//});